import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogueService {

  constructor(private http: HttpClient, public commonService: CommonService) { }
  getComponentMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetComponentMaster');
  }
  getstateMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetStatesMaster');
  }
  getLocations() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetLocations');
  }
  getStandardComponent() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetStandardComponent');
  }
  postPriceCatalogue(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostPriceCatalogue/SavePriceCatalogue',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postPriceComponent(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostPriceCatalogue/PostPriceComponent',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getPriceCatalogueDetails(id,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetPriceCatalogueDetails?Id=' + this.commonService.DataToEncrypt(id, true)+'&patientInfoId='+ this.commonService.DataToEncrypt(patientInfoId, true));

  }
  deletePriceComponent(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostPriceCatalogue/deletePriceComponent?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getPriceComponentDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetPriceComponentDetails?Id=' + this.commonService.DataToEncrypt(id, true));

  }
  postOrderPriceComponent(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostPriceCatalogue/SavePriceComponentOrder',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  getDeductibleMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetDeductibleMaster');
  }
}

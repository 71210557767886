<div class="top-btn-section clearfix srchtop" >


   <h2 class="head-section mb-2 mt-0 txtsize">Declined List</h2> 
  </div> 
  <div class="col-md-3 row srchtop">
   <div  class="search">
       <!-- <input  type="text" placeholder="Search By Client Name" name="" class="search-input " [(ngModel)]="searchpatientListtxt" (keyup)="OnSearchPatient()"><a  class="search-icon"><i  class="fa fa-search"></i></a></div> -->
       <input  type="text" placeholder="Search" name="" class="search-input " [(ngModel)]="searchpatientListtxt" (keyup)="OnSearchPatient()"><a  class="search-icon"><i  class="fa fa-search"></i></a>
      </div>  
   </div>
   <div>
      <button class="btn back-btn-new mr-2 btnback" (click)="BacktoLead()"><i
         class="fas fa-chevron-left mr-2"></i>Back</button>
   </div>    
   
   <div class="grid-view-section dclnview">
    <div class="top-list-notifiaction" >
      
    </div>
  
    <div class="list-main-section" >
       <div class="col-md-12 p-0">
          <div class="row" *ngFor="let item of lstLead">
             <div class="col-md-12">
                <div class="card list-grid mb-3">
                   <div class="card-body hot ">
                      <div class="circle"><i class="fas fa-circle"></i></div>
                      <div class="row no-gutters align-items-top">
                         <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                               <h5 class=" card-title text-uppercase mb-2 "><a>{{item.FirstName}} {{item.LastName}}</a>
                               <mat-icon class="mt-2 cursor-pointer info-icon" matTooltip="{{getMoreInformation(item)}}"
                                  matTooltipClass="leadinfo" matTooltipPosition="right">info</mat-icon>
                            </h5>
 
                            <div class="card-details">
                               <p class="label-small ">Gender | DOB </p>
                               <p>{{item.GenderType}} | {{item.Dob| date: 'MM/dd/yyyy'}}</p>
                            </div>
                            <p class="label-small mb-1 ">Lead Stage </p>
 
                            <h5 class=" card-title text-uppercase mb-2 benefits">{{item.LeadStage}}</h5>
                            <div class="card-details">
                               <p class="clearfix"><span class="label-small">Source </span>
                                 
                                      <span
                                     class="value-small">{{item.SourceTypeName}}</span></p>
                               <p class="clearfix"><span class="label-small">Lead Type </span><span
                                     class="value-small">{{item.LeadTypeName}}</span></p>
                               
                                     <p class="clearfix"><span class="label-small">Location </span><span
                                        class="value-small">{{item.FacilityTypeName}}</span></p>
                                     <p class="clearfix"><span class="label-small">Address </span><span
                                        class="value-small">{{item?.City}}{{item?.StateName ==
                                        '' ? '' :','}} {{item?.StateName}}</span> </p>
                                        <p class="clearfix"><span class="label-small">Status </span><span
                                          class="value-small">Declined</span></p>
                                          <p class="clearfix"><span class="label-small">Reason </span><span
                                             class="vreason">{{item?.Reason}} - <a class="newlink" (click)="commentsClick(item?.Comments)">View Comment</a></span></p>
                                      
                            </div>
                         </div>
                         <div class="col-lg-3 col-md-3 col-sm-6 col-12 mb-2">
                            <h5 class=" card-title text-uppercase mb-2 ">Contact information </h5>
                            <div class="card-details">
                             
                               <p class="name-client">{{item?.ParentName}}</p>
                               <p>{{item?.ContactNo | mask: '(000) 000-0000'}}</p>
                               <p>{{item?.Email}}</p>
                            </div>
                         </div>
                         <div class="col-lg-3 col-md-3 col-sm-6 mb-2">
                            <h5 class=" card-title text-uppercase mb-2 ">immediate action </h5>
                            <div class="card-details">
                               <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 resend-mail"
                                  *ngIf="item.ImmediateActionId == statusResendActivationMail && roleAccess.isCreate"
                                  title="Resend Activation Mail" (click)="onResendActivation(item)">RESEND ACTIVATION LINK</button>
                               <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                  (click)="onIntakeSchedule(item)"
                                  *ngIf="item.ImmediateActionId == statusintake && roleAccess.isCreate"
                                  title="For Intake Schedule">Schedule
                                  Intake</button>
                               <button class="btn primary-btn-filled primary-btn-l mb-2 h-40"
                                  *ngIf="item.ImmediateActionId == statusUploadInsurance" title="Upload Insurance">Upload
                                  Insurance</button>
                               <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 review"
                                  *ngIf="item.ImmediateActionId == statusReviewPacket && roleAccess.isApprove" title="Review Packet"
                                  (click)="onPatientinfo(item.PatientInfoId,item)">REVIEW CLIENT PACKET</button>
                               <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                  *ngIf="(item.ImmediateActionId == statusVerified && !item.IsSkipInsurance) && roleAccess.isEdit " title="CRBA"
                                  (click)="onUpdateCRBA(item.PatientInfoId)">Update Benefits</button>
                               <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                  *ngIf="((item.ImmediateActionId == statusCrbaUpdate ) || (item.ImmediateActionId == statusVerified && item.IsSkipInsurance)) && roleAccess.isCreate" title="Financial Proposal"
                                  (click)="onSubmitFinancial(item)">Submit Financial Proposal</button>
                               
                               <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                  *ngIf="item.ImmediateActionId == statusreviewTour && (item.Status==statusRequestTour||item.Status==null) && roleAccess.isApprove" title="Request Tour"
                                  (click)="onRequestTour(item)">Review Tour Request</button>
                                  <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                  *ngIf="item.ImmediateActionId == statusreviewTour && item.Status==statusstaffAssigned && roleAccess.isEdit" title="Request Tour"
                                  (click)="onRequestTour(item)">Edit Tour</button>
                               <p *ngIf="item.ImmediateActionId == statusReviewPacket">
                                 {{item.PatientStatus}} <br><span class="text-danger">{{item.UpdatedOn | date: 'MM/dd/yyyy'}}</span>
                               </p>
                               <p *ngIf="item.ImmediateActionId == statusintake">
                                 {{item.PatientStatus}} <br><span class="font-weight-bold">{{item.UpdatedOn | date: 'MM/dd/yyyy'}}</span>
                               </p>
                               <p class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                               *ngIf="item.ImmediateActionId == statusreviewTour && item.Status==statusstaffAssigned" class="plain-message" title="Request Tour">
                                 Tour Scheduled for {{item.RequestTourDate | date: 'MM/dd/yyyy'}}
                               </p>
                               <p class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                               *ngIf="item.ImmediateActionId == statusreviewTour && item.Status==statusTourCompleted" class="plain-message" title="Request Tour">
                                 Tour Completed Successfully for {{item.RequestTourDate | date: 'MM/dd/yyyy'}}
                               </p>
                               <p class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                               *ngIf="item.ImmediateActionId == statusreviewTour && item.Status==statusdecline" class="plain-message" title="Request Tour">
                                 Tour has been cancelled for {{item.RequestTourDate | date: 'MM/dd/yyyy'}}
                               </p>
                             
                               <p *ngIf="item.ImmediateActionId != statusCrbaUpdate && item.ImmediateActionId != statusReturn && item.ImmediateActionId != statusFinancial && item.ImmediateActionId != statusVerified" class="status-msg d-none">
                                  {{item.PatientStatus}}
                                  <span
                                     *ngIf="item.PatientStatus !='' && item.ImmediateActionId != statusstaffAssigned ">{{item.CreatedOn|
                                     date: 'MM/dd/yyyy'}}</span>
                               </p>
                               <p *ngIf="item.ImmediateActionId == statusReturn || item.ImmediateActionId == statusFinancial " class="plain-message">
                                  {{item.PatientStatus}}
                                  <span *ngIf="item.PatientStatus !='' && item.ImmediateActionId != statusstaffAssigned">{{item.CreatedOn|
                                     date: 'MM/dd/yyyy'}}</span>
                               </p>
                               <u>
                                  <a *ngIf="item.ImmediateActionId == statusCrbaUpdate"
                                     class="status-msg cursor-pointer text-underline d-none"
                                     (click)="onUpdateCRBA(item.PatientInfoId)">{{item.PatientStatus}}<span
                                        *ngIf="item.PatientStatus !=''">{{item.CreatedOn|
                                        date: 'MM/dd/yyyy'}}</span></a>
                               </u>
                               <div class="mt-2 mb-2">
                                  <a *ngIf="item.ImmediateActionId == statusdecline"
                                     class="status-msg cursor-pointer text-underline"
                                     (click)="getfinancialReason(item)">Show Reason </a> <a
                                     *ngIf="item.ImmediateActionId  == statusdecline || item.ImmediateActionId == statusFinancial || item.ImmediateActionId == statusreviewTour || item.ImmediateActionId == statusstaffAssigned "
                                     class="status-msg cursor-pointer text-underline" (click)="onSubmitFinancial(item)">
                                     Edit Financial Proposal</a>
                               </div>
 
                            </div>
                         </div>
                         <div class=" col-lg-3 col-md-6 col-sm-6 mb-2">
                            <div class="icons-section row mt-4">
                               <div class=" col-lg-11 ml-auto">
                                 
                                  <button class="col text-left col-btn-dis" role="button"
                                     (click)="onPatientdetailview(item.PatientInfoId)" title="Detail View"><i
                                        class="far fa-calendar-minus"></i></button>
                                        <button class="col text-left col-btn-dis" role="button" 
                                     (click)="onPatientActivitylogview(item.PatientInfoId)" title="Activity"><i
                                      class="fas fa-user-clock"></i></button>
                                  <button class="col text-center col-btn-dis" role="button"
                                     [disabled]="item.ImmediateActionId == statusResendActivationMail"
                                     (click)="onPatientinfo(item.PatientInfoId,item)"  title="Edit"><i class="far fa-edit"
                                        aria-hidden="true"></i></button>
                                  <button class="col text-center col-btn-dis" role="button"
                                     (click)="openComposeMail(item.Email)"><i
                                        class="far fa-envelope"></i></button>
                                      
                                        <button class="col text-center col-btn-dis" role="button"
                                     (click)="returnLead(item.PatientInfoId)"  title="Resume"><i class="fas fa-undo"></i></button>
                                 
                                  <span class=" position-relative d-none">
                                     <div class="dropdown no-caret list-options" dropdown="">
                                        <button aria-controls="dropdown-basic"
                                           class="btn btn-sm btn-neutral mr-0 dropdown-toggle" dropdowntoggle=""
                                           id="button-basic" type="button" aria-haspopup="true" aria-expanded="false"><i
                                              class=" fas fa-ellipsis-v"></i></button>
                                        <div
                                           class="dropdown-menu dropdown-menu-right dropdown-menu-arrow ng-star-inserted"
                                           style="left: auto; right: 0px;"><a class="dropdown-item"
                                              href="javascript:void(0)"> Action </a><a class="dropdown-item"
                                              href="javascript:void(0)"> Another action </a><a class="dropdown-item"
                                              href="javascript:void(0)"> Something else here </a></div>
                                     </div>
                                  </span>
 
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
       <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="20"
          [pageSizeOptions]="[ 10, 20, 50, 75, 100]">
       </mat-paginator>
    </div>
 
    
 </div>
 <div class="modal mail-modal" id="myModal" [style.display]="global.showEmailPopup ? 'block' : 'none'">
    <div class="modal-dialog mb-2">
       <div class="modal-content email-compose">
          <div class="modal-header">
             <h4 class="modal-title">Email</h4>
             <button aria-hidden="true" data-dismiss="modal" class="close" 
                type="button"><i class="fas fa-times"></i></button>
 
          </div>
          
       </div>
    </div>
 </div>


 <div class="comment-popup  cancel-tour">

   <div *ngIf="isCommentInformation">
   <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
     <div class="modal-dialog mat-dialog-container popupdcln" role="document">
       <div class="modal-content border-0">
         <div class="modal-header">
            <h6 class="cmdhdr">Comments</h6>
           
         </div>
         <div class="modal-body">
 <div >
   
   <div class="col-md-12">
     <div class="row mb-2">
     </div>
     <div *ngIf="isCommentInformationtext">
      <span class="modal-title pdgleft" >{{isCommentInformationtext}}</span>
   </div>
   <div *ngIf="!isCommentInformationtext">
      <span class="modal-title pdgleft" >No comments</span>
   </div>
     
   
 
       <div class="col-md-2 btnok"  >
         <button mat-raised-button   
         class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40 smtbtn"  type="submit" 
         (click) = "closecomments()"  >ok</button>
       
       </div>
    
   </div>
 </div>
 </div>
 </div>
 </div>
 </div>
</div>
  </div>

<div class="clnical">
    <!-- <span *ngIf="global.evaluvationUpdatedOn !=null"
        class="status-msg float-left text-right w-100 mr-3 mt-2 cursor-pointer text-underline">Last Updated on:
        <span>{{global.evaluvationUpdatedOn | date:
            'MM/dd/yyyy'}}</span></span>
    <div class="col-md-12">
        <div class="text-right">
            <button type="button" (click)="onSaveClinicalQuestion()" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save & Next</button>
            <button type="button" [routerLink]="['/clinician']" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40">Cancel</button>
        </div>
    </div> -->

    <div class="col-md-12 d-flex">
        <span *ngIf="global.evaluvationUpdatedOn !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
            Updated on:
            <span>{{global.globalUtctimeformat(global.evaluvationUpdatedOn) | date:
                'MM/dd/yyyy hh:mm a'}}</span></span>
                <span *ngIf="global.evaluvationUpdatedByName !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
                    Updated By:
                    <span>{{global.evaluvationUpdatedByName}}</span></span>
        <div class="text-right ml-auto">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <!-- <button type="button" (click)="onSaveClinicalQuestion(0)" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{isMovedChma ? 'Next' : 'Save
                &
                Next'}}</button> -->
                <button type="button" (click)="onSaveClinicalQuestion(0)" mat-stroked-button *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{  'Save
                &
                Next'}}</button>
                <!-- *ngIf="!isMovedChma" -->
            <button type="button" (click)="onSaveClinicalQuestion(1)" mat-stroked-button
                *ngIf="roleAccess.isCreate||roleAccess.isEdit" class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save &
                Exit</button>
            <button type="button" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button
                *ngIf="!global.isEvaluvation" (click)="onSaveClinicalQuestion(2)">Save as Draft</button>
            </div>
    </div>
    <!-- [ngClass]="{'disabled':isMovedChma}" -->
    <div >
        <ng-container *ngFor="let item of lstIntakeEvaluation?.answers?.details ;let i=index;">
            <div class="col-md-12 set1-temp">
                <div class="row mb-2">
                    <label class="col-md-12 set-label">{{item.question}}</label>
                    <div class="col-md-12" *ngIf="item.typeId==queDescriptive">
                        <ng-container *ngFor="let opt of item?.options;let idx=index;">
                            <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
                            <textarea matInput appTextareaAutoresize cols="2" rows="4"  class="w-100" maxlength="3000" [(ngModel)]="opt.description"
                                #explanation="ngModel"></textarea>
                            </mat-form-field> -->

                            <app-word-counter [text]="opt.description" [maxlength]="3000"  [(ngModel)]="opt.description" 
                            (textChange)="opt.description = $event"></app-word-counter>
                        </ng-container>

                    </div>
                </div>
            </div>
        </ng-container>

    </div>

    <div class="col-md-12">
        <div class="text-right">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <!-- <button type="button" (click)="onSaveClinicalQuestion(0)" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{isMovedChma ? 'Next' : 'Save
                &
                Next'}}</button> -->
                <button type="button" (click)="onSaveClinicalQuestion(0)" mat-stroked-button *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{ 'Save
                &
                Next'}}</button>
                <!-- *ngIf="!isMovedChma" -->
            <button type="button" (click)="onSaveClinicalQuestion(1)" mat-stroked-button *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save &
                Exit</button>
        </div>
    </div>

</div>
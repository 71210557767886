import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { PatientService } from 'src/app/patient/patient.service';
import { chmadetails } from 'src/app/_models/_Patient/_CHMADetails';
import { IntakeCmhaComponent } from 'src/app/patient/intake/intake-cmha/intake-cmha.component';
import { chmaEditPatientDetails } from 'src/app/_models/_Patient/_ChmaEditPatientDetails';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ChangeDetectorRef } from '@angular/core';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { TreatmentDiagosis } from 'src/app/_models/_Treatment/_diagnosis';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-cmha-clinicalinfo',
  templateUrl: './cmha-clinicalinfo.component.html',
  styleUrls: ['./cmha-clinicalinfo.component.scss']
})
export class CmhaClinicalinfoComponent implements OnInit {
  // @ViewChild(IntakeCmhaComponent, { static: false }) private chma;
  _dtoDiagnosis: TreatmentDiagosis = new TreatmentDiagosis();
  @ViewChild('DCode') DCode: MatSelect;
  isMovedChma: boolean;
  selectedTabIndex: number;
  queYesNo = constantVariables.questionType.YesNo;
  queMCSA = constantVariables.questionType.MCSA;
  queMCMA = constantVariables.questionType.MCMA;
  queDescriptive = constantVariables.questionType.Descriptive;
  queRatings = constantVariables.questionType.Ratings;
  survey = constantVariables.inTakeForms.Survey;
  patientInfoId: any;
  patientAccountId: any;
  lstIntakeClinicial: any;
  lstMentalSurvey: any;
  lstCHMADetails: chmadetails;
  isReqExplanation: boolean = false;
  isReqMentalExplanation: boolean = false;
  today = new Date();
  patientDetails= new chmaEditPatientDetails();
  roleAccess: RoelAccess;
  IsSuicidalOption: boolean;
  ddlMindset: any=[];
  mindsetList:any[]=[];
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  remainingChars : number;
  isShowCount: boolean[]=Array().fill(false);; 
  wordCount: number = 0;
  wordCounts: number[] ;
  chmaTreatmentSummaryId : number;
  ddldiagnosiscode : any;
  diagnosisCode : any;
  diagnosisCodes : any = [];
  secondaryId : any;
  constructor(public global: Global, public patientservice: PatientService, private chma: IntakeCmhaComponent,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public roleaccessservice:PageAccessService,
    private cdr: ChangeDetectorRef,public treatmentservice: TreatmentService) { }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'mindsetMasterid',
      textField: 'mindSetLable',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: false,
      enableCheckAll:false,
    };
    const pageid = constantVariables.pagelists.PrepareCHMA;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.lstCHMADetails = new chmadetails();
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.isMovedChma = Number(this.commonService.FrontEndDecryption(params.isChma).replace(/"/g, "")) == 0 ? false : true;
      }
      this.chmaTreatmentSummaryId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
     
   });
   this.getClinicialQuestion();
   this.getInTakeQuestions();
   this.getDiagnosisCodeMster();
  }
  // onTextChange(updatedText: string,item,i) {
  //   item[i].description = updatedText;
  //   console.log('Updated Text:', updatedText);
  // }

  trackByFn(index, item) {
    return index; // Use a unique identifier if available
  }
  updateRemainingChars(value,maxlength,i){
    this.isShowCount[i] = true;
    this.wordCount = maxlength-value;
  }
  getClinicialQuestion() {
    this.spinnerservice.show();
    this.patientservice.getInTakeQuestions(7, this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.lstIntakeClinicial = cloneDeep(res);

       let SuicidalMindset = this.lstIntakeClinicial.answers.details.filter(x=>x.configureFormDetailId==311)[0];
       if(SuicidalMindset.isAnswered){
        for(let item of SuicidalMindset.options){
          if(item.isAnswer==true && item.optionLabel=='Yes'){
            this.IsSuicidalOption=true;
          }
          if(item.isAnswer==true && item.optionLabel=='No'){
            this.IsSuicidalOption=false;
          }
        }
       }
       this.lstIntakeClinicial?.answers?.details.forEach(e => {
        if (e.typeId == 4 && e.question == 'Current DSM-V diagnosis (Write numeric code and description of diagnosis): '){
          if(e.diagnosiscode){
            this._dtoDiagnosis.diagnosisCode = e.diagnosiscode.split(',');
          }
        }
      })
      if(this.ddldiagnosiscode){
        this.ddldiagnosiscode.forEach(x=>x.isActive=false);
      }
      if(this._dtoDiagnosis.diagnosisCode){
        if(this._dtoDiagnosis.diagnosisCode.length > 0)
        {
          this._dtoDiagnosis.diagnosisCode.forEach(diagnosis => {
            const index = this.ddldiagnosiscode.findIndex(x => x.value === diagnosis)
            this.ddldiagnosiscode[index].isActive = true;
          });
        }
      }      
       this.GetMindsetMaster();
       this.getCHMADetails();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getInTakeQuestions() {

    this.patientservice.getInTakeQuestions(8, this.patientAccountId).subscribe(
      (res: any) => {

        this.lstMentalSurvey = cloneDeep(res);
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getCHMADetails() {
    //this.spinnerservice.show();
    this.secondaryId = localStorage.getItem('id');
    this.patientservice.getCHMADetails(this.patientAccountId,this.secondaryId).subscribe(
      (res: any) => {
        if (res != null) {
          this.lstCHMADetails = res;
          this.lstCHMADetails.ageStarted = this.lstCHMADetails.ageStarted ==0 ? this.lstCHMADetails.ageStarted ="":this.lstCHMADetails.ageStarted;
          if(res.mindSet){
            // var mindSetIdList=res.mindSet.split(',');
            // mindSetIdList.map(x=>{ 
            //  var mind= this.ddlMindset.filter(y=>y.mindsetMasterid==x)[0];
            //  if(mind){
            //   console.log(mind,mind.mindsetMasterid);              
            //  this.mindsetList.push(+mind.mindsetMasterid);
            // }
            // })            
            var myArr = String(res.mindSet).split(",").map((num)=>{
              return Number(num)
            })
            this.mindsetList=myArr;
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  rbAnswer(index, opt,details) {
    this.lstIntakeClinicial.answers.details[index].options.forEach((val: any, key: any) => {
      this.lstIntakeClinicial.answers.details[index].options[key].isAnswer = false;
    });
    this.lstIntakeClinicial.answers.details[index].options[opt].isAnswer = true;
    if( this.lstIntakeClinicial.answers.details[index].configureFormDetailId==311 && this.lstIntakeClinicial.answers.details[index].options[opt].optionLabel=='Yes'){
     this.IsSuicidalOption = true;
    }
    else if(this.lstIntakeClinicial.answers.details[index].configureFormDetailId==311 && this.lstIntakeClinicial.answers.details[index].options[opt].optionLabel=='No'){
      this.IsSuicidalOption = false;
    }
  }
  cbkAnswer(index, opt, event) {
    this.lstMentalSurvey.answers.details[index].options[opt].isAnswer = !this.lstMentalSurvey.answers.details[index].options[opt].isAnswer;

  }
  onSaveClinicalQuestion(type) {
    this.cdr.detectChanges();
   // if (!this.isMovedChma) {
      for (let i = 0; i < this.lstIntakeClinicial?.answers?.details?.length; i++) {
        if (this.lstIntakeClinicial?.answers?.details[i].typeId == 1) {
        
          for (let j = 0; j < this.lstIntakeClinicial?.answers?.details[i].options?.length; j++) {
            if (this.lstIntakeClinicial?.answers?.details[i].options[j].optionLabel == 'Yes' && this.lstIntakeClinicial.answers.details[i].options[j].isAnswer && (this.lstIntakeClinicial.answers.details[i].explanation == null || this.lstIntakeClinicial.answers.details[i].explanation == '')) {
              this.lstIntakeClinicial.answers.details[i].isReqExplanation = true;
              this.snackbar.error("Please fill mandatory field");
              return
            }
          }

        }
        else{
          if (this.lstIntakeClinicial?.answers?.details[i].typeId == 4 && this.lstIntakeClinicial?.answers?.details[i].question == 'Current DSM-V diagnosis (Write numeric code and description of diagnosis): '){
            this.diagnosisCode = this._dtoDiagnosis.diagnosisCode
            var clinicianString = '';
            if(this.diagnosisCode == null || ''){
              this.snackbar.error("Please fill Current DSM-V diagnosis information");
              return;
            }
            this.diagnosisCode.map((cli, index) => {
              if (index == 0) {
                clinicianString = cli
              } else {
                clinicianString = clinicianString + ',';
                clinicianString = clinicianString + cli
              }
            })
            this.lstIntakeClinicial.answers.details[i]["diagnosiscode"] = clinicianString;
          }
        }
      }
      for (let i = 0; i < this.lstMentalSurvey?.answers?.details?.length; i++) {
        for (let j = 0; j < this.lstMentalSurvey?.answers?.details[i].options?.length; j++) {
          if (this.lstMentalSurvey?.answers?.details[i].options[j].optionLabel == 'Other') {
            if (this.lstMentalSurvey?.answers?.details[i].options[j].isAnswer && this.lstMentalSurvey.answers.details[i].explanation == '') {
              this.lstMentalSurvey.answers.details[i].isReqMentalExplanation = true;
              this.snackbar.error("Please fill mandatory field");
              return
            }
          }
        }

      }
      
      //this.spinnerservice.show();
      this.onSaveMentalQuestion(type);
      this.patientservice.postIntakeNutritional(this.lstIntakeClinicial).subscribe(
        (res: any) => {
          //this.spinnerservice.hide();
          //this.snackbar.success(JSON.parse(res).message);
          //this.getClinicialQuestion();
          this.onSaveChmadetails(type);
        
          this.onSaveChmaStatus(type);

        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
//} else {
 //     this.chma.onTabChange(3);
 //   }

  }
  onSaveMentalQuestion(type) {

    this.spinnerservice.show();
    this.patientservice.postIntakeNutritional(this.lstMentalSurvey).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //this.getInTakeQuestions();
        this.snackbar.success(JSON.parse(res).message);
        if (type == 1) {
          window.close()
        } else if(type == 2) {
          this.spinnerservice.hide();
        }else{
          this.chma.onTabChange(3);
        }
        //this.snackbar.success(JSON.parse(res).message);


      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  onSaveChmadetails(type) {
    this.lstCHMADetails.mindSet='';
    // this.mindsetList.map(({mindsetMasterid})=>{
    //   this.lstCHMADetails.mindSet += (!this.lstCHMADetails.mindSet) ? mindsetMasterid : `,${mindsetMasterid}`
    // }); 
    this.lstCHMADetails.mindSet=this.mindsetList.toString();
    this.lstCHMADetails.ageStarted = Number(this.lstCHMADetails.ageStarted);
    this.lstCHMADetails.patientAccountId = Number(this.patientAccountId);
    //this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.lstCHMADetails, false);

    
    this.patientservice.postChmaDeatils(this.lstCHMADetails).subscribe(
      (res: any) => {

        //this.onSaveMentalQuestion();
       
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  onSaveChmaStatus(type: any) {
    let _obj = {
      PatientAccountId: this.patientAccountId,
      FormType: 2,
      patientdetails:this.patientDetails,
      Type : type
    }
    // const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.patientservice.postChmaStatus(_obj).subscribe(
      (res: any) => {
        this.chma.getChmaStatus();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  onPrevcmhatab() {
    this.chma.onTabChange(1);
  }
  GetMindsetMaster(){
    this.spinnerservice.show()
    this.commonService.GetMindsetMaster().subscribe(
      res => {
        if (res != null) {
      this.ddlMindset = res;
      
   this.spinnerservice.hide()
  }},
  err => {
    this.spinnerservice.hide();
  
  })
  }
  getTreatmentPlanDiagnosis() {
    this.spinnerservice.show();
    
    this.treatmentservice.getTreatmentPlanDiagnosis(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        var result:any = res;
        if(result.dtoTreatmentPlanDiagnosis){
          this._dtoDiagnosis = result.dtoTreatmentPlanDiagnosis;
          
        }
        if (result.dtoTreatmentDiagnosis && result.dtoTreatmentDiagnosis.length > 0) {
         
          // this._dtoDiagnosis.presentingProblems = res.dtoTreatmentPlanDiagnosis.presentingProblems;
          localStorage.setItem('TreatmentplanProvider',  result.dtoTreatmentPlanDiagnosis.providerName);
          this.treatmentservice.DiagnosisProvider.next(false)
         
          this._dtoDiagnosis.diagnosisCode = res.dtoTreatmentDiagnosis;
          this._dtoDiagnosis.diagnosisCode = [];
          if(this.ddldiagnosiscode){
            this.ddldiagnosiscode.forEach(x=>x.isActive=false);
          }
          for (let i = 0; i < res.dtoTreatmentDiagnosis.length; i++) {
            if (res.dtoTreatmentDiagnosis[i].isActive == true) {
              this._dtoDiagnosis.diagnosisCode[i] = res.dtoTreatmentDiagnosis[i].diagnosisCode;
              const index = this.ddldiagnosiscode.findIndex(x => x.value === res.dtoTreatmentDiagnosis[i].diagnosisCode)
              this.ddldiagnosiscode[index].isActive = true;
            }
          }
        

        }
        
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  onDiagnosisCodeselect(item, event) {

    item.isActive = event;


  }
  onRemoveDiagnosisCode(item) {
    
    this.DCode.options.forEach((x: MatOption, i) => {
      if (x.value == item.value) {
        x.deselect();
      }
    });
    item.isActive = false;
  }
  getDiagnosisCodeMster() {
    this.commonService
      .getDiagonosisCodeMaster()
      .subscribe((res: any) => {
        if (res.objInputString != null) {
          let fres = this.commonService.decrypdata(res.objInputString);
          if (fres.length > 0) {
            this.ddldiagnosiscode = fres;
        }
      }
      this.getTreatmentPlanDiagnosis();
      },
        err => {
          this.spinnerservice.hide();
        });
  }
}

<div class="text-right">
  <button type="submit" form="crbafrm" mat-stroked-button
      class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>
  <button type="button" [routerLink]="['/leadlist']" mat-stroked-button
      class="btn btn-cancel primary-btn-m h-40">Cancel</button>
</div>
<div class="col-md-12 p-0"     [ngClass]="{'disabled': IsnoInsurance }">
  <form (ngSubmit)="insAuthorization.form.valid  && PostInsAuthorization()" id="insAuthorization" autocomplete="off" #insAuthorization="ngForm"
  novalidate>
  <div class="lead-box">
      <div class="row" >
          <div class="col-md-12">
              <div class="box-lead-head">AUTHORIZATIONS</div>


              <div class="col-md-12 p-0">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                          
                          <div class="col-md-12 p-0">
                            <mat-form-field class="w-100 " >
                              <mat-label>Insurance</mat-label>
                              <mat-select   [(ngModel)]="insAuthorizationDtl.InsuranceDetailsId" #InsuranceDetailsId="ngModel" name="InsuranceDetailsId"
                              >
                                <mat-option *ngFor="let item of ddlInsuranceList" [value]="item.insuranceDetailsId" >{{item.carrierName}}</mat-option>
                              </mat-select>
                              <mat-error *ngIf="insAuthorization.submitted && InsuranceDetailsId.invalid">
                                <mat-error *ngIf="InsuranceDetailsId.errors.required">Insurance name is required</mat-error>
                              </mat-error>
                            </mat-form-field>
                          <!--  <mat-form-field class="w-100 " >

                              <mat-label>Insurance Name</mat-label>
                              <mat-select [(ngModel)]="insAuthorizationDtl.InsuranceDetailsId" #InsuranceDetailsId="ngModel" name="InsuranceDetailsId"
                              [ngClass]="{ 'is-invalid': insAuthorization.submitted && InsuranceDetailsId.invalid }"   required >
                                <mat-option *ngFor="let item of ddlInsuranceList" [value]="item.insuranceDetailsId">{{item.carrierName}}</mat-option>
                               
                              </mat-select>
                              <mat-error *ngIf="insAuthorization.submitted && InsuranceDetailsId.invalid">
                                <mat-error *ngIf="InsuranceDetailsId.errors.required">Insurance Name is required</mat-error>
                              </mat-error>
                            </mat-form-field>-->
                         
                            
                          </div>
                      </div>
                  </div>
                    <div class="col-md-3">
                      <div class="example-form">
                          <mat-form-field class="example-full-width w-100" >
                              <mat-label>Authorization Number</mat-label>
                              <input matInput   maxlength="50" (keypress)="alphanumericwithhypen($event)"
                              [(ngModel)]="insAuthorizationDtl.AuthorizationNumber" #AuthorizationNumber="ngModel" name="AuthorizationNumber"
                              [ngClass]="{ 'is-invalid': insAuthorization.submitted && AuthorizationNumber.invalid }"
                              >
                              <mat-error *ngIf="insAuthorization.submitted && AuthorizationNumber.invalid">
                                <mat-error *ngIf="AuthorizationNumber.errors.required">Authorization Number is required</mat-error>
                              </mat-error>
                          </mat-form-field>
                      </div>

                  </div>
                      <div class="col-md-3">
                          <div class="form-group">
                              
                              <div class="col-md-12 p-0">
                                <mat-form-field class="w-100 " >
                                  <mat-label>Authorization Type</mat-label>
                                  <mat-select   [(ngModel)]="insAuthorizationDtl.AuthorizationTypeId" #AuthorizationTypeId="ngModel" name="AuthorizationTypeId"
                                  [ngClass]="{ 'is-invalid': insAuthorization.submitted && AuthorizationTypeId.invalid }" >
                                    <mat-option *ngFor="let item of AuthorizationTypeddl" [value]="item.authorizationTypeId" >{{item.authorizationType1}}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="insAuthorization.submitted && AuthorizationTypeId.invalid">
                                    <mat-error *ngIf="AuthorizationTypeId.errors.required">Authorization Type is required</mat-error>
                                  </mat-error>
                                </mat-form-field>
                              
                                 
                              </div>
                          </div>
                      </div>
                      <!-- <div class="col-md-3 mt-3">
                        <label for="exampleInputEmail1" class="crb-label">Applicable for</label>
                        
                        <mat-radio-group class="ml-3 align-middle mt-2 d-inline-block" aria-label="Select an option"
                        #IsIop="ngModel"
                        [(ngModel)]="insAuthorizationDtl.IsIop" name="IsIop"
                        name="atype">
                      
                        <mat-radio-button class="mr-2" (click)="OnPcpIopClick(1)"  [value]="1">PCP</mat-radio-button>
                        <mat-radio-button class="mr-0" (click)="OnPcpIopClick(2)" [value]="2">IOP</mat-radio-button>

                    </mat-radio-group>
                  
                      </div> -->
                      <div class="col-md-3">
                        <mat-form-field class="w-100">
                          <mat-label>CPT/HCPCS Code</mat-label>
                                <input matInput   maxlength="10" (keypress)="alphanumeric($event)"
                              [(ngModel)]="insAuthorizationDtl.CptHcpcs" #CptHcpcs="ngModel" name="CptHcpcs"
                              [ngClass]="{ 'is-invalid': insAuthorization.submitted && CptHcpcs.invalid }"
                              >
                              <mat-error *ngIf="insAuthorization.submitted && CptHcpcs.invalid">
                                <mat-error *ngIf="CptHcpcs.errors.required">CPT/HCPCS Code is required</mat-error>
                              </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-2 ">
                          <div class="example-form">
                              <mat-form-field class="w-100">
                                  <mat-label>Effective Date</mat-label>
                                  <input matInput [matDatepicker]="stpicker" readonly
                                  [(ngModel)]="insAuthorizationDtl.StartDate" #StartDate="ngModel"
                                  name="StartDate" >
                                  <mat-datepicker-toggle matSuffix [for]="stpicker">
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #stpicker></mat-datepicker>
                                  <mat-error *ngIf="insAuthorization.submitted && StartDate.invalid">
                                    <mat-error *ngIf="StartDate.errors.required">Start Date is required</mat-error>
                                  </mat-error>
                              </mat-form-field>
                          </div>

                      </div>
                      <div class="col-md-2 ">
                          <div class="example-form">
                              <mat-form-field class="w-100">
                                  <mat-label>Expiration Date</mat-label>
                                  <input matInput [matDatepicker]="edpicker" readonly customdate
                                  [(ngModel)]="insAuthorizationDtl.EndDate" #EndDate="ngModel"
                                  name="EndDate" >
                                  <mat-datepicker-toggle matSuffix [for]="edpicker">
                                  </mat-datepicker-toggle>
                                  <mat-datepicker #edpicker></mat-datepicker>
                                  <mat-error *ngIf="insAuthorization.submitted && EndDate.invalid">
                                    <mat-error *ngIf="EndDate.errors.required">End Date is required</mat-error>
                                  </mat-error>

                              </mat-form-field>
                             
                          </div>

                      </div>

                     

                      <!-- <div class="col-md-2 ">
                        <div class="example-form">
                            <mat-form-field class="w-100">
                                <mat-label>Active From</mat-label>
                                <input matInput [matDatepicker]="Actpicker" readonly
                                [(ngModel)]="insAuthorizationDtl.ActiveFrom" #ActiveFrom="ngModel"
                                name="ActiveFrom" required   >
                                <mat-datepicker-toggle matSuffix [for]="Actpicker">
                                </mat-datepicker-toggle>
                                <mat-datepicker #Actpicker></mat-datepicker>
                                <mat-error *ngIf="insAuthorization.submitted && ActiveFrom.invalid">
                                  <mat-error *ngIf="ActiveFrom.errors.required">Active From Date is required</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
  
                    </div> -->
                      <!-- <div class="col-md-3 ">
                          <div class="example-form">
                              <mat-form-field class="example-full-width w-100">
                                  <mat-label>Days per Week</mat-label>
                                  <input matInput placeholder="" step="0" maxlength="1" numeric customMin="1"
                                      customMax="7" 
                                      [(ngModel)]="insAuthorizationDtl.DaysPerWeek" #DaysPerWeek="ngModel" name="DaysPerWeek"
                                      [ngClass]="{ 'is-invalid': insAuthorization.submitted && DaysPerWeek.invalid }">
                                  <mat-error >
                                     
                                  </mat-error>
                              </mat-form-field>
                          </div>

                      </div> -->
                      <div class="col-md-2 ">
                          <div class="example-form">
                              <mat-form-field class="example-full-width w-100">
                                  <mat-label># of Visits Allowed</mat-label>
                                  <input matInput placeholder="" step="0" maxlength="5" numeric
                                    [(ngModel)]="insAuthorizationDtl.NumberOfVisits" #NumberOfVisits="ngModel" name="NumberOfVisits"
                                     [ngClass]="{ 'is-invalid': insAuthorization.submitted && NumberOfVisits.invalid }"
                                     
                                       />
                                       <mat-error *ngIf="insAuthorization.submitted && NumberOfVisits.invalid">
                                        <mat-error *ngIf="NumberOfVisits.errors.required">Number of visits is required</mat-error>
                                      </mat-error>
                              </mat-form-field>
                          </div>

                      </div>
                     
                   
                    <!-- <div class="col-md-2">
                        <div class="example-form">
                            <mat-form-field class="example-full-width w-100">
                                <mat-label>Reference</mat-label>
                                <input matInput placeholder="" maxlength="30" appAlphabetOnly
                                [(ngModel)]="insAuthorizationDtl.Reference" #Reference="ngModel" name="Reference"
                                [ngClass]="{ 'is-invalid': insAuthorization.submitted && Reference.invalid }"
                                >
                               
                            </mat-form-field>
                        </div>

                    </div> -->
                    <div class="col-md-3">
                      <mat-form-field class="w-100">
                        <mat-label>Track</mat-label>
                        <mat-select   [(ngModel)]="insAuthorizationDtl.AuthorizationTrackId" #AuthorizationTrackId="ngModel" name="AuthorizationTrackId"
                        [ngClass]="{ 'is-invalid': insAuthorization.submitted && AuthorizationTrackId.invalid }" >
                          <mat-option *ngFor="let item of drpAuthorizationTrack" [value]="item.AuthorizationTrackId" >{{item.AuthorizationTrackName}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="insAuthorization.submitted && AuthorizationTrackId.invalid">
                          <mat-error *ngIf="AuthorizationTrackId.errors.required">Authorization Track is required</mat-error>
                        </mat-error>
                      </mat-form-field> 
                    </div>

                    <div class="col-md-3">
                      <div class="example-form">
                          <mat-form-field class="example-full-width w-100" >
                              <mat-label>Clinical Information Provided</mat-label>
                                <input matInput [(ngModel)]="insAuthorizationDtl.ClinicalInformationProvider"
                                #ClinicalInformationProvider="ngModel" name="ClinicalInformationProvider" maxlength="150"
                                [ngModelOptions]="{ standalone : true }"
                                [ngClass]="{ 'is-invalid': insAuthorization.submitted && ClinicalInformationProvider.invalid }"

                                > 
                          </mat-form-field>
                          <span class="text-xs sub-descib">(maximum allowed character is 150 only)</span>
                        </div>
                        <!-- <div class="col-md-12 p-0">
                          <div class="text-right">
                              <button type="submit" form="crbafrm" mat-stroked-button
                                  class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>
                              <button type="button" [routerLink]="['/leadlist']" mat-stroked-button
                                  class="btn btn-cancel primary-btn-m h-40">Cancel</button>
                          </div>
                      </div> -->
 
                  </div>
                  <div class="col-md-2">
                        <div class="example-form">
                            <mat-form-field class="example-full-width w-100">
                                <mat-label>Reference</mat-label>
                                <input matInput placeholder="" maxlength="150" specialIsAlphaNumeric
                                [(ngModel)]="insAuthorizationDtl.Reference" #Reference="ngModel" name="Reference"
                                [ngClass]="{ 'is-invalid': insAuthorization.submitted && Reference.invalid }"
                                >
                               
                            </mat-form-field>
                        </div>

                    </div>


                    <!-- <div class="custom-radio" >
                      <mat-radio-group aria-label="Select an option" #isExpiryOrVisit="ngModel"
                      [(ngModel)]="insAuthorizationDtl.isExpiryOrVisit" name="isExpiryOrVisit" required>
                        <div class="row">
                          <div class="col-md">
                            <mat-radio-button class="mr-1" (change)="chengeRadioValue('expiryDate')" [value]="true">Expiry Date
                            </mat-radio-button>
                          </div>
                          <div class="col-md">
                            <mat-radio-button (change)="chengeRadioValue('noOfVisits')" [value]="false">No of visits
                            </mat-radio-button>
                          </div>
                        </div>
                        <mat-error *ngIf="insAuthorization.submitted && isExpiryOrVisit.invalid">
                          <mat-error *ngIf="isExpiryOrVisit.errors.required">Authorization Track is required</mat-error>
                        </mat-error>
                      </mat-radio-group>
                    </div> -->


                    <!-- <div class="col-md-3" *ngIf="isExpiryDate">
                      <div class="form-group">
                          
                          <div class="col-md-12 p-0">
                            <mat-form-field class="w-100 " >
                              <mat-label>Notification Type</mat-label>
                              <mat-select   [(ngModel)]="insAuthorizationDtl.AuthorizationNotificationTypeId" #NotificationTypeId="ngModel" name="NotificationTypeId"
                              [ngClass]="{ 'is-invalid': insAuthorization.submitted && AuthorizationNotificationTypeId.invalid }" required >
                              <mat-option *ngFor="let item of AuthorizationNotificationType" [value]="item.authorizationNotificationTypeId" >{{item.typeName}}</mat-option>
                              </mat-select>
                              <mat-error *ngIf="insAuthorization.submitted && AuthorizationTypeId.invalid">
                                <mat-error *ngIf="AuthorizationTypeId.errors.required">Authorization Type is required</mat-error>
                              </mat-error>
                            </mat-form-field>
                          
                             
                          </div>
                      </div>
                  </div> -->

                  <!-- <div class="col-md-2 " *ngIf="isNoOfVisits">
                    <div class="example-form">
                        <mat-form-field class="example-full-width w-100">
                            <mat-label>Number of Visits Allowed</mat-label>
                            <input matInput placeholder="" step="0" maxlength="5" numeric
                              [(ngModel)]="insAuthorizationDtl.NumberOfVisitsNotification" #NumberOfVisitsNotification="ngModel" name="NumberOfVisitsNotification"
                               [ngClass]="{ 'is-invalid': insAuthorization.submitted && NumberOfVisits.invalid }"
                               required
                                 />
                                 <mat-error *ngIf="insAuthorization.submitted && NumberOfVisitsNotification.invalid">
                                  <mat-error *ngIf="NumberOfVisitsNotification.errors.required">Number of visits is required</mat-error>
                                </mat-error>
                        </mat-form-field>
                    </div>

                </div> -->

                  </div>
              </div>

              
              


          </div>
          <div class="col-md-12"   >
              <div class="text-right">
                  <button type="submit" form="insAuthorization"  mat-stroked-button *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                      class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{isEditInsAuth? 'Update' : 'Save'}}</button>
                  <button type="button" (click)="Oncancel()" mat-stroked-button
                      class="btn btn-cancel primary-btn-m h-40">Cancel</button>

              </div>
          </div>


      </div>
  </div>
</form>
</div> 
<div class="col-md-12 p-0" *ngIf="tmpInsAuthDataSource.length > 0">
  <div  class="lead-box">
    <div  class="clearfix w-100">
      <div  class="box-lead-head float-left">Authorization Details</div>
      <div  class="float-right  d-none">
        <button  type="button" title="Add Insurance" class="btn add-btn " (click)="Oncancel()">
          <i  class="fas fa-plus mr-2"></i>ADD Authorization Details</button>
         </div>
</div>
<div  class="col-md-12 table-custom p-0">
  <div  class="table-responsive" >
      <table mat-table [dataSource]="insAuthDataSource" class="mat-elevation-z8 w-100" matSort>

          <ng-container matColumnDef="InsuranceName">
            <th mat-header-cell *matHeaderCellDef>Insurance </th>
            <td mat-cell *matCellDef="let element">{{element.InsuranceName}}</td>
          </ng-container>

          <ng-container matColumnDef="AuthorizationNumber">
            <th mat-header-cell *matHeaderCellDef> Authorization Number </th>
            <td mat-cell *matCellDef="let element">{{(element.AuthorizationNumber==0? '' : element.AuthorizationNumber)}}</td>
          </ng-container>
          <ng-container matColumnDef="StartDate">
            <th mat-header-cell *matHeaderCellDef> Effective Date </th>
            <td mat-cell *matCellDef="let element">{{element.StartDate | date:'MM/dd/yyyy'}}</td>
          </ng-container>
          <ng-container matColumnDef="EndDate">
            <th mat-header-cell *matHeaderCellDef>Expiration Date </th>
            <td mat-cell *matCellDef="let element"> {{element.EndDate | date:'MM/dd/yyyy'}} </td>
          </ng-container>
          <ng-container matColumnDef="NumberOfVisits">
              <th mat-header-cell *matHeaderCellDef># of visits allowed </th>
              <td mat-cell *matCellDef="let element">{{element.NumberOfVisits}}  </td>
            </ng-container>
            <ng-container matColumnDef="TotalAppointments">
              <th mat-header-cell *matHeaderCellDef>Appts </th>
              <td mat-cell *matCellDef="let element"> {{element.TotalAppointments}} </td>
            </ng-container>
            <ng-container matColumnDef="ClinicalInformationProvider">
              <th class="w30px" mat-header-cell *matHeaderCellDef>Clinical Information Provided</th>
              <td class="w30px" mat-cell *matCellDef="let element"> {{element.ClinicalInformationProvider}} </td>
            </ng-container>

          <ng-container matColumnDef="actions"  >
            <th mat-header-cell *matHeaderCellDef>Actions </th>
            <td mat-cell *matCellDef="let element;let i = index;" class=""  >
              <button type="button" mat-icon-button matTooltip="Edit" type="button" 
                (click)="onEditInsuranceAuth(element)" color="primary">
                <img src="../../../assets/images/editblue.png">
              </button>
              <button type="button" mat-icon-button matTooltip="Delete" type="button"
                (click)="ondeleteInsuranceAuth(element)" color="warn">
                <img src="../../../assets/images/deletered.png">
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="insuraceColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: insuraceColumns;"></tr>
        </table>

  </div>
  </div>
  </div>
  </div> 

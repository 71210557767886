<div class="col-md-12">
  <div class="d-flex met-info-top w-100">
    <i class="fas fa-user"></i>
    <div class="mr-4">
      Client Name
      <span class="imp-info">
        {{patientName}}

      </span>
    </div>
    <div class="mr-4">
      Appointment Date
      <span class="imp-info">{{StartDate | date:'MM/dd/YYYY'}}</span>
    </div>
    <div class="mr-4"> Appointment Time   <span class="imp-info">{{StartTime | date:'hh:mm a'}}</span></div>
    <div class="mr-4">
    </div><div class="ml-auto">
    </div>
  </div>
  <div>
    <div class="box-lead-head mb-2 fntsize hdr">MEDICATION INFORMED CONSENT FOR CHILDREN</div>
    <button class="btn primary-btn-outlined-small ng-star-inserted float-right mr-2 gntpdf_btn" (click)="onGeneratePDF()">GeneratePDF</button>
  </div>
  <form [formGroup]="medicationForm" (ngSubmit)="saveconsent()" class="formwidth mx-2">
    <button class="btn primary-btn-outlined-small ng-star-inserted float-right mr-2 sve_btn">Save</button>
    <div style="margin-top: 30px;">
      <p>
        My psychiatrist and/or advanced practice nurse has reviewed with me the benefits and risks of taking and
        not taking the following medications:
      </p>
    </div>
    <div class="medication-dosage">
      <div class="row inputElement" *ngIf="medicationDetails.length > 0">
        <div class="col-md-3"><label>Medications</label></div>
        <div class="col-md-3"><label>Dosage</label></div>
        <div class="col-md-3"><label>Frequency</label></div>
        <div class="col-md-3"><label>Side Effects</label></div>
      </div>
      <div formArrayName="medicationDetails">
        <div class="loop-section position-relative mb-2"
             *ngFor="let med of medicationDetails.controls; let i = index" [formGroupName]="i">
          <div class="row  inputElement">
            <div class="col-md-3">

              <input class="form-control" placeholder="Medications" type="text"
                     formControlName="medications" maxlength="50" />
            </div>
            <div class="col-md-3">

              <input class="form-control" placeholder="Dosage" type="text"
                     formControlName="dosage" maxlength="30" />
            </div>
            <div class="col-md-3">
              <select class="form-control" formControlName="frequency">
                <option [ngValue]="undefined">--Select Frequency--</option>
                <option [ngValue]="'1'">q.a.m. Every morning</option>
                <option [ngValue]="'2'">q.p.m. Every evening</option>
                <option [ngValue]="'3'">q.h.s Every night at bedtime</option>
                <option [ngValue]="'4'">b.i.d. Twice daily</option>
                <option [ngValue]="'5'">t.i.d. 3 times a day</option>
                <option [ngValue]="'6'">q.i.d 4 times a day</option>
                <option [ngValue]="'7'">q.o.d Every other day</option>
                <option [ngValue]="'8'">q.w. Every week</option>
                <option [ngValue]="'9'">p.r.n. Daily as needed</option>
              </select>
            </div>
            <div class="col-md-3">
              <input class="form-control" placeholder="Side Effects" type="text"
                     formControlName="sideEffects" maxlength="50" />
            </div>
          </div>
          <a class="add" style="cursor: pointer;" (click)="addMedicationInfo()">
            <i class="fa fa-lg fa-plus-circle"></i><span class="sr-only">Add</span>
          </a>
          <a class="remove" style="cursor: pointer;" (click)="removeclinicalinformation(med,i)">
            <i class="fa fa-lg fa-minus-circle"></i><span class="sr-only">Remove</span>
          </a>
        </div>
      </div>
      <div class="col-md-12 set1-temp" *ngIf="medicationDetails.length == 0">
        <div class=" col-md-12 row">
          <div class="col-md-6 p-0">
            <label class="additional">
              Medication history not available, would you like to
              add?
            </label>

          </div>
          <div class="col-md-4 p-0">
            <button type="button" (click)="addMedicationInfo()" mat-stroked-button
                    class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">
              Add
            </button>
          </div>

        </div>
      </div>
    </div>
    <div>
      <p>
        The dose, the schedule of dosing, potential side effects, and the potential interactions with alcohol and
        other nonprescription drugs were clearly explained to me. I have also received a detailed information
        sheet about this medication.
      </p>
      <p>
        I have had the opportunity to ask questions about this information and understand the benefits and risks
        of this treatment. I consent to taking the medication(s) listed above.
      </p>
    </div>

    <div class="form-row">
      <div class="label-container">
        <mat-label>Patient Signature</mat-label>
      </div>
      <div class="float-left p-0 mt-3 pt-1">
        <img id='esignPatient' height="50px" width="300px" formControlName="signatureImgPatient" style="border: 1px solid grey;">
      </div>
      <div class="float-left col-md-4 pr-2 pl-2">

        <button type="button" (click)="openDialogPatient()" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2">
          Add E-Sign
        </button>
      </div>
      <div class="label-container pt-2 pr-2">
        <mat-label>Date</mat-label>
      </div>
      <div class="input-container">
        <mat-form-field class="button-container">
          <input matInput [matDatepicker]="currentDatePatient" readonly
                 (click)="currentDatePatient.open()" formControlName="currentDatePatient"
                 name="currentDatePatient">
          <mat-datepicker-toggle matSuffix [for]="currentDatePatient">
          </mat-datepicker-toggle>
          <mat-datepicker #currentDatePatient></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div style="padding-top: 35px;clear: both;padding-bottom: 20px;">
      <span>{{patientName}}</span>
      <label for="client_acknowledge">
        ,the client acknowledges taking/not taking the above mentioned medication(s)
        and understands the benefits and risks of this treatment.
      </label>
    </div>
    <div class="form-row">
      <div class="label-container">
        <mat-label>Parent/Guardian Signature</mat-label>
      </div>
      <div class="float-left p-0 mt-3 pt-1">
        <img id='esignParent' height="50px" width="300px" formControlName="signatureImgParent" style="border: 1px solid grey;">
      </div>
      <div class="float-left col-md-4 pr-2 pl-2">

        <button type="button" (click)="openDialogParent()" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2">
          Add E-Sign
        </button>
      </div>
      <div class="label-container pt-2 pr-2">
        <mat-label>Date</mat-label>
      </div>
      <div class="input-container">
        <mat-form-field class="button-container">
          <input matInput [matDatepicker]="currentDateParent" readonly
                 (click)="currentDateParent.open()" formControlName="currentDateParent"
                 name="currentDateParent">
          <mat-datepicker-toggle matSuffix [for]="currentDateParent">
          </mat-datepicker-toggle>
          <mat-datepicker #currentDateParent></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div style="padding-top: 30px;clear: both;">
      <div style="padding-bottom: 30px;">
        <mat-checkbox formControlName="parentNotAvailable" id="parent_not_available">
          Parent/Guardian was not available in person due to emergent/urgent nature of clinical situations,
          medication informed consent was obtained by telephone; see clinical record for documentation of
          telephone consent.
        </mat-checkbox>
      </div>

      <div class="form-row">
        <div class="label-container">
          <mat-label>Physician/APN Signature</mat-label>
        </div>
        <div class="float-left p-0 mt-3 pt-1">
          <img id='esign' height="50px" width="300px" formControlName="signatureImgPhysician" style="border: 1px solid grey;">
        </div>
        <div class="float-left col-md-4 pr-2 pl-2">

          <button type="button" (click)="openDialog()" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2">
            Add E-Sign
          </button>
        </div>
        <div class="label-container pt-2 pr-2">
          <mat-label>Date</mat-label>
        </div>
        <div class="input-container">
          <mat-form-field class="button-container">
            <input matInput [matDatepicker]="currentDatePhysician" readonly
                   (click)="currentDatePhysician.open()" formControlName="currentDatePhysician"
                   name="currentDatePhysician">
            <mat-datepicker-toggle matSuffix [for]="currentDatePhysician">
            </mat-datepicker-toggle>
            <mat-datepicker #currentDatePhysician></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
<a mimetype="application/octet-stream" #downloadLink></a> 

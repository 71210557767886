export class Consent {
    treatmentConsentId: number;
    chmatreatmentSummaryId: number;
    providerDate: string;
    signingforminor: string;
    minorDate: string;
    psychiatristsSign: string;
    psychiatristsDate: string;
    isAgree: boolean;
    comments: string;
    clientSign: string;
    clientDate: string;
    parentSign: string;
    parentDate: string;
    referralcomments:string;
    dischargeGoal:string;
    goalResponse:string;
    staffInitial1:string;
    diagnosisProvider: string;
    sSignature:boolean;
    licenseNumber : string;
    SSignature:boolean;
    LicenseNumber : string;
    userName : string;
}
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'src/app/common/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeadService } from 'src/app/patient-lead/lead.service'
@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss']
})
export class LeadComponent implements OnInit {
  displayedColumns = ['position', 'name', 'weight', 'symbol']; // // for table
  dataSource: MatTableDataSource<any>;
  patientid: any;
  userName: any;
  constructor(private route: ActivatedRoute,
    public commonService: CommonService,
    private router: Router,
    public spinnerservice: NgxSpinnerService,
    public leadservice: LeadService,) { }

  ngOnInit(): void {
    this.spinnerservice.hide();
    this.userName = localStorage.getItem('username');
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        this.spinnerservice.hide();
        this.patientid =Number(this.commonService.FrontEndDecryption(params.id));

      }
    });
   // this.patientid = localStorage.getItem("patientId");
  }
  onLetsStarted() {
    this.spinnerservice.hide();
    this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(this.patientid) } });
    this.leadservice.postLetsStarted(this.patientid).subscribe(
      (res: any) => {
        

      },
      err => {    
        
      }

    );

  }
}

<div class="login-page">
<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div class="login-section">
        <div class="container-fluid">
            <div class="row no-gutter">
              <div class="d-none d-md-flex col-md-6 col-lg-9 bg-image"></div>
              <div class="col-md-6 col-lg-3">
                <div class="login d-flex align-items-top py-4">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-9 col-lg-12 mx-auto">
                        <div class="col-md-12 p-0 logo-img">
                            <img  class="img-fluid" src="../../assets/images/brand-logo.jpg" alt="">
                            </div>
                            <form [formGroup]="loginForm" class="col-md-12 col-mg-12 col-sm-12 col-xs-12 mt-4 p-0" autocomplete="off">
                                <mat-card-content class="mb-0">
                                    <div class="input-row">
                                        <mat-form-field class="example-full-width w-100">

                                            <input id="float-input" matInput placeholder="Username" (paste)="global.trimValue($event)" class="form-control cs-control"
                                                autocomplete="off" formControlName="UserName" name="UserName">
                                            <div *ngIf="submitted && f3.UserName.errors" class="invalid-feedback d-block">
                                                <div *ngIf="f3.UserName.errors.required || f3.UserName.errors.cannotContainSpace">Username
                                                    is
                                                    required</div>
                                            </div>

                                            <div *ngIf="submitted && f3.UserName.errors" class="invalid-feedback d-block">
                                                <div *ngIf="f3.UserName.errors.pattern ">Invalid Username</div>
                                            </div>
                                        </mat-form-field>
                                    </div>
                                    <div class="input-row">
                                        <mat-form-field class="example-full-width w-100">
                                            <div class="custom-password">
                                            <input id="float-input" placeholder="Password" matInput [type]="newPassEye ? 'password' : 'text'" autocomplete="off"
                                                class="form-control cs-control" formControlName="Password" name="Password">
                                                <i  [class]="newPassEye ? 'fa fa-fw fa-eye field-icon toggle-password' : 'fa fa-fw fa-eye-slash field-icon toggle-password'"  (click)="newPassEye=!newPassEye"></i>
                                            </div>
                                            <div *ngIf="submitted && f3.Password.errors" class="invalid-feedback d-block">
                                                <div *ngIf="f3.Password.errors.required">Password is required</div>
                                            </div>
                                            <div *ngIf="submitted && !f3.UserName.errors && !f3.Password.errors">
                                                <p class="invalid-feedback d-block" *ngIf="errormsg"> Incorrect Username or Password.</p>
                                            </div>
                                        </mat-form-field>
                                    </div>
                                </mat-card-content>
                                <div class="d-flex">
                                        <div class="checkbox rememberme mb-3 mr-auto ">
                                            <label>
                                            <input type="checkbox" value="remember-me" formControlName="RememberMe"> Remember me
                                            </label>
                                        </div>

                                        <div class="forgot-pass-link">
                                            <a  role="button" (click)="onforgotpwd()" class="forgot-pass">
                                                Forgot Password?
                                            </a>
                                        </div>
                                </div>
                                 <div class="btn-toolbar">
                                    <button class="login-btn mt-2 text-center" mat-stroked-button color="accent" type="submit" (click)="onSubmit()">Log
                                        in</button>
                                        <div class="text-center w-100 p-b-4">
                                           <!-- <span class="txt1">
                                            NEW USER
                                            </span>
                                             <a class="register-txt-link"  [routerLink]="['/register']">
                                            REGISTER HERE !
                                            </a> -->
                                        </div>

                                    <!-- <button mat-stroked-button color="accent" class="ml-1" type="button"
                                        [routerLink]="['/register']">Register</button> -->
                                    <!-- <a class="ml-1 mt-2 cursor-pointer" role="button" (click)="onforgotpwd()">Forgot Password?</a> -->
                                </div>
                            </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
    </div>

    <form [formGroup]="ForgetForm" class="col-md-12 col-mg-12 col-sm-12 col-xs-12" autocomplete="off">
        <div class="modal" id="myModal" [style.display]="showForgetPopup ? 'block' : 'none'">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Forgot Password? </h4>
                        <button type="button" title="Close" class="close" data-dismiss="modal"
                            (click)="hideForgetPopup()">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-4">
                            <span class="ui-float-label">
                                <input id="float-input" matInput class="form-control cs-control" (paste)="global.trimValue($event)" formControlName="email"
                                    name="email" >
                                <label for="float-input">Enter your email address</label>
                            </span>
                            <div *ngIf="resetSubmit && f4.email.errors" class="invalid-feedback d-block">
                                <div *ngIf="f4.email.errors.required">Email is required</div>
                            </div>
                            <div *ngIf="resetSubmit && f4.email.errors" class="invalid-feedback d-block">
                                <div *ngIf="f4.email.errors.pattern ">Email Username</div>
                            </div>
                            <div *ngIf="resetSubmit && showErrorPopup" class="invalid-feedback d-block">Email id doesn't
                                exist.</div>
                        </div>
                        <div>

                            <a class="btn primary-btn-filled primary-btn-m shadow-sm float-right h-40" (click)="ForgetPassword()">Proceed</a>
                            <a class="btn primary-btn-outlined primary-btn-m shadow-sm mr-2 float-right h-40" (click)="hideForgetPopup()">Cancel</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="modal" id="myModal" [style.display]="showForgetSuccessPopup ? 'block' : 'none'">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Forgot Password? </h4>
                    <button type="button" title="Close" class="close" data-dismiss="modal"
                        (click)="hideForgetSuccessPopup()">&times;</button>
                </div>
                <div class="modal-body">
                    Procedure to reset password is mailed to your registered mail id.
                    <div>
                        <a class="btn button-red text-white mr-2 ml-2 float-right" (click)="hideForgetSuccessPopup()">Ok</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    <!-- <h2>Login</h2>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="username">Username</label>
            <input type="text" formControlName="UserName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f3.UserName.errors }" />
            <div *ngIf="submitted && f3.UserName.errors" class="invalid-feedback">
                <div *ngIf="f3.UserName.errors.required">Username is required</div>
            </div>
        </div>
        <div class="form-group">
            <label for="password">Password</label>
            <input type="password" formControlName="Password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f3.Password.errors }" />
            <div *ngIf="submitted && f3.Password.errors" class="invalid-feedback">
                <div *ngIf="f3.Password.errors.required">Password is required</div>
            </div>
        </div>
        <div class="form-group">
            <button  class="btn btn-primary">Login</button>
            <a [routerLink]="['/register']" class="btn btn-link">Register</a>
        </div>
    </form> -->

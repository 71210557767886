
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { BillingService } from 'src/app/billing/billing.service';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import {transactionForm} from 'src/app/_models/_Billing/transactionForm';
import { NgForm } from '@angular/forms';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';

import * as moment from 'moment';
declare function decrypt(data): any;

@Component({
  selector: 'app-transaction-code',
  templateUrl: './transaction-code.component.html',
  styleUrls: ['./transaction-code.component.scss']
})
export class TransactionCodeComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['sno','TransCode', 'TtypeDescription', 'TypeofService', 
  'CptCode','RevenuCode' ,'Hcpcscode','Units','Amount', 'actions', 'Tdescription'];
  dataSource: MatTableDataSource<any>;
  @ViewChild('Transactionfrm') Transactionfrm: NgForm;
  dtoTransactionfrm: transactionForm;
  roleAccess: any;
  constructor(
    public billingservice: BillingService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, private route: ActivatedRoute,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService,public datepipe: DatePipe
    ,public roleaccessservice:PageAccessService
    ) { }
  
  selectedTabIndex: number;
    cpt:any;
    revenueCode:any;
  transactionType:any;
  typeofServices:any;
  hcpcsMaster:any;
  Units:any;
  Amount:any;
  transactionCodelists:any;
  isEdit=false;
  viewRightsMsg:any;

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.BillingMasters;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.viewRightsMsg=constantVariables.PagePermissionMsg.IsView;
    this.selectedTabIndex = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    if (isNaN(this.selectedTabIndex) || this.selectedTabIndex < 0) {
      this.selectedTabIndex = 1;
    }
    this.dtoTransactionfrm = new transactionForm();
    this.dtoTransactionfrm.IsCpt=false;
    this.dtoTransactionfrm.IsUB04=false;
    this.isEdit=false;
    this.getBillingMaster();
    this.bindGridData();

  }
  
  onCptUb04click(val){
    if(val==1){ 
      this.dtoTransactionfrm.IsCpt=true;
      this.dtoTransactionfrm.IsUB04=false;
      this.dtoTransactionfrm.RevenuCode=null;
      this.dtoTransactionfrm.HcpcsCode=null;
    }
    else if(val==2){ 
      this.dtoTransactionfrm.IsCpt=false;
      this.dtoTransactionfrm.IsUB04=true;
      this.dtoTransactionfrm.CptCode=null;
    }
     }
   getBillingMaster(){
    this.spinnerservice.show();
    this.billingservice.getBillMstr().subscribe(
      res => {
        if (res != null) {
         let result=decrypt(res.objInputString)
       //  console.log(result);
          this.cpt=result.Cptcode;
          this.transactionType=result.TransType;
          this.typeofServices=result.TypeofServices;
          this.revenueCode=result.RevenueCode;
          this.hcpcsMaster=result.HcpcsMaster;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  PostTransactionfrm(form: NgForm){
     this.spinnerservice.show();
      if (!form.valid) {
        this.spinnerservice.hide();
        return;
      }
    //console.log(this.dtoTransactionfrm);
    if(this.dtoTransactionfrm.IsUB04==false && this.dtoTransactionfrm.IsCpt==false)
    {
      this.spinnerservice.hide();
      this.snackbar.error("Select CPT Code or UB04");
      return;
    }
    if(this.dtoTransactionfrm.IsUB04==true && (this.dtoTransactionfrm.HcpcsCode==undefined || this.dtoTransactionfrm.RevenuCode==undefined ) )
    {
      this.spinnerservice.hide();
      this.snackbar.error("Select Revenue & HCPCS Code");
      return;
    }
    if(this.dtoTransactionfrm.IsCpt==true && this.dtoTransactionfrm.CptCode==undefined)
    {
      this.spinnerservice.hide();
      this.snackbar.error("Select CPT Code");
      return;
    }
     
    const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoTransactionfrm, false);
    this.billingservice.postTransactionfrm(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if(this.isEdit==true){
          this.snackbar.success("Transaction Code has been updated successfully");
        }
        else{this.snackbar.success(res);}
        this.Transactionfrm.resetForm();
         this.bindGridData();
       },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  
  bindGridData(){
   this.spinnerservice.show();
    this.dtoTransactionfrm.IsCpt=false;
    this.dtoTransactionfrm.IsUB04=false;
    this.billingservice.getTransactionLists().subscribe(
      res => {
        if (res != null) {
          this.spinnerservice.hide();
         let result=decrypt(res.objInputString)
          this.transactionCodelists=result;
          console.log(result);
          this.dataSource = new MatTableDataSource(this.transactionCodelists);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
          }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onListEdit(item)
  {
this.dtoTransactionfrm.TransCode=item.TransCode;
this.dtoTransactionfrm.TranType=item.TranType;
this.dtoTransactionfrm.IsCpt=item.IsCpt;
this.dtoTransactionfrm.IsUB04=item.IsUb04;
this.dtoTransactionfrm.CptCode=item.CptCode;
this.dtoTransactionfrm.RevenuCode=item.RevenuCode;
this.dtoTransactionfrm.HcpcsCode=item.Hcpcscode;
this.dtoTransactionfrm.TypesOfService=item.TypesOfService;
this.dtoTransactionfrm.Units=item.Units;
this.dtoTransactionfrm.Amount=item.Amount;
this.dtoTransactionfrm.Tdescription=item.Tdescription;

this.dtoTransactionfrm.UserId=localStorage.getItem('id');
this.isEdit=true;
  }

  CancelClick(){ 
    this.isEdit=false;
    this.Transactionfrm.resetForm();
    this.dtoTransactionfrm = new transactionForm();
    this.dtoTransactionfrm.IsCpt=false;
    this.dtoTransactionfrm.IsUB04=false;
    this.bindGridData();


  }
  searchTblVal(val){
  this.dataSource.filter = val.trim().toLocaleLowerCase();
}

}


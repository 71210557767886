import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { TaskService } from 'src/app/common/pages/task/task.service';
import { Global } from 'src/app/global/global.model';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Comment } from 'src/app/_models/_Common/task';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { constantVariables } from 'src/app/constant/constant';

@Component({
  selector: 'app-task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.scss']
})
export class TaskDialogComponent implements OnInit {
  isCreateTask: boolean = false;
  lstComment: any;
  patientInfoId: any;
  taskId: any;
  Decrptedlst:any;
  UserId: any;
  commentInfo: Comment;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  commentids: any = [];

  constructor(public dialogRef: MatDialogRef<TaskDialogComponent>,private snackbar: SnackBarService, public taskService: TaskService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService,private dialog: MatDialog, public spinnerservice: NgxSpinnerService, public global: Global,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {
    this.commentInfo = new Comment();
    this.patientInfoId = this.data.parentinfoId;
    this.taskId = this.data.taskId;
    this.getComment();
    this.UserId = Number(localStorage.getItem("id"));
  }

  onCreateTask(){
    this.isCreateTask = !this.isCreateTask;
  }

  resetnotesform() {
    this.commentInfo = new Comment();
    this.isCreateTask = false;
  }

  onPageChange($event) {
    this.lstComment = this.Decrptedlst.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }


  saveCommentInfo(form: NgForm) {
    this.commentInfo.patientInfoId = this.patientInfoId;
    this.commentInfo.taskId = this.taskId;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.commentInfo, false);
    this.taskService.postTaskComment(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        form.resetForm();
        this.snackbar.success(JSON.parse(res).message);
        this.getComment();
      }
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }

  onReadComment(item) {
    this.spinnerservice.show();
    this.taskService.readComment(item.taskCommentId).subscribe(
      res => {
        this.spinnerservice.hide();
        this.snackbar.success(res.message);
        this.getComment();


      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);

      },
    );
  }


  onReadmultipleComment() {
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.commentids, false);
    this.taskService.readmultipleComment(dataToEncrypt).subscribe(
      (res: any) => {
        this.commentids = [];
        this.snackbar.success(JSON.parse(res).message);
        this.getComment();
        this.commentids.hide();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);

      },
    );

  }

  getComment() {
    this.taskService.getComment(this.patientInfoId, this.taskId).subscribe(
      (res: any) => {
        if (res) {
          this.Decrptedlst = res;
          this.lstComment = this.Decrptedlst.slice(0, 5);
          console.log(this.lstComment)
          this.dataSource = new MatTableDataSource(this.Decrptedlst);
          this.dataSource.paginator = this.paginator;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }


  cbklistcomment(event, item, index) {
    if (event.checked) {
      this.commentids.push(item.taskCommentId);
    } else {
      this.commentids.forEach((x, index) => {
        if (x === item.taskCommentId) this.commentids.splice(index, 1);
      });
    }
  }




}

export class TreatmentSummaryFormInfo {
    treatmentSummaryId:Number;
    dischargeSummaryId:Number;
    patientAccountId:Number;
    progFromDate:any;
    progToDate:any;
    diagnosisCode:any;
    presentingProblem:string;
    treatmentProvided:string;
    treatmentGoals:string;
    strengths:string;
    observations:string;
    treatmentResponse:string;
    recommendations:string;
    staffInitials:string;
    signDate:any;
    patientName:string;
    dob:any;
    recommendationsToGoals:string;
    clinicianId:Number;
    staffInitial1:string;
    licenseNumber : string;
    sSignature : boolean;
}



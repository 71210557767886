<!-- <h2>Diagnosis Information</h2> -->
<div class="row w-100">
  <div class="col-md-12">

    <button type="submit" class="btn primary-btn-outlined-small ng-star-inserted float-right" (click)="generatePdf()">
      Generate PDF
    </button>
  </div>
</div>
<form (ngSubmit)="postTreatmentPlanDiagnosis(frmDiagnosis)" id="frmDiagnosis" autocomplete="off" #frmDiagnosis="ngForm"
  novalidate [ngClass]="{'disabled': isTreatmentDiagnosisFormDisabled}">
  <div class="info-top">
    <div class="col-md-12 p-0 ">
      <div class="row">
        <div class="col-md-12">
          <h2 class="diag-label mt-3 pr-0 mb-3"> Diagnosis</h2>
          <div class="basic-info diag-sec mb-3 row">
            <!-- <div class="col-md-2">
              <img class="img-fluid" src="../../../../../assets/images/diagnos2.png" />
            </div> -->

            <div class="col-md-4">

              <mat-form-field class="w-100">

                <mat-label>Chooose the Diagnosis Code for the Patient</mat-label>
                <mat-select multiple #DCode [(ngModel)]="_dtoDiagnosis.diagnosisCode" #diagnosisCode="ngModel"
                  name="diagnosisCode" required>
                  <input class="myInput" [(ngModel)]="filterBy" id="filterBy" name="filterBy" matInput focused="'true'"
                    type="text" autocomplete="off" placeholder="search" style="padding:14px">
                  <mat-option #matOption *ngFor="let item of ddldiagnosiscode | filter :filterBy" [value]="item.value"
                    (click)="onDiagnosisCodeselect(item,matOption.selected)" [matOption.selected]="item.isActive"
                    [matTooltip]="item.label">
                    {{item.label}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="frmDiagnosis.submitted && diagnosisCode.invalid">
                  <mat-error *ngIf="diagnosisCode.errors.required">Diagnosis is
                    required</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-8" *ngIf="_dtoDiagnosis.diagnosisCode?.length > 0">
              <div class="chip-container-content">
                <mat-chip-list aria-label="Fish selection">
                  <ng-container *ngFor="let item of ddldiagnosiscode">
                    <mat-chip *ngIf="item.isActive">{{item.label}}
                      <mat-icon matChipRemove (click)="onRemoveDiagnosisCode(item)">cancel</mat-icon>
                    </mat-chip>
                  </ng-container>
                </mat-chip-list>
              </div>
            </div>
            <!-- <div class="col-md-7">
              <textarea cols="2" class="w-100" maxlength="300" [(ngModel)]="_dtoDiagnosis.diagosisDetails"
                #diagosisDetails="ngModel" name="diagosisDetails" required></textarea>
              <mat-error *ngIf="frmDiagnosis.submitted && diagosisDetails.invalid">
                <mat-error class="mat-form-field-subscript-wrapper" *ngIf="diagosisDetails.errors.required">
                  Diagnosis details is
                  required</mat-error>
              </mat-error>
            </div> -->
          </div>
        </div>

        <div class="col-md-12 basic-info mb-3">
          <!-- <div class="basic-info mb-3 row pdatop"> -->
          <label class="treat-label">Presenting Problems</label>
          <!-- <div class="!(_dtoDiagnosis.presentingProblems)"> -->
          <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
                <textarea matInput appTextareaAutoresize class="w-100" cols="2" rows="4" maxlength="3000"
                [(ngModel)]="_dtoDiagnosis.presentingProblems" #presentingProblems="ngModel" name="presentingProblems"
                required></textarea> -->
                <app-word-counter-textarea [text]="_dtoDiagnosis.presentingProblems" [maxlength]="10000" [(ngModel)]="_dtoDiagnosis.presentingProblems" 
                (textChange)="_dtoDiagnosis.presentingProblems = $event" #presentingProblems="ngModel" name="presentingProblems" ngDefaultControl  required></app-word-counter-textarea>
             
                <mat-error *ngIf="frmDiagnosis.submitted && presentingProblems.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-4" *ngIf="presentingProblems.errors.required">Presenting Problems
                    is
                    required</mat-error>
                </mat-error>
              <!-- </mat-form-field> -->
            <!-- </div> -->
          <!-- </div> -->
        </div>

        <!-- <div class="col-md-12 basic-info mb-3">
              <label class="treat-label">Current DSM-V diagnosis (Write numeric code and description of diagnosis)</label>
              <div class="col-md-12 p-0"> -->
        <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
                  <textarea class="w-100" cols="2" rows="4" matInput appTextareaAutoresize maxlength="3000"
                    [(ngModel)]="_dtoDiagnosis.currentDsm" #currentDsm="ngModel" name="currentDsm"
                    required  ></textarea>
                
                </mat-form-field> -->
        <!-- <app-word-counter-textarea [text]="_dtoDiagnosis.currentDsm" [maxlength]="3000"  [(ngModel)]="_dtoDiagnosis.currentDsm" 
                (textChange)="_dtoDiagnosis.currentDsm = $event" #currentDsm="ngModel" name="currentDsm" ngDefaultControl required></app-word-counter-textarea>
                
                   
                    <mat-error *ngIf="frmDiagnosis.submitted && currentDsm.invalid">
                      <mat-error class="mat-form-field-subscript-wrapper mt-4" *ngIf="currentDsm.errors.required">Current DSM-V diagnosis
                        is
                        required</mat-error>
                    </mat-error>
              </div>
            </div> -->




        <!-- <div class="col-md-12 custom-radio">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="_dtoDiagnosis.majorProblemArea"
                #majorProblemArea="ngModel" name="majorProblemArea" required>
                <mat-radio-button [value]="1" class="mr-2">ADHD</mat-radio-button>
                <mat-radio-button [value]="2" class="mr-2">Depression</mat-radio-button>
                <mat-radio-button [value]="3" class="mr-2">SIB</mat-radio-button>
                <mat-radio-button [value]="4" class="mr-2">Anxiety</mat-radio-button>
                <mat-radio-button [value]="5" class="mr-2">ODD</mat-radio-button>
                  <mat-radio-button [value]="6" class="mr-2">OCD</mat-radio-button>
                  <mat-radio-button [value]="7" class="mr-2">Other</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="frmDiagnosis.submitted && majorProblemArea.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="majorProblemArea.errors.required">Major
                    problem
                    is
                    required</mat-error>
                </mat-error>
              </div> -->
        <div class="col-md-6">
          <div class="col-md-12 p-0 mb-3">
            <div class="form-group">
              <h2 class="diag-label col-md-12 mt-3 mb-3 mt-3 pr-0"> Major Problem Areas</h2>
              <div class="col-md-12 p-0">
                <div class="check-list-custom">
                  <!-- <mat-selection-list class="check-custom d-inline-block mr-2" 
                        [(ngModel)]="majorProblemArea1"
                        #majorProblemArea1="ngModel" name="majorProblemArea1"> -->
                  <mat-selection-list class="check-custom d-inline-block mr-2" required #majorProblemArea="ngModel"
                    name="majorProblemArea" [(ngModel)]="majorProblemArea1" [ngModelOptions]="{standalone: true}"
                    (selectionChange)="onSelection(majorProblemArea1)">
                    <mat-list-option [value]="a.key" [checked]="a.isActive" *ngFor="let a of majorProblemAreas">
                      {{a.text}}
                    </mat-list-option>
                  </mat-selection-list>
                  <mat-error *ngIf="isMajorProblemAreaError == true">
                    <mat-error class="mat-form-field-subscript-wrapper mt-4"
                      *ngIf="isMajorProblemAreaError == true">Major
                      problem area is
                      required</mat-error>
                  </mat-error>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div class="col-md-12 mt-3 mb-3" *ngIf="isOtherSelected">
          <label class="col-md-4 treat-label ">
            If other, please enter details
          </label>
          <!-- <mat-form-field class="example-full-width custom-textarea "> -->

          <!-- <textarea matInput appTextareaAutoresize class="w-100" cols="2" rows="4" maxlength="3000" 
                    [(ngModel)]="_dtoDiagnosis.majorProblem" #majorProblem="ngModel" name="majorProblem"
                    required  ></textarea> -->
          <app-word-counter-textarea [text]="_dtoDiagnosis.majorProblem" [maxlength]="3000" [name]="majorProblem"
            [(ngModel)]="_dtoDiagnosis.majorProblem" (textChange)="_dtoDiagnosis.majorProblem = $event"
            #majorProblem="ngModel" name="majorProblem" ngDefaultControl required></app-word-counter-textarea>

          <mat-error
            *ngIf="frmDiagnosis.submitted && (majorProblem == '' || majorProblem == null || majorProblem == undefined)">
            <mat-error class="mat-form-field-subscript-wrapper mt-3">Major
              problem description is
              required</mat-error>
          </mat-error>


          <!-- </mat-form-field> -->

        </div>


      </div>
    </div>
    <div class="col-md-12 p-0 mb-3 pb-3 border-bottom">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <label class="col-md-4 treat-label ">
              Suicidal Ideation
            </label>

            <div class="col-md-8 align-middle mb-2">
              <div class="custom-radio">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="_dtoDiagnosis.isSuicidal"
                  #isSuicidal="ngModel" name="isSuicidal" required>
                  <mat-radio-button [value]="true" class="mr-2">Yes</mat-radio-button>
                  <mat-radio-button [value]="false" class="mr-2">No</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="frmDiagnosis.submitted && isSuicidal.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="isSuicidal.errors.required">Suicidal
                    Ideation
                    is
                    required</mat-error>
                </mat-error>
              </div>

            </div>
          </div>
          <div class="alert alert-warning float-left w-100 Suicidal-alert" role="alert"
            [ngClass]="{'d-none':_dtoDiagnosis.isSuicidal,'d-block':!_dtoDiagnosis.isSuicidal}">
            No Suicidal Ideation.
          </div>
          <div [ngClass]="{'d-block':_dtoDiagnosis.isSuicidal,'d-none':!_dtoDiagnosis.isSuicidal}">
            <div class="row">
              <label class=" col-md-4 treat-label mt-2">
                Attempt
              </label>
              <div class="col-md-8 align-middle mb-2">
                <app-word-counter-textarea [text]="_sudicialthoughts.Attempt" [maxlength]="3000" [name]="Attempt"
            [(ngModel)]="_sudicialthoughts.Attempt" (textChange)="_sudicialthoughts.Attempt = $event"
            #Attempt="ngModel" name="Attempt" ngDefaultControl ></app-word-counter-textarea>
                <!-- <input type="text" maxlength="1000" class="form-control" [(ngModel)]="_sudicialthoughts.Attempt"
                  #sAttempt="ngModel" name="sAttempt"> -->

              </div>
            </div>
            <div class="row">
              <label class=" col-md-4 treat-label mt-2">
                Intent
              </label>
              <div class="col-md-8 align-middle mb-2">
                <app-word-counter-textarea [text]="_sudicialthoughts.Intent" [maxlength]="3000" [name]="Intent"
                [(ngModel)]="_sudicialthoughts.Intent" (textChange)="_sudicialthoughts.Intent = $event"
                #Intent="ngModel" name="Intent" ngDefaultControl ></app-word-counter-textarea>
                <!-- <input type="text" maxlength="1000" class="form-control" [(ngModel)]="_sudicialthoughts.Intent"
                  #sIntent="ngModel" name="sIntent"> -->

              </div>
            </div>
            <div class="row">
              <label class=" col-md-4 treat-label mt-2">
                Plan
              </label>
              <div class="col-md-8 align-middle mb-2">
                <app-word-counter-textarea [text]="_sudicialthoughts.Plan" [maxlength]="3000" [name]="Plan"
                [(ngModel)]="_sudicialthoughts.Plan" (textChange)="_sudicialthoughts.Plan = $event"
                #Plan="ngModel" name="Plan" ngDefaultControl ></app-word-counter-textarea>
                <!-- <input type="text" maxlength="1000" class="form-control" [(ngModel)]="_sudicialthoughts.Plan"
                  #sPlan="ngModel" name="sPlan"> -->

              </div>
            </div>
            <div class="row">
              <label class=" col-md-4 treat-label mt-2">
                History
              </label>
              <div class="col-md-8 align-middle mb-2">
                <app-word-counter-textarea [text]="_sudicialthoughts.History" [maxlength]="3000" [name]="History"
                [(ngModel)]="_sudicialthoughts.History" (textChange)="_sudicialthoughts.History = $event"
                #History="ngModel" name="History" ngDefaultControl ></app-word-counter-textarea>
                <!-- <input type="text" maxlength="1000" class="form-control" [(ngModel)]="_sudicialthoughts.History"
                  #sHistory="ngModel" name="sHistory"> -->

              </div>

            </div>

          </div>

        </div>
        <div class="col-md-6">
          <div class="row">
            <label class=" col-md-4 treat-label">
              Homicidal Ideation
            </label>
            <div class="col-md-8 align-middle mb-2">
              <div class="custom-radio">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="_dtoDiagnosis.isHomicidal"
                  #isHomicidal="ngModel" name="isHomicidal" required>
                  <mat-radio-button [value]="true" class="mr-2">Yes</mat-radio-button>
                  <mat-radio-button [value]="false" class="mr-2">No</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="frmDiagnosis.submitted && isHomicidal.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="isHomicidal.errors.required">Homicidal
                    Ideation
                    is
                    required</mat-error>
                </mat-error>
              </div>

            </div>
          </div>
          <div class="alert alert-warning float-left w-100 Suicidal-alert" role="alert"
            [ngClass]="{'d-none':_dtoDiagnosis.isHomicidal,'d-block':!_dtoDiagnosis.isHomicidal}">
            No Homicidal Ideation.
          </div>
          <div [ngClass]="{'d-block':_dtoDiagnosis.isHomicidal,'d-none':!_dtoDiagnosis.isHomicidal}">
            <div class="row">
              <label class=" col-md-4 treat-label mt-2">
                Attempt
              </label>
              <div class="col-md-8 align-middle mb-2">
                <app-word-counter-textarea [text]="_homicidalthoughts.Attempt" [maxlength]="3000" [name]="Attempt"
                [(ngModel)]="_homicidalthoughts.Attempt" (textChange)="_homicidalthoughts.Attempt = $event"
                #Attempt="ngModel" name="Attempt" ngDefaultControl ></app-word-counter-textarea>
                <!-- <input type="text" maxlength="1000" class="form-control" [(ngModel)]="_homicidalthoughts.Attempt"
                  #hAttempt="ngModel" name="hAttempt"> -->

              </div>
            </div>
            <div class="row">
              <label class=" col-md-4 treat-label mt-2">
                Intent
              </label>
              <div class="col-md-8 align-middle mb-2">
                <app-word-counter-textarea [text]="_homicidalthoughts.Intent" [maxlength]="3000" [name]="Intent"
                [(ngModel)]="_homicidalthoughts.Intent" (textChange)="_homicidalthoughts.Intent = $event"
                #Intent="ngModel" name="Intent" ngDefaultControl ></app-word-counter-textarea>
                <!-- <input type="text" maxlength="1000" class="form-control" [(ngModel)]="_homicidalthoughts.Intent"
                  #hIntent="ngModel" name="hIntent"> -->

              </div>
            </div>
            <div class="row">
              <label class=" col-md-4 treat-label mt-2">
                Plan
              </label>
              <div class="col-md-8 align-middle mb-2">
                <app-word-counter-textarea [text]="_homicidalthoughts.Plan" [maxlength]="3000" [name]="Plan"
                [(ngModel)]="_homicidalthoughts.Plan" (textChange)="_homicidalthoughts.Plan = $event"
                #Plan="ngModel" name="Plan" ngDefaultControl ></app-word-counter-textarea>
                <!-- <input type="text" maxlength="1000" class="form-control" [(ngModel)]="_homicidalthoughts.Plan"
                  #hPlan="ngModel" name="hPlan"> -->

              </div>
            </div>
            <div class="row">
              <label class=" col-md-4 treat-label mt-2">
                History
              </label>
              <div class="col-md-8 align-middle mb-2">
                <app-word-counter-textarea [text]="_homicidalthoughts.History" [maxlength]="3000" [name]="History"
                [(ngModel)]="_homicidalthoughts.History" (textChange)="_homicidalthoughts.History = $event"
                #History="ngModel" name="History" ngDefaultControl ></app-word-counter-textarea>
                <!-- <input type="text" maxlength="1000" class="form-control" [(ngModel)]="_homicidalthoughts.History"
                  #hHistory="ngModel" name="hHistory"> -->

              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
    <div class="col-md-12 set6-temp mt-2 mb-3 pb-3 border-bottom">
      <div class=" row p-1">
        <div class="col-1 p-3">
          <h2 class="diag-label  mb-3 mt-3 pr-0"> Medication</h2>
        </div>
        <div class="row d-flex align-items-center pl-5 pb-2">
          <div>
            <a class="btn btn-sm" title="Add Update" (click)="showCommentsPopupFun(1)">
              <img src="../../../assets/images/editblue.png">
              <!-- <i class="fas fa-edit mr-2"  style="color:rgb(255, 224, 0);font-size: 18px"></i> -->
            </a>
          </div>
          <div class="pt-1">
            <a class="btn btn-sm" title="View Medication" (click)="ViewComments(1)">
              <i class="fas fa-history mr-2" style="color:rgb(10, 10, 133);font-size: 20px"></i>
            </a>
          </div>
        </div>

      </div>

      <div class="row" *ngIf="_dtoMedications.length > 0 || isAssigned">
        <div class="medication col-md-12 inputWrapper p-0">
          <div class="row inputElement">
            <div class="col-md-3"><label>Medications</label></div>
            <div class="col-md-3"><label>Dosage</label></div>
            <div class="col-md-3"><label>Frequency</label></div>
            <div class="col-md-3"><label>Side effects</label></div>
          </div>
          <div class="loop-section position-relative mb-2"
            *ngFor="let med of _dtoMedications;let i= index;let last=last">
            <div class="row  inputElement">
              <div class="col-md-3">
                <input class="form-control" maxlength="50" [(ngModel)]="med.medications" #medications="ngModel"
                  name="medications{{i}}" placeholder="Medicine" type="text" required>
                <mat-error *ngIf="frmDiagnosis.submitted && medications.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="medications.errors.required">Medicine
                    is
                    required</mat-error>
                </mat-error>
              </div>
              <div class="col-md-3"><input class="form-control" maxlength="30" [(ngModel)]="med.dosage"
                  #dosage="ngModel" name="dosage{{i}}" placeholder="Dosage" type="text" required>
                <mat-error *ngIf="frmDiagnosis.submitted && dosage.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="dosage.errors.required">Dosage is
                    required</mat-error>
                </mat-error>
              </div>
              <div class="col-md-3">
                <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                  [(ngModel)]="med.frequency" #frequency="ngModel" name="frequency{{i}}" required>
                  <option value="null">--Select Frequency--</option>
                  <option value="1">q.a.m. Every morning</option>
                  <option value="2">q.p.m. Every evening</option>
                  <option value="3">q.h.s Every night at bedtime</option>
                  <option value="4">b.i.d. Twice daily</option>
                  <option value="5">t.i.d. 3 times a day</option>
                  <option value="6">q.i.d 4 times a day</option>
                  <option value="7">q.o.d Every other day</option>
                  <option value="8">q.w. Every week</option>
                  <option value="9">p.r.n. Daily as needed</option>
                </select>
                <mat-error *ngIf="frmDiagnosis.submitted && frequency.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="frequency.errors.required">Frequency
                    is
                    required</mat-error>
                </mat-error>
              </div>
              <div class="col-md-3"><input class="form-control" maxlength="50" [(ngModel)]="med.sideEffects"
                  #sideEffects="ngModel" name="sideEffects{{i}}" required placeholder="Sideeffects" type="text">
                <mat-error *ngIf="frmDiagnosis.submitted && sideEffects.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="sideEffects.errors.required">Side
                    Effects is
                    required</mat-error>
                </mat-error>
              </div>
            </div>
            <a class="add" style="cursor: pointer;" *ngIf="last" (click)="addMedicationInfo()"><i
                class="fa fa-lg fa-plus-circle"></i><span class="sr-only">Add</span></a>
            <a class="remove" style="cursor: pointer;" (click)="removeclinicalinformation(med,i)"><i
                class="fa fa-lg fa-minus-circle"></i><span class="sr-only">Remove</span></a>
          </div>

        </div>
      </div>
      <div class="col-md-12 set1-temp" *ngIf="_dtoMedications.length == 0">
        <div class=" col-md-12 row">
          <div class="col-md-3 p-0">
            <!-- <label class="additional">Medication history not available, would you like to
                    add?</label> -->
            <label class="additional">No Medication Recorded</label>

          </div>
          <div class="col-md-4 p-0">
            <button type="button" (click)="addMedicationInfo()" mat-stroked-button
              class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Add</button>
          </div>

        </div>
      </div>
    </div>
    <div class="col-md-12 ">
      <div class=" row">
        <div class="col-md-2 pr-0">
          <h2 class="diag-label  mb-3 mt-3 pr-0"> Recommendations</h2>
        </div>

      </div>

      <div class="basic-info row mb-3">
        <label class="treat-label p-1 mrgn_leftquestion"> What is the recommended course/time frame of treatment? (How many days per week
          (PCP/
          IOP/Individual/Group/Family)</label>
        <div class="row pl-5">
          <div class="pr-4">
            <a title="Add Update" (click)="showCommentsPopupFun(2)">
              <img src="../../../assets/images/editblue.png">
            </a>
          </div>
          <div class="pt-0">
            <a title="View Recommended course" (click)="ViewComments(2)">
              <i class="fas fa-history mr-2" style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
          </div>
        </div>



        <div class="col-md-12 p-0">
          <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
            <textarea matInput appTextareaAutoresize class="w-100" cols="2" rows="4" maxlength="3000"
              [(ngModel)]="_dtoDiagnosis.recommendedCourse" #recommendedCourse="ngModel" name="recommendedCourse"
              required  ></textarea>
            
          </mat-form-field> -->
          <app-word-counter-textarea [text]="_dtoDiagnosis.recommendedCourse" [maxlength]="3000"
            [(ngModel)]="_dtoDiagnosis.recommendedCourse" (textChange)="_dtoDiagnosis.recommendedCourse = $event"
            #recommendedCourse="ngModel" name="recommendedCourse" ngDefaultControl required></app-word-counter-textarea>

          <mat-error *ngIf="frmDiagnosis.submitted && recommendedCourse.invalid">
            <mat-error class="mat-form-field-subscript-wrapper mt-3"
              *ngIf="recommendedCourse.errors.required">Recommended
              Course is
              required</mat-error>
          </mat-error>

        </div>
      </div>
    </div>
    <div class="col-md-12 p-0">

      <div class="basic-info row mb-3">
        <label class="treat-label p-1">Home Instruction Recommendations</label>
        <div class="row pl-5" >
          <div class="pr-4">
            <a title="Add Update" (click)="showCommentsPopupFun(3)">
              <img src="../../../assets/images/editblue.png" style="width: 20px; height: 20px;">
            </a>
          </div>
          <div class="pt-0">
            <a title="View Home Instruction Recommendations" (click)="ViewComments(3)">
              <i class="fas fa-history mr-2" style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
          </div>
        </div>


        <div class="col-md-12 p-0">
          <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
            <textarea class="w-100" cols="2" rows="4" matInput appTextareaAutoresize maxlength="3000"
              [(ngModel)]="_dtoDiagnosis.homeRecommendations" #homeRecommendations="ngModel" name="homeRecommendations"
              required  ></textarea>
          
          </mat-form-field> -->
          <app-word-counter-textarea [text]="_dtoDiagnosis.homeRecommendations" [maxlength]="3000"
            [(ngModel)]="_dtoDiagnosis.homeRecommendations" (textChange)="_dtoDiagnosis.homeRecommendations = $event"
            #homeRecommendations="ngModel" name="homeRecommendations" ngDefaultControl
            required></app-word-counter-textarea>

          <mat-error *ngIf="frmDiagnosis.submitted && homeRecommendations.invalid">
            <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="homeRecommendations.errors.required">Home
              Recommendations is
              required</mat-error>
          </mat-error>

        </div>
      </div>
    </div>
    <div class="col-md-12 p-0">

      <div class="basic-info row mb-3">
        <label class="treat-label p-1">Outside Providers Recommendations</label>
        <div class="row pl-5" >
          <div class="pr-4">
            <a title="Add Update" (click)="showCommentsPopupFun(4)">
              <img src="../../../assets/images/editblue.png" style="width: 20px; height: 20px;">
            </a>
          </div>
          <div class="pt-0">
            <a title="View Outside Providers Recommendations" (click)="ViewComments(4)">
              <i class="fas fa-history mr-2" style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
          </div>
        </div>

        <div class="col-md-12 p-0">
          <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
            <textarea matInput appTextareaAutoresize class="w-100" cols="2" rows="4" maxlength="3000"
              [(ngModel)]="_dtoDiagnosis.outsideRecommendations" #outsideRecommendations="ngModel"
              name="outsideRecommendations" required  ></textarea>
            
          </mat-form-field> -->
          <app-word-counter-textarea [text]="_dtoDiagnosis.outsideRecommendations" [maxlength]="3000"
            [(ngModel)]="_dtoDiagnosis.outsideRecommendations"
            (textChange)="_dtoDiagnosis.outsideRecommendations = $event" #outsideRecommendations="ngModel"
            name="outsideRecommendations" ngDefaultControl required></app-word-counter-textarea>

          <mat-error *ngIf="frmDiagnosis.submitted && outsideRecommendations.invalid">
            <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="outsideRecommendations.errors.required">
              Outside Recommendations is
              required</mat-error>
          </mat-error>

        </div>
      </div>
    </div>
    <div class="col-md-12 set2-temp p-0">
      <div class="row mb-2">
        <label class="col-md-12 treat-label">If there are other provisions involved in this episode of treatment, is
          there
          coordination of all services being rendered?
        </label>
        <div class="col-md-12 custom-radio">
          <mat-radio-group aria-label="Select an option" [(ngModel)]="_dtoDiagnosis.otherProvisions"
            #otherProvisions="ngModel" name="otherProvisions" required>
            <mat-radio-button [value]="true" class="mr-2">Yes</mat-radio-button>
            <mat-radio-button [value]="false" class="mr-2">No</mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="frmDiagnosis.submitted && otherProvisions.invalid">
            <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="otherProvisions.errors.required">This Field
              is
              required</mat-error>
          </mat-error>
          <div class=" treat-label mt-3 mb-2" *ngIf="_dtoDiagnosis.otherProvisions">If yes, who is the coordinator?
          </div>
          <div class="col-md-3 pl-0" *ngIf="_dtoDiagnosis.otherProvisions">
            <mat-form-field class="w-100">
              <mat-select [(ngModel)]="_dtoDiagnosis.coordinatorId" #coordinatorId="ngModel" name="coordinatorId"
                required>
                <mat-option *ngFor="let x of ddlPrimarystaff" [value]="x.staffId">
                  {{x.firstName}} {{x.lastName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="frmDiagnosis.submitted && coordinatorId.invalid">
                <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="coordinatorId.errors.required">
                  Co-ordinator is
                  required</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-12 p-3 sep-section">
          <div class="basic-info mb-2">
            <label class="treat-label">Outside Providers Invited/Participating in Treatment Meeting?</label>
            <div class="col-md-12 custom-radio p-0">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="_dtoDiagnosis.isOutsideProviders"
                #isOutsideProviders="ngModel" name="isOutsideProviders" required>
                <mat-radio-button [value]="1" class="mr-2">Yes</mat-radio-button>
                <mat-radio-button [value]="2" class="mr-2">No</mat-radio-button>
                <mat-radio-button [value]="3" class="mr-2">N/A</mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="frmDiagnosis.submitted && isOutsideProviders.invalid">
                <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="isOutsideProviders.errors.required">Is
                  Outside Providers is
                  required</mat-error>
              </mat-error>
            </div>
          </div>
          <div class=" treat-label mt-3 mb-2" *ngIf="_dtoDiagnosis.isOutsideProviders == 1">If yes, select the outside
            provider?
          </div>
          <div class="row">
            <div class="col-md-2" *ngIf="_dtoDiagnosis.isOutsideProviders == 1">
              <mat-form-field class="w-100">
                <mat-select [(ngModel)]="_dtoDiagnosis.providerId" #providerId="ngModel" name="providerId" required
                  (selectionChange)="onSelectionChanged()">
                  <mat-option *ngFor="let x of lstreportstaff" [value]="x.userId">
                    {{x.firstName}} {{x.lastName}}
                  </mat-option>
                  <mat-option [value]="-1">
                    Other
                  </mat-option>
                </mat-select>

              </mat-form-field>
              <mat-error *ngIf="frmDiagnosis.submitted && providerId.invalid">
                <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="providerId.errors.required">
                  Provider is
                  required</mat-error>
              </mat-error>


            </div>
            <div class="col-md-2" *ngIf="_dtoDiagnosis.providerId==-1 && _dtoDiagnosis.isOutsideProviders == 1">
              <mat-form-field class="w-100">
                <mat-label>Other provider name</mat-label>
                <input matInput [(ngModel)]="_dtoDiagnosis.providerName" #providerName="ngModel" name="providerName"
                  (change)="OnProviderNameChnage($event.target.value)" />
              </mat-form-field>
            </div>
          </div>


        </div>
        <!-- <h2>Modality</h2>
        <div class="col-md-12 set6-temp mt-2 mb-3 p-0 ">
          <div class="row">
            <div class="medication col-md-12 inputWrapper p-0">
              <div class="row inputElement col-md-9">
                <div class="col-md-5"><label>Therapy</label></div>
                <div class="col-md-7"><label>Frequency</label></div>
              </div>
              <div class="loop-section position-relative mb-2 col-md-9"
                *ngFor="let data of _dtoModality;let i= index;let last=last">
                <div class="row  inputElement">
                  <div class="col-md-5">
                    <select class="form-control" [(ngModel)]="data.therapyId" #therapyId="ngModel" name="therapyId{{i}}"
                      required>
                      <option value="null">--Select Therapy--</option>
                      <option *ngFor="let a of ddlTherapy" value="{{a.TherapyId}}">
                        {{a.TherapyName}}
                      </option>
                    </select>
                    <mat-error *ngIf="frmDiagnosis.submitted && therapyId.invalid">
                      <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="therapyId.errors.required">Therapy
                        is
                        required</mat-error>
                    </mat-error>

                  </div>
                  <div class="col-md-7">
                    <select class="form-control" [(ngModel)]="data.frequencyId" #frequencyId="ngModel"
                      name="frequencyId{{i}}" required>
                      <option value="null">--Select Frequency--</option>
                      <option *ngFor="let a of ddlFrequency" value="{{a.FrequencyId}}">
                        {{a.FrequencyName}}
                      </option>
                    </select>
                    <mat-error *ngIf="frmDiagnosis.submitted && frequencyId.invalid">
                      <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="frequencyId.errors.required">
                        Frequency is
                        required</mat-error>
                    </mat-error>
                  </div>
                </div>
                <a class="add" style="cursor: pointer;" *ngIf="last" (click)="appendModality(1)"><i
                    class="fa fa-lg fa-plus-circle"></i><span class="sr-only">Add</span></a>
                <a class="remove" style="cursor: pointer;" *ngIf="i !=0" (click)="removemodality(data,i)"><i
                    class="fa fa-lg fa-minus-circle"></i><span class="sr-only">Remove</span></a>
              </div>

            </div>
          </div>
        </div> -->

      </div>
    </div>
    <div class="basic-info mb-3">
      <label class="treat-label">Clinical Challenges</label>
      <div class="col-md-12 p-0">
        <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
          <textarea matInput appTextareaAutoresize class="w-100" cols="2" rows="4" maxlength="3000"
            [(ngModel)]="_dtoDiagnosis.clinicalChallenges" #clinicalChallenges="ngModel"
            name="clinicalChallenges" required  ></textarea>
          
        </mat-form-field> -->
        <app-word-counter-textarea [text]="_dtoDiagnosis.clinicalChallenges" [maxlength]="3000"
          [(ngModel)]="_dtoDiagnosis.clinicalChallenges" (textChange)="_dtoDiagnosis.clinicalChallenges = $event"
          #clinicalChallenges="ngModel" name="clinicalChallenges" ngDefaultControl required></app-word-counter-textarea>

        <mat-error *ngIf="frmDiagnosis.submitted && clinicalChallenges.invalid">
          <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="clinicalChallenges.errors.required">
            Clinical Challenges is
            required</mat-error>
        </mat-error>

      </div>
    </div>
    <div class="col-md-12" *ngIf="roleAccess.isCreate||roleAccess.isEdit">
      <div class="text-right">
        <!-- <button type="button" form="frmDiagnosis" mat-stroked-button
        class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="postTreatmentPlanDiagnosis(frmDiagnosis,true)">Save as Draft</button> -->
        <button type="submit" form="frmDiagnosis" mat-stroked-button
          class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>
        <button type="button" form="frmDiagnosis" mat-stroked-button [routerLink]="['/clinician']"
          class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2">Cancel</button>
      </div>
    </div>
  </div>


</form>
<!-- Medication PopUp Starts -->
<div class="comment-popup re-schedule-popup blocktime-popup">
  <div class="modal" tabindex="-1" role="dialog" id="blockTime"
    [style.display]="showMedicationsPopup? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container" role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h6 class="modal-title">Medication History</h6>
          <div class="modal-closing "><a class="cursor-pointer" (click)="OncloseMedicationpopup()"><i
                class="fa fa-times"></i></a></div>
        </div>
        <div class="modal-body">
          <div class="row" *ngIf="_dtoMedicationsPopUp.length > 0 || isAssigned">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3 font-weight-bold"><label>Medications</label></div>
                <div class="col-md-2 font-weight-bold"><label>Dosage</label></div>
                <div class="col-md-2 font-weight-bold"><label>Frequency</label></div>
                <div class="col-md-2 font-weight-bold"><label>Side effects</label></div>
                <div class="col-md-3 font-weight-bold"><label>Updated date</label></div>
              </div>

              <div *ngFor="let med of _dtoMedicationsPopUp; let i = index; let last = last" class="row">
                <div class="col-md-3">
                  {{ med.medications }}
                </div>
                <div class="col-md-2">
                  {{ med.dosage }}
                </div>
                <div class="col-md-2">
                  {{ getFrequencyLabel(med.frequency) }}
                </div>
                <div class="col-md-2">
                  {{ med.sideEffects }}
                </div>
                <div class="col-md-3">
                  <!-- {{ med.updatedDate | date: 'MM/dd/yyyy' }} -->
                  {{ med.createdDate | date: 'MM/dd/yyyy' }}

                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 set1-temp" *ngIf="_dtoMedicationsPopUp.length === 0">
            <div class="col-md-12 row">
              <div class="col-md-6 p-0">
                <label class="additional">No Medication Recorded</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Medication PopUp Ends-->
<!-- Recommdations PopUp Starts -->
<div class="comment-popup re-schedule-popup Treatment-popup ">
  <div class="modal" tabindex="-1" role="dialog" id="blockTime"
    [style.display]="showRecommandationPopup? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container" role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h6 class="modal-title">{{ title }}</h6>
          <a class="cursor-pointer" (click)="OncloseRecommandationpopup()"><i class="fa fa-times"></i></a>
        </div>
        <div class="modal-body">
          <div class="row" *ngIf="_dtoComments.length > 0 || isAssigned">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-1 font-weight-bold"><label>S.No</label></div>
                <div class="col-md-6 font-weight-bold"><label>Updates</label></div>
                <div class="col-md-3 font-weight-bold"><label>Date</label></div>
                <div class="col-md-2 font-weight-bold"><label>Action</label></div>
              </div>

              <div *ngFor="let med of _dtoComments; let i = index; let last = last" class="row"
                style="margin-bottom: 10px;">
                <div class="col-md-1">
                  <label>{{ i + 1 }}</label>
                </div>
                <div class="col-md-6">
                  {{ med.commentDetails }}
                </div>
                <div class="col-md-3">
                  {{ med.createdDate | date: 'MM/dd/yyyy' }}
                </div>
                <div class="col-md-2">
                  <div >
                    <button type="button" mat-icon-button matTooltip="Edit" (click)="editCommentPopup(med.treatmentPlanCommentsId)" color="primary">
                      <img src="../../../assets/images/editblue.png">
                    </button>
                    <button mat-icon-button matTooltip="Delete" color="warn" (click)="ondeleteTreatmentPlanComments(med.treatmentPlanCommentsId)">
                      <img src="../../../assets/images/deletered.png">
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
            <div class="col-md-12 set1-temp" *ngIf="_dtoComments.length === 0">
              <div class="col-md-12 row">
                <div class="col-md-4 p-0">
                  <label class="additional"></label>
                </div>
                <div class="col-md-4 p-0">
                  <label class="additional">{{NoRecord}}</label>
                  <div class="col-md-4 p-0">
                    <label class="additional"></label>
                  </div>
                </div>
              </div>
            </div>
          
        </div>
      </div>
    </div>
  </div>


  <!-- Recommdations PopUp Ends-->

  <!--Comments PopUp Starts-->
  <div class="comment-popup re-schedule-popup Treatment-popup ">
    <div class="modal" tabindex="-1" role="dialog" id="blockTime" [style.display]="showCommentsPopup? 'block' : 'none'">
      <div class="modal-dialog mat-dialog-container" role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title">{{ Addtitle }}</h6>
            <a class="cursor-pointer" (click)="OncloseCommentpopup(notedialogfrm)"><i class="fa fa-times"></i></a>
          </div>
          <div class="modal-body">

            <form (ngSubmit)="notedialogfrm.form.valid  && postTreatmentPlanComments(notedialogfrm)" id="notedialogfrm"
              autocomplete="off" #notedialogfrm="ngForm" novalidate>
              <div class="col-md-12">
                <div class="row">
                 
                    <!-- <mat-form-field class="w-100">
                      <mat-label>Date</mat-label>
                      <input [matDatepicker]="dateComment" name="dateComment" (click)="dateComment.open()"
                        (dateChange)="onChangedosTo($event)" [(ngModel)]="_dtoTreatmetComments.commentUpdatedate"
                        #dateCommentModel="ngModel" required
                        [ngClass]="{ 'is-invalid': notedialogfrm.submitted && dateCommentModel.invalid }">
                      <mat-datepicker-toggle matSuffix [for]="dateComment" required
                        [ngClass]="{ 'is-invalid': notedialogfrm.submitted && dateCommentModel.invalid }"></mat-datepicker-toggle>
                      <mat-datepicker #dateComment></mat-datepicker>
                      <mat-error *ngIf="notedialogfrm.submitted && dateCommentModel.invalid">
                        <mat-error *ngIf="dateCommentModel.errors.required">Date is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                 -->
                 <mat-form-field class="w-100">
                  <mat-label class="">Date</mat-label>
                  <input matInput [matDatepicker]="pstpicker" readonly
                      (click)="pstpicker.open()" [(ngModel)]="_dtoTreatmetComments.commentUpdatedate"
                       name="commentUpdatedate" #commentUpdatedate="ngModel" required
                       [ngClass]="{ 'is-invalid': notedialogfrm.submitted && commentUpdatedate.invalid }"
                       >
                  <mat-datepicker-toggle matSuffix [for]="pstpicker">
                  </mat-datepicker-toggle>
                  <mat-datepicker #pstpicker></mat-datepicker>
                  <mat-error *ngIf="notedialogfrm.submitted && commentUpdatedate.invalid">
                    <mat-error *ngIf="commentUpdatedate.errors.required">Date is required</mat-error>
                  </mat-error>
              </mat-form-field>

                  <mat-form-field class="w-100">
                    <input matInput placeholder="Add Update" [(ngModel)]="_dtoTreatmetComments.commentsName"
                      #commentsName="ngModel" name="commentsName" autocomplete="new-password"
                      [ngClass]="{ 'is-invalid': notedialogfrm.submitted && commentsName.invalid }" required>

                    <mat-error *ngIf="notedialogfrm.submitted && commentsName.invalid">
                      <mat-error *ngIf="commentsName.errors.required">Update is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 text-right mt-2">

                    <button type="submit" mat-raised-button
                      class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>
                  </div>
                </div>
              </div>


            </form>

            <br />

            <!-- <div class="row" *ngIf="_dtoComments.length > 0 || isAssigned">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-9 font-weight-bold"><label>{{ AddHeader }}</label></div>
                <div class="col-md-3 font-weight-bold"><label>Updated date</label></div>
                            </div>
          
              <div *ngFor="let med of _dtoComments; let i = index; let last = last" class="row">
                <div class="col-md-9">
                  {{ med.commentDetails }}
                </div>               
                <div class="col-md-3">
                 {{ med.createdDate | date: 'MM/dd/yyyy' }}

                </div>
              </div>
            </div>
          </div>  -->
            <!-- <div class="col-md-12 set1-temp" *ngIf="_dtoComments.length === 0">
            <div class="col-md-12 row">
              <div class="col-md-6 p-0">
                <label class="additional">No Recorded found</label>
              </div>
            </div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Ends-->

  <a mimetype="application/octet-stream" #downloadLink></a>
<div class="col-md-12 Treatment-list pl-5">
  <div class="top-btn-section clearfix mb-2">
    <h2 class="head-section mb-2 mt-2">Treatment</h2>
    <div class="ml-auto">
      <span class="filter-icon mr-2">
        <i class="fa fa-filter"></i>
      </span>
      <div class="filter-group d-inline-block">
        <label class="d-inline-block mr-2">Status</label>
        <select
          class="form-control d-inline-block status-filter"
          (change)="onchangeFilter($event.target.value)"
        >
          <option value="Any">Any</option>
          <option value="New">New</option>
          <option value="InProgress">In Progress</option>
        </select>
      </div>
      <div class="filter-group-search d-inline-block">
        <div class="search">
          <input
            type="text"
            placeholder="Search By Client Name"
            name=""
            class="search-input"
            [(ngModel)]="srchTreatmnPlanpatientListtxt"
            (focusout)="OnSearchPatient()"
          /><a class="search-icon"><i class="fa fa-search"></i></a>
        </div>
      </div>
    </div>
    <!--New-->

    <!--New End-->
  </div>
  <ul class="col-md-12 nomargin">
    <ng-container *ngFor="let item of lstTreatment; let i = index">
      <li
        class="col-md-12 p-0"
        [ngClass]="{
          'status-newd': item.statusId == 3,
          'status-inprog': item.statusId == statusinProgress
        }"
      >
        <div class="card mb-2">
          <div class="card-body p-0">
            <div class="row treat-list-pad">
              <div class="col-md-3">
                <div class="py-2 position-relative">
                  <div class="pl-3 d-inline-block align-middle">
                    <a data-abc="true" class="text-decoration-none"
                      ><span class="w-40 avatar gd-primary">{{
                        global.getCharUsername(item.patientName)
                      }}</span></a
                    >
                  </div>
                  <div
                    class="pl-3 d-inline-block align-middle"
                    style="width: 64%"
                  >
                    <a *ngIf="!item.preferredName"
                      data-abc="true"
                      class="item-author text-color"
                      (click)="onTreatmentPlan(item)"
                      >{{ item.patientName }} - {{ item.dob }} yrs </a
                    >
                    <a *ngIf="item.preferredName"
                    data-abc="true"
                    class="item-author text-color"
                    (click)="onTreatmentPlan(item)"
                    >{{ item.patientName }} - {{ item.dob }} yrs ({{ item.preferredName}})</a
                  >
                    <div>
                      <span class="status-patient"> Preferred Pronouns:</span><span class="name-size"> {{item.preferredPronouns}}</span>
                    </div>
                    <div class="patient-details">
                      <!-- <h5 class=""></h5> -->
                      <h5>
                        <i class="fas fa-envelope mr-1"></i>{{ item.email }}
                      </h5>
                      <h5 class="mb-0">
                        <i class="fas fa-phone-alt"></i>+1
                        {{ item.contactNo | mask : "(000) 000-0000" }}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-1">
                <div class="py-2 position-relative">
                  <!-- <div class="sub-except text-muted text-sm h-1x">Assigned To</div>
                                    <span class="text-color">{{item.staffName}}</span> -->
                  <div class="sub-except text-muted text-sm h-1x mt-2">
                    Severity level
                  </div>
                  <span
                    class="treatmentplan-severity"
                    [ngStyle]="{ background: item.severityCode }"
                    *ngIf="item.severity != null"
                    >{{ item.severity }}</span
                  >
                </div>
              </div>
              <div class="col-md-1">
                <div class="py-2 position-relative">
                  <!-- <div class="sub-except text-muted text-sm h-1x">Assigned To</div>
                                    <span class="text-color">{{item.staffName}}</span> -->
                  <div class="sub-except text-muted text-sm h-1x mt-2">
                    Mindset
                  </div>
                  <span class="text-color mr-2"></span>
                  <span class="text-color mr-2">{{ item.mindSet }}</span>
                </div>
              </div>
              <div class="col-md-2">
                <div class="py-2 position-relative">
                  <!-- <div class="sub-except text-muted text-sm h-1x">Assigned To</div>
                                    <span class="text-color">{{item.staffName}}</span> -->
                  <div class="sub-except text-muted text-sm h-1x mt-2">
                    Treatment Start Date
                  </div>
                  <span class="text-color">{{
                    item.treatmentPlanDate | date : "MM/dd/yyyy"
                  }}</span>
                </div>
              </div>
              <div class="col-md-1">
                <div class="py-2 position-relative">
                  <!-- <span class="text-color gp-color">{{item.appointmentType}}</span>
                            <div class="sub-except text-muted text-sm h-1x ">Appointment Type </div> -->
                  <span class="text-color"></span>
                  <div class="sub-except text-muted text-sm h-1x">
                    Program Status
                  </div>
                  <div class="mr-2 status-section mt-2">
                    <span
                      class="badge"
                      [ngClass]="{
                        'badge-primary': item.statusId == 3,
                        'badge-warning': item.statusId == statusinProgress,
                        'badge-success': item.statusId == 32
                      }"
                      >{{ item.status }}</span
                    >
                  </div>
                </div>
              </div>

              <div class="col-md-4 text-right">
                <div class="py-2 clearfix">
                  <div class="result_action_btn d-flex float-left w-100 mt-2">
                    <div class="appointment-action">
                      <div class="btn-section-list icon-listing">
                        <span class="border-right main-con-cont mr-2">
                          <a
                            class="btn btn-sm mr-2 main-icon"
                            (click)="onEditTreatmentPlan(item)"
                            title="Create Treatment Plan"
                            *ngIf="item.statusId != 27 && item.statusId != 32"
                          >
                            <i class="fas fa-plus-circle color-plus-circle"></i>
                          </a>
                          <a
                            class="btn btn-sm mr-2 main-icon"
                            (click)="onEditTreatmentPlan(item)"
                            *ngIf="item.statusId == 27 || item.statusId == 32"
                            title="Edit Treatment Plan"
                          >
                            <i class="far fa-edit color-edit-icon"></i>
                          </a>
                          <!--<a
                                        class="btn btn-sm  mr-2 main-icon"  title="View Treatment Plan">
                                    <i class="fas fa-eye"></i>
                                    </a> -->
                        </span>

                        <a
                          class="btn btn-sm mr-2 main-icon"
                          (click)="onScheduleModality(item)"
                          *ngIf="
                            item.statusId == 32 &&
                            (roleId === roleFront || roleId === roleAdmin)
                          "
                          title="Schedule Modality{{ roleId }}"
                        >
                          <i class="far fa-clock color-clock-icon"></i>
                        </a>
                        <!-- <a class="btn btn-sm  mr-2" title="Generate Intake" [ngClass]="MenuAccess.GenerateIntake?'':'disabled'"
                                                    (click)="onGenerateIntake(item)" ><i
                                                        class="fas fa-notes-medical mr-2"></i></a>
                                                <a class="btn btn-sm  " title="Pain Assessment"
                                                    (click)="onPainAssessment(item)" [ngClass]="MenuAccess.PainAssessment?'':'disabled'"><i
                                                        class="fas fa-user-injured mr-2"></i></a>
                                                <a class="btn btn-sm  " title="Nutritional Question"
                                                    (click)="onNutritional(item)" [ngClass]="MenuAccess.NutritionalQuestion?'':'disabled'"><i
                                                        class="far fa-question-circle mr-2"></i>
                                                </a>

                                                <a class="btn btn-sm  mr-2" title="Prepare CHMA" [ngClass]="MenuAccess.PrepareCHMA?'':'disabled'"
                                                    (click)="onintakechma(item)" ><i class="fas fa-procedures " ></i></a>
                                                    <a title="Suicidal Risk Assessment" class="btn btn-sm mr-2" (click)="OnSuicidalRiskAssessment(item)" 
                                                    [ngClass]="MenuAccess.SuicidalRiskAssessment?'':'disabled'"><i class="fas fa-hand-holding-heart"></i></a> -->
                      
                                <a
                                class="btn btn-sm mr-2"
                                title="Communication Notes"
                                (click)="onmeetingnotes(item)"
                                [ngClass]="
                                  MenuAccess.TreatmentHistory ? '' : 'disabled'
                                "
                                  
                              >
                                <i class="fas fa-clipboard" style="color:rgb(114, 15, 109)"></i
                              ></a>
                      
                                                    <a
                          class="btn btn-sm mr-2"
                          title="Treatment History"
                          (click)="ontreatmenthistory(item)"
                         
                        >
                          <i class="fas fa-history color-history-icon"></i
                        ></a>

                        <a
                          class="btn btn-sm"
                          title="Activity Log"
                          (click)="getActivityLog(item)"
                          *ngIf="roleAccess.isView"
                        >
                          <i class="fas fa-user-clock color-clock-icon"></i>
                        </a>
                        <a
                          class="btn btn-sm"
                          title="Documents"
                          (click)="getDocuments(item)"
                        >
                          <i class="fas fa-file-upload color-upload-icon"></i>
                        </a>
                        <a
                        class="btn btn-sm"
                        title="Notes"
                        (click)="getNotes(item)"
                      >
                        <i class="far fa-sticky-note mr-2"></i>
                      </a>

                        <div class="btn btn-sm treatment-plan mr-2" *ngIf="item.isRejoiner == false">
                          <i
                            class="fa fa-ellipsis-v dropdown-toggle cursor-pointer"
                            id="menu1"
                            data-toggle="dropdown"
                          ></i>
                          <ul
                            class="dropdown-menu"
                            role="menu"
                            aria-labelledby="menu1"
                          >
                            <li role="presentation" class="w-100 float-left">
                              <a
                                [ngClass]="
                                  MenuAccess.GenerateIntake ? '' : 'disabled'
                                "
                                (click)="onGenerateIntake(item)"
                                >Intake Packet</a
                              >
                            </li>
                            <li role="presentation" class="w-100 float-left">
                              <a
                                role="menuitem"
                                tabindex="-1"
                                [ngClass]="
                                  MenuAccess.PrepareCHMA ? '' : 'disabled'
                                "
                                (click)="onintakechma(item)"
                                >Comprehensive MHA</a
                              >
                            </li>
                            <li role="presentation" class="w-100 float-left">
                              <a
                                role="menuitem"
                                tabindex="-1"
                                (click)="onNutritional(item)"
                                [ngClass]="
                                  MenuAccess.NutritionalQuestion
                                    ? ''
                                    : 'disabled'
                                "
                                >Nutritional Questionnaire</a
                              >
                            </li>
                            <li role="presentation" class="w-100 float-left">
                              <a
                                role="menuitem"
                                tabindex="-1"
                                (click)="onPainAssessment(item)"
                                [ngClass]="
                                  MenuAccess.PainAssessment ? '' : 'disabled'
                                "
                                >Pain Assessment</a
                              >
                            </li>
                            <li role="presentation" class="w-100 float-left">
                              <a
                                role="menuitem"
                                tabindex="-1"
                                (click)="OnSuicidalRiskAssessment(item)"
                                [ngClass]="
                                  MenuAccess.SuicidalRiskAssessment
                                    ? ''
                                    : 'disabled'
                                "
                                >Suicidal Risk Assessment</a
                              >
                            </li>
                          </ul>
                        </div>
                        <div class="btn btn-sm treatment-plan mr-2"  *ngIf="item.isRejoiner == true">
                          <i
                            class="fa fa-ellipsis-v dropdown-toggle cursor-pointer"
                            id="menu1"
                            data-toggle="dropdown"
                          ></i>
                          <ul
                            class="dropdown-menu"
                            role="menu"
                            aria-labelledby="menu1"
                          >
                            <li role="presentation" class="w-100 float-left">
                              <a
                                [ngClass]="
                                  MenuAccess.GenerateIntake ? '' : 'disabled'
                                "
                                (click)="onGenerateIntake(item)"
                                >Intake Packet 1</a
                              >
                            </li>
                            <li role="presentation" class="w-100 float-left">
                              <a
                                role="menuitem"
                                tabindex="-1"
                                [ngClass]="
                                  MenuAccess.PrepareCHMA ? '' : 'disabled'
                                "
                                (click)="onintakechma(item)"
                                >Comprehensive MHA 1</a
                              >
                            </li>
                            <li role="presentation" class="w-100 float-left">
                              <a
                                role="menuitem"
                                tabindex="-1"
                                (click)="onNutritional(item)"
                                [ngClass]="
                                  MenuAccess.NutritionalQuestion
                                    ? ''
                                    : 'disabled'
                                "
                                >Nutritional Questionnaire 1</a
                              >
                            </li>
                            <li role="presentation" class="w-100 float-left">
                              <a
                                role="menuitem"
                                tabindex="-1"
                                (click)="onPainAssessment(item)"
                                [ngClass]="
                                  MenuAccess.PainAssessment ? '' : 'disabled'
                                "
                                >Pain Assessment 1</a
                              >
                            </li>
                            <li role="presentation" class="w-100 float-left">
                              <a
                                role="menuitem"
                                tabindex="-1"
                                (click)="OnSuicidalRiskAssessment(item)"
                                [ngClass]="
                                  MenuAccess.SuicidalRiskAssessment
                                    ? ''
                                    : 'disabled'
                                "
                                >Suicidal Risk Assessment 1</a
                              >
                            </li>
                          </ul>
                        </div>


                      </div>
                      <!-- <div class="btn-section-list" >
                                        <a
                                            class="btn btn-sm  mr-2 action-accept-btn">
                                            <i class="fas fa-check"></i> Accept
                                        </a>
                                        item.statusType != 14 && -->
                      <!-- <a
                                            class="btn btn-sm  mr-2 action-Reject-btn">
                                            <i class="far fa-calendar-alt"></i> Reschedule
                                        </a>
                                    </div> -->

                      <!-- <a class="btn btn-sm action-btn mr-2" data-toggle="modal"
                                        data-target="#appt_details">
                                        <i class="far fa-eye"></i>
                                    </a>
                                    <a class="btn btn-sm action-btn mr-2" data-toggle="modal"
                                        data-target="#appt_details">
                                        <i class="far fa-edit"></i>
                                    </a>
                                    <a class="btn btn-sm action-btn mr-2" data-toggle="modal"
                                        data-target="#appt_details">
                                        <i class="fas fa-trash-alt"></i>
                                    </a> -->
                      <!-- <div class="btn-section-list" >


                                    </div>
                                    <div class="btn-section-list" >

                                    </div> -->
                    </div>
                    <!-- <div class="mr-2">
                                    <div class="item-action dropdown"> <a data-toggle="dropdown"
                                            class="text-muted" data-abc="true" aria-expanded="false"> <svg
                                                xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-more-vertical">
                                                <circle cx="12" cy="12" r="1"></circle>
                                                <circle cx="12" cy="5" r="1"></circle>
                                                <circle cx="12" cy="19" r="1"></circle>
                                            </svg> </a>
                                        <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"
                                            x-placement="bottom-end"
                                            style="position: absolute; transform: translate3d(16px, 22px, 0px); top: 0px; left: 0px; will-change: transform;">
                                            <!-- <a class="dropdown-item cursor-pointer" data-abc="true" *ngIf="item.statusType == 14" (click)="onGenerateIntake(item)">Generate Intake </a>
                                            <a class="dropdown-item cursor-pointer" data-abc="true" *ngIf="item.statusType == 14" (click)="onPainAssessment(item)">Pain Assessment </a>
                                            <a class="dropdown-item cursor-pointer" data-abc="true" *ngIf="item.statusType == 14" (click)="onNutritional(item)">Nutritional Question</a>
                                            <a class="dropdown-item cursor-pointer" data-abc="true" *ngIf="item.statusType == 14" (click)="onintakechma(item)">Prepare CHMA</a> -->
                    <!--<a class="dropdown-item cursor-pointer" data-abc="true">View
                                                Goals
                                            </a>
                                            <a class="dropdown-item cursor-pointer" data-abc="true">View
                                                Treatment Plan </a>
                                            <a class="dropdown-item cursor-pointer" data-abc="true">View
                                                Progress </a>
                                        </div>
                                    </div>
                                </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
  <div class="col-md-12" *ngIf="lstTreatment?.length === 0">
    <div class="list-note w-100 mt-2 mb-2 pt-4 pb-4 text-center">
      <div class="text-center">
        <p class="msg-nomeet">No Client Found</p>
      </div>
    </div>
  </div>
  <mat-paginator *ngIf = "lstTreatment?.length != 0"
    class="pagination pagination-large"
    (page)="onPageChange($event)"
    [pageSize]="20"
    [pageSizeOptions]="[ 10, 20, 50, 75, 100]"
  >
  </mat-paginator>
</div>
<!-- Modal content for Activity log-->
<div class="Activity-popup">
  <div
    class="modal"
    tabindex="-1"
    role="dialog"
    [style.display]="ShowActivity ? 'block' : 'none'"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Activity Log</h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="onCloseActivitylog()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="individual-threaphy-container">
            <div class="history-list col-md-12 p-0">
              <ul>
                <li *ngFor="let item of clinicianHistory; let i = index">
                  <div class="list-history col-md-14">
                    <div class="row">
                      <div class="col-md-1" *ngIf="item.roleId !== 4">
                        <img
                          class="img-fluid"
                          src="../../../assets/images/move2stage-icon.png"
                        />
                      </div>
                      <div class="col-md-1" *ngIf="item.roleId == 4">
                        <img
                          class="img-fluid"
                          src="../../../assets/images/move2stage-icon-green.png"
                        />
                      </div>
                      <div class="col-md-9">
                        <p class="sub-history mb-1"></p>
                        <p
                          class="sub-history2 mb-1"
                          innerHTML="{{ global.getContent(item.message) }}"
                        ></p>
                      </div>
                      <div class="col-md-2 text-right">
                        <p class="sub-history2 mb-1">
                          {{
                            global.globalUtctimeformat(item.createdOn)
                              | date : "MM/dd/yyyy"
                          }}
                        </p>
                        <p class="sub-history2 mb-1">
                          {{
                            global.globalUtctimeformat(item.createdOn)
                              | date : "hh:mm a"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Document upload popup-->
<!-- Modal -->
<div
  class="modal showdocspopup"
  role="dialog"
  [style.display]="ShowDocuments ? 'block' : 'none'"
>
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title w-100 float-left">
          Documents
          <button
            type="button"
            class="close float-right"
            (click)="closepopup()"
          >
            &times;
          </button>
        </h6>
      </div>
      <div class="modal-body">
        <div class="float-left w-100">
          <div class="w-100 d-flex justify-content-around mb-2">
            <button
              mat-raised-button
              class="primary-btn-filled"
              (click)="fileInput.click()"
            >
              Add Files
            </button>
            <input
              style="display: none"
              #attachments
              type="file"
              (change)="onFileChanged($event)"
              #fileInput
              multiple="true"
            />
            <div
              class="w-100 float-left mb-2 text-danger pl-2 mt-2"
              *ngIf="filesize"
            >
              File size should not exceed 10 Mb
            </div>
            <div
              class="w-100 float-left mb-2 text-danger pl-2 mt-2"
              *ngIf="filecount"
            >
              You are allowed to upload only 5 documents
            </div>
            <div class="doc-type">
              <mat-form-field class="w-100">
                <mat-label>Document Type</mat-label>
                <mat-select   [(ngModel)]="documentModuleTypeId" 
                name="documentModuleTypeId" (selectionChange)="isRequired = false">
                 <mat-option [title]="doc.documentName" *ngFor="let doc of ddlDocumentType" [value]="doc.documentModuleTypeId">{{doc.documentName}}</mat-option>
                </mat-select> 
               
             </mat-form-field>
             <!-- <mat-error *ngIf="isRequired"> <mat-error>Document type is required</mat-error>  </mat-error> -->
             <div class="error-message err-top" *ngIf="isRequired" >Document Type is required
            </div>
             
            </div>
          </div>
          <!--Documents will be shown here-->
          <div
            class="w-100 float-left"
            *ngFor="let selected of listOfFiles; let index = index"
          >
            <div class="filesectionloop mb-2">
              <span class="filenametext">{{ selected }}</span>
              <button
                mat-icon-button
                class="deleteicon"
                title="Delete"
                (click)="removeSelectedFile(index)"
              >
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-sm btn-danger" (click)="closepopup()">
          Close
        </button>
        <button type="button" class="btn-sm btn-success" (click)="savedocs()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<!--Add Treatment Notes-->

  <div class="notes-section">
    <div class="col-md-12 p-0">
      <div class="lead-box">
        <div *ngIf=isShowCalenderView> 
          <!-- <app-notes [noteslist]="selectItem"></app-notes>
          <app-notes [noteslist]="selectItem"></app-notes> -->
          <app-notes-add [noteslist]="selectItem"></app-notes-add>
          </div>
        
      </div>
    </div>
  </div>


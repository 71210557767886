import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { BillingService } from 'src/app/billing/billing.service';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import {PatientPaymentDetails} from 'src/app/_models/_Billing/payment';
import {DtoPatientCreditCardDetails} from 'src/app/_models/_Billing/payment';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';



declare function decrypt(data): any;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YY',
  },
  display: {
    dateInput: 'MM/YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PaymentDetailsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  //  displayedColumns = ['sno','CardHolderName','BankName','CardNumber','Cvv','ExpiryDate','PaymentTypeName','Comments'];
  displayedColumns = ['sno', 'CardHolderName', 'CardNumber', 'ExpiryDate', 'PaymentTypeName', 'Comments'];
  dataSource: MatTableDataSource<any>;

  @ViewChild('Paymentfrm') Paymentfrm: NgForm;
  @ViewChild('exppicker') datePicker: MatDatepicker<any>;
  roleAccess: any;
  constructor( public billingservice: BillingService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, private route: ActivatedRoute,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService,public datepipe: DatePipe
    ,public roleaccessservice:PageAccessService
  ) { }
  drpPaymentype:any;
  drpCreditcardtypes:any;
  showCreditCard:boolean=false;
  isSave:boolean=false;
  allPaymenttypeMasters:any;
  dtoPatientPaymentDetails : PatientPaymentDetails;
  date = moment(new Date());
 // dtoPatientCreditCardDetails: DtoPatientCreditCardDetails;
  patientInfoId;
  patientPaymentTypemasters:any;
  isEdit:boolean=false;
  currentdate;
  viewRightsMsg:any;
  ngOnInit(): void {
    this.patientInfoId = Number(localStorage.getItem("patientIdforpayment"));
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        if(params.id){
               //this.patientInfoId = this.commonService.FrontEndDecryption(params.id);
                this.patientInfoId = this.commonService.FrontEndDecryption(params.id).replace(/"/g, "");
        }
        
      }
    });
    this.dtoPatientPaymentDetails = new PatientPaymentDetails();
    this.dtoPatientPaymentDetails.PatientCreditCardDetails =new DtoPatientCreditCardDetails();
   this.dtoPatientPaymentDetails.PatientInfoId= this.patientInfoId;
    this.dtoPatientPaymentDetails.PatientCreditCardDetails= this.patientInfoId;
    this.currentdate = moment().format("YYYY-MM-DD HH:mm:ss")
    const pageid = constantVariables.pagelists.PaymentDetails;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.viewRightsMsg=constantVariables.PagePermissionMsg.IsView;
   this.getPaymentMaster();
   this.bindGridData();
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
  const ctrlValue = this.date;
   ctrlValue.month(normalizedMonthAndYear.month());
   ctrlValue.year(normalizedMonthAndYear.year());
   this.dtoPatientPaymentDetails.PatientCreditCardDetails.ExpiryDate = ctrlValue.toDate();
   datepicker.close();
 }

  getPaymentMaster(){
    this.spinnerservice.show();
    this.billingservice.getPaymentMstr().subscribe(
      res => {
        this.spinnerservice.hide();
         if(res.objInputString!=null)
          {
            this.allPaymenttypeMasters = this.commonService.decrypdata(res.objInputString);
            this.drpCreditcardtypes=this.allPaymenttypeMasters.filter(x=>x.IsshowninLead==false && x.ParentId==6);
            this.drpPaymentype= this.allPaymenttypeMasters.filter(x=>x.IsshowninLead==true);
            this.dtoPatientPaymentDetails.PaymentTypeId=1;
          //  console.log(this.dtoPatientPaymentDetails.PaymentTypeId);
            this.onPaymenttypeChange(this.dtoPatientPaymentDetails.PaymentTypeId);
           }
     
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onPaymenttypeChange(val)
  {this.dtoPatientPaymentDetails.PatientCreditCardDetails =new DtoPatientCreditCardDetails();
    if(val==1)
    {
      this.isSave=true;
      this.showCreditCard=true;
      this.dtoPatientPaymentDetails.PatientCreditCardDetails.PatientInfoId=this.patientInfoId;
      this.bindGridData();
    }
    else
    {
      this.isSave=false;
      this.showCreditCard=false;
      }
  }
  onSave()
  {
    if (this.dtoPatientPaymentDetails.PaymentTypeId==null || this.dtoPatientPaymentDetails.PaymentTypeId==undefined) {
      this.spinnerservice.hide();
      this.snackbar.error("Select payment type");
      return;
    } 
   // console.log(this.dtoPatientPaymentDetails)
    this.SavePatientPaymentTypeMaster();

  }
  OnSaveCreditcard(form:NgForm)
  {
    this.spinnerservice.show();
    if (!form.valid) {
       this.spinnerservice.hide();
       return;
     }
     if (this.dtoPatientPaymentDetails.PaymentTypeId==null || this.dtoPatientPaymentDetails.PaymentTypeId==undefined) {
      this.spinnerservice.hide();
      this.snackbar.error("Select payment type");
      return;
    } 
   
    this.dtoPatientPaymentDetails.IscreditCard=true;
   // console.log(this.dtoPatientPaymentDetails);
   // console.log(this.dtoPatientPaymentDetails.PatientCreditCardDetails);
    this.SavePatientPaymentTypeMaster();
    
  }
  SavePatientPaymentTypeMaster()
  {
    if(!this.dtoPatientPaymentDetails.IsEdit){
   this.dtoPatientPaymentDetails.PaymentdetailId=0;
   this.dtoPatientPaymentDetails.PatientCreditCardDetails.Cid=0;
  //  this.dtoPatientPaymentDetails.PatientAccountId = localStorage.getItem('patientAccountId');   
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoPatientPaymentDetails, false);
    this.billingservice.PostPatientPaymentMaster(dataToEncrypt).subscribe(
        (res) => {
          this.spinnerservice.hide();
          if(res=="100")
          {
            this.snackbar.error("Credit card details are already exists");
            return;
          }
          else
          {
          this.snackbar.success(res);
          this.onCancelClick();
          }
         
          
        },
        (err) => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        }
      );
   }
   

onCancelClick()
  {
this.isSave=false;
this.showCreditCard=false;
this.isEdit=false;
this.Paymentfrm.resetForm();
this.dtoPatientPaymentDetails = new PatientPaymentDetails();
this.dtoPatientPaymentDetails.PatientCreditCardDetails =new DtoPatientCreditCardDetails();
this.dtoPatientPaymentDetails.PatientInfoId= this.patientInfoId;
this.dtoPatientPaymentDetails.PatientCreditCardDetails= this.patientInfoId;
this.dtoPatientPaymentDetails.PaymentTypeId=1;
this.onPaymenttypeChange(1);

  }
  
  bindGridData(){
    this.spinnerservice.show();
    this.patientPaymentTypemasters=[];
      this.billingservice.getPatientCreditcardDtls(this.patientInfoId).subscribe(
       res => {
         if (res != null) {
         this.spinnerservice.hide();
          let result=decrypt(res.objInputString)
           this.patientPaymentTypemasters=result;
          // console.log(result);
           this.dataSource = new MatTableDataSource(this.patientPaymentTypemasters);
            this.dataSource.paginator = this.paginator;
           this.dataSource.sort = this.sort;
           }
       },
       err => {
         this.spinnerservice.hide();
       },
     );
   }
   onListEdit(item)
   {
    this.dtoPatientPaymentDetails.PatientCreditCardDetails.BankName=item.BankName;
    this.dtoPatientPaymentDetails.PatientCreditCardDetails.CVV=item.Cvv;
    this.dtoPatientPaymentDetails.PatientCreditCardDetails.CardHolderName=item.CardHolderName;
    this.dtoPatientPaymentDetails.PatientCreditCardDetails.CardNumber=item.CardNumber;
    this.dtoPatientPaymentDetails.PatientCreditCardDetails.Cid=item.Cid;
    this.dtoPatientPaymentDetails.PatientCreditCardDetails.Comments=item.Comments;
    this.dtoPatientPaymentDetails.PatientCreditCardDetails.CreditcardTypeId=item.CreditcardTypeId;
    this.dtoPatientPaymentDetails.PatientCreditCardDetails.ExpiryDate=item.ExpiryDate;
    this.dtoPatientPaymentDetails.PatientCreditCardDetails.PatientInfoId=this.patientInfoId;
    this.dtoPatientPaymentDetails.PatientCreditCardDetails.PaymentdetailId=item.PaymentdetailId;
    this.dtoPatientPaymentDetails.PatientCreditCardDetails.UserId=localStorage.getItem('id');
    this.dtoPatientPaymentDetails.IsEdit=true;
    
   }
   searchTblVal(val){
    this.dataSource.filter = val.trim().toLocaleLowerCase();
  }
  
}

<div class="">
  <div class="col-md-12 p-0">
    <div class="mt-3">
      <div class="top-notes d-flex ">
        <div class="box-lead-head">Notes</div>
        <div class="ml-auto ">
          <!-- && roleAccess.isCreate -->
          <button mat-raised-button class="primary-btn-filled-small cursor-pointer mr-2" type="button" [disabled]="notesids?.length !== 0"
            *ngIf="!isCreateNotes " (click)="onCreateNotes()">Create Note</button>
            <!-- && roleAccess.isDelete -->
            <button *ngIf="patientnotes?.length > 0 " [disabled]="notesids?.length == 0" (click)="ondeletemultipleNotes()"   type="button"
                  mat-raised-button class="primary-btn-filled-small">Delete All</button>
        </div>
      </div>
      <div class="empty-data" *ngIf="patientnotes?.length ==0">
        <!-- <img *ngIf="!isCreateNotes" class="img-fluid" src="../../../../../assets/images/no_result.png" width="50%"> -->
        <div *ngIf="!isCreateNotes" class="task-empty"> <p>No Notes Available</p><i  class="far fa-sticky-note mr-2"></i></div>
      </div>
      <div class="create-notes-section" *ngIf="isCreateNotes">
        <div class="col-md-12 p-0 edit-note">
          <form (ngSubmit)="frmnote.form.valid  && addnote(frmnote)" id="frmnote" autocomplete="off" #frmnote="ngForm"
            novalidate>
            <div class="row">
              <div class="col-md-12">
                <mat-radio-group [(ngModel)]="notes.isClinical" #isClinical="ngModel" name="isClinical" required>
                  <mat-radio-button [value]='false' class="mr-3"> Admin Note   </mat-radio-button>
                  <mat-radio-button [value]='true' class="mr-2 my-1">   Clinical Note</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="frmnote.submitted && isClinical.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper" *ngIf="isClinical.errors.required">This field is
                    required</mat-error>
                </mat-error>
              </div>
              <div class="col-md-12">
                <div class="col-md-12 p-0">
                    <mat-form-field class="w-100">
                        <input matInput placeholder="Enter Subject" appAlphabetOnly maxlength="50"
                          [(ngModel)]="notes.subject" #subject="ngModel" name="subject"
                          autocomplete="new-password" [ngClass]="{ 'is-invalid': frmnote.submitted && subject.invalid }"
                          required>
                        <mat-error *ngIf="frmnote.submitted && subject.invalid">
                          <mat-error *ngIf="subject.errors.required">Subject is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>

            </div>
              <div class="col-md-12">
                <label class="control-label-new">Enter Notes</label>
                <div class="col-md-12 p-0">

                  <!-- <textarea class="custom-textarea-new" [(ngModel)]="notes.notesContent"
                      #notesContent="ngModel" name="notesContent"
                      [ngClass]="{ 'is-invalid': frmnote.submitted && notesContent.invalid }"
                      required></textarea> -->
                  <quill-editor [(ngModel)]="notes.notesContent" #notesContent="ngModel" name="notesContent"
                    [modules]="quillConfig" placeholder="Insert note..." required></quill-editor>
                  <mat-error *ngIf="frmnote.submitted && notesContent.invalid">
                    <mat-error class="mat-form-field-subscript-wrapper" *ngIf="notesContent.errors.required">Content is
                      required</mat-error>
                  </mat-error>

                </div>

              </div>
              <div class="col-md-12 text-right mb-2 mt-2">
                <!-- *ngIf="roleAccess.isCreate" -->
                <button type="submit" mat-raised-button class="primary-btn-outlined-small mr-2 d-none" >Save
                  Note</button>
                <button type="submit" mat-raised-button class="primary-btn-outlined-small mr-2"
                  form="frmnote">{{!isEditNotes ?
                  'Create Note':'Update Note'}}</button>
                <button mat-raised-button class="primary-btn-outlined-small mr-2" (click)="resetnotesform()">Cancel
                </button>
                
              </div>
              <!-- <div class="col-md-6">

                <mat-form-field class="example-chip-list w-100 gmail-txt">
                  <mat-label>Add tags</mat-label>
                  <mat-chip-list #chipList aria-label="">
                    <mat-chip *ngFor="let tag of usertags" [selectable]="selectable" [removable]="removable"
                      (removed)="removeUsertags(tag)">
                      {{tag.email}}
                      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Add more tags..." [matAutocomplete]="auto" (matChipInputTokenEnd)="addUserstag($event)"  [matChipInputFor]="chipList" [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [(ngModel)]="notes.tags"
                      #tags="ngModel" name="tags"
                      [ngClass]="{ 'is-invalid': frmnote.submitted && tags.invalid }" required>
                  </mat-chip-list>
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="userselected($event)">
                    <mat-option *ngFor="let a of parentlst" [value]="a">
                      {{a.email}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="frmnote.submitted && tags.invalid">
                    <mat-error *ngIf="tags.errors.required">User is required</mat-error>
                  </mat-error>
                </mat-form-field>

              </div> -->
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-12 p-0 mt-2 view-note d-block">
        <div class="row">
          <div class="col-md-12 mb-1" *ngFor="let item of patientnotes">
            <div class="list-note">
              <div class="row">
                <div class="col-md-10">

                  <div class="float-left w-100 pl-4 position-relative">
                    <span class="check-custom invi-check2 align-middle">
                      <mat-checkbox class="example-margin" (change)="cbklistnotes($event,item, i)" name="tag{{i}}">
                      </mat-checkbox>
                    </span>
                    

                    <div class="tag-label">
                      {{item.subject}}
                    </div>
                    <p class="note-content float-left w-100" innerHTML="{{global.getContent(item.notesContent)}}">

                    </p>
                  </div>



                </div>
                <div class="col-md-2 text-right pl-0">
                  <!-- *ngIf="roleAccess.isCreate||roleAccess.isEdit" -->
                  <a title="comment"><span
                    class="mr-2" (click)="openDialog(item)" ><i class="far fa-comments"></i></span></a>
                    <!-- *ngIf="roleAccess.isEdit" -->
                    <a (click)="onPinNotes(item)"  class="icon-note cursor-pointer" [title]="item.isPin == true ? 'UnPin the note' : 'Pin the note' "> <span
                      class="mr-2"><i [class]="item.isPin == true ? 'fas fa-thumbtack text-success' : 'fas fa-thumbtack' "></i></span></a>
                      <!-- *ngIf="roleAccess.isEdit" -->
                      <a (click)="oneditNotes(item)" class="icon-note cursor-pointer"  title="edit"><span class="mr-2"><i
                        class="far fa-edit"></i></span></a>
                        <!-- *ngIf="roleAccess.isDelete" -->
                  <a (click)="ondeletedNotes(item)" class="icon-note cursor-pointer"  title="delete"> <span
                      class="mr-2"><i class="far fa-trash-alt"></i></span></a>
                </div>
                <div class="col-md-12 text-right">
                  <div class="row">
                    <div class="col-md-8">
                      <span class="tag-label">Tags : </span>
                      <div class="tag-label">
                        {{item.noteType}}
                      </div>

                      <!-- <mat-chip-list aria-label="Fish selection">
    <mat-chip *ngFor="let a of item.tags">{{a.email}}</mat-chip>
  </mat-chip-list> -->
                    </div>
                   
                    <div class="col-md-4">
                      <div><span class="time-label">Last
                          updated : </span><span>{{global.globalUtctimeformat(item.updateDate)}}</span></div>

                    </div>
                    <div class="col-md-12 text-right pl-0">
                      <div><span class="time-label">Written by : </span><span>{{item.createdName}}</span></div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="5"
              [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>

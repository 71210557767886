import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { MenuList, RoelAccess } from '../_models/_Common/roleAccessMaster';
import { constantVariables } from '../constant/constant';
@Injectable({
  providedIn: 'root'
})
export class PageAccessService {
roleaccess: any;
PageAccessDetail:RoelAccess;
MenuAccess:MenuList;
constructor(private http: HttpClient) { this.PageAccessDetail = new RoelAccess();
                                        this.MenuAccess = new MenuList();}
GetRoleAccessByPageId(id:number){
    var access = JSON.parse(localStorage.getItem('roleaccess'));
    //const pageid = constantVariables.pagelists.Goal;
    this.roleaccess = access.pagepermissions.find(x => x.pageId == id);
    if (this.roleaccess != null) {
        this.PageAccessDetail.isCreate = this.roleaccess.isCreate;
        this.PageAccessDetail.isView = this.roleaccess.isView;
        this.PageAccessDetail.isEdit = this.roleaccess.isEdit;
        this.PageAccessDetail.isDelete = this.roleaccess.isDelete;
        this.PageAccessDetail.isApprove = this.roleaccess.isApprove;
        this.PageAccessDetail.isSign = this.roleaccess.isSign;
        this.PageAccessDetail.isInternSign = this.roleaccess.isInternSign;
        this.PageAccessDetail.isUnlicensed = this.roleaccess.isUnlicensed;
        this.PageAccessDetail.isUnlockProgress = this.roleaccess.isUnlockProgress;
    }
    else{
        this.PageAccessDetail.isCreate = false;
        this.PageAccessDetail.isView =false;
        this.PageAccessDetail.isEdit = false;
        this.PageAccessDetail.isDelete = false;
        this.PageAccessDetail.isApprove = false;
        this.PageAccessDetail.isSign = false;
        this.PageAccessDetail.isInternSign = false;
        this.PageAccessDetail.isUnlicensed = false;
        this.PageAccessDetail.isUnlockProgress = false;
    }
    return this.PageAccessDetail
}

GetSiteHeaderAccess(){
    var access = JSON.parse(localStorage.getItem('roleaccess'));
    var scheduleraccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.ScheduleAppointmentsTherapies)
    this.MenuAccess.Scheduler = scheduleraccess.isCreate|| scheduleraccess.isView||scheduleraccess.isEdit||scheduleraccess.isDelete || scheduleraccess.isApprove || scheduleraccess.isSign || scheduleraccess.isUnlockProgress;
    var LeadAccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Lead)
    this.MenuAccess.Leads = LeadAccess.isCreate|| LeadAccess.isView||LeadAccess.isEdit|| LeadAccess.isDelete || LeadAccess.isApprove || LeadAccess.isSign;
    var PatientAccountaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.PatientAccount)
    this.MenuAccess.PatientAccount = PatientAccountaccess.isCreate|| PatientAccountaccess.isView||PatientAccountaccess.isEdit||PatientAccountaccess.isDelete || PatientAccountaccess.isApprove || PatientAccountaccess.isSign;
    var Briefcaseaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Briefcase)
    this.MenuAccess.Briefcase = Briefcaseaccess.isCreate|| Briefcaseaccess.isView||Briefcaseaccess.isEdit||Briefcaseaccess.isDelete || Briefcaseaccess.isApprove || Briefcaseaccess.isSign;
    var Profileaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Profile)
    this.MenuAccess.Profile = (Profileaccess.isCreate|| Profileaccess.isView||Profileaccess.isEdit||Profileaccess.isDelete || Profileaccess.isSign ) && !Profileaccess.isApprove;
   // var Notesaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Notes)
   // this.MenuAccess.Notes = Notesaccess.isCreate|| Notesaccess.isView||Notesaccess.isEdit||Notesaccess.isDelete || Notesaccess.isApprove || Notesaccess.isSign;
    var Taskaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Task)
    this.MenuAccess.Task = Taskaccess.isCreate|| Taskaccess.isView||Taskaccess.isEdit||Taskaccess.isDelete || Taskaccess.isApprove || Taskaccess.isSign;
    var Goalsaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Goals)
    this.MenuAccess.Goals = Goalsaccess.isCreate|| Goalsaccess.isView||Goalsaccess.isEdit||Goalsaccess.isDelete || Goalsaccess.isApprove || Goalsaccess.isSign;
    var Templatesaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Templates)
    this.MenuAccess.Template = Templatesaccess.isCreate|| Templatesaccess.isView||Templatesaccess.isEdit||Templatesaccess.isDelete|| Templatesaccess.isApprove || Templatesaccess.isSign;
    var Billingaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.CreateBill)
    var Claimsaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.InsuranClaims)
    this.MenuAccess.Billing = Billingaccess.isCreate|| Billingaccess.isView||Billingaccess.isEdit||Billingaccess.isDelete || Claimsaccess.isCreate|| Claimsaccess.isView||Claimsaccess.isEdit||Claimsaccess.isDelete || Claimsaccess.isApprove || Claimsaccess.isSign || Billingaccess.isApprove || Billingaccess.isSign;
    var BillingMastersaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.BillingMasters)
    this.MenuAccess.BillingMasters = BillingMastersaccess.isCreate|| BillingMastersaccess.isView||BillingMastersaccess.isEdit||BillingMastersaccess.isDelete || BillingMastersaccess.isApprove || BillingMastersaccess.isSign;
    var Settingsaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Settings)
    this.MenuAccess.Settings = Settingsaccess.isCreate|| Settingsaccess.isView||Settingsaccess.isEdit||Settingsaccess.isDelete || Settingsaccess.isApprove || Settingsaccess.isSign;
    var StaffManagementaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.StaffManagement)
    this.MenuAccess.StaffManagement = StaffManagementaccess.isCreate|| StaffManagementaccess.isView||StaffManagementaccess.isEdit||StaffManagementaccess.isDelete || StaffManagementaccess.isApprove || StaffManagementaccess.isSign;
    var Scheduleintakeaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.ScheduleIntake)
    this.MenuAccess.ScheduleIntake = Scheduleintakeaccess.isCreate|| Scheduleintakeaccess.isView||Scheduleintakeaccess.isEdit||Scheduleintakeaccess.isDelete || Scheduleintakeaccess.isApprove || Scheduleintakeaccess.isSign;
    var Paymentaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.PaymentDetails)
    this.MenuAccess.PaymentDetails = Paymentaccess.isCreate|| Paymentaccess.isView||Paymentaccess.isEdit||Paymentaccess.isDelete || Paymentaccess.isApprove || Paymentaccess.isSign;
    var TreatmentPlanaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.TreatmentPlan)
    this.MenuAccess.TreatmentPlan = TreatmentPlanaccess.isCreate|| TreatmentPlanaccess.isView||TreatmentPlanaccess.isEdit||TreatmentPlanaccess.isDelete || TreatmentPlanaccess.isApprove || TreatmentPlanaccess.isSign;
    var GenerateIntakeaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.GenerateIntake)
    this.MenuAccess.GenerateIntake = GenerateIntakeaccess.isCreate|| GenerateIntakeaccess.isView||GenerateIntakeaccess.isEdit||GenerateIntakeaccess.isDelete || GenerateIntakeaccess.isApprove || GenerateIntakeaccess.isSign;
    var PainAssessaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.PainAssessment)
    this.MenuAccess.PainAssessment = PainAssessaccess.isCreate|| PainAssessaccess.isView||PainAssessaccess.isEdit||PainAssessaccess.isDelete || PainAssessaccess.isApprove || PainAssessaccess.isSign;
    var Nutritionalaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.NutritionalQuestion)
    this.MenuAccess.NutritionalQuestion = Nutritionalaccess.isCreate|| Nutritionalaccess.isView||Nutritionalaccess.isEdit||Nutritionalaccess.isDelete || Nutritionalaccess.isApprove || Nutritionalaccess.isSign;
    var Preparechmaaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.PrepareCHMA)
    this.MenuAccess.PrepareCHMA = Preparechmaaccess.isCreate|| Preparechmaaccess.isView||Preparechmaaccess.isEdit||Preparechmaaccess.isDelete || Preparechmaaccess.isApprove || Preparechmaaccess.isSign;
    var Suicidalaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.SuicidalRiskAssessment)
    this.MenuAccess.SuicidalRiskAssessment = Suicidalaccess.isCreate|| Suicidalaccess.isView||Suicidalaccess.isEdit||Suicidalaccess.isDelete || Suicidalaccess.isApprove || Suicidalaccess.isSign;
    var TraetementHisaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.TreatmentHistory)
    this.MenuAccess.TreatmentHistory = TraetementHisaccess.isCreate|| TraetementHisaccess.isView||TraetementHisaccess.isEdit||TraetementHisaccess.isDelete || TraetementHisaccess.isApprove || TraetementHisaccess.isSign;
    var FivePointaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.ProgressNoteFivepointRating)
    this.MenuAccess.ProgressNoteFivepointRating = FivePointaccess.isCreate|| FivePointaccess.isView||FivePointaccess.isEdit||FivePointaccess.isDelete || FivePointaccess.isApprove || FivePointaccess.isSign;
    var Incidentaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.IncidentReporting)
    this.MenuAccess.IncidentReport = Incidentaccess.isCreate|| Incidentaccess.isView||Incidentaccess.isEdit||Incidentaccess.isDelete || Incidentaccess.isApprove || Incidentaccess.isSign;
    var Dischargeaccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.DischargeSummary)
    this.MenuAccess.DischargeSummary = Dischargeaccess.isCreate|| Dischargeaccess.isView||Dischargeaccess.isEdit||Dischargeaccess.isDelete || Dischargeaccess.isApprove || Dischargeaccess.isSign;
    var DocumentAccess = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Documents)
    this.MenuAccess.Documents =  DocumentAccess.isView;
    var HorizondalMenu = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.HorizondalMenu)
    this.MenuAccess.HorizondalMenu =  HorizondalMenu.isView;
    var Dashboard = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Dashboard)
    this.MenuAccess.Dashboard =  Dashboard.isView;
    var Authorization = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Authorization)
    this.MenuAccess.Authorization =  Authorization.isView;
    var Audit = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.Audit)
    this.MenuAccess.Audit =  Audit.isView;
    return this.MenuAccess;
}

}
import { Component, OnInit, ViewChild, Input ,ElementRef} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { sfcclocation } from 'src/app/_models/_Common/_sfcc-Location';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { CommonService } from 'src/app/common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { cloneDeep } from "lodash";
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { SiteHeaderComponent } from 'src/app/_Layout/site-header/site-header.component';
import { LibraryDialogComponent } from 'src/app/common/library-dialog/library-dialog.component';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
@Component({
  selector: 'app-image-library',
  templateUrl: './image-library.component.html',
  styleUrls: ['./image-library.component.scss']
})
export class ImageLibraryComponent implements OnInit {
  libraryNames = [];
  libraryImages = [];
  isEditLibrary: boolean;
  libraryNameId : number = 0;
  libraryName : any;
  dublibraryNameId : number = 0;
  dublibraryName : any;
  submitted :boolean  =false;
  isEdit: boolean ;
  @ViewChild('importphbook') eleimportphbook: ElementRef;
  UploadFileList = [];
  roleAccess: any;
  constructor(public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public settingsService: SettingsService,
    public roleaccessservice:PageAccessService
    ) { }


  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Settings;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.getLibraryNames();
  }

  onloadImages(event)
  {
    this.isEditLibrary = true;
    this.libraryNameId = event;
    this.libraryName = this.libraryNames.filter(x => x.libraryNameId == this.libraryNameId)[0].name;
    this.spinnerservice.show();
    this.settingsService.getLibraryImages(event).subscribe(
      res => {
        if (res != null) {
          this.libraryImages = cloneDeep(res);
          this.spinnerservice.hide();
          // this.spinnerservice.hide();
          // this.spinnerservice.hide();
        }
      },
      err => {
        // this.spinnerservice.hide();
      },
    );
  }

  getLibraryNames()
  {
    this.spinnerservice.show();
    this.settingsService.getLibraryNames().subscribe(
      res => {
        if (res != null) {
          this.libraryNames = cloneDeep(res);
           this.spinnerservice.hide();
          // this.spinnerservice.hide();
        }
      },
      err => {
         this.spinnerservice.hide();
      },
    );
  }

  onFileChange(event) {
    
        const reader = new FileReader();
        var _validFileExtensions = [".jpg", ".jpeg", ".png"];
        if (event.target.files && event.target.files.length) {
          const [file] = event.target.files;
          if (file.size > 5020905) {
            this.snackbar.error(constantVariables.ErrorMessage.filesize)
            return
          }
          if (file.type == 'image/jpg'
            || file.type == 'image/jpeg' || file.type == 'image/png') {
            reader.readAsDataURL(file);
    
            reader.onload = () => {
              this.UploadFileList=[];
              var files = Array.from(event.target.files);
              for (var i = 0; i < files.length; i++) {
                
                  this.UploadFileList.push(files[i]);
                
              }
              const frmData = new FormData();
              for (var i = 0; i < this.UploadFileList.length; i++) {
                frmData.append("doc", this.UploadFileList[i]);
              }
              frmData.append('DocumentTypeId', constantVariables.DocumentTypeId.Library);
              frmData.append('LibraryNameId', this.libraryNameId.toString());
              this.spinnerservice.show();
              this.settingsService.postimportImage(frmData).subscribe((res: any) => {
              if (res) {
                  
                  this.eleimportphbook.nativeElement.value = "";
                  this.spinnerservice.hide();
                  this. onloadImages(this.libraryNameId)
                 // console.log(event)
                  this.snackbar.success(res.message)
                 
              }
                }, err => {
                this.spinnerservice.hide();
                this.snackbar.error(err.error);
              });
              this.eleimportphbook.nativeElement.value = "";
            };
          } else {
            this.eleimportphbook.nativeElement.value = "";
            this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
          }
        }
      }

      onRemoveImage(obj)
      {
        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: '',
            message: constantVariables.Warning.DeleteImageMessage
          }
        });
        confirmDialog.afterClosed().subscribe(result => {
          if (result === true) {
            this.spinnerservice.show();
        this.settingsService.deleteLibraryImage(obj.uploadDocumentsId).subscribe(
          res => {
            if (res != null) {
              
              this. onloadImages(this.libraryNameId)
              this.spinnerservice.hide();
              this.snackbar.success(res.message);
              
              // this.spinnerservice.hide();
              // this.spinnerservice.hide();
            }
            
          },
          err => {
            
             this.spinnerservice.hide();
             this.snackbar.error(err.error);
          },
        );
          }
        });
        
      }

      oneditNotes(element) {        
        if(element == 1)
        {
         this.isEdit = false;
         this.dublibraryNameId = 0;
         this.dublibraryName = '';
        }
        else
        {
          this.isEdit = true;
          this.dublibraryNameId = this.libraryNameId;
          this.dublibraryName = this.libraryName;
        }
        const dialogRef = this.dialog.open(LibraryDialogComponent,
          { data: { libraryNameId: this.dublibraryNameId, libraryName : this.dublibraryName, isEdit : this.isEdit } });
        dialogRef.afterClosed().subscribe(result => {
          this.getLibraryNames();
        });
      }

}

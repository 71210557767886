<div class="finance-review-page" *ngIf="this.isFinancereview != true"
    [ngClass]="{' ':this.LeadStatus == this.statusforIntake || this.LeadStatus == this.statusCrbaUpdate}">
    <!-- [ngClass]="{'disabled':financialProposal?.status !== statusFinancial}" -->
    <div class="lead-box mb-3 pt-3 h-vh-50">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-8">
                    <h2 class="head-section mb-2 mt-0">Review Financial Proposal</h2>
                    
                </div>

            </div>
        </div>
        <div class="col-md-12 finance-review p-0 pt-2">
            <div class="col-md-12 proposal">
                <div class="row">
                    <div [ngClass] ="isTour? 'col-md-8' : 'col-md-12'" >
                        <div class="proposal-form">
                            <div class="form">
                                <p>Hello <b>{{userName}}</b></p>
                                <p> I was able to take a look at your benefits and the following will be your financial 
                                    responsibility
                                    for the program: </p>
                                    <div class="col-md-12 pricelistview-review">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-3" *ngFor="let com of financialProposal?.componentDetails">
                                                    <div class="">
                                                        <label class="list-head price-label col-md-12">{{com.componentName}}</label>
                                                        <div class="col-md-12 p-0" *ngFor="let pr of com.pricing">
                                                            <div class="list-value-new float-left w-100 ">
                                                                <span class="highlight-text">$ {{pr.priceValue}}</span>
                                                                <span class="small-text-highlight">
                                                                    {{pr.coInsuranceVal}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 pricelistview-review" style="max-width: 100% !important;">

                                    <div class=" " *ngFor="let com of financialProposal?.componentDetails">
                                                <!-- Deductible column -->
                                                <div class="col-md-3" *ngIf="financialProposal?.isInsuranceCoverage && com.componentName == 'Session Fee' ">
                                                    <div class="">
                                                        <label class="list-head price-label col-md-12">Deductible</label>
                                                        <div class="col-md-12 p-0 ">
                                                            <div class="list-value-new float-left w-100 ">
                                                                <span class="small-text-highlight"><b>{{com?.deductibleType}}</b>
                                                                    {{com?.deductibleTypeVal}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                    
                                                <!-- Comments column -->
                                                <div class="col-md-3" *ngIf="financialProposal?.isInsuranceCoverage && com.componentName == 'Session Fee' ">
                                                    <div class="">
                                                        <label class="list-head price-label col-md-12">Comments</label>
                                                        <div class="col-md-12 p-0 ">
                                                            <div class="list-value-new float-left w-100 ">
                                                                <span class="small-text-highlight">{{com?.deductibleComments}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                <p>

                                </p>
                                <p>Please review our financial responsibilities video on our website to understand the insurance process.<a target="_blank" href="https://www.steppingforwardcounselingcenter.com/fees/"> www.steppingforwardcounselingcenter.com/fees/</a></p>
                                <p>Please let me know if you have any questions and if you would like to move forward.
                                </p>
                                <p>Best, </p>
                                <div class="col-md-12 p-0">
                                    <div class="row">
                                        <div class="col-md-6 text-left">
                                            <p class="m-0">{{financialProposal?.location?.locationName}}</p>
                                            <p *ngIf = "financialProposal?.location?.locationType != 1" class="m-0">{{financialProposal?.location?.address1}}, {{financialProposal?.location?.address2}}</p>
                                            <p *ngIf = "financialProposal?.location?.locationType != 1"  class="m-0">{{financialProposal?.location?.cityName}}, {{financialProposal?.location?.stateName}} {{financialProposal?.location?.zipCode !=0 ? financialProposal?.location?.zipCode : ''}}
                                                </p>
                                        </div>


                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <div>Thank you</div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-12 accept-btn text-center mt-3 pr-0">
                            <button type="button" (click)="postAcceptProposal()"
                                class="btn primary-btn-s btn-success mr-2" [ngClass]="{'disabled':financialProposal?.status == statusAccepted}" *ngIf="financialProposal?.status != statusDeclined">{{btnAcceptText}}</button>
                            <button type="button" (click)="onDenyProposal()" class="btn primary-btn-s btn-danger " *ngIf="isShowDeny">Reject</button>
                            <button type="button" class="btn primary-btn-s btn-danger " [ngClass]="{'disabled':financialProposal?.status == statusDeclined}" *ngIf="isShowDeclined">Rejected</button>
                        </div>
                    </div>
                    <div class="col-md-4 req-tour" *ngIf="isTour">
                        <div class="card">
                            <div class="card-header p-b-0">
                                <h6 class="card-title text-left m-0">
                                    Request Tour
                                </h6>
                            </div>
                            <div class="card-body">
                                <div [ngClass]="{'disabled':financialProposal?.status == statusAccepted || financialProposal?.status == statusDeclined}" *ngIf="!isRequestTour && (LeadStatus != statusreviewTour || !IsTourExist) && financialProposal?.requestTourDetails[0]?.status !=  statustourCompleted && financialProposal?.requestTourDetails[0]?.status != statusstaffAssigned && roleAccessTour.isCreate ">
                                    <div class="form-group text-center">
                                        <label class="label-tour">Would you like to schedule a tour of our facility before you accept the financial proposal and proceed to the intake?</label>
                                        <!-- <label class="label-tour">It looks like you have not availed our facility
                                            tour.</label>
                                        <label class="label-tour">Do you want to avail one before you accept our
                                            financial proposal?</label> -->
                                    </div>
                                    <div class="yes-no-btn text-right">
                                        <button class="btn btn-yes mr-2 cursor-pointer" type="button" title="Yes"
                                            (click)="onYestoRequestTour()">Yes</button>
                                        <button class="btn btn-yes mr-2 cursor-pointer" type="button" title="No"
                                            (click)="onNotoRequestTour()">No</button>
                                    </div>

                                </div>
                                <div class="req-tour-section"
                                    *ngIf="(LeadStatus == statusreviewTour && IsTourExist) || financialProposal?.requestTourDetails[0]?.status == statusstaffAssigned || financialProposal?.requestTourDetails[0]?.status ==  statustourCompleted && roleAccessTour.isView">
                                    <!-- <label class="label-tour col-md-12 p-0" *ngIf="!financialProposal?.requestTourDetails[0]?.isTourCancelled">Your Tour Has Been Scheduled.</label> -->
                                       <label class="label-tour col-md-12 p-0" *ngIf="!financialProposal?.requestTourDetails[0]?.isTourCancelled">Tour options have been requested.</label>
                                    <label class="label-tour col-md-12 p-0" *ngIf="financialProposal?.requestTourDetails[0]?.isTourCancelled">Your Tour Has Been Cancelled.</label>
                                    <div class="info-user">
                                        <p>Tour Date: <b
                                                class="ml-2">{{financialProposal?.requestTourDetails[0]?.requestTourDate |
                                                date: 'MM/dd/yyyy'}}</b> </p>
                                        <p>Tour Time: <b
                                                class="ml-2">{{financialProposal?.requestTourDetails[0]?.fromTime | date:
                                                'hh:mm a'}} - {{financialProposal?.requestTourDetails[0]?.toTime | date:
                                                'hh:mm a'}}</b></p>
                                        <!-- <p class="confirm-schedule help-texted"
                                            *ngIf=" financialProposal?.requestTourDetails[0]?.status ==  statustourCompleted  || financialProposal?.requestTourDetails[0]?.status == statusstaffAssigned && !financialProposal?.requestTourDetails[0]?.isTourCancelled">
                                            Your schedule is confirmed with
                                            <b>{{financialProposal?.requestTourDetails[0]?.userName}}</b>. You can check
                                            with our front office once you visit us here.
                                        </p> -->
                                        <p class="confirm-schedule help-texted"
                                            *ngIf=" financialProposal?.requestTourDetails[0]?.status ==  statustourCompleted  || financialProposal?.requestTourDetails[0]?.status == statusstaffAssigned && !financialProposal?.requestTourDetails[0]?.isTourCancelled">
                                            Your tour request has been assigned to
                                            <b>{{financialProposal?.requestTourDetails[0]?.userName}}</b>. Kindly 
                                            reach our front office, once you visit us.
                                        </p>
                                        
                                        <p class="confirm-schedule help-texted"
                                        *ngIf="financialProposal?.requestTourDetails[0]?.isTourCancelled">
                                          Your tour is cancelled.
                                        </p>
                                        <p class="confirm-schedule help-texted"
                                        *ngIf="!financialProposal?.requestTourDetails[0]?.isTourCancelled && financialProposal?.requestTourDetails[0]?.status==statustourCompleted">
                                          Your Tour has been completed Successfully.
                                        </p>
                                    </div>
                                    <div class="info-user" *ngIf="financialProposal?.requestTourDetails?.length>1">
                                      <p>Tour Date: <b
                                              class="ml-2">{{financialProposal?.requestTourDetails[1]?.requestTourDate |
                                              date: 'MM/dd/yyyy'}}</b> </p>
                                      <p>Tour Time: <b
                                              class="ml-2">{{financialProposal?.requestTourDetails[1]?.fromTime | date:
                                              'hh:mm a'}} - {{financialProposal?.requestTourDetails[1]?.toTime | date:
                                              'hh:mm a'}}</b></p>
                                  </div>
                                    <!-- <div *ngIf="financialProposal?.requestTourDetails?.status ==  statustourCompleted || financialProposal?.requestTourDetails?.status == statusstaffAssigned">
                                        <div class="check-custom dotted-border text-center mb-3">
                                            <mat-checkbox
                                                [disabled]="financialProposal?.requestTourDetails?.status == statustourCompleted"
                                                (change)="postTourCompleted()">I had a good facility tour</mat-checkbox>
                                        </div>
                                    </div> -->
                                    <div class="col-md-12 text-center" *ngIf="(roleAccessTour.isApprove)">
                                      <button mat-stroked-button class="btn  btn primary-btn-outlined  h-40 " *ngIf="!financialProposal?.requestTourDetails[0]?.isTourCancelled && financialProposal?.requestTourDetails[0]?.status==statusstaffAssigned && financialProposal.requestTourDetails[0]?.status==19" style="margin-right: 30px;" [disabled]="!isTourCompleteEnabled" (click)="postTourCompleted(1)">Mark Tour as Completed</button>
                                      <button mat-stroked-button class="btn  btn primary-btn-outlined  h-40 " *ngIf="!financialProposal?.requestTourDetails[0]?.isTourCancelled && financialProposal?.requestTourDetails[0]?.status==statusstaffAssigned && financialProposal.requestTourDetails[0]?.status==19" (click)="hideModal=true;"> Cancel</button>
                                    </div>
                                </div>
                                <div class="req-tour-section" *ngIf="isRequestTour && roleAccessTour.isCreate">
                                    <form id="frmRequestTour"
                                        (ngSubmit)="frmRequestTour.form.valid  "
                                        autocomplete="off" #frmRequestTour="ngForm" novalidate>
                                        <label class="label-tour"><i class=""></i>Confirm
                                            Option 1</label>
                                        <div>
                                            <!-- <mat-calendar (selectedChange)="onCalendarSelectedChange($event)">
                                            </mat-calendar> -->

                                        </div>
                                        <div class="col-md-12 tour-form p-0">

                                            <div class="row">
                                                <label class="label-tour col-md-6"><i
                                                        class="far fa-calendar-alt mr-2"></i>Tour Date 1
                                                    </label>
                                                <div class="col-md-6">
                                                    <mat-form-field class="w-100">
                                                        <input matInput [matDatepicker]="picker" [min]="currentdate"
                                                            readonly (click)="picker.open()"
                                                            (dateChange)="onCalendarSelectedChange($event,1)"
                                                            [matDatepickerFilter]=meetingDateFilter required>
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                    <mat-error class="mat-form-field-subscript-wrapper" *ngIf="isDateSelectedError">
                                                      Please Choose Date
                                                  </mat-error>
                                                </div>

                                            </div>
                                            <!-- <mat-error *ngIf="isRequiredTour && " class="mat-form-field-subscript-wrapper">
                                                Please Choose Date and Time Slot
                                            </mat-error> -->
                                        </div>
                                        <div class="col-md-12 tour-form p-0">

                                            <div class="row form-group">
                                                <label class="label-tour col-md-6"><i
                                                        class="fas fa-clock mr-2"></i>Confirm
                                                    Time 1</label>
                                                <div class="col-md-6">
                                                    <mat-form-field class="w-100">
                                                        <!-- (selectionChange)="onTourtimeCahnge($event)" -->
                                                        <mat-select [(ngModel)]="timeRequestTour1" (selectionChange)="validateTimeSlot(1)" #timeTour1="ngModel"
                                                            name="timeTour1">
                                                            <mat-option *ngFor="let item of timeslots"
                                                                [value]="item">
                                                                {{item.fromTime | date: 'hh:mm a'}}-{{item.toTime |
                                                                date: 'hh:mm a'}}
                                                            </mat-option>
                                                        </mat-select>

                                                    </mat-form-field>

                                                </div>

                                            </div>
                                            <div>
                                                <span *ngIf="isNoTimeSlots" class="mat-form-field-subscript-wrapper text-danger">
                                                  Please Choose Time
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="req-tour-section" *ngIf="isRequestTour && roleAccessTour.isCreate">
                                  <form id="frmRequestTour1"
                                      (ngSubmit)="frmRequestTour1.form.valid "
                                      autocomplete="off" #frmRequestTour1="ngForm" novalidate>
                                      <label class="label-tour"><i class=""></i>Confirm
                                          Option 2</label>
                                      <div>
                                          <!-- <mat-calendar (selectedChange)="onCalendarSelectedChange($event)">
                                          </mat-calendar> -->

                                      </div>
                                      <div class="col-md-12 tour-form p-0">

                                          <div class="row">
                                              <label class="label-tour col-md-6"><i
                                                      class="far fa-calendar-alt mr-2"></i>Tour Date 2
                                                  </label>
                                              <div class="col-md-6">
                                                  <mat-form-field class="w-100">
                                                      <input matInput [matDatepicker]="picker" [min]="currentdate"
                                                          readonly (click)="picker.open()"
                                                          (dateChange)="onCalendarSelectedChange($event,2)"
                                                          [matDatepickerFilter]=meetingDateFilter required>
                                                      <mat-datepicker-toggle matSuffix [for]="picker">
                                                      </mat-datepicker-toggle>
                                                      <mat-datepicker #picker></mat-datepicker>
                                                  </mat-form-field>
                                                  <mat-error class="mat-form-field-subscript-wrapper" *ngIf="isDateSelected1Error">
                                                    Please Choose Date<br>
                                                </mat-error>
                                                <mat-error class="mat-form-field-subscript-wrapper" *ngIf="!isDateSelected1Error&&sameDateError">
                                                  Date Should not be the same
                                              </mat-error>
                                              </div>

                                          </div>
                                          <!-- <mat-error *ngIf="isRequiredTour && " class="mat-form-field-subscript-wrapper">
                                              Please Choose Date and Time Slot
                                          </mat-error> -->
                                      </div>
                                      <div class="col-md-12 tour-form p-0">

                                          <div class="row form-group">
                                              <label class="label-tour col-md-6"><i
                                                      class="fas fa-clock mr-2"></i>Confirm
                                                  Time 2</label>
                                              <div class="col-md-6">
                                                  <mat-form-field class="w-100">
                                                      <!-- (selectionChange)="onTourtimeCahnge($event)" -->
                                                      <mat-select [(ngModel)]="timeRequestTour" #timeTour="ngModel"
                                                          name="timeTour" (selectionChange)="validateTimeSlot(2)">
                                                          <mat-option *ngFor="let item of timeSlots1"
                                                              [value]="item">
                                                              {{item.fromTime | date: 'hh:mm a'}}-{{item.toTime |
                                                              date: 'hh:mm a'}}
                                                          </mat-option>
                                                      </mat-select>

                                                  </mat-form-field>

                                              </div>

                                          </div>
                                          <div>
                                              <span *ngIf="isNoTimeSlots1" class="mat-form-field-subscript-wrapper text-danger">
                                                Please Choose Time
                                              </span>
                                          </div>
                                      </div>

                                      <div class="row form-group ">

                                          <div class="col-md-12 text-right">
                                              <button type="button" title="Cancel"
                                                  class="btn primary-btn-outlined-small float-right cursor-pointer"
                                                  (click)="onNotoRequestTour()">Cancel</button>
                                              <button type="button" title="Submit" (click)="submitTourRequest()"
                                                  form="frmRequestTour1"
                                                  class="btn primary-btn-outlined-small float-right mr-2 cursor-pointer">Submit</button>

                                          </div>
                                      </div>

                                  </form>
                              </div>
                              <div class="req-tour-section" *ngIf="isRequestTour && !roleAccessTour.isCreate">
                                <p>You do not have the access.</p>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>

<div id="myModal" class="modal deny-modal" role="dialog" [style.display]="isFinancialDecline ? 'block' : 'none'" *ngIf="this.isFinancereview != true">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header fill-header">
                <h6 class="modal-title">Reason</h6>
                <button type="button" class="close" (click)="closeDeclineProcess()" data-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>
            <div class="modal-body">
                <p>Please explain your reason for rejecting the Financial Proposal</p>
                <textarea class="custom-textarea" maxlength="200" [(ngModel)]="reasonforDecline" #reason="ngModel"
                    name="reasonforDecline"></textarea>
                <mat-error class="mat-form-field-subscript-wrapper" *ngIf="reqDeclineReason">
                    Reason for reject is required</mat-error>
            </div>
            <div class="modal-footer">

                <button type="button" (click)="closeDeclineProcess()" class="btn btn-cancel primary-btn-s"
                    data-dismiss="modal">Close</button>
                <button type="button" (click)="onDeclineProposal()"
                    class="btn primary-btn-s btn-danger rounded-0 ">Confirm</button>
            </div>
        </div>

    </div>
</div>
<div class="comment-popup  cancel-tour" *ngIf="this.isFinancereview != true">
    <div class="modal" tabindex="-1" role="dialog"  style="display: block;" *ngIf="hideModal">
      <div class="modal-dialog mat-dialog-container " role="document">
        <div class="modal-content border-0">

          <div class="modal-body">
  <div >


    <div class="col-md-12">
      <div class="row ">
        <div class="col-md-12">

            <p class="text-center">Do you want to cancel the Tour?</p>
        </div>


      </div>

    </div>
    <div class="text-center col-md-12 mt-2">
      <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" (click)="CancelTour(financialProposal?.requestTourDetails[0])" >Yes</button>
      <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="hideModal=false">No</button>
    </div>



  </div>


  </div>
  </div>
  </div>
  </div>
  </div>

  <!-- review financialProposal for briefcase view start -->
   <div *ngIf="this.isFinancereview == true">
    <div class="pdg_top">
        <h2 class="head-section mb-2 mt-0">Review Financial Proposal</h2>
        
    </div>
      
  <div  >
    <div class="proposal-form" >
        <div class="form">
            <p>Hello <b>{{userName}}</b></p>
            <p> I was able to take a look at your benefits and the following will be your financial 
                responsibility
                for the program: </p>
                <div class="col-md-12 pricelistview-review">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3" *ngFor="let com of financialProposal?.componentDetails">
                                <div class="">
                                    <label class="list-head price-label col-md-12">{{com.componentName}}</label>
                                    <div class="col-md-12 p-0" *ngFor="let pr of com.pricing">
                                        <div class="list-value-new float-left w-100 ">
                                            <span class="highlight-text">$ {{pr.priceValue}}</span>
                                            <span class="small-text-highlight">
                                                {{pr.coInsuranceVal}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 pricelistview-review" style="max-width: 100% !important;">

                <div class=" " *ngFor="let com of financialProposal?.componentDetails">
                            <!-- Deductible column -->
                            <div class="col-md-3" *ngIf="financialProposal?.isInsuranceCoverage && com.componentName == 'Session Fee' ">
                                <div class="">
                                    <label class="list-head price-label col-md-12">Deductible</label>
                                    <div class="col-md-12 p-0 ">
                                        <div class="list-value-new float-left w-100 ">
                                            <span class="small-text-highlight"><b>{{com?.deductibleType}}</b>
                                                {{com?.deductibleTypeVal}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                            <!-- Comments column -->
                            <div class="col-md-3" *ngIf="financialProposal?.isInsuranceCoverage && com.componentName == 'Session Fee' ">
                                <div class="">
                                    <label class="list-head price-label col-md-12">Comments</label>
                                    <div class="col-md-12 p-0 ">
                                        <div class="list-value-new float-left w-100 ">
                                            <span class="small-text-highlight">{{com?.deductibleComments}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
                
            <p>

            </p>
            <p>Please review our financial responsibilities video on our website to understand the insurance process.<a target="_blank" href="https://www.steppingforwardcounselingcenter.com/fees/"> www.steppingforwardcounselingcenter.com/fees/</a></p>
            <p>Please let me know if you have any questions and if you would like to move forward.
            </p>
            <p>Best, </p>
            <div class="col-md-12 p-0">
                <div class="row">
                    <div class="col-md-6 text-left">
                        <p class="m-0">{{financialProposal?.location?.locationName}}</p>
                        <p *ngIf = "financialProposal?.location?.locationType != 1" class="m-0">{{financialProposal?.location?.address1}}, {{financialProposal?.location?.address2}}</p>
                        <p *ngIf = "financialProposal?.location?.locationType != 1"  class="m-0">{{financialProposal?.location?.cityName}}, {{financialProposal?.location?.stateName}} {{financialProposal?.location?.zipCode !=0 ? financialProposal?.location?.zipCode : ''}}
                            </p>
                    </div>


                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div>Thank you</div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
   </div>

  <!-- review financialProposal for briefcase view end -->


import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { LeadService } from 'src/app/patient-lead/lead.service'
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import "quill-mention";
declare function decrypt(data): any;
import { cloneDeep } from "lodash";
import { ParentInfo } from 'src/app/_models/_Lead/parentInfo';
import { NgForm } from '@angular/forms';
declare function decrypt(data): any;
declare function EncryptFieldData(data): any;
import { addressslookup } from 'src/app/services/add-lookup.service';
import { ProviderDetails } from 'src/app/_models/_Treatment/_dtoProviderDetails';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ListRange } from '@angular/cdk/collections';
import { MasterService } from 'src/app/common/pages//masters/master.service';
import {FormControl} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {   startWith, map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
@Component({
  selector: 'app-lead-detailview',
  templateUrl: './lead-detailview.component.html',
  styleUrls: ['./lead-detailview.component.scss']
})
export class LeadDetailviewComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  @ViewChild('parentform') parentform: NgForm;
  @ViewChild('providerform') providerform:NgForm;
  @ViewChild('leadform') leadform :NgForm;
  displayedColumns = ['position', 'firstName', 'lastName', 'email', 'contactNo', 'parentalRelation', 'isPrimaryContact', 'actions']; // // for table
  Leaddetailview: any;
  patientdetail: any;
  patientPrimary: any;
  public pageSize = 10;
  public currentPage = 0;
  public totalSize = 0;
  pagefrom: any;
  patientInfoId:any;
  selectedTab:number;
  isShowCalenderView:boolean =false;
  istogglemenu:boolean = false;
  ShowContactSavePopup:boolean =false;
  parentModel: any;
  ddlparentalRelationType: any;
  ddlmaritalStatus: any;
  addressoption;
  ddlStates: any;
  ddlCities: any;
  ddlcityMaster: any;
  parentStateId: any;
  ddlstatemaster: any;
  displayedColumnsForProvider = ['position', 'firstName', 'lastName', 'email', 'contactNo', 'role', 'actions']; // // for table provider
  ProviderList: any;
  displayedColumnsForLeaddetails = ['position', 'source', 'type', 'location', 'actions'];
  LeadDetailList:any;
    @ViewChild(MatPaginator) paginatorForProvider: MatPaginator;
    @ViewChild(MatPaginator) paginatorForLead: MatPaginator;
  @ViewChild(MatSort) sortForProvider: MatSort;
  dataSourceForProvider: MatTableDataSource<any>;
  dataSourceForLead : MatTableDataSource<any>;
  totalSizeforProvider: any;
  IsEditProvider: boolean;
  ObjProvider: ProviderDetails;
  drpPhysTypes = constantVariables.PhysicianTypesForProviderContact;
  isAddProvider:boolean=false;
  isStateValid:boolean=false;
  isCityValid:boolean=false;
  initialRange: ListRange = { start: 0, end: 5 } as ListRange;
  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  ddlCitiesProviderinfo:any;
  CityName:any;
  showDdlProviderInfoCity:boolean=false;
  showcity=false;
  cityCtrl= new FormControl();
  filteredcities = <any>[];
  selectedCityId=0;
  selectedLocation:any;
  roleAccess: RoelAccess;
  primaryMail : string;
  // preferredPronouns : any;
  preferredName: any;
  preferredPronouns: string[] = [];
  pronouns: string[] = ['She', 'Her', 'Hers', 'He', 'Him', 'His', 'They', 'Them', 'Their'];
  ddlCitiesforclient : any;
  errorcity : boolean;
  constructor(public leadservice: LeadService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    private addlookup: addressslookup,
    private masterservice: MasterService,
    public roleaccessservice:PageAccessService

    ) {    this.cityCtrl = new FormControl(); 
    
    }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.PatientAccount;
    var access = JSON.parse(localStorage.getItem('roleaccess'));
    //const pageid = constantVariables.pagelists.Goal;
    this.roleAccess = access.pagepermissions.find(x => x.pageId == pageid);
   // this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.ObjProvider = new ProviderDetails();
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        this.pagefrom = params.PageId;        
        this.patientInfoId = this.commonService.FrontEndDecryption(params.id);
        this.selectedTab = 0;
        this.getPatientdetailview();
        this.getProviderDetailLead();
       this.isAddProvider=false;

     
      }
    });
    this.parentModel = new ParentInfo();
    this.ddlmaritalStatus = constantVariables.maritalStatus;

    this.onloadcityforProvider(0);
 
    this.showDdlProviderInfoCity=false;
    // this.cityCtrl.valueChanges.subscribe(
    //   term => {
    //     if (term != '') {
    //       this.masterservice.getSearchCity(term).subscribe(
    //         data => {
    //           debugger;
    //           this.filteredcities =this.commonService.decrypdata(data.objInputString);
    //        })
    //     }
    // });
    this.getddlMaster();
    this.getddlStateMaster();
  }
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  
  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.Leaddetailview.ObjDtoParentInfo.slice(start, end);
    this.dataSource = part;
  }
  onTabChange(tab){
    this.selectedTab=tab.index;
    if(tab.index == 0){   
      this.getPatientdetailview();
    }else if(tab.index == 1  ){   
      this.getProviderDetailList()
    // }else if(tab.index == 2  ){   
    //   this.getProviderDetailLead()
    }
    else if(tab.index == 3){
      this.istogglemenu =true;
    }
  }
  getProviderDetailLead(){
    this.spinnerservice.show();
    this.leadservice.getPatientdetailview(this.patientInfoId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.Leaddetailview = decrypt(res.objInputString);
        var details =  this.Leaddetailview.ObjDtoPatientInfo;
        // this.patientdetail = this.Leaddetailview.ObjDtoPatientInfo;
       console.log(details);
        // this.patientPrimary = this.Leaddetailview.ObjDtoParentInfo.filter(
        //   x => x.IsPrimaryContact === true);
         
    this.dataSourceForLead = new MatTableDataSource(details);
    this.dataSourceForLead.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.totalSize = this.Leaddetailview.ObjDtoParentInfo.length;
    //this.iterator();
  },
  err => {
    this.spinnerservice.hide();
  },
);

  }
  getPatientdetailview() {
    this.spinnerservice.show();
    this.leadservice.getPatientdetailview(this.patientInfoId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.Leaddetailview = decrypt(res.objInputString);
        this.leadservice.mail_text = this.Leaddetailview.ObjDtoParentInfo[0].Email;
        this.patientdetail = this.Leaddetailview.ObjDtoPatientInfo[0];
        let selectedPreferredPronouns: string[] = this.patientdetail.PreferredPronouns.split(', ');
        this.patientdetail.PreferredPronouns = selectedPreferredPronouns;

       // console.log(this.patientdetail);
        this.patientPrimary = this.Leaddetailview.ObjDtoParentInfo.filter(
          x => x.IsPrimaryContact === true);
         // debugger;
      //  console.log(this.patientPrimary);
      this.primaryMail = this.patientPrimary[0].Email;
        this.dataSource = new MatTableDataSource(this.Leaddetailview.ObjDtoParentInfo);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.totalSize = this.Leaddetailview.ObjDtoParentInfo.length;
        this.Leaddetailview.ObjDtoPatientInfo[0].preferredName;
        //this.iterator();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  makePrimaryContact() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.confirmation.ChangePrimaryEmailAddressAdmin
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.parentModel.IsPrimaryContact = true;
        this.parentModel.IsEmergency = !this.patientPrimary.IsPrimaryContact;
        //this.isPrimaryContactChanged = true;
      }
      else {
        this.parentModel.IsPrimaryContact = false;
        this.parentModel.IsEmergency = !this.patientPrimary.IsPrimaryContact;
        //this.isPrimaryContactChanged = false;
      }
    });
  }
  onBacklead() {
    if (this.pagefrom == 1) {
      this.router.navigate(['/leadlist']);
    }
    else if(this.pagefrom == 2){
      this.router.navigate(['/patientslists']);
    } 
    else if (this.pagefrom == 3){
      this.router.navigate(['/clinician']);
    }
    else {
      this.router.navigate(['/leadkanban']);
    }
  }  
  oncalenderView(){
    this.isShowCalenderView=true;
    
  }
  ontaskview(){
    this.isShowCalenderView=false;
  }
  toggleinfo(){
    this.istogglemenu = !this.istogglemenu;
  }
  onEditPatientinfo(parentId) {
   this.router.navigate(['/leadinfo'], { queryParams: { 
	id: 	this.commonService.FrontEndEncryption(this.patientInfoId), 
	email: this.commonService.FrontEndEncryption(this.patientPrimary[0]?.Email),
	name: this.commonService.FrontEndEncryption(this.patientdetail?.FirstName) , 
  isProfileonly: this.commonService.FrontEndEncryption(true),
  parentInfoId: this.commonService.FrontEndEncryption(parentId),
  isAdminClicked :this.commonService.FrontEndDecryption(true)
}  });
  }
  onEditLeadDetailInfo(parentId){
    // this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
    //   this.router.navigate(['/leadinfo']), { queryParams: { 
  this.router.navigate(['/leadinfo'], { queryParams: { 
	id: 	this.commonService.FrontEndEncryption(this.patientInfoId), 
	email: this.commonService.FrontEndEncryption(this.patientPrimary[1]?.Email),
	name: this.commonService.FrontEndEncryption(this.patientdetail?.FirstName) , 
  isProfileonly: this.commonService.FrontEndEncryption(true),
  parentInfoId: this.commonService.FrontEndEncryption(parentId??0),
  isAdminClicked :this.commonService.FrontEndDecryption(true)
}  });
  }
  
  // AddLeadDetails(parentId){
  //   this.router.navigate(['/leadinfo'], { queryParams: { 
  //     id: 	this.commonService.FrontEndEncryption(this.patientInfoId), 
  //     email: this.commonService.FrontEndEncryption(this.patientPrimary[0]?.Email),
  //     name: this.commonService.FrontEndEncryption(this.patientdetail?.FirstName) , 
  //     isProfileonly: this.commonService.FrontEndEncryption(true),
  //     parentInfoId: this.commonService.FrontEndEncryption(parentId),
  //     isAdminClicked :this.commonService.FrontEndDecryption(true)
  //   }  });
  // }
  onAddContact()
  {
    
    this.parentform.resetForm();
    this.ShowContactSavePopup=true;
    
    
  }
  onAddLead()
  {
    this.leadform.resetForm();
    this.ShowContactSavePopup=true;
  }
  onCloseContactSavePopup()
  {this.ShowContactSavePopup=false;}
  getddlMaster() {
    this.spinnerservice.show();
    this.leadservice.getAllMaster().subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this.ddlparentalRelationType = decrypt(res.parentalRelationType);
        }
      },
      err => {

      },
    );
  }
  onChangeAddress(term: any) {
    this.addlookup.AutocompleteAddress(term).then(data => {
      this.addressoption = data.result as any[];
    }).catch();
  }
  onselectaddress(item) {
    this.ddlStates = this.ddlstatemaster.filter((x) => x.StateCode == item.state);
    this.parentModel.StatesId = this.ddlStates[0].StatesId
    this.parentModel.Zip = item.zipcode;
    this.onloadcity(this.ddlStates[0].StatesId, item);

  }
  onloadcity(id, item) {
    this.leadservice.getcities(id).then(
      res => {
        this.ddlCities = this.commonService.decrypdata(res.cities);
        this.parentModel.City = this.ddlCities.filter((x) => x.CityName == item.city)[0].CitiesId;
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  getddlStateMaster() {
    this.spinnerservice.show();
    this.leadservice.getstateMaster().subscribe(
      res => {
        if (res != null) {
          this.ddlparentalRelationType = this.commonService.decrypdata(res.parentalRelationType);
          this.ddlstatemaster = this.commonService.decrypdata(res.states);
          this.ddlStates= this.ddlstatemaster;
         }
         
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onChangeState(state) {
   this.leadservice.getcities(state.value).then(
      res => {
        this.ddlCities = this.commonService.decrypdata(res.cities);;
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  addparent(form: NgForm){
    this.spinnerservice.show();
     if (!form.valid) {
       this.spinnerservice.hide();
       return;
     }
   
    this.parentModel.PatientInfoId = this.patientInfoId;
    this.parentModel.IsPrimaryContactChanged = this.parentModel.IsPrimaryContact == true ? true : false;
    this.parentModel.PrimaryMail = this.primaryMail;
    const dataToEncrypt = this.commonService.DataToEncrypt(this.parentModel, false);
      this.leadservice.postParent(dataToEncrypt).subscribe((res: any) => {
        if (res) {
          this.spinnerservice.hide();
          this.snackbar.success(res);
          this.getPatientdetailview();
          this.ShowContactSavePopup=false;

          }
      },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        }
      );
    }
    getProviderDetailList() {
      this.spinnerservice.show();
       this.leadservice.getProviderDetailsList(this.patientInfoId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.ProviderList = this.commonService.decrypdata(res.objInputString);
         this.dataSourceForProvider = new MatTableDataSource(this.ProviderList);
          this.dataSourceForProvider.paginator = this.paginatorForProvider;
          this.dataSource.sort = this.sortForProvider;
          this.totalSizeforProvider = this.ProviderList.length;
          
       this.showDdlProviderInfoCity=false;
        },
        err => {
          this.spinnerservice.hide();
        },
      );
    }
    getProviderDetails():any{
      let tmpdrp=this.drpPhysTypes;
          if(    this.ShowContactSavePopup==true){
              let  tmpdataalist=this.ProviderList;
             return tmpdrp.filter(function(array_el){
              return tmpdataalist.filter(function(anotherOne_el){
                 return anotherOne_el.PhysicianTypeId == array_el.value;
              }).length == 0
           });
          }
          else{
            return this.drpPhysTypes
          }
          
    }
    


   onChangeProviderinfoCity(city)
   {
    let stId=this.ddlCitiesProviderinfo.find(x=>x.CitiesId==city.value).StatesId;
    this.ddlStates.filter((item) => item.StatesId == stId);
    
   this.ObjProvider.StatesId=this.ddlStates.find(x=>x.StatesId==stId).StatesId;
   this.ObjProvider.CityId = city.value;
   this.isCityValid=false;
   }
   onChangeStateProviderinfo()
   {
     this.isStateValid=false;
   }
    OnEditProviderInfo(item){
      this.isAddProvider=true;
      this.IsEditProvider = true;
      this.ObjProvider = item;
      this.isStateValid=false;
  this.isCityValid=false;
  this.ObjProvider.CityId= item.CityId;

  this.CityName=this.ddlCitiesProviderinfo.find(x=>x.CitiesId==item.CityId).CityName;
  this.showDdlProviderInfoCity=false;
  this.isAddProvider=true;
  this.IsEditProvider = true;
  console.log(this.ObjProvider);
  console.log(this.drpPhysTypes);
  
  
  //
  // debugger;
  // this.masterservice.getSearchCity("AllValues").subscribe(
  //   data => {
  //     this.spinnerservice.hide();  
  //     debugger;
  //     this.filteredcities =this.commonService.decrypdata(data.objInputString);
  //     this.selectedCityId=this.filteredcities.find(x=>x.CitiesId==item.CityId).CitiesId;
      
  //     },
  // error =>this.spinnerservice.hide());

  //
  
    }
    oncloseProvider(){
      this.IsEditProvider = false;
      if (this.providerform) {
        this.providerform.resetForm();
      }
      // this.providerform.resetForm();
    }

    postProviderform(){
      if(this.ObjProvider.CityId==0){
        this.isCityValid = true;
        return;
      }
      this.isCityValid = false;
      this.isStateValid = false;
      if(this.ObjProvider.StatesId==0 && this.ObjProvider.CityId==0 ){
        this.isCityValid = true;
        this.isStateValid = true;
        return;
      }
      
      else{
       if(this.ObjProvider.StatesId ==0 && this.ObjProvider.CityId !=0 ){
        this.isStateValid = true;
        return;
       }
       if(this.ObjProvider.CityId==0 && this.ObjProvider.StatesId!=0){
        this.isCityValid = true;
        return
       }        

      }
      if(!this.providerform.valid){
        return
      }
      else{
        this.spinnerservice.show();
      
        const dataToEncrypt = this.commonService.DataToEncrypt(this.ObjProvider, false);
      this.leadservice.UpdateProviderDetails(dataToEncrypt).subscribe((res: any) => {     
      
          this.spinnerservice.hide();
          this.snackbar.success(res);
          this.providerform.resetForm();
          this.leadform?.resetForm();
          this.getProviderDetailList();
          this.IsEditProvider=false;
        
      },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        }
      );
      }
    }

    onloadcityforProvider(id) {
      
      this.leadservice.getcities(id).then(
        res => {
          this.ddlCitiesProviderinfo = this.commonService.decrypdata(res.cities);
             },
        error =>
          this.spinnerservice.hide()
      );
    }
 
    AddProvider(){
      this.IsEditProvider = true;
      this.ObjProvider=new  ProviderDetails();
    this.ObjProvider.PatientHistoryId = 0;
      this.ObjProvider.PhysicianDetailsId = 0;
      this.ObjProvider.IsAgree = false;
      this.isAddProvider=true;
      this.ObjProvider.DataFrom="history";
      this.isStateValid=false;
      this.isCityValid=false;
      this.ObjProvider.PatientInfoId = this.patientInfoId;
      this.showDdlProviderInfoCity=true;
      this.ObjProvider.CityId=0;
      
    }
    AddLead(){
      
    }
    
    OneditProviderinfoCity()
    {
     
      this.showDdlProviderInfoCity=true;
    }


    // getCityname(cityId: string) {
    //   debugger;
    //   if(cityId!="" && cityId!= null && cityId!="0"){
    //     let selectedItem;
    //     selectedItem=this.filteredcities.find(x => x.CitiesId === cityId);
    //     this.selectedCityId=this.filteredcities.find(x => x.CitiesId === cityId).CitiesId;
    //     this.ObjProvider.CityId= this.selectedCityId;
    //     if(this.ddlStates!="undefined" && this.ddlStates.length>0){
    //          this.ObjProvider.StatesId= this.ddlStates.find(x=>x.StatesId==selectedItem.StatesId).StatesId;
    //     }
    //   return this.filteredcities.find(x => x.CitiesId === cityId).CityName;
    //   }
    // }  
    close(){
      if(this.providerform.valid){
      this.IsEditProvider = false;
      // this.providerform.resetForm();
      
    }
    }

    saveName(){
     
      let _obj = {
        preferredName: this.patientdetail.PreferredName,
        patientInfoId :this.patientInfoId 
      }
      const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
      this.leadservice.postPreferredName(dataToEncrypt).subscribe((res: any) => {
        if (res) {
          this.spinnerservice.hide();
          this.snackbar.success("Data saved successfully")
          // this.snackbar.success(res);
          this.getPatientdetailview();
          // this.ShowContactSavePopup=false;

          }
      },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        }
      );
    }
    savePronouns(){
      let PreferredPronouns : string = this.patientdetail.PreferredPronouns.join(', ');
      let _obj = {
        preferredPronouns: PreferredPronouns,
        patientInfoId :this.patientInfoId 
      }
      const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
      this.leadservice.postPreferredPronouns(dataToEncrypt).subscribe((res: any) => {
        if (res) {
          this.spinnerservice.hide();
          this.snackbar.success("Data saved successfully")
          // this.snackbar.success(res);
          this.getPatientdetailview();
          // this.ShowContactSavePopup=false;

          }
      },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        }
      );
    }
    cancelName(){
      this.patientdetail.PreferredName = '';
    }
    cancelPronouns(){
      this.patientdetail.PreferredPronouns = '';
    }
  
        
    onChangeStateforclient(state) {
      this.leadservice.getcities(state.value).then(
         res => {
           this.ddlCitiesforclient = this.commonService.decrypdata(res.cities);;
         },
         error =>
           this.spinnerservice.hide()
       );
       this.isCityValid = true;

     }
     errorcitys(){
      this.isCityValid = true;
     }
     errorhide(){
      this.isCityValid = false;
     }
}

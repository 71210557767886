export class Blocks{
    meetingblockId:number;
    blockTypeId:number;
    blockTypeName:string;
    description:string;
    isActive:boolean=false;
    frequency: number;
    availableDays: any=[];

}
export class DtoPurposeMaster{
    purposeMasterId:number;
    PurposeName:string;
    isActive:boolean=false;
}
export class DtoColorCodeMaster{
    
    colorCodemasterid:number;
    ColorName:string;
    ColorCode:string;
    isActive:boolean=false;
}
export class Dtoblocktime{
    blockTimeId:number;
    purposeMasterId:number;
    colorCodeMasterId:number;
    colorCode:string;           
    isActive:boolean=false;
    purposeName:string;
    newcolorcode:string;
}
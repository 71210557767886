<div class="col-md-12">
    <div class="row">
        <div class="col-md-2 pr-0">
            <!-- Tabs nav -->


            <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">

                <!-- *ngIf="isUser" -->
                <a  class="nav-link mb-2 p-3 shadow cursor-pointer" title="Add Staff"
                    (click)="onTabChange(1)" [ngClass]="{'active': selectedTabIndex == 1}">
                    <i class="fas fa-users mr-2"></i>
                    <span class="label-settings small text-uppercase">Manage Staff</span></a>

                    <!-- *ngIf="isView" -->  <a  class="nav-link mb-2 p-3 shadow cursor-pointer" title="Manage Roles" 
                    (click)="onTabChange(2)" [ngClass]="{'active': selectedTabIndex == 2}">
                    <i class="fas fa-user-lock mr-2"></i>
                    <span class="label-settings small text-uppercase">Manage Roles</span></a>

                <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Manage Permission" (click)="onTabChange(3)"
                    [ngClass]="{'active': selectedTabIndex == 3}">
                    <i class="fas fa-user-cog mr-2"></i>
                    <span class="label-settings small text-uppercase">Manage Permission</span></a>
            </div>
        </div>


        <div class="col-md-10">
            <!-- Tabs content -->
            <div class="tab-content" id="v-pills-tabContent">
                <div class="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-home" role="tabpanel"
                    aria-labelledby="v-pills-home-tab" *ngIf="selectedTabIndex == 1">
                    <app-user-onboard></app-user-onboard>

                </div>

                <div class="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-profile" role="tabpanel"
                    aria-labelledby="v-pills-profile-tab" *ngIf="selectedTabIndex == 2">
                    <div class="roles-page">

                        <div class="col-md-12">
                            <div class="top-btn-section clearfix ">
                                <h2 class="head-section mt-2">Roles </h2>
                                <!-- <div class="ml-auto">
                                <button  type="button" class="btn primary-btn-outlined float-right mr-2" >
                                    <i class="fas fa-plus-circle mr-2"></i>Add New Roles
                                </button>
                            </div> -->
                            </div>
                            <!-- <div class="row" *ngIf="isCreate || datarole?.id != 0" -->
                                <div class="row">
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <mat-form-field class="w-100">
                                                <mat-label>Role Name</mat-label>
                                                <!-- <input matInput [disabled]="isRoleEdit" [(ngModel)]="datarole.roleName" required (input)="isReqName=false"> -->
                                                <input matInput  [(ngModel)]="datarole.roleName" required (input)="isReqName=false">
                                            </mat-form-field>
                                            <!-- <mat-error *ngIf="isReqName" style="font-size:12px;">Role Name is
                                                Required</mat-error> -->
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="w-100">
                                                <mat-label>Assigned To</mat-label>
                                                <mat-select [(ngModel)]="datarole.assignedTo" #assignedTo="ngModel" (selectionChange)="isReqAssigned=false"
                                                    name="assignedTo" required>
                                                    <ng-container *ngFor="let a of roles">
                                                        <mat-option *ngIf="a.isActive && a.id != datarole.id"
                                                            [disabled]="datarole.assignedTo == a.id" [value]="a.id">
                                                            {{a.roleName}}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>

                                            </mat-form-field>
                                            <mat-error *ngIf="isReqAssigned" style="font-size:12px;">Assigned To is
                                                Required</mat-error>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="w-100">
                                                <mat-label>Role Types</mat-label>
                                                <mat-select [(ngModel)]="datarole.roleTypeId"  (selectionChange)="isReqAssigned=false"
                                                    name="roleTypeId" required>
                                                    <ng-container *ngFor="let a of typeroles">
                                                        <mat-option 
                                                             [value]="a.roleTypeId">
                                                            {{a.roleTypeName}}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>

                                            </mat-form-field>
                                            <mat-error *ngIf="isReqAssigned" style="font-size:12px;">Role Types is
                                                Required</mat-error>
                                            <!-- <div  class="check-custom mt-4">
                                            <mat-checkbox class="example-margin">Active</mat-checkbox>
                                        </div> -->
                                        </div>

                                        <div class="col-md-4">
                                            
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-4 text-right"  *ngIf="roleAccess.isCreate || roleAccess.isEdit">
                                    <button mat-stroked-button
                                        class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2"
                                        (click)="saveRole()">Save</button>
                                    <button mat-stroked-button class="btn btn-cancel primary-btn-m h-40"
                                        (click)="addRole()">Cancel</button>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="col-md-8">
                                <div class="box-lead-head float-left mt-3 pt-1">Roles list</div>
                            </div>
                            <!-- <div class="top-btn-section float-right col-md-2">
                                <button mat-stroked-button
                                        class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="addRole()">Add</button>
                            </div> -->
                            <div class="col-md-12 table-custom p-0 mb-2">
                                <div class="table-responsive table-roles">

                                    <mat-table [dataSource]="dataSourceRole" matSort class="usertable">
                                        <ng-container matColumnDef="sno">
                                            <mat-header-cell style="width: 10% !important;" *matHeaderCellDef
                                                mat-sort-header>S.No </mat-header-cell>
                                            <mat-cell *matCellDef="let row;let i=index">{{i+1}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="rolename">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header> Role Name
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let row"> {{row.roleName}} </mat-cell>
                                        </ng-container>
                                        <!-- <ng-container matColumnDef="rolecategory" >
                                      <mat-header-cell *matHeaderCellDef mat-sort-header> Role Category </mat-header-cell>
                                      <mat-cell *matCellDef="let row" style="width: 100px;" > {{row.email}} </mat-cell>
                                    </ng-container> -->

                                        <ng-container matColumnDef="status">
                                            <mat-header-cell *matHeaderCellDef mat-sort-header> Status
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let row"> {{row.isActive ? 'Active' :
                                                'Inactive'}} </mat-cell>
                                        </ng-container>
                                        <ng-container matColumnDef="actions">
                                            <mat-header-cell *matHeaderCellDef> {{ (roleAccess.isEdit || roleAccess.isDelete) ? 'Actions' : '' }} </mat-header-cell>
                                            <mat-cell *matCellDef="let row;let i=index">
                                                <button  mat-icon-button matTooltip="Edit" *ngIf="roleAccess.isEdit"
                                                    class="iconbutton" color="primary" (click)="onEdit(row
                                                    )">
                                                    <mat-icon aria-label="Edit">edit</mat-icon>
                                                </button>
                                                <button  mat-icon-button matTooltip="Delete" *ngIf="roleAccess.isDelete"
                                                    class="iconbutton" color="warn" (click)="deleteRole(row)">
                                                    <mat-icon aria-label="Delete">delete</mat-icon>
                                                </button>
                                                
                                                <span (click)="activeRole(row)" *ngIf="roleAccess.isEdit">
                                                    <i class="fas fa-toggle-on fa-toogle ml-2" *ngIf="row.isActive"></i>
                                                    <i class="fas fa-toggle-off fa-toogle ml-2"
                                                        *ngIf="!row.isActive"></i>
                                                </span>
                                            </mat-cell>
                                        </ng-container>
                                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                        <mat-row [style.background-color]="(isEdit && row.id == this.datarole.id) ? 'aliceblue' : 'white'" *matRowDef="let row; columns: displayedColumns;">
                                        </mat-row>
                                    </mat-table>
                                    <mat-paginator [pageSize]="20"[pageSizeOptions]="[ 10, 20, 50, 75, 100]" showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-profile" role="tabpanel"
                    aria-labelledby="v-pills-profile-tab" *ngIf="selectedTabIndex == 3">
                    <app-manage-permissions></app-manage-permissions>
                    <div class="permission-page d-none">

                        <div class="col-md-12">
                            <div class="top-btn-section clearfix">
                                <h2 class="head-section mt-2">Permission </h2>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <mat-form-field>
                                                <mat-label>Select Role Name</mat-label>
                                                <mat-select>
                                                    <mat-option value="one">Clinician</mat-option>
                                                    <mat-option value="two">Administrator</mat-option>
                                                    <mat-option value="three">Executive</mat-option>
                                                    <mat-option value="four">Super Admin</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-12">
                                            <label _ngcontent-xld-c306="" class="list-head">Select Module</label>
                                            <div class="module-list">
                                                <div class="module-list-sec">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">Patient Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">Clinician Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">Executive Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">Admin Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="module-content-permisiion col-md-12">
                                            <div class="module-content-name">
                                                Patient Onboarding
                                            </div>
                                            <div class="module-perm-list">
                                                <div class="module-list-sec-perm">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">Make Patient Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec-perm">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">Edit Patient Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec-perm">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin"> Delete Patient Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec-perm">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin"> View Patient Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec-perm">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">Export pdf</mat-checkbox>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="module-content-permisiion col-md-12">
                                            <div class="module-content-name">
                                                Clinician Onboarding
                                            </div>
                                            <div class="module-perm-list">
                                                <div class="module-list-sec-perm">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">Make Clinician Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec-perm">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">Edit Clinician Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec-perm">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin"> Delete Clinician
                                                            Onboarding</mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec-perm">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">View Clinician Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec-perm">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">Export pdf</mat-checkbox>
                                                    </div>
                                                </div>
                                                <div class="module-list-sec-perm">
                                                    <div class="check-custom ">
                                                        <mat-checkbox class="example-margin">Patient Onboarding
                                                        </mat-checkbox>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>


                                    </div>

                                </div>
                                <div class="col-md-12 text-right">
                                    <button mat-stroked-button
                                        class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>
                                    <button mat-stroked-button class="btn btn-cancel primary-btn-m h-40">Cancel</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

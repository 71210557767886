import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/common/common.service';


@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private http: HttpClient, public commonService: CommonService) { }
  getPhonebook(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PhoneBook/GetPhoneBooks?id=' + this.commonService.DataToEncrypt(id, true));
  }
  postPhonebook(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PhoneBook/PostPhoneBooks',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  deletePhonebook(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PhoneBook/DeletePhoneBookById?id=' + this.commonService.DataToEncrypt(id, true));
  }
  postimportPhbook(frmData) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    return this.http.post(environment.BaseURI + 'gateway/PhoneBook/PhoneBookImport', frmData)
      .pipe(map(res => {

        return res;
      }));
  }
  DownloadedMTemplate(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetMasterTemplatePath?Id=' + id);
  }
  getSearchCity(serachtxt): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetSearchCities?SearchCity=' + this.commonService.DataToEncrypt(serachtxt, true));
  }
}

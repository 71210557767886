import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient, public commonService: CommonService) { }
  getAllMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetMaster');
  }
  getMeetinglocation() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetMeetingLocations/GetMeetingLocations');
  }
  getMeetinglocationGT(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetMeetingLocations/GetMeetingLocationsGT?locationId='+this.commonService.DataToEncrypt(id, true));
  }
  getMeetingRoom(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetMeetingLocations/GetMeetingRoom?locationId='+this.commonService.DataToEncrypt(id, true));
  }
  getLocations() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetLocations');
  }
  getAppointmentTypeNames() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetAppointmentTypeNames');
  }
  postAppointmentTypeName(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/SaveAppointmentTypeName',
    //return this.http.post('https://localhost:44368/api/Master/SaveAppointmentTypeName',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postMeetingLocation(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostMeetingLocations/PostMeetingLocation',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getMeetingCategories() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetAppointmentTypes/GetMeetingCategories');
  }
  getAppointmentTypes(){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetAppointmentTypes/GetAppointmentTypes');
    //return this.http.get<any>('https://localhost:44368/api/GetAppointmentTypes/GetAppointmentTypes');
  }
  getAppointmentTypeDetails(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetAppointmentTypes/GetAppointmentTypeDetails?Id=' + this.commonService.DataToEncrypt(id, true));
    //return this.http.get<any>('https://localhost:44368/api/GetAppointmentTypes/GetAppointmentTypeDetails?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getTimeSlots() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/getTimeSlots');
  }
  postMeetingAppoinment(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostAppointmentTypes/PostAppointmentTypes',
    //return this.http.post('https://localhost:44368/api/PostAppointmentTypes/PostAppointmentTypes',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  deleteAppointment(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostAppointmentTypes/DeleteAppointmentType?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  deleteMeetingLocation(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostMeetingLocations/DeleteMeetingLocation?Id=' + this.commonService.DataToEncrypt(id, true));
  }


  //#region SFCC-Location
  getSfccLocation() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetSfccLocation/GetLocations');
  }

  getLibraryNames() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetLibraryNames');
  }
  getLibraryImages(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetLibraryImages?id=' + this.commonService.DataToEncrypt(id, true));
  }

  postLibraryName(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostLibraryName',
    //return this.http.post('https://localhost:44358/api/PostIntake/PostLibraryName',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  deleteLibraryImage(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/DeleteLibraryImage?id=' + this.commonService.DataToEncrypt(id, true));
  }
  postimportImage(frmData) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/ImageUpload', frmData)
      .pipe(map(res => {

        return res;
      }));
  }
  postSfccLocation(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostSfccLocation/PostLocation',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  deleteSfccLocation(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostSfccLocation/DeleteLocation?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  //#endregion

  //#region Blocks
  postBlockReasonTypeName(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/SaveBlockTypeName',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }


  postBlockTypeName(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/SaveBlockType',
    //return this.http.post('https://localhost:44368/api/Master/SaveBlockType',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postMeetingBlocks(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostAppointmentTypes/PostMeetingBlock',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getBlocksMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetBlocksMaster');
  }
  getMeetingBlocks() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetAppointmentTypes/getMeetingBlocks');
  }
  deletemeetingblock(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostAppointmentTypes/DeleteMeetingBlock?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  //#endregion
  PostReArrangeClinician(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostAppointmentTypes/PostReArrangeClinician',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  getSchedulerTimeSlots() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetSchedulerTimeSlots');
  }
  postNotifyAuthsettings(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/SaveAuthNotifyTypeMaster',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postAuditsettings(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/postAuditsettings',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getAuthSettingsMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetAuthNotifySettings');
  }
  getAuditMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetAuditSettings');
  }
  checkGroupMeetingExist(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostTreatment/CheckGroupMeetingExist?id=' + this.commonService.DataToEncrypt(id, true));
  }
}

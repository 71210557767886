import { Component, OnInit,Input,Output,EventEmitter,ViewChild } from '@angular/core';

@Component({
  selector: 'app-word-counter-textarea',
  templateUrl: './word-counter-textarea.component.html',
  styleUrls: ['./word-counter-textarea.component.scss']
})
export class WordCounterTextareaComponent implements OnInit {
  @Input() text: any;
  @Input() maxlength: any;
  @Input() name: any;
  @Output() textChange = new EventEmitter<string>();
  @ViewChild('explanation', { static: true }) explanation: any;
  wordCount: number = 0;
  isShowCount : boolean = false;
  constructor() { }

  ngOnInit(): void {
    this.explanation = this.name;
  }
  updateWordCount() {
    this.isShowCount = true;
    this.wordCount =this.maxlength - (this.text != null ? this.text.length : 0) ;
    this.textChange.emit(this.text);
  }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { PatientService } from 'src/app/patient/patient.service';
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from 'moment';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
declare var require: any;
@Component({
  selector: 'app-intake-nutritional',
  templateUrl: './intake-nutritional.component.html',
  styleUrls: ['./intake-nutritional.component.scss']
})
export class IntakeNutritionalComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  queYesNo = constantVariables.questionType.YesNo;
  queMCSA = constantVariables.questionType.MCSA;
  queMCMA = constantVariables.questionType.MCMA;
  queDescriptive = constantVariables.questionType.Descriptive;
  queRatings = constantVariables.questionType.Ratings;
  queText = constantVariables.questionType.Text;
  IntakeNutritional = 4;
  lstIntakeNutritional: any;
  patientInfoId: any;
  patientAccountId: any;
  isReqRating: boolean = false;
  isReqRadio: boolean = false;
  isMovedChma: boolean = false;
  page: any;
  pdfNutritionfile: any;
  _dtoPatientInfo: any;
  roleAccess: RoelAccess;
  IsPatientAccount: boolean;
  isNew:boolean=false;
  formCount:any;
  count:number=1;
  adjustments:any;
  constructor(public global: Global, public patientservice: PatientService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute, private sanitizer: DomSanitizer,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.NutritionalQuestion;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
     this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.page = params.page;
        if(params.isChma){
        this.isMovedChma = Number(this.commonService.FrontEndDecryption(params.isChma).replace(/"/g, "")) == 0 ? false :true;
        }
       
      }
      this.getInTakeQuestions();
    });

  }
    totalCount(i: number, j: number) {
      let len= 0;
      for(let x = 0; x<i; x++) {
        len = len + this.adjustments[x].details.length;
      } 
      return len + (j+ 1);
    
  }
  NewQuestionnaire()
  {
    this.isNew=true;
    this.getInTakeQuestions();
  }
  getInTakeQuestions() {
    this.spinnerservice.show();
    this.patientservice.getNutritionalQuestion(this.IntakeNutritional, this.patientAccountId, this.isNew).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if(res!=null)
        {
          this.lstIntakeNutritional = cloneDeep(res);
          this._dtoPatientInfo = res.patientDetailedInfo;
          if(res.answers.formCount != null)
          {
            this.formCount=res.answers.formCount;
          }else{
            this.formCount=0;
          }
          
          this.isNew=false;
          this.calculateAge();
          this.adjustments=this.lstIntakeNutritional?.answers?.groupList;
        }else{
          this.snackbar.error(constantVariables.ErrorMessage.NutritionalError);
        }
       
      },
      err => {
        this.isNew=false;
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  rbAnswer(i,index, opt,j) {
    this.lstIntakeNutritional.answers.groupList[j].details[i].options.forEach((val: any, key: any) => {
      this.lstIntakeNutritional.answers.groupList[j].details[i].options[key].isAnswer = false;
    });
    this.lstIntakeNutritional.answers.groupList[j].details[i].options[index].isAnswer = true;
    this.isReqRadio = false;
  }
  cbkAnswer(index, opt, event,j) {
    this.lstIntakeNutritional.answers.groupList[j].details[index].options[opt].isAnswer = !this.lstIntakeNutritional.answers.groupList[j].details[index].options[opt].isAnswer;
    this.isReqRadio = false;
  }
  onrating(index, opt, event,j) {
    this.lstIntakeNutritional.answers.groupList[j].details[index].options[opt].rating = event;
    this.isReqRating = false;
  }
  onSaveNutritionQuestion(frmsurvey: NgForm) {

    // for (let i = 0; i > this.lstIntakeNutritional.answers.details.length; i++) {
    //   if (type == this.queYesNo || type == this.queMCSA || type == this.queMCMA) {
    //     if (this.lstIntakeNutritional.answers.details[i].options.filter(x => x.isAnswer).length == 0) {
    //       this.isReqRadio = true;
    //       return
    //     }
    //   } else if (type == this.queRatings) {
    //     if (this.lstIntakeNutritional.answers.details[i].options[0].rating == 0) {
    //       this.isReqRating = true;
    //       return
    //     }
    //   }
    // }

    this.lstIntakeNutritional.IsNutritional = true;
    //const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.spinnerservice.show();
    this.patientservice.saveIntakeNutritional(this.lstIntakeNutritional).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.getInTakeQuestions();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  openimage(content) {

    return this.sanitizer.bypassSecurityTrustUrl(content);

  }
  onbacktoMytask() {
    if(this.global.ismytask == undefined || this.global.ismytask){
      window.close(); 
    }
    else{
      this.router.navigateByUrl('/clinician');
    }
  }
  getNutritionPdfFile(item) {
   
      this.spinnerservice.show();
      this.patientservice.getNutritionPdfFile(this.patientAccountId, 13,this.formCount,0).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdfNutritionfile = res.filePath;
          if(item==1){
            window.open(this.pdfNutritionfile, '_blank');
          }
          else if(item==0){
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
          }
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    

  }

  calculateAge() {
   // this._dtoPatientInfo.age = moment().diff(this._dtoPatientInfo.dob, 'years');

  }
}

<div class="create-consent-page">
    <div class="top-btn-section clearfix mb-1">
      <h2 class="head-section mb-2 mt-1">Create Templates</h2>
      <div class="col-md-6 ml-auto mt-2 button-position">
        <button type="submit" form="templateinfo" *ngIf="roleAccess.isEdit || roleAccess.isCreate"
            class="btn primary-btn-filled primary-btn-s float-right mb-2 h-40 ml-2">{{!isedittemplate ?
            'Save':'Update'}}</button>
        
        <button type="button" (click)="previewtemplate()" [disabled]="frmtemplate.templateContent == undefined" *ngIf="roleAccess.isView"
            class="btn btn secondary-btn-filled primary-btn-s float-right ml-2">Preview</button>
            <button type="button" [routerLink]="['/consentlist']"
            class="btn btn-cancel primary-btn-s float-right h-40 ">Back</button>
    </div>
  
    </div>
    <div class="col-lg-12 col-md-12 p-0">
      <div class="lead-box">
  
        <form (ngSubmit)="templateinfo.form.valid  && postConsentTemplate(templateinfo)" id="templateinfo"
              autocomplete="off" #templateinfo="ngForm" novalidate>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
  
                <mat-form-field class="w-100">
                  <mat-label>Template category</mat-label>
                  <mat-select [(ngModel)]="frmtemplate.templateCategoryId" #TemplateCategoryId="ngModel"
                              (selectionChange)="onCatagoryChange($event)" name="TemplateCategoryId"
                              [ngClass]="{ 'is-invalid': templateinfo.submitted && TemplateCategoryId.invalid }"
                              required>
                    <mat-option *ngFor="let cat of templateCatagory" [value]="cat.templateCategoryId">
                      {{cat.category}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="templateinfo.submitted && TemplateCategoryId.invalid">
                    <mat-error *ngIf="TemplateCategoryId.errors.required">Template Category is required
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="example-full-width w-100">
                  <mat-label>Template Name</mat-label>
                  <mat-select [(ngModel)]="frmtemplate.templateNameId" #TemplateNameId="ngModel"
                              (selectionChange)="ontempNameChange($event)" name="TemplateNameId"
                              [ngClass]="{ 'is-invalid': templateinfo.submitted && TemplateNameId.invalid }"
                              required>
                    <mat-option *ngFor="let nam of templateNames" [value]="nam.templateNameId">
                      {{nam.templateName1}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="templateinfo.submitted && TemplateNameId.invalid">
                    <mat-error *ngIf="TemplateNameId.errors.required">Template Name is required
                    </mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3  mt-1">
                <!-- <mat-form-field class="w-100">
                    <mat-label>Location</mat-label>
                    <mat-select [formControl]="toppings" multiple>
                      <mat-option >Location1</mat-option>
                      <mat-option >Location2</mat-option>
                      <mat-option >Location3</mat-option>
                      <mat-option >Location4</mat-option>
                    </mat-select>
                  </mat-form-field> -->
                  <mat-form-field class="w-100">
                    <mat-label class="control-label-new">Location</mat-label>
                    <mat-select multiple [disabled]="isGlobalAccess" [(ngModel)]="frmtemplate.locationList"
                        (change)="locationchange($event)" #location="ngModel" name="location"
                        [ngClass]="{ 'is-invalid': templateinfo.submitted && location.invalid }"
                        required>
                        <mat-option #matOption *ngFor="let item of ddllocation" [value]="item.sfccMasterId"
                            [matOption.selected]="item.isActive">
                            {{item.locationName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="templateinfo.submitted && location.invalid">
                        <mat-error *ngIf="location.errors.required">Location is required</mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-1 pr-0">
                <div class="check-custom w-100 mt-4">
                    <mat-checkbox class="example-margin"  [(ngModel)]="frmtemplate.isGlobal"
                        (change)="onchangeAccesslvl($event)" name="accessLevel">
                        Global
                    </mat-checkbox>
                </div>
            </div>
              <div class="col-md-12" *ngIf="frmtemplate.templateNameId">
                
                <mat-label class="consent-ques pr-3">Would you like to add E-Signature to the Template</mat-label>
  
                <span class="pr-5 d-inline-block">
                  <mat-radio-group [(ngModel)]="frmtemplate.isEsign" #IsEsign="ngModel"
                                   name="IsEsign" required>
                    <mat-radio-button [value]='true' class="mr-1 my-1" (change)="ontempEsignfieldChange(true)"> Yes </mat-radio-button>
                    <mat-radio-button [value]='false' (change)="ontempEsignfieldChange(false)"> No </mat-radio-button>
                  </mat-radio-group>
                  <p *ngIf="frmtemplate.isEsign" class="m-0">
                    <mat-radio-group [(ngModel)]="frmtemplate.isMultiPartyDocument" #IsMultiPartyDocument="ngModel"
                                     name="IsMultiPartyDocument" required>
                      <mat-radio-button [value]='false'  (change)="ontempPartyfieldChange(false)" class="mr-1 my-1"> Single party document </mat-radio-button>
                      <mat-radio-button [value]='true' (change)="ontempPartyfieldChange(true)"> Multi-party document </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="templateinfo.submitted && IsMultiPartyDocument.invalid">
                      <mat-error *ngIf="IsMultiPartyDocument.errors.required">
                        Party document option is required
                      </mat-error>
                    </mat-error>
                  </p>
                  <mat-error *ngIf="templateinfo.submitted && IsEsign.invalid">
                    <mat-error *ngIf="IsEsign.errors.required">
                      E-Signature option is required
                    </mat-error>
                  </mat-error>
                </span>                    
              <span class=" d-inline-block float-right mt-4" *ngIf="(frmtemplate.isMultiPartyDocument === true || frmtemplate.isMultiPartyDocument === false)">
                <button type="submit" name="reset" class="btn primary-btn-filled primary-btn-s float-right mb-2 h-40 ml-2">Reset Form</button>
              </span>           
              </div>             
            </div>
          </div>
  
        </form>
        <div class="col-md-12 mt-3">
          <div class="row">
            <div class="col-md-3">
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="field-head">
                      E-Signature Fields
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="fileds-client" *ngFor="let item of templateFields">
                    <div *ngIf="item.fieldType == 'E-Signature' && (this.frmtemplate.isMultiPartyDocument !== '' && this.frmtemplate.isMultiPartyDocument !== null)">
                      <div class="temp-head field-value">{{item.fieldType}}</div>
                      <div class="temp-node" *ngFor="let a of item.fields">
                        <a (click)="onEsignInsertfieldValue(a.templateFieldValue, a.templateFieldsId, a.fieldType)"
                        class="field-value cursor-pointer" style="pointer-events: auto;cursor: allowed;" id = "{{a.templateFieldsId}}"><i class="fas fa-circle mr-2"></i>{{a.templateFieldName}}</a>
                      </div>
                    </div>           
                    <div *ngIf="item.fieldType == 'E-Signature Date' && (this.frmtemplate.isMultiPartyDocument !== '' && this.frmtemplate.isMultiPartyDocument !== null)">
                      <div class="temp-head field-value">{{item.fieldType}}</div>
                      <div class="temp-node" *ngFor="let a of item.fields">
                        <a (click)="onEsignInsertfieldValue(a.templateFieldValue, a.templateFieldsId, a.fieldType)"
                        class="field-value cursor-pointer" style="pointer-events: auto;cursor: allowed;" id = "{{a.templateFieldsId}}"><i class="fas fa-circle mr-2"></i>{{a.templateFieldName}}</a>
                      </div>
                    </div>         
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="field-head">
                      User Input Fields
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="fileds-client" *ngFor="let item of templateFields">
                    <div *ngIf="item.fieldType == 'User Inputs'">
                      <div class="temp-head field-value">{{item.fieldType}}</div>
                      <div class="temp-node" *ngFor="let a of item.fields">
                        <a (click)="onInsertfieldValue(a.templateFieldValue)"
                        class="field-value cursor-pointer"><i class="fas fa-circle mr-2"></i>{{a.templateFieldName}}</a>
                      </div>
                    </div>                  
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title class="field-head">
                      Auto Generate Fields
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="fileds-client" *ngFor="let item of templateFields">
                    <div *ngIf="item.fieldType == 'Auto Generate'">
                      <div class="temp-head field-value">{{item.fieldType}}</div>
                      <div class="temp-node" *ngFor="let a of item.fields">
                        <a (click)="onInsertfieldValue(a.templateFieldValue)"
                        class="field-value cursor-pointer"><i class="fas fa-circle mr-2"></i>{{a.templateFieldName}}</a>
                      </div>
                    </div>                  
                  </div>
                </mat-expansion-panel>                
              </mat-accordion>
            </div>
            <div class="col-md-9">
  
              <quill-editor #tempEditor [disabled]="frmtemplate.templateCategoryId == undefined"
                            style="height: 100%;" (onEditorCreated)="onEditorCreated($event)" [preserveWhitespace]="true"
                            [(ngModel)]="frmtemplate.templateContent" placeholder="Enter Text"
                            #TemplateContent="ngModel" name="TemplateContent" required [modules]="quillConfig">
              </quill-editor>
              <mat-error *ngIf="templateinfo.submitted && TemplateContent.invalid">
                <mat-error *ngIf="TemplateContent.errors.required">Template Content is required</mat-error>
              </mat-error>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<div class="modal previewmodel" id="myModal" [style.display]="isPreviewtemplate ? 'block' : 'none'">
  <div class="modal-dialog mb-2">
    <div class="modal-content email-compose">
      <div class="modal-header">
        <h4 class="modal-title">Preview Template</h4>
        <button aria-hidden="true" data-dismiss="modal" class="close" (click)="closepreviewtemp()"
                type="button"><i class="fas fa-times"></i></button>

      </div>
      <div class="modal-body">
        <div class="col-md-12 preview-body">

          <div class="ql-container ql-snow" style="border-width: 0;">
            <div class="ql-editor" style="white-space: pre;" [innerHTML]="frmtemplate.templateContent">
            </div>
          </div>
        </div>

      </div>
      <!-- <div class="modal-footer">
          <button mat-raised-button class="theme-small" (click)="closepreviewtemp()">Close</button>
      </div> -->
    </div>
  </div>
</div>
<!--Select Location pop up-->
<!-- <div class="comment-popup loc-sched-popup benchmark-popup-rating" >
  <div class="modal" tabindex="-1" role="dialog"  [style.display]="isAllLocation ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container p-3" role="document">
      <div class="modal-content border-0">
          <div class="modal-body">
             <div>
                <div class="text-center location-schedule">
                 <i class="fas fa-map-marked-alt"></i>
                  </div>
                <p  class="text-center font-weight-500">Please choose a location</p>
             </div>
           </div>
          </div>
         </div>
        </div>
  </div> -->
<!--Select Location pop up end-->
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private http: HttpClient, public commonService: CommonService) { }

  mail_text:any = '';

  getAllMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetMaster');
  }

  async getstateMasterForAllState() {
    let stateMasterRes=await this.http.get<any>(environment.BaseURI + 'gateway/Master/GetStatesMaster').toPromise();
      return stateMasterRes;
    }
  getstateMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetStatesMaster');
  }
  getPatientList(): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetPatientList');
  }
  getLeadKanbanView(): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetLeadKanbanView');
  }
  getPatientdetailview(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetPatientDetailView?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getPatientAdditionalinfo(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetPatientDetailView?id=' + this.commonService.DataToEncrypt(id, true));
  }
  deleteParentinfo(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/deleteParentinfo?id=' + this.commonService.DataToEncrypt(id, true));
  }
  // getProviderDetailLead(id): Observable<any> {
  //   return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetPatientDetailView?id=' + this.commonService.DataToEncrypt(id, true));
  // }
  getLeadHistory(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetActivityLogs?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getIsEmailExist(data) {

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/EmailValidation',
      JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }

  postLeadAccount(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/CreateLeadContact',
      JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  postInsurancedata(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    return this.http.post(environment.BaseURI + 'gateway/Upload/InsuranceUpload', data)
      .pipe(map(res => {

        return res;
      }));
    //return this.http.post(environment.BaseURI + 'gateway/Upload/InsuranceUpload', data, { reportProgress: true, observe: 'events' });
  }
  postInsuranceImage(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    return this.http.post(environment.BaseURI + 'gateway/Upload/InsuranceImageUpload', data)
      .pipe(map(res => {

        return res;
      }));
    //return this.http.post(environment.BaseURI + 'gateway/Upload/InsuranceUpload', data, { reportProgress: true, observe: 'events' });
  }
  postSkipInsurance(id,resubmit) {
      return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/PostSkipInsurance?Id=' + this.commonService.DataToEncrypt(id, true)+'&resubmt='+this.commonService.DataToEncrypt(resubmit?1:0, true));
  }
  getInsuranceMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetInsuranceMaster');
  }
  getInsurance(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetInusuranceList?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getInsuranceBill(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetInsuranceByPatientId?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getAuthDetails(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetAuthDetails?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getInsurancebyId(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetInusuranceById?id=' + this.commonService.DataToEncrypt(id, true));
  }
  deleteInsurance(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/DeleteInsuranceById?id=' + this.commonService.DataToEncrypt(id, true));
  }
  deleteInsuranceCopy(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/DeleteDocumentById?id=' + this.commonService.DataToEncrypt(id, true));
  }
  postLetsStarted(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/InitialLogin?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getImage(fileName: string) {
    return this.http.get(environment.BaseURI + 'gateway/Upload/DownloadFile?fileName=' + fileName, { responseType: 'blob' });
  }
  getPatientHistory(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetPatientHistory?id=' + this.commonService.DataToEncrypt(id, true));
  }
  postPaitentHistory(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/SavePatientHistory',
      JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  postVerifyPacket(id) {
    //return this.http.post(environment.BaseURI + 'gateway/LeadContact/PatientVerified?id=' + this.commonService.DataToEncrypt(id, true), null);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/PatientVerified',
      this.commonService.DataToEncrypt(id, false), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postReturnPacket(id) {
    //return this.http.post(environment.BaseURI + 'gateway/LeadContact/PatientVerified?id=' + this.commonService.DataToEncrypt(id, true), null);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/PatientReturn',
      this.commonService.DataToEncrypt(id, false), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  // getPhonebook() {
  //   return this.http.get<any>(environment.BaseURI + 'gateway/PhoneBook/GetPhoneBooks');
  // }
  getPhonebook(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PhoneBook/GetPhoneBooks?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getAuthorizationThreshold(locationId)
  {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetAuthorizationThreshold?locationId=' + this.commonService.DataToEncrypt(locationId, true));
  }
  postResendActivation(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Authenticate/ResendActivationMail',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getcities(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetCitiesMaster?id=' + this.commonService.DataToEncrypt(id, true)).toPromise();
  }


  //crba
  postProgramDetails(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostCrba/SaveProgramDetails',
    JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));


  }

  postParent(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/SaveParent',
    data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));


  }
  postBenefitDetails(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostCrba/SaveBenefitDetails',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  postAuthorization(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostCrba/SaveAuthorization',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  getCrbaDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetCrba/GetCrbaDetails?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getCrbaviewInsurance(id,insId,HistoryId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetCrba/GetCrbaDetailsinsurance?Id=' + this.commonService.DataToEncrypt(id, true)
    + '&InsuranceDetailsId=' + this.commonService.DataToEncrypt(insId, true)
    + '&HistoryId=' + this.commonService.DataToEncrypt(HistoryId, true));
  }

  getBasicDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetCrba/getBasicDetails?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  removeClinicalInfo(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostCrba/RemoveClinicalInformation?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  //

  //Note
  getActiveParentlst(){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetNotes/GetActiveParentList');
  }
  getNotes(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetNotes/GetNotes?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  postNotes(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostNotes/PostNotes',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  deleteNote(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostNotes/DeteleNotes?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  pinNote(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostNotes/PinNotes?Id=' + this.commonService.DataToEncrypt(id, true));
  }

  deleteNotes(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostNotes/DeteleAllNotes',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  removeTag(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostNotes/RemoveTag?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  //

  getfinancialReason(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetFinancialProposal/GetReason??Id=' + this.commonService.DataToEncrypt(id, true));
  }

  postEsignature(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/PostEsignature',
      JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  // getLeadCurrentStatus(id){
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GetFinancialProposal/GetLeadCurrentStatus?id=' + this.commonService.DataToEncrypt(id, true));
  // }

  getAuthorizationTypeMaster(){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetAuthorizationType');
  }
  getAuthorizationNotificationTypeMaster(){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetAuthorizationNotificationType');
  }
  getInsuranceDetailListByPatient(id){
    return this.http.get<any>(environment.BaseURI+'gateway/LeadContact/GetInsuranceDetailsBypatient??patientinfoId='+ this.commonService.DataToEncrypt(id, true));

  }
  // ChangeInsuranceActive(id,isActive,patientInfoId){
  //   return this.http.get<any>(environment.BaseURI+'gateway/LeadContact/ChangeInsuranceActive??Id='+ this.commonService.DataToEncrypt(id, true)+ '&isActive='+ this.commonService.DataToEncrypt(isActive, true) +'&patientInfoId='+ this.commonService.DataToEncrypt(patientInfoId, true));

  // }

  ChangeInsuranceActive(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/ChangeInsuranceActive',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  

  postInsAuthorizationDtl(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    console.log(data)
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/PostPatientInsAuthorization',
    data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }

  getInsuranceAuthorizationDtl(id){
    return this.http.get<any>(environment.BaseURI+'gateway/LeadContact/GetInsAuthorizationList??patientinfoId='+ this.commonService.DataToEncrypt(id, true));

  }
  RemoveInsuranceAuthorizationDtl(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/RemoveInsAuthorizationById?id=' + this.commonService.DataToEncrypt(id, true));
   
  }

  GetInsAuthorizationStatus(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/InsAuthorizationStatus?id=' + this.commonService.DataToEncrypt(id, true));
  }
  ChkPaymentDtlExists(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/ChkPaymentDtlsExists?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getAuthorizationDetailTrack(){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetAuthorizationTrack');
  }
  // check(){
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/AuthorizationThresholdNotification');
  // }
  postAttachment(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    return this.http.post(environment.BaseURI + 'gateway/Upload/UploadAttachment', data)
      .pipe(map(res => {

        return res;
      }));
    
  }
  getAdminDashboardAlertLists(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetAdminDashboardAlertLists?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  updateActionForAlerts(patientAccountid,acctionId){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/updateActionForAlerts?patientInfoId=' + this.commonService.DataToEncrypt(patientAccountid, true)+'&acctionId='+this.commonService.DataToEncrypt(acctionId, true));
  }
  getAdminLoginLeadLists(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetAdminLoginLeadLists?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  

  getProviderDetailsList(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetProviderDetailsList?Id=' + this.commonService.DataToEncrypt(id, true));
  }

  
  UpdateProviderDetails(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PhoneBook/SavedProviderContactinfoFromClientDashBoard', data,
    { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
    
  }
  // RemoveLeadAccount(id,IsActive){
  //   return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/DeclainLeadAccount?Id=' + this.commonService.DataToEncrypt(id, true)
  //   +'&Isactive=' + this.commonService.DataToEncrypt(IsActive, true));
  // }
  //RemoveLeadAccount(id,IsActive,selectedreason,selectedcomments,){
    //return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/DeclainLeadAccount?Id=' + this.commonService.DataToEncrypt(id, true)
  //  +'&Isactive=' + this.commonService.DataToEncrypt(IsActive, true)
  //  +'&Selectedreason=' + this.commonService.DataToEncrypt(selectedreason, true)
  //  +'&Selectedcomments=' + this.commonService.DataToEncrypt(selectedcomments, true));
  //}
  //getAdminLoginDeclainList(id){
  //  return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/getAdminLoginDeclainList?Id=' + this.commonService.DataToEncrypt(id, true));
  //}

  getTreatmentNotes(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetNotes/GetTreatmentNotes?Id=' + this.commonService.DataToEncrypt(id, true));
  }

  PostTreatmentNotes(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostNotes/PostTreatmentNotes',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  EditTreatmentNotes(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostNotes/PostTreatmentNotes',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  deleteTreatmentNotes(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostNotes/DeteleTreatmentNotes?Id=' + this.commonService.DataToEncrypt(id, true));

  }
  postPreferredName(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/SavePreferredName',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  postPreferredPronouns(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/SavePronouns',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }


  RemoveLeadAccount(id, IsActive, selectedreason, selectedcomments){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/DeclainLeadAccount?Id=' + this.commonService.DataToEncrypt(id, true)
    +'&Isactive=' + this.commonService.DataToEncrypt(IsActive, true)
    +'&Selectedreason=' + this.commonService.DataToEncrypt(selectedreason, true)
    +'&Selectedcomments=' + this.commonService.DataToEncrypt(selectedcomments, true));
  }
  getAdminLoginDeclainList(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/getAdminLoginDeclainList?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getInsuranceHistoryDetails(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetInsuranceHistoryDetails?id=' + this.commonService.DataToEncrypt(id, true));
  }
  postUpdateInsurance(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Upload/UpdateInsurance',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getInsuranceAuthorizationDtlNewCrba(id,insuranceId,HistoryId){
    return this.http.get<any>(environment.BaseURI+'gateway/LeadContact/GetInsAuthorizationListNewCrba?patientinfoId='+ this.commonService.DataToEncrypt(id, true)
  
    +'&insuranceId=' + this.commonService.DataToEncrypt(insuranceId, true)
    +'&historyId=' + this.commonService.DataToEncrypt(HistoryId, true));

  }
  getInsuranceId(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetInsuranceId?id=' + this.commonService.DataToEncrypt(id, true));
  }

  getFinancialDocuments(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetFinancialDocuments?id=' + this.commonService.DataToEncrypt(id, true));
  }
}

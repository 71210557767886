<div class="sb-nav-fixed">
    <app-site-header></app-site-header>
    <!-- <app-clinician-layout></app-clinician-layout> -->
    <div id="layoutSidenav">     
        <div id="layoutSidenav_content">
            <main>               
                <div class="main-content">                    
                        <router-outlet></router-outlet>                   
                </div>
            </main>
            <!-- <app-site-footer></app-site-footer> -->
        </div>
    </div>
</div>
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { PatientService } from 'src/app/patient/patient.service';
import _Quill from 'quill';
import { DomSanitizer } from "@angular/platform-browser";
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { IntakegenerateService } from 'src/app/patient/intake/intake-generate/intakegenerate.service';
import { saveAs } from 'file-saver';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { DatePipe } from '@angular/common';
declare var require: any;

@Component({
  selector: 'app-intake-generate',
  templateUrl: './intake-generate.component.html',
  styleUrls: ['./intake-generate.component.scss']
})
export class IntakeGenerateComponent implements OnInit {
  selectedtab: number = 1;
  patientAccountId: any;
  patientViewData: any;
  patientData: any;
  parentData: any;
  marital: any;
  physicianDetails: any;
  psychiatristDetails: any;
  therapistDetails: any;
  neurologistDetails: any;
  caseManagerDetails : any ;
  patienthistoryinfo: any;
  lstIntakeConsent: any;
  isDynamicField: boolean = false;
  templateData: any;
  templateFields: any;
  consentTemplatesId: any;
  isVerified: boolean = false;
  consentCompleted: any;
  dtoButtonFieldList: any;
  defaultImage: any;
  isAttachEsign: boolean;
  dynamicimgsrcid: any = [];
  primaryParentId: number;
  isfutureSign: boolean;
  isESignAttached: boolean;
  signatureImg: any;
  updatedsignatureImg: any;
  isformtype: string;
  lstIntakeSurvey: any;
  currentQuestion: any;
  sourceQuestion: any;
  isAnswerRequired: boolean = false;
  isCommentsRequired: boolean = false;
  cecklist = constantVariables.inTakeForms.Checklist;
  healthScreening = constantVariables.inTakeForms.HealthScreening;
  survey = constantVariables.inTakeForms.Survey;
  queYesNo = constantVariables.questionType.YesNo;
  queMCSA = constantVariables.questionType.MCSA;
  queMCMA = constantVariables.questionType.MCMA;
  queDescriptive = constantVariables.questionType.Descriptive;
  queRatings = constantVariables.questionType.Ratings;
  documentType: any;
  pdfPatientInformationIntake: any;
  patientName: any;
  patientfullName: any;
  page: any;
  isMovedChma: boolean = false;
  _dtoMedications = [];
  patientInfoId: any;
  selectedConsent: number = 0;
  selectedPhysicianId: any=0;
  lstAuthorizationConsent: any;
  CalculatePercentage: any;
  roleAccess: RoelAccess;
  isShowPopupForDownload:boolean=false;
  isDownloadShow:boolean=false;
  isShowPopupForDownloadConfirmation:boolean=false;
  pdfGeneratedDate:any;
  pdfGeneratedTime:any;
  medicationIndex: any;
  vitaminIndex: any;
  _dtoVitamins: any;
  _dtoMedicationsCheckList: any;
  IsEditMedication: boolean;
  isSubmitForm: any;
  IsEditVit: boolean;
  configureFormId : number = 0;
  clinicianId : any;
  isClinician : boolean = false
  constructor(public service: IntakegenerateService, public spinnerservice: NgxSpinnerService, public commonService: CommonService
    , private route: ActivatedRoute, public patientservice: PatientService, private snackbar: SnackBarService
    , private sanitizer: DomSanitizer, private dialog: MatDialog, public global: Global,
    private router: Router,public roleaccessservice:PageAccessService,public datepipe: DatePipe) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.GenerateIntake;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.clinicianId = Number(localStorage.getItem("id"))
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patientid).replace(/"/g, ""));
        this.page = params.page;
        this.isMovedChma = Number(this.commonService.FrontEndDecryption(params.isChma).replace(/"/g, "")) == 0 ? false : true;
        this.onGeneratedPdfExist();
        this.onclickpacket(1);
      }

      this.getPatientView();
      //this.onPrintPDFFile();

    });
  }
  OnGenarateIntakePdfDownload(item){
    this.isShowPopupForDownloadConfirmation=false;
    this.documentType = 7;
    this.getPatientInfoPDFFile(item);
  }
  OnDownloadDetailspopupClose(){
    this.isShowPopupForDownloadConfirmation=false;
  }

  onclickpacket(tab) {

    //this.d1.nativeElement.addEventListener("mousedown", this.updateDisplay, false);
    // this.d1.nativeElement.insertAdjacentHTML('beforeend', '<div style="top: 50px;left: 200px;position: absolute;" class="two"> <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><g id="Layer_1"><path fill="#CBFFFF" d="M301.4600507986862,258.6364464450192 L304.1338123731426,250.2930254367168 L311.13377163869535,245.1364463202779 L319.78633051083625,245.1364463202779 L326.78633299787106,250.2930254367168 L329.4600504112491,258.6364464450192 L326.78633299787106,266.9798674533206 L319.78633051083625,272.1364456259677 L311.13377163869535,272.1364456259677 L304.1338123731426,266.9798674533206 L301.4600507986862,258.6364464450192 z" id="svg_2" stroke="#000"></path></g></svg></div>');


    this.selectedtab = tab;
    if (tab == 1) {
      this.getPatientView();
    }
    else if (tab == 2) {
      this.getPatientHistory();
      this.getPsychotropicMedication();
    }
    else if (tab == 3) {
      this.getInTakeQuestions(this.healthScreening);
    }
    else if (tab == 4) {
      this.getInTakeTemplates();
    }
    else if (tab == 5) {
      this.configureFormId = constantVariables.ConfigureFormType.CheckList;
      this.getInTakeQuestions(this.cecklist);
      this.getPatientMedication();
      this.getPatientVitamins();
      this.onAddVitamin();
      this.onAddMedicine();   
    }
    else if (tab == 6) {
      this.getInTakeQuestions(this.survey);
    }

  }
  getPatientView() {
    this.spinnerservice.show();
    this.service.getPatientView(this.patientAccountId).subscribe(
      (res: any) => {

        this.spinnerservice.hide();
        this.patientViewData = res;
        // console.log(this.patientViewData)
        this.patientData = this.patientViewData.patientData;
        this.patientName = this.patientData.firstName;
        this.patientfullName = this.patientData.firstName + " "+  this.patientData.lastName; 
        this.parentData = this.patientViewData.parentData;
        this.primaryParentId = this.parentData.parentInfoId;
        var ddlmaritalStatus = constantVariables.maritalStatus;
        this.marital = ddlmaritalStatus.find(x => x.value == this.patientData.maritalStatus) != null ? ddlmaritalStatus.find(x => x.value == this.patientData.maritalStatus).label : '';
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  getPatientHistory() {
    this.spinnerservice.show();
    this.service.getPatientViewHistory(this.patientAccountId).subscribe(
      (res: any) => {

        this.spinnerservice.hide();
        this.patienthistoryinfo = res;
        this.physicianDetails = this.patienthistoryinfo.objDtoPatientHistory.physicianDetails
        this.psychiatristDetails = this.patienthistoryinfo.objDtoPatientHistory.psychiatristDetails;
        this.therapistDetails = this.patienthistoryinfo.objDtoPatientHistory.therapistDetails;
        this.neurologistDetails = this.patienthistoryinfo.objDtoPatientHistory.neurologistDetails;
        this.caseManagerDetails = this.patienthistoryinfo.objDtoPatientHistory.caseMangerDetails;
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getInTakeTemplates() {
    this.spinnerservice.show();
    this.patientservice.getIntakeTemplates(this.patientAccountId).subscribe(
      (res: any) => {
        debugger;
        this.spinnerservice.hide();
        this.lstIntakeConsent = cloneDeep(res);
        this.lstAuthorizationConsent = cloneDeep(res);
        this.CalculatePercentage = cloneDeep(res);
        this.CalculatePercentage = this.CalculatePercentage.filter(x=>x.clinicianTypeId==0)
        this.lstAuthorizationConsent[0]["isActive"] = true;
        this.lstAuthorizationConsent = this.lstAuthorizationConsent.filter(x=>x.templateNameId==2 )
        this.authtemplatechange();
        this.lstAuthorizationConsent.forEach(element => {
          element["isActive"] = false;
        });

        if (this.consentTemplatesId == undefined) {
          this.consentTemplatesId = this.lstIntakeConsent[0].consentTemplatesId;
          this.onloadTemplate(this.lstIntakeConsent[0].consentTemplatesId, this.lstIntakeConsent.filter(x => x.consentTemplatesId == this.consentTemplatesId)[0].isVerified, this.selectedConsent,this.lstIntakeConsent[0].clinicianTypeId,0);

        } else {
          this.onloadTemplate(this.consentTemplatesId, this.lstIntakeConsent.filter(x => x.consentTemplatesId == this.consentTemplatesId)[0].isVerified, this.selectedConsent,this.lstIntakeConsent[0].clinicianTypeId,0);
        }

        this.isVerified = this.lstIntakeConsent.filter(x => x.consentTemplatesId == this.consentTemplatesId)[0].isVerified;
        var countverified = this.CalculatePercentage.filter(x => x.isVerified == true).length
        //this.consentCompleted = (this.lstIntakeConsent.length / 100) * countverified * 100;
        this.consentCompleted = (countverified / this.CalculatePercentage.length) * 100;
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }


  // addNewTesmplate(consentTemplatesId, isVerified, i,clinicianTypeId,index){
  //   var newTemplate = this.lstAuthorizationConsent[index]
  //   newTemplate["isActive"] = false
  //   this.lstAuthorizationConsent.push(newTemplate)
  //  this.lstAuthorizationConsent.forEach(e => e["isActive"] = false);
  //   this.onloadTemplate(consentTemplatesId, isVerified, i,clinicianTypeId,index+1) 
  // }
  
  onloadTemplate_new(consentTemplatesId, isVerified, i,clinicianTypeId,index){
    this.onloadTemplate(consentTemplatesId, isVerified, i,clinicianTypeId,index) 
  }
  onloadTemplate(consentTemplatesId, isVerified, i,clinicianTypeId,index) {
    this.lstAuthorizationConsent.forEach(e => e["isActive"] = false);
    if(index) {
      this.lstAuthorizationConsent[index]["isActive"] = true;
    } else {
      this.lstAuthorizationConsent[0]["isActive"] = true;
    }
    
    this.selectedConsent = i
    this.selectedPhysicianId= clinicianTypeId;
    this.isVerified = isVerified;
    if (consentTemplatesId == 0) {
      this.snackbar.error(constantVariables.ErrorMessage.template);
      return
    }
    this.consentTemplatesId = consentTemplatesId;
    this.isVerified = this.lstIntakeConsent.filter(x => x.consentTemplatesId == consentTemplatesId)[0].isVerified;
    this.spinnerservice.show();
    this.patientservice.getTemplateDataClinician(consentTemplatesId, this.patientAccountId, true,clinicianTypeId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        console.log(res)
        this.templateData = cloneDeep(res.template);
        this.templateData.templateContent = this.sanitizer.bypassSecurityTrustHtml(this.templateData.templateContent);
        this.templateFields = cloneDeep(res.fields);
        this.dtoButtonFieldList = res.dtoButtonFieldList;
        this.defaultImage = res.defaultImage;
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  postDynamicFields() {
    // for (let i = 0; i < this.templateFields.length; i++) {
    //   if (this.templateFields[i].fieldInputTypeId == 1) {
    //     if (this.templateFields[i].description == null) {
    //       return
    //     }
    //   }
    // }
    for (let i = 0; i < this.templateFields.length; i++) {
      let errornode = <HTMLInputElement>document.getElementById(this.templateFields[i].errFieldId);
      if (this.templateFields[i].fieldInputTypeId == 1 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null) {

        let value = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).value;
        this.templateFields[i].description = value
        if (this.templateFields[i].isMandatory && (this.templateFields[i].description == null || this.templateFields[i].description == "")) {
          errornode.innerText = " " + constantVariables.ErrorMessage.RequiredFieldValidation;
          return
        } else {
          if (errornode != null) {

            errornode.innerText = "";
          }
        }
      }
      else if (this.templateFields[i].fieldInputTypeId == 4 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null) {
        let element = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).checked;
        this.templateFields[i].isChecked = element
        if (this.templateFields[i].isMandatory && (!this.templateFields[i].isChecked)) {
          errornode.innerText = " " + constantVariables.ErrorMessage.RequiredFieldValidation;
          return
        } else {
          if (errornode != null) {
            errornode.innerText = "";
          }
        }


        if (this.templateFields[i].isChecked && this.templateFields[i].checkFieldId != "") {
          var checkfield = (<HTMLInputElement>document.getElementById(this.templateFields[i].checkFieldId));
          if (typeof checkfield !== 'undefined' && checkfield !== null) {
            let value = (<HTMLInputElement>document.getElementById(this.templateFields[i].checkFieldId)).value;
            this.templateFields[i].description = value
            if (this.templateFields[i].description == null || this.templateFields[i].description == "") {
              errornode.innerText = "The field value is required";
              return
            } else {
              errornode.innerText = "";
            }
          }

        }
      }
      else if (this.templateFields[i].fieldInputTypeId == 3 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null) {
        let value = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).src;
        this.templateFields[i].imageDescription = value;

        this.templateFields[i].isAttachEsign =true ;
        this.isAttachEsign=true;
        if (this.isAttachEsign === null || this.isAttachEsign === undefined) {
          errornode.innerText = " " + constantVariables.ErrorMessage.RequiredFieldValidation;
          return
        }
        if (this.templateFields[i].isMandatory && (this.templateFields[i].imageDescription == null || this.templateFields[i].imageDescription == "")) {
          errornode.innerText = " " + constantVariables.ErrorMessage.RequiredFieldValidation;
          return
        } else {
          if (errornode != null) {

            errornode.innerText = "";
          }
        }
      }
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(this.templateFields, false);
    this.spinnerservice.show();
    this.patientservice.postUpdateDynamicFields(this.templateFields,this.selectedPhysicianId ).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);

        //this.onloadTemplate(this.consentTemplatesId);
        this.getInTakeTemplates();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  openDialog(imageId) {
    const oldImage = (document.getElementById(imageId) as HTMLImageElement).src;
    const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
      { data: { parentinfoId: this.primaryParentId, isfutureSign: this.isfutureSign, isParentsign: true } });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign) {
        this.signatureImg = this.updatedsignatureImg;
        this.isAttachEsign = true;
        this.postUpdateVerified(true);
      }
      if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.isAttachEsign = true;
        this.postUpdateVerified(true);
      }
      else {
        this.signatureImg = oldImage;
      }
      (document.getElementById(imageId) as HTMLImageElement).src = this.signatureImg;
    });
  }
  toggleHelp(input, e: MouseEvent): void {
    input.helpOpen = !input.helpOpen;
    if ((e.target as HTMLElement).tagName == 'IMG') {
      var id = (e.target as HTMLElement).id;
      let toArray = id.split("_");
      this.openDialog('Img_' + toArray[1]);
      this.dynamicimgsrcid.push('Img_' + toArray[1]);
    }
  }
  postUpdateVerified(data) {
    this.isVerified = data;
    let obj = {
      ConsentTemplatesId: this.consentTemplatesId,
      PatientAccountId: this.patientAccountId,
      IsVerified: data,
      PhysicianTypeId : this.selectedPhysicianId
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(this.templateFields, false);
    this.spinnerservice.show();
    this.patientservice.postUpdateVerified(obj).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //this.snackbar.success(JSON.parse(res).message);
        //this.getInTakeQuestions();
        // var countverified=this.lstIntakeConsent.filter(x=> x.isVerified == true).length +1;
        // this.consentCompleted= (this.lstIntakeConsent.length  / 100) * countverified *100;

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );

  }
  //Get Questions
  getInTakeQuestions(type) {
    this.spinnerservice.show();
    this.currentQuestion = null;
    this.patientservice.getInTakeQuestions(type, this.patientAccountId,this.configureFormId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.lstIntakeSurvey = cloneDeep(res);
        console.log(this.lstIntakeSurvey);
        this.medicationIndex = this.lstIntakeSurvey.answers.findIndex(x=>x.questionTypeId==2);
        this.vitaminIndex = this.lstIntakeSurvey.answers.findIndex(x=>x.questionTypeId==3);
        console.log(this.medicationIndex);
        console.log(this.vitaminIndex);
        this.configureFormId = constantVariables.ConfigureFormType.NoForm;
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onEdit(item) {
    this.currentQuestion = item;
    this.sourceQuestion = item;
  }

  onCancel() {
    this.isAnswerRequired = false;
    this.isCommentsRequired = false;
    if (this.selectedtab == 3) {
      this.getInTakeQuestions(this.healthScreening);
    }
    else if (this.selectedtab == 5) {
      this.configureFormId = constantVariables.ConfigureFormType.CheckList;
      this.getInTakeQuestions(this.cecklist);
    }
    else if (this.selectedtab == 6) {
      this.getInTakeQuestions(this.survey);
    }
  }
  postIntakeChecklist() {
    var valid = true;
    if (this.currentQuestion.isAnswer == undefined || this.currentQuestion.isAnswer == null) {
      this.isAnswerRequired = true;
      valid = false;
    }
    else {
      this.isAnswerRequired = false;
    }


    if (this.currentQuestion.iSquestionExplanation == 1 && (this.currentQuestion.explanation == undefined || this.currentQuestion.explanation == null || this.currentQuestion.explanation == "")) {
      this.isCommentsRequired = true;
      valid = false;
    }
    else {
      this.isCommentsRequired = false;
    }
    if (!valid)
      return;
    this.spinnerservice.show();
    this.patientservice.postIntakecheckAnswer(this.currentQuestion).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        if (this.selectedtab == 3) {
          this.getInTakeQuestions(this.healthScreening);
        }
        else if (this.selectedtab == 5) {
          this.configureFormId = constantVariables.ConfigureFormType.CheckList;
          this.getInTakeQuestions(this.cecklist);
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onGeneratePDF() {
    this.isShowPopupForDownload = true;
  }
  OncloseDownloadDetailspopup(){
    this.isShowPopupForDownload = false;
  }
  onDownloadPDF(){
    this.isShowPopupForDownloadConfirmation =true;
  }
  OnGenarateIntakePdf(){
    this.isShowPopupForDownload = false;
    this.documentType = 7;
    this.getPatientInfoPDF();
  }
  onGeneratedPdfExist(){
    this.spinnerservice.show();
    this.documentType=7;
    this.service.CheckGeneratedPdfExist(this.patientAccountId, this.documentType).subscribe(
      (res: any) => {
        if(res != null)
        {
          this.pdfGeneratedDate = res.createdOn;
          this.pdfGeneratedTime = this.datepipe.transform(res.createdOn, "hh:mm a");
          this.isDownloadShow=true;
        }
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getPatientInfoPDFFile(item) {
    this.spinnerservice.show();
    this.service.getPatientInfoPDFFile(this.patientAccountId, this.documentType).subscribe(
      (res: any) => {
        if(item==0)
        {
          var blob = new Blob([res]);
          let saveAs = require('file-saver');
          let file = this.patientName + '.pdf';
          saveAs(blob, file);
          this.spinnerservice.hide();
          this.snackbar.success('PDF Downloaded successfully');
          this.onPrintPDFFile(item)
        }else{
          var blob = new Blob([res]);
          let saveAs = require('file-saver');
          let file = this.patientName + '.pdf';
          //saveAs(blob, file);
          this.spinnerservice.hide();
          this.onPrintPDFFile(item)
        }
       
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  getPatientInfoPDF() {
    // this.spinnerservice.show();
    this.service.getPatientInfoPDF(this.patientAccountId,this.clinicianId = 0,this.isClinician = false).subscribe(
      (res: any) => {
        // this.getPatientInfoPDFFile();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  onPrintPDFFile(item) {
    this.documentType = 7;
    this.service.getPrintPDFFile(this.patientAccountId, this.documentType).subscribe(
      (res: any) => {
        this.pdfPatientInformationIntake = res.filePath;
        if(item==1){
          window.open(this.pdfPatientInformationIntake, '_blank');
        }
         
      },
      err => {
      },
    );
  }
  onrating(index, opt, event) {
    this.currentQuestion.options[opt].rating = event;
  }
  rbAnswer(index, opt) {
    this.currentQuestion.options.forEach((val: any, key: any) => {
      this.currentQuestion.options[key].isAnswer = false;
    });
    this.currentQuestion.options[opt].isAnswer = true;
    //this.isReqRadio = false;
  }
  cbkAnswer(index, opt, event) {
    this.currentQuestion.options[opt].isAnswer = !this.currentQuestion.options[opt].isAnswer;
    //this.isReqRadio = false;
  }
  onNextQuestion() {

    if (this.currentQuestion.options.filter(x => x.isAnswer).length > 0 || this.currentQuestion.options[0].rating > 0 || this.currentQuestion.options[0].description != '') {
      let obj = {
        Answer: this.currentQuestion,
        InTakeProgress: this.lstIntakeSurvey.inTakeProgress,
        PatientAccountId: this.patientAccountId,
        //Total: this.totalquestions
      }
      const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
      this.spinnerservice.show();
      this.patientservice.postIntakeSurvey(obj).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getInTakeQuestions(this.survey);

        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    } else {

    }

  }
  onbacktoMytask() {
    if (this.global.ismytask == undefined || this.global.ismytask) {
      window.close();
    }
    else {
      this.router.navigateByUrl('/clinician');
    }
    // this.router.navigate(['/clinician'], { queryParams: { page:this.page } });

  }

  getPsychotropicMedication() {
    this.patientservice.getPsychotropicMedication(this.patientInfoId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();

        this._dtoMedications = cloneDeep(res);


      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  
  onAddMedicine(){
    if (this._dtoMedications == null || this._dtoMedications == undefined || this._dtoMedications.length == 0) {
      this._dtoMedications = [];
      for (let i = 0; i < 1; i++) {
        this._dtoMedications.push({
          psychotropicMedicationId: 0,
          patientAccountId: Number(this.patientAccountId),
          medications: null,
          dosage: null,
          frequency: null,
          sideEffects: null,
          patientInfoId:Number(this.patientInfoId),
          noMedicationError:false,
          noDosageError:false,
          noFrequencyError:false,
          noSideEffectsError:false
        })
      }
    }
  }

  getPatientMedication(){  
    this.patientservice.getPsychotropicMedication(this.patientInfoId).subscribe((res:any)=>{
      if(res.length ==0){
        return;
      }
      else
      {
      this._dtoMedicationsCheckList=res;    
      } 
    })
  }
  
  addVitamins_click(){    
    this._dtoVitamins.push({
      vitaminId: 0,
      patientAccountId: Number(this.patientAccountId),
      medications: null,
      dosage: null,
      frequency: null,
      sideEffects: null,
      patientInfoId:Number(this.patientInfoId),
      noMedicationError:false,
      noDosageError:false,
      noFrequencyError:false,
      noSideEffectsError:false
    })
  }
  removeVitamin_click(data,i){   
    if (data.vitaminId != 0) {
      this.removeExistingVitamin(data);
    }
    this._dtoVitamins.splice(i, 1);
    if(this._dtoVitamins.length==0){
      this.onAddVitamin();
    }
  }

  //To remove Existing Medicine
  removeexistMedinfo(data) {
    this.spinnerservice.show();
    this.patientservice.removePsychotropicMed(data.psychotropicMedicationId).subscribe((res: any) => {
      this.spinnerservice.hide();
      this.getPatientMedication();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  removeExistingVitamin(data){
    this.spinnerservice.show();
    this.patientservice.removeVitamin(data.vitaminId).subscribe((res: any) => {
      this.getPatientVitamins();
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  
  getPatientVitamins(){
    this.patientservice.getPatientVitamins(this.patientInfoId).subscribe((res)=>{
      if(res.length ==0){
        return;
      }
      else{
        this._dtoVitamins=res;
      }      
    })
  }

  onAddVitamin() {
    if (this._dtoVitamins == null || this._dtoVitamins == undefined || this._dtoVitamins.length<1) {
      this._dtoVitamins = [];
      for (let i = 0; i < 1; i++) {
        this._dtoVitamins.push({
          vitaminId: 0,
          patientAccountId: Number(this.patientAccountId),
          medications: null,
          dosage: null,
          frequency: null,
          sideEffects: null,
          patientInfoId:Number(this.patientInfoId),
          noMedicationError:false,
          noDosageError:false,
          noFrequencyError:false,
          noSideEffectsError:false
        })
      }
    }
  }
  
  addMedicine_click(){    
    this._dtoMedicationsCheckList.push({
      psychotropicMedicationId: 0,
      patientAccountId: Number(this.patientAccountId),
      medications: null,
      dosage: null,
      frequency: null,
      sideEffects: null,
      patientInfoId:Number(this.patientInfoId)
    })
  }
  
  removeMedicine_click(data,i){
    //  console.log(data);
      
      if (data.psychotropicMedicationId != 0) {
        this.removeexistMedinfo(data);
      }
      this._dtoMedicationsCheckList.splice(i, 1);
      if(this._dtoMedicationsCheckList.length==0){
        this.onAddMedicine();
      }
    }

  onEditMedication(){
    this.IsEditMedication = true;
  }
  
  postIntakeChecklistall(frmchecklist: NgForm) {
    // for(let i=0;i<this.lstIntakeChecklist.answers.length; i++){
    //   if(this.lstIntakeChecklist.answers[i].explanation == ""){
    //     return
    //   }
    // }
       
    this.isSubmitForm=true;
   // this.lstIntakeSurvey.inTakeProgress.lastExamination = this.lastExaminationDate;
    this.lstIntakeSurvey.medications=this._dtoMedicationsCheckList;
    this.lstIntakeSurvey.vitamins=this._dtoVitamins;
   
    if(this.selectedtab==5){   
      if(this.checkValidations()){
        return;
      }
    }
    // if(!frmchecklist.form.valid){
    //   return;
    // }
    
  //const dataToEncrypt = this.commonService.DataToEncrypt(this.lstIntakeSurvey, false);     
    this.spinnerservice.show();
    this.patientservice.postIntakechecklist(this.lstIntakeSurvey).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.IsEditMedication=false;
        this.IsEditVit=false;
        this.configureFormId = constantVariables.ConfigureFormType.CheckList;
        this.getInTakeQuestions(this.cecklist);
        this.snackbar.success(JSON.parse(res).message);
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  
  checkValidations(){
    if(this.isSubmitForm){
      if(this.lstIntakeSurvey?.answers[this.medicationIndex].isAnswer){
        for (let index = 0; index < this._dtoMedicationsCheckList.length; index++) {
          const element = this._dtoMedicationsCheckList[index];
          if(element.medications==null || element.medications==''){
            element.noMedicationError=true;
          }else{
            element.noMedicationError=false;
          }

          if(element.dosage==null || element.dosage==''){
            element.noDosageError=true;
          }else{
            element.noDosageError=false;
          }

          if(element.frequency==null || element.frequency==''){
            element.noFrequencyError=true;
          }else{
            element.noFrequencyError=false;
          }

          if(element.sideEffects==null || element.sideEffects==''){
            element.noSideEffectsError=true;
          }else{
            element.noSideEffectsError=false;
          }
        }
      }
      if(this.lstIntakeSurvey?.answers[this.vitaminIndex].isAnswer){
        for (let index = 0; index < this._dtoVitamins.length; index++) {
          const element = this._dtoVitamins[index];
          if(element.medications==null || element.medications==''){
            element.noMedicationError=true;
          }else{
            element.noMedicationError=false;
          }

          if(element.dosage==null || element.dosage==''){
            element.noDosageError=true;
          }else{
            element.noDosageError=false;
          }

          if(element.frequency==null || element.frequency==''){
            element.noFrequencyError=true;
          }else{
            element.noFrequencyError=false;
          }

          if(element.sideEffects==null || element.sideEffects==''){
            element.noSideEffectsError=true;
          }else{
            element.noSideEffectsError=false;
          }
        }
      }
      var medicationValidationError=false;
      var vitaminsValidationError=false;
      var medicationValidation = this._dtoMedications.filter(function(v, i) {
        medicationValidationError=(v["noMedicationError"] == true || v["noDosageError"] == true || v["noFrequencyError"] == true || v["noSideEffectsError"] == true);
      })   
      var vitaminsValidation = this._dtoVitamins.filter(function(v, i) {
        vitaminsValidationError=(v["noMedicationError"] == true || v["noDosageError"] == true || v["noFrequencyError"] == true || v["noSideEffectsError"] == true);
      })
      if(medicationValidationError||vitaminsValidationError){
        return true;
      }
      else{        
        return false;
      }
    }
  }
  oncancelEditMedication(){
    this.IsEditMedication=false;
    this.getPatientMedication();
  }
  onEditIsEditVit(){
    this.IsEditVit=true;
  }
  oncancelEditIsEditVit(){
    this.IsEditVit=false;
    this.getPatientVitamins();
  }
  authtemplatechange(){
    const index = this.lstAuthorizationConsent.findIndex(item => item.templateNameId == 2 && item.clinicianTypeId == 0)  ;
     if (index !== -1) 
     {    
      let firstObj = this.lstAuthorizationConsent.filter(item => item.templateNameId == 2 && item.clinicianTypeId == 0)[0];
       this.lstAuthorizationConsent.splice(index, 1);  
       this.lstAuthorizationConsent.push(firstObj);
       }  
      
      
      }
}

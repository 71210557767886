import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserserviceService } from '../userservice.service';

import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
declare function decrypt(data): any;
declare function EncryptFieldData(data): any;
@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['FirstName', 'LastName', 'UserName', 'Rolename', 'Email', 'ContactNumber', 'actions'];
  //#region Declaration
  registerForm: FormGroup;
  dataSource: MatTableDataSource<any>;

  isPageEdit: boolean;
  isPageCreate: boolean;
  isPageDelete: boolean;
  submitted = false;
  isEditModal: boolean = false;

  pageList: any;
  userProfiles: any[] = [];
  authId: any;
  rolelist: any;

  //#endregion
  constructor(public global: Global,private formBuilder: FormBuilder, private uservice: UserserviceService,
    private router: Router, private dialog: MatDialog, private snackbar: SnackBarService) { }

  ngOnInit(): void {
   
    this.getRoles();
    this.getAlluserprofile();
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required,this.global.cannotContainSpace],
      lastName: ['', Validators.required,this.global.cannotContainSpace],
      email: ['', Validators.required,this.global.cannotContainSpace],
      contactNumber: ['', Validators.required,this.global.cannotContainSpace],
      roleId: ['', Validators.required]

    });
    this.roleaccess();
  }

  get f() { return this.registerForm.controls; }
  roleaccess() {
    const pageid = constantVariables.enumpagelists.USERS;
    this.pageList = JSON.parse(localStorage.getItem('pagelist'));
    const page = this.pageList.find(x => x.pageId == pageid);
    // this.isPageEdit = page.isEdit;
    // this.isPageCreate = page.isCreate;
    // this.isPageDelete = page.isDelete;

  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  getRoles() {
    this.uservice.getRoles().subscribe(
      response => {
        this.rolelist = response;
      },
      error =>{}
       

    );
  }
  getAlluserprofile() {
    this.uservice.getAllUsers().subscribe(
      res => {
        if (res) {
          if (res != null && res.inputString != "") {
            this.userProfiles = decrypt(res.inputString);
            this.dataSource = new MatTableDataSource(this.userProfiles);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }

        }
      },
      err => {
       

      },
    );
  }
  oneditUserProfile(authId) {

    this.uservice.getUsersByAuthId(authId).subscribe(userdata => {
      this.isEditModal = true;
      this.authId = userdata.authId;
      this.registerForm = this.formBuilder.group({
        firstName: [userdata.firstName, [Validators.required]],
        lastName: [userdata.lastName, [Validators.required]],
        userName: [userdata.userName, [Validators.required]],
        email: [userdata.email],
        roleId: [userdata.roleId, [Validators.required]],
        contactNumber: [userdata.contactNumber],
      });

    });

  }
  editclose() {
    this.resetEdit();
  }

  resetEdit() {
    this.registerForm.reset();
    this.authId = '';
    this.isEditModal = false;
  }
  onUpdate() {
    this.submitted = true;
    if (!this.registerForm.valid) {

      return;
    }
    const data = {
      Email: this.registerForm.value.email,
      RoleId: Number(this.registerForm.value.roleId),
      ContactNumber: this.registerForm.value.contactNumber,
      Firstname: this.registerForm.value.firstName,
      Lastname: this.registerForm.value.lastName,
      AuthId: this.authId,

    };
    const dataToEncrypt = {

      objRegister: EncryptFieldData((JSON.stringify(data))),

    };
    this.uservice.updateUsers(dataToEncrypt).subscribe(res => {
      if (res) {
        this.snackbar.success(constantVariables.SuccessMessage.Dataupdatedsuccessfully);
        //this.toastr.success('', constantMessages.SuccessMessage.Dataupdatedsuccessfully);
        this.getAlluserprofile();
        this.resetEdit();
      } else {
        this.snackbar.error(constantVariables.SuccessMessage.ErrorSave);
      }
    },
      err => {
        this.snackbar.error(constantVariables.SuccessMessage.ErrorSave);
        //this.toastr.error(constantMessages.ErrorMessage.ErrorSave);
        
      }
    );
  }
  deleteUser(item) {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: 'Are you sure you want to delete this ?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = {
          AuthId: item.AuthId,
          userid: item.userid
        }
        this.uservice.deleteUser(data).subscribe(
          () => {
            this.getAlluserprofile();
            this.snackbar.success(constantVariables.SuccessMessage.DeletetedSuccesscully);
          });

      }
    });
  }
}

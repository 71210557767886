
<div>
  <div class="modal-header">
    <h5  class="modal-title">
      Treatment Notes</h5>
      <button (click)="closepopup()" mat-riased-button color="warn" class="close float-right">
        &times;
      </button>
    <p></p>
  </div>

  <div>
    <!-- <form  (ngSubmit)="onSubmit()" #notedialogfrm="ngForm"> -->
      <form (ngSubmit)="notedialogfrm.form.valid  && onSubmit(notedialogfrm)" id="notedialogfrm" autocomplete="off" #notedialogfrm="ngForm"
      novalidate>
      <div class="col-md-12">
        <div class="row">
        <mat-form-field class="w-100">
            <input matInput placeholder="Add Subject" 
              [(ngModel)]="treatmentnotesform.notesSubject" #notesSubject="ngModel" name="notesSubject"
              autocomplete="new-password" [ngClass]="{ 'is-invalid': notedialogfrm.submitted && notesSubject.invalid }"
              required>
             
            <mat-error *ngIf="notedialogfrm.submitted && notesSubject.invalid">
              <mat-error *ngIf="notesSubject.errors.required">Subject is required</mat-error>
            </mat-error>
          </mat-form-field>
        </div>
    </div>


    <div class="col-md-12">
      <div class="row">
      <mat-form-field class="w-100">
          <input matInput placeholder="Add Content" 
            [(ngModel)]="treatmentnotesform.notesContent" #notesContent="ngModel" name="notesContent"
            autocomplete="new-password" [ngClass]="{ 'is-invalid': notedialogfrm.submitted && notesContent.invalid }"
            required>
            <!-- <mat-hint align="end">{{input.value.length}}/3000</mat-hint> -->
          <mat-error *ngIf="notedialogfrm.submitted && notesContent.invalid">
            <mat-error *ngIf="notesContent.errors.required">Content is required</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
  </div>

      <div class="col-md-12">
        <div class="row">
            <div class="col-md-12 text-right mt-2">    
        <!-- <button  mat-raised-button  class="primary-btn-outlined-small mr-2"*ngIf="IsAdd" 
          type="submit">Add Note</button> -->
          <button type="submit" mat-raised-button class="primary-btn-outlined-small mr-2"
                    >{{IsAdd ?
                    'Add Note':'Update Note'}}</button>
      </div>
        </div></div>


    </form>
    <!-- <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12 text-right mt-2">    
      <button  mat-raised-button class="primary-btn-outlined-small mr-2" *ngIf="IsUpdate" (click)="EditNote(item)"
       >Update Note</button>
    </div>
    </div>
    </div> -->
    

<div class="col-md-12 ">
  <div class="row">
      <div class="col-md-12 p-0 mt-2 view-note d-block">
    <div *ngFor="let item of patientnotes1">
      
        <div class="list-note">
          <div class="row">
            <div class="col-md-5">
              <div class="float-left w-100  position-relative">

                <span class="note-content  mt-3" style="font-size: medium;">
                  {{item.notesSubject}}
                </span>
              </div>
            </div>
            <div class="col-md-5">
              <div class="float-left w-100  position-relative">

                <span class="note-content  mt-3" style="font-size: medium;">
                  {{item.notesContent}}
                </span>
              </div>
            </div>
            <div class="col-md-2">
              <div class="float-left w-100  position-relative" >

                <span class="note-content  mt-3" style="font-size: medium;">
                  <a (click)="OpenEdit(item)" class="icon-note cursor-pointer" title="Edit"><span class="mr-2"><i
                        class="far fa-edit"></i></span></a>
                  <a (click)="deleteNote(item)" class="icon-note cursor-pointer" title="Delete"> <span class="mr-2"><i
                        class="far fa-trash-alt"></i></span></a>
                </span>
              </div>
            </div>

          </div>
        </div>

    </div>
    <div class="col-md-12">
      <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)"  #commentpaginator
      [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>
  <!-- <mat-paginator #commentpaginator [length]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
  </div>
</div>
</div>
   


</div>
</div>

</div>
<div class="content-main-section pl-5 schedular">
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <h4 class="head-section m-0 pr-3">My Appointments</h4>
      <a *ngIf="roleAccess.isView" class="current-day" (click)="filterToday()">Today</a>
    </div>
    <div class="">
      <div
          class="btn-group mb-2 custom-radio-grp pt-2"
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="filterToday()"
            [ngClass]="{ active: filterType == 1 }"
          >
            Day
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            [ngClass]="{ active: filterType == 2 }"
            (click)="filterbyWeek()"
          >
            Week
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            [ngClass]="{ active: filterType == 3 }"
            (click)="filterbyMonth()"
          >
            Month
          </button>
        </div>
    </div>
  </div>
  
  <div *ngIf="roleAccess.isView">
    <div class="col-md-12 d-flex day-changer-container justify-content-between">


      <div class="d-flex align-items-center">
        <div class="align-items-center d-flex slider-arr"
             *ngIf="filterType == 1">
          <!--<a class="mr-2" (click)="filterDayPrev($event)"><span><i class="fas fa-chevron-left"></i></span></a>
            <a class="mr-2" (click)="filterDayNext($event)"><span><i class="fas fa-chevron-right"></i></span></a>-->
          <div class="d-inline-block mr-2">
            <span class="current-date text-nowrap fs-14">{{ filterdate }}</span>
          </div>
          <a class="mr-2 cursor-pointer">
            <span mwlCalendarPreviousView
                  [view]="view"
                  [(viewDate)]="viewDateForDay"
                  (viewDateChange)="closeOpenMonthViewDay()">
              <i class="fas fa-chevron-left"></i>
            </span>
          </a>
          <a class="mr-2 cursor-pointer">
            <span mwlCalendarNextView
                  [view]="view"
                  [(viewDate)]="viewDateForDay"
                  (viewDateChange)="closeOpenMonthViewDay()">
              <i class="fas fa-chevron-right"></i>
            </span>
          </a>


        </div>
        <div class="align-items-center d-flex slider-arr"
             *ngIf="filterType == 2">
          <div class="d-inline-block mr-2">
            <span class="current-date text-nowrap fs-14">
              {{
            viewDate | calendarDate : view + "ViewTitle" : "en"
              }}
            </span>
          </div>
          <a class="mr-2"
             mwlCalendarPreviousView
             [view]="view"
             [(viewDate)]="viewDate"
             (viewDateChange)="filterDayPrev($event)">
            <i class="fas fa-chevron-left"></i>
          </a>
          <a class="mr-2"
             mwlCalendarNextView
             [view]="view"
             [(viewDate)]="viewDate"
             (viewDateChange)="filterDayNext($event)">
            <i class="fas fa-chevron-right"></i>
          </a>

        </div>

        <div class="align-items-center d-flex slider-arr"
             *ngIf="filterType == 3">
          <div class="d-inline-block mr-2">
            <span class="current-date text-nowrap fs-14">
              {{
            viewDate | calendarDate : view + "ViewTitle" : "en"
              }}
            </span>
          </div>
          <a class="mr-2"
             mwlCalendarPreviousView
             [view]="view"
             [(viewDate)]="viewDate"
             (viewDateChange)="filterDayPrev($event)">
            <i class="fas fa-chevron-left"></i>
          </a>
          <a class="mr-2"
             mwlCalendarNextView
             [view]="view"
             [(viewDate)]="viewDate"
             (viewDateChange)="filterDayNext($event)">
            <i class="fas fa-chevron-right"></i>
          </a>


        </div>
        <div class="d-inline-block choose-date date-hide ml-0 mr-3">
          <mat-form-field class="w-100">
            <mat-label>Choose a date</mat-label>
            <input matInput
                   [matDatepicker]="picker"
                   (dateChange)="addEvent('change', $event)"
                   [(ngModel)]="PickedDate"
                   name="PickedDate" />
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="d-flex client-search" id="rowset">
          <!-- <i class="material-icons search-icon" id="srcicon">search</i>
      <mat-form-field class="example-full-width" id="filtercontrol">
        <input type="text" matInput [formControl]="filterControl" [matAutocomplete]="auto"  placeholder="Client Name"
        [matAutocompleteDisabled]="!isAutocompleteEnabled" (keyup)="onKeyUp($event)">
        <button mat-button matSuffix *ngIf="filterControl" mat-icon-button (click)="onClick()">
          <mat-icon>close</mat-icon>
      </button>
        <mat-autocomplete  id="srcwidthleft"  #auto="matAutocomplete"  (optionSelected)="clientnamedetails($event)">
          <mat-option *ngFor="let a of filteredOptions " [value]="a.patientName"> {{a.patientName}}</mat-option>
        </mat-autocomplete>
      </mat-form-field> -->
          <mat-form-field class="example-full-width">
            <input type="text" matInput [formControl]="filterControl" [matAutocomplete]="auto" placeholder="Client Name"
                   [matAutocompleteDisabled]="!isAutocompleteEnabled" (keyup)="onKeyUp($event)">
            <button mat-button matSuffix *ngIf="filterControl" mat-icon-button (click)="onClick()">
              <mat-icon matSuffix>clear</mat-icon>
            </button>
            <mat-autocomplete id="srcwidthleft" #auto="matAutocomplete" (optionSelected)="clientnamedetails($event)">
              <mat-option *ngFor="let a of filteredOptions " [value]="a.patientName"> {{a.patientName}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-icon matSuffix class="src" id="search">search</mat-icon>
        </div>
      </div>
      <!--<div class="ml-auto pdg_left">

    <span class="filter-icon mr-2"><i class="fa fa-filter"></i></span>
    <div class="filter-group d-inline-block">
      <label class="d-inline-block mr-2">Clinician</label>
      <select class="form-control d-inline-block status-filter" [value]='0' [(ngModel)]="selectedClinician"
        name="clinician" (change)="onAppointmentClinicianchange()">
        <option value="-1">All</option>
        <option *ngFor="let i of drpClinicians" [value]="i.clinicianId">{{i.clinicianName}}</option>
      </select>-->
      <!-- <button class="rearange" title="Re-arrange Clinician" (click)="OnreArrangeClinician()"><i
      class="fas fa-sort-amount-up"></i></button> -->
      <!--</div>.

  </div>
  <div class="ml-auto">
    <span class="filter-icon mr-2"><i class="fa fa-filter"></i></span>
    <div class="filter-group d-inline-block">
      <label class="d-inline-block mr-2">Appointment Type</label>-->

      <div class="d-flex">
        <div class="filter-group d-flex">
          <label class="d-inline-block mr-2 fs-14 mb-0 mt-2">Clinician</label>
          <select class="form-control d-inline-block status-filter" [value]='0' [(ngModel)]="selectedClinician"
                  name="clinician" (change)="onAppointmentClinicianchange()">
            <option value="-1">All</option>
            <option *ngFor="let i of drpClinicians" [value]="i.clinicianId">{{i.clinicianName}}</option>
          </select>
          <!-- <button class="rearange" title="Re-arrange Clinician" (click)="OnreArrangeClinician()"><i
          class="fas fa-sort-amount-up"></i></button> -->
        </div>
        <div class="filter-group d-flex">
          <label class="d-inline-block text-nowrap mr-2 fs-14 mb-0 mt-2">Appointment Type</label>

          <select class="form-control d-inline-block status-filter"
                  [(ngModel)]="appointmentType"
                  #Meeting="ngModel"
                  name="Meeting"
                  (change)="loadCalenderAppointment($event.target.value)">
            <option value="undefined">--Select --</option>
            <option value="0" selected>All</option>
            <option value="1">Intake</option>
            <option value="2">Group Therapy</option>
            <option value="3">Individual Therapy</option>
            <option value="4">Family Therapy</option>
            <option value="6">Psychiatric Therapy</option>
            <option value="7">Psychiatric Evaluation</option>
            <option value="8">Psychiatric Medication</option>
            <option value="5">Tour</option>
            <option value="11">Phone Call</option>
            <option value="10">School Meeting</option>
            <option value="12">Speech Therapy</option>
            <option value="13">Nutrition Therapy</option>
            <option value="14">Block Time</option>
          </select>
        </div>
        <div class="filter-group d-flex mr-0">
          <label class="d-inline-block mr-2 fs-14 mb-0 mt-2">Status</label>
          <select class="form-control d-inline-block status-filter mr-0"
                  (change)="onchangeFilter($event.target.value)">
            <option value="Any">Any</option>
            <option value="New">New</option>
            <option value="Scheduled">Scheduled</option>
          </select>
        </div>

      </div>
    </div>
    <!-- <div class="col-md-12 mytask dayview" *ngIf="filterType == 1">

        <ul class="col-md-12 nomargin">
            <li class="col-md-12 p-0" *ngFor="let item of lstAppointments">
                <div class="card mb-2">
                    <div class="card-body p-0">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="py-3 position-relative ">
                                    <div class="pl-3 d-inline-block align-middle"><a data-abc="true"
                                            class="text-decoration-none"><span
                                                class="w-40 avatar gd-primary">{{global.getCharUsername(item.patientName)}}</span></a>
                                    </div>
                                    <div class=" pl-3 d-inline-block align-middle" style="width:64%;">
                                        <a data-abc="true" class="item-author text-color">{{item.patientName}}</a>
                                        <div class="patient-details">
                                            <h5 class="">{{item.dob}} years old</h5>
                                            <h5><i class="fas fa-envelope mr-1"></i>{{item.email}}</h5>
                                            <h5 class="mb-0"><i class="fas fa-phone"></i> +1 {{item.contactNo |
                                                mask:
                                                '(000) 000-0000'}}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="py-3 position-relative">
                                
                                    <span class="text-color">{{item.scheduleDate| date:'MM/dd/yyyy'}}
                                        {{item.fromTime|
                                        date:'hh:mm a'}}</span>
                                    <div class="sub-except text-muted text-sm h-1x ">Scheduled Time </div>
                                    <div class="mr-2 status-section mt-2">
                                        <span class=" "><span class="status "
                                                [ngClass]="{'text-success' :item.statusType == 14,'text-info' :item.statusType == 8,'text-danger' :item.statusType == 27}">•</span>{{item.status}}</span>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="py-3 position-relative">
                                    <span class="text-color">{{item.supportstaff == null ?
                                        '-':item.supportstaff}}</span>
                                    <div class="sub-except text-muted text-sm h-1x ">Supported Staff </div>

                                </div>
                            </div>




                            <div class="col-md-4 text-right">
                                <div class="py-3 clearfix">

                                    <div class="result_action_btn d-flex float-left w-100 mt-2">

                                      
                                        <div class="appointment-action">
                                            <div class="btn-section-list" *ngIf="item.meetingScheduleStatus != 28 && item.meetingScheduleStatus != 31">
                                               
                                                <a (click)="reSchedule(objAppointment)"
                                                    *ngIf="objAppointment?.statusType != 29 && ShowReSchedule(objAppointment?.scheduleDate,objAppointment?.fromTime)"
                                                    class="btn btn-sm  mr-2 action-Reject-btn">
                                                    <i class="far fa-calendar-alt"></i> Reschedule
                                                </a>
                                                <a (click)="requestCancelMeeting(objAppointment)"
                                                    *ngIf="objAppointment?.statusType != 29 && ShowReSchedule(objAppointment?.scheduleDate,objAppointment?.fromTime)"
                                                    class="btn btn-sm  mr-2 action-Reject-btn">
                                                    <i class="fas fa-times"></i> Cancel
                                                </a>
                                            </div>

                                         
                                            <div class="btn-section-list" >
                                                <a class="btn btn-sm action-btn mr-2"
                                                    (click)="onGenerateIntake(item)"><i
                                                        class="fas fa-notes-medical mr-2"></i>Generate Intake</a>
                                                <a class="btn btn-sm action-btn mr-2"
                                                    (click)="onPainAssessment(item)"><i
                                                        class="fas fa-user-injured mr-2"></i>Pain Assessment</a>

                                            </div>
                                            <div class="btn-section-list" >
                                                <a class="btn btn-sm action-btn mr-2" (click)="onNutritional(item)"><i
                                                        class="far fa-question-circle mr-2"></i>Nutritional
                                                    Question</a>

                                                <a class="btn btn-sm action-btn mr-2" (click)="onintakechma(item)"><i
                                                        class="fas fa-procedures mr-2"></i>Prepare CHMA</a>
                                            </div>

                                        </div>
                                        <div class="mr-2">
                                            <div class="item-action dropdown"> <a data-toggle="dropdown"
                                                    class="text-muted" data-abc="true" aria-expanded="false"> <svg
                                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-more-vertical">
                                                        <circle cx="12" cy="12" r="1"></circle>
                                                        <circle cx="12" cy="5" r="1"></circle>
                                                        <circle cx="12" cy="19" r="1"></circle>
                                                    </svg> </a>
                                                <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"
                                                    x-placement="bottom-end"
                                                    style="position: absolute; transform: translate3d(16px, 22px, 0px); top: 0px; left: 0px; will-change: transform;">
                                                   
                                                    <a class="dropdown-item cursor-pointer" data-abc="true">View
                                                        Goals
                                                    </a>
                                                    <a class="dropdown-item cursor-pointer" data-abc="true">View
                                                        Treatment Plan </a>
                                                    <a class="dropdown-item cursor-pointer" data-abc="true">View
                                                        Progress </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>

        </ul>

        <div class="col-md-12" *ngIf="lstAppointments?.length === 0">
            <div class="list-note w-100 mt-2 mb-2 pt-4 pb-4 text-center">
                <div class="text-center">
                    <p class="msg-nomeet">No Meetings available today</p>
                    <img src="../../../../assets/images/no-meetings.png" style=" width:225px;">
                </div>

            </div>
        </div>
        <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="5"
            [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>


    </div>  -->
    <div
      class="col-md-12 mytask dayview task-week pt-3"
      *ngIf="filterType == 1"
    >
      <mwl-calendar-day-view
        class="schdule-calendar"
        [viewDate]="viewDateForDay"
        [events]="events"
        [refresh]="refresh"
        (dayClicked)="onclickeddate($event)"
        (eventClicked)="handleEvent('Clicked', $event.event, $event)"
        [cellTemplate]="customCellTemplate"
        (eventTimesChanged)="eventTimesChanged($event)"
        [dayStartHour]="07"
        [dayEndHour]="23"
      >
      </mwl-calendar-day-view>
    </div>
    <div
      class="col-md-12 mytask weekview task-week pt-3"
      *ngIf="filterType == 2"
    >
      <mwl-calendar-week-view
        class="schdule-calendar"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (dayClicked)="onclickeddate($event)"
        (eventClicked)="handleEvent('Clicked', $event.event, $event)"
        [cellTemplate]="customCellTemplate"
        [tooltipTemplate]="weekViewEventTemplate"
        (eventTimesChanged)="eventTimesChanged($event)"
        [dayStartHour]="07"
        [dayEndHour]="23"
      >
      </mwl-calendar-week-view>
      <!--  -->
    </div>
    <div
      class="col-md-12 mytask Monthview task-month pt-3"
      *ngIf="filterType == 3"
    >
      <!-- <div class="more-popup scrollbar" *ngIf="moreTaskEvents.length>0"
            [ngStyle]="{'top' : moreEventStyles.top , 'left': moreEventStyles.left}">
            <div class="header">
                <button type="button" class="close close-more-popup" (click)="moreTaskEvents=[]">&times;</button>
                <div class="header-day">{{moreTaskEvents[0].start | date : 'EEEE'}}</div>
                <div class="header-date">{{moreTaskEvents[0].start | date : 'd'}} </div>
            </div>
            <div class="body">
                <div *ngFor="let e of moreTaskEvents">
                    <div class="col-event-mytask cal-event-title" (click)="handleEvent('Clicked', e)"> <span innerHtml="{{event.title}} {{event.fromTime|
                        date:'hh:mm a'}}"></span></div>
                </div>
            </div>
        </div> -->

      <mwl-calendar-month-view
        class="month-scheduler"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="clickedDate = $event.day.date"
        (eventClicked)="handleEvent('Clicked', $event.event, $event)"
        [cellTemplate]="customCellTemplate"
        (eventTimesChanged)="eventTimesChanged($event)"
      >
      </mwl-calendar-month-view>
      <ng-template #customCellTemplate let-day="day" let-locale="locale">
        <div class="cal-cell-top">
          <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{
            day.badgeTotal
          }}</span>
          <span class="cal-day-number">{{
            day.date | calendarDate : "monthViewDayNumber" : locale
          }}</span>
        </div>
        <div *ngIf="day.events.length > 0" class="">
          <div
            *ngFor="
              let event of day.events;
              trackBy: trackByEventId;
              index as i
            "
          >
            <ng-template
              *ngIf="i < EVENT_LIMIT; then showEventsBlock; else showMoreBlock"
            >
            </ng-template>
            <ng-template #showEventsBlock>
              <div
                class="col-event-mytask cal-event-title"
                *ngIf="i < EVENT_LIMIT"
                (mwlClick)="eventClicked(event)"  [ngStyle]="{'background-color': event.color.primary}"
              >
                <span
                  innerHtml="{{ event.title }} {{
                    event.fromTime | date : 'hh:mm a'
                  }}"
                ></span>
              </div>
            </ng-template>
            <ng-template #showMoreBlock>
              <ng-container *ngIf="i === EVENT_LIMIT">
                <div
                  class="cal-events"
                  (mwlClick)="handleMoreEvent($event, day.events)"
                >
                  <a class="showmore font-14">
                    {{ day.events.length - EVENT_LIMIT }} more</a
                  >
                </div>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <!--If View permission is not there for this role-->
  <div class="task" *ngIf="!roleAccess.isView">
    <div class="msg-nomeet text-center">{{ viewRightsMsg }}</div>
  </div>
</div>

<!--Intake pop up-->
<div
  class="modal bulk-add emoji-popup1 intake-reschedule-popup"
  id="myModal"
  [style.display]="isReSchedule ? 'block' : 'none'"
>
  <div class="modal-dialog mb-2">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Reschedule Meeting</h6>
        <button
          aria-hidden="true"
          data-dismiss="modal"
          class="close"
          (click)="openReSchedule()"
          type="button"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12 mt-3">
          <div class="row p-0">
            <div class="col-md-12 mb-3">
              <mat-label>Reason for Reschedule</mat-label>
              <textarea
                row="3"
                cols="5"
                placeholder="Enter Reason"
                class="w-100"
                maxlength="200"
                [(ngModel)]="rescheduleReason"
                #explanation="ngModel"
                name="explanation"
                required
              ></textarea>
              <mat-error *ngIf="submitted && explanation.invalid">
                <mat-error
                  class="mat-form-field-subscript-wrapper position-relative"
                  *ngIf="explanation.errors.required"
                  >Reason is required</mat-error
                >
              </mat-error>
            </div>
            <div class="col-md-6 date-custom">
              <mat-form-field class="w-100">
                <mat-label>Choose a Date</mat-label>
                <!-- [min]="currentdate" -->
                <input
                  matInput
                  [matDatepicker]="picker"
                  readonly
                  [min]="currentdate"
                  [(ngModel)]="rescheduleDate"
                  #redate="ngModel"
                  name="redate"
                  (click)="picker.open()"
                  name="scheduleDate"
                  [matDatepickerFilter]="meetingDateFilter"
                  (dateChange)="onMettingDate($event, 0)"
                  required
                />
                <mat-datepicker-toggle matSuffix [for]="picker">
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="submitted">
                  <mat-error>Schedule Date is required</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <div class="row">
                <mat-form-field class="w-100">
                  <mat-label>Choose a Time</mat-label>
                  <mat-select
                    name="timeSlotId"
                    required
                    (selectionChange)="onChangeDay1Time($event.value)"
                  >
                    <mat-option *ngFor="let a of timeslots" [value]="a">
                      {{ a.fromTime | date : "hh:mm a" }} -
                      {{ a.toTime | date : "hh:mm a" }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <mat-error
                      >No time slots available for selected date</mat-error
                    >
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row" *ngIf="noslots">
                <span class="mat-form-field-subscript-wrapper text-danger"
                  >No time slots available for selected date
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6" *ngIf="!IsNewRescheduleIntake">
              <div class="date-day">
                {{ Day1viewDate | date : "MM/dd/yyy" }}
              </div>
              <mwl-calendar-day-view
                [viewDate]="Day1viewDate"
                [events]="staffAppointmentDay1"
                [dayStartHour]="Day1StartTime"
                [dayEndHour]="Day1EndTime"
                [refresh]="refresh"
              >
              </mwl-calendar-day-view>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-center">
        <button
          type="button"
          (click)="staffAppointmentDay1 = []; openReSchedule()"
          class="btn btn-cancel primary-btn-s"
          data-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          (click)="reScheduleMeeting(true)"
          [disabled]="isConflict"
          class="btn primary-btn-filled primary-btn-s"
          data-dismiss="modal"
        >
          Submit Request
        </button>
      </div>
    </div>
  </div>
</div>
<!--Intake pop up end-->
<!--Task Pop up -->
<div
  class="modal bulk-add emoji-popup1"
  id="myModal"
  [style.display]="isTaskShow ? 'block' : 'none'"
>
  <div class="modal-dialog mb-2">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="clearfix popup-mytask position-relative">
          <div class="modal-closing">
            <a class="cursor-pointer" (click)="onCloseEvent()"
              ><i class="fa fa-times"></i
            ></a>
          </div>
          <div class="col-md-12 mytask p-0">
            <div class="col-md-12 p-0">
              <div class="card mb-0">
                <div class="card-body p-0">
                  <div>
                    <div class="col-md-12 pr-0">
                      <div class="py-2 position-relative d-flex">
                        <div class="pl-3 d-inline-block align-middle">
                          <a class="text-decoration-none">
                            <span class="w-40 avatar gd-primary">{{
                              global.getCharUsername(
                                objAppointment?.patientName
                              )
                            }}</span>
                          </a>
                        </div>
                        <div
                          class="pl-3 d-inline-block align-middle"
                          style="width: 80%"
                        >
                          <a class="item-author text-color">{{
                            objAppointment?.patientName
                          }}</a>
                          <div class="patient-details">
                            <h5>{{ objAppointment?.dob }} years old</h5>
                            <h5>
                              <i class="fas fa-envelope mr-1"></i
                              >{{ objAppointment?.email }}
                            </h5>
                            <h5 class="mb-0">
                              <i class="fas fa-phone-alt"></i>+1
                              {{
                                objAppointment?.contactNo
                                  | mask : "(000) 000-0000"
                              }}
                            </h5>
                          </div>
                        </div>
                        <!-- <div class="pl-1 d-inline-block align-top">
                          <div class="item-action dropdown">
                            <a
                              data-toggle="dropdown"
                              aria-expanded="false"
                              class="text-muted"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-more-vertical"
                              >
                                <circle
                                  _ngcontent-bra-c341=""
                                  cx="12"
                                  cy="12"
                                  r="1"
                                ></circle>
                                <circle
                                  _ngcontent-bra-c341=""
                                  cx="12"
                                  cy="5"
                                  r="1"
                                ></circle>
                                <circle
                                  _ngcontent-bra-c341=""
                                  cx="12"
                                  cy="19"
                                  r="1"
                                ></circle>
                              </svg>
                            </a>
                            <div
                              role="menu"
                              x-placement="bottom-end"
                              class="dropdown-menu dropdown-menu-right bg-black"
                              style="
                                position: absolute;
                                transform: translate3d(16px, 22px, 0px);
                                top: 0px;
                                left: 0px;
                                will-change: transform;
                              "
                            >
                              <a class="dropdown-item cursor-pointer"
                                >View Goals
                              </a>
                              <a
                                data-abc="true"
                                class="dropdown-item cursor-pointer"
                                >View Treatment Plan
                              </a>
                              <a
                                data-abc="true"
                                class="dropdown-item cursor-pointer"
                                >View Progress
                              </a>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="py-2 position-relative">
                        <div class="mr-2 status-section mt-2 mb-2">
                          <span class="sub-except text-muted text-sm mr-3"
                            >Status
                          </span>
                          <span
                            ><span
                              class="status text-success"
                              [ngClass]="{
                                'text-success':
                                  objAppointment?.statusType == 14,
                                'text-info': objAppointment?.statusType == 8,
                                'text-danger': objAppointment?.statusType == 29
                              }"
                              >•</span
                            >{{ objAppointment?.status }}</span
                          >
                        </div>
                        <div class="w-100 mb-2">
                          <span class="sub-except text-muted text-sm mr-3"
                            >Scheduled Time
                          </span>
                          <span class="text-color text-success"
                            >{{
                              objAppointment?.scheduleDate | date : "MM/dd/yyyy"
                            }}
                            {{
                              objAppointment?.fromTime | date : "hh:mm a"
                            }}</span
                          >
                        </div>
                        <div class="w-100">
                          <div class="sub-except text-muted text-sm mr-3">
                            Supported Staff
                          </div>
                          <span class="text-color">{{
                            objAppointment?.supportstaff == null
                              ? "-"
                              : objAppointment?.supportstaff
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mb-2">
                      <div class="py-2 clearfix">
                        <div
                          class="result_action_btn d-flex float-left w-100 mt-2 pr-1"
                        >
                          <div class="appointment-action w-100 text-center">
                            <div class="btn-section-list"></div>
                            <div class="btn-section-list">
                              <div
                                class="btn-section-list"
                                *ngIf="
                                  objAppointment?.meetingScheduleStatus != 31 &&
                                  objAppointment?.statusType != 28
                                "
                              >
                                <!-- <a (click)="AcceptMeeting(objAppointment)"
                                                                    *ngIf="objAppointment?.statusType != 14 && objAppointment?.statusType != 29"
                                                                    class="btn btn-sm  mr-2 action-accept-btn">
                                                                    <i class="fas fa-check"></i> Accept
                                                                </a> -->
                                <!-- item.statusType != 14 && -->
                                <a
                                  (click)="reSchedule(objAppointment)"
                                  *ngIf="
                                    objAppointment?.statusType != 29 &&
                                    ShowReSchedule(
                                      objAppointment?.scheduleDate,
                                      objAppointment?.fromTime
                                    )
                                  "
                                  class="btn btn-sm mr-2 action-Reject-btn"
                                >
                                  <i class="far fa-calendar-alt"></i> Reschedule
                                </a>

                                <a
                                  (click)="requestCancelMeeting(objAppointment)"
                                  *ngIf="
                                    objAppointment?.statusType != 29 &&
                                    ShowReSchedule(
                                      objAppointment?.scheduleDate,
                                      objAppointment?.fromTime
                                    )
                                  "
                                  class="btn btn-sm mr-2 action-Reject-btn"
                                >
                                  <i class="fas fa-times"></i> Cancel
                                </a>
                              </div>
                              <a
                                class="btn btn-sm action-btn mr-3 btn-nutrition-common"
                                [ngClass]="
                                  MenuAccess.GenerateIntake ? '' : 'disabled'
                                "
                                (click)="onGenerateIntake(objAppointment)"
                              >
                                <i class="fas fa-notes-medical mr-2"></i>Intake
                                Packet
                              </a>
                              <a
                                class="btn btn-sm action-btn mr-2 btn-nutrition-common"
                                [ngClass]="
                                  MenuAccess.PainAssessment ? '' : 'disabled'
                                "
                                (click)="onPainAssessment(objAppointment)"
                              >
                                <i class="fas fa-user-injured mr-2"></i>Pain
                                Assessment
                              </a>
                            </div>
                            <div class="btn-section-list">
                              <a
                                class="btn btn-sm action-btn mr-3 btn-nutrition-common"
                                [ngClass]="
                                  MenuAccess.NutritionalQuestion
                                    ? ''
                                    : 'disabled'
                                "
                                (click)="onNutritional(objAppointment)"
                                ><i class="far fa-question-circle mr-2"></i
                                >Nutritional Questionnaire
                              </a>
                              <a
                                class="btn btn-sm action-btn mr-2 btn-nutrition-common"
                                [ngClass]="
                                  MenuAccess.PrepareCHMA ? '' : 'disabled'
                                "
                                (click)="onintakechma(objAppointment)"
                              >
                                <!-- <i
                                                                    class="fas fa-procedures mr-2"></i> -->
                                <span class="menu-icon">
                                  <img
                                    src="../../../../assets/images/cmha-black.png"
                                    alt="cmha"
                                    class="active-img mb-1 cmha-black-icon mr-1"
                                  />
                                </span>
                                Prepare CMHA
                              </a>
                            </div>
                            <div class="btn-section-list text-center">
                              <a
                                class="btn btn-sm action-btn mr-3 btn-nutrition-common"
                                [ngClass]="
                                  MenuAccess.SuicidalRiskAssessment
                                    ? ''
                                    : 'disabled'
                                "
                                (click)="OnSuicidalAssesment(objAppointment)"
                                ><i class="fas fa-hand-holding-heart mr-2"></i
                                >Suicidal Risk Assessment
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Task pop up end-->
<!--Click more pop up-->
<div
  class="modal bulk-add emoji-popup1"
  id="myModal"
  [style.display]="isShowMoreEvent ? 'block' : 'none'"
>
  <div class="modal-dialog mb-2">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="clearfix popup-mytask position-relative">
          <div class="modal-closing">
            <a
              class="cursor-pointer"
              (click)="isShowMoreEvent = !isShowMoreEvent"
              ><i class="fa fa-times"></i
            ></a>
          </div>
          <div class="col-md-12 mytask p-0">
            <div
              class="header-day"
              class="badge badge-info mt-2 mb-2 ml-2"
              *ngIf="moreTaskEvents?.length > 0"
            >
              {{ moreTaskEvents[0].start | date : "EEEE, MMMM d, y" }}
            </div>

            <div *ngFor="let e of moreTaskEvents">
              <div
                class="col-event-mytask cal-event-title ml-2"
                (click)="handleEvent('Clicked', e)"
              >
                <span
                  innerHtml="{{ e.title }} {{ e.fromTime | date : 'hh:mm a' }}"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Click more pop up end-->

<!-- <div class="modal bulk-add emoji-popup " id="myModal" [style.display]="isShowMoreEvent ? 'block' : 'none'">
    <div class="modal-dialog mb-2">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="clearfix popup-mytask position-relative">
                    <div class="modal-closing "><a class="cursor-pointer" (click)="moreTaskEvents=[];isShowMoreEvent"><i
                                class="fa fa-times"></i></a></div>
                    <ul>
                        <div class="header-day">{{moreTaskEvents[0]?.start | date : 'EEEE'}}</div>
                            <div class="header-date">{{moreTaskEvents[0]?.start | date : 'd'}} </div>
                        <li *ngFor="let e of moreTaskEvents">
                            
                            <div class="body-events col-event-mytask" (click)="handleEvent('Clicked', e)">
                                {{e.title}}
                            </div>

                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</div> -->

<!--Therapies pop up-->
<div
  class="modal bulk-add emoji-popup1 schedule-popup"
  id="myModal"
  [style.display]="isShowTherapydetails ? 'block' : 'none'"
>
  <div class="modal-dialog mb-2">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="clearfix popup-mytask position-relative">
          <div class="modal-closing">
            <a class="cursor-pointer" (click)="OncloseTherapyDetailspopup()"
              ><i class="fa fa-times"></i
            ></a>
          </div>
          <div class="col-md-12 mytask p-0">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <span class="head-top-schedule">{{
                    objAppointmentForPopup.ScheduleName
                  }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="header-day" class="schedule-pop-head mt-2">
                    Client Info
                  </div>
                  <div
                    class="align-middle mb-3"
                    *ngIf="objAppointmentForPopup?.tags != GrouptherapyId"
                  >
                    <a class="item-author text-color mb-1 d-block">{{
                      objAppointmentForPopup?.patientName
                    }}</a>
                    <div class="patient-details">
                      <h5>
                        <i class="fas fa-envelope mr-1"></i
                        >{{ objAppointmentForPopup?.email }}
                      </h5>
                      <h5 class="mb-0">
                        <i class="fas fa-phone-alt"></i
                        >{{
                          objAppointmentForPopup?.contactNo
                            | mask : "(000) 000-0000"
                        }}
                      </h5>
                    </div>
                  </div>
                  <div
                    class="align-middle mb-3"
                    *ngIf="objAppointmentForPopup?.tags == GrouptherapyId"
                  >
                    <p class="text-xs">
                      Please click Progress Notes to view client details.
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="w-100">
                    <span
                      class="sub-except schedule-time-head text-sm mr-3 mt-1"
                      >Appointment Date
                    </span>
                    <span class="text-color text-success"
                      >{{
                        objAppointmentForPopup?.scheduleDate
                          | date : "MM/dd/yyyy"
                      }}
                    </span>
                  </div>
                  <div class="w-100">
                    <span
                      class="sub-except schedule-time-head text-sm mr-3 mt-1"
                      >Appointment Time
                    </span>
                    <span class="text-color text-success">
                      {{
                        objAppointmentForPopup?.fromtime | date : "hh:mm a"
                      }}</span
                    >
                  </div>
                  <div
                    class="w-100 mb-2"
                    *ngIf="objAppointmentForPopup.tags != 4"
                  >
                    <span
                      class="sub-except schedule-time-head text-sm mr-3 mt-1"
                      >Duration
                    </span>
                    <span class="text-color text-success">{{
                      objAppointmentForPopup?.ScheduleDuration
                    }}</span>
                  </div>
                  <div
                  class="w-100 mb-2"
                  *ngIf="objAppointmentForPopup.tags != 4"
                >
                  <span
                    class="sub-except schedule-time-head text-sm mr-3 mt-1"
                    >Location
                  </span>
                  <span class="text-color text-success">{{
                    objAppointmentForPopup?.locationName
                  }}</span>
                </div>
                </div>
                <div class="col-md-6">
                  <a
                    class="mr-2 link-trans"
                    title="Virtual Meeting Link"
                    *ngIf="objAppointmentForPopup.MeetingType == 2"
                    [ngClass]="{
                      disabled: objAppointmentForPopup.MeetingLink == null
                    }"
                    (click)="OnMeetingLinkClick(objAppointmentForPopup)"
                    ><span
                      ><i class="fas fa-link mr-2"></i>Virtual Meeting
                      Link</span
                    ></a
                  >
                  <a
                    class="link-trans"
                    title="Session details"
                    *ngIf="
                      objAppointmentForPopup.tags != 4 &&
                      objAppointmentForPopup.tags != GrouptherapyId
                    "
                    (click)="OnProgressNotes(objAppointmentForPopup)"
                    ><span
                      ><i class="fas fa-info-circle mr-2"></i>Progress
                      note</span
                    ></a
                  >
                  <a
                    class="link-trans"
                    title="Session details"
                    *ngIf="
                      objAppointmentForPopup.tags != 4 &&
                      objAppointmentForPopup.tags != GrouptherapyId &&
                      objAppointmentForPopup.tags == 8 || objAppointmentForPopup.tags == 9
                      ||objAppointmentForPopup.tags == 6
                    "
                    (click)="MedicationConsent(objAppointmentForPopup,objAppointment)"
                    ><span
                      ><i class="fas fa-info-circle mr-2"></i>Medication Consent For Children
                      </span
                    ></a
                  >
                  <a
                  class="link-trans"
                  title="Session details"
                  *ngIf="
                    objAppointmentForPopup.tags != 4 &&
                    objAppointmentForPopup.tags != GrouptherapyId &&
                    objAppointmentForPopup.tags == 8 || objAppointmentForPopup.tags == 9
                    ||objAppointmentForPopup.tags == 6
                  "
                  (click)="MedicationReview(objAppointmentForPopup)"
                  ><span
                    ><i class="fas fa-info-circle mr-2"></i>Medication Review
                    </span
                  ></a
                >
                  <button type="button" title="View Daily Scheduler" class="btn btn-primary mr-2 btn-sm" (click)="loadFormGroup(objAppointmentForPopup)"  *ngIf="
                  objAppointmentForPopup.tags == GrouptherapyId && roleTypeId != 6
                " >View DS</button>
                </div>
              </div>
              <div
                *ngIf="
                  objAppointmentForPopup.tags == GrouptherapyId &&
                  groupTherapyList &&
                  groupTherapyList.length > 0
                "
              >
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Therapy Name</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of groupTherapyList; let i = index">
                      <th scope="row">{{ i + 1 }}</th>
                      <td>{{ item.themeName }}</td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-primary mr-2 btn-sm"
                          (click)="progressNoteGroupTherapy(item)"
                        >
                        Group Progress Note
                        </button>

                        <button
                          type="button"
                          class="btn btn-primary mr-2 btn-sm"
                          (click)="CheckInDetails(item)"
                        >
                          Check-In
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="row"
                *ngIf="
                  objAppointmentForPopup.AdminNotes != null &&
                  (roleAccess.isCreate || roleAccess.isEdit)
                "
              >
                <div class="col-md-12 pt-2 pb-2">
                  <p class="schedule-time-head mb-1 text-sm">Admin Notes</p>
                  <p class="text-xs">{{ objAppointmentForPopup.AdminNotes }}</p>
                </div>
              </div>
              <div class="row" *ngIf="objAppointmentForPopup.MeetingType == 3">
                <div class="col-md-12 pt-2 pb-2">
                  <p class="schedule-time-head mb-1 text-sm">Address</p>
                  <p
                    class="text-xs"
                    *ngIf="
                      !(
                        objAppointmentForPopup.OffSiteAddress == null ||
                        objAppointmentForPopup.OffSiteAddress == ''
                      )
                    "
                  >
                    {{ objAppointmentForPopup.OffSiteAddress }}
                  </p>
                  <p
                    class="text-xs font-weight-500 info-tag"
                    *ngIf="
                      objAppointmentForPopup.OffSiteAddress == null ||
                      objAppointmentForPopup.OffSiteAddress == ''
                    "
                  >
                    <i class="fas fa-exclamation-triangle mr-2"></i>Admin is yet
                    to update the address details
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Therapies pop up end-->
<!--Block Time pop up-->
<div
  class="modal bulk-add emoji-popup1 schedule-popup"
  id="myModal1"
  [style.display]="isShowBlockTime ? 'block' : 'none'"
>
  <div class="modal-dialog mb-2">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="clearfix popup-mytask position-relative">
          <div class="modal-closing">
            <a class="cursor-pointer" (click)="OncloseTherapyDetailspopup()"
              ><i class="fa fa-times"></i
            ></a>
          </div>
          <div class="col-md-12 mytask p-0">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <span class="head-top-schedule">{{
                    objAppointmentForPopup.ScheduleName
                  }}</span>
                  <p></p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="w-100">
                    <span
                      class="sub-except schedule-time-head text-sm mr-3 mt-1"
                      >Appointment Date
                    </span>
                    <span class="text-color text-success"
                      >{{
                        objAppointmentForPopup?.scheduleDate
                          | date : "MM/dd/yyyy"
                      }}
                    </span>
                  <p></p>

                  </div>
                  <div class="w-100">
                    <span
                      class="sub-except schedule-time-head text-sm mr-3 mt-1"
                      >Appointment Time
                    </span>
                    <span class="text-color text-success">
                      {{
                        objAppointmentForPopup?.fromtime | date : "hh:mm a"
                      }}</span>
                  <p></p>

                  </div>
                  <div
                    class="w-100 mb-2"
                    *ngIf="objAppointmentForPopup.tags != 4"
                  >
                    <span
                      class="sub-except schedule-time-head text-sm mr-3 mt-1"
                      >Duration
                    </span>
                    <span class="text-color text-success">{{
                      objAppointmentForPopup?.ScheduleDuration
                    }}</span>
                  </div>
                </div>
               
              </div>
        
          
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Block Time pop up end-->
<!--Group Therapy popup-->


<div *ngIf="isShowGrpTherapydetails" #TherapyDetailsPopup>
  <app-therapy-details-pop-up [ObjTherapyPopUp]="objAppointmentForPopup"></app-therapy-details-pop-up>
</div>







<div
  class="modal bulk-add emoji-popup1 schedule-popup"
  id="myModal"
  [style.display]="isShowGroupTherapy ? 'block' : 'none'"
>
  <div class="modal-dialog mb-2">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="clearfix popup-mytask position-relative">
          <div class="modal-closing">
            <a class="cursor-pointer" (click)="OncloseGroupDetailspopup()"
              ><i class="fa fa-times"></i
            ></a>
          </div>
          <div class="col-md-12 mytask p-0">
            <div class="col-md-12">
              <div class="row"></div>
              <div class="row">
                <div class="col-md-12">
                  <div
                    class="align-middle mb-3"
                    *ngFor="let item of groupTherapyList; let i = index"
                  >
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="progressNoteGroupTherapy(item)"
                    >
                      {{ item.themeName }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Group Therapy popup end-->
</div>

<!--Task Rejoiners Pop up -->
<div
  class="modal bulk-add emoji-popup1"
  id="myModal"
  [style.display]="isTaskShowRejoiners ? 'block' : 'none'"
>
  <div class="modal-dialog mb-2 nutrition-common-box">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="clearfix popup-mytask position-relative">
          <div class="modal-closing">
            <a class="cursor-pointer" (click)="onCloseEvent()"
              ><i class="fa fa-times"></i
            ></a>
          </div>
          <div class="col-md-12 mytask p-0">
            <div class="col-md-12 p-0">
              <div class="card mb-0">
                <div class="card-body p-0">
                  <div>
                    <div class="col-md-12 pr-0">
                      <div class="py-2 position-relative d-flex">
                        <div class="pl-3 d-inline-block align-middle">
                          <a class="text-decoration-none">
                            <span class="w-40 avatar gd-primary">{{
                              global.getCharUsername(
                                objAppointment?.patientName
                              )
                            }}</span>
                          </a>
                        </div>
                        <div
                          class="pl-3 d-inline-block align-middle"
                          style="width: 80%"
                        >
                          <a class="item-author text-color">{{
                            objAppointment?.patientName
                          }}</a>
                          <div class="patient-details">
                            <h5>{{ objAppointment?.dob }} years old</h5>
                            <h5>
                              <i class="fas fa-envelope mr-1"></i
                              >{{ objAppointment?.email }}
                            </h5>
                            <h5 class="mb-0">
                              <i class="fas fa-phone-alt"></i>+1
                              {{
                                objAppointment?.contactNo
                                  | mask : "(000) 000-0000"
                              }}
                            </h5>
                          </div>
                        </div>
                        <!-- <div class="pl-1 d-inline-block align-top">
                          <div class="item-action dropdown">
                            <a
                              data-toggle="dropdown"
                              aria-expanded="false"
                              class="text-muted"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-more-vertical"
                              >
                                <circle
                                  _ngcontent-bra-c341=""
                                  cx="12"
                                  cy="12"
                                  r="1"
                                ></circle>
                                <circle
                                  _ngcontent-bra-c341=""
                                  cx="12"
                                  cy="5"
                                  r="1"
                                ></circle>
                                <circle
                                  _ngcontent-bra-c341=""
                                  cx="12"
                                  cy="19"
                                  r="1"
                                ></circle>
                              </svg>
                            </a>
                            <div
                              role="menu"
                              x-placement="bottom-end"
                              class="dropdown-menu dropdown-menu-right bg-black"
                              style="
                                position: absolute;
                                transform: translate3d(16px, 22px, 0px);
                                top: 0px;
                                left: 0px;
                                will-change: transform;
                              "
                            >
                              <a class="dropdown-item cursor-pointer"
                                >View Goals
                              </a>
                              <a
                                data-abc="true"
                                class="dropdown-item cursor-pointer"
                                >View Treatment Plan
                              </a>
                              <a
                                data-abc="true"
                                class="dropdown-item cursor-pointer"
                                >View Progress
                              </a>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="py-2 position-relative">
                        <div class="mr-2 status-section mt-2 mb-2">
                          <span class="sub-except text-muted text-sm mr-3"
                            >Status
                          </span>
                          <span
                            ><span
                              class="status text-success"
                              [ngClass]="{
                                'text-success':
                                  objAppointment?.statusType == 14,
                                'text-info': objAppointment?.statusType == 8,
                                'text-danger': objAppointment?.statusType == 29
                              }"
                              >•</span
                            >{{ objAppointment?.status }}</span
                          >
                        </div>
                        <div class="w-100 mb-2">
                          <span class="sub-except text-muted text-sm mr-3"
                            >Scheduled Time
                          </span>
                          <span class="text-color text-success"
                            >{{
                              objAppointment?.scheduleDate | date : "MM/dd/yyyy"
                            }}
                            {{
                              objAppointment?.fromTime | date : "hh:mm a"
                            }}</span
                          >
                        </div>
                        <div class="w-100">
                          <div class="sub-except text-muted text-sm mr-3">
                            Supported Staff
                          </div>
                          <span class="text-color">{{
                            objAppointment?.supportstaff == null
                              ? "-"
                              : objAppointment?.supportstaff
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mb-2">
                      <div class="py-2 clearfix">
                        <div
                          class="result_action_btn d-flex float-left w-100 mt-2 pr-1"
                        >
                          <div class="appointment-action w-100 ">
                            <div class="btn-section-list"></div>
                            <div class="btn-section-list">
                              <div
                                class="btn-section-list"
                                *ngIf="
                                  objAppointment?.meetingScheduleStatus != 31 &&
                                  objAppointment?.statusType != 28
                                "
                              >
                                <!-- <a (click)="AcceptMeeting(objAppointment)"
                                                                    *ngIf="objAppointment?.statusType != 14 && objAppointment?.statusType != 29"
                                                                    class="btn btn-sm  mr-2 action-accept-btn">
                                                                    <i class="fas fa-check"></i> Accept
                                                                </a> -->
                                <!-- item.statusType != 14 && -->
                          
                              </div>
                              <a
                                class="btn btn-sm action-btn mr-3 btn-nutrition-common"
                                [ngClass]="
                                  MenuAccess.GenerateIntake ? '' : 'disabled'
                                "
                                (click)="onGenerateIntake(objAppointment)" *ngIf="_dtoPatientDischarge.isIntakePacket == true"
                              >
                                <i class="fas fa-notes-medical mr-2"></i>Intake 
                                Packet 1
                              </a>
                              <a
                                class="btn btn-sm action-btn mr-2 btn-nutrition-common"
                                [ngClass]="
                                  MenuAccess.PainAssessment ? '' : 'disabled'
                                "
                                (click)="onPainAssessment(objAppointment)" *ngIf="_dtoPatientDischarge.isPainAssessment == true"
                              >
                                <i class="fas fa-user-injured mr-2"></i>Pain
                                Assessment 1
                              </a>
                            </div>
                            <div class="btn-section-list">
                              <a
                                class="btn btn-sm action-btn mr-3 btn-nutrition-common"
                                [ngClass]="
                                  MenuAccess.NutritionalQuestion
                                    ? ''
                                    : 'disabled'
                                "
                                (click)="onNutritional(objAppointment)" *ngIf="_dtoPatientDischarge.isNutritionalQuestionnaire == true"
                                ><i class="far fa-question-circle mr-2"></i
                                >Nutritional Questionnaire 1
                              </a>
                              <a
                                class="btn btn-sm action-btn mr-2 btn-nutrition-common"
                                [ngClass]="
                                  MenuAccess.PrepareCHMA ? '' : 'disabled'
                                "
                                (click)="onintakechma(objAppointment)"  *ngIf="_dtoPatientDischarge.isComprehensiveMHA == true"
                              >
                                <!-- <i
                                                                    class="fas fa-procedures mr-2"></i> -->
                                <span class="menu-icon">
                                  <img
                                    src="../../../../assets/images/cmha-black.png"
                                    alt="cmha"
                                    class="active-img mb-1 cmha-black-icon mr-1"
                                  />
                                </span>
                                Prepare CMHA 1
                              </a>
                            </div>
                            <div class="btn-section-list">
                              <a
                                class="btn btn-sm action-btn mr-3 btn-nutrition-common"
                                [ngClass]="
                                  MenuAccess.SuicidalRiskAssessment
                                    ? ''
                                    : 'disabled'
                                "
                                (click)="OnSuicidalAssesment(objAppointment)" *ngIf="_dtoPatientDischarge.isSuicidalRiskAssessment == true "
                                ><i class="fas fa-hand-holding-heart mr-2"></i
                                >Suicidal Risk Assessment 1
                              </a>
                              <a
                              class="btn btn-sm action-btn mr-2 btn-nutrition-common"
                              [ngClass]="
                                MenuAccess.SuicidalRiskAssessment
                                  ? ''
                                  : 'disabled'
                              "
                              (click)="OnIntakeUpdate(objAppointment)" *ngIf="_dtoPatientDischarge.isIntakeUpdate == true "
                              >  <span class="menu-icon">
                                <img
                                  src="../../../../assets/images/cmha-black.png"
                                  alt="cmha"
                                  class="active-img mb-1 cmha-black-icon mr-1"
                                />
                              </span>Intake Update
                            </a>
                            </div>
                            
                           
                          </div>
                        </div>

                        <a    (click)="Documentviewforclient(objAppointment)" class="green-link">View Previous History</a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Task Rejoiners pop up end-->


<ng-template let-data #clientModal>
  <div class="row">
    <h2 mat-dialog-title class="col-8">Appointment Details</h2>
    <div class="col-3">
      <button class="btn generate-btn mr-2 pdf_color " (click)="onGeneratePDF(appointmentTypes)"><i class="far fa-file-pdf mr-2"></i>Generate
        PDF</button>
    </div>
    <div class="col-1 text_right">
      <mat-icon  mat-dialog-close>close</mat-icon>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row mt_20"> 
      <div class="col-5">
        <div class="d-flex row ml-10">
          <h6 class="p-2 mar_left"> Patient Name : </h6>
          <span class="p-2 client_mrgtop">{{patientNameview}}</span>
        </div>
      </div>
      <aside id="info-block" class="col-6 mar_border">
        <section class="file-marker">
          <div>
            <div class="box-title">
              Appointments
            </div>
            <div class="box-contents">
              <div id="audit-trail">
                <label  class="Radio_padding">
                  <input type="radio" [(ngModel)]="appointmentTypes" (change)="appointmentchangeValue($event)" name="Appointment" value="past" /> Past
                </label>
                
                <label class="Radio_padding">
                  <input type="radio" [(ngModel)]="appointmentTypes" (change)="appointmentchangeValue($event)" name="Appointment" value="future" /> Future
                </label>
                <label class="Radio_padding">
                  <input type="radio" [(ngModel)]="appointmentTypes" (change)="appointmentchangeValue($event)" name="Appointment" value="all" /> All
                </label>
                <label class="Radio_padding">
                  <input type="radio" [(ngModel)]="appointmentTypes" (change)="appointmentchangeValue($event)"
                    name="Appointment" value="Deleted" /> Deleted
                </label>
              </div>
            </div>
          </div>
        </section>
      </aside>
      </div>
      <div  class="d-flex mt-2 justify-content-center" *ngIf="clientTherapydetails.length ==  0 && appointmentTypes == 'future'"> 
       <span class="text-danger p-2">No Appointments for future </span>
      </div>
      <div class="d-flex mt-2 justify-content-center" *ngIf="clientTherapydetails.length ==  0 && appointmentTypes == 'past'"> 
        <span class="text-danger p-2">No Appointments for past </span>
      </div>
      <div class="d-flex mt-2 justify-content-center" *ngIf="clientTherapydetails.length ==  0 && appointmentTypes == 'all'"> 
        <span class="text-danger p-2">No Appointments for all </span>
      </div>
      <div class="d-flex mt-2 justify-content-center"
      *ngIf="clientTherapydetailsDelete.length ==  0 && appointmentTypes == 'Deleted'">
      <span class="text-danger p-2">No Appointments for Deleted </span>
    </div>
      
      <div class="row" *ngIf="clientTherapydetails.length > 0">
        <div style="margin-top: 10px;">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Location</th>
                <th>Provider</th>
                <th>Date</th>
                <th>Time</th>
                <th>Duration</th>
                <th>Reason</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let li of clientTherapydetails;let i=index" (click)="chooseTheraphy(li)">
                <td>{{li.location}}</td>
                <td>{{li.clinicianName}}</td>
                <td>{{li.scheduleDate}}</td>
                <td>{{li.fromTime |date:'shortTime'}}</td>
                <td>{{li.duration}}</td>
                <td>{{li.reason}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row scrollwdth" *ngIf="clientTherapydetailsDelete.length > 0">
        <div style="margin-top: 10px;">
          <table class="table table-bordered tblwidth">
            <thead>
              <tr>
                <th style="width: 3%;">Location</th>
                <th style="width: 4%;">Provider</th>
                <th style="width: 3%;">Date</th>
                <th style="width: 3%;">Time</th>
                <th style="width: 3%;">Duration</th>
                <th style="width: 5%;">Reason</th>
                <th style="width: 3%;">Reason for deletion</th>
                <th style="width: 25%;">Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let li of clientTherapydetailsDelete;let i=index" (click)="chooseTheraphy(li)">
                <td>{{li.location}}</td>
                <td>{{li.clinicianName}}</td>
                <td>{{li.scheduleDate}}</td>
                <td>{{li.fromTime |date:'shortTime'}}</td>
                <td>{{li.duration}}</td>
                <td>{{li.reason}}</td>
                <td>{{li.deleteReason}}</td>
                <td>{{li.deleteComments}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        
      </div>
  </mat-dialog-content>
</ng-template>
<a mimetype="application/octet-stream" #downloadLink></a>
<a mimetype="application/octet-stream" #mainpage></a>

 <div class="comment-popup re-schedule-popup anyclick-popup">
    <div class="modal" tabindex="-1" role="dialog" id="scheduleAppointment" [style.display]="isSchedule ? 'block' : 'none'">
      <div class="modal-dialog mat-dialog-container " role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title"> Schedule Appointment</h6>
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OnCancelReschedule()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
  <div >
    <form (ngSubmit)="this.isFormSubmitted=true;scheduleModality.form.valid && getConflictsModality(); " id="scheduleModality"
    autocomplete="off" #scheduleModality="ngForm" novalidate>
    <div class="col-md-12">
        <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5">
                <label class="control-label-new font-weight-bold">Location</label>
        
              </div>
        
               <div class="col-md-7 form-group">
                <select class="form-control  mb-2" [(ngModel)]="dtoScheduleModality.location"
                #location="ngModel" name="location" required (change)="getMeetingLocationById($event.target.value)">
                <option value=0>--Select Location--</option>
                <option *ngFor="let a of ddlSfccLocationforclinician" value="{{a.sfccMasterId}}">
                   {{a.locationName}}
                </option>
             </select>
             <mat-error *ngIf="scheduleModality.submitted && (location.invalid||dtoScheduleModality.location==0)">
                <mat-error class="mat-form-field-subscript-wrapper mt-3"
                   *ngIf="dtoScheduleModality.location==0">Location
                   is
                   required</mat-error>
             </mat-error>
              </div>
              </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5">
                <label class="control-label-new font-weight-bold">Client Name</label>
               </div>
                 <!-- <div class="col-md-7 form-group"> -->
                  <!-- <select class="form-control mb-2"  id="searchDropdown" name="searchDropdown"
                  [(ngModel)]="dtoScheduleModality.patientAccountId" #patientAccountId="ngModel" 
                  (change)="getModalityForSchedule(dtoScheduleModality.patientAccountId)" required>
                  <option value="undefined">--Select Client--</option>
                  <option *ngFor="let a of ddlpatientList" value="{{a.patientAccountId}}">
                     {{a.patientName}}
                  </option>
               </select> -->

               <div class="col-md-7 client-mat-autocomplete">
                <mat-form-field>
                  <input matInput name="patientAccountId" [(ngModel)]="searchpatientName" #patientAccountId="ngModel"
                   placeholder="Select Client" [matAutocomplete]="auto" required 
                   
                   (input)="filterClients($event.target.value)">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getPatientId($event)" class="client-mat-autocomplete-list">
                        <mat-option *ngFor="let a of filteredClientName" [value]="a.patientName">
                            {{a.patientName}}
                        </mat-option>
                    </mat-autocomplete> 
                </mat-form-field>
                <mat-error *ngIf="scheduleModality.submitted && patientAccountId.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3"
                     *ngIf="patientAccountId.errors.required">Client Name is required</mat-error>
               </mat-error>
               </div>

               
                <!-- <p-autoComplete name="patientAccountId"  [(ngModel)]="dtoScheduleModality.patientAccountId" #patientAccountId="ngModel"
                [suggestions]="ddlpatientList" 
                 field="patientName">
              </p-autoComplete> -->
              <!-- <div>
                <p-autoComplete name="patientAccountId" 
                [(ngModel)]="dtoScheduleModality.patientAccountId.patientAccountId" #patientAccountId="ngModel"
                [suggestions]="filteredClientName" 
                (onSelect)="getModalityForSchedule(dtoScheduleModality.patientAccountId)"
                (completeMethod)="filterClients($event)" field="patientName"></p-autoComplete>
            </div> -->
            
            
               
                <!-- </div> -->
            </div>
          </div>
        </div>
           
      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Appointment Type</label>
             </div>
               <div class="col-md-7 form-group">
                <select class="form-control mb-2" name="therapyId"
                [(ngModel)]="dtoScheduleModality.therapyId" #therapyId="ngModel" name="therapyId"
                (change)="ModalityChange(dtoScheduleModality.therapyId)"  required>
                <option value="undefined">--Select Appointment Type--</option>
                <option *ngFor="let a of _dtoModality" value="{{a.therapyId}}">
                   {{a.therapyName}}
                </option>
             </select>
             <mat-error *ngIf="scheduleModality.submitted && therapyId.invalid">
               <mat-error class="mat-form-field-subscript-wrapper mt-3"
                  *ngIf="therapyId.errors.required">Appointment Type is required</mat-error>
            </mat-error>
              </div>
            </div>
            </div>


        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Appointment  Date</label>
      
            </div>
      
             <div class="col-md-7  ">
              <mat-form-field class="example-full-width w-100 " >
                <mat-label>Choose a date</mat-label>
                <input required matInput [matDatepicker]="picker2" readonly
                   [min]="minDate"  (dateChange)="handleStartDatechange($event)"
                   [(ngModel)]="dtoScheduleModality.startDate"
                   #startDate="ngModel" name="startDate" />
                <mat-datepicker-toggle matSuffix [for]="picker2">
                </mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error *ngIf="scheduleModality.submitted && startDate.invalid">
                   <mat-error *ngIf="startDate.errors.required">Appointment  Date is required
                   </mat-error>
                </mat-error>
             </mat-form-field>
            </div>
          </div>
        </div>
      
      </div>
      
      
       <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5">
                <label class="control-label-new font-weight-bold">Appointment  Time</label>
              </div>
              <div class="col-md-7 ">
                <mat-form-field class="w-100 mt--14">
                  <mat-label>Select appointment time </mat-label>
                <mat-select   #startTime="ngModel" (selectionChange)="OnTimeChange()" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
                max="12:00" required
                required>
                <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
                    {{item.slotTime | date: 'hh:mm a'}}
                </mat-option>
            </mat-select>
            
            <mat-error *ngIf="scheduleModality.submitted && startTime.invalid">
              <mat-error *ngIf="startTime.errors.required ">Appointment Time is required
              </mat-error>
          </mat-error>
          </mat-form-field>
          <mat-error class="text-xs" *ngIf="IspastTime">
            Appointment Time is invalid
            </mat-error>
              </div>
              </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5">
                <label class="control-label-new font-weight-bold">Duration</label>
        
              </div>
        
                <div class="col-md-4 form-group">
                <select class="form-control mb-1" name="duration1"
                [(ngModel)]="durationhr"  required (change)="hoursChange(durationhr)">
                <option value="undefined">hr</option>         
                <option value=0>0 hr</option>
                <option value=60>1 hr</option>
                <option value=120>2 hr</option>
                <option value=180>3 hr</option>
                <option value=240>4 hr</option>
                <option value=300>5 hr</option>
                <option value=360>6 hr</option>
                <option value=420>7 hr</option>
                <option value=480>8 hr</option>
              </select>
              <mat-error *ngIf="scheduleModality.submitted && (durationmin==undefined || durationhr==undefined)">
                <mat-error class="mat-form-field-subscript-wrapper mt-3"
                    *ngIf="( durationhr==undefined)">Hours required</mat-error>
              </mat-error>
              <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="IsDurationInvalid">
              Duration is invalid
                </mat-error>
              </div>
              <div class="col-md-3 form-group">
                <select class="form-control " name="duration2"
                [(ngModel)]="durationmin"  required (change)="minChange(durationmin)">
                <option value="undefined">mins</option>
                <option value=00>00 mins</option>
                <option value=15>15 mins</option>
                <option value=30>30 mins</option>
                <option value=45>45 mins</option>             
              </select>
              <mat-error *ngIf="scheduleModality.submitted && (durationmin==undefined || durationhr==undefined)">
              <mat-error class="mat-form-field-subscript-wrapper mt-3"
                  *ngIf="(durationmin==undefined )">Mins required</mat-error>
            </mat-error>
              </div>
              
              </div>
          </div>
       </div> 
    
      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Provider</label>
             </div>
               <div class="col-md-7 form-group">
                <select class="form-control mb-2" 
                [(ngModel)]="dtoScheduleModality.provider" #provider="ngModel" name="provider" required (change)="modalityChange(dtoScheduleModality.provider)">
                <option value="undefined">--Select Provider--</option>
                <option *ngFor="let a of ddlStafflist" value="{{a.staffId}}">
                   {{a.firstName}} {{a.lastName}}
                </option>
             </select>
             <mat-error *ngIf="scheduleModality.submitted && provider.invalid">
              <mat-error class="mat-form-field-subscript-wrapper mt-3"
                  *ngIf="provider.errors.required">Provider is required</mat-error>
            </mat-error>
              </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Meeting Room</label>
      
            </div>
      
              <div class="col-md-7 form-group">
              <select class="form-control mb-2" [(ngModel)]="dtoScheduleModality.meetingRoom"
                #meetingRoom="ngModel" name="meetingRoom" required (change)="meetingRoomChange(dtoScheduleModality.meetingRoom)">
                <option value="undefined">--Select Location--</option>
                <option *ngFor="let a of ddlMeetinglocation" value="{{a.meetingLocationsId}}">
                    {{a.meetingName}}
                </option>
                </select>
                <mat-error *ngIf="scheduleModality.submitted && meetingRoom.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3"
                      *ngIf="meetingRoom.errors.required">Meeting Room is required</mat-error>
                </mat-error>
            </div>
            </div>
        </div>
       
      </div>
     
      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Meeting Type</label>
              </div>
              <div class="col-md-7 form-group">
                 <select class="form-control mb-2"  [(ngModel)]="dtoScheduleModality.meetingType" #meetingType="ngModel" name="meetingType" required (change)="meetingTypeChange(dtoScheduleModality.meetingType)">
                     <!-- <option [value]=0>Select Meeting Type</option> -->
                     <option value="undefined">--Select Meeting Type--</option>
                     <option *ngFor="let item of ddlMeetingType " [value]="item.msMeetingTypeId" >{{item.meetingType}}</option>
                 </select>
                 <mat-error *ngIf="scheduleModality.submitted && meetingType.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3"
                       *ngIf="meetingType.errors.required">Meeting Type is required</mat-error>
                 </mat-error>
           </div>
            </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-5">
                  <label class="control-label-new font-weight-bold">Admin Notes</label>
          
                </div>
          
                 <div class="col-md-7 form-group">
                  <textarea cols="2" cols="2" class="form-control font-italic" maxlength="400" #adminNotes="ngModel" name="adminNotes"  [(ngModel)]="dtoScheduleModality.adminNotes"   placeholder="Enter admin  notes" ></textarea>
                 
                </div>
                </div>
                </div>
        
    </div>
     

    </div>
   
    <div class="text-right col-md-12 mt-2">
      <button mat-raised-button type="submit" class="btn primary-btn-filled primary-btn-m h-40 mr-2"  >Schedule</button>
      <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="OnCancelReschedule()" >Cancel</button>
    </div>
  
  
  </form>
  </div>
  
  
  </div>
  </div>
  </div>
  </div>
  </div> 

 <div class="comment-popup re-schedule-popup blocktime-popup">
  <div class="modal" tabindex="-1" role="dialog" id="blockTime" [style.display]="isBlockTime ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container" role="document">
      <div class="modal-content border-0">
        <div class="modal-header px-5">
          <h6 class="modal-title">BLOCK TIME FRAME</h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="OnCancelReschedule()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form class="px-5"
              (ngSubmit)="blockTime.form.valid && getConflictsModalityRecurrenceBlock()"
              id="blockTime"
              autocomplete="off"
              #blockTime="ngForm"
              novalidate
            >
            <div class="row">
              <div  class="col-md-3 pr-0 pl-0">
                <label class="control-label-new font-weight-bold">Provider</label>
               </div>
                 <div  class="col-md-4 pl-0">
                  <select class="form-control mb-2"
                  [(ngModel)]="dtoScheduleModality.provider" #provider="ngModel" name="provider" required >
                  <option value="undefined">--Select Provider--</option>
                  <option *ngFor="let a of ddlStafflist" value="{{a.staffId}}">
                     {{a.firstName}} {{a.lastName}}
                  </option>
               </select>
               <mat-error *ngIf="blockTime.submitted && provider.invalid">
                <mat-error class="mat-form-field-subscript-wrapper mt-3"
                    *ngIf="provider.errors.required">Provider is required</mat-error>
              </mat-error>
                </div>
            </div>
              <div class="row">
                <div class="col-md-3 pr-0 pl-0">
                  <label class="control-label-new font-weight-bold"
                    >Purpose of Blocking</label
                  >
                </div>
                <div class="col-md-4 pl-0">
                  <div class="form-group">
                    <select
                      class="form-control mb-2"
                      name="purposeMasterId"
                      [(ngModel)]="dtoScheduleModality.blockTimeId"
                      #purposeMasterId="ngModel"
                      
                      required
                    >
                      <option value="undefined">--Select Purpose--</option>
                      <option
                        *ngFor="let a of purposeOfBlockTime"
                        value="{{ a.blockTimeId }}"
                      >
                        {{ a.dtoPurposeMaster.purposeName }}
                      </option>
                    </select>
                    <mat-error
                      *ngIf="
                      blockTime.submitted && purposeMasterId.invalid"
                    >
                      <mat-error
                        class="mat-form-field-subscript-wrapper mt-3"
                        *ngIf="purposeMasterId.errors.required"
                        >Purpose of Blocking is required</mat-error
                      >
                    </mat-error>
                  </div>
                </div>
                <div class="col-md-5 pr-0 pl-0">
                  <label class="control-label-new align-middle  font-weight-bold mt-2">
                    Recurrence</label>
                  <div class=" mt-2 d-inline-block align-middle pl-2 ">
          
                      <mat-radio-group aria-label="Select an option" [(ngModel)]="dtoScheduleModality.isRecuranceBlock"
                          #isRecurance="ngModel" name="isRecuranceBlock" required >
                          <mat-radio-button [value]="true"  class="mr-4">Yes</mat-radio-button>
                          <mat-radio-button [value]="false"  class="mr-4">No</mat-radio-button>
                       
                      </mat-radio-group>
                      
                  </div>


     

              </div>
              </div>
  
                <div class="row">
                  <div class="col-md-3 pr-0 pl-0">
                    <label class="control-label-new font-weight-bold">Start time</label>
            
                  </div>
                  <div class="col-md-4  pl-0">
                    <mat-form-field class="w-100 mt--14">
                      <mat-label>Select start time </mat-label>
                    <mat-select   #startTime="ngModel" (selectionChange)="OnTimeChange()" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
                    max="12:00" required
                    required>
                    <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
                        {{item.slotTime | date: 'hh:mm a'}}
                    </mat-option>
                </mat-select>
               
              </mat-form-field>
               <mat-error class="text-xs" *ngIf="IspastTime">
                Start  Time is invalid
                </mat-error>
                  </div>
                    <div class="col-md-1 pr-0 pl-0">
                      <label class="control-label-new font-weight-bold">Duration</label>
              
                    </div>
              
                     <div class="col-md-2 form-group pl-2">
                      <select class="form-control mb-1" name="duration1"
                      [(ngModel)]="durationhr"  required>
                      <option value="undefined">hr</option>         
                      <option value=0>0 hr</option>
                      <option value=60>1 hr</option>
                      <option value=120>2 hr</option>
                      <option value=180>3 hr</option>
                      <option value=240>4 hr</option>
                      <option value=300>5 hr</option>
                      <option value=360>6 hr</option>
                      <option value=420>7 hr</option>
                      <option value=480>8 hr</option>
                   </select>
                   <mat-error *ngIf="blockTime.submitted && (durationmin==undefined || durationhr==undefined)">
                      <mat-error class="mat-form-field-subscript-wrapper mt-3"
                         *ngIf="( durationhr==undefined)">Hours required</mat-error>
                   </mat-error>
                   <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="IsDurationInvalid">
                    Duration is invalid
                     </mat-error>
                    </div>
                   
                    <div class="col-md-2 form-group pl-1">
                      <select class="form-control " name="duration2"
                      [(ngModel)]="durationmin"  required>
                      <option value="undefined">mins</option>
                      <option value=00>00 mins</option>
                      <option value=15>15 mins</option>
                      <option value=30>30 mins</option>
                      <option value=45>45 mins</option>             
                   </select>
                   <mat-error *ngIf="blockTime.submitted && (durationmin==undefined || durationhr==undefined)">
                    <mat-error class="mat-form-field-subscript-wrapper mt-3"
                       *ngIf="(durationmin==undefined )"> Mins required</mat-error>
                 </mat-error>
                    </div>
                   
                    </div>
                    <div class="row">
                      <div class="col-md-3 pr-0 pl-0">
                        <label class="control-label-new font-weight-bold"
                          >Notes</label
                        >
                      </div>
                      <div class="col-md-9 pl-0">
                        <textarea
                          cols="4"
                          class="form-control font-italic"
                          placeholder=""
                          [(ngModel)]="dtoScheduleModality.notes"
                          #notes="ngModel"
                          name="notes"
                          maxlength="400"
                          resize="none"
                        ></textarea>
                     
                      </div>
                    </div>
                    <div class="row mt-2" *ngIf="dtoScheduleModality.isRecuranceBlock==true">

                          <div class="col-md-3 pr-0 pl-0">
                            <label class="control-label-new font-weight-bold">Start Date</label>
                           </div>
                           <div class="col-md-4 pl-0">
                            <mat-form-field class="example-full-width w-100 " >
                              <mat-label>Choose a date</mat-label>
                              <input  matInput [matDatepicker]="picker7" readonly
                                 [min]="minDate"  (dateChange)="handleStartDatechange($event)"
                                 [(ngModel)]="dtoScheduleModality.startDate"
                                 #startDate="ngModel" name="startDate" />
                              <mat-datepicker-toggle matSuffix [for]="picker7">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker7></mat-datepicker>
                              <mat-error *ngIf="blockTime.submitted && startDate.invalid && dtoScheduleModality.isRecuranceBlock==true">
                                <mat-error *ngIf="startDate.errors.required">Start Date is required
                                </mat-error>
                             </mat-error>
                           </mat-form-field>
                          </div>
                       
                
                
                      <div class="col-md-2 pr-0 pl-0">
                       
                            <label class="control-label-new font-weight-bold">End  Date</label>
                    
                          </div>
                    
                           <div class="col-md-3 pl-0">
                            <mat-form-field class="example-full-width w-100 " >
                              <mat-label>Choose a date</mat-label>
                              <input  matInput [matDatepicker]="picker5" readonly
                                 [min]="minDate"  (dateChange)="handleEndDatechange($event)"
                                 [(ngModel)]="dtoScheduleModality.endDate"
                                 #endDate="ngModel" name="endDate" />
                              <mat-datepicker-toggle matSuffix [for]="picker5">
                              </mat-datepicker-toggle>
                              <mat-datepicker #picker5></mat-datepicker>
                             <mat-error *ngIf="blockTime.submitted && endDate.invalid && dtoScheduleModality.isRecuranceBlock==true">
                 <mat-error *ngIf="endDate.errors.required">End Date is required
                 </mat-error>
              </mat-error>
                           </mat-form-field>
                          </div>
                        </div>
                    
              <div class="row">
                

                <div class="col-md-7 pr-0 pl-0"  *ngIf="dtoScheduleModality.isRecuranceBlock">
                  
                        <div class="mt-2 d-inline-block align-middle">
                            <label class="control-label-new font-weight-bold  align-middle mr-3">Frequency</label>
            
                                <mat-radio-group aria-label="Select an option" [(ngModel)]="dtoScheduleModality.frequencyBlock"
                                    #frequency="ngModel" name="frequencyBlock" required>
                                    <mat-radio-button [value]="4" class="mr-2">Daily </mat-radio-button>
                                    <mat-radio-button [value]="5" class="mr-2">Weekly </mat-radio-button>
                                    <mat-radio-button [value]="6" class="mr-2">Monthly </mat-radio-button>
                                </mat-radio-group>
                              
                        </div>
                    
            
                </div>
                <div class="col-md-5 pr-0 pl-0"  *ngIf="dtoScheduleModality.frequencyBlock == 5 && dtoScheduleModality.isRecuranceBlock==true">
          <div class="row">
   <div class="col-md-6 pr-10">
   <label class="control-label-new font-weight-bold">Repeat every</label>
</div>
<div class="col-md-2 pl-0">
  <select class="drpdown" name="repeatnumber" [(ngModel)]="dtoScheduleModality.quantityBlock" #quantityBlock="ngModel"
  required>
  <option [value]=1>1</option>
  <option [value]=2>2</option>
  <option [value]=3>3</option>
  <option [value]=4>4</option>
 </select>
</div>

<div class="col-md-2 pl-0">
                
                  <div class="repeatdrpdown">
                    <select class="drpdown" name="repeatWeek" [(ngModel)]="dtoScheduleModality.repeatWeek" #repeatWeek="ngModel"
                    required>
                    <option [value]=2>Week</option>       
                  </select>
                  </div>
     </div>
    </div>
                
                </div>
                </div>
                <div class="row">
                <div class="col-md-12" *ngIf="dtoScheduleModality.frequencyBlock == 5  && dtoScheduleModality.isRecuranceBlock==true">
                    <div class="row">
                        <div class="col check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isSunday" #sunday="ngModel"
                                name="sunday" (change)="onWeekDaySelection($event.checked,0)">Sunday
                            </mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isMonday" #monday="ngModel"
                                name="monday" (change)="onWeekDaySelection($event.checked,1)" >Monday
                            </mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isTuesday"
                                #tuesday="ngModel" name="tuesday" (change)="onWeekDaySelection($event.checked,2)">
                                Tuesday</mat-checkbox>
                        </div>
                        <div class="col check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isWednesday"
                                #wednesday="ngModel" name="wednesday" (change)="onWeekDaySelection($event.checked,3)">
                                Wednesday</mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isThursday"
                                #thursday="ngModel" name="thursday" (change)="onWeekDaySelection($event.checked,4)">Thursday</mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isFriday" #friday="ngModel"
                                name="friday" (change)="onWeekDaySelection($event.checked,5)">Friday</mat-checkbox>
                        </div>
                        <div class="col check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isSaturday"
                                #saturday="ngModel" name="saturday" (change)="onWeekDaySelection($event.checked,6)">Saturday</mat-checkbox>
                        </div>
                    </div>
            
                </div>
                </div>

                <div class="row" *ngIf="dtoScheduleModality.frequencyBlock == 6  && dtoScheduleModality.isRecuranceBlock==true">
                  
                      <div class="col-md-3 pr-0 pl-0 mt-2">
                      <label class="control-label-new align-middle  font-weight-bold mt-2">Repeat every</label>
                      </div>
                              <div class="col-md-3 pl-0">
                                  <mat-form-field class="w-100">
          
                                      <mat-select name="weekOfMonth" [(ngModel)]="dtoScheduleModality.weekOfMonth" #weekOfMonth="ngModel"
                                          required>
                                          <mat-option [value]=1>First</mat-option>
                                          <mat-option [value]=2>Second</mat-option>
                                          <mat-option [value]=3>Third</mat-option>
                                          <mat-option [value]=4>Fourth</mat-option>
                                      </mat-select>
                                  </mat-form-field>
                                  <!-- <mat-error *ngIf="appointmentinfo.submitted && weekOfMonth.invalid">
                                      <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                          *ngIf="weekOfMonth.errors.required">Repeat every is required
                                      </mat-error>
                                  </mat-error> -->
                                  </div>
                                  <div class="col-md-3 pl-0">
                                  <mat-form-field class="w-100">
          
                                      <mat-select name="weekDaysOnMonth" [(ngModel)]="dtoScheduleModality.weekDaysOnMonth" #weekDaysOnMonth="ngModel"
                                          required>
                                          <mat-option [value]=0>Sunday</mat-option>
                                          <mat-option [value]=1>Monday</mat-option>
                                          <mat-option [value]=2>Tuesday</mat-option>
                                          <mat-option [value]=3>Wednesday</mat-option>
                                          <mat-option [value]=4>Thursday</mat-option>
                                          <mat-option [value]=5>Friday</mat-option>
                                          <mat-option [value]=6>Saturday</mat-option>
                                      </mat-select>
                                  </mat-form-field>
                                  <!-- <mat-error *ngIf="appointmentinfo.submitted && weekDaysOnMonth.invalid">
                                      <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                          *ngIf="weekDaysOnMonth.errors.required">Repeat every is required
                                      </mat-error>
                                  </mat-error> -->
                              </div>
                          
          
          
              </div>
           

              <div class="text-right col-md-12 mt-3">            
                <button *ngIf="dtoScheduleModality.isRecuranceBlock == true"
                  mat-raised-button
                  class="btn primary-btn-filled primary-btn-m h-40 mr-2"
                >
                  Save
                </button>

                <button
                mat-raised-button  *ngIf="dtoScheduleModality.isRecuranceBlock == false"
                class="btn primary-btn-filled primary-btn-m h-40 mr-2" (click)="getConflictsModality()"
              >
                Save  
              </button>

                <button
                  type="button"
                  class="btn btn-cancel"
                  data-dismiss="modal"
                  (click)="OnCancelReschedule()"
                >
                  Clear
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>  

<!-- Old Block time frame -- Changed for Recurrence on Feb 15 2024 
  
  <div class="comment-popup re-schedule-popup blocktime-popup">
  <div class="modal" tabindex="-1" role="dialog" id="blockTime" [style.display]="isBlockTime ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container" role="document">
      <div class="modal-content border-0">
        <div class="modal-header px-5">
          <h6 class="modal-title">BLOCK TIME FRAME</h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="OnCancelReschedule()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form class="px-5"
              (ngSubmit)="blockTime.form.valid && getConflictsModality()"
              id="blockTime"
              autocomplete="off"
              #blockTime="ngForm"
              novalidate
            >
              <div class="row mb-2">
                <div class="col-md-4 pr-0 pl-0">
                  <label class="control-label-new font-weight-bold"
                    >Purpose of Blocking</label
                  >
                </div>
                <div class="col-md-5 pl-0">
                  <div class="form-group">
                    <select
                      class="form-control mb-2"
                      name="purposeMasterId"
                      [(ngModel)]="dtoScheduleModality.blockTimeId"
                      #purposeMasterId="ngModel"
                      
                      required
                    >
                      <option value="undefined">--Select Purpose--</option>
                      <option
                        *ngFor="let a of purposeOfBlockTime"
                        value="{{ a.blockTimeId }}"
                      >
                        {{ a.dtoPurposeMaster.purposeName }}
                      </option>
                    </select>
                    <mat-error
                      *ngIf="
                      blockTime.submitted && purposeMasterId.invalid"
                    >
                      <mat-error
                        class="mat-form-field-subscript-wrapper mt-3"
                        *ngIf="purposeMasterId.errors.required"
                        >Purpose of Blocking is required</mat-error
                      >
                    </mat-error>
                  </div>
                </div>
              </div>
  
                <div class="row">
                  <div class="col-md-4 pr-0 pl-0">
                    <label class="control-label-new font-weight-bold">Start time</label>
            
                  </div>
                  <div class="col-md-7 ">
                    <mat-form-field class="w-100 mt--14">
                      <mat-label>Select start time </mat-label>
                    <mat-select   #startTime="ngModel" (selectionChange)="OnTimeChange()" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
                    max="12:00" required
                    required>
                    <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
                        {{item.slotTime | date: 'hh:mm a'}}
                    </mat-option>
                </mat-select>
               
              </mat-form-field>
               <mat-error class="text-xs" *ngIf="IspastTime">
                Start  Time is invalid
                </mat-error>
                  </div>
                 
                  </div> 
                  <div class="row">
                    <div class="col-md-4 pr-0 pl-0">
                      <label class="control-label-new font-weight-bold">Duration</label>
              
                    </div>
              
                     <div class="col-md-4 form-group pl-0">
                      <select class="form-control mb-1" name="duration1"
                      [(ngModel)]="durationhr"  required>
                      <option value="undefined">hr</option>         
                      <option value=0>0 hr</option>
                      <option value=60>1 hr</option>
                      <option value=120>2 hr</option>
                      <option value=180>3 hr</option>
                      <option value=240>4 hr</option>
                      <option value=300>5 hr</option>
                      <option value=360>6 hr</option>
                      <option value=420>7 hr</option>
                      <option value=480>8 hr</option>
                   </select>
                   <mat-error *ngIf="blockTime.submitted && (durationmin==undefined || durationhr==undefined)">
                      <mat-error class="mat-form-field-subscript-wrapper mt-3"
                         *ngIf="( durationhr==undefined)">Hours required</mat-error>
                   </mat-error>
                   <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="IsDurationInvalid">
                    Duration is invalid
                     </mat-error>
                    </div>
                   
                    <div class="col-md-3 form-group">
                      <select class="form-control " name="duration2"
                      [(ngModel)]="durationmin"  required>
                      <option value="undefined">mins</option>
                      <option value=00>00 mins</option>
                      <option value=15>15 mins</option>
                      <option value=30>30 mins</option>
                      <option value=45>45 mins</option>             
                   </select>
                   <mat-error *ngIf="blockTime.submitted && (durationmin==undefined || durationhr==undefined)">
                    <mat-error class="mat-form-field-subscript-wrapper mt-3"
                       *ngIf="(durationmin==undefined )"> Mins required</mat-error>
                 </mat-error>
                    </div>
                   
                    </div>
       

              <div class="row mb-2">
                <div class="col-md-4 pr-0 pl-0">
                  <label class="control-label-new font-weight-bold"
                    >Notes</label
                  >
                </div>
                <div class="col-md-7 pl-0">
                  <textarea
                    cols="3"
                    class="form-control font-italic"
                    placeholder=""
                    [(ngModel)]="dtoScheduleModality.notes"
                    #notes="ngModel"
                    name="notes"
                    maxlength="400"
                    resize="none"
                  ></textarea>
                 
                </div>
              </div>

              <div class="text-right col-md-12 mt-3">
                <button
                  mat-raised-button
                  class="btn primary-btn-filled primary-btn-m h-40 mr-2"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-cancel"
                  data-dismiss="modal"
                  (click)="OnCancelReschedule()"
                >
                  Clear
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>   -->
 <div class="comment-popup re-schedule-popup selecttime-popup">
  <div class="modal" tabindex="-1" role="dialog" id="selectTime" [style.display]="isScheduleOrBlock ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container" role="document">
      <div class="modal-content border-0">
         <div class="modal-header border-0">
       
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="OnCancelReschedule()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form
              (ngSubmit)="scheduleModality.form.valid && getConflictsModality()"
              id="scheduleModality"
              autocomplete="off"
              #scheduleModality="ngForm"
              novalidate
            >
              <div class="row mb-2 pb-4">
                <div class="col-md-12 mt-2 mb-4 text-center">
                  <p>Kindly choose the required service</p>
                </div>
                <div class="d-flex m-auto">
                  <button
                    mat-raised-button
                    class="btn primary-btn-filled primary-btn-m h-40 mr-2"
                  (click)="ScheduleAppointment()">
                    Schedule Appointment
                  </button>
                  <button
                    type="button"
                    class="btn primary-btn-filled primary-btn-m h-40 mr-2"
                    data-dismiss="modal"
                    (click)="BlockTime()"
                  >
                    Block Time
                  </button>
                 
                </div>
                <div class="recurncepopup">
                  <button
                  mat-raised-button
                  class="btn primary-btn-filled primary-btn-m h-40 mr-2"
                (click)="ScheduleAppointmentRecurrence()">
                  Schedule Recurring Appointment
                </button>
                </div>
               
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>  
<div class="comment-popup re-schedule-popup anyclick-popup">
  <div class="modal" tabindex="-1" role="dialog" id="scheduleAppointment" [style.display]="isRecurrence ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container " role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h6 class="modal-title"> Schedule Appointment</h6>
          <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OnCancelReschedule()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
<div >
  <form (ngSubmit)="scheduleModalityRecurence.form.valid && getConflictsModalityRecurrence() " id="scheduleModalityRecurence"
  autocomplete="off" #scheduleModalityRecurence="ngForm" novalidate>
  <div class="col-md-12">
      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Location</label>
      
            </div>
      
             <div class="col-md-7 form-group">
              <select class="form-control  mb-2" [(ngModel)]="dtoScheduleModality.location"
              #location="ngModel" name="location" required (change)="getMeetingLocationById($event.target.value)">
              <option value=0>--Select Location--</option>
              <option *ngFor="let a of ddlSfccLocation" value="{{a.sfccMasterId}}">
                 {{a.locationName}}
              </option>
           </select>
           <mat-error *ngIf="scheduleModalityRecurence.submitted && (location.invalid||dtoScheduleModality.location==0)">
              <mat-error class="mat-form-field-subscript-wrapper mt-3"
                 *ngIf="dtoScheduleModality.location==0">Location
                 is
                 required</mat-error>
           </mat-error>
            </div>
            </div>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Client Name</label>
             </div>
               <!-- <div class="col-md-7 form-group"> -->
                <!-- <select class="form-control mb-2"  id="searchDropdown" name="searchDropdown"
                [(ngModel)]="dtoScheduleModality.patientAccountId" #patientAccountId="ngModel" 
                (change)="getModalityForSchedule(dtoScheduleModality.patientAccountId)" required>
                <option value="undefined">--Select Client--</option>
                <option *ngFor="let a of ddlpatientList" value="{{a.patientAccountId}}">
                   {{a.patientName}}
                </option>
             </select> -->

             <div class="col-md-7 client-mat-autocomplete">
              <mat-form-field>
                <input matInput name="patientAccountId" [(ngModel)]="searchpatientName" #patientAccountId="ngModel"
                 placeholder="Select Client" [matAutocomplete]="auto" required 
                 
                 (input)="filterClients($event.target.value)">
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getPatientId($event)" class="client-mat-autocomplete-list">
                      <mat-option *ngFor="let a of filteredClientName" [value]="a.patientName">
                          {{a.patientName}}
                      </mat-option>
                  </mat-autocomplete> 
              </mat-form-field>
              <mat-error *ngIf="scheduleModalityRecurence.submitted && patientAccountId.invalid">
                <mat-error class="mat-form-field-subscript-wrapper mt-3"
                   *ngIf="patientAccountId.errors.required">Client Name is required</mat-error>
             </mat-error>
             </div>

             
              <!-- <p-autoComplete name="patientAccountId"  [(ngModel)]="dtoScheduleModality.patientAccountId" #patientAccountId="ngModel"
              [suggestions]="ddlpatientList" 
               field="patientName">
            </p-autoComplete> -->
            <!-- <div>
              <p-autoComplete name="patientAccountId" 
              [(ngModel)]="dtoScheduleModality.patientAccountId.patientAccountId" #patientAccountId="ngModel"
              [suggestions]="filteredClientName" 
              (onSelect)="getModalityForSchedule(dtoScheduleModality.patientAccountId)"
              (completeMethod)="filterClients($event)" field="patientName"></p-autoComplete>
          </div> -->
          
          
             
              <!-- </div> -->
          </div>
        </div>
      </div>
         
    <div class="row mb-2">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-5">
            <label class="control-label-new font-weight-bold">Appointment Type</label>
           </div>
             <div class="col-md-7 form-group">
              <select class="form-control mb-2" name="therapyId"
              [(ngModel)]="dtoScheduleModality.therapyId" #therapyId="ngModel" name="therapyId"
              (change)="ModalityChange(dtoScheduleModality.therapyId)"  required>
              <option value="undefined">--Select Appointment Type--</option>
              <option *ngFor="let a of _dtoModality" value="{{a.therapyId}}">
                 {{a.therapyName}}
              </option>
           </select>
           <mat-error *ngIf="scheduleModalityRecurence.submitted && therapyId.invalid">
             <mat-error class="mat-form-field-subscript-wrapper mt-3"
                *ngIf="therapyId.errors.required">Appointment Type is required</mat-error>
          </mat-error>
            </div>
          </div>
          </div>


      <div class="col-md-6">
        <div class="row">
          <div class="col-md-5">
            <label class="control-label-new font-weight-bold">Provider</label>
           </div>
             <div class="col-md-7 form-group">
              <select class="form-control mb-2" 
              [(ngModel)]="dtoScheduleModality.provider" #provider="ngModel" name="provider" required (change)="modalityChange(dtoScheduleModality.provider)">
              <option value="undefined">--Select Provider--</option>
              <option *ngFor="let a of ddlStafflist" value="{{a.staffId}}">
                 {{a.firstName}} {{a.lastName}}
              </option>
           </select>
           <mat-error *ngIf="scheduleModalityRecurence.submitted && provider.invalid">
            <mat-error class="mat-form-field-subscript-wrapper mt-3"
                *ngIf="provider.errors.required">Provider is required</mat-error>
          </mat-error>
            </div>
        </div>
       
      </div>
    
    
    </div>
    
    
     <div class="row mb-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Appointment  Time</label>
            </div>
            <div class="col-md-7 ">
              <mat-form-field class="w-100 mt--14">
                <mat-label>Select appointment time </mat-label>
              <mat-select   #startTime="ngModel" (selectionChange)="OnTimeChange()" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
              max="12:00" required
              required>
              <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
                  {{item.slotTime | date: 'hh:mm a'}}
              </mat-option>
          </mat-select>
          
          <mat-error *ngIf="scheduleModalityRecurence.submitted && startTime.invalid">
            <mat-error *ngIf="startTime.errors.required ">Appointment Time is required
            </mat-error>
        </mat-error>
        </mat-form-field>
        <mat-error class="text-xs" *ngIf="IspastTime">
          Appointment Time is invalid
          </mat-error>
            </div>
            </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Duration</label>
      
            </div>
      
              <div class="col-md-4 form-group">
              <select class="form-control mb-1" name="duration1"
              [(ngModel)]="durationhr"  required (change)="hoursChange(durationhr)">
              <option value="undefined">hr</option>         
              <option value=0>0 hr</option>
              <option value=60>1 hr</option>
              <option value=120>2 hr</option>
              <option value=180>3 hr</option>
              <option value=240>4 hr</option>
              <option value=300>5 hr</option>
              <option value=360>6 hr</option>
              <option value=420>7 hr</option>
              <option value=480>8 hr</option>
            </select>
            <mat-error *ngIf="scheduleModalityRecurence.submitted && (durationmin==undefined || durationhr==undefined)">
              <mat-error class="mat-form-field-subscript-wrapper mt-3"
                  *ngIf="( durationhr==undefined)">Hours required</mat-error>
            </mat-error>
            <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="IsDurationInvalid">
            Duration is invalid
              </mat-error>
            </div>
            <div class="col-md-3 form-group">
              <select class="form-control " name="duration2"
              [(ngModel)]="durationmin"  required (change)="minChange(durationmin)">
              <option value="undefined">mins</option>
              <option value=00>00 mins</option>
              <option value=15>15 mins</option>
              <option value=30>30 mins</option>
              <option value=45>45 mins</option>             
            </select>
            <mat-error *ngIf="scheduleModalityRecurence.submitted && (durationmin==undefined || durationhr==undefined)">
            <mat-error class="mat-form-field-subscript-wrapper mt-3"
                *ngIf="(durationmin==undefined )">Mins required</mat-error>
          </mat-error>
            </div>
            
            </div>
        </div>
     </div> 
  
     <div class="row mb-2">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-5">
            <label class="control-label-new font-weight-bold">Start Date</label>
           </div>
           <div class="col-md-7  ">
            <mat-form-field class="example-full-width w-100 " >
              <mat-label>Choose a date</mat-label>
              <input required matInput [matDatepicker]="picker2" readonly
                 [min]="minDate"  (dateChange)="handleStartDatechange($event)"
                 [(ngModel)]="dtoScheduleModality.startDate"
                 #startDate="ngModel" name="startDate" />
              <mat-datepicker-toggle matSuffix [for]="picker2">
              </mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
              <!-- <mat-error *ngIf="scheduleModality.submitted && startDate.invalid">
                 <mat-error *ngIf="startDate.errors.required">Appointment  Date is required
                 </mat-error>
              </mat-error> -->
           </mat-form-field>
          </div>
          </div>
          </div>


      <div class="col-md-6">
        <div class="row">
          <div class="col-md-5">
            <label class="control-label-new font-weight-bold">End  Date</label>
    
          </div>
    
           <div class="col-md-7  ">
            <mat-form-field class="example-full-width w-100 " >
              <mat-label>Choose a date</mat-label>
              <input required matInput [matDatepicker]="picker3" readonly
                 [min]="minDate"  (dateChange)="handleEndDatechange($event)"
                 [(ngModel)]="dtoScheduleModality.endDate"
                 #endDate="ngModel" name="endDate" />
              <mat-datepicker-toggle matSuffix [for]="picker3">
              </mat-datepicker-toggle>
              <mat-datepicker #picker3></mat-datepicker>
              <mat-error *ngIf="scheduleModalityRecurence.submitted && endDate.invalid">
                 <mat-error *ngIf="endDate.errors.required">End Date is required
                 </mat-error>
              </mat-error>
           </mat-form-field>
          </div>
        </div>
      </div>
    
    </div>
    
   

  </div>
  <div class="row" >
    <div class="col-md-3 ">
        <label class="control-label-new align-middle  font-weight-bold mt-2">
          Recurrence</label>
        <div class=" mt-2 d-inline-block align-middle pl-2 ">

            <mat-radio-group aria-label="Select an option" [(ngModel)]="dtoScheduleModality.isRecurance"
                #isRecurance="ngModel" name="isRecurance" required>
                <mat-radio-button [value]="true"  class="mr-2">Yes</mat-radio-button>
                <!-- <mat-radio-button [value]="false" class="mr-2">No</mat-radio-button> -->
            </mat-radio-group>
            <!-- <mat-error *ngIf="appointmentinfo.submitted && isRecurrance.invalid">
                <mat-error class="mat-form-field-subscript-wrapper mt-4"
                    *ngIf="isRecurrance.errors.required">Recurrance is
                    required
                </mat-error>
            </mat-error> -->
        </div>
    </div>
    <div class="col-md-5" *ngIf="dtoScheduleModality.isRecurance">
        <div class="">
            <div class="mt-2 d-inline-block align-middle pl-2">
                <label class="control-label-new font-weight-bold  align-middle mr-3">Frequency</label>

                    <mat-radio-group aria-label="Select an option" [(ngModel)]="dtoScheduleModality.frequency"
                        #frequency="ngModel" name="frequency" required>
                        <mat-radio-button [value]="4" class="mr-2" >Daily </mat-radio-button>
                        <mat-radio-button [value]="5" class="mr-2">Weekly </mat-radio-button>
                        <mat-radio-button [value]="6" class="mr-2">Monthly </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="scheduleModalityRecurence.submitted && frequency.invalid">
                      <mat-error class="mat-form-field-subscript-wrapper mt-3"
                         *ngIf="frequency.errors.required">Frequency is required</mat-error>
                   </mat-error>
                    <!-- <mat-error *ngIf="appointmentinfo.submitted && frequency.invalid">
                        <mat-error class="mat-form-field-subscript-wrapper mt-3"
                            *ngIf="frequency.errors.required">Frequency is
                            required</mat-error>
                    </mat-error> -->

            </div>
        </div>

    </div>
    <div class="col-md-4 row" *ngIf="dtoScheduleModality.frequency == 5">
      <label class="control-label-new font-weight-bold  align-middle mr-3 mrgtop">Repeat every</label>
      <select class="drpdown" name="repeatnumber" [(ngModel)]="dtoScheduleModality.quantity" #quantity="ngModel"
      required>
      <option [value]=1>1</option>
      <option [value]=2>2</option>
      <option [value]=3>3</option>
      <option [value]=4>4</option>
     </select>
      <div class="repeatdrpdown">
        <select class="drpdown" name="repeatWeek" [(ngModel)]="dtoScheduleModality.repeatWeek" #repeatWeek="ngModel"
        required>
        <option [value]=2>Week</option>       
      </select>
      </div>
       
    
    </div>
    <div class="col-md-12 mt-3  mb-2" *ngIf="dtoScheduleModality.frequency == 5">
      <div class="row">
        <div class="col check-custom large-font">
            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isSunday" #sunday="ngModel"
                name="sunday" (change)="onWeekDaySelection($event.checked,0)">Sunday
            </mat-checkbox>
        </div>
        <div class="col  check-custom large-font">
            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isMonday" #monday="ngModel"
                name="monday" (change)="onWeekDaySelection($event.checked,1)" >Monday
            </mat-checkbox>
        </div>
        <div class="col  check-custom large-font">
            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isTuesday"
                #tuesday="ngModel" name="tuesday" (change)="onWeekDaySelection($event.checked,2)">
                Tuesday</mat-checkbox>
        </div>
        <div class="col check-custom large-font">
            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isWednesday"
                #wednesday="ngModel" name="wednesday" (change)="onWeekDaySelection($event.checked,3)">
                Wednesday</mat-checkbox>
        </div>
        <div class="col  check-custom large-font">
            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isThursday"
                #thursday="ngModel" name="thursday" (change)="onWeekDaySelection($event.checked,4)">Thursday</mat-checkbox>
        </div>
        <div class="col  check-custom large-font">
            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isFriday" #friday="ngModel"
                name="friday" (change)="onWeekDaySelection($event.checked,5)">Friday</mat-checkbox>
        </div>
        <div class="col check-custom large-font">
            <mat-checkbox class="example-margin" [(ngModel)]="dtoScheduleModality.isSaturday"
                #saturday="ngModel" name="saturday" (change)="onWeekDaySelection($event.checked,6)">Saturday</mat-checkbox>
        </div>
    </div>
    <mat-error *ngIf="scheduleModalityRecurence.submitted && !isWeekDaySelected">
      <mat-error class="mat-form-field-subscript-wrapper mt-3"
          > Please select at least one day</mat-error>
    </mat-error>
       

    </div>
    <div class="col-md-12 " *ngIf="dtoScheduleModality.frequency == 6">
        <div class="row">
            <div class="col-md-2 mt-2">
            <label class="control-label-new align-middle  font-weight-bold mt-2">Repeat every</label>
            </div>
                    <div class="col-md-3 pl-0">
                        <mat-form-field class="w-100">

                            <mat-select name="weekOfMonth" [(ngModel)]="dtoScheduleModality.weekOfMonth" #weekOfMonth="ngModel"
                                required>
                                <mat-option [value]=1>First</mat-option>
                                <mat-option [value]=2>Second</mat-option>
                                <mat-option [value]=3>Third</mat-option>
                                <mat-option [value]=4>Fourth</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <mat-error *ngIf="appointmentinfo.submitted && weekOfMonth.invalid">
                            <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                *ngIf="weekOfMonth.errors.required">Repeat every is required
                            </mat-error>
                        </mat-error> -->
                        </div>
                        <div class="col-md-3 pl-0">
                        <mat-form-field class="w-100">

                            <mat-select name="weekDaysOnMonth" [(ngModel)]="dtoScheduleModality.weekDaysOnMonth" #weekDaysOnMonth="ngModel"
                                required>
                                <mat-option [value]=0>Sunday</mat-option>
                                <mat-option [value]=1>Monday</mat-option>
                                <mat-option [value]=2>Tuesday</mat-option>
                                <mat-option [value]=3>Wednesday</mat-option>
                                <mat-option [value]=4>Thursday</mat-option>
                                <mat-option [value]=5>Friday</mat-option>
                                <mat-option [value]=6>Saturday</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <mat-error *ngIf="appointmentinfo.submitted && weekDaysOnMonth.invalid">
                            <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                *ngIf="weekDaysOnMonth.errors.required">Repeat every is required
                            </mat-error>
                        </mat-error> -->
                    </div>
                </div>


    </div>
</div>
  <div class="text-right col-md-12 mt-2">
    <button type="submit" mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2"  >Schedule</button>
    <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="OnCancelReschedule()" >Cancel</button>
  </div>


</form>
</div>


</div>
</div>
</div>
</div>
</div> 
<div class="comment-popup re-schedule-popup anyclick-popup">
  <div class="modal" tabindex="-1" role="dialog"  [style.display]="isConflictpopup ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container " role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <div *ngIf="!isBlockTime"><h6 class="modal-title"> Schedule Recurring Appointment List</h6></div>
          <div  *ngIf="isBlockTime"> <h6 class="modal-title"> Schedule Recurring Block Time List</h6></div>
         
          <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OnCancelconflictpopup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
<div >
  <div class="col-md-12 p-0" *ngIf="scheduleModalityList?.length>0 " >
    <div class="col-md-12 p-0 table-container">
      <div class="table-wrapper">
         <div class="table-responsive">
            <table class="table modality-table">
               <thead>
                  <tr>
                     <th scope="col">#</th>
                     <th scope="col">Schedule Date</th>
                     <th scope="col">Start Time</th>
                     <th scope="col">End Time</th>
                     <th scope="col"></th>
                  </tr>
               </thead>
               <tbody>
                  <ng-container *ngFor="let item of scheduleModalityList; let i = index">
                     <tr [ngClass]="item.isConflict == true ? 'conflict-cell' : ''">
                        <td>
                           <input type="checkbox" name="isChecked" 
                                  [checked]="item.isChecked == true ? true : false" 
                                  value="item.isChecked"  (change)="updateItem(item, $event)">
                        </td>
                        <td>{{item.scheduleDate}}</td>
                        <td>{{item.fromTime}}</td>
                        <td>{{item.toTime}}</td>
                        <td>
                           <button mat-icon-button matTooltip="Delete" role="button" *ngIf="!isViewModality"
                                   (click)="ondeleteSchdule(item)" color="warn">
                              <img src="../../../assets/images/deletered.png">
                           </button>
                        </td>
                     </tr>
                  </ng-container>
               </tbody>
            </table>
         </div>
      </div>
   </div>
   
   
    <div class="col-md-12 text-right mt-2" >
       <button class="btn primary-btn-filled-small float-right ml-2" type="button"
          (click)="postSchduleModalityRecurrence()" *ngIf="!isBlockTime">Create Therapy</button>

          
  <button class="btn primary-btn-filled-small float-right ml-2 mr-2" type="button"
  (click)="postSchduleModalityRecurrenceBlock()" *ngIf="isBlockTime">Create Block</button>

       <button class="btn primary-btn-filled-small float-right" type="button"
          (click)="skipAllConflictsforRecurrence()"><i class="fas fa-forward mr-2"></i> Skip All
          Conflicts</button>
       <button class="btn primary-btn-filled-small float-right mr-2" type="button"
          (click)="skipConflicts()"><i class="fas fa-forward mr-2"></i> Skip</button>
    </div>
 </div>
</div>
        </div>
      </div>
    </div>
  </div>
</div>


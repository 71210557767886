export class DocumentPdf {
    isIntakePacket:boolean;
    isPainAssesment:boolean;
    isNutritionalQuestion:boolean;
    isChma:boolean;
    toEmail:any;
    patientAccountId:number;
    documentType:any;
}
export class ShareFiles{
    email:any;
}

export class ShareDocuments{
    PatientAccountId:number;
    ToEmail:any;
    FilePath:any;
    Base64File:any;
    FileName:any;
    DocumentType:any;
    }
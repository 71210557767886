export const constantVariables: any = {
    SuccessMessage: {
        DataSaveSuccessfully: 'Data saved successfully!',
        Deactivatedsuccessfully: 'Deactivated successfully!',
        Activatedsuccessfully: 'Activated successfully!',
        Norecordsfound: 'No records found',
        FilesUploadedSuccessfully: 'Document(s) uploaded successfully',
        FileDeletedSuccessfully: 'Document deleted successfully',
        ImageuploadedSuccessfully: 'Image uploaded successfully',
        ImageDeletedSuccessfully: 'Image deleted successfully',
        Passwordupdatedsuccessfully: 'Password updated successfully',
        DeletetedSuccesscully: 'Deleted successfully',
        Dataupdatedsuccessfully: 'Data updated successfully',
        DeleteConfirmMessage: 'Are you sure you want to delete this?',
        CompleteConfirmMessage: 'Are you sure you want to mark as completed this task?',
        passwordChangeSuccess: 'Your password has been changed successfully',
        mailsendsuccess: 'Message Sent',
        onverified: 'Initial Client Packet Verified Successfully',
        onreuturnpacket: 'The packet has been returned',
        // parentadd: 'The parent  has been added successfully',
        parentadd: 'Contact info has been added successfully',
        phbookImport: 'Phone book has been Imported Successfully',
        esignUpate: 'E-signature has been updated Successfully',
        useradd: 'Staff information has been added Successfully',
        useredit: 'Staff information has been updated Successfully',
        goaldelete: 'Goal has been Removed Successfully',
        // parentUpdate: 'The parent has been updated successfully',
        parentUpdate: 'Contact info has been updated successfully',
        parenDelete: 'Contact info has been deleted successfully',
        FailedToReschedule: ' has a session on selected ',
        ScheduleIntakeDirectly: 'Do you want to schedule Intake appointment directly?',
        RemoveTreatmentGoal: 'Benchmark removed successfully',
        BenchmarkSelectError: 'Please select one benchmark',
        EmailUpdatedSucess: 'Email updated successfully',
        AccessPermissionError: 'You do not have permission to access',
        BlockTimeSuccess: 'Block time saved successfully',
        EditTimeSuccess: 'Block time updated successfully',
        DeleteTimeSuccess: 'Block time deleted successfully',
        UpdateMessage: 'Color Code Updated successfully',
        SaveProfileConfirmation: 'Do you want to save the provided profile details?',
        SaveInsuranceConfirmation: 'Do you want to save the provided insurance details?',
        SubjectContent: " New message from SFCC",
        ProgressNoteEdit: 'Are you sure you want to edit this progress note?',
        DeleteDiagnosisCode:'Diagnosis code deleted successfully',
        PresentingProblemTabChange :'Are you sure you want to move the presenting problems/goals to another tab?',
        LeadReturn : 'Do you want to return the client to the treatment process? ',
        MTPSucess : "MTP created successfully",
        MTPUpdate : "MTP date updated successfully",
        SaveasDraftMessage : "Do you want to save details in draft?",
        DeletetedNotesSuccesscully: 'Phone notes deleted successfully',
        ReleaseNote : 'Are you sure you want to release this?',
        RejoinMessage : 'Hey! Kindly verify your Reoccurrence process. Once you submit, then you could not be able to edit it',
    },

    ErrorMessage: {
        ErrorSave: 'Error occured during save!',
        RequiredFieldValidation: 'This field is required ',
        NameExist: 'Entered name already exists',
        Errorwhilegettingdata: 'Error while getting data!',
        DocumentNameMustEnter: 'Document Name cannot be empty',
        MustSelectFile: 'Please select atleast one document',
        MustSelectDocument: 'Please select the document',
        PasswordandConfirmPasswordshouldbesame: 'Password and ConfirmPassword should be same',
        Incorrectusernameorpassword: 'Incorrect username or password.',
        Authenticationfailed: 'Authentication failed.',
        dobvalidation: 'DOB must be 3 to 18 years old',
        parentinfo: 'Please add parent information',
        primaryparent: 'Please add primary contact',
        tomail: 'Please add email address',
        insuranceUpload: 'Please upload the insurance card front and back',
        insurancePolicyUpload: 'Do you want to upload without Policy document',
        replycontent: 'please add your comments',
        primaryInsurance: 'Please add primary insurance details',
        filesize: 'Please upload a file less than 2 MB',
        UploadinfoError: 'Please add a file',
        importphonebook: "Phone Book Import Successfully",
        endate: "End date should be grater then start date.",
        usertag: "Please tag the user",
        priceComponent: "Please add price component",
        financialreview: "Please select intake and session fee",
        financialdeductible: "Please select deductible type",
        template: "Error occurred in the Template, Please Contact our Front Desk",
        signtaurevalidation: "Signature field is required",
        ERROR_INTERNET: 'Service terminated or interrupted. Please reload the page.',
        Option2: 'Option 2 is required',
        slotbooked: 'Slot has been already booked for ',
        DeleteError: 'Deleted blocktime successfully',
        LocationClinician: 'This clinician is not associated with this location',
        SubGroupError: "Please fill the sub group",
        NutritionalError: "Nutritional form is not available for the patient's location",
        PrimaryContactDelete : "Primary Contact cannot be deleted",
        GroupMeetingTimeChange : "The Clinician already associated with this group therapy",
        ImageSizeExceeded : "Please upload image below 1MB",
        MandatoryFieldError : "Please fill mandatory fields",
        Insurancedetails:"Please fill authorization details",
        NoAccess:"You are not authorized to access this page",
        DiagnosisError:"This diagnosis code is already associated with the treatment plan diagnosis",
        PresentingProblemError : "Must have one record in the tab",
        ActivationMailSend : "The activation link was sent to the changed primary email id. Please activate the account to login",
        SessionError : "Error occured. Please contact admin",
        PrimaryInsuranceDelete : "Primary insurance should not be deleted",
        dobvalidationremove: 'Invalid age'

       
    },
    Warning: {
        ConfictConfirmMessage: 'Are you sure would like to cancel the existing appointment?',
        DeleteImageMessage: 'Are you sure want to delete image?',
        SkipInsurance: 'Would you like to skip insurance?',
        removeConfirmMessage: 'Are you sure you want to remove this?',
    },
    DocumentTypeId: {
        PhoneBook: 2,
        PhoneBookImport: 3,
        Notes: 4,
        Library: 5,
        Question: 6,
        Task: 7,
        TreatmentSummary: 14,
    },
    Insurance:
    {
        Primary : "Primary",
        Secondary : "Secondary",
        Teritary : "Teritary"
    },
    confirmation: {
        onCompleteLeadProfile: 'On submission data entered cannot be changed, would you like to proceed?',
        onTemplateContentClear: 'Template content will be lost when you change the template category',
        DeleteConfirmMessage: 'Are you sure you want to delete this?',
        ChangePrimaryMessage: 'Do you want change the primary contact, you can login in portal by using primary parentS email?',
        ChangePrimaryEmailAddress: 'By setting this contact as your Primary contact, the email address linked to this contact will become your login username. Confirming "Yes" will trigger the sending of an activation email to this email "{{email}}".',
        ChangePrimaryEmailAddressAdmin: 'By marking this contact as Primary contact, the email address of this contact will serve as your login email address.By clicking on yes, an activation email will be send to this email address.'
    },
    enumpagelists: {
        USERS: 1,
        ROLES: 2,
        RolePermission: 3,
        Task: 3,
    },
    pagelists: {
        NoPage:0,
        Lead: 1,
        PatientAccount: 2,
        Tour: 3,
        ScheduleIntake: 4,
        GenerateIntake: 5,
        TreatmentPlan: 6,
        ScheduleAppointmentsTherapies: 7,
        ProgressNoteFivepointRating: 8,
        TourScheduler: 9,
        ScheduleIntakeScheduler: 10,
        Task: 11,
        Goals: 12,
        Templates: 13,
        Settings: 14,
        StaffManagement: 15,
        IncidentReporting: 16,
        DischargeSummary: 17,
        OutofpocketInvoice: 18,
        CreateBill: 19,
        InsuranClaims: 20,
        BillingMasters: 21,
        Briefcase: 22,
        Profile: 23,
        Notes: 24,
        PaymentDetails: 25,
        PainAssessment: 26,
        NutritionalQuestion: 27,
        PrepareCHMA: 28,
        SuicidalRiskAssessment: 29,
        TreatmentHistory: 30,
        Documents: 31,
        HorizondalMenu: 32,
        Dashboard: 34,
        Authorization: 39,
        Audit :40,
        ClientDocuments :41
    },
    PageName :{
        Audit:'Audit',
    },
    lblMessage:
    {
        DownloadTemplate: 'Download Template',
        TemplateDownloaded: 'Template Downloaded',
        Selected: "Selected",
        Selected1: "Selected1",
        Exist: "Exist",
        TreatmentPlan : "Treatment Plan -1"
    },
    stages: {
        Lead: 1,
        PointofContact: 2
    },
    role: {
        Admin: 1,
        FrontDesk: 2,
        Lead: 4,
        PrimaryClinician: 5,
        SupportClinician: 6,
        Interns: 7,
        AdminandClinician : 7
    },
   roleType:{
    Intern : 6
   },
    therapyView:{
        Month : 1,
        Week : 2
    },
    moduleName : {
        CMHA:'CMHA',
        PainAssessment :'Pain Assessment',
        NutritionalQuestionarie :'Nutritional Questionarie',
        SuicidalRiskAssessment : 'Suicidal Risk Assessment',
        TreatmentPlan :'Master Treatment Plan'
    },

    therapy: {
        IndividualTheraphy: 1,
        FamilyTheraphy: 2,
        GroupTheraphy: 3,
        Tour: 4,
        Intake: 5,
        PsycatricTheraphy: 6,
        PsycatricEvaluation: 8,
        PsycatricMedication: 9,
        Phonecall: 11,
        School: 10,
        Speech: 12,
        Nutrition: 13,
        IntakeTherapy: 100,
        PhonecallSceduler:15,
        SchoolmeetingScheduler: 16,
        BlockTimeScheduler:17,
    },
    calenderType: {
        Task: 1,
        BreifCase: 2,
    },
    status: {
        PointofContact: 1,
        Benefits: 2,
        ResendActivationMail: 3,
        UploadInsurance: 4,
        ReviewPacket: 5,
        Return: 6,
        Verified: 7,
        submitted: 8,
        CrbaUpdate: 9,
        open: 10,
        close: 11,
        fee: 12,
        financial: 13,
        Accept: 14,
        Decline: 15,
        forIntake: 16,
        requestTour: 17,
        reviewTour: 18,
        staffAssigned: 19,
        tourCompleted: 20,
        meetingScheduled: 21,
        delete: 22,
        intakeScheduled: 23,
        intakeDeclined: 24,
        intakeAccepted: 25,
        intakePacket: 26,
        inProgress: 27,
        meetingReSchedule: 28,
        timeOver: 29,
        completed: 30,
        requestcancel: 31,
        scheduleModality: 32,
        booked: 33,
        psytherapyReschedule: 34,
        tourDeleted: 35
    },
    notificationType: {
        requestTour: 1,

    }, therapyName: {
        tour: "Tour"
    },
    DocumentType: {
        IndividualTherapy: "Individual Therapy",
        FamilyTherapy: "Family Therapy",
        PsycatricTherapy: "Psycatric Therapy",
        PsycatricEvaluation_MentalStates: "Psycatric Evaluation_MentalStates",
        PsycatricMedication: "Psycatric Medication",
        PhoneCallMeeting: "Phone Call Meeting",
        SchoolMeeting: "School Meeting",
        SpeechTherapy: "Speech Therapy",
        NutritionTherapy: "Nutrition Therapy",
        GroupTheraphy: "Nutrition Therapy",

    },
    DocumentTab: {
        Intake: "Intake",
        TreatmentPlan: "TreatmentPlan",
        Appointment: "Appointment",
        SusidalRisk: "SusidalRisk",
        OnBoarding: "OnBoarding",
        Financial: "Financial",
        Communication: "Communication",
        Uploaded: "Uploaded",
        Consent : "Consent",
        PainAssessment : "PainAssesment"
    },
    DocumentTypeForShare: {
        Intake: "Intake document",
        TreatmentPlan: "Treatment Plan document",
        Appointment: "Appointment document",
        SusidalRisk: "Suicidal Risk document",
        OnBoarding: "OnBoarding document",
        Financial: "Financial document",
        Communication: "Communication document",
        Document: "Document",
        ConsentForm : "ConsentForm",
        PainAssesmentForm : "PainAssesmentForm"


    },
    taskStatus: {
        Today: "Today",
        Due: "Due in ",
        OverDue: "Over Due",
        Completed: "Completed"
    },

    formType: {
        Survey: 1,
        Checklist: 2,
        Nutritional: 3

    },

    questionType: {
        YesNo: 1,
        MCSA: 2,
        MCMA: 3,
        Descriptive: 4,
        Ratings: 5,
        Text: 6

    },
    painImages: {
        image1: '<span  class="badge badge-pill badge-danger mr-3" style="background: #a3d17a;padding-right: .6em;padding-left: .6em;border-radius: 10rem;' +
            'display: inline-block;padding: .25em .4em;font-size: 75%;font-weight: 700;line-height: 1;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 10rem;">0</span>',
        image2: '<span  class="badge badge-pill badge-danger mr-3" style="background: #a3d17a;padding-right: .6em;padding-left: .6em;border-radius: 10rem;' +
            'display: inline-block;padding: .25em .4em;font-size: 75%;font-weight: 700;line-height: 1;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 10rem;">0</span>',
        image3: '<span  class="badge badge-pill badge-danger mr-3" style="background: #a3d17a;padding-right: .6em;padding-left: .6em;border-radius: 10rem;' +
            'display: inline-block;padding: .25em .4em;font-size: 75%;font-weight: 700;line-height: 1;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 10rem;">0</span>',
        image4: '<span  class="badge badge-pill badge-danger mr-3" style="background: #a3d17a;padding-right: .6em;padding-left: .6em;border-radius: 10rem;' +
            'display: inline-block;padding: .25em .4em;font-size: 75%;font-weight: 700;line-height: 1;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 10rem;">0</span>',
        image5: '<span  class="badge badge-pill badge-danger mr-3" style="background: #a3d17a;padding-right: .6em;padding-left: .6em;border-radius: 10rem;' +
            'display: inline-block;padding: .25em .4em;font-size: 75%;font-weight: 700;line-height: 1;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 10rem;">0</span>',
        image6: '<span  class="badge badge-pill badge-danger mr-3" style="background: #a3d17a;padding-right: .6em;padding-left: .6em;border-radius: 10rem;' +
            'display: inline-block;padding: .25em .4em;font-size: 75%;font-weight: 700;line-height: 1;text-align: center;white-space: nowrap;vertical-align: baseline;border-radius: 10rem;">0</span>',
    },
    questionExplanation: {
        isquestion: 1,
        isoption: 2,
        No: 3,
    },
    inTakeForms: {
        HealthScreening: 1,
        Checklist: 2,
        Survey: 3,
        ConsentForm: 4,
        Nutritional: 5,
    },
    ConfigureFormType : {
        NoForm : 0,
        CheckList : 4
    },
    MeasureTypeName: {
        Aggregatedpercentagescore: 2,
        KPIprogresslinked: 3,
        Qualitative: 4,
        Percentagebased: 5,
        Timebased: 6
    },

    PatientAppointmentTypes: {
        Tour: 0,
        Intake: 1,
        IndividualTherapy: 2,
        FamilyTherapy: 3,
        GroupTherapy: 4
    },
    masterMenu: [{ label: "Source", value: 1 }, { label: "Lead Type", value: 2 }, { label: "Facility", value: 3 }, { label: "Lead Stage", value: 4 }, { label: "Grade", value: 5 }],
    gender: [{ label: "Male", value: 0 }, { label: "Female", value: 1 }],
    gradelevel: [{ label: "KG", value: '0', IsActive: true }, { label: "Grade1", value: '1', IsActive: true }, { label: "Grade2", value: '2', IsActive: true }, { label: "Grade3", value: '3', IsActive: true }, { label: "Grade4", value: '4', IsActive: true }, { label: "Grade5", value: '5', IsActive: true },
    { label: "Grade6", value: '6', IsActive: true }, { label: "Grade7", value: '7', IsActive: true }, { label: "Grade8", value: '8', IsActive: true }, { label: "Grade9", value: '9', IsActive: true }, { label: "Grade10", value: '10', IsActive: true }
        , { label: "Grade11", value: '11', IsActive: true }, { label: "Grade12", value: '12', IsActive: true }],
    maritalStatus: [{ label: "Single", value: '0', IsActive: true }, { label: "Married", value: '1', IsActive: true }, { label: "Separated", value: '2', IsActive: true }, { label: "Divorced", value: '3', IsActive: true }, { label: "Widowed", value: '4', IsActive: true }],
    NotificationTitle: {
        onResubmitTreatment: "Successfully resubmitted by {{username}} of the additional info asked",
        onsubmitTreatment: "Successfully submitted by {{username}} of the additional info asked",
        onVerified: "Hi {{username}}, Thanks for updating your additional data submitted which has been verified successfully.",
        onReturned: "Hi {{username}}, Kindly requesting to visit the Profile/Insurance/History page again. Please check for the subject line of the mail sent for the required task"
    },
    priority: [{ label: "High", value: 1, IsActive: true }, { label: "Medium", value: 2, IsActive: true }, { label: "Low", value: 3, IsActive: true }],
    diagnosiscode: [
        // { label: "F90.2 - Attention-deficit hyperactivity disorder, combined type",value: 'F90.2', isActive: false }, 
    //  { label: "F41.9 - Anxiety disorder, unspecified", value: "F41.9", isActive: false }, 
    //  { label: "F84.0 - Autistic disorder", value: "F84.0", isActive: false },
     { label: "F31.9 - Bipolar affective disorder, unspecified", value: "F31.9", isActive: false },
     { label: "F32.0 - Mild depressive episode", value: "F32.0", isActive: false },
     { label: "F32.9 - Depressive episode", value: "F32.9", isActive: false },
     { label: "F33.0 - Recurrent depressive disorder, current episode mild", value: "F33.0", isActive: false },
     { label: "F33.1 - Recurrent depressive disorder, current episode moderate", value: "F33.1", isActive: false },
     { label: "F33.2 - Recurrent depressive disorder, current episode severe without psychotic symptoms", value: "F33.2", isActive: false },
     { label: "F34.81 - Disruptive mood dysregulation disorder", value: "F34.81", isActive: false },
     { label: "F40.10 - Social anxiety disorder", value: "F40.10", isActive: false },
     { label: "F41.1 - Generalized anxiety disorder", value: "F41.1", isActive: false },
     { label: "F41.9 - Unspecified anxiety disorder", value: "F41.9", isActive: false },
     { label: "F42.9 - Obsessive-compulsive disorder", value: "F42.9", isActive: false },
     { label: "F43.1 - Post-traumatic stress disorder", value: "F43.1", isActive: false },
     { label: "F43.2 - Adjustment disorder", value: "F43.2", isActive: false },
     { label: "R45.89 - Other somatoform disorders", value: "R45.89", isActive: false },
     { label: "F60.5 - Obsessive-compulsive personality disorder", value: "F60.5", isActive: false },
     { label: "F63.81 - Intermittent explosive disorder", value: "F63.81 ", isActive: false },
     { label: "F80.2 - Receptive language disorder", value: "F80.2", isActive: false },
     { label: "F84.0 - Autism spectrum disorder", value: "F84.0", isActive: false },
     { label: "F90.0 - Disturbance of activity and attention", value: "F90.0", isActive: false },
     { label: "F90.1 - Hyperkinetic conduct disorder", value: "F90.1", isActive: false },
     { label: "F90.2 - Attention-deficit hyperactivity disorder", value: "F90.2", isActive: false },
     { label: "F90.9 - Hyperkinetic disorder, unspecified", value: "F90.9", isActive: false },
     { label: "F91.3 - Oppositional defiant disorder", value: "F91.3", isActive: false },
     { label: "F91.9 - Conduct disorder, unspecified", value: "F91.9", isActive: false },
     { label: "F93.0 - Separation anxiety disorder of childhood", value: "F93.0", isActive: false },
     { label: "F94.0 - Selective mutism", value: "F94.0", isActive: false }],
     billingForm: [{ label: "CPT Code", value: 'CPTCODE', isActive: true },
     { label: "UB04", value: 'UB04', isActive: true }],
    billStatus: [{ label: "New", value: 'New', isActive: true },
     { label: "Inprogress", value: 'Inprogress', isActive: true },
    { label: "Completed", value: 'Completed', isActive: true }],
    YesNo: [{ label: "Yes", value: true, IsActive: true }, { label: "No", value: false, IsActive: true }],
    Ratings: [{ label: 1, value: 1, IsActive: true }, { label: 2, value: 2, IsActive: true }, { label: 3, value: 3, IsActive: true }, { label: 4, value: 4, IsActive: true },
    { label: 5, value: 5, IsActive: true }, { label: 6, value: 6, IsActive: true }, { label: 7, value: 7, IsActive: true }, { label: 8, value: 8, IsActive: true },
    { label: 9, value: 9, IsActive: true }, { label: 10, value: 10, IsActive: true }],
    Average: [{ label: "Above Average", value: 1, IsActive: true }, { label: "Average", value: 2, IsActive: true }, { label: "Below Average", value: 3, IsActive: true }],
    Goodbehavior: [{ label: "Good", value: 1, IsActive: true }, { label: "Fair", value: 2, IsActive: true }, { label: "Poor", value: 3, IsActive: true }],
    OrientationTime: [{ label: "Morning", value: 1, IsActive: true }, { label: "Afternoon", value: 2, IsActive: true }, { label: "Evening", value: 3, IsActive: true }],
    AuditDate: [{ label: "N/A", value: 0 },{ label: "1 day", value: 1 }, { label: "2 days", value: 2 }, { label: "3 days", value: 3 }, { label: "4 days", value: 4 }, { label: "5 days", value: 5 },{ label: "6 days", value: 6 }, { label: "7 days", value: 7 }, { label: "8 days", value: 8 }, { label: "9 days", value: 9 }, { label: "10 days", value: 10 }],

    NotificationTypeid: {
        Email: 1,
        AssignTour: 2,
        RequestReview: 3,
        ReturnProfile: 4,
        ProfileVerified: 5,
        SubmitProposal: 6,
        AcceptProposal: 7,
        DenyProposal: 8,
        ScheduleIntake: 9,
        RequestTour: 10,
        RescheduleIntakeRequest: 11,
        AcceptReschedule: 12,
        DenyReschedule: 13,
        CompleteTreatmentplan: 14,
        ScheduleAppointments: 15,
        PsyTherapyFollowup: 16,
        GenerateIntakepdf: 17,
        UpdateBenifits: 18,
        RequestTourAssignStaff: 19,
        CancelTour: 20,
        MarkTourCompleted: 21,
        RescheduleTourRequest: 22,
        ChangeStaffTour: 23,
        AcceptIntake: 24,
        AuthSetting: 25,
        AuthNotificationIsManager: 26,
        PainAssesmentPdf: 27,
        Audit:28
    },
    AgreeDisAgree: [{ label: "Agree", value: true, IsActive: true }, { label: "Disagree", value: false, IsActive: true }],

    SuicidalAssessmentCIMaster: {
        ActivatingEvents: 1,
        TreatmentHistory: 2,
        ClinicalStatus: 3,
        Internal: 4,
        External: 5,
        Behaviors: 6,
        HighRisk: 7,
        HighRiskTRIAGE: 8,
        ModerateRisk: 9,
        ModerateRiskTRIAGE: 10,
        LowRisk: 11,
        LowRiskTRIAGE: 12,
        RiskLevel: 13,
        Frequency: 14,
        Duration: 15,
        Controllability: 16,
        Deterrents: 17,
        ReasonsforIdeation: 18
    },

    SuicidalClinicalNotesMaster: {
        AllParent: 1,
        BriefEvaluationSummary: 2
    },
    ShortTermGoalTypes: {
        ParicipateTask: 1,
        ListeningDirections: 2,
        ManageFrustation: 3,
        AcceptNo: 4
    },
    PhysicianTypesForProviderContact: [{ label: "Physician", value: 1, isActive: true }, { label: "Psychiatrist", value: 2, isActive: true },
    { label: "Therapist", value: 3, isActive: true }, { label: "Neurologist", value: 4, isActive: true }, { label: "Teacher", value: 5, isActive: true }],
    MonthList: [{ label: "January", value: 1, isActive: true },
    { label: "February", value: 2, isActive: true },
    { label: "March", value: 3, isActive: true },
    { label: "April", value: 4, isActive: true },
    { label: "May", value: 5, isActive: true },
    { label: "June", value: 6, isActive: true },
    { label: "July", value: 7, isActive: true },
    { label: "August", value: 8, isActive: true },
    { label: "September", value: 9, isActive: true },
    { label: "October", value: 10, isActive: true },
    { label: "November", value: 11, isActive: true },
    { label: "December", value: 12, isActive: true }],


    PagePermissionMsg: {
        IsView: 'You do not have privilege to access this. Please contact admin'
    },


    // Appconfiguration:
    //                 {
    //                     //  Esign : 'DefaultEsignImage'
    //                      Esign : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHoAAABlCAYAAACC2feqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAADuSURBVHhe7dEBAQAACMMg+5e+QRgVuIVQNKJoRNGIohFFI4pGFI0oGlE0omhE0YiiEUUjikYUjSgaUTSiaETRiKIRRSOKRhSNKBpRNKJoRNGIohFFI4pGFI0oGlE0omhE0YiiEUUjikYUjSgaUTSiaETRiKIRRSOKRhSNKBpRNKJoRNGIohFFI4pGFI0oGlE0omhE0YiiEUUjikYUjSgaUTSiaETRiKIRRSOKRhSNKBpRNKJoRNGIohFFI4pGFI0oGlE0omhE0YiiEUUjikYUjSgaUTSiaETRiKIRRSOKRhSNKBpRNKJoRNGIognbA8sa0qq/iWftAAAAAElFTkSuQmCC',
    //                      Esign1: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHoAAABlCAYAAACC2feqAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAFiUAABYlAUlSJPAAAADuSURBVHhe7dEBAQAACMMg+5e+QRgVuIVQNKJoRNGIohFFI4pGFI0oGlE0omhE0YiiEUUjikYUjSgaUTSiaETRiKIRRSOKRhSNKBpRNKJoRNGIohFFI4pGFI0oGlE0omhE0YiiEUUjikYUjSgaUTSiaETRiKIRRSOKRhSNKBpRNKJoRNGIohFFI4pGFI0oGlE0omhE0YiiEUUjikYUjSgaUTSiaETRiKIRRSOKRhSNKBpRNKJoRNGIohFFI4pGFI0oGlE0omhE0YiiEUUjikYUjSgaUTSiaETRiKIRRSOKRhSNKBpRNKJoRNGIognbA8sa0qq/iWftAAAAAElFTkSuQmCC'

    //                 }


}

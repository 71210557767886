<div class=" text-center">
  <!-- <div class="col-md-12 p-0 mb-1">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
  </div> -->
  <div class="col-md-12 mb-1">
    <div class="row">
      <div class="col-md-12" *ngIf="Type==1">
        <div class="btn-group selection-btns">
          <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
            Day
          </div>
          <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
            Week
          </div>
          <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month">
            Month
          </div>


        </div>
      </div>



    </div>
  </div>



</div>
<div class="col-md-12 p-0">
  <div class="btn-group w-100 not-selection-view">
    <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
      (viewDateChange)="closeOpenMonthViewDay()">
      <i class="fas fa-chevron-left"></i>
    </div>
    <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
      {{viewDate | calendarDate:(view + 'ViewTitle'):'en'}}
    </div>
    <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
      (viewDateChange)="closeOpenMonthViewDay()">
      <i class="fas fa-chevron-right"></i>
    </div>
  </div>

  <!-- <ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>


    <a (click)="onOpentaskmodal(day.date)"> <span class="cal-day-number">{{ day.date |
        calendarDate:'monthViewDayNumber':locale }}</span></a>
  </div>
  <div class="cal-events" *ngIf="day.events.length > 0">
    <div class="cal-event" *ngFor="let event of day.events; trackBy: trackByEventId"
      [ngStyle]="{ backgroundColor: event.color }" [ngClass]="event?.cssClass" [mwlCalendarTooltip]=" event.title | calendarEventTitle: 'monthTooltip':event" [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event" [tooltipTemplate]="tooltipTemplate"
      [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay" mwlDraggable
      [class.cal-draggable]="event.draggable" dragActiveClass="cal-drag-active"
      [dropData]="{ event: event, draggedFrom: day }" [dragAxis]="{ x: event.draggable, y: event.draggable }"
      [validateDrag]="validateDrag" [touchStartLongPress]="{ delay: 300, delta: 30 }"
      (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
      [attr.aria-hidden]="{} | calendarA11y: 'hideMonthCellEvents'"></div>
  </div>
</ng-template> -->
  <!-- <ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top">
      <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
      <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    </div>
    
      
     
      <p class="desc">{{ day.event.title }}</p>
    </div>

  </ng-template> -->
  <div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
      [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
      [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-day-view>
  </div>
</div>
<div class="task-value-section">
  <div class="color-code"><span><i class="fas fa-circle"></i></span>Task</div>
  <div class="task-listed">
    <mat-expansion-panel hideToggle [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{eventsOnDate[0]?.start | date: 'MM/dd/yyyy'}}
        </mat-panel-title>
        <mat-panel-description>
          Task Available <span class="float-right"><i class="fas fa-chevron-down"></i></span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="task-content-mail small-task">
        <ul>
          <li *ngFor="let task of eventsOnDate;let i=index">
            <div class="col-md-12 p-0 mb-2">

              <div class="row">
                <div class="col task-content-small" innerHTML="{{global.getContent(task.title)}}"></div>


                <div class="col-md-3 p-0 text-right" *ngIf="task.status !=null || task.updateDate !=null">
                  <span>{{task.updateDate | date: 'MM/dd/yyyy'}}</span>
                  <span class="mr-2" *ngIf="task.status !=null">
                    <label class="task-status overdue" *ngIf="task.status=='Over Due'">{{task.status}}</label>
                    <label class="task-status today" *ngIf="task.status=='Today'">{{task.status}}</label>
                    <label class="task-status due"
                      *ngIf="(task.status!='Today') &&  (task.status!='Over Due')">{{task.status}}</label>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12 asigned p-0">
              <span class="tag-label" *ngIf="task.tags !=null">Assigned</span>
              <mat-chip-list aria-label="Fish selection">
                <ng-container *ngFor="let a of task.tags">
                  <mat-chip *ngIf="a.email !=''">{{a.email}}</mat-chip>
                </ng-container>

              </mat-chip-list>
            </div>

          </li>
          <span *ngIf="eventsOnDate?.length == 0 ">
            <span>No record Found</span>
          </span>
        </ul>
      </div>
    </mat-expansion-panel>
  </div>
</div>


<div class="modal " id="myModal" [style.display]="taskmodal ? 'block' : 'none'">
  <div class="modal-dialog mb-2">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Add Task</h6>
        <button aria-hidden="true" data-dismiss="modal" class="close" (click)="onClosetaskmodal()" type="button"><i
            class="fas fa-times"></i></button>

      </div>
      <div class="modal-body">
        <form (ngSubmit)="taskfrm.form.valid  && saveTaskInfo(taskfrm)" id="taskfrm" autocomplete="off"
          #taskfrm="ngForm" novalidate>

          <div class="col-md-12 p-0 edit-note">
            <div class="row">
              <div class="col-md-9">
                <label class="control-label-new">Enter Task</label>
                <div class="col-md-12 p-0">
                  <quill-editor [(ngModel)]="taskinfo.content" #content="ngModel" name="content" [modules]="quillConfig"
                    required>
                  </quill-editor>
                  <mat-error *ngIf="taskfrm.submitted && content.invalid">
                    <mat-error class="mat-form-field-subscript-wrapper" *ngIf="content.errors.required">
                      Content is required</mat-error>
                  </mat-error>
                </div>

              </div>
              <div class="col-md-3 mt-4">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="w-100">
                      <mat-label>Due date</mat-label>

                      <input matInput [matDatepicker]="duepicker" readonly [min]="currentdate"
                        (click)="duepicker.open()" [(ngModel)]="taskinfo.dueDate" #dueDate="ngModel" name="dueDate"
                        datePickerFormat="MM/DD/YYYY" [ngClass]="{ 'is-invalid': taskfrm.submitted && dueDate.invalid }"
                        required>
                      <mat-datepicker-toggle matSuffix [for]="duepicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #duepicker></mat-datepicker>
                      <mat-error *ngIf="taskfrm.submitted && dueDate.invalid">
                        <mat-error *ngIf="dueDate.errors.required">Due date is
                          required</mat-error>

                      </mat-error>

                    </mat-form-field>
                  </div>

                  <div class="col-md-12">
                    <mat-form-field class="w-100">
                      <mat-label>Remind me</mat-label>
                      <input matInput [matDatepicker]="flagpicker" readonly [min]="currentdate"
                        (click)="flagpicker.open()" [(ngModel)]="taskinfo.flagMeBy" #flagMeBy="ngModel" name="flagMeBy"
                        [ngClass]="{ 'is-invalid': taskfrm.submitted && flagMeBy.invalid }" required>
                      <mat-datepicker-toggle matSuffix [for]="flagpicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #flagpicker></mat-datepicker>
                      <mat-error *ngIf="taskfrm.submitted && flagMeBy.invalid">
                        <mat-error *ngIf="flagMeBy.errors.required">Remind me is
                          required</mat-error>
                      </mat-error>
                    </mat-form-field>
                    <mat-error class="mat-form-field-subscript-wrapper" *ngIf="!isValidFlagDate">
                      Remind me should be grater than due
                      date</mat-error>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
      <div class="modal-footer text-center">
        <button type="submit" title="{{!isEditTask ?
                    'Create Task':'Update Task'}}" form="taskfrm" mat-raised-button
          class="primary-btn-outlined mr-2">{{!isEditTask ?
          'Create Task':'Update Task'}}</button>
        <button type="button" (click)="onClosetaskmodal()" class="btn primary-btn-filled primary-btn-s "
          data-dismiss="modal">Discard</button>
      </div>
    </div>
  </div>
</div>
<div class="grid-view-section">
   <div class="top-list-notifiaction" >
      <div class="row">
         <div class="col-sm-9 col-lg-3 mb-3">
            <span class="hot-leads pr-2"><span><i class="fas fa-circle pr-1"></i></span>Hot Leads</span>
            <span class="warm-leads pr-2"><span><i class="fas fa-circle pr-1"></i></span>warm Leads</span>
            <span class="cold-leads pr-2"><span><i class="fas fa-circle pr-1"></i></span>cold Leads</span>
         </div>
         <div class="col-sm-9 col-lg-3 mb-3">
         </div>
        
         <div class="col-sm-9 col-lg-6 mb-3  text-right">
            <button  mat-stroked-button class=" btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2 dclnbtn" (click)="DeclainListView()"> <i class="far fa-list-alt mr-2"></i>Declined  List</button>
            <div class="toggle-switch float-right">
               <a [routerLink]="['/leadaccount']" *ngIf="roleAccess.isCreate" class="col text-left" title="Add New Lead"><span><i
                        class="fas fa-user-plus"></i></span></a>
               <!-- <a class="col text-center"><span><i class="fas fa-filter"></i></span></a>  -->
               <a [routerLink]="['/leadlist']" *ngIf="roleAccess.isView" class="col text-center active" title="List View"><span><i
                        class="fas fa-list"></i></span></a>
               <a [routerLink]="['/leadkanban']" *ngIf="roleAccess.isView" class="col text-right" title="Kanban View"><span><i
                        class="fas fa-th"></i></span></a>
            </div>
            <!-- <div class="page-nation float-right">

               <!- <ul class="pagination pagination-large">
                  <li class="page-head mr-3"> <span class="label-color">List Entries</span></li>
                  <li class="active"><a href="#">05</a></li>
                  <li><a href="#">10</a></li>
                  <li><a href="#">15</a></li>
                  <li><a href="#">20</a></li>

               </ul> -->


            <!--</div> -->

         </div>
         <div class="col-md-3 ">
            <div  class="search">
                <!-- <input  type="text" placeholder="Search By Client Name" name="" class="search-input " [(ngModel)]="searchpatientListtxt" (keyup)="OnSearchPatient()"><a  class="search-icon"><i  class="fa fa-search"></i></a></div> -->
                <input  type="text" placeholder="Search" name="" class="search-input " [(ngModel)]="searchpatientListtxt" (keyup)="OnSearchPatient()"><a  class="search-icon"><i  class="fa fa-search"></i></a>
               </div>
            </div>
      </div>
   </div>

   <div class="list-main-section" *ngIf="roleAccess.isView">
      <div class="col-md-12 p-0">
         <div class="row" *ngFor="let item of lstLead">
            <div class="col-md-12">
               <div class="card list-grid mb-3">
                  <div class="card-body hot ">
                     <div class="circle"><i class="fas fa-circle"></i></div>
                     <div class="row no-gutters align-items-top">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
                           <!-- <h5 class=" card-title text-uppercase mb-2 "><a>{{item.FirstName}} {{item.LastName}}</a> -->
                              <h5 class=" card-title text-uppercase mb-2 "><a>{{item.FirstName}} {{item.LastName}}</a>
                              <mat-icon class="mt-2 cursor-pointer info-icon" matTooltip="{{getMoreInformation(item)}}"
                                 matTooltipClass="leadinfo" matTooltipPosition="right">info</mat-icon>
                           </h5>

                           <div class="card-details">
                              <p class="label-small ">Gender | DOB </p>
                              <p>{{item.GenderType}} | {{item.Dob| date: 'MM/dd/yyyy'}}</p>
                           </div>
                           <p class="label-small mb-1 ">Lead Stage </p>

                           <h5 class=" card-title text-uppercase mb-2 benefits">{{item.LeadStage}}</h5>
                           <div class="card-details">
                              <p class="clearfix"><span class="label-small">Source </span>
                                 <!-- <span
                                     class="value-small">Paid Facebook Ad</span></p> -->
                                     <span
                                    class="value-small">{{item.SourceTypeName}}</span></p>
                              <p class="clearfix"><span class="label-small">Lead Type </span><span
                                    class="value-small">{{item.LeadTypeName}}</span></p>
                              <!-- <p class="clearfix"><span class="label-small">Location </span><span
                                    class="value-small">{{item.FacilityType}}</span></p>
                              <p class="clearfix"><span class="label-small">Address </span><span
                                    class="value-small">{{item.ParentList[0]?.City}}{{item.ParentList[0]?.StateName ==
                                    '' ? '' :','}} {{item.ParentList[0]?.StateName}}</span> </p> -->
                                    <p class="clearfix"><span class="label-small">Location </span><span
                                       class="value-small">{{item.FacilityTypeName}}</span></p>
                                    <p class="clearfix"><span class="label-small">Address </span><span
                                       class="value-small">{{item?.City}}{{item?.StateName ==
                                       '' ? '' :','}} {{item?.StateName}}</span> </p>
                           </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 col-12 mb-2">
                           <h5 class=" card-title text-uppercase mb-2 ">Contact information </h5>
                           <div class="card-details">
                              <!-- <p class="name-client">{{item.ParentList[0]?.FirstName}}</p>
                              <p>{{item.ParentList[0]?.ContactNo | mask: '(000) 000-0000'}}</p>
                              <p>{{item.ParentList[0]?.Email}}</p> -->
                              <p class="name-client">{{item?.ParentName}}</p>
                              <p>{{item?.ContactNo | mask: '(000) 000-0000'}}</p>
                              <p>{{item?.Email}}</p>
                           </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-6 mb-2">
                           <h5 class=" card-title text-uppercase mb-2 ">immediate action </h5>
                           <div class="card-details">
                              <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 resend-mail"
                                 *ngIf="item.ImmediateActionId == statusResendActivationMail && roleAccess.isCreate"
                                 title="Resend Activation Mail" (click)="onResendActivation(item)">RESEND ACTIVATION LINK</button>
                              <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                 (click)="onIntakeSchedule(item)"
                                 *ngIf="item.ImmediateActionId == statusintake && roleAccess.isCreate"
                                 title="For Intake Schedule">Schedule
                                 Intake</button>
                              <button class="btn primary-btn-filled primary-btn-l mb-2 h-40"
                                 *ngIf="item.ImmediateActionId == statusUploadInsurance" title="Upload Insurance" (click)="onPatientinfoRedirect(item.PatientInfoId,item)">Upload
                                 Insurance
                              </button>
                              <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 review"
                                 *ngIf="item.ImmediateActionId == statusReviewPacket && roleAccess.isApprove" title="Review Packet"
                                 (click)="onPatientinfo(item.PatientInfoId,item)">REVIEW CLIENT PACKET</button>
                              <!-- <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 returned" *ngIf="item.ImmediateActionId == statusReturn" title="Returned">Returned</button> -->
                              <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                 *ngIf="(item.ImmediateActionId == statusVerified && !item.IsSkipInsurance) && roleAccess.isEdit " title="CRBA"
                                 (click)="onUpdateCRBA(item.PatientInfoId)">Update Benefits</button>
                              <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                 *ngIf="((item.ImmediateActionId == statusCrbaUpdate ) || (item.ImmediateActionId == statusVerified && item.IsSkipInsurance)) && roleAccess.isCreate" title="Financial Proposal"
                                 (click)="onSubmitFinancial(item)">Submit Financial Proposal</button>
                              <!-- <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified" *ngIf="item.ImmediateActionId == statusFinancial" title="Financial Proposal"  >Completed Financial Proposal</button>  -->
                              <!-- <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                 *ngIf="item.ImmediateActionId == statusintake" title="For Intake Schedule">For Intake
                                 Schedule</button> -->
                              <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                 *ngIf="item.ImmediateActionId == statusreviewTour && (item.Status==statusRequestTour||item.Status==null) && roleAccess.isApprove" title="Request Tour"
                                 (click)="onRequestTour(item)">Review Tour Request</button>
                                 <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                 *ngIf="item.ImmediateActionId == statusreviewTour && item.Status==statusstaffAssigned && roleAccess.isEdit" title="Request Tour"
                                 (click)="onRequestTour(item)">Edit Tour</button>
                              <p *ngIf="item.ImmediateActionId == statusReviewPacket">
                                {{item.PatientStatus}} <br><span class="text-danger">{{item.UpdatedOn | date: 'MM/dd/yyyy'}}</span>
                              </p>
                              <p *ngIf="item.ImmediateActionId == statusintake">
                                {{item.PatientStatus}} <br><span class="font-weight-bold">{{item.UpdatedOn | date: 'MM/dd/yyyy'}}</span>
                              </p>
                              <p class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                              *ngIf="item.ImmediateActionId == statusreviewTour && item.Status==statusstaffAssigned" class="plain-message" title="Request Tour">
                                Tour Scheduled for {{item.RequestTourDate | date: 'MM/dd/yyyy'}}
                              </p>
                              <p class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                              *ngIf="item.ImmediateActionId == statusreviewTour && item.Status==statusTourCompleted" class="plain-message" title="Request Tour">
                                Tour Completed Successfully for {{item.RequestTourDate | date: 'MM/dd/yyyy'}}
                              </p>
                              <p class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                              *ngIf="item.ImmediateActionId == statusreviewTour && item.Status==statusdecline" class="plain-message" title="Request Tour">
                                Tour has been cancelled for {{item.RequestTourDate | date: 'MM/dd/yyyy'}}
                              </p>
                            
                              <p *ngIf="item.ImmediateActionId != statusCrbaUpdate && item.ImmediateActionId != statusReturn && item.ImmediateActionId != statusFinancial && item.ImmediateActionId != statusVerified" class="status-msg d-none">
                                 {{item.PatientStatus}}
                                 <span
                                    *ngIf="item.PatientStatus !='' && item.ImmediateActionId != statusstaffAssigned ">{{item.CreatedOn|
                                    date: 'MM/dd/yyyy'}}</span>
                              </p>
                              <p *ngIf="item.ImmediateActionId == statusReturn || item.ImmediateActionId == statusFinancial " class="plain-message">
                                 {{item.PatientStatus}}
                                 <span *ngIf="item.PatientStatus !='' && item.ImmediateActionId != statusstaffAssigned">{{item.CreatedOn|
                                    date: 'MM/dd/yyyy'}}</span>
                              </p>
                              <u>
                                 <a *ngIf="item.ImmediateActionId == statusCrbaUpdate"
                                    class="status-msg cursor-pointer text-underline d-none"
                                    (click)="onUpdateCRBA(item.PatientInfoId)">{{item.PatientStatus}}<span
                                       *ngIf="item.PatientStatus !=''">{{item.CreatedOn|
                                       date: 'MM/dd/yyyy'}}</span></a>
                              </u>
                              <div class="mt-2 mb-2">
                                 <a *ngIf="item.ImmediateActionId == statusdecline"
                                    class="status-msg cursor-pointer text-underline"
                                    (click)="getfinancialReason(item)">Show Reason </a> <a
                                    *ngIf="item.ImmediateActionId  == statusdecline || item.ImmediateActionId == statusFinancial || item.ImmediateActionId == statusreviewTour || item.ImmediateActionId == statusstaffAssigned "
                                    class="status-msg cursor-pointer text-underline" (click)="onSubmitFinancial(item)">
                                    Edit Financial Proposal</a>
                              </div>

                           </div>
                        </div>
                        <div class=" col-lg-3 col-md-6 col-sm-6 mb-2">
                           <div class="icons-section row mt-4">
                              <div class=" col-lg-11 ml-auto">
                                
                                 <button class="col text-left col-btn-dis" role="button"
                                    (click)="onPatientdetailview(item.PatientInfoId)" title="Detail View"><i
                                       class="far fa-calendar-minus"></i></button>
                                       <button class="col text-left col-btn-dis" role="button" *ngIf="roleAccess.isView"
                                    (click)="onPatientActivitylogview(item.PatientInfoId)" title="Activity"><i
                                     class="fas fa-user-clock"></i></button>
                                 <button class="col text-center col-btn-dis" role="button"
                                    [disabled]="item.ImmediateActionId == statusResendActivationMail"
                                    (click)="onPatientinfo(item.PatientInfoId,item)" *ngIf="roleAccess.isEdit" title="Edit"><i class="far fa-edit"
                                       aria-hidden="true"></i></button>
                                 <button class="col text-center col-btn-dis" role="button"
                                    (click)="openComposeMail(item.Email)"><i
                                       class="far fa-envelope"></i></button>
                                     
                                       <button class="col text-center col-btn-dis" role="button"
                                    (click)="removeLead(item.PatientInfoId)"  title="Decline Client"><i class="fas fa-minus-circle"></i></button>
                                 <!-- <button class="col text-center col-btn-dis" role="button" [disabled]="item.ImmediateActionId == statusResendActivationMail || item.ImmediateActionId == statusUploadInsurance || item.ImmediateActionId == statusReviewPacket || item.ImmediateActionId == statusReturn" (click)="onPatientinfo(item.PatientInfoId,item)" title="Edit"><i class="far fa-edit" aria-hidden="true"></i></button>
                                 <button class="col text-center col-btn-dis" role="button" [disabled]="item.ImmediateActionId == statusResendActivationMail || item.ImmediateActionId == statusUploadInsurance || item.ImmediateActionId == statusReviewPacket || item.ImmediateActionId == statusReturn " (click)="global.onOpenEmailPopup(item.ParentList[0].Email)"><i class="far fa-envelope"></i></button> -->
                                 <!-- <button class="col text-right col-btn-dis" role="button"><i
                                       class="far fa-address-book"></i></button> -->

                                 <span class=" position-relative d-none">
                                    <div class="dropdown no-caret list-options" dropdown="">
                                       <button aria-controls="dropdown-basic"
                                          class="btn btn-sm btn-neutral mr-0 dropdown-toggle" dropdowntoggle=""
                                          id="button-basic" type="button" aria-haspopup="true" aria-expanded="false"><i
                                             class=" fas fa-ellipsis-v"></i></button>
                                       <div
                                          class="dropdown-menu dropdown-menu-right dropdown-menu-arrow ng-star-inserted"
                                          style="left: auto; right: 0px;"><a class="dropdown-item"
                                             href="javascript:void(0)"> Action </a><a class="dropdown-item"
                                             href="javascript:void(0)"> Another action </a><a class="dropdown-item"
                                             href="javascript:void(0)"> Something else here </a></div>
                                    </div>
                                 </span>

                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="20"
         [pageSizeOptions]="[ 10, 20, 50, 75, 100]">
      </mat-paginator>
   </div>

   <div class="task" *ngIf="!roleAccess.isView">
      <div class="msg-nomeet text-center">You do not have privilege to access this. Please contact admin</div>
  </div>
</div>
<div class="modal mail-modal" id="myModal" [style.display]="global.showEmailPopup ? 'block' : 'none'">
   <div class="modal-dialog mb-2">
      <div class="modal-content email-compose">
         <div class="modal-header">
            <h4 class="modal-title">Email</h4>
            <button aria-hidden="true" data-dismiss="modal" class="close" (click)="global.oncloseEmailpopup()"
               type="button"><i class="fas fa-times"></i></button>

         </div>
         <div class="modal-body">
            <app-send-email [subtag]="subjectTag"></app-send-email>

         </div>
      </div>
   </div>
</div>
<!-- Modal content for Activity log-->
<div class="Activity-popup">
<div class="modal"  tabindex="-1" role="dialog" [style.display]=" ShowActivity ? 'block' : 'none'">
   <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h6 class="modal-title">Activity Log</h6>
            <button type="button" class="close"  data-dismiss="modal"
            aria-label="Close" (click)="onCloseActivitylog()">
            <span aria-hidden="true">&times;</span>
         </button>
         </div>
         <div class="modal-body ">
            <div class="individual-threaphy-container">
            <div  class="history-list col-md-14 p-0">
               <ul>
                  <li *ngFor="let item of leadhistory;let i = index">
                        <div class="list-history col-md-14">
                          <div class="row">
                              <div class="col-md-1" *ngIf="item.roleId !== 4">
                                  <img class="img-fluid" src="../../../assets/images/move2stage-icon.png"/>
                              </div>
                              <div class="col-md-1" *ngIf="item.roleId == 4">
                                  <img class="img-fluid" src="../../../assets/images/move2stage-icon-green.png"/>
                              </div>
                              <div class="col-md-9">
                                  <p class="sub-history mb-1"></p>
                                  <p class="sub-history2 mb-1" innerHTML="{{global.getContent(item.message)}}"></p>
                              </div>
                              <div class="col-md-2 text-right">
                                  <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) |  date: 'MM/dd/yyyy'}}</p>
                                  <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) | date: 'hh:mm a' }}</p>
                              </div>
                           </div>
                        </div>
                  </li>
               </ul>
          </div>
      </div>
      </div>
   </div>
      <div class="col-md-12 p-0 mt-4 view-note d-none">

          <div class="row">
              <div class="col-md-12 mb-1" *ngFor="let item of leadhistory;let i = index">
                  <div class="list-note">
                      <div class="row">
                          <div class="col-md-8">
                              <div class="float-left w-100 pl-4 position-relative">

                                  <p class="note-content mt-1" innerHTML="{{global.getContent(item.message)}}">

                                  </p>
                              </div>
                          </div>

              </div>
            </div>
            </div>
         </div>
      </div>
   </div>
</div>

<div id="myModal" class="modal deny-modal" role="dialog" [style.display]="isFinancialDecline ? 'block' : 'none'">
   <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
         <div class="modal-header fill-header">
            <h6 class="modal-title">Please explain your reason for rejecting the Financial Proposal</h6>
            <button type="button" class="close" (click)="closeDeclineProcess()" data-dismiss="modal"><i
                  class="fas fa-times"></i></button>
         </div>
         <div class="modal-body">
            <p>{{financialDenyReason}}</p>
         </div>
         <div class="modal-footer">

            <button type="button" (click)="closeDeclineProcess()" class="btn btn-cancel primary-btn-s"
               data-dismiss="modal">Close</button>

         </div>
      </div>

   </div>
</div>

<div class="comment-popup  cancel-tour" *ngIf="isPopup" >
   <form class="form-horizontal form-material mx-2" (ngSubmit)="ShareDocument.form.valid" id="ShareDocument"   #ShareDocument="ngForm">
   <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
     <div class="modal-dialog mat-dialog-container " role="document">
 
       <div class="modal-content border-0">
 <div class="modal-header">
   <div class="modal-title">Email</div>
   <!-- <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="SharePopupClose()">
     <span aria-hidden="true">&times;</span>
   </button> -->
   <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="SharePopupClose()">
      <span aria-hidden="true">&times;</span>
    </button>
 </div>
         <div class="modal-body">
 <div >
 
 
   <div class="col-md-12">
     <div class="row ">
       <div class="col-md-12">
 <div class="row">
  
   <div class="col-md-10" >
     <mat-form-field class="w-100">
      <!-- <label class="label" style="font-weight: bold;" >Email:</label>
      <p class="m-0">{{resendactivationemail.Email}}</p> -->
      <input matInput placeholder="Email" maxlength="50" [(ngModel)]="resendactivationemail.Email"
      #email="ngModel" name="email" [disabled]="isEditUsers"
      pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
      required>
     </mat-form-field>
     <div class="col-md-2" >
      <button mat-raised-button style="min-width: 187px; margin-left: 236px;"  
      class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40" type="submit" 
      form="ShareDocument" (click)="onResendActivationEmail(resendactivationemail)">Resend Activation Email</button>
    </div>
    <!-- <div class="col-md-3" *ngIf="isActiveEmail">
      <div class="check-custom mt-4">
          <button mat-stroked-button title="Resend Activation Mail" type="button"
              class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="onResendActivationEmail()">Resend Activation Mail</button>
      </div>
  </div> -->
   </div>
   
  
 </div>
         
       </div>
 
 
     </div>
 
   </div>
   <div class="text-center col-md-12 mt-2">
     
     <!-- <button type="button" class="btn btn-cancel" data-dismiss="modal" >No</button> -->
   </div>
 
 
 
 </div>
 
 
 </div>
 </div>
 </div>
 
 </div>
 </form>
 </div>
 <div class="comment-popup  cancel-tour">

   <div *ngIf="isLeadInformation">
   <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
     <div class="modal-dialog mat-dialog-container popupdcln" role="document">
       <div class="modal-content border-0">
         <div class="modal-header">
           <h6 class="modal-title pdgleft" >Decline</h6>
          
           
           <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="PopupClose()">
             <span aria-hidden="true">&times;</span>
           </button>
         </div>
         <div class="modal-body">
 <div >
   
   <div class="col-md-12">
     <div class="row mb-2">
     </div>
    
     <div class="row mb-2" style="margin-left:1px">
       <div class="col-md-4 pr-0 pl-0">
         <label class="control-label-new " >Reason for decline<span class="errorpopup">*</span></label>
       </div>
 
        <div class="col-md-4 form-group pl-0">
         <select class="form-control drpdown" name="reason" 
         [(ngModel)]="reason"   required (change)="reasonchange()">
         <option value="undefined">-- Reason --</option>         
          <option value="Future Client">Future Client</option>
          <option value="Incompatible">Incompatible</option>
          <option value="Financial Issues">Financial Issues</option>
          <option value="Transportation Issues">Transportation Issues</option>
          <option value="Waitlist">Waitlist</option>
          <option value="Ghosted">Ghosted</option>
          <option value="Other">Other</option>
         
      </select>
      <span class="text-danger fs12 row ermsg" *ngIf="!declineReason">
         Reason for decline is required
          </span>
      
       </div>
     
     </div> 
     <div>
      <div class="example-form">
         <mat-form-field class="example-full-width w-100">
             <mat-label >Comments<span class="errorpopup">*</span></mat-label>
             <input matInput placeholder="" [(ngModel)]="comments"  (ngModelChange) ="commentchange($event)" maxlength="150" >
            
             
            
         </mat-form-field>
         <span class="text-danger fs12 row cmnterrmsg" *ngIf="!commentReason">
            comments is required
             </span>
     </div>

     </div>
 
       <div class="col-md-2"  >
         <button mat-raised-button   
         class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40 smtbtn"  type="submit" 
         (click) = "moveLeadInformation()"  >Submit</button>
       
       </div>
    
   </div>
 </div>
 </div>
 </div>
 </div>
 </div>
</div>
  </div>
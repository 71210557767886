<div class="col-md-12">
    <div class="lead-box">
        <div class="head-section mb-2 mt-0">{{headerdata}}</div>
        <form (ngSubmit)="masterform.form.valid" id="masterform" autocomplete="off"
            #masterform="ngForm" novalidate>
            <div class="row">
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Name" (keypress)="omit_special_char($event)" [(ngModel)]="masterData.MasterName" 
                                    #MasterName="ngModel" name="MasterName" maxlength="30"
                                    [ngClass]="{ 'is-invalid': masterform.submitted && MasterName.invalid }" required>
                                <mat-error *ngIf="masterform.submitted && MasterName.invalid">
                                    <mat-error *ngIf="MasterName.errors.required">Name is required</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Description" [(ngModel)]="masterData.Description"
                                    #Description="ngModel" name="Description" maxlength="80"
                                    [ngClass]="{ 'is-invalid': masterform.submitted && Description.invalid }">
                                <!-- <mat-error *ngIf="masterform.submitted && Description.invalid">
                    <mat-error *ngIf="Description.errors.required">Description is required</mat-error>
                </mat-error> -->
                            </mat-form-field>


                        </div>
                        <!-- <div class="col-md-4">
                            <div class="check-custom mt-4">
                                <mat-checkbox class="example-margin" [(ngModel)]="masterData.IsActive"
                                    #IsActive="ngModel" name="parentIsPrimaryContact">Active
                                </mat-checkbox>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-4 text-right" *ngIf="roleAccess.isEdit || roleAccess.isCreate">
                    <button type="submit" form="masterform" mat-stroked-button
                        class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="addorUpdate(masterform)"  [ngClass]="{'disabled': Savestarted }" >{{!isEditMaster ? 'Save' :
                        'Update'}}</button>
                    <button mat-stroked-button class="btn btn-cancel primary-btn-m h-40" type="button"
                        (click)="resetMasterform(masterform)">Cancel</button>

                </div>
            </div>


        </form>
    </div>

</div>
<div class="col-md-12 p-0 mt-4">
    <div class="lead-box">
        <div class="clearfix w-100">

            <div class="box-lead-head float-left">{{headerdata}} - DETAILS</div>
        </div>
        <div class="col-md-12  table-custom p-0 tablemaster">
            <div class="table-responsive">
                <mat-table #table [dataSource]="lstMaster" matSort class="w-100 " 
                cdkDropList
                (cdkDropListDropped)="drop($event)"
                cdkDropListData="lstMaster"
                [cdkDropListDisabled]="dragDisabled">
                    <ng-container matColumnDef="sno" sticky>
                        <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                        <!-- <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell> -->
                        <mat-cell *matCellDef="let element; let i = index;" class="dragCursor" (mousedown)="dragDisabled = false;"> 
                            <!-- <mat-icon class="dragCursor" (mousedown)="dragDisabled = false;"
                                        >reorder</mat-icon> -->
                           
                        <span> {{i+1}}</span>
                        </mat-cell>

                    </ng-container>

                    <ng-container matColumnDef="MasterName" >
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Master Name </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="dragCursor" (mousedown)="dragDisabled = false;"> {{element.MasterName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="Description" >
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                        <mat-cell *matCellDef="let element" title="{{element.Description}} " class="dragCursor" (mousedown)="dragDisabled = false;"> {{element.Description}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="IsActive" >
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Active </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="dragCursor" (mousedown)="dragDisabled = false;"> {{element.IsActive == 1 ? 'Yes':'No'}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions" >
                        <mat-header-cell *matHeaderCellDef> {{ (roleAccess.isEdit || roleAccess.isDelete) ? 'Actions' : '' }}   </mat-header-cell>
                        <mat-cell class="text-right" *matCellDef="let element;let i = index;" class="dragCursor" (mousedown)="dragDisabled = false;">
                            <div style="width: 40px;" *ngIf="roleAccess.isEdit || roleAccess.isDelete">
                                <button type="button" mat-icon-button matTooltip="Edit"
                                    *ngIf="!(pageId == 1 &&  element.MasterName =='Others')"
                                    (click)="oneditMasters(element)" color="primary">
                                    <img src="../../../assets/images/editblue.png">
                                </button>
                            </div>
                            <div style="width: 40px;" *ngIf="roleAccess.isDelete">
                                <button mat-icon-button matTooltip="Delete" (click)="ondeleteMasters(element)"
                                    color="warn">
                                    <img src="../../../assets/images/deletered.png">
                                </button>
                            </div>
                            <div style="width: 40px;" *ngIf="roleAccess.isDelete">
                                <span (click)="onChangeStatusMasters(element)">
                                    <i class="fas fa-toggle-on fa-toogle ml-2 mt-1" *ngIf="element.IsActive"></i>
                                    <i class="fas fa-toggle-off fa-toogle ml-2 mt-1" *ngIf="!element.IsActive"></i>
                                </span>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"
                    cdkDrag
                    [cdkDragData]="row"
                    ></mat-row>

                </mat-table>
            </div>
        </div>
        <mat-paginator  (page)="onPageChangeMaster($event)" 
        [pageSize]="20"[pageSizeOptions]="[ 10, 20, 50, 75, 100]" showFirstLastButtons></mat-paginator>
    </div>
</div>
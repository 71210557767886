import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import _Quill from 'quill'
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Global } from 'src/app/global/global.model';
import { SendEmail } from 'src/app/_models/_Common/sendEmail';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { constantVariables } from 'src/app/constant/constant';
import { HttpEventType } from '@angular/common/http';
import { notification } from 'src/app/_models/_Common/notification';
import { NotificationService } from 'src/app/notification/notification.service';
import { cloneDeep } from "lodash";
import { LeadService } from 'src/app/patient-lead/lead.service';
@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent implements OnInit {
  @Input() subtag: string;
  @Input() idtag: any;
  @Input() tomailtag: any;
  @ViewChild('mailUploadfile', { static: true }) mailUploadfile;
  @ViewChild('mailUploadfile') elemailUploadfile: ElementRef;
  @ViewChild('tomailinput') toEmailInput: ElementRef;
  @ViewChild('ccmailinput') CCEmailInput: ElementRef;
  @ViewChild(NgForm) form: NgForm;
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction

        //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        //[{ 'font': [] }],
        //[{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        //['link', 'image', 'video']
      ],

    },

    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range, context) => {
            //console.log("enter");
            return true;
          }
        }
      }
    }
  }
  selectable: boolean = true;
  public separatorKeysCodes = [ENTER, COMMA];
  removable = true;
  sendMailinfo: any;
  // mailform: NgForm;
  mailform: boolean = false;
  UserName: any;
  currentUserId: any;
  noticationinfo: notification;
  roleId: any;
  patientid: any;
  parentlst: any;
  filteredTomail: any = [];
  filteredCCmail: any = [];
  constructor(public leadservice: LeadService, public global: Global, private route: ActivatedRoute, public commonService: CommonService,
    public spinnerservice: NgxSpinnerService, private router: Router, private snackbar: SnackBarService, public notify: NotificationService) { }

  ngOnInit(): void {

    this.global.mailinfo = new SendEmail();
    this.global.toemailList.push({ value: this.tomailtag, invalid: false });
    this.noticationinfo = new notification();
    this.roleId = Number(localStorage.getItem("roleId"));
    this.currentUserId = localStorage.getItem('id');
    this.UserName = localStorage.getItem("username");
    this.patientid = localStorage.getItem("patientId");
    this.global.mailinfo.QueryDescription = '<br>Hi, <br>Subject: A new message from SFCC EMR<br><br><br>Thanks,<br>' + this.UserName + '';
    this.global.mailinfo.Title = constantVariables.SuccessMessage.SubjectContent;
    this.getActiveParentList();

  }
  addtoemail(event) {
   

    if (event.value) {
      if (event.value != "[object Object]") {
        if (this.validateEmail(event.value)) {
          this.global.toemailList.push({ value: event.value, invalid: false });
          event.input.value = '';
        }
        else{
          event.input.value = '';
        }
      }
    }
  
      if (event.input) {
        event.chipInput!.clear();
        this.filteredTomail = this.parentlst;
      }
    
  }
  doFiltertomail(val) {
    this.filteredTomail = [];
    this.filteredTomail = this.parentlst.filter(m => m.email.startsWith(val))
  }
  doFilterccmail(val) {
    this.filteredCCmail = [];
    this.filteredCCmail = this.parentlst.filter(m => m.email.startsWith(val))
  }
  addtoemailselected(event) {
    if (this.global.toemailList.filter(x => x.value == event.option.viewValue).length == 0) {
      this.global.toemailList.push({ value: event.option.viewValue, invalid: false });
    }
    this.toEmailInput.nativeElement.value = '';
  }
  addCCemailselected(event) {
    if (this.global.ccemailList.filter(x => x.value == event.option.viewValue).length == 0) {
      if (this.global.toemailList.filter(x => x.value == event.option.viewValue).length == 0) {
        this.global.ccemailList.push({ value: event.option.viewValue, invalid: false });
       
      }
    }
    this.CCEmailInput.nativeElement.value = '';
  }
  addccemail(event) {
    if (event.value) {
      if (this.validateEmail(event.value)) {
        if (this.global.toemailList.filter(x => x.value == event.value).length == 0) {
          this.global.ccemailList.push({ value: event.value, invalid: false });
          event.input.value = '';
        }
      }
      else{
        event.input.value = '';
      }
    }
    if (event.input) {
      event.chipInput!.clear();
      this.filteredCCmail = this.parentlst;
    }
  }
  private validateEmail(email) {
    var re = /^[a-z0-9._%+-]+@[a-z.-]+\.[a-z]{2,3}$/;
    return re.test(String(email).toLowerCase());
  }
  removeEmail(data: any): void {
    if (this.global.toemailList.indexOf(data) >= 0) {
      this.global.toemailList.splice(this.global.toemailList.indexOf(data), 1);
    }
  }
  removeCCEmail(data: any): void {
    if (this.global.ccemailList.indexOf(data) >= 0) {
      this.global.ccemailList.splice(this.global.ccemailList.indexOf(data), 1);
    }
  }
  removeattachments(data: any): void {
    if (this.global.mailattachment.indexOf(data) >= 0) {
      this.global.mailattachment.splice(this.global.mailattachment.indexOf(data), 1);
    }
  }
  onmailFileChange(event) {
    const reader = new FileReader();
    var _validFileExtensions = [".jpg", ".jpeg", ".png"];
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (file.type == "image/jpeg" || "image/png" || "image/webp") {
        reader.readAsDataURL(file);
        // var dd = this.mailUploadfile.nativeElement.files;
        reader.onload = () => {
          for (var i = 0; i < event.target.files.length; i++) {
            this.global.mailattachment.push(event.target.files[i]);
          }
          this.elemailUploadfile.nativeElement.value = "";
        };
      } else {
        this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
      }
    }
  }
  postmail() {
    if (this.global.toemailList && this.global.toemailList.length == 0) {
      this.snackbar.error(constantVariables.ErrorMessage.tomail);
      return
    }
    this.spinnerservice.show();
    this.sendMailinfo = cloneDeep(this.global.mailinfo);
    this.sendMailinfo.Title = this.subtag !== "" ? "" + this.subtag + " : " + this.sendMailinfo.Title + "" : this.sendMailinfo.Title;
    this.sendMailinfo.ToUserEmail = this.global.toemailList.map((item) => { return item.value }).join(';');
    this.sendMailinfo.CCUsersEmail = this.global.ccemailList.map((item) => { return item.value }).join(';');
    this.sendMailinfo.Type = this.subtag;
    this.sendMailinfo.Id = this.idtag;
    this.sendMailinfo.PrqueryId = 0;
    this.sendMailinfo.ParentId = this.sendMailinfo.ParentId == '' ? 0 : this.sendMailinfo.ParentId;
    //const dataToEncrypt = this.commonService.DataToEncrypt(this.sendMailinfo, false);

    let formData = new FormData();
    for (var i = 0; i < this.global.mailattachment.length; i++) {
      formData.append("global.mailattachments[]", this.global.mailattachment[i]);
    }

    formData.append('data', this.commonService.DataToEncrypt(this.sendMailinfo, true));

    this.commonService.postEmail(formData)
      .subscribe(event => {

        this.snackbar.success(constantVariables.SuccessMessage.mailsendsuccess);
        this.sendMailinfo.PrqueryId = event;
        this.uploadAttachment(this.sendMailinfo);
        this.global.oncloseEmailpopup();
        this.elemailUploadfile.nativeElement.value = "";
        if (this.subtag !== "") {
          this.router.navigate(['/leadlist']);
        } else {
          this.mailform = false;
        }

      },
        err => {
          this.spinnerservice.hide();
        }
      );
  }

  public sendmailFinished = (event) => {
    this.snackbar.success(event);

  }
  uploadAttachment(item) {

    let formData = new FormData();
    for (var i = 0; i < this.global.mailattachment.length; i++) {
      formData.append("global.mailattachments[]", this.global.mailattachment[i]);
    }

    formData.append('data', this.commonService.DataToEncrypt(item, true));
    this.commonService.UploadDocment(formData).subscribe(
      response => {


      },
      err => {

        this.spinnerservice.hide();
      },
    );
  }

  getActiveParentList() {
    this.leadservice.getActiveParentlst().subscribe(
      (res: any) => {
        if (res) {
          this.parentlst = res;
          this.filteredTomail = res;
          this.filteredCCmail = res;

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
}

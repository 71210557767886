import { Injectable, DebugElement } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private fb: FormBuilder, private http: HttpClient, public commonService: CommonService) { }
  login(formData) {

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8',

    );

    return this.http.post(environment.BaseURI + 'Login', formData, { headers })
      .pipe(map(res => {
        return res;
      }));

  }
  getAuthToken() {
    return localStorage.getItem('token');
  }
  ForgetPassword(formData) {

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'ForgotPasswordMailSend',
    formData, { responseType: 'text' })
    .pipe(map(function (response) { return response; }));
   
    // return this.http.post(environment.BaseURI + 'ForgotPasswordMailSend', formData, { headers })
    //   .pipe(map(res => {
    //     return res;
    //   }));
  }
  onlogoutLog() {
    var userid = Number(localStorage.getItem('id'));
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'Logout', { headers }
    )
      .pipe(map(function (response) { return response; }));
    return this.http.get<any>(environment.BaseURI + 'gateway/Authenticate/logout?id=' + this.commonService.DataToEncrypt(userid, true));
  }
}

export class sfcclocation{
    sfccMasterId :number;
    locationName :string;
    address1  :string;
    address2  :string;
    email  :string;
    phone  :string;
    contactPerson  :string;
    stateId :number;
    cityId :number;
    stateName  :string;
    cityName  :string;
    locationType :number;
    locationTypeName  :string;
    isActive  :boolean;
    isTour : boolean = false;
}
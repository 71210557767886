import { Component, OnInit,ViewChild } from '@angular/core';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { ActivatedRoute,Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/common/common.service';
import {IncReportInfo, RestraintCondition} from 'src/app/_models/IncReportDtl'
import { MatDialog } from '@angular/material/dialog';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
	   
@Component({
  selector: 'app-incident-reporting',
  templateUrl: './incident-reporting.component.html',
  styleUrls: ['./incident-reporting.component.scss']
})
export class IncidentReportingComponent implements OnInit {
  @ViewChild('IncReportfrm') IncReportfrm: NgForm;
  IncReportfrmInfo:IncReportInfo;
  PatientList:any;
  ddlTherapy:any
  ddlpatientList:any
  Id:any
  PatientName:any
  ddlstafflist: any;
  ddlReportedBy: any;
  ddlrestraintType: any;
  ddlcpiRestraintType: any;
  IsInjured: boolean;
  IsDiscontinuingRestraint: boolean;
  IsCalled: boolean=false;
  SelectAllRestraintType: boolean;
  OtherRestraintType: boolean;
  SelectAllCpiRestraintType: boolean;
  IncidentReportId: number=0;
  Edit: number=0;
  IsEdit:boolean;
  RoleId: number;
  status: any;
  ddlIncidenType: any;
  signatureImg: any;
  isESignAttached: boolean;
  updatedsignatureImg: any;
  isformtype: string;
  defaultImage: any;
  ddlrestraintTimeMaster: any;
  Numberoftimes: any;
  ddlProgramType: any;
  inaDay: boolean;
  today = new Date();
  currentTime: string;
  errorMessageForIncident:string;
  showErrorMessageForIncident:boolean=false;
  errorMessageForBehaviour:string;
  showErrorMessageForBehaviour:boolean=false;
  roleAccess: any;
  isSaveasClientName:boolean=false;
  isSaveasIncidentTypeName:boolean=false;  
  secondaryId : any;
  AutoFillSS:boolean=false;
  SSignature:any;
  GetClinicianSign: any[];  
  userId: any;
  IsReview :boolean = false;
  constructor( public treatmentservice: TreatmentService, public router:Router,public global: Global,
    public spinnerservice: NgxSpinnerService, private dialog: MatDialog, private snackbar: SnackBarService,public commonService: CommonService,private route: ActivatedRoute,
    public datepipe: DatePipe,
    public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.IncidentReporting;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.RoleId = Number(localStorage.getItem("roleId"));
    this.userId = Number(localStorage.getItem('id'));
    this.getAutoFillSignature();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.IncidentReportId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));       
        this.Edit = Number(this.commonService.FrontEndDecryption(params.isedit).replace(/"/g, ""));
        this.status = Number(this.commonService.FrontEndDecryption(params.status).replace(/"/g, ""));
      }
    })
    if(this.status ==1 ){
      this.IsEdit= true;
    }
    if(this.status==undefined)
      {
        this.IsReview=true;
      }
    this.IncReportfrmInfo = new IncReportInfo();
    this.Id = localStorage.getItem('id') 
    this.currentTime= moment().format("hh:mm");
    let restraintCondition = Array<RestraintCondition>();
  //  let _obj = new RestraintCondition();
  //   restraintCondition.push(_obj)
   this.IncReportfrmInfo.RestraintConditions=[]
  // this.IncReportfrmInfo.RestraintConditions.push(_obj)
    this.LoadMasters()
   
  }
LoadMasters(){
  this.getPatientlist()
  this.GetStaffList()
  this.getRestraintMaster()
  this.getTherapylist()
  if(this.IncidentReportId!=0){
    this.getIncidentReportById()
  }
}

getPatientlist(){
  this.spinnerservice.show();
  this.secondaryId = localStorage.getItem('id');
this.treatmentservice.getPatientListforIR(this.Id,this.secondaryId).subscribe(res=>{
 this.ddlpatientList = res;
 this.spinnerservice.hide()
},
err => {
  this.spinnerservice.hide();

})
}
GetStaffList(){
  this.spinnerservice.show()
  this.treatmentservice.getCLinicalStaffList().subscribe(res=>{
    this.spinnerservice.hide()
    this.ddlstafflist = res
    this.ddlReportedBy = res
    this.IncReportfrmInfo.ClinicianId = +this.Id
  },
  err => {
    this.spinnerservice.hide();

  })
}
getRestraintMaster(){
  this.spinnerservice.show()
  this.treatmentservice.getRestraintMaster().subscribe(res=>{
    this.spinnerservice.hide()
   this.ddlrestraintType = res.restraints
   this.ddlcpiRestraintType = res.cpiRestraints
   this.ddlrestraintTimeMaster = res.restraintTimeMaster
   this.ddlProgramType = res.programMasters
  },
  err => {
    this.spinnerservice.hide();

  })
}
onSelectedPatient(item){
 // console.log(item)
  this.PatientName = item.patientName
}
OnBack(){
  this.router.navigate(['/clinician'],{queryParams:{menu:4}});
}
PostIncReportfrm(){
  if (!this.IncReportfrm.form.valid){
    this.snackbar.error("Please fill mandatory fields")
    return
  }
  if(this.IncReportfrmInfo.IncidentReportTypeId == 1 && this.showErrorMessageForIncident)
  {
    return
  }
  else if(this.IncReportfrmInfo.IncidentReportTypeId == 2 && this.showErrorMessageForBehaviour)
  {
    return
  }
  else
  {
  if(this.IncReportfrmInfo.IncidentReportTypeId==2){
    if(this.IncReportfrmInfo.RestraintTypeId.includes(1)){
      this.IncReportfrmInfo.RestraintTypeId.splice(0, 1);}
      if(this.IncReportfrmInfo.CpiRestraintTypeId.includes(1)){
        this.IncReportfrmInfo.CpiRestraintTypeId.splice(0, 1);}
    this.IncReportfrmInfo.IsInjured = this.IsInjured;
    this.IncReportfrmInfo.IsCalled = this.IsCalled;
    this.IncReportfrmInfo.RestraintInAday = this.inaDay;
    this.IncReportfrmInfo.IsDiscontinuingRestraint = this.IsDiscontinuingRestraint
    
    this.IncReportfrmInfo.RestraintTypeId = this.IncReportfrmInfo.RestraintTypeId.toString();
    this.IncReportfrmInfo.CpiRestraintTypeId = this.IncReportfrmInfo.CpiRestraintTypeId.toString();
  }
  if(this.IncReportfrmInfo.IncidentReportTypeId==1){
    this.IncReportfrmInfo.IncidentDate = this.IncReportfrmInfo.IncidentDateForIncident;
    this.IncReportfrmInfo.IncidentTime = this.IncReportfrmInfo.IncidentTimeForIncident;
  }
  if(!this.IsEdit && this.IncReportfrmInfo.IncidentReportTypeId!=2 ){
    this.IncReportfrmInfo.RestraintTypeId = null
    this.IncReportfrmInfo.CpiRestraintTypeId = null
    this.IncReportfrmInfo.IsCalled = null
    this.IncReportfrmInfo.IsDiscontinuingRestraint=null
    this.IncReportfrmInfo.IsInjured=null
    this.IncReportfrmInfo.Program=0
    this.IncReportfrmInfo.RTimeline=0
    this.IncReportfrmInfo.TheraphyId=0
    this.IncReportfrmInfo.RestraintInAday=false
    this.IncReportfrmInfo.IncidentDate = this.IncReportfrmInfo.IncidentDateForIncident;
    this.IncReportfrmInfo.IncidentTime = this.IncReportfrmInfo.IncidentTimeForIncident;
    if(this.IncReportfrmInfo.RestraintConditions.length > 0) {
      this.IncReportfrmInfo.RestraintConditions[0].RestraintConditionId=0;
    } else {
      this.IncReportfrmInfo.RestraintConditions = [];
    }

  }
  this.IncReportfrmInfo.RestraintConditions.forEach(value => {
    if(value.RestraintAdminstrated == 1)
    {
      value.RestraintAdminstrated = true;
    }else{
      value.RestraintAdminstrated = false;
    }
    if(value.ProvisionOfWater == 1)
    {
      value.ProvisionOfWater = true;
    }else{
      value.ProvisionOfWater = false;
    }
  })
  if(this.IncReportfrmInfo.IncidentReportTypeId==1 && (this.IncReportfrmInfo.StaffInitial==undefined || this.IncReportfrm.invalid)){
    return
  }
  else if(this.IncReportfrmInfo.IncidentReportTypeId==2 && this.IncReportfrm.invalid ){
    return
  }
  else{
  //console.log(this.IncReportfrmInfo)
  this.spinnerservice.show()
  this.treatmentservice.PostIncidentReport(this.IncReportfrmInfo).subscribe(res=>{
    this.spinnerservice.hide()
    if(!this.IsEdit){
    this.snackbar.success("Incident Report has been submitted successfully");
    }
    else{
      this.snackbar.success("Incident Report has been updated successfully");
    }
    this.OnBack();
  },
  err=>{
    this.spinnerservice.hide()
  this.snackbar.error("Not Implemented")

  })
  }
  }
}


getTherapylist(){
  this.spinnerservice.show();

  this.treatmentservice.getTreatmentPlanMaster().subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      this.ddlTherapy = this.commonService.decrypdata(res.therapy);
      this.ddlIncidenType = this.commonService.decrypdata(res.incidentype)
    },
    err => {
      this.spinnerservice.hide();

    },
  );
}
OnIsInjured(item){
  if(item==0){
    this.IsInjured = true;
  }
  else{
    this.IsInjured=false;
  }
}
onIsDiscontinuingRestraint(item){
  if(item==0){
    this.IsDiscontinuingRestraint = true;
  }
  else{
    this.IsDiscontinuingRestraint=false;
  }
}
onIsCalled(item){
  if(item==0){
    this.IsCalled = true;
  }
  else{
    this.IsCalled=false;
  }
}

onRestraintTypeIdChange(item:any){
  this.OtherRestraintType = false
 // console.log(item.includes(0))
   if(item.includes(1)){
     this.SelectAllRestraintType = true;
  this.IncReportfrmInfo.RestraintTypeId=[];
 // this.IncReportfrmInfo.RestraintTypeId.push(-1)
  this.ddlrestraintType.forEach(x=>{
    this.IncReportfrmInfo.RestraintTypeId.push(x.restraintTypeId)
  })
   }
   else if(item.includes(-1)){
    this.SelectAllRestraintType = false;
    this.IncReportfrmInfo.RestraintTypeId=[];
   }
   else if(item.includes(6)){
     this.OtherRestraintType = true
   }
   else{
    this.SelectAllRestraintType = false;
    this.OtherRestraintType = false
   }
}

onCpiRestraintTypeIdChange(item){
 // console.log(item.includes(0))
   if(item.includes(1)){
     this.SelectAllCpiRestraintType = true;
  this.IncReportfrmInfo.CpiRestraintTypeId=[];
 // this.IncReportfrmInfo.RestraintTypeId.push(-1)
  this.ddlcpiRestraintType.forEach(x=>{
    this.IncReportfrmInfo.CpiRestraintTypeId.push(x.cpirestraintTypeId)
  })
   }
   else if(item.includes(-1)){
    this.SelectAllCpiRestraintType = false;
    this.IncReportfrmInfo.CpiRestraintTypeId=[];
   }
   else{
    this.SelectAllCpiRestraintType = false;
   
   }
}
onPatientchange(item){
  //this.IncReportfrmInfo.Address = item.address
  this.IncReportfrmInfo.PatientName = item.patientName
}

getIncidentReportById(){
  this.spinnerservice.show();
  this.secondaryId = Number(localStorage.getItem('id'));
  this.treatmentservice.getIncidentById(this.IncidentReportId,this.secondaryId).subscribe((res:any)=>{
    this.IncReportfrmInfo = this.commonService.decrypdata(res.objInputString);    
    if(this.IncReportfrmInfo.IncidentReportTypeId==2){
    this.IncReportfrmInfo.CpiRestraintTypeId = this.IncReportfrmInfo.CpiRestraintTypeId.split(',').map(Number);
    this.IncReportfrmInfo.RestraintTypeId = this.IncReportfrmInfo.RestraintTypeId.split(',').map(Number);
    if( this.IncReportfrmInfo.IsInjured){
      this.IncReportfrmInfo.IsInjured='1'
      this.IsInjured  =true;
    }
    else{
      this.IncReportfrmInfo.IsInjured='2'
      this.IsInjured  =false;
    }
    if( this.IncReportfrmInfo.IsCalled){
      this.IncReportfrmInfo.IsCalled='1'
      this.IsCalled = true
    }
    else{
      this.IncReportfrmInfo.IsCalled='2'
      this.IsCalled = false;
    }
    if( this.IncReportfrmInfo.IsDiscontinuingRestraint){
      this.IncReportfrmInfo.IsDiscontinuingRestraint='true'
      this.IsDiscontinuingRestraint = true
    }
    else{
      this.IncReportfrmInfo.IsDiscontinuingRestraint='false'
      this.IsDiscontinuingRestraint = false
    }
    if( this.IncReportfrmInfo.RestraintInAday){
      this.IncReportfrmInfo.RestraintInAday='true'
      this.inaDay = true
    }
    else{
      this.IncReportfrmInfo.RestraintInAday='false'
      this.inaDay = false
    }
    //console.log(this.IncReportfrmInfo)
    if(this.IncReportfrmInfo.RestraintTypeId.includes(6)){
      this.OtherRestraintType = true;
    }
    this.IncReportfrmInfo.RestraintConditions.forEach(value => {
      if(value.RestraintAdminstrated == true)
      {
        value.RestraintAdminstrated = "1";
      }
      else if(value.RestraintAdminstrated == false)
      {
        value.RestraintAdminstrated = "0";
      }
      if(value.ProvisionOfWater == true)
      {
        value.ProvisionOfWater = "1";
      }
      else if(value.ProvisionOfWater == false){
        value.ProvisionOfWater = "0";
      }
    })
    }
    else if(this.IncReportfrmInfo.IncidentReportTypeId==1){
      this.IncReportfrmInfo.IncidentDateForIncident=this.IncReportfrmInfo.IncidentDate;
      this.IncReportfrmInfo.IncidentTimeForIncident=this.IncReportfrmInfo.IncidentTime;
    }
    else{
    (document.getElementById('esign_2') as HTMLImageElement).src = this.IncReportfrmInfo.StaffInitial;
    }
    this.status = this.IncReportfrmInfo.Status;
    (document.getElementById('esign_3') as HTMLImageElement).src = this.IncReportfrmInfo.StaffInitials;
    (document.getElementById('esign_2') as HTMLImageElement).src = this.IncReportfrmInfo.StaffInitial;

  })
}
onReviewClick(){
  this.router.navigate(['/incident-review'],{queryParams:{id:this.commonService.FrontEndEncryption(this.IncidentReportId),status:this.commonService.FrontEndEncryption(this.status),type:this.commonService.FrontEndEncryption(this.IncReportfrmInfo.IncidentReportTypeId)}});
}

getIncidentType(){

}
openDialog() {
  // this.global.isconsentesign = false;
  // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
  //   data: { isfutureSign: false, isParentsign: true },
  //   autoFocus: false 

  // });
  // dialogRef.afterClosed().subscribe((result) => {
  //   this.isESignAttached = this.global.isconsentesign;
  //   if (
  //     this.global.isconsentesign != undefined &&
  //     this.global.isconsentesign
  //   ) {
    this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if (
      this.global.isconsentesign != undefined &&
      this.global.isconsentesign
    ) {
      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
      this.signatureImg = this.updatedsignatureImg;
      this.IncReportfrmInfo.StaffInitial = this.updatedsignatureImg;
     // this.IncReportfrmInfo.IsAttachEsign = true;
      (document.getElementById('esign_2') as HTMLImageElement).src =
        this.signatureImg;
    } else if (
      !this.global.isconsentesign &&
      this.global.formtype != null &&
      this.global.formtype != undefined
    ) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      this.IncReportfrmInfo.StaffInitial = this.global.consentesign;
      //this.IncReviewInfo.IsAttachEsign = true;
      (document.getElementById('esign_2') as HTMLImageElement).src =
        this.signatureImg;
    } else {
      this.signatureImg = this.defaultImage;
      //this.IncReportfrmInfo.IsAttachEsign = true;
      this.IncReportfrmInfo.StaffInitial = this.defaultImage;
    }
  });
}
OnTypechange(){
}
OnRtimechange(item){
this.Numberoftimes= this.ddlrestraintTimeMaster.filter(x=>x.restraintTimelineId == item)
if (this.Numberoftimes[0].numberOfiterations > this.IncReportfrmInfo.RestraintConditions.length)
{
let iterations = this.Numberoftimes[0].numberOfiterations - this.IncReportfrmInfo.RestraintConditions.length
for(let i=0; i<iterations; i++){
  let release = new RestraintCondition();
  this.IncReportfrmInfo.RestraintConditions.push(release)
}
}
else if(this.Numberoftimes[0].numberOfiterations < this.IncReportfrmInfo.RestraintConditions.length){
  let iterations = this.IncReportfrmInfo.RestraintConditions.length - this.Numberoftimes[0].numberOfiterations
  for(let i=0; i<iterations; i++){
    
    this.IncReportfrmInfo.RestraintConditions.pop()
  }
}
}
onInaDay(item){
  if(item==1){
    this.inaDay = true;
  }
  else{
    this.inaDay = false;
  }
}
timePickerDisableTime()
{
  let currentDate = this.datepipe.transform(new Date(),"yyyy-MM-dd");
  let selectedDate = this.datepipe.transform(this.IncReportfrmInfo.IncidentDate,"yyyy-MM-dd");
  if(currentDate != selectedDate)
  {
    this.showErrorMessageForBehaviour = false;
  }else if(currentDate == selectedDate)
  {
    this.check();
  }
}
timePickerDisableTimeForIncident()
{
  let currentDate = this.datepipe.transform(new Date(),"yyyy-MM-dd");
  let selectedDate = this.datepipe.transform(this.IncReportfrmInfo.IncidentDateForIncident,"yyyy-MM-dd");
  if(currentDate != selectedDate)
  {
    this.showErrorMessageForIncident = false;
  }else if(currentDate == selectedDate)
  {
    this.checkIncidentTime();
  }
}
checkIncidentTime(){
  if(this.IncReportfrmInfo.IncidentDateForIncident)
  {
  let currentDate = this.datepipe.transform(new Date(),"yyyy-MM-dd");
  let selectedDate = this.datepipe.transform(this.IncReportfrmInfo.IncidentDateForIncident,"yyyy-MM-dd");
  if(currentDate == selectedDate)
  {
    let currentTime = moment().format("HH:mm");
    let selectedTime = this.IncReportfrmInfo.IncidentTimeForIncident;
    if(selectedTime > currentTime)
    {
      this.errorMessageForIncident='Select Incident Time between 12:00 am'  +' to ' +moment().format("hh:mm a");
      this.showErrorMessageForIncident = true;
    //this.IncReportfrmInfo.IncidentTime = undefined;
    }else{
      this.showErrorMessageForIncident = false;
    }
  }
  else{
    this.showErrorMessageForIncident = false;
  }
}
}
check(){
  if(this.IncReportfrmInfo.IncidentDate)
  {
  let currentDate = this.datepipe.transform(new Date(),"yyyy-MM-dd");
  let selectedDate = this.datepipe.transform(this.IncReportfrmInfo.IncidentDate,"yyyy-MM-dd");
  if(currentDate == selectedDate)
  {
    let currentTime = moment().format("HH:mm");
    let selectedTime = this.IncReportfrmInfo.IncidentTime;
    if(selectedTime > currentTime)
    {
        this.errorMessageForBehaviour='Select IncidentTime between 12:00 am'  +' to ' +moment().format("hh:mm a");
        this.showErrorMessageForBehaviour = true;
    //this.IncReportfrmInfo.IncidentTime = undefined;
    }else{
      this.showErrorMessageForBehaviour = false;
    }
  }
  else{
    this.showErrorMessageForBehaviour = false;
  }
}
}
saveAsReportIncident(){
  var isError=false;
  if(this.IncReportfrmInfo.IncidentReportTypeId==0||this.IncReportfrmInfo.IncidentReportTypeId==undefined){
    this.isSaveasIncidentTypeName=true;
    isError=true;
  }
  if(this.IncReportfrmInfo.PatientInfoId == 0 || this.IncReportfrmInfo.PatientInfoId == undefined)
  {
    this.isSaveasClientName=true;
    isError=true;
  }
  if(isError){
    return;
  }
  if(this.IncReportfrmInfo.IncidentReportTypeId==2){
    if(this.IncReportfrmInfo.RestraintTypeId){
      if(this.IncReportfrmInfo.RestraintTypeId.includes(1))
      {
        this.IncReportfrmInfo.RestraintTypeId.splice(0, 1);
      }
    }
    if(this.IncReportfrmInfo.CpiRestraintTypeId){
      if(this.IncReportfrmInfo.CpiRestraintTypeId.includes(1))
      {
        this.IncReportfrmInfo.CpiRestraintTypeId.splice(0, 1);
      }
    }
    this.IncReportfrmInfo.IsInjured = this.IsInjured;
    this.IncReportfrmInfo.IsCalled = this.IsCalled;
    this.IncReportfrmInfo.RestraintInAday = this.inaDay;
    this.IncReportfrmInfo.IsDiscontinuingRestraint = this.IsDiscontinuingRestraint
    
    this.IncReportfrmInfo.RestraintTypeId = this.IncReportfrmInfo.RestraintTypeId ? this.IncReportfrmInfo.RestraintTypeId.toString() : null;
    this.IncReportfrmInfo.CpiRestraintTypeId = this.IncReportfrmInfo.CpiRestraintTypeId ? this.IncReportfrmInfo.CpiRestraintTypeId.toString() : null ;
  }

  if(this.IncReportfrmInfo.IncidentReportTypeId==1){
    this.IncReportfrmInfo.IncidentDate = this.IncReportfrmInfo.IncidentDateForIncident;
    this.IncReportfrmInfo.IncidentTime = this.IncReportfrmInfo.IncidentTimeForIncident;
  }

  if(!this.IsEdit && this.IncReportfrmInfo.IncidentReportTypeId!=2 ){
    this.IncReportfrmInfo.RestraintTypeId = null
    this.IncReportfrmInfo.CpiRestraintTypeId = null
    this.IncReportfrmInfo.IsCalled = null
    this.IncReportfrmInfo.IsDiscontinuingRestraint=null
    this.IncReportfrmInfo.IsInjured=null
    this.IncReportfrmInfo.Program=0
    this.IncReportfrmInfo.RTimeline=0
    this.IncReportfrmInfo.RestraintConditions[0].RestraintConditionId=0
    this.IncReportfrmInfo.TheraphyId=0
    this.IncReportfrmInfo.RestraintInAday=false
    this.IncReportfrmInfo.IncidentDate = this.IncReportfrmInfo.IncidentDateForIncident;
    this.IncReportfrmInfo.IncidentTime = this.IncReportfrmInfo.IncidentTimeForIncident;
  }
  this.IncReportfrmInfo.RestraintConditions.forEach(value => {
    if(value.RestraintAdminstrated == 1)
    {
      value.RestraintAdminstrated = true;
    }else{
      value.RestraintAdminstrated = false;
    }
    if(value.ProvisionOfWater == 1)
    {
      value.ProvisionOfWater = true;
    }else{
      value.ProvisionOfWater = false;
    }
  })
  this.spinnerservice.show()
  this.treatmentservice.PostIncidentReport(this.IncReportfrmInfo).subscribe(res=>{
    this.spinnerservice.hide()
    if(this.IsEdit){
    this.snackbar.success("Incident Report has been submitted successfully");
    }
    else{
      this.snackbar.success("Incident Report has been updated successfully");
    }
    this.OnBack();
  },
  err=>{
    this.spinnerservice.hide()
  this.snackbar.error("Not Implemented")

  })
}
openDialog1() {
  // this.global.isconsentesign = false;
  // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
  //   data: { isfutureSign: false, isParentsign: true },
  //   autoFocus: false 

  // });
  this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if (
      this.global.isconsentesign != undefined &&
      this.global.isconsentesign
    ) {
      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
      this.signatureImg = this.updatedsignatureImg;
      this.IncReportfrmInfo.StaffInitials = this.updatedsignatureImg;
     // this.IncReportfrmInfo.IsAttachEsign = true;
      (document.getElementById('esign_3') as HTMLImageElement).src =
        this.signatureImg;
    } else if (
      !this.global.isconsentesign &&
      this.global.formtype != null &&
      this.global.formtype != undefined
    ) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      this.IncReportfrmInfo.StaffInitials = this.global.consentesign;
      //this.IncReviewInfo.IsAttachEsign = true;
      (document.getElementById('esign_3') as HTMLImageElement).src =
        this.signatureImg;
    } else {
      this.signatureImg = this.defaultImage;
      //this.IncReportfrmInfo.IsAttachEsign = true;
      this.IncReportfrmInfo.StaffInitials = this.defaultImage;
    }
  });
}
secondarySign():any{
  if(this.ddlpatientList[0].licenseNumber != '' && this.ddlpatientList[0].licenseNumber != null ){
    return false;
  }
  else if(this.ddlpatientList[0].sSignature ){
   return false;
  }
  else{
    return true;
  }
}
getAutoFillSignature() {
  this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
      this.GetClinicianSign = res;

      if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
        this.AutoFillSS=false;
        this.SSignature=this.GetClinicianSign[0].clinicianSign;
      }
      else
      {
        this.AutoFillSS=true;
        this.SSignature='';
      }
  });
}
}


<div class="psycharist-progress">
  <div class="col-md-12">
    <div class="d-flex met-info-top w-100">
      <i  class="fas fa-user"></i>
      <div  class="mr-4">Client Name <span  class="imp-info">    {{_dtoPatientInfo.patientName }} </span></div>
      <div  class="mr-4">Psychiatrist Name <span  class="imp-info">    {{_dtoPatientInfo.clinicianName}} </span></div>
      <div  class="mr-4"> Appointment Date <span  class="imp-info">     {{_dtoPatientInfo.startDate | date:'MM/dd/YYYY'}} </span></div>
      <div  class="mr-4"> Appointment Time <span  class="imp-info">    {{_dtoPatientInfo.startTime | date:'hh:mm a'}}</span></div>
      
      
      <div  class="ml-auto">
        <button  type="button" (click)="OnbackClick()" mat-stroked-button class="mat-focus-indicator bg-white top-back mt-1 mb-2 h-40 mr-2 mat-stroked-button mat-button-base">Back</button>
      </div>
      </div>
  </div>
  <div class="col-md-12  mt-3">
    <div class="lead-box pt-2 pb-2">
      <div class="tab-history custom-tab-border mt-2">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item mr-1" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#progress" type="button" role="tab" aria-controls="s1" aria-selected="true" (click)="OnTabchange(1)"  [ngClass]="{'active show': SelectedTab == 1}" >Progress Note</button>
          </li>
          <li class="nav-item mr-1" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#goals" type="button" role="tab" aria-controls="s3" aria-selected="true" (click)="OnTabchange(2)"  [ngClass]="{'active show': SelectedTab == 2}" >Goals</button>
          </li>
          
          
        </ul>
        
        <div class="tab-content">
          <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==1" [ngClass]="{'disabled':progressUpg == true }">
            <form (ngSubmit)="PsyMedFrm.form.valid" id="PsyMedFrm" autocomplete="off" #PsyMedFrm="ngForm"
            novalidate>
           <div class="col-md-12 mt-3">
            <div  class="row">
              <div  class="d-flex w-100">
                <div  class="box-lead-head mb-2 ml-3">Psychiatric/Medication Management Form </div>
                <div  class="ml-auto mr-2">
                  <button  type="button" class="btn primary-btn-outlined-small mr-2 float-left" (click)="OnpreviewClick()">
                    <i class="far fa-eye mr-2"></i>Preview</button>
                    <button type="button" class="btn primary-btn-outlined-small mr-2 float-left" (click)="OnDownload(0)"><i class="fas fa-file-alt mr-2"></i>Generate Pdf</button>
                    <button  form="PsyMedFrm" type="submit" class="btn primary-btn-outlined-small ng-star-inserted float-left mr-2" (click)="PostPsyMedForm(1)" *ngIf="roleAccess.isCreate||roleAccess.isEdit">
                      <i  class="far fa-save mr-2"></i>{{IseditPsyMed?'Save':'Update'}}</button>
                      <button  class="export-pdf-btn btn"  mat-type="button" (click)="OnDownload(1)"><i class="fa fa-print"></i></button>

                    </div>
                  </div>
                </div>
              </div>
 
           
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
      <label  class="col-md-4 font-weight-bold "><span class="control-label-new">Client Name</span></label> 
      <div  class=" col-md-6 form-group" [ngClass]="{'disabled': true }">
       <input type="text" class="form-control" [(ngModel)]="_dtoPatientInfo.patientName"
       #patientName="ngModel" name="patientName"/>
      </div>
      </div>
      </div>
      <div class="col-md-6">
        <div class="row"  [ngClass]="{'disabled': true }">
       <label  class="col-md-3 font-weight-bold "><span class="control-label-new">Date of Birth</span></label>
        <div  class=" col-md-4 form-group" >
          <!--<input class="form-control" [(ngModel)]="PsyMedFrmInfo.dob " #dob="ngModel" name="dob" >-->
           <mat-form-field class="w-100 mt--14 custom-border-date" appearance="outline">
            
            <input matInput [matDatepicker]="dobpicker" [(ngModel)]="PsyMedFrmInfo.dob" #dob="ngModel" name="dob">            
            <mat-datepicker-toggle matSuffix [for]="dobpicker"></mat-datepicker-toggle>
            <mat-datepicker #dobpicker></mat-datepicker>
            
          </mat-form-field> 
        </div>
        </div>
        </div>
    </div>
    </div>
    <div class="col-md-12">
      <div class="row">
      <label  class="col-md-2 font-weight-bold "><span class="control-label-new">Psychiatrist Name</span></label> 
      <div  class=" col-md-3 form-group" [ngClass]="{'disabled': true }">
       <input type="text" class="form-control"  [(ngModel)]="_dtoPatientInfo.clinicianName"
       #clinicianName="ngModel" name="clinicianName"/>
      </div>
    </div>
    </div>
    <!--<div class="col-md-12 mt-3 mb-3">
      <div class="medication-dosage">
        <div  class="row inputElement">
          <div  class="col-md-3">
            <label >Medications</label>
          </div>
          <div  class="col-md-3">
            <label >Dosage</label>
          </div>
          <div  class="col-md-3">
            <label>Frequency</label>
          </div>
          <div  class="col-md-3">
            <label >Side effects</label>
          </div>
        </div>
        <div  class="loop-section position-relative mb-2 ">
          <div class="row inputElement">
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Medicine" type="text" required="" class="form-control "  >
            </div>
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Dosage" type="text" required="" class="form-control "  >
            </div>
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Frequency" type="text" required="" class="form-control "  >
            </div>
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Side effects" type="text" required="" class="form-control "  >
            </div>
          </div>
          <a  class="add " style="cursor: pointer;">
            <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
          </a>
          <a  class="remove" style="cursor: pointer;">
            <i  class="fa fa-lg fa-minus-circle"></i>
            <span  class="sr-only">Remove</span>
          </a>
        </div>
      </div>
    </div>-->
  
      <div class="medication col-md-12 mt-3 mb-3 ">
        <div class="medication-dosage">
        <div class="row inputElement">
          <div class="col-md-3"><label>Medications</label></div>
          <div class="col-md-3"><label>Dosage</label></div>
          <div class="col-md-3"><label>Frequency</label></div>
          <div class="col-md-3"><label>Side effects</label></div>
        </div>
        <div class="loop-section position-relative mb-2"
          *ngFor="let med of _dtoMedications;let i= index;let last=last">
          <div class="row  inputElement">
            <div class="col-md-3">
              <!-- <input class="form-control" maxlength="50" [(ngModel)]="med.medications" #medications="ngModel"
                name="medications{{i}}" placeholder="Medicine" type="text" (focusout)="PostPsyMedForm(2)"> -->
               <input class="form-control" maxlength="50" [(ngModel)]="med.medications" #medications="ngModel"
                name="medications{{i}}" placeholder="Medicine" type="text" >
            </div>
            <div class="col-md-3"><input class="form-control" maxlength="30" [(ngModel)]="med.dosage"
                #dosage="ngModel" name="dosage{{i}}" placeholder="Dosage" type="text" >
            
            </div>
            <div class="col-md-3">
              <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
              [(ngModel)]="med.frequency" #frequency="ngModel" name="frequency{{i}}">
                <!-- [(ngModel)]="med.frequency" #frequency="ngModel" name="frequency{{i}}" (change)="onSelectionChangedDosage()"> -->
                <option value="null">--Select Frequency--</option>
                <option value="1">Daily</option>
                <option value="2">Weekly</option>
                <option value="3">Monthly</option>
              </select>
            </div>
            <!--<div class="col-md-3">
              <input class="form-control" maxlength="50" [(ngModel)]="med.frequency" #medications="ngModel"
                name="frequency{{i}}" placeholder="Frequency" type="text" required>
            
            </div>-->
            <div class="col-md-3"><input class="form-control" maxlength="50" [(ngModel)]="med.sideEffects"
                #sideEffects="ngModel" name="sideEffects{{i}}" placeholder="Sideeffects" type="text" >
            </div>
          </div>
          <a class="add" style="cursor: pointer;" (click)="addMedicationInfo()"><i
              class="fa fa-lg fa-plus-circle"></i><span class="sr-only">Add</span></a>
          <a class="remove" style="cursor: pointer;" *ngIf="i!=0" (click)="removeclinicalinformation(med,i)"><i
              class="fa fa-lg fa-minus-circle"></i><span class="sr-only">Remove</span></a>
        </div>
      </div>
      </div>
  
    <div class="col-md-12">
      <label  class="control-label-new font-weight-bold ">Conversation details </label> 
      <div  class="form-group">
        <textarea  name="complaint" rows="3" maxlength="800" class="form-control " ng-reflect-name="txt_clinical" [(ngModel)]="PsyMedFrmInfo.conversationDetails"
        #conversationDetails="ngModel" name="conversationDetails" required [ngClass]="{ 'is-invalid': PsyMedFrm.submitted && conversationDetails.invalid }" ></textarea>
        <mat-error *ngIf="PsyMedFrm.submitted && conversationDetails.invalid">
          <mat-error class="text-xs" *ngIf="conversationDetails.errors.required">
            Conversation details is required</mat-error>
      </mat-error>
      </div>
    </div>
    <div  class="col-md-12">
      <div  class="form-group clearfix m-0">
        <div  class="float-left col-md-4 p-0 mt-3 pt-1">
          <label  class="treat-label mr-2">Staff Signature </label>
          <img  id="esign" height="50px" width="300px" src="{{PsyMedFrmInfo.staffInitial}}"style="border: 1px solid #d3c7c7 !important;">
          <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialog()" > Add E-Sign </button>
          <div class="float-left col-md-4 p-0 mt-4 pt-1" >
            <mat-error *ngIf="PsyMedFrm.submitted && PsyMedFrmInfo.staffInitial == undefined ">
              <mat-error class="mat-form-field-subscript-wrapper" *ngIf="PsyMedFrmInfo.staffInitial == undefined">
              Staff signtaure is required</mat-error>
          </mat-error>
               </div>
        </div>
        <div  class="float-right col-md-2 p-0 ">
          <mat-form-field class="w-100">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="PsyMedFrmInfo.staffSignDate" #staffSignDate="ngModel" name="staffSignDate">            
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="form-group clearfix m-0">
        <div class="float-left col-md-4 p-0 mt-3 pt-1">
          <label class="control-label-new font-weight-bold">Severity Level:</label>
          <mat-select #severity="ngModel" name="severity" [(ngModel)]="PsyMedFrmInfo.severity" [ngStyle]="{'color':bg}" (selectionChange)="onSelectionChanged($event.value)" placeholder="Select Severity">
            <!-- <mat-option [value]="0"> 0 - Discharged </mat-option>
            <mat-option [value]="1"> 1 - Mild </mat-option>
            <mat-option [value]="2"> 2 - Medium  </mat-option>
            <mat-option [value]="3"> 3 - Critical </mat-option> -->
            <mat-option *ngFor="let item of ddlseverity" [ngStyle]="{'color': item.colocode}"  [value]="item.severityValue">{{item.severityLable}}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>

    <div  class="col-md-12">
      <div  class="form-group clearfix m-0">
        <div  class="float-left col-md-4 p-0 mt-3 pt-1">
          <label  class="mr-2">Psychiatrist Signature</label>
          <img  id="esign_2" height="50px" width="300px" src="{{PsyMedFrmInfo.psychiatristInitials}}" style="border: 1px solid #d3c7c7 !important;">
          <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialogPsy()" > Add E-Sign </button>
          <div class="float-left col-md-10 p-0 mt-4 pt-1" >
            <mat-error *ngIf="PsyMedFrm.submitted && PsyMedFrmInfo.psychiatristInitials == undefined ">
              <mat-error class="mat-form-field-subscript-wrapper" *ngIf="PsyMedFrmInfo.psychiatristInitials == undefined">
                Psychiatrist Signtaure is required</mat-error>
          </mat-error>
               </div>
        </div>
        <div  class="float-left col-md-4 p-0 mt-3 pt-1">
          <label  class="mr-2">Secondary Signature</label>
          <img  id="esign_2" height="50px" width="300px" src="{{PsyMedFrmInfo.staffInitial1}}" style="border: 1px solid hsl(0, 12%, 80%) !important;">
          <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" [disabled]="secondarySign()" (click)="openDialogPsy1()" > Add E-Sign </button>
          <div class="float-left col-md-10 p-0 mt-4 pt-1" >
            <!-- <mat-error *ngIf="PsyMedFrm.submitted && PsyMedFrmInfo.psychiatristInitials == undefined ">
              <mat-error class="mat-form-field-subscript-wrapper" *ngIf="PsyMedFrmInfo.psychiatristInitials == undefined">
                Psychiatrist Signtaure is required</mat-error>
          </mat-error> -->
               </div>
        </div>
        <div  class="float-right col-md-2 p-0 ">
          <mat-form-field class="w-100">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="Psypicker"  [(ngModel)]="PsyMedFrmInfo.psySignDate" #psySignDate="ngModel" name="psySignDate" >
          
            <mat-datepicker-toggle matSuffix [for]="Psypicker"></mat-datepicker-toggle>
            <mat-datepicker #Psypicker></mat-datepicker>
           
          </mat-form-field>
        </div>
          </div>
        </div>
        </form>
      </div>
      <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==2" [ngClass]="{'disabled':progressUpg == true }">
        <div class="goal-page-indiv col-md-12 mt-3">
          <div class="col-md-12 p-0">
            <div class="d-flex w-100 mb-2">
              <div class="box-lead-head mb-2 ">Goals </div>
              <div class="ml-auto">
              </div>
            </div>
          </div>
          <div class="panel-group" id="accordion">
            <div *ngFor="let item of goalsinfo; let i=index">
            <div class="panel panel-default active" >
             
              <div class="panel-heading" >
                <h4 class="panel-title">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse{{i}}" class="collapsed">
                    <i class="fa fa-caret-right mr-2"></i>{{ item.goal }}

                  </a>
                  <!--<div class="goal-stat-chang float-right" >
                   <select class="form-control" [(ngModel)]="item.status"
                   #status="ngModel" name="status" (change)="OnstatusChange(item)">
                     <option [value]=2  >Inprogress</option>
                     <option [value]=3 > Completed</option>
                   </select>
                  </div>-->
                  
                </h4>
              </div>
              <div id="collapse{{i}}" class="panel-collapse collapse in" >
                <div class="panel-body">
                 <div class="col-md-12 ben-head">
                   <div class="row">
                     
                     <div class="col-md-8 ben_des">
                      <div class=" ben_tit">
                       {{ item.benchmarkTitle }}
                      </div>
                     {{item.benchmarkDescription}}
                    </div>
                    <div class="col-md-2">
                      <div class="goal-stat-chang mt-3">
                          <span class="status-tabl inprogress" *ngIf="item.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                          <span class="status-tabl completed" *ngIf="item.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                      </div>
                  </div>
                    <div class="col-md-2 ben_unit">
                      <p class="text-muted text-xs">Unit of Measure</p>
                      {{item.benchmarkunit}}
                    </div>
                   </div>
                 </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        
        </div>
     
     
         </div>
    </div>
    </div>
    </div>
  </div>
     <!--Preview popup-->
     <div class="Activity-popup">
      <div class="modal"  tabindex="-1" role="dialog" [style.display]="PopUpPreview? 'block':'none'" >
         <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h6 class="modal-title">Preview</h6>
                  <button type="button" class="close"  data-dismiss="modal" (click)="OnclosePopup()"
                  aria-label="Close" >
                  <span aria-hidden="true">&times;</span>
               </button>
               </div>
               <div class="modal-body">
                <div class="individual-threaphy-container">
                <div  class="history-list col-md-12 p-0">
                  <div class="row">
                    <div class="d-flex col-md-12">
                      <button class="ml-auto btn-print-hover mb-3 mr-2" mat-type="button" (click)="print()"><i class="fa fa-print"></i></button>
                    </div>
                    
                  <div id="print-section" class="col-md-12"> 

                    <div class="page-header" style=" text-align: center; font-size: 15px; font-weight: 500; margin-bottom: 25px; color: #173a64;">PSYCHIATRIC/MEDICATION MANAGEMENT FORM </div>

                  <div class="row top-details-psych">
                    <div class="col-md-3"><label>Client Name</label>
                    <p>{{_dtoPatientInfo.patientName }}</p></div>
                    <div class="col-md-3"><label>Date of Birth</label>
                      <p>{{PsyMedFrmInfo.dob | date:'MM/dd/YYYY'}}</p></div>
                    <div class="col-md-3"><label>Psychiatrist Name</label>
                      <p>{{_dtoPatientInfo.clinicianName}}</p></div>
                      <div class="col-md-3"><label> Appointment  Date</label>
                        <p>{{_dtoPatientInfo.startDate | date:'MM/dd/YYYY'}}</p></div>
                        <!--<div class="col-md-3"><label> Start Time</label>
                          <p>{{_dtoPatientInfo.startTime | date:'hh:mm a'}}</p></div>-->
                        
                   </div>
                   <div class="row">
                    <div class="col-md-12 mb-2  ">
                    <div class="medication-dosage">
                      <div class="row inputElement">
                        <div class="col-md-3"><label>Medications</label></div>
                        <div class="col-md-3"><label>Dosage</label></div>
                        <div class="col-md-3"><label>Frequency</label></div>
                        <div class="col-md-3"><label>Side effects</label></div>
                      </div>
                      <div class="loop-section position-relative mb-2"
                        *ngFor="let med of _dtoMedications;let i= index;let last=last">
                        <div class="row  inputElement">
                          <div class="col-md-3">    
                            <div class="cli_value_family">
                              <span>{{med.medications}}</span>
                            </div>
                          </div>
                          <div class="col-md-3">
                          <div class="cli_value_family">
                            <span>{{med.dosage}}</span>
                          </div>
                          </div>
                          <div class="col-md-3">
                            <div class="cli_value_family">
                            <span>{{med.frequency == 1 ? 'Daily' :med.frequency == 2 ? 'Weekly' :med.frequency==3? 'Monthly':''
                            }}</span>
                            </div>
                          </div>
                          <!--<div class="col-md-3">
                            <input class="form-control" maxlength="50" [(ngModel)]="med.frequency" #medications="ngModel"
                              name="frequency{{i}}" placeholder="Frequency" type="text" required>
                          
                          </div>-->
                          <div class="col-md-3">
                            <div class="cli_value_family">
                              <span>{{med.sideEffects}}</span>
                            </div>
                          </div>
                        </div>
                   </div>
                   </div>
                   </div>
                   </div>
                   <div class="row" >
                    <div class="col-md-12 mb-2  ">
                       <label class="control-label-new font-weight-bold" >Conversation details </label>
                   <div class="cli_value_family">
                    <span>{{PsyMedFrmInfo.conversationDetails}}</span>
                  </div>
                </div>
              </div>
               
                      <div class="row" >
                        <div class="col-md-12 mb-2  ">
                         <label class="control-label-new font-weight-bold" >Staff Signtaure</label>
                        <div class="cli_value_family">
                        <span><img  id="esign_prev" height="50px" width="300px" src="{{PsyMedFrmInfo.staffInitial}}" /></span>
                        </div>
                        </div>
                        </div>
                        <div class="row" >
                          <div class="col-md-12 mb-2  ">
                           <label class="control-label-new font-weight-bold" >Psychiatrist Signtaure</label>
                          <div class="cli_value_family">
                          <span><img  id="esign_prev" height="50px" width="300px" src="{{PsyMedFrmInfo.psychiatristInitials}}" /></span>
                          </div>
                          </div>
                          </div>
                          </div>
            </div> 
    
            </div> 
                 </div>
            </div>
          </div>
        </div>
      </div>  
     
       <!--End Preview popup-->
</div> 

<a mimetype="application/octet-stream" #downloadLink></a>
 

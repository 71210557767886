
// export class IndvFamTherphyPtInfo{
//     startDate;
//     endDate;
//     startTime;
//     endTime;
//     dOB;
//     duration;
//     patientName;
//     clinicianName;
//     patientAccountId;
//     patientInfoId;
//     individualfamilyTheraphyCIMaster:Array<IndividualFamilyTheraphyCIMaster>;
//     indfamTheraphyOutcomeNotes:IndFamTheraphyOutcomeNotes;
//     indfamTheraphyOutcomeChecklist:Array<IndFamTheraphyOutcomeChecklist>;
     
// }
// export class IndividualFamilyTheraphyCIMaster{
//     id;
//     clinicalIssueGroup;
//     clinicalIssues;
//     theraphyId;
//     isDeleted;
//     orderSeq;
//     clinicalIssueGroupId;
// }

export class IndFamTheraphyOutcomeNotes{

    indvfamTheraphyNotesId;
     theraphyId;
     clientResponse;
     followupPlan;
    patientAccountId;
    createdBy;
     createdDate ;
     updatedBy;
      updatedDate;
    isDeleted;
    presentingProblems;
     }
    // export class IndFamTheraphyOutcomeChecklist{

    //     indvfamTheraphyChkLstId;
    //     clinicalIssueGroupId;
    //     clinicalIssuesId;
    //      isClinicalIssues;
    //     theraphyId;
    //    patientAccountId;
    //     createdBy;
    //      createdDate ;
    //      updatedBy;
    //       updatedDate;
    //     isDeleted;
    //     }

import { Injectable, DebugElement } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from '../common/common.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private http: HttpClient, public commonService: CommonService) { }

    getAllNotification(userId,roleId) {
        return this.http.get(environment.BaseInAppURI + 'api/Notifications/notificationresult?RoleId='+roleId+'&UserId='+userId);
      
    }
    posttriggernotification(data) {
        const headers = new HttpHeaders().set(
            'Content-Type',
            'application/json; charset=utf-8'
        );
        return this.http.post(environment.BaseInAppURI + 'api/Notifications/SaveNotification',
            data, { headers: headers, responseType: 'text' })
            .pipe(map(function (response) { return response; }));

    }
    postMarkRead(data){
        const headers = new HttpHeaders().set(
            'Content-Type',
            'application/json; charset=utf-8'
        );
        return this.http.post(environment.BaseInAppURI + 'api/Notifications/MarkAsRead',
            data, { headers: headers, responseType: 'text' })
            .pipe(map(function (response) { return response; }));
    }
    postClearNotification(data){
        const headers = new HttpHeaders().set(
            'Content-Type',
            'application/json; charset=utf-8'
        );
        return this.http.post(environment.BaseInAppURI + 'api/Notifications/ClearAll',
            data, { headers: headers, responseType: 'text' })
            .pipe(map(function (response) { return response; }));
    }
    getViewNotification(obj) {
        return this.http.get(environment.BaseInAppURI + 'api/Notifications/GetAllNotification?FromDate='+obj.FromDate+'&ToDate='+obj.ToDate+'&RoleId='+obj.RoleId+'&UserId='+obj.UserId);
      
    }
}
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { S } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common/common.service';
import { constantVariables } from '../constant/constant';
import { Global } from '../global/global.model';
import { PageAccessService } from '../rolemanagement/PageRoleAccessService';
import { SnackBarService } from '../services/snackBar.service';
import { ClinicianService } from '../staff/clinician/clinician.service';
import { TreatmentService } from '../staff/treatment/treatment.service';

@Component({
  selector: 'app-dailyscheduler',
  templateUrl: './dailyscheduler.component.html',
  styleUrls: ['./dailyscheduler.component.scss']
})
export class DailyschedulerComponent implements OnInit {
  grpTherapyId: any;
  _dtoGrpTherapyMeta: any;
  _dtoGrpTherapyUsers: any;
  _dtoAssignedUsers:any;
  _dtoGroupMeetingTherapyMaster:any;
  //Drag and Drop list
  _dtoDropListCount:any=['1','2','3','4'];
  _dtoGroupTherapyDetails:any;
  allClinicians:any;  
  _dtoOutputList:any[]=[];
  isFormSubmitted:boolean=false;

  //Time and Date comparison variable
  groupTherapyStartTime:any;
  groupTherapyEndTime:any;
  groupTherapyStartTimeHrs:any;
  groupTherapyEndTimeHrs:any;
  clientType:any;
  typeSelected:any;
  scheduleDate:string;

  constructor(public global: Global,
    private snackbar: SnackBarService,
    private route: ActivatedRoute,
    private router: Router,
    public commonService: CommonService,
    public clinicianservice: ClinicianService,
    public spinnerservice: NgxSpinnerService,
    public treatmentservice: TreatmentService,
    private date: DatePipe,
    private dialog: MatDialog,
    public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        if (params.GroupTherapyId) {
          this.grpTherapyId = this.commonService.FrontEndDecryption(params.GroupTherapyId).replace(/"/g, "");
        }
        if (params.clientType) {
          this.clientType = this.commonService.FrontEndDecryption(params.clientType).replace(/"/g, "");
        }
        if (params.scheduleDate) {
          this.scheduleDate = this.commonService.FrontEndDecryption(params.scheduleDate).replace(/"/g, '');
        }
      }
    });
    if(this.clientType == 1)
    {
      this.typeSelected = "IOP"
    }else if(this.clientType == 2){
      this.typeSelected = "PCP"
    }
    this.getGroupTherapyDetails();
    this.getPrimaryStaff();
    this.getDayScheduleForAssign();
    this.getGropuMeetingTherapyMaster();
    this.initArray();
   
  }
  initArray(){
    for(let i=0;i<this._dtoDropListCount.length-1;i++){
      this._dtoOutputList[i]=[]
      this._dtoOutputList[i].dropList=[]
      this._dtoOutputList[i].clinician=[]
      this._dtoOutputList[i].leader=0
      this._dtoOutputList[i].selectedTherapy=[]
      this._dtoOutputList[i].startTime=''
      this._dtoOutputList[i].endTime=''
      this._dtoOutputList[i].startTimeError=false
      this._dtoOutputList[i].endTimeError=false
      this._dtoOutputList[i].leaderError=false
      this._dtoOutputList[i].dropListError=false
      this._dtoOutputList[i].therapyError=false
      this._dtoOutputList[i].clinicianError=false
    }
  }
  getDayScheduleForAssign() {
    this.spinnerservice.show();
     this.treatmentservice
      .getDayScheduleForAssignClinician(this.grpTherapyId)
      .subscribe((res: any)  => {
        this.spinnerservice.hide();
          if(res.objInputString!=null)
           {
        let fres=this.commonService.decrypdata(res.objInputString);
       this._dtoGrpTherapyMeta = fres[0];
       this._dtoGrpTherapyUsers = fres;
           }
          },
           err => {
            this.spinnerservice.hide();
      });
  }
  getGroupTherapyDetails(){
    this.treatmentservice
    .getGroupMeetingDetails(this.grpTherapyId)
    .subscribe((res: any)  => {
      this._dtoGroupTherapyDetails=res;
      this.groupTherapyEndTime=this._dtoGroupTherapyDetails.endTime;
      this.groupTherapyStartTime=this._dtoGroupTherapyDetails.startTime;
      this.groupTherapyStartTimeHrs=moment(this.groupTherapyStartTime).format("HH:mm");
      this.groupTherapyEndTimeHrs=moment(this.groupTherapyEndTime).format("HH:mm");      
    });
  }

  drop(event: CdkDragDrop<string[]>) {    
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this._dtoOutputList.map(group=>{
      if(group.dropList.length == 0){
        group.dropListError=true;
      }else{
        group.dropListError=false;
      }
    });
  }
  getPrimaryStaff() {
    this.commonService
      .getStaffList(constantVariables.role.PrimaryClinician)
      .subscribe((res: any) => {
        if (res) {
          this.allClinicians=res;
        }
      });
  }
  clinicianChange(i,item){
     //var clinicianIds=this._dtoOutputList[i-1].clinician.map(x=>{return x.staffId});
   // var clinicianIds=this._dtoOutputList[i-1].clinician;
    this.commonService.getStaffInternList(item.staffId).subscribe((res:any)=>{
      console.log(res);
    })
   // console.log(clinicianIds);
  }
  getGropuMeetingTherapyMaster(){
    this.commonService
    .getGroupMeetingTherapyMaster()
    .subscribe((res: any) => {
      if(res){
        this._dtoGroupMeetingTherapyMaster=res;        
      }      
    })
  }
  onClinicianChange(item,id){
      this._dtoOutputList[id] = this._dtoOutputList[id].clinician; 
  }
  checkConflictsWithClinician(){
  //   var offset = new Date().getTimezoneOffset();
  // this.scheduleModalityList = [];
  // this.dtoScheduleConflictModalitylist = []
  // let starttime = moment(this.dtoScheduleModality.startTime).format("HH:mm");
  // let _obj = {
  //   StartDate: moment.utc(this.dtoScheduleModality.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
  //   EndDate: moment.utc(this.dtoScheduleModality.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
  //   FromTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),
  //   ToTime: moment(starttime,'HH:mm').add(((Number(this.durationhr)+Number(this.durationmin))), 'minute').format('HH:mm A'),
  //   Provider: this.dtoScheduleModality.provider
  // }
  // this.spinnerservice.show();
  // const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
  //   this.treatmentservice.getConfilctsForModalityScheduler(dataToEncrypt).subscribe(
  //     (res: any) => {
  //       this.dtoScheduleConflictModalitylist = JSON.parse(res);
  //       this.spinnerservice.hide();
  //       if(this.dtoScheduleConflictModalitylist.length==0){
  //         this.scheduleModalityList =[]
  //         this.scheduleModalityList.push({
  //           // day: data[i].day,
  //           scheduleDate: moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY'),
  //           isChecked: false,
  //           fromTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),
  //           toTime:moment(starttime,'HH:mm').add(((Number(this.durationhr)+Number(this.durationmin))), 'minute').format('HH:mm A'),
  //           isConflict: false})
  //           if(this.isBlockTime){
  //             this.postBlockTime();
  //           }else{
  //             this.postSchduleModality();
  //           }
          
  //       }
  //       else{
  //         this.dateList = [];
  //       //  this.dateList = this.getDaysBetweenDates(moment(this.dtoScheduleModality.startDate).utcOffset(-offset), moment(this.dtoScheduleModality.endDate).utcOffset(-offset));
  //         this.onBetweenappointment(this.dtoScheduleConflictModalitylist);
  //         this.skipAllConflicts();
  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  
  //     },
  //   );
  }
  postScheduleGroup(){
    console.log(this._dtoOutputList);
    this.isFormSubmitted=true;
    //validations
    var errorCount=0;
    this._dtoOutputList.map(group=>{
      if(group.clinician.length == 0 || group.dropList.length == 0 || group.selectedTherapy.length == 0 || !group.leader || group.startTime=='' || group.endTime==''){        
        if(group.startTime==''){
          group.startTimeError=true;
          group.startTimeErrorMessage="Start time is required"                      
        }else if(group.startTime!=''){
          var TherapyStartTime = new Date(
            'May 26, 2016 ' + group.startTime
          ).getTime();
          var TherapyEndTime = new Date(
            'May 26, 2016 ' + group.endTime
          ).getTime();
          var TherapyMasterStartTime = new Date(
            'May 26, 2016 ' + this.groupTherapyStartTimeHrs
          ).getTime();
          var TherapyMasterEndTime = new Date(
            'May 26, 2016 ' + this.groupTherapyEndTimeHrs
          ).getTime();

          if(TherapyStartTime>TherapyEndTime){
            group.startTimeError=true;
            group.startTimeErrorMessage="Start time is more than end time"          
          }else if(TherapyStartTime<TherapyMasterStartTime){
            group.startTimeError=true;
            group.startTimeErrorMessage="Start time is less than Therapy Start time"                      
          }else if(TherapyStartTime>TherapyMasterEndTime){
            group.startTimeError=true;
            group.startTimeErrorMessage="Start time is more than Therapy End time"                      
          }
        }
        if(group.endTime==''){
          group.endTimeError=true
          group.endTimeErrorMessage="End time is required"                      
        }
        else if(group.endTime!=''){
          var TherapyStartTime = new Date(
            'May 26, 2016 ' + group.startTime
          ).getTime();
          var TherapyEndTime = new Date(
            'May 26, 2016 ' + group.endTime
          ).getTime();
          var TherapyMasterStartTime = new Date(
            'May 26, 2016 ' + this.groupTherapyStartTimeHrs
          ).getTime();
          var TherapyMasterEndTime = new Date(
            'May 26, 2016 ' + this.groupTherapyEndTimeHrs
          ).getTime();
          if(TherapyStartTime==TherapyEndTime){
            group.endTimeError=true
            group.endTimeErrorMessage="Start time and End time should't be same"           
          }else if(TherapyEndTime>TherapyMasterEndTime){
            group.endTimeError=true
            group.endTimeErrorMessage="End time is more than Therapy End time"                   
          }else if(TherapyEndTime<TherapyStartTime){
            group.endTimeError=true
            group.endTimeErrorMessage="End Time is less that Start time"                               
          }else if(TherapyEndTime<TherapyMasterStartTime){
            group.endTimeError=true
            group.endTimeErrorMessage="End Time is less that Therapy Start time"                   
          }
        }
        if(group.clinician.length == 0){
          group.clinicianError=true;
        }else{
          this.checkConflictsWithClinician();
        }
        if(group.dropList.length == 0){
          group.dropListError=true;
        }
        if(group.selectedTherapy.length == 0){
          group.therapyError=true;
        }
        if(!group.leader){
          group.leaderError=true
        }
        errorCount++;
      }
    })
    if(errorCount!=0){
      return
    }


    var clinician=[];
    var patients=[];
    var dtoPost=[];
    this._dtoOutputList.map((x,index)=>{
      clinician.push(
      //   x.clinician.map(y=>{
      //   return y.staffId;
      // })
      +x.clinician.staffId
      )  
      patients.push(x.dropList.map(y=>{
        var obj={
          groupTherapyAssignId:y.GroupTherapyAssignId,
          groupTherapyId:y.GroupTherapyId,
          patientAccountId:y.PatientAccountId,
          patientInfoId:y.PatientInfoId
        }
        return obj;
      }))  
      var _obj={
        clinician:clinician[index].toString(),
        patients:patients[index],
        leader:x.leader,
        therapy:x.selectedTherapy.toString(),
        startTime:x.startTime,
        endTime:x.endTime
      }
      dtoPost.push(_obj);
    })
    return;
    console.log(dtoPost); 
    this.commonService.PostGroupMeeting(dtoPost).subscribe((res)=>{
      console.log(res);      
    })   
  }
  onMultiChange(item,id){
    if(id==1){
      if(item.clinician){
        item.clinicianError=false
      }else{
        item.clinicianError=true
      }
    }else if(id==2){
      if(item.selectedTherapy.length==0){
        item.therapyError=true;
      }else{
        item.therapyError=false;
      }
    }else if(id==3){
      if(item.leader==0){
        item.leaderError=true;
      }else{
        item.leaderError=false;
      }
    }
  }
  onTimeChange(item,id){
    if(id==1){
      var TherapyStartTime = new Date(
        'May 26, 2016 ' + item.startTime
      ).getTime();
      var TherapyEndTime = new Date(
        'May 26, 2016 ' + item.endTime
      ).getTime();
      var TherapyMasterStartTime = new Date(
        'May 26, 2016 ' + this.groupTherapyStartTimeHrs
      ).getTime();
      var TherapyMasterEndTime = new Date(
        'May 26, 2016 ' + this.groupTherapyEndTimeHrs
      ).getTime();
      if(item.endTime==''){
        if(TherapyStartTime>TherapyMasterStartTime&&TherapyStartTime<TherapyMasterEndTime){
          item.startTimeError=false;
        }
        return;
      }
      if(TherapyStartTime>TherapyEndTime){
        item.startTimeErrorMessage="Start time is more than end time"          
      }else if(TherapyStartTime<TherapyMasterStartTime){
        item.startTimeErrorMessage="Start time is less than Therapy Start time"                      
      }else if(TherapyStartTime>TherapyMasterEndTime){
        item.startTimeErrorMessage="Start time is more than Therapy End time"                      
      }else{
        item.startTimeError=false;
      }
    }
    if(id==2){
    var TherapyStartTime = new Date(
      'May 26, 2016 ' + item.startTime
    ).getTime();
    var TherapyEndTime = new Date(
      'May 26, 2016 ' + item.endTime
    ).getTime();
    var TherapyMasterStartTime = new Date(
      'May 26, 2016 ' + this.groupTherapyStartTimeHrs
    ).getTime();
    var TherapyMasterEndTime = new Date(
      'May 26, 2016 ' + this.groupTherapyEndTimeHrs
    ).getTime();
    if(TherapyStartTime==TherapyEndTime){
      item.endTimeErrorMessage="Start time and End time should't be same"           
    }else if(TherapyEndTime>TherapyMasterEndTime){
      item.endTimeErrorMessage="End time is more than Therapy End time"                   
    }else if(TherapyEndTime<TherapyStartTime){
      item.endTimeErrorMessage="End Time is less that Start time"                               
    }else if(TherapyEndTime<TherapyMasterStartTime){
      item.endTimeErrorMessage="End Time is less that Therapy Start time"                   
    }else{
      item.endTimeError=false;
    }
  }
  }
  arrayFunction(id){
    return this._dtoDropListCount.filter((e,i) => i !== id);
  }
  createGroup(){
    if(this._dtoDropListCount.length<=5){
      var x=this._dtoDropListCount.length;      
      this._dtoDropListCount.push((x+1).toString())
      this._dtoOutputList[x-1]=[]
      this._dtoOutputList[x-1].dropList=[]
      this._dtoOutputList[x-1].clinician=[]
      this._dtoOutputList[x-1].leader=0
      this._dtoOutputList[x-1].selectedTherapy=[]
      this._dtoOutputList[x-1].leaderError=false
      this._dtoOutputList[x-1].dropListError=false
      this._dtoOutputList[x-1].therapyError=false
      this._dtoOutputList[x-1].clinicianError=false
      this._dtoOutputList[x-1].startTime=''
      this._dtoOutputList[x-1].endTime=''
      this._dtoOutputList[x-1].startTimeError=false
      this._dtoOutputList[x-1].endTimeError=false
    }
  }
}

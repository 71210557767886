import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PatientService } from 'src/app/patient/patient.service';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { IntakegenerateService } from 'src/app/patient/intake/intake-generate/intakegenerate.service';
//import { DtoPatientOutofPacketInvoicePdf } from 'src/app/_models/_Billing/dtoPatientOutofPacketDetails';
import { DtoPatientOutofPacketInvoicePdf } from 'src/app/_models/_Billing/dtoPatientOutofPacketDetails';
import { NgForm } from '@angular/forms';
import { ShareFiles, DocumentPdf, ShareDocuments } from 'src/app/_models/_Common/_document';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { cloneDeep } from "lodash";
import { TemplateService } from '../template/template.service';
import { timer } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';

@Component({
  selector: 'app-patient-documents',
  templateUrl: './patient-documents.component.html',
  styleUrls: ['./patient-documents.component.scss']
})
export class PatientDocumentsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('anawrapper') mainpage: ElementRef;
  @ViewChild('ShareDocument') ShareDocument: NgForm;
  @ViewChild('attachments') attachment: any;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['patientName', 'status', 'clinicianName'];
  public searchpatientListtxt: string;
  PatientList: any = [];
  lstPatient: any = [];
  SelectedStatus: any = 0
  pdfNutritionfile: any;
  patientAccountId: any;
  patientInfoId: any;
  documentType: any;
  pdfPatientInformationIntake: any;
  patientName: any;
  isPainAssesment: boolean = false;
  isSucidalRisk: boolean = false;
  lstDocuments: any;
  pdfSuicdescreener: any;
  isIntakeDocuments: boolean = true;
  isTreatmentDocument: boolean = false;
  isAppointmentDocument: boolean = false;
  isSusidalRiskDocument: boolean = false;
  isSusidalRiskDocumentExist: boolean = false;
  isFinancial: boolean = false;
  isOnboarding: boolean = false;
  appoinmentDocumentList: any;
  treatmentPlanDocumentList : any;
  pdfFamIndNotes: any;
  pdfpsymedNotes: any;
  pdffile: any;
  pdfpsytherapynotes: any;
  isAppointmentDocumentExists1: boolean = false;
  isIntakePacket: boolean = false;
  isNutritionalQuestion: boolean = false;
  isChma: boolean = false;
  noDocuments: boolean = false;
  DtoPatientOutofPacketInvoicePdf: DtoPatientOutofPacketInvoicePdf;
  dtoPatientOutofPacketInvoicePdf;
  clickedItem: any;
  pdfInvoice: any;
  financialDocumentList: any;
  dtoPaymentActiveList: any;
  totalpending: any;
  Userlists: any;
  Opendocumentsection: boolean;
  programname: any;
  serialno = 0;
  createdby: any;
  isFinancialExists1: boolean = false;
  intakedocumentList: any;
  onboardingDocumentList: any;
  isOnboardingExists1: boolean;
  financialList: any;
  isFinancialDocumentExists1: boolean;
  onBoardingDocument: any;
  OnFinancial:any;
  financialProposalfile: any;
  isCommunicationDocumentExists: boolean = false;
  communicationDocumentList: any;
  isCommunication: boolean = false;
  isCommunicationExist: boolean = false;
  isShare: boolean = false;
  shareDetails: any;
  email: any;
  ShareFiles = new ShareFiles();
  shareDocuments: any;
  isShareDocument: boolean = false;
  toEmail: any;
  clickedTab: any;
  isShareAll: boolean = true;
  shareAllIntake: any;
  documentTypeForShare: any;
  isUploadedDocumentExists:boolean=false;
  uploadedDocumentList: any = [];
  isUploaded:boolean=false;
  uploadedDocumentfile: any;
  roleAccess: any;
  isTreatmentDocumentExists:boolean = false;
  isIntakeDocumentExists:boolean = false;
  isConsentExists = false;
  isConsent = false;
  lstIntakeConsent : any;
  base64StringConvert : any;
  CalculatePercentage: any;
  consentCompleted: any;
  pdfConsentpreview : any;
  ShowDocuments: boolean = false;
  fileList: File[] = [];
  listOfFiles: any[] = [];
  SelectedPatientInfoForFile: any;
  filesize:any;
  filecount:any;
  ddlDocumentType : any;
  documentModuleTypeId : number = 0;
  isRequired : boolean = false;
  newPatientAccountId : number = 0;
  painAssesmentDocument : any;
  nutritionalQuestionnaire : any;
  intakePacket : any;
  intakeUpdate : any;
  pdfPainAssesment : any;
  suicideAssessment : any;
  isShowPopupForDownload:boolean = false;
  isShowPopupForDownloadPrevious:boolean = false;

  isClear:boolean=false;
  painAssessmentId:any;
 painAssessment : any;
  uploadDocuments: any = [];

  constructor(public service: IntakegenerateService, public patientservice: PatientService, public spinnerservice: NgxSpinnerService, private snackbar: SnackBarService,public roleaccessservice:PageAccessService,
    public commonService: CommonService, public treatmentservice: TreatmentService, public clinicianservice: ClinicianService, private _location: Location, private router: Router, private route: ActivatedRoute,public tempService: TemplateService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Documents;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        this.patientAccountId = this.commonService.FrontEndDecryption(params.id).replace(/"/g, "");
        this.patientName = params.patientName;
      }
      this.dtoPatientOutofPacketInvoicePdf = new DtoPatientOutofPacketInvoicePdf();
      this.shareDocuments = new ShareDocuments();
      this.checkDocumentExists();
      this.getAppointmentDocumentList();
     

    })
    this.newPatientAccountId =Number(localStorage.getItem("PatientAccountIddoc"));

    this.getpatientViewList();
    this.getDocumentModuleType();

  }
  getDocumentModuleType()
  {
    this.tempService.getDocumentModuleType().subscribe(
      res => {
        if (res != null) {
          this.ddlDocumentType = res;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }
  getpatientViewList() {
    this.spinnerservice.show();

    let locationid = Number(localStorage.getItem('sfcclocation')) == 0 ? -1 : Number(localStorage.getItem('sfcclocation'));
    this.patientservice.getPatientViewList(locationid, 0, 0).subscribe(
      (res: any) => {
        this.spinnerservice.hide();

        this.PatientList = this.commonService.decrypdata(res.objInputString);
        console.log(this.PatientList);

        if(this.newPatientAccountId>0)
          {
            this.PatientList = this.PatientList.slice(0,5).filter(x => x.PatientAccountId == this.newPatientAccountId);

            this.lstPatient = this.PatientList.slice(0, 5).filter(x => x.PatientAccountId == this.newPatientAccountId);
           localStorage.setItem("PatientAccountIddoc","0");
          }
          else
          {
            this.lstPatient = this.PatientList.slice(0, 5);
          }

        //this.dataSource = new MatTableDataSource(this.PatientList);
        //this.dataSource.paginator = this.paginator;
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  OnSearchPatient() {
    let tempPatientlist = this.PatientList;
    if (this.searchpatientListtxt != "" || this.searchpatientListtxt === undefined || this.searchpatientListtxt === null) {
      this.lstPatient = tempPatientlist.filter(
        (x: any) => 
      x.FirstName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
      x.LastName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) );
    }
    else {
      this.lstPatient = tempPatientlist.slice(0, 5);
    }
  }
  onClickToDocument(data) {
    this.router.navigate(['/patientdocuments-details'], { queryParams: { id: this.commonService.FrontEndEncryption(data.PatientAccountId), patientName: data.FirstName + " " + data.LastName } });
  }
  OnlistFilter(item) {
    if (this.SelectedStatus != 0 && this.SelectedStatus != 5) {
      this.lstPatient = this.PatientList.filter(x => x.PatientStatusId == this.SelectedStatus)
    } else if (this.SelectedStatus == 5) {
      this.lstPatient = this.PatientList.filter(x => x.IsDischarged == 1)
    }
    else {
      this.lstPatient = this.PatientList;

    }
    this.dataSource = new MatTableDataSource(this.lstPatient);
    this.dataSource.paginator = this.paginator;
  }
  userFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  onPageChange($event) {
    this.lstPatient = this.PatientList.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
    //  debugger;OnPatientDashboard
    // this.PageIndx=$event.pageIndex==0?1:$event.pageIndex;
    // this.PageSz=$event.pageSize;
    //this.getpatientlistView();
  }

  checkDocumentExists() {
    this.spinnerservice.show();
    this.patientservice.checkDocumentExists(this.patientAccountId,this.patientInfoId).subscribe(
      (res: any) => {
        if (res != null) {
          this.spinnerservice.hide();
          console.log(res);
          this.isIntakePacket = res.isIntakePacket;
          this.isPainAssesment = res.isPainAssesment;
          this.isSucidalRisk = res.isSucidalRisk;
          this.isNutritionalQuestion = res.isNutritionalQuestion;
          this.isChma = res.isChma;
          this.lstDocuments = res.dtoSpSuicideDocuments;
          this.intakedocumentList = res.intakeDocuments;
          this.painAssesmentDocument = res.painAssesmentDocument;
          this.nutritionalQuestionnaire = res.nutritionalQuestionnaire;
          this.intakePacket = res.IntakePacket;
          this.intakeUpdate = res.intakeUpdate;
          this.suicideAssessment = res.suicideAssessment;
          if(this.lstDocuments!=null && this.lstDocuments.length>0)
         { 
          this.createdby = this.lstDocuments[0].createdby;
         }
         else
         {
          this.createdby = "";
         }

          if(res.intakeDocuments.length >0)
          {
            this.isIntakeDocumentExists = true;
          }
          this.onBoardingDocument = res.onBoardingDocument;
          this.isFinancial = res.isFinancial;
          this.OnFinancial=res.onFinancialDocument
          this.isOnboarding = res.isOnboarding;
          // if (!this.isIntakePacket && !this.isPainAssesment && !this.isSucidalRisk && !this.isNutritionalQuestion && !this.isChma && this.lstDocuments == null && !this.onBoardingDocument) {
          //   this.noDocuments = true;
          // }
          this.ActiveTab();
          this.NoDocumentExist();
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );

  }
  ActiveTab(){
    if(this.isIntakeDocumentExists)
    {
      this.isIntakeDocuments = true;
      this.isTreatmentDocument = false;
      this.isAppointmentDocument = false;
      this.isSusidalRiskDocumentExist = false;
      this.isFinancialExists1 = false;
      this.isOnboardingExists1 = false;
      this.isCommunicationExist = false;
      this.isUploaded = false;
      this.isConsentExists = false;

    }else if(this.isTreatmentDocumentExists){
      this.isIntakeDocuments = false;
      this.isTreatmentDocument = true;
      this.isAppointmentDocument = false;
      this.isSusidalRiskDocumentExist = false;
      this.isFinancialExists1 = false;
      this.isOnboardingExists1 = false;
      this.isCommunicationExist = false;
      this.isUploaded = false;
      this.isConsentExists = false;

    }else if(this.isSucidalRisk){
      this.isIntakeDocuments = false;
      this.isTreatmentDocument = false;
      this.isAppointmentDocument = false;
      this.isSusidalRiskDocumentExist = true;
      this.isFinancialExists1 = false;
      this.isOnboardingExists1 = false;
      this.isCommunicationExist = false;
      this.isUploaded = false;
      this.isConsentExists = false;

    }else if(this.isAppointmentDocumentExists1){
      this.isIntakeDocuments = false;
      this.isTreatmentDocument = false;
      this.isAppointmentDocument = true;
      this.isSusidalRiskDocumentExist = false;
      this.isFinancialExists1 = false;
      this.isOnboardingExists1 = false;
      this.isCommunicationExist = false;
      this.isUploaded = false;
      this.isConsentExists = false;

    }else if(this.isOnboarding){
      this.isIntakeDocuments = false;
      this.isTreatmentDocument = false;
      this.isAppointmentDocument = false;
      this.isSusidalRiskDocumentExist = false;
      this.isFinancialExists1 = false;
      this.isOnboardingExists1 = true;
      this.isCommunicationExist = false;
      this.isUploaded = false;
      this.isConsentExists = false;

    }else if(this.isFinancial){
      this.isIntakeDocuments = false;
      this.isTreatmentDocument = false;
      this.isAppointmentDocument = false;
      this.isSusidalRiskDocumentExist = false;
      this.isFinancialExists1 = true;
      this.isOnboardingExists1 = false;
      this.isCommunicationExist = false;
      this.isUploaded = false;
      this.isConsentExists = false;

    }else if(this.isCommunication){
      this.isIntakeDocuments = false;
      this.isTreatmentDocument = false;
      this.isAppointmentDocument = false;
      this.isSusidalRiskDocumentExist = false;
      this.isFinancialExists1 = false;
      this.isOnboardingExists1 = false;
      this.isCommunicationExist = true;
      this.isUploaded = false;
      this.isConsentExists = false;

    }else if(this.isUploadedDocumentExists){
      this.isIntakeDocuments = false;
      this.isTreatmentDocument = false;
      this.isAppointmentDocument = false;
      this.isSusidalRiskDocumentExist = false;
      this.isFinancialExists1 = false;
      this.isOnboardingExists1 = false;
      this.isCommunicationExist = false;
      this.isUploaded = true;
      this.isConsentExists = false;
    }
    else if(this.isConsent){
      this.isIntakeDocuments = false;
      this.isTreatmentDocument = false;
      this.isAppointmentDocument = false;
      this.isSusidalRiskDocumentExist = false;
      this.isFinancialExists1 = false;
      this.isOnboardingExists1 = false;
      this.isCommunicationExist = false;
      this.isUploaded = false;
      this.isConsentExists = true;
    }
  }
  NoDocumentExist(){
    // if (!this.isIntakePacket && !this.isPainAssesment && !this.isSucidalRisk && !this.isNutritionalQuestion && !this.isChma && this.lstDocuments == null && !this.onBoardingDocument) {
    //   this.noDocuments = true;
    // }else{
    //   this.noDocuments = false;
    // }

    if(!this.isIntakeDocumentExists && !this.isTreatmentDocumentExists && !this.isSucidalRisk && !this.isAppointmentDocumentExists1 && !this.isOnboarding && !this.isFinancial && !this.isCommunication && !this.isUploadedDocumentExists && !this.isConsent){
      this.noDocuments = true;
    }else{
      this.noDocuments = false;
    }
  }
  intakeClick() {
    this.isIntakeDocuments = true;
    this.isTreatmentDocument = false;
    this.isAppointmentDocument = false;
    this.isSusidalRiskDocumentExist = false;
    this.isFinancialExists1 = false;
    this.isOnboardingExists1 = false;
    this.isCommunicationExist = false;
    this.isUploaded = false;
    this.isConsentExists = false;


  }
  financialClick() {
    this.isFinancialExists1 = true;
    this.isOnboardingExists1 = false;
    this.isIntakeDocuments = false;
    this.isTreatmentDocument = false;
    this.isAppointmentDocument = false;
    this.isSusidalRiskDocumentExist = false;
    this.isCommunicationExist = false;
    this.isUploaded = false;
    this.isConsentExists = false;

  }
  onboardingClick() {
    this.isFinancialExists1 = false;
    this.isOnboardingExists1 = true;
    this.isIntakeDocuments = false;
    this.isTreatmentDocument = false;
    this.isAppointmentDocument = false;
    this.isSusidalRiskDocumentExist = false;
    this.isCommunicationExist = false;
    this.isUploaded = false;
    this.isConsentExists = false;

  }
  communicationClick() {
    this.isFinancialExists1 = false;
    this.isOnboardingExists1 = false;
    this.isIntakeDocuments = false;
    this.isTreatmentDocument = false;
    this.isAppointmentDocument = false;
    this.isSusidalRiskDocumentExist = false;
    this.isCommunicationExist = true;
    this.isUploaded = false;
    this.isConsentExists = false;

  }
  ConsentClick(){
    this.isFinancialExists1 = false;
    this.isOnboardingExists1 = false;
    this.isIntakeDocuments = false;
    this.isTreatmentDocument = false;
    this.isAppointmentDocument = false;
    this.isSusidalRiskDocumentExist = false;
    this.isCommunicationExist = false;
    this.isUploaded = false;
    this.isConsentExists = true;
     this.getInTakeQuestions();

  }
  openDocumentSection(patientdetails) {
    this.Opendocumentsection = true;
    this.patientAccountId = patientdetails.PatientAccountId;
    this.patientInfoId = patientdetails.PatientInfoId;
    this.patientName = patientdetails.FirstName + " " + patientdetails.LastName;
    this.programname = patientdetails.PatientStatusName;
    this.createdby = patientdetails.ClinicianName;
    this.isIntakeDocumentExists = false;
    this.isTreatmentDocumentExists = false;
    this.isSucidalRisk = false;
    this.isAppointmentDocumentExists1 = false;
    this.isOnboarding = false;
    this.isFinancial = false;
    this.isCommunication = false;
    this.isUploadedDocumentExists = false;
    this.checkDocumentExists();
    this.getAppointmentDocumentList();
    this.getCommunicationDocumentList();
    this.getUploadedDocumentList();
    this.getTreatmentPlanDoumentList()
    this.intakeClick();
    
  }


  onClickShare(item, clickedTab) {
    if(item.documentName == "PainAssesment" && !this.painAssessment?.documentLink){
      this.getPainAssessment();
      this.isShowPopupForDownload=!this.isShowPopupForDownload;
      return;
    }
    if(item.documentName == "PainAssesment" && this.painAssessment?.documentLink){
      clickedTab = this.painAssessment?.documentLink
    }
    this.isShare = true;
    this.clickedItem = item;
    this.isShareDocument = true;
    this.clickedTab = clickedTab;
    this.isShareAll = false;
    //this.router.navigateByUrl('/messages');
  }
  SharePopupClose() {
    this.isShare = false;
    this.clickedItem = "";
    this.isShareDocument = true;
    this.ShareDocument.resetForm();
    if (this.isShareAll) {
      this.isShareAll = false;
    }
  }
  ShareDocumentPdf() {
    if (!this.ShareDocument.valid) {
      return
    } else if (!this.isShareAll) {
      this.toEmail = this.ShareFiles.email;
      if (this.clickedTab == constantVariables.DocumentTab.Appointment || this.clickedTab == constantVariables.DocumentTab.Communication) {
        if (this.clickedTab == constantVariables.DocumentTab.Appointment)
           {
          this.documentTypeForShare = constantVariables.DocumentTypeForShare.Appointment;
        } 
        else {
          this.documentTypeForShare = constantVariables.DocumentTypeForShare.Communication;
        }
        this.OnDownloadAppointment(this.clickedItem, 3);
      }
      
      else if(this.clickedItem.documentType == 'NutritionalQuestion_Previous')
        {
          this.getNutritionPdfFileHistory(3,this.clickedItem); 
        }
        else if (this.clickedTab == constantVariables.DocumentTab.SusidalRisk && this.clickedItem.documentName.includes("Suicide Screener_Previous")) {
          this.documentTypeForShare = constantVariables.DocumentTypeForShare.SusidalRisk;
          this.OnDownloadSusidalRisk(this.clickedItem, 3);
        } 

      else if (this.clickedTab == constantVariables.DocumentTab.SusidalRisk) {
        this.documentTypeForShare = constantVariables.DocumentTypeForShare.SusidalRisk;
        this.OnDownloadSusidalRisk(this.clickedItem, 3);
      } 
      
      else if (this.clickedTab == constantVariables.DocumentTab.Intake) {
       
          this.documentTypeForShare = constantVariables.DocumentTypeForShare.Intake;
        
        this.onGeneratePDFIntakeDownload(this.clickedItem, 3)
      } else if(this.clickedTab == constantVariables.DocumentTab.TreatmentPlan){
        this.documentTypeForShare = constantVariables.DocumentTypeForShare.TreatmentPlan;
        this.onGenerateTreatmentPlanDownload(this.clickedItem,3)

      } else if (this.clickedTab == constantVariables.DocumentTab.OnBoarding) {
        this.documentTypeForShare = constantVariables.DocumentTypeForShare.OnBoarding;
        this.onGeneratePDFOnboading(this.clickedItem, 3);
      }
      else if (this.clickedTab == constantVariables.DocumentTab.Financial) {
        this.documentTypeForShare = constantVariables.DocumentTypeForShare.Financial;
        this.OnPrintFinancialDocument(3)
      }
      else if(this.clickedTab == constantVariables.DocumentTab.Uploaded)
      {
        this.documentTypeForShare = constantVariables.DocumentTypeForShare.Document;
        this.UploadedDocumentsDownload(this.clickedItem, 3);
      }
      else if(this.clickedTab == constantVariables.DocumentTab.Consent)
      {
        this.documentTypeForShare = constantVariables.DocumentTypeForShare.ConsentForm;
        this.GetPdfConsentform(this.clickedItem,3);
      }
      else if(this.clickedTab == "PainAssesment" && this.clickedItem.documentName == "Nutritional Questionnarie" )
      {
          this.getNutritionPdfFile(3,this.clickedItem); 
      }
      else if(this.clickedTab == this.painAssessment?.documentLink)
        {
            this.onGeneratePDFShare(this.clickedTab,3); 
        }
      
        else if(this.clickedTab == "SusidalRiskAssesment" && this.clickedItem.documentType == "SuicideAssessment_Previous")
          {
              this.SuicideAssesmentHistory(this.clickedItem,3); 
          }
          else if(this.clickedTab == "SusidalRiskAssesment" && this.clickedItem.documentType == "SuicidalSafetyContract_Previous")
            {
                this.SafetyContractPdfHistory(this.clickedItem,3); 
            }

      else if(this.clickedTab == "SusidalRiskAssesment" && this.clickedItem.documentName == "SuicideAssessment")
        {
            this.SuicideAssesment(this.clickedItem,3); 
        }
        else if(this.clickedTab == "SusidalRiskAssesment" && this.clickedItem.documentName == "SuicidalSafetyContract")
          {
              this.SafetyContractPdf(this.clickedItem,3); 
          }
          else if ((this.clickedTab == "NutritionalQuestionnaire")) {
            this.getNutritionPdfFile(3,this.clickedItem);
          }
          else if ((this.clickedTab == "intakeUpdate")) {
            this.OnIntakeUpdate(this.clickedItem,3);
          }
          

    } else if (this.isShareAll) {
      this.toEmail = this.ShareFiles.email;
      if (this.isAppointmentDocument) {
        this.ShareAllAppointmentDocuments();
      } else if (this.isCommunicationExist) {
        this.ShareAllCommunicationDocuments();
      } else if (this.isOnboardingExists1) {
        this.ShareAllOnBoardingDocuments();
      } else if (this.isFinancialExists1) {
        // this.documentTypeForShare = constantVariables.DocumentTypeForShare.Financial;
        // this.OnPrintFinancialDocument(3);
        this.ShareAllOnFinancialProposalDocuments();
      } else if (this.isIntakeDocuments) {
        this.ShareAllIntakeAndTreatment();
        // this.ShareAllSusidalRiskDocument();
        // this.ShareAllTreatmentPlanDocument();
        // this.ShareAllSuicideAssessmentsafetyDocument();
        // this.ShareAllSuicideAssessmentsafetyDocuments();
      // } else if (this.isSusidalRiskDocumentExist) {
      //   this.ShareAllSusidalRiskDocument();
      }else if(this.isUploaded)
      {
        this.ShareAllUploadedDocument();
      // }else if(this.isTreatmentDocument)
      // {
      //   this.ShareAllTreatmentPlanDocument();
      }
      else if(this.isConsentExists){
        this.ShareAllOnConsentDocuments();


      }
    }
  }
  ShareAllIntakeAndTreatment() {
    this.shareAllIntake = new DocumentPdf();
    this.shareAllIntake.isIntakePacket = this.isIntakePacket;
    this.shareAllIntake.isPainAssesment = this.isPainAssesment;
    this.shareAllIntake.isNutritionalQuestion = this.isNutritionalQuestion;
    this.shareAllIntake.isChma = this.isChma;
    this.shareAllIntake.toEmail = this.toEmail;
    this.shareAllIntake.patientAccountId = this.patientAccountId;
    if (this.isIntakeDocuments) {
      this.shareAllIntake.documentType = constantVariables.DocumentTypeForShare.Intake;
    } else if (this.isTreatmentDocument) {
      this.shareAllIntake.documentType = constantVariables.DocumentTypeForShare.TreatmentPlan;
    }

    
    this.patientservice.ShareAllIntakeAndTreatment(this.shareAllIntake).subscribe(
      (res: any) => {
        this.ShareSucess();
      },
    );
  }
  ShareAllUploadedDocument() {
  
    this.patientservice.ShareAllUploadedDocument(this.patientAccountId, this.toEmail,this.patientInfoId).subscribe(
      (res: any) => {
        this.ShareSucess();
      },
    );
  }
  ShareAllTreatmentPlanDocument() {
    
    this.patientservice.ShareAllTreatmentPlanDocument(this.patientAccountId, this.toEmail,this.patientInfoId).subscribe(
      (res: any) => {
        this.ShareSucess();
      },
    );
  }
  ShareAllSusidalRiskDocument() {
    
    this.patientservice.ShareAllSusidalRiskDocument(this.patientAccountId, this.toEmail,this.patientInfoId).subscribe(
      (res: any) => {
        this.ShareSucess();
      },
    );
  }
  ShareAllOnBoardingDocuments() {
   
    this.patientservice.ShareAllOnBoardingDocuments(this.patientAccountId, this.toEmail,this.patientInfoId).subscribe(
      (res: any) => {
        this.ShareSucess();
      },
    );
  }
  ShareAllCommunicationDocuments() {
 
    this.patientservice.ShareAllCommunicationDocuments(this.patientAccountId, this.toEmail,this.patientInfoId).subscribe(
      (res: any) => {
        this.ShareSucess();
      },
    );
  }
  ShareAllAppointmentDocuments() {
  
    this.patientservice.ShareAllAppointmentDocuments(this.patientAccountId, this.toEmail,this.patientInfoId).subscribe(
      (res: any) => {
        this.ShareSucess();
      },
    );
  }
  ShareAllOnConsentDocuments() {
   
    this.patientservice.ShareAllOnConsentDocuments(this.patientAccountId, this.toEmail,this.patientInfoId).subscribe(
      (res: any) => {
        this.ShareSucess();
      },
    );
  }
  treatmentDocumentClick() {
    this.isIntakeDocuments = false;
    this.isTreatmentDocument = true;
    this.isAppointmentDocument = false;
    this.isSusidalRiskDocumentExist = false;
    this.isFinancialExists1 = false;
    this.isOnboardingExists1 = false;
    this.isCommunicationExist = false;
    this.isUploaded = false;
  }
  susidalRiskDocumentClick() {
    this.isIntakeDocuments = false;
    this.isTreatmentDocument = false;
    this.isAppointmentDocument = false;
    this.isSusidalRiskDocumentExist = true;
    this.isFinancialExists1 = false;
    this.isOnboardingExists1 = false;
    this.isCommunicationExist = false;
    this.isUploaded = false;
  }
  appointmentDocumentClick() {
    this.isIntakeDocuments = false;
    this.isTreatmentDocument = false;
    this.isAppointmentDocument = true;
    this.isSusidalRiskDocumentExist = false;
    this.isFinancialExists1 = false;
    this.isOnboardingExists1 = false;
    this.isCommunicationExist = false;
    this.isUploaded = false;
    //this.getAppointmentDocumentList();
  }
  uploadedDocumentClick() {
    this.isUploaded = true;
    this.isIntakeDocuments = false;
    this.isTreatmentDocument = false;
    this.isAppointmentDocument = false;
    this.isSusidalRiskDocumentExist = false;
    this.isFinancialExists1 = false;
    this.isOnboardingExists1 = false;
    this.isCommunicationExist = false;
    this.isConsentExists = false;
  }
  ShareAllDocuments() {
    this.isShareAll = true;
    this.isShare = true;
  }
  getCommunicationDocumentList() {
    this.spinnerservice.show();
    this.patientservice.checkCommunicationDocumentExists(this.patientAccountId).subscribe(
      (res: any) => {
        //this.spinnerservice.hide();
        if (res.objInputString != null) {
          var DataResult = this.commonService.decrypdata(res.objInputString);
          if (DataResult) {
            this.isCommunication = true;
            this.communicationDocumentList = DataResult;
            this.ActiveTab();
            this.NoDocumentExist();
          }

        } else {
          this.isCommunication = false;

        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getUploadedDocumentList() {
    this.isUploadedDocumentExists = true;
    this.uploadedDocumentList = [];
    this.spinnerservice.show();
    this.patientservice.getUploadedDocumentList(this.patientAccountId).subscribe(
      (res: any) => {
        //this.spinnerservice.hide();
        if (res.objInputString != null) {
          var DataResult = this.commonService.decrypdata(res.objInputString);
          if (DataResult) {
             this.isUploadedDocumentExists = true;
             this.uploadedDocumentList = DataResult;
             this.ActiveTab();
             this.NoDocumentExist();
          }

        } else {
          this.isUploadedDocumentExists = false;

        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getTreatmentPlanDoumentList(){
    this.spinnerservice.show();
    this.patientservice.checkTreatmentPlanDocumentExists(this.patientAccountId).subscribe(
      (res: any) => {
        //this.spinnerservice.hide();
        if (res.objInputString != null) {
          var DataResult = this.commonService.decrypdata(res.objInputString);
          if (DataResult) {
            this.isTreatmentDocumentExists = true;
            this.treatmentPlanDocumentList = DataResult;
            this.ActiveTab();
            this.NoDocumentExist();
          }

        } else {
          this.isTreatmentDocumentExists = false;

        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getAppointmentDocumentList() {
    this.spinnerservice.show();
    this.patientservice.checkAppointmentDocumentExists(this.patientAccountId).subscribe(
      (res: any) => {
        //this.spinnerservice.hide();
        if (res.objInputString != null) {
          var DataResult = this.commonService.decrypdata(res.objInputString);
          if (DataResult) {
            this.isAppointmentDocumentExists1 = true;
            this.appoinmentDocumentList = DataResult;
            this.ActiveTab();
            this.NoDocumentExist();
          }

        } else {
          this.isAppointmentDocumentExists1 = false;

        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  OnPrintFinancialDocument(params) {
    this.spinnerservice.show();
    this.patientservice.getFinancialDocument(this.patientAccountId).subscribe(

      (res: any) => {

        var finacialDocuments = this.commonService.decrypdata(res.objInputString);

        this.PrintFinancialDocuments(finacialDocuments, params);

      },

      err => {

        this.snackbar.error(err.error);

      }

    )

  }

  PrintFinancialDocuments(item, params) {
    this.spinnerservice.show();

    this.dtoPaymentActiveList = item.DtoPatientOutofPacketInfoDetailsActiveLists;
    const resultsumSession = this.dtoPaymentActiveList.reduce((accumulator, obj) => {
      return accumulator + obj.DueAmount;
    }, 0);
    this.totalpending = resultsumSession;
    this.dtoPatientOutofPacketInvoicePdf = new DtoPatientOutofPacketInvoicePdf();
    this.dtoPatientOutofPacketInvoicePdf.dtoPatientOutofPacketInfoDetailsPdf = item.DtoPatientOutofPacketInfoDetailsCompletedLists;
    this.dtoPatientOutofPacketInvoicePdf.dtoPatientOutofPacketInfo = item.DtoPatientOutofPacketInfo[0];
    this.dtoPatientOutofPacketInvoicePdf.Totalpending = this.totalpending.toString();
    if (params == 3) {
      this.ShareSucess();
    }
    //const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoPatientOutofPacketInvoicePdf, false);

    this.patientservice.postOutofPktInvoicePdf(this.dtoPatientOutofPacketInvoicePdf).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //this.OutofPktPaymentPrintdata=[];
        let res1 = JSON.parse(res)
        this.pdfInvoice = res1.filePath;
        if (params == 1) {
          window.open(this.pdfInvoice, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res1.base64File + '';
          this.downloadLink.nativeElement.href = res1.base64File;
          this.downloadLink.nativeElement.download = res1.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res1);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  ShareSucess() {
    this.spinnerservice.hide();
    this.SharePopupClose();
    this.snackbar.success("Document shared successfully");
  }

  onGeneratePDFOnboading(item, params) {
    if (item.documentType == "BenefitsandAuthorization") {
      this.GetBenefitsAndAuthoization(item, params);
    }
    else if ((item.documentType == "FinancialProposal")) {
      this.GetFinancialProposal(item, params);

    }
    else if ((item.documentType == "FinancialProposal_Previous" )) {
      this.GetFinancialProposalHistory(item, params);

    }
    else if ((item.documentType == "BenefitsandAuthorization_History" )) {
      this.GetBenefitsAndAuthoizationHistory(item, params);

    }
  }
  onGeneratePDFConsent(item,params){
     if ((item.documentType.includes("_Previous"))) {
      this.GetPdfConsentformHistory(item,params);
    }
     else  {
      this.GetPdfConsentform(item,params);

    }
    
  }
  /*Onboarding document */
  GetFinancialProposal(item, params) {
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    this.patientservice.getFinancialProposal(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //this.OutofPktPaymentPrintdata=[];
        this.financialProposalfile = res.filePath;

        if (params == 1) {
          window.open(this.financialProposalfile, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  GetBenefitsAndAuthoization(item, params) {
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    this.patientservice.GetBenefitsAndAuthorizationPdf(this.patientAccountId,this.patientInfoId,item.insuranceId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //this.OutofPktPaymentPrintdata=[];
        this.financialProposalfile = res.filePath;
        if (params == 1) {
          window.open(this.financialProposalfile, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  UploadedDocumentsDownload(item, params) {
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    item.patientAccountId=this.patientAccountId;
    const dataToEncrypt = this.commonService.DataToEncrypt(item, false);
    this.patientservice.UploadedDocumentsDownload(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //this.OutofPktPaymentPrintdata=[];
        this.uploadedDocumentfile = res.filePath;
        if (params == 1) {
          var fileType = this.getFileExtension(this.uploadedDocumentfile);
          if ( fileType === '.doc' || fileType === '.docx' || fileType === '.ppt' || fileType === '.pptx' || fileType === '.xls' || fileType === '.xlsx') {
            // Replace certain characters in the URL with their URL-encoded versions
            var url = this.uploadedDocumentfile.replace(/\//g, '%2F')
                                               .replace(/:/g, '%3A')
                                               .replace(/\?/g, '%3F')
                                               .replace(/-/g, '%2D')
                                               .replace(/&/g, '%26')
                                               .replace(/=/g, '%3D');
            // Open the URL in a new tab using Microsoft Office viewer
            window.open(`https://view.officeapps.live.com/op/view.aspx?src=${url}`, "_blank");
          }
          // If the file extension is PDF, just open the URL in a new tab
          else {
            window.open(this.uploadedDocumentfile, "_blank");
          }
          //window.open(this.uploadedDocumentfile, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  OnDownloadAppointment(item, params) {
    if (item.TypeId == 2 || item.TypeId == 3 || item.TypeId == 12 || item.TypeId == 13) {
      this.OnDownloadInduvalAndFamily(item, params)
    } else if (item.TypeId == 5) {
      this.OnDownloadPhycatricTherapy(item, params);
    } else if (item.TypeId == 8 && item.ScheduleName.includes("_MentalStates")) {
      this.OnDownloadPhyscatricEvalationMentalStatus(item, params);
    }
    else if (item.TypeId == 8 && !item.ScheduleName.includes("_MentalStates")) {
      this.OnDownloadPhyscatricEvalation(item, params);
    } else if (item.TypeId == 10 || item.TypeId == 11) {
      this.OnDownloadSchoolMeetingAndPhoneCall(item, params);
    } else if (item.TypeId == 9) {
      this.OnDownloadPhyscatricMedication(item, params);
    }
    else if (item.TypeId == null && item.GroupTherapyId) {
      this.getProgressPDF(item, params);
    }


  }
  OnDownloadPhyscatricMedication(item, params) {
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    this.clinicianservice.getPsychiatricmedicationpdf(item.MeetingScheduleId, item.MeetingScheduleTimeId, item.PatientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfpsymedNotes = res.filePath;

        if (params == 1) {
          window.open(this.pdfpsymedNotes, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  OnDownloadSchoolMeetingAndPhoneCall(item, params) {
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    this.clinicianservice.getCommunicationformpdf(item.MeetingScheduleId, item.MeetingScheduleTimeId, item.PatientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfpsytherapynotes = res.filePath;

        if (params == 1) {
          window.open(this.pdfpsytherapynotes, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  OnDownloadPhyscatricEvalationMentalStatus(item, params) {
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    this.clinicianservice.getPsychiatricEvaluationMSpdf(item.MeetingScheduleId, item.MeetingScheduleTimeId, item.PatientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfpsymedNotes = res.filePath;

        if (params == 1) {
          window.open(this.pdfpsymedNotes, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  OnDownloadPhyscatricEvalation(item, params) {
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    this.clinicianservice.getPsychiatricEvaluationFormpdf(item.MeetingScheduleId, item.MeetingScheduleTimeId, item.PatientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfpsymedNotes = res.filePath;

        if (params == 1) {
          window.open(this.pdfpsymedNotes, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getProgressPDF(item, params) {
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    this.clinicianservice.getProgressPDFForDocuments(item, item.ScheduleDate).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdffile = res.filePath;

        if (params == 1) {
          window.open(this.pdffile, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  OnDownloadPhycatricTherapy(item, params) {
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    this.clinicianservice.getPsychiatrictherapynotespdf(item.MeetingScheduleId, item.MeetingScheduleTimeId, item.PatientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfpsytherapynotes = res.filePath;
        const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
        if (params == 1) {
          window.open(this.pdfpsytherapynotes, '_blank');
        }
        else if (params == 0) {

          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  shareFunCall(res) {
    this.shareDocuments = new ShareDocuments();
    this.shareDocuments.PatientAccountId = this.patientAccountId;
    this.shareDocuments.ToEmail = this.toEmail;
    if(res == this.painAssessment?.documentLink){
      this.shareDocuments.FilePath = res
      this.shareDocuments.FileName = "PainAssessment"
    }
    else{
      this.shareDocuments.FilePath = res.filePath;
      this.shareDocuments.FileName = res.fileName;
    }
    this.shareDocuments.DocumentType = this.documentTypeForShare;
    this.shareDocuments.PatientInfoId = this.patientInfoId;
    this.toEmail = "";
    //const dataToEncrypt = this.commonService.DataToEncrypt(this.shareDocuments, false);
    this.patientservice.shareAppointmentDocuments(this.shareDocuments).subscribe(
      (res: any) => {
        this.ShareSucess();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  OnDownloadInduvalAndFamily(item, params) {
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    this.clinicianservice.getIndFamProgressNotespdf(item.MeetingScheduleId, item.MeetingScheduleTimeId, item.PatientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfFamIndNotes = res.filePath;

        if (params == 1) {
          window.open(this.pdfFamIndNotes, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  clickArrowForSusidalRisk() {
    if (this.isSusidalRiskDocument) {
      this.isSusidalRiskDocument = false;
    } else {
      this.isSusidalRiskDocument = true;
    }

  }
  OnDownloadSusidalRisk(item, params) {
    //this.downloadLink = new ElementRef();
   
    if (item.documentName.includes('Suicide Screener_Previous'))
      {
        this.getOnDownloadSusidalRiskHistory(item,params);
        return;
      }
      this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
   

    this.treatmentservice.getSuicidepdf(item.suicideScreeningId, item.formTypeId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfSuicdescreener = res.filePath;
        if (params == 1) {
          window.open(this.pdfSuicdescreener, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onGeneratePDFIntake(item) {
    this.documentType = 7;
    this.getPatientInfoPDFFile(item);

  }
  onGeneratePDFIntakePrevious(item) {
    this.documentType = 7;
    this.getPatientInfoPDFFileHistory(item);

  }
  onGenerateTreatmentPlanDownload(item,params){
     if ((item.documentType == "NutritionalQuestion_Previous")) {
      this.getNutritionPdfFileHistory(params,item);
    }
   else if ((item.documentType == "PainAssesment_Previous")) {
    // this.painAssessment.documentLink.clear();
    this.isShowPopupForDownload=!this.isShowPopupForDownload;
    this.isShowPopupForDownloadPrevious=true;

      // this.painAssesmentPdf(params,item);
    }
    else if ((item.documentType == "PainAssesment")) {
        this.isShowPopupForDownload=!this.isShowPopupForDownload;     
    
      // this.painAssesmentPdfdata(params,item);
      // const Obj = {
      //   filePath: item.DocumentLink,
      //   fileName: item.FileName
      // }
      // this.shareFunCall(Obj);
    //   this.patientservice.getPainAssessmentPdf(this.patientAccountId,0).subscribe(
    //     (res: any) => {
    //       this.spinnerservice.hide();
    //       this.pdfPainAssesment = res.filePath;
        
    //   if (params == 1) {
    //     window.open(this.pdfPainAssesment, '_blank');
    //   }
    //   else if (params == 0) {
    //     const linkSource = 'data:application/pdf;base64,' + res.Base64File + '';
    //     this.downloadLink.nativeElement.href =linkSource;
    //     this.downloadLink.nativeElement.download = res.DocumentName;
    //     this.downloadLink.nativeElement.click();
    //   }else{
    //     item.fileName = item.FileName;
    //     item.filePath = item.DocumentLink;
    //     this.shareFunCall(item);
    //   }
    // });
    } else if ((item.documentType == "Nutritional Questionnarie")) {
        this.getNutritionPdfFile(params,item);
      }
  }
  onGeneratePDFIntakeDownload(item, params) {
    if (item.documentType == "IntakePacket_Previous") {
      this.onGeneratePDFIntakePrevious(params);
    }

   else if (item.documentType == "IntakePacket") {
      this.onGeneratePDFIntake(params);
    }
    // else if ((item.documentType == "PainAssesment")) {
    //   this.painAssesmentPdf(params);

    // }
   
    else if  ((item.documentType == "PrepareCMHA_Previous"))
    {
      this.onGenerateCHMAPDFHistory(params);
    }
     else if ((item.documentType == "PrepareCMHA")) {
      this.onGenerateCHMAPDF(params);
    }
    // else if ((item.documentType == "NutritionalQuestion")) {
    //   this.getNutritionPdfFile(params);
    // }
  }
  onGeneratePDFShare(item,params) {
  
    this.shareFunCall(item)
    // window.open(this.painAssessment.documentLink, '_blank');

    // this.painAssessment.documentLink
    // this.spinnerservice.show();
    // this.getPainAssessment();
    // this.patientservice.getPainAssessmentPdf(this.patientAccountId,0).subscribe(
    //   (res: any) => {
    //     this.spinnerservice.hide();
        
    //     this.pdfPatientInformationIntake = res.filePath;
    //     if (item == 1) {
    //       window.open(this.pdfPatientInformationIntake, '_blank');
    //     }
    //     else if (item == 0) {
    //       const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
    //       this.downloadLink.nativeElement.href = res.base64File;
    //       this.downloadLink.nativeElement.download = res.fileName;
    //       this.downloadLink.nativeElement.click();
    //     }  else if (params == 3) {
    //         this.shareFunCall(res);
    //       }
        
        
    //   },
    //   err => {
    //     this.spinnerservice.hide();
    //   },
    // );
  }
  getPatientInfoPDFFile(item) {
    this.spinnerservice.show();
    if (item == 3) {
      this.ShareSucess();
      this.patientservice.getIGetPatientInfoPdfFilentake(this.patientAccountId, this.documentType).subscribe(
        (res: any) => {
          this.shareFunCall(res);
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    } 
    else {
      this.service.getPatientInfoPDFFile(this.patientAccountId, this.documentType).subscribe(
        (res: any) => {
          if (item == 0) {
            var blob = new Blob([res],{ type: 'application/octet-stream' });
            let saveAs = require('file-saver');
            let file = this.patientName + '.pdf';
            saveAs(blob, file);
            this.spinnerservice.hide();
            this.snackbar.success('PDF Downloaded successfully');
            this.onPrintPDFFile(item)
          } else if (item == 1) {
            var blob = new Blob([res]);
            let saveAs = require('file-saver');
            let file = this.patientName + '.pdf';
            this.spinnerservice.hide();
            this.onPrintPDFFile(item)
          }
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }


  }
  onPrintPDFFile(item) {

    this.documentType = 7;

    this.service.getPrintPDFFile(this.patientAccountId, this.documentType).subscribe(

      (res: any) => {

        this.pdfPatientInformationIntake = res.filePath;

        if (item == 1) {

          window.open(this.pdfPatientInformationIntake, '_blank');

        }



      },

      err => {

      },

    );

  }
  // getPatientInfoPDF() {
  //   this.spinnerservice.show();
  //   this.service.getPatientInfoPDF(this.patientAccountId).subscribe(
  //     (res: any) => {
  //       this.getPatientInfoPDFFile();
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //       this.snackbar.error(err.error);
  //     },
  //   );
  // }
  painAssesmentPdf(item,data) {
    this.spinnerservice.show();
    if (data == 3) {
      this.ShareSucess();
    }
    this.patientservice.getPainAssessmentPdf(this.patientAccountId,item.assesmentId).subscribe(
      (res: any) => {
        var result = res;
        this.pdfPatientInformationIntake = result.filePath
        this.spinnerservice.hide();
        if (data == 1) {
          window.open(this.pdfPatientInformationIntake, '_blank');
        }
        else if (data == 0) {
          const linkSource = 'data:application/pdf;base64,' + result.base64File + '';
          this.downloadLink.nativeElement.href = result.base64File;
          this.downloadLink.nativeElement.download = result.fileName;
          this.downloadLink.nativeElement.click();
        } else if (data == 3) {
          this.shareFunCall(result);
        }
      },
      err => {
        this.spinnerservice.hide();

        this.snackbar.error(err.error);
      },
    );

  }
  onGenerateCHMAPDF(item) {
    this.spinnerservice.show();
    if (item == 3) {
      this.ShareSucess();
    }
    this.patientservice.getChmaPdf(this.patientAccountId).subscribe(
      (res: any) => {

        var result = res;
        this.pdfPatientInformationIntake = result.filePath

        this.spinnerservice.hide();
        if (item == 1) {
          window.open(this.pdfPatientInformationIntake, '_blank');
        }
        else if (item == 0) {
          const linkSource = 'data:application/pdf;base64,' + result.base64File + '';
          this.downloadLink.nativeElement.href = result.base64File;
          this.downloadLink.nativeElement.download = result.fileName;
          this.downloadLink.nativeElement.click();
        } else if (item == 3) {
          this.shareFunCall(result);
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getNutritionPdfFile(item,data) {

    this.spinnerservice.show();
    if (item == 3) {
      this.ShareSucess();
    }
    this.patientservice.getNutritionPdfFile(this.patientAccountId, 13,data.formCount,data.configureFormId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfNutritionfile = res.filePath;
        if (item == 1) {
          window.open(this.pdfNutritionfile, '_blank');
        }
        else if (item == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (item == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
    getFileExtension(url) {
      var fileType = (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).substr(url.lastIndexOf("."));
      return fileType
  }

  painAssesmentAction(result,item){
    if (item == 1) {
      window.open(result.DocumentLink, '_blank');
    }
    else if (item == 0) {
      const linkSource = 'data:application/pdf;base64,' + result.Base64File + '';
      this.downloadLink.nativeElement.href =linkSource;
      this.downloadLink.nativeElement.download = result.DocumentName;
      this.downloadLink.nativeElement.click();
    }
  }
  ConsentPdfgenerate : any;



  GetPdfConsentform(item,params){
    this.spinnerservice.show();
    this.patientservice.GetPdfConsentform(item.consentTemplatesId, this.patientAccountId,false,item.clinicianTypeId ).subscribe(
      (res) => {
         this.ConsentPdfgenerate = res;
         this.pdfConsentpreview = res.filePath;
        if (params == 1) {
          window.open(this.pdfConsentpreview, "_blank");
        }
          else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + this.ConsentPdfgenerate.base64File + '';
          this.downloadLink.nativeElement.href = this.ConsentPdfgenerate.base64File;
          this.downloadLink.nativeElement.download = this.ConsentPdfgenerate.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }
        this.spinnerservice.hide();
          
        
        

      },
      err => {
        this.spinnerservice.hide();
      },
    );


  }
  getInTakeQuestions() {
    this.spinnerservice.show();
    this.patientservice.getIntakeConsentTemplates(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        var result = cloneDeep(res);
       this.lstIntakeConsent = result.filter(x => x.isVerified);
      //  this.lstIntakeConsent = result;
        console.log(this.lstIntakeConsent);
        
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getDocuments(patientInfoId) {
    this.fileList=[];
    this.listOfFiles = [];
    this.documentModuleTypeId = null ;
    this.isRequired = false;
    this.ShowDocuments = true;
    this.SelectedPatientInfoForFile = patientInfoId;
  }
  closepopup(){
    this.filesize=false;
    this.filecount=false;
    this.ShowDocuments = false;
    return;
  }
  onFileChanged(event: any) {
    if (this.fileList && this.fileList.length >= 5) {
        this.filecount=true;
        console.log("You are only allowed to upload 5 files");
        return;
    }
    else {
      this.filecount=false;
      for (var i = 0; i <= event.target.files.length - 1; i++) {
        var filesize = ((event.target.files[i].size / 1024) / 1024).toFixed(4);
        if (event.target.files.length < 5 && parseInt(filesize) <= 10) {
          this.filesize = false;
          var selectedFile = event.target.files[i];
          this.fileList.push(selectedFile);
          this.listOfFiles.push(selectedFile.name)
        }
        else {
          this.filesize = true;
          console.log("File size should not exceed 3 Mb")
        }
      }
    }
    this.attachment.nativeElement.value = '';
  }
  removeSelectedFile(index) {
    // Delete the item from fileNames list
    this.listOfFiles.splice(index, 1);
    // delete file from FileList
    this.fileList.splice(index, 1);
  }
  savedocs(){
    if(this.documentModuleTypeId == null)
    {
      this.isRequired = true;
      return;
    }
    if(this.fileList.length==0){
      this.snackbar.error("Please add files")
      return;
    }
    //    this.uploadDocuments = this.fileList.map(x => x.size);
    //if (this.uploadDocuments.length != 0) {
    //  for (let i = 0; i < this.uploadDocuments.length; i++) {
    //    if (this.uploadDocuments[i] > 1048576) {
    //      this.snackbar.error(constantVariables.ErrorMessage.ImageSizeExceeded);
    //      return;
    //    }
    //  }
    //}
 
    let formData = new FormData();
    for(var i in this.fileList){
      formData.append('File'+i, this.fileList[i]);
    
    
    // formData.append('PolicyDocument', this.srcInsurancePolicy);
    formData.append('PatientInfoId', this.SelectedPatientInfoForFile);
    formData.append('DocumentModuleTypeId', this.documentModuleTypeId?.toString());

    this.spinnerservice.show();
    this.treatmentservice.postUploadDocuments(formData)
      .subscribe((event: any) => {
        this.snackbar.success(event.message);
        this.getUploadedDocumentList();
        this.spinnerservice.hide();
        this.ShowDocuments = false;

      }, (err: any) => {

        this.spinnerservice.hide();
        this.snackbar.error(err.error)

      });
    }
  }
  OnIntakeUpdate(item, params) {
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    this.clinicianservice.getGenerateIntakePdf(item.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfSuicdescreener = res.filePath;
        if (params == 1) {
          window.open(this.pdfSuicdescreener, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  SucidePdf(item,params){
    if(item.documentType == "SuicideAssessment_Previous")
      {
        this.SuicideAssesmentHistory(item,params);
      }else if(item.documentType == "SuicidalSafetyContract_Previous")
      {
        this.SafetyContractPdfHistory(item,params);
      }
   else if(item.documentType == "SuicideAssessment")
    {
      this.SuicideAssesment(item,params);
    }else if(item.documentType == "SuicidalSafetyContract")
    {
      this.SafetyContractPdf(item,params);
    }
  }
  SuicideAssesment(item,params)
  {
    this.spinnerservice.show();
    this.clinicianservice.getSuicideAssesmentPdf(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdffile = res.filePath;
        if(params==1){
          window.open(this.pdffile, '_blank');
        }
        else if(params==0){
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
        }
        else if (params == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  SafetyContractPdf(item,params)
  {
    this.spinnerservice.show();
    this.clinicianservice.getSafetyContractPdf(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdffile = res.filePath;
        if(params==1){
          window.open(this.pdffile, '_blank');
        }
        else if(params==0){
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
        }
        else if (params == 3) {
          this.shareFunCall(res);
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  ShareAllSuicideAssessmentsafetyDocument() {
  
    this.patientservice.ShareAllSuicideAssessmentsafetyDocument(this.patientAccountId, this.toEmail,this.patientInfoId).subscribe(
      (res: any) => {
        this.ShareSucess();
      },
    );
  }
  ShareAllSuicideAssessmentsafetyDocuments() {
  
    this.patientservice.ShareAllSuicideAssessmentsafetyDocuments(this.patientAccountId, this.toEmail,this.patientInfoId).subscribe(
      (res: any) => {
        this.ShareSucess();
      },
    );
  }
  painAssesmentPdfdata(item, data) {
    this.spinnerservice.show();
    if (item === 3) {
      this.ShareSucess();
    }
    this.patientservice.getPainAssessmentPdfdata(this.patientAccountId, data.assesmentId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        var result = res;
        this.pdfPatientInformationIntake = result.filePath;
        if (item === 1 && this.pdfPatientInformationIntake) {
          window.open(this.pdfPatientInformationIntake, '_blank');
        } else if (item === 0) {
          const linkSource = 'data:application/pdf;base64,' + result.base64File + '';
          this.downloadLink.nativeElement.href = result.base64File;
          this.downloadLink.nativeElement.download = result.fileName;
          this.downloadLink.nativeElement.click();
        } else if (item === 3) {
          this.shareFunCall(result);
        }
      },
      err => {
        this.spinnerservice.hide();
        if (err.name === 'TimeoutError') {
          this.snackbar.error('The request timed out. Please try again.');
        } else {
          this.snackbar.error(err.error);
        }
      },
    );
  }

    /*Onboarding document */
    GetFinancialProposalHistory(item, params) {
      this.spinnerservice.show();
      if (params == 3) {
        this.ShareSucess();
      }
      this.patientservice.getFinancialProposalHistory(this.patientAccountId,item.insuranceId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          //this.OutofPktPaymentPrintdata=[];
          this.financialProposalfile = res.filePath;
  
          if (params == 1) {
            window.open(this.financialProposalfile, '_blank');
          }
          else if (params == 0) {
            const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
            this.downloadLink.nativeElement.href = res.base64File;
            this.downloadLink.nativeElement.download = res.fileName;
            this.downloadLink.nativeElement.click();
          } else if (params == 3) {
            this.shareFunCall(res);
          }
  
        },
        err => {
          this.spinnerservice.hide();
        },
      );
    }
    getPatientInfoPDFFileHistory(item) {
      this.spinnerservice.show();
      if (item == 3) {
        this.ShareSucess();
        this.patientservice.getIGetPatientInfoPdfFilentakeHistory(this.patientAccountId, this.documentType).subscribe(
          (res: any) => {
            this.shareFunCall(res);
          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },
        );
      } else {
        
        // this.patientservice.getPatientInfoPDFFile(this.patientAccountId, this.documentType).subscribe(
          this.service.getPatientInfoPDFFileHistory(this.patientAccountId, this.documentType).subscribe(

          (res: any) => {
            if (item == 0) {
              var blob = new Blob([res],{ type: 'application/octet-stream' });
              let saveAs = require('file-saver');
              let file = this.patientName+'_Previous' + '.pdf';
              saveAs(blob, file);
              this.spinnerservice.hide();
              this.snackbar.success('PDF Downloaded successfully');
              this.onPrintPDFFileHistory(item)
            } else if (item == 1) {
              var blob = new Blob([res]);
              let saveAs = require('file-saver');
              let file = this.patientName + '_Previous'  + '.pdf';
              this.spinnerservice.hide();
              this.onPrintPDFFileHistory(item)
            }
          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },
        );
      }
  
  
    }
    onGenerateCHMAPDFHistory(item) {
      this.spinnerservice.show();
      if (item == 3) {
        this.ShareSucess();
      }
      this.patientservice.getChmaPdfHistory(this.patientAccountId).subscribe(
        (res: any) => {
  
          var result = res;
          this.pdfPatientInformationIntake = result.filePath
  
          this.spinnerservice.hide();
          if (item == 1) {
            window.open(this.pdfPatientInformationIntake, '_blank');
          }
          else if (item == 0) {
            const linkSource = 'data:application/pdf;base64,' + result.base64File + '';
            this.downloadLink.nativeElement.href = result.base64File;
            this.downloadLink.nativeElement.download = result.fileName;
            this.downloadLink.nativeElement.click();
          } else if (item == 3) {
            this.shareFunCall(result);
          }
  
        },
        err => {
          this.spinnerservice.hide();
        },
      );
    }
    getNutritionPdfFileHistory(item,data) {

      this.spinnerservice.show();
      if (item == 3) {
        this.ShareSucess();
      }
      this.patientservice.getNutritionPdfFileHistory1(this.patientAccountId, 13,data.formCount,data.configureFormId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdfNutritionfile = res.filePath;
          if (item == 1) {
            window.open(this.pdfNutritionfile, '_blank');
          }
          else if (item == 0) {
            const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
            this.downloadLink.nativeElement.href = res.base64File;
            this.downloadLink.nativeElement.download = res.fileName;
            this.downloadLink.nativeElement.click();
          } else if (item == 3) {
            this.shareFunCall(res);
          }
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
  
  
    }
    painAssesmentPdfHistory(item,data) {
      this.spinnerservice.show();
      if (data == 3) {
        this.ShareSucess();
      }
      this.patientservice.getPainAssessmentPdfHistory(this.patientAccountId,item.assesmentId).subscribe(
        (res: any) => {
          var result = res;
          this.pdfPatientInformationIntake = result.filePath
          this.spinnerservice.hide();
          if (data == 1) {
            window.open(this.pdfPatientInformationIntake, '_blank');
          }
          else if (data == 0) {
            const linkSource = 'data:application/pdf;base64,' + result.base64File + '';
            this.downloadLink.nativeElement.href = result.base64File;
            this.downloadLink.nativeElement.download = result.fileName;
            this.downloadLink.nativeElement.click();
          } else if (data == 3) {
            this.shareFunCall(result);
          }
        },
        err => {
          this.spinnerservice.hide();
  
          this.snackbar.error(err.error);
        },
      );
  
    }
    SuicideAssesmentHistory(item,params)
    {
      this.spinnerservice.show();
      this.clinicianservice.getSuicideAssesmentPdfHistory(this.patientAccountId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdffile = res.filePath;
          if(params==1){
            window.open(this.pdffile, '_blank');
          }
          else if(params==0){
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
          }
          else if (params == 3) {
            this.shareFunCall(res);
          }
        },
        err => {
          this.spinnerservice.hide();
  
        },
      );
    }
    SafetyContractPdfHistory(item,params)
    {
      this.spinnerservice.show();
      this.clinicianservice.getSafetyContractPdfHistory(this.patientAccountId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdffile = res.filePath;
          if(params==1){
            window.open(this.pdffile, '_blank');
          }
          else if(params==0){
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
          }
          else if (params == 3) {
            this.shareFunCall(res);
          }
        },
        err => {
          this.spinnerservice.hide();
  
        },
      );
    }
    GetPdfConsentformHistory(item,params){
    this.spinnerservice.show();
      this.patientservice.GetPdfConsentformHistory(item.consentTemplatesId, this.patientAccountId,false,item.clinicianTypeId ).subscribe(
        (res) => {
   
           this.ConsentPdfgenerate = res;
           this.pdfConsentpreview = res.filePath;
          if (params == 1) {
            window.open(this.pdfConsentpreview, "_blank");
          }
            else if (params == 0) {
            const linkSource = 'data:application/pdf;base64,' + this.ConsentPdfgenerate.base64File + '';
            this.downloadLink.nativeElement.href = this.ConsentPdfgenerate.base64File;
            this.downloadLink.nativeElement.download = this.ConsentPdfgenerate.fileName;
            this.downloadLink.nativeElement.click();
          } else if (params == 3) {
            this.shareFunCall(res);
          }
            
          this.spinnerservice.hide();
          
  
        },
        err => {
          this.spinnerservice.hide();
        },
      );
  
  
    }


    getlstDocuments(){
      return this.intakedocumentList?.length > 0 ? this.intakedocumentList?.length : 0
    }
     
    getpainAssesmentDocument(){
      return (this.intakedocumentList?.length > 0 ? this.intakedocumentList?.length : 0) +
              (this.lstDocuments?.length > 0 ? this.lstDocuments?.length : 0)
    }
     
    getnutritionalQuestionnaire(){
      return (this.intakedocumentList?.length > 0 ? this.intakedocumentList?.length : 0) +
              (this.lstDocuments?.length > 0 ? this.lstDocuments?.length : 0) +
          (this.painAssesmentDocument?.length > 0 ? this.painAssesmentDocument?.length : 0)
    }
    getsuicideAssessment(){
      return (this.intakedocumentList?.length > 0 ? this.intakedocumentList?.length : 0) +
              (this.lstDocuments?.length > 0 ? this.lstDocuments?.length : 0) +
          (this.painAssesmentDocument?.length > 0 ? this.painAssesmentDocument?.length : 0) +
          (this.nutritionalQuestionnaire?.length > 0 ? this.nutritionalQuestionnaire?.length : 0) 
          
    }
  getintakeUpdate() {
    return (
      (this.intakedocumentList?.length > 0 ? this.intakedocumentList?.length : 0) +
      (this.lstDocuments?.length > 0 ? this.lstDocuments?.length : 0) +
      (this.painAssesmentDocument?.length > 0 ? this.painAssesmentDocument?.length : 0) +
      (this.nutritionalQuestionnaire?.length > 0 ? this.nutritionalQuestionnaire?.length : 0) +
      (this.suicideAssessment?.length > 0 ? this.suicideAssessment?.length : 0)
    );
  }
   /* getintakeUpdate(){*/

      //return (this.intakedocumentList?.length > 0 ? this.intakedocumentList?.length : 0) +
      //        (this.lstDocuments?.length > 0 ? this.lstDocuments?.length : 0) +
      //    (this.painAssesmentDocument?.length > 0 ? this.painAssesmentDocument?.length : 0)+
      //    (this.nutritionalQuestionnaire?.length > 0 ? this.nutritionalQuestionnaire?.length : 0)
      //    (this.suicideAssessment?.length > 0 ? this.suicideAssessment?.length : 0)
   /* }*/

    OnDownloadDetailspopupShowOrClose(){
      this.isShowPopupForDownload=!this.isShowPopupForDownload;
    }
    onGeneratePDF(){
      this.spinnerservice.show();
      if(this.isShowPopupForDownloadPrevious==true)
        {
          this.onGeneratePDFPrevious();
          this.painAssessment="";
          this.isShowPopupForDownload=!this.isShowPopupForDownload;    

        }
        else
        {
          this.getPainAssessment();
          this.patientservice.getPainAssessmentPdf(this.patientAccountId,this.painAssessmentId).subscribe(
            (res: any) => {
              this.spinnerservice.hide();
              this.isShowPopupForDownload=!this.isShowPopupForDownload;
              //this.pdfPatientInformationIntake = res.filePath;
            //   if(item==1){
            //     window.open(this.pdfPatientInformationIntake, '_blank');
            //   }
            //   else if(item==0){
            //   const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
            //   this.downloadLink.nativeElement.href = res.base64File;
            //   this.downloadLink.nativeElement.download = res.fileName;
            //   this.downloadLink.nativeElement.click();}
            },
            err => {
              this.spinnerservice.hide();
            },
          );
        }
    
    }
    getPainAssessment() {
      this.spinnerservice.show();
      this.patientservice.getPainAssessment(this.patientAccountId,this.isClear).subscribe(
        (res: any) => {
          this.painAssessment = res;        
          this.painAssessmentId=res.painAssessmentId;        
        },
        err => {
          this.spinnerservice.hide();
          this.isClear=false;
        },
      );
    }

 

    OnDownloadPdf()
    {

      window.open(this.painAssessment.documentLink, '_blank');
      this.painAssessment = "";
      this.isShowPopupForDownloadPrevious = false;
    }
    getOnDownloadSusidalRiskHistory(item, params) {
      //this.downloadLink = new ElementRef();
      this.spinnerservice.show();
        
      if (params == 3) {
        this.ShareSucess();
      }
     
  
      this.treatmentservice.getSuicideHistorypdf(item.suicideScreeningId, item.formTypeId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdfSuicdescreener = res.filePath;
          if (params == 1) {
            window.open(this.pdfSuicdescreener, '_blank');
          }
          else if (params == 0) {
            const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
            this.downloadLink.nativeElement.href = res.base64File;
            this.downloadLink.nativeElement.download = res.fileName;
            this.downloadLink.nativeElement.click();
          } else if (params == 3) {
            this.shareFunCall(res);
          }
        },
        err => {
          this.spinnerservice.hide();
        },
      );
    }
    onPrintPDFFileHistory(item) {

      this.documentType = 7;
  
      this.service.getPrintPDFFileHistory1(this.patientAccountId, this.documentType).subscribe(
  
        (res: any) => {
  
          this.pdfPatientInformationIntake = res.filePath;
  
          if (item == 1) {
  
            window.open(this.pdfPatientInformationIntake, '_blank');
  
          }
  
  
  
        },
  
        err => {
  
        },
  
      );
  
    }
    onGeneratePDFPrevious(){
      this.spinnerservice.show();      
      this.getPainAssessmentPrevious();
      this.patientservice.getPainAssessmentPdfHistory(this.patientAccountId,this.painAssessmentId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.isShowPopupForDownload=!this.isShowPopupForDownload;
          //this.pdfPatientInformationIntake = res.filePath;
        //   if(item==1){
        //     window.open(this.pdfPatientInformationIntake, '_blank');
        //   }
        //   else if(item==0){
        //   const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        //   this.downloadLink.nativeElement.href = res.base64File;
        //   this.downloadLink.nativeElement.download = res.fileName;
        //   this.downloadLink.nativeElement.click();}
        },
        err => {
          this.spinnerservice.hide();
        },
      );
    }
    getPainAssessmentPrevious() {
      this.spinnerservice.show();
      this.patientservice.getPainAssessmentHistory(this.patientAccountId,this.isClear).subscribe(
        (res: any) => {
          this.painAssessment = res;        
          this.painAssessmentId=res.painAssessmentId;        
        },
        err => {
          this.spinnerservice.hide();
          this.isClear=false;
        },
      );
    }
    GetBenefitsAndAuthoizationHistory(item, params) {
      this.spinnerservice.show();
      if (params == 3) {
        this.ShareSucess();
      }
      this.patientservice.GetBenefitsAndAuthorizationPdfHistory(this.patientAccountId,this.patientInfoId,item.insuranceId,item.insuranceId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          //this.OutofPktPaymentPrintdata=[];
          this.financialProposalfile = res.filePath;
          if (params == 1) {
            window.open(this.financialProposalfile, '_blank');
          }
          else if (params == 0) {
            const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
            this.downloadLink.nativeElement.href = res.base64File;
            this.downloadLink.nativeElement.download = res.fileName;
            this.downloadLink.nativeElement.click();
          } else if (params == 3) {
            this.shareFunCall(res);
          }
  
        },
        err => {
          this.spinnerservice.hide();
        },
      );
    }
    ShareAllOnFinancialProposalDocuments() {
   
      this.patientservice.ShareAllOnFinancialProposalDocuments(this.patientAccountId, this.toEmail,this.patientInfoId).subscribe(
        (res: any) => {
          this.ShareSucess();
        },
      );
    }
}


  export class InsuranceAuthorization{
    InsuranceAuthorizationId:number=0;
    InsuranceDetailsId:number;
    PatientInfoId:number=0;
    AuthorizationNumber:string;
    AuthorizationTypeId:number;
    // IsPcp:any;
    // IsIop:any;
    StartDate:any;
    ActiveFrom:any;
    EndDate:any;
    DaysPerWeek:number=0;
    NumberOfVisits:number;
    Reference:string;
    CptHcpcs:string;
    AuthorizationTrackId :number;
    ClinicalInformationProvider :string;
  }

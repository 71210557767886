import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import _Quill from 'quill'
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { TemplateService } from 'src/app/common/pages/template/template.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { template, DtoTemplate, DtoTemplatefield } from 'src/app/_models/_Common/template';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import * as moment from 'moment';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { CatalogueService } from '../../price-catalogue/catalogue.service';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {
  @ViewChild('templateEditor', { static: false }) editorElementRef: ElementRef;
  @ViewChild('frmtemplate') elefrmtemplate: NgForm;
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],                                       // remove formatting button
        ['link'],
        [{ 'align': [] }],
      ],

    },

    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range, context) => {
           // console.log("enter");
            return true;
          }
        }
      }
    }
  }

  panelOpenState = false;
  templateEditor: any;
  templateCatagory: any;
  templateNames: any;
  templateFields: any;
  filteredfileds: any;
  filteredTempfileds: any;
  frmtemplate: template;
  consentTemplatesId: any;
  isedittemplate: boolean = false;
  isPreviewtemplate: boolean = false;
  signData: any = [];
  tempalteesignfieldId: any = [];
  templateesigndatefield: any = [];
  isAllLocation: boolean;
  isGlobalAccess: boolean = false;
  roleAccess: any;
  ddllocation;
  constructor(public tempService: TemplateService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,private cdr: ChangeDetectorRef,
    public roleaccessservice:PageAccessService,public CatlogueService: CatalogueService
    ) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Templates;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
	
    this.frmtemplate = new template();
    this.getddlLocation();
    this.getTemplateCategory();
    // this.getTemplateFields();
    this.router.routerState.root.queryParams.subscribe(params => {
     if (params.id) {

        this.consentTemplatesId = this.commonService.FrontEndDecryption(params.id);
        this.onEdittemplate(this.consentTemplatesId);
      }
    // else if(Number(localStorage.getItem('sfcclocation'))<0 ){
      else if(Number(localStorage.getItem('sfcclocation'))<0  || localStorage.getItem('sfcclocation')==null || localStorage.getItem('sfcclocation')==undefined ){
        // this.isAllLocation=true;
        this.cdr.detectChanges();
      }
    });
   
 
  }
  onEditorCreated(event) {
    this.templateEditor = event;
  }

  onResetSign() {
    this.tempalteesignfieldId.forEach(field => {
      document.getElementById(field).style.pointerEvents = "auto";
    });
    this.frmtemplate.templateContent = '';
    this.signData = [];
    this.templateEditor.deleteText(0, this.templateEditor.getLength());
  }

  onResetFieldSign() {
    this.tempalteesignfieldId.forEach(field => {
      document.getElementById(field).style.pointerEvents = "auto";
    });
    var textEditorContent = this.frmtemplate.templateContent;
    for (let i = 0; i < this.signData.length; i++) {
      const tempFieldName = this.signData[i];
      textEditorContent = textEditorContent.replace(tempFieldName, '');
      if (tempFieldName == '[[Parent/GuardianSignature]]') {
        textEditorContent = textEditorContent.replace('[[Parent&#x2F;GuardianSignature]]', '');
      }
      if (tempFieldName == '[[Parent/GuardianSignatureDate]]') {
        textEditorContent = textEditorContent.replace('[[Parent&#x2F;GuardianSignatureDate]]', '');
      }
    }
    this.frmtemplate.templateContent = textEditorContent;
    this.signData = [];
  }

  onInsertfieldValue(data) {
    const selection = this.templateEditor.getSelection(true);
    this.templateEditor.insertText(selection.index, data);
    
    this.templateEditor.formatText(selection.index, data.length, {                   // unbolds 'hello' and set its color to blue
      'bold': false,
      'color': 'rgb(0, 0, 255)'
    });
    setTimeout(() => this.templateEditor.setSelection(this.templateEditor.getSelection(true).index + 10, 0), 0)
    var changeDeltaCFG = {};
    var deltaOps = this.templateEditor.editor.delta.ops;
    var converter = new QuillDeltaToHtmlConverter(deltaOps, changeDeltaCFG);
    this.frmtemplate.templateContent = converter.convert();

    let esignData = this.filteredTempfileds.filter((item) => item.fieldType == 'E-Signature' || item.fieldType == 'E-Signature Date');
    if (esignData.length) {
      esignData.forEach(fieldtype => {
        fieldtype.fields.forEach(field => {
          if (field.templateFieldValue == data) {
            this.signData.push(data);
          }
        });
      });
    }
  }


  onEsignInsertfieldValue(data, templateFieldsId, fieldType) {
    if (!this.frmtemplate.isMultiPartyDocument) {
      if (fieldType == 'E-Signature') {
        this.tempalteesignfieldId.forEach(field => {
          document.getElementById(field).style.pointerEvents = "none";
        });
      }
      else {
        this.templateesigndatefield.forEach(field => {
          document.getElementById(field).style.pointerEvents = "none";
        });
      }
    }
    if (this.frmtemplate.isMultiPartyDocument) {
      document.getElementById(templateFieldsId).style.pointerEvents = "none";
    }

    const selection = this.templateEditor.getSelection(true);
    this.templateEditor.insertText(selection.index, data);
    this.templateEditor.formatText(selection.index, data.length, {                   // unbolds 'hello' and set its color to blue
      'bold': false,
      'color': 'rgb(0, 0, 255)'
    });

    var changeDeltaCFG = {};
    var deltaOps = this.templateEditor.editor.delta.ops;
    var converter = new QuillDeltaToHtmlConverter(deltaOps, changeDeltaCFG);
    this.frmtemplate.templateContent = converter.convert();

    let esignData = this.filteredTempfileds.filter((item) => item.fieldType == 'E-Signature' || item.fieldType == 'E-Signature Date');
    if (esignData.length) {
      esignData.forEach(fieldtype => {
        fieldtype.fields.forEach(field => {
          if (field.templateFieldValue == data) {
            this.signData.push(data);
          }
        });
      });
    }
  }

  getTemplateFields(id) {
    this.tempService.getTemplateFields(id).subscribe(
      res => {
        if (res != null) {
          this.filteredTempfileds = res;
          this.templateFields = this.getTemplateFieldData(this.filteredTempfileds);
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  getTemplateFieldData(data) {
    let dtoTemplatelist = new Array<DtoTemplate>();
    var esignfieldData = data.filter((item) => item.fieldType == 'E-Signature' || item.fieldType == 'E-Signature Date');
    esignfieldData.forEach(field => {
      let dtoTemplate = new DtoTemplate();
      dtoTemplate.fieldType = field.fieldType;
      let dtoTemplatefieldlist = new Array<DtoTemplatefield>();
      field.fields.forEach(fieldType => {
        let dtoTemplatefield = new DtoTemplatefield();
        if (this.frmtemplate.isEsign == true) {
          dtoTemplatefield.createDate = fieldType.createDate;
          dtoTemplatefield.fieldType = fieldType.fieldType;
          dtoTemplatefield.fieldTypeId = fieldType.fieldTypeId;
          dtoTemplatefield.isActive = fieldType.isActive;
          dtoTemplatefield.isEsign = fieldType.isEsign;
          dtoTemplatefield.templateFieldName = fieldType.templateFieldName;
          dtoTemplatefield.templateFieldValue = fieldType.templateFieldValue;
          dtoTemplatefield.templateFieldsId = fieldType.templateFieldsId;
          dtoTemplatefield.templateNameId = fieldType.templateNameId;
          dtoTemplatefield.updateDate = fieldType.updateDate;
          if (fieldType.fieldType == 'E-Signature') {
            this.tempalteesignfieldId.push(dtoTemplatefield.templateFieldsId);
          }
          else {
            this.templateesigndatefield.push(dtoTemplatefield.templateFieldsId);
          }
          dtoTemplatefieldlist.push(dtoTemplatefield);
        }
      });
      if (dtoTemplatefieldlist.length > 0) {
        dtoTemplate.fields = dtoTemplatefieldlist;
        dtoTemplatelist.push(dtoTemplate);
      }
    });
    var otherfieldData = data.filter((item) => item.fieldType !== 'E-Signature' && item.fieldType !== 'E-Signature Date');
    otherfieldData.forEach(field => {
      let dtoTemplate = new DtoTemplate();
      dtoTemplate.fieldType = field.fieldType;
      let dtoTemplatefieldlist = new Array<DtoTemplatefield>();
      field.fields.forEach(fieldType => {
        let dtoTemplatefield = new DtoTemplatefield();
        dtoTemplatefield.createDate = fieldType.createDate;
        dtoTemplatefield.fieldType = fieldType.fieldType;
        dtoTemplatefield.fieldTypeId = fieldType.fieldTypeId;
        dtoTemplatefield.isActive = fieldType.isActive;
        dtoTemplatefield.isEsign = fieldType.isEsign;
        dtoTemplatefield.templateFieldName = fieldType.templateFieldName;
        dtoTemplatefield.templateFieldValue = fieldType.templateFieldValue;
        dtoTemplatefield.templateFieldsId = fieldType.templateFieldsId;
        dtoTemplatefield.templateNameId = fieldType.templateNameId;
        dtoTemplatefield.updateDate =fieldType.updateDate;
        dtoTemplatefieldlist.push(dtoTemplatefield);
      });
      dtoTemplate.fields = dtoTemplatefieldlist;
      dtoTemplatelist.push(dtoTemplate);
    });
    return dtoTemplatelist;
  }
  getTemplateCategory() {
    this.tempService.getTemplateCategory().subscribe(
      res => {
        if (res != null) {
          this.templateCatagory = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  postConsentTemplate(formtemplate: NgForm) {
    var buttonName = document.activeElement.getAttribute("Name");
    if (buttonName == "reset") {
      this.onResetSign();
      return
    }
    if (!formtemplate.valid) {
      return
    }
    if (this.frmtemplate.templateContent == "" || this.frmtemplate.templateContent == null) {
      return
    }
    if ((this.signData == "" || this.signData == null) && this.frmtemplate.isEsign) {
      this.snackbar.error("E-sign is not assigned in template content.");
      return
    }
    this.spinnerservice.show();
    this.frmtemplate.isActive = true;
    if (this.signData != "" || this.signData != null) {
      this.frmtemplate.esignContent = this.signData.toString();
    }
    else {
      this.frmtemplate.esignContent = null;
    }
    if (this.frmtemplate.isGlobal == 1) {
      this.frmtemplate.location='';
      this.frmtemplate.isGlobal = 1;
    } else {
      this.frmtemplate.isGlobal = 0;
      if (this.frmtemplate.locationList && this.frmtemplate.locationList.length > 0) {
        this.frmtemplate.location = this.frmtemplate.locationList.toString();
      }
    }
    this.frmtemplate.updateDate = moment(this.frmtemplate.updateDate);
    const dataToEncrypt = this.commonService.DataToEncrypt(this.frmtemplate, false);
    this.tempService.postConsentTemplate(dataToEncrypt).subscribe(
      res => {
        if (res != null) {
          this.frmtemplate = new template();
          formtemplate.resetForm();
          this.filteredfileds = [];
          this.isedittemplate = false;
          this.snackbar.success(res);
          this.spinnerservice.hide();
          this.router.navigate(['/consentlist']);
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onCatagoryChange(data) {
    this.tempService.getTemplateNames(data.value).subscribe(
      res => {
        if (res != null) {
          this.templateNames = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  ontempNameChange(data) {
    this.getTemplateFields(data.value);
    this.frmtemplate.isEsign = null;
    this.frmtemplate.isMultiPartyDocument = null;
    this.frmtemplate.templateContent = '';
    this.signData = [];
    this.templateEditor.deleteText(0, this.templateEditor.getLength());
  }

  ontempPartyfieldChange(data) {
    this.frmtemplate.isMultiPartyDocument = data;
    this.templateFields = this.getTemplateFieldData(this.filteredTempfileds);
    this.onResetFieldSign();
  }

  ontempEsignfieldChange(data) {
    this.frmtemplate.isEsign = data;
    this.templateFields = this.getTemplateFieldData(this.filteredTempfileds);
    if (data == false) {
      this.frmtemplate.isMultiPartyDocument = null;
      var textEditorContent = this.frmtemplate.templateContent;
      for (let i = 0; i < this.signData.length; i++) {
        const tempFieldName = this.signData[i];
        textEditorContent = textEditorContent.replace(tempFieldName, '');
      }
      this.frmtemplate.templateContent = textEditorContent;
      this.signData = [];
    }
  }

  onloadtemplate(data) {
    //  this.spinnerservice.show();
    this.signData = data.esignContent.split(',');
    this.tempService.getTemplateNames(data.templateCategoryId).subscribe(
      res => {
        if (res != null) {
          this.templateNames = res;
          this.spinnerservice.hide();
          this.getTemplateFields(data.templateNameId);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  onEdittemplate(id) {
    this.isedittemplate = true;

    this.tempService.getConsentTemplateDetails(id).subscribe(
      res => {

        if (res != null) {
          if (res.consentTemplatesId != 0) {
            this.onloadtemplate(res);
            this.frmtemplate = cloneDeep(res);
            
          
          if (res.isGlobal == 1) {
            this.frmtemplate.location='';
            this.frmtemplate.locationList = [];
            this.frmtemplate.isGlobal = 1;
            this.isGlobalAccess = true;
          } else {
            this.isGlobalAccess = false;
            this.frmtemplate.isGlobal = 0;
            var locationMaster = res.location.split(',');
            var locationList = [];
            locationMaster.map(x => {
            var isExist = this.ddllocation.filter(y => y.sfccMasterId == x)[0].sfccMasterId;
            if (isExist) {
              locationList.push(isExist);
            }
          })
          this.frmtemplate.locationList = locationList;
          if (this.frmtemplate.locationList && this.frmtemplate.locationList.length > 0) {
            this.frmtemplate.location = this.frmtemplate.locationList.toString();
              
            }
          }
          }

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  previewtemplate() {
    this.isPreviewtemplate = true;
  }
  closepreviewtemp() {
    this.isPreviewtemplate = false;

  }
  onchangeAccesslvl(event) {
    if (event.checked) {
        this.isGlobalAccess = true;
        this.frmtemplate.location="";
    } else {
      this.isGlobalAccess = false;
    }
  }
  locationchange(event){
    this.isGlobalAccess = false;
    
  }
  getddlLocation() {
    this.spinnerservice.show();
    this.CatlogueService.getLocations().subscribe(
      res => {
        if (res != null) {
          this.ddllocation = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

}

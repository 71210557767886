import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserserviceService } from '../userservice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';

@Component({
  selector: 'app-user-manage',
  templateUrl: './user-manage.component.html',
  styleUrls: ['./user-manage.component.scss']
})
export class UserManageComponent implements OnInit {
  selectedTabIndex: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  dataSourceRole: MatTableDataSource<any>;
  displayedColumns = ['sno', 'rolename', 'status', 'actions'];
  ddlrole: any;
  roles: any;
  typeroles :any;
  datarole: any;
  isReqAssigned = false;
  isReqName = false;
  isView = false;
  isEdit = false;
  isDelete = false;
  isCreate = false;
  isUser = false;
  isRoleEdit = false;
  roleAccess: any;
  constructor(private router: Router, private route: ActivatedRoute, private userService: UserserviceService, public spinnerservice: NgxSpinnerService
    , private snackbar: SnackBarService, private dialog: MatDialog
    ,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    this.addRole();
    // var access = JSON.parse(localStorage.getItem('roleaccess'));
    // const pageid = constantVariables.pagelists.ROLES;
    // this.roleaccess = access.details.find(x => x.pageId == pageid);
    // var useraccess = access.details.find(x => x.pageId == constantVariables.pagelists.USERS);
    // if (this.roleaccess != null) {
    //   this.isCreate = this.roleaccess.isCreate;
    //   this.isView = this.roleaccess.isView;
    //   this.isEdit = this.roleaccess.isEdit;
    //   this.isDelete = this.roleaccess.isDelete;
    // }
    // else {
    //   this.isCreate = true;
    //   this.isView = true;
    //   this.isEdit = true;
    //   this.isDelete = true;
    // }
    // if (useraccess != null) {
    //   this.isUser = useraccess.isView;
    // }
    // else {
    //   this.isUser = true;
    // }
    // this.selectedTabIndex = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    // if (isNaN(this.selectedTabIndex) || this.selectedTabIndex < 0) {
    //   if (this.isUser)
    //     this.selectedTabIndex = 1;
    //   else if (this.isView)
    //     this.selectedTabIndex = 2;
    //   else
    //     this.selectedTabIndex = 3;
    // }
    // if (this.selectedTabIndex == 2) {
    //   this.getRoles();
    // }
    //this.roledata();
    const pageid = constantVariables.pagelists.StaffManagement;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.selectedTabIndex = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    if (isNaN(this.selectedTabIndex) || this.selectedTabIndex < 0) {
      this.selectedTabIndex = 1;
    }
  }
  onTabChange(tab) {
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: tab
      }
    });
    this.selectedTabIndex = tab;
    if (this.selectedTabIndex == 2) {
      this.getRoles();
      this.getRoleTypes();
    }
  }

  addRole() {
    this.isEdit=false;
    this.isReqName = false;
    this.isReqAssigned =false;
    let _obj = {
      id: 0,
      roleName: '',
      description: '',
      isActive: false,
      assignedTo: 0,
      roleTypeId : 0
    }
    this.datarole = _obj;

  }

  onEdit(item) {
    this.isEdit=true;
    this.isReqName = false;
    this.isReqAssigned =false;
    this.isRoleEdit = false;
    this.datarole = item;
  }
  saveRole() {
    var isvalid = true;

    if (this.datarole.roleName == "") {
      this.isReqName = true;
      isvalid = false;
    }
    else {
      this.isReqName = false;
    }
    if (this.datarole.assignedTo == 0) {
      this.isReqAssigned = true;
      isvalid = false;
    }
    else {
      this.isReqAssigned = false;
    }
    if (this.datarole.roleTypeId == 0) {
      this.isReqName = true;
      isvalid = false;
    }
    else {
      this.isReqName = false;
    }
    if (!isvalid)
      return;

    this.spinnerservice.show();
    this.userService.saveRole(this.datarole)
      .subscribe(
        data => {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(data).message);
          this.getRoles();
          this.addRole();
          this.isRoleEdit = false;
        },
        error => {
          this.spinnerservice.hide();
          this.snackbar.error(error.error);
        });
  }
  deleteRole(obj) {
    this.isReqName = false;
    this.isReqAssigned =false;

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.confirmation.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.userService.deleteRole(obj)
          .subscribe(
            data => {
              this.spinnerservice.hide();
              this.snackbar.success(JSON.parse(data).message);
              this.getRoles();
            },
            error => {
              this.spinnerservice.hide();
              this.snackbar.error(error.error);
            });
      }
      else {
        return
      }
    });

  }
  activeRole(obj) {
    this.isReqName = false;
    this.isReqAssigned =false;
    obj.isActive = !obj.isActive;
    this.spinnerservice.show();
    this.userService.activeRole(obj)
      .subscribe(
        data => {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(data).message);
          this.getRoles();
        },
        error => {
          this.spinnerservice.hide();
          this.snackbar.error(error.error);
        });
  }
  getRoles() {
    this.userService.getRoles().subscribe(
      response => {
        this.roles = response;
        this.dataSourceRole = new MatTableDataSource(response);
        this.dataSourceRole.paginator = this.paginator;
      },
      error => { }

    );
  }
  getRoleTypes() {
    this.userService.getRoleTypes().subscribe(
      response => {
        this.typeroles = response;
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
      },
      error => { }

    );
  }
  roledata() {
    var data = [{
      rolename: "Clinician Staff",
      status: 1
    },
    {
      rolename: "Support Staff",
      status: 0
    }]
    this.dataSource = new MatTableDataSource(data);
  }
}

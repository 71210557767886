import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Global } from '../global/global.model';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {


  constructor(private router: Router, private global: Global) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
   


    if (localStorage.getItem('token') != null){

      let landingPage =localStorage.getItem('landingPage');
      if(landingPage != null){
        landingPage = landingPage.replace(/"/g, '');
      }

      if((!!(landingPage) && (landingPage != "null"))){
        if(landingPage=='clinician')
        {                        
          this.router.navigate(['/clinician'],{ queryParams: { menu: 1 } });           
        }
        else{
          this.router.navigate(['/'+landingPage]);
        }          
      }
      else{
        this.router.navigate(['/welcome']);
      }


  //     if(+localStorage.getItem('patientAccountId')!=0){
  //       this.router.navigate(['/patient-breifcase']);
      
  //     }
 
  //  else  if (+localStorage.getItem('roleId') == 5 || +localStorage.getItem('roleId') == 6) {
  //     this.global.ismytask =true
  //     this.router.navigate(['/clinician'])
  //     console.log("clinician")
  //   }
  //   else {
  //     this.router.navigate(['/leadlist'])
  //     console.log("admin")
  //   }
  }
  else{
      return true;
  }
 
   

  }
}

import { Medicationinfo, PsyMedFormInfo } from 'src/app/_models/_Treatment/_medication';
import { Component, OnInit, Input, ViewChild,ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { constantVariables } from 'src/app/constant/constant';
import { ClinicalNotes, Medication } from 'src/app/_models/_Treatment/_clinicalnotes';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { NgForm } from '@angular/forms';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { PsyTherapyInfo } from 'src/app/_models/_Treatment/_PsyTherapy';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { DatePipe } from '@angular/common';
import { TreatmentService } from '../treatment.service';


@Component({
  selector: 'app-psychiatric-medication-progress-notes',
  templateUrl: './psychiatric-medication-progress-notes.component.html',
  styleUrls: ['./psychiatric-medication-progress-notes.component.scss']
})
export class PsychiatricMedicationProgressNotesComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('PsyMedFrm') PsyMedFrm: NgForm;
  SelectedTab=1;
  _dtoMedications: any = [];
   _dtoPatientInfo: any;
  selecteddate: any;
  selectedtheraphyid: number;
  selectedMsId: any;
  selectedIntervention: string;
  PsyMedFrmInfo:PsyMedFormInfo;
  signatureImg: any;
  updatedsignatureImg: any;
  isformtype: string;
  defaultImage: any;
  isESignAttached: boolean;
  IseditPsyMed: boolean;
  PopUpPreview: boolean;
  goalsinfo: any;
  roleAccess: RoelAccess;
  pdfpsymedNotes: any;
  isLicensed: boolean;
  RoleId: string;
  IsCloseWindow: boolean=false;
  ddlseverity: any;
  bg: any;
  progressUpg:boolean=false;
  secondaryId : any;
  AutoFillSS:boolean=false;
  SSignature:any;
  GetClinicianSign: any[];  
  userId: any;
  constructor(public global: Global, public clinicianservice: ClinicianService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,public treatmentservice: TreatmentService,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,private formBuilder: FormBuilder,
    public roleaccessservice:PageAccessService,public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.isLicensed = localStorage.getItem('isLicensed') == 'true'?true:false;
    this.RoleId = localStorage.getItem('roleId');
    this.userId = Number(localStorage.getItem('id'));
    this.getAutoFillSignature();
    this._dtoMedications.push(new Medicationinfo());
    this.PsyMedFrmInfo = new PsyMedFormInfo();
    
    const pageid = constantVariables.pagelists.ProgressNoteFivepointRating;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) { 
         this.selecteddate = JSON.parse(this.commonService.FrontEndDecryption(params.date));
        this.selectedtheraphyid = JSON.parse(this.commonService.FrontEndDecryption(params.theraphyid));
        this.selectedMsId= JSON.parse(this.commonService.FrontEndDecryption(params.id));
        if(params.IsFromHistory){
          this.IsCloseWindow = true;
        }
        this.selectedIntervention="";
        if ((this.datepipe.transform(new Date(), "yyyy-MM-dd")) < (this.datepipe.transform(this.selecteddate, "yyyy-MM-dd"))) {
          this.progressUpg = true;
        }
        this.getPatientInfo();
        }
    });
    this.getSeverityMaster();
  }
OnTabchange(item){
    this.SelectedTab = item;
    if(this.SelectedTab ==2){
      this.getpatientgoal()
    }
   
}

addMedicationInfo() {
    this._dtoMedications.push(new Medicationinfo())
}

removeclinicalinformation(data, index) {
  if (data.psychiatricMedicationDtlId != undefined) {
    this.removeexistMedinfo(data);
    this.getPatientInfo();
  }
  else{
    this._dtoMedications.splice(index, 1);
  }
}

getPatientInfo()
  {
     this.spinnerservice.show();
     this.secondaryId = localStorage.getItem('id');
    this.clinicianservice.getpsychaiatricMedpatientinfo(this.selectedMsId,this.selectedtheraphyid,this.selecteddate,this.secondaryId).subscribe(
      (res: any) => {
       // debugger;
        this.spinnerservice.hide();
        if (res){
         this._dtoPatientInfo=res; 
        //  this.PsyMedFrmInfo = res.dtoPsyMedNotes
         setTimeout(()=>{                           
          this.PsyMedFrmInfo = res.dtoPsyMedNotes;
      }, 3000);
      this.PsyMedFrmInfo = res.dtoPsyMedNotes;
         if(this.PsyMedFrmInfo.psychiatricMedicationNotesId!=0 ){
          this.IseditPsyMed  = false
        }
        else{
         this.IseditPsyMed  = true
        }
        if(!this.IseditPsyMed){
         this._dtoMedications = this.PsyMedFrmInfo.medication
        } 
        this.PsyMedFrmInfo.isSave = false;     
        }},
        err => {
          this.spinnerservice.hide();
        },
      );
}  


openDialog() {
        // this.global.isconsentesign = false;
        // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
        //   data: { isfutureSign: false, isParentsign: true },
        //   autoFocus: false 
        // });
        // dialogRef.afterClosed().subscribe((result) => {
        //   this.isESignAttached = this.global.isconsentesign;
        //   if (
        //     this.global.isconsentesign != undefined &&
        //     this.global.isconsentesign
        //   ) {
          this.global.isconsentesign = false;
          let isParentsign =false;
          if(this.AutoFillSS==true)
           {
             isParentsign=true;
        
           }
           const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
             {
            data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
             autoFocus: false 
        
           });
          dialogRef.afterClosed().subscribe((result) => {
            this.isESignAttached = this.global.isconsentesign;
            if (
              this.global.isconsentesign != undefined &&
              this.global.isconsentesign
            ) {
              if(this.SSignature!='')
                {
                 this.updatedsignatureImg=this.SSignature;
                }
            this.signatureImg = this.updatedsignatureImg;
            this.PsyMedFrmInfo.staffInitial = this.updatedsignatureImg;
           // this.IncReportfrmInfo.IsAttachEsign = true;
            (document.getElementById('esign') as HTMLImageElement).src =
              this.signatureImg;
          } else if (
            !this.global.isconsentesign &&
            this.global.formtype != null &&
            this.global.formtype != undefined
          ) {
            this.signatureImg = this.global.consentesign;
            this.isformtype = this.global.formtype;
            this.PsyMedFrmInfo.staffInitial = this.global.consentesign;
            //this.IncReviewInfo.IsAttachEsign = true;
            (document.getElementById('esign') as HTMLImageElement).src =
              this.signatureImg;
          } else {
            this.signatureImg = this.defaultImage;
            //this.IncReportfrmInfo.IsAttachEsign = true;
            this.PsyMedFrmInfo.staffInitial = this.defaultImage;
          }
        });
}

openDialogPsy(){
        // this.global.isconsentesign = false;
        // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
        //   data: { isfutureSign: false, isParentsign: true },
        //   autoFocus: false 
        // });
        // dialogRef.afterClosed().subscribe((result) => {
        //   this.isESignAttached = this.global.isconsentesign;
        //   if (
        //     this.global.isconsentesign != undefined &&
        //     this.global.isconsentesign
        //   ) {
          this.global.isconsentesign = false;
          let isParentsign =false;
          if(this.AutoFillSS==true)
           {
             isParentsign=true;
        
           }
           const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
             {
            data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
             autoFocus: false 
        
           });
          dialogRef.afterClosed().subscribe((result) => {
            this.isESignAttached = this.global.isconsentesign;
            if (
              this.global.isconsentesign != undefined &&
              this.global.isconsentesign
            ) {
              if(this.SSignature!='')
                {
                 this.updatedsignatureImg=this.SSignature;
                }
            this.signatureImg = this.updatedsignatureImg;
            this.PsyMedFrmInfo.psychiatristInitials = this.updatedsignatureImg;
           // this.IncReportfrmInfo.IsAttachEsign = true;
            (document.getElementById('esign_2') as HTMLImageElement).src =
              this.signatureImg;
          } else if (
            !this.global.isconsentesign &&
            this.global.formtype != null &&
            this.global.formtype != undefined
          ) {
            this.signatureImg = this.global.consentesign;
            this.isformtype = this.global.formtype;
            this.PsyMedFrmInfo.psychiatristInitials = this.global.consentesign;
            //this.IncReviewInfo.IsAttachEsign = true;
            (document.getElementById('esign_2') as HTMLImageElement).src =
              this.signatureImg;
          } else {
            this.signatureImg = this.defaultImage;
            //this.IncReportfrmInfo.IsAttachEsign = true;
            this.PsyMedFrmInfo.psychiatristInitials = this.defaultImage;
          }
        });
}
PostPsyMedForm(val){
  if(val != 2 && (!this.PsyMedFrm.valid || ((this.PsyMedFrmInfo.staffInitial==undefined || this.PsyMedFrmInfo.psychiatristInitials == undefined) && ((this.roleAccess.isSign && this.isLicensed) || (!this._dtoPatientInfo.isLicensed && this.roleAccess.isUnlicensed) || (this._dtoPatientInfo.IsIntern && this.roleAccess.isInternSign )))) ){
    return
  }
  else{
    if(val !=2)
    {
      this.spinnerservice.show()
      this.PsyMedFrmInfo.isSave = true;
    }else
    {
      this.PsyMedFrmInfo.isSave = false;
    }
  
this.PsyMedFrmInfo.medication = this._dtoMedications
const dataToEncrypt = this.commonService.DataToEncrypt(this.PsyMedFrmInfo, false);
this.clinicianservice.PostPsyMedNotes(this.PsyMedFrmInfo).subscribe(
  res=>{
    if(val !=2)
    {
      this.spinnerservice.hide();
      this.snackbar.success(res);
      this.getPatientInfo()
    }
},
    err => {
      if(val !=2)
      {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      }
    },)}
  }

    OnpreviewClick(){
      this.PopUpPreview = true;
    }
    OnclosePopup(){
      this.PopUpPreview = false;
    }


    OnbackClick(){
      if(this.IsCloseWindow){
        window.close();
      }
      else{
        this.router.navigate(['/clinician'],{queryParams:{menu:1}});
    
      }
     }

     getpatientgoal(){
      this.spinnerservice.show();
    //  console.log(this.userId )
      this.clinicianservice.getIndvFamgoals(this._dtoPatientInfo.patientAccountId,(this.selectedtheraphyid-1),this.selectedMsId,this.selecteddate ).subscribe(
        (res:any)=>{
          this.spinnerservice.hide();
         console.log(res)
         this.goalsinfo = res
         if(this.goalsinfo[0].staffInitials != ""){
         //  this.signatureImg = this.goalsinfo[0].staffInitials
           (document.getElementById('esign2') as HTMLImageElement).src =this.goalsinfo[0].staffInitials  
         }
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }
    removeexistMedinfo(data) {
      this.spinnerservice.show();
      this.clinicianservice.removePsyMed(data.psychiatricMedicationDtlId).subscribe((res: any) => {
        this.spinnerservice.hide();
        
      },
        err => {
          this.spinnerservice.hide();
        }
      );
    }

     
OnDownload(item){
  //this.downloadLink = new ElementRef();
  this.spinnerservice.show();
    this.clinicianservice.getPsychiatricmedicationpdf(this.selectedMsId,this._dtoPatientInfo.meetingScheduleTimeId,this._dtoPatientInfo.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfpsymedNotes = res.filePath;
        if(item==1){
          window.open(this.pdfpsymedNotes, '_blank');
        }
        else if(item==0){
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
}
print(){
  let printContents = document.getElementById('print-section').innerHTML;
     let originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
    // this.ShowPreview=true;
     window.location.reload();
}
getSeverityMaster(){
  this.spinnerservice.show()
  this.commonService.getSeverityMaster().subscribe(
    res => {
      if (res != null) {
    this.ddlseverity = res;
 this.spinnerservice.hide()
}},
err => {
  this.spinnerservice.hide();

})
}
onSelectionChanged(value){

  this.bg = this.ddlseverity.filter(x=>x.severityValue==value)[0].colocode;
  this.PsyMedFrmInfo.severity=+value;
  // this.PostPsyMedForm(2);
}
// onSelectionChangedDosage()
// {
//   this.PostPsyMedForm(2);
// }

openDialogPsy1(){
  // this.global.isconsentesign = false;
  // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
  //   data: { isfutureSign: false, isParentsign: true },
  //   autoFocus: false 
  // });
  this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if (
      this.global.isconsentesign != undefined &&
      this.global.isconsentesign
    ) {
      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
      this.signatureImg = this.updatedsignatureImg;
      this.PsyMedFrmInfo.staffInitial1 = this.updatedsignatureImg;
     // this.IncReportfrmInfo.IsAttachEsign = true;
      (document.getElementById('esign_3') as HTMLImageElement).src =
        this.signatureImg;
    } else if (
      !this.global.isconsentesign &&
      this.global.formtype != null &&
      this.global.formtype != undefined
    ) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      this.PsyMedFrmInfo.staffInitial1 = this.global.consentesign;
      //this.IncReviewInfo.IsAttachEsign = true;
      (document.getElementById('esign_3') as HTMLImageElement).src =
        this.signatureImg;
    } else {
      this.signatureImg = this.defaultImage;
      //this.IncReportfrmInfo.IsAttachEsign = true;
      this.PsyMedFrmInfo.staffInitial1 = this.defaultImage;
    }
  });
}
secondarySign():any{
  if(this.PsyMedFrmInfo.licenseNumber != '' && this.PsyMedFrmInfo.licenseNumber != null ){
    return false;
  }
  else if(this.PsyMedFrmInfo.sSignature ){
   return false;
  }
  else{
    return true;
  }
}
getAutoFillSignature() {
  this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
      this.GetClinicianSign = res;

      if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
        this.AutoFillSS=false;
        this.SSignature=this.GetClinicianSign[0].clinicianSign;
      }
      else
      {
        this.AutoFillSS=true;
        this.SSignature='';
      }
  });
}
}

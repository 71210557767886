
<div class="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-home" role="tabpanel"
      aria-labelledby="v-pills-home-tab">
      <form (ngSubmit)="InsuranceInfofrm.form.valid  && PostInsurancefrm(InsuranceInfofrm)" id="InsuranceInfofrm" autocomplete="off" #InsuranceInfofrm="ngForm"
novalidate>
            <div  class="box-lead-head w-100">Insurance Companies </div>
           
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Insurance Code</mat-label>
                      <mat-select [(ngModel)]="InsuranceCode" #InsuranceCode1="ngModel" name="InsuranceCode1">
                        <mat-option value="one">First option</mat-option>
                        <mat-option value="two">Second option</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2">
                    <button type="button" mat-stroked-button class="btn primary-btn-filled-small primary-btn-m mt-3"> Rename</button>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Insurance Group</mat-label>
                      <mat-select [(ngModel)]="dtoInsuranceInfo.InsuranceGroupId" #InsuranceGroupId="ngModel" name="InsuranceGroupId"
                      [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && InsuranceGroupId.invalid }">
                      <mat-option *ngFor="let ins of insuranceGroupList" [value]="ins.insuranceGroupId">{{ins.insuranceGroupDescription}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2">
                    <button type="button" mat-stroked-button class="btn primary-btn-filled-small primary-btn-m mt-3"> New</button>
                  </div>
                </div>
              
              </div>
              <!-- <div class="col-md-12">
                <div class="row">
               
                </div>
              
              </div> -->
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                 
                      <div class="col-md-4">
                        <mat-form-field class="w-100">
                          <mat-label >Company Name</mat-label>
                          <input matInput [(ngModel)]="dtoInsuranceInfo.CompanyName" #CompanyName="ngModel" name="CompanyName"
                          [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && CompanyName.invalid }" required>
                          <mat-error *ngIf="InsuranceInfofrm.submitted && CompanyName.invalid">
                            <mat-error *ngIf="CompanyName.errors.required">Company name is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                    
                      </div>
                      <div class="col-md-4">
                        <mat-form-field class="w-100">
                          <mat-label>Contact Name</mat-label>
                           <input matInput [(ngModel)]="dtoInsuranceInfo.ContactName" #ContactName="ngModel" name="ContactName"
                           [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && ContactName.invalid }">
                        </mat-form-field>
                      </div>
                      <div class="col-md-4">
                        <div class="d-flex">
                        <mat-form-field class="flex-grow-1 mr-2">
                          <mat-label>Website</mat-label>
                          <input matInput [(ngModel)]="dtoInsuranceInfo.Website" #Website="ngModel" name="Website"
                          [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && Website.invalid }">
                        </mat-form-field>
                        <div class="ml-auto">
                          <button type="button" mat-stroked-button class="btn primary-btn-filled-small primary-btn-m mt-3"  (click)="goToLink()">Visit</button>
                        </div>
                      </div>
                      </div>
                      </div>
                  </div>
                 
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="w-100">
                      <mat-label>Address</mat-label>
                      <input matInput  [(ngModel)]="dtoInsuranceInfo.Address" #Address="ngModel" name="Address"
                      [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && Address.invalid }">
                    </mat-form-field>
                    </div>
                  </div>  
                  <div class="row">
                    
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>State</mat-label>
                        <mat-select [(ngModel)]="dtoInsuranceInfo.StaesId" #StaesId="ngModel"
                        (selectionChange)="onChangePhysicianState($event.value)" name="StaesId"
                        [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && StaesId.invalid }">
                        <mat-option *ngFor="let item of ddlStates" [value]="item.StatesId">
                          {{item.StateName}}
                        </mat-option>
                      </mat-select>
                      
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="w-100">
                          <mat-label>City</mat-label>
                          <mat-select [(ngModel)]="dtoInsuranceInfo.CityId" #CityId="ngModel"
                          name="CityId"
                          [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && CityId.invalid }">
                          <mat-option *ngFor="let item of ddlCities" [value]="item.CitiesId">
                            {{item.CityName}}
                          </mat-option>
                        </mat-select>
                         
                        </mat-form-field>
                      </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Zip</mat-label>
                        <input matInput mask='00000' maxlength="50" [(ngModel)]="dtoInsuranceInfo.Zipcode" #Zipcode="ngModel" name="Zipcode"
                        [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && Zipcode.invalid }">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Office</mat-label>
                        <input matInput [(ngModel)]="dtoInsuranceInfo.Office" #Office="ngModel" name="Office"
                        [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && Office.invalid }">
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Fax</mat-label>
                        <input matInput [(ngModel)]="dtoInsuranceInfo.Fax" #Fax="ngModel" name="Fax"
                        [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && Fax.invalid }">
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Other</mat-label>
                        <input matInput [(ngModel)]="dtoInsuranceInfo.Other" #Other="ngModel" name="Other"
                        [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && Other.invalid }"> 
                      </mat-form-field>
                    </div>
                  </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <div class="d-flex">

                      <mat-form-field class="flex-grow-1 mr-2">
                        <mat-label>eclaims ID</mat-label>
                        <mat-select [(ngModel)]="dtoInsuranceInfo.EclaimsId" #EclaimsId="ngModel" name="EclaimsId"
                        [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && EclaimsId.invalid }">
                          <mat-option value="1">blue cross blue shield of new</mat-option>
                          <mat-option value="2">Second option</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="ml-auto mt-2">
                        <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                      </div>
                     
                    </div>
                  </div>
                 
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Eligibility ID</mat-label>
                      <mat-select [(ngModel)]="dtoInsuranceInfo.EligibilityId" #EligibilityId="ngModel" name="EligibilityId"
                      [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && EligibilityId.invalid }">
                        <mat-option value="1">Horizon blue cross blue shield</mat-option>
                        <mat-option value="2">Second option</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Fee schedules</mat-label>
                      <mat-select [(ngModel)]="dtoInsuranceInfo.FeeScheduleId" #FeeScheduleId="ngModel" name="FeeScheduleId"
                      [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && FeeScheduleId.invalid }">
                      <mat-option *ngFor="let fee of feeScheduleList" [value]="fee.feeScheduleId">{{fee.feeScheduleDescription}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Allowable</mat-label>
                      <mat-select [(ngModel)]="dtoInsuranceInfo.AllowableId" #AllowableId="ngModel" name="AllowableId"
                      [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && AllowableId.invalid }">
                      <mat-option *ngFor="let fee of feeScheduleList" [value]="fee.feeScheduleId">{{fee.feeScheduleDescription}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div  class="col-md-12 ">
                <div  class="mt-2">
                  <span  class="small-heading-label align-middle font-weight-500">Use following billing schedule when posting charges</span>
                  <span  class="ml-4 yes-no-insurance">
                    <mat-radio-group aria-label="Select an option"  [(ngModel)]="dtoInsuranceInfo.BillingSchedule" #BillingSchedule="ngModel" name="BillingSchedule"
                    [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && BillingSchedule.invalid }">
                      <mat-radio-button class="mr-2" value="1">Insurance Allowable</mat-radio-button>
                      <mat-radio-button value="2">Location</mat-radio-button>
                    </mat-radio-group>
                  </span>
                </div>
              </div>
              <div  class="col-md-12 check-font-large">
                <div  class="row">
                  <div class="col-md-4">
                    <div class="check-custom mt-4">
                      <mat-checkbox class="example-margin " [(ngModel)]="dtoInsuranceInfo.IsCrossCover" #IsCrossCover="ngModel" name="IsCrossCover"
                      [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && IsCrossCover.invalid }">Medicare Auto crossover</mat-checkbox>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="check-custom mt-4">
                      <mat-checkbox class="example-margin" [(ngModel)]="dtoInsuranceInfo.IsSupplimental" #IsSupplimental="ngModel" name="IsSupplimental"
                      [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && IsSupplimental.invalid }">Medicare Supplemental</mat-checkbox>
                    </div>
                  </div>
                </div>
                
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Medigap Number</mat-label>
                        <input matInput [(ngModel)]="dtoInsuranceInfo.MediGapNumber" #MediGapNumber="ngModel" name="MediGapNumber"
                        [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && MediGapNumber.invalid }"> 
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Payer category</mat-label>
                        <mat-select [(ngModel)]="dtoInsuranceInfo.PayerCategoryId" #PayerCategoryId="ngModel" name="PayerCategoryId"
                        [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && PayerCategoryId.invalid }" required>
                       
                        <mat-option *ngFor="let payer of payerCategoryList" [value]="payer.payerCategoryId">{{payer.payerCategoryDescription}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="InsuranceInfofrm.submitted && PayerCategoryId.invalid">
                          <mat-error *ngIf="PayerCategoryId.errors.required">Payer category is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">

                  <div class="row">
                    <div class="col-md-12 mt-3">
                      Billing Groups Details
                    </div>
                   
                    <div class="col-md-3">
                      <mat-form-field class="w-100">
                        <mat-label>Billing Groups</mat-label>
                        <mat-select [(ngModel)]="dtoInsuranceInfo.BillingGroupId" #BillingGroupId="ngModel" name="BillingGroupId"
                        [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && BillingGroupId.invalid }">
                        <mat-option *ngFor="let bill of billingGroupList" [value]="bill.billingGroupId">{{bill.groupCode}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-md-1">
                      <button type="button" mat-stroked-button class="btn primary-btn-filled-small primary-btn-m mt-3" (click)="addBillingGroup()"> Add</button>
                                  
                    </div>
                    <div class="col-md-8">
                      <div class=" w-100">
                        <div class="table-responsive">
                          <div class="mat-elevation-z8">
                            <table mat-table [dataSource]="dataSource1" class="w-100">
                          
                              <!-- Position Column -->
                             
                              <ng-container matColumnDef="groupCode" class="w-50">
                                <th mat-header-cell *matHeaderCellDef class="insurance-header"> Group Code </th>
                                <td mat-cell *matCellDef="let element"> {{element.GroupCode}} </td>
                              </ng-container>
                              <!-- Name Column -->
                              <!-- <ng-container matColumnDef="comment">
                                <th mat-header-cell *matHeaderCellDef> Comment </th>
                                <td mat-cell *matCellDef="let element"> {{element.Comment}} </td>
                              </ng-container> -->
                              <!-- Symbol Column -->
                              <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="insurance-header"> Actions </th>
                                <td mat-cell *matCellDef="let element"><a class="text-danger" (click)="removeBillGroup(element)"><i class="fas fa-minus-circle"></i></a> </td>
                                
                              </ng-container>
                          
                              <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
                            </table>
                          
                            <!-- <mat-paginator [pageSizeOptions]="[ 10, 20, 50, 75, 100]"
                                           showFirstLastButtons 
                                           aria-label="Select page of periodic elements">
                            </mat-paginator> -->
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
             
                <div class="col-md-12 mt-4">
                  <mat-form-field class="w-100">
                    <mat-label >Comments</mat-label>
                    <textarea matInput [(ngModel)]="dtoInsuranceInfo.Comments" #Comments="ngModel" name="Comments"
                    [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && Comments.invalid }"></textarea>
                  </mat-form-field>
                </div>



                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="check-custom mt-4">
                        <mat-checkbox class="example-margin " [(ngModel)]="dtoInsuranceInfo.IsIcd" #IsIcd="ngModel" name="IsIcd"
                        [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && IsIcd.invalid }">Use ICD10 Starting</mat-checkbox>
                      </div>
                    </div>
                    <div class="col-md-2" style="z-index: 1">
                      <mat-form-field>
                          <mat-label>Date</mat-label>
                          <input matInput [matDatepicker]="exppicker" [(ngModel)]="dtoInsuranceInfo.IcdDate" #IcdDate="ngModel" name="IcdDate" required>
                          <mat-datepicker-toggle matSuffix [for]="exppicker "></mat-datepicker-toggle>
                          <mat-datepicker #exppicker></mat-datepicker>
                          <mat-error *ngIf="InsuranceInfofrm.submitted && IcdDate.invalid">
                            <mat-error *ngIf="IcdDate.errors.required">Date is required</mat-error>
                          </mat-error>
                        </mat-form-field>    
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex ml-4">
                      <mat-label class=" font-weight-500 mt-3 ml-4" >Processing Time</mat-label>
                      <mat-form-field class=" mr-2 ml-4">
                       
                        <input matInput [(ngModel)]="dtoInsuranceInfo.ProcessingTime" #ProcessingTime="ngModel" name="ProcessingTime"
                        [ngClass]="{ 'is-invalid': InsuranceInfofrm.submitted && ProcessingTime.invalid }"> 
                      </mat-form-field> 
                      <div class="mt-3">
                        <span  class="font-weight-500">Days</span>
                      </div>
                    </div>
              
                   
                  </div>

                  </div>
                </div>






                <div class="col-md-12 ">
                  <div class="row">
                  
                  
                  
                  </div>
                  </div>
                  <div class="col-md-12 mt-3 mb-4">
                    <div class="declaimar d-flex">
                      <i class="fas fa-exclamation-triangle mr-2 mt-1"></i>
                      <span>This payer requires special enrollment. if you have not enrolled yet, please contact the payer before transmitting eclaims.</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-right mt-3"  *ngIf="roleAccess.isCreate || roleAccess.isEdit">
                      <button type ="submit" mat-stroked-button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{!isEdit ? 'Save' : 'Update'}}</button>
                      <button type="button"  mat-stroked-button class="btn btn-cancel primary-btn-m h-40"  (click)="ResetForm()">Cancel</button>
        
                    </div>
                    </div>
        </form>
        <div class="col-md-12  mt-2" *ngIf="roleAccess.isView">
          <!-- <div  class="box-lead-head ">Transaction - Details </div>  -->
            <!--search-->
            <div class="row">
              <div  class="col-md-4 mt-3">Insurance Companies </div>
              <!-- <div  class="col-md-3 ml-auto"> 
                <mat-form-field class="w-100">
                  <mat-label>Search transaction values</mat-label>
                  <input matInput type="text" (keyup)="searchTblVal($event.target.value)"/>
                </mat-form-field>
                
               </div> -->
              </div>
             <!--search end-->
            <div class="col-md-12 table-custom mt-2 p-0">
              <div class="table-responsive">
                <mat-table #table [dataSource]="dataSource" matSort class="w-100">
                  <ng-container matColumnDef="sno">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="CompanyName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Company Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.CompanyName}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="PayerCategory">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Payer Category </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.PayerCategoryDescription}} </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="ICDDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> ICD Date </mat-header-cell>
                    <mat-cell *matCellDef="let element">  {{element.IcdDate |  date: 'MM/dd/yyyy'}}</mat-cell>
                  </ng-container>
        
                 
                  
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{ (roleAccess.isEdit) ? 'Actions' : '' }}  </mat-header-cell>
                    <mat-cell *matCellDef="let element;let i = index;"  style="padding-right: 13%;">
                      <button type="button" mat-icon-button matTooltip="Edit" (click)="onListEdit(element)" color="primary"  *ngIf="roleAccess.isEdit">
                        <img src="../../../assets/images/editblue.png">
                      </button>
                   

             
                        <button mat-icon-button matTooltip="Delete" (click)="ondeleteInsuranceInfo(element)"
                            color="warn">
                            <img src="../../../assets/images/deletered.png">
                        </button>
                  
                
                        <span (click)="onChangeStatusInsuranceInfo(element)">
                            <i class="fas fa-toggle-on"  style="padding-top: 25%;font-size: 24px;" *ngIf="element.IsActive"></i>
                            <i class="fas fa-toggle-off"  style="padding-top: 25%;font-size: 24px;" *ngIf="!element.IsActive"></i>
                        </span>
                    
                      
                    </mat-cell>
                  </ng-container>
        
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row [style.background-color]="(isEdit && row.InsuranceInfoId == this.dtoInsuranceInfo.InsuranceInfoId) ? 'aliceblue' : 'white'" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        
                </mat-table>
               
              </div>
            </div>
            <mat-paginator [pageSizeOptions]="[10,20,50,100]" showFirstLastButtons></mat-paginator>

          </div>
              </div>
              
           


          
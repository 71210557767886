
<div class="transaction-modules">
    <div class="col-md-12">
      <div class="row">
       <div class="col-md-2 pr-0">
         <!-- Tabs nav -->
         <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
           aria-orientation="vertical">
           <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Transaction Code" (click)="onTabChange(1)"
           [ngClass]="{'active': selectedTabIndex == 1}">
           <i class="fas fa-exchange-alt mr-2"></i>
           <span class="label-settings small text-uppercase">Transaction Code</span></a>
         </div>
         <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
           aria-orientation="vertical">
           <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Provider" (click)="onTabChange(2)"
           [ngClass]="{'active': selectedTabIndex == 2}">
           <i class="fas fa-file-invoice-dollar mr-2"></i>
           <span class="label-settings small text-uppercase">Provider Info</span></a>
         </div>
         <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
         aria-orientation="vertical">
         <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Provider" (click)="onTabChange(3)"
         [ngClass]="{'active': selectedTabIndex == 3}">
         <i class="fas fa-hand-holding-medical mr-2"></i>
         <span class="label-settings small text-uppercase">Insurance Info</span></a>
       </div>
       <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
         aria-orientation="vertical">
         <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Provider" (click)="onTabChange(4)"
         [ngClass]="{'active': selectedTabIndex == 4}">
         <i class="fas fa-diagnoses mr-2"></i> 
         <span class="label-settings small text-uppercase">Diagnosis code</span></a>
       </div>
         </div>
         <div class="col-md-10">
           <!-- Tabs content -->
           <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade shadow rounded  show active" id="v-pills-profile" role="tabpanel"
            aria-labelledby="v-pills-profile-tab" *ngIf="selectedTabIndex == 1">
            <app-transaction-code></app-transaction-code>
          </div>
          <!-- provider master-->
          <div class="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-home" role="tabpanel"
      aria-labelledby="v-pills-home-tab"  *ngIf="selectedTabIndex == 2">

            <div  class="box-lead-head w-100">Provider Details </div>
            <app-billing-provider></app-billing-provider>

          
            <!-- <form>
              <div class="col-md-12">
                
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Provider Code</mat-label>
                      <mat-select>
                        <mat-option value="one">First option</mat-option>
                        <mat-option value="two">Second option</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2">
                    <button mat-stroked-button class="btn primary-btn-filled-small primary-btn-m mt-3"> Rename</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                 
                      <div class="col-md-4">
                        <mat-form-field class="w-100">
                          <mat-label>Provider Type</mat-label>
                          <mat-select>
                            <mat-option value="one">First option</mat-option>
                            <mat-option value="two">Second option</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-md-4">
                        <mat-form-field class="w-100">
                          <mat-label>Speciality</mat-label>
                          <mat-select>
                            <mat-option value="one">First option</mat-option>
                            <mat-option value="two">Second option</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-md-4">
                        <div class="d-flex">
                        <mat-form-field class="flex-grow-1 mr-2">
                          <mat-label>Provider Group</mat-label>
                          <mat-select>
                            <mat-option value="one">First option</mat-option>
                            <mat-option value="two">Second option</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div class="ml-auto">
                          <button mat-stroked-button class="btn primary-btn-filled-small primary-btn-m mt-3"> New</button>
                        </div>
                      </div>
                      </div>
                      </div>
                  </div>
                
                </div>
                <div class="row">
                  <div class="col-md-4">
                  <mat-form-field class="w-100">
                    <mat-label>NPI</mat-label>
                    <input matInput>
                  </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>First Name</mat-label>
                      <input matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Middle Name</mat-label>
                      <input matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Last Name</mat-label>
                      <input matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Title</mat-label>
                      <input matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Spoken Name</mat-label>
                      <input matInput>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Select Gender</mat-label>
                      <mat-select>
                        <mat-option value="one">Male</mat-option>
                        <mat-option value="two">Female</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100 ">
                      <mat-label>Comment</mat-label>
                      <textarea matInput></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 bill-address-tab">
                     Nav tabs 

      <div class="card border-0">
        <div class="card-header p-1">
          <ul class="nav nav-tabs justify-content-left" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#home" role="tab">
                <i class="now-ui-icons objects_umbrella-13"></i> Address
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#profile" role="tab">
                <i class="now-ui-icons shopping_cart-simple"></i> Pay To Address
              </a>
            </li>
          
          </ul>
        </div>
        <div class="card-body p-1">
          <div class="tab-content text-center">
            <div class="tab-pane active" id="home" role="tabpanel">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="w-100">
                      <mat-label>Address</mat-label>
                      <textarea matInput></textarea>
                    </mat-form-field>
                    </div>
                  </div>  
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>City</mat-label>
                        <input matInput>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>State</mat-label>
                        <input matInput>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Zip</mat-label>
                        <input matInput>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Home</mat-label>
                        <input matInput>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Office</mat-label>
                        <input matInput>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Pager</mat-label>
                        <input matInput>
                      </mat-form-field>
                    </div>
                  </div>
              </div>
            </div>
            <div class="tab-pane" id="profile" role="tabpanel">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <mat-form-field class="w-100">
                      <mat-label>Address</mat-label>
                      <textarea matInput></textarea>
                    </mat-form-field>
                    </div>
                  </div>  
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>City</mat-label>
                        <input matInput>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>State</mat-label>
                        <input matInput>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Zip</mat-label>
                        <input matInput>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Home</mat-label>
                        <input matInput>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Office</mat-label>
                        <input matInput>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Pager</mat-label>
                        <input matInput> 
                      </mat-form-field>
                    </div>
                  </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>  
        </div>
         </div>
<div class="row">
  <div class="col-md-12 mt-2">
    <div class="row">
    <div  class="col-md-5 mt-2 ">
      <div  class="mt-2">
        <span  class="small-heading-label align-middle font-weight-500">By default use</span>
        <span  class="ml-4 yes-no-insurance">
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button class="mr-2" value="1">Federal Tax ID</mat-radio-button>
            <mat-radio-button value="2">SSN</mat-radio-button>
          </mat-radio-group>
        </span>
      </div>
    </div>
    <div class="col-md-3">
      <mat-form-field class="w-100">
        <mat-label>Federal Tax ID</mat-label>
        <input matInput>
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <div class="check-custom mt-4">
      <mat-checkbox class="example-margin">Signature On File</mat-checkbox>
    </div>
    </div>
  </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12 mt-3">
    Insurance Specific  FTN/SSN ID
  </div>
  <div class="col-md-4">
    <mat-form-field class="w-100">
      <mat-label>Inusrance</mat-label>
      <mat-select>
        <mat-option value="one">Male</mat-option>
        <mat-option value="two">Female</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <mat-form-field class="w-100">
      <mat-label>Provider ID</mat-label>
      <mat-select>
        <mat-option value="one">Male</mat-option>
        <mat-option value="two">Female</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-4">
    <button mat-stroked-button class="btn primary-btn-filled-small primary-btn-m mt-3"> Add</button>
                
  </div>
</div>
<div class="row">
  <div class="col-md-12 table-custom">
    <div class="table-responsive">
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" class="w-100">
      
         
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>
      
       
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Insurance </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>
      
        
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Provider ID </th>
            <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
          </ng-container>
      
 
          <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element"><a href="#" class="text-danger"><i class="fas fa-minus-circle"></i></a> </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                       showFirstLastButtons 
                       aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
    
  </div>
</div>


              
              </div>
            </form> -->
          </div>
          <!-- provider master-->
          <!-- Insurance companies-->
          <div id="v-pills-home" role="tabpanel"
          aria-labelledby="v-pills-home-tab"  *ngIf="selectedTabIndex == 3">
    
                <!-- <div  class="box-lead-head w-100">Diagnosis Code </div> -->
                <app-insurance-info></app-insurance-info>

  </div>
              <!-- insurance -->

              <div id="v-pills-home" role="tabpanel"
              aria-labelledby="v-pills-home-tab"  *ngIf="selectedTabIndex == 4">
        
                    <!-- <div  class="box-lead-head w-100">Diagnosis Code </div> -->
                    <app-diagnosis-code></app-diagnosis-code>

      </div>
    </div>
  </div>
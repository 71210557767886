import { Injectable, NgZone } from '@angular/core';
// import {
//     MatSnackBar,
//     MatSnackBarConfig,
//     MatSnackBarHorizontalPosition,
//     MatSnackBarVerticalPosition,
//     MatSnackBarRef
// } from '@angular/material/snack-bar';
import { MatSnackBar,MatSnackBarRef,MatSnackBarConfig,MatSnackBarVerticalPosition,MatSnackBarHorizontalPosition, } from '@angular/material/snack-bar';
@Injectable()
export class SnackBarService {

    snackBarConfig: MatSnackBarConfig;
    snackBarRef: MatSnackBarRef<any>;
    horizontalPosition: MatSnackBarHorizontalPosition = 'right';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    snackBarAutoHide = '3000';
    snackBarAutoHideLong = '10000';

    constructor(private snackBar: MatSnackBar, private zone: NgZone) { }

    openSnackBar(message) {
        this.snackBarConfig = new MatSnackBarConfig();
        this.snackBarConfig.horizontalPosition = this.horizontalPosition;
        this.snackBarConfig.verticalPosition = this.verticalPosition;
        this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);
        this.snackBarConfig.panelClass = 'custom-snackbar';
        this.snackBarRef = this.snackBar.open(message, 'x', this.snackBarConfig);
    }
    error(message: string) {
        this.snackBarConfig = new MatSnackBarConfig();
        this.snackBarConfig.horizontalPosition = this.horizontalPosition;
        this.snackBarConfig.verticalPosition = this.verticalPosition;
        this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);
        this.snackBarConfig.panelClass = 'snackbar-error';
        
        return this.snackBar.open(message, 'x',this.snackBarConfig);
    }

    success(message: string) {
        this.snackBarConfig = new MatSnackBarConfig();
        this.snackBarConfig.horizontalPosition = this.horizontalPosition;
        this.snackBarConfig.verticalPosition = this.verticalPosition;
        this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);
        this.snackBarConfig.panelClass = 'snackbar-success';
        return this.snackBar.open(message, 'x', this.snackBarConfig);
    }

    info(message: string) {
        this.snackBarConfig = new MatSnackBarConfig();
        this.snackBarConfig.horizontalPosition = this.horizontalPosition;
        this.snackBarConfig.verticalPosition = this.verticalPosition;
        this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);
        this.snackBarConfig.panelClass = 'snackbar-info';
        return this.snackBar.open(message, 'x', this.snackBarConfig);
    }

    errorLongDuration(message: string) {
        this.snackBarConfig = new MatSnackBarConfig();
        this.snackBarConfig.horizontalPosition = this.horizontalPosition;
        this.snackBarConfig.verticalPosition = this.verticalPosition;
        this.snackBarConfig.duration = parseInt(this.snackBarAutoHideLong, 0);
        this.snackBarConfig.panelClass = 'snackbar-error';
        
        return this.snackBar.open(message, 'x',this.snackBarConfig);
    }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { insuranceInfo } from 'src/app/_models/_Billing/insuranceInfo';
import { Router,ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { BillingService } from '../billing.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeadService } from 'src/app/patient-lead/lead.service';
import { CommonService } from 'src/app/common/common.service';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
declare function decrypt(data): any;

@Component({
  selector: 'app-insurance-info',
  templateUrl: './insurance-info.component.html',
  styleUrls: ['./insurance-info.component.scss']
})
export class InsuranceInfoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('InsuranceInfofrm') InsuranceInfofrm: NgForm;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['sno','CompanyName', 'PayerCategory', 'ICDDate', 'actions'];
  dtoInsuranceInfo: insuranceInfo;
  roleAccess: any;
  feeScheduleList : any;
  payerCategoryList : any;
  insuranceGroupList : any;
  ddlStates : any;
  ddlCitiesLoad : any;
  ddlCities : any;
  billingGroupList : any;
  billingGroupAddList : any = [];
  insurancelists : any = [];
  isEdit: boolean = false;
  InsuranceCode : any;
  displayedColumns1: string[] = [ 'groupCode','actions'];
  dataSource1 = new MatTableDataSource();

  constructor(public roleaccessservice:PageAccessService, public leadservice: LeadService,
 private snackbar: SnackBarService, private route: ActivatedRoute,public billingservice: BillingService,    private dialog: MatDialog,
    private router: Router, public spinnerservice: NgxSpinnerService,  public commonService: CommonService,) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.BillingMasters;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);

    this.dtoInsuranceInfo = new insuranceInfo;
    this.getInsuranceMaster();
    this.getCityStatesMaster()
    this.getBillingGroup();
    this.bindGridData();
  }

  PostInsurancefrm(form: NgForm){
    this.spinnerservice.show();
     if (!form.valid) {
       this.spinnerservice.hide();
       return;
     }

    if(this.dtoInsuranceInfo.BillingSchedule == "1")
    {
      this.dtoInsuranceInfo.IsInsurance = true;
      this.dtoInsuranceInfo.IsLocation = false;
    }
    else if(this.dtoInsuranceInfo.BillingSchedule == "2")
    {
      this.dtoInsuranceInfo.IsInsurance = false;
      this.dtoInsuranceInfo.IsLocation = true;
    }
    this.dtoInsuranceInfo.EclaimsId = +this.dtoInsuranceInfo.EclaimsId;
    this.dtoInsuranceInfo.EligibilityId = +this.dtoInsuranceInfo.EligibilityId;  
    this.dtoInsuranceInfo.DtoBillingGroupList = this.billingGroupAddList;
   const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoInsuranceInfo, false);
   this.billingservice.PostInsurancefrm(dataToEncrypt).subscribe(
     (res: any) => {
       this.spinnerservice.hide();
       this.snackbar.success(res);
       this.isEdit = false;
      this.ResetForm();
      this.bindGridData();
      },
     err => {
       this.spinnerservice.hide();
       this.snackbar.error(err.error);
     },
   );
 }
  async getCityStatesMaster() {
    var res = await this.leadservice.getstateMasterForAllState();
    if (res != null) {
      this.ddlStates = this.commonService.decrypdata(res.states);
      this.ddlCitiesLoad = this.commonService.decrypdata(res.cities);
    }
 
  
  }
  onChangePhysicianState(state) {
    if (this.ddlCitiesLoad.length>0) {
      this.ddlCities = this.ddlCitiesLoad.filter(x => x.StatesId == state)
    } else {
      this.leadservice.getcities(state).then(
        res => {
          this.ddlCities = this.commonService.decrypdata(res.cities);;
        },
        error =>
          this.spinnerservice.hide()
      );
    }
  }
  bindGridData(){
    this.spinnerservice.show();
     this.billingservice.getInsuranceInfo().subscribe(
       res => {
         if (res != null) {
           this.spinnerservice.hide();
          let result=decrypt(res.objInputString)
           this.insurancelists=result;
           console.log(result);
           this.dataSource = new MatTableDataSource(this.insurancelists);
         this.dataSource.paginator = this.paginator;
         this.dataSource.sort = this.sort;
           }
       },
       err => {
         this.spinnerservice.hide();
       },
     );
   }

  getInsuranceMaster()
  {
    
    this.billingservice.getInsuranceMaster().subscribe(
      res => {
        if (res != null) {
        //  let result=decrypt(res.objInputString)
       //  console.log(result);
          this.feeScheduleList = res.feeSchedule;
          this.insuranceGroupList = res.insuranceGroup;
          this.payerCategoryList = res.payerCategory;
        
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  
  }
  removeBillGroup(item)
  {
    var bill = this.billingGroupAddList.filter(x=> x.BillingGroupId == item.BillingGroupId)[0];
    var index = this.billingGroupAddList.indexOf(bill);
    if (index != -1) {
      this.billingGroupAddList.splice(index, 1);
    }
    this.dataSource1 = new MatTableDataSource(this.billingGroupAddList);
  }
  addBillingGroup()
  {
    var valExist = this.billingGroupAddList.filter(x=> x.BillingGroupId == this.dtoInsuranceInfo.BillingGroupId);
    if(valExist.length == 0)
    {
      var billList = this.billingGroupList.filter(x=>x.billingGroupId == this.dtoInsuranceInfo.BillingGroupId);
      if(billList.length > 0)
      {
        let obj = {
          BillingGroupId : billList[0].billingGroupId,
          GroupCode : billList[0].groupCode,
          Comment : billList[0].comment
        }
        this.billingGroupAddList.push(obj);
        this.dataSource1 = new MatTableDataSource(this.billingGroupAddList);
      }
    }
   
   
  }
  ResetForm()
  {
    this.InsuranceCode =
    this.isEdit=false;
    this.billingGroupAddList = [];
    this.InsuranceInfofrm.resetForm();
    this.dtoInsuranceInfo = new insuranceInfo;
    this.dataSource1 = new MatTableDataSource(this.billingGroupAddList);
    this.bindGridData();
  }
  onListEdit(item)
  {
    this.ResetForm();
    this.isEdit = true;
    this.dtoInsuranceInfo = item;
    this.dtoInsuranceInfo.EligibilityId = item.EligibilityId.toString();
    this.dtoInsuranceInfo.EclaimsId = item.EclaimsId.toString();
    if(this.dtoInsuranceInfo.IsInsurance)
    {
      this.dtoInsuranceInfo.BillingSchedule = "1"
    }
    else 
    {
      this.dtoInsuranceInfo.BillingSchedule = "2"
    }
    if(item.DtoBillingGroupList.length > 0)
    {
      item.DtoBillingGroupList.forEach(val => {
        var billList = this.billingGroupList.filter(x=>x.billingGroupId == val.BillingGroupId);
      if(billList.length > 0)
      {
        let obj = {
          BillingGroupId : billList[0].billingGroupId,
          GroupCode : billList[0].groupCode,
          Comment : billList[0].comment
        }
        this.billingGroupAddList.push(obj);
      }
      });
      this.dataSource1 = new MatTableDataSource(this.billingGroupAddList);
    }
    this.onChangePhysicianState(item.StaesId);
  }
  
  goToLink(){
    if(this.dtoInsuranceInfo.Website !=" " && this.dtoInsuranceInfo.Website !='' && this.dtoInsuranceInfo.Website != undefined)
    {
      window.open(this.dtoInsuranceInfo.Website, "_blank");
      
    }
   
  }
  getBillingGroup()
  {
    
    this.billingservice.getBillingGroup().subscribe(
      res => {
        if (res != null) {
        //  let result=decrypt(res.objInputString)
       //  console.log(result);
       this.billingGroupList = res;
        
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  
  }

  onChangeStatusInsuranceInfo(element) {

    this.spinnerservice.show();
    element.IsActive = !element.IsActive;
    const dataToEncrypt = this.commonService.DataToEncrypt(element, false);
    this.billingservice.changeStatusInsuranceInfo(dataToEncrypt).subscribe(
      (res: any) => {
        this. bindGridData();
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
      },
      err => {
        this.snackbar.error(err.error);
        this.spinnerservice.hide();
      },
    );

  }

  ondeleteInsuranceInfo(element) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        console.log(element);
        let id=element.InsuranceInfoId;

        const dataToEncrypt = this.commonService.DataToEncrypt(element, false);
        this.billingservice.deleteInsuranceInfo(dataToEncrypt).subscribe(
          (res: any) => {
            this. bindGridData();
            this.spinnerservice.hide();
            this.snackbar.success(JSON.parse(res).message);

          },
          err => {
            this.snackbar.error(err.error);
            this.spinnerservice.hide();
          },
        );
      }
    });

  }

}

<!-- <h2>Goals </h2> -->
<div class="row">
<div class="col-md-12 px-4">

  <button
  type="submit"
  class="btn primary-btn-outlined-small ng-star-inserted float-right mb-2"
  (click)="generatePdf()"
>
  Generate PDF
</button>
  </div>
<div class="col-md-12 mt-4">  
<div class="col-md-12 p-0">
  <!-- <button type="button" class="btn primary-btn-outlined ml-auto" (click)="isAddGoals = true">
    <i class="fas fa-plus-circle mr-2"></i>Add New Goals
  </button> -->
  <div class="selection-gaols-head" *ngIf="lstTreatmentGoals?.length > 0" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
    Selected Goals ({{lstTreatmentGoals?.length}})
  </div>
  <cdk-accordion class="example-accordion sugges-list-goal top-list-goal" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
    <cdk-accordion-item *ngFor="let item of lstTreatmentGoals;let index=index" #accordionItem="cdkAccordionItem"
      class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
      [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
      <div class="example-accordion-item-header" (click)="accordionItem.toggle()">


        <h4 class="card-title mt-2 "><span class="mt-2 text-success"><i class="fas fa-check mr-2"></i> </span>Goal Name
          : <span> {{ item.goalName }}</span></h4>
      <!--  <h4 class="card-title1 mt-2 ">Category : <span>{{item.catagoryName}}</span></h4>-->
       <h4 class="card-title1 mt-2 "></h4>
        <a class="example-accordion-item-description mt-2 text-danger " (click)="ondeletegoals(item);" *ngIf="!isTreatmentGoalDisabled" ><i class="fas fa-times mr-2"></i> Remove
          Goal</a>
        <span class="">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
      <div class="example-accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index">
        <h4 class="card-title">Description : <span [innerHtml]="item.definition"></span></h4>
        <div class="col-md-12 ">
          <div class="row">
            <div class="col p-0">
              <h4 class="card-title">Diagnosis Code : <span>{{item.diagnosisCode}} </span></h4>
            </div>
            <div class="col text-right p-0">
              <h4 class="card-title">No. of Benchmarks : <span>{{item.measures?.length}}</span></h4>
            </div>
          </div>
        </div>
        <div class="col-md-12 ">
          <div class="row">
            <div class="col p-0">
              <h4 class="card-title">Applicable Treatment : <span *ngFor="let therapy of item.applicableTreatmentSelected let last=last" >
                <!-- {{therapy==1?'Individual Therapy':therapy==2?'Family Therapy':therapy==3?'Group Therapy':therapy==4?'Psychiatric Therapy':therapy==5?'Nutrition Therapy':therapy==6?'Speech Theraphy':therapy==7?'Psychiatric Evaluation':'Psychiatric Medication'}}{{last?'':','}} -->
                {{therapy==1?'Individual Therapy':therapy==2?'Family Therapy':therapy==3?'Group Therapy':therapy==4?'Psychiatric Therapy':therapy==5?'Nutrition Therapy':therapy==6?'Speech Theraphy':therapy==7?'Psychiatric Evaluation':therapy==8?'Psychiatric Medication':therapy==11?'Speech Therapy':'Nutrition Therapy'}}{{last?'':','}} 
      
              </span> <span><a><i class="fas fa-edit" (click)="OnEditApplicaleTreatment(item)"></i></a></span> </h4> 
              
            </div>
            <div class="col text-right p-0">
             
            </div>
          </div>
        </div>

        

      </div>
    </cdk-accordion-item>
  </cdk-accordion>
</div>
<div class="goal-suggestion" *ngIf="!isShowAllGoal" >

  <div class="d-flex justify-content-between">
    <div class="p-2 col-8">
      <h2 class="prsenting-tab">Suggested Goals <span class="sub-goal-div">Based on Patients Diagnosis</span></h2>
    </div>
      <div  class="p-2 search col-4">
        <input  type="text" placeholder="Search" name="" class="search-input " [(ngModel)]="searchpatientListtxt" (keyup)="OnSearchPatient()"><a  class="search-icon"><i  class="fa fa-search"></i></a>
    </div>
  </div>
  
 <div class="sugges-list-goal">

    <!-- <div  class="no-goal text-center pt-4 pb-4" *ngIf="noallgoal()">
      <img src="../../../../../assets/images/No-goals.png" style="width:204px;" />
    </div> -->
    <div class="col-md-12 p-0 mt-2 mb-2">
      <div class="row">
        <ng-container *ngFor="let item of suggestedGoals;let i = index">

          <div class="col-md-6" *ngIf="getGoalinTreatment(item,1)">

            <div class="card selected">
              <!-- (dblclick)="onGoalSelect(item)" -->
              <div class="card-body" >
                <input type="radio" name="select{{i}}" [checked]="item.isSelected">
                <label>

                </label>

                <h4 class="card-title goal-title-prom mt--27 d-flex"><img style="width: 30px;margin-right: 10px;"
                    src="../../../../../assets/images/goalsnew.png"> {{item.goalName}}<button class="ml-auto btn primary-btn-outlined-small" (click)="assignGoal(item)" *ngIf="roleAccess.isCreate||roleAccess.isEdit"><i class="fas fa-user-check mr-2"></i>Assign</button></h4>
                <h4 class="card-title">Category : <span *ngFor="let catName of item.catagoryName let last=last">{{catName}}{{last?'':','}}</span> </h4>
                <h4 class="card-title">Description : <span ><read-more [text]="item.definition" [maxLength]="200"></read-more></span>
                </h4>
                <h4 class="card-title">Diagnosis Code : <span>{{item.diagnosisCode}}</span></h4>
                <h4 class="card-title">No. of Benchmarks : <span>{{item.measures?.length}}</span> </h4>
                <h4 class="card-title">Applicable Treatment : <span *ngFor="let therapy of item.applicableTreatment let last=last" >
                  <!-- {{therapy==1?'Individual Therapy':therapy==2?'Family Therapy':therapy==3?'Group Therapy':therapy==4?'Psychiatric Therapy':therapy==5?'Nutrition Therapy':therapy==6?'Speech Theraphy':therapy==7?'Psychiatric Evaluation':'Psychiatric Medication'}}{{last?'':','}} -->
                  {{therapy==1?'Individual Therapy':therapy==2?'Family Therapy':therapy==3?'Group Therapy':therapy==4?'Psychiatric Therapy':therapy==5?'Nutrition Therapy':therapy==6?'Speech Theraphy':therapy==7?'Psychiatric Evaluation':therapy==8?'Psychiatric Medication':therapy==11?'Speech Therapy':'Nutrition Therapy'}}{{last?'':','}} 

                </span>  </h4>
                <!-- <div class="widget-49-meeting-action text-right">
                <a  class="btn btn-sm btn-flash-border-primary">View All</a>[innerHtml]="item.definition"
              </div> -->
              </div>
            </div>
          </div>
        </ng-container>

        <div class="col-md-12 widget-49-meeting-action text-right">
          <a class="btn btn-sm btn-flash-border-primary" (click)="isShowAllGoal = !isShowAllGoal">View All Goals</a>
        </div>
      </div>
    </div>

  </div>

</div>
<div class="all-goals mt-3" *ngIf="isShowAllGoal" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
  <div class="d-flex justify-content-between">
    <div class="p-2 col-8">
      <h2 class="prsenting-tab">All Goals</h2>
    </div>
      <div  class="p-2 search col-4">
        <input  type="text" placeholder="Search" name="" class="search-input" [(ngModel)]="searchpatientListtext" (keyup)="OnSearchPatientAll()"><a  class="search-icon"><i  class="fa fa-search"></i></a>
    </div>
  </div>
 
  <div class="sugges-list-goal">
    <!-- <div  class="no-goal text-center pt-4 pb-4" *ngIf="allGoals?.length == 0">
      <img src="../../../../../assets/images/No-goals.png" style="width:204px;" />
    </div> -->
    <div class="col-md-12 p-0 mt-2 mb-2">
      <div class="row">

        <ng-container *ngFor="let item of allGoals;let i = index">
          <div class="col-md-6" *ngIf="getGoalinTreatment(item,2)">
            <div class="card selected">
              <!-- (dblclick)="onGoalSelect(item)" -->
              <div class="card-body" >
                <input type="radio" name="select{{i}}" [checked]="item.isSelected">
                <label>

                </label>

                <h4 class="card-title goal-title-prom mt--27 d-flex"><img style="width: 30px;margin-right: 10px;"
                    src="../../../../../assets/images/goalsnew.png"> {{item.goalName}} <button class="ml-auto btn primary-btn-outlined-small" (click)="assignGoal(item)" *ngIf="roleAccess.isCreate||roleAccess.isEdit"><i class="fas fa-user-check mr-2"></i>Assign</button></h4>
                <h4 class="card-title">Category : <span>{{item.catagoryName}}</span> </h4>
                <h4 class="card-title">Description : <span><read-more [text]="item.definition" [maxLength]="200"></read-more></span>
                </h4>
                <h4 class="card-title">Diagnosis Code : <span>{{item.diagnosisCode}}</span></h4>
                <h4 class="card-title">No. of Benchmarks : <span>{{item.measures?.length}}</span> </h4>
                <h4 class="card-title">Applicable Treatment : <span *ngFor="let therapy of item.applicableTreatment  let last=last" >
                   <!-- {{therapy==1?'Individual Therapy':therapy==2?'Family Therapy':therapy==3?'Group Therapy':therapy==4?'Psychiatric Therapy':therapy==5?'Nutrition Therapy':therapy==6?'Speech Theraphy':therapy==7?'Psychiatric Evaluation':'Psychiatric Medication'}}{{last?'':','}}  -->
                   {{therapy==1?'Individual Therapy':therapy==2?'Family Therapy':therapy==3?'Group Therapy':therapy==4?'Psychiatric Therapy':therapy==5?'Nutrition Therapy':therapy==6?'Speech Theraphy':therapy==7?'Psychiatric Evaluation':therapy==8?'Psychiatric Medication':therapy==11?'Speech Therapy':'Nutrition Therapy'}}{{last?'':','}} 

                </span> </h4>
                <!-- <div class="widget-49-meeting-action text-right">
                <a  class="btn btn-sm btn-flash-border-primary">View All</a>
              </div> -->
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
    <div class="col-md-12 widget-49-meeting-action text-right">
      <a class="btn btn-sm btn-flash-border-primary" (click)="isShowAllGoal = !isShowAllGoal">View Suggested Goals</a>
    </div>
  </div>
</div>



<div class="comment-popup treatment-goals anyclick-popup">

<div class="benchmark-popup">
  <div class="modal" tabindex="-1" role="dialog" [style.display]="isAssign ? 'block' : 'none'">
    <div class="modal-dialog" id="treatment-goals"  role="document">
     
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Applicable Treatment </h6>
          <button type="button" class="close"  data-dismiss="modal"
            aria-label="Close" (click)="OnCancel()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
 
          <div class="col-md-12 p-0">

            <div class="form-group">

              <div class="check-list-custom">
                <mat-selection-list class="check-custom d-inline-block mr-2" required #program="ngModel"
                    name="program" [(ngModel)]="applicableTreatment1" [ngModelOptions]="{standalone: true}">
                    <mat-list-option [value]="therapy" *ngFor="let therapy of applicableTrtMent">
                      {{therapy==1?'Individual Therapy':therapy==2?'Family Therapy':therapy==3?'Group Therapy':therapy==4?'Psychiatric Therapy':therapy==5?'Nutrition Therapy':therapy==6?'Speech Theraphy':therapy==7?'Psychiatric Evaluation':therapy==8?'Psychiatric Medication':therapy==11?'Speech Therapy':'Nutrition Therapy'}}
                    </mat-list-option>
                </mat-selection-list>

                <div *ngIf="isApplicableError" class="error mt-2">Please select applicable treatment</div>
            </div>

        </div>




    </div>

</div>
<div class="modal-footer">
  <div class="col-md-12 p-0 float-left text-right">
      <button mat-stroked-button class="btn primary-btn-outlined-small float-right mt-2 ml-2 " (click)="OnCancel()">Cancel</button>
      <button  mat-stroked-button  class="btn primary-btn-filled-small float-right mt-2 mr-2 " (click)="onGoalSelect(goalitem)">Assign</button>    
  </div>
</div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>

<a mimetype="application/octet-stream" #downloadLink></a>
<!-- <div class="col-md-12 p-3  sep-section d-none" >
  <form (ngSubmit)="frmTreatmentGoals.form.valid  && postTreatmentGoals(frmTreatmentGoals)" id="frmTreatmentGoals"
    autocomplete="off" #frmTreatmentGoals="ngForm" novalidate>
    <div class="row">

      <div class="col-md-4">
        <label class="treat-label m-0">Choose your Goals</label>
        <div class="col-md-12 p-0">
          <select class="form-control p-0" [(ngModel)]="_dtoGoals.goalId" #goalName="ngModel" name="goalName" required
            (change)="onGoalSelection($event)">
            <option *ngFor="let item of ddlGoals" [value]="item.goalId">
              {{item.goalName}}
            </option>
          </select>
          <mat-error *ngIf="frmTreatmentGoals.submitted && goalName.invalid">
            <mat-error *ngIf="goalName.errors.required">Goal Name is
              required</mat-error>
          </mat-error>

        </div>
      </div>

    </div>
    <div class="row goal-wrapper" *ngFor="let item of _dtoGoalMeasure;let i=index">

        <div class="col-md-2 pr-0">
          <label class="treat-label m-0">Interventions</label>
          <div class="col-md-12 p-0 mt-2">
            {{item.interVention}} weeks
          </div>

        </div>
        <div class="col-md-2 ">

          <div class="col-md-12 p-0 mt-1">
            <mat-form-field class="w-100">
              <mat-label>Schedule Date</mat-label>
              <input matInput [matDatepicker]="picker" [min]="getminScheduleDate(item,i)" [max]="item.scheduleDate" readonly
                (click)="picker.open()" [(ngModel)]="item.scheduleDate" #scheduleDate="ngModel"
                name="scheduleDate{{i}}" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-2">
          <label class="treat-label m-0">Achievable</label>
          <div class="col-md-12 p-0 mt-2">
            {{item.achievable}}

          </div>

        </div>
        <div class="col-md-2">
          <label class="treat-label m-0">Benchmark</label>
          <div class="col-md-12 p-0 mt-2">
            {{item.benchMark}} {{item.timeType == 1 ? "Seconds" : item.timeType == 2 ? "Minutes" : "Hours"}}

          </div>

        </div>
        <div class="col-md-2">
          <label class="treat-label m-0">Actual</label>
          <div class="col-md-12">

            <div class="row">

              <div class="col-md-12 p-0">
                <input type="text" class="form-control" [(ngModel)]="item.benchMarkActual" #benchMarkActual="ngModel"
                  name="benchMarkActual{{i}}" maxlength="2" customMin="1" customMax="60" numeric decimals="0" required />
              </div>
              <mat-error *ngIf="frmTreatmentGoals.submitted && benchMarkActual.invalid">
                <mat-error *ngIf="benchMarkActual.errors.required">Actuals is required
                </mat-error>
                <mat-error *ngIf="benchMarkActual.errors?.customMin">Minimum required number is 1.
                </mat-error>
                <mat-error *ngIf="benchMarkActual.errors?.customMax">Maximum number can be 60.
                </mat-error>

              </mat-error>
            </div>


          </div>

        </div>
        <div class="col-md-2">
          <label class="treat-label m-0">Staff</label>
          <mat-form-field class="w-100">
            <mat-select [(ngModel)]="item.staffId" #staffId="ngModel" name="staffId" required>
              <mat-option *ngFor="let x of lstreportstaff" [value]="x.userId">
                {{x.firstName}} {{x.lastName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="frmTreatmentGoals.submitted && staffId.invalid">
              <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="staffId.errors.required">
                Co-ordinator is
                required</mat-error>
            </mat-error>
          </mat-form-field>
        </div>

    </div>
    <div class="col-md-12 text-right mt-3">
      <button type="submit" form="frmTreatmentGoals" mat-stroked-button title="{{!isEditGoals ? 'Save' : 'Update'}}"
        class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{!isEditGoals ? 'Save'
        :'Update'}}</button>
      <button mat-stroked-button (click)="onCancelgoal()" class="btn btn-cancel primary-btn-m h-40" type="button"
        title="Cancel">Cancel</button>

    </div>
  </form>
</div> -->
<!-- <div class="col-md-12 goal-list p-0">

  <div class="col-md-12  " *ngFor="let item of lstTreatmentGoals;let i=index">
    <div class="row">

      <div class="col-md-12 mt-3 p-0">
        <div class="task-content">
          <div class="task-items ">
            <div class="task-item animate__animated animate__zoomIn task-item-completed "
              style="animation: 0s ease 0s 1 normal none running none; position: relative; top: 0px; left: 0px;">
              <div class="col-md-12 ">
                <div class="row">
                  <div class="col-md-3 pl-0">
                    <div class="goal-heads">Goal</div>
                    {{item.goalName}}
                  </div>
                  <div class="col-md-3 pl-0">
                    <div class="goal-heads">Overarching period</div>
                    3 months
                  </div>
                  <div class="col-md-5 pl-0">
                    <div class="goal-heads">Relevance</div>
                    {{item.relevance}}
                  </div>
                </div>
              </div>
              <div class="col-md-12 border-top mt-2 pt-2 " *ngFor="let subitem of item?.measures">
                <div class="row">
                  <div class="task-item-body mr-1">
                    <div class="goal-heads">Achievable/Evidence</div>
                    {{subitem.achievable}}
                  </div>
                  <div class="task-item-body mr-1">
                    <div class="goal-heads">Benchmark Unit</div>
                    <i class="fas fa-stopwatch mr-2"></i> {{subitem.benchMark}} {{subitem.timeType == 1 ? "Seconds" :
                    subitem.timeType == 2 ? "Minutes" : "Hours"}}
                  </div>
                  <div class="task-item-body mr-1">
                    <div class="goal-heads">Benchmark Actuals</div>
                    <i class="fas fa-stopwatch mr-2"></i> {{subitem.benchMarkActual}} {{subitem.timeType == 1 ?
                    "Seconds" : subitem.timeType == 2 ? "Minutes" : "Hours"}}
                  </div>
                  <div class="task-item-body mr-1">
                    <div class="goal-heads">Benchmark Actuals</div>
                    {{subitem.interVention}}weeks
                  </div>
                </div>
              </div>

              =
              <div class="task-item-action ">

                <a title="edit" class="cursor-pointer" role="button" (click)="oneditgoals(item)">
                  <i class="far fa-edit"></i>
                </a>
                <a title="delete" role="button" class="cursor-pointer task-item-sortable-handle ui-sortable-handle "
                  (click)="ondeletegoals(item)">
                  <i class="fas fa-trash-alt"></i>
                </a>
              </div>
            </div>

          </div>


        </div>

      </div>
    </div>
  </div>

</div> -->

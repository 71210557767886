import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ClinicianService } from '../../../clinician.service';
import { ReviewDto } from 'src/app/_models/_Treatment/_TherapyPopUpObj';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';

@Component({
  selector: 'app-medication-reviewsheet',
  templateUrl: './medication-reviewsheet.component.html',
  styleUrls: ['./medication-reviewsheet.component.scss']
})
export class MedicationReviewsheetComponent implements OnInit {
  patientName: any;
  userid: any;
  Meetingscheduleid: any;
  currentDate: any;
  currentDate2: any;
  currentDate3: any;
  reviewDto: ReviewDto = new ReviewDto();
  dob: any;
  medicationForm: FormGroup;
  isESignAttached: boolean;
  updatedsignatureImg: any;
  isAttachEsign: boolean;
  defaultImage: any;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  staffName: any;
  StartDate: any;
  StartTime: any;
  constructor(private router: Router, private route: ActivatedRoute, public commonService: CommonService,
    public global: Global, private dialog: MatDialog, public spinnerservice: NgxSpinnerService, public clinicianservice: ClinicianService,
    public datepipe: DatePipe, public domSrv: DomSanitizer, private snackbar: SnackBarService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createForm();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientName = this.commonService.FrontEndDecryption(params.patientName).replace(/"/g, "");
        this.staffName = this.commonService.FrontEndDecryption(params.staffName).replace(/"/g, "");
        this.userid = Number(this.commonService.FrontEndDecryption(params.clinicianId).replace(/"/g, ""));
        this.Meetingscheduleid = Number(this.commonService.FrontEndDecryption(params.Meetingscheduleid).replace(/"/g, ""));


        this.medicationForm.controls["patientName"].setValue(this.patientName)
        this.medicationForm.controls["staffName"].setValue(this.staffName)
        this.medicationForm.controls["userid"].setValue(this.userid)
        this.medicationForm.controls["Meetingscheduleid"].setValue(this.Meetingscheduleid)
        console.log(this.staffName)

        var currentDate = new Date();
        this.medicationForm.controls["currentDate"].setValue(currentDate);
        this.getReviewPatientDob(this.Meetingscheduleid);
        this.getpdfdatareview(this.Meetingscheduleid);
      }


    });


  }

  createForm() {
    this.medicationForm = this.fb.group({
      patientName: ['',],
      dob: ['',],
      siteOrProgram: ['',],
      currentDate: ['',],
      medicationName: ['',],
      staffName: ['',],
      medicationHelps: ['',],
      medicationTime: ['',],
      timesPerDay: ['',],
      angerMoody: [false],
      depression: [false],
      adhd: [false],
      anxiety: [false],
      impulsivity: [false],
      otherSideEffectsangerMoody: [''],
      dizzy: [false],
      stomachHurts: [false],
      sleepy: [false],
      shaky: [false],
      otherSideEffects: [''],
      otherSpecify: [''],
      medicationOnFeelGood: [''],
      medicationImportant: [''],
      medicationOnSummer: [''],
      medicationOnTimeAction: [''],
      userid: [''],
      Meetingscheduleid: [''],
      otherInputValue: [''],
      signatureImg: [''],
      signatureImg2: [''],
      signatureImg3: [''],
      currentDate2: [null,],
      currentDate3: [null,],
      NurseName: ['',],
      ClinicianName: ['',]
    });
  }

  getpdfdatareview(Meetingscheduleid) {
    this.spinnerservice.show();
    this.clinicianservice.getpdfdatareview(Meetingscheduleid).subscribe(
      (res: any) => {
        if (res.objInputString != null) {
          var fres = this.commonService.decrypdata(res.objInputString);
          var reviewDto = fres[0];
          console.log(reviewDto)
          var date1 = new Date(reviewDto.CurrentDate);
          date1.setHours(1, 0, 0, 0);
          const dateObject = new Date(date1 + 'Z');

          const formattedDate = this.formatDate(dateObject);
          var currentDate = formattedDate ? formattedDate : currentDate
          this.medicationForm.controls["currentDate"].setValue(currentDate);
          this.medicationForm.controls["medicationName"].setValue(reviewDto.MedicationName);
          this.medicationForm.controls["NurseName"].setValue(reviewDto.NurseName);
          this.medicationForm.controls["medicationHelps"].setValue(reviewDto.MedicationHelps);
          this.medicationForm.controls["medicationTime"].setValue(reviewDto.MedicationTime);
          this.medicationForm.controls["timesPerDay"].setValue(reviewDto.TimesPerDay);
          this.medicationForm.controls["angerMoody"].setValue(reviewDto.AngerMoody);
          this.medicationForm.controls["depression"].setValue(reviewDto.Depression);
          this.medicationForm.controls["adhd"].setValue(reviewDto.Adhd);
          this.medicationForm.controls["anxiety"].setValue(reviewDto.Anxiety);
          this.medicationForm.controls["impulsivity"].setValue(reviewDto.Impulsivity);
          this.medicationForm.controls["otherSideEffectsangerMoody"].setValue(reviewDto.OtherSideEffectsangerMoody);
          this.medicationForm.controls["dizzy"].setValue(reviewDto.Dizzy);
          this.medicationForm.controls["stomachHurts"].setValue(reviewDto.StomachHurts);
          this.medicationForm.controls["sleepy"].setValue(reviewDto.Sleepy);
          this.medicationForm.controls["shaky"].setValue(reviewDto.Shaky);
          this.medicationForm.controls["otherSideEffects"].setValue(reviewDto.OtherSideEffects);
          this.medicationForm.controls["otherSpecify"].setValue(reviewDto.OtherSpecify);
          this.medicationForm.controls["medicationOnFeelGood"].setValue(reviewDto.MedicationOnFeelGood);
          this.medicationForm.controls["medicationImportant"].setValue(reviewDto.MedicationImportant);
          this.medicationForm.controls["medicationOnSummer"].setValue(reviewDto.MedicationOnSummer);
          this.medicationForm.controls["medicationOnTimeAction"].setValue(reviewDto.MedicationOnTimeAction);
          var dob = this.datepipe.transform(reviewDto.Dob, 'MM/dd/yyyy');
          this.medicationForm.controls["dob"].setValue(dob);
          this.medicationForm.controls["siteOrProgram"].setValue(reviewDto.siteOrProgram);
          (document.getElementById('esign') as HTMLImageElement).src = reviewDto.signatureImg
          this.medicationForm.controls["signatureImg"].setValue((document.getElementById('esign') as HTMLImageElement).src);
          (document.getElementById('esign2') as HTMLImageElement).src = reviewDto.signatureImg2
          this.medicationForm.controls["signatureImg2"].setValue((document.getElementById('esign2') as HTMLImageElement).src);
          (document.getElementById('esign3') as HTMLImageElement).src = reviewDto.signatureImg3
          this.medicationForm.controls["signatureImg3"].setValue((document.getElementById('esign3') as HTMLImageElement).src);
          // var date2 = new Date(reviewDto.currentDate2);
          // date2.setHours(1, 0, 0, 0);
          // const dateObject2 = new Date(date2 + 'Z');
          this.medicationForm.controls["currentDate2"].setValue(reviewDto.currentDate2);

          // var date3 = new Date(reviewDto.currentDate3);
          // date3.setHours(1, 0, 0, 0);
          // const dateObject3 = new Date(date3 + 'Z');
          this.medicationForm.controls["currentDate3"].setValue(reviewDto.currentDate3);
          this.medicationForm.controls["ClinicianName"].setValue(reviewDto.ClinicianName);
        }
      }
    );
    this.spinnerservice.hide();
  }
  formatDate(date: Date) {
    return date;
  }

  onSubmit() {
    this.spinnerservice.show();
    this.clinicianservice.saveReviewSheet(this.medicationForm.value).subscribe(
      (res: any) => {
        if (res) {
          this.snackbar.success("Data saved successfully");
          this.getpdfdatareview(this.Meetingscheduleid);
        }
        this.spinnerservice.hide();
      }
    );
  }


  onGeneratePDF() {

    this.spinnerservice.show();
    this.clinicianservice.onGenerateMedicationReviewSheet(this.Meetingscheduleid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();

        const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }
  openDialog() {
    this.global.isconsentesign = false;

    const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
      {
        data: { parentinfoId: this.userid, isfutureSign: false, isParentsign: true },
        autoFocus: false
      });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign) {
        this.medicationForm.controls["signatureImg"].setValue(this.updatedsignatureImg)
        this.isAttachEsign = true;
      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.medicationForm.controls["signatureImg"].setValue(this.global.consentesign)
        this.isAttachEsign = true;
      }
      else {
        this.medicationForm.controls["signatureImg"].setValue(this.defaultImage)
      }
      (document.getElementById('esign') as HTMLImageElement).src = this.medicationForm.controls["signatureImg"].value;
    });
  }
  openDialog2() {
    this.global.isconsentesign = false;

    const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
      {
        data: { parentinfoId: this.userid, isfutureSign: false, isParentsign: true },
        autoFocus: false
      });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign) {
        this.medicationForm.controls["signatureImg2"].setValue(this.updatedsignatureImg)
        this.isAttachEsign = true;
      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.medicationForm.controls["signatureImg2"].setValue(this.global.consentesign)
        this.isAttachEsign = true;
      }
      else {
        this.medicationForm.controls["signatureImg2"].setValue(this.defaultImage)
      }
      (document.getElementById('esign2') as HTMLImageElement).src = this.medicationForm.controls["signatureImg2"].value;
    });
  }
  openDialog3() {
    this.global.isconsentesign = false;

    const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
      {
        data: { parentinfoId: this.userid, isfutureSign: false, isParentsign: true },
        autoFocus: false
      });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign) {
        this.medicationForm.controls["signatureImg3"].setValue(this.updatedsignatureImg)
        this.isAttachEsign = true;
      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.medicationForm.controls["signatureImg3"].setValue(this.global.consentesign)
        this.isAttachEsign = true;
      }
      else {
        this.medicationForm.controls["signatureImg3"].setValue(this.defaultImage)
      }
      (document.getElementById('esign3') as HTMLImageElement).src = this.medicationForm.controls["signatureImg3"].value;
    });
  }
  getReviewPatientDob(Meetingscheduleid) {
    this.clinicianservice.getReviewPatientDob(Meetingscheduleid).subscribe(
      (res: any) => {
        if (res.objInputString != null) {
          var fres = this.commonService.decrypdata(res.objInputString);
          var reviewDto = fres[0];
          this.StartDate = reviewDto.StartDate;
          this.StartTime = reviewDto.StartTime;
          var dob = this.datepipe.transform(reviewDto.Dob, 'MM/dd/yyyy');
          this.medicationForm.controls["dob"].setValue(dob);
        }
      }
    )
  }

}

import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/common/common.service';
@Injectable({
  providedIn: 'root'
})
export class UserserviceService {

  constructor(private http: HttpClient, public commonService: CommonService) { }
  login(formData) {

    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'Login', formData, { headers })
      .pipe(map(res => {
        return res;
      }));

  }
  ActivationLinkValidation(data) {

    
      return this.http.post(environment.BaseURI + 'ActivateLinkValidation',
      data, { responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  
  }
  ForgetLinkValidation(data) {
    return this.http.post(environment.BaseURI + 'ForgetPasswordLinkValidation',
    data, { responseType: 'text' })
    .pipe(map(function (response) { return response; }));
  }
  // getRoles() {
  //   return this.http.get(environment.BaseURI + 'gateway/User/GetRoles');
  // }

  getAllUsers(): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/User/GetUsers');
  }
  getUsersByAuthId(AuthId: string): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/User/GetUser?id=' + AuthId);
  }
  register(data) {
    return this.http.post(environment.BaseURI + 'gateway/User/CreateUser',
      data, { responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  Changepassword(_data) {
    
    return this.http.post(environment.BaseURI + 'ChangePassword',
      _data, { responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  updatePassword(_data,pageId) {
    
    var pathURL=pageId == 3 ? 'Gateway/LeadContact/UpdatePassword' : 'UpdatePassword';
    return this.http.post(environment.BaseURI + 'UpdatePassword',
      _data, { responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getUserEmail(id) {
    return this.http.get<any>('https://auth.sfccllc.com/api/Authenticate/GetUserEmail?id=' + this.commonService.DataToEncrypt(id, true));
  }
  resetPassword(_data) {
    
    return this.http.post(environment.BaseURI + 'ResetPassword',
      _data, { responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  updateUsers(data: any) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/User/UpdateUser',
      data, { responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  deleteUser(data) {
    return this.http.put<any>(environment.BaseURI + 'gateway/User/DeleteUser', data);
  }
  getLocation(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/User/GetLocation?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getRoles() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Role/GetRoles');
  }
  getRoleTypes() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Role/GetRoleTypes');
  }
  
  getRolesById() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Role/GetRolesId');
  }
  getStaffList(): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/User/GetStaffList');
  }
  saveRole(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Role/SaveRole',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }

  deleteRole(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Role/DeleteRole',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  activeRole(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Role/ActiveRole',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  postStaffRegister(data,isEdit) {
    var registerurl;
    if(isEdit){
      registerurl='gateway/User/Updateuser'
    }else{
      registerurl='gateway/User/CreateUser'
    }
    return this.http.post(environment.BaseURI + registerurl,
      data, { responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  deleteStaffUser(AuthId:string) {
    return this.http.get<any>(environment.BaseURI + 'Gateway/User/DeleteUser?id=' + AuthId);
  }
  getReportUsers(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/User/GetReportUsers',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  getProfileInfoById(userId)
  {
    return this.http.get<any>(environment.BaseURI + 'gateway/User/GetProfileInfoById?id=' + this.commonService.DataToEncrypt(userId, true));
  }
  // getProfileInfoByIdentity(userId)
  // {
  //   return this.http.get<any>(environment.BaseURI + 'gateway/Authenticate/GetProfileDetails?id=' + this.commonService.DataToEncrypt(userId, true));
  // }
  postProfileImage(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/User/SaveProfileImage',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  ChangepasswordProfile(_data) {
    
    return this.http.post(environment.BaseURI + 'gateway/Authenticate/ChangePasswordProfile',
      _data, { responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  UpdateEmail(_data) {
    
    return this.http.post(environment.BaseURI + 'gateway/Authenticate/UpdateEmail',
      _data, { responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  PostProfileInfoDetails(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/User/PostProfileInfoDetails',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
}

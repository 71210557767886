import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { IncReviewInfo } from 'src/app/_models/_Treatment/IncidentReview';
import { Global } from 'src/app/global/global.model';
import { MatDialog } from '@angular/material/dialog';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { TreatmentService } from '../../treatment/treatment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';

@Component({
  selector: 'app-incident-review',
  templateUrl: './incident-review.component.html',
  styleUrls: ['./incident-review.component.scss'],
})
export class IncidentReviewComponent implements OnInit {
  @ViewChild('IncReviewfrm') IncReviewfrm: NgForm;
  IncidentReportId: number;
  IncReviewInfo: IncReviewInfo;
  isESignAttached: boolean;
  signatureImg: any;
  updatedsignatureImg: any;
  isformtype: string;
  defaultImage: any;
  status: any;
  currentDay: any;
  MarkAsClosed: boolean = false;
  submitted: boolean = false;
  IncidentType: number;
  roleAccess: any;
  secondaryId : any;
  AutoFillSS:boolean=false;
  SSignature:any;
  GetClinicianSign: any[];  
  userId: any;
  constructor(
    private route: ActivatedRoute,
    public commonService: CommonService,
    public router: Router,
    public global: Global,
    private dialog: MatDialog,
    public treatmentservice: TreatmentService,
    public spinnerservice: NgxSpinnerService,
    private snackbar: SnackBarService
    ,public roleaccessservice:PageAccessService
  ) {}

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.IncidentReporting;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.currentDay = new Date();
    this.IncReviewInfo = new IncReviewInfo();
    // this.IncReviewInfo.FollowUpDate=this.currentDay;
    // this.IncReviewInfo.CompletedDate = this.currentDay
    this.userId = Number(localStorage.getItem('id'));
    this.getAutoFillSignature();
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length != 0) {
        this.IncidentReportId = Number(
          this.commonService.FrontEndDecryption(params.id).replace(/"/g, '')
        );
        this.status = Number(
          this.commonService.FrontEndDecryption(params.status).replace(/"/g, '')
        );
        this.IncidentType = Number(
          this.commonService.FrontEndDecryption(params.type).replace(/"/g, '')
        );
        this.getIncidentReview();
        console.log(this.IncidentType)
      }
    });
  }
  Oncancel() {
    this.router.navigate(['/incident-report'], {
      queryParams: {
        id: this.commonService.FrontEndEncryption(this.IncidentReportId),
        isedit: this.commonService.FrontEndEncryption(1),
      },
    });
  }
  PostIncReviewfrm() {
  if ( this.IncReviewfrm.invalid || (this.IncReviewInfo.StaffInitial ==undefined && this.roleAccess.isSign))
  {
    this.MarkAsClosed = false;
    this.submitted = false;
    return;
  }   
    else {

      this.spinnerservice.show();
      this.submitted = true;
      this.IncReviewInfo.IncidentReportId = this.IncidentReportId;
      this.IncReviewInfo.IncidentReportTypeId = this.IncidentType;
      if (this.MarkAsClosed == true) {
        this.IncReviewInfo.ReportStatus = 3;
      } else if(this.IncReviewInfo.IncidentReportTypeId==2 && !this.MarkAsClosed) {
        if (!this.IncReviewInfo.IsReportCompleted) {
          this.IncReviewInfo.ReportStatus = 2;
        } else {
          this.IncReviewInfo.ReportStatus = 3;
        }
      }
      else if(this.IncReviewInfo.IncidentReportTypeId==1 && !this.MarkAsClosed){
        this.IncReviewInfo.ReportStatus = 2;
      }
      // console.log(this.IncReviewInfo)
      this.treatmentservice.PostIncidentReview(this.IncReviewInfo).subscribe(
        (res) => {
          this.spinnerservice.hide();
          if (this.MarkAsClosed == true) {
            this.snackbar.success(
              'Review is saved and Incident is closed successfully'
            );
          } else {
            this.snackbar.success(
              'Incident Review has been submitted successfully'
            );
          }
          this.Oncancel();
        },
        (err) => {
          this.spinnerservice.hide();
          this.snackbar.error('Not Implemented');
        }
      );
    }
  }

  OnresultCompleted(item) {
    if (item == 1) {
      this.IncReviewInfo.IsReportCompleted = true;
      this.IncReviewInfo.IsFollowUp = false;
    } else {
      this.IncReviewInfo.IsReportCompleted = false;
      this.IncReviewInfo.IsFollowUp = true;
    }
  }

  openDialog() {
    // this.global.isconsentesign = false;
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
    //   data: { isfutureSign: false, isParentsign: true },
    //   autoFocus: false 
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   this.isESignAttached = this.global.isconsentesign;
    //   if (
    //     this.global.isconsentesign != undefined &&
    //     this.global.isconsentesign
    //   ) {
      this.global.isconsentesign = false;
      let isParentsign =false;
      if(this.AutoFillSS==true)
       {
         isParentsign=true;
    
       }
       const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
         {
        data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
         autoFocus: false 
    
       });
      dialogRef.afterClosed().subscribe((result) => {
        this.isESignAttached = this.global.isconsentesign;
        if (
          this.global.isconsentesign != undefined &&
          this.global.isconsentesign
        ) {
          if(this.SSignature!='')
            {
             this.updatedsignatureImg=this.SSignature;
            }
        this.signatureImg = this.updatedsignatureImg;
        this.IncReviewInfo.StaffInitial = this.updatedsignatureImg;
        this.IncReviewInfo.IsAttachEsign = true;
        (document.getElementById('esign_2') as HTMLImageElement).src =
          this.signatureImg;
      } else if (
        !this.global.isconsentesign &&
        this.global.formtype != null &&
        this.global.formtype != undefined
      ) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.IncReviewInfo.StaffInitial = this.global.consentesign;
        this.IncReviewInfo.IsAttachEsign = true;
        (document.getElementById('esign_2') as HTMLImageElement).src =
          this.signatureImg;
      } else {
        this.signatureImg = this.defaultImage;
        this.IncReviewInfo.IsAttachEsign = true;
        this.IncReviewInfo.StaffInitial = this.defaultImage;
      }
    });
  }

  getIncidentReview() {
    this.spinnerservice.show();
    this.secondaryId = localStorage.getItem('id');
    this.treatmentservice.getIncidentReviewById(this.IncidentReportId,this.secondaryId)
      .subscribe(
        (res) => {
          this.spinnerservice.hide();
          if (res ) {
            this.IncReviewInfo = this.commonService.decrypdata(
              res.objInputString
            );
          if(this.IncReviewInfo.IncidentReportTypeId==2){
            if (this.IncReviewInfo.IsReportCompleted == true) {
              this.IncReviewfrm.controls['IsReportCompletedradio'].setValue(
                '1'
              );
            } else {
              this.IncReviewfrm.controls['IsReportCompletedradio'].setValue(
                '2'
              );
              // this.IsReportCompletedradio = '2';
            }
            if (this.IncReviewInfo.ReportStatus == 3) {
              this.status = this.IncReviewInfo.ReportStatus;
            }
          }
          if(this.IncReviewInfo.StaffInitial){
            (document.getElementById('esign_2') as HTMLImageElement).src =
            this.IncReviewInfo.StaffInitial;
          }
          if(this.IncReviewInfo.StaffInitial1){
            (document.getElementById('esign_3') as HTMLImageElement).src =
            this.IncReviewInfo.StaffInitial1;
          }
            
            // console.log(this.IsReportCompletedradio)

          }
        },
        (err) => {
          this.spinnerservice.hide();
        }
      );
  }

  OnCloseIncident() {
    this.spinnerservice.show();
    this.MarkAsClosed = true;
    this.PostIncReviewfrm();
    this.spinnerservice.hide();
  }

  openDialog1() {
    // this.global.isconsentesign = false;
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
    //   data: { isfutureSign: false, isParentsign: true },
    //   autoFocus: false 
    // });
    this.global.isconsentesign = false;
    let isParentsign =false;
    if(this.AutoFillSS==true)
     {
       isParentsign=true;
  
     }
     const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
       {
      data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
       autoFocus: false 
  
     });
    dialogRef.afterClosed().subscribe((result) => {
      this.isESignAttached = this.global.isconsentesign;
      if (
        this.global.isconsentesign != undefined &&
        this.global.isconsentesign
      ) {
        if(this.SSignature!='')
          {
           this.updatedsignatureImg=this.SSignature;
          }
        this.signatureImg = this.updatedsignatureImg;
        this.IncReviewInfo.StaffInitial1 = this.updatedsignatureImg;
        this.IncReviewInfo.IsAttachEsign = true;
        (document.getElementById('esign_3') as HTMLImageElement).src =
          this.signatureImg;
      } else if (
        !this.global.isconsentesign &&
        this.global.formtype != null &&
        this.global.formtype != undefined
      ) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.IncReviewInfo.StaffInitial1 = this.global.consentesign;
        this.IncReviewInfo.IsAttachEsign = true;
        (document.getElementById('esign_3') as HTMLImageElement).src =
          this.signatureImg;
      } else {
        this.signatureImg = this.defaultImage;
        this.IncReviewInfo.IsAttachEsign = true;
        this.IncReviewInfo.StaffInitial1 = this.defaultImage;
      }
    });
  }
  secondarySign():any{
    if((this.IncReviewInfo.licenseNumber != '' && this.IncReviewInfo.licenseNumber != null) ||  (this.IncReviewInfo.LicenseNumber != '' && this.IncReviewInfo.LicenseNumber != null) ){
      return false;
    }
    else if(this.IncReviewInfo.sSignature || this.IncReviewInfo.SSignature ){
     return false;
    }
    else{
      return true;
    }
  }
  getAutoFillSignature() {
    this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
        this.GetClinicianSign = res;
  
        if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
          this.AutoFillSS=false;
          this.SSignature=this.GetClinicianSign[0].clinicianSign;
        }
        else
        {
          this.AutoFillSS=true;
          this.SSignature='';
        }
    });
  }

}

<div class="landing-page">
    <div class="intake-welcome-screen">
        <div class="landing-page">
          <div  class="col-md-12">
            <div  class="row">
              <div  class="lead-box pl-5 pr-5 pt-4 pb-4 w-100 land-all">
                <div  class="col-md-12 text-center welcome-txt">
                  <h2 >Welcome to Stepping Forward Counseling Center</h2>
                  <div  class="w-100 welcome-img">
                    <img  src="../../../assets/images/WelcomepageImage.png" class="img-fluid">
                  </div>
                </div>
                <div  class="col-md-12 text-center welcome-txt">
                  <h2 >Thank you for joining us</h2>
                </div>
                <!-- <div  class="sub-txt-board mt-3 mb-3">The front desk will provide you Intake appointment options shortly.</div> -->
                <div  class="w-100 text-center">
                  <!-- <button  class="btn start-btn">Continue</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 

</div>

<div class="row ">
<form (ngSubmit)="notedialogfrm.form.valid  && saveCommentInfo(notedialogfrm)" id="notedialogfrm" autocomplete="off" #notedialogfrm="ngForm"
    novalidate>
<div class="mt--14 task-comment-container">
    <div  class="modal-header p-2">
        <h5  class="modal-title"><i  class="far fa-comments mr-2"></i>Note Comments</h5>
        <button  type="button" mat-dialog-close data-dismiss="modal" aria-label="Close" class="close ">
            <span  aria-hidden="true">×</span></button>
        </div>
    <div class="col-md-12 ">
        <div class="col-md-12 p-0">
            <div class="top-notes d-flex w-100 position-relative mt-3">
                <!-- <mat-dialog-actions class="btn-closed">
                    <button mat-button mat-dialog-close><i class="fas fa-times"></i></button>
                  </mat-dialog-actions>
                <div class="box-lead-head">Notes Comment</div> -->
                <div class="ml-auto ">
                   
                    <button mat-raised-button class="primary-btn-filled-small cursor-pointer mr-2"
                        type="button" (click)="onCreateTask()">Add Comment</button>
                        <!-- <button *ngIf="lstNoteComment?.length > 0" type="button" [disabled]="taskids?.length == 0" title="Delete"
                                (click)="onReadmultipleComment()" mat-raised-button
                                class="primary-btn-filled-small ">Read All</button> -->
                                <!-- <button *ngIf="lstComment?.length > 0" type="button" [disabled]="taskids?.length == 0" title="Delete"
                                    (click)="ondeletemultipleComment()" mat-raised-button
                                    class="primary-btn-filled-small mr-2">Delete All</button> -->
                    </div>
            </div>
            <div class="empty-data" *ngIf="lstNoteComment?.length ==0" >
                <!-- <img  class="img-fluid" src="../../../../../assets/images/no_result.png" width="50%"> -->
                <div *ngIf="!isCreateTask" class="task-empty"> <p>No Comments Available</p><i  class="fas fa-tasks mr-2"></i></div>
            </div>
            <div class="task-create-section" *ngIf="isCreateTask">
            <div class="col-md-12">
                <div class="row">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Add Comment"
                      [(ngModel)]="noteCommentInfo.noteContent" #noteContent="ngModel" name="noteContent"
                      autocomplete="new-password" [ngClass]="{ 'is-invalid': notedialogfrm.submitted && noteContent.invalid }"
                      required>
                    <mat-error *ngIf="notedialogfrm.submitted && noteContent.invalid">
                      <mat-error *ngIf="noteContent.errors.required">Comment is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12 text-right mt-2">    
                        <button type="submit" title="Add Comment" form="notedialogfrm" mat-raised-button
                                class="primary-btn-outlined-small mr-2">Save Comment</button>
                        <button mat-raised-button class="primary-btn-outlined-small mr-2" (click)="resetnotesform()">Cancel
                            </button>                            
                    </div>
                </div>
            </div>
        </div>
        </div>
        
        <div class="col-md-12 ">
            <div class="row">
                <div class="col-md-12 p-0 mt-2 view-note d-block">
                    <div class="task-comment">
                    <div class="row">
                        <div class="col-md-12 mb-1" *ngFor="let item of lstNoteComment;let i = index">
                            <div class="list-note">
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="float-left w-100  position-relative">
                                            <!-- <span class="check-custom invi-check2 align-middle">
                                                <mat-checkbox class="example-margin"
                                                    [disabled]="item.createdBy == UserId"
                                                    (change)="cbklistcomment($event,item, i)" name="cbktask{{i}}">
                                                </mat-checkbox>
                                            </span> -->
                                            <span class="note-content  mt-1">
                                                {{item.noteContent}}
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-4 text-right pl-0">
                                        <div *ngIf="item.createdBy == UserId">
                                            <a [title]="item.isRead == true ? 'Comment is readed' : 'Comment is unreaded'" class="icon-note"><span
                                                class="mr-2"><i [class]="item.isRead == true ? 'fas fa-check-circle text-success' : 'far fa-check-circle'"></i></span></a>
                                        </div>
                                        <div *ngIf="item.createdBy !== UserId">
                                            <a [title]="item.isRead == true ? 'Comment is readed' : 'Comment is unreaded'" (click)="onReadComment(item)" class="icon-note"><span
                                                class="mr-2"><i [class]="item.isRead == true ? 'fas fa-check-circle text-success' : 'far fa-check-circle'"></i></span></a>
                                        </div>
                                                
                                    </div> -->
                                    <div class="col-md-12 text-right">
                                        <div class="">                                         
                                            <div class="col-md-12 asigned font-15 float-right">
                                                <div><span class="time-label">Last
                                                        updated : </span><span>{{global.globalUtctimeformat(item.createDate)}}</span>
                                                </div>
                                                <div><span class="time-label">Written by :
                                                    </span><span>{{item.username}}</span>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                    </div>
                </div>
                <div class="col-md-12">
                    <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)"  #commentpaginator
                    [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
                <!-- <mat-paginator #commentpaginator [length]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
</form>
</div>
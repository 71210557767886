
<div class="row w-100">
  <div class="col-md-12">

  <button
  type="submit"
  class="btn primary-btn-outlined-small ng-star-inserted float-right"
  (click)="generatePdf()"
>
  Generate PDF
</button>
  </div>
</div>

<h2 class="prsenting-tab">Modality</h2>
<form (ngSubmit)=" postModality(frmModality)" id="frmModality" autocomplete="off"
  #frmModality="ngForm" novalidate [ngClass]="{'disabled': isModalityFormDisabled}"  >
  <div class="col-md-12 set6-temp mt-2 mb-3 p-0 ">
    <div class="row">
      <div class="medication col-md-12 inputWrapper p-0">
        <div class=" col-md-12">
          <div class="row inputElement">
          <div class="col-md-3"><label>Therapy</label></div>
          <div class="col-md-3"><label>Frequency</label></div>
        </div>
        </div>
        <div class="loop-section position-relative mb-2 col-md-12"
          *ngFor="let data of _dtoModality;let i= index;let last=last">
          <div class="row  inputElement">
            <div class="col-md-3">

              <!-- With DropDown -->

              <!-- <select class="form-control" [(ngModel)]="data.therapyId" #therapyId="ngModel" name="therapyId{{i}}"
                required (change)="validateDdlTheraphy($event.target.value)">
                <option value="null">--Select Therapy--</option>
                <option *ngFor="let a of ddlTherapy" value="{{a.TherapyId}}">
                  {{a.TherapyName}}
                </option>
              </select>
              <mat-error *ngIf="frmModality.submitted && therapyId.invalid">
                <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="therapyId.errors.required">Therapy
                  is
                  required</mat-error>
              </mat-error> -->

              <!-- Without DropDown -->
              <div class="row">
                <div class="col-md-1 mt-2">
              <div class="check-custom" ><mat-checkbox class="example-margin"  (change)="OnCheckboxclick(i,$event)" [checked]="SelectArray[i]"></mat-checkbox></div>
            </div>
            <div class="col" >
              <span class="form-control"  >{{data.theraphyName}}</span>
            </div>
              
            </div>


            </div>

            <!-- <div class="col-md-7">
              <select class="form-control" [(ngModel)]="data.frequencyId" #frequencyId="ngModel" name="frequencyId{{i}}"
                required>
                <option value="null">--Select Frequency--</option>
                <option *ngFor="let a of ddlFrequency" value="{{a.FrequencyId}}">
                  {{a.FrequencyName}}
                </option>
              </select>
              <mat-error *ngIf="frmModality.submitted && frequencyId.invalid">
                <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="frequencyId.errors.required">
                  Frequency is
                  required</mat-error>
              </mat-error>
            </div> -->
            <div class="col-md-2" *ngIf="!(data.therapyId==4 ||data.therapyId==7 || data.therapyId==8 )">
              <select class="form-control w-100 d-inline-block mr-2" [(ngModel)]="data.theraphyWeek" #theraphyWeek="ngModel" name="theraphyWeek{{i}}" (change)="OnTherapyWeekChange(i,$event.target.value)"
                required  [disabled]="!SelectArray[i]" >
                <option value="null">--Select Range--</option> 
                <option value=1>1-2</option>
                <option value=2>2-3</option>
                <option value=3>3-4</option>
                <option value=4>4-5</option>
              </select>
             
              
               <div *ngIf="frmModality.submitted && theraphyWeek.invalid  ">
                  <div class="mt-1 mb-1  text-xs  error" *ngIf="theraphyWeek.errors.required ">
                  Range is
                  required</div>
                  </div>
           
            </div>
            <div class="col-md-2" *ngIf="!(data.therapyId==4 ||data.therapyId==7 || data.therapyId==8 )">
              <select class="form-control w-100 d-inline-block mr-2" [(ngModel)]="data.theraphyWeekShot" #theraphyWeekShot="ngModel" name="theraphyWeekShot{{i}}" (change)="OnTherapyWeekShotChange(i,$event.target.value)"
                required  [disabled]="!SelectArray[i]" >
                <option value="null">--Select Week--</option> 
                <option value=1>Weekly</option>
                <option value=2>Bi-Weekly</option>
                <option value=3>Monthly</option>
              </select>
             
              
               <div *ngIf="frmModality.submitted && theraphyWeekShot.invalid  ">
                  <div class="mt-1 mb-1  text-xs  error" *ngIf="theraphyWeekShot.errors.required ">
                  Week is
                  required</div>
                  </div>
           
            </div>
            <!-- <div class="col-md-4">
              <select class="form-control" [(ngModel)]="data.theraphyWeekShot" #theraphyWeekShot="ngModel" name="theraphyWeekShot{{i}}"
                required>
                <option value="null">--Select Every--</option>
                <option value=1>Day</option>
                <option value=2>Month</option>
                <option value=3>Week</option>
              </select>
              <mat-error *ngIf="frmModality.submitted && theraphyWeekShot.invalid">
                <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="theraphyWeekShot.errors.required">
                  Frequency is
                  required</mat-error>
              </mat-error>
            </div> -->
          </div>

          <!-- removed add and erase button for Therapy and Frequency -->

          <!-- <a class="add" style="cursor: pointer;" *ngIf="last" (click)="appendModality(1)"><i
              class="fa fa-lg fa-plus-circle"></i><span class="sr-only">Add</span></a>
          <a class="remove" style="cursor: pointer;" *ngIf="i !=0" (click)="removemodality(data,i)"><i
              class="fa fa-lg fa-minus-circle"></i><span class="sr-only">Remove</span></a> -->
        </div>

      </div>
    </div>
  </div>
  <div class="col-md-12" *ngIf="roleAccess.isCreate||roleAccess.isEdit">
    <div class="text-right">

      <button type="submit" form="frmModality" mat-stroked-button
        class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>
      <button type="button"  mat-stroked-button [routerLink]="['/clinician']"
        class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2">Cancel</button>
    </div>
  </div>
</form>

<a mimetype="application/octet-stream" #downloadLink></a>
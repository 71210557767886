import { Component, IterableDiffers, OnInit } from '@angular/core';
import { FinancialService } from 'src/app/patient-lead/financial-proposal/financial.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { SpinnerService } from 'src/app/services/spinnerservice.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { financial } from 'src/app/_models/_Lead/financial';
import { financialreview } from 'src/app/_models/_Lead/financial';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import { cloneDeep } from "lodash";
import { constantVariables } from 'src/app/constant/constant';
import { CatalogueService } from 'src/app/common/pages/price-catalogue/catalogue.service';
@Component({
  selector: 'app-financial-proposal',
  templateUrl: './financial-proposal.component.html',
  styleUrls: ['./financial-proposal.component.scss']
})
export class FinancialProposalComponent implements OnInit {
  ddlCatalouge: any;
  dtoFinancial: financial;

  priceCatalogue: any;
  componentdetails: any;
  rbnPriceComponent: any;
  priceCatalogueId: any;
  objIntakeFee: any = 0;
  objSessionFee: any = 0;
  objReview: financialreview;
  isFinancialReview: boolean = false;
  patientInfoId: any;
  patientName: any;
  parentName: any;
  currentdate: any;
  deductibleVal: any;
  ddlAnthem: any;
  objlocation: any;
  ddlOthers: any;
  ddlSca: any;
  isEditFinancial: boolean = false;
  isIntakeFeeRequired: boolean = false;
  isSessionFeeRequired: boolean = false;
  pricingdetails: any = [];
  intake: any = [];
  ddlType: any;
  pageFrom:number;
  constructor(public financialService: FinancialService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: SpinnerService,public CatlogueService: CatalogueService) { }

  ngOnInit(): void {
    this.currentdate = moment(new Date()).format("MM/DD/YYYY");
    this.dtoFinancial = new financial();
    this.objReview = new financialreview();
    this.dtoFinancial.programType = 2;

    this.getComponentMaster();
    //this.initpricing();
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        this.patientInfoId = this.commonService.FrontEndDecryption(params.id);
        if(params.page != undefined)
        {
          this.pageFrom = JSON.parse(this.commonService.FrontEndDecryption(params.page));
        }
        this.patientName = this.commonService.FrontEndDecryption(params.name).replace(/"/g, "");
        this.parentName = this.commonService.FrontEndDecryption(params.parentname).replace(/"/g, "");

        this.getPriceCatalogueByProgram(this.dtoFinancial.programType);
        this.getFinancialProposalDetails();
        this.GetDeductiblemaster();
      }
    });
  }
  //initpricing() {
    // this.pricingdetails =
    //   [
    //     {
    //       "componentName": "InTake Fee",
    //       "pricing": []
    //     },
    //     {
    //       "componentName": "Session Fee",
    //       "pricing": []
    //     }
    //   ]
  //}
  getPriceCatalogueByProgram(id) {
    this.spinnerservice.show();
    this.financialService.getPriceCatalogueByProgram(id,this.patientInfoId).subscribe(
      res => {
        if (res != null) {
          this.ddlCatalouge = [];
          this.ddlCatalouge = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getFinancialProposalDetails() {

    this.financialService.getFinancialProposalDetails(this.patientInfoId).subscribe(
      res => {
        if (res != null) {
          //this.financialProposal = res;
          if (res.financialProposalId != 0) {
            this.isEditFinancial = true;
            this.openFinancialreview();
            this.objlocation = res.location;
            this.objReview = {
              intakeFee: res.inTakeFee,
              sessionFee: res.sessionFee,
              anthemVal: res.anthemVal,
              deductibleVal: res.deductibleType,
              priceCatalogueId: res.priceCatalogueId,
              priceComponentId: res.priceComponentId,
              patientInfoId: this.patientInfoId,
              deductible: res.deductible,
              deductibleTypeId: res.deductibleTypeId,
              componentdetails: res.componentDetails,
              deductibleActVal:'',
              isInsuranceCoverage : res.isInsuranceCoverage,
              deductibleComments : res.deductibleComments
            }
          }
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onChangeProgramType(type) {
    this.getPriceCatalogueByProgram(type);

  }
  onCatalougeChange(event) {
    this.rbnPriceComponent = undefined;
    this.spinnerservice.show();
    this.financialService.getPriceCatalogueDetails(event.value,this.patientInfoId).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          if (res.priceCatalogueId != 0) {
            this.priceCatalogueId = res.priceCatalogueId;
            this.priceCatalogue = cloneDeep(res);
            this.objlocation = res.location;
            this.componentdetails = res.components;
            if(this.componentdetails.length > 0)
            {
              this.cbkrbnPriceComponent(this.componentdetails[0]);
            }

          }

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }
  onPriceValueChage(item, data, event) {
    var obj = {
        componentName: data.componentName,
        pricing: [],
        deductibleTypeId :data.deductibleType,
        deductibleComments : data.deductibleComments
    }
      obj?.pricing.push(event.value);
    
    this.pricingdetails.push(obj);
   
    // if (data.componentName == 'InTake Fee') {
    //   this.pricingdetails[0].pricing = [];
    //   this.pricingdetails[0].pricing.push(event.value);
    //   // for (let i = 0; i < event.value.length; i++) {
    //   //   this.pricingdetails[0].pricing.push(event.value[i]);
    //   // }
    // } else if (data.componentName == 'Session Fee') {
    //   this.pricingdetails[1].pricing = [];
    //   this.pricingdetails[1].pricing.push(event.value);
    //   // for (let i = 0; i < event.value.length; i++) {
    //   //   this.pricingdetails[1].pricing.push(event.value[i]);
    //   // }
    // }
    this.objReview = {
      intakeFee: this.objIntakeFee,
      sessionFee: this.objSessionFee,
      anthemVal: item.anthemVal,
      deductibleVal: this.deductibleVal,
      priceCatalogueId: this.priceCatalogueId,
      priceComponentId: item.priceComponentId,
      patientInfoId: this.patientInfoId,
      deductible:  item.deductibleType,
      deductibleTypeId:item.deductibleType,
      componentdetails: this.pricingdetails,
      deductibleActVal:'',
      isInsuranceCoverage : item.isInsuranceCoverage,
      deductibleComments : item.deductibleComments
    }

  }
  onBack(){
    if(this.pageFrom == 1)
    {
      this.router.navigateByUrl('/dashboard');
    }
    else if(this.pageFrom == 2){
      this.router.navigate(['/patientslists'])
    }
    else{
      this.router.navigateByUrl('/leadlist');
    }
  }
  postFinancialProposal(financialfrm: NgForm) {

    if ((this.objReview.intakeFee === undefined)) {
      // this.snackbar.error(constantVariables.ErrorMessage.financialreview)
      // return
    } else if (((isNaN(Number(this.objReview.intakeFee))) || (this.objReview.sessionFee === undefined || isNaN(Number(this.objReview.sessionFee))))) {

    } else if (this.objReview.deductibleTypeId == 0) {
      this.snackbar.error(constantVariables.ErrorMessage.financialdeductible)
      return
    } else {
      this.isFinancialReview = true;
    }
  }
  closeFinReview() {
    this.isFinancialReview = false;
  }
  openFinancialreview() {
    this.isFinancialReview = true;
  }
  cbkrbnPriceComponent(item) {
    this.rbnPriceComponent = item.priceComponentId;
    // this.objReview = new financialreview();
    this.objReview.deductible =  item.anthem;
    this.objReview.deductibleTypeId =item.deductibleType;
    this.objReview.deductibleComments = item.deductibleComments;
    this.objReview.deductibleVal = item.deductibleVal;
    this.deductibleVal = item.deductibleVal;
    //this.initpricing();
    this.pricingdetails = [];

  }
  onrbnDeductible(event) {
    this.objReview.deductibleTypeId = event;
    if (event == 1) {
      this.deductibleVal = 'Anthem';

    }
    else if (event == 2) {
      this.deductibleVal = 'SCA';
    }
    else if (event == 3) {
      this.deductibleVal = 'Others';
    }
    this.objReview.deductibleVal = this.deductibleVal;
  }

  onChangedeductible(event,index) {
    var anthem= this.ddlAnthem.filter(o1 => o1.deductibleId == event.value);
    var sca = this.ddlSca.filter(o1 => o1.deductibleId == event.value);
    var others = this.ddlOthers.filter(o1 => o1.deductibleId == event.value);
    if(anthem.length != 0)
    {
      this.objReview.deductibleActVal=anthem[0].deductible1;
    }
    if(sca.length != 0)
    {
      this.objReview.deductibleActVal=sca[0].deductible1;
    }
    if(others.length != 0)
    {
      this.objReview.deductibleActVal=others[0].deductible1;
    }

    this.objReview.deductibleTypeId = event.value;
    this.objReview.componentdetails[index].deductibleTypeId = event.value;
  }
  onInputChange(inputValue,index) {
    this.objReview.componentdetails[index].deductibleComments = inputValue;
  }
  postFinancialReview(financialReviewfrm: NgForm) {
    if (!financialReviewfrm.valid) {
      return
    }
    debugger
    this.objReview.patientInfoId = +this.objReview.patientInfoId;
    this.objReview.componentdetails.map(p=>{
      p.pricing[0].priceValue = +p.pricing[0].priceValue
      if(p.componentName != 'Session Fee')
      {
        p.deductibleTypeId = 0;
      }
    })
    //const dataToEncrypt = this.commonService.DataToEncrypt(this.objReview, false);
    this.spinnerservice.show();
    this.financialService.postFinancialProposal(this.objReview).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.closeFinReview();
        if(this.pageFrom == 2)
        {
          this.router.navigate(['/patientslists']);
        }
        else
        {
          this.router.navigateByUrl('/leadlist');
        }
       // this.router.navigate(['/leadlist']);
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  // onChangeDeductible(type) {
  //   if (type == 1) {
  //     this.deductibleVal = 'Anthem';

  //   }
  //   else if (type == 2) {
  //     this.deductibleVal = 'SCA';
  //   }
  //   else if (type == 3) {
  //     this.deductibleVal = 'Others';
  //   }
  //   this.objReview.deductible = 0;
  //   this.objReview.deductibleVal = this.deductibleVal;
  //   this.objReview.deductibleTypeId = type;
  // }
  getComponentMaster() {
    this.financialService.getComponentMaster().subscribe(
      res => {
        if (res) {
          this.ddlAnthem = res.anthem;
          this.ddlOthers = res.others;
          this.ddlSca = res.sca;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  GetDeductiblemaster() {
    this.CatlogueService.getDeductibleMaster().subscribe(
      res => {
        if (res) {
          this.ddlType = res;
       
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
}

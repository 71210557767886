import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { data } from 'browserslist';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { FormRenameDialogComponent } from 'src/app/common/form-rename-dialog/form-rename-dialog.component';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { skillTaught } from 'src/app/_models/_Common/skillTaught';
import {
  CrateSkillTaught,
  CrateSkillTaughtQuestion,
} from 'src/app/_models/_Common/template';
import { TemplateService } from '../template.service';
// import { MatTableDataSource } from '@angular/material/table/table-data-source';
// import { MatPaginator } from '@angular/material/paginator/paginator';
// import { MatSort } from '@angular/material/sort/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { constantVariables } from 'src/app/constant/constant';
@Component({
  selector: 'app-skill-taught',
  templateUrl: './skill-taught.component.html',
  styleUrls: ['./skill-taught.component.scss'],
})
export class SkillTaughtComponent implements OnInit {
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],                                       // remove formatting button


      ],

    },

    keyboard: {
      bindings: {
        shiftEnter: {
          key: 13,
          shiftKey: true,
          handler: (range, context) => {
            // Handle shift+enter
           // console.log("shift+enter")
          }
        },
        enter: {
          key: 13,
          handler: (range, context) => {
           // console.log("enter");
            return true;
          }
        }
      }
    }
  }
  locationMaster: any;
  createSkillTaughtForm: CrateSkillTaught;
  skillTaughtQuestionForm: CrateSkillTaughtQuestion;
  skillTaughtId: any = 0;
  therapyMaster: any;
  skillTaughtMasterId: number;
  actionId: any;
  isSkillTaughtExist: boolean = false;
  skillTaughtmasterDataById: skillTaught = new skillTaught();
  skillTaughtQuestionId: number = 0;
  skillTaughtQuestions:any;
  isEditEnabled:boolean=false;
  isQuestionsAvailable:boolean=false;
  isQuestionFormSubmitted:boolean=false;
  isViewMode:boolean=false;
  TherapyName : any;
  OrderBy : any;
  therapyForms : boolean;
  dataSource: any=[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['sno', 'TherapyName', 'IsActive', 'actions'];
  lstMaster: any;
  isEditMaster = false;
  editForms:boolean = false;
  therapyName : any;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private spinnerservice: NgxSpinnerService,
    private templateService: TemplateService,
    private route: ActivatedRoute,
    private snackbar: SnackBarService,
    public global: Global,
    private dialog: MatDialog
  ) {
    this.createSkillTaughtForm = new CrateSkillTaught();
    this.skillTaughtQuestionForm = new CrateSkillTaughtQuestion();
    this.getLoationMasters();
    // this.skillTaughtQuestionForm.question='• Hello';
    // this.skillTaughtQuestionForm.question="<ul><li>apples</li><li>bananas</li><li>pineapples</li></ul>"
    this.getGropuMeetingTherapyMaster();
    this.spinnerservice.hide();
    this.route.queryParams.subscribe((params) => {
      this.skillTaughtMasterId = +this.commonService.FrontEndDecryption(
        params['id']
      );
      this.actionId = +this.commonService.FrontEndDecryption(params['action']);
      if(this.actionId==2){
        this.isViewMode=true;
      }
      if (this.skillTaughtMasterId) {
        this.isSkillTaughtExist = true;
        this.getSkillTaught();
      }
    });
  }
  ngOnInit(): void {}

  oneditFormName(item){
      var dataToForm={
        skillTaught:item.skillTaught,
        skillTaughtMasterId:item.skillTaughtMasterId,
        isIop:item.isIop,
        isPcp:item.isPcp,
        location:item.locationId,
        therapy:item.therapyId,
        isGlobal:item.isGlobal
      }
      const dialogRef= this.dialog.open(FormRenameDialogComponent,
       { data: {skillTaught : dataToForm , type : 1} })
          dialogRef.afterClosed().subscribe(result => {
            this.getSkillTaught();
        });
   }

  getSkillTaught() {
    this.spinnerservice.show();
    this.templateService
      .GetSkillTaughtMasterDataById(this.skillTaughtMasterId)
      .subscribe((res) => {
        this.skillTaughtmasterDataById = res[0];
        this.GetSkillTaughtQuestionsById();
        this.spinnerservice.hide();
      });
  }
  GetSkillTaughtQuestionsById(){
    this.templateService.GetSkillTaughtQuestionsById(this.skillTaughtMasterId).subscribe((res)=>{
      this.skillTaughtQuestions=res;
      if(this.skillTaughtQuestions.length > 0){
        this.isQuestionsAvailable=true;
      }
    },(err)=>{
      this.spinnerservice.hide();
  })
  }
  getLoationMasters() {
    this.commonService.getSfccLocation().subscribe((res) => {
      this.locationMaster = res;
      this.locationMaster = this.locationMaster.filter((obj) => {
        return obj.sfccMasterId !== -1;
      });
    });
  }
  getGropuMeetingTherapyMaster() {
    this.commonService.getGroupMeetingTherapyMaster().subscribe((res: any) => {
      if (res) {
        this.therapyMaster = res;
        this.lstMaster = this.therapyMaster;
        this.dataSource = new MatTableDataSource(this.lstMaster);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      }
    });
  }
  onbacktoformlist() {
    this.router.navigate(['/consentlist'], { queryParams: { tab: 3 } });
  }
  postQuestionForm(questionForm: NgForm) {
    this.spinnerservice.show();
    this.isQuestionFormSubmitted=true;
    if (questionForm.invalid) {
      this.spinnerservice.hide();
      return;
    }
    if (questionForm.valid) {
      var dataToBackend = {
        question: questionForm.value.question,
        skillTaughtQuestionId: this.skillTaughtQuestionId,
        skillTaughtMasterId: this.skillTaughtMasterId,
      };
      var encryptedData = this.commonService.DataToEncrypt(
        dataToBackend,
        false
      );
      this.templateService.postSkillTaughtQuestion(encryptedData).subscribe(
        (res) => {
          if (res) {
            this.snackbar.success(res);
            this.spinnerservice.hide();
            questionForm.reset();
            this.isQuestionFormSubmitted=false;
            questionForm.reset();
            this.skillTaughtQuestionId=0;
            this.getSkillTaught();
          }
        },
        (err) => {
          this.spinnerservice.hide();
        }
      );
      this.spinnerservice.hide();
    }
  }

  getValue(id) {
   var values:any = this.createSkillTaughtForm.formTypeId;
   var data =  values.find(e=>e==id);
   return data?true:false;
  }

  postConfigureForm(configureForm: NgForm) {    
    if (configureForm.invalid) {
      this.spinnerservice.hide();
      return;
    }
    
    if (configureForm.valid) {
      this.spinnerservice.show();
      var dataToBackend = {
        skillTaughtMasterId: this.skillTaughtId,
        // isIop: configureForm.value.formTypeId == 1 ? true : false,
        // isPcp: configureForm.value.formTypeId == 2 ? true : false,
        isIop: this.getValue("1"),
        isPcp: this.getValue("2"),
        skillTaught: configureForm.value.formName,
        location: configureForm.value.location ? configureForm.value.location.toString() : 0,
        therapy: configureForm.value.therapy.toString(),
        isGlobal: configureForm.value.isGlobal,
      };
      var encryptedData = this.commonService.DataToEncrypt(
        dataToBackend,
        false
      );
      this.templateService.postSkillTaughtCreateForm(encryptedData).subscribe(
        (res) => {
          if (res) {
            this.snackbar.success(res);
            configureForm.reset();
            this.spinnerservice.hide();
            this.onbacktoformlist();
          }
          this.spinnerservice.hide();
        },
        (err) => {
          this.spinnerservice.hide();
        }
      );
    }
  }
  disableEdit(){
  this.isEditEnabled=false;
  this.skillTaughtQuestionForm.question='';
  this.isQuestionFormSubmitted=false;
  this.skillTaughtQuestionId=0;
  }
  onEditQuestion(item:any){
    this.isEditEnabled=true;
    this.skillTaughtQuestionForm.question=item.skillTaughtQuestion;
    this.skillTaughtQuestionId=item.skillTaughtQuestionId;
  }
  onDeleteQuestion(item:any){
    this.spinnerservice.show();
    this.templateService.DeleteSkillTaughtQuestion(this.commonService.DataToEncrypt(item,false)).subscribe((res)=>{
      this.snackbar.success(res);
      this.spinnerservice.hide();
      this.getSkillTaught();
    },
    (err)=>{
      this.spinnerservice.hide();
    })
  }
  ontherapyEdit(){
    this.therapyForms = true;
  }
  therapyFormclose(){
    this.therapyForms = false;

  }

  therapyFormName = '';
saveFormName(){
  if(this.therapyFormName == '' || this.therapyFormName == null){
    this.snackbar.error("Please add therapy name");
     return;
  }
this.spinnerservice.show();
this.TherapyName = this.therapyFormName;
    this.templateService.postSkillTaughtTherapyForm({TherapyName:this.TherapyName}).subscribe((res)=>{
      console.log(res);
      this.snackbar.success(res);   
      this.spinnerservice.hide();
      this.therapyFormName = '';
      this.therapyForms = false; 
      this.getGropuMeetingTherapyMaster();
    },
    (err)=>{
      this.spinnerservice.hide();
    })
  }
  onPageChangeMaster($event) {
    this.lstMaster = this.therapyMaster.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  oneditMasters(element) {
    this.editForms = true;
    this.therapyName = element.therapyName;
    localStorage.setItem('therapydetails', JSON.stringify(element))
    // this.onChangeSkilltaught(element); 
    }
  ondeleteMasters(element) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();

        const dataToEncrypt = this.commonService.DataToEncrypt(element, false);
        this.commonService.deleteSkilltaught(element).subscribe(
          (res: any) => {
            this.getGropuMeetingTherapyMaster();
            this.spinnerservice.hide();
            this.snackbar.success("Therapy deleted successfully");
          },
          
        );
      }
    });
    this.spinnerservice.hide();
  }
  onChangeSkilltaught() {
    var data = localStorage.getItem('therapydetails');
    var element = JSON.parse(data);
    element.therapyName = this.therapyName;
    this.spinnerservice.show();
    // element.GTTherapyIsActive = !element.GTTherapyIsActive;
    const dataToEncrypt = this.commonService.DataToEncrypt(element);
    this.commonService.ChangeSkilltaught(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.editForms = false;
        this.snackbar.success("Therapy updated successfully");
        this.getGropuMeetingTherapyMaster();
      },
     
    );
    this.spinnerservice.hide();

  }
  editFormclose(){
    this.editForms = false;
  }
  onActiveSkilltaught(element) {
    this.spinnerservice.show();
    element.gttherapyIsActive = !element.gttherapyIsActive;
    const dataToEncrypt = this.commonService.DataToEncrypt(element);
    this.commonService.onActiveSkilltaught(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.editForms = false;
        if(element.gttherapyIsActive == true){
          this.snackbar.success("Therapy activated successfully");
        }
        if(element.gttherapyIsActive == false){
          this.snackbar.success("Therapy deactivated successfully");
        }
        this.getGropuMeetingTherapyMaster();
      },
     
    );
    this.spinnerservice.hide();

  }
}

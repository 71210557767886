<div class="individual-thrp">
    <div class="col-md-12">
    
      <div class="d-flex met-info-top w-100">
        <i class="fas fa-user"></i>
        <div class="mr-4">Client Name 
          <span class="imp-info">{{_dtoPatientInfo.patientName }}

        </span></div>
        <div  class="mr-4"> Appointment Date
          <span class="imp-info">{{_dtoPatientInfo?.startDate | date:'MM/dd/YYYY'}}</span>
        </div>
        <div  class="mr-4"> Appointment Time   <span class="imp-info">{{_dtoPatientInfo?.startTime | date:'hh:mm a'}}</span></div>
      <div  class="mr-4">  <!-- Duration <span  class="imp-info"> </span>-->
        </div><div class="ml-auto">
          <button type="button" mat-stroked-button (click)="OnbackClick()"
          class="bg-white top-back  mt-1 mb-2 h-40 mr-2">Back</button>
         </div>
        </div>
      <div class="lead-box pt-2 pb-2">
        
        <div class="tab-history custom-tab-border mt-2">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item mr-1" role="presentation">
              <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#progress" type="button" role="tab" aria-controls="s1" aria-selected="true" (click)="OnTabchange(1)"  [ngClass]="{'active show': SelectedTab == 1}" >Progress Note</button>
            </li>
            <li class="nav-item mr-1" role="presentation">
              <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#goals" type="button" role="tab" aria-controls="s3" aria-selected="true" (click)="OnTabchange(3)"  [ngClass]="{'active show': SelectedTab == 3}" >Goals</button>
            </li>
            <li class="nav-item " role="presentation" *ngIf="selectedtheraphyid==1 || selectedtheraphyid==2">
              <button class="nav-link" id="medication-tab" data-bs-toggle="tab" data-bs-target="#medications" type="button" role="tab" aria-controls="s2" aria-selected="true" (click)="OnTabchange(2)"  [ngClass]="{'active show': SelectedTab == 2}" >Medication History</button>
            </li>
            
          </ul>
          
          <div class="tab-content">
            <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==1">
          <!-- <div class="col-md-12 Theraphy-preview mt-3" [ngClass]="{'disabled':progressUpg == true }"> -->
            <div class="col-md-12 Theraphy-preview mt-3" >
            <div class="row">
             </div>
           
          <div class="col-md-12 mt-3">
          <div class="row">
            <div class="d-flex w-100">
              <div class="box-lead-head mb-2 ml-3 fntsize">{{indfamTheraphyHdr}} </div>
              <div class="ml-auto ">
                <button type="submit" class="btn primary-btn-outlined-small mr-2 float-left" (click)="onIndFamEditClick('P')"><i class="far fa-eye mr-2"></i>Preview</button>
                <button type="submit" class="btn primary-btn-outlined-small mr-2 float-left" *ngIf="!IsIndividualpdf" (click)="OnDownload(0)"><i class="fas fa-file-alt mr-2"></i>Generate Pdf</button>
                <button type="submit" class="btn primary-btn-outlined-small mr-2 float-left" *ngIf="IsIndividualpdf" (click)="OnDownloadindfam(0)"><i class="fas fa-file-alt mr-2"></i>Generate Pdf</button>
                <button form="frmindfamtheraphy" type="submit" class="btn primary-btn-outlined-small float-left mr-2" *ngIf="!isEdit && (roleAccess.isCreate||roleAccess.isEdit)" ><i  class="far fa-save mr-2" ></i>Save</button>
                <button class="btn primary-btn-outlined-small float-left  mr-2" *ngIf="isEdit && (roleAccess.isCreate||roleAccess.isEdit)" (click)="onIndFamEditClick('E')"><i  class="far fa-edit mr-2"  ></i>Edit</button>
                <button  class="export-pdf-btn btn"  mat-type="button" (click)="OnDownload(1)"><i class="fa fa-print"></i></button>
             
              </div>
             </div>
          </div>
<!--Preview-->
<div class="Activity-popup" >
  <div class="modal"  tabindex="-1" role="dialog" [style.display]=" ShowPreview ? 'block' : 'none'">
     <div class="modal-dialog modal-lg" role="document" >
        <div class="modal-content">
           <div class="modal-header">
              <h6 class="modal-title">Preview</h6>
             
              <button type="button" class="close"  data-dismiss="modal"
              aria-label="Close" (click)="onClosePreview()">
              <span aria-hidden="true">&times;</span>
           </button>
           </div>
           <div class="modal-body" >
            <div class="individual-threaphy-container">
              <div  class="history-list col-md-12 p-0">
                <div class="row">
                  <div class="d-flex col-md-12">
                    <button class="ml-auto btn-print-hover mb-3 mr-2" mat-type="button" (click)="print()"><i class="fa fa-print"></i></button>
                  </div>
                  
                </div> 
                <div id="print-section">                
                  <div class="page-header fntsize" style=" text-align: center; font-size: 15px; font-weight: 500; margin-bottom: 25px; color: #173a64;">{{indfamTheraphyHdr}} </div>

                <div class="row top-details-psych">
                  <div class="col-md-3"><label>Client Name</label>
                  <p>{{_dtoPatientInfo.patientName }}</p></div>
                  <!-- <div class="col-md-3"><label>Psychiatrist Name</label>
                    <p>{{_dtoPatientInfo.clinicianName}}</p></div> -->
                    <div class="col-md-3"><label> Start Date</label>
                      <p>{{_dtoPatientInfo.startDate | date:'MM/dd/YYYY'}}</p></div>
                      <div class="col-md-3"><label> Start Time</label>
                        <p>{{_dtoPatientInfo.startTime | date:'hh:mm a'}}</p></div>                         
                 </div>
                <div class="row" >
                <div class="col-md-12 mb-2  " *ngIf="!IsFamily">
                   <label class="control-label-new font-weight-bold" >Clinical challenge(s) client presents with:</label>
               <div class="cli_value_family">
           <ng-container *ngFor="let item of _dtoPatientInfo.iftCheklist;let i=index">
          <span class="cli_value_family"  *ngIf="item.clinicalIssueGroupId == 1 && item.isChecked == true">
            <span >{{item.clinicalIssues}} <small>,</small></span>
          </span>
          
          </ng-container>
        </div> 
      </div>
      <div class="col-md-12 mb-2" *ngIf="IsFamily">
        <label class="control-label-new font-weight-bold" >Presenting Problem(s):</label>
        <div class="cli_value_family">
          <span> {{_dtoPatientInfo.iftNotes.presentingProblems}}  </span>
        </div>
        </div>
      <div  *ngIf="_dtoPatientInfo.iftCheklist[11].isChecked==true">
        <div class="col-md-12 mb-2  ">
          <label class="preview-sub" >Other Clinical challenge(s)</label>
      <div class="cli_value_family">
        <span>{{_dtoPatientInfo.iftNotes.otherClinicalChallenges}}</span>
      </div>
    </div>
  </div>
      <div class="col-md-12 mb-2" *ngIf="!IsFamily">
        <label class="control-label-new font-weight-bold" >{{ intervention}} </label>
        <div class="cli_value_family">
          <ng-container *ngFor="let item of _dtoPatientInfo.iftCheklist;let i=index">
            <span class="cli_value_family"  *ngIf="item.clinicalIssueGroupId == 2 && item.isChecked == true"> 
            <span >{{item.clinicalIssues}}<small>,</small></span> 
             </span>
            </ng-container> 
        </div>
        </div>
        <div class="col-md-12 mb-2" *ngIf="IsFamily">
          <label class="control-label-new font-weight-bold" >Interventions(s):</label>
          <div class="cli_value_family">
            <span> {{_dtoPatientInfo.iftNotes.intervention}}  </span>
          </div>
          </div>
        <div class="col-md-12 mb-2">
          <label class="control-label-new font-weight-bold" >Client's response to treatment intervention(s):</label>
          <div class="cli_value_family">
            <span> {{_dtoPatientInfo.iftNotes.clientResponse}}  </span>
          </div>
          </div>
           <div class="col-md-12 mb-2 followupmgntop" >
            <label class="control-label-new font-weight-bold" >Follow-up plan</label>
            <div class="cli_value_family">
              <span> {{_dtoPatientInfo.iftNotes.followupPlan}}  </span>
            </div>
            </div>
            <div class="col-md-12 mb-2 followupmgntop" >
              <label class="control-label-new font-weight-bold" >Severity Level:</label>
              <div class="cli_value_family">               
                <span> {{getSeverityDescription(_dtoPatientInfo.iftNotes.severity) }}  </span>
              </div>
              </div>
  
          <div  class=" col-md-12  mt-3 pt-1">
            <label  class="treat-label">Provider's Signature</label>
            
            <img  id="esign_prev" height="50px" width="300px" src="{{_dtoPatientInfo.iftNotes.staffInitial}}" /></div>
            <div  class=" col-md-12  mt-3 pt-1">
              <label  class="treat-label">Secondary Signature</label>
              
              <img  id="esign_prev" height="50px" width="300px" src="{{_dtoPatientInfo.iftNotes.staffInitial1}}" /></div>
                </div>
              </div>
                </div>
                </div>
        </div>
        </div>
     </div>
    
     </div>
  </div>
  

<!--Preview- end-->

           <form (ngSubmit)="frmindfamtheraphy.form.valid  && postindfamTheraphyOutcomeNotes(frmindfamtheraphy)" id="frmindfamtheraphy" autocomplete="off"
            #frmindfamtheraphy="ngForm" novalidate>
            <div class="row" *ngIf="isEdit==false">
            <div class="col-md-12"  *ngIf="!IsFamily">
              <label class="control-label-new font-weight-bold"   *ngIf="_dtoPatientInfo.iftCheklist[0].clinicalIssueGroupId == 1"> {{ _dtoPatientInfo.iftCheklist[0].clinicalIssueGroup}} </label> 
              <div class="form-group row check-fields-large-label"  >
                <ng-container *ngFor="let item of _dtoPatientInfo.iftCheklist;let i=index">
                <div class="col-md-3" *ngIf="item.clinicalIssueGroupId == 1">
                  <div class="check-custom">
                      <mat-checkbox class="example-margin" name="clinicalIssues{{i}}" [(ngModel)]="item.isChecked"  >{{item.clinicalIssues}}</mat-checkbox> 
                  </div>
                </div>
              </ng-container>
             </div>
            </div>
            <div class="col-md-12 " *ngIf="_dtoPatientInfo.iftCheklist[11].isChecked==true">
              <div  class="form-group">
                <textarea  rows="3" class="form-control text-xs font-italic" placeholder="If other, please enter details" maxlength="200"  [(ngModel)]="_dtoPatientInfo.iftNotes.otherClinicalChallenges" #otherClinicalChallenges="ngModel" name="otherClinicalChallenges" [ngClass]="{ 'is-invalid': frmindfamtheraphy.submitted && otherClinicalChallenges.invalid }" required ></textarea>
                <mat-error *ngIf="frmindfamtheraphy.submitted && otherClinicalChallenges.invalid">
                  <mat-error class="text-xs"  *ngIf="otherClinicalChallenges.invalid">
                    This field is required</mat-error>
              </mat-error>
              </div>
            </div>
           
            <div class="col-md-12"  *ngIf="!IsFamily">
             <label class="control-label-new font-weight-bold"  > {{ intervention}} </label>
              <div class="form-group row check-fields-large-label"  >
                <ng-container *ngFor="let item of _dtoPatientInfo.iftCheklist;let i=index">
                <div class="col-md-3" *ngIf="item.clinicalIssueGroupId == 2">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin" name="intervention{{i}}" [(ngModel)]="item.isChecked" >{{item.clinicalIssues}}</mat-checkbox>
                  </div>
                </div>
              </ng-container>
             </div>
            </div>
            <div class="col-md-12" *ngIf="IsFamily">
              <label class="control-label-new font-weight-bold">Presenting Problem(s):</label>
              <div class="form-group">
                  <textarea name="presentingProblems"  [(ngModel)]="_dtoPatientInfo.iftNotes.presentingProblems"   class="form-control" rows="3"></textarea>
                </div>
            </div>
            <div class="col-md-12" *ngIf="IsFamily">
              <label class="control-label-new font-weight-bold">Intervention(s):</label>
              <div class="form-group"> 
                <textarea name="intervention"  [(ngModel)]="_dtoPatientInfo.iftNotes.intervention"     class="form-control" rows="3"></textarea>
                </div>
            </div>

            <div class="col-md-12">
              <label class="control-label-new font-weight-bold">Client's response to treatment intervention(s):</label>
              <div class="form-group">
               <textarea name="clientResponse"  [(ngModel)]="_dtoPatientInfo.iftNotes.clientResponse"   class="form-control" rows="3"></textarea>
                </div>
            </div>
            <div class="col-md-12 followupmgntop" >
              <label class="control-label-new font-weight-bold">Follow-up plan:</label>
              <div class="form-group">
                  <textarea name="followupPlan"  [(ngModel)]="_dtoPatientInfo.iftNotes.followupPlan"   class="form-control" rows="3"></textarea>
                </div>
            </div>

            <div class="col-md-12">
              <div class="form-group clearfix m-0">
                <div class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label class="control-label-new font-weight-bold">Severity Level:</label>
                  <mat-select #severity="ngModel" name="severity" [(ngModel)]="_dtoPatientInfo.iftNotes.severity" [ngStyle]="{'color':bg}" (selectionChange)="onSelectionChanged($event.value)" placeholder="Select Severity">
                    <!-- <mat-option [value]="0"> 0 - Discharged </mat-option>
                    <mat-option [value]="1"> 1 - Mild </mat-option>
                    <mat-option [value]="2"> 2 - Medium  </mat-option>
                    <mat-option [value]="3"> 3 - Critical </mat-option> -->
                    <mat-option *ngFor="let item of ddlseverity" [ngStyle]="{'color': item.colocode}"  [value]="item.severityValue">{{item.severityLable}}</mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
<!--newly added sign-->
<div class="col-md-12">
  <div class="form-group clearfix m-0">
  <div class="float-left col-md-4 p-0 mt-3 pt-1">
    <label class="treat-label mr-2" style="padding-left :9px;">Provider's Signature </label>
    <img id='esign' height="50px" width="300px" src="{{_dtoPatientInfo.iftNotes.staffInitial}}" style="border: 1px solid grey;">
    <!-- <img id='esign' height="50px" width="300px" *ngIf = "_dtoPatientInfo?.iftNotes?.staffInitial" src="{{_dtoPatientInfo.iftNotes.staffInitial}}">
    <img id='esign' height="50px" width="300px" *ngIf ="!_dtoPatientInfo?.iftNotes?.staffInitial" src= "{{eSignWhiteSpace}}"> -->
     <!-- <mat-error  *ngIf="frmindfamtheraphy.submitted && _dtoPatientInfo.iftNotes.staffInitial == undefined && (isLicensed && RoleId==5)"> 
      -->    
     <!-- <mat-error  *ngIf="frmindfamtheraphy.submitted && _dtoPatientInfo.iftNotes.staffInitial == undefined && (isLicensed && RoleId==5)">     -->

      <!-- <mat-error  *ngIf="frmindfamtheraphy.submitted && _dtoPatientInfo.iftNotes.staffInitial == undefined && ((isLicensed && RoleId==5) || (RoleId!=5))"> -->
      <mat-error class="text-xs" *ngIf="_dtoPatientInfo.iftNotes.staffInitial == undefined">
      Staff signtaure is required</mat-error>
  <!-- </mat-error> -->
  </div>
  <div class="float-left col-md-4 p-0 mt-3 pt-1 ">
    <button type="button" (click)="openDialog(1)" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" 
      type="button">
      Add E-Sign
    </button>
    
  </div>
 
</div>
</div>
<!--newly added sign end-->
<!-- <div class="col-md-4" style="padding-left: 59px;">
  <label class="treat-label">Client Name</label>

<mat-form-field appearance="fill" style="padding-left: 14px;">
  <input matInput maxlength="50" appAlphabetOnly
  [(ngModel)]="_dtoPatientInfo.iftNotes.clientName" #clientName="ngModel" name="clientName">
</mat-form-field>
</div> -->
<!-- newly not mandatory added sign -->
<div class="col-md-12">
  <div class="form-group clearfix m-0">
  <div class="float-left col-md-4 p-0 mt-3 pt-1">
    <label class="treat-label mr-2">Secondary Signature</label>
    <img id='esign1' height="50px" width="300px"   src="{{_dtoPatientInfo.iftNotes.staffInitial1}}" style="border: 1px solid gray;" >
    <!-- <img id='esign1' height="50px" width="300px" *ngIf ="_dtoPatientInfo.iftNotes.staffInitial1"  src="{{_dtoPatientInfo.iftNotes.staffInitial1}}">
    <img id='esign1' height="50px" width="300px" *ngIf ="!_dtoPatientInfo.iftNotes.staffInitial1"  src= "{{eSignWhiteSpace}}"> -->
  </div>
  <div class="float-left col-md-4 p-0 mt-3 pt-1 ">
    <button type="button" (click)="openDialogs(1)" [disabled]="secondarySign()" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" 
      type="button">
      Add E-Sign
    </button>
    
  </div>
 
</div>
</div>
<!-- newly not mandatory sign end-->


          </div>
          </form>
        </div>
        </div>
        </div>
        <div class="tab-pane active show" id="goal" role="tabpanel" aria-labelledby="goal-tab" *ngIf=" SelectedTab ==3">
          <div class="goal-page-indiv col-md-12 mt-3" [ngClass]="{'disabled':progressUpg == true }">
            <div class="col-md-12 p-0">
              <div class="d-flex w-100 mb-4">
                <div class="box-lead-head mb-2 ">Goals </div>
                <div class="ml-auto" *ngIf=" (roleAccess.isCreate||roleAccess.isEdit)">
                  <button mat-stroked-button class="btn primary-btn-outlined-small mr-2 float-left" (click)="onsavegoals()" ><i class="far fa-save mr-2"></i>Save</button>
                  <button mat-stroked-button class="btn primary-btn-outlined-small mr-2 float-left">Cancel</button>
                </div>
              </div>
            </div>
            <div class="panel-group" id="accordion">
              <div *ngFor="let item of goalsinfo; let i=index">
              <div class="panel panel-default active" >
               
                <div class="panel-heading" >
                  <h4 class="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapse{{i}}" class="collapsed">
                      <i class="fa fa-caret-right mr-2"></i>{{ item.goal }}

                    </a>
                    <div class="goal-stat-chang float-right" >
                     <select class="form-control" [(ngModel)]="item.status"
                     #status="ngModel" name="status" (change)="OnstatusChange(item)">
                       <option [value]=2  >Inprogress</option>
                       <option [value]=3 > Completed</option>
                     </select>
                    </div>
                  </h4>
                </div>
                <div id="collapse{{i}}" class="panel-collapse collapse in" style="">
                  <div class="panel-body">
                   <div class="col-md-12 ben-head">
                     <div class="row">
                       
                       <div class="col-md-10 ben_des">
                        <div class=" ben_tit">
                         {{ item.benchmarkTitle }}
                        </div>
                       {{item.benchmarkDescription}}
                      </div>
                      <div class="col-md-2 ben_unit">
                        <p class="text-muted text-xs">Unit of Measure</p>
                        {{item.benchmarkunit}}
                      </div>
                     </div>
                   </div>
                  </div>
                </div>
              </div>
              </div>
           <!-- <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" class="collapsed">
                      <i class="fa fa-caret-right"></i> weight loss
                    </a>
                    <div class="goal-stat-chang float-right">
                      <select class="form-control">
                        <option>Completed</option>
                        <option>Inprogress</option>
                        <option>Delayed</option>
                      </select>
                     </div>
                  </h4>
                </div>
                <div id="collapseTwo" class="panel-collapse collapse">
                  <div class="panel-body">
                    <div class="col-md-12 ben-head">
                      <div class="row">
                        
                        <div class="col-md-10 ben_des">
                         <div class=" ben_tit">
                           Run for 2km
                         </div>
                         Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                       </div>
                       <div class="col-md-2 ben_unit">
                         <p class="text-muted text-xs">Unit of Measure</p>
                         10
                       </div>
                      </div>
                    </div> </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h4 class="panel-title">
                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree" class="collapsed">
                      <i class="fa fa-caret-right"></i> Improve mental health
                    </a>
                    <div class="goal-stat-chang float-right">
                      <select class="form-control">
                        <option>Completed</option>
                        <option>Inprogress</option>
                        <option>Delayed</option>
                      </select>
                     </div>
                  </h4>
                </div>
                <div id="collapseThree" class="panel-collapse collapse">
                  <div class="panel-body">
                    <div class="col-md-12 ben-head">
                     <div class="row">
                       
                       <div class="col-md-10 ben_des">
                        <div class=" ben_tit">
                          Run for 2km
                        </div>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                      </div>
                      <div class="col-md-2 ben_unit">
                        <p class="text-muted text-xs">Unit of Measure</p>
                        10
                      </div>
                     </div>
                   </div> </div>
                </div>
              </div>-->
            </div>
            <div class="col-md-12 p-0">
              <div class="form-group clearfix m-0">
              <div class="float-left col-md-4 p-0 mt-3 pt-1">
                <label class="treat-label mr-2">Provider's Signature</label>
                <img id='esign2' height="50px" width="300px" >
              </div>
              <div class="float-left col-md-4 p-0 mt-3 pt-1">
                <button type="button" (click)="openDialogForgoals(2)" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" 
                  type="button">
                  Add E-Sign
                </button>
              </div>
            </div>
            </div>
          </div>
          
        </div>
        <div  id="medication" role="tabpanel" aria-labelledby="medication-tab" class="tab-pane fade medic-hist  active show"  *ngIf="SelectedTab ==2">

          <div  class="medic-his-list p-2" [ngClass]="{'disabled':progressUpg == true }">
              <ul *ngIf="!onAddmedication">
                  <li  class="mb-2 cursor-pointer">
                      <div  class="card p-2 pb-1">
                          <div  class="col-md-12">
                              <div  class="col-md-12 medic-his-list">
                                  <div  class="row">
                                      <div  class="col-md-3 p-0">
                                          <div  class="mt-2">
                                              <div  class="date-medic">Previous Medication History</div>
                                          </div>
                                      </div>
                                      <div  class="col-md-5">
                                      </div>
                                      <div  class="col-md-4 pr-0">
                                          <div  class="d-flex w-100 mt-2 justify-content-end">
                                              <a>
                                                 <i  class="fas" 
                                                 [ngClass]="{'fa-chevron-up':isshowhistroy,'fa-chevron-down':!isshowhistroy}" (click)="isshowhistroy=!isshowhistroy"></i>
                                              </a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <ng-container *ngIf="isshowhistroy">
                          <div  class="panel-list ">
                              <div  class="col-md-12 mt-2">
                                  <div  class="row">
                                      <div  class="col-md-3">
                                          <div  class="w-100">
                                              <label >Medication</label>
                                          </div>
                                      </div>
                                      <div  class="col-md-2">
                                          <div >
                                              <label >Dosage</label>
                                          </div>
                                      </div>
                                      <div  class="col-md-3">
                                          <div >
                                              <label >Frequency</label>
                                          </div>
                                      </div>
                                      <div  class="col-md-4">
                                          <div >
                                              <label >Side Effect</label>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div  class="col-md-12 mt-2 mb-2 ">
                                <ul  >
                                  <li *ngFor="let item of medicationRecords ;let i=index" >
                                      <div  class="row">
                                          <div  class="col-md-3">
                                              <div  class="w-100">
                                                  <span>{{item.medications}}</span>
                                              </div>
                                          </div>
                                          <div  class="col-md-2">
                                              <div >
                                                  <span>{{item.dosage}}</span>
                                              </div>
                                          </div>
                                          <div  class="col-md-3">
                                              <div >
                                                  <span>{{item.frequency == 1 ? 'q.a.m. Every morning' :item.frequency == 2 ? 'q.p.m. Every evening' :item.frequency == 3 ? 'q.h.s Every night at bedtime'
                                                    :item.frequency == 4 ? 'b.i.d. Twice daily':item.frequency == 5 ? 't.i.d. 3 times a day':item.frequency == 6 ? 'q.i.d 4 times a day'
                                                    :item.frequency == 7 ? 'q.o.d Every other day':item.frequency == 8 ? 'q.w. Every week'  : 'p.r.n. Daily as needed'
                                                    
                                                  }}</span>
                                              </div>
                                          </div>
                                          <div  class="col-md-4">
                                              <div > {{item.sideEffects}} </div>
                                          </div>
                                      </div>                                                                  
                             </li>
                             </ul>                                    
                             </div>
                             <div class="panel-list " *ngIf="medicationhistroy?.length == 0">
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <span class="text-muted mx-auto">Previous medication history not available</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          </ng-container>
                             
                       </div>
                  </li>
                  <li class="mb-2" *ngFor="let item of lstmedicine;" >
                    <div class="card p-2 pb-1">
                      <div class="col-md-12">

                        <div class="col-md-12 medic-his-list " *ngIf="!onAddmedication">
                          <div class="row">
                            <div class="col-md-3 p-0">
                              <div class="mt-2">

                                <div class="date-medic" >Current Medication </div>
                              </div>
                            </div>
                            <div class="col-md-5">
                            </div>
                            <div class="col-md-4 pr-0">
                              <div class="d-flex w-100 mt-2">

                                <div class="date-medic ml-auto mt-1">Medication Date: <span>{{item.updatedDate |
                                    date:'MM/dd/yyy'}}</span> </div>
                                <a class="icon-note cursor-pointer" (click)="onEditMedication(item)"
                                  title="Edit"><span class="mr-2"><i class="far fa-edit"></i></span></a>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="panel-list "  *ngIf="!onAddmedication">
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-3">
                              <div class="w-100">
                                <label>Medication</label>

                              </div>

                            </div>
                            <div class="col-md-2">
                              <div class="">
                                <label>Dosage</label>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="">
                                <label>Frequency</label>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="">
                                <label>How Often?</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="!item.toggle">
                          <div class="col-md-12 mt-2 mb-2"
                            *ngFor="let data of item.dtoPatientMedication ">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="w-100">
                                  <span>{{data.medications}}</span>

                                </div>

                              </div>
                              <div class="col-md-2">
                                <div class="">
                                  <span>{{data.dosage}}</span>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="">
                                  <span>{{data.frequency == 1 ? 'q.a.m. Every morning' :data.frequency == 2 ? 'q.p.m. Every evening' :data.frequency == 3 ? 'q.h.s Every night at bedtime'
                                    :data.frequency == 4 ? 'b.i.d. Twice daily':data.frequency == 5 ? 't.i.d. 3 times a day':data.frequency == 6 ? 'q.i.d 4 times a day'
                                    :data.frequency == 7 ? 'q.o.d Every other day':data.frequency == 8 ? 'q.w. Every week'  : 'p.r.n. Daily as needed'
                                    
                                  }}</span>
                                  <!-- <span>{{data.frequency == 1 ? 'Daily' :data.frequency == 2 ? 'Weekly' : 'Monthly'
                                    }}</span> -->
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="">
                                  <span class="morn-time" *ngIf="data.isMorning"><i
                                      class="fas fa-cloud-sun mr-1"></i>Morning</span>
                                  <span class="morn-time" *ngIf="data.isNoon"><i
                                      class="fas fa-sun mr-1"></i>Noon</span>
                                  <span class="morn-time" *ngIf="data.isEvening"><i
                                      class="far fa-moon mr-1"></i>Evening</span>
                                  <span class="morn-time" *ngIf="data.isBedTime"><i class="fas fa-bed mr-1"></i>Bed
                                    Time</span>
                                  <span class="pl-2"
                                    *ngIf="!data.isMorning && !data.isNoon && !data.isEvening && !data.isBedTime">-</span>
                                </div>
                              </div>


                            </div>

                          </div>
                        </ng-container>
                        <ng-container *ngIf="item.toggle">
                          <div class="col-md-12 mt-2 mb-2" *ngFor="let data of item.dtoPatientMedication">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="w-100">
                                  <span>{{data.medications}}</span>

                                </div>

                              </div>
                              <div class="col-md-2">
                                <div class="">
                                  <span>{{data.dosage}}</span>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="">
                                  <span>{{data.frequency == 1 ? 'q.a.m. Every morning' :data.frequency == 2 ? 'q.p.m. Every evening' :data.frequency == 3 ? 'q.h.s Every night at bedtime'
                                    :data.frequency == 4 ? 'b.i.d. Twice daily':data.frequency == 5 ? 't.i.d. 3 times a day':data.frequency == 6 ? 'q.i.d 4 times a day'
                                    :data.frequency == 7 ? 'q.o.d Every other day':data.frequency == 8 ? 'q.w. Every week'  : 'p.r.n. Daily as needed'
                                    
                                  }}</span>
                                  <!-- <span>{{data.frequency == 1 ? 'Daily' :data.frequency == 2 ? 'Weekly' : 'Monthly'
                                    }}</span> -->
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="">
                                  <span class="morn-time" *ngIf="data.isMorning"><i
                                      class="fas fa-cloud-sun mr-1"></i>Morning</span>
                                  <span class="morn-time" *ngIf="data.isNoon"><i
                                      class="fas fa-sun mr-1"></i>Noon</span>
                                  <span class="morn-time" *ngIf="data.isEvening"><i
                                      class="far fa-moon mr-1"></i>Evening</span>
                                  <span class="morn-time" *ngIf="data.isBedTime"><i class="fas fa-bed mr-1"></i>Bed
                                    Time</span>
                                  <span class="pl-2"
                                    *ngIf="!data.isMorning && !data.isNoon && !data.isEvening && !data.isBedTime">-</span>
                                </div>
                              </div>


                            </div>

                          </div>
                        </ng-container>
                        <div class="expndable text-right  ">
                          <a class="exp-btn cursor-pointer" (click)="item.toggle=!item.toggle"><span
                              class="dots">...</span> </a>
                        </div>

                      </div>
                    </div>
                  </li>
              </ul>
          </div>
          <div class="first-entry-medication text-center mt-5 "
          *ngIf="lstmedicine?.length == 0 && _dtoMedication?.length == 0 && roleAccess.isCreate||roleAccess.isEdit">
          <label class="additional mt-3 pl-3">Medication history not available,
            would you like to <a class="text-underscore cursor-pointer"  (click)="addMedicationInfo()">add</a>
            Medication?
            <p><i class="fas fa-notes-medical"></i></p>

          </label>
        </div>
        
        <form (ngSubmit)="frmMedication.form.valid  && postProgressMedication()" id="frmMedication"
          autocomplete="off" #frmMedication="ngForm" novalidate class="clearfix pb-3">
          <div class="col-md-12 float-left text-right" *ngIf=" (roleAccess.isCreate||roleAccess.isEdit)">
            <button *ngIf="_dtoMedication?.length > 0" (click)="_dtoMedication=[]; onAddmedication=fasle; editmedication=false"
              class="btn primary-btn-outlined-small float-right mt-2 ml-2">Cancel</button>
            <button type="submit" *ngIf="_dtoMedication?.length > 0 " form="frmMedication"
              class="btn primary-btn-filled-small float-right mt-2 mr-2"><i
                class="far fa-save mr-2"></i>{{editmedication ? 'Update':'Save'}}</button>
          
  
            <button type="button" *ngIf="lstmedicine?.length > 0 && _dtoMedication?.length == 0"
              (click)="addMedicationInfo()" class="btn primary-btn-outlined-small float-right mt-2 "><i
                class="fas fa-plus-circle mr-2"></i>Add Medication</button>
          </div>
          <div class="add-medic float-left w-100" *ngFor="let med of _dtoMedication;let i= index;let last=last">

            <div class="col-md-12 medication ">
              <div class="row">
                <div class="col-md-2">
                  <label>Medication</label>
                  <input class="form-control" maxlength="50" [(ngModel)]="med.medications"
                    #medications="ngModel" name="medications{{i}}" placeholder="Medicine" type="text"
                    required>
                  <mat-error *ngIf="frmMedication.submitted && medications.invalid">
                    <mat-error class="mat-form-field-subscript-wrapper mt-3"
                      *ngIf="medications.errors.required">Medicine
                      is
                      required</mat-error>
                  </mat-error>
                </div>
                <div class="col-md-2">
                  <label>Dosage</label>
                  <input class="form-control" maxlength="30" [(ngModel)]="med.dosage" #dosage="ngModel"
                    name="dosage{{i}}" placeholder="Dosage" type="text" required>
                  <mat-error *ngIf="frmMedication.submitted && dosage.invalid">
                    <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="dosage.errors.required">
                      Dosage is
                      required</mat-error>
                  </mat-error>
                </div>
                <div class="col-md-2">
                  <label>Frequency</label>
                  <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                    [(ngModel)]="med.frequency" #frequency="ngModel" name="frequency{{i}}" required>
                    <option value="null">--Select Frequency--</option>
                    <option value="1">q.a.m. Every morning</option>
                          <option value="2">q.p.m. Every evening</option>
                          <option value="3">q.h.s Every night at bedtime</option>
                          <option value="4">b.i.d. Twice daily</option>
                          <option value="5">t.i.d. 3 times a day</option>
                          <option value="6">q.i.d 4 times a day</option>
                          <option value="7">q.o.d Every other day</option>
                          <option value="8">q.w. Every week</option>
                          <option value="9">p.r.n. Daily as needed</option>
                  </select>
                  <mat-error *ngIf="frmMedication.submitted && frequency.invalid">
                    <mat-error class="mat-form-field-subscript-wrapper mt-3"
                      *ngIf="frequency.errors.required">Frequency
                      is
                      required</mat-error>
                  </mat-error>
                </div>
                <div class="col-md-5 text-center">

                  <div class="row">
                    <div class="d-flex w-100">
                      <label>How Often?</label>
                      <div class="ml-auto b4-meal">
                        <div class="custom-radio">

                          <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isBeforemeal"
                            #isBeforemeal="ngModel" name="isBeforemeal{{i}}" required>
                            <mat-radio-button class="mr-1" [value]="true">Before Meals
                            </mat-radio-button>
                            <mat-radio-button [value]="false">After Meals
                            </mat-radio-button>
                          </mat-radio-group>
                          <mat-error *ngIf="frmMedication.submitted && isBeforemeal.invalid">
                            <mat-error class="mat-form-field-subscript-wrapper"
                              *ngIf="isBeforemeal.errors.required">
                              This field is required
                            </mat-error>
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 p-0">
                      <label class="sub-small-label">Morning</label>
                      <div class="custom-radio">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isMorning"
                          #isMorning="ngModel" name="isMorning{{i}}">
                          <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                          <mat-radio-button [value]="false">N</mat-radio-button>
                        </mat-radio-group>

                      </div>

                    </div>
                    <div class="col-md-3 p-0">
                      <label class="sub-small-label">Noon</label>
                      <div class="custom-radio">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isNoon"
                          #isNoon="ngModel" name="isNoon{{i}}">
                          <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                          <mat-radio-button [value]="false">N</mat-radio-button>
                        </mat-radio-group>
                      </div>

                    </div>
                    <div class="col-md-3 p-0">
                      <label class="sub-small-label">Evening</label>
                      <div class="custom-radio">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isEvening"
                          #isEvening="ngModel" name="isEvening{{i}}">
                          <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                          <mat-radio-button [value]="false">N</mat-radio-button>
                        </mat-radio-group>
                      </div>

                    </div>
                    <div class="col-md-3 p-0">
                      <label class="sub-small-label">Bed Time</label>
                      <div class="custom-radio">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isBedTime"
                          #isBedTime="ngModel" name="isBedTime{{i}}">
                          <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                          <mat-radio-button [value]="false">N</mat-radio-button>
                        </mat-radio-group>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-md-1 mt-4">
                  <a class="add " (click)="addMedicationInfo()" style="cursor: pointer;"><i
                      class="fa fa-lg fa-plus-circle mr-2"></i><span class="sr-only">Add</span></a>
                  <a class="add " style="cursor: pointer;" (click)="removeclinicalinformation(med,i)"><i
                      class="fa fa-lg fa-minus-circle"></i><span class="sr-only">Remove</span></a>
                </div>

              </div>
            </div>
          </div>
          
         
        </form>         
      </div>
        
        </div>
      </div>
    </div>
  </div>

  <div class="comment-popup benchmark-popup-rating">
    <div class="modal" tabindex="-1" role="dialog"  [style.display]="PopUp? 'block':'none'" >
      <div class="modal-dialog mat-dialog-container p-3" role="document">
        <div class="modal-content border-0">
          <div class="modal-body">
  <div >
    <p  class="text-center font-weight-500">Do you want to update with next benchmark?</p>
   <!-- <p class="disabled"> <i class="fas fa-check-circle text-success mr-2"></i> {{PopUpdata.benchmarkTitle}}</p>-->
    <mat-radio-group aria-label="Select an option" [(ngModel)]="SelectedBenchmark" >
      <mat-radio-button class="w-100" [value]=1>{{PopUpdata.benchmarkTitle}}  </mat-radio-button>
  
      <mat-radio-button *ngIf="PopUpdata.nextBenchmark!=''" class="w-100" [value]=2>{{PopUpdata.nextBenchmark}}  </mat-radio-button>
    </mat-radio-group>
  </div>
  
  <div class="text-center"  *ngIf=" (roleAccess.isCreate||roleAccess.isEdit)">
    <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" (click)="OnBenchmarkselect()">Save</button>
    <button type="button" class="btn btn-cancel" (click)="Onclosepopup()"
      data-dismiss="modal"  >Close</button>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  <a mimetype="application/octet-stream" #downloadLink></a>

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeadService } from '../lead.service';
import { Insurance } from 'src/app/_models/Index';
import { MatTableDataSource } from '@angular/material/table';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { NgForm } from '@angular/forms';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-cumulative-insurance',
  templateUrl: './cumulative-insurance.component.html',
  styleUrls: ['./cumulative-insurance.component.scss']
})
export class CumulativeInsuranceComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('insAuthorizationEdit') insAuthorizationEdit: NgForm;

  constructor(
    private route: ActivatedRoute,
    public commonService: CommonService,
    public spinnerservice: NgxSpinnerService,
    public leadservice: LeadService,
    public roleaccessservice: PageAccessService,
    private snackbar: SnackBarService,
    private router: Router
  ) { }
  patientid: any;
  patientaccIdcumulative: any;
  insuranceinfo: Insurance;
  ddlInsuranceType: any = [];
  ddlInsuranceRelationShip: any = [];
  lstInsurance: any;
  paientdetails: any;
  primaryInsurance: any;
  lstUploadInsurance: any;
  imgInsuranceFront = '';
  imgInsuranceBack = '';
  imgInsurancePolicy = '';
  imgNameInsuranceFront = '';
  imgNameInsuranceBack = '';
  idInsuranceFront: any;
  idInsuranceBack: any;
  idAttachment: any;
  imgAttachment: any;
  imgNameAttachment: any;
  lstCount: number;
  currentCount: number;
  authDetails: any;
  authDetailsList: any;
  dataSource: MatTableDataSource<any>;
  dataSourceinsurance: MatTableDataSource<any>;
  insDataSource: any = [];

  displayedColumns = [
    'authorizationNumber',
    'diagonasis',
    'effctive',
    'expire',
    'visits',
    'appts',
    'Actions',
  ];
  insuraceDetails: any;
  insuraceList: any;
  insuraceColumns = ['InsuranceType', 'CarrierName', 'PolicyID', 'GotoCRBA', 'GroupNo'];
  insuraceColumnsAdd = ['InsuranceType', 'CarrierName', 'MemberId', 'GroupNo', 'GotoCRBA', 'actions'];

  roleAccess: RoelAccess;
  isShowEdit: boolean = false;
  ddlInsuranceList: any = [];
  authDetailsEdit: any;
  AuthorizationTypeddl: any = [];
  drpAuthorizationTrack = [];
  isAdd: boolean = false;
  clientName: any;
  isShowImage: boolean = false;
  imgInsuranceSrc = '';
  currentImg: any;
  isNoInsurance: boolean = false;
  isChecked: boolean = false;
  isUploadinsuranceFront: boolean = false;
  isUploadinsuranceBack: boolean = false;
  isUploadinsurancePolicy: boolean = false;
  isAddInsurance = false;
  isEditInsurance: boolean = false;
  isAttachmentEnabled: boolean = false;
  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Authorization;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        this.patientid = this.commonService.FrontEndDecryption(params.id).replace(/"/g, "");
        this.patientaccIdcumulative = this.commonService.FrontEndDecryption(params.patientAccId).replace(/"/g, "");
      }
      this.authDetailsEdit = {};
      this.insuranceinfo = new Insurance();
      this.getInsuranceMaster();
      this.getInsurance();
      // this.GetAuthorizationTypeMaster();
      this.getAuthorizationDetailTrack();
      this.getInsuranceDetails(this.patientid);
      this.getInsuranceHistoryDetails(this.patientid);
    });

  }

  getInsuranceMaster() {
    this.spinnerservice.show();
    this.leadservice.getInsuranceMaster().subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this.ddlInsuranceType = this.commonService.decrypdata(res.insuranceType);
          this.ddlInsuranceRelationShip = this.commonService.decrypdata(res.insuranceRelationship);

        }
      },
      err => {
        // this.spinnerservice.hide();
      },
    );
  }
  InsuranceDatas = [];
  getInsurance() {

    this.spinnerservice.show();
    // this.insuranceinfo.Ssid = "";
    this.leadservice.getInsurance(this.patientid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        var dtoInsurance = this.commonService.decrypdata(res.objInputString);
        this.lstInsurance = dtoInsurance.ObjDtoInsurance;
        this.lstInsurance = this.lstInsurance.sort(x => x.IsprimaryInsurance);
        this.lstCount = this.lstInsurance.length;
        this.InsuranceDatas = dtoInsurance.ObjDtoInsurance;;
        this.paientdetails = dtoInsurance.PatientDetails;
        this.lstUploadInsurance = dtoInsurance.ObjDtoDocuments;
        var primaryInsurance = this.lstInsurance.filter(x => x.IsPrimaryInsurance === true);
        if ((primaryInsurance && primaryInsurance.length > 0) || !primaryInsurance.isSkipInsurance) {
          this.loadPrimaryInsurance();
          // this.isEditInsurance = true;
        }
      });
  }
  loadPrimaryInsurance() {
    this.primaryInsurance = this.lstInsurance.filter(
      x => x.IsPrimaryInsurance === true)[0];
    if (this.primaryInsurance != null && this.primaryInsurance != undefined) {
      this.insuranceinfo = this.primaryInsurance;
      this.clientName = this.insuranceinfo.MemberName;
      this.getAuthorizationDetails(this.primaryInsurance.InsuranceDetailsId);
      this.loadDocument(this.primaryInsurance.InsuranceDetailsId);


    } else {
      this.insuranceinfo = this.lstInsurance[0];
      this.clientName = this.insuranceinfo.MemberName;
      this.getAuthorizationDetails(this.insuranceinfo.InsuranceDetailsId);
    }
    this.currentCount = 1;
  }
  loadDocument(InsuranceDetailsId) {
    var documents = this.lstUploadInsurance.filter(
      x => x.DocumentId === InsuranceDetailsId);
    if (documents && documents.length > 0) {
      if (documents.length == 1) {
        if (documents[0].Description == "0") {

          this.imgInsuranceFront = documents[0].DocumentLocation;
          this.idInsuranceFront = documents[0].UploadDocumentsId;
          this.imgNameInsuranceFront = documents[0].DocumentName;
        } else if (documents[0].Description == "1") {
          this.idInsuranceBack = documents[0].UploadDocumentsId;
          this.imgInsuranceBack = documents[0].DocumentLocation;
          this.imgNameInsuranceBack = documents[0].DocumentName;
        } else if (documents[0].Description == "3") {
          this.idAttachment = documents[0].UploadDocumentsId;
          this.imgAttachment = documents[0].DocumentLocation;
          this.imgNameAttachment = documents[0].DocumentName;
        }
      }
      else {
        for (let i = 0; i < documents.length; i++) {
          if (documents[i].Description == "0") {
            this.imgInsuranceFront = documents[i].DocumentLocation;
            this.idInsuranceFront = documents[i].UploadDocumentsId;
            this.imgNameInsuranceFront = documents[i].DocumentName;
          }
          else if (documents[i].Description == "1") {
            this.idInsuranceBack = documents[i].UploadDocumentsId;
            this.imgInsuranceBack = documents[i].DocumentLocation;
            this.imgNameInsuranceBack = documents[i].DocumentName;
          } else if (documents[i].Description == "3") {
            this.idAttachment = documents[i].UploadDocumentsId;
            this.imgAttachment = documents[i].DocumentLocation;
            this.imgNameAttachment = documents[i].DocumentName;
          }
        }
      }
    }
  }
  getAuthorizationDetails(crbaId) {
    this.spinnerservice.show();
    this.leadservice.getAuthDetails(crbaId).subscribe(
      res => {
        this.spinnerservice.hide();
        this.authDetails = [];
        if (res.objInputString != null) {
          this.authDetails = this.commonService.decrypdata(res.objInputString);
        }
        this.dataSource = new MatTableDataSource(this.authDetails);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  nextInsurance() {
    if (this.lstInsurance.length > this.currentCount) {
      this.insuranceinfo = this.lstInsurance[this.currentCount];
      this.clientName = this.insuranceinfo.MemberName;
      this.getAuthorizationDetails(this.insuranceinfo.InsuranceDetailsId);
      this.loadDocument(this.insuranceinfo.InsuranceDetailsId);
      this.currentCount = this.currentCount + 1;
    } else {
      return;
    }

  }
  previousInsurance() {
    if (this.currentCount > 1) {
      this.currentCount = this.currentCount - 1;
      let count = this.currentCount;
      this.insuranceinfo = this.lstInsurance[count - 1];
      this.clientName = this.insuranceinfo.MemberName;
      this.getAuthorizationDetails(this.insuranceinfo.InsuranceDetailsId);
      this.loadDocument(this.insuranceinfo.InsuranceDetailsId);
    } else {
      return;
    }
  }
  editAuthorization(authDetails) {
    this.isShowEdit = true;
    this.getPatientInsuranceDetailList(this.patientid, authDetails);

    //this.authDetailsEdit.NumberOfVisits =  +this.authDetailsEdit.NumberOfVisits;
  }
  AddAuthorization() {
    this.isShowEdit = true;
    let authDetails = {};
    this.isAdd = true;
    this.isNoInsurance = false;
    this.getPatientInsuranceDetailList(this.patientid, authDetails);
  }
  getPatientInsuranceDetailList(PaInfoId, authDetails) {
    this.leadservice
      .getInsuranceDetailListByPatient(PaInfoId)
      .subscribe((res: any) => {
        this.ddlInsuranceList = res;
        this.authDetailsEdit = authDetails;
        this.authDetailsEdit.InsuranceDetailsId = this.authDetailsEdit?.InsuranceDetailsId == undefined ? this.insuranceinfo.InsuranceDetailsId : this.authDetailsEdit?.InsuranceDetailsId;
        this.authDetailsEdit.Name = this.clientName;
      }, (err) => {
        this.spinnerservice.hide();
      });
  }
  alphanumericwithhypen(e) {
    var code = 'charCode' in e ? e.charCode : e.keyCode;
    if (
      !(code == 45) && // hypen
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      e.preventDefault();
    }
  }
  alphanumeric(e) {
    var code = 'charCode' in e ? e.charCode : e.keyCode;
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      e.preventDefault();
    }
  }
  onClose() {
    this.isShowEdit = false;
    this.isAdd = false;
    this.isNoInsurance = false;
    this.authDetailsEdit = {}
  }
  onCloseImage() {
    this.isShowImage = false;
  }
  showImage(img) {
    this.isShowImage = true;
    if (img == 1) {
      this.currentImg = 1;
      this.imgInsuranceSrc = this.imgInsuranceFront;

    } else {
      this.currentImg = 2;
      this.imgInsuranceSrc = this.imgInsuranceBack;
    }
  }
  PostInsAuthorization() {
    if (
      (this.authDetailsEdit.InsuranceDetailsId != undefined && this.authDetailsEdit.InsuranceDetailsId != "undefined") ||
      this.authDetailsEdit.AuthorizationNumber ||
      this.authDetailsEdit.AuthorizationTypeId ||
      this.authDetailsEdit.CptHcpcs ||
      this.authDetailsEdit.StartDate ||
      this.authDetailsEdit.EndDate ||
      this.authDetailsEdit.NumberOfVisits ||
      this.authDetailsEdit.AuthorizationTrackId
    ) {
      if (!(this.authDetailsEdit.InsuranceDetailsId != undefined && this.authDetailsEdit.InsuranceDetailsId != "undefined")) {
        this.isNoInsurance = true;
        return;
      } else {
        this.isNoInsurance = false;
      }
      this.spinnerservice.show();
      //this.authDetailsEdit.PatientInfoId= this.patientInfoId;
      this.authDetailsEdit.AuthorizationTypeId = +this.authDetailsEdit.AuthorizationTypeId; //.toString();
      // this.insAuthorizationDtl.IsIop = this.IsIop;
      // this.insAuthorizationDtl.IsPcp = this.IsPcp;
      this.authDetailsEdit.DaysPerWeek = +this.authDetailsEdit.DaysPerWeek;
      this.authDetailsEdit.InsuranceDetailsId = +this.authDetailsEdit.InsuranceDetailsId;
      this.authDetailsEdit.AuthorizationTrackId = +this.authDetailsEdit.AuthorizationTrackId;
      this.authDetailsEdit.NumberOfVisits = +this.authDetailsEdit.NumberOfVisits;
      this.authDetailsEdit.PatientInfoId = +this.patientid;

      //console.log(this.insAuthorizationDtl)
      //const dataToEncrypt = this.commonService.DataToEncrypt(this.insAuthorizationDtl, true);
      this.leadservice.postInsAuthorizationDtl(this.authDetailsEdit).subscribe(
        (res) => {
          if (res != '') {
            this.spinnerservice.hide();
            this.isShowEdit = false;
            this.isAdd = false;
            this.insAuthorizationEdit.resetForm();
            this.snackbar.success(res);
            this.isNoInsurance = false;
            this.getAuthorizationDetails(this.insuranceinfo.InsuranceDetailsId);
            this.clientName = this.insuranceinfo.MemberName;
          }
        },
        (err) => {
          this.spinnerservice.hide();
        }
      );
    } else {
      this.snackbar.error(constantVariables.ErrorMessage.Insurancedetails);
    }
  }
  GetAuthorizationTypeMaster() {
    this.leadservice.getAuthorizationTypeMaster().subscribe((res: any) => {
      this.AuthorizationTypeddl = res;
      // console.log(res)
    }, (err) => {
      //this.spinnerservice.hide();
    });
  }
  getAuthorizationDetailTrack() {
    this.leadservice.getAuthorizationDetailTrack().subscribe(
      (data) => {
        if (data.objInputString != null) {
          this.drpAuthorizationTrack = this.commonService.decrypdata(
            data.objInputString
          );
        }
      },
      (err) => {
        //this.spinnerservice.hide();
      }
    );
  }
  onback() {
    this.router.navigateByUrl('/patientslists');
  }
  onUpdateCRBA() {
    this.router.navigate(['/crba'], { queryParams: { id: this.commonService.FrontEndEncryption(this.patientid),patientaccId : this.commonService.FrontEndEncryption(this.patientaccIdcumulative)
      , InsurancetypeId: this.commonService.FrontEndEncryption(this.insuranceinfo.InsuranceDetailsId)} });


  }
  InsuraceActiveChange(insuranceinfo) {
    this.spinnerservice.show();
    let obj = {
      InsuranceDetailsId: insuranceinfo.InsuranceDetailsId,
      InsuranceIsActive: insuranceinfo.InsuranceIsActive,
      PatientInfoId: this.patientid
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.leadservice
      .ChangeInsuranceActive(dataToEncrypt)
      .subscribe((res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(res);
      }, (err) => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
        insuranceinfo.InsuranceIsActive = !insuranceinfo.InsuranceIsActive;
      });
  }

  getInsuranceHistoryDetails(patientid) {
    this.spinnerservice.show();
    this.leadservice.getInsuranceHistoryDetails(patientid).subscribe(
      res => {
        this.spinnerservice.hide();
        this.insuraceDetails = [];
        if (res.objInputString != null) {
          this.insuraceDetails = this.commonService.decrypdata(res.objInputString);
        }
        this.dataSourceinsurance = new MatTableDataSource(this.insuraceDetails);
        this.dataSourceinsurance.paginator = this.paginator;
        this.dataSourceinsurance.sort = this.sort;
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  getInsuranceDetails(patientid) {
    this.spinnerservice.show();
    this.insuranceinfo.Ssid = "";
    this.leadservice.getInsurance(this.patientid).subscribe(
      (res: any) => {

        // if (localStorage.getItem('currentstatus')) {
        //   this.immidiateactionid = Number(localStorage.getItem('currentstatus'));
        // }

        var dtoInsurance = this.commonService.decrypdata(res.objInputString);
        this.lstInsurance = dtoInsurance.ObjDtoInsurance;
        //  this.lstInsuranceHistory = dtoInsurance.ObjDtoInsuranceHistory;

        this.InsuranceDatas = dtoInsurance.ObjDtoInsurance;;
        this.paientdetails = dtoInsurance.PatientDetails;
        // if (this.lstInsurance && this.lstInsurance.length == 0) {
        //   this.insuranceTabCompleted = false;

        //   this.isAddInsurance = true;
        //   this.isAddPrimary = true;

        //   this.insuranceinfo.MemberName = this.paientdetails.MemberName;

        // } else {
        //   this.isAddPrimary = false;
        //   this.IsNoInsurancechecked = this.lstInsurance[0].IsSkipInsurance
        //   this.IsNoInsurance = this.IsNoInsurancechecked

        //   this.primaryTabCompleted = dtoInsurance.ObjDtoInsurance[0].IsProfileCompleted == null ? false : dtoInsurance.ObjDtoInsurance[0].IsProfileCompleted;
        //   this.insuranceTabCompleted = dtoInsurance.ObjDtoInsurance[0].IsInsuranceCompleted == null ? false : dtoInsurance.ObjDtoInsurance[0].IsInsuranceCompleted;
        //   this.historyTabCompleted = dtoInsurance.ObjDtoInsurance[0].IsHistoryCompleted == null ? false : dtoInsurance.ObjDtoInsurance[0].IsHistoryCompleted;
        //   this.esignTabCompleted = this.patientPrimary.Esignature == null || this.patientPrimary.Esignature == '' ? false : true;

        // }
        this.lstUploadInsurance = dtoInsurance.ObjDtoDocuments;
        var primaryInsurance = this.lstInsurance.filter(x => x.IsPrimaryInsurance === true);
        if ((primaryInsurance && primaryInsurance.length > 0) || !primaryInsurance.isSkipInsurance) {
          this.loadPrimaryInsurance();
          // this.isEditInsurance = true;
        }
        // else {
        //   this.isAddInsurance = false;
        //   this.isEditInsurance = false;
        //   this.getPatientAdditionalinfo(this.patientid)

        // }
        this.insDataSource = new MatTableDataSource(this.lstInsurance.filter(obj => obj.IsSkipInsurance != true && obj.Ssid != null));
        this.insDataSource.paginator = this.paginator;
        this.insDataSource.sort = this.sort;

        this.spinnerservice.hide();
        // if(this.isDelete ){
        //   if(!this.lstInsurance || (this.lstInsurance && this.lstInsurance.length == 0)){
        //     this.insurancefrm.resetForm();
        //     this.insuranceinfo.IsPrimaryInsurance = true;
        //     this.insuranceTabCompleted = false;
        //   }
        //   this.isDelete = false;
        // }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onEditInsurance(data) {
    this.isAttachmentEnabled = true;
    this.resetInsUploadflag();
    this.imgInsuranceFront = "";
    this.imgInsuranceBack = "";
    this.imgInsurancePolicy = "";
    this.imgAttachment = "";
    this.imgNameAttachment = "";
    this.isAddInsurance = true;
    this.isEditInsurance = true;
    this.insuranceinfo = data;
    this.loadInsuranceDocument(data);

  }
  resetInsUploadflag() {
    this.isUploadinsuranceFront = false;
    this.isUploadinsuranceBack = false;
    this.isUploadinsurancePolicy = false;
  }
  loadInsuranceDocument(data) {
    var documents = this.lstUploadInsurance.filter(

      x => x.DocumentId === data.InsuranceDetailsId);

    if (documents && documents.length > 0) {
      if (documents.length == 1) {
        if (documents[0].Description == "0") {
          this.imgInsuranceFront = documents[0].DocumentLocation;
          this.idInsuranceFront = documents[0].UploadDocumentsId;
          this.imgNameInsuranceFront = documents[0].DocumentName;
        } else if (documents[0].Description == "1") {
          this.idInsuranceBack = documents[0].UploadDocumentsId;
          this.imgInsuranceBack = documents[0].DocumentLocation;
          this.imgNameInsuranceBack = documents[0].DocumentName;
        }
        else if (documents[0].Description == "3") {
          this.idAttachment = documents[0].UploadDocumentsId;
          this.imgAttachment = documents[0].DocumentLocation;
          this.imgNameAttachment = documents[0].DocumentName;
        }
      }
      else {
        for (let i = 0; i < documents.length; i++) {
          if (documents[i].Description == "0") {
            this.imgInsuranceFront = documents[i].DocumentLocation;
            this.idInsuranceFront = documents[i].UploadDocumentsId;
            this.imgNameInsuranceFront = documents[i].DocumentName;
          }
          else if (documents[i].Description == "1") {
            this.idInsuranceBack = documents[i].UploadDocumentsId;
            this.imgInsuranceBack = documents[i].DocumentLocation;
            this.imgNameInsuranceBack = documents[i].DocumentName;
          }
          else if (documents[i].Description == "3") {
            this.idAttachment = documents[i].UploadDocumentsId;
            this.imgAttachment = documents[i].DocumentLocation;
            this.imgNameAttachment = documents[i].DocumentName;
          }
        }
      }
    }
  }
  onviewInsurance(data) {
    window.scroll(0, 0);
    this.imgInsuranceFront = "";
    this.imgInsuranceBack = "";
    this.imgInsurancePolicy = "";
    this.isAddInsurance = false;
    this.insuranceinfo = data;
    this.loadInsuranceDocument(data);
    this.resetInsUploadflag();
  }
  UpdateInsurance() {
    if (this.isAddInsurance || this.isEditInsurance) {
      const insuranceData = {
        MemberId: this.insuranceinfo.MemberId,
        GroupNo: this.insuranceinfo.GroupNo,
        CarrierName: this.insuranceinfo.CarrierName,
        InsuranceDetailsId: this.insuranceinfo.InsuranceDetailsId,
        InsurancetypeId: this.insuranceinfo.InsuranceTypeId,
        StartDate: this.insuranceinfo.StartDate,
        EndDate: this.insuranceinfo.EndDate,
        GroupName: this.insuranceinfo.GroupName,
        CoPayRequired: this.insuranceinfo.CoPayRequired,
        PatientPercent: this.insuranceinfo.PatientPercent,
        AuthorizationRequired: this.insuranceinfo.AuthorizationRequired

      };
      this.spinnerservice.show();
      this.leadservice.postUpdateInsurance(insuranceData).subscribe(
        (res: any) => {
          // this.getInsuranceMaster();
          this.getInsurance();
          // this.GetAuthorizationTypeMaster();
          this.getAuthorizationDetailTrack();
          this.getInsuranceDetails(this.patientid);
          this.getInsuranceHistoryDetails(this.patientid);

          this.snackbar.success(JSON.parse(res).message);
          this.spinnerservice.hide();
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }

  }
  onGoToCRBA(data) {
    this.isAttachmentEnabled = true;
    this.resetInsUploadflag();
    this.imgInsuranceFront = "";
    this.imgInsuranceBack = "";
    this.imgInsurancePolicy = "";
    this.imgAttachment = "";
    this.imgNameAttachment = "";
    this.isAddInsurance = true;
    this.isEditInsurance = true;
    this.insuranceinfo = data;
    this.loadInsuranceDocument(data);

}
onUpdateCRBANew(data) {
  localStorage.setItem("HistoryId","0");
  this.router.navigate(['/crba'], { queryParams: { id: this.commonService.FrontEndEncryption(this.patientid),patientaccId : this.commonService.FrontEndEncryption(this.patientaccIdcumulative)
    ,InsurancetypeId : this.commonService.FrontEndEncryption(data.InsuranceDetailsId), PageId: 2  } });


  }

onUpdateCRBAHistory(data)
{
  localStorage.setItem("HistoryId",data.HistoryId);
  this.router.navigate(['/crba'], { queryParams: { id: this.commonService.FrontEndEncryption(this.patientid),patientaccId : this.commonService.FrontEndEncryption(this.patientaccIdcumulative)
    ,InsurancetypeId : this.commonService.FrontEndEncryption(data.InsuranceDetailsId), PageId: 2 ,isView : this.commonService.FrontEndEncryption(true) } });

  }

}

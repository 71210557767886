import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { Global } from 'src/app/global/global.model';
import { constantVariables } from 'src/app/constant/constant';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { Pipe, PipeTransform } from '@angular/core';

import {
  DayScheduleGroupTherapy,
  DayScheduleSubGroupAssign,
  DayScheduleSubGroup,
  DtoPostInactiveAppointments
} from 'src/app/_models/_Treatment/_dayschedule';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { NgForm } from '@angular/forms';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';


@Component({
  selector: 'app-assign-clinician',
  templateUrl: './assign-clinician.component.html',
  styleUrls: ['./assign-clinician.component.scss']
})
export class AssignClinicianComponent implements OnInit {
  grpTherapyId: any;
  ddlPrimarystaff: any;
  ddlSfccLocation: any;
  isNewSubgroup: boolean = false;
  isCreateSubgroup: boolean = false;
  _dtoGrpTherapyMeta: any;
  _dtoGrpTherapyUsers: any = [];

  _dtoDaySchGrpTherapy: DayScheduleGroupTherapy;
  _dtoDayScheduleSubGroup: DayScheduleSubGroup;
  _dtoAssignedUsers: Array<DayScheduleSubGroupAssign>;
  lstsubgroup: any = [];
  _dtoSelectedUsers: any = [];
  mintime: any;
  maxtime: any;
  endtime: boolean = false;
  assignKidsError: boolean = false;
  subGroupCopy: any = [];
  patientExist: string = '';
  clinicianExist: string = '';
  todaysDate: any = new Date();
  selecteddate: string;

  errorMessage:string;
  showErrorMessage:boolean=false;

  pcpError:boolean=false;
  isSave:boolean=true;
  _dtoPostInactiveAppointments:DtoPostInactiveAppointments;
  therapyId;
  isIndividualFamilyTherapy:boolean=false;
  isGroupyTherapy:boolean=false;
  selectedMsId:any;
  _dtoPatientInfo: any ;
  currentdate;
  clinicians:any;
  allClinicians:any[];
  roleAccess:RoelAccess;
  blockTimeDetails=[];
  currentDateTime = new Date();
  buttonEnabled = false;
  constructor(
    public global: Global,
    private snackbar: SnackBarService,
    private route: ActivatedRoute,
    private router: Router,
    public commonService: CommonService,
    public clinicianservice: ClinicianService,
    public spinnerservice: NgxSpinnerService,
    public treatmentservice: TreatmentService,
    private date: DatePipe,
    private dialog: MatDialog,
    public roleaccessservice:PageAccessService
  ) {}

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.ScheduleAppointmentsTherapies;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this._dtoDaySchGrpTherapy = new DayScheduleGroupTherapy();
    this._dtoDayScheduleSubGroup = new DayScheduleSubGroup();
    this._dtoPostInactiveAppointments = new DtoPostInactiveAppointments();
    this.currentdate = moment().format("YYYY-MM-DD HH:mm:ss")
    //this.isNewSubgroup = true;
    this.route.queryParams.subscribe((params) => {

      if (Object.keys(params).length != 0) {
        this.grpTherapyId = Number(this.commonService.FrontEndDecryption(params.id));
        this.selectedMsId= Number(this.commonService.FrontEndDecryption(params.id));

          this.therapyId = Number(this.commonService.FrontEndDecryption(params.theraphyid));
           if (this.therapyId == constantVariables.therapy.GroupTheraphy) {
            let res1= this.commonService.FrontEndDecryption(params.clinicians).replace(/"/g, '');
            this.clinicians= res1.split(',').map(Number);
            this.isNewSubgroup = true;
            this.isIndividualFamilyTherapy=false;
            this.isGroupyTherapy=true;
            this.getsubgrouptherapy();
            this.getPrimaryStaff();
            this.getddlLocation();
            this.getDayScheduleForAssign();

          }

       }
    });
  }
  getddlLocation() {
    this.spinnerservice.show();
    this.treatmentservice.getLocations().subscribe(
      (res) => {
        this.spinnerservice.hide();
        if (res != null) {
          this.ddlSfccLocation = res;
        }
      },
      (err) => {
        this.spinnerservice.hide();
      }
    );
  }
  onNewSubGroup() {
    this.isNewSubgroup = true;
  }
  getDayScheduleForAssign() {
    this.spinnerservice.show();
     this.treatmentservice
      .getDayScheduleForAssignClinician(this.grpTherapyId)
      .subscribe((res: any)  => {
        this.spinnerservice.hide();
          if(res.objInputString!=null)
           {
           // console.log(this.commonService.decrypdata(res.objInputString));
        let fres=this.commonService.decrypdata(res.objInputString);
       // console.log(fres);
       this._dtoGrpTherapyMeta = fres[0];
       this._dtoGrpTherapyUsers = fres;
       console.log(this._dtoGrpTherapyUsers);
       
        //this._dtoGrpTherapyMeta = res[0];
        //this._dtoGrpTherapyUsers = res;
           }
          },
           err => {
            this.spinnerservice.hide();
      });
  }
  onCreateSubGroup() {
    this.isCreateSubgroup = true;
    this._dtoAssignedUsers = [];
    this._dtoDaySchGrpTherapy = new DayScheduleGroupTherapy();
    this._dtoDayScheduleSubGroup = new DayScheduleSubGroup();
  }
  drop(event) {
     if (
      this._dtoAssignedUsers.filter(
        (x) => x.patientAccountId == event.item.data.PatientAccountId
      ).length == 0
    ) {
      event.item.data.isSelected = true;
      let _obj = {
        dayScheduleAssignId: 0,
        dayScheduleSubGroupId: 0,
        groupTherapyId: this.grpTherapyId,
        patientAccountId: event.item.data.PatientAccountId,
        patientName: event.item.data.UserName,
        clinicianId: null,
        clinicianName: null,
      };
      this._dtoAssignedUsers.push(_obj);
      if (this._dtoAssignedUsers.length > 0) {
        this.assignKidsError = false;
      }

    }

  }
  onremoveassigned(item) {

    let obj = this._dtoGrpTherapyUsers.filter(
      (x) =>
        x.userName == item.patientName &&
        x.patientAccountId == item.patientAccountId
    );
    if (obj.length > 0) {
      obj[0].isSelected = false;
    }
    const objIndex = this._dtoAssignedUsers.findIndex(
      (x) =>
       x.patientName == item.patientName &&
        x.patientAccountId == item.patientAccountId
    );

    if (objIndex > -1) {
      this._dtoAssignedUsers.splice(objIndex, 1);
    }
    this.clinicianExist = '';
    this.patientExist = '';
  }
  getPrimaryStaff() {

    this.commonService
      .getStaffList(constantVariables.role.PrimaryClinician)
      .subscribe((res: any) => {
        if (res) {
          //this.ddlPrimarystaff = res;
          this.allClinicians=res;
          console.log(res);
          
          this.cliniciandropdownchanges();
         }
      });
  }
  tmpcl=[];
  OndayschClinicianchange(val)
  {

    if(val==-1)
    {

      // this.ddlPrimarystaff=[];
      //this.ddlPrimarystaff=this.allClinicians;
     let tmpall=this.allClinicians;
     let tmp2=this.ddlPrimarystaff;
     this.tmpcl = tmpall.filter(o1 => !tmp2.some(o2 => o1.staffId === o2.staffId));;

   if(this.tmpcl.length>0){
         for(let i=0;i<this.tmpcl.length;i++)
        {
          let _obj1 = {
            email: this.tmpcl[i].email,
            firstName:this.tmpcl[i].firstName,
            lastName: this.tmpcl[i].lastName,
            staffId:this.tmpcl[i].staffId
          }
          this.ddlPrimarystaff.push(_obj1)
         }
         this.ddlPrimarystaff = this.ddlPrimarystaff.filter(x=>x.staffId!=-1);
         }
    }


  }
  postdayschdule(form: NgForm) {
    // this.spinnerservice.show();
     this.buttonEnabled = true;
    // console.log("hit");
    if (this._dtoAssignedUsers.length == 0 || null) {
      this.assignKidsError = true;
      this.buttonEnabled = false;
      return;
     }
     if (!form.valid) {
      this.buttonEnabled = false;
      return;
    }
    var offset = new Date().getTimezoneOffset();
    let _obj = {
      StartDate: moment.utc(this._dtoDaySchGrpTherapy.schduleDate).utcOffset(-offset).format("MM/DD/YYYY"),
      EndDate: moment.utc(this._dtoDaySchGrpTherapy.schduleDate).utcOffset(-offset).format("MM/DD/YYYY"),
      FromTime: this._dtoDayScheduleSubGroup.startTime,
      ToTime: this._dtoDayScheduleSubGroup.endTime,
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.treatmentservice.getBlockTimeConfilcts(dataToEncrypt).subscribe(
      (res: any) => {
        this.blockTimeDetails=JSON.parse(res);
        if(this.blockTimeDetails.length>0)
        {
          for (let j = 0; j < this._dtoAssignedUsers.length; j++) {
            let count = 0;
            let filterBlockTime = this.blockTimeDetails.filter(
              (x) =>
                Number(x.clinicianId) == Number(this._dtoAssignedUsers[j].clinicianId)
                && new Date(x.scheduleDate).toDateString() == new Date(this._dtoDaySchGrpTherapy.schduleDate).toDateString()
            );
            for (let i = 0; i < filterBlockTime.length; i++) {
              const startime = moment(filterBlockTime[i].startTime).format('HH:mm');
              const endtime = moment(filterBlockTime[i].endTime).format('HH:mm');
              var bookedsttime = new Date(
                dt.getFullYear(),
                dt.getMonth(),
                dt.getDate(),
                parseInt(startime.split(':')[0]),
                parseInt(startime.split(':')[1]),
                parseInt('00')
              );
      
              var bookedettime = new Date(
                dt.getFullYear(),
                dt.getMonth(),
                dt.getDate(),
                parseInt(endtime.split(':')[0]),
                parseInt(endtime.split(':')[1]),
                parseInt('00')
              );
              if (
                (substartTime >= bookedsttime && substartTime <= bookedettime) ||
                (subendTime >= bookedsttime && subendTime <= bookedettime)
              ) {
                if (!errorClinician.includes(filterBlockTime[i].clinicianName)) {
                  errorClinician +=
                    '<br>' +
                    filterBlockTime[i].clinicianName +
                    ' already associated with ' +
                    filterBlockTime[i].scheduleName +
                    ' at ' +
                    moment(filterBlockTime[i].startTime).format('HH:mm a') +
                    '-' +
                    moment(filterBlockTime[i].endTime).format('HH:mm a') +
                    '';
                  count++;
                }
              }
            }
          }
        }
      },
      err => {
        this.spinnerservice.hide();
        this.buttonEnabled = false;
      },
    );
    var dt = new Date();
    var dt = new Date(this._dtoDaySchGrpTherapy.schduleDate);
    var substartTime = new Date(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate(),
      parseInt(this._dtoDayScheduleSubGroup.startTime.split(':')[0]),
      parseInt(this._dtoDayScheduleSubGroup.startTime.split(':')[1]),
      parseInt('00')
    );
    var subendTime = new Date(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate(),
      parseInt(this._dtoDayScheduleSubGroup.endTime.split(':')[0]),
      parseInt(this._dtoDayScheduleSubGroup.endTime.split(':')[1]),
      parseInt('00')
    );

    //EndTimeLimit

    var tempStartTime = new Date(this._dtoGrpTherapyMeta.StartTime);
     var getEndTimeLimit = new Date(this._dtoGrpTherapyMeta.StartTime).setHours(
      tempStartTime.getHours() + (this._dtoGrpTherapyMeta.Duration / 60)
    );
    var tempConfirmDate = new Date(getEndTimeLimit);
    this.errorMessage='Select time between ' +this.date.transform(this._dtoGrpTherapyMeta.StartTime, 'h:mm a') +' and ' +this.date.transform(tempConfirmDate, 'h:mm a');
    
    var subStartTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.startTime
    ).getTime();
    var subEndTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.endTime
    ).getTime();
    var startTimeNew = new Date(
      'May 26, 2016 ' + tempStartTime.toLocaleTimeString()
    ).getTime();
    var endTimeNew = new Date('May 26, 2016 ' +tempConfirmDate.toLocaleTimeString()
    ).getTime();
    var startTimeRestrict = new Date('May 26, 2016 ' +this.currentDateTime.toLocaleTimeString()
    ).getTime();
    
    if((subStartTimeNew <= startTimeRestrict)){
       this.errorMessage = 'Start time should be greater than current time' +this.date.transform(this._dtoGrpTherapyMeta.StartTime, 'h:mm a') +' and ' +this.date.transform(tempConfirmDate, 'h:mm a');
        //  console.log('test');
         this.showErrorMessage=true;
         this.buttonEnabled = false;
         return     
    }
    if((subEndTimeNew <= subStartTimeNew)){
      this.endtime=true;
      this.showErrorMessage=false;
      this.buttonEnabled = false;
      return
    }
    if(subStartTimeNew < startTimeNew || subStartTimeNew > endTimeNew){
      this.showErrorMessage=true;
      this.buttonEnabled = false;
    return
    }
    if(subEndTimeNew > endTimeNew || subEndTimeNew < startTimeNew){
      this.showErrorMessage=true;
      this.buttonEnabled = false;
      return
    }
    if (this._dtoAssignedUsers.length == 0 || null) {
     this.assignKidsError = true;
     this.buttonEnabled = false;
     return;
    }

    var errorPatient = '';
    var errorClinician = '';
    for (let j = 0; j < this._dtoAssignedUsers.length; j++) {
      let filtersubgroup = this.subGroupCopy.filter(
        (x) => x.patientAccountId == this._dtoAssignedUsers[j].patientAccountId
        && new Date(x.scheduleDate).toDateString() == new Date(this._dtoDaySchGrpTherapy.schduleDate).toDateString()
      );
      for (let i = 0; i < filtersubgroup.length; i++) {
        const startime = moment(filtersubgroup[i].startTime).format('HH:mm');
        const endtime = moment(filtersubgroup[i].endTime).format('HH:mm');
        var bookedsttime = new Date(
          dt.getFullYear(),
          dt.getMonth(),
          dt.getDate(),
          parseInt(startime.split(':')[0]),
          parseInt(startime.split(':')[1]),
          parseInt('00')
        );

        var bookedettime = new Date(
          dt.getFullYear(),
          dt.getMonth(),
          dt.getDate(),
          parseInt(endtime.split(':')[0]),
          parseInt(endtime.split(':')[1]),
          parseInt('00')
        );
        if (
          (substartTime >= bookedsttime && substartTime <= bookedettime) ||
          (subendTime >= bookedsttime && subendTime <= bookedettime)
        ) {
          if (!errorPatient.includes(filtersubgroup[i].userName)) {
          errorPatient +=
            '<br>' +
            filtersubgroup[i].userName +
            ' already associated with the theme ' +
            filtersubgroup[i].themeName +
            ' at ' +
            moment(filtersubgroup[i].startTime).format('HH:mm a') +
            '-' +
            moment(filtersubgroup[i].endTime).format('HH:mm a') +
            '';
          }
        }
      }
    }
    for (let j = 0; j < this._dtoAssignedUsers.length; j++) {
      let count = 0;
      let filtersubgroup = this.subGroupCopy.filter(
        (x) =>
          Number(x.clinicianId) == Number(this._dtoAssignedUsers[j].clinicianId)
          && new Date(x.scheduleDate).toDateString() == new Date(this._dtoDaySchGrpTherapy.schduleDate).toDateString()
      );
      for (let i = 0; i < filtersubgroup.length; i++) {
        const startime = moment(filtersubgroup[i].startTime).format('HH:mm');
        const endtime = moment(filtersubgroup[i].endTime).format('HH:mm');
        var bookedsttime = new Date(
          dt.getFullYear(),
          dt.getMonth(),
          dt.getDate(),
          parseInt(startime.split(':')[0]),
          parseInt(startime.split(':')[1]),
          parseInt('00')
        );

        var bookedettime = new Date(
          dt.getFullYear(),
          dt.getMonth(),
          dt.getDate(),
          parseInt(endtime.split(':')[0]),
          parseInt(endtime.split(':')[1]),
          parseInt('00')
        );
        if (
          (substartTime >= bookedsttime && substartTime <= bookedettime) ||
          (subendTime >= bookedsttime && subendTime <= bookedettime)
        ) {
          if (!errorClinician.includes(filtersubgroup[i].clinicianName)) {
            errorClinician +=
              '<br>' +
              filtersubgroup[i].clinicianName +
              ' already associated with the theme ' +
              filtersubgroup[i].themeName +
              ' at ' +
              moment(filtersubgroup[i].startTime).format('HH:mm a') +
              '-' +
              moment(filtersubgroup[i].endTime).format('HH:mm a') +
              '';
            count++;
          }
        }
      }
    }
    this.patientExist = errorPatient;
    this.clinicianExist = errorClinician;
    this.buttonEnabled = false;

    // }
   

    if (this.patientExist?.length > 0) {
     return;
    }
    if (this.clinicianExist?.length > 0) {
     return;
    }
       var stt = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.startTime
    ).getTime();

    var endt = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.endTime
    ).getTime();

    if (stt < endt) {
    //  this._dtoDaySchGrpTherapy.schduleDate = this.selecteddate;
      this._dtoDaySchGrpTherapy.groupTherapyId = this.grpTherapyId;
      this._dtoDaySchGrpTherapy.dayScheduleSubGroupAssign =
        this._dtoAssignedUsers;
      this._dtoDaySchGrpTherapy.dayScheduleSubGroup =
        this._dtoDayScheduleSubGroup;
      let obj = JSON.stringify(this._dtoDaySchGrpTherapy);
      const dataToEncrypt = this.commonService.DataToEncrypt(
        this._dtoDaySchGrpTherapy,
        false
      );

      this.spinnerservice.show();
      this.treatmentservice.postdayschdule(dataToEncrypt).subscribe(
        (res: any) => {
         this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getsubgrouptherapy();
          this.clearsubgroup();
          this.isCreateSubgroup = true;
          this.onCloseSubGroup();
          form.resetForm();
          this.buttonEnabled = false;
        },
        (err) => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
          this.buttonEnabled = false;
        }
      );
    } else {
      this.buttonEnabled = false;
      return;
    }
  }
  // getsubgrouptherapy() {
  //   this.onCreateSubGroup();

  // }
  getsubgrouptherapy() {

    this.treatmentservice
      .getsubgrouptherapy(this.grpTherapyId, '1900/01/01')
      .subscribe((res: any) => {
        if (res) {

          this.lstsubgroup = res;
          for (let i = 0; i < this.lstsubgroup.length; i++) {
            this.subGroupCopy = [
              ...this.subGroupCopy,
              ...this.lstsubgroup[i].dtoSpDayScheduleSubgroupDetails,
            ];
          }
          if (res?.length == 0) {
            this.onCreateSubGroup();
          }
        }
      });
  }
  clearsubgroup() {
    this.isCreateSubgroup = false;
    this._dtoAssignedUsers = [];
    // this._dtoDaySchGrpTherapy = new DayScheduleGroupTherapy();
    // this._dtoDayScheduleSubGroup = new DayScheduleSubGroup();
  }
  onCloseSubGroup() {
    this.isCreateSubgroup = false;
    this.clinicianExist = '';
    this.patientExist = '';
  }

  checkTime(){
    //EndTimeLimit

    var tempStartTime = new Date(this._dtoGrpTherapyMeta.StartTime);
    var getEndTimeLimit = new Date(this._dtoGrpTherapyMeta.StartTime).setHours(
      tempStartTime.getHours() + (this._dtoGrpTherapyMeta.Duration / 60)
    );
    var tempConfirmDate = new Date(getEndTimeLimit);
    this.errorMessage='Select time between ' +this.date.transform(this._dtoGrpTherapyMeta.StartTime, 'h:mm a') +' and ' +this.date.transform(tempConfirmDate, 'h:mm a');

    var subStartTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.startTime
    ).getTime();
    var subEndTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.endTime
    ).getTime();
    var startTimeNew = new Date(
      'May 26, 2016 ' + tempStartTime.toLocaleTimeString()
    ).getTime();
    var endTimeNew = new Date('May 26, 2016 ' +tempConfirmDate.toLocaleTimeString()
    ).getTime();

    if((subStartTimeNew < startTimeNew) || (subStartTimeNew > endTimeNew)){
      this.showErrorMessage=true;
      this.endtime=false;
      return
    }else{
      this.showErrorMessage=false;
      this.endtime=false;
    }
    if((subStartTimeNew >= subEndTimeNew)){
      this.endtime=true;
      this.showErrorMessage=false;
      return
    }
  }

  check() {
    //EndTimeLimit

    var tempStartTime = new Date(this._dtoGrpTherapyMeta.StartTime);
    var getEndTimeLimit = new Date(this._dtoGrpTherapyMeta.StartTime).setHours(
      tempStartTime.getHours() + (this._dtoGrpTherapyMeta.Duration / 60)
    );
    var tempConfirmDate = new Date(getEndTimeLimit);
    this.errorMessage='Select time between ' +this.date.transform(this._dtoGrpTherapyMeta.StartTime, 'h:mm a') +' and ' +this.date.transform(tempConfirmDate, 'h:mm a');

    var subStartTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.startTime
    ).getTime();
    var subEndTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.endTime
    ).getTime();
    var startTimeNew = new Date(
      'May 26, 2016 ' + tempStartTime.toLocaleTimeString()
    ).getTime();
    var endTimeNew = new Date('May 26, 2016 ' +tempConfirmDate.toLocaleTimeString()
    ).getTime();

    if((subEndTimeNew <= subStartTimeNew)){
      this.endtime=true;
      this.showErrorMessage=false;
      return
    }

    if((subEndTimeNew > endTimeNew) || (subEndTimeNew < startTimeNew)){
      this.showErrorMessage=true;
      this.endtime=false;
      return
    }else{
      this.showErrorMessage=false;
      this.endtime=false;
    }
  }

  chengeRadioValue(value){
    if(value=='iop'){
      this._dtoDayScheduleSubGroup.isIop=true;
      this._dtoDayScheduleSubGroup.isPcp=false;
      this.pcpError=false;
    }else if(value=='pcp'){
      this._dtoDayScheduleSubGroup.isIop=false;
      this._dtoDayScheduleSubGroup.isPcp=true;
      this.pcpError=false;
    }
  }
  onDeleteDaySchedule(item,dayscheduledtl)
  {
   //console.log(item);
   this.spinnerservice.show();
    this._dtoPostInactiveAppointments.PatientAccountId=dayscheduledtl.patientAccountId;
    this._dtoPostInactiveAppointments.DayScheduleSubGroupId=item.dayScheduleSubGroupId;
    this._dtoPostInactiveAppointments.DayScheduleAssignId=dayscheduledtl.dayScheduleAssignId;
    this._dtoPostInactiveAppointments.GroupTherapyId=item.groupTherapyId;
    this._dtoPostInactiveAppointments.TherapyDate=this.selecteddate;
    this._dtoPostInactiveAppointments.TheraphyId=3;
    this._dtoPostInactiveAppointments.UserId=localStorage.getItem('id');
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoPostInactiveAppointments, false);
   //  console.log(this._dtoPostInactiveAppointments);
    this.treatmentservice.postinactiveAppointments(dataToEncrypt).subscribe(
      (res: any) => {
         this.spinnerservice.hide();
        if(JSON.parse(res).id==1){this.snackbar.success(JSON.parse(res).message);}
        else if(JSON.parse(res).id==0){this.snackbar.error(JSON.parse(res).message);}
         },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onLoadFamilyIndividualTherapy()
  {
    this.spinnerservice.show();
    this.treatmentservice.getIndFamSchedulePtDetails(this.selecteddate,this.therapyId,this.selectedMsId,0).subscribe(
      (res: any) => {
       //
        this.spinnerservice.hide();
        if (res.length>0){
          this._dtoPatientInfo=res[0];
        // console.log(this._dtoPatientInfo);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }
  deleteMeetingSchedule()
  {
    this.spinnerservice.show();
    if(this._dtoPatientInfo.isPatientVisit==true)
    {
      this.spinnerservice.hide();
      this.snackbar.error('Unable to delete the scheduled appointment');
      return;
    }
    this._dtoPostInactiveAppointments.PatientAccountId=this._dtoPatientInfo.patientAccountId;
    this._dtoPostInactiveAppointments.DayScheduleAssignId=this._dtoPatientInfo.meetingScheduleTimeId;
    this._dtoPostInactiveAppointments.DayScheduleSubGroupId=this._dtoPatientInfo.meetingScheduleId;
    this._dtoPostInactiveAppointments.TheraphyId=this._dtoPatientInfo.therapyId;
    this._dtoPostInactiveAppointments.UserId=localStorage.getItem('id');
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoPostInactiveAppointments, false);
    this.treatmentservice.postinactiveAppointments(dataToEncrypt).subscribe(
      (res: any) => {
         this.spinnerservice.hide();
        if(JSON.parse(res).id==1){
          this._dtoPatientInfo.isDeleted=true;
          this.snackbar.success(JSON.parse(res).message);

        }
        else if(JSON.parse(res).id==0){this.snackbar.error(JSON.parse(res).message);}
         },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );

  }

  ondeleteMS() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.deleteMeetingSchedule();

      }
    });

  }
  onBackClick()
  {
  this.router.navigate(['/settings'], { queryParams: { tab: 4} });
  }
cliniciandropdownchanges()
{
  if(this.clinicians.length>0)
  {  this.ddlPrimarystaff=[];
      let tmplist=this.allClinicians;
      for(let i=0;i<this.clinicians.length;i++)
          {
             let obj=tmplist.find(x=>x.staffId==this.clinicians[i]);
             this.ddlPrimarystaff.push(obj)
           }
            if(this.allClinicians.length> this.ddlPrimarystaff.length)
                {
                  let _obj1 = {
                          email: null,
                          firstName:"more...",
                          lastName: null,
                          staffId:-1
                  }
          this.ddlPrimarystaff.push(_obj1);
         }
    }
    else{
      this.ddlPrimarystaff=this.allClinicians;
    }
 }
}


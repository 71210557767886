<div class="catalogue-lsit">
    <div class="top-btn-section clearfix mb-1 w-100">
        <h2 class="head-section mb-2 mt-1">{{!isEditGoals ? 'Add' :
            'Update'}} Goals</h2>
        <div class="col-md-6 ml-auto mt-2 button-position pr-0">
            <button type="button" (click)="onbacktogoallist()" class="btn primary-btn-outlined float-right mr-2"><i
                    class="fas fa-angle-left pr-2"></i>Back</button>
        </div>

    </div>
    <div class="row">
        <div class="col-md-12  p-0">
            <div class="col-md-12 p-0 created-form-section">
                <div>

                    <div class="lead-box pt-2 pb-2" *ngIf="isCreate || isEditGoals ">
                        <form (ngSubmit)="frmGoals.form.valid " id="frmGoals"
                            autocomplete="off" #frmGoals="ngForm" novalidate>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row form-group">
                                            <div class="col-md-8">
                                                
                                                <label class="control-label-new font-weight-bold">Goal</label>
                                                <div class="col-md-12 p-0">
                                                    <input  class="form-control" maxlength="80"
                                                        [(ngModel)]="_dtoGoals.goalName" appAlphabetOnly
                                                        #goalName="ngModel" name="goalName"
                                                        [ngClass]="{ 'is-invalid': frmGoals.submitted && goalName.invalid }"
                                                        required>
                                                    <mat-error *ngIf="frmGoals.submitted && goalName.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper mt-4" *ngIf="goalName.errors.required">Goal name is
                                                            required
                                                        </mat-error>
                                                    </mat-error>
                                                </div>
                                                    
                                                

                                            </div>
                                            <div class="col-md-4">
                                                <label class="control-label-new font-weight-bold">Category</label>
                                                <div class="col-md-12 p-0">  
                                                    <mat-form-field class="w-100 mt--14" floatLabel="always">

                                                    <mat-select multiple 
                                                        [(ngModel)]="_dtoGoals.goalCatagoryId" #catagoryId="ngModel"
                                                        name="catagoryId" required>                                                   
                                                        <mat-option #matOption *ngFor="let item of ddlGoalCatagory" [value]="item.goalCatagoryId">
                                                            {{item.catagoryName}}
                                                        </mat-option>
                                                    </mat-select>
                                                   
                                                </mat-form-field>
                                                <mat-error *ngIf="frmGoals.submitted && catagoryId.invalid">
                                                    <mat-error class="mat-form-field-subscript-wrapper mt-4"  *ngIf="catagoryId.errors.required">Category is
                                                        required</mat-error>
                                                </mat-error>
                                                </div>
                                                   <!-- <label class="control-label-new font-weight-bold">Category</label>
                                                    <div class="col-md-12 p-0">
                                                    <mat-select  [(ngModel)]="_dtoGoals.goalCatagoryId"
                                                        #catagoryId="ngModel" name="catagoryId"
                                                        [ngClass]="{ 'is-invalid': frmGoals.submitted && catagoryId.invalid }"
                                                        required multiple>
                                                        <mat-option *ngFor="let item of ddlGoalCatagory"
                                                            [value]="item.goalCatagoryId">
                                                            {{item.catagoryName}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error *ngIf="frmGoals.submitted && catagoryId.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper mt-4"  *ngIf="catagoryId.errors.required">Category is
                                                            required
                                                        </mat-error>
                                                    </mat-error>
                                                </div>-->

                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-md-12">
                                                <label class="control-label-new font-weight-bold">Description </label>
                                                <div class="col-md-12 p-0">
                                                    
                                                    <textarea [(ngModel)]="_dtoGoals.definition" maxlength="1000" class="custom-textarea"
                                                        #definition="ngModel" name="definition" row="4"
                                                        required [ngClass]="{ 'is-invalid': frmGoals.submitted && definition.invalid }">
                                                    </textarea>
                                                    <mat-error *ngIf="frmGoals.submitted && definition.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                                            *ngIf="definition.errors.required">
                                                            Definition is required</mat-error>
                                                        <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                                            *ngIf="definition.errors?.maxlength">
                                                            Goal Description must be in 1000 charaters</mat-error>
                                                    </mat-error>
                                               
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            
                                            
                                          <!-- <div class="col-md-4">
                                                <label class="control-label-new font-weight-bold">Specificity</label>
                                               <div class="col-md-12 p-0">
                                                    <input class="form-control" maxlength="80"
                                                        [(ngModel)]="_dtoGoals.specificity" appAlphabetOnly
                                                        #specificity="ngModel" name="specificity"
                                                        [ngClass]="{ 'is-invalid': frmGoals.submitted && specificity.invalid }"
                                                        required>
                                                    <mat-error *ngIf="frmGoals.submitted && specificity.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper mt-4"  *ngIf="specificity.errors.required">Specificity is
                                                            required
                                                        </mat-error>
                                                    </mat-error>
                                                </div>
                                                

                                            </div>--> 
                                            <div class="col-md-4">
                                                <label class="control-label-new font-weight-bold">Applicable Treatment</label>
                                                <div class="col-md-12 p-0">  
                                                    <mat-form-field class="w-100 mt--14" floatLabel="always">

                                                    <mat-select multiple 
                                                        [(ngModel)]="_dtoGoals.applicableTreatment" #TherapyId="ngModel"
                                                        name="TherapyId" required>                                                   
                                                        <mat-option #matOption *ngFor="let item of ddlTherapy" [value]="item.TherapyId" >
                                                            {{item.TherapyName}}
                                                        </mat-option>
                                                    </mat-select>
                                                   
                                                </mat-form-field>
                                                <mat-error *ngIf="frmGoals.submitted && TherapyId.invalid">
                                                    <mat-error class="mat-form-field-subscript-wrapper mt-4"  *ngIf="TherapyId.errors.required">Applicable Treatment is
                                                        required</mat-error>
                                                </mat-error>
                                                </div>
                                            </div>
                                            <div class="col-md-4 ">
                                                <label class="control-label-new font-weight-bold">Applicable Diagnosis Code</label>
                                                <mat-form-field class="w-100 mt--14" floatLabel="always">
                                                    
                                                    <mat-select multiple [(ngModel)]="_dtoGoals.diagnosisCode" (selectionChange)="onDignosisChange($event.value)"
                                                        #diagnosisCode="ngModel" name="diagnosisCode" required>
                                                        <mat-option  #matOption  *ngIf="!SelectAllDiagnosis"
                                                        [value]="0" >
                                                        Select All
                                                         </mat-option>

                                                         <mat-option  #matOption  *ngIf="SelectAllDiagnosis"
                                                         [value]="-1" > Unselect All
                                                          </mat-option>
                                                        <mat-option #matOption *ngFor="let item of ddldiagnosiscode"
                                                            [value]="item.value" 
                                                            [matOption.selected]="item.isActive" [matTooltip]="item.label">
                                                            {{item.label}}
                                                        </mat-option>

                                                    </mat-select>
                                                
                                                </mat-form-field>
                                                <mat-error *ngIf="frmGoals.submitted && diagnosisCode.invalid">
                                                    <mat-error class="mat-form-field-subscript-wrapper mt-4"  *ngIf="diagnosisCode.errors.required">Diagnosis Code
                                                        required</mat-error>
                                                </mat-error>
                                            </div>
                                            <div class="col-md-4">
                                                <label class="control-label-new font-weight-bold">Measure Type</label>
                                               <div class="col-md-12 p-0">
                                                
                                                    <select class="form-control" [(ngModel)]="_dtoGoals.measureTypeId"
                                                        #measureTypeId="ngModel" name="measureTypeId"
                                                        [ngClass]="{ 'is-invalid': frmGoals.submitted && measureTypeId.invalid }"
                                                        required (change)="OnmeasuretypeChange()">
                                                        <option *ngFor="let item of ddlMeasureType"
                                                            [value]="item.goalMeasureTypeId">
                                                            {{item.measureTypeName}}
                                                        </option>
                                                    </select>
                                                    <mat-error *ngIf="frmGoals.submitted && measureTypeId.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper mt-4"  *ngIf="measureTypeId.errors.required">Measure Type is
                                                            required
                                                        </mat-error>
                                                    </mat-error>
                                                </div>

                                            </div>
                                            </div>
                                             <!--<div class="row form-group">
                                          <div class="col-md-8">
                                                <label class="control-label-new font-weight-bold">Measure</label>
                                               <div class="col-md-12 p-0">
                                               
                                                    <input  class="form-control" maxlength="80"
                                                        [(ngModel)]="_dtoGoals.measure" appAlphabetOnly
                                                        #measure="ngModel" name="measure"
                                                        [ngClass]="{ 'is-invalid': frmGoals.submitted && measure.invalid }"
                                                        required>
                                                    <mat-error *ngIf="frmGoals.submitted && measure.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper mt-4"  *ngIf="measure.errors.required">Measure is required
                                                        </mat-error>
                                                    </mat-error>
                                                </div>

                                            </div>
                                            
                                           
                                        </div>--> 

                                    </div>
                                </div>
                                <div class="row"  >
                                    <div class="col-md-12 mt-2">
                                        <div class="control-label-new font-weight-bold">Benchmarks</div>
                                    </div>
                                </div> 
                               <div class="col-md-12 p-0">
                                    <div class="">
                                        <div class="list_wrapper" *ngFor="let com of _dtoGoals.measures;let i=index">

                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Benchmark Title</mat-label>
                                                            <input matInput maxlength="100" [(ngModel)]="com.benchmarktitle"
                                                            #benchmarktitle="ngModel" name="benchmarktitle{{i}}"
                                                            [ngClass]="{ 'is-invalid': frmGoals.submitted && benchmarktitle.invalid }"
                                                            required>
                                                            <mat-error *ngIf="frmGoals.submitted && benchmarktitle.invalid">
                                                                <mat-error  *ngIf="benchmarktitle.errors.required">Benchmark Title
                                                                    is required
                                                                </mat-error>
                                                            </mat-error>
                                                          </mat-form-field>
                                                        
                                                    </div>
                                                 </div>
                                                 <div class="col-md-4" style="margin: 15px 0px;">
                                                    <div class="form-group">
                                                        <div class="col-md-12 p-0">                                                        <!-- <mat-form-field class="w-100"> -->
                                                            <mat-label class="control-label-new">Benchmark Description</mat-label>
                                                            <textarea  class="custom-textarea" maxlength="1000" [(ngModel)]="com.benchmarkdiscription"
                                                            #benchmarkdiscription="ngModel" name="benchmarkdiscription{{i}}" row="4"
                                                            [ngClass]="{ 'is-invalid': frmGoals.submitted && benchmarkdiscription.invalid }"
                                                            required></textarea>
                                                            <div  style="padding-top: 10px;">
                                                                <mat-error *ngIf="frmGoals.submitted && benchmarkdiscription.invalid">
                                                                    <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="benchmarkdiscription.errors.required">Benchmark Description
                                                                        is required
                                                                    </mat-error>
                                                                </mat-error>
 
                                                            </div>
                                                           
                                                          <!-- </mat-form-field> -->
                                                        </div>
                                                    </div>
                                                 </div>
                                                 <div class="col-md-2">
                                                    <div class="form-group d-inline-block">
                                                        <mat-form-field class="">
                                                            <mat-label>Unit of Measure</mat-label>
                                                            <input matInput [(ngModel)]="com.benchmarkunit"
                                                            #benchmarkunit="ngModel" name="benchmarkunit{{i}}"
                                                            [ngClass]="{ 'is-invalid': frmGoals.submitted && benchmarkunit.invalid }"
                                                            required step="0" maxlength="3" percentage customMin="1">     
                                                            <mat-error *ngIf="frmGoals.submitted && benchmarkunit.invalid">
                                                                <mat-error *ngIf="benchmarkunit.errors.required">Unit of Measure
                                                                    is required
                                                                </mat-error>
                                                            </mat-error>
                                                          </mat-form-field>
                                                         
                                                    </div>
                                                    <span  *ngIf="_dtoGoals.measureTypeId == PercentageBased">%</span>
                                                    <span *ngIf="_dtoGoals.measureTypeId == Timebased">min</span>
                                                 </div>
                                                
                                                <!-- <div class="col-md-3">
                                                    <div class="form-group">
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Achievable</mat-label>
                                                            <input matInput maxlength="100" [(ngModel)]="com.benchmark"
                                                                #benchmark="ngModel" name="benchmark{{i}}"
                                                                [ngClass]="{ 'is-invalid': frmGoals.submitted && benchmark.invalid }"
                                                                required>
                                                            <mat-error *ngIf="frmGoals.submitted && benchmark.invalid">
                                                                <mat-error *ngIf="benchmark.errors.required">Achievable
                                                                    is required
                                                                </mat-error>
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">

                                                    <div class="form-group">
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Intervention</mat-label>
                                                            <mat-select [(ngModel)]="com.intervention"
                                                                #intervention="ngModel" name="intervention{{i}}"
                                                                [ngClass]="{ 'is-invalid': frmGoals.submitted && intervention.invalid }"
                                                                required>
                                                                <mat-option *ngFor="let item of lstWeek"
                                                                    [value]="item.weekId">
                                                                    {{item.weeek}}
                                                                </mat-option>
                                                            </mat-select>
                                                            <mat-error
                                                                *ngIf="frmGoals.submitted && intervention.invalid">
                                                                <mat-error *ngIf="intervention.errors.required">
                                                                    Intervention is required
                                                                </mat-error>

                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                </div> -->


                                                <div class="col mt-2">

                                                    <button (click)="addMeasures()"
                                                        class="btn btn-primary list_add_button btn-theme mr-1"
                                                        type="button">+</button>
                                                    <button *ngIf="i > 0" (click)="removeMeasures(i)"
                                                        class="btn btn-danger list_add_button " type="button">-</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               <!--<div class="row" *ngIf="_dtoGoals.measureTypeId == KPIprogresslinked || _dtoGoals.measureTypeId == Qualitative " >
                                    <div class="col-md-12 mt-2">
                                        <div class="control-label-new font-weight-bold">Benchmarks</div>
                                    </div>
                                </div>--> 
                                <!--<div class="col-md-12 p-0" *ngIf="_dtoGoals.measureTypeId == Qualitative ">
                                    <div class="">
                                        <div class="list_wrapper" *ngFor="let com of _dtoGoals.measures;let i=index"  >
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Interventional KPIs</mat-label>
                                                            <input matInput maxlength="100" [(ngModel)]="com.interventionalKPIs"
                                                                #interventionalKPIs="ngModel" name="interventionalKPIs{{i}}"
                                                                [ngClass]="{ 'is-invalid': frmGoals.submitted && interventionalKPIs.invalid }"
                                                                required>
                                                            <mat-error *ngIf="frmGoals.submitted && interventionalKPIs.invalid">
                                                                <mat-error *ngIf="interventionalKPIs.errors.required">interventional KPI
                                                                    is required
                                                                </mat-error>
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    </div>
                                               
                                                <div class="col mt-2">

                                                    <button (click)="addMeasures()"
                                                        class="btn btn-primary list_add_button btn-theme mr-1"
                                                        type="button">+</button>
                                                    <button *ngIf="i > 0" (click)="removeMeasures(i)"
                                                        class="btn btn-danger list_add_button " type="button">-</button>
                                                </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>-->
                                            <!--<div class="col-md-12 p-0" *ngIf="_dtoGoals.measureTypeId == KPIprogresslinked">
                                                <div class="" >
                                                    <div class="list_wrapper" *ngFor="let com of _dtoGoals.measures;let i=index"  >
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <mat-form-field class="w-100">
                                                                        <mat-label>Interventional KPIs</mat-label>
                                                                        <input matInput maxlength="100" [(ngModel)]="com.interventionalKPIs"
                                                                            #interventionalKPIs="ngModel" name="interventionalKPIs{{i}}"
                                                                            [ngClass]="{ 'is-invalid': frmGoals.submitted && interventionalKPIs.invalid }"
                                                                            required>
                                                                        <mat-error *ngIf="frmGoals.submitted && interventionalKPIs.invalid">
                                                                            <mat-error *ngIf="interventionalKPIs.errors.required">interventional KPI
                                                                                is required
                                                                            </mat-error>
                                                                        </mat-error>
                                                                    </mat-form-field>
                                                                </div>
                                                                </div>
                                                           
                                                            <div class="col mt-2">
            
                                                                <button (click)="addMeasures()"
                                                                    class="btn btn-primary list_add_button btn-theme mr-1"
                                                                    type="button">+</button>
                                                                <button *ngIf="i > 0" (click)="removeMeasures(i)"
                                                                    class="btn btn-danger list_add_button " type="button">-</button>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        </div>
                                                        </div>-->
    
                            </div>
                          
                            <div class="col-md-12 text-right mt-3">
                                <button type="submit" mat-stroked-button title="{{!isEditGoals ? 'Save' : 'Update'}}"
                                    class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="addorUpdateGoals(frmGoals)" [ngClass]="{'disabled': onpostgoal }" >{{!isEditGoals ? 'Save'
                                    :
                                    'Update'}}</button>
                                <button mat-stroked-button class="btn btn-cancel primary-btn-m h-40" type="button"
                                    title="Cancel" (click)="resetfrmGoals(frmGoals)">Cancel</button>

                            </div>

                        </form>
                    </div>

                </div>

            </div>

        </div>


    </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { PatientService } from 'src/app/patient/patient.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import * as moment from 'moment';

@Component({
  selector: 'app-patient-listview',
  templateUrl: './patient-listview.component.html',
  styleUrls: ['./patient-listview.component.scss']
})
export class PatientListviewComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  lstPatient: any = [];
  DecrptedlstPatient: any = [];
  subjectTag = "";
  idTag: any;
  parentemail: any;
  statusintake = constantVariables.status.forIntake;
  statusmeetingScheduled = constantVariables.status.meetingScheduled;
  statusintakeScheduled = constantVariables.status.intakeScheduled;
  statusintakeDeclined = constantVariables.status.intakeDeclined;
  statusintakeAccepted = constantVariables.status.intakeAccepted;
  statusintakePacket = constantVariables.status.intakePacket;
  statusmeetingReSchedule = constantVariables.status.meetingReSchedule;
  statusrequestcancel = constantVariables.status.requestcancel;
  statusPsyReschedule = constantVariables.status.psytherapyReschedule;
  ShowActivity: boolean= false;
  leadhistory: any[];
  ShowPatientStatus: boolean= false;
  ddlPsMaster:any;
  _dtoupdatePatientStatusDtls:any;
  patientStatusId;
  selectedScheduleModality:any="M";
  constructor(public patientservice: PatientService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, private route: ActivatedRoute,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService) { }

  ngOnInit(): void {
      if(localStorage.getItem('roleId')=="4")
      {
        this.router.navigate(['/patient-breifcase']);
      }
      else if(localStorage.getItem('roleId')!="4")
        {
        this.getpatientlistView();
        }
   
  }
  onPageChange($event) {
    this.lstPatient = this.DecrptedlstPatient.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  getpatientlistView() {
    this.spinnerservice.show();
    this.patientservice.getPatientList().subscribe(
      (res: any) => {
        this.spinnerservice.hide();
          this.DecrptedlstPatient = this.commonService.decrypdata(res.objInputString);
       console.log(this.DecrptedlstPatient);
        this.lstPatient = this.DecrptedlstPatient.slice(0, 5);
        this.dataSource = new MatTableDataSource(this.DecrptedlstPatient);
        this.dataSource.paginator = this.paginator;
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onIntakeSchedule(item) {
    localStorage.setItem("immediateActionId", item.ImmediateActionId);
    let obj = {
      name: item.FirstName,
      patientAccountId: item.PatientAccountId,
      pageid: 1,
      age:moment(item.Dob, "DD-MM-YYYY").diff(moment(), 'years'),
      gender: item.GenderType,
      location:item.sfccMasterId
    }
    this.router.navigate(['/intake-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(obj) } });
  }
  // onPrepareIntake(type, data) {
  //   if (type == 1) {
  //     this.router.navigate(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1 } });
  //   } else if (type == 2) {
  //     this.router.navigate(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1 } });
  //   } else if (type == 3) {
  //     this.router.navigate(['/intake-survey'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1 } });
  //   } else if (type == 4) {
  //     this.router.navigate(['/intake-consent'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1 } });
  //   }
  // }
  onPrepareIntake(type, data) {
    if (type == 1) {
      this.router.navigate(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1,patientinfoid:this.commonService.FrontEndEncryption(data.PatientInfoId),isPatientAccount:this.commonService.FrontEndEncryption(1),cftid:this.commonService.FrontEndEncryption(1),patientName : this.commonService.FrontEndEncryption(data.ParentName) } });
    } else if (type == 2) {
      this.router.navigate(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1,patientinfoid:this.commonService.FrontEndEncryption(data.PatientInfoId),isPatientAccount:this.commonService.FrontEndEncryption(1),cftid:this.commonService.FrontEndEncryption(1),patientName : this.commonService.FrontEndEncryption(data.ParentName) } });
    } else if (type == 3) {
      this.router.navigate(['/intake-survey'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1,isPatientAccount:this.commonService.FrontEndEncryption(1),patientName : this.commonService.FrontEndEncryption(data.ParentName) } });
    } else if (type == 4) {
      this.router.navigate(['/intake-consent'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1,isPatientAccount:this.commonService.FrontEndEncryption(1),patientName : this.commonService.FrontEndEncryption(data.ParentName) } });
    }
  }
  onAcceptReschdule(data) {
    this.spinnerservice.show();
    this.patientservice.onReScheduleAccept(data.MeetingScheduleId).subscribe(
      res => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(res.message);
          this.getpatientlistView();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onDeclineReschdule(data) {
    this.spinnerservice.show();
    this.patientservice.onReScheduleDeny(data.MeetingScheduleId).subscribe(
      res => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(res.message);
          this.getpatientlistView();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onAcceptCancelIntake(data) {
    this.spinnerservice.show();
    let obj = {
      MeetingScheduleId: data.MeetingScheduleId,
      Type: 3
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.patientservice.onAcceptCancelIntake(dataToEncrypt).subscribe(
      (res: any) => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getpatientlistView();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onDeclineCancelIntake(data) {
    this.spinnerservice.show();
    let obj = {
      MeetingScheduleId: data.MeetingScheduleId,
      Type: 2
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.patientservice.onDeclineCancelIntake(dataToEncrypt).subscribe(
      (res: any) => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getpatientlistView();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onReturnLead(obj) {
    this.global.clearEmaillist();
    //this.subjectTag = "Lead";
    this.idTag = obj.PatientInfoId;
    this.parentemail = obj.Email;
    localStorage.setItem('patientId', obj.PatientInfoId);
    localStorage.setItem('parentname', obj.ParentList[0].FirstName);
    this.global.onOpenEmailPopup(obj.ParentList[0].Email);
  }
  OnActivityClick(Patient){
    //console.log(Patient)
    this.ShowActivity = true;
   // this.leadhistory=[];
    this.getLeadActivity(Patient.PatientInfoId)
  }
  onCloseActivitylog(){
    this.ShowActivity = false;
  }
  getLeadActivity(Id) {
    this.leadhistory=[]
    this.spinnerservice.show()
    this.patientservice.getLeadHistory(Id).subscribe(
      (res: any) => {
        if (res) {
          this.leadhistory = res;
          this.spinnerservice.hide()
        //  console.log(this.leadhistory)
        }
        this.spinnerservice.hide()
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  // onScheduleModality(item) {
  //     this.router.navigate(['/schedule-modality'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId),treatmentPlanDate:item.TreatmentPlanDate } });
  // }
  onRedirectTo(item,selectedType)
  {
    if(selectedType=="1")
    {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/patient-breifcase'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientInfoId)}}));
    window.open(url, '_blank');
    }
    else if(selectedType=="2")
    {
      this.router.navigate(['/schedule-modality'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId),treatmentPlanDate:item.TreatmentPlanDate } });
    }
    if(selectedType=="3")
    {
     
    const url = this.router.serializeUrl(this.router.createUrlTree(['/treatmentplan'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId)   }}));
     window.open(url, '_blank');
    }
  }
  getPatientStatusMaster()
  {
    this.spinnerservice.show();
    this.patientservice.getPatientStatusMaster().subscribe(
      res => {
        if (res != null) {
          this.ddlPsMaster = this.commonService.decrypdata(res.objInputString);
          this.ddlPsMaster =this.ddlPsMaster.filter(x=>x.PatientStatusId!=1);
         this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }

  onShowStatusPopup(patient)
  {
 this.getPatientStatusMaster();
this._dtoupdatePatientStatusDtls=patient;
this.patientStatusId=patient.PatientStatusId;
this.ShowPatientStatus=true;
  }
  onCloseStatusPopup(){
    this.ShowPatientStatus=false;
    this.patientStatusId=0;
  }
  patientStatusradioChange(event){
    this._dtoupdatePatientStatusDtls.PatientStatusId=event.value;
  }
  updatePatientStatus(){
    this.spinnerservice.show();
    if( this._dtoupdatePatientStatusDtls.PatientStatusId==1 || this._dtoupdatePatientStatusDtls.PatientStatusId==0)
    {
    this.spinnerservice.hide();
    this.snackbar.error('Select any one  patient status');
    return;
  }
  this.ShowPatientStatus=false;
  const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoupdatePatientStatusDtls, false);
  this.patientservice.postPatientAccountStatus(dataToEncrypt).subscribe(res => {
      if (res) {
      this.patientStatusId=1;
      this.spinnerservice.hide();
       this.getpatientlistView();
      this.snackbar.success(res);
        }
    },
    err => {
     this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}
OnDischargeListView(){
  this.router.navigate(['/discharged-patient-list'])
}

onScheduleModalityChange(val)
{
  this.selectedScheduleModality=val;
}
onSelectedModality(item)
{
  if(this.selectedScheduleModality=="M")
  {
    // item.ImmediateActionId != statusPsyReschedule
    this.router.navigate(['/schedule-modality'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId),treatmentPlanDate:item.TreatmentPlanDate } });
    
  }
  else if(this.selectedScheduleModality=="S")
  {
    let obj = {
      name: item.FirstName,
      patientAccountId: item.PatientAccountId,
      pageid: 10,
      age:moment().diff(moment(item.Dob, "DD-MM-YYYY"), 'years'),
      gender: item.GenderType,
      lastName : item.LastName,
      location:item.sfccMasterId
    }
    this.router.navigate(['/phone-schoolmeet-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(obj) } });
  }
  else if(this.selectedScheduleModality=="P")
  {
    let obj1 = {
      name: item.FirstName,
      patientAccountId: item.PatientAccountId,
      pageid: 11,
      age:moment().diff(moment(item.Dob, "DD-MM-YYYY"), 'years'),
      gender: item.GenderType,
      lastName : item.LastName,
      location:item.sfccMasterId
    }
    this.router.navigate(['/phone-schoolmeet-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(obj1) } });
  }
}
onPatientPayment(item)
{
this.router.navigate(['/patient-payment-details'], { queryParams: { id:this.commonService.FrontEndEncryption(item.PatientAccountId),info:this.commonService.FrontEndEncryption(item.PatientInfoId) } });
}

}

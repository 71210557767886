<div class="lead-section">
  <div class="top-btn-section clearfix">
    <h2 class="head-section mb-2 mt-0">BLOCK TIME</h2>
  </div>
  <div class="lead-box">
    <form (ngSubmit)="blocktimeform.form.valid" id="blocktimeform" autocomplete="off"
    #blocktimeform="ngForm" novalidate>
      <div class="col-md-12 p-0 mt-3">
        <div class="row">
          <div class="col-md-4">
            <!-- <label class="blocktime-label">PURPOSE OF BLOCKING</label> -->
            <!-- <mat-form-field class="w-100">
              <mat-label>PURPOSE OF BLOCKING</mat-label>
              <mat-select [(ngModel)]="dtoblocktime.purposeMasterId" #purposeMasterId="ngModel" 
              name="purposeMasterId" 
              [ngClass]="{ 'is-invalid': blocktimeform.submitted && purposeMasterId.invalid }"required>
            
                <mat-option *ngFor="let item of dtopurpose" [value]="item.purposeMasterId">
                  {{item.purposeName}}
                </mat-option>
              </mat-select>
              
              
            </mat-form-field> -->
            <ng-select autocomplete="off" class="block-type-design" 
            [items]="dtopurpose" placeholder="PURPOSE OF BLOCKING" appAlphabetOnly bindLabel="purposeName"
            bindValue="purposeName" 
            [(ngModel)]="dtoblocktime.purposeName" addTagText="Add purpose"
            [addTag]="AddNewItemTypeIdTag" (change)="postPurposeName(dtoblocktime.purposeName)"
            #purposeName="ngModel" name="purposeName" [ngClass]="{ 'is-invalid': blocktimeform.submitted && purposeName.invalid }"required
            >
        </ng-select>
        <!-- <div *ngIf="blocktimeform.submitted && purposeName?.invalid">
          <div class="error-message"  *ngIf="purposeName?.errors?.required">
            Purpose of blocking is required</div>
          </div> -->
          <mat-error *ngIf="blocktimeform.submitted && purposeName.invalid">
            <mat-error class="text-xs" *ngIf="purposeName.errors.required">
              Purpose of blocking is required</mat-error>
        </mat-error>  
          </div>
          <div class="col-md-4" *ngIf="isShowColor==true">
            <!-- <label class="blocktime-label">COLOUR</label> -->
            <mat-form-field class="w-100">
              <mat-label>COLOR</mat-label>
              <mat-select [(ngModel)]="dtoblocktime.colorCodeMasterId" #colorCodemasterid="ngModel" 
              name="colorCodemasterid" 
              [ngClass]="{ 'is-invalid': blocktimeform.submitted && colorCodemasterid.invalid }">
                <mat-option *ngFor="let item of dtocolorcode" [value]="item.colorCodemasterid">
                  {{item.colorName}}
                  <span class="dot-transparent backgroud mr-2" [ngStyle]="{'background-color': item.colorCode}"></span>

                </mat-option>
              </mat-select>
              <!-- <div *ngIf="blocktimeform.submitted && colorCodemasterid?.invalid">
                <div class="error-message"  *ngIf="colorCodemasterid?.errors?.required">
                  Color is required</div>
                </div> -->
                <!-- <mat-error *ngIf="blocktimeform.submitted && colorCodemasterid.invalid">
                  <mat-error class="text-xs" *ngIf="colorCodemasterid.errors.required">
                    Color is required</mat-error>
              </mat-error>   -->
            </mat-form-field>
          </div>
          <div class="col-md-4">
           

            <mat-form-field>
              
              <mat-label>COLOR</mat-label>
         <span [ngStyle]="{'background-color': dtoblocktime.newcolorcode}">
              <input matInput #colorname="ngModel" 
               [ngxMatColorPicker]="picker"  [(ngModel)]="dtoblocktime.newcolorcode" name="colorname" 
               [ngClass]="{ 'is-invalid': blocktimeform.submitted && colorname.invalid }"  required>
              </span>
              <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
              <ngx-mat-color-picker #picker [touchUi]="touchUi" [color]="color"></ngx-mat-color-picker>
              
          </mat-form-field>
       
          </div> 

        

          <div class="col-md-12 text-right">  
            <button
              type="submit"
              mat-stroked-button
              title="Save" 
              class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="Onsave(blocktimeform)"
            >
            {{!isEditBlocktime ? 'Save' :
            'Update'}}
            </button>
            <button (click)="resetblocktimeform(blocktimeform)" type="button" mat-stroked-button title="Cancel" class="btn btn-cancel primary-btn-m h-40">
              Cancel
            </button>
            <!-- <button (click)="resetphonebookform(phbookform)" type="button" mat-stroked-button  *ngIf="roleAccess.isEdit || roleAccess.isDelete"
            class="btn btn-cancel primary-btn-m h-40">Cancel</button> -->
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
 <!--Activity popup-->
 <div class="Blocktime-popup">
  <div class="modal"  tabindex="-1" role="dialog" [style.display]=" ShowActivity ? 'block' : 'none'">
     <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
        
           <div class="modal-body">
            
                <div class="row">
                  <div class="col-md-6 mt-2">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="ENTER THE PURPOSE"
                       appAlphabetOnly maxlength="50" required>
                      <!-- <mat-error *ngIf="taskfrm.submitted && taskName.invalid">
                        <mat-error *ngIf="taskName.errors.required">Task name is required</mat-error>
                      </mat-error> -->
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                  <label class="blocktime-label">LIBRARY</label>
                  <table class="mt-3">
                         <tr>
                            <td width="77" align="center" bgcolor="	#ff4000" class="dot-transparent mr-2"></td>
                            <td width="77" align="center" bgcolor="	#bfff00" class="dot-transparent mr-2"></td>
                            <td width="77" align="center" bgcolor="#00ff40" class="dot-transparent mr-2"> </td>
                            <td width="77" align="center" bgcolor="#00ffff" class="dot-transparent mr-2"></td>
                            <td width="77" align="center" bgcolor="#0040ff" class="dot-transparent mr-2"></td>
                            <td width="77" align="center" bgcolor="#0040ff" class="dot-transparent mr-2"></td>
                            <td width="77" align="center" bgcolor=" #bf00ff" class="dot-transparent mr-2"></td>
                            <td width="77" align="center" bgcolor=" #ff00ff" class="dot-transparent mr-2"></td>
                            
                          </tr>
                 
                    </table>
             
                </div>
                <div class="col-md-12 text-center buttons mt-5">
                  <button
                    type="submit"
                    mat-stroked-button
                    title="Save"
                    class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2"
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    mat-stroked-button
                    title="Cancel"
                    class="btn btn-cancel primary-btn-m h-40"
                  >
                    Cancel
                  </button>
                </div>
              </div>
        </div>
        
     </div>
  
     </div>
  </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-12">
        <div class="row">         -->
       <!--For looping the options-->
      <!-- <div class="role-permission col-md-12" *ngFor="let item of dtoblocktime"> -->
        <!-- <div class="role-permission col-md-12" > -->

        <!-- <div class="permission-container"  [ngClass]="item.isActive?'active':''">  -->
          <!-- <div class="permission-container" >  -->

          <!-- <div  class="permis-heading" (click)="item.isActive=!item.isActive">{{item.moduleName}} <i class="fas fa-chevron-down ml-2" ></i></div> -->
          <!-- <div class="expand-table" > -->
            <!-- <div class="table-reponsive pt-2">
              <table class="table table-custom text-center table-history"> 
                <thead>
                  <tr>
                    <th>Block TimeId</th>
                    <th>BlockTimeMasterId</th>
                    <th>ColorCodeMasterId</th>
                    <th>Active</th>
                    <th>Actions</th>
                </tr>
                </thead>
    
              <tbody> -->
        <!-- <tr *ngFor = "let blocktime of dtoblocktime">
          <td>{{blocktime.blockTimeMasterId}} </td>
          <td>{{blocktime.ColorCodeMasterId}} </td>
          <td>{{blocktime.active}}</td>
         
        </tr> -->
<!-- </tbody> -->
<div class="top-btn-section clearfix" >
  <h2 class="head-section mb-2 mt-0">Block Time List</h2>

</div>
<div class="col-md-12 table-custom p-0 mb-2" >
  <div class="table-responsive">
    <mat-table class="lessons-table mat-elevation-z8 w-100" [dataSource]="dataSource" >

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> S.No </mat-header-cell>
        <mat-cell *matCellDef="let blocktime;let i = index;">{{i+1}}</mat-cell>
      </ng-container>
      <!-- <ng-container matColumnDef="blockTimeId">
        <mat-header-cell *matHeaderCellDef>BlockTimeId</mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let blocktime">{{blocktime.blockTimeId}}
        </mat-cell>
      </ng-container> -->
      <ng-container matColumnDef="purposeName">
        <mat-header-cell *matHeaderCellDef>Purpose Name</mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let blocktime">{{blocktime.dtoPurposeMaster.purposeName}}
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="colorName">
        <mat-header-cell *matHeaderCellDef>Color Name</mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let blocktime">{{blocktime.dtoColorCodeMaster.colorName}}
          <span class="dot-transparentBlockTime backgroud mr-2" [ngStyle]="{'background-color': blocktime.dtoColorCodeMaster.colorCode}"></span>
        </mat-cell>
      </ng-container> -->
      <ng-container matColumnDef="colorName">
        <mat-header-cell *matHeaderCellDef>Color Name</mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let blocktime">{{blocktime.colorCode}}&nbsp;&nbsp;&nbsp;
          <span class="dot-transparentBlockTime backgroud mr-2" [ngStyle]="{'background-color': blocktime.colorCode}"></span>
        </mat-cell>
      </ng-container>

      
      <!-- <ng-container matColumnDef="colorCode">
        <mat-header-cell *matHeaderCellDef>ColorCode</mat-header-cell>
        <mat-cell class="description-cell" *matCellDef="let blocktime">{{blocktime.colorCode}}
        </mat-cell>
      </ng-container> -->
      <ng-container matColumnDef="actions">
        <!-- <mat-header-cell *matHeaderCellDef>{{  (roleAccess.isEdit || roleAccess.isDelete) ? Actions : ''  }}</mat-header-cell> -->
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>

        <mat-cell class="duration-cell" *matCellDef="let blocktime">
          <!-- <button type="button" mat-icon-button matTooltip="Edit" role="button" class="cursor-pointer" type="button" (click)="oneditPhonebook(customer)"
            color="primary" *ngIf="roleAccess.isEdit"> -->
            <button type="button" mat-icon-button matTooltip="Edit" role="button" class="cursor-pointer" type="button" (click)="oneditBlocktime(blocktime)"
            color="primary" >

            <img src="../../../assets/images/editblue.png">
          </button>
          <!-- <button type="button" mat-icon-button matTooltip="Delete" type="button" role="button"  class="cursor-pointer"
            (click)="ondeletePhonebook(customer)" color="warn"  *ngIf="roleAccess.isDelete"> -->
            <button type="button" mat-icon-button matTooltip="Delete" type="button" role="button"  class="cursor-pointer"
            (click)="ondeleteBlocktime(blocktime)" color="warn" >
            <img src="../../../assets/images/deletered.png">
          </button>
        </mat-cell>
        
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let blocktime; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="20"[pageSizeOptions]="[ 10, 20, 50, 75, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>

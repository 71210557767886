export class RoelAccess {
    isCreate :boolean;
      isView : boolean;
      isEdit :boolean;
      isDelete :boolean;
      isApprove:boolean;
      isSign:boolean;
      isInternSign:boolean;
      isUnlicensed:boolean;
      isUnlockProgress:boolean;
}

export class MenuList{
    Scheduler:boolean;
    Leads:boolean;
    PatientAccount:boolean;
    Briefcase:boolean;
    Profile:boolean;
    Notes:boolean;
    Task:boolean;
    Goals:boolean;
    Template:boolean;
    Billing:boolean;
    BillingMasters:boolean;
    Settings:boolean;
    StaffManagement:boolean;
    ScheduleIntake:boolean;
    PaymentDetails:boolean;
    TreatmentPlan:boolean;
    GenerateIntake:boolean;
    PainAssessment	:boolean;
    NutritionalQuestion:boolean;
    PrepareCHMA :boolean;
    SuicidalRiskAssessment:boolean;
    TreatmentHistory:boolean;
    IncidentReport:boolean;
    DischargeSummary:boolean;
    ProgressNoteFivepointRating:boolean;
    Documents:boolean;
    HorizondalMenu:boolean;
    Dashboard:boolean;
    Authorization:boolean;
    Audit:boolean;
}
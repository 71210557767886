import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { cloneDeep } from "lodash";
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-discharge-summary-list',
  templateUrl: './discharge-summary-list.component.html',
  styleUrls: ['./discharge-summary-list.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class DischargeSummaryListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns1: string[] = ['name','dischar',  'Clinician','Discharge','DOB',  'actions'];
  DischargeSumDatasource:MatTableDataSource<any>;
  DischargeSummaryList: any;
  tempDischargeSummaryList: any;
  PaginatorVariable: number=20;
  PageIndex: number;
  Id: string;
  Isedit: any;
  searchDischargettxt:string="";
  ddlstafflist: any;
  SelectedClinician:any=1;
  SelectedDischargeType:any=0;
  SelectedDate:any;
  today = new Date();
  date = moment(new Date());
  monthStartDate:any;
  monthEndDate:any;
  roleAccess: any;
  constructor(private router:Router,public treatmentservice: TreatmentService, 
    public spinnerservice: NgxSpinnerService, public commonService:CommonService,
    private snackbar: SnackBarService,public datepipe: DatePipe
    ,public roleaccessservice:PageAccessService
    ) { }

   ngOnInit(): void {
    
    const pageid = constantVariables.pagelists.DischargeSummary;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.Id = localStorage.getItem('id') 
    this.router.navigate(['/clinician'],{queryParams:{menu:5}});
    if(!!(localStorage.getItem("DisSumListpaginator"))){
      this.PaginatorVariable =+(localStorage.getItem("DisSumListpaginator"));
    }
    if(!!(localStorage.getItem("DisSumListpageIndex"))){
      this.PageIndex =+(localStorage.getItem("DisSumListpageIndex"));
    }
    if(!!(localStorage.getItem("DisSumSelectedClinician"))){
      this.SelectedClinician =(localStorage.getItem("DisSumSelectedClinician"));
    }
    if(!!(localStorage.getItem("DisSumSelectedDischargeType"))){
      this.SelectedDischargeType =(localStorage.getItem("DisSumSelectedDischargeType"));
    }
    if(!!(localStorage.getItem("DisSumSelectedDate"))){
      this.SelectedDate =(localStorage.getItem("DisSumSelectedDate"));
    }
    this.getDischargeSummaryList();
    this.GetStaffList();
  }
  OnStartDischarge(){
    this.router.navigate(['/discharge-summary']);
  }
  getDischargeSummaryList(){
    this.spinnerservice.show()
    this.treatmentservice.getDischargeSummaryList().subscribe((res:any)=>{
      this.spinnerservice.hide()
     // console.log(res)
      this.DischargeSummaryList = res;
      this.tempDischargeSummaryList = cloneDeep(res);
      if(Number(localStorage.getItem("roleId"))==5){
        this.SelectedClinician = this.Id;
      }
      if(!!(localStorage.getItem("DisSumSelectedClinician")) ){
        this.SelectedClinician =(localStorage.getItem("DisSumSelectedClinician"));
      }
      if(this.SelectedClinician!="1" ){
      this.DischargeSummaryList= this.tempDischargeSummaryList.filter(x=>x.clinicianId == +this.SelectedClinician) 
      }
      
      this.DischargeSumDatasource = new MatTableDataSource(this.DischargeSummaryList);
      this.DischargeSumDatasource.paginator = this.paginator;
      this.paginator.pageSize = this.PaginatorVariable;
      this.paginator.pageIndex= this.PageIndex;
          this.DischargeSumDatasource.sort = this.sort;
     // console.log(this.tempIncidentReportList)
    },
    err => {
      this.spinnerservice.hide();
  
    })
  }
  OnViewDischargeSummary(item){
if(item.clinicianId==this.Id){
  this.Isedit=1;
}
else{
  this.Isedit=2;
}
  this.router.navigate(['/discharge-summary'],{queryParams:{id:this.commonService.FrontEndEncryption(item.dischargeSummaryId),isedit:this.commonService.FrontEndEncryption(this.Isedit),clinician:this.commonService.FrontEndEncryption(item.clinicianId)}});
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
 // this.IncReportDatasource.filter = JSON.stringify(filterValue);
 this.DischargeSumDatasource.filter = filterValue.trim().toLowerCase();
 
}
GetStaffList(){
  this.spinnerservice.show()
  this.treatmentservice.getCLinicalStaffList().subscribe(res=>{
    this.spinnerservice.hide()
    this.ddlstafflist = res.filter(x=>x.userId==this.Id)
  //  this.ddlstafflist.
   // this.ddlReportedBy = res
   // this.DischargeSUmmaryInfo.clinicianId = +this.Id
  },
  err => {
    this.spinnerservice.hide();

  })
}

OnFilter(){
  // localStorage.setItem('DisSumSelectedClinician',this.SelectedClinician)
  // localStorage.setItem('DisSumSelectedDischargeType',this.SelectedDischargeType)
  // localStorage.setItem('DisSumSelectedDate',this.SelectedDate)
  if(this.SelectedDate != undefined && this.SelectedDate != "" && this.SelectedDate != "undefined")
  {
    this.monthStartDate = this.datepipe.transform(new Date(this.SelectedDate.getFullYear(), this.SelectedDate.getMonth(), 1,0),"yyyy-MM-dd");
    this.monthEndDate = this.datepipe.transform(new Date(this.SelectedDate.getFullYear(), this.SelectedDate.getMonth()+1,0),"yyyy-MM-dd");
  }
  console.log(this.SelectedClinician!=0 && this.SelectedDischargeType!=0 && this.SelectedDate!="undefined" && this.SelectedDate!="" && this.SelectedDate==undefined)
  //Clinician filter alone
  if(this.SelectedClinician!=0 && this.SelectedClinician!=1 && this.SelectedDischargeType==0 && (this.SelectedDate==undefined || this.SelectedDate=="" || this.SelectedDate=="undefined")){
    this.DischargeSummaryList= this.tempDischargeSummaryList.filter(x=>x.clinicianId == +this.SelectedClinician) 
  }
  if(this.SelectedClinician!=0 && this.SelectedClinician==1 && this.SelectedDischargeType==0&& (this.SelectedDate==undefined || this.SelectedDate=="" || this.SelectedDate=="undefined")){
      this.DischargeSummaryList= this.tempDischargeSummaryList
  }
  //Discharge Type Filter alone
  if(this.SelectedDischargeType!=0 &&  this.SelectedClinician==0 && (this.SelectedDate==undefined || this.SelectedDate=="" || this.SelectedDate=="undefined" || this.SelectedDate==undefined)){
    if(this.SelectedDischargeType==1)
      {
         this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDischarge==true)
     }
        if(this.SelectedDischargeType==2)
        {
          this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDropOut==true)
        }
  }
  //Date filter alone 
  if((this.SelectedDate!=undefined || this.SelectedDate!="" || this.SelectedDate=="undefined")&& this.SelectedDischargeType==0 && this.SelectedClinician==0)
  {
     this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>(this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) >= this.monthStartDate && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) <= this.monthEndDate)
    
  }
//clinician and Discharge Type filter
  if(this.SelectedClinician!=0 && this.SelectedDischargeType!=0 && (this.SelectedDate==undefined || this.SelectedDate=="" || this.SelectedDate=="undefined")){
    if(this.SelectedDischargeType==1 && this.SelectedClinician!=1)
    {
       this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDischarge==true && x.clinicianId == +this.SelectedClinician)
   }
   if(this.SelectedDischargeType==2 && this.SelectedClinician!=1)
   {
      this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDropOut==true && x.clinicianId == +this.SelectedClinician)
   }
    if(this.SelectedDischargeType==1 && this.SelectedClinician==1)
    {
        this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDischarge==true )
    }
    if(this.SelectedDischargeType==2 && this.SelectedClinician==1)
    {
      this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDropOut==true)
    }
  }
//Clinician and date filter
if(this.SelectedClinician!=0 && this.SelectedDischargeType==0 && this.SelectedDate!=undefined && this.SelectedDate!="" &&  this.SelectedDate!="undefined"){
  if( this.SelectedClinician!=1)
  {
     this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>(this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) >= this.monthStartDate && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) <= this.monthEndDate && x.clinicianId == +this.SelectedClinician)
 }
  if( this.SelectedClinician==1)
  {
    this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>(this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) >= this.monthStartDate && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) <= this.monthEndDate)
  }
}
//discharge type and date filter
if(this.SelectedClinician==0 && this.SelectedDischargeType!=0  && this.SelectedDate!=undefined && this.SelectedDate!="" &&  this.SelectedDate!="undefined"){
  if(this.SelectedDischargeType==1 )
  {
     this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDischarge==true && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) >= this.monthStartDate && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) <= this.monthEndDate)
 }
 if(this.SelectedDischargeType==2)
 {
    this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDropOut==true && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) >= this.monthStartDate && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) <= this.monthEndDate)
 }
}
//All three filter
if(this.SelectedClinician!=0 && this.SelectedDischargeType!=0 && this.SelectedDate!="undefined" && this.SelectedDate!="" && this.SelectedDate!=undefined){
  if(this.SelectedDischargeType==1 && this.SelectedClinician!=1)
  {
     this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDischarge==true && x.clinicianId == +this.SelectedClinician && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) >= this.monthStartDate && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) <= this.monthEndDate)
 }
 if(this.SelectedDischargeType==2 && this.SelectedClinician!=1)
 {
    this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDropOut==true && x.clinicianId == +this.SelectedClinician && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) >= this.monthStartDate && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) <= this.monthEndDate)
 }
  if(this.SelectedDischargeType==1 && this.SelectedClinician==1)
  {
      this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDischarge==true && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) >= this.monthStartDate && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) <= this.monthEndDate)
  }
  if(this.SelectedDischargeType==2 && this.SelectedClinician==1)
  {
    this.DischargeSummaryList = this.tempDischargeSummaryList.filter(x=>x.isDropOut==true && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) >= this.monthStartDate && (this.datepipe.transform(x.dsDate,"yyyy-MM-dd")) <= this.monthEndDate)
  }
}

if(this.SelectedDischargeType==0 && this.SelectedClinician==0 && (this.SelectedDate=="undefined" || this.SelectedDate=="" || this.SelectedDate==undefined))
{ 
  this.DischargeSummaryList= this.tempDischargeSummaryList
}

this.DischargeSumDatasource = new MatTableDataSource(this.DischargeSummaryList);
this.DischargeSumDatasource.paginator = this.paginator;
    this.DischargeSumDatasource.sort = this.sort;
}

onPageChange(event){
  console.log(event.pageSize)
  localStorage.setItem('DisSumListpaginator',event.pageSize)
  localStorage.setItem('DisSumListpageIndex',event.pageIndex)
}
setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
  debugger
  const ctrlValue = this.date;
  ctrlValue.month(normalizedMonthAndYear.month());
  ctrlValue.year(normalizedMonthAndYear.year());
  this.SelectedDate = ctrlValue.toDate();
  datepicker.close();
  this.OnFilter();
}


}

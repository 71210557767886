export class transactionForm{
 TransCode:string;
TranType:Number;
IsCpt;
IsUB04;
CptCode;
RevenuCode;
HcpcsCode;
TypesOfService;
Units;
Amount;
IsDeleted;
UserId=localStorage.getItem('id');
Tdescription:string;
}
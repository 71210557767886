import { Component, OnInit, Input, ViewChild,ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { Consent } from 'src/app/_models/_Treatment/_consent';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { TreatmentComments } from 'src/app/_models/_Treatment/_diagnosis';

import { PatientService } from 'src/app/patient/patient.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-treatmentconsent',
  templateUrl: './treatmentconsent.component.html',
  styleUrls: ['./treatmentconsent.component.scss']
})
export class TreatmentconsentComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @Input() patientAccountId: number;
  @Input() patientInfoId: number;
  @ViewChild('frmConsent') frmConsent: NgForm;
  chmaTreatmentSummaryId: number;
  userId: any;
  userName: any;
  currentDate: any;
  _dtoConsent: Consent;
  signatureImg: any;
  updatedsignatureImg: any;
  isESignAttached: boolean;
  isformtype: string;
  isfutureSign: boolean;
  dynamicimgsrcid: any = [];
  isAttachEsign: boolean;
  defaultImage: any;
  isConsentFormDisabled:boolean=false;
  roleId:number;
  DiagnosisProvider: string;
  roleAccess: RoelAccess;
  isConsentExist : boolean = false;
  isPsychiatristSignRequired:boolean=false;
  _dtoComments: any = [];
  showCommentsPopup:boolean=false;
  showRecommandationPopup:boolean=false;
  title: string = '';
  Header: string='';
  Addtitle: string = '';
  AddHeader: string='';
  CommentsidAll:number;
  submitted = false;
  Error:boolean=false;
  showMedicationsPopup:boolean =false;
  _dtoTreatmetComments: TreatmentComments = new  TreatmentComments();
  NoRecord:string ='';
  selectedToDate: any;
  _dtotreatmentPlanCommentsId: any;
  _dtoCommentdetails: any;
  Commentid: any;
  secondaryId : any;
  sSignature : any;
  licenseNumber :any;
  AutoFillSS:boolean=false;
  SSignature:any;
  GetClinicianSign: any[];


  constructor(public global: Global, public treatmentservice: TreatmentService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public roleaccessservice:PageAccessService,public patientservice: PatientService,public datepipe: DatePipe) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.TreatmentPlan;
   this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.roleId = Number(localStorage.getItem("roleId"));
    this.userId = Number(localStorage.getItem('id'));
    this.userName = localStorage.getItem('username');
    this.currentDate = new Date();
    this.getAutoFillSignature();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.account).replace(/"/g, ""));
        this.chmaTreatmentSummaryId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patient).replace(/"/g, ""));
      }
      
      this.getTreatmentPlanConsent();
      this._dtoConsent = new Consent();
    });
    if(this.roleId==1){
      this.isConsentFormDisabled=true;
    }
    this.treatmentservice.DiagnosisProvider.subscribe((res)=>  
     { this.SetProviderDetailsFromDiagnosis();});
  }
  getAutoFillSignature() {
    this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
        this.GetClinicianSign = res;

        if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
          this.AutoFillSS=false;
          this.SSignature=this.GetClinicianSign[0].clinicianSign;
        }
        else
        {
          this.AutoFillSS=true;
          this.SSignature='';
        }
    });
}

  getTreatmentPlanConsent() {
    this.spinnerservice.show();
    this.secondaryId = localStorage.getItem('id');
    this.treatmentservice.getTreatmentPlanConsent(this.chmaTreatmentSummaryId , this.secondaryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null && res.treatmentConsentId > 0) {
          this._dtoConsent = res;
          this.userName = this._dtoConsent.userName ? this._dtoConsent.userName : this.userName; 
          this.licenseNumber = this._dtoConsent.licenseNumber ? this._dtoConsent.licenseNumber : this._dtoConsent.LicenseNumber;
          this.sSignature = this._dtoConsent.sSignature ? this._dtoConsent.sSignature : this._dtoConsent.SSignature;
          this.isConsentExist = true;
          this.defaultImage=res.defaultEsignImage;  
          this.DiagnosisProvider = res.diagnosisProvider;     
          if(this._dtoConsent.psychiatristsSign==''){
            this.isPsychiatristSignRequired=false;     
          }
          else{
            this._dtoConsent.psychiatristsSign = (res.psychiatristsSign == null || res.psychiatristsSign == undefined || res.psychiatristsSign == '') ? this.defaultImage : res.psychiatristsSign;
            this.isPsychiatristSignRequired=true;         
            (document.getElementById('esign_2') as HTMLImageElement).src = this._dtoConsent.psychiatristsSign;
          }

          this._dtoConsent.signingforminor = (res.signingforminor == null || res.signingforminor == undefined) ? this.defaultImage : res.signingforminor;
          (document.getElementById('esign_1') as HTMLImageElement).src = this._dtoConsent.signingforminor;

          this._dtoConsent.clientSign = (res.clientSign || res.clientSign == undefined) == null ? this.defaultImage : res.clientSign;
          (document.getElementById('esign_3') as HTMLImageElement).src = this._dtoConsent.clientSign;

          this._dtoConsent.parentSign = (res.parentSign == null || res.parentSign == undefined) ? this.defaultImage : res.parentSign;
          (document.getElementById('esign_4') as HTMLImageElement).src = this._dtoConsent.parentSign;
          
          this._dtoConsent.staffInitial1 = (res.staffInitial1 == null || res.staffInitial1 == undefined) ? this.defaultImage : res.staffInitial1;
          (document.getElementById('esign_5') as HTMLImageElement).src = this._dtoConsent.staffInitial1;
        }else{
          this.isConsentExist = false;
        }
        if(res != null){
          this._dtoConsent = res;  
          this.licenseNumber = this._dtoConsent.licenseNumber ? this._dtoConsent.licenseNumber : this._dtoConsent.LicenseNumber;
          this.sSignature = this._dtoConsent.sSignature ? this._dtoConsent.sSignature : this._dtoConsent.SSignature;
        }

      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  postTreatmentConsent(frmConsent: NgForm) {

    this._dtoConsent.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
    if(!this.isPsychiatristSignRequired){
      this._dtoConsent.psychiatristsSign='';
    }
    this._dtoConsent.diagnosisProvider = this.DiagnosisProvider;
    this._dtoConsent.userName = this.userName;
    //const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoConsent, false);
    this.spinnerservice.show();
    
    this.treatmentservice.postTreatmentPlanConsent(this._dtoConsent).subscribe(
      (res: any) => {

        this.snackbar.success(JSON.parse(res).message);
        this.frmConsent.resetForm();
        this.getTreatmentPlanConsent();
        this.spinnerservice.hide();
        this.treatmentservice.TreatmentPlanStatus.next(false)
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  openDialog(type) {
    this.global.isconsentesign = false;
   let IsParentSignFuture =true;
   let primaryParentId=35;
   let isParentsign =false;
   if((type==5  || type==1 || type== 2) && this.AutoFillSS==true)
    {
      isParentsign=true;

    }

    if((type==3  || type==4))
      {
        isParentsign=true;
      } 
    const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
      {
     data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
      autoFocus: false 

    });

    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign!=undefined && this.global.isconsentesign ) {
        if(this.SSignature!='' &&  (type!=3 && type !=4))
          {
           this.updatedsignatureImg=this.SSignature;
          }
        this.signatureImg = this.updatedsignatureImg;
        this.isAttachEsign = true;
        this.appendEsign(type, this.updatedsignatureImg);
        (document.getElementById('esign_' + type + '') as HTMLImageElement).src = this.signatureImg;
      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.isAttachEsign = true;
        this.appendEsign(type, this.global.consentesign);
        (document.getElementById('esign_' + type + '') as HTMLImageElement).src = this.signatureImg;
      }
      else {
        this.signatureImg = this.defaultImage;
        this.appendEsign(type, this.defaultImage);
      }
      
    });
  }
  appendEsign(type, esign) {
    if (type == 2) {
      this._dtoConsent.psychiatristsSign = esign;
    } else if (type == 1) {
      this._dtoConsent.signingforminor = esign;
    }
    else if (type == 3) {
      this._dtoConsent.clientSign = esign;
    } else if (type == 4) {
      this._dtoConsent.parentSign = esign;
    } else if (type == 5){
      this._dtoConsent.staffInitial1 = esign;
    }
  }

  SetProviderDetailsFromDiagnosis(){
    if(!this.isConsentExist)
    this.DiagnosisProvider = localStorage.getItem("TreatmentplanProvider")
  }
  OnCheckboxclick(){
    this.isPsychiatristSignRequired = !this.isPsychiatristSignRequired;
  }
  generatePdf()
  {
    this.spinnerservice.show();
    this.userName = this._dtoConsent.userName ? this._dtoConsent.userName : this.userName; 
    this.commonService.getTreatmentConsentPdf(this.chmaTreatmentSummaryId,this.DiagnosisProvider,this.userName).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  ViewComments(Commentid)
  {
    this._dtoComments='';
    this.Commentid = Commentid;
    this.getTreatmentPlanComments(Commentid);
    this.showRecommandationPopup=true;    
    this.title="MTP Updates";
    this.Header="MTP Updates";
    this.NoRecord="";
    
  }
  OncloseMedicationpopup()
  {
    this.showMedicationsPopup=false;
  }
  OncloseRecommandationpopup()
  {
    this.showRecommandationPopup=false;
    this.NoRecord="";

  }
  getFrequencyLabel(frequency: string): string {
    switch (frequency) {
      case "1":
        return 'Daily';
      case "2":
        return 'Weekly';
      case "3":
        return 'Monthly';
      default:
        return '';
    }
  }
  getTreatmentPlanComments(Commentid) {
    this.patientservice.getTreatmentPlanComments(this.patientInfoId,Commentid,0).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res && res.length > 0) {
          this._dtoComments = cloneDeep(res);
        } else 
        {
          // this.onAddMedication();
          this.NoRecord="No record found";
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getTreatmentPlanCommentsById(Commentid) {
    this.patientservice.getTreatmentPlanCommentsById(this.patientInfoId,Commentid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res) {
          this._dtoCommentdetails = res;
          this._dtoTreatmetComments.commentUpdatedate = this._dtoCommentdetails.createdDate;
          this._dtoTreatmetComments.commentsName = this._dtoCommentdetails.commentDetails;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  showCommentsPopupFun(Commentsid)
  {
    
    this._dtoComments='';
    this.CommentsidAll=Commentsid;
    
    // this.Error=false;
    // console.log(this.Error);
    this.showCommentsPopup=true;
    this.NoRecord="";
    
    this.Addtitle="MTP Updates";
    this.AddHeader="MTP Updates"; 

  //  this.getTreatmentPlanComments(Commentsid);

  }
  onChangedosTo(event)
  {
      this.selectedToDate=this.datepipe.transform(event.value, "yyyy-MM-dd");
  }
  ondeleteTreatmentPlanComments(treatmentPlanCommentsId) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.treatmentservice.onDeletetreatmentcomment(treatmentPlanCommentsId).subscribe(
          (res: any) => {
            console.log(res)
            this.spinnerservice.hide();
            this.snackbar.success(constantVariables.SuccessMessage.DeletetedSuccesscully);
            this.ViewComments(this.Commentid);
          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },
        );
       
      }
    });
  }
  postTreatmentPlanComments(form:NgForm) {
    
    
    this._dtoTreatmetComments.commentsName=this._dtoTreatmetComments.commentsName;
    this._dtoTreatmetComments.commentUpdatedate = this._dtoTreatmetComments.commentUpdatedate;
    this._dtoTreatmetComments.targetSymptomId=0;
    this._dtoTreatmetComments.treatmentPlanCommentsId = this._dtotreatmentPlanCommentsId;
   
   
    this._dtoTreatmetComments.treatmentDiagosisId=328;
    this._dtoTreatmetComments.commentsId=this.CommentsidAll;
    this._dtoTreatmetComments.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
    this._dtoTreatmetComments.patientAccountId = this.patientAccountId;

    this.spinnerservice.show();
    this.treatmentservice.postTreatmentPlanComments(this._dtoTreatmetComments).subscribe(
      (res: any) => {
                
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.treatmentservice.TreatmentPlanStatus.next(false)
        //this.getTreatmentPlanComments(this.CommentsidAll);
       
      //  window.location.reload();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
   // this.reset();
   this._dtoTreatmetComments.treatmentPlanCommentsId =null;
   form.resetForm();  
   this.showCommentsPopup=false;

  }
  OncloseCommentpopup(form:NgForm)
  {
    this.showCommentsPopup=false; 
    form.resetForm();   

  }
  editCommentPopup(treatmentPlanCommentsId)
  {
    this.OncloseRecommandationpopup();
    this.showCommentsPopup = true;
     this._dtotreatmentPlanCommentsId =treatmentPlanCommentsId ;
    this.getTreatmentPlanCommentsById(this._dtotreatmentPlanCommentsId);
    
  }
  reset()
  {
    this._dtoTreatmetComments.commentsName="";
  }
  secondarySign():any{
    if(this.licenseNumber != '' && this.licenseNumber != null ){
      return false;
    }
    else if(this.sSignature ){
     return false;
    }
    else{
      return true;
    }
  }


}

export class TreatmentDiagosis {
	treatmentDiagosisId :number;
	chmatreatmentSummaryId :number;	
	patientAccountId :number;
	diagnosisCode:any;
	isSuicidal :boolean=true;
	isHomicidal :boolean=true;
	suicidalDetails :string;
	homicidalDetails :string;
	diagosisDetails :string;
	majorProblemArea  :any;
	majorProblem_Area  :any;
	majorProblem  :string;
	recommendedCourse :string;
	homeRecommendations :string;
	outsideRecommendations :string;
	otherProvisions:boolean;
	coordinatorId :number;	
	isOutsideProviders :number;
	status :number;	
	providerId:number;	
	providerName:string;
	clinicalChallenges:string;
	presentingProblems : string;
	currentDsm : string;
	// chmatreatmentSummaryId: any

// 	clinicalChallenges: any

// 	coordinatorId
// 		:
// 		any
// 	createdBy
// 		:
// 		any
// 	createdDate
// 		:
// 		any
// 	diagnosisIsActive
// 		:
// 		any
// 	diagosisDetails
// 		:
// 		any
// 	homeRecommendations
// 		:
// 		any
// 	homicidalDetails
// 		:
// 		any
	
// 	isOutsideProviders
// 		:
// 		any
	
// 	majorProblem
// 		:
// 		any
// 	majorProblemArea
// 		:
// 		any
// 	modalityIsActive
// 		:
// 		any
	
// 	patientAccountId
// 		:
// 		any
	
	
// 	treatmentPlanDiagnosisId
// 		:
// 		any
// 	updatedBy
// 		:
// 		any
// 	updatedDate
// 		:
// 		any

}
export class TreatmentComments {
	treatmentDiagosisId :number;
	chmatreatmentSummaryId :number;	
	patientAccountId :number;	
	recommendedCourse :string;
	homeRecommendations :string;
	outsideRecommendations :string;
	medications :string;
	commentsId: number;
	commentsName: string;
	commentUpdatedate : Date;
	targetSymptomId: number;
	shortTermGoalId: number;
    treatmentPlanCommentsId : number;

	goalId:number;
	ShortTermGoalsMasterid: number;
}

export class _dtoPatientDischarge {
	
	isIntakePacket : boolean;
	isNutritionalQuestionnaire : boolean;
	isPainAssessment  : boolean;
	isSuicidalRiskAssessment  : boolean;
	isIntakeUpdate  : boolean;
	isComprehensiveMHA  : boolean;
}
import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleserviceService } from '../roleservice.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { NONE_TYPE, viewClassName } from '@angular/compiler';
import * as moment from 'moment';
declare function decrypt(data): any;
declare function EncryptFieldData(data): any;

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {
 
  
  // toggle: boolean = false;
  // panelOpenState = false;
  // toggleStyle: boolean = false;
  // toggleStyle2: boolean = false;
  // displayedColumns2: string[] = ['SNo', 'TCode', 'TType', 'Tos', 'CPT', 'HCPCS', 'Rc', 'Un', 'Tc'];
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  //displayedColumns1: string[] = ['position', 'name',  'Clinician','dischar','DOB', 'Discharge', 'actions'];
  //dataSource1 = ELEMENT_DATA1;
 
   displayedColumns = ['RoleName', 'Description', 'actions'];
  //displayedColumns = ['Sno', 'Particular', 'Type', 'actions'];
  //#region  Declaration
  SelectedTab =1;
  dataSource: MatTableDataSource<any>;
  roleForm: FormGroup;
  pageList: any;
  isPageEdit: boolean;
  isPageCreate: boolean;
  isPageDelete: boolean;
  isAddRole = false;
  submitted = false;
  isEdit = false;
  //#endregion
   selectedtab:number=1;
  CPT: any=0;
  timenew: string;
  isShowDivIf: boolean = false;

  constructor(public global: Global,private formBuilder: FormBuilder, private roleService: RoleserviceService,
    private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
     
   
   
   
    // this.firstFormGroup = this.formBuilder.group({
    //   firstCtrl: ['', Validators.required]
    // });
    // this.secondFormGroup = this.formBuilder.group({
    //   secondCtrl: ['', Validators.required]
    // });
 
    this.getAllRoles();
    this.roleaccess();
    this.roleForm = this.formBuilder.group({
      RoleName: ['', [Validators.required,this.global.cannotContainSpace]],
      Description: ['', [Validators.required,this.global.cannotContainSpace]],
    });
  }
  toggleDisplayDivIf() {
    this.isShowDivIf = !this.isShowDivIf;
  }

  get f() { return this.roleForm.controls; }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  roleaccess() {
    const pageid = constantVariables.enumpagelists.ROLES;
    this.pageList = JSON.parse(localStorage.getItem('pagelist'));
    const page = this.pageList.find(x => x.pageId == pageid);
    this.isPageEdit = page.isEdit;
    this.isPageCreate = page.isCreate;
    this.isPageDelete = page.isDelete;

  }
  getAllRoles() {

    this.roleService.getAllRoleMaster().subscribe(
      res => {
        if (res != null && res.inputString != "") {
          var rolelist = decrypt(res.inputString);
          this.dataSource = new MatTableDataSource(rolelist);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }

      },
      err => {

      },
    );
  }
  OnTabchange(item){
    this.SelectedTab = item;
    if(this.SelectedTab ==2){
    }
   
  }
  onAddRole() {
    this.isAddRole = true;
  }
  onRoleSubmit() {
    this.submitted = true;
    if (!this.roleForm.valid) {
      return
    }
    const data = {
      Id: 0,
      RoleName: this.roleForm.value.RoleName,
      Description: this.roleForm.value.Description,
      IsActive: true
    };
    const dataToEncrypt = {
      objmaster: EncryptFieldData(JSON.stringify(data))
    };
    
    this.roleService.saveRoleMaster(data).subscribe(res => {
      if (res) {
        this.getAllRoles();
        this.closeAddRole();
      }
    },
      err => {

      }
    );

  }
  closeAddRole() {
    this.isAddRole = false;
    this.isEdit = false;
    this.roleForm.reset();
  }

  onEdituser(id) {
    this.isEdit = true;
    var data = {
      id: id
    }
    this.roleService.getMastersById(data).subscribe((userdata: any) => {
     
      this.isAddRole = true;
      var DataResult = decrypt(userdata.inputString);

      this.roleForm = this.formBuilder.group({
        RoleName: [DataResult[0].RoleName, [Validators.required]],
        Description: [DataResult[0].Description, [Validators.required]],
        Id: DataResult[0].Id
      });


    });
  }
  
  onUpdateRole() {
    this.submitted = true;
    if (!this.roleForm.valid) {
      return
    }
    const data = {
      RoleName: this.roleForm.value.RoleName,
      Description: this.roleForm.value.Description,
      Id: Number(this.roleForm.value.Id),
    };
    this.roleService.saveRoleMaster(data).subscribe(res => {
      if (res) {
        this.getAllRoles();
        this.closeAddRole();

      }
    },
      err => {

      }
    );

  }
  onclickpacket(tab){
    this.selectedtab = tab;
  }

  OnCPTclick(item){
    if(item==1){
      this.CPT=1
    }
    else{
      this.CPT=2
    }
  }
}


function toggleDisplayDivIf() {
  throw new Error('Function not implemented.');
}
// export interface PeriodicElement1 {
//   name: string;
//   position: number;
//   DOB: string;
//   Clinician: string;
//   Discharge: string;
// }

// const ELEMENT_DATA1: PeriodicElement1[] = [
//   {position: 1, name: 'Ezra',  Clinician: 'george',DOB: '21-11-1990', Discharge: '29-06-2022',},
// ];




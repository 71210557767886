export class SendEmail {
    PrqueryId: number;
    ToUserEmail: string;
    CCUsersEmail: string;
    NoOfDaysInOpen: number;
    Title: string;
    QueryDescription: string;
    TotalCount: number;
    BlobUrl: string;
    Comments: string;
    IsRead: boolean
    Email: string;
    QuerySentUserId: number;
    Type: string;
    Id: number;
    ParentId:number;
}

// export class SendEmail {
//     MailCommunicationId: number;
//     ToMailId: string;
//     CcMailId: string;
//     MailSubject: string;
//     MailContent: string;
//     ReplyId: number;
//     Type : string;
//     Id: number;
// }
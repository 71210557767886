<div class="strength-weekness">
    <!-- <span *ngIf="global.clientSWUpdatedOn !=null"
        class="status-msg float-left text-right w-100 mr-3 mt-2 cursor-pointer text-underline">Last Updated on:
        <span>{{global.clientSWUpdatedOn | date:
            'MM/dd/yyyy'}}</span></span>
    <div class="col-md-12">
        <div class="text-right">
            <button type="button" mat-stroked-button (click)="onSaveClientSWQuestion()"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save & Next</button>
            <button type="button" [routerLink]="['/clinician']" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40">Cancel</button>
        </div>
    </div> -->
    <div class="col-md-12 d-flex">
        <span *ngIf="global.clientSWUpdatedOn !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
            Updated on:
            <span>{{global.globalUtctimeformat(global.clientSWUpdatedOn) | date:
                'MM/dd/yyyy hh:mm a'}}</span></span>
                <span *ngIf="global.clientSWUpdatedByName !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
                    Updated By:
                    <span>{{global.clientSWUpdatedByName}}</span></span>
        <div class="text-right ml-auto">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <!-- <button type="button" (click)="onSaveClientSWQuestion(0)" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{isMovedChma ? 'Next' : 'Save
                &
                Next'}}</button> -->
                <button type="button" (click)="onSaveClientSWQuestion(0)" mat-stroked-button  *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{  'Save
                &
                Next'}}</button>
                <!-- *ngIf="!isMovedChma" -->
            <button type="button" (click)="onSaveClientSWQuestion(1)" mat-stroked-button
                *ngIf="roleAccess.isCreate||roleAccess.isEdit" class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save &
                Exit</button>
            <button type="button" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button
                *ngIf="!global.isClientSW" (click)="onSaveClientSWQuestion(2)">Save as Draft</button>
        </div>
    </div>
    <!-- [ngClass]="{'disabled':isMovedChma}" -->
    <div >
        <ng-container *ngFor="let item of lstClientSW?.answers?.details ;let i=index;">
            <div class="col-md-12 set1-temp">
                <div class="row mb-2">
                    <label class="col-md-12 set-label">{{item.question}}</label>
                    <div class="col-md-12" *ngIf="item.typeId==queDescriptive">

                        <ng-container *ngFor="let opt of item?.options;let idx=index;">
                            <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
                            <textarea matInput appTextareaAutoresize cols="2" rows="4" class="w-100" maxlength="3000" [(ngModel)]="opt.description"
                                #explanation="ngModel"></textarea>
                            </mat-form-field> -->
                            <app-word-counter [text]="opt.description" [maxlength]="3000"  [(ngModel)]="opt.description" 
                            (textChange)="opt.description = $event"></app-word-counter>

                        </ng-container>

                    </div>
                    <div class="col-md-12 custom-radio" *ngIf="item.typeId==queMCMA">
                        <ng-container *ngFor="let opt of item?.options;let idx=index;">
                            <label class="mr-2">
                                <input type="checkbox" name="quemcma{{idx}}{{i}}"
                                    [checked]="opt.isAnswer == true ? true:false" (click)="cbkAnswer(i,idx,$event)"
                                    required>
                                {{opt.optionLabel}}
                            </label>


                            <div *ngIf="item.iSquestionExplanation == 1">
                                <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
                                <textarea matInput appTextareaAutoresize cols="2" rows="4"  class="w-100" maxlength="3000" [(ngModel)]="item.explanation"
                                    #explanation="ngModel"></textarea>
                                    </mat-form-field> -->
                                    <app-word-counter [text]="item.explanation" [maxlength]="3000"  [(ngModel)]="item.explanation" 
                                    (textChange)="item.explanation = $event"></app-word-counter>

                                <span class="w-100 font-14 text-danger"
                                    *ngIf="isReqExplanation && item.explanation == ''">Explanation is Required</span>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-md-12 custom-radio" *ngIf="item.typeId==queYesNo">
                        <ng-container *ngFor="let opt of item?.options;let idx=index;">
                            <label class="mr-2">
                                <input type="radio" class="medium-radio" name="queYesNo{{idx}}{{i}}"
                                    [checked]="opt.isAnswer == true ? true:false" (click)="rbAnswer(i,idx)" required>
                                {{opt.optionLabel}}
                            </label>

                        </ng-container>

                        <div *ngIf="item.iSquestionExplanation == 1">
                            <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
                            <textarea matInput appTextareaAutoresize cols="2" rows="4"  class="w-100" maxlength="3000" [(ngModel)]="item.explanation"
                                #explanation="ngModel"></textarea>
                                </mat-form-field> -->

                                <app-word-counter [text]="item.explanation" [maxlength]="3000"  [(ngModel)]="item.explanation" 
                                (textChange)="item.explanation = $event"></app-word-counter>
                            <span class="w-100 font-14 text-danger" *ngIf="isReqExplanation">Explanation is
                                Required</span>
                        </div>

                    </div>
                </div>
            </div>
        </ng-container>
        <div class="col-md-12 set6-temp">
            <div class="row mb-2">
                <div class="col-md-12 custom-radio">

                    <div class="medication col-md-12 inputWrapper p-0" *ngIf="_dtoMedications.length > 0 || isAssigned">
                        <div class="row inputElement">
                            <div class="col-md-3"><label>Medications</label></div>
                            <div class="col-md-3"><label>Dosage</label></div>
                            <div class="col-md-3"><label>Frequency</label></div>
                            <div class="col-md-3"><label>Side effects</label></div>
                        </div>
                        <div class="loop-section position-relative mb-2"
                            *ngFor="let med of _dtoMedications;let i= index;let last=last">
                            <div class="row  inputElement">
                                <div class="col-md-3">
                                    <input class="form-control" maxlength="50" [(ngModel)]="med.medications"
                                        #medications="ngModel" name="medications{{i}}" placeholder="Medicine"
                                        type="text">
                                </div>
                                <div class="col-md-3"><input class="form-control" maxlength="30"
                                        [(ngModel)]="med.dosage" #dosage="ngModel" name="dosage{{i}}"
                                        placeholder="Dosage" type="text"></div>
                                <div class="col-md-3">
                                    <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                                        [(ngModel)]="med.frequency" #frequency="ngModel" name="frequency{{i}}">
                                        <option value="null">Frequency</option>
                                        <option value="1">q.a.m. Every morning</option>
                                        <option value="2">q.p.m. Every evening</option>
                                        <option value="3">q.h.s Every night at bedtime</option>
                                        <option value="4">b.i.d. Twice daily</option>
                                        <option value="5">t.i.d. 3 times a day</option>
                                        <option value="6">q.i.d 4 times a day</option>
                                        <option value="7">q.o.d Every other day</option>
                                        <option value="8">q.w. Every week</option>
                                        <option value="9">p.r.n. Daily as needed</option>
                                    </select>
                                </div>
                                <div class="col-md-3"><input class="form-control" maxlength="50"
                                        [(ngModel)]="med.sideEffects" #sideEffects="ngModel" name="sideEffects{{i}}"
                                        placeholder="Sideeffects" type="text"></div>
                            </div>
                            <a class="add" style="cursor: pointer;" *ngIf="last" (click)="addMedicationInfo()"><i
                                    class="fa fa-lg fa-plus-circle"></i><span class="sr-only">Add</span></a>
                            <a class="remove" style="cursor: pointer;" (click)="removeclinicalinformation(med,i)"><i
                                    class="fa fa-lg fa-minus-circle"></i><span class="sr-only">Remove</span></a>
                        </div>

                    </div>

                    <div class="col-md-12 set1-temp" *ngIf="_dtoMedications.length == 0">
                        <div class=" col-md-12 row">
                            <div class="col-md-6 p-0">
                                <label class="additional">Medication history not available, would you like to
                                    add?</label>

                            </div>
                            <div class="col-md-4 p-0">
                                <button type="button" (click)="addMedicationInfo()" mat-stroked-button
                                    class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Add</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 set7-temp">
            <div class="row mb-2">
                <label class="col-md-12 set-label">Family History</label>
                <div class="col-md-12 ">
                    <p>To your knowledge, have you or any members of the child’s family (that is, parent, other
                        children,
                        aunts, uncles, or grandparents on either side) ever had any of the following problems? If so,
                        please
                        specify the person’s relationship to the child (i.e. aunt, uncle) and whether the individual was
                        on
                        the mother’s or father’s side of the family.</p>
                </div>
                <div class="col-md-12 ">
                    <div class="disaese-statement col-md-12 p-0 text-center">
                        <div class="row table-head-medication">
                            <div class="col-md-3"></div>
                            <div class="col-md-3"><label>Relationship to child</label></div>
                            <div class="col-md-3"><label>Mother’s side</label></div>
                            <div class="col-md-3"><label>Father’s side</label></div>
                        </div>
                        <div class="loop-section2 position-relative mb-2 border-bottom pb-3"
                            *ngFor="let item of lstPatientHistory?.answers?.details ;let i=index;">
                            <div class="row ">
                                <div class="col-md-3">
                                    <label class="side-heading-table">{{item.question}}</label>
                                    <input class="form-control" *ngIf="item.question=='Other (what?) '" name="item.otherQuestion"
                                        [(ngModel)]="item.otherQuestion" #otherQuestion="ngModel" maxlength="100" type="text">
                                </div>
                                <div class="col-md-3"><input class="form-control" name="tn_firstname{{i}}"
                                        [(ngModel)]="item.explanation" #explanation="ngModel" maxlength="100"
                                        placeholder="Relationship" type="text"></div>
                                            <div class="col-md-3 text-center "  *ngFor="let opt of item?.options;let idx=index;">
                                                <div class="check-custom">
                                                    <ng-container>
                                                        <label class="mr-2">
                                                            <input type="checkbox" name="quemcma{{idx}}{{i}}"
                                                                [checked]="opt.isAnswer == true ? true:false"
                                                                (click)="cbkPatientAnswer(i,idx,$event)" required>
            
                                                        </label>
            
                                                    </ng-container>
                                                </div>
                                            </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="text-right">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <!-- <button type="button" (click)="onSaveClientSWQuestion(0)" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{isMovedChma ? 'Next' : 'Save
                &
                Next'}}</button> -->
                <button type="button" (click)="onSaveClientSWQuestion(0)" mat-stroked-button  *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{ 'Save
                &
                Next'}}</button>
                <!-- *ngIf="!isMovedChma" -->
            <button type="button" (click)="onSaveClientSWQuestion(1)" mat-stroked-button  *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save &
                Exit</button>

        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/common/common.service';
@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private http: HttpClient, public commonService: CommonService) { }
  getInboxMessage(data) {
    return this.http.get(environment.BaseURI + 'gateway/SendMailQueryPost/GetSentMailQueries?id=' + this.commonService.DataToEncrypt(data, true));
  }
  getMessageDetails(data) {
    return this.http.get(environment.BaseURI + 'gateway/SendMailQueryPost/GetQueryDetails?id=' + this.commonService.DataToEncrypt(data, true));
  }
  postMessage(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    return this.http.post(environment.BaseURI + 'gateway/SendMailQueryPost/SaveMailQuery', data)
      .pipe(map(res => {

        return res;
      }));
    
  }
  UploadDocment(data) {   
    return this.http.post(environment.BaseURI + 'gateway/SendMailQueryPost/UploadQueryDocument', data, { reportProgress: true, observe: 'events' });
  }
  setMarkasRead(data) {
    return this.http.get(environment.BaseURI + 'gateway/SendMailQueryPost/SetQueryAsRead?id=' + this.commonService.DataToEncrypt(data, true));
  }
}

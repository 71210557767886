<div class="review-incident-report">
    <div  class="d-flex"><div  class="head-section mt-2 mb-2">Review Incident Information</div><div  class="ml-auto"><button  class="btn back-btn-new mr-2" tabindex="0" (click)="Oncancel()"><i  class="fas fa-chevron-left mr-2" ></i>Back</button></div></div>
    <form (ngSubmit)="IncReviewfrm.form.valid" id="IncReviewfrm" autocomplete="off" #IncReviewfrm="ngForm"
    novalidate [ngClass]="{'disabled': status==3 }" >
        <div class="col-md-12 bg-white p-2" *ngIf="IncidentType==2">
          <div class="col-md-12">
          <div class="col-md-12 p-0">
            <div  class="treat-label">Disposition<span class="custom-radio ml-2 align-text-top">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="IncReviewfrm.IsReportCompletedradio"
              #IsReportCompletedradio="ngModel" name="IsReportCompletedradio" >
                <mat-radio-button value='1' (click)="OnresultCompleted(1)" class="mr-2">Report Completed (No follow up needed) </mat-radio-button>
                <mat-radio-button value='2'  (click)="OnresultCompleted(2)">Follow up (Explain) </mat-radio-button>
              </mat-radio-group>
            </span></div>
            <div> 
            <mat-form-field class="w-100 custom-textarea mt-2" *ngIf="this.IncReviewInfo.IsFollowUp==true">
              <textarea matInput [(ngModel)]="IncReviewInfo.Disposition" #Disposition="ngModel" name="Disposition" row="3" maxlength="500" required 
              [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && Disposition.invalid }" ></textarea>
              <mat-error *ngIf="IncReviewfrm.submitted && Disposition.invalid ">
                <mat-error  *ngIf="Disposition.errors.required">
                  Disposition is required</mat-error>
            </mat-error>
            </mat-form-field>
          </div>
          </div>
          <div class="col-md-12 p-0">
            <div  class="treat-label">Narrative</div>
            <mat-form-field class="w-100 custom-textarea mt-2">
              <textarea matInput [(ngModel)]="IncReviewInfo.Narrative" #Narrative="ngModel" name="Narrative" maxlength="500" required
              [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && Narrative.invalid }"></textarea>
              <mat-error *ngIf="IncReviewfrm.submitted && Narrative.invalid " >
                <mat-error  *ngIf="Narrative.errors.required">
                  Narrative is required</mat-error>
            </mat-error>
            </mat-form-field>
           
           </div>
           <div class="col-md-12 p-0">
            <div  class="treat-label">Follow up debriefing</div>
            <mat-form-field class="w-100 custom-textarea mt-2">
              <textarea matInput  [(ngModel)]="IncReviewInfo.FollowUpBrief" #FollowUpBrief="ngModel" name="FollowUpBrief" maxlength="500"
              [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && FollowUpBrief.invalid }" required></textarea>
              <mat-error *ngIf="IncReviewfrm.submitted && FollowUpBrief.invalid " >
                <mat-error  *ngIf="FollowUpBrief.errors.required">
                  Follow up debriefing is required</mat-error>
            </mat-error>
            </mat-form-field>
           
           </div>
           <div class="col-md-12 p-0">
            <div  class="treat-label">Identify what led to incident and what could have been handled differently</div>
            <mat-form-field class="w-100 custom-textarea mt-2">
              <textarea matInput [(ngModel)]="IncReviewInfo.HandledDifferent" #HandledDifferent="ngModel" name="HandledDifferent"  maxlength="500"
              [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && HandledDifferent.invalid }" required></textarea>
              <mat-error *ngIf="IncReviewfrm.submitted && HandledDifferent.invalid " >
                <mat-error  *ngIf="HandledDifferent.errors.required">
                  This field is required</mat-error>
            </mat-error>
            </mat-form-field>
           
           </div>
           <div class="col-md-12 p-0">
            <div  class="treat-label">Ascertain that the physical well-being, psychological comfort, and right to privacy of the child or youth were addressed</div>
            <mat-form-field class="w-100 custom-textarea mt-2">
              <textarea matInput [(ngModel)]="IncReviewInfo.Psychologicalcomfort" #Psychologicalcomfort="ngModel" name="Psychologicalcomfort"  maxlength="500"
              [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && Psychologicalcomfort.invalid }" required></textarea>
              <mat-error *ngIf="IncReviewfrm.submitted && Psychologicalcomfort.invalid " >
                <mat-error  *ngIf="Psychologicalcomfort.errors.required">
                  This field is required</mat-error>
            </mat-error>
            </mat-form-field>
           
           </div>
           <div class="col-md-12 p-0">
            <div  class="treat-label">Assess the impact of the holding on the child's or youth's emotional functions</div>
            <mat-form-field class="w-100 custom-textarea mt-2">
              <textarea matInput [(ngModel)]="IncReviewInfo.Emotionalfunctions" #Emotionalfunctions="ngModel" name="Emotionalfunctions"  maxlength="500"
              [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && Emotionalfunctions.invalid }" required></textarea>
              <mat-error *ngIf="IncReviewfrm.submitted && Emotionalfunctions.invalid " >
                <mat-error  *ngIf="Emotionalfunctions.errors.required">
                  This field is required</mat-error>
            </mat-error>
            </mat-form-field>
           
           </div>
           <div class="col-md-12 p-0">
            <div  class="treat-label">When indicated, modify the child's or youth's plan for care, treatment, or services</div>
            <mat-form-field class="w-100 custom-textarea mt-2">
              <textarea matInput [(ngModel)]="IncReviewInfo.PlanForCare" #PlanForCare="ngModel" name="PlanForCare"  maxlength="500"
              [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && PlanForCare.invalid }" required></textarea>
              <mat-error *ngIf="IncReviewfrm.submitted && PlanForCare.invalid " required>
                <mat-error  *ngIf="PlanForCare.errors.required">
                  This field is required</mat-error>
            </mat-error>
            </mat-form-field>
           
           </div>
          </div>
  
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 check-custom text-transform-unset">
                <mat-checkbox class="example-margin"  [(ngModel)]="IncReviewInfo.IsInformed" #IsInformed="ngModel" name="IsInformed" [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && IsInformed.invalid }" required>Check if parent/guardian was informed on regarding Stepping Forward Counseling Center’s policy on the use of behavioral restraints</mat-checkbox>
                <mat-error *ngIf="IncReviewfrm.submitted && IsInformed.invalid " >
                  <mat-error class="mat-form-field-subscript-wrapper"  *ngIf="IsInformed.errors.required">
                    This field is required</mat-error>
              </mat-error>
              </div>
              <!-- <div class="col-md-6 check-custom text-transform-unset">
                <mat-checkbox class="example-margin" [(ngModel)]="IncReviewInfo.IsDied" #IsDied="ngModel" name="IsDied">Check if death occurred as a result of the use of behavioral restraints </mat-checkbox>
              </div> -->
            </div>
          </div>
          <div  class="col-md-12">
            <div class="form-group clearfix m-0">
              <div  class="float-left col-md-4 p-0 mt-3 pt-1">
                <label  class="treat-label w-100">Reviewer’s signature</label>
                <img  id="esign_2" height="50px" width="300px"  >
              
                <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialog()"  > Add E-Sign </button>
                <div class="float-left col-md-4 p-0 mt-3 pt-1" >
                    <mat-error *ngIf="IncReviewfrm.submitted && IncReviewInfo.StaffInitial == undefined " >
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="IncReviewInfo.StaffInitial == undefined">
                            E-Sign is required</mat-error>
                    </mat-error>
                     </div>
                    </div>

            
                <div  class="float-right col-md-2 p-0" *ngIf="this.IncReviewInfo.IsReportCompleted !=true" >
                  <mat-form-field class="example-full-width w-100 ">
                    <mat-label> Date</mat-label>
                    <input matInput [matDatepicker]="updatedpicker" 
                    [(ngModel)]="IncReviewInfo.FollowUpDate" #FollowUpDate="ngModel" name="FollowUpDate" required 
                    [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && FollowUpDate.invalid }">
                    <mat-datepicker-toggle matSuffix [for]="updatedpicker"></mat-datepicker-toggle>
                    <mat-datepicker #updatedpicker></mat-datepicker>
                    <mat-error *ngIf="IncReviewfrm.submitted && FollowUpDate.invalid">
                        <mat-error  *ngIf="FollowUpDate.errors.required">
                            Date is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div  class="float-right col-md-2 p-0" *ngIf="this.IncReviewInfo.IsReportCompleted ==true">
                    <mat-form-field class="example-full-width w-100 ">
                      <mat-label> Date</mat-label>
                      <input matInput [matDatepicker]="completedpicker" 
                      [(ngModel)]="IncReviewInfo.CompletedDate" #CompletedDate="ngModel" name="CompletedDate"
                      [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && CompletedDate.invalid }" required>
                      <mat-datepicker-toggle matSuffix [for]="completedpicker"></mat-datepicker-toggle>
                      <mat-datepicker #completedpicker></mat-datepicker>
                      <mat-error *ngIf="IncReviewfrm.submitted && CompletedDate.invalid">
                        <mat-error  *ngIf="CompletedDate.errors.required">
                            Date is required</mat-error>
                    </mat-error>
                    </mat-form-field>
                  </div>
            </div>
          </div>
          <div class="col-md-12 p-0">
            <div class="text-right" *ngIf="status!=3">
              <button type="button" class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="OnCloseIncident()" [ngClass]="{'disabled': MarkAsClosed || submitted  }" *ngIf="roleAccess.isApprove">Mark as Closed </button>
             <button  form="IncReviewfrm" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="PostIncReviewfrm()" [ngClass]="{'disabled': submitted || MarkAsClosed }" *ngIf="(roleAccess.isCreate || roleAccess.isEdit)">Save</button>
             <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" (click)="Oncancel()" mat-stroked-button *ngIf="(roleAccess.isCreate || roleAccess.isEdit)">Cancel</button>
            </div>
          </div>
        </div>
   

  <div class="reviw-incident-new bg-white p-2 " *ngIf="IncidentType==1">
      <div class="col-md-12">
        <div class="form-group clearfix m-0">
          <div  class="float-left col-md-4 p-0 mt-3 pt-1">
            <mat-form-field class="w-100">
              <mat-label>Name & Title of Reviewer</mat-label>
              <input matInput [(ngModel)]="IncReviewInfo.ReviewerName" #ReviewerName="ngModel" name="ReviewerName" required
              [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && ReviewerName.invalid }">
              <mat-error *ngIf="IncReviewfrm.submitted && ReviewerName.invalid ">
                <mat-error  *ngIf="ReviewerName.errors.required">
                  Name & Title of Reviewer is required</mat-error>
            </mat-error>
            </mat-form-field>
          
          </div>
            <div  class="float-right col-md-2 p-0">
              <mat-form-field class="example-full-width w-100 ">
                <mat-label> Date</mat-label>
                <input matInput [matDatepicker]="datedpicker" 
                [(ngModel)]="IncReviewInfo.SignDate" #SignDate="ngModel" name="SignDate" required
                [ngClass]="{ 'is-invalid': IncReviewfrm.submitted && SignDate.invalid }">
                <mat-datepicker-toggle matSuffix [for]="datedpicker"></mat-datepicker-toggle>
                <mat-datepicker #datedpicker></mat-datepicker>
                <mat-error *ngIf="IncReviewfrm.submitted && SignDate.invalid">
                  <mat-error  *ngIf="SignDate.errors.required">
                      Date is required</mat-error>
              </mat-error>
              </mat-form-field>
            </div>
            
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group clearfix m-0">
          <div  class="float-left col-md-4 p-0 mt-3 pt-1">
            <label  class="treat-label">Reviewer’s signature</label>
            <img  id="esign_2" height="50px" width="300px"  >
          
            <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialog()" > Add E-Sign </button>
            <div class="float-left col-md-4 p-0 mt-3 pt-1" >
              <mat-error *ngIf="IncReviewfrm.submitted && IncReviewInfo.StaffInitial == undefined ">
                <mat-error class="mat-form-field-subscript-wrapper" *ngIf="IncReviewInfo.StaffInitial == undefined">
                    E-Sign is required</mat-error>
            </mat-error>
                 </div></div>
            <!-- <div  class="float-right col-md-2 p-0">
              <mat-form-field class="w-100">
                <mat-label>Phone number</mat-label>
                <input matInput mask='(000) 000-0000'  [(ngModel)]="IncReviewInfo.PhoneNumber" #PhoneNumber="ngModel" name="PhoneNumber">
              </mat-form-field>
            </div> -->
            
        </div>
      </div>

            <div class="col-md-12">
              <div class="form-group clearfix m-0">
                <div  class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label">Secondary signature</label>
                  <img  id="esign_3" height="50px" width="300px"  >
                
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" [disabled]="secondarySign()" (click)="openDialog1()" > Add E-Sign </button>
                  <div class="float-left col-md-4 p-0 mt-3 pt-1" >
                    <mat-error *ngIf="IncReviewfrm.submitted && IncReviewInfo.StaffInitial1 == undefined ">
                      <mat-error class="mat-form-field-subscript-wrapper" *ngIf="IncReviewInfo.StaffInitial1 == undefined">
                          E-Sign is required</mat-error>
                  </mat-error>
                       </div></div>
            
              </div>
            </div>


      <div class="col-md-12 " >
        <div class="text-right" *ngIf="status!=3">
          <button type="button" class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2" (click)="OnCloseIncident()"  mat-stroked-button *ngIf="roleAccess.isApprove">Mark as Closed</button>
        
         <button  class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="PostIncReviewfrm()"  *ngIf="(roleAccess.isCreate || roleAccess.isEdit)"  mat-stroked-button>Save</button>
          <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button  (click)="Oncancel()"  *ngIf="(roleAccess.isCreate || roleAccess.isEdit)">Cancel</button>
        </div>
      </div>
    </div>
  </form>
   </div>

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TemplateService } from 'src/app/common/pages/template/template.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { ConfigureForm } from 'src/app/_models/_Common/template';
import { ConfigureFormDetail } from 'src/app/_models/_Common/template';
import { DtoOption } from 'src/app/_models/_Common/template';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import _Quill from 'quill';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import * as moment from 'moment';
import { deepStrictEqual } from 'assert';
import { FormRenameDialogComponent } from 'src/app/common/form-rename-dialog/form-rename-dialog.component';
import { DtoEditDirectionName } from 'src/app/_models/_Common/template';
import { CatalogueService } from 'src/app/common/pages/price-catalogue/catalogue.service';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';

@Component({
  selector: 'app-configure-forms',
  templateUrl: './configure-forms.component.html',
  styleUrls: ['./configure-forms.component.scss']
})
export class ConfigureFormsComponent implements OnInit {
  @ViewChild('bulkconfigform', { static: true }) bulkconfigform: NgForm;
  @ViewChild('divQuestionPG') divQuestionPG: ElementRef;
  @ViewChild('importphbook') eleimportphbook: ElementRef;
  configureForm: ConfigureForm;
  configureFormDetail: ConfigureFormDetail;
  ddlFormName: any;
  ddlFormTypes: any;
  ddlQuestionTypes: any;
  ddlInstructionType:any;
  uploadType: number = 0;
  currentoption: any;
  isChecked : boolean;
  preferredMulAnswer : any;
  isImageChecked : boolean;
  configureFormId: number = 0;
  isAddQuestion: boolean = false;
  isEditConfigureForm: boolean = false;
  isShowAddQuestion: boolean = false;
  dtoOption = [];
  isAnswerindex: any;
  isMulAnswerindex:any = [];
  preferredAnswer: any;
  isMSMAAnswers: any = [];
  lstFromDetails: any = [];
  UploadFileList = [];
  QuestionImages = [];
  libraryNames = [];
  libraryImages = [];
  libraryImageId: number = 0;
  survey = constantVariables.formType.Survey;
  checkList = constantVariables.formType.Checklist;
  nutritional = constantVariables.formType.Nutritional
  queYesNo = constantVariables.questionType.YesNo;
  queMCSA = constantVariables.questionType.MCSA;
  queMCMA = constantVariables.questionType.MCMA;
  queDescriptive = constantVariables.questionType.Descriptive;
  queRatings = constantVariables.questionType.Ratings;
  queText = constantVariables.questionType.Text;
  pageid: any;
  isEditQuestion: boolean = false;
  isBulkQuestion: boolean = false;
  istemplatedownloaded: boolean = false;
  bulkQuestion = [];
  questionlen: any;
  surveyactivepage: number = 0;
  lstIntakeNutritional: any;
  questionIndex: number = 0;
  templateEditor: any;
  questionDirection:any;
  IsCommaRequired:boolean =false
  numOfOptions:number=0
  preferredAnswerArray: any=[];
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],        // toggled buttons
      ],

    },

    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log("enter");
            return true;
          }
        }
      }
    }
  }
  NoprefferedAnswerError: boolean=false;

  isDirectionNameUpdt: boolean=false;
  DirectionName;
  dtoEditDirectionName:DtoEditDirectionName;
  ddllocation;
  isGlobalAccess: boolean = false;
  roleAccess: any;
  constructor(public tempService: TemplateService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public CatlogueService: CatalogueService
    ,public roleaccessservice:PageAccessService
    ) { }

  ngOnInit(): void {
    var access = JSON.parse(localStorage.getItem('roleaccess'));
    const pageid = constantVariables.pagelists.Templates;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.oninit();
    this.getFormTypes();
    this.getQuestionTypes();
    this.getFormName();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.configureFormId = Number(this.commonService.FrontEndDecryption(params.id));
        this.getConfigureFormDetails();
        this.pageid = params.pageid;

      }
    });
    this.getLibraryNames();
    this.getddlLocation();
    this.getInstructionType();
   
  }
  oninit() {
    this.configureForm = new ConfigureForm();
    this.configureFormDetail = new ConfigureFormDetail();
    this.configureFormDetail.typeId = null;
    this.configureFormDetail.iSquestionExplanation = 3; 
    this.dtoOption = new Array<DtoOption>();
    this.dtoEditDirectionName=new DtoEditDirectionName();


  }
  getFormTypes() {
    this.tempService.getFormTypes().subscribe(
      res => {
        if (res != null) {
          this.ddlFormTypes = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  onEditorCreated(event) {
    this.templateEditor = event;
  }
  getFormName() {
    this.tempService.getFormName().subscribe(
      res => {
        if (res != null) {
          this.ddlFormName = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getInstructionType() {
    this.tempService.getInstructionType().subscribe(
      res => {
        if (res != null) {
          this.ddlInstructionType = res;
          this.spinnerservice.hide();
          // if (this.configureForm.formTypeId == 2) {
          //   this.configureFormDetail.typeId = constantVariables.questionType.YesNo;
          //   this.onQuestionType(null, constantVariables.questionType.YesNo)
          // }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getQuestionTypes() {
    this.tempService.getQuestionTypes().subscribe(
      res => {
        if (res != null) {
          this.ddlQuestionTypes = res;
          this.spinnerservice.hide();
          if (this.configureForm.formTypeId == 2) {
            this.configureFormDetail.typeId = constantVariables.questionType.YesNo;
            this.onQuestionType(null, constantVariables.questionType.YesNo)
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  AddNewItemTypeIdTag(tag: string) {

    return tag;
  }
  postInstruction(data: string) {
    
   // if (data != null) {
      // let _obj = {
      //   AppointmentTypeNameId: 0,
      //   AppointmentTypeName: data,
      //   IsActive: true
      // }


      let _obj = {
        intructionId: 0,
        InstructionName: data,
        IsActive: true
      }

      const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
      this.tempService.postInstructionType(dataToEncrypt).subscribe(
        (res: any) => {
          //this.ddlappoinments = JSON.parse(res);
          //this.ddlmeetingblocks = JSON.parse(res);
          
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
     //}
    
  }
  postConfigureForm(configureForm: NgForm) {
    if (!configureForm.valid) {
      return
    }
    
    this.spinnerservice.show();
    if (this.configureForm.isGlobal == 1) {
      this.configureForm.location='';
      this.configureForm.isGlobal = 1;
    } else {
      this.configureForm.isGlobal = 0;
      if (this.configureForm.location && this.configureForm.location.length > 0) {
        this.configureForm.location = this.configureForm.location.toString();
      }
    }
    
    const dataToEncrypt = this.commonService.DataToEncrypt(this.configureForm, false);
    this.tempService.postConfigureForm(dataToEncrypt).subscribe(
      res => {
        if (res != null) {
          this.configureFormId = JSON.parse(res).id;
          this.router.navigate([], {
            relativeTo: this.route, queryParams: {
              id: this.commonService.FrontEndEncryption(this.configureFormId),
              pageid: 1
            }
          });
          this.getConfigureFormDetails();

          this.snackbar.success(JSON.parse(res).message);
          this.spinnerservice.hide();
          this.isShowAddQuestion = true;
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onAddQuestion() {
    this.global.scrollup();
    this.isAddQuestion = true;
    this.dtoOption = [];
    this.configureFormDetail = new ConfigureFormDetail();
    this.configureFormDetail.iSquestionExplanation=3;
    this.getQuestionTypes();

  }
  onCloseAddQuestion() {

    this.isAddQuestion = false;
    this.isEditConfigureForm = false;
    this.dtoOption = [];
    this.configureFormDetail = new ConfigureFormDetail();
  }
  resetConfigureForms() {
    //this.configureForm = new ConfigureForm();
    this.configureFormDetail = new ConfigureFormDetail();
    this.isEditConfigureForm = false;
    this.isAddQuestion = false;

  }
  onQuestionType(event, type) {
    this.QuestionImages=[];
    this.dtoOption = [];
    if (Number(type) == constantVariables.questionType.YesNo || Number(type) == constantVariables.questionType.MCSA || Number(type) == constantVariables.questionType.MCMA) {
      this.dtoOption.push({
        questionOptionId: 0,
        configureFormDetailId: 0,
        optionChar: null,
        documentId: 0,
        documentName: 'Upload',
        documentLocation: '',
        optionLabel: Number(type) == constantVariables.questionType.YesNo ? 'Yes' : null,
        isAnswer: false,
      }, {
        questionOptionId: 0,
        configureFormDetailId: 0,
        optionChar: null,
        documentId: 0,
        documentName: 'Upload',
        documentLocation: '',
        optionLabel: Number(type) == constantVariables.questionType.YesNo ? 'No' : null,
        isAnswer: false,
      });
    }
  }
  onOptionChecked(event, index) {
    this.NoprefferedAnswerError = false
    if (event.target.checked) {
      this.isAnswerindex = index;
    }
    this.dtoOption.forEach((val: any, key: any) => {
      this.dtoOption[key].isAnswer = false;
    });
    this.dtoOption[index].isAnswer = event.target.checked;
    this.isChecked = true;
  }
  onOptionMCMAChecked(event, index) {
    this.NoprefferedAnswerError = false
   // this.isChecked = true;
   this.IsCommaRequired = false
    if (event.target.checked) {
      this.isMulAnswerindex.push(index);
    }
    this.dtoOption[index].isAnswer = event.target.checked;
    this.numOfOptions=0
    this.preferredAnswerArray=[]
    this.dtoOption.forEach((val: any, key: any) => {
      if(this.dtoOption[key].isAnswer == true){
        this.numOfOptions+=1
        this.preferredAnswerArray.push(this.dtoOption[key])
      //  console.log(this.preferredAnswerArray)
      }
    });
    if(this.numOfOptions>1 ){
    this.IsCommaRequired = true
    console.log("num of answer", this.numOfOptions)
    }

  }

  
  getAnswer()
  {
    
    this.dtoOption.forEach((val: any, key: any) => {
    if(this.dtoOption[key].isAnswer == true)
    {      
      if(this.preferredAnswer !== '' && this.preferredAnswer !== undefined)
      {        
        this.preferredAnswer = this.preferredAnswer + ',' + this.dtoOption[key].optionLabel;
        return this.preferredAnswer;
      }
      else
      {
        this.preferredAnswer = this.dtoOption[key].optionLabel;
        return this.preferredAnswer;
      }
    }
    });
      
  }

  resetnotesform()
  {
    this.dtoOption[this.isAnswerindex].isAnswer = false;
    this.isAnswerindex ='';
    this.isMulAnswerindex = [];
    this.isChecked = false;
  }

  resetImageform()
  {
    this.dtoOption.forEach((val: any, key: any) => {
      this.dtoOption[key].documentLocation = '';
      this.dtoOption[key].documentId = 0;
    });
    this.isImageChecked = false;
  }

  addMoreOption(type) {

    if (Number(type) == constantVariables.questionType.MCSA) {
      this.dtoOption.push({
        questionOptionId: 0,
        configureFormDetailId: 0,
        optionChar: null,
        optionLabel: null,
        isAnswer: false,
      });
     
    }
  }
  ondeleteOption(key, type) {
    if (this.dtoOption[key].questionOptionId != 0) {
      this.ondeleteExistingOption(this.dtoOption[key].questionOptionId)
    }
    this.dtoOption.splice(key, 1);
    if (Number(type) == constantVariables.questionType.MCMA) {
      this.isMSMAAnswers.splice(key, 1);
    }
  }
  ondeleteExistingOption(id) {
    this.tempService.deleteQuesOption(id).subscribe(
      (res: any) => {
        this.getConfigureFormDetails();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  postConfigFormDetails(configureFormDetails: NgForm) {
    if(this.configureFormDetail.iSquestionExplanation == 2){
      this.NoprefferedAnswerError = true
      this.dtoOption.forEach((val: any, key: any) => {
        if(this.dtoOption[key].isAnswer == true){
            this.NoprefferedAnswerError = false
          }
        });
    }
 

    if (!configureFormDetails.valid || this.NoprefferedAnswerError) {
      return
    }
    
    this.spinnerservice.show();
    var type = this.configureFormDetail.typeId.toString();
    var typeId = parseInt(type);
    this.configureFormDetail.configureFormId = this.configureFormId;
    this.configureFormDetail.question =  this.configureFormDetail.question.replace( /(<([^>]+)>)/ig, '')
    this.configureFormDetail.options = this.dtoOption;
    this.configureFormDetail.images = this.QuestionImages;
    this.configureFormDetail.questionDirection = this.lstFromDetails.questionDirection;
    this.configureFormDetail.instructionName = this.configureFormDetail.instructionName;
    this.configureFormDetail.typeId = typeId;
    
   // console.log(this.configureFormDetail)
    
    //const dataToEncrypt = this.commonService.DataToEncrypt(this.configureFormDetail, false);
    this.tempService.postConfigureFormDetails(this.configureFormDetail).subscribe(
      res => {
        if (res != null) {
          configureFormDetails.resetForm();
          this.resetConfigureForms();
          this.snackbar.success(JSON.parse(res).message);
          this.spinnerservice.hide();
          this.getConfigureFormDetails();
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getConfigureFormDetails() {
    this.tempService.getConfigureFormDetails(this.configureFormId).subscribe(
      res => {
        if (res != null) {
          
           this.lstFromDetails = res;
          for(var index in this.lstFromDetails.details){
            if(this.lstFromDetails.details[index].typeId == this.queMCMA ||this.lstFromDetails.details[index].typeId == this.queMCSA ){
              this.lstFromDetails.details[index].options.sort((a, b) => {return a.questionOptionId-b.questionOptionId}) 
            }
          }        
          this.configureForm = cloneDeep(res);
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onEditQuestion(item, index) {
    this.questionIndex = index;
    this.global.scrollup();
    this.isAddQuestion = true;
    this.isEditConfigureForm = true;
    if (this.configureForm.formTypeId != 3) {
      this.configureFormDetail = cloneDeep(item);
      this.dtoOption = cloneDeep(item.options);
//console.log(this.configureFormDetail.typeId)
      if(this.configureFormDetail.typeId==this.queMCMA){
       
        this.numOfOptions=0
        this.preferredAnswerArray=[]
        this.dtoOption.forEach((val: any, key: any) => {
         if(this.dtoOption[key].isAnswer == true){
             this.numOfOptions+=1
             this.preferredAnswerArray.push(this.dtoOption[key])
            }
           });
        if(this.numOfOptions>1 ){
            this.IsCommaRequired = true
           
        // console.log("num of answer", this.numOfOptions)
          }
      }
      else if(this.configureFormDetail.typeId==this.queMCSA || this.configureFormDetail.typeId == this.queYesNo){
        this.dtoOption.forEach((val: any, key: any) => {
          if(this.dtoOption[key].isAnswer == true){
              this.isAnswerindex = key
             }
            });
      }

    }
    else {
      var question = cloneDeep(item);
      if (question.typeId == this.queMCSA || question.typeId == this.queMCMA || question.typeId == this.queYesNo) {
        this.spinnerservice.show();
        this.tempService.getQuestionDetails(question.configureFormDetailId).subscribe(
          res => {
            if (res != null) {

              this.configureFormDetail = cloneDeep(res.details[0]);
              this.dtoOption = cloneDeep(res.details[0].options);
              this.QuestionImages = cloneDeep(res.details[0].images);
              this.dtoOption.forEach((val: any, key: any) => {
                if(this.dtoOption[key].documentId == 0)
                this.dtoOption[key].documentLocation = '';
              });
             
              this.spinnerservice.hide();
              this.spinnerservice.hide();
            }
          },
          err => {
            this.spinnerservice.hide();
          },
        );
      }
      else {
        this.configureFormDetail = cloneDeep(item);
        this.dtoOption = cloneDeep(item.options);
      }
    }
  }
  onDeleteQuestion(item) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.tempService.deleteQuestion(item.configureFormDetailId).subscribe(
          (res: any) => {
            this.spinnerservice.hide();
            this.getConfigureFormDetails();
            this.snackbar.success(res.message);
          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },
        );
      }
    });
  }
  onDuplicateQuestion(item) {

    this.isAddQuestion = true;
    this.isEditConfigureForm = false;
    this.configureFormDetail = cloneDeep(item);
    this.configureFormDetail.configureFormDetailId = 0;
    this.dtoOption = [];
    for (let i = 0; i < item.options.length; i++) {
      this.dtoOption.push({
        questionOptionId: 0,
        configureFormDetailId: 0,
        optionChar: null,
        optionLabel: item.options[i].optionLabel,
        isAnswer: item.options[i].isAnswer,
      });
    }
  }
  onbacktoformlist() {
    this.router.navigate(['/consentlist'], { queryParams: { tab: 2 } });

  }
  onBulkAddQuestion() {
    this.isBulkQuestion = true;
    this.bulkconfigform.resetForm()
    this.questionlen = null;

  }
  onCloseBulkAddQuestion() {
    this.isBulkQuestion = false;
    this.bulkQuestion = [];
    this.isEditConfigureForm = false;
    this.isAddQuestion = false;
    this.questionlen = null;
  }
  onbulkQuestionload(item) {

    //this.bulkconfigform.resetForm()

    this.bulkQuestion = [];
    for (let i = 0; i < item; i++) {
      this.bulkQuestion.push({
        question: null,
        iSquestionExplanation: null,
        questionExplanation: null,
        questionType:1
      });
    }
    // this.bulkconfigform.form.get('questionlen1').setValue(item)
  }
  ondeletebulkOption(key) {
    // this.bulkconfigform.controls["iSquestionExplanation"+key+""].setValidators(null);
    // this.bulkconfigform.controls["iSquestionExplanation"+key+""].updateValueAndValidity();
    this.bulkQuestion.splice(key, 1);

    //this.bulkconfigform.controls["question"+key+""].re(null);
    //  var ss="question"+key+"";
    //  this.bulkconfigform.removeControl(ss);
    // this.bulkconfigform.controls["iSquestionExplanation"+key+""].setErrors(null);
    // this.bulkconfigform.controls["questionExplanation"+key+""].setErrors(null);
  }
  postBulkQuestionForm(frmbulkques: NgForm) {
    if (!frmbulkques.valid) {
      return
    }

    // if(this.bulkQuestion && this.bulkQuestion.length == 0){
    //   this.isReqNoQuestion = true;
    //   return
    // }

    for (let i = 0; i < this.bulkQuestion.length; i++) {
      if (this.bulkQuestion[i].question == null || this.bulkQuestion[i].iSquestionExplanation == null || (this.bulkQuestion[i].iSquestionExplanation == 1 && this.bulkQuestion[i].questionExplanation == null)) {
        return false
      }
    }

    this.spinnerservice.show();
    let _obj = {
      ConfigureFormId: this.configureFormId,
      QuestionDirection: this.lstFromDetails.questionDirection,
      Questions: this.bulkQuestion
    }
    
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.tempService.postBulkQuestions(dataToEncrypt).subscribe(
      res => {
        if (res != null) {

          this.onCloseBulkAddQuestion();
          this.snackbar.success(JSON.parse(res).message);
          this.spinnerservice.hide();
          this.getConfigureFormDetails();

        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  //To Edit Form Name For Survey forms
  oneditFormName(){
   // console.log(this.lstFromDetails)
     const dialogRef= this.dialog.open(FormRenameDialogComponent,
      { data: { configureFormId: this.lstFromDetails.configureFormId, formTypeId : this.lstFromDetails.formTypeId, 
         formName : this.lstFromDetails.formName , isquestionExplanation: this.lstFromDetails.isquestionExplanation, location:this.lstFromDetails.location,isGlobal:this.lstFromDetails.isGlobal} })
         dialogRef.afterClosed().subscribe(result => {
          this.getConfigureFormDetails();
        });
        
  }
  addFormNameTag(tag: string) {
    return tag;
  }
  postFormName(data: string) {
    if (data != null && this.ddlFormName.filter(x => x.formName == data).length == 0) {
      let _obj = {
        ConfigureFormNameId: 0,
        FormName: data,
        IsActive: true
      }

      const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
      this.tempService.postFormName(dataToEncrypt).subscribe(
        (res: any) => {
          this.ddlFormName = JSON.parse(res);
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }
  }
  scrollLeft() {
    //document.getElementById('divQuestionPG').scrollLeft -= 50;
    this.divQuestionPG.nativeElement.scrollTo({ left: (this.divQuestionPG.nativeElement.scrollLeft - 50), behavior: 'smooth' });
  }

  scrollRight() {
    //document.getElementById('divQuestionPG').scrollLeft += 50;
    this.divQuestionPG.nativeElement.scrollTo({ left: (this.divQuestionPG.nativeElement.scrollLeft + 50), behavior: 'smooth' });
  }
  onNextSurvey() {
    this.surveyactivepage = this.surveyactivepage + 1;
  }
  onPrevSurvey() {
    this.surveyactivepage = this.surveyactivepage - 1;
  }
  onClickPage(pageNumber: number): void {
    if (pageNumber >= 0 && pageNumber <= this.lstFromDetails?.details.length - 1) {
      this.surveyactivepage = pageNumber;
      // this.onPageChange.emit(this.activePage);  

    }
  }
  dropQuestions(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.lstFromDetails?.details, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.spinnerservice.show();
     
     // const dataToEncrypt = this.commonService.DataToEncrypt(this.lstFromDetails?.details, false);
      this.tempService.postQuestionOrder(this.lstFromDetails?.details).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message)
          //this.ddlFormName = JSON.parse(res);
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }
  }
  openUploadImage(type, obj) {
    this.uploadType = type;
    this.currentoption = obj;
    this.istemplatedownloaded = true;
    this.isImageChecked = true;
    this.QuestionImages=[];
  }
  onImportImage() {
    this.istemplatedownloaded = !this.istemplatedownloaded;
  }
  removeSelectedImport(index: number) {
    this.QuestionImages.splice(index, 1);
  }
  onloadImages(id) {
    this.libraryImageId = id;
    this.tempService.getLibraryImages(id).subscribe(
      res => {
        if (res != null) {
          this.libraryImages = cloneDeep(res);
          // this.spinnerservice.hide();
          // this.spinnerservice.hide();
        }
      },
      err => {
        // this.spinnerservice.hide();
      },
    );
  }

  getLibraryNames() {
    this.spinnerservice.show();
    this.tempService.getLibraryNames().subscribe(
      res => {
        if (res != null) {
          this.libraryNames = cloneDeep(res);
          this.libraryImageId = this.libraryNames[0].libraryNameId;
          this.spinnerservice.hide();
          // this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onselectImage(obj) {

    if (this.uploadType == 1) {
      const _obj = {
        uploadDocumentsId: obj.uploadDocumentsId,
        fileName: obj.documentName,
        filePath: obj.documentLocation
      }
      this.QuestionImages.push(_obj);
    }
    else {
      this.currentoption.documentId = obj.uploadDocumentsId;
      this.currentoption.documentName = obj.documentName;
      this.currentoption.documentLocation = obj.documentLocation;
      this.onImportImage();
    }
  }
  onFileChange(event) {
    const reader = new FileReader();
    var _validFileExtensions = [".jpg", ".jpeg", ".png"];
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (file.size > 5020905) {
        this.snackbar.error(constantVariables.ErrorMessage.filesize)
        return
      }
      if (file.type == 'image/jpg'
        || file.type == 'image/jpeg' || file.type == 'image/png') {
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.UploadFileList = [];
          var files = Array.from(event.target.files);
          for (var i = 0; i < files.length; i++) {

            this.UploadFileList.push(files[i]);

          }
          const frmData = new FormData();
          for (var i = 0; i < this.UploadFileList.length; i++) {
            frmData.append("doc", this.UploadFileList[i]);
          }
          frmData.append('DocumentTypeId', constantVariables.DocumentTypeId.Library);
          frmData.append('LibraryNameId', this.libraryImageId.toString());
          //alert(this.libraryImageId)
          this.spinnerservice.show();
          this.tempService.postimportImage(frmData).subscribe((event: any) => {

            if (event) {

              this.eleimportphbook.nativeElement.value = "";
              this.spinnerservice.hide();
              var res = cloneDeep(event);
              if (this.uploadType == 1) {
                for (var i = 0; i < res.length; i++) {

                  this.QuestionImages.push(res[i]);

                }
              }
              else {
                this.currentoption.documentId = res[0].uploadDocumentsId;
                this.currentoption.documentName = res[0].fileName;
                this.currentoption.documentLocation = res[0].filePath;
                //File location

                this.onImportImage();
              }
              // this.QuestionImages.push(event);
              // for (var i = 0; i < files.length; i++) {
              //   if (this.UploadFileList.length < 3) {

              //     this.UploadFileList.push(files[i]);
              //   }
              // }
            }
          }, err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          });
          this.eleimportphbook.nativeElement.value = "";
        };
      } else {
        this.eleimportphbook.nativeElement.value = "";
        this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
      }
    }
  }
  oneditDirectionsName()
  {
    this.isDirectionNameUpdt=true;
  }
  
  saveDirectionName(directionEditForm: NgForm)
  {
    if (!directionEditForm.valid) {
      return;
    }
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.configureForm, false);
    this.tempService.postConfigureFormDirectionName(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        this.isDirectionNameUpdt=false;
        this.getConfigureFormDetails();
        this.snackbar.success(JSON.parse(res).message);
             }
    },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      }
      );
    }
  
  OncloseDiectionEditPopup(){this.isDirectionNameUpdt=false;}
  getddlLocation() {
    this.spinnerservice.show();
    this.CatlogueService.getLocations().subscribe(
      res => {
        if (res != null) {
          this.ddllocation = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  locationchange(event){
    this.isGlobalAccess = false;
    
  }
  onchangeAccesslvl(event) {
    if (event.checked) {
        this.isGlobalAccess = true;
        this.configureForm.location="";
    } else {
      this.isGlobalAccess = false;
    }
  }
}

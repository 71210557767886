export class insuranceInfo{
    InsuranceInfoId : Number;
    CompanyName : any;
    ContactName : any;
    Website : any;
    Address : any;
    CityId : Number;
    StaesId : Number;
    Zipcode : any;
    Office : any;
    Fax : any;
    Other : any;
    EclaimsId : any;
    EligibilityId : any;
    FeeScheduleId : Number;
    AllowableId : Number;
    IsInsurance : boolean = false;
    IsLocation : boolean = false;
    IsCrossCover : boolean = false;
    IsSupplimental : boolean = false;
    MediGapNumber : any;
    PayerCategoryId : Number;
    Comments : any;
    IsIcd : boolean = false;
    IcdDate : any;
    ProcessingTime : Number;
    InsuranceGroupId : Number;
    BillingSchedule : any;
    BillingGroupId : any;
    DtoBillingGroupList : any = [];
   }
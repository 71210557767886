import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { TreatmentDiagosis } from 'src/app/_models/_Treatment/_diagnosis';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MenuList } from 'src/app/_models/_Common/roleAccessMaster';
import { TreatmentdiagnosisComponent } from './treatmentdiagnosis/treatmentdiagnosis.component';
import { TreatmentmodalityComponent } from './treatmentmodality/treatmentmodality.component';
import { TreatmentPresentingProblemsComponent } from './treatment-presenting-problems/treatment-presenting-problems.component';
import { TreatmentconsentComponent } from './treatmentconsent/treatmentconsent.component';
import { dtoChmaTreatmentSummary } from 'src/app/_models/_Patient/_CHMADetails';
import { PatientService } from 'src/app/patient/patient.service';
import { Consent } from 'src/app/_models/_Treatment/_consent';

declare function decrypt(data): any;
@Component({
  selector: 'app-treatmentplan',
  templateUrl: './treatmentplan.component.html',
  styleUrls: ['./treatmentplan.component.scss']
})
export class TreatmentplanComponent implements OnInit {
  @ViewChild(TreatmentdiagnosisComponent) treatmentDiagnosisComponent: TreatmentdiagnosisComponent;
  @ViewChild(TreatmentmodalityComponent) treatmentModalityComponent: TreatmentmodalityComponent;
  @ViewChild(TreatmentPresentingProblemsComponent) treatmentPresentingProblemsComponent: TreatmentPresentingProblemsComponent;
  @ViewChild(TreatmentconsentComponent) treatmentconsentComponent: TreatmentconsentComponent;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  panelOpenState = false;
  patientAccountId: number;
  patientInfoId: number;
  chmaTreatmentSummaryId: number;
  _dtoTreatmentInfo: any;
  age:any;
  isToogleInfo:boolean=true;
  roleAccess: any;
  MenuAccess: MenuList;
  LocationId: any;
  pageFrom:number;
  IsDiagonasis : boolean = false;
  IsModality : boolean = false;
  IsGoal : boolean = false;
  IsPresentingProblem : boolean = false;
  IsConsent : boolean = false;
  selectedTab: number =1;
  activeTab ;
  userName: any;
  isShowNewMTP : boolean = false;
  isprimaryClinician: boolean = false;
  isSecondaryClinician: boolean = false;
  isClinicianSame : boolean = false;
  clinicianDetails: any;
  primaryClinicianId: any;
  treatmentPlanDate: any;
  overarchingPeriod: any;
  secondaryClinicianId: any;
  treatmentPlanDateSecondary: any;
  overarchingPeriodSecondary: any;
  istreatmentPlanDate: any;
  istreatmentPlanDateSecondary: any;
  overarchingDateExist :boolean = false;
  overarchingDateExistSecondary :boolean = false;
  clinicalsummary: any;
  userId: any;
  currentdate: any;
  MTPName : any;
  isMTP : boolean = false;
  treatmentPlanDateEdit : any;
  overarchingDateEdit : any;
  isTreatmentEdit : boolean = false;
  isDateValidate : boolean =  false;
  isDateValidate1 : boolean =  false;
  isDateValidateEdit : boolean = false;
  treatmentPlanMultiple : any;
  IsDiagonasisopen : boolean = false;
  Istreatmentmodalityopen : boolean = false;
  IsGoalsinfoopen : boolean = false;
  IsPresentingProblemopen : boolean = false;
  Istreatmentgoalconfigopen : boolean = false;
  Isconsentopen : boolean = false;
  _dtoConsent : any;
  secondaryId :any;
  constructor(public global: Global, public treatmentservice: TreatmentService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public roleaccessservice:PageAccessService,public patientservice: PatientService) {

    }

  ngOnInit(): void {
    this.userName = localStorage.getItem('username');
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.chmaTreatmentSummaryId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        if(params.page != undefined)
        {
          this.pageFrom = JSON.parse(this.commonService.FrontEndDecryption(params.page));
        }
        if(params.location != undefined)
        {
          this.LocationId = JSON.parse(this.commonService.FrontEndDecryption(params.location));
        }
      }
      this.clinicalsummary = new dtoChmaTreatmentSummary();
      this.currentdate = new Date();
      this.patientInfoId = Number(localStorage.getItem("patientId"));
      this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
      this.userId = Number(localStorage.getItem('id'));
      this.userName = localStorage.getItem('username');
      this.getClinicianDetails();
      this.treatmentservice.TreatmentPlanStatus.subscribe((res)=>
      this.getTreatmentPlanStatus()
      );
      this.getTreatmentPlanStatus();
      this.getTreatmentPlandetails();
      const pageid = constantVariables.pagelists.TreatmentPlan;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.MenuAccess = this.roleaccessservice.GetSiteHeaderAccess();
    this.IsDiagonasisopen = true;
    this._dtoConsent = new Consent();
    this.getTreatmentPlanConsent();
    });
  }
  getTreatmentPlandetails() {
    this.spinnerservice.show();
     this.treatmentservice.getTreatmentPlanList(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          this._dtoTreatmentInfo = res[0];
         // this.age = moment().diff(this._dtoTreatmentInfo.dob, 'years');
          this.patientAccountId =  this._dtoTreatmentInfo.patientAccountId;
          this.patientInfoId =  this._dtoTreatmentInfo.patientInfoId;
          this.LocationId = this._dtoTreatmentInfo.sfccMasterId;
          this.treatmentPlanDateEdit = this._dtoTreatmentInfo.treatmentPlanDate;
          this.overarchingDateEdit = this._dtoTreatmentInfo.treatmentPlanEndDate;
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  
  getTreatmentPlanConsent() {
    this.spinnerservice.show();
    this.secondaryId = localStorage.getItem('id');
    this.treatmentservice.getTreatmentPlanConsent(this.chmaTreatmentSummaryId , this.secondaryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null && res.treatmentConsentId > 0) {
          this._dtoConsent = res;
          this.userName = this._dtoConsent.userName ? this._dtoConsent.userName : this.userName; 
        
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }

  onClickChanges(activetab) {
    let _resultformData = false;
    if (this.selectedTab == 1) {
      // Access form data from the child component
      _resultformData = (this.treatmentDiagnosisComponent.frmDiagnosis.dirty && this.treatmentDiagnosisComponent.frmDiagnosis.touched);
      const diagnosisFormData1 = this.treatmentDiagnosisComponent.majorProblemArea1;
      const diagnosisFormData2 = this.treatmentDiagnosisComponent._dtoDiagnosis.majorProblem_Area;
      const majorProblemArea1Changes = JSON.stringify(diagnosisFormData2) != undefined ? JSON.stringify(this.treatmentDiagnosisComponent.majorProblemArea1) !== JSON.stringify(diagnosisFormData2) : false;

      if (majorProblemArea1Changes) {
        _resultformData = true;
      }
      console.log('Dagnosis Form Data:', _resultformData, "2", diagnosisFormData1, diagnosisFormData2);
    }else if(this.selectedTab == 2) {
      _resultformData = (this.treatmentModalityComponent.frmModality.dirty && this.treatmentModalityComponent.frmModality.touched);
      console.log(' Form Data:', _resultformData);

    } 
    else if(this.selectedTab == 4) {
      
      const PresentingProbfrm = (this.treatmentPresentingProblemsComponent.PresentingProbfrm?.dirty && this.treatmentPresentingProblemsComponent.PresentingProbfrm?.touched);
      const PresentingProbfrm1 = (this.treatmentPresentingProblemsComponent.PresentingProbfrm2?.dirty && this.treatmentPresentingProblemsComponent.PresentingProbfrm2?.touched);
      const PresentingProbfrm2 = (this.treatmentPresentingProblemsComponent.PresentingProbfrm3?.dirty && this.treatmentPresentingProblemsComponent.PresentingProbfrm3?.touched);
      if(PresentingProbfrm || PresentingProbfrm1 || PresentingProbfrm2){
        _resultformData = true;
      }
      console.log(' Form Data:', _resultformData);
    } 
    else if(this.selectedTab == 6) {
      _resultformData = (this.treatmentconsentComponent.frmConsent.dirty && this.treatmentconsentComponent.frmConsent.touched);
      console.log(' Form Data:', _resultformData);

    } 
    
    
    return _resultformData;
   }

  
  getTreatmentPlanStatus() {
    // this.spinnerservice.show();
     this.treatmentservice.getTreatmentPlanStatus(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        if (res != null) {
          // this.spinnerservice.hide();
         let result=decrypt(res.objInputString);
         this.IsDiagonasis = result[0].IsDiagonasis;
         this.IsModality = result[0].IsModality;
         this.IsGoal = result[0].IsGoal;
         this.IsPresentingProblem = result[0].IsPresentingProblem;
         this.IsConsent = result[0].IsConsent;
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
 
  onTabChange(tab) {
    var result: boolean;
    var _activatedtab = this.selectedTab ;
    if (this.onClickChanges(tab)) {
      this.Popup( _activatedtab, tab);
    }else{
      this.OnTabChecked_True(tab);
      this.selectedTab = tab;
    }
  
  }

  Popup(selectedtab, activetab) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {

      data: {

        title: 'Warning',

        message: 'You have unsaved changes! If you leave, your changes will be lost.'
      }

    });

    confirmDialog.afterClosed().subscribe(result => {
    
      if (result === true) {
        this.selectedTab = activetab;
        this.OnTabChecked_True(activetab);
        return;
      }else{
        this.selectedTab = selectedtab;
        this.OnTabChecked_True(this.selectedTab);
      }

    });

  }
  OnTabChecked_True(tab){
    var _inputElement : any
    if(tab ==1){
      this.IsDiagonasisopen = true;
      this.Istreatmentmodalityopen = false;
      this.IsGoalsinfoopen = false;
      this.IsPresentingProblemopen = false;
      this.Istreatmentgoalconfigopen = false;
      this.Isconsentopen = false;
      _inputElement  = document.getElementById('tab1'); 
      _inputElement.checked = true;
      console.log(_inputElement,"tab1");
    }else if( tab ==2){
       //this.Istreatmentmodalityopen = true;
      this.IsDiagonasisopen = false;
      this.Istreatmentmodalityopen = true;
      this.IsGoalsinfoopen = false;
      this.IsPresentingProblemopen = false;
      this.Istreatmentgoalconfigopen = false;
      this.Isconsentopen = false;
      _inputElement  = document.getElementById('tab2');
      _inputElement.checked = true;
    }else if(tab ==3){
      //this.IsGoalsinfoopen = true;
      this.IsDiagonasisopen = false;
      this.Istreatmentmodalityopen = false;
      this.IsGoalsinfoopen = true;
      this.IsPresentingProblemopen = false;
      this.Istreatmentgoalconfigopen = false;
      this.Isconsentopen = false;
      _inputElement  = document.getElementById('tab3');
      _inputElement.checked = true;
    }else if(tab ==4){
      // this.router.navigate(['/treatment-presentingproblems'] , { queryParams: { patientAccountId: this.commonService.FrontEndEncryption(this.patientAccountId),chmaTreatmentSummaryId : this.commonService.FrontEndEncryption(this.chmaTreatmentSummaryId) } } );
      // 'app-treatment-presenting-problems'
       //this.IsPresentingProblemopen = true;
      this.IsDiagonasisopen = false;
      this.Istreatmentmodalityopen = false;
      this.IsGoalsinfoopen = false;
      this.IsPresentingProblemopen = true;
      this.Istreatmentgoalconfigopen = false;
      this.Isconsentopen = false;
      _inputElement  = document.getElementById('tab4');
      _inputElement.checked = true;
    }else if(tab ==5){
      //this.Istreatmentgoalconfigopen = true;
      this.IsDiagonasisopen = false;
      this.Istreatmentmodalityopen = false;
      this.IsGoalsinfoopen = false;
      this.IsPresentingProblemopen = false;
      this.Istreatmentgoalconfigopen = true;
      this.Isconsentopen = false;
      _inputElement  = document.getElementById('tab5');
      _inputElement.checked = true;
    }else if(tab ==6){
       //this.Isconsentopen = true;
      this.IsDiagonasisopen = false;
      this.Istreatmentmodalityopen = false;
      this.IsGoalsinfoopen = false;
      this.IsPresentingProblemopen = false;
      this.Istreatmentgoalconfigopen = false;
      this.Isconsentopen = true;
      _inputElement  = document.getElementById('tab6');
      _inputElement.checked = true;
    }
  }
  onNutritional(item) {
    this.router.navigate(['/intake-nutritional'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId)} });
  }
  onGenerateIntake(item) {
    this.router.navigate(['/intake-generate'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId),patientid: this.commonService.FrontEndEncryption(item.patientInfoId),isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } });
  }
  onintakechma(item) {
    this.router.navigate(['/intake-chma'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId),patientid: this.commonService.FrontEndEncryption(item.patientInfoId),isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } });
  }

  onPainAssessment(item) {
    this.router.navigate(['/pain-assessment'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId) } });
  }
  ontreatmenthistory(item) {
    this.router.navigate(['/treatment-history'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId) } });
  }
  onTreatmentPlan(item) {
    this.router.navigate(['/treatmentplan'], { queryParams: { id: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.patientAccountId),patient:this.commonService.FrontEndEncryption(item.patientInfoId), location: this.commonService.FrontEndEncryption(this.LocationId) } });
  }
  onbacktoMytask(){
    if ((this.global?.ismytask == true) && this.global != undefined) {
      window.close();
    }
    else {
      if(this.pageFrom == 1)
    {
      this.router.navigateByUrl('/dashboard');
    }else{
      this.router.navigateByUrl('/clinician');
    }
    }

  }
  newMTP()
  {
    this.isShowNewMTP = true;
    this.getMultipleTreatmentPlan();
  }
  getMultipleTreatmentPlan()
  {
    // this.spinnerservice.show();
    this.treatmentservice.getMultipleTreatmentPlan(this.patientAccountId).subscribe(
      (res: any) => {
        // this.spinnerservice.hide();
        if (res != null) {
          this.treatmentPlanMultiple=res;

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  closePopup(){
    this.isShowNewMTP = false;
    this.treatmentPlanDate = '';
    this.primaryClinicianId = '';
    this.overarchingPeriod = '';
    this.secondaryClinicianId = '';
    this.treatmentPlanDateSecondary ='';
    this.overarchingPeriodSecondary = '';
    this.MTPName = "";
    this.isDateValidate = false;
    this.isDateValidate1 = false;
  }
  errormsgChange(){
    this.isprimaryClinician = false;
    this.isClinicianSame = false;

  }
  private mtpValidation(treatmentPlanDate,overarchingPeriod):boolean{
    let isError = false;
    this.treatmentPlanMultiple.forEach(x=>{
      if((new Date(x.treatmentPlanDate) <= new Date(treatmentPlanDate) &&  new Date(treatmentPlanDate) <= new Date(x.overarchingPeriod) 
        || new Date(x.treatmentPlanDate) <= new Date(overarchingPeriod) &&  new Date(overarchingPeriod) <= new Date(x.overarchingPeriod) 
        || new Date(treatmentPlanDate) <= new Date(x.treatmentPlanDate) &&  new Date(x.overarchingPeriod) <= new Date(overarchingPeriod) ))
      {
        this.snackbar.error("The MTP ("+ x.mtpname +") is already scheduled from "+  moment(x.treatmentPlanDate).format("MM/DD/YYYY")+" to "+  moment(x.overarchingPeriod).format("MM/DD/YYYY"));
        isError = true;
      }
    });
    return isError;
  }
  private mtpValidationEdit(treatmentPlanDate,overarchingPeriod):boolean{
    let isError = false;
    this.treatmentPlanMultiple.forEach(x=>{
      if( (this.chmaTreatmentSummaryId != x.chmatreatmentSummaryId ) && (new Date(x.treatmentPlanDate) <= new Date(treatmentPlanDate) &&  new Date(treatmentPlanDate) <= new Date(x.overarchingPeriod) 
        || new Date(x.treatmentPlanDate) <= new Date(overarchingPeriod) &&  new Date(overarchingPeriod) <= new Date(x.overarchingPeriod) 
        || new Date(treatmentPlanDate) <= new Date(x.treatmentPlanDate) &&  new Date(x.overarchingPeriod) <= new Date(overarchingPeriod) ))
      {
        this.snackbar.error("The MTP ("+ x.mtpname +") is already scheduled from "+  moment(x.treatmentPlanDate).format("MM/DD/YYYY")+" to "+  moment(x.overarchingPeriod).format("MM/DD/YYYY"));
        isError = true;
      }
    });
    return isError;
  }
  onDateChange() {
    this.istreatmentPlanDate = false;
    console.log("as")
    
    if (this.treatmentPlanDate != null && this.treatmentPlanDate != undefined && this.treatmentPlanDate != "") {
      //this.overarchingDate = moment(this.treatmentPlanDate).add(90, 'days').format('LL');
      this.overarchingPeriod = moment(this.treatmentPlanDate).add(90, 'days').format("YYYY-MM-DD HH:mm:ss");
      this.overarchingDateExist = false;
      this.checkValidation(this.treatmentPlanDate,this.overarchingPeriod,1);
    
      return this.overarchingPeriod;
    }
    else {
      return "";
    }
  }
  onDateChangeEdit() {
    this.istreatmentPlanDate = false;
    console.log("as")
    this.checkValidation(this.istreatmentPlanDate,this.overarchingDateEdit,3)
    if (this.treatmentPlanDateEdit != null && this.treatmentPlanDateEdit != undefined && this.treatmentPlanDateEdit != "") {
      //this.overarchingDate = moment(this.treatmentPlanDate).add(90, 'days').format('LL');
      this.overarchingDateEdit = moment(this.treatmentPlanDateEdit).add(90, 'days').format("YYYY-MM-DD HH:mm:ss");
      this.overarchingDateExist = false;
      return this.overarchingPeriod;
    }
    else {
      return "";
    }
    
  }
  onDateChangeSecondary() {
    this.istreatmentPlanDateSecondary = false;
    console.log("as")
    this.checkValidation(this.treatmentPlanDateSecondary,this.overarchingPeriodSecondary,1);
    if (this.treatmentPlanDateSecondary != null && this.treatmentPlanDateSecondary != undefined && this.treatmentPlanDateSecondary != "") {
      //this.overarchingDate = moment(this.treatmentPlanDate).add(90, 'days').format('LL');
      this.overarchingPeriodSecondary = moment(this.treatmentPlanDateSecondary).add(90, 'days').format("YYYY-MM-DD HH:mm:ss");
      this.overarchingDateExistSecondary = false;
      return this.overarchingPeriodSecondary;
    }
    else {
      return "";
    }
  }
  errormsgChangeSecondary(){
    this.isSecondaryClinician = false;
    this.isClinicianSame = false;
  }
  getClinicianDetails()
  {
    this.patientservice.getClinicianForAssignClinician(this.patientAccountId).subscribe(
      (res: any) => {
        if (res != null) {
          this.clinicianDetails = res;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  mtperrorchange()
  {
    this.isMTP = false;
  }
  onSaveChmadetails() {

    if(this.MTPName == undefined || this.MTPName  == "")
    {
      this.isMTP = true;
      return;
    }

    if (this.primaryClinicianId == "" || this.primaryClinicianId == undefined) {
      this.isprimaryClinician = true;
      return
    }
    

    if (this.treatmentPlanDate == "" || this.treatmentPlanDate == undefined) {
      this.istreatmentPlanDate = true;
      return
    }
    if (this.overarchingPeriod == "" || this.overarchingPeriod == undefined) {
      this.overarchingDateExist = true;
      return
    }
    if(this.primaryClinicianId == this.secondaryClinicianId)
    {
      this.isClinicianSame = true;
      return
    }
    if((this.secondaryClinicianId != "" && this.secondaryClinicianId != undefined)   || (this.treatmentPlanDateSecondary != "" && this.treatmentPlanDateSecondary != undefined) || (this.overarchingPeriodSecondary != "" && this.overarchingPeriodSecondary != undefined ))
    {
      if (this.secondaryClinicianId == "" || this.secondaryClinicianId == undefined ) {
        this.isSecondaryClinician = true;
        return
      }
      if (this.treatmentPlanDateSecondary == "" || this.treatmentPlanDateSecondary == undefined) {
        this.istreatmentPlanDateSecondary = true;
        return
      }
      if (this.overarchingPeriodSecondary == "" || this.overarchingPeriodSecondary == undefined) {
        this.overarchingDateExistSecondary = true;
        return
      }
    }
    const isError = this.mtpValidation(this.treatmentPlanDate, this.overarchingPeriod);
    if(isError)
    {
      return;
    }
    if(this.isDateValidate || this.isDateValidate1)
    {
      return
    }
    if (this.clinicalsummary == '' || this.clinicalsummary == undefined) {
      this.clinicalsummary = new dtoChmaTreatmentSummary();
    }
    this.clinicalsummary.primaryClinicianId = this.primaryClinicianId;
    this.clinicalsummary.treatmentPlanDate = this.treatmentPlanDate;
    this.clinicalsummary.overarchingPeriod = this.overarchingPeriod;
    this.clinicalsummary.secondaryClinicianId = this.secondaryClinicianId;
    this.clinicalsummary.treatmentPlanDateSecondary = this.treatmentPlanDateSecondary;
    this.clinicalsummary.overarchingPeriodSecondary = this.overarchingPeriodSecondary;
    this.clinicalsummary.createdBy = this.userId;
    this.clinicalsummary.updatedBy = this.userId;
    this.clinicalsummary.patientAccountId = Number(this.patientAccountId);
    this.clinicalsummary.mTPName = this.MTPName;
    // this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.clinicalsummary, false);
    this.patientservice.postChmaTreatmentSummaryDeatils(dataToEncrypt).subscribe(
      (res: any) => {
        // this.spinnerservice.hide();;
        this.snackbar.success(constantVariables.SuccessMessage.MTPSucess);
        this.chmaTreatmentSummaryId = JSON.parse(res).item2;
        
        this.router.navigate(['/treatmentplan'], 
        { queryParams: { id: this.commonService.FrontEndEncryption(this.chmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(this.patientAccountId),patient:this.commonService.FrontEndEncryption(this.patientInfoId) , location: this.commonService.FrontEndEncryption(this.LocationId) }}) .then(() => {
          window.location.reload();
        });;
        // const currentUrl = this.router.url;
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        //   this.router.navigate([currentUrl]);
        // });
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  generatePdf(){
    this.spinnerservice.show();
    this.commonService.getTreatmentPlanPdf(this.chmaTreatmentSummaryId ,this.userName).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  OnTreatmentEdit()
  {
    this.isTreatmentEdit = true;
    this.getMultipleTreatmentPlan();
  }
  closePopupTreatmentEdit()
  {
    this.isTreatmentEdit = false;
    this.isDateValidateEdit = false;
  }
  checkValidation(treatmentPlanDate,overarchingDate,dateValidate){
   
    if( new Date(overarchingDate) <= new Date(treatmentPlanDate))
    {
      if(dateValidate == 1)
      {
        this.isDateValidate = true;
      }else if(dateValidate == 2){
        this.isDateValidate1 = true;
      }else if(dateValidate == 3){
        this.isDateValidateEdit = true;
      }
      
    }else{
      if(dateValidate == 1)
      {
        this.isDateValidate = false;
      }else if(dateValidate == 2){
        this.isDateValidate1 = false;
      }else if(dateValidate == 3){
        this.isDateValidateEdit = false;
      }
    }
    
  }
  
  onEditChmadetails()
  {
    if (this.treatmentPlanDateEdit == "" || this.treatmentPlanDateEdit == undefined) {
      this.istreatmentPlanDate = true;
      return
    }
    if (this.overarchingDateEdit == "" || this.overarchingDateEdit == undefined) {
      this.overarchingDateExist = true;
      return
    }
    if(this.isDateValidateEdit)
    {
      return
    }
    const isError = this.mtpValidationEdit(this.treatmentPlanDateEdit, this.overarchingDateEdit);
    if(isError)
    {
      return;
    }
    let _obj = {
      chmaTreatmentSummaryId : this.chmaTreatmentSummaryId,
      treatmentPlanDate : this.treatmentPlanDateEdit,
      overarchingPeriod : this.overarchingDateEdit
    }
    // this.spinnerservice.show();
       const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
      this.patientservice.editChmaTreatmentSummaryDeatils(dataToEncrypt).subscribe(
        (res: any) => {
          // this.spinnerservice.hide();;
          this.snackbar.success(constantVariables.SuccessMessage.MTPUpdate);
          this.closePopupTreatmentEdit();
          this.getTreatmentPlandetails();
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
  
    
  }
}

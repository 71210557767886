export class ClinicalNotes {
    progressNotesId: number;
    patientAccountId: number;
    dayScheduleSubGroupId: number;
    grouptherapyId: number;
    notesType: number;
    clinicalTopics: string;
    clientsResponse: string;
    followUpPlan: string;
}

export class Medication {
    patientMedicationId: number;
    patientAccountId: number;
    medications: string;
    dosage: string;
    frequency: string;
    isMorning: boolean;
    isNoon: boolean;
    isEvening: boolean;
    isBedTime: boolean;
    isBeforemeal: boolean;
    isDelete: boolean;
}
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { TaskService } from 'src/app/common/pages/task/task.service';
import { Global } from 'src/app/global/global.model';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NoteComment } from 'src/app/_models/_Common/task';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { Library } from 'src/app/_models/_Common/notes';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
@Component({
  selector: 'app-library-dialog',
  templateUrl: './library-dialog.component.html',
  styleUrls: ['./library-dialog.component.scss']
})
export class LibraryDialogComponent implements OnInit {
  libraryNameId : any;
  isEdit : any;
  libraryName : any;
  newlibraryName: Library;
  roleAccess: any;
  constructor(public dialogRef: MatDialogRef<LibraryDialogComponent>,private snackbar: SnackBarService, public settingsService: SettingsService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService,private dialog: MatDialog, public spinnerservice: NgxSpinnerService, public global: Global,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public roleaccessservice:PageAccessService
    ) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Settings;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.newlibraryName= new Library();
    this.libraryNameId = this.data.libraryNameId;
    this.libraryName = this.data.libraryName;
    this.newlibraryName.libraryName = this.data.libraryName;
    this.isEdit = this.data.isEdit;
  }
  saveLibraryName(form: NgForm)
  {
    this.spinnerservice.show();
    // let object ={
    //   LibraryId : this.libraryNameId,
    //   libraryName : this.newlibraryName,
    // }
    this.newlibraryName.LibraryId = this.libraryNameId;
     const dataToEncrypt = this.commonService.DataToEncrypt(this.newlibraryName, false);
    this.settingsService.postLibraryName(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        form.resetForm();
        this.snackbar.success(JSON.parse(res).message);
        this.dialogRef.close();
      }
    },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error('Library name already exists');
      }
    );
  }

  resetnotesform()
  {
    this.newlibraryName.libraryName = '';
  }

}


<div class="top-btn-section clearfix mb-1 w-100" *ngIf="pageId == 1">    
  <div class="col-md-6 ml-auto mt-2 button-position pr-0">
      <!-- <button type="button" [routerLink]="['/patient']" class="btn primary-btn-outlined float-right mb-2"><i
              class="fas fa-angle-left pr-2"></i>Back</button> -->
              <button type="button" (click)="onBackClick()"  class="btn primary-btn-outlined float-right mb-2"><i
                class="fas fa-angle-left pr-2"></i>Back</button> 
  </div>
</div>
<div class="screen-note d-flex  mt-2">
  <div class="m-0"><span class="font-weight-bold mr-2 ">Directions:</span>{{lstIntakeSurvey.inTakeProgress.questionDirection}}</div>
  <div class="ml-auto font-weight-bold mr-4"> Client Name : {{patientName}}  </div>
</div>

<div class="top-btn-section clearfix mt-3">
  <h2 class="head-section mb-3 mt-0">Survey</h2>
  <div class="qustion-pagination">
    <span class="question-label mr-2">No of Questions</span>
    <div class="page-item page-arrow" (click)="scrollLeft()"><a class="page-link_new"><i class="fas fa-angle-left"></i>
        <div class="ripple-container"></div>
      </a>
    </div>
    <div class="pagination-custom" #divQuestionPG>
      <ul class="pagination pagination-info">
        <!-- <li class=""><span class="question-label">No of Questions</span></li> -->

        <!-- <li class="page-item" (click)="onClickPage(activePage - 1)"><a class="page-link_new"><i
            class="fas fa-angle-left"></i>
          <div class="ripple-container"></div>
        </a>
      </li> -->

        <ng-container
          *ngFor="let item of lstIntakeSurvey?.answers?.details ;let i=index;let first=first;let last=last;">
          <li class="page-item" [ngClass]="{'active': activePage === i,'completed':item.isAnswered}"
            (click)="onClickPage(i)"><a class="page-link">{{i+1}}</a></li>
        </ng-container>
        <!-- <li class=" page-item"  (click)="onClickPage(activePage + 1)"><a class="page-link_new"><i
            class="fas fa-angle-right"></i>
          <div class="ripple-container"></div>
        </a>
      </li> -->

      </ul>
    </div>
    <div class=" page-item page-arrow" (click)="scrollRight()"><a class="page-link_new"><i
          class="fas fa-angle-right"></i>
        <div class="ripple-container"></div>
      </a>
    </div>
  </div>
</div>

<div class="privew">

  <ng-container *ngFor="let item of lstIntakeSurvey?.answers?.details;let i=index">

    <div class="questionsBox" *ngIf="i==activePage">
      <form (ngSubmit)="frmsurvey.form.valid && onNextQuestion(frmsurvey,i,item.typeId)" id="frmsurvey"
        autocomplete="off" #frmsurvey="ngForm" novalidate>
        <div class="questions">{{item.question}}</div>

        <ul class="answerList" [ngClass]="{'disabled':pageId == 1}">
          <li *ngFor="let opt of item?.options;let idx=index;let last = last;">
            <div class="option-withtxt" *ngIf="item.typeId==queYesNo || item.typeId==queMCSA">
              <label>
                <input type="radio" name="queYesNo{{idx}}"  [checked]="opt.isAnswer == true ? true:false"
                  (click)="rbAnswer(i,idx)" required>
                {{opt.optionLabel}}
              </label>
              <!--<div class="option-text" *ngIf="item.iSquestionExplanation == 2">

                <textarea name="expnation_{{idx}}" id="" rows="4" maxlength="300" [(ngModel)]="opt.explanation" #explanation="ngModel"
                  placeholder="Enter Your Answer" class="w-100"></textarea>
              </div>-->

              <!-- <mat-error class="mat-form-field-subscript-wrapper " *ngIf="isReqRadio && last">Please select any option
              </mat-error> -->

            </div>
            <div class="option-withtxt" *ngIf="item.typeId==queMCMA">
              <label>
                <input type="checkbox" name="queMCMA{{idx}}" (click)="cbkAnswer(i,idx,$event)" [checked]="opt.isAnswer"
                  required>
                {{opt.optionLabel}}
              </label>

              <!--<div class="option-text" *ngIf="item.iSquestionExplanation == 2">
                <label> 
                  {{item.questionExplanation}}
                </label>
                <textarea name="expnation_{{idx}}" id="" rows="4" maxlength="300" [(ngModel)]="opt.explanation"
                  #explanation="ngModel" placeholder="Enter Your Answer" class="w-100"></textarea>
              </div>-->

              <!-- <mat-error class="mat-form-field-subscript-wrapper " *ngIf="isReqRadio  && last">Please select any option
              </mat-error> -->

            </div>
          </li>
          <li *ngIf="(item.iSquestionExplanation == 1) && item.typeId != queDescriptive && item.typeId != queRatings "><span
              class="w-100 extra-info">{{item.questionExplanation}}</span>
            <div class="w-100 extra-value">
              <textarea name="" id="" rows="4" [ngModelOptions]="{standalone: true}" maxlength="300"
                [(ngModel)]="item.explanation" #questionExplanation="ngModel" placeholder="Enter Your Answer"
                class="w-100"></textarea>
            </div>
          </li>
          <li *ngIf="(item.iSquestionExplanation == 2 && IsEnableExplainationYesOrNo == true) && item.typeId == queYesNo "><span
            class="w-100 extra-info">{{item.questionExplanation}}</span>
          <div class="w-100 extra-value">
            <textarea name="" id="" rows="4" [ngModelOptions]="{standalone: true}" maxlength="300"
              [(ngModel)]="item.explanation" #questionExplanation="ngModel" placeholder="Enter Your Answer"
              class="w-100"></textarea>
          </div>
        </li>
        <li *ngIf="(item.iSquestionExplanation == 2 && IsEnableExplaination == true) && item.typeId == queMCSA "><span
          class="w-100 extra-info">{{item.questionExplanation}}</span>
        <div class="w-100 extra-value">
          <textarea name="" id="" rows="4" [ngModelOptions]="{standalone: true}" maxlength="300"
            [(ngModel)]="item.explanation" #questionExplanation="ngModel" placeholder="Enter Your Answer"
            class="w-100"></textarea>
        </div>
      </li>
        <li *ngIf="(item.iSquestionExplanation == 2 && IsEnableExplainationMCMA == true) && item.typeId == queMCMA"><span
          class="w-100 extra-info">{{item.questionExplanation}}</span>
        <div class="w-100 extra-value">
          <textarea name="" id="" rows="4" [ngModelOptions]="{standalone: true}" maxlength="300"
            [(ngModel)]="item.explanation" #questionExplanation="ngModel" placeholder="Enter Your Answer"
            class="w-100"></textarea>
        </div>
      </li>
     
      </ul>



        <ul class="answerList" *ngIf="item.typeId==queDescriptive" [ngClass]="{'disabled':pageId == 1}">
          <li *ngFor="let opt of item?.options;let idx=index">
            <div class="w-100 extra-value">
              <span class="w-100 extra-info">Descriptive</span>

              <textarea name="" id="" rows="4" class="w-100" [(ngModel)]="opt.description" #explanation="ngModel"
                name="explanation"></textarea>
              <!-- <mat-error class=" mt-4 mb-1 pt-1  mat-form-field-subscript-wrapper "
                *ngIf="frmsurvey.submitted && explanation.invalid">
                <mat-error *ngIf="explanation.errors.required">Descriptive is required
                </mat-error>
              </mat-error> -->
            </div>
          </li>
        </ul>
        <ul class="answerList" *ngIf="item.typeId==queRatings" [ngClass]="{'disabled':pageId == 1}">
          <li *ngFor="let opt of item?.options;let idx=index">
            <div class="rating-get mt-3 mb-3">
              <a title="poor" class="rate-numb" [class.active]="opt.rating == 1"
                (click)="onrating(i,idx,1)"><span>1</span></a>
              <a class="rate-numb" [class.active]="opt.rating == 2" (click)="onrating(i,idx,2)"><span>2</span></a>
              <a class="rate-numb" [class.active]="opt.rating == 3" (click)="onrating(i,idx,3)"><span>3</span></a>
              <a class="rate-numb" [class.active]="opt.rating == 4" (click)="onrating(i,idx,4)"><span>4</span></a>
              <a title="Excellent" class="rate-numb" [class.active]="opt.rating == 5"
                (click)="onrating(i,idx,5)"><span>5</span></a>
            </div>
          </li>
          <li *ngIf="item.iSquestionExplanation == 1 && item.typeId != queDescriptive "><span
              class="w-100 extra-info">{{item.questionExplanation}}</span>
            <div class="w-100 extra-value">

              <textarea name="" id="" rows="4" [ngModelOptions]="{standalone: true}" maxlength="300"
                [(ngModel)]="item.explanation" #questionExplanation="ngModel" placeholder="Enter Your Answer"
                class="w-100"></textarea>
            </div>
          </li>
          <mat-error class="mat-form-field-subscript-wrapper " *ngIf="isReqRating">Rating is required
          </mat-error>
        </ul>
        <div class="questionsRow">
          <span>{{activePage+1}} of {{totalquestions}}</span>


          <div class="d-inline-block ml-auto" *ngIf="pageId != 1">
            <button type="button" class="greyButton" id="skipquestions" *ngIf="i > 0"
              (click)="onPrevQuestion()">Previous</button>
            <button type="submit" class="greyButton" id="nextquestions" *ngIf="i < questionsIndex">Next</button>
            <button type="submit" class="greyButton" id="nextquestions" *ngIf="i == questionsIndex">Save</button>
          </div>
          <div class="d-inline-block ml-auto" *ngIf="pageId == 1">
            <button type="button" class="greyButton" id="skipquestions" *ngIf="i > 0"
              (click)="onPrevQuestion()">Previous</button>
            <button type="button" (click)="onPreviewNextQuestion()" class="greyButton" id="nextquestions"
              *ngIf="i < questionsIndex">Next</button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>

</div>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-2 pr-0">
      <!-- Tabs nav -->
      <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
        aria-orientation="vertical">

        <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Lead Settings" (click)="onTabChange(1)"
          [ngClass]="{'active': selectedTabIndex == 1}">
          <i class="fas fa-graduation-cap mr-2"></i>
          <span class="label-settings small text-uppercase">Lead Settings</span></a>

        <!-- <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Facility Settings" (click)="onTabChange(2)"
          [ngClass]="{'active': selectedTabIndex == 2}">
          <i class="fas fa-house-user mr-2"></i>
          <span class="label-settings small text-uppercase">Facility Settings</span></a> -->
        <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Location" (click)="onTabChange(3)"
          [ngClass]="{'active': selectedTabIndex == 3}">
          <i class="fas fa-map-marker-alt mr-2"></i>
          <span class="label-settings small text-uppercase">Location/Tour</span></a>
        <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Meeting Settings" (click)="onTabChange(4)"
          [ngClass]="{'active': selectedTabIndex == 4}">
          <i class="fas fa-handshake mr-2"></i>
          <span class="label-settings small text-uppercase">Meeting Settings</span></a>
        <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Image Library" (click)="onTabChange(5)"
          [ngClass]="{'active': selectedTabIndex == 5}">
          <i class="fas fa-map-marker-alt mr-2"></i>
          <span class="label-settings small text-uppercase">Image Library</span></a>
        <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Phone Book" (click)="onTabChange(6)"
          [ngClass]="{'active': selectedTabIndex == 6}">
          <i class="fas fa-address-book mr-2"></i>
          <span class="label-settings small text-uppercase">Phone Book</span></a>
          <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Block Time" 
          (click)="onTabChange(7)"
          [ngClass]="{'active': selectedTabIndex == 7}">
          <i class="fas fa-clock mr-2"></i>
          <span class="label-settings small text-uppercase">Block Time</span></a>
          <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Authorization Settings" 
          (click)="onTabChange(8)"
          [ngClass]="{'active': selectedTabIndex == 8}">
          <i class="fas fa-clock mr-2"></i>
          <span class="label-settings small text-uppercase">Authorization Settings</span></a>
          <a class="nav-link mb-2 p-3 shadow cursor-pointer" title="Audit Settings" 
          (click)="onTabChange(9)"
          [ngClass]="{'active': selectedTabIndex == 9}">
          <i class="fas fa-clock mr-2"></i>
          <span class="label-settings small text-uppercase">Audit Settings</span></a>
      </div>
    </div>


    <div class="col-md-10">
      <!-- Tabs content -->
      <div class="tab-content" id="v-pills-tabContent">
        <div class="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-home" role="tabpanel"
          aria-labelledby="v-pills-home-tab" *ngIf="selectedTabIndex == 1">
          <mat-tab-group>
            <mat-tab label="Source">
              <app-masters [pageid]="1"></app-masters>
            </mat-tab>
            <mat-tab label="Lead Type">
              <app-masters [pageid]="2"></app-masters>
            </mat-tab>
            <!-- <mat-tab label="Facility">
              <app-masters [pageid]="3"></app-masters>
            </mat-tab> -->
            <!-- <mat-tab label="lead Stage">
              <app-masters [pageid]="4"></app-masters>
            </mat-tab> -->
            <mat-tab label="Grade">
              <app-masters [pageid]="5"></app-masters>
            </mat-tab>
          </mat-tab-group>

        </div>

        <div class="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-profile" role="tabpanel"
          aria-labelledby="v-pills-profile-tab" *ngIf="selectedTabIndex == 2">
          <app-masters [pageid]="3"></app-masters>
        </div>
        <div class="tab-pane fade shadow rounded bg-white show active p-3" role="tabpanel"
          *ngIf="selectedTabIndex == 3">
          <app-sfcc-location></app-sfcc-location>
        </div>
        <div class="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-messages" role="tabpanel"
          aria-labelledby="v-pills-messages-tab" *ngIf="selectedTabIndex == 4">
          <mat-tab-group>
            <mat-tab label="Appointment Types">
              <app-meeting-appointment></app-meeting-appointment>
            </mat-tab>
            <mat-tab label="Meeting Room">

              <app-meeting-location></app-meeting-location>
            </mat-tab>
            <!-- <mat-tab label="Block">
              <app-meeting-blocks></app-meeting-blocks>
             
            </mat-tab> -->

          </mat-tab-group>

        </div>
        <div class="tab-pane fade shadow rounded bg-white show active p-3" role="tabpanel"
          *ngIf="selectedTabIndex == 5">
          <app-image-library></app-image-library>
        </div>
        <div class="tab-pane fade shadow rounded bg-white show active p-3" role="tabpanel"
          *ngIf="selectedTabIndex == 6">
          <app-phone-book></app-phone-book>
        </div>
        <div class="tab-pane fade shadow rounded bg-white show active p-3" role="tabpanel"
        *ngIf="selectedTabIndex == 7">
        <app-block-time></app-block-time>
      </div>
      <div class="tab-pane fade shadow rounded bg-white show active p-3" role="tabpanel"
        *ngIf="selectedTabIndex == 8">
       <app-authorization-settings-master></app-authorization-settings-master>
      </div>
      <div class="tab-pane fade shadow rounded bg-white show active p-3" role="tabpanel"
      *ngIf="selectedTabIndex == 9">
     <app-audit-settings-master></app-audit-settings-master>
    </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="settings">
  <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Appointment settings">
     <app-meeting-appointment></app-meeting-appointment>
    </mat-tab>
    <mat-tab label="Location settings">
      <app-meeting-location></app-meeting-location>     
    </mat-tab>
  </mat-tab-group>
</div> -->
<script src="https://ajax.googleapis.com/ajax/libs/jquery/1.5.1/jquery.min.js"></script>
<link href='https://fonts.googleapis.com/css?family=Liu+Jian+Mao+Cao' rel='stylesheet' type='text/css'>
<link href='https://fonts.googleapis.com/css?family=Dancing+Script' rel='stylesheet' type='text/css'> 
<link href='https://fonts.googleapis.com/css?family=Ma+Shan+Zheng' rel='stylesheet' type='text/css'>

<div class=" e-sign_tabs">
<canvas #canvas width="400" height="150" style="border:1px solid #d3d3d3;" hidden></canvas>
<div mat-dialog-content required >
  <img hidden src='{{ signatureImg }}' />
  
</div>
<mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChanged($event)">
  <mat-tab label="Type" >
    <div class="example-large-box" >
      <div class="col-md-12">
        <label class="form-label m-0 mt-2">Signature</label>
        <mat-form-field class="w-100 signature-padnew mt-2">         
          <input matInput placeholder="Type Name" appAlphabetOnly maxlength="25" (keypress)="omitspecialchar($event)" [(ngModel)]="TypeName" name="TypeName" required>
        </mat-form-field>
        <div class=" mt-2 selectable-options" *ngIf="TypeName !=''">
          <div class="radio-group" id="divType" required>
            <label class="radio-label">
                <input name="snacks" value="Dancing Script" (change)="onfontview($event)" type="radio" id="pizza" ngModel>
                <span class="inner-label" style="font-family:Dancing Script;"> {{TypeName}}</span>
            </label>
            <label class="radio-label">
                <input name="snacks" value="Liu Jian Mao Cao" (change)="onfontview($event)" type="radio" id="nachos" ngModel>
                <span class="inner-label" style="font-family:Liu Jian Mao Cao;"> {{TypeName}}</span>
            </label>
            <label class="radio-label">
                <input name="snacks" value="Ma Shan Zheng" (change)="onfontview($event)" type="radio" id="burgers" ngModel>
                <span class="inner-label" style="font-family:Ma Shan Zheng;"> {{TypeName}}</span>
            </label>
        </div>
      </div>
      </div>
     </div>
  </mat-tab>
  <mat-tab label="Draw">
    <div class="col-md-12">
    <label class="form-label m-0 mt-2 mb-2">Draw a Signature</label>
    <div  class="w-100 sign-draw" id="divDraw" required>
      <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
     </div>
  </div>
  </mat-tab>
  <mat-tab label="Upload">
    <div class="col-md-12" id="divUpload">
      <form method="post" action="#" id="#">              
        <div class="form-group files" required>
          <label class="form-label m-0 mt-2 mb-2">Upload Your File </label>             
          <input accept="image/*" type='file' class="form-control" #esignupload (change)="handleInputChange($event)" required>
        </div>
    </form>
     </div>
  </mat-tab>
</mat-tab-group>
<mat-dialog-actions align="end">              
  <button type="submit" title="Clear" class="btn primary-btn-outlined clear-btn" (click)="clearSignature()"  mat-button> Clear </button>
  <button type="submit" title="Save" [disabled]="!submitForm || (TypeName == ''&& tabname == 'Type')" class="btn primary-btn-filled" [mat-dialog-close]="signatureImg" cdkFocusInitial (click)="savePad()" mat-button> Save </button>  
  <button mat-button mat-dialog-close class="btn btn-cancel">Cancel</button>
</mat-dialog-actions> 
</div>


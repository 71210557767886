<div class="top-btn-section clearfix" >
   
   <h2 class="head-section mb-2 mt-0">Authorization Details</h2> 
  </div> 
<div class="discharge-lst create-patients">
  <div *ngIf="roleAccess.isView || roleAccess.isCreate">
    <div class="d-flex w-100 ">
       
      <div class="col-md-4 text-right">
          <div  class="search">
              <!-- <input  type="text" placeholder="Search By Client Name" name="" (keyup)="userFilter($event.target.value)" class="search-input " > -->
              <input  type="text" placeholder="Search" name="" (keyup)="userFilter($event.target.value)" class="search-input " >
              <a  class="search-icon"><i  class="fa fa-search"></i></a></div>
      </div>
      <div class="col-md-8 text-right">
       
        <button type="button" class="btn primary-btn-outlined float-right mr-2" (click)="OnBackClick()"><i class="fas fa-angle-left pr-2"></i>Back</button>
        <button type="button" class="btn primary-btn-outlined float-right mr-2" (click)="downloadExcel()">Export</button>
        <div class="mr-2 mt-2">
          <mat-radio-group aria-label="Select an option" class="mr-2" [(ngModel)]="selectedValue"  (change)="onFilterChange()"> 
            <mat-radio-button class="mr-2" value="1">All</mat-radio-button>
            <mat-radio-button  class="mr-2" value="2">Expired</mat-radio-button>
          </mat-radio-group>
        </div>
            </div>
  </div>

  <div class="col-md-12 table-custom table-expired p-0 mb-2" >
    <div class="table-responsive">
      <mat-table class="lessons-table mat-elevation-z8 w-100" [dataSource]="dataSource">

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> S.No </mat-header-cell>
          <mat-cell  *matCellDef="let authDetails;let i = index;" [style.color]="(authDetails.IsDateExpiredAlready || authDetails.IsVisitExpiredAlready) ? 'red' : 'grey'">{{i+1}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="clientName">
          <mat-header-cell *matHeaderCellDef>Client Name</mat-header-cell>
          <mat-cell class="description-cell" *matCellDef="let authDetails" [style.color]="(authDetails.IsDateExpiredAlready || authDetails.IsVisitExpiredAlready) ? 'red' : 'grey'">{{authDetails.PatientName}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef>Location</mat-header-cell>
          <mat-cell class="description-cell" *matCellDef="let authDetails" [style.color]="(authDetails.IsDateExpiredAlready || authDetails.IsVisitExpiredAlready) ? 'red' : 'grey'">{{authDetails.LocationName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="authorizationNumber">
          <mat-header-cell *matHeaderCellDef>Authorization Number</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let authDetails" [style.color]="(authDetails.IsDateExpiredAlready || authDetails.IsVisitExpiredAlready) ? 'red' : 'grey'">{{authDetails.AuthorizationNumber}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="authorizationType">
          <mat-header-cell *matHeaderCellDef>Authorization Type</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let authDetails" [style.color]="(authDetails.IsDateExpiredAlready || authDetails.IsVisitExpiredAlready) ? 'red' : 'grey'">{{authDetails.AuthorizationType}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="insurance">
          <mat-header-cell *matHeaderCellDef>Insurance</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let authDetails" [style.color]="(authDetails.IsDateExpiredAlready || authDetails.IsVisitExpiredAlready) ? 'red' : 'grey'">{{authDetails.Insurance}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="visitsAllowded">
          <mat-header-cell *matHeaderCellDef>Visits Allowded</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let authDetails" [style.color]="(authDetails.IsDateExpiredAlready || authDetails.IsVisitExpiredAlready) ? 'red' : 'grey'">{{authDetails.NumberOfVisits}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="visitsCompleted">
          <mat-header-cell *matHeaderCellDef>Visits Completed</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let authDetails" [style.color]="(authDetails.IsDateExpiredAlready || authDetails.IsVisitExpiredAlready) ? 'red' : 'grey'">{{authDetails.NoOfVisits}}
          </mat-cell>
        </ng-container>
        

        <ng-container matColumnDef="utilized">
            <mat-header-cell *matHeaderCellDef>%  Utilized</mat-header-cell>
            <mat-cell class="duration-cell" *matCellDef="let authDetails" [style.color]="(authDetails.IsDateExpiredAlready || authDetails.IsVisitExpiredAlready) ? 'red' : 'grey'">{{authDetails.VisitUtilized}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expiringIn">
            <mat-header-cell *matHeaderCellDef>Expiring In</mat-header-cell>
            <mat-cell class="duration-cell" *matCellDef="let authDetails" [style.color]="(authDetails.IsDateExpiredAlready || authDetails.IsVisitExpiredAlready) ? 'red' : 'grey'">
              {{(authDetails.IsDateExpiredAlready != 1 ) ? ((authDetails.DateCount != 0) ? (authDetails.IsDateExpired == 1 ? authDetails.PriorValueExpiry +  ' days':authDetails.DateCount +(authDetails.DateCount == 1 ?  ' day' : ' days') ) : (authDetails.EndDate | date: 'MM/dd/yyyy')) : 'Expired'}}
              <!-- {{row.isActive == 1 ? 'Active' : 'InActive'}} -->
              <!-- {{authDetails.EndDate | date: 'MM/dd/yyyy'}} -->
            </mat-cell>
          </ng-container>

        <ng-container matColumnDef="Actions">
          <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let customer">
            <button type="button" mat-icon-button matTooltip="Edit" role="button" class="cursor-pointer" type="button" (click)="editAuthorization(customer)"
              color="primary" *ngIf="roleAccess.isEdit">
              <img src="../../../assets/images/editblue.png">
            </button>
            <button type="button" mat-icon-button matTooltip="View" type="button" role="button"  class="cursor-pointer"    
              (click)="onViewActivity(customer)" color="warn"  >
              <img src="../../../assets/images/eye.png">
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[ 10, 20, 50, 75, 100]" showFirstLastButtons></mat-paginator>
      
    </div>
    
  </div>
  </div>
  <div  *ngIf="!roleAccess.isView">
    <div class="msg-nomeet text-center">You do not have privilege to access this. Please contact admin</div>
</div>
  </div>
 
  <div class="comment-popup authorization-popup auth-popup">
    <div class="modal" tabindex="-1" role="dialog" id="authorization" [style.display]="isShowEdit ? 'block' : 'none'">
      <div class="modal-dialog mat-dialog-container " role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title font-weight-bold">EDIT AUTHORIZATION</h6>
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="onClose()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
  <div >
    <form (ngSubmit)="insAuthorizationEdit.form.valid && PostInsAuthorization()"  id="insAuthorizationEdit"
    autocomplete="off" #insAuthorizationEdit="ngForm" novalidate>
    <div class="col-md-12">
        <div class="row mb-2">
          <div class="col-md-4 form-group">
                <label class="control-label-new font-weight-bold">Client Name</label>
        
        <input class="form-control mb-2"   maxlength="50" [disabled]="true"
        [(ngModel)]="authDetailsEdit.PatientName" #PatientName="ngModel" name="PatientName"
        >
          </div>
          <div class="col-md-4 form-group">
            <label class="control-label-new font-weight-bold">Location</label>
    
    <input class="form-control mb-2"   maxlength="50" [disabled]="true"
    [(ngModel)]="authDetailsEdit.LocationName" #LocationName="ngModel" name="LocationName"
    >
      </div>
          <div class="col-md-4 form-group">
                <label class="control-label-new font-weight-bold">Insurance</label>
                <select class="form-control mb-2"  [(ngModel)]="authDetailsEdit.InsuranceDetailsId" #InsuranceDetailsId="ngModel" name="InsuranceDetailsId"
                [ngClass]="{ 'is-invalid': insAuthorizationEdit.submitted && InsuranceDetailsId.invalid }" >
                <option *ngFor="let item of ddlInsuranceList" [value]="item.insuranceDetailsId" >{{item.carrierName}}</option>
              </select>
                <mat-error *ngIf="insAuthorizationEdit.submitted && InsuranceDetailsId.invalid">
                  <mat-error *ngIf="InsuranceDetailsId.errors.required">Insurance is required</mat-error>
                </mat-error>
          </div>
          
          <div class="col-md-4 form-group">
            <label class="control-label-new font-weight-bold">Authorization Number</label>
            <input class="form-control mb-2"   maxlength="50" (keypress)="alphanumericwithhypen($event)"
            [(ngModel)]="authDetailsEdit.AuthorizationNumber" #AuthorizationNumber="ngModel" name="AuthorizationNumber"
            [ngClass]="{ 'is-invalid': insAuthorizationEdit.submitted && AuthorizationNumber.invalid }"
            >
            <mat-error *ngIf="insAuthorizationEdit.submitted && AuthorizationNumber.invalid">
              <mat-error *ngIf="AuthorizationNumber.errors.required">Authorization Number is required</mat-error>
            </mat-error>
          </div>
          <div class="col-md-4 form-group">
                <label class="control-label-new font-weight-bold">Authorization Type</label>
                <select class="form-control mb-2"  [(ngModel)]="authDetailsEdit.AuthorizationTypeId" #AuthorizationTypeId="ngModel" name="AuthorizationTypeId"
                [ngClass]="{ 'is-invalid': insAuthorizationEdit.submitted && AuthorizationTypeId.invalid }" >
                  <option *ngFor="let item of AuthorizationTypeddl" [value]="item.authorizationTypeId" >{{item.authorizationType1}}</option>
                </select>
                <mat-error *ngIf="insAuthorizationEdit.submitted && AuthorizationTypeId.invalid">
                  <mat-error *ngIf="AuthorizationTypeId.errors.required">Authorization Type is required</mat-error>
                </mat-error>
          </div>
          <div class="col-md-4 form-group">
            <label class="control-label-new font-weight-bold">CPT/HCPCS Code</label>
            <input  class="form-control mb-2"  maxlength="10" (keypress)="alphanumeric($event)"
          [(ngModel)]="authDetailsEdit.CptHcpcs" #CptHcpcs="ngModel" name="CptHcpcs"
          [ngClass]="{ 'is-invalid': insAuthorizationEdit.submitted && CptHcpcs.invalid }"
          >
          <mat-error *ngIf="insAuthorizationEdit.submitted && CptHcpcs.invalid">
            <mat-error *ngIf="CptHcpcs.errors.required">CPT/HCPCS Code is required</mat-error>
          </mat-error>
      </div>
      <div class="col-md-4 form-group">
        <mat-form-field class="example-full-width w-100 ">
          <mat-label>Effective Date</mat-label>
          <input   matInput [matDatepicker]="stpicker" readonly
          [(ngModel)]="authDetailsEdit.StartDate" #StartDate="ngModel"
          name="StartDate" >
          <mat-datepicker-toggle matSuffix [for]="stpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #stpicker></mat-datepicker>
          <mat-error *ngIf="insAuthorizationEdit.submitted && StartDate.invalid">
            <mat-error *ngIf="StartDate.errors.required">Effective Date is required</mat-error>
          </mat-error>
      </mat-form-field>
  </div>
  <div class="col-md-4 form-group">
    <mat-form-field class="w-100">
      <mat-label>Expiration Date</mat-label>
      <input   matInput [matDatepicker]="edpicker" readonly 
      [(ngModel)]="authDetailsEdit.EndDate" #EndDate="ngModel"
      name="EndDate" >
      <mat-datepicker-toggle matSuffix [for]="edpicker">
      </mat-datepicker-toggle>
      <mat-datepicker #edpicker></mat-datepicker>
      <!-- <mat-error *ngIf="insAuthorizationEdit.submitted && EndDate.invalid">
        <mat-error *ngIf="EndDate.errors.required">Expiration Date is required</mat-error>
      </mat-error> -->

  </mat-form-field>
</div>
<div class="col-md-4 form-group">
    <label class="control-label-new font-weight-bold"># of Visits Allowed</label>
    <input  class="form-control mb-2"  placeholder="" step="0" maxlength="5" numeric
                                    [(ngModel)]="authDetailsEdit.NumberOfVisits" #NumberOfVisits="ngModel" name="NumberOfVisits"
                                     [ngClass]="{ 'is-invalid': insAuthorizationEdit.submitted && NumberOfVisits.invalid }"
                                     
                                       />
                                       <mat-error *ngIf="insAuthorizationEdit.submitted && NumberOfVisits.invalid">
                                        <mat-error *ngIf="NumberOfVisits.errors.required">Number of visits is required</mat-error>
                                      </mat-error>


</div>
<div class="col-md-4 form-group">
  <label class="control-label-new font-weight-bold">Track</label>
  <select class="form-control mb-2"  [(ngModel)]="authDetailsEdit.AuthorizationTrackId" #AuthorizationTrackId="ngModel" name="AuthorizationTrackId"
                        [ngClass]="{ 'is-invalid': insAuthorizationEdit.submitted && AuthorizationTrackId.invalid }" >
                          <option *ngFor="let item of drpAuthorizationTrack" [value]="item.AuthorizationTrackId" >{{item.AuthorizationTrackName}}</option>
                        </select>
                        <mat-error *ngIf="insAuthorizationEdit.submitted && AuthorizationTrackId.invalid">
                          <mat-error *ngIf="AuthorizationTrackId.errors.required">Authorization Track is required</mat-error>
                        </mat-error>
</div>
<div class="col-md-4 form-group">
  <label class="control-label-new font-weight-bold">Clinical Information Provided</label>
  <textarea class="form-control mb-2" [(ngModel)]="authDetailsEdit.ClinicalInformationProvider" maxlength="150"
                                [ngModelOptions]="{ standalone : true }"
                                    #information="ngModel"  maxlength="150" 
                                ></textarea>
</div>
        </div>
    
    </div>
    <div class="text-right col-md-12 mt-2">
      <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" type="submit"  >Update</button>
      <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="onClose()" >Cancel</button>
    </div>
  
  
  </form>
  </div>
  
  
  </div>
  </div>
  </div>
  </div>
  </div> 

  <div class="Activity-popup-view">
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      [style.display]="ShowActivity ? 'block' : 'none'"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">View</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="onCloseActivitylog()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="individual-threaphy-container">
              <div class="history-list col-md-12 p-0">
                <div *ngIf = "authLog?.length == 0">
                  <ul>
                
                    <li>
                      <div class="list-history col-md-14">
                        <div class="row">
                          
                          <div class="col-md-12">
                            <p class="sub-history mb-1"></p>
                            <p
                              class="sub-history2 mb-1"
                              
                            >No data found</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  
                  
                </div> 
                <ul>
                
                  <li *ngFor="let item of authLog; let i = index">
                    <div class="list-history col-md-14">
                      <div class="row">
                        
                        <div class="col-md-1">
                          <img
                            class="img-fluid"
                            src="../../../assets/images/move2stage-icon-green.png"
                          />
                        </div>
                        <div class="col-md-9">
                          <p class="sub-history mb-1"></p>
                          <p
                            class="sub-history2 mb-1"
                            innerHTML="{{item.message}}"
                          ></p>
                        </div>
                        <div class="col-md-2 text-right">
                          <p class="sub-history2 mb-1">
                            {{global.globalUtctimeformat(item.createdOn) |  date: 'MM/dd/yyyy'}}
                          </p>
                          <p class="sub-history2 mb-1">
                            {{global.globalUtctimeformat(item.createdOn) | date: 'hh:mm a' }}                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
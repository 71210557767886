export class PatientHistory {
    PatientHistoryId: number;
    PatientInfoId: number;
    SourceTypeId: number;
    SourceOthers: string;
    PhysicianName: string;
    PhysicianPhone: string;
    PhysicianHospitalName: string;
    PhysicianHospitalAddress: string;
    PsychiatristName: string;
    PsychiatristPhone: string;
    PsychiatristHospitalName: string;
    PsychiatristHospitalAddress: string;
    TherapistName: string;
    TherapistPhone: string;
    TherapistHospitalName: string;
    TherapistHospitalAddress: string;
    WorkDesc: string;
    GoalsDesc: string;
    NutritionDesc: string;
    IsPhysician: boolean;
    IsPsychiatrist: boolean;
    IsTherapist: boolean;
    IsCompleted: boolean;
    PhysicianDetails:   HospitalInfo;
    PsychiatristDetails: HospitalInfo
    TherapistDetails: HospitalInfo;
    NeurologistDetails: HospitalInfo;
    IsNeurologist:boolean;
    lastPhysicalExamDate:Date;
    IsCaseManager : boolean;
    CaseMangerDetails : HospitalInfo;
    PharmacyName :string;
    PharmacyAddress :string;
    PharmacyFax :string;
    PharmacyPhone :string
}
export class HospitalInfo{
    FirstName:string;
    LastName: string;
    Email: string;
    AddressLine1: string;
    AddressLine2: string;
    StatesId: number;
    CityId: number;
    ZipCode: string;
    PhoneNumber : string;
    CellPhone : string;
    FaxNumber : string;
    HospitalName : string;
    IsAggree : string;
}


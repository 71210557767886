 <div class="documents d-none">
        <div  class="col-md-12 p-0">
          <div  class="d-flex w-100">
            <div  class="col-md-3 p-0">
              <div  class="search">
                <input   >
                <a  class="search-icon">
                  <i  class="fa fa-search"></i>
                </a>
              </div>
            </div>
            <div  class="col-md-5 mt-2">
              <span  class="filter-icon mr-2"><i  class="fa fa-filter"></i></span>
              <div  class="filter-group d-inline-block">
                <label  class="d-inline-block mr-2">Status</label>
                <select  name="clinician" class="form-control d-inline-block status-filter mr-0 " [(ngModel)]="SelectedStatus" (change)="OnlistFilter(0)"  >
                  <option [value]="0" >Any</option>
                  <option [value]="1" >New</option>
                  <option [value]="2" >IOP</option>
                  <option [value]="3">PCP</option>
                  <option [value]="4">Outpatient</option>
                  <option [value]="5">Discharged</option>
                  </select>
                </div>
              
                </div>
                <div  class="ml-auto">
                  
                  <button   mat-stroked-button="" class=" bg-white top-back mt-1 mb-2 h-40 mr-2 " tabindex="0" >
                 Back</button>
                  </div>
                </div>
              </div>
              <div class="col-md-12 card ">
                <div class="doc_table table-responsive" >
                  <!-- <table class="table ">
                    <thead>
                      <tr>
                        
                        <th style="width:250px" class="  text-xs">
                          Patient Name
                        </th>
                        <th class="  text-xs">
                            Status
                        </th>
                      </tr>
                      
                    </thead>
                    <tbody>
                      <tr>
                        <td class="  text-xs"><a href="#">Aaron Aaiden</a></td>
                        <td class="  text-xs">Inprogress</td>
                      </tr>
                      <tr>
                        <td class="  text-xs"><a href="#">Aaron Aaiden</a></td>
                        <td class="  text-xs">Inprogress</td>
                      </tr>
                      <tr>
                        <td class="  text-xs"><a href="#">Aaron Aaiden</a></td>
                        <td class="  text-xs">Inprogress</td>
                      </tr>
                    </tbody>
                  </table> -->
                  <mat-table [dataSource]="dataSource" matSort> 
      
                    <ng-container matColumnDef="patientName" style="width: 50px;">
                      <mat-header-cell *matHeaderCellDef> Client Name </mat-header-cell>
                      <mat-cell *matCellDef="let row"><a [routerLinkActive]="['active']" (click)="onClickToDocument(row)"> {{row.FirstName}} {{row.LastName}}</a> </mat-cell>
                    </ng-container> 
                    <ng-container matColumnDef="status">
                      <mat-header-cell *matHeaderCellDef> Program </mat-header-cell>
                      <mat-cell *matCellDef="let row"> {{row.PatientStatusName}} </mat-cell>
                    </ng-container> 
                    <ng-container matColumnDef="clinicianName">
                      <mat-header-cell *matHeaderCellDef> Primary Clinician </mat-header-cell>
                      <mat-cell *matCellDef="let row"> {{row.ClinicianName}} </mat-cell>
                    </ng-container> 
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;">
                  </mat-row>
                </mat-table>  
                <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="5"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>   
                </div>
              </div>
       </div> 

<!--New Page-->
<div class="doc_patient document--patients">
  <div  class="col-md-12 ">
    <div  class="row">
      <div class="col-md-9 p-0">
        <h2  class="head-section mt-3">Document</h2>
      </div>
      <!-- <div  class="col-md-3 p-0">
        <button  tabindex="0" class="btn back-btn-new">
        <i class="fas fa-chevron-left mr-2"></i>Back</button>
      </div>  -->
</div>

</div>
<div class="col-md-12 p-0">
  <div class="row">
    <div class="col-md-3">
      <div class="card p-2 ">
        <div  class="search border mb-2">
          <input  type="text" placeholder="Search By Client " [(ngModel)]="searchpatientListtxt" name="txt" class="search-input " >
          <a  class="search-icon">
            <i  class="fa fa-search"></i>
          </a>
        </div>

      <div  class="tabs-panel"><h6 class="doc-head mb-0"> Client Name</h6><div  class="clear"></div></div>
      <ul class="folders patient-leftbar list-unstyled nav nav-tabs">
        <li *ngFor="let patient of PatientList | grdFilter:  searchpatientListtxt:'lstPatient'"> 
          <a data-toggle="tab" href="#home"  (click)="openDocumentSection(patient)">
            <div class="col-md-12">
            <div  class="row">
              <div  class="left-side col-md-2">
                <span  class="gender-img">
                  <i  class="fas fa-user mt-2"></i>
                </span>
              </div>
              <div  class="right-side col-md-10 pl-0">
                <div  class="text-md text-truncate font-weight-500" >{{patient.FirstName +' '+ patient.LastName}}</div>
            <div  class="text-xs text-truncate">{{patient.Email}}</div>
          </div>
        </div>
        </div>
       </a>
        </li>
      </ul>
    </div>
    </div>
    <div class="tab-content col-md-9 p-0">
      <div *ngIf="Opendocumentsection" id="home" class="tab-pane fade in active show">
        <div class="col-md-12 pl-0">
          <div class="card p-2 ">
            <div class="d-flex">
              <div class="">
                  <div class="d-inline-block">
                    <div class="ml-2"><span  class="status-patient text-md"> Program:</span>
                      <span  class="patient-info-value text-md text-dark ml-0">{{this.programname}}</span></div>
                  </div>
                  <!-- <div>
                    <ngx-doc-viewer
                    url="http://www.pdf995.com/samples/pdf.pdf"
                    viewer="pdf"
                    style="width:100%;height:93vh;"
                  >
                  </ngx-doc-viewer></div>-->
              </div> 
              <div class="ml-auto">
                <button  mat-type="button" class="export-pdf-btn btn mr-0" (click)="ShareAllDocuments()"><i  class="fa fa-share mr-2" ></i>Share All</button>
                <!-- <button  mat-type="button" class="export-pdf-btn btn mr-2"><i  class="fa fa-print mr-2"></i>Print All</button> -->
              </div>
             
            </div>
            <div class="col-md-12 p-0">
              <div class="row">
              <div class="col-md-12 p-0">
                <div class="col-md-12">
                  <div class="fixed-left-header">
                    <div class="tabs-panel">
                      <h6 class="doc-head d-none"><i class="fa fa-file mr-2"></i> Documents</h6>
                      
                      <div class="clear"></div>
                    </div>
                    <div class="panel-body tab-content">
                        <div class="tab-pane documents-panel active">
                           <div class="clear"></div>
                           <div class="v-spacing-xs"></div>
                           <h4 class="no-margin-top d-none"> Categories</h4>
                           <ul class="folders list-unstyled">
                            <li *ngIf="isIntakeDocumentExists" [class]="isIntakeDocuments ? 'active':''" > <a (click)="intakeClick()">  Intake  </a></li>
                            <!-- <li *ngIf="isTreatmentDocumentExists" [class]="isTreatmentDocument ? 'active':''"> <a (click)="treatmentDocumentClick()"> Treatment Plan  </a></li> -->
                            <!-- <li *ngIf="isSucidalRisk" [class]="this.isSusidalRiskDocumentExist  ? 'active':''"> <a (click)="susidalRiskDocumentClick()"> Suicidal  Risk </a></li> -->
                            <li *ngIf="isAppointmentDocumentExists1" [class]="isAppointmentDocument ? 'active':''"> <a (click)="appointmentDocumentClick()"> Appointment </a></li>
                            <li *ngIf="isOnboarding" [class]="this.isOnboardingExists1 ? 'active':''"> <a (click)="onboardingClick()"> Onboarding </a></li>
                            <li *ngIf="isFinancial" [class]="this.isFinancialExists1  ? 'active':''"> <a (click)="financialClick()"> Financial </a></li>
                           <!-- <li *ngIf="isConsent" [class]="this.isConsentExists  ? 'active':''"> <a (click)="ConsentClick()"> Consent Forms </a> </li> -->
                             <li  [class]="this.isConsentExists  ? 'active':''"> <a (click)="ConsentClick()"> Consent Forms </a> </li> 

                            <li *ngIf="isCommunication" [class]="this.isCommunicationExist ? 'active':''"> <a (click)="communicationClick()"> Communication </a></li>
                            <!-- <li *ngIf="isUploadedDocumentExists" [class]="this.isUploaded ? 'active':''"> <a (click)="uploadedDocumentClick()"> Uploaded </a></li> -->
                            <li  [class]="this.isUploaded ? 'active':''"> <a (click)="uploadedDocumentClick()"> Uploaded </a></li>
                          </ul>
                          
                         
                          <div class="v-spacing-xs"></div> 
                         
                        </div>
                        <div *ngIf="isUploaded">
                          <!-- <button 
                          class="export-pdf-btn btn mr-0 add_wdth"
                          
                          type="submit"
                        > -->
                          <i title="Add Documents" class="fas fa-file-upload i mar_right add-document" (click)="getDocuments(patientInfoId)"></i><span></span>
                        <!-- </button> -->
                        </div>
                    </div>
                        </div>
                      </div>
                      <div *ngIf="isIntakeDocuments">
                        <div class="col-md-12 tab-content float-left no-bg no-border" >
                          <div class="row">
                           
                            <div class="col-lg-12 col-md-12 col-sm-12 mb-2" >
                              <table class="table table-styles documenttable--fix">
                                 <thead>
                                    <tr>
                                      
                                      <td>S.No</td>
                                      <td>Document Name</td>
                                      <td>Document Link</td>
                                      <td>Created by</td>
                                      <td>Action</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let item of intakedocumentList; let i=index;">
                                      
                                      <td>{{i+1}}</td>
                                      <td>{{item.documentName}}</td>
                                      <td><a (click)="onGeneratePDFIntakeDownload(item,1)">{{item.documentLink}}</a></td>
                                      <td>{{this.createdby}}</td>
                                      <td>
                                       
                                        <div class="dropdown">
                                          <i class="fa fa-ellipsis-v dropdown-toggle cursor-pointer" id="menu1" data-toggle="dropdown"></i>
                                          <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                            <li role="presentation" class="w-100 float-left"><a (click)="onGeneratePDFIntakeDownload(item,0)" class="fil-names">Download</a></li>
                                            <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGeneratePDFIntakeDownload(item,1)">Preview</a></li>
                                            <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGeneratePDFIntakeDownload(item,1)">Print</a></li>
                                            <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'Intake')">Share</a></li>
                                          </ul>
                                        </div>
                                        
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody>
                                    <tr *ngFor="let item of lstDocuments; let i=index;">
                                      
                                      <td>{{i+ 1 + getlstDocuments()}}</td>
                                      <td>{{item?.documentName}}</td>
                                      <td><a (click)="OnDownloadSusidalRisk(item,1)">{{item.documentLink}}</a></td>
                                      <td>{{item.createdby}}</td>
                                      <td>
                                       <div class="dropdown">
                                         <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                                         <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                           <li role="presentation"  class="w-100 float-left"><a (click)="OnDownloadSusidalRisk(item,0)" class="fil-names">Download</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnDownloadSusidalRisk(item,1)" >Preview</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnDownloadSusidalRisk(item,1)">Print</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'SusidalRisk')">Share</a></li>
                                         </ul>
                                       </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody *ngIf="!painAssessment?.documentLink">
                                    <tr *ngFor="let item of painAssesmentDocument; let i=index;">
                                      
                                      <td>{{i+ 1 + getpainAssesmentDocument()}}</td>
                                      <td>{{item?.documentName}}</td>
                                      <td><a (click)="onGenerateTreatmentPlanDownload(item,1)">{{item.documentLink}}</a></td>
                                      <td>{{item.createdby}}</td>
                                      <td>
                                       <div class="dropdown">
                                         <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                                         <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                           <li role="presentation"  class="w-100 float-left"><a (click)="onGenerateTreatmentPlanDownload(item,0)" class="fil-names">Download</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGenerateTreatmentPlanDownload(item,1)" >Preview</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGenerateTreatmentPlanDownload(item,1)">Print</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'PainAssesment')">Share</a></li>
                                         </ul>
                                       </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody *ngIf="painAssessment?.documentLink && !pdfNutritionfile">
                                    <tr *ngFor="let item of painAssesmentDocument; let i=index;">
                                      
                                      <td>{{i+ 1 + getpainAssesmentDocument()}}</td>
                                      <td>{{item?.documentName}}</td>
                                      <td><a (click)="OnDownloadPdf()">{{item.documentLink}}</a></td>
                                      <td>{{item.createdby}}</td>
                                      <td>
                                       <div class="dropdown">
                                         <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                                         <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                           <li role="presentation"  class="w-100 float-left"><a (click)="OnDownloadPdf()" class="fil-names">Download</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnDownloadPdf()" >Preview</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnDownloadPdf()">Print</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'PainAssesment')">Share</a></li>
                                         </ul>
                                       </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody>
                                    <tr *ngFor="let item of nutritionalQuestionnaire; let i=index;">
                                      
                                      <td>{{i+ 1 +getnutritionalQuestionnaire()}}</td>
                                      <td>{{item?.documentName}}</td>
                                      <td><a (click)="onGenerateTreatmentPlanDownload(item,1)">{{item.documentLink}}</a></td>
                                      <td>{{item.createdby}}</td>
                                      <td>
                                       <div class="dropdown">
                                         <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                                         <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                           <li role="presentation"  class="w-100 float-left"><a (click)="onGenerateTreatmentPlanDownload(item,0)" class="fil-names">Download</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGenerateTreatmentPlanDownload(item,1)" >Preview</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGenerateTreatmentPlanDownload(item,1)">Print</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'NutritionalQuestionnaire')">Share</a></li>
                                         </ul>
                                       </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tbody>
                                    <tr *ngFor="let item of suicideAssessment; let i=index;">
                                      
                                      <td>{{i+ 1 +getsuicideAssessment()}}</td>
                                      <td>{{item?.documentName}}</td>
                                      <td><a (click)="SucidePdf(item,1)">{{item.documentLink}}</a></td>
                                      <td>{{item.createdby}}</td>
                                      <td>
                                       <div class="dropdown">
                                         <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                                         <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                           <li role="presentation"  class="w-100 float-left"><a (click)="SucidePdf(item,0)" class="fil-names">Download</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="SucidePdf(item,1)" >Preview</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="SucidePdf(item,1)">Print</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'SusidalRiskAssesment')">Share</a></li>
                                         </ul>
                                       </div>
                                      </td>
                                    </tr>
                                  </tbody>        
                                  <tbody>
                                    <tr *ngFor="let item of intakeUpdate; let i=index;">
                                      
                                      <td>{{i+1 +getintakeUpdate()}}</td>
                                      <td>{{item?.documentName}}</td>
                                      <td><a (click)="OnIntakeUpdate(item,1)">{{item.documentLink}}</a></td>
                                      <td>{{item.createdby}}</td>
                                      <td>
                                       <div class="dropdown">
                                         <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                                         <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                           <li role="presentation"  class="w-100 float-left"><a (click)="OnIntakeUpdate(item,0)" class="fil-names">Download</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnIntakeUpdate(item,1)" >Preview</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnIntakeUpdate(item,1)">Print</a></li>
                                           <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'intakeUpdate')">Share</a></li>
                                         </ul>
                                       </div>
                                      </td>
                                    </tr>
                                  </tbody>  
                                                                      
                              </table>
                              </div>
                          </div>
                          </div>
                      </div>
                          
                            <div class="col-md-12 tab-content float-left no-bg no-border" *ngIf="isTreatmentDocument">
                              <div class="row">
                              
                                <div class="col-lg-12 col-md-12 col-sm-12 mb-2" >
                                  <table class="table table-styles documenttable--fix">
                                    <thead>
                                       <tr>
                                         
                                         <td>S.No</td>
                                         <td>Document Name</td>
                                         <td>Document Link</td>
                                         <td>Created by</td>
                                         <td>Action</td>
                                       </tr>
                                     </thead>
                                     <tbody>
                                       <tr *ngFor="let item of treatmentPlanDocumentList; let i=index;">
                                         
                                         <td>{{i+1}}</td>
                                         <td>{{item.DocumentName}}</td>
                                         <td><a (click)="onGenerateTreatmentPlanDownload(item,1)">{{item.FileName}}</a></td>
                                         <td>{{this.createdby}}</td>
                                         <td>
                                          
                                           <div class="dropdown">
                                             <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                                             <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                               <li role="presentation" class="w-100 float-left"><a (click)="onGenerateTreatmentPlanDownload(item,0)" class="fil-names">Download</a></li>
                                               <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGenerateTreatmentPlanDownload(item,1)">Preview</a></li>
                                               <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGenerateTreatmentPlanDownload(item,1)">Print</a></li>
                                               <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'TreatmentPlan')">Share</a></li>
                                             </ul>
                                           </div>
                                           
                                         </td>
                                       </tr>
                                     </tbody>
                                 </table>
                              </div>
                              </div>
                              </div>
                              <div class="col-md-12 tab-content float-left no-bg no-border" *ngIf="isSusidalRiskDocumentExist">
             
                                          <div  *ngIf="isSucidalRisk">
                                            <table class="table table-styles documenttable--fix">
                                              <thead>
                                                 <tr>
                                                   
                                                   <td>S.No</td>
                                                   <td>Document Name</td>
                                                   <td>Document Link</td>
                                                   <td>Created by</td>
                                                   <td>Action</td>
                                                 </tr>
                                               </thead>
                                               <tbody>
                                                 <tr *ngFor="let item of lstDocuments; let i=index;">
                                                   
                                                   <td>{{i+1}}</td>
                                                   <td>{{item?.documentName}}</td>
                                                   <td><a (click)="OnDownloadSusidalRisk(item,1)">{{item.documentLink}}</a></td>
                                                   <td>{{item.createdby}}</td>
                                                   <td>
                                                    <div class="dropdown">
                                                      <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                                                      <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                                        <li role="presentation"  class="w-100 float-left"><a (click)="OnDownloadSusidalRisk(item,0)" class="fil-names">Download</a></li>
                                                        <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnDownloadSusidalRisk(item,1)" >Preview</a></li>
                                                        <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnDownloadSusidalRisk(item,1)">Print</a></li>
                                                        <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'SusidalRisk')">Share</a></li>
                                                      </ul>
                                                    </div>
                                                   </td>
                                                 </tr>
                                               </tbody>
                                            </table>
                                          <div  *ngFor="let item of lstDocuments; let i=index;" class="d-none">
                                            <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                                            <div class="card" >
                                              <div class="file h-auto p-2">
                                                 <div class="document-view-list">
                                                  <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">{{item?.documentName}}<a (click)="OnDownloadSusidalRisk(item)" class="fil-names">Download</a></p>
                                                    <!-- <p class="w-100 mb-0">
                                                      <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                                    </p>  -->
                                                    </div> 
                                                    <!-- <div class="col-md-12 file_manager" *ngIf="isSusidalRiskDocument">
                                                      <div class="row">
                                                        <div class="col-lg-3 col-md-4 col-sm-12" >
                                                          <div class="card">
                                                            <div class="file"> 
                                                              <a (click)="OnDownloadSusidalRisk(item)">
                                                                <div class="hover"> 
                                                                  <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                                                                </div>
                                                                <div class="file-name">
                                                                  <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>{{item?.documentName}}</p> 
                                                                  <small>Size: 42KB <span class="date text-muted">{{item?.createdDate | date:'mediumDate' }}</span></small>
                                                                </div> 
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                               
                                                      </div>
                                                    </div> -->
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                           </div>
                                         
                                </div>
                                </div>
                                <div class="col-md-12 tab-content float-left no-bg no-border" *ngIf="isAppointmentDocument">
                                  <table class="table table-styles documenttable--fix">
                                    <thead>
                                       <tr>
                                         
                                         <td>S.No</td>
                                         <td>Document Name</td>
                                         <td>Document Link</td>
                                         <td>Created by</td>
                                         <td>Action</td>
                                       </tr>
                                     </thead>
                                     <tbody>
                                       <tr  *ngFor="let item of appoinmentDocumentList; let i=index;">
                                         
                                         <td>{{i+1}}</td>
                                         <td>{{item?.ScheduleName}}</td>
                                         <td><a (click)="OnDownloadAppointment(item,1)">{{item.DocumentLink}}</a></td>
                                         <td>{{item.Createdby}}</td>
                                         <td>
                                          <div class="dropdown">
                                            <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                                            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                              <li role="presentation"  class="w-100 float-left"><a (click)="OnDownloadAppointment(item,0)" class="fil-names">Download</a></li>
                                              <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnDownloadAppointment(item,1)" >Preview</a></li>
                                              <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnDownloadAppointment(item,1)">Print</a></li>
                                              <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'Appointment')">Share</a></li>
                                            </ul>
                                          </div>
                                         </td>
                                       </tr>
                                     </tbody>
                                  </table>
                                  <div>
                                  <div  *ngFor="let item of appoinmentDocumentList; let i=index;" class="d-none">
                                    <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                                    <div class="card" >
                                      <div class="file h-auto p-2">
                                         <div class="document-view-list">
                                          <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">{{item?.ScheduleName}}<a (click)="OnDownloadAppointment(item)" class="fil-names">Download</a></p>
                                            <!-- <p class="w-100 mb-0">
                                              <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                            </p>  -->
                                            </div> 
                                            <!-- <div class="col-md-12 file_manager" *ngIf="isSusidalRiskDocument">
                                              <div class="row">
                                                <div class="col-lg-3 col-md-4 col-sm-12" >
                                                  <div class="card">
                                                    <div class="file"> 
                                                      <a (click)="OnDownloadSusidalRisk(item)">
                                                        <div class="hover"> 
                                                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                                                        </div>
                                                        <div class="file-name">
                                                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>{{item?.documentName}}</p> 
                                                          <small>Size: 42KB <span class="date text-muted">{{item?.createdDate | date:'mediumDate' }}</span></small>
                                                        </div> 
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                       
                                              </div>
                                            </div> -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                   </div>
                                 
                        </div>
                        </div>
                        <div class="col-md-12 tab-content float-left no-bg no-border" *ngIf="isCommunicationExist">
                          <table class="table table-styles documenttable--fix">
                            <thead>
                               <tr>
                                 
                                 <td>S.No</td>
                                 <td>Document Name</td>
                                 <td>Document Link</td>
                                 <td>Created by</td>
                                 <td>Action</td>
                               </tr>
                             </thead>
                             <tbody>
                               <tr  *ngFor="let item of communicationDocumentList; let i=index;">
                                 
                                 <td>{{i+1}}</td>
                                 <td>{{item?.ScheduleName}}</td>
                                 <td><a (click)="OnDownloadAppointment(item,1)">{{item.DocumentLink}}</a></td>
                                 <td>{{item.Createdby}}</td>
                                 <td>
                                  <div class="dropdown">
                                    <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                                    <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                      <li role="presentation"  class="w-100 float-left"><a (click)="OnDownloadAppointment(item,0)" class="fil-names">Download</a></li>
                                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnDownloadAppointment(item,1)" >Preview</a></li>
                                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="OnDownloadAppointment(item,1)">Print</a></li>
                                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'Communication')">Share</a></li>
                                    </ul>
                                  </div>
                                 </td>
                               </tr>
                             </tbody>
                          </table>
                          <div>
                          <div  *ngFor="let item of appoinmentDocumentList; let i=index;" class="d-none">
                            <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                            <div class="card" >
                              <div class="file h-auto p-2">
                                 <div class="document-view-list">
                                  <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">{{item?.ScheduleName}}<a (click)="OnDownloadAppointment(item)" class="fil-names">Download</a></p>
                                    <!-- <p class="w-100 mb-0">
                                      <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                    </p>  -->
                                    </div> 
                                    <!-- <div class="col-md-12 file_manager" *ngIf="isSusidalRiskDocument">
                                      <div class="row">
                                        <div class="col-lg-3 col-md-4 col-sm-12" >
                                          <div class="card">
                                            <div class="file"> 
                                              <a (click)="OnDownloadSusidalRisk(item)">
                                                <div class="hover"> 
                                                  <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                                                </div>
                                                <div class="file-name">
                                                  <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>{{item?.documentName}}</p> 
                                                  <small>Size: 42KB <span class="date text-muted">{{item?.createdDate | date:'mediumDate' }}</span></small>
                                                </div> 
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                               
                                      </div>
                                    </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                           </div>
                         
                </div>
                </div>
                        <div class="col-md-12 tab-content float-left no-bg no-border" *ngIf="isFinancialExists1">
                          <table class="table table-styles documenttable--fix">
                            <thead>
                               <tr>
                                 
                                 <td>S.No</td>
                                 <td>Document Name</td>
                                 <td>Status</td>
                                 <td>Document Link</td>
                                 <td>Created by</td>
                                 <td>Action</td>
                               </tr>
                             </thead>
                             <tbody>
                               <tr  *ngFor="let item of OnFinancial; let i=index;">
                                 
                                 <td>{{i+1}}</td>
                                 <td>{{item.documentName}}</td>
                                 <td>{{item.status}}</td>
                                  <td><a (click)="onGeneratePDFOnboading(item,1)">{{item.documentLink}}</a></td>
                                 <td>{{item.createdBy}}</td>
                                 <td>
                                  <div class="dropdown">
                                    <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                                    <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                      <li role="presentation"  class="w-100 float-left"><a (click)="onGeneratePDFOnboading(item,0)" class="fil-names">Download</a></li>
                                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGeneratePDFOnboading(item,1)" >Preview</a></li>
                                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGeneratePDFOnboading(item,1)">Print</a></li>
                                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'OnBoarding')">Share</a></li>
                                    </ul>
                                  </div>
                                 </td>
                               </tr>
                             </tbody>
                          </table>
                        
                </div>
                <div class="col-md-12 tab-content float-left no-bg no-border" *ngIf="isOnboardingExists1">
                  <table class="table table-styles documenttable--fix">
                    <thead>
                       <tr>
                         
                         <td>S.No</td>
                         <td>Document Name</td>
                         <td>Document Link</td>
                         <td>Created by</td>
                         <td>Action</td>
                       </tr>
                     </thead>
                     <tbody>
                       <tr  *ngFor="let item of onBoardingDocument; let i=index;">
                         
                         <td>{{i+1}}</td>
                         <td>{{item.documentName}}</td>
                          <td><a (click)="onGeneratePDFOnboading(item,1)">{{item.documentLink}}</a></td>
                         <td>{{item.createdBy}}</td>
                         <td>
                          <div class="dropdown">
                            <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                              <li role="presentation"  class="w-100 float-left"><a (click)="onGeneratePDFOnboading(item,0)" class="fil-names">Download</a></li>
                              <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGeneratePDFOnboading(item,1)" >Preview</a></li>
                              <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGeneratePDFOnboading(item,1)">Print</a></li>
                              <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'OnBoarding')">Share</a></li>
                            </ul>
                          </div>
                         </td>
                       </tr>
                     </tbody>
                  </table>
                
        </div>
        <div class="col-md-12 tab-content float-left no-bg no-border" *ngIf="isUploaded">
          <table class="table table-styles documenttable--fix" >
            <thead>
               <tr>
                 
                 <td>S.No</td>
                 <td>Document Name</td>
                 <td>Document Link</td>
                 <td>Document Type</td>
                 <td>Created by</td>
                 <td>Action</td>
               </tr>
             </thead>
             
             <tbody>
               <tr  *ngFor="let item of uploadedDocumentList; let i=index;">
                 
                 <td>{{i+1}}</td>
                 <td>{{item.FileName}}</td>
                  <td><a (click)="UploadedDocumentsDownload(item,1)">{{item.DocumentName}}</a></td>
                  <td>{{item.DocumentTypeName}}</td>
                 <td>{{item.CreatedName}}</td>
                 <td>
                  <div class="dropdown">
                    <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                    <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                      <li role="presentation"  class="w-100 float-left"><a (click)="UploadedDocumentsDownload(item,0)" class="fil-names">Download</a></li>
                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="UploadedDocumentsDownload(item,1)" >Preview</a></li>
                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="UploadedDocumentsDownload(item,1)">Print</a></li>
                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'Uploaded')">Share</a></li>
                    </ul>
                  </div>
                 </td>
               </tr>
             </tbody>
          </table>
        
          <div  class="d-flex justify-content-center Doc_text" *ngIf = "uploadedDocumentList.length == 0">
            <span >No Documents Found </span>
          </div>
</div>    

<div class="col-md-12 tab-content float-left no-bg no-border" *ngIf="isConsentExists">
  <table class="table table-styles documenttable--fix">
    <thead>
       <tr>
         
         <td>S.No</td>
         <td>Document Name</td>
         <td>Document Link</td>
         <td>Created by</td>
         <td>Action</td>
       </tr>
     </thead>
     <tbody>
       <tr  *ngFor="let item of lstIntakeConsent; let i=index;">
         
         <td>{{i+1}}</td>
         <td>{{item.templateName}}</td>
          <td><a (click)="onGeneratePDFConsent(item,1)">{{item.documentLink}}</a></td>
         <td>{{item.createdBy}}</td>
        
         <td>
          <div class="dropdown">
            <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
              <li role="presentation"  class="w-100 float-left"><a (click)="onGeneratePDFConsent(item,0)" class="fil-names">Download</a></li>
              <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGeneratePDFConsent(item,1)" >Preview</a></li>
              <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onGeneratePDFConsent(item,1)">Print</a></li>
              <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'Consent')">Share</a></li>
            </ul>
          </div>
         </td>
       </tr>
     </tbody>
  </table>

</div>

<div class="col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="noDocuments">
                                
  <div class="card">
    <div class="file h-auto p-2">
       <div class="document-view-list">
        <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">No Documents Found</p>
          <!-- <p class="w-100 mb-0">
            <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
          </p>  -->
          </div> 
        </div>
      </div>
    </div>
  </div>
                </div>
              </div>
              </div>
            </div>
          
        </div>
      </div>
      </div>
    </div>
   
</div>
</div>
<div>

<!-- Share Documents popup start -->
<div class="comment-popup  cancel-tour" *ngIf="isShare">
  <form class="form-horizontal form-material mx-2" (ngSubmit)="ShareDocument.form.valid" id="ShareDocument"   #ShareDocument="ngForm">
  <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
    <div class="modal-dialog mat-dialog-container " role="document">

      <div class="modal-content border-0">
<div class="modal-header">
  <div class="modal-title">Share Documents</div>
  <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="SharePopupClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
        <div class="modal-body">
<div >


  <div class="col-md-12">
    <div class="row ">
      <div class="col-md-12">
<div class="row">
 
  <div class="col-md-10">
    <mat-form-field class="w-100">
      <mat-label>Email Address</mat-label>
      <input matInput  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$" [(ngModel)]="ShareFiles.email" #email="ngModel" name="email" id="email"
      [ngClass]="{ 'is-invalid': ShareDocument.submitted && email.invalid }" required>
      <mat-error *ngIf="ShareDocument.submitted && email.invalid">
        <mat-error  *ngIf="email.errors.required">
          Email is required</mat-error>
          <mat-error  *ngIf="email.errors?.pattern">
            Please enter valid email address</mat-error>
        </mat-error>
    </mat-form-field>
  </div>
  <div class="col-md-2">
    <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" type="submit" form="ShareDocument" (click)="ShareDocumentPdf()" >Send</button>
  </div>
</div>
        
      </div>


    </div>

  </div>
  <div class="text-center col-md-12 mt-2">
    
    <!-- <button type="button" class="btn btn-cancel" data-dismiss="modal" >No</button> -->
  </div>



</div>


</div>
</div>
</div>

</div>
</form>
</div>
<!-- Share Documents popup end -->

  <a mimetype="application/octet-stream" #downloadLink></a>
  <a mimetype="application/octet-stream" #mainpage></a>
</div>
<div
  class="modal showdocspopup"
  role="dialog"
  [style.display]="ShowDocuments ? 'block' : 'none'"
>
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title w-100 float-left">
          Documents
          <button
            type="button"
            class="close float-right"
            (click)="closepopup()"
          >
            &times;
          </button>
        </h6>
      </div>
      <div class="modal-body">
        <div class="float-left w-100">
          <div class="w-100 d-flex justify-content-around mb-2">
            <button
              mat-raised-button
              class="primary-btn-filled mt-2 btn_size"
              (click)="fileInput.click()"
            >
              Add Files
            </button>

            <input
              style="display: none"
              #attachments
              type="file"
              (change)="onFileChanged($event)"
              #fileInput
              multiple="true"
            />
           
            <div class="doc-type">
              <mat-form-field class="w-100">
                <mat-label>Document Type</mat-label>
                <mat-select   [(ngModel)]="documentModuleTypeId" 
                name="documentModuleTypeId" (selectionChange)="isRequired = false">
                 <mat-option [title]="doc.documentName" *ngFor="let doc of ddlDocumentType" [value]="doc.documentModuleTypeId">{{doc.documentName}}</mat-option>
                </mat-select> 
               
             </mat-form-field>
             <!-- <mat-error *ngIf="isRequired"> <mat-error>Document type is required</mat-error>  </mat-error> -->
             <div class="error-message err-top" *ngIf="isRequired" >Document Type is required
            </div>
             
            </div>
          </div>
          <div
          class="w-100 float-left mb-2 text-danger pl-2 mt-2"
          *ngIf="filesize"
        >
          File size should not exceed 10 Mb
        </div>
        <div
          class="w-100 float-left mb-2 text-danger pl-2 mt-2"
          *ngIf="filecount"
        >
          You are allowed to upload only 5 documents
        </div>
          <!--Documents will be shown here-->
          <div
            class="w-100 float-left"
            *ngFor="let selected of listOfFiles; let index = index"
          >
            <div class="filesectionloop mb-2">
              <span class="filenametext">{{ selected }}</span>
              <button
                mat-icon-button
                class="deleteicon"
                title="Delete"
                (click)="removeSelectedFile(index)"
              >
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-sm btn-danger" (click)="closepopup()">
          Close
        </button>
        <button type="button" class="btn-sm btn-success" (click)="savedocs()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal generate-confirm" tabindex="-1" role="dialog"  [style]="isShowPopupForDownload ? 'display: block;' : 'display :none'">
  <div class="modal-dialog mat-dialog-container " role="document">
    <div class="modal-content border-0">
      <div class="modal-header">
        <h6 class="modal-title"> Generate Pain Assesment Pdf Confirmation</h6>
        <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OnDownloadDetailspopupShowOrClose()" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"></div>
      <div >

    <div class="col-md-12">
      <div class="row mb-2">
        <div class="col-md-12">
          <div >
            <div class="mb-4">Hey! Your document will be downloaded in 5 minutes. Kindly wait. 
              </div>
            <div class=" text-right">
            <button type="button" class="btn primary-btn-filled float-right" data-dismiss="modal" (click)="onGeneratePDF()" >Proceed
            </button>
            <button type="button" class="btn btn-cancel mr-2 "  data-dismiss="modal" (click)="OnDownloadDetailspopupShowOrClose()" >May be Later
            </button>
            </div>
        
          <!-- </tbody>
        </table> -->
        </div>
        </div>

        </div>
    </div>
    </div>
  </div>
  </div>
</div>

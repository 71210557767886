<div class="profile-page">
    <div  class="top-btn-section col-md-12 clearfix mb-1 w-100">
      <h2  class="head-section mt-3">Profile Information</h2>
      <div  class="col-md-6 ml-auto mt-2 pr-0">
        </div>
        <div  class="ml-auto"><button  class="btn back-btn-new mr-2" tabindex="0" (click)="OnBack()" ><i  class="fas fa-chevron-left mr-2"></i>Back</button></div>
      </div>
    <div class="col-md-12">
    <div class="row">
        
      <div class="col-lg-3  col-md-5">
        <div class="card">
          <div class="card-body profile-card">
            <div class="mt-4 text-center">
              <!-- <img src="../../../assets/images/avator.jpg" class="rounded-circle" width="150"> -->
              <img [src]="imageSource" class="rounded-circle" width="150" height="150">
              <h4 class="card-title mt-2">{{fullName}}</h4>
              <div *ngIf="roleId != 4">
                <h6 class="card-subtitle">{{roleNameToDisplay}}</h6>
              </div>
              <label class="custom-files">Upload Profile Picture
                <input accept="image/*" #UploadProfile type='file' class="custom-file-input"
                (change)="onImgSelected($event)" ></label>
              
            </div>
          </div>
        </div>
      </div>
      
      
      <div class="col-lg-9 col-md-7">
        <div class="card">
          <div class="card-body">
            <div class="col-md-12 d-flex p-0"><div class="ml-auto">
              <button class="btn primary-btn-filled-small btn-sm" (click)="changePasswordPage()">change password</button>
            </div>
          </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 border-right">
                  <form class="form-horizontal form-material mx-2" (ngSubmit)="ProfileInfoFrm.form.valid" id="ProfileInfoFrm" #ProfileInfoFrm="ngForm">
                    <div class="form-group">
                      <label>First Name</label>
                      <div>
                        <input type="text" [(ngModel)]="ProfileInfo.firstName" #firstName="ngModel" name="firstName" placeholder="First Name" class="form-control ps-0 form-control-line" required>
                        <div class="error-message" *ngIf="ProfileInfoFrm.submitted && firstName.invalid">
                          <div *ngIf="firstName.errors.required">
                            First Name is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Last Name</label>
                      <div>
                        <input type="text" [(ngModel)]="ProfileInfo.lastName" #lastName="ngModel" name="lastName" placeholder="Last Name" class="form-control ps-0 form-control-line" required>
                        <div class="error-message" *ngIf="ProfileInfoFrm.submitted && lastName.invalid">
                          <div *ngIf="lastName.errors.required">
                            Last Name is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="example-email">Email</label>
                      <div>
                        <input type="email" [(ngModel)]="ProfileInfo.email" #email="ngModel" name="email" placeholder="Email" class="form-control ps-0 form-control-line" name="example-email" id="example-email"
                               pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                               [ngClass]="{ 'is-invalid': ProfileInfoFrm.submitted && email.invalid }" required>
                        <div class="error-message" *ngIf="ProfileInfoFrm.submitted && email.invalid">
                          <div *ngIf="email.errors.required">
                            Email is required
                          </div>
                          <div *ngIf="email.errors?.pattern">
                            Please enter valid email address
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class="form-group" [ngClass]="{'disabled': true }" *ngIf="roleId != 4">
                      <label>Role</label>
                      <div>
                        <input type="text" [(ngModel)]="ProfileInfo.roleName" #roleName="ngModel" name="roleName" placeholder="Role" class="form-control ps-0 form-control-line" required>
                        <div class="error-message" *ngIf="ProfileInfoFrm.submitted && roleName.invalid">
                          <div *ngIf="roleName.errors.required">
                            Role is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group" *ngIf="roleId != 4 ">
                      <label>Primary Facility</label>
                      <div>
                        <input type="text" [(ngModel)]="ProfileInfo.primaryFacility" #primaryFacility="ngModel" name="primaryFacility" placeholder="Primary Facility" class="form-control ps-0 form-control-line" required>
                        <div class="error-message" *ngIf="ProfileInfoFrm.submitted && primaryFacility.invalid">
                          <div *ngIf="primaryFacility.errors.required">
                            Primary Facility is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group" *ngIf="roleTypeId == PrimaryClinician || roleTypeId == SupportClinician || roleTypeId == Interns">
                      <label>License Type</label>
                      <div>
                        <input type="text" [(ngModel)]="ProfileInfo.licenseType" #licenseType="ngModel" name="licenseType" placeholder="License Type" class="form-control ps-0 form-control-line" required>
                        <div class="error-message" *ngIf="ProfileInfoFrm.submitted && licenseType.invalid">
                          <div *ngIf="licenseType.errors.required">
                            License Type is required
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="form-group" *ngIf="roleTypeId == PrimaryClinician || roleTypeId == SupportClinician || roleTypeId == Interns">
                      <label>License Number</label>
                      <div>
                        <input type="text" [(ngModel)]="ProfileInfo.licenseNumber" #licenseNumber="ngModel" name="licenseNumber" placeholder="License Number" class="form-control ps-0 form-control-line" required>
                        <div class="error-message" *ngIf="ProfileInfoFrm.submitted && licenseNumber.invalid">
                          <div *ngIf="licenseNumber.errors.required">
                            License Number is required
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group" *ngIf="roleId != 4 ">
                      <label>Signature</label>
                      <br />
                      <img id="esign_3" height="50px" width="300px" style="border: 1px solid gray;">
                      <button type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialog()"> Add E-Sign </button>

                    </div>
                    <div class="d-flex mt-4">
                      <button class="btn btn-success btn-theme ml-auto mr-2" type="submit" form="ProfileInfoFrm" (click)="PostProfileInfo()">
                        Save Profile
                      </button>
                    </div>
                  </form>
                </div>
                <div class="col-md-6">
                  <form class="form-horizontal form-material mx-2">
                    <div class="form-group" [ngClass]="{'disabled': true }">
                      <label class="col-md-12">Username</label>
                      <div class="col-md-12">
                        <input type="text" [(ngModel)]="ProfileInfo.userName" #userName="ngModel" name="userName" placeholder="User Name" class="form-control ps-0 form-control-line">
                      </div>
                    </div>
                    <div class="form-group" [ngClass]="{'disabled': true }">
                      <label class="col-md-12 ">Password</label>
                      <div class="col-md-12">
                        <input type="password" value="password" class="form-control ps-0 form-control-line">
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <!-- <div class="form-group"  *ngIf="roleId == 4">
          <label>Address</label>
          <div>
            <input type="text" [(ngModel)]="ProfileInfo.address" #address="ngModel" name="address" placeholder="Address" class="form-control ps-0 form-control-line" required>
            <div class="error-message" *ngIf="ProfileInfoFrm.submitted && address.invalid">
              <div  *ngIf="address.errors.required">
                Address is required</div>
              </div>
          </div>
        </div>

      <div class="d-flex mt-4">
        <button class="btn btn-success btn-theme ml-auto mr-2" type="submit" form="ProfileInfoFrm" (click)="PostProfileInfo()">
          Save Profile
        </button>
      </div>
    </form>
    </div>
    <div class="col-md-6">
      <form class="form-horizontal form-material mx-2">
        <div class="form-group" [ngClass]="{'disabled': true }">
          <label class="col-md-12">User Name</label>
          <div class="col-md-12">
            <input type="text" [(ngModel)]="ProfileInfo.userName" #userName="ngModel" name="userName" placeholder="User Name" class="form-control ps-0 form-control-line">
          </div>
        </div>
        <div class="form-group" [ngClass]="{'disabled': true }">
          <label class="col-md-12 ">Password</label>
          <div class="col-md-12">
            <input type="password" value="password" class="form-control ps-0 form-control-line">
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- </div> -->
              <!-- <div class="col-md-6" *ngIf="!isChangePassword">
    <form class="form-horizontal form-material mx-2">
      <div class="form-group">
        <label class="col-md-12">User Name</label>
        <div class="col-md-12">
          <input type="text" placeholder="User Name" class="form-control ps-0 form-control-line">
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-12 ">Password</label>
        <div class="col-md-12">
          <input type="password" value="password" class="form-control ps-0 form-control-line">
        </div>
      </div>
      <div class="form-group">
        <div class="col-sm-12 d-flex">
          <button class="btn btn-success btn-theme ml-auto  text-white" (click)="ChangePasswordClick()" type="submit">
           Change Password
          </button>
        </div>
      </div>
      </form>
  </div>
  <div class="col-md-6"  *ngIf="isChangePassword">
      <form (submit)="onSubmit()" class="form-horizontal form-material mx-2" [formGroup]="changepassswordForm">

      <div class="form-group">
        <label class="col-md-12 ">Old Password</label>
        <div class="col-md-12">
          <input id="float-input" formControlName="OldPassword" class="form-control ps-0 form-control-line"
          autocomplete="off" class="form-control " />
      <div *ngIf="submitted && f.OldPassword.errors"
          class="invalid-feedback d-block">
          <div *ngIf="f.OldPassword.errors.required">Old Password is
              required</div>
      </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-12 ">New Password</label>
        <div class="col-md-12">
          <input  id="password" formControlName="Password" type="password"
                                              class="form-control ps-0 form-control-line"
                                              [ngClass]="(changepassswordForm.controls['Password'].invalid && (changepassswordForm.controls.Password.value !=null && changepassswordForm.controls.Password.value !='')) ? 'is-invalid' : ''" />
                                          <div *ngIf="submitted && f.Password.errors"
                                              class="invalid-feedback d-block">
                                              <div *ngIf="f.Password.errors.required">New Password is
                                                  required</div>
                                          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-md-12">Confirm Password</label>
        <div class="col-md-12">
          <input id="ConfirmPassword" class="form-control ps-0 form-control-line"
          [ngClass]="(changepassswordForm.controls['ConfirmPassword'].invalid && (changepassswordForm.controls.ConfirmPassword.value !=null && changepassswordForm.controls.ConfirmPassword.value !=''))? 'is-invalid' : ''"
          formControlName="ConfirmPassword" type="password"
          class="form-control ps-0 form-control-line" />
      <div *ngIf="submitted && f.ConfirmPassword.errors"
          class="invalid-feedback d-block">
          <div *ngIf="f.ConfirmPassword.errors.required">Confirm
              password
              is Required</div>
      </div>
      <label
          class="invalid-feedback d-block"
          *ngIf="changepassswordForm.controls['ConfirmPassword'].hasError('NoPassswordMatch') && (changepassswordForm.controls.ConfirmPassword.value !=null && changepassswordForm.controls.ConfirmPassword.value !='')">
          Passwords do not match
      </label>
        </div>

      </div>
      <div class="form-group">
        <div class="col-md-6">
          <button class="btn btn-success btn-theme ml-auto  text-white" (click)="onSubmit()" type="submit">
           Update Password
          </button>
        </div>
        <div class="col-md-6">
          <button class="btn btn-success btn-theme ml-auto  text-white" (click)="CancelChangePassword()" type="submit">
            Cancel
           </button>
        </div>
      </div>
      </form>
  </div> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- </div> -->
              <!-- <div class="modal" id="myModal" [style.display]="showChangePasswordPopup ? 'block' : 'none'">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="modal-header">
                  <h6 class="modal-title">Change Password </h6>
                  <button type="button" title="Close" class="close" data-dismiss="modal"
                      (click)="hideChangePasswordPopup()">&times;</button>
              </div>
                <div class="modal-body">
                      <form (submit)="onSubmit()" class="form-horizontal form-material mx-2" [formGroup]="changepassswordForm">

                      <div class="form-group">
                        <label class="col-md-12 ">Old Password</label>
                        <div class="col-md-12">
                          <input type="password" id="float-input" formControlName="OldPassword" class="form-control ps-0 form-control-line"
                          autocomplete="off" class="form-control " />
                      <div *ngIf="submitted && f.OldPassword.errors"
                          class="error-message">
                          <div *ngIf="f.OldPassword.errors.required">Old Password is
                              required</div>
                      </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-md-12 ">New Password</label>
                        <div class="col-md-12">
                          <input  id="password" formControlName="Password" type="password"
                                                              class="form-control ps-0 form-control-line"
                                                              [ngClass]="(changepassswordForm.controls['Password'].invalid && (changepassswordForm.controls.Password.value !=null && changepassswordForm.controls.Password.value !='')) ? 'is-invalid' : ''" />
                                                          <div *ngIf="submitted && f.Password.errors"
                                                              class="error-message">
                                                              <div *ngIf="f.Password.errors.required">New Password is
                                                                  required</div>
                                                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="col-md-12">Confirm Password</label>
                        <div class="col-md-12">
                          <input id="ConfirmPassword" class="form-control ps-0 form-control-line"
                          [ngClass]="(changepassswordForm.controls['ConfirmPassword'].invalid && (changepassswordForm.controls.ConfirmPassword.value !=null && changepassswordForm.controls.ConfirmPassword.value !=''))? 'is-invalid' : ''"
                          formControlName="ConfirmPassword" type="password"
                          class="form-control ps-0 form-control-line" />
                      <div *ngIf="submitted && f.ConfirmPassword.errors"
                          class="error-message">
                          <div *ngIf="f.ConfirmPassword.errors.required">Confirm
                              password
                              is Required</div>
                      </div>
                      <label
                          class="error-message"
                          *ngIf="changepassswordForm.controls['ConfirmPassword'].hasError('NoPassswordMatch') && (changepassswordForm.controls.ConfirmPassword.value !=null && changepassswordForm.controls.ConfirmPassword.value !='')">
                          Passwords do not match
                      </label>
                      <label
                          class="error-message"
                          *ngIf="errorMessageForSamePassword">
                          Old password and Password shoud not to be same.
                      </label>
                        </div>

                      </div>
                      <div class="form-group">
                        <div class="col-sm-12 d-flex">
                          <button class="btn btn-success btn-theme ml-auto mr-2 text-white" (click)="onSubmit()" type="submit">
                           Update Password
                          </button>
                          <button class="btn btn-success btn-theme text-white" (click)="CancelChangePassword()" type="submit">
                            Cancel
                           </button>
                        </div>
                      </div>
                      </form>
              </div>
          </div>
      </div>
  </div> -->

import {ParentInfo} from './parentInfo'
export class PatientInfo{
    PatientInfoId:number=0;
    FirstName:string;
    LastName:string;
    Gender:number;
    Dob:Date;
    SourceTypeId:number;
    LeadTypeId:number;
    FacilityTypeId:any;
    Age	:	number;
    IsMinor	:	boolean;
    ParentName:string;
    Ssid	:	string;
    GradeLevel	:	number;
    SchoolDistrict	:	string;
    SchoolName	:	string;
    TeachersName	:	string;
    TeachersLastName	:	string;
    IsProfileCompleted:boolean;
    IsInsuranceCompleted:boolean;
    IsHistoryCompleted:boolean;
    PatientStatus:string;
    CreatedOn:string;
    IsResubmit:boolean;
    Academiccategory :string;
    IsSkipInsurance : boolean;
    NewFee : boolean;

    
  }

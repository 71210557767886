<div class="form-configuration">
  <div class="top-btn-section clearfix mb-1 w-100">
    <h2 class="head-section mb-2 mt-1">Create Form</h2>
    <div class="col-md-6 ml-auto mt-2 button-position pr-0">
      <button type="button" (click)="onbacktoformlist()" class="btn primary-btn-outlined float-right mr-2">
        <i class="fas fa-angle-left pr-2"></i>Back
      </button>
    </div>
  </div>
  <div class="col-md-12 p-0 created-form-section" *ngIf="!isSkillTaughtExist">
    <div class="lead-box pt-2 pb-2">
      <form id="configureForminfo" autocomplete="off" #configureForminfo="ngForm" novalidate>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3 mt-1">
              <mat-form-field class="w-100">
                <mat-label>Form Name</mat-label>
                <input matInput type="text" placeholder="Form Name" required [(ngModel)]="createSkillTaughtForm.formName" #formName="ngModel" name="formName">
                <mat-error *ngIf="configureForminfo.controls.formName?.errors?.required">
                  This field is required</mat-error>
              </mat-form-field>        
            </div>
            <!-- <div class="col-md-2 mt-1">
              <mat-form-field class="w-100">
                <mat-label>Form Type </mat-label>
                <mat-select  [(ngModel)]="createSkillTaughtForm.formTypeId" #formTypeId="ngModel" name="formTypeId" placeholder="Select Form Type" required>
                  <mat-option value="1"> IOP </mat-option>
                  <mat-option value="2"> PCP </mat-option>
                </mat-select>
                <mat-error *ngIf="configureForminfo.controls.formTypeId?.errors?.required">This field is required</mat-error>
              </mat-form-field>
            </div> -->
            <div class="col-md-2 mt-1">
              <mat-form-field class="w-100">
                <mat-label>Form Type </mat-label>
                <mat-select  [(ngModel)]="createSkillTaughtForm.formTypeId" #formTypeId="ngModel" name="formTypeId" placeholder="Select Form Type" required multiple>
                  <mat-option value="1"> IOP </mat-option>
                  <mat-option value="2"> PCP </mat-option>
                </mat-select>
                <mat-error *ngIf="configureForminfo.controls.formTypeId?.errors?.required">This field is required</mat-error>
              </mat-form-field>
            </div>
            

            <div class="col-md-2 mt-1">
              <mat-form-field class="w-100">
                <mat-label class="control-label-new">Therapy</mat-label>
                <mat-select multiple required [(ngModel)]="createSkillTaughtForm.therapy" #therapy="ngModel" name="therapy">
                  <mat-option *ngFor="let item of therapyMaster" [value]="item.groupMeetingTherapyId"> {{item.therapyName}} </mat-option>
                </mat-select>
                <mat-error *ngIf="configureForminfo.controls.therapy?.errors?.required">This field is required</mat-error>
              </mat-form-field> 
            </div>

            <!-- <div class="col-md-1 mt-1 therapyEdit">
              <span >
                <a class="icon-note cursor-pointer" (click)="ontherapyEdit()" title="Edit Form Name"><span class="mr-2"><i
                    class="far fa-edit"></i></span></a>
            </span>
            </div> -->
            
           
            <div class="col-md-2 mt-1">
              <mat-form-field class="w-100">
                <mat-label class="control-label-new">Location</mat-label>
                <mat-select multiple required [disabled]="createSkillTaughtForm.isGlobal" [(ngModel)]="createSkillTaughtForm.location" #location="ngModel" name="location">
                  <mat-option *ngFor="let item of locationMaster" [value]="item.sfccMasterId"> {{item.locationName}} </mat-option>
                </mat-select>
                <mat-error *ngIf="configureForminfo.controls.location?.errors?.required">This field is required</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-1 pr-0 mt-1">
              <div class="check-custom w-100 mt-4">
                <mat-checkbox class="example-margin" [(ngModel)]="createSkillTaughtForm.isGlobal" #isGlobal="ngModel" name="isGlobal"> Global </mat-checkbox>
              </div>
            </div>
            <!-- <div class=" col-md-7 mt-3 pt-1" *ngIf='configureForm.formTypeId == 2'>
                            <div class="row">
                                <label class="additional ">Is explanation for the questions in form</label>

                                <span class="pl-2 p-0">

                                    <mat-radio-group aria-label="Select an option"
                                        [(ngModel)]="configureForm.isquestionExplanation"
                                        #iSquestionExplanation="ngModel" name="iSquestionExplanation" required>
                                        <mat-radio-button class="mr-1" [value]="1">Yes
                                        </mat-radio-button>

                                        <mat-radio-button class="mr-2" [value]="3">No
                                        </mat-radio-button>


                                    </mat-radio-group>

                                </span>
                                <mat-error class="w-100 position-relative"
                                    *ngIf="configureForminfo.submitted && iSquestionExplanation.invalid">
                                    <mat-error class="mat-form-field-subscript-wrapper"
                                        *ngIf="iSquestionExplanation.errors.required">
                                        Explanation is
                                        required</mat-error>
                                </mat-error>
                            </div>

                        </div> -->
            <div class="col-md-12 mb-2 text-left mt-2">
              <button
                type="submit"
                class="btn primary-btn-filled float-right mr-2"
                (click)="postConfigureForm(configureForminfo)"
              >
                <i class="far fa-save mr-2"></i>SAVE FORM
              </button>
            </div>


            <div class="row" *ngIf="therapyForms" >
              <!-- Configure Form -->
              <form  id="therapyForm" autocomplete="off" 
              novalidate>
              <div class="modal pdg_top" tabindex="-1" role="dialog"  style="display: block;">
                <div class="modal-dialog mat-dialog-container " role="document">
                  <div class="modal-content border-0  therapydialog"  >
                    <div>
          <div class="mt--14 libraryImages-container">
                <div  class="modal-header p-2">
                  <span >
                      <h5  class="modal-title">Create Therapy</h5>
                  </span>
                  <button (click) ="therapyFormclose()" type="button" mat-dialog-close data-dismiss="modal" aria-label="Close" class="close ">
                      <span  aria-hidden="true">×</span></button>
                  </div>
              <div class="col-md-12 ">
                  <div class="col-md-12 p-0">
                      <div class="top-notes d-flex w-100 position-relative mt-3">
                      </div>
                      <div class="task-create-section">
                      <div class="col-md-12">
                          <div class="row">
                              <mat-form-field class="w-100">
                              <input matInput placeholder="Therapy"   
                                 name="FormName" [(ngModel)]="therapyFormName"
                                required>                      
                               <!-- <mat-error *ngIf="formrenamedialog.submitted && libraryName.invalid ">
                                  <mat-error *ngIf="libraryName.errors.required">Library name is required</mat-error>
                                </mat-error> -->
                            </mat-form-field>
                          </div>
                      </div>
                      <div class="col-md-12 text-center position-relative mt-3">
                                  <span> 
                                      <button   type="button"  (click)="saveFormName()" mat-raised-button
                                      class="primary-btn-outlined-small mr-2">Add</button>
                                  </span>   
                                     <!-- <button mat-raised-button class="primary-btn-outlined-small mr-2" (click)="resetnotesform()">Reset
                                      </button>  -->                   
                      </div>
                  </div>
                  </div>
              </div>
          </div>
                    </div>
                  </div>
                </div>
              </div>
              </form>
          </div>


          </div>
        </div>
      </form>
    </div>
  </div>
  <!--Form details starts-->
  <div class="col-md-12 p-0 created-form-section view-form-meta" *ngIf="isSkillTaughtExist">
    <div class="lead-box pt-2 pb-2">
      <div class="clearfix">
        <div class="col-md-12">
          <div class="form-details-head">Form Details</div>
          <div class="form-details col-md-12 mt-3 mb-3 p-0">
            <div class="row">
              <div class="col-md-11">
                <div class="row">
                  <div class="col-md-5">
                    <div>
                      <label class="form-detail-label">Form Name :</label>
                      <span
                        class="form-detail-Value text-truncate align-middle d-inline-block"
                      >
                        {{skillTaughtmasterDataById.skillTaught}}
                      </span>
                      <span>
                        <a
                          class="icon-note cursor-pointer"
                          title="Edit Form Name"
                          *ngIf="!isViewMode" (click)="oneditFormName(skillTaughtmasterDataById)"
                          ><span class="mr-2"> <i class="far fa-edit"></i></span
                        ></a>
                      </span>
                    </div>
                    <div>
                      <p class="form-detail-label">
                        Created On :
                        <span class="form-detail-label">{{global.globalUtctimeformat(skillTaughtmasterDataById.createdOn | date:'MM/dd/yyyy hh:mm a')}}</span>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4 p-0">
                    <div>
                      <label class="form-detail-label">Client Type :</label>
                      <span class="text-truncate align-middle d-inline-block font-weight-bold" *ngIf="skillTaughtmasterDataById.isIop && skillTaughtmasterDataById.isPcp">IOP,</span>
                      <span class="text-truncate align-middle d-inline-block font-weight-bold" *ngIf="skillTaughtmasterDataById.isIop && !skillTaughtmasterDataById.isPcp">IOP</span>
                      <span class="text-truncate align-middle d-inline-block font-weight-bold" *ngIf="skillTaughtmasterDataById.isPcp">PCP</span>
                      <!-- <span class="w200px text-truncate align-middle d-inline-block font-weight-bold" *ngIf="skillTaughtmasterDataById.isIop">IOP</span>
                      <span class="w200px text-truncate align-middle d-inline-block font-weight-bold" *ngIf="skillTaughtmasterDataById.isPcp">PCP</span> -->
                    </div>
                    <div>
                      <p class="form-detail-label">
                        Last Updated On :
                        <span class="form-detail-label">{{global.globalUtctimeformat(skillTaughtmasterDataById.updatedOn | date:'MM/dd/yyyy hh:mm a')}}</span>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label class="form-detail-label-created">Therapy :</label>
                    <span class="align-middle d-inline-block font-weight-bold">{{skillTaughtmasterDataById.therapy}}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-1 text-left clearfix mt-2 pr-1 pl-1">
                <button
                  type="button"
                  class="btn primary-btn-outlined float-right" *ngIf="!isEditEnabled && !isViewMode" (click)="isEditEnabled=true;"
                >
                  <i class="fas fa-plus-circle mr-2"></i> Add Skill
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Form details ends-->

  <div class="col-md-12 p-0 created-form-section view-form-meta" *ngIf="isSkillTaughtExist">
    <div class="lead-box pt-2 pb-2">
      <div class="box-lead p-3">
        <!-- <div class="form-details-head mb-4">Add New Skill</div> -->
        <div class="form-details-head mb-4" *ngIf="isEditEnabled">Enter New Skill</div>
        <div class="row mt-3" *ngIf="isEditEnabled">
          <div class="col-md-10 pr-0 created-form-section">
            <form id="addQuestionForm" autocomplete="off" #addQuestionForm="ngForm" novalidate>
              <mat-form-field class="example-full-width w-100">
                <mat-label>Enter Skill</mat-label>
                <textarea matInput required placeholder="Enter Skill" [(ngModel)]="skillTaughtQuestionForm.question" name="question" #question="ngModel" ></textarea>
              </mat-form-field>
              <mat-error *ngIf="isQuestionFormSubmitted && addQuestionForm.controls.question?.errors?.required">This field is required</mat-error>
              <!-- <quill-editor [(ngModel)]="skillTaughtQuestionForm.question" name="question" #question="ngModel"  [modules]="quillConfig"
              required>
            </quill-editor> -->
            </form>
          </div>
          <div class="col-md-2 p-0 created-form-section">
            <button type="submit" class="btn primary-btn-filled float-right mr-2" (click)="postQuestionForm(addQuestionForm)"> <i class="far fa-save mr-2"></i>SAVE</button>
            <button type="submit" class="btn primary-btn-filled float-right mr-2" (click)="disableEdit()" >CANCEL</button>
          </div>
        </div>

        <!-- Question List Region -->
        <div class="form-details-head mb-4" *ngIf="isQuestionsAvailable">Skills List</div>
        <div class="row" *ngIf="isQuestionsAvailable">
          <div class="col-md-4" *ngFor="let item of skillTaughtQuestions;let i = index">
            <div class="card list-grid mb-3">
              <button
                aria-controls="dropdown-basic"
                class="btn btn-sm btn-neutral text-right mr-0 dropdown-toggle"
                dropdowntoggle=""
                id="button-basic"
                type="button"
                aria-haspopup="true"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenuLink{{in}}"
                *ngIf="!isViewMode"
              >
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in "
              aria-labelledby="dropdownMenuLink{{in}}">
                <a class="dropdown-item cursor-pointer" title="Edit"
                    (click)="onEditQuestion(item)">Edit</a>
                <a class="dropdown-item cursor-pointer" title="Delete"
                    (click)="onDeleteQuestion(item)">Delete</a>
              </div>

              <div class="question text-truncate m-4"><span>S{{i+1}}.</span>
                <span class="ql-container ql-snow" style="border-width: 0;">
                    <span class="ql-editor" [innerHTML]="item.skillTaughtQuestion">
                    </span>
                </span>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="col-md-12 p-0 mt-4" *ngIf="!isSkillTaughtExist">
  <div class="lead-box top">
      <div class="clearfix w-100 ">

          <div class="box-lead-head float-left">Therapy List</div>
          <div>
            <button
            type="submit"
            class="btn primary-btn-filled float-right mr-2"
            (click)="ontherapyEdit()" 
          >
            Add Therapy
          </button>
          </div>
          
      </div>
      <div class="col-md-12  table-custom p-0 tablemaster">
          <div class="table-responsive">
              <mat-table #table [dataSource]="lstMaster" matSort class="w-100 " >
                  <ng-container matColumnDef="sno" sticky>
                      <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                      <!-- <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell> -->
                      <mat-cell *matCellDef="let element; let i = index;"> 
                          <!-- <mat-icon 
                                      >reorder</mat-icon> -->
                         
                      <span> {{i+1}}</span>
                      </mat-cell>

                  </ng-container>

                  <ng-container matColumnDef="TherapyName" >
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Therapy Name </mat-header-cell>
                      <mat-cell *matCellDef="let element" > {{element.therapyName}} </mat-cell>
                  </ng-container>                 

                  <ng-container matColumnDef="IsActive" >
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Active </mat-header-cell>
                      <mat-cell *matCellDef="let element" > {{element.gttherapyIsActive == 1 ? 'Yes':'No'}} </mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="actions" >
                      <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                      <mat-cell class="text-right" *matCellDef="let element;let i = index;" >
                          <div style="width: 40px;" >
                              <button type="button" mat-icon-button matTooltip="Edit"
                                  
                                  (click)="oneditMasters(element)" color="primary">
                                  <img src="../../../assets/images/editblue.png">
                              </button>
                          </div>
                          <div style="width: 40px;" >
                              <button mat-icon-button matTooltip="Delete" (click)="ondeleteMasters(element)"
                                  color="warn">
                                  <img src="../../../assets/images/deletered.png">
                              </button>
                          </div>
                          <div style="width: 40px;" >
                              <span (click)="onActiveSkilltaught(element)">
                                  <i class="fas fa-toggle-on fa-toogle ml-2 mt-1" *ngIf="element.gttherapyIsActive"></i>
                                  <i class="fas fa-toggle-off fa-toogle ml-2 mt-1" *ngIf="!element.gttherapyIsActive"></i>
                              </span>
                          </div>
                      </mat-cell> 
                  </ng-container>

                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"
                  cdkDrag
                  [cdkDragData]="row"
                  ></mat-row>

              </mat-table>
          </div>
      </div>
      <mat-paginator  (page)="onPageChangeMaster($event)" 
      [pageSize]="20"[pageSizeOptions]="[ 10, 20, 50, 75, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
<div class="row" *ngIf="editForms" >
  <!-- Configure Form -->
  <form  id="editForms" autocomplete="off" 
  novalidate>
  <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
    <div class="modal-dialog mat-dialog-container " role="document">
      <div class="modal-content border-0  therapydialog"  >
        <div>
<div class="mt--14 libraryImages-container">
    <div  class="modal-header p-2">
      <span >
          <h5  class="modal-title">Edit Therapy</h5>
      </span>
      <button (click) ="editFormclose()" type="button" mat-dialog-close data-dismiss="modal" aria-label="Close" class="close ">
          <span  aria-hidden="true">×</span></button>
      </div>
  <div class="col-md-12 ">
      <div class="col-md-12 p-0">
          <div class="top-notes d-flex w-100 position-relative mt-3">
          </div>
          <div class="task-create-section">
          <div class="col-md-12">
              <div class="row">
                  <mat-form-field class="w-100">
                  <input matInput placeholder="Therapy"   
                     name="therapyName" [(ngModel)]="therapyName"
                    required>                      
                   <!-- <mat-error *ngIf="formrenamedialog.submitted && libraryName.invalid ">
                      <mat-error *ngIf="libraryName.errors.required">Library name is required</mat-error>
                    </mat-error> -->
                </mat-form-field>
              </div>
          </div>
          <div class="col-md-12 text-center position-relative mt-3">
                      <span> 
                          <button   type="button"  (click)="onChangeSkilltaught()" mat-raised-button
                          class="primary-btn-outlined-small mr-2">Save</button>
                      </span>   
                         <!-- <button mat-raised-button class="primary-btn-outlined-small mr-2" (click)="resetnotesform()">Reset
                          </button>  -->                   
          </div>
      </div>
      </div>
  </div>
</div>
        </div>
      </div>
    </div>
  </div>
  </form>
</div>



import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { PatientService } from 'src/app/patient/patient.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import * as moment from 'moment';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MenuList, RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';


@Component({
  selector: 'app-patientslists',
  templateUrl: './patientslists.component.html',
  styleUrls: ['./patientslists.component.scss']
})

export class PatientslistsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('clientModal') clientModal = {} as TemplateRef<any>
  dataSource: MatTableDataSource<any>;
  public searchpatientListtxt : string;
  lstPatient: any = [];
  DecrptedlstPatient: any = [];
  subjectTag = "";
  idTag: any;
  parentemail: any;
  statusintake = constantVariables.status.forIntake;
  statusmeetingScheduled = constantVariables.status.meetingScheduled;
  statusintakeScheduled = constantVariables.status.intakeScheduled;
  statusintakeDeclined = constantVariables.status.intakeDeclined;
  statusintakeAccepted = constantVariables.status.intakeAccepted;
  statusintakePacket = constantVariables.status.intakePacket;
  statusmeetingReSchedule = constantVariables.status.meetingReSchedule;
  statusrequestcancel = constantVariables.status.requestcancel;
  statusPsyReschedule = constantVariables.status.psytherapyReschedule;
  ShowActivity: boolean= false;
  leadhistory: any[];
  ShowPatientStatus: boolean= false;
  ddlPsMaster:any;
  _dtoupdatePatientStatusDtls:any;
  patientStatusId;
  selectedScheduleModality:any="M";
  roleAccess: RoelAccess;
  //ScheduleroleAccess: RoelAccess;
  MenuAccess: MenuList;
  roleAccessPayment:RoelAccess;
  isLeadInformation : boolean;
  isTherapyInformation : boolean;
  PatientInfoId : any;
  reason : any;
  declineReason : boolean = true;
  commentReason : boolean = true;
  PatientDeclainIsActive : boolean ;
  selectedreason : any;
  selectedcomments : any;
  comments : any; 
  filteredOptions: any = [];
  PatientAccountId : any;
  clientTherapydetails: any = [];
  clientdialogRef: any;
  clinicianDetails: any;
  ShowPrimaryClinicianPopup : boolean = false;
  primaryclinicianName : any;
  primaryclinicianID:any;
  imageSource : any;
  parentFirstName : string;
  // PageIndx:any=1;
  // PageSz:any=5;
  // pageArray:any[];
  constructor(public patientservice: PatientService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, private route: ActivatedRoute,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
      // if(localStorage.getItem('roleId')=="4")
      // {
      //   this.router.navigate(['/patient-breifcase']);
      // }
      // else if(localStorage.getItem('roleId')!="4")
      //   {
      //   
      //   }   
      const pageid = constantVariables.pagelists.PatientAccount;
      this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
      this.MenuAccess = this.roleaccessservice.GetSiteHeaderAccess();

      const pageidPayment = constantVariables.pagelists.OutofpocketInvoice;
      //this.roleAccessPayment = this.roleaccessservice.GetRoleAccessByPageId(pageidPayment);
      this.roleAccessPayment = JSON.parse(localStorage.getItem('roleaccess')).pagepermissions.find(x => x.pageId == pageidPayment)
      this.getpatientlistView();
    }
  onPageChange($event) {
    this.lstPatient = this.DecrptedlstPatient.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  //  debugger;OnPatientDashboard
  // this.PageIndx=$event.pageIndex==0?1:$event.pageIndex;
  // this.PageSz=$event.pageSize;
  //this.getpatientlistView();
  }
  getpatientlistView() {
    this.spinnerservice.show();
    
     let locationid= Number(localStorage.getItem('sfcclocation'))==0?-1:Number(localStorage.getItem('sfcclocation'));
      this.patientservice.getadminLoginPatientList(locationid,0,0).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.DecrptedlstPatient = this.commonService.decrypdata(res.objInputString);
       //  console.log(this.DecrptedlstPatient);
         this.lstPatient = this.DecrptedlstPatient.slice(0, 20);
        this.dataSource = new MatTableDataSource(this.DecrptedlstPatient);
        this.dataSource.paginator = this.paginator;
        //for (const patient of this.lstPatient) {
        //  this.getProfileImage(patient.PatientInfoId);
        //}


      //    debugger;
      //  if(this.DecrptedlstPatient.length>0){
      //      this.lstPatient = this.DecrptedlstPatient.slice(0, this.PageSz);
      //     let totalnoofrecords=this.lstPatient[0].TotalNofRecords;
      //    this.pageArray=[];
      //  for (var i=0;i<totalnoofrecords; i++)
      //   {
      //     if (i % 5 == 0){
      //      this.pageArray.push(i);
      //     }
      //   }
      //   console.log("1");
      //   console.log(this.pageArray);
      //   this.dataSource = new MatTableDataSource(this.DecrptedlstPatient);
      //   this.dataSource.paginator = this.paginator;
      //  }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onIntakeSchedule(item) {
    if(this.MenuAccess.ScheduleIntake){
      localStorage.setItem("immediateActionId", item.ImmediateActionId);
      let obj = {
        name: item.FirstName,
        patientAccountId: item.PatientAccountId,
        pageid: 1,
        age:moment(item.Dob, "DD-MM-YYYY").diff(moment(), 'years'),
        gender: item.GenderType,
        location:item.SfccMasterId
  
      }
      this.router.navigate(['/intake-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(obj) } });
    }
    else{
      this.snackbar.error(constantVariables.SuccessMessage.AccessPermissionError)
    }
    
  }
  onPrepareIntake(type, data) {
    var patientName = data.FirstName +" "+ data.LastName;
    if (type == 1) {
      this.router.navigate(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1,patientinfoid:this.commonService.FrontEndEncryption(data.PatientInfoId),isPatientAccount:this.commonService.FrontEndEncryption(1),cftid:this.commonService.FrontEndEncryption(1),patientName : this.commonService.FrontEndEncryption(patientName) } });
    } else if (type == 2) {
      this.router.navigate(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1,patientinfoid:this.commonService.FrontEndEncryption(data.PatientInfoId),isPatientAccount:this.commonService.FrontEndEncryption(1),cftid:this.commonService.FrontEndEncryption(1),patientName : this.commonService.FrontEndEncryption(patientName) } });
    } else if (type == 3) {
      this.router.navigate(['/intake-survey'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1,isPatientAccount:this.commonService.FrontEndEncryption(1),patientName : this.commonService.FrontEndEncryption(patientName) } });
    } else if (type == 4) {
      this.router.navigate(['/intake-consent'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1,isPatientAccount:this.commonService.FrontEndEncryption(1),patientinfoid: this.commonService.FrontEndEncryption(data.PatientInfoId),patientName : this.commonService.FrontEndEncryption(patientName) } });
    }
  }
  onAcceptReschdule(data) {
    this.spinnerservice.show();
    this.patientservice.onReScheduleAccept(data.MeetingScheduleId).subscribe(
      res => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(res.message);
          this.getpatientlistView();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onDeclineReschdule(data) {
    this.spinnerservice.show();
    this.patientservice.onReScheduleDeny(data.MeetingScheduleId).subscribe(
      res => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(res.message);
          this.getpatientlistView();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onAcceptCancelIntake(data) {
    this.spinnerservice.show();
    let obj = {
      MeetingScheduleId: data.MeetingScheduleId,
      Type: 3
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.patientservice.onAcceptCancelIntake(dataToEncrypt).subscribe(
      (res: any) => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getpatientlistView();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onDeclineCancelIntake(data) {
    this.spinnerservice.show();
    let obj = {
      MeetingScheduleId: data.MeetingScheduleId,
      Type: 2
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.patientservice.onDeclineCancelIntake(dataToEncrypt).subscribe(
      (res: any) => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getpatientlistView();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onReturnLead(obj) {
    this.global.clearEmaillist();
    //this.subjectTag = "Lead";
    this.idTag = obj.PatientInfoId;
    this.parentemail = obj.Email;
    localStorage.setItem('patientId', obj.PatientInfoId);
    localStorage.setItem('parentname', obj.ParentList[0].FirstName);
    this.global.onOpenEmailPopup(obj.ParentList[0].Email);
  }
  OnActivityClick(Patient){
    //console.log(Patient)
    this.ShowActivity = true;
   // this.leadhistory=[];
    this.getLeadActivity(Patient.PatientInfoId)
  }
  onCloseActivitylog(){
    this.ShowActivity = false;
  }
  getLeadActivity(Id) {
    this.leadhistory=[]
    this.spinnerservice.show()
    this.patientservice.getLeadHistory(Id).subscribe(
      (res: any) => {
        if (res) {
          this.leadhistory = res;
          this.spinnerservice.hide()
        //  console.log(this.leadhistory)
        }
        this.spinnerservice.hide()
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  // onScheduleModality(item) {
  //     this.router.navigate(['/schedule-modality'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId),treatmentPlanDate:item.TreatmentPlanDate } });
  // }
  onRedirectTo(item,selectedType)
  {
    var patienName = item.FirstName +" "+ item.LastName;
    if(selectedType=="1")
    {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/patient-breifcase'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientInfoId), patientname : this.commonService.FrontEndEncryption(patienName)}}));
    window.open(url, '_blank');
    }
    else if(selectedType=="2")
    {
      this.router.navigate(['/schedule-modality'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId),treatmentPlanDate:item.TreatmentPlanDate,locationId :this.commonService.FrontEndDecryption(item.SfccMasterId) } });
    }
    if(selectedType=="3")
    {
     if(this.MenuAccess.TreatmentPlan){
    const url = this.router.serializeUrl(this.router.createUrlTree(['/treatmentplan'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId)   }}));
     window.open(url, '_blank');
     }
     else{
      this.snackbar.error(constantVariables.SuccessMessage.AccessPermissionError)
     }
    }
  }
  getPatientStatusMaster()
  {
    this.spinnerservice.show();
    this.patientservice.getPatientStatusMaster().subscribe(
      res => {
        if (res != null) {
          this.ddlPsMaster = this.commonService.decrypdata(res.objInputString);
          this.ddlPsMaster =this.ddlPsMaster.filter(x=>x.PatientStatusId!=1);
         this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }

  onShowStatusPopup(patient)
  {
 this.getPatientStatusMaster();
this._dtoupdatePatientStatusDtls=patient;
this.patientStatusId=patient.PatientStatusId;
this.ShowPatientStatus=true;
  }
  onCloseStatusPopup(){
    this.ShowPatientStatus=false;
    this.patientStatusId=0;
  }
  patientStatusradioChange(event){
    this._dtoupdatePatientStatusDtls.PatientStatusId=event.value;
  }
  updatePatientStatus(){
    this.spinnerservice.show();
    if( this._dtoupdatePatientStatusDtls.PatientStatusId==1 || this._dtoupdatePatientStatusDtls.PatientStatusId==0)
    {
    this.spinnerservice.hide();
    this.snackbar.error('Select any one  patient status');
    return;
  }
  this.ShowPatientStatus=false;
  this._dtoupdatePatientStatusDtls.profileImage='';
  const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoupdatePatientStatusDtls, false);
  this.patientservice.postPatientAccountStatus(dataToEncrypt).subscribe(res => {
      if (res) {
      this.patientStatusId=1;
      this.spinnerservice.hide();
       this.getpatientlistView();
      this.snackbar.success(res);
        }
    },
    err => {
     this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}
OnDischargeListView(){
  this.router.navigate(['/discharged-patient-list'])
}
OnAuthDetails(){
  this.router.navigate(['/auth-threshold'])
}
onScheduleModalityChange(val)
{
  this.selectedScheduleModality=val;
}
onSelectedModality(item)
{
  if(this.selectedScheduleModality=="M")
  {
    // item.ImmediateActionId != statusPsyReschedule
    this.router.navigate(['/schedule-modality'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId),treatmentPlanDate:item.TreatmentPlanDate,locationId :this.commonService.FrontEndDecryption(item.SfccMasterId)} });
    
  }
  else if(this.selectedScheduleModality=="S")
  {
    let obj = {
      name: item.FirstName,
      patientAccountId: item.PatientAccountId,
      pageid: 10,
      age:moment().diff(moment(item.Dob, "DD-MM-YYYY"), 'years'),
      gender: item.GenderType,
      lastName : item.LastName,
      location:item.SfccMasterId
    }
    this.router.navigate(['/phone-schoolmeet-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(obj) } });
  }
  else if(this.selectedScheduleModality=="P")
  {
    let obj1 = {
      name: item.FirstName,
      patientAccountId: item.PatientAccountId,
      pageid: 11,
      age:moment().diff(moment(item.Dob, "DD-MM-YYYY"), 'years'),
      gender: item.GenderType,
      lastName : item.LastName,
      location:item.SfccMasterId
    }
    this.router.navigate(['/phone-schoolmeet-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(obj1) } });
  }
}
onPatientPayment(item)
{
 // if(this.MenuAccess.PaymentDetails){
  if(this.roleAccessPayment.isView){
this.router.navigate(['/patient-payment-details'], { queryParams: { id:this.commonService.FrontEndEncryption(item.PatientAccountId),info:this.commonService.FrontEndEncryption(item.PatientInfoId) } });
}
else{
  this.snackbar.error(constantVariables.SuccessMessage.AccessPermissionError)
}
}
// OnSearchPatient(){
//   let tempPatientlist = this.DecrptedlstPatient
//   if(this.searchpatientListtxt !="" || this.searchpatientListtxt === undefined || this.searchpatientListtxt === null){
//     this.lstPatient = tempPatientlist.filter(
//       (x: any) =>
//     x.FirstName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
//     x.LastName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
//   }
//   else{
//    this.lstPatient = tempPatientlist.slice(0, 5);
//   }
// }
OnSearchPatient(){
  let tempPatientlist = this.DecrptedlstPatient;
  
  if(this.searchpatientListtxt !== "" && this.searchpatientListtxt !== undefined && this.searchpatientListtxt !== null){
    this.lstPatient = tempPatientlist.filter(
      (x: any) => {
        const fullName = `${x.FirstName.toLowerCase()} ${x.LastName.toLowerCase()}`;
        return fullName.includes(this.searchpatientListtxt.toLowerCase());
      }
    );
  } else {
    this.lstPatient = tempPatientlist.slice(0, 5);
  }
}

OnPatientDashboard(item){
    this.router.navigate(['/leaddetailview'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientInfoId), PageId: 2 } });
  }
OnPatientInsurance(item){
  if(item.IsSkipInsurance == true)
  {
    this.snackbar.error(item.FirstName +" "+item.LastName+" skipped the insurance")

  }else{
    this.router.navigate(['/cumulative-insurance'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientInfoId),patientAccId : this.commonService.FrontEndEncryption(item.PatientAccountId), PageId: 2 } });

  }
  
}
onIntakeDateReSchedule(item){
  if(this.MenuAccess.ScheduleIntake){
    localStorage.setItem("immediateActionId", item.ImmediateActionId);
    let obj = {
      name: item.FirstName,
      patientAccountId: item.PatientAccountId,
      pageid: 1,
      age:moment(item.Dob, "DD-MM-YYYY").diff(moment(), 'years'),
      gender: item.GenderType,
      location:item.SfccMasterId

    }
    this.router.navigate(['/intake-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(obj) } });
  }
  else{
    this.snackbar.error(constantVariables.SuccessMessage.AccessPermissionError)
  }
  
  }
  removeLead(item){
    this.PatientInfoId = item.PatientInfoId;
    this.PatientAccountId = item.PatientAccountId
    this.clientdialogRef = this.dialog.open(this.clientModal,

      { width: '40%' });
  
    this.PatientAccountId = item.PatientAccountId
     this.patientservice.getFutureTherpy(this.PatientAccountId,"f").subscribe(
      (res: any) => {
        this.clientTherapydetails = this.commonService.decrypdata(res.objInputString);
        
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  PopupClose(){
    this.declineReason = true;
    this.commentReason = true;
    this.isLeadInformation = false;
    this.reason = undefined;
    this.comments = '';
  }
  reasonchange(){
    if (this.reason == 'undefined'){
      this.declineReason = false;
      return;
    }
    this.declineReason = true;
  }
  movePatientInformation(){
    this.spinnerservice.show();
    if((this.reason == undefined || this.reason == 'undefined')|| (this.comments == undefined || this.comments == '')){
      if(this.reason == undefined || this.reason == 'undefined'){
        this.declineReason = false;
        this.spinnerservice.hide();
      }        
    if(this.comments == undefined || this.comments == ''){
          this.commentReason = false;
          this.spinnerservice.hide();
        }
                
      return;   
     
    }     
      this.selectedreason = this.reason;
      this.selectedcomments = this.comments;
      this.PatientDeclainIsActive = false;
      this.patientservice.RemovePatientAccount(this.PatientInfoId , this.PatientDeclainIsActive,this.selectedreason,this.selectedcomments).subscribe(
        (res: any) => {
          this.snackbar.success("Patient decline processed successfully");
          this.comments = '';
          this.spinnerservice.hide();
          this.reason = undefined;
          this.isLeadInformation = false;
          this. getpatientlistView();

        },
        err => {
          this.spinnerservice.hide();
        },
      );
      
    }
    DeclainListView(){
      this.router.navigate(['/patient-declainlist'])
    }
    commentchange(event){
      if (this.comments == '' || event == ''){
        this.commentReason = false;
        return;
      }
      this.commentReason = true;
    }
    PopupClosetherapy(){
      this.clientdialogRef = false;

      
    }
    yesPatientInformation(){
      this.PatientDeclainIsActive = false;
       this.patientservice.RemoveTherapy(this.PatientAccountId , this.PatientDeclainIsActive).subscribe(
        (res: any) => {
          this.snackbar.success("Future therapies removed successfully");
          this.clientdialogRef.close();
          this.spinnerservice.hide();
          this.isLeadInformation = true;
          // this.comments = '';
          // this.spinnerservice.hide();
          // this.reason = undefined;
          // this.isLeadInformation = false;
          // this. getpatientlistView();

        },
        err => {
          this.spinnerservice.hide();
        },
      );

    }
    noPatientInformation()
{
  this.clientdialogRef.close();
  this.isLeadInformation = true;

}  
onShowPrimaryClinicianPopup(item){
  this.primaryclinicianID = item.ChangedPrimaryClinicianId;
  this.ShowPrimaryClinicianPopup = true;
  this.getChmaTreatmentSummaryDetails(item.PatientAccountId);
}
onClosePrimaryClinicianPopup(){
  this.ShowPrimaryClinicianPopup = false;
}
getChmaTreatmentSummaryDetails(PatientAccountId) {
  localStorage.setItem('PrimaryPatientAccountId', PatientAccountId);
  this.spinnerservice.show();
  this.patientservice.getChmaTreatmentSummaryDetails(PatientAccountId).subscribe(
    (res: any) => {
      if (res != null) {
        this.clinicianDetails = res.dtoPrimaryClinicianList;   
        this.spinnerservice.hide(); 
    }
    }

    );
    this.spinnerservice.hide();

  }
  updatePrimaryClinician(){
    this.spinnerservice.show();
    let _obj = {
      PatientAccountId :   localStorage.getItem('PrimaryPatientAccountId'),
      primaryclinicianId : this.primaryclinicianID
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.patientservice.postPrimaryClinician(dataToEncrypt).subscribe(res => {
        if (res) {
        
        this.spinnerservice.hide();
        this.ShowPrimaryClinicianPopup = false;
         this.getpatientlistView();
        this.snackbar.success(res);
          }
      },
      err => {
       this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  OnFinancialProposalDetails(item)
  {
    // localStorage.setItem('patientId',item.PatientInfoId)
    // // localStorage.setItem('isFinancereview', 'true');
    // //   // this.isfinancialproposal = true;
    // // this.router.navigate(['/financial-review']);
    // this.parentFirstName =item.ParentName;
    // this.router.navigate(['/financial-proposal'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientInfoId),
    //    name: this.commonService.FrontEndEncryption(item.FirstName), 
    //    parentname: this.commonService.FrontEndEncryption(this.parentFirstName),
    //    page: this.commonService.FrontEndEncryption(2) } });
       this.router.navigate(['/financiallist'],{ queryParams: { id: this.commonService.FrontEndEncryption(item.PatientInfoId)}});
     
  }
  OnFinancialProposal(item)
  {
    localStorage.setItem('patientId',item.PatientInfoId)
    // localStorage.setItem('isFinancereview', 'true');
    //   // this.isfinancialproposal = true;
    // this.router.navigate(['/financial-review']);
    this.parentFirstName =item.ParentName;
    this.router.navigate(['/financial-proposal'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientInfoId),
       name: this.commonService.FrontEndEncryption(item.FirstName), 
       parentname: this.commonService.FrontEndEncryption(this.parentFirstName),
       page: this.commonService.FrontEndEncryption(2) } });
    //   this.router.navigate(['/financiallist'],{ queryParams: { id: this.commonService.FrontEndEncryption(item.PatientInfoId)}});
     
  }
    
}

import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { PatientService } from 'src/app/patient/patient.service';
import { IntakeCmhaComponent } from 'src/app/patient/intake/intake-cmha/intake-cmha.component';
import { chmaEditPatientDetails } from 'src/app/_models/_Patient/_ChmaEditPatientDetails';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
@Component({
  selector: 'app-cmha-evaluation',
  templateUrl: './cmha-evaluation.component.html',
  styleUrls: ['./cmha-evaluation.component.scss']
})
export class CmhaEvaluationComponent implements OnInit {
  // @ViewChild(IntakeCmhaComponent) chma;
  isMovedChma: boolean;
  queYesNo = constantVariables.questionType.YesNo;
  queMCSA = constantVariables.questionType.MCSA;
  queMCMA = constantVariables.questionType.MCMA;
  queDescriptive = constantVariables.questionType.Descriptive;
  queRatings = constantVariables.questionType.Ratings;
  survey = constantVariables.inTakeForms.Survey;
  patientInfoId: any;
  patientAccountId: any;
  lstIntakeEvaluation: any;
  patientDetails=new chmaEditPatientDetails();
  roleAccess: RoelAccess;
  constructor(public global: Global, public patientservice: PatientService, private chma: IntakeCmhaComponent,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.PrepareCHMA;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.isMovedChma = Number(this.commonService.FrontEndDecryption(params.isChma).replace(/"/g, "")) == 0 ? false : true;
      }
      this.getEvaluationQuestion();

    });
  }
  getEvaluationQuestion() {
    this.spinnerservice.show();
    this.patientservice.getInTakeQuestions(9, this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.lstIntakeEvaluation = cloneDeep(res);
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onSaveClinicalQuestion(type) {
    // if (!this.isMovedChma) {
    //   this.spinnerservice.show();
    //   this.patientservice.postIntakeNutritional(this.lstIntakeEvaluation).subscribe(
    //     (res: any) => {
    //       this.getEvaluationQuestion();
    //       this.onSaveChmaStatus();
    //       this.spinnerservice.hide();
    //       this.snackbar.success(JSON.parse(res).message);
    //       if (type == 1) { 
    //         window.close();
    //       }
    //       else {
    //         this.chma.onTabChange(4);
    //       }
    //     },
    //     err => {
    //       this.spinnerservice.hide();
    //       this.snackbar.error(err.error);
    //     },
    //   );
    // } else {
    //   this.chma.onTabChange(4);
    // }
        this.spinnerservice.show();
    this.patientservice.postIntakeNutritional(this.lstIntakeEvaluation).subscribe(
      (res: any) => {
        this.getEvaluationQuestion();
        this.onSaveChmaStatus(type);
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        if (type == 1) {
          window.close();
        }
        else if(type != 2)  {
          this.chma.onTabChange(4);
        }
      },
         err => {
           this.spinnerservice.hide();
           this.snackbar.error(err.error);
         },
       );
  }
  onSaveChmaStatus(type : any) {
    let _obj = {
      PatientAccountId: this.patientAccountId,
      FormType: 3,
      patientdetails:this.patientDetails,
      Type : type
    }
    // const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.patientservice.postChmaStatus(_obj).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.chma.getChmaStatus();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  onPrevcmhatab() {
    this.chma.onTabChange(2);
  }
}


<div class="col-md-12">
    <div class="lead-box">
        <div class="head-section mb-2 mt-0">Image Library</div>
        <div class="row">
            <div class="col-md-3">
                <mat-form-field class="w-100">
                    <mat-label>Library</mat-label>
                    <mat-select  (selectionChange)="onloadImages($event.value)"  required>
                        <mat-option *ngFor="let item of libraryNames" [value]="item.libraryNameId">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submitted">
                        <mat-error >Library name is required</mat-error>
                    </mat-error>
                </mat-form-field>


            </div>
            <div class="col-md-3 mt-3">
                <a (click)="oneditNotes(1)" class="icon-note cursor-pointer" 
                title="Add Library Name" *ngIf="roleAccess.isCreate"><span class="mr-2"><i
                    class="fas fa-plus"></i></span></a>
                    <span *ngIf="isEditLibrary == true">                        
                        <a (click)="oneditNotes(2)" class="icon-note cursor-pointer" title="Edit Library Name"   *ngIf="roleAccess.isEdit"><span class="mr-2"><i
                            class="far fa-edit"></i></span></a>
                    </span>
            </div>
            <div class="col-md-9 mt-2" *ngIf="libraryNameId != 0 && (roleAccess.isCreate || roleAccess.isEdit)">
            <label class="file">
            <input  accept=".jpg,.jpeg,.png" title="Add Files" type="file" (change)="onFileChange($event)" 
                                  class=" cursor-pointer " #importphbook />
            <span class="file-custom"></span>
            </label>
           </div>
        <div class="col-md-12 mt-3 p-0">
        <div class="row">
            <!-- <div  class="float-left uploaded -file mr-1 mb-2 text-danger"
            *ngFor="let a of libraryImages;let index = index;"> -->
            <div  class="col-md-2 col-sm-3 col-xs-6 uploaded -file mr-1 mb-2 text-danger"
            *ngFor="let a of libraryImages;let index = index;">
            <a  class="upload-btn-custom">
                <div class="content-overlay"></div>
                <img  src="{{a.documentLocation}}" /> 
                <span class="options-container">
                    <!-- <i class="fas fa-edit fa-1x mr-2"></i> -->
                <i class="far fa-trash-alt remove-note"  *ngIf="roleAccess.isDelete" (click)="onRemoveImage(a)"></i>
                </span>
            </a>
            </div>
            <!-- <span class="file-lenght" (click)="onselectImage(a)" title="{{a.documentName}}">{{a.documentName}}</span> -->
            <!-- <img style="width: 30px; height: 30px;"  src="{{a.documentLocation}}" /> -->
            
          
        </div>
    </div>
        </div>

    </div>

import { Component, OnInit , ViewChild} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { CommonService } from 'src/app/common/common.service';
import { LeadService } from 'src/app/patient-lead/lead.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import * as moment from 'moment';

@Component({
  selector: 'app-financiallist',
  templateUrl: './financiallist.component.html',
  styleUrls: ['./financiallist.component.scss']
})
export class FinanciallistComponent implements OnInit {

 
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['Name', 'ComponentName', 'PriceValue', 'Type','Status', 'SubmittedDate','OrderId','AcceptedDate','Occurrence'];

  pendingDocuments: any = [];
  isClinician: boolean = false;
  selectedLocation: any;
  roleAccess: RoelAccess;
  Data:string;
  LocationId:any;
  patientinfoid:Number;

  constructor(
    public spinnerservice: NgxSpinnerService,
    public clinicianservice: ClinicianService,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    public roleaccessservice: PageAccessService,
    public lead:LeadService,

  ) {}

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Audit;
   // this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.pendingDocuments ="";
    this.LocationId=36;
    this.patientinfoid=0;
    this.route.queryParams.subscribe(params => {
    if(params.id){
      this.patientinfoid = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
    }
  })
    this.getpendingDocuments();
    

  }
  getpendingDocuments() {
   
   
    this.spinnerservice.show();
    this.lead.getFinancialDocuments(this.patientinfoid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pendingDocuments = res;
        // this.pendingDocuments = this.commonService.decrypdata(res.objInputString);
        //  console.log(this.DecrptedlstPatient);
        this.dataSource = new MatTableDataSource(this.pendingDocuments);
        this.dataSource.paginator = this.paginator;
        if(res.length==0)
          {
            this.Data="No Record Found";
          }
      },
      (err) => {
        this.spinnerservice.hide();
      }
    );
  }
  OnViewDetails(item) {
    this.router.navigate(['/audit-clinician'], {
      queryParams: {
        id: this.commonService.FrontEndEncryption(item.PatientAccountId),
      },
    });
  }
  userFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  OnBackClick() {
    this.router.navigate(['/leadlist']);
  }
  filterChange(value) {
    let filterChange;
    if (value == '1') {
      filterChange = this.pendingDocuments.filter((x) => x.Pending != 0);
    } else if (value == '2') {
      filterChange = this.pendingDocuments.filter((x) => x.Completed != 0);
    } else if (value == '3') {
      filterChange = this.pendingDocuments.filter((x) => x.Overdue != 0);
    } 
    else if (value == '4') {
      filterChange = this.pendingDocuments.filter((x) => x.YetToStart != 0);
    } else {
      filterChange = this.pendingDocuments;
    }
    this.dataSource = new MatTableDataSource(filterChange);
    this.dataSource.paginator = this.paginator;
  }

  onListEdit(item)
  {
    
    if (item.DocumentType == 'Individual Theraphy') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(constantVariables.therapy.IndividualTheraphy)}}));
  
    // window.open(url, '_blank');  
    
    window.open(url, '_blank');  
    }
 
  }
  onBacklead()
  {
    this.router.navigate(['/patientslists'])
  }
}

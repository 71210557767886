export class phonebook {
    phoneBookMasterId: number;
    physicianName: string;
    phoneNumber: string;
    cellPhone: string;
    faxNumber: string;
    hospitalName: string;
    hospitalAddress: string;
    isActive: boolean;
    firstName :string;
    lastName:string;
    email:string;
    addressLine1:string;
    addressLine2:string;
    statesId:number;
    cityId:number =0;
    zipCode:number;
    occupation:string;
    SFCCMasterId:number;
}
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { UserserviceService } from 'src/app/usermanagement/userservice.service';
import { roles } from 'src/app/_models/_Users/users';
import { RoleserviceService } from '../roleservice.service';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { constantVariables } from 'src/app/constant/constant';
@Component({
  selector: 'app-manage-permissions',
  templateUrl: './manage-permissions.component.html',
  styleUrls: ['./manage-permissions.component.scss']
})
export class ManagePermissionsComponent implements OnInit {
  dtorole: roles;
  allRoles: any;
  allsupermodules: any;
  ddlRole: string;
  pageListDetails: any;
  IsShow:boolean=false;
  roleAccess: any;
  constructor(private userService: UserserviceService, private roleService: RoleserviceService,public spinnerservice: NgxSpinnerService,
    private snackbar: SnackBarService
    ,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.StaffManagement;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.dtorole = new roles();
    this.loadAllRoles();
  }
  

  
  loadAllRoles() {
    this.userService.getRoles().subscribe(data => {
      this.allRoles = data;
      this.allRoles = this.allRoles.filter(x => x.id != 1);
      this.ddlRole = '1';
    //   this.allsupermodules = this.pageListDetails.map(item => item.superModule)
    //     .filter((value, index, self) => self.indexOf(value) === index);
     });

  }

  getroleAccess() {
    this.dtorole.details = [];
    var isvalid = true;
    if (this.dtorole.roleId == 0 || this.dtorole.roleId == -1 || this.dtorole.roleId == undefined || this.dtorole.roleId == null) {
      isvalid = false;
    }
    
    // if (!this.dtorole.isAll) {
    //   if (this.dtorole.location == 0 || this.dtorole.location == -1 || this.dtorole.location == undefined || this.dtorole.location == null) {
    //     isvalid = false;
    //   }
    // }
    else {
      this.dtorole.location = -1;
    }
    if (!isvalid)
      return;
    this.roleService.getManagePermissions(this.dtorole).subscribe(res => {
      if (res) {
        this.dtorole.details = JSON.parse(res).details;
        this.dtorole.isSave = JSON.parse(res).isSave;
      }
    },
      err => {

      }
    );
  }

  onSubmitRole() {

    var isvalid = true;

    if ((this.dtorole.details != null && this.dtorole.details.length == 0) || this.dtorole.details == null || this.dtorole.details == null) {

      isvalid = false;
    }

    const _obj = {
      Details: this.dtorole.details,
      IsSave: this.dtorole.isSave
    }

    this.spinnerservice.show();
    this.roleService.saveManageAccess(this.dtorole)
      .subscribe(
        data => {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(data).message);
          this.getroleAccess();
        },
        error => {
          this.spinnerservice.hide();
          this.snackbar.error(error.error);
        });
  }


}

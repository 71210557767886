<div class="invoice-page">
    <div class="col-md-12">
      <div class="row">
        <div class="d-flex w-100">
          <div class="head-section mt-2">Invoices</div>
          <div class="ml-auto">
            <button  tabindex="0" class="btn back-btn-new mr-2" (click)="onBackClick()">
                <i  class="fas fa-chevron-left mr-2"></i>Back</button>
          </div>
        </div>
      </div>
    </div>
    <div class="invoice-section col-md-12 mt-2">
      <div class="row">
        <!-- <div class="col-md-3 pl-0">
          <div class="search">
            <input  type="text" placeholder="Search" name="" class="search-input " >
            <a  class="search-icon">
              <i  class="fa fa-search"></i>
            </a>
          </div>
        </div> -->
        <div class="col-md-3 ml-auto text-right pr-0 mb-2">
          <span  class="filter-icon mr-2"><i  class="fa fa-filter"></i></span>
          <mat-form-field class="custom-border-date align-middle" appearance="outline">
            <mat-label>Choose a date</mat-label>
            <!-- <input matInput [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker> -->
            <input matInput [matDatepicker]="datePicker" [(ngModel)]="SelectedDate" (dateChange)="SearchbyDate()" [max]="today">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker (monthSelected)="setMonthAndYear($event, datePicker)" startView="year"></mat-datepicker>
          </mat-form-field>
        </div>

        
      </div>
      <div class="row" *ngIf="OutofPktPaymentCompletedLists.length>0">
        <div class=" card card-table mb-0 w-100">
          <div class="card-body p-0">
          <div class="table-responsive">
          <table class="table table-hover table-center mb-0">
          <thead>
          <tr>
          <th>Invoice No</th>
          <th>Therapy</th>
          <th>Frequency</th>
          <th>Duration</th>
          <th>Paid Amount</th>
          <th>Payment Type</th>
          <th>Paid On</th>
           </tr>
          </thead>
          <tbody >
              <tr *ngFor="let item of OutofPktPaymentCompletedLists;let i=index">
                <td>{{item?.InvoiceNo}} </td>
                <td><h6 class="table-avatar">{{item?.TherapyName}}</h6></td>
                <td> {{item?.PayFrequencyName}}</td>
                <td> {{global.getMinutesSlot(item?.Duration)}}</td>
                <td> {{item?.PaidAmount}}</td>
                <td>{{item?.PaymentType}}</td>
                <td>{{item?.PaidOn | date: 'MM/dd/yyyy'}}</td>
              </tr>
            
          </tbody>
         
          </table>
          </div>
          </div>
          </div>
      </div>
      
        <div class="row align-middle" *ngIf="OutofPktPaymentCompletedLists.length==0">
            <div class="col-md-12">
             No Records Found
        </div>
        </div>


    </div>
   </div>
<div class="row p-heading">
  <div class="col justify-content-start">

    <h2 class="head-section mt-2">Manage permission </h2>
  </div>
</div>
<div class="col-md-12 p-0 mb-3">
  <div class="lead-box p-0">
    <form (ngSubmit)="userinfo.form.valid  && onSubmitRole()" id="userinfo" autocomplete="off"
          #userinfo="ngForm" novalidate>
      <div class="box-lead-head"></div>
      <div class="col-md-12 p-0">
        <div class="row">
          <div class="col-md-3">
            <mat-form-field class="w-100">
              <mat-label>Role</mat-label>
              <mat-select [(ngModel)]="dtorole.roleId" #roleId="ngModel" name="roleId"
                          [ngClass]="{ 'is-invalid': userinfo.submitted && roleId.invalid }" required>
                <mat-option *ngFor="let item of allRoles" [value]="item.id" (click)="getroleAccess()">
                  {{item.roleName}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="userinfo.submitted && roleId.invalid">
                <mat-error *ngIf="roleId.errors.required">
                  Role is required
                </mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          <!-- <div class="col-md-3">
              <mat-form-field class="w-100">
                  <mat-label>Location</mat-label>
                  <mat-select [disabled]="dtorole.isAll == true" [(ngModel)]="dtorole.location" #location="ngModel" name="location"
                  [ngClass]="{ 'is-invalid': userinfo.submitted && location.invalid }" required>
                  <mat-option *ngFor="let item of ddlSfccLocation" [value]="item.sfccMasterId" (click)="getroleAccess()">
                      {{item.locationName}}
                  </mat-option>
              </mat-select>
                  <mat-error *ngIf="userinfo.submitted && location.invalid && !dtorole.isAll">
                      <mat-error *ngIf="location.errors.required">Location  is required
                      </mat-error>
                  </mat-error>
              </mat-form-field>

          </div>
          <div class="col-md-3" >
              <div class="check-custom mt-4">
                  <mat-checkbox class="example-margin" [(ngModel)]="dtorole.isAll"
                      #isActive="ngModel" name="parentIsPrimaryContact" (change)="onCheck($event)">All Locations
                  </mat-checkbox>
              </div>
          </div> -->
          <div class="col-md-3 text-right mt-2">

            <!-- <button type="submit" mat-stroked-button form="userinfo" title="Save"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" *ngIf="dtorole.details != null && dtorole.details.length != 0" (click)="onSubmitRole()">Save</button> -->
            <!-- <button (click)="resetuserform(userinfo)" type="button" mat-stroked-button title="Cancel"
                class="btn btn-cancel primary-btn-m h-40">Cancel</button> -->
          </div>
        </div>
      </div>

    </form>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <div class="row">
      <!--For looping the options-->
      <div class="role-permission col-md-12" *ngFor="let item of dtorole.details">
        <div class="permission-container" [ngClass]="item.isActive?'active':''">
          <div class="permis-heading" (click)="item.isActive=!item.isActive">{{item.moduleName}} <i class="fas fa-chevron-down ml-2"></i></div>
          <div class="expand-table">
            <div class="table-reponsive pt-2">
              <table class="table table-custom text-center table-history">
                <thead>
                  <tr *ngIf="item.moduleName == 'Scheduler'">
                    <th>Accounts</th>
                    <th>Create</th>
                    <th>Edit/Reschedule  </th>
                    <th>Delete</th>
                    <th>View</th>
                    <th>Approve/Reject  </th>
                    <th>Signature </th>
                    <th>Intern Sign </th>
                    <th>Unlicensed Sign </th>
                    <th>Unlock Progress Note </th>
                  </tr>
                  <tr *ngIf="item.moduleName != 'Scheduler'">
                    <th>Accounts</th>
                    <th>Create</th>
                    <th>Edit/Reschedule  </th>
                    <th>Delete</th>
                    <th>View</th>
                    <th>Approve/Reject  </th>
                    <th>Signature </th>
                    <th>Intern Sign </th>
                    <th>Unlicensed Sign </th>
                  </tr>


                </thead>
                <tbody *ngFor="let page of item.pagepermissions">

                  <tr *ngIf="(page.typeid==1 || page.typeid==2) &&  page.pageName != 'Profile' && item.moduleName != 'Scheduler'">
                    <td>
                      {{page.pageName}}
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isCreate">
                        </mat-checkbox>
                      </div>

                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isEdit">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isDelete">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="(page.typeid==1 || page.typeid==2)" [(ngModel)]="page.isView">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isApprove">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isSign">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isInternSign">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isUnlicensed">
                        </mat-checkbox>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="(page.typeid==1 || page.typeid==2) && item.moduleName == 'Scheduler'">
                    <td>
                      {{page.pageName}}
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isCreate">
                        </mat-checkbox>
                      </div>

                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isEdit">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isDelete">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="(page.typeid==1 || page.typeid==2)" [(ngModel)]="page.isView">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isApprove">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isSign">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isInternSign">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isUnlicensed">
                        </mat-checkbox>
                      </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" *ngIf="page.typeid==1" [(ngModel)]="page.isUnlockProgress">
                        </mat-checkbox>
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="page.typeid==3">
                    <td>
                      {{page.pageName}}
                    </td>
                    <td></td>
                    <td>
                      <div>
                        <mat-select class="form-control mb-2" [(ngModel)]="page.landingPage">
                          <mat-option *ngFor="let LP of page.landingPageMasters" [value]="LP.landingPageMasterid">{{LP.pageLable}}</mat-option>
                        </mat-select>
                      </div>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td>
                      Patient Account
                    </td>
                    <td><div class="check-custom ">
                      <mat-checkbox class="example-margin" >
                      </mat-checkbox>
                  </div>

                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                  </tr> -->

                </tbody>

              </table>
            </div>
          </div>
        </div>


      </div>



    </div>

  </div>
  <div class="col-md-12 text-right" *ngIf="roleAccess.isCreate || roleAccess.isEdit">
    <button mat-stroked-button
            class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" *ngIf="dtorole.details != null && dtorole.details.length != 0" (click)="onSubmitRole()">
      {{dtorole.isSave?'Update':'Save'}}
    </button>
    <button mat-stroked-button class="btn btn-cancel primary-btn-m h-40" (click)="getroleAccess()" *ngIf="dtorole.details != null && dtorole.details.length != 0">Cancel</button>
  </div>

</div>

<div class="top-btn-section clearfix srchtop" >
   
    <h2 class="head-section mb-2 mt-0 txtsize">Discharged List</h2> 

   </div> 
   <div class="col-md-10 text-right mrgnleft">
    <button type="button" class="btn primary-btn-outlined float-right mr-2" (click)="OnBackClick()"><i class="fas fa-angle-left pr-2"></i>Back</button>
        </div>
   
   <div class="discharge-lst create-patients">
    <div class="d-flex w-100 ">
      <div class="col-md-4 srchtop">
          <div  class="search srchlft">
              <input  type="text" placeholder="Search By Client Name" name="" class="search-input " [(ngModel)]="searchpatientListtxt" (keyup)="OnSearchPatient()">
              <a  class="search-icon"><i  class="fa fa-search"></i></a></div>
      </div>
      
  </div>
  <div  class="list-group contact-group mt--14">
    <a  class="list-group-item " *ngFor="let item of lstPatient">
      <div  class="col-md-12 p-0">
        <div  class="row">
            <div class="col-md-4 ">
                <div class="media">
                    <div class="pull-left">
                        <img class="rounded-circle"
                            src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                            alt="...">
                    </div>
                    <div class="media-body">
                        <h4 class="media-heading">{{item.FirstName}} {{item?.LastName}}</h4>
                        <div class="media-content">
                            <ul class="list-unstyled">

                                <li><i class="fas fa-mobile"></i> {{item.ParentList[0]?.ContactNo | mask: '(000)
                                    000-0000'}}</li>
                                <li><i class="fas fa-envelope"></i> {{item.ParentList[0]?.Email}}</li>
                                <!-- <li><span class="lead-stage-head stage-contact">{{item.LeadStage}}</span> </li> -->
                                <!-- <li><span class="lead-stage-head stage-contact">Intake</span> </li> -->
                                <li  class="mr-2 ml-auto d-flex"><span class="status-patient">Status</span>
                                    <span class="status-patient-value">{{item.PatientStatusName}}</span><a (click)="onShowStatusPopup(item)"  class="edit-status"><i class="far fa-edit"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="">
                    <label class="program-heading">Program Status</label>
                    <div>
                        <div class="patient-forms">
                            <a (click)="onPrepareIntake(1,item)" title="Health Screening"><img
                                    src="../../../assets/images/health_screen.png" class="img-fluid"></a>
                            <a (click)="onPrepareIntake(4,item)" title="Consent Form"><img
                                    src="../../../assets/images/consent-new.png" class="img-fluid"></a>
                            <a (click)="onPrepareIntake(2,item)" title="Checklist"><img
                                    src="../../../assets/images/health_insurance.png" class="img-fluid"></a>
                            <a (click)="onPrepareIntake(3,item)" title="Survey"><img
                                    src="../../../assets/images/survey.png" class="img-fluid"></a>
                        </div>
                        <div class="w-100 float-left">
                            <div class="status-indicator">

                                <ul class="progress-indicator pl-2">
                                    <li [ngClass]="{'completed': item.IntakeStatus?.IsHealth}">
                                        <span class="bubble" title="Health Screening"></span>
                                    </li>
                                    <li
                                        [ngClass]="{'completed': item.IntakeStatus?.Verified == item.IntakeStatus?.Total }">
                                        <span class="bubble" title="Consent Form"></span>
                                    </li>
                                    <li [ngClass]="{'completed': item.IntakeStatus?.IsMedication}">
                                        <span class="bubble" title="Checklist"></span>
                                    </li>
                                    <li [ngClass]="{'completed': item.IntakeStatus?.IsSurvey}">
                                        <span class="bubble" title="Survey"></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="icons-section ">
                    <!-- <button class="col text-center col-btn-dis"><i class="far fa-edit"></i></button>
                    <button class="btn btn-patient"><i class="fas fa-briefcase mr-2"></i>Briefcase View</button> -->
                    <button class="btn btn-patient" (click)="onRedirectTo(item,1)"><i class="fas fa-briefcase mr-2"></i>Briefcase View</button>
                    <!-- <button class="btn btn-patient" *ngIf="item.ChmaTreatmentSummaryId !== null" (click)="onScheduleModality(item,2)"><i class="far fa-calendar-check mr-2"></i>Schedule Modality</button> -->
                        <button class="btn btn-patient" (click)="OnActivityClick(item)">
                            <i class="fas fa-user-clock mr-2"></i> Activity</button>
                            <button class="btn btn-patient" (click)="OnResume(item)">
                                <i class="fas fa-undo rsume_icon"></i>Rejoin</button>
                </div>
                
            </div>
                      </div>
                    </div>
                  </a>
                  <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="20"
                  [pageSizeOptions]="[ 10, 20, 50, 75, 100]">
              </mat-paginator>
  </div>
  </div>
  <!--Activity popup-->
  <div class="Activity-popup">
    <div class="modal"  tabindex="-1" role="dialog" [style.display]=" ShowActivity ? 'block' : 'none'">
       <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
             <div class="modal-header">
                <h6 class="modal-title">Activity Log</h6>
                <button type="button" class="close"  data-dismiss="modal"
                aria-label="Close" (click)="onCloseActivitylog()">
                <span aria-hidden="true">&times;</span>
             </button>
             </div>
             <div class="modal-body">
                <div class="individual-threaphy-container">
                <div  class="history-list col-md-14 p-0">
                   <ul>
                      <li *ngFor="let item of leadhistory;let i = index">
                          <div class="list-history col-md-14">
                              <div class="row">
                                  <div class="col-md-1" *ngIf="item.roleId !== 4">
                                      <img class="img-fluid" src="../../../assets/images/move2stage-icon.png"/>
                                  </div>
                                  <div class="col-md-1" *ngIf="item.roleId == 4">
                                      <img class="img-fluid" src="../../../assets/images/move2stage-icon-green.png"/>
                                  </div>
                                  <div class="col-md-9">
                                      <p class="sub-history mb-1"></p>
                                      <p class="sub-history2 mb-1" innerHTML="{{global.getContent(item.message)}}"></p>
                                  </div>
                                  <div class="col-md-2 text-right">
                                      <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) |  date: 'MM/dd/yyyy'}}</p>
                                      <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) | date: 'hh:mm a' }}</p>
                                  </div>
                              </div>
                             
                          </div>
                      </li>
                     
                  </ul>
              </div>
          </div>
          </div>
       </div>
          <div class="col-md-12 p-0 mt-4 view-note d-none">
          
              <div class="row">
                  <div class="col-md-12 mb-1" *ngFor="let item of leadhistory;let i = index">
                      <div class="list-note">
                          <div class="row">
                              <div class="col-md-8">
                                  <div class="float-left w-100 pl-4 position-relative">
                                      
                                      <p class="note-content mt-1" innerHTML="{{global.getContent(item.message)}}">
          
                                      </p>
                                  </div>
                              </div>
                        
                  </div>
                </div>
                </div>
             </div>
          </div>
       </div>
    </div>
    </div>

    <!--Patient status pop up-->
    <div class="comment-popup benchmark-popup-rating">
        <div class="modal" tabindex="-1" role="dialog"  [style.display]="ShowPatientStatus ? 'block' : 'none'">
          <div class="modal-dialog mat-dialog-container p-3" role="document">
            <div class="modal-content border-0">
              <div class="modal-body">
      <div class=" mb-3">
        <p  class="text-center font-weight-500 mt-2">Do you want to change the Status ?</p>
        <div class="text-center">
            <!-- <mat-radio-group aria-label="Select an option">
                <mat-radio-button value="1" class="mr-2">IOP</mat-radio-button>
                <mat-radio-button value="2" class="mr-2">PCB</mat-radio-button>
                <mat-radio-button value="3" class="mr-2">Outpatient</mat-radio-button>
              </mat-radio-group> -->
        <!--      <mat-radio-group aria-label="Select an option" [(ngModel)]="patientStatusId" #PatientStatusId="ngModel" name="PatientStatusId"  >   -->
         <mat-radio-group aria-label="Select an option"  name="patientsts" [(ngModel)]="patientStatusId" #PatientStatusId="ngModel"  required > 
                 <mat-radio-button *ngFor="let item of ddlPsMaster; let i=index" class="mr-2"  [value]="item.PatientStatusId"   (change)="patientStatusradioChange($event)"> {{item.PatientStatusName}} </mat-radio-button>            
        </mat-radio-group>
        </div>
        
      </div>
      
      <div class="text-center">
        <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2"  (click)="updatePatientStatus()">Save</button>
        <button type="button" class="btn btn-cancel" 
          data-dismiss="modal"   (click)="onCloseStatusPopup()">Close</button>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>

      <!-- Resume popop -->
      <div class="Activity-popup">
        <div class="modal"  tabindex="-1" role="dialog" [style.display]=" ShowResumepopup ? 'block' : 'none'">
           <div class="modal-dialog modal-lg mdl_contnt" role="document">
              <div class="modal-content em_popup">
                 <div class="modal-header">
                    <h6 class="modal-title">Reoccurrence Process</h6>
                    <button type="button" class="close"  data-dismiss="modal"
                    aria-label="Close" (click)="onCloseResume()">
                    <span aria-hidden="true">&times;</span>
                 </button>
                 </div>
                 <div class="modal-body modal-bodyheader">
                    <mat-form-field class="mtform_field" >
                        <mat-select multiple class="custom-width" [(ngModel)]="selectedValues" matSelectPlaceholder="Select Process">
                            <!-- <mat-option disabled selected>{{ 'Select the intake process' }}</mat-option> -->
                            <mat-option *ngFor="let mtpoffive of fiveMTP" [value]="mtpoffive">{{mtpoffive}}</mat-option>
                        </mat-select>
                        <mat-placeholder>Select Process
                        </mat-placeholder>
                    </mat-form-field>
                    <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2 save-button" (click)="postResumeClients()">Save</button>
                    <div class="mt-2 fs-6">Note: Select either 'Comprehensive MHA' or 'Intake Update' from the dropdown menu</div>
            
                </div>
                
           </div>
              
           </div>
        </div>
        </div>
<div class="row" *ngIf="data.type!=1">
    <!-- Configure Form -->
    <form (ngSubmit)="saveFormName(configureForm)" id="configureForm" autocomplete="off" #configureForm="ngForm"
    novalidate>
<div class="mt--14 libraryImages-container">
    <div  class="modal-header p-2">
        <span >
            <h5  class="modal-title">Edit Form Name</h5>
        </span>
        <button  type="button" mat-dialog-close data-dismiss="modal" aria-label="Close" class="close ">
            <span  aria-hidden="true">×</span></button>
        </div>
    <div class="col-md-12 ">
        <div class="col-md-12 p-0">
            <div class="top-notes d-flex w-100 position-relative mt-3">
            </div>
            <div class="task-create-section">
            <div class="col-md-12">
                <div class="row">
                    <mat-form-field class="w-100">
                    <input matInput placeholder="Form Name"   [(ngModel)]="FormName"
                       name="FormName" 
                      required>                      
                     <!-- <mat-error *ngIf="formrenamedialog.submitted && libraryName.invalid ">
                        <mat-error *ngIf="libraryName.errors.required">Library name is required</mat-error>
                      </mat-error> -->
                  </mat-form-field>
                </div>
            </div>
            <div class="col-md-12 text-center position-relative mt-3">
                        <span>
                            <button type="submit" form="configureForm" mat-raised-button
                            class="primary-btn-outlined-small mr-2">Update</button>
                        </span>   
                           <!-- <button mat-raised-button class="primary-btn-outlined-small mr-2" (click)="resetnotesform()">Reset
                            </button>  -->                   
            </div>
        </div>
        </div>
    </div>
</div>
    </form>
</div>
<div class="row" *ngIf="data.type==1">
    <!-- Skill Taught Form -->
    <form (ngSubmit)="saveSkillTaughtForm(skillTaughtForm)" id="skillTaughtForm" autocomplete="off" #skillTaughtForm="ngForm"
        novalidate>
    <div class="mt--14 libraryImages-container">
        <div  class="modal-header p-2">
            <span >
                <h5  class="modal-title">Edit Form Name</h5>
            </span>
            <button  type="button" mat-dialog-close data-dismiss="modal" aria-label="Close" class="close ">
                <span  aria-hidden="true">×</span></button>
            </div>
        <div class="col-md-12 ">
            <div class="col-md-12 p-0">
                <div class="top-notes d-flex w-100 position-relative mt-3">
                </div>
                <div class="task-create-section">
                <div class="col-md-12">
                    <div class="row">
                        <mat-form-field class="w-100">
                        <input matInput placeholder="Form Name" #formName  [(ngModel)]="skillTaughtMaster.skillTaught"
                           name="formName" 
                          required>                      
                         <!-- <mat-error *ngIf="formrenamedialog.submitted && libraryName.invalid ">
                            <mat-error *ngIf="libraryName.errors.required">Library name is required</mat-error>
                          </mat-error> -->
                      </mat-form-field>
                    </div>
                </div>
                <div class="col-md-12 text-center position-relative mt-3">
                            <span>
                                <button type="submit" form="skillTaughtForm" mat-raised-button
                                class="primary-btn-outlined-small mr-2">Update</button>
                            </span>   
                               <!-- <button mat-raised-button class="primary-btn-outlined-small mr-2" (click)="resetnotesform()">Reset
                                </button>  -->                   
                </div>
            </div>
            </div>
        </div>
    </div>
    </form>
</div>

<div class="clnical">
    <div class="col-md-12 d-flex">
        <span *ngIf="global.TSummaryUpdatedOn !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
            Updated on:
            <span>{{global.globalUtctimeformat(global.TSummaryUpdatedOn) | date:
                'MM/dd/yyyy hh:mm a'}}</span></span>
                <span *ngIf="global.TSummaryUpdatedByName !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
                    Updated By:
                    <span>{{global.TSummaryUpdatedByName }}</span></span>
        <div class="text-right ml-auto">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <button type="button" (click)="onSaveChmadetails()"  *ngIf="!isMovedChma && isAssigned && (roleAccess.isCreate||roleAccess.isEdit)"   mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Submit</button>

        </div>
    </div>

    <div class="col-md-12 set1-temp" *ngIf="isAssigned">
        <div class="row mb-2" >






                <div class="list-group contact-group mt--14 w-100" *ngIf="isMovedChma" >
                    <a class="list-group-item clinician-assign" *ngFor="let clinical of clinicalsummaryList" >
                        <div class="col-md-12 p-0" [ngClass]="{'disabled':isMovedChma}">
                            <div class="col-md-12 p-0">
                              
                   
                                    <div class="col-md-3">
                                          <div class="d-flex w-100 mt-2 ">MTP Name : <span>&nbsp;{{clinical.mtpname}}</span> </div>
                                      
                                    </div>                     
                                  
                                        
                                    
                              
                                <div class="additional-steps">
                              
                                    <div class="col-md-12">
                                        <div class="row">
                                            
                                            <div class="col-md-3">
                                                <div class="form-group row">
                                                    <label for="staticEmail" class="col-md-12 col-form-label">Primary Clinician</label>
                                                    <div class="col-md-12 ">
                                                        <mat-form-field class="w-100">
                                                            <mat-select (selectionChange)="errormsgChange()"[(ngModel)]="clinical.primaryClinicianId" #PrimaryClinician="ngModel"
                                                                name="PrimaryClinician">
                                                                <mat-option *ngFor="let item of clinicianDetails" [value]="item.userId">
                                                                    {{item.firstName}} {{item.lastName}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <span *ngIf="isprimaryClinician" class="text-danger">Primary clinician is
                                                            required</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group row">
                                                    <label for="staticEmail" class="col-md-12 col-form-label">Treatment Plan
                                                        Date</label>
                                                    <div class="col-md-12 ">
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Choose a date</mat-label>
                                                            <input matInput [matDatepicker]="picker" readonly
                                                                (click)="picker.open()" [(ngModel)]="clinical.treatmentPlanDate"
                                                                #TreatmentPlanDate="ngModel" name="TreatmentPlanDate" (dateChange)="onDateChange()"
                                                                [ngClass]="{ 'is-invalid': TreatmentPlanDate.invalid }" >
                                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                        </mat-form-field>
                                                        <span *ngIf="istreatmentPlanDate" class="text-danger">Treatment Plan Date is
                                                            required</span>
                                                    </div>
                                                </div>
                                            </div>
                
                
                                            <div class="col-md-3">
                                                <div class="form-group row">
                                                    <label for="staticEmail" class="col-md-12 col-form-label">Overarching period end by
                                                    </label>
                                                    <div class="col-md-12 ">
                                                      
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Choose a date</mat-label>
                                                        
                                                            <input matInput [matDatepicker]="picker1" readonly
                                                                (click)="picker1.open()" [(ngModel)]="clinical.overarchingPeriod"
                                                                #OverarchingPeriod="ngModel" name="OverarchingPeriod" 
                                                                (dateChange)="checkValidation(clinical.treatmentPlanDate,clinical.overarchingPeriod,1)"
                                                                [ngClass]="{ 'is-invalid': OverarchingPeriod.invalid }">
                                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                            <mat-datepicker #picker1></mat-datepicker>
                                                        </mat-form-field>
                                                        <span *ngIf="overarchingDateExist" class="text-danger">Overarching Period Date is
                                                            required</span>
                                                           
                                                    </div>
                                                </div>
                                                <!-- <div class="form-group">
                                                    <label  class="col-md-12 col-form-label p-0 mb-2" for="exampleFormControlFile1">Upload a CSV file / PDF - ASEBA Report</label>
                                                   
                                                    <input multiple title="Add Files" accept="application/msword, application/vnd.ms-excel, application/pdf, .csv" type="file" (change)="onFileChange($event)" class="form-control-file mt-3 pt-1" #taskfile />
                                                                        <div class="col-md-9 mt-1 p-0">
                                                                            <mat-chip-list class="mt-2">
                        
                                                                                <mat-chip *ngFor="let a of UploadedFileList;let x = index" (click)="downloadUploadedFile(a)">
                                                                                    {{a.fileName}}
                                                                                    <mat-icon matChipRemove (click)="deleteUploadImage(x)">
                                                                                        cancel</mat-icon>
                                                                                </mat-chip>
                        
                                                                            </mat-chip-list>
                                                                        </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 p-0">
                                <div class="additional-steps">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group row">
                                                    <label for="staticEmail" class="col-md-12 col-form-label">Secondary Clinician</label>
                                                    <div class="col-md-12 ">
                                                        <mat-form-field class="w-100">
                                                            <mat-select (selectionChange)="errormsgChangeSecondary()"[(ngModel)]="clinical.secondaryClinicianId" #SecondaryClinician="ngModel"
                                                                name="SecondaryClinician">
                                                                <mat-option *ngFor="let item of clinicianDetails" [value]="item.userId">
                                                                    {{item.firstName}} {{item.lastName}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                        <span *ngIf="isSecondaryClinician" class="text-danger">Secondary clinician is
                                                            required</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group row">
                                                    <label for="staticEmail" class="col-md-12 col-form-label">Treatment Plan
                                                        Date</label>
                                                    <div class="col-md-12 ">
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Choose a date</mat-label>
                                                            <input matInput [matDatepicker]="picker2" readonly
                                                                (click)="picker2.open()" [(ngModel)]="clinical.treatmentPlanDateSecondary"
                                                                #TreatmentPlanDateSecondary="ngModel" name="TreatmentPlanDateSecondary" (dateChange)="onDateChangeSecondary()"
                                                                [ngClass]="{ 'is-invalid': TreatmentPlanDateSecondary.invalid }" >
                                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                            <mat-datepicker #picker2></mat-datepicker>
                                                        </mat-form-field>
                                                        <span *ngIf="istreatmentPlanDateSecondary" class="text-danger">Treatment Plan Date is
                                                            required</span>
                                                    </div>
                                                </div>
                                            </div>
                
                
                                            <div class="col-md-3">
                                                <div class="form-group row">
                                                    <label for="staticEmail" class="col-md-12 col-form-label">Overarching period end by
                                                    </label>
                                                    <div class="col-md-12 ">
                                                      
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Choose a date</mat-label>
                                                        
                                                            <input matInput [matDatepicker]="picker3" readonly
                                                                (click)="picker3.open()" [(ngModel)]="clinical.overarchingPeriodSecondary"
                                                                #OverarchingPeriodSecondary="ngModel" name="OverarchingPeriodSecondary" 
                                                                (dateChange)="checkValidation(clinical.treatmentPlanDateSecondary,clinical.overarchingPeriodSecondary,1)"
                                                                [ngClass]="{ 'is-invalid': OverarchingPeriodSecondary.invalid }">
                                                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                                            <mat-datepicker #picker3></mat-datepicker>
                                                        </mat-form-field>
                                                        <span *ngIf="overarchingDateExistSecondary" class="text-danger">Overarching Period Date is
                                                            required</span>
                                                           
                                                    </div>
                                                </div>
                                                <!-- <div class="form-group">
                                                    <label  class="col-md-12 col-form-label p-0 mb-2" for="exampleFormControlFile1">Upload a CSV file / PDF - ASEBA Report</label>
                                                   
                                                    <input multiple title="Add Files" accept="application/msword, application/vnd.ms-excel, application/pdf, .csv" type="file" (change)="onFileChange($event)" class="form-control-file mt-3 pt-1" #taskfile />
                                                                        <div class="col-md-9 mt-1 p-0">
                                                                            <mat-chip-list class="mt-2">
                        
                                                                                <mat-chip *ngFor="let a of UploadedFileList;let x = index" (click)="downloadUploadedFile(a)">
                                                                                    {{a.fileName}}
                                                                                    <mat-icon matChipRemove (click)="deleteUploadImage(x)">
                                                                                        cancel</mat-icon>
                                                                                </mat-chip>
                        
                                                                            </mat-chip-list>
                                                                        </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <span *ngIf="isClinicianSame" class="text-danger"> Primary and Secondary clinician is
                                        same</span>
                                </div>
                            </div>
                        </div>
            
                    </a>
                    <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="5"
                        [pageSizeOptions]="[5, 10, 25, 100]">
                    </mat-paginator>
                </div>
    
    
    
    
    
    
    
    
    
    
    
              
   











            <div class="col-md-12 p-0" *ngIf="!isMovedChma" [ngClass]="{'disabled':isMovedChma}">
                <div class="additional-steps">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Primary Clinician</label>
                                    <div class="col-md-12 ">
                                        <mat-form-field class="w-100">
                                            <mat-select (selectionChange)="errormsgChange()"[(ngModel)]="primaryClinicianId" #PrimaryClinician="ngModel"
                                                name="PrimaryClinician">
                                                <mat-option *ngFor="let item of clinicianDetails" [value]="item.userId">
                                                    {{item.firstName}} {{item.lastName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <span *ngIf="isprimaryClinician" class="text-danger">Primary clinician is
                                            required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Treatment Plan
                                        Date</label>
                                    <div class="col-md-12 ">
                                        <mat-form-field class="w-100">
                                            <mat-label>Choose a date</mat-label>
                                            <input matInput [matDatepicker]="picker" readonly
                                                (click)="picker.open()" [(ngModel)]="treatmentPlanDate"
                                                #TreatmentPlanDate="ngModel" name="TreatmentPlanDate" (dateChange)="onDateChange()"
                                                [ngClass]="{ 'is-invalid': TreatmentPlanDate.invalid }" >
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                        <span *ngIf="istreatmentPlanDate" class="text-danger">Treatment Plan Date is
                                            required</span>
                                            
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-3">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Overarching period end by
                                    </label>
                                    <div class="col-md-12 ">
                                      
                                        <mat-form-field class="w-100">
                                            <mat-label>Choose a date</mat-label>
                                        
                                            <input matInput [matDatepicker]="picker1" readonly
                                                (click)="picker1.open()" [(ngModel)]="overarchingPeriod"
                                                #OverarchingPeriod="ngModel" name="OverarchingPeriod" 
                                                (dateChange)="checkValidation(treatmentPlanDate,overarchingPeriod,1)"
                                                [ngClass]="{ 'is-invalid': OverarchingPeriod.invalid }">
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1></mat-datepicker>
                                        </mat-form-field>
                                        <span *ngIf="overarchingDateExist" class="text-danger">Overarching Period Date is
                                            required</span>
                                            <span *ngIf="isDateValidate" class="text-danger">
                                                The overarching period end by date should not be greater than the treatment plan date.</span>
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <label  class="col-md-12 col-form-label p-0 mb-2" for="exampleFormControlFile1">Upload a CSV file / PDF - ASEBA Report</label>
                                   
                                    <input multiple title="Add Files" accept="application/msword, application/vnd.ms-excel, application/pdf, .csv" type="file" (change)="onFileChange($event)" class="form-control-file mt-3 pt-1" #taskfile />
                                                        <div class="col-md-9 mt-1 p-0">
                                                            <mat-chip-list class="mt-2">
        
                                                                <mat-chip *ngFor="let a of UploadedFileList;let x = index" (click)="downloadUploadedFile(a)">
                                                                    {{a.fileName}}
                                                                    <mat-icon matChipRemove (click)="deleteUploadImage(x)">
                                                                        cancel</mat-icon>
                                                                </mat-chip>
        
                                                            </mat-chip-list>
                                                        </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 p-0" *ngIf="!isMovedChma" [ngClass]="{'disabled':isMovedChma}">
                <div class="additional-steps">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Secondary Clinician</label>
                                    <div class="col-md-12 ">
                                        <mat-form-field class="w-100">
                                            <mat-select (selectionChange)="errormsgChangeSecondary()"[(ngModel)]="secondaryClinicianId" #SecondaryClinician="ngModel"
                                                name="SecondaryClinician">
                                                <mat-option *ngFor="let item of clinicianDetails" [value]="item.userId">
                                                    {{item.firstName}} {{item.lastName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <span *ngIf="isSecondaryClinician" class="text-danger">Secondary clinician is
                                            required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Treatment Plan
                                        Date</label>
                                    <div class="col-md-12 ">
                                        <mat-form-field class="w-100">
                                            <mat-label>Choose a date</mat-label>
                                            <input matInput [matDatepicker]="picker2" readonly
                                                (click)="picker2.open()" [(ngModel)]="treatmentPlanDateSecondary"
                                                #TreatmentPlanDateSecondary="ngModel" name="TreatmentPlanDateSecondary" (dateChange)="onDateChangeSecondary()"
                                                [ngClass]="{ 'is-invalid': TreatmentPlanDateSecondary.invalid }" >
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
                                        </mat-form-field>
                                        <span *ngIf="istreatmentPlanDateSecondary" class="text-danger">Treatment Plan Date is
                                            required</span>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-3">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Overarching period end by
                                    </label>
                                    <div class="col-md-12 ">
                                      
                                        <mat-form-field class="w-100">
                                            <mat-label>Choose a date</mat-label>
                                        
                                            <input matInput [matDatepicker]="picker3" readonly
                                                (click)="picker3.open()" [(ngModel)]="overarchingPeriodSecondary"
                                                #OverarchingPeriodSecondary="ngModel" name="OverarchingPeriodSecondary" 
                                                (dateChange)="checkValidation(treatmentPlanDateSecondary,overarchingPeriodSecondary,2)"
                                                [ngClass]="{ 'is-invalid': OverarchingPeriodSecondary.invalid }">
                                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                        </mat-form-field>
                                        <span *ngIf="overarchingDateExistSecondary" class="text-danger">Overarching Period Date is
                                            required</span>
                                            <span *ngIf="isDateValidate1" class="text-danger">
                                                The overarching period end by date should not be greater than the treatment plan date.</span>
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <label  class="col-md-12 col-form-label p-0 mb-2" for="exampleFormControlFile1">Upload a CSV file / PDF - ASEBA Report</label>
                                   
                                    <input multiple title="Add Files" accept="application/msword, application/vnd.ms-excel, application/pdf, .csv" type="file" (change)="onFileChange($event)" class="form-control-file mt-3 pt-1" #taskfile />
                                                        <div class="col-md-9 mt-1 p-0">
                                                            <mat-chip-list class="mt-2">
        
                                                                <mat-chip *ngFor="let a of UploadedFileList;let x = index" (click)="downloadUploadedFile(a)">
                                                                    {{a.fileName}}
                                                                    <mat-icon matChipRemove (click)="deleteUploadImage(x)">
                                                                        cancel</mat-icon>
                                                                </mat-chip>
        
                                                            </mat-chip-list>
                                                        </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <span *ngIf="isClinicianSame" class="text-danger"> Primary and Secondary clinician is
                        same</span>
                </div>
            </div>

        </div>
    </div>
    <div class="col-md-12 set1-temp" *ngIf="!isAssigned">
        <div class=" col-md-12 row">
            <div class="col-md-6 p-0">
                <label class="additional">You have not assigned a Clinician yet, would you like to assign?</label>

            </div>
            <div class="col-md-4 p-0">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="isAssigned">
                    <mat-radio-button class="mr-1" [value]="true">Yes
                    </mat-radio-button>
                    <mat-radio-button class="mr-2" [value]="false">No
                    </mat-radio-button>
                </mat-radio-group>
            </div>

        </div>
    </div>
    <div class="col-md-12">
        <div class="text-right">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <button type="button" (click)="onSaveChmadetails()" *ngIf="!isMovedChma && isAssigned && (roleAccess.isCreate||roleAccess.isEdit)" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Submit</button>

        </div>
    </div>
</div>

<div class="col-md-12 tabs-bill">
    <div class="billing-provider">
      <div class="patient_for_billing">
        <div  class="d-flex"><div  class="head-section mt-2 mb-2" *ngIf="fromClaimsLists=='false'"> Create Billing</div><div  class="ml-auto"><button  class="btn back-btn-new mr-2" tabindex="0"  (click)="onClickBack()" ><i  class="fas fa-chevron-left mr-2"></i>Back</button></div></div>
        <div class="large-search-custom" *ngIf="!isHideSearchBox">
          <div class="col-md-12">
            <div  class="search">
              <!-- <input  type="text" placeholder="Search By Patient" [(ngModel)]="searchCreateBillPtName"  name="" class="search-input " ><a  class="search-icon"><i  class="fa fa-search"></i></a></div>
               <ul id="myUL" *ngIf="searchCreateBillPtName">
                <li *ngFor="let item of deCrptedBillLists | grdFilter: searchCreateBillPtName:'CreateBillLists'">
                  <a (click)="onSelectedPatient(item)" style="cursor: pointer;">{{item.PatientName}} , {{item.ParentName}}</a></li>
              </ul>  -->
              <input  type="text" placeholder="Search By Patient" [(ngModel)]="searchCreateBillPtName"  name="" class="search-input " ><a  class="search-icon"><i  class="fa fa-search"></i></a></div>
              <ul id="myUL" *ngIf="searchCreateBillPtName">
               <li *ngFor="let item of patientDemographicLists | grdFilter: searchCreateBillPtName:'CreateBillLists'">
                 <a (click)="onSelectedPatient(item)" style="cursor: pointer;">{{item.PatientName}} , {{item.ParentName}}</a></li>
             </ul> 
              </div>
        </div>
        <div class="search-result pb-5 d-none">
          <div class="search-res-txt mb-3">
             Patient Not Selected
          </div>
          <div>
            <img src="../../../assets/images/patient-icon-26.png" class="img-fluid"/>
          </div>
        </div>
       
      </div>
      <div class="col-md-12 p-0 mt-2 " *ngIf="isPatientSelected">
        <div class="card social mb-3 w-100">
        <div class="profile-header col-md-12 theme-bg gradient">
          <div class="row">
          <div class="col-md-4">
            <div class="d-flex">
              <div class="mr-3">
              <img src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" class="rounded" alt="" style="width: 78px;">
              </div>
              <div class="details">
              <h5 class="mb-0">{{patientName}}</h5>
              <div class="text-muted text-sm">DOB:   {{dob| date: 'MM/dd/yyyy'}}</div>
              <div class="text-muted text-sm">Contact : {{ParentName}}</div>
              <div class="text-muted text-sm">Email   : {{email}}</div>
              <div class="text-muted text-sm">Phone   : {{phoneNo | mask: '(000) 000-0000'}}</div>
              </div>
              </div>
          </div>
          <div class="col-md-4">
            <div class="d-flex">
              <div class="details">
              <h5 class="mb-0">Insurance Details</h5>
              <div class="text-muted text-sm">Primary:   {{primary}}</div>
              <div class="text-muted text-sm">Secondary : {{secondary}}</div>
              <div class="text-muted text-sm">Teritary   : {{teritary}}</div>
              </div>
              </div>
          </div>
          <div class="col-md-4 text-right ">
            <button class="btn primary-btn-outlined-small float-right btn-sm mr-2 mt-2" (click)="OutOfPacketExpense()"><i class="fas fa-wallet mr-2 d-none"></i>Out-of-pocket expenses</button>
            
              <button class="btn btn-default btn-sm mr-2 d-none">Make an Appointments</button>
              <button class="btn btn-default btn-sm mr-2 d-none">Capture a Payment</button>
              <button class="btn btn-default btn-sm d-none">Print a Statement</button>
           </div>
        </div>
       </div>
        </div>
 
        <mat-tab-group>
          <mat-tab label="Diagnosis Information"> 
            <div class="col-md-12 p-0 mt-2">
              <div class="card p-3">
                <div  class="col-md-12 p-0" >
                <form (ngSubmit)="createBillfrm.form.valid  && OnSaveclick(createBillfrm)" id="createBillfrm" autocomplete="off" #createBillfrm="ngForm"
        novalidate>
                <div class="col-md-12 p-0">
                  <div class="row">
                    <div class="col-md-4">
                      <mat-form-field class="w-100 mb-2">
                        <mat-label>Billing Provider</mat-label>
                        <mat-select name="billprovider"  [(ngModel)]="dtocreateBill.BProviderId"  #BProviderId="ngModel" [ngClass]="{ 'is-invalid': createBillfrm.submitted && BProviderId.invalid }"
                          required>
                          <mat-option *ngFor="let bp of drpBillingProvider" [value]="bp.Bpid">{{bp.Bpdescription}}</mat-option>
                        </mat-select>
                        <mat-error  *ngIf="(createBillfrm.submitted && BProviderId.invalid)">
                          <mat-error *ngIf="BProviderId.errors?.required">Billing Provider is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field class="w-100 mb-2">
                        <mat-label>Billing Method</mat-label>
                        <mat-select name="billMethod"  [(ngModel)]="dtocreateBill.BMethodId"  #BMethodId="ngModel" [ngClass]="{ 'is-invalid': createBillfrm.submitted && BMethodId.invalid }"
                          required>
                          <mat-option *ngFor="let bm of drpBillingMethod" [value]="bm.Bmid">{{bm.Bmdescription}}</mat-option>
                        </mat-select>
                        <mat-error  *ngIf="(createBillfrm.submitted && BMethodId.invalid)">
                          <mat-error *ngIf="BMethodId.errors?.required">Billing Method is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-4">
                     
                      <mat-form-field class="w-100 mb-2">
                        <mat-label>Billing Form</mat-label>
                        <mat-select name="BFormType"  [(ngModel)]="dtocreateBill.BFormType"  #BFormType="ngModel" [ngClass]="{ 'is-invalid': createBillfrm.submitted && BFormType.invalid }"
                            (selectionChange)="onBillformchange($event.value)"  required>
                          <mat-option *ngFor="let bf of drpBillForm" [value]="bf.value">{{bf.label}}</mat-option>
                        </mat-select>
                        <mat-error  *ngIf="(createBillfrm.submitted && BFormType.invalid)">
                          <mat-error *ngIf="BFormType.errors?.required">Billing Form is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    
                  </div>
                </div>
                <div class="col-md-12 theraphy-mul p-0">
                  <div  class="loop-section position-relative mb-3 ">
                  <div class="row inputElement bill-label">
                    <div class="col-md-12 p-0">
                      <div class="row">
                      <div class="col-md-6">
                         <mat-form-field class="w-100">
                          <mat-label>Diagnosis Code</mat-label>
                          <mat-select multiple name="diagcode"  [(ngModel)]="dtocreateBill.DiagnosisCode"  #DiagnosisCode="ngModel" [ngClass]="{ 'is-invalid': createBillfrm.submitted && DiagnosisCode.invalid }"
                            required>
                            <mat-option *ngFor="let dg of drpDiagCode" [value]="dg.value">{{dg.label}}</mat-option>
                          </mat-select>
                          <mat-error *ngIf="(createBillfrm.submitted && DiagnosisCode.invalid)">
                            <mat-error *ngIf="DiagnosisCode.errors?.required">Diagnosis Code is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                        </div>
                        <div class="col-md-3">
                          <mat-form-field class="w-100">
                            <mat-label>Dos From</mat-label>
                            <input matInput [matDatepicker]="DosFrompcr" [(ngModel)]="dtocreateBill.DOSFrom" #DOSFrom="ngModel" name="DOSFrom"
                            required>
                            <mat-datepicker-toggle matSuffix [for]="DosFrompcr"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #DosFrompcr></mat-datepicker>
                            <mat-error *ngIf="createBillfrm.submitted && DOSFrom.invalid">
                              <mat-error  *ngIf="DOSFrom.errors.required">
                                DosFrom Date  is required</mat-error>
                          </mat-error>
                          </mat-form-field>
                        </div>
                     <div class="col-md-3">
                          <mat-form-field class="w-100">
                        <mat-label>Dos To</mat-label>
                        <input matInput [matDatepicker]="DosTopcr" [(ngModel)]="dtocreateBill.DOSTo" #DOSTo="ngModel"
                        [min]="dtocreateBill.DOSFrom" 
                        name="DOSTo"
                        required>
                        <mat-datepicker-toggle matSuffix [for]="DosTopcr" ></mat-datepicker-toggle>
                        <mat-datepicker #DosTopcr></mat-datepicker>
                        <mat-error *ngIf="createBillfrm.submitted && DOSTo.invalid">
                          <mat-error  *ngIf="DOSTo.errors.required">
                            DosTo Date  is required</mat-error>
                      </mat-error>
                      </mat-form-field>
                        </div>
                    </div>
                  </div>
                  
                    <div class="col-md-3">
                                       
                      <mat-form-field class="w-100">
                        <mat-label>Transation Code</mat-label>
                        <input matInput  [(ngModel)]="dtocreateBill.TransCode"  #TransCode="ngModel"
                          name="TranCode" [ngClass]="{ 'is-invalid': createBillfrm.submitted && TransCode.invalid }" 
                           (input)="onTransactioncCodePress()" step=0 class="ng-trim-ignore" required
                          />
                          <button type="button" mat-button matSuffix mat-icon-button aria-label="search" (click)="onTransactioncCodePress($event.target.value.trim())">
                            <mat-icon>search</mat-icon>
                          </button>
                        <mat-error *ngIf="createBillfrm.submitted && TransCode.invalid">
                        <mat-error *ngIf="TransCode.errors.required">Transation Code is required</mat-error>
                        <mat-error *ngIf="TransCode.errors?.minlength">select  valid Transation Code </mat-error>
                      </mat-error>
               </mat-form-field>
                </div>
               
                    <div class="col-md-3">
                      <mat-form-field class="w-100">
                        <mat-label>Units</mat-label>
                        <input matInput maxlength="2"  customMin="1"  [(ngModel)]="dtocreateBill.Units"  #Units="ngModel"
                        numeric  name="Units" [ngClass]="{ 'is-invalid': createBillfrm.submitted && Units.invalid }" required/>
                        <mat-error *ngIf="createBillfrm.submitted && Units.invalid">
                        <mat-error *ngIf="Units.errors.required">Units is required</mat-error>
                        <mat-error *ngIf="Units.errors?.minlength">Please enter valid Units</mat-error>
                      </mat-error>
        
        
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                     <mat-form-field class="w-100">
                        <mat-label>Amount</mat-label>
                         <input matInput maxlength="4"  customMin="2"   [(ngModel)]="dtocreateBill.Amount"  #Amount="ngModel"
                                numeric  name="Amount"  required/>
                              <mat-error *ngIf="createBillfrm.submitted && Amount.invalid">
                              <mat-error *ngIf="Amount.errors.required">Amount is required</mat-error>
                        </mat-error>
                       </mat-form-field>
                    </div>
                  
                    <div class="col-md-3">
                     
                    <mat-form-field class="w-100">
                      <mat-label>Rendering Provider </mat-label>
                      <!-- <mat-select name="Rprovider"  [(ngModel)]="dtocreateBill.Rprovider"  #Rprovider="ngModel" [ngClass]="{ 'is-invalid': createBillfrm.submitted && Rprovider.invalid }"
                      required>
                      <mat-option value="1">Provider1</mat-option>
                      <mat-option value="2">Provider2</mat-option>
                      <mat-option value="3">Provider3</mat-option>
                    </mat-select> -->
                    <mat-select name="Rprovider"  [(ngModel)]="dtocreateBill.Rprovider"  #Rprovider="ngModel" [ngClass]="{ 'is-invalid': createBillfrm.submitted && Rprovider.invalid }"
                    required>
                    <mat-option *ngFor="let bp of drpRenderingProvider" [value]="bp.Rpid">{{bp.RpDescription}}</mat-option>
                  </mat-select>
                    <mat-error *ngIf="(createBillfrm.submitted && Rprovider.invalid)">
                      <mat-error *ngIf="Rprovider.errors?.required">Rendering provider is required</mat-error>
                    </mat-error>
                    </mat-form-field>
                    </div>
                    <div class="col-md-6"> 
                      <mat-form-field class="w-100">
                        <mat-label>Transaction description</mat-label>
                        <input matInput maxlength="75" [(ngModel)]="dtocreateBill.Tdescription"  #Tdescription="ngModel"
                         name="Tdescription" [ngClass]="{ 'is-invalid': createBillfrm.submitted && Tdescription.invalid }" required/>
                        <mat-error *ngIf="createBillfrm.submitted && Tdescription.invalid">
                        <mat-error *ngIf="createBillfrm.errors.required">Transaction description is required</mat-error>
                     
                      </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-3" *ngIf="isUB04">
                      <mat-form-field class="w-100">
                        <mat-label>Revenue Code</mat-label>
                        <input matInput   [(ngModel)]="dtocreateBill.RevenuCode"  #RevenuCode="ngModel"
                          name="RevenuCode" [ngClass]="{ 'is-invalid': createBillfrm.submitted && RevenuCode.invalid }" disabled required/>
                       
        
        
                      </mat-form-field>
                    </div>
                    <div class="col-md-3" *ngIf="isCpt">
                      <mat-form-field class="w-100">
                        <mat-label>CPT Code</mat-label>
                        <input matInput   [(ngModel)]="dtocreateBill.CPTCode"  #CPTCode="ngModel"
                          name="CPTCode" [ngClass]="{ 'is-invalid': createBillfrm.submitted && CPTCode.invalid }" disabled required/>
                       
        
        
                      </mat-form-field>
                    </div>

                  </div>
                  <!-- <div class="transcode" > -->
                     <div  class="table-responsive"> 
                     <div class="row transcode" *ngIf="isTransactioncode">
                        <!-- <div class="col-sm-12" style="height: auto; overflow: auto;"> -->
                          <div class="col-sm-12" style="height: auto;">
                          <div class="form-group" id="transcode">
                            <table class="table table-bordered" style="width:100%;border:1px solid;border-color:lightgray;" cellspacing="0" role="grid">
                             <thead>
                                <tr>
                                  
                                   <th class="transcd" >Transaction Code </th>
                                  <th class="cptcode" *ngIf="isCpt">CPT</th>
                                  <th class="rev" *ngIf="isUB04">Revenue Code</th>
                                  <th class="hspsc" *ngIf="isUB04">HSPSC Name</th> 
                                  <th class="descr">Description</th> 
                                   </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let itm of drpTransactionCode;let i=index" (click)="onSelectedTranscode($event,itm)" [ngClass]="{'highlightselectedTcode':(itm.TransCode == selectedTranscode)}">
                                 <td class="transcd" >{{itm.TransCode}} </td>
                                  <td class="cptcode" *ngIf="isCpt">{{itm.CptCode}} </td>
                                  <td class="rev" *ngIf="isUB04">{{itm.RevenuCode}}</td>
                                  <td class="hspsc" *ngIf="isUB04">{{itm.Hcpcscode}}</td>
                                  <td class="descr">{{itm.Tdescription}}</td>
                                 
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
            
                 </div> 
                  
                </div>
               </div>
                <div class="col-md-12 text-right mt-3" *ngIf="roleAccess.isCreate || roleAccess.isEdit">
             <button type="submit"  class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="this.dtocreateBill.BformSaveMethhod='POST'">POST</button>
             <button type="submit"  class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="this.dtocreateBill.BformSaveMethhod='SAVE'">Save</button>
             <button mat-stroked-button class="btn btn-cancel primary-btn-m h-40" (click)="onCancelClick()">Cancel</button>
                </div>
              </form>
            </div>
              <div class="col-md-12  mt-2"  *ngIf="this.patientBillLists.length>0">
                <!-- <div  class="box-lead-head ">Transaction - Details </div>  -->
                  <!--search-->
                  <div class="row">
                    <div  class="col-md-4 mt-3">Billing  Details </div>
                    <div  class="col-md-3 ml-auto"> 
                      <mat-form-field class="w-100">
                        <mat-label>Search by billing values</mat-label>
                        <input matInput type="text" (keyup)="searchTblVal($event.target.value)"/>
                      </mat-form-field>
                      
                     </div>
                    </div>
                   <!--search end-->
                  <div class="col-md-12 table-custom p-0">
                    <div class="table-responsive">
                      <mat-table #table [dataSource]="dataSource" matSort class="w-100">
                        <ng-container matColumnDef="sno">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                          <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="TransCode">
                          <mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Code </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.TransCode}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Dosfrom">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Dos From </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.Dosfrom  | date:'dd/MM/yyyy'}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Dosto">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Dos To</mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.Dosto  | date:'dd/MM/yyyy'}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="Tdescription">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.Tdescription}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="TherapyName">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Therapy Name</mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.TherapyName}} </mat-cell>
                        </ng-container>
                            
                        <ng-container matColumnDef="Amount">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Amount </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.Amount}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="PostedDate">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Posted Date </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.CreatedDate  | date:'dd/MM/yyyy'}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="BillStatus">
                          <mat-header-cell *matHeaderCellDef mat-sort-header>Status </mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element.BillStatus}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="alerts">
                          <mat-header-cell *matHeaderCellDef>Alert </mat-header-cell>
                          <mat-cell *matCellDef="let element;let i = index;">
                            <mat-checkbox ></mat-checkbox>
                            
                          </mat-cell>
                        </ng-container>
                      
                              
                        <ng-container matColumnDef="actions">
                          <mat-header-cell *matHeaderCellDef>Actions </mat-header-cell>
                          <mat-cell *matCellDef="let element;let i = index;">
                            <button type="button" mat-icon-button matTooltip="Edit" (click)="getEditBill(element)" color="primary" *ngIf="roleAccess.isEdit">
                              <img src="../../../assets/images/editblue.png">
                            </button>
                            
                          </mat-cell>
                        </ng-container>
              
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              
                      </mat-table>
                     
                    </div>
                  </div>
                  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      
                </div>

        <!-- </div>-->
    </div>
    

   </div>  

   <div class="comment-popup  re-schedule-popup out-of-pack">
    <div class="modal" tabindex="-1" role="dialog"  [style]="isOutOfPacketExpense ? 'display: block;' : 'display :none'">
      <div class="modal-dialog mat-dialog-container " role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title"> Out-of-pocket expenses</h6>
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OutofPacketExpenseClose()" >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
  <div >
    <form  
    autocomplete="off"  novalidate>
    <div class="col-md-12 p-0">
      <div class="row mb-2">
        <div class="col-md-12">
          <div class="table-responsive outofpacket">
            
            <!-- <table class="outofpacket">
              <thead>
                <tr>
                  <th style="width:5%">SNo</th>
                  <th>SCHEDULED DATE</th>
                  <th>THERAPY</th>
                  <th>FREQUENCY</th>
                  <th>DURATION</th>
                  <th>PAYMENT DONE</th>
                  <th>PAYMENT TYPE</th>
                  <th>PAID ON</th>
                </tr>
              
              </thead>
              <tbody> 
                 <tr>
                  <td>1</td>
                  <td>09/21/2022</td>
                  <td>Intake</td>
                  <td>One time</td>
                  <td>1 hrs</td>
                  <td>350</td>
                  <td>Cash</td>
                  <td>09/22/2022 06:08 PM</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>09/21/2022</td>
                  <td>Intake</td>
                  <td>One time</td>
                  <td>1 hrs</td>
                  <td>350</td>
                  <td>Cash</td>
                  <td>09/22/2022 06:08 PM</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>09/21/2022</td>
                  <td>Intake</td>
                  <td>One time</td>
                  <td>1 hrs</td>
                  <td>350</td>
                  <td>Cash</td>
                  <td>09/22/2022 06:08 PM</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>09/21/2022</td>
                  <td>Intake</td>
                  <td>One time</td>
                  <td>1 hrs</td>
                  <td>350</td>
                  <td>Cash</td>
                  <td>09/22/2022 06:08 PM</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>09/21/2022</td>
                  <td>Intake</td>
                  <td>One time</td>
                  <td>1 hrs</td>
                  <td>350</td>
                  <td>Cash</td>
                  <td>09/22/2022 06:08 PM</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>09/21/2022</td>
                  <td>Intake</td>
                  <td>One time</td>
                  <td>1 hrs</td>
                  <td>350</td>
                  <td>Cash</td>
                  <td>09/22/2022 06:08 PM</td>
                </tr>
                </tbody>
                </table> -->
                <div class="summary-list col-md-12 p-0" *ngIf="isOutOfPacketData">
                  <div class="p-0">
                   <div class="card-body1">
                      <div class="col-md-12 per-section-loop">
                        <div class="row pt-2 pb-2 top-border-out">
                          <!-- radio-->
                            <!-- <div class="col-md-1 pl-0">
                                <div class="bill-labeld">
                                </div>
                             </div> -->
                          <!--radio end-->
                          <div class="col " *ngIf="isPerWeekC">
                            <div class="bill-labeld">
                              week Date
                            </div>
                          </div>

                          <div class="col">
                            <div class="bill-labeld">
                              Scheduled Date
                            </div>
                           </div>
                          <div class="col">
                            <div class="bill-labeld">
                              Therapy
                           </div>
                          </div>
                          <div class="col">
                            <div class="bill-labeld">
                             Frequency
                            </div>
                          </div>
                          <div class="col">
                            <div class="bill-labeld">
                              Duration
                            </div>
                         </div>
                          
                          <div class="col">
                            <div class="bill-labeld">
                             Payment Done
                            </div>
                          </div>
                          <div class="col">
                            <div class="bill-labeld">
                              Payment Type
                            </div>
                            
                          </div>
                          <div class="col">
                            <div class="bill-labeld">
                              Paid On
                            </div>
                           </div>
                          
                        </div>
                        <div class="body-billed-value">
                        <!-- Completed List-->
                        <!-- <div class="row pt-2 pb-2" *ngFor="let item of OutofPktPaymentCompletedLists;let i=index"  style="background-color:{{((i > 0) && item.IsGrouped==1 &&(item.PayFrequency==3 && (item.WeekStartDate==OutofPktPaymentCompletedLists[i-1].WeekStartDate)) )?'#e7effd':'#fff'}}">   -->
                          <div class="row pt-2 pb-2" *ngFor="let item of dtoPatientOutofPacketInfoCompletedDetails;let i=index">  
                          <!-- radio-->
                          <!-- <div class="col-md-1 pl-0">
                           <div class="bill-labeld">
                             <mat-radio-button  (click)="radiobuttonClick(item,$event)" *ngIf="item.DueAmount!=0.00"></mat-radio-button>
                           </div>
                         </div> -->
                          <!--radio end-->
                          <div class="col" *ngIf="isPerWeekC">
                            <div class="bill-valued-sub"  *ngIf="(item.IsGrouped==1 && item.DueAmount!=0.00)" >
                              {{item?.WeekStartDate |date:'MM/dd/yyyy'}}  - 
                              {{item?.WeekEndDate |date:'MM/dd/yyyy'}}
                             </div>
                             <div class="bill-valued-sub"  *ngIf="item.IsGrouped==0" >
                              {{item?.WeekStartDate |date:'MM/dd/yyyy'}}  - 
                              {{item?.WeekEndDate |date:'MM/dd/yyyy'}}
                             </div>
                          </div>

                          <div class="col">
                          <div class="bill-valued-sub" >
                             {{item?.TherapyDate |date:'MM/dd/yyyy'}}
                          </div>
                          </div>
                         <div class="col">
                          <div class="bill-valued-sub"> 
                             {{item?.TherapyId==3?item.ApointmenteName: item.TherapyName }}
                          
                           </div>
                         </div>
                        <div class="col">
                          <div class="bill-valued-sub"  *ngIf="(item.IsGrouped==1 && item.DueAmount!=0.00)?true:item.IsGrouped==0?true:false" >
                            {{item?.PayFrequencyName}}
                           </div>
                        </div>
                       
                        <div class="col">
                            <div class="bill-valued-sub">
                             {{global.getMinutesSlot(item?.Duration)}}
                           </div>
                         </div>
                         
                         <div class="col">
                            <div class="bill-valued-sub"  *ngIf="(item.IsGrouped==1 && item.DueAmount!=0.00)?true:item.IsGrouped==0?true:false" >
                              {{item?.PaidAmount}}
                             </div>
                         </div>

                         <div class="col">
                           <div class="bill-valued-sub"   *ngIf="(item.IsGrouped==1 && item.DueAmount!=0.00)?true:item.IsGrouped==0?true:false">
                            {{item?.PaymentType}}
                           </div>
                           </div>

                         <div class="col">
                          <div class="bill-valued-sub"  *ngIf="(item.IsGrouped==1 && item.DueAmount!=0.00)?true:item.IsGrouped==0?true:false" >
                            {{item?.PaidOn | date: 'MM/dd/yyyy hh:mm a'}}
                           </div>
                         
                          </div> 
                                                        
                       </div>
                       </div>
                        
                      </div>
                      
                    </div>
                  </div>


                </div>
                <div *ngIf="!isOutOfPacketData">
                  No records found
                  </div>
              <!-- </tbody>
            </table> -->
          </div>
          </div>
    
    <div class="text-right col-md-12 mt-2">
      <!-- <button type="button" class="btn btn-cancel" data-dismiss="modal" >Cancel</button> -->
    </div>
  
    <div>
      
  
    </div>
  
  </div>
  </div>
  </form>
  </div>
  
  
  </div>
  </div>
  </div>
  </div>
  </div>
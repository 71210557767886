<div class="login-page">
<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <div class="login-section">
        <div class="container-fluid">
            <div class="row no-gutter">
              <div class="d-none d-md-flex col-md-6 col-lg-9 bg-image"></div>
              <div class="col-md-6 col-lg-3">
                <div class="login d-flex align-items-top py-4">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-9 col-lg-12 mx-auto">
                        <div class="col-md-12 p-0 logo-img">
                            <img  class="img-fluid" src="../../assets/images/brand-logo.jpg" alt="">
                            </div>
                            <form fxLayout="column" [formGroup]="registerForm">
                                <mat-card-content>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-12">
                                                
                                                <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px"
                                                fxLayoutGap.lt-md="0px">
                                                <mat-form-field fxFlex class="w-100">
                                                   
                                                    <input type="text" matInput formControlName="firstName" placeholder="FirstName"
                                                        class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                                        <div *ngIf="f.firstName.errors.required || f.firstName.errors.cannotContainSpace">First Name is required</div>
                                                    </div>
                                                </mat-form-field>
                                            </div>
                                            </div>
                                            <div class="col-md-12">
                                               
                                                <div class="input-row">
                                                   
                                                    <mat-form-field fxFlex class="w-100">
                                                        
                                                        <input type="text" matInput formControlName="lastName" placeholder="LastName"
                                                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                                            <div *ngIf="f.lastName.errors.required || f.lastName.errors.cannotContainSpace ">Last Name is required</div>
                                                        </div>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                    
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-12">
                                                
                                                <div class="input-row">
                                                    <mat-form-field fxFlex class="w-100">
                                                        
                                                        <input type="text" matInput formControlName="username" placeholder="UserName"
                                                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                                                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                                            <div *ngIf="f.username.errors.required || f.username.errors.cannotContainSpace">Username is required</div>
                                                        </div>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                               
                                                <div class="input-row">
                                                    <mat-form-field fxFlex class="w-100">
                                                        
                                                        <input type="text" matInput formControlName="email" placeholder="Email" class="form-control"
                                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                            <div *ngIf="f.email.errors.required || f.email.errors.cannotContainSpace">Email is required</div>
                                                            <div *ngIf="f.email.errors?.pattern">Please enter valid email</div>
                                                        </div>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-12">
                                                
                                                <div class="input-row">
                                                    <mat-form-field fxFlex class="w-100">
                                                        
                                                        <input type="text" matInput formControlName="contactNumber" placeholder="ContactNumber"
                                                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }" />
                                                        <div *ngIf="submitted && f.contactNumber.errors" class="invalid-feedback">
                                                            <div *ngIf="f.contactNumber.errors.required || f.contactNumber.errors.cannotContainSpace">Contact Number is required</div>
                                                        </div>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                               
                                                <div class="input-row">
                                                    <mat-form-field fxFlex class="w-100">
                                                        
                                                          <mat-label>Roles</mat-label>
                                                        <mat-select formControlName="roleId" name="food" placeholder="Select Role">
                                                            
                                                            <mat-option *ngFor="let item of rolelist" [value]="item.roleId">
                                                                {{item.roleName}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <div *ngIf="submitted && f.roleId.errors" class="invalid-feedback">
                                                            <div *ngIf="f.roleId.errors.required">Role is required</div>
                                                        </div>
                                                    </mat-form-field>
                                                </div>  
                                            </div>
                                        
                                        </div>
                                    </div>
                                   
                                    
                                   
                                    
                                    
                                    
                                </mat-card-content>
                                <div class="button-section  col-md-12">
                                    <div class="row">
                                        <div class="col-md-12 text-center mb-3 mt-3"> 
                                            <button mat-stroked-button color="accent" class="mr-2 primary-btn-outlined primary-btn-s h-40 mb-3" type="button"
                                        [routerLink]="['/login']">Cancel</button><button class="submit-btn primary-btn-filled primary-btn-s h-40" mat-stroked-button color="accent" type="submit" (click)="onSubmitRegister()">Submit</button>
                                        </div></div>
                                    
                               
                                </div>
                            </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>

    
</div>
</div>
import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { NgForm } from '@angular/forms';
import { dtoScheduleModality, dtoScheduleConflictModality } from 'src/app/_models/_Treatment/scheduleModality';
import {
  CalendarEvent, CalendarView, CalendarDayViewBeforeRenderEvent, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent,
} from 'angular-calendar';
declare function decrypt(data): any;
declare function EncryptFieldData(data): any;
import * as moment from 'moment';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { Subject } from 'rxjs';
import { colors } from 'src/app/_models/_Patient/_ScheduleAppoinment';
import RRule from 'rrule';
interface RecurringEvent {
  title: string;
  color: any;
  end?: any;
  id?: any;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
    until: any;

  };
}
import { ViewPeriod } from 'calendar-utils';
import { DatePipe } from '@angular/common';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { I } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-schedule-modality',
  templateUrl: './schedule-modality.component.html',
  styleUrls: ['./schedule-modality.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleModalityComponent implements OnInit {
  @Input() patientAccountId: number;
  @Input() patientInfoId: number;
  chmaTreatmentSummaryId: number;
  scheduleModality: NgForm; // f is nothing but the template reference of the Template Driven Form
  @ViewChild('scheduleModality') currentForm: NgForm;
  scheduleConflictModality: NgForm; // f is nothing but the template reference of the Template Driven Form
  @ViewChild('searchConflictModality') currentConflictForm: NgForm;
  dtoScheduleModality: dtoScheduleModality;
  resScheduleModality: any;
  staffAppointmentDay1: CalendarEvent[] = [];
  isConflict: boolean = true;
  Day1viewDate: Date = new Date();
  timeslots = [];
  noslots: boolean = false;
  meetingDateBlocks: any = [];
  currentdate :any;
  maxdate: any;
  ddlTherapy: any;
  ddllocation: any;
  rangeOn: any;
  treatmentPlanDate: any;
  dtoScheduleConflictModalitylist: any;
  scheduleModalityList: any;
  dates = [];
  isSearch: boolean;
  // _dtoModality: any = [];
  _dtoTreatmentInfo: any;
  _dtoModality: any;
  isModalityScreen: boolean = false;
  isViewModality: boolean = false;
  therapyName: string;
  frequencyValue: number;
  ddlStafflist: any = [];
  currentMinDate = new Date();
  ddlMeetinglocation: any;
  ddlMeetinglocations: any;
  modalitySummary: any;
  selectedDay: any = [];
  dateList: any = [];
  errConflicts: boolean = false;
  treatmentenddate: any;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  recurringEvents: RecurringEvent[] = [];
  activeDayIsOpen: boolean = false;
  isGroupTherapyScreen: boolean = false;
  viewPeriod: ViewPeriod;
  lstGroupMeeting: any;
  Decrptedlst: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  releaseDate:any;
  releaseDates : any;
  assignDate:any;
  isShowAssign:any;
  isReqAssignTherapy: boolean = false;
  assignedtotal: any;
  endDate: any=null;
  startDate: any = null;
  currentTime: string;
  therapyId: any;
  ddlMeetingType:any[];
  LocationId: number;
  roleAccess:RoelAccess;
  IntakeStartTme: any;
  IntakeEndTime: any;
  pageFrom:number;
  releaseDateEdit : boolean = false
  getreleasedate : any;
  constructor(public global: Global, private formBuilder: FormBuilder, public settingsService: SettingsService, private datepipe :DatePipe,
    private snackbar: SnackBarService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog, public commonService: CommonService, public clinicianservice: ClinicianService, public spinnerservice: NgxSpinnerService, public treatmentservice: TreatmentService, private cdr: ChangeDetectorRef,
    public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.ScheduleAppointmentsTherapies;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.dtoScheduleModality = new dtoScheduleModality();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.account).replace(/"/g, ""));
        this.chmaTreatmentSummaryId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patient).replace(/"/g, ""));
        if(params.page != undefined)
        {
          this.pageFrom = JSON.parse(this.commonService.FrontEndDecryption(params.page));
        }
        this.dtoScheduleModality.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
        this.dtoScheduleModality.patientAccountId = this.patientAccountId;
        this.dtoScheduleModality.patientInfoId = this.patientInfoId;
        this.dtoScheduleModality.isRecurance = false;
        this.LocationId =  Number(this.commonService.FrontEndDecryption(params.locationId).replace(/"/g, ""));
       
      }
      // this.GetMeetingTypeMaster();
      // this.getTreatmentPlandetails();
      //// this.getTreatmentPlanDiagnosis();
      // this.getMeetingLocation();
      // this.getModalityForSchedule();
      // this.getStaffList();
      // this.getddlLocation();
  

    });
    this.GetMeetingTypeMaster();
      this.getTreatmentPlandetails();
      // this.getTreatmentPlanDiagnosis();
      this.getMeetingLocation();
      this.getModalityForSchedule();
      this.getStaffList();
      this.getddlLocation();
    let Curdate = new Date();
    this.currentdate = moment().format("YYYY-MM-DD HH:mm:ss")
    this.currentTime= moment().format("HH:mm")
    }
  getModalityForSchedule() {
    this.spinnerservice.show();
     this.treatmentservice.getModalityForSchedule(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          this._dtoModality = res; this._dtoModality = this._dtoModality.filter(x=>x.therapyId!=3 || x.therapyId==14 || x.therapyId==15|| x.therapyId==16)
          this._dtoModality = this._dtoModality.filter(x=> (x.therapyId==4 && x.meetingScheduleId==0)|| (x.therapyId==7 && x.meetingScheduleId==0) || (x.therapyId==8 && x.meetingScheduleId==0) || (x.therapyId==1 || x.therapyId==2 || x.therapyId==11 || x.therapyId==12) || x.therapyId==14 || x.therapyId==15);
                

          this._dtoModality.endDate
          this._dtoModality = res;
          this._dtoModality.endDate
          //this.cdr.detectChanges();
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  onBack(){
    if(this.pageFrom == 1)
    {
      this.router.navigateByUrl('/dashboard');
    }else{
      this.router.navigateByUrl('/patientslists');
    }
  }
  getTreatmentPlandetails() {
   
    this.spinnerservice.show();

    this.treatmentservice.getTreatmentPlanList(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          this._dtoTreatmentInfo = res[0];
          console.log(this._dtoTreatmentInfo)
          this.patientAccountId = this._dtoTreatmentInfo.patientAccountId;
          this.patientInfoId = this._dtoTreatmentInfo.patientInfoId;
          this.treatmentPlanDate = this._dtoTreatmentInfo.treatmentPlanDate;
          this.IntakeStartTme = this._dtoTreatmentInfo.fromTime;
          this.IntakeEndTime = this._dtoTreatmentInfo.toTime;
          var enddate = moment(this.treatmentPlanDate).add(90, 'days').toDate();
          this.treatmentenddate = this._dtoTreatmentInfo.treatmentPlanEndDate;
          this.dtoScheduleModality.endDate = this.treatmentenddate;

        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }

  // getStaffList() {
  //   this.spinnerservice.show();

  //   this.treatmentservice.getStaffList().subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       if (res != null) {
  //         this.ddlStafflist = res;

  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();

  //     },
  //   );
  // }
  getStaffList()
  {
  this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician,this.LocationId).subscribe(
      (res: any) => {
        if (res) {
          this.ddlStafflist = res;        
          }   
        },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  
  onScheduleModality(obj) {
    this.clearModality();

    this.isModalityScreen = true;
    this.isViewModality = false;
    this.isGroupTherapyScreen = false;
    this.therapyName = obj.therapyName;
    this.therapyId = obj.therapyId
    this.frequencyValue = obj.frequencyValue;
    this.dtoScheduleModality.therapyId = obj.therapyId;
    this.dtoScheduleModality.scheduleModalityId = obj.scheduleModalityId;
  }
  onViewModality(obj) {
    this.clearModality();
    this.isViewModality = true;
    this.isModalityScreen = true;
    this.isGroupTherapyScreen = false;
    this.therapyName = obj.therapyName;
    this.modalitySummary = obj.modalitySummary;
    this.therapyId = obj.therapyId
    this.spinnerservice.show();
    this.treatmentservice.getViewModality(obj.meetingScheduleId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        debugger;
        this.dtoScheduleModality = res.dtoScheduleModality;
        this.dtoScheduleModality.startTime = moment(res.dtoScheduleModality.fromTime).format("HH:mm");
        this.dtoScheduleConflictModalitylist = [];
        var data = res.dtoScheduleModalityDetailList;
        this.scheduleModalityList = [];
        for (let i = 0; i < data.length; i++) {
          this.scheduleModalityList.push({
            // day: data[i].day,
            scheduleDate: moment(data[i].scheduleDate).format('MM/DD/YYYY'),
            isChecked: false,
            fromTime: moment(res.dtoScheduleModality.fromTime).format("HH:mm A"),
            toTime: moment(res.dtoScheduleModality.toTime).format("HH:mm A"),
            isConflict: false,

          });
        }


      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  onCancelModality() {
    this.isModalityScreen = false;
    this.isGroupTherapyScreen = false;
    this.clearModality();
    this.ngOnInit();
  }
  getddlLocation() {
    this.spinnerservice.show();
    this.settingsService.getLocations().subscribe(
      res => {
        if (res != null) {
          this.ddllocation = res;
          this.getMeetingLocation();
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getMeetingLocation() {
    this.spinnerservice.show();
    // let locationId=+localStorage.getItem('sfcclocation')
  
    let locationId = this.dtoScheduleModality.location;
    this.settingsService.getMeetingRoom(locationId).subscribe(
      res => {
        this.spinnerservice.hide();
        this.ddlMeetinglocation =res.filter(x=>x.isActive != false);
        // var ddlMeetinglocations = res.filter(this.ddllocation.sfccMasterId = this.ddlMeetinglocation.sfccMasterId)
        // this.getAllScheduleModality();
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  getDayBWDates(start, end, dayName, lstday) {
    var result = [];
    for (let i = 0; i < lstday.length; i++) {
      var days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
      var day = days[dayName.toLowerCase().substr(0, 3)];
      var current = new Date(start.toDate());
      current.setDate(current.getDate() + (lstday[i] - current.getDay() + 7) % 7);
      while (current <= end.toDate()) {
        result.push(new Date(+current));
        current.setDate(current.getDate() + 7);
      }
    }
    return result;
  }
  getConflictsModality() {
    if(this.dtoScheduleModality.startTime<this.currentTime){
      return
    }
    var offset = new Date().getTimezoneOffset();
    this.scheduleModalityList = [];
    this.dtoScheduleConflictModalitylist = []
    let _obj = {
      StartDate: moment.utc(this.dtoScheduleModality.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
      EndDate: moment.utc(this.dtoScheduleModality.endDate).utcOffset(-offset).format("MM/DD/YYYY"),
      FromTime: this.dtoScheduleModality.startTime,
      ToTime: moment(this.dtoScheduleModality.startTime, 'HHmm').add(this.dtoScheduleModality.duration, 'minute').format('HH:mm A'),
      Provider: this.dtoScheduleModality.provider
    }

    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.treatmentservice.getConfilctsForModality(dataToEncrypt).subscribe(
      (res: any) => {
        this.dtoScheduleConflictModalitylist = JSON.parse(res);
        this.spinnerservice.hide();
        if (this.dtoScheduleModality.isRecurance) {


          let lstdate = this.getDayBWDates(
            moment(this.dtoScheduleModality.startDate).utcOffset(-offset),
            moment(this.dtoScheduleModality.endDate).utcOffset(-offset),
            'Wed', this.selectedDay)
          this.onBetweenappointment(lstdate);
        } else {
          this.dateList = [];
          this.dateList = this.getDaysBetweenDates(moment(this.dtoScheduleModality.startDate).utcOffset(-offset), moment(this.dtoScheduleModality.endDate).utcOffset(-offset));
          this.onBetweenappointment(this.dateList);
        }
      if(this.therapyId==4 ||this.therapyId==7 ||this.therapyId ==8 ){
        this.skipAllConflicts()
      }
      },
      err => {
        this.spinnerservice.hide();

      },
    );

  }

  getDaysBetweenDates(startDate, endDate) {
    // var now = startDate.clone();
    let betweendates = [];
    while (startDate.isSameOrBefore(endDate)) {
      betweendates.push(new Date(startDate));
      startDate.add(1, 'days');
    }
    return betweendates;
  };

  onBetweenappointment(data) {
    this.scheduleModalityList = [];
    for (let i = 0; i < data.length; i++) {
      this.scheduleModalityList.push({
        // day: data[i].day,
        scheduleDate: moment(data[i]).format('MM/DD/YYYY'),
        isChecked: false,
        fromTime: moment(this.dtoScheduleModality.startTime, "h:mm").format("HH:mm A"),
        toTime: moment(this.dtoScheduleModality.startTime, 'HHmm').add(this.dtoScheduleModality.duration, 'minute').format('HH:mm A'),
        isConflict: this.getIsConflict(data[i]),
        // location: data[i].location,
        // locationName: this.getLocationName(data[i].location),
      });
    }


  }
  getIsConflict(conflictDate: any) {
    let isConflictlist = this.dtoScheduleConflictModalitylist.filter((item) => item.isConflict == true);
    for (let i = 0; i < isConflictlist.length; i++) {
      let firstDate = moment(isConflictlist[i].scheduleDate).format('MM/DD/YYYY');
      let secondDate = moment(conflictDate).format('MM/DD/YYYY');
      if ((firstDate == secondDate) && isConflictlist[i].isConflict == true) {

        return true;
      }
    }
    return false;
  }
  updateItem(item, event) {
    let index = this.scheduleModalityList.indexOf(item);
    if (event.target.checked) {
      item.isChecked = true;
    }
    else {
      item.isChecked = false;
    }
    this.scheduleModalityList[index] = item;
  }
  ondeleteSchdule(item) {
    let index = this.scheduleModalityList.indexOf(item);
    this.scheduleModalityList.splice(index, 1);
  }
  skipAllConflicts() {
    this.errConflicts = false;
    this.scheduleModalityList = this.scheduleModalityList.filter((item) => item.isConflict != true);
  }
  skipConflicts() {
    this.errConflicts = false;
    this.scheduleModalityList.forEach((item, index) => {
      if (item.isChecked == true && item.isConflict == true) this.scheduleModalityList.splice(index, 1);
    });

  }
  postSchduleModality() {
    let checkconfilcts = this.scheduleModalityList.filter((item) => item.isConflict == true).length;
     if (checkconfilcts > 0) {
      this.errConflicts = true;
      return;
    }
    else{
      let obj;
      if(!(this.therapyId==4 ||this.therapyId==7 ||this.therapyId ==8 )){   
        obj = {
          MeetingCategoryId: 1,
          MeetingLocationId: this.dtoScheduleModality.location,
          PatientAccountId: this.patientAccountId,
          PatientInfoId: this.patientInfoId,
          ScheduleName: this.therapyName + "-" + this._dtoTreatmentInfo?.patientName,
          Provider: this.dtoScheduleModality.provider,
          MeetingTimes: this.scheduleModalityList,
          ModalityDetails: this.dtoScheduleModality,
          MeetingTypeId: this.dtoScheduleModality.meetingTypeId
        }
      }
      else if((this.therapyId==4 ||this.therapyId==7 ||this.therapyId ==8 )){
        let MeetingTimesArray = [];
        MeetingTimesArray.push(this.scheduleModalityList[0])
        obj = {
          MeetingCategoryId: 1,
          MeetingLocationId: this.dtoScheduleModality.location,
          PatientAccountId: this.patientAccountId,
          PatientInfoId: this.patientInfoId,
          ScheduleName: this.therapyName + "-" + this._dtoTreatmentInfo?.patientName,
          Provider: this.dtoScheduleModality.provider,
          MeetingTimes: MeetingTimesArray,
          ModalityDetails: this.dtoScheduleModality,
          MeetingTypeId: this.dtoScheduleModality.meetingTypeId
        }
      }
  
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.treatmentservice.postSchduleModality(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.isModalityScreen = false;
        this.isViewModality = false;
       //// this.snackbar.success(this.therapyName + " " + JSON.parse(res).message);
        this.clearModality();
        this.getModalityForSchedule();
        this.snackbar.success(this.therapyName + " " + JSON.parse(res).message);

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  }
  clearModality() {
    this.dtoScheduleModality = new dtoScheduleModality();
    this.scheduleModalityList = [];
    this.selectedDay=[];
    this.dtoScheduleModality.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
    this.dtoScheduleModality.patientAccountId = this.patientAccountId;
    this.dtoScheduleModality.patientInfoId = this.patientInfoId;
    this.dtoScheduleModality.isRecurance = false;
    this.dtoScheduleModality.endDate = this.treatmentenddate;
  }
  onWeekDaySelection(event, day) {
    if (event == true) {
      this.selectedDay.push(day)
    } else {
      let index = this.selectedDay.findIndex(d => d === day);
      this.selectedDay.splice(index, 1);
    }

  }

  // onGroupTherapy(item) {
  //   this.isGroupTherapyScreen = true;
  //   this.isModalityScreen = false;
  //   this.spinnerservice.show();
  //   this.treatmentservice.getGroupTherapy(0).subscribe(
  //     res => {
  //       this.spinnerservice.hide();
  //       if (res != null) {
  //         this.events = [];
  //         for (var i = 0; i < res.length; i++) {

  //           var title, color,enddate;
  //           enddate = res[i].endDate == null ? new Date((moment(res[i].startDate).format("MM/DD/YYYY") + " " +(moment(res[i].startTime)).add(res[i].duration, 'minutes').format('HH:mm'))) :  new Date(moment(res[i].endDate).format("MM/DD/YYYY") + " " +moment(res[i].startTime, 'HHmm').add(res[i].duration, 'minutes').format('HH:mm'));
  //           if (res[i].booked == res[i].capacity) {
  //             title = "<span>" + res[i].groupTherapyName + "</span> ";
  //             color = colors.red;
  //           } else {
  //             title = "<span>" + res[i].groupTherapyName + "</span> " + "<br/><a (click)='onViewTherapy()'> View Slots </a>  <span class='slot-img mr-1'><i class='fas fa-chair  text-success'></i></span>:"+(res[i].capacity - res[i].booked)+" <span class='slot-img mr-1'><i class='fas fa-chair  text-danger'></i></span>:"+res[i].booked;
  //             color = colors.blue;
  //           }
  //           let _obj = {
  //             start: new Date(moment(res[i].startDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
  //             //end:res[i].endDate == null ? new Date((moment(this.treatmentenddate).format("MM/DD/YYYY") + " " +(moment(res[i].startTime)).add(res[i].duration, 'minutes').format('HH:mm'))) :  new Date(moment(res[i].endDate).format("MM/DD/YYYY") + " " +moment(res[i].startTime, 'HHmm').add(res[i].duration, 'minutes').format('HH:mm')),
  //             title: title,
  //             color: color,
  //             cssClass: 'event-anchor',
  //             id: res[i].groupTherapyId
  //           }

  //           this.events = [...this.events, _obj];
  //         }
  //         this.refresh.next();
  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );

  // }
  // onGroupTherapy(item) {
  //   this.isGroupTherapyScreen = true;
  //   this.isModalityScreen = false;
  //   this.spinnerservice.show();
  //   this.treatmentservice.getGroupTherapy(0).subscribe(
  //     res => {
  //       this.spinnerservice.hide();
  //       if (res != null) {
  //         this.recurringEvents = [];
  //         for (var i = 0; i < res.length; i++) {

  //           var title, color, enddate;
  //           enddate = res[i].endDate == null ? new Date((moment(res[i].startDate).format("MM/DD/YYYY") + " " + (moment(res[i].startTime)).add(res[i].duration, 'minutes').format('HH:mm'))) : new Date(moment(res[i].endDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime, 'HHmm').add(res[i].duration, 'minutes').format('HH:mm'));
  //           if (res[i].booked == res[i].capacity) {
  //             title = "<span>" + res[i].groupTherapyName + "</span> ";
  //             color = colors.red;
  //           } else {
  //             title = "<span>" + res[i].groupTherapyName + "</span> " + "<br/><a (click)='onViewTherapy()'> View Slots </a>  <span class='slot-img mr-1'><i class='fas fa-chair  text-success'></i></span>:" + (res[i].capacity - res[i].booked) + " <span class='slot-img mr-1'><i class='fas fa-chair  text-danger'></i></span>:" + res[i].booked;
  //             color = colors.blue;
  //           }
  //           let _obj = {
  //             //start: new Date(moment(res[i].startDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
  //             end:(moment(res[i].startTime)).add(res[i].duration, 'minutes').format('HH:mm'),
  //             title: title,
  //             color: color,
  //             cssClass: 'event-anchor',
  //             id: res[i].groupTherapyId,
  //             rrule: {
  //               freq: RRule.DAILY,
  //               // dtstart: new Date(Date.UTC(2021, 10, 23, 19, 1, 0)),
  //               // until: new Date(Date.UTC(2021, 10, 28, 20, 1, 0))
  //               dtstart: new Date(moment(res[i].startDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
  //               until: res[i].endDate == null ? new Date((moment(this.treatmentenddate).format("MM/DD/YYYY") + " " + (moment(res[i].startTime)).add(res[i].duration, 'minutes').format('HH:mm'))) : new Date(moment(res[i].endDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).add(res[i].duration, 'minutes').format('HH:mm')),
  //               //end: new Date(moment(res[i].startDate).format("MM/DD/YYYY") + " " +moment(res[i].startTime, 'HHmm').add(res[i].duration, 'minutes').format('HH:mm')),
  //             },
  //           }

  //           this.recurringEvents = [...this.recurringEvents, _obj];
  //         }

  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );

  // }
  // updateCalendarEvents(
  //   viewRender:
  //     | CalendarMonthViewBeforeRenderEvent
  //     | CalendarWeekViewBeforeRenderEvent
  //     | CalendarDayViewBeforeRenderEvent
  // ): void {
  //   if (
  //     !this.viewPeriod ||
  //     !moment(this.viewPeriod.start).isSame(viewRender.period.start) ||
  //     !moment(this.viewPeriod.end).isSame(viewRender.period.end)
  //   ) {

  //     this.viewPeriod = viewRender.period;
  //     this.events = [];

  //     this.recurringEvents.forEach((event) => {
  //       //let item = event;
  //       const rule: RRule = new RRule({
  //         ...event.rrule,
  //         // dtstart: moment(viewRender.period.start).startOf('day').toDate(),
  //         // until: moment(viewRender.period.end).endOf('day').toDate(),
  //       });
  //       const { title, color,id } = event;
  //       const END = event.end
  //       rule.all().forEach((date) => {
  //         this.events.push({
  //           title,
  //           color,
  //           end:new Date(moment(date).format("MM/DD/YYYY") + " " +END),
  //           start: moment(date).toDate(),
  //           id:id
  //         });
  //       });
  //     });
  //     this.cdr.detectChanges();
  //     this.refresh.next();
  //   }
  // }

  // setView(view: CalendarView) {
  //   this.view = view;
  // }
  // handleEvent(action: string, event: CalendarEvent): void {
  //   this.onViewTherapy(event.id);

  // }
  onViewTherapy(id) {
    let obj = {
      id: id,
      patient: this.patientAccountId,
      treatmentPlanDate: this.treatmentPlanDate,
      patientName: this._dtoTreatmentInfo?.patientName,
      chmaTreatmentSummaryId: this.chmaTreatmentSummaryId,
      patientInfoId: this.patientInfoId,
    }
    this.router.navigate(['/schedule-grptherapy'], { queryParams: { id: this.commonService.FrontEndEncryption(obj) } });

  }
  onIsViewTherapy(item) {
    let obj = {
      id: item.groupTherapyId,
      patient: this.patientAccountId,
      treatmentPlanDate: this.treatmentPlanDate,
      patientName: this._dtoTreatmentInfo?.patientName,
      chmaTreatmentSummaryId: this.chmaTreatmentSummaryId,
      patientInfoId: this.patientInfoId,
      isView: true
    }
    this.router.navigate(['/schedule-grptherapy'], { queryParams: { id: this.commonService.FrontEndEncryption(obj) } });

  }
  onGroupTherapy() {
    this.isGroupTherapyScreen = true;
    this.isModalityScreen = false;
    this.spinnerservice.show();
    this.treatmentservice.getGroupTherapy(this.patientAccountId).subscribe(
      res => {
       
        this.spinnerservice.hide();
        this.Decrptedlst = res;
        this.Decrptedlst.forEach(e => {e.releaseDateEdit = false}) 
        //console.log(this.Decrptedlst)
        this.lstGroupMeeting = this.Decrptedlst.slice(0, 6);
        this.assignedtotal=this.Decrptedlst.filter(x=>x.isAssigned == true).length;
        this.dataSource = new MatTableDataSource(this.Decrptedlst);
        this.dataSource.paginator = this.paginator;
      })
  }
  onPageChange($event) {
    this.lstGroupMeeting = this.Decrptedlst.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  onAssignClick(i){
    this.isShowAssign=i
    if(moment(this.Decrptedlst[i].startDate).isBefore(moment(this.treatmentPlanDate))){
      this.startDate = this.treatmentPlanDate
    }
    else{
      this.startDate = this.Decrptedlst[i].startDate
    }
  //  console.log(this.startDate)
  let localendDate = this.datepipe.transform( this.treatmentenddate,'yyyy-MM-ddTh:mm:ss')
 
  if(moment(this.treatmentenddate).isBefore(moment(this.Decrptedlst[i].endDate))){
    this.endDate = localendDate
  }
  else{
    this.endDate = this.Decrptedlst[i].endDate
  }
  //console.log(this.treatmentenddate)
  //console.log(localendDate)
  //  if(localendDate > this.Decrptedlst[i].endDate){
  //    this.endDate = this.Decrptedlst[i].endDate
  // }
  // else{
  //   this.endDate = localendDate
  // }

  }

  onbookSlots(item) {  
    
    item.status = 33;
    item.patientAccountId = this.patientAccountId;
    
    if(this.assignDate == undefined || this.releaseDate == undefined)  {
      this.isReqAssignTherapy=true;
      return
    }
    if(!moment(this.assignDate).isBefore(moment(this.releaseDate))){
      return
    }
    item.groupTherapyId = String(item.groupTherapyId);
    item.assignDate = this.assignDate.toDate();
    item.releaseDate = this.releaseDate.toDate();
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(item, false);
    this.treatmentservice.postBookSlotGrpTherapy(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.onGroupTherapy();
        this.getModalityForSchedule();
        this.snackbar.success(JSON.parse(res).message);
        this.isReqAssignTherapy=false;
        this.assignDate = undefined ;
        this.releaseDate = undefined;
      },
      err => {
        this.spinnerservice.hide();

      },
    );

  }
  onCancelSlots(item) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.ReleaseNote
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        item.status = 22;
    item.patientAccountId = this.patientAccountId;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(item, false);
    this.treatmentservice.postBookSlotGrpTherapy(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.onGroupTherapy();
        this.snackbar.success((JSON.parse(res).message).replace('{name}', item.groupTherapyName));
        this.getModalityForSchedule();
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  
      }
    });
  }
  firstCharfromString(data) {
    if(Number(data.charAt(0)) == 0){
      return false;
    }
    return true;
  }
  //#region  Hema Changes
  // resetform() {
  //   this.dtoScheduleModality = new dtoScheduleModality();
  //   this.scheduleModalityList = [];
  //   this.isSearch = false;
  // }

  // getTreatmentPlandetails() {
  //   this.spinnerservice.show();

  //   this.treatmentservice.getTreatmentPlanList(this.chmaTreatmentSummaryId).subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       if (res != null) {
  //         this._dtoTreatmentInfo = res[0];
  //         this.patientAccountId = this._dtoTreatmentInfo.patientAccountId;
  //         this.patientInfoId = this._dtoTreatmentInfo.patientInfoId;
  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();

  //     },
  //   );
  // }


  // getTreatmentPlanMaster() {
  //   this.spinnerservice.show();
  //   this.ddlTherapy = [];
  //   this.treatmentservice.getTreatmentPlanMaster().subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       let theraphyList = this.commonService.decrypdata(res.therapy);

  //       for (let i = 0; i < theraphyList.length; i++) {
  //         let theraphy = this._dtoModality.filter((item) => item.therapyId == theraphyList[i].TherapyId);
  //         if (theraphy.length != 0) {
  //           this.ddlTherapy.push({
  //             TherapyId: theraphyList[i].TherapyId,
  //             TherapyName: theraphyList[i].TherapyName,
  //           })
  //         }
  //       }

  //     },
  //     err => {
  //       this.spinnerservice.hide();

  //     },
  //   );
  // }


  // getTreatmentPlanDiagnosis() {
  //   this.spinnerservice.show();

  //   this.treatmentservice.getTreatmentPlanDiagnosis(this.chmaTreatmentSummaryId).subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       if (res.dtoTreatmentDiagnosis && res.dtoTreatmentDiagnosis.length > 0) {

  //         if (res.dtoTreatmentModality.length > 0) {
  //           this._dtoModality = res.dtoTreatmentModality;
  //           this.getTreatmentPlanMaster();
  //         }
  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );
  // }

  // getDay(date) {
  //   var weekdays = new Array(7);
  //   weekdays[0] = "Sunday";
  //   weekdays[1] = "Monday";
  //   weekdays[2] = "Tuesday";
  //   weekdays[3] = "Wednesday";
  //   weekdays[4] = "Thursday";
  //   weekdays[5] = "Friday";
  //   weekdays[6] = "Saturday";
  //   return weekdays[date.day()];
  // }

  // getDaysBetweenDates(startDate, endDate) {
  //   var now = startDate.clone();

  //   while (now.isSameOrBefore(endDate)) {
  //     this.dates.push(moment(now));
  //     now.add(1, 'days');
  //   }
  //   return this.dates;
  // };

  // getScheduleModality() {

  //   //getDay(this.)
  //   this.spinnerservice.show();
  //   this.treatmentservice.getScheduldeModalityDetails(this.patientAccountId, this.chmaTreatmentSummaryId).subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       if (res.dtoTreatmentDiagnosis && res.dtoTreatmentDiagnosis.length > 0) {

  //         if (res.dtoTreatmentModality.length > 0) {
  //           this.dtoScheduleModality = res.dtoTreatmentModality;
  //         }
  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();

  //     },
  //   );
  // }

  // getMaxWeek(event) {
  //   if (this.dtoScheduleModality.isRecurringChanged !== true) {
  //     let theraphy = this._dtoModality.filter((item) => item.therapyId == event);
  //     if (theraphy.length != 0) {
  //       for (let i = 0; i < theraphy.length; i++) {
  //         this.dtoScheduleModality.recurringEvery = theraphy[i].theraphyWeek;
  //       }
  //     }
  //   }
  // }


  // getAllScheduleModality() {
  //   this.spinnerservice.show();
  //   this.treatmentservice.getScheduleModality(this.patientAccountId, this.chmaTreatmentSummaryId).subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       this.resScheduleModality = res.dtoScheduleModality;
  //       if (res.dtoScheduleModality != null || res.dtoScheduleModality != undefined) {
  //         this.dtoScheduleModality = '';
  //         this.scheduleModalityList = [];
  //         this.isSearch = true;
  //         this.dtoScheduleModality = res.dtoScheduleModality;
  //         this.modalityIsConfilct(res.dtoScheduleModalityDetails);
  //         this.dtoScheduleModality.rangeOn = moment(this.dtoScheduleModality.rangeOn).add(1, 'days');
  //         this.dtoScheduleModality.startDate = moment(this.dtoScheduleModality.startDate).add(1, 'days');
  //         this.maxdate = moment(this.dtoScheduleModality.startDate).add(this.dtoScheduleModality.recurringEvery, 'days');
  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();

  //     },
  //   );
  // }


  // modalityIsConfilct(data) {
  //   debugger;
  //   this.scheduleModalityList = [];
  //   var startDate = moment(this.dtoScheduleModality.startDate);
  //   var endDate = moment(this.dtoScheduleModality.rangeOn);
  //   let dateList = this.getDaysBetweenDates(startDate, endDate);
  //   var a = this.dtoScheduleModality.appointTime.split("T");
  //   this.dtoScheduleModality.appointTime = a[1];
  //   var futureDate = this.addMinutes(this.dtoScheduleModality.appointTime, this.dtoScheduleModality.tolerance);
  //   this.spinnerservice.show();
  //   var offset = new Date().getTimezoneOffset();
  //   var planDate = moment(this.dtoScheduleModality.startDate).format("MM/DD/YYYY");
  //   var endOnDate = moment(this.dtoScheduleModality.rangeOn).format("MM/DD/YYYY");
  //   this.treatmentservice.getScheduldeConflictModalityDetails(this.patientAccountId, this.chmaTreatmentSummaryId, planDate, endOnDate, this.dtoScheduleModality.appointTime, futureDate).subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       this.dtoScheduleConflictModalitylist = res;
  //       for (let i = 0; i < data.length; i++) {
  //         var appt = data[i].appointTime.split("T");
  //         var endT = data[i].endTime.split("T");
  //         this.scheduleModalityList.push({
  //           day: data[i].day,
  //           date: moment(data[i].date).format('MM/DD/YYYY'),
  //           appointTime: appt[1],
  //           isChecked: data[i].isChecked,
  //           endTime: endT[1],
  //           isConclict: this.getIsConflict(data[i].date),
  //           location: data[i].location,
  //           locationName: this.getLocationName(data[i].location),
  //         });
  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();

  //     },
  //   );

  // }

  // addMinutes(time, minsToAdd) {
  //   function D(J) { return (J < 10 ? '0' : '') + J };

  //   var piece = time.split(':');

  //   var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

  //   return D(mins % (24 * 60) / 60 | 0) + ':' + D(mins % 60);
  // }

  // getMaxDate(event) {
  //   this.dtoScheduleModality.rangeOn = '';
  //   this.dtoScheduleModality.isRecurringChanged = true;
  //   this.maxdate = moment(this.dtoScheduleModality.startDate).add(this.getDayCount(event), 'days');
  // }
  // getDayCount(count) {
  //   var weekdays = new Array(4);
  //   weekdays[1] = 7;
  //   weekdays[2] = 14;
  //   weekdays[3] = 21;
  //   weekdays[4] = 28;
  //   return weekdays[count];
  // }






  // searchModality() {
  //   debugger;
  //   this.isSearch = true;
  //   this.scheduleModalityList = [];
  //   var startDate = moment(this.dtoScheduleModality.startDate);
  //   var endDate = moment(this.dtoScheduleModality.rangeOn);
  //   let dateList = this.getDaysBetweenDates(startDate, endDate);
  //   var futureDate = this.addMinutes(this.dtoScheduleModality.appointTime, this.dtoScheduleModality.tolerance);
  //   this.spinnerservice.show();
  //   var offset = new Date().getTimezoneOffset();
  //   var planDate = moment(this.dtoScheduleModality.startDate).format("MM/DD/YYYY");
  //   var endOnDate = moment(this.dtoScheduleModality.rangeOn).format("MM/DD/YYYY");
  //   this.treatmentservice.getScheduldeConflictModalityDetails(this.patientAccountId, this.chmaTreatmentSummaryId, planDate, endOnDate, this.dtoScheduleModality.appointTime, futureDate).subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       this.dtoScheduleConflictModalitylist = res;
  //       for (let i = 0; i < dateList.length; i++) {
  //         let getDay = this.getDay(moment(dateList[i]));
  //         if (this.dtoScheduleModality.isSunday == true && getDay == 'Sunday') {

  //           this.scheduleModalityList.push({
  //             day: getDay,
  //             isChecked: this.getIsChecked(dateList[i]),
  //             isConclict: this.getIsConflict(dateList[i]),
  //             date: dateList[i].format('MM/DD/YYYY'),
  //             appointTime: this.dtoScheduleModality.appointTime,
  //             endTime: futureDate,
  //             location: this.dtoScheduleModality.location,
  //             locationName: this.getLocationName(this.dtoScheduleModality.location),
  //           });
  //         }
  //         if (this.dtoScheduleModality.isMonday == true && getDay == 'Monday') {

  //           this.scheduleModalityList.push({
  //             day: getDay,
  //             isChecked: this.getIsChecked(dateList[i]),
  //             isConclict: this.getIsConflict(dateList[i]),
  //             date: dateList[i].format('MM/DD/YYYY'),
  //             appointTime: this.dtoScheduleModality.appointTime,
  //             endTime: futureDate,
  //             location: this.dtoScheduleModality.location,
  //             locationName: this.getLocationName(this.dtoScheduleModality.location),
  //           });
  //         }
  //         if (this.dtoScheduleModality.isTuesday == true && getDay == 'Tuesday') {

  //           this.scheduleModalityList.push({
  //             day: getDay,
  //             isChecked: this.getIsChecked(dateList[i]),
  //             isConclict: this.getIsConflict(dateList[i]),
  //             date: dateList[i].format('MM/DD/YYYY'),
  //             appointTime: this.dtoScheduleModality.appointTime,
  //             endTime: futureDate,
  //             location: this.dtoScheduleModality.location,
  //             locationName: this.getLocationName(this.dtoScheduleModality.location),
  //           });
  //         }
  //         if (this.dtoScheduleModality.isWednesday == true && getDay == 'Wednesday') {

  //           this.scheduleModalityList.push({
  //             day: getDay,
  //             isChecked: this.getIsChecked(dateList[i]),
  //             isConclict: this.getIsConflict(dateList[i]),
  //             date: dateList[i].format('MM/DD/YYYY'),
  //             appointTime: this.dtoScheduleModality.appointTime,
  //             endTime: futureDate,
  //             location: this.dtoScheduleModality.location,
  //             locationName: this.getLocationName(this.dtoScheduleModality.location),
  //           });
  //         }
  //         if (this.dtoScheduleModality.isThursday == true && getDay == 'Thursday') {

  //           this.scheduleModalityList.push({
  //             day: getDay,
  //             isChecked: this.getIsChecked(dateList[i]),
  //             isConclict: this.getIsConflict(dateList[i]),
  //             date: dateList[i].format('MM/DD/YYYY'),
  //             appointTime: this.dtoScheduleModality.appointTime,
  //             endTime: futureDate,
  //             location: this.dtoScheduleModality.location,
  //             locationName: this.getLocationName(this.dtoScheduleModality.location),
  //           });
  //         }
  //         if (this.dtoScheduleModality.isFriday == true && getDay == 'Friday') {

  //           this.scheduleModalityList.push({
  //             day: getDay,
  //             isChecked: this.getIsChecked(dateList[i]),
  //             isConclict: this.getIsConflict(dateList[i]),
  //             date: dateList[i].format('MM/DD/YYYY'),
  //             appointTime: this.dtoScheduleModality.appointTime,
  //             endTime: futureDate,
  //             location: this.dtoScheduleModality.location,
  //             locationName: this.getLocationName(this.dtoScheduleModality.location),
  //           });
  //         }
  //         if (this.dtoScheduleModality.isSaturday == true && getDay == 'Saturday') {

  //           this.scheduleModalityList.push({
  //             day: getDay,
  //             isChecked: this.getIsChecked(dateList[i]),
  //             isConclict: this.getIsConflict(dateList[i]),
  //             date: dateList[i].format('MM/DD/YYYY'),
  //             appointTime: this.dtoScheduleModality.appointTime,
  //             endTime: futureDate,
  //             location: this.dtoScheduleModality.location,
  //             locationName: this.getLocationName(this.dtoScheduleModality.location),
  //           });
  //         }
  //       }

  //     },
  //     err => {
  //       this.spinnerservice.hide();

  //     },
  //   );

  // }

  // getIsConflict(conflictDate) {
  //   let isConflictlist = this.dtoScheduleConflictModalitylist.filter((item) => item.isConclict == true);
  //   for (let i = 0; i < isConflictlist.length; i++) {
  //     let firstDate = moment(isConflictlist[i].date).format('MM/DD/YYYY');
  //     let secondDate = moment(conflictDate).format('MM/DD/YYYY');
  //     if ((firstDate == secondDate) && isConflictlist[i].isConclict == true) {
  //       console.log(isConflictlist[i].isConclict)
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // updateItem(item, event) {
  //   let index = this.scheduleModalityList.indexOf(item);
  //   if (event.target.checked) {
  //     item.isChecked = true;
  //   }
  //   else {
  //     item.isChecked = false;
  //   }
  //   this.scheduleModalityList[index] = item;
  // }

  // getIsChecked(conflictDate) {
  //   let location = this.dtoScheduleConflictModalitylist.filter((item) => item.date == conflictDate && item.isChecked);
  //   if (location.length != 0) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }

  // getLocationName(locId) {
  //   let location = this.ddllocation.filter((item) => item.meetingLocationsId == locId);
  //   return location[0].locationName;
  // }

  // submitModality() {
  //   let obj = {
  //     dtoScheduleModalityDetailList: this.scheduleModalityList,
  //     dtoScheduleModality: this.dtoScheduleModality
  //   }
  //   const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
  //   this.spinnerservice.show();
  //   this.treatmentservice.postScheduleModality(dataToEncrypt).subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       this.snackbar.success(JSON.parse(res).message);
  //       this.getAllScheduleModality();
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //       this.snackbar.error(err.error);
  //     },
  //   );
  // }


  // onMettingDate(event, index) {
  //   this.isConflict = true;
  //   this.staffAppointmentDay1 = [];
  //   this.Day1viewDate = event.value;
  //   var day = moment(event.value).day();
  //   this.getTimeSlots(1, day, index)
  // }

  // getTimeSlots(id, day, index) {
  //   var offset = new Date().getTimezoneOffset();
  //   let _obj = {
  //     Day: day,
  //     CurrentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
  //     SelectedDate: moment.utc(this.Day1viewDate).utcOffset(-offset).format("MM/DD/YYYY"),
  //   }
  //   const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
  //   this.clinicianservice.getTimeSlots(dataToEncrypt).subscribe(
  //     res => {
  //       if (res != null) {

  //         this.timeslots = JSON.parse(res);
  //         this.noslots = this.timeslots.length == 0;
  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );
  // }


  // meetingDateFilter = (d: any) => {
  //   const day = moment(d).isoWeekday();
  //   if (this.meetingDateBlocks?.length > 0) {
  //     return this.meetingDateBlocks.includes(String(day));
  //   }
  //   return true
  // };
  //#endregion
  handleDateChange(event) {
    const m: moment.Moment = event.value;
 
      console.log("Date of Birth: " + m.toDate());
      console.log(this.currentdate)
      if(this.datepipe.transform(m.toDate(),"yyyy-MM-dd")==this.datepipe.transform(this.treatmentPlanDate,"yyyy-MM-dd")){
        this.currentTime = moment(this.IntakeEndTime).format("HH:mm");
      }
      else if(this.datepipe.transform(m.toDate(),"yyyy-MM-dd")==this.datepipe.transform(this.currentdate,"yyyy-MM-dd") && this.datepipe.transform(m.toDate(),"yyyy-MM-dd")!=this.datepipe.transform(this.treatmentPlanDate,"yyyy-MM-dd")){
        this.currentTime = moment().format("HH:mm");
      }
      else{
        this.currentTime = '00.00'
      }
  } 

  GetMeetingTypeMaster(){
    debugger;
    this.spinnerservice.show();
    this.treatmentservice.GetMeetingTypeMaster().subscribe(
      (res: any) => {
        debugger;
        this.spinnerservice.hide();
        if (res.length>0){
          this.ddlMeetingType=res;
          this.ddlMeetingType = this.ddlMeetingType.filter(x=>x.commonAppointmentTypeid ==0)
        // console.log(this.ddlMeetingType);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  endDateEdit(item,i){
    this.Decrptedlst[i].releaseDateEdit = true; 
    // this.releaseDateEdit = true;
    this.releaseDates = item.releaseDate;
  }
  onbookEndDate(item) {  
    
    item.status = 33;
    item.patientAccountId = this.patientAccountId;
    item.releaseDate =  new Date(this.releaseDates) 

    if(this.releaseDates == undefined)  {
      this.isReqAssignTherapy=true;
      return
    }
    if(!moment(this.releaseDates)){
      return
    }
    item.groupTherapyId = String(item.groupTherapyId);
    item.releaseDates = this.releaseDates;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(item, false);
    this.treatmentservice.postBookSlotGrpTherapy(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.onGroupTherapy();
        this.getModalityForSchedule();
        this.snackbar.success("Release date updated successfully");
       item.releaseDateEdit = false;
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
}

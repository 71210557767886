export class SuicidalScreener 
{
    suicideScreeningId:Number;
    patientAccountId:Number;
    wishToDead:boolean;
    suicidalThoughts:boolean;
    thoughtsWithoutPlan:boolean;
    intentWithoutPlan:boolean;
    intentWithPlan:boolean;
    suicideBehaviourLifeTime:boolean;
    suicideBehaviourThreeMonths:boolean;
    FormTypeId:any;
}
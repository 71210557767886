
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { BillingService } from 'src/app/billing/billing.service';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import * as moment from 'moment';
declare function decrypt(data): any;

@Component({
  selector: 'app-billing-provider',
  templateUrl: './billing-provider.component.html',
  styleUrls: ['./billing-provider.component.scss']
})
export class BillingProviderComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['sno','TransCode', 'TtypeDescription', 'TypeofService', 
  'CptCode','RevenuCode' ,'Hcpcscode','Units','Amount', 'actions', 'Tdescription'];
  dataSource: MatTableDataSource<any>;
  
  providerTypeList : any;
  ProviderId : Number;
  roleAccess: any;
  constructor(
    public billingservice: BillingService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, private route: ActivatedRoute,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService,public datepipe: DatePipe,
    public roleaccessservice:PageAccessService
  ) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.BillingMasters;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.getProviderMaster();
  }


  getProviderMaster(){
    this.spinnerservice.show();
    this.billingservice.getProviderMaster().subscribe(
      res => {
        if (res != null) {
        //  let result=decrypt(res.objInputString)
       //  console.log(result);
          this.providerTypeList=res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

}

<div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
  </div>
  
  <div class="example-container mat-elevation-z8">
  
    <mat-table [dataSource]="dataSource" matSort> 
      
      <ng-container matColumnDef="FirstName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> FirstName </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.FirstName}} </mat-cell>
      </ng-container> 
     
      <ng-container matColumnDef="LastName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> LastName </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.LastName}} </mat-cell>
      </ng-container>  
     
      <ng-container matColumnDef="UserName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> UserName </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.UserName}} </mat-cell>
      </ng-container> 
     
      <ng-container matColumnDef="Rolename">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Role </mat-header-cell>
        <mat-cell *matCellDef="let row" > {{row.Rolename}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="Email">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
        <mat-cell *matCellDef="let row" > {{row.Email}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="ContactNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header> ContactNumber </mat-header-cell>
        <mat-cell *matCellDef="let row" > {{row.ContactNumber}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-icon-button matTooltip="Edit"  class="iconbutton" (click)="oneditUserProfile(row.AuthId)" color="primary">
              <mat-icon aria-label="Edit">edit</mat-icon>
            </button>
          <button mat-icon-button matTooltip="Delete"  class="iconbutton" (click)="deleteUser(row)" color="warn">
              <mat-icon aria-label="Delete">delete</mat-icon>
            </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>  
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
  
  <div class="modal mrfmodel" [style.display]="isEditModal ? 'block' : 'none'" id="edituser" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true"> 
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title f-clr-primary" id="staticBackdropLabel">Edit User</h4>
          <button type="button" class="close" (click)="editclose();" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="registerForm"  autocomplete="off"  class="create-user"> 
                <div class="form-row">      
                  <div class="col-6 popup-cont-sec left">
                      <div class="col-12 mb-2">
                        <label for="inp" class="inp">
                            <span class="label">First Name </span>
                            <input type="text"  formControlName="firstName" placeholder="&nbsp;">
                            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback d-block">
                                <div *ngIf="f.firstName.errors.required || f.firstName.errors.cannotContainSpace">First Name is required</div>
                            </div>
                            <span class="focus-bg"></span>
                        </label>
                      </div>
                      <div class="col-12 mb-2">
                        <label for="inp" class="inp">
                            <span class="label">Last Name </span>
                            <input type="text" formControlName="lastName" placeholder="&nbsp;">
                            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback d-block">
                                <div *ngIf="f.lastName.errors.required || f.lastName.errors.cannotContainSpace">Last Name is required</div>
                            </div>
                            <span class="focus-bg"></span>
                        </label>
                      </div>
                     
                      <div class="col-12 mb-2">
                        <label for="inp" class="inp">
                            <span class="label">E-mail </span>
                            <input type="text" formControlName="email" placeholder="&nbsp;" >
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback d-block">
                                <div *ngIf="f.email.errors.required || f.email.errors.cannotContainSpace">Email is required</div>
                            </div>
                            <span class="focus-bg"></span>
                        </label>
                      </div>
                      <div class="col-12 mb-2">
                        <label for="inp" class="inp">
                            <span class="label">Phone </span>
                            <input type="text" formControlName="contactNumber" placeholder="&nbsp;">
                            <div *ngIf="submitted && f.contactNumber.errors" class="invalid-feedback d-block">
                                <div *ngIf="f.contactNumber.errors.required || f.contactNumber.errors.cannotContainSpace">Contact Number is required</div>
                            </div>
                            <span class="focus-bg"></span>
                        </label>
                      </div>
                  </div>
                  <div class="col-6 popup-cont-sec right">
                    <div class="col-12 mb-2">
                      <span class="select-label">Role name</span>
                      <label for="inp" class="inp">
                          <select formControlName="roleId"  required>
                              <option *ngFor="let s of rolelist" [value]="s.roleId">
                                  {{s.description}}</option>                                   
                          </select>
                          <div *ngIf="submitted && f.roleId.errors" class="invalid-feedback d-block">
                            <div *ngIf="f.roleId.errors.required">Role is required</div>
                        </div>
                          <span class="focus-bg"></span>
                      </label>
                    </div>                 
                   
                  </div>

                </div>
            </form>
        
        </div>
        <div class="modal-footer">
            <div class="row align-items-end">
                <button type="button" class="btn primary-btn-gradient btn-min-width mr-4" data-dismiss="modal" (click)="editclose();">Cancel</button>
                <button type="button" class="btn primary-btn-gradient btn-min-width mr-4" (click)="onUpdate()" >Update</button>
            </div>
        </div>
      </div>
    </div>
</div>


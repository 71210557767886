<div class="payment-page" *ngIf="roleAccess.isView">
    <div class="col-md-12">
       <div class="lead-box">
           <div  class="box-lead-head"><i class="fas fa-credit-card mr-2"></i>Payment</div>
           <div  class="label-pay additional " >
            Please add any payment options you would like to keep on file (Credit Cards, Debit Cards, HSAs, or FSAs)
           </div>
           <div class="payment-type odd-bg col-md-12 p-0 pt-2 mb-2">
             <div class="d-flex justify-content-center">
               <div class="label-pay additional  col-md-2 mt-3">Payment Type</div>
               <div class="col-md-3">
                 <mat-form-field class="w-100">
                  <mat-label>Select Payment Type</mat-label>
                  <mat-select name="paymenttype"  (selectionChange)="onPaymenttypeChange($event.value)"  
                  [(ngModel)]="dtoPatientPaymentDetails.PaymentTypeId"  #PaymentTypeId="ngModel"  [(value)]="dtoPatientPaymentDetails.PaymentTypeId"
                  >
                    <mat-option *ngFor="let dg of drpPaymentype" [value]="dg.PaymentTypeId">{{dg.PaymentTypeName}}</mat-option> 
                  </mat-select>
                   <!-- <mat-error *ngIf="(PaymentTypeId.invalid)">
                    <mat-error *ngIf="PaymentTypeId.errors?.required">Payment Type is required</mat-error>
                  </mat-error>  -->
                </mat-form-field>
               </div>
               <!-- <div class="col-md-3">
               <button type="button" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="onSave()" *ngIf="!isSave">SAVE</button>
               </div> -->
             </div>
           </div>
<!--credit Card -->
 <div class="col-md-5 card credit-card-section  mx-auto pb-3" *ngIf="showCreditCard">
  <form (ngSubmit)="Paymentfrm.form.valid  && OnSaveCreditcard(Paymentfrm)" id="Paymentfrm" autocomplete="off" #Paymentfrm="ngForm"
    novalidate> 
    <div class="row">
      <div class="d-flex align-items-center justify-content-between w-100 top-credit-head"> <span>Credit card</span>
        <div class="icons"> <img src="https://i.imgur.com/2ISgYja.png" width="30"> <img src="https://i.imgur.com/W1vtnOV.png" width="30"> <img src="https://i.imgur.com/35tC99g.png" width="30"> <img src="https://i.imgur.com/2ISgYja.png" width="30"> </div>
       </div>
      <div class="col-md-12">
        <mat-form-field class="w-100">
          <mat-label>Card Holder Name</mat-label>
          <input matInput maxlength="75" [(ngModel)]="dtoPatientPaymentDetails.PatientCreditCardDetails.CardHolderName"  #CardHolderName="ngModel"
          name="CardHolderName" [ngClass]="{ 'is-invalid': Paymentfrm.submitted && CardHolderName.invalid }" required/>
         <mat-error *ngIf="Paymentfrm.submitted && CardHolderName.invalid">
         <mat-error *ngIf="CardHolderName.errors.required">Card holder name is required</mat-error>
      
       </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field class="w-100">
          <mat-label>Card Number</mat-label>
          <input matInput customMin="16" maxlength="16" [(ngModel)]="dtoPatientPaymentDetails.PatientCreditCardDetails.CardNumber"  #CardNumber="ngModel"
          numeric  name="CardNumber" [ngClass]="{ 'is-invalid': Paymentfrm.submitted && CardNumber.invalid }" required/>
         <mat-error *ngIf="Paymentfrm.submitted && CardNumber.invalid">
         <mat-error *ngIf="CardNumber.errors.required">Card number is required</mat-error>
        </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
       <mat-form-field class="w-100">
         <mat-label>Card Type</mat-label>
        <mat-select name="cardtype"  
          [(ngModel)]="dtoPatientPaymentDetails.PatientCreditCardDetails.CreditcardTypeId"  #CreditcardTypeId="ngModel" [ngClass]="{ 'is-invalid': Paymentfrm.submitted && CreditcardTypeId.invalid }"
             required>
          <mat-option *ngFor="let dc of drpCreditcardtypes" [value]="dc.PaymentTypeId">{{dc.PaymentTypeName}}</mat-option> 
        </mat-select>
         <mat-error *ngIf="(Paymentfrm.submitted && CreditcardTypeId.invalid)">
          <mat-error *ngIf="CreditcardTypeId.errors?.required">Credit card type is required</mat-error>
        </mat-error> 
      </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="w-100" >
          <mat-label>Expiry date</mat-label>
          <input matInput [matDatepicker]="exppicker" [(ngModel)]="dtoPatientPaymentDetails.PatientCreditCardDetails.ExpiryDate" 
          #ExpiryDate="ngModel" name="ExpiryDate" required [min]="currentdate">
          <mat-datepicker-toggle matSuffix [for]="exppicker "></mat-datepicker-toggle>
          <mat-datepicker #exppicker [startView]="'multi-year'" [startAt]="pickerDate" (monthSelected)="setMonthAndYear($event,exppicker)"></mat-datepicker>
          <mat-error *ngIf="Paymentfrm.submitted && ExpiryDate.invalid">
            <mat-error  *ngIf="ExpiryDate.errors.required">
              Expiry Date is required</mat-error>
        </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field class="w-100">
          <mat-label> CVV</mat-label>
          <input matInput maxlength="4" [(ngModel)]="dtoPatientPaymentDetails.PatientCreditCardDetails.CVV"  #CVV="ngModel"
          name="CVV" [ngClass]="{ 'is-invalid': Paymentfrm.submitted && CVV.invalid }" required/>
         <mat-error *ngIf="Paymentfrm.submitted && CVV.invalid">
         <mat-error *ngIf="CVV.errors.required">CVV required</mat-error>
        </mat-error>
        </mat-form-field>
       </div>
      <!-- <div class="col-md-12">
        <mat-form-field class="w-100">
          <mat-label>Bank Name</mat-label>
          <input matInput maxlength="70" [(ngModel)]="dtoPatientPaymentDetails.PatientCreditCardDetails.BankName"  #BankName="ngModel"
          name="BankName" [ngClass]="{ 'is-invalid': Paymentfrm.submitted && BankName.invalid }" required/>
         <mat-error *ngIf="Paymentfrm.submitted && BankName.invalid">
         <mat-error *ngIf="BankName.errors.required">Bank name is required</mat-error>
        </mat-error>
        </mat-form-field>
      </div> -->
      <div class="col-md-12">
        <mat-form-field class="w-100">
          <mat-label>Description<span class="text-xs sub-descib">(maximum allowed character is 200 only)</span></mat-label>
          <textarea matInput  row="3" maxlength="200" [(ngModel)]="dtoPatientPaymentDetails.PatientCreditCardDetails.Comments"
          #Comments="ngModel" name="Comments" ></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-12 text-right" *ngIf="roleAccess.isEdit ||  roleAccess.isCreate">
         <button type="submit" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button  *ngIf="showCreditCard">SAVE</button>
        <button  type="button" mat-stroked-button class="btn btn-cancel primary-btn-m h-40" (click)="onCancelClick()">Cancel</button>
       </div>
    
    </div>
    
 </form>
</div>
<!--Search-->
</div> 
<div class="col-md-12  mt-2"  *ngIf="dtoPatientPaymentDetails.PaymentTypeId==1 && patientPaymentTypemasters.length>0">
  <!--search-->
<div class="row">
 <div  class="col-md-4 mt-3">Payment Details </div>
 <div  class="col-md-3 ml-auto"> 
   <mat-form-field class="w-100">
     <mat-label>Search credit card details</mat-label>
     <input matInput type="text" (keyup)="searchTblVal($event.target.value)"/>
   </mat-form-field>
  </div>
 </div>


<!--search end-->

<div class="col-md-12 table-custom p-0">
 <div class="table-responsive">
   <mat-table #table [dataSource]="dataSource" matSort class="w-100">
     <ng-container matColumnDef="sno" class="table-flex-column">
       <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
       <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
     </ng-container>
     <ng-container matColumnDef="CardHolderName" class="table-flex-column">
       <mat-header-cell *matHeaderCellDef mat-sort-header>Card Holder </mat-header-cell>
       <mat-cell *matCellDef="let element"> {{element.CardHolderName}} </mat-cell>
     </ng-container>
     <!-- <ng-container matColumnDef="BankName" class="table-flex-column">
       <mat-header-cell *matHeaderCellDef mat-sort-header>Bank name</mat-header-cell>
       <mat-cell *matCellDef="let element"> {{element.BankName}} </mat-cell>
     </ng-container> -->
     
     <ng-container matColumnDef="CardNumber" class="table-flex-column" >
       <mat-header-cell *matHeaderCellDef mat-sort-header>Card Number</mat-header-cell>
       <mat-cell *matCellDef="let element"> {{element.CardNumber}} </mat-cell>
     </ng-container>

     <ng-container matColumnDef="Cvv" class="table-flex-column">
       <mat-header-cell *matHeaderCellDef mat-sort-header>Cvv </mat-header-cell>
       <mat-cell *matCellDef="let element"> {{element.Cvv}} </mat-cell>
     </ng-container>

     <ng-container matColumnDef="ExpiryDate" class="table-flex-column">
       <mat-header-cell *matHeaderCellDef mat-sort-header>Expiration Date</mat-header-cell>
       <mat-cell *matCellDef="let element"> {{element.ExpiryDate | date:'MM/yy'}} </mat-cell>
     </ng-container>
   
     <ng-container matColumnDef="PaymentTypeName" class="table-flex-column">
       <mat-header-cell *matHeaderCellDef mat-sort-header>Card Type </mat-header-cell>
       <mat-cell *matCellDef="let element"> {{element.PaymentTypeName}} </mat-cell>
     </ng-container>

     <ng-container matColumnDef="Comments" class="table-flex-column">
       <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
       <mat-cell *matCellDef="let element"> {{element.Comments}} </mat-cell>
     </ng-container>

     
     <ng-container matColumnDef="actions" class="table-flex-column" >
       <mat-header-cell *matHeaderCellDef>{{roleAccess.isEdit ? 'Actions' : '' }}  </mat-header-cell>
       <mat-cell *matCellDef="let element;let i = index;">
         <button type="button" mat-icon-button matTooltip="Edit"  *ngIf="roleAccess.isEdit"  (click)="onListEdit(element)" color="primary">
           <img src="../../../assets/images/editblue.png">
         </button>
         
       </mat-cell>
     </ng-container>

     <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
     <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

   </mat-table>
  
 </div>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

</div>
 <!--search end-->
 

   <!-- credit card end-->

     
    </div>
  </div>
  <div *ngIf="!roleAccess.isView">
    {{viewRightsMsg}}
    </div>

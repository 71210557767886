import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { location } from 'src/app/_models/_Common/_Location';
import { NgForm } from '@angular/forms';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { cloneDeep } from "lodash";
import { CatalogueService } from 'src/app/common/pages/price-catalogue/catalogue.service';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
@Component({
  selector: 'app-meeting-location',
  templateUrl: './meeting-location.component.html',
  styleUrls: ['./meeting-location.component.scss']
})
export class MeetingLocationComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['sno','location', 'meetingName', 'seatCapacity', 'IsActive', 'actions'];
  dataSource: MatTableDataSource<any>;
  ddlfacility:any;
  _Dtolocation: location;
  locationlst: any;
  isEditLocation: boolean = false;
  ddllocation: any;
  isGlobalAccess: boolean = false;
  onpostmeetinglocation: boolean;
  roleAccess: any;
  constructor(public global: Global, public settingsService: SettingsService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public CatlogueService: CatalogueService
    ,public roleaccessservice:PageAccessService
    ) { }

  ngOnInit(): void {
    this._Dtolocation = new location();
    const pageid = constantVariables.pagelists.Settings;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.getMeetingLocation();
    this.getddlMaster();
    this.getddlLocation();
  }
  //#region  Location 
  getddlMaster() {
    //this.spinnerservice.show();
    this.settingsService.getAllMaster().subscribe(
      res => {
        if (res != null) {        
          this.ddlfacility = this.commonService.decrypdata(res.facilityType);       

          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  onchangeAccesslvl(event) {
    if (event.checked) {
      //this.priceCatalogue.accessLevel = 0;
      //this.priceCatalogue.location = null;
      this.isGlobalAccess = true;

    } else {
      //this.priceCatalogue.accessLevel = 1;
      this.isGlobalAccess = false;
    }
  }

  locationchange(event){
    this.isGlobalAccess = false;
    this._Dtolocation.accessLevel = undefined;
  }

  getddlLocation() {
    this.spinnerservice.show();
    this.CatlogueService.getLocations().subscribe(
      res => {
        if (res != null) {
          this.ddllocation = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }


  getMeetingLocation() {
    //this.spinnerservice.show();
    this.settingsService.getMeetinglocation().subscribe(
      res => {
          this.spinnerservice.hide();
        this.locationlst = res;
        this.dataSource = new MatTableDataSource(this.locationlst);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  postMeetingLocation(frmlocation: NgForm) {
    if (!frmlocation.valid) {
      return
    }
    this.onpostmeetinglocation = true;
    if (this._Dtolocation.accessLevel == 1) {
      this._Dtolocation.locationId = '';
      this._Dtolocation.location='';
      this._Dtolocation.accessLevel = 1;
    } else {
      this._Dtolocation.accessLevel = 0;
      if (this._Dtolocation.locationId && this._Dtolocation.locationId.length > 0) {
        this._Dtolocation.location = this._Dtolocation.locationId.toString();
      }
    }

    const dataToEncrypt = this.commonService.DataToEncrypt(this._Dtolocation, false);
    this.spinnerservice.show();
    this.settingsService.postMeetingLocation(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if(this.isEditLocation == true)
        {
          this.snackbar.success("SFCC Meeting Room has been updated successfully");
        }
        else
        {
          this.snackbar.success("SFCC Meeting Room has been saved successfully");
          this.isGlobalAccess = false;
        }
        this.getMeetingLocation();
        frmlocation.resetForm();
        this.resetLocationform(frmlocation);
        this.onpostmeetinglocation = false;
        
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  resetLocationform(form:NgForm) {
    form.resetForm();
    this._Dtolocation = new location();
    this.isEditLocation = false;

  }
  oneditLocation(element) {
    this.isEditLocation = true;
    this._Dtolocation = cloneDeep(element);
    this._Dtolocation.locationId = element.locationName.split(',').map(Number);
  }
  ondeleteLocation(data) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.settingsService.deleteMeetingLocation(data.meetingLocationsId).subscribe(
          (res: any) => {
            this.getMeetingLocation();
            this.spinnerservice.hide();
            this.snackbar.success(constantVariables.SuccessMessage.DeletetedSuccesscully);
          },
          err => {
            this.spinnerservice.hide();
          },
        );
      }
    });

  }
  //#endregion
}

<div class="col-md-12 pl-5">
    <div class="row">
       
        <div class="col-md-12 mt-3">
            <div class="lef-tak-cont d-flex">
                <ul class="nav flex-row ">
                    <li class="nav-item" *ngIf="activeGroupBy == 1">
                        <a class="nav-link  cursor-pointer" [ngClass]="{'active':activeGroupBy == 1}" role="button"
                            aria-current="page" (click)="getAllTask()">
                            <i class="far fa-check-circle mr-2"></i> All Tasks
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="activeGroupBy == 2">
                        <a class="nav-link cursor-pointer" role="button" [ngClass]="{'active':activeGroupBy == 2}"
                            (click)="getimportantTask()">
                            <i class="fas fa-exclamation-circle mr-2"></i> Important
                            <button class="project-column-heading__options_small ml-2">{{filterImportant?.length}}</button>

                        </a>
                    </li>
                    <li class="nav-item " *ngIf="activeGroupBy == 3">
                        <a class="nav-link cursor-pointer" role="button" (click)="getAssignedToMe()"
                            [ngClass]="{'active':activeGroupBy == 3}">
                            <i class="fas fa-people-arrows mr-2"></i> Assigned to You
                            <button class="project-column-heading__options_small ml-2">{{filterAssignedtoMe?.length}}</button>

                        </a>
                    </li>
                    <li class="nav-item " *ngIf="activeGroupBy == 4">
                        <a class="nav-link cursor-pointer" role="button" (click)="getAssignedbyMe()"
                            [ngClass]="{'active':activeGroupBy == 4}">
                            <i class="fas fa-people-arrows mr-2"></i> Assigned by You
                            <button class="project-column-heading__options_small ml-2">{{filterAssignedbyMe?.length}}</button>

                        </a>
                    </li>
                </ul>
                <ul class="ml-auto nav " >
                    <div class="filter-group d-inline-block">
                        <!-- <label class="d-inline-block mr-2">Sort by</label> -->
                        <div id='my-select' class="btn-group">
                            <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <span id="selected">Sort by</span> <i class="fas fa-chevron-down"></i>
                            </button>
                            <ul class="dropdown-menu">
                              <li><a (click)="getAllTask()">All Task</a></li>
                              <li><a (click)="getimportantTask()">Important <span class="badge badge-warning ml-2">{{filterImportant?.length}}</span></a></li>
                              <li><a (click)="getAssignedToMe()">Assigned to You <span class="badge badge-warning ml-2">{{filterAssignedtoMe?.length}}</span></a></li>
                              <li><a (click)="getAssignedbyMe()">Assigned by You <span class="badge badge-warning ml-2">{{filterAssignedbyMe?.length}}</span></a></li>
                            </ul>
                        </div>
                        </div>
                    <li class="nav-item ">

                        <a class="nav-link create-tasked cursor-pointer" role="button" (click)="CreateTask(taskfrm)" *ngIf="isCreate">
                            <i class="fas fa-plus mr-2"></i> Create Task
                        </a>
                    </li>
                </ul>
            </div>
            

        </div>
        <div class="col-md-12 mt-3">

            <div class="kanban-task col-md-12">
                <div class="project-tasks row">
                    <div class="project-column col-md-4 col-sm-12">
                        <div class="project-column-heading">
                            <h2 class="project-column-heading__title"><i class="far fa-file mr-2"></i>New</h2>
                            <button class="project-column-heading__options">{{filterToday?.length}}</button>
                        </div>


                        <div cdkDropList id="cdkdropnew" #newtask="cdkDropList" [cdkDropListData]="filterToday"
                            [cdkDropListConnectedTo]="[inprogresstask,donetask]" class="example-list"
                            (cdkDropListDropped)="drop($event)">
                            <div class="task" *ngIf="filterToday?.length == 0">
                                <div class="msg-nomeet text-center">No task Available</div>
                                <img class="img-fluid d-table text-center mx-auto" src="../../../../assets/images/no-tasked.jpg">
                            </div>
                            <div class="task" cdkDrag draggable="true" *ngFor="let item of filterToday">
                                <div class="task__tags">
                                    <span class="task__tag task__tag--overdue"
                                        *ngIf="item.taskPriority == 1">High</span>
                                    <span class="task__tag task__tag--copyright"
                                        *ngIf="item.taskPriority == 2">Medium</span>
                                    <span class="task__tag task__tag--illustration"
                                        *ngIf="item.taskPriority == 3">Low</span>
                                    <!-- <span class="task__tag task__tag--design">{{item.status}}</span> -->
                                    <div class="item-action dropdown">
                                        <a data-toggle="dropdown" class="text-muted" data-abc="true"
                                            aria-expanded="false">
                                            <svg _ngcontent-faq-c167="" aria-hidden="true" focusable="false"
                                                data-prefix="fas" data-icon="ellipsis-h" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                data-fa-i2svg="" class="svg-inline--fa fa-ellipsis-h fa-w-16">
                                                <path _ngcontent-faq-c167="" fill="currentColor"
                                                    d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z">
                                                </path>
                                            </svg></a>
                                        <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"
                                            x-placement="bottom-end"
                                            style="position: absolute; transform: translate3d(16px, 22px, 0px); top: 0px; left: 0px; will-change: transform;">
                                            <a class="dropdown-item cursor-pointer" data-abc="true"
                                                (click)="onedittask(item)" *ngIf="item.createdBy == UserId && isEdit">
                                                Edit
                                            </a>
                                            <a class="dropdown-item cursor-pointer" data-abc="true"
                                                (click)="ondeletetask(item)"
                                                *ngIf="item.createdBy == UserId && isDelete">Delete
                                            </a>
                                            <a class="dropdown-item cursor-pointer" data-abc="true"
                                                (click)="onViewtask(item)" *ngIf="isView">View
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <span class="tag-label w-100">{{item.taskName}}</span>
                                <p title="{{global.getContent1(item.content)}}" innerHTML="">{{(item.content.length > 100 ?
                                    (global.getContent1(item.content) | slice:0:100)+'...':global.getContent1(item.content))}}</p>
                                <div class="mb-2 clearfix">
                                    <span class="text-muted float-left text-xs mr-2 mt-1 align-middle">Assigned -</span>

                                    <span class="tag-label">{{item.userName}}</span>
                                    <div class="float-right">
                                        <a class="mr-2" title="comment" *ngIf="roleAccess.isCreate"><i class="far fa-comments" style="cursor: pointer;" (click)="openDialog(item)"></i></a>
                                        <a class="mr-2" title="Complete" *ngIf="roleAccess.isApprove"><i class="far fa-check-circle" style="cursor: pointer;"(click)="oncompletetask(item)" ></i></a>
                                        <a class="mr-2" title="activity" *ngIf="roleAccess.isView"><i  class="fas fa-user-clock" style="cursor: pointer;" (click)="showActivityLog(item.taskId)"></i></a>
                                    </div>
                                </div>
                               
                                <div class="task__stats  "
                                    [ngClass]="{'duein':item.status != statusOverDue && item.status != statusToday,'newed':item.status == statusToday,'overdued':item.status == statusOverDue }">
                                    <span><time datetime="2021-11-24T20:00:00"><svg
                                                class="svg-inline--fa fa-flag fa-w-16" aria-hidden="true"
                                                focusable="false" data-prefix="fas" data-icon="flag" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                data-fa-i2svg="">
                                                <path fill="currentColor"
                                                    d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z">
                                                </path>
                                            </svg>
                                            <label class="text-muted mr-1">Due Date:</label><span class="value-task">
                                                {{item.dueDate | date:'MMM
                                                d, y'}}</span></time></span>
                                    <span class="ml-auto"><label class="text-muted mr-1">Created On :</label><span
                                            class="value-task">{{item.createDate | date:'MMM d, y'}}</span></span>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="project-column col-md-4 col-sm-12">
                        <div class="project-column-heading">
                            <h2 class="project-column-heading__title"><i class="far fa-clock mr-2"></i>In Progress</h2>
                            <button class="project-column-heading__options">{{filterInprogress?.length}}</button>
                        </div>

                        <div cdkDropList id="cdkdropinprogress" #inprogresstask="cdkDropList"
                            [cdkDropListData]="filterInprogress" [cdkDropListConnectedTo]="[newtask,donetask]"
                            class="example-list" (cdkDropListDropped)="drop($event)">
                            <div class="task" *ngIf="filterInprogress?.length == 0">
                                <div class="msg-nomeet text-center">No task Available</div>
                                <img class="img-fluid d-table text-center mx-auto" src="../../../../assets/images/no-tasked.jpg">
                            </div>
                            <div class="task" draggable="true" cdkDrag *ngFor="let item of filterInprogress">
                                <div class="task__tags">
                                    <span class="task__tag task__tag--overdue"
                                        *ngIf="item.taskPriority == 1">High</span>
                                    <span class="task__tag task__tag--copyright"
                                        *ngIf="item.taskPriority == 2">Medium</span>
                                    <span class="task__tag task__tag--illustration"
                                        *ngIf="item.taskPriority == 3">Low</span>
                                    <!-- <span class="task__tag task__tag--copyright">{{item.status}}</span> -->
                                    <div class="item-action dropdown"> <a data-toggle="dropdown" class="text-muted"
                                            data-abc="true" aria-expanded="false"> <svg _ngcontent-faq-c167=""
                                                aria-hidden="true" focusable="false" data-prefix="fas"
                                                data-icon="ellipsis-h" role="img" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512" data-fa-i2svg=""
                                                class="svg-inline--fa fa-ellipsis-h fa-w-16">
                                                <path _ngcontent-faq-c167="" fill="currentColor"
                                                    d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z">
                                                </path>
                                            </svg></a>
                                        <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"
                                            x-placement="bottom-end"
                                            style="position: absolute; transform: translate3d(16px, 22px, 0px); top: 0px; left: 0px; will-change: transform;">
                                            <a class="dropdown-item cursor-pointer" data-abc="true"
                                                (click)="onedittask(item)" *ngIf="item.createdBy == UserId && isEdit">
                                                Edit
                                            </a>
                                            <a class="dropdown-item cursor-pointer" data-abc="true"
                                                (click)="ondeletetask(item)"
                                                *ngIf="item.createdBy == UserId && isDelete">Delete
                                            </a>
                                            <a class="dropdown-item cursor-pointer" data-abc="true"
                                                (click)="onViewtask(item)" *ngIf="isView">View
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <span class="tag-label w-100">{{item.taskName}}</span>
                                <p title="{{global.getContent1(item.content)}}" innerHTML="">{{(item.content.length > 100 ?
                                    (global.getContent1(item.content) | slice:0:100)+'...':global.getContent1(item.content))}}</p>
                                <div class="mb-2 clearfix">
                                    <span class="text-muted float-left text-xs mr-2 mt-1 align-middle">Assigned -</span>

                                    <span class="tag-label ">{{item.userName}}</span>
                                    <div class="float-right">
                                        <a class="mr-2" title="comment" *ngIf="roleAccess.isCreate"><i class="far fa-comments" style="cursor: pointer;"  (click)="openDialog(item)"></i></a>
                                        <a class="mr-2" title="complete" *ngIf="roleAccess.isApprove"><i class="far fa-check-circle" style="cursor: pointer;" (click)="oncompletetask(item)"></i></a>
                                        <a class="mr-2" title="activity" *ngIf="roleAccess.isView"><i  class="fas fa-user-clock" style="cursor: pointer;" (click)="showActivityLog(item.taskId)"></i></a>
                                    </div>
                                </div>
                               

                                <div class="task__stats"
                                    [ngClass]="{'overdued':item.status == statusOverDue,'duein':item.status != statusOverDue && item.status != statusToday && item.statusId != statusInprogess ,'inprog':item.statusId == statusInprogess}">
                                    <span><time datetime="2021-11-24T20:00:00"><svg
                                                class="svg-inline--fa fa-flag fa-w-16" aria-hidden="true"
                                                focusable="false" data-prefix="fas" data-icon="flag" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                data-fa-i2svg="">
                                                <path fill="currentColor"
                                                    d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z">
                                                </path>
                                            </svg>
                                            <label class="text-muted mr-1">Due Date:</label><span
                                                class="value-task">{{item.dueDate | date:'MMM
                                                d, y'}}</span> </time></span>
                                    <span class="ml-auto"><label class="text-muted mr-1">Created On :</label><span
                                            class="value-task">{{item.createDate | date:'MMM d, y'}}</span></span>


                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="project-column col-md-4 col-sm-12">
                        <div class="project-column-heading">
                            <h2 class="project-column-heading__title"><i class="far fa-check-circle mr-2"></i>Done</h2>
                            <button class="project-column-heading__options">{{filterComplted?.length}}</button>
                        </div>

                        <div cdkDropList id="cdkdropdone" #donetask="cdkDropList" [cdkDropListData]="filterComplted"
                            [cdkDropListConnectedTo]="[newtask,inprogresstask]" class="example-list"
                            (cdkDropListDropped)="drop($event)">
                            <div class="task" *ngIf="filterComplted?.length == 0">
                                <div class="msg-nomeet text-center">No task Available</div>
                                <img class="img-fluid d-table text-center mx-auto" src="../../../../assets/images/no-tasked.jpg">
                            </div>
                            <div class="task" cdkDrag draggable="true" *ngFor="let item of filterComplted">
                                <div class="task__tags">
                                    <span class="task__tag task__tag--overdue"
                                        *ngIf="item.taskPriority == 1">High</span>
                                    <span class="task__tag task__tag--copyright"
                                        *ngIf="item.taskPriority == 2">Medium</span>
                                    <span class="task__tag task__tag--illustration"
                                        *ngIf="item.taskPriority == 3">Low</span>
                                    <!-- <span class="task__tag task__tag--illustration">{{item.status}}</span> -->
                                    <div class="item-action dropdown"> <a data-toggle="dropdown" class="text-muted"
                                            data-abc="true" aria-expanded="false"> <svg _ngcontent-faq-c167=""
                                                aria-hidden="true" focusable="false" data-prefix="fas"
                                                data-icon="ellipsis-h" role="img" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512" data-fa-i2svg=""
                                                class="svg-inline--fa fa-ellipsis-h fa-w-16">
                                                <path _ngcontent-faq-c167="" fill="currentColor"
                                                    d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z">
                                                </path>
                                            </svg></a>
                                        <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"
                                            x-placement="bottom-end"
                                            style="position: absolute; transform: translate3d(16px, 22px, 0px); top: 0px; left: 0px; will-change: transform;">
                                            <!-- <a class="dropdown-item cursor-pointer" data-abc="true"
                                            (click)="onedittask(item)" *ngIf="item.createdBy == UserId">
                                            Edit
                                        </a> -->
                                            <a class="dropdown-item cursor-pointer" data-abc="true"
                                                (click)="ondeletetask(item)"
                                                *ngIf="item.createdBy == UserId && isDelete">Delete
                                            </a>
                                            <a class="dropdown-item cursor-pointer" data-abc="true"
                                                (click)="onViewtask(item)" *ngIf="isView">View
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <span class="tag-label w-100">{{item.taskName}}</span>
                                <p title="{{global.getContent1(item.content)}}" innerHTML="">{{(item.content.length > 100 ?
                                    (global.getContent1(item.content) | slice:0:100)+'...':global.getContent1(item.content))}}</p>
                                <div class="mb-2 clearfix">
                                    <span class="text-muted float-left text-xs mr-2 mt-1 align-middle">Assigned -</span>

                                    <span class="tag-label ">{{item.userName}}</span>
                                     <div class="float-right">
                                        <a class="mr-2" title="comment"><i class="far fa-comments" style="cursor: pointer;" (click)="openDialog(item)"></i></a>
                                        <!-- <a class="mr-2" ><i class="far fa-check-circle" style="cursor: pointer;" (click)="oncompletetask(item)"></i></a> -->
                                    </div> 
                                </div>
                                <div class="task__stats complete">
                                    <span><time datetime="2021-11-24T20:00:00"><svg
                                                class="svg-inline--fa fa-flag fa-w-16" aria-hidden="true"
                                                focusable="false" data-prefix="fas" data-icon="flag" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                                data-fa-i2svg="">
                                                <path fill="currentColor"
                                                    d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z">
                                                </path>
                                            </svg>
                                            <label class="text-muted mr-1">Due Date:</label><span
                                                class="value-task">{{item.dueDate | date:'MMM
                                                d, y'}}</span> </time></span>
                                    <span class="ml-auto"><label class="text-muted mr-1">Created On :</label><span
                                            class="value-task">{{item.createDate | date:'MMM d, y'}}</span></span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal task-create-modal" tabindex="-1" role="dialog" [style.display]="isShowAddtask ? 'block' : 'none'">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header p-2">
                <h5 class="modal-title">{{!isEditTask ?
                    'Create Task':'Update Task'}}</h5>
                <button type="button" class="close" data-dismiss="modal" (click)="resetnotesform()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="task-create-section bg-white p-2  ">
                    <form (ngSubmit)="taskfrm.form.valid  && saveTaskInfo(taskfrm)" id="taskfrm" autocomplete="off"
                        #taskfrm="ngForm" novalidate>
                        <div [ngClass]="{'disabled':isViewTask}">
                            <div class="col-md-12">
                                <!-- <label class="control-label-new mb-0">Enter Task Name</label> -->
                                <div class="col-md-12 p-0">
                                    <mat-form-field class="w-100">
                                      <mat-label class="control-label-new mb-0">Task Name
                                       </mat-label>
                                        <input matInput placeholder="" maxlength="80" [(ngModel)]="taskinfo.taskName"
                                            appAlphabetOnly #taskName="ngModel" name="taskName"
                                            [ngClass]="{ 'is-invalid': taskfrm.submitted && taskName.invalid }"
                                            required>
                                        <mat-error *ngIf="taskfrm.submitted && taskName.invalid">
                                            <mat-error *ngIf="taskName.errors.required">Task Name is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <mat-label class="control-label-new">Enter Task
                                  <span *ngIf="!(taskfrm.submitted && content.invalid)">*</span>
                                  <span *ngIf="taskfrm.submitted && content.invalid" style="color:  #D0342C;">*</span>
                                </mat-label>
                                <div class="col-md-12 p-0">
                                    <quill-editor [(ngModel)]="taskinfo.content" #content="ngModel" name="content"
                                        [modules]="quillConfig" required>
                                    </quill-editor>
                                    <mat-error *ngIf="taskfrm.submitted && content.invalid">
                                        <mat-error style="padding-top:12px;" class="mat-form-field-subscript-wrapper"
                                            *ngIf="content.errors.required">
                                            Content is required</mat-error>
                                    </mat-error>
                                </div>

                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-6 mt-2">
                                        <mat-form-field class="w-100">
                                            <mat-label class="control-label-new">Assigned To
                                            </mat-label>

                                            <mat-select [(ngModel)]="taskinfo.assignedTo" #assignedTo="ngModel"
                                                name="assignedTo"
                                                [ngClass]="{ 'is-invalid': taskfrm.submitted && assignedTo.invalid }"
                                                required [disabled]="taskinfo.isSelfAssigned">
                                                <mat-option *ngFor="let item of lstuser" [value]="item.staffId">
                                                    {{item.firstName}} {{item.lastName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="taskfrm.submitted && assignedTo.invalid">
                                                <mat-error *ngIf="assignedTo.errors.required">Assigned To is
                                                    required
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <mat-form-field class="w-100">
                                            <mat-label class="control-label-new">Due Date
                                            </mat-label>

                                            <input matInput [matDatepicker]="duepicker" readonly [min]="currentdate"
                                                (click)="duepicker.open()" [(ngModel)]="taskinfo.dueDate"
                                                #dueDate="ngModel" name="dueDate"
                                                [ngClass]="{ 'is-invalid': taskfrm.submitted && dueDate.invalid }"
                                                required>
                                            <mat-datepicker-toggle matSuffix [for]="duepicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #duepicker></mat-datepicker>
                                            <mat-error *ngIf="taskfrm.submitted && dueDate.invalid">
                                                <mat-error *ngIf="dueDate.errors.required">Due Date is
                                                    required</mat-error>

                                            </mat-error>

                                        </mat-form-field>
                                    </div>

                                    <div class="col-md-6">
                                        <mat-form-field class="w-100">
                                            <mat-label class="control-label-new">Reminder
                                            </mat-label>
                                            <input matInput [matDatepicker]="flagpicker" readonly [min]="currentdate"
                                                (click)="flagpicker.open()" [(ngModel)]="taskinfo.flagMeBy"
                                                #flagMeBy="ngModel" name="flagMeBy" (dateChange)="changeRemainder()">
                                            <mat-datepicker-toggle matSuffix [for]="flagpicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #flagpicker></mat-datepicker>
                                            <!-- <mat-error *ngIf="taskfrm.submitted && flagMeBy.invalid">
                                                <mat-error *ngIf="flagMeBy.errors.required">Remind me is
                                                    required</mat-error>
                                            </mat-error> -->
                                        </mat-form-field>
                                        <mat-error class="mat-form-field-subscript-wrapper w-100"
                                            *ngIf="!isValidFlagDate">
                                            Reminder should be scheduled before due date</mat-error>

                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="w-100">
                                            <mat-label class="control-label-new">Priority
                                            </mat-label>
                                            <mat-select [(ngModel)]="taskinfo.taskPriority" #taskPriority="ngModel"
                                                name="taskPriority"
                                                [ngClass]="{ 'is-invalid': taskfrm.submitted && taskPriority.invalid }"
                                                required>
                                                <mat-option *ngFor="let item of ddlPriority" [value]="item.value">
                                                    {{item.label}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="taskfrm.submitted && taskPriority.invalid">
                                                <mat-error *ngIf="taskPriority.errors.required">Priority is
                                                    required</mat-error>
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                    <div class="col-md-12  mt-4">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="check-custom mt-1">
                                                    <mat-checkbox class="example-margin" [(ngModel)]="taskinfo.isSelfAssigned"
                                                        #isSelfAssigned="ngModel" name="isSelfAssigned" (change)="onSelfAssign($event)" >Self Assign
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="upload-btn-wrapperring position-relative">
                                                    <button class="btn"><i class="fas fa-plus mr-2"></i>Attachment
                                                    </button>
                                                    <input multiple
                                                        accept="application/msword, application/vnd.ms-excel, application/pdf, image/*"
                                                        title="Add Files" type="file" (change)="onFileChange($event)"
                                                        class=" cursor-pointer" #taskfile />
                                                    <!-- <i class="fas fa-plus mr-2"></i>Attachment -->
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2 p-0">
                                             
                                                <mat-chip-list class="mt-2">

                                                    <mat-chip *ngFor="let a of UploadedFileList;let x = index"
                                                        (click)="downloadUploadedFile(a)">
                                                        <!--<i class="far fa-file-pdf mr-2"></i><i class="fas fa-images mr-2"></i> -->
                                                        {{a.fileName}}
                                                        <mat-icon matChipRemove (click)="deleteUploadImage(x)">
                                                            cancel</mat-icon>
                                                    </mat-chip>

                                                </mat-chip-list>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 text-right mt-3 mb-2">

                            <button mat-raised-button class="primary-btn-outlined-small mr-2 d-none">Save
                                Task</button>
                            <button *ngIf="!isViewTask" type="submit" title="{{!isEditTask ?
                    'Create Task':'Update Task'}}" form="taskfrm" mat-raised-button
                                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{!isEditTask ?
                                'Save Task':'Update Task'}}</button>
                            <button type="button" mat-raised-button class="btn btn-cancel primary-btn-m mb-2 h-40 "
                                (click)="resetnotesform()">Cancel
                            </button>

                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div>
<!--Select Location pop up-->
<div class="comment-popup loc-sched-popup benchmark-popup-rating" >
    <div class="modal" tabindex="-1" role="dialog"  [style.display]="isAllLocation ? 'block' : 'none'">
      <div class="modal-dialog mat-dialog-container p-3" role="document">
        <div class="modal-content border-0">
            <div class="modal-body">
               <div>
                  <div class="text-center location-schedule">
                   <i class="fas fa-map-marked-alt"></i>
                    </div>
                  <p  class="text-center font-weight-500">Please choose a location</p>
               </div>
             </div>
            </div>
           </div>
          </div>
    </div>
  <!--Select Location pop up end-->



  <div class="Activity-popup">
    <div class="modal" tabindex="-1" role="dialog" style="display: block;" *ngIf="isActivityLogShown">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Activity Log</h6>
            <button type="button" class="close"  data-dismiss="modal"
              aria-label="Close" (click)="isActivityLogShown=false;taskActivityLog=null">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
  <div class="individual-threaphy-container">
    <div  class="history-list col-md-12 p-0">
      <ul >
        <li *ngFor="let item of taskActivityLog" >
          <div  class="list-history col-md-12">
            <div  class="row" >
              <div  class="col-md-1 ng-star-inserted">
                <img  src="../../../assets/images/move2stage-icon.png" class="img-fluid"></div>
                <div  class="col-md-9">
                  <p class="sub-history mb-1"></p>
                  <p  class="sub-history2 mb-1">{{item.message}}</p>
                </div>
                <div  class="col-md-2 text-right">
                    <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) |  date: 'MM/dd/yyyy'}}</p>
                    <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) | date: 'hh:mm a' }}</p>
                </div>
              </div>
            </div>
          </li>
          
      </ul>
    </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
<div class="landing-page">
  <div class="col-md-12">
    <div class="row">
      <div class="lead-box pl-5 pr-5 pt-4 pb-4 w-100 ">
      <div class="col-md-12 text-center welcome-txt">
      <h2 class="">Welcome !</h2>
      <div class="w-100 welcome-img">
        <img class="img-fluid" src="../../../assets/images/WelcomepageImage.png">
      </div>
      </div>
      <div class="col-md-12 text-center welcome-txt">
        <h2 class="">{{userName}},</h2>
      </div>  
      <div class="sub-txt-board mt-3 mb-3">In the next few steps, we would like to confirm your profile and insurance details. Please fill out each section carefully and completely.</div>
        <div class="w-100 text-center">
         <button class="btn start-btn" (click)="onLetsStarted()">Let's get started</button>
        </div>
    </div>
    </div>
  </div>
</div>
<!-- <div class="landing-page">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-6 col-lg-5">
        <div class="card pl-5 pr-5 pt-4 pb-4 h-100 ">
          <div class="welcome-board mb-5">
            <h1 class="thin-txt">Hi,</h1>
            <h1>{{userName}}</h1>
            <div class="sub-txt-board">LET'S GET YOU ON BOARDED!</div>
          </div>
           <div class="w-100">
             <img class="img-fluid" src="../../../assets/images/Children.png">
           </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-6">
        <div class="card pl-5 pr-5 pt-4 pb-4 h-100 bg-flower">
          <h1 class="thin-txt">Welcome Aboard</h1>
          <div class="sub-txt-board">In the next step we would like you to confirm a few profile and
             insurance details. Please fill it carefully</div>
             <div class="w-100 text-center">
              <button class="btn start-btn" (click)="onLetsStarted()">Let's get started</button>
             </div>
             
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- <div class="inbox-section">
  <div class="heading-inbox clearfix">
    <div class="float-left mess-head">MESSAGES</div>
    <div class="float-right date-custom">
      <mat-form-field class="w-100">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="center-inbox-section">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <div class="card">
          <div class="card-body p-0">
            <div class="col-md-12 mt-2">
              <div class="row">
                <div class="col-md-8 card-head  mt-3">INBOX</div>
                <div class="col-md-4 pl-0">
                  <mat-form-field  class="w-100">
                    <mat-label>Filter</mat-label>
                    <mat-select>
                      <mat-option value="one">Name</mat-option>
                      <mat-option value="two">Date</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="user-name-list">
              <ul class="inbox-nav inbox-divider">
                <li class="active">
                    <a href="#">
                      <img src="../../assets/images/avator.jpg" alt="User Avatar" class="rounded-circle mr-2" style="width:3rem">
                      <div class="name-list-inbox">John williamson
                        <div class="regarding">Reg: Insurance</div>
                      </div>
                      </a>
                </li>
                <li>
                  <a href="#">
                    <img src="../../assets/images/avator.jpg" alt="User Avatar" class="rounded-circle mr-2" style="width:3rem">
                    <div class="name-list-inbox">John williamson
                      <div class="regarding">Reg: Insurance</div>
                    </div>
                    </a>
              </li>
              </ul>
            </div>
            </div>
          
        </div>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="card mb-3">
          <div class="card-body">
              <div class="msg-details">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-lg-9 col-md-12">
                      <img src="../../assets/images/avator.jpg" alt="User Avatar" class="rounded-circle mr-2 align-top" style="width:3rem">
                      <div class="d-inline-block align-middle">
                        <div class="name-list-inbox">John williamson</div>
                        <div class="msg-content">Surely, will help. Click on this link..</div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                      <div class="reply-section text-right">
                        <i class="fas fa-reply mr-3"></i>
                        <i class="fas fa-reply-all mr-3"></i>
                        <i class="fas fa-arrow-right mr-3"></i>
                      </div>
                      <div class="msg-content text-right">05-04-2021 | 08.35 AM</div></div>
                  </div>
                </div>
                
              </div>
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-body">
              <div class="msg-details">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-lg-9 col-md-12">
                      <img src="../../assets/images/avator.jpg" alt="User Avatar" class="rounded-circle mr-2 align-top" style="width:3rem">
                    <div class="d-inline-block align-middle">
                      <div class="name-list-inbox">John williamson</div>
                      <div class="msg-content">Surely, will help. Click on this link..</div>
                    </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                      <div class="reply-section text-right">
                        <i class="fas fa-reply mr-3"></i>
                        <i class="fas fa-reply-all mr-3"></i>
                        <i class="fas fa-arrow-right mr-3"></i>
                      </div>
                      <div class="msg-content text-right">05-04-2021 | 08.35 AM</div></div>
                  </div>
                </div>
                
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="form-configuration">
    <div class="top-btn-section clearfix mb-1 w-100">
        <h2 class="head-section mb-2 mt-1">Create Form</h2>
        <div class="col-md-6 ml-auto mt-2 button-position pr-0">
            <button type="button" (click)="onbacktoformlist()" class="btn primary-btn-outlined float-right mr-2"><i
                    class="fas fa-angle-left pr-2"></i>Back</button>
        </div>

    </div>
    <div class="col-md-12 p-0 created-form-section" *ngIf="configureFormId == 0 ">
        <div class="lead-box pt-2 pb-2">
            <form (ngSubmit)="configureForminfo.form.valid  && postConfigureForm(configureForminfo)"
                id="configureForminfo" autocomplete="off" #configureForminfo="ngForm" novalidate>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">


                            <!--  <label class="m-0">Form Name</label><input matInput placeholder="Form Name" [(ngModel)]="configureForm.formName"
                                    appAlphabetOnly #formName="ngModel" name="formName"
                                    [ngClass]="{ 'is-invalid': configureForminfo.submitted && formName.invalid }"
                                    required> -->
                            <ng-select autocomplete="off" [disabled]="pageid == 1" [items]="ddlFormName"
                                placeholder="Enter the name for Form" appAlphabetOnly bindLabel="formName"
                                bindValue="formName" [(ngModel)]="configureForm.formName" addTagText="Add item"
                                [addTag]="addFormNameTag" (change)="postFormName(configureForm.formName)"
                                name="formName" #formName="ngModel" required
                                [ngClass]="{ 'is-invalid': configureForminfo.submitted && formName.invalid }">

                            </ng-select>
                            <mat-error *ngIf="configureForminfo.submitted && formName.invalid">
                                <mat-error class="mat-form-field-subscript-wrapper" *ngIf="formName.errors.required">
                                    Form Name is required</mat-error>
                            </mat-error>

                        </div>
                        <div class="col-md-3  mt-1">

                            <mat-form-field class="w-100">
                                <mat-label>Form Type </mat-label>
                                <mat-select [(ngModel)]="configureForm.formTypeId" placeholder="Select Form Type"
                                    #formTypeId="ngModel" name="formTypeId"
                                    [ngClass]="{ 'is-invalid': configureForminfo.submitted && formTypeId.invalid }"
                                    required>
                                    <mat-option *ngFor="let typ of ddlFormTypes" [value]="typ.formTypeId">
                                        {{typ.formTypeName}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint>Helps you to define the template for the form.</mat-hint>
                                <mat-error *ngIf="configureForminfo.submitted && formTypeId.invalid">
                                    <mat-error *ngIf="formTypeId.errors.required">Form Type is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3  mt-1">
                            <!-- <mat-form-field class="w-100">
                                <mat-label>Location</mat-label>
                                <mat-select [formControl]="toppings" multiple>
                                  <mat-option >Location1</mat-option>
                                  <mat-option >Location2</mat-option>
                                  <mat-option >Location3</mat-option>
                                  <mat-option >Location4</mat-option>
                                </mat-select>
                              </mat-form-field> -->
                              <mat-form-field class="w-100">
                                <mat-label class="control-label-new">Location</mat-label>
                                <mat-select multiple [disabled]="isGlobalAccess" [(ngModel)]="configureForm.location"
                                    (change)="locationchange($event)" #location="ngModel" name="location"
                                    [ngClass]="{ 'is-invalid': configureForminfo.submitted && location.invalid }"
                                    required>
                                    <mat-option #matOption *ngFor="let item of ddllocation" [value]="item.sfccMasterId"
                                        [matOption.selected]="item.isActive">
                                        {{item.locationName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="configureForminfo.submitted && location.invalid">
                                    <mat-error *ngIf="location.errors.required">Location is required</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-1 pr-0">
                            <div class="check-custom w-100 mt-4">
                                <mat-checkbox class="example-margin"  [(ngModel)]="configureForm.isGlobal"
                                    (change)="onchangeAccesslvl($event)" name="accessLevel">
                                    Global
                                </mat-checkbox>
                            </div>
                        </div>
                        <!-- <div class=" col-md-7 mt-3 pt-1" *ngIf='configureForm.formTypeId == 2'>
                            <div class="row">
                                <label class="additional ">Is explanation for the questions in form</label>

                                <span class="pl-2 p-0">

                                    <mat-radio-group aria-label="Select an option"
                                        [(ngModel)]="configureForm.isquestionExplanation"
                                        #iSquestionExplanation="ngModel" name="iSquestionExplanation" required>
                                        <mat-radio-button class="mr-1" [value]="1">Yes
                                        </mat-radio-button>

                                        <mat-radio-button class="mr-2" [value]="3">No
                                        </mat-radio-button>


                                    </mat-radio-group>

                                </span>
                                <mat-error class="w-100 position-relative"
                                    *ngIf="configureForminfo.submitted && iSquestionExplanation.invalid">
                                    <mat-error class="mat-form-field-subscript-wrapper"
                                        *ngIf="iSquestionExplanation.errors.required">
                                        Explanation is
                                        required</mat-error>
                                </mat-error>
                            </div>

                        </div> -->
                        <div class="col-md-12 mb-2 text-left mt-2"  *ngIf="roleAccess.isEdit ||  roleAccess.isCreate">

                            <button type="button" class="btn primary-btn-outlined float-right mr-2"
                                *ngIf="configureFormId != 0 || pageid == 1" (click)="onAddQuestion()">
                                <i class="fas fa-plus-circle mr-2"></i> Add Question
                            </button>
                            <button type="submit" class="btn primary-btn-filled float-right mr-2">
                                <i class="far fa-save mr-2"></i>{{pageid == 1 ? 'Update Form' : 'Save Form'}}
                            </button>
                        </div>
                      
                    </div>
                </div>
            </form>
           
        </div>

    </div>
  
    <div class="col-md-12 p-0 created-form-section view-form-meta"
        *ngIf="configureFormId != 0 || pageid == 1 || pageid == 2">

        <div class="lead-box pt-2 pb-2">
            <div class="clearfix">
                <div class="col-md-12">
                    <div class="form-details-head">Form Details</div>
                    <div class="form-details col-md-12 mt-3 mb-3 p-0">
                        <div class="row">
                            <div class="col-md-9 ">
                                <div class="row">
                                    
                                    <div class="col-md-6">
                                        <label class="form-detail-label" >Form Name :</label> <span title="{{lstFromDetails?.formName}}"
                                        class="form-detail-Value text-truncate align-middle d-inline-block">{{lstFromDetails?.formName}} &nbsp;&nbsp;
                                          
                                    </span>
                                    <span *ngIf="pageid == 1">  <!-- *ngIf="lstFromDetails.formTypeId == survey && pageid == 1"                     -->
                                        <a   *ngIf="roleAccess.isEdit"   (click)="oneditFormName()" class="icon-note cursor-pointer" title="Edit Form Name"><span class="mr-2"><i
                                            class="far fa-edit"></i></span></a>
                                    </span> 
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-detail-label" >Form Type :</label> <span title="{{lstFromDetails?.formTypeName}}"
                                        class="form-detail-Value text-truncate align-middle d-inline-block">{{lstFromDetails?.formTypeName}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    
                                    <div class="col-md-6">
                                        <label class="form-detail-label-created">Created On :</label> <span
                                            class="form-detail-Value-created">
                                            {{global.globalUtctimeformat(lstFromDetails?.createDate | date:
                                            'MM/dd/yyyy hh:mm a')
                                            }}
                                        </span>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-detail-label-created">Last Updated On :</label> <span
                                            class="form-detail-Value-created">{{
                                                global.globalUtctimeformat(lstFromDetails?.updateDate | date:
                                                'MM/dd/yyyy hh:mm a')
                                                }} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 text-left clearfix mt-2 pr-1 pl-1">
                                <button type="button" class="btn primary-btn-outlined float-right "
                                    *ngIf="pageid == 1  && roleAccess.isCreate" (click)="onAddQuestion()">
                                    <i class="fas fa-plus-circle mr-2"></i> Add Question
                                </button>
                              <!-- <button type="button" class="btn primary-btn-outlined direction-btn float-right mr-2" >
                                <i class="fas fa-plus-circle mr-2"></i> Add Direction
                            </button>--> 
                            </div>
                            
                        </div>
                        <div class="row" *ngIf="!isAddQuestion && pageid == 1 &&lstFromDetails.formTypeId != nutritional">
                            <div class="col-md-4">
                                <label class="form-detail-label" >Directions :</label> <span title="{{lstFromDetails?.questionDirection}}"
                                class="form-detail-Value text-truncate align-middle d-inline-block">{{lstFromDetails?.questionDirection}}</span>
                            
                                <span  *ngIf="lstFromDetails?.questionDirection!=null">                        
                                    <a (click)="oneditDirectionsName()" class="icon-note cursor-pointer" title="Edit Direction Name"><span class="mr-2"><i
                                        class="far fa-edit"></i></span></a>
                                </span> 
                             </div>
                        </div>

                        <div class="row" *ngIf="isAddQuestion && lstFromDetails.formTypeId != nutritional">
                            <div class="col-md-4">
                                <!-- <mat-form-field class="w-100" *ngIf="configureForm.formTypeId == survey "></mat-form-field> -->
                                <mat-form-field class="w-100"  >
                                    <!-- <mat-label> Enter Question Direction</mat-label>-->
                                     <input matInput placeholder="Direction" 
                                       [(ngModel)]="lstFromDetails.questionDirection"
                                         maxlength="200" #lstFromDetails.questionDirection="ngModel"
                                         name="lstFromDetails.questionDirection" />
                                 </mat-form-field>
                            </div>
                           
                        </div>
                        <div class="row"  *ngIf="lstFromDetails.formTypeId != nutritional">
                             <div class="descipr-intake">This form is shows up in the Prepare for Intake
                            <span *ngIf="lstFromDetails?.formTypeId==1"> - Survey</span>
                            <span *ngIf="lstFromDetails?.formTypeId==2"> - Checklist</span>
                               <div class="intake-value-dis">
                                / <span *ngIf="lstFromDetails?.isGlobal==1">Global</span> 
                                <span  *ngIf="lstFromDetails?.isGlobal==0" title="{{lstFromDetails?.locationName}}" >{{lstFromDetails?.locationName}}</span>
                               </div>
                        </div>
                    </div>
                    </div>
                </div>


            </div>
        </div>




    </div>
    <!-- *ngIf="isShowAddQuestion" -->
    <div class="col-md-12 p-0 created-form-section" *ngIf="isAddQuestion">
        <div>
            <div class="col-md-12 mt-2">
                <div class="row">
                    <div class="col-md-12 p-0 bg-white mb-2">
                        <div class="box-lead-head p-3">Add New Question </div>
                        
                <!-- <div class="col-md-12 p-0 bg-white mb-2">
                    <mat-form-field class="w-100" *ngIf="configureForm.formTypeId == checkList ">
                       <mat-label> Enter Question Direction</mat-label>
                        <input matInput placeholder="Direction"
                            [(ngModel)]="questionDirection"
                            maxlength="50" 
                            name="questionDirection" />
                    </mat-form-field>
            </div> -->
                        <!--Yes/No Question type bulkconfigform.form.valid  && -->
                        <div *ngIf="configureForm.formTypeId == 2|| configureForm.formTypeId==4&& !isEditConfigureForm">
                            <form (ngSubmit)="postBulkQuestionForm(bulkconfigform)"
                                id="bulkconfigform" autocomplete="off" #bulkconfigform="ngForm" novalidate>
                                <div class="col-md-12">
                                    <div class="col-md-12 set-quesion">
                                        <div class="row">
                                            <mat-label class="text-left mt-3 pt-1">Number of Questions?
                                            </mat-label>
                                            <span class="pl-3 text-left">
                                                <mat-form-field>
                                                    <mat-select placeholder="Select the option" name="questionlen1"
                                                        [(ngModel)]="questionlen" #questionlen1="ngModel"
                                                        (selectionChange)="onbulkQuestionload($event.value)" required>
                                                        <mat-option [value]="5">
                                                            5
                                                        </mat-option>
                                                        <mat-option [value]="10">
                                                            10
                                                        </mat-option>
                                                        <mat-option [value]="15">
                                                            15
                                                        </mat-option>
                                                        <mat-option [value]="20">
                                                            20
                                                        </mat-option>
                                                    </mat-select>

                                                </mat-form-field>
                                                <mat-error *ngIf="bulkconfigform.submitted && questionlen1.invalid">
                                                    <mat-error class="mat-form-field-subscript-wrapper"
                                                        *ngIf="questionlen1.errors.required">Please select the number of
                                                        question
                                                    </mat-error>
                                                </mat-error>
                                            </span>

                                        </div>


                                    </div>
                                    <div class="list-question thin-scroll">
                                        <div class="row">
                                            <div class="col-md-12" *ngFor="let item of bulkQuestion;let i = index">

                                                <div class="row">

                                                    <div class="col-md-4 position-relative">
                                                        <span
                                                            class="mt-3 pt-1 position-absolute number-questionlist">{{i+1}}.</span>
                                                        <mat-form-field class="d-inline-block w-100 pl-4">
                                                            <mat-label>Enter your question</mat-label>
                                                            <input matInput placeholder="Enter your question"
                                                                [(ngModel)]="item.question" #question="ngModel"
                                                                name="question{{i}}" [ngModelOptions]="{ standalone : true }"
                                                                [ngClass]="{ 'is-invalid': bulkconfigform.submitted && question.invalid }"
                                                                required />
                                                            <mat-error *ngIf="bulkconfigform.submitted">
                                                                <mat-error *ngIf="question.errors?.required">Question is
                                                                    required
                                                                </mat-error>
                                                            </mat-error>
                                                            <a *ngIf="i >= 2"
                                                                class="delete-node d-inline-block cursor-pointer "
                                                                (click)="ondeletebulkOption(i)"><i
                                                                    class="fas fa-minus-circle"></i></a>

                                                        </mat-form-field>
                                                    </div>
                                                    <div class=" col-md-5 ">
                                                        <div class="row  mt-3">
                                                            <label class="additional col-md-12 ">Explanation required from user?</label>
                                                            <div class="col-md-12 custom-radio">
                                                                <mat-radio-group aria-label="Select an option"
                                                                    [(ngModel)]="item.iSquestionExplanation"
                                                                    #iSquestionExplanation="ngModel" [ngModelOptions]="{ standalone : true }"
                                                                    name="iSquestionExplanation{{i}}" required>
                                                                    <mat-radio-button class="mr-2" [value]="1"> Yes, if the answer is yes 
                                                                    </mat-radio-button>
                                                                    <mat-radio-button class="mr-2" [value]="2"> 
                                                                    Yes, if the answer is no 
                                                                </mat-radio-button>
                                                                    <mat-radio-button class="mr-2" [value]="3">No
                                                                    </mat-radio-button>
                                                                </mat-radio-group>
                                                            </div>
                                                            <mat-error class="w-100 position-relative mt-2"
                                                                *ngIf="bulkconfigform.submitted && iSquestionExplanation.invalid">
                                                                <mat-error class="mat-form-field-subscript-wrapper ml-3"
                                                                    *ngIf="iSquestionExplanation.errors.required">
                                                                    Explanation is
                                                                    required</mat-error>
                                                            </mat-error>
                                                        </div>

                                                    </div>
                                                    <div class="col-md-3 mt-3" *ngIf="configureForm.formTypeId == 4">
                                                        <label class="additional col-md-12 p-0"> Question Type?</label>
                                                        <div class="custom-radio">
                                                        <mat-radio-group aria-label="Select an option" [(ngModel)]="item.questionType" #questionType="ngModel" name="questionType{{i}}">
                                                            <mat-radio-button [value]="1" class="mr-2">Normal</mat-radio-button>
                                                            <mat-radio-button [value]="2" class="mr-2">Medication</mat-radio-button>
                                                            <mat-radio-button [value]="3" class="">Vitamins</mat-radio-button>
                                                        </mat-radio-group>
                                                    </div>
                                                    </div>
                                                    <div class="col-md-4 pl-4" *ngIf="item.iSquestionExplanation == 1 || item.iSquestionExplanation == 2">
                                                        <mat-form-field class="w-100 pl-2">
                                                            <mat-label> What do you want the user to explain?</mat-label>
                                                            <input matInput placeholder="Hint"
                                                                [(ngModel)]="item.questionExplanation"
                                                                #questionExplanation="ngModel"
                                                                name="questionExplanation{{i}}" [ngModelOptions]="{ standalone : true }"
                                                                [ngClass]="{ 'is-invalid': bulkconfigform.submitted && questionExplanation.invalid }"
                                                                [required]="item.iSquestionExplanation == 1" />
                                                            <mat-error *ngIf="bulkconfigform.submitted">
                                                                <mat-error *ngIf="questionExplanation.errors?.required">
                                                                    Explanation is
                                                                    required
                                                                </mat-error>
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <!--<div class=" col-md-4 ">
                                                        <div class="row  mt-4">
                                                            <label class="additional col-md-8 ">Explanation required
                                                                from
                                                                user?</label>

                                                            <div class="col-md-4 p-0">

                                                                <mat-radio-group aria-label="Select an option"
                                                                    [(ngModel)]="item.iSquestionExplanation"
                                                                    #iSquestionExplanation="ngModel" [ngModelOptions]="{ standalone : true }"
                                                                    name="iSquestionExplanation{{i}}" required>
                                                                    <mat-radio-button class="mr-1" [value]="1">Yes
                                                                    </mat-radio-button>

                                                                    <mat-radio-button class="mr-2" [value]="3">No
                                                                    </mat-radio-button>


                                                                </mat-radio-group>

                                                            </div>
                                                            <mat-error class="w-100 position-relative mt-2"
                                                                *ngIf="bulkconfigform.submitted && iSquestionExplanation.invalid">
                                                                <mat-error class="mat-form-field-subscript-wrapper ml-3"
                                                                    *ngIf="iSquestionExplanation.errors.required">
                                                                    Explanation is
                                                                    required</mat-error>
                                                            </mat-error>
                                                        </div>

                                                    </div>
                                                    <div class="col-md-4">
                                                        <mat-form-field class="w-100">
                                                            <mat-label>Hint</mat-label>
                                                            <input matInput placeholder="Hint"
                                                                [(ngModel)]="item.questionExplanation"
                                                                #questionExplanation="ngModel"
                                                                name="questionExplanation{{i}}" [ngModelOptions]="{ standalone : true }"
                                                                [ngClass]="{ 'is-invalid': bulkconfigform.submitted && questionExplanation.invalid }"
                                                                [required]="item.iSquestionExplanation == 1" />
                                                            <mat-error *ngIf="bulkconfigform.submitted">
                                                                <mat-error *ngIf="questionExplanation.errors?.required">
                                                                    Explanation is
                                                                    required
                                                                </mat-error>
                                                            </mat-error>
                                                        </mat-form-field>
                                                    </div>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right col-md-12 pb-4"  *ngIf="roleAccess.isEdit ||  roleAccess.isCreate">

                                    <button type="submit" form="bulkconfigform" title="Save"
                                        class="btn primary-btn-filled mr-2" mat-button><i
                                            class="far fa-save pr-2"></i>{{isEditConfigureForm == 1 ?
                                        'Update' : 'Save'}}</button>
                                    <button type="button" class="btn btn-cancel mr-2" title="Cancel"
                                        (click)="onCloseAddQuestion()" mat-button>Cancel</button>
                                    <!--<button class="btn primary-btn-outlined mr-2" mat-button >Add New Question</button> -->

                                </div>
                            </form>
                        </div>
                        </div>
                        <!--Other Question type -->
                        <div *ngIf="configureForm.formTypeId != 2 && configureForm.formTypeId != 4 || isEditConfigureForm" class="col-md-12">
                            <form
                                (ngSubmit)="configFormDetailInfo.form.valid  && postConfigFormDetails(configFormDetailInfo)"
                                id="configFormDetailInfo" autocomplete="off" #configFormDetailInfo="ngForm" novalidate>
                                <div class="configure-right-section active">
                                    <div class="col-md-12 p-0">
                                        <div class="media ">
                                            <div class="media-left">
                                                <div class="question-number">Q{{ !isEditConfigureForm ? lstFromDetails?.details?.length + 1 : questionIndex + 1 }}
                                                </div>
                                            </div>
                                            
                                            <div class="media-body">
                                                <div class="col-md-12 p-0">
                                                    <div class="row">
                                                        <div class="form-group position-relative col-md-12">
                                                           
                                                            <label class="question-label">Enter Question Description</label>
                                                            <div class="input-group">
                                                                <quill-editor #tempEditor 
                                                                            style="height: 100%;width:75%" (onEditorCreated)="onEditorCreated($event)" [preserveWhitespace]="true"
                                                                            [(ngModel)]="configureFormDetail.question" placeholder="Enter your question"
                                                                            #question="ngModel" name="question" required [modules]="quillConfig">
                                                                </quill-editor>
        
                                                                <!-- <textarea appTextareaAutoresize maxlength="300"
                                                                    [(ngModel)]="configureFormDetail.question"
                                                                    #question="ngModel" name="question"
                                                                    class="form-control question-text overflow-hidden"
                                                                    placeholder="Enter your question" name="text"
                                                                    required></textarea> -->
        
        
                                                                <div class="input-group-btn bs-dropdown-to-select-group mt-auto"
                                                                    *ngIf="configureForm.formTypeId != 2">
                                                                    <select class="form-control quest-selct"
                                                                        [disabled]="configureForm.formTypeId == 2"
                                                                        (change)="onQuestionType($event,configureFormDetail.typeId)"
                                                                        [(ngModel)]="configureFormDetail.typeId"
                                                                        #typeId="ngModel" name="typeId" required
                                                                        aria-label="Default select example">
                                                                        <option *ngIf="configureForm.formTypeId != 2"
                                                                            [value]=null>
                                                                            Select Question Type</option>
                                                                        <option *ngFor="let typ of ddlQuestionTypes"
                                                                            [value]="typ.questionTypeId">
        
                                                                            {{typ.questionTypeName}}
                                                                        </option>
                                                                    </select>
                                                                    <mat-error
                                                                        class=" mt-3 mb-1 pt-1  mat-form-field-subscript-wrapper "
                                                                        *ngIf="configFormDetailInfo.submitted && typeId.invalid">
                                                                        <mat-error *ngIf="typeId.errors.required">Question Type
                                                                            is
                                                                            required
                                                                        </mat-error>
                                                                    </mat-error>
                                                                </div>
                                                                
                                                            
                                                            </div>
                                                            <mat-error
                                                                class="mt-1 mb-1 two-error mat-form-field-subscript-wrapper"
                                                                *ngIf="configFormDetailInfo.submitted && question.invalid">
                                                                <mat-error *ngIf="question.errors.required">Question is
                                                                    required
                                                                </mat-error>
                                                            </mat-error>
                                                        </div>
                                           
                                                    </div>
                                                </div>
                                                <div class="col-md-12 p-0" *ngIf="configureForm.formTypeId == 3 && (configureFormDetail.typeId == queMCSA || configureFormDetail.typeId == queMCMA || configureFormDetail.typeId == queYesNo)">
                                                    <div  class="float-left uploaded -file mr-1 mb-2 text-danger"
                                              *ngFor="let a of QuestionImages;let index = index;">
                                              <img style="width: 40px; height: 40px;" class="img-thumbnail zoom" src="{{a.filePath}}" />
                                            </div>
                                            </div>
                                                
                                            </div>
                                        </div>
                                        <div 
                                        *ngIf="configureForm.formTypeId != 2">
                                       
                                        <!-- <ng-select autocomplete="off" [items]="ddlInstructionType" maxlength="50"
                                        placeholder="Instructions" appAlphabetOnly bindLabel="reasonTypeName"
                                        bindValue="reasonTypeName" #blockReasonTypeId="ngModel" name="intructionTypeId"
                                        [(ngModel)]="configureFormDetail.intructionTypeName" addTagText="Add item" [addTag]="AddNewItemTypeIdTag"
                                         required> -->
                                         <ng-select autocomplete="off" class="block-type-design" 
                                            [items]="ddlInstructionType" placeholder="Instruction" appAlphabetOnly bindLabel="instructionName"
                                            bindValue="instructionName" 
                                            [(ngModel)]="configureFormDetail.instructionName" addTagText="Add instruction"
                                            [addTag]="AddNewItemTypeIdTag" (change)="postInstruction(configureFormDetail.instructionName)"
                                            #instructionName="ngModel" name="instructionName"
                                            >
                                        </ng-select>
                                    <!-- </ng-select> -->
                                    <mat-error
                                    class=" mt-3 mb-1 pt-1  mat-form-field-subscript-wrapper "
                                    *ngIf="configFormDetailInfo.submitted && instructionName.invalid">
                                    <mat-error *ngIf="instructionName.errors.required">Instruction
                                        is
                                        required
                                    </mat-error>
                                </mat-error>
                                    </div>

                                    </div>
                                  
                                    <div class="col-md-12 mt-2">
                                        <div class="row">
                                    
                                    <div class="col-md-1 pl-4 pt-1 " *ngIf="configureForm.formTypeId == 3 && (configureFormDetail.typeId == '1' || configureFormDetail.typeId == '2' ||  configureFormDetail.typeId == '3')"
                                   >
                                        <button  type="button"  (click)="openUploadImage(1,null)"
                                                class="btn file-add cursor-pointer primary-btn-outlined h-30 ml-3">Upload</button>

                                    </div>
                                    <div class="col-md-11" *ngIf="configureForm.formTypeId != 2">
                                        <div class="value-question mqsa col-md-12 pl-4 ml-2 mb-2"
                                            *ngIf=" configureFormDetail.typeId == queYesNo || configureFormDetail.typeId == queMCSA">
                                            <div class="all-radios">
                                                <div *ngIf="configureFormDetail.typeId == queYesNo">
                                                    <div class="custom-radio-text"
                                                        *ngFor="let typ1 of dtoOption;let i = index">
                                                        <ng-container *ngIf="i==0">
                                                            <input class="rd" type="radio" name="ind1"
                                                                (change)="onOptionChecked($event,i)"
                                                                [checked]="typ1.isAnswer" [value]="typ1.optionChar" />
    
                                                            <input type="text" class="tb" name="tb1"
                                                                disabled [value]="typ1.optionLabel"
                                                                [(ngModel)]="typ1.optionLabel" #optionLabel="ngModel"
                                                                maxlength="100" placeholder="Option {{i + 1}}" required />
                                                                <span class="empty-upload" (click)="openUploadImage(2,dtoOption[i])" *ngIf="(dtoOption[i].documentLocation == '' || dtoOption[i].documentLocation == null) && configureForm.formTypeId == 3 "><i class="fas fa-upload"></i></span>
                                                            <img style="width: 20px; height: 20px;" (click)="openUploadImage(2,dtoOption[i]) " *ngIf="dtoOption[i].documentLocation != '' && dtoOption[i].documentLocation != null" src="{{dtoOption[i].documentLocation}}" />
                                                        <a class="delete-node" *ngIf="i >= 2"
                                                            (click)="ondeleteOption(i,configureFormDetail.typeId)"><i
                                                                class="fas fa-minus-circle"></i></a>
                                                        </ng-container>
                                                        <ng-container *ngIf="i==1">
                                                            <input class="rd" type="radio" name="ind1"
                                                                (change)="onOptionChecked($event,i)"
                                                                [checked]="typ1.isAnswer" [value]="typ1.optionChar" />
    
                                                            <input type="text" class="tb" name="tb2"
                                                                disabled [value]="typ1.optionLabel"
                                                                [(ngModel)]="typ1.optionLabel" #optionLabel="ngModel"
                                                                maxlength="100" placeholder="Option {{i + 1}}" required />
                                                                <span class="empty-upload" (click)="openUploadImage(2,dtoOption[i])" *ngIf="(dtoOption[i].documentLocation == '' || dtoOption[i].documentLocation == null) && configureForm.formTypeId == 3 "><i class="fas fa-upload"></i></span>
                                                            <img style="width: 20px; height: 20px;" (click)="openUploadImage(2,dtoOption[i]) " *ngIf="dtoOption[i].documentLocation != '' && dtoOption[i].documentLocation != null" src="{{dtoOption[i].documentLocation}}" />
                                                        <a class="delete-node" *ngIf="i >= 2"
                                                            (click)="ondeleteOption(i,configureFormDetail.typeId)"><i
                                                                class="fas fa-minus-circle"></i></a>
                                                        </ng-container>
                                                        <!-- <input type="text" appAlphabetOnly class="tb" name="tb1"
                                                        [(ngModel)]="typ1.optionLabel" #optionLabel="ngModel" maxlength="30"
                                                        placeholder="Option {{i + 1}}" required /> -->
                                                        <!-- <mat-error class="mat-form-field-subscript-wrapper"
                                                        *ngIf="configFormDetailInfo.submitted && ind1.invalid">
                                                        <mat-error *ngIf="optionLabel.errors.required">Option Label is
                                                            required
                                                        </mat-error>
                                                    </mat-error> -->
                                                    </div>
                                                </div>
                                                <div *ngIf="configureFormDetail.typeId == queMCSA">
    
                                                    <div class="custom-radio-text"
                                                        *ngFor="let typ1 of dtoOption;let i = index;let last = last;">
                                                        <input class="rd" type="radio" name="ind2" [value]="typ1.optionChar"
                                                            [checked]="typ1.isAnswer" 
                                                            (change)="onOptionChecked($event,i)" />
                                                        <input type="text" class="tb" name="tb1{{i}}"
                                                            maxlength="100"  
                                                            [(ngModel)]="typ1.optionLabel" #optionLabel="ngModel"
                                                            placeholder="Option {{i + 1}}" required />
                                                            <!-- <span (click)="openUploadImage(2,dtoOption[i])" *ngIf="dtoOption[i].documentLocation == '' && configureForm.formTypeId == 3 ">{{dtoOption[i].documentName}}</span> -->
                                                            <span class="empty-upload" (click)="openUploadImage(2,dtoOption[i])" *ngIf="(dtoOption[i].documentLocation == '' || dtoOption[i].documentLocation == null) && configureForm.formTypeId == 3 "><i class="fas fa-upload"></i></span>
                                                            <img style="width: 20px; height: 20px;" (click)="openUploadImage(2,dtoOption[i]) " *ngIf="dtoOption[i].documentLocation != '' && dtoOption[i].documentLocation != null" src="{{dtoOption[i].documentLocation}}" />
                                                        <a class="delete-node" *ngIf="i >= 2"
                                                            (click)="ondeleteOption(i,configureFormDetail.typeId)"><i
                                                                class="fas fa-minus-circle"></i></a>
    
                                                        <mat-error class="mt-3 mb-1 pt-1 mat-form-field-subscript-wrapper"
                                                            *ngIf="configFormDetailInfo.submitted && optionLabel.invalid">
                                                            <mat-error *ngIf="optionLabel.errors.required">Option {{i+1}} Label
                                                                is
                                                                required
                                                            </mat-error>
                                                        </mat-error>
                                                    </div>
                                                    <a class="add-btn-plus d-inline-block"
                                                        (click)="addMoreOption(queMCSA)"><span><i
                                                                class="fas fa-plus-circle mr-2"></i></span></a>
                                                </div>
                                                <!-- <div *ngIf="configureFormDetail.typeId == queMCMA">
    
                                                <div class="custom-radio-text"
                                                    *ngFor="let typ1 of dtoOption;let i = index;let last = last;">
                                                    <input class="rd" type="radio" name="ind2" [value]="typ1.optionChar" [checked]="typ1.isAnswer"
                                                        (change)="onOptionChecked($event,i)" />
                                                    <input type="text" appAlphabetOnly class="tb" name="tb1"
                                                        [ngModelOptions]="{ standalone : true }" maxlength="30"
                                                        [(ngModel)]="typ1.optionLabel" #optionLabel="ngModel"
                                                        placeholder="Option {{i + 1}}" required />
    
                                                    <a class="delete-node" *ngIf="i >= 2"
                                                        (click)="ondeleteOption(i,configureFormDetail.typeId)"><i
                                                            class="fas fa-minus-circle"></i></a>
    
                                                    <mat-error class="mat-form-field-subscript-wrapper"
                                                        *ngIf="configFormDetailInfo.submitted && optionLabel.invalid">
                                                        <mat-error *ngIf="optionLabel.errors.required">Option Label is
                                                            required
                                                        </mat-error>
                                                    </mat-error>
                                                </div>
                                                <a class="add-btn-plus d-inline-block"
                                                    (click)="addMoreOption(queMCSA)"><span><i
                                                            class="fas fa-plus-circle mr-2"></i></span></a>
                                            </div> -->
                                            </div>
                                        </div>
                                        <div class="value-question mqsa col-md-12 pl-4 ml-3 mb-2"
                                            *ngIf=" configureFormDetail.typeId == queMCMA">
                                            <div class="all-checks">
                                                <div class="custom-radio-text"
                                                    *ngFor="let typ1 of dtoOption;let i = index;let last = last;">
                                                    <input class="rd" type="checkbox" name="ind3{{i}}"
                                                        [value]="typ1.optionChar" [checked]="typ1.isAnswer"
                                                        (change)="onOptionMCMAChecked($event,i)" />
                                                    <input type="text" class="tb" name="tb1{{i}}"
                                                       
                                                        [(ngModel)]="typ1.optionLabel" #optionLabel="ngModel"
                                                        placeholder="Option {{i + 1}}" required />
                                                        <span class="empty-upload" (click)="openUploadImage(2,dtoOption[i])" *ngIf="(dtoOption[i].documentLocation == '' || dtoOption[i].documentLocation == null) && configureForm.formTypeId == 3 "><i class="fas fa-upload"></i></span>
                                                        <img style="width: 20px; height: 20px;" (click)="openUploadImage(2,dtoOption[i]) " *ngIf="dtoOption[i].documentLocation != '' && dtoOption[i].documentLocation != null" src="{{dtoOption[i].documentLocation}}" />
                                                    <a class="delete-node" *ngIf="i >= 2"
                                                        (click)="ondeleteOption(i,configureFormDetail.typeId)"><i
                                                            class="fas fa-minus-circle"></i></a>
    
                                                    <mat-error class="mt-3 mb-1 pt-1 mat-form-field-subscript-wrapper"
                                                        *ngIf="configFormDetailInfo.submitted && optionLabel.invalid">
                                                        <mat-error *ngIf="optionLabel.errors.required">Option {{i+1}} Label is
                                                            required
                                                        </mat-error>
                                                    </mat-error>
                                                </div>
                                                <a class="add-btn-plus d-inline-block"
                                                    (click)="addMoreOption(queMCSA)"><span><i
                                                            class="fas fa-plus-circle mr-2"></i></span></a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                                    <div class="col-md-12 pr-0 mt-2 mb-2">
                                        <div class="text-right">
                                            <span *ngIf="isChecked">
                                                <button type="button" class="btn btn-info btn-sm mr-2" (click)="resetnotesform()"><i class="fab fa-rev mr-2"></i>Reset  Options</button>                                            
                                            </span>
                                            <span *ngIf="isImageChecked">
                                                <button type="button" class="btn btn-info btn-sm" (click)="resetImageform()"><i class="fab fa-rev mr-2"></i>Reset Images</button>
                                            </span>
                                            
                                        </div>
                                    </div>
                                    <div class=" col-md-12 pl-4 ml-2"
                                            *ngIf=" configureFormDetail.typeId == queMCSA || configureFormDetail.typeId == queMCMA">
                                            <div class=" row">
                                                <div class=" col-md-4 ">
                                                    <div class="key"
                                                        *ngIf="configureFormDetail.typeId == queMCSA">
                                                        <label>Preferred
                                                            Answer:</label><span> {{dtoOption[isAnswerindex]?.optionLabel}}</span>
                                                            <mat-error class="mt-3 mb-1 pt-1 mat-form-field-subscript-wrapper"
                                                            *ngIf="configFormDetailInfo.submitted && NoprefferedAnswerError">
                                                            Preffered answer is required
                                                        </mat-error>
                                                    </div>
                                                
                                                    <div class="key" *ngIf=" configureFormDetail.typeId == queMCMA">
                                                        <label>Preferred Answer:</label>
                                                        <ng-container *ngFor="let a of preferredAnswerArray;let idx=index;let last=last;let first=first">
                                                            <span *ngIf="a.isAnswer">  {{preferredAnswerArray[idx]?.optionLabel}}  {{((preferredAnswerArray[idx]?.optionLabel.trim() !== '' && preferredAnswerArray[idx]?.optionLabel !== null )&&( !IsCommaRequired || last ) ) ? '' : ', '}}  </span>

                                                              <!-- <span *ngIf="idx >= 1">, </span> -->
                                                        </ng-container>
                                                        
                                                    <mat-error class="mt-3 mb-1 pt-1 mat-form-field-subscript-wrapper"
                                                    *ngIf="configFormDetailInfo.submitted && NoprefferedAnswerError">
                                                    Preffered answer is required
                                                </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                   
                                    
                                    
                                    <div class=" col-md-12 pl-4 ml-2">
                                        <div class=" col-md-12">
                                            <div class=" row">
                                                <div class=" col-md-8 mt-3"
                                                    *ngIf='configureFormDetail.typeId != queDescriptive && configureFormDetail.typeId != queText'>
                                                    <div class="row">
                                                        <label class="additional col-md-4 p-0">Explanation required from user?</label>
                                                        <div class="col-md-8 p-0">
                                                            <mat-radio-group aria-label="Select an option"
                                                                [(ngModel)]="configureFormDetail.iSquestionExplanation"
                                                                #iSquestionExplanation="ngModel"
                                                                name="iSquestionExplanation" required>
                                                                <mat-radio-button *ngIf='configureFormDetail.typeId != queRatings && configureFormDetail.typeId != queYesNo '
                                                                 class="mr-1" [value]="1">Yes, at question level
                                                                </mat-radio-button>
                                                                <mat-radio-button
                                                                    *ngIf='configureFormDetail.typeId != queRatings && configureFormDetail.typeId != queYesNo  '
                                                                    class="mr-1" [value]="2"> Yes, at certain answers
                                                                </mat-radio-button>
                                                               <mat-radio-button
                                                                *ngIf='configureFormDetail.typeId != queRatings && configureFormDetail.typeId == queYesNo '
                                                                class="mr-1" [value]="1"> Yes, if the answer is yes
                                                            </mat-radio-button>
                                                            <mat-radio-button
                                                            *ngIf='configureFormDetail.typeId != queRatings && configureFormDetail.typeId == queYesNo '
                                                            class="mr-1" [value]="2"> Yes, if the answer is no
                                                        </mat-radio-button>
                                                                <mat-radio-button class="mr-2" [value]="3">No
                                                                </mat-radio-button>                        
                                                            </mat-radio-group>
            
                                                        </div>
                                                        <mat-error class="w-100 position-relative mt-2"
                                                            *ngIf="configFormDetailInfo.submitted && iSquestionExplanation.invalid">
                                                            <mat-error class="mat-form-field-subscript-wrapper"
                                                                *ngIf="iSquestionExplanation.errors.required">
                                                                Explanation is
                                                                required</mat-error>
                                                        </mat-error>
                                                    </div>
            
                                                </div>
            
            
                                                <div class="col-md-4"
                                                    *ngIf="configureFormDetail.iSquestionExplanation == 1 || configureFormDetail.iSquestionExplanation == 2">
                                                    <mat-form-field class="w-100">
                                                        <mat-label> What do you want the user to explain?</mat-label>
                                                        <input matInput placeholder="Hint"
                                                            [(ngModel)]="configureFormDetail.questionExplanation"
                                                            maxlength="300" #questionExplanation="ngModel"
                                                            name="questionExplanation"
                                                            [ngClass]="{ 'is-invalid': configureFormDetail.submitted && questionExplanation.invalid }"
                                                            [required]="configureFormDetail.iSquestionExplanation == 1 || configureFormDetail.iSquestionExplanation == 2" />
                                                        <mat-error *ngIf="configureFormDetail.submitted">
                                                            <mat-error *ngIf="questionExplanation.errors?.required">
                                                                Explanation is
                                                                required
                                                            </mat-error>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-md-4"
                                                *ngIf="configureFormDetail.typeId == queText">
                                                <mat-form-field class="w-100">
                                                    <mat-label> Add answer hint</mat-label>
                                                    <input matInput placeholder="Hint"
                                                        [(ngModel)]="configureFormDetail.answerHint"
                                                        maxlength="300" #answerHint="ngModel"
                                                        name="answerHint"/>
                                                    <!-- <mat-error *ngIf="configureFormDetail.submitted">
                                                        <mat-error *ngIf="questionExplanation.errors?.required">
                                                            Explanation is
                                                            required
                                                        </mat-error>
                                                    </mat-error> -->
                                                </mat-form-field>
                                            </div>
                                                <div class="text-right col-md-12"  *ngIf="roleAccess.isEdit ||  roleAccess.isCreate">
                                                    <!-- <button *ngIf='configureForm.formTypeId == 2' type="button"
                                                            class="btn primary-btn-outlined  mr-2" title="Add Bulk Question"
                                                            (click)="onBulkAddQuestion()" mat-button><i
                                                                class="fas fa-plus-circle mr-2"></i>Add Bulk
                                                            Question</button> -->
                                                    <button type="submit" form="configFormDetailInfo" title="Save"
                                                        class="btn primary-btn-filled mr-2" mat-button><i
                                                            class="far fa-save pr-2"></i>{{isEditConfigureForm == 1 ?
                                                        'Update' : 'Save'}}</button>
                                                    <button type="button" class="btn btn-cancel mr-2" title="Cancel"
                                                        (click)="onCloseAddQuestion()" mat-button>Cancel</button>
                                                    <!--<button class="btn primary-btn-outlined mr-2" mat-button >Add New Question</button> -->
            
            
                                                </div>
                                            </div>
                                        </div>
            
            
                                    </div>
                                </div>
                             
                            </form>
                        </div>
                     
                    </div>
                </div>
            </div>

        </div>



    </div>
    <div class="preview-questions-checklist"
        *ngIf="lstFromDetails?.details?.length > 0 && pageid == 2 &&(lstFromDetails?.formTypeId==2 || lstFromDetails?.formTypeId==4)">
        <div class="lead-box pt-2 pb-2">
            <div class="col-md-12 mt-3">
                <div class="question-list preview-head-check ">Preview</div>
                <div class="col-md-12  border disabled">
                    <div class="top-btn-section clearfix mb-3">
                        <h2 class="head-section">{{lstFromDetails?.formName}}</h2>
                        <div class="w-25 ml-auto">
                            <mat-form-field class="w-100">
                                <mat-label>
                                    Date of last physical exam *
                                </mat-label>
                                <input matInput [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-12 ">
                        <p class="m-0"><span class="font-weight-bold mr-2 ">Directions:</span>{{lstFromDetails.questionDirection}}</p>
                    </div>
                    <div class="col-md-12 ">
                       
                        <div class="row form-group" *ngFor="let item of lstFromDetails?.details;let in=index">
                            <div class="col mb-1">
                                <span class="yesno-label question"><span>Q{{in+1}}.</span>
                                <span class="ql-container ql-snow" style="border-width: 0;">
                                    <span class="ql-editor" [innerHTML]="item.question">
                                    </span>
                                </span></span>
                            </div>
                            <!-- <div class="col-md-1 p-0 col-sm-6 mb-1">
                                <label class="switch">
                                    <input class="switch-input" type="checkbox" />
                                    <span class="switch-label" data-on="Yes" data-off="No"></span>
                                    <span class="switch-handle"></span>
                                </label>

                            </div> -->
                            <div class="col-md-2  col-sm-6 text-center">
                                <!-- <label class="switch">
                                    <input class="switch-input" type="checkbox" [(ngModel)]="item.isAnswer" [ngModelOptions]="{standalone: true}"
                                    #isAnswer="ngModel" name="isAnswer" />
                                    <span class="switch-label" data-on="Yes" data-off="No"></span> 
                                    <span class="switch-handle"></span> 
                                </label> -->

                                <mat-radio-group disabled="false">
                                    <mat-radio-button [value]="true" class="mr-3 my-3">Yes
                                    </mat-radio-button>
                                    <mat-radio-button [value]="false">No
                                    </mat-radio-button>
                                </mat-radio-group>
                            
                            </div>
                            <div class="col-md-5">
                                <div *ngIf="item.iSquestionExplanation != 3 " >
                                    <span class="hint-label" *ngIf="item.questionExplanation != ''" style="color: black;" >{{item.questionExplanation}}</span>
                                    <textarea row="3" placeholder="Enter your Comments" class="w-100" 
                                    name="explanation"></textarea>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="preview-questions-checklist"
        *ngIf="lstFromDetails?.details?.length > 0 && pageid == 2 && lstFromDetails?.formTypeId==1">
        <div class="lead-box pt-2 pb-2">
            <div class="col-md-12 mt-3">
                <div class="question-list preview-head-check ">Preview</div>
                <div class="col-md-12  border">
                    <div class="col-md-12 ">
                        <p class="m-0"><span class="font-weight-bold mr-2 ">Directions:</span>{{lstFromDetails.questionDirection}}</p>
                    </div>
                    <div class="top-btn-section clearfix mb-3 mt-3">
                        <h2 class="head-section mb-3 mt-0">Survey</h2>
                        <div class="qustion-pagination">
                            <span class="question-label mr-2"># of Questions</span>
                            <div class="page-item page-arrow" (click)="scrollLeft()"><a class="page-link_new"><i
                                        class="fas fa-angle-left"></i>
                                    <div class="ripple-container"></div>
                                </a>
                            </div>
                            <div class="pagination-custom" #divQuestionPG>
                                <ul class="pagination pagination-info">
                                    <ng-container
                                        *ngFor="let item of lstFromDetails?.details ;let i=index;let first=first;let last=last;">
                                        <li class="page-item" [ngClass]="{'active': surveyactivepage === i}"
                                            (click)="onClickPage(i)"><a class="page-link">{{i+1}}</a></li>
                                    </ng-container>


                                </ul>
                            </div>
                            <div class=" page-item page-arrow" (click)="scrollRight()"><a class="page-link_new"><i
                                        class="fas fa-angle-right"></i>
                                    <div class="ripple-container"></div>
                                </a>
                            </div>
                        </div>

                    </div>

                    <div class="privew">
                        <ng-container *ngFor="let item of lstFromDetails?.details;let i=index">
                            <div class="questionsBox" *ngIf="i==surveyactivepage">
                                <div class="questions">
                                    <div class="ql-container ql-snow" style="border-width: 0;">
                                        <div class="ql-editor" [innerHTML]="item.question">
                                        </div>
                                    </div></div>
                                <ul class="answerList">
                                    <li class="" *ngIf="item.typeId == queYesNo">
                                        <div class="option-withtxt" *ngFor="let opt of item.options">
                                            <label>
                                                <input type="radio" disabled="disabled" name="flexRadioDefault"
                                                    id="flexRadioDefault4">

                                                {{opt.optionLabel}}

                                            </label>
                                          <!--  <div class="option-text" *ngIf="item.iSquestionExplanation == 2">

                                                <textarea name="" id="" rows="4" maxlength="300" disabled
                                                    placeholder="Enter Your Answer" class="w-100"></textarea>
                                            </div>-->
                                        </div>

                                    </li>
                                    <li class="" *ngIf="item.typeId == queMCSA">
                                        <div class="option-withtxt" *ngFor="let opt of item.options">
                                            <label>
                                                <input type="radio" disabled="disabled" name="flexRadioDefault"
                                                    id="flexRadioDefault4">

                                                {{opt.optionLabel}}
                                            </label>

                                         <!--  <div class="option-text" *ngIf="item.iSquestionExplanation == 2">

                                                <textarea name="" id="" rows="4" maxlength="300" disabled
                                                    placeholder="Enter Your Answer" class="w-100"></textarea>
                                            </div> -->
                                        </div>

                                    </li>
                                    <li class="" *ngIf="item.typeId == queMCMA">
                                        <div class="option-withtxt" *ngFor="let opt of item.options">
                                            <label>
                                                <input type="checkbox" id="inlineCheckbox3" value="option3" disabled>
                                                {{opt.optionLabel}}</label>

                                           <!--   <div class="option-text" *ngIf="item.iSquestionExplanation == 2">

                                                <textarea name="" id="" rows="4" maxlength="300" disabled
                                                    placeholder="Enter Your Answer" class="w-100"></textarea>
                                            </div>-->
                                        </div>

                                    </li>
                                    <li class="mt-2 mb-2" *ngIf="item.typeId == queRatings">
                                        <div class="rating-get mt-3 mb-3">
                                            <a title="poor" class="rate-numb"><span>1</span></a>
                                            <a class="rate-numb"><span>2</span></a>
                                            <a class="rate-numb"><span>3</span></a>
                                            <a class="rate-numb"><span>4</span></a>
                                            <a title="Excellent" class="rate-numb"><span>5</span></a>
                                        </div>
                                    </li>
                                    <li class="comment-section pl-2 mt-auto 1" *ngIf="item.typeId ==queDescriptive">
                                        <div class="comment-txt">
                                            Enter your answer
                                        </div>
                                    </li>

                                    <li class="comment-section pl-2 mt-auto 2"
                                        *ngIf="item.iSquestionExplanation !=3 && item.typeId !=queDescriptive">
                                        <span class="hint-label" *ngIf="item.questionExplanation != ''" style="color: black;" >{{item.questionExplanation}}</span>
                                        <div class="comment-txt">
                                            Enter your answer
                                        </div>
                                    </li>
                                </ul>
                                <div class="questionsRow">
                                    <span>{{surveyactivepage+1}} of {{lstFromDetails?.details.length}}</span>


                                    <div class="d-inline-block ml-auto">
                                        <button type="button" class="greyButton" (click)="onPrevSurvey()" *ngIf="i != 0"
                                            id="skipquestions">Previous</button>

                                        <button type="button" class="greyButton" (click)="onNextSurvey()"
                                            *ngIf="i != (lstFromDetails?.details.length -1)"
                                            id="nextquestions">Next</button>

                                    </div>

                                </div>
                            </div>
                        </ng-container>
                    </div>



                </div>
            </div>
        </div>
    </div>
    <div class="preview-questions-checklist"
    *ngIf=" pageid == 2 && lstFromDetails?.formTypeId==3" >
    <div class="lead-box pt-2 pb-2">
        <div class="col-md-12 mt-3 disabled">
            <div class="question-list preview-head-check ">Preview</div>
            <div class="col-md-12  border">
                <div class="col-md-12 mb-4 question-list-nutri"
                *ngFor="let item of lstFromDetails?.details ;let i=index;">
                <div class="row">
                    <div class="question text-truncate w-100"><span>Q{{i+1}}.</span>
                       
                            <span class="d-inline-block pl-2" [innerHTML]="item.question">
                            </span>
                       </div>
                    <!-- <label class="nut-label col-md-12"></label> -->
                </div>

                <div class="row align-self-center text-left mt-3" *ngIf="item.typeId==queMCSA || item.typeId==queMCMA || item.typeId == queYesNo">
                    <div class="d-inline-block mr-2 " *ngFor="let opt of item?.images;let idx=index;">

                        <img class="img-thumbnail" style="width:90px;height:90px;" *ngIf="opt.fileName !=''"
                            [src]="opt.filePath">
                    </div>

                </div>
                <fieldset>

                    <div class="row">
                        <div class="question-divider ">
                            <div class="divider-text "> answer choices </div>
                        </div>

                        <ng-container *ngIf="item.typeId==queYesNo">
                            <div class="col-md-1" *ngFor="let opt of item?.options;let idx=index;">

                                <div class="img-text-active" [ngClass]="{'txt-area-active':opt.isAnswer}">
                                    <div class="custom-control custom-radio image-checkbox">
                                        <input type="radio" name="queYesNo{{idx}}{{i}}" id="queYesNo{{idx}}"
                                        [ngModelOptions]="{standalone: true}" [checked]="opt.isAnswer"
                                        (click)="rbAnswer(i,idx)" required>
                                    {{opt.optionLabel}}                                       
                                        <label for="queYesNo{{idx}}{{i}}">
                                            <div *ngIf="opt.documentName != null && opt.documentName !=''">
                                                <img *ngIf="opt.documentName != null || opt.documentName !=''" onerror="this.onerror=null; this.src='/assets/images/no-image.png'"
                                                [src]="opt.documentLocation"  class="img-fluid">  
                                            </div> 
                                            <div class="mt-3 no-image-text" *ngIf="opt.documentName == null || opt.documentName ==''">
                                                No image available
                                            </div>                                                                           
                                        </label>          
                                       
                                    </div>

                                   
                                </div>     
                                <!-- <label>
                                    <input type="radio" name="queYesNo{{idx}}{{i}}" id="queYesNo{{idx}}"
                                        [ngModelOptions]="{standalone: true}" [checked]="opt.isAnswer"
                                        (click)="rbAnswer(i,idx)" required>
                                    {{opt.optionLabel}}
                                </label> -->
                                <div class="option-text" *ngIf="item.iSquestionExplanation == 2">
                                    <span class="extra-info" *ngIf="item.questionExplanation != ''">{{item.questionExplanation}}</span>
                                    <textarea name="" id="" rows="4" maxlength="300" [(ngModel)]="opt.explanation"
                                        #explanation="ngModel" placeholder="Enter Your Answer" class="w-100"></textarea>
                                </div>


                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.typeId==queMCSA">
                            <div class="d-inline-block ml-3 mr-2 card-shadow"
                                *ngFor="let opt of item?.options;let idx=index;">
                                <div class="img-text-active" [ngClass]="{'txt-area-active':opt.isAnswer}">
                                    <div class="custom-control custom-radio image-checkbox">
                                        <input type="radio" name="queMCSA{{idx}}{{i}}" id="queMCSA{{idx}}{{i}}"
                                            [ngModelOptions]="{standalone: true}" [checked]="opt.isAnswer"
                                            (click)="rbAnswer(i,idx)" required class="custom-control-input">                                            
                                        <label class="custom-control-label" for="queMCSA{{idx}}{{i}}">
                                            <div *ngIf="opt.documentName != null && opt.documentName !=''">
                                                <img *ngIf="opt.documentName != null || opt.documentName !=''" onerror="this.onerror=null; this.src='/assets/images/no-image.png'"
                                                [src]="opt.documentLocation"  class="img-fluid">  
                                            </div> 
                                            <div class="mt-3 no-image-text" *ngIf="opt.documentName == null || opt.documentName ==''">
                                                No image available
                                            </div>                                                                           
                                        </label>          
                                        <div class="text-slide-img">{{opt.optionLabel}}</div>
                                    </div>

                                    <div class="image-textarea" *ngIf="item.iSquestionExplanation == 2">
                                        <span class="extra-info" *ngIf="item.questionExplanation != ''">{{item.questionExplanation}}</span>
                                        <textarea name="" id="" rows="4" maxlength="300" [(ngModel)]="opt.explanation"
                                            [ngModelOptions]="{standalone: true}" #explanation="ngModel"
                                            placeholder="Enter Your Answer" class="w-100"></textarea>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf=" item.typeId==queMCMA">
                            <div class="d-inline-block ml-3 mr-2 card-shadow"
                                *ngFor="let opt of item?.options;let idx=index;">
                                <div class="img-text-active" [ngClass]="{'txt-area-active':opt.isAnswer}">
                                    <div class="custom-control custom-checkbox image-checkbox">
                                        <input type="checkbox" class="custom-control-input" name="queMCMA{{idx}}{{i}}"
                                            id="queMCMA{{idx}}{{i}}" [ngModelOptions]="{standalone: true}"
                                            (click)="cbkAnswer(i,idx,$event)" [checked]="opt.isAnswer" required>
                                        <label class="custom-control-label" for="queMCMA{{idx}}{{i}}">
                                            <div *ngIf="opt.documentName != null && opt.documentName !=''">                                                
                                            <img *ngIf="opt.documentName != null || opt.documentName !=''" onerror="this.onerror=null; this.src='/assets/images/no-image.png'"
                                            [src]="opt.documentLocation"  class="img-fluid">
                                            </div> 
                                            <div class="mt-3 no-image-text" *ngIf="opt.documentName == null || opt.documentName ==''">
                                                No image available
                                            </div>
                                        </label>
                                        <div class="text-slide-img">{{opt.optionLabel}}</div>
                                    </div>

                                    <div class="option-text" *ngIf="item.iSquestionExplanation == 2">
                                        <span class="extra-info" *ngIf="item.questionExplanation != ''">{{item.questionExplanation}}</span>
                                        <textarea name="" id="" rows="4" maxlength="300" [(ngModel)]="opt.explanation"
                                            [ngModelOptions]="{standalone: true}" #explanation="ngModel"
                                            placeholder="Enter Your Answer" class="w-100"></textarea>
                                    </div>
                                </div>
                            </div>

                        </ng-container>
                        <ng-container *ngIf="item.typeId==queDescriptive">
                            <div class="col-md-12 mt-2" *ngFor="let opt of item?.options;let idx=index;">
                                <div class="w-100 extra-value">
                                    <span class="w-100 extra-info">Descriptive</span>

                                    <textarea name="" id="" rows="4" class="w-100" [(ngModel)]="opt.description"
                                        [ngModelOptions]="{standalone: true}" #explanation="ngModel"
                                        name="explanation"></textarea>

                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="item.typeId==queRatings">
                            <div class="col-md-12" *ngFor="let opt of item?.options;let idx=index;">
                                <div class="rating-get mt-3 mb-3">
                                    <a title="poor" class="rate-numb" [class.active]="opt.rating == 1"
                                        (click)="onrating(i,idx,1)"><span>1</span></a>
                                    <a class="rate-numb" [class.active]="opt.rating == 2"
                                        (click)="onrating(i,idx,2)"><span>2</span></a>
                                    <a class="rate-numb" [class.active]="opt.rating == 3"
                                        (click)="onrating(i,idx,3)"><span>3</span></a>
                                    <a class="rate-numb" [class.active]="opt.rating == 4"
                                        (click)="onrating(i,idx,4)"><span>4</span></a>
                                    <a title="Excellent" class="rate-numb" [class.active]="opt.rating == 5"
                                        (click)="onrating(i,idx,5)"><span>5</span></a>
                                </div>

                                <mat-error class="mat-form-field-subscript-wrapper " *ngIf="isReqRating">Rating is
                                    required
                                </mat-error>
                            </div>
                        </ng-container>



                        <div class="col-md-12 mt-2">
                            <ng-container *ngIf="item.iSquestionExplanation == 1 && item.typeId != queDescriptive"><span
                                    class="w-100 extra-info">Additional Information</span>
                                <div class="w-100 extra-value">
                                    <span class="extra-info" *ngIf="item.questionExplanation != ''">{{item.questionExplanation}}</span>
                                    <textarea name="" id="" rows="4" [ngModelOptions]="{standalone: true}"
                                        maxlength="300" [(ngModel)]="item.explanation" #questionExplanation="ngModel"
                                        placeholder="Enter Your Answer" class="w-100"></textarea>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </fieldset>

            </div>
            </div>
        </div>
    </div>
</div>
    <div class="col-md-12 grid-card question-card" *ngIf="lstFromDetails?.details?.length > 0 && pageid == 1">
        <div class="question-list ">Question List</div>
        <div class="col-md-12 p-0">
            <div cdkDropList (cdkDropListDropped)="dropQuestions($event)" class="row">
                <div class="col-lg-4  mb-5" cdkDrag *ngFor="let item of lstFromDetails?.details;let in=index">

                    <div class="card shadow cursor-pointer cursor-move">
                        <div class="card-body  d-flex flex-column">
                            <div class="dropdown no-arrow pr-2">
                                <a class="dropdown-toggle" role="button" id="dropdownMenuLink{{in}}"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in "
                                    aria-labelledby="dropdownMenuLink{{in}}">
                                    <a class="dropdown-item cursor-pointer" title="Edit"  *ngIf="roleAccess.isEdit"
                                        (click)="onEditQuestion(item,in)">Edit</a>
                                    <a class="dropdown-item cursor-pointer" title="Delete" *ngIf="roleAccess.isDelete"
                                        (click)="onDeleteQuestion(item)">Delete</a>
                                    <a class="dropdown-item cursor-pointer" title="Duplicate"
                                        (click)="onDuplicateQuestion(item)">Duplicate</a>
                                </div>
                            </div>
                            <div class="question text-truncate"><span>Q{{in+1}}.</span>
                                <span class="ql-container ql-snow" style="border-width: 0;">
                                    <span class="ql-editor" [innerHTML]="item.question">
                                    </span>
                                </span>
                            </div>
                            <div class="yesno mt-2 mb-1 pl-4" *ngIf="item.typeId == queYesNo">
                                <div class="form-check form-check-inline" *ngFor="let opt of item.options">
                                    <input class="form-check-input" type="radio" disabled="disabled"
                                        name="flexRadioDefault" id="flexRadioDefault4">
                                    <label class="form-check-label label-radio text-truncate" for="flexRadioDefault4 "
                                        >
                                        {{opt.optionLabel}} 
                                    </label>
                                </div>

                            </div>
                            <div class="yesno mt-2 mb-1 pl-4 multiplechoice-single" *ngIf="item.typeId == queMCSA">
                                <div class="form-check form-check-inline" *ngFor="let opt of item.options">
                                    <input class="form-check-input" type="radio" disabled="disabled"
                                        name="flexRadioDefault" id="flexRadioDefault4">
                                    <label class="form-check-label  label-radio text-truncate" for="flexRadioDefault4"
                                        [ngClass]="{ 'answer-green ': opt.isAnswer }">
                                        {{opt.optionLabel}} 
                                    </label>
                                </div>

                            </div>
                            <div class="yesno mt-2 mb-1 pl-4 multiplechoice-single" *ngIf="item.typeId == queMCMA">
                                <div class="form-check form-check-inline" *ngFor="let opt of item.options">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3"
                                        disabled>
                                    <label class="form-check-label text-truncate"
                                        [ngClass]="{ 'answer-green ': opt.isAnswer }"
                                        for="inlineCheckbox3"> {{opt.optionLabel}} </label>
                                </div>

                            </div>
                            <div class="mt-2 mb-2" *ngIf="item.typeId == queRatings">
                                <div class="rating">
                                    <input type="radio" name="rating" id="rata5"><label for="rata5">5</label>
                                    <input type="radio" name="rating" id="rata4"><label for="rata4">4</label>
                                    <input type="radio" name="rating" id="rata3"><label for="rata3">3</label>
                                    <input type="radio" name="rating" id="rata2"><label for="rata2">2</label>
                                    <input type="radio" name="rating" id="rata1"><label for="rata1">1</label>
                                </div>
                            </div>
                            <div class="q-type"><label>Question Type :</label><span>{{item.questionTypeName}}</span>
                            <div class="q-type" *ngIf="item.instructionName != '' && item.instructionName != null"><label>Instructions :</label><span>{{item.instructionName}}</span>
                                <!-- <span *ngIf="lstFromDetails?.formTypeId == 3 && item.isAttachment == true && (item.typeId == queMCSA || item.typeId == queMCMA)" ><i class="fas fa-paperclip ml-2"></i></span> -->
                            </div>
                            <div class="comment-section pl-2 mt-auto" *ngIf="item.typeId ==4 ">
                                <div class="comment-txt">
                                    Enter your answer
                                </div>
                            </div>
                            <div class="comment-section pl-2 mt-auto" *ngIf="item.iSquestionExplanation !=3 && item.typeId !=4">
                                <div class="comment-txt">
                                    Enter your answer
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>

    </div>
    <button type="button" class="btn btn-danger btn-floating btn-lg" id="btn-back-to-top"
        *ngIf="configureFormId != 0 && pageid == 1 &&  roleAccess.isCreate" (click)="onAddQuestion()">
        <i class="fas fa-plus-circle mr-1"></i> Question
    </button>

<div class="modal bulk-add" id="myModal" [style.display]="isBulkQuestion ? 'block' : 'none'">
    <div class="modal-dialog mb-2">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Add Bulk Question</h6>
                <button aria-hidden="true" data-dismiss="modal" class="close" (click)="onCloseBulkAddQuestion()"
                    type="button"><i class="fas fa-times"></i></button>

            </div>
            <div class="modal-body">

                <form (ngSubmit)="bulkconfigform.form.valid  && postBulkQuestionForm(bulkconfigform)"
                    id="bulkconfigform" autocomplete="off" #bulkconfigform="ngForm" novalidate>
                    <div class="col-md-12">
                        <div class="col-md-8 mx-auto p-0 set-quesion">
                            <div class="row">
                                <mat-label class="col-md-6 mt-3 pt-1 text-right">Number of Questions?</mat-label>
                                <div class="col-md-6 text-left pt-1">
                                    <mat-form-field>
                                        <mat-select placeholder="Select the option" name="questionlen1"
                                            [(ngModel)]="questionlen" #questionlen1="ngModel"
                                            (selectionChange)="onbulkQuestionload($event.value)" required>
                                            <mat-option [value]="5">
                                                5
                                            </mat-option>
                                            <mat-option [value]="10">
                                                10
                                            </mat-option>
                                            <mat-option [value]="15">
                                                15
                                            </mat-option>
                                            <mat-option [value]="20">
                                                20
                                            </mat-option>
                                        </mat-select>

                                    </mat-form-field>
                                    <mat-error *ngIf="bulkconfigform.submitted && questionlen1.invalid">
                                        <mat-error class="mat-form-field-subscript-wrapper"
                                            *ngIf="questionlen1.errors.required">Please select the number of question
                                        </mat-error>
                                    </mat-error>
                                </div>

                            </div>


                        </div>
                        <div class="list-question">
                            <div class="row" *ngFor="let item of bulkQuestion;let i = index">
                                <div class="col-md-1 p-0 text-center mt-3 pt-1">
                                    <span>{{i+1}}</span>
                                </div>
                                <div class="col-md-11">
                                    <mat-form-field class="w-100 ">
                                        <mat-label>Enter your question</mat-label>
                                        <input matInput placeholder="Enter your question" [(ngModel)]="item.question"
                                            #question="ngModel" ngModel name="question{{i}}"
                                            [ngModelOptions]="{standalone: true}"
                                            [ngClass]="{ 'is-invalid': bulkconfigform.submitted && question.invalid }"
                                            required />
                                        <mat-error *ngIf="bulkconfigform.submitted">
                                            <mat-error *ngIf="question.errors?.required">Question is required
                                            </mat-error>
                                        </mat-error>
                                        <a *ngIf="i >= 2" class="delete-node d-inline-block cursor-pointer "
                                            (click)="ondeletebulkOption(i)"><i class="fas fa-minus-circle"></i></a>

                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer text-center" *ngIf="roleAccess.isEdit ||  roleAccess.isCreate">
                <button type="button" (click)="onCloseBulkAddQuestion()" class="btn primary-btn-filled primary-btn-s "
                    data-dismiss="modal">Close</button>
                <button type="submit" form="bulkconfigform" class="btn primary-btn-filled primary-btn-s "
                    data-dismiss="modal">Save</button>
            </div>
        </div>
    </div>
</div>
<div class="modal bulk-add emoji-popup" id="myModal" [style.display]="istemplatedownloaded ? 'block' : 'none'">
    <div class="modal-dialog mb-2">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Add Image</h6>
                <button aria-hidden="true" data-dismiss="modal" class="close" (click)="onImportImage()"
                    type="button"><i class="fas fa-times"></i></button>

            </div>
            <div class="modal-body">

                <div class="col-md-12 mt-3 " >
                    <div class="row" *ngIf="(uploadType == 1 && QuestionImages.length < 5) || uploadType == 2">
                        <div class="col-md-3 p-0">
            
                            <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                <ng-container *ngFor="let item of libraryNames;let i=index">
                                    <a class="nav-link mb-2 p-3 shadow" id="v-pills-home-tab" data-toggle="pill"
                                        (click)="onloadImages(item.libraryNameId)" href="#v-pills-home" role="tab"
                                        aria-controls="v-pills-home" aria-selected="true">
            
                                        <span class="font-weight-bold small text-uppercase text-truncate  d-block">
                                            <span class="complete-icon">
                                                <i class="fas fa-check-circle mr-1" *ngIf="item.isVerified"></i>
                                            </span>
                                            {{item.name}}
                                        </span>
                                    </a>
                                </ng-container>
            
                            </div>
                        </div>
                        <div class="col-md-9" >
                            <div class="lead-box">
                            <!-- Tabs content -->
            
                            <div  class="float-left uploaded -file mr-1 mb-2 text-danger"
                            *ngFor="let a of libraryImages;let index = index;">
                            <!-- <span class="file-lenght" (click)="onselectImage(a)" title="{{a.documentName}}">{{a.documentName}}</span> -->
                            <img style="width: 20px; height: 20px;" (click)="onselectImage(a)" src="{{a.documentLocation}}" />
                          </div>
                        </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="(uploadType == 1 && QuestionImages.length < 5 )|| uploadType == 2">
                        <div class="col-md-12 mt-4 mb-2">
                            <!-- Tabs content -->
            
                            <div class="upload-btn-wrapper">
                                <!-- <button class="btn file-add cursor-pointer float-right primary-btn-outlined">Add
                                  Files</button> -->
                                  
                      </div>
                                <input accept=".jpg,.jpeg,.png" title="Add Files" type="file" (change)="onFileChange($event)"
                                  class=" cursor-pointer" #importphbook />
                              </div>
                        </div>
                        <div class="row" *ngIf="uploadType == 1">
                            <label class="form-detail-Value-created"> Maximum 5 Images</label>
                        </div>
                        <div class="row">
                            <div *ngIf="uploadType == 1">
                                <mat-chip-list class="mt-2  attachment-sec">
                                    <mat-chip  selected *ngFor="let a of QuestionImages;let index = index;"
                                      [selectable]="true" [removable]="true" (removed)="removeSelectedImport(index)">
                                      <span class="attachname">{{a.fileName}}</span>
                                      <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
                                    </mat-chip>
                                  </mat-chip-list>

                                <!-- <div  class="float-left uploaded -file mr-1 mb-2 text-danger"
                          *ngFor="let a of QuestionImages;let index = index;">
                          <span class="file-lenght" title="{{a.fileName}}">{{a.fileName}}</span>
                          <span (click)="removeSelectedImport(index)"
                            class="close text-danger cursor-pointer">&times;</span>
                        </div> -->
                        </div>
                    </div>
            
                </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" (click)="onImportImage()" class="btn primary-btn-filled primary-btn-s "
                        data-dismiss="modal">Close</button>
                    <!-- <button type="submit" form="bulkconfigform" class="btn primary-btn-filled primary-btn-s "
                        data-dismiss="modal">Save</button> -->
                </div>
                </div>
                </div>
                </div>

<!--DirectionEdit-->
<div class="comment-popup11  ">
    <div class="modal" tabindex="-1" role="dialog" [style.display]="isDirectionNameUpdt? 'block':'none'">
      <div class="modal-dialog mat-dialog-container h-auto p-0" role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title">Edit Direction Name</h6>
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OncloseDiectionEditPopup()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
    <div class="modal-body">
      <div>
        <div class="col-md-12">
          <div class="row mb-2">
           <form (ngSubmit)="saveDirectionName(directionEditForm)" class="w-100" id="directionEditForm" autocomplete="off" #directionEditForm="ngForm"
        novalidate>
    <div class="mt--14 libraryImages-container">
       
        <div class="col-md-12 p-0 ">
            <div class="col-md-12 p-0">
                <div class="top-notes d-flex w-100 position-relative mt-3">
                </div>
                <div class="task-create-section">
                <div class="col-md-12">
                    <div class="row">
                        <mat-form-field class="w-100">
                        <input matInput placeholder="Direction Name"   [(ngModel)]="configureForm.questionDirection" #questionDirection="ngModel" required
                           name="questionDirection" >  
                           <mat-error *ngIf="directionEditForm.submitted && questionDirection.invalid">
                            <mat-error class="mat-form-field-subscript-wrapper" *ngIf="questionDirection.errors.required">
                                Direction Name is required</mat-error>
                        </mat-error>                    
                          </mat-form-field>
                    </div>
                </div>
                <div class="col-md-12 text-center position-relative mt-0">
                            <span>
                                <button type="submit" form="directionEditForm" mat-raised-button
                                class="primary-btn-outlined-small mr-2">Update</button>
                            </span>   
                             
                </div>
            </div>
            </div>
        </div>
    </div>
    </form>
    </div>
   </div>
  </div>
  </div>
  
</div>
  </div>
  </div>
  </div>






<!--DirectionEdit End-->




    
export class template {
    consentTemplatesId: number;
    templateCategoryId: number;
    templateNameId: number;
    templateContent: string;
    isActive: boolean;
    isEsign: boolean;
    isMultiPartyDocument: boolean;
    esignContent: string;
    updateDate: any;
    locationList:any=[];
    location:any;
    isGlobal:any;
    locationName:any;
}
export class ConfigureForm {
    configureFormId: number;
    formTypeId: number;
    formName: string;
    isquestionExplanation : number;
    questionDirection:string;
    location:string;
    isGlobal:any;
    locationName:any;
     }
export class ConfigureFormDetail {
    configureFormDetailId: number;
    configureFormId: number;
    questionDirection: string;
    question: string;
    typeId: number;
    options: Array<DtoOption>;
    images: Array<any>;
    iSquestionExplanation: number;
    questionExplanation: string;
    isDelete: boolean;
    iSoptionExplanation: boolean;
    answerHint:string;
    instructionName:string;
}
export class DtoOption {
    questionOptionId: number;
    configureFormDetailId: number;
    optionChar: string;
    optionLabel: string;
    isAnswer: boolean;   
    isDelete: boolean;
}

export class DtoTemplate {
    fieldType: string;
    fields: Array<DtoTemplatefield>;
}

export class DtoTemplatefield {
    createDate: string;
    fieldType: string;
    fieldTypeId: number;
    isActive: boolean;
    isEsign: boolean;
    templateFieldName: string;
    templateFieldValue: string;
    templateFieldsId: number;
    templateNameId: number;
    updateDate: any;
}
export class GroupTherapy{
    groupTherapyId: any;
	groupTherapyName: string;
	capacity: number;
	isRecurrance: boolean=false;   
	startDate: string;
	endDate: string;
	startTime: any;
	duration: number;
	isEndDate: boolean;   
	frequency: number;
    isActive: boolean;   
}
export class DtoEditDirectionName {
    configureFormId: number;
    formTypeId: number;
    formName: string;
    isquestionExplanation : number;
    questionDirection: string;
    }

export class CrateSkillTaught {
    SkillTaughtMasterId:number;
    formTypeId: number;
    formName: string;
    location : number;
    therapy:number;
    isGlobal:any;
    }
export class CrateSkillTaughtQuestion {
    question:string;
    }
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { S, Y } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common/common.service';
import { constantVariables } from '../constant/constant';
import { Global } from '../global/global.model';
import { PageAccessService } from '../rolemanagement/PageRoleAccessService';
import { SnackBarService } from '../services/snackBar.service';
import { ClinicianService } from '../staff/clinician/clinician.service';
import { TreatmentService } from '../staff/treatment/treatment.service';
import { SettingsService } from '../common/pages/settings/settings.service';
import { TemplateService } from '../common/pages/template/template.service';
import { PeriodicElement } from '../billing/billing-masters/billing-masters.component';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-accordiandesign-dailyscheduler',
  templateUrl: './accordiandesign-dailyscheduler.component.html',
  styleUrls: ['./accordiandesign-dailyscheduler.component.scss']
})
export class AccordiandesignDailyschedulerComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('table', { static: true }) table: MatTable<PeriodicElement>;

  expandedIndex = 0;
  grpTherapyId: any;
  _dtoGrpTherapyMeta: any;
  _dtoGrpTherapyUsers: any = [];
  _dtoAssignedUsers: any;
  _dtoGroupMeetingTherapyMaster: any;
  _dtoGroupMeetingTherapyMasterList :any;
  //Drag and Drop list
  _dtoDropListCount: any = ['1', '2', '3', '4', '5', '6'];
  _dtoGroupTherapyDetails: any;
  allClinicians: any;
  supportInterns :any;
  _dtoOutputList: any[] = [];
  _dtoTherapyList: any[] = [];
  selectedTherapy: any[] = [];
  isFormSubmitted: boolean = false;

  //Time and Date comparison variable
  groupTherapyStartTime: any;
  groupTherapyEndTime: any;
  groupTherapyStartTimeHrs: any;
  groupTherapyEndTimeHrs: any;
  groupTherapyStartTimeChange: any;
  groupTherapyEndTimeChange: any;
  clientType: any;
  typeSelected: any;
  arrLength: any = ['1', '2', '3', '4', '5'];
  clinicians: any=[];
  therpyList: any[] = [];
  ddlMeetinglocation: any;
  supportList: any;
  pdfpsytherapynotes: any;
  pdfDailySchedule: any;
  groupTherapyEndTimeDetails: any;
  selectedTherapyList: any = [];
  onTimeChaneError: boolean = false;
  OntimeChangeError: boolean = false;
  IsPcp: boolean = false;
  IsIop: boolean = false;
  IsHomeInstruction : boolean = false;
  scheduleDate: any;
  supportListData: any[] = [];
  locationId: number;
  isUpdate: boolean = false;
  disable: any;
  noRecords: boolean = false;
  isLoad: boolean = false;
  internString: any;
  leaderString:any;
  groupMeetingLocationId:any;
  clinicianId : number = 0;
  isClinician : boolean = false;
  dragDisabled = true;
  gtIntervention : boolean = false;
  dsComments : any;
  totalTime : any;
  DSlength : any;
  constructor(public global: Global,
    private snackbar: SnackBarService,
    private route: ActivatedRoute,
    private router: Router,
    public commonService: CommonService,
    public clinicianservice: ClinicianService,
    public spinnerservice: NgxSpinnerService,
    public treatmentservice: TreatmentService,
    private date: DatePipe,
    private dialog: MatDialog,
    public roleaccessservice: PageAccessService,
    public settingsService: SettingsService,
    private templateService:TemplateService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        if (params.groupTherapyId) {
          this.grpTherapyId = +this.commonService.FrontEndDecryption(params.groupTherapyId).replace(/"/g, "");
        }
        if (params.clientType) {
          this.clientType = Number(this.commonService.FrontEndDecryption(params.clientType).replace(/"/g, ""));
        }
        if (params.scheduleDate) {
          this.scheduleDate = new Date(params.scheduleDate);
        }
        if (params.disable) {
          this.disable = this.commonService.FrontEndDecryption(params.disable).replace(/"/g, "");
        }
        if (params.clinicianId &&  Number(localStorage.getItem('roletypeId')) != 1) {
          //this.clinicianId = Number(this.commonService.FrontEndDecryption(params.clinicianId).replace(/"/g, ""));
          this.isClinician = true;
        }
      }
      
    });
    this.locationId = Number(localStorage.getItem('sfcclocation'));
    if(this.locationId <= 0)
    {
      var location = JSON.parse(localStorage.getItem('location'));
      if(location!=null){
        this.locationId = location.split(',').map(Number)[0];
      }
    }
    if (this.clientType == 1) {
      this.typeSelected = "IOP"
    } else if (this.clientType == 2) {
      this.typeSelected = "PCP"
    }else if (this.clientType == 3) {
      this.typeSelected = "Home Instruction"
    }

    this.getGroupTherapyDetails();

    this.getMeetingLocation();
  }
  onloadFormGroup() {
    this.treatmentservice
      .loadFormGroupDetails(this.grpTherapyId, moment(this.scheduleDate).format("MM/DD/YYYY"),this.clinicianId)
      .subscribe((res: any) => {
        
        this.spinnerservice.hide();
        if (res.length > 0) {
          if (res[0].isIop) {
            this.typeSelected = "IOP";
            this.clientType=1;
          } else if (res[0].isPcp) {
            this.typeSelected = "PCP";
            this.clientType=2;
          }else if(res[0].isHomeInstruction)
          {
            this.typeSelected = "Home instruction";
            this.clientType=3;
          }
          this.noRecords = false;
          this.isUpdate = true;
          this.clinicians = [];
          res.map((x, indexRes) => {
            var _obj = {
              dropList: [],
              selectedTherapy: [],
              dayScheduleGroupTherapyId: x.dayScheduleGroupTherapyId,
              groupTherapyId: x.groupTherapyId,
              dayScheduleSubGroupId: x.dayScheduleSubGroupId,
              dropListError: false,
              therapyError: false,
              tmpList: [],
              isDelete: true,
              isActive: false,
              dsComments : x.dsComments
            }
            if (indexRes == 0) {
              _obj.isActive = true;
            } else {
              _obj.isActive = false;
            }
            x.patients.map(y => {
              var patientDetail = this._dtoGrpTherapyUsers.filter(a => a.PatientAccountId == y.patientAccountId)[0];
              if (patientDetail) {
                var index = this._dtoGrpTherapyUsers.indexOf(patientDetail);
                if (index != -1) {
                  this._dtoGrpTherapyUsers.splice(index, 1);
                }
                patientDetail.DayScheduleAssignId = y.dayScheduleAssignId;
                _obj.dropList.push(patientDetail);
              }
            })
            x.selectedTherapy.map((z,indextmpList) => {
              if(res[0].isHomeInstruction)
              {
                this._dtoGroupMeetingTherapyMaster = this._dtoGroupMeetingTherapyMasterList.filter(x=>x.isHomeInstruction == true && x.gttherapyIsActive == true);
              }else{
                this._dtoGroupMeetingTherapyMaster = this._dtoGroupMeetingTherapyMasterList.filter(x=>x.isHomeInstruction == false && x.gttherapyIsActive == true);
              
              }
              var selectedTherapyFind = this._dtoGroupMeetingTherapyMaster.filter(a => a.groupMeetingTherapyId == z.groupMeetingTherapyId)[0];
              var skillTaught=[];
              if(z.skillTaughtMasterId){
                if(z.skillTaughtMasterId.includes(',')){
                skillTaught=z.skillTaughtMasterId.split(',').map(x => {return +x});
                }else{
                  skillTaught.push(+z.skillTaughtMasterId);
                }
              }              
              var obj = {
                groupMeetingTherapyId: z.groupMeetingTherapyId,
                therapyName: selectedTherapyFind.therapyName,
                gtIntervention : z.gtIntervention,
                startTime: moment(z.startTime).format("HH:mm"),
                endTime: moment(z.endTime).format("HH:mm"),
                meetingLocationsId: z.meetingLocationsId,
                dayScheduleSubTherapyId: z.dayScheduleSubTherapyId,
                dayScheduleSubGroupId: z.dayScheduleSubGroupId,
                clinician: null,
                leader: [],
                support: [],
                comments: z.skillTaught,
                skillTaught:[],
                startTimeError: false,
                endTimeError: false,
                locationError: false,
                commentsError: false,
                startTimeErrorMessage: "",
                endTimeErrorMessage: "",
                leaderError: false,
                clinicianError: false,
                supportError: false,
                supportData: this.supportInterns,
                clinicians: [],
                leaderListData:[],
                skillTaughtNames:[],
                dSlength : z.dSlength

              }
              if(skillTaught){
                if(skillTaught.length!=0){
                  this.templateService.GetSkillTaughtNamesByTherapy(obj.groupMeetingTherapyId,this.clientType,this.locationId).subscribe((res)=>{
                    obj.skillTaughtNames=res;
                    obj.skillTaught=skillTaught;
                  },
                  (err)=>{
                    this.spinnerservice.hide();
                  })
                }
              }
              obj.skillTaught=skillTaught;
              var leaderListData = [];
              if (z.clinician != undefined) {
                // var getClinicianList = this.allClinicians.filter(a => a.staffId == z.clinician)[0];
                // if (getClinicianList) {
                  // var checkexists = this.clinicians?.filter(a => a.staffId == getClinicianList.staffId);
                  // if (checkexists.length == 0) {
                  //   this.clinicians.push(getClinicianList);
                    
                  // }
                  //obj.clinician = getClinicianList;

                  var clinicianSt = z.clinician.split(',');
                  var clinicianList = [];
                  clinicianSt.map(cli => {
                    var selectedclinician = this.allClinicians.filter(y => y.staffId == cli)[0];
                    if (selectedclinician) {
                      var objLead = {
                        firstName : selectedclinician.firstName,
                        gender : 0,
                        isActive : true,
                        lastName : selectedclinician.lastName,
                        reportedTo : 0,
                        roleId : 0,
                        userId : selectedclinician.staffId
                      }
                    }
                   
                    leaderListData.push(objLead);
                      clinicianList.push(selectedclinician);
                    })
                  }
                  obj.clinician = [...clinicianList];


                  // obj.clinicians = this.clinicians;
                  obj.clinicians = this.allClinicians;
                  this.isLoad = true;
                  this.internString = z.support;
                  this.leaderString = z.leader;
                  // this.clinicianChange(indexRes, getClinicianList, this.internString, true, indextmpList, this.leaderString);


                  //this._dtoOutputList[i].tmpList[y].leaderListData = this._dtoOutputList[i].tmpList[y].support;
               
                  
                  //this._dtoOutputList[i].tmpList[y].supportData = res;
                  var interns = this.internString.split(',');
                  var internsList = [];
                  interns.map(p => {
                    var intern = this.supportInterns.filter(y => y.userId == p)[0];
                    if (intern) {
                      internsList.push(intern);
                      leaderListData.push(intern);
                    }
                  })
                  obj.support = [...internsList];
                  obj.leaderListData = leaderListData;
                  var leader = this.leaderString.split(',');
                  var leaderList = [];
                  leader.map(z => {
                    var leaders = obj.leaderListData.filter(y => y.userId == z)[0];
                    if (leaders) {
                      leaderList.push(leaders);
                    }
                    obj.leader =[...leaderList]
                    // else{
                    //   var leaderInClinician = this._dtoOutputList[i].tmpList[y].leaderListData.filter(y => y.staffId == z)[0];
                    //   if(leaderInClinician)
                    //   {
                    //     leaderList.push(leaderInClinician);
                    //   }
                    // }
                  })
                 // this._dtoOutputList[i].tmpList[y].leader = [...leaderList];
                  // this.stafChange(i,this._dtoOutputList[i].tmpList[y].support,y,false,leaderString);
                  // this.stafChange(i,this._dtoOutputList[i].tmpList[y].support,y,true,leaderString);
        
        
                

              
              _obj.tmpList.push(obj);
              if (selectedTherapyFind) {
                _obj.selectedTherapy.push(selectedTherapyFind);
              }

            })
            this._dtoOutputList.push(_obj);
          })
        } else {
          this.initArray(1);
          this.isUpdate = false;
        }
      },
        err => {
          this.spinnerservice.hide();
          this.initArray(1);
          this.isUpdate = false;
        });

  }
  initArray(count) {

    for (let i = 0; i < count; i++) {
      var _obj = {
        dropList: [],
        selectedTherapy: [],
        dayScheduleGroupTherapyId: 0,
        groupTherapyId: 0,
        leaderError: false,
        dropListError: false,
        therapyError: false,
        clinicianError: false,
        supportError: false,
        tmpList: [],
        isDelete: false,
        isActive: true
      }
      if(this.clientType == 3)
      {
        var homeInst = this._dtoGroupMeetingTherapyMaster.filter(x=>x.therapyName == "Therapeutic Home Instruction")[0];
        if(homeInst)
        _obj.selectedTherapy.push(homeInst);
        _obj.selectedTherapy.map(x=>{
          for(let i=0;i<2;i++)
          {
            let objVal = {
              groupMeetingTherapyId: x.groupMeetingTherapyId,
              therapyName: x.therapyName,
              startTime: '',
              endTime: '',
              meetingLocationsId: 0,
              dayScheduleSubTherapyId: 0,
              comments: "",
              clinician: null,
              leader: [],
              support: [],
              startTimeError: false,
              endTimeError: false,
              locationError: false,
              commentsError: false,
              leaderError: false,
              clinicianError: false,
              supportError: false,
              supportData:this.supportInterns,
              // clinicians: this.clinicians,
              clinicians: this.allClinicians,
              leaderListData:[],
            }
            var isExist = _obj.tmpList?.filter(s => s.groupMeetingTherapyId == x.groupMeetingTherapyId);
            if (isExist?.length < 2)
            _obj.tmpList.push(objVal);
          }
        })

      
      }
      this._dtoOutputList.push(_obj);
     
    }


  }
  getDayScheduleForAssign() {
    this.treatmentservice
      .getDayScheduleForAssignClinician(this.grpTherapyId)
      .subscribe((res: any) => {
        if (res.objInputString != null) {
          let fres = this.commonService.decrypdata(res.objInputString);
          if (fres.length > 0) {
            this.noRecords = false;
            this._dtoGrpTherapyMeta = fres[0];
            fres.map(({ PatientAccountId, UserName, PresentorAbsent ,ProgressnotePresentorAbsent,PresentorAbsentMarked }) => {
              var _obj = {
                PatientAccountId: PatientAccountId,
                UserName: UserName,
                DayScheduleAssignId: 0,
                PresentorAbsent : PresentorAbsent,
                ProgressnotePresentorAbsent : ProgressnotePresentorAbsent,
                PresentorAbsentMarked : PresentorAbsentMarked
              }
              if(this._dtoGrpTherapyUsers.length >0) {
                  var isExistIndex  = this._dtoGrpTherapyUsers.findIndex(e=>e.PatientAccountId == _obj.PatientAccountId);
                  if(isExistIndex>=0){
                    if(this._dtoGrpTherapyUsers[isExistIndex].PresentorAbsentMarked == 1) {
                      this._dtoGrpTherapyUsers[isExistIndex] = _obj
                    }
                  } else {
                    this._dtoGrpTherapyUsers.push(_obj);
                  }
              } else {
                this._dtoGrpTherapyUsers.push(_obj);
              }
            });
            this.getGropuMeetingTherapyMaster();

          }
        } else {
          this.noRecords = true;
        }


      },
        err => {
          this.spinnerservice.hide();
        });
  }
  cancelGroup() {
    this.spinnerservice.show();
    this._dtoOutputList = [];
    this.OntimeChangeError = false;
    this.isFormSubmitted = false;
    if (this.isUpdate) {
      this.onloadFormGroup();
    } else {
      this.initArray(1);
    }

    this.isUpdate = false;
    this.spinnerservice.hide();
  }
  getGroupTherapyDetails() {
    this.spinnerservice.show();
    this.treatmentservice
      .getGroupMeetingDetails(this.grpTherapyId)
      .subscribe((res: any) => {
        this._dtoGroupTherapyDetails = res;
        this.groupMeetingLocationId=this._dtoGroupTherapyDetails.location;
        this.groupTherapyEndTime = this._dtoGroupTherapyDetails.endTime;
        this.groupTherapyStartTime = this._dtoGroupTherapyDetails.startTime;
        this.groupTherapyStartTimeHrs = moment(this.groupTherapyStartTime).format("HH:mm");
        this.groupTherapyEndTimeHrs = (moment(this._dtoGroupTherapyDetails.startTime)).add(this._dtoGroupTherapyDetails.duration, 'minutes').format('HH:mm');
        this.groupTherapyStartTimeChange = moment(this.groupTherapyStartTime).format("hh:mm A");
        this.groupTherapyEndTimeChange = (moment(this._dtoGroupTherapyDetails.startTime)).add(this._dtoGroupTherapyDetails.duration, 'minutes').format('hh:mm A');
        this.groupTherapyEndTimeDetails = (moment(this._dtoGroupTherapyDetails.startTime)).add(this._dtoGroupTherapyDetails.duration, 'minutes').format('hh:mm A');
        this.getPrimaryStaff();
      });
  }
  getStaffInternList(id) {
    this.commonService.getStaffInternList(id).subscribe((res: any) => {
    })
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this._dtoOutputList.map(group => {
      if (group.dropList.length == 0) {
        group.dropListError = true;
      } else {
        group.dropListError = false;
      }
    });
  }
  getPrimaryStaff() {
    let location;
    if(localStorage.getItem('sfcclocation') == null)
    {
      location =-1;
    }else{
      location = localStorage.getItem('sfcclocation')
    }
    this.commonService.getStaffListForGroupTherapy(constantVariables.role.PrimaryClinician, location,this.grpTherapyId)
      .subscribe((res: any) => {
        if (res) {
          // if(this.isClinician)
          // {
          //   this.allClinicians = res.filter(x=>x.staffId == this.clinicianId);
          // }else{
            this.allClinicians = res;
          //}
          
          this.getInternSupportStaff();
        }
      });
  }
  getInternSupportStaff() {
    this.commonService.getStaffInternList(this.grpTherapyId)
      .subscribe((res: any) => {
        if (res) {
          this.supportInterns = res;
          this.getDayScheduleForAssign();
        }
      });
  }
  addGroup() {
    var _obj = {
      dropList: [],
      selectedTherapy: [],
      dayScheduleGroupTherapyId: 0,
      groupTherapyId: 0,
      dropListError: false,
      therapyError: false,
      tmpList: [],
      isDelete: false,
      isActive: false
    }
    this._dtoOutputList.push(_obj);

  }
  deleteGroup(i) {
    if (this._dtoOutputList.length > 1) {
      this._dtoOutputList.splice(i, 1);
    }
    this._dtoOutputList[0].isActive = true;
  }
  accordianOpen(accordionItem) {
    accordionItem.openAll();
  }
  leaderChange(i,item,y){

    let val  = this._dtoOutputList[i].tmpList[y].leader;
    // this._dtoOutputList[i].tmpList[y].leader.map(a=>{
      
    //   var leaderExist = this._dtoOutputList[i].tmpList[y].leader.find(n=>n.userId == item.userId);
    //   console.log(a);
    //   if(leaderExist)
    //   {
    //     this._dtoOutputList[i].tmpList[y].leader = this._dtoOutputList[i].tmpList[y].leader.filter(m=>m.userId != item.userId)
    //   }
    // })
    
  }
  stafChange(i,item,y,isLoad,leaderString){
    
          
          if(isLoad)
          {
            var leader = leaderString.split(',');
            var leaderList = [];
            leader.map(z => {
              var leaders = this._dtoOutputList[i].tmpList[y].leaderListData.filter(y => y.userId == z)[0];
              if (leaders) {
                leaderList.push(leaders);
              }else{
                var leaderInClinician = this._dtoOutputList[i].tmpList[y].leaderListData.filter(y => y.staffId == z)[0];
                if(leaderInClinician)
                {
                  leaderList.push(leaderInClinician);
                }
              }
            })
            this._dtoOutputList[i].tmpList[y].leader = [...leaderList];
          }else{
            this._dtoOutputList[i].tmpList[y].leaderListData = [];
            var leaderListData = []
            if(this._dtoOutputList[i].tmpList[y].clinician.length > 0)
            {
              this._dtoOutputList[i].tmpList[y].clinician.map(cli => {
                var obj = {
                  firstName : cli.firstName,
                  gender : 0,
                  isActive : true,
                  lastName : cli.lastName,
                  reportedTo : 0,
                  roleId : 0,
                  userId : cli.staffId
              }
              //var leaderListData = [...this._dtoOutputList[i].tmpList[y].leaderListData];
              leaderListData.push(obj);
              });

              
            [...this._dtoOutputList[i].tmpList[y].leaderListData] = leaderListData;
            }
            
            this._dtoOutputList[i].tmpList[y].support.map(m=>{
              this._dtoOutputList[i].tmpList[y].leaderListData.push(m);
            })
            this._dtoOutputList[i].tmpList[y].leader.map(a=>{
      
              var leaderExist = this._dtoOutputList[i].tmpList[y].leader.find(n=>n.userId == item.userId);
              console.log(a);
              //return a.userId != this._dtoOutputList[i].tmpList[y].leader;
              if(leaderExist)
              {
                
                this._dtoOutputList[i].tmpList[y].leader = this._dtoOutputList[i].tmpList[y].leader.filter(obj =>{
                  return obj !== item
                } );
              }else{
               // this._dtoOutputList[i].tmpList[y].leader = [...this._dtoOutputList[i].tmpList[y].leader];
              }
            })
            //this._dtoOutputList[i].tmpList[y].leader = this._dtoOutputList[i].tmpList[y].leaderListData.filter(n => n !== );
          }
          
          

  }
  clinicianChange(i, item, internString, isLoad, y, leaderString) {

         if (isLoad) {
          this._dtoOutputList[i].tmpList[y].leaderListData = this._dtoOutputList[i].tmpList[y].support;
          var obj = {
              firstName : this._dtoOutputList[i].tmpList[y].clinician.firstName,
              gender : 0,
              isActive : true,
              lastName : this._dtoOutputList[i].tmpList[y].clinician.lastName,
              reportedTo : 0,
              roleId : 0,
              userId : this._dtoOutputList[i].tmpList[y].clinician.staffId
          }
          var leaderListData = [...this._dtoOutputList[i].tmpList[y].leaderListData];
          leaderListData.push(obj);
          [...this._dtoOutputList[i].tmpList[y].leaderListData] = leaderListData;
          
          //this._dtoOutputList[i].tmpList[y].supportData = res;
          var interns = internString.split(',');
          var internsList = [];
          interns.map(x => {
            var intern = this._dtoOutputList[i].tmpList[y].supportData.filter(y => y.userId == x)[0];
            if (intern) {
              internsList.push(intern);
            }
          })
          this._dtoOutputList[i].tmpList[y].support = [...internsList];
         
          this.stafChange(i,this._dtoOutputList[i].tmpList[y].support,y,false,leaderString);
          this.stafChange(i,this._dtoOutputList[i].tmpList[y].support,y,true,leaderString);


        } else {
         

          this._dtoOutputList[i].tmpList[y].leaderList = [];
          var leaderListData = [];
          if(this._dtoOutputList[i].tmpList[y].supportData.length > 0)
          {
            this._dtoOutputList[i].tmpList[y].support.map(support=>{
              leaderListData.push(support);
            })
          }
          this._dtoOutputList[i].tmpList[y].clinician.map(cli => {
            var obj = {
              firstName : cli.firstName,
              gender : 0,
              isActive : true,
              lastName : cli.lastName,
              reportedTo : 0,
              roleId : 0,
              userId : cli.staffId
            }
          
            leaderListData.push(obj);
          
           
          });
          [...this._dtoOutputList[i].tmpList[y].leaderListData] = leaderListData;



          // this._dtoOutputList[i].tmpList[y].leaderListData = this._dtoOutputList[i].tmpList[y].support;
          // var obj = {
          //   firstName : this._dtoOutputList[i].tmpList[y].clinician.firstName,
          //   gender : 0,
          //   isActive : true,
          //   lastName : this._dtoOutputList[i].tmpList[y].clinician.lastName,
          //   reportedTo : 0,
          //   roleId : 0,
          //   userId : this._dtoOutputList[i].tmpList[y].clinician.staffId
          // }
          // var leaderListData = [...this._dtoOutputList[i].tmpList[y].leaderListData];
          // leaderListData.push(obj);
          // [...this._dtoOutputList[i].tmpList[y].leaderListData] = leaderListData;
          //this._dtoOutputList[i].tmpList[y].supportData = res;
        }

    //this.spinnerservice.show();
    // this.commonService.getStaffInternList(item.staffId).subscribe((res: any) => {
    //   this.spinnerservice.hide();
    //   if (res.length > 0) {
    //     if (isLoad) {
    //       this._dtoOutputList[i].tmpList[y].leaderListData = this._dtoOutputList[i].tmpList[y].support;
    //       var obj = {
    //           firstName : this._dtoOutputList[i].tmpList[y].clinician.firstName,
    //           gender : 0,
    //           isActive : true,
    //           lastName : this._dtoOutputList[i].tmpList[y].clinician.lastName,
    //           reportedTo : 0,
    //           roleId : 0,
    //           userId : this._dtoOutputList[i].tmpList[y].clinician.staffId
    //       }
    //       var leaderListData = [...this._dtoOutputList[i].tmpList[y].leaderListData];
    //       leaderListData.push(obj);
    //       [...this._dtoOutputList[i].tmpList[y].leaderListData] = leaderListData;
          
    //       this._dtoOutputList[i].tmpList[y].supportData = res;
    //       var interns = internString.split(',');
    //       var internsList = [];
    //       interns.map(x => {
    //         var intern = this._dtoOutputList[i].tmpList[y].supportData.filter(y => y.userId == x)[0];
    //         if (intern) {
    //           internsList.push(intern);
    //         }
    //       })
    //       this._dtoOutputList[i].tmpList[y].support = [...internsList];
         
    //       this.stafChange(i,this._dtoOutputList[i].tmpList[y].support,y,false,leaderString);
    //       this.stafChange(i,this._dtoOutputList[i].tmpList[y].support,y,true,leaderString);


    //     } else {
         
    //       this._dtoOutputList[i].tmpList[y].leaderListData = this._dtoOutputList[i].tmpList[y].support;
    //       var obj = {
    //         firstName : this._dtoOutputList[i].tmpList[y].clinician.firstName,
    //         gender : 0,
    //         isActive : true,
    //         lastName : this._dtoOutputList[i].tmpList[y].clinician.lastName,
    //         reportedTo : 0,
    //         roleId : 0,
    //         userId : this._dtoOutputList[i].tmpList[y].clinician.staffId
    //       }
    //       var leaderListData = [...this._dtoOutputList[i].tmpList[y].leaderListData];
    //       leaderListData.push(obj);
    //       [...this._dtoOutputList[i].tmpList[y].leaderListData] = leaderListData;
    //       this._dtoOutputList[i].tmpList[y].supportData = res;
    //     }
    //   }else{
    //     this._dtoOutputList[i].tmpList[y].support = [];
    //     this._dtoOutputList[i].tmpList[y].leader = [];
    //     this._dtoOutputList[i].tmpList[y].supportData = [];
    //     this._dtoOutputList[i].tmpList[y].leaderListData = [];
    //   }
    // },
    //   error =>
    //     this.spinnerservice.hide()
    // );
  }
  getGropuMeetingTherapyMaster() {
    this.commonService
      .getGroupMeetingTherapyMaster()
      .subscribe((res: any) => {
        if (res) {
          this._dtoGroupMeetingTherapyMasterList = res;
          if(this.clientType != 3)
          {
            this._dtoGroupMeetingTherapyMaster = res.filter(x=>x.isHomeInstruction == false && x.gttherapyIsActive == true);
          }else{
            this._dtoGroupMeetingTherapyMaster = res.filter(x=>x.isHomeInstruction == true && x.gttherapyIsActive == true);
          }
          
          this.onloadFormGroup();
        }
      })
  }
  onClinicianChange(item, id) {
    this._dtoOutputList[id] = this._dtoOutputList[id].clinician;
  }
  getMeetingLocation() {
    this.settingsService.getMeetinglocationGT(this.locationId).subscribe(
      res => {
        this.ddlMeetinglocation = res;
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  postScheduleGroup() {
    this.isFormSubmitted = true;
    // if(this.clinicians.length==0){
    if(this.allClinicians.length==0){
      return;
    }
    //validations
    var isError: Boolean = false;
    this._dtoOutputList.map(data => {
      if (data.dropList.length > 0 || data.tmpList.length > 0) {
        data.tmpList.map(group => {
          var TherapyStartTime = new Date(
            'May 26, 2016 ' + group.startTime
          ).getTime();
          var TherapyEndTime = new Date(
            'May 26, 2016 ' + group.endTime
          ).getTime();
          var TherapyMasterStartTime = new Date(
            'May 26, 2016 ' + this.groupTherapyStartTimeHrs
          ).getTime();
          var TherapyMasterEndTime = new Date(
            'May 26, 2016 ' + this.groupTherapyEndTimeHrs
          ).getTime();
          if (group.endTime == '' || group.endTime == undefined) {
            group.endTimeError = true;
            group.endTimeErrorMessage = "End time is required";
            isError = true;
          }
          if (group.startTime == '' || group.startTime == undefined) {
            group.startTimeError = true;
            group.startTimeErrorMessage = "Start time is required";
            isError = true;
          } else if ((TherapyStartTime < TherapyMasterStartTime) || (TherapyStartTime > TherapyMasterEndTime) || (TherapyEndTime > TherapyMasterEndTime) || (TherapyEndTime < TherapyMasterStartTime)) {

            isError = true;
            group.endTimeError = false;
            group.startTimeError = true;
            group.startTimeErrorMessage = 'Select time between ' + this.groupTherapyStartTimeChange + ' and ' + this.groupTherapyEndTimeChange;
          }
          else if (TherapyStartTime == TherapyEndTime) {
            group.endTimeError = true;
            group.startTimeError = false;
            group.endTimeErrorMessage = "Start time and End time should't be same";
            isError = true;
          }
          else if (TherapyEndTime < TherapyStartTime) {
            group.endTimeError = true;
            group.startTimeError = false;
            group.endTimeErrorMessage = "End time should be greater than start time";
            isError = true;
          }

          if (group.meetingLocationsId == 0 || group.meetingLocationsId == undefined) {
            group.locationError = true;
            isError = true;
          }
          if (group.comments == "" && this.clientType != 3) {
            group.commentsError = true;
            isError = true;
          }
          if (!group.clinician) {
            group.clinicianError = true;
            isError = true;
          }
          if (group.leader.length == 0) {
            group.leaderError = true;
            isError = true;
          }
          // if (group.support.length == 0) {
          //   group.supportError = true;
          //   isError = true;
          // }
        });

        if (data.dropList.length == 0) {
          data.dropListError = true;
          isError = true;
        }

        if (data.selectedTherapy.length == 0) {
          data.therapyError = true;
          isError = true;
        }


      }
    })
    if (isError) {
      //this.snackbar.error(constantVariables.ErrorMessage.SubGroupError)
      return
    }

    if (this.clientType == 1) {
      this.IsPcp = false;
      this.IsIop = true;
      this.IsHomeInstruction = false;
    } else if (this.clientType == 2) {
      this.IsPcp = true;
      this.IsIop = false;
      this.IsHomeInstruction = false;
    }else if (this.clientType == 3)
    {
      this.IsHomeInstruction = true;
      this.IsPcp = false;
      this.IsIop = false;
    }
    var dtoPost = [];
    this._dtoOutputList.map((x, index) => {
      // var dsComments = any;
      var patients = [];
      var therapies = [];
      if (x.dropList.length != 0  && x.tmpList.length != 0 ) {
        var isSave =false;
        var temp = [];
        x.dropList.map((y, index) => {
          var obj = {
            groupTherapyAssignId: y.GroupTherapyAssignId ?? 0,
            dayScheduleAssignId: y.DayScheduleAssignId ?? 0,
            groupTherapyId: this.grpTherapyId ?? 0,
            patientAccountId: y.PatientAccountId,
            patientInfoId: y.PatientInfoId ?? 0,
            dayScheduleSubGroupId: x.dayScheduleSubGroupId ?? 0,
          }

          patients.push(obj)
        });
        x.tmpList.map(z => {
          // if (z.clinician && z.support.length != 0 && z.leader.length != 0) {
            if (z.clinician  && z.leader.length != 0) {
            // var clinician;
            // clinician = +z.clinician.staffId;
            var clinicianString = '';
            z.clinician.map((cli, index) => {
              if (index == 0) {
                clinicianString = cli.staffId.toString()
              } else {
                clinicianString = clinicianString + ',';
                clinicianString = clinicianString + cli.staffId
              }
            })
            var supportString = '';
            z.support.map((sp, index) => {
              if (index == 0) {
                supportString = sp.userId.toString()
              } else {
                supportString = supportString + ',';
                supportString = supportString + sp.userId
              }
            })
            var leaderString = '';
            z.leader.map((ls, index) => {
              if (index == 0) {
                leaderString = ls.userId.toString()
              } else {
                leaderString = leaderString + ',';
                leaderString = leaderString + ls.userId
              }
            })
            
            var objTherapy = {
              groupMeetingTherapyId: z.groupMeetingTherapyId,
              meetingLocationsId: z.meetingLocationsId,
              dayScheduleSubGroupId: x.dayScheduleSubGroupId ?? 0,
              startTime: z.startTime,
              endTime: z.endTime,
              skillTaught: z.comments,
              dayScheduleSubTherapyId: z.dayScheduleSubTherapyId ?? 0,
              clinician: clinicianString,
              leader: leaderString,
              support: supportString,
              skillTaughtMasterId: z.skillTaught ? z.skillTaught.toString() : '',
               gTSortOrder : index + 1,
               gtIntervention : z.gtIntervention,
               DSlength :z.dSlength
            }
            therapies.push(objTherapy);
            isSave = true;
          }
        
        })
        if(isSave){
          var _obj = {
            dayScheduleGroupTherapyId: x.dayScheduleGroupTherapyId ?? 0,
            groupTherapyId: this.grpTherapyId ?? 0,
            dayScheduleSubGroupId: x.dayScheduleSubGroupId ?? 0,
            groupTherapyDate: new Date(this.scheduleDate),
            isPcp: this.IsPcp,
            isIop: this.IsIop,
            isHomeInstruction : this.IsHomeInstruction,
            SfccMasterId: this.locationId,
            patients: patients,
            themeName: "Group " + (index + 1),
            selectedTherapy: therapies,
             gTSortOrder : index + 1,
             gtIntervention : x.gtIntervention,
             dsComments : x.dsComments

          }
          dtoPost.push(_obj);
        }
       
      }
      // else {
      //   this.snackbar.error(constantVariables.ErrorMessage.SubGroupError)
      //   return
      // }
    })
    if (dtoPost.length == 0) {
      this.snackbar.error(constantVariables.ErrorMessage.SubGroupError)
      return;
    }
    this.spinnerservice.show();
    this.commonService.PostGroupMeeting(dtoPost).subscribe((res) => {
      if (res) {
        this.spinnerservice.hide();
        this.snackbar.success(constantVariables.SuccessMessage.DataSaveSuccessfully);
        this._dtoOutputList = [];
        this.OntimeChangeError = false;
        this.isFormSubmitted = false;
        this._dtoGrpTherapyUsers = [];
        this.getDayScheduleForAssign();
      }

    })
  }
  getTherapyValue(item): any {
    return [...item];
  }
  MulticlinicianChange() {
    this._dtoOutputList.map(x => {
      x.tmpList.map(y => {
        // y.clinicians = this.clinicians
        y.clinicians = this.allClinicians
      })
    })
  }
  async onMultiChange(item, therapyId, index) {
    this.spinnerservice.show();
    var skillTaughtnames =await this.templateService.GetSkillTaughtNamesByTherapyList(therapyId.groupMeetingTherapyId,this.clientType,this.locationId);
      //var skillTaughtnames=res;
      var oldData = [...item.tmpList];
      item.tmpList = [];
      item.selectedTherapy.map(x => {
        var data = oldData?.filter(y => y.groupMeetingTherapyId == x.groupMeetingTherapyId)[0];
        var obj = {};
        if (data) {

          obj = {
            groupMeetingTherapyId: data.groupMeetingTherapyId,
            therapyName: data.therapyName,
            startTime: data.startTime,
            endTime: data.endTime,
            meetingLocationsId: data.meetingLocationsId ?? 0,
            dayScheduleSubTherapyId: data.dayScheduleSubTherapyId ?? 0,
            comments: data.comments ?? "",
            clinician: data.clinician,
            leader: data.leader,
            support: data.support,
            startTimeError: false,
            endTimeError: false,
            locationError: false,
            commentsError: false,
            leaderError: false,
            clinicianError: false,
            supportError: false,
            supportData: data.supportData,
            // clinicians: this.clinicians,
            clinicians: this.allClinicians,
            leaderListData: data.leaderListData,
            skillTaughtNames:data.skillTaughtNames,
            skillTaught:data.skillTaught,
            dSlength : data.dSlength

          }
         
        }
        else {
          if(x.isHomeInstruction == true)
          {
            for(let i=0;i<2;i++)
            {
              let objVal = {
                groupMeetingTherapyId: x.groupMeetingTherapyId,
                therapyName: x.therapyName,
                startTime: '',
                endTime: '',
                meetingLocationsId: 0,
                dayScheduleSubTherapyId: 0,
                comments: "",
                clinician: null,
                leader: [],
                support: [],
                startTimeError: false,
                endTimeError: false,
                locationError: false,
                commentsError: false,
                leaderError: false,
                clinicianError: false,
                supportError: false,
                supportData:this.supportInterns,
                // clinicians: this.clinicians,
                clinicians: this.allClinicians,
                leaderListData:[],
                skillTaughtNames:skillTaughtnames,
                dSlength : ''
              }
              var isExist = item.tmpList?.filter(s => s.groupMeetingTherapyId == x.groupMeetingTherapyId);
              if (isExist?.length < 2)
              this._dtoOutputList[index].tmpList.push(objVal);
            }

          }else{
            obj = {
              groupMeetingTherapyId: x.groupMeetingTherapyId,
              therapyName: x.therapyName,
              startTime: '',
              endTime: '',
              meetingLocationsId: 0,
              dayScheduleSubTherapyId: 0,
              comments: "",
              clinician: null,
              leader: [],
              support: [],
              startTimeError: false,
              endTimeError: false,
              locationError: false,
              commentsError: false,
              leaderError: false,
              clinicianError: false,
              supportError: false,
              supportData:this.supportInterns,
              // clinicians: this.clinicians,
              clinicians: this.allClinicians,
              leaderListData:[],
              skillTaughtNames:skillTaughtnames,
              dSlength : ''
            }
           
          }
        }    
          var isExist = item.tmpList?.filter(s => s.groupMeetingTherapyId == x.groupMeetingTherapyId);
          if (isExist?.length == 0)
            this._dtoOutputList[index].tmpList.push(obj);
          
        
      
      })
    // },
    // (err)=>{
    //   this.spinnerservice.hide();
    // });

    // if(id==1){
    //   if(item.clinician){
    //     item.clinicianError=false
    //   }else{
    //     item.clinicianError=true
    //   }
    // }else if(id==2){
    //   if(item.selectedTherapy.length==0){
    //     item.therapyError=true;
    //   }else{
    //     item.therapyError=false;
    //   }
    // }else if(id==3){
    //   if(item.leader==0){
    //     item.leaderError=true;
    //   }else{
    //     item.leaderError=false;
    //   }
    // }
    this.spinnerservice.hide();
  }

  getSkillTaughtNameById(therapyId:any){
    this.templateService.GetSkillTaughtNamesByTherapy(therapyId,this.clientType,this.locationId).subscribe((res)=>{
      return res;          
    },
    (err)=>{
      this.spinnerservice.hide();
    });
  }

  onTimeChange(item, id, i, y) {
    var TherapyStartTime = new Date(
      'May 26, 2016 ' + item.startTime
    ).getTime();
    var TherapyEndTime = new Date(
      'May 26, 2016 ' + item.endTime
    ).getTime();
    var TherapyMasterStartTime = new Date(
      'May 26, 2016 ' + this.groupTherapyStartTimeHrs
    ).getTime();
    var TherapyMasterEndTime = new Date(
      'May 26, 2016 ' + this.groupTherapyEndTimeHrs
    ).getTime();

    if(id==1){
    if ((TherapyStartTime < TherapyMasterStartTime && item.startTime != "") || (TherapyStartTime > TherapyMasterEndTime && item.startTime != "") || (TherapyEndTime > TherapyMasterEndTime && item.endTime != "") || (TherapyEndTime < TherapyMasterStartTime && item.endTime != "")) {
      this.OntimeChangeError = true;
      this._dtoOutputList[i].tmpList[y].startTimeError = true;
      this._dtoOutputList[i].tmpList[y].endTimeError = false;
      this._dtoOutputList[i].tmpList[y].startTimeErrorMessage = 'Select time between ' + this.groupTherapyStartTimeChange + ' and ' + this.groupTherapyEndTimeChange;
    }
    else if (TherapyStartTime == TherapyEndTime && item.staffId != "" && item.endTime != "") {
      this.OntimeChangeError = true;
      this._dtoOutputList[i].tmpList[y].endTimeError = true;
      this._dtoOutputList[i].tmpList[y].startTimeError = false;
      this._dtoOutputList[i].tmpList[y].endTimeErrorMessage = "Start time and End time should't be same"
    }
    else if (TherapyEndTime < TherapyStartTime && item.endTime != "" && item.staffId != "") {
      this.OntimeChangeError = true;
      this._dtoOutputList[i].tmpList[y].endTimeError = true;
      this._dtoOutputList[i].tmpList[y].startTimeError = false;
      this._dtoOutputList[i].tmpList[y].endTimeErrorMessage = "End time should be greater than start time"
    }
    else {
      item.startTimeError = false;
      this.OntimeChangeError = false;
    }
    // this.totalTime = TherapyStartTime + TherapyEndTime;
  }
  if(id==2){
    if ((TherapyStartTime < TherapyMasterStartTime && item.startTime != "") || (TherapyStartTime > TherapyMasterEndTime && item.startTime != "") || (TherapyEndTime > TherapyMasterEndTime && item.endTime != "") || (TherapyEndTime < TherapyMasterStartTime && item.endTime != "")) {
      this.OntimeChangeError = true;
      this._dtoOutputList[i].tmpList[y].startTimeError = true;
      this._dtoOutputList[i].tmpList[y].endTimeError = false;
      this._dtoOutputList[i].tmpList[y].startTimeErrorMessage = 'Select time between ' + this.groupTherapyStartTimeChange + ' and ' + this.groupTherapyEndTimeChange;
    }
    else if (TherapyStartTime == TherapyEndTime && item.staffId != "" && item.endTime != "") {
      this.OntimeChangeError = true;
      this._dtoOutputList[i].tmpList[y].endTimeError = true;
      this._dtoOutputList[i].tmpList[y].startTimeError = false;
      this._dtoOutputList[i].tmpList[y].endTimeErrorMessage = "Start time and End time should't be same"
    }
    else if (TherapyEndTime < TherapyStartTime && item.endTime != "" && item.staffId != "") {
      this.OntimeChangeError = true;
      this._dtoOutputList[i].tmpList[y].endTimeError = true;
      this._dtoOutputList[i].tmpList[y].startTimeError = false;
      this._dtoOutputList[i].tmpList[y].endTimeErrorMessage = "End time should be greater than start time"
    }
    else {
      this.OntimeChangeError = false;
      item.endTimeError = false;
    }
  }
    if (this.OntimeChangeError == false) {
      if (this._dtoOutputList[i].tmpList.length > 0) {
        this._dtoOutputList[i]?.tmpList.map((data, index) => {
          if (index != y) {
            var dataStartTime = new Date(
              'May 26, 2016 ' + data.startTime
            ).getTime();
            var dataEndTime = new Date(
              'May 26, 2016 ' + data.endTime
            ).getTime();
            if (((data?.startTime > item?.startTime) && (data?.startTime < item?.endTime)) ||
              ((data?.endTime > item?.startTime) && (data?.endTime < item?.endTime)) ||
              ((item?.startTime > data?.startTime) && (item?.startTime < data?.endTime)) ||
              ((item?.endTime > data?.startTime) && (item?.startTime < data?.endTime))) {
              this.OntimeChangeError = true;
              item.startTimeError = true;
              item.startTimeErrorMessage = item?.therapyName + " time is conflicted with the " + data?.therapyName + " time"
            }
          }
        });
      }
    }
    var timeDifference = TherapyEndTime - TherapyStartTime;
    var minutes = Math.floor(timeDifference / (1000 * 60));
    this._dtoOutputList[i].tmpList[y].dSlength = this.convertMinutesToHoursAndMinutes(minutes);


  }
  convertMinutesToHoursAndMinutes(minutes) {
    if (minutes < 60) {
        return minutes + " min";
    } else {
        var hours = Math.floor(minutes / 60);
        var remainingMinutes = minutes % 60;
        return hours + " hr " + remainingMinutes + " min";
    }
}
  arrayFunction(id) {
    return this._dtoDropListCount.filter((e, i) => i !== id);
  }
  createGroup() {
    if (this._dtoDropListCount.length <= 5) {
      var x = this._dtoDropListCount.length;
      this._dtoDropListCount.push((x + 1).toString())
      this._dtoOutputList[x - 1] = []
      this._dtoOutputList[x - 1].dropList = []
      this._dtoOutputList[x - 1].clinician = []
      this._dtoOutputList[x - 1].leader = {}
      this._dtoOutputList[x - 1].selectedTherapy = []
      this._dtoOutputList[x - 1].leaderError = false
      this._dtoOutputList[x - 1].dropListError = false
      this._dtoOutputList[x - 1].therapyError = false
      this._dtoOutputList[x - 1].clinicianError = false
      this._dtoOutputList[x - 1].startTime = ''
      this._dtoOutputList[x - 1].endTime = ''
      this._dtoOutputList[x - 1].startTimeError = false
      this._dtoOutputList[x - 1].endTimeError = false
    }
  }
  removeTherapy(val, y, i) {
    if(this.clientType == 3 && this._dtoOutputList[i].tmpList.length > 1)
    {
      this._dtoOutputList[i].tmpList = this._dtoOutputList[i].tmpList.filter(obj => { return obj !== val });
    }else{
      this._dtoOutputList[i].selectedTherapy = this._dtoOutputList[i].selectedTherapy.filter(obj => { return obj.groupMeetingTherapyId !== val.groupMeetingTherapyId });
      this._dtoOutputList[i].tmpList = this._dtoOutputList[i].tmpList.filter(obj => { return obj !== val });
    }
    
  }
  getAllSkillTaughtQuestionsBasedOnTherapies(data:any){    
    this.templateService.GetSkillTaughtQuestionByTherapyIds(this.commonService.DataToEncrypt(data.skillTaught,true)).subscribe((res)=>{
      var result=res;
      var bulletedText='';
      result.forEach(element => {
        var text = '•'+element.skillTaughtQuestion+'\n';
        bulletedText+=text;
      });
      data.comments=bulletedText;   
    },
    (err)=>{
      this.spinnerservice.hide();
    })
  }
  onGeneratePDF(item){
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
      this.clinicianservice.getDailySchedudlepdf(this.grpTherapyId, moment(this.scheduleDate).format("MM/DD/YYYY"),this.clinicianId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdfDailySchedule= res.filePath;
          if(item==1){
            window.open(this.pdfDailySchedule, '_blank');
          } 
          if(item==0){
            const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
            this.downloadLink.nativeElement.href = res.base64File;
            this.downloadLink.nativeElement.download = res.fileName;
            this.downloadLink.nativeElement.click();
          }
         
        },
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  drops(event: CdkDragDrop<string[]> ,i,y) {  
    this.dragDisabled = true;

     //const previousIndex = this._dtoOutputList[i].tmpList[y].findIndex((d) => d === event.item.data);

    moveItemInArray(this._dtoOutputList[i].tmpList, event.previousIndex, event.currentIndex);
    //  this.table.renderRows();
    var gtDataList = this._dtoOutputList[i].tmpList
    this.treatmentservice.postGTListOrder(gtDataList).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message)
        //this.ddlFormName = JSON.parse(res);
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  
  onChangePresentorAbsent(item) {
    
    // this.IsActivePresent = true
    this.spinnerservice.show();
    if(item.PresentorAbsentMarked) {
      item.PresentorAbsentMarked = false;
    } else {
      item.PresentorAbsentMarked = true;
    }
    var _obj = {
      PresentorAbsent : item.PresentorAbsentMarked,
      PatientAccountId : item.PatientAccountId 
    }
    this.clinicianservice.PostGroupTherapyPresentorAbsent(_obj).subscribe(
      (res: any) => {
        this.snackbar.success(res);
       
        // if(this._dtoGrpTherapyUsers.PresentorAbsent == true) {
        //   this.snackbar.success("Client marked as present");
        // }
        // else{
        //   this.snackbar.success("Client marked as absent");

        // }
        this.spinnerservice.hide();
      });
  }

}



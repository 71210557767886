<div class="row w-100">
  <div class="col-md-12 px-4">

    <button type="submit" class="btn primary-btn-outlined-small ng-star-inserted float-right" (click)="generatePdf()">
      Generate PDF
    </button>
  </div>
  <div class="col-md-12">
    <form (ngSubmit)="frmConsent.form.valid  && postTreatmentConsent(frmConsent)" id="frmConsent" autocomplete="off"
      #frmConsent="ngForm" novalidate [ngClass]="{'disabled': isConsentFormDisabled}">
      <div class="col-md-12">
        <div class="basic-info mb-3 row">
          <label class="treat-label  mt-3"> Referrals to other services not provided by Stepping Forward
            Counseling Center:</label>

         

          <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
        <textarea cols="2" matInput appTextareaAutoresize class="w-100" [(ngModel)]="_dtoConsent.referralcomments"
          #referralcomments="ngModel" name="referralcomments" maxlength="3000"></textarea>
      </mat-form-field> -->
          <app-word-counter-textarea class="w-100" [text]="_dtoConsent.referralcomments" [maxlength]="3000"
            [(ngModel)]="_dtoConsent.referralcomments" (textChange)="_dtoConsent.referralcomments = $event"
            #referralcomments="ngModel" name="referralcomments" ngDefaultControl></app-word-counter-textarea>
          <!-- <mat-error *ngIf="frmDiagnosis.submitted && referralcomments.invalid">
        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="referralcomments.errors.required">
          Diagnosis details is
          required</mat-error>
      </mat-error> -->

        </div>
      </div>
      <div class="col-md-12">
        <div class="basic-info mb-3 row">
          <label class="treat-label  mt-3 w-100">Discharge Goals/ Criteria:</label>

          <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
        <textarea cols="2" matInput appTextareaAutoresize class="w-100" [(ngModel)]="_dtoConsent.dischargeGoal"
          #dischargeGoal="ngModel" name="dischargeGoal" maxlength="3000"></textarea>
      </mat-form-field> -->
          <app-word-counter-textarea class="w-100" [text]="_dtoConsent.dischargeGoal" [maxlength]="3000"
            [(ngModel)]="_dtoConsent.dischargeGoal" (textChange)="_dtoConsent.dischargeGoal = $event"
            #dischargeGoal="ngModel" name="dischargeGoal" ngDefaultControl></app-word-counter-textarea>
          <!-- <mat-error *ngIf="frmDiagnosis.submitted && referralcomments.invalid">
        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="referralcomments.errors.required">
          Diagnosis details is
          required</mat-error>
      </mat-error> -->

        </div>
      </div>
      <div class="col-md-12">
        <div class="basic-info mb-3 row">
          <label class="treat-label  mt-3">Client’s response/ preferences to Goals, Objectives, and
            Interventions:</label>

            <div style="padding-left: 10px;" class="mt-3">
              <a title="Add update" (click)="showCommentsPopupFun(9)">
                <img src="../../../assets/images/editblue.png">
              </a>

            </div>
            <div style="padding-left: 10px;" class="mt-3 pt-1">
              <a title="View updates" (click)="ViewComments(9)">
                <i class="fas fa-history mr-2" style="color:rgb(10, 10, 133);font-size: 20px"></i>
              </a>
            </div>

          <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
        <textarea cols="2" class="w-100" matInput appTextareaAutoresize [(ngModel)]="_dtoConsent.goalResponse"
          #goalResponse="ngModel" name="goalResponse" maxlength="3000"></textarea>
      </mat-form-field> -->
          <app-word-counter-textarea class="w-100" [text]="_dtoConsent.goalResponse" [maxlength]="3000"
            [(ngModel)]="_dtoConsent.goalResponse" (textChange)="_dtoConsent.goalResponse = $event"
            #goalResponse="ngModel" name="goalResponse" ngDefaultControl></app-word-counter-textarea>
          <!-- <mat-error *ngIf="frmDiagnosis.submitted && referralcomments.invalid">
        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="referralcomments.errors.required">
          Diagnosis details is
          required</mat-error>
      </mat-error> -->

        </div>
       
      </div>
      <div class="col-md-3">
        <div class="basic-info mb-3 row">
          <label class="treat-label  mt-3 w-100"> Treatment Team/Outside Provider Names</label>

          <mat-form-field class="example-full-width custom-textarea mt-2 ">
            <textarea cols="2" class="w-100" matInput appTextareaAutoresize maxlength="100"
              [(ngModel)]="DiagnosisProvider" name="DiagnosisProvider"></textarea>
          </mat-form-field>


        </div>
      </div>

      <div class="col-md-12 mt-3 pl-0">
        <div class=" form-group clearfix">
          <div class="float-left">
            <label class="treat-label">Provider’s Signature with Credentials</label>
          </div>
        </div>
      </div>
      <div class="col-md-12 pl-0">
        <div class="form-group clearfix m-0">
          <div class="float-left col-md-4 p-0">
            <mat-form-field class="w-100">
              <mat-label>Provider’s Name (printed)</mat-label>
              <input matInput [(ngModel)]="userName" #providerName="ngModel" name="providerName">
            </mat-form-field>
          </div>

          <div class="float-right col-md-2 p-0">
            <!-- <mat-form-field class="w-100">
          <mat-label>Date</mat-label>

          <input matInput [matDatepicker]="picker" [min]="currentDate" readonly (click)="picker.open()"
            [(ngModel)]="_dtoConsent.providerDate" #providerDate="ngModel" name="providerDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> -->
          </div>
        </div>
      </div>
      <div class="col-md-12 pl-0 ">
        <div class="form-group clearfix m-0">
          <div class="float-left col-md-4 p-0 mt-3 pt-1">
            <label class="treat-label">Provider's signature</label>
            <img id='esign_1' height="50px" width="300px"  style="border: 1px solid grey;">
            <button type="button" (click)="openDialog(1)" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"
              type="button" >
              Add E-Sign
            </button>
          </div>

          <div class="float-right col-md-2 p-0">
            <mat-form-field class="w-100">
              <mat-label>Date</mat-label>

              <input matInput [matDatepicker]="picker1" readonly (click)="picker1.open()"
                [(ngModel)]="_dtoConsent.minorDate" #minorDate="ngModel" name="minorDate" />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-12 pl-0 mt-5">
        <div class="form-group clearfix m-0">
          <div class="check-custom">
            <mat-checkbox class="example-margin" [checked]="isPsychiatristSignRequired" (change)="OnCheckboxclick()">
              <label class="mt-2">Add Psychiatric Signature</label>
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="col-md-12 pl-0" [ngClass]="{'d-none' : !isPsychiatristSignRequired}">
        <div class="form-group clearfix m-0">
          <div class="float-left col-md-4 p-0 mt-3 pt-1">
            <label class="treat-label">Psychiatrist’s Signature</label>
            <img id='esign_2' height="50px" width="300px"  style="border: 1px solid grey;">
            <button type="button" (click)="openDialog(2)" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"
              type="button" *ngIf="!isConsentFormDisabled">
              Add E-Sign
            </button>
          </div>

          <div class="float-right col-md-2 p-0">
            <mat-form-field class="w-100">
              <mat-label>Date</mat-label>

              <input matInput [matDatepicker]="picker2" (click)="picker2.open()"
                [(ngModel)]="_dtoConsent.psychiatristsDate" #psychiatristsDate="ngModel" name="psychiatristsDate" />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-- newly not mandatory added sign -->
      <!-- <div class="col-md-12">
  <div  class="form-group clearfix m-0">
  <div  class="float-left col-md-4 p-0 mt-3 pt-1">
    <label  class="treat-label mr-2">Secondary Signature</label>
    <img  id="esign_5" height="50px" width="300px"  style="border: 1px solid grey;">
    <img  id="esign_5" height="50px" width="300px" src="{{_dtoConsent.staffInitial1}}" style="border: 1px solid grey;">
    <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" 
    (click) = "openDialog(5)" > Add E-Sign </button>
    <div class="float-left col-md-4 p-0 mt-4 pt-1" >
   </div>
  </div>
</div>
</div> -->
      <div class="col-md-12">
        <div class="form-group clearfix m-0">
          <div class="float-left col-md-4 p-0 mt-3 pt-1">
            <label class="treat-label">Secondary Signature</label>
            <img id='esign_5' height="50px" width="300px"  style="border: 1px solid grey;">
            <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" [disabled]="secondarySign()" (click)="openDialog(5)" > Add E-Sign </button>
          </div>
        </div>
      </div>
      <!-- newly not mandatory sign end-->




      <div class="col-md-12 sep-section pb-3 pl-0">
        <div class="col-md-12  p-3">
          <div class="row mb-2">
            <label class="col-md-12 set-label">I have participated in the development of the treatment plan for myself
              and or
              my
              child</label>
            <div class="col-md-12 custom-radio">
              <!--<label class="mr-2 font-15">
          <input type="radio" [(ngModel)]="_dtoConsent.isAgree" #isAgree="ngModel" name="isAgree1" class="medium-radio">
          I Agree </label>
        <label class="mr-2 font-15">
          <input type="radio" [(ngModel)]="_dtoConsent.isAgree" #isAgree="ngModel" name="isAgree2" class="medium-radio">
          I Disagree </label> -->
              <mat-radio-group [(ngModel)]="_dtoConsent.isAgree" #isAgree="ngModel" name="isAgree">
                <mat-radio-button [value]='true' class="mr-3 my-3"> Agree</mat-radio-button>
                <mat-radio-button [value]='false'> Disagree</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
      <textarea cols="2" matInput appTextareaAutoresize class="w-100" [(ngModel)]="_dtoConsent.comments"
        #comments="ngModel" name="comments" maxlength="3000" placeholder="Comments"></textarea>
    </mat-form-field> -->
          <app-word-counter-textarea class="w-100" [text]="_dtoConsent.comments" [maxlength]="3000"
            [(ngModel)]="_dtoConsent.comments" (textChange)="_dtoConsent.comments = $event" #comments="ngModel"
            name="comments" ngDefaultControl></app-word-counter-textarea>
        </div>
        <div class="col-md-12 ">
          <div class="form-group clearfix m-0">
            
            <div class="float-left col-md-4 p-0 mt-3 pt-1">
              <label class="treat-label">Client Signtaure</label>
              <img id='esign_3' height="50px" width="300px">
              <button type="button" (click)="openDialog(3)" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"
                type="button" *ngIf="!isConsentFormDisabled">
                Add E-Sign
              </button>
            </div>

            <div class="float-right col-md-2 p-0">
              <mat-form-field class="w-100">
                <mat-label>Date</mat-label>
                <input matInput [matDatepicker]="picker3" readonly (click)="picker3.open()"
                  [(ngModel)]="_dtoConsent.clientDate" #clientDate="ngModel" name="clientDate" />
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-12 ">
          <div class="form-group clearfix m-0">
            <div class="float-left col-md-4 p-0 mt-3 pt-1">
              <label class="treat-label">Parent/Guardian</label>
              <img id='esign_4' height="50px" width="300px"  style="border: 1px solid grey;">
              <button type="button" (click)="openDialog(4)" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"
                type="button" *ngIf="!isConsentFormDisabled">
                Add E-Sign
              </button>
            </div>

            <div class="float-right col-md-2 p-0">
              <mat-form-field class="w-100">
                <mat-label>Date</mat-label>
                <input matInput [matDatepicker]="picker4" readonly (click)="picker4.open()"
                  [(ngModel)]="_dtoConsent.parentDate" #parentDate="ngModel" name="parentDate" />
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <mat-datepicker #picker4></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="roleAccess.isCreate||roleAccess.isEdit">
        <div class="text-right">

          <button type="submit" form="frmConsent" mat-stroked-button
            class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>

        </div>
      </div>

    </form>
  </div>
</div>


<!-- Recommdations PopUp Starts -->
<div class="comment-popup re-schedule-popup Treatment-popup ">
  <div class="modal" tabindex="-1" role="dialog" id="blockTime"
    [style.display]="showRecommandationPopup? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container" role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h6 class="modal-title">{{ title }}</h6>
          <a class="cursor-pointer" (click)="OncloseRecommandationpopup()"><i class="fa fa-times"></i></a>
        </div>
        <div class="modal-body">
          <div class="row" *ngIf="_dtoComments.length > 0">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-1 font-weight-bold"><label>S.No</label></div>
                <div class="col-md-6 font-weight-bold"><label>Updates</label></div>
                <div class="col-md-3 font-weight-bold"><label>Date</label></div>
                <div class="col-md-2 font-weight-bold"><label>Action</label></div>
            </div>

              <div *ngFor="let med of _dtoComments; let i = index; let last = last" class="row"
                style="margin-bottom: 10px;">
                <div class="col-md-1">
                  <label>{{ i + 1 }}</label>
                </div>
                <div class="col-md-6">
                  {{ med.commentDetails }}
                </div>
                <div class="col-md-3">
                  <!-- {{ med.updatedDate | date: 'MM/dd/yyyy' }} -->
                  {{ med.createdDate | date: 'MM/dd/yyyy' }}

                </div>
                <div class="col-md-2">
                  <div >
                      <button type="button" mat-icon-button matTooltip="Edit"  color="primary" (click)="editCommentPopup(med.treatmentPlanCommentsId)">
                      <img src="../../../assets/images/editblue.png">
                    </button>
                    <button mat-icon-button matTooltip="Delete" color="warn" (click)="ondeleteTreatmentPlanComments(med.treatmentPlanCommentsId)">
                      <img src="../../../assets/images/deletered.png">
                    </button>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>

          <div class="col-md-12 set1-temp" *ngIf="_dtoComments.length === 0">
            <div class="col-md-12 row">
              <div class="col-md-4 p-0">
                <label class="additional"></label>
              </div>
              <div class="col-md-4 p-0">
                <label class="additional">{{NoRecord}}</label>
                <div class="col-md-4 p-0">
                  <label class="additional"></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Comments PopUp Starts-->
<div class="comment-popup re-schedule-popup Treatment-popup ">
  <div class="modal" tabindex="-1" role="dialog" id="blockTime" [style.display]="showCommentsPopup? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container" role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h6 class="modal-title">{{ Addtitle }}</h6>
          <a class="cursor-pointer" (click)="OncloseCommentpopup(notedialogfrm)"><i class="fa fa-times"></i></a>
        </div>
        <div class="modal-body">

          <form (ngSubmit)="notedialogfrm.form.valid  && postTreatmentPlanComments(notedialogfrm)" id="notedialogfrm"
            autocomplete="off" #notedialogfrm="ngForm" novalidate>
            <div class="col-md-12">
              <div class="row">
              
                <mat-form-field class="w-100">
                  <mat-label class="">Date</mat-label>
                  <input matInput [matDatepicker]="pstpicker" readonly
                      (click)="pstpicker.open()" [(ngModel)]="_dtoTreatmetComments.commentUpdatedate"
                       name="commentUpdatedate" #commentUpdatedate="ngModel" required
                       [ngClass]="{ 'is-invalid': notedialogfrm.submitted && commentUpdatedate.invalid }"
                       >
                  <mat-datepicker-toggle matSuffix [for]="pstpicker">
                  </mat-datepicker-toggle>
                  <mat-datepicker #pstpicker></mat-datepicker>
                  <mat-error *ngIf="notedialogfrm.submitted && commentUpdatedate.invalid">
                    <mat-error *ngIf="commentUpdatedate.errors.required">Date is required</mat-error>
                  </mat-error>
              </mat-form-field>
                <mat-form-field class="w-100">
                  <input matInput placeholder="Add Update" [(ngModel)]="_dtoTreatmetComments.commentsName"
                    #commentsName="ngModel" name="commentsName" autocomplete="new-password"
                    [ngClass]="{ 'is-invalid': notedialogfrm.submitted && commentsName.invalid }" required>

                  <mat-error *ngIf="notedialogfrm.submitted && commentsName.invalid">
                    <mat-error *ngIf="commentsName.errors.required">Update is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 text-right mt-2">

                  <button type="submit" mat-raised-button
                    class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>
                </div>
              </div>
            </div>


          </form>


        </div>
      </div>
    </div>
  </div>
</div>

<a mimetype="application/octet-stream" #downloadLink></a>

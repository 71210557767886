
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { Global } from 'src/app/global/global.model';
import { constantVariables } from 'src/app/constant/constant';
import * as moment from 'moment';
@Component({
  selector: 'app-schedule-grptherapy',
  templateUrl: './schedule-grptherapy.component.html',
  styleUrls: ['./schedule-grptherapy.component.scss']
})
export class ScheduleGrptherapyComponent implements OnInit {
  dtoGrpTherapyMeta: any
  grpTherapyid: any;
  patientAccountId: any;
  _dtoGrpTherapy: any;
  _dtoGrpTherapyDetails: any;
  _dtoSlots: any = [];
  statusbooked = constantVariables.status.booked;
  releaseDate: any;
  assignDate: any;
  isShowAssign: any;
  isReqAssignTherapy: boolean = false;
  constructor(public global: Global,
    private snackbar: SnackBarService, private route: ActivatedRoute, private router: Router, public commonService: CommonService, public clinicianservice: ClinicianService, public spinnerservice: NgxSpinnerService, public treatmentservice: TreatmentService,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.dtoGrpTherapyMeta = JSON.parse(this.commonService.FrontEndDecryption(params.id));
        this.grpTherapyid = this.dtoGrpTherapyMeta.id
        this.patientAccountId = this.dtoGrpTherapyMeta.patient
        this.getGroupTherapyDetails();

      }

    });

  }

  getGroupTherapyDetails() {
    this.spinnerservice.show();

    this.treatmentservice.getGroupTherapyDetails(this.grpTherapyid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          this._dtoGrpTherapy = res;
          //this._dtoSlots = res.dtoGroupTherapyAssigns;
          this.pushSlots(this._dtoGrpTherapy)
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  pushSlots(item) {
    this._dtoSlots = [];
    let objTherapy = item.dtoGroupTherapyAssigns.filter(x => x.status == this.statusbooked)
    if (objTherapy.length > 0) {
      for (let i = 0; i < objTherapy.length; i++) {
        let obj = {
          groupTherapyAssignId: objTherapy[i].groupTherapyAssignId,
          slotNumber: objTherapy[i].slotNumber,
          groupTherapyId: objTherapy[i].groupTherapyId,
          patientAccountId: objTherapy[i].patientAccountId,
          name: objTherapy[i].patientName,
          // treatmentStartDate: item.dtoGroupTherapyAssigns[i].treatmentStartDate,
          daysleft: moment(this.dtoGrpTherapyMeta.treatmentPlanDate).add(90, 'days').diff(new Date(), 'days'),
          summary: moment(this.dtoGrpTherapyMeta.treatmentPlanDate).add(90, 'days').format('LL'),
          status: objTherapy[i].status
        }
        this._dtoSlots.push(obj);
      }
    }
    for (let i = 0; i < this._dtoGrpTherapy.capacity - item.dtoGroupTherapyAssigns.length; i++) {

      let obj = {
        groupTherapyAssignId: 0,
        slotNumber: i + 1,
        groupTherapyId: this.grpTherapyid,
        patientAccountId: this.patientAccountId,
        name: "Available",
        treatmentStartDate: this.dtoGrpTherapyMeta.treatmentPlanDate,
        status: 0
      }
      this._dtoSlots.push(obj);


    }

  }
  onAssignClick(i) {
    this.isShowAssign = i
  }
  onbookSlots(item) {
    let objTherapy = this._dtoGrpTherapy.dtoGroupTherapyAssigns.filter(x => x.patientAccountId == this.patientAccountId && x.status == this.statusbooked)
    if (objTherapy.length > 0) {
      this.snackbar.error(constantVariables.ErrorMessage.slotbooked + " " + objTherapy[0].patientName);
      return
    }
    if (this.assignDate == undefined || this.releaseDate == undefined) {
      this.isReqAssignTherapy = true;
      return
    }
    if (!moment(this.assignDate).isBefore(moment(this.releaseDate))) {
      return
    }
    item.groupTherapyId = Number(item.groupTherapyId);
    item.assignDate = this.assignDate.toDate();
    item.releaseDate = this.releaseDate.toDate();
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(item, false);
    this.treatmentservice.postBookSlotGrpTherapy(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.getGroupTherapyDetails();
        this.snackbar.success(JSON.parse(res).message);
        this.onScheduleModality();
        this.isReqAssignTherapy = false;
        this.assignDate = undefined;
        this.releaseDate = undefined;
      },
      err => {
        this.spinnerservice.hide();

      },
    );

  }

  onCancelSlots(item) {
    item.status = 22;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(item, false);
    this.treatmentservice.postBookSlotGrpTherapy(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.getGroupTherapyDetails();
        this.snackbar.success(JSON.parse(res).message);

      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  onScheduleModality() {
    this.router.navigate(['/schedule-modality'], { queryParams: { id: this.commonService.FrontEndEncryption(this.dtoGrpTherapyMeta.chmaTreatmentSummaryId), account: this.commonService.FrontEndEncryption(this.dtoGrpTherapyMeta.patientAccountId), patient: this.commonService.FrontEndEncryption(this.dtoGrpTherapyMeta.patientInfoId), treatmentPlanDate: this.dtoGrpTherapyMeta.treatmentPlanDate } });
  }

}

import { Injectable } from '@angular/core';
import * as _ from 'smartystreets-javascript-sdk';

@Injectable({
    providedIn: 'root'
})
export class addressslookup {

    constructor() { }

    AutocompleteAddress(term: string): any {        
        const SmartyStreetsCore = _.core;       
        const websiteKey = '106074381505273825'; 
        const Lookup = _.usAutocompletePro.Lookup;
        const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
        const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials);
        const client = clientBuilder.buildUsAutocompleteProClient();
        const lookup = new Lookup(term);
        return client.send(lookup);
    }

}
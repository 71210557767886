<div class="price-cat ">
    <!-- priceCatform.form.valid  && -->
    <form id="priceCatform" (ngSubmit)="priceCatform.form.valid  && postPriceCatalogue(priceCatform)" autocomplete="off"
        #priceCatform="ngForm" novalidate>
        <div class="top-btn-section clearfix mb-1 w-100">
            <h2 class="head-section mb-2 mt-1">Create Price Catalogue</h2>
            <div class=" col-md-6 ml-auto mt-2 button-position pr-0">
                <!-- <button type="submit" (click)="onsavecataClick()" class="btn primary-btn-outlined mr-2 float-right"><i
                        class="far fa-save pr-2"></i>Save
                    Catalogue</button> -->

                <button type="button" (click)="resetPriceCatalouge()"
                    class="btn primary-btn-outlined float-right mr-2"><i class="fas fa-redo mr-2"></i>Reset</button>
                <button type="button" (click)="onbacktopricelist()" class="btn primary-btn-outlined float-right mr-2"><i
                        class="fas fa-angle-left pr-2"></i>Back</button>

            </div>
        </div>
        <div class="lead-box clearfix pt-3">

            <!-- (ngSubmit)="priceCatform.form.valid  && postPriceCatalogue(priceCatform)" -->

            <div class="col-md-12 ">

                <div class="row">
                    <div class="col-md-4">
                        <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="w-100">
                                <mat-label>Catalogue Name</mat-label>
                                <input matInput maxlength="30" appAlphabetOnly
                                    [(ngModel)]="priceCatalogue.catalogueName" #catalogueName="ngModel"
                                    name="catalogueName"
                                    [ngClass]="{ 'is-invalid': priceCatform.submitted && catalogueName.invalid }"
                                    required>
                                <mat-error *ngIf="priceCatform.submitted && catalogueName.invalid">
                                    <mat-error *ngIf="catalogueName.errors.required">Catalogue Name is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- <div class="col-md-6">
                            <mat-form-field class="w-100">
                                <mat-label>Transaction type</mat-label>
                                 <mat-select   name="TTypeId">
                                 <mat-option *ngFor="let ttyp of transactionType" [value]="ttyp.Tid">{{ttyp.TtypeDescription}}</mat-option>
                                </mat-select>
                             </mat-form-field>
                         </div> -->
                    </div>
                     <!-- <div class="row">
                        <div class="col-md-6">
                            <div class=" mt-3 custom-radio">
                                <mat-radio-group aria-label="Select an option">
                                    <mat-radio-button value="1" class="mr-4">CPT code</mat-radio-button>
                                    <mat-radio-button value="2">UB04</mat-radio-button>
                                  </mat-radio-group>
                            </div>
                        </div> -->
                        <!-- <div class="col-md-6">
                            <mat-form-field class="w-100">
                            <mat-label>CPT Code</mat-label>
                             <mat-select   name="cpt">
                                <mat-option *ngFor="let cp of cpt" [value]="cp.CptCode">{{cp.CptCode}} - {{cp.CptcodeDescription}}</mat-option>
                               </mat-select>
                            </mat-form-field>

                        </div> 
                    </div>-->

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="w-100">
                                <mat-label>Program Type</mat-label>
                                <mat-select [(ngModel)]="priceCatalogue.programType" #programType="ngModel"
                                    name="programType"
                                    [ngClass]="{ 'is-invalid': priceCatform.submitted && programType.invalid }"
                                    required>
                                    <mat-option *ngFor="let item of ddlProgramTypes" [value]="item.programTypesId">
                                        {{item.programName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="priceCatform.submitted && programType.invalid">
                                    <mat-error *ngIf="programType.errors.required">Program Type is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- <div class="col-md-6">
                            <mat-form-field class="w-100">
                                <mat-label>Types of services</mat-label>
                                   <mat-select   name="typeofServices">
                                    <mat-option *ngFor="let ts of typeofServices" [value]="ts.TsCode">{{ts.TypeofService}}</mat-option>
                                   </mat-select>
                                </mat-form-field>
                        </div> -->
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="w-100">
                                <mat-label>Unit</mat-label>
                                 <input matInput maxlength="2" [(ngModel)]="Units" customMin="1"
                                     numeric  name="Unit" required/>
                                 </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="w-100">
                                <mat-label>Amount</mat-label>
                                <input matInput maxlength="4" [(ngModel)]="Amount" customMin="2"
                                numeric  name="Amount" required/>
                              </mat-form-field>
                        </div>
                    </div> -->
                    <div class="row">

                        <div class="col-md-12">
                            <div class="row">


                                <div class="col-md-8">
                                    <mat-form-field class="w-100">
                                        <mat-label>Location</mat-label>
                                        <mat-select multiple [disabled]="isGlobalAccess"
                                            [(ngModel)]="priceCatalogue.sfccmasterId" #sfccmasterId="ngModel"
                                            name="sfccmasterId" (change)="locationchange($event)"
                                            [ngClass]="{ 'is-invalid': priceCatform.submitted && sfccmasterId.invalid }"
                                            [required]="!isGlobalAccess">
                                            <mat-option *ngFor="let item of ddllocation" [value]="item.sfccMasterId">
                                                {{item.locationName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="priceCatform.submitted && sfccmasterId.invalid">
                                            <mat-error *ngIf="sfccmasterId.errors.required">Location is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>

                                </div>
                                <div class="col-md-4">
                                    <div class="check-custom w-100 mt-4">
                                        <mat-checkbox class="example-margin" [(ngModel)]="priceCatalogue.accessLevel"
                                            (change)="onchangeAccesslvl($event)" name="accessLevel">
                                            Global
                                        </mat-checkbox>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-8">
                        <label class="control-label-new">Description</label>
                        <div class="col-md-12 p-0">
                            <textarea class="custom-textarea-new-large price-textarea" [(ngModel)]="priceCatalogue.description"
                                maxlength="300" #description="ngModel" name="description"
                                [ngClass]="{ 'is-invalid': priceCatform.submitted && description.invalid }"
                                required></textarea>
                            <mat-error *ngIf="priceCatform.submitted && description.invalid">
                                <mat-error class="mat-form-field-subscript-wrapper" *ngIf="description.errors.required">
                                    Description is required</mat-error>
                            </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix" *ngIf="!isshowPriceComponent">
                <div class="no-price" *ngIf="!iseditCatalogue">
                    Add New Price Components <button type="button" (click)="addPriceComponent(1)"
                        class="btn btn-theme ml-2"><i class="fas fa-plus-circle ml-2 mr-2"></i>Add</button>
                </div>
            </div>
            <div class="col-md-12 mt-2 center-bg" *ngIf="isshowPriceComponent">

                <div class="yes-section">
                    <div class="text-right col-md-12  p-0 ">
                        <div class="row">
                            <div class="col-md-9 text-left">
                                <div class="cover-option">
                                    <span class="small-heading-label align-middle">Insurance Coverage</span>

                                    <span class="ml-3 yes-no-insurance">
                                        <mat-radio-group [(ngModel)]="priceComponent.isInsuranceCoverage"
                                            #isInsuranceCoverage="ngModel" name="isInsuranceCoverage" required>
                                            <mat-radio-button [value]='true' class="mr-3 my-3"
                                                (change)="onChangeInsCoverage(1)"> Yes
                                            </mat-radio-button>
                                            <mat-radio-button [value]='false' (change)="onChangeInsCoverage(0)"> No
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <mat-error *ngIf="priceCatform.submitted && isInsuranceCoverage.invalid">
                                            <mat-error class="" *ngIf="isInsuranceCoverage.errors.required">Insurance
                                                Coverage is
                                                required
                                            </mat-error>
                                        </mat-error>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <a (click)="showCustomInput()" class="btn primary-btn-outlined float-right " *ngIf="roleAccess.isCreate"><i
                                        class="fas fa-plus-circle ml-2 mr-2"></i>Add New Fee</a>
                            </div>
                        </div>


                    </div>
                    <div cdkDropList class="example-list " (cdkDropListDropped)="dropPriceComp($event)">
                        <div class="list_wrapper task" cdkDrag *ngFor="let com of componentinfo;let i=index">
                            <div class="row">
                                <div class="col-md-3">

                                    <div class="form-group">
                                        <mat-form-field class="w-100">
                                            <mat-label>{{com.componentName}}</mat-label>
                                            <input matInput maxlength="30" [(ngModel)]="com.priceValue" customMin="1"
                                                maxlength="8" numeric decimals="2" #priceValue="ngModel"
                                                name="priceValue{{i}}"
                                                [ngClass]="{ 'is-invalid': priceCatform.submitted && priceValue.invalid }"
                                                required>
                                            <mat-error *ngIf="priceCatform.submitted && priceValue.invalid">
                                                <mat-error *ngIf="priceValue.errors.required">Value is required
                                                </mat-error>
                                                <mat-error *ngIf="priceValue.errors?.customMin && com.priceValue!=null">
                                                    Minimum required number is 1.
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <mat-form-field class="w-100">
                                            <mat-label>Occurances</mat-label>
                                            <mat-select [(ngModel)]="com.payFrequency" #payFrequency="ngModel"
                                                name="payFrequency{{i}}"
                                                [ngClass]="{ 'is-invalid': priceCatform.submitted && payFrequency.invalid }"
                                                required>
                                                <mat-option *ngFor="let item of ddlPayFrequencies"
                                                    [value]="item.payFrequencyId">
                                                    {{item.payFrequency}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="priceCatform.submitted && payFrequency.invalid">
                                                <mat-error *ngIf="payFrequency.errors.required">Pay Frequency is
                                                    required
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-xs-7 col-sm-7 col-md-7 mt-3" *ngIf="roleAccess.isCreate || roleAccess.isEdit">

                                    <button (click)="addPriceCompRow(com)"
                                        class="btn btn-primary list_add_button btn-rad-add btn-theme mr-1" type="button">+</button>
                                    <button (click)="removePriceCompRow(i)" class="btn btn-danger list_add_button "
                                        type="button">-</button>
                                </div>
                                <div class="deductible col-md-12" *ngIf="priceComponent.isInsuranceCoverage && com.componentName == 'Session Fee'">
                                    <!-- <div class="d-inline-block align-top">
                                        <label class="small-heading-label align-middle mr-3">Deductible</label>

                                        <mat-radio-group [(ngModel)]="com.deductibleType" #deductibleType="ngModel"
                                            name="deductibleType{{i}}" required>
                                            <mat-radio-button [value]='1' class="mr-3 my-3"
                                                (change)="onChangeDeductible(1)"> Anthem
                                            </mat-radio-button>
                                            <mat-radio-button [value]='2' class="mr-3 my-3"
                                                (change)="onChangeDeductible(2)">SCA
                                            </mat-radio-button>
                                            <mat-radio-button [value]='3' class="mr-3 my-3"
                                                (change)="onChangeDeductible(3)">Others
                                            </mat-radio-button>
                                        </mat-radio-group>
                                        <mat-error class="mat-form-field-subscript-wrapper position-relative" style="top: -18px;" *ngIf="priceCatform.submitted && deductibleType.invalid">
                                            <mat-error
                                                *ngIf="deductibleType.errors.required">
                                                Deductible is
                                                required
                                            </mat-error>
                                        </mat-error>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label class="small-heading-label align-middle w-100 mb-0">Deductible</label>

                                            <mat-form-field class="w-100">
                                                <!-- <mat-label>{{ deductibleVal}}</mat-label> -->
                                                <mat-label>Deductible Type</mat-label>
                                                <mat-select [(ngModel)]="com.deductibleType" #deductibleType="ngModel" name="deductibleType{{i}}"
                                                    [ngClass]="{ 'is-invalid': priceCatform.submitted && deductibleType.invalid }"
                                                    required>
                                                    <!--<ng-container
                                                    *ngIf="com.deductibleType == 1">
                                                    <mat-option
                                                        *ngFor="let anth of ddlType"
                                                        [value]="anth.deductibleId">
                                                        {{anth.deductible1}}
                                                    </mat-option>
                                                    </ng-container>
                                                    <ng-container
                                                    *ngIf="com.deductibleType == 2">
                                                    <mat-option
                                                        *ngFor="let anth of ddlSca"
                                                        [value]="anth.deductibleId">
                                                        {{anth.deductible1}}
                                                    </mat-option>
                                                    </ng-container>
                                                    <ng-container
                                                    *ngIf="com.deductibleType == 3">
                                                    <mat-option
                                                        *ngFor="let anth of ddlOthers"
                                                        [value]="anth.deductibleId">
                                                        {{anth.deductible1}}
                                                    </mat-option>
                                                    </ng-container>-->
                                                    <ng-container>
                                                    <mat-option
                                                        *ngFor="let anth of ddlType"
                                                        [value]="anth.deductibleId">
                                                        {{anth.deductible1}}
                                                    </mat-option>
                                                    </ng-container>
                                                </mat-select>
                                                <mat-error *ngIf="priceCatform.submitted && deductibleType.invalid">
                                                    <mat-error *ngIf="deductibleType.errors.required">Deductible Type is required</mat-error>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="small-heading-label align-middle w-100 mb-0"></label>
                                            <mat-form-field class="w-100 ">
                                                <input matInput placeholder="Comments" value="" [(ngModel)]="com.deductibleComments" #deductibleComments="ngModel" name="deductibleComments{{i}}" maxlength="300">
                                              </mat-form-field>
                                        </div>
                                    </div>
                                    


                                </div>
                                <!-- <div class="col" *ngIf="priceComponent.isInsuranceCoverage">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Co-insurance</mat-label>
                                        <mat-select [(ngModel)]="com.coInsurance" #coInsurance="ngModel"
                                            name="coInsurance{{i}}"
                                            [ngClass]="{ 'is-invalid': priceCatform.submitted && coInsurance.invalid }"
                                            required>
                                            <mat-option *ngFor="let item of ddlCoInsurances"
                                                [value]="item.coInsuranceId">
                                                {{item.coInsurance}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="priceCatform.submitted && coInsurance.invalid">
                                            <mat-error *ngIf="coInsurance.errors.required">Co-frequency is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col" *ngIf="com.coInsurance == 2">
                                <mat-form-field class="w-100">
                                    <mat-label>Min Value</mat-label>
                                    <input matInput maxlength="30" [(ngModel)]="com.minPercentage" customMin="1"
                                        customMax="100" maxlength="8" numeric decimals="2" #minPercentage="ngModel"
                                        name="minPercentage{{i}}"
                                        [ngClass]="{ 'is-invalid': priceCatform.submitted && minPercentage.invalid }"
                                        required>
                                    <mat-error *ngIf="priceCatform.submitted && minPercentage.invalid">
                                        <mat-error *ngIf="minPercentage.errors.required">Min percentage is required
                                        </mat-error>
                                        <mat-error
                                            *ngIf="minPercentage.errors?.customMin && com.minPercentage != null ">
                                            Minimum required number is 1.
                                        </mat-error>
                                        <mat-error
                                            *ngIf="minPercentage.errors?.customMax && com.minPercentage != null ">
                                            Maximum number can be 100.
                                        </mat-error>

                                    </mat-error>

                                </mat-form-field>

                            </div>
                            <div class="col" *ngIf="com.coInsurance == 2">
                                <mat-form-field class="w-100">
                                    <mat-label>Max Value</mat-label>
                                    <input matInput maxlength="30" [(ngModel)]="com.maxPercentage"
                                        customMin="{{com.minPercentage}}" customMax="100" maxlength="8" numeric
                                        decimals="2" #maxPercentage="ngModel" name="maxPercentage{{i}}"
                                        [ngClass]="{ 'is-invalid': priceCatform.submitted && maxPercentage.invalid }"
                                        required>
                                    <mat-error *ngIf="priceCatform.submitted && maxPercentage.invalid">
                                        <mat-error *ngIf="maxPercentage.errors.required">Max percentage is required
                                        </mat-error>

                                        <mat-error *ngIf="maxPercentage.errors?.customMin && com.maxPercentage !=null">
                                            Minimum required number is {{com.minPercentage}}.
                                        </mat-error>
                                        <mat-error *ngIf="maxPercentage.errors?.customMax && com.maxPercentage !=null">
                                            Maximum number can be 100.
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col" *ngIf="com.coInsurance == 3">
                                <mat-form-field class="w-100">
                                    <mat-label>Fixed Percentage</mat-label>
                                    <mat-select [(ngModel)]="com.fixedPercentage" #fixedPercentage="ngModel"
                                        name="fixedPercentage{{i}}"
                                        [ngClass]="{ 'is-invalid': priceCatform.submitted && fixedPercentage.invalid }"
                                        required>
                                        <mat-option *ngFor="let item of ddlfixedCoInsurances"
                                            [value]="item.coInsuranceId">
                                            {{item.coInsurance}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="priceCatform.submitted && fixedPercentage.invalid">
                                        <mat-error *ngIf="fixedPercentage.errors.required">Fixed Percentage is required
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div> -->
                                <!-- *ngIf="i > 1" -->
                    
                            </div>
                        </div>
                    </div>
                    <!-- <div class="list_wrapper">
                        <div class="row">

                            <div class="col-xs-4 col-sm-4 col-md-4">

                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Session Fee</mat-label>
                                        <input matInput maxlength="30" [(ngModel)]="priceComponentDetails.priceValue"
                                            #priceValue="ngModel" name="priceValue"
                                            [ngClass]="{ 'is-invalid': priceCatform.submitted && priceValue.invalid }"
                                            required>
                                        <mat-error *ngIf="priceCatform.submitted && priceValue.invalid">
                                            <mat-error *ngIf="priceValue.errors.required">Value is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-4 col-sm-4 col-md-4">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Pay Method</mat-label>
                                        <mat-select [(ngModel)]="priceComponentDetails.payFrequency"
                                            #payFrequency="ngModel" name="payFrequency"
                                            [ngClass]="{ 'is-invalid': priceCatform.submitted && payFrequency.invalid }"
                                            required>
                                            <mat-option *ngFor="let item of ddlPayFrequencies"
                                                [value]="item.payFrequencyId">
                                                {{item.payFrequency}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="priceCatform.submitted && payFrequency.invalid">
                                            <mat-error *ngIf="payFrequency.errors.required">Pay frequency is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-3 col-sm-3 col-md-3" *ngIf="priceComponent.isInsuranceCoverage">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Co-insurance</mat-label>
                                        <mat-select [(ngModel)]="priceComponentDetails.coInsurance"
                                            #coInsurance="ngModel" name="coInsurance"
                                            [ngClass]="{ 'is-invalid': priceCatform.submitted && coInsurance.invalid }"
                                            required>
                                            <mat-option *ngFor="let item of ddlCoInsurances"
                                                [value]="item.coInsuranceId">
                                                {{item.coInsurance}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="priceCatform.submitted && coInsurance.invalid">
                                            <mat-error *ngIf="coInsurance.errors.required">Co-frequency is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-xs-1 col-sm-1 col-md-1 mt-3">

                                <button class="btn btn-primary btn-theme list_add_button mr-1" type="button">+</button>
                                <button class="btn btn-danger list_add_button " type="button">-</button>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="deductible" *ngIf="priceComponent.isInsuranceCoverage">
                        <label class="small-heading-label align-middle mr-3">Deductible</label>

                        <mat-radio-group [(ngModel)]="priceComponent.deductibleType" #deductibleType="ngModel"
                            name="deductibleType" required>
                            <mat-radio-button [value]='1' class="mr-3 my-3" (change)="onChangeDeductible(1)"> Anthem
                            </mat-radio-button>
                            <mat-radio-button [value]='2' class="mr-3 my-3" (change)="onChangeDeductible(2)">SCA
                            </mat-radio-button>
                            <mat-radio-button [value]='3' class="mr-3 my-3" (change)="onChangeDeductible(3)">Others
                            </mat-radio-button>
                        </mat-radio-group>
                        <mat-error *ngIf="priceCatform.submitted && deductibleType.invalid">
                            <mat-error class="mat-form-field-subscript-wrapper" *ngIf="deductibleType.errors.required">
                                Deductible is
                                required
                            </mat-error>
                        </mat-error>
                        <mat-form-field class="ml-2">
                            <mat-label>{{ deductibleVal}}</mat-label>
                            <mat-select [(ngModel)]="priceComponent.anthem" #anthem="ngModel" name="anthem"
                                [ngClass]="{ 'is-invalid': priceCatform.submitted && anthem.invalid }" required>
                                <mat-option *ngFor="let item of ddlAnthem" [value]="item.deductibleId">
                                    {{item.deductible1}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="priceCatform.submitted && anthem.invalid">
                                <mat-error *ngIf="anthem.errors.required">Anthem is required</mat-error>
                            </mat-error>
                        </mat-form-field>


                    </div> -->
                </div>


                <div class="clearfix text-right" *ngIf="isshowPriceComponent && (roleAccess.isCreate || roleAccess.isEdit)">
                    <button type="button" (click)="oncancelcomponent()"
                        class="btn btn-cancel primary-btn-m  float-right">Cancel</button>
                    <!-- <button type="submit" (click)="onsaveOldClick(priceCatform)"
                        class="btn primary-btn-outlined mr-2 float-right">Save</button>
                      <button type="submit" (click)="onsaveaddClick(priceCatform)"
                        class="btn primary-btn-outlined mr-2 float-right">Save &
                        Add</button> (click)="onNewsaveClick(priceCatform)"-->
                      <button type="submit"  [disabled]="saveClicked"
                        class="btn primary-btn-outlined mr-2 float-right">Save</button>
                </div>
            </div>
            <div class="col-md-12 mt-3">
                <div class="top-button-section d-flex mb-2" *ngIf="componentdetails && componentdetails.length > 0  && roleAccess.isView">
                    <h6 class="box-lead-head pt-2 pb-0">Available Price Components</h6>
                    <button type="button" (click)="addPriceComponent(1)" class="btn primary-btn-outlined ml-auto" *ngIf="roleAccess.isCreate"><i
                            class="fas fa-plus-circle mr-2"></i>Add New Component
                    </button>
                </div>

                <div class="Catalogue-list list-template" *ngIf="componentdetails && componentdetails.length > 0">
                    <ul class="col-md-12 nomargin">
                        <li *ngFor='let item of componentdetails;let inx=index'>
                            <div class="card">
                                <div class="card-body p-0">
                                    <div class="row">
                                        <div class="col-md-1 mt-4 pr-0">
                                            <div class=" insurance p-0 text-center" *ngIf="item.isInsuranceCoverage">
                                                <img src="../../../../../assets/images/shield.png">
                                            </div>
                                            <div class="insurance p-0 text-center"
                                                *ngIf="item.isInsuranceCoverage == false">
                                                <img src="../../../../../assets/images/noshield.png">
                                            </div>
                                            <div class="insurance p-0 text-center" *ngIf="i > 0">
                                           </div>
                                        </div>
                                        <div class="col-md-7 pl-0">
                                            <div class="py-4 position-relative">
                                                <div class=" col-md-12">
                                                    <div class=" row">
                                                        <div class="col-md-6"
                                                            *ngFor="let data of item.componentDetails;let i=index">
                                                            <div class="row">
                                                                <label
                                                                    class="list-head price-label col-md-12 ">{{data.componentName|titlecase}}</label>
                                                                <div class="col-md-12 ">
                                                                    <div *ngFor="let a of data.pricing"
                                                                        class="list-value-new float-left w-100"><span
                                                                            class="highlight-text">$
                                                                            {{a.priceValue}}</span><span
                                                                            class="small-text-highlight" *ngIf="a.payFrequency!=1">,
                                                                            <!-- class="small-text-highlight" *ngIf="a.payFrequency!=1">,if
                                                                            co-insurance -->
                                                                            {{a.coInsuranceVal}}</span></div>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                </div>




                                            </div>
                                        </div>
                                        <div class="col-md-2 ">
                                        </div>
                                        <!-- <div class="col-md-2 ">
                                            <div class="deduct py-4 position-relative" *ngIf="item.anthemVal != ''">
                                                <div class="clearfix">
                                                    <label
                                                        class="list-head deduct-label sub-head-list col-md-12 p-0">Deductible</label>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <label
                                                            class="list-head deduct-label-value col-md-4 p-0">Athem</label>
                                                        <div class="col-md-8 pl-0">
                                                            <p class="list-value deduct-label-value">{{item.anthemVal}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div> -->

                                        <div class="col-md-2 border-left">
                                            <div class="py-4 clearfix">
                                                <div class="result_action_btn float-left w-100 mt-3">
                                                    <a title="Edit"  *ngIf="roleAccess.isEdit"
                                                        (click)="onEditPriceComponent(item.priceComponentId)"
                                                        class="btn line-btn cursor-pointer">Edit</a>
                                                    <a title="Delete"  *ngIf="roleAccess.isDelete"
                                                        (click)="ondeletePriceComponent(item.priceComponentId)"
                                                        class="btn line-btn cursor-pointer">Delete</a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

        </div>
    </form>
    <div class="modal " id="myModal" [style.display]="isAddCustomField ? 'block' : 'none'">
        <div class="modal-dialog mb-2">
            <div class="modal-content">
                <div class="modal-header fill-header">
                    <h6 class="modal-title">Add New Fee</h6>
                    <button aria-hidden="true" data-dismiss="modal" class="close" (click)="oncloseCustomfld()"
                        type="button"><i class="fas fa-times"></i></button>

                </div>
                <div class="modal-body">
                    <mat-form-field class="w-100">
                        <mat-label>New Fee</mat-label>
                        <input matInput maxlength="30" appAlphabetOnly [(ngModel)]="pricefield" #pricefieldM="ngModel"
                            name="pricefieldM">

                    </mat-form-field>
                    <mat-error *ngIf="ispricefieldReq">This field is required
                    </mat-error>
                </div>
                <div class="modal-footer text-center" *ngIf="roleAccess.isCreate">
                    <button type="button" (click)="onAddCustomInput()" class="btn primary-btn-filled primary-btn-s "
                        data-dismiss="modal">Ok</button>
                    <button type="button" (click)="oncloseCustomfld()" class="btn btn-cancel primary-btn-s "
                        data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit, ViewChild } from '@angular/core';
import { CatalogueService } from 'src/app/common/pages/price-catalogue/catalogue.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { constantVariables } from 'src/app/constant/constant';
import { NgForm } from '@angular/forms';
import { PriceCatalogue } from 'src/app/_models/_Lead/pricecatalogue';
import { PriceComponent } from 'src/app/_models/_Lead/pricecatalogue';
import { PriceComponentDetails } from 'src/app/_models/_Lead/pricecatalogue';
import { cloneDeep } from "lodash";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BillingService } from 'src/app/billing/billing.service';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
declare function decrypt(data): any;
@Component({
  selector: 'app-price-catalogue',
  templateUrl: './price-catalogue.component.html',
  styleUrls: ['./price-catalogue.component.scss']
})
export class PriceCatalogueComponent implements OnInit {
  @ViewChild('priceCatform') catalougeForm: NgForm;
  ddlProgramTypes: any;
  ddlPayFrequencies: any;
  ddlCoInsurances: any;
  ddlAnthem: any;
  ddlType: any;
  ddlSca: any;
  ddlOthers: any;
  ddlStates: any;
  ddlCities: any;
  ddllocation: any;
  ddlfixedCoInsurances: any;
  priceCatalogue: PriceCatalogue;
  priceComponent: PriceComponent;
  priceComponentDetails: PriceComponentDetails;
  // isAddPriceComponent: boolean = false;
  isGlobalAccess: boolean = false;
  isInsuranceCoverage: boolean = false;
  isAddCustomField: boolean = false;
  ispricefieldReq: boolean = false;
  iseditCatalogue: boolean = false;
  isSavePrice: boolean = false;
  componentinfo: PriceComponentDetails[] = [];
  standardComponent: any = [];
  pricefield: any;
  priceCatalogueId: any = 0;
  componentdetails: any = [];
  onpriceCatalogueId: any;
  //isshowpricecomp: boolean = false;
  isshowPriceComponent: boolean = false;
  deductibleVal: any;
  saveClicked: boolean = false;
  roleAccess: any;
  patientInfoId: any;
  // cpt:any;
  // transactionType:any;
  // typeofServices:any
  // Units:any;
  // Amount:any;

  constructor(public CatlogueService: CatalogueService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public billingservice: BillingService,
    public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Templates;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.priceCatalogue = new PriceCatalogue();
    this.priceComponent = new PriceComponent();
    this.priceComponentDetails = new PriceComponentDetails();
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.getComponentMaster();
    this.getddlLocation();
    this.GetDeductiblemaster();
  //  this.getBillingMaster();
     this.router.routerState.root.queryParams.subscribe(params => {
      if (params.id) {
        this.priceCatalogueId = this.commonService.FrontEndDecryption(params.id);
        this.onEditPriceCatalogue(this.priceCatalogueId);
      }
    });

  }
  getComponentMaster() {
    this.CatlogueService.getComponentMaster().subscribe(
      res => {
        if (res) {
          this.ddlAnthem = res.anthem;
          this.ddlCoInsurances = res.coInsurances;
          this.ddlOthers = res.others;
          this.ddlPayFrequencies = res.payFrequencies;
          this.ddlProgramTypes = res.programTypes;
          this.ddlSca = res.sca;
          this.ddlfixedCoInsurances = res.fixedCoInsurances;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getddlStateMaster() {
    this.spinnerservice.show();
    this.CatlogueService.getstateMaster().subscribe(
      res => {
        if (res != null) {
          this.ddlStates = this.commonService.decrypdata(res.states);
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getddlLocation() {
    this.spinnerservice.show();
    this.CatlogueService.getLocations().subscribe(
      res => {
        if (res != null) {
          this.ddllocation = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onChangeState(state) {
    this.commonService.getcities(state.value).subscribe(
      res => {
        this.ddlCities = this.commonService.decrypdata(res.cities);;
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  addPriceComponent(type) {
    if (type == 1) {
      // if (this.componentdetails && this.componentdetails.length == 0) {
      //   this.catalougeForm.resetForm();
      // }
      this.priceComponent = new PriceComponent();
      this.priceComponent.isInsuranceCoverage = true;
      this.priceComponent.deductibleType = 1;
      this.deductibleVal = 'Anthem';
      this.isshowPriceComponent = true;
      this.getStandardComponent();
    }
  }
  onChangeDeductible(type) {
    if (type == 1) {
      this.deductibleVal = 'Anthem';
      this.ddlType = this.ddlAnthem;
    }
    else if (type == 2) {
      this.deductibleVal = 'SCA';
      this.ddlType = this.ddlSca;
    }
    else if (type == 3) {
      this.deductibleVal = 'Others';
      this.ddlType = this.ddlOthers;
    }
  }
  getStandardComponent() {
    this.CatlogueService.getStandardComponent().subscribe(
      res => {
        this.standardComponent = res;
        this.pushSTDPriceComponent();
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  pushSTDPriceComponent() {
    this.componentinfo = [];
    for (let i = 0; i < this.standardComponent.length; i++) {
      this.pushCompenent(this.standardComponent[i].componentName);
    }
  }
  pushCompenent(item) {
    let _obj = {
      priceComponentDetailsId: 0,
      priceComponentId: 0,
      componentName: item,
      priceValue: null,
      payFrequency: null,
      coInsurance: null,
      minPercentage: null,
      maxPercentage: null,
      fixedPercentage: null,
      anthem: null,
      sca: null,
      others: null,
      deductibleType: null,
      deductibleComments:null
    }
    this.componentinfo.push(_obj)
  }
  showCustomInput() {
    this.pricefield = "";
    this.isAddCustomField = true;
    this.ispricefieldReq = false;
  }
  onAddCustomInput() {
    if (this.pricefield != '') {
      var existRecord = this.componentinfo.filter((x) => x.componentName == this.pricefield);
      if (existRecord && existRecord.length == 0) {
        this.pushCompenent(this.pricefield);
        this.isAddCustomField = false;
      }
    } else {
      this.ispricefieldReq = true;
    }
  }
  oncloseCustomfld() {
    this.isAddCustomField = false;
  }
  addPriceCompRow(item) {
    this.pushCompenent(item.componentName);

  }
  removePriceCompRow(index) {
    this.componentinfo.splice(index, 1);
    this.priceCatalogue.components[0].componentDetails.splice(index,1)
  }

  onChangeInsCoverage(type) {
    if (type == 1) {
      this.isInsuranceCoverage = true;
    } else {
      this.isInsuranceCoverage = false;
    }
  }
  onchangeAccesslvl(event) {
    if (event.checked) {
      //this.priceCatalogue.accessLevel = 0;
      //this.priceCatalogue.location = null;
      this.isGlobalAccess = true;


    } else {
      //this.priceCatalogue.accessLevel = 1;
      this.isGlobalAccess = false;

    }

  }
  locationchange(event) {
      this.isGlobalAccess = false;
    this.priceCatalogue.accessLevel = undefined;
  }
  postPriceCatalogue(form: NgForm) {
    this.saveClicked=true;
   this.spinnerservice.show();
   this.isSavePrice = true;
    if (!form.valid) {
      this.saveClicked=false;
      this.spinnerservice.hide();
      return;
    }

    if (this.priceCatalogue.accessLevel == 1) {
      this.priceCatalogue.sfccmasterId = '';
      this.priceCatalogue.accessLevel = 1;
    } else {
      this.priceCatalogue.accessLevel = 0;
      if (this.priceCatalogue.sfccmasterId && this.priceCatalogue.sfccmasterId.length > 0) {
        this.priceCatalogue.sfccmasterId = this.priceCatalogue.sfccmasterId.toString();
      }
    }
    // if(this.priceCatalogue.sfccmasterId && this.priceCatalogue.sfccmasterId.length > 0){
    //   this.priceCatalogue.accessLevel = 1;
    //   this.isGlobalAccess = false;
    // }else{
    //   this.priceCatalogue.accessLevel = 0;
    //   this.isGlobalAccess = true;
    // }
    this.priceCatalogue.priceCatalogueId = this.priceCatalogueId;
    this.priceCatalogue.location = null;
    const dataToEncrypt = this.commonService.DataToEncrypt(this.priceCatalogue, false);
    this.CatlogueService.postPriceCatalogue(dataToEncrypt).subscribe(
      (res: any) => {
         this.spinnerservice.hide();
          if (!this.isSavePrice)
           {
           this.snackbar.success(JSON.parse(res).message);
            }
        this.priceCatalogueId = JSON.parse(res).id;
        this.onEditPriceCatalogue(this.priceCatalogueId)
        if (this.componentinfo && this.componentinfo.length > 0) {
          this.postPriceComponent(JSON.parse(res).id, form)
        } else {
          this.snackbar.success(JSON.parse(res).message);
          this.onbacktopricelist();
          this.catalougeForm.resetForm();
          this.isshowPriceComponent = true;
          this.iseditCatalogue = false;
          this.onloadPriceCatalogue(this.priceCatalogueId);
          this.saveClicked=false;
        }
      },
      err => {
        this.spinnerservice.hide();
        this.saveClicked=false;
        this.snackbar.error(err.error);
      },
    );
  }
  postPriceComponent(id, form: NgForm) {
    this.priceComponent.priceCatalogueId = this.priceCatalogueId;
    this.onpriceCatalogueId = this.priceCatalogueId;
    this.priceComponent.componentDetails = this.componentinfo;
    const dataToEncrypt = this.commonService.DataToEncrypt(this.priceComponent, false);
    this.spinnerservice.show();
    this.CatlogueService.postPriceComponent(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.catalougeForm.resetForm();
        // this.pushSTDPriceComponent();

        if (this.isSavePrice) {
          this.snackbar.success(res);
          this.onbacktopricelist();
        } else {
          this.priceComponent = new PriceComponent();
          this.priceComponent.priceCatalogueId = this.onpriceCatalogueId;
          this.priceCatalogue.priceCatalogueId = this.onpriceCatalogueId;
          this.getStandardComponent();
          this.onclearDeductible();
          this.onloadPriceCatalogue(this.priceCatalogueId)

        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onclearDeductible() {
    this.priceComponent.anthem = 0;
    this.priceComponent.sca = 0;
    this.priceComponent.others = 0;
  }

  // public onsavecataClick(): void {
  //   this.isSavePrice = true;
  // }
  // public onsaveOldClick(form: NgForm): void {
  //     this.isSavePrice = true;
  //     this.spinnerservice.show();
  //   this.postPriceCatalogue(form);

  // }

  // public onsaveaddClick(form: NgForm): void {
  //   this.isSavePrice = false;
  //  }
   public onNewsaveClick(form: NgForm): void {
    this.spinnerservice.show();
    this.isSavePrice = true;
   }

  resetPriceCatalouge() {
    this.componentinfo = [];
    this.componentdetails = [];
    this.catalougeForm.resetForm();
    this.priceCatalogue = new PriceCatalogue();
    this.priceComponent = new PriceComponent();
    this.priceComponentDetails = new PriceComponentDetails();
    this.isshowPriceComponent = false;
    this.iseditCatalogue = false;
    this.isGlobalAccess=false;
   //this.router.navigate(['/price-catalogue']);

  }
  oncancelcomponent() {
    this.componentinfo = [];
    this.pushSTDPriceComponent();
    this.priceComponent = new PriceComponent();
    this.priceComponentDetails = new PriceComponentDetails();
    if (this.componentdetails && this.componentdetails.length == 0) {

      this.isshowPriceComponent = false;

    } else {

      if (this.iseditCatalogue) {
        this.isshowPriceComponent = false;
      } else {
        this.isshowPriceComponent = true;
      }
    }
    this.onclearDeductible();
  }
  onEditPriceCatalogue(id) {
    this.iseditCatalogue = true;
    this.spinnerservice.show();
    this.CatlogueService.getPriceCatalogueDetails(id,this.patientInfoId).subscribe(
      res => {
         if (res != null) {
          if (res.priceCatalogueId != 0) {
            this.priceCatalogue = cloneDeep(res);
           
            if (res.sfccmasterId != "") {
              this.priceCatalogue.sfccmasterId = this.priceCatalogue.sfccmasterId.split(',').map(Number);
            }
            this.loadAccessLevel()
            if (res.components && res.components.length > 0) {
              this.componentdetails = res.components;

              this.isshowPriceComponent = false;
            } else {
              this.isshowPriceComponent = false;
              this.iseditCatalogue = false;
            }

          }

        }
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  loadAccessLevel() {
    if (this.priceCatalogue.accessLevel == 0) {
      //this.priceCatalogue.location = this.priceCatalogue.accessLevel;
      this.priceCatalogue.accessLevel = 0;
      this.isGlobalAccess = false;
    } else {
      this.priceCatalogue.location = null;
      this.priceCatalogue.accessLevel = 1;
      this.isGlobalAccess = true;
    }
  }
  onloadPriceCatalogue(id) {

    this.CatlogueService.getPriceCatalogueDetails(id,this.patientInfoId).subscribe(
      res => {
        if (res != null) {

          if (res.priceCatalogueId != 0) {
            //this.catalougeForm.resetForm();
            this.isshowPriceComponent = false;
            this.priceCatalogue = cloneDeep(res);
            this.loadAccessLevel()
            if (res.components && res.components.length > 0) {
              this.componentdetails = res.components;
              this.isshowPriceComponent = false;
              this.iseditCatalogue = true;
            } else {

              this.isshowPriceComponent = false;
              this.iseditCatalogue = false;
            }

          }

        }
        if (!this.isSavePrice) {
          this.addPriceComponent(1);
          this.global.scrollup();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  ondeletePriceComponent(id) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.CatlogueService.deletePriceComponent(id).subscribe(
          (res: any) => {

            this.snackbar.success(res.message);
            this.onEditPriceCatalogue(this.priceCatalogueId);
            this.spinnerservice.hide();

          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );

      }
    });
  }
  onEditPriceComponent(id) {
    this.isshowPriceComponent = true;
    //this.priceComponent = cloneDeep(data)
    this.CatlogueService.getPriceComponentDetails(id).subscribe(
      res => {
        if (res != null) {
          this.componentinfo = [];
          this.priceComponent = res;
          this.componentinfo = res.componentDetails;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onloadPriceComponent(id) {
    this.CatlogueService.getPriceComponentDetails(id).subscribe(
      res => {
        if (res != null) {
          this.priceComponent = new PriceComponent();
          this.priceComponentDetails = new PriceComponentDetails();
          this.componentinfo = [];
          //this.priceComponent = res;
          this.componentinfo = res.componentDetails;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  addnewCatalogue() {
    this.router.navigate(['/price-catalogue']);
  }
  onbacktopricelist() {
    this.router.navigate(['/consentlist'], { queryParams: { tab: 1 } });

  }
  dropPriceComp(event) {
    moveItemInArray(this.componentinfo, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.spinnerservice.show();
      const dataToEncrypt = this.commonService.DataToEncrypt(this.componentinfo, false);
      this.CatlogueService.postOrderPriceComponent(dataToEncrypt).subscribe(
        (res: any) => {
          this.spinnerservice.hide();

        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }
  }
  //  getBillingMaster(){
  //   this.spinnerservice.show();
  //   this.billingservice.getBillMstr().subscribe(
  //     res => {
  //       if (res != null) {
  //        let result=decrypt(res.objInputString)
  //        console.log(result);
  //         this.cpt=result.Cptcode;
  //         this.transactionType=result.TransType;
  //         this.typeofServices=result.TypeofServices;
  //         this.spinnerservice.hide();
  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );
 //  }
 GetDeductiblemaster() {
  this.CatlogueService.getDeductibleMaster().subscribe(
    res => {
      if (res) {
        this.ddlType = res;
     
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}

}

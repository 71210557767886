<li class="nav-item  dropdown notifications">
 
  <a class="nav-link nav-link-icon text-center"  (click)="toggleNotification()" role="button" id="dropdownMenuLink"
    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <div class="nav-link-icon__wrapper">
      <i class="material-icons text-secondary bi bi-exclamation-circle-fill text-danger"></i>
      <span class="badge badge-pill badge-success">{{UnReadCount}}</span>
    </div>
  </a>
  <div class="dropdown-menu dropdown-menu-small" aria-labelledby="dropdownMenuLink"
    [ngClass]="{ 'show': notificationOpen }">
    <div class="dropdown-header">
      <span class="triangle"></span>
      <span class="heading">Notifications</span>
      <a class="viewall-btn cursor-pointer float-right" [routerLink]="['/viewnotification']">View All</a>
    </div>
    <div class="notify-list">
    <div  *ngFor="let item of FilterInAPPNotificationList">
      <a class="dropdown-item unread" *ngIf="item.status==0" (click)="markUnreadNofitication(item)" >

        <div class="notification__content" >
          <p><span class="notification__category cursor-pointer" title="{{item.notificationTitle}}" >{{item.notificationTitle.length > 80 ? item.notificationTitle.substring(0,80)+"..." : item.notificationTitle}}</span>
         
            <span class="float-right time-stam">{{getfromnow(item.createdOn)}}</span></p>
          <p class="notify-italics"></p>
          <p> <span class="float-right dot-circle"></span>{{item.comments}}</p>
         <a (click)="clearNofitication(item)"><span class="make-unread"> <i class="fas fa-times-circle"></i></span></a> 
        </div>
      </a>

      <a class="dropdown-item read-notify" *ngIf="item.status == 1" (click)="onReadNotification(item)">

        <div class="notification__content">
          <P><span class="notification__category cursor-pointer" title="{{item.notificationTitle}}">{{item.notificationTitle.length > 80 ? item.notificationTitle.substring(0,80)+"..." : item.notificationTitle}}</span>
            <span class="float-right time-stam">{{getfromnow(item.createdOn)}}</span></P>
          <p class="notify-italics"></p>
          <p>   {{item.comments}}</p>
          <a (click)="clearNofitication(item)"><span class="make-unread">  <i class="fas fa-times-circle"></i> </span></a> 
        </div>
      </a>
    </div>
  </div>
    <div class="clear-action "> 
      <a  class="float-left cursor-pointer" (click)="clearAllUnreadNofitication()">Clear All</a>
      <a (click)="markAllUnreadNofitication()" class="float-right cursor-pointer">Mark all as
        read</a> </div>
  </div>
</li>
<div class="change_pas_after_login ">
    <div  class="top-btn-section col-md-12 clearfix mb-1 w-100">
      <h2  class="head-section mt-3">Change Password</h2>
      <div  class="col-md-6 ml-auto mt-2 pr-0">
        </div>
        <div  class="ml-auto"><button  class="btn back-btn-new mr-2" tabindex="0" (click)="OnBack()" ><i  class="fas fa-chevron-left mr-2"></i>Back</button></div>
      </div>
    <div class="col-md-12">
      <div class="card pt-4 ">
        <form (submit)="onSubmit()" class="form-horizontal form-material mx-2 col-md-4" [formGroup]="changepassswordForm">
          
            <div class="form-group">
                <label class="col-md-12 ">Current Password</label>
                <div class="col-md-12">
                  <div class="custom-password">
                  <input  formControlName="OldPassword" [type]="oldPassEye ? 'password' : 'text'" class="form-control ps-0 form-control-line" (mouseout)="hideErrorMessage()"/>
                  <i  [class]="oldPassEye ? 'fa fa-fw fa-eye field-icon toggle-password' : 'fa fa-fw fa-eye-slash field-icon toggle-password'"  (click)=" oldPassEye=!oldPassEye"></i></div>
                    <div *ngIf="submitted && f.OldPassword.errors"
                    class="error-message">
                        <div *ngIf="f.OldPassword.errors.required">Current Password is
                        required</div>
                    </div>
                    <div class="error-message" *ngIf="isOldPasswordCorrect">
                      Please enter current password correctly
                    </div>
                </div>
            </div> 
            <div class="form-group">
                <label class="col-md-12 ">New Password</label>
                <div class="col-md-12">
                  <div class="custom-password">
                  <input  id="password" formControlName="Password" [type]="newPassEye ? 'password' : 'text'"
                                                      class="form-control ps-0 form-control-line"
                                                      [ngClass]="(changepassswordForm.controls['Password'].invalid && (changepassswordForm.controls.Password.value !=null && changepassswordForm.controls.Password.value !='')) ? 'is-invalid' : ''" 
                                                      (mouseover)="changeStyle($event)"
                                                      (mouseout)="changeStyleMouseOut($event)" 
                                                      />
                                                      <i  [class]="newPassEye ? 'fa fa-fw fa-eye field-icon toggle-password' : 'fa fa-fw fa-eye-slash field-icon toggle-password'"  (click)="newPassEye=!newPassEye"></i></div>
                                                  <div *ngIf="submitted && f.Password.errors"
                                                      class="error-message">
                                                      <div *ngIf="f.Password.errors.required">New Password is
                                                          required</div>
                                                  </div>
                </div>
                <div class="popup-design--replicate"
                                                        [style.display]="showErrorPassword ? 'block' : 'none'">
                                                        <div class="icon-align--newpop">
                                                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="#ff0000"
                                                                class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                            </svg> -->
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ff0000" class="bi bi-caret-left-fill" viewBox="0 0 16 16">
                                                              <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                                                            </svg>
                                                        </div>
                                                        <label class="col text-xs"
                                                            [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                                                            <i class="material-icons">{{
                                                                changepassswordForm.controls['Password'].hasError('required')
                                                                ||
                                                                changepassswordForm.controls['Password'].hasError('minlength')
                                                                ? 'cancel' :
                                                                'check_circle' }}</i>
                                                                Must contain at least 8 characters!
                                                        </label>

                                                        <label class="col text-xs"
                                                            [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                                            <i class="material-icons">{{
                                                                changepassswordForm.controls['Password'].hasError('required')
                                                                ||
                                                                changepassswordForm.controls['Password'].hasError('hasNumber')
                                                                ? 'cancel' :
                                                                'check_circle' }}</i>
                                                                Must contain at least 1 numeric character!
                                                        </label>

                                                        <label class="col text-xs"
                                                            [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                                            <i class="material-icons">{{
                                                                changepassswordForm.controls['Password'].hasError('required')
                                                                ||
                                                                changepassswordForm.controls['Password'].hasError('hasCapitalCase')
                                                                ? 'cancel' :
                                                                'check_circle' }}</i>
                                                                Must contain at least 1 uppercase letter!
                                                        </label>

                                                        <label class="col text-xs"
                                                            [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                                            <i class="material-icons">{{
                                                                changepassswordForm.controls['Password'].hasError('required')
                                                                ||
                                                                changepassswordForm.controls['Password'].hasError('hasSpecialCharacters')
                                                                ? 'cancel' :
                                                                'check_circle' }}</i>
                                                                Must contain at least 1 special character!
                                                        </label>
                                                    </div>
              </div> 
              <div class="form-group">
                <label class="col-md-12">Confirm Password</label>
                <div class="col-md-12">
                  <div class="custom-password">
                  <input id="ConfirmPassword" [type]="confirmPassEye ? 'password' : 'text'" class="form-control ps-0 form-control-line"
                  [ngClass]="(changepassswordForm.controls['ConfirmPassword'].invalid && (changepassswordForm.controls.ConfirmPassword.value !=null && changepassswordForm.controls.ConfirmPassword.value !=''))? 'is-invalid' : ''"
                  formControlName="ConfirmPassword"
                  class="form-control ps-0 form-control-line" />
                  <i  [class]="confirmPassEye ? 'fa fa-fw fa-eye field-icon toggle-password' : 'fa fa-fw fa-eye-slash field-icon toggle-password'"  (click)="confirmPassEye=!confirmPassEye"></i></div>
              <div *ngIf="submitted && f.ConfirmPassword.errors"
                  class="error-message">
                  <div *ngIf="f.ConfirmPassword.errors.required">Confirm
                      password
                      is Required</div>
              </div>
              <label
                  class="error-message"
                  *ngIf="changepassswordForm.controls['ConfirmPassword'].hasError('NoPassswordMatch') && (changepassswordForm.controls.ConfirmPassword.value !=null && changepassswordForm.controls.ConfirmPassword.value !='')">
                  Password does not match
              </label>
              <label
                  class="error-message"
                  *ngIf="errorMessageForSamePassword">
                  Current password and New password shoud not to be same
              </label>
                </div>
                
              </div>
          <div class="form-group">
            <div class="col-sm-12 d-flex">
                <button class="btn btn-success btn-theme ml-auto mr-2 text-white" (click)="onSubmit()" type="submit">
                    Update Password
                   </button>
            </div>
          </div> 
          </form>
      </div>
    </div>
      
   </div>

import {  Component, OnInit, Input} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';

import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  CalendarDateFormatter
} from 'angular-calendar';
import { constantVariables } from 'src/app/constant/constant';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TaskService } from 'src/app/common/pages/task/task.service';
import { Task } from 'src/app/_models/_Common/task';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import { CustomDateFormatter } from 'src/app/global/custom-date-formatter.provider';
import { PatientService } from 'src/app/patient/patient.service';
const colors: any = {
  red: {
    primary: '#173a64',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class CalenderComponent implements OnInit {
  @Input() patientInfoId: number;
  @Input() patientAccountId: number;
  @Input() Type: any;
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],                                       // remove formatting button


      ],

    },

    keyboard: {
      bindings: {
        shiftEnter: {
          key: 13,
          shiftKey: true,
          handler: (range, context) => {
            // Handle shift+enter
           // console.log("shift+enter")
          }
        },
        enter: {
          key: 13,
          handler: (range, context) => {
           // console.log("enter");
            return true;
          }
        }
      }
    }
  }
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;
  taskmodal: boolean = false;
  //patientInfoId: any;
  lstTask: any;
  taskinfo: Task;
  currentdate: any;
  isValidDueDate: boolean = true;
  isValidFlagDate: boolean = true;
  isEditTask: boolean = false;
  eventsOnDate: any = [];
  lstCalender: any = [];
  constructor(public global: Global,
    private snackbar: SnackBarService, public taskService: TaskService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public patientservice: PatientService) { }

  ngOnInit(): void {
    this.currentdate = new Date();
    this.taskinfo = new Task();
        this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
         this.patientInfoId = this.commonService.FrontEndDecryption(params.id);
        //this.getTask();//commented for --briefcase view from admin / clinican login
        if(localStorage.getItem('roleId')!="4"){
          this.getCalenderDetails();
        }
        else{this.getTask();}
      } else {
        this.patientInfoId = Number(localStorage.getItem("patientId"));
        if (this.Type == constantVariables.calenderType.Task){
          this.getTask();
        }
        else if (this.Type == constantVariables.calenderType.BreifCase) {
          this.getCalenderDetails();
        }
      }
    });
   
  }


  getTask() {
    this.taskService.getTask(this.patientInfoId).subscribe(
      (res: any) => {
         if (res) {
          this.lstTask = res;
          this.events = [];
          for (var i = 0; i < this.lstTask.length; i++) {
            let _obj = {
              start: new Date(this.lstTask[i].dueDate),
              //end: new Date(this.lstTask[i].dueDate),
              //title: this.lstTask[i].content.replace(/<[^>]+>/g, ''),
              title: this.lstTask[i].content,
              color: colors.red,
              status: this.lstTask[i].status,
              tags: this.lstTask[i].tags
            }
            this.events = [...this.events, _obj];
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getCalenderDetails() {
    this.patientservice.getCalenderTasks(this.patientInfoId).subscribe(
      res => {
          if (res != null) {
          this.lstCalender = res;
          this.events = [];
          for (var i = 0; i < this.lstCalender.length; i++) {
            let _obj = {
              start: new Date(this.lstCalender[i].dueDate),             
              title: this.lstCalender[i].content,
              color: this.lstCalender[i].colorCode,
              status: this.lstCalender[i].status,
              tags: this.lstCalender[i].tags,
              updateDate: this.lstCalender[i].updateDate
            }
          
          this.events = [...this.events, _obj];
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
        this.eventsOnDate = [];
      } else {
        //this.activeDayIsOpen = true;

        this.eventsOnDate = events;

      }
      this.viewDate = date;
    }
  }

  // eventTimesChanged({
  //   event,
  //   newStart,
  //   newEnd,
  // }: CalendarEventTimesChangedEvent): void {
  //   this.events = this.events.map((iEvent) => {
  //     if (iEvent === event) {
  //       return {
  //         ...event,
  //         start: newStart,
  //         end: newEnd,
  //       };
  //     }
  //     return iEvent;
  //   });
  //   this.handleEvent('Dropped or resized', event);
  // }

  // handleEvent(action: string, event: CalendarEvent): void {
  //   this.modalData = { event, action };

  // }

  // addEvent(): void {
  //   this.events = [
  //     ...this.events,
  //     {
  //       title: 'New event',
  //       start: startOfDay(new Date()),
  //       end: endOfDay(new Date()),
  //       color: colors.red,
  //       draggable: true,
  //       resizable: {
  //         beforeStart: true,
  //         afterEnd: true,
  //       },
  //     },
  //   ];
  // }

  // deleteEvent(eventToDelete: CalendarEvent) {
  //   this.events = this.events.filter((event) => event !== eventToDelete);
  // }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  onClosetaskmodal() {
    this.taskinfo = new Task();
    this.taskmodal = false;
  }
  onOpentaskmodal(date: Date) {
    let today = new Date();
    if (moment(date).format("MM/DD/YYYY") >= moment(today).format("MM/DD/YYYY")) {
      this.taskinfo = new Task();
      this.taskinfo.dueDate = new Date(date).toISOString();
      //this.taskmodal = true;
    }
  }
  saveTaskInfo(form: NgForm) {
    var dueDate = moment(this.taskinfo.dueDate).format("MM/DD/YYYY");
    var overDate = moment(this.taskinfo.overDate).format("MM/DD/YYYY");
    var flagDate = moment(this.taskinfo.flagMeBy).format("MM/DD/YYYY");
    // var ValidDueDate = this.validateDates(dueDate, overDate);
    // if (!ValidDueDate) {
    //   this.isValidDueDate = false;
    //   return
    // }else{
    //   this.isValidDueDate = true;
    // }
    var ValidFlagDate = this.validateDates(flagDate, dueDate);
    if (!ValidFlagDate) {
      this.isValidFlagDate = false;
      return
    } else {
      this.isValidFlagDate = true;
    }
    this.taskinfo.patientInfoId = this.patientInfoId;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.taskinfo, false);
    this.taskService.postTaskInfo(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        form.resetForm();
        this.resetnotesform();
        this.snackbar.success(JSON.parse(res).message);

      }
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  validateDates(sDate: string, eDate: string) {
    var isValidDate = true;
    if ((sDate == null || eDate == null)) {
      isValidDate = false;
    }

    if ((sDate != null && eDate != null) && (eDate) < (sDate)) {

      isValidDate = false;
    }
    return isValidDate;
  }
  resetnotesform() {
    this.refresh.next();
    this.taskinfo = new Task();
    this.isEditTask = false;
    this.getTask();
    this.onClosetaskmodal();
  }

}

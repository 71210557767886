import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';


import { LeadService } from 'src/app/patient-lead/lead.service'
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from "lodash";

@Component({
  selector: 'app-lead-history',
  templateUrl: './lead-history.component.html',
  styleUrls: ['./lead-history.component.scss']
})
export class LeadHistoryComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  leadhistory:any;
  patientInfoId:any;
  Decrptedlst:any;
  constructor(public leadservice: LeadService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params != null) {

        this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.id));
        this.getLeadHistory();
      }
    });
    
  }
  onPageChange($event) {

    this.leadhistory = this.Decrptedlst.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  getLeadHistory() {
    this.leadservice.getLeadHistory(this.patientInfoId).subscribe(
      (res: any) => {
        if (res) {
          this.Decrptedlst = res;
          this.leadhistory = this.Decrptedlst.slice(0, 5);
          this.dataSource = new MatTableDataSource(this.Decrptedlst);
          this.dataSource.paginator = this.paginator;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
}

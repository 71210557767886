<div class="patient-intake">
    <div class="top-btn-section clearfix mb-1 w-100">
      <div class="d-flex">
        <h2 class="head-section mb-2 mt-2 mr-2 p-0">Schedule {{ScheduleName}} {{scheduleType==1?'Appointment':''}}</h2>
  
        <div class="mt-2 meta-data-prof">
          <i class="fas fa-user-tie mr-2"></i>
          <span class="meta-value">{{patientdetails?.firstName}} {{patientdetails?.lastName}}</span><span class="meta-value">({{patientdetails?.age}} yrs
            old)</span>
          <span class="meta-value">{{patientdetails?.gender}}</span>
  
          <!-- <span  class="meta-value">{{_DtoSchAppoinment?.scheduleName}}</span><span  class="meta-value">{{_DtoSchAppoinment?.age}}</span>
          <span  class="meta-value">{{_DtoSchAppoinment?.gender}}</span> -->
        </div>
  
      </div>
  
      <div class=" col-md-1 ml-auto mt-2 button-position pr-0">
  
        <button type="button" (click)="onback()" class="btn primary-btn-outlined float-right mr-2"><i
            class="fas fa-angle-left pr-2"></i>Back</button>
      </div>
    </div>
    <div class="lead-box pt-2 ">
      <!-- [ngClass]="{'disabled':immediateActionId == statusintakeAccepted}" -->
      <form (ngSubmit)="schIntake.form.valid  && postSchAppointment(schIntake)" id="schIntake" autocomplete="off" *ngIf="roleAccess.isCreate"
        #schIntake="ngForm" novalidate>
        <div class="col-md-12 ">
          <div class="row">
            <div class="options-tabs col-md-12 p-0"> 
              <div class="col-md-12">
                <div class="mt-3 row">
                  <div class="col-md-3 pr-0">
                    <div class="col-md-12 p-0">
                      <div class="">
  
                        <div class="d-flex" *ngIf="IsshowFirsttab">
                          <div class="d-inline-block mr-2">
                            <span *ngIf="ScheduleFirstviewDate!=undefined" class="current-date text-nowrap">{{ ScheduleFirstviewDate | calendarDate:(view + 'ViewTitle'):'en' }}</span>
                            <span *ngIf="ScheduleFirstviewDate==undefined" class="current-date text-nowrap">Choose a date</span>
                          </div>
                            <a class="mr-2 cursor-pointer" *ngIf="ScheduleFirstviewDate!=undefined" [ngClass]="{'disabled': currentdate >= ScheduleFirstviewDate}">
                              <span mwlCalendarPreviousView [view]="view" [(viewDate)]="ScheduleFirstviewDate"
                                (viewDateChange)="closeOpenMonthViewDay()"><i class="fas fa-chevron-left"></i></span>
                            </a>
                            <a class="mr-2 cursor-pointer" *ngIf="ScheduleFirstviewDate!=undefined">
                              <span mwlCalendarNextView [view]="view" [(viewDate)]="ScheduleFirstviewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                <i class="fas fa-chevron-right"></i></span>
                            </a>
                          
                          <div class="date-hide" *ngIf="this.scheduleType == 1">
                          <mat-form-field class="w-100">
                            <mat-label>Choose a Date</mat-label>
                            <input matInput [matDatepicker]="picker" readonly (click)="picker.open()"
                              #scheduleOptionFirstDate="ngModel" name="scheduleOptionFirstDate"
                              [matDatepickerFilter]=meetingDateFilter
                              [(ngModel)]="_DtoSchAppoinment.scheduleOptionFirstDate"
                              (dateChange)="onDay1MettingDate($event,1)"
                              [ngClass]="{ 'is-invalid': schIntake.submitted && scheduleOptionFirstDate.invalid }"
                              required>
                            
                            <mat-datepicker-toggle matSuffix [for]="picker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="schIntake.submitted && scheduleOptionFirstDate.invalid">
                              <mat-error *ngIf="scheduleOptionFirstDate.errors.required"> Date is
                                required</mat-error>
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="date-hide" *ngIf="this.scheduleType != 1">
                          <mat-form-field class="w-100">
                            <mat-label>Choose a Date</mat-label>
                            <input matInput [matDatepicker]="picker" readonly [min]="currentdate" (click)="picker.open()"
                              #scheduleOptionFirstDate="ngModel" name="scheduleOptionFirstDate"
                              [matDatepickerFilter]=meetingDateFilter
                              [(ngModel)]="_DtoSchAppoinment.scheduleOptionFirstDate"
                              (dateChange)="onDay1MettingDate($event,1)"
                              [ngClass]="{ 'is-invalid': schIntake.submitted && scheduleOptionFirstDate.invalid }"
                              required>
                            
                            <mat-datepicker-toggle matSuffix [for]="picker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="schIntake.submitted && scheduleOptionFirstDate.invalid">
                              <mat-error *ngIf="scheduleOptionFirstDate.errors.required"> Date is
                                required</mat-error>
                            </mat-error>
                          </mat-form-field>
                        </div>
                        </div>

                      </div>
                      
                    </div>
                    
                  </div>
                 
                  <div class="col-md-9">
  
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
  
                      <li class="nav-item dropdown" [ngClass]="{ 'active':IsshowFirsttab == true}">
                        <a class="nav-link " [attr.aria-selected]="IsshowFirsttab"
                          [ngClass]="{ 'active':IsshowFirsttab == true}" id="one-tab" data-toggle="tab" href="#one"
                          role="tab" aria-controls="One" (click)="IsshowFirsttab = true;IsshowSecondtab = false">Selection</a>
                      </li>    
                                 
                      <li class="ml-auto d-flex">
                        <div class="mr-2" *ngIf="scheduleType == schoolmeeting">
                          <select class="form-control " style="margin-top: -10px;"   [(ngModel)]="meetingTypeId"  name="meetingTypeId" required>
                            <option value="undefined">--Select Meeting Type--</option>
                            <option *ngFor="let item of ddlMeetingType " [value]="item.msMeetingTypeId" >{{item.meetingType}}</option>
                          </select>
                          <mat-error *ngIf="schIntake.submitted && meetingTypeId == undefined">
                            <mat-error class="mat-form-field-subscript-wrapper mt-3"
                               *ngIf="meetingTypeId == undefined">Meeting Type is required</mat-error>
                         </mat-error>
                        </div>
                        <div class="user-diff">
                          <span class="user1"><i class="fas fa-circle mr-2"></i>Clinician</span>
                          <span class="user2"><i class="fas fa-circle mr-2"></i>Support Staff</span>
                        </div>
                        <!-- <a class="show-hide-btn cursor-pointer" (click)="showIntakeDetails = !showIntakeDetails">Show
                          Details</a> -->
                        <button type="button" (click)="resetCalender()"
                          class="btn btn primary-btn-outlined-small primary-btn-m mr-2"><i
                            class="font-15 fab fa-rev mr-2"></i>Reset</button>
                        <button type="submit" 
                          class="btn btn primary-btn-filled-small primary-btn-m">Save</button>
                     
                        <!-- <button type="button" (click)="scrollLeft()" class="btn arrow-btn mr-2 ml-2"><i
                            class="fas fa-chevron-left"></i></button>
                        <button type="button" (click)="scrollRight()" class="btn  arrow-btn mr-2"><i
                            class="fas fa-chevron-right"></i></button> -->
                      </li>
                    </ul>
  
                  </div>
                </div>
               
                <div class="row">
                  <div class="tab-content col-md-12 p-0" id="myTabContent">
                    <div class="tab-pane fade show p-3" id="one" role="tabpanel"
                      [ngClass]="{ 'active show':IsshowFirsttab == true}" aria-labelledby="one-tab">
                      <div class="options-content intake-calendar schedule-calendar-view" >
  
                        <div class="col-md-12" *ngIf="ScheduleFirstviewDate != undefined">
                          <!-- <div class="one-style">
                            <h1>Available Slots for Staffs on 10/19/2021</h1>
                          </div> -->
                          <div
                            class="summary-slot-sub mb-2 text-danger mat-form-field-subscript-wrapper position-relative"
                            *ngIf="isReqStaff">
                            Please select available slots
                          </div>
                          <mwl-day-view-scheduler [viewDate]="ScheduleFirstviewDate" [events]="events" [users]="users"
                            [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)"
                            (userChanged)="userChanged($event)" [hourSegments]="2"
                             (eventClicked)="Intake($event)"  [dayStartHour]="07" [dayEndHour]="23"> 
                            <!-- (eventClicked)="eventDay1Clicked($event)"  [dayStartHour]="07" [dayEndHour]="23"> -->
                          </mwl-day-view-scheduler>
                        </div>
                        <div class="no-intake-page text-center" *ngIf="ScheduleFirstviewDate == undefined">
                          <h2>Select {{ScheduleName}} Date</h2>
                          <img src="../../../assets/images/no-intake-available.png">
                        </div>
                      </div>
                    </div>
                    <!-- <div class="tab-pane fade p-3" id="two" role="tabpanel"
                      [ngClass]="{ 'active show':IsshowSecondtab == true}" aria-labelledby="two-tab">
                      <div class="options-content intake-calendar">
                       
                        <div class="col-md-12" *ngIf="IsshowSecondtab">
                          
                          <div class="summary-slot-sub mb-2" *ngIf="ScheduleSecondviewDate != undefined && userlstcopy.length != users.length">
                            Showing the schedule for the staffs chosen in <span class="slot-txt">Option 1.</span>.<span>
                              <a class="show-all-btn cursor-pointer" role="button" (click)="onShowStaff()">Click
                              here</a> <i class="fas fa-hand-pointer"></i> to Show all
                              Staffs</span>
                          </div>
                          <div class="summary-slot-sub mb-2" *ngIf="ScheduleSecondviewDate != undefined && userlstcopy.length == users.length">
                            Showing the schedule for all the staffs. <span class="slot-txt"></span><span><a
                                class="show-all-btn cursor-pointer" role="button" (click)="onShowOption1staff()">Click
                                here </a><i class="fas fa-hand-pointer"></i> to go back to staffs chosen in option 1
                            </span>
                          </div>
                          <div class="" *ngIf="ScheduleSecondviewDate != undefined">
                            <div class=" mb-2 text-danger mat-form-field-subscript-wrapper position-relative"
                              *ngIf="isReqOption2Staff">
                              Please select available slots
                            </div>
                            <mwl-day-view-scheduler [viewDate]="ScheduleSecondviewDate" [events]="day2events"
                              [users]="users" [isScrolltop]="isScrolltop" [refresh]="refresh"
                              (eventTimesChanged)="eventTimesChanged($event)" (userChanged)="userChanged($event)"
                              [hourSegments]="2" (eventClicked)="eventDay2Clicked($event)">
                            </mwl-day-view-scheduler>
                          </div>
                          <div class="no-intake-page text-center" *ngIf="ScheduleSecondviewDate == undefined">
                            <h2>Choose a date for appoinments</h2>
                            <img src="../../../assets/images/no-intake-available.png">
                          </div>
                        </div>
                      </div>
                    </div> -->
  
  
                  </div>
                </div>
              </div>
            </div>
  
            <mat-error *ngIf="isReqMeetingDate" class="mat-form-field-subscript-wrapper">
              Please select different schedule date
            </mat-error>
            <!-- <mat-error *ngIf="isReqMeetingTime" class="mat-form-field-subscript-wrapper">
              Please select different time slots
            </mat-error> -->
          </div>
        </div>
        <!-- <div class="col-md-12 text-right clearfix mt-2" *ngIf="!isShowAssignStaff">
  
          <button type="button" (click)="onAssignStaff(schIntake)" class="btn primary-btn-filled float-right mr-2">Assign
            Staff</button>
        </div>
       <div class="col-md-12  clearfix mt-2" >
          <button type="button"  (click)="onAssignStaff(schIntake)" class="btn primary-btn-filled float-left mr-2"
            >Assign Staff</button>
        </div> -->
        <div class="col-md-12 staff mt-2" *ngIf="isShowAssignStaff">
          <div class="box-lead-head pb-0">Assign Staff </div>
          <div class="col-md-9 pl-0">
            <div class="row">
              <div class="col-lg-6">
                <mat-form-field class="w-100">
                  <mat-label>Primary Clinician</mat-label>
                  <mat-select multiple #PrimaryClinician [(ngModel)]="meetingPrimaryHost" #PrimaryHost="ngModel"
                    name="PrimaryHost" [ngClass]="{ 'is-invalid': schIntake.submitted && PrimaryHost.invalid }" required>
                    <mat-option #matOption *ngFor="let a of ddlPrimarystaff"
                      (click)="getAssignedSlots(a,matOption.selected,1)" [value]="a.staffId">
                      {{a.email}}
                    </mat-option>
                  </mat-select>
  
                </mat-form-field>
                <mat-error *ngIf="schIntake.submitted && PrimaryHost.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper position-relative"
                    *ngIf="PrimaryHost.errors?.required">Primary clinician is
                    required
                  </mat-error>
                </mat-error>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="w-100">
                  <mat-label>Support Staff</mat-label>
                  <mat-select multiple #SupportClinician [(ngModel)]="meetingSupportHost" #SupportHost="ngModel"
                    name="SupportHost" [ngClass]="{ 'is-invalid': schIntake.submitted && SupportHost.invalid }">
                    <mat-option *ngFor="let a of ddlSupportstaff" #matOption2
                      (click)="getAssignedSlots(a,matOption2.selected,2)" [value]="a.staffId">
                      {{a.email}}
                    </mat-option>
                  </mat-select>
  
                </mat-form-field>
              </div>
              
  
            </div>
          </div>
  
  
          <div class="row" *ngIf="showCalender">
  
            <div class="col-md-9 ">
              <div class="row">
                <div class="col-md-6">
                  <div class="date-day">Date 1:{{meetingdates[0].scheduleDate | date:'MM/dd/yyy'}}</div>
                  <mwl-calendar-day-view [viewDate]="Day1viewDate" [events]="staffAppointmentDay1"
                    [dayStartHour]="Day1StartTime" [dayEndHour]="Day1EndTime">
                  </mwl-calendar-day-view>
                </div>
              
  
            </div>
          </div>
        </div>
        </div>
        <div class="col-md-12 text-right clearfix mt-2"
          *ngIf="this.immediateActionId != this.statusintakeAccepted && isShowAssignStaff ">
  
          <!-- <button type="button" class="btn btn-cancel float-right" title="Cancel"
            (click)="resetScheduleform()">Cancel</button> -->
          <button type="button" class="btn btn-cancel float-right" title="Cancel"
            [routerLink]="['/patientslists']">Cancel</button>
          <button type="submit" form="schIntake" class="btn primary-btn-filled float-right mr-2"
            title="Submit">Submit</button>
        </div>
      </form>
  
  
    </div>
  </div>
  

<!--Select Location pop up-->
<div class="comment-popup loc-sched-popup benchmark-popup-rating" >
  <div class="modal" tabindex="-1" role="dialog"  [style.display]="isAllLocation ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container p-3" role="document">
      <div class="modal-content border-0">
          <div class="modal-body">
             <div>
                <div class="text-center location-schedule">
                 <i class="fas fa-map-marked-alt"></i>
                  </div>
                <p  class="text-center font-weight-500">Please choose a location</p>
             </div>
           </div>
          </div>
         </div>
        </div>
  </div>
<!--Select Location pop up end-->
<!-- <div class="comment-popup  cancel-tour" *ngIf="isPopup" >
  <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
    <div class="modal-dialog mat-dialog-container " role="document">

      <div class="modal-content border-0">
<div class="modal-header">
 
  <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="SharePopupClose()">
     <span aria-hidden="true">&times;</span>
   </button>
</div>
        <div class="modal-body">
          
<div >


  <div class="col-md-12">
    <div class="row ">
      <div class="col-md-12">
<div class="row">
 
  <div class="col-md-10" >
  
    <div class="col-md-2" >
     <button mat-raised-button style="min-width: 187px; margin-left: -12px;"  
     class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40" type="submit" 
     form="ShareDocument" (click)="eventDay1Clicked()">Intake schedule</button>
     <button mat-raised-button style="min-width: 187px; margin-left: 236px;position: relative;bottom: 49px;"  
     class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40" type="submit" 
     form="ShareDocument" (click)="Reschedule()">Re-schedule</button>
   </div>
  
  </div>
  
 
</div>
        
      </div>


    </div>

  </div>
  <div class="text-center col-md-12 mt-2">
    
  </div>



</div>


</div>
</div>
</div>

</div>
</div> -->

 <div class="comment-popup  cancel-tour">

  <form (ngSubmit)="isReschedule.form.valid && getConflictsModality()" id="isReschedule" autocomplete="off" *ngIf="isReschedule"
  #isReschedule="ngForm" novalidate>
  <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
    <div class="modal-dialog mat-dialog-container " role="document">
      <div class="modal-content border-0">
        <div class="modal-header row">
          <div class="col-md-5">
            <h6 class="modal-title" >{{headerString}} - </h6>
          </div>
          <div class="col-md-6" >
            <i class="fas fa-user-tie mr-2"></i>
            <span class="meta-value">{{patientdetails?.name}} {{patientdetails?.lastName}}</span><span class="meta-value">({{patientdetails?.age}} yrs
              old)</span>
            <span class="meta-value">{{patientdetails?.gender}}</span>
          </div>
          <div class="col-md-1">
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="SharePopupClose()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
         
        </div>


        <div class="modal-body">
<div >
  
  <div class="col-md-12">
    <div class="row mb-2">
    </div>
    <div class="row mb-2">
      <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Start Date</label>

      </div>

       <div class="col-md-8">
        <mat-form-field class="example-full-width" >
          <mat-label>Choose a date</mat-label>
          <input required matInput 
               disabled 
             [(ngModel)]="scheduleFirstView"
            name="startDate"/>
         
          <mat-error *ngIf="isReschedule.submitted && start.invalid">
            <mat-error *ngIf="startDate.errors.required">Start Date is required
            </mat-error>
         </mat-error>
       </mat-form-field>
      </div>
    </div>
   
     <div class="row mb-2">
      <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Start Time</label>
      </div>


       <div class="col-md-8">     
        <mat-form-field class="w-100 mt--14">
          <mat-label>Start time</mat-label>
          <mat-select  (selectionChange)="OnTimeChange()" [(ngModel)]="eventtime" name="fromtime" [min]="currenttime"
          max="12:00" 
          required>
          <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
              {{item.slotTime | date: 'hh:mm a'}}
          </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-error class="text-xs" *ngIf="IspastTime">
      Start Time is invalid
   </mat-error>
      </div>


    </div> 
    <div class="row mb-2" style="margin-left:1px">
      <div class="col-md-4 pr-0 pl-0">
        <label class="control-label-new font-weight-bold" >Duration</label>

      </div>

       <div class="col-md-4 form-group pl-0">
        <select class="form-control" name="duration1" style="margin-left:4px"
        [(ngModel)]="durationhr"   required  (change)="hoursChange()">
        <option value="undefined">hr</option>         
        <option value=0>0 hr</option>
        <option value=60>1 hr</option>
        <option value=120>2 hr</option>
        <option value=180>3 hr</option>
        <option value=240>4 hr</option>
        <option value=300>5 hr</option>
        <option value=360>6 hr</option>
        <option value=420>7 hr</option>
        <option value=480>8 hr</option>
     </select>
        <span class="text-danger fs12" *ngIf="isHrsrequired">   
          Hours required
          </span>
          <span class="text-danger fs12" *ngIf="IsDurationInvalid">
            Hours invalid
             </span>
      </div>
      <div class="col-md-3 form-group">
        <select class="form-control " name="duration2" style="width: 104px;"
        [(ngModel)]="durationmin"  required (change)="minChange()">
        <option value="undefined">mins</option>
        <option value=00>00 mins</option>
        <option value=15>15 mins</option>
        <option value=30>30 mins</option>
        <option value=45>45 mins</option>             
     </select>
     
      <span class="text-danger fs12 wsnowrap" *ngIf="isMinsrequird">   
        Mins required
        </span>
        <span class="text-danger fs12 wsnowrap " *ngIf="IsDurationInvalid"> Mins invalid
        </span> 
      </div>
    </div> 
  

      <div class="col-md-2" >
        <!-- <button mat-raised-button   
        class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40" style="min-width: 187px; margin-left: 94px; margin-top: 17px;"  type="submit" 
        form="ShareDocument"   (click) ="eventDay1Clicked()">Schedule Intake</button> -->
        <button mat-raised-button   
        class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40" style="min-width: 187px; margin-left: 94px; margin-top: 17px;"  type="submit" 
        form="ShareDocument"   (click) ="eventDay1Clicked()">{{buttonString}}</button>
     
      </div>
  </div>
</div>
</div>
</div>
</div>
</div>
  </form>
 </div> 
 <!-- <div class="comment-popup re-schedule-popup selecttime-popup">
    <div class="modal-dialog mat-dialog-container" role="document">
      <div class="modal-content border-0">
        <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="SharePopupClose()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
        <div class="modal-body">
          <div>
            <form
              (ngSubmit)="isReschedule.form.valid && getConflictsModality()"
              id="isReschedule"
              autocomplete="off"
              #isReschedule="ngForm"
              novalidate
            >
            </form>
          </div>
        </div>
      </div>
    </div>
</div>   -->


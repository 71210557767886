export class Appoinment {
    appointmentTypesId: number;
    meetingCategoryId: number;
    categoryName: string;
    typeName: string;
    frequency: number;
    availableDays: string;
    startTime: string;
    endTime: string;
    timePerSlot: number;
    minPerSlot: number;
    isActive: boolean;
    appointmentFrequencies:Array<AppointmentFrequency>;
    accessLevel: number;
    location:any; 
    locationId:any;
    blockTypeId:number; 
    typeId:number; 
    description: string;
    capacity: number;
    startDate: string;
    endDate: string;
    isEndDate: boolean;
    duration: any;
    isRecurrance: boolean=false;   
    groupFrequency: number;
    blockTypeName: string;
    reasonTypeName: string;
    isSunday: boolean;
    isMonday: boolean;
    isTuesday: boolean;
    isWednesday: boolean;
    isThursday: boolean;
    isFriday: boolean;
    isSaturday: boolean;
    clinicianId:any;
    weekOfMonth : number = 1;
    weekDaysOnMonth :number = 1;
    durationhr : any ;
    durationmins : any;
}
export class AppointmentFrequency{
    appointmentFrequencyId: number;
    appointmentTypesId: number;
    dayId: number;
    startTime : string;
    endTime : string;
    timePerSlot: number;
    minPerSlot: number;
    isDelete: boolean;
    daylabel:string;
    isChecked:boolean;
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { TemplateService } from 'src/app/common/pages/template/template.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { template } from 'src/app/_models/_Common/template';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
@Component({
  selector: 'app-goal-list',
  templateUrl: './goal-list.component.html',
  styleUrls: ['./goal-list.component.scss']
})
export class GoalListComponent implements OnInit {
  @ViewChild('pagGoal') pagGoal: MatPaginator;
  dataSourceGoal: MatTableDataSource<any>;
  isGoalView: boolean = false;
  isGoalCreate: boolean = false;
  isGoalEdit: boolean = false;
  isGoalDelete: boolean = false;
  lstGoals: any = [];
  goalids: any = [];
  decGoallist: any;
  ddlGoalCatagory: any;
  ddlMeasureType: any;
  roleAccess: any;
  viewRightsMsg:any;
  isAdmin: boolean = false;
  constructor(public tempService: TemplateService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService
    ,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    var access = JSON.parse(localStorage.getItem('roleaccess'));
    const pageid = constantVariables.pagelists.Goals;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.viewRightsMsg=constantVariables.PagePermissionMsg.IsView;
	   this.isGoalView = this.roleAccess.isView;
     this.isGoalCreate = this.roleAccess.isCreate;
     this.isGoalEdit = this.roleAccess.isEdit;
     this.isGoalDelete = this.roleAccess.isDelete;
    // var roleaccess = access.details.find(x => x.pageId == pageid);
    // if (roleaccess != null) {
    //   this.isGoalView = roleaccess.isView;
    //   this.isGoalCreate = roleaccess.isCreate;
    //   this.isGoalEdit = roleaccess.isEdit;
    //   this.isGoalDelete = roleaccess.isDelete;
    // }
    // else {
    //   this.isGoalView = true;
    //   this.isGoalCreate = true;
    //   this.isGoalEdit = true;
    //   this.isGoalDelete = true;
    // }

    this.getGoals()
  }
//#region  Goals
getGoals() {
 this.isAdmin = true;
//  const dataToEncrypt = this.commonService.DataToEncrypt(this.isAdmin, false);
  this.tempService.getGoals(this.isAdmin).subscribe(
    res => {
      this.spinnerservice.hide();
      if (res != null) {
       
        this.decGoallist = res;
       // console.log(this.decGoallist)
        this.decGoallist.forEach(x=>{x.goalCatagoryId=x.goalCatagoryId.split(',').map(Number)})
        this.decGoallist.forEach(x=>{x.applicableTreatment=x.applicableTreatment.split(',').map(Number)})
        this.lstGoals = this.decGoallist.slice(0, 20);
      //  console.log(this.lstGoals)
        this.dataSourceGoal = new MatTableDataSource(this.decGoallist);
        this.dataSourceGoal.paginator = this.pagGoal;
        this.getGoalsMaster();
      } 
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
onPageChangeGoal($event) {
  this.lstGoals = this.decGoallist.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
}
cbklistGoal(event, item, index) {
  if (event.checked) {
    this.goalids.push(item.goalId);
  } else {
    this.goalids.forEach((x, index) => {
      if (x === item.goalId) this.goalids.splice(index, 1);
    });
  }
}
ondeletemultipleGoal() {
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.SuccessMessage.DeleteConfirmMessage
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
      const dataToEncrypt = this.commonService.DataToEncrypt(this.goalids, false);
      this.tempService.deleteMultipleGoals(dataToEncrypt).subscribe(
        (res: any) => {
          this.goalids = [];
          this.snackbar.success(JSON.parse(res).message);
          this.getGoals();
          this.spinnerservice.hide();

        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);

        },
      );

    }
  });

}
oneditGoal(id) {
  this.spinnerservice.show();
  this.router.navigate(['/config-goal'], { queryParams: { id: this.commonService.FrontEndEncryption(id) } });

}
ondeleteGoal(obj) {


  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.confirmation.DeleteConfirmMessage
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
      this.spinnerservice.show();

      this.tempService.deleteGoals(obj.goalId)
        .subscribe(
          data => {
            this.spinnerservice.hide();
            this.snackbar.success(data.message);
            this.getGoals();
          },
          error => {
            this.spinnerservice.hide();
            this.snackbar.error(error.error);
          });
    }
    else {
      return
    }
  });

}

getGoalsMaster() {
  this.tempService.getGoalsMaster().subscribe(
    res => {
      this.spinnerservice.hide();
      if (res != null) {
        this.ddlGoalCatagory = res.goalCatagory;
        this.decGoallist.forEach(x=>x.catagoryName=[])
        this.decGoallist.map(x=>{
          this.ddlGoalCatagory.forEach(y=>{
           if(x.goalCatagoryId.includes(y.goalCatagoryId)){
             x.catagoryName.push(y.catagoryName)
           }
          })
         });
        // console.log(this.decGoallist)
        this.ddlMeasureType = res.goalMeasure;
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}

//#endregion
}

import { Component, OnInit,ViewChild,ViewChildren,QueryList, ElementRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table'; 
import { MatPaginator } from '@angular/material/paginator';
import { LeadService } from '../patient-lead/lead.service';
import { constantVariables } from 'src/app/constant/constant';
import {RoelAccess,MenuList} from 'src/app/_models/_Common/roleAccessMaster';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { PatientService } from 'src/app/patient/patient.service';
import { Task } from '../_models/_Common/task';
import { NgForm } from '@angular/forms';
import { Global } from '../global/global.model';
import { TaskService } from '../common/pages/task/task.service';
import { MatDialog } from '@angular/material/dialog';
//import { LeadListviewComponent } from 'src/app/patient-lead/lead-listview/lead-listview.component';
declare function decrypt(data): any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  // @ViewChild(MatPaginator) paginatorAlert : MatPaginator;
  // @ViewChild(MatPaginator) paginatorAppointment : MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('paginatorTask') paginatorTask: MatPaginator;
  @ViewChild('paginatorAlert') paginatorAlert: MatPaginator;
  @ViewChild('paginatorAppointments') paginatorAppointments: MatPaginator;
  @ViewChild('paginatorGrpAppointments') paginatorGrpAppointments: MatPaginator;
  @ViewChild('paginatorTour') paginatorTour: MatPaginator;

  dataSourceAppointments: MatTableDataSource<any>;
  dataSourceAlert:MatTableDataSource<any>;
  dataSourceTask:MatTableDataSource<any>;
  dataSourceGrpAppointments:MatTableDataSource<any>;
  dataSourceTour:MatTableDataSource<any>;
  selectedTab: number;
  appointmentList:any;
  loadAppointments:any;
  upcommingAppointmentCount:number;
  expiredAppointmentCount:number;
  appointments:any=[];
  defaultRecords: any = 20;
  public pageSize = 10;
  public currentPage = 0;
  public totalSize = 0;
  alertList:any = [];
  alertListLoad:any=[];
  today = new Date();
  parentFirstName: any;
  statusResendActivationMail = constantVariables.status.ResendActivationMail;
  statusUploadInsurance = constantVariables.status.UploadInsurance;
  statusReviewPacket = constantVariables.status.ReviewPacket;
  statusReturn = constantVariables.status.Return;
  statusVerified = constantVariables.status.Verified;
  statusCrbaUpdate = constantVariables.status.CrbaUpdate;
  statusFinancial = constantVariables.status.financial;
  statusintake = constantVariables.status.forIntake;
  statusreviewTour = constantVariables.status.reviewTour;
  statusdecline = constantVariables.status.Decline;
  statusstaffAssigned = constantVariables.status.staffAssigned;
  statusRequestTour=constantVariables.status.requestTour;
  statusTourCompleted=constantVariables.status.tourCompleted;
  statusPsyReschedule = constantVariables.status.psytherapyReschedule;
  alertCount:number;
  financialDenyReason: any;
  isFinancialDecline: boolean = false;
  roleAccess:RoelAccess=new RoelAccess();
  MenuAccess: MenuList;
  grpAppointmentList:any;
  loadGrpAppointments:any;
  upcommingGrpAppointmentCount:number;
  expiredGrpAppointmentCount:number;
  appointmentsGrp:any=[];
  tourdashboard : any = [];
  tourList:any;
  upcomingTour:number;
  expiredTour :any = [];
  loadTour:any;
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],                                       // remove formatting button


      ],

    },

    keyboard: {
      bindings: {
        shiftEnter: {
          key: 13,
          shiftKey: true,
          handler: (range, context) => {
            // Handle shift+enter
            //console.log("shift+enter")
          }
        },
        enter: {
          key: 13,
          handler: (range, context) => {
           // console.log("enter");
            return true;
          }
        }
      }
    }
  }

  isShowAddtask: boolean = false;
  isValidFlagDate: boolean = true;
  isFormSubmitted: boolean = false;
  isViewTask: boolean = false;
  taskinfo: Task;
  // @ViewChild('taskfrm') taskForm: NgForm;
  //  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  // @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  // selectedTab: number;
  lstTasks: any;
  Decrptedlst:any;
  taskCount : any;
  totalCount : any;
  lstuser: any;
  currentdate: any;
  UploadedFileList = [];
  UploadFileList = [];
  ddlPriority = constantVariables.priority;
  UserId: any;
  Assignedto: any;
  LocationId: any;
  Nodata:boolean=false;
  NodataUpcomming:boolean=false;
  NodataAlert:boolean=false;
  // @ViewChild('taskfile') eletaskfile: ElementRef;


  // TaskinfoComponent : any;
  // const subject = new BehaviorSubject(0);

  
  constructor(private router: Router, private route: ActivatedRoute,private commonservice : CommonService,
              public spinnerservice: NgxSpinnerService, private leadService : LeadService, private roleaccessservice : PageAccessService,
              private snackbar : SnackBarService, public patientservice : PatientService,public global: Global, private dialog: MatDialog,
              public taskService: TaskService,public commonService: CommonService
             ) { }
              

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Dashboard;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.today = new Date();
    this.getAppointmentList();
    this.onTabChange(0);
    this.MenuAccess = this.roleaccessservice.GetSiteHeaderAccess();
    this.taskinfo = new Task();
    // this.Assignedto = new Task();
    this.UserId = Number(localStorage.getItem("id"));
    this.getStaffList();
    // this.TaskViewDetails();
    // this.getCount();
    this.getGrpAppointmentList();
    this.getTask();
    this.GetTourList();
  }
  onTabChange(tab) {

    this.selectedTab = tab;
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: tab
      }
    });
    if (tab == 0) {
      this.getAlerts();
    }else if(tab == 1)
    {
      this.getAppointmentList();
    }else if(tab == 2){
      this.getGrpAppointmentList();
      // this.DetailView();
  }else if(tab == 3){
    this.GetTourList();
  }

  }
  updateActionForAlerts(item)
  {
    this.spinnerservice.show();
     this.leadService.updateActionForAlerts(item.PatientInfoId,item.ImmediateActionId).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          
          this.alertList = decrypt(res.objInputString);
          
        }
          
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getAlerts()
  {
    this.spinnerservice.show();
    let locationid= Number(localStorage.getItem('sfcclocation'))==0?-1:Number(localStorage.getItem('sfcclocation'));
    this.leadService.getAdminDashboardAlertLists(locationid).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          
          this.alertList = decrypt(res.objInputString);
          this.alertCount = this.alertList.length > 0 ? this.alertList.length : 0;
          this.alertListLoad = this.alertList.slice(0, this.defaultRecords);
          this.dataSourceAlert = new MatTableDataSource(this.alertList);
          this.dataSourceAlert.paginator = this.paginatorAlert;
          if(res.length > 0)
          {
          this.NodataAlert = false;
          }
          else{
            this.NodataAlert = true;
          }
        }
          
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
 
  getAppointmentList()
  {
    this.spinnerservice.show();
    let locationid= Number(localStorage.getItem('sfcclocation'))==0?-1:Number(localStorage.getItem('sfcclocation'));
    this.commonservice.getUpcommingAppointmentsAdmin(locationid).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          var date = new Date().getTime();
          var dexpiredTime = new Date(date - 2 * 60 * 60 * 1000);
          this.appointments = res;
        
          this.appointmentList = this.appointments.filter(x=> moment(x.fromTime).format("HH:mm") >= moment(dexpiredTime).format("HH:mm"));
          this.upcommingAppointmentCount = this.appointmentList.length > 0 ? this.appointmentList.length : 0 ;
          this.expiredAppointmentCount = this.appointments.filter(x=> moment(x.fromTime).format("HH:mm") <= moment(dexpiredTime).format("HH:mm")).length > 0 ? this.appointments.filter(x=> moment(x.fromTime).format("HH:mm") <= moment(dexpiredTime).format("HH:mm")).length : 0;
          this.loadAppointments = this.appointmentList.slice(0, this.defaultRecords);
          this.dataSourceAppointments = new MatTableDataSource(this.appointmentList);
          this.dataSourceAppointments.paginator = this.paginatorAppointments;
          if(res.length > 0)
          {
          this.NodataUpcomming = false;
          }
          else{
            this.NodataUpcomming = true;
          }
        }
          
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getGrpAppointmentList()
  {
    this.spinnerservice.show();
    let locationid= Number(localStorage.getItem('sfcclocation'))==0?-1:Number(localStorage.getItem('sfcclocation'));
    this.commonservice.getUpcmgGrpAppointmentsAdmin(locationid).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          
          var date = new Date().getTime();
          var dexpiredTime = new Date(date - 2 * 60 * 60 * 1000);
          this.appointmentsGrp = res;
        
          this.grpAppointmentList = this.appointmentsGrp.filter(x=> moment(x.fromTime).format("HH:mm") >= moment(dexpiredTime).format("HH:mm"));
          this.upcommingGrpAppointmentCount = this.grpAppointmentList.length > 0 ? this.grpAppointmentList.length : 0;
          this.expiredGrpAppointmentCount = this.appointmentsGrp.filter(x=> moment(x.fromTime).format("HH:mm") <= moment(dexpiredTime).format("HH:mm")).length > 0 ? this.appointmentsGrp.filter(x=> moment(x.fromTime).format("HH:mm") <= moment(dexpiredTime).format("HH:mm")).length : 0;
          this.loadGrpAppointments = this.grpAppointmentList.slice(0, this.defaultRecords);
          this.dataSourceGrpAppointments = new MatTableDataSource(this.grpAppointmentList);
          this.dataSourceGrpAppointments.paginator = this.paginatorGrpAppointments;
          if(res.length > 0)
          {
          this.Nodata = false;
          }
          else{
            this.Nodata = true;
          }
        }
          
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  GetTourList()
  {
    this.spinnerservice.show();
    let locationid= Number(localStorage.getItem('sfcclocation'))==0?-1:Number(localStorage.getItem('sfcclocation'));
    this.commonservice.GetTourList(locationid).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          // var date = new Date().getTime();
          // var dexpiredTime = new Date(date - 2 * 60 * 60 * 1000);
          // this.tourdashboard = res;
        
          // this.tourList = this.tourdashboard.filter(x=> moment(x.fromTime).format("HH:mm") >= moment(dexpiredTime).format("HH:mm"));
          // this.upcomingTour = this.tourList.length > 0 ? this.tourList.length : 0;
          // this.expiredTour = this.tourdashboard.filter(x=> moment(x.fromTime).format("HH:mm") <= moment(dexpiredTime).format("HH:mm")).length > 0 ? this.tourdashboard.filter(x=> moment(x.fromTime).format("HH:mm") <= moment(dexpiredTime).format("HH:mm")).length : 0;
          // this.loadTour = this.tourList.slice(0, this.defaultRecords);
          this.tourList = decrypt(res.objInputString);
          this.upcomingTour = this.tourList.length > 0 ? this.tourList.length : 0;
          this.expiredTour = this.tourList.slice(0, this.defaultRecords);
          this.dataSourceTour = new MatTableDataSource(this.tourList);
          this.dataSourceTour.paginator = this.paginatorTour;
          if(res.length > 0)
          {
          this.Nodata = false;
          }
          else{
            this.Nodata = true;
          }
        }
          
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onPageAppointmentChange($event) {
    //ngfor array                 //array
    this.loadAppointments = this.appointmentList.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  onPageGrpAppointmentChange($event) {
    //ngfor array                 //array
    this.loadGrpAppointments = this.grpAppointmentList.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  onPageAlertChange($event) {
    //ngfor array                 //array
    this.alertListLoad = this.alertList.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  onTourChange($event)
{
  this.expiredTour = this.tourList.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);

}  
  
  viewAppointents()
  {
    this.router.navigate(['/scheduler']);
  }
  onTherapyChange(event)
  {
    var typeid : number = +event.value;
    if(typeid == 0)
    {
      this.loadAppointments = this.appointmentList;
    }else{
    this.loadAppointments = this.appointmentList.filter(x=>x.typeId == typeid);
    }
  }
  onAlertChange(event){
    var typeid : number = +event.value;
    if(typeid == 0)
    {
      this.alertListLoad = this.alertList;
    }else{
    this.alertListLoad = this.alertList.filter(x=>x.ImmediateActionId == typeid);
    }
  }
  ResendActivationDashboard(item) {
    this.updateActionForAlerts(item);
    this.spinnerservice.show();
    let _obj = {
      PatientInfoId: item.PatientInfoId,
    //  Email: item.ParentList[0].Email
    Email: item.Email,
    IsPatient : true
    }
    this.leadService.postResendActivation(_obj).subscribe(
      (res: any) => {
        this.snackbar.success(res)
        this.spinnerservice.hide();
        this.getAlerts();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  intakeScheduleDashboard(item) {
    this.updateActionForAlerts(item);
    localStorage.setItem("immediateActionId", item.ImmediateActionId);
    //this.router.navigate(['/intake-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientAccountId), name: this.commonService.FrontEndEncryption(item.FirstName), pageid: 1 } });
    let obj = {
      name: item.FirstName,
      patientAccountId: item.PatientAccountId,
      pageid: 1,
      age: moment().diff(item.Dob, 'years'),
      gender: item.GenderType,
      lastName:item.LastName,
      location:item.SfccMasterId

    }
    this.router.navigate(['/intake-schedule'], { queryParams: { id: this.commonservice.FrontEndEncryption(obj),page :this.commonService.FrontEndEncryption(1) } });
  }
  Patientinfo(id, item) {
    this.updateActionForAlerts(item);
    //this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(id), email: this.commonService.FrontEndEncryption(item.ParentList[0].Email), name: this.commonService.FrontEndEncryption(item.FirstName) } });
    this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonservice.FrontEndEncryption(id), email: this.commonservice.FrontEndEncryption(item.Email), name: this.commonservice.FrontEndEncryption(item.FirstName)  } });
  }
  UpdateCRBA(id,item) {
    this.updateActionForAlerts(item);
    this.router.navigate(['/crba'], { queryParams: { id: this.commonservice.FrontEndEncryption(id),page :this.commonService.FrontEndEncryption(1) } });
  }
  SubmitFinancial(item) {
    this.updateActionForAlerts(item);
    this.parentFirstName =item.ParentName;
    this.router.navigate(['/financial-proposal'], { queryParams: { id: this.commonservice.FrontEndEncryption(item.PatientInfoId), name: this.commonservice.FrontEndEncryption(item.FirstName), parentname: this.commonservice.FrontEndEncryption(this.parentFirstName),page :this.commonService.FrontEndEncryption(1) } });
  }
  RequestTour(item) {
    if(item.Status==0){
      item.Status=null;
    }
    this.updateActionForAlerts(item);
    this.router.navigate(['/request-tour'], { queryParams: { id: this.commonservice.FrontEndEncryption(item.PatientInfoId), name: this.commonservice.FrontEndEncryption(item.FirstName),page :this.commonService.FrontEndEncryption(1) } });
  }
  getfinancialReasonDashboard(item) {
    this.updateActionForAlerts(item);
    this.leadService.getfinancialReason(item.PatientInfoId).subscribe(
      (res: any) => {

        this.spinnerservice.hide();
        this.isFinancialDecline = true;
        this.financialDenyReason = "";
        this.financialDenyReason = res.reason;

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  closeDeclineProcess() {
    this.isFinancialDecline = false;
  }
  IntakeSchedule(item) {
    this.updateActionForAlerts(item);
    if(this.MenuAccess.ScheduleIntake){
      localStorage.setItem("immediateActionId", item.ImmediateActionId);
      let obj = {
        name: item.FirstName,
        patientAccountId: item.PatientAccountId,
        pageid: 1,
        age:moment(item.Dob, "DD-MM-YYYY").diff(moment(), 'years'),
        gender: item.GenderType,
        location:item.SfccMasterId
  
      }
      this.router.navigate(['/intake-schedule'], { queryParams: { id: this.commonservice.FrontEndEncryption(obj),page :this.commonService.FrontEndEncryption(1) } });
    }
    else{
      this.snackbar.error(constantVariables.SuccessMessage.AccessPermissionError)
    }
    
  }
  RedirectTo(item,selectedType)
  {
    this.updateActionForAlerts(item);
    if(selectedType=="1")
    {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/patient-breifcase'], { queryParams: { id: this.commonservice.FrontEndEncryption(item.PatientInfoId)}}));
    window.open(url, '_blank');
    }
    else if(selectedType=="2")
    {
      this.router.navigate(['/schedule-modality'], { queryParams: { id: this.commonservice.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonservice.FrontEndEncryption(item.PatientAccountId),patient:this.commonservice.FrontEndEncryption(item.PatientInfoId),treatmentPlanDate:item.TreatmentPlanDate,locationId :this.commonservice.FrontEndDecryption(item.SfccMasterId),page :this.commonService.FrontEndEncryption(1) } });
    }
    if(selectedType=="3")
    {
     if(this.MenuAccess.TreatmentPlan){
    const url = this.router.serializeUrl(this.router.createUrlTree(['/treatmentplan'], { queryParams: { id: this.commonservice.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonservice.FrontEndEncryption(item.PatientAccountId),patient:this.commonservice.FrontEndEncryption(item.PatientInfoId),page :this.commonService.FrontEndEncryption(1)   }}));
     window.open(url, '_blank');
     }
     else{
      this.snackbar.error(constantVariables.SuccessMessage.AccessPermissionError)
     }
    }
  }
  AcceptReschdule(data) {
    this.updateActionForAlerts(data);
    this.spinnerservice.show();
    this.patientservice.onReScheduleAccept(data.MeetingScheduleId).subscribe(
      res => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(res.message);
          this.getAlerts();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  DeclineReschdule(data) {
    this.updateActionForAlerts(data);
    this.spinnerservice.show();
    this.patientservice.onReScheduleDeny(data.MeetingScheduleId).subscribe(
      res => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(res.message);
          this.getAlerts();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  AcceptCancelIntake(data) {
    this.updateActionForAlerts(data);
    this.spinnerservice.show();
    let obj = {
      MeetingScheduleId: data.MeetingScheduleId,
      Type: 3
    }
    const dataToEncrypt = this.commonservice.DataToEncrypt(obj, false);
    this.patientservice.onAcceptCancelIntake(dataToEncrypt).subscribe(
      (res: any) => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getAlerts();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  DeclineCancelIntake(data) {
    this.updateActionForAlerts(data);
    this.spinnerservice.show();
    let obj = {
      MeetingScheduleId: data.MeetingScheduleId,
      Type: 2
    }
    const dataToEncrypt = this.commonservice.DataToEncrypt(obj, false);
    this.patientservice.onDeclineCancelIntake(dataToEncrypt).subscribe(
      (res: any) => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getAlerts();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
   
  
  TaskViewDetails(data:any) {
    console.log(data);
    console.log(this.ddlPriority);
    console.log(this.lstuser);
    // data.priority = 1;

      data.assignedStaffName = data?.isSelfAssigned ? "" : data?.assignedStaffName
    
    data.assigned="";
    this.isShowAddtask = !this.isShowAddtask;
    this.isValidFlagDate=true;
    this.isFormSubmitted=false;
    this.taskinfo=data;
    this.Assignedto = data.assignedTo;


  }
  getStaffList() {
    if(localStorage.getItem('roleId')==constantVariables.role.PrimaryClinician || localStorage.getItem('roleId')==constantVariables.role.SupportClinician){
        if(!!localStorage.getItem('location')){

          // this.LocationId = localStorage.getItem('location').split('"').map(Number)[1];
        }
        else{
          this.LocationId = -1;
        }
    }
    else{
      this.LocationId = localStorage.getItem('sfcclocation');
    }
    this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician,this.LocationId).subscribe(
      (res: any) => {
        if (res) {
          this.lstuser = res;
console.log(this.lstuser);

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onPageChange($event) {
    this.lstTasks = this.Decrptedlst.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  // onPaginationChange(event){
  //   var typeid : number = +event.value;
  //   if(typeid == 0)
  //   {
  //     this.lstTasks = this.Decrptedlst;
  //   }else{
  //   this.lstTasks = this.Decrptedlst.filter(x=>x.ImmediateActionId == typeid);
  //   }
  // }
  // // onPageChange($event) {
  //   //ngfor array                 //array
  //   this.lstLead = this.DecrptedlstLead.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  // }


  getTask() {
  this.spinnerservice.show();
  this.taskService.getTasksDashboard(0).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if (res) {
        this.Decrptedlst = res;
        console.log(this.Decrptedlst);
        this.lstTasks = this.Decrptedlst.slice(0, this.defaultRecords);
        console.log(this.lstTasks)
        this.dataSourceTask = new MatTableDataSource(this.Decrptedlst);
        console.log(this.dataSourceTask?.filteredData?.length)
        this.dataSourceTask.paginator = this.paginatorTask;
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );  
}
getCount(){
  this.spinnerservice.show();
  this.taskService.getCompletedCount(0).subscribe(
    
    (res:any) => {
      this.spinnerservice.hide();
      if (res) {
         this.taskCount = res.completeCount;
       //this.taskCount = this.appointments.filter(x=> moment(x.fromTime).format("HH:mm") <= moment(dexpiredTime).format("HH:mm")).length;
        this.totalCount = res.spGetTasks.length;
        this.Decrptedlst = res;
        // this.lstTasks = this.Decrptedlst.slice(0, 5);
        // console.log(this.lstTasks)

    }
  },
  err => {
    this.spinnerservice.hide();
  },
  );
}    
saveTaskInfo(form: NgForm) {
  var dueDate = moment(this.taskinfo.dueDate).format("MM/DD/YYYY");
  var overDate = moment(this.taskinfo.overDate).format("MM/DD/YYYY");
  var flagDate = moment(this.taskinfo.flagMeBy).format("MM/DD/YYYY");
  this.isFormSubmitted=true;
  //var ValidFlagDate = this.validateDates(dueDate,flagDate);
  var ValidFlagDate = moment(flagDate).isSameOrBefore(dueDate);

  if (!ValidFlagDate) {
    this.isValidFlagDate = false;
    return
  } else {
    this.isValidFlagDate = true;
  }
  this.taskinfo.taskPriority = Number(this.taskinfo.taskPriority);
  this.taskinfo.documents = this.UploadedFileList;
  this.spinnerservice.show();
  //const dataToEncrypt = this.commonService.DataToEncrypt(this.taskinfo, false);
  this.taskService.postTaskInfo(this.taskinfo).subscribe((res: any) => {
    if (res) {
      this.spinnerservice.hide();
      form.resetForm();
      this.resetnotesform();
      this.snackbar.success(JSON.parse(res).message);
      this.getTask();
      this.isFormSubmitted=false;
    }
  },
    err => {
      this.spinnerservice.hide();
    }
  );
}
resetnotesform() {
  this.taskinfo = new Task();

  // this.isEditTask = false;
  this.isShowAddtask = false;
  this.UploadFileList = [];
  this.UploadedFileList = [];
  this.isViewTask = false;

}
changeRemainder(){   
  if(this.isFormSubmitted){
    var dueDate = moment(this.taskinfo.dueDate).format("MM/DD/YYYY");
    var flagDate = moment(this.taskinfo.flagMeBy).format("MM/DD/YYYY");
    var ValidFlagDate = moment(flagDate).isSameOrBefore(dueDate);

    if (!ValidFlagDate) {
      this.isValidFlagDate = false;
      return
    } else {
      this.isValidFlagDate = true;
    }
  }
}
onSelfAssign(event){
  if(event.checked){
    this.taskinfo.assignedTo = this.UserId;
  }else{
    // this.taskinfo.assignedTo = null;
  }
}
onFileChange(event) {

  const reader = new FileReader();
  var _validFileExtensions = [".jpg", ".jpeg", ".png", ".pdf", ".doc", ".docx"];
  if (event.target.files && event.target.files.length) {
    const [file] = event.target.files;
    if (file.size > 5020905) {
      this.snackbar.error(constantVariables.ErrorMessage.filesize)
      return
    }
    if ((Array.from(event.target.files).length + this.UploadedFileList?.length) <= 3) {
      if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "application/pdf" || file.type == "application/msword" || file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        reader.readAsDataURL(file);

        reader.onload = () => {

          this.UploadFileList = [];
          var files = Array.from(event.target.files);
          for (var i = 0; i < files.length; i++) {

            // this.UploadFileList.push(files[i]);

          }
          const frmData = new FormData();
          for (var i = 0; i < this.UploadFileList.length; i++) {
            frmData.append("doc", this.UploadFileList[i]);
          }
          frmData.append('DocumentTypeId', constantVariables.DocumentTypeId.Task);
          this.spinnerservice.show();
          this.taskService.postTaskImage(frmData).subscribe((res: any) => {

            if (res) {
              // this.eletaskfile.nativeElement.value = "";
              this.spinnerservice.hide();
              if (this.UploadedFileList?.length == 0) {
                this.UploadedFileList = res;
              } else {
                // this.UploadedFileList.push(...res);
              }

            }
          }, err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          });
          // this.eletaskfile.nativeElement.value = "";
        };
      } else {
        // this.eletaskfile.nativeElement.value = "";
        this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
      }
    } else {
      // this.eletaskfile.nativeElement.value = "";
      this.snackbar.error("Maximum 3 files allowed")
    }
  }
}

DetailView() {
  this.spinnerservice.show();
  this.taskService.getTasksDashboard(0).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if (res) {
        this.Decrptedlst = res;
        this.lstTasks = this.Decrptedlst.slice(0, 5);
        console.log(this.lstTasks);
        this.dataSource = new MatTableDataSource(this.Decrptedlst);
        // this.dataSource.paginator = this.paginator;
        this.dataSource.paginator = this.paginator.toArray()[0];

      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );  
}
  downloadUploadedFile(item) {

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', item.filePath);
    link.setAttribute('download', item.filename);
    document.body.appendChild(link);
    link.click();
    link.remove();



  }

}

import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { dtoReScheduleModality } from 'src/app/_models/_Treatment/scheduleModality';
import { DtoPostInactiveAppointments } from 'src/app/_models/_Treatment/_dayschedule';
import { AdminTherapyPopUpObj } from 'src/app/_models/_Treatment/_TherapyPopUpObj';
import { CommonService } from 'src/app/common/common.service';
import { SettingsService } from '../pages/settings/settings.service';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';

@Component({
  selector: 'app-therapy-details-pop-up',
  templateUrl: './therapy-details-pop-up.component.html',
  styleUrls: ['./therapy-details-pop-up.component.scss']
})
export class TherapyDetailsPopUpComponent implements OnInit {
  @Input() ObjTherapyPopUp:AdminTherapyPopUpObj;
  isShowTherapydetails: boolean=false;
  isShowNotes: boolean=false;
  adminNotes:string="";
  isNotesErr: boolean;
  @ViewChild('scheduleModality') currentForm: NgForm;
  dtoScheduleModality: dtoReScheduleModality;
  PopUpReschedule: boolean;
  PopUpBlockReschedule: boolean;

  ddlMeetingType: any;
  ddlMeetinglocation: any;
  ddlSfccLocation: any;
  currenttime: string= '00.00';
  currentdate: string;
  newToTimeBlock:any;
  scheduleModalityList: any[];
  dtoScheduleConflictModalitylist: any[];
  dateList: any[];
  errConflicts: boolean;
  ddlStafflist: any;
  ddlStafflistadditional: any;
  ddlDeleteReason: any;
  PopUpDelete: boolean;
  PopUpBlockDelete:boolean;
  DeleteReason=0;
  DeleteComments:string
  DeleteMeetingscheduleclick: boolean;
  DeleteMeetingBlockscheduleclick: boolean;
  GetClinicianName: any[];
  GetSupportName: any[];
  GetStaffName: any[];
  GetAdditionalName: any[];

  AdditionalProvider=0;
  SupportStaff=0;

 AdditionalProviderId: number[];
ProviderId: number[];
SupportStaffId: number [];
IsSameProvider:boolean=false;
IsSameAdditionalProvider:boolean=false;
IsSameSupportStaff:boolean=false;



  IsgroupTherapy: any;
  _dtoPostInactiveAppointments: DtoPostInactiveAppointments;
  IsPast: boolean=false;
  IspastTime:boolean=false;
Intake = constantVariables.therapy.Intake;
Tour = constantVariables.therapy.Tour;
GroupTherapy = constantVariables.therapy.GroupTheraphy;
Phonecall = constantVariables.therapy.Phonecall;
School = constantVariables.therapy.School;
Accept = constantVariables.status.Accept;
TourCompleted = constantVariables.status.tourCompleted;
ReviewTour =constantVariables.status.reviewTour;
StaffAssigned = constantVariables.status.staffAssigned;
Financial = constantVariables.status.financial;
TourName = constantVariables.therapyName.tour;
PhonecallSceduler = constantVariables.therapy.PhonecallSceduler;
SchoolmeetingScheduler = constantVariables.therapy.SchoolmeetingScheduler;
//BlockTimeScheduler=constantVariables.theraphy.BlockTimeScheduler;
  isShowAddress: boolean;
  Address:string;
  isVmeetingErr: boolean;
  virtualMeettxt: string;
  isVirtualMeet: boolean;
  isAddErr: boolean;
  meetingTimeslots:any;
  LocationId: any;
  roleAccess: RoelAccess;
  showGrpTherapyPopup : boolean = false;
  showTherapyPopup : boolean = false;
  showBlockTimePopup:boolean=false;
  clienttype : any;
  groupTherapyList:any;
  showDs:boolean=false;
  startDate:any;
  userId : any;
  ddlSupportStafflist : any;
  constructor(public global: Global, public settingsService: SettingsService, private cdr: ChangeDetectorRef,
    private snackbar: SnackBarService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog, public commonService: CommonService, public clinicianservice: ClinicianService, public spinnerservice: NgxSpinnerService, public treatmentservice: TreatmentService,
    public datepipe: DatePipe, public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.ScheduleAppointmentsTherapies;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.loadPage();

    this.commonService.GroupPopShow.subscribe((res) => {
      this.loadPage();

    });

  }
  loadPage(){

     // Get selected  staffid
     this.treatmentservice.getProviderId(this.ObjTherapyPopUp.id).subscribe( (res: any) => {  
      this.GetStaffName=res;
      this.ProviderId = this.GetStaffName.map(provider => provider.providerId);
     // this.ProviderId =this.GetStaffName[0].providerId;
      console.log(this.GetStaffName[0].providerId);
      
  },
  )
  // Get selected support staffid
  this.treatmentservice.getSupportStaffId(this.ObjTherapyPopUp.id).subscribe( (res: any) => {  
    this.GetSupportName=res;
    this.SupportStaffId = this.GetSupportName.map(provider => provider.supportStaffId);

   // this.SupportStaffId =this.GetSupportName[0].supportStaffId;
    console.log(this.GetSupportName[0].supportStaffId);
},
)  

// Get Additional Additional Provider
this.treatmentservice.getAdditionalProviderId(this.ObjTherapyPopUp.id).subscribe( (res: any) => {  
  this.GetAdditionalName=res;
  this.AdditionalProviderId = this.GetAdditionalName.map(provider => provider.additionalProviderId);
 // this.AdditionalProviderId =this.GetAdditionalName[0].additionalProviderId;
  console.log(this.GetAdditionalName[0].additionalProviderId);
},
)

 

    this.dtoScheduleModality = new dtoReScheduleModality();
    if(new Date()<this.ObjTherapyPopUp.scheduleDate){
      this.currenttime = '00.00'
    }else{
      this.currenttime = moment().format("HH:mm");
    }
    //this.currenttime = moment().format("HH:mm");
    this.currentdate = moment().subtract(2, 'days').format("YYYY-MM-DD HH:mm:ss");    
    this._dtoPostInactiveAppointments = new DtoPostInactiveAppointments();
    this.IsPast = (this.datepipe.transform(new Date(this.currentdate), "yyyy-MM-dd"))> (this.datepipe.transform(this.ObjTherapyPopUp.start, "yyyy-MM-dd"))
   // this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
   this.LocationId = this.ObjTherapyPopUp.Location;
   //this.checkGroupMeeting(this.ObjTherapyPopUp);
   if(this.ObjTherapyPopUp.GroupTherapyId != undefined ){
    this.treatmentservice.GetGroupCount(this.ObjTherapyPopUp.GroupTherapyId, moment(this.ObjTherapyPopUp.start).format("MM/DD/YYYY")).subscribe((res: any) => {
    
      this.ObjTherapyPopUp.GroupTherapyLength = res;
      if(this.ObjTherapyPopUp.GroupTherapyId != undefined && this.ObjTherapyPopUp.scheduleDate > new Date() && this.ObjTherapyPopUp.GroupTherapyLength == 0){
        this.showGrpTherapyPopup = true;
        this.showTherapyPopup = false;
        this.showBlockTimePopup=false;
      }else if(this.ObjTherapyPopUp.GroupTherapyId != undefined && this.ObjTherapyPopUp.scheduleDate < new Date() && this.ObjTherapyPopUp.GroupTherapyLength == 0) 
      {
        this.showDs = false;
        this.showTherapyPopup = true;
        this.showGrpTherapyPopup = false;
      }
      else{
        this.showDs = true;
        this.showTherapyPopup = true;
        this.showGrpTherapyPopup = false;
      }
     
    });
    
    //this.checkGroupMeeting(this.ObjTherapyPopUp.GroupTherapyLength)
    // this.grpStarttime =this.ObjTherapyPopUp
    // this.grpendTime = 
   }else{
     if(this.ObjTherapyPopUp.ScheduleName =="BlockTime" || this.ObjTherapyPopUp.ScheduleName =="Block") 
    {
      this.showDs = false;
      this.showTherapyPopup = false;
      this.showGrpTherapyPopup = false;
      this.showBlockTimePopup=true;

    }
else
{
  this.showTherapyPopup = true;
  this.showGrpTherapyPopup = false;
  //this.getgrouptherapy(this.ObjTherapyPopUp);
}
   
   }

     

   this.LoadMasters()
   if (localStorage.getItem('id')) {
    this.userId = localStorage.getItem('id');
  }
  }
  checkIn(data){
    this.router.navigate(['/attendance'], 
    {
       queryParams: 
      { 
        id: this.commonService.FrontEndEncryption(data.GroupTherapyId),
        encDate : this.commonService.FrontEndEncryption(data.scheduleDate),
        IsAdmin : this.commonService.FrontEndEncryption(true)
      } 
    });
  }
  onFormGroup(data,form : NgForm){
    if(!form.valid)
    {
      return;
    }
    // if(this.clienttype == 1)
    // {
    //   this.typeSelected = "IOP"  
    // }else if(this.clienttype == 2){
    //   this.typeSelected = "PCP"
    // }
    // this.router.navigate(['/dailyscheduler'], { queryParams: { GroupTherapyId: this.commonService.FrontEndEncryption(data.GroupTherapyId),clientType:this.commonService.FrontEndDecryption(this.clienttype),scheduleDate:this.commonService.FrontEndDecryption(data.scheduleDate)}});
    this.router.navigate(['/grouptherapy'], { queryParams: { groupTherapyId: this.commonService.FrontEndEncryption(data.GroupTherapyId),clientType:this.commonService.FrontEndDecryption(this.clienttype),scheduleDate:data.scheduleDate,disable:this.commonService.FrontEndDecryption(false)}});
  }
  loadFormGroup(data){
    var disableDs;
    if(data.scheduleDate < new Date())
    {
      disableDs = true;
    }else{
      disableDs = false;
    }
    this.router.navigate(['/grouptherapy'], { queryParams: { groupTherapyId: this.commonService.FrontEndEncryption(data.GroupTherapyId),clientType:this.commonService.FrontEndDecryption(this.clienttype),scheduleDate:data.scheduleDate,disable:this.commonService.FrontEndDecryption(disableDs)}});
  }
  OnClientType(val)
  {
    this.clienttype = val;
    // if(val == 1){
    //   this.clienttype = true;
    // }else{
    //   this.clienttype = false;
    // }
  }
  OnLoadSessionDetails(item){
    if(item.tags == constantVariables.therapy.GroupTheraphy )
    {   
      // let grouptherapyid=this.grouptherapyAppointments.find(x=>x.dayScheduleAssignId==item.id).groupTherapyId;
      // let dsgsubgrpId=this.grouptherapyAppointments.find(x=>x.dayScheduleAssignId==item.id).dayScheduleSubGroupId;
      
      //  this.router.navigate(['/day-schedule'], { queryParams: { dsaId: this.commonService.FrontEndEncryption(event.id),dsgsubgrpId:this.commonService.FrontEndEncryption(dsgsubgrpId), grpid: this.commonService.FrontEndEncryption(grouptherapyid)
      //   ,theraphyid: this.commonService.FrontEndEncryption(event.tags),date: this.commonService.FrontEndEncryption(moment(event.start).format('MM/DD/YYYY'))
       
    
       this.router.navigate(['/day-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(item.GroupTherapyId),
                                                               theraphyid: this.commonService.FrontEndEncryption(item.tags),
                                                               paramClinician: this.commonService.FrontEndEncryption(item.ClinicianId),
                                                                date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")) } });
      }
      
    else if (item.tags ==constantVariables.therapy.IndividualTheraphy || item.tags ==constantVariables.therapy.FamilyTheraphy 
      ||item.tags == constantVariables.therapy.PsycatricTheraphy || item.tags ==constantVariables.therapy.PsycatricEvaluation || item.tags ==constantVariables.therapy.PsycatricMedication  || item.tags == constantVariables.therapy.Speech || item.tags == constantVariables.therapy.Nutrition
      || item.tags == constantVariables.therapy.Intake  || item.tags == constantVariables.therapy.PhonecallSceduler  || item.tags == constantVariables.therapy.SchoolmeetingScheduler ||item.tags==constantVariables.theraphy.BlockTimeScheduler
      )
    {
    this.router.navigate(['/day-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id), date: this.commonService.FrontEndEncryption(moment(item.start).format('MM/DD/YYYY'))
    ,theraphyid: this.commonService.FrontEndEncryption(item.tags),mstid:this.commonService.FrontEndEncryption(item.ScheduleTimeId)
   } });
  }
  }
  //therapy pop up close
  OncloseTherapyDetailspopup(){
    this.ObjTherapyPopUp = undefined;
   this.commonService.TherapyPopup.next(false);
  }
  
  //open meeting link in new window
  OnMeetingLinkClick(item){
    const url = item.MeetingLink
    window.open(url, '_blank');
  }
  
  OnAddAdminNotes(item){
    this.adminNotes = item.AdminNotes
    this.isShowNotes=true;
  }
  OncancelNotes(){
    this.isShowNotes = false
  }
  saveNotes(item)
  {
    this.isNotesErr=false;
    if(this.adminNotes=="" || this.adminNotes==null)
    {
     //this.snackbar.error("Notes is empty,enter valid notes");
     this.isNotesErr=true;
     return;
    }
    else{
    let notesobj;
    notesobj = {
      TherapyId: item.tags,
      MeetingScheduleTimeDsaId: item.ScheduleTimeId,
      ScheduleNotes:this.adminNotes,
      UserId:localStorage.getItem("id")
    }
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(notesobj, false);
    this.treatmentservice.postSchedularAdminNotes(dataToEncrypt).subscribe(
      (res: any) => {
  
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);  
        window.location.reload();
        },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
}
  OnCancelReschedule(){
    this.PopUpReschedule=false;
    this.dtoScheduleModality = new dtoReScheduleModality();
    this.currentForm.resetForm();
// this.AdditionalProviderId=0;
// this.SupportStaffId=0;
// this.ProviderId=0;
  }
  
  GetMeetingTypeMaster(){
    this.spinnerservice.show();
    this.treatmentservice.GetMeetingTypeMaster().subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res.length>0){
          this.ddlMeetingType=res;
          this.ddlMeetingType = this.ddlMeetingType.filter(x=>x.commonAppointmentTypeid ==0)
        // console.log(this._dtoPatientInfo);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  
  getMeetingLocation() {
    this.spinnerservice.show();
    let locationId=+localStorage.getItem('sfcclocation')

    this.settingsService.getMeetingRoom(locationId).subscribe(
      res => {
        this.spinnerservice.hide();
        this.ddlMeetinglocation = res.filter(x=>x.isActive != false);
        // this.getAllScheduleModality();
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  getddlLocation() {
    this.spinnerservice.show();
    this.treatmentservice.getLocations().subscribe(
      (res) => {
        this.spinnerservice.hide();
        if (res != null) {
          this.ddlSfccLocation = res;
        }
      },
      (err) => {
        this.spinnerservice.hide();
      }
    );
  }
  
  handleStartDatechange(event) {
    const m: moment.Moment = event.value;
  
     // console.log("Date of Birth: " + m.toDate());
     // console.log(this.currentdate)
      if(this.datepipe.transform(m.toDate(),"yyyy-MM-dd")==this.datepipe.transform(this.currentdate,"yyyy-MM-dd")){
        this.currenttime = moment().format("HH:mm");
      }
      else{
        this.currenttime = '00.00'
      }
      this.OnTimeChange();
  }
  DirectToScheduleModality(starttime){
    this.scheduleModalityList =[]
    this.scheduleModalityList.push({
      // day: data[i].day,
      scheduleDate: moment(this.startDate).format('MM/DD/YYYY'),
      isChecked: false,
      fromTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),
      toTime:moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes))), 'minute').format('HH:mm A'),
      isConflict: false})
    this.postSchduleModality();
  }
  
  getConflictsModality(){
    // if( moment(this.dtoScheduleModality.startTime).format("HH:mm")<this.currenttime){
    //   this.IspastTime=true;
    //   return
    // }


    
    var offset = new Date().getTimezoneOffset();
    this.scheduleModalityList = [];
    this.dtoScheduleConflictModalitylist = []
    this.startDate =  new Date(moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY'))
    let starttime = moment(this.dtoScheduleModality.startTime).format("HH:mm");

    let startTime = moment(this.dtoScheduleModality.startTime); 
    let newTime = startTime; 
    let formattedTime = newTime.add(1, 'minutes').format("HH:mm");
    let newToTime = moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes - 1))), 'minute').format('HH:mm A')
    let _obj = {
      StartDate: moment.utc(this.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
      EndDate: moment.utc(this.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
      FromTime: formattedTime,
      ToTime : newToTime,
      // ToTime: moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes))), 'minute').format('HH:mm A'),
      Provider: this.dtoScheduleModality.provider[0]
    }
    let _objAdditional = {
      StartDate: moment.utc(this.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
      EndDate: moment.utc(this.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
      FromTime: formattedTime,
      ToTime : newToTime,
      // ToTime: moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes))), 'minute').format('HH:mm A'),
      Provider: this.dtoScheduleModality.additionalprovider[0]
    }
    let _objSupport = {
      StartDate: moment.utc(this.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
      EndDate: moment.utc(this.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
      FromTime: formattedTime,
      ToTime : newToTime,
      // ToTime: moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes))), 'minute').format('HH:mm A'),
      Provider: this.dtoScheduleModality.supportstaffprovider[0]
    }
   // if(this.AdditionalProviderId>0 && this.dtoScheduleModality.additionalprovider>0 )
   if (this.AdditionalProviderId[0] > 0 && this.dtoScheduleModality.provider[0] > 0)
  
    {
      if (this.AdditionalProviderId[0] ==this.dtoScheduleModality.additionalprovider[0])
      {
        this.IsSameAdditionalProvider=true;
      }

    } 
    if(this.SupportStaffId[0]>0 && this.dtoScheduleModality.supportstaffprovider[0]>0)
    {
      if (this.SupportStaffId[0] ==this.dtoScheduleModality.supportstaffprovider[0])
      {
        this.IsSameSupportStaff=true;
      }

    }  
    
    if(this.ProviderId[0]>0 && this.dtoScheduleModality.provider[0]>0 )
    {
      if (this.ProviderId[0] ==this.dtoScheduleModality.provider[0])
      {
        this.IsSameProvider=true;
      }

    } 
   

    if(moment(this.ObjTherapyPopUp.fromtime).format("HH:mm") == moment(this.dtoScheduleModality.startTime).format("HH:mm")
     && this.dtoScheduleModality.startDate == this.ObjTherapyPopUp.scheduleDate  && this.IsSameProvider==true)
    {
      this.DirectToScheduleModality(starttime);
    }
  //  else if(moment(this.ObjTherapyPopUp.fromtime).format("HH:mm") == moment(this.dtoScheduleModality.startTime).format("HH:mm")
  //   && this.dtoScheduleModality.startDate == this.ObjTherapyPopUp.scheduleDate  && (this.IsSameProvider==true || this.IsSameSupportStaff==false || this.IsSameAdditionalProvider ==false))
  //  {
  //    this.DirectToScheduleModality(starttime);
  //  }
  //  else if(moment(this.ObjTherapyPopUp.fromtime).format("HH:mm") == moment(this.dtoScheduleModality.startTime).format("HH:mm")
  //  && this.dtoScheduleModality.startDate == this.ObjTherapyPopUp.scheduleDate  && (this.IsSameProvider==false && this.IsSameSupportStaff==true))
  // {
  //   this.DirectToScheduleModality(starttime);
  // }
    else{
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.treatmentservice.getConfilctsForModality(dataToEncrypt).subscribe(
      (res: any) => {
        this.dtoScheduleConflictModalitylist = JSON.parse(res);
        this.spinnerservice.hide();
        if(this.dtoScheduleConflictModalitylist.length==0){
if(this.dtoScheduleModality.provider==this.dtoScheduleModality.additionalprovider)
{
  this.snackbar.error("Please select different Additional Provider, both Provider and Additional Provider are same");
  return true; 
}

else
{
  if(this.dtoScheduleModality.additionalprovider[0]>0 &&  this.IsSameAdditionalProvider==false)
  {
    if(this.AdditionalProviderId[0]>0 || this.dtoScheduleModality.additionalprovider[0]>0 )
          {
            if (this.AdditionalProviderId[0] !=this.dtoScheduleModality.additionalprovider[0])
            {
              const dataToEncrypt = this.commonService.DataToEncrypt(_objAdditional, false);
    this.treatmentservice.getConfilctsForModality(dataToEncrypt).subscribe(
      (res: any) => {
        this.dtoScheduleConflictModalitylist = JSON.parse(res);
        this.spinnerservice.hide();
        if(this.dtoScheduleConflictModalitylist.length>0)
        { 
          this.skipAllConflictsNew(this.dtoScheduleModality.additionalprovider[0]);
        }
        else
        {
          this.DirectToScheduleModality(starttime);
        }
      },
        err => {
          this.spinnerservice.hide();
    
        },
      );
            }

          }  
      
    // this.snackbar.error("Please select different Additional Provider, He has conflict");
    // return true; 
  }
  else if (this.dtoScheduleModality.supportstaffprovider[0]>0 &&  this.IsSameSupportStaff==false) 
{
  

  if(this.SupportStaffId[0]>0 && this.dtoScheduleModality.supportstaffprovider[0]>0)
  {
    if (this.SupportStaffId[0] !=this.dtoScheduleModality.supportstaffprovider[0])
    {
      const dataToEncrypt = this.commonService.DataToEncrypt(_objAdditional, false);
      this.treatmentservice.getConfilctsForModality(dataToEncrypt).subscribe(
        (res: any) => {
          this.dtoScheduleConflictModalitylist = JSON.parse(res);
          this.spinnerservice.hide();
          if(this.dtoScheduleConflictModalitylist.length>0)
          { 
            this.skipAllConflictsNew(this.dtoScheduleModality.supportstaffprovider[0]);
          
          }
        else
        {
          this.DirectToScheduleModality(starttime);
        }
    
        },
          err => {
            this.spinnerservice.hide();
      
          },
        );
    }
  }
  
  // this.snackbar.error("Please select different support staff, He has conflict");
  // return true; 
}
else
{
  
  // this.snackbar.error("Will procced with proccess");
  // return true; 
  this.DirectToScheduleModality(starttime);
}

}

// Adding additional clinician and support staff for intake Jan 31 2024

  

        }
        else{
          this.dateList = [];
        //  this.dateList = this.getDaysBetweenDates(moment(this.dtoScheduleModality.startDate).utcOffset(-offset), moment(this.dtoScheduleModality.endDate).utcOffset(-offset));
    
        this.onBetweenappointment(this.dtoScheduleConflictModalitylist);

        // Modified on Jan 31 2024
       //   this.skipAllConflicts();
    
       this.skipAllConflictsNew(this.dtoScheduleModality.provider[0]);
        }
      },
      err => {
        this.spinnerservice.hide();
  
      },
    );
    }
  }



  getDayBWDates(start, end, dayName, lstday) {
    var result = [];
    for (let i = 0; i < lstday.length; i++) {
      var days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
      var day = days[dayName.toLowerCase().substr(0, 3)];
      var current = new Date(start.toDate());
      current.setDate(current.getDate() + (lstday[i] - current.getDay() + 7) % 7);
      while (current <= end.toDate()) {
        result.push(new Date(+current));
        current.setDate(current.getDate() + 7);
      }
    }
    return result;
  }
  
  onBetweenappointment(data) {
    this.scheduleModalityList = [];
    let starttime = moment(this.dtoScheduleModality.startTime).format("HH:mm");
    for (let i = 0; i < data.length; i++) {
      this.scheduleModalityList.push({
        // day: data[i].day,
        scheduleDate: moment(data[i].scheduleDate).format('MM/DD/YYYY'),
        isChecked: false,
        fromTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),
        toTime: moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes))), 'minute').format('HH:mm A'),
        isConflict: this.getIsConflict(data[i].scheduleDate),
        // location: data[i].location,
        // locationName: this.getLocationName(data[i].location),
      });
    }
  
  
  
  
  }
  
  
  getDaysBetweenDates(startDate, endDate) {
    let betweendates = [];
    while (startDate.isSameOrBefore(endDate)) {
      betweendates.push(new Date(startDate));
      startDate.add(1, 'days');
    }
    return betweendates;
  };
  
  skipAllConflicts() {
    this.errConflicts = false;
    this.scheduleModalityList = this.scheduleModalityList.filter((item) => item.isConflict != true);
    if(this.scheduleModalityList.length!=0){
      this.postSchduleModality();
    }
    else{
      this.snackbar.error(this.ObjTherapyPopUp.clinicianName + constantVariables.SuccessMessage.FailedToReschedule + moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY') + " " + this.dtoScheduleModality.startTime)
    }
  }
  skipAllConflictsNew(ClinicianID) {
    this.errConflicts = false;
    this.scheduleModalityList = this.scheduleModalityList.filter((item) => item.isConflict != true);
    if(this.scheduleModalityList.length!=0){
      this.postSchduleModality();
    }
    else{
       this.treatmentservice.getClinicianName(ClinicianID).subscribe( (res: any) => {  
        this.GetClinicianName =res;
        this.snackbar.success(this.GetClinicianName[0].clinicianName);
 console.log(this.GetClinicianName[0].clinicianName);
 let clinicianName=this.GetClinicianName[0].clinicianName;


      this.spinnerservice.hide();
      this.snackbar.error(this.GetClinicianName[0].clinicianName+ constantVariables.SuccessMessage.FailedToReschedule + moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY') + " " + this.dtoScheduleModality.startTime)
     
    },
    )
    }
  }
  
  getIsConflict(conflictDate: any) {
    let isConflictlist = this.dtoScheduleConflictModalitylist.filter((item) => item.isConflict == true);
    for (let i = 0; i < isConflictlist.length; i++) {
      let firstDate = moment(isConflictlist[i].scheduleDate).format('MM/DD/YYYY');
      let secondDate = moment(conflictDate).format('MM/DD/YYYY');
      if ((firstDate == secondDate) && isConflictlist[i].isConflict == true) {
  
        return true;
      }
      else{
        return false;
      }
    }
  
  }
  
  postSchduleModality() {
    let checkconfilcts = this.scheduleModalityList.filter((item) => item.isConflict == true).length;
     if (checkconfilcts > 0) {
      this.errConflicts = true;
      return;
    }
    else{

      this.dtoScheduleModality.provider = this.normalizeProvider(this.dtoScheduleModality.provider);

      if(this.dtoScheduleModality.additionalprovider[0]>0)
      {
       this.AdditionalProvider=this.dtoScheduleModality.additionalprovider[0];
      }
      else
      {
        this.AdditionalProvider=0;
      }
      if(this.dtoScheduleModality.supportstaffprovider[0]>0)
      {
      this.SupportStaff=this.dtoScheduleModality.supportstaffprovider[0];
      }
      else
      {
        this.SupportStaff=0;
      }

      let obj;
        let MeetingTimesArray = [];
        MeetingTimesArray.push(this.scheduleModalityList[0])
        obj = {
          MeetingCategoryId: 1,
          MeetingScheduleId:this.ObjTherapyPopUp.id,
          MeetingLocationId: this.dtoScheduleModality.location,
          PatientAccountId: this.ObjTherapyPopUp.patientAccountId,
        //  PatientInfoId: this._dtoPatientInfo.patientInfoId,
          ScheduleName: this.ObjTherapyPopUp.ScheduleName + " - "+this.ObjTherapyPopUp.patientName,
          Provider: this.dtoScheduleModality.provider,
          MeetingTimes: MeetingTimesArray,
          ModalityDetails: this.dtoScheduleModality,
          PreviousMeetingScheduleTimeId : this.ObjTherapyPopUp.ScheduleTimeId,
          Commands:this.dtoScheduleModality.rescheduleComments,
          MeetingTypeId: this.dtoScheduleModality.meetingType,
          AdditionalProvider:this.dtoScheduleModality.additionalprovider,
          SupportStaff: this.dtoScheduleModality.supportstaffprovider
          // SupportStaff:this.SupportStaff
          // AdditionalProvider:this.AdditionalProvider,
          // SupportStaff:this.SupportStaff
        }
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.treatmentservice.postReSchduleMeeting(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();      
        this.snackbar.success(res);      
      window.location.reload();
  
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  
  }
 
  getStaffList()
  {
  this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician,this.LocationId).subscribe(
      (res: any) => {
        if (res) {
          this.ddlStafflist = res;            
                  }   
        },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getStaffListAddtional()
  {
 
  this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician,this.LocationId).subscribe(
      (res: any) => {
        if (res) {
          this.ddlStafflistadditional = res;    
        }   
        },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getSupportStaffList()
  {
  this.commonService.getSupportStaffListV2(constantVariables.role.SupportClinician,this.LocationId).subscribe(
      (res: any) => {
        if (res) {
          this.ddlSupportStafflist = res;
          }   
        },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  
  // onRescheduleMS(item){
  //   if(this.ObjTherapyPopUp.tags == this.Tour){
  //     this.router.navigate(['/request-tour'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), name: this.commonService.FrontEndEncryption(item.userName) } });
  //   }else{
  //     if(this.ProviderId>0)
  //    {
  //     this.dtoScheduleModality.provider[0]=this.ProviderId;
  //    }
  //    else
  //    {
  //     this.dtoScheduleModality.provider= undefined;
  //    }
     
  //    if(this.AdditionalProviderId>0)
  //     {
  //       this.dtoScheduleModality.additionalprovider[0]=this.AdditionalProviderId;
  //     } 
  //     else
  //     {
  //       this.dtoScheduleModality.additionalprovider=undefined;
  //     }
  //     if(this.SupportStaffId>0)
  //     {
  //       this.dtoScheduleModality.supportstaffprovider[0]=this.SupportStaffId;
  //     } 
  //     else
  //     {
  //       this.dtoScheduleModality.supportstaffprovider=undefined;
  //     }
  //     let date = '2021-05-28'
  //     this.PopUpReschedule = true;
  //     // this.dtoScheduleModality.provider = item.ClinicianId;
  //     this.dtoScheduleModality.provider[0]
  //      = this.ProviderId;
  //     this.dtoScheduleModality.startDate =item.scheduleDate;
  //     this.dtoScheduleModality.startTime =moment(date).format("YYYY-MM-DD") + " " + moment(item.fromtime).format('HH:mm')+":00.000";
  //    // console.log(this._dtoPatientInfo.startTime)
  //     this.dtoScheduleModality.location = item.Location;
  //     this.dtoScheduleModality.meetingType =  item.MeetingType;
  //     this.dtoScheduleModality.duration = item.Duration;
  //     this.dtoScheduleModality.meetingRoom = item.MeetingRoom;
  //     this.dtoScheduleModality.therapyId = item.tags
  //     //Retriving Hours and Minutes
  //     const totalMinutes = this.ObjTherapyPopUp.Duration;
  //     var duration = Math.floor(totalMinutes / 60) * 60;
  //     this.dtoScheduleModality.duration = duration;
  //     this.dtoScheduleModality.minutes = totalMinutes % 60;


  //   }
   
  // }


  onRescheduleMS(item) {
    if (this.ObjTherapyPopUp.tags === this.Tour) {
      this.router.navigate(['/request-tour'], {
        queryParams: {
          id: this.commonService.FrontEndEncryption(item.patientAccountId),
          name: this.commonService.FrontEndEncryption(item.userName)
        }
      });
    } else {
      if (this.ProviderId[0] > 0) {
        // Initialize if undefined
        if (!Array.isArray(this.dtoScheduleModality.provider)) {
          this.dtoScheduleModality.provider = [];
        }
        this.dtoScheduleModality.provider = this.ProviderId;
      } else {
        this.dtoScheduleModality.provider = []; // Initialize as empty array instead of undefined
      }
  
      if (this.AdditionalProviderId[0] > 0) {
        if (!Array.isArray(this.dtoScheduleModality.additionalprovider)) {
          this.dtoScheduleModality.additionalprovider = [];
        }
        this.dtoScheduleModality.additionalprovider = this.AdditionalProviderId;
      } else {
        this.dtoScheduleModality.additionalprovider = []; // Initialize as empty array
      }
  
      if (this.SupportStaffId[0] > 0) {
        if (!Array.isArray(this.dtoScheduleModality.supportstaffprovider)) {
          this.dtoScheduleModality.supportstaffprovider = [];
        }
        this.dtoScheduleModality.supportstaffprovider = this.SupportStaffId;
      } else {
        this.dtoScheduleModality.supportstaffprovider = []; // Initialize as empty array
      }
  
      let date = '2021-05-28';
      this.PopUpReschedule = true;
      this.dtoScheduleModality.provider[0] = this.ProviderId[0];
      this.dtoScheduleModality.startDate = item.scheduleDate;
      this.dtoScheduleModality.startTime = moment(date).format("YYYY-MM-DD") + " " + moment(item.fromtime).format('HH:mm') + ":00.000";
      this.dtoScheduleModality.location = item.Location;
      this.dtoScheduleModality.meetingType = item.MeetingType;
      this.dtoScheduleModality.duration = item.Duration;
      this.dtoScheduleModality.meetingRoom = item.MeetingRoom;
      this.dtoScheduleModality.therapyId = item.tags;
  
      // Retrieving Hours and Minutes
      const totalMinutes = this.ObjTherapyPopUp.Duration;
      var duration = Math.floor(totalMinutes / 60) * 60;
      this.dtoScheduleModality.duration = duration;
      this.dtoScheduleModality.minutes = totalMinutes % 60;
    }
  }
  
  LoadMasters()
  {
    this.GetDeleteReasonForMs();
    this.getStaffList();
    this.getStaffListAddtional();
    this.getSupportStaffList();
    
    this.getMeetingLocation();
    this.getddlLocation();
    this.GetMeetingTypeMaster();
    this.getSchedulerTimeSlots();
  }
  GetDeleteReasonForMs(){
    this.spinnerservice.show();
    this.treatmentservice.GetDeleteReasonForMs().subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res.length>0){
          this.ddlDeleteReason=res;
        // console.log(this._dtoPatientInfo);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  
  ondeleteMS() {
  
    this.PopUpDelete = true
  }
  OncloseDeletePopup(){
    this.DeleteReason=0;
    this.DeleteComments="";
    this.DeleteMeetingscheduleclick = false
    this.PopUpDelete = false
  
  }
  deleteMeetingSchedule(item)
  {
    this.DeleteMeetingscheduleclick = true
    this.spinnerservice.show();
    if(!this.IsgroupTherapy){
      if(item.isPatientVisit==true)
         {
        this.spinnerservice.hide();
       this.snackbar.error('Unable to delete the scheduled appointment');
        return;
       }
       if(this.DeleteReason==0){
        this.spinnerservice.hide();
         return;
       }
      this._dtoPostInactiveAppointments.PatientAccountId=item.patientAccountId;
      this._dtoPostInactiveAppointments.DayScheduleAssignId=item.ScheduleTimeId;
      this._dtoPostInactiveAppointments.DayScheduleSubGroupId=item.id;
      this._dtoPostInactiveAppointments.TheraphyId=item.tags;
      this._dtoPostInactiveAppointments.TherapyDate=item.scheduleDate;
      this._dtoPostInactiveAppointments.UserId=localStorage.getItem('id');
      this._dtoPostInactiveAppointments.AppointmentId = item.AppointmentId
    }
    else
    {
      this._dtoPostInactiveAppointments.PatientAccountId=item.patientAccountId;
     this._dtoPostInactiveAppointments.DayScheduleSubGroupId=item.DayScheduleSubGroupId;
    this._dtoPostInactiveAppointments.DayScheduleAssignId=item.DayScheduleAssignId;
     this._dtoPostInactiveAppointments.GroupTherapyId=item.GroupTherapyId;
    this._dtoPostInactiveAppointments.TherapyDate=item.scheduleDate;
    this._dtoPostInactiveAppointments.TheraphyId=3;
    this._dtoPostInactiveAppointments.UserId=localStorage.getItem('id');
  
    }
    this._dtoPostInactiveAppointments.Reason = this.DeleteReason;
    this._dtoPostInactiveAppointments.Comments = this.DeleteComments;
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoPostInactiveAppointments, false);
    this.treatmentservice.postinactiveAppointments(dataToEncrypt).subscribe(
      (res: any) => {
        this.OncloseTherapyDetailspopup()
         this.spinnerservice.hide();        
         if(JSON.parse(res).id==1){this.snackbar.success(JSON.parse(res).message);
        //  this.router.navigate(['/scheduler']);}
           window.location.reload();
         }
           else if(JSON.parse(res).id==0){this.snackbar.error(JSON.parse(res).message);}
  
         },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  
  }
  OnVirtualMeetingLink(item){
this.virtualMeettxt = item.MeetingLink;
this.isVirtualMeet = true
  }

  OnAddress(item){
    this.Address = item.OffSiteAddress;
    this.isShowAddress = true;
  }
  OncancelAddress(){
    this.isShowAddress = false
  }

  saveAddress(item)
  {
    this.isAddErr=false;
    if(this.Address==null)
    {
     //this.snackbar.error("Notes is empty,enter valid notes");
     this.isAddErr=true;
     return;
    }
    let notesobj;
    notesobj = {
      TherapyId: item.tags,
      MeetingScheduleTimeDsaId: item.ScheduleTimeId,
      OffSiteAddress:this.Address,
      UserId:localStorage.getItem("id")
    }
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(notesobj, false);
    this.treatmentservice.postSchedularoffsiteaddress(dataToEncrypt).subscribe(
      (res: any) => {
  
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);  
        window.location.reload();
        },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  saveVirtualMeet(item)
{
  this.isVmeetingErr=false;
  let vmobj;
  if(this.virtualMeettxt==null)
  {
   //this.snackbar.error("Text is empty,enter valid meeting link");
   this.isVmeetingErr=true;
   return;
  }
  vmobj = {
    TherapyId: item.tags,
    MeetingScheduleTimeDsaId: item.ScheduleTimeId,
    MeetingLinks:this.virtualMeettxt,
    UserId:localStorage.getItem("id")
  }
  this.spinnerservice.show();
  const dataToEncrypt = this.commonService.DataToEncrypt(vmobj, false);
  this.treatmentservice.postSchedularAdminMeetingLink(dataToEncrypt).subscribe(
    (res: any) => {
      this.isVirtualMeet=true;
      this.spinnerservice.hide();
      this.snackbar.success(JSON.parse(res).message);
      window.location.reload();
      },
    err => {
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}

OnCancelVirtualmeet(){
  this.isVirtualMeet = false
}

getSchedulerTimeSlots() {
  this.settingsService.getSchedulerTimeSlots().subscribe(
    res => {
      if (res != null) {
        this.meetingTimeslots = res;


      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}

generateTimeSlots(sDate, eDate, time) {
  if (sDate != null && sDate != undefined && eDate != null && eDate != undefined) {
    var start = moment(sDate);
    var end = moment(eDate);
    // let diffMs = end.getTime() - start.getTime(); // milliseconds
    // let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    let diffMins = end.diff(start, 'minutes')
    if (time > diffMins) {
      return true
    } else {
      return false
    }
  }
}
OnSuicidalAssesment(item)
{ 
  if(item.tags == constantVariables.therapy.Intake)
  {
    this.router.navigate(['/sucidal-risk'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId)}});
  }
}

OnTimeChange(){
  return;
  if( moment(this.dtoScheduleModality.startTime).format("HH:mm")>this.currenttime){
    this.IspastTime=false;
    }
    else{
      this.IspastTime = true;
    }
  }
  onRescheduleBlockScheduler(item){
    if(this.ObjTherapyPopUp.tags == this.Tour){
      this.router.navigate(['/request-tour'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), name: this.commonService.FrontEndEncryption(item.userName) } });
    }else{
      let date = '2021-05-28'
      this.PopUpBlockReschedule = true;
      this.dtoScheduleModality.provider = item.ClinicianId;
      this.dtoScheduleModality.startDate =item.scheduleDate;
      this.dtoScheduleModality.startTime =moment(date).format("YYYY-MM-DD") + " " + moment(item.fromtime).format('HH:mm')+":00.000";
     // console.log(this._dtoPatientInfo.startTime)
      
      this.dtoScheduleModality.duration = item.Duration;
      this.dtoScheduleModality.therapyId = item.tags
      //Retriving Hours and Minutes
      const totalMinutes = item.Duration;
      var duration = Math.floor(totalMinutes / 60) * 60;
      this.dtoScheduleModality.duration = duration;
      this.dtoScheduleModality.minutes = totalMinutes % 60;
    }
   
  }
  OnCancelBlockReschedule(){
    this.PopUpBlockReschedule=false;
    this.dtoScheduleModality = new dtoReScheduleModality();
   this.currentForm.resetForm()
  }
  onBlockdeleteMS() {
  
    this.PopUpBlockDelete = true
  }
  OncloseBlockDeletePopup(){
    this.DeleteReason=0;
    this.DeleteComments="";
    this.DeleteMeetingBlockscheduleclick = false
    this.PopUpBlockDelete = false
  
  }
  deleteMeetingBlockSchedule(item)
  {
    this.DeleteMeetingBlockscheduleclick = true
    this.spinnerservice.show();
    if(!this.IsgroupTherapy){
      if(item.isPatientVisit==true)
         {
        this.spinnerservice.hide();
       this.snackbar.error('Unable to delete the scheduled appointment');
        return;
       }
       if(this.DeleteReason==0){
        this.spinnerservice.hide();
         return;
       }
      this._dtoPostInactiveAppointments.PatientAccountId=item.patientAccountId;
      this._dtoPostInactiveAppointments.DayScheduleAssignId=item.ScheduleTimeId;
      this._dtoPostInactiveAppointments.DayScheduleSubGroupId=item.id;
      this._dtoPostInactiveAppointments.TheraphyId=17;
      this._dtoPostInactiveAppointments.TherapyDate=item.scheduleDate;
      this._dtoPostInactiveAppointments.UserId=localStorage.getItem('id');
      this._dtoPostInactiveAppointments.AppointmentId = item.AppointmentId
    }
    else
    {
      this._dtoPostInactiveAppointments.PatientAccountId=item.patientAccountId;
     this._dtoPostInactiveAppointments.DayScheduleSubGroupId=item.DayScheduleSubGroupId;
    this._dtoPostInactiveAppointments.DayScheduleAssignId=item.DayScheduleAssignId;
     this._dtoPostInactiveAppointments.GroupTherapyId=item.GroupTherapyId;
    this._dtoPostInactiveAppointments.TherapyDate=item.scheduleDate;
    this._dtoPostInactiveAppointments.TheraphyId=17;
    this._dtoPostInactiveAppointments.UserId=localStorage.getItem('id');
  
    }
    this._dtoPostInactiveAppointments.Reason = this.DeleteReason;
    this._dtoPostInactiveAppointments.Comments = this.DeleteComments;
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoPostInactiveAppointments, false);
    this.treatmentservice.postinactiveAppointments(dataToEncrypt).subscribe(
      (res: any) => {
        this.OncloseTherapyDetailspopup()
         this.spinnerservice.hide();        
         if(JSON.parse(res).id==1){this.snackbar.success(JSON.parse(res).message);
        //  this.router.navigate(['/scheduler']);}
           window.location.reload();
         }
           else if(JSON.parse(res).id==0){this.snackbar.error(JSON.parse(res).message);}
  
         },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  
  }
  getConflictsModalityBlockTime(item){
    if(this.dtoScheduleModality.provider.toString()=="undefined")
    {

      this.spinnerservice.hide();
      this.snackbar.error("Please select the provider");
      return;
    }
    if(this.dtoScheduleModality.duration.toString()=="undefined")
    {

      this.spinnerservice.hide();
      this.snackbar.error("Please select the duration");
      return;
    }
    if(this.dtoScheduleModality.minutes.toString()=="undefined")
    {

      this.spinnerservice.hide();
      this.snackbar.error("Please select the minutes");
      return;
    }
    if(this.dtoScheduleModality.minutes.toString()=="0" && this.dtoScheduleModality.duration.toString()=="0")
    {

      this.spinnerservice.hide();
      this.snackbar.error("Please select the valid duration");
      return;
    }

  

    var offset = new Date().getTimezoneOffset();
    this.scheduleModalityList = [];
   
    this.dtoScheduleConflictModalitylist = []
    this.startDate =  new Date(moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY'))
    let starttime = moment(this.dtoScheduleModality.startTime).format("HH:mm");
    this.dtoScheduleModality.therapyId=17;
    let startTime = moment(this.dtoScheduleModality.startTime); 
    let newTime = startTime; 
    let formattedTime = newTime.add(1, 'minutes').format("HH:mm");
    this.dtoScheduleModality.duration=item.duration;
   
    //Retriving Hours and Minutes
    const totalMinutes = Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes);
    var duration = Math.floor(totalMinutes / 60) * 60;
    this.dtoScheduleModality.duration = totalMinutes;
    let newToTime = moment(starttime,'HH:mm').add((totalMinutes - 1), 'minute').format('HH:mm A')
this.newToTimeBlock=newToTime
console.log(this.newToTimeBlock)

    let _obj = {
      StartDate: moment.utc(this.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
      EndDate: moment.utc(this.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
      FromTime: formattedTime,
      ToTime : newToTime,
      // ToTime: moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes))), 'minute').format('HH:mm A'),
      Provider: this.dtoScheduleModality.provider
    }
    this.spinnerservice.show();
    if(moment(this.ObjTherapyPopUp.fromtime).format("HH:mm") == moment(this.dtoScheduleModality.startTime).format("HH:mm") && this.dtoScheduleModality.startDate == this.ObjTherapyPopUp.scheduleDate && this.dtoScheduleModality.duration == this.ObjTherapyPopUp.Duration){
      this.DirectToScheduleModalityBlock(starttime);
    }
    else{


 


    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.treatmentservice.getConfilctsForModality(dataToEncrypt).subscribe(
      (res: any) => {
        this.dtoScheduleConflictModalitylist = JSON.parse(res);
        this.spinnerservice.hide();

        if(this.dtoScheduleConflictModalitylist.length==0){
         this.DirectToScheduleModalityBlock(starttime);
        }
        else{
          this.dateList = [];
        //  this.dateList = this.getDaysBetweenDates(moment(this.dtoScheduleModality.startDate).utcOffset(-offset), moment(this.dtoScheduleModality.endDate).utcOffset(-offset));
          this.onBetweenappointmentBlock(this.dtoScheduleConflictModalitylist);
           // Modified on Jan 31 2024
       //   this.skipAllConflicts();
    
       this.skipAllConflictsNew(this.dtoScheduleModality.provider[0]);
        }
      },
      err => {
        this.spinnerservice.hide();
  
      },
    );
    }
  }

  DirectToScheduleModalityBlock(starttime){
    this.scheduleModalityList =[]
    console.log(this.newToTimeBlock)
    
    this.scheduleModalityList.push({
      // day: data[i].day,
      scheduleDate: moment(this.startDate).format('MM/DD/YYYY'),
      isChecked: false,
      fromTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),    
      toTime:this.newToTimeBlock,
      
      //toTime:moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration))), 'minute').format('HH:mm A'),
      isConflict: false})
    this.postSchduleModalityBlockTime();
  }
  postSchduleModalityBlockTime() {
    let checkconfilcts = this.scheduleModalityList.filter((item) => item.isConflict == true).length;
     if (checkconfilcts > 0) {
      this.errConflicts = true;
      return;
    }
    else{

       this.dtoScheduleModality.provider = this.normalizeProvider(this.dtoScheduleModality.provider);


      let obj;
        let MeetingTimesArray = [];
        let startTime = moment(this.dtoScheduleModality.startTime); 
        let toTime=this.newToTimeBlock;
        console.log(this.newToTimeBlock)
        MeetingTimesArray.push(this.scheduleModalityList[0])
        obj = {
          MeetingCategoryId: 1,
          MeetingScheduleId:this.ObjTherapyPopUp.id,
          MeetingLocationId: this.dtoScheduleModality.location,
          PatientAccountId: this.ObjTherapyPopUp.patientAccountId,
        //  PatientInfoId: this._dtoPatientInfo.patientInfoId,
          ScheduleName: this.ObjTherapyPopUp.ScheduleName + " - "+this.ObjTherapyPopUp.patientName,
          Provider: this.dtoScheduleModality.provider,
          MeetingTimes: MeetingTimesArray,
          ModalityDetails: this.dtoScheduleModality,
          PreviousMeetingScheduleTimeId : this.ObjTherapyPopUp.ScheduleTimeId,
          Commands:this.dtoScheduleModality.rescheduleComments,
          MeetingTypeId: this.dtoScheduleModality.meetingType,
          //EndTime:moment(startTime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes))), 'minute').format('HH:mm A'),
          EndTime:this.newToTimeBlock,
      
        }
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.treatmentservice.postReSchduleMeeting(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
       //// this.snackbar.success(this.therapyName + " " + JSON.parse(res).message);
        this.snackbar.success(res);
      //  this.router.navigate(['/scheduler']);
      window.location.reload();
  
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  
  }


  onBetweenappointmentBlock(data) {
    this.scheduleModalityList = [];
    let starttime = moment(this.dtoScheduleModality.startTime).format("HH:mm");
    for (let i = 0; i < data.length; i++) {
      this.scheduleModalityList.push({
        // day: data[i].day,
        scheduleDate: moment(data[i].scheduleDate).format('MM/DD/YYYY'),
        isChecked: false,
        fromTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),
        toTime:this.newToTimeBlock,
        //toTime: moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes))), 'minute').format('HH:mm A'),
        isConflict: this.getIsConflict(data[i].scheduleDate),
        // location: data[i].location,
        // locationName: this.getLocationName(data[i].location),
      });
    }
}
 normalizeProvider(provider: number | number[]): number[] {
    return Array.isArray(provider) ? provider : [provider];
  }



}
  

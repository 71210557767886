<div class="billing-page">
    <div class="col-md-12">
      <div  class="d-flex">
        <div  class="head-section mt-2 mb-2">
          Payment Details
        </div>
        <div  class="ml-auto">
          <button  tabindex="0" class="btn back-btn-new mr-2" (click)="onBackClick()">
            <i  class="fas fa-chevron-left mr-2"></i>Back</button>
          </div>
        </div>
        </div>
        <div class="col-md-12 bg-white p-2">
          <div class="row">
            <div class="col-md-8">
              <div class="custom-head mt-3 mb-2 pl-2">
                <h2>Account Information</h2>
              </div>
              <div class="col-md-12 info-bill">
                <div class="row">
                  <div class="col-md-6">
                    <div class="patient-detail pl-2 pt-2">
                      <h6 class="fw-bold">{{dtoPatientOutofPacketInfo?.PatientName}}</h6>
                      <ul  class="list-unstyled text-sm text-muted">
                        <li><div>{{dtoPatientOutofPacketInfo?.PtAddress}}</div>
                           {{dtoPatientOutofPacketInfo?.CityName}}, {{dtoPatientOutofPacketInfo?.StateName}} {{dtoPatientOutofPacketInfo?.Zip}}</li>
                        <li>
                          <i  class="fas fa-mobile"></i> {{dtoPatientOutofPacketInfo?.Phone | mask: '(000) 000-0000'}}</li>
                          <li ><i  class="fas fa-envelope"></i> {{dtoPatientOutofPacketInfo?.Email}}</li>
                        </ul>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-6"><label class="text-muted font-weight-500 font-15">Your Balance Due</label></div>
                      <div class="col-md-6"><div class="fw-bold">: <i class="fas fa-dollar-sign mr-1"></i>{{totalpending}}</div></div>
                    </div>
                    <div class="row">
                      <div class="col-md-6"><label class="text-muted font-weight-500 font-15">Total Number of Payments</label></div>
                      <div class="col-md-6"><div class="fw-bold">: {{dtoPatientOutofPacketInfo?.TotalNumberofPayments}}</div></div>
                    </div>
                    <div class="row">
                      <div class="col-md-6"><label class="text-muted font-weight-500 font-15">Guardian Name</label></div>
                      <div class="col-md-6"><div class="fw-bold">: {{dtoPatientOutofPacketInfo?.ParentName}}</div></div>
                    </div>
                    <div class="row">
                      <div class="col-md-6"><label class="text-muted font-weight-500 font-15">Treatment Start Date</label></div>
                      <div class="col-md-6"><div class="fw-bold">: {{dtoPatientOutofPacketInfo?.TreatmentStartDate| date: 'MM/dd/yyyy'}}</div></div>
                    </div>
                </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="col-12 mb-4"> 
                <div class="row box-right"> 
                  <div class="col-md-6 pr-0 border-right "> 
                    <p class="pl-3 p-blue  h6 mb-0">TOTAL RECIEVED</p>
                     <p class="h3 fw-bold-700 d-flex"> 
                      <!-- <i class="fas fa-dollar-sign mr-1 align-text-top text-muted"></i>84,254 <span class="text-muted">.58</span> -->
                      <i class="fas fa-dollar-sign mr-1 align-text-top text-muted"></i><span class="text-muted">{{dtoPatientOutofPacketInfo?.TotalAmountReceived}}</span>
                     </p> 
                     </div>
                     <div class="col-md-6">
                       <div class="">
                        <p class="pl-3   h6 mb-0 p-blue">PENDING</p>
                           <p class="h3 fw-bold-700 d-flex"> 
                          <!-- <i class="fas fa-dollar-sign mr-1 align-text-top text-muted"></i>1254 <span class="text-muted">.50</span> -->
                          <i class="fas fa-dollar-sign mr-1 align-text-top text-muted"></i>{{totalpending}}
                         </p> 
                       </div>
                      </div> 
                    </div> 
                  </div>
                  <!-- Notes-->
                  <div class="col-md-12">
                    <button class="btn btn-success btn-sm float-right mb-2" *ngIf="isShowNotesAttachment && roleAccess.isCreate" (click)="onaddNotes()" ><i class="fas fa-paperclip mr-2"></i>Notes attached</button>
                   
                    <button class="btn primary-btn-outlined-small float-right mb-2" (click)="onaddNotes()" *ngIf="!isShowNotesAttachment"> <i class="fas fa-plus mr-2"></i>Add Notes</button>
                    <div class="notes-container" *ngIf="isAddNotes">
                      <textarea cols="3" class="form-control" maxlength="300" [(ngModel)]="dtoPatientOutofPacketNotes.NotesContent"  placeholder="Enter notes content"></textarea>
                      <span class="text-xs sub-descib">(maximum allowed character is 300 only)</span>
                      <br>
                      <span class="text-xs sub-descib" *ngIf="isNotesErr">* Notes content is required</span>                      
                      <button class="btn btn-cancel float-right mb-1 mt-2" *ngIf="roleAccess.isCreate"   (click)="onCanceladdNotes()">cancel</button>
                      <button class="btn primary-btn-filled float-right mb-1 mt-2 mr-1" *ngIf="roleAccess.isCreate"   (click)="onSaveaddNotes()">Save</button>
                    </div>
                </div>
                  <!-- Notes -->
            </div>
          </div>
         
          <div class="row">
            <div class="col-md-12">
              <div class="custom-head mt-3 mb-1 pl-2 d-flex">
                <h2>Account Summary</h2>
                <div class="ml-auto d-none">
                  <button mat-stroked-btn class="btn primary-btn-outlined-small"><i class="fas fa-file-invoice-dollar mr-2"></i>Payment Logs</button>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="risks-tabs">
                <mat-tab-group>
                  <mat-tab label="Payments Active">
                    <div class="summary-list col-md-12">
                      <div class="p-2 ">
                         <div class="card-body1">
                          <div class="col-md-12 per-section-loop">
                            <div class="row pt-2 pb-2">
                              <div class="col-md-2 pl-0" *ngIf="isPerWeek">
                                <div class="bill-labeld">
                                  week Date
                                </div>
                              </div>

                              <div class="col-md-1 pl-0">
                                <div class="bill-labeld">
                                  Scheduled Date
                                </div>
                                           
                              </div>
                              <div class="col-md-2">
                                <div class="bill-labeld">
                                  Therapy
                                </div>
                               
                                
                              </div>
                              <div class="col-md-2">
                                <div class="bill-labeld">
                                 Frequency
                                </div>
                                
                              </div>
                              <div class="col-md-1">
                                <div class="bill-labeld">
                                  Duration
                                </div>
                               </div>
                              
                              <div class="col-md-2">
                                <div class="bill-labeld">
                                 Payment Due
                                </div>
                               
                              </div>
                             
                              <div class="col-md-1">
                                <div class="bill-labeld">
                                  Action
                                </div>
                                
                              </div>
                            </div>

                            <!-- Active-->
                                <!-- <div class="row pt-2 pb-2" *ngFor="let itm of OutofPktPaymentActiveLists;let i=index"  style="background-color:{{((i > 0) && itm.IsGrouped==1 &&(itm.PayFrequency==3 && (itm.WeekStartDate==OutofPktPaymentActiveLists[i-1].WeekStartDate)) )?'#e7effd':'#fff'}}">   -->
                                  <div class="row pt-2 pb-2" *ngFor="let itm of OutofPktPaymentActiveLists;let i=index"  style="background-color:{{((i >= 0) && ((itm.ColorVal==1 ))) ?'#e7effd':  '#fff '}}">  
                                                             
                                    <div class="col-md-2" *ngIf="isPerWeek">
                                  <div class="bill-valued-sub"  *ngIf="(itm.IsGrouped==1 && itm.DueAmount!=0.00)" >
                                    {{itm?.WeekStartDate |date:'MM/dd/yyyy'}}  - 
                                    {{itm?.WeekEndDate |date:'MM/dd/yyyy'}}
                                   </div>
                                   <div class="bill-valued-sub"  *ngIf="itm.IsGrouped==0" >
                                    {{itm?.WeekStartDate |date:'MM/dd/yyyy'}}  - 
                                    {{itm?.WeekEndDate |date:'MM/dd/yyyy'}}
                                   </div>
                                </div>
                               
                                <div class="col-md-1 pl-0">
                                <div class="bill-valued-sub">
                                  {{itm?.TherapyDate |date:'MM/dd/yyyy'}}
                                </div>
                               </div>
                              <div class="col-md-2">
                               <div class="bill-valued-sub">
                                {{itm?.TherapyId==3?itm.ApointmenteName: itm.TherapyName }}
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="bill-valued-sub"  *ngIf="(itm.IsGrouped==1 && itm.DueAmount!=0.00)" >
                                  {{itm?.PayFrequencyName}}
                                 </div>
                                 <div class="bill-valued-sub"  *ngIf="itm.IsGrouped==0" >
                                  {{itm?.PayFrequencyName}}
                                 </div>
                              </div>
                              <div class="col-md-1">
                               <div class="bill-valued-sub">
                                  {{global.getMinutesSlot(itm?.Duration)}}
                                </div>
                              </div>
                             
                              <div class="col-md-2">
                                  <div class="bill-valued" *ngIf="(itm.IsGrouped==1 && itm.DueAmount!=0.00)"> 
                                    $ {{itm?.DueAmount }}
                                </div>
                                <div class="bill-valued" *ngIf="(itm.IsGrouped==0)"> 
                                  $ {{itm?.DueAmount }}
                              </div>
                              </div>
                            
                              <div class="col-md-1">
                                <div class="bill-valued-sub" *ngIf="roleAccess.isCreate">
                                  <button   *ngIf="(itm.IsGrouped==1 && itm.DueAmount!=0.00)"  mat-stroked-btn class="btn primary-btn-filled-small" (click)="onShowPaymentPopup(itm)"   >Pay now</button>
                                  <button   *ngIf="(itm.IsGrouped==0)"  mat-stroked-btn class="btn primary-btn-filled-small" (click)="onShowPaymentPopup(itm)"  >Pay now</button>
                                </div>
                              </div>
                            </div>
                          <!-- </div> -->
                     <!-- Active end-->
                         </div>
                          
                        </div>
                      </div>
                    </div>
  
                  </mat-tab>
                  <mat-tab label="Payments Completed">
                    <div class="d-flex w-100 pr-3">
                      <div class="ml-auto">
                        <!--  -->
                      <button class="btn primary-btn-filled-small float-right mr-2" (click)="OnPrintClick()"><i class="fas fa-print mr-2"></i>Print</button>
                      </div> 
                    </div>
                    <div class="summary-list col-md-12">
                      <div class="p-2">
                       <div class="card-body1">
                          <div class="col-md-12 per-section-loop">
                            <div class="row pt-2 pb-2">
                              <!-- radio-->
                                <div class="col-md-1 pl-0">
                                    <div class="bill-labeld">
                                    </div>
                                 </div>
                              <!--radio end-->
                              <div class="col-md-2 pl-0" *ngIf="isPerWeekC">
                                <div class="bill-labeld">
                                  week Date
                                </div>
                              </div>

                              <div class="col-md-1 pl-0">
                                <div class="bill-labeld">
                                  Scheduled Date
                                </div>
                               </div>
                              <div class="col-md-2">
                                <div class="bill-labeld">
                                  Therapy
                               </div>
                              </div>
                              <div class="col-md-1">
                                <div class="bill-labeld">
                                 Frequency
                                </div>
                              </div>
                              <div class="col-md-1">
                                <div class="bill-labeld">
                                  Duration
                                </div>
                             </div>
                              
                              <div class="col-md-1">
                                <div class="bill-labeld">
                                 Payment Done
                                </div>
                              </div>
                              <div class="col-md-1">
                                <div class="bill-labeld">
                                  Payment Type
                                </div>
                                
                              </div>
                              <div class="col-md-2">
                                <div class="bill-labeld">
                                  Paid On
                                </div>
                               </div>
                              
                            </div>
                            
                            <!-- Completed List-->
                            <!-- <div class="row pt-2 pb-2" *ngFor="let item of OutofPktPaymentCompletedLists;let i=index"  style="background-color:{{((i > 0) && item.IsGrouped==1 &&(item.PayFrequency==3 && (item.WeekStartDate==OutofPktPaymentCompletedLists[i-1].WeekStartDate)) )?'#e7effd':'#fff'}}">   -->
                              <div class="row pt-2 pb-2" *ngFor="let item of OutofPktPaymentCompletedLists;let i=index"  style="background-color:{{((i >= 0) && ((item.ColorVal==1 ))) ?'#e7effd':  '#fff '}}">  
                              <!-- radio-->
                              <div class="col-md-1 pl-0">
                               <div class="bill-labeld">
                                 <mat-radio-button  (click)="radiobuttonClick(item,$event)" *ngIf="item.DueAmount!=0.00"></mat-radio-button>
                               </div>
                             </div>
                              <!--radio end-->
                              <div class="col-md-2" *ngIf="isPerWeekC">
                                <div class="bill-valued-sub"  *ngIf="(item.IsGrouped==1 && item.DueAmount!=0.00)" >
                                  {{item?.WeekStartDate |date:'MM/dd/yyyy'}}  - 
                                  {{item?.WeekEndDate |date:'MM/dd/yyyy'}}
                                 </div>
                                 <div class="bill-valued-sub"  *ngIf="item.IsGrouped==0" >
                                  {{item?.WeekStartDate |date:'MM/dd/yyyy'}}  - 
                                  {{item?.WeekEndDate |date:'MM/dd/yyyy'}}
                                 </div>
                              </div>

                              <div class="col-md-1 pl-0">
                              <div class="bill-valued-sub" >
                                 {{item?.TherapyDate |date:'MM/dd/yyyy'}}
                              </div>
                              </div>
                             <div class="col-md-2">
                              <div class="bill-valued-sub"> 
                                 {{item?.TherapyId==3?item.ApointmenteName: item.TherapyName }}
                              
                               </div>
                             </div>
                            <div class="col-md-1">
                              <div class="bill-valued-sub"  *ngIf="(item.IsGrouped==1 && item.DueAmount!=0.00)?true:item.IsGrouped==0?true:false" >
                                {{item?.PayFrequencyName}}
                               </div>
                            </div>
                           
                            <div class="col-md-1">
                                <div class="bill-valued-sub">
                                 {{global.getMinutesSlot(item?.Duration)}}
                               </div>
                             </div>
                             
                             <div class="col-md-1">
                                <div class="bill-valued-sub"  *ngIf="(item.IsGrouped==1 && item.DueAmount!=0.00)?true:item.IsGrouped==0?true:false" >
                                  {{item?.PaidAmount}}
                                 </div>
                             </div>

                             <div class="col-md-1">
                               <div class="bill-valued-sub"   *ngIf="(item.IsGrouped==1 && item.DueAmount!=0.00)?true:item.IsGrouped==0?true:false">
                                {{item?.PaymentType}}
                               </div>
                               </div>

                             <div class="col-md-2">
                              <div class="bill-valued-sub"  *ngIf="(item.IsGrouped==1 && item.DueAmount!=0.00)?true:item.IsGrouped==0?true:false" >
                                {{item?.PaidOn | date: 'MM/dd/yyyy hh:mm a'}}
                               </div>
                             
                              </div> 
                                                            
                           </div>
                           
                            
                          </div>
                          
                        </div>
                      </div>


                    </div>
                  </mat-tab>
                  </mat-tab-group>
            </div>
            
            
          </div>
        </div>
        </div>
  
 <!--Payment Test credit card pop up -->
 <div class="comment-popup  re-schedule-popup">
  <div class="modal" tabindex="-1" role="dialog" [style.display]="isTranssale? 'block':'none'">
    <div class="modal-dialog mat-dialog-container " role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h6 class="modal-title"> Payment Transaction Sale</h6>
          <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OnCancelsale()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
<div >
  <form (ngSubmit)="paymentTransSale.form.valid && PostTransactionSale(paymentTransSale)" id="paymentTransSale"
  autocomplete="off" #paymentTransSale="ngForm" novalidate>
  <div class="col-md-12 position-relative">
    <div class="row">
      <div class="payment-icon">
        <div class="icons">
          <img src="../../../assets/images/icon1.png" width="30">
          <img src="../../../assets/images/icon2.png" width="30">
          <img src="../../../assets/images/icon3.png" width="30">
          <img src="../../../assets/images/icon1.png" width="30">
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-5">
       <label class="align-middle fw-bold align-self-center">Payment Method</label>
      </div>
      <div class="col-md-7">
        <select class="form-control d-inline-block status-filter mr-0" [(ngModel)]="selectedPaymentMethod"  name="paymentmethod" (change)="OnchangePaymentMethod($event.target.value)">
          <option value="0">--Select--</option>
          <option *ngFor="let a of drpPaymentMethod" value="{{a.PaymentTypeId}}">
              {{a.PaymentTypeName}}
           </option>
        </select>
      </div>
    </div>
  </div>
  <div class="col-md-12 border mt-3 pt-2 odd-bg" *ngIf="isPaymentSelected">
    <div class="row mb-2">
     <div class="col-md-12" *ngIf="!isCash">
        <mat-form-field class="w-100">
          <mat-label>Card Number</mat-label>
           <input matInput  maxlength="19" numeric autocomplete="off"   (focusout)="creditnumfocusout($event.target.value)"
           [(ngModel)]="dtoBridgePayTransactionSaleRequest.PaymentAccountNumberMask"
           #PaymentAccountNumber="ngModel" name="PaymentAccountNumber"
            [ngClass]="{ 'is-invalid': paymentTransSale.submitted && PaymentAccountNumber.invalid }"
            required>
            <mat-error *ngIf="paymentTransSale.submitted && PaymentAccountNumber.invalid">
            <mat-error *ngIf="PaymentAccountNumber.errors.required">Card Number is required</mat-error>
            </mat-error>
        </mat-form-field>
     </div>
    <div class="col-md-6 mb-2" *ngIf="!isCash">
      <mat-form-field class="w-100">
        <mat-label>Expiration Date</mat-label>
           <input matInput [matDatepicker]="exppicker" [(ngModel)]="expirationDate" #ExpirationDate="ngModel" name="ExpirationDate" required
          [min]="currentdate">
          <mat-datepicker-toggle matSuffix [for]="exppicker"></mat-datepicker-toggle>
          <mat-datepicker #exppicker></mat-datepicker>
          <mat-error *ngIf="paymentTransSale.submitted && ExpirationDate.invalid">
            <mat-error  *ngIf="ExpirationDate.errors.required">
              ExpirationDate is required</mat-error>
        </mat-error>
        </mat-form-field>

       </div>
    <div class="col-md-6 mb-2" *ngIf="!isCash">
      <mat-form-field class="w-100">
        <mat-label>CVV</mat-label>
         <input matInput  maxlength="4" numeric  autocomplete="off"   (focusout)="securitycodefocusout($event.target.value)"  
          [(ngModel)]="dtoBridgePayTransactionSaleRequest.SecurityCodeMask" #SecurityCode="ngModel" name="SecurityCode"
               [ngClass]="{ 'is-invalid': paymentTransSale.submitted && SecurityCode.invalid }"
          required>
          <mat-error *ngIf="paymentTransSale.submitted && SecurityCode.invalid">
            <mat-error *ngIf="SecurityCode.errors.required">CVV is required</mat-error>
          </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6 mb-2" *ngIf="isCash">
      <mat-form-field class="w-100">
        <mat-label>Payment Date</mat-label>
         <input matInput [matDatepicker]="paypicker" [(ngModel)]="dtoBridgePayTransactionSaleRequest.PaymentDate" #PaymentDate="ngModel" name="PaymentDate" required
          [min]="currentdate">
          <mat-datepicker-toggle matSuffix [for]="paypicker"></mat-datepicker-toggle>
          <mat-datepicker #paypicker></mat-datepicker>
          <mat-error *ngIf="paymentTransSale.submitted && PaymentDate.invalid">
            <mat-error  *ngIf="PaymentDate.errors.required">
              PaymentDate Date is required</mat-error>
        </mat-error>
        </mat-form-field>
       </div>
    <div class="col-md-6 mb-2">
      <mat-form-field class="w-100">
        <mat-label>Amount</mat-label>
         <input matInput  maxlength="5" numeric
          [(ngModel)]="dtoBridgePayTransactionSaleRequest.Amount" #Amount="ngModel" name="Amount"
          [ngClass]="{ 'is-invalid': paymentTransSale.submitted && Amount.invalid }"
          required>
          <mat-error *ngIf="paymentTransSale.submitted && Amount.invalid">
          <mat-error *ngIf="Amount.errors.required">Amount  is required</mat-error>
          </mat-error>
      </mat-form-field>
    </div>
   <div class="text-right col-md-12 mt-2">
    <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2">Submit</button>
    <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="OnCancelsale()" >Cancel</button>
  </div>

  <div>
    

  </div>

</div>
</div>
</form>
</div>


</div>
</div>
</div>
</div>
</div>
<!--Payment Test credit card pop up end -->
<!--Print Popup  <div class="Activity-popup11 print-popup"  >-->
<div class="comment-popup  re-schedule-popup">
  <div class="modal"  tabindex="-1" role="dialog"  [style.display]="isPrintDatePopup? 'block':'none'">
     <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h6 class="modal-title"><i class="fas fa-print mr-2"></i> Print</h6>
              <button type="button" class="close"  data-dismiss="modal" (click)="OnPrintPopupcancel()"
              aria-label="Close" >
              <span aria-hidden="true">&times;</span>
           </button>
           </div>
           <div class="modal-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field class="w-100 mt--14">
                    <mat-label>From Date</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" [max]="today"
                   name="PrStartDate" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                    <mat-error *ngIf="isStartDateErr" class="text-xs">
                      <mat-error *ngIf="startDate==null || startDate=='' || startDate==undefined">
                        From Date is required
                      </mat-error>
                   </mat-error>
                  
                </div>
                <div class="col-md-4">
                 <mat-form-field class="w-100  mt--14">
                    <mat-label>To Date</mat-label>
                    <input matInput [matDatepicker]="edpicker"   [(ngModel)]="endDate" (dateChange)="OnPrintdatechange()" [max]="today"
                    name="PrEndDate" required >
                    <mat-datepicker-toggle matSuffix [for]="edpicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #edpicker></mat-datepicker>
                  </mat-form-field> 
                    <mat-error *ngIf="isEndDateErr" class="text-xs">
                      <mat-error *ngIf="endDate==null || endDate==''  || endDate==undefined ">
                        To Date is required
                      </mat-error>
                   </mat-error>
                   
                </div>
              </div>

            </div>
             </div>
             <div class="modal-footer">
              <button class="btn primary-btn-filled primary-btn-m" (click)="OnPrintPopupSubmit()">Submit</button>
              <button class="btn btn-cancel" (click)="OnPrintPopupcancel()">cancel</button>
             </div>
        </div>
      </div>
    </div>
  </div> 
</div>

<a mimetype="application/octet-stream" #downloadLink></a>
import { Component, OnInit, ViewChild } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { PatientService } from 'src/app/patient/patient.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import * as moment from 'moment';
import { tr } from 'date-fns/locale';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-discharged-patient-list-view',
  templateUrl: './discharged-patient-list-view.component.html',
  styleUrls: ['./discharged-patient-list-view.component.scss']
})
export class DischargedPatientListViewComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  DecrptedlstPatient: any;
  lstPatient: any;
  dataSource: MatTableDataSource<any>;
  //paginator: MatPaginator;
  leadhistory: any[];
  ShowActivity: boolean;
  ddlPsMaster: any;
  _dtoupdatePatientStatusDtls: any;
  patientStatusId: any;
  ShowPatientStatus: boolean;
  ShowResumepopup : boolean = false;
  IsFinacial:boolean;
  resumeclients : any;
  ChmaTreatmentSummaryId:number;
  SelectedTreatment : string;
  fiveMTP: string[] = ['Financial Proposal', 'Intake Packet', 'Comprehensive MHA', 'Nutritional Questionnaire', 'Pain Assessment', 'Suicidal Risk Assessment','Intake Update'];
  selectedValues: string[] = [];
  public searchpatientListtxt : string;
  constructor(public patientservice: PatientService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, private route: ActivatedRoute,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService) { }


  ngOnInit(): void {
    this.getpatientlistView();
  }
  getpatientlistView() {
    this.spinnerservice.show();
    this.patientservice.getDischargedPatientList().subscribe(
      (res: any) => {
        this.spinnerservice.hide();
          this.DecrptedlstPatient = this.commonService.decrypdata(res.objInputString);
       //console.log(this.DecrptedlstPatient);
        this.lstPatient = this.DecrptedlstPatient.slice(0, 20);
        this.dataSource = new MatTableDataSource(this.DecrptedlstPatient);
        this.dataSource.paginator = this.paginator;
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  OnSearchPatient(){
    let tempPatientlist = this.DecrptedlstPatient
    if(this.searchpatientListtxt !="" || this.searchpatientListtxt === undefined || this.searchpatientListtxt === null){
      this.lstPatient = tempPatientlist.filter(
        (x: any) =>
      x.FirstName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
      x.LastName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
    }
    else{
     this.lstPatient = tempPatientlist.slice(0, 5);
    }
  }
  onPrepareIntake(type, data) {
    if (type == 1) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1 } }));
      window.open(url, '_blank');
    } else if (type == 2) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1 } }));
      window.open(url, '_blank');
    } else if (type == 3) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-survey'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1 } }));
      window.open(url, '_blank');
    } else if (type == 4) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-consent'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(data.PatientAccountId), pageid: 1 } }));
      window.open(url, '_blank');
    }
    
  }

  onRedirectTo(item,selectedType)
  {
    if(selectedType=="1")
    {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/patient-breifcase'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientInfoId)}}));
    window.open(url, '_blank');
    }
    else if(selectedType=="2")
    {
      this.router.navigate(['/schedule-modality'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId),treatmentPlanDate:item.TreatmentPlanDate } });
    }
    if(selectedType=="3")
    {
     
    const url = this.router.serializeUrl(this.router.createUrlTree(['/treatmentplan'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId)   }}));
     window.open(url, '_blank');
    }
  }
  OnActivityClick(Patient){
    //console.log(Patient)
    this.ShowActivity = true;
   // this.leadhistory=[];
    this.getLeadActivity(Patient.PatientInfoId)
  }
  onCloseActivitylog(){
    this.ShowActivity = false;
  }
  getLeadActivity(Id) {
    this.leadhistory=[]
    this.spinnerservice.show()
    this.patientservice.getLeadHistory(Id).subscribe(
      (res: any) => {
        if (res) {
          this.leadhistory = res;
          this.spinnerservice.hide()
        //  console.log(this.leadhistory)
        }
        this.spinnerservice.hide()
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  onPageChange($event) {
    this.lstPatient = this.DecrptedlstPatient.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }

  getPatientStatusMaster()
  {
    this.spinnerservice.show();
    this.patientservice.getPatientStatusMaster().subscribe(
      res => {
        if (res != null) {
          this.ddlPsMaster = this.commonService.decrypdata(res.objInputString);
          this.ddlPsMaster =this.ddlPsMaster.filter(x=>x.PatientStatusId!=1);
         this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }
 
  onShowStatusPopup(patient)
  {
 this.getPatientStatusMaster();
this._dtoupdatePatientStatusDtls=patient;
this.patientStatusId=patient.PatientStatusId;
this.ShowPatientStatus=true;
  }
  onCloseStatusPopup(){
    this.patientStatusId=0;
    this._dtoupdatePatientStatusDtls.PatientStatusId=0;
    this.ShowPatientStatus=false; 
  }
  patientStatusradioChange(event){
    this._dtoupdatePatientStatusDtls.PatientStatusId=event.value;
  }
  updatePatientStatus(){
    this.spinnerservice.show();
    if( this._dtoupdatePatientStatusDtls.PatientStatusId==1 || this._dtoupdatePatientStatusDtls.PatientStatusId==0)
    {
    this.spinnerservice.hide();
    this.snackbar.error('Select any one  patient status');
    return;
  }
  this.ShowPatientStatus=false;
  const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoupdatePatientStatusDtls, false);
  this.patientservice.postDischargedPatientAccountStatus(dataToEncrypt).subscribe(res => {
      if (res) {
      this.patientStatusId=1;
      this.spinnerservice.hide();
       this.getpatientlistView();
      this.snackbar.success(res);
        }
    },
    err => {
     this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}
OnBackClick(){
  this.router.navigate(['/patientslists'])
}
OnResume(item){
  console.log(item);  
  this.ShowResumepopup = true;
  this.resumeclients = item;
  this.ChmaTreatmentSummaryId=item.ChmaTreatmentSummaryId;
}
onCloseResume(){
  this.ShowResumepopup = false;
  this.selectedValues = [];
}
postResumeClients(){
  if(this.selectedValues.length == 0){
    this.snackbar.error("Please select the option");
    return
  }
  if(this.selectedValues.includes("Financial Proposal"))
  {
    this.IsFinacial=true;
  }

  if(!this.selectedValues.includes("Financial Proposal"))
    {
      this.IsFinacial=false;
    }

  if(!this.selectedValues.includes("Comprehensive MHA") && !this.selectedValues.includes("Intake Update"))
    {
      this.snackbar.error("Please select either 'Comprehensive MHA' or 'Intake Update' to proceed");
      return;
    }

  // else
  // {

  //   this.IsFinacial=false;
  // }
  var data = this.resumeclients;
  let _obj = {
    PatientAccountId : data.PatientAccountId,
    IsDischarge : true, 
    IsFinacial : this.IsFinacial,
    CHMATreatmentSummaryId:this.ChmaTreatmentSummaryId,
    SelectedTreatment:this.selectedValues.join(', ')
  }
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.RejoinMessage
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // If the user confirms, switch to the next tab
      if (result) {
     this.patientservice.postResumeClients(_obj).subscribe(res => {
    if (res) {
      this.snackbar.success(res);
      this.ShowResumepopup = false;
      this.getpatientlistView();
      if(this.IsFinacial==true)
      this.router.navigate(['/leadlist'])
      else
      this.router.navigate(['/patientslists'])

      }
  },
  err => {
   this.spinnerservice.hide();
    this.snackbar.error(err.error);
  },
);
    
}
    })
  }
}

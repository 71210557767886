import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ParentInfo } from 'src/app/_models/_Lead/parentInfo';
import { PatientInfo } from 'src/app/_models/_Lead/patientInfo';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { LeadService } from 'src/app/patient-lead/lead.service'
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SendEmail } from 'src/app/_models/_Common/sendEmail';
import {RoelAccess} from 'src/app/_models/_Common/roleAccessMaster'
import * as moment from 'moment';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { UserserviceService } from 'src/app/usermanagement/userservice.service';
import { AuthenticationService } from 'src/app/admin/authentication.service';
import { Subject } from 'rxjs';
declare function decrypt(data): any;
declare function EncryptFieldData(data): any;
@Component({
  selector: 'app-lead-listview',
  templateUrl: './lead-listview.component.html',
  styleUrls: ['./lead-listview.component.scss']
})
export class LeadListviewComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  public searchpatientListtxt : string;
  lstLead: any = [];
  parentFirstName: any;
  DecrptedlstLead: any = [];
  defaultRecords: any = 20;
  public pageSize = 10;
  public currentPage = 0;
  public totalSize = 0;
  statusResendActivationMail = constantVariables.status.ResendActivationMail;
  statusUploadInsurance = constantVariables.status.UploadInsurance;
  statusReviewPacket = constantVariables.status.ReviewPacket;
  statusReturn = constantVariables.status.Return;
  statusVerified = constantVariables.status.Verified;
  statusCrbaUpdate = constantVariables.status.CrbaUpdate;
  statusFinancial = constantVariables.status.financial;
  statusintake = constantVariables.status.forIntake;
  statusreviewTour = constantVariables.status.reviewTour;
  statusdecline = constantVariables.status.Decline;
  statusstaffAssigned = constantVariables.status.staffAssigned;
  statusRequestTour=constantVariables.status.requestTour;
  statusTourCompleted=constantVariables.status.tourCompleted;
  subjectTag = "";
  financialDenyReason: any;
  isFinancialDecline: boolean = false;
  isView = false;
  isEdit = false;
  isDelete = false;
  isCreate = false;
  roleaccess: any;
  ShowActivity: boolean = false;
  GetActivityId: any;
  Decrptedlst: any;
  leadhistory: any;
  resendactivationemail:any;
  isEditUsers : boolean =true;
  isActiveEmail : boolean = false;
  isEditEmailId : any;
  isPrimaryEmailChanged:boolean=false;
  oldEmail : any;
  email : any;
  refresh: Subject<any> = new Subject();
  isLeadInformation : boolean;
  PatientInfoId : any;
  IsActive : boolean;
  declineReason : boolean = true;
  commentReason : boolean = true;
  reason : any;
  // closeEmailPopup : boolean = false;
  // ShowContactSavePopup: boolean = false;
  isPopup: boolean = false;
  roleAccess:RoelAccess=new RoelAccess();
  selectedreason : any;
  selectedcomments : any;
  comments : any; 
  constructor(public leadservice: LeadService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public roleaccessservice:PageAccessService,private userService:UserserviceService,private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.getLeadlistView();
    localStorage.setItem("profiletab", "1");
   // var access = JSON.parse(localStorage.getItem('roleaccess'));
    // const pageid = constantVariables.pagelists.Lead;
    // this.roleaccess = access.details.find(x => x.pageId == pageid);
    // if (this.roleaccess != null) {
    //   this.isCreate = this.roleaccess.isCreate;
    //   this.isView = this.roleaccess.isView;
    //   this.isEdit = this.roleaccess.isEdit;
    //   this.isDelete = this.roleaccess.isDelete;
    // }
    // else {
    //   this.isCreate = true;
    //   this.isView = true;
    //   this.isEdit = true;
    //   this.isDelete = true;
    // }
    const pageid = constantVariables.pagelists.Lead;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);

  }
  onPageChange($event) {
    //ngfor array                 //array
    this.lstLead = this.DecrptedlstLead.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  getLeadlistView() {
    this.spinnerservice.show();
    let locationid= Number(localStorage.getItem('sfcclocation'))==0?-1:Number(localStorage.getItem('sfcclocation'));
    //this.leadservice.getPatientList().subscribe(
      this.leadservice.getAdminLoginLeadLists(locationid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.DecrptedlstLead = decrypt(res.objInputString);
        this.lstLead = this.DecrptedlstLead.slice(0, this.defaultRecords);
        this.dataSource = new MatTableDataSource(this.DecrptedlstLead);
        this.dataSource.paginator = this.paginator;
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }
  // OnSearchPatient(){
  //   let tempPatientlist = this.DecrptedlstLead
  //   if(this.searchpatientListtxt !="" || this.searchpatientListtxt === undefined || this.searchpatientListtxt === null){
  //     this.lstLead = tempPatientlist.filter(
  //       (x: any) => 
  //       x.FirstName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) || 
  //       x.Email?.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
  //       x.LastName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
  //     //this.lstLead = tempPatientlist.filter((x: any) =>x.Email?.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
  //   }
  //   else{
  //    this.lstLead = tempPatientlist.slice(0, 5);
  //   }
  // }


  OnSearchPatient() {
    let tempPatientlist = this.DecrptedlstLead;
    if (this.searchpatientListtxt && this.searchpatientListtxt.trim() !== "") {
        const searchText = this.searchpatientListtxt.toLowerCase().trim();
        this.lstLead = tempPatientlist.filter(
            (x: any) => {
                const fullName = `${x.FirstName} ${x.LastName}`.toLowerCase();
                return fullName.includes(searchText) ||
                    (x.Email !== undefined && x.Email !== null && x.Email.toLowerCase().includes(searchText));
            }
        );
    } else {
        this.lstLead = tempPatientlist.slice(0, 5);
    }
}


  onIntakeSchedule(item) {

    localStorage.setItem("immediateActionId", item.ImmediateActionId);
    //this.router.navigate(['/intake-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientAccountId), name: this.commonService.FrontEndEncryption(item.FirstName), pageid: 1 } });
    let obj = {
      name: item.FirstName,
      patientAccountId: item.PatientAccountId,
      pageid: 1,
      age: moment().diff(item.Dob, 'years'),
      gender: item.GenderType,
      lastName:item.LastName,
      location:item.SfccMasterId

    }
    this.router.navigate(['/intake-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(obj) } });
  }

  onPatientdetailview(id) {
    this.router.navigate(['/leaddetailview'], { queryParams: { id: this.commonService.FrontEndEncryption(id), PageId: 1 } });
  }
  onPatientActivitylogview(id){
    this.ShowActivity = true;
    this.GetActivityId = id;
    this.getLeadHistory()
  }
  onPatientinfo(id, item) {
    //this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(id), email: this.commonService.FrontEndEncryption(item.ParentList[0].Email), name: this.commonService.FrontEndEncryption(item.FirstName) } });
    this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(id), email: this.commonService.FrontEndEncryption(item.Email), name: this.commonService.FrontEndEncryption(item.FirstName), isAdminClicked :this.commonService.FrontEndDecryption(true) } });
  }
  onPatientinfoRedirect(id,item)
  {
    
    this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(id), email: this.commonService.FrontEndEncryption(item.Email), name: this.commonService.FrontEndEncryption(item.FirstName), isAdminClicked :this.commonService.FrontEndDecryption(true),isInsuranceRedirect:true } });
  }
  getMoreInformation(item) {
    return 'Source Type : ' + item.SourceType + ' \n  Lead Type :  ' + item.LeadType + ' \n Facility Type :  ' + item.FacilityTypeName + ' \n ';
  }
  onCloseActivitylog(){
    this.ShowActivity = false;
  }
  onResendActivation(item) {
    this.spinnerservice.show();
    this.isPopup = true;
    this.isEditUsers = false;
    this.resendactivationemail = item;
    this.oldEmail = this.resendactivationemail.Email;
    this.spinnerservice.hide();
    // this.spinnerservice.show();
    // let _obj = {
    //   PatientInfoId: item.PatientInfoId,
    // //  Email: item.ParentList[0].Email
    // Email: item.Email,
    // IsPatient : true,
    // }
    // this.leadservice.postResendActivation(_obj).subscribe(
    //   (res: any) => {
    //     // this.resendactivationemail = res.Email;
    //     this.snackbar.success(res)
    //     this.spinnerservice.hide();

    //   },
    //   err => {
    //     this.spinnerservice.hide();
    //   },
    // );
  }

  getLeadHistory() {
    this.leadhistory = []
    this.spinnerservice.show()
    this.leadservice.getLeadHistory(this.GetActivityId).subscribe(
      (res: any) => {
        if (res) {
          this.Decrptedlst = res;
          this.leadhistory = this.Decrptedlst;
         // console.log(this.leadhistory)
        }
        this.spinnerservice.hide()
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  openComposeMail(email) {
    // this.closeEmailPopup = true;
    this.spinnerservice.show();
    this.global.clearEmaillist();
    this.global.onOpenEmailPopup(email);
    // window.location.reload();
    this.spinnerservice.hide();
  }
  onUpdateCRBA(id) {
    this.getInsuranceId(id);
  }
  
  getInsuranceId(patientinfoId) {
    localStorage.setItem("HistoryId","0");
    this.leadservice.getInsuranceId(patientinfoId).subscribe(
      (res: any) => {
        if (res && res.length > 0) {
          let InsuranceId = res[0].insuranceDetailsId;
          localStorage.setItem("InsurancetypeId", InsuranceId);
          localStorage.setItem("InsuranceId", InsuranceId);
  
          this.router.navigate(['/crba'], {
            queryParams: {
              id: this.commonService.FrontEndEncryption(patientinfoId),
              InsurancetypeId: this.commonService.FrontEndEncryption(InsuranceId)
            }
          });
        }
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
        console.error("Error retrieving insurance ID", err);
      }
    );
  }
  
  onSubmitFinancial(item) {
    // var parentList = item.ParentList.filter(x => x.IsPrimaryContact == true);
    // if (parentList.length > 0) {
    //  this.parentFirstName =item.ParentName;
    // }
    // else {
    //  // this.parentFirstName = item.FirstName;
    //  this.parentFirstName =item.ParentName;
    // }
    this.parentFirstName =item.ParentName;
    this.router.navigate(['/financial-proposal'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientInfoId), name: this.commonService.FrontEndEncryption(item.FirstName), parentname: this.commonService.FrontEndEncryption(this.parentFirstName) } });
  }
  onRequestTour(item) {
    this.router.navigate(['/request-tour'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientInfoId), name: this.commonService.FrontEndEncryption(item.FirstName) } });
  }
  getfinancialReason(item) {
    this.leadservice.getfinancialReason(item.PatientInfoId).subscribe(
      (res: any) => {

        this.spinnerservice.hide();
        this.isFinancialDecline = true;
        this.financialDenyReason = "";
        this.financialDenyReason = res.reason;

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  closeDeclineProcess() {
    this.isFinancialDecline = false;
  }
  SharePopupClose(){
    this.isPopup = false;
  }
  onResendActivationEmail(resendactivationemail){
           if(resendactivationemail.Email != this.oldEmail){
             this.isPrimaryEmailChanged = true;
           }
          //  this.resendactivationemail.Email = this.oldEmail
          // this.email = resendactivationemail.Email;
          //  resendactivationemail.screenName = "UpdateEmailProfile"
          //  resendactivationemail.oldEmail = this.oldEmail;
          //  const dataToEncrypt = this.commonService.DataToEncrypt(resendactivationemail, false);
          //  this.leadservice.postParent(dataToEncrypt).subscribe((res: any) => {     
          //   if (res) {
          //     this.spinnerservice.hide();
          //     this.snackbar.success(res);

          //  }
          // })
          let _obj = {
              PatientInfoId: resendactivationemail.PatientInfoId,
            //  Email: resendactivationemail.ParentList[0].Email
             Email: resendactivationemail.Email,
             OldEmail :this.oldEmail,
             IsPatient : true,
             }
            this.leadservice.postResendActivation(_obj).subscribe(
              (res: any) => {
                this.resendactivationemail = res.Email;
               this.snackbar.success(res)
                this.spinnerservice.hide();
                this.isPopup = false;
        
             },
              err => {
               this.spinnerservice.hide();
             },
            );
  }
  // updateEmail()
  // {
    
  //   this.spinnerservice.show();
  //   let data={
  //     oldEmail :this.oldEmail,
  //      NewEmail : this.resendactivationemail.email
  //     // th.oldEmail = this.patientPrimary.Email;

  //   };
  //   const dataToEncrypt = {
  //     ObjInputString: EncryptFieldData((JSON.stringify(data))),
  //   };
  //    this.userService.UpdateEmail(dataToEncrypt).subscribe(
  //      res=>{
        
  //       this.spinnerservice.hide();
  //       this.snackbar.success(constantVariables.SuccessMessage.EmailUpdatedSucess);
  //       this.authenticationService.onLogout();
  //    },
  //        err => {
      
  //         this.spinnerservice.hide();
  //         this.snackbar.error(err.error);
  //       },)
  // }
  // //  if(this.isActiveEmail = true) {
  // //   this.resendactivationemail = item;
  // //   this.resendactivationemail.oldEmail = item.Email;
  // //  }
  //     this.spinnerservice.show();
  //     let _obj = {
  //       PatientInfoId: item.PatientInfoId,
  //     //  Email: item.ParentList[0].Email
  //     Email: item.Email,
  //     IsPatient : true,
  //     }
  //     this.leadservice.postResendActivation(_obj).subscribe(
  //       (res: any) => {
  //         // this.resendactivationemail = res.Email;
  //         this.snackbar.success(res)
  //         this.spinnerservice.hide();
  
  //       },
  //       err => {
  //         this.spinnerservice.hide();
  //       },
  //     );
  //   }
    // oneditUserProfile(item) {
    //   this.isEditUsers = true;
    //   this.userId = item.userId;
    //   this.getsfcclocation();
    //   this._dtoUser = item;
    //   this._dtoUser.oldEmail = item.email;
    //   if(item.isActive == 0 && item.emailConfirmed == false){
    //     this.isActiveUser = true;
    //   }else{
    //     this.isActiveUser = false;
    //   }
    //   if(item.emailConfirmed == false){
    //     this.emailConfirmed = false;
    //   }else{
    //     this.emailConfirmed = true;
    //   }
      
     
    //   // this.userService.getUsersByAuthId(authId).subscribe(userdata => {
    //   //   this.isEditUsers = true;
    //   //   this._dtoUser = userdata;
  
    //   // });
  
    // }
    // this.isEditEmailId = resendactivationemail.PatientInfoId;
    // this.isEditEmailId.oldEmail = resendactivationemail.Email;
    // this.leadservice.postResendActivation(resendactivationemail).subscribe(
    //         (res: any) => {
    //           this.snackbar.success(res)
    //           this.spinnerservice.hide();
      
    //         },)

    
    PopupClose(){
     this.isLeadInformation = false;
     this.declineReason = true;
     this.commentReason = true;
     this.reason = undefined;
     this.comments = '';
    }
    removeLead(PatientInfoId){
      this.isLeadInformation = true;
      this.PatientInfoId = PatientInfoId;
    }
    moveLeadInformation(){
      this.spinnerservice.show();
      if((this.reason == undefined || this.reason == 'undefined')|| (this.comments == undefined || this.comments == '')){
        if(this.reason == undefined || this.reason == 'undefined'){
          this.declineReason = false;
          this.spinnerservice.hide();
        }        
      if(this.comments == undefined || this.comments == ''){
            this.commentReason = false;
            this.spinnerservice.hide();
          }
                  
        return;   
       
      }     
      this.selectedreason = this.reason;
      this.selectedcomments = this.comments;
      this.IsActive = false;
      this.leadservice.RemoveLeadAccount(this.PatientInfoId , this.IsActive,this.selectedreason,this.selectedcomments).subscribe(
        (res: any) => {
          this.snackbar.success("Lead decline processed successfully");
          this.comments = '';
          this.spinnerservice.hide();
          this.reason = undefined;
          this.isLeadInformation = false;
          this.getLeadlistView();

        },
        err => {
          this.spinnerservice.hide();
        },
      );
      
    }
    DeclainListView(){
      this.router.navigate(['/admindeclainlist-lead'])
    }
    reasonchange(){
      if (this.reason == 'undefined'){
        this.declineReason = false;
        return;
      }
      this.declineReason = true;
    }
    commentchange(event){
      if (this.comments == '' || event == ''){
        this.commentReason = false;
        return;
      }
      this.commentReason = true;
    }
}

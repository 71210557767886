import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { PatientService } from 'src/app/patient/patient.service';
import * as moment from 'moment';
@Component({
  selector: 'app-intake-cmha',
  templateUrl: './intake-cmha.component.html',
  styleUrls: ['./intake-cmha.component.scss']
})
export class IntakeCmhaComponent implements OnInit {
  selectedTabIndex: number;
  queYesNo = constantVariables.questionType.YesNo;
  queMCSA = constantVariables.questionType.MCSA;
  queMCMA = constantVariables.questionType.MCMA;
  queDescriptive = constantVariables.questionType.Descriptive;
  queRatings = constantVariables.questionType.Ratings;
  survey = constantVariables.inTakeForms.Survey;
  patientInfoId: any;
  patientAccountId: any;
  pdfPatientInformationIntake: any = null;
  chmastatus: any;
  isPatient: number = 0;
  isClinicalInfo: number = 0;
  isEvaluvation: number = 0;
  isClientSW: number = 0;
  isAssessment: number = 0;
  isClinicalSummary: number = 0;
  isTreatmentSummary: number = 0;
  patientUpdatedOn: any;
  page: any;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  isMovedChma: number = 0;
  chmaId: any;
  _dtoPatientInfo: any;
  showModal: boolean;
  constructor(public global: Global, public patientservice: PatientService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.selectedTabIndex = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    if (isNaN(this.selectedTabIndex) || this.selectedTabIndex < 0) {
      this.selectedTabIndex = 1;
    }
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        if (params.patientid) {
          this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patientid).replace(/"/g, ""));
        }
        this.page = params.page;
        this.isMovedChma = Number(this.commonService.FrontEndDecryption(params.isChma).replace(/"/g, "")) 
        this.chmaId = Number(this.commonService.FrontEndDecryption(params.isChma).replace(/"/g, ""))
      }
      this.getChmaStatus();
    });
  }
  onTabChange(tab) {
    // this.showModal = false;

    // if (this.OnclickTab()) {
    //   this.Popup(tab);
    //   return ;
    // }
    // else {
      this.router.navigate([], {
        relativeTo: this.route, queryParams: {
          tab: tab,
          id: this.commonService.FrontEndEncryption(this.patientAccountId),
          patientid: this.commonService.FrontEndEncryption(this.patientInfoId),
          isChma: this.commonService.FrontEndEncryption(this.isMovedChma),
        }
      });
      this.selectedTabIndex = tab;
    //}
  }
  getChmaStatus() {
    this.patientservice.getCmhaStatus(this.patientAccountId).subscribe(
      (res: any) => {
        //this.isPatient = 1;
        if (res) {
          this._dtoPatientInfo = res.patientDetailedInfo;
          this.calculateAge()
          if(res.dtoChmastatuses.length>0)
          {
          this.chmastatus = res.dtoChmastatuses;
          this.global.isPatient = this.chmastatus.filter(x => x.formType == 1).length > 0 ? this.chmastatus.filter(x => x.formType == 1)[0].formType : 0;
          this.global.isClinicalInfo = this.chmastatus.filter(x => x.formType == 2).length > 0 ? 1 : 0;
          this.global.isEvaluvation = this.chmastatus.filter(x => x.formType == 3).length > 0 ? 1 : 0;
          this.global.isAssessment = this.chmastatus.filter(x => x.formType == 5).length > 0 ? 1 : 0;
          this.global.isClientSW = this.chmastatus.filter(x => x.formType == 4).length > 0 ? 1 : 0;
          this.global.isClinicalSummary = this.chmastatus.filter(x => x.formType == 6).length > 0 ? 1 : 0;
          this.global.isTreatmentSummary = this.chmastatus.filter(x => x.formType == 7).length > 0 ? 1 : 0;
          this.global.patientUpdatedOn = this.chmastatus.filter(x => x.formType == 1).length > 0 ? this.chmastatus.filter(x => x.formType == 1)[0].updatedDate : null;
          this.global.patientUpdatedByName = this.chmastatus.filter(x => x.formType == 1).length > 0 ? this.chmastatus.filter(x => x.formType == 1)[0].updateByName : null;
          this.global.clinicalInfoUpdatedOn = this.chmastatus.filter(x => x.formType == 2).length > 0 ? this.chmastatus.filter(x => x.formType == 2)[0].updatedDate : null;
          this.global.clinicalInfoUpdatedByName = this.chmastatus.filter(x => x.formType == 2).length > 0 ? this.chmastatus.filter(x => x.formType == 2)[0].updateByName  : null;
          this.global.evaluvationUpdatedOn = this.chmastatus.filter(x => x.formType == 3).length > 0 ? this.chmastatus.filter(x => x.formType == 3)[0].updatedDate : null;
          this.global.evaluvationUpdatedByName = this.chmastatus.filter(x => x.formType == 3).length > 0 ? this.chmastatus.filter(x => x.formType == 3)[0].updateByName : null;
          this.global.clientSWUpdatedOn = this.chmastatus.filter(x => x.formType == 4).length > 0 ? this.chmastatus.filter(x => x.formType == 4)[0].updatedDate : null;
          this.global.clientSWUpdatedByName = this.chmastatus.filter(x => x.formType == 4).length > 0 ? this.chmastatus.filter(x => x.formType == 4)[0].updateByName : null;
          this.global.assessmentUpdatedOn = this.chmastatus.filter(x => x.formType == 5).length > 0 ? this.chmastatus.filter(x => x.formType == 5)[0].updatedDate : null;
          this.global.assessmentUpdatedByName = this.chmastatus.filter(x => x.formType == 5).length > 0 ? this.chmastatus.filter(x => x.formType == 5)[0].updateByName : null;
          this.global.CSummaryUpdatedOn = this.chmastatus.filter(x => x.formType == 6).length > 0 ? this.chmastatus.filter(x => x.formType == 6)[0].updatedDate : null;
          this.global.CSummaryUpdatedByName = this.chmastatus.filter(x => x.formType == 6).length > 0 ? this.chmastatus.filter(x => x.formType == 6)[0].updateByName : null;
          this.global.TSummaryUpdatedOn = this.chmastatus.filter(x => x.formType == 7).length > 0 ? this.chmastatus.filter(x => x.formType == 7)[0].updatedDate : null;
          this.global.TSummaryUpdatedByName = this.chmastatus.filter(x => x.formType == 7).length > 0 ? this.chmastatus.filter(x => x.formType == 7)[0].updateByName : null;
          } 

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onbacktoMytask() {
    // this.router.navigate(['/clinician'], { queryParams: { page: this.page } });
    if(this.global.ismytask==undefined || this.global.ismytask){
    window.close();
    }
  //  window.location.reload()
  else{
    this.router.navigateByUrl('/clinician');
  }
  }
  onGeneratePDF(item) {
    this.spinnerservice.show();
    this.patientservice.getChmaPdf(this.patientAccountId).subscribe(
      (res: any) => {

        var result = res;
        this.pdfPatientInformationIntake = result.filePath
        if(item==1){
          window.open(this.pdfPatientInformationIntake, '_blank');
        }
        else if(item==0){
          this.downloadLink.nativeElement.href = result.base64File;
          this.downloadLink.nativeElement.download = result.fileName;
          this.downloadLink.nativeElement.click();
        }
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  calculateAge() {
    //this._dtoPatientInfo.age = moment().diff(this._dtoPatientInfo.dob, 'years');

  }

  OnclickTab() {
    let result: boolean;
    switch (this.selectedTabIndex) {
      case 1: {
        if (this.global.frmPatientDetails) {
          result = this.global.frmPatientDetails.touched;
        }
        // else if (this.EngPrimaryInfoform) {
        //   result = this.EngPrimaryInfoform.touched;
        // }
        else
          result = false;
        break;
      }
    }
    return result
  }
  
  Popup(tab){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
  
      data: {
  
        title: '',
  
        message: 'You have unsaved changes! If you leave, your changes will be lost.'
  
      }
  
    });
  
    confirmDialog.afterClosed().subscribe(result => {
  
      if (result === true) {
       this.onTabChange(tab);
      }
      else{
        
      }
  
    });
  
  }
}

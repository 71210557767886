export class ParentInfo {
  ParentInfoId: number;
  PatientInfoId: number;
  FirstName: string;
  LastName: string;
  Email: string;
  OldEmail : string;
  ContactNo: string;
  ParentalRelationTypeId: number;
  IsPrimaryContact: boolean;
  MaritalStatus: number;
  AddressLine1: string;
  City: string;
  Zip: number;
  WorkNumber: string;
  CellNumber: string;
  StatesId: number;
  IsEmergency: boolean;
  Esignature: string;
  EsignType: string;
  IsSignFuture: boolean;
  ParentEsignature: string;
  IsParentSignFuture: boolean;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { CommonService } from 'src/app/common/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';

@Component({
  selector: 'app-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.scss'],
})
export class AuditListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  auditList: any = [];
  isClinician: boolean = false;
  selectedLocation: any;
  roleAccess: RoelAccess;
  displayedColumns = ['sno', 'clientName', 'location', 'status', 'actions'];
  constructor(
    public spinnerservice: NgxSpinnerService,
    public clinicianservice: ClinicianService,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    public roleaccessservice: PageAccessService,
  ) {}

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Audit;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.getAuditListClinician();
  }
  getAuditListClinician() {
    this.selectedLocation =
      Number(localStorage.getItem('sfcclocation')) <= 0
        ? -1
        : Number(localStorage.getItem('sfcclocation'));
    this.isClinician =
      Number(localStorage.getItem('roletypeId')) ==
        constantVariables.role.PrimaryClinician ||
      Number(localStorage.getItem('roletypeId')) ==
        constantVariables.role.SupportClinician
        ? true
        : false;
    this.spinnerservice.show();
    this.clinicianservice.getAuditListClinician(this.selectedLocation,this.isClinician).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.auditList = this.commonService.decrypdata(res.objInputString);
        //  console.log(this.DecrptedlstPatient);
        this.dataSource = new MatTableDataSource(this.auditList);
        this.dataSource.paginator = this.paginator;
      },
      (err) => {
        this.spinnerservice.hide();
      }
    );
  }
  OnViewDetails(item) {
    this.router.navigate(['/audit-clinician'], {
      queryParams: {
        id: this.commonService.FrontEndEncryption(item.PatientAccountId),
      },
    });
  }
  userFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  OnBackClick() {
    this.router.navigate(['/leadlist']);
  }
  filterChange(value) {
    let filterChange;
    if (value == '1') {
      filterChange = this.auditList.filter((x) => x.Pending != 0);
    } else if (value == '2') {
      filterChange = this.auditList.filter((x) => x.Completed != 0);
    } else if (value == '3') {
      filterChange = this.auditList.filter((x) => x.Overdue != 0);
    } 
    else if (value == '4') {
      filterChange = this.auditList.filter((x) => x.YetToStart != 0);
    } else {
      filterChange = this.auditList;
    }
    this.dataSource = new MatTableDataSource(filterChange);
    this.dataSource.paginator = this.paginator;
  }
}

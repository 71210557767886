<div class="lead-section">
    <div class="top-btn-section clearfix">
      <h2 class="head-section mb-2 mt-0">Authorization Settings</h2>
    </div>
    <div class="lead-box">
        <form (ngSubmit)="AuthSettings.form.valid" id="AuthSettings" autocomplete="off"  #AuthSettings="ngForm" novalidate>
        <div class="col-md-12 p-0 mt-4">
          <div>Notify before the available authorization is exhausted by : </div>
            <div class="row">
                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Before expiry Date</mat-label>
                        <mat-select  [(ngModel)]="dtoAuthNotifySettings.notifyDateId" #notifyDateId="ngModel" name="notifyDateId"
                        [ngClass]="{ 'is-invalid': AuthSettings.submitted && notifyDateId.invalid }" required >
                        <mat-option *ngFor="let item of AuthorizationNotificationType" [value]="item.authorizationNotificationTypeId" >{{item.typeName}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuthSettings.submitted && notifyDateId.invalid">
                          <mat-error *ngIf="notifyDateId.errors.required">This field is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>
                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Before No.of Visits</mat-label>
                        <mat-select   [(ngModel)]="dtoAuthNotifySettings.notifyPercentId" #notifyPercentId="ngModel" name="notifyPercentId"
                        [ngClass]="{ 'is-invalid': AuthSettings.submitted && notifyPercentId.invalid }" required  >
                        <mat-option *ngFor="let item of AuthorizationNotificationPercentageType" [value]="item.authorizationNotificationTypeId" >{{item.typeName}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuthSettings.submitted && notifyPercentId.invalid">
                          <mat-error *ngIf="notifyPercentId.errors.required">This field is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>
            </div>
            <div class="col-md-12 text-right">  
                <button
                  type="submit" mat-stroked-button title="Save" form="AuthSettings" 
                  class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="postNotifyAuthsettings()">            
                {{!isEditAuthSettings ? 'Save' :
                'Update'}}
                </button>
                <button
                  type="button"
                  mat-stroked-button
                  title="Cancel"
                  class="btn btn-cancel primary-btn-m h-40">
                  Cancel
                </button>
              </div>
        </div>
        </form>
    </div>
  </div>

export class financial {
    financialProposalId: number;
    patientInfoId: number;
    programType:number;
    priceCatalogueId: number;
    inTakeFee: string;
    sessionFee: string;
    deductibleFee: string;
    quotedAmounts: string;
    deductible:number;
    deductibleTypeId:number;
}
export class financialreview {
    intakeFee:string;
    sessionFee:string;
    anthemVal:string;
    deductibleVal:string;
    priceCatalogueId:number;
    priceComponentId:number;
    patientInfoId:number;
    deductible:number;
    deductibleTypeId:number;
    componentdetails:any;
    deductibleActVal:string;
    isInsuranceCoverage :boolean;
    deductibleComments:string;
}
<div class="create-patients" >
    <div class="d-flex w-100 " >
    <div class="col-md-4 ">
        <div  class="search">
            <input  type="text" placeholder="Search By Client Name" name="" class="search-input " [(ngModel)]="searchpatientListtxt" (keyup)="OnSearchPatient()"><a  class="search-icon"><i  class="fa fa-search"></i></a></div>
    </div>
    <div class="col-md-8 text-right" *ngIf="roleAccess.isView">
        <button  mat-stroked-button class=" btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2 dclnbtn" (click)="DeclainListView()"> <i class="far fa-list-alt mr-2"></i>Declined  List</button>
        <button  mat-stroked-button class=" btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2 " (click)="OnDischargeListView()"> <i class="far fa-list-alt mr-2"></i>Discharged List</button>
        <button  mat-stroked-button class=" btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2 " (click)="OnAuthDetails()"> Authorization Details</button>
    </div>
</div>

    <div class="list-group contact-group mt--14" *ngIf="roleAccess.isView"> 
        <!-- | grdFilter:  searchpatientListtxt:'AdminPatientLists'; -->
        <a class="list-group-item" *ngFor="let item of lstPatient">
            <div class="col-md-12 p-0">
                <div class="row">
                    <div class="col-md-4 ">
                        <div class="media">
                            <div class="pull-left">
                                <img class="rounded-circle"
                                    src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                    alt="...">
                            </div>
                            <div class="media-body">
                                <h4 *ngIf="!item.PreferredName" class="media-heading">{{item.FirstName}} {{item?.LastName}}  {{item.Age!=null?'-':''}} {{item.Age}}{{item.Age!=null?' yrs':''}} </h4>
                                <h4 *ngIf="item.PreferredName" class="media-heading">{{item.FirstName}} {{item?.LastName}}  {{item.Age!=null?'-':''}} {{item.Age}}{{item.Age!=null?' yrs':''}}({{item.PreferredName}}) </h4>
                                <h4 class="media-heading"> <span>DOB:</span> {{item.Dob | date:'MM/dd/yyyy' }} </h4>
                                <span class="status-patient"> Preferred Pronouns:</span><span class="patient-info-value"> {{item.PreferredPronouns}}</span>
                                <div class="media-content">
                                    <ul class="list-unstyled">

                                        <li><i class="fas fa-mobile"></i> {{item?.ContactNo | mask: '(000)
                                            000-0000'}}</li>
                                        <li><i class="fas fa-envelope"></i> {{item?.Email}}</li>
                                        <li *ngIf="item.ClinicianName!=null"><span class="status-patient"> Primary Clinician:</span><span class="patient-info-value">{{item.ClinicianName}}</span>
                                            <a (click)="onShowPrimaryClinicianPopup(item)"  class="edit-status"><i class="far fa-edit"></i></a>
                                        </li>
                                        <!-- <li><span class="lead-stage-head stage-contact">{{item.LeadStage}}</span> </li> -->
                                        <!-- <li><span class="lead-stage-head stage-contact">Intake</span> </li> -->
                                        <li  class="mr-2 ml-auto d-flex"><span class="status-patient">Status</span>
                                            <span class="status-patient-value">{{item.PatientStatusName}}</span><a (click)="onShowStatusPopup(item)"  class="edit-status"><i class="far fa-edit"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="">
                            <label class="program-heading">Program Status</label>
                            <!--Schedule Intake if Create intake is enabled-->
                           <div *ngIf="(item.ImmediateActionId == statusintakePacket &&  item.MeetingStatus == statusintakeDeclined && !item.IsRejoiner) || (item.ImmediateActionId == statusintake && !item.IsRejoiner) ">
                            <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                            (click)="onIntakeSchedule(item)"
                            *ngIf="(item.ImmediateActionId == statusintake  || item.ImmediateActionId == statusintakePacket)  "
                            title="For Intake Schedule">
                            {{  item.MeetingStatus == statusintakeDeclined ? 'Re-Schedule Intake':'Schedule
                            Intake'}}
                        </button>
</div>
<div *ngIf="item.IsRejoiner">
                            <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                (click)="onIntakeSchedule(item)"
                                *ngIf="(item.ImmediateActionId == statusintake  || item.ImmediateActionId == statusintakeDeclined) &&
                                (item.ChmaTreatmentSummaryId == null || item.IsRejoiner == true ) "
                                title="For Intake Schedule">
                                {{item.ImmediateActionId == statusintakeDeclined ? 'Re-Schedule Intake':'Schedule
                                Intake'}}
                            </button>
                            </div>

                                <!-- <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified" *ngIf="item.ChmaTreatmentSummaryId !== null" 
                                (click)="onScheduleModality(item)">Schedule Modality</button> -->
                                <!-- <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified" *ngIf="item.ChmaTreatmentSummaryId !== null" 
                                (click)="onRedirectTo(item,2)">{{ item.ImmediateActionId != statusPsyReschedule?'Schedule Modality':'Schedule Psychiatric Therapy'}}</button> -->
                                <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified" *ngIf="item.ChmaTreatmentSummaryId !== null && item.IsRejoiner != true " 
                                (click)="onRedirectTo(item,2)">Schedule Modality</button>
                            <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                                (click)="onIntakeSchedule(item)" *ngIf="item.ImmediateActionId == statusintakeAccepted"
                                title="Assign Staff For Intake">Assign Staff For Intake
                            </button>
                            <div
                                *ngIf="item.ImmediateActionId == statusintakePacket  &&  item.MeetingStatus != statusintakeDeclined  && item.MeetingStatus !=statusmeetingReSchedule && item.MeetingStatus !=statusrequestcancel && (item.ChmaTreatmentSummaryId === null ||  item.IsRejoiner == true)">
                                <div class="patient-forms">
                                    <a (click)="onPrepareIntake(1,item)" title="Health Screening"><img
                                            src="../../../assets/images/health_screen.png" class="img-fluid"></a>
                                    <a (click)="onPrepareIntake(4,item)" title="Consent Form"><img
                                            src="../../../assets/images/consent-new.png" class="img-fluid"></a>
                                    <a (click)="onPrepareIntake(2,item)" title="Checklist"><img
                                            src="../../../assets/images/health_insurance.png" class="img-fluid"></a>
                                    <a (click)="onPrepareIntake(3,item)" title="Survey"><img
                                            src="../../../assets/images/survey.png" class="img-fluid"></a>
                                    <a (click)="OnPatientInsurance(item)" title="CRBA"><img
                                                src="../../../assets/images/certificate.png" class="img-fluid"></a>       
                                    <a (click)="OnFinancialProposal(item)" title="Financial Proposal">
                                        <img class="img-fluid" src="../../../assets/images/fin.png"></a>             
                                </div>
                                <div class="w-100 float-left">
                                    <div class="status-indicatorNew">

                                        <ul class="progress-indicator pl-2">
                                            <li [ngClass]="{'completed': item.IsHealth}">
                                                <span class="bubble" title="Health Screening"></span>
                                            </li>
                                            <li
                                                [ngClass]="{'completed': item.Verified >= 7 }">
                                                <span class="bubble" title="Consent Form"></span>
                                            </li>
                                            <li [ngClass]="{'completed': item.IsMedication}">
                                                <span class="bubble" title="Checklist"></span>
                                            </li>
                                            <li [ngClass]="{'completed': item.IsSurvey}">
                                                <span class="bubble" title="Survey"></span>
                                            </li>
                                            <li [ngClass]="{'completed': item.Crba}">
                                                <span class="bubble" title="CRBA"></span>
                                            </li>
                                            <li [ngClass]="{'completed': !item.NewFee}">
                                                <span class="bubble" title="Financial Proposal"></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p *ngIf="item.ImmediateActionId != statusintake &&  item.PatientStatus!=Null"
                                [ngClass]="item.ImmediateActionId == statusintakeScheduled ? 'plain-message mb-1 w-100' : 'status-msg mb-1 w-100'">
                                {{item.PatientStatus}}
                                <!-- *ngIf="item.ImmediateActionId != statusintakePacket" -->
                                <span *ngIf="item.ImmediateActionId !=statusintakePacket">{{item.CreatedOn|
                                    date: 'MM/dd/yyyy'}}</span>
                            </p>
                            <div *ngIf="item.MeetingStatus ==statusmeetingReSchedule" class="w-100 float-left">
                                <p title="{{item.RescheduleReason}}">{{item.RescheduleReason}}</p>
                                <button class="btn btn-dashboard green-theme  mr-2" title="Accept"
                                    (click)="onAcceptReschdule(item)">Accept</button>
                                <button class="btn btn-dashboard red-theme  mr-2" title="Deny"
                                    (click)="onDeclineReschdule(item)">Deny</button>
                                <button class="btn btn-dashboard red-theme" title="Communicate Client"
                                    (click)="onReturnLead(item)">Communicate Client</button>
                            </div>
                            <div *ngIf="item.MeetingStatus ==statusrequestcancel" class="w-100 float-left">
                               
                                <button class="btn btn-dashboard green-theme  mr-2" title="Accept"
                                    (click)="onAcceptCancelIntake(item)">Cancel Intake</button>
                                <button class="btn btn-dashboard red-theme  mr-2" title="Deny"
                                    (click)="onDeclineCancelIntake(item)">Deny</button>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="icons-section " >
                            <!-- <button class="col text-center col-btn-dis"><i class="far fa-edit"></i></button>
                            <button class="btn btn-patient"><i class="fas fa-briefcase mr-2"></i>Briefcase View</button> -->
                            <button class="btn btn-patient" (click)="onRedirectTo(item,1)"><i class="fas fa-briefcase mr-2" ></i>Briefcase View</button>
                            <!-- <button class="btn btn-patient" *ngIf="item.ChmaTreatmentSummaryId !== null" (click)="onScheduleModality(item,2)"><i class="far fa-calendar-check mr-2"></i>Schedule Modality</button> -->
                            <!-- <button class="btn btn-patient" *ngIf="item.ChmaTreatmentSummaryId !== null" (click)="onRedirectTo(item,2)"><i class="far fa-calendar-check mr-2"></i>Schedule Modality1</button> -->
                            <!-- <button class="btn btn-patient"  *ngIf="item.ChmaTreatmentSummaryId !== null" (click)="onRedirectTo(item,3)"><i class="fas fa-first-aid mr-2"></i> Treatment
                                Plan</button> -->
                                <button class="btn btn-patient" (click)="OnActivityClick(item)"><i class="fas fa-user-clock mr-1"></i> Activity</button>
                                <button class="btn btn-patient"  (click)="onPatientPayment(item)"><i class="far fa-credit-card mr-1"></i> Payment Details</button>
                                <button _ngcontent-gwg-c395="" class="btn btn-patient" (click)="OnPatientDashboard(item)"><i class="fas fa-tachometer-alt mr-1"></i> Client Dashboard</button>
                                <button _ngcontent-gwg-c395="" class="btn btn-patient" (click)="OnPatientInsurance(item)"><i class="fas fa-shield-alt"></i> Insurance Details</button>
                                <button _ngcontent-gwg-c395="" class="btn btn-patient" (click)="OnFinancialProposalDetails(item)"><i class="far fa-credit-card"></i> Financial Proposal</button>

                                <div class="input-group mb-1 custom-btn-go">
                                    <span>Schedule</span>
                                    <select id="drP{{item.PatientInfoId}}" class="form-control"   (change)="onScheduleModalityChange($event.target.value)"><option value="M" > Modality</option><option value="P"> Phone Call </option><option value="S"> School Meeting</option></select>
                                    <div class="input-group-append">
                                      <button class="btn " type="button" id="Button3" (click)="onSelectedModality(item)"><i class="fas fa-arrow-right"></i></button>
                                    </div>
                                    
                                 </div>
                                 <div class="col-md-1">
                                    <button _ngcontent-gwg-c395="" class="btn btn-patient clireturn" (click)="removeLead(item)"><i class="fas fa-minus-circle"></i> Decline Client </button>
                                </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        </a>
        <mat-paginator class="pagination pagination-large" [pageSize]="20" (page)="onPageChange($event)" 
        [pageSizeOptions]="[ 10, 20, 50, 75, 100]">
    </mat-paginator>
    </div>
    <div class="list-group contact-group mt--14" *ngIf="!roleAccess.isView">
        <div class="msg-nomeet text-center">You do not have privilege to access this. Please contact admin</div>
    </div>
 
</div>

<div class="Activity-popup">
    <div class="modal"  tabindex="-1" role="dialog" [style.display]=" ShowActivity ? 'block' : 'none'">
       <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
             <div class="modal-header">
                <h6 class="modal-title">Activity Log</h6>
                <button type="button" class="close"  data-dismiss="modal"
                aria-label="Close" (click)="onCloseActivitylog()">
                <span aria-hidden="true">&times;</span>
             </button>
             </div>
             <div class="modal-body">
                <div class="individual-threaphy-container">
                <div  class="history-list col-md-14 p-0">
                   <ul>
                      <li *ngFor="let item of leadhistory;let i = index">
                          <div class="list-history col-md-14">
                              <div class="row">
                                  <div class="col-md-1" *ngIf="item.roleId !== 4">
                                      <img class="img-fluid" src="../../../assets/images/move2stage-icon.png"/>
                                  </div>
                                  <div class="col-md-1" *ngIf="item.roleId == 4">
                                      <img class="img-fluid" src="../../../assets/images/move2stage-icon-green.png"/>
                                  </div>
                                  <div class="col-md-9">
                                      <p class="sub-history mb-1"></p>
                                      <p class="sub-history2 mb-1" innerHTML="{{global.getContent(item.message)}}"></p>
                                  </div>
                                  <div class="col-md-2 text-right">
                                      <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) |  date: 'MM/dd/yyyy'}}</p>
                                      <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) | date: 'hh:mm a' }}</p>
                                  </div>
                              </div>
                             
                          </div>
                      </li>
                     
                  </ul>
              </div>
          </div>
          </div>
       </div>
          <div class="col-md-12 p-0 mt-4 view-note d-none">
          
              <div class="row">
                  <div class="col-md-12 mb-1" *ngFor="let item of leadhistory;let i = index">
                      <div class="list-note">
                          <div class="row">
                              <div class="col-md-8">
                                  <div class="float-left w-100 pl-4 position-relative">
                                      
                                      <p class="note-content mt-1" innerHTML="{{global.getContent(item.message)}}">
          
                                      </p>
                                  </div>
                                </div>
                  </div>
                </div>
                </div>
             </div>
          </div>
       </div>
    </div>
    
<div class="modal mail-modal" id="myModal" [style.display]="global.showEmailPopup ? 'block' : 'none'">
    <div class="modal-dialog mb-2">
        <div class="modal-content email-compose">
            <div class="modal-header">
                <h4 class="modal-title">Email</h4>
                <button aria-hidden="true" data-dismiss="modal" class="close" (click)="global.oncloseEmailpopup()"
                    type="button"><i class="fas fa-times"></i></button>

            </div>
            <div class="modal-body">
                <app-send-email [subtag]="subjectTag" [idtag]="idTag" [tomailtag]="parentemail"></app-send-email>

            </div>
        </div>
    </div>
</div>

<div class="comment-popup benchmark-popup-rating">
    <div class="modal" tabindex="-1" role="dialog"  [style.display]="ShowPatientStatus ? 'block' : 'none'">
      <div class="modal-dialog mat-dialog-container p-3" role="document">
        <div class="modal-content border-0">
          <div class="modal-body">
  <div class=" mb-3">
    <p  class="text-center font-weight-500 mt-2">Do you want to change the Status ?</p>
    <div class="text-center">
        <!-- <mat-radio-group aria-label="Select an option">
            <mat-radio-button value="1" class="mr-2">IOP</mat-radio-button>
            <mat-radio-button value="2" class="mr-2">PCB</mat-radio-button>
            <mat-radio-button value="3" class="mr-2">Outpatient</mat-radio-button>
          </mat-radio-group> -->
    <!--      <mat-radio-group aria-label="Select an option" [(ngModel)]="patientStatusId" #PatientStatusId="ngModel" name="PatientStatusId"  >   -->
     <mat-radio-group aria-label="Select an option"  name="patientsts" [(ngModel)]="patientStatusId" #PatientStatusId="ngModel"  required > 
             <mat-radio-button *ngFor="let item of ddlPsMaster; let i=index" class="mr-2"  [value]="item.PatientStatusId"   (change)="patientStatusradioChange($event)"> {{item.PatientStatusName}} </mat-radio-button>            
    </mat-radio-group>
    </div>
    
  </div>
  
  <div class="text-center">
    <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2"  (click)="updatePatientStatus()">Save</button>
    <button type="button" class="btn btn-cancel" 
      data-dismiss="modal"   (click)="onCloseStatusPopup()">Close</button>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>


  <div class="comment-popup  cancel-tour">

    <div *ngIf="isLeadInformation">
    <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
      <div class="modal-dialog mat-dialog-container popupdcln" role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title pdgleft" >Decline</h6>
           
            
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="PopupClose()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
  <div >
    
    <div class="col-md-12">
      <div class="row mb-2">
      </div>
     
      <div class="row mb-2" style="margin-left:1px">
        <div class="col-md-4 pr-0 pl-0">
          <label class="control-label-new " >Reason for decline<span class="errorpopup">*</span></label>
        </div>
  
         <div class="col-md-4 form-group pl-0">
          <select class="form-control drpdown" name="reason" 
          [(ngModel)]="reason"   required (change)="reasonchange()">
          <option value="undefined">-- Reason --</option>         
           <option value="Future Client">Future Client</option>
           <option value="Incompatible">Incompatible</option>
           <option value="Financial Issues">Financial Issues</option>
           <option value="Transportation Issues">Transportation Issues</option>
           <option value="Waitlist">Waitlist</option>
           <option value="Ghosted">Ghosted</option>
           <option value="Other">Other</option>
          
       </select>
       <span class="text-danger fs12 row cmnterrmsg" *ngIf="!declineReason">
          Reason for decline is required
           </span>
       
        </div>
      
      </div> 
      <div>
        <div class="example-form">
          <mat-form-field class="example-full-width w-100">
            <mat-label>Comments<span class="errorpopup">*</span></mat-label>
            <input matInput [(ngModel)]="comments" (ngModelChange)="commentchange($event)" placeholder="" maxlength="150">

          </mat-form-field>
          <span class="text-danger fs12 row ermsg" *ngIf="!commentReason">
            comments is required
          </span>
        </div>
 
      </div>
  
        <div class="col-md-2"  >
          <button mat-raised-button   
          class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40 smtbtn"  type="submit" 
          (click) = "movePatientInformation()"  >Submit</button>
        
        </div>
     
    </div>
  </div>
  </div>
  </div>
  </div>
  </div>
 </div>
   </div>
  

    
   <ng-template let-data #clientModal >
    <div class="row">
      <h2 mat-dialog-title class="col-8">Future Therapy Details</h2>
     
      <div class="col-1 text_right closeleft">
        <mat-icon  mat-dialog-close>close</mat-icon>
      </div>
    </div>
  
    <mat-dialog-content>
     <div *ngIf="clientTherapydetails.length == 0">
       <span class="spntext">No future appoinments</span>
     </div>
      
        <div class="row" *ngIf="clientTherapydetails.length > 0">
          <div style="margin-top: 10px;">
            <table class="table table-bordered">
              <thead>
                <tr>
                    
                    <th>Therapy</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let li of clientTherapydetails;let i=index" >
                    <td>{{li.Reason}}</td>
                    <!-- <td>{{li.location}}</td>
                    <td>{{li.clinicianName}}</td> -->
                    <td>{{li.ScheduleDate |date:'MM/dd/yyyy'}}</td>
                    <td>{{li.FromTime |date:'shortTime'}}</td>
                    <td>{{li.Duration}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="fntweight"  *ngIf="clientTherapydetails.length > 0">
          <span>Do you want to remove future therapies?</span>  
        </div>
        <div class="col-md-8 row yesorno"  >
            <button mat-raised-button   
            class="yes"  type="submit" 
            (click) = "yesPatientInformation()"  >Yes</button>
            <button mat-raised-button   
            class=" no"  type="submit" 
            (click) = "noPatientInformation()"  >No</button>
            <!-- <button   
            class=""  type="submit" 
            (click) = "yesPatientInformation()"  >Yes</button>
            <button 
            class=""  type="submit" 
            (click) = "noPatientInformation()"  >No</button> -->
          
          </div>
    </mat-dialog-content>
  </ng-template>


  <div class="comment-popup benchmark-popup-rating">
    <div class="modal" tabindex="-1" role="dialog"  [style.display]="ShowPrimaryClinicianPopup ? 'block' : 'none'">
      <div class="modal-dialog mat-dialog-container p-3" role="document">
        <div class="modal-content border-0">
          <div class="modal-body">
  <div class=" mb-3">
    <p  class="text-center font-weight-500 mt-2">Do you want to change the PrimaryClinician ?</p>
    <div class="text-center">       
        <mat-form-field class="w-100">
        <mat-select [(ngModel)]="primaryclinicianID" #PrimaryClinician="ngModel"
            name="PrimaryClinician">
            <mat-option *ngFor="let item of clinicianDetails" [value]="item.userId">
                {{item.firstName}} {{item.lastName}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    </div>
    
  </div>
  
  <div class="text-center">
    <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2"  (click)="updatePrimaryClinician()">Save</button>
    <button type="button" class="btn btn-cancel" 
      data-dismiss="modal"   (click)="onClosePrimaryClinicianPopup()">Close</button>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>

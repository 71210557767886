export class ProviderDetails{
    PhysicianDetailsId:Number;
    Pid:Number;
    PhoneNumber:string;
    HospitalName:string;
    FirstName:string;
    LastName:string;
    Email:string;
    AddressLine1:string;
    AddressLine2:string;
    StatesId:any;
    CityId:any;
    ZipCode:string;
    PhysicianTypeId:any;
    CellPhone:string;
    FaxNumber:string;
    IsAgree:any;
    Role:any;
    PatientHistoryId:Number;
    DataFrom:any;
    PatientAccountId:Number;
    PatientInfoId:Number;
}




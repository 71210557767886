export class IncReviewInfo{
    IncidentReportTrackId:Number=0;
    IncidentReportId:Number;
    ReportStatus:Number;
    IsReportCompleted:boolean;
    IsFollowUp:boolean;
    Disposition:string;
    Narrative:string;
    FollowUpBrief:string;
    IsInformed:boolean;
    IsDied:boolean;
    StaffInitial:string;
    FollowUpDate:string;
    CompletedDate:string;
    IsAttachEsign:boolean;
    IncidentReportTypeId:Number;
    ReviewerName:string ;
     PhoneNumber:string ;
    SignDate :string;
   HandledDifferent :string ;
    Psychologicalcomfort :string ;
     Emotionalfunctions :string ;
    PlanForCare :string ;
    StaffInitial1:string;
    licenseNumber : string;
    sSignature : boolean;
    LicenseNumber : string;
    SSignature : boolean;
}
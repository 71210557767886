import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../admin/authentication.service';
import { SnackBarService } from '../services/snackBar.service';
import { constantVariables } from '../constant/constant';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class PageAuthGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService,private snackbar: SnackBarService,
    private route: ActivatedRoute,private router: Router,public commonService: CommonService) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
   
      let roles = route.data.roles as Array<string>;
      let roleTypeId = +localStorage.getItem('roletypeId');
      let pageId = route.data.pageId;
      var access = JSON.parse(localStorage.getItem('roleaccess'));
      var permissionAccess;
      var routerUrl = this.router.url;
      if(access != null)
      {
         permissionAccess = access?.pagepermissions?.find(x => x.pageId == pageId);
      }
      if(routerUrl == "/phone-schoolmeet-schedule")
        {
        this.route.queryParams.subscribe(params => {
          if (params != null) {
            var details = JSON.parse(this.commonService.FrontEndDecryption(params.id));
            var scheduleType = Number(details.pageid);
            if (scheduleType == 11) {
              pageId = constantVariables.pagelists.ScheduleAppointmentsTherapies;
            }
            else if (scheduleType == 10) {
              const pageId = constantVariables.pagelists.ScheduleAppointmentsTherapies;
            }
            else if (scheduleType == 1) {
              const pageid = constantVariables.pagelists.ScheduleIntake;
            }
          }
        });
      }
    if (roleTypeId == 4  ){
      if(roles[0].includes('Lead'))
      {
        if((pageId == constantVariables.pagelists.Briefcase || pageId == constantVariables.pagelists.PatientAccount) && (localStorage.getItem('patientAccountId') == undefined || localStorage.getItem('patientAccountId') == null  ))
        {
          this.snackbar.error(constantVariables.ErrorMessage.NoAccess);
          this.authenticationService.onLogout();
        }else{
          return true;
        }
      }else{
        this.snackbar.error(constantVariables.ErrorMessage.NoAccess);
        this.authenticationService.onLogout();
      }
       
    }else if(roleTypeId == 5 || roleTypeId == 6 || roleTypeId == 7)
    {
      if(roles[0].includes('Clinician') )
      {
        if(pageId != undefined && pageId == 0)
        {
          return true;
        }else if(permissionAccess?.isCreate || permissionAccess?.isView || permissionAccess?.isEdit || permissionAccess?.isDelete)
        {
          return true;
        }
        else{
          this.snackbar.error(constantVariables.ErrorMessage.NoAccess);
          this.authenticationService.onLogout();
        }
       
      }else{
        this.snackbar.error(constantVariables.ErrorMessage.NoAccess);
        this.authenticationService.onLogout();
      }
    }else if(roleTypeId == 1 || localStorage.getItem('roletypeId') == 'null')
    {
      if(roles[0].includes('Admin'))
      {
        
        if(pageId != undefined && pageId == 0)
        {
          return true;
        }else if(permissionAccess?.isCreate || permissionAccess?.isView || permissionAccess?.isEdit || permissionAccess?.isDelete)
        {
          return true;
        }
        else{
          this.snackbar.error(constantVariables.ErrorMessage.NoAccess);
          this.authenticationService.onLogout();
        }
      }else{
        this.snackbar.error(constantVariables.ErrorMessage.NoAccess);
        this.authenticationService.onLogout();
      }
    }
    else{
        return true;
    }
   
     
  
  }

  }

import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { PatientService } from 'src/app/patient/patient.service';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import {Location} from '@angular/common';
import { Router,ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { IntakegenerateService } from 'src/app/patient/intake/intake-generate/intakegenerate.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { constantVariables } from 'src/app/constant/constant';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import {DtoPatientOutofPacketInvoicePdf } from 'src/app/_models/_Billing/dtoPatientOutofPacketDetails';

@Component({
  selector: 'app-patientdocuments-details',
  templateUrl: './patientdocuments-details.component.html',
  styleUrls: ['./patientdocuments-details.component.scss']
})
export class PatientdocumentsDetailsComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('anawrapper') mainpage: ElementRef;
  pdfNutritionfile: any;
  patientAccountId: any;
  documentType: any;
  pdfPatientInformationIntake: any;
  patientName : any;
  isPainAssesment : boolean =false;
  isSucidalRisk : boolean =false;
  lstDocuments: any;
  pdfSuicdescreener: any;
  isIntakeDocuments:boolean=true;
  isTreatmentDocument:boolean=false;
  isAppointmentDocument:boolean=false;
  isSusidalRiskDocument:boolean=false;
  isSusidalRiskDocumentExist:boolean=false;
  appoinmentDocumentList :any;
  pdfFamIndNotes: any;
  pdfpsymedNotes: any;
  pdffile: any;
  pdfpsytherapynotes: any;
  isAppointmentDocumentExists1:boolean=false;
  isIntakePacket:boolean=false;
  isNutritionalQuestion:boolean=false;
  isChma:boolean=false;
  noDocuments:boolean=false;
  DtoPatientOutofPacketInvoicePdf:DtoPatientOutofPacketInvoicePdf;
  dtoPatientOutofPacketInvoicePdf;
  pdfInvoice:any;
  financialDocumentList:any;
  dtoPaymentActiveList:any;
  totalpending:any;
  financialProposalfile:any;
  constructor(public service: IntakegenerateService,public patientservice: PatientService,public spinnerservice: NgxSpinnerService,private snackbar: SnackBarService,
    public commonService: CommonService,public treatmentservice: TreatmentService,public clinicianservice: ClinicianService, private _location: Location,private router: Router,private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        this.patientAccountId = this.commonService.FrontEndDecryption(params.id).replace(/"/g, "");
        this.patientName = params.patientName;
      }
      this.dtoPatientOutofPacketInvoicePdf=new DtoPatientOutofPacketInvoicePdf();
      this.checkDocumentExists();
      this.getAppointmentDocumentList()
    })

  }
  checkDocumentExists(){
    this.spinnerservice.show();
    this.patientservice.checkDocumentExists(this.patientAccountId,0).subscribe(
      (res: any) => {
        if(res!=null)
        {
          this.spinnerservice.hide();
          this.isIntakePacket = res.isIntakePacket;
          this.isPainAssesment = res.isPainAssesment;
          this.isSucidalRisk = res.isSucidalRisk;
          this.isNutritionalQuestion = res.isNutritionalQuestion;
          this.isChma=res.isChma;
          this.lstDocuments =res.dtoSpSuicideDocuments;
          if(!this.isIntakePacket&&!this.isPainAssesment&&!this.isSucidalRisk&&!this.isNutritionalQuestion&&!this.isChma&&this.lstDocuments==null)
          {
            this.noDocuments=true;
            //this.snackbar.error("No records found in intake and treatment document");
            // this.isIntakeDocuments=false;
            // this.isTreatmentDocument=false;
          }
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  
  }
  OnBack(){
    this.router.navigateByUrl('/patient-documents');
  }
  intakeClick(){
    this.isIntakeDocuments=true;
    this.isTreatmentDocument=false;
    this.isAppointmentDocument=false;
    this.isSusidalRiskDocumentExist =false;

  }
  treatmentDocumentClick(){
    this.isIntakeDocuments=false;
    this.isTreatmentDocument=true;
    this.isAppointmentDocument=false;
    this.isSusidalRiskDocumentExist =false;
  }
  susidalRiskDocumentClick(){
    this.isIntakeDocuments=false;
    this.isTreatmentDocument=false;
    this.isAppointmentDocument=false;
    this.isSusidalRiskDocumentExist =true;
  }
  appointmentDocumentClick(){
    this.isIntakeDocuments=false;
    this.isTreatmentDocument=false;
    this.isAppointmentDocument=true;
    this.isSusidalRiskDocumentExist =false;
    //this.getAppointmentDocumentList();
  }
  getAppointmentDocumentList()
  {
    this.spinnerservice.show();
    this.patientservice.checkAppointmentDocumentExists(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if(res.objInputString!=null)
        {
          var DataResult = this.commonService.decrypdata(res.objInputString);
          if(DataResult)
          {
            this.isAppointmentDocumentExists1 =true;
            this.appoinmentDocumentList =DataResult;
            this.noDocuments=false;
          }
         
        } else{
          this.isAppointmentDocumentExists1 =false;
          
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  OnDownloadAppointment(item){
    if(item.TypeId == 2 || item.TypeId == 3)
    {
      this.OnDownloadInduvalAndFamily(item)
    }else if(item.TypeId == 5)
    {
      this.OnDownloadPhycatricTherapy(item);
    }else if(item.TypeId == 8 && item.ScheduleName.includes("_MentalStates_"))
    {
      this.OnDownloadPhyscatricEvalationMentalStatus(item);
    }
    else if(item.TypeId == 8 && !item.ScheduleName.includes("_MentalStates_"))
    {
      this.OnDownloadPhyscatricEvalation(item);
    }else if(item.TypeId == 10 || item.TypeId ==11)
    {
      this.OnDownloadSchoolMeetingAndPhoneCall(item);
    }else if(item.TypeId == 9)
    {
      this.OnDownloadPhyscatricMedication(item);
    }
    else if(item.TypeId == null && item.GroupTherapyId )
    {
      this.getProgressPDF(item);
    }


  }
  OnDownloadPhyscatricMedication(item){
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
      this.clinicianservice.getPsychiatricmedicationpdf(item.MeetingScheduleId,item.MeetingScheduleTimeId,item.PatientAccountId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdfpsymedNotes = res.filePath;
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        },
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  OnDownloadSchoolMeetingAndPhoneCall(item){
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
      this.clinicianservice.getCommunicationformpdf(item.MeetingScheduleId,item.MeetingScheduleTimeId,item.PatientAccountId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdfpsytherapynotes = res.filePath;
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        },
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  OnDownloadPhyscatricEvalationMentalStatus(item){
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
      this.clinicianservice.getPsychiatricEvaluationMSpdf(item.MeetingScheduleId,item.MeetingScheduleTimeId,item.PatientAccountId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdfpsymedNotes = res.filePath;
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        },
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  OnDownloadPhyscatricEvalation(item){
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
      this.clinicianservice.getPsychiatricEvaluationFormpdf(item.MeetingScheduleId,item.MeetingScheduleTimeId,item.PatientAccountId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdfpsymedNotes = res.filePath;
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        },
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  getProgressPDF(item){
    this.spinnerservice.show();
    this.clinicianservice.getProgressPDFForDocuments(item,item.ScheduleDate).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdffile = res.filePath;
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  OnDownloadPhycatricTherapy(item){
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
      this.clinicianservice.getPsychiatrictherapynotespdf(item.MeetingScheduleId,item.MeetingScheduleTimeId,item.PatientAccountId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdfpsytherapynotes = res.filePath;
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        },
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  OnDownloadInduvalAndFamily(item){
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
      this.clinicianservice.getIndFamProgressNotespdf(item.MeetingScheduleId,item.MeetingScheduleTimeId,item.PatientAccountId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdfFamIndNotes = res.filePath;
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        },
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  clickArrowForSusidalRisk()
  {
    if(this.isSusidalRiskDocument)
    {
      this.isSusidalRiskDocument = false;
    }else{
      this.isSusidalRiskDocument = true;
    }
   
  }
  OnDownloadSusidalRisk(item){
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
      this.treatmentservice.getSuicidepdf(item.suicideScreeningId,item.formTypeId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.pdfSuicdescreener = res.filePath;
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        },
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  onGeneratePDFIntake() {
    this.documentType = 7;
    this.getPatientInfoPDFFile();
  }
  getPatientInfoPDFFile() {
    this.spinnerservice.show();
    this.service.getPatientInfoPDFFile(this.patientAccountId, this.documentType).subscribe(
      (res: any) => {
        var blob = new Blob([res]);
        let saveAs = require('file-saver');
        let file = this.patientName + '.pdf';
        saveAs(blob, file);
        this.spinnerservice.hide();
        this.snackbar.success('PDF downloaded successfully')
        this.onPrintPDFFile();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onPrintPDFFile() {
    this.documentType = 7;
    this.service.getPrintPDFFile(this.patientAccountId, this.documentType).subscribe(
      (res: any) => {
        this.pdfPatientInformationIntake = res.filePath;
      },
      err => {
      },
    );
  }
  // getPatientInfoPDF() {
  //   this.spinnerservice.show();
  //   this.service.getPatientInfoPDF(this.patientAccountId).subscribe(
  //     (res: any) => {
  //       this.getPatientInfoPDFFile();
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //       this.snackbar.error(err.error);
  //     },
  //   );
  // }
  painAssesmentPdf(){
    this.spinnerservice.show();
    this.patientservice.getPainAssessmentPdf(this.patientAccountId,0).subscribe(
      (res: any) => {
        var result = res;
        this.pdfPatientInformationIntake = result.filePath
        this.downloadLink.nativeElement.href = result.base64File;
        this.downloadLink.nativeElement.download = result.fileName;
        this.downloadLink.nativeElement.click();
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  
  }
  onGenerateCHMAPDF() {
    this.spinnerservice.show();
    this.patientservice.getChmaPdf(this.patientAccountId).subscribe(
      (res: any) => {

        var result = res;
        this.pdfPatientInformationIntake = result.filePath
        this.downloadLink.nativeElement.href = result.base64File;
        this.downloadLink.nativeElement.download = result.fileName;
        this.downloadLink.nativeElement.click();
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getNutritionPdfFile() {
   
    this.spinnerservice.show();
    this.patientservice.getNutritionPdfFile(this.patientAccountId, 13,0,0).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfNutritionfile = res.filePath;
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  

}
OnPrintFinancialDocument()
{
  this.patientservice.getFinancialDocument(this.patientAccountId).subscribe(
    (res : any) =>{
      var finacialDocuments = this.commonService.decrypdata(res.objInputString);
      this.PrintFinancialDocuments(finacialDocuments);
    },
    err =>{
      this.snackbar.error(err.error);
    }
  )
}
ListFinancialDocuments(){
  this.patientservice.listFinancialDocuments(this.patientAccountId).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      //this.OutofPktPaymentPrintdata=[];
      var finacialDocuments = this.commonService.decrypdata(res.objInputString);
      
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
PrintFinancialDocuments(item){
  this.dtoPaymentActiveList = item.DtoPatientOutofPacketInfoDetailsActiveLists;
  const resultsumSession = this.dtoPaymentActiveList.reduce((accumulator, obj) => {
    return accumulator + obj.DueAmount;
  }, 0);
  this.totalpending=resultsumSession;
  this.dtoPatientOutofPacketInvoicePdf.dtoPatientOutofPacketInfoDetailsPdf=item.DtoPatientOutofPacketInfoDetailsCompletedLists;
  this.dtoPatientOutofPacketInvoicePdf.dtoPatientOutofPacketInfo=item.DtoPatientOutofPacketInfo[0];
 
  this.dtoPatientOutofPacketInvoicePdf.Totalpending=this.totalpending.toString();
  //const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoPatientOutofPacketInvoicePdf, false);

  this.patientservice.postOutofPktInvoicePdf(this.dtoPatientOutofPacketInvoicePdf).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      //this.OutofPktPaymentPrintdata=[];
      let res1=JSON.parse(res)
      this.pdfInvoice = res1.filePath;
      const linkSource = 'data:application/pdf;base64,'+res1.base64File+'';
      this.downloadLink.nativeElement.href = res1.base64File;
      this.downloadLink.nativeElement.download = res1.fileName;
      this.downloadLink.nativeElement.click();
      
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
GetFinancialProposal(){
  this.patientservice.getFinancialProposal(this.patientAccountId).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      //this.OutofPktPaymentPrintdata=[];
      this.financialProposalfile = res.filePath;
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
      
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
GetBenefitsAndAuthoization(){
  this.patientservice.GetBenefitsAndAuthorizationPdf(this.patientAccountId,0,0).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      //this.OutofPktPaymentPrintdata=[];
      this.financialProposalfile = res.filePath;
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
      
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
}

<div class="billing-section">
      <!-- <div class="col-md-12 billing-patient d-none "> -->
        <div class="col-md-12 billing-patient">
      <div class="col-md-12 p-0">
        <div class="d-flex w-100">
          <div class="col-md-3 p-0">
            <div  class="search">
                <input  type="text" placeholder="Search By Patient " name="txt"  [(ngModel)]="searchbillListtxt" class="search-input " ><a  class="search-icon"><i  class="fa fa-search"></i></a></div>
         <!--   <button style="color:#000;" (click)="postpaymentTest()">bridgepay</button>
                 <button style="color:#000;" (click)="getpaymentTest()">Getbridgepay</button> -->
              </div>
        <div class="col-md-5 mt-2"> 
        <!--filter-->
         <div  class="filter-group d-inline-block">
            <label  class="d-inline-block mr-2">clinician</label>
            <select class="form-control d-inline-block status-filter mr-0" [(ngModel)]="selectedClinician"  name="clinician"  (change)="getBListFilterby()">
              <option value="0">All</option>
              <option *ngFor="let a of ddlStafflist" value="{{a.ClinicianId}}">
                  {{a.Clinician}}
               </option>
            </select>
          </div>
          <div  class=" d-inline-block">
            <label  class="d-inline-block mr-2">Therapy</label>
            <select class="form-control d-inline-block status-filter mr-0"
                        [(ngModel)]="selectedTheraphy"  name="therapy"  (change)="getBListFilterby()">
                        <option value="0">All</option>
                        <option *ngFor="let i of theraphy" [value]="i.therapyId">{{i.therapyName}}</option> 
                 </select>
          </div>
        </div>
        <!--filter-->
        <div class="ml-auto">
        
          <!-- <button class="btn primary-btn-outlined float-right "  mat-stroked-button  [routerLink]="['/bill']"><i  class="fas fa-plus-circle mr-2" ></i>Create Billing</button> -->
          <button class="btn primary-btn-outlined float-right mr-2 " mat-stroked-button  [routerLink]="['/claims-lists']"><i class="far fa-list-alt mr-2"></i>Claims List</button>
        </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="badge badge-danger">{{paymentresponse}}</div>
       </div>
      <div class="col-md-12 p-0" >
        <div class=" ">
          <div class="new-bill-list bill-list ">
            <div class="col-md-12 p-2">
            <div class="row">
              <div class="col-md-2  ">
                <div class="d-flex flex-row text-muted text-xs  ">
                <div class="">Patient Id</div>
              </div>
              </div>
              <div class="col-md-2  ">
                <div class="d-flex flex-row text-muted text-xs  ">
                <div class="">Patient Name</div>
              </div>
              </div> 
              <div class="col-md-2 ">
                <div class="d-flex flex-row text-muted text-xs  ">
                <div class="">Clinician Name</div>
              </div>
              </div>
              <div class="col-md-3 ">
                <div class="d-flex flex-row text-muted text-xs ">
                <div class="">Therapy</div>
              </div>
                  
              </div>
              <div class="col-md-2">
                <div class="d-flex flex-row text-muted text-xs  ">
                <div class="">Treatment Date</div>
              </div>
            
              </div> 
              <!-- <div class="col-md-1">
                <div class="d-flex flex-row text-muted text-xs ">
                <div class="">Update Payment</div>
              </div> -->
                  
            </div>
          </div>
           <!-- Row Values-->
            <div class="col-md-12 p-2 card mb-1"  style="cursor: pointer;"  *ngFor="let dataitem of deCrptedBillLists| grdFilter:  searchbillListtxt:'BillLists';let i=index" (click)="onScheduleLink(dataitem)">
              <div class="row">
                <div class="col-md-2 align-self-center">
                  <h6 class="mb-0">{{dataitem.PatientAccountId}}</h6>
                </div> 
                <div class="col-md-2  align-self-center">
                  <h6 class="mb-0">{{dataitem.PatientName}}</h6>
                </div> 
                <div class="col-md-2 align-self-center">
                  <h6 class="mb-0">{{dataitem.Clinician}}</h6>
                </div>
                <div class="col-md-3 align-self-center">
                  <h6 class="mb-0">{{dataitem.ApointmenteName}}</h6>
                    
                </div>
                <div class="col-md-2 align-self-center">
                  <h6 class="mb-0">{{ dataitem.TherapyDate | date:'MM/dd/yyyy'}}</h6>
                    
                </div> 
                <!-- <div class="col-md-1">
                <button type="button" mat-icon-button matTooltip="Edit" (click)="onSelectTransale(dataitem)" color="primary">
                  <img src="../../../assets/images/editblue.png">
                </button>
              </div>  -->
              </div>
              </div>
              <!-- Row Values end-->
           </div> 
          <!---->
          <div class="bill-list" style="display:none;">
            <ul class="list list-inline mb-0">
              <li class=" card  p-2 d-flex justify-content-between w-100  mb-1">
               <!-- <table>
               <thead>
                 <tr>
                    <th class="bill-pat-name text-muted text-xs" style="text-align:left;font-weight:normal;">Patient Name</th>
                    <th class="bill-pat-name text-muted text-xs" style="text-align:left;font-weight:normal;">Clinician Name</th>
                    <th class="bill-pat-name text-muted text-xs" style="text-align:left;font-weight:normal;">Therapy</th>
                    <th class="bill-pat-name text-muted text-xs" style="font-weight:normal;">Treatment Date</th>
                    <th class="bill-pat-name text-muted text-xs" style="text-align:right;font-weight:normal;display:none;">Treatment Price</th>
                 </tr>
                 <tr style="height:5px;"></tr>
                </thead>
                 <tbody>
                     <ng-container *ngFor="let dataitem of deCrptedBillLists| grdFilter:  searchbillListtxt:'BillLists';let i=index">
                      <tr [ngClass]="{'hiddenrow':(dataitem.ApointmenteName=='Z-Total')}">
                        <td class="bill-pat-name"> <h6 class="mb-0" >{{i>0 && deCrptedBillLists[i-1].PatientName==dataitem.PatientName?'':dataitem.PatientName}}</h6></td>
                        <td> <h6 class="mb-1 list-label-pay">{{dataitem.Clinician}}</h6></td>
                        <td [ngClass]="{'highlighttotal':(dataitem.ApointmenteName=='Z-Total')}"><h6 class="mb-1 list-label-pay">{{dataitem.ApointmenteName=='Z-Total' ?'':dataitem.ApointmenteName}}</h6></td>
                        <td [ngClass]="{'highlighttotal':(dataitem.ApointmenteName=='Z-Total')}"><h6 class="mb-1 list-label-pay">{{dataitem.ApointmenteName=='Z-Total' ?'': dataitem.TherapyDate | date:'MM/dd/yyyy'}}</h6></td>
                        <td [ngClass]="{'highlighttotal':(dataitem.ApointmenteName=='Z-Total')}" style="text-align:right;display:none;">  <h6 class="mb-1 list-label-pay">{{dataitem.TreatmentCharge}}</h6></td>
                      </tr>
                      </ng-container>
                    </tbody>
                </table>  -->
              </li>
                <!-- <li class=" card  p-2 d-flex justify-content-between w-100  mb-1">
                    <div class="d-flex flex-row align-items-top w-100">
                     <div class="col-md-2  border-right">
                          <div class="d-flex flex-row  text-muted text-xs ">
                          <div class="bill-pat-name">Patient Name</div>
                        </div>
                            <h6 class="mb-0">Andrews G</h6>
                        </div>
                        <div class="col-md-2 border-right">
                          <div class="d-flex flex-row  text-muted text-xs ">
                          <div class="bill-pat-name">Clinician Name</div>
                        </div>
                            <h6 class="mb-0">Issac</h6>
                        </div>
                        <div class="col-md-6 border-right">
                          <div class="d-flex flex-row  text-muted text-xs ">
                          <div class="bill-pat-name">Therapy</div>
                        </div>
                            <h6 class="mb-0"><a href="#">Family Therapy-Louis F</a></h6>
                        </div>
                        <div class="col-md-2">
                          <div class="d-flex flex-row  text-muted text-xs ">
                          <div class="bill-pat-name">Treatment Date</div>
                        </div>
                            <h6 class="mb-0">04/15/2022</h6>
                        </div>
                        
                      
                      </div>
                      </li>
                      <li class=" card  p-2 d-flex justify-content-between w-100  mb-1">
                        <div class="d-flex flex-row align-items-top w-100">
                         <div class="col-md-2  border-right">
                              <div class="d-flex flex-row  text-muted text-xs ">
                              <div class="bill-pat-name">Patient Name</div>
                            </div>
                                <h6 class="mb-0">Peter</h6>
                            </div>
                            <div class="col-md-2 border-right">
                              <div class="d-flex flex-row  text-muted text-xs ">
                              <div class="bill-pat-name">Clinician Name</div>
                            </div>
                                <h6 class="mb-0">David</h6>
                            </div>
                            <div class="col-md-6 border-right">
                              <div class="d-flex flex-row  text-muted text-xs ">
                              <div class="bill-pat-name">Therapy</div>
                            </div>
                                <h6 class="mb-0"><a href="#">Individual Therapy-Phillips </a></h6>
                            </div>
                            <div class="col-md-2">
                              <div class="d-flex flex-row  text-muted text-xs ">
                              <div class="bill-pat-name">Treatment Date</div>
                            </div>
                                <h6 class="mb-0">04/14/2022</h6>
                            </div>
                            
                          
                          </div>
                          </li>
                          <li class=" card  p-2 d-flex justify-content-between w-100  mb-1">
                            <div class="d-flex flex-row align-items-top w-100">
                             <div class="col-md-2  border-right">
                                  <div class="d-flex flex-row  text-muted text-xs ">
                                  <div class="bill-pat-name">Patient Name</div>
                                </div>
                                    <h6 class="mb-0">Merlin</h6>
                                </div>
                                <div class="col-md-2 border-right">
                                  <div class="d-flex flex-row  text-muted text-xs ">
                                  <div class="bill-pat-name">Clinician Name</div>
                                </div>
                                    <h6 class="mb-0">Stephen</h6>
                                </div>
                                <div class="col-md-6 border-right">
                                  <div class="d-flex flex-row  text-muted text-xs ">
                                  <div class="bill-pat-name">Therapy</div>
                                </div>
                                    <h6 class="mb-0"><a href="#">Group Therapy-james </a></h6>
                                </div>
                                <div class="col-md-2">
                                  <div class="d-flex flex-row  text-muted text-xs ">
                                  <div class="bill-pat-name">Treatment Date</div>
                                </div>
                                    <h6 class="mb-0">04/13/2022</h6>
                                </div>
                                
                              
                              </div>
                              </li> -->
                      </ul>
          </div>
        </div>
              <!-- old-->
      <!--  <div class="col-md-12 table-custom p-0 ">
            <div class="table-responsive">
              <table class="table table-history">
                <thead>
                  <tr>
                    <th scope="col" >S.No</th>
                    <th scope="col" style="text-align:left;">Patient Name</th>
                    <th scope="col" style="text-align:left;">Clinician Name</th>
                    <th scope="col" style="text-align:left;">Therapy</th>
                    <th scope="col">Treatment Date</th>
                    <th scope="col" style="text-align:right;">Treatment Price</th>
                  </tr>
                </thead>
                <tbody>
                   <tr   *ngFor="let item of deCrptedBillLists | grdFilter:  searchbillListtxt:'BillLists'; let i=index;"
                   >
                    <td >{{i+1}}</td>
                    <td style="text-align:left;">{{item.PatientName}}</td>
                    <td style="text-align:left;">{{item.Clinician}}</td>
                    <td style="text-align:left;">{{item.ApointmenteName}}</td>
                    <td >{{item.TherapyDate | date:'MM/dd/yyyy'}}</td>
                    <td style="text-align:right;">{{item.TreatmentCharge}}</td>
                 </tr>
                </tbody>
              </table>
              </div>
          </div>    
      </div> -->
      <!--second-->
      <!-- <div class="col-md-12 p-0" >
        <div class="col-md-12 table-custom p-0 ">
            <div class="table-responsive">
              <table class="table table-history">
                <thead>
                  <tr>
                    <th scope="col" >S.No</th>
                    <th scope="col" style="text-align:left;">Patient Name</th>
                    <th scope="col" style="text-align:left;">Clinician Name</th>
                    <th scope="col" style="text-align:left;">Therapy</th>
                    <th scope="col">Treatment Date</th>
                    <th scope="col" style="text-align:right;">Treatment Price</th>
                  </tr>
                </thead>
                <tbody>
                   <tr   *ngFor="let item of deCrptedBillLists | grdFilter:  searchbillListtxt:'BillLists'; let i=index;"
                   [ngClass]="{'highlighttotal':(item.ApointmenteName=='Z-Total')}"
                   >
                    <td >{{i+1}}</td>
                    <td style="text-align:left;">{{item.PatientName}}</td>
                    <td style="text-align:left;">{{item.Clinician}}</td>
                    <td style="text-align:left;">{{item.ApointmenteName=='Z-Total' ?'Total': item.ApointmenteName}}</td>
                    <td >{{item.ApointmenteName=='Z-Total' ?'': item.TherapyDate | date:'MM/dd/yyyy'}}</td>
                    <td style="text-align:right;">{{item.TreatmentCharge}}</td>
              
                  </tr>

                  
                </tbody>
              </table>
              </div>
          </div>    
      </div> -->
      <!--second end-->
    </div>
    
    <!--Dummy pop up -->
<div class="comment-popup  re-schedule-popup">
  <div class="modal" tabindex="-1" role="dialog" [style.display]="isTranssale? 'block':'none'">
    <div class="modal-dialog mat-dialog-container " role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h6 class="modal-title"> Payment Transaction Sale</h6>
          <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OnCancelsale()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
<div >
  <form (ngSubmit)="paymentTransSale.form.valid && PostTransactionSale(paymentTransSale)" id="paymentTransSale"
  autocomplete="off" #paymentTransSale="ngForm" novalidate>
  <div class="col-md-12">
    <div class="row mb-2">
     <div class="col-md-7">
        <mat-form-field class="w-100">
          <mat-label>PaymentAccountNumber</mat-label>
           <input matInput  maxlength="20" numeric
            [(ngModel)]="dtoBridgePayTransactionSaleRequest.PaymentAccountNumber" #PaymentAccountNumber="ngModel" name="PaymentAccountNumber"
            [ngClass]="{ 'is-invalid': paymentTransSale.submitted && PaymentAccountNumber.invalid }"
            required>
            <mat-error *ngIf="paymentTransSale.submitted && PaymentAccountNumber.invalid">
              <mat-error *ngIf="PaymentAccountNumber.errors.required">PaymentAccountNumber Code  is required</mat-error>
            </mat-error>
        </mat-form-field>
   
    </div>
    <div class="col-md-5 mb-2">
      <mat-form-field class="w-100">
        <mat-label>Expiration Date</mat-label>
         <input matInput  maxlength="4" placeholder="MMYY"  numeric
          [(ngModel)]="dtoBridgePayTransactionSaleRequest.ExpirationDate" #ExpirationDate="ngModel" name="ExpirationDate"
          [ngClass]="{ 'is-invalid': paymentTransSale.submitted && ExpirationDate.invalid }"
          required>
          <mat-error *ngIf="paymentTransSale.submitted && ExpirationDate.invalid">
            <mat-error *ngIf="ExpirationDate.errors.required">Expiration Date is required</mat-error>
          </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6 mb-2">
      <mat-form-field class="w-100">
        <mat-label>Security Code</mat-label>
         <input matInput  maxlength="4" numeric
          [(ngModel)]="dtoBridgePayTransactionSaleRequest.SecurityCode" #SecurityCode="ngModel" name="SecurityCode"
          [ngClass]="{ 'is-invalid': paymentTransSale.submitted && SecurityCode.invalid }"
          required>
          <mat-error *ngIf="paymentTransSale.submitted && SecurityCode.invalid">
            <mat-error *ngIf="SecurityCode.errors.required">Security Code is required</mat-error>
          </mat-error>
      </mat-form-field>
    </div>
    <div class="col-md-6 mb-2">
      <mat-form-field class="w-100">
        <mat-label>Amount</mat-label>
         <input matInput  maxlength="5" numeric
          [(ngModel)]="dtoBridgePayTransactionSaleRequest.Amount" #Amount="ngModel" name="Amount"
          [ngClass]="{ 'is-invalid': paymentTransSale.submitted && Amount.invalid }"
          required>
          <mat-error *ngIf="paymentTransSale.submitted && Amount.invalid">
            <mat-error *ngIf="Amount.errors.required">Amount  is required</mat-error>
          </mat-error>
      </mat-form-field>
    </div>
   <!-- <div class="col-md-12">
    <div class="badge badge-danger">{{paymentresponse}}</div>
   </div> -->
  <div class="text-right col-md-12 mt-2">
    <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2"  >Submit</button>
    <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="OnCancelsale()" >Cancel</button>
  </div>

  <div>
    

  </div>

</div>
</div>
</form>
</div>


</div>
</div>
</div>
</div>
</div>
<!--Dummy pop up end-->
<div class="incident-report">
    <div  class="d-flex"><div  class="head-section mt-2 mb-2"><i class="fa fa-exclamation-triangle mr-2"></i>Report An Incident</div><div  class="ml-auto"><button  class="btn back-btn-new mr-2" tabindex="0" (click)="OnBack()" ><i  class="fas fa-chevron-left mr-2"></i>Back</button></div></div>
        <div class="col-md-12 bg-white p-2" >
          <form (ngSubmit)="PostIncReportfrm()" id="IncReportfrm" autocomplete="off" #IncReportfrm="ngForm"
          novalidate [ngClass]="{'disabled': status==3 }">
          <div class="col-md-12">
          <div class="row">
            <div class="col-md-4" [ngClass]="{'disabled': IsEdit }">
              <mat-form-field class="w-100">
                <mat-label>Incident Type</mat-label>
                <!-- <mat-select [(ngModel)]="IncReportfrmInfo.IncidentReportTypeId" #IncidentReportTypeId="ngModel" name="IncidentReportTypeId"
                [ngClass]="{ 'is-invalid': IncReportfrm.submitted && IncidentReportTypeId.invalid }"   required  (selectionChange)="OnTypechange()"> -->
                <mat-select [(ngModel)]="IncReportfrmInfo.IncidentReportTypeId" #IncidentReportTypeId="ngModel" name="IncidentReportTypeId"
                [ngClass]="{ 'is-invalid': IncReportfrm.submitted && IncidentReportTypeId.invalid }"   required >
                  <mat-option #matOption *ngFor="let item of ddlIncidenType" [value]="item.IncidentReportTypeId">
                    {{item.IncidentReportType1}}
                </mat-option>
                  
                </mat-select>
              </mat-form-field>
              <mat-error class="mat-form-field-subscript-wrapper" *ngIf="!IncReportfrmInfo.IncidentReportTypeId && isSaveasIncidentTypeName">
                Incident Type is required</mat-error>
          </div>
            <div class="col-md-4">
             
             <ng-select appAlphabetOnly autocomplete="off" [items]="ddlpatientList" placeholder="Client Name *"
              bindLabel="patientName" bindValue="patientInfoId"
             [(ngModel)]="IncReportfrmInfo.PatientInfoId" addTagText="Add item"
               name="PatientInfoId" #PatientInfoId="ngModel" required
               [ngClass]="{ 'invalid': IncReportfrm.submitted && PatientInfoId.invalid }"
               (change)="onPatientchange($event)" >
            </ng-select>
            <mat-error *ngIf="IncReportfrm.submitted && PatientInfoId.invalid">
              <mat-error class="mat-form-field-subscript-wrapper" *ngIf="PatientInfoId.errors.required">
                Client Name is required</mat-error>
          </mat-error>
          <mat-error class="mat-form-field-subscript-wrapper" *ngIf="!IncReportfrmInfo.PatientInfoId && isSaveasClientName">
            Client Name is required</mat-error>
           <!-- <mat-form-field class="example-full-width w-100 ">
               <input  matInput type="text" placeholder="Search Patient Name" [(ngModel)]="PatientName"  >
               <ul id="myUL" *ngIf="PatientName">
                <li *ngFor="let item of ddlpatientList | grdFilter: PatientName:'IncidentReport'">
                  <a (click)="onSelectedPatient(item)">{{item.patientName}} </a></li>
              </ul> 
             <input matInput placeholder="Search Patient Name" type="text"/>-->
               <!-- <button type="button" mat-button matSuffix mat-icon-button aria-label="search">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>-->
            </div>
            <div class="col-md-4">
              <ng-select appAlphabetOnly placeholder="Reporting Clinician Name *" [items]="ddlstafflist" bindLabel="staffName" 
              bindValue="userId"   [(ngModel)]="IncReportfrmInfo.ClinicianId" addTagText="Add item"
              name="ClinicianId" #ClinicianId="ngModel" required
             [ngClass]="{ 'invalid': IncReportfrm.submitted && ClinicianId.invalid }" >
            </ng-select>
           
            <mat-error *ngIf="IncReportfrm.submitted && ClinicianId.invalid">
              <mat-error class="mat-form-field-subscript-wrapper mt-1" *ngIf="ClinicianId.errors.required">
                  Clinician name is required</mat-error>
          </mat-error>
        
           <!-- <mat-form-field class="example-full-width w-100 ">
                <input matInput placeholder="Search Clinician Name" type="text"/>
                <button type="button" mat-button matSuffix mat-icon-button aria-label="search">
                  <mat-icon>search</mat-icon>
                </button>
              </mat-form-field>-->
            </div>
            
          </div>
          <div class="behave-incident " *ngIf="IncReportfrmInfo.IncidentReportTypeId==2" >
          <div class="row mb-2">
            <div class="col-md-3">
              <mat-form-field class="example-full-width w-100" >
                <mat-label [ngClass]="{ 'errors text-xs': IncReportfrm.submitted && TheraphyId.invalid }">Therapy Type</mat-label>
                <mat-select    [(ngModel)]="IncReportfrmInfo.TheraphyId" #TheraphyId="ngModel" name="TheraphyId"
                [ngClass]="{ 'is-invalid': IncReportfrm.submitted && TheraphyId.invalid }" required >                                               
                    <mat-option #matOption *ngFor="let item of ddlTherapy" [value]="item.TherapyId" >
                        {{item.TherapyName}}
                    </mat-option>
                    <mat-option [value]="0" >
                      Others
                  </mat-option>
                   <!-- <mat-option #matOption  [value]="0" >
                      Others
                  </mat-option>-->
                </mat-select>

              <mat-error *ngIf="IncReportfrm.submitted && TheraphyId.invalid">
                <mat-error *ngIf="TheraphyId.errors.required">Therapy is required
                </mat-error>
            </mat-error>
               
            </mat-form-field>
            
          
             <!-- <mat-form-field class="example-full-width w-100 ">
                <mat-label>Theraphy/Program</mat-label>
                <input matInput>
              </mat-form-field>-->
            </div>
            <div class="col-md-2" *ngIf="IncReportfrmInfo.TheraphyId==0">
              <!-- <span  *ngIf="IncReportfrmInfo.TheraphyId==0" class="treat-label ml-2"> If Others,</span> -->
              <span *ngIf="IncReportfrmInfo.TheraphyId==0" > 
                <mat-form-field class="example-full-width ml-2 w-100" >
                <mat-label> If Others,</mat-label>
                <input matInput  [(ngModel)]="IncReportfrmInfo.OtherTheraphyName"  maxlength="100"
                #OtherTheraphyName="ngModel" name="OtherTheraphyName" [ngClass]="{ 'is-invalid': IncReportfrm.submitted && OtherTheraphyName.invalid }"
                required>
                <mat-error *ngIf="IncReportfrm.submitted && OtherTheraphyName.invalid">
                  <mat-error  *ngIf="OtherTheraphyName.errors.required">
                      Therapy name is required</mat-error>
              </mat-error>
              </mat-form-field>
            
            </span>
            </div>
           
            <div class="col-md-2">
              <!--<ng-select appAlphabetOnly placeholder="Reported By" [items]="ddlReportedBy" bindLabel="staffName" 
              bindValue="userId"   [(ngModel)]="IncReportfrmInfo.ReportedBy" addTagText="Add item"
              name="ReportedBy" #ReportedBy="ngModel" required
             [ngClass]="{ 'invalid': IncReportfrm.submitted && ReportedBy.invalid }">
            </ng-select>
            <mat-error *ngIf="IncReportfrm.submitted && ReportedBy.invalid">
              <mat-error class="mat-form-field-subscript-wrapper" *ngIf="ReportedBy.errors.required">
                  Reported by is required</mat-error>
          </mat-error>-->
          <mat-form-field class="w-100">
            <mat-label> Program </mat-label>
            <mat-select  [(ngModel)]="IncReportfrmInfo.Program" #Program="ngModel" name="Program"   [ngClass]="{ 'is-invalid': IncReportfrm.submitted && Program.invalid }" required   >
              <mat-option *ngFor="let typ of ddlProgramType" [value]="typ.programTypesId">{{typ.programName}}</mat-option>              
            </mat-select>
            <mat-error *ngIf="IncReportfrm.submitted && Program.invalid">
              <mat-error  *ngIf="Program.errors.required">
                  Program is required</mat-error>
          </mat-error>
          </mat-form-field>
            </div>
            <!--<div class="col" >
              <mat-form-field class="example-full-width w-100 ">
                <mat-label>Address</mat-label>
                <input matInput placeholder="Address" 
                [(ngModel)]="IncReportfrmInfo.Address" #Address="ngModel" name="Address" maxlength="100"
                [ngClass]="{ 'is-invalid': IncReportfrm.submitted && Address.invalid }"
                required>
                <mat-error *ngIf="IncReportfrm.submitted && Address.invalid">
                  <mat-error  *ngIf="Address.errors.required">
                      Address is required</mat-error>
              </mat-error>
              </mat-form-field>
            </div>-->
           
          </div>
          
          <div class="row mb-2">
            <div class="col-md-3">
              <mat-form-field class="example-full-width w-100 ">
                <mat-label>Title</mat-label>
                <input matInput  [(ngModel)]="IncReportfrmInfo.Title" #Title="ngModel"  maxlength="200" name="Title"    [ngClass]="{ 'is-invalid': IncReportfrm.submitted && Title.invalid }" required >
                <mat-error *ngIf="IncReportfrm.submitted && Title.invalid">
                  <mat-error  *ngIf="Title.errors.required">
                      Title is required</mat-error>
              </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="example-full-width w-100 ">
                <mat-label>Incident Date</mat-label>
                <input matInput [matDatepicker]="picker"
                [(ngModel)]="IncReportfrmInfo.IncidentDate" #IncidentDate="ngModel" [max]="today" name="IncidentDate"
                [ngClass]="{ 'is-invalid': IncReportfrm.submitted && IncidentDate.invalid }" (dateChange)="timePickerDisableTime()"
                required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="IncReportfrm.submitted && IncidentDate.invalid">
                  <mat-error  *ngIf="IncidentDate.errors.required">
                      Incident Date  is required</mat-error>
              </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="example-full-width w-100 ">
                <mat-label>Incident Time</mat-label>
                <input matInput type="time" [(ngModel)]="IncReportfrmInfo.IncidentTime" #IncidentTime="ngModel" name="IncidentTime" [max]="currentTime"
                [ngClass]="{ 'is-invalid': IncReportfrm.submitted && IncidentTime.invalid }" (focusout)="check()"
                required>
                <mat-error *ngIf="IncReportfrm.submitted && IncidentTime.invalid">
                  <mat-error  *ngIf="IncidentTime.errors.required">
                      Incident Time  is required</mat-error>
              </mat-error>
              </mat-form-field>
                <div class="error-message mt--14" *ngIf="IncReportfrm.submitted && showErrorMessageForBehaviour">
                  {{errorMessageForBehaviour}}
                </div>
            </div>
            <div class="col-md-5">
              <mat-form-field class="example-full-width w-100 ">
                <mat-label>Incident Location</mat-label>
                <input matInput  [(ngModel)]="IncReportfrmInfo.IncidentLocation" #IncidentLocation="ngModel"  maxlength="100" name="IncidentLocation"
                [ngClass]="{ 'is-invalid': IncReportfrm.submitted && IncidentLocation.invalid }"
                required >
                <mat-error *ngIf="IncReportfrm.submitted && IncidentLocation.invalid">
                  <mat-error *ngIf="IncidentLocation.errors.required">
                      Incident Location  is required</mat-error>
              </mat-error>
              </mat-form-field>
            </div>
          </div>
         
          <div class="row">
            <div class="col-md-12 form-group">
            <div  class="treat-label">All Staff members involved</div>
            <mat-form-field class="w-100 custom-textarea mt-2">
              <textarea matInput  [(ngModel)]="IncReportfrmInfo.StaffMember" #StaffMember="ngModel" name="StaffMember" row="3" maxlength="200"
              [ngClass]="{ 'is-invalid': IncReportfrm.submitted && StaffMember.invalid }" required  ></textarea>
              <mat-error *ngIf="IncReportfrm.submitted && StaffMember.invalid">
                <mat-error  *ngIf="StaffMember.errors.required">
                  Staff members involved  is required</mat-error>
            </mat-error>
            </mat-form-field></div>
            <div class="col-md-12 form-group">
              <div  class="treat-label">Describe behavior before incident started</div>
              <mat-form-field class="w-100 custom-textarea mt-2">
                <textarea matInput   [(ngModel)]="IncReportfrmInfo.BehaviorBefore" #BehaviorBefore="ngModel" name="BehaviorBefore" row="3" maxlength="500"
                [ngClass]="{ 'is-invalid': IncReportfrm.submitted && BehaviorBefore.invalid }" required ></textarea>
                <mat-error *ngIf="IncReportfrm.submitted && BehaviorBefore.invalid">
                  <mat-error  *ngIf="BehaviorBefore.errors.required">
                   This field is required</mat-error>
              </mat-error>
              </mat-form-field></div>
              <div class="col-md-12 form-group">
                <div  class="treat-label">Describe behavior during incident (including response to nonphysical interventions)</div>
                <mat-form-field class="w-100 custom-textarea mt-2">
                  <textarea matInput  [(ngModel)]="IncReportfrmInfo.BehaviorAfter" #BehaviorAfter="ngModel" name="BehaviorAfter" row="3" maxlength="500"
                  [ngClass]="{ 'is-invalid': IncReportfrm.submitted && BehaviorAfter.invalid }" required ></textarea>
                  <mat-error *ngIf="IncReportfrm.submitted && BehaviorAfter.invalid">
                    <mat-error  *ngIf="BehaviorAfter.errors.required">
                     This field is required</mat-error>
                </mat-error>
                </mat-form-field></div>
                <div class="col-md-12 form-group">
                  <div  class="treat-label">Describe how you responded to behavior</div>
                  <mat-form-field class="w-100 custom-textarea mt-2">
                    <textarea matInput [(ngModel)]="IncReportfrmInfo.BehaviorResponse" #BehaviorResponse="ngModel" name="BehaviorResponse" row="3" maxlength="500"
                    [ngClass]="{ 'is-invalid': IncReportfrm.submitted && BehaviorResponse.invalid }" required  ></textarea>
                    <mat-error *ngIf="IncReportfrm.submitted && BehaviorResponse.invalid">
                      <mat-error  *ngIf="BehaviorResponse.errors.required">
                       This field is required</mat-error>
                  </mat-error>
                  </mat-form-field></div>
                  <div class="col-md-12 form-group">
                    <div  class="treat-label">Was the client informed of criteria for discontinuing restraint? 
                      <span class="custom-radio ml-2 align-text-top">
                      <mat-radio-group aria-label="Select an option" [(ngModel)]="IncReportfrmInfo.IsDiscontinuingRestraint"
                      #IsDiscontinuingRestraint="ngModel" name="IsDiscontinuingRestraint"  [ngClass]="{ 'is-invalid': IncReportfrm.submitted && IsDiscontinuingRestraint.invalid }" required>
                        <mat-radio-button value=true (click)="onIsDiscontinuingRestraint(0)" class="mr-2">Yes</mat-radio-button>
                        <mat-radio-button value=false (click)="onIsDiscontinuingRestraint(1)">No</mat-radio-button>
                      </mat-radio-group>
                      <div *ngIf="IncReportfrm.submitted && IsDiscontinuingRestraint.invalid">
                        <div class="error-message"  *ngIf="IsDiscontinuingRestraint.errors.required">
                         This field is required</div>
                      </div>
                    </span></div>
                    
                   </div>
                   <div class="col-md-12 form-group">
                    <div  class="treat-label">Describe the behavioral criteria for discontinuing restraint</div>
                    <mat-form-field class="w-100 custom-textarea mt-2">
                      <textarea matInput  [(ngModel)]="IncReportfrmInfo.DiscontinuingRestraint" #DiscontinuingRestraint="ngModel" name="DiscontinuingRestraint" row="3" maxlength="500"
                      [ngClass]="{ 'is-invalid': IncReportfrm.submitted && DiscontinuingRestraint.invalid }" required
                      ></textarea>
                      <mat-error *ngIf="IncReportfrm.submitted && DiscontinuingRestraint.invalid">
                        <mat-error  *ngIf="DiscontinuingRestraint.errors.required">
                         This field is required</mat-error>
                    </mat-error>
                    </mat-form-field></div>
                   <div class="col-md-12 form-group">
                    <div  class="treat-label">Support Person Called? <span class="custom-radio ml-2 align-text-top">
                      <mat-radio-group aria-label="Select an option" [(ngModel)]="IncReportfrmInfo.IsCalled"
                      #IsCalled="ngModel" name="IsCalled" [ngClass]="{ 'is-invalid': IncReportfrm.submitted && IsCalled.invalid }" required>
                        <mat-radio-button value='1' class="mr-2" (click)="onIsCalled(0)">Yes</mat-radio-button>
                        <mat-radio-button value='2' (click)="onIsCalled(1)">No</mat-radio-button>
                      </mat-radio-group>
                      <div *ngIf="IncReportfrm.submitted && IsCalled.invalid">
                        <div class="error-message"  *ngIf="IsCalled.errors.required">
                         This field is required</div>
                        </div>
                    </span>
                   
                    <span  *ngIf="IncReportfrmInfo.IsCalled==1" class="treat-label ml-2"> If Yes,</span>
                    <span *ngIf="IncReportfrmInfo.IsCalled==1" > 
                      <mat-form-field class="example-full-width ml-2 w-25" >
                      <mat-label>Name</mat-label>
                      <input matInput  [(ngModel)]="IncReportfrmInfo.SupportPerson" maxlength="100"
                      #SupportPerson="ngModel" name="SupportPerson" [ngClass]="{ 'is-invalid': IncReportfrm.submitted && SupportPerson.invalid }"
                      required>
                      <mat-error *ngIf="IncReportfrm.submitted && SupportPerson.invalid">
                        <mat-error  *ngIf="SupportPerson.errors.required">
                            Support person name is required</mat-error>
                    </mat-error>
                    </mat-form-field>
                  
                  </span>
              
              </div>
                    
                   </div>
                   <div class="col-md-12 form-group">
                    <div  class="treat-label">Was anyone injured? <span class="custom-radio ml-2 align-text-top">
                      <mat-radio-group aria-label="Select an option" [(ngModel)]="IncReportfrmInfo.IsInjured"
                      #IsInjured="ngModel" name="IsInjured" [ngClass]="{ 'is-invalid': IncReportfrm.submitted && IsInjured.invalid }"
                      required>
                        <mat-radio-button value='1' (click)="OnIsInjured(0)" class="mr-2">Yes</mat-radio-button>
                        <mat-radio-button value='2'  (click)="OnIsInjured(1)" >No</mat-radio-button>
                      </mat-radio-group>
                      <div *ngIf="IncReportfrm.submitted && IsInjured.invalid">
                        <div class="error-message"  *ngIf="IsInjured.errors.required">
                            This field is required</div>
                        </div>
                    </span>
                    <span  *ngIf="IncReportfrmInfo.IsInjured==1" class="treat-label ml-3"> If Yes,</span>
                    <span  *ngIf="IncReportfrmInfo.IsInjured==1"> <mat-form-field class="example-full-width ml-3 w-25">
                      <mat-label>Name</mat-label>
                      <input matInput  matInput  [(ngModel)]="IncReportfrmInfo.InjuredPerson"  maxlength="100"
                      #InjuredPerson="ngModel" name="InjuredPerson" [ngClass]="{ 'is-invalid': IncReportfrm.submitted && InjuredPerson.invalid }"
                      required>
                      <mat-error *ngIf="IncReportfrm.submitted && InjuredPerson.invalid">
                        <mat-error  *ngIf="InjuredPerson.errors.required">
                            Injured person name is required</mat-error>
                    </mat-error>
                    </mat-form-field>
                  </span>
                </div>
                    
                   </div>
                   <div class="col-md-12 form-group">
                    <mat-form-field class="example-full-width w-25 ">
                      <mat-label>Witness to incident</mat-label>
                      <input matInput  [(ngModel)]="IncReportfrmInfo.Witness" maxlength="100"
                      #Witness="ngModel" name="Witness"  [ngClass]="{ 'is-invalid': IncReportfrm.submitted && Witness.invalid }"
                      required>
                      <mat-error *ngIf="IncReportfrm.submitted && Witness.invalid">
                        <mat-error  *ngIf="Witness.errors.required">
                          Witness is required</mat-error>
                    </mat-error>
                    </mat-form-field>
                   </div>
                   <div class="col-md-12 form-group">
                    <div  class="treat-label"> Describe any interventions attempted</div>
                    <mat-form-field class="w-100 custom-textarea mt-2"> 
                      <textarea matInput [(ngModel)]="IncReportfrmInfo.AttemptedInterventions" row="3" maxlength="500"
                      #AttemptedInterventions="ngModel" name="AttemptedInterventions"  [ngClass]="{ 'is-invalid': IncReportfrm.submitted && AttemptedInterventions.invalid }"
                      required></textarea>
                      <mat-error *ngIf="IncReportfrm.submitted && AttemptedInterventions.invalid">
                        <mat-error  *ngIf="AttemptedInterventions.errors.required">
                          This field is required</mat-error>
                    </mat-error>
                    </mat-form-field>
                   </div>

          </div>
          <div class="row">
            <div class="col-md-12 restraint-info">
        <h2>Restraint Information</h2>
        <div class="col-md-12 form-group p-0">
          <div  class="treat-label">Describe behavior that made restraint necessary</div>
          <mat-form-field class="w-100 custom-textarea mt-2">
            <textarea matInput [(ngModel)]="IncReportfrmInfo.RestraintDescribtion" row="3" maxlength="500"
            #RestraintDescribtion="ngModel" name="RestraintDescribtion" [ngClass]="{ 'is-invalid': IncReportfrm.submitted && RestraintDescribtion.invalid }"
            required></textarea>
            <mat-error *ngIf="IncReportfrm.submitted && RestraintDescribtion.invalid">
              <mat-error  *ngIf="RestraintDescribtion.errors.required">
                This field is required</mat-error>
          </mat-error>
          </mat-form-field>
         </div>
         <!-- <div class="col-md-12 p-0">
           <div class="row">
             <div class="col-md-4">
              <mat-form-field class="example-full-width w-100 ">
                <mat-label>Condition of client upon release</mat-label>
                <input matInput  [(ngModel)]="IncReportfrmInfo.ReleaseCondition" maxlength="300"
                #ReleaseCondition="ngModel" name="ReleaseCondition">
              </mat-form-field>
             </div>
             <div class="col-md-4">
              <mat-form-field class="example-full-width w-100 ">
                <mat-label>Staff Applying Restraint</mat-label>
                <input matInput [(ngModel)]="IncReportfrmInfo.Staff" maxlength="300"
                #Staff="ngModel" name="Staff">
              </mat-form-field>
             </div>
           </div>
         </div> -->
         <div class="col-md-12 form-group p-0">
          <div class="row">
            <div class="col-md-6">
          <mat-form-field class="w-100">
            <mat-label>Types of Restraint</mat-label>
            <mat-select multiple  [(ngModel)]="IncReportfrmInfo.RestraintTypeId" #RestraintTypeId="ngModel" name="RestraintTypeId" 
            (selectionChange)="onRestraintTypeIdChange($event.value)"  [ngClass]="{ 'is-invalid': IncReportfrm.submitted && RestraintTypeId.invalid }"
            required>
              <mat-option [value]="1" #matOption  *ngIf="!SelectAllRestraintType">All(Except Others)</mat-option>
              <mat-option  #matOption  *ngIf="SelectAllRestraintType"
              [value]="-1" > UnSelect All
               </mat-option>
              <mat-option *ngFor="let typ of ddlrestraintType" [value]="typ.restraintTypeId">{{typ.restraintName}}</mat-option>
              <mat-option [value]="6" #matOption>Other</mat-option>
            </mat-select>
            <mat-error *ngIf="IncReportfrm.submitted && RestraintTypeId.invalid">
              <mat-error  *ngIf="RestraintTypeId.errors.required">
                Types of Restraint is required</mat-error>
          </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="OtherRestraintType">
          <mat-form-field class=" mt--14 w-100">
            <textarea matInput placeholder="If other, please describe" maxlength="500" row="3"  [(ngModel)]="IncReportfrmInfo.DescribeRestraint" #DescribeRestraint="ngModel" name="DescribeRestraint"
            [ngClass]="{ 'is-invalid': IncReportfrm.submitted && DescribeRestraint.invalid }"
            required></textarea>
            <mat-error *ngIf="IncReportfrm.submitted && DescribeRestraint.invalid">
              <mat-error  *ngIf="DescribeRestraint.errors.required">
                This field is required</mat-error>
          </mat-error>
          </mat-form-field>
         
        </div>
        <div class="col-md-6">
          <mat-form-field class="w-100">
            <mat-label>Types of CPI Physical Intervention</mat-label>
            <mat-select multiple   [(ngModel)]="IncReportfrmInfo.CpiRestraintTypeId" #CpiRestraintTypeId="ngModel" name="CpiRestraintTypeId"
            (selectionChange)="onCpiRestraintTypeIdChange($event.value)"  [ngClass]="{ 'is-invalid': IncReportfrm.submitted && CpiRestraintTypeId.invalid }"
            required>
            <mat-option [value]="1" #matOption  *ngIf="!SelectAllCpiRestraintType">All</mat-option>
            <mat-option  #matOption  *ngIf="SelectAllCpiRestraintType"
            [value]="-1" > UnSelect All
             </mat-option>
              <mat-option *ngFor="let cpityp of ddlcpiRestraintType" [value]="cpityp.cpirestraintTypeId">{{cpityp.cpirestraintName}}</mat-option>
              
            </mat-select>
            <mat-error *ngIf="IncReportfrm.submitted && CpiRestraintTypeId.invalid">
              <mat-error  *ngIf="CpiRestraintTypeId.errors.required">
                This field is required</mat-error>
          </mat-error>
          </mat-form-field>
        </div>
        </div>
         </div>
        
         <div class="col-md-12 form-group p-0">
          <label  class="additional mt-3 mb-4">Include the following actions on the timeline above: Each episode of restraint & release, Length of restraint, Time restraint ended, Each 15 minute assessment of the status of the client, Provisions of water</label>
         </div>
         <div class="col-md-12 form-group p-0">
          <div class="row">
          
        <div class="col-md-3">
          <mat-form-field class="w-100">
            <mat-label>Restraint Timeline</mat-label>
            <mat-select  [(ngModel)]="IncReportfrmInfo.RTimeline" #RTimeline="ngModel" name="RTimeline"
            (selectionChange)="OnRtimechange($event.value)"   [ngClass]="{ 'is-invalid': IncReportfrm.submitted && RTimeline.invalid }"
            required >
              <mat-option *ngFor="let time of ddlrestraintTimeMaster" [value]="time.restraintTimelineId"   >{{time.timeline}}</mat-option>
             
            </mat-select>
            <mat-error *ngIf="IncReportfrm.submitted && RTimeline.invalid">
              <mat-error  *ngIf="RTimeline.errors.required">
                Restraint Timeline is required</mat-error>
          </mat-error>
          </mat-form-field>
          <!-- <mat-form-field class="  w-100">
            <input matInput placeholder="Restraint Timeline" type="time"   [(ngModel)]="IncReportfrmInfo.Rtime" #Rtime="ngModel" name="Rtime"/>
          </mat-form-field> -->
         
        </div>
        </div>
         </div>
         <div class="col-md-12 form-group p-0">
          <div class="row">
          <div class="col-md-3">
            <mat-form-field class="example-full-width w-100 ">
              <mat-label>Staff Applying Restraint</mat-label>
              <input matInput [(ngModel)]="IncReportfrmInfo.Staff" maxlength="300"
              #Staff="ngModel" name="Staff"  [ngClass]="{ 'is-invalid': IncReportfrm.submitted && Staff.invalid }"
              required>
              <mat-error *ngIf="IncReportfrm.submitted && RTimeline.invalid">
                <mat-error  *ngIf="RTimeline.errors.required">
                  Staff Applying Restraint is required</mat-error>
            </mat-error>
            </mat-form-field>
           </div>
           </div>
         </div>
         <div class="loop-rest" *ngFor="let item of IncReportfrmInfo.RestraintConditions; let i=index;">
         <div class="col-md-12 form-group p-0">
          <div class="row">
            
            <div class="col-md-12 form-group ">
              <div  class="treat-label">Was a restraint administered?
                <span class="custom-radio ml-2 align-text-top">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="item.RestraintAdminstrated"
                name="Restraint{{i}}" #RestraintAdminstrated ="ngModel"
                required >
                  <mat-radio-button value="1" class="mr-2">Yes</mat-radio-button>
                  <mat-radio-button value="0">No</mat-radio-button>
                </mat-radio-group>
                <div class="error-message" *ngIf="IncReportfrm.submitted && RestraintAdminstrated.invalid">
                  <div  *ngIf="RestraintAdminstrated.errors.required">
                    Was a restraint administered is required</div>
                  </div>
              </span></div>
              
             </div>
             <div class="col-md-12 form-group " *ngIf="item.RestraintAdminstrated == 1">
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field class="example-full-width w-100 ">
                    <mat-label>Length of Restraint</mat-label>
                    <input matInput   maxlength="300" [(ngModel)]="item.RestraintLength" #RestraintLength="ngModel" name="RestraintLength{{i}}"
                    [ngClass]="{ 'is-invalid': IncReportfrm.submitted && RestraintLength.invalid }"
                  required>
                  <mat-error *ngIf="IncReportfrm.submitted && RestraintLength.invalid">
                    <mat-error  *ngIf="RestraintLength.errors.required">
                      Length of Restraint is required</mat-error>
                </mat-error>
                  </mat-form-field>
                 </div>
                 <div class="col-md-3">
                  <mat-form-field class="example-full-width w-100 ">
                      <input matInput placeholder="End time of Restraint" type="time"   [(ngModel)]="item.EndTime" #EndTime="ngModel" name="EndTime{{i}}" 
                      [ngClass]="{ 'is-invalid': IncReportfrm.submitted && EndTime.invalid }"
                  required/>
                    
                  </mat-form-field>
                  <mat-error *ngIf="IncReportfrm.submitted && EndTime.invalid">
                    <mat-error  *ngIf="EndTime.errors.required">
                      End time of Restraint is required</mat-error>
                </mat-error>
                 </div>
              </div>
             </div>
             <div class="col-md-12 form-group">
              <div  class="treat-label">Provision of water?
                <span class="custom-radio ml-2 align-text-top">
                <mat-radio-group aria-label="Select an option" #ProvisionOfWater="ngModel" 
                name="RestraintWater{{i}}" [(ngModel)]="item.ProvisionOfWater"
                required >
                  <mat-radio-button value="1"  class="mr-2"  >Yes</mat-radio-button>
                  <mat-radio-button value="0"  >No</mat-radio-button>
                </mat-radio-group>
                <div class="error-message" *ngIf="IncReportfrm.submitted && ProvisionOfWater.invalid">
                  <div  *ngIf="ProvisionOfWater.errors.required">
                    Provision of water is required</div>
                  </div>
              </span></div>
              
             </div>
          </div>
        </div> 
        <div class="col-md-12 form-group p-0">
          <div class="treat-label">Assessment of the status of the client</div>
          <mat-form-field class="w-100 custom-textarea mt-2">
            <textarea class="mb-0" matInput  maxlength="800" 
             row="3" 
             [(ngModel)]="item.AssessmentStatus" #AssessmentStatus = "ngModel" name="AssessmentStatus{{i}}"
            required></textarea>
            <mat-error *ngIf="IncReportfrm.submitted && AssessmentStatus.invalid">
              <mat-error  *ngIf="AssessmentStatus.errors.required">
                Assessment of the status of the client is required</mat-error>
          </mat-error>
          </mat-form-field>
         
         </div>
         </div>
         <div class="col-md-12 form-group p-0">
          <div  class="treat-label">Condition of client upon release</div>
          <mat-form-field class="w-100 custom-textarea mt-2">
             <textarea class="mb-0" matInput [(ngModel)]="IncReportfrmInfo.ConditionOnRelease" maxlength="800"
             #ConditionOnRelease="ngModel" name="ConditionOnRelease" row="3" 
             [ngClass]="{ 'is-invalid': IncReportfrm.submitted && ConditionOnRelease.invalid }"
             required></textarea>
           <mat-error *ngIf="IncReportfrm.submitted && ConditionOnRelease.invalid">
             <mat-error  *ngIf="ConditionOnRelease.errors.required">
               Condition of client upon release is required</mat-error>
         </mat-error>
          </mat-form-field>
         
         </div>
         <!-- <div class="col-md-12 p-0">
          <div  class="treat-label">Condition of client upon release</div>
          <mat-form-field class="w-100 custom-textarea mt-2">
            <textarea class="mb-0" matInput [(ngModel)]="item.ReleaseCondition" maxlength="800"
            #ReleaseCondition="ngModel" name="ReleaseCondition{{i}}" row="3" 
            [ngClass]="{ 'is-invalid': IncReportfrm.submitted && ReleaseCondition.invalid }"
            required></textarea>
          </mat-form-field>
          <mat-error *ngIf="IncReportfrm.submitted && ReleaseCondition.invalid">
            <mat-error  *ngIf="ReleaseCondition.errors.required">
              Condition of client upon release is required</mat-error>
        </mat-error>
         </div> -->
         <div class="col-md-12 form-group p-0">
          <div  class="treat-label">Has a restraint been implemented within the last 24 hours? 
            <span class="custom-radio ml-2 align-text-top">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="IncReportfrmInfo.RestraintInAday"
            #RestraintInAday="ngModel" name="RestraintInAday" [ngClass]="{ 'is-invalid': IncReportfrm.submitted && RestraintInAday.invalid }"
            required >
              <mat-radio-button value=true  class="mr-2"  (click)="onInaDay(1)">Yes</mat-radio-button>
              <mat-radio-button value=false  (click)="onInaDay(2)" >No</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="IncReportfrm.submitted && RestraintInAday.invalid">
              <div class="error-message"  *ngIf="RestraintInAday.errors.required">
              This field is required</div>
              </div>
          </span></div>
          
         </div>
         <div class="col-md-12 form-group p-0">
           <div class="d-flex">
             <div class="mr-3">
              <div class="treat-label mt-4">Body check after restraint</div>
             </div>
             <div class="mr-3">
              <mat-form-field class="example-full-width w-100 ">
                <mat-label> Date</mat-label>
                <input matInput [matDatepicker]="Examdatepicker" [(ngModel)]="IncReportfrmInfo.MedExamDate" #MedExamDate="ngModel" name="MedExamDate"
                [ngClass]="{ 'is-invalid': IncReportfrm.submitted && MedExamDate.invalid }"
                required >
                <mat-datepicker-toggle matSuffix [for]="Examdatepicker"></mat-datepicker-toggle>
                <mat-datepicker #Examdatepicker></mat-datepicker>
                <mat-error *ngIf="IncReportfrm.submitted && MedExamDate.invalid">
                  <mat-error  *ngIf="MedExamDate.errors.required">
                    Date is required</mat-error>
              </mat-error>
              </mat-form-field>
            </div>
            <div class="">
              <mat-form-field class="example-full-width w-100 ">
                <mat-label>Time</mat-label>
                <input matInput type="time"  [(ngModel)]="IncReportfrmInfo.MedExamTime" #MedExamTime="ngModel" name="MedExamTime"
                [ngClass]="{ 'is-invalid': IncReportfrm.submitted && MedExamTime.invalid }"
                required >
                <mat-error *ngIf="IncReportfrm.submitted && MedExamTime.invalid">
                  <mat-error  *ngIf="MedExamTime.errors.required">
                    Time is required</mat-error>
              </mat-error>
              </mat-form-field>
             
            </div>
           </div>
         </div>
         <div class="col-md-12 form-group p-0">
          <div  class="treat-label">Result</div>
          <mat-form-field class="w-100 custom-textarea mt-2">
            <textarea matInput [(ngModel)]="IncReportfrmInfo.Result" #Result="ngModel" name="Result" row="3" maxlength="500"
            [ngClass]="{ 'is-invalid': IncReportfrm.submitted && Result.invalid }"
            required  ></textarea>
            <mat-error *ngIf="IncReportfrm.submitted && Result.invalid">
              <mat-error  *ngIf="Result.errors.required">
                Result is required</mat-error>
          </mat-error>
          </mat-form-field>
         </div>
         <div class="col-md-12 form-group p-0">
          <div  class="treat-label">Describe any preexisting medical conditions/physical disabilities that would place client at greater risk during restraint</div>
          <mat-form-field class="w-100 custom-textarea mt-2">
            <textarea matInput [(ngModel)]="IncReportfrmInfo.PhysicalDisabilities" #PhysicalDisabilities="ngModel" name="PhysicalDisabilities" row="3" maxlength="500"
            [ngClass]="{ 'is-invalid': IncReportfrm.submitted && PhysicalDisabilities.invalid }"
            required ></textarea>
            <mat-error *ngIf="IncReportfrm.submitted && PhysicalDisabilities.invalid">
              <mat-error  *ngIf="PhysicalDisabilities.errors.required">
                This field is required</mat-error>
          </mat-error>
          </mat-form-field>
         </div>
         <div class="col-md-12 form-group p-0">
          <div  class="treat-label">Describe any history of sexual/physical abuse that would place client at greater psychological risk during restraint</div>
          <mat-form-field class="w-100 custom-textarea mt-2">
            <textarea matInput [(ngModel)]="IncReportfrmInfo.PhysicalAbuse" #PhysicalAbuse="ngModel" name="PhysicalAbuse" row="3" maxlength="500"
            [ngClass]="{ 'is-invalid': IncReportfrm.submitted && PhysicalAbuse.invalid }"
            required ></textarea>
            <mat-error *ngIf="IncReportfrm.submitted && PhysicalAbuse.invalid">
              <mat-error  *ngIf="PhysicalAbuse.errors.required">
                This field is required</mat-error>
          </mat-error>
          </mat-form-field>
         </div>
         <div class="col-md-12 form-group p-0" *ngIf="status!=3">
           <div class="text-right" >
            <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button  (click)="onReviewClick()" *ngIf="(IsEdit || roleAccess.isView) && !IsReview">Review</button>
            <button type ="button" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2"   mat-stroked-button *ngIf="!IsEdit && roleAccess.isView" (click)="saveAsReportIncident()">Save as Draft</button>
            <button type ="submit" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2"   mat-stroked-button  *ngIf="roleAccess.isEdit || roleAccess.isCreate">{{!IsEdit?'Save':'Update'}}</button>
            <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button  *ngIf="roleAccess.isEdit || roleAccess.isCreate"  (click)="OnBack()" >Cancel</button>
           </div>
         </div>
            </div>
          </div>
        </div>


        <div class="incident-common" *ngIf="IncReportfrmInfo.IncidentReportTypeId!=2">
            <div class="row">
              <div class="col-md-2">
                <mat-form-field class="example-full-width w-100 ">
                  <mat-label>Reporting Date</mat-label>
                  <input matInput [matDatepicker]="ReportDatepicker" [(ngModel)]="IncReportfrmInfo.ReportDate" #ReportDate="ngModel" name="ReportDate"
                  required>
                  <mat-datepicker-toggle matSuffix [for]="ReportDatepicker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #ReportDatepicker></mat-datepicker>
                  <mat-error *ngIf="IncReportfrm.submitted && ReportDate.invalid">
                    <mat-error  *ngIf="ReportDate.errors.required">
                      Report Date  is required</mat-error>
                </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field class="example-full-width w-100 ">
                  <mat-label>Incident Date</mat-label>
                  <input matInput [matDatepicker]="Incpicker" [(ngModel)]="IncReportfrmInfo.IncidentDateForIncident" #IncidentDateForIncident="ngModel" name="IncidentDateForIncident" [max]="today" (dateChange)="timePickerDisableTimeForIncident()" required>
                  <mat-datepicker-toggle matSuffix [for]="Incpicker"></mat-datepicker-toggle>
                  <mat-datepicker #Incpicker></mat-datepicker>
                  <mat-error *ngIf="IncReportfrm.submitted && IncidentDateForIncident.invalid">
                    <mat-error  *ngIf="IncidentDateForIncident.errors.required">
                        Incident Date  is required</mat-error>
                </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field class="example-full-width w-100 ">
                  <mat-label>Incident Time</mat-label>
                  <input matInput type="time"  [(ngModel)]="IncReportfrmInfo.IncidentTimeForIncident" #IncidentTimeForIncident="ngModel" name="IncidentTimeForIncident"
                  [ngClass]="{ 'is-invalid': IncReportfrm.submitted && IncidentTimeForIncident.invalid }" (focusout)="checkIncidentTime()"
                  required>
                  <mat-error *ngIf="IncReportfrm.submitted && IncidentTimeForIncident.invalid">
                    <mat-error  *ngIf="IncidentTimeForIncident.errors.required">
                        Incident Time  is required</mat-error>
                </mat-error>
                </mat-form-field>
                <div class="error-message mt--14" *ngIf="IncReportfrm.submitted && showErrorMessageForIncident">
                  {{errorMessageForIncident}}
              </div>
              </div>
              
              <div class="col-md-3">
                <mat-form-field class="example-full-width w-100 ">
                  <mat-label>Incident Location</mat-label>
                  <input matInput  [(ngModel)]="IncReportfrmInfo.IncidentLocation" #IncidentLocation="ngModel"  maxlength="100" name="IncidentLocation">
               
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="example-full-width w-100 ">
                  <mat-label>Individuals Involved</mat-label>
                  <input matInput  [(ngModel)]="IncReportfrmInfo.IndividualInvolved" #IndividualInvolved="ngModel"  maxlength="100" name="IndividualInvolved">
                </mat-form-field>
              </div>
              <div class="col-md-12 form-group ">
                <div  class="treat-label" [ngClass]="{ 'error': IncReportfrm.submitted && IncidentDescription.invalid }">Description of Incident *</div>
                <mat-form-field class="w-100 custom-textarea mt-2">
                  <textarea matInput  row="3" maxlength="500" [(ngModel)]="IncReportfrmInfo.IncidentDescription"
                   #IncidentDescription="ngModel" name="IncidentDescription" required [ngClass]="{ 'is-invalid': IncReportfrm.submitted && IncidentDescription.invalid }"> </textarea>
                   <mat-error *ngIf="IncReportfrm.submitted && IncidentDescription.invalid">
                    <mat-error  *ngIf="IncidentDescription.errors.required">
                      Incident Description  is required</mat-error>
                </mat-error>
                </mat-form-field>
               
               </div>
               <div class="col-md-12 form-group ">
                <div  class="treat-label">Intervention</div>
                <mat-form-field class="w-100 custom-textarea mt-2">
                  <textarea matInput  row="3" maxlength="500" [(ngModel)]="IncReportfrmInfo.Intervention"
                  #Intervention="ngModel" name="Intervention"
                 ></textarea>
                </mat-form-field>
               
               </div>
               <div class="col-md-12 form-group">
                <div class="form-group clearfix m-0">
                  <div  class="float-left col-md-4 p-0 mt-3 pt-1">
                    <label  class="treat-label">Reporting Clinician's Signature</label>
                    <img  id="esign_2" height="50px" width="300px"  style="border: 1px solid #d3c7c7 !important;" >
                
                    <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"  (click)="openDialog()" > Add E-Sign </button>
                    <div class="float-left col-md-4 p-0 mt-3 pt-1" >
                      <mat-error *ngIf="IncReportfrm.submitted && IncReportfrmInfo.StaffInitial == undefined ">
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="IncReportfrmInfo.StaffInitial == undefined">
                            E-Sign is required</mat-error>
                    </mat-error>
                         </div></div>
                         <div  class="float-left col-md-4  mt-3 pt-1">
                          <label  class="treat-label">Secondary Signature</label>
                          <img  id="esign_3" height="50px" width="300px"  style="border: 1px solid #d3c7c7 !important;" >
                        
                          <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" [disabled]="secondarySign()" (click)="openDialog1()" > Add E-Sign </button>
                          <div class="float-left col-md-4 p-0 mt-3 pt-1" >
                            <mat-error *ngIf="IncReportfrm.submitted && IncReportfrmInfo.StaffInitial == undefined ">
                              <mat-error class="mat-form-field-subscript-wrapper" *ngIf="IncReportfrmInfo.StaffInitial == undefined">
                                  E-Sign is required</mat-error>
                          </mat-error>
                               </div></div>
                    <div  class="float-right col-md-2 p-0">
                      <mat-form-field class="example-full-width w-100 ">
                        <mat-label> Date</mat-label>
                        <input matInput [matDatepicker]="updatedpicker" [(ngModel)]="IncReportfrmInfo.SignDate" #SignDate="ngModel" name="SignDate" >
                        <mat-datepicker-toggle matSuffix [for]="updatedpicker"></mat-datepicker-toggle>
                        <mat-datepicker #updatedpicker></mat-datepicker>
                     
                      </mat-form-field>
                    </div>

                    
                </div>
               </div>
               <div class="col-md-12 form-group " *ngIf="status!=3">
                <div class="text-right" >
                  <button type="button" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="onReviewClick()" *ngIf="(IsEdit ||  roleAccess.isView)  && !IsReview">Review</button>
                  <button type ="button" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2"   mat-stroked-button *ngIf="!IsEdit && roleAccess.isView" (click)="saveAsReportIncident()">Save as Draft</button>
                 <button type ="submit" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2"   mat-stroked-button *ngIf="roleAccess.isCreate || roleAccess.isEdit">{{!IsEdit?'Save':'Update'}}</button>
                 <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button  *ngIf="roleAccess.isCreate || roleAccess.isEdit"  (click)="OnBack()" >Cancel</button>
                </div>
              </div>
            </div>
        </div>

        </div>
        </form>
        <div class="col-md-12 form-group p-0" *ngIf="status==3">
          <div class="text-right">
        <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button  (click)="onReviewClick()" *ngIf="(!IsEdit  ||  roleAccess.isView)  && !IsReview">Review</button>
       <!-- <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button  (click)="OnBack()" *ngIf="!IsEdit">Cancel</button>-->
        </div>
      </div>
    </div>
  </div>


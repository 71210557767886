<div class="grid-view-section">
   <div class="top-list-notifiaction">
      <div class="row">
         <div class="col-sm-12 col-lg-6 mb-3">
            <span class="hot-leads pr-2"><span><i class="fas fa-circle pr-1"></i></span>Hot Leads</span>
            <span class="warm-leads pr-2"><span><i class="fas fa-circle pr-1"></i></span>warm Leads</span>
            <span class="cold-leads pr-2"><span><i class="fas fa-circle pr-1"></i></span>cold Leads</span>
         </div>
         <div class="col-sm-12 col-lg-6 mb-3  text-right">
            <div class="toggle-switch float-right">
               <a [routerLink]="['/leadaccount']" class="col text-left"><span><i
                        class="fas fa-user-plus"></i></span></a>
               <!-- <a class="col text-center"><span><i class="fas fa-filter"></i></span></a> -->
               <a [routerLink]="['/leadlist']" class="col text-center "><span><i class="fas fa-list"></i></span></a>
               <a [routerLink]="['/leadkanban']" class="col text-right active"><span><i
                        class="fas fa-th"></i></span></a>
            </div>
            <!-- <div class="page-nation float-right"> -->

            <!-- <ul class="pagination pagination-large">
                   <li class="page-head mr-3"> <span class="label-color">List Entries</span></li>
                   <li class="active"><a href="#">05</a></li>
                   <li><a href="#">10</a></li>
                   <li><a href="#">15</a></li>
                   <li><a href="#">20</a></li>
 
                </ul> -->

            <!-- </div> -->

         </div>
         <div class="col-md-3 ">
            <div  class="search">
                <!-- <input  type="text" placeholder="Search By Client Name" name="" class="search-input " [(ngModel)]="searchpatientListtxt" (keyup)="OnSearchPatient()"><a  class="search-icon"><i  class="fa fa-search"></i></a></div> -->
                <input  type="text" placeholder="Search" name="" class="search-input " [(ngModel)]="searchpatientListtxt" (keyup)="OnSearchPatient()">
                <a  class="search-icon"><i  class="fa fa-search"></i></a>
               </div>
            </div>
      </div>
   </div>
   <div class="grid-main-section lead-kanban mt-4">
      <div class="col-md-12 p-0">
         <div class="row">
            <div class="col ">
               <h4>Point of contact</h4>
               <div class=" card grid-card mb-3 cursor-pointer"
                  *ngFor="let item of lstLeadkanban?.pointOfContact;let i = index">
                  <div class=" card-body pb-2 hot">
                     <div class="circle"><i class="fas fa-circle"></i></div>
                     
                     <div class=" row">
                        <div class=" col">
                            <h5 class=" card-title text-uppercase mb-2 ">{{item.firstName}} {{item.lastName}}</h5> 
                         <!-- <h5 class=" card-title text-uppercase mb-2 ">{{item.firstName}}{{item.LastName}}</h5>  -->
                           <!-- <div class="card-details">
                              <div class="content-card">
                                 <p>{{item.parentList[0]?.email}}</p>
                                 <p>+1 {{item.parentList[0]?.contactNo | mask: '(000) 000-0000'}}</p>
                                 <p>{{item.leadType}}</p>
                                 <p>{{item.parentList[0]?.city}}{{item.parentList[0]?.stateName ==
                                    '' ? '' :','}} {{item.parentList[0]?.stateName}}</p>
                              </div> -->
                               <div class="card-details">
                                 <p class="label-small ">Gender | DOB </p>
                                 <p>{{item.genderType}} | {{item.dob| date: 'MM/dd/yyyy'}}</p>
                              </div>
                              <p class="label-small mb-1 ">Lead Stage </p>
   
                              <h5 class=" card-title text-uppercase mb-2 benefits">{{item.leadStage}}</h5>
                              <div class="card-details">
                                  <!-- <p class="clearfix"><span class="label-small">Source </span>
                                    
                                        <p
                                       class="value-small">{{item.sourceType}}</span></p> -->
                                       <!-- <div>
                                       <p class="label-small mb-1 ">Lead Stage </p>
   
                              <h5 class=" card-title text-uppercase mb-2 benefits">{{item.LeadStage}}</h5> -->
                              <p class="label-small ">Source</p>
                              <p>{{item.sourceType}}</p>
                              <p class="label-small ">Lead Type</p>
                              <p>{{item.leadType}}</p>
                              <p class="label-small ">Location</p>
                              <p>{{item.facilityType}}</p>
                              <!-- <h5 class=" card-title text-uppercase mb-2 benefits" style="color:blue">Contacts:</h5> -->
                              <h1 style="color:#000000;" style="font-size:100%;">Contacts:</h1>
                              <p>+1 {{item.parentList[0]?.contactNo | mask: '(000) 000-0000'}}</p>
                              <p>{{item.parentList[0]?.email}}</p>
                              <div *ngIf="item.immediateActionId == statusReturn">
                              <h1 style="color:#000000;" style="font-size:100%;">Status:</h1>
                              <p >Initial client packet sent back for revision</p></div>
                                 <!-- <p class="clearfix"><span class="label-small">Lead Type </span> </p><p><span
                                       class="value-small">{{item.leadType}}</span></p>
                               
                                       <p class="clearfix"><span class="label-small">Location </span><span
                                          class="value-small">{{item.facilityType}}</span></p> -->
                              <div class="icons-section row mt-4">

                                 <div class="col-3 text-left cursor-pointer"><button class="col-btn-dis"
                                       (click)="onPatientdetailview(item.patientInfoId)" title="Detail View"><i
                                          class="far fa-calendar-minus"></i></button></div>
                                 <div class="col-3 text-center cursor-pointer"><button
                                       [disabled]="item.immediateActionId == statusResendActivationMail"
                                       class="col-btn-dis" (click)="onPatientinfo(item.patientInfoId)" title="Edit"><i
                                          class="far fa-edit" aria-hidden="true"></i></button></div>
                                 <!-- <div class="col-3 text-center cursor-pointer"><button
                                       [disabled]="item.immediateActionId == statusResendActivationMail"
                                       class="col-btn-dis"><i class="far fa-envelope"></i></button></div> -->
                                       <div class="col-3 text-center cursor-pointer">
                                          <button class="col text-center col-btn-dis" role="button"
                                          (click)="openComposeMail(item.email)"><i
                                             class="far fa-envelope"></i></button>
                                       </div>
                                      
                                 <div class="col-3 text-right cursor-pointer"><button class="col-btn-dis"><i
                                          class="far fa-address-book"></i></button></div>
                              </div>
                           </div>

                        </div>
                       
                        <div class=" ">
                           <div class="dropdown no-caret" dropdown="">
                              <a class="dropdown-toggle" role="button" id="dropdownMenuLink{{i}}" *ngIf="item.immediateActionId != statusReturn" data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                 <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in "
                                 style="left: auto; right: 0px;" aria-labelledby="dropdownMenuLink{{i}}">

                                 <a class="dropdown-item cursor-pointer" title="Resend Activation"
                                    *ngIf="item.immediateActionId == statusResendActivationMail"
                                    (click)="onResendActivation(item)">Resend Activation</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusUploadInsurance"
                                    title="Upload Insurance">Upload
                                    Insurance</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusReviewPacket"
                                    title="Review Packet" (click)="onPatientinfo(item.patientInfoId,item)">Review
                                    Packet</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusVerified && !item.isSkipInsurance" title="CRBA"
                                    (click)="onUpdateCRBA(item.patientInfoId)">Update Benefits</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="((item.immediateActionId == statusCrbaUpdate ) || (item.immediateActionId == statusVerified && item.isSkipInsurance))"
                                    title="Financial Proposal" (click)="onSubmitFinancial(item)">Submit Financial
                                    Proposal</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusintake"
                                    title="Schedule Intake" (click)="onIntakeSchedule(item)">Schedule Intake</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusreviewTour && (item.status==statusRequestTour||item.status==null)"
                                    title="Request Tour" (click)="onRequestTour(item)">Review Tour Request</a>
                                 <a  *ngIf="item.immediateActionId == statusdecline"
                                    class="dropdown-item status-msg cursor-pointer text-underline"
                                    (click)="getfinancialReason(item)">Show Reason </a>
                                 <a *ngIf="item.immediateActionId  == statusdecline || item.immediateActionId == statusFinancial || item.immediateActionId == statusreviewTour || item.immediateActionId == statusstaffAssigned "
                                    class="dropdown-item status-msg cursor-pointer text-underline" (click)="onSubmitFinancial(item)">
                                    Edit Financial proposal</a>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
            <div class="col">
               <h4>Benefits</h4>
               <div class=" card grid-card mb-3" *ngFor="let item of lstLeadkanban?.benefits">
                  <div class=" card-body cold">
                     <div class="circle"><i class="fas fa-circle"></i></div>
                     <div class=" row">
                        <div class=" col">
                           <h5 class=" card-title text-uppercase mb-2 ">{{item.firstName}} {{item.lastName}}</h5>
                           <!-- <div class="card-details">
                              <div class="content-card">
                                 <p>{{item.parentList[0]?.email}}</p>
                                 <p>+1 {{item.parentList[0]?.contactNo | mask: '(000) 000-0000'}}</p>
                                 <p>{{item.leadType}}</p>
                                 <p>{{item.parentList[0]?.city}}{{item.parentList[0]?.stateName ==
                                    '' ? '' :','}} {{item.parentList[0]?.stateName}}</p>
                              </div> -->
                              <div class="card-details">
                                 <p class="label-small ">Gender | DOB </p>
                                 <p>{{item.genderType}} | {{item.dob| date: 'MM/dd/yyyy'}}</p>
                              </div>
                              <p class="label-small mb-1 ">Lead Stage </p>
   
                              <h5 class=" card-title text-uppercase mb-2 benefits">{{item.leadStage}}</h5>
                              <div class="card-details">
                                  <!-- <p class="clearfix"><span class="label-small">Source </span>
                                    
                                        <p
                                       class="value-small">{{item.sourceType}}</span></p> -->
                                       <!-- <div>
                                       <p class="label-small mb-1 ">Lead Stage </p>
   
                              <h5 class=" card-title text-uppercase mb-2 benefits">{{item.LeadStage}}</h5> -->
                              <p class="label-small ">Source</p>
                              <p>{{item.sourceType}}</p>
                              <p class="label-small ">Lead Type</p>
                              <p>{{item.leadType}}</p>
                              <p class="label-small ">Location</p>
                              <p>{{item.facilityType}}</p>
                              <h1 style="color:#000000;" style="font-size:100%;">Contacts:</h1>
                              <p>+1 {{item.parentList[0]?.contactNo | mask: '(000) 000-0000'}}</p>
                              <p>{{item.parentList[0]?.email}}</p>

                              <div class="icons-section row mt-4">

                                 <div class="col-3 text-left cursor-pointer"><button class="col-btn-dis"
                                       (click)="onPatientdetailview(item.patientInfoId)" title="Detail View"><i
                                          class="far fa-calendar-minus"></i></button></div>
                                 <div class="col-3 text-center cursor-pointer"><button
                                       [disabled]="item.immediateActionId == statusResendActivationMail"
                                       class="col-btn-dis" (click)="onPatientinfo(item.patientInfoId)" title="Edit"><i
                                          class="far fa-edit" aria-hidden="true"></i></button></div>
                                 <!-- <div class="col-3 text-center cursor-pointer"><button
                                       [disabled]="item.immediateActionId == statusResendActivationMail"
                                       class="col-btn-dis"><i class="far fa-envelope"></i></button></div> -->
                                       <div class="col-3 text-center cursor-pointer">
                                          <button class="col text-center col-btn-dis" role="button"
                                          (click)="openComposeMail(item.email)"><i
                                             class="far fa-envelope"></i></button>
                                       </div>
                                 <div class="col-3 text-right cursor-pointer"><button class="col-btn-dis"><i
                                          class="far fa-address-book"></i></button></div>
                              </div>
                           </div>

                        </div>
                        <div class=" ">
                           <div class="dropdown no-caret" dropdown="">
                              <a class="dropdown-toggle" role="button" id="dropdownMenuLink{{i}}" data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                 <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in "
                                 style="left: auto; right: 0px;" aria-labelledby="dropdownMenuLink{{i}}">

                                 <a class="dropdown-item cursor-pointer" title="Resend Activation"
                                    *ngIf="item.immediateActionId == statusResendActivationMail"
                                    (click)="onResendActivation(item)">Resend Activation</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusUploadInsurance"
                                    title="Upload Insurance">Upload
                                    Insurance</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusReviewPacket"
                                    title="Review Packet" (click)="onPatientinfo(item.patientInfoId,item)">Review
                                    Packet</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusVerified && !item.isSkipInsurance" title="CRBA"
                                    (click)="onUpdateCRBA(item.patientInfoId)">Update Benefits</a>
                                  <a class="dropdown-item cursor-pointer" *ngIf="((item.immediateActionId == statusCrbaUpdate ) || (item.immediateActionId == statusVerified && item.isSkipInsurance))"
                                    title="Financial Proposal" (click)="onSubmitFinancial(item)">Submit Financial
                                    Proposal</a>
                                 <!--<a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusintake"
                                    title="Schedule Intake" (click)="onIntakeSchedule(item)">Schedule Intake</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusreviewTour && (item.status==statusRequestTour||item.status==null)"
                                    title="Request Tour" (click)="onRequestTour(item)">Review Tour Request</a>
                                 <a  *ngIf="item.immediateActionId == statusdecline"
                                    class="dropdown-item status-msg cursor-pointer text-underline"
                                    (click)="getfinancialReason(item)">Show Reason </a>
                                 <a *ngIf="item.immediateActionId  == statusdecline || item.immediateActionId == statusFinancial || item.immediateActionId == statusreviewTour || item.immediateActionId == statusstaffAssigned "
                                    class="dropdown-item status-msg cursor-pointer text-underline" (click)="onSubmitFinancial(item)">
                                    Edit Financial proposal</a> -->
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
            <div class="col ">
               <h4>Fee</h4>
               <div class=" card grid-card mb-3" *ngFor="let item of lstLeadkanban?.fee">
                  <div class=" card-body warm">
                     <div class="circle"><i class="fas fa-circle"></i></div>
                     <div class=" row">
                        <div class=" col">
                           <h5 class=" card-title text-uppercase mb-2 ">{{item.firstName}}  {{item.lastName}}</h5>
                           <!-- <div class="card-details">
                              <div class="content-card">
                                 <p>{{item.parentList[0]?.email}}</p>
                                 <p>+1 {{item.parentList[0]?.contactNo | mask: '(000) 000-0000'}}</p>
                                 <p>{{item.leadType}}</p>
                                 <p>{{item.parentList[0]?.city}}{{item.parentList[0]?.stateName ==
                                    '' ? '' :','}} {{item.parentList[0]?.stateName}}</p>
                              </div> -->
                              <div class="card-details">
                                 <p class="label-small ">Gender | DOB </p>
                                 <p>{{item.genderType}} | {{item.dob| date: 'MM/dd/yyyy'}}</p>
                              </div>
                              <p class="label-small mb-1 ">Lead Stage </p>
   
                              <h5 class=" card-title text-uppercase mb-2 benefits">{{item.leadStage}}</h5>
                              <div class="card-details">
                                  <!-- <p class="clearfix"><span class="label-small">Source </span>
                                    
                                        <p
                                       class="value-small">{{item.sourceType}}</span></p> -->
                                       <!-- <div>
                                       <p class="label-small mb-1 ">Lead Stage </p>
   
                              <h5 class=" card-title text-uppercase mb-2 benefits">{{item.LeadStage}}</h5> -->
                              <p class="label-small ">Source</p>
                              <p>{{item.sourceType}}</p>
                              <p class="label-small ">Lead Type</p>
                              <p>{{item.leadType}}</p>
                              <p class="label-small ">Location</p>
                              <p>{{item.facilityType}}</p>
                              <h1 style="color:#000000;" style="font-size:100%;">Contacts:</h1>
                              <p>+1 {{item.parentList[0]?.contactNo | mask: '(000) 000-0000'}}</p>
                              <p>{{item.parentList[0]?.email}}</p>
                              <div class="icons-section row mt-4">

                                 <div class="col-3 text-left cursor-pointer"><button class="col-btn-dis"
                                       (click)="onPatientdetailview(item.patientInfoId)" title="Detail View"><i
                                          class="far fa-calendar-minus"></i></button></div>
                                 <div class="col-3 text-center cursor-pointer"><button
                                       [disabled]="item.immediateActionId == statusResendActivationMail"
                                       class="col-btn-dis" (click)="onPatientinfo(item.patientInfoId)" title="Edit"><i
                                          class="far fa-edit" aria-hidden="true"></i></button></div>
                                 <!-- <div class="col-3 text-center cursor-pointer"><button
                                       [disabled]="item.immediateActionId == statusResendActivationMail"
                                       class="col-btn-dis"><i class="far fa-envelope"></i></button></div> -->
                                       <div class="col-3 text-center cursor-pointer">
                                          <button class="col text-center col-btn-dis" role="button"
                                          (click)="openComposeMail(item.email)"><i
                                             class="far fa-envelope"></i></button>
                                       </div>
                                 <div class="col-3 text-right cursor-pointer"><button class="col-btn-dis"><i
                                          class="far fa-address-book"></i></button></div>
                              </div>
                           </div>

                        </div>
                        <div class=" ">
                           <div class="dropdown no-caret" dropdown="">
                              <a class="dropdown-toggle" role="button" id="dropdownMenuLink{{i}}" data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                 <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in "
                                 style="left: auto; right: 0px;" aria-labelledby="dropdownMenuLink{{i}}">

                                 <a class="dropdown-item cursor-pointer" title="Resend Activation"
                                    *ngIf="item.immediateActionId == statusResendActivationMail"
                                    (click)="onResendActivation(item)">Resend Activation</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusUploadInsurance"
                                    title="Upload Insurance">Upload
                                    Insurance</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusReviewPacket"
                                    title="Review Packet" (click)="onPatientinfo(item.patientInfoId,item)">Review
                                    Packet</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusVerified && !item.isSkipInsurance" title="CRBA"
                                    (click)="onUpdateCRBA(item.patientInfoId)">Update Benefits</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="((item.immediateActionId == statusCrbaUpdate ) || (item.immediateActionId == statusVerified && item.isSkipInsurance))"
                                    title="Financial Proposal" (click)="onSubmitFinancial(item)">Submit Financial
                                    Proposal</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusintake"
                                    title="Schedule Intake" (click)="onIntakeSchedule(item)">Schedule Intake</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusreviewTour && (item.status==statusRequestTour||item.status==null)"
                                    title="Request Tour" (click)="onRequestTour(item)">Review Tour Request</a>
                                 <a  *ngIf="item.immediateActionId == statusdecline"
                                    class="dropdown-item status-msg cursor-pointer text-underline"
                                    (click)="getfinancialReason(item)">Show Reason </a>
                                 <a *ngIf="item.immediateActionId  == statusdecline || item.immediateActionId == statusFinancial || item.immediateActionId == statusreviewTour || item.immediateActionId == statusstaffAssigned "
                                    class="dropdown-item status-msg cursor-pointer text-underline" (click)="onSubmitFinancial(item)">
                                    Edit Financial proposal</a>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
            <div class="col">
               <h4>Tour</h4>
               <div class=" card grid-card mb-3" *ngFor="let item of lstLeadkanban?.tour">
                  <div class=" card-body hot">
                     <div class="circle"><i class="fas fa-circle"></i></div>
                     <div class=" row">
                        <div class=" col">
                           <h5 class=" card-title text-uppercase mb-2 ">{{item.firstName}} {{item.lastName}}</h5>
                           <!-- <div class="card-details">
                              <div class="content-card">
                                 <p>{{item.parentList[0]?.email}}</p>
                                 <p>+1 {{item.parentList[0]?.contactNo | mask: '(000) 000-0000'}}</p>
                                 <p>{{item.leadType}}</p>
                                 <p>{{item.parentList[0]?.city}}{{item.parentList[0]?.stateName ==
                                    '' ? '' :','}} {{item.parentList[0]?.stateName}}</p>
                              </div> -->
                              <div class="card-details">
                                 <p class="label-small ">Gender | DOB </p>
                                 <p>{{item.genderType}} | {{item.dob| date: 'MM/dd/yyyy'}}</p>
                              </div>
                              <p class="label-small mb-1 ">Lead Stage </p>
   
                              <h5 class=" card-title text-uppercase mb-2 benefits">{{item.leadStage}}</h5>
                              <div class="card-details">
                                  <!-- <p class="clearfix"><span class="label-small">Source </span>
                                    
                                        <p
                                       class="value-small">{{item.sourceType}}</span></p> -->
                                       <!-- <div>
                                       <p class="label-small mb-1 ">Lead Stage </p>
   
                              <h5 class=" card-title text-uppercase mb-2 benefits">{{item.LeadStage}}</h5> -->
                              <p class="label-small ">Source</p>
                              <p>{{item.sourceType}}</p>
                              <p class="label-small ">Lead Type</p>
                              <p>{{item.leadType}}</p>
                              <p class="label-small ">Location</p>
                              <p>{{item.facilityType}}</p>
                              <h1 style="color:#000000;" style="font-size:100%;">Contacts:</h1>
                              <p>+1 {{item.parentList[0]?.contactNo | mask: '(000) 000-0000'}}</p>
                              <p>{{item.parentList[0]?.email}}</p>
                              <div class="icons-section row mt-4">

                                 <div class="col-3 text-left cursor-pointer"><button class="col-btn-dis"
                                       (click)="onPatientdetailview(item.patientInfoId)" title="Detail View"><i
                                          class="far fa-calendar-minus"></i></button></div>
                                 <div class="col-3 text-center cursor-pointer"><button
                                       [disabled]="item.immediateActionId == statusResendActivationMail"
                                       class="col-btn-dis" (click)="onPatientinfo(item.patientInfoId)" title="Edit"><i
                                          class="far fa-edit" aria-hidden="true"></i></button></div>
                                 <!-- <div class="col-3 text-center cursor-pointer"><button
                                       [disabled]="item.immediateActionId == statusResendActivationMail"
                                       class="col-btn-dis"><i class="far fa-envelope"></i></button></div> -->
                                       <div class="col-3 text-center cursor-pointer">
                                          <button class="col text-center col-btn-dis" role="button"
                                          (click)="openComposeMail(item.email)"><i
                                             class="far fa-envelope"></i></button>
                                       </div>
                                 <div class="col-3 text-right cursor-pointer"><button class="col-btn-dis"><i
                                          class="far fa-address-book"></i></button></div>
                              </div>
                           </div>

                        </div>
                        <div class=" ">
                           <div class="dropdown no-caret" dropdown="">
                              <a class="dropdown-toggle" role="button" id="dropdownMenuLink{{i}}" data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                 <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in "
                                 style="left: auto; right: 0px;" aria-labelledby="dropdownMenuLink{{i}}">

                                 <a class="dropdown-item cursor-pointer" title="Resend Activation"
                                    *ngIf="item.immediateActionId == statusResendActivationMail"
                                    (click)="onResendActivation(item)">Resend Activation</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusUploadInsurance"
                                    title="Upload Insurance">Upload
                                    Insurance</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusReviewPacket"
                                    title="Review Packet" (click)="onPatientinfo(item.patientInfoId,item)">Review
                                    Packet</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusVerified && !item.isSkipInsurance" title="CRBA"
                                    (click)="onUpdateCRBA(item.patientInfoId)">Update Benefits</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="((item.immediateActionId == statusCrbaUpdate ) || (item.immediateActionId == statusVerified && item.isSkipInsurance))"
                                    title="Financial Proposal" (click)="onSubmitFinancial(item)">Submit Financial
                                    Proposal</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusintake"
                                    title="Schedule Intake" (click)="onIntakeSchedule(item)">Schedule Intake</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusreviewTour && (item.status==statusRequestTour||item.status==null)"
                                    title="Request Tour" (click)="onRequestTour(item)">Review Tour Request</a>
                                    <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusreviewTour && item.status==statusstaffAssigned"
                                    title="Request Tour" (click)="onRequestTour(item)">Edit Tour</a>
                                 <a  *ngIf="item.immediateActionId == statusdecline"
                                    class="dropdown-item status-msg cursor-pointer text-underline"
                                    (click)="getfinancialReason(item)">Show Reason </a>
                                 <a *ngIf="item.immediateActionId  == statusdecline || item.immediateActionId == statusFinancial || item.immediateActionId == statusreviewTour || item.immediateActionId == statusstaffAssigned "
                                    class="dropdown-item status-msg cursor-pointer text-underline" (click)="onSubmitFinancial(item)">
                                    Edit Financial proposal</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col">
               <h4>Intake</h4>
               <div class=" card grid-card mb-3" *ngFor="let item of lstLeadkanban?.intake">
                  <div class=" card-body hot">
                     <div class="circle"><i class="fas fa-circle"></i></div>
                     <div class=" row">
                        <div class=" col">
                           <h5 class=" card-title text-uppercase mb-2 ">{{item.firstName}} {{item.lastName}}</h5>
                           <!-- <div class="card-details">
                              <div class="content-card">
                                 <p>{{item.parentList[0]?.email}}</p>
                                 <p>+1 {{item.parentList[0]?.contactNo | mask: '(000) 000-0000'}}</p>
                                 <p>{{item.leadType}}</p>
                                 <p>{{item.parentList[0]?.city}}{{item.parentList[0]?.stateName ==
                                    '' ? '' :','}} {{item.parentList[0]?.stateName}}</p>
                              </div> -->
                              <div class="card-details">
                                 <p class="label-small ">Gender | DOB </p>
                                 <p>{{item.genderType}} | {{item.dob| date: 'MM/dd/yyyy'}}</p>
                              </div>
                              <p class="label-small mb-1 ">Lead Stage </p>
   
                              <h5 class=" card-title text-uppercase mb-2 benefits">{{item.leadStage}}</h5>
                              <div class="card-details">
                                  <!-- <p class="clearfix"><span class="label-small">Source </span>
                                    
                                        <p
                                       class="value-small">{{item.sourceType}}</span></p> -->
                                       <!-- <div>
                                       <p class="label-small mb-1 ">Lead Stage </p>
   
                              <h5 class=" card-title text-uppercase mb-2 benefits">{{item.LeadStage}}</h5> -->
                              <p class="label-small ">Source</p>
                              <p>{{item.sourceType}}</p>
                              <p class="label-small ">Lead Type</p>
                              <p>{{item.leadType}}</p>
                              <p class="label-small ">Location</p>
                              <p>{{item.facilityType}}</p>
                              <h5 class=" card-title text-uppercase mb-2 benefits">Contacts:</h5>
                              <p>+1 {{item.parentList[0]?.contactNo | mask: '(000) 000-0000'}}</p>
                              <p>{{item.parentList[0]?.email}}</p>
                              <div class="icons-section row mt-4">

                                 <div class="col-3 text-left cursor-pointer"><button class="col-btn-dis"
                                       (click)="onPatientdetailview(item.patientInfoId)" title="Detail View"><i
                                          class="far fa-calendar-minus"></i></button></div>
                                 <div class="col-3 text-center cursor-pointer"><button
                                       [disabled]="item.immediateActionId == statusResendActivationMail"
                                       class="col-btn-dis" (click)="onPatientinfo(item.patientInfoId)" title="Edit"><i
                                          class="far fa-edit" aria-hidden="true"></i></button></div>
                                 <!-- <div class="col-3 text-center cursor-pointer"><button
                                       [disabled]="item.immediateActionId == statusResendActivationMail"
                                       class="col-btn-dis"><i class="far fa-envelope"></i></button></div> -->
                                       <div class="col-3 text-center cursor-pointer">
                                          <button class="col text-center col-btn-dis" role="button"
                                          (click)="openComposeMail(item.email)"><i
                                             class="far fa-envelope"></i></button>
                                       </div>
                                 <div class="col-3 text-right cursor-pointer"><button class="col-btn-dis"><i
                                          class="far fa-address-book"></i></button></div>
                              </div>
                           </div>

                        </div>
                        <div class=" ">
                           <div class="dropdown no-caret" dropdown="">
                              <a class="dropdown-toggle" role="button" id="dropdownMenuLink{{i}}" data-toggle="dropdown"
                                 aria-haspopup="true" aria-expanded="false">
                                 <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in "
                                 style="left: auto; right: 0px;" aria-labelledby="dropdownMenuLink{{i}}">

                                 <a class="dropdown-item cursor-pointer" title="Resend Activation"
                                    *ngIf="item.immediateActionId == statusResendActivationMail"
                                    (click)="onResendActivation(item)">Resend Activation</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusUploadInsurance"
                                    title="Upload Insurance">Upload
                                    Insurance</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusReviewPacket"
                                    title="Review Packet" (click)="onPatientinfo(item.patientInfoId,item)">Review
                                    Packet</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusVerified && !item.isSkipInsurance" title="CRBA"
                                    (click)="onUpdateCRBA(item.patientInfoId)">Update Benefits</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="((item.immediateActionId == statusCrbaUpdate ) || (item.immediateActionId == statusVerified && item.isSkipInsurance))"
                                    title="Financial Proposal" (click)="onSubmitFinancial(item)">Submit Financial
                                    Proposal</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusintake"
                                    title="Schedule Intake" (click)="onIntakeSchedule(item)">Schedule Intake</a>
                                 <a class="dropdown-item cursor-pointer" *ngIf="item.immediateActionId == statusreviewTour && (item.status==statusRequestTour||item.status==null)"
                                    title="Request Tour" (click)="onRequestTour(item)">Review Tour Request</a>
                                 <a  *ngIf="item.immediateActionId == statusdecline"
                                    class="dropdown-item status-msg cursor-pointer text-underline"
                                    (click)="getfinancialReason(item)">Show Reason </a>
                                 <a *ngIf="item.immediateActionId  == statusdecline || item.immediateActionId == statusFinancial || item.immediateActionId == statusreviewTour || item.immediateActionId == statusstaffAssigned "
                                    class="dropdown-item status-msg cursor-pointer text-underline" (click)="onSubmitFinancial(item)">
                                    Edit Financial proposal</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="5"
      [pageSizeOptions]="[5, 10, 25, 100]">
   </mat-paginator> -->
   </div>

</div>
<div class="comment-popup  cancel-tour" *ngIf="isPopup" >
   <form class="form-horizontal form-material mx-2" (ngSubmit)="ShareDocument.form.valid" id="ShareDocument"   #ShareDocument="ngForm">
   <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
     <div class="modal-dialog mat-dialog-container " role="document">
 
       <div class="modal-content border-0">
 <div class="modal-header">
   <div class="modal-title">Email</div>
   <!-- <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="SharePopupClose()">
     <span aria-hidden="true">&times;</span>
   </button> -->
   <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="SharePopupClose()">
      <span aria-hidden="true">&times;</span>
    </button>
 </div>
         <div class="modal-body">
 <div >
 
 
   <div class="col-md-12">
     <div class="row ">
       <div class="col-md-12">
 <div class="row">
  
   <div class="col-md-10" >
     <mat-form-field class="w-100">
      <!-- <label class="label" style="font-weight: bold;" >Email:</label>
      <p class="m-0">{{resendactivationemail.Email}}</p> -->
      <input matInput placeholder="Email" maxlength="50" [(ngModel)]="resendactivationemail.email"
      #email="ngModel" name="email" [disabled]="isEditUsers"
      pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
      required>
     </mat-form-field>
     <div class="col-md-2" >
      <button mat-raised-button style="min-width: 187px; margin-left: 236px;"  
      class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40" type="submit" 
      form="ShareDocument" (click)="onResendActivationEmail(resendactivationemail)">Resend Activation Email</button>
    </div>
    <!-- <div class="col-md-3" *ngIf="isActiveEmail">
      <div class="check-custom mt-4">
          <button mat-stroked-button title="Resend Activation Mail" type="button"
              class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="onResendActivationEmail()">Resend Activation Mail</button>
      </div>
  </div> -->
   </div>
   
  
 </div>
         
       </div>
 
 
     </div>
 
   </div>
   <div class="text-center col-md-12 mt-2">
     
     <!-- <button type="button" class="btn btn-cancel" data-dismiss="modal" >No</button> -->
   </div>
 
 
 
 </div>
 
 
 </div>
 </div>
 </div>
 
 </div>
 </form>
 </div>
 <div id="myModal" class="modal deny-modal" role="dialog" [style.display]="isFinancialDecline ? 'block' : 'none'">
   <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
         <div class="modal-header fill-header">
            <h6 class="modal-title">Please explain your reason for rejecting the Financial Proposal</h6>
            <button type="button" class="close" (click)="closeDeclineProcess()" data-dismiss="modal"><i
                  class="fas fa-times"></i></button>
         </div>
         <div class="modal-body">
            <p>{{financialDenyReason}}</p>
         </div>
         <div class="modal-footer">

            <button type="button" (click)="closeDeclineProcess()" class="btn btn-cancel primary-btn-s"
               data-dismiss="modal">Close</button>

         </div>
      </div>

   </div>
</div>
 <div class="modal mail-modal" id="myModal" [style.display]="global.showEmailPopup ? 'block' : 'none'">
                           <div class="modal-dialog mb-2">
                              <div class="modal-content email-compose">
                                 <div class="modal-header">
                                    <h4 class="modal-title">Email</h4>
                                    <button aria-hidden="true" data-dismiss="modal" class="close" (click)="global.oncloseEmailpopup()"
                                       type="button"><i class="fas fa-times"></i></button>
                        
                                 </div>
                                 <div class="modal-body">
                                    <app-send-email [subtag]="subjectTag"></app-send-email>
                        
                                 </div>
                              </div>
                           </div>
                        </div>
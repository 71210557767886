import { Time } from "@angular/common";

export class dtoScheduleModality {
    chmatreatmentSummaryId: number;
    patientAccountId: number;
    patientInfoId:number;
    scheduleModalityId: number;
    therapyId: number;
    provider: number;
    startDate: string;
    endDate: any;
    startTime: any;
    duration: Time;
    location: number;
    meetingRoom: number;
    isRecurance: boolean;
    isSunday: boolean;
    isMonday: boolean;
    isTuesday: boolean;
    isWednesday: boolean;
    isThursday: boolean;
    isFriday: boolean;
    isSaturday: boolean;
    meetingTypeId:any;
    

}


export class dtoScheduleConflictModality {
    isChecked: boolean;
    isConclict: boolean;
    date: string;
    day: Date;
    appointTime: Time;
    endTime: Time;
    location: number;
    locationName: string;
}


export class dtoReScheduleModality {
    chmatreatmentSummaryId: number;
    patientAccountId: number;
    patientInfoId:number;
    scheduleModalityId: number;
    therapyId: number;
    // provider: number;
    provider: number[];
    startDate: any;
    endDate: any;
    startTime: any;
    duration: any;
    location: number;
    meetingRoom: number;
    isRecurance: boolean = true;
    isRecuranceBlock:boolean;
    frequencyBlock : number;
    isSunday: boolean;
    isMonday: boolean;
    isTuesday: boolean;
    isWednesday: boolean;
    isThursday: boolean;
    isFriday: boolean;
    isSaturday: boolean;
    meetingType:any;
    rescheduleComments:string;
    blockTimeId:number;
    notes:any;
    adminNotes:any;
    minutes:any;
    additionalprovider: number[];
    supportstaffprovider: number[];
    frequency : number;
    weekOfMonth : number = 1;
    weekDaysOnMonth :number = 1;
    typename : any;
    repeatWeek : number = 2;
    quantity : number = 1;
    quantityBlock: number=1;
}

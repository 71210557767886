import { Component, OnInit, Input } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { PatientService } from 'src/app/patient/patient.service';
@Component({
  selector: 'app-intake-checklist',
  templateUrl: './intake-checklist.component.html',
  styleUrls: ['./intake-checklist.component.scss']
})
export class IntakeChecklistComponent implements OnInit {
  typeId: number;
  lstIntakeChecklist: any;
  patientInfoId: any;
  patientAccountId: any;
  cecklist = constantVariables.inTakeForms.Checklist;
  healthScreening = constantVariables.inTakeForms.HealthScreening;
  lastExaminationDate: any;
  today = new Date();
  pageId: number;
  lstmedicine:any;
  _dtoMedications:any;
  _dtoVitamins:any;
  roleID=localStorage.getItem('roleId');
  medicationIndex:number=null;
  vitaminIndex:number=null;
  _checkListQuestions:any[];
  isSubmitForm:boolean=false;
  configureFormTypeId:any=0;
  IsPatientAccount: any;
  patientName : any;
  constructor(public global: Global, public patientservice: PatientService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.patientName = localStorage.getItem("patientName");
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.typeId = Number(this.commonService.FrontEndDecryption(params.tab).replace(/"/g, ""));
       
        if (params.pageid == 1) {
          this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.patientAccountId).replace(/"/g, ""));
          this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patientinfoid).replace(/"/g, ""));
          this.pageId = params.pageid;
          this.configureFormTypeId=JSON.parse(this.commonService.FrontEndDecryption(params.cftid));
          this.patientName = JSON.parse(this.commonService.FrontEndDecryption(params.patientName))
          // this.configureFormTypeId=JSON.parse(this.commonService.FrontEndDecryption(params.isPatientAccount));
        }
       else if(params.cftid){
          this.configureFormTypeId=JSON.parse(this.commonService.FrontEndDecryption(params.cftid));
          }
          if(params.isPatientAccount){
            this.IsPatientAccount = true;
          }
      }
      
      this.getInTakeQuestions();
      this.getPatientMedication();
      this.getPatientVitamins();
      this.onAddVitamin();
      this.onAddMedicine();      
    });    
  }

  onAddVitamin() {
    if (this._dtoVitamins == null || this._dtoVitamins == undefined || this._dtoVitamins.length<1) {
      this._dtoVitamins = [];
      for (let i = 0; i < 1; i++) {
        this._dtoVitamins.push({
          vitaminId: 0,
          patientAccountId: Number(this.patientAccountId),
          medications: null,
          dosage: null,
          frequency: null,
          sideEffects: null,
          patientInfoId:Number(this.patientInfoId),
          noMedicationError:false,
          noDosageError:false,
          noFrequencyError:false,
          noSideEffectsError:false
        })
      }
    }
  }
  onAddMedicine(){
    if (this._dtoMedications == null || this._dtoMedications == undefined || this._dtoMedications.length == 0) {
      this._dtoMedications = [];
      for (let i = 0; i < 1; i++) {
        this._dtoMedications.push({
          psychotropicMedicationId: 0,
          patientAccountId: Number(this.patientAccountId),
          medications: null,
          dosage: null,
          frequency: null,
          sideEffects: null,
          patientInfoId:Number(this.patientInfoId),
          noMedicationError:false,
          noDosageError:false,
          noFrequencyError:false,
          noSideEffectsError:false
        })
      }
    }
  }

  getPatientMedication(){  
    this.patientservice.getPsychotropicMedication(this.patientInfoId).subscribe((res:any)=>{
      if(res.length ==0){
        return;
      }
      else
      {
      this._dtoMedications=res;    
      } 
    })
  }
  getInTakeQuestions() {
    this.spinnerservice.show();
    this.patientservice.getInTakeQuestionsintake(this.typeId, this.patientAccountId,this.configureFormTypeId).subscribe(
      (res: any) => {
      
        this.spinnerservice.hide();
        this.lstIntakeChecklist = cloneDeep(res);  
        this._checkListQuestions=cloneDeep(res.answers);
        if(this.typeId==2)
        {
        this.medicationIndex = this._checkListQuestions.findIndex(x=>x.questionTypeId==2);
        this.vitaminIndex = this._checkListQuestions.findIndex(x=>x.questionTypeId==3);
        }
        this.lastExaminationDate = this.lstIntakeChecklist.inTakeProgress.lastExamination;
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
        this.router.navigate(['/patient-breifcase']);
      },
    );
  }
  postIntakeChecklist(frmchecklist: NgForm) {
    // for(let i=0;i<this.lstIntakeChecklist.answers.length; i++){
    //   if(this.lstIntakeChecklist.answers[i].explanation == ""){
    //     return
    //   }
    // }
       
    this.isSubmitForm=true;
    this.lstIntakeChecklist.inTakeProgress.lastExamination = this.lastExaminationDate;
    this.lstIntakeChecklist.medications=this._dtoMedications;
    this.lstIntakeChecklist.vitamins=this._dtoVitamins;
   
    if(this.typeId==2){   
      if(this.checkValidations()){
        this.snackbar.error(constantVariables.ErrorMessage.MandatoryFieldError);
        return;
      }
    }
    if(!frmchecklist.form.valid){
      this.snackbar.error(constantVariables.ErrorMessage.MandatoryFieldError);
      return;
    }
    
  //const dataToEncrypt = this.commonService.DataToEncrypt(this.lstIntakeChecklist, false);     
    this.spinnerservice.show();
    this.patientservice.postIntakechecklist(this.lstIntakeChecklist).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        frmchecklist.resetForm();
        this.resetChecklistform();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  resetChecklistform() {
    if(this.pageId==1){
      this.router.navigate(['/patient-breifcase']);
    }
    else{
      setTimeout(() =>  window.close(),2500); // 2500 is millisecond
     // window.close();
    }
  }

  onBackClick()
  {
    if(!this.IsPatientAccount){
      window.close();
    }
    else{
       this.router.navigate(['/patientslists'])
    }
  }
  addVitamins_click(){    
    this._dtoVitamins.push({
      vitaminId: 0,
      patientAccountId: Number(this.patientAccountId),
      medications: null,
      dosage: null,
      frequency: null,
      sideEffects: null,
      patientInfoId:Number(this.patientInfoId),
      noMedicationError:false,
      noDosageError:false,
      noFrequencyError:false,
      noSideEffectsError:false
    })
  }
  removeVitamin_click(data,i){   
    if (data.vitaminId != 0) {
      this.removeExistingVitamin(data);
    }
    this._dtoVitamins.splice(i, 1);
    if(this._dtoVitamins.length==0){
      this.onAddVitamin();
    }
  }

  //To remove Existing Medicine
  removeexistMedinfo(data) {
    this.spinnerservice.show();
    this.patientservice.removePsychotropicMed(data.psychotropicMedicationId).subscribe((res: any) => {
      this.spinnerservice.hide();
      this.getPatientMedication();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  removeExistingVitamin(data){
    this.spinnerservice.show();
    this.patientservice.removeVitamin(data.vitaminId).subscribe((res: any) => {
      this.getPatientVitamins();
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  postVitamins(){
    this.spinnerservice.show();
    this.patientservice.postVitamins(this._dtoVitamins).subscribe((res)=>{
      this.getPatientVitamins();
      this.spinnerservice.hide();
    })
  }
  getPatientVitamins(){
    this.patientservice.getPatientVitamins(this.patientInfoId).subscribe((res)=>{
      if(res.length ==0){
        return;
      }
      else{
        this._dtoVitamins=res;
      }      
    })
  }

  addMedicine_click(){    
    this._dtoMedications.push({
      psychotropicMedicationId: 0,
      patientAccountId: Number(this.patientAccountId),
      medications: null,
      dosage: null,
      frequency: null,
      sideEffects: null,
      patientInfoId:Number(this.patientInfoId)
    })
  }
  removeMedicine_click(data,i){
  //  console.log(data);
    
    if (data.psychotropicMedicationId != 0) {
      this.removeexistMedinfo(data);
    }
    this._dtoMedications.splice(i, 1);
    if(this._dtoMedications.length==0){
      this.onAddMedicine();
    }
  }
  onSavePsychotropic() {

    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoMedications, false);
    this.patientservice.postPsychotropic(this._dtoMedications).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //this.snackbar.success(JSON.parse(res).message);
        this.getPatientMedication();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  checkValidations(){
    if(this.isSubmitForm){
      if(this.lstIntakeChecklist?.answers[this.medicationIndex].isAnswer){
        for (let index = 0; index < this._dtoMedications.length; index++) {
          const element = this._dtoMedications[index];
          if(element.medications==null || element.medications==''){
            element.noMedicationError=true;
          }else{
            element.noMedicationError=false;
          }

          if(element.dosage==null || element.dosage==''){
            element.noDosageError=true;
          }else{
            element.noDosageError=false;
          }

          if(element.frequency==null || element.frequency==''){
            element.noFrequencyError=true;
          }else{
            element.noFrequencyError=false;
          }

          if(element.sideEffects==null || element.sideEffects==''){
            element.noSideEffectsError=true;
          }else{
            element.noSideEffectsError=false;
          }
        }
      }
      if(this.lstIntakeChecklist?.answers[this.vitaminIndex].isAnswer){
        for (let index = 0; index < this._dtoVitamins.length; index++) {
          const element = this._dtoVitamins[index];
          if(element.medications==null || element.medications==''){
            element.noMedicationError=true;
          }else{
            element.noMedicationError=false;
          }

          if(element.dosage==null || element.dosage==''){
            element.noDosageError=true;
          }else{
            element.noDosageError=false;
          }

          if(element.frequency==null || element.frequency==''){
            element.noFrequencyError=true;
          }else{
            element.noFrequencyError=false;
          }

          if(element.sideEffects==null || element.sideEffects==''){
            element.noSideEffectsError=true;
          }else{
            element.noSideEffectsError=false;
          }
        }
      }
      var medicationValidationError=false;
      var vitaminsValidationError=false;
      var medicationValidation = this._dtoMedications.filter(function(v, i) {
        medicationValidationError=(v["noMedicationError"] == true || v["noDosageError"] == true || v["noFrequencyError"] == true || v["noSideEffectsError"] == true);
      })   
      var vitaminsValidation = this._dtoVitamins.filter(function(v, i) {
        vitaminsValidationError=(v["noMedicationError"] == true || v["noDosageError"] == true || v["noFrequencyError"] == true || v["noSideEffectsError"] == true);
      })
      if(medicationValidationError||vitaminsValidationError){
        return true;
      }
      else{        
        return false;
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { GroupTherapy } from 'src/app/_models/_Common/template';
import { TemplateService } from 'src/app/common/pages/template/template.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'src/app/common/common.service';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import * as moment from 'moment';
@Component({
  selector: 'app-group-therapy',
  templateUrl: './group-therapy.component.html',
  styleUrls: ['./group-therapy.component.scss']
})
export class GroupTherapyComponent implements OnInit {
  _dtoGrpTherapy: GroupTherapy;
  currentdate: any;
  isEditForm: boolean = false;
  constructor(public tempService: TemplateService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,public commonService: CommonService,
    private router: Router, private route: ActivatedRoute, public spinnerservice: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.currentdate = new Date();
    this._dtoGrpTherapy = new GroupTherapy();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        //this.configureFormId = Number(this.commonService.FrontEndDecryption(params.id));
        this.getGroupTherapy(Number(this.commonService.FrontEndDecryption(params.id)));
       

      }
    });
  }
  getGroupTherapy(id) {
    this.spinnerservice.show();
    this.tempService.getGroupTherapy(id).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this._dtoGrpTherapy = res[0];
          this._dtoGrpTherapy.startTime = moment(res[0].startTime).format('HH:mm');
          this.isEditForm = true;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onbacktoformlist() {
    this.router.navigate(['/consentlist'], { queryParams: { tab: 3 } });

  }
  postfrmGrpTherapy(frmTherpy: NgForm) {
    if (!frmTherpy) {
      return
    }
    this.spinnerservice.show();
    this._dtoGrpTherapy.groupTherapyId = this._dtoGrpTherapy.groupTherapyId == undefined ? 0 : String(this._dtoGrpTherapy.groupTherapyId)
    this._dtoGrpTherapy.endDate = this._dtoGrpTherapy.isEndDate == true ? this._dtoGrpTherapy.endDate : null;
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoGrpTherapy, false);
    this.tempService.postGroupTherapy(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        
        frmTherpy.resetForm();
        this.isEditForm = false;
        this.snackbar.success(res);      
        this.onbacktoformlist();

      }
    },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      }
    );
  }
  resetGrpTherapy() {
    this._dtoGrpTherapy = new GroupTherapy();
    this.isEditForm = false;
  }
}

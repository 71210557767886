import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class RoleserviceService {

  constructor(private http: HttpClient) { }
  getAllRoleMaster() {
    return this.http.get<any>(environment.BaseURI + 'Get');
  }


  saveRoleMaster(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'SaveRoleMaster',
      JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  getMastersById(data) {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: data };

    return this.http.get(environment.BaseURI + 'GetRolesById', httpOptions);

  }
  saveRole(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Role/SaveRole',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  getAllRoles() {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(environment.BaseURI + 'GetRoles', httpOptions);
  }
  getPages(roleid: any) {

    return this.http.get<any[]>(environment.BaseURI + 'GetPages?roleid=' + roleid);

  }
  // saveRoleAccess(data) {
  //   return this.http.post<any[]>(environment.BaseURI + 'SaveRoleAccess', data);
  // }
  getRoleAccess(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Role/GetRoleAccess',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  saveRoleAccess(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Role/SaveRoleAccess',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }

  getManagePermissions(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Role/GetManagePermission',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }

  saveManageAccess(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Role/SaveManageAccess',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
}

import { Component, OnInit,ViewChild, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileDetails,ProfileImageDetails } from 'src/app/_models/_ProfileDetails/_profileDetails';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { UserserviceService } from '../userservice.service';
import { DomSanitizer } from "@angular/platform-browser";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/global/custom-validators';
import { constantVariables } from 'src/app/constant/constant';
import { AuthenticationService } from 'src/app/admin/authentication.service';
import {Location} from '@angular/common';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { Global } from 'src/app/global/global.model';
import { MatDialog } from '@angular/material/dialog';
declare function EncryptFieldData(data): any;

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @ViewChild('ProfileInfoFrm') ProfileInfoFrm: NgForm;
  changepassswordForm: FormGroup;
  ProfileInfo : ProfileDetails;
  ProfileImageDeatails : ProfileImageDetails;
  fullName : string;
  role : string;
  userName : any;
  password : any;
  userId : number;
  roleNameToDisplay : string;
  isUploadProfileImg: boolean = false;
  ProfileImage = '';
  srcProfile:any;
  UploadFileList: any = [];
  imageSource : any;
  isChangePassword:boolean;
  isActivation = false;
  submitted = false;
  activationEmail = '';
  passwordid = '';
  oldPassword:any;
  Email:string;
  submitClicked=false;
  roleId:number;
  roleTypeId:number;
  signature:string;
  showChangePasswordPopup :boolean = false;
  Authid:string;
  isEmailChanged : boolean = false;
  errorMessageForSamePassword:boolean=false;
  PrimaryClinician = constantVariables.role.PrimaryClinician;
  SupportClinician = constantVariables.role.SupportClinician;
  Interns = constantVariables.role.Interns;
  uploadDocumentsId : number= 0;
  patientId: string;
  isESignAttached: boolean;
  signatureImg: any;
  updatedsignatureImg: any;
  isformtype: string;
  defaultImage: any;
  
  constructor(
    private router: Router,
     public spinnerservice: NgxSpinnerService,
     public commonService: CommonService,
     private snackbar: SnackBarService,
     private userService:UserserviceService,
     private sanitizer :DomSanitizer,
     private authenticationService: AuthenticationService,
     private _location: Location,
     public global: Global,
     private dialog: MatDialog,
     private formBuilder: FormBuilder) { }

  ngOnInit(): void {
     this.spinnerservice.show();
     this.ProfileImageDeatails = new ProfileImageDetails();
    this.ProfileInfo = new ProfileDetails();
    
    this.userId = Number(localStorage.getItem("id"));
    this.userService.getProfileInfoById(this.userId).subscribe((res:any)=>{
      this.ProfileInfo = res.result;
      this.roleNameToDisplay = res.result.roleName;
      this.fullName = res.result.firstName+" " + res.result.lastName;
      this.roleId=res.result.roleId;
      this.Authid = res.result.authid;
      this.Email = res.result.email;
      this.userName=res.result.UserName;
      this.ProfileInfo.password=res.result.password;
      this.roleTypeId = res.result.roleTypeId;
      this.uploadDocumentsId = res.result.uploadDocumentsId;
      (document.getElementById('esign_3') as HTMLImageElement).src = res.result.signature;
   
      if(res.result.profileImage != null){
        this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(res.result.profileImage);
      }else{
        if(res.result.gender == 1)
        {
          this.imageSource="../../../assets/images/f_avator.jpg";
        }else{
          this.imageSource="../../../assets/images/avator.jpg";
        }
        
      }
      this.spinnerservice.hide();
    },  err => {
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },)
    
  
  }

  OnBack(){
    this._location.back();
  }
//  GetUserDetails(){
//   this.spinnerservice.show();
//   this.userService.getProfileInfoByIdentity(this.userId).subscribe((res:any)=>{
//     this.ProfileInfo.userName =res.userName;
//     this.userName = res.userName;
//     this.ProfileInfo.password=res.password;
//     this.spinnerservice.hide();
//   },  err => {
//     this.spinnerservice.hide();
//     this.snackbar.error(err.error);
//   },)
//  }
  PostProfileInfo(){
    if(!this.ProfileInfoFrm.valid){
      return
    }else{
      this.spinnerservice.show()
      if(this.ProfileInfo.email != this.Email)
      {
        this.isEmailChanged =true;
      }
      this.ProfileInfo.oldEmail = this.Email;
      this.ProfileInfo.screenName ="UpdateEmailProfile";
     
      const dataToEncrypt = this.commonService.DataToEncrypt(this.ProfileInfo, false);
       this.userService.PostProfileInfoDetails(this.ProfileInfo).subscribe(
         res=>{
          this.spinnerservice.hide();
          this.snackbar.success(res);
          if(this.isEmailChanged)
          {
            this.updateEmail();
          }
       },
           err => {
        
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },)
      
    }
  }
  changePasswordPage()
  {
    this.router.navigate(['/changepasswordprofile']);
  }
  updateEmail()
  {
    debugger
    this.spinnerservice.show();
    let data={
      UserId : this.userId,
      OldEmail :this.Email,
      NewEmail : this.ProfileInfo.email
    };
    const dataToEncrypt = {
      ObjInputString: EncryptFieldData((JSON.stringify(data))),
    };
     this.userService.UpdateEmail(dataToEncrypt).subscribe(
       res=>{
        debugger
        this.spinnerservice.hide();
        this.snackbar.success(constantVariables.SuccessMessage.EmailUpdatedSucess);
        this.authenticationService.onLogout();
     },
         err => {
      
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },)
  }
  ChangePasswordClick()
  {
    this.showChangePasswordPopup = true;
  }
  CancelChangePassword(){
    this.showChangePasswordPopup = false;
    this.changepassswordForm.reset();
  }
  hideChangePasswordPopup() {
     this.showChangePasswordPopup = false;
     this.changepassswordForm.reset();
  }
  onImgSelected(event) {
    this.spinnerservice.show();
    const reader = new FileReader();
    var _validFileExtensions = [".jpg", ".jpeg", ".png"];
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "image/webp") {
        reader.readAsDataURL(file);

        reader.onload = () => {
            this.isUploadProfileImg = true;
            this.ProfileImage = reader.result as string;
            this.srcProfile = event.target.files[0];
            //this.UploadFileList.push(Array.from(event.target.files));
            this.UploadFileList = Array.from(event.target.files);
            this.ProfileImageDeatails.UserId=Number(this.userId);
            this.ProfileImageDeatails.ProfileImage =  this.ProfileImage;
            this.ProfileImageDeatails.ProfileImageName = this.srcProfile.name;
            const dataToEncrypt = this.commonService.DataToEncrypt(this.ProfileImageDeatails, false);
            this.userService.postProfileImage(this.ProfileImageDeatails).subscribe(
              res=>{
                this.spinnerservice.hide();
                this.snackbar.success(res);
                this.imageSource=this.sanitizer.bypassSecurityTrustResourceUrl(this.ProfileImage);
                this.commonService.UpdateProfileImage.next(false);
              },
              err=>{

              })
        
          
        };
      } else {
        this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
      }

    }
  }

  
  openDialog() {
    this.global.isconsentesign = false;
    const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
      data: { isfutureSign: false, isParentsign: true },
      autoFocus: false
   
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.isESignAttached = this.global.isconsentesign;
      if (
        this.global.isconsentesign != undefined &&
        this.global.isconsentesign
      ) {
        this.signatureImg = this.updatedsignatureImg;
        this.ProfileInfo.signature = this.updatedsignatureImg;
       // this.IncReportfrmInfo.IsAttachEsign = true;
        (document.getElementById('esign_3') as HTMLImageElement).src =
          this.signatureImg;
      } else if (
        !this.global.isconsentesign &&
        this.global.formtype != null &&
        this.global.formtype != undefined
      ) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.ProfileInfo.signature= this.global.consentesign;
        //this.IncReviewInfo.IsAttachEsign = true;
        (document.getElementById('esign_3') as HTMLImageElement).src =
          this.signatureImg;
      } else {
        this.signatureImg = this.defaultImage;
        //this.IncReportfrmInfo.IsAttachEsign = true;
        this.ProfileInfo.signature = this.defaultImage;
      }
    });
  }
}



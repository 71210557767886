import { Component, OnInit,ViewChild ,Input, inject, Inject,NgZone} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { LeadService } from 'src/app/patient-lead/lead.service'
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as moment from 'moment';
import "quill-mention";
import { notesinfo } from 'src/app/_models/_Common/notes';
import { TreatmentNotesInfo } from 'src/app/_models/_Treatment/TreatmentNotes'
import { cloneDeep } from "lodash";
import { NotesDialogComponent } from 'src/app/common/notes-dialog/notes-dialog.component';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { publicDecrypt } from 'crypto';
import {CdkTextareaAutosize, TextFieldModule} from '@angular/cdk/text-field';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-treatment-notes',
  templateUrl: './treatment-notes.component.html',
  styleUrls: ['./treatment-notes.component.scss']
})

export class TreatmentNotesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() allrecord :any;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  
  TreatmentNotesForm=FormGroup;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  dataSource: MatTableDataSource<any>;

  notes: notesinfo;
  treatmentnotes:TreatmentNotesInfo;
  parentlst: any;
  patientInfoId: any;
  patientnotes: any;
  patientnotes1: any;
  item:any;
  lstNoteComment: any;
  isEditNotes: boolean = false;
  notesids: any = [];
  atValues: any = [];
  Decrptedlst:any;
  
  DecrptedCommentlst:any;
  pagefrom:any;
  usertags:any=[];
  isCreateNotes: boolean = false;
  IsAdd:boolean=true;
  IsUpdate:boolean=false;
  roleAccess: RoelAccess;
  Form=FormGroup;
  editform:any;  
  dataSourceComments: MatTableDataSource<any>;  
  @ViewChild('commentpaginator') paginatorimport: MatPaginator;
  treatmentnotesform: TreatmentNotesInfo = new TreatmentNotesInfo();
  constructor(@Inject(MAT_DIALOG_DATA) public data:any, private ref:MatDialogRef<TreatmentNotesComponent>,public leadservice: LeadService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, 
    public roleaccessservice:PageAccessService,private fb:FormBuilder,private _ngZone: NgZone) { 
 
    }
  

    ngOnInit(): void {

      this.patientInfoId = this.data.allrecord;
      console.log(this.data.allrecord);    
      this.getNotes();
     
    }
    onSubmit(form:NgForm){

      this.treatmentnotesform.patientInfoId = this.patientInfoId;
      this.treatmentnotesform.notesId=0;
      console.log(this.treatmentnotesform);
      this.saveEmployee();
      form.resetForm();
    }
    saveEmployee(){
      if(this.IsAdd==true)
      {
        const dataToEncrypt = this.commonService.DataToEncrypt(this.treatmentnotesform, false);
      this.leadservice.PostTreatmentNotes(dataToEncrypt).subscribe( (res: any) =>{
        console.log(res);
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.getNotes();
       
       // this.reset();
      },
      error => console.log(error));

      }
      else
      {
        this.treatmentnotesform.patientInfoId=this.patientInfoId;
    this.treatmentnotesform.notesId=this.editform.notesId;

      const dataToEncrypt = this.commonService.DataToEncrypt(this.treatmentnotesform, false);
      this.leadservice.EditTreatmentNotes(dataToEncrypt).subscribe( (res: any) =>{
        console.log(res); 
     
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.getNotes();
        this.IsAdd=true;
        this.IsUpdate=false;
        this.reset();
      
      },
      error => console.log(error));
      }
      
    }
    deleteNote(item)
    {
    
      this.leadservice.deleteTreatmentNotes(item.notesId).subscribe( (res: any) =>{
        console.log(res);
        this.spinnerservice.hide();
        this.snackbar.success(res.message);
        this.getNotes();  
        this.reset();
        this.IsAdd=true;
        this.IsUpdate=false;
      },
      error => console.log(error));
    }
    OpenEdit(item)
    {
  
      this.treatmentnotesform.notesContent=item.notesContent;
      this.treatmentnotesform.notesSubject=item.notesSubject;
      this.treatmentnotesform.notesId=item.notesId;
      this.IsAdd=false;
      this.IsUpdate=true;
      this.editform=item;

    }
    EditNote()
    {
     if(this.treatmentnotesform.notesContent=="") 
     {
// alert("Enter valid Content");
return;
     }
     if(this.treatmentnotesform.notesSubject=="") 
     {
//alert("Enter valid Subject");
return;
     }
       
    this.treatmentnotesform.patientInfoId=this.patientInfoId;
    this.treatmentnotesform.notesId=this.editform.notesId;

      const dataToEncrypt = this.commonService.DataToEncrypt(this.treatmentnotesform, false);
      this.leadservice.EditTreatmentNotes(dataToEncrypt).subscribe( (res: any) =>{
        console.log(res); 
     
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.getNotes();
        this.IsAdd=true;
        this.IsUpdate=false;
        this.reset();
      
      },
      error => console.log(error));
    }
  reset(){
    this.treatmentnotesform.notesContent="";
      this.treatmentnotesform.notesSubject="";
   

  }
  getNotes() {
    this.leadservice.getTreatmentNotes(this.patientInfoId).subscribe(
      (res: any) => {
        if (res) {


          this.DecrptedCommentlst = res;
          this.patientnotes1 = this.DecrptedCommentlst.slice(0, 5);
         // console.log(this.lstNoteComment)
          this.dataSourceComments = new MatTableDataSource(this.DecrptedCommentlst);
          this.dataSourceComments.paginator = this.paginatorimport;
        // //  this.Decrptedlst = res;
        //   this.patientnotes1 = res;
        //   // this.dataSource = new MatTableDataSource(this.Decrptedlst);
        //   // this.dataSource.paginator = this.paginator;
        // //  this.isCreateNotes=true;
        // console.log(this.patientnotes1);
        // console.log(res);

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  
closepopup()
{
  this.ref.close();
}

  openDialog(data) {
    //console.log(this.patientInfoId)
    //console.log(data.notesId)
    const dialogRef = this.dialog.open(TreatmentNotesComponent,
      { data: { parentinfoId: this.patientInfoId, notesId : data.notesId} });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onPageChange($event) {
    this.patientnotes1 = this.DecrptedCommentlst.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }

}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from 'src/app/admin/authentication.service';
import { CommonService } from 'src/app/common/common.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/app/global/global.model';
import { constantVariables } from 'src/app/constant/constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MenuList } from 'src/app/_models/_Common/roleAccessMaster';
import { DomSanitizer } from "@angular/platform-browser";
declare function EncryptFieldData(data): any;


@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],

})
export class SiteHeaderComponent implements OnInit {
  statusFinancial = constantVariables.status.financial;
  statusreviewTour = constantVariables.status.reviewTour;
  statusforIntake = constantVariables.status.forIntake;
  subMaster: string = '';
  roleId: any;
  profileOpen = false;
  notificationOpen = false;
  patientId: any;
  UserName: any;
  messageCount: any;
  isView = false;
  isViewTemplate = false;
  isViewGoal = false;
  LeadStatus: any;
  patientAccountId: any;
  ddlSfccLocation: any;
  isDisplayRecptIcon = false;
  UserLocation: any;
  MenuAccess: MenuList;
  userId:number;
  imageSource : any;
  constructor(private http: HttpClient, private authenticationService: AuthenticationService, private changeDetectorRef: ChangeDetectorRef,private sanitizer :DomSanitizer,
    private router: Router, public commonService: CommonService, public global: Global, public spinnerservice: NgxSpinnerService,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    this.getManageAccess();
    var access = JSON.parse(localStorage.getItem('roleaccess'));
    if(!!localStorage.getItem('location')){
      var location = JSON.parse(localStorage.getItem('location'));
      if(location!=null){
        this.UserLocation = location.split(',').map(Number);
      }
      this.commonService.UpdateProfileImage.subscribe((res)=>{
        this.getProfileImage();
     
     });
    }
   ///Old Role Access method
    // const pageid = constantVariables.pagelists.Task;
    // var roleaccess = access != null ? access.details.find(x => x.pageId == pageid) : null;
    // if (roleaccess != null) {
    //   this.isView = roleaccess.isView;
    // }
    // else {
    //   this.isView = true;
    // }
    // var roletemplateaccess = access != null ? access.details.find(x => x.pageId == constantVariables.pagelists.Templates) : null;
    // if (roletemplateaccess != null) {
    //   this.isViewTemplate = roletemplateaccess.isView;
    // }
    // else {
    //   this.isViewTemplate = true;
    // }
    // var rolegoalaccess = access.details.find(x => x.pageId == constantVariables.pagelists.Goal);
    // if (roleaccess != null) {
    //   this.isViewGoal = rolegoalaccess.isView;
    // }
    // else {
    //   this.isViewGoal = true;
    // }
    this.MenuAccess = this.roleaccessservice.GetSiteHeaderAccess();
    this.roleId = localStorage.getItem("roleId");
    this.patientId = localStorage.getItem("patientId");
    this.UserName = localStorage.getItem("username");
    this.global.messagecount = localStorage.getItem("messageCount");
    this.global.LeadStatus = localStorage.getItem("currentstatus");
    this.LeadStatus = localStorage.getItem("currentstatus");
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.global.ddlsfcclocation = Number(localStorage.getItem("sfcclocation")) == 0 ? -1 : Number(localStorage.getItem("sfcclocation"));
    this.isDisplayRecptIcon=(constantVariables.role.Lead=="4")?true:false;
    this.getsfcclocation();
    this.getProfileImage();
  }

  getProfileImage(){
    this.spinnerservice.show();
    this.userId = Number(localStorage.getItem("id"));
    this.commonService.getProfileImage(this.userId).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          if(res.profileImage != null){
            this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(res.profileImage);
          }else{
            if(res.gender == 1)
            {
              this.imageSource="../../../assets/images/f_avator.jpg";
            }else{
              this.imageSource="../../../assets/images/avator.jpg";
            }
            
          }
          
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }

  isActive(routePath: string) {
    return this.router.url == routePath;
  }
  getsfcclocation() {
    this.commonService.getSfccLocation().subscribe(
      res => {
        if (res != null) {

          this.ddlSfccLocation = res;
          var tempddlSfccLocation = this.ddlSfccLocation;
        if(this.roleId != 1 && this.UserLocation!= null){
          var locationarray = []
          //locationarray.push(this.ddlSfccLocation[0])
          for(var item of this.UserLocation){
            locationarray.push((tempddlSfccLocation.filter(x=>x.sfccMasterId == item))[0])
          }
          if(locationarray.length>1){
            locationarray.splice(0,0,this.ddlSfccLocation[0])
          }
          else{
            this.global.ddlsfcclocation = locationarray[0].sfccMasterId;
            this.OnsetLocation(locationarray[0].sfccMasterId.toString());
          }
        
          this.ddlSfccLocation = locationarray;
         
        }       
          this.changeDetectorRef.detectChanges();
          
        }
      },
      err => {

      },
    );
  }
  onChangeLocation(event) {
     let _obj = {
      UserId: Number(this.patientId),
      SfccmasterId: Number(event.value),
      UserName: localStorage.getItem("email")
    }
    this.spinnerservice.show();
    localStorage.setItem('sfcclocation', event.value)
    const dataToEncrypt = {
      ObjLogin: EncryptFieldData(JSON.stringify(_obj))
    };
    this.commonService.setLocation(dataToEncrypt).subscribe((res: any) => {
      if (res) {

        this.spinnerservice.hide();
        localStorage.setItem('token', res.token);
        window.location.reload();

      }
    },
      err => {
        this.spinnerservice.hide();
      }
    );

  }
  toggleProfile() {
    //this.profileOpen = !this.profileOpen;
  }
  toggleNotification() {
    //this.notificationOpen = !this.notificationOpen;
  }
  onlogout() {
    this.authenticationService.onLogout();
  }
  onClickLead() {

    if (Number(this.roleId) == 4) {
      this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(this.patientId) } });
    } else {
      //localStorage.setItem('sfcclocation',"-1");
      this.router.navigateByUrl('/leadlist');
    }
  }
  onClickMessages() {
    this.router.navigateByUrl('/messages');
  }
  onClickDocument() {
    this.router.navigateByUrl('/patient-documents');
  }
  onlogoClick() {
    if (Number(this.roleId) == 4) {
      this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(this.patientId) } });
    } if (Number(this.roleId) == 5 || Number(this.roleId) == 6) {
      this.router.navigate(['/clinician']);
    }
    else if (Number(this.roleId) == 1 || Number(this.roleId) == 3) {
      this.router.navigateByUrl('/leadlist');
    }
  }
  // menuClick(mnuId)
  // {
  //   if(mnuId==1)
  //   {
  //   this.router.navigate(['/scheduler']);
  //   }
  //   else{
  //     if(localStorage.getItem('sfcclocation')!="-1")
  //         {
  //         localStorage.setItem('sfcclocation',"-1");
  //         this.onChangeLocation(-1);
  //         }
  //       if(mnuId==2){
  //         this.router.navigate(['/leadlist']);
  //         }
  //       else if(mnuId==3){
  //         this.router.navigate(['/patient']);
  //       }
  //       else if(mnuId==4){
  //         this.router.navigate(['/patient-breifcase']);
  //       }
  //       else if(mnuId==5){
  //         this.onClickLead();
  //       }
  //       else if(mnuId==6){
  //           this.router.navigate(['/notes']);
  //       }
  //       else if(mnuId==7){
  //         this.router.navigate(['/task-info']);
  //       }
  //       else if(mnuId==8){
  //         this.router.navigate(['/goal-list']);
  //       }
  //       else if(mnuId==9){
  //         this.router.navigate(['/consentlist']);
  //       }
  //       else if(mnuId==10){
  //         this.router.navigate(['/bill-lists']);
  //       }
  //       else if(mnuId==11){
  //         this.router.navigate(['/billing-masters']);
  //       }
  //       else if(mnuId==12){
  //         this.router.navigate(['/settings']);
  //       }
  //       else if(mnuId==13){
  //         this.router.navigate(['/user-manage']);
  //       }
  // }
    
  // }
  // onChangeLocation(val)
  // {
    
  //   this.onChangeLocationformenuclick(this.ddlSfccLocation.find(x=>x.sfccMasterId==val));
  // }

  // onChangeLocationformenuclick(event) {
  //   let _obj = {
  //     UserId: Number(this.patientId),
  //     SfccmasterId: Number(event.sfccMasterId),
  //     UserName: localStorage.getItem("email")
  //   }
  //   this.spinnerservice.show();
  //   localStorage.setItem('sfcclocation', event.sfccMasterId)
  //   const dataToEncrypt = {
  //     ObjLogin: EncryptFieldData(JSON.stringify(_obj))
  //   };
  //   this.commonService.setLocation(dataToEncrypt).subscribe((res: any) => {
  //     if (res) {
  //       this.spinnerservice.hide();
  //       localStorage.setItem('token', res.token);
  //       window.location.reload();

  //     }
  //   },
  //     err => {
  //       this.spinnerservice.hide();
  //     }
  //   );

  // }

  OnsetLocation(value){
    let _obj = {
      UserId: Number(this.patientId),
      SfccmasterId: Number(value),
      UserName: localStorage.getItem("email")
    }
    this.spinnerservice.show();
    localStorage.setItem('sfcclocation', value)
    const dataToEncrypt = {
      ObjLogin: EncryptFieldData(JSON.stringify(_obj))
    };
    this.commonService.setLocation(dataToEncrypt).subscribe((res: any) => {
      if (res) {

        this.spinnerservice.hide();
        localStorage.setItem('token', res.token);
       // this.router.navigateByUrl('/leadlist');
       // window.location.reload();

      }
    },
      err => {
        this.spinnerservice.hide();
      }
    );

  }
  onClickReceptLists()
  {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/patient-invoice-lists'], { queryParams: { id: this.commonService.FrontEndEncryption(Number(localStorage.getItem("patientAccountId"))) } }));
    window.open(url, '_blank');
  }
  ProfilePage()
  {
    this.router.navigate(['/profile']);
  }
  changePasswordPage()
  {
    this.router.navigate(['/changepasswordprofile']);
  }
  getManageAccess(){
    let id = localStorage.getItem("id");
    this.spinnerservice.show();
    this.commonService.GetManageAccessByid(id).subscribe((res: any) => {
      this.spinnerservice.hide();
      if (res) {

        this.spinnerservice.hide();
        localStorage.setItem('roleaccess', JSON.stringify(res));
      }
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  Documentviewforclient(){
    this.router.navigate(['/client-documents']);
  }
}

import { Component, OnInit, Input, ViewChild, EventEmitter, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { TreatmentDiagosis } from 'src/app/_models/_Treatment/_diagnosis';
import { TreatmentComments } from 'src/app/_models/_Treatment/_diagnosis';


import { PatientService } from 'src/app/patient/patient.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { X } from '@angular/cdk/keycodes';
import { FilterPipe } from 'src/app/common/_directive/filter.pipe';
import { Comment } from 'src/app/_models/_Common/task';
import { tr } from 'date-fns/locale';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-treatmentdiagnosis',
  templateUrl: './treatmentdiagnosis.component.html',
  styleUrls: ['./treatmentdiagnosis.component.scss'],
  // declarations : [FilterPipe]
})
export class TreatmentdiagnosisComponent implements OnInit {
  @ViewChild('DCode') DCode: MatSelect;
  @ViewChild('frmDiagnosis') frmDiagnosis: NgForm;
  @Input() patientAccountId: number;
  @Input() patientInfoId: number;
  @Input() locationId: number;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  //@Output() formChanged = new EventEmitter<boolean>();
  chmaTreatmentSummaryId: number;
  // ddlTherapy: any;
  // ddlFrequency: any;
  _dtoDiagnosis: TreatmentDiagosis = new TreatmentDiagosis();
  _dtoTreatmetComments: TreatmentComments = new TreatmentComments();
  _dtoModality: any = [];
  _sudicialthoughts: any;
  _homicidalthoughts: any;
  _dtoMedications: any = [];
  _dtoMedicationsPopUp: any = [];
  _dtoComments: any = [];

  lstreportstaff: any = [];
  _diagnosisCode = [];
  isAssigned = false;
  //majorProblem:any;
  ddldiagnosiscode: any;
  isTreatmentDiagnosisFormDisabled: boolean = false;
  roleId: number;
  providerName: string;
  roleAccess: RoelAccess;
  ddlPrimarystaff: any;
  majorProblemAreas = [];
  majorProblemArea1: any = [];
  PresentingProblems: any;
  isOtherSelected: boolean = false;
  isMajorProblemAreaError: boolean = false;
  showMedicationsPopup: boolean = false;
  showCommentsPopup: boolean = false;
  showRecommandationPopup: boolean = false;
  title: string = '';
  Header: string = '';
  Addtitle: string = '';
  AddHeader: string = '';
  CommentsidAll: number;
  submitted = false;
  NoRecord: string = '';
  //@Input() _dtoDiagnosis: TreatmentDiagosis ;

  filterBy = '';
  selectedToDate: any;
  TreatmentPlanCommentsId: any;
  _dtoCommentdetails: any;
  _dtotreatmentPlanCommentsId: number;
  Commentid: any;

  constructor(public global: Global, public treatmentservice: TreatmentService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public patientservice: PatientService,
    public roleaccessservice: PageAccessService, public datepipe: DatePipe) {


  }

  ngOnInit(): void {
    // this._dtoDiagnosis = new TreatmentDiagosis();
    this.roleId = Number(localStorage.getItem("roleId"));


    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.account).replace(/"/g, ""));
        this.chmaTreatmentSummaryId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patient).replace(/"/g, ""));
        this.locationId = Number(this.commonService.FrontEndDecryption(params.location).replace(/"/g, ""));
      }
      this.getDiagnosisCodeMster();
      //this.getTreatmentPlanDiagnosis();
      // this.getTreatmentPlanMaster();
      this.getStaffList();
      this.getPsychotropicMedication();
      this.getPrimaryStaff();
      // this.appendModality(1);
      this.sudicialthoughts();
      this.homicidalthoughts();
      // if(this.roleId==1){
      //   this.isTreatmentDiagnosisFormDisabled=true;
      // }
      const pageid = constantVariables.pagelists.TreatmentPlan;
      this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    });
    this.majorProblemAreas = [
      { key: '0', text: 'ADHD', isActive: false },
      { key: '1', text: 'Depression', isActive: false },
      { key: '2', text: 'SIB', isActive: false },
      { key: '3', text: 'Anxiety', isActive: false },
      { key: '4', text: 'ODD', isActive: false },
      { key: '5', text: 'OCD', isActive: false },
      { key: '6', text: 'Other', isActive: false }

    ];

    this.frmDiagnosis.form.valueChanges.subscribe(X => {
      console.log(X)
      this.global.frmPatientDetails = true;
    })

  }
  onChangedosTo(event) {
    this.selectedToDate = this.datepipe.transform(event.value, "yyyy-MM-dd");
  }

  getDiagnosisCodeMster() {
    this.commonService
      .getDiagonosisCodeMaster()
      .subscribe((res: any) => {
        if (res.objInputString != null) {
          let fres = this.commonService.decrypdata(res.objInputString);
          if (fres.length > 0) {
            this.ddldiagnosiscode = fres;
          }
        }
        this.getTreatmentPlanDiagnosis();
      },
        err => {
          this.spinnerservice.hide();
        });
  }
  getStaffList() {
    this.treatmentservice.getProviderList(this.patientInfoId).subscribe(
      (res: any) => {
        if (res) {
          this.lstreportstaff = res;
          this.lstreportstaff = this.lstreportstaff.filter(x => (x.firstName != "" && x.lastName != ""))
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getTreatmentPlanDiagnosis() {
    this.spinnerservice.show();

    this.treatmentservice.getTreatmentPlanDiagnosis(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        var result: any = res;
        if (result.dtoTreatmentPlanDiagnosis) {
          this._dtoDiagnosis = result.dtoTreatmentPlanDiagnosis;
          this._dtoDiagnosis.isSuicidal = this._dtoDiagnosis?.isSuicidal ?? true;
          this._dtoDiagnosis.isHomicidal = this._dtoDiagnosis?.isHomicidal ?? true;
        }
        if (result.dtoTreatmentDiagnosis && result.dtoTreatmentDiagnosis.length > 0) {

          // this._dtoDiagnosis.presentingProblems = res.dtoTreatmentPlanDiagnosis.presentingProblems;
          localStorage.setItem('TreatmentplanProvider', result.dtoTreatmentPlanDiagnosis.providerName);
          this.treatmentservice.DiagnosisProvider.next(false)
          this._sudicialthoughts = JSON.parse(result.dtoTreatmentPlanDiagnosis.suicidalDetails);
          this._homicidalthoughts = JSON.parse(result.dtoTreatmentPlanDiagnosis.homicidalDetails);
          if (res.dtoTreatmentPlanDiagnosis.majorProblemArea.includes('6')) {
            this.isOtherSelected = true;
          } else {
            this.isOtherSelected = false;
          }
          var majorProblem = res.dtoTreatmentPlanDiagnosis.majorProblemArea.split(',');

          var majorProblemList = [];

          majorProblem.map(p => {

            majorProblemList.push(p);


          })
          this.majorProblemArea1 = majorProblemList;
          this._dtoDiagnosis.majorProblem_Area = majorProblemList
          if (result.dtoTreatmentModality.length > 0) {
            this._dtoModality = result.dtoTreatmentModality;
          }
          this._dtoDiagnosis.diagnosisCode = res.dtoTreatmentDiagnosis;
          this._dtoDiagnosis.diagnosisCode = [];
          this.ddldiagnosiscode.forEach(x=>x.isActive=false);
          for (let i = 0; i < result.dtoTreatmentDiagnosis.length; i++) {
            if (result.dtoTreatmentDiagnosis[i].isActive == true) {
              this._dtoDiagnosis.diagnosisCode[i] = result.dtoTreatmentDiagnosis[i].diagnosisCode;
              const index = this.ddldiagnosiscode.findIndex(x => x.value === result.dtoTreatmentDiagnosis[i].diagnosisCode)
              this.ddldiagnosiscode[index].isActive = true;
            }
          }


        }

      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  // getTreatmentPlanMaster() {
  //   this.spinnerservice.show();

  //   this.treatmentservice.getTreatmentPlanMaster().subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       this.ddlTherapy = this.commonService.decrypdata(res.therapy);
  //       this.ddlFrequency = this.commonService.decrypdata(res.frequency);

  //     },
  //     err => {
  //       this.spinnerservice.hide();

  //     },
  //   );
  // }
  getPsychotropicMedication() {
    this.patientservice.getPsychotropicMedication(this.patientInfoId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res && res.length > 0) {
          this._dtoMedications = cloneDeep(res);
        } else {
          // this.onAddMedication();
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  // appendModality(loop) {

  //   for (let i = 0; i < loop; i++) {
  //     this._dtoModality.push({
  //       treatmentModalityId: 0,
  //       chmatreatmentSummaryId: this.chmaTreatmentSummaryId,
  //       patientAccountId: this.patientAccountId,
  //       therapyId: null,
  //       frequencyId: null,

  //     })
  //   }
  // }
  // onAddMedication() {
  //   if (this._dtoMedications == null || this._dtoMedications.length == 0) {
  //     this._dtoMedications = [];
  //     for (let i = 0; i < 2; i++) {
  //       this._dtoMedications.push({
  //         psychotropicMedicationId: 0,
  //         patientInfoId: this.patientInfoId,
  //         medications: null,
  //         dosage: null,
  //         frequency: null,
  //         sideEffects: null
  //       })
  //     }
  //   }
  // }
  addMedicationInfo() {
    this._dtoMedications.push({
      psychotropicMedicationId: 0,
      patientInfoId: this.patientInfoId,
      medications: null,
      dosage: null,
      frequency: null,
      sideEffects: null
    })
  }
  removeclinicalinformation(data, index) {
    if (data.psychotropicMedicationId != 0) {
      this.removeexistMedinfo(data);
    }
    this._dtoMedications.splice(index, 1);
  }
  removeexistMedinfo(data) {
    this.patientservice.removePsychotropicMed(data.psychotropicMedicationId).subscribe((res: any) => {
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  // removeTreatmentModality(data) {
  //   this.treatmentservice.removeTreatmentModality(data.treatmentModalityId).subscribe((res: any) => {
  //     this.spinnerservice.hide();
  //   },
  //     err => {
  //       this.spinnerservice.hide();
  //     }
  //   );
  // }
  onSavePsychotropic() {
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoMedications, false);
    this.patientservice.postPsychotropic(this._dtoMedications).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.getPsychotropicMedication();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  sudicialthoughts() {
    this._sudicialthoughts = { Attempt: null, Intent: null, Plan: null, History: null };
  }
  homicidalthoughts() {
    this._homicidalthoughts = { Attempt: null, Intent: null, Plan: null, History: null };
  }
  // removemodality(data, index) {
  //   if (data.treatmentModalityId != 0) {
  //     this.removeTreatmentModality(data);
  //   }
  //   this._dtoModality.splice(index, 1);
  // }
  onDiagnosisCodeselect(item, event) {

    item.isActive = event;


  }
  onRemoveDiagnosisCode(item) {
    // let index = this._dtoDiagnosis.diagnosisCode.findIndex(d => d === item.value); //find index in your array
    // this._dtoDiagnosis.diagnosisCode.splice(index, 1);
    this.DCode.options.forEach((x: MatOption, i) => {
      if (x.value == item.value) {
        x.deselect();
      }
    });
    item.isActive = false;
  }
  onSelection(event) {
    if (event.length > 0) {
      this.isMajorProblemAreaError = false;
    }
    if (event.includes('6')) {
      this.isOtherSelected = true;
    } else {
      this.isOtherSelected = false;
    }
  }

  postTreatmentPlanDiagnosis(frmDiagnosis: NgForm) {
    // if(!this._dtoDiagnosis.presentingProblems 
    // || !this._dtoDiagnosis.currentDsm 
    // || !this._dtoDiagnosis.recommendedCourse 
    // || !this._dtoDiagnosis.homeRecommendations
    // || !this._dtoDiagnosis.outsideRecommendations
    // || !this._dtoDiagnosis.clinicalChallenges)
    // {
    //   return;
    // }
    if (this.isOtherSelected && !this._dtoDiagnosis.majorProblem) {
      return;
    }
    if ((this.majorProblemArea1 == undefined || this.majorProblemArea1.length == 0)) {
      this.isMajorProblemAreaError = true;
    } else {
      this.isMajorProblemAreaError = false;
    }
    if (!frmDiagnosis.valid || this.isMajorProblemAreaError == true) {
      this.snackbar.error("Please fill mandatory field");
      return
    }
    var majarProblemString;
    this.majorProblemArea1?.map((cli, index) => {

      if (index == 0) {

        majarProblemString = cli.toString()

      } else {

        majarProblemString = majarProblemString + ',';

        majarProblemString = majarProblemString + cli;

      }

    })
    this._dtoDiagnosis.majorProblemArea = majarProblemString;
    // this._dtoDiagnosis.presentingProblems = this.PresentingProblems;
    this._dtoDiagnosis.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
    this._dtoDiagnosis.patientAccountId = this.patientAccountId;
    this._dtoDiagnosis.suicidalDetails = JSON.stringify(this._sudicialthoughts);
    this._dtoDiagnosis.homicidalDetails = JSON.stringify(this._homicidalthoughts);
    // const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoDiagnosis, false);
    this.spinnerservice.show();
    this.treatmentservice.postTreatmentPlanDiagnosis(this._dtoDiagnosis).subscribe(
      (res: any) => {
        this.postDiagnosis();
        ////this.postModality();
        this.onSavePsychotropic();

        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.treatmentservice.TreatmentPlanStatus.next(false)
        //  window.location.reload();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }


  postDiagnosis() {
    this._diagnosisCode = []
    for (let i = 0; i < this.ddldiagnosiscode.length; i++) {
      let obj = {
        ChmatreatmentSummaryId: this.chmaTreatmentSummaryId,
        PatientAccountId: this.patientAccountId,
        DiagnosisCode: this.ddldiagnosiscode[i].value,
        IsActive: this.ddldiagnosiscode[i].isActive,
      }
      this._diagnosisCode.push(obj);
    }
    // const dataToEncrypt = this.commonService.DataToEncrypt(this._diagnosisCode, false);
    this.spinnerservice.show();
    this.treatmentservice.postTreatmentDiagnosis(this._diagnosisCode).subscribe(
      (res: any) => {
        this.frmDiagnosis.resetForm();
        this.getTreatmentPlanDiagnosis();
        this.treatmentservice.InitModalityPage.next(false);
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  // postModality() {
  //   const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoModality, false);
  //   this.spinnerservice.show();
  //   this.treatmentservice.postTreatmentModality(dataToEncrypt).subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();


  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //       this.snackbar.error(err.error);
  //     },
  //   );
  // }

  onSelectionChanged() {
    this._dtoDiagnosis.providerName = ''
    if (this._dtoDiagnosis.providerId != -1) {
      let Provider = this.lstreportstaff.filter(x => x.userId == this._dtoDiagnosis.providerId)[0];
      this._dtoDiagnosis.providerName = Provider.firstName + ' ' + Provider.lastName;
      localStorage.setItem('TreatmentplanProvider', this._dtoDiagnosis.providerName);
      // this.providerName = Provider.firstName+' '+Provider.lastName
      this.treatmentservice.DiagnosisProvider.next(false)
    }

  }

  OnProviderNameChnage(val) {
    localStorage.setItem('TreatmentplanProvider', this._dtoDiagnosis.providerName);
    this.treatmentservice.DiagnosisProvider.next(false)
  }
  getPrimaryStaff() {
    this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician, this.locationId).subscribe(
      (res: any) => {
        if (res) {
          this.ddlPrimarystaff = res;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  generatePdf() {
    this.spinnerservice.show();
    this.commonService.getDiagnosisInformationPdf(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
          console.log(res);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  majorproblemchange(event) {
    console.log(event)
  }

  getPsychotropicMedication_V1() {
    this.patientservice.getPsychotropicMedication_V1(this.patientInfoId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res && res.length > 0) {
          this._dtoMedicationsPopUp = cloneDeep(res);
        } else {
          // this.onAddMedication();
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  ViewMedicationHistory() {
    this.spinnerservice.show();
    this.getPsychotropicMedication_V1();
    this.showMedicationsPopup = true;

  }
  ViewComments(Commentid)
  {
    this._dtoComments='';
    this.showRecommandationPopup=true;
    this.Commentid = Commentid; 
    this.getTreatmentPlanComments(Commentid);
    this.title="MTP Updates";
    this.Header="MTP Updates";
    this.NoRecord="";
    
  }
  OncloseMedicationpopup() {
    this.showMedicationsPopup = false;
    this.NoRecord = "";

  }
  OncloseRecommandationpopup() {
    this.showRecommandationPopup = false;
    this.NoRecord = "";

  }
  getFrequencyLabel(frequency: string): string {
    switch (frequency) {
      case "1":
        return 'Daily';
      case "2":
        return 'Weekly';
      case "3":
        return 'Monthly';
      default:
        return '';
    }
  }
  getTreatmentPlanComments(Commentid) {
    this.spinnerservice.show();
    this.patientservice.getTreatmentPlanComments(this.patientInfoId, Commentid, 0).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res && res.length > 0) {
          this._dtoComments = cloneDeep(res);
        } else {
          // this.onAddMedication();
          this.NoRecord = "No record found";
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getTreatmentPlanCommentsById(Commentid) {
    this.patientservice.getTreatmentPlanCommentsById(this.patientInfoId, Commentid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res) {
          this._dtoCommentdetails = res;
          console.log(this._dtoCommentdetails);
          this._dtoTreatmetComments.commentUpdatedate = this._dtoCommentdetails.createdDate;
          this._dtoTreatmetComments.commentsName = this._dtoCommentdetails.commentDetails;
          
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
   
  }

  showCommentsPopupFun(Commentsid) {
    this.NoRecord = "";
    this._dtoComments = '';
    this.CommentsidAll = Commentsid;
    this.showCommentsPopup = true;
    this.Addtitle = "MTP Updates";
    this.AddHeader = "MTP Updates";

  }
  ondeleteTreatmentPlanComments(treatmentPlanCommentsId) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.treatmentservice.onDeletetreatmentcomment(treatmentPlanCommentsId).subscribe(
          (res: any) => {
            console.log(res)
            this.spinnerservice.hide();
            this.snackbar.success(constantVariables.SuccessMessage.DeletetedSuccesscully);
            this. ViewComments(this.Commentid);
          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },
        );
      }
    });
  }
  
  postTreatmentPlanComments(form: NgForm) {
    this._dtoTreatmetComments.commentsName = this._dtoTreatmetComments.commentsName;
    this._dtoTreatmetComments.commentUpdatedate = this._dtoTreatmetComments.commentUpdatedate;
    this._dtoTreatmetComments.targetSymptomId = 0;
    this._dtoTreatmetComments.treatmentPlanCommentsId = this._dtotreatmentPlanCommentsId;

    this._dtoTreatmetComments.treatmentDiagosisId = 328;
    this._dtoTreatmetComments.commentsId = this.CommentsidAll;
    this._dtoTreatmetComments.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
    this._dtoTreatmetComments.patientAccountId = this.patientAccountId;

    this.spinnerservice.show();
    this.treatmentservice.postTreatmentPlanComments(this._dtoTreatmetComments).subscribe(
      (res: any) => {

        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.treatmentservice.TreatmentPlanStatus.next(false)
        //this.getTreatmentPlanComments(this.CommentsidAll);

        //  window.location.reload();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
    // this.reset();
    this._dtoTreatmetComments.treatmentPlanCommentsId = null;
    form.resetForm();
    this.showCommentsPopup = false;


  }
  OncloseCommentpopup(form: NgForm) {
    this.showCommentsPopup = false;
    form.resetForm();

  }
  editCommentPopup(treatmentPlanCommentsId) {
    this.OncloseRecommandationpopup();
    this.showCommentsPopup = true;
    this._dtotreatmentPlanCommentsId = treatmentPlanCommentsId;
    this.getTreatmentPlanCommentsById(this._dtotreatmentPlanCommentsId);

  }
  reset() {
    this._dtoTreatmetComments.commentsName = "";
    //this._dtoTreatmetComments.dateComment ="";
  }
}
function Output(): (target: TreatmentdiagnosisComponent, propertyKey: "formChanged") => void {
  throw new Error('Function not implemented.');
}



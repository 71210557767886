import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'grdFilter'
})
export class gridFilterPipe implements PipeTransform {
    transform(value: any[], term: string, name?: string): any[] {
      if (name == "BillLists") {
            if (term === undefined || term === null) return value;
         return value.filter((x: any) =>x.PatientName.toLowerCase().indexOf(term.toLowerCase()) !== -1);
          }
        else  if (name == "CreateBillLists") {
            if (term === undefined || term === null) return value;
         return value.filter((x: any) =>x.PatientName.toLowerCase().indexOf(term.toLowerCase()) !== -1);
          }
          else  if (name == "IncidentreportList") {
            if (term === undefined || term === null || term === "") return value;
         return value.filter((x: any) =>x.patientName.toLowerCase().indexOf(term.toLowerCase()) !== -1);
          } 
         else if (name == "claimLists") {
            if (term === undefined || term === null) return value;
         return value.filter((x: any) =>x.PatientName.toLowerCase().indexOf(term.toLowerCase()) !== -1);
          }
          else if (name == "lstPatient") {
            if (term === undefined || term === null) return value;
         return value.filter((x: any) =>x.FirstName.toLowerCase().indexOf(term.toLowerCase()) !== -1 ||
             x.LastName.toLowerCase().indexOf(term.toLowerCase())  !== -1);
          }
          else if (name == "AdminPatientLists") {
            if (term === undefined || term === null) return value;
         return value.filter((x: any) =>x.FirstName.toLowerCase().indexOf(term.toLowerCase()) !== -1);
          }
          
          
        }
}


import { Component, OnInit, ViewChild } from '@angular/core';
import { TemplateService } from 'src/app/common/pages/template/template.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { template } from 'src/app/_models/_Common/template';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { log } from 'console';
@Component({
  selector: 'app-consent-templates',
  templateUrl: './consent-templates.component.html',
  styleUrls: ['./consent-templates.component.scss']
})
export class ConsentTemplatesComponent implements OnInit {
  @ViewChild('consent') paginator: MatPaginator;
  @ViewChild('pricecat') paginatorcat: MatPaginator;
  @ViewChild('pagGoal') pagGoal: MatPaginator;
  @ViewChild('grptherpy') Pagtherpy: MatPaginator;
  dataSource: MatTableDataSource<any>;
  dataSourceCat: MatTableDataSource<any>;
  dataSourceGoal: MatTableDataSource<any>;
  dataSourcegrptherpy: MatTableDataSource<any>;
  lsttemplates: any;
  selectedLocation: any = -1;
  templateids: any = [];
  defaultRecords: any = 20;
  public pageSize = 10;
  public currentPage = 0;
  public totalSize = 0;
  Decrptedlst: any;
  templateCatagorySch: any = '';
  templateNameSch: any = '';
  isPreviewtemplate: boolean = false;
  previewtemplatecontent: any;
  deccatalougelist: any;
  catalougelst: any;
  catalougeids: any = [];
  searchbyCatalouge: any = '';
  selectedTab: number;
  pagefrom: any;
  ddllocation: any;
  Sfcclocation: any;
  lstConfigureForms: any;
  configids: any = [];
  isView: boolean = false;
  lstGoals: any = [];
  goalids: any = [];
  decGoallist: any;
  isCreate: boolean = false;
  isEdit: boolean = false;
  isDelete: boolean = false;
  isGoalView: boolean = false;
  isGoalCreate: boolean = false;
  isGoalEdit: boolean = false;
  isGoalDelete: boolean = false;
  drptTherapy:any=[];
  grpTherapylst: any;
  grpTherapyids: any = [];
  roleAccess: any;
  skillTaughtMasterData:any;
  skillTaughtCheckBoxItems=[];
  constructor(public tempService: TemplateService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService
    ,public roleaccessservice:PageAccessService,private templateService:TemplateService) { }

  ngOnInit(): void {

    var access = JSON.parse(localStorage.getItem('roleaccess'));
    const pageid = constantVariables.pagelists.Templates;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
      
         this.isView =  this.roleAccess.isView;
      this.isCreate =  this.roleAccess.isCreate;
      this.isEdit =  this.roleAccess.isEdit;
      this.isDelete =  this.roleAccess.isDelete;

    // const pageid = constantVariables.pagelists.Goal;
    // var roleaccess = access.details.find(x => x.pageId == pageid);
    // if (roleaccess != null) {
      
    //   this.isGoalView = roleaccess.isView;
    //   this.isGoalCreate = roleaccess.isCreate;
    //   this.isGoalEdit = roleaccess.isEdit;
    //   this.isGoalDelete = roleaccess.isDelete;
    // }
    // else {
    //   this.isGoalView = true;
    //   this.isGoalCreate = true;
    //   this.isGoalEdit = true;
    //   this.isGoalDelete = true;
    // }
    
    // var access = JSON.parse(localStorage.getItem('roleaccess'));
    // var templatesAccess = access.details.find(x => x.pageId ==  constantVariables.pagelists.Templates);
    // if (templatesAccess != null) {
      
    //   this.isView = templatesAccess.isView;
    //   this.isCreate = templatesAccess.isCreate;
    //   this.isEdit = templatesAccess.isEdit;
    //   this.isDelete = templatesAccess.isDelete;
    // }
    // else {
    //   this.isView = true;
    //   this.isCreate = true;
    //   this.isEdit = true;
    //   this.isDelete = true;
    // }
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        this.pagefrom = params.PageId;
        if (this.pagefrom == 1) {
          this.loadCatalogue();
        }
        else if (this.pagefrom == 3) {
          this.getGroupTherapy();
        }
      }
    });
    this.selectedTab = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    if (isNaN(this.selectedTab) || this.selectedTab < 0) {
      this.selectedTab = 0;

    }
    this.onTabChange(this.selectedTab);
  }
  onTabChange(tab) {

    this.selectedTab = tab;
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: tab
      }
    });
    if (tab == 0) {
      this.gettemplateList();
    }
    else if (tab == 1) {
      this.loadCatalogue();
    }
    else if (tab == 2) {
      this.getConfigureForms();
    }
    // else if (tab == 3) {
    //   this.getGroupTherapy();
    // }
    else if(tab == 3){
      this.getSkillTaught();
    }
  }
  getSkillTaught(){
    this.spinnerservice.show();
    this.tempService.GetSkillTaughtMasterData().subscribe((res)=>{
      this.skillTaughtMasterData=res;
      this.spinnerservice.hide();      
    })
  }
  editSkillTaughtForm(id:any){
    this.spinnerservice.show();    
    this.router.navigate(['/skill-taught'], { queryParams: { id: this.commonService.FrontEndEncryption(id),action: this.commonService.FrontEndEncryption(1)}});
  }
  viewSkillTaughtForm(id:any){
    this.spinnerservice.show();    
    this.router.navigate(['/skill-taught'], { queryParams: { id: this.commonService.FrontEndEncryption(id),action: this.commonService.FrontEndEncryption(2)}});
  }
  loadCatalogue() {
    this.selectedTab = 1;
    this.Sfcclocation = null;
    this.getPriceCatalogues(this.selectedLocation);
    this.getddlLocation();
  }
  gettemplateList() {
    this.tempService.getConsentTemplates().subscribe(
      res => {
        if (res != null) {
          this.Decrptedlst = res;
          this.lsttemplates = this.Decrptedlst.slice(0, this.defaultRecords);

          this.spinnerservice.hide();
          this.dataSource = new MatTableDataSource(this.Decrptedlst);
          this.dataSource.paginator = this.paginator;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  onPageChange($event) {

    this.lsttemplates = this.Decrptedlst.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  onedittemplate(id) {
    this.spinnerservice.show();
    this.router.navigate(['/consent'], { queryParams: { id: this.commonService.FrontEndEncryption(id) } });

  }
  onStatusChange(item, status) {
    item.isActive = status == 0 ? true : false;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(item, false);
    this.tempService.poststatus(dataToEncrypt).subscribe(
      res => {
        if (res != null) {

          this.snackbar.success(res);
          this.gettemplateList();
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  ondeleteTemplate(item) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {

        this.deletephbook(item);

      }
    });

  }
  deletephbook(item) {

    this.spinnerservice.show();
    this.tempService.deleteTemplate(item.consentTemplatesId).subscribe(
      res => {

        this.snackbar.success(res.message);
        this.gettemplateList();
        this.spinnerservice.hide();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);

      },
    );
  }
  cbklisttemplate(event, item, index) {
    if (event.checked) {
      this.templateids.push(item.consentTemplatesId);
    } else {
      this.templateids.forEach((x, index) => {
        if (x === item.consentTemplatesId) this.templateids.splice(index, 1);
      });
    }
  }
  ondeletemultipletemp() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        const dataToEncrypt = this.commonService.DataToEncrypt(this.templateids, false);
        this.tempService.deleteTemplates(dataToEncrypt).subscribe(
          (res: any) => {
            this.templateids = [];
            this.snackbar.success(JSON.parse(res).message);
            this.gettemplateList();
            this.spinnerservice.hide();

          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );

      }
    });

  }
  searchTempCat(issearch) {
    if (issearch == 1) {
      this.templateCatagorySch = '';
    }
    let term = this.templateCatagorySch.toLowerCase();
    this.lsttemplates = this.Decrptedlst.filter(function (tag) {
      return tag.category.toLowerCase().indexOf(term) >= 0;
    });
  }
  searchTempName(issearch) {
    if (issearch == 1) {
      this.templateNameSch = '';
    }
    let term = this.templateNameSch.toLowerCase();
    this.lsttemplates = this.Decrptedlst.filter(function (tag) {
      return tag.templateName.toLowerCase().indexOf(term) >= 0;
    });
  }
  onpreivewtemplate(item) {
    this.spinnerservice.show();
    this.tempService.getConsentTemplateDetails(item.consentTemplatesId).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          if (res.consentTemplatesId != 0) {
            this.isPreviewtemplate = true;
            this.previewtemplatecontent = res.templateContent

          }

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  closepreviewtemp() {
    this.isPreviewtemplate = false;
    this.previewtemplatecontent = "";
  }

  //#region Price Catalogue
  getPriceCatalogues(id) {
    this.spinnerservice.show();
     let isGlobal=false;
    if(id=="0"){isGlobal=true;}
    id=(id=="0")?"-1":id;
    this.tempService.getPriceCatalogues(id).subscribe(
      res => {
        if (res != null) {
          if(isGlobal)
          {
            this.deccatalougelist =res.filter(c=>c.isGolbal==true);
          }
          else{ this.deccatalougelist = res;}
            this.catalougelst = this.deccatalougelist.slice(0, this.defaultRecords);
           this.spinnerservice.hide();
          this.dataSourceCat = new MatTableDataSource(this.deccatalougelist);
          this.dataSourceCat.paginator = this.paginatorcat;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onChangelocation(event) {
     this.selectedLocation = event.value;
    this.getPriceCatalogues(event.value)
  }
  oneditPriceCatalogue(id) {
    this.spinnerservice.show();
    this.router.navigate(['/price-catalogue'], { queryParams: { id: this.commonService.FrontEndEncryption(id) } });

  }
  ondeletePriceCatalogue(id) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.tempService.deletePriceCatalogue(id).subscribe(
          (res: any) => {

            this.snackbar.success(res.message);
            this.getPriceCatalogues(this.selectedLocation);
            this.spinnerservice.hide();

          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );

      }
    });
  }
  onPageChangeCataloge($event) {
    this.catalougelst = this.deccatalougelist.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  cbklistCatalogue(event, item, index) {
    if (event.checked) {
      this.catalougeids.push(item.priceCatalogueId);
    } else {
      this.catalougeids.forEach((x, index) => {
        if (x === item.priceCatalogueId) this.catalougeids.splice(index, 1);
      });
    }
  }
  ondeletemultipleCat() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        const dataToEncrypt = this.commonService.DataToEncrypt(this.catalougeids, false);
        this.tempService.deletePriceCatalogues(dataToEncrypt).subscribe(
          (res: any) => {
            this.catalougeids = [];
            this.snackbar.success(JSON.parse(res).message);
            this.getPriceCatalogues(this.selectedLocation);
            this.spinnerservice.hide();

          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );

      }
    });

  }
  searchCatalouge(issearch) {
    if (issearch == 1) {
      this.searchbyCatalouge = '';
    }
    let term = this.searchbyCatalouge.toLowerCase();
    this.catalougelst = this.deccatalougelist.filter(function (tag) {
      return tag.catalogueName.toLowerCase().indexOf(term) >= 0;
    });
  }
  getddlLocation() {

    this.tempService.getLocationList().subscribe(
      res => {
        if (res != null) {
          this.ddllocation = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  //#endregion

  //#region forms
  getConfigureForms() {
    this.tempService.getConfigureForms().subscribe(
      res => {
        if (res != null) {
          this.lstConfigureForms = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onEditForms(item) {
    this.router.navigate(['/configure-forms'], { queryParams: { id: this.commonService.FrontEndEncryption(item.configureFormId), pageid: 1 } });
  }
  onViewForms(item) {
    this.router.navigate(['/configure-forms'], { queryParams: { id: this.commonService.FrontEndEncryption(item.configureFormId), pageid: 2, type: item.formTypeId } });
  }
  onDeleteForm(item) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.tempService.deleteConfigureForms(item.configureFormId).subscribe(
          (res: any) => {
            this.spinnerservice.hide();
            this.getConfigureForms();
            this.snackbar.success(res.message);
          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },
        );
      }
    });
  }
  cbklistForms(event, item, index) {
    if (event.checked) {
      this.configids.push(item.configureFormId);
    } else {
      this.configids.forEach((x, index) => {
        if (x === item.configureFormId) this.configids.splice(index, 1);
      });
    }
  }
  ondeletemultipleForm() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        const dataToEncrypt = this.commonService.DataToEncrypt(this.configids, false);
        this.tempService.deleteMultipleConfigureForms(dataToEncrypt).subscribe(
          (res: any) => {
            this.configids = [];
            this.snackbar.success(JSON.parse(res).message);
            this.getConfigureForms();
            this.spinnerservice.hide();

          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );

      }
    });

  }

  //#endregion

  //#region  Goals
  // getGoals() {
  //   this.tempService.getGoals().subscribe(
  //     res => {
  //       this.spinnerservice.hide();
  //       if (res != null) {
         
  //         this.decGoallist = res;
  //         this.lstGoals = this.decGoallist.slice(0, this.defaultRecords);
  //         this.dataSourceGoal = new MatTableDataSource(this.decGoallist);
  //         this.dataSourceGoal.paginator = this.pagGoal;
  //       } 
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );
  // }
  // onPageChangeGoal($event) {
  //   this.lstGoals = this.decGoallist.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  // }
  // cbklistGoal(event, item, index) {
  //   if (event.checked) {
  //     this.goalids.push(item.goalId);
  //   } else {
  //     this.goalids.forEach((x, index) => {
  //       if (x === item.goalId) this.goalids.splice(index, 1);
  //     });
  //   }
  // }
  // ondeletemultipleGoal() {
  //   const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
  //     data: {
  //       title: '',
  //       message: constantVariables.SuccessMessage.DeleteConfirmMessage
  //     }
  //   });
  //   confirmDialog.afterClosed().subscribe(result => {
  //     if (result === true) {
  //       const dataToEncrypt = this.commonService.DataToEncrypt(this.goalids, false);
  //       this.tempService.deleteMultipleGoals(dataToEncrypt).subscribe(
  //         (res: any) => {
  //           this.goalids = [];
  //           this.snackbar.success(JSON.parse(res).message);
  //           this.getGoals();
  //           this.spinnerservice.hide();

  //         },
  //         err => {
  //           this.spinnerservice.hide();
  //           this.snackbar.error(err.error);

  //         },
  //       );
  //     }
  //   });
  // }

  
  //#endregion

  //#region  Goals
  getGoals() {
    let isAdmin = true;
    this.tempService.getGoals(isAdmin).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
         
          this.decGoallist = res;
          this.lstGoals = this.decGoallist.slice(0, this.defaultRecords);
          this.dataSourceGoal = new MatTableDataSource(this.decGoallist);
          this.dataSourceGoal.paginator = this.pagGoal;
        } 
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onPageChangeGoal($event) {
    this.lstGoals = this.decGoallist.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  cbklistGoal(event, item, index) {
    if (event.checked) {
      this.goalids.push(item.goalId);
    } else {
      this.goalids.forEach((x, index) => {
        if (x === item.goalId) this.goalids.splice(index, 1);
      });
    }
  }
  ondeletemultipleGoal() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        const dataToEncrypt = this.commonService.DataToEncrypt(this.goalids, false);
        this.tempService.deleteMultipleGoals(dataToEncrypt).subscribe(
          (res: any) => {
            this.goalids = [];
            this.snackbar.success(JSON.parse(res).message);
            this.getGoals();
            this.spinnerservice.hide();

          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );

      }
    });

  }
  oneditGoal(id) {
    this.spinnerservice.show();
    this.router.navigate(['/config-goal'], { queryParams: { id: this.commonService.FrontEndEncryption(id) } });

  }
  ondeleteGoal(obj) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.confirmation.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();

        this.tempService.deleteGoals(obj.goalId)
          .subscribe(
            data => {
              this.spinnerservice.hide();
              this.snackbar.success(data.message);
              this.getGoals();
            },
            error => {
              this.spinnerservice.hide();
              this.snackbar.error(error.error);
            });
      }
      else {
        return
      }
    });

  }

  
  //#endregion

//#region Group Therapy
getGroupTherapy() {
  this.spinnerservice.show();
  this.tempService.getGroupTherapy(0).subscribe(
    res => {
      if (res != null) {
        this.drptTherapy = res;
        this.grpTherapylst = this.drptTherapy.slice(0, this.defaultRecords);

        this.spinnerservice.hide();
        this.dataSourcegrptherpy = new MatTableDataSource(this.drptTherapy);
        this.dataSourcegrptherpy.paginator = this.Pagtherpy;
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
getMinutesSlot(timeSlot)
{
  if(timeSlot == 15)
  {
    return '15 mins';
  }
  else if(timeSlot == 30)
  {
    return '30 mins';
  }
  else if(timeSlot == 60)
  {
    return '1 hrs';
  }
  else if(timeSlot == 120)
  {
    return '2 hrs';
  }
  else if(timeSlot == 180)
  {
    return '3 hrs';
  }
  else if(timeSlot == 240)
  {
    return '4 hrs';
  }
  else if(timeSlot == 300)
  {
    return '5 hrs';
  }
  else if(timeSlot == 360)
  {
    return '6 hrs';
  }
  else if(timeSlot == 420)
  {
    return '7 hrs';
  }
  else if(timeSlot == 480)
  {
    return '8 hrs';
  }
}
oneditGrpTherapy(id) {
  this.spinnerservice.show();
  this.router.navigate(['/group-therapy'], { queryParams: { id: this.commonService.FrontEndEncryption(id) } });

}
ondeleteGrpTherapy(id) {
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.SuccessMessage.DeleteConfirmMessage
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
      this.spinnerservice.show();
      this.tempService.deleteGroupTherapy(id).subscribe(
        (res: any) => {

          this.snackbar.success(res.message);
          this.getGroupTherapy();
          this.spinnerservice.hide();

        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);

        },
      );

    }
  });
}
onPageChangeTherapy($event) {
  this.grpTherapylst = this.drptTherapy.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
}
cbklistTherapy(event, item, index) {
  if (event.checked) {
    this.grpTherapyids.push(item.groupTherapyId);
  } else {
    this.grpTherapyids.forEach((x, index) => {
      if (x === item.groupTherapyId) this.grpTherapyids.splice(index, 1);
    });
  }
}
ondeletemultipleTherapy() {
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.SuccessMessage.DeleteConfirmMessage
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
      const dataToEncrypt = this.commonService.DataToEncrypt(this.grpTherapyids, false);
      this.tempService.deleteGroupTherapys(dataToEncrypt).subscribe(
        (res: any) => {
          this.grpTherapyids = [];
          this.snackbar.success(JSON.parse(res).message);
          this.getGroupTherapy();
          this.spinnerservice.hide();

        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);

        },
      );

    }
  });

}

deleteSkillTaughForm(id:any){
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.SuccessMessage.DeleteConfirmMessage
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
      this.spinnerservice.show();
      var temp=[];
      temp.push(id);
      this.templateService.DeleteSkillTaughtForm(this.commonService.DataToEncrypt(temp,false)).subscribe((res)=>{
        var data=JSON.parse(res);
        console.log(data);
        if(data.result.length!=0){
          this.snackbar.error(data.message);
        }
        else{
        this.snackbar.success(data.message);
        }
        this.getSkillTaught(); 
        this.spinnerservice.hide();
      },
      (err)=>{
        this.spinnerservice.hide();
      })
    }
  });
}
skillTaughtFormCheckBox(event, item, index) {  
  if (event.checked) {
    this.skillTaughtCheckBoxItems.push(item.skillTaughtMasterId);
  } else {
    this.skillTaughtCheckBoxItems.forEach((x, index) => {
      if (x === item.skillTaughtMasterId) this.skillTaughtCheckBoxItems.splice(index, 1);
    });
  }
}
ondeletemultipleSkillTaughtForm(id:any) {
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.SuccessMessage.DeleteConfirmMessage
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
      this.spinnerservice.show();
      var temp=[];
      temp.push(id);
      console.log(this.skillTaughtCheckBoxItems);
      this.templateService.DeleteSkillTaughtForm(this.commonService.DataToEncrypt(this.skillTaughtCheckBoxItems,false)).subscribe((res)=>{
        var data = JSON.parse(res)
        console.log(data)
        if(data.result.length!=0){
          this.snackbar.error(data.message);
        }
        else{
        this.snackbar.success(data.message);
        }
        // this.snackbar.success(data);
        this.getSkillTaught(); 
        this.spinnerservice.hide();
      },
      (err)=>{
        this.spinnerservice.hide();
      })
    }
  });
}
//#endregion

}

<div class="benchmark-popup">
    <div class="modal" tabindex="-1" role="dialog" [style.display]="  'block'">
      <div class="modal-dialog" role="document">
        <form (ngSubmit)="Benchmarkfrm.form.valid " id="Benchmarkfrm" autocomplete="off" #Benchmarkfrm="ngForm"
        novalidate>
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Benchmark </h6>
            <button type="button" class="close"  data-dismiss="modal"
              aria-label="Close" (click)="onClosemodal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
   
        <div class="benchmark-data col-md-12">
      <div class="col-md-12 p-0 form-group">
        <mat-form-field class="w-100">
            <mat-label>Benchmark Title</mat-label>
            <input matInput  maxlength="50"
            [(ngModel)]="Benchmarkfrminfo.Benchmarktitle" #Benchmarktitle="ngModel" name="Benchmarktitle"
            [ngClass]="{ 'is-invalid': Benchmarkfrm.submitted && Benchmarktitle.invalid }"
            required>
            <mat-error *ngIf="Benchmarkfrm.submitted && Benchmarktitle.invalid">
                <mat-error class="text-xs" *ngIf="Benchmarktitle.invalid">
                    Benchmark Title is required</mat-error>
            </mat-error> 
        </mat-form-field>
      
      </div>
      <div class="col-md-12 p-0 form-group">
        <mat-form-field class="w-100">
            <mat-label>Benchmark Description</mat-label>
            <textarea matInput maxlength="400" [(ngModel)]="Benchmarkfrminfo.Benchmarkdiscription"
            #Benchmarkdiscription="ngModel" name="Benchmarkdiscription"
            [ngClass]="{ 'is-invalid': Benchmarkfrm.submitted && Benchmarkdiscription.invalid }" required></textarea>
            <mat-error *ngIf="Benchmarkfrm.submitted && Benchmarkdiscription.invalid">
                <mat-error class="text-xs" *ngIf="Benchmarkdiscription.invalid">
                    Benchmark description is required</mat-error>
            </mat-error> 
          </mat-form-field>
        
      </div>
      <div class="col-md-12 p-0">
          <div class="row">
            <div class="col-md-6  form-group">
                <mat-form-field class="w-75">
                    <mat-label>Unit of Measure</mat-label>
                    <input matInput [(ngModel)]="Benchmarkfrminfo.Benchmarkunit" percentage
                    #Benchmarkunit="ngModel" name="Benchmarkunit"
                    [ngClass]="{ 'is-invalid': Benchmarkfrm.submitted && Benchmarkunit.invalid }"
                    required step="0" maxlength="4" customMin="1">
                    <mat-error *ngIf="Benchmarkfrm.submitted && Benchmarkunit.invalid">
                        <mat-error class="text-xs" *ngIf="Benchmarkunit.invalid">
                            Benchmark Title is required</mat-error>
                    </mat-error> 
                  </mat-form-field>
                  <span  *ngIf="Benchmarkfrminfo.GoalMeasureTypeId == PercentageBased">%</span>
                  <span *ngIf="Benchmarkfrminfo.GoalMeasureTypeId == Timebased">min</span>

              </div>
              
              <div class="col-md-6 form-group" *ngIf="IsShowStatus">
                <mat-form-field class="w-100">
                    <mat-label>Status</mat-label>
                    <mat-select [(ngModel)]="Benchmarkfrminfo.Status"
                    #status="ngModel" name="status">
                      <mat-option value='true' (click)="Onstatuschange(1)">Inprogress</mat-option>
                      <mat-option value='false' (click)="Onstatuschange(2)">Completed</mat-option>
                    </mat-select>                   
                  </mat-form-field>
                  
              </div>
          </div>
      </div>
      
      
    
  </div>
  
  </div>
  <div class="modal-footer">
    <div class="col-md-12 p-0 float-left text-right">
        <button mat-stroked-button class="btn primary-btn-outlined-small float-right mt-2 ml-2 " (click)="onClosemodal()">Cancel</button>
        <button  mat-stroked-button  class="btn primary-btn-filled-small float-right mt-2 mr-2 " (click)="OnpostBenchmark()"><i  class="far fa-save mr-2"></i>Save</button>    
    </div>
</div>
</div>
</form>
 
  </div>
  </div>
  </div>

import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { SendEmail } from 'src/app/_models/_Common/sendEmail';
import { SendEmailComponent } from 'src/app/common/pages/send-email/send-email.component';
import * as moment from 'moment';
import { constantVariables } from 'src/app/constant/constant';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Injectable()
export class Global {


  mailform: NgForm;
  mailinfo: SendEmail;
  esign: string;
  consentesign: string;
  formtype: string;
  isconsentesign: boolean;
  ismytask:boolean;
  @ViewChild(SendEmailComponent, { read: false, static: false }) _sendemail: SendEmailComponent
  public toemailList = [];
  public ccemailList = [];
  public mailattachment: string[] = [];
  submitted = false;
  patientid: any;
  patientInfoStatus: any;
  patientInfoStatusCreatedon: any;
  MasterTemplateImportlbl: any;
  ImportTemplateUrl: any;
  messagecount: any;
  gblUserTags: any = [];
  LeadStatus: any;
  ddlsfcclocation: any;
  clinicalInfoUpdatedOn: any;
  patientUpdatedByName: any;
  clinicalInfoUpdatedByName : any;
  evaluvationUpdatedOn: any;
  evaluvationUpdatedByName: any;
  clientSWUpdatedOn: any;
  clientSWUpdatedByName: any;
  assessmentUpdatedOn: any;
  assessmentUpdatedByName: any;
  CSummaryUpdatedOn: any;
  CSummaryUpdatedByName: any;
  TSummaryUpdatedOn: any;
  TSummaryUpdatedByName: any;
  patientUpdatedOn: any;
  isPatient : number = 0;
  isClinicalInfo : number = 0;
  isEvaluvation : number = 0;
  isAssessment : number = 0;
  isClientSW : number = 0;
  isClinicalSummary : number = 0;
  isTreatmentSummary : number = 0;
  frmPatientDetails: any;
  activeTab: number = 1;

  gblStates: any = [];
  gblCities: any = [];
  gblGrade: any = [];

  isSaveClinicalInfo : boolean = false;
  isSaveStrength: boolean = false;

  cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value || '' as string).trim().length === 0) {
      return { cannotContainSpace: true };
    }
    else if ((control.value || '' as string).replace('<p>', '').replace('</p>', '').trim().length === 0) {
      return { cannotContainSpace: true };
    }

    return null;
  }
  trimValue(event) { event.target.value = event.target.value.trim(); }
  isValidMailFormat(control: FormControl) {
    let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

    if (control.value != "" && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return { "Please provide a valid email": true };
    }

    return null;
  }
  public showEmailPopup: boolean = false;

  public onOpenEmailPopup(tomailtag) {

    // this.toemailList = [];
    // this.ccemailList = [];
    this.mailattachment = [];
    if (tomailtag) {
      this.toemailList.push({ value: tomailtag, invalid: false });
    }
    this.showEmailPopup = true;
    this.submitted = false;
  }
  public clearEmaillist() {
    this.toemailList = [];
    this.ccemailList = [];
  }
  public oncloseEmailpopup() {
    window.location.reload();
    this.mailinfo = new SendEmail();
    this.toemailList = [];
    this.ccemailList = [];
    this.mailattachment = [];
    this.showEmailPopup = false;
    this.submitted = false;
    var resetmailform = <HTMLFormElement>document.getElementById("mailform");
    resetmailform.reset();
    localStorage.setItem('patientId', "");
    localStorage.setItem('parentname', "");
    this.mailinfo.ParentId = 0;
  }

  alphanumeric(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  onPasteAlphanumeric(event) {
    let clipboardData = event.clipboardData;
    let regexp = new RegExp('[^-_().;:,!a-zA-Z0-9/ ]');
    let pastedText = clipboardData.getData('text');
    let test = regexp.test(pastedText);
    let trimmedText = pastedText.replace(/[^a-zA-Z0-9-()._ ]/g, '');
    (document.getElementById('bindingId') as HTMLInputElement).value = trimmedText;
    if (test) {
      event.preventDefault();
    }
  }
  scrollup() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  globalUtctimeformat(date) {
    var offset = new Date().getTimezoneOffset();
    return moment.utc(date).utcOffset(-offset).format("MM/DD/YYYY hh:mm a");
  }
  getContent(data) {
    if (data != null) {
      return data.replace(/(<br ?\/?>)*/g, "");
    }
  }
  getContent1(data) {
    
      return data.replace( /(<([^>]+)>)/ig, '');
    
  }
  getCharUsername(str) {
    str = str == null ? "" : str.trim();
    if (str != "") {
      const matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      const acronym = matches.join(''); // JSON

      return acronym.substr(0, 2);
    }
  }
  getMinutesSlot(timeSlot) {
    if (timeSlot == 15) {
      return '15 mins';
    }
    else if (timeSlot == 30) {
      return '30 mins';
    }
    else if (timeSlot == 60) {
      return '1 hrs';
    }
    else if (timeSlot == 120) {
      return '2 hrs';
    }
    else if (timeSlot == 180) {
      return '3 hrs';
    }
    else if (timeSlot == 240) {
      return '4 hrs';
    }
    else if (timeSlot == 300) {
      return '5 hrs';
    }
    else if (timeSlot == 360) {
      return '6 hrs';
    }
    else if (timeSlot == 420) {
      return '7 hrs';
    }
    else if (timeSlot == 480) {
      return '8 hrs';
    }
  }
}

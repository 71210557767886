
export class crba {
    crbainformationId: number;
    patientInfoId: number;
    status: number;
    programDetails: DtoProgramDetails;
    benefitDetails: DtoBenefitDetails;
    authorizationsDetails: DtoAuthorizationsDetails;
    authorizationsRequiredInfo: DtoAuthorizationsRequiredInfo;
    insuranceDetails:DtoInsurance;
    clientAddress:DtoClientAddress;
}
export class DtoProgramDetails {
    programDetailsId: number;
    crbainformationId: number;
    programType: number;
    programDays: string;
    clientName: string;
    motherName: string;
    fatherName: string;
    dob: string;
    startDate: Date;
    insuranceId: string;
    homeNumber: string;
    cellNumber: string;
    addressLine1: string;
    addressLine2: string;
    email: string;
    
}
export class DtoBenefitDetails {
    benefitDetailsId: number;
    crbainformationId: number;
    insuranceCompany: string;
    outOfNetwork: boolean;
    coInsurance: number;
    coPay: number;
    deductibleforindividual: number;
    deductibleforfamily: number;
    individualDeductible: number;
    familyDeductible: number;
    maxOutOfPocket: number;
    maxOutOfPocketFamily: number;
    deductibleUsed: number;
    maxNumberofVisitsPa: number;
    effectiveDate: string;
    isSingleCaseAgreement: boolean;
    isMaxVisitPerYear: boolean;
    isSelfFunded: boolean;
    address: string;
    stateId: number;
    cityId : number;
    zipcode : number;

}

export class DtoAuthorizationsDetails {
    authorizationsDetailsId: number;
    crbainformationId: number;
    type: number;
    startDate: string;
    endDate: string;
    daysPerWeek: number;
    totalVisits: number;
    authorizationNumber: string;
    reference: string;
    employeeName: string;
    infoDate: string;
    clinicalInformations: any;
}
export class DtoClinicalInformation {
    clinicalInformationId: number;
    authorizationsDetailsId: number;
    information: string;
    isActive: boolean;
}
export class DtoAuthorizationsRequiredInfo {
    authorizationsRequiredInfoId: number;
    crbainformationId: number;
    isIndividual: boolean;
    isFamily: boolean;
    isGroup: boolean;
    isPcp: boolean;
    isIop: boolean;
    isNutrition:boolean;
    individualGroup: boolean;
    insuranceRepresentative: string;
    reportDate: string;
    phone: string;
    ext: string;
    isTelehealth: boolean;
    isoutOfNetwork: boolean;
    medManagement : boolean;
    diagnosticEval : boolean;
    reference : string;
    payerId : string;
    comments : any;
}
export class DtoInsurance{
    insuranceDetailsId:number;
    memberName: string;
    ssid: string;
    memberEmployer: string;
    employerAddress: string;
    employerPhoneNo: string;
    carrierName: string;
    policyNo: string;
    groupNo: string;
    isPrimaryInsurance :boolean
    patientInfoId:number;
    isScaRequired:boolean;
    insuranceTypeId:number;
    insuranceRelationShipId:number;
    memberId:number;
    firstName: string;
    lastName: string;
    
}
export class DtoClientAddress{
    addressLine1: string;
    stateName: string;
    city: string;
    zip: string;
}
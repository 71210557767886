<div class="block-settings">
    <div class="col-md-12">
        <div class="box-lead-head">Create Block </div>
    </div>
    <form (ngSubmit)="blockinfo.form.valid  && postMeetingBlock(blockinfo)" id="blockinfo" autocomplete="off"
        #blockinfo="ngForm" novalidate>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field class="w-100">
                        <mat-label>Block type</mat-label>
                        <mat-select [(ngModel)]="_dtoBlocks.blockTypeId" #blockTypeId="ngModel" name="blockTypeId"
                            required>
                            <mat-option *ngFor="let item of ddlmeetingblocks" [value]="item.blockTypeId">
                                {{item.blockTypeName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="blockinfo.submitted && blockTypeId.invalid">
                            <mat-error *ngIf="blockTypeId.errors.required">Block Type is required
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4">

                    <!-- <mat-label>Block Reason</mat-label> -->
                    <ng-select autocomplete="off" [items]="ddlblockreason" maxlength="50"
                        placeholder="Block Reason Name" appAlphabetOnly bindLabel="reasonTypeName"
                        bindValue="reasonTypeName" #blockReasonTypeId="ngModel" name="blockReasonTypeId"
                        [(ngModel)]="_dtoBlocks.blockTypeName" addTagText="Add item" [addTag]="blockReasonTypeIdTag"
                        (change)="postTypeName(_dtoBlocks.blockTypeName)" required>
                    </ng-select>
                    <mat-error *ngIf="blockinfo.submitted && blockReasonTypeId.invalid">
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="blockReasonTypeId.errors.required">
                            Block reason is required
                        </mat-error>
                    </mat-error>

                </div>
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>Description</mat-label>                        
                        <input matInput placeholder="" maxlength="50" [(ngModel)]="_dtoBlocks.description"
                            #description="ngModel" name="description">
                    </mat-form-field>
                </div>
                <div class="col-md-1 pr-0">
                    <div class="check-custom mt-3 pt-1">
                        <mat-checkbox class="example-margin" [(ngModel)]="_dtoBlocks.isActive" #isActive="ngModel"
                            name="isActive">Active</mat-checkbox>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-md-5 mt-2 pr-0">
                    <div class="mt-3">
                        <span class="small-heading-label align-middle font-weight-500">Frequency</span>
                        <span class="ml-4 yes-no-insurance">
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="_dtoBlocks.frequency"
                                #frequency="ngModel" name="frequency" required>
                                <mat-radio-button class="mr-2" [value]="1">Daily </mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="2">Weekly </mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="3">Monthly </mat-radio-button>
                            </mat-radio-group>
                            <mat-error class="mat-form-field-subscript-wrapper"
                                *ngIf="blockinfo.submitted && frequency.invalid">
                                <mat-error *ngIf="frequency.errors.required">Frequency is required
                                </mat-error>
                            </mat-error>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="_dtoBlocks.frequency == 2">
                <div class="col-md-12 mb-2 mt-2">
                    <div class="">
                        <!-- <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" (change)="onWeekDaySelection($event.checked,0)">Sunday
                            </mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" (change)="onWeekDaySelection($event.checked,1)">Monday
                            </mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" (change)="onWeekDaySelection($event.checked,2)">Tuesday
                            </mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" (change)="onWeekDaySelection($event.checked,3)">
                                Wednesday</mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" (change)="onWeekDaySelection($event.checked,4)">
                                Thursday</mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" (change)="onWeekDaySelection($event.checked,5)">Friday
                            </mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" (change)="onWeekDaySelection($event.checked,6)">
                                Saturday</mat-checkbox>
                        </div> -->
                        <div class="check-list-custom">
                        <mat-selection-list class="check-custom d-inline-block mr-2" required #program="ngModel"
                            name="program" [(ngModel)]="prgdays" [ngModelOptions]="{standalone: true}">
                            <mat-list-option [value]="a.key" *ngFor="let a of programdays">
                                {{a.text}}
                            </mat-list-option>
                        </mat-selection-list>
                        <mat-error *ngIf="blockinfo.submitted && program.invalid">
                            <mat-error *ngIf="program.errors.required">Program Days is required
                            </mat-error>
                        </mat-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-right mt-2">
                    <button type="button" title="Cancel" (click)="resetblocks()"
                        class="btn btn-cancel float-right">Cancel</button>
                    <button type="submit" class="btn primary-btn-filled float-right mr-2" title="{{editblocks ? 'Update' : 'Save'}}">{{editblocks ? 'Update' : 'Save'}}</button>
                </div>
            </div>
        </div>
    </form>
    <div>
        <div class="col-md-12 mt-2">
            <div class="box-lead-head">Meeting Blocks</div>
        </div>
        <div class="col-md-12  table-custom p-0">
            <div class="table-responsive">
                <mat-table #table [dataSource]="dataSource" matSort class="w-100">
                    <ng-container matColumnDef="sno">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="blockType">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Block Type </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.blockType}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="blockTypeName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Block Reason </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.blockTypeName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="IsActive">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Active </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.isActive == 1 ? 'Yes':'No'}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Actions </mat-header-cell>
                        <mat-cell *matCellDef="let element;let i = index;">
                            <button type="button" mat-icon-button matTooltip="Edit"
                                (click)="oneditMeetingBlocks(element)" color="primary">
                                <img src="../../../assets/images/editblue.png">
                            </button>
                            <button type="button" mat-icon-button matTooltip="Delete"
                                (click)="ondeleteMeetingBlocks(element)" color="warn">
                                <img src="../../../assets/images/deletered.png">
                            </button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                </mat-table>
            </div>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
</div>
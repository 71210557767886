import { Component, OnInit, ViewChild } from '@angular/core';
import { LeadService } from '../lead.service';
import { Global } from 'src/app/global/global.model';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { UserserviceService } from 'src/app/usermanagement/userservice.service';
import { AuthenticationService } from 'src/app/admin/authentication.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { constantVariables } from 'src/app/constant/constant';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
declare function decrypt(data): any;

@Component({
  selector: 'app-admindeclainlist-lead',
  templateUrl: './admindeclainlist-lead.component.html',
  styleUrls: ['./admindeclainlist-lead.component.scss']
})
export class AdmindeclainlistLeadComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  public searchpatientListtxt : string;
  DecrptedlstLead: any = [];
  lstLead: any = [];
  defaultRecords: any = 20;
  leadhistory: any;
  GetActivityId: any;
  Decrptedlst: any;
  statusResendActivationMail = constantVariables.status.ResendActivationMail;
  statusUploadInsurance = constantVariables.status.UploadInsurance;
  statusReviewPacket = constantVariables.status.ReviewPacket;
  statusReturn = constantVariables.status.Return;
  statusVerified = constantVariables.status.Verified;
  statusCrbaUpdate = constantVariables.status.CrbaUpdate;
  statusFinancial = constantVariables.status.financial;
  statusintake = constantVariables.status.forIntake;
  statusreviewTour = constantVariables.status.reviewTour;
  statusdecline = constantVariables.status.Decline;
  statusstaffAssigned = constantVariables.status.staffAssigned;
  statusRequestTour=constantVariables.status.requestTour;
  statusTourCompleted=constantVariables.status.tourCompleted;
  roleAccess : any;
  PatientInfoId : any;
  IsActive : Boolean;
  isCommentInformation : Boolean;
  isCommentInformationtext : any;
  constructor(public leadservice: LeadService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public roleaccessservice:PageAccessService,private userService:UserserviceService,private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    localStorage.setItem("profiletab", "1");
    this.getLeadlistView();
    const pageid = constantVariables.pagelists.Lead;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
  }
  getLeadlistView() {
    this.spinnerservice.show();
    let locationid= Number(localStorage.getItem('sfcclocation'))==0?-1:Number(localStorage.getItem('sfcclocation'));
    //this.leadservice.getPatientList().subscribe(
      this.leadservice.getAdminLoginDeclainList(locationid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.DecrptedlstLead = decrypt(res.objInputString);
        this.lstLead = this.DecrptedlstLead.slice(0, this.defaultRecords);
        this.dataSource = new MatTableDataSource(this.DecrptedlstLead);
        this.dataSource.paginator = this.paginator;
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }
  onPageChange($event) {
   
    this.lstLead = this.DecrptedlstLead.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  getLeadHistory() {
    this.leadhistory = []
    this.spinnerservice.show()
    this.leadservice.getLeadHistory(this.GetActivityId).subscribe(
      (res: any) => {
        if (res) {
          this.Decrptedlst = res;
          this.leadhistory = this.Decrptedlst;
         // console.log(this.leadhistory)
        }
        this.spinnerservice.hide()
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getMoreInformation(item) {
    return 'Source Type : ' + item.SourceType + ' \n  Lead Type :  ' + item.LeadType + ' \n Facility Type :  ' + item.FacilityTypeName + ' \n ';
  }
  returnLead(PatientInfoId){
    this.PatientInfoId = PatientInfoId;

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.LeadReturn
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.moveLeadInformation();      
      }
    });
  }
  moveLeadInformation(){
    this.spinnerservice.show();
    this.IsActive = true;
    this.leadservice.RemoveLeadAccount(this.PatientInfoId , this.IsActive,null,null).subscribe(
      (res: any) => {
        this.snackbar.success("Lead resumed successfully");  
        this.spinnerservice.hide();
        this.getLeadlistView();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
    
  }
  BacktoLead(){
    this.router.navigate(['/leadlist'])

  }
  OnSearchPatient(){
    let tempPatientlist = this.DecrptedlstLead
    if(this.searchpatientListtxt !="" || this.searchpatientListtxt === undefined || this.searchpatientListtxt === null){
      this.lstLead = tempPatientlist.filter(
        (x: any) => 
        x.FirstName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) || 
        x.Email?.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
        x.LastName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
      //this.lstLead = tempPatientlist.filter((x: any) =>x.Email?.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
    }
    else{
     this.lstLead = tempPatientlist.slice(0, 5);
    }
  }
  commentsClick(item){
    this.isCommentInformation = true;
    this.isCommentInformationtext = item
  }
  closecomments(){
    this.isCommentInformation = false;

  }
}

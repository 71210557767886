<div>
  <div *ngIf="roleAccess.isView">
  <div class="d-flex w-100 mt-2">
    <div class="search col-md-4 mb-2">
      <input
        type="text"
        placeholder="Search"
        name=""
        (keyup)="userFilter($event.target.value)"
        class="search-input"
      />
      <a class="search-icon"><i class="fa fa-search"></i></a>
    </div>
    
        <div class="ml-auto filter-group d-inline-block">
          <span class="filter-icon mr-2"><i class="fa fa-filter"></i></span>
          <label class="d-inline-block mr-2">Status</label>
          <select
            class="form-control d-inline-block status-filter"
                        (change)="filterChange($event.target.value)"
          >
          
            <option value="0" selected>All</option>
            <option value="1">In Progress</option>
            <option value="2">Completed</option>
            <option value="3">Overdue</option>
            <option value="4">Not Started</option>
          </select>
        </div>

    <!-- <div class="ml-auto">
      <button
        type="button"
        class="btn primary-btn-outlined float-right"
        (click)="OnBackClick()"
      >
        <i class="fas fa-angle-left pr-2"></i>Back
      </button>
    </div> -->
  </div>

  <div class="col-md-12 auditClinicianTable table-custom p-0">
    <div class="table-responsive">
      <mat-table #table [dataSource]="dataSource" matSort class="w-100">
        <ng-container matColumnDef="sno">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            S.No
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            {{ i + 1 }}</mat-cell
          >
        </ng-container>
        <ng-container matColumnDef="clientName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Client Name
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.FirstName + " " + element.LastName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Location Name
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.LocationName }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Status
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="element.Completed != 0">
              Completed({{ element.Completed }})</span
            >
            <span *ngIf="element.Completed != 0 && element.Pending != 0">
              ,&nbsp;
            </span>
            <span *ngIf="element.Pending != 0">
              In Progress({{ element.Pending }})</span
            >
            <span *ngIf="element.Pending != 0 && element.Overdue != 0">
              ,&nbsp;
            </span>
            <span *ngIf="element.Completed != 0 && element.Pending == 0 && element.Overdue != 0">
              ,&nbsp;
            </span>
            <span *ngIf="element.Overdue != 0">
              Overdue({{ element.Overdue }})</span
            >
            <span *ngIf="(element.Completed != 0 || element.Pending != 0 || element.Overdue != 0 ) && element.YetToStart != 0">
              ,&nbsp;
            </span>
           
            <span *ngIf="element.YetToStart != 0">
              Not Started({{ element.YetToStart }})</span
            >
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <button
              type="button"
             class="btn Audit-view px-0 fs-14"
              title="View"
              color="primary"
              (click)="OnViewDetails(element)"
            >
              View Details
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
    <div  *ngIf="auditList?.length == 0">
      <div class="list-note w-100 mt-2 mb-2">
        <span>No records found</span>
      </div>
    </div>
  </div>
  
  <mat-paginator 
    [pageSize]="20"
    [pageSizeOptions]="[10, 20, 50, 75, 100]"
    showFirstLastButtons
  ></mat-paginator>
  </div>
  <div  *ngIf="!roleAccess.isView">
    <div class="msg-nomeet text-center">You do not have privilege to access this. Please contact admin</div>
</div>
</div>

export class Goals {
    treatmentGoalsId:number;
    chmatreatmentSummaryId:number;
    treatmentModalityId:number;
    goalId: number;
    goalName: string;
    relevance: string;
    period: number;
    isActive: boolean;
    overarchingDate:string;
    treatmentPlanDate:string;
    measures: Array<GoalMeasure>;
    applicableTreatment : Array<Number>;
    measureTypeId: Number;
}

export class GoalMeasure {
    interVention: number;
    achievable: string;
    benchMark: number;
    timeType: number;
    benchMarkValue: string = "Time";
    benchMarkActual:number;
    scheduleDate:string;
    staffId:number;
}

export class TreatmentGoalConfig {
  treatmentGoalsId:number;
  chmaTreatmentSummaryId:number;
  goalId: number;
  dueDate:any;
  goalMeasureId:number;
  trackGoalId:number;
  goalMeasureTypeId:number;
  therapyId:number;
}


export class PsyTherapyInfo {

    psychiatricTherapyNotesId:Number;
    patientAccountId:Number;
    meetingscheduleid:Number;
    meetingscheduleTimeId:Number;
    chiefComplaint:string;
    mentalStatus:string;
    assessment:any;
    medicationCompliance:any;
    allergies:any;
    isMedicationReaction:any;
    medicationReaction:string;
    diagnosis:any;
    treatment:string;
    treatmentGoals:string;
    serviceGoals:string;
    isMedicationChanges:any;
    counseling:any;
    comments:string;
    nextFollowUp:any;
    staffInitials:string;
    signDate:any;
    severity:number;
    isSave:boolean;
    staffInitial1:string;
    licenseNumber : string;
    sSignature : boolean;
}



export class PsyEvaNotes{
    psyEvaNotesId:Number;
    patientAccountId:Number;
    meetingscheduleid:Number;
    meetingscheduleTimeId:Number;
    evaDate:any;
    identification:String;
    chiefComplaint:String;
    presentingIllness:String;
    psychiatricHistory:String;
    etoh:String;
    medicalHistory:String;
    currentMedications:String;
    comprehensiveMedication:String;
    developmentalHistory:String;
    psychosocialHistory:String;
    familyHistory:String;
    medication:Array<PsyEvaMedicationinfo>;
    severity:number;
}

export class PsyEvaMentalStatusInfo{
    psyEvaMentalStatusId:Number;
    patientAccountId:Number;
    meetingscheduleid:Number;
    meetingscheduleTimeId:Number;
    appearance:any;
    isTatoos:boolean;
    tatoosDetails:string;
    isBirthmarks:boolean;
    birthmarks:string;
    isDentalCondition:boolean;
    dentalCondition:string;
    motorActivity:string;
    facialExpressions:string;
    emotionalResponse:any;
    otherEmotionalResponse:string;
    attitue:any;
    otherAttitue:string;
    mostDays:Number;
    now:Number;
    goodAboutYourself:Number;
    crying:Number;
    warrier:Number;
    moodSwings:Number;
    threeWishes:string;
    mood:any;
    iscongruent:boolean;
    speech:any;
    otherSpeech:string;
    additionalComments:string;
    perceptualDisturbances:string;
    thinkingMakeSense:boolean;
    thoughtPatternsConnected:boolean;
    flightofIdeas:boolean;
    looseAssociation:boolean;
    magicalThinking:boolean;
    specialPower:boolean;
    forceisControlling:boolean;
    talkingorLaughing:boolean;
    killingYourself:boolean;
    commitSuicide:boolean;
    suicidePlan:boolean;
    violentBehavior:boolean;
    killingSomeone:boolean;
    killingSomeonePlan:boolean;
    orientationTime:Number;
    orientationPlace:boolean;
    orientationReason:boolean;
    goodMemory:boolean;
    diffRememberingNames:boolean;
    diffRememberingPhone:boolean;
    diffRememberAppoinments:boolean;
    eatYesterday:string;
    lastWeekend:string;
    threeObj:Number;
    fourNum:Number;
    countBackwards:Number;
    daysofWeek:Number;
    fundofKnowledge:Number;
    lienMistake:Number;
    windownDoor:Number;
    vocabulary:Number;
    calculation:Number;
    badChoice:Number;
    criticizes:Number;
    goodDecisions:Number;
    responsible:Number;
    played:string;
    changesHelp:string;
    frustrationTolerance:Number;
    impulseControl:Number;
    comments:string;
    axisI:string;
    axisIi:string;
    axisIii:string;
    axisIv:string;
    axisVcurrent:string;
    axisVlastYear:string;
    psychodynamicFormulation:string;
    recommendation:string;
    killingYourselfWhen:string;
    commitSuicideWhen:string;
    suicidePlanWhen:string;
    killingSomeoneWhen:string;
    killingSomeonePlanWhen:string;
    staffInitial : string;
    isAttachEsign : boolean;
    staffInitial1 : string;
    licenseNumber : string;
    sSignature : boolean;
}

export class PsyEvaMedicationinfo {
    psychiatricEvaMedDtlId:Number;
    psyEvaNotesId:Number;
    medications:string;
    dosage:string;
    frequency:string;
    sideEffects:string;
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { CommonService } from 'src/app/common/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import * as moment from 'moment';

@Component({
  selector: 'app-pending-documents',
  templateUrl: './pending-documents.component.html',
  styleUrls: ['./pending-documents.component.scss']
})

export class PendingDocumentsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  pendingDocuments: any = [];
  isClinician: boolean = false;
  selectedLocation: any;
  roleAccess: RoelAccess;
  Data:string;
  LocationId:any;
  displayedColumns = ['sno', 'clientName', 'location', 'actions'];
  constructor(
    public spinnerservice: NgxSpinnerService,
    public clinicianservice: ClinicianService,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    public roleaccessservice: PageAccessService,
  ) {}

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Audit;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.pendingDocuments ="";
    this.LocationId=36;
    this.getpendingDocuments();
  }
  getpendingDocuments() {
    this.LocationId = Number(localStorage.getItem('sfcclocation')) <= 0
    ? -1
    : Number(localStorage.getItem('sfcclocation'));

    this.selectedLocation =
      Number(localStorage.getItem('sfcclocation')) <= 0
        ? -1
        : Number(localStorage.getItem('sfcclocation'));
    this.isClinician =
      Number(localStorage.getItem('roletypeId')) ==
        constantVariables.role.PrimaryClinician ||
      Number(localStorage.getItem('roletypeId')) ==
        constantVariables.role.SupportClinician
        ? true
        : false;
    
    this.spinnerservice.show();
    this.clinicianservice.getPendingDocuments().subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pendingDocuments = this.commonService.decrypdata(res.objInputString);
        //  console.log(this.DecrptedlstPatient);
        this.dataSource = new MatTableDataSource(this.pendingDocuments);
        this.dataSource.paginator = this.paginator;
        if(res.length==0)
          {
            this.Data="No Record Found";
          }
      },
      (err) => {
        this.spinnerservice.hide();
      }
    );
  }
  OnViewDetails(item) {
    this.router.navigate(['/audit-clinician'], {
      queryParams: {
        id: this.commonService.FrontEndEncryption(item.PatientAccountId),
      },
    });
  }
  userFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  OnBackClick() {
    this.router.navigate(['/leadlist']);
  }
  filterChange(value) {
    let filterChange;
    if (value == '1') {
      filterChange = this.pendingDocuments.filter((x) => x.Pending != 0);
    } else if (value == '2') {
      filterChange = this.pendingDocuments.filter((x) => x.Completed != 0);
    } else if (value == '3') {
      filterChange = this.pendingDocuments.filter((x) => x.Overdue != 0);
    } 
    else if (value == '4') {
      filterChange = this.pendingDocuments.filter((x) => x.YetToStart != 0);
    } else {
      filterChange = this.pendingDocuments;
    }
    this.dataSource = new MatTableDataSource(filterChange);
    this.dataSource.paginator = this.paginator;
  }

  onListEdit(item)
  {
    
    if (item.DocumentType == 'Individual Theraphy') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(constantVariables.therapy.IndividualTheraphy)}}));
  
    // window.open(url, '_blank');  
    
    window.open(url, '_blank');  
    }
   if (item.DocumentType =='Family Theraphy') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(constantVariables.therapy.FamilyTheraphy)}}));
  
    window.open(url, '_blank');  
    }
    if (item.DocumentType =='Speech Theraphy') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(constantVariables.therapy.Speech)}}));
  
    window.open(url, '_blank');  
    }
   if (item.DocumentType =='Nutrition Theraphy') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(constantVariables.therapy.Nutrition)}}));
  
    window.open(url, '_blank');  
    }
    if (item.DocumentType =='Psychiatric Therapy') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatrictherapy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(constantVariables.therapy.PsycatricTheraphy)}}));
  
    window.open(url, '_blank');  
    }
    if (item.DocumentType =='School Meeting') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(10)}}));
  
    window.open(url, '_blank');  
    }
   
    if (item.DocumentType =='Phone call meeting') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(11)}}));
  
    window.open(url, '_blank');  
    }
    
    if (item.DocumentType =='Psychiatric Medication') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatricmedication'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(9)}}));
  
    window.open(url, '_blank');  
    }
    if (item.DocumentType =='PsyEvaMentalStatus') {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatricevaluation'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(8)}}));
  
    window.open(url, '_blank');  
    }

    
// Done
    if(item.DocumentType =='Discharge Summary')
      {
       let id = localStorage.getItem('id') 
       const url = this.router.serializeUrl(this.router.createUrlTree(['/discharge-summary'],{queryParams:{id:this.commonService.FrontEndEncryption(item.MeetingScheduleId),isedit:this.commonService.FrontEndEncryption(1),clinician:this.commonService.FrontEndEncryption(id)}}));
         window.open(url, '_blank');
      }
      if(item.DocumentType =='Treatment Summary')
      {
       let id = localStorage.getItem('id') 
       const url = this.router.serializeUrl(this.router.createUrlTree(['/treatment-summary'],{queryParams:{id:this.commonService.FrontEndEncryption(item.MeetingScheduleId),isedit:this.commonService.FrontEndEncryption(1),clinician:this.commonService.FrontEndEncryption(id)}}));
         window.open(url, '_blank');
      }
      if(item.DocumentType =='Incident Reporting')
      {
        const url = this.router.serializeUrl(this.router.createUrlTree(['/incident-report'],{queryParams:{id:this.commonService.FrontEndEncryption(item.MeetingScheduleId),isedit:this.commonService.FrontEndEncryption(1),status:this.commonService.FrontEndEncryption(1) }}));
        window.open(url, '_blank');
      }
      // doubt
      if (item.DocumentType=='Group Therapy') {
        const url = this.router.serializeUrl(this.router.createUrlTree(['/progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),dayScheduleSubGroupId: this.commonService.FrontEndEncryption(item.DayScheduleSubGroupId) }}));
    
      window.open(url, '_blank');  
      }

      if(item.DocumentType =='Review incident')
        {
          const url = this.router.serializeUrl(this.router.createUrlTree(['/incident-report'],{queryParams:{id:this.commonService.FrontEndEncryption(item.MeetingScheduleId),isedit:this.commonService.FrontEndEncryption(1),status:this.commonService.FrontEndEncryption(1) }}));
          window.open(url, '_blank');
        }
        if(item.DocumentType =='MTP Consent tab')
          {
            // this.router.navigate(['/treatmentplan'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId), location: this.commonService.FrontEndEncryption(this.LocationId) } });
            const queryParams = {
              id: this.commonService.FrontEndEncryption(item.MeetingScheduleId),
              account: this.commonService.FrontEndEncryption(item.PatientAccountId),
              patient: this.commonService.FrontEndEncryption(item.PatientInfoId),
              location: this.commonService.FrontEndEncryption(this.LocationId)
            };
            
            const urlTree = this.router.createUrlTree(['/treatmentplan'], { queryParams });
            const url = this.router.serializeUrl(urlTree);
        
            window.open(url, '_blank');
          }

          
    if (item.DocumentType =='Chmadetails') {
      // this.router.navigate(['/intake-chma'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientAccountId),patientid: this.commonService.FrontEndEncryption(item.PatientInfoId),isChma: this.commonService.FrontEndEncryption(item.MeetingScheduleId) } });
      const queryParams = {
        id: this.commonService.FrontEndEncryption(item.PatientAccountId),
        patientid: this.commonService.FrontEndEncryption(item.PatientInfoId),
        isChma: this.commonService.FrontEndEncryption(item.MeetingScheduleId)
      };
  
      const urlTree = this.router.createUrlTree(['/intake-chma'], { queryParams });
      const url = this.router.serializeUrl(urlTree);
  
      window.open(url, '_blank');
    }
   
    if (item.DocumentType =='MTP Communication Notes') {
     
      const queryParams = {
        id: this.commonService.FrontEndEncryption(item.PatientAccountId),
        notesId: this.commonService.FrontEndEncryption(item.MeetingScheduleId)
      };

      localStorage.setItem('patientAccountIdT',item.PatientAccountId);
      const url = this.router.serializeUrl(this.router.createUrlTree(['/treatment-meeting-notes'],{ queryParams }));
      window.open(url, '_blank');

      // this.router.navigate(['/treatment-meeting-notes'],{ queryParams });
    }
 
  }




}

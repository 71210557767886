import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { LoginService } from 'src/app/login/login.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient, private router: Router, public commonService: CommonService,public loginservice:LoginService) { }


  getAuthToken() {
    return localStorage.getItem('token');
  }
  
  onLogout() {
    this.loginservice.onlogoutLog();
    localStorage.clear();
    this.router.navigate(['/login']);

  }
}

import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SnackBarService } from '../services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Global } from '../global/global.model';
import { CommonService } from 'src/app/common/common.service';
import { constantVariables } from 'src/app/constant/constant';


declare function EncryptFieldData(data): any;



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  //#region  Declaration
  submitted = false;
  resetSubmit = false;
  showErrorPopup: boolean = false;
  showForgetPopup: boolean = false;
  showForgetSuccessPopup: boolean = false;
  rememberme;boolean =false;
  newPassEye=true;

  loginForm = new FormGroup({
    UserName: new FormControl(''),
    Password: new FormControl('')
  });
  ForgetForm = new FormGroup({
    UserName: new FormControl('')
  });
  //#endregion
  constructor(public global: Global, public loginService: LoginService, private router: Router,
    private formBuilder: FormBuilder, public snackbar: SnackBarService,
    public spinnerservice: NgxSpinnerService, public commonService: CommonService,private cookie:CookieService
    ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      UserName: ['', [Validators.required, this.global.cannotContainSpace]],
      Password: ['', Validators.required],
      RememberMe:[false]
    });

    // if(!!localStorage.getItem('UserName')&&!!localStorage.getItem('Password')){
    //   var UserName :string =localStorage.getItem('UserName');
    //   var Password :string =localStorage.getItem('Password');
    //   this.loginForm.controls['UserName'].setValue(UserName);
    //   this.loginForm.controls['Password'].setValue(Password);
    //   this.loginForm.controls['RememberMe'].setValue(true);
    // }else{
    //   this.loginForm.controls['UserName'].setValue('');
    //   this.loginForm.controls['Password'].setValue('');
    // }
    if(!!this.cookie.get('UserName') && !!this.cookie.get('Password')){

       var UserName:string=this.commonService.FrontEndDecryption(this.cookie.get('UserName'));
       var Password:string=this.commonService.FrontEndDecryption(this.cookie.get('Password'));
       this.loginForm.controls['UserName'].setValue(UserName.substring(1, UserName.length-1));
       this.loginForm.controls['Password'].setValue(Password.substring(1,Password.length-1));

       this.loginForm.controls['RememberMe'].setValue(true);
     }else{
       this.loginForm.controls['UserName'].setValue('');
       this.loginForm.controls['Password'].setValue('');
     } 

    this.ForgetForm = this.formBuilder.group({
      email: ['', [Validators.required,]
      ]
    })
  }

  get f3() { return this.loginForm.controls; }
  get f4() { return this.ForgetForm.controls; }
  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {

      return;
    }
    const dataToEncrypt = {
      ObjLogin: EncryptFieldData(JSON.stringify(this.loginForm.value))
    };
    this.spinnerservice.show();
    this.loginService.login(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //remember me
         if(this.loginForm.value.RememberMe){
           this.cookie.set('UserName',this.commonService.FrontEndEncryption(this.loginForm.value.UserName));
           this.cookie.set('Password',this.commonService.FrontEndEncryption(this.loginForm.value.Password));
         }else{
           this.cookie.delete('UserName');
          this.cookie.delete('Password');
         }
        localStorage.setItem('token', res.token);
        localStorage.setItem('username', res.username);
        localStorage.setItem('email', res.email);
        localStorage.setItem('roleId', res.roleId);
        localStorage.setItem('roletypeId', res.roletypeId);
        localStorage.setItem('id', res.id);
        localStorage.setItem('messageCount', res.messageCount);
        localStorage.setItem("currentstatus", res.immediateActionId);
        localStorage.setItem('pagelist', JSON.stringify(res.pageList));
        localStorage.setItem('roleaccess', JSON.stringify(res.roleaccess));
        localStorage.setItem('location', JSON.stringify(res.location));
        localStorage.setItem('isLicensed', JSON.stringify(res.isLicensed));
        localStorage.setItem('landingPage', JSON.stringify(res.roleaccess.landingPage));
        localStorage.setItem('patientName', res.patientName);
        //localStorage.setItem('landingPage',JSON.stringify(res.roleaccess.landingPage));
        console.log(localStorage.getItem('isLicensed'))
        constantVariables.role.Lead=res.roleId;
        if (res.patientId != 0 && res.patientId!=null && res.patientId !=undefined) {
          localStorage.setItem('patientId', res.patientId);
        }
        if (res.patientAccountId != 0 && res.patientAccountId!=null && res.patientAccountId !=undefined) {
          localStorage.setItem('patientAccountId', res.patientAccountId);
        }
        
        //   if (res.patientAccountId != 0) {
        //    // this.router.navigate(['/patient-breifcase']);
        //     return
        //  }
        //   if (res.isStarted == false) {
        //     this.router.navigate(['/lead'], { queryParams: { id: this.commonService.FrontEndEncryption(res.patientId) } });
        //   } else if (res.isStarted == true) {
        //     this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(res.patientId) } });
        //   }
        // } else if (res.roleId == 5 || res.roleId == 6) {
        //   this.global.ismytask =true
        //   this.router.navigateByUrl('/clinician');
        // }
        // else {
        //   this.router.navigateByUrl('/leadlist');
        // }
        if(!!(res.roleaccess.landingPage)){
          if(res.roleaccess.landingPage=='clinician')
          {                        
            this.router.navigate(['/clinician'],{ queryParams: { menu: 1 } });           
          }
          else{
            this.router.navigate(['/'+res.roleaccess.landingPage]);
          }          
        }
        else{
          this.router.navigate(['/welcome']);
        }
      },
      err => {
        this.spinnerservice.hide();
        if (err.error instanceof ProgressEvent) {
          this.snackbar.error(constantVariables.ErrorMessage.ERROR_INTERNET);
        } else {
          this.snackbar.error(err.error)
          if (err.status == 501) {
          } else {

          }
        }

      }

    );

  }
  getAuthToken() {
    return localStorage.getItem('token');
  }
  onforgotpwd() {
    this.showForgetPopup = true;
    this.showErrorPopup = false;
  }

  hideForgetSuccessPopup() {
    this.showForgetSuccessPopup = false;
    this.showErrorPopup = false;
  }
  hideErrorPopup() {
    this.showErrorPopup = false;
  }

  hideForgetPopup() {
    this.showForgetPopup = false;
    this.ForgetForm.reset();
    this.resetSubmit = false;
    this.showErrorPopup = false;
  }

  ForgetPassword() {

    this.resetSubmit = true;
    if (this.ForgetForm.invalid) {
      this.spinnerservice.hide();
      return;
    }
    const dataToEncrypt = {

      forgotPasswordModel: EncryptFieldData(JSON.stringify(this.ForgetForm.value))
    };

    let _ChangePasswordData = {
      Email: this.ForgetForm.get('email').value,
      OldPassword: null,
      Password: null,
      Username: null,
      IsResetPassword: false,
      Token: null,
      PasswordID: 0,
      Authid: null,
    };
    this.spinnerservice.show();
    this.loginService.ForgetPassword(_ChangePasswordData).subscribe(
      res => {
        this.spinnerservice.hide();
        this.showForgetPopup = false;
        this.showForgetSuccessPopup = true;
        this.showErrorPopup = false;
        this.ForgetForm.controls.UserName.setValue('');
        this.resetSubmit = false;
        this.ForgetForm.reset();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
        // console.log(err);
      }
    );

  }

}

<div  class="d-flex my-3"><div  class="ml-auto"><button  class="btn back-btn-new mr-3" tabindex="0"  (click)="OnBack()" ><i  class="fas fa-chevron-left mr-2"></i>Back</button></div></div>
<div class="container-fluid">
 
    <div class="row">
      <div class="col-sm-3">
        <div class="card p-4 fixed-left-header">
          <div class="tabs-panel">
            <h6 class="doc-head"><i class="fa fa-file mr-2"></i> Documents</h6>
            
            <div class="clear"></div>
          </div>
          <div class="panel-body tab-content">
            <div class="tab-pane documents-panel active">
            
                 <div class="clear"></div>
                 <div class="v-spacing-xs"></div><h4 class="no-margin-top"> Categories</h4>
                 <ul class="folders list-unstyled">
                  <li [class]="isIntakeDocuments ? 'active':''"> <a (click)="intakeClick()"> <i class="fa fa-folder mr-2"></i> Intake Documents </a></li>
                  <li [class]="isTreatmentDocument ? 'active':''"> <a (click)="treatmentDocumentClick()"> <i class="fa fa-folder mr-2"></i> Treatment Plan Documents </a></li>
                  <li *ngIf="isSucidalRisk" [class]="this.isSusidalRiskDocumentExist  ? 'active':''"> <a (click)="susidalRiskDocumentClick()"> <i class="fa fa-folder mr-2"></i> Susidal Risk Documents</a></li>
                  <li *ngIf="isAppointmentDocumentExists1" [class]="isAppointmentDocument ? 'active':''"> <a (click)="appointmentDocumentClick()"> <i class="fa fa-folder mr-2"></i> Appointments Documents</a></li>
                  <!-- <li> <a href="#"> <i class="fa fa-folder mr-2"></i>  </a></li>
                  <li> <a href="#"> <i class="fa fa-folder mr-2"></i>  </a></li>
                  <li> <a href="#"> <i class="fa fa-folder mr-2"></i> </a></li>
                  <li> <a href="#"> <i class="fa fa-folder mr-2"></i>  </a></li> -->
                </ul>
                <div class="v-spacing-xs"></div> 
                </div>
                </div></div></div>
                <div class="col-sm-9 tab-content no-bg no-border" *ngIf="isIntakeDocuments">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="noDocuments">
                      <div class="card">
                        <div class="file h-auto p-2">
                           <div class="document-view-list">
                            <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">No Documents Found</p>
                              <!-- <p class="w-100 mb-0">
                                <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                              </p>  -->
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="isIntakePacket">
                      <div class="card">
                        <div class="file h-auto p-2">
                           <div class="document-view-list">
                            <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Intake Packet.pdf<a (click)="onGeneratePDFIntake()" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                              <!-- <p class="w-100 mb-0">
                                <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                              </p>  -->
                              </div> 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="isPainAssesment">
                        <div class="card">
                          <div class="file h-auto p-2">
                             <div class="document-view-list">
                              <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Pain Assessment.pdf<a (click)="painAssesmentPdf()" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                <!-- <p class="w-100 mb-0">
                                  <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                </p>  -->
                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="isNutritionalQuestion">
                          <div class="card">
                            <div class="file h-auto p-2">
                               <div class="document-view-list">
                                <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Nutritional Question.pdf<a class="fil-name" (click)="getNutritionPdfFile()"><i  class="fa fa-download mr-2 " ></i>Download</a></p>
                                  <!-- <p class="w-100 mb-0">
                                    <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                  </p>  -->
                                  </div> 
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="isChma">
                            <div class="card">
                              <div class="file h-auto p-2">
                                 <div class="document-view-list">
                                  <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Prepare CHMA.pdf<a (click)="onGenerateCHMAPDF()" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                    <!-- <p class="w-100 mb-0">
                                      <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                    </p>  -->
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                           
                             
                  </div>
                  </div>
                  <div class="col-sm-9 tab-content no-bg no-border" *ngIf="isTreatmentDocument">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="noDocuments">
                        <div class="card">
                          <div class="file h-auto p-2">
                             <div class="document-view-list">
                              <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">No Documents Found</p>
                                <!-- <p class="w-100 mb-0">
                                  <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                </p>  -->
                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="isIntakePacket">
                        <div class="card">
                          <div class="file h-auto p-2">
                             <div class="document-view-list">
                              <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Intake Packet.pdf<a (click)="onGeneratePDFIntake()" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                <!-- <p class="w-100 mb-0">
                                  <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                </p>  -->
                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="isPainAssesment">
                          <div class="card">
                            <div class="file h-auto p-2">
                               <div class="document-view-list">
                                <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Pain Assessment.pdf<a (click)="painAssesmentPdf()" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                  <!-- <p class="w-100 mb-0">
                                    <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                  </p>  -->
                                  </div> 
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="isNutritionalQuestion">
                            <div class="card">
                              <div class="file h-auto p-2">
                                 <div class="document-view-list">
                                  <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Nutritional Question.pdf<a class="fil-name" (click)="getNutritionPdfFile()"><i  class="fa fa-download mr-2 " ></i>Download</a></p>
                                    <!-- <p class="w-100 mb-0">
                                      <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                    </p>  -->
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 mb-2" *ngIf="isChma">
                              <div class="card">
                                <div class="file h-auto p-2">
                                   <div class="document-view-list">
                                    <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Prepare CHMA.pdf<a (click)="onGenerateCHMAPDF()" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                      <!-- <p class="w-100 mb-0">
                                        <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                      </p>  -->
                                      </div> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                               
                    </div>
                    </div>
                    <div class="col-sm-9 tab-content no-bg no-border" *ngIf="isSusidalRiskDocumentExist">
   
                                <div  *ngIf="isSucidalRisk">
                                <div  *ngFor="let item of lstDocuments; let i=index;">
                                  <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                                  <div class="card" >
                                    <div class="file h-auto p-2">
                                       <div class="document-view-list">
                                        <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">{{item?.documentName}}<a (click)="OnDownloadSusidalRisk(item)" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                          <!-- <p class="w-100 mb-0">
                                            <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                          </p>  -->
                                          </div> 
                                          <!-- <div class="col-md-12 file_manager" *ngIf="isSusidalRiskDocument">
                                            <div class="row">
                                              <div class="col-lg-3 col-md-4 col-sm-12" >
                                                <div class="card">
                                                  <div class="file"> 
                                                    <a (click)="OnDownloadSusidalRisk(item)">
                                                      <div class="hover"> 
                                                        <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                                                      </div>
                                                      <div class="file-name">
                                                        <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>{{item?.documentName}}</p> 
                                                        <small>Size: 42KB <span class="date text-muted">{{item?.createdDate | date:'mediumDate' }}</span></small>
                                                      </div> 
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                     
                                            </div>
                                          </div> -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                 </div>
                               
                      </div>
                      </div>
                      <div class="col-sm-9 tab-content no-bg no-border" *ngIf="isAppointmentDocument">
   
                        <div>
                        <div  *ngFor="let item of appoinmentDocumentList; let i=index;">
                          <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                          <div class="card" >
                            <div class="file h-auto p-2">
                               <div class="document-view-list">
                                <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">{{item?.ScheduleName}}<a (click)="OnDownloadAppointment(item)" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                  <!-- <p class="w-100 mb-0">
                                    <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                  </p>  -->
                                  </div> 
                                  <!-- <div class="col-md-12 file_manager" *ngIf="isSusidalRiskDocument">
                                    <div class="row">
                                      <div class="col-lg-3 col-md-4 col-sm-12" >
                                        <div class="card">
                                          <div class="file"> 
                                            <a (click)="OnDownloadSusidalRisk(item)">
                                              <div class="hover"> 
                                                <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                                              </div>
                                              <div class="file-name">
                                                <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>{{item?.documentName}}</p> 
                                                <small>Size: 42KB <span class="date text-muted">{{item?.createdDate | date:'mediumDate' }}</span></small>
                                              </div> 
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                             
                                    </div>
                                  </div> -->
                                </div>
                              </div>
                            </div>
                          </div>
                         </div>
                       
              </div>
              </div>
                </div>
              </div>
                  
                  <div>
                    <a mimetype="application/octet-stream" #downloadLink></a>
                    <a mimetype="application/octet-stream" #mainpage></a>
                </div>
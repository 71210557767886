import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MasterService } from 'src/app/common/pages//masters/master.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { CommonService } from 'src/app/common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm, Validators } from '@angular/forms';
import { phonebook } from 'src/app/_models/_Lead/phonebook';
import { LeadService } from 'src/app/patient-lead/lead.service';
import { Global } from 'src/app/global/global.model';
import { cloneDeep } from "lodash";
import {FormControl} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import {Observable} from 'rxjs';
import {   startWith, map } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';

@Component({
  selector: 'app-phone-book',
  templateUrl: './phone-book.component.html',
  styleUrls: ['./phone-book.component.scss']
})
export class PhoneBookComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('errorpaginator') paginatorimport: MatPaginator;
  @ViewChild(MatSort) sortimport: MatSort;

  @ViewChild('importphbook') eleimportphbook: ElementRef;
  displayedColumns = ["id", "firstName", "lastName", "phoneNumber", "hospitalName", "hospitalAddress", "Actions"];
  dataSource: MatTableDataSource<any>;

  displayedColumnsimport = ["id", "firstName", "lastName", "phoneNumber", "hospitalName", "hospitalAddress", "Actions"];
  dataSourceimport: any=[];
  public data = [{ id: 1, name: "mohan", Phone: "phone", Hospital: "apollo", Address: "Chennai" }, { id: 2, name: "mohan", Phone: "phone", Hospital: "apollo", Address: "Chennai" }];
  phonebook: any;
  phonebookData: phonebook;
  isEditPhonebook: boolean = false;
  showImport: boolean = false;
  UploadFileList = [];
  ddlStates: any;
  ddlStatesMaster: any;
  ddlCities: any;
  ddlcityMaster: any;
  importfails: any;
  showimportfailure: boolean = false;
  isEditSummaryPhonebook: boolean = false;
  editSummarId: any;
  importCount: any;
  importSCount: any;
  importFCount: any;
  istemplatedownloaded:boolean=false;
  showcity=false;

  cityCtrl= new FormControl();
  selectedCityId = new FormControl();
  //filteredcities: Observable<any[]>;
//  filteredcities: Observable<any>;
  
  filteredcities = <any>[];
  // selectedCityId=0;
  selectedLocation:any;
  roleAccess: any;
  constructor(private masterservice: MasterService, private route: ActivatedRoute, public commonService: CommonService,
    private snackbar: SnackBarService, public spinnerservice: NgxSpinnerService,
    private dialog: MatDialog, public leadservice: LeadService, public global: Global
    ,public roleaccessservice:PageAccessService) {
         this.cityCtrl = new FormControl();
         this.selectedCityId = new FormControl('',Validators.required);
          }

  ngOnInit(): void {

    this.phonebookData = new phonebook();
    const pageid = constantVariables.pagelists.Settings;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.getddlStateMaster();
    this.getphonebook();
    this.updateDownloadedMTemplate();

   this.cityCtrl.valueChanges.subscribe(
        term => {
          if (term != '') {
            this.masterservice.getSearchCity(term).subscribe(
              data => {
                  this.filteredcities =this.commonService.decrypdata(data.objInputString);
                  //this.phonebookData.cityId = term;
                
             })
          }
      });
     
  }

  getphonebook() {
   this.selectedLocation=(Number(localStorage.getItem('sfcclocation'))<=0)?-1:Number(localStorage.getItem('sfcclocation'));
    this.masterservice.getPhonebook(this.selectedLocation).subscribe(
      res => {
        if (res) {
          this.phonebook = res;
          this.dataSource = new MatTableDataSource(this.phonebook);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      })
  }
  addorUpdate(phbookform) {
    this.phonebookData.cityId=this.phonebookData.cityId == 0 ?  null:this.phonebookData.cityId;
    if(Number(localStorage.getItem('sfcclocation'))<=0){
      this.snackbar.error("Please choose a location");
      return;
   }
   if(!phbookform.valid){
    return;
   }
    // if (this.selectedCityId.value==0)
    // {
    //   this.snackbar.error("Select country");
    //   return;
    // }

    this.phonebookData.SFCCMasterId=Number(localStorage.getItem('sfcclocation'));
  
    // this.phonebookData.cityId=this.selectedCityId;
    this.spinnerservice.show();

    const dataToEncrypt = this.commonService.DataToEncrypt(this.phonebookData, false);
    this.masterservice.postPhonebook(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        this.snackbar.success(res);
        this.getphonebook();
        if (this.isEditSummaryPhonebook) {
          this.onClearImportFailure()
        }
        this.resetphonebookform(phbookform)
      }
    },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      }
    );
  }
  onClearImportFailure() {
    this.importfails.splice(this.editSummarId, 1);
    this.dataSourceimport = new MatTableDataSource(this.importfails);
    this.dataSourceimport.paginator = this.paginatorimport;
    this.dataSourceimport.sort = this.sortimport;
  }
  resetphonebookform(form: NgForm) {
    this.phonebookData = new phonebook();
    form.resetForm();
    this.isEditPhonebook = false;
    this.isEditSummaryPhonebook = false;
    this.editSummarId = null;
    this.selectedCityId.reset();
    // this.selectedCityId=0;
    this.cityCtrl.reset();
    // this.showimportfailure = false;

  }
  oneditPhonebook(element) {

    this.isEditPhonebook = true;
    // if (element.statesId) {
    //   this.ddlCities = this.ddlcityMaster.filter((item) => item.StatesId == element.statesId);
    // }

    // this.commonService.getcities(element.statesId).subscribe(
    //   res => {
    //     this.ddlCities = this.commonService.decrypdata(res.cities);;
    //   },
    //   error =>
    //     this.spinnerservice.hide()
    // );
    this.spinnerservice.show();
    this.masterservice.getSearchCity("AllValues").subscribe(
      data => {
        this.spinnerservice.hide();
        this.filteredcities =this.commonService.decrypdata(data.objInputString);
        // this.selectedCityId=this.filteredcities.find(x=>x.CitiesId==element.cityId).CitiesId
        this.selectedCityId.setValue(this.filteredcities?.find(x=>x.CitiesId==element.cityId)?.CitiesId);
        this.phonebookData.cityId = this.selectedCityId.value;
        this.phonebookData.cityId=this.phonebookData.cityId == 0 ?  null:this.phonebookData.cityId;
        },
    error =>this.spinnerservice.hide());

    
    this.phonebookData = cloneDeep(element);
  }
  ondeletePhonebook(element) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.ondeletephbook(element);

      }
    });

  }
  ondeletephbook(element) {
    this.masterservice.deletePhonebook(element.phoneBookMasterId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.getphonebook();
        this.snackbar.success(res.message);
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  Import() {
    this.showImport = !this.showImport;
  }
  removeSelectedImport(index: number) {
    this.UploadFileList.splice(index, 1);
  }
  onFileChange(event) {

    const reader = new FileReader();
    var _validFileExtensions = [".xls", ".xlsx", ".csv"];
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (file.size > 5020905) {
        this.snackbar.error(constantVariables.ErrorMessage.filesize)
        return
      }
      if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        || file.type == 'application/vnd.ms-excel' || file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        reader.readAsDataURL(file);

        reader.onload = () => {

          var files = Array.from(event.target.files);

          for (var i = 0; i < files.length; i++) {
            if (this.UploadFileList.length < 3) {

              this.UploadFileList.push(files[i]);
            }
          }

          this.eleimportphbook.nativeElement.value = "";
        };
      } else {
        this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
      }
    }
  }
  onImportPhoneBook() {
    this.spinnerservice.show();
    if (this.UploadFileList && this.UploadFileList.length == 0) {
      this.snackbar.error(constantVariables.ErrorMessage.UploadinfoError);
      this.spinnerservice.hide();
      return;
    } else {

      const frmData = new FormData();
      for (var i = 0; i < this.UploadFileList.length; i++) {
        frmData.append("doc", this.UploadFileList[i]);
      }
      frmData.append('DocumentTypeId', constantVariables.DocumentTypeId.PhoneBook);
      this.masterservice.postimportPhbook(frmData).subscribe((event: any) => {

        if (event) {

          this.spinnerservice.hide();
          this.getphonebook();
          this.snackbar.success(constantVariables.SuccessMessage.phbookImport);
          // this.snackbar.success(event.message);
          this.UploadFileList = [];
          this.importfails = [];
          this.showImport = false;
          if (event.responses && event.responses.phoneBooks.length > 0) {
            this.importfails = event.responses.phoneBooks;
            if (this.importfails && this.importfails.length > 0) {
              this.showimportfailure = true;
              this.importCount = event.responses.total;
              this.importFCount = event.responses.failed;
              this.importSCount = event.responses.success;
            }
            //this.paginatorimport.length=this.importfails.length;
            this.dataSourceimport = new MatTableDataSource(this.importfails);
            this.dataSourceimport.paginator = this.paginatorimport;
            this.dataSourceimport.sort = this.sortimport;
          }
        }
      }, err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      });
    }
  }
  getddlStateMaster() {
    this.spinnerservice.show();
    this.leadservice.getstateMaster().subscribe(
      res => {
        if (res != null) {
          this.ddlStates = this.commonService.decrypdata(res.states);
          this.ddlStatesMaster = this.commonService.decrypdata(res.states);
          //this.ddlcityMaster = this.commonService.decrypdata(res.cities);

          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  // onChangeState(state) {
  //   this.commonService.getcities(state.value).subscribe(
  //     res => {
  //       this.ddlCities = this.commonService.decrypdata(res.cities);;
  //     },
  //     error =>
  //       this.spinnerservice.hide()
  //   );
  //   //this.ddlCities = this.ddlcityMaster.filter((item) => item.StatesId == state.value);

  // }
  updateDownloadedMTemplate() {
    this.masterservice.DownloadedMTemplate(constantVariables.DocumentTypeId.PhoneBookImport).subscribe(
      res => {
        this.global.MasterTemplateImportlbl = constantVariables.lblMessage.lblMessage;
        this.global.ImportTemplateUrl = res.masterTemplatePath1;
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  ondownloadtemplate(){
    this.istemplatedownloaded =true
  }
  oneditsummary(element, index) {
    this.global.scrollup();
    this.isEditSummaryPhonebook = true;
    this.editSummarId = index;
    this.phonebookData = cloneDeep(element);
    this.phonebookData.cityId=this.phonebookData.cityId == 0 ?  null:this.phonebookData.cityId;
    // this.showimportfailure = false;
    if (element.statesId != 0) {
      this.phonebookData.statesId = element.statesId;
      this.commonService.getcities(element.statesId).subscribe(
        res => {
          this.ddlCities = this.commonService.decrypdata(res.cities);
              //this.showimportfailure = false;

          if (element.cityId != 0) {
            this.phonebookData.cityId = this.ddlCities.filter((x) => x.CityName == element.cityId)[0]?.CitiesId;
          }
        },
        error =>
          this.spinnerservice.hide()
      );
    }
  }

  getCityname(cityId: string) {
   
    if(cityId!="" && cityId!= null && cityId!="0"){
      let selectedItem;
      selectedItem=this.filteredcities?.find(x => x.CitiesId === cityId);
      // this.selectedCityId=this.filteredcities.find(x => x.CitiesId === cityId).CitiesId;
      // this.phonebookData.cityId= this.selectedCityId;
      this.selectedCityId.setValue(this.filteredcities?.find(x => x.CitiesId === cityId)?.CitiesId);
      this.phonebookData.cityId= this.selectedCityId.value;
      if(this.ddlStates!="undefined" && this.ddlStates.length>0){
      this.phonebookData.statesId= this.ddlStates?.find(y=>y.StatesId==selectedItem.StatesId)?.StatesId;
      }
    return this.filteredcities?.find(x => x.CitiesId === cityId)?.CityName;
    }
  }

}

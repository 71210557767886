<div class="psycharist-progress">
  <div class="col-md-12">
    <div class="d-flex met-info-top w-100">
      <i  class="fas fa-user"></i>
      <div  class="mr-4">Client Name <span  class="imp-info">    {{_dtoPatientInfo.patientName }} </span></div>
      <div  class="mr-4">Psychiatrist Name <span  class="imp-info">    {{_dtoPatientInfo.clinicianName}} </span></div>
      <div  class="mr-4"> Appointment Date <span  class="imp-info">     {{_dtoPatientInfo.startDate | date:'MM/dd/YYYY'}} </span></div>
      <div  class="mr-4"> Appointment Time <span  class="imp-info">    {{_dtoPatientInfo.startTime | date:'hh:mm a'}}</span></div>
      
      
      
      <div  class="ml-auto">
        <button  type="button" mat-stroked-button class="mat-focus-indicator bg-white top-back mt-1 mb-2 h-40 mr-2 mat-stroked-button mat-button-base" (click)="OnbackClick()">Back</button>
      </div>
      </div>
  </div>
  <div class="col-md-12  mt-3">
    <div class="lead-box pt-2 pb-2">
        
      <div class="tab-history custom-tab-border mt-2">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item mr-1" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#progress" type="button" role="tab" aria-controls="s1" aria-selected="true" (click)="OnTabchange(1)"  [ngClass]="{'active show': SelectedTab == 1}" >Psychiatric Evaluation</button>
          </li>
          <li class="nav-item mr-1" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#Mentalstatus" type="button" role="tab" aria-controls="s3" aria-selected="true" (click)="OnTabchange(2)"  [ngClass]="{'active show': SelectedTab == 2}" >Mental Status</button>
          </li>
          <li class="nav-item mr-1" role="presentation">
            <button class="nav-link" id="goals-tab" data-bs-toggle="tab" data-bs-target="#goals" type="button" role="tab" aria-controls="s3" aria-selected="true" (click)="OnTabchange(3)"  [ngClass]="{'active show': SelectedTab == 3}" >Goals</button>
          </li>
          
        </ul>
        
        <div class="tab-content">
          <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==1" [ngClass]="{'disabled':progressUpg == true }">
            <form (ngSubmit)="PsyEvaFrm.form.valid && PostPsyEvaFrm(1)" id="PsyEvaFrm" autocomplete="off" #PsyEvaFrm="ngForm"
            novalidate>
           <div class="col-md-12 mt-3">
             <div class="row">
              <div  class="d-flex w-100">
                <div  class="box-lead-head mb-2 ml-3">Psychiatric Evaluation Form</div>
                <div  class="ml-auto mr-2">
                  <button  type="button" class="btn primary-btn-outlined-small mr-2 float-left" (click)="OnpreviewClick()">
                    <i class="far fa-eye mr-2"></i>Preview</button>
                    <button type="button" class="btn primary-btn-outlined-small mr-2 float-left" (click)="OnDownload(0)"><i class="fas fa-file-alt mr-2"></i>Generate Pdf</button>
                    <button  form="PsyEvaFrm" type="submit" class="btn primary-btn-outlined-small ng-star-inserted" *ngIf="roleAccess.isCreate||roleAccess.isEdit">
                      <i  class="far fa-save mr-2"></i>{{IseditPsyEva?'Save':'Update'}}</button>
                <button  class="export-pdf-btn btn"  mat-type="button" (click)="OnDownload(1)"><i class="fa fa-print"></i></button>

                    </div>
                  </div>
             </div>
             
           </div>
           <div class="">
             
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4"  [ngClass]="{'disabled': true }">
                     <mat-form-field class="w-100">
                       <mat-label>Client Name </mat-label>
                       <input matInput [(ngModel)]="_dtoPatientInfo.patientName"
                       #patientName="ngModel" name="patientName">
                     </mat-form-field>
                    </div>
                    <div class="col-md-2"  [ngClass]="{'disabled': true }">
                      <mat-form-field class="w-100">
                        <mat-label>Date of Birth</mat-label>
                        <input matInput [matDatepicker]="picker" [(ngModel)]="_dtoPatientInfo.dob"
                        #dob="ngModel" name="dob">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                     </div>
                    <div class="col-md-4"  [ngClass]="{'disabled': true }">
                      <mat-form-field class="w-100">
                        <mat-label>Evaluating Psychiatrist </mat-label>
                        <input matInput [(ngModel)]="_dtoPatientInfo.clinicianName"
                        #clinicianName="ngModel" name="clinicianName">
                      </mat-form-field>
                     </div>
                    
                    <div class="col-md-2">
                     <mat-form-field class="w-100">
                       <mat-label>Date of Evaluation</mat-label>
                       <input matInput [matDatepicker]="Evapicker" [(ngModel)]="PsyEvaInfo.evaDate"
                       #evaDate="ngModel" name="evaDate" [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && evaDate.invalid }" required>
                       <mat-datepicker-toggle matSuffix [for]="Evapicker"></mat-datepicker-toggle>
                       <mat-datepicker #Evapicker></mat-datepicker>
                       <mat-error *ngIf="PsyEvaFrm.submitted && evaDate.invalid">
                        <mat-error  *ngIf="evaDate.errors.required">
                          Date of Evaluation is required</mat-error>
                    </mat-error>
                     </mat-form-field>
                    </div>
                   
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Identification</label>
                  <div  class="form-group">
                    <textarea  class="form-control " ng-reflect-name="txt_clinical" [(ngModel)]="PsyEvaInfo.identification" #identification="ngModel" name="identification" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && identification.invalid }" required ></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && identification.invalid">
                    <mat-error class="text-xs" *ngIf="identification.errors.required">
                      Identification  is required</mat-error>
                </mat-error>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Chief complaint</label>
                  <div  class="form-group">
                    <textarea  class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaInfo.chiefComplaint" #chiefComplaint="ngModel" name="chiefComplaint" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && chiefComplaint.invalid }" required ></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && chiefComplaint.invalid">
                    <mat-error class="text-xs" *ngIf="chiefComplaint.errors.required">
                      Chief complaint  is required</mat-error>
                </mat-error>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">History of Presenting Illness</label>
                  <div  class="form-group">
                    <textarea  class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaInfo.presentingIllness" #presentingIllness="ngModel" name="presentingIllness" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && presentingIllness.invalid }" required ></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && presentingIllness.invalid">
                    <mat-error class="text-xs" *ngIf="presentingIllness.errors.required">
                      History of Presenting Illness is required</mat-error>
                </mat-error>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Past Psychiatric History	</label>
                  <div  class="form-group">
                    <textarea   class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaInfo.psychiatricHistory" #psychiatricHistory="ngModel" name="psychiatricHistory" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && psychiatricHistory.invalid }" required ></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && psychiatricHistory.invalid">
                    <mat-error class="text-xs"  *ngIf="psychiatricHistory.errors.required">
                      Past Psychiatric History is required</mat-error>
                </mat-error>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">ETOH & Substance Abuse History</label>
                  <div  class="form-group">
                    <textarea   class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaInfo.etoh" #etoh="ngModel" name="etoh" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && etoh.invalid }" required ></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && etoh.invalid">
                    <mat-error class="text-xs" *ngIf="etoh.errors.required">
                      ETOH & Substance Abuse History is required</mat-error>
                </mat-error>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Medical History</label>
                  <div  class="form-group">
                    <textarea  class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaInfo.medicalHistory" #medicalHistory="ngModel" name="medicalHistory" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && medicalHistory.invalid }" required ></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && medicalHistory.invalid">
                    <mat-error class="text-xs" *ngIf="medicalHistory.errors.required">
                      Medical History is required</mat-error>
                </mat-error>
                </div>
                <!-- <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Current Medications</label>
                  <div  class="form-group">
                    <textarea   class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaInfo.currentMedications" #currentMedications="ngModel" name="currentMedications" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && currentMedications.invalid }" required></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && currentMedications.invalid">
                    <mat-error class="text-xs" *ngIf="currentMedications.errors.required">
                      Current Medications is required</mat-error>
                </mat-error>
                </div> -->
                <!--<div class="col-md-12 mt-3 mb-3">
      <div class="medication-dosage">
        <div  class="row inputElement">
          <div  class="col-md-3">
            <label >Medications</label>
          </div>
          <div  class="col-md-3">
            <label >Dosage</label>
          </div>
          <div  class="col-md-3">
            <label>Frequency</label>
          </div>
          <div  class="col-md-3">
            <label >Side effects</label>
          </div>
        </div>
        <div  class="loop-section position-relative mb-2 ">
          <div class="row inputElement">
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Medicine" type="text" required="" class="form-control "  >
            </div>
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Dosage" type="text" required="" class="form-control "  >
            </div>
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Frequency" type="text" required="" class="form-control "  >
            </div>
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Side effects" type="text" required="" class="form-control "  >
            </div>
          </div>
          <a  class="add " style="cursor: pointer;">
            <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
          </a>
          <a  class="remove" style="cursor: pointer;">
            <i  class="fa fa-lg fa-minus-circle"></i>
            <span  class="sr-only">Remove</span>
          </a>
        </div>
      </div>
    </div>-->
    <div class="medication col-md-12 mt-3 mb-3 ">
      <div class="medication-dosage">
      <div class="row inputElement" *ngIf="_dtoMedications.length > 0 ">
        <div class="col-md-3"><label>Medications</label></div>
        <div class="col-md-3"><label>Dosage</label></div>
        <div class="col-md-3"><label>Frequency</label></div>
        <div class="col-md-3"><label>Side effects</label></div>
      </div>
      <div class="loop-section position-relative mb-2"
        *ngFor="let med of _dtoMedications;let i= index;let last=last">
        <div class="row  inputElement">
          <div class="col-md-3">
            <input class="form-control" maxlength="50" [(ngModel)]="med.medications" #medications="ngModel"
              name="medications{{i}}" placeholder="Medicine" type="text">
        
          </div>
          <div class="col-md-3"><input class="form-control" maxlength="30" [(ngModel)]="med.dosage"
              #dosage="ngModel" name="dosage{{i}}" placeholder="Dosage" type="text"  >
          
          </div>
          <div class="col-md-3">
            <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
              [(ngModel)]="med.frequency" #frequency="ngModel" name="frequency{{i}}" >
              <option value="null">--Select Frequency--</option>
              <option value="1">Daily</option>
              <option value="2">Weekly</option>
              <option value="3">Monthly</option>
            </select>
          </div>
          <div class="col-md-3"><input class="form-control" maxlength="50" [(ngModel)]="med.sideEffects"
              #sideEffects="ngModel" name="sideEffects{{i}}" placeholder="Sideeffects" type="text" >
          </div>
        </div>
        <a class="add" style="cursor: pointer;" *ngIf="last" (click)="addMedicationInfo()"><i
          class="fa fa-lg fa-plus-circle"></i><span class="sr-only">Add</span></a>
          <a class="remove" style="cursor: pointer;"  (click)="removeclinicalinformation(med,i)"><i
            class="fa fa-lg fa-minus-circle"></i><span class="sr-only">Remove</span></a>
      </div>
      <div class="col-md-12 set1-temp" *ngIf="_dtoMedications.length == 0">
        <div class=" col-md-12 row">
            <div class="col-md-6 p-0">
                <label class="additional">Medication history not available, would you like to
                    add?</label>

            </div>
            <div class="col-md-4 p-0">
                <button type="button" (click)="addMedicationInfo()" mat-stroked-button
                    class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Add</button>
            </div>
        </div>
    </div>
    </div>
    </div>
    
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Comprehensive Medication History</label>
                  <div  class="form-group">
                    <textarea   class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaInfo.comprehensiveMedication" #comprehensiveMedication="ngModel" name="comprehensiveMedication" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && comprehensiveMedication.invalid }" required ></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && comprehensiveMedication.invalid">
                    <mat-error class="text-xs" *ngIf="comprehensiveMedication.errors.required">
                      Comprehensive Medication History is required</mat-error>
                </mat-error>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Developmental History</label>
                  <div  class="form-group">
                    <textarea  class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaInfo.developmentalHistory" #developmentalHistory="ngModel" name="developmentalHistory" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && developmentalHistory.invalid }" required ></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && developmentalHistory.invalid">
                    <mat-error class="text-xs" *ngIf="developmentalHistory.errors.required">
                      Developmental History is required</mat-error>
                </mat-error>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Psychosocial History</label>
                  <div  class="form-group">
                    <textarea   class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaInfo.psychosocialHistory" #psychosocialHistory="ngModel" name="psychosocialHistory" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && psychosocialHistory.invalid }" required ></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && psychosocialHistory.invalid">
                    <mat-error class="text-xs" *ngIf="psychosocialHistory.errors.required">
                      Psychosocial History is required</mat-error>
                </mat-error>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Family History</label>
                  <div  class="form-group">
                    <textarea   class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaInfo.familyHistory" #familyHistory="ngModel" name="familyHistory" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && familyHistory.invalid }" required ></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && familyHistory.invalid">
                    <mat-error class="text-xs" *ngIf="familyHistory.errors.required">
                      Family History is required</mat-error>
                </mat-error>
                </div> 
                <div class="col-md-12">
                  <div class="form-group clearfix m-0">
                    <div class="float-left col-md-4 p-0 mt-3 pt-1">
                      <label class="control-label-new font-weight-bold">Severity Level:</label>
                      <mat-select #severity="ngModel" name="severity" [(ngModel)]="PsyEvaInfo.severity" [ngStyle]="{'color':bg}" (selectionChange)="onSelectionChanged($event.value)" placeholder="Select Severity">
                        <!-- <mat-option [value]="0"> 0 - Discharged </mat-option>
                        <mat-option [value]="1"> 1 - Mild </mat-option>
                        <mat-option [value]="2"> 2 - Medium  </mat-option>
                        <mat-option [value]="3"> 3 - Critical </mat-option> -->
                        <mat-option *ngFor="let item of ddlseverity" [ngStyle]="{'color': item.colocode}"  [value]="item.severityValue">{{item.severityLable}}</mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-md-12">
                  <div class="form-group clearfix m-0">
                    <div class="float-left col-md-4 p-0 mt-3 pt-1">
                      <label class="control-label-new font-weight-bold">Severity Level:</label>
                      <mat-select #severity="ngModel" name="severity" [(ngModel)]="PsyEvaInfo.severity" [ngStyle]="{'color':bg}" (selectionChange)="onSelectionChanged($event.value)" placeholder="Select Severity">
                         <mat-option [value]="0"> 0 - Discharged </mat-option>
                        <mat-option [value]="1"> 1 - Mild </mat-option>
                        <mat-option [value]="2"> 2 - Medium  </mat-option>
                        <mat-option [value]="3"> 3 - Critical </mat-option> -->
                        <!-- <mat-option *ngFor="let item of ddlseverity" [ngStyle]="{'color': item.colocode}"  [value]="item.severityValue">{{item.severityLable}}</mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div> --> 

                <!-- <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Family History</label>
                  <div  class="form-group">
                    <textarea   class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaInfo.familyHistory" #familyHistory="ngModel" name="familyHistory" row="3" maxlength="800"
                    [ngClass]="{ 'is-invalid': PsyEvaFrm.submitted && familyHistory.invalid }" required ></textarea>
                  </div>
                  <mat-error *ngIf="PsyEvaFrm.submitted && familyHistory.invalid">
                    <mat-error class="text-xs" *ngIf="familyHistory.errors.required">
                      Family History is required</mat-error>
                </mat-error>
                </div> 
                <div class="col-md-12">
                  <div class="form-group clearfix m-0">
                    <div class="float-left col-md-4 p-0 mt-3 pt-1">
                      <label class="control-label-new font-weight-bold">Severity Level:</label>
                      <mat-select #severity="ngModel" name="severity" [(ngModel)]="PsyEvaInfo.severity" [ngStyle]="{'color':bg}" (selectionChange)="onSelectionChanged($event.value)" placeholder="Select Severity">
                         <mat-option [value]="0"> 0 - Discharged </mat-option>
                        <mat-option [value]="1"> 1 - Mild </mat-option>
                        <mat-option [value]="2"> 2 - Medium  </mat-option>
                        <mat-option [value]="3"> 3 - Critical </mat-option> 
                        <mat-option *ngFor="let item of ddlseverity" [ngStyle]="{'color': item.colocode}"  [value]="item.severityValue">{{item.severityLable}}</mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div> -->
           </div>
          </form>
          </div>
          <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==2" [ngClass]="{'disabled':progressUpg == true }">
            <form (ngSubmit)="PsyEvaMSFrm.form.valid" id="PsyEvaMSFrm" autocomplete="off" #PsyEvaMSFrm="ngForm"
            novalidate>
            <div class="col-md-12 mt-3" >
              <div class="row">
               <div  class="d-flex w-100">
                 <div  class="box-lead-head mb-2 ml-3">Mental Status </div>
                 <div  class="ml-auto mr-2">
                   <button  type="button" class="btn primary-btn-outlined-small mr-2 float-left" (click)="OnpreviewMS()">
                     <i class="far fa-eye mr-2"></i>Preview</button>
                     <button type="button" class="btn primary-btn-outlined-small mr-2 float-left" (click)="OnDownloadMS(0)"><i class="fas fa-file-alt mr-2"></i>Generate Pdf</button>
                     <button  form="PsyEvaMSFrm" type="submit" class="btn primary-btn-outlined-small ng-star-inserted" (click)="postPsyEvaMentalStatus(1)" *ngIf="roleAccess.isCreate||roleAccess.isEdit">
                       <i  class="far fa-save mr-2"></i>{{IseditPsyEvaMS?'Save':'Update'}}</button>
                <button  class="export-pdf-btn btn"  mat-type="button" (click)="OnDownloadMS(1)"><i class="fa fa-print"></i></button>

                     </div>
                   </div>
              </div>
              
            </div>
            <div class="col-md-12">
              <label  class="control-label-new font-weight-bold">Appearance: (Posture, Facial Expressions, Tics, Eye Contact, Weight, Height, Hygiene)</label>
              <div  class="form-group row check-fields-large-label">
                <div class="col-md-3"  *ngFor="let item of _mentalStatus.appearance;let i=index">
                  <div class="check-custom" >
                    <mat-checkbox class="example-margin" name="appearance{{i}}" [(ngModel)]="item.isChecked" >{{item.clinicalIssues}}</mat-checkbox>
                  </div>
                </div>
                <!--<div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Well Groomed</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Well Nourished</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2"> Adequate Hygiene</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Older Than Stated Age</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Younger Than Stated Age</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Appeared Stated Age</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Disheveled</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Malodorous</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Average Weight</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Overweight</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Obese</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Thin</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Malnourished</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Short in Stature</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Tall</mat-checkbox>
                  </div>
                </div>-->
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="check-custom mt-2">
                        <mat-checkbox class="example-margin mr-2" [(ngModel)]="PsyEvaMSInfo.isTatoos" #isTatoos="ngModel" name="isTatoos" (change)="Onappearance(2)">Tatoos</mat-checkbox>
                      </div>
                    </div>
                    <div class="col-md-9 " *ngIf="PsyEvaMSInfo.isTatoos==true">
                      <div  class="form-group">
                        <textarea   rows="1" class="form-control text-xs font-italic" placeholder="Enter tatoo details" [(ngModel)]="PsyEvaMSInfo.tatoosDetails" #tatoosDetails="ngModel" name="tatoosDetails" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && tatoosDetails.invalid }" required maxlength="200"   ></textarea>
                        <mat-error *ngIf="PsyEvaMSFrm.submitted && tatoosDetails.invalid">
                          <mat-error class="text-xs" *ngIf="tatoosDetails.invalid">
                           This field is required</mat-error>
                      </mat-error>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                <div class="col-md-3">
                  <div class="check-custom mt-2">
                    <mat-checkbox class="example-margin mr-2" [(ngModel)]="PsyEvaMSInfo.isBirthmarks" #isBirthmarks="ngModel" name="isBirthmarks" (change)="Onappearance(2)" >Birthmarks</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-9 " *ngIf="PsyEvaMSInfo.isBirthmarks==true">
                  <div  class="form-group">
                    <textarea  rows="1" maxlength="200" class="form-control text-xs font-italic" placeholder="Enter birthmark details" [(ngModel)]="PsyEvaMSInfo.birthmarks" #birthmarks="ngModel" name="birthmarks" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && birthmarks.invalid }" required   ></textarea>
                    <mat-error *ngIf="PsyEvaMSFrm.submitted && birthmarks.invalid">
                      <mat-error class="text-xs" *ngIf="birthmarks.invalid">
                       This field is required</mat-error>
                  </mat-error>
                  </div>
                </div>
                </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                <div class="col-md-3">
                  <div class="check-custom mt-2">
                    <mat-checkbox class="example-margin mr-2" [(ngModel)]="PsyEvaMSInfo.isDentalCondition" #isDentalCondition="ngModel" name="isDentalCondition"  (change)="Onappearance(2)">Dental Condition</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-9 " *ngIf="PsyEvaMSInfo.isDentalCondition==true">
                  <div  class="form-group">
                    <textarea  rows="1" class="form-control text-xs font-italic" placeholder="Enter dental condition details" maxlength="200" [(ngModel)]="PsyEvaMSInfo.dentalCondition" #dentalCondition="ngModel" name="dentalCondition" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && dentalCondition.invalid }" required    ></textarea>
                    <mat-error *ngIf="PsyEvaMSFrm.submitted && dentalCondition.invalid">
                      <mat-error class="text-xs" *ngIf="dentalCondition.invalid">
                       This field is required</mat-error>
                  </mat-error>
                  </div>
                </div>
                </div>
                </div>
                <div class="col-md-12">
                <mat-error *ngIf="PsyEvaMSFrm.submitted && !AppearanceGiven">
                  <mat-error class="text-xs" *ngIf="!AppearanceGiven">
                   This field is required</mat-error>
              </mat-error>
            </div>
              </div>
            </div>
           <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Motor Activity: (Shaking Foot, Tapping, Tics, Fidgety)</label>
            <div  class="form-group">
              <textarea   rows="3" class="form-control " ng-reflect-name="txt_clinical"  [(ngModel)]="PsyEvaMSInfo.motorActivity" #motorActivity="ngModel" name="motorActivity" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && motorActivity.invalid }" required maxlength="800"   ></textarea>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && motorActivity.invalid">
                <mat-error class="text-xs"  *ngIf="motorActivity.invalid">
                  Motor Activity is required</mat-error>
            </mat-error>
            </div>
           </div>
           <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Facial Expressions</label>
            <div  class="form-group">
              <textarea  rows="3" class="form-control " ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.facialExpressions" #facialExpressions="ngModel" name="facialExpressions" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && facialExpressions.invalid }" required   ></textarea>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && facialExpressions.invalid">
                <mat-error class="text-xs"  *ngIf="facialExpressions.invalid">
                  Facial Expressions is required</mat-error>
            </mat-error>
            </div>
           </div>
           <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">What is predominant feeling/emotional response? </label>
            <div  class="form-group row check-fields-large-label">
              <div class="col-md-3"  *ngFor="let item of _mentalStatus.emotionalResponse;let i=index">
                <div class="check-custom">
                  <mat-checkbox class="example-margin" name="emotionalResponse{{i}}" [(ngModel)]="item.isChecked" (change)="Onemotionalresponse(2)">{{item.clinicalIssues}}</mat-checkbox>
                </div>
              </div>
              <div class="col-md-12">
              <mat-error *ngIf="PsyEvaMSFrm.submitted && !Emotionalresponsegiven">
                <mat-error class="text-xs" *ngIf="!Emotionalresponsegiven">
                 This field is required</mat-error>
            </mat-error></div>
              <!--<div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Elated</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Fearful</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2"> Anxious</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Angry</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Hostile</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Indifferent </mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Flat</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Blunted</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom mt-2">
                  <mat-checkbox class="example-margin mr-2">Other</mat-checkbox>
                </div>
              </div>-->
              <div class="col-md-12 " *ngIf="_mentalStatus.emotionalResponse[9].isChecked==true">
                <div  class="form-group">
                  <textarea  rows="3" class="form-control text-xs font-italic" placeholder="If other, please enter details" maxlength="200" [(ngModel)]="PsyEvaMSInfo.otherEmotionalResponse" #otherEmotionalResponse="ngModel" name="otherEmotionalResponse" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && otherEmotionalResponse.invalid }" required   ></textarea>
                  <mat-error *ngIf="PsyEvaMSFrm.submitted && otherEmotionalResponse.invalid">
                    <mat-error class="text-xs"  *ngIf="otherEmotionalResponse.invalid">
                      This field is required</mat-error>
                </mat-error>
                </div>
              </div>
             
              
              
            </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Attitude/Behavior</label>
            <div  class="form-group row check-fields-large-label">
              <div class="col-md-3"  *ngFor="let item of _mentalStatus.attitude;let i=index">
                <div class="check-custom">
                  <mat-checkbox class="example-margin" name="attitude{{i}}" [(ngModel)]="item.isChecked" (change)="onattitude(2)">{{item.clinicalIssues}}</mat-checkbox>
                </div>
              </div>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && !Attitudegiven">
                <mat-error class="text-xs" *ngIf="!Attitudegiven">
                 This field is required</mat-error>
            </mat-error>
              <!--<div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Cooperative</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Friendly</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Engaged</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2"> Polite</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Humble</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Shy</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Uncooperative</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Sullen</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Tearful</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Guarded</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Resistant </mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Other</mat-checkbox>
                </div>
              </div>-->
              <div class="col-md-12" *ngIf="_mentalStatus.attitude[11].isChecked==true">
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control text-xs font-italic" placeholder="If other, please enter details" maxlength="200" [(ngModel)]="PsyEvaMSInfo.otherAttitue" #otherAttitue="ngModel" name="otherAttitue" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && otherAttitue.invalid }" required   ></textarea>
                  <mat-error *ngIf="PsyEvaMSFrm.submitted && otherAttitue.invalid">
                    <mat-error class="text-xs"  *ngIf="otherAttitue.invalid">
                      This field is required</mat-error>
                </mat-error>
                </div>
              </div>
              
            </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Mood/Affect: (examples) On a scale of 1-10 with 10 being very happy, answer the following</label>
            <div class="form-group d-flex">
              <label class="treat-label ">How do you feel most days?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control " [(ngModel)]="PsyEvaMSInfo.mostDays" #mostDays="ngModel" name="mostDays" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && mostDays.invalid }" required >
                      <option *ngFor="let item of ddlRatings" [ngValue]="item.value">{{item.label}}</option>
                    </select>
                  
                  </div>
                  <div class="col-md-4">
                    <mat-error *ngIf="PsyEvaMSFrm.submitted && mostDays.invalid">
                      <mat-error class="text-xs"  *ngIf="mostDays.invalid">
                      *required</mat-error>
                  </mat-error>
                  </div>

                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">How do you feel now?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control " [(ngModel)]="PsyEvaMSInfo.now" #now="ngModel" name="now" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && now.invalid }" required >
                      <option *ngFor="let item of ddlRatings" [ngValue]="item.value">{{item.label}}</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <mat-error *ngIf="PsyEvaMSFrm.submitted && now.invalid">
                      <mat-error class="text-xs"  *ngIf="now.invalid">
                      *required</mat-error>
                  </mat-error>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">In general, would you say that you feel good about yourself?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control " [(ngModel)]="PsyEvaMSInfo.goodAboutYourself" #goodAboutYourself="ngModel" name="goodAboutYourself" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && goodAboutYourself.invalid }" required >
                      <option *ngFor="let item of ddlRatings" [ngValue]="item.value">{{item.label}}</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <mat-error *ngIf="PsyEvaMSFrm.submitted && goodAboutYourself.invalid">
                      <mat-error class="text-xs"  *ngIf="goodAboutYourself.invalid">
                      *required</mat-error>
                  </mat-error>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">Do you find yourself crying sometimes?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control " [(ngModel)]="PsyEvaMSInfo.crying" #crying="ngModel" name="crying" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && crying.invalid }" required >
                      <option *ngFor="let item of ddlRatings" [ngValue]="item.value">{{item.label}}</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <mat-error *ngIf="PsyEvaMSFrm.submitted && crying.invalid">
                      <mat-error class="text-xs"  *ngIf="crying.invalid">
                      *required</mat-error>
                  </mat-error>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">Are you a worrier? </label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control " [(ngModel)]="PsyEvaMSInfo.warrier" #warrier="ngModel" name="warrier" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && warrier.invalid }" required >
                      <option *ngFor="let item of ddlRatings" [ngValue]="item.value">{{item.label}}</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <mat-error *ngIf="PsyEvaMSFrm.submitted && warrier.invalid">
                      <mat-error class="text-xs"  *ngIf="warrier.invalid">
                      *required</mat-error>
                  </mat-error>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">Do you experience mood swings?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control " [(ngModel)]="PsyEvaMSInfo.moodSwings" #moodSwings="ngModel" name="moodSwings" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && moodSwings.invalid }" required >
                      <option *ngFor="let item of ddlRatings" [ngValue]="item.label">{{item.label}}</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <mat-error *ngIf="PsyEvaMSFrm.submitted && moodSwings.invalid">
                      <mat-error class="text-xs"  *ngIf="moodSwings.invalid">
                      *required</mat-error>
                  </mat-error>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">For children: If you had 3 wishes, what would they be?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <div  class="form-group">
                      <textarea  name="complaint" rows="3" class="form-control text-xs font-italic" placeholder="Please, enter details" maxlength="800" [(ngModel)]="PsyEvaMSInfo.threeWishes" #threeWishes="ngModel" name="threeWishes"   ></textarea>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Mood</label>
            <div  class="form-group row check-fields-large-label">
              <div class="col-md-3"  *ngFor="let item of _mentalStatus.mood;let i=index">
                <div class="check-custom">
                  <mat-checkbox class="example-margin" name="mood{{i}}" [(ngModel)]="item.isChecked" (change)="Onmoodchange(2)">{{item.clinicalIssues}}</mat-checkbox>
                </div>
              </div>
              <div class="col-md-12">
              <mat-error *ngIf="PsyEvaMSFrm.submitted && !MoodGiven">
                <mat-error class="text-xs" *ngIf="!MoodGiven">
                 This field is required</mat-error>
            </mat-error>
          </div>
              <!--<div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Happy </mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Anxious</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Elevated</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2"> Liable</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Euthymic</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Sad</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Hostile</mat-checkbox>
                </div>
              </div>-->
            </div>
          </div>

          <div class="col-md-12">
            <div class="row">
            <label  class="treat-label col-md-3 font-weight-bold "><span class="control-label-new">Is effect congruent to discussion? </span></label> 
            <div  class="col-md-9 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.iscongruent" #iscongruent="ngModel" name="iscongruent" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && iscongruent.invalid }" required >  
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
                <!--<mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>-->
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && iscongruent.invalid">
                <mat-error class="text-xs"  *ngIf="iscongruent.invalid">
                This field is required</mat-error>
            </mat-error>
            </div>
          </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Speech</label>
            <div  class="form-group row check-fields-large-label">
              <div class="col-md-3"  *ngFor="let item of _mentalStatus.speech;let i=index">
                <div class="check-custom">
                  <mat-checkbox class="example-margin" name="speech{{i}}" [(ngModel)]="item.isChecked" (change)="onspeech(2)">{{item.clinicalIssues}}</mat-checkbox>
                </div>
              </div>
              <div class="col-md-12">
                <mat-error *ngIf="PsyEvaMSFrm.submitted && !SpeechGiven">
                  <mat-error class="text-xs" *ngIf="!SpeechGiven">
                   This field is required</mat-error>
              </mat-error>
            </div>
              <!--<div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Normal rate </mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Normal Volume</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Soft Spoken	</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2"> Loud</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Logical</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Slurred</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Clear</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Garbled/Mumbling</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2"> Coherent</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Incoherent</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Stuttering</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Halting</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Guarded</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Obscene</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Rambling</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Loose Association</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Flight of Ideas</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Poverty of Thought</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Other</mat-checkbox>
                </div>
              </div>-->
              <div class="col-md-12" *ngIf="_mentalStatus.speech[18].isChecked==true">
                <div  class="form-group">
                  <textarea maxlength="800" rows="3" class="form-control text-xs font-italic" placeholder="If other, please enter details" [(ngModel)]="PsyEvaMSInfo.otherSpeech" #otherSpeech="ngModel" name="otherSpeech" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && otherSpeech.invalid }" required   ></textarea>
                  <mat-error *ngIf="PsyEvaMSFrm.submitted && otherSpeech.invalid">
                    <mat-error class="text-xs" *ngIf="otherSpeech.invalid">
                     This field is required</mat-error>
                </mat-error>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Additional Comments</label>
            <div  class="form-group">
              <textarea   rows="3" class="form-control "  ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.additionalComments" #additionalComments="ngModel" name="additionalComments"   ></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Perceptual Disturbances</label>
            <div  class="form-group">
              <textarea  maxlength="800" rows="3" class="form-control "  ng-reflect-name="txt_clinical" [(ngModel)]="PsyEvaMSInfo.perceptualDisturbances" #perceptualDisturbances="ngModel" name="perceptualDisturbances" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && perceptualDisturbances.invalid }" required   ></textarea>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && perceptualDisturbances.invalid">
                <mat-error class="text-xs" *ngIf="perceptualDisturbances.invalid">
                  Perceptual Disturbances is required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Thought Process</label>
            <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Does the client’s thinking make sense? </span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.thinkingMakeSense" #thinkingMakeSense="ngModel" name="thinkingMakeSense" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && thinkingMakeSense.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && thinkingMakeSense.invalid">
                <mat-error class="text-xs" *ngIf="thinkingMakeSense.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Are thought patterns connected?	</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.thoughtPatternsConnected" #thoughtPatternsConnected="ngModel" name="thoughtPatternsConnected" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && thoughtPatternsConnected.invalid }" required  >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && thoughtPatternsConnected.invalid">
                <mat-error class="text-xs" *ngIf="thoughtPatternsConnected.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Flight of ideas? (Pressured skipping from one idea to another) </span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.flightofIdeas" #flightofIdeas="ngModel" name="flightofIdeas" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && flightofIdeas.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && flightofIdeas.invalid">
                <mat-error class="text-xs" *ngIf="flightofIdeas.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Loose association? (Connections between thoughts that seem mixed up and although the client seems to be a connection, it is far from obvious to you)</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.looseAssociation" #looseAssociation="ngModel" name="looseAssociation" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && looseAssociation.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && looseAssociation.invalid">
                <mat-error class="text-xs" *ngIf="looseAssociation.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class=" col-md-8 font-weight-bold "><span class="treat-label">Any evidence of magical thinking?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.magicalThinking" #magicalThinking="ngModel" name="magicalThinking" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && magicalThinking.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && magicalThinking.invalid">
                <mat-error class="text-xs" *ngIf="magicalThinking.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Thought content</label>
            <div class="sub-head-psy">Delusions </div>
            <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you believe that you have special power? </span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.specialPower" #specialPower="ngModel" name="specialPower" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && specialPower.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && specialPower.invalid">
                <mat-error class="text-xs" *ngIf="specialPower.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you feel that another person or force is controlling your behavior? </span></label> 
            
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.forceisControlling" #forceisControlling="ngModel" name="forceisControlling" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && forceisControlling.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && forceisControlling.invalid">
                <mat-error class="text-xs" *ngIf="forceisControlling.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="sub-head-psy">Paranoia </div>
          <div class="row">
            
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you ever have thoughts that people are talking or laughing about either you or the things you say or do?</span></label> 
            
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.talkingorLaughing" #talkingorLaughing="ngModel" name="talkingorLaughing" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && talkingorLaughing.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && talkingorLaughing.invalid">
                <mat-error class="text-xs" *ngIf="talkingorLaughing.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Suicidal/Homicidal Ideation</label>
            <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Have you ever been so sad that you thought about hurting/killing yourself? </span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.killingYourself" #killingYourself="ngModel" name="killingYourself" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && killingYourself.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value" (click)="PsyEvaMSInfo.killingYourselfWhen=PsyEvaMSInfo.killingYourself==false?'':PsyEvaMSInfo.killingYourselfWhen"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && killingYourself.invalid">
                <mat-error class="text-xs" *ngIf="killingYourself.invalid">
                 required</mat-error>
            </mat-error>
            <div *ngIf="PsyEvaMSInfo.killingYourself==true" >
              <textarea  rows="3" class="form-control " placeholder="When?" ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.killingYourselfWhen" #killingYourselfWhen="ngModel" name="killingYourselfWhen" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && killingYourselfWhen.invalid }" required   ></textarea>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && killingYourselfWhen.invalid">
                <mat-error class="text-xs" *ngIf="killingYourselfWhen.invalid">
                 This field is required</mat-error>
            </mat-error>
          </div>
            </div>
          </div>
         
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Have you ever tried to commit suicide?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.commitSuicide" #commitSuicide="ngModel" name="commitSuicide" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && commitSuicide.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  (click)="PsyEvaMSInfo.commitSuicideWhen=PsyEvaMSInfo.commitSuicide==false?'':PsyEvaMSInfo.commitSuicideWhen"> {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && commitSuicide.invalid">
                <mat-error class="text-xs" *ngIf="commitSuicide.invalid">
                 required</mat-error>
            </mat-error>
            <div *ngIf="PsyEvaMSInfo.commitSuicide==true" >
              <textarea  rows="3" class="form-control " placeholder="When?" ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.commitSuicideWhen" #commitSuicideWhen="ngModel" name="commitSuicideWhen" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && commitSuicideWhen.invalid }" required   ></textarea>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && commitSuicideWhen.invalid">
                <mat-error class="text-xs" *ngIf="commitSuicideWhen.invalid">
                 This field is required</mat-error>
            </mat-error>
          </div>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Plan/Intent?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.suicidePlan" #suicidePlan="ngModel" name="suicidePlan" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && suicidePlan.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  (click)="PsyEvaMSInfo.suicidePlanWhen=PsyEvaMSInfo.suicidePlan==false?'':PsyEvaMSInfo.suicidePlanWhen"> {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && suicidePlan.invalid">
                <mat-error class="text-xs" *ngIf="suicidePlan.invalid">
                 required</mat-error>
            </mat-error>
            <div   *ngIf="PsyEvaMSInfo.suicidePlan==true"> 
              <textarea  rows="3" class="form-control " placeholder="When?" ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.suicidePlanWhen" #suicidePlanWhen="ngModel" name="suicidePlanWhen" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && suicidePlanWhen.invalid }" required   ></textarea>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && suicidePlanWhen.invalid">
                <mat-error class="text-xs" *ngIf="suicidePlanWhen.invalid">
                 This field is required</mat-error>
            </mat-error>
          </div>
            </div>
          </div>
          <div class="row">
            <label  class=" col-md-8 font-weight-bold "><span class="treat-label">Do you have a history of violent behavior/getting into fights frequently?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.violentBehavior" #violentBehavior="ngModel" name="violentBehavior" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && violentBehavior.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && violentBehavior.invalid">
                <mat-error class="text-xs" *ngIf="violentBehavior.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Have you ever felt so angry that you thought about hurting or killing someone?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.killingSomeone" #killingSomeone="ngModel" name="killingSomeone" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && killingSomeone.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  (click)="PsyEvaMSInfo.killingSomeoneWhen=PsyEvaMSInfo.killingSomeone==false?'':PsyEvaMSInfo.killingSomeoneWhen"> {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && killingSomeone.invalid">
                <mat-error class="text-xs" *ngIf="killingSomeone.invalid">
                 required</mat-error>
            </mat-error>
            <div *ngIf="PsyEvaMSInfo.killingSomeone==true">
              <textarea   rows="3" class="form-control " placeholder="If yes, when, about whom, and when is the last time you felt this way?" ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.killingSomeoneWhen" #killingSomeoneWhen="ngModel" name="killingSomeoneWhen" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && killingSomeoneWhen.invalid }" required   ></textarea>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && killingSomeoneWhen.invalid">
                <mat-error class="text-xs" *ngIf="killingSomeoneWhen.invalid">
                 This field is required</mat-error>
            </mat-error>
          </div>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Plan/Intent?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.killingSomeonePlan" #killingSomeonePlan="ngModel" name="killingSomeonePlan" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && killingSomeonePlan.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  (click)="PsyEvaMSInfo.killingSomeonePlanWhen=PsyEvaMSInfo.killingSomeonePlan==false?'':PsyEvaMSInfo.killingSomeonePlanWhen"> {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && killingSomeonePlan.invalid">
                <mat-error class="text-xs" *ngIf="killingSomeonePlan.invalid">
                 required</mat-error>
            </mat-error>
            <div *ngIf="PsyEvaMSInfo.killingSomeonePlan==true">
              <textarea   rows="3" class="form-control " placeholder="When?" ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.killingSomeonePlanWhen" #killingSomeonePlanWhen="ngModel" name="KillingSomeonePlanWhen" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && killingSomeonePlanWhen.invalid }" required    ></textarea>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && killingSomeonePlanWhen.invalid">
                <mat-error class="text-xs" *ngIf="killingSomeonePlanWhen.invalid">
                 This field is required</mat-error>
            </mat-error>
            </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Orientation</label>
          <div class="sub-head-psy">Time </div>
          <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">When you came in today, was it in the?</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.orientationTime" #orientationTime="ngModel" name="orientationTime" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && orientationTime.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlorientationtime; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && orientationTime.invalid">
              <mat-error class="text-xs" *ngIf="orientationTime.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="sub-head-psy">Place  </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you know the name of this building? </span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.orientationPlace" #orientationPlace="ngModel" name="orientationPlace" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && orientationPlace.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && orientationPlace.invalid">
              <mat-error class="text-xs" *ngIf="orientationPlace.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="sub-head-psy">Person  </div>
        <div class="row">
          
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Can you tell me why you are here today?</span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.orientationReason" #orientationReason="ngModel" name="orientationReason" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && orientationReason.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && orientationReason.invalid">
              <mat-error class="text-xs" *ngIf="orientationReason.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        </div>
        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Memory</label>
          <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Has your memory been good?</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.goodMemory" #goodMemory="ngModel" name="goodMemory" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && goodMemory.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && goodMemory.invalid">
              <mat-error class="text-xs" *ngIf="goodMemory.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you find you are having difficulty remembering names?	</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.diffRememberingNames" #diffRememberingNames="ngModel" name="diffRememberingNames" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && diffRememberingNames.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && diffRememberingNames.invalid">
              <mat-error class="text-xs" *ngIf="diffRememberingNames.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you find you are having difficulty remembering telephone numbers?</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.diffRememberingPhone" #diffRememberingPhone="ngModel" name="diffRememberingPhone" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && diffRememberingPhone.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && diffRememberingPhone.invalid">
              <mat-error class="text-xs" *ngIf="diffRememberingPhone.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you find you are having difficulty remembering appointments?</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.diffRememberAppoinments" #diffRememberAppoinments="ngModel" name="diffRememberAppoinments" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && diffRememberAppoinments.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && diffRememberAppoinments.invalid">
              <mat-error class="text-xs" *ngIf="diffRememberAppoinments.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="row">
          <label  class=" col-md-8 font-weight-bold "><span class="treat-label">What did you have to eat yesterday? </span></label> 
          <div  class="col-md-4 form-group">
            <textarea   rows="3" class="form-control " placeholder="Please, enter details" ng-reflect-name="txt_clinical" maxlength="200" [(ngModel)]="PsyEvaMSInfo.eatYesterday" #eatYesterday="ngModel" name="eatYesterday" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && eatYesterday.invalid }" required   > </textarea>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && eatYesterday.invalid">
              <mat-error class="text-xs" *ngIf="eatYesterday.invalid">
               This field is required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="row">
          <label  class=" col-md-8 font-weight-bold "><span class="treat-label">What did you do last weekend? </span></label> 
          <div  class="col-md-4 form-group">
            <textarea   rows="3" class="form-control " placeholder="Please, enter details" ng-reflect-name="txt_clinical" maxlength="200" [(ngModel)]="PsyEvaMSInfo.lastWeekend" #lastWeekend="ngModel" name="lastWeekend" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && lastWeekend.invalid }" required   ></textarea>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && lastWeekend.invalid">
              <mat-error class="text-xs" *ngIf="lastWeekend.invalid">
              This field is required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">I am going to give you 3 objects to remember and tell back to me when I ask.</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.threeObj" #threeObj="ngModel" name="threeObj" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && threeObj.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlgoodbehaviour; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && threeObj.invalid">
              <mat-error class="text-xs" *ngIf="threeObj.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">I am going to give you 4 numbers.  I would like you to repeat them and say in reverse.</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.fourNum" #fourNum="ngModel" name="fourNum" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && fourNum.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlgoodbehaviour; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && fourNum.invalid">
              <mat-error class="text-xs" *ngIf="fourNum.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        </div>
        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Concentration (examples) </label>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Starting at 20, I would like you to count backwards by 3</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.countBackwards" #countBackwards="ngModel" name="countBackwards" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && countBackwards.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlgoodbehaviour; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && countBackwards.invalid">
                <mat-error class="text-xs" *ngIf="countBackwards.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Name the days of the week in reverse.</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.daysofWeek" #daysofWeek="ngModel" name="daysofWeek" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && daysofWeek.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlgoodbehaviour; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && daysofWeek.invalid">
                <mat-error class="text-xs" *ngIf="daysofWeek.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Intelligence(examples)</label>
          <div class="sub-head-psy"> Fond of Knowledge </div>
          <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Name 5 animals, foods, countries.</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.fundofKnowledge" #fundofKnowledge="ngModel" name="fundofKnowledge" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && fundofKnowledge.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlAverage; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && fundofKnowledge.invalid">
              <mat-error class="text-xs" *ngIf="fundofKnowledge.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="sub-head-psy">Abstract Thinking </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">How is a lie different from a mistake? </span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.lienMistake" #lienMistake="ngModel" name="lienMistake" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && lienMistake.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlAverage; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>        
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && lienMistake.invalid">
              <mat-error class="text-xs" *ngIf="lienMistake.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">How are a window and a door the same?</span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.windownDoor" #windownDoor="ngModel" name="windownDoor" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && windownDoor.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlAverage; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>        
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && windownDoor.invalid">
              <mat-error class="text-xs" *ngIf="windownDoor.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="sub-head-psy">Vocabulary  </div>
        <div class="row">
          
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Spell “elephant”</span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.vocabulary" #vocabulary="ngModel" name="vocabulary" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && vocabulary.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlAverage; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>        
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && vocabulary.invalid">
              <mat-error class="text-xs" *ngIf="vocabulary.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="sub-head-psy"> Calculation  </div>
        <div class="row">
          
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">How many quarters are in $1.75?</span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.calculation" #calculation="ngModel" name="calculation" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && calculation.invalid }" required >
              <mat-radio-button *ngFor="let item of ddlAverage; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>        
            </mat-radio-group>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && calculation.invalid">
              <mat-error class="text-xs" *ngIf="calculation.invalid">
               required</mat-error>
          </mat-error>
          </div>
        </div>
        </div>

        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Judgement (examples) </label>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Can you think of a situation where you have acted foolish or made a bad choice?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.badChoice" #badChoice="ngModel" name="badChoice" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && badChoice.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlgoodbehaviour; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && badChoice.invalid">
                <mat-error class="text-xs" *ngIf="badChoice.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">What would you do if someone criticizes a job that you have done or a decision out have made?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.criticizes" #criticizes="ngModel" name="criticizes" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && criticizes.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlgoodbehaviour; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && criticizes.invalid">
                <mat-error class="text-xs" *ngIf="criticizes.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Insight: </label>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you think that you usually make good decisions?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.goodDecisions" #goodDecisions="ngModel" name="goodDecisions" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && goodDecisions.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlgoodbehaviour; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && goodDecisions.invalid">
                <mat-error class="text-xs" *ngIf="goodDecisions.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you think that you are in anyway responsible for what has happened?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.responsible" #responsible="ngModel" name="responsible" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && responsible.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlgoodbehaviour; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && responsible.invalid">
                <mat-error class="text-xs" *ngIf="responsible.invalid">
                 required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">What part do you think that you played in this?</span></label> 
            <div  class="col-md-4 form-group">
                <textarea  maxlength="800" rows="3" placeholder="Please, enter details" class="form-control " ng-reflect-name="txt_clinical" [(ngModel)]="PsyEvaMSInfo.played" #played="ngModel" name="played" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && played.invalid }" required   ></textarea>
                <mat-error *ngIf="PsyEvaMSFrm.submitted && played.invalid">
                  <mat-error class="text-xs" *ngIf="played.invalid">
                  This field is required</mat-error>
              </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">What changes would help you most?</span></label> 
            <div  class="col-md-4 form-group">
              <textarea  maxlength="800" rows="3" placeholder="Please, enter details" class="form-control " ng-reflect-name="txt_clinical" [(ngModel)]="PsyEvaMSInfo.changesHelp" #changesHelp="ngModel" name="changesHelp" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && changesHelp.invalid }" required   ></textarea>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && changesHelp.invalid">
                <mat-error class="text-xs" *ngIf="changesHelp.invalid">
                This field is required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="control-label-new font-weight-bold">Frustration Tolerance</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.frustrationTolerance" #frustrationTolerance="ngModel" name="frustrationTolerance" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && frustrationTolerance.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlgoodbehaviour; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && frustrationTolerance.invalid">
                <mat-error class="text-xs" *ngIf="frustrationTolerance.invalid">
               required</mat-error>
            </mat-error>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="control-label-new font-weight-bold">Impulse Control</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" [(ngModel)]="PsyEvaMSInfo.impulseControl" #impulseControl="ngModel" name="impulseControl" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && impulseControl.invalid }" required >
                <mat-radio-button *ngFor="let item of ddlgoodbehaviour; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>           
              </mat-radio-group>
              <mat-error *ngIf="PsyEvaMSFrm.submitted && impulseControl.invalid">
                <mat-error class="text-xs" *ngIf="impulseControl.invalid">
               required</mat-error>
            </mat-error>
            </div>
          </div>
          
        </div>

        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Comments</label>
          <div  class="form-group">
            <textarea  maxlength="800" rows="3" class="form-control " ng-reflect-name="txt_clinical" [(ngModel)]="PsyEvaMSInfo.comments" #comments="ngModel" name="comments" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && comments.invalid }" required   ></textarea>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && comments.invalid">
              <mat-error class="text-xs" *ngIf="comments.invalid">
                Comments is required</mat-error>
          </mat-error>
          </div>
        </div>
        
        <div class="col-md-12">
          <div>
          <label  class="control-label-new font-weight-bold mb-2">Assessment</label>
        </div>
          <label  class="treat-label font-weight-bold">Axis I</label>
          <div  class="form-group">
            <textarea  rows="3" class="form-control " ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.axisI" #axisI="ngModel" name="axisI" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && axisI.invalid }" required   ></textarea>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && axisI.invalid">
              <mat-error class="text-xs" *ngIf="axisI.invalid">
                Axis I is required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="col-md-12">
          <label  class="treat-label font-weight-bold">Axis II</label>
          <div  class="form-group">
            <textarea  maxlength="800" rows="3" class="form-control " ng-reflect-name="txt_clinical" [(ngModel)]="PsyEvaMSInfo.axisIi" #axisIi="ngModel" name="axisIi" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && axisIi.invalid }" required   ></textarea>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && axisIi.invalid">
              <mat-error class="text-xs" *ngIf="axisIi.invalid">
                Axis II is required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="col-md-12">
          <label  class="treat-label font-weight-bold">Axis III</label>
          <div  class="form-group">
            <textarea  rows="3" class="form-control " ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.axisIii" #axisIii="ngModel" name="axisIii" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && axisIii.invalid }" required   ></textarea>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && axisIii.invalid">
              <mat-error class="text-xs" *ngIf="axisIii.invalid">
                Axis III is required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="col-md-12">
          <label  class="treat-label font-weight-bold">Axis IV</label>
          <div  class="form-group">
            <textarea rows="3" class="form-control " ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.axisIv" #axisIv="ngModel" name="axisIv" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && axisIv.invalid }" required   ></textarea>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && axisIv.invalid">
              <mat-error class="text-xs" *ngIf="axisIv.invalid">
                Axis IV is required</mat-error>
          </mat-error>
          </div> 
        </div>
        <div class="col-md-12">
          <label  class="treat-label font-weight-bold">Axis V</label>
          <div  class="form-group">
            <div class="row">
              <div class="col-md-6">
                <textarea  name="complaint" rows="3" class="form-control " placeholder="Current" ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.axisVcurrent" #axisVcurrent="ngModel" name="axisVcurrent" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && axisVcurrent.invalid }" required   ></textarea>
                <mat-error *ngIf="PsyEvaMSFrm.submitted && axisVcurrent.invalid">
                  <mat-error class="text-xs" *ngIf="axisVcurrent.invalid">
                   This field is required</mat-error>
              </mat-error>
              </div>
              <div class="col-md-6">
                <textarea  name="complaint" rows="3" class="form-control " placeholder="Last Year" ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.axisVlastYear" #axisVlastYear="ngModel" name="axisVlastYear" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && axisVlastYear.invalid }" required   ></textarea>
                <mat-error *ngIf="PsyEvaMSFrm.submitted && axisVlastYear.invalid">
                  <mat-error class="text-xs" *ngIf="axisVlastYear.invalid">
                    This field is required</mat-error>
              </mat-error>
              </div>
            </div>
             </div>
        </div>

        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Summary Psychodynamic Formulation</label>
          <div  class="form-group">
            <textarea   rows="3" class="form-control " ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.psychodynamicFormulation" #psychodynamicFormulation="ngModel" name="psychodynamicFormulation" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && psychodynamicFormulation.invalid }" required   ></textarea>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && psychodynamicFormulation.invalid">
              <mat-error class="text-xs" *ngIf="psychodynamicFormulation.invalid">
                Summary Psychodynamic Formulation is required</mat-error>
          </mat-error>
          </div>
        </div>
        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Plan/Recommendation	</label>
          <div  class="form-group">
            <textarea   rows="3" class="form-control " ng-reflect-name="txt_clinical" maxlength="800" [(ngModel)]="PsyEvaMSInfo.recommendation" #recommendation="ngModel" name="recommendation" [ngClass]="{ 'is-invalid': PsyEvaMSFrm.submitted && recommendation.invalid }" required   ></textarea>
            <mat-error *ngIf="PsyEvaMSFrm.submitted && recommendation.invalid">
              <mat-error class="text-xs" *ngIf="recommendation.invalid">
                Plan/Recommendation is required</mat-error>
          </mat-error>
          </div>
        </div>
        <!--newly added sign-->
        <div class="col-md-12 p-0">
          <div  class="form-group clearfix m-0">
            <div class="float-left col-md-4 p-0 mt-3 pt-1">
              <label  class="treat-label w-100">Clinician's Signature</label>
              <img id='esign' height="50px" width="300px" src="{{PsyEvaMSInfo.staffInitial}}" style="border: 1px solid grey;">
              <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialog()" > Add E-Sign </button>
              <div class="float-left col-md-6 p-0 mt-3 pt-1" >
                <mat-error class="text-xs" *ngIf="PsyEvaMSInfo.staffInitial == undefined">
                  Staff signtaure is required</mat-error>
                   </div>
            
            </div>
            <div class="float-left col-md-4 p-0 mt-3 pt-1">
              <label  class="treat-label w-100">Secondary Signature</label>
              <img id='esign1' height="50px" width="300px" src="{{PsyEvaMSInfo.staffInitial1}}" style="border: 1px solid grey;">
              <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" [disabled]="secondarySign()"(click)="openDialog1()" > Add E-Sign </button>
              <div class="float-left col-md-6 p-0 mt-3 pt-1" >
                <!-- <mat-error *ngIf="DisSumFrm.submitted && DischargeSUmmaryInfo.clinicianSignature == undefined">
                  <mat-error class="mat-form-field-subscript-wrapper" *ngIf="DischargeSUmmaryInfo.clinicianSignature == undefined">
                    Clinician's Signature is required</mat-error>
              </mat-error> -->
                   </div>
            
            </div>
            <div  class="float-right col-md-2 p-0">
              <mat-form-field class="w-100">
                <mat-label>Date</mat-label>
                <input matInput [matDatepicker]="Psypicker"  [(ngModel)]="PsyEvaMSInfo.psyEvaDate" #psyEvaDate="ngModel" name="psyEvaDate" >
              
                <mat-datepicker-toggle matSuffix [for]="Psypicker"></mat-datepicker-toggle>
                <mat-datepicker #Psypicker></mat-datepicker>
               
              </mat-form-field>
              </div>
            </div>
        </div>
<!-- <div class="col-md-12">
  <div class="form-group clearfix m-0">
  <div class="float-left col-md-4 p-0 mt-3 pt-1">
    <label class="treat-label mr-2" style="padding-left :9px;">Provider's Signature </label>
    <img id='esign' height="50px" width="300px" src="{{PsyEvaMSInfo.staffInitial}}" style="border: 1px solid grey;">
    

      <mat-error class="text-xs" *ngIf="PsyEvaMSInfo.staffInitial == undefined">
      Staff signtaure is required</mat-error>
  </div>
  <div class="float-left col-md-4 p-0 mt-3 pt-1 ">
    
    <button type="button" (click)="openDialog(1)" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2">
      Add E-Sign
    </button>
  </div>
  <div  class="float-right col-md-2 p-0 ">
    <mat-form-field class="w-100">
      <mat-label>Date</mat-label>
      <input matInput [matDatepicker]="Psypicker"  [(ngModel)]="PsyEvaMSInfo.psyEvaDate" #psyEvaDate="ngModel" name="psyEvaDate" >
    
      <mat-datepicker-toggle matSuffix [for]="Psypicker"></mat-datepicker-toggle>
      <mat-datepicker #Psypicker></mat-datepicker>
     
    </mat-form-field>
  </div>
</div>
</div> -->
<!--newly added sign end-->

      </form>
    </div>
          <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==3">
            <div class="goal-page-indiv col-md-12 mt-3" [ngClass]="{'disabled':progressUpg == true }">
              <div class="col-md-12 p-0">
                <div class="d-flex w-100 mb-2">
                  <div class="box-lead-head mb-2 ">Goals </div>
                  <div class="ml-auto">
                  </div>
                </div>
              </div>
              <div class="panel-group" id="accordion">
                <div *ngFor="let item of goalsinfo; let i=index">
                <div class="panel panel-default active" >
                 
                  <div class="panel-heading" >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse{{i}}" class="collapsed">
                        <i class="fa fa-caret-right mr-2"></i>{{ item.goal }}
    
                      </a>
                      <!--<div class="goal-stat-chang float-right" >
                       <select class="form-control" [(ngModel)]="item.status"
                       #status="ngModel" name="status" (change)="OnstatusChange(item)">
                         <option [value]=2  >Inprogress</option>
                         <option [value]=3 > Completed</option>
                       </select>
                      </div>-->
                      
                    </h4>
                  </div>
                  <div id="collapse{{i}}" class="panel-collapse collapse in" >
                    <div class="panel-body">
                     <div class="col-md-12 ben-head">
                       <div class="row">
                         
                         <div class="col-md-8 ben_des">
                          <div class=" ben_tit">
                           {{ item.benchmarkTitle }}
                          </div>
                         {{item.benchmarkDescription}}
                        </div>
                        <div class="col-md-2">
                          <div class="goal-stat-chang mt-3">
                              <span class="status-tabl inprogress" *ngIf="item.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                              <span class="status-tabl completed" *ngIf="item.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                          </div>
                      </div>
                        <div class="col-md-2 ben_unit">
                          <p class="text-muted text-xs">Unit of Measure</p>
                          {{item.benchmarkunit}}
                        </div>
                       </div>
                     </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            
            </div>
         
         
             </div>
        </div>
        </div>
        </div>  
    

  </div>
      <!--Preview popup for pgrogress notes-->
      <div class="Activity-popup">
        <div class="modal"  tabindex="-1" role="dialog" [style.display]="PopUpPreview? 'block':'none'" >
           <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                 <div class="modal-header">
                    <h6 class="modal-title">Preview</h6>
                    <button type="button" class="close"  data-dismiss="modal" (click)="OnclosePopup()"
                    aria-label="Close" >
                    <span aria-hidden="true">&times;</span>
                 </button>
                 </div>
                 <div class="modal-body">
                  <div class="individual-threaphy-container">
                  <div  class="history-list col-md-12 p-0">
                    <div class="d-flex col-md-12">
                      <button class="ml-auto btn-print-hover mb-3 mr-2" mat-type="button" (click)="print()"><i class="fa fa-print"></i></button>
                    </div>
                    <div id="print-section" class="col-md-12">
                      <div class="page-header" style=" text-align: center; font-size: 15px; font-weight: 500; margin-bottom: 25px; color: #173a64;">PSYCHIATRIC EVALUATION FROM </div>

                    <div class="row top-details-psych">
                      <div class="col-md-3"><label>Client Name</label>
                      <p>{{_dtoPatientInfo.patientName }}</p></div>
                      <div class="col-md-3"><label>Psychiatrist Name</label>
                        <p>{{_dtoPatientInfo.clinicianName}}</p></div>
                        <div class="col-md-3"><label> Appointment  Date</label>
                          <p>{{_dtoPatientInfo.startDate | date:'MM/dd/YYYY'}}</p></div>
                          <div class="col-md-3"><label> Appointment  Time</label>
                            <p>{{_dtoPatientInfo.startTime | date:'hh:mm a'}}</p></div>                         
                     </div>

                     <div class="row" >
                      <div class="col-md-12 mb-2  ">
                         <label class="control-label-new font-weight-bold" >Identification </label>
                     <div class="cli_value_family">
                      <span>{{PsyEvaInfo.identification}}</span>
                    </div>
                  </div>
                </div>
                <div class="row" >
                  <div class="col-md-12 mb-2  ">
                     <label class="control-label-new font-weight-bold" >Chief complaint </label>
                 <div class="cli_value_family">
                  <span>{{PsyEvaInfo.chiefComplaint}}</span>
                </div>
              </div>
            </div>
            <div class="row" >
              <div class="col-md-12 mb-2  ">
                 <label class="control-label-new font-weight-bold" >History of Presenting Illness</label>
             <div class="cli_value_family">
              <span>{{PsyEvaInfo.presentingIllness}}</span>
            </div>
          </div>
        </div>
                      
        <div class="row" >
          <div class="col-md-12 mb-2  ">
             <label class="control-label-new font-weight-bold" >Past Psychiatric History</label>
         <div class="cli_value_family">
          <span>{{PsyEvaInfo.psychiatricHistory}}</span>
        </div>
      </div>
    </div>     
    
          <div class="row" >
            <div class="col-md-12 mb-2  ">
              <label class="control-label-new font-weight-bold" >ETOH & Substance Abuse History</label>
          <div class="cli_value_family">
            <span>{{PsyEvaInfo.etoh}}</span>
          </div>
        </div>
      </div> 
          <div class="row" >
            <div class="col-md-12 mb-2  ">
              <label class="control-label-new font-weight-bold" >Medical History</label>
          <div class="cli_value_family">
            <span>{{PsyEvaInfo.medicalHistory}}</span>
          </div>
        </div>
      </div> 
     <!-- <div class="row" >
        <div class="col-md-12 mb-2  ">
          <label class="control-label-new font-weight-bold" >Current Medications</label>
      <div class="cli_value_family">
        <span>{{PsyEvaInfo.currentMedications}}</span>
      </div>
    </div>
  </div> -->
  <div class="row">
    <div class="col-md-12 mb-12">
      <label class="control-label-new font-weight-bold" >Medication</label>
    </div>
  </div>
  <div class="row" *ngIf="_dtoMedications?.length!=0">
    <div class="col-md-12 mb-2  ">
    <div class="medication-dosage">
      <div class="row inputElement">
        <div class="col-md-3"><label >Medications</label></div>
        <div class="col-md-3"><label >Dosage</label></div>
        <div class="col-md-3"><label >Frequency</label></div>
        <div class="col-md-3"><label >Side effects</label></div>
      </div>
      <div class="loop-section position-relative mb-2"
        *ngFor="let med of _dtoMedications;let i= index;let last=last">
        <div class="row  inputElement">
          <div class="col-md-3">    
            <div class="cli_value_family" >
              <span>{{med.medications}}</span>
            </div>
          </div>
          <div class="col-md-3">
          <div class="cli_value_family">
            <span>{{med.dosage}}</span>
          </div>
          </div>
          <div class="col-md-3">
            <div class="cli_value_family">
            <span>{{med.frequency == 1 ? 'Daily' :med.frequency == 2 ? 'Weekly' :med.frequency==3? 'Monthly':''
            }}</span>
            </div>
          </div>
          <!--<div class="col-md-3">
            <input class="form-control" maxlength="50" [(ngModel)]="med.frequency" #medications="ngModel"
              name="frequency{{i}}" placeholder="Frequency" type="text" required>
          
          </div>-->
          <div class="col-md-3">
            <div class="cli_value_family">
              <span>{{med.sideEffects}}</span>
            </div>
          </div>
        </div>
   </div>
   </div>
   </div>
   </div>
  <div class="row" >
    <div class="col-md-12 mb-2  ">
      <label class="control-label-new font-weight-bold" >Comprehensive Medication History</label>
  <div class="cli_value_family">
    <span>{{PsyEvaInfo.comprehensiveMedication}}</span>
  </div>
</div>
</div> 
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Developmental History</label>
<div class="cli_value_family">
  <span>{{PsyEvaInfo.developmentalHistory}}</span>
</div>
</div>
</div> 
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Psychosocial History</label>
<div class="cli_value_family">
  <span>{{PsyEvaInfo.psychosocialHistory}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Family History</label>
<div class="cli_value_family">
  <span>{{PsyEvaInfo.familyHistory}}</span>
</div>
</div>
</div>

</div>
              </div> 
      
              </div> 
                   </div>
              </div>
            </div>
          </div>
        </div>  
       
         <!--End Preview popup-->

              <!--Preview popup For mental status-->
      <div class="Activity-popup">
        <div class="modal"  tabindex="-1" role="dialog" [style.display]="PopUpPreviewMS? 'block':'none'" >
           <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                 <div class="modal-header">
                    <h6 class="modal-title">Preview</h6>
                    <button type="button" class="close"  data-dismiss="modal" (click)="OnclosePopupMS()"
                    aria-label="Close" >
                    <span aria-hidden="true">&times;</span>
                 </button>
                 </div>
                 <div class="modal-body">
                  <div class="individual-threaphy-container">
                  <div  class="history-list col-md-12 p-0">
                    <div class="d-flex col-md-12">
                      <button class="ml-auto btn-print-hover mb-3 mr-2" mat-type="button" (click)="printMS()"><i class="fa fa-print"></i></button>
                    </div>
                    <div id="print-section2" class="col-md-12">
                      <div class="page-header" style=" text-align: center; font-size: 15px; font-weight: 500; margin-bottom: 25px; color: #173a64;">PSYCHIATRIC EVALUATION FROM MENTAL STATUS </div>

                    <div class="row top-details-psych">
                      <div class="col-md-3"><label>Client Name</label>
                      <p>{{_dtoPatientInfo.patientName }}</p></div>
                      <div class="col-md-3"><label>Psychiatrist Name</label>
                        <p>{{_dtoPatientInfo.clinicianName}}</p></div>
                        <div class="col-md-3"><label> Appointment  Date</label>
                          <p>{{_dtoPatientInfo.startDate | date:'MM/dd/YYYY'}}</p></div>
                          <div class="col-md-3"><label> Appointment  Time</label>
                            <p>{{_dtoPatientInfo.startTime | date:'hh:mm a'}}</p></div>                         
                     </div>

                     <div class="row" >
                      <div class="col-md-12 mb-2  ">
                         <label class="control-label-new font-weight-bold" >Appearance: (Posture, Facial Expressions, Tics, Eye Contact, Weight, Height, Hygiene) </label>
                     <div class="cli_value_family">
                      <ng-container *ngFor="let item of _mentalStatus.appearance;let i=index">
                        <span class="cli_value_family"  *ngIf="item.isChecked">
                          <span >{{item.clinicalIssues}} <small>,</small></span>
                        </span>
                        </ng-container>  
                        <span *ngIf="PsyEvaMSInfo.isTatoos==true" >Tatoos <small>,</small></span>
                        <span *ngIf="PsyEvaMSInfo.isBirthmarks">Birthmarks <small>,</small></span>
                        <span *ngIf="PsyEvaMSInfo.isDentalCondition==true ">Dental Condition <small></small></span>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="PsyEvaMSInfo.isTatoos==true">
                  <div class="col-md-12 mb-2  ">
                     <label class="preview-sub" >Enter tatoo details </label>
                 <div class="cli_value_family">
                  <span>{{PsyEvaMSInfo.tatoosDetails}}</span>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="PsyEvaMSInfo.isBirthmarks">
              <div class="col-md-12 mb-2  ">
                 <label class="preview-sub" >Enter birthmark details </label>
             <div class="cli_value_family">
              <span>{{PsyEvaMSInfo.birthmarks}}</span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="PsyEvaMSInfo.isDentalCondition==true">
          <div class="col-md-12 mb-2  ">
             <label class="preview-sub" >Enter dental condition details </label>
         <div class="cli_value_family">
          <span>{{PsyEvaMSInfo.dentalCondition}}</span>
        </div>
      </div>
    </div>

                      
                     <div class="row" >
                      <div class="col-md-12 mb-2  ">
                         <label class="control-label-new font-weight-bold" >Motor Activity: (Shaking Foot, Tapping, Tics, Fidgety) </label>
                     <div class="cli_value_family">
                      <span>{{PsyEvaMSInfo.motorActivity}}</span>
                    </div>
                  </div>
                </div>


                          <div class="row" >
                            <div class="col-md-12 mb-2  ">
                              <label class="control-label-new font-weight-bold" >Facial Expressions </label>
                          <div class="cli_value_family">
                            <span>{{PsyEvaMSInfo.facialExpressions}}</span>
                          </div>
                        </div>
                      </div>

                          <div class="row" >
                            <div class="col-md-12 mb-2  ">
                              <label class="control-label-new font-weight-bold" >What is predominant feeling/emotional response? </label>
                          <div class="cli_value_family">
                            <ng-container *ngFor="let item of _mentalStatus.emotionalResponse;let i=index">
                              <span class="cli_value_family"  *ngIf="item.isChecked">
                                <span >{{item.clinicalIssues}} <small>,</small></span>
                              </span>
                              </ng-container> 
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="_mentalStatus.emotionalResponse[9].isChecked==true">
                        <div class="col-md-12 mb-2  ">
                          <label class="preview-sub" >Other Emotional response </label>
                      <div class="cli_value_family">
                        <span>{{PsyEvaMSInfo.otherEmotionalResponse}}</span>
                      </div>
                    </div>
                  </div>
                      <div class="row" >
                        <div class="col-md-12 mb-2  ">
                          <label class="control-label-new font-weight-bold" >Attitude/Behavior </label>
                      <div class="cli_value_family">
                        <ng-container *ngFor="let item of _mentalStatus.attitude;let i=index">
                          <span class="cli_value_family"  *ngIf="item.isChecked">
                            <span >{{item.clinicalIssues}} <small>,</small></span>
                          </span>
                          </ng-container> 
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="_mentalStatus.attitude[11].isChecked==true">
                    <div class="col-md-12 mb-2  ">
                      <label class="preview-sub" >Other Attitude </label>
                  <div class="cli_value_family">
                    <span>{{PsyEvaMSInfo.otherAttitue}}</span>
                  </div>
                </div>
              </div>
              <div class="row" >
                <div class="col-md-12 mb-2  ">
                  <label class="control-label-new font-weight-bold" >Mood/Affect: (examples) On a scale of 1-10 with 10 being very happy, answer the following</label>
            
            </div>
          </div>
                        <div class="row" >
                          <div class="col-md-12 mb-2  ">
                            
                            <label class="control-label-new font-weight-bold" >How do you feel most days? </label>
                        <div class="cli_value_family">
                          <span>{{PsyEvaMSInfo.mostDays}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row" >
                      <div class="col-md-12 mb-2  ">
                        <label class="control-label-new font-weight-bold" >How do you feel now? </label>
                    <div class="cli_value_family">
                      <span>{{PsyEvaMSInfo.now}}</span>
                    </div>
                  </div>
                </div>

                <div class="row" >
                  <div class="col-md-12 mb-2  ">
                    <label class="control-label-new font-weight-bold" >In general, would you say that you feel good about yourself? </label>
                <div class="cli_value_family">
                  <span>{{PsyEvaMSInfo.goodAboutYourself}}</span>
                </div>
              </div>
            </div>


                <div class="row" >
                  <div class="col-md-12 mb-2  ">
                    <label class="control-label-new font-weight-bold" >Do you find yourself crying sometimes? </label>
                <div class="cli_value_family">
                  <span>{{PsyEvaMSInfo.crying}}</span>
                </div>
              </div>
            </div>
                  <div class="row" >
                    <div class="col-md-12 mb-2  ">
                      <label class="control-label-new font-weight-bold" >Are you a worrier? </label>
                  <div class="cli_value_family">
                    <span>{{PsyEvaMSInfo.warrier}}</span>
                  </div>
                </div>
              </div>
                    <div class="row" >
                      <div class="col-md-12 mb-2  ">
                        <label class="control-label-new font-weight-bold" >Do you experience mood swings? </label>
                    <div class="cli_value_family">
                      <span>{{PsyEvaMSInfo.moodSwings}}</span>
                    </div>
                  </div>
                </div>

                <div class="row" >
                  <div class="col-md-12 mb-2  ">
                    <label class="control-label-new font-weight-bold" >For children: If you had 3 wishes, what would they be? </label>
                <div class="cli_value_family">
                  <span>{{PsyEvaMSInfo.threeWishes}}</span>
                </div>
              </div>
            </div>

  
              <div class="row" >
                <div class="col-md-12 mb-2  ">
                  <label class="control-label-new font-weight-bold" >Mood </label>
              <div class="cli_value_family">
                <ng-container *ngFor="let item of _mentalStatus.mood;let i=index">
                  <span class="cli_value_family"  *ngIf="item.isChecked">
                    <span >{{item.clinicalIssues}} <small>,</small></span>
                  </span>
                  </ng-container> 
              </div>
            </div>
          </div>

                <div class="row" >
                  <div class="col-md-12 mb-2  ">
                    <label class="control-label-new font-weight-bold" >Is affect congruent to discussion? </label>
                <div class="cli_value_family">
                  <span>{{PsyEvaMSInfo.iscongruent==true?'Yes':PsyEvaMSInfo.iscongruent==false?'No':''}}</span>
                </div>
              </div>
            </div>
            <div class="row" >
              <div class="col-md-12 mb-2  ">
                <label class="control-label-new font-weight-bold" >Speech </label>
            <div class="cli_value_family">
              <ng-container *ngFor="let item of _mentalStatus.speech;let i=index">
                <span class="cli_value_family"  *ngIf="item.isChecked">
                  <span >{{item.clinicalIssues}} <small>,</small></span>
                </span>
                </ng-container> 
            </div>
          </div>
        </div>
        <div class="row" *ngIf="_mentalStatus.speech[18].isChecked==true" >
          <div class="col-md-12 mb-2  ">
            <label class="preview-sub" >Other Speech </label>
        <div class="cli_value_family">
          <span>{{PsyEvaMSInfo.otherSpeech}}</span>
        </div>
      </div>
    </div>
              <div class="row" >
                <div class="col-md-12 mb-2  ">
                  <label class="control-label-new font-weight-bold" >Additional Comments</label>
              <div class="cli_value_family">
                <span>{{PsyEvaMSInfo.additionalComments}}</span>
              </div>
            </div>
          </div>

              <div class="row" >
                <div class="col-md-12 mb-2  ">
                  <label class="control-label-new font-weight-bold" >Perceptual Disturbances</label>
              <div class="cli_value_family">
                <span>{{PsyEvaMSInfo.perceptualDisturbances}}</span>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-12 mb-2  ">
              <label class="control-label-new font-weight-bold" >Thought Process</label>
        
        </div>
      </div>
          <div class="row" >
            <div class="col-md-12 mb-2  ">
             
              <label class="control-label-new font-weight-bold" >Does the client’s thinking make sense?</label>
          <div class="cli_value_family">
            <span>{{PsyEvaMSInfo.thinkingMakeSense==true?'Yes':PsyEvaMSInfo.thinkingMakeSense==false?'No':''}}</span>
          </div>
        </div>
      </div>

      
      <div class="row" >
        <div class="col-md-12 mb-2  ">
          <label class="control-label-new font-weight-bold" >Are thought patterns connected?</label>
      <div class="cli_value_family">
        <span>{{PsyEvaMSInfo.thoughtPatternsConnected==true?'Yes':PsyEvaMSInfo.thoughtPatternsConnected==false?'No':''}}</span>
      </div>
    </div>
  </div>
  <div class="row" >
    <div class="col-md-12 mb-2  ">
      <label class="control-label-new font-weight-bold" >Flight of ideas? (Pressured skipping from one idea to another) </label>
  <div class="cli_value_family">
    <span>{{PsyEvaMSInfo.flightofIdeas==true?'Yes':PsyEvaMSInfo.flightofIdeas==false?'No':''}}</span>
  </div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Loose association? (Connections between thoughts that seem mixed up and although the client seems to be a connection, it is far from obvious to you) </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.looseAssociation==true?'Yes':PsyEvaMSInfo.looseAssociation==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Any evidence of magical thinking? </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.magicalThinking==true?'Yes':PsyEvaMSInfo.magicalThinking==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Thought Content (examples)</label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="sub-head-psy" >Delusions</label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Do you believe that you have special power? </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.specialPower==true?'Yes':PsyEvaMSInfo.specialPower==false?'No':''}}</span>
</div>
</div>
</div>


<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Do you feel that another person or force is controlling your behavior? </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.forceisControlling==true?'Yes':PsyEvaMSInfo.forceisControlling==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="sub-head-psy" >Paranoia</label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Do you ever have thoughts that people are talking or laughing about either you or the things you say or do? </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.talkingorLaughing==true?'Yes':PsyEvaMSInfo.talkingorLaughing==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Suicidal/Homicidal Ideations </label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
 
    <label class="control-label-new font-weight-bold" >Have you ever been so sad that you thought about hurting/killing yourself?  </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.killingYourself==true?'Yes':PsyEvaMSInfo.killingYourself==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row"  *ngIf="PsyEvaMSInfo.killingYourself==true"  >
  <div class="col-md-12 mb-2  ">
    <label class="preview-sub" >  When?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.killingYourselfWhen}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Have you ever tried to commit suicide?  </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.commitSuicide==true?'Yes':PsyEvaMSInfo.commitSuicide==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" *ngIf="PsyEvaMSInfo.commitSuicide==true" >
  <div class="col-md-12 mb-2  ">
    <label class="preview-sub" >  When?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.commitSuicideWhen}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Plan/Intent?  </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.suicidePlan==true?'Yes':PsyEvaMSInfo.suicidePlan==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" *ngIf="PsyEvaMSInfo.suicidePlan==true">
  <div class="col-md-12 mb-2  ">
    <label class="preview-sub" >  When?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.suicidePlanWhen}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Do you have a history of violent behavior/getting into fights frequently?  </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.violentBehavior==true?'Yes':PsyEvaMSInfo.violentBehavior==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Have you ever felt so angry that you thought about hurting or killing someone?  </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.killingSomeone==true?'Yes':PsyEvaMSInfo.killingSomeone==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row"  *ngIf="PsyEvaMSInfo.killingSomeone==true" >
  <div class="col-md-12 mb-2  ">
    <label class="preview-sub" >  If yes, when, about whom, and when is the last time you felt this way?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.killingSomeoneWhen}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Plan/Intent?  </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.killingSomeonePlan==true?'Yes':PsyEvaMSInfo.killingSomeonePlan==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" *ngIf="PsyEvaMSInfo.killingSomeonePlan==true">
  <div class="col-md-12 mb-2  ">
    <label class="preview-sub" > When?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.killingSomeonePlanWhen}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" > Orientation</label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="sub-head-psy" >Time</label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">

    <label class="control-label-new font-weight-bold" > When you came in today, was it in the?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.orientationTime==1?'Morning':PsyEvaMSInfo.orientationTime==2?'Afternoon':PsyEvaMSInfo.orientationTime==3?'Evening':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="sub-head-psy" >Place</label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Do you know the name of this building? </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.orientationPlace==true?'Yes':PsyEvaMSInfo.orientationPlace==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="sub-head-psy" >Person</label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Can you tell me why you are here today?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.orientationReason==true?'Yes':PsyEvaMSInfo.orientationReason==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Memory (examples)</label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">

    <label class="control-label-new font-weight-bold" >Has your memory been good?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.goodMemory==true?'Yes':PsyEvaMSInfo.goodMemory==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Do you find you are having difficulty remembering names?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.diffRememberingNames==true?'Yes':PsyEvaMSInfo.diffRememberingNames==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Do you find you are having difficulty remembering telephone numbers?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.diffRememberingPhone==true?'Yes':PsyEvaMSInfo.diffRememberingPhone==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Do you find you are having difficulty remembering appointments?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.diffRememberAppoinments==true?'Yes':PsyEvaMSInfo.diffRememberAppoinments==false?'No':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >What did you have to eat yesterday?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.eatYesterday}}</span>
</div>
</div>
</div>

<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >What did you do last weekend?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.lastWeekend}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >I am going to give you 3 objects to remember and tell back to me when I ask</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.threeObj==1?'Good':PsyEvaMSInfo.threeObj==2?'Fair':PsyEvaMSInfo.threeObj==3?'Poor':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >I am going to give you 4 numbers.  I would like you to repeat them and say in reverse.</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.fourNum==1?'Good':PsyEvaMSInfo.fourNum==2?'Fair':PsyEvaMSInfo.fourNum==3?'Poor':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Concentration (examples)</label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">

    <label class="control-label-new font-weight-bold" >Starting at 20, I would like you to count backwards by 3</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.countBackwards==1?'Good':PsyEvaMSInfo.countBackwards==2?'Fair':PsyEvaMSInfo.countBackwards==3?'Poor':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Name the days of the week in reverse.</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.daysofWeek==1?'Good':PsyEvaMSInfo.daysofWeek==2?'Fair':PsyEvaMSInfo.daysofWeek==3?'Poor':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Intelligence</label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="sub-head-psy" >Fond of knowledge </label>

</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Name 5 animals, foods, countries. </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.fundofKnowledge==1?'Above Average':PsyEvaMSInfo.fundofKnowledge==2?'Average':PsyEvaMSInfo.fundofKnowledge==3?'Below Average':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="sub-head-psy" >Abstract Thinking   </label>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >How is a lie different from a mistake? </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.lienMistake==1?'Above Average':PsyEvaMSInfo.lienMistake==2?'Average':PsyEvaMSInfo.lienMistake==3?'Below Average':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >How are a window and a door the same? </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.windownDoor==1?'Above Average':PsyEvaMSInfo.windownDoor==2?'Average':PsyEvaMSInfo.windownDoor==3?'Below Average':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="sub-head-psy" >Vocabulary  </label>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">

    <label class="control-label-new font-weight-bold" >Spell “elephant” </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.vocabulary==1?'Above Average':PsyEvaMSInfo.vocabulary==2?'Average':PsyEvaMSInfo.vocabulary==3?'Below Average':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="sub-head-psy" >Calculation  </label>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">

    <label class="control-label-new font-weight-bold" >How many quarters are in $1.75? </label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.calculation==1?'Above Average':PsyEvaMSInfo.calculation==2?'Average':PsyEvaMSInfo.calculation==3?'Below Average':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Judgment: (examples)</label>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
  
    <label class="control-label-new font-weight-bold" >Can you think of a situation where you have acted foolish or made a bad choice?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.badChoice==1?'Good':PsyEvaMSInfo.badChoice==2?'Fair':PsyEvaMSInfo.badChoice==3?'Poor':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >What would you do if someone criticizes a job that you have done or a decision out have made?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.criticizes==1?'Good':PsyEvaMSInfo.criticizes==2?'Fair':PsyEvaMSInfo.criticizes==3?'Poor':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Insight: (examples)</label>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">

    <label class="control-label-new font-weight-bold" >Do you think that you usually make good decisions?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.goodDecisions==1?'Good':PsyEvaMSInfo.goodDecisions==2?'Fair':PsyEvaMSInfo.goodDecisions==3?'Poor':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Do you think that you are in anyway responsible for what has happened?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.responsible==1?'Good':PsyEvaMSInfo.responsible==2?'Fair':PsyEvaMSInfo.responsible==3?'Poor':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >What part do you think that you played in this?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.played}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >What changes would help you most?</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.changesHelp}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Frustration Tolerance</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.frustrationTolerance==1?'Good':PsyEvaMSInfo.frustrationTolerance==2?'Fair':PsyEvaMSInfo.frustrationTolerance==3?'Poor':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Impulse Control</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.impulseControl==1?'Good':PsyEvaMSInfo.impulseControl==2?'Fair':PsyEvaMSInfo.impulseControl==3?'Poor':''}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Comments</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.comments}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Assessment</label>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Axis I</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.axisI}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Axis II</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.axisIi}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Axis III</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.axisIii}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Axis IV</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.axisIv}}</span>
</div>
</div>
</div>
<div class="row">
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Axis V</label>
  </div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">

    <label class="control-label-new font-weight-bold" >Current</label>

<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.axisVcurrent}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Last Year</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.axisVlastYear}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Summary Psychodynamic Formulation</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.psychodynamicFormulation}}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Plan/Recommendation</label>
<div class="cli_value_family">
  <span>{{PsyEvaMSInfo.recommendation}}</span>
</div>
</div>
</div>
</div>
              <!--End div-->
              </div> 
      
              </div> 
                   </div>
              </div>
            </div>
          </div>
        </div>  
       
         <!--End Preview popup-->
</div>

<a mimetype="application/octet-stream" #downloadLink></a>
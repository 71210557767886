<div class="history">
    <div class="lead-box">
    <div class="col-md-12 d-flex">
        <div class="ml-auto col-md-2">
            <mat-form-field class="w-100 filter-plain">
                <mat-label>Filter By</mat-label>
                <mat-select>
                  <mat-option>
                    Stage
                  </mat-option>
                  <mat-option>
                    Messages
                  </mat-option>
                </mat-select>
              </mat-form-field>
        </div>
    </div>
    <div class="history-list col-md-12">
        <ul>
            <li *ngFor="let item of leadhistory;let i = index">
                <div class="list-history col-md-12">
                    <div class="row">
                        <div class="col-md-1" *ngIf="item.roleId !== 4">
                            <img class="img-fluid" src="../../../assets/images/move2stage-icon.png"/>
                        </div>
                        <div class="col-md-1" *ngIf="item.roleId == 4">
                            <img class="img-fluid" src="../../../assets/images/move2stage-icon-green.png"/>
                        </div>
                        <div class="col-md-9">
                            <p class="sub-history mb-1"></p>
                            <p class="sub-history2 mb-1" innerHTML="{{global.getContent(item.message)}}"></p>
                        </div>
                        <div class="col-md-2 text-right">
                            <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) |  date: 'MM/dd/yyyy'}}</p>
                            <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) | date: 'hh:mm a' }}</p>
                        </div>
                    </div>
                   
                </div>
            </li>
           
        </ul>
    </div>
</div>
</div>

<div class="col-md-12 p-0 mt-4 view-note d-none">

    <div class="row">
        <div class="col-md-12 mb-1" *ngFor="let item of leadhistory;let i = index">
            <div class="list-note">
                <div class="row">
                    <div class="col-md-8">
                        <div class="float-left w-100 pl-4 position-relative">
                            
                            <p class="note-content mt-1" innerHTML="{{global.getContent(item.message)}}">

                            </p>
                        </div>
                    </div>
                    
                    <div class="col-md-1 text-right pl-0">
                        <!-- <a title="edit" (click)="onedittask(item)" class="icon-note"><span class="mr-2"><i
                                    class="far fa-edit"></i></span></a>
                        <a title="delete" (click)="ondeletedtask(item)" class="icon-note"><span class="mr-2"><i
                                    class="far fa-trash-alt"></i></span></a> -->
                    </div>
                    <div class="col-md-12 text-right">
                        <div class="row">
                            <div class="col-md-8">
                                <!-- <span class="tag-label">Assigned</span>
                                            <mat-chip-list aria-label="Fish selection">
                                                <mat-chip *ngFor="let a of item.tags">{{a.email}}</mat-chip>
                                            </mat-chip-list> -->
                            </div>
                            <div class="col-md-4">
                                <div class="timeanddate"><span class="time-label">Last
                                        updated</span><span>{{global.globalUtctimeformat(item.createdOn)}}</span></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="5"
                [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </div>

    </div>
</div>
<div class="row">
<form (ngSubmit)="librarydialogfrm.form.valid  && saveLibraryName(librarydialogfrm)" id="librarydialogfrm" autocomplete="off" #librarydialogfrm="ngForm"
    novalidate>
<div class="mt--14 libraryImages-container">
    <div  class="modal-header p-2">
        <span *ngIf="libraryNameId !== 0">
            <h5  class="modal-title">Edit Image Library</h5>
        </span>
        <span *ngIf="libraryNameId == 0">
            <h5  class="modal-title">Add Image Library</h5>
        </span>
        <button  type="button" mat-dialog-close data-dismiss="modal" aria-label="Close" class="close ">
            <span  aria-hidden="true">×</span></button>
        </div>
    <div class="col-md-12 ">
        <div class="col-md-12 p-0">
            <div class="top-notes d-flex w-100 position-relative mt-3">
                <!-- <mat-dialog-actions class="btn-closed">
                    <button mat-button mat-dialog-close><i class="fas fa-times"></i></button>
                  </mat-dialog-actions> -->
            </div>
            <div class="task-create-section">
            <div class="col-md-12">
                <div class="row">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Library Name"  #libraryName="ngModel"
                      [(ngModel)]="this.newlibraryName.libraryName" name="libraryName" [ngClass]="{ 'is-invalid': librarydialogfrm.submitted && libraryName.invalid }"
                      required>                      
                      <mat-error *ngIf="librarydialogfrm.submitted && libraryName.invalid ">
                        <mat-error *ngIf="libraryName.errors.required">Library name is required</mat-error>
                      </mat-error>
                  </mat-form-field>
                </div>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12 text-right mt-2 mb-2">  
                        <span *ngIf="libraryNameId !==0">
                            <button type="submit" title="Edit Comment" form="librarydialogfrm" mat-raised-button *ngIf="roleAccess.isEdit"
                                class="primary-btn-outlined-small mr-2">Edit Library</button>
                        </span>
                        <span *ngIf="libraryNameId ==0">
                            <button type="submit" title="Add Comment" form="librarydialogfrm" mat-raised-button *ngIf="roleAccess.isCreate"
                            class="primary-btn-outlined-small mr-2">Add Library</button>
                        </span>  
                       
                                <button mat-raised-button class="primary-btn-outlined-small mr-2"  *ngIf="roleAccess.isEdit || roleAccess.isCreate"  (click)="resetnotesform()">Clear
                                </button>                         
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
</form>
</div>
 export class dtoSucidalSafetyContract
    {
        suicidalSafetyContractId:Number;
        patientAccountId:Number;
        qtwoName:string;
        qtwoContact:string;
        qthreeContact:string;
        clientSign:any;
        therapistSign:any;
        clinentSignDate:any;
        therapistSignDate:any;
        qthreeContactDtl:Array<dtoGetSuicidalSafetyContactDtl>;
        qfourContactDtl:Array<dtoGetSuicidalSafetyContactDtl>;
        qfiveContactDtl:Array<dtoGetSuicidalSafetyContactDtl>;
    }

    export class dtoGetSuicidalSafetyContactDtl{
        suicidalSafetyContactDtlId:Number;
        patientAccountId:Number;
        suicidalSafetyContractId:Number;
        contractName:string;
        contact:string;
        contractAddress:string;
        typeId:Number;
    }
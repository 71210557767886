import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';


@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http: HttpClient, public commonService: CommonService) { }
  getPatientList(): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetIntakePatientList');
  }
  getAppointmentTypes(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetAppointmentTypes/GetAppointmentTypes?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getPatientAccounts() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetPatientAccounts');
  }
  postIntakeSchedule(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostMeeting/PostMeeting',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postAssignStaff(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostMeeting/AssignStaff',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  // getAssignedSlots(id,MeetingId){
  //   return this.http.get<any>(environment.BaseURI + 'gateway/PostMeeting/GetAssignedSlots?Id=' + this.commonService.DataToEncrypt(id, true)+"&MeetingId="+ this.commonService.DataToEncrypt(MeetingId, true) );
  // }
  getAssignedSlots(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostMeeting/GetAssignedSlots',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postRemoveConflicts(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostMeeting/PostRemoveConflicts',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getCalenderTasks(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetCalenderTasks?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  checkDocumentExists(id,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetPdfDocumentExists?Id=' + this.commonService.DataToEncrypt(id, true)+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true));
  }
  checkAppointmentDocumentExists(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/CheckAppointmentDocumentExists?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  checkTreatmentPlanDocumentExists(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetTreatmentPlanDocumentExists?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  ShareAllAppointmentDocuments(id, toEmail,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/ShareAllAppointmentDocuments?Id=' + this.commonService.DataToEncrypt(id, true) + '&toEmail=' + toEmail+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true));
  }
  ShareAllCommunicationDocuments(id, toEmail,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/ShareAllCommunicationDocuments?Id=' + this.commonService.DataToEncrypt(id, true) + '&toEmail=' + toEmail+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true));
  }
  ShareAllOnBoardingDocuments(id, toEmail,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/ShareAllOnBoardingDocuments?Id=' + this.commonService.DataToEncrypt(id, true) + '&toEmail=' + toEmail+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true) );
  }
  ShareAllSusidalRiskDocument(id, toEmail,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/ShareAllSusidalRiskDocument?Id=' + this.commonService.DataToEncrypt(id, true) + '&toEmail=' + toEmail);
  }
  ShareAllUploadedDocument(id, toEmail,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/ShareAllUploadedDocument?Id=' + this.commonService.DataToEncrypt(id, true) + '&toEmail=' + toEmail+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true));
  }
  ShareAllTreatmentPlanDocument(id, toEmail,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/ShareAllTreatmentPlanDocuments?Id=' + this.commonService.DataToEncrypt(id, true) + '&toEmail=' + toEmail+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true));
  }
  ShareAllOnConsentDocuments(id, toEmail,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/ShareAllOnConsentDocuments?Id=' + this.commonService.DataToEncrypt(id, true) + '&toEmail=' + toEmail+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true) );
  }
  ShareAllIntakeAndTreatment(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GeneratePdf/ShareAllIntakeAndTreatment',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  checkCommunicationDocumentExists(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/CheckCommunicationDocumentExists?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getUploadedDocumentList(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/CheckUploadedDocumentExists?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getPatientBreifcase(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetPatientBreifcase?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  postAcceptMeeting(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostMeeting/AcceptMeeting',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  onReScheduleMeeting(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostMeeting/ReScheduleMeeting?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  onReScheduleAccept(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostMeeting/ReScheduleAccept?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  onReScheduleDeny(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostMeeting/ReScheduleDeny?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getInTakeDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostMeeting/GetInTakeDetails?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  // getTimeSlots(id, day) {
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GetMeeting/GetSlotByAppointment?Id=' + this.commonService.DataToEncrypt(id, true) + '&day=' + this.commonService.DataToEncrypt(day, true));
  // }
  getTimeSlots(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetMeeting/GetSlotByAppointment',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getDay1MeetingDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetMeeting/GetScheduleIntakeWithStaff?Id=' + this.commonService.DataToEncrypt(id, true));
  }



  //#region Intake
  onAcceptCancelIntake(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/CancelIntake',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  onDeclineCancelIntake(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/CancelIntake',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getInTakeQuestions(id, patientAccountId, configureFormTypeId = 0) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetInTakeQuestions?Id=' + this.commonService.DataToEncrypt(id, true) + '&PatientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true)
      + '&CfTId=' + this.commonService.DataToEncrypt(configureFormTypeId, true)
    );
  }
  getInTakeQuestionsintake(id, patientAccountId, configureFormTypeId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetInTakeQuestions?Id=' + this.commonService.DataToEncrypt(id, true) +
     '&PatientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true)
      + '&CfTId=' + this.commonService.DataToEncrypt(configureFormTypeId, true)
    );
  }
  postIntakechecklist(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/SaveCheckList',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postIntakecheckAnswer(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/SaveCheckAnswer',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postIntakeSurvey(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/SaveSurveyAnswer',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getIntakeTemplates(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetIntakeTemplates?PatientAccountId=' + this.commonService.DataToEncrypt(id, true));
  }
  getTemplateData(id, patientAccountId, isClinician, ClinicianTypeId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetTemplateData?Id=' + this.commonService.DataToEncrypt(id, true) + '&PatientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true) + '&isClinician=' + this.commonService.DataToEncrypt(isClinician, true) + '&cliniciantypeid=' + this.commonService.DataToEncrypt(ClinicianTypeId, true));
  }
  postUpdateDynamicFields(data, physicianDetails) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/UpdateDynamicFields?cliniciantypeid=' + this.commonService.DataToEncrypt(physicianDetails, true),
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postUpdateVerified(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/UpdateVerified',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getNutritionalQuestion(id, patientAccountId, isNew) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetNutritionalQuestion?Id=' + this.commonService.DataToEncrypt(id, true) + '&PatientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true) + '&IsNew=' + this.commonService.DataToEncrypt(isNew, true));
  }
  postIntakeNutritional(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/SaveSurveyList',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  saveIntakeNutritional(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/SaveNutitionalQuestion',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  getNutritionPdfFile(patientId, documentId, formCount, configureFormId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetNutritionPdfFile?patientId=' + this.commonService.DataToEncrypt(patientId, true) + '&documentId=' + this.commonService.DataToEncrypt(documentId, true) + '&formCount=' + this.commonService.DataToEncrypt(formCount, true) + '&configureFormId=' + this.commonService.DataToEncrypt(configureFormId, true));
  }

  getFinancialDocument(Id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetFinancialDocuments?id=' + this.commonService.DataToEncrypt(Id, true));
  }
  SavePainAssessment(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/SavePainAssessment',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getPainAssessment(id, isClear) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetPainAssessment?id=' + this.commonService.DataToEncrypt(id, true) + '&isClear=' + this.commonService.DataToEncrypt(isClear, true));
  }
  getPainAssessmentPdf(id, painAssesmentId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetPainAssessmentPdf?id=' + this.commonService.DataToEncrypt(id, true) + '&painAssesmentId=' + this.commonService.DataToEncrypt(painAssesmentId, true));
  }
  getIGetPatientInfoPdfFilentake(id, documentType): Observable<any> {
    return this.http.get(environment.BaseURI + 'gateway/GeneratePdf/getIGetPatientInfoPdfFilentake?patientId=' + this.commonService.DataToEncrypt(id, true) + '&documentId=' + this.commonService.DataToEncrypt(documentType, true));
  }
  getChmaPdf(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetChmaPdf?id=' + this.commonService.DataToEncrypt(id, true));
  }

  //#endregion
  //#region activityLog
  getLeadHistory(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetActivityLogs?id=' + this.commonService.DataToEncrypt(id, true));
  }
  //#endregion
  //#region  CMHA
  getPatientDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetPatientDetails?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getPatientDetailsNew(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetAllPatientDetails?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getCHMADetails(id,secondaryId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetChmaDetails?Id=' + this.commonService.DataToEncrypt(id, true)+ '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
  }
  postproviderinfo(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/SaveProviderContactinfo',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postfrequncy(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostPatientFrequncy',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postChmaDeatils(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostChmaDeatils',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  removePsychotropicMed(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostIntake/RemovePsychotropicMed?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getPsychotropicMedication(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetPsychotropicMedication?id=' + this.commonService.DataToEncrypt(id, true));
  }
  postPsychotropic(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostPsychotropic',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postCmhaAssessment(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostCmhaAssessment',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postChmaStatus(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostChmaStatus',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getCmhaAssessment(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetChmaAssessment?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getCmhaStatus(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetChmaStatus?id=' + this.commonService.DataToEncrypt(id, true));
  }


  uploadChmaTreatmentSummaryDeatils(frmData) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/UploadChmaTreatmentSummaryDeatils', frmData)
      .pipe(map(res => {

        return res;
      }));
  }


  postChmaTreatmentSummaryDeatils(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostChmaTreatmentSummaryDeatils',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  editChmaTreatmentSummaryDeatils(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/EditChmaTreatmentSummaryDeatils',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  getChmaTreatmentSummaryDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetChmaTreatmentSummaryDetails?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getClinicianForAssignClinician(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/getClinicianForAssignClinician?');
  }
  //#endregion
  getPatientBreifcaseAppointments(id, duration, apponitmentType, clinician, isUpPast) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/getPatientBreifcaseAppointments?Id=' + this.commonService.DataToEncrypt(id, true)
      + '&duration=' + this.commonService.DataToEncrypt(duration, true)
      + '&apponitmentType=' + this.commonService.DataToEncrypt(apponitmentType, true)
      + '&clinician=' + this.commonService.DataToEncrypt(clinician, true)
      + '&isUpPast=' + this.commonService.DataToEncrypt(isUpPast, true)

    );
  }
  getPatientStatusMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetPatientStatusMaster');
  }
  postPatientAccountStatus(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostPateintStatus',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getDischargedPatientList(): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetDischargedPatientList');
  }

  postDischargedPatientAccountStatus(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/ChangeDischargedPateintStatus',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  //Phonecall and school meeting
  GetSlotByForPhonecallSchoolMeet(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetMeeting/GetSlotByForPhonecallSchoolMeet',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  postVitamins(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostVitamins',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getPatientVitamins(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetPatientVitamins?id=' + this.commonService.DataToEncrypt(id, true));
  }
  removeVitamin(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostIntake/RemoveVitamin?Id=' + this.commonService.DataToEncrypt(id, true));
  }

  getPatientOutofPktInfo(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetPatientOutofPacketinfo?Id=' + this.commonService.DataToEncrypt(id, true));
  }

  getPatientOutofPktDtls(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetPatientOutofPacketDtls?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getPatientOutofPktCompletedDtls(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetPatientOutofPacketCompletedDtls?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getadminLoginPatientList(id, pgNo, pgSz) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetAdminLoginPatientLists?Id=' + this.commonService.DataToEncrypt(id, true) + "&PageNo=" + this.commonService.DataToEncrypt(pgNo, true) + "&Pages=" + this.commonService.DataToEncrypt(pgSz, true));
  }
  getPatientViewList(id, pgNo, pgSz) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetPatientViewList?Id=' + this.commonService.DataToEncrypt(id, true) + "&PageNo=" + this.commonService.DataToEncrypt(pgNo, true) + "&Pages=" + this.commonService.DataToEncrypt(pgSz, true));
  }
  postChmaStatusWithClinicans(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostChmaStatusWithClinicans',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getPatientchmaclinicians(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetChmaClinicians?id=' + this.commonService.DataToEncrypt(id, true));
  }
  postOutofPktInvoicePdf(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GeneratePdf/PostOutofPktInvoicePdf',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  // saveOutOfPacketPdfHistory(data) {
  //   const headers = new HttpHeaders().set(
  //     'Content-Type',
  //     'application/json; charset=utf-8'
  //   );
  //   return this.http.post(environment.BaseURI + 'gateway/GetPatientAccount/SaveOutOfPacketPdfHistory',
  //     data, { headers: headers, responseType: 'text' })
  //     .pipe(map(function (response) { return response; }));
  // }
  listFinancialDocuments(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/ListFinancialDocuments?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getFinancialProposal(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/FinancialProposalPdf?id=' + this.commonService.DataToEncrypt(id, true));
  }
  GetBenefitsAndAuthorizationPdf(id,patientInfoId,InsuranceId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetBenefitsAndAuthorizationPdf?id=' + 
      this.commonService.DataToEncrypt(id, true)+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true)
      + '&insuranceId=' + this.commonService.DataToEncrypt(InsuranceId, true)
    );
  }
  GetBenefitsAndAuthorizationInsurancePdf(id,patientInfoId,insurancedetailid,HistoryId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetBenefitsAndAuthorizationInsurancePdf?id=' + this.commonService.DataToEncrypt(id, true)+ 
    '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true) +
    '&insurancedetailid=' + this.commonService.DataToEncrypt(insurancedetailid, true)
    +'&historyId=' + this.commonService.DataToEncrypt(HistoryId, true)
  );
  }
  UploadedDocumentsDownload(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GeneratePdf/UploadedDocumentsDownload',
      data, { headers: headers })
      .pipe(map(function (response) { return response; }));
  }
  shareAppointmentDocuments(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GeneratePdf/ShareAppointmentDocuments',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  // postOutofPktInvoicePdfForDocuments(data)
  // { 
  //   const headers = new HttpHeaders().set(
  //     'Content-Type',
  //     'application/json; charset=utf-8'
  //   );
  //   return this.http.post(environment.BaseURI + 'gateway/GeneratePdf/PostOutofPktInvoicePdfForDocuments',
  //     data, { headers: headers, responseType: 'text' })
  //     .pipe(map(function (response) { return response; }));
  // }
  postPatientOutofPktNotes(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostPatientOutofPacketNotes',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  getPatientOutofPacketInvoiceLists(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetPatientOutofPacketInvoiceLists?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  // GetPdfConsentform(reqJson){
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetConsentPdf' + this.commonService.DataToEncrypt(reqJson, true));

  // }
   GetPdfConsentform(id,patientAccountsId,isClinician,clinicianId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetConsentPdf?Id=' + id 
    + '&PatientAccountsId=' + patientAccountsId+ 
    '&isClinician=' +isClinician+ 
    '&CinicianId=' +clinicianId);
   }
  //   const headers = new HttpHeaders().set(
  //     'Content-Type',
  //     'application/json; charset=utf-8'
  //   );
  //   return this.http.post(environment.BaseURI + 'gateway/GeneratePdf/GetConsentPdf',
  //   reqJson, { headers: headers, responseType: 'text' })
  //     .pipe(map(function (response) { return response; }));
  // }
  getIntakeConsentTemplates(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetIntakeConsentTemplates?PatientAccountId=' + this.commonService.DataToEncrypt(id, true));
  }
  // RemovePatientAccount(id,IsActive){
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/RemovePatientAccount?Id=' + this.commonService.DataToEncrypt(id, true)
  //   +'&Isactive=' + this.commonService.DataToEncrypt(IsActive, true));
  // }
  RemovePatientAccount(id,IsActive,selectedreason,selectedcomments,){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/RemovePatientAccount?Id=' + this.commonService.DataToEncrypt(id, true)
    +'&Isactive=' + this.commonService.DataToEncrypt(IsActive, true)
    +'&Selectedreason=' + this.commonService.DataToEncrypt(selectedreason, true)
    +'&Selectedcomments=' + this.commonService.DataToEncrypt(selectedcomments, true));
  }
  getadminDeclinePatientList(id, pgNo, pgSz) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/GetAdminDeclinePatientLists?Id=' + this.commonService.DataToEncrypt(id, true) + "&PageNo=" + this.commonService.DataToEncrypt(pgNo, true) + "&Pages=" + this.commonService.DataToEncrypt(pgSz, true));
  }
  getFutureTherpy(id , therapy){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/getFutureTherpy?Id=' + this.commonService.DataToEncrypt(id, true) 
    + "&therapy=" + this.commonService.DataToEncrypt(therapy, true) );

  }
  RemoveTherapy(id,IsActive){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPatientAccount/RemoveTherapy?Id=' + this.commonService.DataToEncrypt(id, true)
    +'&Isactive=' + this.commonService.DataToEncrypt(IsActive, true));
  }

  getPsychotropicMedication_V1(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetPsychotropicMedication_V1?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getTreatmentPlanComments(id,Commentid,targetSymptomId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetTreatmentPlanComments?id=' + this.commonService.DataToEncrypt(id, true)+ "&Commentsid=" +this.commonService.DataToEncrypt(Commentid,true)+"&TargetSymptomid="+this.commonService.DataToEncrypt(targetSymptomId,true));
  }

  getTreatmentPlanCommentsById(id,Commentid) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetTreatmentPlanCommentsById?id=' + this.commonService.DataToEncrypt(id, true)+ "&Commentsid=" +this.commonService.DataToEncrypt(Commentid,true));
  }
  
  getTreatmentPlanCommentsGoal(id,CommentsidAll,shortTermGoalId,chma,shortTermGoalsMasterid) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetTreatmentPlanCommentsGoal?id=' + this.commonService.DataToEncrypt(id, true)+ "&Commentsid=" +this.commonService.DataToEncrypt(CommentsidAll,true)+"&ShortTermGoalid="+this.commonService.DataToEncrypt(shortTermGoalId,true)+"&Chma="+this.commonService.DataToEncrypt(chma,true)+"&ShortTermGoalsMasterid="+this.commonService.DataToEncrypt(shortTermGoalsMasterid,true));
  }

  postPrimaryClinician(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/PostPrimaryClinician',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postResumeClients(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostResumeClients',
    data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));

  }
  getDischargePatientinChma(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetDischargePatientinChma?id=' + this.commonService.DataToEncrypt(id, true));
  }

  getNutritionPdfFileHistory(patientId, documentId,formCount,configureFormId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetNutritionPdfFileHistory?patientId=' + this.commonService.DataToEncrypt(patientId, true) + '&documentId=' + this.commonService.DataToEncrypt(documentId, true)+ '&formCount=' +this.commonService.DataToEncrypt(formCount, true)+ '&configureFormId=' +this.commonService.DataToEncrypt(configureFormId, true));
  }
  ShareAllSuicideAssessmentsafetyDocument(id, toEmail,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/ShareAllSuicideAssessmentsafetyDocument?Id=' + this.commonService.DataToEncrypt(id, true) + '&toEmail=' + toEmail+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true));
  }
  ShareAllSuicideAssessmentsafetyDocuments(id, toEmail,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/ShareAllSuicideAssessmentsafetyDocument?Id=' + this.commonService.DataToEncrypt(id, true) + '&toEmail=' + toEmail+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true));
  }
  // getPainAssessmentPdfdata(id,painAssesmentId): Observable<any> {
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetPainAssessmentPdfDocuments?id=' + this.commonService.DataToEncrypt(id, true)+ '&painAssesmentId=' + this.commonService.DataToEncrypt(painAssesmentId, true));
  // }
  getPainAssessmentPdfdata(id: string, painAssesmentId: string): Observable<any> {
    const url = `${environment.BaseURI}gateway/GeneratePdf/GetPainAssessmentPdfDocuments?id=${this.commonService.DataToEncrypt(id, true)}&painAssesmentId=${this.commonService.DataToEncrypt(painAssesmentId, true)}`;
    return this.http.get<any>(url).pipe(
      timeout(180000) // 3 minutes timeout
    );
  }

  getFinancialProposalHistory(id,fid) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/FinancialProposalPdfHistory?id=' + this.commonService.DataToEncrypt(id, true)
    + '&fid=' + this.commonService.DataToEncrypt(fid, true)
  );
  }
  getIGetPatientInfoPdfFilentakeHistory(id, documentType): Observable<any> {
    return this.http.get(environment.BaseURI + 'gateway/GeneratePdf/getIGetPatientInfoPdfFilentakeHistory?patientId=' + this.commonService.DataToEncrypt(id, true) + '&documentId=' + this.commonService.DataToEncrypt(documentType, true));
  }

  getNutritionPdfFileHistory1(patientId, documentId,formCount,configureFormId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetNutritionPdfFileHistory1?patientId=' + this.commonService.DataToEncrypt(patientId, true) + '&documentId=' + this.commonService.DataToEncrypt(documentId, true)+ '&formCount=' +this.commonService.DataToEncrypt(formCount, true)+ '&configureFormId=' +this.commonService.DataToEncrypt(configureFormId, true));
  }

  getPainAssessmentPdfHistory(id,painAssesmentId): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetPainAssessmentPdfHistory?id=' + this.commonService.DataToEncrypt(id, true)+ '&painAssesmentId=' + this.commonService.DataToEncrypt(painAssesmentId, true));
  }
  GetPdfConsentformHistory(id,patientAccountsId,isClinician,clinicianId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetConsentPdfHistory?Id=' + id 
    + '&PatientAccountsId=' + patientAccountsId+ 
    '&isClinician=' +isClinician+ 
    '&CinicianId=' +clinicianId);
   }
   getChmaPdfHistory(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetChmaPdfHistory?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getPatientInfoPDFFile(id, documentType): Observable<any> {
    return this.http.get(environment.BaseURI + 'gateway/LeadContact/DownloadFileHistory1?patientId=' + this.commonService.DataToEncrypt(id, true) + '&documentId=' + this.commonService.DataToEncrypt(documentType, true));    
  }
  getTemplateDataClinician(id, patientAccountId, isClinician, ClinicianTypeId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetTemplateDataClinician?Id=' + this.commonService.DataToEncrypt(id, true) + '&PatientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true) + '&isClinician=' + this.commonService.DataToEncrypt(isClinician, true) + '&cliniciantypeid=' + this.commonService.DataToEncrypt(ClinicianTypeId, true));
  }

 getPainAssessmentHistory(id,isClear) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetPainAssessmentHistory?id=' + this.commonService.DataToEncrypt(id, true)+ '&isClear=' + this.commonService.DataToEncrypt(isClear, true));
}
GetBenefitsAndAuthorizationPdfHistory(id,patientInfoId,InsuranceId,HistoryId) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetBenefitsAndAuthorizationPdf?id=' + 
    this.commonService.DataToEncrypt(id, true)+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true)
    + '&insuranceId=' + this.commonService.DataToEncrypt(InsuranceId, true)
    + '&historyId=' + this.commonService.DataToEncrypt(HistoryId, true)
  );
}
ShareAllOnFinancialProposalDocuments(id, toEmail,patientInfoId) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/ShareAllOnFinancialProposalDocuments?Id=' + this.commonService.DataToEncrypt(id, true) + '&toEmail=' + toEmail+ '&patientInfoId=' + this.commonService.DataToEncrypt(patientInfoId, true) );
}
}
  
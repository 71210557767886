 <div class="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-home" role="tabpanel"
 aria-labelledby="v-pills-home-tab">
  
       <form >
         <div class="col-md-12">
           
           <div class="row">
             <div class="col-md-4">
               <mat-form-field class="w-100">
                 <mat-label>Provider Code</mat-label>
                 <mat-select >
                   <mat-option value="one">First option</mat-option>
                   <mat-option value="two">Second option</mat-option>
                 </mat-select>
               </mat-form-field>
             </div>
             <div class="col-md-2">
               <button mat-stroked-button class="btn primary-btn-filled-small primary-btn-m mt-3"> Rename</button>
             </div>
           </div>
           <div class="row">
             <div class="col-md-12">
               <div class="row">
            
                 <div class="col-md-4">
                  
                     <mat-form-field class="w-100">
                      <mat-label>Provider Type</mat-label>
                      <mat-select name="billprovider"  [(ngModel)]="ProviderId"  
                      required>
                      <mat-option *ngFor="let bp of providerTypeList" [value]="bp.providerTypeId">{{bp.providerDescription}}</mat-option>
                    </mat-select>
                          <!-- <mat-error *ngIf="Transactionfrm.submitted && Tid.invalid">
                            <mat-error *ngIf="Tid.errors.required">Transaction Type is required</mat-error>
                          </mat-error> -->
                        </mat-form-field>
                 
                 </div>
                 <div class="col-md-4">
                   <mat-form-field class="w-100">
                     <mat-label>Speciality</mat-label>
                     <mat-select>
                       <mat-option value="one">First option</mat-option>
                       <mat-option value="two">Second option</mat-option>
                     </mat-select>
                   </mat-form-field>
                 </div>
                 <div class="col-md-4">
                   <div class="d-flex">
                   <mat-form-field class="flex-grow-1 mr-2">
                     <mat-label>Provider Group</mat-label>
                     <mat-select>
                       <mat-option value="one">First option</mat-option>
                       <mat-option value="two">Second option</mat-option>
                     </mat-select>
                   </mat-form-field>
                   <div class="ml-auto">
                     <button mat-stroked-button class="btn primary-btn-filled-small primary-btn-m mt-3"> New</button>
                   </div>
                 </div>
                 </div>
                 </div>
             </div>
           
           </div>
           <div class="row">
             <div class="col-md-4">
             <mat-form-field class="w-100">
               <mat-label>NPI</mat-label>
               <input matInput>
             </mat-form-field>
             </div>
           </div>
           <div class="row">
             <div class="col-md-4">
               <mat-form-field class="w-100">
                 <mat-label>First Name</mat-label>
                 <input matInput>
               </mat-form-field>
             </div>
             <div class="col-md-4">
               <mat-form-field class="w-100">
                 <mat-label>Middle Name</mat-label>
                 <input matInput>
               </mat-form-field>
             </div>
             <div class="col-md-4">
               <mat-form-field class="w-100">
                 <mat-label>Last Name</mat-label>
                 <input matInput>
               </mat-form-field>
             </div>
             <div class="col-md-4">
               <mat-form-field class="w-100">
                 <mat-label>Title</mat-label>
                 <input matInput>
               </mat-form-field>
             </div>
             <div class="col-md-4">
               <mat-form-field class="w-100">
                 <mat-label>Spoken Name</mat-label>
                 <input matInput>
               </mat-form-field>
             </div>
             <div class="col-md-4">
               <mat-form-field class="w-100">
                 <mat-label>Select Gender</mat-label>
                 <mat-select>
                   <mat-option value="one">Male</mat-option>
                   <mat-option value="two">Female</mat-option>
                 </mat-select>
               </mat-form-field>
             </div>
             <div class="col-md-4">
               <mat-form-field class="w-100 ">
                 <mat-label>Comment</mat-label>
                 <textarea matInput></textarea>
               </mat-form-field>
             </div>
           </div>
           <div class="row">
             <div class="col-md-12 bill-address-tab">
                <!-- Nav tabs -->
 <div class="card border-0">
   <div class="card-header p-1">
     <ul class="nav nav-tabs justify-content-left" role="tablist">
       <li class="nav-item">
         <a class="nav-link active" data-toggle="tab" href="#home" role="tab">
           <i class="now-ui-icons objects_umbrella-13"></i> Address
         </a>
       </li>
       <li class="nav-item">
         <a class="nav-link" data-toggle="tab" href="#profile" role="tab">
           <i class="now-ui-icons shopping_cart-simple"></i> Pay To Address
         </a>
       </li>
     
     </ul>
   </div>
   <div class="card-body p-1">
     <div class="tab-content text-center">
       <div class="tab-pane active" id="home" role="tabpanel">
         <div class="col-md-12">
           <div class="row">
             <div class="col-md-12">
               <mat-form-field class="w-100">
                 <mat-label>Address</mat-label>
                 <textarea matInput></textarea>
               </mat-form-field>
               </div>
             </div>  
             <div class="row">
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>City</mat-label>
                   <input matInput>
                 </mat-form-field>
               </div>
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>State</mat-label>
                   <input matInput>
                 </mat-form-field>
               </div>
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>Zip</mat-label>
                   <input matInput>
                 </mat-form-field>
               </div>
             </div>
             <div class="row">
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>Home</mat-label>
                   <input matInput>
                 </mat-form-field>
               </div>
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>Office</mat-label>
                   <input matInput>
                 </mat-form-field>
               </div>
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>Pager</mat-label>
                   <input matInput>
                 </mat-form-field>
               </div>
             </div>
         </div>
       </div>
       <div class="tab-pane" id="profile" role="tabpanel">
         <div class="col-md-12">
           <div class="row">
             <div class="col-md-12">
               <mat-form-field class="w-100">
                 <mat-label>Address</mat-label>
                 <textarea matInput></textarea>
               </mat-form-field>
               </div>
             </div>  
             <div class="row">
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>City</mat-label>
                   <input matInput>
                 </mat-form-field>
               </div>
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>State</mat-label>
                   <input matInput>
                 </mat-form-field>
               </div>
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>Zip</mat-label>
                   <input matInput>
                 </mat-form-field>
               </div>
             </div>
             <div class="row">
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>Home</mat-label>
                   <input matInput>
                 </mat-form-field>
               </div>
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>Office</mat-label>
                   <input matInput>
                 </mat-form-field>
               </div>
               <div class="col-md-4">
                 <mat-form-field class="w-100">
                   <mat-label>Pager</mat-label>
                   <input matInput> 
                 </mat-form-field>
               </div>
             </div>
         </div>
       </div>
      
     </div>
   </div>
 </div>  
   </div>
    </div>
<div class="row">
<div class="col-md-12 mt-2">
<div class="row">
<div  class="col-md-5 mt-2 ">
 <div  class="mt-2">
   <span  class="small-heading-label align-middle font-weight-500">By default use</span>
   <span  class="ml-4 yes-no-insurance">
     <mat-radio-group aria-label="Select an option">
       <mat-radio-button class="mr-2" value="1">Federal Tax ID</mat-radio-button>
       <mat-radio-button value="2">SSN</mat-radio-button>
     </mat-radio-group>
   </span>
 </div>
</div>
<div class="col-md-3">
 <mat-form-field class="w-100">
   <mat-label>Federal Tax ID</mat-label>
   <input matInput>
 </mat-form-field>
</div>
<div class="col-md-3">
 <div class="check-custom mt-4">
 <mat-checkbox class="example-margin">Signature On File</mat-checkbox>
</div>
</div>
</div>
</div>
</div>
<div class="row">
<div class="col-md-12 mt-3">
Insurance Specific  FTN/SSN ID
</div>
<div class="col-md-4">
<mat-form-field class="w-100">
 <mat-label>Inusrance</mat-label>
 <mat-select>
   <mat-option value="one">Male</mat-option>
   <mat-option value="two">Female</mat-option>
 </mat-select>
</mat-form-field>
</div>
<div class="col-md-4">
<mat-form-field class="w-100">
 <mat-label>Provider ID</mat-label>
 <mat-select>
   <mat-option value="one">Male</mat-option>
   <mat-option value="two">Female</mat-option>
 </mat-select>
</mat-form-field>
</div>
<div class="col-md-4">
<button mat-stroked-button class="btn primary-btn-filled-small primary-btn-m mt-3"> Add</button>
           
</div>
</div>
<div class="row">
<div class="col-md-12 table-custom">
<div class="table-responsive">
 <div class="mat-elevation-z8" style="display:none;">
   
 </div>
</div>

</div>
</div>


         
         </div>
       </form>
     </div>
     <!-- provider master-->

import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { PatientService } from 'src/app/patient/patient.service';
import { IntakeCmhaComponent } from 'src/app/patient/intake/intake-cmha/intake-cmha.component';
import { dtoChmastatusWithChmaClinicians,DtoChmaproviderPsychatric ,  DtoChmaproviderPhysian, DtoChmaproviderTherapy } from 'src/app/_models/_Patient/_CHMADetails';
import { LeadService } from 'src/app/patient-lead/lead.service';
import { chmaEditPatientDetails } from 'src/app/_models/_Patient/_ChmaEditPatientDetails';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
declare function decrypt(data): any;

@Component({
  selector: 'app-cmha-patientdetails',
  templateUrl: './cmha-patientdetails.component.html',
  styleUrls: ['./cmha-patientdetails.component.scss']
})
export class CmhaPatientdetailsComponent implements OnInit {
  // @ViewChild(IntakeCmhaComponent, { static: false }) private chma;
  isMovedChma: boolean;
  queYesNo = constantVariables.questionType.YesNo;
  queMCSA = constantVariables.questionType.MCSA;
  queMCMA = constantVariables.questionType.MCMA;
  queDescriptive = constantVariables.questionType.Descriptive;
  queRatings = constantVariables.questionType.Ratings;
  survey = constantVariables.inTakeForms.Survey;
  patientInfoId: any;
  patientAccountId: any;
  lstPatientDetails: any = [];
  dtofrequency: any = "";
  isEditPsychiatrist: boolean = false;
  isEditPhysicians: boolean = false;
  isEditTherapist: boolean = false;
  isEditFrequency: boolean = false;
  isReqFrequency: boolean = false;
  dtoChmastatusWithClinicians:any;
  statesMaster:any=[];
  ddlStates:any;
  ddlCitiesProviderinfo:any;
  ddlCitiesLoad : any;
  dtoChmaproviderPsychatric:any;
  dtoChmaproviderPhysian:any;
  dtoChmaproviderTherapy:any;
  IsEditChmaClinicians: boolean=false;
  isEditPatientDetails:boolean=false;
  ddlgrade:any;
  CityName:any;
  isPatientDetailsFormSubmitted:boolean=false;
  patientDetails=new chmaEditPatientDetails();
  @ViewChild('frmPatientDetails') frmPatientDetails: NgForm;
  roleAccess: RoelAccess;
  constructor(public global: Global, public patientservice: PatientService, private chma: IntakeCmhaComponent,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public leadservice:LeadService,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.PrepareCHMA;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    // this.frmPatientDetails.form.valueChanges.subscribe(()=>{ 
    //   this.global.frmPatientDetails = this.frmPatientDetails.form;
    // })
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.isMovedChma = Number(this.commonService.FrontEndDecryption(params.isChma).replace(/"/g, "")) == 0 ? false : true;
      }
      this.dtoChmastatusWithClinicians = new dtoChmastatusWithChmaClinicians();
          
      this.dtoChmaproviderPsychatric=new DtoChmaproviderPsychatric();
      this.dtoChmaproviderPhysian=new DtoChmaproviderPhysian();
      this.dtoChmaproviderTherapy=new DtoChmaproviderTherapy();
      
     
      this.getchmaclinicinDetails()
      this.getddlStateMaster()
      
    });
  }
   onStateChnage(state) {
    // this.isSubmit = false;
    let stateItem = this.ddlStates.find(x => x.StatesId == state);
    this.ddlCitiesProviderinfo = this.ddlCitiesLoad.filter((item) => item.StatesId == stateItem.StatesId); 
    this.lstPatientDetails[0].cityId = 0;
  }
  getPatientDetails() {
    this.spinnerservice.show();
    this.patientservice.getPatientDetailsNew(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();        
        this.lstPatientDetails = cloneDeep(res);
        this.dtofrequency = this.lstPatientDetails[0].frequency;
        let stateItem = this.ddlStates.find(x => x.StatesId == this.lstPatientDetails[0].stateId);
        this.ddlCitiesProviderinfo = this.ddlCitiesLoad.filter((item) => item.StatesId == stateItem.StatesId); 
        this.lstPatientDetails[0].cityId =  this.lstPatientDetails[0].cityId;
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onEditPsychiatrist() {
    let pid=this.dtoChmaproviderPsychatric.Pid;
    this.isEditPsychiatrist = true;
    this.dtoChmaproviderPsychatric.Pid=pid;
  }
  onCancelPsychiatrist() {
    this.isEditPsychiatrist = false;
    this.getPatientDetails();
    this.getchmaclinicinDetails();
  }
  onEditPhysican() {
    this.isEditPhysicians = true;
  }
  onCancelPhysican() {
    this.isEditPhysicians = false;
    this.getPatientDetails();
    this.getchmaclinicinDetails();
  }
  onEditTherapist() {
    this.isEditTherapist = true;
  
  }
  onCancelTherapist() {
    this.isEditTherapist = false;
    this.getPatientDetails();
    this.getchmaclinicinDetails();
  }
  onEditFrequency() {
    this.isEditFrequency = true;
  }
  onCancelFrequency() {
    this.isEditFrequency = false;
    this.getPatientDetails();
  }

  postfrmPsychiatrist(frmpys: NgForm) {
    let dtoobj = {
      FirstName: this.dtoChmaproviderPsychatric.FirstName,
      PhoneNumber: this.dtoChmaproviderPsychatric.PhoneNumber,
      PhysicianTypeId: 2,
      Pid: (this.dtoChmaproviderPsychatric.Pid==undefined || this.dtoChmaproviderPsychatric.Pid==null)?0:this.dtoChmaproviderPsychatric.Pid,
      PatientAccountId: this.patientAccountId
    }
    this.OnSaveProviderContactInfo(dtoobj,2) 
    }
  
  postfrmPhysician(frmpys: NgForm) {
    let dtoobj = {
      FirstName: this.dtoChmaproviderPhysian.FirstName,
      PhoneNumber: this.dtoChmaproviderPhysian.PhoneNumber,
      PhysicianTypeId: 1,
      Pid: (this.dtoChmaproviderPhysian.Pid==undefined || this.dtoChmaproviderPhysian.Pid==null)?0:this.dtoChmaproviderPhysian.Pid,
      PatientAccountId: this.patientAccountId
    }
    this.OnSaveProviderContactInfo(dtoobj,1);
  }
  postfrmTherapist(frmpys: NgForm) {
    let dtoobj = {
      FirstName: this.dtoChmaproviderTherapy.FirstName,
      PhoneNumber: this.dtoChmaproviderTherapy.PhoneNumber,
      PhysicianTypeId: 3,
      Pid: (this.dtoChmaproviderTherapy.Pid==undefined || this.dtoChmaproviderTherapy.Pid==null)?0:this.dtoChmaproviderTherapy.Pid,
      PatientAccountId: this.patientAccountId
    }
    this.OnSaveProviderContactInfo(dtoobj,3);
  }
  OnSaveProviderContactInfo(dtoObj,typeid)
  { this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(dtoObj, false);
    this.patientservice.postproviderinfo(dataToEncrypt).subscribe(
      (res: any) => {
        this.snackbar.success(res);
        this.spinnerservice.hide();
        this.isEditTherapist = false;
        if(typeid==1)
        {
        this.onCancelPhysican();
        }
       else if(typeid=2)
        {
        this.onCancelPsychiatrist();
        }
       else if(typeid=3)
        {
        this.onCancelTherapist();
        }

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );

  }
  onloadcityforProvider(id) {
      
    this.leadservice.getcities(id).then(
      res => {
        this.ddlCitiesLoad = this.commonService.decrypdata(res.cities);
        this.CityName=this.ddlCitiesProviderinfo.find(x=>x.CitiesId==this.lstPatientDetails[0].cityId).CityName;   
        this.lstPatientDetails[0].cityId = this.lstPatientDetails[0].cityId;     
           },
      error =>
        this.spinnerservice.hide()
    );
  }
  postfrmFrequncy(frmpys: NgForm) {
    if (this.dtofrequency == "" || this.dtofrequency == null) {
      this.isReqFrequency = true;
      return
    }
    let _obj = {
      Frequency: this.dtofrequency,
      PatientAccountId: this.patientAccountId
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.patientservice.postfrequncy(dataToEncrypt).subscribe(
      (res: any) => {
        this.snackbar.success(JSON.parse(res).message);
        this.spinnerservice.hide();
        this.onCancelFrequency();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onChangeProviderinfoCity(city)
  {
   let stId=this.ddlCitiesProviderinfo.find(x=>x.CitiesId==city.value).StatesId;
   this.ddlStates.filter((item) => item.StatesId == stId);
   
  this.lstPatientDetails[0].stateId=this.ddlStates.find(x=>x.StatesId==stId).StatesId;
  this.lstPatientDetails[0].cityId = city.value;
  }
  getddlStateMaster() {
    this.spinnerservice.show();
    this.leadservice.getstateMaster().subscribe(
      res => {
        if (res != null) {
          this.ddlStates= this.commonService.decrypdata(res.states);
          this.ddlgrade = this.commonService.decrypdata(res.grades);  
          this.ddlCitiesLoad = this.commonService.decrypdata(res.cities);  
    
          this.getPatientDetails();        
         }
         
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  editPatientDetailsClick(){
    this.isEditPatientDetails = true;
  }
  onSaveChmaStatus(type) {
    this.patientDetails.patientAccountId=+this.patientAccountId;
    this.patientDetails.firstName=this.lstPatientDetails[0].clientFirstName;
    this.patientDetails.lastName=this.lstPatientDetails[0].clientLastName;
    this.patientDetails.parentsFirstName=this.lstPatientDetails[0].parentFirstName;
    this.patientDetails.parentsLastName=this.lstPatientDetails[0].parentLastName;
    this.patientDetails.dateOfBirth=this.lstPatientDetails[0].dob;
    this.patientDetails.gradeLevel=this.lstPatientDetails[0].gradeLevel;
    this.patientDetails.school=this.lstPatientDetails[0].schoolName;
    this.patientDetails.stateId=this.lstPatientDetails[0].stateId;
    this.patientDetails.cityId=this.lstPatientDetails[0].cityId;
    this.patientDetails.zipCode=this.lstPatientDetails[0].zip;
    this.patientDetails.streetAddress=this.lstPatientDetails[0].streetAddress;
    
    let _obj = {
      PatientAccountId: this.patientAccountId,
      FormType: 1,
      patientdetails:this.patientDetails,
      Type : type
    }
    this.spinnerservice.hide();
    // const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.spinnerservice.hide();
    this.patientservice.postChmaStatus(_obj).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.chma.getChmaStatus();
        if(type == 1 || type == 2){
          window.close();
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  onnext(type) {
    // if (!this.isMovedChma) {
    //   this.onSaveChmaStatus(type);
    // }
    this.isPatientDetailsFormSubmitted=true;
    if(!this.frmPatientDetails.valid){
      return;
    }else{    
    this.onSaveChmaStatus(type);
    this.chma.onTabChange(2);
    }
  }
  onSaveChmaStatuswithClinicians(type) {
    this.dtoChmastatusWithClinicians.PatientAccountId=this.patientAccountId;
    this.dtoChmastatusWithClinicians.FormType=1;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt( this.dtoChmastatusWithClinicians, false);
    this.patientservice.postChmaStatusWithClinicans(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.chma.getChmaStatus();
        this.IsEditChmaClinicians = false;
        if(type == 1){
          window.close();
        }
        else{ 
          this.chma.onTabChange(2);
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
 psyc:any;
getchmaclinicinDetails(){
  this.spinnerservice.show();

  this.patientservice.getPatientchmaclinicians(this.patientAccountId).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if(res.objInputString!=null){
      this.dtoChmastatusWithClinicians = decrypt(res.objInputString);
     let alldatas= this.dtoChmastatusWithClinicians; 
    // console.log(this.dtoChmastatusWithClinicians);
    
this.dtoChmaproviderPsychatric=alldatas.filter(x=>x.PhysicianTypeId==2)[0];
this.dtoChmaproviderPhysian=alldatas.filter(x=>x.PhysicianTypeId==1)[0];
this.dtoChmaproviderTherapy=alldatas.filter(x=>x.PhysicianTypeId==3)[0];
this.dtoChmaproviderPsychatric=(this.dtoChmaproviderPsychatric==undefined)?this.dtoChmaproviderPsychatric=new DtoChmaproviderPsychatric():this.dtoChmaproviderPsychatric;
this.dtoChmaproviderPhysian=(this.dtoChmaproviderPhysian==undefined)?this.dtoChmaproviderPhysian=new DtoChmaproviderPhysian():this.dtoChmaproviderPhysian;
this.dtoChmaproviderTherapy=(this.dtoChmaproviderTherapy==undefined)?this.dtoChmaproviderTherapy=new DtoChmaproviderTherapy():this.dtoChmaproviderTherapy;
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
OnEditChmaClinicians(){
  this.IsEditChmaClinicians = true;
}



}

<div class="financial-page">
    <form id="financialfrm" (ngSubmit)="financialfrm.form.valid  && postFinancialProposal(financialfrm)"
        autocomplete="off" #financialfrm="ngForm" novalidate>
        <div class="top-btn-section clearfix">
            <h2 class="head-section mt-0 mb-2">Submit Financial Proposal</h2>
            <div class="col-md-4 ml-auto pr-0 mb-2">
                <span class=" float-right">
                 </span>

                  <button class="btn back-btn-new mr-2" (click)="onBack()"><i class="fas fa-chevron-left mr-2"></i>Back</button>

              </div>

        </div>
        <div class="lead-box mb-3 h-vh-75">
            <div class="clearfix mb-2">
                <div class="float-left w-100">
                    <button class="btn primary-btn-outlined cursor-pointer float-right" role="button" type="button"
                        *ngIf="isEditFinancial" (click)="openFinancialreview()"><i class="fas fa-edit mr-2"></i>Edit
                        Financial Proposal </button>
                </div>
            </div>
            <div class="col-md-12 price-category">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="choosing-label pt-3">
                                    Choose your Program
                                </label>
                            </div>
                            <div class="col-md-8">
                                <div class="">
                                    <div class="col-md-12 p-0">
                                        <mat-radio-group [(ngModel)]="dtoFinancial.programType" #programType="ngModel"
                                            name="programType" required>
                                            <mat-radio-button [value]='2' class="mr-2 my-3"
                                                (change)="onChangeProgramType(2)"> Regular
                                            </mat-radio-button>
                                            <mat-radio-button [value]='3' class="mr-2" (change)="onChangeProgramType(3)"> Outpatient
                                            </mat-radio-button>
                                            <mat-radio-button [value]='1'  class="mr-2" (change)="onChangeProgramType(1)"> Summer
                                                C.A.M.P
                                            </mat-radio-button>

                                        </mat-radio-group>
                                        <mat-error *ngIf="financialfrm.submitted && programType.invalid">
                                            <mat-error class="" *ngIf="programType.errors.required">Insurance
                                                Coverage is
                                                required
                                            </mat-error>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-5">
                                <label class="choosing-label pt-3">
                                    Choose your Price Catalog
                                </label>
                            </div>
                            <div class="col-md-7">
                                <div class="example-form">
                                    <mat-form-field class="example-full-width w-100">
                                        <mat-label>Price Catalog</mat-label>
                                        <mat-select [(ngModel)]="dtoFinancial.priceCatalogueId"
                                            (selectionChange)="onCatalougeChange($event)" #priceCatalogueId="ngModel"
                                            name="priceCatalogueId"
                                            [ngClass]="{ 'is-invalid': financialfrm.submitted && priceCatalogueId.invalid }"
                                            required> 
                                            <mat-option *ngFor="let item of ddlCatalouge"
                                                [value]="item.priceCatalogueId">
                                                {{item.catalogueName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="financialfrm.submitted && priceCatalogueId.invalid">
                                            <mat-error *ngIf="priceCatalogueId.errors.required">Price Catalog is
                                                required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="col-md-12 p-0">
                <div class="row">
                    <div class="col-md-12 component-list-view">
                        <label class="choosing-label col-md-12 p-0"
                            *ngIf="componentdetails && componentdetails?.length > 0">
                            Choose your Price Component
                        </label>
                        <div class="Catalogue-view-list list-template">
                            <ul class="col-md-12 nomargin">
                                <li *ngFor='let item of componentdetails;let inx=index'
                                    [ngClass]="{'active': rbnPriceComponent==item.priceComponentId }">
                                    <div class="card">
                                        <div class="card-body p-0">
                                            <div class="row">

                                                <div class="col-md-1 mt-4 pr-0 position-relative">
                                                    <div class="example-form selecting-comp">

                                                        <mat-radio-button [ngModelOptions]="{standalone: true}"
                                                            (click)="cbkrbnPriceComponent(item)" [checked]="inx == 0 "
                                                            [(ngModel)]="rbnPriceComponent"></mat-radio-button>

                                                    </div>
                                                    <div class=" insurance p-0 text-right"
                                                        *ngIf="item.isInsuranceCoverage">
                                                        <img src="../../../../../assets/images/shield.png">
                                                    </div>
                                                    <div class=" insurance p-0 text-right"
                                                        *ngIf="item.isInsuranceCoverage == false">
                                                        <img src="../../../../../assets/images/noshield.png">
                                                    </div>

                                                </div>
                                                

                                                <div class="col-md-10 pl-0">
                                                    <div class="py-4 position-relative">
                                                        <div class=" col-md-12">
                                                            <div class=" row" *ngFor="let data of item.componentDetails;let i=index">
                                                                <div class="col-md-3"
                                                                    >
                                                                    <!-- <div class="row"> -->
                                                                        <label class="list-head col-md-12  pr-2"
                                                                            >{{data.componentName| titlecase}}</label>
                                                                <!-- </div>
                                                                        <div class="col-md-4 m-0"> -->
                                                                            <mat-form-field
                                                                                class="w-100 custom-multiselect">
                                                                                <mat-label>Select {{data.componentName}}</mat-label>
                                                                                <mat-select name="pricing{{i}}{{inx}}"
                                                                                    [disabled]="rbnPriceComponent!=item.priceComponentId"
                                                                                    #pricing="ngModel" ngModel
                                                                                    required
                                                                                    (selectionChange)="onPriceValueChage(item,data,$event)">
                                                                                    <mat-option
                                                                                        *ngFor="let a of data.pricing"
                                                                                        [value]="a"> $
                                                                                        {{a.priceValue}}
                                                                                        <span *ngIf="a.payFrequency!=1"> ,{{a.coInsuranceVal}}</span>

                                                                                    </mat-option>
                                                                                </mat-select>
                                                                            </mat-form-field>
                                                                            
                                                                            
                                                                            <mat-error
                                                                                *ngIf="financialfrm.submitted && pricing.invalid">
                                                                                <mat-error
                                                                                    class="mt-1 mat-form-field-subscript-wrapper"
                                                                                    *ngIf="pricing.errors?.required">
                                                                                    {{data.componentName}} is required.</mat-error>
                                                                            </mat-error>
                                                                            </div>
                                                                                <div class="deduct py-4 position-relative col-md-3" *ngIf="item.isInsuranceCoverage && data.componentName == 'Session Fee' ">
                                                                                   
                                                                                    
                                                                                            <!-- <label class="small-heading-label align-middle w-100 mb-0">Deductible</label> -->
                                                
                                                                                            <mat-form-field class="w-100" >
                                                                                                <!-- <mat-label>{{ deductibleVal}}</mat-label> -->
                                                                                                <mat-label>Deductible Type</mat-label>
                                                                                                <mat-select [(ngModel)]="data.deductibleType" #deductibleType="ngModel" name="deductibleType{{i}}" 
                                                                                                    [ngClass]="{ 'is-invalid': financialfrm.submitted && deductibleType.invalid }" (selectionChange)="onChangedeductible($event,i)"
                                                                                                    >
                                                                                                    
                                                                                                    <ng-container>
                                                                                                    <mat-option
                                                                                                        *ngFor="let anth of ddlType"
                                                                                                        [value]="anth.deductibleId">
                                                                                                        {{anth.deductible1}}
                                                                                                    </mat-option>
                                                                                                    </ng-container>
                                                                                                </mat-select>
                                                                                                <mat-error *ngIf="financialfrm.submitted && deductibleType.invalid">
                                                                                                    <mat-error *ngIf="deductibleType.errors.required">Deductible type is required</mat-error>
                                                                                                </mat-error>
                                                                                            </mat-form-field>
                                                                                        </div>
                                                                                        <div class="col-md-3" *ngIf="item.isInsuranceCoverage && data.componentName == 'Session Fee' ">
                                                                                            <label class="small-heading-label align-middle w-100 mb-0"></label>
                                                                                            <mat-form-field class="w-100 ">
                                                                                                <input matInput placeholder="Comments" text="{{data.deductibleComments}}" value="" [(ngModel)]="data.deductibleComments" #deductibleComments="ngModel" name="deductibleComments{{i}}" maxlength="300"
                                                                                                (blur)="onInputChange(data.deductibleComments,i)">
                                                                                              </mat-form-field>
                                                                                        </div>
                                                                        </div>
                                                                        
                                                            </div>
                                                        </div>
                                                    <!-- </div> -->
                                                </div>
                                              


                                            </div>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 text-right mt-2 mb-2">
                    <button type="submit" [disabled]="rbnPriceComponent == undefined" mat-stroked-button
                        class="btn primary-btn-filled primary-btn-s  h-40 mt-2">Submit
                        Fee</button>

                </div>
            </div>

        </div>

    </form>
</div>

<div class="modal finance-modal" [style.display]="isFinancialReview ? 'block' : 'none'">
    <div class="modal-dialog modal-notify modal-info" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead m-0">Financial Proposal</p>

                <button type="button" class="close" data-dismiss="modal" (click)="closeFinReview()" aria-label="Close">
                    <span aria-hidden="true" class="white-text">×</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <form id="financialReviewfrm"
                    (ngSubmit)="financialReviewfrm.form.valid  && postFinancialReview(financialReviewfrm)"
                    autocomplete="off" #financialReviewfrm="ngForm" novalidate>
                    <div class="form">

                        <p>Hello <b>{{parentName}},</b></p>
                        <p> I was able to take a look at your benefits and the following will be your financial responsibility for the program: </p>
                        <div class="col-md-12 pricelistview">
                            <div class="row" *ngFor="let data of objReview?.componentdetails;let i=index">
                                <!-- <div class="col-md-12">
                                    <label class="heading-price-list">{{data.componentName}}</label>
                                    <label> </label>
                                </div> -->
                                <div class="col-md-12">
                                    <ng-container *ngFor="let pr of data.pricing;let j=index">
                                        <div class="row mb-2">
                                            <div class="col-md-8">
                                                <!-- <label> if co-insurance {{pr.coInsuranceVal}}</label> -->
                                                <label class="heading-price-list">{{data.componentName}} <span *ngIf="pr.payFrequency!=1"> ({{pr.coInsuranceVal}})</span></label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text" id="btnGroupAddon">$</div>
                                                    </div>

                                                    <input matInput maxlength="30" [(ngModel)]="pr.priceValue"
                                                        class="form-control" [ngModelOptions]="{ standalone : true }"
                                                        maxlength="8" numeric decimals="2" #intakeFee="ngModel"
                                                        name="intakeFee{{j}}" id="intakeFee{{j}}"
                                                        required> 
                                                </div>
                                                <mat-error *ngIf="financialReviewfrm.submitted && intakeFee.invalid">
                                                    <mat-error *ngIf="intakeFee.errors.required">{{data.componentName}}
                                                        Fee
                                                        is required
                                                    </mat-error>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="row mt-2" *ngIf="objReview?.isInsuranceCoverage && data.componentName == 'Session Fee' ">
                                
                                            <div class="col-md-8" >
                                                <!-- <label> Deductible <span
                                                        class="insu-name">{{objReview.deductibleVal}}</span></label> -->
                                                        <label class="heading-price-list"> Deductible </label>
                                            </div>
                                          
                                            <div class="col-md-4">
                                                <mat-select [(ngModel)]="data.deductibleTypeId" #deductibleTypeId="ngModel" aria-required="false"
                                                    name="deductibleTypeId{{i}}"  id="deductibleTypeId{{i}}"(selectionChange)="onChangedeductible($event,i)" >
                                                    <mat-option *ngFor="let anth of ddlType" [value]="anth.deductibleId">
                                                        {{anth.deductible1}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="financialReviewfrm.submitted && deductibleTypeId.invalid">
                                                    <mat-error *ngIf="deductibleTypeId.errors.required">Deductible Type is required
                                                    </mat-error>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="row mt-2 mb-2" *ngIf="objReview?.isInsuranceCoverage && data.componentName == 'Session Fee' ">
                                
                                            <div class="col-md-8" >
                                                <!-- <label> Deductible <span
                                                        class="insu-name">{{objReview.deductibleVal}}</span></label> -->
                                                        <label class="heading-price-list"> Comments </label>
                                            </div>
                                          
                                            <div class="col-md-4">
                                                    <input matInput  class="form-control" id="deductibleComments{{i}}" text="{{data.deductibleComments}}" value="" [(ngModel)]="data.deductibleComments" #deductibleComments="ngModel" name="deductibleComments{{i}}"
                                                     maxlength="300" >
                                                 
                                                <mat-error *ngIf="financialReviewfrm.submitted && deductibleComments.invalid">
                                                    <mat-error *ngIf="deductibleComments.errors.required">Comments is required
                                                    </mat-error>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>
                               
                          
                           
                                <div class="col-md-8" *ngIf="objReview.deductibleTypeId == 1">
                                    <label>You are also responsible for all amounts applied toward  <span
                                            class="insu-name">{{objReview.deductibleActVal}}.</span></label>
                                </div>
                                <div class="col-md-8" *ngIf="objReview.deductibleTypeId == 2">
                                    <label>You are also responsible for all amounts applied toward your <span
                                        class="insu-name">{{objReview.deductibleActVal}} deductible.</span>
                                        These financials are contingent on getting a single-case agreement put in place with
                                        your insurance. We will reach out to your insurance to start the process after the intake. </label>
                                </div>
                                <div class="col-md-8" *ngIf="objReview.deductibleTypeId == 3">
                                    <label>You are also responsible for all amounts applied towards your <span
                                        class="insu-name">{{objReview.deductibleActVal}} deductible.</span>  </label>
                                </div>
                            </div>

                        </div>
                        <p>

                        </p>
                        <p>Please review our financial responsibilities video on our website to understand the insurance process.<a target="_blank" href="https://www.steppingforwardcounselingcenter.com/fees/"> www.steppingforwardcounselingcenter.com/fees/</a></p>
                        <p>Please let me know if you have any questions and if you would like to move forward with scheduling an Intake appointment. </p>
                        <p>Best, </p>
                        <div class="col-md-12 p-0">
                            <div class="row">
                                <div class="col-md-6 text-left">
                                    <p class="m-0">{{objlocation?.locationName}}</p>
                                    <p *ngIf ="objlocation?.locationType == 1"   class="m-0"> {{objlocation?.address2}}</p>
                                    <p *ngIf ="objlocation?.locationType != 1" class="m-0">{{objlocation?.address1}}, {{objlocation?.address2}}</p>
                                    <p *ngIf ="objlocation?.locationType != 1" class="m-0">{{objlocation?.cityName}}, {{objlocation?.stateName}} {{objlocation?.zipCode != 0 ?objlocation?.zipCode : '' }}</p>
                                </div>
                                <div class="col-md-6 text-right">
                                    Date: {{currentdate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="submit" form="financialReviewfrm" class="btn primary-btn-filled">Submit</button>
                <button class="btn btn-cancel" (click)="closeFinReview()">Cancel</button>
            </div>

        </div>
        <!--/.Content-->
    </div>
</div>

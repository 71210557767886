import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class ClinicianService {

  constructor(private http: HttpClient, public commonService: CommonService) { }
  // getPatientAppointments() {
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetPatientAppointments');
  // }
  getPatientAppointments(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetClinician/GetPatientAppointments',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getGrpTherapyAppointments(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetClinician/GetGrpTherapyAppointments',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) {
           return response; }));
  }
  GetGrpTherapyAppointmentsForAdmin(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetClinician/GetGrpTherapyAppointmentsForAdmin',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) {
           return response; }));
  }
  
  getClinicianAppointments(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetClinician/GetClinicianAppointments',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  acceptMeeting(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/AcceptMeeting',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
    //return this.http.get<any>(environment.BaseURI + 'gateway/PostClinician/AcceptMeeting?id=' + this.commonService.DataToEncrypt(id, true));
  }
  // getTimeSlots( day) {
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetSlotByAppointment?id=' + this.commonService.DataToEncrypt(day, true));
  // }
  cancelMeeting(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/CancelIntake',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getTimeSlots(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetClinician/GetSlotByAppointment',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  reScheduleMeeting(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/ReScheduleAppointMents',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getAssignedSlots(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/GetAssignedSlots',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  // getGroupTherapyDetails(id,date) {
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetGrpTherapyDetails?id=' + this.commonService.DataToEncrypt(id, true)+ '&encdate=' + this.commonService.DataToEncrypt(date, true) );
  // }
  getGroupTherapyDetails(id,date,clinicianid) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetGrpTherapyDetails?id=' + this.commonService.DataToEncrypt(id, true)+ '&encdate=' + this.commonService.DataToEncrypt(date, true) + '&clinicianId=' + this.commonService.DataToEncrypt(clinicianid, true) );
  }
  getSubGroupTherapyDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetSubGrpTherapyDetails?id=' + this.commonService.DataToEncrypt(id, true) );
  }
  getProgressActivityDetails(item) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetProgressActivityDetails?id=' + this.commonService.DataToEncrypt(item.patientAccountId, true) + '&therapyid=' + this.commonService.DataToEncrypt(item.groupTherapyId, true)+ '&entypeId=' + this.commonService.DataToEncrypt(item.dayScheduleSubGroupId, true));
  }
  getProgressPDF(item,date) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetProgressNotePdf?id=' + this.commonService.DataToEncrypt(item.patientAccountId, true) + '&therapyid=' + this.commonService.DataToEncrypt(item.groupTherapyId, true)+'&meetingscheduletimeId=' + this.commonService.DataToEncrypt(item.dayScheduleSubGroupId, true)+'&therapyDate=' + this.commonService.DataToEncrypt(date, true));
  }
  postprogressActivity(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostProgressActivity',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getProgressPDFForDocuments(item,date) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetProgressNotePdf?id=' + this.commonService.DataToEncrypt(item.PatientAccountId, true) + '&therapyid=' + this.commonService.DataToEncrypt(item.GroupTherapyId, true)+'&meetingscheduletimeId=' + this.commonService.DataToEncrypt(item.DayScheduleSubGroupId, true)+'&therapyDate=' + this.commonService.DataToEncrypt(date, true));
  }
  getSafetyContractPdf(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetSafetyContractPdf?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getSuicideAssesmentPdf(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetSuicideAssesmentTabPdf?id=' + this.commonService.DataToEncrypt(id, true));
  }
  postProgressNote(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostProgressNotes',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postProgressStaffInitial(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostProgressStaffInitial',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getProgressNote(enPatAcntId, enthrpyId, entypeId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetProgressNotes?enPatAcntId=' + this.commonService.DataToEncrypt(enPatAcntId, true) + '&enthrpyId=' + this.commonService.DataToEncrypt(enthrpyId, true) + '&entypeId=' + this.commonService.DataToEncrypt(entypeId, true));
  }
  deleteMediciation(id,dayScheduleSubGroupId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostTreatment/RemoveMedication?Id=' + this.commonService.DataToEncrypt(id, true)+'&entypeId=' + this.commonService.DataToEncrypt(dayScheduleSubGroupId, true));
  }
  postProgressMedication(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostMedication',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getprogressmedicine(id,dayScheduleSubGroupId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetProgressMedicine?id=' + this.commonService.DataToEncrypt(id, true)+'&entypeId=' + this.commonService.DataToEncrypt(dayScheduleSubGroupId, true));
  }
  getPsychotropicMedication(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetPsychotropicMedication?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getIndvFamTherphyPtInfo(id,theraphyId,selectedDate,secondaryId)
  {
    //return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/getIndvFamTherphyPtInfo?id=' + this.commonService.DataToEncrypt(id, true)+'&therapyId=' + this.commonService.DataToEncrypt(theraphyId, true));
     return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/getIndvFamTherphyPtInfo?id=' + this.commonService.DataToEncrypt(id, true)+'&therapyId=' + this.commonService.DataToEncrypt(theraphyId, true)+'&msScheduleddate=' + this.commonService.DataToEncrypt(selectedDate, true) + '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
  }

  postindfamTheraphyOutcomeNotes(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/postindfamTpyOutcomeNotes',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getPatientMedication(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetPatientMedication?id='+ this.commonService.DataToEncrypt(id, true));
  }
  GetTreatmentGoalForTherapy(id,therapyId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetTreatmentGoalForTherapy?id=' + this.commonService.DataToEncrypt(id, true)+'&therapyId='+this.commonService.DataToEncrypt(therapyId,true));
  }
  postKpiGoalStatus(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/postKpiGoalStatus',data,{ headers: headers, responseType: 'text' });
  }
  postKpiGoalComments(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/postKpiGoalComments',data,{ headers: headers, responseType: 'text' });
  }
  getProgressActivityDetailsForTherapy(item) {
 //   console.log(item);
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetProgressActivityDetailsForTherapy?id=' + this.commonService.DataToEncrypt(item.patientAccountId, true) + '&therapyid=' + this.commonService.DataToEncrypt(item.groupTherapyId, true)
    + '&entypeId=' + this.commonService.DataToEncrypt(item.dayScheduleSubGroupId, true)+'&therapyTypeId='+this.commonService.DataToEncrypt(item.therapyType, true)+'&therapyDate='+this.commonService.DataToEncrypt(item.therapyDate, true));
  }
  getProgressActivityDetailsForTherapyWithTotal(item) {
       return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetProgressActivityDetailsForTherapyWithTotal?id=' + this.commonService.DataToEncrypt(item.patientAccountId, true) + '&therapyid=' + this.commonService.DataToEncrypt(item.groupTherapyId, true)
    + '&entypeId=' + this.commonService.DataToEncrypt(item.dayScheduleSubGroupId, true)+'&therapyTypeId='+this.commonService.DataToEncrypt(item.therapyType, true)+'&therapyDate='+this.commonService.DataToEncrypt(item.therapyDate, true));
  }
  getIndvFamgoals(id,theraphyId,meetingscheduletimeid,selectedDate)
  {
    //return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/getIndvFamTherphyPtInfo?id=' + this.commonService.DataToEncrypt(id, true)+'&therapyId=' + this.commonService.DataToEncrypt(theraphyId, true));
     return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetINDFamGoalswithBenchmark?id=' + this.commonService.DataToEncrypt(id, true)+'&therapyTypeId=' + this.commonService.DataToEncrypt(theraphyId, true)+'&meetingscheduletimeId=' + this.commonService.DataToEncrypt(meetingscheduletimeid, true)+'&therapyDate=' + this.commonService.DataToEncrypt(selectedDate, true));
  }

  postIndFamgoals(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostGoalsForFamIndTheraphy',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
  }
  PostGroupTherapyBenchmark(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostGoalsForGroupTheraphy',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
  }
  getpsychaiatricpatientinfo(id,theraphyId,selectedDate,secondaryId)
  {
    //return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/getIndvFamTherphyPtInfo?id=' + this.commonService.DataToEncrypt(id, true)+'&therapyId=' + this.commonService.DataToEncrypt(theraphyId, true));
     return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetPsychiatricPatientinfo?id=' + this.commonService.DataToEncrypt(id, true)+'&therapyId=' + this.commonService.DataToEncrypt(theraphyId, true)+'&msScheduleddate=' + this.commonService.DataToEncrypt(selectedDate, true) + '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true)
);
  }
  PostPsyTherapyNotes(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostPsytherapyNotes',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  getpsychaiatricMedpatientinfo(id,theraphyId,selectedDate,secondaryId)
  {
    //return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/getIndvFamTherphyPtInfo?id=' + this.commonService.DataToEncrypt(id, true)+'&therapyId=' + this.commonService.DataToEncrypt(theraphyId, true));
     return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetPsychiatricMedPatientinfo?id=' + this.commonService.DataToEncrypt(id, true)+'&therapyId=' + this.commonService.DataToEncrypt(theraphyId, true)+'&msScheduleddate=' + this.commonService.DataToEncrypt(selectedDate, true)+ '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
  }
  PostPsyMedNotes(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostPsyMedNotes',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  removePsyMed(id) {
    console.log(id)
    return this.http.get<any>(environment.BaseURI + 'gateway/PostClinician/RemovePsyMed?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getpsychaiatricEvapatientinfo(id,theraphyId,selectedDate)
  {
     return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetPsychiatricEvaPatientinfo?id=' + this.commonService.DataToEncrypt(id, true)+'&therapyId=' + this.commonService.DataToEncrypt(theraphyId, true)+'&msScheduleddate=' + this.commonService.DataToEncrypt(selectedDate, true));
  }

  getAuditListClinician(selectedLocation,isClinician) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetClinicianAuditList?selectedLocation=' + this.commonService.DataToEncrypt(selectedLocation, true)+'&isClinician=' + this.commonService.DataToEncrypt(isClinician, true));
  }
  
  getAuditDetailsClinician(id,isClinician) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetAuditDetailsClinician?id=' + this.commonService.DataToEncrypt(id, true)+'&isClinician=' + this.commonService.DataToEncrypt(isClinician, true));
  }
  PostPsyEvaNotes(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostPsyEvaNotes',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  getpsychaiatricEvaMentalstatus(id,mstimeid,theraphyId,secondaryId)
  {
     return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetPsychiatricEvaMentalStatus?id=' + this.commonService.DataToEncrypt(id, true)+'&meetingscheduletimeid=' + this.commonService.DataToEncrypt(mstimeid, true)+'&therapyid=' + this.commonService.DataToEncrypt(theraphyId, true)+ '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
  }
  PostPsyEvaMentalStatus(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostPsyEvaMentalstatus',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  PostDischargeSummary(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/postDischargeSummary',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getprogressmedicineForDS(id,dayScheduleSubGroupId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetProgressMedicineForDischarge?id=' + this.commonService.DataToEncrypt(id, true)+'&entypeId=' + this.commonService.DataToEncrypt(dayScheduleSubGroupId, true));
  }
  removeDischargeMedication(id) {
    console.log(id)
    return this.http.get<any>(environment.BaseURI + 'gateway/PostClinician/RemoveDischargeMedication?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getTreatmentSummarybyId(id,secondaryId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetTreatmentSummary?id=' + this.commonService.DataToEncrypt(id, true)+ '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
  }
  PostTreatmentSummary(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostTreatmentSummary',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  removePsyEvaMed(id) {
      return this.http.get<any>(environment.BaseURI + 'gateway/PostClinician/RemovePsyEvaMed?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getGrpTherapyPatientAppointmentLists(id,date,clinicianid) {
      return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetGrpTherapyPatientAppointmentLists?id=' + this.commonService.DataToEncrypt(id, true)+ '&encdate=' + this.commonService.DataToEncrypt(date, true) + '&clinicianId=' + this.commonService.DataToEncrypt(clinicianid, true) );
      
    }
    getschedulerappointmentsForAdmin(data) {
      const headers = new HttpHeaders().set(
        'Content-Type',
        'application/json; charset=utf-8'
      );
      return this.http.post(environment.BaseURI + 'gateway/GetClinician/GetSchedulerAppointmentsForAdmin',
        data, { headers: headers, responseType: 'text' })
        .pipe(map(function (response) {
             return response; }));
    }
    

    getphoneschoolmeetpatientinfo(id,theraphyId,selectedDate,secondaryId)
    {
       return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetPhoneSchoolPatientInfo?id=' + this.commonService.DataToEncrypt(id, true)+'&therapyId=' + this.commonService.DataToEncrypt(theraphyId, true)+'&msScheduleddate=' + this.commonService.DataToEncrypt(selectedDate, true)+ '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
    }


    PostPhoneSchoolNotes(data) {
      const headers = new HttpHeaders().set(
        'Content-Type',
        'application/json; charset=utf-8'
      );
      return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostPhoneSchoolNotes',
        data, { headers: headers, responseType: 'text' })
        .pipe(map(function (response) { return response; }));
    }
    getIndFamProgressNotespdf(msid,mstid,patientAccountId) {
      return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetIndFamProgressNotesPdf?meetingscheduleId=' + this.commonService.DataToEncrypt(msid, true) + '&meetingscheduletimeid=' + this.commonService.DataToEncrypt(mstid, true) + '&patientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true));
    }
    getPsychiatrictherapynotespdf(msid,mstid,patientAccountId) {
      return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetPsychiatricTherapypdf?meetingscheduleId=' + this.commonService.DataToEncrypt(msid, true) + '&meetingscheduletimeid=' + this.commonService.DataToEncrypt(mstid, true) + '&patientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true));
    }
    getPsychiatricmedicationpdf(msid,mstid,patientAccountId) {
      return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetPsychiatricMedicationpdf?meetingscheduleId=' + this.commonService.DataToEncrypt(msid, true) + '&meetingscheduletimeid=' + this.commonService.DataToEncrypt(mstid, true) + '&patientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true));
    }

    getPsychiatricEvaluationFormpdf(msid,mstid,patientAccountId) {
      return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetPsychiatricEvaluationNotespdf?meetingscheduleId=' + this.commonService.DataToEncrypt(msid, true) + '&meetingscheduletimeid=' + this.commonService.DataToEncrypt(mstid, true) + '&patientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true));
    }
    getPsychiatricEvaluationMSpdf(msid,mstid,patientAccountId) {
      return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetPsychiatricEvaluationMSpdf?meetingscheduleId=' + this.commonService.DataToEncrypt(msid, true) + '&meetingscheduletimeid=' + this.commonService.DataToEncrypt(mstid, true) + '&patientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true));
    }
    getCommunicationformpdf(msid,mstid,patientAccountId) {
      return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetCommunicationFormPdf?meetingscheduleId=' + this.commonService.DataToEncrypt(msid, true) + '&meetingscheduletimeid=' + this.commonService.DataToEncrypt(mstid, true) + '&patientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true));
    }
    getallCurrentMedication(id) {
      return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetPatientCurrentMedList?id=' + this.commonService.DataToEncrypt(id, true));
    }
    getGroupTheraphyProgressNotes(dayScheduleGroupTherapyId,patientAccountId,secondaryId) {
      return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetGroupTheraphyProgressNotes?DayScheduleGroupTherapyId=' + this.commonService.DataToEncrypt(dayScheduleGroupTherapyId, true)+'&PatientAccountId='+ this.commonService.DataToEncrypt(patientAccountId, true) + '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
    }
    PostGroupTherapyProgressNoteDetails(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostGroupTherapyProgressNoteDetails',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  GetGroupTheraphyProgressNotes(id,patientId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetGroupTheraphyProgressNotes?groupTherapyId=' + this.commonService.DataToEncrypt(id, true)+'&patientId='+this.commonService.DataToEncrypt(patientId, true) );
  }
  VerifyIsClinician() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/VerifyIsClinician');
  }
  getCheckInDetails(dayScheduleSubTherapyid) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetClinicianCheckInDetails?DayScheduleSubTherapyid=' + this.commonService.DataToEncrypt(dayScheduleSubTherapyid, true));
  }
  PostCheckInDetails(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostCheckInDetails',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getAdminCheckInDetails(groupTherapyId,date) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetAdminCheckInDetails?groupTherapyId=' + this.commonService.DataToEncrypt(groupTherapyId, true)+'&encDate='+this.commonService.DataToEncrypt(date, true));
  }
  // GetSearchAppointmentsPatient(PatientAccountId) {
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetSearchAppointmentsPatient?PatientAccountId =' + this.commonService.DataToEncrypt(PatientAccountId, true));
  // }
  GetSearchAppointmentsPatient(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetClinician/GetSearchAppointmentsPatient',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getPatientListforDS(id,locationId,secondaryId){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetPatientListForDischarge?id='+this.commonService.DataToEncrypt(id, true)+'&locationId='+this.commonService.DataToEncrypt(locationId, true) +'&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
  }
  onGenerateSchedulerpopupPDF(PatientAccountId,appointmentTypes){
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/onGenerateSchedulerpopupPDF?PatientAccountId='+this.commonService.DataToEncrypt(PatientAccountId, true)
    + '&appointmentTypes=' +this.commonService.DataToEncrypt(appointmentTypes, true));

  }
  getClientName(id,locationId){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetClientNameForScheduler?id='+this.commonService.DataToEncrypt(id, true)+'&locationId='+this.commonService.DataToEncrypt(locationId, true));
  }
  getDailySchedudlepdf(grpTherapyId, scheduleDate, clinicianId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetDailySchedudlepdf?grpTherapyId=' + this.commonService.DataToEncrypt(grpTherapyId, true) + '&scheduleDate=' + this.commonService.DataToEncrypt(scheduleDate, true) + '&clinicianId=' + this.commonService.DataToEncrypt(clinicianId, true));
  }
  PostGroupTherapyPresentorAbsent(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostGroupTherapyPresentorAbsent',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  saveconsentMedication(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostMedicationconsentHistoryPdf',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getpdfdata(Meetingscheduleid){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/getpdfdata?Meetingscheduleid='+this.commonService.DataToEncrypt(Meetingscheduleid, true));

  }
  getpdfdataconsent(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/getpdfdata?Meetingscheduleid='+this.commonService.DataToEncrypt(id, true));
  }
  onGenerateMedicationPDFconsent(Meetingscheduleid){
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/onGenerateMedicationPDFconsent?Meetingscheduleid='+this.commonService.DataToEncrypt(Meetingscheduleid, true));

  }
  
  getpdfdatareview(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/getpdfdatareview?Meetingscheduleid='+this.commonService.DataToEncrypt(id, true));
  }
  saveReviewSheet(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostReviewSheet',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  onGenerateMedicationReviewSheet(Meetingscheduleid){
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/onGenerateMedicationReviewSheet?Meetingscheduleid='+this.commonService.DataToEncrypt(Meetingscheduleid, true));

  }
  getReviewPatientDob(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/getreviewpatientdob?Meetingscheduleid='+this.commonService.DataToEncrypt(id, true));
  }

  getPatientAndClinicainName(id,secondaryId)
  {
     return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetPatientAndClinicainName?Id=' + this.commonService.DataToEncrypt(id, true) + '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
  }
  
  PostPhoneSchoolNotesTreatment(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostPhoneSchoolNotesTreatment',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getCommunicationformTreatmentpdf(id,patientAccountId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetCommunicationFormTreatmentPdf?id=' + this.commonService.DataToEncrypt(id, true) + '&Pid=' + this.commonService.DataToEncrypt(patientAccountId, true));
  }

  getPhoneSchoolMeetProgressNotesTreatment(id)
  {
     return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetPhoneSchoolMeetProgressNotesTreatment?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  onDeletetreatmentnotes(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostTreatment/DeleteTreatmentNotes?id=' + id);
  }
  getDischargePatient(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostTreatment/GetDischargePatient?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  saveUpdateIntake(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/SaveUpdateIntake',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getSaveUpdateIntake(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/getUpdateIntake?PatientAccountId=' + this.commonService.DataToEncrypt(id, true));
  }
  getGenerateIntakePdf(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/onGenerateIntakePdf?PatientAccountId=' + this.commonService.DataToEncrypt(id, true));
  }
  getPendingDocuments() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetPendingDocuments');
  }

  getTreatmentNotesById(id)
  {
     return this.http.get<any>(environment.BaseURI + 'gateway/PostTreatment/GetTreatmentNotesById?Id=' + this.commonService.DataToEncrypt(id, true));
  }


  getSuicideAssesmentPdfHistory(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetSuicideAssesmentTabPdfHistory?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getSafetyContractPdfHistory(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetSafetyContractPdfHistory?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getIndFamProgressNotespdfReverse(msid,mstid,patientAccountId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetIndFamProgressNotespdfReverse?meetingscheduleId=' + this.commonService.DataToEncrypt(msid, true) + '&meetingscheduletimeid=' + this.commonService.DataToEncrypt(mstid, true) + '&patientAccountId=' + this.commonService.DataToEncrypt(patientAccountId, true));
  }
  PostGroupTherapyActivityDetails(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostClinician/PostGroupTherapyActivityDetails',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  removeConsentMedication(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostClinician/RemoveConsentMedication?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  // removeConsentMedication(id) {
  //   return this.http.get<any>(environment.BaseURI + 'gateway/PostClinician/RemoveConsentMedication?Id=' + this.commonService.DataToEncrypt(id, true));
  // }
}

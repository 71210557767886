export class notesinfo {
    notesId: number;
    patientInfoId: number;
    notesContent: string;
    tags: userstag;
    isClinical:boolean;
    subject: string;
}
export class userstag {
    notesTagUserId: number;
    notesId: number;
    patientInfoId: number;
    email: string;
    userName: string;

}

export class EditConfigFormName{
    ConfigureFormId: number;
    FormName: string;
}

export class Library {
    LibraryId: number;
    libraryName: string;

}
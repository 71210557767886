import { map } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { GoalMeasure, Goals } from 'src/app/_models/_Treatment/_treatmentgoals';
import { PatientService } from 'src/app/patient/patient.service';
import { SELECT_ITEM_HEIGHT_EM } from '@angular/material/select';
import { th } from 'date-fns/locale';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-treatmentgoals',
  templateUrl: './treatmentgoals.component.html',
  styleUrls: ['./treatmentgoals.component.scss']
})
export class TreatmentgoalsComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  items = ['Goal 1', 'Goal 2', 'Goal 3', 'Goal 4', 'Goal 5'];
  expandedIndex = 0;
  @Input() patientAccountId: number;
  @Input() patientInfoId: number;
  ddlstaff: any = [];
  _dtoGoals: Goals;
  _dtoGoalMeasure: any = [];
  dtogoals: any = [];

  chmaTreatmentSummaryId: number;
  isEditGoals: boolean = false;
  lstTreatmentGoals: any = [];
  isAddGoals: boolean = false;
  suggestedGoals: any = [];
  allGoals: any = [];
  isShowAllGoal: boolean = false;
  ddlGoalCatagory: any;
  isTreatmentGoalDisabled:boolean=false;
  roleId:number;
  roleAccess: RoelAccess;
  isAssign : boolean = false;
  applicableTrtMent : any;
  applicableTreatment1 :  any = [];
  goalitem : any;
  isApplicableError : boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  public searchpatientListtxt : string;
  public searchpatientListtext : string;
  DecrptedlstPatient: any = [];
  DecrptedlstPatients: any = [];
  constructor(public global: Global, public treatmentservice: TreatmentService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public patientservice: PatientService,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    this._dtoGoals = new Goals();
    this._dtoGoalMeasure = new GoalMeasure();

    //Subscribed to Behavior Subject to get the Goal list when an Update is made in Goal Config
    this.treatmentservice.getGoals.subscribe((res)=>
    this.getTreatmentGoalsList()
    );
    this.treatmentservice.getSuggestedGoals.subscribe((res)=>
    this.getTreatmentGoalsList()
    );
    const pageid = constantVariables.pagelists.TreatmentPlan;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);

    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.account).replace(/"/g, ""));
        this.chmaTreatmentSummaryId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patient).replace(/"/g, ""));
      }

      this.getTreatmentGoalsList();
      //this.getStaffList();
      this.roleId = Number(localStorage.getItem("roleId"));
      if(this.roleId==1){
        this.isTreatmentGoalDisabled=true;
      }
    });
  }
  private ensureArray(value: any): number[] {
    if (typeof value === 'string') {
      return value.split(',').map(Number);
    }
    return Array.isArray(value) ? value : [];
  }
  private ensureArrayTreatmentSelected(value: any): number[] {
    if (typeof value === 'string') {
      return value.split(',').map(Number);
    }
    return Array.isArray(value) ? value : [];
  }
  getStaffList() {
    this.treatmentservice.getUserList(0).subscribe(
      (res: any) => {
        if (res) {
          this.ddlstaff = res;

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getSuggestGoals() {
    // this.treatmentservice.GetSuggestGoals(this.chmaTreatmentSummaryId).subscribe(
    //   (res: any) => {
    //     if (res) {
    //       this.suggestedGoals = res;
    //       this.getAllGoals();
    //     }
    //   },
    //   err => {
    //     this.spinnerservice.hide();
    //   },
    // );
    this.treatmentservice.GetSuggestGoalsBasedOnTherapy(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        if (res) {
          this.suggestedGoals = res;
          this.DecrptedlstPatient = this.suggestedGoals;
       //  console.log(this.DecrptedlstPatient);
         this.suggestedGoals = this.DecrptedlstPatient.slice(0, 20);
        this.dataSource = new MatTableDataSource(this.DecrptedlstPatient);
        this.dataSource.paginator = this.paginator;

          this.suggestedGoals.map((x)=>{
            var newVar=x.applicableTreatment.split(',').map(Number);
            x.applicableTreatment=newVar
          })
          this.getAllGoals();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  //getSuggestGoals() {
  //  this.treatmentservice.GetSuggestGoalsBasedOnTherapy(this.chmaTreatmentSummaryId).subscribe(
  //    (res: any) => {
  //      if (res) {
  //        this.suggestedGoals = res;
  //        this.DecrptedlstPatient = this.suggestedGoals.slice(0, 20);

  //        this.DecrptedlstPatient.forEach((x) => {
  //          x.applicableTreatment = this.ensureArray(x.applicableTreatment);
  //        });

  //        this.dataSource = new MatTableDataSource(this.DecrptedlstPatient);
  //        this.dataSource.paginator = this.paginator;

  //        this.getAllGoals();

  //      }
  //    },
  //    err => {
  //      this.spinnerservice.hide();
  //    },
  //  );
  //}

  getAllGoals() {

    // this.treatmentservice.getGoalsMaster().subscribe(
    //   (res: any) => {
    //     if (res) {

    //       if(this.suggestedGoals.length !==0){
    //         let result = res.filter(o1 => this.suggestedGoals.some(o2 => o1.goalId !== o2.goalId));
    //         this.allGoals = result;
    //       }
    //       else{
    //         this.allGoals = res
    //       }

    //     }
    //   },
    //   err => {
    //     this.spinnerservice.hide();
    //   },
    // );
        this.treatmentservice.GetAllGoalsBasedOnTherapy(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        if (res) {
            this.allGoals = res
            this.DecrptedlstPatients = this.allGoals;
       //  console.log(this.DecrptedlstPatient);
         this.allGoals = this.DecrptedlstPatients.slice(0, 20);
        this.dataSource = new MatTableDataSource(this.DecrptedlstPatients);
        this.dataSource.paginator = this.paginator;
            this.DecrptedlstPatients.map((x)=>{
              var newVar=x.applicableTreatment.split(',').map(Number);
              x.applicableTreatment=newVar;
            })
        }
        this.getGoalMaster();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  // onGoalSelection(event) {
  //   this.treatmentservice.getGoalsbyId(Number(event.target.value), this.chmaTreatmentSummaryId).subscribe(
  //     (res: any) => {
  //       if (res) {
  //         this._dtoGoals = cloneDeep(res);
  //         this._dtoGoalMeasure = cloneDeep(res.measures);

  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );
  // }
  postTreatmentGoals(fromGoals: NgForm) {
    this.spinnerservice.show();
    this._dtoGoals.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;

    this._dtoGoals.measures = this._dtoGoalMeasure;
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoGoals, false);

    this.treatmentservice.postTreatmentGoals(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
         this.treatmentservice.TreatmentPlanStatus.next(false)
        this.getTreatmentGoalsList();
        this.snackbar.success(JSON.parse(res).message);
       
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  getTreatmentGoalsList() {
    this.treatmentservice.getTreatmentGoalsList(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        if (res) {
          this.lstTreatmentGoals = res; 
          this.lstTreatmentGoals.map((x)=>{
            var newVar=x.applicableTreatment.split(',').map(Number);
            x.applicableTreatment=newVar;
            var newVarSelected =x.applicableTreatmentSelected.split(',').map(Number);
            x.applicableTreatmentSelected = newVarSelected;
          })        
          this.treatmentservice.changeInGoalInformation.next(false);
          
          this.treatmentservice.PresentingProblem.next(false);
        }
        this.getSuggestGoals();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  //getTreatmentGoalsList() {
  //  this.treatmentservice.getTreatmentGoalsList(this.chmaTreatmentSummaryId).subscribe(
  //    (res: any) => {
  //      if (res) {
  //        this.lstTreatmentGoals = res;
  //        this.lstTreatmentGoals.forEach((x) => {
  //          x.applicableTreatment = this.ensureArray(x.applicableTreatment);
  //          x.applicableTreatmentSelected = this.ensureArrayTreatmentSelected(x.applicableTreatmentSelected);
  //        });

  //        this.treatmentservice.changeInGoalInformation.next(false);
  //        this.treatmentservice.PresentingProblem.next(false);
  //      }
  //      this.getSuggestGoals();
  //    },
  //    err => {
  //      this.spinnerservice.hide();
  //    },
  //  );
  //}

  ondeletegoals(item) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.Warning.removeConfirmMessage
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.treatmentservice.removeTreatmentGaols(item.goalId).subscribe(
          (res: any) => {
            this.spinnerservice.hide();
            this.getTreatmentGoalsList();

            this.treatmentservice.PresentingProblem.next(false);
            this.snackbar.success(constantVariables.SuccessMessage.goaldelete);
          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },
        );
      }
    });
  }

  getminScheduleDate(item, index) {
    var mindate;
    if (index == 0) {
      var a = moment()
      var b = moment(item.scheduleDate)
      mindate = moment.max(a, b).toDate();
    } else {
      mindate = moment(this._dtoGoalMeasure[index - 1].scheduleDate).add(1, 'days');
    }
    return mindate
  }
  handleSelectionChange(event)
  {
    var l=event;
  }
  assignGoal(item){
    this.isAssign =true;
    this.applicableTrtMent = item.applicableTreatment;
    this.goalitem = item;
  }
  OnCancel(){
    this.isAssign = false;
    this.applicableTrtMent  = null;
    this.goalitem = null;
    this.isApplicableError = false;
  }

  onGoalSelect(item) {

    if(this.applicableTreatment1.length == 0)
    {
      this.isApplicableError = true;
    }else{
      this.isApplicableError = false;
      this.isAssign = false;
      if(this.isTreatmentGoalDisabled){
        return
      }
      item.isSelected = !item.isSelected
      this.spinnerservice.show();
      this._dtoGoals.treatmentGoalsId = item.treatmentGoalsId;
      this._dtoGoals.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
      this._dtoGoals.goalId = item.goalId;
      this._dtoGoals.applicableTreatment = this.applicableTreatment1
      this._dtoGoals.measures = item.measures
      this._dtoGoals.measureTypeId = item.measureTypeId
      const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoGoals, false);
  
      this.treatmentservice.postTreatmentGoals(dataToEncrypt).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.OnCancel();
          this.treatmentservice.TreatmentPlanStatus.next(false)
          this.getTreatmentGoalsList();
          this.snackbar.success(JSON.parse(res).message);
        },
        err => {
          this.spinnerservice.hide();
          this.OnCancel();
          this.snackbar.error(err.error);
        },
      );
     // console.log(item)
    //  console.log(this._dtoGoals)
    }
  
  }
  getGoalinTreatment(item, type) {

    if (this.lstTreatmentGoals.filter(x => x.goalId == item.goalId).length == 0) {
      return true;
    } else {
      return false;
    }
  }
  noSuggestedgoal() {
    if (this.suggestedGoals.filter(o1 => this.lstTreatmentGoals.some(o2 => o1.goalId !== o2.goalId)).length == 0) {
      return true;
    } else {
      return false;
    }
  }

  noallgoal() {
    if (this.allGoals.filter(o1 => this.lstTreatmentGoals.some(o2 => o1.goalId !== o2.goalId)).length == 0) {
      return true;
    } else {
      return false;
    }
  }
getGoalMaster(){
  this.treatmentservice.getGoalsMasterTemplate().subscribe(
    res => {
      this.spinnerservice.hide();
      if (res != null) {
        this.ddlGoalCatagory = res.goalCatagory;
        this.suggestedGoals.forEach(x=>x.catagoryName=[])
        this.suggestedGoals.map(x=>{
          this.ddlGoalCatagory.forEach(y=>{
           if(x.goalCatagoryId.includes(y.goalCatagoryId)){
             x.catagoryName.push(y.catagoryName)
           }
          })
         });
         this.allGoals.forEach(x=>x.catagoryName=[])
         this.allGoals.map(x=>{
           this.ddlGoalCatagory.forEach(y=>{
            if(x.goalCatagoryId.includes(y.goalCatagoryId)){
              x.catagoryName.push(y.catagoryName)
            }
           })
          });

      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
OnEditApplicaleTreatment(itemVal){
  this.isAssign = true;
  this.applicableTrtMent = itemVal.applicableTreatment;
  this.applicableTreatment1 = itemVal.applicableTreatmentSelected;
  this.goalitem = itemVal;
}
generatePdf()
{
  this.spinnerservice.show();
  this.commonService.getGoalPdf(this.chmaTreatmentSummaryId).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if (res != null) {
        const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
OnSearchPatient(){
  let tempPatientlist = this.DecrptedlstPatient
  if(this.searchpatientListtxt !="" || this.searchpatientListtxt === undefined || this.searchpatientListtxt === null){
    this.suggestedGoals = tempPatientlist.filter(
      (x: any) =>
    x.goalName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
  }
  else{
    this.suggestedGoals = tempPatientlist.slice(0, 5);
    this.getSuggestGoals();
  }
}
onPageChange($event) {
  this.suggestedGoals = this.DecrptedlstPatient.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
}
OnSearchPatientAll(){
  let tempPatientlists = this.DecrptedlstPatients
  if(this.searchpatientListtext !="" || this.searchpatientListtext === undefined || this.searchpatientListtext === null){
    this.allGoals = tempPatientlists.filter(
      (x: any) =>
    x.goalName.toLowerCase().includes(this.searchpatientListtext.toLowerCase()));
  }
  else{
   this.allGoals = tempPatientlists.slice(0, 5);
   this.getAllGoals();
  }
}
onPageChanges($event) {
  this.allGoals = this.DecrptedlstPatients.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
}
}

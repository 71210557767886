import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(DiagnosisCode: any, term: any): any {

    //check if search term is undefined
    if(term === undefined) return DiagnosisCode;
    //return updates people array
    return DiagnosisCode.filter(function(element){
        return element.label.toLowerCase().includes(term.toLowerCase());
    }) 

  }

}
<div class="suicide-risk">
    <div  class="top-btn-section clearfix mb-2">
      <h2  class="head-section mb-2 mt-2 ">Suicidal Risk Assessment </h2>
      <div  class="col-md-5 ml-auto pr-0 text-right">
        <button  class="btn back-btn-new float-none mr-2" (click)="OnBack()">
          <i class="fas fa-chevron-left mr-2"> 

        </i>Back</button>
       
        <button  class="btn back-btn-new mr-2 float-none" *ngIf="!IsSuiceScreenerDocument" (click)="SucidePdf(0)"><i  class="far fa-file-pdf mr-2"></i>Generate PDF</button>
        <button  class="btn back-btn-new mr-2 float-none" (click)="SuicdeAssessmentlogs()"><i class="fas fa-list-ul mr-2"></i>Assessment logs</button>
        <button  class="btn back-btn-new mr-2 float-none"  (click)="OnScreenerDocument()" 
        *ngIf="IsSuiceScreenerDocument && roleAccess.isView"><i class="fas fa-file-alt mr-2" ></i>Screener Documents</button>
        <span class="" *ngIf="!IsSuiceScreenerDocument">
          <a title="PatientInformation" role="button" class="export-pdf-btn btn float-none" 
          (click)="SucidePdf(1)"><i
                  class="fas fa-print"></i>
          </a>
      </span>
      </div>
    </div>
    <div class="top-btn-section clearfix">
      <div class="d-flex met-info-top w-100">
          <i class="fas fa-user"></i>
          <div class="mr-4">Client Name 
            <span class="imp-info">{{_dtoPatientInfo?.patientName }}
  
          </span></div>
          <div  class="mr-4"> DOB
            <span class="imp-info">{{_dtoPatientInfo?.dob | date:'MM/dd/YYYY'}}</span>
          </div>
          <div  class="mr-4"> Age  <span class="imp-info"> {{_dtoPatientInfo?.age}} Yrs</span></div>
           <div  class="mr-4"> Updated On  <span class="imp-info" >{{_dtoPatientInfo?.updatedDate | date:'MM/dd/YYYY'}}</span></div> 
          
          </div>
  </div>
    <div class="risks-tabs">
      <mat-tab-group (selectedTabChange)="OnTabChange($event)">
        <mat-tab label="Suicide Assessment">
          <form (ngSubmit)="SuicidalAssessmentFrm.form.valid" id="SuicidalAssessmentFrm" autocomplete="off" #SuicidalAssessmentFrm="ngForm"
          novalidate>
          <div class="bg-white p-3 col-md-12">
            <div class="s1 mb-4">
            <div class="custom-head mt-3 mb-3"><h2 style="font-weight: 600;">Step 1: Identify Risk Factors </h2></div>
            
            <div class="safe-head col-md-12 ">
              <div class="row">
                  <div class="col-md-8 pt-2">
                   <div class="safe-t-label">C-SSCS Suicidal Ideation Severity</div> 
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="safe-t-label pl-0">Month</div> 
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="safe-t-label pl-0">Lifetime(Worst)</div> 
                  </div>
              </div>
          </div>
            <div class="safe-t col-md-12 safe-border ">
                <div class="row">
                    <div class="col-md-8 ">
                      <div class="safe-t-label-bold">1) Wish to be dead</div>
                     <div class="safe-t-label text-muted">Have you wished you were dead or wished you could go to sleep and not wake up?</div> 
                    </div>
                    <div class="col-md-2">
                      <!-- <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.wishTodeadMonth" #wishTodeadMonth="ngModel" name="wishTodeadMonth"> </label></div> -->
                      <div class="check-custom lower-risk-box larger-checkbox-center risk-custom-check pt-2">
                        <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.wishTodeadMonth" #wishTodeadMonth="ngModel" name="wishTodeadMonth"></mat-checkbox>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <!-- <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.wishTodeadLifetime" #wishTodeadLifetime="ngModel" name="wishTodeadLifetime"> </label></div> -->
                      <div class="check-custom lower-risk-box larger-checkbox-center risk-custom-check pt-2">
                        <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.wishTodeadLifetime" #wishTodeadLifetime="ngModel" name="wishTodeadLifetime"></mat-checkbox>
                      </div>
                    </div>
                </div>
            </div>
            <div class="safe-t col-md-12 safe-border">
              <div class="row">
                  <div class="col-md-8 ">
                    <div class="safe-t-label-bold">2) Current suicidal thoughts</div>
                   <div class="safe-t-label text-muted">Have you actually had any thoughts of killing yourself?</div> 
                  </div>
                  <div class="col-md-2">
                    <!-- <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.currentSuithoughtsMonth" #currentSuithoughtsMonth="ngModel" name="currentSuithoughtsMonth"> </label></div> -->
                    <div class="check-custom lower-risk-box larger-checkbox-center risk-custom-check pt-2">
                      <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.currentSuithoughtsMonth" #currentSuithoughtsMonth="ngModel" name="currentSuithoughtsMonth"></mat-checkbox>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <!-- <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.currentSuithoughtsLifetime" #currentSuithoughtsLifetime="ngModel" name="currentSuithoughtsLifetime"> </label></div> -->
                    <div class="check-custom lower-risk-box larger-checkbox-center risk-custom-check pt-2">
                      <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.currentSuithoughtsLifetime" #currentSuithoughtsLifetime="ngModel" name="currentSuithoughtsLifetime"></mat-checkbox>
                    </div>
                  </div>
              </div>
          </div>
          <div class="safe-t col-md-12 safe-border">
            <div class="row">
                <div class="col-md-8 ">
                  <div class="safe-t-label-bold">3) Suicidal thoughts w/ Method<span class="safe-t-label text-muted">(w/no specific Plan or Intent to Act)</span> </div>
                 <div class="safe-t-label text-muted">Have you been thinking about how you might kill yourself?</div> 
                </div>
                <div class="col-md-2">
                  <!-- <div class="moderate-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.thoughtsWithMethodMonth" #thoughtsWithMethodMonth="ngModel" name="thoughtsWithMethodMonth"> </label></div> -->
                  <div class="check-custom moderate-risk-box larger-checkbox-center risk-custom-check pt-2">
                    <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.thoughtsWithMethodMonth" #thoughtsWithMethodMonth="ngModel" name="thoughtsWithMethodMonth"></mat-checkbox>
                  </div>
                </div>
                <div class="col-md-2">
                  <!-- <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.thoughtsWithMethodLifeTime" #thoughtsWithMethodLifeTime="ngModel" name="thoughtsWithMethodLifeTime"> </label></div> -->
                  <div class="check-custom lower-risk-box larger-checkbox-center risk-custom-check pt-2">
                    <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.thoughtsWithMethodLifeTime" #thoughtsWithMethodLifeTime="ngModel" name="thoughtsWithMethodLifeTime"></mat-checkbox>
                  </div>
                </div>
            </div>
        </div>
        <div class="safe-t col-md-12 safe-border">
          <div class="row">
              <div class="col-md-8 ">
                <div class="safe-t-label-bold">4) Suicidal Intent without Specific Plan</div>
               <div class="safe-t-label text-muted">Have you had these thoughts and had some intention of acting on them?</div> 
              </div>
              <div class="col-md-2">
                <!-- <div class="higher-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox"  [(ngModel)]="dtosuicidalIdeationSeverity.intentWithoutPlanMonth" #intentWithoutPlanMonth="ngModel" name="intentWithoutPlanMonth"> </label></div> -->
                <div class="check-custom higher-risk-box larger-checkbox-center risk-custom-check pt-2">
                  <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.intentWithoutPlanMonth" #intentWithoutPlanMonth="ngModel" name="intentWithoutPlanMonth"></mat-checkbox>
                </div>
              </div>
              <div class="col-md-2">
                <!-- <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox"  [(ngModel)]="dtosuicidalIdeationSeverity.intentWithoutPlanLifeTime" #intentWithoutPlanLifeTime="ngModel" name="intentWithoutPlanLifeTime"> </label></div> -->
                <div class="check-custom lower-risk-box larger-checkbox-center risk-custom-check pt-2">
                  <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.intentWithoutPlanLifeTime" #intentWithoutPlanLifeTime="ngModel" name="intentWithoutPlanLifeTime"></mat-checkbox>
                </div>
              </div>
          </div>
      </div>
      <div class="safe-t col-md-12 safe-border ">
        <div class="row">
            <div class="col-md-8 ">
              <div class="safe-t-label-bold">5) Intent with Plan</div>
             <div class="safe-t-label text-muted">Have you started to work out or worked out the details of how to kill yourself? Did you intend to carry out this plan?</div> 
            </div>
            <div class="col-md-2">
              <!-- <div class="higher-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.intentwithPlanMonth" #intentwithPlanMonth="ngModel" name="intentwithPlanMonth"> </label></div> -->
              <div class="check-custom higher-risk-box larger-checkbox-center risk-custom-check pt-2">
                <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.intentwithPlanMonth" #intentwithPlanMonth="ngModel" name="intentwithPlanMonth"></mat-checkbox>
              </div>
            </div>
            <div class="col-md-2">
              <!-- <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.intentwithPlanMonthLifeTime" #intentwithPlanMonthLifeTime="ngModel" name="intentwithPlanMonthLifeTime"> </label></div> -->
              <div class="check-custom lower-risk-box larger-checkbox-center risk-custom-check pt-2">
                <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.intentwithPlanMonthLifeTime" #intentwithPlanMonthLifeTime="ngModel" name="intentwithPlanMonthLifeTime"></mat-checkbox>
              </div>
            </div>
        </div>
    </div>
    <div class="safe-head col-md-12 ">
      <div class="row">
          <div class="col-md-8 pt-2">
           <div class="safe-t-label">C-SSRS Suicidal Behavior</div> 
          </div>
          <div class="col-md-2 text-center">
            <div class="safe-t-label pl-0">3 Months</div> 
          </div>
          <div class="col-md-2 text-center">
            <div class="safe-t-label pl-0">Lifetime(Worst)</div> 
          </div>
      </div>
  </div>
            <div class="safe-t col-md-12 p-2">
                <div class="row">
                    <div class="col-md-8 ">
                     <div class="safe-t-label p-0">"Have you ever done anything, started to do anything, or prepared to do anything to end your life?"</div> 
                     <div class="col-md-12 p-0 mt-2">
                      <div class="Theme-textarea">
                       <p class="safe-t-label text-muted pl-0" >Examples: Collected pills, obtained a gun, gave away valuables, wrote a will or suicide note, took out pills but didn’t swallow any, held a gun but changed your mind or it was grabbed from your hand, went to the roof but didn’t jump; or actually took pills, tried to shoot yourself, cut yourself, tried to hang yourself, etc.</p>
                        <!-- <textarea cols="4"  class="form-control" placeholder="" ></textarea> -->
                      </div>
                    </div>
                    </div>
                    <div class="col-md-2">
                      <!-- <div class="higher-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.preparedForSuicideMonth" #preparedForSuicideMonth="ngModel" name="preparedForSuicideMonth"> </label></div> -->
                     <div class="check-custom higher-risk-box larger-checkbox-center risk-custom-check pt-2">
                      <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="dtosuicidalIdeationSeverity.preparedForSuicideMonth" #preparedForSuicideMonth="ngModel" name="preparedForSuicideMonth" ></mat-checkbox>
                    </div>
                    </div>
                    <div class="col-md-2">
                      <!-- <div class="moderate-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.preparedForSuicideLifeTime" #preparedForSuicideLifeTime="ngModel" name="preparedForSuicideLifeTime"> </label></div> -->
                      <div class="check-custom moderate-risk-box larger-checkbox-center risk-custom-check pt-2">
                        <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.preparedForSuicideLifeTime" #preparedForSuicideLifeTime="ngModel" name="preparedForSuicideLifeTime"></mat-checkbox>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-4">
  
              <div class="safe-t-label p-0 mb-3 font-weight-bold text-dark col-md-12">Activating Events</div>
              <div class="col-md-12 p-0 ">
                <div class="row">
               <!-- <div class="col-md-6"> <label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Recent losses or other significant negative event(s) (legal, financial, relationship, etc.)</label></div>
               <div class="col-md-6"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Pending incarceration or homelessness </label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Current or pending isolation or feeling alone </label></div> 
                <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Others</label> -->
                  <div class="check-custom check-fields-large-label text-transform-unset col-md-6" *ngFor="let item of _dtoPatientInfo.activiatingEvent; let i=index;">
                    <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="AE{{i}}id" (change)="OnCheckBoxClick($event,1)">{{item.suicidalRiskCl}}</mat-checkbox>
                  </div>
                  <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && !Assessmentgiven">
                    <mat-error class="text-xs" *ngIf="!Assessmentgiven">
                     Assessment is required</mat-error>
                </mat-error> -->
                  <!-- <div class="Theme-textarea  d-none">
                       
                    <textarea cols="4" class="form-control" ></textarea>
                  </div> 
                </div>-->
                
              </div>
              <div class="Theme-textarea col-md-6" *ngIf="_dtoPatientInfo.activiatingEvent[_dtoPatientInfo.activiatingEvent?.length-1].isChecked==true">                     
                <textarea cols="4" class="form-control" [(ngModel)]="dtosuicideAssessment.activatingEventsOhers" #activatingEventsOhers="ngModel" name="activatingEventsOhers" [ngClass]="{ 'is-invalid': SuicidalAssessmentFrm.submitted && activatingEventsOhers.invalid }" required ></textarea>
                <mat-error *ngIf="SuicidalAssessmentFrm.submitted && activatingEventsOhers.invalid">
                  <mat-error class="text-xs" *ngIf="activatingEventsOhers.invalid">
                   This field is required</mat-error>
              </mat-error>
              </div>
                </div>
            </div>
            <div class="col-md-12 mt-3 ">
  
              <div class="safe-t-label p-0 mb-3 font-weight-bold text-dark col-md-12">Treatment History</div>
              <div class="col-md-12 p-0 ">
                <div class="row">
               <!-- <div class="col-md-6"> <label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" > Previous psychiatric diagnosis and treatments</label></div>
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" >Hopeless or dissatisfied with treatment </label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Non-compliant with treatment </label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Not receiving treatment </label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Insomnia </label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Others</label>
                <div class="Theme-textarea  d-none">
                     
                  <textarea cols="4" class="form-control" ></textarea>
                </div>
              </div> -->
              <div class="check-custom check-fields-large-label text-transform-unset col-md-6" *ngFor="let item of _dtoPatientInfo.treatmentHistory; let i=index;">
                <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="TH{{i}}id" (change)="OnCheckBoxClick($event,2)">{{item.suicidalRiskCl}}</mat-checkbox>
              </div>
            </div>
            <div class="Theme-textarea col-md-6"  *ngIf="_dtoPatientInfo.treatmentHistory[_dtoPatientInfo.treatmentHistory?.length-1].isChecked==true">                     
              <textarea cols="4" class="form-control" [(ngModel)]="dtosuicideAssessment.treatmentHistryOther" #treatmentHistryOther="ngModel" name="treatmentHistryOther" [ngClass]="{ 'is-invalid': SuicidalAssessmentFrm.submitted && treatmentHistryOther.invalid }" required ></textarea>
              <mat-error *ngIf="SuicidalAssessmentFrm.submitted && treatmentHistryOther.invalid">
                <mat-error class="text-xs" *ngIf="treatmentHistryOther.invalid">
                 This field is required</mat-error>
            </mat-error>
            </div>
                </div>
            </div>
            <div class="col-md-12 mt-3">
  
              <div class="safe-t-label p-0 mb-3 font-weight-bold text-dark col-md-12">Clinical Status</div>
              <div class="col-md-12 p-0 ">
                <div class="row">
               <!-- <div class="col-md-6"> <label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox">Hopelessness</label></div>
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" >Major depressive episode</label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Mixed affect episode (e.g. Bipolar)</label></div>
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Command Hallucinations to hurt self</label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Chronic physical pain or other acute medical problem (e.g. CNS disorders)</label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Highly impulsive behavior </label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Substance abuse or dependence </label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Agitation or severe anxiety </label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Perceived burden on family or others</label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Homicidal Ideation</label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Aggressive behavior towards others</label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox"  >Refuses or feels unable to agree to safety plan</label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Sexual abuse (lifetime)</label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox"  >Family history of suicide</label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Others</label>
                <div class="Theme-textarea  d-none">
                     
                  <textarea cols="4" class="form-control" ></textarea>
                </div>
              </div> -->
              <div class="check-custom check-fields-large-label text-transform-unset col-md-6" *ngFor="let item of _dtoPatientInfo.clinicalStatus; let i=index;">
                <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="CS{{i}}id" (change)="OnCheckBoxClick($event,3)">{{item.suicidalRiskCl}}</mat-checkbox>
              </div>
              </div>
              <div class="Theme-textarea col-md-6" *ngIf="_dtoPatientInfo.clinicalStatus[_dtoPatientInfo.clinicalStatus?.length-1].isChecked==true">                     
                <textarea cols="4" class="form-control" [(ngModel)]="dtosuicideAssessment.clinicalStatusOhers" #clinicalStatusOhers="ngModel" name="clinicalStatusOhers" [ngClass]="{ 'is-invalid': SuicidalAssessmentFrm.submitted && clinicalStatusOhers.invalid }" required ></textarea>
                <mat-error *ngIf="SuicidalAssessmentFrm.submitted && clinicalStatusOhers.invalid">
                  <mat-error class="text-xs" *ngIf="clinicalStatusOhers.invalid">
                   This field is required</mat-error>
              </mat-error>
              </div>
                </div>
            </div>
            <div class="col-md-12 check-custom check-fields-large-label text-transform-unset mt-4">
              <mat-checkbox class="mr-2"   [(ngModel)]="dtosuicideAssessment.accessToLethalMethods" #accessToLethalMethods="ngModel" name="accessToLethalMethods">Access to lethal methods:<span class ="safe-t-label text-muted">Ask specifically about presence or absence of a firearm in the home or workplace or ease of accessing</span></mat-checkbox>
              
            </div>
          </div>
          <div class="s2 mb-4">
            <div class="custom-head mt-3 mb-3"><h2  style="font-weight: 600;">Step 2: Identify Protective Factors (Protective factors may not counteract significant acute suicide risk factors)</h2></div>
            <div class="col-md-12 mt-3 ">
  
              <div class="safe-t-label p-0 mb-3 font-weight-bold text-dark col-md-12">Internal</div>
              <div class="col-md-12 p-0 ">
                <div class="row">
               <!-- <div class="col-md-6"> <label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox">Fear of death or dying due to pain and suffering</label></div>
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" >Identifies reasons for living</label></div> 
               
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Others</label>
                <div class="Theme-textarea  d-none">
                     
                  <textarea cols="4" class="form-control" ></textarea>
                </div>
              </div> -->
              <div class=" check-custom check-fields-large-label text-transform-unset col-md-6" *ngFor="let item of _dtoPatientInfo.internal; let i=index;">
                <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="I{{i}}id" (change)="OnCheckBoxClick($event,4)">{{item.suicidalRiskCl}}</mat-checkbox>
              </div>             
            </div>
            <div class="Theme-textarea col-md-6" *ngIf="_dtoPatientInfo.internal[_dtoPatientInfo.internal?.length-1].isChecked==true">                     
              <textarea cols="4" class="form-control" [(ngModel)]="dtosuicideAssessment.internalOther" #internalOther="ngModel" name="internalOther"  [ngClass]="{ 'is-invalid': SuicidalAssessmentFrm.submitted && internalOther.invalid }" required></textarea>
              <mat-error *ngIf="SuicidalAssessmentFrm.submitted && internalOther.invalid">
                <mat-error class="text-xs" *ngIf="internalOther.invalid">
                 This field is required</mat-error>
            </mat-error>
            </div>
                </div>
            </div>
            <div class="col-md-12 mt-3">
  
              <div class="safe-t-label p-0 mb-3 font-weight-bold text-dark col-md-12">External</div>
              <div class="col-md-12 p-0 ">
                <div class="row">
               <!-- <div class="col-md-6"> <label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox">Belief that suicide is immoral; high spirituality</label></div>
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" >Responsibility to family or others; living with family</label></div> 
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Supportive social network of family or friends</label></div>
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Engaged in work or school</label></div> 
              
               <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Others</label>
                <div class="Theme-textarea  d-none">
                     
                  <textarea cols="4" class="form-control" ></textarea>
                </div>
              </div> -->
              <div class=" check-custom check-fields-large-label text-transform-unset col-md-6" *ngFor="let item of _dtoPatientInfo.external; let i=index;">
                <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="E{{i}}id" (change)="OnCheckBoxClick($event,5)">{{item.suicidalRiskCl}}</mat-checkbox>
              </div>
              </div>
              <div class="Theme-textarea col-md-6" *ngIf="_dtoPatientInfo.external[_dtoPatientInfo.external?.length-1].isChecked==true">                     
                <textarea cols="4" class="form-control" [(ngModel)]="dtosuicideAssessment.externalOther" #externalOther="ngModel" name="externalOther" [ngClass]="{ 'is-invalid': SuicidalAssessmentFrm.submitted && externalOther.invalid }" required></textarea>
                <mat-error *ngIf="SuicidalAssessmentFrm.submitted && externalOther.invalid">
                  <mat-error class="text-xs" *ngIf="externalOther.invalid">
                   This field is required</mat-error>
              </mat-error>
              </div>
                </div>
            </div>
          </div>
          <div class="s3 mb-4">
            <div class="custom-head mt-3 mb-3"><h2 style="font-weight: 600;">Step 3: Specific questioning about Thoughts, Plans, and Suicidal Intent – (see Step 1 for Ideation Severity and Behavior)</h2></div>
            
            <div class="safe-head col-md-12 ">
              <div class="row">
                  <div class="col-md-8 pt-2">
                   <div class="safe-t-label">C-SSRS Suicidal Ideation Intensity (with respect to the most severe ideation identified above)</div> 
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="safe-t-label pl-0">Month</div> 
                  </div>
                  <div class="col-md-2 text-center">
                    <div class="safe-t-label pl-0">Lifetime(Worst)</div> 
                  </div>
              </div>
          </div>
            <div class="safe-t col-md-12 ">
                <div class="row">
                    <div class="col-md-8 pt-2">
                      <div class="safe-t-label-bold">Frequency</div>
                     <div class="safe-t-label text-muted p-0">How many times have you had these thoughts? </div>
                     <div class="d-flex flex-wrap mt-3">
                      
                        <!-- <div class="mr-2 font-15"><a  class="number-selct mr-1">1</a>
                          Less than once a week
                        </div>
                        <div class="mr-2 font-15"><a  class="number-selct mr-1">2</a>
                          Once a week
                          </div>
                          
                          <div class="mr-2 font-15"><a  class="number-selct mr-1">3</a>
                            2-5 times in week
                            </div>
                            <div class="mr-2 font-15"><a  class="number-selct mr-1">4</a>
                         Daily or almost daily
                         </div>
                         <div class="mr-2 font-15"><a  class="number-selct mr-1">5</a>
                        Many times each day
                        </div> -->
                  
                        <div class="mr-2 font-15" *ngFor="let item of _dtoPatientInfo.frequency;let i=index;"><a  class="number-selct mr-1" (click)="OnIdeationIntensity(item)">{{item.orderSeq}}</a>
                         {{item.suicidalRiskCl}}
                          </div>
                    </div>
                    </div>
                    <div class="col-md-2 text-center d-flex align-items-center border">
                      <div class="value-number-selct w-100">
                       <span>{{dtosuicidalIdeationSeverity.howManyTimesMonth}}</span>                       
                         <mat-error *ngIf="SuicidalAssessmentFrm.submitted && dtosuicidalIdeationSeverity.howManyTimesMonth == undefined "> 
                         <mat-error class="text-xs" *ngIf="dtosuicidalIdeationSeverity.howManyTimesMonth == undefined">
                          *required</mat-error>
                       </mat-error>   
                      </div>
                     </div>
                    <div class="col-md-2 text-center d-flex align-items-center border">                     
                        <!-- <div ><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.howManyTimesLifeTime" #howManyTimesLifeTime="ngModel" name="howManyTimesLifeTime"> </label></div> -->
                        <!-- <div class="check-custom  larger-checkbox-center risk-custom-check pt-2 w-100">
                           <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.howManyTimesLifeTime" #howManyTimesLifeTime="ngModel" name="howManyTimesLifeTime"></mat-checkbox> 
                        </div> -->
                        <div _ngcontent-qvs-c396="" class=" pt-2 w-100 text-center">
                          <input class="form-control w-25 mx-auto" numeric name="quantity" step="0" maxlength="1"  [(ngModel)]="dtosuicidalIdeationSeverity.howManyTimesLifeTime" #howManyTimesLifeTime="ngModel" name="howManyTimesLifeTime" (keypress)="OnchangeIdeationLifeTime($event,1)"/>
                          <mat-error *ngIf="SuicidalAssessmentFrm.submitted && dtosuicidalIdeationSeverity.howManyTimesLifeTime == undefined "> 
                            <mat-error class="text-xs" *ngIf="dtosuicidalIdeationSeverity.howManyTimesLifeTime == undefined">
                             *required</mat-error>
                          </mat-error>  
                        </div>
                      </div>
                </div>
            </div>
            <div class="safe-t col-md-12 odd-bg pt-0 pb-0">
              <div class="row">
                  <div class="col-md-8 pt-2">
                    <div class="safe-t-label-bold">Duration</div>
                   <div class="safe-t-label text-muted p-0">When you have the thoughts how long do they last?</div>
                   <div class="col-md-12 mt-3 p-0">
                    <!-- <div class="row">
                    <div class="col-md-6 font-15"><a  class="number-selct mr-1">1</a>
                      Fleeting - few seconds or minutes
                    </div>
                    <div class="col-md-6 font-15"><a  class="number-selct mr-1">2</a>
                      Less than 1 hour/some of the time
                      </div>
                      </div>
                      <div class="row">
                      <div class="col-md-6 font-15"><a  class="number-selct mr-1">3</a>
                        1-4 hours/a lot of time
                        </div>
                        <div class="col-md-6 font-15"><a  class="number-selct mr-1">4</a>
                          4-8 hours/most of day
                     </div>
                    </div>
                    <div class="row">
                     <div class="col-md-6 font-15"><a  class="number-selct mr-1">5</a>
                      More than 8 hours/persistent or continuous
                    </div>
                  </div> -->
                 <div class="w-100 d-flex" *ngFor="let item of _dtoPatientInfo.duration;let i=index;">
                  <div class="mr-2 font-15"><a  class="number-selct mr-1" (click)="OnIdeationIntensity(item)">{{item.orderSeq}}</a>
                    {{item.suicidalRiskCl}}
                     </div>
                 </div>
                
                </div>
                   
                  </div>
                  <div class="col-md-2 text-center d-flex align-items-center border">
                    <div class="value-number-selct w-100">
                     <span>{{dtosuicidalIdeationSeverity.howLongLastMonth}}</span>
                     <mat-error *ngIf="SuicidalAssessmentFrm.submitted && dtosuicidalIdeationSeverity.howLongLastMonth == undefined "> 
                      <mat-error class="text-xs" *ngIf="dtosuicidalIdeationSeverity.howLongLastMonth == undefined">
                       *required</mat-error>
                    </mat-error> 
                    </div>                                             
                   </div>
                  
                  <div class="col-md-2 text-center d-flex align-items-center border">
                    <!-- <div ><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.howLongLastLifeTime" #howLongLastLifeTime="ngModel" name="howLongLastLifeTime"> </label></div> -->
                    <!-- <div class="check-custom  larger-checkbox-center risk-custom-check pt-2 w-100">
                      <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.howLongLastLifeTime" #howLongLastLifeTime="ngModel" name="howLongLastLifeTime"></mat-checkbox>
                    </div> -->
                    <div _ngcontent-qvs-c396="" class=" pt-2 w-100 text-center">
                      <input  class="form-control w-25 mx-auto" numeric name="quantity" step="0" maxlength="1"   [(ngModel)]="dtosuicidalIdeationSeverity.howLongLastLifeTime" #howLongLastLifeTime="ngModel" name="howLongLastLifeTime" (keypress)="OnchangeIdeationLifeTime($event,2)"/>
                      <mat-error *ngIf="SuicidalAssessmentFrm.submitted && dtosuicidalIdeationSeverity.howLongLastLifeTime == undefined "> 
                        <mat-error class="text-xs" *ngIf="dtosuicidalIdeationSeverity.howLongLastLifeTime == undefined">
                         *required</mat-error>
                      </mat-error> 
                    </div>
                  </div>
              </div>
          </div>
          <div class="safe-t col-md-12 ">
            <div class="row">
                <div class="col-md-8 pt-2">
                  <div class="safe-t-label-bold">Controllability</div>
                 <div class="safe-t-label text-muted p-0">Could/can you stop thinking about killing yourself or wanting to die if you want to? </div>
                 <div class="col-md-12 mt-3 p-0">
                  <!-- <div class="row">
                    <div class=" col-md-6 font-15"><a class="number-selct mr-1">1</a>
                      Easily able to control thoughts
                    </div>
                    <div class=" col-md-6 font-15"><a  class="number-selct mr-1">2</a>
                      Can control thoughts with little difficulty
                      </div>
                  </div>
                  <div class="row">
                    <div class="  col-md-6 font-15"><a class="number-selct mr-1">3</a>
                      Can control thoughts with some difficulty
                      </div>
                      <div class=" col-md-6 font-15"><a  class="number-selct mr-1">4</a>
                        Can control thoughts  with a lot of difficulty
                   </div>
                  </div>
                    <div class="row">
                    
                   <div class=" col-md-6 font-15"><a  class="number-selct mr-1">5</a>
                    Unable to control thoughts
                  </div>
                  <div class=" col-md-6 font-15"><a  class="number-selct mr-1">0</a>
                    Does not attempt to control thoughts
                  </div>
                  </div> -->
                  <div class="w-100 d-flex" *ngFor="let item of _dtoPatientInfo.controllability;let i=index;">
                    <div class="mr-2 font-15"><a  class="number-selct mr-1" (click)="OnIdeationIntensity(item)"> {{item.orderSeq}}</a>
                      {{item.suicidalRiskCl}}
                       </div>
                   </div>
              </div>
                  
                </div>
                <div class="col-md-2 text-center d-flex align-items-center border">
                  <div class="value-number-selct w-100">
                   <span>{{dtosuicidalIdeationSeverity.controllabilityMonth}}</span>
                   <mat-error *ngIf="SuicidalAssessmentFrm.submitted && dtosuicidalIdeationSeverity.controllabilityMonth == undefined "> 
                    <mat-error class="text-xs" *ngIf="dtosuicidalIdeationSeverity.controllabilityMonth == undefined">
                     *required</mat-error>
                  </mat-error> 
                   
                  </div>
                 </div>
                <div class="col-md-2 text-center d-flex align-items-center border">
                  <!-- <div ><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.controllabilityLifeTime" #controllabilityLifeTime="ngModel" name="controllabilityLifeTime"> </label></div> -->
                  <!-- <div class="check-custom  larger-checkbox-center risk-custom-check pt-2 w-100">
                    <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.controllabilityLifeTime" #controllabilityLifeTime="ngModel" name="controllabilityLifeTime"></mat-checkbox>
                  </div> -->
                  <div _ngcontent-qvs-c396="" class=" pt-2 w-100 text-center">
                    <input  class="form-control w-25 mx-auto" numeric name="quantity" step="0" maxlength="1"  [(ngModel)]="dtosuicidalIdeationSeverity.controllabilityLifeTime" #controllabilityLifeTime="ngModel" name="controllabilityLifeTime" (keypress)="OnchangeIdeationLifeTime($event,3)"/>
                    <mat-error *ngIf="SuicidalAssessmentFrm.submitted && dtosuicidalIdeationSeverity.controllabilityLifeTime == undefined "> 
                      <mat-error class="text-xs" *ngIf="dtosuicidalIdeationSeverity.controllabilityLifeTime == undefined">
                       *required</mat-error>
                    </mat-error> 
                  </div>
                </div>
            </div>
        </div>
        <div class="safe-t col-md-12 odd-bg pt-0 pb-0">
          <div class="row">
              <div class="col-md-8 pt-2">
                <div class="safe-t-label-bold">Deterrents</div>
               <div class="safe-t-label text-muted p-0">Are there things - anyone or anything (e.g., family, religion, pain of death) - that stopped you from wanting to die or acting on thoughts of committing suicide? </div>
              <div class="col-md-12 mt-3 p-0">
                <!-- <div class="row">
                  <div class=" col-md-6 font-15"><a  class="number-selct mr-1">1</a>
                    Deterrents definitely stopped you from attempting suicide 
                  </div>
                  <div class=" col-md-6 font-15"><a  class="number-selct mr-1">2</a>
                    Deterrents probably stopped you
                    </div>
                </div>
                <div class="row">
                  <div class="  col-md-6 font-15"><a  class="number-selct mr-1">3</a>
                    Uncertain that deterrents stopped you
                    </div>
                    <div class=" col-md-6 font-15"><a  class="number-selct mr-1">4</a>
                      Deterrents most likely did not stop you
                 </div>
                </div>
                  <div class="row">
                  
                 <div class=" col-md-6 font-15"><a  class="number-selct mr-1">5</a>
                  Deterrents definitely did not stop you
                </div>
                <div class=" col-md-6 font-15"><a class="number-selct mr-1">0</a>
                  Does not apply
                </div>
                </div> -->
                <div class="w-100 d-flex" *ngFor="let item of _dtoPatientInfo.deterrents;let i=index;">
                  <div class="mr-2 font-15"><a  class="number-selct mr-1" (click)="OnIdeationIntensity(item)">{{item.orderSeq}}</a>
                    {{item.suicidalRiskCl}}
                     </div>
                 </div>
            </div>
               
              </div>
              <div class="col-md-2 text-center d-flex align-items-center border">
                <div class="value-number-selct w-100">
                 <span>{{dtosuicidalIdeationSeverity.deterrentsMonth }}</span>
                 <mat-error *ngIf="SuicidalAssessmentFrm.submitted && dtosuicidalIdeationSeverity.deterrentsMonth == undefined "> 
                  <mat-error class="text-xs" *ngIf="dtosuicidalIdeationSeverity.deterrentsMonth == undefined">
                   *required</mat-error>
                  </mat-error>
                </div>
               </div>
              <div class="col-md-2 text-center d-flex align-items-center border">
                <!-- <div ><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.deterrentsLifeTime" #deterrentsLifeTime="ngModel" name="deterrentsLifeTime"> </label></div> -->
                <!-- <div class="check-custom  larger-checkbox-center risk-custom-check pt-2 w-100">
                  <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.deterrentsLifeTime" #deterrentsLifeTime="ngModel" name="deterrentsLifeTime"></mat-checkbox>
                </div> -->
                <div _ngcontent-qvs-c396="" class=" pt-2 w-100 text-center">
                  <input  class="form-control w-25 mx-auto" numeric name="quantity" step="0" maxlength="1" [(ngModel)]="dtosuicidalIdeationSeverity.deterrentsLifeTime" #deterrentsLifeTime="ngModel" name="deterrentsLifeTime" (keypress)="OnchangeIdeationLifeTime($event,4)"/>
                  <mat-error *ngIf="SuicidalAssessmentFrm.submitted && dtosuicidalIdeationSeverity.deterrentsLifeTime == undefined "> 
                    <mat-error class="text-xs" *ngIf="dtosuicidalIdeationSeverity.deterrentsLifeTime == undefined">
                     *required</mat-error>
                  </mat-error> 
                </div>
              </div>
          </div>
      </div>
      <div class="safe-t col-md-12 ">
        <div class="row">
            <div class="col-md-8 pt-2">
              <div class="safe-t-label-bold">Reasons for Ideation</div>
             <div class="safe-t-label text-muted p-0">What sort of reasons did you have for thinking about wanting to die or killing yourself?  Was it to end the pain or stop the way you were feeling (in other words you couldn’t go on living with this pain or how you were feeling) or was it to get attention, revenge or a reaction from others? Or both?</div>
             <div class="col-md-12 mt-3 p-0">
              <!-- <div class="row">
                <div class=" col-md-12 font-15"><a  class="number-selct mr-1">1</a>
                  Completely to get attention, revenge or a reaction from others 
                </div>
                <div class=" col-md-12 font-15"><a class="number-selct mr-1">2</a>
                  Mostly to get attention, revenge or a reaction from others
                  </div>
              </div>
              <div class="row">
                <div class="  col-md-12 font-15"><a  class="number-selct mr-1">3</a>
                  Equally to get attention, revenge or a reaction from others and to end/stop the pain
                  </div>
                  <div class=" col-md-12 font-15"><a  class="number-selct mr-1">4</a>
                    Mostly to end or stop the pain (you couldn’t go on living with the pain or how you were feeling)
               </div>
              </div>
                <div class="row">
                
               <div class=" col-md-12 font-15"><a class="number-selct mr-1">5</a>
                Completely to end or stop the pain (you couldn’t go on living with the pain or  how you were feeling)
              </div>
              <div class=" col-md-12 font-15"><a  class="number-selct mr-1">0</a>
                Does not apply
              </div>
              </div> -->
              <div class="w-100 d-flex" *ngFor="let item of _dtoPatientInfo.reasonsForIdeation;let i=index;">
                <div class="mr-2 font-15"><a  class="number-selct mr-1" (click)="OnIdeationIntensity(item)"> {{item.orderSeq}}</a>
                  {{item.suicidalRiskCl}}
                   </div>
               </div>
          </div>
              
            </div>
            <div class="col-md-2 text-center d-flex align-items-center border">
             <div class="value-number-selct w-100">
              <span>{{dtosuicidalIdeationSeverity.reasonsForIdeationMonth}}</span>
              <mat-error *ngIf="SuicidalAssessmentFrm.submitted && dtosuicidalIdeationSeverity.reasonsForIdeationMonth == undefined "> 
                <mat-error class="text-xs" *ngIf="dtosuicidalIdeationSeverity.reasonsForIdeationMonth == undefined">
                 *required</mat-error>
                </mat-error>
             </div>
            </div>
            <div class="col-md-2 text-center d-flex align-items-center border">
              <!-- <div ><label class=" font-15 mt-2"><input type="checkbox" [(ngModel)]="dtosuicidalIdeationSeverity.reasonsForIdeationLifeTime" #reasonsForIdeationLifeTime="ngModel" name="reasonsForIdeationLifeTime"> </label></div> -->
              <!-- <div class="check-custom  larger-checkbox-center risk-custom-check pt-2 w-100">
                <mat-checkbox class="example-margin mr-2 font-15"  [(ngModel)]="dtosuicidalIdeationSeverity.reasonsForIdeationLifeTime" #reasonsForIdeationLifeTime="ngModel" name="reasonsForIdeationLifeTime"></mat-checkbox>
              </div> 
            class="form-control w-25 mx-auto" -->
            <div _ngcontent-qvs-c396="" class=" pt-2 w-100 text-center">
              <input  class="form-control w-25 mx-auto" numeric name="quantity" step="0" maxlength="1" customMin="0" customMax="5" [(ngModel)]="dtosuicidalIdeationSeverity.reasonsForIdeationLifeTime" #reasonsForIdeationLifeTime="ngModel" name="reasonsForIdeationLifeTime" (keypress)="OnchangeIdeationLifeTime($event,5)"/>
              <mat-error *ngIf="SuicidalAssessmentFrm.submitted && dtosuicidalIdeationSeverity.reasonsForIdeationLifeTime == undefined "> 
                <mat-error class="text-xs" *ngIf="dtosuicidalIdeationSeverity.reasonsForIdeationLifeTime == undefined">
                 *required</mat-error>
              </mat-error> 
            </div>
            </div>
        </div>
    </div>
    <div class="total-bg col-md-12 ">
      <div class="row">
          <div class="col-md-8  text-right">
            <div class="safe-t-label-bold">Total Score :</div>
           
           </div> 
          
          <div class="col-md-2 text-center">
            <span class="font-weight-bold">{{IdeationTotal}}</span>
          </div>
          <div class="col-md-2 text-center">
            <span class="font-weight-bold">{{IdeationLifeTimeTotal}}</span>
          </div>
        </div>
      </div>
      <div class="safe-t-label-bold mt-3">Notes</div>
      <div class="col-md-12 mt-3 mb-2  p-0">
        <textarea class="form-control" cols="4" [(ngModel)]="dtosuicidalIdeationSeverity.example"  #example="ngModel" name="example" maxlength="900"></textarea>
      </div>
      <div class="col-md-12 mt-3 mb-2  p-0">
  
        <div class="safe-t-label-bold p-0 mb-3 font-weight-500 text-dark col-md-12">Behaviors</div>
        <div class="col-md-12 pl-3 ">
          <div class="row">
         <!-- <div class="col-md-12"> <label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox">Preparatory Acts (e.g., buying pills, purchasing a gun, giving things away, writing a suicide note) </label></div>
         <div class="col-md-12"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" >Aborted/self-interrupted attempts,</label></div> 
         <div class="col-md-12"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox"  >Interrupted attempts and </label></div>
         <div class="col-md-12"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox"  >Actual attempts</label></div>  -->
         <div class=" check-custom check-fields-large-label  text-transform-unset col-md-6 pl-0" *ngFor="let item of _dtoPatientInfo.behaviors; let i=index;">
          <mat-checkbox class="example-margin mr-2 font-15 safe-t-label text-muted" [(ngModel)]="item.isChecked" name ="B{{i}}id" (change)="OnCheckBoxClick($event,6)">{{item.suicidalRiskCl}}</mat-checkbox>
        </div>
        </div>
  
          </div>
      </div>
      <div class="mb-2">
        <div class="twolinescheck check-custom check-fields-large-label text-dark text-transform-unset col-md-12 pl-0" >
          <div class="safe-t-label p-0"> <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="dtosuicideAssessment.nonsuicidalSelfInjuriousBehavior" #nonsuicidalSelfInjuriousBehavior="ngModel" name="nonsuicidalSelfInjuriousBehavior">Assess for the presence of non-suicidal self-injurious behavior  <span class="text-muted text-xs">(e.g. cutting, hair pulling, cuticle biting, skin picking)</span>
            <div class="text-muted text-xs mt-1">particularly among adolescents and  young adults, and especially among those with a history of mood or externalizing disorders</div></mat-checkbox> </div>
        </div>
        </div>
        <div class="mb-2">
        <div class="twolinescheck check-custom check-fields-large-label text-dark text-transform-unset col-md-12 pl-0" >
          <div class="safe-t-label p-0"> <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="dtosuicideAssessment.forYouths" #forYouths="ngModel" name="forYouths">For Youths:   <span class="text-muted text-xs">ask parents/guardian about evidence of suicidal thoughts, plans or behaviors and changes in mood, behaviors or disposition</span>
            </mat-checkbox> </div>
        </div>
        </div>
        <div class="mb-2">
        <div class="twolinescheck check-custom check-fields-large-label text-dark text-transform-unset col-md-12 pl-0" >
          <div class="safe-t-label p-0"> <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="dtosuicideAssessment.homicidalIdeation" #homicidalIdeation="ngModel" name="homicidalIdeation">Assess for homicidal ideation, plan behavior and intent    <span class="text-muted text-xs">particularly in:</span>
            </mat-checkbox> </div>
        </div>
        </div>
        <div class="mb-2">
        <div class="pl-3">
          <div class="twolinescheck check-custom check-fields-large-label text-dark text-transform-unset col-md-12 pl-0" >
            <div class="safe-t-label p-0"> <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="dtosuicideAssessment.impulsivityDisorders" #impulsivityDisorders="ngModel" name="impulsivityDisorders">   <span class="text-muted text-xs">character disordered males dealing with separation, especially if paranoid, or impulsivity disorders</span>
              </mat-checkbox> </div>
          </div>
        </div>
        </div>
        <!-- <div class="mb-2">
        <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  [(ngModel)]="dtosuicideAssessment.nonsuicidalSelfInjuriousBehavior" #nonsuicidalSelfInjuriousBehavior="ngModel" name="nonsuicidalSelfInjuriousBehavior">Assess for the presence of non-suicidal self-injurious behavior <span class="text-muted text-xs">(e.g. cutting, hair pulling, cuticle biting, skin picking)</span> </div>
        <div class="text-muted text-xs">particularly among adolescents and  young adults, and especially among those with a history of mood or externalizing disorders</div>
      </div>
      <div class="mb-2">
        <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  [(ngModel)]="dtosuicideAssessment.forYouths" #forYouths="ngModel" name="forYouths">For Youths: <span class="text-muted text-xs">ask parents/guardian about evidence of suicidal thoughts, plans or behaviors and changes in mood, behaviors or disposition</span> </div>
      
      </div>
      <div class="mb-2">
        <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  [(ngModel)]="dtosuicideAssessment.homicidalIdeation" #homicidalIdeation="ngModel" name="homicidalIdeation">Assess for homicidal ideation, plan behavior and intent <span class="text-muted text-xs">particularly in:</span></div>
      <div class="pl-3">
             
       <div class="mt-2"><input class="mr-2 "  type="checkbox"  [(ngModel)]="dtosuicideAssessment.impulsivityDisorders" #impulsivityDisorders="ngModel" name="impulsivityDisorders"> <span class="text-muted text-xs">character disordered males dealing with separation, especially if paranoid, or impulsivity disorders</span></div>
      </div>
      </div> -->
          </div>
          <div class="s4 mb-4">
            <div class="custom-head mt-3 mb-3"><h2 style="font-weight: 600;">Step 4: Guidelines to Determine Level of Risk and Develop Interventions to LOWER Risk Level</h2></div>
            <blockquote><div class="safe-head-quote">“The estimation of suicide risk, at the culmination of the suicide assessment, is the quintessential <span class="font-weight-bold"><u>clinical judgment,</u></span> since no study has identified one specific risk factor or set of risk factors as specifically predictive of suicide or other suicidal behavior.” 
              <div class="quote-from"> - From The American Psychiatric Association Practice Guidelines for the Assessment and Treatment of Patients with Suicidal Behaviors, page 24.</div></div>
            </blockquote>
            <div class="safe-head col-md-12 ">
              <div class="row">
                  <div class="col-md-8 ">
                    
                   <div class="safe-t-label p-0">RISK STRATIFICATION</div>
                   
                  </div>
                  <div class="col-md-4">
                    <div class="safe-t-label p-0">TRIAGE</div>
                  </div>
                  
              </div>
          </div>
            <div class="safe-t col-md-12 border-bottom ">
              <div class="row">
                  <div class="col-md-7 pt-2 pl-0">
                  <div class="hgr mb-2">
                   <div class="safe-t-label p-0  "> <span class="font-weight-bold"><u>High Risk</u></span></div>
                   <div class=" mt-3">
                      <!-- <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Suicidal  ideation  with intent or intent with plan in past month (C-SSRS Suicidal Ideation #4 or #5) </label></div>
                      <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Suicidal behavior within past 3 months (C-SSRS Suicidal Behavior)</label></div> -->
                      <div class="check-custom text-dark check-fields-large-label text-transform-unset col-md-12" *ngFor="let item of _dtoPatientInfo.highRisk; let i=index;">
                        <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="HR{{i}}id" (change)="OnCheckBoxClick($event,7)">{{item.suicidalRiskCl}}</mat-checkbox>                        
                      </div>
                   </div> 
                  </div> 
                  </div>
                  <div class="col-md-5">
                    <!-- <div class="safe-t-label p-2"><div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Initiate local psychiatric admission process</label></div>
                      <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Stay with patient until transfer to higher level of care is complete
                    Follow-up and document outcome of emergency psychiatric evaluation</label></div>
                  </div> -->
                  <div class="safe-t-label p-2 pt-3">
                  <div class="twolinescheck check-custom text-dark check-fields-large-label text-transform-unset col-md-12" *ngFor="let item of _dtoPatientInfo.highRiskTriage; let i=index;">
                    <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="HRT{{i}}id" (change)="OnCheckBoxClick($event,8)">{{item.suicidalRiskCl}}</mat-checkbox>
                  </div>
                </div>
                  </div>
              </div>
          </div>
          <div class="safe-t col-md-12 border-bottom ">
            <div class="row">
                <div class="col-md-7 pt-2 pl-0">
                  <div class="modr mb-2">
                 <div class="safe-t-label p-0 "> <span class="font-weight-bold"><u>Moderate Risk </u></span></div>
                 <div class=" mt-3">
                  <!-- <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Suicidal ideation with method WITHOUT plan, intent or behavior 
                    in past month (C-SSRS screen #3)</label></div>
                  <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Suicidal behavior more than 3 months ago (C-SSRS Suicidal Behavior)</label></div>
                  <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Multiple risk factors and few protective factors</label></div>
                    
                 -->
                 <div class="twolinescheck check-custom text-dark  check-fields-large-label text-transform-unset col-md-12" *ngFor="let item of _dtoPatientInfo.moderateRisk; let i=index;">
                  <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="MR{{i}}id" (change)="OnCheckBoxClick($event,9)">{{item.suicidalRiskCl}}</mat-checkbox>
                </div>
                 </div> 
                </div>
                </div>
                <div class="col-md-5">
                  <div class="safe-t-label p-2 pt-3">
                    <!-- <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Directly address suicide risk, implementing suicide prevention strategies</label></div>
                    <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Develop Safety Plan</label></div>
                   -->
                   <div class="twolinescheck check-custom text-dark check-fields-large-label text-transform-unset col-md-12" *ngFor="let item of _dtoPatientInfo.moderateRiskTriage; let i=index;">
                    <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="MRT{{i}}id" (change)="OnCheckBoxClick($event,10)">{{item.suicidalRiskCl}}</mat-checkbox>
                  </div>
                </div>
                </div>
                
            </div>
        </div>
        <div class="safe-t col-md-12 border-bottom ">
          <div class="row">
              <div class="col-md-7 pt-2 pl-0">
                <div class="lr mb-2">
               <div class="safe-t-label p-0 "> <span class="font-weight-bold"><u>Low Risk</u></span></div>
               <div class=" mt-3">
                <!-- <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Wish to die or suicidal thoughts (C-SSRS Suicidal Ideation #1 and/or #2) no method, plan, intent or behavior</label></div>
                <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Suicidal ideation more than 1 month ago (C-SSRS screen #1-5)</label></div>
                <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Modifiable risk factors and strong protective factors</label></div>
                <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >No reported history of Suicidal Ideation or Behavior</label></div>
                  
                -->
                <div class="twolinescheck check-custom text-dark check-fields-large-label text-transform-unset col-md-12" *ngFor="let item of _dtoPatientInfo.lowRisk; let i=index;">
                  <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="LR{{i}}id" (change)="OnCheckBoxClick($event,11)">{{item.suicidalRiskCl}}</mat-checkbox>
                </div>
               </div> 
              </div>
              </div>
              <div class="col-md-5">
  
                <div class="safe-t-label p-2 pt-3">
                  <!-- <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Discretionary Outpatient Referral</label></div> -->
                  <div class="twolinescheck check-custom text-dark check-fields-large-label text-transform-unset col-md-12" *ngFor="let item of _dtoPatientInfo.lowRiskTriage; let i=index;">
                    <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="LRT{{i}}id" (change)="OnCheckBoxClick($event,12)">{{item.suicidalRiskCl}}</mat-checkbox>
                  </div>
                  
                </div>
              </div>
              
          </div>
      </div>
          </div>
          <div class="s5 mb-4">
            <div class="custom-head mt-3 mb-3"><h2 style="font-weight: 600;">Step 5: Document Level of Risk, Rationale for Risk Assignment, Intervention and Structured Follow Up Plan (to be developed)</h2></div>
            <div class="safe-t-label-bold mt-3"> <span class="font-weight-bold"><u>Risk Level</u></span></div>
            <div class="col-md-12 p-0  mt-2">
              <!-- <label class="mr-3 font-15"><input type="checkbox" required="" class="mr-1 align-middle">High Risk</label>
              <label class="mr-3 font-15"><input type="checkbox" required="" class="mr-1 align-middle">Moderate Risk</label>
              <label class="mr-3 font-15"><input type="checkbox" required="" class="mr-1 align-middle">Low Risk Suicidal</label> -->
              <mat-radio-group aria-label="Select an option" required #riskLevel="ngModel"
              [(ngModel)]="dtosuicideAssessment.riskLevel" name="riskLevel">
              <mat-radio-button *ngFor="let item of _dtoPatientInfo.riskLevel" [value]="item.id" class="mr-2">{{item.suicidalRiskCl}}</mat-radio-button>
              
          </mat-radio-group>
          <mat-error *ngIf="SuicidalAssessmentFrm.submitted && dtosuicideAssessment.riskLevel== 0 "> 
            <mat-error class="text-xs" *ngIf="dtosuicideAssessment.riskLevel == 0">
              Risk Level is required</mat-error>
            </mat-error>
            </div>
            <div class="safe-t-label-bold mt-3"> <span class="font-weight-bold"><u>Clinical Note</u></span></div>
            <div class="mb-2 mt-3">
              <!-- <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Your Clinical Observation </div>
              <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Relevant Mental Status Information</div>
              <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Methods of Suicide Risk Evaluation </div>
              <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Brief Evaluation Summary </div>
               <div class="pl-3">
                <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Warning Signs</div>
                <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Risk Indicators</div>
                <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Protective Factors</div>
                <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Access to Lethal Means</div>
                <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Collateral Sources Used and Relevant Information Obtained</div>
                <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Specific Assessment Data to Support Risk Determination</div>
                <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Rationale for Actions Taken and Not Taken</div>
               </div>
               <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Provision of Crisis Line 1-800-273-TALK(8255) </div>
               <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Implementation of Safety Plan (If Applicable) </div>
               -->

               <div class=" check-custom check-fields-large-label text-transform-unset col-md-12" *ngFor="let item of _dtoPatientInfo.clinicalNotes; let i=index;">
                <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="CN{{i}}id" (change)="OnclinicalNotes($event,1)">{{item.suicidalRiskCl}}</mat-checkbox>
               <div *ngIf="item.id==_dtoPatientInfo.briefEvaluationSummary[0].parentId">
                <div class=" check-custom check-fields-large-label text-transform-unset col-md-12 pl-3" *ngFor="let item of _dtoPatientInfo.briefEvaluationSummary; let i=index;">
                  <mat-checkbox class="example-margin mr-2 font-15" [(ngModel)]="item.isChecked" name ="CNB{{i}}id" (change)="OnclinicalNotes($event,2)">{{item.suicidalRiskCl}}</mat-checkbox>
                </div>
              </div>
              </div>
            </div>
            <div class="Theme-textarea">
                 
              <textarea cols="4" class="form-control" placeholder="comments" [(ngModel)]="dtosuicideAssessment.clinicalNoteComments" #clinicalNoteComments="ngModel" name="clinicalNoteComments"></textarea>
            </div>
            </div>
            <div class="col-md-12">
              <div class="text-right" *ngIf="roleAccess.isCreate||roleAccess.isEdit">
              <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="postSucidalAssessment()">{{IsEditSuicidalAssessment?'Save':'Update'}}</button>
              <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="OnBack()">Cancel</button>
            </div>
            </div>
          </div>
          </form>
        </mat-tab>
  
  
  
  
  
  
        <mat-tab label="Safety Contract" > 
          <form (ngSubmit)="SucidalSafetyfrm.form.valid" id="SucidalSafetyfrm" autocomplete="off" #SucidalSafetyfrm="ngForm"
          novalidate>
          <div class="bg-white p-3 col-md-12">
            <div class="col-md-12 mt-3 mb-3">
              I, <span class="name-highlight">{{this._dtoPatientInfo.patientName}} (client)</span>, hereby contract with <span class="name-highlight">{{this._dtoPatientInfo.clinicianName}} (clinician)</span>, that I will take the following actions if I feel suicidal.
            </div>
            
            <div class="col-md-12 mb-4 mt-3">
              <div class="treat-label mb-4">1. I will not attempt suicide.</div>
              <div class="treat-label d-flex mb-2">
                <span class="mt-2 mr-2">2. I will phone </span>
                <span><input type="text" class="form-control"  [(ngModel)]="_dtoSafetyContract.qtwoName" #qtwoName="ngModel" name="qtwoName"
                  [ngClass]="{ 'is-invalid': SucidalSafetyfrm.submitted && qtwoName.invalid }" required /></span>
               <div>
                <mat-error *ngIf="SucidalSafetyfrm.submitted && qtwoName.invalid">
                  <mat-error class="error-message" *ngIf="qtwoName.errors.required">
                    *required</mat-error>
                </mat-error>
               </div>
                  <span class="mt-2 ml-2 mr-2">at </span>
                
                <span><input type="text" class="form-control" mask='(000) 000-0000' [(ngModel)]="_dtoSafetyContract.qtwoContact" #qtwoContact="ngModel" name="qtwoContact"
                  [ngClass]="{ 'is-invalid': SucidalSafetyfrm.submitted && qtwoContact.invalid }" required /></span>
                  <mat-error *ngIf="SucidalSafetyfrm.submitted && qtwoContact.invalid">
                    <mat-error class="error-message" *ngIf="qtwoContact.errors.required"> 
                      *required</mat-error>
                  </mat-error>
              </div>      
              <div class="treat-label d-flex mb-2">
                <span class="mt-2 mr-2">3. If I do not reach,</span>
                <span><input type="text" class="form-control" mask='(000) 000-0000' [(ngModel)]="_dtoSafetyContract.qthreeContact" #qthreeContact="ngModel" name="qthreeContact"
                  [ngClass]="{ 'is-invalid': SucidalSafetyfrm.submitted && qthreeContact.invalid }" required/></span>
                  <mat-error *ngIf="SucidalSafetyfrm.submitted && qthreeContact.invalid">
                    <mat-error class="error-message" *ngIf="qthreeContact.errors.required"> 
                      *required</mat-error>
                  </mat-error>
                <span class="mt-2 ml-2 mr-2">I will phone any of the following services : </span>
                 </div>
                 <div class="services-person">
                  <div class="col-md-6 mt-3 mb-3">
                    <div class="medication-dosage">
                      <div  class="row inputElement">
                        <div  class="col-md-6">
                          <label >Name/Agency</label>
                        </div>
                        <div  class="col-md-6">
                          <label >Phone</label>
                        </div>
                        
                      </div>
                      <div  class="loop-section position-relative mb-2 " *ngFor="let cont of _dtoQthreeContact;let i= index;let last=last;">
                        <div class="row inputElement">
                          <div  class="col-md-6">
                            <input  maxlength="50" placeholder="Name/Agency" type="text" required class="form-control "  [(ngModel)]="cont.contractName" #contractName="ngModel"
                            name="QTcontractName{{i}}"  required>
                            <mat-error *ngIf="SucidalSafetyfrm.submitted && contractName.invalid">
                              <mat-error class="error-message" *ngIf="contractName.errors.required">Name/Agency
                                is required</mat-error>
                            </mat-error>
                          </div>
                          <div  class="col-md-6">
                            <input  maxlength="50" placeholder="Phone" type="text"  mask='(000) 000-0000' required class="form-control "  [(ngModel)]="cont.contact" #contact="ngModel"
                            name="QTcontact{{i}}" required>
                            <mat-error *ngIf="SucidalSafetyfrm.submitted && contact.invalid">
                              <mat-error class="error-message" *ngIf="contact.errors.required">Phone
                                is required</mat-error>
                            </mat-error>
                          </div>
                          
                        </div>
                        <a  class="add " style="cursor: pointer;" (click)="addContactDtl(1)">
                          <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only" >Add</span>
                        </a>
                        <a  class="remove" style="cursor: pointer;" *ngIf="i!=0" (click)="removeclinicalinformation(contact,i,1)">
                          <i  class="fa fa-lg fa-minus-circle"></i>
                          <span  class="sr-only">Remove</span>
                        </a>
                      </div>
                    </div>
                  </div>
                 </div>
              <div class="treat-label">4. I will further seek social supports from any of the following people:</div>
              <div class="services-person">
                <div class="col-md-6 mt-3 mb-3">
                  <div class="medication-dosage">
                    <div  class="row inputElement">
                      <div  class="col-md-6">
                        <label >Name</label>
                      </div>
                      <div  class="col-md-6">
                        <label >Phone</label>
                      </div>
                      
                    </div>
                    <div  class="loop-section position-relative mb-2 " *ngFor="let cont of _dtoQFourContact;let i= index;let last=last;">
                      <div class="row inputElement">
                        <div  class="col-md-6">
                          <input  maxlength="50" placeholder="Name/Agency" type="text" required class="form-control "  [(ngModel)]="cont.contractName" #contractName="ngModel"
                          name="QfcontractName{{i}}"  required>
                          <mat-error *ngIf="SucidalSafetyfrm.submitted && contractName.invalid">
                            <mat-error class="error-message" *ngIf="contractName.errors.required">Name/Agency
                              is required</mat-error>
                          </mat-error>
                        </div>
                        <div  class="col-md-6">
                          <input  maxlength="50" placeholder="Phone" type="text"  mask='(000) 000-0000' required class="form-control "  [(ngModel)]="cont.contact" #contact="ngModel"
                          name="Qfcontact{{i}}" required>
                          <mat-error *ngIf="SucidalSafetyfrm.submitted && contact.invalid">
                            <mat-error class="error-message" *ngIf="contact.errors.required">Phone
                              is required</mat-error>
                          </mat-error>
                        </div>
                        
                      </div>
                      <a  class="add " style="cursor: pointer;" (click)="addContactDtl(2)">
                        <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only" >Add</span>
                      </a>
                      <a  class="remove" style="cursor: pointer;" *ngIf="i!=0" (click)="removeclinicalinformation(contact,i,2)">
                        <i  class="fa fa-lg fa-minus-circle"></i>
                        <span  class="sr-only">Remove</span>
                      </a>
                    </div>
                  </div>
                </div>
               </div>
              <div class="treat-label">5. If none of these actions are helpful or not available, I will check-in the ER at one of the following:</div>
              <div class="services-person">
                <div class="col-md-9 mt-3 mb-3">
                  <div class="medication-dosage">
                    <div  class="row inputElement">
                      <div  class="col-md-4">
                        <label >Name</label>
                      </div>
                      <div  class="col-md-4">
                        <label >Address</label>
                      </div>
                      <div  class="col-md-4">
                        <label >Phone</label>
                      </div>
                      
                    </div>
                    <div  class="loop-section position-relative mb-2 " *ngFor="let cont of _dtoQFiveContact;let i= index;let last=last;">
                      <div class="row inputElement">
                        <div  class="col-md-4">
                          <input  maxlength="50" placeholder="Name/Agency" type="text" required class="form-control "  [(ngModel)]="cont.contractName" #contractName="ngModel"
                          name="QficontractName{{i}}"  required>
                          <mat-error *ngIf="SucidalSafetyfrm.submitted && contractName.invalid">
                            <mat-error class="error-message" *ngIf="contractName.errors.required">Name/Agency
                              is required</mat-error>
                          </mat-error>
                        </div>
                        <div  class="col-md-4">
                          <input  maxlength="50" placeholder="Address" type="text" required="" class="form-control "  [(ngModel)]="cont.contractAddress" #contractAddress="ngModel"
                          name="QficontractAddress{{i}}" required>
                          <mat-error *ngIf="SucidalSafetyfrm.submitted && contractAddress.invalid">
                            <mat-error class="error-message" *ngIf="contractAddress.errors.required">Address
                              is required</mat-error>
                          </mat-error>
                        </div>
                        <div  class="col-md-4">
                          <input  maxlength="50" placeholder="Phone" type="text"  mask='(000) 000-0000' required class="form-control "  [(ngModel)]="cont.contact" #contact="ngModel"
                          name="Qficontact{{i}}" required>
                          <mat-error *ngIf="SucidalSafetyfrm.submitted && contact.invalid">
                            <mat-error class="error-message" *ngIf="contact.errors.required">Phone
                              is required</mat-error>
                          </mat-error>
                        </div>
                        
                      </div>
                      <a  class="add " style="cursor: pointer;" (click)="addContactDtl(3)">
                        <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only" >Add</span>
                      </a>
                      <a  class="remove" style="cursor: pointer;" *ngIf="i!=0" (click)="removeclinicalinformation(contact,i,3)">
                        <i  class="fa fa-lg fa-minus-circle"></i>
                        <span  class="sr-only">Remove</span>
                      </a>
                    </div>
                  </div>
                </div>
               </div>
              <div class="treat-label">6. If I am not able to receive help, I will phone 911 or 988.</div>
            </div>
            
            <div  class="col-md-12">
              <div  class="form-group clearfix m-0">
                <div  class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label mr-2 w-100">Client’s Signature </label>
                  <img  id="esign1" height="50px" width="300px" src="{{_dtoSafetyContract.clientSign}}"style="border: 1px solid #d3c7c7 !important;">
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialog(1)"> Add E-Sign </button>
                  <div class="float-left col-md-4 p-0 mt-4 pt-1" >
                    <mat-error *ngIf="SucidalSafetyfrm.submitted && _dtoSafetyContract.clientSign == undefined">
                      <mat-error class="mat-form-field-subscript-wrapper" *ngIf="_dtoSafetyContract.clientSign == undefined">
                        Client’s Signature is required</mat-error>
                  </mat-error>
                       </div>
                </div>
                <div  class="float-right col-md-2 p-0 ">
                  <mat-form-field class="w-100">
                    <mat-label>Date</mat-label>
                    <input matInput [matDatepicker]="picker1"  [min]="currentdate"  [(ngModel)]="_dtoSafetyContract.clinentSignDate"
                    #clinentSignDate="ngModel" name="clinentSignDate" required>
                    
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  <mat-error *ngIf="SucidalSafetyfrm.submitted && clinentSignDate.invalid">
                    <mat-error class="mat-form-field-subscript-wrapper" *ngIf="clinentSignDate.invalid">
                      Date is required</mat-error>
                </mat-error>
                </div>
              </div>
            </div>
            <div  class="col-md-12">
              <div  class="form-group clearfix m-0">
                <div  class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label mr-2 w-100">Clinician's Signature</label>
                  <img  id="esign2" height="50px" width="300px" src="{{_dtoSafetyContract.therapistSign}}"style="border: 1px solid #d3c7c7 !important;">
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialog(2)"> Add E-Sign </button>
                  <div class="float-left col-md-4 p-0 mt-4 pt-1" >
                    <mat-error *ngIf="SucidalSafetyfrm.submitted && _dtoSafetyContract.therapistSign == undefined">
                      <mat-error class="mat-form-field-subscript-wrapper" *ngIf="_dtoSafetyContract.therapistSign == undefined">
                        Therapist’s Signature is required</mat-error>
                  </mat-error>
                       </div>
                </div>
                <div  class="float-right col-md-2 p-0 ">
                  <mat-form-field class="w-100">
                    <mat-label>Date</mat-label>
                    <input matInput [matDatepicker]="picker"  [(ngModel)]="_dtoSafetyContract.therapistSignDate"
                    #therapistSignDate="ngModel" name="therapistSignDate" required [min]="currentdate" >
                    
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  <mat-error *ngIf="SucidalSafetyfrm.submitted && therapistSignDate.invalid">
                    <mat-error class="mat-form-field-subscript-wrapper" *ngIf="therapistSignDate.invalid">
                      Date is required</mat-error>
                </mat-error>
                </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="text-right" *ngIf="roleAccess.isCreate||roleAccess.isEdit" >
                  <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="postSuicidalSafetyCOntract()">{{IsEditContract?'Save':'Update'}}</button>
                  <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="OnBack()" type="button">Cancel</button>
                </div>
                </div>
          </div>
          </form>
        </mat-tab>
  
  
  
        <mat-tab label="Suicide Screener"> 
          <div class="bg-white p-3 col-md-12">
            <div class="custom-head mt-3 mb-3"><h2>Suicide Screening</h2></div>
            <div class="col-md-12 p-0">
              <div class="col-md-12 d-flex">
                <div class="ml-auto custom-toggle-btn toggle-top-position">
                  <mat-button-toggle-group name="fontStyle" aria-label="Font Style"   [(ngModel)]="_dtoSuicidalScreener.FormTypeId" name="FormTypeId" (change)="OnFormTypechange()">
                    <mat-button-toggle [value]="1" >Daily</mat-button-toggle>
                    <mat-button-toggle [value]="2">Monthly</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
              <div class="col-md-12 text-right">
                <div class="row">
                 <div class="font-12 hint-ques-label"><i class="far fa-lightbulb mr-2"></i>Ask Questions that are Bolded and Underlined</div>
               <div class="ml-auto mt-3">
                <span class="low"><i class="fas fa-square mr-2"> </i>Low Risk</span>
                <span class="moderate"><i class="fas fa-square mr-2"> </i>Moderate Risk</span>
                <span class="high"><i class="fas fa-square mr-2"> </i>High Risk</span>
               </div>
                </div>
                </div>
              <div class="safe-head col-md-12 ">
                <div class="row">
                  <div class="col-md-10 ">
                    <div class="font-15 mt-3 pl-2">Suicidal Ideation Definitions and Prompts</div>
                  </div>
                  <div class="col-md-2 text-center pl-0 border-left">
                    <div class="safe-t-label pl-0 text-muted">Past Month</div>
                    <div class="col-md-12 border-top">
                    <div class="row">
                      <div class="col-md-6 p-2 text-success bg-white">
                        <div class="safe-t-label pl-0"><i class="fas fa-check-circle mr-2"></i>Yes</div>
                      </div>
                      <div class="col-md-6 p-2 border-left text-danger bg-white">
                        <div class="safe-t-label pl-0"><i class="fas fa-times-circle mr-2"></i>No</div>
                      </div>
                    </div>
                  </div>
                  </div>
                 </div>
                 </div>
                 <div class="safe-t col-md-12 safe-border">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="safe-t-label-bold">1) Wish to be dead</div>
                      <div class="safe-t-label text-muted">Person endorses thoughts about a wish to be dead or not alive anymore, or wish to fall asleep and not wake up</div>
                      <div class="ques-safe">Have you wished you were dead or wished you could go to sleep and not wake up?</div>
                     
                    </div>
                    <div class="col-md-2">
                      <div class="col-md-12 ">
                      <div class="row">
                        <div class="col-md-6 h-40 border-right lr">
                          <div class="form-check text-center large-radio-btn">
                            <!--  -->
                            <input class="form-check-input" type="radio" name="wistodead" id="flexRadioDefault1" (click)="OnRadioButtonChange(1,1)" [checked]="this._dtoSuicidalScreener.wishToDead">
                            <label class="form-check-label" for="flexRadioDefault1">
                              
                            </label>
                          </div>
                           </div>
                        <div class="col-md-6  h-40 p-2">
                          <div class="form-check text-center large-radio-btn">
                            <!--   -->
                            <input class="form-check-input" type="radio" name="wistodead" id="flexRadioDefault2" (click)="OnRadioButtonChange(2,1)" [checked]="this._dtoSuicidalScreener.wishToDead==false">
                            <label class="form-check-label" for="flexRadioDefault2">
                              
                            </label>
                          </div>
                           </div>
                      </div>
                    </div>
                    <mat-error *ngIf="SubmitSuicideScreener && this._dtoSuicidalScreener.wishToDead==null">                 
                      <mat-error class="error-message text-center pl-3 mt-2" *ngIf="this._dtoSuicidalScreener.wishToDead==null">
                        This field is required</mat-error>
                   </mat-error> 
                      </div>
                    </div>
                  </div>
                  <div class="safe-t col-md-12 safe-border">
                    <div class="row">
                      <div class="col-md-10">
                        <div class="safe-t-label-bold">2) Suicidal Thoughts</div>
                        <div class="safe-t-label text-muted">general non-specific thoughts of wanting to end one's life/die by suicide, "I've thought about Killing myself" without general thoughts of ways to kill oneself/associated methods,intent, or plan.</div>
                        <div class="ques-safe">Have you actually had any thoughts of killing yourself?</div>
                        
                      </div>
                      <div class="col-md-2">
                        <div class="col-md-12 ">
                        <div class="row">
                          <div class="col-md-6 h-40 border-right lr">
                            <div class="form-check text-center large-radio-btn">
                              <!--   -->
                              <input class="form-check-input" type="radio" id="flexRadioDefault1"  name="suicidalThoughts" (click)="OnRadioButtonChange(1,2)" [checked]="this._dtoSuicidalScreener.suicidalThoughts">
                              <label class="form-check-label" for="flexRadioDefault1">
                                
                              </label>
                            </div>
                             </div>
                          <div class="col-md-6  h-40 p-2 ">
                            <div class="form-check text-center large-radio-btn">
                              <!--  -->
                              <input class="form-check-input" type="radio" id="flexRadioDefault2"  name="suicidalThoughts"  (click)="OnRadioButtonChange(2,2)" [checked]="this._dtoSuicidalScreener.suicidalThoughts==false">
                              <label class="form-check-label" for="flexRadioDefault2">
                                
                              </label>
                            </div>
                             </div>
                        </div>
                      </div>
                      <mat-error *ngIf="SubmitSuicideScreener && this._dtoSuicidalScreener.suicidalThoughts==null">                              
                        <mat-error class="error-message text-center pl-3 mt-2" *ngIf="this._dtoSuicidalScreener.suicidalThoughts==null">
                          This field is required</mat-error>
                     </mat-error> 
                        </div>
                      </div>
                    </div>
                    <div class="info-question-answer">If <b>YES</b> to 2, ask questions 3, 4, 5, and 6. If <b>NO</b> to 2, go directly to question 6.</div>
                   
                   <div *ngIf=" this._dtoSuicidalScreener.suicidalThoughts ">
                    <div class="safe-t col-md-12 safe-border">
                      <div class="row">
                        <div class="col-md-10">
                          <div class="safe-t-label-bold"> 3) Suicidal Thoughts with Method(without Specific Plan or Intent to Act)</div>
                          <div class="safe-t-label text-muted" *ngIf="_dtoSuicidalScreener.FormTypeId==1">
                            Person endorses thoughts of suicide and has thought of a least one method during the assessment period. This is different than a specific plan with time, place, or method details worked out. "I thought about taking an overdose, but I never made a specific plan as to when where or how I would actually do it... and I would never go through with it
                          </div>
                          <div class="ques-safe"> Have you been thinking about how you might do this?</div>
                         
                        </div>
                        <div class="col-md-2">
                          <div class="col-md-12 ">
                          <div class="row">
                            <div class="col-md-6 h-40 border-right " [ngClass]="_dtoSuicidalScreener.FormTypeId==1?'hgr':'lr'">
                              <div class="form-check text-center large-radio-btn">
                                <!--  -->
                                <input class="form-check-input" type="radio" name="thoughtswothpaln" id="flexRadioDefault1" (click)="OnRadioButtonChange(1,3)"  [checked]="this._dtoSuicidalScreener.thoughtsWithoutPlan">
                                <label class="form-check-label" for="flexRadioDefault1">
                                  
                                </label>
                              </div>
                               </div>
                            <div class="col-md-6  h-40 p-2 ">
                              <div class="form-check text-center large-radio-btn">
                                <!--  -->
                                <input class="form-check-input" type="radio" name="thoughtswothpaln" id="flexRadioDefault2" (click)="OnRadioButtonChange(2,3)" [checked]="this._dtoSuicidalScreener.thoughtsWithoutPlan==false">
                                <label class="form-check-label" for="flexRadioDefault2">
                                  
                                </label>
                              </div>
                               </div>
                          </div>
                        </div>
                        <mat-error *ngIf="SubmitSuicideScreener && this._dtoSuicidalScreener.thoughtsWithoutPlan==null">                                           
                          <mat-error class="error-message text-center pl-3 mt-2" *ngIf="this._dtoSuicidalScreener.thoughtsWithoutPlan==null">
                            This field is required</mat-error>
                       </mat-error> 
                          </div>
                        </div>
                      </div>
                      <div class="safe-t col-md-12 safe-border">                       
                        <div class="row">
                          <div class="col-md-10">
                            <div class="safe-t-label-bold"> 4) Suicidal Intent (without Specific Plan)</div>
                            <div class="safe-t-label text-muted" *ngIf="_dtoSuicidalScreener.FormTypeId==1">Active suicidal thoughts of killing oneself and patient reports having some intern to act on such thoughts, as opposed to "I have the thoughts, but I definitely will not do anything about them.</div>
                            <div class="ques-safe">Have you had these thoughts and had some intention of acting on them?</div>
                           
                          </div>
                          <div class="col-md-2">
                            <div class="col-md-12 ">
                            <div class="row">
                              <div class="col-md-6 h-40 border-right " [ngClass]="_dtoSuicidalScreener.FormTypeId==1?'hgr':'lr'">
                                <div class="form-check text-center large-radio-btn">
                                  <!--  -->
                                  <input class="form-check-input" type="radio" name="intentwoplan" id="flexRadioDefault1" (click)="OnRadioButtonChange(1,4)" [checked]="this._dtoSuicidalScreener.intentWithoutPlan">
                                  <label class="form-check-label" for="flexRadioDefault1">
                                    
                                  </label>
                                </div>
                                 </div>
                              <div class="col-md-6  h-40 p-2 ">
                                <div class="form-check text-center large-radio-btn">
                                  <!--  -->
                                  <input class="form-check-input" type="radio" name="intentwoplan" id="flexRadioDefault2" (click)="OnRadioButtonChange(2,4)" [checked]="this._dtoSuicidalScreener.intentWithoutPlan==false">
                                  <label class="form-check-label" for="flexRadioDefault2">
                                    
                                  </label>
                                </div>
                                 </div>
                            </div>
                          </div>
                          <mat-error *ngIf="SubmitSuicideScreener && this._dtoSuicidalScreener.intentWithoutPlan==null">          
                            <mat-error class="error-message text-center pl-3 mt-2" *ngIf="this._dtoSuicidalScreener.intentWithoutPlan==null">
                              This field is required</mat-error>
                        </mat-error>
                            </div>
                          </div>
                        </div>
                        <div class="safe-t col-md-12 safe-border">
                          <div class="row">
                           

                            <div class="col-md-10">
                              <div class="safe-t-label-bold"> 5) Suicidal Intent (with Specific Plan)</div>
                              <div class="safe-t-label text-muted" *ngIf="_dtoSuicidalScreener.FormTypeId==1">Thoughts of killing oneself with details of plan fully or partially worked out and person has some intent to carry it out.</div>
                              <div class="ques-safe">Have you started to work out or worked out the details of how to kill yourself? Did you intend to carry out this plan?</div>
                             
                            </div>
                            <div class="col-md-2">
                              <div class="col-md-12 ">
                              <div class="row">
                                <div class="col-md-6 h-40 border-right "  [ngClass]="_dtoSuicidalScreener.FormTypeId==1?'hgr':'lr'">
                                  <div class="form-check text-center large-radio-btn">
                                    <!--   -->
                                    <input class="form-check-input" type="radio" name="intentwplan" id="flexRadioDefault1" (click)="OnRadioButtonChange(1,5)" [checked]="this._dtoSuicidalScreener.intentWithPlan">
                                    <label class="form-check-label" for="flexRadioDefault1">
                                      
                                    </label>
                                  </div>
                                   </div>
                                <div class="col-md-6  h-40 p-2 ">
                                  <div class="form-check text-center large-radio-btn">
                                    <!--  -->
                                    <input class="form-check-input" type="radio" name="intentwplan" id="flexRadioDefault2"  (click)="OnRadioButtonChange(2,5)" [checked]="this._dtoSuicidalScreener.intentWithPlan==false">
                                    <label class="form-check-label" for="flexRadioDefault2">
                                      
                                    </label>
                                  </div>
                                   </div>
                              </div>
                            </div>
                            <mat-error *ngIf="SubmitSuicideScreener && this._dtoSuicidalScreener.intentWithPlan==null">                       
                              <mat-error class="error-message text-center pl-3 mt-2" *ngIf="this._dtoSuicidalScreener.intentWithPlan==null">
                                This field is required</mat-error>
                           </mat-error>
                              </div>
                            </div>
                          </div>
                    </div>
                    <div class="safe-t col-md-12 safe-border">
                      <div class="row">
                        <div class="col-md-10">
                          <div class="safe-t-label-bold">6) Suicide Behavior Question</div>
                          <div class="ques-safe">Have you ever done anything, started to do anything, or prepared to do anything to end your life?</div>
                          <div class="safe-t-label text-muted">Examples: Collected pills, obtained a gun, gave away valuables, wrote a will or suicide note, took out pills but didn’t swallow any, held a gun but changed your mind or it was grabbed from your hand, went to the roof but didn’t jump; or actually took pills, tried to shoot yourself, cut yourself, tried to hang yourself, etc.</div>
                          <div class="ques-safe">If YES, was this within the past 3 months?</div>
                        
                        </div>
                        <div class="col-md-2">
                          <div class="col-md-12  text-center border">
                            <div class="row">
                            <div class="safe-head safe-t-label pl-0 text-muted w-100">Lifetime</div>
                          </div>
                          </div>
                          <div class="col-md-12 ">
                          <div class="row">
                            <div class="col-md-6 h-40 border-right p-2">
                              <div class="form-check text-center large-radio-btn">
                                <!--   -->
                                <input class="form-check-input" type="radio" name="lifetime" id="flexRadioDefault1" (click)="OnRadioButtonChange(1,6)" [checked]="this._dtoSuicidalScreener.suicideBehaviourLifeTime">
                                <label class="form-check-label" for="flexRadioDefault1">
                                  
                                </label>
                              </div>
                               </div>
                            <div class="col-md-6  h-40 p-2 ">
                              <div class="form-check text-center large-radio-btn">
                                <!--  -->
                                <input class="form-check-input" type="radio" name="lifetime" id="flexRadioDefault2" (click)="OnRadioButtonChange(2,6)" [checked]="this._dtoSuicidalScreener.suicideBehaviourLifeTime==false">
                                <label class="form-check-label" for="flexRadioDefault2">
                                  
                                </label>
                              </div>
                               </div>
                          </div>
                        </div>
                        <div class="col-md-12  text-center border">
                          <div class="row">
                          <div class="safe-head safe-t-label pl-0 text-muted w-100">Past 3 months</div>
                        </div>
                        </div>
                        <div class="col-md-12 ">
                        <div class="row">
                          <div class="col-md-6 h-40 border-right hgr">
                            <div class="form-check text-center large-radio-btn">
                              <!--  -->
                              <input class="form-check-input" type="radio" name="past3" id="flexRadioDefault1" (click)="OnRadioButtonChange(1,7)" [checked]="this._dtoSuicidalScreener.suicideBehaviourThreeMonths">
                              <label class="form-check-label" for="flexRadioDefault1">
                                
                              </label>
                            </div>
                             </div>
                          <div class="col-md-6  h-40 modr">
                            <div class="form-check text-center large-radio-btn">
                              <!--   -->
                              <input class="form-check-input" type="radio" name="past3" id="flexRadioDefault2" (click)="OnRadioButtonChange(2,7)" [checked]="this._dtoSuicidalScreener.suicideBehaviourThreeMonths==false">
                              <label class="form-check-label" for="flexRadioDefault2">
                                
                              </label>
                            </div>
                             </div>
                        </div>
                      </div>
                      <mat-error *ngIf="SubmitSuicideScreener && (this._dtoSuicidalScreener.suicideBehaviourLifeTime==null || this._dtoSuicidalScreener.suicideBehaviourThreeMonths==null)">                                    
                        <mat-error class="error-message text-center pl-3 mt-2" *ngIf="(this._dtoSuicidalScreener.suicideBehaviourLifeTime==null || this._dtoSuicidalScreener.suicideBehaviourThreeMonths==null)">
                          This field is required</mat-error>
                     </mat-error> 
                          </div>
                        </div>
                      </div>
                  </div>
              
            
          </div>
          <div class="col-md-12">
            <div class="text-right" *ngIf="roleAccess.isCreate||roleAccess.isEdit">
            <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button  (click)="OnSuicideScreenerValid()">Save</button>
            <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="OnBack()" type="button">Cancel</button>
          </div>
          </div>
    
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
 <!-- Modal content for Activity log-->
<div class="Activity-popup">
  <div class="modal"  tabindex="-1" role="dialog" [style.display]=" ShowActivitylog ? 'block' : 'none'">
     <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h6 class="modal-title">Activity Log</h6>
              <button type="button" class="close"  data-dismiss="modal"
              aria-label="Close" (click)="onCloseActivitylog()">
              <span aria-hidden="true">&times;</span>
           </button>
           </div>
           <div class="modal-body ">
              <div class="individual-threaphy-container">
              <div  class="history-list col-md-14 p-0">
                 <ul>
                    <li *ngFor="let item of ActivitylogList;let i = index">
                        <div class="list-history col-md-14">
                            <div class="row">
                                <div class="col-md-1" *ngIf="item.roleId !== 4">
                                    <img class="img-fluid" src="../../../assets/images/move2stage-icon.png"/>
                                </div>
                                <div class="col-md-1" *ngIf="item.roleId == 4">
                                    <img class="img-fluid" src="../../../assets/images/move2stage-icon-green.png"/>
                                </div>
                                <div class="col-md-9">
                                    <p class="sub-history mb-1"></p>
                                    <p class="sub-history2 mb-1" innerHTML="{{global.getContent(item.message)}}"></p>
                                </div>
                                <div class="col-md-2 text-right">
                                    <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) |  date: 'MM/dd/yyyy'}}</p>
                                    <p class="sub-history2 mb-1">{{global.globalUtctimeformat(item.createdOn) | date: 'hh:mm a' }}</p>
                                </div>
                            </div>
  
                        </div>
                    </li>
  
                </ul>
            </div>
        </div>
        </div>
     </div>
        <div class="col-md-12 p-0 mt-4 view-note d-none">
  
            <div class="row">
                <div class="col-md-12 mb-1" *ngFor="let item of ActivitylogList;let i = index">
                    <div class="list-note">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="float-left w-100 pl-4 position-relative">
  
                                    <p class="note-content mt-1" innerHTML="{{global.getContent(item.message)}}">
  
                                    </p>
                                </div>
                            </div>
  
                </div>
              </div>
              </div>
           </div>
        </div>
     </div>
  </div>
  <div>
    <a mimetype="application/octet-stream" #downloadLink></a>
  </div>
export class PhoneSchoolmeetForm{
    PhoneSchoolMeetProgressNotesId:Number;
    PatientAccountId:Number;
    MeetingscheduleId:Number;
    MeetingscheduletimeId:Number;
    FormOfCommunication:String;
    WhomCommunicated:String;
    Content:String;
    StaffInitials:any;
    StaffInitial1:any;
    SignDate:any;
    Typeid:Number;
    Checkin:any;
    CheckOut:any;
    isSave:boolean;
    ClinicianName:string;
    ClientName:string;
    LicenseNumber : string;
    SSignature : boolean;
}



<div class="col-md-12">
    <div class="lead-box">
        <div class="head-section mb-2 mt-0" >{{!isEditlocation ? 'Add' :
            'Update'}} Location</div>
        <form (ngSubmit)="sfcclocationform.form.valid " id="sfcclocationform"
            autocomplete="off" #sfcclocationform="ngForm" novalidate>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Location Name" [(ngModel)]="_sfcclocation.locationName"
                                    appAlphabetOnly #locationName="ngModel" name="locationName"
                                    [ngClass]="{ 'is-invalid': sfcclocationform.submitted && locationName.invalid }"
                                    required>
                                <mat-error *ngIf="sfcclocationform.submitted && locationName.invalid">
                                    <mat-error *ngIf="locationName.errors.required">Location Name is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>

                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <mat-label>Location Type</mat-label>
                                <mat-select disableOptionCentering [(ngModel)]="_sfcclocation.locationType" #locationType="ngModel"
                                    name="locationType"
                                    [ngClass]="{ 'is-invalid': sfcclocationform.submitted && locationType.invalid }"
                                    required>
                                    <mat-option [value]="1">
                                        Head
                                    </mat-option>
                                    <mat-option [value]="2">
                                        Branch
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="sfcclocationform.submitted && locationType.invalid">
                                    <mat-error *ngIf="locationType.errors.required">Location type is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Email" [(ngModel)]="_sfcclocation.email" #Email="ngModel"
                                    autocomplete="new-password" name="Email"
                                    pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                                    [ngClass]="{ 'is-invalid': sfcclocationform.submitted && Email.invalid }" required>
                                <mat-error *ngIf="sfcclocationform.submitted && Email.invalid">
                                    <mat-error *ngIf="Email.errors?.required">Email is required</mat-error>
                                    <mat-error *ngIf="Email.errors?.pattern">Please enter valid email address
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>


                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="w-100">

                                <input matInput placeholder="Phone Number" mask='(000) 000-0000' maxlength="14"
                                    [(ngModel)]="_sfcclocation.phone" #phone="ngModel" name="phone"
                                    [ngClass]="{ 'is-invalid': sfcclocationform.submitted && phone.invalid }" required>
                                <mat-error *ngIf="sfcclocationform.submitted && phone.invalid">
                                    <mat-error *ngIf="phone.errors.required">Phone Number is required</mat-error>
                                    <mat-error *ngIf="phone.errors?.minlength">Please enter valid phone number
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>

                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Address 1" [(ngModel)]="_sfcclocation.address1"
                                    #address1="ngModel" name="address1"
                                    [ngClass]="{ 'is-invalid': sfcclocationform.submitted && address1.invalid }"
                                    required>
                                <mat-error *ngIf="sfcclocationform.submitted && address1.invalid">
                                    <mat-error *ngIf="address1.errors.required">Address 1 is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>


                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Address 2" [(ngModel)]="_sfcclocation.address2"
                                    #address2="ngModel" name="address2"
                                    [ngClass]="{ 'is-invalid': sfcclocationform.submitted && address2.invalid }">
                                <mat-error *ngIf="sfcclocationform.submitted && address2.invalid">
                                    <mat-error *ngIf="address2.errors.required">Address 2 is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>

                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <mat-label>State</mat-label>
                                <mat-select disableOptionCentering [(ngModel)]="_sfcclocation.stateId" #stateId="ngModel"
                                    (selectionChange)="onChangeState($event)" name="stateId"
                                    [ngClass]="{ 'is-invalid': sfcclocationform.submitted && stateId.invalid }"
                                    required>
                                    <mat-option *ngFor="let item of ddlStates" [value]="item.StatesId">
                                        {{item.StateName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="sfcclocationform.submitted && stateId.invalid">
                                    <mat-error *ngIf="stateId.errors.required">State is required</mat-error>
                                </mat-error>
                            </mat-form-field>


                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <mat-label>City</mat-label>
                                <mat-select disableOptionCentering [(ngModel)]="_sfcclocation.cityId" #cityId="ngModel" name="cityId"
                                    [ngClass]="{ 'is-invalid': sfcclocationform.submitted && cityId.invalid }" required>
                                    <mat-option *ngFor="let item of ddlCities" [value]="item.CitiesId">
                                        {{item.CityName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="sfcclocationform.submitted && cityId.invalid">
                                    <mat-error *ngIf="cityId.errors.required">City is required</mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 mt-2">
                            <div class="form-group">
                              <div class="check-custom ins-active">
                                <label class="control-label-new font-weight-bold mr-4"
                                >Tour</label>
                                <mat-slide-toggle [(ngModel)]="_sfcclocation.isTour" #isTour="ngModel" name="isTour" class="insuranceIsActive"></mat-slide-toggle>
                             
                                <span class="ml-3">
                                    <a  title="Disable the tour request option when the location does not support tours" 
                                      role="button"><i class="fa fa-info-circle" aria-hidden="true"></i></a></span>
                            </div>
                              
                            </div>
                          </div>
                      
                        <!-- <div class="col-md-3">
                            <div class="check-custom mt-4">
                                <mat-checkbox class="example-margin" [(ngModel)]="_sfcclocation.isActive"
                                    #isActive="ngModel" name="isActive">Active
                                </mat-checkbox>
                            </div>
                        </div> -->
                    </div>
                </div>

            </div>
            <div class="col-md-12 text-right mt-3"  *ngIf="roleAccess.isEdit || roleAccess.isCreate">
                <button type="submit" mat-stroked-button title="{{!isEditlocation ? 'Save' : 'Update'}}"
                    class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="addorUpdate(sfcclocationform)"  [ngClass]="{'disabled': onpostform }" >{{!isEditlocation ? 'Save' :
                    'Update'}}</button>
                <button mat-stroked-button class="btn btn-cancel primary-btn-m h-40" type="button" title="Cancel"
                    (click)="resetlocationform(sfcclocationform)">Cancel</button>

            </div>

        </form>
    </div>

</div>
<div class="col-md-12 p-0 mt-4">
    <div class="lead-box">
        <div class="clearfix w-100">
            <div class="box-lead-head float-left">Location List</div>
        </div>
        <div class="col-md-12  table-custom p-0">
            <div class="table-responsive">
                <mat-table #table [dataSource]="dataSource" matSort class="w-100">
                    <ng-container matColumnDef="sno">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="locationName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Location Name </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.locationName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="isTour">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Tour </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.isTour ? 'Yes' : 'No'}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="address">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Address </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.address1}}, {{element.address2}}, {{element.stateName}}, {{element.cityName}} </mat-cell>
                    </ng-container>
                   
                    <ng-container matColumnDef="Email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Email </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Phone">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.phone | mask: '(000) 000-0000'}} </mat-cell>
                    </ng-container>
                    <!-- <ng-container matColumnDef="IsActive">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Active </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.isActive == 1 ? 'Yes':'No'}} </mat-cell>
                    </ng-container> -->

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>{{ (roleAccess.isEdit || roleAccess.isDelete) ? 'Actions' : '' }}  </mat-header-cell>
                        <mat-cell *matCellDef="let element;let i = index;">
                            <button type="button" mat-icon-button matTooltip="Edit" role="button"  *ngIf="roleAccess.isEdit"
                                (click)="oneditsfccLocation(element)" color="primary">
                                <img src="../../../assets/images/editblue.png">
                            </button>
                            <button mat-icon-button matTooltip="Delete" role="button"  *ngIf="roleAccess.isDelete" (click)="ondeleteLocation(element)" color="warn">
                                <img src="../../../assets/images/deletered.png">
                            </button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row [style.background-color]="(isEditlocation && row.sfccMasterId == this._sfcclocation.sfccMasterId) ? 'aliceblue' : 'white'" *matRowDef="let row; columns: displayedColumns;"></mat-row>

                </mat-table>
            </div>
        </div>
        <mat-paginator 
        [pageSize]="20"[pageSizeOptions]="[ 10, 20, 50, 75, 100]" showFirstLastButtons></mat-paginator>
    </div>
</div>
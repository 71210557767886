
  export class DtoSuicidalAssessmentInfo
    {
        dob:any;
        patientName:string;
        clinicianName:string;
        patientAccountId:Number;
        patientInfoId:Number;
        activiatingEvent:Array<dtoSuicidalRiskChecklists> ;
        treatmentHistory:Array<dtoSuicidalRiskChecklists> ;
        clinicalStatus:Array<dtoSuicidalRiskChecklists> ;
        internal:Array<dtoSuicidalRiskChecklists> ;
        external:Array<dtoSuicidalRiskChecklists> ;
        behaviors:Array<dtoSuicidalRiskChecklists> ;
        highRisk:Array<dtoSuicidalRiskChecklists> ;
        highRiskTriage:Array<dtoSuicidalRiskChecklists> ;
        moderateRisk:Array<dtoSuicidalRiskChecklists> ;
        moderateRiskTriage:Array<dtoSuicidalRiskChecklists> ;
        lowRisk:Array<dtoSuicidalRiskChecklists> ;
        lowRiskTriage:Array<dtoSuicidalRiskChecklists> ;
        riskLevel:Array<dtoSuicidalRiskChecklists> ;
        clinicalNotes:Array<dtoSuicidalRiskChecklists> ;
        briefEvaluationSummary:Array<dtoSuicidalRiskChecklists> ;
        frequency:Array<dtoSuicidalRiskChecklists> ;
        duration :Array<dtoSuicidalRiskChecklists> ;
        controllability :Array<dtoSuicidalRiskChecklists> ;
        deterrents :Array<dtoSuicidalRiskChecklists> ;
        reasonsForIdeation :Array<dtoSuicidalRiskChecklists> ;
        suicidalIdeationDtl:suicidalIdeationSeverity;
        suicideAssessment:suicideAssessment;
    }

    export class dtoSuicidalRiskChecklists
    {

        id:Number;
        suicidalRiskClgroupId:Number;
        suicidalRiskClgroup:string;
        suicidalRiskCl:string;
        orderSeq:Number;
        isChecked:any;
        parentId:Number;
    }

    export class suicidalIdeationSeverity
    {
        suicidalIdeationSeverityId:Number;
        patientAccountId:Number;
        wishTodeadMonth:boolean;
        wishTodeadLifetime:boolean;
        currentSuithoughtsMonth:boolean;
        currentSuithoughtsLifetime:boolean;
        thoughtsWithMethodMonth:boolean;
        thoughtsWithMethodLifeTime:boolean;
        intentWithoutPlanMonth:boolean
        intentWithoutPlanLifeTime:boolean;
        intentwithPlanMonth :boolean;
        intentwithPlanMonthLifeTime :boolean;
        preparedForSuicideMonth :boolean;
        preparedForSuicideLifeTime :boolean;
        example:string;
        howManyTimesMonth:Number;
        howManyTimesLifeTime:Number;
        howLongLastMonth:Number;
        howLongLastLifeTime:Number;
        controllabilityMonth:Number;
        controllabilityLifeTime:Number;
        deterrentsMonth:Number;
        deterrentsLifeTime:Number;
        reasonsForIdeationMonth:Number;
        reasonsForIdeationLifeTime:Number;        
    }


    export class suicideAssessment
    {
        suicideAssessmentId:Number;
        activatingEvents:any;
        treatmentHistory:any;
        clinicalStatus:any;
        accessToLethalMethods:boolean;
        protectiveFactorsInternal:any;
        protectiveFactorsExternal :any;
        behaviors :any;
        nonsuicidalSelfInjuriousBehavior:boolean;
        forYouths :boolean;
        homicidalIdeation:boolean;
        impulsivityDisorders:boolean;
        riskStratificationHigh:any;
        riskStratificationHighTriage:any;
        riskStratificationModerate:any;
        riskStratificationModerateTriage:any;
        riskStratificationLow:any;
        riskStratificationLowTriage :any;
        riskLevel :any;
       clinicalNote:any;
        briefEvaluationSummary:any;
        clinicalNoteComments:string;
        patientAccountId:Number;
        treatmentHistryOther:string;
        internalOther:string;     
        externalOther:string;
        activatingEventsOhers:string;
        clinicalStatusOhers:string;
    }
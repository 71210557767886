import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import * as moment from 'moment';
import { PatientService } from 'src/app/patient/patient.service';
import { chmadetails, dtoChmaTreatmentSummary } from 'src/app/_models/_Patient/_CHMADetails';
import { IntakeCmhaComponent } from 'src/app/patient/intake/intake-cmha/intake-cmha.component';
import { chmaEditPatientDetails } from 'src/app/_models/_Patient/_ChmaEditPatientDetails';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-cmha-treatmentplan',
  templateUrl: './cmha-treatmentplan.component.html',
  styleUrls: ['./cmha-treatmentplan.component.scss']
})
export class CmhaTreatmentplanComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  isMovedChma: boolean;
  isAssigned : boolean;
  @ViewChild('taskfile') eletaskfile: ElementRef;
  patientInfoId: any;
  patientAccountId: any;
  userId: any;
  userName: any;
  clinicalsummary: any;
  clinicalsummaryList: any;
  providerName: any;
  lstCHMADetails: any;
  clinicianDetails: any;
  UploadFileList = [];
  FilePath: any;
  fileType: any;
  primaryClinicianId: any;
  treatmentPlanDate: any;
  overarchingPeriod: any;
  secondaryClinicianId: any;
  treatmentPlanDateSecondary: any;
  overarchingPeriodSecondary: any;
  asebareportPath: any;
  UploadedFileList = [];
  istreatmentPlanDate: any;
  istreatmentPlanDateSecondary: any;
  overarchingDateExist :boolean = false;
  overarchingDateExistSecondary :boolean = false;
  isprimaryClinician: boolean = false;
  isSecondaryClinician: boolean = false;
  currentdate: any;
  patientDetails= new chmaEditPatientDetails;
  roleAccess: RoelAccess;
  isDateValidate : boolean =  false;
  isDateValidate1 : boolean =  false;
  isClinicianSame : boolean = false;
  IsPatientDischargeChma : boolean;
  constructor(public global: Global, public patientservice: PatientService, private chma: IntakeCmhaComponent,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.PrepareCHMA;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.currentdate = new Date();
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.userId = Number(localStorage.getItem('id'));
    this.userName = localStorage.getItem('username');

    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.isMovedChma =this.commonService.FrontEndDecryption(params.isChma) == 0 ? false : true;
        this.isAssigned = this.isMovedChma;
      }

     

    });

    this.checkIsRejoiner(this.patientAccountId);
      
    // if(this.IsPatientDischargeChma == true)
    //   {
    //     this.isAssigned = false;
    //     this.isMovedChma = false;
    //   }


    this.clinicalsummary = new dtoChmaTreatmentSummary();
    this.getChmaTreatmentSummaryDetails();

  }


  

  getChmaTreatmentSummaryDetails() {
    this.spinnerservice.show();
    this.patientservice.getChmaTreatmentSummaryDetails(this.patientAccountId).subscribe(
      (res: any) => {
        if (res != null) {
          this.clinicianDetails = res.dtoPrimaryClinicianList;
          this.clinicalsummary = res.dtoChmaTreatmentSummary;
          this.clinicalsummaryList = this.clinicalsummary.slice(0, 5);
          this.dataSource = new MatTableDataSource(this.clinicalsummaryList);
          this.dataSource.paginator = this.paginator;
          if (res.dtoChmaTreatmentSummary != '' && res.dtoChmaTreatmentSummary != undefined && this.IsPatientDischargeChma != true) {
            this.treatmentPlanDate = this.clinicalsummary.treatmentPlanDate;
            this.primaryClinicianId = this.clinicalsummary.primaryClinicianId;
            this.secondaryClinicianId = this.clinicalsummary.secondaryClinicianId;
            this.treatmentPlanDateSecondary = this.clinicalsummary.treatmentPlanDateSecondary;
            this.overarchingDateExistSecondary = this.clinicalsummary.overarchingDateExistSecondary;
            this.asebareportPath = this.clinicalsummary.asebareport;
            this.isAssigned = true;
            this.overarchingPeriod = (this.clinicalsummary.overarchingPeriod == null)? this.onDateChange() : this.clinicalsummary.overarchingPeriod;
            this.overarchingPeriodSecondary = (this.clinicalsummary.overarchingPeriodSecondary == null)? this.onDateChangeSecondary() : this.clinicalsummary.overarchingPeriodSecondary;
          }
          else {
            this.treatmentPlanDate = '';
            this.primaryClinicianId = '';
            this.asebareportPath = '';
            // this.secondaryClinicianId = '';
            // this.treatmentPlanDateSecondary ='';
            // this.overarchingPeriodSecondary = '';
          }
          if (res.documents) {
            this.UploadedFileList = res.documents;
          }
          this.spinnerservice.hide();
        } else {
          this.clinicalsummary = "";
          this.providerName = this.userName;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onPageChange($event) {
    this.clinicalsummaryList = this.clinicalsummary.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }

  onSaveChmadetails() {

    if (this.primaryClinicianId == "" || this.primaryClinicianId == undefined) {
      this.isprimaryClinician = true;
      return
    }
    

    if (this.treatmentPlanDate == "" || this.treatmentPlanDate == undefined) {
      this.istreatmentPlanDate = true;
      return
    }
    if (this.overarchingPeriod == "" || this.overarchingPeriod == undefined) {
      this.overarchingDateExist = true;
      return
    }
    if(this.primaryClinicianId == this.secondaryClinicianId)
    {
      this.isClinicianSame = true;
      return
    }
    if((this.secondaryClinicianId != "" && this.secondaryClinicianId != undefined)   || (this.treatmentPlanDateSecondary != "" && this.treatmentPlanDateSecondary != undefined) || (this.overarchingPeriodSecondary != "" && this.overarchingPeriodSecondary != undefined ))
    {
      if (this.secondaryClinicianId == "" || this.secondaryClinicianId == undefined ) {
        this.isSecondaryClinician = true;
        return
      }
      if (this.treatmentPlanDateSecondary == "" || this.treatmentPlanDateSecondary == undefined) {
        this.istreatmentPlanDateSecondary = true;
        return
      }
      if (this.overarchingPeriodSecondary == "" || this.overarchingPeriodSecondary == undefined) {
        this.overarchingDateExistSecondary = true;
        return
      }
    }
    if(this.isDateValidate || this.isDateValidate1)
    {
      return
    }
  
    if (this.clinicalsummary == '' || this.clinicalsummary == undefined) {
      this.clinicalsummary = new dtoChmaTreatmentSummary();
    }

    if ((this.FilePath != '' || this.FilePath != undefined)) {
      this.clinicalsummary.asebareport = this.FilePath;
    }
    if(this.IsPatientDischargeChma == true) 
      {
        this.clinicalsummary.asebareport = '';
        this.clinicalsummary = new dtoChmaTreatmentSummary();
      }
    this.clinicalsummary.primaryClinicianId = this.primaryClinicianId;
    this.clinicalsummary.treatmentPlanDate = this.treatmentPlanDate;
    this.clinicalsummary.overarchingPeriod = this.overarchingPeriod;
    this.clinicalsummary.secondaryClinicianId = this.secondaryClinicianId;
    this.clinicalsummary.treatmentPlanDateSecondary = this.treatmentPlanDateSecondary;
    this.clinicalsummary.overarchingPeriodSecondary = this.overarchingPeriodSecondary;
    this.clinicalsummary.createdBy = this.userId;
    this.clinicalsummary.updatedBy = this.userId;
    this.clinicalsummary.documents = this.UploadedFileList;
    this.clinicalsummary.patientAccountId = Number(this.patientAccountId);
    this.clinicalsummary.mtpname = constantVariables.lblMessage.TreatmentPlan;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.clinicalsummary, false);
    this.patientservice.postChmaTreatmentSummaryDeatils(dataToEncrypt).subscribe(
      (res: any) => {
        this.isAssigned = true;
        this.spinnerservice.hide();
        this.onSaveChmaStatus();
        this.snackbar.success(JSON.parse(res).message);
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }

  onSaveChmaStatus() {
    let _obj = {
      PatientAccountId: this.patientAccountId,
      FormType: 7,
      patientdetails:this.patientDetails
    }
    // const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.patientservice.postChmaStatus(_obj).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.chma.onbacktoMytask();
        this.chma.getChmaStatus();
        if(this.chma.isTreatmentSummary == 1){
          this.isAssigned = true;
        }

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onPrevcmhatab() {
    this.chma.onTabChange(6);
  }

  onFileChange(event) {
    const reader = new FileReader();
    var _validFileExtensions = [".csv", ".pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (file.type != '') {
        this.fileType = file.type;
      }
      else {
        var fileExt = file.name.split(".");
        this.fileType = fileExt[1];
      }
      if ((Array.from(event.target.files).length + this.UploadedFileList?.length) <= 3) {
        if (this.fileType == 'csv' || this.fileType == 'application/pdf' || this.fileType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || this.fileType == 'application/vnd.ms-excel') {
          reader.readAsDataURL(file);

          reader.onload = () => {

            this.UploadFileList = [];
            var files = Array.from(event.target.files);
            for (var i = 0; i < files.length; i++) {

              this.UploadFileList.push(files[i]);

            }
            const frmData = new FormData();
            for (var i = 0; i < this.UploadFileList.length; i++) {
              frmData.append("doc", this.UploadFileList[i]);
            }
            frmData.append('DocumentTypeId', constantVariables.DocumentTypeId.TreatmentSummary);
            this.spinnerservice.show();
            this.patientservice.uploadChmaTreatmentSummaryDeatils(frmData).subscribe((res: any) => {

              if (res) {
                this.eletaskfile.nativeElement.value = "";
                this.spinnerservice.hide();
                if (this.UploadedFileList?.length == 0) {
                  this.UploadedFileList = res;
                } else {
                  this.UploadedFileList.push(...res);
                }

              }
            }, err => {
              this.spinnerservice.hide();
              this.snackbar.error(err.error);
            });
            this.eletaskfile.nativeElement.value = "";
          };
        } else {
          this.eletaskfile.nativeElement.value = "";
          this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
        }
      } else {
        this.eletaskfile.nativeElement.value = "";
        this.snackbar.error("Maximum 3 files allowed")
      }
    }
  }

  deleteUploadImage(index) {
    this.UploadedFileList.splice(index, 1);
    this.eletaskfile.nativeElement.value = "";
  }

  downloadUploadedFile(item) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', item.filePath);
    link.setAttribute('download', item.filename);
    document.body.appendChild(link);
    link.click();
    link.remove();

  }
  onDateChange() {
    this.istreatmentPlanDate = false;
    console.log("as")
    if (this.treatmentPlanDate != null && this.treatmentPlanDate != undefined && this.treatmentPlanDate != "") {
      //this.overarchingDate = moment(this.treatmentPlanDate).add(90, 'days').format('LL');
      this.overarchingPeriod = moment(this.treatmentPlanDate).add(90, 'days').format("YYYY-MM-DD HH:mm:ss");
      this.overarchingDateExist = false;
      this.checkValidation(this.treatmentPlanDate,this.overarchingPeriod,1);
      return this.overarchingPeriod;
    }
    else {
      return "";
    }
  }
  checkValidation(treatmentPlanDate,overarchingDate,dateValidate){
   
    if( new Date(overarchingDate) <= new Date(treatmentPlanDate))
    {
      if(dateValidate == 1)
      {
        this.isDateValidate = true;
      }else if(dateValidate == 2){
        this.isDateValidate1 = true;
      }
      
    }else{
      if(dateValidate == 1)
      {
        this.isDateValidate = false;
      }else if(dateValidate == 2){
        this.isDateValidate1 = false;
      }
    }
    //this.mtpValidation(treatmentPlanDate, overarchingDate);
  }
  

  onDateChangeSecondary() {
    this.istreatmentPlanDateSecondary = false;
    console.log("as")
    if (this.treatmentPlanDateSecondary != null && this.treatmentPlanDateSecondary != undefined && this.treatmentPlanDateSecondary != "") {
      //this.overarchingDate = moment(this.treatmentPlanDate).add(90, 'days').format('LL');
      this.overarchingPeriodSecondary = moment(this.treatmentPlanDateSecondary).add(90, 'days').format("YYYY-MM-DD HH:mm:ss");
      this.overarchingDateExistSecondary = false;
      this.checkValidation(this.treatmentPlanDateSecondary,this.overarchingPeriodSecondary,2);
      return this.overarchingPeriodSecondary;
    }
    else {
      return "";
    }
  }
  errormsgChange(){
    this.isprimaryClinician = false;
    this.isClinicianSame = false;

  }
  errormsgChangeSecondary(){
    this.isSecondaryClinician = false;
    this.isClinicianSame = false;
  }

  checkIsRejoiner(PatientAccountId)
  {
  
      this.spinnerservice.show();
      this.patientservice.getDischargePatientinChma(this.patientAccountId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          if (res.patientAccountId>0) 
          {
            this.IsPatientDischargeChma = true;
            this.isAssigned = false;
            this.isMovedChma = false;
            
          } 
          else 
          {
            this.IsPatientDischargeChma = false;
          }
  
        },
        err => {
          this.spinnerservice.hide();
        },
      );
    

  }

}

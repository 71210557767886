
<div class="aggr-goal-sec  col-md-12  pb-3" >
    <div class="">
        <div class="position-relative d-flex">
            <div class="col-md-12 p-0">
        <mat-tab-group (selectedTabChange)="OntabChange($event);">
        <mat-tab label="Individual Therapy" *ngIf="ShowIndividualTherapy" >
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(IndPercentageGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Percentage Based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.IndPercentageGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index" >
                                <div class="d-flex w-100 ">
                                    <!--   -->
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"   (click)="OnselectTrackbenchmark(bench,item)" > {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(IndTimeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Time Based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.IndTimeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                <div class="ml-auto">
                            </div>
                                </div>
                                <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(IndExchangeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of exchanges based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.IndExchangeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(IndActivityGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of initiation per activity based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.IndActivityGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(IndResponseGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of responses based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.IndResponseGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(IndOthers?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Others</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.IndOthers">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
        </mat-tab>
        <mat-tab label="Family Therapy" *ngIf="ShowFamilyTherapy" (click)="OntabChange()">
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(FamPercentageGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Percentage Based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.FamPercentageGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)"> Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(FamTimeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Time Based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.FamTimeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"  > {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(FamExchangeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of exchanges based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.FamExchangeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(FamActivityGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of initiation per activity based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.FamActivityGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(FamResponseGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of responses based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.FamResponseGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(FamOthers?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Others</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.FamOthers">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"> </i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
        </mat-tab>
        <mat-tab label="Group Therapy" *ngIf="ShowGroupTherapy" (click)="OntabChange()">
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(GrpPercentageGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Percentage Based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.GrpPercentageGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(GrpTimeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Time Based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.GrpTimeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(GrpExchangeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of exchanges based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.GrpExchangeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(GrpActivityGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of initiation per activity based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.GrpActivityGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(GrpResponseGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of responses based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.GrpResponseGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(GrpOthers?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Others</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.GrpOthers">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">                            
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
          </mat-tab>
        <mat-tab label="Psychiatric Therapy" *ngIf="ShowPsyividualTherapy" >
                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyPercentageGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Percentage Based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of PsyPercentageGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index" >
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"   (click)="OnselectTrackbenchmark(bench,item)"  > {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                             </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyTimeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Time Based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of PsyTimeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                <div class="ml-auto">
                            </div>
                                </div>
                                <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                             </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyExchangeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of exchanges based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of PsyExchangeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyActivityGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of initiation per activity based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of PsyActivityGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                           </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyResponseGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of responses based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of PsyResponseGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                              </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyOthers?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Others</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of PsyOthers">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                              
                              </mat-radio-group>
                        </div>
                    </div>
                </div>
        </mat-tab>
    <!-- <mat-tab label="Psychiatric Evaluation" *ngIf="ShowPsyEvaTherapy" >
                        <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyEvaPercentageGoals?.length==0)">
                            <h2 class="w-100 mt-3">Percentage Based</h2>
                            <div class="goal-percen mb-2" *ngFor="let item of this.PsyEvaPercentageGoals">
                                <div class="d-flex">
                                    <div class="goal-name">
                                        <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                    </div>
                                    <div class="ml-auto">
                                        
                                    </div>
                                </div>
                                
                                <div class="goal-name">
                                    <label class="mb-1">Description:</label>
                                    <div class="value-goal">
                                        {{item.definition}}
                                    </div>
                                </div>
                                <div class="options-goal mt-2" >
                                    <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                        <div  *ngFor="let bench of item.measures; let i=index" >
                                        <div class="d-flex w-100 ">
                                            <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"   (click)="OnselectTrackbenchmark(bench,item)"  > {{bench.benchmarktitle}} </mat-radio-button>
                                            <div class="ml-auto">
                                        </div>
                                            </div>
                                            <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                            <div class="col-md-12 ben-head position-relative">
                                                <div  class="row">
                                                    <div  class="col-md-8 ben_des">
                                                        <div  class="ben_tit"> Benchmark Description </div>
                                                         {{bench.benchmarkdiscription}} </div>
                                                        <div class="col-md-2">
                                                            <div class="goal-stat-chang mt-3">
                                                                <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                            </div>
                                                        </div>
                                                         <div  class="col-md-2 ben_unit pr-4">
                                                             <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                             <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
        
                                                             </div>
                                                            </div>
                                                        </div>
                                        </div>
                                    </div>
                                     </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyEvaTimeGoals?.length==0)">
                            <h2 class="w-100 mt-3">Time Based</h2>
                            <div class="goal-percen mb-2" *ngFor="let item of this.PsyEvaTimeGoals">
                                <div class="d-flex">
                                    <div class="goal-name">
                                        <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                    </div>
                                    <div class="ml-auto">
                                        
                                    </div>
                                </div>
                                
                                <div class="goal-name">
                                    <label class="mb-1">Description:</label>
                                    <div class="value-goal">
                                        {{item.definition}}
                                    </div>
                                </div>
                                <div class="options-goal mt-2" >
                                    <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                        <div  *ngFor="let bench of item.measures; let i=index">
                                        <div class="d-flex w-100 ">
                                        <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                        <div class="ml-auto">
                                    </div>
                                        </div>
                                        <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                            <div class="col-md-12 ben-head position-relative">
                                                <div  class="row">
                                                    <div  class="col-md-8 ben_des">
                                                        <div  class="ben_tit"> Benchmark Description </div>
                                                         {{bench.benchmarkdiscription}} </div>
                                                        <div class="col-md-2">
                                                            <div class="goal-stat-chang mt-3">
                                                                <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                            </div>
                                                        </div>
                                                         <div  class="col-md-2 ben_unit pr-4">
                                                             <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                             <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
        
                                                             </div>
                                                            </div>
                                                        </div>
                                        </div>
                                    </div>
                                     </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyEvaExchangeGoals?.length==0)">
                            <h2 class="w-100 mt-3">Number of exchanges based</h2>
                            <div class="goal-percen mb-2" *ngFor="let item of this.PsyEvaExchangeGoals">
                                <div class="d-flex">
                                    <div class="goal-name">
                                        <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                    </div>
                                    <div class="ml-auto">
                                        
                                    </div>
                                </div>
                                
                                <div class="goal-name">
                                    <label class="mb-1">Description:</label>
                                    <div class="value-goal">
                                        {{item.definition}}
                                    </div>
                                </div>
                                <div class="options-goal mt-2" >
                                    <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                        <div  *ngFor="let bench of item.measures; let i=index">
                                        <div class="d-flex w-100 ">
                                            <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                            <div class="ml-auto">
                                        </div>
                                            </div>
                                            <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                            <div class="col-md-12 ben-head position-relative">
                                                <div  class="row">
                                                    <div  class="col-md-8 ben_des">
                                                        <div  class="ben_tit"> Benchmark Description </div>
                                                         {{bench.benchmarkdiscription}} </div>
                                                        <div class="col-md-2">
                                                            <div class="goal-stat-chang mt-3">
                                                                <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                            </div>
                                                        </div>
                                                         <div  class="col-md-2 ben_unit pr-4">
                                                             <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                             <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
        
                                                             </div>
                                                            </div>
                                                        </div>
                                        </div>
                                    </div>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyEvaActivityGoals?.length==0)">
                            <h2 class="w-100 mt-3">Number of initiation per activity based</h2>
                            <div class="goal-percen mb-2" *ngFor="let item of this.PsyEvaActivityGoals">
                                <div class="d-flex">
                                    <div class="goal-name">
                                        <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                    </div>
                                    <div class="ml-auto">
                                        
                                    </div>
                                </div>
                                
                                <div class="goal-name">
                                    <label class="mb-1">Description:</label>
                                    <div class="value-goal">
                                        {{item.definition}}
                                    </div>
                                </div>
                                <div class="options-goal mt-2" >
                                    <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                        <div  *ngFor="let bench of item.measures; let i=index">
                                        <div class="d-flex w-100 ">
                                            <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                            <div class="ml-auto">
                                        </div>
                                            </div>
                                            <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                            <div class="col-md-12 ben-head position-relative">
                                                <div  class="row">
                                                    <div  class="col-md-8 ben_des">
                                                        <div  class="ben_tit"> Benchmark Description </div>
                                                         {{bench.benchmarkdiscription}} </div>
                                                        <div class="col-md-2">
                                                            <div class="goal-stat-chang mt-3">
                                                                <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                            </div>
                                                        </div>
                                                         <div  class="col-md-2 ben_unit pr-4">
                                                             <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                             <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
        
                                                             </div>
                                                            </div>
                                                        </div>
                                        </div>
                                    </div>
                                   </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyEvaResponseGoals?.length==0)">
                            <h2 class="w-100 mt-3">Number of responses based</h2>
                            <div class="goal-percen mb-2" *ngFor="let item of this.PsyEvaResponseGoals">
                                <div class="d-flex">
                                    <div class="goal-name">
                                        <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                    </div>
                                    <div class="ml-auto">
                                        
                                    </div>
                                </div>
                                
                                <div class="goal-name">
                                    <label class="mb-1">Description:</label>
                                    <div class="value-goal">
                                        {{item.definition}}
                                    </div>
                                </div>
                                <div class="options-goal mt-2" >
                                    <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                        <div  *ngFor="let bench of item.measures; let i=index">
                                        <div class="d-flex w-100 ">
                                            <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                            <div class="ml-auto">
                                        </div>
                                            </div>
                                            <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                            <div class="col-md-12 ben-head position-relative">
                                                <div  class="row">
                                                    <div  class="col-md-8 ben_des">
                                                        <div  class="ben_tit"> Benchmark Description </div>
                                                         {{bench.benchmarkdiscription}} </div>
                                                        <div class="col-md-2">
                                                            <div class="goal-stat-chang mt-3">
                                                                <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                            </div>
                                                        </div>
                                                         <div  class="col-md-2 ben_unit pr-4">
                                                             <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                             <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
        
                                                             </div>
                                                            </div>
                                                        </div>
                                        </div>
                                    </div>
                                      </mat-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyEvaOthers?.length==0)">
                            <h2 class="w-100 mt-3">Others</h2>
                            <div class="goal-percen mb-2" *ngFor="let item of this.PsyEvaOthers">
                                <div class="d-flex">
                                    <div class="goal-name">
                                        <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                    </div>
                                    <div class="ml-auto">
                                        
                                    </div>
                                </div>
                                
                                <div class="goal-name">
                                    <label class="mb-1">Description:</label>
                                    <div class="value-goal">
                                        {{item.definition}}
                                    </div>
                                </div>
                                <div class="options-goal mt-2" >
                                    <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                        <div  *ngFor="let bench of item.measures; let i=index">
                                        <div class="d-flex w-100 ">
                                            <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                            <div class="ml-auto">
                                        </div>
                                            </div>
                                            <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                            <div class="col-md-12 ben-head position-relative">
                                                <div  class="row">
                                                    <div  class="col-md-8 ben_des">
                                                        <div  class="ben_tit"> Benchmark Description </div>
                                                         {{bench.benchmarkdiscription}} </div>
                                                        <div class="col-md-2">
                                                            <div class="goal-stat-chang mt-3">
                                                                <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                            </div>
                                                        </div>
                                                         <div  class="col-md-2 ben_unit pr-4">
                                                             <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                             <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
        
                                                             </div>
                                                            </div>
                                                        </div>
                                        </div>
                                    </div>
                                      
                                      </mat-radio-group>
                                </div>
                            </div>
                        </div>
                            </mat-tab> -->
         <mat-tab label="Psychiatric Medication" *ngIf="ShowPsyMedTherapy" >
                                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyMedPercentageGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                                    <h2 class="w-100 mt-3">Percentage Based</h2>
                                    <div class="goal-percen mb-2" *ngFor="let item of this.PsyMedPercentageGoals">
                                        <div class="d-flex">
                                            <div class="goal-name">
                                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                            </div>
                                            <div class="ml-auto">
                                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                                            </div>
                                        </div>
                                        
                                        <div class="goal-name">
                                            <label class="mb-1">Description:</label>
                                            <div class="value-goal">
                                                {{item.definition}}
                                            </div>
                                        </div>
                                        <div class="options-goal mt-2" >
                                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                                <div  *ngFor="let bench of item.measures; let i=index" >
                                                <div class="d-flex w-100 ">
                                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"   (click)="OnselectTrackbenchmark(bench,item)"  > {{bench.benchmarktitle}} </mat-radio-button>
                                                    <div class="ml-auto">
                                                </div>
                                                    </div>
                                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                                    <div class="col-md-12 ben-head position-relative">
                                                        <div  class="row">
                                                            <div  class="col-md-8 ben_des">
                                                                <div  class="ben_tit"> Benchmark Description </div>
                                                                 {{bench.benchmarkdiscription}} </div>
                                                                <div class="col-md-2">
                                                                    <div class="goal-stat-chang mt-3">
                                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                                    </div>
                                                                </div>
                                                                 <div  class="col-md-2 ben_unit pr-4">
                                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
                
                                                                     </div>
                                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                                    </div>
                                                                </div>
                                                </div>
                                            </div>
                                             </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyMedTimeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                                    <h2 class="w-100 mt-3">Time Based</h2>
                                    <div class="goal-percen mb-2" *ngFor="let item of this.PsyMedTimeGoals">
                                        <div class="d-flex">
                                            <div class="goal-name">
                                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                            </div>
                                            <div class="ml-auto">
                                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                                            </div>
                                        </div>
                                        
                                        <div class="goal-name">
                                            <label class="mb-1">Description:</label>
                                            <div class="value-goal">
                                                {{item.definition}}
                                            </div>
                                        </div>
                                        <div class="options-goal mt-2" >
                                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                                <div  *ngFor="let bench of item.measures; let i=index">
                                                <div class="d-flex w-100 ">
                                                <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                                <div class="ml-auto">
                                            </div>
                                                </div>
                                                <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                                    <div class="col-md-12 ben-head position-relative">
                                                        <div  class="row">
                                                            <div  class="col-md-8 ben_des">
                                                                <div  class="ben_tit"> Benchmark Description </div>
                                                                 {{bench.benchmarkdiscription}} </div>
                                                                <div class="col-md-2">
                                                                    <div class="goal-stat-chang mt-3">
                                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                                    </div>
                                                                </div>
                                                                 <div  class="col-md-2 ben_unit pr-4">
                                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
                
                                                                     </div>
                                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                                    </div>
                                                                </div>
                                                </div>
                                            </div>
                                             </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyMedExchangeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                                    <h2 class="w-100 mt-3">Number of exchanges based</h2>
                                    <div class="goal-percen mb-2" *ngFor="let item of this.PsyMedExchangeGoals">
                                        <div class="d-flex">
                                            <div class="goal-name">
                                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                            </div>
                                            <div class="ml-auto">
                                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                                            </div>
                                        </div>
                                        
                                        <div class="goal-name">
                                            <label class="mb-1">Description:</label>
                                            <div class="value-goal">
                                                {{item.definition}}
                                            </div>
                                        </div>
                                        <div class="options-goal mt-2" >
                                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                                <div  *ngFor="let bench of item.measures; let i=index">
                                                <div class="d-flex w-100 ">
                                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                                    <div class="ml-auto">
                                                </div>
                                                    </div>
                                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                                    <div class="col-md-12 ben-head position-relative">
                                                        <div  class="row">
                                                            <div  class="col-md-8 ben_des">
                                                                <div  class="ben_tit"> Benchmark Description </div>
                                                                 {{bench.benchmarkdiscription}} </div>
                                                                <div class="col-md-2">
                                                                    <div class="goal-stat-chang mt-3">
                                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                                    </div>
                                                                </div>
                                                                 <div  class="col-md-2 ben_unit pr-4">
                                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
                
                                                                     </div>
                                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                                    </div>
                                                                </div>
                                                </div>
                                            </div>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyMedActivityGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                                    <h2 class="w-100 mt-3">Number of initiation per activity based</h2>
                                    <div class="goal-percen mb-2" *ngFor="let item of this.PsyMedActivityGoals">
                                        <div class="d-flex">
                                            <div class="goal-name">
                                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                            </div>
                                            <div class="ml-auto">
                                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                                            </div>
                                        </div>
                                        
                                        <div class="goal-name">
                                            <label class="mb-1">Description:</label>
                                            <div class="value-goal">
                                                {{item.definition}}
                                            </div>
                                        </div>
                                        <div class="options-goal mt-2" >
                                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                                <div  *ngFor="let bench of item.measures; let i=index">
                                                <div class="d-flex w-100 ">
                                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                                    <div class="ml-auto">
                                                </div>
                                                    </div>
                                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                                    <div class="col-md-12 ben-head position-relative">
                                                        <div  class="row">
                                                            <div  class="col-md-8 ben_des">
                                                                <div  class="ben_tit"> Benchmark Description </div>
                                                                 {{bench.benchmarkdiscription}} </div>
                                                                <div class="col-md-2">
                                                                    <div class="goal-stat-chang mt-3">
                                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                                    </div>
                                                                </div>
                                                                 <div  class="col-md-2 ben_unit pr-4">
                                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
                
                                                                     </div>
                                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                                    </div>
                                                                </div>
                                                </div>
                                            </div>
                                           </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyMedResponseGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                                    <h2 class="w-100 mt-3">Number of responses based</h2>
                                    <div class="goal-percen mb-2" *ngFor="let item of this.PsyMedResponseGoals">
                                        <div class="d-flex">
                                            <div class="goal-name">
                                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                            </div>
                                            <div class="ml-auto">
                                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                                            </div>
                                        </div>
                                        
                                        <div class="goal-name">
                                            <label class="mb-1">Description:</label>
                                            <div class="value-goal">
                                                {{item.definition}}
                                            </div>
                                        </div>
                                        <div class="options-goal mt-2" >
                                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                                <div  *ngFor="let bench of item.measures; let i=index">
                                                <div class="d-flex w-100 ">
                                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                                    <div class="ml-auto">
                                                </div>
                                                    </div>
                                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                                    <div class="col-md-12 ben-head position-relative">
                                                        <div  class="row">
                                                            <div  class="col-md-8 ben_des">
                                                                <div  class="ben_tit"> Benchmark Description </div>
                                                                 {{bench.benchmarkdiscription}} </div>
                                                                <div class="col-md-2">
                                                                    <div class="goal-stat-chang mt-3">
                                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                                    </div>
                                                                </div>
                                                                 <div  class="col-md-2 ben_unit pr-4">
                                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
                
                                                                     </div>
                                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                                    </div>
                                                                </div>
                                                </div>
                                            </div>
                                              </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 inputWrapper p-0" *ngIf="!(PsyMedOthers?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                                    <h2 class="w-100 mt-3">Others</h2>
                                    <div class="goal-percen mb-2" *ngFor="let item of this.PsyMedOthers">
                                        <div class="d-flex">
                                            <div class="goal-name">
                                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                                            </div>
                                            <div class="ml-auto">
                                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                                            </div>
                                        </div>
                                        
                                        <div class="goal-name">
                                            <label class="mb-1">Description:</label>
                                            <div class="value-goal">
                                                {{item.definition}}
                                            </div>
                                        </div>
                                        <div class="options-goal mt-2" >
                                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                                <div  *ngFor="let bench of item.measures; let i=index">
                                                <div class="d-flex w-100 ">
                                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                                    <div class="ml-auto">
                                                </div>
                                                    </div>
                                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                                    <div class="col-md-12 ben-head position-relative">
                                                        <div  class="row">
                                                            <div  class="col-md-8 ben_des">
                                                                <div  class="ben_tit"> Benchmark Description </div>
                                                                 {{bench.benchmarkdiscription}} </div>
                                                                <div class="col-md-2">
                                                                    <div class="goal-stat-chang mt-3">
                                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                                    </div>
                                                                </div>
                                                                 <div  class="col-md-2 ben_unit pr-4">
                                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>
                
                                                                     </div>
                                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                                    </div>
                                                                </div>
                                                </div>
                                            </div>                                             
                                              </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
         </mat-tab>
         <mat-tab label="Speech Therapy" *ngIf="ShowSpeechTherapy" >
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(SpeechPercentageGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
            <h2 class="w-100 mt-3">Percentage Based</h2>
            <div class="goal-percen mb-2" *ngFor="let item of this.SpeechPercentageGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index" >
                                <div class="d-flex w-100 ">
                                    <!--   -->
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"   (click)="OnselectTrackbenchmark(bench,item)" > {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
            </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(SpeechTimeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Time Based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.SpeechTimeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                <div class="ml-auto">
                            </div>
                                </div>
                                <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(SpeechExchangeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of exchanges based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.SpeechExchangeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(SpeechActivityGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of initiation per activity based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.SpeechActivityGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(SpeechResponseGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of responses based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.SpeechResponseGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(SpeechOthers?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Others</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.SpeechOthers">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
        </mat-tab>
        <mat-tab label="Nutrition Therapy" *ngIf="ShowNutritionTherapy" >
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(NutritionPercentageGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
            <h2 class="w-100 mt-3">Percentage Based</h2>
            <div class="goal-percen mb-2" *ngFor="let item of this.NutritionPercentageGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index" >
                                <div class="d-flex w-100 ">
                                    <!--   -->
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"   (click)="OnselectTrackbenchmark(bench,item)" > {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
            </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(NutritionTimeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Time Based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.NutritionTimeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                <div class="ml-auto">
                            </div>
                                </div>
                                <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(NutritionExchangeGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of exchanges based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.NutritionExchangeGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(NutritionActivityGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of initiation per activity based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.NutritionActivityGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(NutritionResponseGoals?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Number of responses based</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.NutritionResponseGoals">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
            <div class="col-md-12 inputWrapper p-0" *ngIf="!(NutritionOthers?.length==0)" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
                    <h2 class="w-100 mt-3">Others</h2>
                    <div class="goal-percen mb-2" *ngFor="let item of this.NutritionOthers">
                        <div class="d-flex">
                            <div class="goal-name">
                                <label class="mr-2">Goal:</label><span class="value-goal">{{item.goalName}}</span>
                            </div>
                            <div class="ml-auto">
                                <button class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(item)">Add Benchmark</button>
                            </div>
                        </div>
                        
                        <div class="goal-name">
                            <label class="mb-1">Description:</label>
                            <div class="value-goal">
                                {{item.definition}}
                            </div>
                        </div>
                        <div class="options-goal mt-2" >
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="item.selectedTrackbenchId">
                                <div  *ngFor="let bench of item.measures; let i=index">
                                <div class="d-flex w-100 ">
                                    <mat-radio-button class="w-100"  [value]="bench.trackBenchmarkId"    (click)="OnselectTrackbenchmark(bench,item)"> {{bench.benchmarktitle}} </mat-radio-button>
                                    <div class="ml-auto">
                                </div>
                                    </div>
                                    <div class="goal-desc-expand" *ngIf="bench.trackBenchIsSelected">
                                    <div class="col-md-12 ben-head position-relative">
                                        <div  class="row">
                                            <div  class="col-md-8 ben_des">
                                                <div  class="ben_tit"> Benchmark Description </div>
                                                 {{bench.benchmarkdiscription}} </div>
                                                <div class="col-md-2">
                                                    <div class="goal-stat-chang mt-3">
                                                        <span class="status-tabl inprogress" *ngIf="bench.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                        <span class="status-tabl completed" *ngIf="bench.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                                    </div>
                                                </div>
                                                 <div  class="col-md-2 ben_unit pr-4">
                                                     <p  class="text-muted text-xs">Unit of Measure</p> {{bench.benchmarkunit}} </div>
                                                     <div class="edit-float" *ngIf="!isGoalConfigDisabled">
                                                        <a><i class="fas fa-edit" (click)="OnEditBenchmark(bench,item)"></i></a>

                                                     </div>
                                                     <div  class="delete-float" *ngIf="bench.status==1">
                                                        <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(bench.trackBenchmarkId)"></i></a></div>
                                                    </div>
                                                </div>
                                </div>
                            </div>
                                <!--<mat-radio-button class="w-100" value="2">Drink 4 lit of water</mat-radio-button>
                                <mat-radio-button class="w-100" value="3">Eat fruits and vegetables that are low in calories & fat</mat-radio-button>-->
                              </mat-radio-group>
                        </div>
                    </div>
            </div>
        </mat-tab>
        </mat-tab-group>
    </div>
        <div class="progress-btn-logs floating-progress-btn ml-auto">
            <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button>Progress Logs</button>
            <button
            type="button"
            class="btn primary-btn-filled primary-btn-m mb-2 h-40 float-right"
            (click)="generatePdf()"
          >
            Generate PDF
          </button>
        </div>
      
    </div>


</div>
<div class="benchmark-popup">
    <div class="modal" tabindex="-1" role="dialog" [style.display]="EditBenchmark ? 'block' : 'none'">
      <div class="modal-dialog" role="document">
        <form (ngSubmit)="Benchmarkfrm.form.valid " id="Benchmarkfrm" autocomplete="off" #Benchmarkfrm="ngForm"
        novalidate>
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Benchmark </h6>
            <button type="button" class="close"  data-dismiss="modal"
              aria-label="Close" (click)="onClosemodal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
   
        <div class="benchmark-data col-md-12">
      <div class="col-md-12 p-0 form-group">
        <mat-form-field class="w-100">
            <mat-label>Benchmark Title</mat-label>
            <input matInput  maxlength="50"
            [(ngModel)]="Benchmarkfrminfo.Benchmarktitle" #Benchmarktitle="ngModel" name="Benchmarktitle"
            [ngClass]="{ 'is-invalid': Benchmarkfrm.submitted && Benchmarktitle.invalid }"
            required>
            <mat-error *ngIf="Benchmarkfrm.submitted && Benchmarktitle.invalid">
                <mat-error class="text-xs" *ngIf="Benchmarktitle.invalid">
                    Benchmark Title is required</mat-error>
            </mat-error> 
        </mat-form-field>
      
      </div>
      <div class="col-md-12 p-0 form-group">
        <mat-form-field class="w-100">
            <mat-label>Benchmark Description</mat-label>
            <textarea matInput maxlength="1000" [(ngModel)]="Benchmarkfrminfo.Benchmarkdiscription"
            #Benchmarkdiscription="ngModel" name="Benchmarkdiscription"
            [ngClass]="{ 'is-invalid': Benchmarkfrm.submitted && Benchmarkdiscription.invalid }" required></textarea>
            <mat-error *ngIf="Benchmarkfrm.submitted && Benchmarkdiscription.invalid">
                <mat-error class="text-xs" *ngIf="Benchmarkdiscription.invalid">
                    Benchmark description is required</mat-error>
            </mat-error> 
          </mat-form-field>
        
      </div>
      <div class="col-md-12 p-0">
          <div class="row">
            <div class="col-md-6  form-group">
                <mat-form-field class="w-75">
                    <mat-label>Unit of Measure</mat-label>
                    <input matInput [(ngModel)]="Benchmarkfrminfo.Benchmarkunit" percentage
                    #Benchmarkunit="ngModel" name="Benchmarkunit"
                    [ngClass]="{ 'is-invalid': Benchmarkfrm.submitted && Benchmarkunit.invalid }"
                    required step="0" maxlength="4" customMin="1">
                    <mat-error *ngIf="Benchmarkfrm.submitted && Benchmarkunit.invalid">
                        <mat-error class="text-xs" *ngIf="Benchmarkunit.invalid">
                            Benchmark Title is required</mat-error>
                    </mat-error> 
                  </mat-form-field>
                  <span  *ngIf="Benchmarkfrminfo.GoalMeasureTypeId == PercentageBased">%</span>
                  <span *ngIf="Benchmarkfrminfo.GoalMeasureTypeId == Timebased">min</span>

              </div>
              
              <div class="col-md-6 form-group" *ngIf="IsShowStatus">
                <mat-form-field class="w-100">
                    <mat-label>Status</mat-label>
                    <mat-select [(ngModel)]="Benchmarkfrminfo.Status"
                    #status="ngModel" name="status">
                      <mat-option value='true' (click)="Onstatuschange(1)">Inprogress</mat-option>
                      <mat-option value='false' (click)="Onstatuschange(2)">Completed</mat-option>
                    </mat-select>                   
                  </mat-form-field>
                  
              </div>
          </div>
      </div>
      
      
    
  </div>
  
  </div>
  <div class="modal-footer">
    <div class="col-md-12 p-0 float-left text-right">
        <button mat-stroked-button class="btn primary-btn-outlined-small float-right mt-2 ml-2 " (click)="onClosemodal()">Cancel</button>
        <button  mat-stroked-button  class="btn primary-btn-filled-small float-right mt-2 mr-2 " (click)="OnpostBenchmark()"><i  class="far fa-save mr-2"></i>Save</button>    
    </div>
</div>
</div>
</form>
 
  </div>
  </div>
  </div>

  <a mimetype="application/octet-stream" #downloadLink></a>

<!-- <div class="log-activity">
  <div class="modal" id="myModal" style="display: block;">
  <div class="modal-dialog">
    <div class="modal-content">

  
      <div class="modal-header">
        <h6 class="modal-title">Progress Log</h6>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

   
      <div class="modal-body">
       <div class="col-md-12">
           <div class="row">
               <div class="col-md-5">
                <mat-form-field class="w-100">
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="picker">
                 
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
               </div>
               <div class="col-md-5">
                   <mat-form-field class="w-100">
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="picker">
                 
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
            </div>
            <div class="col-md-2 mt-3">
                <button mat-stroked-button class="btn primary-btn-outlined-small">Search</button>  
            </div>
           </div>
       </div>
       <div class="col-md-12 list-container-log">
           <div class="row">
               <div class="col-md-9">
                    <h6 class="mb-1">Timothy Husai - Clinician</h6>
                    <p class="text-muted mb-0 text-xs">Individual Threaphy Program Completed</p>
                    
                
               </div>
               <div class="col-md-3 text-right">
                <p class="text-muted mb-0 text-xs">16 May 2022 17:19</p>
                <span class="text-xs text-success">[Completed]</span>
               </div>
           </div>
       </div>
       <div class="col-md-12 list-container-log">
        <div class="row">
            <div class="col-md-9">
                 <h6 class="mb-1">Ezra - Clinician</h6>
                 <p class="text-muted mb-0 text-xs">Family Threaphy Program Started</p>
                 
             
            </div>
            <div class="col-md-3 text-right">
             <p class="text-muted mb-0 text-xs">18 Jun 2022 17:19</p>
             <span class="text-xs text-warning">[Inprogress]</span>
            </div>
        </div>
    </div>
      </div>

   

    </div>
  </div>
</div>
</div> -->
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { cloneDeep } from "lodash";
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
@Component({
  selector: 'app-incident-reporting-list',
  templateUrl: './incident-reporting-list.component.html',
  styleUrls: ['./incident-reporting-list.component.scss']
})
export class IncidentReportingListComponent implements OnInit {
@ViewChild(MatPaginator) paginator: MatPaginator;
@ViewChild(MatSort) sort: MatSort;
IncidenReportList:any;
tempIncidentReportList:any;
searchReporttxt:string=""
SelectedReportedBy:any=0
selectedTheraphy:any=-1
SelectedClinician:any=0
SelectedStatus:any=0
selectInctype:any=0;
  ddlstafflist: any;
  ddlReportedBy: any;
  ddlTherapy: any;
  RoleId:any
  incidentids=[];
  IncReportDatasource: MatTableDataSource<any>;
  displayedColumns = ['sno','PatientName', 'Reportedby', 'Therapy/Program', 'Clinician', 'IncidentDate','IncidentType','Status','actions'];
  PaginatorVariable: number=20;
  PageIndex: number;
  ddlIncidenType: any;
  roleAccess: any;
  constructor(private router:Router,public treatmentservice: TreatmentService, 
    public spinnerservice: NgxSpinnerService, public commonService:CommonService,
    private snackbar: SnackBarService
    ,public roleaccessservice:PageAccessService) { }

    ngOnInit(): void {
    const pageid = constantVariables.pagelists.IncidentReporting;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.RoleId = Number(localStorage.getItem("roleId"));
  //  if(this.RoleId!=1){
     this.router.navigate(['/clinician'],{queryParams:{menu:4}});
    // }
    // else{
    //  this.router.navigate(['/incidentreportingList'])
   // }
    if(!!(localStorage.getItem("IncReportListpaginator"))){
      this.PaginatorVariable =+(localStorage.getItem("IncReportListpaginator"));
    }
    if(!!(localStorage.getItem("IncReportListpageIndex"))){
      this.PageIndex =+(localStorage.getItem("IncReportListpageIndex"));
    }
    this.getIncidentReportList()
    this.GetStaffList()
    this.getTherapylist()
    //this.filterTable()
  
  }
  OnCreateIncident(){
    this.router.navigate(['/incident-report']);
  }

  getIncidentReportList(){
    this.spinnerservice.show()
    this.treatmentservice.getIncidentReportList().subscribe((res:any)=>{
      this.spinnerservice.hide()
     // console.log(res)
      this.IncidenReportList = res;
      this.tempIncidentReportList = cloneDeep(res);
      this.IncReportDatasource = new MatTableDataSource(this.tempIncidentReportList.filter(x=>x.status!=3) );
      this.IncReportDatasource.paginator = this.paginator;
      this.paginator.pageSize = this.PaginatorVariable;
      this.paginator.pageIndex= this.PageIndex;
          this.IncReportDatasource.sort = this.sort;
     // console.log(this.tempIncidentReportList)
    },
    err => {
      this.spinnerservice.hide();
  
    })
  }

  GetStaffList(){
    this.spinnerservice.show()
    this.treatmentservice.getCLinicalStaffList().subscribe(res=>{
      this.spinnerservice.hide()
      this.ddlstafflist = res
      this.ddlReportedBy = res
    },
    err => {
      this.spinnerservice.hide();
  
    })
  }

  getTherapylist(){
    this.spinnerservice.show();
  
    this.treatmentservice.getTreatmentPlanMaster().subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.ddlTherapy = this.commonService.decrypdata(res.therapy);
        this.ddlIncidenType = this.commonService.decrypdata(res.incidentype)
      // console.log(this.ddlTherapy)
      },
      err => {
        this.spinnerservice.hide();
  
      },
    );
  }
  OnIRlistFilter(item){
 
    //single filter
    if(this.SelectedReportedBy!=0 && this.selectedTheraphy==-1 && this.SelectedClinician==0 && this.SelectedStatus==0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.reportedBy ==this.SelectedReportedBy)
    }
    else if(this.SelectedReportedBy==0 && this.selectedTheraphy!=-1 && this.SelectedClinician==0 && this.SelectedStatus==0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.therapyId ==this.selectedTheraphy)
    }
    else if(this.SelectedReportedBy==0 && this.selectedTheraphy==-1 && this.SelectedClinician!=0 && this.SelectedStatus==0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.clinicianId ==this.SelectedClinician)
    }
    else if(this.SelectedReportedBy==0 && this.selectedTheraphy==-1 && this.SelectedClinician==0 && this.SelectedStatus!=0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.status ==this.SelectedStatus)
    }
    //double combination
    else   if(this.SelectedReportedBy!=0 && this.selectedTheraphy!=-1 && this.SelectedClinician==0 && this.SelectedStatus==0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.reportedBy ==this.SelectedReportedBy && x.therapyId==this.selectedTheraphy)
    }
    else   if(this.SelectedReportedBy!=0 && this.selectedTheraphy==-1 && this.SelectedClinician!=0 && this.SelectedStatus==0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.reportedBy ==this.SelectedReportedBy && x.clinicianId==this.SelectedClinician)
    }
    else   if(this.SelectedReportedBy!=0 && this.selectedTheraphy==-1 && this.SelectedClinician==0 && this.SelectedStatus!=0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.reportedBy ==this.SelectedReportedBy && x.status==this.SelectedStatus)
    }
    else   if(this.SelectedReportedBy==0 && this.selectedTheraphy!=-1 && this.SelectedClinician!=0 && this.SelectedStatus==0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.therapyId ==this.selectedTheraphy && x.clinicianId==this.SelectedClinician)
    }
    else   if(this.SelectedReportedBy==0 && this.selectedTheraphy!=-1 && this.SelectedClinician==0 && this.SelectedStatus!=0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.therapyId==this.selectedTheraphy && x.status==this.SelectedStatus)
    }
    else   if(this.SelectedReportedBy==0 && this.selectedTheraphy==-1 && this.SelectedClinician!=0 && this.SelectedStatus!=0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.clinicianId==this.SelectedClinician && x.status==this.SelectedStatus)
    }

    //Three combination
    else   if(this.SelectedReportedBy!=0 && this.selectedTheraphy!=-1 && this.SelectedClinician!=0 && this.SelectedStatus==0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.reportedBy ==this.SelectedReportedBy && x.therapyId==this.selectedTheraphy && x.clinicianId ==this.SelectedClinician)
    }
    else if(this.SelectedReportedBy==0 && this.selectedTheraphy!=-1 && this.SelectedClinician!=0 && this.SelectedStatus!=0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.status ==this.SelectedStatus && x.therapyId==this.selectedTheraphy && x.clinicianId ==this.SelectedClinician)
    }
    else if(this.SelectedReportedBy!=0 && this.selectedTheraphy==-1 && this.SelectedClinician!=0 && this.SelectedStatus!=0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.reportedBy ==this.SelectedReportedBy && x.status==this.SelectedStatus && x.clinicianId ==this.SelectedClinician)
    }
    else if(this.SelectedReportedBy!=0 && this.selectedTheraphy!=-1 && this.SelectedClinician==0 && this.SelectedStatus!=0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.status ==this.SelectedStatus && x.therapyId==this.selectedTheraphy && x.reportedBy ==this.SelectedReportedBy)
    }

    //four combinations
    else if(this.SelectedReportedBy!=0 && this.selectedTheraphy!=-1 && this.SelectedClinician!=0 && this.SelectedStatus!=0){
      this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.status ==this.SelectedStatus && x.therapyId==this.selectedTheraphy && x.reportedBy ==this.SelectedReportedBy && x.clinicianId == this.SelectedClinician)
    }
if(this.selectedTheraphy==-1 && this.SelectedReportedBy==0 && this.SelectedClinician==0&&this.SelectedStatus==0 && this.selectInctype ==0){
  this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.status!=3)
}
if(this.selectInctype !=0){
  this.tempIncidentReportList = this.IncidenReportList.filter(x=>x.incidentReportTypeId==this.selectInctype)
}
this.IncReportDatasource = new MatTableDataSource(this.tempIncidentReportList);
this.IncReportDatasource.paginator = this.paginator;
    this.IncReportDatasource.sort = this.sort;
  }
  OnviewReport(incidentReport:any,){
  //console.log(incidentReportId)
  this.router.navigate(['/incident-report'],{queryParams:{id:this.commonService.FrontEndEncryption(incidentReport.incidentReportId),isedit:this.commonService.FrontEndEncryption(1),status:this.commonService.FrontEndEncryption(incidentReport.status)}});
  }


  cbklistIncidentReport(event,item, i){
  if (event.checked) {
      this.incidentids.push(item.incidentReportId);
    } else {
      this.incidentids.forEach((x, index) => {
        if (x === item.incidentReportId) this.incidentids.splice(index, 1);
      });
    }
    //console.log(this.incidentids)
  }


  OnCloseMultipleIncidents(){
    for(var inc of this.incidentids){
      var forclosing = this.tempIncidentReportList.filter(x=>x.incidentReportId == inc)
      if(forclosing[0].status !=2){
        if(forclosing[0].status ==3){
          this.snackbar.error("Incidents are already closed")
        }
        else{
          this.snackbar.error("Please review the Incidents before closing")
        }
        
        return;
      }
    }
this.spinnerservice.show()
//console.log(this.incidentids)
const dataToEncrypt = this.commonService.DataToEncrypt(this.incidentids, false);
//console.log(dataToEncrypt)
this.treatmentservice.CloseIncidentReports(dataToEncrypt).subscribe((res:any)=>{
  this.spinnerservice.hide()
  if(res){
    this.snackbar.success(JSON.parse(res).message)
    this.getIncidentReportList()
  }
},err=>{
  this.spinnerservice.hide()
})
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
   // this.IncReportDatasource.filter = JSON.stringify(filterValue);
   this.IncReportDatasource.filter = filterValue.trim().toLowerCase();
   
}
// filterTable() {
//   //const filterValue = (event.target as HTMLInputElement).value;
//   this.IncReportDatasource.filterPredicate = (data:IncReportList, filter: string): boolean => {
//     return (
//       data.patientName.toLocaleLowerCase().includes(filter)
//     )
//   }
// } 

onPageChange(event){
  console.log(event.pageIndex)
  localStorage.setItem('IncReportListpaginator',event.pageSize)
  localStorage.setItem('IncReportListpageIndex',event.pageIndex)
}


}


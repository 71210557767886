<!-- <div class="inbox-section">
  <div class="heading-inbox clearfix">
    <div class="float-left mess-head">MESSAGES</div>
    <div class="float-right date-custom widthadjust--buttonalign">
      <mat-form-field class="w-75 float-left">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <button type="button" (click)="global.onOpenEmailPopup('')" class="btn primary-btn-filled  mt-2 ml-1 float-right">+ New</button>
    </div>
  </div>
  <div class="center-inbox-section">
    <div class="row">
      <div class="col-lg-3 col-md-4">
        <div class="card">
          <div class="card-body p-0 left-section-usermsg">
            <div class="col-md-12 mt-2">
              <div class="row">
                <div class="col-md-8 card-head  mt-3">INBOX</div>
                <div class="col-md-4 pl-0">
                  <mat-form-field class="w-100">
                    <mat-label>Filter</mat-label>
                    <mat-select (selectionChange)="filtermessage($event)">
                      <mat-option value="title">Name</mat-option>
                      <mat-option value="updatedOn">Date</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="user-name-list">
              <ul class="inbox-nav inbox-divider" *ngFor="let item of inboxMessage" (click)='openMessageThread(item.prqueryId)'>
                <li [ngClass]="currentId=== item.prqueryId ?'select':'active'" *ngIf="!item.isRead">
                  <a class="clearfix">
                    <img src="../../assets/images/avator.jpg" alt="User Avatar" class="rounded-circle mr-2"
                      style="width:3rem;float:left;">
                    <div class="name-list-inbox">{{item.title}}
                      <div class="regarding" innerHTML="{{(item.queryDescription.length>20)?(item.queryDescription | slice:0:20)+'..':(item.queryDescription)}}"></div>
                    </div>
                  </a>
                </li>
                <li [ngClass]="currentId=== item.prqueryId ?'select':''" *ngIf="item.isRead" >
                  <a  class="clearfix">
                    <img src="../../assets/images/avator.jpg" alt="User Avatar" class="rounded-circle mr-2"
                      style="width:3rem;float:left;">
                    <div class="name-list-inbox">{{item.title}}
                      <div class="regarding"  innerHTML="{{(item.queryDescription.length>20)?(item.queryDescription | slice:0:20)+'..':(item.queryDescription)}}"></div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="card mb-3 card-height-default">
          <div class="card-body">
            <div class="msg-details" *ngFor="let data of messagedetails">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-lg-9 col-md-12">
                    <div class="row">
                      <div class="co-md-2">
                        <img src="../../assets/images/avator.jpg" alt="User Avatar" class="rounded-circle mr-2 align-top"
                      style="width:3rem">
                      </div>
                      <div class="col-md-10">
                        <div class="d-inline-block align-middle right-msg-section">
                          <div class="name-list-inbox w-100">{{data.title}}</div>
                          <div class="msg-content" innerHTML="{{data.queryDescription}}"></div>
                        </div>
                      </div>
                    </div>
                    
                    
                  </div>
                  <div class="col-lg-3 col-md-12">
                    <div class="reply-section text-right d-none">
                      <i class="fas fa-reply mr-3"></i>
                      <i class="fas fa-reply-all mr-3"></i>
                      <i class="fas fa-arrow-right mr-3"></i>
                    </div>
                    <div class="msg-content text-right">{{data.createdOn | date: 'MM/dd/yyyy hh:mm a'}}</div>
                    <span class="show-more d-none">Show more</span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mail-assign mail-assign--update pb-0 mb-0" *ngFor="let rpy of data.queryComments">
                  <div class="details clearfix">
                    <div class="left">{{rpy.creatorEmail}}
                      <!-- <div class="arrow"></div> -->
<!-- </div>
                    <div class="right">{{rpy.createdOn | date: 'MM/dd/yyyy hh:mm a'}}</div>
                    <div class="message w-100 float-left">
                      <p>{{rpy.comments}}</p>
                    </div>
                  </div>
                
                </div>
                
                <div class="form-group row attachmentsection--update mesg">
                  <div class="w-100 pl-3">
                <mat-chip-list class="mt-2 attachment--update  attachment-sec"  *ngFor="let a of data.queryDocuments">
                  <mat-chip  selected  
                    [selectable]="selectable" [removable]="removable" >
                    *ngFor="let a of data.queryDocuments"
                    <a class="attachname" href="{{a.uploadDocumentPath}}" target="_blank" >{{a.documentName}}</a>
                   
                  </mat-chip>
                </mat-chip-list>
                
                </div>
               
                <div class="col-md-12  float-left w-100">
                  <div class="row">
                    <div class="col-md-10">
                      <label class="form-label pt-3 font-weight-bold">Reply</label>
                      <mat-form-field class="custom-textarea">
                        <mat-label>Reply</mat-label>
                        <textarea matInput [(ngModel)]="rpycomments" #comments="ngModel" name="comments"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2  send-reply-btn">
                      <button class="btn btn-send" (click)="postReply()" type="submit">Send</button>
                    </div>
                  </div>

                </div>
                <div class=" attachmentsection--update mesg">
                  <div class="col-lg-12 ">
                    <mat-chip-list class="mt-1 mb-1 attachment-sec">
                      <mat-chip [color]="(item.invalid)?'warn':''" selected *ngFor="let item of global.mailattachment"
                        [selectable]="selectable" [removable]="true" (removed)="removeattachments(item)">
                        <span class="attachname">{{item.name}}</span>
                        <mat-icon matChipRemove >cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                    <span class="btn green fileinput-button mr-2">
                      <i class="fa fa-plus fa fa-white pr-1"></i>
                      <span>Attachment</span>
                      <input accept="image/*" type='file' #mailUploadfile multiple (change)="onmailFileChange($event)">
                    </span>
                    <!-- <button class="btn btn-send" type="submit">Send</button> -->
<!-- </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div class="modal mail-modal" id="myModal" [style.display]="global.showEmailPopup ? 'block' : 'none'">
    <div class="modal-dialog mb-2">
        <div class="modal-content email-compose">
          <div class="modal-header">
             <h4 class="modal-title">Email</h4>
              <button aria-hidden="true" data-dismiss="modal" class="close"  (click)="global.oncloseEmailpopup()" type="button"><i class="fas fa-times"></i></button>
              
          </div>
          <div class="modal-body">
             <app-send-email  [subtag]="subjectTag" ></app-send-email>
              
          </div>
      </div>
    </div>
 </div>  -->


<div class="content-wrapper mail-page">

  <div class="container-fluid p-0">
    <div class="row no-gutters ">
      <div class="col-xl-2  side-bar " [ngClass]="{'toggle-section': isExpandMsgpanel}">
        <div class="card card-shadow mb-4">
          <!-- <div class="small-menu" style="display: none;">
            <ul class="p-0 m-0 mail-navs">
              <li>
                <a type="button" id="compose-mail" (click)="openComposeMail()" class="btn btn-sm  btn-danger">
                  <i class=" fa fa-plus"></i></a>
              </li>
              <li [ngClass]="{'active': !issentmsg}">
                <a class=" cursor-pointer inbox " (click)="getInboxMessage()"><i class="fas fa-inbox"></i>
                  <span class="badge badge-danger ml-2" *ngIf="inboxcount > 0">{{inboxcount}}</span></a>
              </li>
              <li [ngClass]="{'active': issentmsg}">
                <a class=" cursor-pointer inbox" (click)="getSentItem()"><i class="fas fa-paper-plane"></i></a>
              </li>
            </ul>

          </div> -->
          <div class="card-body p-0 large-menu">
            <div class="custom-title-wrap border-0 px-4 pt-2 mb-2">
              <div class="custom-title">
                <span class="msg-text">Message</span>
                <a type="button" id="compose-mail" (click)="openComposeMail()"
                  class="btn btn-sm  btn-danger float-md-right float-lg-right float-right">
                  <i class=" fa fa-plus pr-2"></i> Compose</a>

              </div>

            </div>
            <div class="mail-list-scroll" tabindex="1" style="overflow: hidden; outline: none;">
              <ul class="nav flex-column mail-navs mb-4">
                <li class="nav-item " [ngClass]="{'active': !issentmsg}">
                  <a class="nav-link cursor-pointer " [ngClass]="{'active': !issentmsg}" (click)="getInboxMessage()"><i
                      class="fas fa-inbox pr-3"></i>Inbox
                    <span class="badge badge-danger ml-2" *ngIf="inboxcount > 0">{{inboxcount}}</span></a>
                </li>
                <li class="nav-item" [ngClass]="{'active': issentmsg}">
                  <a class="nav-link cursor-pointer" [ngClass]="{'active': issentmsg}" (click)="getSentItem()"><i
                      class="fas fa-paper-plane pr-3"></i>Sent</a>
                </li>
              </ul>


            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-4">
        <div class="card card-shadow mb-4">
          <div class="card-body p-0">
            <div class="custom-title-wrap border-0 pl-2 pr-2 pt-2 mb-2 position-relative">
              <!-- <div class="toggle-btn "><a (click)="onclickmsgtoogle()" class="toggle"><i  [ngClass]="isExpandMsgpanel ? 'far fa-caret-square-right' : 'far fa-caret-square-left'"></i></a></div>  -->
              <div class="custom-title">
                <div class="toggle-btn "><a (click)="onclickmsgtoogle()" class="toggle"><i class="fas fa-bars"></i></a>
                </div>
                <span>{{!issentmsg ? "All Inbox" :"Sent Item" }}</span>
                <div class="float-right text-right sorting-mail">
                  <!-- (selectionChange)="filtermessage($event)" -->
                  <!-- <mat-form-field class="w-100">
                    <mat-label>Sort</mat-label>
                    
                    <mat-select>
                      <mat-option value="title">
                        <mat-checkbox (change)="onsortName($event)">Name</mat-checkbox> 
                      </mat-option>
                      <mat-option value="updatedOn">
                        <mat-checkbox (change)="onsortDate($event)">Date</mat-checkbox> 
                      </mat-option>
                    </mat-select>

                  </mat-form-field> -->
                  <button mat-button [matMenuTriggerFor]="menu">Sort</button>
                 
                  <mat-menu #menu="matMenu" class="sort-width">
                    <div class="check-custom">
                    <mat-checkbox (change)="onsortName($event)" class=" float-left pl-2 w-100">Name</mat-checkbox> 
                    <mat-checkbox (change)="onsortDate($event)" class=" float-left pl-2 w-100">Date</mat-checkbox> 
                  </div>
                  </mat-menu>
                  
                </div>
              </div>
            </div>
            <ul class="list-unstyled mb-0 list-widget mail-widget mail-list-scroll" tabindex="2"
              style=" outline: none;">
              <li class="cursor" [ngClass]="{'new': !item.isRead ,'active':selectedIndex === i}"
                *ngFor="let item of inboxMessage;let i = index"
                (click)='openMessageThread(item.prqueryId);setIndexformsg(i)'>
                <div class="media">
                  <div class="st-alphabet mr-3">
                    <span class="initial">{{getusername(item.userName)}}</span>
                  </div>
                  <div class="media-body ">
                    <div class="float-left position-relative msg-hint">
                      <span class="main-time text-muted">{{getmsgtime(item.createdOn)}}</span>
                      <div class="mb-2 d-block text-muted text-truncate mail-name">{{item.email}}</div>

                      <h5 class=" mb-0"><span class="text-truncate d-inline-block w-75">{{item.title.length > 20 ?
                          (item.title | slice:0:20)+'...':(item.title)}}</span> <i
                          *ngIf="item?.queryDocuments?.length > 0" class="fas fa-paperclip float-right"></i></h5>
                      <span class="text-muted msg-des"
                        innerHTML="{{(item.queryDescription.trim().length>20)?(item.queryDescription | slice:0:20)+'...':(item.queryDescription)}}"></span>
                    </div>
                  </div>
                </div>
              </li>


            </ul>
          </div>
        </div>
      </div>

      <div class="col-md-8 " [ngClass]="isExpandMsgpanel ? 'expand-body' : 'col-xl-7'">
        <div class="mail-list-scroll-trans">
          <div class="card card-shadow mb-2 ml-2" *ngFor="let data of messagedetails; let inx=index;">
            <div class="card-body px-2 pb-3 py-1 " tabindex="3" style=" outline: none;">
              <div class="media">
                <div class="st-alphabet-large mr-1">
                  <span class="initial-large">{{getusername(data.userName)}}</span>
                </div>
                <div class="media-body ">
                  <div class="float-left position-relative msg-hint w-100">
                    <div class="subject-msg">
                      <div class="mt-2 d-block text-dark font-weight-bold">{{data.userName}}</div>
                      <p class="text-muted float-lg-right">
                        <a (click)="replyto(data)"><span><i class="fas fa-reply mr-2"></i></span></a>
                        <a (click)="replytoall(data)"><span><i class="fas fa-reply-all"></i></span></a>
                      </p>

                      <span class="text-muted mb-1">{{getMessageDateon(data.createdOn)}}</span>
                      <div><span class="text-dark font-weight-bold mr-2 mb-1">To:</span><span
                          class="text-dark">{{data.toUserEmail}}</span></div>
                      <div><span class="text-dark font-weight-bold mr-2 mb-1">Cc:</span><span class="text-dark">
                          {{data.ccUsersEmail}}</span>

                      </div>
                      <div><span class="text-dark font-weight-bold mr-2 mb-1">Subject:</span><span class="text-dark">
                          {{data.title}}</span></div>
                    </div>

                    <p class="mt-1" innerHTML="{{global.getContent(data.queryDescription)}}"></p>

                    <div class=" ">
                      <div class="row">

                        <div class="col-md-6 mb-3" *ngFor="let a of data.queryDocuments">
                          <div class="media cursor border p-3 " *ngIf="a.sendMailQueryDocumentId != 0">
                            <div class="mr-3">
                              <i class="far fa-file mt-1 d-block" style="font-size:26px;vertical-align: middle;"></i>
                            </div>
                            <div class="media-body">
                              <div class="float-left">
                                <h6 class="mb-0"><a class="sorting-text" href="{{a.uploadDocumentPath}}"
                                    target="_blank">{{a.documentName}}</a></h6>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="w-100 float-left mb-1" *ngIf="data?.history?.length > 0">
                      <a class="three-dot-cover" data-toggle="collapse" href="#collapseExample{{inx}}" role="button" aria-expanded="false" aria-controls="collapseExample"><span class="three-dot">. . .</span></a>
                    </div>
                  </div>

                  <div  id="collapseExample{{inx}}" class="reply-details msg-hint float-left w-100 collapse" *ngFor="let his of data.history;let i = index">
                    <div>
                      <div class="subject-msg">
                        <!-- <p class="text-muted float-lg-right">
                      <a (click)="replyto(his)"><span><i class="fas fa-reply mr-2"></i></span></a>
                      <a (click)="replytoall(his)"><span><i class="fas fa-reply-all"></i></span></a>
                    </p> -->

                        <div><span class="text-dark font-weight-bold mr-2 mb-1">From:</span><span
                            class="text-dark">{{his.email}}</span></div>
                        <div><span class="text-dark font-weight-bold mr-2 mb-1">Sent:</span><span class="text-dark">
                            {{getMessageDateon(his.createdOn)}}</span></div>
                        <div><span class="text-dark font-weight-bold mr-2 mb-1">To:</span><span
                            class="text-dark">{{his.toUserEmail}}</span></div>
                        <div><span class="text-dark font-weight-bold mr-2 mb-1">Cc:</span><span class="text-dark">
                            {{his.ccUsersEmail}}</span></div>

                        <div><span class="text-dark font-weight-bold mr-2 mb-1">Subject:</span><span class="text-dark">
                            {{his.title}}</span></div>

                      </div>
                      <p innerHTML="{{global.getContent(his.queryDescription)}}"></p>
                      <div class=" ">
                        <div class="row">
                          <div class="col-md-6 mb-3" *ngFor="let aa of his.queryDocuments">
                            <div class="media cursor border p-3" *ngIf="aa.sendMailQueryDocumentId != 0">
                              <div class="mr-3">
                                <i class="far fa-file mt-1 d-block" style="font-size:26px;vertical-align: middle;"></i>
                              </div>
                              <div class="media-body">
                                <div class="float-left">
                                  <h6 class="mb-0"><a class="sorting-text" href="{{aa.uploadDocumentPath}}"
                                      target="_blank">{{aa.documentName}}</a></h6>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
<div class="modal mail-modal " [ngClass]="{'minimized': isminimizedEmail}" id="myModal"
  [style.display]="global.showEmailPopup ? 'block' : 'none'">
  <div class="modal-dialog mb-2">
    <div class="modal-content email-compose">
      <div class="modal-header cursor-pointer" (click)="onExpandComposeEmail()">
        <h4 class="modal-title">Email</h4>
        <div class="ml-auto">
          <a class="minimize-mail-compose"><i class="fas fa-minus"></i></a>
          <button aria-hidden="true" data-dismiss="modal" class="close" (click)="global.oncloseEmailpopup()"
            type="button"><i class="fas fa-times"></i></button>
        </div>


      </div>
      <div class="modal-body">
        <app-send-email [subtag]="subjectTag"></app-send-email>

      </div>
    </div>
  </div>
</div>
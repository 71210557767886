<div>
  <div>
      <!-- <div class="details-head d-flex">
          <h4 class="top-label">Financial List</h4>
          <a (click)="onBacklead()" class="top-label ml-auto cursor-pointer">
              <i class="fas fa-angle-left pr-2"></i>back
          </a>
      </div> -->
      <div class="row top-btn-section clearfix srchtop">
        <div class="col-md-6">
            <h2 class="head-section mb-2 mt-0 txtsize">Financial List</h2>
        </div>
        <div class="col-md-6 text-right">
            <button type="button" class="btn primary-btn-outlined float-right mr-2" (click)="onBacklead()">
                <i class="fas fa-angle-left pr-2"></i>Back
            </button>
        </div>
    </div>
    
       
    <div class="d-flex w-100">
        <div class="col-md-12 auditClinicianTable">
          <div>
            <mat-table #table [dataSource]="dataSource" matSort class="w-100">
              <!-- Columns definitions -->
              <ng-container matColumnDef="Name" class="client-name-column">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="client-name-header backgroundcolor" style="width:150px;">
                  Catalog Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="client-name-cell" style="width:150px;">
                  {{ element.name }}
                </mat-cell>
              </ng-container>
      
              <ng-container matColumnDef="ComponentName" class="location-column">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="location-header backgroundcolor" style="width:150px;">
                  Component Name
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="location-cell" style="width:150px;">
                  {{ element.componentName }}
                </mat-cell>
              </ng-container>
      
              <ng-container matColumnDef="PriceValue" class="actions-column">
                <mat-header-cell *matHeaderCellDef class="actions-header backgroundcolor" style="width:300px;">
                  Price Value
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" class="actions-cell" style="width:300px;">
                  {{ element.priceValue }}
                </mat-cell>
              </ng-container>
      
              <ng-container matColumnDef="Type" class="actions-column">
                <mat-header-cell *matHeaderCellDef class="actions-header backgroundcolor" style="width:300px;">
                  Type
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" class="actions-cell" style="width:300px;">
                  {{ element.type }}
                </mat-cell>
              </ng-container>
      
              <ng-container matColumnDef="Status" class="actions-column">
                <mat-header-cell *matHeaderCellDef class="actions-header backgroundcolor" style="width:300px;">
                  Status
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" class="actions-cell" style="width:300px;">
                  {{ element.status }}
                </mat-cell>
              </ng-container>
      
              <ng-container matColumnDef="SubmittedDate" class="actions-column">
                <mat-header-cell *matHeaderCellDef class="actions-header backgroundcolor" style="width:300px;">
                  Submitted Date
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" class="actions-cell" style="width:300px;">
                  {{ element.submittedDate | date:'yyyy/MM/dd' }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="AcceptedDate" class="actions-column">
                <mat-header-cell *matHeaderCellDef class="actions-header backgroundcolor" style="width:300px;">
                  Accepted Date
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" class="actions-cell" style="width:300px;">
                  {{ element.acceptedDate | date:'yyyy/MM/dd' }}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="OrderId" class="actions-column">
                <mat-header-cell *matHeaderCellDef class="actions-header backgroundcolor  order-id-column" style="width:0px;">
                    Order Id
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" class="actions-cell  order-id-column" style="width:0px;">
                  {{ element.orderId}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Occurrence" class="actions-column">
                <mat-header-cell *matHeaderCellDef class="actions-header backgroundcolor" style="width:300px;">
                  Occurrence
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" class="actions-cell" style="width:300px;">
                  {{ element.payFrequency}}
                </mat-cell>
              </ng-container>
      
              <!-- Apply conditional row class -->
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row 
                *matRowDef="let row; columns: displayedColumns" 
                [ngClass]="{
                  'row-style-1': row.orderId == '1',
                  'row-style-2': row.orderId == '2',
                  'row-style-3': row.orderId == '3',
                  'row-style-4': row.orderId == '4',
                  'row-style-5': row.orderId == '5',
                  'row-style-6': row.orderId == '6',
                  'row-style-7': row.orderId == '7'
                }">
              </mat-row>
            </mat-table>
      
            <div *ngIf="pendingDocuments?.length == 0">
              <div class="list-note w-100 mt-2 mb-2">
                <span>{{ Data }}</span>
              </div>
            </div>
          </div>
          <mat-paginator [pageSize]="20" [pageSizeOptions]="[10, 20, 50, 75, 100]" showFirstLastButtons>
          </mat-paginator>
        </div>
      </div>

      
  </div>
</div>

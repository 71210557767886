import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { Task } from 'src/app/_models/_Common/task';
import { CalenderComponent } from 'src/app/common/pages/calender/calender.component';
import { TaskService } from 'src/app/common/pages/task/task.service';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from "lodash";
import { TaskDialogComponent } from 'src/app/common/task-dialog/task-dialog.component';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit {
  @ViewChild(CalenderComponent ) CalenderCom: CalenderComponent ; 
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],                                       // remove formatting button


      ],

    },
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      source: (searchTerm, renderList, mentionChar) => {
        let values;

        if (mentionChar === "@") {
          values = this.atValues;
        }

        if (searchTerm.length === 0) {

          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (var i = 0; i < values.length; i++) {
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
          }
          renderList(matches, searchTerm);
        }
      },
      onSelect(item, insertItem) {
        
        //this.assignUserTags(item);
        insertItem(item)

      },
    },
    keyboard: {
      bindings: {
        shiftEnter: {
          key: 13,
          shiftKey: true,
          handler: (range, context) => {
            // Handle shift+enter
           // console.log("shift+enter")
          }
        },
        enter: {
          key: 13,
          handler: (range, context) => {
           // console.log("enter");
            return true;
          }
        }
      }
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  taskinfo: Task;
  patientInfoId: any;
  atValues: any = [];
  taskids: any = [];
  parentlst: any;
  lstTask: any;
  currentdate: any;
  isValidDueDate: boolean = true;
  isValidFlagDate: boolean = true;
  isEditTask: boolean = false;
  isCreateTask: boolean = false;
  Decrptedlst:any;
  lstuser: any;
  UserId: any;
  roleAccess: RoelAccess;
  viewRightsMsg:any
  constructor(public global: Global, private dialog: MatDialog,
    private snackbar: SnackBarService, public taskService: TaskService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public roleaccessservice:PageAccessService) {
      
     }

  ngOnInit(): void {
    //const pageid = constantVariables.pagelists.Task;
   // this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.viewRightsMsg=constantVariables.PagePermissionMsg.IsView;
    this.currentdate = new Date();
    this.taskinfo = new Task();
    this.route.queryParams.subscribe(params => {
      if (params != null) {

        this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.id));

      }
    });
    this.UserId = Number(localStorage.getItem("id"));
    this.getTask();
    this.getAllStaffList();
    this.getStaffList();
  }
  saveTaskInfo(form: NgForm) {
    var dueDate = moment(this.taskinfo.dueDate).format("MM/DD/YYYY");
    var overDate = moment(this.taskinfo.overDate).format("MM/DD/YYYY");
    var flagDate = moment(this.taskinfo.flagMeBy).format("MM/DD/YYYY");
    // var ValidDueDate = this.validateDates(dueDate, overDate);
    // if (!ValidDueDate) {
    //   this.isValidDueDate = false;
    //   return
    // }else{
    //   this.isValidDueDate = true;
    // }
    // var ValidFlagDate = this.validateDates(flagDate,dueDate);
    // if (!ValidFlagDate) {
    //   this.isValidFlagDate = false;
    //   return
    // }else{
    //   this.isValidFlagDate = true;
    // }
    this.taskinfo.patientInfoId = this.patientInfoId;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.taskinfo, false);
    this.taskService.postTask(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        form.resetForm();
        this.resetnotesform();
        this.snackbar.success(JSON.parse(res).message);
        this.getTask();
        this.CalenderCom.getTask();
        this.CalenderCom.refresh.next();
      }
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }

  onSelfAssign(event){
    if(event.checked){
      this.taskinfo.assignedTo = this.UserId;
    }else{
      this.taskinfo.assignedTo = null;
    }
  }

  validateDates(sDate: string, eDate: string) {
    var isValidDate = true;
    if ((sDate == null || eDate == null)) {
      isValidDate = false;
    }

    if ((sDate != null && eDate != null) && (eDate) < (sDate)) {

      isValidDate = false;
    }
    return isValidDate;
  }
  resetnotesform() {
    this.taskinfo = new Task();
    this.isEditTask = false;
    this.isCreateTask = false;
  }
  onPageChange($event) {

    this.lstTask = this.Decrptedlst.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  getTask() {
    this.taskService.getTask(this.patientInfoId).subscribe(
      (res: any) => {
        if (res) {
          this.Decrptedlst = res;
          this.lstTask = this.Decrptedlst.slice(0, 5);
          console.log(this.lstTask)
          this.dataSource = new MatTableDataSource(this.Decrptedlst);
          this.dataSource.paginator = this.paginator;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  getAllStaffList() {
    this.taskService.getUserList(0).subscribe(
      (res: any) => {
        if (res) {
          this.lstuser = res;

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  getStaffList() {
    this.commonService.getStaffList(0).subscribe(
      (res: any) => {
        if (res) {
          this.parentlst = res;
          for (var i = 0; i < this.parentlst.length; i++) {
            let _obj = {
              id: this.parentlst[i].staffId,
              value: this.parentlst[i].email + ";"
            }
            this.atValues.push(_obj);
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onedittask(element){
    this.isEditTask = true;
    this.isCreateTask = true;
    this.taskinfo = cloneDeep(element)
  }

  oncompletetask(element){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.CompleteConfirmMessage
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.taskService.completeTask(element.taskId).subscribe(
          res => {
            this.spinnerservice.hide();
            this.snackbar.success(res.message);
            this.getTask();


          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );
      }
    });

  }

  cbklisttask(event, item, index) {
    if (event.checked) {
      this.taskids.push(item.taskId);
    } else {
      this.taskids.forEach((x, index) => {
        if (x === item.taskId) this.taskids.splice(index, 1);
      });
    }
  }
  ondeletedtask(item) {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.taskService.deleteTask(item.taskId).subscribe(
          res => {
            this.spinnerservice.hide();
            this.snackbar.success(res.message);
            this.getTask();


          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );
      }
    });
  }
  ondeletemultipleTask() {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        const dataToEncrypt = this.commonService.DataToEncrypt(this.taskids, false);
        this.taskService.deletetasks(dataToEncrypt).subscribe(
          (res: any) => {
            this.taskids = [];
            this.snackbar.success(JSON.parse(res).message);
            this.getTask();
            this.spinnerservice.hide();

          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );

      }
    });

  }

  oncompletemultipleTask() {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.CompleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        const dataToEncrypt = this.commonService.DataToEncrypt(this.taskids, false);
        this.taskService.completeAlltasks(dataToEncrypt).subscribe(
          (res: any) => {
            this.taskids = [];
            this.snackbar.success(JSON.parse(res).message);
            this.getTask();
            this.spinnerservice.hide();

          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );

      }
    });

  }

  openCalender(){
    this.router.navigate(['/calender'], { queryParams: { id: this.commonService.FrontEndEncryption(this.patientInfoId) } });
  }
  onCreateTask(){
    this.isCreateTask = !this.isCreateTask;
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(TaskDialogComponent,
      { data: { parentinfoId: this.patientInfoId, taskId : data.taskId} });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

<div class="history-list-overall">
  <div class="col-md-12">
    <div class="d-flex">
      <div class="head-section mt-2 mb-2">Client Episode History</div>
      <div class="ml-auto">
        <button class="btn back-btn-new mr-2" [routerLink]="['/clinician']">
          <i class="fas fa-chevron-left mr-2"></i>Back
        </button>
      </div>
    </div>
    <div class="card">
      <div class="col-md-12 profile-history-log p-3">
        <div class="row">
          <div class="col-lg-1 col-md-1 col-12">
            <div class="profile-image float-md-right">
              <img
                src="https://bootdey.com/img/Content/avatar/avatar7.png"
                alt=""
              />
            </div>
          </div>
          <div class="col-lg-11 col-md-11 col-12">
            <div class="row">
              <div class="col-md-3">
                <h4 class="mb-0">
                  <strong
                    >{{ patientinfo?.patientName }} -
                    {{ patientinfo?.age }} yrs</strong
                  >
                </h4>
                <div class="sub-text-grey">{{ patientinfo?.email }}</div>
                <div class="sub-text-grey">
                  {{ patientinfo?.contactNo | mask : "(000) 000-0000" }}
                </div>
                <div class="main-tp">
                  <div class="d-flex">
                    <div class="ml-1">
                      MTP Start Date
                      <span class="imp-info">
                        {{
                          patientinfo?.treatmentPlanDate | date : "MM/dd/yyyy"
                        }}</span
                      >
                    </div>
                    <div class="ml-auto mr-2">
                      MTP End Date
                      <span class="imp-info">{{
                        patientinfo?.treatmentEndDate | date : "MM/dd/yyyy"
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="dashbord intake-content">
                  <div class="title-section">
                    <p class="mb-1 font-weight-500 border-bottom">
                      Intake Packets
                      <span class="history-value text-primary float-right">{{
                        patientinfo?.scheduleDate | date : "MM/dd/yyyy"
                      }}</span>
                    </p>
                  </div>
                  <div class="icon-text-section">
                    <div class="icon-section d-inline-block mr-2 align-top">
                      <i class="fas fa-notes-medical mr-2"></i>
                    </div>
                    <div class="d-inline-block">
                      <div class="history-label">Provider</div>
                      <div class="history-value">
                        {{ patientinfo?.intakeClinicianName }}
                      </div>
                    </div>
                    <div style="clear: both"></div>
                  </div>
                  <div
                    class="detail-section cursor-pointer"
                    (click)="onGenerateIntake(patientinfo)"
                  >
                    <a role="button">
                      <p>View Detail</p>
                      <i aria-hidden="true" class="fa fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="dashbord Chma-content">
                  <div class="title-section">
                    <p class="mb-1 font-weight-500 border-bottom">
                      CMHA
                      <span
                        class="history-value text-primary float-right"
                      ></span>
                    </p>
                  </div>
                  <div class="icon-text-section">
                    <div class="icon-section d-inline-block mr-2 align-top">
                      <!-- <i class="fas fa-procedures mr-2"></i> -->

                      <img
                        src="../../../../assets/images/cmha-black.png"
                        alt="cmha"
                        class="history-img mb-2 mr-2"
                      />
                    </div>
                    <div class="d-inline-block">
                      <div class="history-label">Provider</div>
                      <div class="history-value">
                        {{ patientinfo?.chmaClinicianName }}
                      </div>
                    </div>
                    <div style="clear: both"></div>
                  </div>
                  <div
                    class="detail-section cursor-pointer"
                    (click)="onintakechma(patientinfo)"
                  >
                    <a role="button">
                      <p>View Detail</p>
                      <i aria-hidden="true" class="fa fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="dashbord mtp-content">
                  <div class="title-section">
                    <p class="mb-1 font-weight-500 border-bottom">
                      Master Treatment Plan
                    </p>
                  </div>
                  <div class="icon-text-section">
                    <div class="icon-section d-inline-block mr-2 align-top">
                      <i class="fas fa-hand-holding-medical mr-2"></i>
                    </div>
                    <div class="d-inline-block">
                      <div class="history-label">Provider</div>
                      <div class="history-value">
                        {{ patientinfo?.chmaClinicianName }}
                      </div>
                    </div>
                    <div style="clear: both"></div>
                  </div>
                  <div
                    class="detail-section cursor-pointer"
                    
                  >
                
                   <div class="row">
                      <div class="col-md-10">
                        <mat-form-field class="w-100 ml-2">
                          <mat-label>Treatment Plan</mat-label>
                          <mat-select name="treatmentPlan"  [(ngModel)]="treatmentPlan"  
                          >
                          <mat-option *ngFor="let tp of treatmentPlanMultiple" [value]="tp.chmatreatmentSummaryId">{{tp.mtpname1}}</mat-option>
                        </mat-select>
                            </mat-form-field>
                         
                          </div>
                 
                   <div class="col-md-2">
                    <a role="button">
                      <i aria-hidden="true" class="fa fa-arrow-right treatment-icon" (click)="onTreatmentPlan(patientinfo)"></i>
                      </a>
                   </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
      <div class="tab-history mt-3">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home"
              type="button"
              role="tab"
              aria-controls="home"
              aria-selected="true"
              (click)="OntabChange(1)"
              [ngClass]="{ 'active show': SelectedTab == 1 }"
            >
              Treatment History
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
              (click)="OntabChange(2)"
              [ngClass]="{ 'active show': SelectedTab == 2 }"
            >
              Onboarding Logs
            </button>
          </li>
        </ul>

        <div class="tab-content">
          <div
            class="tab-pane active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
            *ngIf="SelectedTab == 1"
          >
            <div class="col-md-12 pt-3">
              <!-- <h5 class="treat-his">Treatment History</h5> -->
              <div class="table-history">
                <div class="table-responsive">
                  <table class="table table-fixed">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Day</th>
                        <th scope="col">Details</th>
                        <th scope="col">Therapy</th>
                        <th scope="col">Point Sheet</th>
                        <th scope="col">Progress Notes</th>
                        <!-- <th scope="col">Medication</th> -->
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of treatmenthistory; let i = index">
                        <td>{{ item.schduleDate | date : "MM/dd/yyyy" }}</td>
                        <td>{{ item.schduleDate | date : "EEEE" }}</td>
                        <td>
                          <div class="font-weight-bold">
                            {{ item.treatmentDetail }}
                          </div>
                          <!-- <div>schedule with Dr.xyz</div> -->
                        </td>
                        <td>
                          <span *ngIf="item.therapyType == 1" class="gt-badge"
                            >GT</span
                          >
                          <span *ngIf="item.therapyType == 2" class="it-badge"
                            >IT</span
                          >
                          <span *ngIf="item.therapyType == 3" class="f-badge"
                            >FT</span
                          >
                          <span *ngIf="item.therapyType == 5" class="pt-badge"
                            >PT</span
                          >
                          <span *ngIf="item.therapyType == 8" class="pe-badge"
                            >PE</span
                          >
                          <span *ngIf="item.therapyType == 9" class="pm-badge"
                            >PM</span
                          >
                          <span *ngIf="item.therapyType == 11" class="pc-badge"
                            >PC</span
                          >
                          <span *ngIf="item.therapyType == 10" class="sm-badge"
                            >SM</span
                          >
                          <span *ngIf="item.therapyType == 12" class="st-badge"
                            >ST</span
                          >
                          <span *ngIf="item.therapyType == 13" class="nt-badge"
                            >NT</span
                          >
                        </td>
                        <td>
                          <a
                            *ngIf="
                              item.therapyType == 1 && item.isFivepoint == 1
                            "
                            class="view-history"
                            ><i
                              class="far fa-grin-stars"
                              (click)="onGrourTherapy(item)"
                            ></i
                          ></a>
                        </td>
                        <td>
                          <a
                            *ngIf="
                              item.therapyType != 1 && item.isProgressNote == 1
                            "
                            class="view-history"
                          >
                            <i
                              class="fas fa-edit fa-1x"
                              (click)="onClinicalNotes(item)"
                            ></i
                          ></a>
                        </td>
                        <td>
                          <!-- <span class="status-tabl inprogress" >Pending</span> 
                                                    <span class="status-tabl completed" >Completed</span>                                               -->
                          <span class="text-success" *ngIf="item.status == 3"
                            >Completed</span
                          >
                          <span class="text-danger" *ngIf="item.status == 2"
                            >Pending</span
                          >
                        </td>
                        <!-- <td>
                                                    <a  *ngIf="item.therapyType == 1 && item.isMedication == 1" class="view-history">
                                                        <i class="fas fa-briefcase-medical"></i>
                                                    </a>
                                                </td>
                                                <td><a  *ngIf="item.therapyType == 1 " class="view-history"><i class="fas fa-bullseye"></i></a></td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane active"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
            *ngIf="SelectedTab == 2"
          >
            <div class="history-list col-md-12 mt-3 p-0">
              <ul>
                <li *ngFor="let item of leadhistory; let i = index">
                  <div class="list-history col-md-12">
                    <div class="row">
                      <div class="col-md-1" *ngIf="item.roleId !== 4">
                        <img
                          class="img-fluid"
                          src="../../../assets/images/move2stage-icon.png"
                        />
                      </div>
                      <div class="col-md-1" *ngIf="item.roleId == 4">
                        <img
                          class="img-fluid"
                          src="../../../assets/images/move2stage-icon-green.png"
                        />
                      </div>
                      <div class="col-md-9">
                        <p class="sub-history mb-1"></p>
                        <p
                          class="sub-history2 mb-1"
                          innerHTML="{{ global.getContent(item.message) }}"
                        ></p>
                      </div>
                      <div class="col-md-2 text-right">
                        <p class="sub-history2 mb-1">
                          {{
                            global.globalUtctimeformat(item.createdOn)
                              | date : "MM/dd/yyyy"
                          }}
                        </p>
                        <p class="sub-history2 mb-1">
                          {{
                            global.globalUtctimeformat(item.createdOn)
                              | date : "hh:mm a"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="col-md-12">
              <mat-paginator
                class="pagination pagination-large"
                (page)="onPageChange($event)"
                [pageSize]="5"
                [pageSizeOptions]="[5, 10, 25, 100]"
              >
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

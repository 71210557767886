export class location{
    meetingLocationsId?:number;
    meetingName:string;
    locationName:string;
    seatingCapacity:number;
    facilityTypeId:number;
    facilityName:string;
    isActive:boolean;
    location:any; 
    locationId:any; 
    accessLevel: number;
}
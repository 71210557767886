import { borderTopWidth } from "html2canvas/dist/types/css/property-descriptors/border-width";

export class PatientPaymentDetails
{ 
 PaymentdetailId :any;
 PaymentTypeId :any;
 PatientInfoId :any;
 UserId=localStorage.getItem('id');
 IsDeleted :any;
 IscreditCard;
 PatientCreditCardDetails: DtoPatientCreditCardDetails;
 IsEdit:boolean=false;
 PatientAccountId : any;
}
export class DtoPatientCreditCardDetails
{
  Cid :any;
  CardHolderName :any;
  CardNumber :any;
  CreditcardTypeId :any;
   ExpiryDate :any;
   CVV :any;
   BankName:any; 
   Comments :any;
   UserId =localStorage.getItem('id');
   IsDeleted :any;
   PatientInfoId :any;
   PaymentdetailId:any;
}

export class DtoBridgePayTransactionSaleRequest
{
  PaymentAccountNumber:number;
  ExpirationDate:any;
  SecurityCode:any;
  Amount:any;
  HolderType:any;
  AccountZip:any;
  BpIntiateTId:any=0;
  PatientAccountId:any;
  InvoiceNum:any;
  AppointmentId:any;
  TherapyId:any;
  LoginUserid:any=localStorage.getItem("id");
  PatientInfoId:any;
  MeetingScheduleTimeId:any;
  DayScheduleAssignId:any;
  TransactionCode:any;
  BillValue:any;
  PaymentAccountNumberMask:number;
  SecurityCodeMask:any;
  PaymentMethod:any;
  PaymentMethodId:any;
  PaymentDate:any;

  GroupedDayScheduleAssignId:any;
  GroupedMeetingScheduleTimeId:any;
   DueAmount:any;
   ExcessAmount;
   ReaminingAmt;

  
}



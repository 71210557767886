<div class="col-md-12">
  <div class="d-flex met-info-top w-100">
    <i class="fas fa-user"></i>
    <div class="mr-4">
      Client Name
      <span class="imp-info">
        {{patientName}}

      </span>
    </div>
    <div class="mr-4">
      Appointment Date
      <span class="imp-info">{{StartDate | date:'MM/dd/YYYY'}}</span>
    </div>
    <div class="mr-4"> Appointment Time   <span class="imp-info">{{StartTime | date:'hh:mm a'}}</span></div>
    <div class="mr-4">
    </div><div class="ml-auto">
    </div>
  </div>
  <div>
    <div class="box-lead-head mb-2 fntsize hdr">MEDICATION INFORMED CONSENT FOR CHILDREN</div>
    <button class="btn primary-btn-outlined-small ng-star-inserted float-right mr-2 gntpdf_btn" (click)="onGeneratePDF()">GeneratePDF</button>
  </div>

  <form class="formwidth mx-5 position-relative" [formGroup]="medicationForm" (ngSubmit)="onSubmit()">
    <div class="button-container btns-clicks">
      <button type="submit" class="btn primary-btn-outlined-small ng-star-inserted mr-2 float-left ">
        <i class="far fa-save mr-2"></i>Save
      </button>
    </div>
    <!-- <div class="date-info d-flex align-items-center">
      <div class="label-container">
      <mat-label>Date</mat-label>
      </div>
      <div class="input-container">
        <mat-form-field class="button-container">
          <input matInput [matDatepicker]="pstpicker" readonly (click)="pstpicker.open()"
          formControlName="currentDate" name="cstartDates">
          <mat-datepicker-toggle matSuffix [for]="pstpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #pstpicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div> -->
    <div class="form-row row2">
      <div class="label-container">
        <mat-label>The name of your medication is</mat-label>
      </div>
      <div class="input-container">
        <mat-form-field appearance="fill" class="form-field">
          <input matInput formControlName="medicationName">
        </mat-form-field>
      </div>
      <div class="label-container ml-5">
        <mat-label>Does it help?</mat-label>
      </div>
      <div class="input-container">
        <mat-radio-group formControlName="medicationHelps">
          <mat-radio-button value="yes">Yes</mat-radio-button>
          <mat-radio-button value="no">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="image-container ml-2">
        <img src="../../assets/images/img1.png" alt="Circle Image" width="60" height="60">
      </div>
    </div>
    <div class="form-row row3">
      <div class="label-container">
        <mat-label>What time do you take your medication?</mat-label>
      </div>
      <div class="input-container">
        <mat-form-field appearance="fill" class="form-field">
          <input matInput formControlName="medicationTime">
        </mat-form-field>
      </div>
      <div class="label-container ml-5">
        <mat-label>How many times a day?</mat-label>
      </div>
      <div class="input-container">
        <mat-form-field appearance="fill" class="form-field">
          <input matInput formControlName="timesPerDay">
        </mat-form-field>
      </div>
      <div class="image-container ml-2">
        <img src="../../assets/images/img3.png" alt="Circle Image" width="60" height="60">
      </div>
    </div>
    <div class="form-row row4">
      <div class="label-container mr-4">
        <mat-label class="field-label">What are your medications for?</mat-label>
      </div>
      <div class="input-container">
        <mat-checkbox formControlName="angerMoody">Anger/Moody</mat-checkbox>
        <mat-checkbox formControlName="depression">Depression</mat-checkbox>
        <mat-checkbox formControlName="adhd">ADHD</mat-checkbox>
        <mat-checkbox formControlName="anxiety">Anxiety</mat-checkbox>
        <mat-checkbox formControlName="impulsivity">Impulsivity</mat-checkbox>
        <mat-checkbox formControlName="otherSideEffectsangerMoody">Other</mat-checkbox>
        <mat-form-field appearance="fill" class="form-field">
          <input matInput formControlName="otherSideEffectsangerMoody">
        </mat-form-field>
      </div>
      <div class="image-container">
        <img src="../../assets/images/img2.png" alt="Image" width="50" height="58">
      </div>
    </div>
    <div class="form-row row5">
      <div class="label-container mr-4">
        <mat-label class="field-label">What are some of the side effects you may experience?</mat-label>
      </div>
      <div class="input-container">
        <mat-checkbox formControlName="dizzy">
          <img src="../../assets/images/img4.png" alt="Dizzy Image" width="42" height="38">
          Dizzy
        </mat-checkbox>
        <mat-checkbox formControlName="stomachHurts">
          <img src="../../assets/images/img5.png" alt="Stomach Hurts Image" width="42" height="38">
          Stomach hurts/Nauseous
        </mat-checkbox>
        <mat-checkbox formControlName="sleepy">
          <img src="../../assets/images/img6.png" alt="Sleepy Image" width="42" height="38">
          Sleepy
        </mat-checkbox>
        <mat-checkbox formControlName="shaky">
          <img src="../../assets/images/img7.png" alt="Shaky Image" width="42" height="38">
          Shaky
        </mat-checkbox>
        <mat-checkbox formControlName="otherSpecify">Other</mat-checkbox>
        <mat-form-field appearance="fill" class="form-field">
          <input matInput formControlName="otherSpecify">
        </mat-form-field>
      </div>
    </div>
    <div class="form-row row6 align-items-start">
      <div class="label-container">
        <mat-label class="field-label">What do you do if you take your medicine and you do not feel good?</mat-label>
      </div>
    </div>
    <div class="form-row row6 align-items-start">
      <div class="input-container">
        <mat-radio-group formControlName="medicationOnFeelGood">
          <mat-radio-button value="1">A. Tell the Nurse</mat-radio-button>
          <mat-radio-button value="2">B. Tell the Staff</mat-radio-button>
          <mat-radio-button value="3">C. Tell the Teacher</mat-radio-button>
          <mat-radio-button value="4">D. All of the Above</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="form-row row7 align-items-start">
      <div class="label-container">
        <mat-label class="field-label">What do you do if you do not get your medication on time?</mat-label>
      </div>
    </div>
    <div class="form-row row7 align-items-start">
      <div class="input-container">
        <mat-radio-group formControlName="medicationOnTimeAction">
          <mat-radio-button value="1">A. Do Nothing</mat-radio-button>
          <mat-radio-button value="2">B. Tell a Grown Up</mat-radio-button>
          <mat-radio-button value="3">C. Tell the Teacher</mat-radio-button>
          <mat-radio-button value="4">C. Drink More Water</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="form-row row8">
      <div class="label-container mb-3">
        <mat-label class="field-label">What Important fact do you need to know about taking your medications?</mat-label>
      </div>
      <div class="input-container">
        <mat-radio-group formControlName="medicationImportant">
          <mat-radio-button value="1">A. What my medication/s for</mat-radio-button>
          <mat-radio-button value="2">B. At what time do I take my medication?</mat-radio-button>
          <mat-radio-button value="3">C. What is the name of my medication?</mat-radio-button>
          <mat-radio-button value="4">D. What are some of the side effects?</mat-radio-button>
          <mat-radio-button value="5">E. All of the Above</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="form-row row9 align-items-start">
      <div class="label-container">
        <mat-label class="field-label">What do you need to know about the summer heat and the sun?</mat-label>
      </div>
    </div>
    <div class="form-row row9 align-items-start">
      <div class="input-container">
        <mat-radio-group formControlName="medicationOnSummer">
          <mat-radio-button value="1">A. When to wear a hat or cover up?</mat-radio-button>
          <mat-radio-button value="2">B. How to apply sunscreen?</mat-radio-button>
          <mat-radio-button value="3">C. How much water I should drink?</mat-radio-button>
          <mat-radio-button value="4">D. All of the Above</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="form-row row10 mb-2">
      <p class="additional-info">I have received a copy of a Medication Fact Sheet for this medication in addition to receiving the above information.</p>
    </div>
    <div class="form-row no-gap row11">
      <!-- <div class="label-container">
      <mat-label class="field-label treat-label mr-2">Client’s Signature:</mat-label>
      </div>
      <div class="input-container">
      <mat-form-field appearance="fill" class="form-field">
          <input matInput required>
      </mat-form-field>
      </div> -->
      <div class="col-md-12 pl-0">
        <div class="form-group clearfix m-0">
          <div class="float-left col-md-4 p-0 mt-3 pt-1">
            <label class="treat-label mr-2 pl-0" style="padding-left :9px;">Client’s Signature:</label>
            <img id='esign' height="50px" width="300px" formControlName="signatureImg" style="border: 1px solid grey;">
          </div>
          <div class="float-left col-md-4 p-0 mt-3 pt-1 ">

            <button type="button" (click)="openDialog()" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2">
              Add E-Sign
            </button>
          </div>

        </div>
      </div>
    </div>
    <div class="form-row row12">
      <p class="additional-info">I have received the above information and Fact sheet with this Resident</p>
    </div>
    <div class="form-row row14">
      <div class="label-container">
        <mat-label class="field-label">MD/APN</mat-label>
      </div>

      <div class="input-container">
        <input matInput formControlName="staffName" name="staffName">
      </div>
      <p class="text-center">Print</p>


      <div class="input-container">
        <div class="form-group clearfix m-0 col-md-10">
          <div class="float-left p-0 mt-3 pt-1">
            <img id='esign2' height="50px" width="300px" formControlName="signatureImg2" style="border: 1px solid grey;">
            <label class="treat-label mr-2 pl-0" style="padding-left: 9px;">Signature</label>
            <div>
              <button type="button" (click)="openDialog2()" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2">
                Add E-Sign
              </button>
            </div>
          </div>
          <div class="input-container">
            <mat-form-field class="button-container">
              <input matInput [matDatepicker]="pstpicker2" (click)="pstpicker2.open()" formControlName="currentDate2" name="cstartDates">
              <mat-datepicker-toggle matSuffix [for]="pstpicker2"></mat-datepicker-toggle>
              <mat-datepicker #pstpicker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="form-row row14">
        <div class="label-container">
            <mat-label class="field-label">Nurse</mat-label>
        </div>

        <div class="input-container">
          <mat-form-field appearance="fill">
            <input matInput formControlName="NurseName" name="NurseName">
            </mat-form-field>
          <p class="text-center">Print</p>
      </div>

        <div class="input-container">
            <div class="form-group clearfix m-0 col-md-10">
                <div class="float-left p-0 mt-3 pt-1">
                    <img id='esign3' height="50px" width="300px" formControlName="signatureImg3" style="border: 1px solid grey;">
                    <label class="treat-label mr-2 pl-0" style="padding-left: 9px;">Signature</label>
                    <div>
                        <button type="button" (click)="openDialog3()" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2">
                            Add E-Sign
                        </button>
                    </div>
                </div>

                <div class="input-container">
                    <mat-form-field class="button-container">
                        <input matInput [matDatepicker]="pstpicker3" (click)="pstpicker3.open()" formControlName="currentDate3" name="cstartDates">
                        <mat-datepicker-toggle matSuffix [for]="pstpicker3"></mat-datepicker-toggle>
                        <mat-datepicker #pstpicker3></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div> -->


  </form>
</div>
<a mimetype="application/octet-stream" #downloadLink></a>
<a mimetype="application/octet-stream" #mainpage></a>

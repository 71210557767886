<div class="schdule-modality">
   <div class="col-md-12 pr-0">
      <div class="top-btn-section col-md-12 p-0 clearfix mb-1 w-100">
         <div class="head-section mt-2">Create Schedule Modality</div>
         <div class="ml-auto">
            <button type="button" mat-stroked-button (click)="onBack()"
               class="top-back mb-2 h-40 mr-2">Back</button>
         </div>
      </div>
   </div>
   <div class="Modality-view-make">
      <div class="col-md-12 bg-white p-3  mb-3">
         <div class="row">
            <div class="col-md-2">
               <div class="d-flex align-items-center">
                  <div class="image"> <img src="https://bootdey.com/img/Content/avatar/avatar7.png" class="rounded"
                        width="60"> </div>
                  <div class="ml-3 w-100 d-flex">
                     <div class="mr-3 mt-3">
                        <h5 class="mb-0 mt-0 text-md">{{_dtoTreatmentInfo?.patientName}}</h5>
                        <span class="text-xs text-dark font-weight-500">{{_dtoTreatmentInfo?.dob}} Years</span>
                     </div>

                  </div>
               </div>
            </div>
            <div class="col-md-10 ">
               <div class="row">
                  <div class="col-md-2">
                     <label class="head-modality">Therapy</label>

                  </div>
                  <div class="col-md-2">
                     <label>Frequency</label>

                  </div>
                  <div class="col-md-2" *ngIf="roleAccess.isCreate || roleAccess.isEdit ">
                     <label>Actions</label>

                  </div>
                  <div class="col-md-7">


                  </div>
               </div>
               <div class="row form-group" *ngFor="let item of _dtoModality;let i =index">
                  <div class="col-md-2">
                     <div class="view-value">
                        {{item.therapyName}}
                     </div>
                  </div>
                  <div class="col-md-2" >
                     <div class="view-value" *ngIf="!(item.therapyId==4 ||item.therapyId==7 || item.therapyId==8 )">
                        {{item.frequency}} {{item.frequencyWeekValue}}
                     </div>
                  </div>
                  <div class="col-md-2">
                     <button *ngIf="item.scheduleModalityId != 0 && item.therapyId !=3 && (roleAccess.isView)" type="button"
                        (click)="onViewModality(item)" class="btn btn primary-btn-filled-small primary-btn-m w-100">View
                        Schedule</button>
                     <button *ngIf="item.scheduleModalityId == 0  && item.therapyId !=3 && (roleAccess.isCreate || roleAccess.isEdit)" type="button"
                        class="btn btn primary-btn-filled-small primary-btn-m w-100"
                        (click)="onScheduleModality(item)">Schedule Modality</button>
                     <button *ngIf="item.therapyId == 3  && (roleAccess.isCreate || roleAccess.isEdit || roleAccess.isView)" type="button"
                        class="btn btn primary-btn-filled-small primary-btn-m w-100"
                        (click)="onGroupTherapy(item)">Assign Group Therapy</button>
                     <!-- <button *ngIf="item.therapyId == 3 && item.groupSummary != null" type="button"
                        class="btn btn primary-btn-filled-small primary-btn-m w-100"
                        (click)="onIsViewTherapy(item)">View Group Therapy</button> -->
                  </div>
                  <div class="col-md-6">
                     <div class="info-modality fade show" *ngIf="item.modalitySummary !=null"><i
                           class="far fa-clock mr-2"></i>{{item.modalitySummary}}</div>
                     <ng-container *ngIf="firstCharfromString(item.groupSummary)">
                        <div class="info-modality fade show" *ngIf="item.therapyId == 3"><i
                              class="far fa-clock mr-2"></i><span innerHTML={{item.groupSummary}}></span></div>
                     </ng-container>
                  </div>
               </div>

            </div>
         </div>
      </div>

   </div>
   <!-- <div>
      <div class="schedule-summary" *ngIf="isViewModality">
         Scheduled for <b>{{therapyName}}</b> having {{modalitySummary}}
      </div>
   </div> -->
   <div class="col-md-12 bg-white pt-2  pb-2 " *ngIf="isModalityScreen "  [ngClass]="{'disabled':isViewModality}"> 
      <h2 class="mb-1 sub-inc">{{isViewModality ? 'View':'Generate'}} Schedule - <span>{{therapyName}}</span></h2>
      <div class="row ">
         <div class="col-md-12  p-3">
            <form (ngSubmit)="scheduleModality.form.valid  && getConflictsModality()" id="scheduleModality"
               autocomplete="off" #scheduleModality="ngForm" novalidate>

               <div class="mb-2 d-flex justify-content-center">
                  <div class="card p-3 w-100">
                     <div class="">

                        <div class="col-md-12 mb-3">
                           <div class="row">
                              <div class="col-md-4 mb-2">
                                 <div class="row">
                                    <label class="control-label-new col-md-3 font-weight-bold mt-2">Provider</label>
                                    <div class="col-md-9">
                                       <select class="form-control" name="Provider"
                                          [(ngModel)]="dtoScheduleModality.provider" #provider="ngModel" name="provider"
                                          required>
                                          <option value="undefined">--Select Provider--</option>
                                          <option *ngFor="let a of ddlStafflist" value="{{a.staffId}}">
                                              {{a.firstName}} {{a.lastName}} 
                                          </option>
                                       </select>
                                       <mat-error *ngIf="scheduleModality.submitted && provider.invalid">
                                          <mat-error *ngIf="provider.errors.required">Provider is required
                                          </mat-error>
                                       </mat-error>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-4 mb-2">
                                 <div class="row">
                                    <label class="control-label-new col-md-3 font-weight-bold mt-2">Start Date</label>
                                    <div class="col-md-9">

                                       <!-- [min]="treatmentPlanDate" -->
                                       <mat-form-field class="example-full-width" class="w-100  mt--14">
                                          <mat-label>Choose a date</mat-label>
                                          <input required matInput [matDatepicker]="picker2" readonly
                                             [min]="currentdate < treatmentPlanDate?treatmentPlanDate: currentdate"  (dateChange)="handleDateChange($event)"
                                             [(ngModel)]="dtoScheduleModality.startDate" [max]="treatmentenddate"
                                             #startDate="ngModel" name="startDate" />
                                          <mat-datepicker-toggle matSuffix [for]="picker2">
                                          </mat-datepicker-toggle>
                                          <mat-datepicker #picker2></mat-datepicker>
                                          <mat-error *ngIf="scheduleModality.submitted && startDate.invalid">
                                             <mat-error *ngIf="startDate.errors.required">Start Date is required
                                             </mat-error>
                                          </mat-error>
                                       </mat-form-field>

                                    </div>
                                 </div>
                              </div>

                              <div class="col-md-4 mb-2">
                                 <div class="row">
                                    <label class="control-label-new col-md-4 font-weight-bold mt-2">Start Time</label>
                                    <div class="col-md-8">

                                       <input class="form-control" type="time" #startTime="ngModel"
                                          [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currentTime"
                                          max="24:00" required>
                                       <mat-error *ngIf="scheduleModality.submitted && startTime.invalid">
                                          <mat-error *ngIf="startTime.errors.required">Start Time is required
                                          </mat-error>
                                       </mat-error>
                                       <mat-error *ngIf="scheduleModality.submitted && startTime.value <currentTime">
                                         Start Time is invalid                                        
                                       </mat-error>
                                    </div>
                                   
                                 </div>
                              </div>
                              <div class="col-md-4 mb-2">
                                 <div class="row">
                                    <label class="control-label-new col-md-3 font-weight-bold mt-2">Duration</label>
                                    <div class="col-md-9">

                                       <select class="form-control" name="duration"
                                          [(ngModel)]="dtoScheduleModality.duration" #duration="ngModel" required>
                                          <option value="undefined">--Select Duration--</option>
                                          <option value=15>15 mins</option>
                                          <option value=30>30 mins</option>
                                          <option value=60>1 hr</option>
                                          <option value=120>2 hr</option>
                                          <option value=180>3 hr</option>
                                          <option value=240>4 hr</option>
                                          <option value=300>5 hr</option>
                                          <option value=360>6 hr</option>
                                          <option value=420>7 hr</option>
                                          <option value=480>8 hr</option>
                                       </select>
                                       <mat-error *ngIf="scheduleModality.submitted && duration.invalid">
                                          <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                             *ngIf="duration.errors.required">Duration is required</mat-error>
                                       </mat-error>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-4 mb-2">
                                 <div class="row">
                                    <label class="control-label-new col-md-3 font-weight-bold mt-2">Location</label>
                                    <div class="col-md-9">

                                       <select class="form-control" [(ngModel)]="dtoScheduleModality.location" 
                                          #location="ngModel" (ngModelChange)= "getMeetingLocation()" name="location" required>
                                          <option value="undefined">--Select Location--</option>
                                          <option *ngFor="let a of ddllocation" value="{{a.sfccMasterId}}">
                                             {{a.locationName}}
                                          </option>
                                       </select>
                                       <mat-error *ngIf="scheduleModality.submitted && location.invalid">
                                          <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                             *ngIf="location.errors.required">Location
                                             is
                                             required</mat-error>
                                       </mat-error>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-4 mb-2">
                                 <div class="row">
                                    <label class="control-label-new col-md-4 font-weight-bold mt-2">Meeting Room</label>
                                    <div class="col-md-8">

                                       <select class="form-control" [(ngModel)]="dtoScheduleModality.meetingRoom" 
                                          #meetingRoom="ngModel" name="meetingRoom" required>
                                          <option value="undefined">--Select Location--</option>
                                          <option *ngFor="let a of ddlMeetinglocation" value="{{a.meetingLocationsId}}">
                                             {{a.meetingName}}
                                          </option>
                                       </select>
                                       <mat-error *ngIf="scheduleModality.submitted && meetingRoom.invalid">
                                          <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                             *ngIf="meetingRoom.errors.required">Meeting Room
                                             is
                                             required</mat-error>
                                       </mat-error>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-4 mb-2 mt-3">
                                 <div class="row">
                                    <label class="control-label-new col-md-3 font-weight-bold mt-2">Meeting Type</label>
                                    <div class="col-md-9">

                                        <select class="form-control"  [(ngModel)]="dtoScheduleModality.meetingTypeId" #meetingTypeId="ngModel" name="meetingTypeId" required>
                                          <option value="undefined">--Select Meeting Type--</option>
                                          <option *ngFor="let item of ddlMeetingType " [value]="item.msMeetingTypeId" >{{item.meetingType}}</option>
                                      </select>
                                      <mat-error *ngIf="scheduleModality.submitted && meetingTypeId.invalid">
                                       <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                          *ngIf="meetingTypeId.errors.required">Meeting Type is required</mat-error>
                                    </mat-error>
                                   </div>
                                 </div>
                              </div>
                              <div class="col-md-12 p-0">
                              <div class="col-md-4 p-0 mt-2" *ngIf="!(therapyId==4 ||therapyId==7 ||therapyId ==8 )">
                                 <label class="control-label-new col-md-7 font-weight-bold">Do you Want to Set
                                    Recurrence?</label>
                                 <mat-radio-group aria-label="Select an option"
                                    class="custom-radio d-inline-block align-middle mt-2" name="isRecurance"
                                    [(ngModel)]="dtoScheduleModality.isRecurance" #isRecurance="ngModel">
                                    <mat-radio-button [value]="true" (click)="dtoScheduleModality.isRecurance = true"
                                       class="mr-2">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false" (click)="dtoScheduleModality.isRecurance = false">
                                       No</mat-radio-button>
                                 </mat-radio-group>
                              </div>
                           </div>
                           </div>
                        </div>


                     </div>
                     <div class="col-md-12 pl-0 pr-0" *ngIf="dtoScheduleModality.isRecurance">
                        <div class="top-2-modality">
                           <div class="one-style">
                              <h2>Recurrence Pattern</h2>
                           </div>
                           <div class="">

                              <div class="col-md-12 mb-2">
                                 <div class="row">

                                    <div class="col-md-12">
                                       <!-- <label class="control-label-new col-md-12 font-weight-bold mt-2">Recurrence
                                          pattern</label> -->
                                       <div class="col-md-12 p-0 mb-2">
                                          <div class="row">
                                             <div class="col check-custom large-font">
                                                <mat-checkbox class="example-margin"
                                                   [(ngModel)]="dtoScheduleModality.isSunday" #sunday="ngModel"
                                                   name="sunday" (change)="onWeekDaySelection($event.checked,0)">Sunday
                                                </mat-checkbox>
                                             </div>
                                             <div class="col  check-custom large-font">
                                                <mat-checkbox class="example-margin"
                                                   [(ngModel)]="dtoScheduleModality.isMonday" #monday="ngModel"
                                                   name="monday" (change)="onWeekDaySelection($event.checked,1)">Monday
                                                </mat-checkbox>
                                             </div>
                                             <div class="col  check-custom large-font">
                                                <mat-checkbox class="example-margin"
                                                   [(ngModel)]="dtoScheduleModality.isTuesday" #tuesday="ngModel"
                                                   name="tuesday" (change)="onWeekDaySelection($event.checked,2)">
                                                   Tuesday</mat-checkbox>
                                             </div>
                                             <div class="col check-custom large-font">
                                                <mat-checkbox class="example-margin"
                                                   [(ngModel)]="dtoScheduleModality.isWednesday" #wednesday="ngModel"
                                                   name="wednesday" (change)="onWeekDaySelection($event.checked,3)">
                                                   Wednesday</mat-checkbox>
                                             </div>
                                             <div class="col  check-custom large-font">
                                                <mat-checkbox class="example-margin"
                                                   (change)="onWeekDaySelection($event.checked,4)"
                                                   [(ngModel)]="dtoScheduleModality.isThursday" #thursday="ngModel"
                                                   name="thursday">Thursday</mat-checkbox>
                                             </div>
                                             <div class="col  check-custom large-font">
                                                <mat-checkbox class="example-margin"
                                                   (change)="onWeekDaySelection($event.checked,5)"
                                                   [(ngModel)]="dtoScheduleModality.isFriday" #friday="ngModel"
                                                   name="friday">Friday</mat-checkbox>
                                             </div>
                                             <div class="col check-custom large-font">
                                                <mat-checkbox class="example-margin"
                                                   (change)="onWeekDaySelection($event.checked,6)"
                                                   [(ngModel)]="dtoScheduleModality.isSaturday" #saturday="ngModel"
                                                   name="saturday">Saturday</mat-checkbox>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-12 p-0">
                                       <label class="control-label-new col-md-12 font-weight-bold mt-2">Expected End
                                          Date
                                       </label>
                                       <div class="col-md-12 p-0">
                                          <div class="d-inline-block mt--14">
                                             <mat-form-field class="w-100">
                                                <!-- <mat-label>Choose a date</mat-label> [min]="treatmentPlanDate"-->
                                                <input required matInput [matDatepicker]="picker3" readonly
                                                   (click)="picker3.open()" [(ngModel)]="dtoScheduleModality.endDate"
                                                   #endDate="ngModel" name="endDate" [min]="currentdate"
                                                   [max]="treatmentenddate" />
                                                <mat-datepicker-toggle matSuffix [for]="picker3">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker3></mat-datepicker>
                                                <mat-error *ngIf="scheduleModality.submitted && endDate.invalid">
                                                   <mat-error *ngIf="endDate.errors.required">End Date is required
                                                   </mat-error>
                                                </mat-error>
                                             </mat-form-field>
                                          </div>
                                       </div>
                                    </div>

                                 </div>
                              </div>


                           </div>
                           <div class="">

                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-md-12">
                  <div class="row">

                     <div class="col-md-12 text-right mt-2 pr-0 mt-3" *ngIf="!isViewModality  && (roleAccess.isCreate || roleAccess.isEdit)">
                        <button class="btn primary-btn-filled float-right " type="submit"
                           form="scheduleModality">Generate Schedule</button>
                        <button class="btn btn-cancel primary-btn-m h-40 mr-2" type="button"
                           (click)="onCancelModality()">Cancel</button>
                     </div>
                     <div class="col-md-12 p-0" *ngIf="scheduleModalityList?.length>0 && !(therapyId==4 ||therapyId==7 ||therapyId ==8 ) " >
                        <div class="col-md-12 p-0">
                           <h2>Schedule List</h2>
                           <div class="schedule-summary">
                              Schedule for <b>{{therapyName}}</b> having {{scheduleModalityList?.length}} Meetings from
                              {{dtoScheduleModality.startDate | date:'MM/dd/yyyy'}} till {{dtoScheduleModality.endDate |
                              date:'MM/dd/yyyy'}}
                           </div>
                           <div class="table-responsive">
                              <form (ngSubmit)="scheduleConflictModality.form.valid  && submitModality()"
                                 id="scheduleConflictModality" autocomplete="off" #scheduleConflictModality="ngForm"
                                 novalidate>
                                 <table class="table modality-table">
                                    <thead>
                                       <tr>
                                          <!-- <th scope="col"></th> -->
                                          <th scope="col">#</th>
                                          <!-- <th scope="col">Day</th> -->
                                          <th scope="col">Schedule Date</th>
                                          <th scope="col">Start Time</th>
                                          <th scope="col">End Time</th>
                                          <th scope="col"></th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <ng-container *ngFor="let item of scheduleModalityList;let i = index">
                                          <tr [ngClass]="item.isConflict === true ? 'conflict-cell' : ''">
                                             <!-- <td>
                                                <input type="checkbox" />
                                             </td> -->
                                             <td>
                                                <input type="checkbox" name="isChecked"
                                                   [checked]="item.isChecked == true ? true:false"
                                                   value="item.isChecked" (change)="updateItem(item, $event)">
                                             </td>
                                             <!-- <td>{{item.day}}</td> -->
                                             <td>{{item.scheduleDate}}</td>
                                             <td>{{item.fromTime}}</td>
                                             <td>{{item.toTime}}</td>
                                             <td><button mat-icon-button matTooltip="Delete" role="button" *ngIf="!isViewModality"
                                                   (click)="ondeleteSchdule(item)" color="warn">
                                                   <img src="../../../assets/images/deletered.png">
                                                </button></td>
                                          </tr>
                                       </ng-container>
                                    </tbody>
                                 </table>
                              </form>
                           </div>
                        </div>
                        <div class="col-md-12 text-right mt-2" *ngIf="!isViewModality">
                           <button class="btn primary-btn-filled-small float-right ml-2" type="button"
                              (click)="postSchduleModality()">Create {{therapyName}}</button>
                           <button class="btn primary-btn-filled-small float-right" type="button"
                              (click)="skipAllConflicts()"><i class="fas fa-forward mr-2"></i> Skip All
                              Conflicts</button>
                           <button class="btn primary-btn-filled-small float-right mr-2" type="button"
                              (click)="skipConflicts()"><i class="fas fa-forward mr-2"></i> Skip</button>

                        </div>
                     </div>
                     <div class="col-md-12 p-0" *ngIf="!(therapyId==4 ||therapyId==7 ||therapyId ==8 )" >
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="errConflicts">
                           Please skip the Conflicts
                        </mat-error>
                     </div>
                     <div class="col-md-12 p-0" *ngIf="scheduleModalityList?.length>0 && (therapyId==4 ||therapyId==7 ||therapyId ==8 )" >
                        <div class="col-md-12 p-0">
                           <h2>Schedule List</h2>
                           <div class="schedule-summary">
                              Schedule for <b>{{therapyName}}</b> having {{scheduleModalityList?.length}} Meetings from
                              {{dtoScheduleModality.startDate | date:'MM/dd/yyyy'}} till {{dtoScheduleModality.endDate |
                              date:'MM/dd/yyyy'}}
                           </div>
                           <div class="table-responsive">
                              <form (ngSubmit)="scheduleConflictModality.form.valid  && submitModality()"
                                 id="scheduleConflictModality" autocomplete="off" #scheduleConflictModality="ngForm"
                                 novalidate>
                                 <table class="table modality-table">
                                    <thead>
                                       <tr>
                                          <!-- <th scope="col"></th> -->
                                          <!--<th scope="col">#</th>-->
                                          <!-- <th scope="col">Day</th> -->
                                          <th scope="col">Schedule Date</th>
                                          <th scope="col">Start Time</th>
                                          <th scope="col">End Time</th>
                                          <th scope="col"></th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <ng-container >
                                          <tr [ngClass]="scheduleModalityList[0].isConflict === true ? 'conflict-cell' : ''">
                                             <!-- <td>
                                                <input type="checkbox" />
                                             </td> -->
                                             <!--<td>
                                                <input type="checkbox" name="isChecked"
                                                   [checked]="scheduleModalityList[0].isChecked == true ? true:false"
                                                   value="scheduleModalityList[0].isChecked" (change)="updateItem(item, $event)">
                                             </td>-->
                                             <!-- <td>{{item.day}}</td> -->
                                             <td>{{scheduleModalityList[0].scheduleDate}}</td>
                                             <td>{{scheduleModalityList[0].fromTime}}</td>
                                             <td>{{scheduleModalityList[0].toTime}}</td>
                                             <!--<td><button mat-icon-button matTooltip="Delete" role="button"
                                                   (click)="ondeleteSchdule(item)" color="warn">
                                                   <img src="../../../assets/images/deletered.png">
                                                </button></td>-->
                                          </tr>
                                       </ng-container>
                                    </tbody>
                                 </table>
                              </form>
                           </div>
                        </div>
                        <div class="col-md-12 text-right mt-2" *ngIf="!isViewModality">
                           <button class="btn primary-btn-filled-small float-right ml-2" type="button"
                              (click)="postSchduleModality()">Create {{therapyName}}</button>
                           <!--<button class="btn primary-btn-filled-small float-right" type="button"
                              (click)="skipAllConflicts()"><i class="fas fa-forward mr-2"></i> Skip All
                              Conflicts</button>-->
                           <!--<button class="btn primary-btn-filled-small float-right mr-2" type="button"
                              (click)="skipConflicts()"><i class="fas fa-forward mr-2"></i> Skip</button>-->

                        </div>
                     </div>
                     <div class="col-md-12 p-0" *ngIf="(therapyId==4 ||therapyId==7 ||therapyId ==8 )">
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="errConflicts">
                           Please skip the Conflicts
                        </mat-error>
                     </div>
                  </div>
               </div>


            </form>
         </div>

      </div>
   </div>
   <!-- modality -->
   <div class="col-md-12 bg-white pt-2  pb-2 " *ngIf="isGroupTherapyScreen">
      <div class=" text-left">
         <div class="assign-heading">Assigned Group Meetings ({{assignedtotal}})</div>
      </div>
      <div class="multiple-gt">
         <div class="col-md-12 p-0">
            <div class="listing-questions">
               <ul class="row nomargin p-0 m-0">
                  <ng-container *ngFor="let item of Decrptedlst;let i = index">
                     <li class="col-md-4 " *ngIf="item.isAssigned">
                        <div class="card">
                           <div class="card-body ">
                              <div class="col-md-12 p-0">
                                 <div class="formed-type ">
                                    <span class="text-truncate d-block" title="Dummy B">{{item.therapyName}}</span>
                                    <label>Meeting Name</label>
                                 </div>
                              </div>
                              <div class="col-md-12 p-0">
                                 <div class="date-desined">
                                    <i class="fas fa-user-check mr-2"></i> Assigned on {{item.assignDate |
                                    date:'MM/dd/yyyy'}}
                                 </div>
                              </div>
                              <div class="col-md-12 date-picked-assign" *ngIf="item.releaseDateEdit">
                                 <div class="row">
                                 <div class="col-md-6 ">
                                    <mat-form-field class="w-100">
                                       <mat-label>Release Date</mat-label>
                                       <input matInput [matDatepicker]="picker1" readonly customdate
                                          sDate="{{assignDate}}"
                                          [min]="assignDate != undefined ? assignDate : currentdate<startDate?startDate:currentdate  "
                                          [max]="endDate" (click)="picker1.open()"
                                          #releaseDate1="ngModel" name="releaseDates{{i}}"
                                          [(ngModel)]="releaseDates" required>
                                       <mat-datepicker-toggle matSuffix [for]="picker1">
                                       </mat-datepicker-toggle>
                                       <mat-datepicker #picker1></mat-datepicker>
                              
                                    </mat-form-field>
                                    <mat-error class="mat-form-field-subscript-wrapper"
                                       *ngIf="isReqAssignTherapy && releaseDates == undefined ">Release date
                                       is
                                       required
                                    </mat-error>
                              
                                 </div>
                                 <div class="col-md-6 RdateEdit">
                                    <ng-container >
                                       <a 
                                          class="assign-view-btn  cursor-pointer ml-2" (click)="onbookEndDate(item)"
                                          role="button" title="Assign">Save</a>
                                    </ng-container>
                                 </div>
                                 </div>
                              </div>
                              <div class="col-md-12 p-0 date-bottom mt-2">
                                 <div class="row">
                                    <div class="col-md-8 text-left">
                                       <span>Release Date: {{item.releaseDate | date:'MM/dd/yyyy'}}</span>
                                    </div>
                                    <div class="col-md-1 text-right" *ngIf="item.isAssigned">
                                       <button class="col text-center edit_icon" (click)="endDateEdit(item,i)" role="button" title="Edit"><i class="far fa-edit"
                                          aria-hidden="true" ></i></button>
                                    </div>
                                    
                                    <div class="col-md-3 text-right">
                                       <a *ngIf="item.isAssigned && (roleAccess.isCreate || roleAccess.isEdit)" (click)="onCancelSlots(item)"
                                          class="release-view-btn  cursor-pointer ml-2" role="button" title="Release">
                                          Release</a>
                                    </div>
                                    
                                 </div>
                              </div>
                           </div>
                        </div>
                     </li>

                  </ng-container>
               </ul>
            </div>
         </div>
      </div>
      
      <div class=" text-left">
         <div class="assign-heading">Other Group Meetings</div>
      </div>
      <div class="col-md-12 p-0">
         <!-- <div class="btn-group w-100 not-selection-view">
            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
               (viewDateChange)="closeOpenMonthViewDay()">
               <i class="fas fa-chevron-left"></i>
            </div>
            <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
               {{viewDate | calendarDate:(view + 'ViewTitle'):'en'}}
            </div>
            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
               (viewDateChange)="closeOpenMonthViewDay()">
               <i class="fas fa-chevron-right"></i>
            </div>
         </div>

         <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
               [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"   (beforeViewRender)="updateCalendarEvents($event)"
               (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
               [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
               (eventTimesChanged)="eventTimesChanged($event)"   (beforeViewRender)="updateCalendarEvents($event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
               [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
               (eventTimesChanged)="eventTimesChanged($event)"   (beforeViewRender)="updateCalendarEvents($event)">
            </mwl-calendar-day-view>
         </div> -->
         <div class="multiple-gt">
            <div class="col-md-12 p-0">
               <div class="listing-questions">
                  <ul class="row nomargin p-0 m-0">
                     <ng-container *ngFor="let item of lstGroupMeeting;let i=index">
                        <li class="col-md-4 " *ngIf="!item.isAssigned">
                           <div class="card">
                              <div class="card-body ">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <div class=" position-relative">


                                          <div class="formed-type ">
                                             <span class="text-truncate d-block"
                                                title="Dummy B">{{item.therapyName}}</span>
                                             <label>Meeting Name</label>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="col-md-12 ">
                                       <div class=" position-relative">

                                          <div class="d-flex text-center">
                                             <div class="formed-type2 mr-2  slot-detail">
                                                <label class="text-info"><i class="fas fa-couch mr-2"></i>Total Slots
                                                   -</label>
                                                <span class="pl-1">
                                                   {{item.capacity}}
                                                </span>
                                             </div>
                                             <div class="formed-type2 mr-2  slot-detail ">
                                                <label [ngClass]="(item.capacity - item.booked) == 0 ? 'text-danger':'text-success'"><i class="fas fa-couch mr-2"></i>Available
                                                   Slots -</label>
                                                <span class="pl-1">{{item.capacity - item.booked}}</span>
                                             </div>
                                          </div>

                                       </div>
                                    </div>
                                    <!-- <div class="col-md-12 ">
                                    <div  class="py-3 position-relative">
                                      <div class="d-flex">
                                      <div class="head-list-gt"><i class="fas fa-user-check mr-2"></i> Assign </div>
                                        <div class="ml-auto"> </div></div>
                                          <div class="assign-data" *ngIf="item.isAssigned">
                                             Assigned on {{item.updateddate | date:'MM/dd/yyyy'}}
                                          </div>
                                      </div>
                                   </div>
                                   <div class="col-md-12 ">
                                    <div  class="py-3  position-relative">
                                      <div class="d-flex">
                                        <div class="head-list-gt"><i class="fas fa-sign-out-alt mr-2"></i> Release </div>
                                          <div class="ml-auto"></div></div>
                                          <div class="assign-data">
                                            Release on 23/11/2021
                                          </div>
                                      </div>
                                   </div>  -->

                                    <div class="col-md-12 date-picked-assign" *ngIf="i === isShowAssign">
                                       <div class="row">
                                          <p>Choose a date to Assign</p>
                                          <div class="col-md-6">
                                             <mat-form-field class="w-100">
                                                <mat-label>Assign Date</mat-label>
                                                <!-- [min]="currentdate" -->
                                                <!-- [min]="currentdate<startDate?startDate:currentdate" [max]="endDate" -->
                                                <input matInput [matDatepicker]="picker" readonly
                                                   (click)="picker.open()" #assignDate1="ngModel" name="assignDate{{i}}"
                                                   [(ngModel)]="assignDate" required>
                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>

                                             </mat-form-field>
                                             <mat-error class="mat-form-field-subscript-wrapper"
                                                *ngIf="isReqAssignTherapy && assignDate == undefined ">Assign date is
                                                required
                                             </mat-error>
                                          </div>
                                          <div class="col-md-6">
                                             <mat-form-field class="w-100">
                                                <mat-label>Release Date</mat-label>
                                                
                                                <input matInput [matDatepicker]="picker1" readonly customdate
                                                   sDate="{{assignDate}}"
                                                   [min]="assignDate != undefined ? assignDate : currentdate<startDate?startDate:currentdate  "
                                                      [max]="endDate"(click)="picker1.open()"
                                                   #releaseDate1="ngModel" name="releaseDate{{i}}"
                                                   [(ngModel)]="releaseDate" required>
                                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>

                                             </mat-form-field>
                                             <mat-error class="mat-form-field-subscript-wrapper"
                                                *ngIf="isReqAssignTherapy && releaseDate == undefined ">Release date
                                                is
                                                required
                                             </mat-error>

                                          </div>
                                          <mat-error class="mat-form-field-subscript-wrapper w-100"
                                             *ngIf="releaseDate1.errors?.customdate">
                                             Release date should be greater then assigns date.
                                          </mat-error>
                                       </div>
                                    </div>

                                    <div class="col-md-12 mt-2">

                                       <div class="d-flex float-right">

                                          <a class="slot-view-btn cursor-pointer " role="button" *ngIf="  roleAccess.isView"
                                             (click)="onIsViewTherapy(item)" title="View Slot">View Slot</a>
                                          <ng-container *ngIf="i === isShowAssign">
                                             <a *ngIf="item.isAssigned == false && (item.capacity - item.booked) > 0&& (roleAccess.isCreate || roleAccess.isEdit)"
                                                class="assign-view-btn  cursor-pointer ml-2" (click)="onbookSlots(item)"
                                                role="button" title="Assign">Save</a>
                                          </ng-container>

                                          <ng-container *ngIf="i !== isShowAssign">
                                             <a *ngIf="item.isAssigned == false && (item.capacity - item.booked) > 0 && (roleAccess.isCreate || roleAccess.isEdit)"
                                                class="assign-view-btn  cursor-pointer ml-2" (click)="onAssignClick(i)"
                                                role="button" title="Assign">Assign</a>
                                          </ng-container>
                                          <a *ngIf="item.isAssigned && (roleAccess.isCreate || roleAccess.isEdit)" (click)="onCancelSlots(item)"
                                             class="release-view-btn  cursor-pointer ml-2" role="button"
                                             title="Release">
                                             Release</a>


                                       </div>
                                    </div>

                                    <!-- <div class="col-md-3 border-left"
                                    *ngIf="item.isAssigned == false && (item.capacity - item.booked) > 0">
                                    <div class="py-3 position-relative">
                                       <div class="d-flex">
                                          <div class="head-list-gt"><i class="fas fa-user-check mr-2"></i> Assign </div>
                                          <div class="ml-auto"><a class="assign-view-btn">Assign</a></div>
                                       </div>
                                       <div class="assign-data">
                                          Assigned on 23/11/2021
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-3 border-left" *ngIf="item.isAssigned">
                                    <div class="py-3 pr-3 position-relative">
                                       <div class="d-flex">
                                          <div class="head-list-gt"><i class="fas fa-sign-out-alt mr-2"></i> Release
                                          </div>
                                          <div class="ml-auto"><a class="release-view-btn">Release</a></div>
                                       </div>
                                       <div class="assign-data">
                                          Assigned on {{item.updateddate | date:'MM/dd/yyyy'}}
                                       </div>
                                    </div>
                                 </div> -->
                                 </div>
                              </div>
                           </div>
                        </li>
                     </ng-container>
                  </ul>
               </div>
               <div class="col-md-12 w-100">
                  <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="5"
                     [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>






import { Component, OnInit, ViewChild ,Input} from '@angular/core';
import { MessagesService } from '../messages/messages.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { constantVariables } from 'src/app/constant/constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { Global } from 'src/app/global/global.model';
import { CommonService } from 'src/app/common/common.service';
import * as _ from "lodash";
import { SendEmail } from 'src/app/_models/_Common/sendEmail';
import * as moment from 'moment';
import { LeadService } from 'src/app/patient-lead/lead.service';
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  @Input()  id: number;
  @ViewChild('mailUploadfile', { static: true }) mailUploadfile;
  inboxMessage: any;
  messagedetails: any;
  rpycomments: any;
  currentId: any;
  subjectTag = "";
  isShowreply: boolean = false;
  issentmsg: boolean = false;
  inboxcount: any;
  selectedIndex: number = null;
  isExpandMsgpanel: boolean = false;
  isminimizedEmail: boolean = false;
  constructor(private msgservice: MessagesService, public spinnerservice: NgxSpinnerService, public leadservice: LeadService,
    private snackbar: SnackBarService, public global: Global, public commonService: CommonService,) { }

  ngOnInit(): void {
    this.global.mailattachment = [];
    this.global.toemailList = [];
    this.global.ccemailList = [];
    this.getInboxMessage();
    this.global.mailinfo = new SendEmail();
  }

  getInboxMessage() {
    this.spinnerservice.show();
    var inputData = {
      Limit: 10,
      Offset: 0,
      Priority: 0,
      Status: 0,
      ReferenceNumber: 0,
      PrDetailId: 0,
      VendorEmail: "",
      MessageType: 1,
      PatientInfoId : this.id == undefined ? 0 : Number(this.id),
    }
    this.msgservice.getInboxMessage(inputData).subscribe(
      (response: any) => {
        this.spinnerservice.hide();
        this.inboxMessage = [];
        this.messagedetails = [];
        if (response) {
          this.inboxMessage = response.prQueryData;
          this.isShowreply = false;
          this.issentmsg = false;
          this.inboxcount = this.inboxMessage.filter(i => (i.isRead == 0)).length;
          this.openMessageThread(this.inboxMessage[0].prqueryId);
          this.setIndexformsg(0);
        }

      },
      err => {

        this.spinnerservice.hide();
      },
    );
  }
  getSentItem() {
    this.spinnerservice.show();
    var inputData = {
      Limit: 10,
      Offset: 0,
      Priority: 0,
      Status: 0,
      ReferenceNumber: 0,
      PrDetailId: 0,
      VendorEmail: "",
      MessageType: 2,
      PatientInfoId : this.id == undefined ? 0 : Number(this.id),
    }
    this.msgservice.getInboxMessage(inputData).subscribe(
      (response: any) => {
        this.messagedetails = [];
        this.spinnerservice.hide();
        this.inboxMessage = [];
        if (response) {
          this.inboxMessage = response.prQueryData;
          this.isShowreply = false;
          this.issentmsg = true;
          this.openMessageThread(this.inboxMessage[0].prqueryId);
          this.setIndexformsg(0);
        }

      },
      err => {

        this.spinnerservice.hide();
      },
    );
  }
  openMessageThread(data) {

    this.spinnerservice.show();
    this.currentId = data;
    this.global.mailattachment = [];
    this.msgservice.getMessageDetails(data).subscribe(
      response => {
        this.spinnerservice.hide();
        if (response) {
          this.isShowreply = true;
          this.messagedetails = [];
          var responseData: any = response;
          if (responseData.prQueryData && responseData.prQueryData.length > 0) {
            this.messagedetails = responseData.prQueryData;
            if (this.inboxMessage.filter(x => x.prqueryId == data)[0].isRead == false) {
              if (this.inboxcount > 0) {
                this.inboxcount = this.inboxcount - 1;
              }
              
              this.SetMessageAsRead(data);
              var messagecount = Number(localStorage.getItem("messageCount"));
              if (messagecount > 0) {
                var count = String(messagecount - 1)
                this.global.messagecount = count;
                localStorage.setItem("messageCount", count);
              }
            }
            this.inboxMessage.filter(x => x.prqueryId == data)[0].isRead = true
          }
        }
      },
      err => {

        this.spinnerservice.hide();
      },
    );
  }
  setIndexformsg(index: number) {
    this.selectedIndex = index;
  }
  SetMessageAsRead(data) {

    this.msgservice.setMarkasRead(data).subscribe(
      response => {

      },
      err => {

        this.spinnerservice.hide();
      },
    );

  }
  onmailFileChange(event) {

    const reader = new FileReader();
    var _validFileExtensions = [".jpg", ".jpeg", ".png"];
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (file.type == "image/jpeg" || "image/png" || "image/webp") {
        reader.readAsDataURL(file);
        // var dd = this.mailUploadfile.nativeElement.files;
        reader.onload = () => {
          for (var i = 0; i < event.target.files.length; i++) {
            this.global.mailattachment.push(event.target.files[i]);
          }



        };
      } else {
        this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
      }
    }
  }
  removeattachments(data: any): void {
    if (this.global.mailattachment.indexOf(data) >= 0) {
      this.global.mailattachment.splice(this.global.mailattachment.indexOf(data), 1);
    }
  }
  postReply() {
    if (this.rpycomments == "" || this.rpycomments == null || this.rpycomments == undefined) {
      this.snackbar.error(constantVariables.ErrorMessage.replycontent);
      return
    }
    let data = {
      ParentId: this.messagedetails[0].parentId,
      Comments: this.rpycomments,
      Title: this.messagedetails[0].title,
      ToUserEmail: this.messagedetails[0].toUserEmail,
      CCUsersEmail: this.messagedetails[0].ccUsersEmail,
    }

    let formData = new FormData();
    for (var i = 0; i < this.global.mailattachment.length; i++) {
      formData.append("global.mailattachments[]", this.global.mailattachment[i]);
    }

    formData.append('data', this.commonService.DataToEncrypt(data, true));
    this.spinnerservice.show();
    this.msgservice.postMessage(formData).subscribe(
      response => {
        this.spinnerservice.hide();
        this.snackbar.success(constantVariables.SuccessMessage.mailsendsuccess);
        this.uploadAttachment(formData);
        this.clearRpy();
        //this.openMessageThread(data.PrqueryId)
      },
      err => {

        this.spinnerservice.hide();
      },
    );
  }
  clearRpy() {
    this.rpycomments = "";
    this.global.mailattachment = [];
  }
  uploadAttachment(formData) {
    this.msgservice.UploadDocment(formData).subscribe(
      response => {


      },
      err => {

        this.spinnerservice.hide();
      },
    );
  }
  filtermessage(event) {

    if (event.value == "updatedOn") {
      //this.inboxMessage = _.sortBy(this.inboxMessage, [event.value], ['desc']);
      this.inboxMessage = _.reverse(_.sortBy(this.inboxMessage, [event.value]));
    }
    else {
      this.inboxMessage = _.reverse(_.sortBy(this.inboxMessage, [event.value]));
    }


  }
  onsortName(event) {
    if (event.checked) {
      this.inboxMessage = _.orderBy(this.inboxMessage, ["email"], ['asc']);
    } else {
      this.inboxMessage = _.orderBy(this.inboxMessage, ["email"], ['desc']);
    }
  }
  onsortDate(event) {
    if (event.checked) {
      this.inboxMessage = _.orderBy(this.inboxMessage, ["updatedOn"], ['asc']);
    } else {
      this.inboxMessage = _.orderBy(this.inboxMessage, ["updatedOn"], ['desc']);
    }
  }
  replyto(item) {
    this.global.toemailList = [];
    var toemaillist = [];

    let toemails = item.toUserEmail.split(',');
    toemails.forEach(function (obj) {
      if (localStorage.getItem("email") !== obj.trim()) {
        toemaillist.push({ value: obj, invalid: false });
      }
    });
    toemaillist.push({ value: item.email, invalid: false });
    this.global.toemailList = toemaillist;
    this.global.onOpenEmailPopup('');
    this.global.mailinfo.Title = item.title;
    this.global.mailinfo.ParentId = this.messagedetails[0].parentId;
  }
  replytoall(item) {
    this.global.toemailList = [];
    this.global.ccemailList = [];
    var toemaillist = [];
    var ccemaillist = [];
    let toemails = item.toUserEmail.split(',');
    toemails.forEach(function (obj) {
      if (localStorage.getItem("email") !== obj.trim()) {
        toemaillist.push({ value: obj, invalid: false });
      }
    });
    toemaillist.push({ value: item.email, invalid: false });
    if (item.ccUsersEmail != "") {
      let ccemails = item.ccUsersEmail.split(',');
      ccemails.forEach(function (obj) {
        if (localStorage.getItem("email") !== obj.trim()) {
          ccemaillist.push({ value: obj, invalid: false });
        }
      });
      this.global.ccemailList = ccemaillist;
    }
    this.global.toemailList = toemaillist;

    this.global.onOpenEmailPopup('');
    this.global.mailinfo.Title = item.title;
    this.global.mailinfo.ParentId = this.messagedetails[0].parentId;
  }
  openComposeMail() {
    this.global.clearEmaillist();
    this.global.onOpenEmailPopup(this.leadservice.mail_text);

  }
  onloadrawhtml(str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  }
  getmsgtime(date) {
    var offset = new Date().getTimezoneOffset();

    return moment.utc(date).utcOffset(-offset).format("MM/DD hh:mm a");
  }
  getMessageDateon(date) {
    var offset = new Date().getTimezoneOffset();
    return moment.utc(date).utcOffset(-offset).format("MM/DD/YYYY hh:mm a");
  }
  onclickmsgtoogle() {
    this.isExpandMsgpanel = !this.isExpandMsgpanel;
  }
  onExpandComposeEmail() {
    this.isminimizedEmail = !this.isminimizedEmail;

  }
  getusername(str) {
    str=str.trim();
    if (str != "") {
      const matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      const acronym = matches.join(''); // JSON

      return acronym.substr(0, 2);
    }
  }
}

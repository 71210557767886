export class DayScheduleGroupTherapy{
    dayScheduleGroupTherapyId:number;
    groupTherapyId :number;
    schduleDate:string;
    isActive:boolean;
    dayScheduleSubGroupAssign:Array<DayScheduleSubGroupAssign>;
    dayScheduleSubGroup:DayScheduleSubGroup
}
export class DayScheduleSubGroupAssign{
    dayScheduleAssignId:number;
    dayScheduleSubGroupId:number;
    groupTherapyId :number;
    patientAccountId:number;
    patientName:string;
    clinicianId:number;
    clinicianName:string;
}
export class DayScheduleSubGroup{
    dayScheduleSubGroupId:number;
    dayScheduleGroupTherapyId:number;
    groupTherapyId :number;
    themeName:string;
    startTime:string;
    endTime:string;
    sfccMasterId:number;
    assignDate:string;
    releaseDate:string;
    isIop:boolean;
    isPcp:boolean;
}

export class DtoPostInactiveAppointments
    {
     
     PatientAccountId:any;
    DayScheduleSubGroupId:any;
    DayScheduleAssignId:any;
     TherapyDate:any;
     TheraphyId:any;
     UserId:any;
     GroupTherapyId:any;
     Reason:any;
     Comments:string;   
     AppointmentId: number;


    }
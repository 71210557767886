<div class="dashboard-page py-3">
  <div class="top-btn-section col-md-12 clearfix mb-1 w-100 p-0">
    <div>
      <h2 class="head-section mt-0 mb-3">
        <span class="ml-2 head-span">Dashboard</span>
      </h2>
    </div>
    <div class="col-md-10 text-right">
      <p>
        <span>Date:</span
        ><span class="font-weight-bold ml-2">
          {{ today | date : "EEEE" }} |
          {{ today | date : "MM/dd/yyyy" }}</span
        >
        <span class="ml-2"><i class="far fa-calendar-alt"></i></span>
      </p>
     
    </div>
  </div>

  <div class="row">
    <div class="col-md-8 p-0">
      <mat-tab-group
        mat-align-tabs="start"
        [selectedIndex]="selectedTab"
        (selectedTabChange)="onTabChange($event.index)"
      >
        <mat-tab class="">
          <ng-template mat-tab-label>
            <span class="ml-2"
              >Alerts <span class="dot-navy-blue">{{ alertCount }}</span></span
            >
           
          </ng-template>
          <div class="form-configuration">
            <div class="row p-2">
              <div class="fw600 color-red mt-4" *ngIf="NodataAlert">
                <p class="fw600 color-red mx-5">
                  <!-- You have "{{ alertCount }}" alerts today ! -->
                  Alerts for the day : {{ alertCount }}
                </p>
              </div>
              <div class="col-md-6">
                <div class="clearfix mb-1 w-100 text-right">
                  <mat-form-field class="border-0">
                      <mat-label>Select</mat-label>
                      <mat-select name="location" (selectionChange)="onAlertChange($event)"
                      >
                          <mat-option value="0">All</mat-option>
                          <mat-option value="3">Resend Activation Link</mat-option>
                          <mat-option value="4">Upload Insurance</mat-option>
                          <mat-option value="5">Review Client Pocket</mat-option>
                          <mat-option value="7">Update Benefits</mat-option>
                          <mat-option value="9">Financial Proposal</mat-option>
                          <mat-option value="16">Schedule Intake</mat-option>
                          <mat-option value="18">Review Tour Request</mat-option>
                          <mat-option value="26">Schedule Modality</mat-option>
                          <mat-option value="34">Schedule Psychiatric Therapy</mat-option>
                      </mat-select>
                  </mat-form-field>
             
              
                </div>
              </div>
              
            </div>
            
          </div>
          <div>
            <div class="row m-2">
              <div
                class="col-md-12 bg-gray pr-0"
                *ngFor="let item of alertListLoad; let i = index"
                ng-repeat="i in 5"
              >
                <p class="ml-0 mt-3 list-heading mb-1">
                  <!-- THE PATIENT LEAD HAS BEEN COMPLETED THE TREATMENT PLAN -->
                  {{ item.AlertStatus }}
                </p>
                <ul class="list-group list-group-horizontal-md">
                  <li class="list-group-item dashboard-truncate col-md-4">
                    <div class="d-flex">
                      <span class="fw600">Client Name : </span>
                      <p class="fw500 dashboard-truncate" >
                        {{ item.FirstName }} {{ item.LastName }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <span class="fw600">Email :</span>
                      <p class="fw500 dashboard-truncate">{{ item?.Email }}</p>
                    </div>
                  </li>
                
                  <li class="list-group-item list-item-group2 dashboard-truncate col-md-4 ml-5">
                    <div class="d-flex" *ngIf="!item.IsLeadList">
                      <span class="fw600">Primary Clinician :</span>
                      <p class="fw500">
                        <span>
                          <img
                            src="../../assets/images/avator.jpg"
                            height="12"
                            width="12"
                            class="mx-2"
                          /> </span
                        >{{ item.ClinicianName }}
                      </p>
                    </div>
                    <div class="d-flex" *ngIf="!item.IsLeadList">
                      <span class="fw600">Location :</span>
                      <p class="fw500 mb-0 dashboard-truncate">{{ item.LocationName }}</p>
                    </div>
                    <div class="d-flex" *ngIf="item.IsLeadList">
                      <span class="fw600">Location :</span>
                      <p class="fw500 mb-0 dashboard-truncate">{{item.FacilityTypeName}}</p>
                    </div>
                                     </li>
                  
                  <li class="list-group-item p-0 col-md-4 ml-5">
                   
                    <button
                      class="btn primary-btn-filled mb-2 br6 h-30 resend-mail fs11"
                      *ngIf="
                        item.ImmediateActionId == statusResendActivationMail &&
                        roleAccess.isCreate
                      "
                      title="Resend Activation Mail"
                      (click)="ResendActivationDashboard(item)"
                    >
                      RESEND ACTIVATION LINK
                    </button>
                    <button
                      class="btn primary-btn-filled mb-0 h-30 br6"
                      (click)="intakeScheduleDashboard(item)"
                      *ngIf="
                        item.ImmediateActionId == statusintake &&
                        roleAccess.isCreate
                      "
                      title="For Intake Schedule"
                    >
                      Schedule Intake
                    </button>
                    <button
                      class="btn primary-btn-filled mb-0 h-30 br6"
                      *ngIf="item.ImmediateActionId == statusUploadInsurance"
                      title="Upload Insurance"
                    >
                      Upload Insurance
                    </button>
                    <button
                      class="btn primary-btn-filled mb-0 h-30 review br6"
                      *ngIf="
                        item.ImmediateActionId == statusReviewPacket &&
                        roleAccess.isApprove
                      "
                      title="Review Packet"
                      (click)="Patientinfo(item.PatientInfoId, item)"
                    >
                      REVIEW CLIENT PACKET
                    </button>
                    <button
                      class="btn primary-btn-filled mb-0 h-30 br6"
                      *ngIf="
                        item.ImmediateActionId == statusVerified &&
                        !item.IsSkipInsurance &&
                        roleAccess.isEdit
                      "
                      title="CRBA"
                      (click)="UpdateCRBA(item.PatientInfoId, item)"
                    >
                      Update Benefits
                    </button>
                    <button
                      class="btn primary-btn-filled mb-0 h-30 br6"
                      *ngIf="
                        (item.ImmediateActionId == statusCrbaUpdate ||
                          (item.ImmediateActionId == statusVerified &&
                            item.IsSkipInsurance)) &&
                        roleAccess.isCreate
                      "
                      title="Financial Proposal"
                      (click)="SubmitFinancial(item)"
                    >
                      Submit Financial Proposal
                    </button>
                    <!-- <button
                      class="btn primary-btn-filled mb-0 br6 h-30"
                      *ngIf="
                        item.ImmediateActionId == statusreviewTour &&
                        (item.Status == statusRequestTour ||
                          item.Status == null) &&
                        roleAccess.isApprove
                      "
                      title="Request Tour"
                      (click)="RequestTour(item)"
                    >
                      Review Tour Request
                    </button> -->
                    <!-- <button
                      class="btn primary-btn-filled br6 mb-0 h-30"
                      *ngIf="
                        item.ImmediateActionId == statusreviewTour &&
                        item.Status == statusstaffAssigned &&
                        roleAccess.isEdit
                      "
                      title="Request Tour"
                      (click)="RequestTour(item)"
                    >
                      Edit Tour
                    </button> -->
                    <!-- <p *ngIf="item.ImmediateActionId == statusReviewPacket">
                                      {{item.PatientStatus}} <br><span class="text-danger">{{item.UpdatedOn | date: 'MM/dd/yyyy'}}</span>
                                    </p> -->
                    <!-- <p *ngIf="item.ImmediateActionId == statusintake">
                                      {{item.PatientStatus}} <br><span class="font-weight-bold">{{item.UpdatedOn | date: 'MM/dd/yyyy'}}</span>
                                    </p> -->
                    <!-- <p
                      class="btn primary-btn-filled mb-0 h-30"
                      *ngIf="
                        item.ImmediateActionId == statusreviewTour &&
                        item.Status == statusstaffAssigned
                      "
                      class="plain-message"
                      title="Request Tour"
                    >
                      Tour Scheduled for
                      {{ item.RequestTourDate | date : "MM/dd/yyyy" }}
                    </p> -->
                    <!-- <p
                      class="btn primary-btn-filled mb-0 h-30"
                      *ngIf="
                        item.ImmediateActionId == statusreviewTour &&
                        item.Status == statusTourCompleted
                      "
                      class="plain-message"
                      title="Request Tour"
                    >
                      Tour Completed Successfully for
                      {{ item.RequestTourDate | date : "MM/dd/yyyy" }}
                    </p> -->
                    <!-- <p
                      class="btn primary-btn-filled mb-0 h-30"
                      *ngIf="
                        item.ImmediateActionId == statusreviewTour &&
                        item.Status == statusdecline
                      "
                      class="plain-message"
                      title="Request Tour"
                    >
                      Tour has been cancelled for
                      {{ item.RequestTourDate | date : "MM/dd/yyyy" }}
                    </p> -->

                    <p
                      *ngIf="
                        item.ImmediateActionId != statusCrbaUpdate &&
                        item.ImmediateActionId != statusReturn &&
                        item.ImmediateActionId != statusFinancial &&
                        item.ImmediateActionId != statusVerified
                      "
                      class="status-msg d-none"
                    >
                      {{ item.PatientStatus }}
                      <span
                        *ngIf="
                          item.PatientStatus != '' &&
                          item.ImmediateActionId != statusstaffAssigned
                        "
                        >{{ item.CreatedOn | date : "MM/dd/yyyy" }}</span
                      >
                    </p>
                    <p
                      *ngIf="
                        item.ImmediateActionId == statusReturn ||
                        item.ImmediateActionId == statusFinancial
                      "
                      class="plain-message"
                    >
                      {{ item.PatientStatus }}
                      <span
                        *ngIf="
                          item.PatientStatus != '' &&
                          item.ImmediateActionId != statusstaffAssigned
                        "
                        >{{ item.CreatedOn | date : "MM/dd/yyyy" }}</span
                      >
                    </p>
                    <u>
                      <a
                        *ngIf="item.ImmediateActionId == statusCrbaUpdate"
                        class="status-msg cursor-pointer text-underline d-none"
                        (click)="onUpdateCRBA(item.PatientInfoId)"
                        >{{ item.PatientStatus
                        }}<span *ngIf="item.PatientStatus != ''">{{
                          item.CreatedOn | date : "MM/dd/yyyy"
                        }}</span></a
                      >
                    </u>
                    <div class="m-0">
                      <a
                        *ngIf="item.ImmediateActionId == statusdecline"
                        class="status-msg cursor-pointer text-underline"
                        (click)="getfinancialReasonDashboard(item)"
                        >Show Reason
                      </a>
                      <a
                        *ngIf="
                          item.ImmediateActionId == statusdecline ||
                          item.ImmediateActionId == statusFinancial ||
                          item.ImmediateActionId == statusreviewTour ||
                          item.ImmediateActionId == statusstaffAssigned
                        "
                        class="status-msg cursor-pointer text-underline"
                        (click)="SubmitFinancial(item)"
                      >
                        Edit Financial Proposal</a
                      >
                    </div>

                    <button
                      class="btn primary-btn-filled br6 mb-0 h-30"
                      (click)="IntakeSchedule(item)"
                      *ngIf="
                        (item.ImmediateActionId == statusintake ||
                          item.ImmediateActionId == statusintakeDeclined) &&
                        item.ChmaTreatmentSummaryId == null
                      "
                      title="For Intake Schedule"
                    >
                      {{
                        item.ImmediateActionId == statusintakeDeclined
                          ? "Re-Schedule Intake"
                          : "Schedule
                                    Intake"
                      }}
                    </button>
                    <!-- <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified" *ngIf="item.ChmaTreatmentSummaryId !== null" 
                                    (click)="onScheduleModality(item)">Schedule Modality</button> -->
                    <button
                      class="btn primary-btn-filled br6 mb-0 h-30"
                      *ngIf="
                        item.ChmaTreatmentSummaryId !== null &&
                        item.PatientAccountId != 0 &&
                        item.ImmediateActionId != statusintake
                      "
                      (click)="RedirectTo(item, 2)"
                    >
                      {{
                        item.ImmediateActionId != statusPsyReschedule
                          ? "Schedule Modality"
                          : "Schedule Psychiatric Therapy"
                      }}
                    </button>
                    <button
                      class="btn primary-btn-filled br6 mb-2 h-30h"
                      (click)="IntakeSchedule(item)"
                      *ngIf="item.ImmediateActionId == statusintakeAccepted"
                      title="Assign Staff For Intake"
                    >
                      Assign Staff For Intake
                    </button>
                    <div
                      *ngIf="item.MeetingStatus == statusmeetingReSchedule"
                      class="w-100 float-left"
                    >
                      <p title="{{ item.RescheduleReason }}">
                        {{ item.RescheduleReason }}
                      </p>
                      <button
                        class="btn btn-dashboard green-theme br6 mr-2 "
                        title="Accept"
                        (click)="AcceptReschdule(item)"
                      >
                        Accept
                      </button>
                      <button
                        class="btn btn-dashboard red-theme br6 mr-2"
                        title="Deny"
                        (click)="DeclineReschdule(item)"
                      >
                        Deny
                      </button>
                      <button
                        class="btn btn-dashboard red-theme br6"
                        title="Communicate Client"
                        (click)="ReturnLead(item)"
                      >
                        Communicate Client
                      </button>
                    </div>
                    <div
                      *ngIf="item.MeetingStatus == statusrequestcancel"
                      class="w-100 float-left"
                    >
                      <button
                        class="btn btn-dashboard green-theme br6 mr-2"
                        title="Accept"
                        (click)="AcceptCancelIntake(item)"
                      >
                        Cancel Intake
                      </button>
                      <button
                        class="btn btn-dashboard red-theme br6 mr-2"
                        title="Deny"
                        (click)="DeclineCancelIntake(item)"
                      >
                        Deny
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
             
            </div>
            <div *ngIf="!NodataAlert">
              <div class="text-center">No Alerts Found</div>
          </div>
          <div *ngIf="NodataAlert">
            <mat-paginator
            [length]="dataSourceAlert?.filteredData?.length"
              class="pagination pagination-large"
              (page)="onPageAlertChange($event)"
              [pageSize]="20"
              [pageSizeOptions]="[ 10, 20, 50, 75, 100]"
              #paginatorAlert="matPaginator" 
            >
            </mat-paginator>
            </div>
          </div>

          
        </mat-tab>
        <mat-tab label="Upcoming Appointments">
          <ng-template mat-tab-label>
            <span class="ml-2">Upcoming Appointments</span>
            <span class="dot-navy-blue ml-2">{{
              upcommingAppointmentCount
            }}</span>
            <!-- <span class="dot-pink ml-2">{{ expiredAppointmentCount }}</span> -->
          </ng-template>
          <div class="form-configuration">
            <div class="row">
            <p class="fw600 color-red mx-5">
              <!-- You have "{{ upcommingAppointmentCount }}" appointments today ! -->
              Appointments for the day : {{ upcommingAppointmentCount }}
            </p>
            <div class="clearfix mb-1 w-100 text-right col-md-10">
              <mat-form-field>
                <mat-label>Select therapy name</mat-label>
                <mat-select
                  name="therapy"
                  (selectionChange)="onTherapyChange($event)"
                >
                  <mat-option value="0">All</mat-option>
                  <mat-option value="1">Intake</mat-option>
                  <mat-option value="2">Individual Therapy</mat-option>
                  <mat-option value="3">Family Therapy</mat-option>
                  <mat-option value="5">Psychiatric Therapy</mat-option>
                  <mat-option value="8">Psychiatric Evaluation</mat-option>
                  <mat-option value="9">Psychiatric Medication</mat-option>
                  <mat-option value="11">Phone Call Meeting</mat-option>
                  <mat-option value="10">School Meeting</mat-option>
                  <mat-option value="12">Speech Therapy</mat-option>
                  <mat-option value="13">Nutrition Therapy</mat-option>
                  <mat-option value="7">Tour</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          </div>
          <div>

            <div
              class="row my-0 mx-2"
              *ngFor="let item of loadAppointments; let i = index"
            >
              <div class="col-md-12 bg-gray">
                <p class="ml-0 mt-3 list-heading mb-1">
                {{item.scheduleName}}
                </p>
               <ul class="list-group list-group-horizontal-md">
                  <li class="list-group-item col-md-3 dashboard-truncate appointment-truncate">
                    <p class="fw500 d-flex">
                      <i class="fas fa-clock mr-2 clock"></i>
                      <span>{{ item.fromTime | date : "hh:mm a" }} to
                      {{ item.toTime | date : "hh:mm a" }}</span>
                    </p>
                  </li>
                  <li class="list-group-item col-md-3 dashboard-truncate appointment-truncate">
                    <div class="d-flex">
                      <p class="fw500 d-flex">
                      <span class="fw600">Client Name :</span>
                      <span
                        class="fw500 dashboard-truncate"
                        title="{{ item.userName }}"
                      >
                      <span>
                        <img
                          src="../../assets/images/avator.jpg"
                          height="12"
                          width="12"
                          class="mx-1"
                      /></span>
                        {{ item.userName }}
                      </span>
                      </p>
                    </div>
                   
                  </li>
                  <!-- <li class="list-group-item col-md-3">
                  <div class="d-flex">
                    <p class="fw500 d-flex">
                    <span class="fw500">Phone : </span>
                    <span class="fw500">{{ item.patientContact }}</span>
                    </p>
                  </div>
                  </li> -->
                  <li class="list-group-item col-md-4 dashboard-truncate appointment-truncate">
                    <div class="d-flex">
                      <p class="fw600">Clinician Name :</p>
                      <p
                        class="fw500 dashboard-truncate"
                        title="{{ item.displayClinicianName }}"
                      >
                      <span>
                        <img
                          src="../../assets/images/avator.jpg"
                          height="12"
                          width="12"
                          class="mx-1"
                      /></span> {{ item.displayClinicianName }}
                      </p>
                    </div>
                  </li>
                  <!-- <li class="list-group-item list-item-group2">
                    <p class="fw500">{{ item.scheduleName }}</p>
                  </li> -->
                  <li class="list-group-item col-md-2 dashboard-truncate appointment-truncate">
                    <!-- <button
                      class="benefit-btn mr-0 view-btn fs12 mt-2 text-center fw500"
                      type="submit"
                      (click)="viewAppointents()"
                    >
                      View Details
                    </button> -->
                    <button
                    class="btn primary-btn-filled btn-width mb-0 h-30 br6"
                   
                    title="Review Packet"
                    (click)="viewAppointents()"
                  >
                   Session Details
                  </button>
                  </li>
                </ul> 
              </div>
            </div> 
            <div *ngIf="NodataUpcomming">
              <div>No Upcomming Appointments Records Found</div>
          </div>
           <div *ngIf="!NodataUpcomming">
            <mat-paginator
              class="pagination pagination-large"
              #paginatorAppointments="matPaginator" 
              (page)="onPageAppointmentChange($event)"
              [pageSize]="20"
              [pageSizeOptions]="[ 10, 20, 50, 75, 100]"
              [length]="dataSourceAppointments?.filteredData?.length"
            >
            </mat-paginator>
            </div>
          </div>
        </mat-tab>
         <mat-tab label="Group Therapy">
          <ng-template mat-tab-label>
            <span class="ml-4">Group Therapy</span>
            <span class="dot-navy-blue ml-2">{{  upcommingGrpAppointmentCount }}</span>
            <!-- <span class="dot-pink ml-2">{{ expiredGrpAppointmentCount }}</span> -->
           
          </ng-template>
          
       
            
            <div *ngIf="!Nodata">
              <p class="fw600 color-red mx-5">
                <!-- You have "{{ upcommingGrpAppointmentCount }}" group therapy today ! -->
                Group Therapy for the day : {{ upcommingGrpAppointmentCount }}
              </p>
            <div 
              class="col-md-12 bg-gray"
              *ngFor="let itemGrp of loadGrpAppointments; let j = index"
            >
            <p class="ml-0 mt-3 list-heading mb-1">
              {{itemGrp.appointmentName}}
             </p>
              <ul class="list-group list-group-horizontal-md">
                <li class="list-group-item col-md-3">
                  <div class="d-flex">
                  <i class="fas fa-clock mr-2 clock"></i>
                  <p class="fw500"><span>{{ itemGrp.fromTime | date : "hh:mm a" }} to
                    {{ itemGrp.toTime | date : "hh:mm a" }}</span></p>
                  </div>
                </li>
                <!-- <li class="list-group-item w50">
                  <div class="d-flex">
                    <p class="fw500">{{ item.taskName }}</p>
                  
                  </div>
                 
                </li> -->
                <li class="list-group-item col-md-3">
                  <div class="d-flex">
                    <span class="fw600">Client Name :</span>
                    <p
                    class="fw500 dashboard-truncate"
                    title="{{ itemGrp.displayClinicianName }}"
                  >
                    <p class="fw500">
                      <span>
                        <img
                          src="../../assets/images/avator.jpg"
                          height="12"
                          width="12"
                          class="mx-1"
                      /></span>
                      {{ itemGrp.userName }}</p>
                   
                  </div>
                </li>
                
                <!-- <li class="list-group-item col-md-3">
                  <div class="d-flex">
                    <span class="fw600">Members</span>
                    <span class="fw500">{{ itemGrp.priority }}</span>
                   
                  </div>
                </li> -->
                <li class="list-group-item col-md-3">
                
                  <button
                  class="btn primary-btn-filled btn-width mb-0 h-30 br6"
                  type="submit"
                  (click)="viewAppointents()"
                >
                 Session Details
                </button>
                </li>
              </ul>
            </div>
            <div class="row w-100" *ngIf="Nodata">
                <div class="col-md-12 text-center">No Group Therapy Records Found</div>
            </div>
              <div class="col-md-12" *ngIf="!Nodata">
                  <!-- <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)"[pageSize]="5" 
                     [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator> -->
                  <mat-paginator class="pagination pagination-large" (page)="onPageGrpAppointmentChange($event)" [pageSize]="20"
         [pageSizeOptions]="[ 10, 20, 50, 75, 100]" [length]="dataSourceGrpAppointments?.filteredData?.length" #paginatorGrpAppointments="matPaginator" >
      </mat-paginator>
              </div>
          </div>
        </mat-tab>
        <div>
          <mat-tab label="Tour">
            <ng-template mat-tab-label>
              <span class="ml-2">Tour</span>
                <span class="dot-navy-blue ml-2">{{upcomingTour}}
              </span>
              <!-- <span class="dot-pink ml-2">{{ expiredAppointmentCount }}</span>  -->
              <!-- <span class="dot-pink ml-2">{{expiredTour}}</span>  -->
            </ng-template>
            <div>
              <div class="row m-2">
                <div
                  class="col-md-12 bg-gray pr-0"
                  *ngFor="let ldTour of expiredTour; let i = index"
                  ng-repeat="i in 5"
                >
                  <ul class="list-group list-group-horizontal-md">
                    <li class="list-group-item dashboard-truncate col-md-4">
                      <div class="d-flex">
                        <span class="fw600">Client Name : </span>
                        <p class="fw500 dashboard-truncate" >
                          {{ ldTour.FirstName }} {{ ldTour.LastName }}
                        </p>
                      </div>
                      <div class="d-flex">
                        <span class="fw600">Email :</span>
                        <p class="fw500 dashboard-truncate">{{ ldTour.Email }}</p>
                      </div>
                    </li>
                  
                    <li class="list-group-item list-item-group2 dashboard-truncate col-md-4 ml-5">
                      
                      <div class="d-flex" >
                        <span class="fw600">Location :</span>
                        <p class="fw500 mb-0 dashboard-truncate">{{ldTour.FacilityTypeName}}</p>
                      </div>
                                       </li>
                    
                    <li class="list-group-item p-0 col-md-4 ml-5">
                 
                     
                     
                      <!-- <button
                        class="btn primary-btn-filled mb-0 br6 h-30"
                        *ngIf="
                        ldTour.ImmediateActionId == statusreviewTour &&
                          (ldTour.Status == statusRequestTour ||
                          ldTour.Status == null) &&
                          roleAccess.isApprove
                        "
                        title="Request Tour"
                        (click)="RequestTour(ldTour)"
                      >
                        Review Tour Request
                      </button> -->
                      <button
                      class="btn primary-btn-filled mb-0 br6 h-30"
                      *ngIf="
                        ldTour.ImmediateActionId == statusreviewTour &&
                          (ldTour.Status == statusRequestTour ||
                          ldTour.Status == null) &&
                          roleAccess.isApprove
                        "
                      title="Request Tour"
                      (click)="RequestTour(ldTour)"
                    >
                      Review Tour Request
                    </button>
                      <button
                        class="btn primary-btn-filled br6 mb-0 h-30"
                        *ngIf="
                        ldTour.ImmediateActionId == statusreviewTour &&
                        ldTour.Status == statusstaffAssigned &&
                          roleAccess.isEdit
                        "
                        title="Request Tour"
                        (click)="RequestTour(ldTour)"
                      >
                       Edit Tour
                      </button>
                      <!-- <p *ngIf="item.ImmediateActionId == statusReviewPacket">
                                        {{item.PatientStatus}} <br><span class="text-danger">{{item.UpdatedOn | date: 'MM/dd/yyyy'}}</span>
                                      </p> -->
                      <!-- <p *ngIf="item.ImmediateActionId == statusintake">
                                        {{item.PatientStatus}} <br><span class="font-weight-bold">{{item.UpdatedOn | date: 'MM/dd/yyyy'}}</span>
                                      </p> -->
                      <p
                        class="btn primary-btn-filled mb-0 h-30"
                        *ngIf="
                        ldTour.ImmediateActionId == statusreviewTour &&
                        ldTour.Status == statusstaffAssigned
                        "
                        class="plain-message"
                        title="Request Tour"
                      >
                        Tour Scheduled for
                        {{ ldTour.RequestTourDate | date : "MM/dd/yyyy" }}
                      </p>
                      <p
                        class="btn primary-btn-filled mb-0 h-30"
                        *ngIf="
                        ldTour.ImmediateActionId == statusreviewTour &&
                        ldTour.Status == statusTourCompleted
                        "
                        class="plain-message"
                        title="Request Tour"
                      >
                        Tour Completed Successfully for
                        {{ ldTour.RequestTourDate | date : "MM/dd/yyyy" }}
                      </p>
                      <p
                        class="btn primary-btn-filled mb-0 h-30"
                        *ngIf="
                        ldTour.ImmediateActionId == statusreviewTour &&
                        ldTour.Status == statusdecline
                        "
                        class="plain-message"
                        title="Request Tour"
                      >
                        Tour has been cancelled for
                        {{ ldTour.RequestTourDate | date : "MM/dd/yyyy" }}
                      </p>
  
                      <div
                        *ngIf="ldTour.MeetingStatus == statusmeetingReSchedule"
                        class="w-100 float-left"
                      >
                        <p title="{{ ldTour.RescheduleReason }}">
                          {{ ldTour.RescheduleReason }}
                        </p>
                        <!-- <div class="m-0">
                          <a
                            *ngIf="ldTour.ImmediateActionId == statusdecline"
                            class="status-msg cursor-pointer text-underline"
                            (click)="getfinancialReasonDashboard(ldTour)"
                            >Show Reason
                          </a>
                          <a
                            *ngIf="
                            ldTour.ImmediateActionId == statusdecline ||
                            ldTour.ImmediateActionId == statusFinancial ||
                            ldTour.ImmediateActionId == statusreviewTour ||
                            ldTour.ImmediateActionId == statusstaffAssigned
                            "
                            class="status-msg cursor-pointer text-underline"
                            (click)="SubmitFinancial(ldTour)"
                          >
                            Edit Financial Proposal</a
                          >
                        </div> -->
                       
                       
                       
                      </div>
                   
                    </li>
                  </ul>
                </div>
               
              </div>
              <div *ngIf="!NodataAlert">
                <div class="text-center">No Alerts Found</div>
            </div>
            <div *ngIf="NodataAlert">
              <mat-paginator class="pagination pagination-large" (page)="onTourChange($event)" [pageSize]="20"
              [pageSizeOptions]="[ 10, 20, 50, 75, 100]" [length]="dataSourceTour?.filteredData?.length" #paginatorTour="matPaginator" >
           </mat-paginator>
              </div>
            </div>
          </mat-tab>
        </div>
        <!-- <mat-tab label="Group Therapy">
          <ng-template mat-tab-label>
            <span class="ml-4">Group Therapy</span>
            <span class="dot-navy-blue ml-2">{{ totalCount }}</span>
            <span class="dot-pink ml-2">{{ taskCount }}</span>
           
          </ng-template>
          
          <div class="row m-2">
            <div
              class="col-md-12 bg-gray"
              *ngFor="let item of lstTasks; let i = index"
            >
            <p class="ml-0 mt-3 list-heading mb-1">
             Anabolics Mind
             </p>
              <ul class="list-group list-group-horizontal-md">
                <li class="list-group-item col-md-3">
                  <div class="d-flex">
                  <i class="fas fa-clock mr-2 clock"></i>
                  <p class="fw500">{{ item.dueDate | date : "dd/MM/yyyy" }}</p>
                  </div>
                </li>
                 <li class="list-group-item w50">
                  <div class="d-flex">
                    <p class="fw500">{{ item.taskName }}</p>
                  
                  </div>
                 
                </li> -->
                <!-- <li class="list-group-item col-md-3">
                  <div class="d-flex">
                    <span>Client Name :</span>
                    <p class="fw500">
                      <span>
                        <img
                          src="../../assets/images/avator.jpg"
                          height="12"
                          width="12"
                          class="mx-1"
                      /></span>
                      {{ item.userName }}</p>
                   
                  </div>
                </li>
                
                <li class="list-group-item col-md-3">
                  <div class="d-flex">
                    <span>Members</span>
                    <span class="fw500">{{ item.priority }}</span>
                   
                  </div>
                </li>
                <li class="list-group-item col-md-3">
                
                  <button
                  class="btn primary-btn-filled btn-width mb-0 h-30 br6"
                  type="submit"
                  (click)="TaskViewDetails(item)"
                >
                 Session Details
                </button>
                </li>
              </ul>
            </div>
              <div class="col-md-12">
                   <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)"[pageSize]="5" 
                     [pageSizeOptions]="[5, 10, 25, 100]">
                  </mat-paginator> -->
                  <!-- <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="5"
         [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>
              </div>
          </div>
        </mat-tab> --> 
      </mat-tab-group>
    </div>
   
<div class="col-md-4 p-3 task-col" >
  <h5 class="text-center fs16">Tasks</h5>
  <ul class="list-group list-group-horizontal-md justify-content-start bg-transparent">
    <li class="list-group-item bg-transparent">
      <p>
        <span class="smalldot dot-red"></span><span class="ml-2">High</span>
      </p>
    </li>
    <li class="list-group-item bg-transparent">
      <p>
        <span class="smalldot dot-orange"></span><span class="ml-2">Medium</span>
      </p>
    </li>
    <li class="list-group-item bg-transparent">
      <p>
        <span class="smalldot dot-blue"></span><span class="ml-2">Low</span>
      </p>
    </li>
  </ul>
  <div class="task-detail-bg" *ngIf="lstTasks?.length > 0">
    <ul class="list-group list-group-horizontal-md border-bottom" *ngFor="let item of lstTasks" >
      <li class="list-group-item border-left-tasks col-md-8" 
      [ngClass]="{ 'border-left-red': item.priority == 'High' , 'border-left-orange': item.priority == 'Medium','border-left-blue': item.priority == 'Low'  }">
        <h6 class="fs12">{{item.taskName}}</h6>
        <div class="d-flex">
          <p class="d-flex">
            <span class="mr-1"><i class="far fa-calendar-alt"></i></span>
            <span class="fs12">{{item.dueDate | date : "MM/dd/yyyy"}}</span>
          </p>
          <p class="d-flex">
            <span>
              <img src="../../assets/images/avator.jpg" height="12" width="12" class="mx-1" /></span>
            <span class="fs12">{{item.userName}}</span>
          </p>
          <p class="d-flex ml1" *ngIf="item.priority">
            <span class="fw600">Status:</span>
            <span>{{item.priority}}</span>
          </p>
        </div>
      </li>
      <li class="list-group-item col-md-4 text-right">
        <button class="btn btn-transparent mr-2 p-0" title="view"  (click)="TaskViewDetails(item)">
          View Details
        </button>
      </li>
    </ul>
  <mat-paginator  #paginatorTask="matPaginator" [length]="dataSourceTask?.filteredData?.length" class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="20"
      [pageSizeOptions]="[ 10, 20, 50, 75, 100]">
   </mat-paginator>
  </div>
  <div  class="task-detail-bg" *ngIf="lstTasks?.length == 0 || !lstTasks">
    <ul class="list-group list-group-horizontal-md border-bottom">
      <li class="list-group-item border-left-tasks "> 
        No Task record found.
      </li>
    </ul>
  </div>
</div>
</div>


</div>
<div
  id="myModal"
  class="modal deny-modal"
  role="dialog"
  [style.display]="isFinancialDecline ? 'block' : 'none'"
>
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header fill-header">
        <h6 class="modal-title">Please explain your reason for rejecting the Financial Proposal</h6>
        <button
          type="button"
          class="close"
          (click)="closeDeclineProcess()"
          data-dismiss="modal"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ financialDenyReason }}</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          (click)="closeDeclineProcess()"
          class="btn btn-cancel primary-btn-s"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<div>
  
  <div
    class="modal task-create-modal"
    tabindex="-1"
    role="dialog"
    [style.display]="isShowAddtask ? 'block' : 'none'"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header p-2">
          <h5 class="modal-title">View Details</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="resetnotesform()"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="task-create-section bg-white p-2">
            <form
              (ngSubmit)="taskfrm.form.valid && saveTaskInfo(taskfrm)"
              id="taskfrm"
              autocomplete="off"
              #taskfrm="ngForm"
              novalidate
            >
              <div class="col-md-12">
                <div class="col-md-12 p-0">
                  <mat-label class="control-label-new mb-0"
                    >Task Name
                  </mat-label>
                  <mat-form-field class="w-100">
                    <!-- <mat-label class="control-label-new mb-0">{{}}
                            </mat-label> -->
                    <input
                      matInput
                      placeholder=""
                      maxlength="80"
                      disabled
                      [(ngModel)]="taskinfo.taskName"
                      appAlphabetOnly
                      #taskName="ngModel"
                      name="taskName"
                      [ngClass]="{
                        'is-invalid': taskfrm.submitted && taskName.invalid
                      }"
                      required
                    />
                    <mat-error *ngIf="taskfrm.submitted && taskName.invalid">
                      <mat-error *ngIf="taskName.errors.required"
                        >Task name is required
                      </mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-md-12">
                <mat-label class="control-label-new"
                  >Enter Task
                  <span *ngIf="!(taskfrm.submitted && content.invalid)">*</span>
                  <span
                    *ngIf="taskfrm.submitted && content.invalid"
                    style="color: #d0342c"
                    >*</span
                  >
                </mat-label>
                <div class="col-md-12 p-0">
                  <quill-editor
                    disabled
                    [(ngModel)]="taskinfo.content"
                    #content="ngModel"
                    name="content"
                    [modules]="quillConfig"
                    required
                  >
                  </quill-editor>
                  <mat-error *ngIf="taskfrm.submitted && content.invalid">
                    <mat-error
                      style="padding-top: 12px"
                      class="mat-form-field-subscript-wrapper"
                      *ngIf="content.errors.required"
                    >
                      Content is required</mat-error
                    >
                  </mat-error>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 mt-2">
                    <mat-form-field class="w-100">
                      <mat-label class="control-label-new"
                        >Assigned To
                      </mat-label>

                      <!-- <mat-select disabled [(ngModel)]="taskinfo.assignedTo" #assignedTo="ngModel"
                                      name="assignedTo"
                                      [ngClass]="{ 'is-invalid': taskfrm.submitted && assignedTo.invalid }"
                                      required [disabled]="taskinfo.isSelfAssigned"> 
                                      <mat-option *ngFor="let item of lstuser" [value]="item.value" >
                                          {{item.firstName}} {{item.lastName}}
                                      </mat-option>
                                  </mat-select> -->
                      <input
                        matInput
                        placeholder=""
                        maxlength="80"
                        disabled
                        [(ngModel)]="taskinfo.assignedStaffName"
                        appAlphabetOnly
                        #assignedStaffName="ngModel"
                        name="assignedStaffName"
                        [ngClass]="{
                          'is-invalid':
                            taskfrm.submitted && assignedStaffName.invalid
                        }"
                        required
                      />
                      <!-- <mat-option *ngFor="let item of lstuser" [value]="item.value" >
                                          {{item.firstName}} {{item.lastName}}
                                      </mat-option> -->
                      <mat-error
                        *ngIf="taskfrm.submitted && assignedStaffName.invalid"
                      >
                        <mat-error *ngIf="assigned.errors.required"
                          >AssignedTo is required
                        </mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 mt-2">
                    <mat-form-field class="w-100">
                      <mat-label class="control-label-new">Due Date </mat-label>

                      <input
                        matInput
                        [matDatepicker]="duepicker"
                        readonly
                        [min]="currentdate"
                        (click)="duepicker.open()"
                        disabled
                        [(ngModel)]="taskinfo.dueDate"
                        #dueDate="ngModel"
                        name="dueDate"
                        [ngClass]="{
                          'is-invalid': taskfrm.submitted && dueDate.invalid
                        }"
                        required
                      />
                      <mat-datepicker-toggle matSuffix [for]="duepicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #duepicker></mat-datepicker>
                      <mat-error *ngIf="taskfrm.submitted && dueDate.invalid">
                        <mat-error *ngIf="dueDate.errors.required"
                          >Due date is required</mat-error
                        >
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="w-100">
                      <mat-label class="control-label-new">Reminder </mat-label>
                      <input
                        matInput
                        [matDatepicker]="flagpicker"
                        readonly
                        [min]="currentdate"
                        (click)="flagpicker.open()"
                        disabled
                        [(ngModel)]="taskinfo.flagMeBy"
                        #flagMeBy="ngModel"
                        name="flagMeBy"
                        (dateChange)="changeRemainder()"
                      />
                      <mat-datepicker-toggle matSuffix [for]="flagpicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #flagpicker></mat-datepicker>
                      <!-- <mat-error *ngIf="taskfrm.submitted && flagMeBy.invalid">
                                  <mat-error *ngIf="flagMeBy.errors.required">Remind me is
                                      required</mat-error>
                              </mat-error> -->
                    </mat-form-field>
                    <mat-error
                      class="mat-form-field-subscript-wrapper w-100"
                      *ngIf="!isValidFlagDate"
                    >
                      Reminder should be scheduled before due date</mat-error
                    >
                  </div>
                  <div class="col-md-6">
                    <mat-form-field class="w-100">
                      <mat-label class="control-label-new">Priority </mat-label>
                      <!-- <mat-select  disable [(ngModel)]="taskinfo.taskPriority" #taskPriority="ngModel" -->
                      <mat-select
                        disabled
                        [(ngModel)]="taskinfo.priority"
                        #taskPriority="ngModel"
                        name="taskPriority"
                        [ngClass]="{
                          'is-invalid':
                            taskfrm.submitted && taskPriority.invalid
                        }"
                        required
                      >
                        <mat-option
                          *ngFor="let item of ddlPriority"
                          [value]="item.label"
                        >
                          <!-- {{item.label}}    {{taskinfo.priority}} -->
                          {{ item.label }}
                        </mat-option>
                      </mat-select>
                      <!-- <input matInput placeholder="" maxlength="80" disabled [(ngModel)]="taskinfo.taskPriority"
                              appAlphabetOnly #taskPriority="ngModel" name="taskPriority"
                              [ngClass]="{ 'is-invalid': taskfrm.submitted && assignedTo.invalid }"
                              required> -->
                      <mat-error
                        *ngIf="taskfrm.submitted && taskPriority.invalid"
                      >
                        <mat-error *ngIf="taskPriority.errors.required"
                          >Priority is required</mat-error
                        >
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="check-custom mt-1">
                          <mat-checkbox
                            class="example-margin"
                            disabled
                            [(ngModel)]="taskinfo.isSelfAssigned"
                            #isSelfAssigned="ngModel"
                            name="isSelfAssigned"
                            (change)="onSelfAssign($event)"
                            >Self Assign
                          </mat-checkbox>
                        </div>
                      </div>
                      <div class="col-md-12 mt-2 p-0" *ngIf="taskinfo.documents?.length > 0">
                        <mat-chip-list class="mt-2">
                          <mat-chip
                            *ngFor="let a of taskinfo.documents; let x = index"
                            (click)="downloadUploadedFile(a)">
                            {{ a.fileName }}
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3 mb-2">
                <button
                  type="button"
                  mat-raised-button
                  class="btn btn-cancel primary-btn-m mb-2 h-40"
                  (click)="resetnotesform()"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>

import { Component, Input, OnInit, ViewChild,ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { benchmarkmodel } from 'src/app/_models/_Treatment/_benchmarkMeasures';
import { GoalMeasureBen, PresentingProblemsInfo, ShortTermGoal } from 'src/app/_models/_Treatment/_presentingProblems';
import { GoalMeasure } from 'src/app/_models/_Treatment/_treatmentgoals';
import { TreatmentService } from '../../treatment.service';
import { TreatmentComments } from 'src/app/_models/_Treatment/_diagnosis';
import { PatientService } from 'src/app/patient/patient.service';
import { cloneDeep } from "lodash";
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-treatment-presenting-problems',
  templateUrl: './treatment-presenting-problems.component.html',
  styleUrls: ['./treatment-presenting-problems.component.scss']
})
export class TreatmentPresentingProblemsComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @Input() patientAccountId: number;
  @Input() chmaTreatmentSummaryId: number;
  @ViewChild('PresentingProbfrm') PresentingProbfrm: NgForm;
  @ViewChild('PresentingProbfrm2') PresentingProbfrm2: NgForm;
  @ViewChild('PresentingProbfrm3') PresentingProbfrm3: NgForm;
  PresentinProblemsInfo:any = [];
  PresentinProblemsInfo2:any= [];
  PresentinProblemsInfo3:any = [];

  ParticipateTask:ShortTermGoal;
  ListeningDirections:ShortTermGoal;
  ManageFrustation:ShortTermGoal;
  AcceptNo:ShortTermGoal;
  PercentageBased = constantVariables.MeasureTypeName.Percentagebased;
  Timebased = constantVariables.MeasureTypeName.Timebased;
  ParticipateTaskId = constantVariables.ShortTermGoalTypes.ParicipateTask;
  ListeningDirectionsId = constantVariables.ShortTermGoalTypes.ListeningDirections;
  ManageFrustationId = constantVariables.ShortTermGoalTypes.ManageFrustation;
  AcceptNoId = constantVariables.ShortTermGoalTypes.AcceptNo;
  isESignAttached: boolean;
  updatedsignatureImg: any;
  isAttachEsign: boolean;
  signatureImg: any;
  isformtype: string;
  defaultImage: any;
  Otherbenchmarks: any[];
  Benchmarkfrminfo: benchmarkmodel;
  EditBenchmark: boolean;
  IsShowStatus: boolean;t
  status: any;
  Sym1GoalCount: number;
  SelectedGoals: number;
  TargetSymType:number =1;
  Type=0;
  IsSecondTab:boolean = false;
  IsThiredTab:boolean = false;
  currentdate: any;
  roleAccess: RoelAccess;
  currentTab : any;
  selectedTab : number = 1;
  isExistTab1 : boolean =false;
  isExistTab2 : boolean =false;
  isExistTab3 : boolean =false;
  title: string = '';
  Header: string='';
  Addtitle: string = '';
  AddHeader: string='';
  CommentsidAll:number;

  ShortTermGoalId:number;
  GoalId:number;
  showMedicationsPopup:boolean=false;
  showCommentsPopup:boolean=false;
  showRecommandationPopup:boolean=false;
  _dtoMedications: any = [];
  _dtoMedicationsPopUp: any = [];
  _dtoComments: any = [];  
  _dtoTreatmetComments: TreatmentComments = new  TreatmentComments();
  targetSymptomId:number;
  NoRecord:string;
  selectedToDate: any;
  shortTermGoalsMasterId: number;
  _dtotreatmentPlanCommentsId: number;
  _dtoCommentdetails: any;
  Commentid: any;
  //GoalId:number;

  constructor(public global: Global, public treatmentservice: TreatmentService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public roleaccessservice:PageAccessService,public patientservice: PatientService,public datepipe: DatePipe) { }

  ngOnInit(): void {
    //this.PresentinProblemsInfo = new PresentingProblemsInfo();
    // this.PresentinProblemsInfo2 = new PresentingProblemsInfo();
    // this.PresentinProblemsInfo3 = new PresentingProblemsInfo();
    this.currentdate = moment().format("YYYY-MM-DD HH:mm:ss");
    
    
    this.treatmentservice.PresentingProblem.subscribe((res)=>  
    { 
      this.currentTab = "1";
      this.TargetSymType =1;
      this.GetPresentingProb();
    // this.TabConfigure()}
    }
     );
    this.commonService.BenchmarkPopUp.subscribe((res)=>{
      this.onClosemodal();
   
   });
   const pageid = constantVariables.pagelists.TreatmentPlan;
   this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
  

  }
  addMeasures(item,type) {
    let _obj = new GoalMeasureBen();
    if(type==1){
      if(item.shortTermGoalsMasterId != 0)
      {
        let index = this.PresentinProblemsInfo.staticGoals.findIndex(object => {
          return object.shortTermGoalsMasterId === item.shortTermGoalsMasterId;
        });
        this.PresentinProblemsInfo.staticGoals[index].measures.push(_obj)
      }else{
        let index = this.PresentinProblemsInfo.staticGoals.findIndex(object => {
          return object.treatmentGoalsId === item.treatmentGoalsId;
        });
        this.PresentinProblemsInfo.staticGoals[index].measures.push(_obj)
      }
     
    }
    if(type==2){
      if(item.shortTermGoalsMasterId != 0)
      {
        let index = this.PresentinProblemsInfo2.staticGoals.findIndex(object => {
          return object.shortTermGoalsMasterId === item.shortTermGoalsMasterId;
        });
        this.PresentinProblemsInfo2.staticGoals[index].measures.push(_obj)
      }else{
        let index = this.PresentinProblemsInfo2.staticGoals.findIndex(object => {
          return object.treatmentGoalsId === item.treatmentGoalsId;
        });
        this.PresentinProblemsInfo2.staticGoals[index].measures.push(_obj)
      }
    }
    if(type==3){
      if(item.shortTermGoalsMasterId != 0)
      {
        let index = this.PresentinProblemsInfo3.staticGoals.findIndex(object => {
          return object.shortTermGoalsMasterId === item.shortTermGoalsMasterId;
        });
        this.PresentinProblemsInfo3.staticGoals[index].measures.push(_obj)
      }else{
        let index = this.PresentinProblemsInfo3.staticGoals.findIndex(object => {
          return object.treatmentGoalsId === item.treatmentGoalsId;
        });
        this.PresentinProblemsInfo3.staticGoals[index].measures.push(_obj)
      }
    }
  
  }
  removeMeasures(index,item,type) {
  if(type == 1){
    if(item.shortTermGoalsMasterId != 0)
    {
      let inx = this.PresentinProblemsInfo.staticGoals.findIndex(object => {
        return object.shortTermGoalsMasterId === item.shortTermGoalsMasterId;
      });
      this.PresentinProblemsInfo.staticGoals[inx].measures.splice(index, 1);
    }else{
      let inx = this.PresentinProblemsInfo.staticGoals.findIndex(object => {
        return object.treatmentGoalsId === item.treatmentGoalsId;
      });
      this.PresentinProblemsInfo.staticGoals[inx].measures.splice(index, 1);
    }
  }
  if(type == 2){
    if(item.shortTermGoalsMasterId != 0)
    {
      let inx = this.PresentinProblemsInfo2.staticGoals.findIndex(object => {
        return object.shortTermGoalsMasterId === item.shortTermGoalsMasterId;
      });
      this.PresentinProblemsInfo2.staticGoals[inx].measures.splice(index, 1);
    }else{
      let inx = this.PresentinProblemsInfo2.staticGoals.findIndex(object => {
        return object.treatmentGoalsId === item.treatmentGoalsId;
      });
      this.PresentinProblemsInfo2.staticGoals[inx].measures.splice(index, 1);
    }
    }
    if(type == 3){
      if(item.shortTermGoalsMasterId != 0)
      {
        let inx = this.PresentinProblemsInfo3.staticGoals.findIndex(object => {
          return object.shortTermGoalsMasterId === item.shortTermGoalsMasterId;
        });
        this.PresentinProblemsInfo3.staticGoals[inx].measures.splice(index, 1);
      }else{
        let inx = this.PresentinProblemsInfo3.staticGoals.findIndex(object => {
          return object.treatmentGoalsId === item.treatmentGoalsId;
        });
        this.PresentinProblemsInfo3.staticGoals[inx].measures.splice(index, 1);
      }
      }
  }

  OnPostPresentingProblem(current,next){
    if(current==1){
      if(!this.PresentingProbfrm.valid&&current==1) {
        this.snackbar.error("Please fill mandatory fields")
        return;
      }
    }
    else if (current==2){
      if(!this.PresentingProbfrm2.valid&&current==2) {
        this.snackbar.error("Please fill mandatory fields")
        return;
      }
    }
    else if (current == 3){
      if(!this.PresentingProbfrm3.valid&&current==3) {
        this.snackbar.error("Please fill mandatory fields")
        return;
      }

    }
    // if((!this.PresentingProbfrm.valid&&current==1) ||(!this.PresentingProbfrm2.valid && current ==2) ||(!this.PresentingProbfrm3.valid && current ==3)){
    //   return;
    // }
    //else{
   // console.log(this.PresentinProblemsInfo)
   let postData;
   if(current == 1){
    this.PresentinProblemsInfo.targetSymType = current;
    this.PresentinProblemsInfo.isnext = next !=0?true:false;
    this.PresentinProblemsInfo.patientAccountId = this.patientAccountId;
    this.PresentinProblemsInfo.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
    postData = this.PresentinProblemsInfo;
   }
   if(current==2){
    this.PresentinProblemsInfo2.targetSymType = current;
    this.PresentinProblemsInfo2.isnext = next !=0?true:false;
    this.PresentinProblemsInfo2.patientAccountId = this.patientAccountId;
    this.PresentinProblemsInfo2.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
    postData = this.PresentinProblemsInfo2;

   }
   if(current==3){
    this.PresentinProblemsInfo3.targetSymType = current;
    this.PresentinProblemsInfo3.isnext =false;
    this.PresentinProblemsInfo3.patientAccountId = this.patientAccountId;
    this.PresentinProblemsInfo3.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
    postData = this.PresentinProblemsInfo3;

   }
   postData.staticGoals.map(x=>{
    x.isSave = true;
   })
    this.spinnerservice.show()
    this.treatmentservice.PostPresentingProblems(postData).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        if(current == 1)
        {
          this.PresentingProbfrm.resetForm();
          this.TargetSymType = 1;
          this.currentTab = "1";
        }else if(current==2){
          this.PresentingProbfrm2.resetForm();
          this.TargetSymType = 2;
          this.currentTab = "2";
        }else if(current == 3)
        {
          this.PresentingProbfrm3.resetForm();
          this.TargetSymType = 3;
          this.currentTab = "3";
        }
        this.GetPresentingProb();
        this.treatmentservice.TreatmentPlanStatus.next(false)
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
    //  }

    }
  openDialog(type) {
    this.global.isconsentesign = false;
    const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
      data: { isfutureSign: false, isParentsign: true },
      autoFocus: false 

    });
    dialogRef.afterClosed().subscribe((result) => {
      this.isESignAttached = this.global.isconsentesign;
      if (
        this.global.isconsentesign != undefined &&
        this.global.isconsentesign
      ) {
        this.signatureImg = this.updatedsignatureImg;
        if(type==1){
        this.PresentinProblemsInfo.staffSignature = this.updatedsignatureImg;
        (document.getElementById('esign_1') as HTMLImageElement).src = this.signatureImg;
        }
        if(type==2){
          this.PresentinProblemsInfo2.staffSignature = this.updatedsignatureImg;
          (document.getElementById('esign_2') as HTMLImageElement).src = this.signatureImg;
          }
          if(type==3){
            this.PresentinProblemsInfo3.staffSignature = this.updatedsignatureImg;
            (document.getElementById('esign_3') as HTMLImageElement).src = this.signatureImg;
            }

      } else if (
        !this.global.isconsentesign &&
        this.global.formtype != null &&
        this.global.formtype != undefined
      ) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        if(type==1){
        this.PresentinProblemsInfo.staffSignature = this.global.consentesign;
        (document.getElementById('esign_1') as HTMLImageElement).src = this.signatureImg;
        }
        if(type==2){
          this.PresentinProblemsInfo2.staffSignature = this.global.consentesign;
          (document.getElementById('esign_2') as HTMLImageElement).src = this.signatureImg;
          }
          if(type==3){
            this.PresentinProblemsInfo3.staffSignature = this.global.consentesign;
            (document.getElementById('esign_3') as HTMLImageElement).src = this.signatureImg;
            }
      } else {
        this.signatureImg = this.defaultImage;
        //this.IncReportfrmInfo.IsAttachEsign = true;
        this.PresentinProblemsInfo.staffSignature = this.defaultImage;
        this.PresentinProblemsInfo2.staffSignature = this.defaultImage;
        this.PresentinProblemsInfo3.staffSignature = this.defaultImage;

      }
    });
}


GetPresentingProb(){
  this.spinnerservice.show()
  this.treatmentservice.getPresentingProblems(this.chmaTreatmentSummaryId,this.TargetSymType).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      
    if(res.targetSymType==1){
      if(res.staticGoals != null)
      {
      this.PresentinProblemsInfo = res;
      if(this.PresentinProblemsInfo.staffSignature!=''){
       (document.getElementById('esign_1') as HTMLImageElement).src = this.PresentinProblemsInfo.staffSignature;
      }
      // this.PresentinProblemsInfo.staticGoals=this.PresentinProblemsInfo.staticGoals.map(x=>{
      //   if(x.shortTermGoalId==0){
      //     x.isActive=false;
      //   }else{
      //     x.isActive=true;
      //   }    
      //   return x;   
      // })
      this.Sym1GoalCount =  this.PresentinProblemsInfo.staticGoals.length;
      this.SelectedGoals = this.PresentinProblemsInfo.staticGoals.length;
      this.isExistTab1 = this.PresentinProblemsInfo.staticGoals.length > 0 ? true : false;


    }
      if(res.targetSymType ==1 && res.isnext ){
        this.TargetSymType =2
        this.GetPresentingProb()
        return;
      }
    }
    if(res.targetSymType ==2){
      if(res.staticGoals != null)
      {
        this.IsSecondTab = this.PresentinProblemsInfo.isnext 
        this.PresentinProblemsInfo2 = res;
        if(this.PresentinProblemsInfo2.staffSignature!=''){
         (document.getElementById('esign_2') as HTMLImageElement).src = this.PresentinProblemsInfo2.staffSignature;
        }
        this.Sym1GoalCount =  this.PresentinProblemsInfo2.staticGoals.length;
      this.SelectedGoals = this.PresentinProblemsInfo2.staticGoals.length;
      this.isExistTab2 = this.PresentinProblemsInfo2.staticGoals.length > 0 ? true : false;
      }else{
        this.IsSecondTab = false;
      }
    this.IsThiredTab = this.PresentinProblemsInfo2.isnext 
    //  if(this.PresentinProblemsInfo2.targetSymType ==2 && this.PresentinProblemsInfo2.isnext ){
    //    this.TargetSymType =3
    //    this.GetPresentingProb()
    //    return;
    //  }

    }
    if(res.targetSymType ==3){
      if(res.staticGoals != null)
      {
      this.PresentinProblemsInfo3 = res;
      if(this.PresentinProblemsInfo3.staffSignature!=''){
       (document.getElementById('esign_3') as HTMLImageElement).src = this.PresentinProblemsInfo3.staffSignature;
      }
      this.Sym1GoalCount =  this.PresentinProblemsInfo3.staticGoals.length;
    this.SelectedGoals = this.PresentinProblemsInfo3.staticGoals.length;
    this.isExistTab3 = this.PresentinProblemsInfo3.staticGoals.length > 0 ? true : false;
      }else{
        this.IsThiredTab = false;
      }
   // this.IsThiredTab = this.PresentinProblemsInfo2.isnext 

        }

      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
 
OnselectTrackbenchmark(bench,goalDtl){
  this.Otherbenchmarks=[];
  goalDtl.measures.map(Y=>{
    if(Y.trackBenchmarkId!=bench.trackBenchmarkId){
      this.Otherbenchmarks.push(Y.trackBenchmarkId)
    }
                Y.trackBenchIsSelected=false; 
              });
  bench.trackBenchIsSelected = true
}

OnAddBenchMark(item){
 
  this.Benchmarkfrminfo= new benchmarkmodel();
  this.Benchmarkfrminfo.GoalMeasureTypeId=item.measureTypeId;
  this.Benchmarkfrminfo.TherapyId=3;
   this.Benchmarkfrminfo.TrackBenchmarkId=0;
   this.Benchmarkfrminfo.ChmatreatmentSummaryId=this.chmaTreatmentSummaryId
   this.Benchmarkfrminfo.TrackBenchIsSelected=0;
   this.Benchmarkfrminfo.GoalId = item.shortTermGoalId;
   this.Benchmarkfrminfo.Status = 1;
   this.Benchmarkfrminfo.IsPresentingProb = true;
  this.status = 1;
  this.Otherbenchmarks=[]
  this.EditBenchmark = true
}
onClosemodal(){
  this.EditBenchmark = false
  this.IsShowStatus = false;
  this.TargetSymType = this.Type+1
  this.GetPresentingProb();
}

OnEditBenchmark(bench,Goal){
 // this.measuretype= Goal.measureTypeId
 this.Benchmarkfrminfo = new benchmarkmodel()
  this.Benchmarkfrminfo.Benchmarktitle = bench.benchmarktitle
  this.Benchmarkfrminfo.Benchmarkdiscription = bench.benchmarkdiscription
  this.Benchmarkfrminfo.Benchmarkunit = bench.benchmarkunit
  this.Benchmarkfrminfo.GoalBenchmarkId = 0
  this.Benchmarkfrminfo.GoalMeasureTypeId=Goal.measureTypeId
  this.Benchmarkfrminfo.TherapyId=bench.therapyId
  this.Benchmarkfrminfo.TrackBenchmarkId=bench.trackBenchmarkId
  this.Benchmarkfrminfo.ChmatreatmentSummaryId=this.chmaTreatmentSummaryId
  this.Benchmarkfrminfo.TrackBenchIsSelected=bench.trackBenchIsSelected
  this.Benchmarkfrminfo.GoalId = Goal.shortTermGoalId;
  this.Benchmarkfrminfo.IsPresentingProb = true;
  this.Benchmarkfrminfo.Status = bench.status;
  this.Benchmarkfrminfo.ChmatreatmentSummaryId = this.chmaTreatmentSummaryId
  if(this.Benchmarkfrminfo.Status ==2){
    this.IsShowStatus = true;
    this.status = 2
    this.Benchmarkfrminfo.Status = 'true'
  }
  else if(this.Benchmarkfrminfo.Status ==3){
    this.status = 3
    this.Benchmarkfrminfo.Status = 'false'
  }
  else{
    //this.Benchmarkfrminfo.Status = null
    this.status = 1
  }

    this.Otherbenchmarks=[]
    if(this.Benchmarkfrminfo.Status !=1){
  Goal.measures.map(Y=>{
    if(Y.trackBenchmarkId!=bench.trackBenchmarkId){
      this.Otherbenchmarks.push(Y.trackBenchmarkId)
    }  });
  }
  this.EditBenchmark = true
}

OnDeleteBenchmark(Id){
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.Warning.removeConfirmMessage
    }
  });

  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
  this.spinnerservice.show();
  this.treatmentservice.removetreatmentbenchmark(Id).subscribe((res:any) => {
    this.spinnerservice.hide();
    this.GetPresentingProb();
    this.snackbar.success(constantVariables.SuccessMessage.RemoveTreatmentGoal);
  },
    err => {
      this.spinnerservice.hide();
    }
  );
}})
}
OnGoalSelectionChange(type){
  if(type==1){
    this.SelectedGoals = this.PresentinProblemsInfo.staticGoals.filter((obj) => obj.isActive == true).length
  }
  if(type==2){
    this.SelectedGoals = this.PresentinProblemsInfo2.staticGoals.filter((obj) => obj.isActive == true).length

  }
  if(type==3){
    this.SelectedGoals = this.PresentinProblemsInfo3.staticGoals.filter((obj) => obj.isActive == true).length

  }
}
OnTabChange(item){
  this.PresentingProbfrm.resetForm();
  var tab = item.index+1
this.TargetSymType = tab;
this.selectedTab = tab;
this.currentTab = tab.toString();
this.GetPresentingProb();
}

OnCancel(){
  this.router.navigate(['/clinician'],{queryParams:{menu:3}});
}
saveAsPresentingProblem(){
  console.log('click');  
  let postData;
   this.PresentinProblemsInfo.targetSymType = 1;
   this.PresentinProblemsInfo.isnext = false;
   this.PresentinProblemsInfo.patientAccountId = this.patientAccountId;
   this.PresentinProblemsInfo.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
   postData = this.PresentinProblemsInfo;
   this.spinnerservice.show();
   this.treatmentservice.PostPresentingProblems(postData).subscribe(
     (res: any) => {
       this.spinnerservice.hide();
       this.snackbar.success(JSON.parse(res).message);
       this.PresentingProbfrm.resetForm();
       this.GetPresentingProb();
     },
     err => {
       this.spinnerservice.hide();
       this.snackbar.error(err.error);
     },
   );}
   onSelectionChanged(item,current,presentinProblemsInfo)
   {

      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: '',
          message: constantVariables.SuccessMessage.PresentingProblemTabChange
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
            this.ReAssignGoals(item);
        }else{
          this.currentTab = this.selectedTab.toString();
        }
      });

    // if(presentinProblemsInfo.staticGoals.length == 1)
    // {
      
    //   this.snackbar.error(constantVariables.ErrorMessage.PresentingProblemError);
    //   this.currentTab= this.selectedTab.toString();
    //   return;
      
    // }else
    // {
    //   this.ReAssignGoals(item);
    // }
   
   }
   ReAssignGoals(item)
   {
    let _obj ={
      presentingProblemId : item.presentingProblemId,
      targetSymType : +this.currentTab,
      selectedTab : this.selectedTab,
      isnext : this.selectedTab == 1 ? this.PresentinProblemsInfo.isnext : this.selectedTab == 2 ? this.PresentinProblemsInfo2.isnext : this.selectedTab == 3 ? this.PresentinProblemsInfo3.isnext : 0 ,
      patientAccountId : this.patientAccountId,
      shortTermGoalId : item.shortTermGoalId,
      //dtoPresentingProblems : +this.currentTab == 1 ? this.PresentinProblemsInfo : +this.currentTab == 2 ? this.PresentinProblemsInfo : +this.currentTab == 3 ? this.PresentinProblemsInfo2 : 0 ,
      dtoPresentingProblemsNew :item ,
    }
    this.spinnerservice.show();
    //const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.treatmentservice.ReassignPresentingProblems(_obj).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        if(this.selectedTab == 1)
        {
          this.TargetSymType = 1;
        }else if(this.selectedTab == 2)
        {
          this.TargetSymType = 2;
        }else if(this.selectedTab == 3)
        {
          this.TargetSymType = 3;
        }
        
        this.GetPresentingProb();
        this.currentTab= this.selectedTab.toString();
        this.treatmentservice.TreatmentPlanStatus.next(false)
      },
      err => {
        this.spinnerservice.hide();
        this.currentTab= this.selectedTab.toString();
        this.snackbar.error(err.error);
      },
    );
   }
   generatePdf()
   {
     this.spinnerservice.show();
     this.commonService.getPresentingProblemPdf(this.chmaTreatmentSummaryId).subscribe(
       (res: any) => {
         this.spinnerservice.hide();
         if (res != null) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
         }
       },
       err => {
         this.spinnerservice.hide();
       },
     );
   }

   ViewComments(Commentid,targetSymptomId)
  {
    this._dtoComments='';
    this.targetSymptomId=targetSymptomId;
    localStorage.setItem('targetid',targetSymptomId);

    this.Commentid = Commentid;
    this.getTreatmentPlanComments(Commentid,targetSymptomId);
    this.showRecommandationPopup=true;    
    this.title="Updates";
    this.Header="Comments";
    this.NoRecord="";
    
  }
  OncloseMedicationpopup()
  {
    this.showMedicationsPopup=false;
    this.NoRecord="";

  }
  OncloseRecommandationpopup()
  {
    this.showRecommandationPopup=false;
    this.NoRecord="";
    this.targetSymptomId=null;
    this.Commentid = '';
    this.ShortTermGoalId =null;
    this.shortTermGoalsMasterId =null;
    this.GoalId = null;
    this._dtotreatmentPlanCommentsId  = 0;
  }
  getFrequencyLabel(frequency: string): string {
    switch (frequency) {
      case "1":
        return 'Daily';
      case "2":
        return 'Weekly';
      case "3":
        return 'Monthly';
      default:
        return '';
    }
  }
  onChangedosTo(event)
  {
      this.selectedToDate=this.datepipe.transform(event.value, "yyyy-MM-dd");
  }
  
  getTreatmentPlanComments(Commentid,targetSymptomId) {
    this._dtoComments = '';
    this.patientservice.getTreatmentPlanComments(this.patientAccountId,Commentid,targetSymptomId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res && res.length > 0) {
          this._dtoComments = cloneDeep(res);
        } else 
        {
          // this.onAddMedication();
          this.NoRecord="No record found";
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
    
  }
  showCommentsPopupFun(Commentsid,targetSymptomId)
  {
    
    this._dtoComments='';
    this.CommentsidAll=Commentsid;
    localStorage.setItem('targetid',targetSymptomId);
    this.targetSymptomId =targetSymptomId;

    this.showCommentsPopup=true;
    
    this.Addtitle="MTP Updates";
    this.AddHeader="MTP Updates"; 
   // this.getTreatmentPlanComments(Commentsid,targetSymptomId)

  }
  editCommentPopup(treatmentPlanCommentsId) {
    this.OncloseRecommandationpopup();
    this.showCommentsPopup = true;
    this._dtotreatmentPlanCommentsId = treatmentPlanCommentsId;
    this.getTreatmentPlanCommentsById(this._dtotreatmentPlanCommentsId);

  }
  getTreatmentPlanCommentsById(Commentid) {
    this.patientservice.getTreatmentPlanCommentsById(this.patientAccountId, Commentid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res) {
          this._dtoCommentdetails = res;
          console.log(this._dtoCommentdetails);
          this._dtoTreatmetComments.commentUpdatedate = this._dtoCommentdetails.createdDate;
          this._dtoTreatmetComments.commentsName = this._dtoCommentdetails.commentDetails;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  ondeleteTreatmentPlanComments(treatmentPlanCommentsId) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.treatmentservice.onDeletetreatmentcomment(treatmentPlanCommentsId).subscribe(
          (res: any) => {
            console.log(res)
            this.spinnerservice.hide();
            this.snackbar.success(constantVariables.SuccessMessage.DeletetedSuccesscully);
            if(this.Commentid && this.targetSymptomId)
            {
              //this.getTreatmentPlanComments(this.Commentid,this.targetSymptomId);
              this.ViewComments(this.Commentid,this.targetSymptomId);
            }
           else{
           // this.getTreatmentPlanCommentsGoal(this.ShortTermGoalId);
            this.ViewCommentsPopupForGoals(this.ShortTermGoalId,this.shortTermGoalsMasterId,this.GoalId);
           }
          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },
        );
        
      }
    });
  
  }

  postTreatmentPlanComments(form:NgForm) {
    
    if(this.CommentsidAll==10)
    {
    let localShortTermGoalId=  Number(localStorage.getItem('localShortTermGoalId'));
    let localtargetSymptomId=  Number( localStorage.getItem('localtargetSymptomId'));
    let localGoalId=  Number(localStorage.getItem('localGoalId')) ;
    let localshortTermGoalsMasterId= Number(localStorage.getItem('localshortTermGoalsMasterId'))  ;
     
      this._dtoTreatmetComments.shortTermGoalId=this.ShortTermGoalId ? this.ShortTermGoalId:localShortTermGoalId;
      this._dtoTreatmetComments.targetSymptomId=this.targetSymptomId ? this.targetSymptomId : localtargetSymptomId;
      this._dtoTreatmetComments.ShortTermGoalsMasterid=this.shortTermGoalsMasterId ? this.shortTermGoalsMasterId : localshortTermGoalsMasterId;
      this._dtoTreatmetComments.goalId=this.GoalId ? this.GoalId :localGoalId ;

      this._dtoTreatmetComments.commentsName=this._dtoTreatmetComments.commentsName;
      this._dtoTreatmetComments.commentUpdatedate = this._dtoTreatmetComments.commentUpdatedate;
      // this._dtoTreatmetComments.shortTermGoalId=this.ShortTermGoalId;
      // this._dtoTreatmetComments.targetSymptomId=this.targetSymptomId;
      // this._dtoTreatmetComments.ShortTermGoalsMasterid=this.shortTermGoalsMasterId;
      // this._dtoTreatmetComments.goalId=this.GoalId;
    }
    else
    {
      this._dtoTreatmetComments.commentsName=this._dtoTreatmetComments.commentsName;
      let targetSymptomIdchange  = Number(localStorage.getItem('targetid'));
      this._dtoTreatmetComments.targetSymptomId=this.targetSymptomId ? this.targetSymptomId :targetSymptomIdchange ;
    }
    this._dtoTreatmetComments.treatmentPlanCommentsId = this._dtotreatmentPlanCommentsId;
    // this._dtoTreatmetComments.treatmentDiagosisId=328;
    this._dtoTreatmetComments.commentsId=this.CommentsidAll;
    this._dtoTreatmetComments.chmatreatmentSummaryId = this.chmaTreatmentSummaryId;
    this._dtoTreatmetComments.patientAccountId = this.patientAccountId;

    this.spinnerservice.show();
    this.treatmentservice.postTreatmentPlanComments(this._dtoTreatmetComments).subscribe(
      (res: any) => {
                
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);   
        this._dtotreatmentPlanCommentsId  = 0;    
        this.treatmentservice.TreatmentPlanStatus.next(false)
        //this.getTreatmentPlanComments(this.CommentsidAll);
       
      //  window.location.reload();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
   // this.reset();
  // this._dtoTreatmetComments.treatmentPlanCommentsId =0;
   form.resetForm();
   this.showCommentsPopup=false; 
  }
  OncloseCommentpopup(form:NgForm)
  {
    this.OncloseRecommandationpopup();
    this.showCommentsPopup=false;
    form.resetForm();  
    this.NoRecord="";
  }
  reset()
  {
    this._dtoTreatmetComments.commentsName="";
  }
  showCommentsPopupForGoals(shortTermGoalId, targetSymptomId, shortTermGoalsMasterId, goalId)
  {

    this._dtoComments = '';
    this.CommentsidAll = 10;
    this.ShortTermGoalId = shortTermGoalId;
    this.targetSymptomId = targetSymptomId;
    this.GoalId = goalId;

    localStorage.setItem('localShortTermGoalId',shortTermGoalId);
    localStorage.setItem('localtargetSymptomId',targetSymptomId);
    localStorage.setItem('localGoalId',goalId);
    localStorage.setItem('localshortTermGoalsMasterId',shortTermGoalsMasterId);

  this.shortTermGoalsMasterId=shortTermGoalsMasterId;
  this.showCommentsPopup=true;  
  this.Addtitle="MTP Updates";
  this.AddHeader="MTP Updates"; 
  }
  ViewCommentsPopupForGoals(shortTermGoalId, shortTermGoalsMasterId,goalId)
  {
    this._dtoComments='';
    if(shortTermGoalId==0)
    {
      this.ShortTermGoalId=goalId;
    }
    else
    {
      this.ShortTermGoalId=shortTermGoalId;
    }
    this.GoalId=goalId;

   this.shortTermGoalsMasterId=shortTermGoalsMasterId;  
   


   localStorage.setItem('localShortTermGoalId',shortTermGoalId);
   localStorage.setItem('localtargetSymptomId','0');
   localStorage.setItem('localGoalId',goalId);
   localStorage.setItem('localshortTermGoalsMasterId',shortTermGoalsMasterId);
   
    this.getTreatmentPlanCommentsGoal(this.ShortTermGoalId);
    this.showRecommandationPopup=true;    
    this.title="MTP Updates";
    this.Header="MTP Updates";
    this.NoRecord="";
  }
  getTreatmentPlanCommentsGoal(shortTermGoalId) {
    this.CommentsidAll=10;
    let chma=this.chmaTreatmentSummaryId;
    let shortTermGoalsMasterid=this.shortTermGoalsMasterId;
    this.patientservice.getTreatmentPlanCommentsGoal(this.patientAccountId,this.CommentsidAll,shortTermGoalId,chma,shortTermGoalsMasterid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res && res.length > 0) {
          this._dtoComments = cloneDeep(res);
        } else 
        {
          // this.onAddMedication();
          this.NoRecord="No record found";
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

}

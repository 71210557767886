import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { DatePipe } from '@angular/common';
import { constantVariables } from 'src/app/constant/constant';


@Component({
  selector: 'app-suicidal-screener-document',
  templateUrl: './suicidal-screener-document.component.html',
  styleUrls: ['./suicidal-screener-document.component.scss']
})
export class SuicidalScreenerDocumentComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  patientAccountId: number;
  FormTypeId: Number=1;
  lstDocuments: any;
  pdfSuicdescreener: any;
  CurrentYear: number;
  ddlyear: any[];
  SelectedYear:Number;
  CUrrentMonth: number;
  ddlMonthList=constantVariables.MonthList;
  SelectedMonth:Number;
  constructor(public global: Global, public treatmentservice: TreatmentService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.CurrentYear = new Date().getFullYear();
    this.CUrrentMonth = new Date().getMonth();
    this.SelectedMonth = (this.CUrrentMonth+1);
    this.SelectedYear = this.CurrentYear;
    console.log(this.CUrrentMonth)
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        
      }
    });
    this.getSuicideScreenerDocuments();
    this.getYearList();
    this.getMonthList();
  }

getSuicideScreenerDocuments(){
  let _obj = {
    PatientAccountId : this.patientAccountId,
    FormTypeId : this.FormTypeId,
    Month:this.SelectedMonth,
    Year:this.SelectedYear
  }

  this.spinnerservice.show();
  const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
  this.treatmentservice.getScreenerDocuments(dataToEncrypt).subscribe(
    (res: any) => {     
      this.spinnerservice.hide();

    this.lstDocuments =JSON.parse(res);
    },
    err => {
      this.spinnerservice.hide();

    },
  );
}

OnDownload(item){
  //this.downloadLink = new ElementRef();
  this.spinnerservice.show();
    this.treatmentservice.getSuicidepdf(item.suicideScreeningId,this.FormTypeId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfSuicdescreener = res.filePath;
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
}

OnChangeType(){
  this.SelectedYear = this.CurrentYear
  this.getSuicideScreenerDocuments()
}

getYearList(){
  this.ddlyear =[];
  this.ddlyear.push(this.CurrentYear)
  for(var i=1; i<6; i++){
   this.ddlyear.push(this.CurrentYear-i)
  }
  
}
getMonthList(){
  this.ddlMonthList.map(x=>
             { if(x.value>(this.CUrrentMonth+1) && this.SelectedYear==this.CurrentYear){
                x.isActive=false;
              }
              else{
                x.isActive=true;
              }
            });
  this.getSuicideScreenerDocuments();
}
OnChangeYear(){
this.getMonthList();

}
OnChangeMonth(){
  this.getSuicideScreenerDocuments()
}

OnBack(){
  this.router.navigate(['/sucidal-risk'], { queryParams: { id: this.commonService.FrontEndEncryption(this.patientAccountId) } });

}
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { sfcclocation } from 'src/app/_models/_Common/_sfcc-Location';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { CommonService } from 'src/app/common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { cloneDeep } from "lodash";
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { SiteHeaderComponent } from 'src/app/_Layout/site-header/site-header.component';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
@Component({
  selector: 'app-sfcc-location',
  templateUrl: './sfcc-location.component.html',
  styleUrls: ['./sfcc-location.component.scss'],
  providers: [SiteHeaderComponent],
})
export class SfccLocationComponent implements OnInit {
  // @ViewChild(SiteHeaderComponent, { static: true })  siteHeader:SiteHeaderComponent;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  masterform: NgForm;
  displayedColumns = ['sno', 'locationName', 'isTour', 'address', 'Email', 'Phone', 'actions'];
  dataSource: MatTableDataSource<any>;
  lstSfccLocation: any;
  _sfcclocation: sfcclocation;
  ddlStates: any;
  ddlCities: any;
  isEditlocation: any;
  onpostform: boolean;
  roleAccess: any;
  constructor(public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public settingsService: SettingsService, private siteheader: SiteHeaderComponent
    ,public roleaccessservice:PageAccessService
    ) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Settings;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this._sfcclocation = new sfcclocation();
    this.getddlStateMaster();
    this.getsfcclocation();

  }
  getsfcclocation() {
    this.spinnerservice.show();
    this.settingsService.getSfccLocation().subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this.lstSfccLocation = res;
          this.dataSource = new MatTableDataSource(this.lstSfccLocation);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getddlStateMaster() {
    this.commonService.getstateMaster().subscribe(
      res => {
        if (res != null) {
          this.ddlStates = this.commonService.decrypdata(res.states);
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onChangeState(state) {
    this.commonService.getcities(state.value).subscribe(
      res => {
        this.ddlCities = this.commonService.decrypdata(res.cities);;
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  addorUpdate(locationfrm: NgForm) {
    if (!locationfrm.valid) {
      return
    }
    this.onpostform = true
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this._sfcclocation, false);
    this.settingsService.postSfccLocation(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        locationfrm.resetForm();
        this.isEditlocation = false;
        this.snackbar.success(res);
        this.getsfcclocation();
        this.resetlocationform(locationfrm);
        // window.location.reload();
        this.onpostform = false
        this.siteheader.getsfcclocation();
        
      }
    },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      }
    );

  }
  resetlocationform(locationfrm: NgForm) {
    locationfrm.resetForm();
    this._sfcclocation = new sfcclocation();
  }
  oneditsfccLocation(element) {
    this.global.scrollup();
    this.isEditlocation = true;
    this._sfcclocation = cloneDeep(element);

    if (element.statesId != 0) {
      this._sfcclocation.stateId = element.stateId;
      this.commonService.getcities(element.stateId).subscribe(
        res => {
          this.ddlCities = this.commonService.decrypdata(res.cities);
          if (element.cityId != 0) {
            this._sfcclocation.cityId = this.ddlCities.filter((x) => x.CityName == element.cityId)[0].CitiesId;
          }
        },
        error =>
          this.spinnerservice.hide()
      );
    }
  }
  ondeleteLocation(element) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.deletephbook(element);

      }
    });

  }
  deletephbook(element) {
    this.settingsService.deleteSfccLocation(element.sfccMasterId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.getsfcclocation();
        this.snackbar.success(res.message);
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
}

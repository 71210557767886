<div class="lead-section">

  <div class="top-btn-section clearfix">
    <h2 class="head-section ">Add new lead</h2>
    <div class="ml-auto">
      <button type="submit" form="leadAccount" mat-stroked-button
        class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>
      <button [routerLink]="['/leadlist']" mat-stroked-button class="btn btn-cancel primary-btn-m h-40">Cancel</button>
    </div>
  </div>
  <div class="col-md-12 p-0 mt-2 mb-4">
    <div class="row">
      <div class="col-md-6">
        <form (ngSubmit)="leadAccount.form.valid  && addleadAccount()" id="leadAccount" autocomplete="off"
          #leadAccount="ngForm" novalidate>
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="lead-box">
                <div class="box-lead-head">client information</div>
                <div class="line-input mt-2">
                  <mat-form-field class="w-100">
                    <input matInput placeholder="First Name" appAlphabetOnly [(ngModel)]="patientModel.FirstName"
                      #FirstName="ngModel" name="FirstName"
                      [ngClass]="{ 'is-invalid': leadAccount.submitted && FirstName.invalid }" required>
                    <mat-error *ngIf="leadAccount.submitted && FirstName.invalid">
                      <mat-error *ngIf="FirstName.errors.required">First Name is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Last Name" appAlphabetOnly [(ngModel)]="patientModel.LastName"
                      #LastName="ngModel" name="LastName"
                      [ngClass]="{ 'is-invalid': leadAccount.submitted && LastName.invalid }" required>
                    <mat-error *ngIf="leadAccount.submitted && LastName.invalid">
                      <mat-error *ngIf="LastName.errors.required">Last Name is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label>Gender</mat-label>
                    <mat-select [(ngModel)]="patientModel.Gender" #Gender="ngModel" name="Gender"
                      [ngClass]="{ 'is-invalid': leadAccount.submitted && Gender.invalid }" required>
                      <mat-option *ngFor="let item of ddlgender" [value]="item.value">
                        {{item.label}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="leadAccount.submitted && Gender.invalid">
                      <mat-error *ngIf="Gender.errors.required">Gender is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <!-- <mat-form-field class="w-100">
                    <mat-label>DOB</mat-label>
                    <input matInput (dateChange)="calculateAge(patientModel.Dob)" [matDatepicker]="picker" readonly
                      (click)="picker.open()" [(ngModel)]="patientModel.Dob" #Dob="ngModel" name="Dob"
                      [ngClass]="{ 'is-invalid': leadAccount.submitted && Dob.invalid }" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="leadAccount.submitted && Dob.invalid" >
                      <mat-error *ngIf="Dob.errors.required">DOB is required</mat-error>

                    </mat-error>
                    
                  </mat-form-field> -->
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Age" type="number" [min]="0" maxlength="2"
                      onKeyPress="if(this.value.length==2) return false;" [(ngModel)]="patientModel.Age" #Age="ngModel"
                      name="Age" [ngClass]="{ 'is-invalid': leadAccount.submitted && Age.invalid }" required>
                    <mat-error *ngIf="leadAccount.submitted && Age.invalid">
                      <mat-error *ngIf="Age.errors.required">Age is required</mat-error>
                      <!-- <mat-error *ngIf="Age.errors?.min ">Age must be 5 to 18 years old </mat-error>
                      <mat-error *ngIf="Age.errors?.max">Age must be 5 to 18 years old </mat-error>
                       -->
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="lead-box">
                <div class="box-lead-head">Lead profile information</div>
                <div class="line-input mt-2">
                  <mat-form-field class="w-100">
                    <mat-label>Source</mat-label>
                    <mat-select [(ngModel)]="patientModel.SourceTypeId" #SourceTypeId="ngModel" name="SourceTypeId"
                      [ngClass]="{ 'is-invalid': leadAccount.submitted && SourceTypeId.invalid }" required>
                      <ng-container *ngFor="let item of ddlSoucre">
                        <mat-option *ngIf="item.IsActive" [value]="item.SourceTypeId">
                          {{item.SourceName}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error *ngIf="leadAccount.submitted && SourceTypeId.invalid">
                      <mat-error *ngIf="SourceTypeId.errors.required">Source is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="patientModel.LeadTypeId" #LeadTypeId="ngModel" name="LeadTypeId"
                      [ngClass]="{ 'is-invalid': leadAccount.submitted && LeadTypeId.invalid }" required>
                      <ng-container *ngFor="let item of ddlLeadType">
                        <mat-option *ngIf="item.IsActive" [value]="item.LeadTypeId">
                          {{item.LeadTypeName}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error *ngIf="leadAccount.submitted && LeadTypeId.invalid">
                      <mat-error *ngIf="LeadTypeId.errors.required">Type is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label>Location</mat-label>
                    <mat-select [(ngModel)]="patientModel.FacilityTypeId" #FacilityTypeId="ngModel"
                      name="FacilityTypeId"
                      [ngClass]="{ 'is-invalid': leadAccount.submitted && FacilityTypeId.invalid }" required>
                      <ng-container *ngFor="let item of ddlfacilityType">
                        <mat-option [value]="item.sfccMasterId">
                          {{item.locationName}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error *ngIf="leadAccount.submitted && FacilityTypeId.invalid">
                      <mat-error *ngIf="FacilityTypeId.errors.required">Location is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <!-- <mat-form-field class="w-100">
                    <mat-label>Age Group</mat-label>
                    <mat-select [(ngModel)]="patientModel.ageGroupId" #ageGroupId="ngModel" name="ageGroupId"
                    [ngClass]="{ 'is-invalid': leadAccount.submitted && ageGroupId.invalid }" required>
                      <mat-option>
                      </mat-option>
                    </mat-select>
                    <div *ngIf="leadAccount.submitted && ageGroupId.invalid" >
                      <div *ngIf="ageGroupId.errors.required">Facility is required</div>
                    </div>
                  </mat-form-field> -->
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>

      <div class="col-md-6">
        <form (ngSubmit)="parentform.form.valid && addparent(parentform)" id="parentform" autocomplete="off"
          #parentform="ngForm" novalidate>
          <div class="lead-box">
            <div class="box-lead-head">CONTACT INFO</div>
            <div class="line-input mt-2">
              <div class="col-md-12">

                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="First Name" appAlphabetOnly [(ngModel)]="parentModel.FirstName"
                        #parentFirstName="ngModel" name="parentFirstName"
                        [ngClass]="{ 'is-invalid': parentform.submitted && parentFirstName.invalid }" required>
                      <mat-error *ngIf="parentform.submitted && parentFirstName.invalid">
                        <mat-error *ngIf="parentFirstName.errors.required">First Name is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Last Name" appAlphabetOnly [(ngModel)]="parentModel.LastName"
                        #parentLastName="ngModel" name="parentLastName"
                        [ngClass]="{ 'is-invalid': parentform.submitted && parentLastName.invalid }" required>
                      <mat-error *ngIf="parentform.submitted && parentLastName.invalid">
                        <mat-error *ngIf="parentLastName.errors.required">Last Name is required</mat-error>
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>

              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Email" [(ngModel)]="parentModel.Email" #parentEmail="ngModel"
                        name="parentEmail" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                        [ngClass]="{ 'is-invalid': parentform.submitted && parentEmail.invalid }" required>
                      <mat-error *ngIf="parentform.submitted && parentEmail.invalid">
                        <mat-error *ngIf="parentEmail.errors?.required">Email is required</mat-error>
                        <mat-error *ngIf="parentEmail.errors?.pattern">Please enter valid email address</mat-error>
                      </mat-error>
                    </mat-form-field>

                  </div>
                  <div class="col-lg-6 col-md-12">
                    <mat-form-field class="w-100">
                      <!-- (input)="validatePhoneNo($event.target)" -->
                      <input matInput placeholder="Phone" mask='(000) 000-0000' maxlength="14"
                        [(ngModel)]="parentModel.ContactNo" #parentContactNo="ngModel" name="parentContactNo"
                        [ngClass]="{ 'is-invalid': parentform.submitted && parentContactNo.invalid }" required>
                      <mat-error *ngIf="parentform.submitted && parentContactNo.invalid">
                        <mat-error *ngIf="parentContactNo.errors.required">Phone is required</mat-error>
                        <mat-error *ngIf="parentContactNo.errors?.minlength">Please enter valid phone number</mat-error>
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <mat-form-field class="w-100">
                      <mat-label>Relation</mat-label>
                      <mat-select [(ngModel)]="parentModel.ParentalRelationTypeId"
                        #parentParentalRelationTypeId="ngModel" name="parentParentalRelationTypeId"
                        [ngClass]="{ 'is-invalid': parentform.submitted && parentParentalRelationTypeId.invalid }"
                        required>
                        <mat-option *ngFor="let item of ddlparentalRelationType" [value]="item.ParentalRelationTypeId">
                          {{item.ParentalRelationName}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="parentform.submitted && parentParentalRelationTypeId.invalid">
                        <mat-error *ngIf="parentParentalRelationTypeId.errors.required">Relation is required
                        </mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6 col-md-12 align-self-center">
                    <div class="check-custom">
                      <mat-checkbox class="example-margin" [(ngModel)]="parentModel.IsPrimaryContact"
                        #parentIsPrimaryContact="ngModel" name="parentIsPrimaryContact">Set as primary contact
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-md-12 text-right mt-3">
                 <button type="submit" mat-stroked-button form="parentform"
                  class="btn primary-btn-filled primary-btn-m h-40 ">{{isEditParent == false ? 'Add':'Update'}}</button>
              </div>
            </div>
          </div>
        </form>
        <!-- <div class="col-md-12 text-right mt-3">
          
        </div> -->
      </div>

    </div>
  </div>
</div>


  <!-- for table -->
  <div class="col-md-12 table-custom p-0 ">
    <div class="lead-box">
      <div class="box-lead-head">CONTACT INFO</div>
      <div class="example-container mat-elevation-z8 ">
        <div class="table-responsive">
          <mat-table #table [dataSource]="dataSource" matSort>

            <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="FirstName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.FirstName}} </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="LastName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.LastName}} </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="Email">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.Email}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ContactNo">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.ContactNo | mask: '(000) 000-0000'}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ParentalRelation">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Relation </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.ParentalRelation}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="IsPrimaryContact">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Primary Contact </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.IsPrimaryContact == 1? 'Yes': 'No'}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>Actions </mat-header-cell>
              <mat-cell *matCellDef="let element;let i = index;">
                <button mat-icon-button matTooltip="Edit" (click)="editParentForm(element,i)" color="primary">
                  <img src="../../../assets/images/editblue.png">
                </button>
                <button mat-icon-button matTooltip="Delete" (click)="deleteParentForm(element,i)" color="warn">
                  <img src="../../../assets/images/deletered.png">
                </button>
              </mat-cell>
            </ng-container>
            <!-- <ng-container matColumnDef="nodata">
              <td mat-footer-row *matFooterCellDef [colSpan]="displayedColumns.length" style="text-align: center;">No
                records found</td>
            </ng-container>

            <tr mat-footer-row [hidden]="dataSource.data.length >0" *matFooterRowDef="['nodata']">
            </tr> -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

          </mat-table>
        </div>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
      <!-- for table -->
    </div>
  </div>

<div class="assign-group-theraphy">
  <div class="top-btn-section col-md-12 p-0 clearfix mb-1 w-100">
    <div class="d-flex met-info-top w-100">
      <i class="fas fa-users"></i>
      <div class="mr-4">Assign Group Therapy for
        <span class="imp-info">"{{_dtoGrpTherapy?.groupTherapyName}}" - {{dtoGrpTherapyMeta?.patientName}}</span>
      </div>
      <div class="mr-4 ">
        Start Date
        <span class="imp-info">{{_dtoGrpTherapy?.startDate | date:'MM/dd/yyyy'}} </span>
      </div>
      <div class="mr-4 ">
        Start Time
        <span class="imp-info">{{_dtoGrpTherapy?.startTime | date:'hh:mm a'}}</span>
      </div>
      <div class="mr-4 ">
        Duration
        <span class="imp-info">{{global.getMinutesSlot(_dtoGrpTherapy?.duration)}} </span>
      </div>
      <div class="mr-4 " *ngIf="_dtoGrpTherapy?.IsEndDate">
        End Date
        <span class="imp-info">{{_dtoGrpTherapy?.endDate | date:'MM/dd/yyyy'}} </span>
      </div>
      <div class="mr-4 ">
        Capacity
        <span class="imp-info">{{_dtoGrpTherapy?.capacity}} </span>
      </div>
      <div class="ml-auto">
        <button type="button" mat-stroked-button (click)="onScheduleModality()"
          class="bg-white top-back  mt-1 mb-2 h-40 mr-2">Back</button>
      </div>
    </div>

    <!-- <div class="col-md-6 ml-auto mt-2 pr-0 text-right">
      
    </div> -->
  </div>


  <div class="kids-table fiftyvh">
    <div class="section-kids">
      <div class="top-head-kids  text-center"></div>
      <!-- <div class="top-head-kids  text-center">{{_dtoGrpTherapy?.groupTherapyName}}</div> -->
      <div class="info-kids"><span class="unbook">Avalible Slots {{_dtoGrpTherapy?.available}} </span> | <span
          class="book">Booked Slots {{_dtoGrpTherapy?.booked}}
        </span> </div>
      <!-- |  Remaining {{_dtoGrpTherapy?.capacity}} -->
      <div class="col-md-12 ">

        <div class="kids-name row">
          <ng-container *ngFor="let item of _dtoSlots;let i = index">
            <ng-container *ngIf="item.status == 0">
              <a class="available  position-relative assign-section">
                <div>Slot {{i+1}}</div>
                <!-- *ngIf="!dtoGrpTherapyMeta?.isView" -->

                <ng-container *ngIf="i === isShowAssign">
                  <button type="button" (click)="onbookSlots(item)" class="assign-btn cursor-pointer">Save</button>

                </ng-container>

                <ng-container *ngIf="i !== isShowAssign">
                  <button type="button" (click)="onAssignClick(i)" class="assign-btn cursor-pointer">Assign</button>
                </ng-container>
                <div class="name-kids">{{item.name}}</div>
                <ng-container *ngIf="i === isShowAssign">
                  <div class="col-md-12">
                    <mat-form-field class="w-100">
                      <mat-label>Assign Date</mat-label>
                      <!-- [min]="currentdate" -->
                      <input matInput [matDatepicker]="picker" readonly [min]="treatmentPlanDate"
                        [max]="treatmentenddate" (click)="picker.open()" #assignDate1="ngModel" name="assignDate{{i}}"
                        [(ngModel)]="assignDate" required>
                      <mat-datepicker-toggle matSuffix [for]="picker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>

                    </mat-form-field>
                    <mat-error class="mat-form-field-subscript-wrapper"
                      *ngIf="isReqAssignTherapy && assignDate == undefined ">Assign date is
                      required
                    </mat-error>
                  </div>
                  <div class="col-md-12">
                    <mat-form-field class="w-100">
                      <mat-label>Release Date</mat-label>
                      <input matInput [matDatepicker]="picker1" readonly customdate sDate="{{assignDate}}"
                        [min]="assignDate == undefined ? treatmentPlanDate : assignDate" [max]="treatmentenddate"
                        (click)="picker1.open()" #releaseDate1="ngModel" name="releaseDate{{i}}"
                        [(ngModel)]="releaseDate" required>
                      <mat-datepicker-toggle matSuffix [for]="picker1">
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>

                    </mat-form-field>
                    <mat-error class="mat-form-field-subscript-wrapper"
                      *ngIf="isReqAssignTherapy && releaseDate == undefined ">Release date
                      is
                      required
                    </mat-error>
                    <mat-error class="mat-form-field-subscript-wrapper w-100" *ngIf="releaseDate1.errors?.customdate">
                      Release date should be greater then assigns date.
                    </mat-error>
                  </div>
                  
                </ng-container>
              </a>
            </ng-container>
            <ng-container *ngIf="item.status == statusbooked">
              <a class="booked cursor-pointer position-relative reassign-section">
                <div>Slot {{i+1}}</div>
                <!-- <button class="reassign-btn" type="button" (click)="onCancelSlots(item)">Cancel</button> -->

                <div class="name-kids">{{item.name}}</div>
                <!-- <div class="text-out" *ngif="item.daysleft <= 10">{{item.name}} is Released from {{item.summary}}</div> -->
              </a>
            </ng-container>
          </ng-container>

          <!-- <a class="booked ">
            <div>Slot 3</div>
            <div class="name-kids">kumar</div>
            <div class="text-out">Ashok is Released from 4th Nov</div>
          </a> -->

        </div>
      </div>
    </div>




  </div>
</div>
import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';


declare function EncryptFieldData(data): any;
declare function FrontEndEncryption(data): any;
declare function FrontEndDecryption(data): any;
declare function decrypt(data): any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  constructor(private http: HttpClient,) { }
  public adminDayViewDrpChange =new BehaviorSubject(true);
  public BenchmarkPopUp = new BehaviorSubject(true);
  public UpdateProfileImage =new BehaviorSubject(true);
  public TherapyPopup  = new BehaviorSubject(true);
  public ScheduleModality  = new BehaviorSubject(true);
  public GroupPopShow = new BehaviorSubject(true);
  DataToEncrypt(inputData, isGet?: boolean) {
    try {
      if (isGet) {
        const dataToEncryptGet = EncryptFieldData(JSON.stringify(inputData));
        return dataToEncryptGet;
      }
      else {
        const dataToEncrypt = {
          ObjInputString: EncryptFieldData(JSON.stringify(inputData))
        };
        return dataToEncrypt;
      }
    }
    catch (error) {
      return '';
    }
  }
  decrypdata(inputData) {
    return decrypt(inputData);
  }
  FrontEndEncryption(inputData) {

    return FrontEndEncryption(JSON.stringify(inputData));
  }
  FrontEndDecryption(inputData) {

    return FrontEndDecryption(JSON.stringify(inputData));

  }
  getMasters(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetCommonMaster?id=' + this.DataToEncrypt(id, true));
  }
  postMasters(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/SaveMaster',
      JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  deleteMasters(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/DeleteMaster',
      JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  changeStatusMaster(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/ChangeStatusMaster',
      JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postEmail(data) {
    //return this.http.post(environment.BaseURI + 'gateway/SendMailQueryPost/SaveMailQuery', data, { reportProgress: true, observe: 'events' });
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    return this.http.post(environment.BaseURI + 'gateway/SendMailQueryPost/SaveMailQuery', data)
      .pipe(map(res => {

        return res;
      }));
  }
  UploadDocment(data) {

    return this.http.post(environment.BaseURI + 'gateway/SendMailQueryPost/UploadQueryDocument', data, { reportProgress: true, observe: 'events' });
  }
  postReturnPacket(id) {
    //return this.http.post(environment.BaseURI + 'gateway/LeadContact/PatientVerified?id=' + this.commonService.DataToEncrypt(id, true), null);
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/LeadContact/PatientReturn',
      this.DataToEncrypt(id, false), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getcities(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetCitiesMaster?id=' + this.DataToEncrypt(id, true));
  }
  getStaffList(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/GetStaffList?id=' + this.DataToEncrypt(id, true));
  }
  getStaffListMultipleClinician(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/GetStaffListMultipleClinician?id=' + this.DataToEncrypt(id, true));
  }
  getStaffInternList(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetStaffInternList?id=' + this.DataToEncrypt(id, true));
  }
  getGroupMeetingTherapyMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetGroupMeetingTherapy');
  }
  getstateMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetStatesMaster');
  }
  getProfileImage(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/User/GetProfileImageById?id=' + this.DataToEncrypt(id, true));
  }
   //async getLicensedDetails(id){
   //  let licensedData = await this.http.get<any>(environment.BaseURI + 'gateway/Master/GetLicensedDetails?id=' + this.DataToEncrypt(id, true)).toPromise();
   //   return licensedData;
   // }
  getSfccLocation() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetSfccLocation/GetLocationList');
  }
  //blocktime
  getPurposeOfBlock(){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetPurposeMaster');
  }
  getColorOfBlock(){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetColorCodeMaster');
  }
  getBlockTime(){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetBlockTime');
  }

  postBlockTime(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/SaveBlockTime',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postDiagnosiscode(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/PostDiagnosiscode',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getDiagonosisCode(){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetDiagonosisCode');
  }
  getDiagonosisCodeMaster(){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetDiagonosisCodeMaster');
  }
  getDiagonosisCodeMasterBill(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetDiagonosisCodeMasterBill?id=' + this.DataToEncrypt(id, true));
  }
  deleteDiagnosis(id) {
  
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/DeleteDiagnosis?id=' + this.DataToEncrypt(id, true));
  }
  getBlockTimeId(id) {
    // return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetBlockTimeId?id=' + this.commonService.DataToEncrypt(id, true));
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetBlockTimeId?id=' + this.DataToEncrypt(id, true));

  }
  deleteBlockTime(id) {
  
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/DeleteBlockTimeId?id=' + this.DataToEncrypt(id, true));
      // JSON.stringify(data), { headers: headers, responseType: 'text' })
      // .pipe(map(function (response) { return response; }));
  }
  setLocation(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    return this.http.post(environment.BaseURI + 'gateway/Authenticate/SetLocation', data)
      .pipe(map(res => {

        return res;
      }));
  } 
  postEsigndata(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    
    return this.http.post(environment.BaseURI + 'gateway/Upload/UploadEsign', data)
       .pipe(map(res => {

         return res;
      }));
    }
    getGroupTherapy(id) {
      return this.http.get<any>(environment.BaseURI + 'gateway/GetAppointmentTypes/GetGroupTherapy?Id=' + this.DataToEncrypt(id, true));
    }
    getIntakeAppointments(id) {
      return this.http.get<any>(environment.BaseURI + 'gateway/GetAppointmentTypes/GetIntakeAppointments?Id=' + this.DataToEncrypt(id, true));
    }
    getLeadCurrentStatus(id){
      return this.http.get<any>(environment.BaseURI + 'gateway/GetFinancialProposal/GetLeadCurrentStatus?id=' + this.DataToEncrypt(id, true));
    }
    getPatientAccountId(id){
      return this.http.get<any>(environment.BaseURI + 'gateway/GetFinancialProposal/GetPatientAccountId?id=' + this.DataToEncrypt(id, true));
    }
    getReArrangeClinician(id,location){
      return this.http.get<any>(environment.BaseURI + 'gateway/GetAppointmentTypes/GetReArrangeClinician?Id=' + this.DataToEncrypt(id, true) + '&location='+this.DataToEncrypt(location, true));
   }
   getIntakeAppointments1(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetAppointmentTypes/GetIntakeAppointments?Id=' + this.DataToEncrypt(id, true));
  }
  getIntakeAppointmentsAdminDayView(id,date,clinicianid,loginUserId,locationid) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetAppointmentTypes/GetAdminDayViewLists?id=' + this.DataToEncrypt(id, true)+ '&encdate=' + this.DataToEncrypt(date, true)+'&clinicianId='+this.DataToEncrypt(clinicianid, true)+'&loginUserId='+this.DataToEncrypt(loginUserId, true)+'&locationId='+this.DataToEncrypt(locationid, true) );
  }
  getStaffListV2(id,location) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/GetStaffListV2?id=' + this.DataToEncrypt(id, true)+'&location='+this.DataToEncrypt(location, true));
  }
  getSupportStaffListV2(id,location) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/getSupportStaffListV2?id=' + this.DataToEncrypt(id, true)+'&location='+this.DataToEncrypt(location, true));
  }
  GetManageAccessByid(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetManageAccessByUserId?id=' + this.DataToEncrypt(id, true));
  }
  getFormCommunicationMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetFormCommunicationMaster');
  }
  getSeverityMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetSeverityMaster');
  }
  GetMindsetMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetMindsetMaster');
  }
  getUpcommingAppointmentsAdmin(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetUpcommingAppointmentsAdmin?Id=' + this.DataToEncrypt(id, true));
  } 
  
  PostGroupMeeting(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostGroupMeeting',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getUpcmgGrpAppointmentsAdmin(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetUpcommingGrpAppointmentsAdmin?location='+this.DataToEncrypt(id, true));
  }
  getStaffListForGroupTherapy(id,location,groupTherapyId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/GetStaffListForGroupTherapy?id=' + this.DataToEncrypt(id, true)+'&location='+this.DataToEncrypt(location, true)+'&groupTherapyId='+this.DataToEncrypt(groupTherapyId, true));
  }
  GetTourList(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetTourList?location='+this.DataToEncrypt(id, true));
  }
  getDiagnosisInformationPdf(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetDiagnosisInformationPdf?id=' + this.DataToEncrypt(id, true));
  }
  getModalityPdf(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetModalityPdf?id=' + this.DataToEncrypt(id, true));
  }

  getGoalPdf(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetGoalPdf?id=' + this.DataToEncrypt(id, true));
  }
  getTreatmentConsentPdf(id,provider,username) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetTreatmentConsentPdf?id=' + this.DataToEncrypt(id, true)+'&provider=' + this.DataToEncrypt(provider, true)+'&username=' + this.DataToEncrypt(username, true));
  }
  getConfigureProgressTrackerPdf(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetConfigureProgressTrackerPdf?id=' + this.DataToEncrypt(id, true));
  }
  getPresentingProblemPdf(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetPresentingProblemPdf?id=' + this.DataToEncrypt(id, true));
  }
  postListOrder(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/SaveListOrder',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getTreatmentPlanPdf(id,username) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/getTreatmentPlanPdf?id=' + this.DataToEncrypt(id, true)
    +'&username=' + this.DataToEncrypt(username, true));
  }
  ChangeSkilltaught(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/ChangeSkilltaught',
      JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  deleteSkilltaught(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/DeleteSkilltaught',
      JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  onActiveSkilltaught(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/onActiveSkilltaught',
      JSON.stringify(data), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
}


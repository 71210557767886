export class DischargeSummaryFormInfo {
    dischargeSummaryId:Number;
    patientAccountId:Number;
    clinicianId:Number;
    dsDate:any;
    isDischarge:boolean;
    isDropOut:boolean;
    diagnosisCode:any;
    startDate:any;
    terminateDate:any;
    presentingProblem:string="____ presents with the following symptoms and behaviors:";
    objectives:string="Objectives of treatment are as follows:  Goal #1.  Goal #2.";
    significantFindings:string="Over the course of treatment counselors observed _____";
    clinicalCondition:string="At termination ____ presented with the following symptoms:";
    treatmentInterventions:string="_____ attended the IOP program at Stepping Forward Counseling Center # of days per week from _____ to ____ . With this, ____ was placed in a small group of peers (6-18) around her age. ____ was placed on a token economy-based behavior modification program which provided reinforcements when ____ met enough of ____ treatment goals throughout the group. Additional interventions included: implementation of coping skills, movement therapy, relaxation training (deep breathing), social skills training, mindfulness, cognitive behavior therapy, to increase ___ flexibility and increase self-esteem and help support relationship building. Finally, ___ was provided with praise, validation, as well as visual, physical and verbal prompts in order to encourage ____ to work on ___ specific treatment goals.";
    treatmentProvided:string="The treatment provided for ___ was group therapy through behavior modification. ____ was involved in interpersonal effectiveness, social skills training, distress tolerance and life skills training. With the treatment provided ____ made mild progress toward both treatment goals. Further, ___ mildly improved peer engagement and taking responsibility for ___ actions and utilization of coping skills. ___ continued to present with fragile self-esteem and poor social skills.";
    terminationReason:string="Parents enrolled ___ for ___ weeks of IOP. ___ was terminated upon parent request. ";
    dischargePlan:string= "It is recommended that ____ continue to work on" ;
    treatmentRecommendations:string="Receive communication skills training focused on engaging with peers effectively (social skills groups)";
    followup:string;
    comments:string;
    clinicianSignature:string;
    clinicianSignDate:any;
    clientSigntaure:string;
    clientSignDate:any;
    famMemSignature:string;
    famMemSignDate:any;
    medication:Array<DischargeMedication>;
    dob:any;
    dischargeDetails:string;
    dropoutReason:string;
    dstype:any;
    treatmentSummaryId:Number;
    licenseNumber : string;
    sSignature : boolean;
    IsLicensed : boolean;
}

export class DischargeMedication{
    dischargeMedicationId:Number=0;
    dischargeSummaryId:Number;
    patientAccountId:Number;
    medications:string;
    dosage:string;
    frequency:string;
    sideEffects:string;
}
<div class="row">
    <div class="col-md-12 px-4">
    
        <button
        type="submit"
        class="btn primary-btn-outlined-small ng-star-inserted float-right"
        (click)="generatePdf()"
      >
        Generate PDF
      </button>
        </div>
        <div class="col-md-12">
    <div class="presenting-section" [ngClass]="roleAccess.isCreate||roleAccess.isEdit?'':'disabled'">
        <!-- (selectedTabChange)="OnTabChange($event)" -->
     <mat-tab-group  [selectedIndex]="Type" (selectedTabChange)="OnTabChange($event)">
        <mat-tab label="#1">
        <form (ngSubmit)="PresentingProbfrm.form.valid " id="PresentingProbfrm" autocomplete="off" #PresentingProbfrm="ngForm"
        novalidate>
        <div class="col-md-12 mt-2">
            <div class="row">
                <label  class="treat-label">Presenting Problem Description/ Target Symptom </label>
                <div  style="padding-left: 10px;">       
                    <a  
            title="Add update"
            (click)="showCommentsPopupFun(5,1)"
            >
               <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a          title="view updates"
            (click)="ViewComments(5,1)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div>
                <div class="col-md-12 p-0 form-group">
                    <!-- <mat-form-field class="w-100 custom-textarea mt-2">
                        
                        <textarea matInput maxlength="3000"
                        [(ngModel)]="PresentinProblemsInfo.targetSymptom" #targetSymptom="ngModel" name="targetSymptom"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm.submitted && targetSymptom.invalid }"
                        required ></textarea> -->
                        <app-word-counter-textarea [text]="PresentinProblemsInfo.targetSymptom" [maxlength]="3000"  [(ngModel)]="PresentinProblemsInfo.targetSymptom" 
                        (textChange)="PresentinProblemsInfo.targetSymptom = $event" #targetSymptom="ngModel" name="targetSymptom" ngDefaultControl  required></app-word-counter-textarea>
                        <!-- <mat-error *ngIf="PresentingProbfrm.submitted && (PresentinProblemsInfo.targetSymptom == '' || PresentinProblemsInfo.targetSymptom == null || PresentinProblemsInfo.targetSymptom == undefined)">
                          <mat-error class="mat-form-field-subscript-wrapper mt-3">Presenting Problems
                            is
                            required</mat-error>
                        </mat-error> -->
    
                        <mat-error *ngIf="PresentingProbfrm.submitted && PresentinProblemsInfo.targetSymptom == ''">
                            <mat-error class="text-xs" *ngIf="PresentinProblemsInfo.targetSymptom== ''">
                                Presenting Problem Description/ Target Symptom  is required</mat-error>
                        </mat-error>
    
                      <!-- </mat-form-field> -->
                    
                </div>
       
            </div>
            <div class="row">
                <label  class="treat-label">Long-Term Goal</label>
                <div  style="padding-left: 10px;">       
                    <a   
            title="Add update"
            (click)="showCommentsPopupFun(6,1)"
            >
               <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a  
            title="view updates"
            (click)="ViewComments(6,1)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div>
                <div class="col-md-12 p-0 form-group">
                    <!-- <mat-form-field class="w-100 custom-textarea mt-2">
                        
                        <textarea matInput maxlength="3000"
                        [(ngModel)]="PresentinProblemsInfo.longTermGoal" #longTermGoal="ngModel" name="longTermGoal"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm.submitted && longTermGoal.invalid }"
                        required></textarea> -->
                        <app-word-counter-textarea [text]="PresentinProblemsInfo.longTermGoal" [maxlength]="3000"  [(ngModel)]="PresentinProblemsInfo.longTermGoal" 
                        (textChange)="PresentinProblemsInfo.longTermGoal = $event" #longTermGoal="ngModel" name="longTermGoal" ngDefaultControl  required></app-word-counter-textarea>
    
                        <mat-error *ngIf="PresentingProbfrm.submitted && PresentinProblemsInfo.longTermGoal == ''">
                            <mat-error class="text-xs" *ngIf="PresentinProblemsInfo.longTermGoal== ''">
                                Long-Term Goal is required</mat-error>
                        </mat-error>
                      <!-- </mat-form-field> -->
                </div>
            </div>
            <div class="row">
                <label  class="treat-label">Interventions</label>
                <!-- <div  style="padding-left: 10px;">       
                    <a  
            title="Add update"
            (click)="showCommentsPopupFun(7,1)"
            >
               <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a  
            title="view updates"
            (click)="ViewComments(7,1)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div> -->
                <div class="col-md-12 p-0 form-group">
                    <!-- <mat-form-field class="w-100 custom-textarea mt-2">
                        
                        <textarea matInput maxlength="3000"
                        [(ngModel)]="PresentinProblemsInfo.interventions" #interventions="ngModel" name="interventions"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm.submitted && interventions.invalid }"
                        required></textarea> -->
    
                        <app-word-counter-textarea [text]="PresentinProblemsInfo.interventions" [maxlength]="3000"  [(ngModel)]="PresentinProblemsInfo.interventions" 
                        (textChange)="PresentinProblemsInfo.interventions = $event" #interventions="ngModel" name="interventions" ngDefaultControl  required></app-word-counter-textarea>
                        <mat-error *ngIf="PresentingProbfrm.submitted && PresentinProblemsInfo.interventions == ''">
                            <mat-error class="text-xs" *ngIf="PresentinProblemsInfo.interventions== ''">
                                Interventions is required</mat-error>
                        </mat-error>
                      <!-- </mat-form-field> -->
                </div>
            </div>
            <div class="row">
                <label  class="treat-label">Responsible Staff/ Individuals/ Agencies</label>
                <!-- <div   style="padding-left: 10px;">       
                    <a 
            title="Add update"
            (click)="showCommentsPopupFun(8,1)"
            >
               <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a  title="View"
            (click)="ViewComments(8,1)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div> -->
            </div>
            <div class="row">
                <div class="col-md-6 p-0 form-group">
                    <mat-form-field class="w-100  mt-2">
                        
                        <input matInput maxlength="300" [(ngModel)]="PresentinProblemsInfo.rsponsibleStaff" #rsponsibleStaff="ngModel" name="rsponsibleStaff"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm.submitted && rsponsibleStaff.invalid }"
                        required>
                    
                      <mat-error *ngIf="PresentingProbfrm.submitted && PresentinProblemsInfo.rsponsibleStaff == ''">
                        <mat-error class="text-xs" *ngIf="PresentinProblemsInfo.rsponsibleStaff== ''">
                            Responsible Staff/ Individuals/ Agencies is required</mat-error>
                    </mat-error>
                </mat-form-field>
                </div>
                <div class="col-md-6 p-0 form-group">
               
                    <label></label>
                </div>
            </div>
        </div>
       
      
        <div class="col-md-12 ">
            <div class="row">
                <h2  class="prsenting-tab mt-3">Measurable Objectives/ Short-Term Goals</h2>
                <div class="col-md-12 treat-tab text-center mt-3"  *ngIf="!isExistTab1"> No goals available  </div>
                <div class="prsenting-tab mt-3" *ngIf="isExistTab1">
               <div class="goal-percen mb-2 w-100"  *ngFor="let static of PresentinProblemsInfo.staticGoals;let i=index">
                <div class="row">
                <div  class="col-md-9 custom-check bennew_tit">
                    <mat-checkbox class="example-margin"  [(ngModel)]="static.isActive" #isActive="ngModel" name="isActive{{i}}" (change)="OnGoalSelectionChange(1)">{{static.goalName}}</mat-checkbox>
    
                </div>
                            
                <div  class="col-md-1 mb-3 pl-5">       
                    <a   class="btn btn-sm pl-4"
            title="Add update"
            (click)="showCommentsPopupForGoals(static.shortTermGoalId,1,static.shortTermGoalsMasterId,static.goalId)" 
            >
            <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div   class="col-md-1 mb-3">       
                    <a   class="btn btn-sm"
            title="View"
            (click)="ViewCommentsPopupForGoals(static.shortTermGoalId,static.shortTermGoalsMasterId,static.goalId)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div>
    
                    <div class="col-md-1 mb-3">
                        <div class="ml-auto">
                            <mat-form-field class="w-100">
                                <mat-label>Move to</mat-label>
                                <mat-select [(ngModel)]="currentTab" #currentTab1="ngModel" name="currentTab1{{i}}" 
                                (selectionChange)="onSelectionChanged(static,1,PresentinProblemsInfo)">
                                <mat-option value="1" [disabled]="selectedTab == 1"> 1 </mat-option>
                                <mat-option value="2" [disabled]="selectedTab == 2" *ngIf=" IsSecondTab"> 2 </mat-option>
                                <mat-option value="3" [disabled]="selectedTab == 3" *ngIf=" IsThiredTab"> 3 </mat-option>
                                </mat-select>                                                
                        </mat-form-field>
                              
                        </div>
                    </div>
                </div>
                <div  class="">
                  
                    <div class="d-flex">
                        <label  class="mr-2  mt-3 bennew_tit ">Description</label>
                        <div class="ml-auto mb-3">
                            <mat-form-field class="w-100 mt--14">
                                <mat-label>Target Date</mat-label>
                                <input matInput [matDatepicker]="pickerPT" [(ngModel)]="static.targetDate" #targetDate="ngModel" name="targetDate{{i}}" 
                                [required]="static.isActive" >
                                <mat-datepicker-toggle matSuffix [for]="pickerPT"></mat-datepicker-toggle>
                                <mat-datepicker #pickerPT></mat-datepicker>
                                <mat-error *ngIf="PresentingProbfrm.submitted && targetDate.invalid">
                                    <mat-error class="text-xs" *ngIf="targetDate.invalid">
                                        Target Date is required</mat-error>
                                </mat-error>
                              </mat-form-field>
                              
                        </div>
                    </div>
                   
                    <div class="w-100">
                        <textarea class="form-control" rows="5" maxlength="3000" [(ngModel)]="static.goalDescription" #goalDescription="ngModel" name="goalDescription{{i}}" [ngClass]="{ 'is-invalid': PresentingProbfrm.submitted && goalDescription.invalid }"
                        [required]="static.isActive" ></textarea>
                        <!-- <app-word-counter-textarea [text]="static.goalDescription" [maxlength]="3000"  [(ngModel)]="static.goalDescription" 
                        (textChange)="static.goalDescription = $event" #goalDescription="ngModel" name="goalDescription" ngDefaultControl [required]="static.isActive"></app-word-counter-textarea> -->
                        <mat-error *ngIf="PresentingProbfrm.submitted && goalDescription.invalid">
                            <mat-error class="text-xs"  *ngIf="goalDescription.invalid">
                                Description is required</mat-error>
                        </mat-error>
                    </div>
                </div>
                
                <div  class="goal-desc-expand mt-2">
                    <div  class="col-md-12 ben-head position-relative">
                        <div  class="row">
                            <div  class="col-md-12 d-flex">
                                <div  class=" bennew_tit "> Benchmark  </div>
                                <div class="ml-auto"><div  class="col-md-12 form-group p-0">
                                    <mat-form-field class="w-100">
                                        <mat-label>Measure Type</mat-label>
                                        <mat-select [(ngModel)]="static.measureTypeId" #measureTypeId="ngModel" name="measureTypeId{{i}}" 
                                        [required]="static.isActive" >
                                            <mat-option [value]="5"> Percentage based </mat-option>
                                            <mat-option [value]="6"> Time based </mat-option>
                                            <mat-option [value]="7"  *ngIf="static.goalId !=0"> Number of exchanges based </mat-option>
                                            <mat-option [value]="8"  *ngIf="static.goalId !=0"> Number of initiation per activity based </mat-option>
                                            <mat-option [value]="9"  *ngIf="static.goalId !=0"> Number of responses based </mat-option>
                                            <mat-option [value]="10" *ngIf="static.goalId !=0"> Others </mat-option>
                                        </mat-select>                                                
                                </mat-form-field>
                                <mat-error *ngIf="PresentingProbfrm.submitted &&  static.measureTypeId=='' && static.isActive ">
                                    <mat-error class="text-xs"  *ngIf=" static.measureTypeId=='' ">
                                        Measure Type is required</mat-error>
                                </mat-error>
                                    </div></div>
                            </div>
                            
                            </div>
                            <div *ngFor="let com of static.measures;let j=index">
                            <div  class="row" >
                            <div  class="col-md-3">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Benchmark Title</mat-label>
                                        <input matInput maxlength="100" [(ngModel)]="com.benchmarktitle"
                                        #benchmarktitle="ngModel" name="benchmarktitle{{j}}{{i}}"
                                        [required]="static.isActive" [invalid]="static.isActive">
                                        <!-- [ngClass]="{ 'is-invalid': PresentingProbfrm.submitted && com.benchmarktitle=='' && static.isActive}" -->
                                     
                                      <mat-error *ngIf="PresentingProbfrm.submitted && benchmarktitle.invalid && static.isActive">
                                        <mat-error class="text-xs"  *ngIf="benchmarktitle.invalid">
                                            Benchmark Title is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div  class="col-md-6">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Benchmark Description</mat-label>
                                        <input matInput maxlength="3000" [(ngModel)]="com.benchmarkdiscription"
                                        #benchmarkdiscription="ngModel" name="benchmarkdiscription{{j}}{{i}}"
                                       
                                        [required]="static.isActive" >
                                      
                                      <mat-error *ngIf="PresentingProbfrm.submitted && benchmarkdiscription.invalid && static.isActive">
                                        <mat-error class="text-xs"  *ngIf="benchmarkdiscription.invalid">
                                            Benchmark Description is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            
                            <div  class="col-md-2 d-flex">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Unit of Measure</mat-label>
                                        <input matInput [(ngModel)]="com.benchmarkunit"
                                        #benchmarkunit="ngModel" name="benchmarkunit{{j}}{{i}}"
                                       
                                        [required]="static.isActive"  step="0" maxlength="4" percentage customMin="1">
                                      
                                      <mat-error *ngIf="PresentingProbfrm.submitted && benchmarkunit.invalid && static.isActive">
                                        <mat-error class="text-xs"  *ngIf="benchmarkunit.invalid">
                                            Unit of Measure is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                                <span  *ngIf="static.measureTypeId == PercentageBased" class="d-inline-block mt-3 pl-2">%</span>
                                <span *ngIf="static.measureTypeId == Timebased" class="d-inline-block mt-3 pl-2">min</span>
                            </div>
                            <div  class="col-md-1">
                                <button (click)="addMeasures(static,1)" type="button" class="btn btn-primary list_add_button btn-theme mr-1">+</button>
                                <button  *ngIf="j > 0" (click)="removeMeasures(j,static,1)" type="button" class="btn btn-danger list_add_button ">-</button>
                            </div>
                                    
                            </div>
                        </div>
                            </div>
                            </div>
                           
               </div> 
            </div>
    
            </div>
            </div>
            <!--For After saving the goals showing it like configure progress tracker tab-->
            <!-- <div class="col-md-12 ">
                <div class="row">
                <div class="w-100 mt-3" *ngIf="PresentinProblemsInfo.presentingProblemId !=0 ">
               <div class="goal-percen mb-2 w-100" *ngFor="let static of PresentinProblemsInfo.staticGoals;let i=index">
                <div>
                <div  class="d-flex">
                    <div  class="goal-name">
                        <label  class="mr-2 fw-14">Goal:</label>
                        <span  class="value-goal fw-14" >{{static.goalName}}</span>
                    </div>
                    <div  class="ml-auto">
                        <button  class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(static)">Add Benchmark</button>
                    </div>
                </div>
                    <div class="d-flex goal-name">
                        <label  class="mr-2  mt-3 fw-14">Description</label>
                        <div class="ml-auto mt-3">
                            <div class="goal-name">
                                <label  class="mr-2 fw-14">Target Date:</label>
                                <span  class="value-goal fw-14">{{static.targetDate | date:'MM/dd/YYYY'}}</span>
                            </div>
                        </div>
                    </div>
                   
                    <div class="w-100">
                        <div  class="value-goal"> {{static.goalDescription}} </div>
                    </div>
                </div>
                <div class="options-goal mt-2" >
                    <mat-radio-group aria-label="Select an option"  [(ngModel)]="static.selectedTrackbenchId"  name="selectedTrackbenchId2{{i}}">
                        <div   *ngFor="let com of static.measures;let j=index">
                        <div class="d-flex w-100 ">
                            
                            <mat-radio-button class="w-100"   [value]="com.goalBenchmarkId"   (click)="OnselectTrackbenchmark(com,static)" >{{com.benchmarktitle}} </mat-radio-button>
                            <div class="ml-auto">
                        </div>
                            </div>
                            <div class="goal-desc-expand" *ngIf="com.trackBenchIsSelected">
                            <div class="col-md-12 ben-head position-relative">
                                <div  class="row">
                                    <div  class="col-md-8 ben_des">
                                        <div  class="ben_tit"> Benchmark Description </div>
                                       {{com.benchmarkdiscription}} </div>
                                        <div class="col-md-2">
                                            <div class="goal-stat-chang mt-3">
                                                <span class="status-tabl inprogress" *ngIf="com.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                <span class="status-tabl completed" *ngIf="com.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                            </div>
                                        </div>
                                         <div  class="col-md-2 ben_unit pr-4">
                                             <p  class="text-muted text-xs">Unit of Measure</p> {{com.benchmarkunit}} </div>
                                             <div class="edit-float" >
                                                <a><i class="fas fa-edit" (click)="OnEditBenchmark(com,static)"></i></a>
    
                                             </div>
                                             <div  class="delete-float"  *ngIf="com.status==1">
                                                <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(com.trackBenchmarkId)"></i></a></div>
                                            </div>
                                        </div>
                        </div>
                    </div>
                     
                      </mat-radio-group>
                </div>
                
                           
               </div> 
               </div>
               </div>
            
            <div class="row">
                <div class="col-md-12">
                   
                    <div class="row clearfix">
                       
                       
                         
                        <div  class="col-md-12 ">
                            <div class="form-group clearfix m-0">
                             
                                        </div>
                                    </div>
    
                         
                    </div>
                </div>
                
                
            </div>
            
        </div>  -->
        <div class="col-md-12">
            <div class="text-right" *ngIf="PresentinProblemsInfo.presentingProblemId ==0 ">
                <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button *ngIf="Sym1GoalCount==SelectedGoals" (click)="OnPostPresentingProblem(1,0)">Save</button>
                <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button *ngIf="Sym1GoalCount!==SelectedGoals" (click)="OnPostPresentingProblem(1,2)">Save & Next</button>
                <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" (click)="OnCancel()" mat-stroked-button>Cancel</button>
            </div>
            <div class="text-right" *ngIf="PresentinProblemsInfo.presentingProblemId !=0 ">
                <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="OnPostPresentingProblem(1,1)">Update</button>
            </div>
        </div>
    </form>
    </mat-tab>
    <mat-tab label="#2" *ngIf="IsSecondTab">
         <form (ngSubmit)="PresentingProbfrm2.form.valid " id="PresentingProbfrm2" autocomplete="off" #PresentingProbfrm2="ngForm"
        novalidate>
        <div class="col-md-12 mt-2">
            <div class="row">
                <label  class="treat-label">Presenting Problem Description/ Target Symptom </label>
                <div  style="padding-left: 10px;">       
                    <a  
            title="Add update"
            (click)="showCommentsPopupFun(5,2)"
            >
            <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a          title="view updates"
            (click)="ViewComments(5,2)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div>
                <div class="col-md-12 p-0">
                    <!-- <mat-form-field class="w-100 custom-textarea mt-2">
                        
                        <textarea matInput maxlength="3000"
                        [(ngModel)]="PresentinProblemsInfo2.targetSymptom" #targetSymptom="ngModel" name="targetSymptom"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm2.submitted && targetSymptom.invalid }"
                        required ></textarea> -->
                        <app-word-counter-textarea [text]="PresentinProblemsInfo2.targetSymptom" [maxlength]="3000"  [(ngModel)]="PresentinProblemsInfo2.targetSymptom" 
                        (textChange)="PresentinProblemsInfo2.targetSymptom = $event" #targetSymptom="ngModel" name="targetSymptom" ngDefaultControl  required></app-word-counter-textarea>
    
                        <mat-error *ngIf="PresentingProbfrm2.submitted && PresentinProblemsInfo2.targetSymptom == ''">
                            <mat-error class="text-xs" *ngIf="PresentinProblemsInfo2.targetSymptom== ''">
                                Presenting Problem Description/ Target Symptom is required</mat-error>
                        </mat-error>
                      <!-- </mat-form-field> -->
                    
                </div>
            </div>
            <div class="row">
                <label  class="treat-label">Long-Term Goal</label>
                <div  style="padding-left: 10px;">       
                    <a   
            title="Add update"
            (click)="showCommentsPopupFun(6,2)"
            >
            <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a  
            title="view updates"
            (click)="ViewComments(6,2)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div>
                <div class="col-md-12 p-0">
                    <!-- <mat-form-field class="w-100 custom-textarea mt-2">
                        
                        <textarea matInput maxlength="3000"
                        [(ngModel)]="PresentinProblemsInfo2.longTermGoal" #longTermGoal="ngModel" name="longTermGoal"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm2.submitted && longTermGoal.invalid }"
                        required></textarea> -->
    
                        <app-word-counter-textarea [text]="PresentinProblemsInfo2.longTermGoal" [maxlength]="3000"  [(ngModel)]="PresentinProblemsInfo2.longTermGoal" 
                        (textChange)="PresentinProblemsInfo2.longTermGoal = $event" #longTermGoal="ngModel" name="longTermGoal" ngDefaultControl  required></app-word-counter-textarea>
                        <mat-error *ngIf="PresentingProbfrm2.submitted && PresentinProblemsInfo2.longTermGoal == ''">
                            <mat-error class="text-xs" *ngIf="PresentinProblemsInfo2.longTermGoal== ''">
                                Long-Term Goal is required</mat-error>
                        </mat-error>
                      <!-- </mat-form-field> -->
                </div>
            </div>
            <div class="row">
                <label  class="treat-label">Interventions</label>
                <!-- <div  style="padding-left: 10px;">       
                    <a  
            title="Add update"
            (click)="showCommentsPopupFun(7,2)"
            >
               <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a  
            title="view updates"
            (click)="ViewComments(7,2)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div> -->
                <div class="col-md-12 p-0">
                    <!-- <mat-form-field class="w-100 custom-textarea mt-2">
                        
                        <textarea matInput maxlength="3000"
                        [(ngModel)]="PresentinProblemsInfo2.interventions" #interventions="ngModel" name="interventions"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm2.submitted && interventions.invalid }"
                        required></textarea> -->
                        <app-word-counter-textarea [text]="PresentinProblemsInfo2.interventions" [maxlength]="3000"  [(ngModel)]="PresentinProblemsInfo2.interventions" 
                        (textChange)="PresentinProblemsInfo2.interventions = $event" #interventions="ngModel" name="interventions" ngDefaultControl  required></app-word-counter-textarea>
    
                        <mat-error *ngIf="PresentingProbfrm2.submitted && PresentinProblemsInfo2.interventions == ''">
                            <mat-error class="text-xs" *ngIf="PresentinProblemsInfo2.interventions== ''">
                                Interventions is required</mat-error>
                        </mat-error>
                      <!-- </mat-form-field> -->
                </div>
            </div>
            <div class="row">
                <label  class="treat-label">Responsible Staff/ Individuals/ Agencies</label>
                <!-- <div   style="padding-left: 10px;">       
                    <a 
            title="Add update"
            (click)="showCommentsPopupFun(8,2)"
            >
               <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a  title="View"
            (click)="ViewComments(8,2)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div> -->
        </div>
        <div class="row">
                <div class="col-md-6 p-0">
                    <mat-form-field class="w-100  mt-2">
                        
                        <input matInput maxlength="300" [(ngModel)]="PresentinProblemsInfo2.rsponsibleStaff" #rsponsibleStaff="ngModel" name="rsponsibleStaff"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm2.submitted && rsponsibleStaff.invalid }"
                        required>
                      </mat-form-field>
                      <mat-error *ngIf="PresentingProbfrm2.submitted && PresentinProblemsInfo2.rsponsibleStaff == ''">
                        <mat-error class="text-xs" *ngIf="PresentinProblemsInfo2.rsponsibleStaff== ''">
                            Responsible Staff/ Individuals/ Agencies is required</mat-error>
                    </mat-error>
                </div>
            </div>
        </div>
       
      
        <div class="col-md-12 ">
            <div class="row">
                <h2  class="prsenting-tab mt-3">Measurable Objectives/ Short-Term Goals</h2>
                <div class="col-md-12 treat-tab text-center mt-3"  *ngIf="!isExistTab2"> No goals available </div>
                <div class="prsenting-tab mt-3" *ngIf="isExistTab2">
               <div class="goal-percen mb-2 w-100" *ngFor="let static2 of PresentinProblemsInfo2.staticGoals;let i=index">
                <div class="row">
                    <div  class="col-md-9 custom-check bennew_tit">
                        <mat-checkbox class="example-margin"  [(ngModel)]="static2.isActive" #isActive="ngModel" name="isActive{{i}}" (change)="OnGoalSelectionChange(2)">{{static2.goalName}}</mat-checkbox>
                    </div>
                    <div  class="col-md-1 mb-3">       
                        <a   class="btn btn-sm"
                title="Add update"
                (click)="showCommentsPopupForGoals(static2.shortTermGoalId,2,static2.shortTermGoalsMasterId,static2.goalId)" 
                >
                   <img src="../../../assets/images/editblue.png">
                </a>
                </div>
                      <div   class="col-md-1 mb-3">       
                        <a   class="btn btn-sm"
                title="View"
                (click)="ViewCommentsPopupForGoals(static2.shortTermGoalId,static2.shortTermGoalsMasterId,static2.goalId)"
                >
               <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
                </a>
                </div>
                        <div class="col-md-1 mb-3">
                            <div class="ml-auto">
                                <mat-form-field class="w-100">
                                    <mat-label>Move to</mat-label>
                                    <mat-select [(ngModel)]="currentTab" #currentTab1="ngModel" name="currentTab1{{i}}"  id="currentTab1{{i}}"
                                    (selectionChange)="onSelectionChanged(static2,2,PresentinProblemsInfo2)">
                                        <mat-option value="1" [disabled]="selectedTab == 1"> 1 </mat-option>
                                        <mat-option value="2" [disabled]="selectedTab == 2" *ngIf=" IsSecondTab"> 2 </mat-option>
                                        <mat-option value="3" [disabled]="selectedTab == 3" *ngIf=" IsThiredTab"> 3 </mat-option>
                                    </mat-select>                                                
                            </mat-form-field>
                                  
                            </div>
                        </div>
                    </div>
                <div  class="">
                    <div class="d-flex">
                        <label  class="mr-2  mt-3 bennew_tit ">Description</label>
                        <div class="ml-auto">
                            <mat-form-field class="w-100 mt--14">
                                <mat-label>Target Date</mat-label>
                                <input matInput [matDatepicker]="pickerPT" [(ngModel)]="static2.targetDate" #targetDate="ngModel" name="targetDate{{i}}" 
                                [required]="static2.isActive" >
                                <mat-datepicker-toggle matSuffix [for]="pickerPT"></mat-datepicker-toggle>
                                <mat-datepicker #pickerPT></mat-datepicker>
                              </mat-form-field>
                              <mat-error *ngIf="PresentingProbfrm2.submitted && targetDate.invalid">
                                <mat-error class="text-xs" *ngIf="targetDate.invalid">
                                    Target Date is required</mat-error>
                            </mat-error>
                        </div>
                    </div>
                   
                    <div class="w-100">
                        <textarea class="form-control" rows="5" maxlength="3000" [(ngModel)]="static2.goalDescription" #goalDescription="ngModel" name="goalDescription{{i}}" [ngClass]="{ 'is-invalid': PresentingProbfrm2.submitted && goalDescription.invalid }"
                        [required]="static2.isActive"></textarea>
                        <mat-error *ngIf="PresentingProbfrm2.submitted && goalDescription.invalid">
                            <mat-error class="text-xs"  *ngIf="goalDescription.invalid">
                                Description is required</mat-error>
                        </mat-error>
                    </div>
                </div>
                
                <div  class="goal-desc-expand mt-2">
                    <div  class="col-md-12 ben-head position-relative">
                        <div  class="row">
                            <div  class="col-md-12 d-flex">
                                <div  class=" bennew_tit "> Benchmark  </div>
                                <div class="ml-auto"><div  class="col-md-12 form-group p-0">
                                    <mat-form-field class="w-100">
                                        <mat-label>Measure Type</mat-label>
                                        <mat-select [(ngModel)]="static2.measureTypeId" #measureTypeId="ngModel" name="measureTypeId{{i}}" 
                                        [required]="static2.isActive" >
                                          <mat-option [value]="5"> Percentage based </mat-option>
                                          <mat-option [value]="6"> Time based </mat-option>
                                          <mat-option [value]="7"  *ngIf="static2.goalId !=0"> Number of exchanges based </mat-option>
                                          <mat-option [value]="8"  *ngIf="static2.goalId !=0"> Number of initiation per activity based </mat-option>
                                          <mat-option [value]="9"  *ngIf="static2.goalId !=0"> Number of responses based </mat-option>
                                          <mat-option [value]="10" *ngIf="static2.goalId !=0"> Others </mat-option>
                                        </mat-select>                                                
                                </mat-form-field>
                                <mat-error *ngIf="PresentingProbfrm2.submitted &&  static2.measureTypeId=='' && static2.isActive ">
                                    <mat-error class="text-xs"  *ngIf=" static2.measureTypeId=='' ">
                                        Measure Type is required</mat-error>
                                </mat-error>
                                    </div></div>
                            </div>
                            
                            </div>
                            <div *ngFor="let com of static2.measures;let j=index">
                            <div  class="row" >
                            <div  class="col-md-3">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Benchmark Title</mat-label>
                                        <input matInput maxlength="100" [(ngModel)]="com.benchmarktitle"
                                        #benchmarktitle="ngModel" name="benchmarktitle{{j}}{{i}}"
                                      
                                        [required]="static2.isActive">
                                     
                                      <mat-error *ngIf="PresentingProbfrm2.submitted && benchmarktitle.invalid">
                                        <mat-error class="text-xs"  *ngIf="benchmarktitle.invalid">
                                            Benchmark Title is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            <div  class="col-md-6">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Benchmark Description</mat-label>
                                        <input matInput maxlength="3000" [(ngModel)]="com.benchmarkdiscription"
                                        #benchmarkdiscription="ngModel" name="benchmarkdiscription{{j}}{{i}}"
                                       
                                        [required]="static2.isActive">
                                      
                                      <mat-error *ngIf="PresentingProbfrm2.submitted && benchmarkdiscription.invalid">
                                        <mat-error class="text-xs"  *ngIf="benchmarkdiscription.invalid">
                                            Benchmark Decription is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            
                            <div  class="col-md-2 d-flex">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Unit of Measure</mat-label>
                                        <input matInput [(ngModel)]="com.benchmarkunit"
                                        #benchmarkunit="ngModel" name="benchmarkunit{{j}}{{i}}"
                                       
                                        [required]="static2.isActive" step="0" maxlength="4" percentage customMin="1">
                                      
                                      <mat-error *ngIf="PresentingProbfrm2.submitted && benchmarkunit.invalid">
                                        <mat-error class="text-xs"  *ngIf="benchmarkunit.invalid">
                                            Unit of Measure is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                                <span  *ngIf="static2.measureTypeId == PercentageBased" class="d-inline-block mt-3 pl-2">%</span>
                                <span *ngIf="static2.measureTypeId == Timebased" class="d-inline-block mt-3 pl-2">min</span>
                            </div>
                                 
                            <div  class="col-md-1">
                                <button (click)="addMeasures(static2,2)" type="button" class="btn btn-primary list_add_button btn-theme mr-1">+</button>
                                <button  *ngIf="j > 0" (click)="removeMeasures(j,static2,2)" type="button" class="btn btn-danger list_add_button ">-</button>
                            </div>
                                    
                            </div>
                        </div>
                            </div>
                            </div>
                           
               </div> 
            </div>
            </div>
            </div>
           
            <!-- <div class="col-md-12 ">
                <div class="row">
                <div class="w-100 mt-3" *ngIf="PresentinProblemsInfo2.presentingProblemId !=0 ">
               <div class="goal-percen mb-2 w-100" *ngFor="let static2 of PresentinProblemsInfo2.staticGoals;let i=index">
                <div>
                <div  class="d-flex">
                    <div  class="goal-name">
                        <label  class="mr-2 fw-14">Goal:</label>
                        <span  class="value-goal fw-14" >{{static2.goalName}}</span>
                    </div>
                    <div  class="ml-auto">
                        <button  class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(static2)">Add Benchmark</button>
                    </div>
                </div>
                    <div class="d-flex goal-name">
                        <label  class="mr-2  mt-3 fw-14">Description</label>
                        <div class="ml-auto mt-3">
                            <div class="goal-name">
                                <label  class="mr-2 fw-14">Target Date:</label>
                                <span  class="value-goal fw-14">{{static2.targetDate | date:'MM/dd/YYYY'}}</span>
                            </div>
                        </div>
                    </div>
                   
                    <div class="w-100">
                        <div  class="value-goal"> {{static2.goalDescription}} </div>
                    </div>
                </div>
                <div class="options-goal mt-2" >
                    <mat-radio-group aria-label="Select an option"  [(ngModel)]="static2.selectedTrackbenchId"  name="selectedTrackbenchId3{{i}}">
                        <div   *ngFor="let com of static2.measures;let j=index">
                        <div class="d-flex w-100 ">
                            
                            <mat-radio-button class="w-100"   [value]="com.goalBenchmarkId"   (click)="OnselectTrackbenchmark(com,static2)" >{{com.benchmarktitle}} </mat-radio-button>
                            <div class="ml-auto">
                        </div>
                            </div>
                            <div class="goal-desc-expand" *ngIf="com.trackBenchIsSelected">
                            <div class="col-md-12 ben-head position-relative">
                                <div  class="row">
                                    <div  class="col-md-8 ben_des">
                                        <div  class="ben_tit"> Benchmark Description </div>
                                       {{com.benchmarkdiscription}} </div>
                                        <div class="col-md-2">
                                            <div class="goal-stat-chang mt-3">
                                                <span class="status-tabl inprogress" *ngIf="com.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                <span class="status-tabl completed" *ngIf="com.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                            </div>
                                        </div>
                                         <div  class="col-md-2 ben_unit pr-4">
                                             <p  class="text-muted text-xs">Unit of Measure</p> {{com.benchmarkunit}} </div>
                                             <div class="edit-float" >
                                                <a><i class="fas fa-edit" (click)="OnEditBenchmark(com,static2)"></i></a>
    
                                             </div>
                                             <div  class="delete-float" *ngIf="com.status==1">
                                                <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(com.trackBenchmarkId)"></i></a></div>
                                            </div>
                                        </div>
                        </div>
                    </div>
                     
                      </mat-radio-group>
                </div>
                
                           
               </div> 
               </div>
               </div>
            
            <div class="row">
                <div class="col-md-12 p-0">
                   
                    <div class="row clearfix">
                       
                       
                         
                        <div  class="col-md-12">
                            <div class="form-group clearfix m-0">
                               
                                        </div>
                                    </div>
    
                         
                    </div>
                </div>
                
                
            </div>
            
        </div>  -->
        <div class="col-md-12">
            <div class="text-right" *ngIf="(PresentinProblemsInfo2.presentingProblemId ==0 || PresentinProblemsInfo2.isnext == false) && (roleAccess.isCreate||roleAccess.isEdit)">
                <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button *ngIf="Sym1GoalCount==SelectedGoals" (click)="OnPostPresentingProblem(2,0)">Save</button>
                <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button *ngIf="Sym1GoalCount!==SelectedGoals" (click)="OnPostPresentingProblem(2,3)">Save & Next</button>
                <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" (click)="OnCancel()" mat-stroked-button>Cancel</button>
            </div>
            <div class="text-right" *ngIf="PresentinProblemsInfo2.presentingProblemId !=0 && PresentinProblemsInfo2.isnext == true  && (roleAccess.isCreate||roleAccess.isEdit)">
                <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="OnPostPresentingProblem(2,1)">Update</button>
            </div>
        </div>
    </form> 
    </mat-tab>
    <mat-tab label="#3" *ngIf="IsThiredTab">
        <form (ngSubmit)="PresentingProbfrm3.form.valid " id="PresentingProbfrm3" autocomplete="off" #PresentingProbfrm3="ngForm"
        novalidate>
        <div class="col-md-12 mt-2">
            <div class="row">
                <label  class="treat-label">Presenting Problem Description/ Target Symptom </label>
                <div  style="padding-left: 10px;">       
                    <a  
            title="Add update"
            (click)="showCommentsPopupFun(5,3)"
            >
               <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a          title="view updates"
            (click)="ViewComments(5,3)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div>
                <div class="col-md-12 p-0">
                    <!-- <mat-form-field class="w-100 custom-textarea mt-2">
                        
                        <textarea matInput maxlength="3000"
                        [(ngModel)]="PresentinProblemsInfo3.targetSymptom" #targetSymptom="ngModel" name="targetSymptom"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm3.submitted && targetSymptom.invalid }"
                        required ></textarea> -->
                        <app-word-counter-textarea [text]="PresentinProblemsInfo3.targetSymptom" [maxlength]="3000"  [(ngModel)]="PresentinProblemsInfo3.targetSymptom" 
                        (textChange)="PresentinProblemsInfo3.targetSymptom = $event" #targetSymptom="ngModel" name="targetSymptom" ngDefaultControl  required></app-word-counter-textarea>
                        <mat-error *ngIf="PresentingProbfrm3.submitted && PresentinProblemsInfo3.targetSymptom == ''">
                            <mat-error class="text-xs" *ngIf="PresentinProblemsInfo3.targetSymptom== ''">
                                Presenting Problem Description/ Target Symptom  is required</mat-error>
                        </mat-error>
                      <!-- </mat-form-field> -->
                    
                </div>
            </div>
            <div class="row">
                <label  class="treat-label">Long-Term Goal</label>
                <div  style="padding-left: 10px;">       
                    <a   
            title="Add update"
            (click)="showCommentsPopupFun(6,3)"
            >
               <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a  
            title="view updates"
            (click)="ViewComments(6,3)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div>
                <div class="col-md-12 p-0">
                    <!-- <mat-form-field class="w-100 custom-textarea mt-2">
                        
                        <textarea matInput maxlength="3000"
                        [(ngModel)]="PresentinProblemsInfo3.longTermGoal" #longTermGoal="ngModel" name="longTermGoal"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm3.submitted && longTermGoal.invalid }"
                        required></textarea> -->
                        <app-word-counter-textarea [text]="PresentinProblemsInfo3.longTermGoal" [maxlength]="3000"  [(ngModel)]="PresentinProblemsInfo3.longTermGoal" 
                        (textChange)="PresentinProblemsInfo3.longTermGoal = $event" #longTermGoal="ngModel" name="longTermGoal" ngDefaultControl  required></app-word-counter-textarea>
                        <mat-error *ngIf="PresentingProbfrm3.submitted && PresentinProblemsInfo3.longTermGoal == ''">
                            <mat-error class="text-xs" *ngIf="PresentinProblemsInfo3.longTermGoal== ''">
                                Long-Term Goal is required</mat-error>
                        </mat-error>
                      <!-- </mat-form-field> -->
                </div>
            </div>
            <div class="row">
                <label  class="treat-label">Interventions</label>
                <!-- <div  style="padding-left: 10px;">       
                    <a  
            title="Add update"
            (click)="showCommentsPopupFun(7,3)"
            >
               <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a  
            title="view updates"
            (click)="ViewComments(7,3)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div> -->
                <div class="col-md-12 p-0">
                    <!-- <mat-form-field class="w-100 custom-textarea mt-2">
                        
                        <textarea matInput maxlength="3000"
                        [(ngModel)]="PresentinProblemsInfo3.interventions" #interventions="ngModel" name="interventions"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm3.submitted && interventions.invalid }"
                        required></textarea> -->
                        <app-word-counter-textarea [text]="PresentinProblemsInfo3.interventions" [maxlength]="3000"  [(ngModel)]="PresentinProblemsInfo3.interventions" 
                        (textChange)="PresentinProblemsInfo3.interventions = $event" #interventions="ngModel" name="interventions" ngDefaultControl  required></app-word-counter-textarea>
                        <mat-error *ngIf="PresentingProbfrm3.submitted && PresentinProblemsInfo3.interventions == ''">
                            <mat-error class="text-xs" *ngIf="PresentinProblemsInfo3.interventions== ''">
                                Interventions is required</mat-error>
                        </mat-error>
                      <!-- </mat-form-field> -->
                </div>
            </div>
            <div class="row">
                <label  class="treat-label">Responsible Staff/ Individuals/ Agencies</label>
                <!-- <div   style="padding-left: 10px;">       
                    <a 
            title="Add update"
            (click)="showCommentsPopupFun(8,3)"
            >
               <img src="../../../assets/images/editblue.png">
            </a>
            </div>
                  <div  style="padding-left: 10px;">       
                    <a  title="View"
            (click)="ViewComments(8,3)"
            >
           <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
            </a>
            </div> -->
        </div>
        <div class="row">
                <div class="col-md-6 p-0">
                    <mat-form-field class="w-100  mt-2">
                        
                        <input matInput maxlength="300" [(ngModel)]="PresentinProblemsInfo3.rsponsibleStaff" #rsponsibleStaff="ngModel" name="rsponsibleStaff"
                        [ngClass]="{ 'is-invalid': PresentingProbfrm3.submitted && rsponsibleStaff.invalid }"
                        required>
                      </mat-form-field>
                      <mat-error *ngIf="PresentingProbfrm3.submitted && PresentinProblemsInfo3.rsponsibleStaff == ''">
                        <mat-error class="text-xs" *ngIf="PresentinProblemsInfo3.rsponsibleStaff== ''">
                            Responsible Staff/ Individuals/ Agencies is required</mat-error>
                    </mat-error>
                </div>
            </div>
        </div>
       
      
        <div class="col-md-12 ">
            <div class="row">
                <h2  class="prsenting-tab mt-3">Measurable Objectives/ Short-Term Goals</h2>
                <div class="col-md-12 treat-tab text-center mt-3"  *ngIf="!isExistTab3"> No goals available </div>
                <div class="prsenting-tab mt-3" *ngIf="isExistTab3">
               <div class="goal-percen mb-2 w-100" *ngFor="let static3 of PresentinProblemsInfo3.staticGoals;let i=index">
                <div class="row">
                    <div  class="col-md-9 custom-check bennew_tit">
                        <mat-checkbox class="example-margin"  [(ngModel)]="static3.isActive" #isActive="ngModel" name="isActive{{i}}" (change)="OnGoalSelectionChange(3)">{{static3.goalName}}</mat-checkbox>
                    </div>
                    <div  class="col-md-1 mb-3">       
                        <a   class="btn btn-sm"
                title="Add update"
                (click)="showCommentsPopupForGoals(static3.shortTermGoalId,3,static3.shortTermGoalsMasterId,static3.goalId)" 
                >
                   <img src="../../../assets/images/editblue.png">
                </a>
                </div>
                      <div   class="col-md-1 mb-3">       
                        <a   class="btn btn-sm"
                title="View"
                (click)="ViewCommentsPopupForGoals(static3.shortTermGoalId,static3.shortTermGoalsMasterId,static3.goalId)"
                >
               <i class="fas fa-history mr-2"  style="color:rgb(10, 10, 133); font-size: 20px; vertical-align: middle;"></i>
                </a>
                </div>
                        <div class="col-md-1 mb-3">
                            <div class="ml-auto">
                                <mat-form-field class="w-100">
                                    <mat-label>Move to</mat-label>
                                    <mat-select [(ngModel)]="currentTab" #currentTab1="ngModel" name="currentTab1{{i}}"  id="currentTab1{{i}}"
                                    (selectionChange)="onSelectionChanged(static3,3,PresentinProblemsInfo3)">
                                    <mat-option value="1" [disabled]="selectedTab == 1"> 1 </mat-option>
                                    <mat-option value="2" [disabled]="selectedTab == 2" *ngIf=" IsSecondTab"> 2 </mat-option>
                                    <mat-option value="3" [disabled]="selectedTab == 3" *ngIf=" IsThiredTab"> 3 </mat-option>
                                    </mat-select>                                                
                            </mat-form-field>
                                  
                            </div>
                        </div>
                    </div>
                <div  class="">
                    <div class="d-flex">
                        <label  class="mr-2  mt-3 bennew_tit ">Description</label>
                        <div class="ml-auto">
                            <mat-form-field class="w-100 mt--14">
                                <mat-label>Target Date</mat-label>
                                <input matInput [matDatepicker]="pickerPT" [(ngModel)]="static3.targetDate" #targetDate="ngModel" name="targetDate{{i}}" 
                                [required]="static3.isActive" >
                                <mat-datepicker-toggle matSuffix [for]="pickerPT"></mat-datepicker-toggle>
                                <mat-datepicker #pickerPT></mat-datepicker>
                              </mat-form-field>
                              <mat-error *ngIf="PresentingProbfrm3.submitted && targetDate.invalid">
                                <mat-error class="text-xs" *ngIf="targetDate.invalid">
                                    Target Date is required</mat-error>
                            </mat-error>
                        </div>
                    </div>
                   
                    <div class="w-100">
                        <textarea class="form-control" rows="5" maxlength="3000" [(ngModel)]="static3.goalDescription" #goalDescription="ngModel" name="goalDescription{{i}}" [ngClass]="{ 'is-invalid': PresentingProbfrm3.submitted && goalDescription.invalid }"
                        [required]="static3.isActive"></textarea>
                        <mat-error *ngIf="PresentingProbfrm3.submitted && goalDescription.invalid">
                            <mat-error class="text-xs"  *ngIf="goalDescription.invalid">
                                Description is required</mat-error>
                        </mat-error>
                    </div>
                </div>
                
                <div  class="goal-desc-expand mt-2">
                    <div  class="col-md-12 ben-head position-relative">
                        <div  class="row">
                            <div  class="col-md-12 d-flex">
                                <div  class=" bennew_tit "> Benchmark  </div>
                                <div class="ml-auto"><div  class="col-md-12 form-group p-0">
                                    <mat-form-field class="w-100">
                                        <mat-label>Measure Type</mat-label>
                                        <mat-select [(ngModel)]="static3.measureTypeId" #measureTypeId="ngModel" name="measureTypeId{{i}}" 
                                        [required]="static3.isActive">
                                          <mat-option [value]="5"> Percentage based </mat-option>
                                          <mat-option [value]="6"> Time based </mat-option>
                                          <mat-option [value]="7"  *ngIf="static3.goalId !=0"> Number of exchanges based </mat-option>
                                          <mat-option [value]="8"  *ngIf="static3.goalId !=0"> Number of initiation per activity based </mat-option>
                                          <mat-option [value]="9"  *ngIf="static3.goalId !=0"> Number of responses based </mat-option>
                                          <mat-option [value]="10" *ngIf="static3.goalId !=0"> Others </mat-option>
                                        </mat-select>
                                </mat-form-field>
                                <mat-error *ngIf="PresentingProbfrm3.submitted &&  static3.measureTypeId=='' && static3.isActive ">
                                    <mat-error class="text-xs"  *ngIf=" static3.measureTypeId=='' ">
                                        Measure Type is required</mat-error>
                                </mat-error>
                                    </div></div>
                            </div>
                            
                            </div>
                            <div *ngFor="let com of static3.measures;let j=index">
                            <div  class="row" >
                            <div  class="col-md-3">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Benchmark Title</mat-label>
                                        <input matInput maxlength="100" [(ngModel)]="com.benchmarktitle"
                                        #benchmarktitle="ngModel" name="benchmarktitle{{j}}{{i}}"
                                       
                                        [required]="static3.isActive">
                                        <mat-error *ngIf="PresentingProbfrm3.submitted && benchmarktitle.invalid">
                                            <mat-error class="text-xs"  *ngIf="benchmarktitle.invalid">
                                                Benchmark Title is required</mat-error>
                                        </mat-error>
                                      </mat-form-field>
                                     
                                </div>
                            </div>
                            <div  class="col-md-6">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Benchmark Description</mat-label>
                                        <input matInput maxlength="3000" [(ngModel)]="com.benchmarkdiscription"
                                        #benchmarkdiscription="ngModel" name="benchmarkdiscription{{j}}{{i}}"
                                       
                                        [required]="static3.isActive">
                                      
                                      <mat-error *ngIf="PresentingProbfrm3.submitted && benchmarkdiscription.invalid">
                                        <mat-error class="text-xs"  *ngIf="benchmarkdiscription.invalid">
                                            Benchmark Decription is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                            </div>
                            
                            <div  class="col-md-2 d-flex">
                                <div class="form-group">
                                    <mat-form-field class="w-100">
                                        <mat-label>Unit of Measure</mat-label>
                                        <input matInput [(ngModel)]="com.benchmarkunit"
                                        #benchmarkunit="ngModel" name="benchmarkunit{{j}}{{i}}"
                                       
                                        [required]="static3.isActive" step="0" maxlength="4" percentage customMin="1">
                                      
                                      <mat-error *ngIf="PresentingProbfrm3.submitted && benchmarkunit.invalid">
                                        <mat-error class="text-xs"  *ngIf="benchmarkunit.invalid">
                                            Unit of Measure is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                                </div>
                                <span class="d-inline-block mt-3 pl-2"  *ngIf="static3.measureTypeId == PercentageBased">%</span>
                                <span class="d-inline-block mt-3 pl-2" *ngIf="static3.measureTypeId == Timebased">min</span>
                            </div>
                                 
                            <div  class="col-md-1">
                                <button (click)="addMeasures(static3,3)" type="button" class="btn btn-primary list_add_button btn-theme mr-1">+</button>
                                <button  *ngIf="j > 0" (click)="removeMeasures(j,static3,3)" type="button" class="btn btn-danger list_add_button ">-</button>
                            </div>
                                    
                            </div>
                        </div>
                            </div>
                            </div>
                           
               </div> 
            </div>
          
            </div>
            </div>
           
            <!-- <div class="col-md-12 ">
                <div class="row">
                <div class="w-100 mt-3" *ngIf="PresentinProblemsInfo3.presentingProblemId !=0 ">
               <div class="goal-percen mb-2 w-100" *ngFor="let static3 of PresentinProblemsInfo3.staticGoals;let i=index">
                <div>
                <div  class="d-flex">
                    <div  class="goal-name">
                        <label  class="mr-2 fw-14">Goal:</label>
                        <span  class="value-goal fw-14" >{{static3.goalName}}</span>
                    </div>
                    <div  class="ml-auto">
                        <button  class="btn primary-btn-outlined-small" (click)="OnAddBenchMark(static3)">Add Benchmark</button>
                    </div>
                </div>
                    <div class="d-flex goal-name">
                        <label  class="mr-2  mt-3 fw-14">Description</label>
                        <div class="ml-auto mt-3">
                            <div class="goal-name">
                                <label  class="mr-2 fw-14">Target Date:</label>
                                <span  class="value-goal fw-14">{{static3.targetDate | date:'MM/dd/YYYY'}}</span>
                            </div>
                        </div>
                    </div>
                   
                    <div class="w-100">
                        <div  class="value-goal"> {{static3.goalDescription}} </div>
                    </div>
                </div>
                <div class="options-goal mt-2" >
                    <mat-radio-group aria-label="Select an option"  [(ngModel)]="static3.selectedTrackbenchId"  name="selectedTrackbenchId1{{i}}">
                        <div   *ngFor="let com of static3.measures;let j=index">
                        <div class="d-flex w-100 ">
                            
                            <mat-radio-button class="w-100"   [value]="com.goalBenchmarkId"   (click)="OnselectTrackbenchmark(com,static3)" >{{com.benchmarktitle}} </mat-radio-button>
                            <div class="ml-auto">
                        </div>
                            </div>
                            <div class="goal-desc-expand" *ngIf="com.trackBenchIsSelected">
                            <div class="col-md-12 ben-head position-relative">
                                <div  class="row">
                                    <div  class="col-md-8 ben_des">
                                        <div  class="ben_tit"> Benchmark Description </div>
                                       {{com.benchmarkdiscription}} </div>
                                        <div class="col-md-2">
                                            <div class="goal-stat-chang mt-3">
                                                <span class="status-tabl inprogress" *ngIf="com.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                                                <span class="status-tabl completed" *ngIf="com.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                                            </div>
                                        </div>
                                         <div  class="col-md-2 ben_unit pr-4">
                                             <p  class="text-muted text-xs">Unit of Measure</p> {{com.benchmarkunit}} </div>
                                             <div class="edit-float" >
                                                <a><i class="fas fa-edit" (click)="OnEditBenchmark(com,static3)"></i></a>
    
                                             </div>
                                             <div  class="delete-float"  *ngIf="com.status==1">
                                                <a><i class="fas fa-trash" (click)="OnDeleteBenchmark(com.trackBenchmarkId)"></i></a></div>
                                            </div>
                                        </div>
                        </div>
                    </div>
                     
                      </mat-radio-group>
                </div>
                
                           
               </div> 
               </div>
               </div>
            
            <div class="row">
                <div class="col-md-12 p-0">
                   
                    <div class="row clearfix">
                       
                       
                         
                        <div  class="col-md-12">
                            <div class="form-group clearfix m-0">
                               
                                  
                                        </div>
                                    </div>
    
                         
                    </div>
                </div>
                
                
            </div>
            
        </div>  -->
        <div class="col-md-12">
            <div class="text-right" *ngIf="(PresentinProblemsInfo3.presentingProblemId ==0 || PresentinProblemsInfo3.isnext == false) && (roleAccess.isCreate||roleAccess.isEdit)">
                <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="OnPostPresentingProblem(3,0)">Save</button>
                <!-- <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button *ngIf="Sym1GoalCount!==SelectedGoals" (click)="OnPostPresentingProblem(2,3)">Save & Next</button> -->
                <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" (click)="OnCancel()" mat-stroked-button>Cancel</button>
            </div>
            <div class="text-right" *ngIf="PresentinProblemsInfo3.presentingProblemId !=0 && PresentinProblemsInfo3.isnext == true && (roleAccess.isCreate||roleAccess.isEdit)">
                <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="OnPostPresentingProblem(3,0)">Update</button>
            </div>
        </div>
    </form>   
    </mat-tab>
    </mat-tab-group>
    </div>
    <div *ngIf="EditBenchmark">
        <app-add-edit-bench-pop-up [Benchmarkfrminfo]="Benchmarkfrminfo" [status]="status" [Otherbenchmarks]="Otherbenchmarks"></app-add-edit-bench-pop-up>
    </div>
    </div>
    </div>
    <!-- Recommdations PopUp Starts -->
    <div class="comment-popup re-schedule-popup Treatment-popup ">
        <div class="modal" tabindex="-1" role="dialog" id="blockTime" [style.display]="showRecommandationPopup? 'block' : 'none'">
          <div class="modal-dialog mat-dialog-container" role="document">
            <div class="modal-content border-0">
              <div class="modal-header">
                <h6 class="modal-title" >{{ title }}</h6>
                <a class="cursor-pointer" (click)="OncloseRecommandationpopup()" ><i
                  class="fa fa-times"></i></a>
              </div>
              <div class="modal-body">
                <div class="row" *ngIf="_dtoComments.length > 0">
                  <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-1 font-weight-bold"><label>S.No</label></div>
                        <div class="col-md-7 font-weight-bold"><label>Updates</label></div>
                        <div class="col-md-2 font-weight-bold"><label>Date</label></div>
                        <div class="col-md-2 font-weight-bold"><label>Action</label></div>
                    </div>

                    <div *ngFor="let med of _dtoComments; let i = index; let last = last" class="row"
                        style="margin-bottom: 10px;">
                        <div class="col-md-1">
                            <label>{{ i + 1 }}</label>
                        </div>
                        <div class="col-md-7">
                            {{ med.commentDetails }}
                        </div>
                        <div class="col-md-2">
                            <!-- {{ med.updatedDate | date: 'MM/dd/yyyy' }} -->
                            {{ med.createdDate | date: 'MM/dd/yyyy' }}

                        </div>
                        <div class="col-md-2">
                            <div>
                                <button type="button" mat-icon-button matTooltip="Edit" color="primary" (click)="editCommentPopup(med.treatmentPlanCommentsId)">
                                    <img src="../../../assets/images/editblue.png">
                                </button>
                                <button mat-icon-button matTooltip="Delete" color="warn" (click)="ondeleteTreatmentPlanComments(med.treatmentPlanCommentsId)">
                                    <img src="../../../assets/images/deletered.png">
                                </button>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
                <div class="col-md-12 set1-temp" *ngIf="_dtoComments.length === 0">
                  <div class="col-md-12 row">
                    <div class="col-md-4 p-0">
                      <label class="additional"></label>
                    </div>
                    <div class="col-md-4 p-0">
                      <label class="additional">{{NoRecord}}</label>
                      <div class="col-md-4 p-0">
                      <label class="additional"></label>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    <!--Comments PopUp Starts-->
    <div class="comment-popup re-schedule-popup Treatment-popup ">
        <div class="modal" tabindex="-1" role="dialog" id="blockTime" [style.display]="showCommentsPopup? 'block' : 'none'">
          <div class="modal-dialog mat-dialog-container" role="document">
            <div class="modal-content border-0">
              <div class="modal-header">
                <h6 class="modal-title" >{{ Addtitle }}</h6>
                <a class="cursor-pointer" (click)="OncloseCommentpopup(notedialogfrm)" ><i
                  class="fa fa-times"></i></a>
              </div>
              <div class="modal-body">
      
                <form (ngSubmit)="notedialogfrm.form.valid  && postTreatmentPlanComments(notedialogfrm)" id="notedialogfrm" autocomplete="off" #notedialogfrm="ngForm"
                novalidate>
                <div class="col-md-12">
                  <div class="row">
                   
                    <mat-form-field class="w-100">
                        <mat-label class="">Date</mat-label>
                        <input matInput [matDatepicker]="pstpicker" readonly
                            (click)="pstpicker.open()" [(ngModel)]="_dtoTreatmetComments.commentUpdatedate"
                             name="commentUpdatedate" #commentUpdatedate="ngModel" required
                             [ngClass]="{ 'is-invalid': notedialogfrm.submitted && commentUpdatedate.invalid }"
                             >
                        <mat-datepicker-toggle matSuffix [for]="pstpicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pstpicker></mat-datepicker>
                        <mat-error *ngIf="notedialogfrm.submitted && commentUpdatedate.invalid">
                          <mat-error *ngIf="commentUpdatedate.errors.required">Date is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                      
                  <mat-form-field class="w-100">
                      <input matInput placeholder="Add Update" 
                        [(ngModel)]="_dtoTreatmetComments.commentsName" #commentsName="ngModel" name="commentsName"
                        autocomplete="new-password" [ngClass]="{ 'is-invalid': notedialogfrm.submitted && commentsName.invalid }"
                        required>
                       
                      <mat-error *ngIf="notedialogfrm.submitted && commentsName.invalid">
                        <mat-error *ngIf="commentsName.errors.required">Update is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
              </div>
          
                <div class="col-md-12">
                  <div class="row">
                      <div class="col-md-12 text-right mt-2">    
                        
                    <button type="submit" mat-raised-button  class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>
                </div>
                  </div></div>
          
          
              </form>
      
     
              </div>
            </div>
          </div>
        </div>
      </div> 
    
    
    <a mimetype="application/octet-stream" #downloadLink></a>

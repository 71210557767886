import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialService {

  constructor(private http: HttpClient, public commonService: CommonService) { }

  getPriceCatalogueByProgram(id,patientInfoId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetFinancialProposal/GetPriceCatalogueByProgram?Id=' + this.commonService.DataToEncrypt(id, true)+'&patientInfoId='+ this.commonService.DataToEncrypt(patientInfoId, true));
  }
  getPriceCatalogueDetails(id,patientInfoId ) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetPriceCatalogueDetails?Id=' + this.commonService.DataToEncrypt(id, true)+'&patientInfoId='+ this.commonService.DataToEncrypt(patientInfoId, true));

  }
  postFinancialProposal(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostFinancialProposal/PostFinancialProposal',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getComponentMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetComponentMaster');
  }
  getTourExist(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetFinancialProposal/GetTourExist?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getFinancialProposalDetails(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetFinancialProposal/GetFinancialProposalDetails?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  postChangeStatus(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostFinancialProposal/ChangeStatus',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postTourStatus(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTour/ChangeStatus',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postRequestTour(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTour/PostRequestTour',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postRequestTourNew(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTour/PostRequestTourNew',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getTourAppointmentType() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTour/GetTourAppointmentType');
  }
  // getTimeSlots(id){
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GetMeeting/GetSlotByAppointment?Id=' + this.commonService.DataToEncrypt(id, true));
  // }
  // getTimeSlots(id,day){
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GetMeeting/GetSlotByAppointment?Id=' + this.commonService.DataToEncrypt(id, true)+'&day='+this.commonService.DataToEncrypt(day, true));
  // }
  getTimeSlots(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetMeeting/GetSlotByAppointment',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getTimeSlotsForTour(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetMeeting/GetSlotByAppointmentForTour',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getAssignedSlots(id,tourid) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTour/GetAssignedSlots?Id=' + this.commonService.DataToEncrypt(id, true)+'&requestTourId='+ this.commonService.DataToEncrypt(tourid, true));

  }
  getAssignedSlotsUpdated(id,tourid) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTour/GetAssignedSlotsNew?Id=' + this.commonService.DataToEncrypt(id, true)+'&requestTourId='+ this.commonService.DataToEncrypt(tourid, true));

  }
  postAssignStaff(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTour/AssignStaff',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  AssignStaffNew(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTour/AssignStaffNew',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  cancelTour(id){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTour/CancelTour?id='+ this.commonService.DataToEncrypt(id, true), { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postRescheduleTour(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTour/RescheduleTour', data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
}

import { filter } from 'jszip';
import { map } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild,ElementRef, ChangeDetectorRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { constantVariables } from 'src/app/constant/constant';
import { ClinicalNotes, Medication } from 'src/app/_models/_Treatment/_clinicalnotes';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { NgForm } from '@angular/forms';
import * as _ from "lodash";
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { CdkAccordion } from '@angular/cdk/accordion';


@Component({
  selector: 'app-progress-track',
  templateUrl: './progress-track.component.html',
  styleUrls: ['./progress-track.component.scss']
})
export class ProgressTrackComponent implements OnInit {
  _dtoGrpTherapy: any = [];
  _dtofilteredGrpTherapy: any = [];
  _ddlGrpTherapy: any = [];
  _dtoProgressActivity: any = [];
  _dtoGrpTherapyMeta: any;
  grpTherapyId: any;
  grpTherapydetails: any;
  currentDate = new Date();
  clinicalNotes: ClinicalNotes;
  isShowgroupnote: boolean = false;
  isshowhistroy: boolean = false;
  notesType: any;
  isSocialNotes: boolean = false;
  isSkillsNotes: boolean = false;
  isDistressNotes: boolean = false;
  isInterpersonalNotes: boolean = false;
  isPurposefulMovement:boolean=false;
  isExposureTherapy:boolean=false;
  isLifeSkills:boolean=false;
  signatureImg: any;
  updatedsignatureImg: any;
  isESignAttached: boolean;
  isformtype: string;
  isfutureSign: boolean;
  dynamicimgsrcid: any = [];
  isAttachEsign: boolean;
  defaultImage: any;
  userId: any; userName: any;
  _dtoMedication: any = [];
  lstmedicine: any = [];
  colomntotal: number = 0;
  rowtotal: number = 0;
  totalcolumn: number;
  medicationhistroy: any = []
  _dtoProgressNotes:any=[];
  patientinfoid:any;
  datanotExists:boolean=false;
  Inprogress = 2;
  Completed = 3
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline',],

        //  [{ 'list': 'ordered' }, { 'list': 'bullet' }],

      ],

    },

    keyboard: {
      bindings: {


        enter: {
          key: 13,
          handler: (range, context) => {
            return true;
          }
        }
      }
    }
  }
  @ViewChild('frmProgress') frmProgress: NgForm;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  editmedication: boolean = false;
  isPreviewTherapy: boolean= false;
  pdffile: any;
  selecteddate: any;


  chmaTreatmentSummaryId:number;
  showModal:boolean=false;
  treatmentGoalList:any[];
  aggregateGoals:any[];
  kpiGoals:any[];
  qualitativeGoals:any[];
  textArea:string;

  subGoal:any;
  Goal:any;

  _postStatusDto:any[]=[];
  _postQualitativeGoalStatus:any[]=[];
  showKpiSaveButton:boolean=false;
  showQualitativeSaveButton:boolean=false;

  calculatePercentage:any[]=[];
  totalGoals:any[]=[];
  totalPercentage:any;

  showRatings:boolean=true;
  showClinicalNotes:boolean=false;
  clinicalNoteParams:any;

  //These variables are added for getting Aggregate goals along with total aggregate
  totalResponse:any[];
  goalsAndTheirResponse:any;
  columnWiseTotalAggregate:any;

  dayScheduleSubGroupId:any;
  daySchedule:any;
  paramClinicianId:any;
  isPatientLogin:boolean;
  PopUp:boolean;
  PopUpdata: any={'benchmarkTitle':'','nextBenchmark':''};
  SelectedBenchmark:any;
  roleAccess: RoelAccess;
  isGroupNote: boolean;
  patientaccountid: any;
  ddlseverity: any;
  bg: any;
  constructor(public global: Global, public clinicianservice: ClinicianService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService
    , public roleaccessservice:PageAccessService, private cdr: ChangeDetectorRef,) { }

  ngOnInit(): void {
    this.getSeverityMaster();
    const pageid = constantVariables.pagelists.ProgressNoteFivepointRating;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.userId = Number(localStorage.getItem('id'));
    this.clinicalNotes = new ClinicalNotes();
    //this._dtoMedication=new Medication();
     this.paramClinicianId="0";
     this.isPatientLogin=false;
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
         this.grpTherapyId = JSON.parse(this.commonService.FrontEndDecryption(params.id));
        this.selecteddate = JSON.parse(this.commonService.FrontEndDecryption(params.date));
        if(params.clinicalNotes){
        this.clinicalNoteParams=JSON.parse(this.commonService.FrontEndDecryption(params.clinicalNotes));
        }
        if(params.dayScheduleSubGroupId){
          this.dayScheduleSubGroupId=JSON.parse(this.commonService.FrontEndDecryption(params.dayScheduleSubGroupId));
          }
          if(params.paramClinicianId)
          {
            this.paramClinicianId=JSON.parse(this.commonService.FrontEndDecryption(params.paramClinicianId));
          }
          if(params.paramIsPatientLogin)
          {
            let ptLogin=JSON.parse(this.commonService.FrontEndDecryption(params.paramIsPatientLogin));
            this.isPatientLogin=(ptLogin=="true")?true:false;
          }
          if(params.patientaccountid){
            this.patientaccountid = JSON.parse(this.commonService.FrontEndDecryption(params.patientaccountid));
          }
          this.getgrouptherapy();
        
      }
    });
  }
  getgrouptherapy() {
    this.spinnerservice.show();
    this.clinicianservice.getGroupTherapyDetails(this.grpTherapyId,this.selecteddate,this.paramClinicianId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
         if (res != null) {
           this.datanotExists=(res.length==0)?true:false;
          this._dtoGrpTherapyMeta = res[0];
          this._dtoGrpTherapy = res;
          this._dtofilteredGrpTherapy =  this._dtoGrpTherapy          
         
            this.onThemeChange( this._dtoGrpTherapy[0].themeName)            
           // this._ddlGrpTherapy = _.uniqBy( this._dtoGrpTherapy, 'themeName');
           
         // this.ongetMedication();
          if(this.clinicalNoteParams=='true'){
            this.onAddGroupNotes(5);
          }
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
 
  onThemeChange(event){
    if(this.dayScheduleSubGroupId!=(undefined||null) && this.patientaccountid!=(undefined||null)){
      this._dtofilteredGrpTherapy = this._dtoGrpTherapy.filter(x=>x.dayScheduleSubGroupId == this.dayScheduleSubGroupId && x.patientAccountId == this.patientaccountid)
      this.daySchedule=this._dtofilteredGrpTherapy.themeName;
      this._ddlGrpTherapy = _.uniqBy( this._dtofilteredGrpTherapy, 'themeName');
    }
    else{
      this._dtofilteredGrpTherapy = this._dtoGrpTherapy.filter(x=>x.themeName == event)
      this._ddlGrpTherapy = _.uniqBy( this._dtoGrpTherapy, 'themeName');
      
    }

    if(this._dtofilteredGrpTherapy?.length > 0){
      this.getProgressActivityDetails(this._dtofilteredGrpTherapy[0]);
      this.getProgressNotes();
      this.dayScheduleSubGroupId=null
    }
  }
  getProgressActivityDetails(item) {
    this.spinnerservice.show();
    this._dtoProgressActivity = [];
    this.patientinfoid = item.patientInfoId;
    this.grpTherapydetails = item;
    item.therapyType=3;
    item.therapyDate=this.selecteddate;
    this.daySchedule=item.themeName;
    // this.clinicianservice.getProgressActivityDetails(item).subscribe(
    //   (res: any) => {
    //     this.spinnerservice.hide();
    //     if (res != null) {

    //       this._dtoProgressActivity = res;
    //       this.chmaTreatmentSummaryId=this._dtoProgressActivity[0].chmaTreatmentSummaryId;
    //       this.getTreatmentGoalsList();

    //       this.getProgressNotes();
    //       this.onHistorymedication();
    //     }
    //   },
    //   err => {
    //     this.spinnerservice.hide();

    //   },
    // );
    this.clinicianservice.getProgressActivityDetailsForTherapyWithTotal(item).subscribe((res)=>{
      var temp:any[]
      temp=res;
      this.totalResponse=res;
     // this.columnWiseTotalAggregate=this.totalResponse.filter(x=>x.typeId!=3);
      this.goalsAndTheirResponse=temp.filter(y=>y.typeId==3)
      res.map(x=>{
        if(x.progressActivityDetailsId!=null){
        x.socialSkills==1?x.socialSkills=true:x.socialSkills=false
        x.distressTolerance==1?x.distressTolerance=true:x.distressTolerance=false
        x.exposureTherapy==1?x.exposureTherapy=true:x.exposureTherapy=false
        x.interpersonalEffectiveness==1?x.interpersonalEffectiveness=true:x.interpersonalEffectiveness=false
        x.lifeSkills==1?x.lifeSkills=true:x.lifeSkills=false
        x.purposefulMovement==1?x.purposefulMovement=true:x.purposefulMovement=false
        x.skillsGeneralization==1?x.skillsGeneralization=true:x.skillsGeneralization=false
        }else{
          x.socialSkills==1?x.socialSkills=true:x.socialSkills=null
          x.distressTolerance==1?x.distressTolerance=true:x.distressTolerance=null
          x.exposureTherapy==1?x.exposureTherapy=true:x.exposureTherapy=null
          x.interpersonalEffectiveness==1?x.interpersonalEffectiveness=true:x.interpersonalEffectiveness=null
          x.lifeSkills==1?x.lifeSkills=true:x.lifeSkills=null
          x.purposefulMovement==1?x.purposefulMovement=true:x.purposefulMovement=null
          x.skillsGeneralization==1?x.skillsGeneralization=true:x.skillsGeneralization=null
        }
      })
      this._dtoProgressActivity=res;
    //  this.chmaTreatmentSummaryId=this._dtoProgressActivity[0].chmaTreatmentSummaryId;
      this.getProgressNotes();
     // this.getTreatmentGoalsList();
      this.onHistorymedication()
    })
    // this.clinicianservice.getProgressActivityDetailsForTherapy(item).subscribe(
    //   (res: any) => {
    //     this.spinnerservice.hide();
    //     if (res != null) {

    //       this._dtoProgressActivity = res;
    //       this.chmaTreatmentSummaryId=this._dtoProgressActivity[0].chmaTreatmentSummaryId;
    //       this.getTreatmentGoalsList();

    //       this.getProgressNotes();
    //       this.onHistorymedication();
    //     }
    //   },
    //   err => {
    //     this.spinnerservice.hide();

    //   },
    // );
  }
  getProgressPDF(item){
    this.spinnerservice.show();
    this.clinicianservice.getProgressPDF(this.grpTherapydetails,this.selecteddate).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdffile = res.filePath;
        if(item==1){
          window.open(this.pdffile, '_blank');
        }
        else if(item==0){
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  postfrmProgress() {
    this.spinnerservice.show();
    for (let i = 0; i < this._dtoProgressActivity.length; i++) {
      this._dtoProgressActivity[i].groupTherapyId = this.grpTherapyId;
      this._dtoProgressActivity[i].patientAccountId = this.grpTherapydetails.patientAccountId;
      this._dtoProgressActivity[i].dayScheduleAssignId = this.grpTherapydetails.dayScheduleAssignId;
      this._dtoProgressActivity[i].dayScheduleSubGroupId = this.grpTherapydetails.dayScheduleSubGroupId;
      this._dtoProgressActivity[i].therapyDate = this.selecteddate;
    }

    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoProgressActivity, false);
    this.clinicianservice.postprogressActivity(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.getProgressActivityDetails(this.grpTherapydetails);

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getrowActivityTotal(item) {
    let total = 0;
    total += item.socialSkills == true ? 1 : 0;
    total += item.skillsGeneralization == true ? 1 : 0;
    total += item.distressTolerance == true ? 1 : 0;
    total += item.interpersonalEffectiveness == true ? 1 : 0;
    total +=item.lifeSkills== true ? 1 : 0;
    total += item.exposureTherapy == true ? 1 : 0;
    total +=item.purposefulMovement == true ? 1 : 0;

    return total;
  }
  getofTotal() {
    let total = 0;
    this._dtoProgressActivity.forEach((el) => {

      total += el["socialSkills"] == true ? 1 : 0;
      total += el["skillsGeneralization"] == true ? 1 : 0;
      total += el["distressTolerance"] == true ? 1 : 0;
      total += el["interpersonalEffectiveness"] == true ? 1 : 0;
    });

    return total;

  }
  getColumnTotal(item) {
    let total = 0;
    this._dtoProgressActivity.forEach((el) => {

      total += el[item] == true ? 1 : 0;
    });

    return total;

  }
  onFivepoint() {
    this.clinicalNotes = new ClinicalNotes();
    this.showRatings=true;
    this.showClinicalNotes=false;
    this.isShowgroupnote = false;
  }
  onAddGroupNotes(type) {
    if(type==5){
      this.showRatings=false;
      this.showClinicalNotes=true;
    }
    this.notesType = type;
    this.isShowgroupnote = true;


    this.getProgressNotes();
  }
  onSaveGroupNote() {

    if (this.clinicalNotes.clinicalTopics != null || this.clinicalNotes.clientsResponse != null || this.clinicalNotes.followUpPlan != null) {
      this.spinnerservice.show();
      const dataToEncrypt = this.commonService.DataToEncrypt(this.clinicalNotes, false);
      this.clinicianservice.postProgressNote(dataToEncrypt).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getProgressActivityDetails(this.grpTherapydetails);
          this.getProgressNotes();
          this.isShowgroupnote = false;
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }
  }
  getProgressNotes() {
    this.spinnerservice.show();
    this._dtoProgressNotes=[];
    this.clinicianservice.getProgressNote(this.grpTherapydetails.patientAccountId, this.grpTherapyId, this.grpTherapydetails.dayScheduleSubGroupId).subscribe(
      (res: any) => {
        this.isSocialNotes = this.isSkillsNotes =this.isDistressNotes=this.isInterpersonalNotes =false;
        this.spinnerservice.hide();
        this._dtoProgressNotes=res;        
        this.getProgressNotesbyType(res, this.notesType);
        if (res && res.length > 0) {
          this.getProgressNotesbyType(res, this.notesType);


          this.isSocialNotes = res.filter(x => x.notesType == 1).length > 0 ? true : false;
          this.isSkillsNotes = res.filter(x => x.notesType == 2).length > 0 ? true : false;
          this.isDistressNotes = res.filter(x => x.notesType == 3).length > 0 ? true : false;
          this.isInterpersonalNotes = res.filter(x => x.notesType == 4).length > 0 ? true : false;
          for (let i = 0; i < res.length; i++) {
           /// if (res[i].notesType <= 1) {
            //  (document.getElementById('esign_' + res[i].notesType + '') as HTMLImageElement).src = res[i].staffInitials;
            //  (document.getElementById('esign_12') as HTMLImageElement).src = res[i].staffInitials;
           // }
            if (res[i].notesType == 1) {
              if (res[i].clinicalTopics != null || res[i].clientsResponse != null || res[i].followUpPlan != null) {
                this.isSocialNotes = res[i].notesType == 1 ? true : false;
              }
            }
            else if (res[i].notesType == 2) {
              if (res[i].clinicalTopics != null || res[i].clientsResponse != null || res[i].followUpPlan != null) {
                this.isSkillsNotes = res[i].notesType == 2 ? true : false;
              }
            }

            else if (res[i].notesType == 3) {
              if (res[i].clinicalTopics != null || res[i].clientsResponse != null || res[i].followUpPlan != null) {
                this.isDistressNotes = res[i].notesType == 3 ? true : false;
              }
            }
            else if (res[i].notesType == 4) {
              if (res[i].clinicalTopics != null || res[i].clientsResponse != null || res[i].followUpPlan != null) {
                this.isInterpersonalNotes = res[i].notesType == 4 ? true : false;
              }
            }
            else if (res[i].notesType == 6) {
              if (res[i].clinicalTopics != null || res[i].clientsResponse != null || res[i].followUpPlan != null) {
                this.isLifeSkills = res[i].notesType == 6 ? true : false;
              }
            }
            else if (res[i].notesType == 7) {
              if (res[i].clinicalTopics != null || res[i].clientsResponse != null || res[i].followUpPlan != null) {
                this.isExposureTherapy = res[i].notesType == 7 ? true : false;
              }
            }
            else if (res[i].notesType == 8) {
              if (res[i].clinicalTopics != null || res[i].clientsResponse != null || res[i].followUpPlan != null) {
                this.isPurposefulMovement = res[i].notesType == 8 ? true : false;
              }
            }
            else if (res[i].notesType == 5) {
              if (res[i].clinicalTopics != null || res[i].clientsResponse != null || res[i].followUpPlan != null) {
                this.isGroupNote = res[i].notesType == 5 ? true : false;
              }
            }
          }

        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  getProgressNotesbyType(res, type) {
    this.clinicalNotes = res.filter(x => x.notesType == type)[0];
    if (this.clinicalNotes == undefined) {
      this.clinicalNotes = new ClinicalNotes();
      this.clinicalNotes.notesType = type;
      this.clinicalNotes.grouptherapyId = this.grpTherapyId;
      this.clinicalNotes.patientAccountId = this.grpTherapydetails.patientAccountId;
      this.clinicalNotes.dayScheduleSubGroupId = this.grpTherapydetails.dayScheduleSubGroupId;
    }
  }
  openDialog(type) {
    this.global.isconsentesign = false;

    const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
      { data: { parentinfoId: this.userId, isfutureSign: false, isParentsign: true } });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign) {
        this.signatureImg = this.updatedsignatureImg;
        this.isAttachEsign = true;

      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.isAttachEsign = true;

      }
      else {
        this.signatureImg = this.defaultImage;

      }
      (document.getElementById('esign_' + type + '') as HTMLImageElement).src = this.signatureImg;
      this.onSaveStaffInitial(type, this.signatureImg);
    });
  }
  onSaveStaffInitial(type, initial) {
    this.spinnerservice.show();
    let obj = {
      notesType: type,
      grouptherapyId: this.grpTherapyId,
      patientAccountId: this.grpTherapydetails.patientAccountId,
      dayScheduleSubGroupId:this.grpTherapydetails.dayScheduleSubGroupId,
      staffInitials: initial
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.clinicianservice.postProgressStaffInitial(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();

        this.getProgressActivityDetails(this.grpTherapydetails);
        this.getProgressNotes();
        this.isShowgroupnote = false;
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  addMedicationInfo() {
    // this._dtoMedication=new Medication();

    this._dtoMedication.push({
      patientMedicationId: 0,
      patientAccountId: this.grpTherapydetails.patientAccountId,
      dayScheduleSubGroupId:this.grpTherapydetails.dayScheduleSubGroupId,
      medications: null,
      dosage: null,
      frequency: null,
      isMorning: null,
      isNoon: null,
      isEvening: null,
      isBedTime: null,
      isBeforemeal: null,
      isDelete: null,
    })
    this.frmProgress.resetForm();
  }
  ongetMedication() {
    this.isshowhistroy=false;
    this._dtoMedication = [];
    this.spinnerservice.show();

    this.clinicianservice.getprogressmedicine(this.grpTherapydetails.patientAccountId,this.grpTherapydetails.dayScheduleSubGroupId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          this.lstmedicine = res;
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
    //this.addMedicationInfo();
  }
  postProgressMedication() {
    this.spinnerservice.show();

    //const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoMedication, false);
    this.clinicianservice.postProgressMedication(this._dtoMedication).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (this.editmedication) {
          var update = res.replace('saved', 'Updated')
          this.snackbar.success(update);
        } else {
          this.snackbar.success(res);
        }

        this._dtoMedication = [];
        this.ongetMedication();
        //this.getProgressActivityDetails(this.grpTherapydetails);
        this.editmedication = false;
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );

  }
  removeclinicalinformation(data, index) {
    if (data.patientMedicationId != 0) {
      this.removeexistMedinfo(data);
    }
    this._dtoMedication.splice(index, 1);
  }
  removeexistMedinfo(data) {
    this.clinicianservice.deleteMediciation(data.patientMedicationId,data.dayScheduleSubGroupId).subscribe((res: any) => {
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  textChanged($event) {
    if ($event.editor.getLength() > 200) {
      $event.editor.deleteText(200, $event.editor.getLength());
    }
  }
  onEditMedication(item) {
    this.editmedication = true;
    this._dtoMedication = [];
    for (let i = 0; i < item.dtoPatientMedication.length; i++) {
      this._dtoMedication.push({
        patientMedicationId: item.dtoPatientMedication[i].patientMedicationId,
        patientAccountId: item.dtoPatientMedication[i].patientAccountId,
        medications: item.dtoPatientMedication[i].medications,
        dosage: item.dtoPatientMedication[i].dosage,
        frequency: item.dtoPatientMedication[i].frequency,
        isMorning: item.dtoPatientMedication[i].isMorning,
        isNoon: item.dtoPatientMedication[i].isNoon,
        isEvening: item.dtoPatientMedication[i].isEvening,
        isBedTime: item.dtoPatientMedication[i].isBedTime,
        isBeforemeal: item.dtoPatientMedication[i].isBeforemeal,
        isDelete: null,
      })
    }
  }
  onHistorymedication() {

    //this.medicationhistroy=[];
      this.clinicianservice.getPsychotropicMedication(this.patientinfoid).subscribe(
        (res: any) => {
          this.spinnerservice.hide();

            this.medicationhistroy = res;


        },
        err => {
          this.spinnerservice.hide();
        },
      );

  }
  onOpenHistroy(){
    this.isshowhistroy = !this.isshowhistroy;
  }
  onPreviewTherapy(){
    this.getProgressNotes();
    this.isPreviewTherapy =true
  }



  toggleModal(modal,subGoal,item){
    this.showModal=!modal;
    this.textArea=subGoal.comments;
    this.subGoal=subGoal;
    this.Goal=item;
  }

  closeModal(modal){
    this.showModal=!modal;
  }

  postComments(){
    this.showModal=false;
    this.spinnerservice.show();

    var _postCommentsDto={
      trackGoalId:+this.subGoal.trackGoalId,
      comments:this.textArea,
      goalMeasureTypeId:this.subGoal.goalMeasureTypeId,
      chmaTreatmentSummaryId:this.chmaTreatmentSummaryId,
      treatmentGoalsId:this.Goal.treatmentGoalsId,
      goalId:this.Goal.goalId,
      goalMeasureId:this.subGoal.goalMeasureId,
      therapyId:3
    }

    this.clinicianservice.postKpiGoalComments(_postCommentsDto).subscribe((res)=>{
      this.getTreatmentGoalsList();
      this.spinnerservice.hide();
      this.textArea=null;
      this.snackbar.success(res)
    },(err)=>{
      this.snackbar.error(err.error);
      this.spinnerservice.hide();
    })
  }

  statusChange(item,subGoal){
    this.showKpiSaveButton=true;
    var currentDate = new Date()
    var _postStatusDto={
      trackGoalId:+subGoal.trackGoalId,
      goalStatus:+subGoal.goalStatus,
      goalMeasureTypeId:subGoal.goalMeasureTypeId,
      chmaTreatmentSummaryId:this.chmaTreatmentSummaryId,
      treatmentGoalsId:item.treatmentGoalsId,
      goalId:item.goalId,
      goalMeasureId:subGoal.goalMeasureId,
      goalCompletionDate:(+subGoal.goalStatus)==2?currentDate:null
    }
    this._postStatusDto.push(_postStatusDto);
  }

  postStatus(){
    if(this._postStatusDto.length==0){
      this.snackbar.error('Please change the Status');
      return
    }

    this.spinnerservice.show();
    this.clinicianservice.postKpiGoalStatus(this._postStatusDto).subscribe((res)=>{
      this.spinnerservice.hide();
      this.snackbar.success(res);
      this._postStatusDto=[];
      this.calculatePercentage=[];
      this.totalGoals=[];
      this._postQualitativeGoalStatus=[];
      this.showKpiSaveButton=false;
      this.getTreatmentGoalsList();
    },(err)=>{
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    })
  }

  getTreatmentGoalsList() {
    this.calculatePercentage=[];
    this.totalGoals=[];
    this.clinicianservice.GetTreatmentGoalForTherapy(this.chmaTreatmentSummaryId,3).subscribe(
      (res: any) => {
        this.spinnerservice.hide();

        if (res) {
          this.treatmentGoalList = res;
          this.aggregateGoals=this.treatmentGoalList.filter(x=>x.measureTypeId==constantVariables.MeasureTypeName.Aggregatedpercentagescore);
          this.kpiGoals=this.treatmentGoalList.filter(X=>X.measureTypeId==constantVariables.MeasureTypeName.KPIprogresslinked);
          this.qualitativeGoals=this.treatmentGoalList.filter(x=>x.measureTypeId==constantVariables.MeasureTypeName.Qualitative);

          var tempOne=this.kpiGoals.filter(x=>{
            x.measures.filter(y=>{
              this.totalGoals.push(y.goalStatus)
              if(y.goalCompletionDate<=y.dueDate){
              this.calculatePercentage.push(y.goalStatus);}
            })
          })
          this.totalPercentage=((this.calculatePercentage.length)/this.totalGoals.length)*100;
          //use this for css
          //[style.width.%]="totalPercentage"
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  qualitativeStatusChange(item,subGoal){
    this.showQualitativeSaveButton=true;
    var _postQualitativeStatusDto={
      trackGoalId:+subGoal.trackGoalId,
      goalMeasureTypeId:subGoal.goalMeasureTypeId,
      chmaTreatmentSummaryId:this.chmaTreatmentSummaryId,
      treatmentGoalsId:item.treatmentGoalsId,
      goalId:item.goalId,
      goalMeasureId:subGoal.goalMeasureId,
      goalStatus:+subGoal.goalStatus,
      therapyId:3
    }
    this._postQualitativeGoalStatus.push(_postQualitativeStatusDto);

  }

  postQualitaiveGoalStatus(){
    this.spinnerservice.show();
    this.clinicianservice.postKpiGoalStatus(this._postQualitativeGoalStatus).subscribe((res)=>{
      this.spinnerservice.hide();
      this.snackbar.success(res);
      this.calculatePercentage=[]
      this._postQualitativeGoalStatus=[];
      this.showQualitativeSaveButton=false;
      this.getTreatmentGoalsList();
    },(err)=>{
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    })
  }
  onBackClick(){
    if(this.global.ismytask==undefined){
      window.close();
    }
    this.router.navigate(['/clinician'],{queryParams:{menu:1}});
  }
  changePosition(item){
    item.isVisible=!(item.isVisible);
  }

  OnstatusChange(item:any,x){
    this.SelectedBenchmark = null;
    if(item.trackBenchIsSelected){
      if(x==3){
        this.PopUp = true;
        this.PopUpdata = item;
      }
  // item.Isstatuschnaged = true;
  // item.status = x
  // console.log(item)
    }
    else{
      this.snackbar.error("Benchmark Already completed")
    }
  }

  Onclosepopup(){
    for(let item of this._dtoProgressActivity) {
      if(item.trackBenchmarkId== this.PopUpdata.trackBenchmarkId){
       item.status = 2 
       this.PopUp = false
       return
      }
    }
  }
  OnBenchmarkselect(){
    this.spinnerservice.show();
 
    if(this.SelectedBenchmark==1){
     for(let item of this._dtoProgressActivity) {
       if(item.trackBenchmarkId== this.PopUpdata.trackBenchmarkId){
        item.Isstatuschnaged = true;
        item.status = 3
        item.IsNextBenchMark = false;
        this.PopUp = false
        this.spinnerservice.hide()
        return
       }
     }
    }
    else if(this.SelectedBenchmark==2){
      for(let item of this._dtoProgressActivity) {
        if(item.trackBenchmarkId== this.PopUpdata.trackBenchmarkId){
         item.Isstatuschnaged = true;
         item.status = 3
         item.IsNextBenchMark = true;
         this.PopUp = false
         this.spinnerservice.hide()
         return
        }
      }
    }
    else{
      this.snackbar.error(constantVariables.SuccessMessage.BenchmarkSelectError)
      this.spinnerservice.hide();
    }
   
//console.log(this.SelectedBenchmark)

  }

  print(){
    let printContents = document.getElementById('print-section').innerHTML;
       let originalContents = document.body.innerHTML;
       document.body.innerHTML = printContents;
       window.print();
       document.body.innerHTML = originalContents;
      // this.ShowPreview=true;
       window.location.reload();
      
  }
  getSeverityMaster(){
    this.spinnerservice.show()
    this.commonService.getSeverityMaster().subscribe(
      res => {
        if (res != null) {
      this.ddlseverity = res;      
   this.spinnerservice.hide()
  }},
  err => {
    this.spinnerservice.hide();
  
  })
  }
  onSelectionChanged(value){
    this.bg = this.ddlseverity.filter(x=>x.severityValue==value)[0].colocode;
    this._dtoProgressNotes[0].severity=value;
  
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/admin/authentication.service';
import { CommonService } from 'src/app/common/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/app/global/global.model';
import { constantVariables } from 'src/app/constant/constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MenuList } from 'src/app/_models/_Common/roleAccessMaster';
declare function EncryptFieldData(data): any;
@Component({
  selector: 'app-clinician-layout',
  templateUrl: './clinician-layout.component.html',
  styleUrls: ['./clinician-layout.component.scss']
})
export class ClinicianLayoutComponent implements OnInit {

  selectedMenu: any;
  statusFinancial = constantVariables.status.financial;
  statusreviewTour = constantVariables.status.reviewTour;
  statusforIntake = constantVariables.status.forIntake;
  subMaster: string = '';
  roleId: any;
  profileOpen = false;
  notificationOpen = false;
  patientId: any;
  UserName: any;
  messageCount: any;
  LeadStatus: any;
  patientAccountId: any;
  ddlSfccLocation: any;
  tab: number;
  MenuAccess: MenuList;
  roleAccess: any;

  constructor(private http: HttpClient, private authenticationService: AuthenticationService,
    private router: Router, public commonService: CommonService, public global: Global,private route: ActivatedRoute,
    public spinnerservice: NgxSpinnerService, public roleaccessservice:PageAccessService
    ) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Task;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        if(params.tab!=undefined){
        this.tab = Number(this.commonService.FrontEndDecryption(params.tab).replace(/"/g, ""));
          this.selectedMenu =1;
        }
        else{
        this.selectedMenu = Number(this.commonService.FrontEndDecryption(params.menu).replace(/"/g, ""));
        }
      }
    else{
      if(this.global.ismytask == undefined || this.global.ismytask){
        this.selectedMenu =1;
      }
      else{
        this.selectedMenu =3;
       // this.onTabChange(3);
       // this.global.ismytask == undefined
      }
        }   
       });
 
    this.MenuAccess = this.roleaccessservice.GetSiteHeaderAccess();
    this.roleId = localStorage.getItem("roleId");
    this.patientId = localStorage.getItem("patientId");
    this.UserName = localStorage.getItem("username");
    this.global.messagecount = localStorage.getItem("messageCount");
    this.global.LeadStatus = localStorage.getItem("currentstatus");
    this.LeadStatus = localStorage.getItem("currentstatus");
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    //this.router.navigateByUrl('/mytask');
    // if (this.selectedMenu == undefined || this.selectedMenu == null) {
    //   this.onTabChange(1);
    // }
  }
  onlogout() {
    this.authenticationService.onLogout();
  }
  onClickMessages() {
    this.router.navigateByUrl('/messages');
  }
  onTabChange(tab) {
    this.selectedMenu = tab;
     if (this.selectedMenu == 1) {
      this.router.navigateByUrl('/myappointments');
    } else if (this.selectedMenu == 2) {
      this.router.navigateByUrl('/task-info');
    } else if (this.selectedMenu == 3) {
      this.router.navigateByUrl('/treatmentplan-list');
    }
    else if (this.selectedMenu == 4) {
      this.router.navigateByUrl('/incidentreportingList');
    }
    else if (this.selectedMenu == 5) {
      this.router.navigateByUrl('/dischargesummary-list');
    }
    else if (this.selectedMenu == 6) {
      this.router.navigateByUrl('/pending-documents');
    }

  }
  resetPaginator(){
    localStorage.removeItem('paginator')
  }
}

export class chmadetails {
    cHMADetailsId: number;
    patientAccountId: number;
    ageStarted: any;
    lastdrink: string;
    frequency: number;
    hospitalizationDate: string;
    hospitalizationReason: string;
    hospitalAttending: string;
    admissionDate: string;
    dischargeDate: string;
    clinicalSummary: string;
    providerName: string;
    signDate: string;
    eSignature: string;
    mindSet:string;
}

export class dtoChmaTreatmentSummary {
    ChmatreatmentSummaryId: number;
    patientAccountId: number;
    primaryClinicianId: number;
    firstName: string;
    lastName: string;
    treatmentPlanDate: string;
    asebareport: string;
    createdBy: number;
    createdDate: string;
    updatedBy: number;
    updatedDate: string;
    documents:any;   
}

export class dtoChmastatusWithChmaClinicians
{
     Pid:any;    
         PatientInfoId :any;   
         PatientAccountId :any;   
        FirstName :any;   
        LastName :any;   
        HospitalName :any;   
        Email :any;   
        AddressLine1 :any;   
        AddressLine2 :any;   
        StatesId :any;   
       CityId :any;   
        ZipCode :any;   
        PhoneNumber :any;   
        FaxNumber :any;   
         PhysicianTypeId :any;   
         Frequency :any;   
        IsDeleted :any;   
         PatientHistoryId :any;   
          UserId=localStorage.getItem('id');


}
export class DtoChmaproviderPsy
{
    Pid:any;    
    PatientInfoId :any;   
    PatientAccountId :any;   
   FirstName :any;   
   PhoneNumber :any;   
   PhysicianTypeId :any;     

}
export class DtoChmaproviderPsychatric  
{
    Pid:any;    
    PatientInfoId :any;   
    PatientAccountId :any;   
   FirstName :any;   
   PhoneNumber :any;   
   PhysicianTypeId :any;     

}
export class DtoChmaproviderPhysian
{
    Pid:any;    
    PatientInfoId :any;   
    PatientAccountId :any;   
   FirstName :any;   
   PhoneNumber :any;   
   PhysicianTypeId :any;     

}
export class DtoChmaproviderTherapy
{
    Pid:any;    
    PatientInfoId :any;   
    PatientAccountId :any;   
   FirstName :any;   
   PhoneNumber :any;   
   PhysicianTypeId :any;     

}
import { Component, OnInit } from '@angular/core';
import { HubConnection, HubConnectionBuilder, } from '@aspnet/signalr';
import { environment } from 'src/environments/environment';
import { notification } from '../_models/_Common/notification';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import * as signalR from '@aspnet/signalr';
import { CommonService } from '../common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Global } from 'src/app/global/global.model';
import * as moment from 'moment';
import { constantVariables } from '../constant/constant';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  public _hubConnection: HubConnection;
  public notificationmodel: notification;
  notificationOpen = false;
  currentUserId: any;
  inAppNotificationList: any;
  FilterInAPPNotificationList: any;
  UnReadCount: any;
  TotalCount: any;
  UserName: any;
  roleId: any
  messageCount:any;

  constructor(private router: Router,public global: Global, private notificationservice: NotificationService,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService) { }

  ngOnInit(): void {
    if (localStorage.getItem('id')) {
      this.currentUserId = localStorage.getItem('id');

    }
    this.roleId = localStorage.getItem("roleId");
    this.notificationmodel = new notification();
    this.UserName = localStorage.getItem("username");
    this._hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information).withUrl(environment.BaseInAppURI + 'notify', {

      }).build();

    this._hubConnection.start().then(
      () => {
        //console.log('Connection started!');
        this.ListAlInAppNotification();
        this.registerHubMethod();
      })
      .catch(err => console.log('Error while establishing connection :(' + err));
  }

  toggleNotification() {
    //this.notificationOpen = !this.notificationOpen;
  }
  private registerHubMethod(): void {

    this._hubConnection.on('BroadcastMessage', (userId: number, InappList: string) => {
      this.ListAlInAppNotification();

    });
  }
  ListAlInAppNotification() {
    let _obj = {
      UserId: this.currentUserId,
      RoleId: Number(this.roleId)
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.notificationservice.getAllNotification(this.currentUserId, Number(this.roleId)).subscribe(
      (res:any) => {
        this.inAppNotificationList = res.notifications;
        this.FilterInAPPNotificationList = res.notifications;

        this.TotalCount = this.inAppNotificationList.length;
        this.UnReadCount = this.inAppNotificationList.filter(i => (i.status == 0)).length;
        this.global.messagecount=res.messageCount;
        var token = localStorage.getItem('token');
        const jwtData = token.split('.')[1];
        const decodedJwtJsonData = window.atob(jwtData);
        const decodedJwtData = JSON.parse(decodedJwtJsonData);
        const userId = decodedJwtData.UserID;

      },
      err => {
        console.log(err);
      },
    );
  }
  triggerInappNotification(title, comments) {
    comments = comments.replace('{{username}}', this.UserName)
    let _obj = {
      NotificationTitle: title,
      Comments: comments,
      RoleId: this.roleId,
      UserId: this.currentUserId
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);

    this.notificationservice.posttriggernotification(dataToEncrypt).subscribe(res => {
      //this.spinnerservice.hide();


    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  markUnreadNofitication(item) {
    const dataToEncrypt = this.commonService.DataToEncrypt(item, false);
    this.notificationservice.postMarkRead(dataToEncrypt).subscribe(res => {
      if(item.notificationTypeId == constantVariables.NotificationTypeid.AuthSetting)
      {
        this.router.navigate(['//auth-threshold']);
      }
      // if(item.notificationTypeId == constantVariables.NotificationTypeid.AuthNotificationIsManager)
      // {
      //   this.router.navigateByUrl('/treatmentplan-list');
      // }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.RequestTour){
        this.router.navigate(['/request-tour'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId),name:this.commonService.FrontEndEncryption(item.comments) } });
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.Email){
        this.router.navigate(['/messages']);
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.RequestReview || item.notificationTypeId == constantVariables.NotificationTypeid.AcceptProposal || item.notificationTypeId == constantVariables.NotificationTypeid.DenyProposal
        || item.notificationTypeId == constantVariables.NotificationTypeid.CancelTour || item.notificationTypeId == constantVariables.NotificationTypeid.MarkTourCompleted){
        if(window.location.href.includes('leadlist')){
          window.location.reload();
        }
        else{
          this.router.navigate(['/leadlist']);
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.ReturnProfile || item.notificationTypeId == constantVariables.NotificationTypeid.ProfileVerified || item.notificationTypeId == constantVariables.NotificationTypeid.SubmitProposal
        ||( item.notificationTypeId == constantVariables.NotificationTypeid.AssignTour && !!(localStorage.getItem('patientId')))){
        //this.router.navigate(['/leadinfo']);
        if( item.notificationTypeId == constantVariables.NotificationTypeid.SubmitProposal ||  item.notificationTypeId == constantVariables.NotificationTypeid.AssignTour){
          localStorage.setItem("profiletab", "5");
  
        }
        this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId) } });
      }
      if(( item.notificationTypeId == constantVariables.NotificationTypeid.AssignTour && !(localStorage.getItem('patientId'))) || (item.notificationTypeId == constantVariables.NotificationTypeid.RequestTourAssignStaff &&  !(localStorage.getItem('patientId')))){
        if(window.location.href.includes('clinician?tab')){
          window.location.reload();
        }
        else{
        this.router.navigate(['/clinician'],{ queryParams: { tab: 2 } });
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.RescheduleIntakeRequest){
        if(window.location.href.includes('patientslists')){
          window.location.reload();
        }
        else{
          this.router.navigate(['/patientslists']);
  
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.ScheduleIntake){
        if(!(localStorage.getItem('patientId'))){
          this.router.navigate(['/clinician']);
        }
        else{
          this.router.navigate(['/patient-breifcase']);
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.AcceptReschedule || item.notificationTypeId == constantVariables.NotificationTypeid.DenyReschedule ){
        if(window.location.href.includes('clinician?tab')){
          window.location.reload();
        }
        else{
        this.router.navigate(['/clinician'],{ queryParams: { tab: 2 } });
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.CompleteTreatmentplan){
        if(!(localStorage.getItem('patientId'))){
          if(window.location.href.includes('patientslists')){
            window.location.reload();
          }
          else{
            this.router.navigate(['/patientslists']);
    
          }
        }
        else{
          this.router.navigate(['/patient-breifcase']);
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.Audit)
      {
        //this.router.navigateByUrl('/audit-list');
        this.router.navigate(['/audit-clinician'], {
          queryParams: {
            id: this.commonService.FrontEndEncryption(item.patientAccountId),
          },
        });
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.ScheduleAppointments){
        if(!(localStorage.getItem('patientId'))){
          if(window.location.href.includes('clinician')){
            window.location.reload();
          }
          else{
            this.router.navigate(['/clinician']);
    
          }
        }
        else{
          this.router.navigate(['/patient-breifcase']);
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.PsyTherapyFollowup){
        if(window.location.href.includes('patientslists')){
          window.location.reload();
        }
        else{
          this.router.navigate(['/patientslists']);
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.GenerateIntakepdf){
        if(window.location.href.includes('clinician?menu=3')){
          window.location.reload();
        }
        else{
          this.router.navigate(['/clinician'],{ queryParams: { menu: 3 } });
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.UpdateBenifits){
        localStorage.setItem("profiletab", "2");
        if(window.location.href.includes('leadinfo')){
          window.location.reload();
        }
        else{
        this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId) } });
        }
      }
      if((item.notificationTypeId == constantVariables.NotificationTypeid.RequestTourAssignStaff &&  !!(localStorage.getItem('patientId')))|| item.notificationTypeId == constantVariables.NotificationTypeid.RescheduleTourRequest || item.notificationTypeId == constantVariables.NotificationTypeid.ChangeStaffTour){
        localStorage.setItem("profiletab", "5");
       
        if(window.location.href.includes('leadinfo')){
          window.location.reload();
        }
        else{
        this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId) } });
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.AcceptIntake){
        var access = JSON.parse(localStorage.getItem('roleaccess'));
        //const pageid = constantVariables.pagelists.Goal;
        var roleaccessClinician = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.ProgressNoteFivepointRating);
        if(!(localStorage.getItem('patientId')) && roleaccessClinician.isView){
          if(window.location.href.includes('clinician')){
            window.location.reload();
          }        
          else{
            this.router.navigate(['/clinician'],{ queryParams: { menu: 1 } });
    
          }
        }
        else{
          if(!(localStorage.getItem('patientId'))){
            if(window.location.href.includes('patientslists')){
              window.location.reload();
            }
            else{
              this.router.navigate(['/patientslists']);
      
            }
        }
      }
    }
      
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  onReadNotification(item){
    if(item.notificationTypeId == constantVariables.NotificationTypeid.RequestTour){
      this.router.navigate(['/request-tour'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId),name:this.commonService.FrontEndEncryption(item.comments) } });
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.Audit)
    {
      //this.router.navigateByUrl('/audit-list');
      this.router.navigate(['/audit-clinician'], {
        queryParams: {
          id: this.commonService.FrontEndEncryption(item.patientAccountId),
        },
      });
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.AuthSetting)
      {
        this.router.navigate(['//auth-threshold']);
      }
      // if(item.notificationTypeId == constantVariables.NotificationTypeid.AuthNotificationIsManager)
      // {
      //   this.router.navigateByUrl('/treatmentplan-list');
      // }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.AuthNotificationIsManager){
        this.router.navigate(['/messages']);
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.Email){
        this.router.navigate(['/messages']);
      }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.Email){
      this.router.navigate(['/messages']);
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.RequestReview || item.notificationTypeId == constantVariables.NotificationTypeid.AcceptProposal || item.notificationTypeId == constantVariables.NotificationTypeid.DenyProposal
      || item.notificationTypeId == constantVariables.NotificationTypeid.CancelTour || item.notificationTypeId == constantVariables.NotificationTypeid.MarkTourCompleted){
        if(window.location.href.includes('leadlist')){
        window.location.reload();
      }
      else{
        this.router.navigate(['/leadlist']);
      }
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.ReturnProfile || item.notificationTypeId == constantVariables.NotificationTypeid.ProfileVerified || item.notificationTypeId == constantVariables.NotificationTypeid.SubmitProposal
      || ( item.notificationTypeId == constantVariables.NotificationTypeid.AssignTour && !!(localStorage.getItem('patientId')))){
      //this.router.navigate(['/leadinfo']);
      if( item.notificationTypeId == constantVariables.NotificationTypeid.SubmitProposal ||  item.notificationTypeId == constantVariables.NotificationTypeid.AssignTour){
        localStorage.setItem("profiletab", "5");

      }
      this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId) } });
    }
    if(( item.notificationTypeId == constantVariables.NotificationTypeid.AssignTour && !(localStorage.getItem('patientId'))) || (item.notificationTypeId == constantVariables.NotificationTypeid.RequestTourAssignStaff &&  !(localStorage.getItem('patientId')))){
      if(window.location.href.includes('clinician?tab')){
        window.location.reload();
      }
      else{
      this.router.navigate(['/clinician'],{ queryParams: { tab: 2 } });
      }
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.RescheduleIntakeRequest){
      if(window.location.href.includes('patientslists')){
        window.location.reload();
      }
      else{
        this.router.navigate(['/patientslists']);

      }
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.ScheduleIntake){
      if(!(localStorage.getItem('patientId'))){
        this.router.navigate(['/clinician']);
      }
      else{
        this.router.navigate(['/patient-breifcase']);
      }
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.AcceptReschedule || item.notificationTypeId == constantVariables.NotificationTypeid.DenyReschedule ){
      if(window.location.href.includes('clinician?tab')){
        window.location.reload();
      }
      else{
      this.router.navigate(['/clinician'],{ queryParams: { tab: 2 } });
      }
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.CompleteTreatmentplan){
      if(!(localStorage.getItem('patientId'))){
        if(window.location.href.includes('patientslists')){
          window.location.reload();
        }
        else{
          this.router.navigate(['/patientslists']);
  
        }
      }
      else{
        this.router.navigate(['/patient-breifcase']);
      }
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.ScheduleAppointments){
      if(!(localStorage.getItem('patientId'))){
        if(window.location.href.includes('clinician')){
          window.location.reload();
        }
        else{
          this.router.navigate(['/clinician']);
  
        }
      }
      else{
        this.router.navigate(['/patient-breifcase']);
      }
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.PsyTherapyFollowup){
      if(window.location.href.includes('patientslists')){
        window.location.reload();
      }
      else{
        this.router.navigate(['/patientslists']);
      }
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.GenerateIntakepdf){
      if(window.location.href.includes('clinician?menu=3')){
        window.location.reload();
      }
      else{
        this.router.navigate(['/clinician'],{ queryParams: { menu: 3 } });
      }
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.UpdateBenifits){
      localStorage.setItem("profiletab", "2");
      if(window.location.href.includes('leadinfo')){
        window.location.reload();
      }
      else{
      this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId) } });
      }
    }
    if((item.notificationTypeId == constantVariables.NotificationTypeid.RequestTourAssignStaff &&  !!(localStorage.getItem('patientId'))) || item.notificationTypeId == constantVariables.NotificationTypeid.RescheduleTourRequest || item.notificationTypeId == constantVariables.NotificationTypeid.ChangeStaffTour){
      localStorage.setItem("profiletab", "5");
     
      if(window.location.href.includes('leadinfo')){
        window.location.reload();
      }
      else{
      this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId) } });
      }
    }
    if(item.notificationTypeId == constantVariables.NotificationTypeid.AcceptIntake){
      var access = JSON.parse(localStorage.getItem('roleaccess'));
      //const pageid = constantVariables.pagelists.Goal;
      var roleaccessClinician = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.ProgressNoteFivepointRating);
      if(!(localStorage.getItem('patientId')) && roleaccessClinician.isView){
        if(window.location.href.includes('clinician')){
          window.location.reload();
        }        
        else{
          this.router.navigate(['/clinician'],{ queryParams: { menu: 1 } });
  
        }
      }
      else{
        if(!(localStorage.getItem('patientId'))){
          if(window.location.href.includes('patientslists')){
            window.location.reload();
          }
          else{
            this.router.navigate(['/patientslists']);
    
          }
      }
    }
  }
  if(item.notificationTypeId == constantVariables.NotificationTypeid.PainAssesmentPdf){
    this.router.navigate(['/clinician'],{ queryParams: { menu: 3 }});
  }
  }
  markAllUnreadNofitication() {
    this.notificationmodel.NotificationTypeId = 0;
    this.notificationmodel.RoleId = this.roleId;
    this.notificationmodel.UserId = this.currentUserId;
    const dataToEncrypt = this.commonService.DataToEncrypt(this.notificationmodel, false);
    this.notificationservice.postMarkRead(dataToEncrypt).subscribe(res => {

    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  clearNofitication(item) {
    const dataToEncrypt = this.commonService.DataToEncrypt(item, false);
    this.notificationservice.postClearNotification(dataToEncrypt).subscribe(res => {

    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  clearAllUnreadNofitication() {
    this.notificationmodel.NotificationTypeId = 0;
    this.notificationmodel.RoleId = this.roleId;
    this.notificationmodel.UserId = this.currentUserId;
    const dataToEncrypt = this.commonService.DataToEncrypt(this.notificationmodel, false);
    this.notificationservice.postClearNotification(dataToEncrypt).subscribe(res => {

    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  getfromnow(date) {
    var offset = new Date().getTimezoneOffset();
    
    return moment.utc(date).utcOffset(+offset).fromNow();
  }
}

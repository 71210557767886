import { Component, OnInit, ViewChild, AfterViewInit, ElementRef,ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { NONE_TYPE, ParseTreeResult, viewClassName } from '@angular/compiler';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { PatientService } from 'src/app/patient/patient.service';
import { BillingService } from 'src/app/billing/billing.service';
import { DatePipe } from '@angular/common'
import { cloneDeep } from "lodash";

import {DtoPatientOutofPacketInfoDetailsCompletedLists} from 'src/app/_models/_Billing/dtoPatientOutofPacketDetails';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;
declare function decrypt(data): any;
declare function EncryptFieldData(data): any;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-patient-invoice-lists',
  templateUrl: './patient-invoice-lists.component.html',
  styleUrls: ['./patient-invoice-lists.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PatientInvoiceListsComponent implements OnInit {

  @ViewChild('paymentTransSale') paymentTransSale: NgForm;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  patientInfoId: any;
  patientAccountId: any;
  roleId:any;
  reDirectFrom:any;
  patientOutofPktDtls:any;
  OutofPktPaymentCompletedLists: Array<DtoPatientOutofPacketInfoDetailsCompletedLists>;
  TmpOutofPktPaymentCompletedLists: Array<DtoPatientOutofPacketInfoDetailsCompletedLists>;
  SelectedDate:any;
  today = new Date();
  date = moment(new Date());
  monthStartDate:any;
  monthEndDate:any;
    
  constructor(public global: Global, public settingsService: SettingsService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public patientservice: PatientService,
    public datepipe: DatePipe,public billingservice: BillingService
    
      ) { }

  ngOnInit(): void {
    this.roleId = localStorage.getItem("roleId");
    
    this.route.queryParams.subscribe(params => {
       if (Object.keys(params).length != 0) {
          if(params.id){
            this.patientAccountId=JSON.parse(this.commonService.FrontEndDecryption(params.id));
            }
        } 
    });
        this.OutofPktPaymentCompletedLists=[];
    this.today=new Date();
    this.getOutpofPacketDtls();
     }
    onBackClick(){window.close();}
    getOutpofPacketDtls()
    {
  this.spinnerservice.show();
   this.patientservice.getPatientOutofPacketInvoiceLists(this.patientAccountId).subscribe(
      res => {
        this.spinnerservice.hide();
        if(res.objInputString!=null) {
          let resultdata=this.commonService.decrypdata(res.objInputString);
           this.OutofPktPaymentCompletedLists=resultdata;
           this.TmpOutofPktPaymentCompletedLists=resultdata;
        }
         },
      err => {this.spinnerservice.hide();}
    );
}
setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
   const ctrlValue = this.date;
  ctrlValue.month(normalizedMonthAndYear.month());
  ctrlValue.year(normalizedMonthAndYear.year());
  this.SelectedDate = ctrlValue.toDate();
  datepicker.close();
  this.SearchbyDate();
}
SearchbyDate(){
  if(this.SelectedDate != undefined && this.SelectedDate != "" && this.SelectedDate != "undefined")
  {
    this.monthStartDate = this.datepipe.transform(new Date(this.SelectedDate.getFullYear(), this.SelectedDate.getMonth(), 1,0),"yyyy-MM-dd");
    this.monthEndDate = this.datepipe.transform(new Date(this.SelectedDate.getFullYear(), this.SelectedDate.getMonth()+1,0),"yyyy-MM-dd");
    this.OutofPktPaymentCompletedLists=this.TmpOutofPktPaymentCompletedLists;
    this.OutofPktPaymentCompletedLists=this.OutofPktPaymentCompletedLists.filter(
    m => this.datepipe.transform(m.PaidOn, 'yyyy-MM-dd')>=this.datepipe.transform(this.monthStartDate, 'yyyy-MM-dd')
        &&
       this.datepipe.transform(m.PaidOn, 'yyyy-MM-dd')<=this.datepipe.transform(this.monthEndDate, 'yyyy-MM-dd'));
   }
}



}

<!-- <div class="sb-nav-fixed">
    <app-site-header></app-site-header>
    <div id="layoutSidenav">
        <div id="layoutSidenav_content">
            <main>

                <div class="main-content">
                    <router-outlet></router-outlet>
                    <div class="physician-Dash position-relative clearfix">
                        <div class="sh-side-options" style="transition: all 0.3s ease-in-out 0s;">
                            <div class="sh-side-options-container">

                                <a class="sh-side-options-item sh-accent-color" [ngClass]="{'active':selectedMenu ==1}"
                                    (click)="onTabChange(1)">
                                    <div class="sh-side-options-item-container">
                                        <i class="fas fa-tasks color-c1"></i>
                                    </div>
                                    <div class="sh-side-options-hover">
                                        My Medical Appointment
                                    </div>
                                </a>


                                <a class="sh-side-options-item sh-side-options-item-trigger-demos sh-accent-color"
                                    [ngClass]="{'active':selectedMenu ==2}" (click)="onTabChange(2)">
                                    <div class="sh-side-options-item-container">
                                        <i class="fas fa-th-list color-c2"></i>
                                    </div>
                                    <div class="sh-side-options-hover">
                                        My Task
                                    </div>
                                </a>
                                <a class="sh-side-options-item sh-accent-color" [ngClass]="{'active':selectedMenu ==3}"
                                    (click)="onTabChange(3)">
                                    <div class="sh-side-options-item-container">
                                        <i class="fas fa-notes-medical color-c6"></i>
                                    </div>
                                    <div class="sh-side-options-hover" style="white-space: nowrap;">
                                        Treatement Plan
                                    </div>
                                </a>
                                <a target="blank" href="https://support.shufflehound.com/pre-sale-questions/"
                                    class="sh-side-options-item sh-accent-color">
                                    <div class="sh-side-options-item-container">
                                        <i class="fas fa-exclamation-triangle color-c1"></i>
                                    </div>
                                    <div class="sh-side-options-hover" style="white-space: nowrap;">
                                        Incident
                                    </div>
                                </a>
                                <a target="blank" href="https://support.shufflehound.com/pre-sale-questions/"
                                    class="sh-side-options-item sh-accent-color">
                                    <div class="sh-side-options-item-container">

                                        <i class="fas fa-clipboard-list color-c7"></i>
                                    </div>
                                    <div class="sh-side-options-hover" style="white-space: nowrap;">
                                        Discharge Summary
                                    </div>
                                </a>
                                <a href="//jevelin.shufflehound.com/hello/#questions"
                                    class="sh-side-options-item sh-accent-color">
                                    <div class="sh-side-options-item-container">
                                        <i class="fas fa-calendar-alt color-c3"></i>
                                    </div>
                                    <div class="sh-side-options-hover">
                                        My Calendar
                                    </div>
                                </a>
                            </div>


                            <div class="sh-side-demos-container">
                                <div class="sh-side-demos-container-close">
                                    <a><i class="fas fa-times"></i></a>
                                </div>

                                <div class="sh-side-demos-intro">

                                    <p class="sh-side-demos-intro-descr sh-heading-font">
                                        Choose one of option to Create
                                    </p>
                                </div>
                                <div class="sh-side-demos-loop">
                                    <div class="sh-side-demos-loop-container">
                                        <div class="col-md-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <a class="tiles">Create Task</a>
                                                </div>
                                                <div class="col-md-12">
                                                    <a class="tiles">Create Patients</a>
                                                </div>
                                                <div class="col-md-12">
                                                    <a class="tiles">Create Incident</a>
                                                </div>
                                                <div class="col-md-12">
                                                    <a class="tiles">Create Discharge Summary</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>

        </div>
    </div>
</div> -->
<!--  -->
<div class="physician-Dash position-relative clearfix" *ngIf="(MenuAccess.ProgressNoteFivepointRating || MenuAccess.IncidentReport || MenuAccess.DischargeSummary || MenuAccess.TreatmentPlan)">
    <div class="sh-side-options" style="transition: all 0.3s ease-in-out 0s;">
        <div class="sh-side-options-container">

            <a class="sh-side-options-item sh-accent-color" [ngClass]="{'active':selectedMenu ==1}"
            (click)="selectedMenu = 1" *ngIf="MenuAccess.ProgressNoteFivepointRating">
                <div class="sh-side-options-item-container">
                    <i class="fas fa-calendar-alt color-c3"></i>
                </div>
                <div class="sh-side-options-hover">
                    My Calendar
                </div>
            </a>


            <a class="sh-side-options-item sh-side-options-item-trigger-demos sh-accent-color"
                [ngClass]="{'active':selectedMenu ==2}"  (click)="selectedMenu = 2" *ngIf="MenuAccess.Task">
                <div class="sh-side-options-item-container">
                    <!-- <i class="fas fa-th-list color-c2"></i> -->
                    <i class="fas fa-tasks color-c1"></i>
                </div>
                <div class="sh-side-options-hover">
                  My Tasks
                </div>
            </a>
            <a class="sh-side-options-item sh-accent-color" [ngClass]="{'active':selectedMenu ==3}"
                (click)="selectedMenu = 3" (click)="resetPaginator()" *ngIf="MenuAccess.TreatmentPlan"> 
                <div class="sh-side-options-item-container">
                    <i class="fas fa-notes-medical color-c2" ></i>
                </div>
                <div class="sh-side-options-hover" style="white-space: nowrap;">
                    Treatment
                </div>
            </a>
            <a
                class="sh-side-options-item sh-accent-color" [ngClass]="{'active':selectedMenu ==4}"
                (click)="selectedMenu = 4" *ngIf="MenuAccess.IncidentReport">
                <div class="sh-side-options-item-container">
                    <i class="fas fa-exclamation-triangle color-c6"></i>
                </div>
                <div class="sh-side-options-hover" style="white-space: nowrap;">
                    Incident
                </div>
            </a>
            <a
                class="sh-side-options-item sh-accent-color" [ngClass]="{'active':selectedMenu ==5}"
                (click)="selectedMenu = 5" *ngIf="MenuAccess.DischargeSummary">
                <div class="sh-side-options-item-container">

                    <i class="fas fa-clipboard-list color-c7"></i>
                </div>
                <div class="sh-side-options-hover" style="white-space: nowrap;">
                    Discharge
                </div>
            </a>

            <a class="sh-side-options-item sh-accent-color" [ngClass]="{'active':selectedMenu == 6}"
            (click)="onTabChange(6)">
                <div class="sh-side-options-item-container">
                    <i class="fas fa-pen color-c3"></i>
                </div>
                <div class="sh-side-options-hover">
                    Pending Documents
                </div>
            </a>


            <!-- <a
                class="sh-side-options-item sh-accent-color">
                <div class="sh-side-options-item-container">
                    <i class="fas fa-calendar-alt color-c3"></i>
                </div>
                <div class="sh-side-options-hover">
                    My Calendar
                </div>
            </a> -->
        </div>


        <div class="sh-side-demos-container">
            <div class="sh-side-demos-container-close">
                <a><i class="fas fa-times"></i></a>
            </div>

            <div class="sh-side-demos-intro">

                <p class="sh-side-demos-intro-descr sh-heading-font">
                    Choose one of option to Create
                </p>
            </div>
            <div class="sh-side-demos-loop">
                <div class="sh-side-demos-loop-container">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <a class="tiles">Create Task</a>
                            </div>
                            <div class="col-md-12">
                                <a class="tiles">Create Patients</a>
                            </div>
                            <div class="col-md-12">
                                <a class="tiles">Create Incident</a>
                            </div>
                            <div class="col-md-12">
                                <a class="tiles">Create Discharge Summary</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div *ngIf="selectedMenu == 1">
    <app-clinician-mytask></app-clinician-mytask>
</div>
<div *ngIf="selectedMenu == 2">
    <app-task-info></app-task-info>
</div>
<div *ngIf="selectedMenu == 3">
    <app-treatmentplan-list></app-treatmentplan-list>
</div>
<div *ngIf="selectedMenu == 4">
    <app-incident-reporting-list></app-incident-reporting-list>
</div>
<div *ngIf="selectedMenu == 5">
  <app-discharge-summary-list></app-discharge-summary-list>
</div>
<div *ngIf="selectedMenu == 6">
    <app-pending-documents></app-pending-documents>
  </div>

<!-- <div class="physician-Dash position-relative clearfix">
        <div class="sh-side-options" style="transition: all 0.3s ease-in-out 0s;">
            <div class="sh-side-options-container">

                <a class="sh-side-options-item sh-accent-color" [ngClass]="{'active':selectedMenu ==1}" (click)="onTabChange(1)">
                    <div class="sh-side-options-item-container">
                        <i class="fas fa-tasks color-c1"></i>
                    </div>
                    <div class="sh-side-options-hover">
                        My Appoinments
                    </div>
                </a>


                <a class="sh-side-options-item sh-side-options-item-trigger-demos sh-accent-color" [ngClass]="{'active':selectedMenu ==2}"
                    (click)="onTabChange(2)">
                    <div class="sh-side-options-item-container">
                        <i class="fas fa-th-list color-c2"></i>
                    </div>
                    <div class="sh-side-options-hover">
                        Task
                    </div>
                </a>
                <a class="sh-side-options-item sh-accent-color" [ngClass]="{'active':selectedMenu ==3}" (click)="onTabChange(3)">
                    <div class="sh-side-options-item-container">
                        <i class="fas fa-notes-medical color-c6"></i>
                    </div>
                    <div class="sh-side-options-hover" style="white-space: nowrap;">
                        Treatement Plan
                    </div>
                </a>
                <a target="blank" href="https://support.shufflehound.com/pre-sale-questions/"
                    class="sh-side-options-item sh-accent-color">
                    <div class="sh-side-options-item-container">
                        <i class="fas fa-exclamation-triangle color-c1"></i>
                    </div>
                    <div class="sh-side-options-hover" style="white-space: nowrap;">
                        Incident
                    </div>
                </a>
                <a target="blank" href="https://support.shufflehound.com/pre-sale-questions/"
                    class="sh-side-options-item sh-accent-color">
                    <div class="sh-side-options-item-container">

                        <i class="fas fa-clipboard-list color-c7"></i>
                    </div>
                    <div class="sh-side-options-hover" style="white-space: nowrap;">
                        Discharge Summary
                    </div>
                </a>
                <a href="//jevelin.shufflehound.com/hello/#questions" class="sh-side-options-item sh-accent-color">
                    <div class="sh-side-options-item-container">
                        <i class="fas fa-calendar-alt color-c3"></i>
                    </div>
                    <div class="sh-side-options-hover">
                        My Calendar
                    </div>
                </a>
            </div>


            <div class="sh-side-demos-container">
                <div class="sh-side-demos-container-close">
                    <a><i class="fas fa-times"></i></a>
                </div>

                <div class="sh-side-demos-intro">

                    <p class="sh-side-demos-intro-descr sh-heading-font">
                        Choose one of option to Create
                    </p>
                </div>
                <div class="sh-side-demos-loop">
                    <div class="sh-side-demos-loop-container">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <a class="tiles">Create Task</a>
                                </div>
                                <div class="col-md-12">
                                    <a class="tiles">Create Patients</a>
                                </div>
                                <div class="col-md-12">
                                    <a class="tiles">Create Incident</a>
                                </div>
                                <div class="col-md-12">
                                    <a class="tiles">Create Discharge Summary</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>        -->

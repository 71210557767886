import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SpinnerService } from 'src/app/services/spinnerservice.service';
import { CommonService } from 'src/app/common/common.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { I, X, Y } from '@angular/cdk/keycodes';
import { th } from 'date-fns/locale';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { benchmarkmodel } from 'src/app/_models/_Treatment/_benchmarkMeasures';

@Component({
  selector: 'app-add-edit-bench-pop-up',
  templateUrl: './add-edit-bench-pop-up.component.html',
  styleUrls: ['./add-edit-bench-pop-up.component.scss']
})
export class AddEditBenchPopUpComponent implements OnInit {
  @ViewChild('Benchmarkfrm') Benchmarkfrm: NgForm;
  @Input() Benchmarkfrminfo:benchmarkmodel;
  @Input()  status:any;
  @Input() Otherbenchmarks: any;
  EditBenchmark: boolean;
  IsShowStatus: boolean;
  PercentageBased = constantVariables.MeasureTypeName.Percentagebased;
  Timebased = constantVariables.MeasureTypeName.Timebased;
  constructor(private dialog:MatDialog,public spinnerservice:NgxSpinnerService
    ,private treatmentservice:TreatmentService,private snackbar:SnackBarService,
    private route:ActivatedRoute,public commonService: CommonService) { }

  ngOnInit(): void {
    if(this.status == 2){
      this.IsShowStatus = true
    }
  }
  OnpostBenchmark(){
    if(!this.Benchmarkfrm.valid){
      return
    }
    else{
    this.Benchmarkfrminfo.Status = this.status
    if(this.Otherbenchmarks!=null){
    this.Benchmarkfrminfo.OtherBenchamarkId = this.Otherbenchmarks;
    }
    if(this.status==2){
      this.Benchmarkfrminfo.TrackBenchIsSelected = true;
    }
    else if(this.status == 1){
      this.Benchmarkfrminfo.TrackBenchIsSelected = false;
  
    }
   // this.Benchmarkfrminfo.Benchmarkunit = Number(this.Benchmarkfrminfo.Benchmarkunit)
  const dataToEncrypt = this.commonService.DataToEncrypt(this.Benchmarkfrminfo, false);
  this.spinnerservice.show();
  //console.log(this._dtoPatientInfo);
  this.treatmentservice.PostTreatmentBenchmark(this.Benchmarkfrminfo).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      this.snackbar.success(JSON.parse(res).message);
      this.EditBenchmark = false;
      this.IsShowStatus = false;
      this.onClosemodal();
     // this.getTreatmentGoalsList()
    },
    err => {
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
    }
  }

  onClosemodal(){
    this.commonService.BenchmarkPopUp.next(false);
  }

  Onstatuschange(item:any){
    if(item ==1){
    this.status = 2
    }
    else{
      this.status = 3
    }
  }
}

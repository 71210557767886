import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ParentInfo } from 'src/app/_models/_Lead/parentInfo';
import { PatientInfo } from 'src/app/_models/_Lead/patientInfo';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { LeadService } from 'src/app/patient-lead/lead.service'
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { EsignDialogComponent } from 'src/app/common/esign-dialog/esign-dialog.component';
import { is } from 'date-fns/locale';
declare function decrypt(data): any;
declare function EncryptFieldData(data): any;
import { TemplateService } from 'src/app/common/pages/template/template.service';
@Component({
  selector: 'app-lead-account',
  templateUrl: './lead-account.component.html',
  styleUrls: ['./lead-account.component.scss']
})
export class LeadAccountComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  leadAccount: NgForm; // f is nothing but the template reference of the Template Driven Form
  @ViewChild('leadAccount') currentForm: NgForm;
  // @ViewChild('parentform', { static: true }) ngForm: NgForm;
  //@ViewChild('leadAccount') leadAccount1: FormGroupDirective;
  displayedColumns = ['position', 'FirstName', 'LastName', 'Email', 'ContactNo', 'ParentalRelation', 'IsPrimaryContact', 'actions']; // // for table
  dataSource: MatTableDataSource<any>;
  patientModel: PatientInfo;
  parentModel: any;
  ddlgender: any;
  ddlSoucre: any;
  ddlLeadType: any;
  ddlfacilityType: any;
  ddlparentalRelationType: any;
  lstParentInfo: any = [];
  parentIndex: any;
  parentalRelationModel: any;

  isEditParent: boolean = false;
  isValidFlg: boolean = true;
  isAgeGroup = false;
  patientAge: number;
  ChangePasswordData = {
    Email: null,
    Password: null,
    IsResetPassword: null,
    Token: null,
    PasswordID: null
  };
  islistAdd = false;

  constructor(public leadservice: LeadService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, public spinnerservice: NgxSpinnerService,public tempService: TemplateService) {
    
     }

  ngOnInit(): void {

    this.patientModel = new PatientInfo();
    this.parentModel = new ParentInfo();
    this.ddlgender = constantVariables.gender;
    this.getddlMaster();
    this.getddlLocation();
    this.patientModel.FacilityTypeId = Number(localStorage.getItem("sfcclocation")) == 0? '':Number(localStorage.getItem("sfcclocation"));
    this.parentModel.IsPrimaryContact=true;
  }

  getddlLocation() {
    this.tempService.getLocationList().subscribe(
      res => {
        if (res != null) {
        //  debugger;
          this.ddlfacilityType = res.filter(m => m.sfccMasterId !== -1 && m.sfccMasterId !== 0);
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  getddlMaster() {
    this.spinnerservice.show();
    this.leadservice.getAllMaster().subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this.ddlSoucre = decrypt(res.source);
          this.ddlLeadType = decrypt(res.leadType);
          //this.ddlfacilityType = decrypt(res.facilityType);
          this.ddlparentalRelationType = decrypt(res.parentalRelationType);
        }
      },
      err => {

      },
    );
  }
  calculateAge(birthdate: any) {
    this.isAgeGroup = false;
    this.patientAge = moment().diff(birthdate, 'years');
    if (this.patientAge >= 5 && this.patientAge <= 18) {
      this.patientModel.IsMinor = true;
    }
    else {
      this.isAgeGroup = true;
      this.patientModel.IsMinor = false;
    }
    this.patientModel.Age = this.patientAge;

  }

  addleadAccount() {
     if ((this.patientModel.Age < 1 )) {
      this.snackbar.error(constantVariables.ErrorMessage.dobvalidationremove);
      return
    }

    // if (this.isAgeGroup == true) {
    //   this.snackbar.error(constantVariables.ErrorMessage.dobvalidation);

    //   return
    // }
    // Age validation remove start
    // if (!(this.patientModel.Age >= 3 && this.patientModel.Age <= 18)) {
    //   this.snackbar.error(constantVariables.ErrorMessage.dobvalidation);

    //   return
    // }
    // Age validation remove end

    // if ((this.lstParentInfo == undefined) || (this.lstParentInfo && this.lstParentInfo.length == 0)) {

    //   this.snackbar.error(constantVariables.ErrorMessage.primaryparent);
    //   return
    // }
    // const isPrimary = this.lstParentInfo.filter(item => item.IsPrimaryContact == true).length;
    // if (isPrimary == 0) {
    //   this.snackbar.error(constantVariables.ErrorMessage.primaryparent);
    //   return
    // }

    var list_parent = [];
    if(this.lstParentInfo.length>0) {
      list_parent = this.lstParentInfo;
    } else {
      let prnModal = this.parentModel;
      prnModal.ParentalRelation = 'Aunt/Uncle',
      prnModal.IsPrimaryContact=true,
      prnModal.ParentInfoId = 0,
      prnModal.IsActive = true
      list_parent = [prnModal]
    }   

    this.spinnerservice.show();
    let _obj: any = {
      ObjDtoPatientInfo: this.patientModel,
      ObjDtoParentInfo:list_parent
    }

    // const dataToEncrypt = {
    //   ObjInputString: EncryptFieldData(JSON.stringify(_obj))
    // };
    this.leadservice.postLeadAccount(_obj).subscribe(res => {

      if (res) {

        this.spinnerservice.hide();
        this.snackbar.success(res);
        this.router.navigate(['/leadlist']);
      }
    },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);

      }
    );
  }

  addparent(ngform: NgForm) {
    this.islistAdd = true;
    this.spinnerservice.show();
    let frmvalues = ngform.value;
    var ParentalRelationName = this.ddlparentalRelationType.find(x => x.ParentalRelationTypeId == frmvalues.parentParentalRelationTypeId).ParentalRelationName;
    this.ChangePasswordData.Email = EncryptFieldData(frmvalues.parentEmail);

    this.leadservice.getIsEmailExist(this.ChangePasswordData).subscribe(
      res => {
        let obj: any = {
          ParentInfoId: 0,
          FirstName: frmvalues.parentFirstName,
          LastName: frmvalues.parentLastName,
          Email: frmvalues.parentEmail,
          ContactNo: frmvalues.parentContactNo,
          ParentalRelationTypeId: frmvalues.parentParentalRelationTypeId,
          ParentalRelation: ParentalRelationName,
          IsPrimaryContact: frmvalues.parentIsPrimaryContact
        };
        if (!this.isEditParent) {
          if (this.lstParentInfo == undefined) {
            this.lstParentInfo = [obj]
            this.snackbar.success(constantVariables.SuccessMessage.parentadd);
          }
          else {
            const isObjectPresent = this.lstParentInfo.find((o) => o.IsPrimaryContact === true);
            if ((!isObjectPresent && frmvalues.parentIsPrimaryContact == false) || (frmvalues.parentIsPrimaryContact == false || frmvalues.parentIsPrimaryContact == null)) {           // As find return object else undefined
              this.lstParentInfo.push(obj)
              this.snackbar.success(constantVariables.SuccessMessage.parentadd);
            }
            else {
              if (isObjectPresent == undefined && frmvalues.parentIsPrimaryContact == true) {
                this.lstParentInfo.push(obj);
                this.snackbar.success(constantVariables.SuccessMessage.parentadd);
              }
              else {
                this.pushParentPrimary(obj, frmvalues, this.isEditParent, this.parentIndex)

              }
            }

          }

        }
        else {
          const isObjectPresent = this.lstParentInfo.find((o) => o.IsPrimaryContact === true);
          if (isObjectPresent == undefined) {
            this.lstParentInfo[this.parentIndex] = obj;
           this.snackbar.success(constantVariables.SuccessMessage.parentUpdate)
          } else {
            this.pushParentPrimary(obj, frmvalues, this.isEditParent, this.parentIndex)

          }

          this.isEditParent = false;
          this.parentIndex = null;
          ngform.form.reset();
        }
        this.dataSource = new MatTableDataSource(this.lstParentInfo);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        ngform.resetForm();

        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
        var errcode = JSON.parse(err.error).code;
        this.snackbar.error(errcode);
      },
    );

  }
  pushParentPrimary(obj, frmvalues, isEditParent, parentIndex) {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: 'would you like to add "' + frmvalues.parentFirstName + '" as primary account ?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        let itemIndex = this.lstParentInfo.findIndex(item => item.IsPrimaryContact == true);
        if (itemIndex != -1)
          this.lstParentInfo[itemIndex].IsPrimaryContact = false;
        if (!isEditParent) {
          this.lstParentInfo.push(obj);
          this.snackbar.success(constantVariables.SuccessMessage.parentadd)
        } else {
          this.lstParentInfo[parentIndex] = obj;
        }
        this.snackbar.success(constantVariables.SuccessMessage.parentUpdate);
        this.dataSource = new MatTableDataSource(this.lstParentInfo);
      } else {
        obj.IsPrimaryContact = this.lstParentInfo.filter(item => item.IsPrimaryContact == true).length > 0 ? false : true;
        if (!isEditParent) {
          this.lstParentInfo.push(obj);
        } else {
          this.lstParentInfo[parentIndex] = obj;
        }
        this.snackbar.success(constantVariables.SuccessMessage.parentUpdate);
        this.dataSource = new MatTableDataSource(this.lstParentInfo);
        return
      }
    });

  }
  editParentForm(element, index) {
    this.isEditParent = true;
    this.parentIndex = index;
    this.parentModel = element;
  }

  deleteParentForm(element, index) {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        const data = this.dataSource.data;
        data.splice((this.paginator.pageIndex * this.paginator.pageSize) + index, 1);
        this.dataSource.data = data;

        this.snackbar.success(constantVariables.SuccessMessage.parenDelete)
      }
    });
  }


}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { LeadService } from 'src/app/patient-lead/lead.service'
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as moment from 'moment';
import "quill-mention";
import { notesinfo } from 'src/app/_models/_Common/notes';
import { userstag } from 'src/app/_models/_Common/notes'
import { cloneDeep } from "lodash";
import { NotesDialogComponent } from 'src/app/common/notes-dialog/notes-dialog.component';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
@Component({
  selector: 'app-notes-add',
  templateUrl: './notes-add.component.html',
  styleUrls: ['./notes-add.component.scss']
})
export class NotesAddComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  dataSource: MatTableDataSource<any>;
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],                                       // remove formatting button


      ],

    },


    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@"],
      source: (searchTerm, renderList, mentionChar) => {
        let values;

        if (mentionChar === "@") {
          values = this.atValues;
        }

        if (searchTerm.length === 0) {

          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (var i = 0; i < values.length; i++) {
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
          }
          renderList(matches, searchTerm);
        }
      },
      onSelect(item, insertItem) {
        
        //this.assignUserTags(item);
        insertItem(item)

      },
    },

    keyboard: {
      bindings: {
        shiftEnter: {
          key: 13,
          shiftKey: true,
          handler: (range, context) => {
            // Handle shift+enter
           // console.log("shift+enter")
          }
        },
        enter: {
          key: 13,
          handler: (range, context) => {
           // console.log("enter");
            return true;
          }
        }
      }
    }
  }
  notes: notesinfo;
  parentlst: any;
  patientInfoId: any;
  patientnotes: any;
  isEditNotes: boolean = false;
  notesids: any = [];
  atValues: any = [];
  Decrptedlst:any;
  pagefrom:any;
  usertags:any=[];
  isCreateNotes: boolean = false;
  roleAccess: RoelAccess;
  constructor(public leadservice: LeadService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    //const pageid = constantVariables.pagelists.Notes;
   // this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        this.pagefrom = params.PageId;
        this.patientInfoId = this.commonService.FrontEndDecryption(params.id);
        
        this.getNotes();
      }
    });
    this.notes = new notesinfo();
    this.notes.tags = new userstag();
    this.getActiveParentList();
  }
  getActiveParentList() {
    this.leadservice.getActiveParentlst().subscribe(
      (res: any) => {
        if (res) {
          this.parentlst = res;
          for (var i = 0; i < this.parentlst.length; i++) {
            let _obj = {
              id: this.parentlst[i].patientInfoId,
              value: this.parentlst[i].email + ";"
            }
            this.atValues.push(_obj);
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  addnote(frmnote: NgForm) {
    // if (this.usertags && this.usertags.length == 0) {
    //   this.snackbar.error(constantVariables.ErrorMessage.usertag);
    //   return
    // }
    
    this.notes.patientInfoId = this.patientInfoId;
    this.notes.tags = this.usertags;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.notes, false);
    this.leadservice.postNotes(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        frmnote.resetForm();
        this.resetnotesform();
        this.snackbar.success(JSON.parse(res).message);
        this.getNotes();
      }
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  resetnotesform() {
    this.notes = new notesinfo();
    this.notes.tags = new userstag();
    this.isCreateNotes = false;
    this.isEditNotes = false;
  }
  getNotes() {
    this.leadservice.getNotes(this.patientInfoId).subscribe(
      (res: any) => {
        if (res) {
          this.Decrptedlst = res;
          this.patientnotes = this.Decrptedlst.slice(0, 5);
          this.dataSource = new MatTableDataSource(this.Decrptedlst);
          this.dataSource.paginator = this.paginator;
         // console.log(this.patientnotes)
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onPageChange($event) {

    this.patientnotes = this.Decrptedlst.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  oneditNotes(element) {
    this.isEditNotes = true;
    this.isCreateNotes =true;
    this.notes = cloneDeep(element)
  }
  ondeletedNotes(item) {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.leadservice.deleteNote(item.notesId).subscribe(
          res => {
            this.spinnerservice.hide();
            this.snackbar.success(res.message);
            this.getNotes();


          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );
      }
    });
  }

  onPinNotes(item) {
   // debugger;
    this.spinnerservice.show();
    this.leadservice.pinNote(item.notesId).subscribe(
      res => {
        this.spinnerservice.hide();
        if(item.isPin == true)
        {
          this.snackbar.success('Note tagged as unpin successfully');
        }
        else
        {
          this.snackbar.success('Note tagged as pin successfully');
        }
        this.getNotes();


      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);

      },
    );
  }

  cbklistnotes(event, item, index) {
    if (event.checked) {
      this.notesids.push(item.notesId);
    } else {
      this.notesids.forEach((x, index) => {
        if (x === item.notesId) this.notesids.splice(index, 1);
      });
    }
  }
  ondeletemultipleNotes() {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        const dataToEncrypt = this.commonService.DataToEncrypt(this.notesids, false);
        this.leadservice.deleteNotes(dataToEncrypt).subscribe(
          (res: any) => {
            this.notesids = [];
            this.snackbar.success(JSON.parse(res).message);
            this.getNotes();
            this.spinnerservice.hide();

          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );

      }
    });

  }
  // userselected(event): void {
  //   var filterdvalue = this.usertags.filter(x => x.email === event.option.value.email)
  //   if (filterdvalue.length == 0) {
  //     this.usertags.push(event.option.value);
  //   }
  // }
  // removeUsertags(text: any): void {
  //   if (text.notesTagUserId == 0 || text.notesTagUserId == undefined) {
  //     const index = this.usertags.indexOf(text);
  //     if (index >= 0) {
  //       this.usertags.splice(index, 1);
  //     }
  //   } else {
  //     this.leadservice.removeTag(text.notesTagUserId).subscribe(
  //       res => {
  //         const index = this.usertags.indexOf(text);
  //         if (index >= 0) {
  //           this.usertags.splice(index, 1);
  //         }
  //         this.spinnerservice.hide();

  //       },
  //       err => {
  //         this.spinnerservice.hide();
  //         this.snackbar.error(err.error);

  //       },
  //     );
  //   }
  // }
  // assignUserTags(item) {
  //   if (this.usertags) {
  //     var filterdvalue = this.usertags.filter(x => x.email === item.value)
  //     if (filterdvalue.length == 0) {
  //       let _obj = {
  //         patientInfoId: item.id,
  //         email: item.value
  //       }
  //       this.usertags.push(_obj);

  //     }
  //   } else {

  //     let _obj = {
  //       patientInfoId: item.id,
  //       email: item.value
  //     }
  //     this.usertags = [_obj];

  //   }
  // }
  onCreateNotes(){
    this.isCreateNotes = !this.isCreateNotes;
  }


  openDialog(data) {
    //console.log(this.patientInfoId)
    //console.log(data.notesId)
    const dialogRef = this.dialog.open(NotesDialogComponent,
      { data: { parentinfoId: this.patientInfoId, notesId : data.notesId} });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}


 <div class="incident-list pl-5">
    <div  class="d-flex"><div  class="head-section mt-3 mb-2">Incident Report</div>
    <div  class="ml-auto d-flex "> 
      <div  class="flex-grow-1 p-0">
        
      </div>
      
      <div  class=" ml-2 mt-1">
        <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2"    [disabled]="incidentids?.length == 0 " (click)="OnCloseMultipleIncidents()" mat-stroked-button  *ngIf="roleAccess.isApprove && roleAccess.isView">Mark as closed </button>
      <button class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2" (click)="OnCreateIncident()" mat-stroked-button  *ngIf="roleAccess.isCreate">Report Incident </button></div></div>
    
    </div>
    <div class="col-md-12 bg-white p-2">
      <div  class="d-flex" *ngIf="roleAccess.isView">
        <div class="col-md-3 pl-0">
        <div  class="search ">
          <input  type="text" placeholder="Search By Client" name="" class="search-input" [(ngModel)]="searchReporttxt" (keyup)="applyFilter($event)">
          <a  class="search-icon"><i  class="fa fa-search"></i>
          </a>
        </div>
      </div>
      <div  class="ml-auto mt-2">
        <span  class="filter-icon mr-2">
          <i  class="fa fa-filter"></i>
        </span>
        <div  class="filter-group d-inline-block">
          <label  class="d-inline-block mr-2">Reported by</label>
          <select  name="Meeting" class="form-control d-inline-block status-filter" [(ngModel)]="SelectedReportedBy" (change)="OnIRlistFilter(0)">
            <option  [value]="0">--Select --</option>
            <option *ngFor="let staff of ddlReportedBy let i=index;" [value]="staff.userId">{{staff.staffName}}</option>
           
            </select>
          </div>
          <div  class="filter-group d-inline-block">
            <label  class="d-inline-block mr-2">Status</label>
            <select  class="form-control d-inline-block status-filter"  [(ngModel)]="SelectedStatus" (change)="OnIRlistFilter(0)">
              <option [value]="0" >Any</option>
              <option [value]="1" >New</option>
              <option [value]="2">Inprogress</option>
              <option [value]="3">Closed</option>
            </select>
          </div>
          <div  class="filter-group d-inline-block">
            <label  class="d-inline-block mr-2">Clinician</label>
            <select  class="form-control d-inline-block status-filter" [(ngModel)]="SelectedClinician" (change)="OnIRlistFilter(0)">
              <option [value]="0" >--Select --</option>
              <option *ngFor="let staff of ddlstafflist let i=index;" [value]="staff.userId">{{staff.staffName}}</option>
            </select>
          </div>
          <div  class=" filter-group d-inline-block">
            <label  class="d-inline-block mr-2">Therapy</label>
            <select  class="form-control d-inline-block status-filter mr-0" [(ngModel)]="selectedTheraphy" (change)="OnIRlistFilter(1)">
              <option  [value]="-1">--Select --</option>
              <option *ngFor="let item of ddlTherapy let i=index" [value]="item.TherapyId"> {{item.TherapyName}} </option>
              <option   [value]="0" >
                Others
            </option>
            </select>
          </div>
          <div  class=" filter-group d-inline-block">
            <label  class="d-inline-block mr-2">Incident Type</label>
            <select  class="form-control d-inline-block status-filter mr-0" [(ngModel)]="selectInctype" (change)="OnIRlistFilter(0)">
              <option  [value]="0">--Select --</option>
              <option  *ngFor="let item of ddlIncidenType let i=index" [value]="item.IncidentReportTypeId">{{item.IncidentReportType1}}</option>
              
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div  class="table-custom incident-table">
          <div  class="table-responsive" *ngIf="roleAccess.isView">
            <!--<table class="table">
              <thead>
                <tr>
                  <th >
                    
                </th>
                  <th scope="col">Patient name</th>
                  <th scope="col">Reported by</th>
                  <th scope="col">Therapy/Program</th>
                  <th scope="col">Clinician</th>
                  <th scope="col">Incident Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th>
                 
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of tempIncidentReportList | grdFilter:  searchReporttxt:'IncidentreportList'; let i=index;">
                  <td ><div class="check-custom">
                    <mat-checkbox class="example-margin" (change)="cbklistIncidentReport($event,item, i)" name="item{{i}}"></mat-checkbox>
                  </div></td>
                  <td> {{item.patientName}}</td>
                  <td> {{item.reportedByName}} </td>
                  <td> {{item.therapyName}} </td>
                  <td> {{item.clinicianName}} </td>
                  <td> {{item.incidentDate | date:'dd/MM/yyyy'}} </td>
                  <td> <span class="badge "
                    [ngClass]="{'badge-primary':item.status == 1,'badge-warning':item.status == 2,'badge-success':item.status == 3}">{{item.status==1?'New':item.status==2?'In Progress':'Closed'}}</span>
                  </td>
                  <td> <a class="mr-2" title="{{ RoleId!=1?'View':'Review'}}" (click)="OnviewReport(item)"><i aria-hidden="true" class="fa fa-eye"></i></a> </td>
                </tr>
              </tbody>
            </table>-->


            <!--Mat Table with pagination-->
            <mat-table #table [dataSource]="IncReportDatasource"  class="mat-elevation-z8 w-100" matSort>
              <ng-container matColumnDef="sno">
                <mat-header-cell *matHeaderCellDef > </mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index;"><div class="check-custom">
                  <mat-checkbox class="example-margin" (change)="cbklistIncidentReport($event,element, i)" name="element{{i}}"></mat-checkbox>
                </div></mat-cell>
              </ng-container>
              <ng-container matColumnDef="PatientName">
                <mat-header-cell *matHeaderCellDef > Client </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.patientName}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Reportedby">
                <mat-header-cell *matHeaderCellDef > Program </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.reportedByName}} </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="Therapy/Program">
                <mat-header-cell *matHeaderCellDef > Therapy</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.therapyName}} </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="Clinician">
                <mat-header-cell *matHeaderCellDef >Clinician </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.clinicianName}} </mat-cell>
              </ng-container>
    
              <ng-container matColumnDef="IncidentDate">
                <mat-header-cell *matHeaderCellDef >Incident Date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.incidentDate | date:'MM/dd/yyyy'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="IncidentType">
                <mat-header-cell *matHeaderCellDef >Incident Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.incidentReportType}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Status">
                <mat-header-cell *matHeaderCellDef > Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> <span class="badge "
                  [ngClass]="{'badge-primary':element.status == 1,'badge-warning':element.status == 2,'badge-success':element.status == 3}">{{element.status==1?'New':element.status==2?'In Progress':'Closed'}}</span></mat-cell>
              </ng-container>
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>{{ roleAccess.isView ? 'Actions' : '' }} </mat-header-cell>
                <mat-cell *matCellDef="let element;let i = index;">
                  <a class="mr-2" title="{{ RoleId!=1?'View':'Review'}}" (click)="OnviewReport(element)" *ngIf="roleAccess.isView"><i aria-hidden="true" class="fa fa-eye"></i></a>
                </mat-cell>
              </ng-container>
    
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    
            </mat-table>
            <mat-paginator [pageSize]="20"[pageSizeOptions]="[ 10, 20, 50, 75, 100]" (page)="onPageChange($event)" showFirstLastButtons [pageSize]="5"></mat-paginator>
          </div>
          <div  *ngIf="!roleAccess.isView">
            <div class="msg-nomeet text-center">You do not have privilege to access this. Please contact admin</div>
        </div>
       </div>
      </div>
     </div>
   </div>

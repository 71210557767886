<div class="main-navbar sticky-top bg-white">
  <!-- Main Navbar -->
  <nav class="navbar align-items-stretch navbar-light flex-md-nowrap p-0">
    <form class="main-navbar__search  d-sm-flex d-md-flex d-lg-flex">
      <div class="header-logo cursor-pointer" (click)="onlogoClick()">
        <img src="../../assets/images/brand-logo.jpg" alt="">
      </div>
    </form>

    <ul class="navbar-nav  flex-row " >
      <li class="nav-item dropdown notifications" *ngIf="MenuAccess.Documents" title="Documents"  >
        <a class="nav-link nav-link-icon text-center" (click)="onClickDocument()"  role="button" id="dropdownMenuLink"  
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="nav-link-icon__wrapper">
            <i class="material-icons text-secondary bi bi-exclamation-triangle-fill text-warning">folder</i>
           </div>
        </a>
      </li>
      <li class="nav-item dropdown notifications">
               <a class="nav-link nav-link-icon text-center" (click)="onClickMessages()" role="button" id="dropdownMenuLink"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="nav-link-icon__wrapper">
            <i class="material-icons  text-primary">mail</i>
            <span class="badge badge-pill badge-danger">{{global.messagecount}}</span>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-small" aria-labelledby="dropdownMenuLink" style="display: none;">
          <div class="dropdown-header">
            <span class="triangle"></span>
            <span class="heading">Messages</span>
            <a class="viewall-btn cursor-pointer float-right" [routerLink]="['/viewnotification']">View All</a>
          </div>
          <!-- <div class="notify-list">
            <div>
              <a class="dropdown-item unread">

                <div class="notification__content">
                  <p><span class="notification__category cursor-pointer">sdfdsfsfddsfsdff</span>

                    <span class="float-right time-stam">dsfndsnfnsdfnsdf</span>
                  </p>
                  <p class="notify-italics"></p>
                  <p>sd.,f,sd/f</p>
                  <a><span class="make-unread"> <i class="fas fa-times-circle"></i></span></a>
                </div>
              </a>

              <a class="dropdown-item read-notify">

                <div class="notification__content">
                  <P><span class="notification__category cursor-pointer">kdjfksjdkfjlksjf</span>
                    <span class="float-right time-stam">sa;djsadjasd</span>
                  </P>
                  <p class="notify-italics"></p>
                  <p>klajsdkjdas</p>
                  <a><span class="make-unread"> <i class="fas fa-times-circle"></i> </span></a>
                </div>
              </a>
            </div>
          </div> -->



        </div>
      </li>
      <app-notification></app-notification>
      <li class="nav-item dropdown notifications" title="Receipt Details" *ngIf="isDisplayRecptIcon" >
        <a class="nav-link nav-link-icon text-center"  role="button" id="dropdownMenuLink"  (click)="onClickReceptLists()"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="nav-link-icon__wrapper">
            <i class="material-icons text-secondary">receipt</i>
           </div>
        </a>
      </li>
     
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle text-nowrap px-3" (click)="toggleProfile()" data-toggle="dropdown"
          role="button" aria-haspopup="true" aria-expanded="false">
          <img class="user-avatar rounded-circle mr-2" [src]="imageSource" alt="User Avatar">
          <span class="d-none d-md-inline-block profile-name">{{UserName}} <div class="profile-desin"></div>
          </span>

          <i class="fas fa-angle-down"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-small" [ngClass]="{ 'show': profileOpen }">
          <a class="dropdown-item" title="Profile" role="button" (click)="ProfilePage()" ><i class="material-icons"></i> Profile</a>
          <a class="dropdown-item" title="changePassword" role="button" (click)="changePasswordPage()" ><i class="material-icons">lock</i> Change Password</a>
          <!--<a class="dropdown-item"><i class="material-icons">vertical_split</i> Blog
            Posts</a>
          <a class="dropdown-item" ><i class="material-icons">note_add</i> Add New Post</a> -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" title="logout" role="button" (click)="onlogout()">
            <i class="material-icons text-danger"></i> Logout </a>
        </div>
      </li>
     

    </ul>
    <nav class="nav d-none">
      <a href="#" class="nav-link nav-link-icon toggle-sidebar d-md-inline d-lg-none text-center "
        data-toggle="collapse" data-target=".header-navbar" aria-expanded="false" aria-controls="header-navbar">
        <i class="material-icons text-secondary"></i>
      </a>
    </nav>
  </nav>


  <div  class="staff-header mt">

  </div>
  <!-- *ngIf="!(MenuAccess.ProgressNoteFivepointRating || MenuAccess.IncidentReport || MenuAccess.DischargeSummary)" -->
  <!-- *ngIf="!(roleId ==5 || roleId ==6)" -->
  <nav class="navbar navbar-expand-lg navbar-expand-md blue-mainbar" *ngIf="MenuAccess.HorizondalMenu">
    <div class="pl-4">
      <a class="grid-btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#ffffff" class="bi bi-grid-3x3-gap-fill"
          viewBox="0 0 16 16">
          <path
            d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
        </svg></a>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav mr-auto pl-3 menu-value">
          <!-- *ngIf="roleId != 4" -->
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/dashboard']" aria-current="page" *ngIf="MenuAccess.Dashboard"
           role="button">Dashboard</a> 
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="['/scheduler']" aria-current="page" *ngIf="MenuAccess.Scheduler"
           role="button">Scheduler</a> 
               <a class="nav-link cursor-pointer" [routerLinkActive]="['active']"  [routerLink]="['/leadlist']" 
             [routerLinkActiveOptions]="{ exact: false }" *ngIf="MenuAccess.Leads" (click)="onClickLead()">Leads</a>
                  <!-- <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" *ngIf="roleId != 4"
            [routerLink]="['/patient']">Patient Account</a> -->
            <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" *ngIf="MenuAccess.PatientAccount"
            [routerLink]="['/patientslists']">Patient Account</a>
              <a class="nav-link cursor-pointer" [routerLinkActive]="['active']"
            *ngIf="MenuAccess.Briefcase && (patientAccountId != 0 || LeadStatus==statusforIntake)"
            [routerLink]="['/patient-breifcase']">Briefcase</a>
             <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" *ngIf="MenuAccess.Profile"
                                 (click)="onClickLead()">Profile</a>
                                 <a class="nav-link cursor-pointer" [routerLinkActive]="['active']"
                                 *ngIf="MenuAccess.Briefcase && (patientAccountId != 0 || LeadStatus==statusforIntake)"
                                 (click)="Documentviewforclient()">Documents</a>
           <!-- <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" [routerLink]="['/notes']"
           *ngIf="MenuAccess.Profile" role="button">Notes</a> -->
          <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" [routerLink]="['/task-info']"
            *ngIf="MenuAccess.Task ||roleTypeId == 7 && !(MenuAccess.ProgressNoteFivepointRating || MenuAccess.IncidentReport || MenuAccess.DischargeSummary || MenuAccess.TreatmentPlan )"  role="button">Tasks</a> 
             <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" [routerLink]="['/goal-list']"
            *ngIf="MenuAccess.Goals" role="button">Goals</a>
            <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" [routerLink]="['/consentlist']"
            *ngIf="MenuAccess.Template" role="button">Template</a>
            <a class="nav-link cursor-pointer" [routerLinkActive]="['active']"  [routerLink]="['/bill-lists']"
            *ngIf="MenuAccess.Billing" role="button">Billing</a>
            
         <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" [routerLink]="['/billing-masters']"
         *ngIf="MenuAccess.BillingMasters" role="button">Billing Masters</a>
             <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" *ngIf="MenuAccess.Settings"
            [routerLink]="['/settings']">Settings</a>
          <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" *ngIf="MenuAccess.StaffManagement"
            [routerLink]="['/user-manage']">Staff Management</a>
            <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" *ngIf="(MenuAccess.ProgressNoteFivepointRating || MenuAccess.IncidentReport || MenuAccess.DischargeSummary || MenuAccess.TreatmentPlan)"
            [routerLink]="['/clinician']">Clinical Activities</a>
            <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" *ngIf="MenuAccess.Audit"
            [routerLink]="['/audit-list']">Audit</a>
            <!-- <a class="nav-link cursor-pointer" [routerLinkActive]="['active']" *ngIf="roleId == 1"
            [routerLink]="['/day-view-scheduler']">Testing admin day vw</a> -->
        </div>

      </div>
    </div>
    <div class="form-group mb-4 ml-auto">
      <!-- *ngIf="roleId == 3 || roleId == 1 || roleId==7" -->
      <mat-form-field class="loc-changer" floatLabel="never" *ngIf="MenuAccess.Leads || MenuAccess.Scheduler || MenuAccess.PatientAccount">
        <mat-select name="location" [(ngModel)]="global.ddlsfcclocation" #ddlsfcclocation="ngModel"
          (selectionChange)="onChangeLocation($event)">
          <mat-option *ngFor="let item of ddlSfccLocation " [value]="item.sfccMasterId">
            {{item.locationName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group mb-4 ml-auto d-none">
      <input id="exampleFormControlInput2" type="email" placeholder="search"
        class="form-control form-control-underlined border-primary">
      <span class="fa fa-search form-control-feedback"></span>
    </div>
  </nav>

</div>

import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { ActivatedRoute,Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/common/common.service';
import {IncReportInfo, RestraintCondition} from 'src/app/_models/IncReportDtl'
import { MatDialog } from '@angular/material/dialog';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { DischargeMedication, DischargeSummaryFormInfo } from 'src/app/_models/_Treatment/_dischargeSummary/_dichargeSummaryInfo';
import { ClinicianService } from '../../clinician/clinician.service';
import { constantVariables } from 'src/app/constant/constant';
import { TreatmentSummaryFormInfo } from 'src/app/_models/_Treatment/_dischargeSummary/_TreatmentSummarInfo';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';

	
@Component({
  selector: 'app-treatment-summary',
  templateUrl: './treatment-summary.component.html',
  styleUrls: ['./treatment-summary.component.scss']
})
export class TreatmentSummaryComponent implements OnInit {
  @ViewChild('TreatmentSumFrm') TreatmentSumFrm: NgForm;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  DischargeSummaryId: number;
  clinician: number;
  IseditNum: number;
  Isedit: boolean;
  TreatmentSummaryInfo:TreatmentSummaryFormInfo;
  ddldiagnosiscode : any;
  isESignAttached: boolean;
  signatureImg: any;
  updatedsignatureImg: any;
  isformtype: string;
  defaultImage: any;
  Isnew: boolean;
  PatAccountId: any=0;
  FromDateRestriction:any;
  Id: any;
  ddlstafflist: any;
  ClinicianName: any;
  roleAccess: any;
  secondaryId : any;
  AutoFillSS:boolean=false;
  SSignature:any;
  GetClinicianSign: any[];  
  userId: any;
  constructor(public treatmentservice: TreatmentService, public router:Router,public global: Global,public clinicianservice: ClinicianService,
    public spinnerservice: NgxSpinnerService, private dialog: MatDialog, private snackbar: SnackBarService,public commonService: CommonService,private route: ActivatedRoute
    ,public roleaccessservice:PageAccessService
    ) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.DischargeSummary;
    this.userId = Number(localStorage.getItem('id'));
    this.getAutoFillSignature();

 this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    if(!!(localStorage.getItem("patientAccountId"))){
      this.PatAccountId = +localStorage.getItem('patientAccountId');
    }
    this.TreatmentSummaryInfo = new TreatmentSummaryFormInfo();
    this.TreatmentSummaryInfo.signDate = new Date();
    this.FromDateRestriction = new Date();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.DischargeSummaryId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.clinician = Number(this.commonService.FrontEndDecryption(params.clinician).replace(/"/g, ""));
        this.IseditNum = Number(this.commonService.FrontEndDecryption(params.isedit).replace(/"/g, ""));
      }
    })
    if(this.IseditNum==1){
      this.Isedit=true;
    }
    else{
      this.Isedit = false;
    }
    this.Id = localStorage.getItem('id');
    this.getDiagnosisCodeMster();
    this.getTreatmentSummarybyId()
  }
  getDiagnosisCodeMster() {
    this.commonService
      .getDiagonosisCodeMaster()
      .subscribe((res: any) => {
        if (res.objInputString != null) {
          let fres = this.commonService.decrypdata(res.objInputString);
          if (fres.length > 0) {
            this.ddldiagnosiscode = fres;
        }
      }
      },
        err => {
          this.spinnerservice.hide();
        });
  }
  GetStaffList(){
    this.spinnerservice.show()
    this.treatmentservice.getCLinicalStaffList().subscribe(res=>{
      this.spinnerservice.hide()
      this.ddlstafflist = res
     // this.ddlReportedBy = res
      this.TreatmentSummaryInfo.clinicianId = +this.Id
      this.ClinicianName = this.ddlstafflist.filter(x=>x.userId ==this.TreatmentSummaryInfo.clinicianId )
    },
    err => {
      this.spinnerservice.hide();
  
    })
  }

  openDialog() {
    // this.global.isconsentesign = false;
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
    //   data: { isfutureSign: false, isParentsign: true },
    //   autoFocus: false 

    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   this.isESignAttached = this.global.isconsentesign;
    //   if (
    //     this.global.isconsentesign != undefined &&
    //     this.global.isconsentesign
    //   ) {
      this.global.isconsentesign = false;
      let isParentsign =false;
      if(this.AutoFillSS==true)
       {
         isParentsign=true;
    
       }
       const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
         {
        data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
         autoFocus: false 
    
       });
        dialogRef.afterClosed().subscribe((result) => {
          this.isESignAttached = this.global.isconsentesign;
          if (
            this.global.isconsentesign != undefined &&
            this.global.isconsentesign
          ) {
            if(this.SSignature!='')
              {
               this.updatedsignatureImg=this.SSignature;
              }
        this.signatureImg = this.updatedsignatureImg;
        this.TreatmentSummaryInfo.staffInitials = this.updatedsignatureImg;
       // this.IncReportfrmInfo.IsAttachEsign = true;
        (document.getElementById('esign_3') as HTMLImageElement).src =
          this.signatureImg;
      } else if (
        !this.global.isconsentesign &&
        this.global.formtype != null &&
        this.global.formtype != undefined
      ) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.TreatmentSummaryInfo.staffInitials= this.global.consentesign;
        //this.IncReviewInfo.IsAttachEsign = true;
        (document.getElementById('esign_3') as HTMLImageElement).src =
          this.signatureImg;
      } else {
        this.signatureImg = this.defaultImage;
        //this.IncReportfrmInfo.IsAttachEsign = true;
        this.TreatmentSummaryInfo.staffInitials = this.defaultImage;
      }
    });
  }
  getTreatmentSummarybyId(){
    this.spinnerservice.show();
    this.secondaryId = localStorage.getItem('id');
    if(!this.DischargeSummaryId){
      this.DischargeSummaryId = 0
    }
    this.clinicianservice.getTreatmentSummarybyId(this.DischargeSummaryId,this.secondaryId).subscribe((res:any)=>{
      this.spinnerservice.hide()
      this.TreatmentSummaryInfo = res
      this.TreatmentSummaryInfo.diagnosisCode = this.TreatmentSummaryInfo.diagnosisCode.split(',').map(String);
      this.ddldiagnosiscode.forEach(x=>x.isActive=false);
      for (let item of this.ddldiagnosiscode){
        if(this.TreatmentSummaryInfo.diagnosisCode.includes(item.value)){
          item.isActive = true;
        }
      }
      if(this.TreatmentSummaryInfo.treatmentSummaryId==0){
        this.Isnew = true;
        this.TreatmentSummaryInfo.signDate = new Date();
      }
      else{
        this.Isnew = false;
      }
      if(this.TreatmentSummaryInfo.staffInitials!=undefined){
      (document.getElementById('esign_3') as HTMLImageElement).src = this.TreatmentSummaryInfo.staffInitials;
      }
      if(this.TreatmentSummaryInfo.staffInitial1!=undefined){
        (document.getElementById('esign_4') as HTMLImageElement).src = this.TreatmentSummaryInfo.staffInitial1;
        }
      this.GetStaffList()
    },
    err => {
      this.spinnerservice.hide();
    },)
  }

  PostTreatmentSummary(){
    if(!this.TreatmentSumFrm.valid || (this.TreatmentSummaryInfo.staffInitials==undefined && this.roleAccess.isSign)){
      return
    }
    else{
      this.TreatmentSummaryInfo.diagnosisCode= this.TreatmentSummaryInfo.diagnosisCode.toString();
      console.log(this.TreatmentSummaryInfo)
      this.spinnerservice.show()
      const dataToEncrypt = this.commonService.DataToEncrypt(this.TreatmentSummaryInfo, false);
      this.clinicianservice.PostTreatmentSummary(dataToEncrypt).subscribe(
        res=>{
          this.spinnerservice.hide();
          this.snackbar.success(res);
          this.OnBack();
         // this.getTreatmentSummarybyId();
      },
          err => {
        
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },)
    }
  }

  OnBack(){
    if(this.PatAccountId!=0){
      window.close(); 
    }
    else{
    this.router.navigate(['/discharge-summary'],{queryParams:{id:this.commonService.FrontEndEncryption(this.DischargeSummaryId),isedit:this.commonService.FrontEndEncryption(this.IseditNum),clinician:this.commonService.FrontEndEncryption(this.clinician)}});
    }
  }

  onDiagnosisCodeselect(item, event) {
    item.isActive = event;
  }
  treatmentSummarypdf(){
    this.spinnerservice.show();
      this.treatmentservice.gettreatmentsummarypdf(this.DischargeSummaryId).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          if (res != null) {
            const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
            this.downloadLink.nativeElement.href = res.base64File;
            this.downloadLink.nativeElement.download = res.fileName;
            this.downloadLink.nativeElement.click();
            console.log(res);
          }
        },
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  openDialog1() {
    // this.global.isconsentesign = false;
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
    //   data: { isfutureSign: false, isParentsign: true },
    //   autoFocus: false 

    // });
    this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
    dialogRef.afterClosed().subscribe((result) => {
      this.isESignAttached = this.global.isconsentesign;
      if (
        this.global.isconsentesign != undefined &&
        this.global.isconsentesign
      ) {
        if(this.SSignature!='')
          {
           this.updatedsignatureImg=this.SSignature;
          }
        this.signatureImg = this.updatedsignatureImg;
        this.TreatmentSummaryInfo.staffInitial1 = this.updatedsignatureImg;
       // this.IncReportfrmInfo.IsAttachEsign = true;
        (document.getElementById('esign_4') as HTMLImageElement).src =
          this.signatureImg;
      } else if (
        !this.global.isconsentesign &&
        this.global.formtype != null &&
        this.global.formtype != undefined
      ) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.TreatmentSummaryInfo.staffInitial1= this.global.consentesign;
        //this.IncReviewInfo.IsAttachEsign = true;
        (document.getElementById('esign_4') as HTMLImageElement).src =
          this.signatureImg;
      } else {
        this.signatureImg = this.defaultImage;
        //this.IncReportfrmInfo.IsAttachEsign = true;
        this.TreatmentSummaryInfo.staffInitial1 = this.defaultImage;
      }
    });
  }
  secondarySign():any{
    if(this.TreatmentSummaryInfo.licenseNumber != '' && this.TreatmentSummaryInfo.licenseNumber != null ){
      return false;
    }
    else if(this.TreatmentSummaryInfo.sSignature ){
     return false;
    }
    else{
      return true;
    }
  }
  getAutoFillSignature() {
    this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
        this.GetClinicianSign = res;
  
        if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
          this.AutoFillSS=false;
          this.SSignature=this.GetClinicianSign[0].clinicianSign;
        }
        else
        {
          this.AutoFillSS=true;
          this.SSignature='';
        }
    });
  }
}

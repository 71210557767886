import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'src/app/global/custom-validators';
import { first } from 'rxjs/operators';
import { UserserviceService } from '../userservice.service';
import { AuthenticationService } from 'src/app/admin/authentication.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { constantVariables } from 'src/app/constant/constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
declare function EncryptFieldData(data): any;
declare function FrontEndDecryption(data): any;
declare function decrypt(data): any;
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  changepassswordForm: FormGroup;
  ResponseData: any;
  pageid:any;
  loading = false;
  submitted = false;
  showErrorPassword = false;
  showForgetform = false;
  isActivation = false;
  activationEmail = '';
  passwordid = '';
  ActivationUserName = '';
  oldPassword:any;
  ChangePasswordData = {
    Email: null,
    PasswordID: null,

  };
  PassEye=true;
  ConfirmpassEye=true;
  submitClicked:boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public global: Global,
    private uService: UserserviceService,
    private authenticationService: AuthenticationService,
    private snackbar: SnackBarService,
    public spinnerservice: NgxSpinnerService,
    public commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {      
      if (Object.keys(params).length !== 0) {
        this.isActivation = true;
        
        // var res = decrypt(params.Email);
        //var res = decrypt(params.Email);
        this.oldPassword=params.Password;
        //this.activationEmail = res;
        this.passwordid = params.LinkId;
        //this.ActivationUserName =decrypt(params.UserName);
        this.pageid=params.PageId;
        
        //this.ChangePasswordData.Email = res;
        this.ChangePasswordData.PasswordID = Number(this.passwordid);
        
      }
    });
    this.changepassswordForm = this.formBuilder.group({
      OldPassword: ['', [Validators.required]],
      // Password: ['', [Validators.required, Validators.minLength(6), Validators.pattern]],
      Password: [
        null,
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          // CustomValidators.patternValidator(/[a-z]/, {
          //   hasSmallCase: true
          // }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8)
        ])
      ],

      // ConfirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      ConfirmPassword: [null, Validators.compose([Validators.required])],
      Screen: ['UpdatePassword']
    }, {
      //validator: MustMatch('Password', 'ConfirmPassword')
      validator: CustomValidators.passwordMatchValidator
    });
    this.changepassswordForm.get('ConfirmPassword').clearValidators();
    this.changepassswordForm.get('ConfirmPassword').updateValueAndValidity();
    if (this.isActivation) {
      this.changepassswordForm.get("OldPassword").setValue(this.oldPassword);
      this.changepassswordForm.controls.OldPassword.disable();
      //this.changepassswordForm.get("OldPassword").disabled;
      this.ActivationLinkValidation();
    }
   
  }
 
  get f() { return this.changepassswordForm.controls; }
  showErrorPopup() {
    this.showErrorPassword = true;
  }

  hideErrorPopup() {
    this.showErrorPassword = false;
  }


  isinvalid: boolean = false;

  changeStyle($event) {
    this.isinvalid = $event.type == 'mouseover' ? this.showErrorPassword = true : this.showErrorPassword = false;
  }


  onSubmit() {
    this.submitted = true;
   
    this.changepassswordForm.get('ConfirmPassword').setValidators(Validators.compose([Validators.required]));
    this.changepassswordForm.get('ConfirmPassword').updateValueAndValidity();
    // stop here if form is invalid
    if (this.changepassswordForm.invalid) {
      
      return;
    }
    if(!this.submitClicked)
    {
    this.submitClicked = true;
    let emailid = localStorage.getItem('email');
    let _ChangePasswordData = {
      email: this.isActivation == true ? this.activationEmail : emailid,
      oldPassword: this.changepassswordForm.controls.OldPassword.value,
      password: this.changepassswordForm.controls.Password.value,
      confirmPassword: this.changepassswordForm.controls.ConfirmPassword.value,
      isResetPassword: this.isActivation == true ? 0 : 1,
      token: null,
      passwordID: this.passwordid
    };
    const dataToEncrypt = {
      objChangepassword: EncryptFieldData((JSON.stringify(_ChangePasswordData))),
    };
    //this.spinnerservice.show();
    if (this.isActivation) {
      this.onUserActivation(dataToEncrypt);
    } else {
      this.onchangePassword(dataToEncrypt);
    }
  }
  }
  onUserActivation(dataToEncrypt) {
    this.spinnerservice.show();
    this.uService.updatePassword(dataToEncrypt,this.pageid)
      .subscribe(
        _data => {
          this.spinnerservice.hide();
          this.snackbar.success(constantVariables.SuccessMessage.Activatedsuccessfully);
          let data = {
            UserName: this.ActivationUserName,
            Password: this.changepassswordForm.controls.Password.value
          }
          this.submitClicked = false;
          this.proceedtoLogin(data)

        },
        _error => {
          this.spinnerservice.hide();
          this.submitClicked = false;
          this.snackbar.error(_error.error);
        });
  }
  onchangePassword(dataToEncrypt) {
    this.spinnerservice.show();
    this.uService.Changepassword(dataToEncrypt)
      .subscribe(
        _data => {
          this.spinnerservice.hide();
           
          this.snackbar.success(constantVariables.SuccessMessage.Passwordupdatedsuccessfully);
          // this.onclear();
          // this.authenticationService.onLogout();
          // this.router.navigate(['./login']);
          // this.loading = false;
          let username = localStorage.getItem('username');
          let data = {
            UserName: username,
            Password: this.changepassswordForm.controls.Password.value
          }
          this.submitClicked = false;
          this.proceedtoLogin(data)

        },
        _error => {
          this.spinnerservice.hide();
          this.submitClicked = false;
          this.snackbar.error(_error.error);
        });
  }
  proceedtoLogin(data) {
   
   this.spinnerservice.hide();
    const dataToEncrypt = {
      ObjLogin: EncryptFieldData(JSON.stringify(data))
    };
    this.uService.login(dataToEncrypt).subscribe(
      (res: any) => {
     
        localStorage.setItem('token', res.token);
        localStorage.setItem('username', res.username);
        localStorage.setItem('email', res.email);
        localStorage.setItem('roleId', res.roleId);
        localStorage.setItem('id', res.id);
        localStorage.setItem('pagelist', JSON.stringify(res.pageList));
        localStorage.setItem('roleaccess', JSON.stringify(res.roleaccess)); 
        if (res.roleId == 4) {      
          localStorage.setItem('patientId', res.patientId);
          localStorage.setItem('patientAccountId', res.patientAccountId);
          if (res.patientAccountId != 0) {
            this.router.navigate(['/patient-breifcase']);
            return
          }
          if(res.isStarted == false) {
           
            this.router.navigate(['/lead'], { queryParams: { id: this.commonService.FrontEndEncryption(res.patientId) } });
          }else if(res.isStarted == true){
            this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(res.patientId) } });
          }
         
        }else if(res.roleId == 5 || res.roleId == 6 ){
          this.router.navigateByUrl('/clinician'); 
        }else{
          this.router.navigateByUrl('/leadlist');
        }

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
        if (err.status == 501) {
        } else {
          this.snackbar.error(err.error);
        }
      }

    );
  }
  onclear() {
    this.changepassswordForm = this.formBuilder.group({
      OldPassword: [''],
      Password: [''],
      ConfirmPassword: ['']

    });
  }
  oncancelChangePwd() {
    if (this.isActivation) {
      this.router.navigate(['./login']);
    }
    else {
      this.router.navigate(['./home']);
    }
  }
  ActivationLinkValidation() {
    this.spinnerservice.show();
    this.uService.getUserEmail(this.passwordid).subscribe(
      res => {
        if (res != null) {
          this.activationEmail = res.email;
          this.ActivationUserName = res.email;
          this.ChangePasswordData.Email = res.email;
          let _ChangePasswordData = {
            Email:this.ChangePasswordData.Email,     
            PasswordID: this.ChangePasswordData.PasswordID
          };
          this.uService.ActivationLinkValidation(_ChangePasswordData)
            .pipe(first())
            .subscribe(
              _data => {
                if(_data != ""){
                  this.snackbar.error(_data);           
                  this.authenticationService.onLogout();
                }
                this.showForgetform = true;
                this.loading = false;
              },
              _error => {
                this.snackbar.error(_error.error);
                this.authenticationService.onLogout();
      
                this.router.navigate(['./login']);
                this.loading = false;
      
              });
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
    
  }
}

<div class="pull-right">

  <span><i title="Add Documents" class="fas fa-file-upload i add-doc mt-2" (click)="getDocuments(patientId)"></i></span>

</div>


<div class=" mt-2 mb-2"> <strong>Client Name : {{UserName}} </strong> </div>


  <div class="col-md-12 tab-content float-left mt-3 no-bg no-border" >
          <table class="table table-styles mt-2 documenttable--fix" >
            <thead>
               <tr>
                 
                 <td>S.No</td>
                 <td>Document Name</td>
                 <td>Document Link</td>
                 <td>Document Type</td>
                 <td>Created by</td>
                 <td>Action</td>
               </tr>
             </thead>
             
             <tbody>
               <tr  *ngFor="let item of uploadedDocumentList; let i=index;">
                 
                 <td>{{i+1}}</td>
                 <td>{{item.FileName}}</td>
                  <td><a (click)="UploadedDocumentsDownload(item,1)">{{item.DocumentName}}</a></td>
                  <td>{{item.DocumentTypeName}}</td>
                 <td>{{item.CreatedName}}</td>
                 <td>
                  <a (click)="UploadedDocumentsDownload(item,1)" class="fil-names">Download</a>

                  <!-- <div class="dropdown">
                    <i class="fa fa-ellipsis-v dropdown-toggle" id="menu1" data-toggle="dropdown"></i>
                    <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                      <li role="presentation"  class="w-100 float-left"><a (click)="UploadedDocumentsDownload(item,0)" class="fil-names">Download</a></li>
                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="UploadedDocumentsDownload(item,1)" >Preview</a></li>
                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="UploadedDocumentsDownload(item,1)">Print</a></li>
                      <li role="presentation"  class="w-100 float-left"><a role="menuitem" tabindex="-1" (click)="onClickShare(item,'Uploaded')">Share</a></li>
                    </ul>
                  </div> -->
                 </td>
               </tr>
             </tbody>
          </table>
        
          <div  class="d-flex justify-content-center Doc_text" *ngIf = "uploadedDocumentList.length == 0">
            <span >No Documents Found </span>
          </div>
</div>  
<div
  class="modal showdocspopup"
  role="dialog"
  [style.display]="ShowDocuments ? 'block' : 'none'"
>
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title w-100 float-left">
          Documents
          <button
            type="button"
            class="close float-right"
            (click)="closepopup()"
          >
            &times;
          </button>
        </h6>
      </div>
      <div class="modal-body">
        <div class="float-left w-100">
          <div class="w-100 d-flex justify-content-around mb-2">
            <button
              mat-raised-button
              class="primary-btn-filled"
              (click)="fileInput.click()"
            >
              Add Files
            </button>
            <input
              style="display: none"
              #attachments
              type="file"
              (change)="onFileChanged($event)"
              #fileInput
              multiple="true"
            />
            <div
              class="w-100 float-left mb-2 text-danger pl-2 mt-2"
              *ngIf="filesize"
            >
              File size should not exceed 10 Mb
            </div>
            <div
              class="w-100 float-left mb-2 text-danger pl-2 mt-2"
              *ngIf="filecount"
            >
              You are allowed to upload only 5 documents
            </div>
            <div class="doc-type">
              <mat-form-field class="w-100">
                <mat-label>Document Type</mat-label>
                <mat-select   [(ngModel)]="documentModuleTypeId" 
                name="documentModuleTypeId" (selectionChange)="isRequired = false">
                 <mat-option [title]="doc.documentName" *ngFor="let doc of ddlDocumentType" [value]="doc.documentModuleTypeId">{{doc.documentName}}</mat-option>
                </mat-select> 
               
             </mat-form-field>
             <!-- <mat-error *ngIf="isRequired"> <mat-error>Document type is required</mat-error>  </mat-error> -->
             <div class="error-message err-top" *ngIf="isRequired" >Document Type is required
            </div>
             
            </div>
          </div>
          <!--Documents will be shown here-->
          <div
            class="w-100 float-left"
            *ngFor="let selected of listOfFiles; let index = index"
          >
            <div class="filesectionloop mb-2">
              <span class="filenametext">{{ selected }}</span>
              <button
                mat-icon-button
                class="deleteicon"
                title="Delete"
                (click)="removeSelectedFile(index)"
              >
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-sm btn-danger" (click)="closepopup()">
          Close
        </button>
        <button type="button" class="btn-sm btn-success" (click)="savedocs()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>  
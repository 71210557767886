<form (ngSubmit)="taskfrm.form.valid  && saveTaskInfo(taskfrm)" id="taskfrm" autocomplete="off" #taskfrm="ngForm"
    novalidate>
    <div class="col-md-12 p-0 edit-note task-notes" >

        <div class="row mt-2">
            <div class="col-md-8 ">
                <div class="col-md-12 ">
                    <div class="row">
                        <div class="top-notes d-flex w-100">
                            <div class="box-lead-head">Tasks</div>
                            <div class="ml-auto ">
                                <button mat-raised-button class="primary-btn-filled-small cursor-pointer mr-2"
                                    type="button" *ngIf="!isCreateTask " [disabled]="taskids?.length !== 0" (click)="onCreateTask()">Create Task</button>
                                    <button *ngIf="lstTask?.length > 0 " type="button" [disabled]="taskids?.length == 0" title="Delete"
                                            (click)="ondeletemultipleTask()" mat-raised-button
                                            class="primary-btn-filled-small mr-2">Delete All</button>
                                    <button *ngIf="lstTask?.length > 0 " type="button" [disabled]="taskids?.length == 0" title="Delete"
                                                (click)="oncompletemultipleTask()" mat-raised-button
                                                class="primary-btn-outlined-small ">complete All</button>
                                </div>
                        </div>
                        <div class="empty-data" *ngIf="lstTask?.length ==0" >
                            <!-- <img  class="img-fluid" src="../../../../../assets/images/no_result.png" width="50%"> -->
                            <div *ngIf="!isCreateTask" class="task-empty"> <p>No Task Available</p><i  class="fas fa-tasks mr-2"></i></div>
                        </div>
                        <div class="task-create-section" *ngIf="isCreateTask">
                            <div class="col-md-12">
                                <div class="col-md-12 p-0">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="Task Name" appAlphabetOnly maxlength="50"
                                          [(ngModel)]="taskinfo.taskName" #taskName="ngModel" name="taskName"
                                          autocomplete="new-password" [ngClass]="{ 'is-invalid': taskfrm.submitted && taskName.invalid }"
                                          required>
                                        <mat-error *ngIf="taskfrm.submitted && taskName.invalid">
                                          <mat-error *ngIf="taskName.errors.required">Task name is required</mat-error>
                                        </mat-error>
                                      </mat-form-field>
                                </div>

                            </div>
                            <div class="col-md-12">
                                <label class="control-label-new">Enter Task</label>
                                <div class="col-md-12 p-0">
                                    <quill-editor [(ngModel)]="taskinfo.content" #content="ngModel" name="content"
                                        [modules]="quillConfig" required  placeholder="Insert notes...">
                                    </quill-editor>
                                    <mat-error *ngIf="taskfrm.submitted && content.invalid">
                                        <mat-error class="mat-form-field-subscript-wrapper"
                                            *ngIf="content.errors.required">
                                            Content is required</mat-error>
                                    </mat-error>
                                </div>

                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-4">
                                        <mat-form-field class="w-100">
                                            <mat-label>Due date</mat-label>

                                            <input matInput [matDatepicker]="duepicker" readonly [min]="currentdate"
                                                (click)="duepicker.open()" [(ngModel)]="taskinfo.dueDate"
                                                #dueDate="ngModel" name="dueDate"
                                                [ngClass]="{ 'is-invalid': taskfrm.submitted && dueDate.invalid }"
                                                required>
                                            <mat-datepicker-toggle matSuffix [for]="duepicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #duepicker></mat-datepicker>
                                            <mat-error *ngIf="taskfrm.submitted && dueDate.invalid">
                                                <mat-error *ngIf="dueDate.errors.required">Due date is
                                                    required</mat-error>

                                            </mat-error>

                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col-md-12 ">
                                    <mat-form-field class="w-100">
                                        <mat-label>Over Due date</mat-label>

                                        <input matInput [matDatepicker]="ovrduepicker" readonly [min]="currentdate"
                                            (click)="ovrduepicker.open()" [(ngModel)]="taskinfo.overDate"
                                            #overDate="ngModel" name="overDate"
                                            [ngClass]="{ 'is-invalid': taskfrm.submitted && overDate.invalid }"
                                            required>
                                        <mat-datepicker-toggle matSuffix [for]="ovrduepicker">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #ovrduepicker></mat-datepicker>
                                        <mat-error *ngIf="taskfrm.submitted && overDate.invalid">
                                            <mat-error *ngIf="overDate.errors.required">Over due date is
                                                required</mat-error>

                                        </mat-error>
                                    </mat-form-field>
                                    <mat-error class="mat-form-field-subscript-wrapper" *ngIf="!isValidDueDate">Over due
                                        date should be grater than due
                                        date</mat-error>
                                </div> -->
                                    <div class="col-md-4">
                                        <mat-form-field class="w-100">
                                            <mat-label>Send reminder</mat-label>
                                            <input matInput [matDatepicker]="flagpicker" readonly [min]="currentdate"
                                                (click)="flagpicker.open()" [(ngModel)]="taskinfo.flagMeBy"
                                                #flagMeBy="ngModel" name="flagMeBy"
                                                [ngClass]="{ 'is-invalid': taskfrm.submitted && flagMeBy.invalid }">
                                            <mat-datepicker-toggle matSuffix [for]="flagpicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #flagpicker></mat-datepicker>
                                            <mat-error *ngIf="taskfrm.submitted && flagMeBy.invalid">
                                                <mat-error *ngIf="flagMeBy.errors.required">Send reminder is
                                                    required</mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="!isValidFlagDate">
                                            Flag
                                            date should be grater than due
                                            date</mat-error>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="w-100">
                                            <mat-label>Type </mat-label>
                                            <mat-select  [(ngModel)]="taskinfo.type" #type="ngModel" name="type"
                                            [ngClass]="{ 'is-invalid': taskfrm.submitted && type.invalid }" required>
                                              <mat-option [value]="1">To-do</mat-option>
                                              <mat-option [value]="2">Call</mat-option>
                                              <mat-option [value]="3">Send message</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="taskfrm.submitted && type.invalid">
                                                <mat-error *ngIf="type.errors.required">Type is required</mat-error>
                                              </mat-error>
                                          </mat-form-field>
                                        <!-- <label class="control-label-new">Type</label>
                                        <select class="form-control w-75 d-inline-block mr-2" name="type"
                                            required>
                                            <option value="null">--Select Type--</option>
                                            <option value=1>To-do</option>
                                            <option value=2>Call</option>
                                            <option value=3>Send message</option>
                                        </select> -->
                                        
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="w-100">

                                            <mat-label>Assigned To: </mat-label>
                                            <mat-select [(ngModel)]="taskinfo.assignedTo" #assignedTo="ngModel"
                                                name="assignedTo"
                                                [ngClass]="{ 'is-invalid': taskfrm.submitted && assignedTo.invalid }"
                                                required [disabled]="taskinfo.isSelfAssigned">
                                                <mat-option *ngFor="let item of lstuser" [value]="item.userId">
                                                    {{item.firstName}} {{item.lastName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="taskfrm.submitted && assignedTo.invalid">
                                                <mat-error *ngIf="assignedTo.errors.required">AssignedTo is
                                                    required
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>

                                       
                                    </div>
                                    <div class="col-md-4 mt-3">
                                        <div class="check-custom mt-1">
                                            <mat-checkbox class="example-margin" [(ngModel)]="taskinfo.isSelfAssigned"
                                                #isSelfAssigned="ngModel" name="isSelfAssigned" (change)="onSelfAssign($event)" >Self Assign
                                            </mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    
                                 
                                    <div class="col-md-12 text-right mt-2">

                                        <button mat-raised-button class="primary-btn-outlined-small mr-2 d-none" >Save
                                            Task</button>
                                        <button type="submit" title="{{!isEditTask ?
                                        'Create Task':'Update Task'}}" form="taskfrm" mat-raised-button
                                            class="primary-btn-outlined-small mr-2">{{!isEditTask ?
                                            'Save Task':'Update Task'}}</button>
                                        <button mat-raised-button class="primary-btn-outlined-small mr-2" (click)="resetnotesform()">Cancel
                                            </button>
                                        

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 ">
                    <div class="row">
                        <div class="col-md-12 p-0 mt-2 view-note d-block">

                            <div class="row">
                                <div class="col-md-12 mb-1" *ngFor="let item of lstTask;let i = index">
                                    <div class="list-note">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="float-left w-100 pl-4 position-relative">
                                                    <span class="check-custom invi-check2 align-middle">
                                                        <mat-checkbox class="example-margin"
                                                            (change)="cbklisttask($event,item, i)" name="cbktask{{i}}">
                                                        </mat-checkbox>
                                                    </span>
                                                    <span class="note-content mt-1">
                                                        {{item.taskName}}
                                                    </span>
                                                    
                                                    <!-- <p class="note-content mt-1"
                                                        innerHTML="{{global.getContent(item.taskName)}}">
                                                    </p> -->
                                                </div>
                                            </div>
                                            <div class="col-md-4 text-right pl-0">
                                                <span class="mr-2">
                                                    <label class="task-status overdue"
                                                    *ngIf="item.status=='Over Due'">{{item.status}}</label>
                                                <label class="task-status today"
                                                    *ngIf="item.status=='Today'">{{item.status}}</label>
                                                <label class="task-status due"
                                                    *ngIf="(item.status!='Today') &&  (item.status!='Over Due')">{{item.status}}</label>
                                                </span>
                                                <div *ngIf="item.isCompleted == true">
                                                    <a title="The task is completed" class="icon-note"><span
                                                        class="mr-2"><i class="fas fa-check-circle text-success"></i></span></a>
                                                </div>       
                                                <div *ngIf="item.isCompleted !== true">
                                                    <a title="Mark the task as completed" (click)="oncompletetask(item)" class="icon-note"><span
                                                        class="mr-2"><i class="far fa-check-circle"></i></span></a>
                                                </div>                                            
                                                    <a title="comment" ><span
                                                        class="mr-2" (click)="openDialog(item)"><i class="far fa-comments"></i></span></a>
                                                <a title="edit"  (click)="onedittask(item)" class="icon-note"><span
                                                        class="mr-2"><i class="far fa-edit"></i></span></a>
                                                <a title="delete"  (click)="ondeletedtask(item)" class="icon-note"><span
                                                        class="mr-2"><i class="far fa-trash-alt"></i></span></a>
                                            </div>
                                            <!-- <div class="col-md-12">
                                                <label class="tag-label">Comments : </label>
                                                <p class="text-truncate w-75 font-15">adadd ada  dakdjljdkj jkajkjdkjaj jdj</p>
                                            </div> -->
                                            <div class="col-md-12 text-right">
                                                <div class="row">
                                                    <div class="col-md-8 asigned">
                                                        <span class="tag-label">Assigned to : </span>
                                                        <span class="tag-label"> {{item.userName}} </span>
                                                        <!-- <mat-chip-list aria-label="Fish selection">
                                                            <ng-container *ngFor="let a of item.tags">
                                                                <mat-chip *ngIf="a.email !=''">{{a.email}}</mat-chip>
                                                            </ng-container>
                                                            
                                                        </mat-chip-list> -->
                                                    </div>
                                                    <div class="col-md-4 asigned font-15">
                                                        <div><span class="time-label">Last
                                                                updated : </span><span>{{global.globalUtctimeformat(item.updateDate)}}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)"
                                        [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
                                    </mat-paginator>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="calendar-small">
                    <app-calender [patientInfoId]="patientInfoId" [Type]="1"></app-calender>
                </div>
                
            </div>
        </div>
    </div>
</form>

<!-- <div *ngIf="!roleAccess.isView">
    {{viewRightsMsg}}
    </div> -->
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http: HttpClient, public commonService: CommonService) { }
  getActiveParentlst(){
    return this.http.get<any>(environment.BaseURI + 'gateway/GetNotes/GetActiveParentList');
  }
  
  getTask(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/GetTask?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getTasksDashboard(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/GetTasksDashboard?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getCompletedCount(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/TaskCompletedCount?Id=' + this.commonService.DataToEncrypt(id, true));
  }

  getComment(id,taskId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/GetComments?strPatientInfoId=' + this.commonService.DataToEncrypt(id, true) + '&strTaskId=' + this.commonService.DataToEncrypt(taskId, true));
  }

  getNotesComment(id,taskId) {
    //return this.http.get<any>('https://localhost:44349/api/GetNotes/GetComments?strPatientInfoId=' + this.commonService.DataToEncrypt(id, true) + '&strNoteId=' + this.commonService.DataToEncrypt(taskId, true));
    return this.http.get<any>(environment.BaseURI + 'gateway/GetNotes/GetComments?strPatientInfoId=' + this.commonService.DataToEncrypt(id, true) + '&strNoteId=' + this.commonService.DataToEncrypt(taskId, true));
  }

  postTask(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTask/PostTask',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }



  postTaskComment(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTask/PostComment',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  postNoteComment(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostNotes/PostComment',
   
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  deleteTask(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostTask/DeteleTask?Id=' + this.commonService.DataToEncrypt(id, true));
  }

  readComment(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostTask/ReadComment?Id=' + this.commonService.DataToEncrypt(id, true));
  }

  readNoteComment(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostNotes/ReadComment?Id=' + this.commonService.DataToEncrypt(id, true));
  }

  completeTask(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostTask/CompleteTask?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  deletetasks(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTask/DeteleAllTask',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  
  }

  readmultipleComment(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTask/ReadAllComment',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  
  }

  readmultipleNotesComment(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostNotes/ReadAllComment',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  
  }

  completeAlltasks(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTask/CompleteAllTask',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  
  }

  postTaskImage(frmData) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTask/ImageUpload', frmData)
      .pipe(map(res => {

        return res;
      }));
  }
  postTaskInfo(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTask/PostTaskInfo',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postTaskStatus(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTask/PostTaskStatus',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  getTaskInfo(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetTask/GetTaskInfo',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getTaskActivityLog(data) {
    return this.http.get(environment.BaseURI + 'gateway/GetTask/GetTaskActivityLog?id=' + this.commonService.DataToEncrypt(data, true));
  }
  // getTaskInfo(id) {
  //   return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/GetTaskInfo?Id=' + this.commonService.DataToEncrypt(id, true));
  // }
  getUserList(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/GetUserList?id=' + this.commonService.DataToEncrypt(id, true));
  }
  
}

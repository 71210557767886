import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from '../../../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class IntakegenerateService {

  constructor(private http: HttpClient, public commonService: CommonService) { }

  getPatientView(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetPatientView?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getPatientViewHistory(id): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetPatientViewHistory?id=' + this.commonService.DataToEncrypt(id, true));
  }

  getPatientInfoPDF(id,clinicianId,isClinician): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/getPatientInfoPDF?patientId=' + this.commonService.DataToEncrypt(id, true)
    + '&clinicianId=' + this.commonService.DataToEncrypt(clinicianId, true)
    + '&isClinician=' + this.commonService.DataToEncrypt(isClinician, true));    
  }
  getPatientInfoPDFFile(id, documentType): Observable<any> {
     return this.http.get(environment.BaseURI + 'gateway/LeadContact/DownloadFile?patientId=' + this.commonService.DataToEncrypt(id, true) + '&documentId=' + this.commonService.DataToEncrypt(documentType, true), { responseType: 'blob' });    
  }
  CheckGeneratedPdfExist(id, documentType): Observable<any> {
    return this.http.get(environment.BaseURI + 'gateway/LeadContact/CheckGeneratedPdfExist?patientId=' + this.commonService.DataToEncrypt(id, true) + '&documentId=' + this.commonService.DataToEncrypt(documentType, true));    
  }
  getPrintPDFFile(id, documentType): Observable<any> {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetPatientInfoPDFFile?patientId=' + this.commonService.DataToEncrypt(id, true) + '&documentId=' + this.commonService.DataToEncrypt(documentType, true));
  }
  getPatientInfoPDFFileHistory1(id, documentType): Observable<any> {
    return this.http.get(environment.BaseURI + 'gateway/LeadContact/DownloadFileHistory1?patientId=' + this.commonService.DataToEncrypt(id, true) + '&documentId=' + this.commonService.DataToEncrypt(documentType, true), { responseType: 'blob' });    
 }
 getPrintPDFFileHistory1(id, documentType): Observable<any> {
  return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetPatientInfoPDFFileHistory?patientId=' + this.commonService.DataToEncrypt(id, true) + '&documentId=' + this.commonService.DataToEncrypt(documentType, true));
}
getPatientInfoPDFFileHistory(id, documentType): Observable<any> {
  return this.http.get(environment.BaseURI + 'gateway/LeadContact/DownloadFileHistory1?patientId=' + this.commonService.DataToEncrypt(id, true) + '&documentId=' + this.commonService.DataToEncrypt(documentType, true), { responseType: 'blob' });    
}

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../common/common.service';
import { ClinicianService } from '../staff/clinician/clinician.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '../services/snackBar.service';
import moment from 'moment';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {
  grpTherapyId: any;
  dayScheduleSubGroupId: any;
  scheduleDate:any;
  isAdmin:boolean = false;
  isClinician = false;
  CheckInList:any;
  selectedTab:any;
  CurrentCheckInData: any;
  constructor(private route: ActivatedRoute,public commonService: CommonService,public clinicianservice: ClinicianService, 
    private router: Router,private snackbar: SnackBarService,public spinnerservice: NgxSpinnerService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        if(params.id){
          this.grpTherapyId = JSON.parse(this.commonService.FrontEndDecryption(params.id));
        }
        if (params.dayScheduleSubGroupId) {
          this.dayScheduleSubGroupId = JSON.parse(this.commonService.FrontEndDecryption(params.dayScheduleSubGroupId));
        }
        if(params.encDate){
          this.scheduleDate = JSON.parse(this.commonService.FrontEndDecryption(params.encDate));
        }
        if(params.IsAdmin){
          this.isAdmin = JSON.parse(this.commonService.FrontEndDecryption(params.IsAdmin))
          this.getCheckInDetailsAdmin();
        }
        if(params.IsClinician){
          this.isClinician = JSON.parse(this.commonService.FrontEndDecryption(params.IsClinician));
          this.getCheckInDetailsClinician();
        }
      }
    });
    
  }
  onTabChangeProgress(tab) {
    this.spinnerservice.show();
    this.selectedTab = tab;
    this.CurrentCheckInData = this.CheckInList[tab];
    this.spinnerservice.hide();
  }
  getCheckInDetailsClinician(){
    this.clinicianservice.getCheckInDetails(this.dayScheduleSubGroupId).subscribe(
      (res: any) => {
        if (res) {
          this.CheckInList = res;
        }
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  getCheckInDetailsAdmin(){
    this.clinicianservice.getAdminCheckInDetails(this.grpTherapyId,this.scheduleDate).subscribe(
      (res: any) => {
        if (res) {
          this.CheckInList = res;
        }
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  selectUnselectAction(event,item) {
    event.value = !event.value;
    if(event.value){
      item.groupDetails.checkInCount += 1;
    }
    else {
      item.groupDetails.checkInCount -= 1;

    }
  }
  onSave(){
    this.spinnerservice.show();
    this.clinicianservice.PostCheckInDetails(this.CurrentCheckInData).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res) {
          this.snackbar.success(res);
        }
      },
      err => {
        this.snackbar.error(err);

        this.spinnerservice.hide();

      },
    );
  }
  onCancel(){
    if(this.isAdmin){
      this.router.navigate(['/scheduler']);

    }
    else{
      this.router.navigate(['/clinician']);
    }
  }
  getEndTime(date,duration){
    return (moment(date)).add(duration, 'minutes').format('hh:mm A')
    
  }

}

<div class="location mt-2">
  <div class="col-md-12">
    <div class="head-section mb-2 mt-0" >CREATE MEETING ROOM</div>
  </div>
  <form (ngSubmit)="locationinfo.form.valid " id="locationinfo" autocomplete="off"
    #locationinfo="ngForm" novalidate>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12 pr-0">
          <div class="row">
            
            <div class="col-md-4">
              <mat-form-field class="w-100">
                <mat-label>Meeting Room Name</mat-label>
                <input matInput maxlength="30" appAlphabetOnly [(ngModel)]="_Dtolocation.meetingName"
                  #locationName="ngModel" name="locationName"
                  [ngClass]="{ 'is-invalid': locationinfo.submitted && locationName.invalid }" required>
                <mat-error *ngIf="locationinfo.submitted && locationName.invalid">
                  <mat-error *ngIf="locationName.errors.required">Meeting room name is required
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field class="w-100">
                <mat-label>Location</mat-label>
                <!-- <mat-select [(ngModel)]="_Dtolocation.facilityTypeId" #facilityTypeId="ngModel" name="facilityTypeId"
                  [ngClass]="{ 'is-invalid': locationinfo.submitted && facilityTypeId.invalid }" required>
                  <ng-container *ngFor="let a of ddlfacility">
                    <mat-option *ngIf="a.IsActive" [value]="a.FacilityTypeId">
                      {{a.FacilityName}}
                    </mat-option>
                  </ng-container>
                </mat-select> -->
                <mat-select multiple [disabled]="isGlobalAccess" [(ngModel)]="_Dtolocation.locationId"
                        (change)="locationchange($event)"  #location="ngModel" name="location" [required]="!isGlobalAccess">

                            <mat-option #matOption *ngFor="let item of ddllocation"
                                    [value]="item.sfccMasterId"
                                    [matOption.selected]="item.isActive">
                                    {{item.locationName}}
                            </mat-option>
                            </mat-select>
                <mat-error *ngIf="locationinfo.submitted && location.invalid">
                  <mat-error *ngIf="location.errors.required">Location is required
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-1 p-0">
              <div class="check-custom w-100 mt-4">
                <mat-checkbox class="example-margin" [(ngModel)]="_Dtolocation.accessLevel"
                    (change)="onchangeAccesslvl($event)" name="accessLevel">
                    Global
                </mat-checkbox>
            </div>
            </div>
            <div class="col-md-3">
              <mat-form-field class="w-100">
                <mat-label>Seating Capacity</mat-label>
                <input matInput [(ngModel)]="_Dtolocation.seatingCapacity" customMin="1" maxlength="4" numeric
                  #seatingCapacity="ngModel" name="seatingCapacity"
                  [ngClass]="{ 'is-invalid': locationinfo.submitted && seatingCapacity.invalid }" required>
                <mat-error *ngIf="locationinfo.submitted && seatingCapacity.invalid">
                  <mat-error *ngIf="seatingCapacity.errors.required">Seat Capacity is required
                  </mat-error>
                  <mat-error *ngIf="seatingCapacity.errors?.customMin">Minimum required number is 1.
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-1 p-0">
              <div class="check-custom mt-3 pt-1">

                <mat-checkbox class="example-margin" [(ngModel)]="_Dtolocation.isActive" name="isActive">
                  Active
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right p-0"  *ngIf="roleAccess.isEdit ||  roleAccess.isCreate">
          <button type="button" class="btn btn-cancel float-right" title="Cancel"
            (click)="resetLocationform(locationinfo)">Cancel</button>
          <button type="submit" class="btn primary-btn-filled float-right mr-2" title="{{!isEditLocation ? 'Save' :
            'Update'}}" (click)="postMeetingLocation(locationinfo)"   [ngClass]="{'disabled': onpostmeetinglocation }">{{!isEditLocation ? 'Save' :
            'Update'}}</button>
        </div>
      </div>
    </div>
  </form>

  <div>
    <div class="col-md-12 mt-2">
      <div class="box-lead-head">Meeting Locations</div>
    </div>
    <div class="col-md-12  table-custom p-0">
      <div class="table-responsive">
        <mat-table #table [dataSource]="dataSource" matSort class="w-100">
          <ng-container matColumnDef="sno">
            <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="location">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Location </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.location}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="meetingName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Meeting Room Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.meetingName}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="seatCapacity">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Seat Capacity </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.seatingCapacity}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="IsActive">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Active </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.isActive == 1 ? 'Yes':'No'}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions"  >
            <mat-header-cell *matHeaderCellDef > {{ (roleAccess.isEdit || roleAccess.isDelete) ? 'Actions' : '' }}   </mat-header-cell>
            <mat-cell *matCellDef="let element;let i = index;">
              <button type="button" mat-icon-button matTooltip="Edit" (click)="oneditLocation(element)" color="primary" *ngIf="roleAccess.isEdit">
                <img src="../../../assets/images/editblue.png">
              </button>
              <button type="button" mat-icon-button matTooltip="Delete" color="warn" *ngIf="roleAccess.isDelete"
                (click)="ondeleteLocation(element)">
                <img src="../../../assets/images/deletered.png">
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>
      </div>
    </div>
    <mat-paginator [pageSize]="20"[pageSizeOptions]="[ 10, 20, 50, 75, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
<div class="col-md-12 Treatment-plan">
    <div class="top-btn-section clearfix mb-2">
        <div class="col-md-4 ml-auto pr-0">
           
          <button class="btn back-btn-new mr-2 " (click)="onbacktoMytask()"><i
              class="fas fa-chevron-left mr-2"></i>Back</button>
              <button type="submit"
              class="btn back-btn-new mr-2 "
              (click)="generatePdf()"
            >
              Generate PDF</button>

    
        </div>
    
      </div>
    <div class="top-btn-section clearfix mb-2">
        <h2 class="head-section mb-2 mt-2">Treatment</h2>
    </div>
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-3 profile-patient" [ngClass]="{'d-none':isToogleInfo}">
                <div class="card">
                    <div class="card-body">
                        <div class="m-t-30 text-center">
                            <img src="https://bootdey.com/img/Content/avatar/avatar7.png" class="rounded-circle"
                                width="100">
                            <div class="patient-info mt-3">
                                <h5 class="card-title "><b class="mr-2"> Name</b>{{_dtoTreatmentInfo?.patientName}}</h5>
                                <h5 class="card-title "><b class="mr-2"> Age</b>{{_dtoTreatmentInfo?.dob}} Years</h5>
                                <h5 class="card-title "><b class="mr-2"> Email</b>{{_dtoTreatmentInfo?.email}}</h5>
                                <h5 class="card-title "><b class="mr-2"> Phone</b>+1 {{_dtoTreatmentInfo?.contactNo|
                                    mask: '(000)
                                    000-0000'}}</h5>
                            </div>
                            <!--<div class="patient-info mt-3">
                    <ul>
                      <li><span class="info-head">Name</span> <span class="info-val">John Doe</span></li>
                      <li><span class="info-head">Age</span> <span class="info-val">38 Years, Male</span></li>
                    <li><span class="info-head">Phone</span> <span class="info-val">+1 952 001 8563</span></li>
                    <li><span class="info-head">Email</span> <span class="info-val">hannagover@gmail.com</span></li>
                    </ul>
                    </div>-->
                        </div>
                    </div>

                    <div class="card-body pt-0">
                        <small class="text-muted">Staff Completing Form</small>
                        <h6>{{_dtoTreatmentInfo?.staffName}}</h6>
                        <small class="text-muted p-t-30 db">Treatment Plan Date</small>
                        <h6>{{_dtoTreatmentInfo?.treatmentPlanDate | date:'MM/dd/yyyy'}}</h6>
                       

                    </div>
                    <div class="icon-buttons-treat">
                        <a title="Generate Intake" (click)="onGenerateIntake(_dtoTreatmentInfo)" *ngIf="MenuAccess.GenerateIntake"><span><i
                                    class="fas fa-notes-medical "></i></span></a>
                        <a title="Pain Assessment" (click)="onPainAssessment(_dtoTreatmentInfo)" *ngIf="MenuAccess.PainAssessment"><span><i
                                    class="fas fa-user-injured "></i></span></a>
                        <a title="Prepare CHMA" (click)="onintakechma(_dtoTreatmentInfo)" *ngIf="MenuAccess.PrepareCHMA" class="list-item-cmha">
                            <!-- <span><i
                                    class="fas fa-procedures "></i></span> -->
                                    <span class="menu-icon">
                                        <img
                                        src="../../../../assets/images/cmha.png"
                                          alt="cmha"
                                          class="active-img mb-1"
                                        />
                                        <img
                                          src="../../../../assets/images/cmha.png"
                                          alt="cmha"
                                          class="inactive-img mb-1"
                                        />
                                    </span>
                                </a>
                        <a title="Nutritional Question" (click)="onNutritional(_dtoTreatmentInfo)" *ngIf="MenuAccess.NutritionalQuestion"><span><i
                                    class="far fa-question-circle "></i></span></a>

                    </div>
                </div>


            </div>
            <div class="col-md-9 p-0" [ngClass]="{'col-md-12':isToogleInfo,'col-md-9':!isToogleInfo}">
                <div class="wrap-tog-btn" [ngClass]="{'active':isToogleInfo}" (click)="isToogleInfo = !isToogleInfo">
                    <div class="tog-btn"><i class="fa fa-bars"></i></div>
                </div>
                <div class="mb-2 d-flex justify-content-center">
                    <div class="card p-2 w-100" [ngClass]="{'d-none':!isToogleInfo}">
                        <div class="d-flex align-items-center">
                            <div class="image"> <img src="https://bootdey.com/img/Content/avatar/avatar7.png"
                                    class="rounded" width="60"> </div>
                            <div class="ml-3 w-100 d-flex">
                                <div class="mr-3 mt-3">
                                    <h5 class="mb-0 mt-0 text-md">{{_dtoTreatmentInfo?.patientName}}</h5>
                                    <span class="text-xs text-dark font-weight-500">{{_dtoTreatmentInfo?.dob}}
                                        Years</span>
                                </div>



                                <div class="p-2   d-flex flex-row rounded text-white ">
                                    <div class="d-flex flex-column mr-3 stats p-2"> <span class="articles">Treatment Plan Name</span> <span
                                        class="number1 text-sm">{{_dtoTreatmentInfo?.mtpName}}</span> </div>

                                    <div class="d-flex flex-column mr-3 stats p-2"> <span class="articles">Staff
                                            Completing Form</span> <span
                                            class="number1 text-sm">{{_dtoTreatmentInfo?.staffName}}</span> </div>
                                    <div class="d-flex flex-column  stats p-2"> <span class="followers">Treatment Plan
                                            Date</span> <span
                                            class="number2 text-sm">{{_dtoTreatmentInfo?.treatmentPlanDate |
                                            date:'MM/dd/yyyy'}} <span>
                                                <a
                                                  class="icon-note cursor-pointer ml-2"
                                                  title="Edit Treatment Plan"
                                                 (click)="OnTreatmentEdit()"
                                                  ><span class="mr-2"> <i class="far fa-edit"></i></span
                                                ></a>
                                              </span></span>   </div>
                                           
                                </div>
                                <div class="icon-buttons-treat d-flex mt-2">
                                    <a title="Generate Intake" (click)="onGenerateIntake(_dtoTreatmentInfo)" *ngIf="MenuAccess.GenerateIntake"><span><i
                                                class="fas fa-notes-medical "></i></span></a>
                                    <a title="Pain Assessment" (click)="onPainAssessment(_dtoTreatmentInfo)" *ngIf="MenuAccess.PainAssessment"><span><i
                                                class="fas fa-user-injured "></i></span></a>
                                    <a title="Prepare CHMA" (click)="onintakechma(_dtoTreatmentInfo)" *ngIf="MenuAccess.PrepareCHMA" class="list-item-cmha">
                                        <!-- <span>
                                        <i
                                                class="fas fa-procedures "></i>
                                            </span> -->
                                            <span class="menu-icon">
                                                <img
                                                src="../../../../assets/images/cmha.png"
                                                  alt="cmha"
                                                  class="active-img mb-1"
                                                />
                                                <img
                                                  src="../../../../assets/images/cmha_hover_img.png"
                                                  alt="cmha"
                                                  class="inactive-img mb-1"
                                                />
                                            </span>
                                        </a>
                                    <a title="Nutritional Question" (click)="onNutritional(_dtoTreatmentInfo)" *ngIf="MenuAccess.NutritionalQuestion"><span>
                                        <i
                                                class="far fa-question-circle "></i></span></a>
                                               
                                </div>
                               

                            </div>
                            <div class="float-right mr-4">
                                <button type="button" mat-stroked-button
                                  class="btn primary-btn-filled primary-btn-m mb-2 h-40 ml-4" (click)="newMTP()" >Create New MTP</button>
                  
                              </div>
                        </div>
                       
                    </div>
                </div>
               
                <div class="tabs-newd">


                    <input type="radio" id="tab1" name="tab-control" checked>
                    <input type="radio" id="tab2" name="tab-control">
                    <input type="radio" id="tab3" name="tab-control">
                    <input type="radio" id="tab4" name="tab-control">
                    <input type="radio" id="tab5" name="tab-control">
                    <input type="radio" id="tab6" name="tab-control">
                    <ul>
                        <li *ngIf="IsDiagonasis" title="Diagnosis Information" (click)="onTabChange(1)"><label   role="button">
                                <svg viewBox="0 0 24 24 ">
                                    <path fill="#01ef07"
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg>
                                <br><span>Diagnosis Information</span></label></li>
                        <li *ngIf="!IsDiagonasis" title="Diagnosis Information" (click)="onTabChange(1)"><label   role="button">
                                <svg viewBox="0 0 24 24 ">
                                    <path
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg>
                                <br><span>Diagnosis Information</span></label></li>
                        <li *ngIf="IsModality" title="Modality" (click)="onTabChange(2)"><label role="button"><svg
                                    viewBox="0 0 24 24">
                                    <path fill="#01ef07"
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg><br><span>Modality</span></label></li>
                        <li *ngIf="!IsModality" title="Modality" (click)="onTabChange(2)"><label role="button"><svg
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg><br><span>Modality</span></label></li>
                        <li *ngIf="IsGoal" title="Goals Information" (click)="onTabChange(3)" ><label  role="button"><svg
                                    viewBox="0 0 24 24 ">
                                    <path fill="#01ef07"
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg><br><span>Goals Information</span></label></li>
                        <li *ngIf="!IsGoal" title="Goals Information" (click)="onTabChange(3)" ><label  role="button"><svg
                                    viewBox="0 0 24 24 ">
                                    <path
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg><br><span>Goals Information</span></label></li>
                        <li *ngIf="IsPresentingProblem" title="Presenting Problems" (click)="onTabChange(4)"><label 
                                role="button"><svg viewBox="0 0 24 24">
                                    <path fill="#01ef07"
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg><br><span>Presenting Problems</span></label></li>
                        <li *ngIf="!IsPresentingProblem" title="Presenting Problems" (click)="onTabChange(4)"><label 
                                role="button"><svg viewBox="0 0 24 24">
                                    <path
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg><br><span>Presenting Problems</span></label></li>
                        <li *ngIf="IsGoal" title="Configured Goals" (click)="onTabChange(5)"><label  role="button"><svg
                                    viewBox="0 0 24 24">
                                    <path fill="#01ef07"
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg><br><span>Configured Progress Tracker</span></label></li>
                        <li *ngIf="!IsGoal" title="Configured Goals" (click)="onTabChange(5)"><label  role="button"><svg
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg><br><span>Configured Progress Tracker</span></label></li>
                        <li *ngIf="IsConsent" title="Consent" (click)="onTabChange(6)" ><label role="button"><svg
                                    viewBox="0 0 24 24">
                                    <path fill="#01ef07"
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg><br><span>Consent</span></label></li>
                        <li *ngIf="!IsConsent" title="Consent" (click)="onTabChange(6)"><label  role="button"><svg
                                    viewBox="0 0 24 24">
                                    <path
                                        d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg><br><span>Consent</span></label></li>
                    
                    </ul>

                    <div class="slider">
                        <div class="indicator"></div>
                    </div>
                    <!-- <div class="content">
                        <section *ngIf="IsDiagonasisopen">
                            <app-treatmentdiagnosis [patientAccountId]="patientAccountId"
                                [patientInfoId]="patientInfoId" [locationId]="LocationId" >
                            </app-treatmentdiagnosis>
                        </section>
                            <section *ngIf="Istreatmentmodalityopen">

                                <app-treatmentmodality [patientAccountId]="patientAccountId"
                                        [patientInfoId]="patientInfoId" 
                                        >
                                    </app-treatmentmodality>
                               </section> 
                      
                            <section *ngIf="IsGoalsinfoopen">
                                <app-treatmentgoals [patientAccountId]="patientAccountId" [patientInfoId]="patientInfoId"
                               >
                                </app-treatmentgoals>
                            </section>
                            <section *ngIf="IsPresentingProblemopen" >

                                <app-treatment-presenting-problems [patientAccountId]="patientAccountId" [chmaTreatmentSummaryId]="chmaTreatmentSummaryId"
                                ></app-treatment-presenting-problems>
                                  
                              </section>
                        <section *ngIf="Istreatmentgoalconfigopen">
                            <app-treatment-goal-config >
                              
                            </app-treatment-goal-config>
                          </section>
                            <section *ngIf="Isconsentopen">
                                <app-treatmentconsent [patientAccountId]="patientAccountId" [patientInfoId]="patientInfoId" 
                                >
                                </app-treatmentconsent>
                              </section>
                    </div> -->
                    <div class="content">
                        <div *ngIf="IsDiagonasisopen" >
                            <app-treatmentdiagnosis [patientAccountId]="patientAccountId"
                                [patientInfoId]="patientInfoId" [locationId]="LocationId" >
                            </app-treatmentdiagnosis>
                        </div>
                            <div *ngIf="Istreatmentmodalityopen" >

                                <app-treatmentmodality [patientAccountId]="patientAccountId"
                                        [patientInfoId]="patientInfoId" 
                                        >
                                    </app-treatmentmodality>
                                </div> 
                      
                            <div *ngIf="IsGoalsinfoopen" >
                                <app-treatmentgoals [patientAccountId]="patientAccountId" [patientInfoId]="patientInfoId"
                               >
                                </app-treatmentgoals>
                            </div>
                            <div *ngIf="IsPresentingProblemopen" >

                                <app-treatment-presenting-problems [patientAccountId]="patientAccountId" [chmaTreatmentSummaryId]="chmaTreatmentSummaryId"
                                ></app-treatment-presenting-problems>
                                  
                            </div>
                        <div *ngIf="Istreatmentgoalconfigopen" >
                            <app-treatment-goal-config >
                              
                            </app-treatment-goal-config>
                        </div>
                            <div *ngIf="Isconsentopen" >
                                <app-treatmentconsent [patientAccountId]="patientAccountId" [patientInfoId]="patientInfoId" 
                                >
                                </app-treatmentconsent>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>







  <div class="mtp-popup">
    <div class="modal" tabindex="-1" role="dialog" id="newMTP" [style.display]="isShowNewMTP ? 'block' : 'none'">
      <div class="modal-dialog mat-dialog-container" role="document">
        <div class="modal-content border-0">
           <div class="modal-header border-0">
             <h5>Create New MTP</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            (click)="closePopup()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-2" >
                <div class="col-md-12">
                   
                    <div class="col-md-3">
                        <mat-form-field class="w-100" >
                            <mat-label>MTP Name</mat-label>
                         <input matInput  maxlength="80" (input)="mtperrorchange()"
                         [(ngModel)]="MTPName" specialIsAlphaNumeric
                         name="MTPName">
                        </mat-form-field>
                        <span *ngIf="isMTP" class="text-danger">MTP Name is
                            required</span>
                    </div>                     
                  
                        
                    
               
                </div>
                <div class="col-md-12 p-2">
                    <div class="additional-steps">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Primary Clinician</label>
                                        <div class="col-md-12 ">
                                            <mat-form-field class="w-100">
                                                <mat-select (selectionChange)="errormsgChange()"[(ngModel)]="primaryClinicianId" #PrimaryClinician="ngModel"
                                                    name="PrimaryClinician">
                                                    <mat-option *ngFor="let item of clinicianDetails" [value]="item.userId">
                                                        {{item.firstName}} {{item.lastName}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <span *ngIf="isprimaryClinician" class="text-danger">Primary clinician is
                                                required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Treatment Plan
                                            Date</label>
                                        <div class="col-md-12 ">
                                            <mat-form-field class="w-100">
                                                <mat-label>Choose a date</mat-label>
                                                <input matInput [matDatepicker]="picker" readonly
                                                    (click)="picker.open()" [(ngModel)]="treatmentPlanDate"
                                                    #TreatmentPlanDate="ngModel" name="TreatmentPlanDate" (dateChange)="onDateChange()"
                                                    [ngClass]="{ 'is-invalid': TreatmentPlanDate.invalid }" >
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                            <span *ngIf="istreatmentPlanDate" class="text-danger">Treatment Plan Date is
                                                required</span>
                                        </div>
                                    </div>
                                </div>
    
    
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Overarching period end by
                                        </label>
                                        <div class="col-md-12 ">
                                          
                                            <mat-form-field class="w-100">
                                                <mat-label>Choose a date</mat-label>
                                            
                                                <input matInput [matDatepicker]="picker1" readonly
                                                    (click)="picker1.open()" [(ngModel)]="overarchingPeriod"
                                                    #OverarchingPeriod="ngModel" name="OverarchingPeriod" 
                                                    (dateChange)="checkValidation(treatmentPlanDate,overarchingPeriod,1)"
                                                    [ngClass]="{ 'is-invalid': OverarchingPeriod.invalid }">
                                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                            <span *ngIf="overarchingDateExist" class="text-danger">Overarching Period Date is
                                                required</span>
                                                <span *ngIf="isDateValidate" class="text-danger">
                                                    The overarching period end by date should not be greater than the treatment plan date.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 p-2">
                    <div class="additional-steps">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Secondary Clinician</label>
                                        <div class="col-md-12 ">
                                            <mat-form-field class="w-100">
                                                <mat-select (selectionChange)="errormsgChangeSecondary()"[(ngModel)]="secondaryClinicianId" #SecondaryClinician="ngModel"
                                                    name="SecondaryClinician">
                                                    <mat-option *ngFor="let item of clinicianDetails" [value]="item.userId">
                                                        {{item.firstName}} {{item.lastName}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <span *ngIf="isSecondaryClinician" class="text-danger">Secondary clinician is
                                                required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Treatment Plan
                                            Date</label>
                                        <div class="col-md-12 ">
                                            <mat-form-field class="w-100">
                                                <mat-label>Choose a date</mat-label>
                                                <input matInput [matDatepicker]="picker2" readonly
                                                    (click)="picker2.open()" [(ngModel)]="treatmentPlanDateSecondary"
                                                    #TreatmentPlanDateSecondary="ngModel" name="TreatmentPlanDateSecondary" (dateChange)="onDateChangeSecondary()"
                                                    [ngClass]="{ 'is-invalid': TreatmentPlanDateSecondary.invalid }" >
                                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                                <mat-datepicker #picker2></mat-datepicker>
                                            </mat-form-field>
                                            <span *ngIf="istreatmentPlanDateSecondary" class="text-danger">Treatment Plan Date is
                                                required</span>
                                        </div>
                                    </div>
                                </div>
    
    
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Overarching period end by
                                        </label>
                                        <div class="col-md-12 ">
                                          
                                            <mat-form-field class="w-100">
                                                <mat-label>Choose a date</mat-label>
                                            
                                                <input matInput [matDatepicker]="picker3" readonly
                                                    (click)="picker3.open()" [(ngModel)]="overarchingPeriodSecondary"
                                                    #OverarchingPeriodSecondary="ngModel" name="OverarchingPeriodSecondary" 
                                                    (dateChange)="checkValidation(treatmentPlanDateSecondary,overarchingPeriodSecondary,2)"
                                                    [ngClass]="{ 'is-invalid': OverarchingPeriodSecondary.invalid }">
                                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                                <mat-datepicker #picker3></mat-datepicker>
                                            </mat-form-field>
                                            <span *ngIf="overarchingDateExistSecondary" class="text-danger">Overarching Period Date is
                                                required</span>
                                                <span *ngIf="isDateValidate1" class="text-danger">
                                                    The overarching period end by date should not be greater than the treatment plan date.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span *ngIf="isClinicianSame" class="text-danger"> Primary and Secondary clinician is
                            same</span>
                    </div>
                </div>
    
            </div>
            <div class="col-md-12">
                <div class="text-right">
                   
                    <button type="button" (click)="onSaveChmadetails()"  mat-stroked-button
                        class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Submit</button>
        
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 








  <div class="treatment-edit">
    <div class="modal treatmentedit-modal" tabindex="-1" role="dialog" id="treatmentedit" [style.display]="isTreatmentEdit ? 'block' : 'none'">
      <div class="modal-dialog mat-dialog-container" role="document">
        <div class="modal-content">
           <div class="modal-header">
             <h5>Edit Treatment Plan Date</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            (click)="closePopupTreatmentEdit()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-2" >
               
                <div class="col-md-12 p-2">
                    <div class="additional-steps">
                        <div class="col-md-12">
                            <div class="row">
                            
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Treatment Plan
                                            Date</label>
                                        <div class="col-md-12 treatment-edit-date">
                                            <mat-form-field class="w-100">
                                                <mat-label>Choose a date</mat-label>
                                                <input matInput [matDatepicker]="picker5" readonly
                                                    (click)="picker5.open()" [(ngModel)]="treatmentPlanDateEdit"
                                                     name="treatmentPlanDateEdit" (dateChange)="onDateChangeEdit()"
                                                    >
                                                <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                                                <mat-datepicker #picker5></mat-datepicker>
                                            </mat-form-field>
                                            <span *ngIf="istreatmentPlanDate" class="text-danger">Treatment Plan Date is
                                                required</span>
                                        </div>
                                    </div>
                                </div>
    
    
                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Overarching period end by
                                        </label>
                                        <div class="col-md-12 ">
                                          
                                            <mat-form-field class="w-100">
                                                <mat-label>Choose a date</mat-label>
                                            
                                                <input matInput [matDatepicker]="picker4" readonly
                                                    (click)="picker4.open()" [(ngModel)]="overarchingDateEdit"
                                                    name="overarchingDateEdit" 
                                                    (dateChange)="checkValidation(treatmentPlanDateEdit,overarchingDateEdit,3)"
                                                    >
                                                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                                <mat-datepicker #picker4></mat-datepicker>
                                            </mat-form-field>
                                            <span *ngIf="overarchingDateExist" class="text-danger">Overarching Period Date is
                                                required</span>
                                                <span *ngIf="isDateValidateEdit" class="text-danger">
                                                    The overarching period end by date should not be greater than the treatment plan date.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           
    
            </div>
            <div class="col-md-12">
                <div class="text-right">
                   
                    <button type="button" (click)="onEditChmadetails()"  mat-stroked-button
                        class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Update</button>
        
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 



  <div  *ngIf="isTreatmentEdit">
    <!-- Configure Form -->
    
<div class="mt--14 libraryImages-container">
    <div  class="modal-header p-2">
        <span >
            <h5  class="modal-title">Edit Treatment Plan </h5>
        </span>
        <button  type="button" mat-dialog-close data-dismiss="modal" aria-label="Close" class="close ">
            <span  aria-hidden="true">�</span></button>
        </div>
    <div class="col-md-12 ">
        <div class="col-md-12 p-0">
            <div class="top-notes d-flex w-100 position-relative mt-3">
            </div>
            <div class="task-create-section ">
                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="staticEmail" class="col-md-12 col-form-label">Treatment Plan
                            Date</label>
                        <div class="col-md-12 ">
                            <mat-form-field class="w-100 treatment-plan-section">
                                <mat-label>Choose a date</mat-label>
                                <input matInput [matDatepicker]="picker" readonly
                                    (click)="picker.open()" [(ngModel)]="treatmentPlanDate"
                                    #TreatmentPlanDate="ngModel" name="TreatmentPlanDate" (dateChange)="onDateChange()"
                                    [ngClass]="{ 'is-invalid': TreatmentPlanDate.invalid }" >
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <span *ngIf="istreatmentPlanDate" class="text-danger">Treatment Plan Date is
                                required</span>
                        </div>
                    </div>
                </div>


                <div class="col-md-3">
                    <div class="form-group row">
                        <label for="staticEmail" class="col-md-12 col-form-label">Overarching period end by
                        </label>
                        <div class="col-md-12 ">
                          
                            <mat-form-field class="w-100">
                                <mat-label>Choose a date</mat-label>
                            
                                <input matInput [matDatepicker]="picker1" readonly
                                    (click)="picker1.open()" [(ngModel)]="overarchingPeriod"
                                    #OverarchingPeriod="ngModel" name="OverarchingPeriod" 
                                    
                                    [ngClass]="{ 'is-invalid': OverarchingPeriod.invalid }">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                            <span *ngIf="overarchingDateExist" class="text-danger">Overarching Period Date is
                                required</span>
                        </div>
                    </div>
                </div>
            <div class="col-md-12 text-center position-relative mt-3">
                        <span>
                            <button type="submit" form="configureForm" mat-raised-button
                            class="primary-btn-outlined-small mr-2">Update</button>
                        </span>   
                           <!-- <button mat-raised-button class="primary-btn-outlined-small mr-2" (click)="resetnotesform()">Reset
                            </button>  -->                   
            </div>
        </div>
        </div>
    </div>
</div>
    
</div>


  <a mimetype="application/octet-stream" #downloadLink></a>
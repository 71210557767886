import { Medicationinfo, PsyMedFormInfo } from 'src/app/_models/_Treatment/_medication';
import { Component, OnInit, Input, ViewChild,ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { constantVariables } from 'src/app/constant/constant';
import { ClinicalNotes, Medication } from 'src/app/_models/_Treatment/_clinicalnotes';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { FormBuilder, NgForm } from '@angular/forms';
import { PsyEvaMedicationinfo, PsyEvaMentalStatusInfo, PsyEvaNotes } from 'src/app/_models/_Treatment/_evvaluation';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { DatePipe } from '@angular/common';
import { TreatmentService } from '../treatment.service';

@Component({
  selector: 'app-psychiatric-evaluation-progress-notes',
  templateUrl: './psychiatric-evaluation-progress-notes.component.html',
  styleUrls: ['./psychiatric-evaluation-progress-notes.component.scss']
})
export class PsychiatricEvaluationProgressNotesComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('PsyEvaFrm') PsyEvaFrm: NgForm;
  @ViewChild('PsyEvaMSFrm') PsyEvaMSFrm: NgForm;
  SelectedTab =1;
  selectedtheraphyid: any;
  selecteddate: any;
  selectedIntervention: string;
  selectedMsId: any;
  _dtoPatientInfo: any;
  PsyEvaInfo:PsyEvaNotes;
  PopUpPreview: boolean;
  PopUpPreviewMS:boolean;
  IseditPsyEva: boolean;
  goalsinfo: any;
  ddlyesno=constantVariables.YesNo;
  ddlRatings=constantVariables.Ratings;
  ddlAverage=constantVariables.Average;
  ddlgoodbehaviour=constantVariables.Goodbehavior;
  ddlorientationtime=constantVariables.OrientationTime;
  PsyEvaMSInfo:PsyEvaMentalStatusInfo;
  _mentalStatus: any;
  AppearanceGiven: boolean=false;
  Emotionalresponsegiven: boolean;
  Attitudegiven: boolean;
  MoodGiven: boolean;
  SpeechGiven: boolean;
  IseditPsyEvaMS: boolean;
  _dtoMedications: any;
  roleAccess: RoelAccess;
  pdfpsymedNotes: any;
  IsCloseWindow: boolean=false;
  ddlseverity: any;
  bg: any;
  progressUpg:boolean=false;
  userId: any; userName: any;
  updatedsignatureImg: any;
  signatureImg: any;
  isESignAttached: boolean;
  isformtype: string;
  defaultImage: any;
  secondaryId : any;
  AutoFillSS:boolean=false;
  SSignature:any;
  GetClinicianSign: any[];  
  constructor(public global: Global, public clinicianservice: ClinicianService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,public treatmentservice: TreatmentService,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,private formBuilder: FormBuilder
    , public roleaccessservice:PageAccessService, public datepipe : DatePipe) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.ProgressNoteFivepointRating;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.PsyEvaInfo  = new PsyEvaNotes();
    this.PsyEvaMSInfo = new PsyEvaMentalStatusInfo();
    this._dtoMedications = []
    this._dtoMedications.push(new PsyEvaMedicationinfo());
    this.userId = Number(localStorage.getItem('id'));
    this.getAutoFillSignature();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) { 
         this.selecteddate = JSON.parse(this.commonService.FrontEndDecryption(params.date));
        this.selectedtheraphyid = JSON.parse(this.commonService.FrontEndDecryption(params.theraphyid));
        this.selectedMsId= JSON.parse(this.commonService.FrontEndDecryption(params.id));
        if(params.IsFromHistory){
          this.IsCloseWindow = true;
        }
        this.selectedIntervention="";
        if ((this.datepipe.transform(new Date(), "yyyy-MM-dd")) < (this.datepipe.transform(this.selecteddate, "yyyy-MM-dd"))) {
          this.progressUpg = true;
        }
        this.getPatientInfo(); 
      }
    });
    this.getSeverityMaster();
  }
  OnTabchange(item){
    this.SelectedTab = item;
    if(this.SelectedTab ==2){
      this.getPsyEvaMS()
    }
    if(this.SelectedTab ==3){
      this.getpatientgoal()
    }
    
  }

  getPatientInfo()
  {
     this.spinnerservice.show();
    this.clinicianservice.getpsychaiatricEvapatientinfo(this.selectedMsId,this.selectedtheraphyid,this.selecteddate).subscribe(
      (res: any) => {
       // debugger;
        this.spinnerservice.hide();
        if (res){
         this._dtoPatientInfo=res;          
         this.PsyEvaInfo = res.dtoPsyEvaNotes
         if(this.PsyEvaInfo.psyEvaNotesId!=0 ){
          this.IseditPsyEva  = false
        }
        else{
         this.IseditPsyEva  = true
        }
        if(this.IseditPsyEva){
          this.PsyEvaInfo.evaDate = new Date()
          }
       
            this._dtoMedications = this.PsyEvaInfo.medication
           
        }
        this.getPsyEvaMS();
      },
        
        err => {
          this.spinnerservice.hide();
        },
      );
}  
PostPsyEvaFrm(val){
if(val != 2 && !this.PsyEvaFrm.valid){
  return;
}
else{ 
  if(val != 2)
  {
    this.spinnerservice.show();
  }
  
  //const dataToEncrypt = this.commonService.DataToEncrypt(this.PsyEvaInfo, false);
this.clinicianservice.PostPsyEvaNotes(this.PsyEvaInfo).subscribe(
  res=>{
    if(val != 2)
    {
      this.spinnerservice.hide();
      this.snackbar.success(res);
      this.getPatientInfo()
    }
},
    err => {
      if(val != 2)
      {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      }
    },)
}
}


OnbackClick(){
  if(this.IsCloseWindow){
    window.close();
  }
  else{
    this.router.navigate(['/clinician'],{queryParams:{menu:1}});

  }
 }
 OnpreviewClick(){
  this.PopUpPreview = true;
}
OnclosePopup(){
  this.PopUpPreview = false;
}

getpatientgoal(){
  this.spinnerservice.show();
//  console.log(this.userId )
  this.clinicianservice.getIndvFamgoals(this._dtoPatientInfo.patientAccountId,(this.selectedtheraphyid-1),this.selectedMsId,this.selecteddate ).subscribe(
    (res:any)=>{
      this.spinnerservice.hide();
     console.log(res)
     this.goalsinfo = res
     if(this.goalsinfo[0].staffInitials != ""){
     //  this.signatureImg = this.goalsinfo[0].staffInitials
       (document.getElementById('esign2') as HTMLImageElement).src =this.goalsinfo[0].staffInitials  
     }
    },
    err => {
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}


getPsyEvaMS(){
  this.spinnerservice.show()
  this.secondaryId = localStorage.getItem('id');
  this.clinicianservice.getpsychaiatricEvaMentalstatus(this.selectedMsId,this._dtoPatientInfo.meetingScheduleTimeId,(this.selectedtheraphyid-1),this.secondaryId).subscribe(
    (res:any)=>{
      this.spinnerservice.hide();
     console.log(res)
     this._mentalStatus = res
     this.PsyEvaMSInfo = res.psyEvaMentalstatus
     console.log(this._mentalStatus)
   
      if(this.PsyEvaMSInfo.staffInitial != ""){
        (document.getElementById('esign') as HTMLImageElement).src = this.PsyEvaMSInfo.staffInitial
      }
      if(this.PsyEvaMSInfo.staffInitial1 != ""){
        (document.getElementById('esign1') as HTMLImageElement).src = this.PsyEvaMSInfo.staffInitial1
      }
     if(this.PsyEvaMSInfo.psyEvaMentalStatusId!=0 ){
      this.IseditPsyEvaMS  = false
    }
    else{
     this.IseditPsyEvaMS  = true
    }
    if(this.IseditPsyEvaMS ==false){
      this.Onemotionalresponse(1);
      this.Onappearance(1);
      this.Onmoodchange(1);
      this.onattitude(1);
      this.onspeech(1);

    }

    },
    err => {
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}
postPsyEvaMentalStatus(val){
  
  if(val !=2 && (!this.PsyEvaMSFrm.valid || !this.AppearanceGiven || !this.SpeechGiven || !this.Attitudegiven || 
    !this.Emotionalresponsegiven || !this.MoodGiven )){return}
    else{
  console.log(this.PsyEvaMSInfo)
  this.PsyEvaMSInfo.appearance = []
    this._mentalStatus.appearance.forEach(element => {
      if(element.isChecked==true){
      this.PsyEvaMSInfo.appearance.push(element.id)
      }
    });
    this.PsyEvaMSInfo.emotionalResponse = []
    this._mentalStatus.emotionalResponse.forEach(element => {
      if(element.isChecked==true){
      this.PsyEvaMSInfo.emotionalResponse.push(element.id)
      }
    });
    this.PsyEvaMSInfo.attitue = []
    this._mentalStatus.attitude.forEach(element => {
      if(element.isChecked==true){
      this.PsyEvaMSInfo.attitue.push(element.id)
      }
    });
    this.PsyEvaMSInfo.mood = []
    this._mentalStatus.mood.forEach(element => {
      if(element.isChecked==true){
      this.PsyEvaMSInfo.mood.push(element.id)
      }
    });
    this.PsyEvaMSInfo.speech = []
    this._mentalStatus.speech.forEach(element => {
      if(element.isChecked==true){
      this.PsyEvaMSInfo.speech.push(element.id)
      }
    });
    this.PsyEvaMSInfo.appearance = this.PsyEvaMSInfo.appearance.toString();
    this.PsyEvaMSInfo.emotionalResponse = this.PsyEvaMSInfo.emotionalResponse.toString();
    this.PsyEvaMSInfo.attitue = this.PsyEvaMSInfo.attitue.toString();
    this.PsyEvaMSInfo.mood = this.PsyEvaMSInfo.mood.toString();
    this.PsyEvaMSInfo.speech = this.PsyEvaMSInfo.speech.toString();
    if(val !=2)
    {
      this.spinnerservice.show();
    }
   
    const dataToEncrypt = this.commonService.DataToEncrypt(this.PsyEvaMSInfo, false);
  this.clinicianservice.PostPsyEvaMentalStatus(this.PsyEvaMSInfo).subscribe(
    res=>{
      if(val !=2)
      {
        this.spinnerservice.hide();
        this.snackbar.success(res);
        this.getPsyEvaMS()
      }
  },
      err => {
        if(val !=2)
        {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        }
      },)
    }
}


Onappearance(val){
 
    this.AppearanceGiven = false
    for(let item of this._mentalStatus.appearance){
      if(item.isChecked == true){
        this.AppearanceGiven = true
      }
    }
if(this.PsyEvaMSInfo.isTatoos || this.PsyEvaMSInfo.isBirthmarks || this.PsyEvaMSInfo.isDentalCondition){
  this.AppearanceGiven = true
}
  // if(val == 2)
  // {
  //   this.postPsyEvaMentalStatus(2);
  // }
}

Onemotionalresponse(val){
  this.Emotionalresponsegiven = false
  for(let item of this._mentalStatus.emotionalResponse){
    if(item.isChecked == true){
      this.Emotionalresponsegiven = true
    }
  }
  // if(val == 2)
  // {
  //   this.postPsyEvaMentalStatus(2);
  // }
}
onattitude(val){
  this.Attitudegiven = false
  for(let item of this._mentalStatus.attitude){
    if(item.isChecked == true){
      this.Attitudegiven = true
    }
  }
  // if(val == 2)
  // {
  //   this.postPsyEvaMentalStatus(2);
  // }
}

Onmoodchange(val){
  this.MoodGiven = false
  for(let item of this._mentalStatus.mood){
    if(item.isChecked == true){
      this.MoodGiven = true
    }
  }
  // if(val == 2)
  // {
  //   this.postPsyEvaMentalStatus(2);
  // }
}
onspeech(val){
  this.SpeechGiven = false
  for(let item of this._mentalStatus.mood){
    if(item.isChecked == true){
      this.SpeechGiven = true
    }
  }
  // if(val == 2)
  // {
  //   this.postPsyEvaMentalStatus(2);
  // }
}

OnpreviewMS(){
  this.PopUpPreviewMS = true;
}
OnclosePopupMS(){
  this.PopUpPreviewMS = false
}
addMedicationInfo() {
  this._dtoMedications.push(new Medicationinfo())
}

removeclinicalinformation(data, index) {
if (data.psychiatricEvaMedDtlId != undefined) {
  this.removeexistMedinfo(data);
  this.getPatientInfo();
}
else{
  this._dtoMedications.splice(index, 1);
}

}

removeexistMedinfo(data) {
  this.spinnerservice.show();
  this.clinicianservice.removePsyEvaMed(data.psychiatricEvaMedDtlId).subscribe((res: any) => {
    this.spinnerservice.hide();
    this.getPatientInfo();
    
  },
    err => {
      this.spinnerservice.hide();
    }
  );
}


     
OnDownload(item){
  //this.downloadLink = new ElementRef();
  this.spinnerservice.show();
    this.clinicianservice.getPsychiatricEvaluationFormpdf(this.selectedMsId,this._dtoPatientInfo.meetingScheduleTimeId,this._dtoPatientInfo.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfpsymedNotes = res.filePath;
        if(item==1){
          window.open(this.pdfpsymedNotes, '_blank');
        }
        else if(item==0){        
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
}
print(){
  let printContents = document.getElementById('print-section').innerHTML;
     let originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
    // this.ShowPreview=true;
     window.location.reload();
    
}
printMS(){
  let printContents = document.getElementById('print-section2').innerHTML;
     let originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
    // this.ShowPreview=true;
     window.location.reload();
    
}
     
OnDownloadMS(item){
  //this.downloadLink = new ElementRef();
  this.spinnerservice.show();
    this.clinicianservice.getPsychiatricEvaluationMSpdf(this.selectedMsId,this._dtoPatientInfo.meetingScheduleTimeId,this._dtoPatientInfo.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfpsymedNotes = res.filePath;
        if(item==1){
          window.open(this.pdfpsymedNotes, '_blank');
        }
        else if(item==0){
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
}
getSeverityMaster(){
  this.spinnerservice.show()
  this.commonService.getSeverityMaster().subscribe(
    res => {
      if (res != null) {
    this.ddlseverity = res;
 this.spinnerservice.hide()
}},
err => {
  this.spinnerservice.hide();

})
}
onSelectionChanged(value){
  this.bg = this.ddlseverity.filter(x=>x.severityValue==value)[0].colocode;
  this.PsyEvaInfo.severity=+value;
 // this.PostPsyEvaFrm(2);
}
openDialog(item) {
  // this.global.isconsentesign = false;
  // const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
  //   { data: { parentinfoId: this.userId, isfutureSign: false, isParentsign: true }, 
  //   autoFocus: false 
  // });
  // dialogRef.afterClosed().subscribe(result => {
  //   this.isESignAttached = this.global.isconsentesign;
  //   if (this.global.isconsentesign != undefined && this.global.isconsentesign) {
    this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe(result => {
    this.isESignAttached = this.global.isconsentesign;
    if (this.global.isconsentesign != undefined && this.global.isconsentesign) {

      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
        
      this.signatureImg = this.updatedsignatureImg;
      if (item = 1) {
        this.PsyEvaMSInfo.staffInitial = this.updatedsignatureImg;
        this.PsyEvaMSInfo.isAttachEsign = true;
        (document.getElementById('esign') as HTMLImageElement).src = this.signatureImg;
      }
    }
    else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      if (item = 1) {
        this.PsyEvaMSInfo.staffInitial = this.global.consentesign;
        this.PsyEvaMSInfo.isAttachEsign = true;
        (document.getElementById('esign') as HTMLImageElement).src = this.signatureImg;
      }
    }
    else {
      this.signatureImg = this.defaultImage;
      this.PsyEvaMSInfo.isAttachEsign = true;
      // this._dtoPatientInfo.psychiatricNotes.staffInitial = this.defaultImage;
    }


  });
}
openDialog1(item) {
  // this.global.isconsentesign = false;
  // const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
  //   { data: { parentinfoId: this.userId, isfutureSign: false, isParentsign: true }, 
  //   autoFocus: false 
  // });
  this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe(result => {
    this.isESignAttached = this.global.isconsentesign;
    if (this.global.isconsentesign != undefined && this.global.isconsentesign) {

      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
        
      this.signatureImg = this.updatedsignatureImg;
      if (item = 1) {
        this.PsyEvaMSInfo.staffInitial1 = this.updatedsignatureImg;
        this.PsyEvaMSInfo.isAttachEsign = true;
        (document.getElementById('esign1') as HTMLImageElement).src = this.signatureImg;
      }
    }
    else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      if (item = 1) {
        this.PsyEvaMSInfo.staffInitial1 = this.global.consentesign;
        this.PsyEvaMSInfo.isAttachEsign = true;
        (document.getElementById('esign1') as HTMLImageElement).src = this.signatureImg;
      }
    }
    else {
      this.signatureImg = this.defaultImage;
      this.PsyEvaMSInfo.isAttachEsign = true;
      // this._dtoPatientInfo.psychiatricNotes.staffInitial = this.defaultImage;
    }


  });
}
secondarySign():any{
  if(this.PsyEvaMSInfo.licenseNumber != '' && this.PsyEvaMSInfo.licenseNumber != null ){
    return false;
  }
  else if(this.PsyEvaMSInfo.sSignature ){
   return false;
  }
  else{
    return true;
  }
}
getAutoFillSignature() {
  this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
      this.GetClinicianSign = res;

      if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
        this.AutoFillSS=false;
        this.SSignature=this.GetClinicianSign[0].clinicianSign;
      }
      else
      {
        this.AutoFillSS=true;
        this.SSignature='';
      }
  });
}

}

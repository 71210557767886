<div class="create-appointment mt-2">
    <div class="col-md-12">

        <div class="head-section mb-2 mt-0" >Create Appointment Type</div>
    </div>
    <form (ngSubmit)="appointmentinfo.form.valid" id="appointmentinfo"
        autocomplete="off" #appointmentinfo="ngForm" novalidate>
        <div class="col-md-12">

            <div class="row">
                <div class="col-md-4">
                    <!-- <ng-select autocomplete="off" [disabled]="isEditAppoinment" [items]="ddlappoinments"
                        placeholder="Appointment Name" appAlphabetOnly bindLabel="appointmentTypeName"
                        bindValue="appointmentTypeName" #appointmentTypesId="ngModel" name="appointmentTypesId"
                        [(ngModel)]="_DtoAppoinment.typeName" addTagText="Add item" [addTag]="addAppointmentnameTag"
                        (change)="postTypeName(_DtoAppoinment.typeName)" #typeName="ngModel" name="typeName" required
                        [ngClass]="{ 'is-invalid': appointmentinfo.submitted && typeName.invalid }">
                    </ng-select> -->
                    <mat-form-field class="w-100">
                        <mat-label>Appointment Name</mat-label>
                        <input matInput placeholder="" maxlength="50" [(ngModel)]="_DtoAppoinment.typeName"
                            #typeName="ngModel" name="typeName"  required>
                        <mat-error *ngIf="appointmentinfo.submitted && typeName.invalid">Appointment Name is required
                        <!-- <mat-error class="mat-form-field-subscript-wrapper" *ngIf="typeName.errors.required">
                                Appointment name is required  </mat-error> -->
                        </mat-error>
                    </mat-form-field>
                    <!-- <input matInput placeholder="" maxlength="50" appAlphabetOnly
                    [(ngModel)]="_DtoAppoinment.typeName" #firstName="ngModel" name="firstName"
                    [ngClass]="{ 'is-invalid': userinfo.submitted && firstName.invalid }" required>
                <mat-error *ngIf="userinfo.submitted && firstName.invalid">
                    <mat-error *ngIf="firstName.errors.required">First Name is required</mat-error>
                </mat-error> -->

                </div>
                <div class="col-md-3">
                    <mat-form-field class="w-100">
                        <mat-label class="control-label-new">Appointment Type</mat-label>
                        <mat-select [(ngModel)]="_DtoAppoinment.typeId" #typeId="ngModel" name="typeId" required>
                            <mat-option *ngFor="let item of ddlappointmenttype" [value]="item.appointmentTypeId">
                                {{item.appointmentTypeName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="appointmentinfo.submitted && typeId.invalid">
                            <mat-error *ngIf="typeId.errors.required">
                                Appointment Type is required
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3" *ngIf="_DtoAppoinment.typeId == 3">
                    <!-- <mat-form-field class="w-100">
                        <mat-label>Block type</mat-label>
                        <mat-select [(ngModel)]="_DtoAppoinment.blockTypeId" #blockTypeId="ngModel" name="blockTypeId"
                            required>
                            <mat-option *ngFor="let item of ddlmeetingblocks" [value]="item.blockTypeId">
                                {{item.blockTypeName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="appointmentinfo.submitted && blockTypeId.invalid">
                            <mat-error *ngIf="blockTypeId.errors.required">Block Type is required
                            </mat-error>
                        </mat-error>
                    </mat-form-field> -->
                    <ng-select autocomplete="off" class="block-type-design" [disabled]="isEditAppoinment"
                        [items]="ddlmeetingblocks" placeholder="Block type" appAlphabetOnly bindLabel="blockTypeName"
                        bindValue="blockTypeName" #blockTypeId="ngModel" name="blockTypeId"
                        [(ngModel)]="_DtoAppoinment.blockTypeName" addTagText="Add item"
                        [addTag]="addAppointmentnameTag" (change)="postTypeName(_DtoAppoinment.blockTypeName)"
                        #blockTypeName="ngModel" name="blockTypeName" required
                        [ngClass]="{ 'is-invalid': appointmentinfo.submitted && blockTypeName.invalid }">
                    </ng-select>
                    <mat-error *ngIf="appointmentinfo.submitted && blockTypeName.invalid">
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="blockTypeName.errors.required">Block
                            Type
                            is required
                        </mat-error>
                    </mat-error>

                </div>
                <div class="col-md-4" *ngIf="_DtoAppoinment.typeId == 3">

                    <!-- <mat-label>Block Reason</mat-label> -->
                    <ng-select autocomplete="off" [items]="ddlblockreason" maxlength="50"
                        placeholder="Block Reason Name" appAlphabetOnly bindLabel="reasonTypeName"
                        bindValue="reasonTypeName" #blockReasonTypeId="ngModel" name="blockReasonTypeId"
                        [(ngModel)]="_DtoAppoinment.reasonTypeName" addTagText="Add item"
                        [addTag]="blockReasonTypeIdTag"
                        (change)="postblockReasonTypeName(_DtoAppoinment.reasonTypeName)" required>
                    </ng-select>
                    <mat-error *ngIf="appointmentinfo.submitted && blockReasonTypeId.invalid">
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="blockReasonTypeId.errors.required">
                            Block reason is required
                        </mat-error>
                    </mat-error>

                </div>
                <div class="col-md-3" *ngIf="_DtoAppoinment.typeId == 3">
                    <mat-form-field class="w-100">
                        <mat-label>Description</mat-label>
                        <input matInput placeholder="" maxlength="50" [(ngModel)]="_DtoAppoinment.description"
                            #description="ngModel" name="description">
                    </mat-form-field>
                </div>
                <div class="col-md-1 p-0" *ngIf="_DtoAppoinment.typeId == 2">
                    <mat-form-field class="w-100">
                        <mat-label>No of Slots</mat-label>
                        <input matInput placeholder="" step="0" maxlength="2" numeric customMin="1" customMax="100"
                            [(ngModel)]="_DtoAppoinment.capacity" #capacity="ngModel" name="capacity">
                        <mat-error *ngIf="appointmentinfo.submitted && capacity.invalid">
                            <mat-error class="mat-form-field-subscript-wrapper mt-4" *ngIf="capacity.errors.required">
                                Capacity is
                                required
                            </mat-error>
                            <mat-error class="mat-form-field-subscript-wrapper mt-4" *ngIf="capacity.errors?.customMin">
                                Minimum number can be
                                1.
                            </mat-error>
                            <mat-error class="mat-form-field-subscript-wrapper mt-4" *ngIf="capacity.errors?.customMax">
                                Maximum number can be
                                100.
                            </mat-error>
                        </mat-error>
                    </mat-form-field>


                </div>
                <!-- <div class="col-md-3">
                    <mat-form-field class="w-100">
                        <mat-label>Meeting Category</mat-label>
                        <mat-select [(ngModel)]="_DtoAppoinment.meetingCategoryId" #meetingCategoryId="ngModel"
                            name="meetingCategoryId"
                            [ngClass]="{ 'is-invalid': appointmentinfo.submitted && meetingCategoryId.invalid }"
                            required>
                            <mat-option *ngFor="let item of ddlCategory" [value]="item.meetingCategoryId">
                                {{item.categoryName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="appointmentinfo.submitted && meetingCategoryId.invalid">
                            <mat-error *ngIf="meetingCategoryId.errors.required">Meeting category is required
                            </mat-error>
                        </mat-error>
                    </mat-form-field>

                </div> -->
                <div class="col-md-3">
                    <mat-form-field class="w-100">
                        <mat-label class="control-label-new">Location</mat-label>
                        <mat-select multiple [disabled]="isGlobalAccess" [(ngModel)]="_DtoAppoinment.locationId"
                            (selectionChange)="locationchange($event)" #location="ngModel" name="location"
                            [required]="!isGlobalAccess">

                            <mat-option #matOption (click)="loadClinician($event)" *ngFor="let item of ddllocation" [value]="item.sfccMasterId"
                                [matOption.selected]="item.isActive">
                                {{item.locationName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="appointmentinfo.submitted && location.invalid">
                            <mat-error *ngIf="location.errors.required">Location is required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-1 pr-0" *ngIf="_DtoAppoinment.typeId !== 5 && _DtoAppoinment.typeId !== 6">
                    <div class="check-custom w-100 mt-4">
                        <mat-checkbox class="example-margin" [(ngModel)]="_DtoAppoinment.accessLevel"
                            (change)="onchangeAccesslvl($event)" name="accessLevel">
                            Global
                        </mat-checkbox>
                    </div>

                </div>


            </div>

             <div class="row" *ngIf="_DtoAppoinment.typeId === 2 || _DtoAppoinment.typeId === 5 || _DtoAppoinment.typeId === 6  ">

                  <div class="col-md-2 " *ngIf="_DtoAppoinment.typeId === 2">
                    <mat-form-field class="w-100">
                        <mat-label class="">Start Date</mat-label>
                        <input matInput [matDatepicker]="pstpicker" readonly [min]="minDate"
                            (click)="pstpicker.open()" [(ngModel)]="_DtoAppoinment.startDate" #startDate="ngModel" (dateChange)="checkGroupMeetingExist()"
                            name="startDate" required>
                        <mat-datepicker-toggle matSuffix [for]="pstpicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pstpicker></mat-datepicker>

                    </mat-form-field>
                    <mat-error *ngIf="appointmentinfo.submitted && startDate.invalid">
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="startDate.errors.required">Start Date
                            is
                            required</mat-error>
                    </mat-error>
                </div>
                <div class="col-md-2 " *ngIf=" _DtoAppoinment.typeId === 5 || _DtoAppoinment.typeId === 6  ">
                    <mat-form-field class="w-100">
                        <mat-label class="">Start Date</mat-label>
                        <input matInput [matDatepicker]="pstpicker" readonly [min]="currentdate"
                            (click)="pstpicker.open()" [(ngModel)]="_DtoAppoinment.startDate" #startDate="ngModel"
                            name="startDate" required>
                        <mat-datepicker-toggle matSuffix [for]="pstpicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pstpicker></mat-datepicker>

                    </mat-form-field>
                    <mat-error *ngIf="appointmentinfo.submitted && startDate.invalid">
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="startDate.errors.required">Start Date
                            is
                            required</mat-error>
                    </mat-error>
                </div>
                <div class="col-md-2 mb-2 ">

                    <div class="">
                        <mat-form-field class="w-100">
                            <mat-label class="">Start Time
                            </mat-label>
                            <input matInput type="time" #startTime="ngModel" [(ngModel)]="_DtoAppoinment.startTime"
                                name="startTime" min="00:00" max="24:00" required>
                        </mat-form-field>
                        <mat-error *ngIf="appointmentinfo.submitted && startTime.invalid">
                            <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="startTime.errors.required">
                                Start Time is required
                            </mat-error>
                        </mat-error>


                    </div>

                </div>
                <div class="col-md-2 mb-2 ">

                    <div class="row">

                        <div class="col-md-6 pl-0">
                            <mat-form-field class="w-100">
                                <mat-label class="">Hours
                                </mat-label>
                                <!-- <mat-select name="duration" [(ngModel)]="_DtoAppoinment.duration" #duration="ngModel"
                                    required>
                                    <mat-option [value]="null">--Select Duration--</mat-option>
                                    <mat-option [value]=15>15 mins</mat-option>
                                    <mat-option [value]=30>30 mins</mat-option>
                                    <mat-option [value]=60>1 hr</mat-option>
                                    <mat-option [value]=120>2 hr</mat-option>
                                    <mat-option [value]=180>3 hr</mat-option>
                                    <mat-option [value]=240>4 hr</mat-option>
                                    <mat-option [value]=300>5 hr</mat-option>
                                    <mat-option [value]=360>6 hr</mat-option>
                                    <mat-option [value]=420>7 hr</mat-option>
                                    <mat-option [value]=480>8 hr</mat-option>
                                </mat-select> -->
                                <mat-select  name="duration"
                                [(ngModel)]="_DtoAppoinment.durationhr"  required>
                    <mat-option value="undefined">hr</mat-option>         
                    <mat-option value=0>0 hr</mat-option>
                    <mat-option value=60>1 hr</mat-option>
                    <mat-option value=120>2 hr</mat-option>
                    <mat-option value=180>3 hr</mat-option>
                    <mat-option value=240>4 hr</mat-option>
                    <mat-option value=300>5 hr</mat-option>
                    <mat-option value=360>6 hr</mat-option>
                    <mat-option value=420>7 hr</mat-option>
                    <mat-option value=480>8 hr</mat-option>
                 </mat-select>
                  <mat-error *ngIf="appointmentinfo.submitted && durationhr.invalid">
                                <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                    *ngIf="durationhr.errors.required">Hours is required
                                </mat-error>
                            </mat-error>
                            </mat-form-field>
                            <!-- <mat-error *ngIf="appointmentinfo.submitted && duration.invalid">
                                <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                    *ngIf="duration.errors.required">Duration is required
                                </mat-error>
                            </mat-error> -->
                           
                            
                        </div>
                        <div class="col-md-6 pl-0">
                            <mat-form-field class="w-100">
                                <mat-label class="">Mins
                                </mat-label>
                                <mat-select name="Mins"
                    [(ngModel)]="_DtoAppoinment.durationmins"  required>
                    <mat-option value="undefined">mins</mat-option>
                    <mat-option value=00>00 mins</mat-option>
                    <mat-option value=15>15 mins</mat-option>
                    <mat-option value=30>30 mins</mat-option>
                    <mat-option value=45>45 mins</mat-option>             
                 </mat-select>
                  <mat-error *ngIf="appointmentinfo.submitted && durationmins.invalid">
                                <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                    *ngIf="durationmins.errors.required">Mins is required
                                </mat-error>
                            </mat-error>
                            </mat-form-field>    
                        </div>
                    </div>
                </div>
               
               <div class="col-md-2" *ngIf="_DtoAppoinment.typeId == 2">
                    <mat-form-field class="w-100">
                        <mat-label>Clinician</mat-label>
                         <mat-select multiple  [(ngModel)]="_DtoAppoinment.clinicianId"
                         #clinicianId="ngModel" name="clinicianId" required>
                              <mat-option #matOption *ngFor="let item of drpClinician" [value]="item.staffId">
                                 {{item.firstName}} {{item.lastName}}
                               </mat-option>
                    </mat-select>

                    </mat-form-field>
                    <mat-error *ngIf="appointmentinfo.submitted && clinicianId.invalid">
                        <mat-error class="mat-form-field-subscript-wrapper mt-2"
                            *ngIf="clinicianId.errors.required">Clinician is required
                        </mat-error>
                    </mat-error>
                </div>
                <div class="col-md-2 "  *ngIf="_DtoAppoinment.isEndDate">
                    <mat-form-field class="w-100">
                        <mat-label class="">End Date</mat-label>
                        <input matInput [matDatepicker]="endpicker" readonly [min]="currentdate"
                            (click)="endpicker.open()" [(ngModel)]="_DtoAppoinment.endDate" #endDate="ngModel"
                            name="endDate" customdate required sDate="{{_DtoAppoinment.startDate}}">
                        <mat-datepicker-toggle matSuffix [for]="endpicker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #endpicker></mat-datepicker>


                        <mat-error *ngIf="appointmentinfo.submitted && endDate.invalid">
                            <mat-error class="mat-form-field-subscript-wrapper w-100" *ngIf="endDate.errors.required">
                                End Date is
                                required</mat-error>
                        </mat-error>
                        <mat-error class="mat-form-field-subscript-wrapper w-100" *ngIf="endDate.errors?.customdate">
                            End date should be greater then start date.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-2 mb-2 check-custom mt-4">
                    <mat-checkbox class="example-margin" [(ngModel)]="_DtoAppoinment.isEndDate" #isEndDate="ngModel"
                        name="isEndDate">Mark End Date?</mat-checkbox>

                </div>

            </div>

            <div class="row" *ngIf="_DtoAppoinment.typeId == 2">
                <div class="col-md-5 ">
                    <label class="control-label-new align-middle  font-weight-bold mt-2">Do you want to set
                        Recurrance?</label>
                    <div class=" mt-2 d-inline-block align-middle pl-2 ">

                        <mat-radio-group aria-label="Select an option" [(ngModel)]="_DtoAppoinment.isRecurrance"
                            #isRecurrance="ngModel" name="isRecurrance" required>
                            <mat-radio-button [value]="true" class="mr-2">Yes</mat-radio-button>
                            <mat-radio-button [value]="false" class="mr-2">No</mat-radio-button>
                        </mat-radio-group>
                        <mat-error *ngIf="appointmentinfo.submitted && isRecurrance.invalid">
                            <mat-error class="mat-form-field-subscript-wrapper mt-4"
                                *ngIf="isRecurrance.errors.required">Recurrance is
                                required
                            </mat-error>
                        </mat-error>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="_DtoAppoinment.isRecurrance">
                    <div class="">
                        <div class="mt-2 d-inline-block align-middle pl-2">
                            <label class="control-label-new font-weight-bold  align-middle mr-3">Frequency</label>

                                <mat-radio-group aria-label="Select an option" [(ngModel)]="_DtoAppoinment.frequency"
                                    #frequency="ngModel" name="frequency" required>
                                    <mat-radio-button [value]="4" class="mr-2">Daily </mat-radio-button>
                                    <mat-radio-button [value]="5" class="mr-2">Weekly </mat-radio-button>
                                    <mat-radio-button [value]="6" class="mr-2">Monthly </mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="appointmentinfo.submitted && frequency.invalid">
                                    <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                        *ngIf="frequency.errors.required">Frequency is
                                        required</mat-error>
                                </mat-error>

                        </div>
                    </div>

                </div>
                <div class="col-md-12 mt-3  mb-2" *ngIf="_DtoAppoinment.frequency == 5">
                    <div class="row">
                        <div class="col check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="_DtoAppoinment.isSunday" #sunday="ngModel"
                                name="sunday">Sunday
                            </mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="_DtoAppoinment.isMonday" #monday="ngModel"
                                name="monday">Monday
                            </mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="_DtoAppoinment.isTuesday"
                                #tuesday="ngModel" name="tuesday">
                                Tuesday</mat-checkbox>
                        </div>
                        <div class="col check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="_DtoAppoinment.isWednesday"
                                #wednesday="ngModel" name="wednesday">
                                Wednesday</mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="_DtoAppoinment.isThursday"
                                #thursday="ngModel" name="thursday">Thursday</mat-checkbox>
                        </div>
                        <div class="col  check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="_DtoAppoinment.isFriday" #friday="ngModel"
                                name="friday">Friday</mat-checkbox>
                        </div>
                        <div class="col check-custom large-font">
                            <mat-checkbox class="example-margin" [(ngModel)]="_DtoAppoinment.isSaturday"
                                #saturday="ngModel" name="saturday">Saturday</mat-checkbox>
                        </div>
                    </div>

                </div>
                <div class="col-md-12 " *ngIf="_DtoAppoinment.frequency == 6">
                    <div class="row">
                        <div class="col-md-2 mt-2">
                        <label class="control-label-new align-middle  font-weight-bold mt-2">Repeat every</label>
                        </div>
                                <div class="col-md-3 pl-0">
                                    <mat-form-field class="w-100">

                                        <mat-select name="weekOfMonth" [(ngModel)]="_DtoAppoinment.weekOfMonth" #weekOfMonth="ngModel"
                                            required>
                                            <!-- <mat-option [value]="null">--Select Duration--</mat-option> -->
                                            <mat-option [value]=1>First</mat-option>
                                            <mat-option [value]=2>Second</mat-option>
                                            <mat-option [value]=3>Third</mat-option>
                                            <mat-option [value]=4>Fourth</mat-option>
                                            <mat-option [value]=5>Last</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error *ngIf="appointmentinfo.submitted && weekOfMonth.invalid">
                                        <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                            *ngIf="weekOfMonth.errors.required">Repeat every is required
                                        </mat-error>
                                    </mat-error>
                                    </div>
                                    <div class="col-md-3 pl-0">
                                    <mat-form-field class="w-100">

                                        <mat-select name="weekDaysOnMonth" [(ngModel)]="_DtoAppoinment.weekDaysOnMonth" #weekDaysOnMonth="ngModel"
                                            required>
                                            <!-- <mat-option [value]="null">--Select Duration--</mat-option> -->
                                            
                                            <mat-option [value]=1>Monday</mat-option>
                                            <mat-option [value]=2>Tuesday</mat-option>
                                            <mat-option [value]=3>Wednesday</mat-option>
                                            <mat-option [value]=4>Thursday</mat-option>
                                            <mat-option [value]=5>Friday</mat-option>
                                            <mat-option [value]=6>Saturday</mat-option>
                                            <mat-option [value]=7>Sunday</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error *ngIf="appointmentinfo.submitted && weekDaysOnMonth.invalid">
                                        <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                            *ngIf="weekDaysOnMonth.errors.required">Repeat every is required
                                        </mat-error>
                                    </mat-error>
                                </div>
                            </div>


                </div>
            </div>
             <div class="row" *ngIf="_DtoAppoinment.typeId !== 2 && _DtoAppoinment.typeId !== 5 && _DtoAppoinment.typeId !== 6">
                 <div class="col-md-4 mt-2 pr-0">
                    <div class="mt-2">
                        <span class="small-heading-label align-middle font-weight-500">Frequency</span>
                        <span class="ml-4 yes-no-insurance">
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="_DtoAppoinment.frequency"
                                #frequency="ngModel" name="frequency" required
                                (change)="onChangeFrequency(_DtoAppoinment.frequency)">
                                <mat-radio-button class="mr-2" [value]="1">Daily </mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="2">Weekly </mat-radio-button>
                                <mat-radio-button class="mr-2" [value]="3">Monthly </mat-radio-button>
                            </mat-radio-group>
                            <mat-error class="mat-form-field-subscript-wrapper"
                                *ngIf="appointmentinfo.submitted && frequency.invalid">
                                <mat-error *ngIf="frequency.errors.required" style="padding: 10px;">Frequency is required
                                </mat-error>
                            </mat-error>
                        </span>
                    </div>

                </div>
                <div class="col-md-2 mt-2" *ngIf="_DtoAppoinment.typeId !== 2 || _DtoAppoinment.typeId !== 5 || _DtoAppoinment.typeId !== 6">
                    <div class="check-custom mt-2 ">
                        <mat-checkbox class="example-margin" [(ngModel)]="_DtoAppoinment.isActive" #isActive="ngModel"
                            name="isActive">
                            Active
                        </mat-checkbox>

                    </div>
                </div>

                <div class="col-md-12 " *ngIf="_DtoAppoinment.frequency == 1 || _DtoAppoinment.frequency == 3">
                    <div class="row">
                        <div class="col-md-3 ">
                            <mat-form-field class="w-100">
                                <mat-label>Start Time</mat-label>
                                <mat-select [(ngModel)]="_DtoAppoinment.startTime"
                                    (selectionChange)="generateTimeSlots()" #startTime="ngModel" name="startTime"
                                    required>
                                    <mat-option *ngFor="let item of meetingTimeslots" [value]="item.fromTime">
                                        {{item.fromTime | date: 'hh:mm a'}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="appointmentinfo.submitted && startTime.invalid">
                                    <mat-error *ngIf="startTime.errors.required">Start Time is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 ">
                            <mat-form-field class="w-100">
                                <mat-label>End Time</mat-label>
                                <mat-select [(ngModel)]="_DtoAppoinment.endTime" #endTime="ngModel" name="endTime"
                                    required
                                    (ngModelChange)="_DtoAppoinment.timePerSlot='' || _DtoAppoinment.minPerSlot=''"
                                    customdate sDate="{{_DtoAppoinment.startTime}}">
                                    <mat-option *ngFor="let item of meetingTimeslots" [value]="item.toTime">
                                        {{item.toTime |
                                        date: 'hh:mm a'}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="appointmentinfo.submitted && endTime.invalid">
                                    <mat-error *ngIf="endTime.errors.required">End Time is required
                                    </mat-error>

                                </mat-error>
                            </mat-form-field>
                            <mat-error class="mat-form-field-subscript-wrapper" *ngIf="endTime.errors?.customdate">
                                End time should be greater
                                then start time.
                            </mat-error>
                        </div>
                        <div class="col-md-3 ">
                            <mat-form-field class="w-100">
                                <mat-label>Time(hr) Per Slot</mat-label>

                                <mat-select [(ngModel)]="_DtoAppoinment.timePerSlot" #timePerSlot="ngModel"
                                    name="timePerSlot"
                                    [ngClass]="{ 'is-invalid': appointmentinfo.submitted && timePerSlot.invalid }"
                                    required>
                                    <!-- <mat-option [value]="15">15 min</mat-option>
                                    <mat-option [value]="30">30 min</mat-option> -->
                                    <!-- <mat-option [value]="45" [disabled]="generateTimeSlots(_DtoAppoinment.startTime,_DtoAppoinment.endTime,45)" >45</mat-option> -->
                                    <mat-option [value]="0"
                                        [disabled]="generateTimeSlots(_DtoAppoinment.startTime,_DtoAppoinment.endTime,0)">
                                        0 hr</mat-option>
                                    <mat-option [value]="60"
                                        [disabled]="generateTimeSlots(_DtoAppoinment.startTime,_DtoAppoinment.endTime,60)">
                                        1 hr</mat-option>
                                    <mat-option [value]="120"
                                        [disabled]="generateTimeSlots(_DtoAppoinment.startTime,_DtoAppoinment.endTime,120)">
                                        2 hr</mat-option>
                                    <mat-option [value]="180"
                                        [disabled]="generateTimeSlots(_DtoAppoinment.startTime,_DtoAppoinment.endTime,180)">
                                        3 hr</mat-option>
                                    <mat-option [value]="240"
                                        [disabled]="generateTimeSlots(_DtoAppoinment.startTime,_DtoAppoinment.endTime,240)">
                                        4 hr</mat-option>
                                    <mat-option [value]="300"
                                        [disabled]="generateTimeSlots(_DtoAppoinment.startTime,_DtoAppoinment.endTime,300)">
                                        5 hr</mat-option>
                                    <mat-option [value]="360"
                                        [disabled]="generateTimeSlots(_DtoAppoinment.startTime,_DtoAppoinment.endTime,360)">
                                        6 hr</mat-option>
                                    <mat-option [value]="420"
                                        [disabled]="generateTimeSlots(_DtoAppoinment.startTime,_DtoAppoinment.endTime,420)">
                                        7 hr</mat-option>
                                    <mat-option [value]="480"
                                        [disabled]="generateTimeSlots(_DtoAppoinment.startTime,_DtoAppoinment.endTime,480)">
                                        8 hr</mat-option>
                                </mat-select>
                                <mat-error *ngIf="appointmentinfo.submitted && timePerSlot.invalid">

                                    <mat-error *ngIf="timePerSlot.errors.required">Slot is required
                                    </mat-error>
                                    <mat-error *ngIf="timePerSlot.errors?.customMax">Maximum number can be 60.
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 ">

                            <mat-form-field class="w-100">
                                <mat-label>Time(min) Per Slot</mat-label>

                                <mat-select [(ngModel)]="_DtoAppoinment.minPerSlot" #minPerSlot="ngModel"
                                    name="minPerSlot{{idx}}"
                                    [ngClass]="{ 'is-invalid': appointmentinfo.submitted && minPerSlot.invalid }"
                                    required>
                                    <mat-option [value]="00"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,00) || _DtoAppoinment.timePerSlot == 0">
                                        00 min</mat-option>
                                    <mat-option [value]="05"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,05)">
                                        05 min</mat-option>
                                    <mat-option [value]="10"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,10)">
                                        10 min</mat-option>
                                    <mat-option [value]="15"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,15)">
                                        15 min</mat-option>
                                    <mat-option [value]="20"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,20)">
                                        20 min</mat-option>
                                    <mat-option [value]="25"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,25)">
                                        25 min</mat-option>
                                    <mat-option [value]="30"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,30)">
                                        30 min</mat-option>
                                    <mat-option [value]="35"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,35)">
                                        35 min</mat-option>
                                    <mat-option [value]="40"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,40)">
                                        40 min</mat-option>
                                    <mat-option [value]="45"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,45)">
                                        45 min</mat-option>
                                    <mat-option [value]="50"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,50)">
                                        50 min</mat-option>
                                    <mat-option [value]="55"
                                        [disabled]="generateTimeSlotsmins(_DtoAppoinment.startTime,_DtoAppoinment.endTime,_DtoAppoinment.timePerSlot,55)">
                                        55 min</mat-option>
                                </mat-select>
                                <mat-error *ngIf="appointmentinfo.submitted && minPerSlot.invalid">

                                    <mat-error *ngIf="minPerSlot.errors.required">Slot is required
                                    </mat-error>
                                    <mat-error *ngIf="minPerSlot.errors?.customMax">Maximum number can be 60.
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12" *ngIf="_DtoAppoinment.frequency == 2">

                    <div class="clearfix p-0  w-100"
                        *ngFor="let freq of _DtoAppoinment.appointmentFrequencies; let idx=index">
                        <div class="row">
                            <div class="col-md-2 pt-3 check-custom large-font">
                                <mat-checkbox class="example-margin" [(ngModel)]="freq.isChecked"
                                    name="isActive{{idx}}">
                                    {{freq.daylabel}}
                                </mat-checkbox>
                            </div>
                            <div class="col-md-2 ">
                                <mat-form-field class="w-100">
                                    <mat-label>Start Time</mat-label>
                                    <mat-select [(ngModel)]="freq.startTime" #startTime="ngModel"
                                        name="startTime{{idx}}" [required]="freq.isChecked">
                                        <mat-option *ngFor="let item of meetingTimeslots" [value]="item.fromTime">
                                            {{item.fromTime | date: 'hh:mm a'}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="appointmentinfo.submitted && startTime.invalid">
                                        <mat-error *ngIf="startTime.errors.required">Start Time is required
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-2 ">
                                <mat-form-field class="w-100">
                                    <mat-label>End Time</mat-label>

                                    <mat-select [(ngModel)]="freq.endTime" customdate sDate="{{freq.startTime}}"
                                        eDate="{{freq.endTime}}" #endTime="ngModel" name="endTime{{idx}}"
                                        [required]="freq.isChecked"
                                        (ngModelChange)="freq.timePerSlot='' || freq.minPerSlot=''">
                                        <mat-option *ngFor="let item of meetingTimeslots" [value]="item.toTime">
                                            {{item.toTime |
                                            date: 'hh:mm a'}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="appointmentinfo.submitted && endTime.invalid">
                                        <mat-error *ngIf="endTime.errors.required">End Time is required
                                        </mat-error>

                                    </mat-error>
                                </mat-form-field>
                                <mat-error class="mat-form-field-subscript-wrapper" *ngIf="endTime.errors?.customdate">
                                    End time should be greater
                                    then start time.
                                </mat-error>

                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="w-100">
                                    <mat-label>Time(hr) Per Slot</mat-label>

                                    <mat-select [(ngModel)]="freq.timePerSlot" #timePerSlot="ngModel"
                                        name="timePerSlot{{idx}}"
                                        [ngClass]="{ 'is-invalid': appointmentinfo.submitted && timePerSlot.invalid }"
                                        [required]="freq.isChecked">
                                        <!-- <mat-option [value]="15">15 min</mat-option>
                                        <mat-option [value]="30">30 min</mat-option> -->
                                        <!-- <mat-option [value]="45" [disabled]="generateTimeSlots(freq.startTime,freq.endTime,45)" >45</mat-option> -->
                                        <!-- <mat-option [value]="60"
                                            [disabled]="generateTimeSlots(freq.startTime,freq.endTime,60)">60
                                        </mat-option> -->
                                        <mat-option [value]="0"
                                            [disabled]="generateTimeSlots(freq.startTime,freq.endTime,0)">
                                            0 hr</mat-option>
                                        <mat-option [value]="60"
                                            [disabled]="generateTimeSlots(freq.startTime,freq.endTime,60)">
                                            1 hr</mat-option>
                                        <mat-option [value]="120"
                                            [disabled]="generateTimeSlots(freq.startTime,freq.endTime,120)">
                                            2 hr</mat-option>
                                        <mat-option [value]="180"
                                            [disabled]="generateTimeSlots(freq.startTime,freq.endTime,180)">
                                            3 hr</mat-option>
                                        <mat-option [value]="240"
                                            [disabled]="generateTimeSlots(freq.startTime,freq.endTime,240)">
                                            4 hr</mat-option>
                                        <mat-option [value]="300"
                                            [disabled]="generateTimeSlots(freq.startTime,freq.endTime,300)">
                                            5 hr</mat-option>
                                        <mat-option [value]="360"
                                            [disabled]="generateTimeSlots(freq.startTime,freq.endTime,360)">
                                            6 hr</mat-option>
                                        <mat-option [value]="420"
                                            [disabled]="generateTimeSlots(freq.startTime,freq.endTime,420)">
                                            7 hr</mat-option>
                                        <mat-option [value]="480"
                                            [disabled]="generateTimeSlots(freq.startTime,freq.endTime,480)">
                                            8 hr</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="appointmentinfo.submitted && timePerSlot.invalid">

                                        <mat-error *ngIf="timePerSlot.errors.required">Time Per Slot is required
                                        </mat-error>
                                        <mat-error *ngIf="timePerSlot.errors?.customMax">Maximum number can be 60.
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 ">

                                <mat-form-field class="w-100">
                                    <mat-label>Time(min) Per Slot</mat-label>

                                    <mat-select [(ngModel)]="freq.minPerSlot" #minPerSlot="ngModel"
                                        name="minPerSlot{{idx}}"
                                        [ngClass]="{ 'is-invalid': appointmentinfo.submitted && minPerSlot.invalid }"
                                        [required]="freq.isChecked">
                                        <mat-option [value]="00"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,00) || freq.timePerSlot == 0">
                                            00 min</mat-option>
                                        <mat-option [value]="05"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,05)">
                                            05 min</mat-option>
                                        <mat-option [value]="10"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,10)">
                                            10 min</mat-option>
                                        <mat-option [value]="15"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,15)">
                                            15 min</mat-option>
                                        <mat-option [value]="20"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,20)">
                                            20 min</mat-option>
                                        <mat-option [value]="25"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,25)">
                                            25 min</mat-option>
                                        <mat-option [value]="30"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,30)">
                                            30 min</mat-option>
                                        <mat-option [value]="35"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,35)">
                                            35 min</mat-option>
                                        <mat-option [value]="40"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,40)">
                                            40 min</mat-option>
                                        <mat-option [value]="45"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,45)">
                                            45 min</mat-option>
                                        <mat-option [value]="50"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,50)">
                                            50 min</mat-option>
                                        <mat-option [value]="55"
                                            [disabled]="generateTimeSlotsmins(freq.startTime,freq.endTime,freq.timePerSlot,55)">
                                            55 min</mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="appointmentinfo.submitted && minPerSlot.invalid">

                                        <mat-error *ngIf="minPerSlot.errors.required">Time Per Slot is required
                                        </mat-error>
                                        <mat-error *ngIf="minPerSlot.errors?.customMax">Maximum number can be 60.
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>





                    </div>
                    <mat-error class="mat-form-field-subscript-wrapper" *ngIf="isReqWeekly">
                        Please choose atleast any one frequency in week
                    </mat-error>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12 text-right mt-2" *ngIf="roleAccess.isEdit ||  roleAccess.isCreate">
                    <button type="button" class="btn btn-cancel float-right" title="Cancel"
                        (click)="resetAppointment(appointmentinfo)">Cancel</button>
                    <button type="submit" class="btn primary-btn-filled float-right mr-2" title="{{!isEditAppoinment ? 'Save' :
                    'Update'}}" (click)="postMeetingAppointment(appointmentinfo)"  [ngClass]="{'disabled': onpostmeetingappointment }" >{{!isEditAppoinment ? 'Save' :
                        'Update'}}</button>
                </div>
            </div>
        </div>
    </form>
    <div class="col-md-12">
        <div class="col-md-12 mt-2">
            <div class="box-lead-head">Meeting Appointments</div>
        </div>
        <div class="row note-has-grid">
            <div class="col-md-4 single-note-item all-category  mb-2" *ngFor="let item of appoinmentlst">
                <div class="card card-body shadow p-3  mb-2 rounded h-100">
                    <span class="side-stick " [ngClass]="{'inactive-stick':!item.isActive}"></span>
                    <h5 class="note-title text-truncate w-75 mb-0">{{item.typeName}}</h5>
                    <p class="note-date fs-2 text-muted">
                        <i class="fas fa-file-medical mr-1" *ngIf="item.typeId == 1"></i>
                        <i class="fas fa-users mr-1" *ngIf="item.typeId == 2"></i>
                        <i class="fas fa-ban mr-1" *ngIf="item.typeId == 3"></i>
                        <i class="fas fa-map-marked-alt mr-1" *ngIf="item.typeId == 4"></i>
                        {{item.categoryName}}
                    </p>
                    <div class="note-content" *ngIf="item.frequency != 2">
                        <div class="d-flex ">
                            <div class="">
                                <p class="m-0"><span class="time-label">Frequency</span><span
                                        class="time-val">{{ (item.typeId != 2) ?   item.frequency == 1 ? 'Daily': 'Monthly' : (item.isRecurrance) ? (item.frequency == 4) ? 'Daily' : (item.frequency == 5) ? 'Weekly' : 'Monthly'   : 'No'                    }}</span></p>
                                <p class="m-0"><span class="time-label">Start Time</span><span
                                        class="time-val">{{item.startTime | date: 'hh:mm a'}}</span></p>
                                <p class="m-0"><span class="time-label end-length">End Time:</span><span
                                        class="time-val">{{item.endTime | date: 'hh:mm a'}}</span></p>
                                <p *ngIf="item.typeId != 2" class="m-0"><span class="time-label">Time/Slots:</span><span
                                        class="time-val">{{getMinutesSlot(item.timePerSlot)}} hr {{item.minPerSlot}}
                                        min</span></p>
                                <p class="m-0"><span class="time-label">Location</span><span
                                        class="time-val">{{item.locationName}}</span></p>
                            </div>
                            <div class="ml-auto" *ngIf=" _DtoAppoinment.typeId !== 5 || _DtoAppoinment.typeId !== 6">
                                <p class="m-0 note-inner-content text-muted"><span class="time-label">No of Slots
                                        :</span><span class="time-val-slot">{{item.slots}}</span></p>

                            </div>

                        </div>
                    </div>
                    <div class="note-content" *ngIf="item.frequency == 2">
                        <div class="d-flex w-100">
                            <div class=" w-100">
                                <p class="m-0"><span class="time-label">Frequency:</span><span
                                        class="time-val">Weekly</span></p>
                                <p class="m-0"><span class="time-label">Available Days</span><span
                                        class="time-val w-50  d-inline-block align-top">{{getWeekdays(item)}}</span></p>
                                <p class="m-0"><span class="time-label">Location</span><span
                                        class="time-val">{{item.locationName}}</span></p>
                                <!-- <p class="m-0"><span class="time-label end-length"></span><span
                                        class="time-val"></span></p>
                                <p class="m-0"><span class="time-label"></span><span
                                        class="time-val"></span></p> -->
                            </div>
                            <div class="ml-auto">
                                <p class="m-0 note-inner-content text-muted"><span class="time-label">
                                    </span><span class="time-val-slot"></span></p>

                            </div>

                        </div>
                    </div>
                    <div class="clearfix w-100 text-right">
                        <a role="button" title="Assign Clinician" class="link mr-1 cursor-pointer d-none"
                        (click)="onSetClinician(item)"  *ngIf="item.typeId == 2">
                                                       <i class="fas fa-people-arrows"></i>
                        </a>
                        <a class="link mr-1 cursor-pointer" role="button" title="Edit" *ngIf="roleAccess.isEdit"
                            (click)="oneditAppointment(item)"><i class="far fa-edit favourite-note"></i></a>
                        <a class="link text-danger ms-2 cursor-pointer" role="button" title="Delete"
                            *ngIf="item.typeName != 'Intake Schedule' && item.typeName != 'Tour' && roleAccess.isDelete"
                            (click)="ondeleteAppointment(item)"><i class="far fa-trash-alt remove-note"></i></a>

                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- <div>

        <div class="col-md-12  table-custom p-0">
            <div class="table-responsive">
                <mat-table #table [dataSource]="dataSource" matSort class="w-100">
                    <ng-container matColumnDef="sno">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="categoryName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Category Name </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.categoryName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="typeName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Type Name </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.typeName}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="startTime">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Start Time</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.startTime | date: 'hh:mm a'}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="endTime">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>End Time </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.endTime | date: 'hh:mm a'}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="timePerSlot">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Slot</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.timePerSlot}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="IsActive">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Active </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.isActive == 1 ? 'Yes':'No'}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>Actions </mat-header-cell>
                        <mat-cell *matCellDef="let element;let i = index;">
                            <button type="button" mat-icon-button matTooltip="Edit" (click)="oneditAppointment(element)"
                                color="primary">
                                <img src="../../../assets/images/editblue.png">
                            </button>

                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

                </mat-table>
            </div>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div> -->
</div>

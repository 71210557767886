import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { constantVariables } from 'src/app/constant/constant';
import { ClinicalNotes, Medication } from 'src/app/_models/_Treatment/_clinicalnotes';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { NgForm } from '@angular/forms';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { PsyTherapyInfo } from 'src/app/_models/_Treatment/_PsyTherapy';
import { ThrowStmt } from '@angular/compiler';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as moment from 'moment';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { element } from 'protractor';
import { DatePipe } from '@angular/common';
import { TreatmentService } from '../treatment.service';
@Component({
  selector: 'app-psychiatric-therapy-progress-notes',
  templateUrl: './psychiatric-therapy-progress-notes.component.html',
  styleUrls: ['./psychiatric-therapy-progress-notes.component.scss']
})
export class PsychiatricTherapyProgressNotesComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('PsyTheraapyFrm') PsyTheraapyFrm: NgForm;
  psyTherapyInfo: PsyTherapyInfo;
  SelectedTab = 1;
  isEdit: boolean;
  selecteddate: any;
  selectedtheraphyid: any;
  selectedMsId: any;
  selectedIntervention: string;
  _dtoPatientInfo: any;
  Ismedicationreaction: boolean;
  MedCompliance: boolean;
  signatureImg = '';
  isESignAttached: boolean;
  updatedsignatureImg: any;
  isformtype: string;
  defaultImage: any;
  PopUpPreview: boolean;
  goalsinfo: any;
  Assessmentgiven: boolean;
  Councellinggiven: boolean;
  IseditPsy: boolean;
  ddldiagnosiscodeModel: any;
  ddldiagnosiscode: any;
  DiagnosisCodeAllowed: any;
  roleAccess: RoelAccess;
  pdfpsytherapynotes: any;
  isLicensed: boolean;
  RoleId: string;
  onAddmedication: boolean;
  _dtoMedication: any;
  medicationRecords: any;
  editmedication: boolean;
  lstmedicine: any;
  isshowhistroy: boolean;
  IsCloseWindow: boolean = false;
  ddlseverity: any;
  bg: any;
  progressUpg:boolean=false;
  secondaryId : any;
  AutoFillSS:boolean=false;
  SSignature:any;
  GetClinicianSign: any[];  
  userId: any;
  constructor(public global: Global, public clinicianservice: ClinicianService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,public treatmentservice: TreatmentService,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,private formBuilder: FormBuilder,public roleaccessservice:PageAccessService,
    public datepipe : DatePipe  ) { }

  ngOnInit(): void {
    this.isLicensed = localStorage.getItem('isLicensed') == 'true' ? true : false;
    this.RoleId = localStorage.getItem('roleId');
    this.userId = Number(localStorage.getItem('id'));
    this.getAutoFillSignature();
    this.psyTherapyInfo = new PsyTherapyInfo();
    const pageid = constantVariables.pagelists.ProgressNoteFivepointRating;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.selecteddate = JSON.parse(this.commonService.FrontEndDecryption(params.date));
        this.selectedtheraphyid = 4
        //  JSON.parse(this.commonService.FrontEndDecryption(params.theraphyid));
        this.selectedMsId = JSON.parse(this.commonService.FrontEndDecryption(params.id));
        if (params.IsFromHistory) {
          this.IsCloseWindow = true;
        }
        this.selectedIntervention = "";
        if ((this.datepipe.transform(new Date(), "yyyy-MM-dd")) < (this.datepipe.transform(this.selecteddate, "yyyy-MM-dd"))) {
          this.progressUpg = true;
        }
        this.getPatientInfo();
      }
    });
    this.getDiagnosisCodeMster()
    this.getSeverityMaster();
  }
  getDiagnosisCodeMster() {
    this.commonService
      .getDiagonosisCodeMaster()
      .subscribe((res: any) => {
        if (res.objInputString != null) {
          let fres = this.commonService.decrypdata(res.objInputString);
          if (fres.length > 0) {
            this.ddldiagnosiscodeModel = fres;
            this.ddldiagnosiscode = fres
            // this.ddldiagnosiscodeModel.forEach(x => x.isActive = false);
          // for (let item of this.ddldiagnosiscodeModel) {            
          //     this.ddldiagnosiscode.push(item)            
          // }
        }
      }
      },
        err => {
          this.spinnerservice.hide();
        });
  }
  OnTabchange(item) {
    this.SelectedTab = item;
    if (this.SelectedTab == 2) {
      this.getpatientgoal()
    }
    if (this.SelectedTab == 3) {
      this.getPatientMedication()
    }
  }

  getPatientInfo()
  {
     this.spinnerservice.show();
    this.isEdit=false;
    this.secondaryId = localStorage.getItem('id');
    this.clinicianservice.getpsychaiatricpatientinfo(this.selectedMsId,4,this.selecteddate,this.secondaryId).subscribe(
      (res: any) => {
        // debugger;
        this.spinnerservice.hide();
        if (res) {

          this._dtoPatientInfo = res;
          //  this.psyTherapyInfo = res.psyTherapyInfo;
          setTimeout(() => {
            this.psyTherapyInfo = res.psyTherapyInfo;
          }, 3000);
          this.psyTherapyInfo = res.psyTherapyInfo;
          this.psyTherapyInfo.diagnosis = this.psyTherapyInfo?.diagnosis?.split(',').map(String);
          this.DiagnosisCodeAllowed = this.psyTherapyInfo?.diagnosis
          if(this.DiagnosisCodeAllowed){
            this.ddldiagnosiscode = [];
          }
          this.ddldiagnosiscodeModel.forEach(x => x.isActive = false);
          for (let item of this.ddldiagnosiscodeModel) {
            if (this.psyTherapyInfo?.diagnosis?.includes(item.value)) {
              item.isActive = true;
              this.ddldiagnosiscode.push(item)
            }
          }
          if (this.psyTherapyInfo.psychiatricTherapyNotesId != 0) {
            this.OnCouncellingchange(2, 1);
            this.Onassessment(1, 1);
            this.IseditPsy = false
          }
          else {
            this.IseditPsy = true
          }

         if(this.IseditPsy){
         this.psyTherapyInfo.signDate = new Date()
         }
         else{
      this.psyTherapyInfo.nextFollowUp = this.psyTherapyInfo?.nextFollowUp?.toString();
      // this.psyTherapyInfo.nextFollowUp = +this.psyTherapyInfo.nextFollowUp;
      this.psyTherapyInfo.isSave = false;
      // if(this.psyTherapyInfo.medicationCompliance==true){
      //   this.MedCompliance = this.psyTherapyInfo.medicationCompliance 
      //   this.psyTherapyInfo.medicationCompliance = "1"c
      // }
      // else{
      //   this.MedCompliance = this.psyTherapyInfo.medicationCompliance 
      //   this.psyTherapyInfo.medicationCompliance = "2"
      // }
      // if(this.psyTherapyInfo.isMedicationReaction==true){
      //   this.MedCompliance = this.psyTherapyInfo.isMedicationReaction 
      //   this.psyTherapyInfo.isMedicationReaction = "1"
      // }
      // else{
      //   this.MedCompliance = this.psyTherapyInfo.isMedicationReaction 
      //   this.psyTherapyInfo.isMedicationReaction = "2"
      // }
      

          }
          //console.log( this._dtoPatientInfo.assessment)

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }


  
  OnCouncellingchange(element,val){
    this.Councellinggiven = false
    for (let item of this._dtoPatientInfo.counseling) {
      if (item.isChecked == true) {
        this.Councellinggiven = true
      }
    }
    // if(val == 2)
    // {
    //   this.PostPsyTheraapyFrm(2);
    // }

  }

  Onassessment(element, val) {
    //console.log(element.checked);
    this.Assessmentgiven = false
    for (let item of this._dtoPatientInfo.assessment) {
      if (item.isChecked == true) {
        this.Assessmentgiven = true
      }

    }
    // if(val == 2)
    // {
    //   this.PostPsyTheraapyFrm(2);
    // }
  }

  PostPsyTheraapyFrm(val) {

    // if(!this.PsyTheraapyFrm.valid || (this.psyTherapyInfo.staffInitials == undefined && ((this.roleAccess.isSign && this.isLicensed) || (!this._dtoPatientInfo.isLicensed && this.roleAccess.isUnlicensed) || (this._dtoPatientInfo.IsIntern && this.roleAccess.isInternSign )))){

    //   return
    // }
    // else{
    this.psyTherapyInfo.counseling = []
    this._dtoPatientInfo?.counseling?.forEach(element => {
      if (element.isChecked == true) {
        this.psyTherapyInfo.counseling.push(element.id)
      }
    });
    this.psyTherapyInfo.assessment = []
    this._dtoPatientInfo?.assessment?.forEach(element => {
      if (element.isChecked == true) {
        this.psyTherapyInfo.assessment.push(element.id)
      }
    });
    this.psyTherapyInfo.patientAccountId = this._dtoPatientInfo.patientAccountId;
    this.psyTherapyInfo.meetingscheduleTimeId = this._dtoPatientInfo.meetingScheduleTimeId
    this.psyTherapyInfo.meetingscheduleid = this._dtoPatientInfo.meetingScheduleId
    // this.psyTherapyInfo.isMedicationReaction = this.Ismedicationreaction;
    //  this.psyTherapyInfo.medicationCompliance = this.MedCompliance;
    this.psyTherapyInfo.diagnosis = this.psyTherapyInfo.diagnosis?.toString();
    this.psyTherapyInfo.assessment = this.psyTherapyInfo.assessment?.toString();
    this.psyTherapyInfo.counseling = this.psyTherapyInfo.counseling?.toString();
    this.psyTherapyInfo.nextFollowUp = +this.psyTherapyInfo.nextFollowUp
    this.psyTherapyInfo.severity = +this._dtoPatientInfo.psyTherapyInfo.severity;
    if (val != 2) {
      this.spinnerservice.show();
      this.psyTherapyInfo.isSave = true;
    } else {
      this.psyTherapyInfo.isSave = false;
    }

    const dataToEncrypt = this.commonService.DataToEncrypt(this.psyTherapyInfo, false);
    if(val == 2)
    {
      this.psyTherapyInfo.nextFollowUp = this.psyTherapyInfo?.nextFollowUp?.toString();
    }
    this.clinicianservice.PostPsyTherapyNotes(dataToEncrypt).subscribe(
      res => {
        if (val != 2) {
          this.spinnerservice.hide();
          this.snackbar.success(res);
          this.getPatientInfo()
          this.Onassessment(1, 1)
          this.OnCouncellingchange(2, 1)
        }
      },
      err => {
        if (val != 2) {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        }
      },
    )
    // }
  }
Onmedicationreaction(item,val){
  if(item==1){
    this.Ismedicationreaction = true
  }
  else{
    this.Ismedicationreaction = false
  }
  if(val == 2)
  {
    this.psyTherapyInfo.isMedicationReaction = item;
    this.psyTherapyInfo.medicationCompliance = this.psyTherapyInfo.medicationCompliance.toString();
    this.psyTherapyInfo.nextFollowUp = this.psyTherapyInfo?.nextFollowUp?.toString();
    // this.PostPsyTheraapyFrm(2);
  }
}

  OnMedicationCompliance(item, val, medication) {
    if (item == 1) {
      this.MedCompliance = true
    }
    else {
      this.MedCompliance = false
    }
    if (val == 2) {
      if (medication == 1) {
        this.psyTherapyInfo.medicationCompliance = item;
      } else {
        this.psyTherapyInfo.isMedicationReaction = item;
        this.psyTherapyInfo.medicationCompliance = this.psyTherapyInfo.medicationCompliance.toString();
      }
      this.psyTherapyInfo.nextFollowUp = this.psyTherapyInfo?.nextFollowUp?.toString();
      // this.PostPsyTheraapyFrm(2);
    }
}
openDialog() {
  // this.global.isconsentesign = false;
  // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
  //   data: { isfutureSign: false, isParentsign: true },
  //   autoFocus: false 
  // });
  // dialogRef.afterClosed().subscribe((result) => {
  //   this.isESignAttached = this.global.isconsentesign;
  //   if (
  //     this.global.isconsentesign != undefined &&
  //     this.global.isconsentesign
  //   ) {
    this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if (
      this.global.isconsentesign != undefined &&
      this.global.isconsentesign
    ) {
      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
      this.signatureImg = this.updatedsignatureImg;
      this.psyTherapyInfo.staffInitials = this.updatedsignatureImg;
     // this.IncReportfrmInfo.IsAttachEsign = true;
      (document.getElementById('esign') as HTMLImageElement).src =
        this.signatureImg;
    } else if (
      !this.global.isconsentesign &&
      this.global.formtype != null &&
      this.global.formtype != undefined
    ) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      this.psyTherapyInfo.staffInitials = this.global.consentesign;
      //this.IncReviewInfo.IsAttachEsign = true;
      (document.getElementById('esign') as HTMLImageElement).src =
        this.signatureImg;
    } else {
      this.signatureImg = this.defaultImage;
      //this.IncReportfrmInfo.IsAttachEsign = true;
      this.psyTherapyInfo.staffInitials = this.defaultImage;
    }
  });
}

  OnpreviewClick() {
    this.PopUpPreview = true;
  }
  OnclosePopup() {
    this.PopUpPreview = false;
  }
  getpatientgoal() {
    this.spinnerservice.show();
    //  console.log(this.userId )
    this.clinicianservice.getIndvFamgoals(this._dtoPatientInfo.patientAccountId, this.selectedtheraphyid, this.selectedMsId, this.selecteddate).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.goalsinfo = res
        if (this.goalsinfo[0].staffInitials != "") {
          //  this.signatureImg = this.goalsinfo[0].staffInitials
          (document.getElementById('esign2') as HTMLImageElement).src = this.goalsinfo[0].staffInitials
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  OnbackClick() {
    if (this.IsCloseWindow) {
      window.close();
    }
    else {
      this.router.navigate(['/clinician'], { queryParams: { menu: 1 } });

    }
  }
  onDiagnosisCodeselect(item, event) {
    item.isActive = event;
    // this.PostPsyTheraapyFrm(2);
  }

  OnDownload(item) {
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
    this.clinicianservice.getPsychiatrictherapynotespdf(this.selectedMsId, this._dtoPatientInfo.meetingScheduleTimeId, this._dtoPatientInfo.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfpsytherapynotes = res.filePath;
        if (item == 1) {
          window.open(this.pdfpsytherapynotes, '_blank');
        }
        else if (item == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  print() {
    let printContents = document.getElementById('print-section').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    // this.ShowPreview=true;
    window.location.reload();

  }

  addMedicationInfo() {
    // this._dtoMedication=new Medication();
    this.onAddmedication = true;
    this._dtoMedication.push({
      patientMedicationId: 0,
      patientAccountId: this.medicationRecords[0].patientAccountId,
      dayScheduleSubGroupId: 0,
      medications: null,
      dosage: null,
      frequency: null,
      isMorning: null,
      isNoon: null,
      isEvening: null,
      isBedTime: null,
      isBeforemeal: null,
      isDelete: null,
    })
  }

  ongetMedication() {
    this.isshowhistroy = false;
    this._dtoMedication = [];
    this.spinnerservice.show();

    this.clinicianservice.getallCurrentMedication(this.medicationRecords[0].patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          this.lstmedicine = res;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
    //this.addMedicationInfo();
  }
  removeclinicalinformation(data, index) {
    if (data.patientMedicationId != 0) {
      this.spinnerservice.show();
      this.removeexistMedinfo(data);
    }
    this._dtoMedication.splice(index, 1);
  }
  removeexistMedinfo(data) {
    data.dayScheduleSubGroupId = 0
    this.clinicianservice.deleteMediciation(data.patientMedicationId, data.dayScheduleSubGroupId).subscribe((res: any) => {
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }

  onEditMedication(item) {
    this.editmedication = true;
    this._dtoMedication = [];
    for (let i = 0; i < item.dtoPatientMedication.length; i++) {
      this._dtoMedication.push({
        patientMedicationId: item.dtoPatientMedication[i].patientMedicationId,
        patientAccountId: item.dtoPatientMedication[i].patientAccountId,
        medications: item.dtoPatientMedication[i].medications,
        dosage: item.dtoPatientMedication[i].dosage,
        frequency: item.dtoPatientMedication[i].frequency,
        isMorning: item.dtoPatientMedication[i].isMorning,
        isNoon: item.dtoPatientMedication[i].isNoon,
        isEvening: item.dtoPatientMedication[i].isEvening,
        isBedTime: item.dtoPatientMedication[i].isBedTime,
        isBeforemeal: item.dtoPatientMedication[i].isBeforemeal,
        isDelete: null,
      })
    }
  }

  postProgressMedication() {
    this.spinnerservice.show();

    //const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoMedication, false);
    this.clinicianservice.postProgressMedication(this._dtoMedication).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (this.editmedication) {
          var update = res.replace('saved', 'Updated')
          this.snackbar.success(update);
        } else {
          this.snackbar.success(res);
        }

        this._dtoMedication = [];
        this.ongetMedication();
        //this.getProgressActivityDetails(this.grpTherapydetails);
        this.editmedication = false;
        this.onAddmedication = false;
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );

  }

  getPatientMedication() {
    this.spinnerservice.show();
    //  console.log(this.userId )
    this.clinicianservice.getPatientMedication(this.selectedMsId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.medicationRecords = res;
        this.ongetMedication();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getSeverityMaster() {
    this.spinnerservice.show()
    this.commonService.getSeverityMaster().subscribe(
      res => {
        if (res != null) {
          this.ddlseverity = res;
          this.spinnerservice.hide()
        }
      },
      err => {
        this.spinnerservice.hide();

})
}
onSelectionChanged(value){
  this.bg = this.ddlseverity.filter(x=>x.severityValue==value)[0].colocode;
  this._dtoPatientInfo.psyTherapyInfo.severity=value;
  // this.PostPsyTheraapyFrm(2);
}
// clearDefaultValues(){
//   this.SelectedTab = 0;
//   this.isEdit = false;
//   this.selecteddate = '';
//   this.selectedtheraphyid= '';
//   this.selectedMsId= '';
//   this.selectedIntervention = '';
//  this._dtoPatientInfo= '';
//   this.Ismedicationreaction= false;
//   this.MedCompliance= false;
//   this.signatureImg='';
//   this.isESignAttached= false;
//   this.updatedsignatureImg= '';
//   this.isformtype= '';
//   this.defaultImage= '';
//   this.PopUpPreview= false;
//   this.goalsinfo= '';
//   this.Assessmentgiven= false;
//   this.Councellinggiven= false;
//   this.IseditPsy= false;
//   this.ddldiagnosiscodeModel = '' ;
//   this.ddldiagnosiscode= '';
//   this.DiagnosisCodeAllowed= '';
//   this.pdfpsytherapynotes= '';
//   this.isLicensed= false;
//   this.RoleId= '';
//   this.onAddmedication= false;
//   this._dtoMedication= '';
//   this.medicationRecords= '';
//   this.editmedication= false;
//   this.lstmedicine= '';
//   this.isshowhistroy= false;
//   this.IsCloseWindow= false;
//   this.ddlseverity= '';
//   this.bg= '';
//   this.progressUpg= false;
// }

openDialog1() {
  // this.global.isconsentesign = false;
  // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
  //   data: { isfutureSign: false, isParentsign: true },
  //   autoFocus: false 
  // });
  this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if (
      this.global.isconsentesign != undefined &&
      this.global.isconsentesign
    ) {
      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
      this.signatureImg = this.updatedsignatureImg;
      this.psyTherapyInfo.staffInitial1 = this.updatedsignatureImg;
     // this.IncReportfrmInfo.IsAttachEsign = true;
      (document.getElementById('esign_1') as HTMLImageElement).src =
        this.signatureImg;
    } else if (
      !this.global.isconsentesign &&
      this.global.formtype != null &&
      this.global.formtype != undefined
    ) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      this.psyTherapyInfo.staffInitial1 = this.global.consentesign;
      //this.IncReviewInfo.IsAttachEsign = true;
      (document.getElementById('esign_1') as HTMLImageElement).src =
        this.signatureImg;
    } else {
      this.signatureImg = this.defaultImage;
      //this.IncReportfrmInfo.IsAttachEsign = true;
      this.psyTherapyInfo.staffInitial1 = this.defaultImage;
    }
  });
}
secondarySign():any{
  if(this.psyTherapyInfo.licenseNumber != '' && this.psyTherapyInfo.licenseNumber != null ){
    return false;
  }
  else if(this.psyTherapyInfo.sSignature ){
   return false;
  }
  else{
    return true;
  }
}
getAutoFillSignature() {
  this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
      this.GetClinicianSign = res;

      if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
        this.AutoFillSS=false;
        this.SSignature=this.GetClinicianSign[0].clinicianSign;
      }
      else
      {
        this.AutoFillSS=true;
        this.SSignature='';
      }
  });
}
}

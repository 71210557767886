<div class="row">
  <div class="col-md-12">
    <div class=" col-md-1 ml-auto mt-2 button-position pr-0">

      <button type="button" (click)="onback()" class="btn primary-btn-outlined float-right mr-2">
        <i class="fas fa-angle-left pr-2"></i>Back
      </button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12 p-0 mt-2 mb-4">
    <!-- <form (ngSubmit)="leadinfo.form.valid  && updateleadinfo(1)" id="leadinfo" autocomplete="off" #leadinfo="ngForm"
        novalidate> -->
    <div class="row">
      <div class="col-md-6">
        <div class="lead-box">
          <div class="row">
            <div class="col-md-12">
              <div class=" ">
                <div class="insurance-head">Insurance Details</div>
                <div class="line-input mt-2">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label-new font-weight-bold">Client Name</label>

                        <input class="form-control mb-2"
                               maxlength="50"
                               [(ngModel)]="insuranceinfo.MemberName"
                               #MemberName="ngModel"
                               name="MemberName"
                               [disabled]="true" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label-new font-weight-bold">Insurace Plan</label>

                        <input class="form-control mb-2"
                               maxlength="50"
                               [(ngModel)]="insuranceinfo.CarrierName"
                               #CarrierName="ngModel"
                               name="CarrierName"
                               [disabled]="!isAddInsurance" required />
                      </div>
                      <div *ngIf="CarrierName.invalid && (CarrierName.dirty || CarrierName.touched)" class="insurance-imgsize1">
                        Carrier Name is required.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label-new font-weight-bold">Insurance Type</label>

                      <select class="form-control"
                              [(ngModel)]="insuranceinfo.InsuranceTypeId"
                              #InsuranceTypeId="ngModel"
                              name="InsuranceTypeId"
                              [disabled]="!isAddInsurance" required>
                        <option *ngFor="let item of ddlInsuranceType"
                                [value]="item.InsuranceTypeId">
                          {{ item.InsuranceTypeName }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="control-label-new font-weight-bold">Address</label><br />

                      <span>{{paientdetails?.AddressLine}},</span><br />
                      <span>{{paientdetails?.City}},</span><br />
                      <span>{{paientdetails?.State}},</span><br />
                      <span>{{paientdetails?.ZipCode}}</span><br />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="control-label-new font-weight-bold">Insurance card(Front)</label>
                    <div class="img-preview position-relative"
                         *ngIf="imgInsuranceFront">
                      <div class="insurance-img">
                        <img [src]="imgInsuranceFront"
                             style="height: 100px; width: 67%" />
                      </div>
                      <div class="action-icon-ins">
                        <span class="">
                          <a title="View" (click)=showImage(1)
                             role="button"><i class="fa fa-eye" aria-hidden="true"></i></a>
                        </span>

                      </div>
                    </div>

                  </div>
                  <div class="col-md-4">
                    <label class="control-label-new font-weight-bold">Insurance card(Back)</label>
                    <div class="img-preview position-relative"
                         *ngIf="imgInsuranceBack">
                      <div class="insurance-img">
                        <img [src]="imgInsuranceBack"
                             style="height: 100px; width: 67%" />
                      </div>
                      <div class="action-icon-ins">
                        <span class="">
                          <a title="View" (click)=showImage(2)
                             role="button"><i class="fa fa-eye" aria-hidden="true"></i></a>
                        </span>

                      </div>
                    </div>

                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-md-6">
                    <label class="control-label-new font-weight-bold"
                      >Insurance card(Front)</label
                    >
                    <div
                      class="img-preview position-relative"
                      *ngIf="imgInsuranceFront"
                    >
                      <div class="insurance-img">
                        <img
                          [src]="imgInsuranceFront"
                          style="height: 350px; width: 100%"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="control-label-new font-weight-bold"
                      >Insurance card(Back)</label
                    >
                    <div
                      class="img-preview position-relative"
                      *ngIf="imgInsuranceBack"
                    >
                      <div class="insurance-img">
                        <img
                          [src]="imgInsuranceBack"
                          style="height: 350px; width: 100%"
                        />
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-md-12 p-0">
            <div class="lead-box">
              <div class="clearfix w-100">
                <div class="box-lead-head float-left">Insurance Details</div>

              </div>
              <div class="col-md-12  table-custom p-0">
                <div class="table-responsive">
                  <table mat-table [dataSource]="insDataSource" class="mat-elevation-z8 w-100" matSort>


                    <ng-container matColumnDef="InsuranceType">
                      <th mat-header-cell *matHeaderCellDef> COB </th>
                      <td mat-cell *matCellDef="let element">{{element.InsuranceType}}</td>
                    </ng-container>

                    <ng-container matColumnDef="CarrierName">
                      <th mat-header-cell *matHeaderCellDef> Carrier </th>
                      <td mat-cell *matCellDef="let element">{{element.CarrierName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="MemberId">
                      <th mat-header-cell *matHeaderCellDef> Member ID</th>
                      <td mat-cell *matCellDef="let element">{{element.MemberId}}</td>
                    </ng-container>

                    <ng-container matColumnDef="GroupNo">
                      <th mat-header-cell *matHeaderCellDef> Group Number </th>
                      <td mat-cell *matCellDef="let element"> {{element.GroupNo}} </td>
                    </ng-container>

                    <ng-container matColumnDef="GotoCRBA">
                      <th mat-header-cell *matHeaderCellDef> Go To CRBA </th>
                      <td mat-cell *matCellDef="let element;let i=index;" class="">
                        <button type="button" mat-icon-button matTooltip="CRBA" type="button"
                                (click)="onUpdateCRBANew(element)" color="primary">
                          <img src="../../../assets/images/CRBA.png">
                        </button>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef>Actions </th>
                      <td mat-cell *matCellDef="let element;let i = index;" class="">
                        <button type="button" mat-icon-button matTooltip="View" type="button"
                                (click)="onviewInsurance(element)" color="primary">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <button type="button" mat-icon-button matTooltip="Edit" type="button"
                                (click)="onEditInsurance(element)" color="primary">
                          <img src="../../../assets/images/editblue.png">
                        </button>



                        <!-- <button type="button" mat-icon-button matTooltip="Delete" type="button"
                          (click)="ondeleteInsurance(element)" color="warn">
                          <img src="../../../assets/images/deletered.png">
                        </button> -->
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="insuraceColumnsAdd"></tr>
                    <tr mat-row *matRowDef="let row; columns: insuraceColumnsAdd;"></tr>
                  </table>
                </div>

              </div>



            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="col-md-12 p-0">
          <div class="lead-box">
            <div class="clearfix w-100">
              <div class="row insurace-btn">
                <div class="col-md-2">
                  <button mat-raised-button
                          class="insurance-prmy h-40 mr-2"
                          type="button"
                          (click)="previousInsurance()">
                    <i class="fa fa-angle-left"></i> Previous
                  </button>
                </div>

                <div class="col-md-8 insurance-text">
                  {{ currentCount }}/{{ lstCount }} insurance(s)
                </div>
                <div class="col-md-2">
                  <button mat-raised-button
                          class="insurance-prmy insurance-btn-back h-40 mr-2"
                          type="button"
                          (click)="nextInsurance()">
                    Next <i class="fa fa-angle-right"></i>
                  </button>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label-new font-weight-bold">Policy Number</label>

                    <input class="form-control mb-2"
                           maxlength="50"
                           [(ngModel)]="insuranceinfo.MemberId"
                           #MemberId="ngModel"
                           name="MemberId"
                           [disabled]="!isAddInsurance" required />
                  </div>
                  <div *ngIf="MemberId.invalid && (MemberId.dirty || MemberId.touched)" class="insurance-imgsize1">
                    Policy Number is required.
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <!-- <label class="control-label-new font-weight-bold">Effective From</label>

                        <input
                          class="form-control mb-2"
                          maxlength="50"
                          name="PatientName"
                          [disabled] ="true"
                        /> -->


                        <mat-form-field class="w-100">
                          <mat-label>Effective From</mat-label>
                          <input matInput [matDatepicker]="stpicker" readonly
                                 [(ngModel)]="insuranceinfo.StartDate" #StartDate="ngModel"
                                 name="StartDate" [disabled]="!isAddInsurance" required>
                          <mat-datepicker-toggle matSuffix [for]="stpicker">
                          </mat-datepicker-toggle>
                          <mat-datepicker #stpicker></mat-datepicker>
                          <!-- <mat-error *ngIf="insuranceinfo.submitted && StartDate.invalid">
                            <mat-error *ngIf="StartDate.errors.required">Start Date is required</mat-error>
                          </mat-error> -->
                        </mat-form-field>





                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <!-- <label class="control-label-new font-weight-bold"
                            >Effective To</label
                          >
                          <input
                          class="form-control mb-2"
                          maxlength="50"
                          name="PatientName"
                          [disabled] ="true"
                        /> -->

                        <mat-form-field class="w-100">
                          <mat-label>Effective To</mat-label>
                          <input matInput [matDatepicker]="stpicker1" readonly
                                 [(ngModel)]="insuranceinfo.EndDate" #EndDate="ngModel"
                                 name="EndDate" [disabled]="!isAddInsurance" required>
                          <mat-datepicker-toggle matSuffix [for]="stpicker1">
                          </mat-datepicker-toggle>
                          <mat-datepicker #stpicker1></mat-datepicker>
                          <!-- <mat-error *ngIf="insAuthorization.submitted && StartDate.invalid">
                            <mat-error *ngIf="StartDate.errors.required">Start Date is required</mat-error>
                          </mat-error> -->
                        </mat-form-field>



                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label-new font-weight-bold">Group Number</label>

                    <input class="form-control mb-2"
                           maxlength="50"
                           [(ngModel)]="insuranceinfo.GroupNo"
                           #GroupNo="ngModel"
                           name="GroupNo"
                           [disabled]="!isAddInsurance" required />
                  </div>
                  <div *ngIf="GroupNo.invalid && (GroupNo.dirty || GroupNo.touched)" class="insurance-imgsize1">
                    Group Number is required.
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label-new font-weight-bold">Group/Plan Name</label>

                    <input class="form-control mb-2"
                           [(ngModel)]="insuranceinfo.GroupName"
                           #GroupNo="ngModel"
                           name="GroupName"
                           [disabled]="!isAddInsurance" required />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="check-custom">
                      <mat-checkbox class="example-margin mr-2" [(ngModel)]="insuranceinfo.CoPayRequired" #CoPayRequired="ngModel" name="CoPayRequired"
                                    [disabled]="!isAddInsurance" [checked]="insuranceinfo.CoPayRequired">Copay Required</mat-checkbox>
                    </div>
                    <div class="check-custom">
                      <mat-checkbox class="example-margin mr-2" [(ngModel)]="insuranceinfo.PatientPercent" #PatientPercent="ngModel" name="PatientPercent"
                                    [disabled]="!isAddInsurance" [checked]="insuranceinfo.PatientPercent">Patient Percent</mat-checkbox>
                    </div>
                    <div class="check-custom">
                      <mat-checkbox class="example-margin mr-2" [(ngModel)]="insuranceinfo.AuthorizationRequired" #AuthorizationRequired="ngModel" name="AuthorizationRequired"
                                    [disabled]="!isAddInsurance" [checked]="insuranceinfo.AuthorizationRequired">Authorization Required</mat-checkbox>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="check-custom ins-active">
                      <label class="control-label-new font-weight-bold mr-4">Active</label>
                      <mat-slide-toggle [(ngModel)]="insuranceinfo.InsuranceIsActive" (change)="InsuraceActiveChange(insuranceinfo)" class="insuranceIsActive"></mat-slide-toggle>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <button type="submit" title="Save" *ngIf="isAddInsurance" [disabled]="MemberId.invalid || GroupNo.invalid || CarrierName.invalid" mat-stroked-button (click)="UpdateInsurance()"
                      class="btn edit-btn mb-2 mr-2 primary-btn-m h-40">
                {{!isEditInsurance ? 'Add' : 'Update'}}
              </button>
            </div>
            <div *ngIf="insuraceDetails?.length > 0">
              <div class="box-lead-head float-left">Insurance Details History</div>
              <div class="col-md-12 table-custom table-expired p-0 mb-2">
                <div class="table-responsive table-container">
                  <mat-table class="lessons-table w-100" [dataSource]="dataSourceinsurance">
                    <!--mat-elevation-z8-->

                    <ng-container matColumnDef="InsuranceType">
                      <th mat-header-cell *matHeaderCellDef class="carrier-name-header"> COB </th>
                      <td mat-cell *matCellDef="let element" class="carrier-name-header">{{element.InsuranceType}}</td>
                    </ng-container>

                    <ng-container matColumnDef="CarrierName">
                      <th mat-header-cell *matHeaderCellDef class="carrier-name-header"> Carrier </th>
                      <td mat-cell *matCellDef="let element" class="carrier-name-cell">{{element.CarrierName}}</td>
                    </ng-container>

                    <ng-container matColumnDef="PolicyID">
                      <th mat-header-cell *matHeaderCellDef class="carrier-name-header"> Policy ID</th>
                      <td mat-cell *matCellDef="let element" class="carrier-name-header">{{element.MemberId}}</td>
                    </ng-container>


                    <ng-container matColumnDef="GotoCRBA">
                      <th mat-header-cell *matHeaderCellDef class="carrier-name-header"> Go To CRBA </th>
                      <td mat-cell *matCellDef="let element;let i=index;" class="carrier-name-header">
                        <button type="button" mat-icon-button matTooltip="CRBA" type="button"
                                (click)="onUpdateCRBAHistory(element)" color="primary">
                          <img src="../../../assets/images/CRBA.png">
                        </button>
                      </td>
                    </ng-container>


                    <ng-container matColumnDef="GroupNo">
                      <th mat-header-cell *matHeaderCellDef class="carrier-name-header"> Group Number </th>
                      <td mat-cell *matCellDef="let element" class="carrier-name-header">{{element.GroupNo}}</td>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="insuraceColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: insuraceColumns"></mat-row>
                  </mat-table>
                  <div class="mt-4 text-center" *ngIf="insuraceDetails?.length == 0">No insurance History is found</div>
                  <mat-paginator [pageSizeOptions]="[10, 20, 50, 75, 100]" showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>
                </div>
              </div>
            </div>






          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-12 auth-head">
      <button mat-raised-button class="btn primary-btn-filled primary-btn-m float-right mt-2 h-40 mr-2" type="button"  (click)="AddAuthorization()" >Add Authorization</button>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-12">

        <div class="lead-box ml-2">
          <div>
            <button mat-raised-button class="btn primary-btn-filled primary-btn-m float-right add-auth h-40 mr-2 mb-2" type="button" (click)="onUpdateCRBA()">Go to CRBA</button>
          </div>
          <button mat-raised-button class="btn primary-btn-filled primary-btn-m float-right add-auth h-40 mr-2 mb-2" type="button" (click)="AddAuthorization()">Add Authorization</button>
          <div class="col-md-12 table-custom table-expired p-0 mb-2">
            <div class="table-responsive">
              <mat-table class="lessons-table mat-elevation-z8 w-100"
                         [dataSource]="dataSource">
                <ng-container matColumnDef="authorizationNumber">
                  <mat-header-cell *matHeaderCellDef>Authorization Number</mat-header-cell>
                  <mat-cell class="description-cell" *matCellDef="let authDetails">
                    {{ authDetails.AuthorizationNumber }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="diagonasis">
                  <mat-header-cell *matHeaderCellDef>Diagnosis</mat-header-cell>
                  <mat-cell class="description-cell"
                            *matCellDef="let authDetails">
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="effctive">
                  <mat-header-cell *matHeaderCellDef>Effective</mat-header-cell>
                  <mat-cell class="duration-cell" *matCellDef="let authDetails">
                    {{
                    authDetails.StartDate
                      | date:'MM/dd/yyy'
                    }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="expire">
                  <mat-header-cell *matHeaderCellDef>Expire</mat-header-cell>
                  <mat-cell class="duration-cell" *matCellDef="let authDetails">
                    {{
                    authDetails.EndDate
                      | date:'MM/dd/yyy'
                    }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="visits">
                  <mat-header-cell *matHeaderCellDef>Visits</mat-header-cell>
                  <mat-cell class="duration-cell" *matCellDef="let authDetails">
                    {{ authDetails.NumberOfVisits }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="appts">
                  <mat-header-cell *matHeaderCellDef>Appts</mat-header-cell>
                  <mat-cell class="duration-cell" *matCellDef="let authDetails">
                    0
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Actions">
                  <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
                  <mat-cell class="duration-cell" *matCellDef="let customer">
                    <button type="button"
                            mat-icon-button
                            matTooltip="Edit"
                            role="button"
                            class="cursor-pointer"
                            type="button"
                            (click)="editAuthorization(customer)"
                            color="primary"
                            *ngIf="roleAccess.isEdit">
                      <img src="../../../assets/images/editblue.png" />
                    </button>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
              </mat-table>
              <!-- <mat-paginator *ngIf = "authDetails?.length > 0"
                #paginator
                [pageSize]="20"
                [pageSizeOptions]="[10, 20, 50, 75, 100]"
                showFirstLastButtons
              ></mat-paginator> -->
              <div class="mt-4 text-center" *ngIf="authDetails?.length == 0"> No records found </div>

              <mat-paginator [pageSizeOptions]="[ 10, 20, 50, 75, 100]"
                             showFirstLastButtons
                             aria-label="Select page of periodic elements">
              </mat-paginator>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </form> -->
  </div>
</div>
<div class="comment-popup authorization-popup auth-popup">
  <div class="modal"
       tabindex="-1"
       role="dialog"
       id="authorization"
       [style.display]="isShowEdit ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container" role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h6 class="modal-title font-weight-bold">{{ isAdd ? 'ADD AUTHORIZATION' : 'EDIT AUTHORIZATION'}}</h6>
          <button type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  (click)="onClose()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form (ngSubmit)="
                insAuthorizationEdit.form.valid && PostInsAuthorization()
              "
                  id="insAuthorizationEdit"
                  autocomplete="off"
                  #insAuthorizationEdit="ngForm"
                  novalidate>
              <div class="col-md-12">
                <div class="row mb-2">
                  <div class="col-md-4 form-group">
                    <label class="control-label-new font-weight-bold">Client Name</label>

                    <input class="form-control mb-2"
                           maxlength="50"
                           [disabled]="true"
                           [(ngModel)]="authDetailsEdit.Name"
                           name="clientName" />
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="control-label-new font-weight-bold">Insurance</label>
                    <select class="form-control mb-2"
                            [(ngModel)]="authDetailsEdit.InsuranceDetailsId"
                            #InsuranceDetailsId="ngModel"
                            name="InsuranceDetailsId"
                            [ngClass]="{
                        'is-invalid':
                          insAuthorizationEdit.submitted &&
                          InsuranceDetailsId.invalid
                      }"
                            required [disabled]="true">
                      <option value="undefined">Select insurance</option>
                      <option *ngFor="let item of ddlInsuranceList"
                              [value]="item.insuranceDetailsId">
                        {{ item.carrierName }}
                      </option>
                    </select>
                    <mat-error *ngIf="
                        insAuthorizationEdit.submitted &&
                        InsuranceDetailsId.invalid
                      ">
                      <mat-error *ngIf="InsuranceDetailsId.errors.required || isNoInsurance">Insurance is required</mat-error>
                    </mat-error>
                  </div>

                  <div class="col-md-4 form-group">
                    <label class="control-label-new font-weight-bold">Authorization Number</label>
                    <input class="form-control mb-2"
                           maxlength="50"
                           (keypress)="alphanumericwithhypen($event)"
                           [(ngModel)]="authDetailsEdit.AuthorizationNumber"
                           #AuthorizationNumber="ngModel"
                           name="AuthorizationNumber"
                           [ngClass]="{
                        'is-invalid':
                          insAuthorizationEdit.submitted &&
                          AuthorizationNumber.invalid
                      }" />
                    <mat-error *ngIf="
                        insAuthorizationEdit.submitted &&
                        AuthorizationNumber.invalid
                      ">
                      <mat-error *ngIf="AuthorizationNumber.errors.required">Authorization Number is required</mat-error>
                    </mat-error>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="control-label-new font-weight-bold">Authorization Type</label>
                    <select class="form-control mb-2"
                            [(ngModel)]="authDetailsEdit.AuthorizationTypeId"
                            #AuthorizationTypeId="ngModel"
                            name="AuthorizationTypeId"
                            [ngClass]="{
                        'is-invalid':
                          insAuthorizationEdit.submitted &&
                          AuthorizationTypeId.invalid
                      }">
                      <option value="undefined">Select authorization</option>
                      <option *ngFor="let item of AuthorizationTypeddl"
                              [value]="item.authorizationTypeId">
                        {{ item.authorizationType1 }}
                      </option>
                    </select>
                    <mat-error *ngIf="
                        insAuthorizationEdit.submitted &&
                        AuthorizationTypeId.invalid
                      ">
                      <mat-error *ngIf="AuthorizationTypeId.errors.required">Authorization Type is required</mat-error>
                    </mat-error>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="control-label-new font-weight-bold">CPT/HCPCS Code</label>
                    <input class="form-control mb-2"
                           maxlength="10"
                           (keypress)="alphanumeric($event)"
                           [(ngModel)]="authDetailsEdit.CptHcpcs"
                           #CptHcpcs="ngModel"
                           name="CptHcpcs"
                           [ngClass]="{
                        'is-invalid':
                          insAuthorizationEdit.submitted && CptHcpcs.invalid
                      }" />
                    <mat-error *ngIf="insAuthorizationEdit.submitted && CptHcpcs.invalid">
                      <mat-error *ngIf="CptHcpcs.errors.required">CPT/HCPCS Code is required</mat-error>
                    </mat-error>
                  </div>
                  <div class="col-md-4 form-group">
                    <mat-form-field class="example-full-width w-100">
                      <mat-label>Effective Date</mat-label>
                      <input matInput
                             [matDatepicker]="stpicker"
                             readonly
                             [(ngModel)]="authDetailsEdit.StartDate"
                             #StartDate="ngModel"
                             name="StartDate" />
                      <mat-datepicker-toggle matSuffix [for]="stpicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #stpicker></mat-datepicker>
                      <mat-error *ngIf="
                          insAuthorizationEdit.submitted && StartDate.invalid
                        ">
                        <mat-error *ngIf="StartDate.errors.required">Effective Date is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4 form-group">
                    <mat-form-field class="w-100">
                      <mat-label>Expiration Date</mat-label>
                      <input matInput
                             [matDatepicker]="edpicker"
                             readonly
                             [(ngModel)]="authDetailsEdit.EndDate"
                             #EndDate="ngModel"
                             name="EndDate" />
                      <mat-datepicker-toggle matSuffix [for]="edpicker">
                      </mat-datepicker-toggle>
                      <mat-datepicker #edpicker></mat-datepicker>
                      <!-- <mat-error *ngIf="insAuthorizationEdit.submitted && EndDate.invalid">
                        <mat-error *ngIf="EndDate.errors.required">Expiration Date is required</mat-error>
                      </mat-error> -->
                    </mat-form-field>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="control-label-new font-weight-bold"># of Visits Allowed</label>
                    <input class="form-control mb-2"
                           placeholder=""
                           step="0"
                           maxlength="5"
                           numeric
                           [(ngModel)]="authDetailsEdit.NumberOfVisits"
                           #NumberOfVisits="ngModel"
                           name="NumberOfVisits"
                           [ngClass]="{
                        'is-invalid':
                          insAuthorizationEdit.submitted &&
                          NumberOfVisits.invalid
                      }" />
                    <mat-error *ngIf="
                        insAuthorizationEdit.submitted && NumberOfVisits.invalid
                      ">
                      <mat-error *ngIf="NumberOfVisits.errors.required">Number of visits is required</mat-error>
                    </mat-error>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="control-label-new font-weight-bold">Track</label>
                    <select class="form-control mb-2"
                            [(ngModel)]="authDetailsEdit.AuthorizationTrackId"
                            #AuthorizationTrackId="ngModel"
                            name="AuthorizationTrackId"
                            [ngClass]="{
                        'is-invalid':
                          insAuthorizationEdit.submitted &&
                          AuthorizationTrackId.invalid
                      }">
                      <option value="undefined">Select track</option>
                      <option *ngFor="let item of drpAuthorizationTrack"
                              [value]="item.AuthorizationTrackId">
                        {{ item.AuthorizationTrackName }}
                      </option>
                    </select>
                    <mat-error *ngIf="
                        insAuthorizationEdit.submitted &&
                        AuthorizationTrackId.invalid
                      ">
                      <mat-error *ngIf="AuthorizationTrackId.errors.required">Authorization Track is required</mat-error>
                    </mat-error>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="control-label-new font-weight-bold">Clinical Information Provided</label>
                    <textarea class="form-control mb-2"
                              [(ngModel)]="authDetailsEdit.ClinicalInformationProvider"
                              maxlength="150"
                              [ngModelOptions]="{ standalone: true }"
                              #information="ngModel"></textarea>
                  </div>
                </div>
              </div>
              <div class="text-right col-md-12 mt-2">
                <button mat-raised-button
                        class="btn primary-btn-filled primary-btn-m h-40 mr-2"
                        type="submit">
                  {{isAdd ? 'Save' : 'Update'}}
                </button>
                <button type="button"
                        class="btn btn-cancel"
                        data-dismiss="modal"
                        (click)="onClose()">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="comment-popup authorization-popup insurance-img">
  <div class="modal"
       tabindex="-1"
       role="dialog"
       id="insurance-img"
       [style.display]="isShowImage ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container" role="insurance-img">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h6 class="modal-title font-weight-bold">{{ currentImg == 1 ? 'Insurance card(Front)' : 'Insurance card(Back)'}}</h6>
          <button type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  (click)="onCloseImage()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>

            <div class="col-md-12">
              <div class="insurance-img">
                <img [src]="imgInsuranceSrc"
                     style="height: 500px; width: 100%" />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

import { Component, OnInit } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { PatientService } from 'src/app/patient/patient.service';
import _Quill from 'quill';
import { DomSanitizer } from "@angular/platform-browser";
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { LeadService } from 'src/app/patient-lead/lead.service';
import { EsignDialogComponent } from 'src/app/common/esign-dialog/esign-dialog.component';
import { ParentInfo } from 'src/app/_models/_Lead/parentInfo';
import { isValid } from 'date-fns';
@Component({
  selector: 'app-intake-consent',
  templateUrl: './intake-consent.component.html',
  styleUrls: ['./intake-consent.component.scss']
})
export class IntakeConsentComponent implements OnInit {
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],                                       // remove formatting button
        ['link'],

      ],

    },

    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range, context) => {
           // console.log("enter");
            return true;
          }
        }
      }
    }
  }

  patientInfoId: any;
  patientAccountId: any;
  parentInfoId: any;
  lstIntakeConsent: any;
  isDynamicField: boolean = false;
  templateData: any;
  templateFields: any;
  consentTemplatesId: any;
  isVerified: boolean = false;
  consentCompleted: any;
  pageId: number;
  typeId: number;
  primaryParentId: number;
  dtoButtonFieldList: any;
  signatureImg: any;
  signatureParentImg: any;
  updatedsignatureImg: any;
  updatedparentsignatureImg: any;
  isESignAttached: boolean;
  isformtype: string;
  isfutureSign: boolean;
  dynamicimgsrcid: any = [];
  isAttachEsign: boolean;
  defaultImage: any;
  isParentsign: boolean = false;
  selectedConsent:number;
  submitted:boolean=false;
  public removeEventListener: () => void;
  public anchors;
  ClinicianTypeId: any;
  selectedPhysicianId: any=0;
  lstAuthorizationConsent: any;
  CalculatePercentage: any;
  IsOnechecked: boolean;
  IsPatientAccount: boolean;
  historyTabCompleted: boolean;
  esignTabCompleted: boolean;
  parentesignTabCompleted: boolean;
  patientPrimary: any;
  IsEsignOptionSelected:boolean=false;
  IsParentEsignOptionSelected:boolean = false;
  esignTab: boolean = false;
  parentesignTab: boolean = false;
  paymentDetailsTab: boolean=false;
  parentSignModel = false;
  TemplateName :any;
  IsSignFuture: boolean;
  IsParentSignFuture: boolean ;
  patientid: any;
  patientName : any;
  Templateauthname : any;
  constructor(public global: Global, public patientservice: PatientService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    public leadservice: LeadService) { }

  ngOnInit(): void {
    this.patientPrimary = new ParentInfo();
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.patientName = localStorage.getItem("patientName");
    this.route.queryParams.subscribe(params => {
      // if (params != null) {
      //   // this.currentUserId = localStorage.getItem('id');
      //   // this.UserName = localStorage.getItem("username");
        // this.patientid = this.commonService.FrontEndDecryption(params.id).replace(/"/g, "");
        //  localStorage.setItem('patientId', this.patientid);
      if (Object.keys(params).length != 0) {
        this.typeId = Number(this.commonService.FrontEndDecryption(params.tab).replace(/"/g, ""));

        if (params.pageid == 1) {
          this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.patientAccountId).replace(/"/g, ""));
          this.pageId = params.pageid;
        }
        if(params.isPatientAccount){
          this.IsPatientAccount = true;
        }
        if(params.patientName){
          this.patientName = this.commonService.FrontEndDecryption(params.patientName).replace(/"/g, "")
        }
        if(params.patientinfoid){
          this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patientinfoid).replace(/"/g, ""));
        }
      }
      this.getInTakeQuestions();
      this.getPatientAdditionalinfo(this.patientInfoId);
    // }
    });
    localStorage.setItem('patientIdforpayment', this.patientInfoId);
  }
  getInTakeQuestions() {
    //this.spinnerservice.show();
    this.patientservice.getIntakeTemplates(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();

        this.lstIntakeConsent = cloneDeep(res);
      
        this.lstAuthorizationConsent = cloneDeep(res);
        this.CalculatePercentage = cloneDeep(res);
        this.CalculatePercentage = this.CalculatePercentage.filter(x=>x.clinicianTypeId==0)
       this.lstAuthorizationConsent[0]["isActive"] = true;
        this.lstAuthorizationConsent = this.lstAuthorizationConsent.filter(x=>x.templateNameId==2 )
        this.authtemplatechange();
        this.lstAuthorizationConsent.forEach(element => {
          element["isActive"] = false;
        });

        if (this.consentTemplatesId == undefined) {
          //this.onloadTemplate(this.lstIntakeConsent[0].consentTemplatesId,this.selectedConsent,this.lstIntakeConsent[0].clinicianTypeId,this.lstIntakeConsent[0].templateName);
          this.consentTemplatesId = this.lstIntakeConsent[0].consentTemplatesId;
          this.isVerified = this.lstIntakeConsent.filter(x => x.consentTemplatesId == this.consentTemplatesId)[0].isVerified;
        } else {
          //this.onloadTemplate(this.consentTemplatesId,this.selectedConsent,this.ClinicianTypeId,this.lstIntakeConsent[0].templateName);
        }
        this.onloadEsign();
        var countverified = this.CalculatePercentage.filter(x => x.isVerified == true).length-2;
        //this.consentCompleted = (this.lstIntakeConsent.length / 100) * countverified * 100;
        let calPercentage = this.CalculatePercentage.length-2;
        this.consentCompleted = (countverified / calPercentage) * 100;
        this.consentCompleted = this.consentCompleted.toFixed(2);
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getpercentage() {
    return this.consentCompleted
  }
  onloadEsign(){
    this.esignTab = true
    this.parentesignTab = true
    this.paymentDetailsTab = false
    this.selectedConsent = undefined
  }
  onloadPayment(){
    this.esignTab = false
    this.parentesignTab = false
      this.paymentDetailsTab = true
      this.selectedConsent = undefined
  }


  onloadTemplate_new(consentTemplatesId,i,ClinicianTypeId,index) {
  this.onloadTemplate(consentTemplatesId,i,ClinicianTypeId, this.templateType ,index)
  }

  templateType =  '';
  onloadTemplate(consentTemplatesId,i,ClinicianTypeId,templateName,index) {
    this.templateType = templateName;
    this.lstAuthorizationConsent.forEach(e => e["isActive"] = false);
    if(index) {
      this.lstAuthorizationConsent[index]["isActive"] = true;
    } else {
      this.lstAuthorizationConsent[0]["isActive"] = true;
    }
    this.esignTab = false;
    this.parentesignTab = false
    this.paymentDetailsTab = false;
    this.submitted =false
    this.global.isconsentesign = null;
    this.global.consentesign = null;
    this.global.formtype = null;
    this.selectedConsent = i
    this.selectedPhysicianId= ClinicianTypeId
    // if (consentTemplatesId == 0 ) {
    //   this.snackbar.error(constantVariables.ErrorMessage.template);
    //   return
      if (consentTemplatesId == 0 && templateName == "E-Signature" ) {

      this.esignTab = true
      //this.parentesignTab = true
      this.paymentDetailsTab = false
    }
    else if(consentTemplatesId == 0 && templateName == "Payment Details") {
      this.esignTab = false
      this.parentesignTab = false
      this.paymentDetailsTab = true
     }else{
      this.esignTab = false;
      this.parentesignTab = false;
      this.paymentDetailsTab = false;
     }
    this.consentTemplatesId = consentTemplatesId;
    this.ClinicianTypeId = ClinicianTypeId;
    this.isVerified = this.lstIntakeConsent.filter(x => x.consentTemplatesId == consentTemplatesId)[0].isVerified;
    this.spinnerservice.show();
    this.patientservice.getTemplateData(consentTemplatesId, this.patientAccountId, false,this.ClinicianTypeId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //console.log(res)
        this.templateData = cloneDeep(res.template);
        this.templateData.templateContent = this.sanitizer.bypassSecurityTrustHtml(this.templateData.templateContent);
        this.templateFields = cloneDeep(res.fields);
        this.dtoButtonFieldList = res.dtoButtonFieldList;
        this.defaultImage = res.defaultImage;
  },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
 
  postDynamicFields() {
    
    this.submitted =true
    let fielderr =false;
    let expfielderr =false;
    let checkInputerr = false;
    let fieldcheckerr = false;
    let imageErr =false;
    let signvalidation = false;
    console.log(this.templateFields)
    for (let i = 0; i < this.templateFields.length; i++) {
      let errornode = <HTMLInputElement>document.getElementById(this.templateFields[i].errFieldId);
      if (this.templateFields[i].fieldInputTypeId == 1 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null) {
        let value = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).value;
        this.templateFields[i].description = value
        if (this.templateFields[i].isMandatory && (this.templateFields[i].description == null || this.templateFields[i].description == "")) {
          errornode.innerText = " " + constantVariables.ErrorMessage.RequiredFieldValidation;
          fielderr = true
        }
        
      }
      else if (this.templateFields[i].fieldInputTypeId == 5 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null) {
        let value = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).value;
        this.templateFields[i].description = value
        if ( (this.templateFields[i].description == null || this.templateFields[i].description == "")) {
          errornode.innerText = " " + constantVariables.ErrorMessage.RequiredFieldValidation;
          expfielderr = true
        }
        else 
        {
         let isvalid = this.isValidMonthYearFormat(this.templateFields[i].description);
         if (isvalid == true)
         {
          errornode.innerText = "Month and year is not in the correct format.";
          expfielderr = true                 
         }
        }
        
      }
      else if (this.templateFields[i].fieldInputTypeId == 4 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null) {
        let element = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).checked;
        this.templateFields[i].isChecked = element
        if (this.templateFields[i].isMandatory && (!this.templateFields[i].isChecked)) {
          errornode.innerText = " " + constantVariables.ErrorMessage.RequiredFieldValidation;
          checkInputerr = true;
         } 
         else{
          this.IsOnechecked = true;
        }
        }
        if (this.templateFields[i].isChecked && this.templateFields[i].checkFieldId != "") {
          let isvalue = (<HTMLInputElement>document.getElementById(this.templateFields[i].checkFieldId))
          if (isvalue != null) {
            let value = (<HTMLInputElement>document.getElementById(this.templateFields[i].checkFieldId)).value;
            this.templateFields[i].description = value
            if (this.templateFields[i].description == null || this.templateFields[i].description == "") {
              errornode.innerText = "The field value is required";
              fieldcheckerr = true
            }
            
        }
      }
      else if (this.templateFields[i].fieldInputTypeId == 3 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null) {
        let value = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).src;
        this.templateFields[i].imageDescription = value;
        if (this.templateFields[i].imageDescription != this.defaultImage) {
          this.templateFields[i].isAttachEsign = true;
        }
        else {
          this.templateFields[i].isAttachEsign = false;
        }
        if (this.templateFields[i].isAttachEsign == false && this.templateFields[i].isMandatory) {
          errornode.innerText = " " + constantVariables.ErrorMessage.signtaurevalidation;
           imageErr =true;
           }
        if (this.templateFields[i].isMandatory && (this.templateFields[i].imageDescription == null || this.templateFields[i].imageDescription == "")) {
          errornode.innerText = " " + constantVariables.ErrorMessage.signtaurevalidation;
          signvalidation = true;
        }
      }
    }
    if(this.IsOnechecked==true && this.selectedConsent == 2){
      checkInputerr = false;
      for (let i = 0; i < this.templateFields.length; i++) {
        let errornode = <HTMLInputElement>document.getElementById(this.templateFields[i].errFieldId);
       if (this.templateFields[i].fieldInputTypeId == 4 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null) {
          errornode.innerText = "";
      }
      }
    }
    if(fielderr || checkInputerr || fieldcheckerr ||  imageErr || signvalidation || expfielderr ){
      this.snackbar.error(constantVariables.ErrorMessage.MandatoryFieldError);
      return
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(this.templateFields, false);
    this.spinnerservice.show();
    this.patientservice.postUpdateDynamicFields(this.templateFields,this.selectedPhysicianId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.postUpdateVerified(true);
       // this.onloadTemplate(this.consentTemplatesId,this.selectedConsent);
       this.snackbar.success(JSON.parse(res).message);
        //this.getInTakeQuestions();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  postUpdateVerified(data) {
    this.isVerified = data;
    let obj = {
      ConsentTemplatesId: this.consentTemplatesId,
      PatientAccountId: this.patientAccountId,
      IsVerified: data,
      PhysicianTypeId : this.selectedPhysicianId
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(this.templateFields, false);
    this.spinnerservice.show();
    this.patientservice.postUpdateVerified(obj).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if(this.consentTemplatesId==this.CalculatePercentage[this.CalculatePercentage.length-1].consentTemplatesId){
          if(this.pageId==1){
            this.onBackClick()
          }
          else{
            window.close();
          }
        }
        else{
          this.getInTakeQuestions();
        }
      

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );

  }

  openDialog(imageId) {
    this.parentSignModel = false;
    const oldImage = (document.getElementById(imageId) as HTMLImageElement).src;
    this.global.isconsentesign = false;
    let dtoButtonField = this.dtoButtonFieldList.filter((item) => item.buttonFieldName == imageId);
    if (dtoButtonField.length) {
      dtoButtonField.forEach(fieldtype => {
        if (fieldtype.buttonFieldType == 3) {
          this.parentSignModel = true;         
        }
        if (this.updatedparentsignatureImg != null) {
          this.isParentsign = true;
        }
        else {
          this.isParentsign = false;
        }
      });
    }
    const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
      { data: { signature:this.IsParentSignFuture,parentinfoId: this.primaryParentId, isfutureSign: this.isfutureSign, isParentsign: this.isParentsign,parentSignModel:this.parentSignModel } });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign) {
        if(this.isParentsign == true){
          this.signatureImg = this.updatedparentsignatureImg;
          this.isAttachEsign = true;
        }else if(this.isParentsign == false && this.parentSignModel == true){
            this.signatureImg = oldImage;
            this.snackbar.error("Esign is not defined in profile creation. Please add esign.");

          }else{
            this.signatureImg = this.updatedsignatureImg;
            this.isAttachEsign = true;
          }

        
       
      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.isAttachEsign = true;
        
      }
      else {
        this.signatureImg = oldImage;
      }
      (document.getElementById(imageId) as HTMLImageElement).src = this.signatureImg;
      //for error validation
      if(this.submitted){
        for (let i = 0; i < this.templateFields.length; i++) {
        
          let errornode = <HTMLInputElement>document.getElementById(this.templateFields[i].errFieldId);
         if (this.templateFields[i].fieldInputTypeId == 3 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null) {
          let value = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).src;
          this.templateFields[i].imageDescription = value;
          if (this.templateFields[i].imageDescription != this.defaultImage) {
            this.templateFields[i].isAttachEsign = true;
          }
          else {
            this.templateFields[i].isAttachEsign = false;
          }
          if (this.templateFields[i].isAttachEsign == true && this.templateFields[i].isMandatory) {
            errornode.innerText = " ";
          }
          }
        }
      }
    });
  }
  // openDialog1(imageId) {
  //   const oldImage = (document.getElementById(imageId) as HTMLImageElement).src;
  //   this.global.isconsentesign = false;
  //   let dtoButtonField = this.dtoButtonFieldList.filter((item) => item.buttonFieldName == imageId);
  //   if (dtoButtonField.length) {
  //     dtoButtonField.forEach(fieldtype => {
  //       if (fieldtype.buttonFieldType == 3) {
  //         this.isParentsign = true;
  //       }
  //       else {
  //         this.isParentsign = false;
  //       }
  //     });
  //   }
  //   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
  //     { data: {signature:this.updatedparentsignatureImg ,parentinfoId: this.primaryParentId, isfutureSign: this.isfutureSign, isParentsign: this.isParentsign } });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.isESignAttached = this.global.isconsentesign;
  //     if (this.global.isconsentesign) {
  //       this.signatureImg = this.updatedparentsignatureImg;
  //       this.isAttachEsign = true;
  //     }
  //     else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
  //       this.signatureImg = this.global.consentesign;
  //       this.isformtype = this.global.formtype;
  //       this.isAttachEsign = true;
        
  //     }
  //     else {
  //       this.signatureImg = oldImage;
  //     }
  //     (document.getElementById(imageId) as HTMLImageElement).src = this.signatureImg;
  //     //for error validation
  //     if(this.submitted){
  //       for (let i = 0; i < this.templateFields.length; i++) {
        
  //         let errornode = <HTMLInputElement>document.getElementById(this.templateFields[i].errFieldId);
  //        if (this.templateFields[i].fieldInputTypeId == 3 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null) {
  //         let value = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).src;
  //         this.templateFields[i].imageDescription = value;
  //         if (this.templateFields[i].imageDescription != this.defaultImage) {
  //           this.templateFields[i].isAttachEsign = true;
  //         }
  //         else {
  //           this.templateFields[i].isAttachEsign = false;
  //         }
  //         if (this.templateFields[i].isAttachEsign == true && this.templateFields[i].isMandatory) {
  //           errornode.innerText = " ";
  //         }
  //         }
  //       }
  //     }
  //   });
  // }
  ValidateError(){
    if(this.submitted){
      for (let i = 0; i < this.templateFields.length; i++) {
        
        let errornode = <HTMLInputElement>document.getElementById(this.templateFields[i].errFieldId);
        if (this.templateFields[i].fieldInputTypeId == 1 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null) {
  
          let value = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).value;
          this.templateFields[i].description = value
          if (this.templateFields[i].isMandatory && !(this.templateFields[i].description == null || this.templateFields[i].description == "")) {
            errornode.innerText = "";
           
          }
        }
        else if (this.templateFields[i].fieldInputTypeId == 4 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null && this.selectedConsent != 2) {
          let element = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).checked;
          this.templateFields[i].isChecked = element
          if (this.templateFields[i].isMandatory && (this.templateFields[i].isChecked)) {
            errornode.innerText = " ";
          
            
          } 
        }
        else if (this.templateFields[i].fieldInputTypeId == 4 && <HTMLInputElement>document.getElementById(this.templateFields[i].fieldId) != null && this.selectedConsent == 2) {
          let element = (<HTMLInputElement>document.getElementById(this.templateFields[i].fieldId)).checked;
            errornode.innerText = " ";                      
        }
      }
    }
  }
  toggleHelp(input, e: MouseEvent): void {
    console.log((e))
    input.helpOpen = !input.helpOpen;
    if ((e.target as HTMLElement).tagName == 'IMG') {
      var id = (e.target as HTMLElement).id;
      console.log(e.target); 
      let toArray = id.split("_");
      this.openDialog('Img_' + toArray[1]);
      this.dynamicimgsrcid.push('Img_' + toArray[1]);
      
     
    }
    else{
      var checkid = (e.target as HTMLElement).id;
      if(checkid.split("_")[0] =='CheckBox' && this.selectedConsent == 2){
        var checkBox = <HTMLInputElement> document.getElementById(checkid);
        var text = <HTMLInputElement> document.getElementById("Text_"+checkid.split("_")[1]);
        if(text != null)
        {
          if (checkBox.checked == true){
            text.style.display = "block";
          } else {
            text.style.display = "none";
          }
        }
      }
    }
   
}

 isValidMonthYearFormat(control : any) 
{ 
  const value = control 
  if (!/^\d{2}\/\d{2}$/.test(value)) 
  {
     return true;
  } 
  const parts = value.split('/'); const month = parseInt(parts[0], 10);
  const year = parseInt(parts[1], 10);
  const currentYear = new Date().getFullYear() % 100;
  
   if ((month < 1 || month > 12) && year <= currentYear) 
   { 
    return true 
   } 
   return null;
 }

  getPatientAdditionalinfo(id) {
    //this.spinnerservice.show();
    this.leadservice.getPatientAdditionalinfo(id).subscribe(
      (res: any) => {

        var patientprofile = this.commonService.decrypdata(res.objInputString);
        if (patientprofile) {

          this.patientPrimary = patientprofile.ObjDtoParentInfo.filter(
            x => x.IsPrimaryContact === true)[0];
          this.primaryParentId = this.patientPrimary.ParentInfoId;
          if (this.patientPrimary.Esignature != null && this.patientPrimary.Esignature != '' && this.patientPrimary.IsSignFuture) {
            //const image = this.commonService.FrontEndDecryption(patientPrimary.Esignature);
            //this.signatureImg = this.patientPrimary.Esignature;
            this.updatedsignatureImg = this.patientPrimary.Esignature
            // this.signatureImg = this.sanitizer.bypassSecurityTrustResourceUrl(patientPrimary.Esignature);
            // this.signatureImg = this.updatedsignatureImg.changingThisBreaksApplicationSecurity.replace(/"/g, "");
          }else{
            //this.signatureImg = null;
            this.updatedsignatureImg = null;
          }
          if(this.patientPrimary.ParentEsignature !=null && this.patientPrimary.ParentEsignature !='' && this.patientPrimary.IsParentSignFuture){
            this.updatedparentsignatureImg = this.patientPrimary.ParentEsignature
          }else{
            this.updatedparentsignatureImg = null;
          }
          if (this.patientPrimary.IsSignFuture === undefined || this.patientPrimary.IsSignFuture === false || this.patientPrimary.IsSignFuture === null) {
            this.isfutureSign = undefined;
          }
          else {
            this.isfutureSign = true;
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  onBackClick()
  { if(!this.IsPatientAccount){
    window.close();
  }
  else{
     this.router.navigate(['/patientslists'])
  }
  }

  tabtoesign() {
    // this.primaryTab = false;
    // this.insuranceTab = false;
    // this.historyTab = false;
    // this.feeTab = false;
    // this.esignTab = true;
    // this.authorizationTab=false;
    // this.paymentDetailsTab=false;
    // this.getPatientAdditionalinfo(this.patientid);
    // if (!this.patientPrimary.IsSignFuture) {
    //   this.signatureImg = null;
    // }
    // localStorage.setItem("profiletab", "4");
    // this.getInsAuthStatusbyPatient();
  }
  openDialogbox() {
    // this.esignTab = true;
    const dialogRef = this.dialog.open(EsignDialogComponent,
      { data: { parentinfoId: this.patientPrimary.ParentInfoId } });
    dialogRef.afterClosed().subscribe(result => {
      if (this.global.esign != null && this.global.esign != '') {
        this.esignTabCompleted = true;
        this.updatedsignatureImg = this.global.esign;
      }
    });
  }
  openDialogbox1() {
    // this.esignTab = true;
    const dialogRef = this.dialog.open(EsignDialogComponent,
      { data: { parentinfoId: this.patientPrimary.ParentInfoId } });
    dialogRef.afterClosed().subscribe(result => {
      if (this.global.esign != null && this.global.esign != '') {
        this.parentesignTabCompleted = true;
       this.updatedparentsignatureImg = this.global.esign;
      }
    });
  }
  onEsignOption($event){
    this.IsEsignOptionSelected = true
  }
  onParentEsignOption(){
    this.IsParentEsignOptionSelected = true
  }
  nexttopaymentdetails(){ 
  //  if(this.IsEsignOptionSelected == true || false){
  //   this.esignTab = false
  //   // this.paymentDetailsTab =true;
  //   this.snackbar.success('E-sign added successfully')
  //  }
  //  else{
  //   this.snackbar.error('Please Select any option to submit.')
  // }
  if(!this.IsEsignOptionSelected && !this.IsParentEsignOptionSelected){
    this.snackbar.error('Please Select any option to submit.')
    return;
  }
  else{
  if ((this.updatedsignatureImg === null || this.updatedsignatureImg === '') && this.patientPrimary.IsSignFuture == true) {
    this.snackbar.error('Please add esign.')
    return;
  }
   if (!this.patientPrimary.IsSignFuture) {
      this.patientPrimary.Esignature = null;
      this.updatedsignatureImg = null;
    }

  this.spinnerservice.show();
  const dtoEsignInfo = {
    ParentInfoId: this.patientPrimary.ParentInfoId,
    IsSignFuture: this.patientPrimary.IsSignFuture,
    PatientInfoId :this.patientInfoId,
    Esignature:this.updatedsignatureImg,
    ParentEsignature : this.updatedparentsignatureImg,
    IsParentSignFuture : this.patientPrimary.IsParentSignFuture,
  };

  const dataToEncrypt = this.commonService.DataToEncrypt(dtoEsignInfo, false);
  this.leadservice.postEsignature(dataToEncrypt).subscribe(res => {
      if (res) {
        this.clearDefaultValues();

     this.snackbar.success(constantVariables.SuccessMessage.esignUpate);
      this.esignTabCompleted = true;
      this.parentesignTabCompleted = true;
      this.getPatientAdditionalinfo(this.patientInfoId);
      this.spinnerservice.hide();
     }
    
    // if (res !== "") {
    //   this.snackbar.success(constantVariables.SuccessMessage.esignUpate);
    //   this.esignTabCompleted = true;
    //   this.parentesignTabCompleted = true;
    //   this.clearDefaultValues();
    //   this.getPatientAdditionalinfo(this.patientInfoId);
    //   this.spinnerservice.hide();
    // } else {
    //   this.snackbar.success(constantVariables.SuccessMessage.esignUpate);
    //   this.esignTabCompleted = true;
    //   this.parentesignTabCompleted = true;
    //   this.clearDefaultValues();
    //   this.getPatientAdditionalinfo(this.patientInfoId);
    //   this.spinnerservice.hide();}
    
  },
    err => {
      this.spinnerservice.hide();
    }
  );
  }
     
    }

    clearDefaultValues(){
      // this.patientAccountId='';
       this.parentInfoId='';
      // this.lstIntakeConsent='';
      // this.isDynamicField = false;
      // this.templateData='';
      // this.templateFields='';
      // this.consentTemplatesId='';
      // this.isVerified  = false;
      // this.consentCompleted='';
      // this.pageId= undefined;
      // this.typeId= undefined;
      // this.primaryParentId=undefined;
      // this.dtoButtonFieldList='';
       this.signatureImg='';
       this.signatureParentImg='';
       this.updatedsignatureImg='';
       this.updatedparentsignatureImg='';
       this.isESignAttached=false;
      // this.isformtype='';
       this.isfutureSign = false;
      // this.dynamicimgsrcid = [];
       this.isAttachEsign  = false;
       this.defaultImage ='';
       this.isParentsign = false;
      // this.selectedConsent=undefined;
      // this.submitted=false;
      
      // this.ClinicianTypeId=0;
      // this.selectedPhysicianId=0;
      // this.lstAuthorizationConsent='';
      // this.CalculatePercentage='';
      // this.IsOnechecked = false;
      // // this.IsPatientAccount = false;
      // this.historyTabCompleted = false;
      // this.patientPrimary='';
       this.IsEsignOptionSelected=false;
       this.IsParentEsignOptionSelected = false;
     //  this.esignTab = false;
      // this.parentesignTab= false;
      // this.paymentDetailsTab=false;
       this.parentSignModel = false;
      // this.TemplateName ='';
       this.IsSignFuture = false;
       this.IsParentSignFuture = false ;
       this.patientid='';
     // this.ngOnInit();
    }
   

    authtemplatechange(){
      const index = this.lstAuthorizationConsent.findIndex(item => item.templateNameId == 2 && item.clinicianTypeId == 0)  ;
       if (index !== -1) 
       {    
        let firstObj = this.lstAuthorizationConsent.filter(item => item.templateNameId == 2 && item.clinicianTypeId == 0)[0];
         this.lstAuthorizationConsent.splice(index, 1);  
         this.lstAuthorizationConsent.push(firstObj);
         }  
        
        
        }
  // Find the first occurrence of templateNameId with a value of 2

}

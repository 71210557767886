import { Component, OnInit,ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { CommonService } from 'src/app/common/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Global } from 'src/app/global/global.model';
import { constantVariables } from 'src/app/constant/constant';
import * as moment from 'moment';
@Component({
  selector: 'app-audit-clinician',
  templateUrl: './audit-clinician.component.html',
  styleUrls: ['./audit-clinician.component.scss']
})
export class AuditClinicianComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  auditDetailList : any = [];
  clientName : any;
  displayedColumns = ['sno','activity', 'responsiblePerson', 'startDate',  'completedDate','status','actions'];
  displayedColumns1 = ['sno','activity', 'responsiblePerson', 'startDate',  'completedDate','status'];
  patientAccountId : number;
  isClinician: boolean = false;
  constructor(public spinnerservice: NgxSpinnerService,public clinicianservice: ClinicianService,
    public commonService: CommonService,private router: Router,private route: ActivatedRoute,public global: Global) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        if(params.id){
          this.patientAccountId = JSON.parse(this.commonService.FrontEndDecryption(params.id));
        }
      }
      this.getAuditDetailsClinician();
    });
   
  }
  getAuditDetailsClinician()
  {
    this.isClinician =
      Number(localStorage.getItem('roletypeId')) ==
        constantVariables.role.PrimaryClinician ||
      Number(localStorage.getItem('roletypeId')) ==
        constantVariables.role.SupportClinician
        ? true
        : false;
    this.spinnerservice.show();
    this.clinicianservice.getAuditDetailsClinician(this.patientAccountId,this.isClinician).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.auditDetailList = [];
        this.auditDetailList = this.commonService.decrypdata(res.objInputString);
        this.clientName = this.auditDetailList[0]?.FirstName+' '+this.auditDetailList[0]?.LastName;
       //  console.log(this.DecrptedlstPatient);
        this.dataSource = new MatTableDataSource(this.auditDetailList);
        this.dataSource.paginator = this.paginator;
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  userFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  OnBackClick() {
    this.router.navigateByUrl('/audit-list');
  }
  onListEdit(item)
  {
    if(item.ModuleName == constantVariables.moduleName.NutritionalQuestionarie)
    {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-nutritional'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientAccountId) } }));
      window.open(url, '_blank');  
    }
    if(item.ModuleName == constantVariables.moduleName.PainAssessment)
    {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/pain-assessment'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientAccountId), isChma: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId) }  }));
      window.open(url, '_blank');  
    }
    if(item.ModuleName == constantVariables.moduleName.CMHA)
    {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-chma'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientAccountId),patientid: this.commonService.FrontEndEncryption(item.PatientInfoId), isChma: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId) } }));
      window.open(url, '_blank');
    }
    if(item.ModuleName == constantVariables.moduleName.SuicidalRiskAssessment)
    {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/sucidal-risk'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientAccountId)} }));
      window.open(url, '_blank');
    }
    if (item.TherapyType ==2) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(constantVariables.therapy.IndividualTheraphy)}}));
  
    window.open(url, '_blank');  
    }
   if (item.TherapyType ==3) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(constantVariables.therapy.FamilyTheraphy)}}));
  
    window.open(url, '_blank');  
    }
    if (item.TherapyType ==12) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(constantVariables.therapy.Speech)}}));
  
    window.open(url, '_blank');  
    }
   if (item.TherapyType ==13) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(constantVariables.therapy.Nutrition)}}));
  
    window.open(url, '_blank');  
    }
    if (item.TherapyType ==5) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatrictherapy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(constantVariables.therapy.PsycatricTheraphy)}}));
  
    window.open(url, '_blank');  
    }
    if (item.TherapyType ==9) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatricmedication'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(9)}}));
  
    window.open(url, '_blank');  
    }
    if (item.TherapyType ==8) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatricevaluation'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(8)}}));
  
    window.open(url, '_blank');  
    }
    if (item.TherapyType ==10) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(10)}}));
  
    window.open(url, '_blank');  
    }
    if (item.TherapyType ==11) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.MeetingId),date: this.commonService.FrontEndEncryption(moment(item.StartDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(11)}}));
  
    window.open(url, '_blank');  
    }
    if (item.OrderbyId ==11) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.GroupTherapyId),dayScheduleSubGroupId: this.commonService.FrontEndEncryption(item.DayScheduleSubGroupId) }}));
  
    window.open(url, '_blank');  
    }
    if(item.ModuleName == constantVariables.moduleName.TreatmentPlan)
    {
      let LocationId = +localStorage.getItem('location').split(',')[0].replace("\"",'').replace('"','');
      const url = this.router.serializeUrl(this.router.createUrlTree(['/treatmentplan'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId),location:this.commonService.FrontEndEncryption(LocationId)} }));
    
      window.open(url, '_blank');
    //   const url = this.router.serializeUrl(this.router.createUrlTree(['/treatmentplan'], { queryParams: { id: this.commonService.FrontEndEncryption(item.ChmaTreatmentSummaryId),account:this.commonService.FrontEndEncryption(item.PatientAccountId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId)   }}));
    //  window.open(url, '_blank');
     }
     if(item.OrderbyId == 12)
    {
      if(item.StatusId == 1)
      {
        const url = this.router.serializeUrl(this.router.createUrlTree( ['/incident-report']));
        window.open(url, '_blank');
       
      }else{
        const url = this.router.serializeUrl(this.router.createUrlTree(['/incident-report'],{queryParams:{id:this.commonService.FrontEndEncryption(item.MeetingId),isedit:this.commonService.FrontEndEncryption(1),status:this.commonService.FrontEndEncryption(1) }}));
        window.open(url, '_blank');
      }
    
     }
     if(item.OrderbyId == 13)
     {
      if(item.StatusId == 1)
      {
        const url = this.router.serializeUrl(this.router.createUrlTree( ['/incident-report']));
        window.open(url, '_blank');
       
      }else{
        const url = this.router.serializeUrl(this.router.createUrlTree(['/incident-report'],{queryParams:{id:this.commonService.FrontEndEncryption(item.MeetingId),isedit:this.commonService.FrontEndEncryption(1),status:this.commonService.FrontEndEncryption(2) }}));
        window.open(url, '_blank');
      }
      
      }
      if(item.OrderbyId == 14)
     {
      let id = localStorage.getItem('id') 
      const url = this.router.serializeUrl(this.router.createUrlTree(['/discharge-summary'],{queryParams:{id:this.commonService.FrontEndEncryption(item.MeetingId),isedit:this.commonService.FrontEndEncryption(1),clinician:this.commonService.FrontEndEncryption(id)}}));
        window.open(url, '_blank');
     }
     if(item.OrderbyId == 15)
     {
      let id = localStorage.getItem('id') 
      const url = this.router.serializeUrl(this.router.createUrlTree(['/treatment-summary'],{queryParams:{id:this.commonService.FrontEndEncryption(item.MeetingId),isedit:this.commonService.FrontEndEncryption(1),clinician:this.commonService.FrontEndEncryption(id)}}));
        window.open(url, '_blank');
     }
  //   else if (item.therapyType ==3) {
  //     const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
  //     theraphyid: this.commonService.FrontEndEncryption(2),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
  //     window.open(url, '_blank');  
  //   }
  //   else if (item.therapyType ==5) {
  //     const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatrictherapy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
  //     theraphyid: this.commonService.FrontEndEncryption(5),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
  //     window.open(url, '_blank'); 
  // }
  //   else if (item.therapyType ==9) {
  //     const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatricmedication'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
  //     theraphyid: this.commonService.FrontEndEncryption(item.therapyType),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
  //     window.open(url, '_blank'); 
  // }
  // else if (item.therapyType ==8) {
  //   const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatricevaluation'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),theraphyid: this.commonService.FrontEndEncryption(item.therapyType),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
  //   window.open(url, '_blank'); 
  // }
  // else if (item.therapyType ==10 ) {
  //   const url = this.router.serializeUrl(this.router.createUrlTree(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
  //   theraphyid: this.commonService.FrontEndEncryption(item.therapyType),IsFromHistory:this.commonService.FrontEndEncryption(1)
    
  // } }));
  // window.open(url, '_blank'); 
  // }
  // else if (item.therapyType ==11 ) {
  //   const url = this.router.serializeUrl(this.router.createUrlTree(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
  //   theraphyid: this.commonService.FrontEndEncryption(item.therapyType),IsFromHistory:this.commonService.FrontEndEncryption(1)
    
  // } }));
  // window.open(url, '_blank'); 
  // }
  // else if (item.therapyType ==12 ) {
  //   const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
  //     theraphyid: this.commonService.FrontEndEncryption(12),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
  //   window.open(url, '_blank'); 
  // }
  // else if (item.therapyType ==13 ) {
  //   const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
  //   theraphyid: this.commonService.FrontEndEncryption(13),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
  //   window.open(url, '_blank'); 
  // }
  }
}

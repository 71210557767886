import { Component, OnInit, ViewChild } from '@angular/core';
import { Dtoblocktime, DtoColorCodeMaster, DtoPurposeMaster } from 'src/app/_models/_Common/_blocks';
import { CommonService } from 'src/app/common/common.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { cloneDeep } from "lodash";
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { data } from 'browserslist';
import { TemplateService } from 'src/app/common/pages/template/template.service';


@Component({
  selector: 'app-block-time',
  templateUrl: './block-time.component.html',
  styleUrls: ['./block-time.component.scss']
})
export class BlockTimeComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('errorpaginator') paginatorimport: MatPaginator;
  @ViewChild(MatSort) sortimport: MatSort;
  @ViewChild('blocktimeform') blocktimeform: NgForm;

  displayedColumns = ["id", "purposeName", "colorName","actions"];
  dataSource: MatTableDataSource<any>;
  dataSourceimport: MatTableDataSource<any>;
  ShowActivity: boolean = false;
  dtopurpose : DtoPurposeMaster;
  dtocolorcode : DtoColorCodeMaster;
  dtoblocktime : Dtoblocktime;
  // phonebookData: DtoBlockTime;
  selectedBlockId=0;
  isEditSummaryBlockTime: boolean = false;
  Dtoblocktime: any;
  importfails: any;
  editSummarId: any;
  isEditBlocktime: boolean = false;
  isEditSummaryBlocktime: boolean = false;
  touchUi : boolean =false;
  disabled:boolean=null;
  color:any;
  picker:any;
  ColorName:any;
  isShowColor:boolean=false;
  bg = '#ff4000'
  green = '#bfff00'

  constructor(private commonService: CommonService, 
     private snackbar: SnackBarService,
      public spinnerservice: NgxSpinnerService,
      private dialog: MatDialog,public tempService: TemplateService) { }

  ngOnInit(): void {
    this.dtopurpose = new DtoPurposeMaster();
    this.dtocolorcode = new DtoColorCodeMaster();
    this.dtoblocktime = new Dtoblocktime();
    // this.OnActivityClick();
    this.Cancel();
    this.getBlockTime();
    this.Onpurpose();
    this.Oncolor();


  }
  Onpurpose(){
    this.commonService.getPurposeOfBlock().subscribe(data => {
      console.log(data);
      this.dtopurpose=data;
    //   this.allsupermodules = this.pageListDetails.map(item => item.superModule)
    //     .filter((value, index, self) => self.indexOf(value) === index);
     });
    }
     Oncolor(){
     this.commonService.getColorOfBlock().subscribe(data => {
      console.log(data);
      this.dtocolorcode=data;
     });
    }
    //  this.commonService.getBlockTime().subscribe(data => {
    //   console.log(data);
    // //  this.dtoblocktime=data;
    //  })
    // this.commonService.getBlockTime().subscribe(data =>{
    //   console.log(data);

    // })
    //console.log(Patient)
    // this.ShowActivity = true;
  
   // this.leadhistory=[];
  
  Cancel(){
      this.ShowActivity = false
  }
  getBlockTime(){
    this.commonService.getBlockTime().subscribe(
      res => {
        if (res) {
          this.Dtoblocktime = res;
          this.dataSource = new MatTableDataSource(this.Dtoblocktime);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      })
  }
  Onsave(blocktimeform){

    if (this.blocktimeform.form.value.colorname.hex=="")
    {
      this.snackbar.error("Select BlockTime Color");

      return;
    }
    if (!this.blocktimeform.valid)
    {
      this.snackbar.error("Select BlockTime");
      return;
    }
    this.dtoblocktime.newcolorcode=this.blocktimeform.form.value.colorname.hex;
    this.spinnerservice.show();
    this.commonService.postBlockTime(this.dtoblocktime).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        this.snackbar.success(res);
        this.resetblocktimeform(blocktimeform)
        this.getBlockTime();
      }
    },
    err => {
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    }
  );
  }
  onClearImportFailure() {
    this.importfails.splice(this.editSummarId, 1);
    this.dataSource = new MatTableDataSource(this.importfails);
    this.dataSource.paginator = this.paginatorimport;
    this.dataSource.sort = this.sortimport;
  }
  resetblocktimeform(form: NgForm) {
    this.dtoblocktime = new Dtoblocktime();
    form.resetForm();
    this.isEditBlocktime= false;
    this.isEditSummaryBlockTime = false;
    this.editSummarId = null;
  }
  AddNewItemTypeIdTag(tag: string) {

    return tag;
  }
  postPurposeName(data: string) {
       let _obj = {
        purposeMasterId: 0,
        purposeName: data
       }
 
       const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
       this.tempService.postPurposeName(dataToEncrypt).subscribe(
         (res: any) => {
           
         },
         err => {
           this.spinnerservice.hide();
           this.snackbar.error(err.error);
         },
       );
      //}
     
   }

   
  oneditBlocktime(blocktime){
    this.isEditBlocktime = true;
    this.spinnerservice.show();
    this.commonService.getBlockTimeId(blocktime.blockTimeId).subscribe(
      data => {
        this.dtoblocktime = data;
        this.spinnerservice.hide();
        this.dtoblocktime.purposeName = data.dtoPurposeMaster.purposeName;
        this.dtoblocktime.newcolorcode=data.colorCode;
        // if (this.isEditBlocktime) {
        //   this.isEditBlocktime = false;
        //   this.snackbar.success(constantVariables.SuccessMessage.UpdateMessage);
        //   // this.onClearImportFailure()
        // }
        // this.getBlockTime =this.commonService.decrypdata(data.objInputString);
        // this.selectedCityId=this.filteredcities.find(x=>x.CitiesId==element.cityId).CitiesId
        },
    error =>this.spinnerservice.hide());

    
    this.Dtoblocktime = cloneDeep(blocktime);

  }
  ondeleteBlocktime(blocktime){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.ondltBlocktime(blocktime);

      }
    });
  }
  ondltBlocktime(blocktime) {
    this.commonService.deleteBlockTime(blocktime.blockTimeId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.getBlockTime();
        this.snackbar.success(constantVariables.SuccessMessage.DeleteTimeSuccess);
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(constantVariables.ErrorMessage.DeleteError);    
    },
    );
  }
}

export class DtoPatientOutofPacketInfoDetails
{

DtoPatientOutofPacketInfoDetailsActiveLists: Array<DtoPatientOutofPacketInfoDetailsActiveLists> = [];
DtoPatientOutofPacketInfoDetailsCompletedLists: Array<DtoPatientOutofPacketInfoDetailsCompletedLists> = [];
}
export class DtoPatientOutofPacketInfo
{
     PatientAccountId:any; 
     PatientInfoId:any; 
     PatientName:any; 
     Dob:any; 
    Age:any; 
     Gender:any; 
     Email:any; 
     ParentName:any; 
     ParentInfoId:any; 
     PtAddress:any; 
     LocationName:any; 
    SfccMasterId:any; 
     Phone:any; 
    City:any; 
    StatesId:any; 
     Zip:any; 
     CityName:any; 
     StateName:any; 
    TotalNumberofPayments:any;
     TreatmentStartDate:any; 
    TotalAmountReceived:any; 
    PendingPayment:any; 
    NotesContent:any; 
     NotesId:any; 
}

export class DtoPatientOutofPacketInvoicePdf
{
  dtoPatientOutofPacketInfo: DtoPatientOutofPacketInfo = new DtoPatientOutofPacketInfo();
  dtoPatientOutofPacketInfoDetailsPdf: Array<DtoPatientOutofPacketInfoDetailsCompletedLists> = [];
  Totalpending:any;
}

export class DtoPatientOutofPacketInfoDetailsActiveLists
{
  TherapyName:any;
TherapyId:any;
 PayFrequencyName:any;
Duration:any;
 PaidAmount:any;
 PaymentType:any;
 PaidOn:any;
 PatientAccountId:any;
 PatientInfoId:any;
 MeetingScheduleTimeId:any;
 DayScheduleAssignId:any;
 LocationName:any;
 SfccMasterId:any;
  ClinicianId:any;
 Clinician:any;
 ApointmenteName:any;
  GroupTherapyId:any;
  DayScheduleSubGroupId:any;
  DayScheduleGroupTherapyId:any;
 StartTime:any;
  EndTime:any;
 TherapyDate:any;
 ProgressActivityDetailsId:any;
 InTakeFee:any;
 SessionFee:any;
  FinancialProposalId:any;
  PriceCatalogueId:any;
  PriceComponentId:any;
 PayFrequency:any;
 IsIop:any;
IsPcp:any;
 TreatmentCharge:any;
 IspatientVisited:any;
 BillStatus:any;
  MeetingScheduleId:any;
  NoOfTherapiesForBilling:any;
TransactionCode:any;
 CptCode:any;
 RevenueCode:any;
 InvoiceNo:any;

  WeekStartDate:any;
  WeekEndDate:any;
  IsGrouped:any;
  GroupedDayScheduleAssignId:any;
 GroupedMeetingScheduleTimeId:any;
  DueAmount:any;
}
export class DtoPatientOutofPacketInfoDetailsCompletedLists
{
  TherapyName:any;
TherapyId:any;
 PayFrequencyName:any;
Duration:any;
 PaidAmount:any;
 PaymentType:any;
 PaidOn:any;
 PatientAccountId:any;
 PatientInfoId:any;
 MeetingScheduleTimeId:any;
 DayScheduleAssignId:any;
 LocationName:any;
 SfccMasterId:any;
  ClinicianId:any;
 Clinician:any;
 ApointmenteName:any;
  GroupTherapyId:any;
  DayScheduleSubGroupId:any;
  DayScheduleGroupTherapyId:any;
 StartTime:any;
  EndTime:any;
 TherapyDate:any;
 ProgressActivityDetailsId:any;
 InTakeFee:any;
 SessionFee:any;
  FinancialProposalId:any;
  PriceCatalogueId:any;
  PriceComponentId:any;
 PayFrequency:any;
 IsIop:any;
IsPcp:any;
 TreatmentCharge:any;
 IspatientVisited:any;
 BillStatus:any;
  MeetingScheduleId:any;
  NoOfTherapiesForBilling:any;
TransactionCode:any;
 CptCode:any;
 RevenueCode:any;
 InvoiceNo:any;
 WeekStartDate:any;
 WeekEndDate:any;
 IsGrouped:any;
 GroupedDayScheduleAssignId:any;
GroupedMeetingScheduleTimeId:any;
 DueAmount:any;
}

export  class DtoPatientOutofPacketNotes
{
 NotesId:any;
 PatientAccountId:any;
 NotesContent:any;
 IsDeleted :any;
 UserId :any=localStorage.getItem('id');
}

export class DtoOutOfPacketPdfHistory{
  PatientAccountId:any;
  PrintStartDate:any;
  PrintEndDate:any;
  IsDateSelected:boolean;
  GroupedDayScheduleAssignId:any;
  GroupedMeetingScheduleTimeId:any;
  GroupTherapyId:any;
  DayScheduleSubGroupId:any;
  DayScheduleGroupTherapyId:any;
  DayScheduleAssignId:any;
}
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { ScheduleAppoinment, colors } from 'src/app/_models/_Patient/_ScheduleAppoinment';
import { DtoMeetingTime } from 'src/app/_models/_Patient/_ScheduleAppoinment';
import { DtoMeetingParticipants } from 'src/app/_models/_Patient/_ScheduleAppoinment';
import { DtoMeetingHost } from 'src/app/_models/_Patient/_ScheduleAppoinment';
import { NgForm } from '@angular/forms';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { PatientService } from 'src/app/patient/patient.service';
import { cloneDeep } from "lodash";
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { CalendarEvent, CalendarView, CalendarEventTimesChangedEvent, } from 'angular-calendar';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
// import { MatInputModule } from '@angular/material/input';
import { MatFormFieldControl } from '@angular/material/form-field'
import { AdminTherapyPopUpObj } from 'src/app/_models/_Treatment/_TherapyPopUpObj';
import { log } from 'console';

@Component({
  selector: 'app-phonecall-schoolmeet-schedule',
  templateUrl: './phonecall-schoolmeet-schedule.component.html',
  styleUrls: ['./phonecall-schoolmeet-schedule.component.scss']
})
export class PhonecallSchoolmeetScheduleComponent implements OnInit {
  @Input() ObjTherapyPopUp: AdminTherapyPopUpObj;
  @ViewChild('divQuestionPG') divQuestionPG: ElementRef;
  @ViewChild('picker', { static: true }) _picker: MatDatepicker<Date>;
  @ViewChild('timeSlotinput') timeSlotinput: ElementRef
  public CLOSE_ON_SELECTED = false;
  public init = new Date();
  public resetModel = new Date(0);
  _DtoSchAppoinment: ScheduleAppoinment;
  _AppoinmentDetails: ScheduleAppoinment;
  statusintakeDeclined = constantVariables.status.intakeDeclined;
  statusintakeAccepted = constantVariables.status.intakeAccepted;
  timeslots = [];
  timeslots1 = [];

  meetingTimeslots = [];
  meetingdates = [];

  meetingParticipants: any;
  meetingDateBlocks: any = [];
  patientAccountId: any;
  userName: any;
  ddlCategory: any;
  ddlPrimarystaff: any;
  ddlSupportstaff: any;
  ddlappoinments: any;
  ddllocation: any;
  ddlPatientAcc: any;
  scheduleType: any;
  intakeId: number;
  schoolmeeting = constantVariables.therapy.School;
  @ViewChild('PrimaryClinician') PrimaryClinician: MatSelect;
  @ViewChild('SupportClinician') SupportClinician: MatSelect;
  isReqMeetingDate: boolean = false;
  isReqMeetingTime: boolean = false;
  isReqStaff: boolean = false;
  immediateActionId: number;
  currentdate: any;
  staffAppointmentDetails: any = [];
  staffAppointmentDay1: CalendarEvent[] = [];
  staffAppointmentDay2: CalendarEvent[] = [];
  view: CalendarView = CalendarView.Day;
  CalendarView = CalendarView;
  Day1viewDate: Date = new Date();
  Day2viewDate: Date = new Date();
  meetingPrimaryHost: any;
  meetingSupportHost: any = [];
  availableSlots:any = [];
  showCalender: boolean = false;
  isShowAssignStaff: boolean = false;
  ScheduleFirstviewDate: Date;
  ScheduleSecondviewDate: Date;

  viewDate = new Date();
  users: any = [];
  events: CalendarEvent[] = [];
  day2events: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();
  showIntakeDetails: boolean = false;
  IsshowFirsttab: boolean = true;
  IsshowSecondtab: boolean = false;
  isReqOption2Staff: boolean = false;
  userlstcopy: any = [];
  opt1Copy: any = [];
  slotoneusers: any = [];
  isScrolltop: number;
  isShowOption1Staff: boolean = false;
  patientgender: any;
  patientdetails: any;
  fullName: string;
  ScheduleName: string;
  ddlMeetingType: any;
  meetingTypeId: any;
  LocationId: any;
  isAllLocation: boolean;
  roleAccess: RoelAccess;
  isPopup: boolean = true;
  eventIntake: any;
  intakeevent: any;
  isReschedule: boolean = false;
  IspastTime: boolean = false;
  currenttime: string = '00.00';
  disabled: boolean = false;
  staffName: string;
  reschedule: any;
  eventtime: string;
  eventtimeformat: any;
  date = new Date();
  duration: any;
  durationhr: any;
  durationmin: any;
  IsDurationInvalid: boolean;
  scheduleModalityList: any;
  dtoScheduleConflictModalitylist: any;
  dateList: any[];
  buttonString: any;
  headerString: any;
  selectedStaffId: any;
  resetduration : boolean;
  tmpList:any[];
  scheduleFirstView : any;
  constructor(public global: Global, public settingsService: SettingsService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public treatmentservice: TreatmentService, public spinnerservice: NgxSpinnerService, public patientservice: PatientService, public datepipe: DatePipe, public roleaccessservice: PageAccessService) { }
  ngOnInit(): void {
    // window.addEventListener('scroll', this.scrolling, true);  
    // if(Number(localStorage.getItem('sfcclocation'))>0){
    this.isPopup = false;
    this.IsshowFirsttab = true;
    // this.LocationId= Number(localStorage.getItem('sfcclocation'))
    this.currentdate = new Date();
    this.getPatientAccounts();
    this.oninit();

    this.getMeetingCategories();

    this.GetMeetingTypeMaster();
    this.getMeetingLocation();
    //this.pushmeetingdates();
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        this.patientdetails = JSON.parse(this.commonService.FrontEndDecryption(params.id));
        this.patientAccountId = Number(this.patientdetails.patientAccountId);
        this.userName = this.patientdetails.name;
        this.fullName = this.userName + " " + (this.patientdetails.lastName == undefined ? "" : this.patientdetails.lastName);
        this.scheduleType = Number(this.patientdetails.pageid);
        this.LocationId = Number(this.patientdetails.location);
        if (this.scheduleType == 11) {
          this.ScheduleName = "Phone Call";
          this.buttonString = "SCHEDULE PHONE CALL";
          this.headerString = "Schedule Phone Call";
          const pageid = constantVariables.pagelists.ScheduleAppointmentsTherapies;
          this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
        }
        else if (this.scheduleType == 10) {
          this.ScheduleName = "School Meeting";
          this.buttonString = "SCHEDULE SCHOOL MEETING";
          this.headerString = "Schedule School Meeting "
          const pageid = constantVariables.pagelists.ScheduleAppointmentsTherapies;
          this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
        }
        else if (this.scheduleType == 1) {
          this.ScheduleName = "Intake ";
          this.headerString = " Schedule Intake ";
          this.buttonString = " SCHEDULE INTAKE"
          const pageid = constantVariables.pagelists.ScheduleIntake;
          this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
        }
        this._DtoSchAppoinment.scheduleName = this.ScheduleName + " - " + this.fullName;
        this._DtoSchAppoinment.typeId = this.scheduleType;
        this.immediateActionId = Number(localStorage.getItem("immediateActionId"));
        this.getPrimaryStaff();
         this.getsupportStaff();
        // this.getInTakeDetails();
        if (this.immediateActionId == this.statusintakeDeclined || this.immediateActionId == this.statusintakeAccepted) {
          this.getInTakeDetails();
        }
      }

    });
    // }
    // else{
    //   this.isAllLocation=true;
    //   this.oninit();
    //  }
  }
  oninit() {
    this._DtoSchAppoinment = new ScheduleAppoinment();
    this._DtoSchAppoinment.meetingTimes = new Array<DtoMeetingTime>();
    this._DtoSchAppoinment.participants = new Array<DtoMeetingParticipants>();
    // this._DtoSchAppoinment.primaryHosts = new Array<DtoMeetingHost>();
    // this._DtoSchAppoinment.supportHosts = new Array<DtoMeetingHost>();

  }
  pushmeetingdates() {
    for (let i = 0; i < 2; i++) {
      this.meetingdates.push({
        meetingScheduleTimeId: 0,
        meetingScheduleId: 0,
        status: 0,
        scheduleDate: null,
        timeSlotId: null,
        fromTime: null,
        toTime: null
      });
    }
  }

  meetingDateFilter = (d: any) => {
    const day = moment(d).day();
    if (this.meetingDateBlocks?.length > 0) {
      return this.meetingDateBlocks.includes(String(day));
    }
    return true

  };
  getMeetingCategories() {
    this.settingsService.getMeetingCategories().subscribe(
      res => {
        this.ddlCategory = res.meetingCategory;
        this.intakeId = res.intakeId;
        if (res.intakeId != 0) {
          this._DtoSchAppoinment.meetingCategoryId = this.ddlCategory.filter(x => x.meetingCategoryId == this.intakeId)[0].meetingCategoryId;
          this.onChangeMtgCatgry(this._DtoSchAppoinment.meetingCategoryId);

        } else {
          this.onChangeMtgCatgry(0);
        }

      },
      error =>
        this.spinnerservice.hide()
    );
  }

  onAppoinmentChange(item, event) {

    if (item.frequency == 2 && item.availableDays != "") {
      this.meetingDateBlocks = item.availableDays.split(',');
    } else {
      this.meetingDateBlocks = [];
    }
    this.meetingdates = [];
    this.pushmeetingdates();
  }
  onMettingDate(event, index) {
    if (index == 1) {
      this.ScheduleFirstviewDate = new Date();
    }
    if (index == 2) {
      this.ScheduleSecondviewDate = new Date();
    }
    this.UncheckAll();
    var day = moment(event.value).day();
    this.GetSlotByForPhonecallSchoolMeet(this._DtoSchAppoinment.appointmentTypesId, day, index, event.value)
  }
  onChangeMtgCatgry(event) {
    this.patientservice.getAppointmentTypes(event).subscribe(
      res => {
        this.ddlappoinments = res;
        if (this.intakeId == 1 || this.intakeId == 0) {
          this._DtoSchAppoinment.appointmentTypesId = this.ddlappoinments.filter(x => x.typeName == "Intake Schedule")[0].appointmentTypesId;
          this._DtoSchAppoinment.locationName = this.ddlappoinments.filter(x => x.typeName == "Intake Schedule")[0].locationName;
          if (this.ddlappoinments.filter(x => x.appointmentTypesId == this._DtoSchAppoinment.appointmentTypesId)[0].frequency == 2) {

            this.meetingDateBlocks = this.ddlappoinments.filter(x => x.appointmentTypesId == this._DtoSchAppoinment.appointmentTypesId)[0].availableDays.split(",");
          }
          //this.getTimeSlots(this._DtoSchAppoinment.appointmentTypesId)
        }
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  getAppoinmentsTypes(id, appointmentTypesId) {
    this.patientservice.getAppointmentTypes(id).subscribe(
      res => {
        this.ddlappoinments = res;
        var appoinmentFilter = this.ddlappoinments.filter(x => x.appointmentTypesId == appointmentTypesId)[0];
        if (appoinmentFilter && appoinmentFilter.frequency == 2 && appoinmentFilter.availableDays != "") {
          this.meetingDateBlocks = this.ddlappoinments.filter(x => x.appointmentTypesId == appointmentTypesId)[0].availableDays.split(",");

        }
      },
      error =>
        this.spinnerservice.hide()
    );
  }

  getPatientAccounts() {
    this.patientservice.getPatientAccounts().subscribe(
      res => {
        if (res != null) {
          this.ddlPatientAcc = res;
          this.patientdetails = this.ddlPatientAcc.find(x => x.patientAccountId == this.patientAccountId);
          console.log(this.patientdetails.firstName)
          // if (details.length > 0) {
          //   this._DtoSchAppoinment.age = details[0].age;
          //   this._DtoSchAppoinment.gender = details[0].gender;
          //   this.patientgender= details[0].gender
          // }
          this.meetingParticipants = [];
          this.ddlPatientAcc.forEach(element => {
            this.meetingParticipants.push(this.patientAccountId)
          });
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getPrimaryStaff() {
    this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician, this.LocationId).subscribe(
      (res: any) => {
        if (res) {
          this.ddlPrimarystaff = res;
          for (let i = 0; i < res.length; i++) {
            this.users.push({
              id: res[i].staffId,
              name: res[i].firstName,
              type: 1
            })

          }
          this.userlstcopy = [...this.users]
          // this.refresh.next();

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getsupportStaff() {
    this.commonService.getSupportStaffListV2(constantVariables.role.SupportClinician, this.LocationId).subscribe(
      (res: any) => {
        if (res) {
          this.ddlSupportstaff = res;
          this.ddlSupportstaff.map(y=> {
            let valExist = this.users.find(x=>x.id == y.staffId);
            if(valExist)
            {
              valExist.type = 2;
            }else{
              this.users.push({
                id: y.staffId,
                name: y.firstName,
                type: 2
              })
            }
          });
          this.userlstcopy = [...this.users]
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  getMeetingLocation() {
    this.spinnerservice.show();
    this.settingsService.getMeetinglocation().subscribe(
      res => {
        this.spinnerservice.hide();
        this.ddllocation = res;

      },
      error =>
        this.spinnerservice.hide()
    );
  }


  getAssignedSlots(item, event, type) {
    if (event) {
      this.showCalender = true;
      var offset = new Date().getTimezoneOffset();

      let _obj = {
        TimeSlot1FromTime: this.meetingdates[0].fromTime,
        TimeSlot1ToTime: this.meetingdates[0].toTime,
        TimeSlot2FromTime: this.meetingdates[1].fromTime,
        TimeSlot2ToTime: this.meetingdates[1].toTime,
        TimeSlot1date: moment.utc(this.meetingdates[0].scheduleDate).utcOffset(-offset).format("MM/DD/YYYY"),
        TimeSlot2date: moment.utc(this.meetingdates[1].scheduleDate).utcOffset(-offset).format("MM/DD/YYYY"),
        Staffid: item.staffId
      }
      const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
      this.patientservice.getAssignedSlots(dataToEncrypt).subscribe(
        (res: any) => {
          this.staffAppointmentDetails = JSON.parse(res).assignedSlots;
          if (this.staffAppointmentDetails != null) {
            this.staffAppointmentDay1 = [];
            this.staffAppointmentDay2 = [];
            this.Day1viewDate = new Date(this.meetingdates[0].scheduleDate);
            this.Day2viewDate = new Date(this.meetingdates[1].scheduleDate);
            var Day1 = this.staffAppointmentDetails.filter(m => moment(m.scheduleDate).format("MM/DD/YYYY") == moment(this.meetingdates[0].scheduleDate).format("MM/DD/YYYY"));
            var Day2 = this.staffAppointmentDetails.filter(m => moment(m.scheduleDate).format("MM/DD/YYYY") == moment(this.meetingdates[1].scheduleDate).format("MM/DD/YYYY"));
            for (var i = 0; i < Day1.length; i++) {
              let _obj = {
                start: new Date(moment(this.meetingdates[0].scheduleDate).format("MM/DD/YYYY") + " " + moment(Day1[i].fromTime).format("HH:mm")),
                //title: "<span><i class='fas fa-exclamation-circle text-danger mr-2'></i></span>Meeting with " + Day1[i].firstName + " " + Day1[i].lastName
                title: Day1[i].isConflict == true ? "<i class='fas fa-exclamation-circle text-danger mr-2'></i>Meeting with " + Day1[i].firstName + " " + Day1[i].lastName : "Meeting with " + Day1[i].firstName + " " + Day1[i].lastName,

              }

              this.staffAppointmentDay1 = [...this.staffAppointmentDay1, _obj];

            }
            for (var i = 0; i < Day2.length; i++) {
              let _obj = {
                start: new Date(moment(this.meetingdates[1].scheduleDate).format("MM/DD/YYYY") + " " + moment(Day2[i].fromTime).format("HH:mm")),
                title: Day2[i].isConflict == true ? "<i class='fas fa-exclamation-circle text-danger mr-2'></i>Meeting with " + Day2[i].firstName + " " + Day2[i].lastName : "Meeting with " + Day2[i].firstName + " " + Day2[i].lastName,
              }
              this.staffAppointmentDay2 = [...this.staffAppointmentDay2, _obj];
            }

            var tourrequests = this.staffAppointmentDetails.filter(x => x.requestTourId > 0 && x.isConflict == true)
            if (JSON.parse(res).isConflict && (tourrequests != null && tourrequests != undefined && tourrequests.length > 0)) {
              this.onRemoveConficts(item, _obj, type);
            }
            else if (JSON.parse(res).isConflict) {
              this.doUncheckConflicts(item, type);
            }
          }
        },
        error =>
          this.spinnerservice.hide()
      );
    }
  }
  onRemoveConficts(item, _obj, type) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.Warning.ConfictConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.postRemoveConflicts(item, type);

      } else {
        this.doUncheckConflicts(item, type);
      }

    });
  }

  UncheckAll() {

    this.showCalender = false;
    if (this.PrimaryClinician != undefined) {
      this.PrimaryClinician.options.forEach((x: MatOption, i) => {

        x.deselect();

      });
    }
    if (this.SupportClinician != undefined) {
      this.SupportClinician.options.forEach((x: MatOption, i) => {

        x.deselect();

      });
    }
  }

  doUncheckConflicts(item, type) {
    if (type == 1) {
      this.PrimaryClinician.options.forEach((x: MatOption, i) => {
        if (x.value == item.staffId) {
          x.deselect();
        }
      });
    }
    if (type == 2) {
      this.SupportClinician.options.forEach((x: MatOption, i) => {
        if (x.value == item.staffId) {
          x.deselect();
        }
      });
    }
  }

  postRemoveConflicts(item, type) {
    this.spinnerservice.show();
    var tourrequests = this.staffAppointmentDetails.filter(x => x.requestTourId > 0 && x.isConflict == true)
    const dataToEncrypt = this.commonService.DataToEncrypt(tourrequests, false);
    this.patientservice.postRemoveConflicts(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.getAssignedSlots(item, true, type);
        // this.snackbar.success(res.message);

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getInTakeDetails() {
    this.patientservice.getInTakeDetails(this.patientAccountId).subscribe(
      res => {
        if (res != null) {
          this._DtoSchAppoinment = cloneDeep(res);
          this._AppoinmentDetails = cloneDeep(res);
          this._DtoSchAppoinment.appointmentTypesId = res.appointmentTypesId;
          this.getAppoinmentsTypes(res.meetingCategoryId, res.appointmentTypesId);
          this._DtoSchAppoinment.meetingTimes = new Array<DtoMeetingTime>();
          this._DtoSchAppoinment.participants = new Array<DtoMeetingParticipants>();
          this.meetingParticipants = new Array<DtoMeetingParticipants>();;
          this.meetingPrimaryHost = new Array<DtoMeetingHost>();
          this.meetingSupportHost = new Array<DtoMeetingHost>();
          this.meetingdates = [];
          this.meetingdates = res.meetingTimes;


          res.primaryHosts.forEach(element => {
            this.meetingPrimaryHost.push(element.staffId)
          });
          res.supportHosts.forEach(element => {
            this.meetingSupportHost.push(element.staffId)
          });
          res.participants.forEach(element => {
            this.meetingParticipants.push(element.patientAccountId)
          });
        }
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  onSelectOption2(frmIntake) {
    frmIntake.submitted = true;
    if (this._DtoSchAppoinment.meetingTimes[0].meetinghost?.length == 0) {
      this.isReqStaff = true;
      return
    } else {
      this.users = this.users.filter(el => this.slotoneusers.includes(el));
      frmIntake.submitted = false;
      this.IsshowFirsttab = false;
      this.IsshowSecondtab = true;
    }
  }
  postSchAppointment(frmIntake) {
    if (!frmIntake.valid || (this.meetingTypeId == undefined && this.scheduleType == this.schoolmeeting)) {
      return
    }

    if (this._DtoSchAppoinment.meetingTimes[0].meetinghost?.length == 0) {
      this.isReqStaff = true;
      return
    }


    this._DtoSchAppoinment.patientAccountId = Number(this.patientAccountId);
    this._DtoSchAppoinment.scheduleName = this.ScheduleName + " - " + this.fullName;
    this._DtoSchAppoinment.typeId = Number(this.scheduleType);
    this._DtoSchAppoinment.meetingTimes[0].meetingTypeId = this.meetingTypeId;
    this._DtoSchAppoinment.staffId = +this.selectedStaffId;
    this._DtoSchAppoinment.meetingLocationId = this.LocationId;
    const dataToEncrypt = this.commonService.DataToEncrypt(this._DtoSchAppoinment, false);
    this.spinnerservice.show();
    this.patientservice.postIntakeSchedule(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        frmIntake.resetForm();
        this.resetScheduleform();
        this.router.navigateByUrl('/patientslists');
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  resetScheduleform() {
    this._DtoSchAppoinment = new ScheduleAppoinment();
    this.isReqMeetingDate = false;

    this._DtoSchAppoinment.scheduleName = "Intake-" + this.fullName;
    this._DtoSchAppoinment.typeId = this.scheduleType;

  }


  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.refresh.next();
  }

  userChanged({ event, newUser }) {
    event.meta.user = newUser;
    this.refresh.next();
  }

  onDay1MettingDate(event, index) {
    this.events = [];
    this.slotoneusers = [];
    this.ScheduleFirstviewDate = new Date(moment(event.value).format('MM/DD/YYYY'));
    if (this._DtoSchAppoinment.meetingTimes[0] == undefined) {
      this._DtoSchAppoinment.meetingTimes.push(new DtoMeetingTime());
    }
    this._DtoSchAppoinment.meetingTimes[0].scheduleDate =  moment.utc(event.value).format('MM/DD/YYYY');
    this.scheduleFirstView = moment(this.ScheduleFirstviewDate).format('MM/DD/YYYY');
    this._DtoSchAppoinment.meetingTimes[0].meetinghost = [];
    this.getDay1MeetingDetails(this.ScheduleFirstviewDate);
    this.users = [... this.userlstcopy];
  }

  getDay1MeetingDetails(date) {
    this.spinnerservice.show();
    this.patientservice.getDay1MeetingDetails(moment(date).format("MM/DD/YYYY")).subscribe(
      res => {
        if (res.length > 0) {
          this.spinnerservice.hide();
          //this.events = [];
          for (let i = 0; i < res.length; i++) {
            var Userslst = this.users.filter(x => x.id == res[i].staffId)
            if (Userslst.length > 0) {
              this.events.push({
                title: res[i].scheduleName,
                color: colors.yellow,
                start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                end: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                meta: {
                  user: Userslst[0],
                },
                status: constantVariables.lblMessage.Exist,
                isnew: false,
                tags: constantVariables.lblMessage.Selected
              })
            }
          }
          this.refresh.next();
        }
        var day = moment(date).day();
        if (this.scheduleType != 1) {
          this.GetSlotByForPhonecallSchoolMeet(this._DtoSchAppoinment.appointmentTypesId, day, 1, date);
        }
        else {
          this.getTimeSlots(this._DtoSchAppoinment.appointmentTypesId, day, 1, date);
        }

      })
  }

  GetSlotByForPhonecallSchoolMeet(id, day, index, date) {
    var offset = new Date().getTimezoneOffset();
    let _obj = {
      // FromTime : new Date(moment(date).format("MM/DD/YYYY") + " " +"07:00"),
      // ToTime : new Date(moment(date).format("MM/DD/YYYY") + " " + "23:00"),
      FromTime: moment(date).format("MM/DD/YYYY") + " " + "07:00",
      ToTime: moment(date).format("MM/DD/YYYY") + " " + "23:00",
      CurrentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      SelectedDate: moment.utc(date).utcOffset(-offset).format("MM/DD/YYYY"),
      Interval: 30,
      MinPerSlot: 0,
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.patientservice.GetSlotByForPhonecallSchoolMeet(dataToEncrypt).subscribe(
      res => {
        if (res != null) {
          if (index == 1)
            this.timeslots = JSON.parse(res);
          this.pushDay1AvalSlot();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  pushDay1AvalSlot() {
    //this.events=[];
    this.spinnerservice.show();
    for (let i = 0; i < this.timeslots.length; i++) {
      for (let j = 0; j < this.users.length; j++) {
        this.events.push({
          id: i,
          title: 'Available',
          color: colors.green,
          start: new Date(moment(this.ScheduleFirstviewDate).format("MM/DD/YYYY") + " " + moment(this.timeslots[i].fromTime).format("HH:mm")),
          end: new Date(moment(this.ScheduleFirstviewDate).format("MM/DD/YYYY") + " " + moment(this.timeslots[i].toTime).format("HH:mm")),
          fromtime: this.timeslots[i].fromTime,
          totime: this.timeslots[i].toTime,
          meta: {
            user: this.users[j],
          },
          isnew: true
        })
      }

    }
    let day1event = this.events.filter(x => x.status == constantVariables.lblMessage.Exist)
    for (let i = 0; i < day1event.length; i++) {
      // this.day2events = [...this.day2events.filter(x => x.start == day1event[i].start)];
      const objIndex = this.events.findIndex(obj => obj.meta.user.id == day1event[i].meta.user.id && moment(obj.start).isSame(moment(day1event[i].start)) && obj.isnew == true);
      if (objIndex > -1) {
        this.events.splice(objIndex, 1);
      }
    }


    // let updateAvailabe = this.events.filter(x=>(moment(x.start).format('HH:mm') + ":00.000" == moment(this.eventIntake?.fromtime).format('HH:mm') + ":00.000") && x.meta.user.id == this.eventIntake?.meta.user.id )
    // for (let i = 0; i < updateAvailabe.length; i++) {
    //   // this.day2events = [...this.day2events.filter(x => x.start == day1event[i].start)];
    //   const objIndex = this.events.findIndex(obj => obj.meta.user.id == updateAvailabe[i].meta.user.id && moment(obj.start).isSame(moment(updateAvailabe[i].start)) && obj.isnew == true);
    //   if (objIndex > -1) {
    //     this.events.splice(objIndex, 1);
    //   }
    // }
    this.refresh.next();
    this.spinnerservice.hide();
  }

  // eventDay1Clicked() {
  //   this.spinnerservice.show();
  //   this.eventIntake.newtime = this.eventtime;
  //   this.eventIntake.newhr = this.durationhr;
  //   this.eventIntake.newmin = this.durationmin;
  //   let date = '2021-05-28';
  //   var mins = Number(this.durationhr) + Number(this.durationmin);
  //   this.intakeevent.newendtime = moment(date).format("YYYY-MM-DD") + " " + moment(this.eventIntake.newtime).add(Number(mins), 'minutes')
  //     .format('HH:mm') + ":00.000";
  //   this._DtoSchAppoinment.meetingTimes[0].fromTime = this.intakeevent.fromtime
  //   this._DtoSchAppoinment.meetingTimes[0].toTime = this.intakeevent.newendtime
  //   if (this.eventIntake.newtime == this._DtoSchAppoinment.meetingTimes[0].fromTime) {
  //     if (this.intakeevent.isnew == true) {
  //       this.events.forEach(function (x, index, object) {
  //         if (x.status == constantVariables.lblMessage.Selected) {
  //           x.color = colors.green;
  //           x.status = constantVariables.lblMessage.Exist;
  //           x.title = 'Available';
  //         }
  //       });
  //       this.PushRescheduleSlots();
  //       // let schedulenames = this.ScheduleName + " _ " + this.fullName
  //       // if (this.intakeevent.isnew == true) {
  //       //   this.events.forEach(function (x, index, object) {
  //       //     if (x.status == constantVariables.lblMessage.Selected) {
  //       //       console.log(constantVariables);
  //       //       x.color = colors.orange;
  //       //       x.status = constantVariables.lblMessage.Exist;
  //       //       x.title = schedulenames;
  //       //     }
  //       //   });
  //         // this._DtoSchAppoinment.meetingTimes[0].meetinghost = []
  //         // this._DtoSchAppoinment.meetingTimes[0].fromTime = this.intakeevent.fromtime
  //         // this._DtoSchAppoinment.meetingTimes[0].toTime = this.intakeevent.totime
  //         // if (this.intakeevent.meta.user.type == 1) {

  //         //   let isPrimaryhost = this._DtoSchAppoinment.meetingTimes[0].meetinghost.filter(x => x.staffId == this.intakeevent.meta.user.id).length;
  //         //   if (isPrimaryhost == 0) {
  //         //     let obj = {
  //         //       meetingHostId: 0,
  //         //       meetingScheduleId: 0,
  //         //       staffId: this.intakeevent.meta.user.id,
  //         //       isActive: true,
  //         //       status: 1,
  //         //       hostType: this.intakeevent.meta.user.type
  //         //     }
  //         //     this._DtoSchAppoinment.meetingTimes[0].meetinghost.push(obj);
  //         //     this.intakeevent.color = colors.green;
  //         //     // this.intakeevent.status = constantVariables.lblMessage.Selected;
  //         //     this.intakeevent.title = 'Available';
  //         //     this.isReqStaff = false;
  //         //     this.slotoneusers.push(this.intakeevent.meta.user);
  //         //   }
  //         // }

  //       // }

  //         let schedulenames = this.ScheduleName + " _ " + this.fullName
  //         if (this.intakeevent.isnew == true) {
  //           this.events.forEach(function (x, index, object) {
  //             if (x.status == constantVariables.lblMessage.Selected) {
  //               x.color = colors.orange;
  //               x.status = constantVariables.lblMessage.Exist;
  //               x.title =schedulenames;
  //             }
  //           });

  //         this._DtoSchAppoinment.meetingTimes[0].meetinghost = []
  //         this._DtoSchAppoinment.meetingTimes[0].fromTime = this.intakeevent.fromtime
  //         this._DtoSchAppoinment.meetingTimes[0].toTime = this.intakeevent.newendtime
  //         if (this.intakeevent.meta.user.type == 1) {
  //           let isPrimaryhost = this._DtoSchAppoinment.meetingTimes[0].meetinghost.filter(x => x.staffId == this.intakeevent.meta.user.id).length;
  //           if (isPrimaryhost == 0) {
  //             let obj = {
  //               meetingHostId: 0,
  //               meetingScheduleId: 0,
  //               staffId: this.intakeevent.meta.user.id,
  //               isActive: true,
  //               status: 1,
  //               hostType: this.intakeevent.meta.user.type
  //             }
  //             let schedulenames = this.ScheduleName + " _ " + this.fullName
  //             this._DtoSchAppoinment.meetingTimes[0].meetinghost.push(obj);
  //             this.intakeevent.color = colors.orange;
  //             this.intakeevent.status = constantVariables.lblMessage.Selected;
  //             // this.intakeevent.status = constantVariables.lblMessage.Exist;
  //             this.intakeevent.title = schedulenames;
  //             this.isReqStaff = false;
  //             this.slotoneusers.push(this.intakeevent.meta.user);
  //           }
  //         }
  //        } 
  //       else {

  //         let issupportHosts = this._DtoSchAppoinment.meetingTimes[0].meetinghost.filter(x => x.staffId == this.intakeevent.meta.user.id).length;
  //         if (issupportHosts == 0) {
  //           let obj = {
  //             meetingHostId: 0,
  //             meetingScheduleId: 0,
  //             staffId: this.intakeevent.meta.user.id,
  //             isActive: true,
  //             status: 1,
  //             hostType: this.intakeevent.meta.user.type
  //           }
  //           this._DtoSchAppoinment.meetingTimes[0].meetinghost.push(obj);
  //           this.intakeevent.color = colors.green
  //           this.intakeevent.status = constantVariables.lblMessage.Selected;
  //           this.intakeevent.title = 'Available';
  //           this.isReqStaff = false;
  //           this.slotoneusers.push(this.intakeevent.meta.user);
  //         }


  //       }
  //       this.refresh.next();

  //       this.isReschedule = false;
  //       this.spinnerservice.hide();

  //     }
  //   } else {
  //     this.PushRescheduleSlots();
  //     let schedulenames = this.ScheduleName + " _ " + this.fullName
  //     if (this.intakeevent.isnew == true) {
  //       this.events.forEach(function (x, index, object) {
  //         if (x.status == constantVariables.lblMessage.Selected) {
  //           x.color = colors.orange;
  //           x.status = constantVariables.lblMessage.Exist;
  //           x.title = schedulenames;
  //         }
  //       });
  //       this._DtoSchAppoinment.meetingTimes[0].meetinghost = []
  //       this._DtoSchAppoinment.meetingTimes[0].fromTime = this.intakeevent.fromtime
  //       this._DtoSchAppoinment.meetingTimes[0].toTime = this.intakeevent.totime
  //       if (this.intakeevent.meta.user.type == 1) {

  //         let isPrimaryhost = this._DtoSchAppoinment.meetingTimes[0].meetinghost.filter(x => x.staffId == this.intakeevent.meta.user.id).length;
  //         if (isPrimaryhost == 0) {
  //           let obj = {
  //             meetingHostId: 0,
  //             meetingScheduleId: 0,
  //             staffId: this.intakeevent.meta.user.id,
  //             isActive: true,
  //             status: 1,
  //             hostType: this.intakeevent.meta.user.type
  //           }
  //           this._DtoSchAppoinment.meetingTimes[0].meetinghost.push(obj);
  //           this.intakeevent.color = colors.green;
  //           // this.intakeevent.status = constantVariables.lblMessage.Selected;
  //           this.intakeevent.title = 'Available';
  //           this.isReqStaff = false;
  //           this.slotoneusers.push(this.intakeevent.meta.user);
  //         }
  //       }
  //     }
  //   }
  //   this.refresh.next();

  //   this.isReschedule = false;
  //   this.spinnerservice.hide();
  // }
  isHrsrequired = false;

  isMinsrequird = false;
  eventDay1Clicked() {
    this.isHrsrequired = false;
    this.isMinsrequird = false;

    if (!this.durationhr || !this.durationmin ) {
      this.isHrsrequired = true;
      this.isMinsrequird = true;
      return

    }
    if(this.durationhr == 0 && this.durationmin == 0){
      this.IsDurationInvalid = true;
      return
    }
    this.spinnerservice.show();
    this.eventIntake.newtime = this.eventtime;
    this.eventIntake.newhr = this.durationhr;
    this.eventIntake.newmin = this.durationmin;
    let date = '2021-05-28';
    var mins = Number(this.durationhr) + Number(this.durationmin);
    this.intakeevent.newendtime = moment(date).format("YYYY-MM-DD") + " " + moment(this.eventIntake.newtime).add(Number(mins), 'minutes')
      .format('HH:mm') + ":00.000";
    this._DtoSchAppoinment.meetingTimes[0].fromTime = this.intakeevent.newtime
    this._DtoSchAppoinment.meetingTimes[0].toTime = this.intakeevent.newendtime
  
    if (this.eventIntake.newtime == this._DtoSchAppoinment.meetingTimes[0].fromTime) {
      if (this.intakeevent.isnew == true) {
        this.events.forEach(function (x, index, object) {
          if (x.status == constantVariables.lblMessage.Selected) {
            x.color = colors.green;
            x.status = constantVariables.lblMessage.Exist;
            x.title = 'Available';
          }
        });

        // this._DtoSchAppoinment.scheduleName = this.fullName;
        let schedulenames = this.ScheduleName + " _ " + this.fullName
        this._DtoSchAppoinment.meetingTimes[0].meetinghost = []
        this._DtoSchAppoinment.meetingTimes[0].fromTime = this.intakeevent.newtime
        this._DtoSchAppoinment.meetingTimes[0].toTime = this.intakeevent.newendtime
        if (this.intakeevent.meta.user.type == 1) {
          let isPrimaryhost = this._DtoSchAppoinment.meetingTimes[0].meetinghost.filter(x => x.staffId == this.intakeevent.meta.user.id).length;
          if (isPrimaryhost == 0) {
            let obj = {
              meetingHostId: 0,
              meetingScheduleId: 0,
              staffId: this.intakeevent.meta.user.id,
              isActive: true,
              status: 1,
              hostType: this.intakeevent.meta.user.type
            }
            this.PushRescheduleSlots()
            this.selectedStaffId=this.intakeevent.meta.user.id;
            this._DtoSchAppoinment.scheduleName = this.fullName;
            this._DtoSchAppoinment.meetingTimes[0].meetinghost.push(obj);
            // this.intakeevent.color = colors.orange;
            // this.intakeevent.status = constantVariables.lblMessage.Selected;
            // this.intakeevent.title = this.fullName;
            // this.isReqStaff = false;
            // this.slotoneusers.push(this.intakeevent.meta.user);
          }
        }
        if (this.intakeevent.meta.user.type == 2) {
          let isPrimaryhost = this._DtoSchAppoinment.meetingTimes[0].meetinghost.filter(x => x.staffId == this.intakeevent.meta.user.id).length;
          if (isPrimaryhost == 0) {
            let obj = {
              meetingHostId: 0,
              meetingScheduleId: 0,
              staffId: this.intakeevent.meta.user.id,
              isActive: true,
              status: 1,
              hostType: this.intakeevent.meta.user.type
            }
            this.PushRescheduleSlots()
            this.selectedStaffId=this.intakeevent.meta.user.id;
            this._DtoSchAppoinment.scheduleName = this.fullName;
            this._DtoSchAppoinment.meetingTimes[0].meetinghost.push(obj);
            // this.intakeevent.color = colors.orange;
            // this.intakeevent.status = constantVariables.lblMessage.Selected;
            // this.intakeevent.title = this.fullName;
            // this.isReqStaff = false;
            // this.slotoneusers.push(this.intakeevent.meta.user);
          }
        }
        else {
          let issupportHosts = this._DtoSchAppoinment.meetingTimes[0].meetinghost.filter(x => x.staffId == this.intakeevent.meta.user.id).length;
          if (issupportHosts == 0) {
            let obj = {
              meetingHostId: 0,
              meetingScheduleId: 0,
              staffId: this.intakeevent.meta.user.id,
              isActive: true,
              status: 1,
              hostType: this.intakeevent.meta.user.type
            }
            this.selectedStaffId = this.intakeevent.meta.user.id;
            this._DtoSchAppoinment.meetingTimes[0].meetinghost.push(obj);
            this.intakeevent.color = colors.green
            this.intakeevent.status = constantVariables.lblMessage.Selected;
            this.intakeevent.title = 'Available';
            this.isReqStaff = false;
            this.slotoneusers.push(this.intakeevent.meta.user);
          }


        }
        this.refresh.next();

        this.isReschedule = false;
        this.isHrsrequired = false;
        this.isMinsrequird = false;
        this.spinnerservice.hide();

      }
    } 
    else {
      this.PushRescheduleSlots();
      let schedulenames = this.ScheduleName + " _ " + this.fullName
      if (this.intakeevent.isnew == true) {
        this.events.forEach(function (x, index, object) {
          if (x.status == constantVariables.lblMessage.Selected) {
            x.color = colors.orange;
            x.status = constantVariables.lblMessage.Exist;
            x.title = schedulenames;
          }
        });
        this._DtoSchAppoinment.scheduleName = this.fullName;
        this._DtoSchAppoinment.meetingTimes[0].meetinghost = []
        this._DtoSchAppoinment.meetingTimes[0].fromTime = this.intakeevent.newtime
        this._DtoSchAppoinment.meetingTimes[0].toTime = this.intakeevent.newendtime
        if (this.intakeevent.meta.user.type == 1) {

          let isPrimaryhost = this._DtoSchAppoinment.meetingTimes[0].meetinghost.filter(x => x.staffId == this.intakeevent.meta.user.id).length;
          if (isPrimaryhost == 0) {
        let obj = {
          meetingHostId: 0,
          meetingScheduleId: 0,
          staffId: this.intakeevent.meta.user.id,
          isActive: true,
          status: 1,
          hostType: this.intakeevent.meta.user.type
        }
        this.selectedStaffId=this.intakeevent.meta.user.id;
        this._DtoSchAppoinment.meetingTimes[0].meetinghost.push(obj);
        let valueExist = this.events.filter(x=>x.fromtime == this.intakeevent.fromtime && x.totime == this.intakeevent.totime && x.status == "Exist")
        for (let i = 0; i < valueExist.length; i++) {
          // this.day2events = [...this.day2events.filter(x => x.start == day1event[i].start)];
          const objIndex = this.events.findIndex(obj => obj.meta.user.id == valueExist[i].meta.user.id && moment(obj.start).isSame(moment(valueExist[i].start)) && obj.isnew == true);
          if (objIndex > -1) {
            this.events.splice(objIndex, 1);
          }
        }
        //this.intakeevent.color = colors.green;
        // this.intakeevent.status = constantVariables.lblMessage.Selected;
        //this.intakeevent.title = 'Available';
        //this.isReqStaff = false;
        //this.slotoneusers.push(this.intakeevent.meta.user);
      }
    }
    }
  }
    this.refresh.next();

    this.isReschedule = false;
    this.isHrsrequired = false;
    this.isMinsrequird = false;
    this.spinnerservice.hide();
  }
  PushRescheduleSlots() {
    this.spinnerservice.show();
    // for (let i = 0; i < this.timeslots.length; i++) {
    //   for (let j = 0; j < this.users.length; j++) {
    let schedulenames = this.ScheduleName + " _ " + this.fullName
    this.events.push({
      id: this.intakeevent.id,
      // title: 'Available',
      // color: colors.green,
      color: colors.orange,
      status: constantVariables.lblMessage.Selected,
      title: schedulenames,
      start: new Date(moment(this.ScheduleFirstviewDate).format("MM/DD/YYYY") + " " + moment(this.intakeevent.newtime).format("HH:mm")),
      end: new Date(moment(this.ScheduleFirstviewDate).format("MM/DD/YYYY") + " " + moment(this.intakeevent.newendtime).format("HH:mm")),
      fromtime: this.intakeevent.newtime,
      totime: this.intakeevent.newendtime,
      meta: {
        user: this.intakeevent.meta.user,
      },
      isnew: true
    })
   
    let day1event = this.events.filter(x => x.status == constantVariables.lblMessage.Exist)
    for (let i = 0; i < day1event.length; i++) {
      // this.day2events = [...this.day2events.filter(x => x.start == day1event[i].start)];
      const objIndex = this.events.findIndex(obj => obj.meta.user.id == day1event[i].meta.user.id && moment(obj.start).isSame(moment(day1event[i].start)) && obj.isnew == true);
      if (objIndex > -1) {
        this.events.splice(objIndex, 1);
      }
    }
    this.tmpList= [];
    let updateAvailabe= this.events.filter(x=>(((moment(x.start).format('HH:mm') + ":00.000" == moment(this.eventIntake?.newtime).format('HH:mm') + ":00.000")) || ((moment(x.end).format('HH:mm') + ":00.000" == moment(this.eventIntake?.newendtime).format('HH:mm') + ":00.000")) 
    || ((moment(x.start).format('HH:mm') + ":00.000" > moment(this.eventIntake.newtime).format('HH:mm') + ":00.000" && moment(x.start).format('HH:mm') + ":00.000" < moment(this.eventIntake.newendtime).format('HH:mm') + ":00.000" ) || ( moment(this.eventIntake.newtime).format('HH:mm') + ":00.000" >moment(x.start).format('HH:mm') + ":00.000" && moment(this.eventIntake.newtime).format('HH:mm') + ":00.000" >moment(x.start).format('HH:mm') + ":00.000"  ) )&&
    ((moment(x.end).format('HH:mm') + ":00.000" > moment(this.eventIntake.newtime).format('HH:mm') + ":00.000" && moment(x.end).format('HH:mm') + ":00.000" < moment(this.eventIntake.newendtime).format('HH:mm') + ":00.000" ) || ( moment(this.eventIntake.newendtime).format('HH:mm') + ":00.000" >moment(x.start).format('HH:mm') + ":00.000" && moment(this.eventIntake.newendtime).format('HH:mm') + ":00.000" < moment(x.end).format('HH:mm') + ":00.000") ) || ((moment(this.eventIntake.newtime).format('HH:mm') + ":00.000" > moment(x.start).format('HH:mm') + ":00.000") && (moment(this.eventIntake.newtime).format('HH:mm') + ":00.000" < moment(x.end).format('HH:mm') + ":00.000") ) )
    && x.meta.user.id == this.eventIntake?.meta.user.id && x.status != "Selected")
    

    if(updateAvailabe.length > 0)
    {
      updateAvailabe.map(x=>{
        this.availableSlots.push(x);
      })
    }
    
    for (let i = 0; i < updateAvailabe.length; i++) {
      // this.day2events = [...this.day2events.filter(x => x.start == day1event[i].start)];
      const objIndex = this.events.findIndex(obj => obj.meta.user.id == updateAvailabe[i].meta.user.id && moment(obj.start).isSame(moment(updateAvailabe[i].start)) && obj.isnew == true);
      if (objIndex > -1) {
        this.events.splice(objIndex, 1);
      }
    }
    
   
    this.availableSlots.map((x,index)=>{
      if (
        x.title == 'Available' &&
        !((((moment(x.start).add(-1,'minutes').format('HH:mm') + ':00.000' > moment(this.eventIntake.newtime).format('HH:mm') + ':00.000' &&
             moment(x.start).add(-1,'minutes').format('HH:mm') + ':00.000' < moment(this.eventIntake.newendtime).format('HH:mm') + ':00.000')) &&

            ((moment(x.end).add(-1,'minutes').format('HH:mm') + ':00.000' > moment(this.eventIntake.newtime).format('HH:mm') + ':00.000' &&
              moment(x.end).add(-1,'minutes').format('HH:mm') + ':00.000' < moment(this.eventIntake.newendtime).format('HH:mm') + ':00.000')))
         ) 
         &&   
         ((moment(x.fromtime).format('HH:mm') + ':00.000' != moment(this.intakeevent.newtime).format('HH:mm') + ':00.000' &&
          moment(x.totime).format('HH:mm') + ':00.000' != moment(this.intakeevent.newendtime).format('HH:mm') + ':00.000'))&&

          ((moment(this.intakeevent.newendtime).format('mm') != '00' &&
           moment(x.fromtime).format('HH:mm') + ':00.000' > moment(this.intakeevent.newendtime).format('HH:mm') + ':00.000') ||
           moment(x.totime).format('HH:mm') + ':00.000' < moment(this.eventIntake.newtime).format('HH:mm') + ':00.000' ||

           (moment(this.intakeevent.newendtime).format('mm') == '00'))
      ) {
          this.events.push({
            id: x.id,
            title: 'Available',
            color: colors.green,
            //color: colors.orange,
            //status: constantVariables.lblMessage.Selected,
            //title: schedulenames,
            start: x.start,
            end: x.end,
            fromtime: x.start,
            totime: x.end,
            meta: {
              user: x.meta.user,
            },
            isnew: true,
          });
          this.tmpList.push(x);
        }
        else if(
          x.title == 'Available' &&
          !((((moment(x.start).add(-1,'minutes').format('HH:mm') + ':00.000' > moment(this.eventIntake.newtime).format('HH:mm') + ':00.000' &&
               moment(x.start).add(-1,'minutes').format('HH:mm') + ':00.000' < moment(this.eventIntake.newendtime).format('HH:mm') + ':00.000')) &&
  
              ((moment(x.end).add(-1,'minutes').format('HH:mm') + ':00.000' > moment(this.eventIntake.newtime).format('HH:mm') + ':00.000' &&
                moment(x.end).add(-1,'minutes').format('HH:mm') + ':00.000' < moment(this.eventIntake.newendtime).format('HH:mm') + ':00.000')))
           ) 
           &&   
           ((moment(x.fromtime).format('HH:mm') + ':00.000' != moment(this.intakeevent.newtime).format('HH:mm') + ':00.000' &&
            moment(x.totime).format('HH:mm') + ':00.000' != moment(this.intakeevent.newendtime).format('HH:mm') + ':00.000'))&&
  
            ((moment(this.intakeevent.newendtime).format('mm') != '00' &&
             moment(x.fromtime).format('HH:mm') + ':00.000' < moment(this.intakeevent.newtime).format('HH:mm') + ':00.000') &&
             moment(x.totime).format('HH:mm') + ':00.000' < moment(this.eventIntake.newendtime).format('HH:mm') + ':00.000' ||
  
             (moment(this.intakeevent.newendtime).format('mm') == '00'))
        )
        {
          this.events.push({
            id: x.id,
            title: 'Available',
            color: colors.green,
            //color: colors.orange,
            //status: constantVariables.lblMessage.Selected,
            //title: schedulenames,
            start: x.start,
            end: x.end,
            fromtime: x.start,
            totime: x.end,
            meta: {
              user: x.meta.user,
            },
            isnew: true,
          });
          this.tmpList.push(x);
        }

          else if(x.meta.user.id != this.intakeevent.meta.user.id )
          {
            this.events.push({
              id: x.id,
              title: 'Available',
              color: colors.green,
              //color: colors.orange,
              //status: constantVariables.lblMessage.Selected,
              //title: schedulenames,
              start: x.start,
              end: x.end,
              fromtime: x.start,
              totime: x.end,
              meta: {
                user: x.meta.user,
              },
              isnew: true,
            });
            this.tmpList.push(x);
        }
    })
    this.tmpList.map((x)=>{
      const index = this.availableSlots.indexOf(x);
      this.availableSlots.splice(index,1)
    })
    this.refresh.next();
    this.spinnerservice.hide();
  }
  resetCalender() {
    // this.durationhr = true;
    // this.durationmin = true;
    this.durationhr = undefined;
    this.durationmin = undefined;
    if (this.IsshowFirsttab) {
      this.events = [];
      this.availableSlots = [];
      this.tmpList = [];
      this._DtoSchAppoinment.meetingTimes[0].scheduleDate = this.ScheduleFirstviewDate;
      this._DtoSchAppoinment.meetingTimes[0].meetinghost = [];
      this.getDay1MeetingDetails(this.ScheduleFirstviewDate);
      this.users = [... this.userlstcopy];

    }
  }
  onback() {
    this.router.navigateByUrl('/patientslists');
  }
  scrollLeft() {

    document.getElementById('widgetsContent').scrollLeft -= 50;
    //this.divQuestionPG.nativeElement.scrollTo({ left: (this.divQuestionPG.nativeElement.scrollLeft - 50), behavior: 'smooth' });
  }

  scrollRight() {

    document.getElementById('widgetsContent').scrollLeft += 50;
    //this.divQuestionPG.nativeElement.scrollTo({ left: (this.divQuestionPG.nativeElement.scrollLeft + 50), behavior: 'smooth' });
  }
  onShowStaff() {
    if (this.userlstcopy.length != this.users.length) {

      for (let i = 0; i < this.users.length; i++) {
        var data = this.day2events.filter(x => x.meta.user.id == this.users[i].id && x.title == "Available")
        this.opt1Copy.push(data)
      }
      this.users = [... this.userlstcopy];
      //this.day2events = [];
      // this._DtoSchAppoinment.meetingTimes[1].scheduleDate = this.ScheduleSecondviewDate;
      // this._DtoSchAppoinment.meetingTimes[1].meetinghost = [];
      //  this.getDay2MeetingDetails(this.ScheduleSecondviewDate);
      this.isShowOption1Staff = true;
      // for (let i = 0; i < this.opt1Copy.length; i++) {
      //   const objIndex = this.day2events.findIndex(x => x.meta.user.id == this.opt1Copy.meta.user[i].id && x.title == "Available");
      //   if (objIndex > -1) {
      //     this.day2events.splice(objIndex, 1);
      //   }
      // }
    }
  }
  // onShowOption1staff() {
  //   this.isShowOption1Staff = false;
  //   this.users = this.users.filter(el => this.slotoneusers.includes(el));
  //   this.getDay2MeetingDetails(this.ScheduleSecondviewDate);
  // }
  // scrolling = (s) => {
  //   let sc = s.target.scrollingElement.scrollTop;

  //   if (sc >= 100) {

  //     this.isScrolltop = 1;
  //     document.getElementById('calscroll').classList.add('scrolled');
  //   }
  //   else {

  //     this.isScrolltop = 0;
  //     document.getElementById('calscroll').classList.remove('scrolled');

  //   }
  // }

  GetMeetingTypeMaster() {
    debugger;
    this.spinnerservice.show();
    this.treatmentservice.GetMeetingTypeMaster().subscribe(
      (res: any) => {
        debugger;
        this.spinnerservice.hide();
        if (res.length > 0) {
          this.ddlMeetingType = res;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  getTimeSlots(id, day, index, date) {
    var offset = new Date().getTimezoneOffset();
    let _obj = {
      Day: day,
      Id: id,
      CurrentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      SelectedDate: moment.utc(date).utcOffset(-offset).format("MM/DD/YYYY"),
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.patientservice.getTimeSlots(dataToEncrypt).subscribe(
      res => {
        if (res != null) {
          if (index == 1) {
            this.timeslots = JSON.parse(res);
            this.pushDay1AvalSlot();
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  
 
  closeOpenMonthViewDay() {
    this.events = [];
    if (this._DtoSchAppoinment.meetingTimes[0] == undefined) {
      this._DtoSchAppoinment.meetingTimes.push(new DtoMeetingTime());
    }
    // this._DtoSchAppoinment.scheduleOptionFirstDate = this.ScheduleFirstviewDate;
    // this._DtoSchAppoinment.scheduleOptionSecondDate = this.ScheduleFirstviewDate;

    this._DtoSchAppoinment.meetingTimes[0].scheduleDate = this.ScheduleFirstviewDate;
    this._DtoSchAppoinment.meetingTimes[0].meetinghost = [];
    this.getDay1MeetingDetails(this.ScheduleFirstviewDate);
    this.users = [... this.userlstcopy];
  }
  SharePopupClose() {
    this.isPopup = false;
    this.isReschedule = false;


  }
  Intake({ event }: { event: CalendarEvent }): void {
    this.spinnerservice.show();
    this.intakeevent = event;
    this.eventIntake = event;
    this.reschedule = event;
    let date = '2021-05-28';
    this.scheduleFirstView = moment(this.ScheduleFirstviewDate).format('MM/DD/YYYY');
    // this.eventtime = moment(this.eventIntake.fromtime).format('HH:mm');
    this.eventtime = moment(date).format("YYYY-MM-DD") + " " + moment(this.eventIntake.fromtime).format('HH:mm') + ":00.000";
    this.intakeevent.fromtime = moment(date).format("YYYY-MM-DD") + " " + moment(this.eventIntake.fromtime).format('HH:mm') + ":00.000";
    this.duration = moment(((Number(this.durationhr) + Number(this.durationmin))), 'minute').format('HH:mm A')
   
    this.intakeevent.newtotime = moment(date).format("YYYY-MM-DD") + " " + moment(this.eventIntake.fromtime).
      add(Number(1), 'hour').add(Number(this.durationmin), 'minutes')
      .format('HH:mm') + ":00.000";
    this.settingsService.getSchedulerTimeSlots().subscribe(
      res => {
        if (res != null) {
          this.meetingTimeslots = res;
          this.isReschedule = true;
          this.OnTimeChange();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
    this.spinnerservice.hide();
  }
  // Reschedule(){
  //   console.log(this.eventIntake)
  //   this.isReschedule = true;
  //   this.isPopup = false; 
  //   // this._DtoSchAppoinment.scheduleName = this.ScheduleName + " - " + this.fullName;
  //   this._DtoSchAppoinment. scheduleName=  this.fullName;
  //   this.staffName = this.intakeevent.meta.user.name;
  //   // this._DtoSchAppoinment.staffName = 
  //   this.settingsService.getSchedulerTimeSlots().subscribe(
  //     res => {
  //       if (res != null) {
  //         this.meetingTimeslots = res;
  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );
  // }

  OnTimeChange() {
    if (new Date() < this.eventIntake.start) {
      this.currenttime = '00.00'
    } else {
      this.currenttime = moment().format("HH:mm");
    }
    if (moment(this.eventIntake.start).format("MM/DD/YYYY") == moment(this.date).format("MM/DD/YYYY")) {
      if (moment(this.eventIntake.fromtime).format("HH:mm") > this.currenttime) {
        this.IspastTime = false;
      }
      else {
        this.IspastTime = true;
      }
    } else {
      this.IspastTime = false;
    }
  }
  // schedule(){
  //   this.spinnerservice.show();
  //   console.log(this.reschedule)
  // if(  this.reschedule.isnew == true){
  //   this.events.forEach(function (x, index, object)
  //    {
  //     if ( x.status == constantVariables.lblMessage.Selected) {
  //      x.color = colors.green;
  //       x.status = constantVariables.lblMessage.Exist;
  //       x.title = 'Available';
  //      // this.slotoneusers.push(x.meta.user);
  //     }
  //   });

  //   this._DtoSchAppoinment.scheduleName = this.ScheduleName + " - " + this.fullName;
  //   this._DtoSchAppoinment.meetingTimes[0].meetinghost = []
  //   this._DtoSchAppoinment.meetingTimes[0].fromTime =     this.reschedule.fromtime
  //   this._DtoSchAppoinment.meetingTimes[0].toTime =    this.reschedule.totime
  //   this.refresh.next();
  //   this.isPopup = false;
  //   this.snackbar.success('Intake Re-schedule successfully')
  //   this.spinnerservice.hide();

  // }
  // }
  getConflictsModality() {
    if ((moment(this.eventIntake.startTime).format("HH:mm") < this.currenttime && moment(this.eventIntake.startDate).format("MM/DD/YYYY") <= moment(this.date).format("MM/DD/YYYY")) || (this.durationhr == undefined || this.durationmin == undefined) || (Number(this.durationhr) == 0 && Number(this.durationmin) == 0)) {
      if (moment(this.eventIntake.startTime).format("HH:mm") < this.currenttime) {
        this.IspastTime = true;
      }
      if ((Number(this.durationhr) == 0 && Number(this.durationmin) == 0)) {
        this.IsDurationInvalid = true;
      }
      return
    }
    var offset = new Date().getTimezoneOffset();
    this.scheduleModalityList = [];
    this.dtoScheduleConflictModalitylist = []
    let starttime = moment(this.eventIntake.fromtime).format("HH:mm");
    let _obj = {
      StartDate: moment.utc(this.eventIntake.start).utcOffset(-offset).format("MM/DD/YYYY"),
      EndDate: moment.utc(this.eventIntake.start).utcOffset(-offset).format("MM/DD/YYYY"),
      FromTime: moment(this.eventIntake.fromtime).format("HH:mm"),
      ToTime: moment(starttime, 'HH:mm').add(((Number(this.durationhr) + Number(this.durationmin))), 'minute').format('HH:mm A'),
      Provider: this.eventIntake.provider
    }
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.treatmentservice.getConfilctsForModalityScheduler(dataToEncrypt).subscribe(
      (res: any) => {
        this.dtoScheduleConflictModalitylist = JSON.parse(res);
        this.spinnerservice.hide();
        if (this.eventIntake.length == 0) {
          this.scheduleModalityList = []
          this.scheduleModalityList.push({
            // day: data[i].day,
            scheduleDate: moment(this.eventIntake.startDate).format('MM/DD/YYYY'),
            isChecked: false,
            fromTime: moment(this.eventIntake.startTime).format("HH:mm"),
            toTime: moment(starttime, 'HH:mm').add(((Number(this.durationhr) + Number(this.durationmin))), 'minute').format('HH:mm A'),
            isConflict: false
          })
          // if(this.isBlockTime){
          //   this.postBlockTime();
          // }else{
          //   this.postSchduleModality();
          // }

        }
        else {
          this.dateList = [];
          //  this.dateList = this.getDaysBetweenDates(moment(this.dtoScheduleModality.startDate).utcOffset(-offset), moment(this.dtoScheduleModality.endDate).utcOffset(-offset));
          this.onBetweenappointment(this.dtoScheduleConflictModalitylist);
          // this.skipAllConflicts();
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  onBetweenappointment(data) {
    this.scheduleModalityList = [];
    let starttime = moment(this.eventIntake.fromtime).format("HH:mm");
    for (let i = 0; i < data.length; i++) {
      this.scheduleModalityList.push({
        // day: data[i].day,
        scheduleDate: moment(data[i].scheduleDate).format('MM/DD/YYYY'),
        isChecked: false,
        fromTime: moment(this.eventIntake.fromtime).format("HH:mm"),
        toTime: moment(starttime, 'HH:mm').add((Number(this.durationhr) + Number(this.durationmin)), 'minute').format('HH:mm A'),
        isConflict: this.getIsConflict(data[i].scheduleDate),
        // location: data[i].location,
        // locationName: this.getLocationName(data[i].location),
      });
    }
  }
  getIsConflict(conflictDate: any) {
    let isConflictlist = this.dtoScheduleConflictModalitylist.filter((item) => item.isConflict == true);
    for (let i = 0; i < isConflictlist.length; i++) {
      let firstDate = moment(isConflictlist[i].scheduleDate).format('MM/DD/YYYY');
      let secondDate = moment(conflictDate).format('MM/DD/YYYY');
      if ((firstDate == secondDate) && isConflictlist[i].isConflict == true) {

        return true;
      }
      else {
        return false;
      }
    }

  }
  // eventDay1Clicked({ event }: { event: CalendarEvent }): void {
  //   //this._DtoSchAppoinment= new ScheduleAppoinment();
  //   //this.events = [...this.events.filter(x => x.id == event.id && x.isnew == event.isnew && x.title == 'Available')];
  //   console.log(event)
  // if(event.isnew == true){
  //   this.events.forEach(function (x, index, object) {
  //     if ( x.status == constantVariables.lblMessage.Selected) {
  //          x.color = colors.green;
  //       x.status = constantVariables.lblMessage.Exist;
  //       x.title = 'Available';
  //      // this.slotoneusers.push(x.meta.user);
  //     }
  //   });
  //   this._DtoSchAppoinment.meetingTimes[0].meetinghost = []
  //   this._DtoSchAppoinment.meetingTimes[0].fromTime =   event.fromtime
  //   this._DtoSchAppoinment.meetingTimes[0].toTime =  event.totime
  //   if (event.meta.user.type == 1) {
  //     let isPrimaryhost = this._DtoSchAppoinment.meetingTimes[0].meetinghost.filter(x => x.staffId == event.meta.user.id).length;
  //     if (isPrimaryhost == 0) {
  //       let obj = {
  //         meetingHostId: 0,
  //         meetingScheduleId: 0,
  //         staffId: event.meta.user.id,
  //         isActive: true,
  //         status: 1,
  //         hostType: event.meta.user.type
  //       }
  //       this._DtoSchAppoinment.meetingTimes[0].meetinghost.push(obj);
  //       event.color = colors.orange;
  //       event.status = constantVariables.lblMessage.Selected;
  //       event.title = this.ScheduleName+ " - " + this.fullName;
  //       this.isReqStaff = false;
  //       this.slotoneusers.push(event.meta.user);
  //     }
  //   } else {
  //     let issupportHosts = this._DtoSchAppoinment.meetingTimes[0].meetinghost.filter(x => x.staffId == event.meta.user.id).length;
  //     if (issupportHosts == 0) {
  //       let obj = {
  //         meetingHostId: 0,
  //         meetingScheduleId: 0,
  //         staffId: event.meta.user.id,
  //         isActive: true,
  //         status: 1,
  //         hostType: event.meta.user.type
  //       }
  //       this._DtoSchAppoinment.meetingTimes[0].meetinghost.push(obj);
  //       event.color = colors.orange
  //       event.status = constantVariables.lblMessage.Selected;
  //       event.title = this.ScheduleName+ " - " + this.fullName;
  //       this.isReqStaff = false;
  //       this.slotoneusers.push(event.meta.user);
  //     }


  //   }
  //   this.refresh.next();
  // }
  // }
  hoursChange(){
    this.isHrsrequired = false;
    this.IsDurationInvalid = false;
  } 
  minChange(){
  this.isMinsrequird = false;
  this.IsDurationInvalid = false;
  }
}

<div class="login-page">
    <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
        <div class="login-section">
            <div class="container-fluid">
                <div class="row no-gutter">
                  <div class="d-none d-md-flex col-md-6 col-lg-9 bg-image"></div>
                  <div class="col-md-6 col-lg-3">
                    <div class="login d-flex align-items-top py-4">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-9 col-lg-12 mx-auto">
                            <div class="col-md-12 p-0 logo-img">
                                <img  class="img-fluid" src="../../assets/images/brand-logo.jpg" alt="">
                                </div>
                                <form fxLayout="column" (submit)="onSubmit()" class="col-md-12 col-mg-12 col-sm-12 col-xs-12 p-0" [formGroup]="changepassswordForm">
                                    <mat-card-content>
                                        
                                        <div class="input-row" >
                                            <mat-form-field fxFlex class="example-full-width w-100">
                                                <div class="custom-password">
                                                <input autocomplete="off" matInput id="password" placeholder="New Password" (paste)="global.trimValue($event)" formControlName="Password" [type]="newPassEye ? 'password' : 'text'"
                                                    class="form-control" tooltipEvent="focus" (focus)="showErrorPopup()"
                                                    (focusout)="hideErrorPopup()"
                                                    [ngClass]="changepassswordForm.controls['Password'].invalid ? 'is-invalid' : ''"
                                                    (mouseover)="changeStyle($event)" (mouseout)="changeStyle($event)" />
                                                    <i  [class]="newPassEye ? 'fa fa-fw fa-eye field-icon toggle-password' : 'fa fa-fw fa-eye-slash field-icon toggle-password'"  (click)="newPassEye=!newPassEye"></i></div>
                                            </mat-form-field>
                                            <div class="popup-design--replicate" [style.display]="showErrorPassword ? 'block' : 'none'">
                                                <div class="icon-align--newpop">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ff0000" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                        <path d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                                                      </svg>
                                                </div>
                                                <label class="col"
                                                    [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                                                    <i class="material-icons">{{ changepassswordForm.controls['Password'].hasError('required')
                                                        ||
                                                        changepassswordForm.controls['Password'].hasError('minlength') ? 'cancel' :
                                                        'check_circle' }}</i>
                                                    Must be at least 8 characters!
                                                </label>
                        
                                                <label class="col"
                                                    [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                                    <i class="material-icons">{{ changepassswordForm.controls['Password'].hasError('required')
                                                        ||
                                                        changepassswordForm.controls['Password'].hasError('hasNumber') ? 'cancel' :
                                                        'check_circle' }}</i>
                                                    Must contain at least 1 number!
                                                </label>
                        
                                                <label class="col"
                                                    [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                                    <i class="material-icons">{{ changepassswordForm.controls['Password'].hasError('required')
                                                        ||
                                                        changepassswordForm.controls['Password'].hasError('hasCapitalCase') ? 'cancel' :
                                                        'check_circle' }}</i>
                                                    Must contain at least 1 in Capital Case!
                                                </label>
                        
                                                <label class="col"
                                                    [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                                    <i class="material-icons">{{ changepassswordForm.controls['Password'].hasError('required')
                                                        ||
                                                        changepassswordForm.controls['Password'].hasError('hasSpecialCharacters') ? 'cancel' :
                                                        'check_circle' }}</i>
                                                    Must contain at least 1 Special Character!
                                                </label>
                                            </div>
                                        </div>
                                        <div class="input-row">
                                            <mat-form-field fxFlex class="example-full-width w-100">
                                                <div class="custom-password">
                                                <input autocomplete="off" matInput placeholder="ConfirmPassword" id="ConfirmPassword" (paste)="global.trimValue($event)" formControlName="ConfirmPassword"
                                                [type]="confirmPassEye ? 'password' : 'text'" class="form-control" />
                                                <i  [class]="confirmPassEye ? 'fa fa-fw fa-eye field-icon toggle-password' : 'fa fa-fw fa-eye-slash field-icon toggle-password'"  (click)="confirmPassEye=!confirmPassEye"></i></div>
                        
                                            </mat-form-field>
                                            <label class="text-danger invalid-feedback d-block"
                                                *ngIf="changepassswordForm.controls['ConfirmPassword'].hasError('required')">
                                                Password is Required!
                                            </label>
                                            <label class="text-danger invalid-feedback d-block"
                                                *ngIf="changepassswordForm.controls['ConfirmPassword'].hasError('NoPassswordMatch')">
                                                Password do not match
                                            </label>
                                        </div>
                                    </mat-card-content>
                                    <div class="col-md-12 text-center p-0">
                                    <button  mat-stroked-button color="accent"  
                                        class="mr-2 primary-btn-outlined mb-3 " type="button" (click)="oncancel()">
                                        Cancel
                                    </button>
                                    <button [disabled]="changepassswordForm.invalid" (click)="onSubmit()" mat-stroked-button color="accent"
                                        class="primary-btn-filled " type="submit">
                                        Change Password
                                    </button>
                                </div>
                                    
                                </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
        </div>
       
    </div>
    </div>
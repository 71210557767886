<span class="col-md-2">
  <a  [routerLink]="['/clinician']" class="float-right back_btn_link fs14">Back</a>
</span>
<div class="progress-notes-page">
  <div class="top-btn-sectionclearfix mb-1 w-100">
    <span class="ml-2 head-span fw700 mr-4"> {{groupTherapyName}} : Group Progress Note</span>
    <span class="list-item-date fw-500 mr-4"> Date : {{scheduleDate |
      date:'mediumDate'}}</span>
      <span class="list-item-date fw-500">Time : {{startTime | date:'shortTime'}} to
        {{endTime}}</span>
  
  </div>
  <div class="row w-100">
    <div class="col-md-12 px-4" >
      <div [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
      <button
      type="submit"
      class="btn primary-btn-outlined-small ng-star-inserted float-right mr-2"
      [disabled]="disableAllProgressField || (isClinician && disableAllProgressField)"
      (click)="submit()"
    >
      Save Report
    </button>
    <button
    type="submit"
    class="btn primary-btn-outlined-small ng-star-inserted float-right mr-2"
    (click)="generatePdf()"
  >
    Generate PDF
  </button>
  <button
  type="submit"
  class="btn primary-btn-outlined-small ng-star-inserted float-right mr-2"
  (click)="Savedraft()"
>
 Save as Draft
</button>
  <button
    type="submit"
    class="btn primary-btn-outlined-small ng-star-inserted float-right mr-2"  *ngIf = "(isClinician ||isGroupLeader) && disableAllProgressField && roleAccess.isUnlockProgress && !isNotPresenting"
    (click)="editData()"
  >
  Edit Progress Note
  </button>
    </div>
    <!-- <mat-tab-group [(selectedIndex)]="selectedTab" class="w-100"
      (selectedTabChange)="onTabChangeProgress($event.index)"> -->
      <mat-tab-group [(selectedIndex)]="selectedTab" class="w-100"
      (selectedTabChange)="onTabChange($event)">
        <mat-tab *ngFor="let item of patientList;let i = index;" label="{{item.userName}}" class="triangle-up px-5">
          <div *ngIf="editclinicalchellages != true" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
            <p>
              <label>Clinical Challenges:
                </label>
                <button class="col text-center col-btn-dis" role="button"
                
                (click)="Editclinicalchellages()"  title="Edit"><i class="far fa-edit"
                   aria-hidden="true"></i></button>
            </p>
              <!-- <app-word-counter-textarea [text]="progressActivityNoteDetails.clinicalChallenges" [maxlength]="3000"  [(ngModel)]="progressActivityNoteDetails.clinicalChallenges" 
              (textChange)="progressActivityNoteDetails.clinicalChallenges = $event" #clinicalChallenges="ngModel" name="clinicalChallenges" ngDefaultControl ></app-word-counter-textarea> -->
               
              <!-- <input type="text" [(ngModel)]="progressActivityNoteDetails.clinicalChallenges" id="fname" name="fname"> -->
              <div>
                <span
                > {{progressActivityNoteDetails.clinicalChallenges}}
              </span>
              </div>
           
          </div>
          <div *ngIf="editclinicalchellages == true" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
            <p>
              <label>Clinical Challenges:</label
              >
              <button class="col text-center col-btn-dis" role="button"
                
                (click)="Editclinicalchellages()"  title="Edit"><i class="far fa-edit"
                   aria-hidden="true"></i></button>
              <app-word-counter-textarea [text]="progressActivityNoteDetails.clinicalChallenges" [maxlength]="3000" (ngModelChange) = "ChangeEdit()" [(ngModel)]="progressActivityNoteDetails.clinicalChallenges" 
              (textChange)="progressActivityNoteDetails.clinicalChallenges = $event" #clinicalChallenges="ngModel" name="clinicalChallenges" ngDefaultControl ></app-word-counter-textarea>
               
              <!-- <input type="text" [(ngModel)]="progressActivityNoteDetails.clinicalChallenges" id="fname" name="fname">
              <span
                > {{progressActivityNoteDetails.clinicalChallenges}}
              </span> -->
            </p>
          </div>
          <!-- <div class="basic-info mb-3">
            <label class="treat-label">Clinical Challenges</label>
            <div class="col-md-12 p-0">
              
              <app-word-counter-textarea  [maxlength]="3000"  [(ngModel)]="progressActivityNoteDetails.clinicalChallenges" 
               #clinicalChallenges="ngModel" name="clinicalChallenges" ngDefaultControl ></app-word-counter-textarea>
              
              
             
            </div>
          </div> -->
          <div class="row" style="margin-left: 70%;">
            <h5>Can you mark the client should be absent?</h5>
            
            <span (click) = "onChangeStatusInsuranceInfo()" [ngClass]="{'disable_part':progressActivityNoteDetails.clinicalChallenges == null}">
              <i class="fas fa-user-check"  style="font-size: 24px;color: green;margin-left: 10px;" *ngIf="this.currentClient?.isAbsent"></i>
              <i class="fas fa-user-times"  style="font-size: 24px;color: red;margin-left: 10px;" *ngIf="!this.currentClient?.isAbsent"></i>
          </span>
          </div>
          
    
          <div class="notes-text" *ngIf="isNotPresenting" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
            <p>
              <label>Note:</label
              ><span
                > Please enter presenting problems for {{item.userName}}
              </span>
            </p>
          </div>
          <div [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
          <div class="timetop">
            <table class="notes-table">
              <tr class="tr-row">
                <th class="th-cols">Time</th>
                <th class="th-cols-width text-center" *ngFor="let item of progressActivityNoteDetails?.actionList;">{{item.startTime | date: 'h:mm a'}}-{{item.endTime | date: 'h:mm a'}}</th>
              </tr>



              <tr >
                <td class="bg-gray p-2">Activity</td>
                <td class="bg-gray p-2 text-center min-vw-20"  (click) = "onChangeStatusActivityInfo(item)"  *ngFor="let item of progressActivityNoteDetails?.actionList;">{{item.therapyName}}            
                  <i class="fas fa-user-check" style="font-size: 24px;color: green;margin-left: 10px;" *ngIf="item.presentorAbsentActivity"></i>
                  <i class="fas fa-user-times" style="font-size: 24px;color: red;margin-left: 10px;" *ngIf="!item.presentorAbsentActivity"></i>
                </td>
              </tr>



              <tr  *ngFor="let itemAction of progressActivityNoteDetails?.actionItems;let i = index"  >
                <td class="border-bottom-gray p-2">{{itemAction.goalName}}</td>
                <td class="border-bottom-gray p-2 text-center" *ngFor="let itemActivity of itemAction?.activeItems;let j = index">
                    <div class="custom-control custom-checkbox" [ngClass]="{'disable_part':!getTherapyStatus(itemActivity,j)}" >
                        <input type="checkbox"  [disabled]=" (disableProgressNote(item) || disableAllProgressField || (isClinician && disableAllProgressField))" class="custom-control-input" [checked]="itemActivity.isSelected" [attr.id]="itemActivity.therapyName + i+j"
                         [attr.name]="itemActivity.therapyName+ i+j" 
                        [ngModelOptions]="{standalone: true}" (click)="selectUnselectAction(itemActivity.isSelected,itemAction,j,i );ChangeEdit()" />
                        <label class="custom-control-label" [for]="itemActivity.therapyName + i+j">
                          </label>
                      </div>
                </td>
              </tr>
              <!-- <tr>
                <td class="border-bottom-gray p-2">
                  Be respectful to self and others
                </td>
                <td class="border-bottom-gray p-2 text-center" *ngFor="let item of progressActivityNoteDetails?.actionList;let j = index">
                  <div class="custom-control custom-checkbox">
                      <input type="checkbox" [disabled]="disableProgressNote(item) || disableAllProgressField || (isClinician && disableAllProgressField)" class="custom-control-input" [attr.id]="item.respectfullOthers.fieldName + j" [checked]="item.respectfullOthers.value" 
                      [attr.name]="item.respectfullOthers.fieldName+ j"
                      [ngModelOptions]="{standalone: true}" (click)="selectUnselectAction(item.respectfullOthers,item)" />
                      <label class="custom-control-label" [for]="item.respectfullOthers.fieldName + j">
                        
                        </label>
                    </div>
              </td>
              </tr>
              <tr>
                <td class="border-bottom-gray p-2">
                  Listening and following instructions
                </td>
                <td class="border-bottom-gray p-2 text-center" *ngFor="let item of progressActivityNoteDetails?.actionList;let j = index">
                  <div class="custom-control custom-checkbox">
                      <input type="checkbox" [disabled]="disableProgressNote(item) || disableAllProgressField || (isClinician && disableAllProgressField)"  class="custom-control-input" [attr.id]="item.listeningInstructions.fieldName+ j" [checked]="item.listeningInstructions.value" 
                      [attr.name]="item.listeningInstructions.fieldName+ j"
                      [ngModelOptions]="{standalone: true}" (click)="selectUnselectAction(item.listeningInstructions,item)" />
                      <label class="custom-control-label" [for]="item.listeningInstructions.fieldName+ j">
                        </label>
                    </div>
              </td>
              </tr>
              <tr>
                <td class="border-bottom-gray p-2">Accept no answer</td>
                <td class="border-bottom-gray p-2 text-center" *ngFor="let item of progressActivityNoteDetails?.actionList;let j = index">
                  <div class="custom-control custom-checkbox">
                      <input type="checkbox" [disabled]="disableProgressNote(item) || disableAllProgressField || (isClinician && disableAllProgressField)" class="custom-control-input" [attr.id]="item.acceptAnswer.fieldName+ j" [checked]="item.acceptAnswer.value" 
                      [attr.name]="item.acceptAnswer.fieldName+ j"
                      [ngModelOptions]="{standalone: true}" (click)="selectUnselectAction(item.acceptAnswer,item)" />
                      <label class="custom-control-label" [for]="item.acceptAnswer.fieldName+ j">
                        </label>
                    </div>
              </td>
              </tr>
              <tr>
                <td class="border-bottom-gray p-2">
                  Identify and replace ineffective behaviour
                </td>
                <td class="border-bottom-gray p-2 text-center" *ngFor="let item of progressActivityNoteDetails?.actionList;let j = index">
                  <div class="custom-control custom-checkbox">
                      <input type="checkbox" [disabled]="disableProgressNote(item) || disableAllProgressField || (isClinician && disableAllProgressField)" class="custom-control-input" [attr.id]="item.ineffectiveBehaviour.fieldName+ j" [checked]="item.ineffectiveBehaviour.value" 
                      [attr.name]="item.ineffectiveBehaviour.fieldName+ j"
                      [ngModelOptions]="{standalone: true}" (click)="selectUnselectAction(item.ineffectiveBehaviour,item)" />
                      <label class="custom-control-label" [for]="item.ineffectiveBehaviour.fieldName+ j">
                        </label>
                    </div>
              </td>
              </tr>
              <tr>
                <td class="border-bottom-gray p-2">
                  Use appropriate words, tone and topics
                </td>
                <td class="border-bottom-gray p-2 text-center" *ngFor="let item of progressActivityNoteDetails?.actionList;let j = index">
                  <div class="custom-control custom-checkbox">
                      <input type="checkbox" [disabled]="disableProgressNote(item) || disableAllProgressField || (isClinician && disableAllProgressField)" class="custom-control-input" [attr.id]="item.appropriateWords.fieldName+ j" [checked]="item.appropriateWords.value" 
                      [attr.name]="item.appropriateWords.fieldName+ j"
                      [ngModelOptions]="{standalone: true}" (click)="selectUnselectAction(item.appropriateWords,item)" />
                      <label class="custom-control-label" [for]="item.appropriateWords.fieldName+ j">
                        </label>
                    </div>
              </td>
              </tr>
              <tr>
                <td class="border-bottom-gray p-2">
                  Stay on task/ focus on task
                </td>
                <td class="border-bottom-gray p-2 text-center" *ngFor="let item of progressActivityNoteDetails?.actionList;let j = index">
                  <div class="custom-control custom-checkbox">
                      <input type="checkbox" [disabled]="disableProgressNote(item) || disableAllProgressField || (isClinician && disableAllProgressField)" class="custom-control-input" [attr.id]="item.stayOnTask.fieldName+ j" [checked]="item.stayOnTask.value" 
                      [attr.name]="item.stayOnTask.fieldName+ j"
                      [ngModelOptions]="{standalone: true}" (click)="selectUnselectAction(item.stayOnTask,item)" />
                      <label class="custom-control-label" [for]="item.stayOnTask.fieldName+ j">
                        </label>
                    </div>
              </td>
              </tr> -->
              <!-- <tr>
                <td class="border-bottom-gray p-2" >Total</td>
                <td class="border-bottom-gray p-2 text-center"  *ngFor="let item of progressActivityNoteDetails?.actionCount;">{{item.activityCount}}/{{progressActivityNoteDetails?.actionItems.length}}</td>

              </tr> -->
              <tr>
                <td class="border-bottom-gray p-2">Total</td>
                <td
                  class="border-bottom-gray p-2 text-center"
                  *ngFor="let action of progressActivityNoteDetails?.actionCount"
                >
                  {{ action.presentorAbsentActivity ? action.activityCount : 0 }}/
                  {{ action.presentorAbsentActivity ? progressActivityNoteDetails?.actionItems.length : 0 }}
                </td>
              </tr>
              <tr>
                <td class="border-bottom-gray p-2">Staff Initials</td>
                <td class="border-bottom-gray p-2 text-center" *ngFor="let item of progressActivityNoteDetails?.actionList;let i = index;">
                  <img id='esign{{i}}' height="50px" width="300px" *ngIf="item.staffInitials" src="{{item.staffInitials}}">
                  <div class="form-group clearfix m-0">
                    <div class="float-left col-md-4 p-0 mt-3 pt-1">
                      <!-- <input [disabled]="true" type="checkbox" id="sign{{i}}" name="sign{{i}}" [checked]="item.staffInitials" > -->
                     
                      <!-- <mat-error  *ngIf="isSubmitted && !item.staffInitials">  
                        <mat-error class="text-xs" *ngIf="item.staffInitials == undefined">
                          Inter/Staff signtaure is required</mat-error>
                      </mat-error> -->
                    </div>
                    <div class="float-left col-md-4 p-0 mt-3 pt-1 ">
                      <button type="button" (click)="staffSign(1,item);ChangeEdit()" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" type="button">
                      Add E-Sign
                    </button>
                      
                    </div>
                   
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        </mat-tab>
        <!-- <mat-tab label="Client Name 2" class="triangle-up px-5"> </mat-tab>
        <mat-tab label="Client Name 3" class="triangle-up px-5"> </mat-tab> -->
      </mat-tab-group>
    </div>
  </div>
  <div class="w-100 group-notes px-4" *ngIf="isHomeInstruction == false" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
  <div class="goal-page-indiv">
  <div class="panel-group" id="accordion">
    <div *ngFor="let item of groupTherapyGoals; let i=index">
    <div class="panel panel-default active" >
     
      <div class="panel-heading" >
        <h4 class="panel-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapse{{i}}" class="collapsed">
            <i class="fa fa-caret-right mr-2"></i>{{ item.goal }}

          </a>
        
        </h4>
      </div>
      <div id="collapse{{i}}" class="panel-collapse collapse in" >
        <div class="panel-body">
         <div class="col-md-12 ben-head">
           <div class="row">
             
             <div class="col-md-9 ben_des">
              <div class=" ben_tit">
               {{ item.benchmarkTitle }}
              </div>
             {{item.benchmarkDescription}}
            </div>
            <div class="col-md-2 ben_unit">
              <p class="text-muted text-xs">Unit of Measure</p>
              {{item.benchmarkunit}}
            </div>
            <div class="col-md-1 goal-stat-chang float-right" >
              <select class="form-control" (ngModelChange) = "ChangeEdit()" [(ngModel)]="item.status"
              #status="ngModel" name="status" (change)="OnstatusChange(item)" [disabled]="disableAllProgressField || (isClinician && disableAllProgressField)">
                <option [value]=2  >Inprogress</option>
                <option [value]=3 > Completed</option>
              </select>
             </div>
           </div>
         </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>
  </div>
  <!-- <div class="border-bottom-gray w-100 px-4" *ngIf="groupTherapyGoals?.length!=0">
    <div class="div" *ngFor="let item of groupTherapyGoals">
      <h3 class="heading mt-2">{{item.goalName}}</h3>
      <div>
        <div class="p-1 d-flex">
          <p class="ml-2">{{item.definition}}</p>
        </div>
        <ul>
          <li>
            <h6 class="ml-2">No. of Benchmarks : <span>{{item.measures?.length}}</span></h6>
          </li>
        </ul>
      </div>
    </div>
  </div> -->

  <div class="w-100 group-notes px-4" *ngIf="isHomeInstruction == false" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
    <h1 class="heading py-2">Comments</h1>
    <textarea class="border-1 progressnote-activity-commnet comments-border" rows="5" cols="50" [disabled]="disableAllProgressField" (ngModelChange) = "ChangeEdit()"  [(ngModel)]="progressActivityNoteDetails.activityComments" placeholder="Enter your comments here..."></textarea>
  </div>

  <div class="w-100 group-notes px-4" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
    <h1 class="heading py-2" *ngIf="isHomeInstruction == false">Group Notes</h1>
    <h1 class="heading py-2" *ngIf="isHomeInstruction == true">Comments/Responses to Goals/Interventions</h1>
    <table class="table">

    </table>
    <ul class="list-group list-group-horizontal ">
      <li [class]="isHomeInstruction ? 'list-group-item listitem1' : 'list-group-item listitem1' ">
        <h2 class="heading">Group Intervention</h2>
       
       </li>
      <li [class]="isHomeInstruction ? 'list-group-item listitem1' : 'list-group-item listitem2'">
        <span>
          <h2 class="heading">Group Leader</h2>
         
        </span>
      
      </li>
      <li class="list-group-item listitem3" *ngIf="isHomeInstruction == false">
        <h2 class="heading">
          Skills Taught/Clinical Topics Discussed and Client's Response to Group
        </h2>
      
      </li>
      <li [class]="isHomeInstruction ? 'list-group-item listitem5' : 'list-group-item listitem4' ">
        <h2 class="heading">Comments</h2>
        
      </li>
    </ul>
    <ul class="list-group list-group-horizontal border-bottom-gray" *ngFor="let item of progressActivityNoteDetails?.groupTemplate;let i = index">
      <li [class]="isHomeInstruction ? 'list-group-item listitem1' : 'list-group-item listitem1' ">
     
    <label>{{item.therapyName}}<span class="color-red">*</span></label><br>
    <label>({{item.dSlength}})<span ></span></label>
      </li>
      <li [class]="isHomeInstruction ? 'list-group-item listitem1' : 'list-group-item listitem2'">
      <label>{{item?.groupLeaderName}}</label>
    </li>
    <li class="list-group-item listitem3" *ngIf="isHomeInstruction == false">
      <div>
        <span style="white-space: pre-line;">
          {{item?.skillTaught}}
        </span>

      </div>
    </li>
    <li [class]="isHomeInstruction ? 'list-group-item listitem5' : 'list-group-item listitem4' " >
      <textarea class="w-100 border-1 comments-border" rows="5" cols="50" [disabled]="disableAllProgressField || (!this.isClinician && !this.isGroupLeader)|| (isClinician && disableAllProgressField)" (ngModelChange) = "ChangeEdit()"  [(ngModel)]="item.comments" placeholder="Enter your comments here..."></textarea>
      <div class="">
        <mat-error *ngIf="isSubmitted && !item.comments && (isClinician || isGroupLeader)">
          <mat-error class="mat-form-field-subscript-wrapper mt-3 position-static">{{item.therapyName}} Comment Required</mat-error>
        </mat-error>
      </div>
    </li>
    </ul>

  </div>
  <div class="border-bottom-gray w-100 px-4" *ngIf="isHomeInstruction == false" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
    <h2 class="heading mt-2">Follow-Up Plan<span class="color-red">*</span></h2>
    <textarea class="w-100" rows="5"  cols="20" [disabled]="disableAllProgressField || (!this.isClinician && !this.isGroupLeader) || (isClinician && disableAllProgressField)" (ngModelChange) = "ChangeEdit()"  [(ngModel)]="progressActivityNoteDetails.followUpPlan" placeholder="Enter your comments here..."></textarea>
    <mat-error *ngIf="isSubmitted && !progressActivityNoteDetails.followUpPlan && (isClinician || isGroupLeader)">
      <mat-error class="mat-form-field-subscript-wrapper mt-3 position-relative">Follow-Up Plan comment Required</mat-error>
    </mat-error>
  </div>
  <div class="border-bottom-gray w-100 px-4" *ngIf="isHomeInstruction == false" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
    <h2 class="heading mt-2">Observed behavior and clinical response<span class="color-red">*</span></h2>
    <textarea class="w-100" rows="5"  cols="20" [disabled]="disableAllProgressField || (!this.isClinician && !this.isGroupLeader)|| (isClinician && disableAllProgressField)" (ngModelChange) = "ChangeEdit()"  [(ngModel)]="progressActivityNoteDetails.observedbehaviour" placeholder="Enter your comments here..."></textarea>
    <!-- <mat-error *ngIf="isSubmitted && !progressActivityNoteDetails.observedbehaviour && (isClinician || isGroupLeader)"> -->
      <!-- <mat-error class="mat-form-field-subscript-wrapper mt-3 position-relative">Observed behaviour and clinical response comment required</mat-error>
    </mat-error> -->
  </div>
  <!-- <div class="border-bottom-gray w-100 px-4">
    <mat-form-field class="w-100">
      <mat-label>Staff signature and Name<span class="color-red">*</span></mat-label>
      <input matInput />
    </mat-form-field>
  </div> -->
  <div class="col-md-12" *ngFor="let sign of progressActivityNoteDetails?.progressSign;let z = index"[ngClass]="{'disable_part':!this.currentClient?.isAbsent}" >
    <div class="form-group clearfix m-0">
    <div class="float-left col-md-4 p-0 mt-3 pt-1">
      <label class="treat-label">{{sign.clinicianName}}</label>
      <img id='esign1' height="50px" width="300px" *ngIf="sign.sign" src="{{sign.sign}}">
      
    </div>
   


   
  </div>
  </div>
  <div class="col-md-12" *ngIf="isGroupLeader" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
    <div class="form-group clearfix m-0">
    <div class="float-left col-md-4 p-0 mt-3 pt-1">
      <label class="treat-label">Provider's Signature</label>
      <img id='esign' height="50px" width="300px"   style="border: 1px solid gray;" *ngIf="progressActivityNoteDetails.clinicianSign" src="{{progressActivityNoteDetails.clinicianSign}}">

      <!-- <img id='esign' height="50px" width="300px"  style="border: 1px solid gray;"> -->
      <!-- <img id='esign' height="50px" width="300px"  src="{{progressActivityNoteDetails.clinicianSign}}"> -->
      <!-- <mat-error  *ngIf="isSubmitted && !progressActivityNoteDetails.clinicianSign && isLicensed">   -->
        <!-- <mat-error class="text-xs" *ngIf="progressActivityNoteDetails.clinicianSign == undefined">
          Signtaure is required</mat-error> -->
      <!-- </mat-error> -->
    </div>
    <div class="float-left col-md-4 p-0 mt-3 pt-1 ">
      <!-- <button type="button" (click)="openDialog(1)" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" *ngIf="(roleAccess.isSign && isLicensed) || (!_dtoPatientInfo.isLicensed && roleAccess.isUnlicensed) || (_dtoPatientInfo.IsIntern && roleAccess.isInternSign )"
        type="button">
        Add E-Sign
      </button> -->
      <button type="button" (click)="openDialog(1);ChangeEdit()" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" type="button">
      Add E-Sign
    </button>
      
    </div>
   
  </div>
  
  </div>
  <div class="col-md-12">
    <div class="form-group clearfix m-0">
    <div class="float-left col-md-4 p-0 mt-3 pt-1">
      <label class="treat-label">Secondary Signature</label>
      <img id='esign' height="50px" width="300px"   style="border: 1px solid gray;" *ngIf="progressActivityNoteDetails.SecondaryclinicianSign" src="{{progressActivityNoteDetails.SecondaryclinicianSign}}">

      <!-- <img id='esignsecond' height="50px" width="300px"  *ngIf="progressActivityNoteDetails.SecondaryclinicianSign"> -->
      <!-- <mat-error  *ngIf="isSubmitted && !progressActivityNoteDetails.SecondaryclinicianSign && isLicensed">  
        <mat-error class="text-xs" *ngIf="progressActivityNoteDetails.SecondaryclinicianSign == undefined">
          Signtaure is required</mat-error>
      </mat-error> -->
    </div>
    <div class="float-left col-md-4 p-0 mt-3 pt-1 ">
      <!-- <button type="button" (click)="openDialog(1)" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" *ngIf="(roleAccess.isSign && isLicensed) || (!_dtoPatientInfo.isLicensed && roleAccess.isUnlicensed) || (_dtoPatientInfo.IsIntern && roleAccess.isInternSign )"
        type="button">
        Add E-Sign
      </button> -->
      <button type="button" [disabled]="secondarySign()" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialogSecondary(2)" >
      Add E-Sign
    </button>
      
    </div>
   
  </div>
  
  </div>
  <div class="w-100 text-center py-3" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
    <button
      type="submit"
      [disabled]="disableAllProgressField || (isClinician && disableAllProgressField)"
      class="btn primary-btn-outlined-small ng-star-inserted float-right mr-2"
    (click)="submit()">
      Save Report
    </button>
  </div>
</div>


<div class="comment-popup benchmark-popup-rating" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
  <div class="modal" tabindex="-1" role="dialog"  [style.display]="PopUp? 'block':'none'" >
    <div class="modal-dialog mat-dialog-container p-3" role="document">
      <div class="modal-content border-0">
        <div class="modal-body">
<div >
  <p  class="text-center font-weight-500">Do you want to update with next benchmark?</p>
 <!-- <p class="disabled"> <i class="fas fa-check-circle text-success mr-2"></i> {{PopUpdata.benchmarkTitle}}</p>-->
  <mat-radio-group aria-label="Select an option" (ngModelChange) = "ChangeEdit()"  [(ngModel)]="SelectedBenchmark" >
    <mat-radio-button class="w-100" [value]=1>{{PopUpdata.benchmarkTitle}}  </mat-radio-button>

    <mat-radio-button *ngIf="PopUpdata.nextBenchmark!=''" class="w-100" [value]=2>{{PopUpdata.nextBenchmark}}  </mat-radio-button>
  </mat-radio-group>
</div>

<div class="text-center"  *ngIf=" (roleAccess.isCreate||roleAccess.isEdit)">
  <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" (click)="OnBenchmarkselect()">Save</button>
  <button type="button" class="btn btn-cancel" (click)="Onclosepopup(PopUpdata)"
    data-dismiss="modal"  >Close</button>
</div>
</div>
</div>
</div>
</div>
</div>
<!-- <div class="comment-popup  cancel-tour"> -->

  <div *ngIf="isSave" [ngClass]="{'disable_part':!this.currentClient?.isAbsent}">
  <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
    <div class="modal-dialog mat-dialog-container popupdcln" role="document">
      <div class="modal-content border-0">
        <div class="modal-body">
          <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="PopupClose()">
            <span aria-hidden="true">&times;</span>
          </button>
<div >
  
  <div class="col-md-12">
    <div class="row mb-2">
    </div>
   
    <div class="row mb-2" style="margin-left:1px">
      <div class="col-md-4 pr-0 pl-0">
        <label class="control-label-new " >Do you want to save details in draft<span class="errorpopup">*</span></label>
      </div>

      
    
    </div> 
 
      <div class="col-md-2"  >
        <button mat-raised-button   
        class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40 smtbtn"  type="submit" 
        (click) = "YesOptionSelected()"  >Yes</button>
        <button mat-raised-button   
        class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40 smtbtn"  type="submit" 
        (click) = "NoOptionSelected()"  >No</button>
      
      </div>
   
  </div>
</div>
</div>
</div>
</div>
</div>
</div>
 <!-- </div> -->

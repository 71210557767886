import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(private router: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('token') != null){
      // let jwtData = localStorage.getItem('token').split('.')[1]
      // let decodedJwtJsonData = window.atob(jwtData)
      // let decodedJwtData = JSON.parse(decodedJwtJsonData)
      // let utype = decodedJwtData.UserTypeId;
      // let firstlogin = decodedJwtData.IsFirstLogin;
      // if(utype==="3"){ //vendor login
      //   if(firstlogin==="True"){
      //     this.router.navigateByUrl('/changepassword');
      //   }else{
      //     this.router.navigateByUrl('/vendorprofile/organisation');
      //   }
      // }else if(utype==="2"){ //mrf user login
      //   this.router.navigateByUrl('/managevendor');
      // }
      return true;
    }
    else {
      this.router.navigate(['/user/login']);
      return false;
    }

  }
}

import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { PatientService } from 'src/app/patient/patient.service';
import { chmadetails } from 'src/app/_models/_Patient/_CHMADetails';
import { IntakeCmhaComponent } from 'src/app/patient/intake/intake-cmha/intake-cmha.component';
import { chmaEditPatientDetails } from 'src/app/_models/_Patient/_ChmaEditPatientDetails';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
@Component({
  selector: 'app-cmha-strengthweekness',
  templateUrl: './cmha-strengthweekness.component.html',
  styleUrls: ['./cmha-strengthweekness.component.scss']
})
export class CmhaStrengthweeknessComponent implements OnInit {
  // @ViewChild(IntakeCmhaComponent, { static: false }) private chma;
  isMovedChma: boolean;
  queYesNo = constantVariables.questionType.YesNo;
  queMCSA = constantVariables.questionType.MCSA;
  queMCMA = constantVariables.questionType.MCMA;
  queDescriptive = constantVariables.questionType.Descriptive;
  queRatings = constantVariables.questionType.Ratings;
  survey = constantVariables.inTakeForms.Survey;
  patientInfoId: any;
  patientAccountId: any;
  lstClientSW: any;
  lstPatientHistory: any;
  isShowMedication: any;
  _dtoMedications: any = [];
  isAssigned = false;
  patientDetails= new chmaEditPatientDetails();
  roleAccess: RoelAccess;
  constructor(public global: Global, public patientservice: PatientService, private chma: IntakeCmhaComponent,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.PrepareCHMA;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    //this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        if (params.patientid) {
          this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patientid).replace(/"/g, ""));
        }
        this.isMovedChma = Number(this.commonService.FrontEndDecryption(params.isChma).replace(/"/g, "")) == 0 ? false : true;
      }
      this.getClientSWQuestion();
      this.getPatientHistory();

    });
  }
  getClientSWQuestion() {
    //this.spinnerservice.show();
    this.patientservice.getInTakeQuestions(10, this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.lstClientSW = cloneDeep(res);
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getPatientHistory() {
    //this.spinnerservice.show();
    this.patientservice.getInTakeQuestions(11, this.patientAccountId).subscribe(
      (res: any) => {
        this.getPsychotropicMedication();
        this.spinnerservice.hide();
        this.lstPatientHistory = cloneDeep(res);
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getPsychotropicMedication() {
    this.patientservice.getPsychotropicMedication(this.patientInfoId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res && res.length > 0) {
          this._dtoMedications = cloneDeep(res);
        }
        //  else {
        //   this.onAddMedication();
        // }
        if (res != null) {
          this.isShowMedication = true;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  rbAnswer(index, opt) {
    this.lstClientSW.answers.details[index].options.forEach((val: any, key: any) => {
      this.lstClientSW.answers.details[index].options[key].isAnswer = false;
      this.isShowMedication = false;
    });
    this.lstClientSW.answers.details[index].options[opt].isAnswer = true;
    if (this.lstClientSW.answers.details[index].question == "Currently taking psychotropic medications") {
      if (this.lstClientSW.answers.details[index].options[0].isAnswer == true) {
        this.isShowMedication = true;
        this.onAddMedication();
      }
    }

  }
  onAddMedication() {
    if (this._dtoMedications == null || this._dtoMedications.length == 0) {
      this._dtoMedications = [];
      for (let i = 0; i < 2; i++) {
        this._dtoMedications.push({
          psychotropicMedicationId: 0,
          patientInfoId: Number(this.patientInfoId),
          medications: null,
          dosage: null,
          frequency: null,
          sideEffects: null
        })
      }
    }
  }
  addMedicationInfo() {
    this._dtoMedications.push({
      psychotropicMedicationId: 0,
      patientInfoId: Number(this.patientInfoId),
      medications: null,
      dosage: null,
      frequency: null,
      sideEffects: null
    })
  }
  cbkAnswer(index, opt, event) {
    this.lstClientSW.answers.details[index].options[opt].isAnswer = !this.lstClientSW.answers.details[index].options[opt].isAnswer;

  }
  cbkPatientAnswer(index, opt, event) {
    this.lstPatientHistory.answers.details[index].options[opt].isAnswer = !this.lstPatientHistory.answers.details[index].options[opt].isAnswer;

  }
  removeclinicalinformation(data, index) {
    if (data.psychotropicMedicationId != 0) {
      this.removeexistMedinfo(data);
    }
    this._dtoMedications.splice(index, 1);
  }
  removeexistMedinfo(data) {
    this.patientservice.removePsychotropicMed(data.psychotropicMedicationId).subscribe((res: any) => {
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  onSaveClientSWQuestion(type) {
   // if (!this.isMovedChma) {
      this.spinnerservice.show();
      this.patientservice.postIntakeNutritional(this.lstClientSW).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.onSavePsychotropic(type);
          this.onSaveChmaStatus(type);
          this.getClientSWQuestion();
          //this.snackbar.success(JSON.parse(res).message);


        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
  //  } else {
  //    this.chma.onTabChange(5);
  //  }

  }
  onSaveFamilyHistoryQuestion() {

    this.spinnerservice.show();
    this.patientservice.postIntakeNutritional(this.lstPatientHistory).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.getPatientHistory();

        //this.snackbar.success(JSON.parse(res).message);


      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  onSavePsychotropic(type) {

    //this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoMedications, false);
    this.patientservice.postPsychotropic(this._dtoMedications).subscribe(
      (res: any) => {
        this.onSaveFamilyHistoryQuestion();
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.getPsychotropicMedication();
        if (type == 1) {
          window.close()
        }
        else if(type != 2)  {
          this.chma.onTabChange(5);
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  onSaveChmaStatus(type : any) {
    let _obj = {
      PatientAccountId: this.patientAccountId,
      FormType: 4,
      patientdetails:this.patientDetails,
      Type :type
    }
    // const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.patientservice.postChmaStatus(_obj).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.chma.getChmaStatus();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  onPrevcmhatab() {
    this.chma.onTabChange(3);
  }
}

import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { LeadService } from '../../lead.service';
import { MatTableDataSource } from '@angular/material/table';
import {InsuranceAuthorization } from '../../../_models/_Lead/InsAuthorizationDtl'
import { CommonService } from 'src/app/common/common.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { SpinnerService } from 'src/app/services/spinnerservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NgForm } from '@angular/forms';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { DtoClinicalInformation, crba } from 'src/app/_models/_Lead/crba';
import { cloneDeep } from "lodash";
import { ActivatedRoute } from '@angular/router';
import { log } from 'console';

@Component({
  selector: 'app-insurance-authorization',
  templateUrl: './insurance-authorization.component.html',
  styleUrls: ['./insurance-authorization.component.scss']
})
export class InsuranceAuthorizationComponent implements OnInit {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('insAuthorization') insAuthorization: NgForm; 
  insuraceColumns = ['InsuranceName', 'AuthorizationNumber', 'StartDate', 'EndDate', 'NumberOfVisits','TotalAppointments','ClinicalInformationProvider','actions'];
  AuthorizationTypeddl:any=[];
  patientInfoId:any;
  ddlInsuranceList:any=[];
  insAuthorizationDtl:InsuranceAuthorization;
  IsPcp:boolean=false;
  IsIop:boolean=false;
  insAuthDataSource: MatTableDataSource<any>;
  tmpInsAuthDataSource=[];
  isEditInsAuth: boolean= false;
  IsnoInsurance: boolean = false;
  drpAuthorizationTrack=[];
  roleId;
  roleAccess: RoelAccess;
  isExpiryDate:boolean=false;
  isNoOfVisits:boolean=false;
  AuthorizationNotificationType=[]
  AllInsuranceAuthList: any;
  InsuranceId: number;
  clinicalInfo: DtoClinicalInformation[] = [];
  patientinfoid: any;
  crbainfo: crba;
  prgdays: any = [];
  pageFrom:number;
  authorizeClinicalInfo :any;
  insuranceDetailsId : any;
  HistoryId:Number;
  constructor(public leadservice:LeadService,public commonService: CommonService,private snackbar: SnackBarService,private spinservice:NgxSpinnerService,
    private dialog: MatDialog, public roleaccessservice:PageAccessService,  private route: ActivatedRoute,
    public spinnerservice: NgxSpinnerService
    ) { }

  ngOnInit(): void {
    this.crbainfo = new crba();
    const pageid = constantVariables.pagelists.Profile;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.insAuthorizationDtl = new InsuranceAuthorization();
    if(Number(localStorage.getItem("HistoryId"))>0)
      this.HistoryId=Number(localStorage.getItem("HistoryId"))
    else
    this.HistoryId=0;
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.InsuranceId=Number(localStorage.getItem("InsuranceId"));
    if(localStorage.getItem("ISInsurance")=="yes") 
    {this.IsnoInsurance = true;}
    this.GetAuthorizationTypeMaster()
    this.getPatientInsuranceDetailList()
    this.getAllInsAuthDetails()
    this.getAuthorizationDetailTrack();
    this.GetAuthorizationNotificationType();

    
    this.roleId = Number(localStorage.getItem("roleId"));
    if (this.clinicalInfo && this.clinicalInfo.length == 0) {
      this.clinicalInfo.push({
        clinicalInformationId: 0,
        authorizationsDetailsId: 0,
        information: "",
        isActive: true
      });
    }
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        this.patientinfoid = this.commonService.FrontEndDecryption(params.id);
        this.insuranceDetailsId = this.commonService.FrontEndDecryption(params.InsurancetypeId);
        if(params.page != undefined)
        {
          this.pageFrom = JSON.parse(this.commonService.FrontEndDecryption(params.page));
        }
        localStorage.setItem('patientId', this.patientinfoid);
        this.getCrbaview()
      }
    });
 
  }

  
GetAuthorizationNotificationType()
{
  this.leadservice.getAuthorizationNotificationTypeMaster().subscribe((res:any)=>{
    this.AuthorizationNotificationType = res;
     // console.log(res)
    })
}
GetAuthorizationTypeMaster(){
this.leadservice.getAuthorizationTypeMaster().subscribe((res:any)=>{
this.AuthorizationTypeddl = res;
 // console.log(res)
})
}

getPatientInsuranceDetailList(){
  this.leadservice.getInsuranceDetailListByPatient(this.patientInfoId).subscribe(
    (res:any)=>{
    this.ddlInsuranceList = res;
   if (this.ddlInsuranceList.length == 0){
    this.IsnoInsurance = true ;
   }
// if(this.ddlInsuranceList==[] || this.ddlInsuranceList.length == 0 ){
//   this.IsnoInsurance=true
// }

  })
}
PostInsAuthorization(){
  // console.log(this.insAuthorizationDtl);
  let IsSameAuthNum = false;
  if(this.AllInsuranceAuthList != undefined)
  {
for(var item of this.AllInsuranceAuthList){
  if(item.AuthorizationNumber == this.insAuthorizationDtl.AuthorizationNumber && item.InsuranceDetailsId== this.insAuthorizationDtl.InsuranceDetailsId){
    IsSameAuthNum = true;
  }
}
  }
if(IsSameAuthNum==true){
  this.snackbar.error("Authorization Number Already added.");
  return;
}
else{
if(this.insAuthorizationDtl.InsuranceDetailsId != undefined || this.insAuthorizationDtl.AuthorizationNumber || this.insAuthorizationDtl.AuthorizationTypeId
  || this.insAuthorizationDtl.CptHcpcs || this.insAuthorizationDtl.StartDate || this.insAuthorizationDtl.EndDate
  || this.insAuthorizationDtl.NumberOfVisits || this.insAuthorizationDtl.AuthorizationTrackId ||this.insAuthorizationDtl.ClinicalInformationProvider)
  {
    this.spinservice.show()
    this.insAuthorizationDtl.PatientInfoId= this.patientInfoId;
    this.insAuthorizationDtl.AuthorizationTypeId = this.insAuthorizationDtl.AuthorizationTypeId;//.toString();
    // this.insAuthorizationDtl.IsIop = this.IsIop;
    // this.insAuthorizationDtl.IsPcp = this.IsPcp;
    this.insAuthorizationDtl.DaysPerWeek = +this.insAuthorizationDtl.DaysPerWeek
    this.insAuthorizationDtl.NumberOfVisits = +this.insAuthorizationDtl.NumberOfVisits
    this.authorizeClinicalInfo = +this.insAuthorizationDtl.ClinicalInformationProvider
    //console.log(this.insAuthorizationDtl)
    //const dataToEncrypt = this.commonService.DataToEncrypt(this.insAuthorizationDtl, true);
    this.leadservice.postInsAuthorizationDtl(this.insAuthorizationDtl).subscribe(res=>{
      if(res!=""){
        this.spinservice.hide()
        this.getAllInsAuthDetails();
        this.IsIop=false;
        this.IsPcp = false;
        this.insAuthorizationDtl.DaysPerWeek=0
        this.isEditInsAuth = false
        this.insAuthorizationDtl = new InsuranceAuthorization();
        this.insAuthorization.resetForm()
        this.snackbar.success(res)
      }
    },
    err => {
      this.spinservice.hide();
    })
  }

}
}

// OnPcpIopClick(item:any){
//   if(item==1){
// this.IsPcp = true;
// this.IsIop = false;
//   }
//   else{
//     this.IsPcp = false;
//     this.IsIop = true;
//   }
// }

// getAllInsAuthDetails(){
//   this.IsIop=false;
//   this.IsPcp = false;
//   this.spinservice.show()
//   this.leadservice.getInsuranceAuthorizationDtl(this.patientInfoId).subscribe((res:any)=>{
//     this.spinservice.hide();
//      if(res!=null){


//     this.insAuthDataSource = new MatTableDataSource(res);
//     this.insAuthDataSource.paginator = this.paginator;
//         this.insAuthDataSource.sort = this.sort;
//     }
//   })
// }

getAllInsAuthDetails(){
  this.IsIop=false;
  this.IsPcp = false;
  this.tmpInsAuthDataSource=[];
  
  this.spinservice.show()
  this.leadservice.getInsuranceAuthorizationDtlNewCrba(this.patientInfoId,this.InsuranceId,this.HistoryId).subscribe(
    data => {
     this.spinservice.hide();
     if(data.objInputString!=null)
      {
      let res =this.commonService.decrypdata(data.objInputString);
      console.log(res);
      this.AllInsuranceAuthList = res;
       if (this.insuranceDetailsId){
      //   var result = res.filter(x=>x.InsuranceDetailsId == this.insuranceDetailsId)
        var result = res;

        this.insAuthDataSource = new MatTableDataSource(result);
      }
      if (this.insuranceDetailsId == null ||this.insuranceDetailsId == undefined ){
        this.insAuthDataSource = new MatTableDataSource(this.AllInsuranceAuthList);
     }
       
        this.insAuthDataSource.paginator = this.paginator;
        this.insAuthDataSource.sort = this.sort;
        this.tmpInsAuthDataSource=res;
      }
      } ,
      err => {this.spinservice.hide();});
      
}

onEditInsuranceAuth(item){
 
 this.isEditInsAuth = true
  this.insAuthorizationDtl.AuthorizationNumber = item.AuthorizationNumber == "0"? null:item.AuthorizationNumber;
  this.insAuthorizationDtl.AuthorizationTypeId =Number(item.AuthorizationTypeId);
  this.insAuthorizationDtl.InsuranceDetailsId = item.InsuranceDetailsId == "0" ? null:  item.InsuranceDetailsId;
  this.insAuthorizationDtl.DaysPerWeek = item.DaysPerWeek;
  this.insAuthorizationDtl.EndDate = item.EndDate;
  this.insAuthorizationDtl.StartDate= item.StartDate;
  this.insAuthorizationDtl.NumberOfVisits=item.NumberOfVisits == "0" ? null : item.NumberOfVisits;
  this.insAuthorizationDtl.InsuranceAuthorizationId = item.InsuranceAuthorizationId == "0" ? null : item.InsuranceAuthorizationId;
  this.insAuthorizationDtl.CptHcpcs = item.CptHcpcs;
  this.insAuthorizationDtl.AuthorizationTrackId = item.AuthorizationTrackId; 
  this.insAuthorizationDtl.ClinicalInformationProvider = item.ClinicalInformationProvider; 
  this.insAuthorizationDtl.Reference=item.Reference;
  // this.IsIop = item.isIop
  // this.IsPcp = item.isPcp
  // if(item.isIop){
  //   this.insAuthorizationDtl.IsIop=2
  //   this.insAuthorizationDtl.IsPcp=false
  // }
  // else if(item.isPcp){
  //   this.insAuthorizationDtl.IsIop = 1
  //   this.insAuthorizationDtl.IsPcp=true
  // }
  //this.insAuthorizationDtl.ActiveFrom=item.activeFrom
  //this.insAuthorizationDtl.Reference=item.reference
}

Oncancel(){
  this.insAuthorizationDtl = new InsuranceAuthorization();
  this.insAuthorization.resetForm()
  this.isEditInsAuth = false
  this.insAuthorizationDtl.DaysPerWeek=0
  this.isExpiryDate = false;
  this.isNoOfVisits = false;
  // this.IsIop=false;
  // this.IsPcp=false
  
}
ondeleteInsuranceAuthOLD(item){
 
  this.spinservice.show();
  this.leadservice.RemoveInsuranceAuthorizationDtl(item.InsuranceAuthorizationId).subscribe((res:any)=>{
    this.spinservice.hide();
      if(res){
      this.snackbar.success("Authorization Details removed successfully");
      this.Oncancel();
      this.getAllInsAuthDetails();
    }
  
  },
err => {
 // console.log("error")
  this.spinservice.hide();
  this.snackbar.error(err.error);
});
}

ondeleteInsuranceAuth(item) {
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.SuccessMessage.DeleteConfirmMessage
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
      this.spinservice.show();
      this.leadservice.RemoveInsuranceAuthorizationDtl(item.InsuranceAuthorizationId).subscribe((res:any)=>{
        this.spinservice.hide();
          if(res){
          this.snackbar.success("Authorization Details removed successfully");
          this.Oncancel();
          this.getAllInsAuthDetails();
        }
      
      },
    err => {
     // console.log("error")
      this.spinservice.hide();
      this.snackbar.error(err.error);
    });
    }
  });

}
chengeRadioValue(value){
  if(value=='expiryDate'){
    this.isExpiryDate=true;
    this.isNoOfVisits=false;
  }else if(value=='noOfVisits'){
    this.isExpiryDate =false;
    this.isNoOfVisits =true; 
   }
  }
alphanumericwithhypen(e) {
  
  var code = ('charCode' in e) ? e.charCode : e.keyCode;
  if (!(code == 45) && // hypen
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123)) { // lower alpha (a-z)
    e.preventDefault();
  }
 }

 alphanumeric(e) {
  
  var code = ('charCode' in e) ? e.charCode : e.keyCode;
  if (!(code > 47 && code < 58) && // numeric (0-9)
    !(code > 64 && code < 91) && // upper alpha (A-Z)
    !(code > 96 && code < 123)) { // lower alpha (a-z)
    e.preventDefault();
  }
 }

 getAuthorizationDetailTrack(){
  this.spinservice.show();
  this.leadservice.getAuthorizationDetailTrack().subscribe(
    data => {
     this.spinservice.hide();
      if(data.objInputString!=null)
      {
      this.drpAuthorizationTrack =this.commonService.decrypdata(data.objInputString);
       } },
      err => {this.spinservice.hide();
      },);
      }

    getCrbaview() {
        this.spinnerservice.show();
    
        this.leadservice.getCrbaviewInsurance(this.patientinfoid,0,this.HistoryId).subscribe(
          (res: any) => {
    
            this.spinnerservice.hide();
            if (res) {
                this.crbainfo = cloneDeep(res);
              this.clinicalInfo = this.crbainfo.authorizationsDetails.clinicalInformations;
              if (this.clinicalInfo && this.clinicalInfo.length == 0) {
                this.clinicalInfo.push({
                  clinicalInformationId: 0,
                  authorizationsDetailsId: 0,
                  information: "",
                  isActive: true
                });
              }
                // this.prgdays = JSON.parse('[' + this.crbainfo.programDetails.programDays.replace(/'/g, '') + ']');
                
              if (this.crbainfo.programDetails.programDays) {
                this.prgdays = this.crbainfo.programDetails.programDays.split(',');
              }
            }
          },
          err => {
            this.spinnerservice.hide();
          },
          
        );
      }
      addclinicalinformation() {
        this.clinicalInfo.push({
          clinicalInformationId: 0,
          authorizationsDetailsId: 0,
          information: "",
          isActive: true
        });
      }
      removeclinicalinformation(data, index) {
        if (data.clinicalInformationId != 0) {
          this.removeexistclicalinfo(data);
        }
        this.clinicalInfo.splice(index, 1);
      }
      removeexistclicalinfo(data) {
        this.leadservice.removeClinicalInfo(data.clinicalInformationId).subscribe((res: any) => {
          this.spinnerservice.hide();
        },
          err => {
            this.spinnerservice.hide();
          }
        );
      }
        
}


import { Component, OnInit,ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/notification/notification.service';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { constantVariables } from 'src/app/constant/constant';
import { Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.scss']
})
export class ViewNotificationComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['notificationTitle', 'status', 'createdOn'];
  dataSource: MatTableDataSource<any>;
  now = new Date();
  FromDate: Date = new Date(this.now.getFullYear(), this.now.getMonth() - 3, 1);
  ToDate: Date = new Date();
  dateRangeerror = false;
  currentUserId: any;
  roleId: any;
  currentdate:any;
  notificationList :any;
  constructor(private notificationservice: NotificationService,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,private router: Router) { }

  ngOnInit(): void {
    this.currentdate=new Date();
    this.currentUserId = localStorage.getItem('id');
    this.roleId = localStorage.getItem("roleId");
    this.getNotification();
  }
  applydatefilter() {
    if (this.FromDate && this.ToDate) {
      var d1 = this.FromDate;
      var d2 = this.ToDate;
      if (d1 > d2) {
        this.dateRangeerror = true;
      }
      else {
        this.dateRangeerror = false;
        this.getNotification();
      }
    }

  }
  getNotification() {
    this.spinnerservice.show();
    let _obj = {
      FromDate: moment(this.FromDate).format("MM/DD/YYYY"),
      ToDate: moment(this.ToDate).format("MM/DD/YYYY"),
      RoleId: this.roleId,
      UserId: this.currentUserId
    };
    this.notificationservice.getViewNotification(_obj).subscribe(
      (Response:any) => {
          this.spinnerservice.hide();
          this.notificationList = Response;
          this.dataSource = new MatTableDataSource(Response);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
       
        
      },
      error =>
        console.log(error.error)

    );

  }
  markUnreadNofitication(item) {
    const dataToEncrypt = this.commonService.DataToEncrypt(item, false);
    this.notificationservice.postMarkRead(dataToEncrypt).subscribe(res => {
      if(item.notificationTypeId == constantVariables.NotificationTypeid.AuthSetting)
      {
        this.router.navigate(['//auth-threshold']);
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.Audit)
      {
       // this.router.navigateByUrl('/audit-list');
        this.router.navigate(['/audit-clinician'], {
          queryParams: {
            id: this.commonService.FrontEndEncryption(item.patientAccountId),
          },
        });
      }
      // if(item.notificationTypeId == constantVariables.NotificationTypeid.AuthNotificationIsManager)
      // {
      //   this.router.navigateByUrl('/treatmentplan-list');
      // }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.RequestTour){
        this.router.navigate(['/request-tour'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId),name:this.commonService.FrontEndEncryption(item.comments) } });
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.Email){
        this.router.navigate(['/messages']);
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.RequestReview || item.notificationTypeId == constantVariables.NotificationTypeid.AcceptProposal || item.notificationTypeId == constantVariables.NotificationTypeid.DenyProposal
        || item.notificationTypeId == constantVariables.NotificationTypeid.CancelTour || item.notificationTypeId == constantVariables.NotificationTypeid.MarkTourCompleted){
        if(window.location.href.includes('leadlist')){
          window.location.reload();
        }
        else{
          this.router.navigate(['/leadlist']);
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.ReturnProfile || item.notificationTypeId == constantVariables.NotificationTypeid.ProfileVerified || item.notificationTypeId == constantVariables.NotificationTypeid.SubmitProposal
        ||( item.notificationTypeId == constantVariables.NotificationTypeid.AssignTour && !!(localStorage.getItem('patientId')))){
        //this.router.navigate(['/leadinfo']);
        if( item.notificationTypeId == constantVariables.NotificationTypeid.SubmitProposal ||  item.notificationTypeId == constantVariables.NotificationTypeid.AssignTour){
          localStorage.setItem("profiletab", "5");
  
        }
        this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId) } });
      }
      if(( item.notificationTypeId == constantVariables.NotificationTypeid.AssignTour && !(localStorage.getItem('patientId'))) || (item.notificationTypeId == constantVariables.NotificationTypeid.RequestTourAssignStaff &&  !(localStorage.getItem('patientId')))){
        if(window.location.href.includes('clinician?tab')){
          window.location.reload();
        }
        else{
        this.router.navigate(['/clinician'],{ queryParams: { tab: 2 } });
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.RescheduleIntakeRequest){
        if(window.location.href.includes('patientslists')){
          window.location.reload();
        }
        else{
          this.router.navigate(['/patientslists']);
  
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.ScheduleIntake){
        if(!(localStorage.getItem('patientId'))){
          this.router.navigate(['/clinician']);
        }
        else{
          this.router.navigate(['/patient-breifcase']);
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.AcceptReschedule || item.notificationTypeId == constantVariables.NotificationTypeid.DenyReschedule ){
        if(window.location.href.includes('clinician?tab')){
          window.location.reload();
        }
        else{
        this.router.navigate(['/clinician'],{ queryParams: { tab: 2 } });
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.CompleteTreatmentplan){
        if(!(localStorage.getItem('patientId'))){
          if(window.location.href.includes('patientslists')){
            window.location.reload();
          }
          else{
            this.router.navigate(['/patientslists']);
    
          }
        }
        else{
          this.router.navigate(['/patient-breifcase']);
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.ScheduleAppointments){
        if(!(localStorage.getItem('patientId'))){
          if(window.location.href.includes('clinician')){
            window.location.reload();
          }
          else{
            this.router.navigate(['/clinician']);
    
          }
        }
        else{
          this.router.navigate(['/patient-breifcase']);
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.PsyTherapyFollowup){
        if(window.location.href.includes('patientslists')){
          window.location.reload();
        }
        else{
          this.router.navigate(['/patientslists']);
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.GenerateIntakepdf){
        if(window.location.href.includes('clinician?menu=3')){
          window.location.reload();
        }
        else{
          this.router.navigate(['/clinician'],{ queryParams: { menu: 3 } });
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.UpdateBenifits){
        localStorage.setItem("profiletab", "2");
        if(window.location.href.includes('leadinfo')){
          window.location.reload();
        }
        else{
        this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId) } });
        }
      }
      if((item.notificationTypeId == constantVariables.NotificationTypeid.RequestTourAssignStaff &&  !!(localStorage.getItem('patientId')))|| item.notificationTypeId == constantVariables.NotificationTypeid.RescheduleTourRequest || item.notificationTypeId == constantVariables.NotificationTypeid.ChangeStaffTour){
        localStorage.setItem("profiletab", "5");
       
        if(window.location.href.includes('leadinfo')){
          window.location.reload();
        }
        else{
        this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(item.commonId) } });
        }
      }
      if(item.notificationTypeId == constantVariables.NotificationTypeid.AcceptIntake){
        var access = JSON.parse(localStorage.getItem('roleaccess'));
        //const pageid = constantVariables.pagelists.Goal;
        var roleaccessClinician = access.pagepermissions.find(x => x.pageId == constantVariables.pagelists.ProgressNoteFivepointRating);
        if(!(localStorage.getItem('patientId')) && roleaccessClinician.isView){
          if(window.location.href.includes('clinician')){
            window.location.reload();
          }        
          else{
            this.router.navigate(['/clinician'],{ queryParams: { menu: 1 } });
    
          }
        }
        else{
          if(!(localStorage.getItem('patientId'))){
            if(window.location.href.includes('patientslists')){
              window.location.reload();
            }
            else{
              this.router.navigate(['/patientslists']);
      
            }
        }
      }
    }
      
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
}

export class notification {
    InAppNotificationId: number;
    NotificationTypeId: number;
    NotificationTitle: string;
    Url: string;
    Comments: string;
    Status: number;
    RoleId: number;
    UserId: number;
    Id:number;
    TypeId:number;
}
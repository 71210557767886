<div class="clnical">
    <!-- <span *ngIf="global.clinicalInfoUpdatedOn !=null"
        class="status-msg float-left text-right w-100 mr-3 mt-2 cursor-pointer text-underline">Last Updated on:
        <span>{{global.clinicalInfoUpdatedOn | date:
            'MM/dd/yyyy'}}</span></span>
    <div class="col-md-12">
        <div class="text-right">
            <button type="button" (click)="onSaveClinicalQuestion()" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save & Next</button>
            <button type="button" [routerLink]="['/clinician']" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40">Cancel</button>
        </div>
    </div> -->
    <div class="col-md-12 d-flex">
        <span *ngIf="global.clinicalInfoUpdatedOn !=null"
            class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last Updated on:
            <span>{{global.globalUtctimeformat(global.clinicalInfoUpdatedOn) | date:
                'MM/dd/yyyy hh:mm a'}}</span></span>
                
                <span *ngIf="global.clinicalInfoUpdatedByName !=null"
                class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last Updated By:
                <span>{{global.clinicalInfoUpdatedByName}}</span></span>
        <div class="text-right ml-auto">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <!-- <button type="button" (click)="onSaveClinicalQuestion(0)" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{isMovedChma ? 'Next' : 'Save &
                Next'}}</button> -->
                <button type="button" (click)="onSaveClinicalQuestion(0)" mat-stroked-button *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{'Save &
                Next'}}</button>
                <!-- *ngIf="!isMovedChma" -->
                <button type="button" (click)="onSaveClinicalQuestion(1)" mat-stroked-button
                    *ngIf="roleAccess.isCreate||roleAccess.isEdit" class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save &
                    Exit</button>
                <button type="button" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button
                    *ngIf="!global.isClinicalInfo" (click)="onSaveClinicalQuestion(2)">Save as Draft</button>
        </div>
    </div>
    <!-- [ngClass]="{'disabled':isMovedChma}" -->
    <div >
        <ng-container *ngFor="let item of lstIntakeClinicial?.answers?.details ;let i=index;">
            <div class="col-md-12 set1-temp">
                <div class="row mb-2">
                    <label class="col-md-12 set-label">{{item.question}}</label>
                    <div class="col-md-12" *ngIf="item.typeId==queDescriptive">

                        <ng-container *ngFor="let opt of item?.options;let idx=index;trackBy: trackByFn">
                            <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
                            <textarea matInput appTextareaAutoresize (input)="updateRemainingChars(opt.description.length,3000,i)" (focusin)="updateRemainingChars(opt.description.length,3000,i)"
                            (focusout)="isShowCount[i] = false" cols="2" rows="4" class="w-100 custom-textarea" maxlength="3000" [(ngModel)]="opt.description"
                                #explanation="ngModel"></textarea>
                                <div class="count-text" *ngIf="isShowCount[i] == true">Remaining Characters: {{ wordCount }}</div>
                            </mat-form-field> -->

                            <!-- <app-word-counter [text]="opt.description" [maxlength]="3000"  [(ngModel)]="opt.description" 
                            (textChange)="onTextChange($event,item?.options,idx)"></app-word-counter> -->
                            <app-word-counter *ngIf="item.configureFormDetailId != 312 || item.question != 'Current DSM-V diagnosis (Write numeric code and description of diagnosis): '" 
                            [text]="opt.description" [maxlength]="10000"  [(ngModel)]="opt.description" 
                            (textChange)="opt.description = $event"></app-word-counter>
                        </ng-container>
                        <div class="col-md-12" *ngIf="item.configureFormDetailId== 312 || item.question == 'Current DSM-V diagnosis (Write numeric code and description of diagnosis): '">
                            <!-- <h2 class="diag-label mt-3 pr-0 mb-3"> Diagnosis</h2> -->
                            <div class="basic-info diag-sec mb-3 row">
                             
                              
                              <div class="col-md-4">
                                
                                <mat-form-field class="w-100">
                  
                                  <mat-label>Chooose the Diagnosis Code for the Patient</mat-label>
                                  <mat-select multiple #DCode [(ngModel)]="_dtoDiagnosis.diagnosisCode" #diagnosisCode="ngModel"
                                    name="diagnosisCode" required >
                                    <input class="myInput"  [(ngModel)]="filterBy" id="filterBy" name="filterBy" matInput focused="'true'" type="text" 
                                    autocomplete="off" placeholder="search" style="padding:14px">
                                    <mat-option #matOption *ngFor="let item of ddldiagnosiscode " [value]="item.value"
                                      (click)="onDiagnosisCodeselect(item,matOption.selected)" [matOption.selected]="item.isActive" [matTooltip]="item.label">
                                      {{item.label}}
                                    </mat-option>
                                  </mat-select>
                                  <!-- <mat-error *ngIf="frmDiagnosis.submitted && diagnosisCode.invalid">
                                    <mat-error *ngIf="diagnosisCode.errors.required">Diagnosis is
                                      required</mat-error>
                                  </mat-error> -->
                                </mat-form-field>
                              </div>
                              <div class="col-md-8" *ngIf="_dtoDiagnosis.diagnosisCode?.length > 0">
                                <div class="chip-container-content">
                                  <mat-chip-list aria-label="Fish selection">
                                    <ng-container *ngFor="let item of ddldiagnosiscode">
                                      <mat-chip *ngIf="item.isActive">{{item.label}}
                                        <mat-icon matChipRemove (click)="onRemoveDiagnosisCode(item)">cancel</mat-icon>
                                      </mat-chip>
                                    </ng-container>
                                  </mat-chip-list>
                                </div>
                              </div>
                            
                            </div>
                          </div>

                    </div>
                    <div class="col-md-12 custom-radio" *ngIf="item.typeId==queYesNo">
                        <ng-container *ngFor="let opt of item?.options;let idx=index;">
                            <label class="mr-2">
                                <input type="radio" class="medium-radio" name="queYesNo{{idx}}{{i}}"
                                    [checked]="opt.isAnswer == true ? true:false" (click)="rbAnswer(i,idx,item)" required>
                                {{opt.optionLabel}}
                            </label>
                            <!-- <div class="sub-hint-txt" *ngIf="opt.optionChar !=null">{{opt.optionchar}}
                        </div>
                        <div class="sub-hint-txt" *ngIf="opt.optionChar ==null">(If Other, please Explain)
                        </div> -->
                        </ng-container>
                        <ng-container *ngFor="let opt of item?.options;let idx=index;">
                            <div class="sub-hint-txt" *ngIf="opt.optionChar !=''">{{opt.optionChar}}
                            </div>
                            <!-- <div class="sub-hint-txt" *ngIf="opt.optionChar ==''">(If Other, please Explain)
                        </div> -->
                        </ng-container>
                        <div class="cmha-multiselect-dropdown mb-4" *ngIf="item.configureFormDetailId== 311 && IsSuicidalOption">
                            
                            <!-- <mat-select class="form-control mb-2" [(ngModel)]="lstCHMADetails.mindSet">
                                <mat-option [value]="1">SI (Suicidal Ideation)</mat-option>
                                <mat-option [value]="2">SH (Self-Harm)</mat-option>
                                <mat-option [value]="3">HI (Homicidal Ideation)</mat-option>
                                <mat-option [value]="4">Elope</mat-option>
                                <mat-option *ngFor="let item of ddlMindset"   [value]="item.mindsetMasterid">{{item.mindSetLable}}</mat-option>
                            </mat-select> -->
                            <!-- Multi Select DropDown -->
                            <!-- <ng-select [items]="ddlMindset"
                                            bindLabel="mindSetLable"  bindValue=""
                                            [multiple]="true"
                                            placeholder=""
                                            (change)="onMaterialGroupChange($event)" [(ngModel)]="mindsetList">  
                            </ng-select>
                            <ng-select [items]="ddlMindset" [multiple]="true" [closeOnSelect]="false"
                                [searchable]="false" bindLabel="mindSetLable" [(ngModel)]="mindsetList">
                            </ng-select> -->

                            <!-- <ng-multiselect-dropdown [placeholder]="" [settings]="dropdownSettings"
                                [data]="ddlMindset" [(ngModel)]="mindsetList">
                            </ng-multiselect-dropdown> -->
                                <mat-select [(value)]="mindsetList" multiple>
                                  <mat-option *ngFor="let minsset of ddlMindset" [value]="minsset.mindsetMasterid">{{minsset.mindSetLable}}</mat-option>
                                </mat-select>
                        </div>
                        <div *ngIf="item.iSquestionExplanation == 1">
                            <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
                            <textarea matInput appTextareaAutoresize cols="2" rows="4" class="w-100 custom-textarea" maxlength="3000" [(ngModel)]="item.explanation"
                                #explanation="ngModel"></textarea>
                                </mat-form-field> -->
                                
                                <app-word-counter [text]="item.explanation" [maxlength]="10000"  [(ngModel)]="item.explanation" 
                            (textChange)="item.explanation = $event"></app-word-counter>
                            <span class="w-100 font-14 text-danger"
                                *ngIf="item.isReqExplanation != undefined && item.isReqExplanation && item.explanation==''">Explanation
                                is Required</span>
                        </div>
                       
                    </div>
                    <div *ngIf = "item.sortOrder == 16"  >
                    <form #someForm="ngForm" class="example-form">
                        <div class="col-md-12 set3-temp">
                            <div class="row mb-2">
                                <label class="col-md-12 set-label">For substances</label>
                                <div class="col-md-12 ">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <mat-form-field class="w-100">
                                                <mat-label>Age started</mat-label>
                                                <input matInput maxlength="2" numeric [(ngModel)]="lstCHMADetails.ageStarted"
                                                    #ageStarted="ngModel" name="ageStarted" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="w-100">
                                                <mat-label>Last drink/drug</mat-label>
                                                <input matInput [matDatepicker]="picker" readonly (click)="picker.open()"
                                                    [max]="today" [(ngModel)]="lstCHMADetails.lastdrink" #lastdrink="ngModel"
                                                    name="lastdrink" />
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
            
                                            <mat-label class="col-md-12"> Frequency</mat-label>
                                            <div class="col-md-12">
                                                <mat-radio-group aria-label="Select an option"
                                                    [(ngModel)]="lstCHMADetails.frequency" #frequency="ngModel" name="frequency">
                                                    <mat-radio-button class="mr-3" [value]="1">q.a.m. Every morning </mat-radio-button>
                                                    <mat-radio-button class="mr-3" [value]="2">q.p.m. Every evening</mat-radio-button>
                                                    <mat-radio-button class="mr-3" [value]="3">q.h.s Every night at bedtime</mat-radio-button>
                                                    <mat-radio-button class="mr-3" [value]="4">b.i.d. Twice daily </mat-radio-button>
                                                    <mat-radio-button class="mr-3" [value]="5">t.i.d. 3 times a day</mat-radio-button>
                                                    <mat-radio-button class="mr-3" [value]="6">q.i.d 4 times a day</mat-radio-button>
                                                    <mat-radio-button class="mr-3" [value]="7">q.o.d Every other day </mat-radio-button>
                                                    <mat-radio-button class="mr-3" [value]="8">q.w. Every week</mat-radio-button>
                                                    <mat-radio-button class="mr-3" [value]="9">p.r.n. Daily as needed</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 set3-temp">
                            <div class="row mb-2">
                                <label class="col-md-12 set-label">Prior hospitalizations</label>
                                <div class="col-md-12 ">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <mat-form-field class="w-100">
                                                <mat-label>Date</mat-label>
                                                <input matInput [matDatepicker]="pickerd" readonly (click)="pickerd.open()"
                                                    [max]="today" [(ngModel)]="lstCHMADetails.hospitalizationDate"
                                                    #hospitalizationDate="ngModel" name="hospitalizationDate" />
                                                <mat-datepicker-toggle matSuffix [for]="pickerd"></mat-datepicker-toggle>
                                                <mat-datepicker #pickerd></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                            <mat-form-field class="w-100">
                                                <mat-label>Reason</mat-label>
                                                <input matInput maxlength="100" [(ngModel)]="lstCHMADetails.hospitalizationReason"
                                                    #hospitalizationReason="ngModel" name="hospitalizationReason" />
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-4">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="col-md-12 set4-temp">
                        <div class="row mb-2">
            
                            <div class="col-md-12 ">
                                <div class="row">
                                    <div class="col-md-4">
                                        <mat-form-field class="w-100">
                                            <mat-label>Hospital and attending physician</mat-label>
                                            <input matInput maxlength="100" [(ngModel)]="lstCHMADetails.hospitalAttending"
                                                #hospitalAttending="ngModel" name="hospitalAttending" />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="w-100">
                                            <mat-label>Admission Dates</mat-label>
                                            <input matInput [matDatepicker]="pickerad" readonly (click)="pickerad.open()"
                                                [max]="today" [(ngModel)]="lstCHMADetails.admissionDate" #admissionDate="ngModel"
                                                name="admissionDate" />
                                            <mat-datepicker-toggle matSuffix [for]="pickerad"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerad></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-4">
                                        <mat-form-field class="w-100">
                                            <mat-label>Discharge Dates</mat-label>
                                            <input matInput [matDatepicker]="pickerdd" readonly (click)="pickerdd.open()"
                                                [max]="today" [(ngModel)]="lstCHMADetails.dischargeDate" #dischargeDate="ngModel"
                                                name="dischargeDate" />
                                            <mat-datepicker-toggle matSuffix [for]="pickerdd"></mat-datepicker-toggle>
                                            <mat-datepicker #pickerdd></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </ng-container>
        <!-- <div class="col-md-12 set2-temp">
        <div class="row mb-2">
            <label class="col-md-12 set-label">Is there a history of prior mental health/substance
                abuse treatment? </label>
            <div class="col-md-12 custom-radio">
                <mat-radio-group aria-label="Select an option">
                    <mat-radio-button value="1" class="mr-2">Yes</mat-radio-button>
                    <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
                </mat-radio-group>
                <div class="sub-hint-txt">(If yes, please provide a chronological psychiatric,
                    medical, and substance use history with time frames and outcome, if available.)
                </div>
                <div>
                    <textarea class="w-100"></textarea>
                </div>
            </div>
        </div>
    </div> -->

        <!-- <form #someForm="ngForm" class="example-form">
            <div class="col-md-12 set3-temp">
                <div class="row mb-2">
                    <label class="col-md-12 set-label">For substances</label>
                    <div class="col-md-12 ">
                        <div class="row">
                            <div class="col-md-4">
                                <mat-form-field class="w-100">
                                    <mat-label>Age started</mat-label>
                                    <input matInput maxlength="2" numeric [(ngModel)]="lstCHMADetails.ageStarted"
                                        #ageStarted="ngModel" name="ageStarted" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="w-100">
                                    <mat-label>Last drink/drug</mat-label>
                                    <input matInput [matDatepicker]="picker" readonly (click)="picker.open()"
                                        [max]="today" [(ngModel)]="lstCHMADetails.lastdrink" #lastdrink="ngModel"
                                        name="lastdrink" />
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">

                                <mat-label class="col-md-12"> Frequency</mat-label>
                                <div class="col-md-12">
                                    <mat-radio-group aria-label="Select an option"
                                        [(ngModel)]="lstCHMADetails.frequency" #frequency="ngModel" name="frequency">
                                        <mat-radio-button class="mr-3" [value]="1">Daily </mat-radio-button>
                                        <mat-radio-button class="mr-3" [value]="2">weekly</mat-radio-button>
                                        <mat-radio-button class="mr-3" [value]="3">monthly</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 set3-temp">
                <div class="row mb-2">
                    <label class="col-md-12 set-label">Prior hospitalizations</label>
                    <div class="col-md-12 ">
                        <div class="row">
                            <div class="col-md-4">
                                <mat-form-field class="w-100">
                                    <mat-label>Date</mat-label>
                                    <input matInput [matDatepicker]="pickerd" readonly (click)="pickerd.open()"
                                        [max]="today" [(ngModel)]="lstCHMADetails.hospitalizationDate"
                                        #hospitalizationDate="ngModel" name="hospitalizationDate" />
                                    <mat-datepicker-toggle matSuffix [for]="pickerd"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerd></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="w-100">
                                    <mat-label>Reason</mat-label>
                                    <input matInput maxlength="100" [(ngModel)]="lstCHMADetails.hospitalizationReason"
                                        #hospitalizationReason="ngModel" name="hospitalizationReason" />
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form> -->
        <!-- <div class="col-md-12 set4-temp">
            <div class="row mb-2">

                <div class="col-md-12 ">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field class="w-100">
                                <mat-label>Hospital and attending physician</mat-label>
                                <input matInput maxlength="100" [(ngModel)]="lstCHMADetails.hospitalAttending"
                                    #hospitalAttending="ngModel" name="hospitalAttending" />
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="w-100">
                                <mat-label>Admission Dates</mat-label>
                                <input matInput [matDatepicker]="pickerad" readonly (click)="pickerad.open()"
                                    [max]="today" [(ngModel)]="lstCHMADetails.admissionDate" #admissionDate="ngModel"
                                    name="admissionDate" />
                                <mat-datepicker-toggle matSuffix [for]="pickerad"></mat-datepicker-toggle>
                                <mat-datepicker #pickerad></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field class="w-100">
                                <mat-label>Discharge Dates</mat-label>
                                <input matInput [matDatepicker]="pickerdd" readonly (click)="pickerdd.open()"
                                    [max]="today" [(ngModel)]="lstCHMADetails.dischargeDate" #dischargeDate="ngModel"
                                    name="dischargeDate" />
                                <mat-datepicker-toggle matSuffix [for]="pickerdd"></mat-datepicker-toggle>
                                <mat-datepicker #pickerdd></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="col-md-12 set5-temp ">
            <div class="row mb-2">
                <label class="col-md-12 set-label mb-3">Mental Status to validate DSM-V
                    diagnosis</label>
                <div class="col-md-12">
                    <ng-container *ngFor="let item of lstMentalSurvey?.answers?.details ;let i=index;">
                        <div class="row mb-3">
                            <div class="col-md-2">
                                <label class="col-md-12 set-label2">{{item.question}}</label>
                            </div>
                            <div class="col-md-10 custom-radio">
                                <ng-container *ngFor="let opt of item?.options;let idx=index;">
                                    <label class="mr-2">
                                        <input type="checkbox" name="quemcma{{idx}}{{i}}"
                                            [checked]="opt.isAnswer == true ? true:false"
                                            (click)="cbkAnswer(i,idx,$event)" required>
                                        {{opt.optionLabel}}
                                    </label>
                                </ng-container>
                                <div class="col-md-12 p-0" *ngIf="item.iSquestionExplanation == 1">
                                    <div class="sub-hint-txt">(If Other, please Explain) </div>
                                    <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
                                    <textarea matInput appTextareaAutoresize cols="2" rows="4" class="w-100 custom-textarea" [(ngModel)]="item.explanation" maxlength="3000"
                                        #explanation="ngModel"></textarea>
                                        </mat-form-field> -->
                                        <app-word-counter [text]="item.explanation" [maxlength]="10000"  [(ngModel)]="item.explanation" 
                            (textChange)="item.explanation = $event"></app-word-counter>
                                    <span class="w-100 font-14 text-danger"
                                        *ngIf="item.isReqMentalExplanation != undefined && item.isReqMentalExplanation && item.explanation==''">Explanation
                                        is
                                        Required</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="text-right">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <!-- <button type="button" (click)="onSaveClinicalQuestion(0)" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{isMovedChma ? 'Next' : 'Save &
                Next'}}</button> -->
                <button type="button" (click)="onSaveClinicalQuestion(0)" mat-stroked-button *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{ 'Save &
                Next'}}</button>
                <!-- *ngIf="!isMovedChma" -->
            <button type="button" (click)="onSaveClinicalQuestion(1)" mat-stroked-button  *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save & Exit</button>

        </div>
    </div>

</div>
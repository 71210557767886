<div class="top-btn-section clearfix mb-1 w-100" *ngIf="pageId == 1">
    <div class="col-md-6 ml-auto mt-2 button-position pr-0">
        <!-- <button type="button" [routerLink]="['/patient']" class="btn primary-btn-outlined float-right mr-2"><i
                class="fas fa-angle-left pr-2"></i>Back</button> -->

                <!-- <button type="button" [routerLink]="roleID=='4'?['/patient-breifcase']:['/patient']" class="btn primary-btn-outlined float-right mr-2"><i
                    class="fas fa-angle-left pr-2"></i>Back</button> -->

                    <button type="button"  (click)="onBackClick()" class="btn primary-btn-outlined float-right mr-2"><i
                        class="fas fa-angle-left pr-2"></i>Back</button>

                   
    </div>
</div>
<div class="screening" [ngClass]="{'disabled':pageId == 1}">

    <div class="screen-note d-none">
        <p class="m-0">Select "Yes" or "No" to the following. If you select yes please list any special
            instruction or health comments.</p>
        <p class="m-0">In the past six months has your child had any of the following symptoms:</p>
    </div>
    <div class="screen-note  mt-2">
        <p class="m-0"><span class="font-weight-bold mr-2 ">Directions:</span>{{lstIntakeChecklist?.inTakeProgress?.questionDirection}}</p>
        
    </div>
   


    <form (ngSubmit)="postIntakeChecklist(formchecklist)" id="formchecklist"
        autocomplete="off" #formchecklist="ngForm" novalidate>
        <div class="col-md-12 text-right">
            <div class="top-btn-section clearfix">
                <h2 class="head-section">{{typeId== 1 ? 'Health Comments/Screening': 'Medication Management Quality
                    Assurance'}} Checklist</h2>
                    <div class="ml-auto mt-3"> <strong>Client Name : {{patientName}} </strong> </div>
                <div class="w-25 ml-auto d-none">
                    <mat-form-field class="w-100">
                        <mat-label>Date of last physical
                            exam</mat-label>
                        <input matInput [matDatepicker]="picker" readonly (click)="picker.open()" [max]="today"
                            #ExaminationDate="ngModel" name="ExaminationDate" [(ngModel)]="lastExaminationDate"
                            [ngClass]="{ 'is-invalid': formchecklist.submitted && ExaminationDate.invalid }" required>
                        <mat-datepicker-toggle matSuffix [for]="picker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="formchecklist.submitted && ExaminationDate.invalid">
                            <mat-error *ngIf="ExaminationDate.errors.required">Last Examination Date is
                                required</mat-error>
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-md-12 p-0 yes-no-heads d-sm-none d-lg-block d-md-block">
                                <div class="row">
                                    <!-- <div class="col-md-12 ">
                                        <div class="row form-group">
                                            <div class="col" >

                                            </div>
                                            <div class="col-md-1 p-0 text-center" >
                                                <span class="yesno-label"> No/Yes</span>
                                            </div>
                                            <div class="col-md-5" *ngIf="lstIntakeChecklist?.answers[0]?.iSquestionExplanation == 1">

                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-6">
                                        <div class="row form-group">
                                            <div class="col mb-1" >
                                            </div>
                                            <div class="col-md-1 p-0 text-center col-sm-6 mb-1">
                                                <span class="yesno-label"> No/Yes</span>
                                            </div>
                                            
                                            <div class="col-md-5" *ngIf="lstIntakeChecklist?.answers[0]?.iSquestionExplanation == 1">
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-12 p-0">
                                <div class="row">
                                    <div class="col-md-12 "
                                        *ngFor="let item of lstIntakeChecklist?.answers;let i = index">
                                        <div class="row form-group">
                                            <div class="col mb-1">
                                                <span class="yesno-label">{{i+1}}. {{item.question}}</span>
                                            </div>
                                            <div class="col-md-2  col-sm-6 text-center">
                                                <!-- <label class="switch">
                                                    <input class="switch-input" type="checkbox" [(ngModel)]="item.isAnswer" [ngModelOptions]="{standalone: true}"
                                                    #isAnswer="ngModel" name="isAnswer" />
                                                    <span class="switch-label" data-on="Yes" data-off="No"></span> 
                                                    <span class="switch-handle"></span> 
                                                </label> -->

                                                <mat-radio-group name="isAnswer{{i}}" [(ngModel)]="item.isAnswer"
                                                    required #isAnswer="ngModel">
                                                    <mat-radio-button [value]="true" class="mr-3 my-3"
                                                        [checked]="item.isAnswer == true">Yes
                                                    </mat-radio-button>
                                                    <mat-radio-button [value]="false"
                                                        [checked]="item.isAnswer == false">No
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                                <mat-error *ngIf="formchecklist.submitted && isAnswer.invalid">
                                                    <mat-error
                                                        class="mat-form-field-subscript-wrapper position-relative"
                                                        *ngIf="isAnswer.errors.required">This field
                                                        is required
                                                    </mat-error>
                                                </mat-error>
                                            </div>

                                            <div class="col-md-5">
                                                <div *ngIf="(item.iSquestionExplanation == 1 && item.isAnswer == true) ||(item.iSquestionExplanation == 2 && item.isAnswer == false) ">

                                                    <span class="extra-info" *ngIf="item.questionExplanation != ''">{{item.questionExplanation}}</span>
                                                    <textarea row="3" placeholder="Enter Comments" class="w-100 textarea-placehold"
                                                        maxlength="300" [(ngModel)]="item.explanation"
                                                        #explanation="ngModel" name="explanation{{i}}"
                                                        [required]="item.isAnswer"></textarea>
                                                   
                                                    <mat-error *ngIf="formchecklist.submitted && explanation.invalid">
                                                        <mat-error
                                                            class="mat-form-field-subscript-wrapper position-relative"
                                                            *ngIf="explanation.errors.required">Comment required
                                                        </mat-error>
                                                    </mat-error>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div *ngIf="typeId==2">
                              <div class="col-md-12 mt-3 mb-3" *ngIf="lstIntakeChecklist?.answers[medicationIndex].isAnswer==true">
                                  <h2 class="head-section">Medication</h2>
                                  <div class="medication-dosage">  
                                    <div  class="row inputElement">  
                                      <div  class="col-md-3">  
                                        <label >Medications</label>  
                                      </div>
                            
                                      <div  class="col-md-3">  
                                        <label >Dosage</label>  
                                      </div>
                            
                                      <div  class="col-md-3">  
                                        <label>Frequency</label>  
                                      </div>
                            
                                      <div  class="col-md-3">  
                                        <label >Side effects</label>  
                                      </div>
                            
                                    </div>
                            
                                    <div  class="loop-section position-relative mb-2" *ngFor="let item of _dtoMedications;let i= index;let last=last" >
                            
                                      <div class="row inputElement">  
                                        <div  class="col-md-3">  
                                          <input  maxlength="50" [(ngModel)]="item.medications" #medications="ngModel" name="medications{{i}}" placeholder="Medicine" type="text" class="form-control " (change)="checkValidations()" >
                                          <mat-error class="text-xs" *ngIf="item.noMedicationError">
                                            This field is required
                                          </mat-error>
                                        </div>
                            
                                        <div  class="col-md-3">  
                                          <input  maxlength="50" [(ngModel)]="item.dosage" #dosage="ngModel" name="dosage{{i}}" placeholder="Dosage" type="text" class="form-control " (change)="checkValidations()" >
                                          <mat-error class="text-xs" *ngIf="item.noDosageError">
                                            This field is required
                                          </mat-error>
                                        </div>
                            
                                        <div  class="col-md-3">  
                                          <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                                          [(ngModel)]="item.frequency" #frequency="ngModel" name="frequencyM{{i}}" (change)="checkValidations()">
                                            <option value="null">Frequency</option>
                                            <option value="1">q.a.m. Every morning</option>
                                            <option value="2">q.p.m. Every evening</option>
                                            <option value="3">q.h.s Every night at bedtime</option>
                                            <option value="4">b.i.d. Twice daily</option>
                                            <option value="5">t.i.d. 3 times a day</option>
                                            <option value="6">q.i.d 4 times a day</option>
                                            <option value="7">q.o.d Every other day</option>
                                            <option value="8">q.w. Every week</option>
                                            <option value="9">p.r.n. Daily as needed</option>
                                          </select>
                                          <mat-error class="text-xs" *ngIf="item.noFrequencyError">
                                            This field is required
                                          </mat-error>
                                        </div>
                            
                                        <div  class="col-md-3">  
                                          <input  maxlength="50" [(ngModel)]="item.sideEffects" #sideEffects="ngModel" name="sideEffects{{i}}" placeholder="Side effects" type="text" class="form-control " (change)="checkValidations()" >
                                          <mat-error class="text-xs" *ngIf="item.noSideEffectsError">
                                              This field is required
                                          </mat-error>
                                        </div>
                            
                                      </div>
                            
                                      <a  class="add " style="cursor: pointer;" (click)="addMedicine_click()" *ngIf="last" >
                                        <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
                                      </a>
                            
                                      <a  class="remove" style="cursor: pointer;" (click)="removeMedicine_click(item,i)" *ngIf="_dtoMedications.length!=1">  
                                        <i  class="fa fa-lg fa-minus-circle"></i>  
                                        <span  class="sr-only">Remove</span>  
                                      </a>
                            
                                    </div>    
                                  </div>
                            
                              </div>
                          
                              <div class="col-md-12 mt-3 mb-3" *ngIf="lstIntakeChecklist?.answers[vitaminIndex].isAnswer==true" >
                                  <h2 class="head-section">Vitamins</h2>
                                  <div class="medication-dosage">  
                                    <div  class="row inputElement">
                            
                                      <div  class="col-md-3">
                            
                                        <label >Vitamins</label>
                            
                                      </div>
                            
                                      <div  class="col-md-3">
                            
                                        <label >Dosage</label>
                            
                                      </div>
                            
                                      <div  class="col-md-3">
                            
                                        <label>Frequency</label>
                            
                                      </div>
                            
                                      <div  class="col-md-3">
                            
                                        <label >Side effects</label>
                            
                                      </div>
                            
                                    </div>
                            
                                    <div  class="loop-section position-relative mb-2" *ngFor="let vitaminItem of _dtoVitamins;let i= index;let last=last" >
                            
                                      <div class="row inputElement">  
                                        <div  class="col-md-3">  
                                          <input  maxlength="50" [(ngModel)]="vitaminItem.medications" #vitaminMedication="ngModel" name="vitaminMedications{{i}}" placeholder="Vitamin" type="text" class="form-control " (change)="checkValidations()" >
                                          <mat-error class="text-xs" *ngIf="vitaminItem.noMedicationError">
                                            This field is required
                                          </mat-error>
                                        </div>
                            
                                        <div  class="col-md-3">  
                                          <input  maxlength="50" [(ngModel)]="vitaminItem.dosage" #vitaminDosage="ngModel" name="vitaminDosage{{i}}" placeholder="Dosage" type="text"  class="form-control "  (change)="checkValidations()">
                                          <mat-error class="text-xs" *ngIf="vitaminItem.noDosageError">
                                            This field is required
                                          </mat-error>
                                        </div>
                            
                                        <div  class="col-md-3">  
                                          <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                                          [(ngModel)]="vitaminItem.frequency" #frequency="ngModel" name="frequencyV{{i}}" (change)="checkValidations()">
                                            <option value="null">Frequency</option>
                                            <option value="1">q.a.m. Every morning</option>
                                            <option value="2">q.p.m. Every evening</option>
                                            <option value="3">q.h.s Every night at bedtime</option>
                                            <option value="4">b.i.d. Twice daily</option>
                                            <option value="5">t.i.d. 3 times a day</option>
                                            <option value="6">q.i.d 4 times a day</option>
                                            <option value="7">q.o.d Every other day</option>
                                            <option value="8">q.w. Every week</option>
                                            <option value="9">p.r.n. Daily as needed</option>
                                          </select>
                                          <mat-error class="text-xs" *ngIf="vitaminItem.noFrequencyError">
                                            This field is required
                                          </mat-error>
                                        </div>
                            
                                        <div  class="col-md-3">  
                                          <input  maxlength="50" [(ngModel)]="vitaminItem.sideEffects" #vitaminSideEffects="ngModel" name="vitaminSideEffects{{i}}" placeholder="Side effects" type="text" class="form-control " (change)="checkValidations()" >
                                          <mat-error class="text-xs" *ngIf="vitaminItem.noSideEffectsError">
                                            This field is required
                                          </mat-error>
                                        </div>
                            
                                      </div>
                            
                                      <a  class="add " style="cursor: pointer;" (click)="addVitamins_click()" *ngIf="last" >
                                        <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
                                      </a>
                            
                                      <a  class="remove" style="cursor: pointer;" (click)="removeVitamin_click(vitaminItem,i)" *ngIf="_dtoVitamins.length!=1">  
                                        <i  class="fa fa-lg fa-minus-circle"></i>  
                                        <span  class="sr-only">Remove</span>  
                                      </a>
                            
                                    </div> 
                                  </div>
                            
                              </div>
                            </div>
                              <br>
                              <div class="col-md-12 text-right clearfix mt-2" *ngIf="pageId != 1">
                                  <button type="button" class="btn btn-cancel float-right" title="Cancel"
                                      (click)="resetChecklistform()">Cancel</button>
                                      <button type="submit" form="formchecklist" class="btn primary-btn-filled float-right mr-2"
                                      title="Submit">Submit</button>
                              </div>
                        </div>
                    </div>
                </div>
            </div>

        <!-- <div *ngIf="typeId==2">
        <div class="col-md-12 mt-3 mb-3" *ngIf="lstIntakeChecklist?.answers[medicationIndex].isAnswer==true">
            <h2 class="head-section">Medication</h2>
            <div class="medication-dosage">  
              <div  class="row inputElement">  
                <div  class="col-md-3">  
                  <label >Medications</label>  
                </div>
      
                <div  class="col-md-3">  
                  <label >Dosage</label>  
                </div>
      
                <div  class="col-md-3">  
                  <label>Frequency</label>  
                </div>
      
                <div  class="col-md-3">  
                  <label >Side effects</label>  
                </div>
      
              </div>
      
              <div  class="loop-section position-relative mb-2" *ngFor="let item of _dtoMedications;let i= index;let last=last" >
      
                <div class="row inputElement">  
                  <div  class="col-md-3">  
                    <input  maxlength="50" [(ngModel)]="item.medications" #medications="ngModel" name="medications{{i}}" placeholder="Medicine" type="text" required="" class="form-control "  >
                  </div>
      
                  <div  class="col-md-3">  
                    <input  maxlength="50" [(ngModel)]="item.dosage" #dosage="ngModel" name="dosage{{i}}" placeholder="Dosage" type="text" required="" class="form-control "  >
                  </div>
      
                  <div  class="col-md-3">  
                    <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                    [(ngModel)]="item.frequency" #frequency="ngModel" name="frequency{{i}}">
                      <option value="null">Frequency</option>
                      <option value="1">Daily</option>
                      <option value="2">Weekly</option>
                      <option value="3">Monthly</option>
                    </select>
                  </div>
      
                  <div  class="col-md-3">  
                    <input  maxlength="50" [(ngModel)]="item.sideEffects" #sideEffects="ngModel" name="sideEffects{{i}}" placeholder="Side effects" type="text" required="" class="form-control "  >
                  </div>
      
                </div>
      
                <a  class="add " style="cursor: pointer;" (click)="addMedicine_click()" *ngIf="last" >
                  <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
                </a>
      
                <a  class="remove" style="cursor: pointer;" (click)="removeMedicine_click(item,i)">  
                  <i  class="fa fa-lg fa-minus-circle"></i>  
                  <span  class="sr-only">Remove</span>  
                </a>
      
              </div>    
            </div>
      
        </div>
    
        <div class="col-md-12 mt-3 mb-3" *ngIf="lstIntakeChecklist?.answers[vitaminIndex].isAnswer==true" >
            <h2 class="head-section">Vitamins</h2>
            <div class="medication-dosage">  
              <div  class="row inputElement">
      
                <div  class="col-md-3">
      
                  <label >Medications</label>
      
                </div>
      
                <div  class="col-md-3">
      
                  <label >Dosage</label>
      
                </div>
      
                <div  class="col-md-3">
      
                  <label>Frequency</label>
      
                </div>
      
                <div  class="col-md-3">
      
                  <label >Side effects</label>
      
                </div>
      
              </div>
      
              <div  class="loop-section position-relative mb-2" *ngFor="let vitaminItem of _dtoVitamins;let i= index;let last=last" >
      
                <div class="row inputElement">  
                  <div  class="col-md-3">  
                    <input  maxlength="50" [(ngModel)]="vitaminItem.medications" #vitaminMedication="ngModel" name="vitaminMedications{{i}}" placeholder="Medicine" type="text" required="" class="form-control "  >
                  </div>
      
                  <div  class="col-md-3">  
                    <input  maxlength="50" [(ngModel)]="vitaminItem.dosage" #vitaminDosage="ngModel" name="vitaminDosage{{i}}" placeholder="Dosage" type="text" required="" class="form-control "  >
                  </div>
      
                  <div  class="col-md-3">  
                    <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                    [(ngModel)]="vitaminItem.frequency" #frequency="ngModel" name="frequency{{i}}">
                      <option value="null">Frequency</option>
                      <option value="1">Daily</option>
                      <option value="2">Weekly</option>
                      <option value="3">Monthly</option>
                    </select>
                  </div>
      
                  <div  class="col-md-3">  
                    <input  maxlength="50" [(ngModel)]="vitaminItem.sideEffects" #vitaminSideEffects="ngModel" name="vitaminSideEffects{{i}}" placeholder="Side effects" type="text" required="" class="form-control "  >
                  </div>
      
                </div>
      
                <a  class="add " style="cursor: pointer;" (click)="addVitamins_click()" *ngIf="last" >
                  <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
                </a>
      
                <a  class="remove" style="cursor: pointer;" (click)="removeVitamin_click(vitaminItem,i)">  
                  <i  class="fa fa-lg fa-minus-circle"></i>  
                  <span  class="sr-only">Remove</span>  
                </a>
      
              </div> 
            </div>
      
        </div>
      </div>
        <br>
        <div class="col-md-12 text-right clearfix mt-2" *ngIf="pageId != 1">
            <button type="button" class="btn btn-cancel float-right" title="Cancel"
                (click)="resetChecklistform()">Cancel</button>
            <button type="submit" form="formchecklist" class="btn primary-btn-filled float-right mr-2"
                title="Submit">Submit</button>
        </div> -->
</div>
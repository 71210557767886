import { map } from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { TreatmentDiagosis } from 'src/app/_models/_Treatment/_diagnosis';
import { PatientService } from 'src/app/patient/patient.service';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';

@Component({
  selector: 'app-treatmentmodality',
  templateUrl: './treatmentmodality.component.html',
  styleUrls: ['./treatmentmodality.component.scss']
})
export class TreatmentmodalityComponent implements OnInit {
  @ViewChild('frmModality') frmModality: NgForm;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @Input() patientAccountId: number;
  @Input() patientInfoId: number;
  chmaTreatmentSummaryId: number;
  _dtoModality: any = [];
  ddlTherapy: any;
  ddlFrequency: any;
  ddlCategory: any;
  ddlappoinments: any;
  ddllocation: any;
  intakeId: number;
  appointmentTypesId: number;
  meetingCategoryId: number;
  meetingLocationId: number;
  meetingDateBlocks: any = [];
  meetingTimeslots = [];
  meetingdates = [];
  roleId: any;
  SelectArray=[false,false,false]
  roleFront = constantVariables.role.FrontDesk;
  roleAdmin = constantVariables.role.Admin;
  dtoTreatmentDiagnosis: any = [];
  TreatmentList = [];
  NumberOfTheraphy=8;
  isModalityFormDisabled:boolean=false;
  roleAccess: RoelAccess;
  constructor(public global: Global, public treatmentservice: TreatmentService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public patientservice: PatientService, public settingsService: SettingsService,public roleaccessservice:PageAccessService) {
      
    }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.roleId = Number(localStorage.getItem("roleId"));
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.account).replace(/"/g, ""));
        this.chmaTreatmentSummaryId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patient).replace(/"/g, ""));
      }
      this.getTreatmentPlanMaster();
      this.getMeetingCategories();

      this.getMeetingLocation();
      this.pushmeetingdates();
      if(this.roleId==1){
        this.isModalityFormDisabled=true;
      }
    //  this.getTreatmentPlanDiagnosis();
    
    });

    this.treatmentservice.InitModalityPage.subscribe(res=>{
      this.getTreatmentPlanDiagnosis();
    });
    const pageid = constantVariables.pagelists.TreatmentPlan;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
  }

  onAppoinmentChange(item, event) {

    if (item.frequency == 2 && item.availableDays != "") {
      this.meetingDateBlocks = item.availableDays.split(',');
    } else {
      this.meetingDateBlocks = [];
    }
    this.meetingdates = [];
    this.pushmeetingdates();
  }

  getMeetingLocation() {
    this.spinnerservice.show();
    this.settingsService.getMeetinglocation().subscribe(
      res => {
        this.spinnerservice.hide();
        this.ddllocation = res;

      },
      error =>
        this.spinnerservice.hide()
    );
  }


  pushmeetingdates() {
    for (let i = 0; i < 2; i++) {
      this.meetingdates.push({
        meetingScheduleTimeId: 0,
        meetingScheduleId: 0,
        status: 0,
        scheduleDate: null,
        timeSlotId: null,
        fromTime: null,
        toTime: null
      });
    }
  }

  getMeetingCategories() {
    this.settingsService.getMeetingCategories().subscribe(
      res => {
        this.ddlCategory = res.meetingCategory;
        this.intakeId = res.intakeId;
        if (res.intakeId != 0) {
          this.meetingCategoryId = this.ddlCategory.filter(x => x.meetingCategoryId == this.intakeId)[0].meetingCategoryId;
          this.onChangeMtgCatgry(this.meetingCategoryId);

        }

      },
      error =>
        this.spinnerservice.hide()
    );
  }


  onChangeMtgCatgry(event) {
    this.patientservice.getAppointmentTypes(event).subscribe(
      res => {
        this.ddlappoinments = res;
        if (this.intakeId == 1) {
          this.appointmentTypesId = this.ddlappoinments.filter(x => x.typeName == "Intake Schedule")[0].appointmentTypesId;
          if (this.ddlappoinments.filter(x => x.appointmentTypesId == this.appointmentTypesId)[0].frequency == 2) {

            this.meetingDateBlocks = this.ddlappoinments.filter(x => x.appointmentTypesId == this.appointmentTypesId)[0].availableDays.split(",");
          }
        }
      },
      error =>
        this.spinnerservice.hide()
    );
  }


  getTreatmentPlanDiagnosis() {
    this.TreatmentList = [1,2,3,4,7,8,11,12];
    this.spinnerservice.show();
    this.treatmentservice.getTreatmentPlanDiagnosis(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.dtoTreatmentDiagnosis = res.dtoTreatmentDiagnosis
        if (res.dtoTreatmentDiagnosis && res.dtoTreatmentDiagnosis.length > 0) {
        //  console.log(res.dtoTreatmentModality)
          if (res.dtoTreatmentModality.length > 0 ) {
            this._dtoModality = res.dtoTreatmentModality;

              for(let i in this._dtoModality){
                this.TreatmentList = this.TreatmentList.filter(x=>x != this._dtoModality[i].therapyId )
              }
              this.treatmentservice.getSuggestedGoals.next(false)
            this._dtoModality.map(x=>{
              if(x.therapyId !=0){
                x.theraphyName=this.ddlTherapy.filter(y=>y.TherapyId==x.therapyId)[0].TherapyName;
              }
            }
            )
          // console.log(res.dtoTreatmentModality)
            if(this._dtoModality.length<this.NumberOfTheraphy){
               this.appendModality(this.NumberOfTheraphy - this._dtoModality.length);

              }
              else if(this._dtoModality.length=this.NumberOfTheraphy){
                this.SelectArray = [];
                let modalitylength = this._dtoModality.length
                for(let j=0;j<modalitylength;j++){
                  this.SelectArray.push(true)
                }
                
              }
              // else if(this._dtoModality.length==2){
              // this.appendModality(1)
              //   this.SelectArray = [true,true,false,false]


              // }
              // else{
              //   this.SelectArray = [true,true,true]
              // }
          }
          else{
             this.appendModality(this.NumberOfTheraphy )
           // console.log("else part")
          }


        }else {
          this.appendModality(this.NumberOfTheraphy)
        }
       
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  getTreatmentPlanMaster() {
    this.spinnerservice.show();
    this.treatmentservice.getTreatmentPlanMaster().subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.ddlTherapy = this.commonService.decrypdata(res.therapy);
        this.ddlFrequency = this.commonService.decrypdata(res.frequency);
        //To get diagnosis info once Therapy is got
        console.log(this.ddlTherapy)
      this.getTreatmentPlanDiagnosis();
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  appendModality(loop) {
    this.SelectArray = [];
    let modalitylength = this._dtoModality.length
    for(let j=0;j<modalitylength;j++){
      this.SelectArray.push(true)
    }
    if(modalitylength!=this.NumberOfTheraphy){
      for (let i = 0; i < loop; i++) {
      // console.log(this.TreatmentList[i],this.ddlTherapy[this.TreatmentList[i]-1].TherapyName)
        this._dtoModality.push({
          treatmentModalityId: 0,
          chmatreatmentSummaryId: this.chmaTreatmentSummaryId,
          patientAccountId: this.patientAccountId,
          therapyId: this.TreatmentList[i],
          theraphyName: this.ddlTherapy.filter(x=>x.TherapyId==this.TreatmentList[i])[0].TherapyName,
          theraphyWeek: null,
          theraphyWeekShot: null,
          frequencyId: null,
          appointmentTypesId: null,
          meetingLocationId: null,
        })
        this.SelectArray.push(false)
      }
   
    }
  }
  removemodality(data, index) {
    if (data.treatmentModalityId != 0) {
      this.removeTreatmentModality(data);
    }
    this._dtoModality.splice(index, 1);
  }
  removeTreatmentModality(data) {
    this.treatmentservice.removeTreatmentModality(data.treatmentModalityId).subscribe((res: any) => {
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  postModality(frmModality: NgForm) {
    // this.getTreatmentPlanDiagnosis();
    if (!frmModality.form.valid){
      this.snackbar.error("Please fill mandatory fields")
      return;
    }
    if (this.dtoTreatmentDiagnosis.length == 0) {
      this.snackbar.error("Please complete the diagnosis information");
      return
    }
    else{
    let postDtomodality=[]
   for(let i in this.SelectArray){
     if(this.SelectArray[i]){
        postDtomodality.push(this._dtoModality[i])
     }
     else{
       if(this._dtoModality[i].treatmentModalityId!=0){
        this._dtoModality[i].isActive = false;
        postDtomodality.push(this._dtoModality[i])
       }
     }
   }
  // console.log(postDtomodality)
   // const dataToEncrypt = this.commonService.DataToEncrypt(postDtomodality, false);
    this.spinnerservice.show();
    this.treatmentservice.postTreatmentModality(postDtomodality).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.frmModality.resetForm();
        this.getTreatmentPlanMaster();
        this.treatmentservice.TreatmentPlanStatus.next(false)
         },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
    }
  }
  OnCheckboxclick(i:any,event:any){
    //console.log(i)
    //console.log(JSON.parse(event.checked))
    this.SelectArray[i]= JSON.parse(event.checked)
   // console.log(this.SelectArray)
  }


  //For validation message in modality after selecting null weeks
  OnTherapyWeekChange(i,event){  
    if(event == 'null'){
      this._dtoModality[i].theraphyWeek = null
    }else{
      this._dtoModality[i].theraphyWeek = +event
    }
  }
  OnTherapyWeekShotChange(i,event){  
    if(event == 'null'){
      this._dtoModality[i].theraphyWeekShot = null
    }else{
      this._dtoModality[i].theraphyWeekShot = +event
    }
  }
  generatePdf()
  {
    this.spinnerservice.show();
    this.commonService.getModalityPdf(this.chmaTreatmentSummaryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
}

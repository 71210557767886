import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { TaskService } from 'src/app/common/pages/task/task.service';
import { Global } from 'src/app/global/global.model';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NoteComment } from 'src/app/_models/_Common/task';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { constantVariables } from 'src/app/constant/constant';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.scss']
})
export class NotesDialogComponent implements OnInit {
  isCreateTask: boolean = false;
  lstNoteComment: any;
  patientInfoId: any;
  notesId: any;
  DecrptedCommentlst:any;
  UserId: any;
  noteCommentInfo: NoteComment;
  dataSourceComments: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('commentpaginator') paginatorimport: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  noteids: any = [];
  constructor(public dialogRef: MatDialogRef<NotesDialogComponent>,private snackbar: SnackBarService, public taskService: TaskService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService,private dialog: MatDialog, public spinnerservice: NgxSpinnerService, public global: Global,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.noteCommentInfo = new NoteComment();
    this.patientInfoId = this.data.parentinfoId;
    this.notesId = this.data.notesId;
    this.getComment();
    this.UserId = Number(localStorage.getItem("id"));
  }


  
  onCreateTask(){
    this.isCreateTask = !this.isCreateTask;
  }

  resetnotesform() {
    this.noteCommentInfo = new NoteComment();
    this.isCreateTask = false;
  }

  onPageChange($event) {
    this.lstNoteComment = this.DecrptedCommentlst.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }

  saveCommentInfo(form: NgForm) {
    this.noteCommentInfo.patientInfoId = this.patientInfoId;
    this.noteCommentInfo.notesId = this.notesId;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.noteCommentInfo, false);
    this.taskService.postNoteComment(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        form.resetForm();
        this.snackbar.success(JSON.parse(res).message);
        this.getComment();
      }
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }


  onReadComment(item) {
    this.spinnerservice.show();
    this.taskService.readNoteComment(item.noteCommentId).subscribe(
      res => {
        this.spinnerservice.hide();
        this.snackbar.success(res.message);
        this.getComment();


      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);

      },
    );
  }


  onReadmultipleComment() {
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.noteids, false);
    this.taskService.readmultipleNotesComment(dataToEncrypt).subscribe(
      (res: any) => {
        this.noteids = [];
        this.snackbar.success(JSON.parse(res).message);
        this.getComment();
        this.noteids.hide();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);

      },
    );

  }


  getComment() {
    this.taskService.getNotesComment(this.patientInfoId, this.notesId).subscribe(
      (res: any) => {
        if (res) {
          this.DecrptedCommentlst = res;
          this.lstNoteComment = this.DecrptedCommentlst.slice(0, 5);
          console.log(this.lstNoteComment)
          this.dataSourceComments = new MatTableDataSource(this.DecrptedCommentlst);
          this.dataSourceComments.paginator = this.paginatorimport;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }


  cbklistcomment(event, item, index) {
    if (event.checked) {
      this.noteids.push(item.taskCommentId);
    } else {
      this.noteids.forEach((x, index) => {
        if (x === item.taskCommentId) this.noteids.splice(index, 1);
      });
    }
  }

}


import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
import * as moment from 'moment';
@Directive({
  selector: '[customdate][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: DateValidator, multi: true }
  ]
})
export class DateValidator implements Validator {
  @Input()  sDate:any;
  @Input()  eDate: any;
  validate(eDate) {
   
    if ((this.sDate != "" && eDate.value != null) ) {      
      if (!moment(this.sDate).isBefore(moment(eDate.value)) ) {
        return {"customdate": true}       
      }
    }
      return null
    
  }
}
<mat-form-field class="example-full-width custom-textarea mt-2">
    <textarea matInput cdkTextareaAutosize   cols="2" 
    [maxlength]="maxlength"
    [(ngModel)]="text"
      (input)="updateWordCount()"
      (focusin)="updateWordCount()"
      (focusout)="isShowCount = false"
      #explanation="ngModel"
      [name]="name"
      required></textarea>
   
    </mat-form-field>
    <div class="count-text" *ngIf="isShowCount">Remaining Characters: {{ wordCount }}</div>

<div class="eclaim-section">
    <div class="col-md-12">
      <div  class="d-flex w-100">
        <div  class="col-md-3 p-0">
          <div  class="search">
            <input  type="text" placeholder="Search" name="txt" class="search-input "  [(ngModel)]="searchclaimListtxt">
            <a  class="search-icon"><i  class="fa fa-search"></i>
            </a>
          </div>
        </div>
        <div  class="ml-auto">
          <div class="input-group-btn search-panel open">
            <button type="button" class="btn btn-default dropdown-toggle filter-btn-border" (click)="toggleStyle = !toggleStyle;" >
              <i class="fas fa-filter "></i> <span class="text-muted mr-1">Filters</span><i class="fas fa-caret-down p-0"></i>
            </button>
            
           
        </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-3 d-none" [ngClass]="{'filter-block' : toggleStyle}">
      <div class="row border bg-white">
        <div class="col-md-3  border">
          <!-- <div class="card-body">
            <div class="col-md-12 p-0 "> -->
              <div class="mt-2"> <div class="head-sub-filter"> Process Filters </div> </div>
              <div class="row">
                <div class="col-md-12 ">
                  <div class="head-sub-filter">Insurance Priority</div>
                </div>
                <div class="col-md-12">
                  <form>
                    <!-- <ng-container *ngFor="let item of drpInsPriority;let i=index">
                        <div class="check-custom">
                            <mat-checkbox class="example-margin" name="InsPriority{{i}}"   (change)="onInspriority($event,item)" >{{item.InsPriorityDesc}}</mat-checkbox> 
                        </div>
                   </ng-container> -->
                   <ul>
                    <li *ngFor="let itm of drpInsPriority;let i=index">
                     <div class="check-custom">
                     <input type="checkbox"  class="example-margin mr-1" name="InsPriority{{i}}"   value={{itm.InsPrId}} (change)="onInspriority($event)" ><span class="check-like-mat">{{itm.InsPriorityDesc}}</span>
                     </div> 
                   </li>
                  </ul>
                  </form>
                </div>
                <div class="col-md-12 ">
                  <div class="head-sub-filter">Status</div>
                </div>
                <div class="col-md-12">
                 <form>
                       <!-- <ng-container *ngFor="let itm of drpBillStatus;let i=index">
                                                      <div class="check-custom">
                          <mat-checkbox class="example-margin" name="billstatus{{i}}"   (change)="onBillStatus($event,itm)" >{{itm.BillingStatus}}</mat-checkbox> 
                      </div>
                 </ng-container> -->
                 <ul>
                   <li *ngFor="let itm of drpBillStatus;let i=index">
                    <div class="check-custom">
                    <input type="checkbox"  class="example-margin mr-1" name="billstatus{{i}}"   value={{itm.BsId}} (change)="onBillStatus($event)" ><span class="check-like-mat">{{itm.BillingStatus}}</span>
                    </div> 
                  </li>
                 </ul>
               </form>
                </div>
                <div class="col-md-12 ">
                  <div class="head-sub-filter">Form Type</div>

                </div>
                <div class="col-md-12 custom-radio">
                  <form>
                  <!-- <mat-radio-group aria-label="Select an option"> -->
                    <mat-radio-button id="rdocms" name="rdocmsub" [checked]="isCpt" [value] ="1" (click)="onCptUb04click(1)" class="mr-2">CMS 1500</mat-radio-button>
                    <mat-radio-button id="rdoub04" name="rdocmsub" [checked]="isUB04" [value] ="2" (click)="onCptUb04click(2)">UB04</mat-radio-button>
                  <!-- </mat-radio-group> -->
                  </form>
                </div>
                
              </div>

              
            <!-- </div>
            
      
            </div> -->
        </div>
        <div class="col-md-3 border">
          <!-- <div class="card-body"> -->
            <div class="mt-2"> <div class="head-sub-filter"> Date Filters </div> </div>
            <div class="col-md-12 custom-radio pl-0">
              <!-- <mat-radio-group aria-label="Select an option"> -->
                <mat-radio-button  id="rdodat" name="datdos" [checked]="isDos"  [value] ="1"  (click)="onDosDatFilter(1)">Date</mat-radio-button> &nbsp;
                <mat-radio-button  id="rdodos" name="datdos" [checked]="isDat"  [value] ="2"  (click)="onDosDatFilter(2)">DOS</mat-radio-button>
             <!-- </mat-radio-group> -->
            </div>
            <div class="form-row">

              <div class="form-group col-md-6" *ngIf="isDat">
                  <mat-form-field class="w-100">
                  <mat-label>Date</mat-label>
                  <input matInput [matDatepicker]="Datpcr"  name="DatFrom"  [max]="maxDate" (dateChange)="onChangedosfrom($event,false)"  >
                  <mat-datepicker-toggle matSuffix [for]="Datpcr"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #Datpcr></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-group col-md-6" *ngIf="isDos">
                <mat-form-field class="w-100">
                <mat-label>Dos From</mat-label>
                <input matInput [matDatepicker]="DosFrompcr"  name="DOSFrom"  [max]="maxDate"  (dateChange)="onChangedosfrom($event,true)"  >
                <mat-datepicker-toggle matSuffix [for]="DosFrompcr"
                ></mat-datepicker-toggle>
                <mat-datepicker #DosFrompcr></mat-datepicker>
              </mat-form-field>
            </div>
              <div class="form-group col-md-6 text-left"  *ngIf="isDos" >
                <mat-form-field class="w-100">
                  <mat-label>Dos To</mat-label>
                  <input matInput [matDatepicker]="DosTopcr"  name="DOSTo"  [max]="maxDate" (dateChange)="onChangedosTo($event)">
                  <mat-datepicker-toggle matSuffix [for]="DosTopcr" ></mat-datepicker-toggle>
                  <mat-datepicker #DosTopcr></mat-datepicker>
              </mat-form-field>
              </div>
              </div>
             <!-- </div>   -->
        </div>
        <div class="col-md-3 border">
          <div class="mt-2"> <div class="head-sub-filter"> Other Filters </div> </div>
          <div class="col-md-12 mb-3">
            <label class="control-label-new">Insurance Company</label>
              <!-- <select  class="form-control" >
                <option value="one">Insurance Company</option>
                <option value="two">Second option</option>
              </select> -->
              <select class="form-control"
              [(ngModel)]="selectedInsGrp"  name="bloc"  (change)="getCListFilterby()">
              <option value="0">All</option>
              <option *ngFor="let dg of drpInsuranceGrp" [value]="dg.InsuranceId">{{dg.InsuranceName}}</option> 
              </select>
             
          </div>
          <div class="col-md-12">
            <label class="control-label-new">Location</label>
            <select class="form-control"
            [(ngModel)]="selectedLocation"  name="bloc"  (change)="getCListFilterby()">
            <option value="0">All</option>
            <option *ngFor="let dg of drpLocation" [value]="dg.SfccMasterId">{{dg.LocationName}}</option> 
            </select>
         
          </div>
         
        </div>
        <div class="col-md-3 border">
          <div class="mt-2"> <div class="head-sub-filter"> Totals </div> </div>
          <ul class="mt-2">
            <li>
              <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                <div class="check-custom">
                <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit"><i class="fas fa-wifi"></i></span>Transmit</mat-checkbox>
              </div>
              <div class="ml-auto">32</div>
              </div>
            </li>
            <li>
              <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                <div class="check-custom">
                <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit-w"><i class="fas fa-wifi"></i></span>Transmit w/warnings</mat-checkbox>
              </div>
              <div class="ml-auto">0</div>
              </div>
            </li>
            <li>
              
              <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                <div class="check-custom">
                <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit"><i class="fas fa-print"></i></span>Print</mat-checkbox>
              </div>
              <div class="ml-auto">0</div>
              </div>
            </li>
            <li>
              <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                <div class="check-custom">
                <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit-w"><i class="fas fa-print"></i></span>Print w/warnings</mat-checkbox>
              </div>
              <div class="ml-auto">0</div>
              </div>
            </li>
            <li>
              <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                <div class="check-custom">
                <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit-error"><i class="fas fa-times"></i></span>Error or Info Missing</mat-checkbox>
              </div>
              <div class="ml-auto">0</div>
              </div>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                
                <span class="total-text">Total Tickets</span>
             
              <div class="ml-auto">0</div>
              </div>
            </li>
            <li>
              <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                
              <span class="total-text"> Total Patients</span>
             
              <div class="ml-auto">0</div>
              </div>
            </li>
            <li>
              <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                
                <span class="total-text"> Total Balance </span>
             
              <div class="ml-auto">0</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="w-100">
          <div class="col-md-12 table-responsive table-scroll-height thin-scroll">
    
            <table class="pl-table col-md-12 w-100">
              <thead class="pl-thead tall">
                <tr  class="text-left">
                  <td  class="text-muted text-xs p-2" style="width: 120px;">Claim ID</td>
                  <td  class="text-muted text-xs p-2" style="width: 120px;">Patient ID</td>
                  <td  class="text-muted text-xs p-2" style="width: 180px;">Patient Name</td>
                  <td  class="text-muted text-xs p-2" style="width: 160px;">Insurance Company</td>
                  <td  class="text-muted text-xs p-2" style="width: 143px;">Provider</td>
                  <td  class="text-muted text-xs p-2" style="width: 220px;">Insurance Coverage/Charges</td>
                  <td  class="text-muted text-xs p-2" style="width: 120px;">Receivables</td>
                  <td class="text-muted text-xs p-2" style="width: 150px;">Status</td>
                  <td  class="text-muted text-xs p-2" style="width: 150px;">Location</td>
                  <td  class="text-muted text-xs p-2" style="width: 150px;">DOS</td>
                  <td  class="text-muted text-xs p-2" style="width: 200px;">Claim Created Date</td>
                  <td  class="text-muted text-xs p-2" style="width: 120px;">Days Over</td>
                </tr>
              </thead>
              <tbody class="pl-tbody scroll">
                <tr class=" mb-1  text-left"  *ngFor="let item of claimLists | grdFilter:  searchclaimListtxt: 'claimLists';let i=index"  (click)="onCreateclaim(item)"
                >
                  <td class="p-2">{{item.BillId}}</td>
                  <td class="p-2">{{item.PatientAccountId}}</td>
                  <td class="p-2 ">{{item.PatientName}}</td>
                  <td class=" p-2">{{item.InsuranceName}}</td>
                  <td class="p-2 ">{{item.RpDescription}}</td>
                  <td class=" p-2">{{item.Amount}}</td>
                  <td class=" p-2">{{item.Receivables}}</td>
                  <td class="p-2 ">{{item.BillStatus}}</td>
                  <td class=" p-2">{{item.LocationName}}</td>
                  <td class=" p-2">{{item.DOSFrom | date:'MM/dd/yyyy'}}</td>
                  <td class=" p-2">{{item.CreatedDate | date:'MM/dd/yyyy' }}</td>
                  <td class="p-2">{{item.DaysOver}}</td>
                </tr>
              </tbody>
            </table>
        </div>
        </div>
        <!-- Filters -->
        <!-- <div class="col-md-3 d-none"  [ngClass]="{'filter-block' : toggleStyle}" >
          <div class="filter-right">
            <div class="card">
              <article class="filter-group m-0">
                  <header class="card-header">
                      <a href="#" data-toggle="collapse" data-target="#collapse_aside1" data-abc="true" aria-expanded="false" class="collapsed">
                          <i class="icon-control fa fa-chevron-down"></i>
                          <h6 class="title">Process Filters </h6>
                      </a>
                  </header>
                  <div class="filter-content collapse show" id="collapse_aside1" style="">
                    <div class="card-body">
                      <div class="col-md-12 p-0 ">
                        <div class="row">
                          <div class="col-md-12 ">
                            <div class="head-sub-filter">Insurance Priority</div>
                          </div>
                          <div class="col-md-12">
                            <form>
                             <ul>
                              <li *ngFor="let itm of drpInsPriority;let i=index">
                               <div class="check-custom">
                               <input type="checkbox"  class="example-margin mr-1" name="InsPriority{{i}}"   value={{itm.InsPrId}} (change)="onInspriority($event)" ><span class="check-like-mat">{{itm.InsPriorityDesc}}</span>
                               </div> 
                             </li>
                            </ul>
                            </form>
                          </div>
                          <div class="col-md-12 ">
                            <div class="head-sub-filter">Status</div>
                          </div>
                          <div class="col-md-12">
                           <form>
                           <ul>
                             <li *ngFor="let itm of drpBillStatus;let i=index">
                              <div class="check-custom">
                              <input type="checkbox"  class="example-margin mr-1" name="billstatus{{i}}"   value={{itm.BsId}} (change)="onBillStatus($event)" ><span class="check-like-mat">{{itm.BillingStatus}}</span>
                              </div> 
                            </li>
                           </ul>
                         </form>
                          </div>
                          <div class="col-md-12 ">
                            <div class="head-sub-filter">Form Type</div>
  
                          </div>
                          <div class="col-md-12 custom-radio">
                            <form>
                              <mat-radio-button id="rdocms" name="rdocmsub" [checked]="isCpt" [value] ="1" (click)="onCptUb04click(1)" class="mr-2">CMS 1500</mat-radio-button>
                              <mat-radio-button id="rdoub04" name="rdocmsub" [checked]="isUB04" [value] ="2" (click)="onCptUb04click(2)">UB04</mat-radio-button>
                            </form>
                          </div>
                          
                        </div>
  
                        
                      </div>
                      
                
                      </div>
                  </div>
              </article> 
              <article class=" filter-group m-0">
                  <header class="card-header">
                      <a href="#" data-toggle="collapse" data-target="#collapse_aside2" data-abc="true" aria-expanded="false" class="collapsed">
                          <i class="icon-control fa fa-chevron-down"></i>
                          <h6 class="title">Date Filters </h6>
                      </a>
                  </header>
                  <div class="filter-content collapse" id="collapse_aside2" >
                      <div class="card-body">
                        
                        <div class="col-md-12 custom-radio pl-0">
                            <mat-radio-button  id="rdodat" name="datdos" [checked]="isDos"  [value] ="1"  (click)="onDosDatFilter(1)">Date</mat-radio-button> &nbsp;
                            <mat-radio-button  id="rdodos" name="datdos" [checked]="isDat"  [value] ="2"  (click)="onDosDatFilter(2)">DOS</mat-radio-button>
                        </div>
                        <div class="form-row">
  
                          <div class="form-group col-md-6" *ngIf="isDat">
                              <mat-form-field class="w-100">
                              <mat-label>Date</mat-label>
                              <input matInput [matDatepicker]="Datpcr"  name="DatFrom"  [max]="maxDate" (dateChange)="onChangedosfrom($event,false)"  >
                              <mat-datepicker-toggle matSuffix [for]="Datpcr"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #Datpcr></mat-datepicker>
                            </mat-form-field>
                          </div>
                          <div class="form-group col-md-6" *ngIf="isDos">
                            <mat-form-field class="w-100">
                            <mat-label>Dos From</mat-label>
                            <input matInput [matDatepicker]="DosFrompcr"  name="DOSFrom"  [max]="maxDate"  (dateChange)="onChangedosfrom($event,true)"  >
                            <mat-datepicker-toggle matSuffix [for]="DosFrompcr"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #DosFrompcr></mat-datepicker>
                          </mat-form-field>
                        </div>
                          <div class="form-group col-md-6 text-left"  *ngIf="isDos" >
                            <mat-form-field class="w-100">
                              <mat-label>Dos To</mat-label>
                              <input matInput [matDatepicker]="DosTopcr"  name="DOSTo"  [max]="maxDate" (dateChange)="onChangedosTo($event)">
                              <mat-datepicker-toggle matSuffix [for]="DosTopcr" ></mat-datepicker-toggle>
                              <mat-datepicker #DosTopcr></mat-datepicker>
                          </mat-form-field>
                          </div>
                          </div>
                         </div>  
                  </div>
              </article>  
              <article class="filter-group m-0">
                  <header class="card-header">
                      <a href="#" data-toggle="collapse" data-target="#collapse_aside3" data-abc="true" aria-expanded="false" class="collapsed">
                          <i class="icon-control fa fa-chevron-down"></i>
                          <h6 class="title">Other Filters </h6>
                      </a>
                  </header>
                  <div class="filter-content collapse" id="collapse_aside3" style="">
                      <div class="card-body">
                        <div class="col-md-12 mb-3">
                          <label class="control-label-new">Insurance Company</label>
                            <select class="form-control"
                            [(ngModel)]="selectedInsGrp"  name="bloc"  (change)="getCListFilterby()">
                            <option value="0">All</option>
                            <option *ngFor="let dg of drpInsuranceGrp" [value]="dg.InsuranceId">{{dg.InsuranceName}}</option> 
                            </select>
                           
                        </div>
                        <div class="col-md-12">
                          <label class="control-label-new">Location</label>
                          <select class="form-control"
                          [(ngModel)]="selectedLocation"  name="bloc"  (change)="getCListFilterby()">
                          <option value="0">All</option>
                          <option *ngFor="let dg of drpLocation" [value]="dg.SfccMasterId">{{dg.LocationName}}</option> 
                          </select>
                       
                        </div>
                        
                        
                       
                      </div>  
                  </div>
              </article>  
              <article class="filter-group m-0">
                  <header class="card-header">
                      <a href="#" data-toggle="collapse" data-target="#collapse_aside4" data-abc="true" class="collapsed" aria-expanded="false">
                          <i class="icon-control fa fa-chevron-down"></i>
                          <h6 class="title">Totals </h6>
                      </a>
                  </header>
                  <div class="filter-content collapse" id="collapse_aside4" style="">
                      <div class="card-body p-2 pt-3">
                        <ul class=" "  >
                          <li>
                            <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                              <div class="check-custom">
                              <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit"><i class="fas fa-wifi"></i></span>Transmit</mat-checkbox>
                            </div>
                            <div class="ml-auto">32</div>
                            </div>
                          </li>
                          <li>
                            <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                              <div class="check-custom">
                              <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit-w"><i class="fas fa-wifi"></i></span>Transmit w/warnings</mat-checkbox>
                            </div>
                            <div class="ml-auto">0</div>
                            </div>
                          </li>
                          <li>
                            
                            <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                              <div class="check-custom">
                              <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit"><i class="fas fa-print"></i></span>Print</mat-checkbox>
                            </div>
                            <div class="ml-auto">0</div>
                            </div>
                          </li>
                          <li>
                            <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                              <div class="check-custom">
                              <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit-w"><i class="fas fa-print"></i></span>Print w/warnings</mat-checkbox>
                            </div>
                            <div class="ml-auto">0</div>
                            </div>
                          </li>
                          <li>
                            <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                              <div class="check-custom">
                              <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit-error"><i class="fas fa-times"></i></span>Error or Info Missing</mat-checkbox>
                            </div>
                            <div class="ml-auto">0</div>
                            </div>
                          </li>
                          <li><hr class="dropdown-divider"></li>
                          <li>
                            <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                              
                              <span class="total-text">Total Tickets</span>
                           
                            <div class="ml-auto">0</div>
                            </div>
                          </li>
                          <li>
                            <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                              
                            <span class="total-text"> Total Patients</span>
                           
                            <div class="ml-auto">0</div>
                            </div>
                          </li>
                          <li>
                            <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                              
                              <span class="total-text"> Total Balance </span>
                           
                            <div class="ml-auto">0</div>
                            </div>
                          </li>
                        </ul>
                      </div>   
                  </div>
              </article>  
          </div>
          </div>
         
        </div> -->
        <!-- Filters end-->

        <!-- <div class="col-md-4 d-none"  [ngClass]="{'filter-block' : toggleStyle}" >
          <div class=" menubar-new pt-0 mx-0 rounded-3 shadow overflow-hidden w-100" >
            <form class="p-2 mb-2 bg-light border-bottom">
              
              <div class="col-md-12 mb-2 p-0">
                <div class="row">
                  <div class="col-md-2 mt-3 pt-3">
                    <label class="d-label">Date</label>
                  </div>
                  <div class="col-md-5 ">
                    <label class="d-label mb-0">From</label>
                    <input type="Date" class="form-control" autocomplete="false" placeholder="From">
                  </div>
                  <div class="col-md-5 pl-0">
                    <label class="d-label mb-0">To</label>
                    <input type="Date" class="form-control" autocomplete="false" placeholder="To">
                  </div>
                </div>
              </div>
            <div class="col-md-12 mb-2 p-0">
              <div class="row">
                <div class="col-md-6">
                    <label  class="d-inline-block mr-2">Status</label>
                  <select class="w-100" 
                  [(ngModel)]="selectedBstatus"  name="bstatus"  (change)="getCListFilterby()">
                  <option value="0">All</option>
                  <option *ngFor="let dg of drpBillstatus" [value]="dg.value">{{dg.label}}</option> 
                  </select>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="w-100" >
                    <mat-label>Process</mat-label>
                    <mat-select>
                      <mat-option value="one">Completed</mat-option>
                      <mat-option value="two">Inprogress</mat-option>
                    </mat-select>
                  </mat-form-field>
                  
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-2 p-0">
              <div class="row">
                <div class="col-md-6">
                  <label  class="d-inline-block mr-2">Provider</label>
                  <select class="w-100" 
                  [(ngModel)]="selectedProvider"  name="provider"  (change)="getCListFilterby()">
                  <option value="0">All</option>
                  <option *ngFor="let i of drpProvider" [value]="i.Rprovider">{{i.RpDescription}}</option> 
                  </select>
                
                  </div>
                <div class="col-md-6">
                 
                  <label  class="d-inline-block mr-2">Location</label>
                  <select class="w-100" 
                  [(ngModel)]="selectedLocation"  name="bloc"  (change)="getCListFilterby()">
                  <option value="0">All</option>
                  <option *ngFor="let dg of drpLocation" [value]="dg.SfccMasterId">{{dg.LocationName}}</option> 
                  </select>
                   </div>
              </div>
            </div>
            <div class="col-md-12 mb-2 p-0">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="w-100" >
                    <mat-label>Payer Category</mat-label>
                    <mat-select>
                      <mat-option value="one">Completed</mat-option>
                      <mat-option value="two">Inprogress</mat-option>
                    </mat-select>
                  </mat-form-field>
                  </div>
                <div class="col-md-6">
                  <mat-form-field class="w-100" >
                    <mat-label>Insurance Group</mat-label>
                    <mat-select>
                      <mat-option value="one">Completed</mat-option>
                      <mat-option value="two">Inprogress</mat-option>
                    </mat-select>
                  </mat-form-field>
                  </div>
              </div>
            </div>
            <div class="col-md-12 mb-2 p-0">
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="w-100" >
                    <mat-label>Insurance Plan</mat-label>
                    <mat-select>
                      <mat-option value="one">Completed</mat-option>
                      <mat-option value="two">Inprogress</mat-option>
                    </mat-select>
                  </mat-form-field>
                    </div>
                <div class="col-md-6">
                   </div>
              </div>
            </div>
            </form>
            
            <div class="col-md-12 text-right p-0">
              <button mat-stroked-button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Apply</button>
              <button mat-stroked-button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2">Cancel</button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div> 
      <button style="color:#e9e0ec;" (click)="avTest()">AvailityDummyTest</button>
      <button style="color:#e0e6ec;" (click)="getMethodAvtest()">AvailityGetTest</button>
    
    </div>
  </div>

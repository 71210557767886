import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { PatientService } from 'src/app/patient/patient.service';
@Component({
  selector: 'app-intake-survey',
  templateUrl: './intake-survey.component.html',
  styleUrls: ['./intake-survey.component.scss']
})
export class IntakeSurveyComponent implements OnInit {
  @ViewChild('divQuestionPG') divQuestionPG: ElementRef;
  lstIntakeSurvey: any;
  patientInfoId: any;
  patientAccountId: any;
  cecklist = constantVariables.inTakeForms.Checklist;
  healthScreening = constantVariables.inTakeForms.HealthScreening;
  survey = constantVariables.inTakeForms.Survey;
  lastExaminationDate: any;
  surveyQuestions: any;
  queYesNo = constantVariables.questionType.YesNo;
  queMCSA = constantVariables.questionType.MCSA;
  queMCMA = constantVariables.questionType.MCMA;
  queDescriptive = constantVariables.questionType.Descriptive;
  queRatings = constantVariables.questionType.Ratings;
  questionAnswered: number;
  totalquestions: number;
  questionsIndex: number;
  isReqRating: boolean = false;
  isReqRadio: boolean = false;
  currentindex: any;
  activePage: number = 0;
  pageId: number;
  typeId:number;
  IsEnableExplaination:boolean =false;
  IsEnableExplainationYesOrNo:boolean=false;
  IsEnableExplainationMCMA:boolean=false;
  IsPatientAccount: boolean;
  patientName : any;
  patienname : any;
  constructor(public global: Global, public patientservice: PatientService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.patientName = localStorage.getItem("patientName");
    //this.getInTakeQuestions();
    this.questionsIndex = 0;
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.typeId = Number(this.commonService.FrontEndDecryption(params.tab).replace(/"/g, ""));
       
        if (params.pageid == 1) {
          this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.patientAccountId).replace(/"/g, ""));
          this.pageId = params.pageid;
        }
        if(params.isPatientAccount){
          this.IsPatientAccount = true;
        }
        if(params.patientName){
          this.patientName = this.commonService.FrontEndDecryption(params.patientName).replace(/"/g, "")
        }
      }
      this.getInTakeQuestions();
    });
  }
  getInTakeQuestions() {
    //this.spinnerservice.show();
    this.patientservice.getInTakeQuestions(this.survey, this.patientAccountId,0).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.lstIntakeSurvey = cloneDeep(res);
        //this.surveyQuestions = res.answers.details;
        this.questionAnswered = this.lstIntakeSurvey.inTakeProgress.noOfQuestions;
        this.totalquestions = this.lstIntakeSurvey.total;
        // if (this.lstIntakeSurvey.total == this.questionAnswered) {
        //   if (this.currentindex == undefined) {
        //     this.questionAnswered = 0;
        //     this.currentindex = 0;
        //   } else {
        //     this.currentindex = this.currentindex + 1;
        //     this.questionAnswered = this.currentindex;
        //     if (this.currentindex == this.totalquestions) {
        //       this.questionAnswered = 0;
        //       this.currentindex = 0;
        //     }
        //   }
        // }
        this.questionsIndex = this.totalquestions - 1;
        this.lstIntakeSurvey.answers.details.forEach((val: any, index: any)=> {
          this.lstIntakeSurvey.answers.details[index].options.forEach((val: any, key: any) => {
            if( this.lstIntakeSurvey.answers.details[index].options[key].optionIsAnswer &&  this.lstIntakeSurvey.answers.details[index].options[key].isAnswer){
             if(this.lstIntakeSurvey.answers.details[index].typeId == this.queMCSA){
              this.IsEnableExplaination =true
             }
             else if(this.lstIntakeSurvey.answers.details[index].typeId == this.queYesNo){
               this.IsEnableExplainationYesOrNo = true
             }
             else{
               this.IsEnableExplainationMCMA = true
             }
            }
           });
        });
      
        //this.activePage=this.questionAnswered;
        //this.questionAnswered = 0;
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  rbAnswer(index, opt) {
    this.lstIntakeSurvey.answers.details[index].options.forEach((val: any, key: any) => {
      this.lstIntakeSurvey.answers.details[index].options[key].isAnswer = false;
    });
    this.lstIntakeSurvey.answers.details[index].options[opt].isAnswer = true;
    if(this.lstIntakeSurvey.answers.details[index].typeId == this.queMCSA){
      if(this.lstIntakeSurvey.answers.details[index].options[opt].optionIsAnswer){
        this.IsEnableExplaination = true
      }
      else{
        this.IsEnableExplaination = false
      }
    }
    else{
      if(this.lstIntakeSurvey.answers.details[index].options[opt].optionIsAnswer){
        this.IsEnableExplainationYesOrNo = true
      }
      else{
        this.IsEnableExplainationYesOrNo = false
      }
    }
  
    this.isReqRadio = false;
  }
  cbkAnswer(index, opt, event) {
    this.IsEnableExplainationMCMA =false
    this.lstIntakeSurvey.answers.details[index].options[opt].isAnswer = !this.lstIntakeSurvey.answers.details[index].options[opt].isAnswer;
    this.lstIntakeSurvey.answers.details[index].options.forEach((val: any, key: any) => {
     if( this.lstIntakeSurvey.answers.details[index].options[key].optionIsAnswer &&  this.lstIntakeSurvey.answers.details[index].options[key].isAnswer){
      this.IsEnableExplainationMCMA =true
     }
    });
    this.isReqRadio = false;
  }
  onrating(index, opt, event) {
    this.lstIntakeSurvey.answers.details[index].options[opt].rating = event;
    this.isReqRating = false;
  }
  onNextQuestion(frmsurvey: NgForm, index, type) {
    if (this.lstIntakeSurvey.answers.details[index].options.filter(x => x.isAnswer).length > 0 || this.lstIntakeSurvey.answers.details[index].options[0].rating > 0 ||  this.lstIntakeSurvey.answers.details[index].options[0].description != '') {
      let obj = {
        Answer: this.lstIntakeSurvey.answers.details[index],
        InTakeProgress: this.lstIntakeSurvey.inTakeProgress,
        PatientAccountId: this.patientAccountId,
        Total: this.totalquestions
      }
      const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
      this.spinnerservice.show();
      this.patientservice.postIntakeSurvey(obj).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.resetsurvey();
          this.getInTakeQuestions();
          //this.questionAnswered = this.questionAnswered + 1;
          if((index+1) == this.lstIntakeSurvey.answers.details.length){
            this.activePage = 0;
           // this.router.navigate(['/patient-breifcase']);
         //  window.close();
          setTimeout(() =>  window.close(),2500); // 2500 is millisecond
          }
          else{
            this.activePage = this.activePage+1;
          }
          
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    } else {
      //this.questionAnswered = this.questionAnswered + 1;
      if((index+1) == this.lstIntakeSurvey.answers.details.length){
        this.activePage = 0;
      }
      else{
        this.activePage = this.activePage+1;
      }
    }

  }
  onPrevQuestion() {
    this.activePage = this.activePage-1;
    this.questionAnswered = this.questionAnswered - 1;
    //this.activePage = this.activePage+1
  }
  onPreviewNextQuestion() {
    if((this.activePage+1) == this.lstIntakeSurvey.answers.details.length){
      this.activePage = 0;
    }
    else{
      this.activePage = this.activePage+1;
    }
  }
  resetsurvey() {
    this.isReqRadio = false;
    this.isReqRating = false;
  }
  onClickPage(pageNumber: number): void {
    if (pageNumber >= 0 && pageNumber <= this.lstIntakeSurvey?.answers?.details.length - 1) {
      this.activePage = pageNumber;
      // this.onPageChange.emit(this.activePage);  
      this.questionAnswered = this.activePage;
    }
  }
  scrollLeft() {
    //document.getElementById('divQuestionPG').scrollLeft -= 50;
    this.divQuestionPG.nativeElement.scrollTo({ left: (this.divQuestionPG.nativeElement.scrollLeft - 50), behavior: 'smooth' });
  }

  scrollRight() {
    //document.getElementById('divQuestionPG').scrollLeft += 50;
    this.divQuestionPG.nativeElement.scrollTo({ left: (this.divQuestionPG.nativeElement.scrollLeft + 50), behavior: 'smooth' });
  }
  
  onBackClick()
  {
    if(!this.IsPatientAccount){
      window.close();
    }
    else{
       this.router.navigate(['/patientslists'])
    }
    
  }
}

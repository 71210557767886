
<div class="top-btn-section clearfix mb-1 w-100" *ngIf="pageId == 1">    
    <div class="col-md-6 ml-auto mt-2 button-position pr-0">
        <!-- <button type="button" [routerLink]="['/patient']" class="btn primary-btn-outlined float-right mb-2"><i
                class="fas fa-angle-left pr-2"></i>Back</button> -->
                <button type="button"  (click)="onBackClick()"  class="btn primary-btn-outlined float-right mb-2"><i
                    class="fas fa-angle-left pr-2"></i>Back</button>
    </div>
</div>
<div class="consent-view-form mt-3">
    <div class="position-relative">
        <div class="top-btn-section clearfix">
            <h2 class="head-section mb-3 mt-0">Consent Requirements</h2>
            
        </div>
        <div class=""> <strong>Client Name : {{patientName}} </strong> </div>

        <div class="total-progress">
            <div class="progress" [attr.data-percentage]="consentCompleted">
                <span class="progress-left">
                    <span class="progress-bar"></span>
                </span>
                <span class="progress-right">
                    <span class="progress-bar"></span>
                </span>
                <div class="progress-value">
                    <div>
                        {{consentCompleted}}%<br>
                        <span>completed</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 mt-3 " >
        <div class="row">
            <div class="col-md-3 p-0">

                <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                    aria-orientation="vertical">
                    <ng-container>
                        <a class="nav-link mb-2 p-3 shadow" id="v-pills-home-tab" [ngClass]="{'active': esignTab == true && parentesignTab == true}" data-toggle="pill"
                            (click)="onloadEsign()" href="#v-pills-home" role="tab"
                            aria-controls="v-pills-home" aria-selected="true" >
                            <span class="font-weight-bold small text-uppercase text-truncate  d-block">
                                <span class="complete-icon">
                                   
                                </span>
                                E-SIGNATURE
                            </span>
                        </a>
                    </ng-container>
                    <ng-container>
                        <a class="nav-link mb-2 p-3 shadow" id="v-pills-home-tab" [ngClass]="{'active': paymentDetailsTab == true}" data-toggle="pill"
                            (click)="onloadPayment()" href="#v-pills-home" role="tab"
                            aria-controls="v-pills-home" aria-selected="true" >
                            <span class="font-weight-bold small text-uppercase text-truncate  d-block">
                                <span class="complete-icon">
                                   
                                </span>
                                PAYMENT DETAILS
                            </span>
                        </a>
                    </ng-container>
                    <ng-container  *ngIf="  lstAuthorizationConsent?.length>1">
                        <span *ngFor="let item of lstIntakeConsent;let i=index">


                        <a class="nav-link mb-2 p-3 shadow"  [ngClass]="{'active': selectedConsent == i}" id="v-pills-home-tab" data-toggle="pill"
                            (click)="onloadTemplate(item.consentTemplatesId,i,item.templateNameId ==2 ? lstAuthorizationConsent[0].clinicianTypeId : item.clinicianTypeId,item.templateName,0)" href="#v-pills-home" role="tab"
                            aria-controls="v-pills-home" aria-selected="true" *ngIf="item.clinicianTypeId== 0
                            ">
                            
                            <span class="font-weight-bold small text-uppercase text-truncate  d-block">
                                <span class="complete-icon">
                                    <i class="fas fa-check-circle mr-1" *ngIf="item.isVerified"></i>
                                </span>
                                {{item.templateName}}
                            </span>
                        </a>
                       </span>
                  
                    <!-- <a class="nav-link mb-2 p-3 shadow" [ngClass]="{'active': selectedConsent == i}" id="v-pills-home-tab" data-toggle="pill"
                    (click)="onloadTemplates(item.consentTemplatesId,i,item.clinicianTypeId)" href="#v-pills-home" role="tab"
                    aria-controls="v-pills-home" aria-selected="true" *ngIf="item.clinicianTypeId==0 && consentTemplatesId == 0">

                    <span class="font-weight-bold small text-uppercase text-truncate  d-block">
                        <span class="complete-icon">
                            <i class="fas fa-check-circle mr-1" *ngIf="item.isVerified"></i>
                        </span>
                        {{item.templateName}}
                    </span>
                </a> -->
            </ng-container>
            <ng-container *ngIf=" lstAuthorizationConsent?.length == 1" >
                <span *ngFor="let item of lstIntakeConsent;let i=index">
                <a class="nav-link mb-2 p-3 shadow"  [ngClass]="{'active': selectedConsent == i}" id="v-pills-home-tab" data-toggle="pill"
                    (click)="onloadTemplate(item.consentTemplatesId,i,item.clinicianTypeId,item.templateName,0)" href="#v-pills-home" role="tab"
                    aria-controls="v-pills-home" aria-selected="true" *ngIf="item.clinicianTypeId==0">
                     
                    <span class="font-weight-bold small text-uppercase text-truncate  d-block">
                        <span class="complete-icon">
                            <i class="fas fa-check-circle mr-1" *ngIf="item.isVerified"></i>
                        </span>
                        {{item.templateName}}
                    </span>
                </a>
            </span>
            
    </ng-container>

                </div>
            
            </div>
    
 

            <div class="col-md-9">
                <!-- Tabs content -->

                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade shadow rounded bg-white show active p-5" id="v-pills-home" role="tabpanel" *ngIf="!esignTab && !paymentDetailsTab && !parentesignTab"
                        aria-labelledby="v-pills-home-tab">
                        <h4 class="font-italic mb-4">{{templateData?.templateName}}</h4>
                        <div class="ql-container ql-snow" style="border-width: 0;"  [ngClass]="{'disabled':pageId == 1}">
                            <div class="ql-editor" (input)="ValidateError()" (click)="toggleHelp({},$event)" [innerHTML]="templateData?.templateContent">
                            </div>
                          </div>
                        <!-- <p class="font-italic text-muted mb-2" (click)="toggleHelp({},$event)" [innerHTML]="templateData?.templateContent"></p> -->
                        <div class="verified-section" >
                            <div class="update-btn-consent1 ml-4 clearfix" [ngClass]="{'disabled':pageId == 1}">
                                <button *ngIf="pageId != 1" type="button" class="btn primary-btn-filled float-right" title="Update Dynamic Field"
                                    (click)="postDynamicFields()">Save</button>                                    
                                <!-- <span class="check-custom d-flex pt-2 ">

                                    <mat-checkbox class="example-margin" [checked]="isVerified" [disabled]="isVerified"
                                        (change)="postUpdateVerified($event)" name="accessLevel">
                                        Mark as signed 
                                    </mat-checkbox>
                                </span> -->
                                <nav aria-label="Page navigation example" *ngIf="selectedConsent==1 && lstAuthorizationConsent.length>1" >
                                    <ul class="pagination" *ngIf="pageId != 1">
                                        <li class="page-item" *ngFor="let item of lstAuthorizationConsent;let i=index" >
                                            <a class="page-link" (click)="onloadTemplate_new(item.consentTemplatesId,1,item.clinicianTypeId,i)" [ngClass]="{'active-page' : item.isActive == true}">{{i+1}}</a></li>
                                        <!--<li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>-->
                                </ul>
                            </nav>
                            </div>
                            <nav aria-label="Page navigation example" *ngIf="selectedConsent==1 && lstAuthorizationConsent.length>1 && pageId==1" >
                                <ul class="pagination" >
                                    <li class="page-item" *ngFor="let item of lstAuthorizationConsent;let i=index" >
                                        <a class="page-link" (click)="onloadTemplate_new(item.consentTemplatesId,1,item.clinicianTypeId,i)" [ngClass]="{'active-page' : item.isActive == true}">{{i+1}}</a></li>
                                    <!--<li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>-->
                            </ul>
                        </nav>
            
            
                        </div>
                       
                    </div>
                   
                    <div class="col-md-12 mt-4 mb-4 p-0  lead-box pt-3 pb-3">
                        <div class="row">
                          <div class="col-md-5"  *ngIf="esignTab"  [ngClass]="{'disabled':pageId == 1}">
                            <div class="col-md-12">
                                Would you like to save your child's signature for future document signatures?                              <div>
                                <mat-radio-group [(ngModel)]="patientPrimary.IsSignFuture" #IsSignFuture="ngModel" name="IsSignFuture"
                                   (change)="onEsignOption($event.value)" required>
                                  <mat-radio-button [value]='true' class="mr-3 my-3"> Yes</mat-radio-button>
                                  <mat-radio-button [value]='false'> No</mat-radio-button>
                                </mat-radio-group>
                              </div>

                              
                            </div>
                            <div class="insurance-img" *ngIf="patientPrimary.IsSignFuture">
                              <div class="w-100 pl-3" *ngIf="updatedsignatureImg !== null && updatedsignatureImg !==''"><img [src]="updatedsignatureImg"
                                  style="height: 100px; width:auto;border: 1px solid #ccc;"></div>
                            </div>
                            <div class="col-md-12 text-right mt-3" *ngIf="patientPrimary.IsSignFuture">
                              <button type="button" (click)="openDialogbox()" 
                                class="btn primary-btn-filled primary-btn-m h-40 mr-2" type="button">
                                Add E-Sign
                              </button>
                            </div>
                            <div class="col-md-4 text-right" style="margin : 148px 200%">
                            <button type="submit"   (click)="nexttopaymentdetails()" mat-stroked-button
                            class="btn edit-btn primary-btn-m h-40 1">Submit</button></div>
                          </div>
                          <div class="col-md-5"  *ngIf="parentesignTab">
                            <div class="col-md-12">
                              Would you like to save your parent/legal guardian signature for future document signatures?
                              <div>
                                <mat-radio-group  [(ngModel)]="patientPrimary.IsParentSignFuture" #IsParentSignFuture="ngModel" name="IsParentSignFuture"
                                   (change)="onParentEsignOption()" required>
                                  <mat-radio-button [value]='true' class="mr-3 my-3"> Yes</mat-radio-button>
                                  <mat-radio-button [value]='false'> No</mat-radio-button>
                                </mat-radio-group>
                              </div>
                            </div>
                            <div class="insurance-img" *ngIf="patientPrimary.IsParentSignFuture">
                              <div class="w-100 pl-3" *ngIf="updatedparentsignatureImg !== null && updatedparentsignatureImg !==''"><img [src]="updatedparentsignatureImg"
                                  style="height: 100px; width:auto;border: 1px solid #ccc;"></div>
                            </div>
                            <div class="col-md-12 text-right mt-3" *ngIf="patientPrimary.IsParentSignFuture">
                              <button type="button" (click)="openDialogbox1()" 
                                class="btn primary-btn-filled primary-btn-m h-40 mr-2" type="button">
                                Add E-Sign
                              </button>
                            </div>
                            <!-- <div class="col-md-9 text-right" style="margin : 39px 240%">
                            <button type="submit"   (click)="nexttopaymentdetails()" mat-stroked-button
                            class="btn edit-btn primary-btn-m h-40 1">Submit</button></div> -->
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-md-12 history-section mt-4"
                            *ngIf="paymentDetailsTab"  [ngClass]="{'disabled':pageId == 1}">
                            <app-payment-details></app-payment-details>
                     </div>
               </div>
              
            </div>
            
        </div>

    </div>
</div>
<!-- 
<div class="modal " id="myModal" [style.display]="isDynamicField ? 'block' : 'none'">
    <div class="modal-dialog mb-2">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Update Dynamic Field</h6>
                <button aria-hidden="true" data-dismiss="modal" class="close" (click)="onCloseDynamicField()"
                    type="button"><i class="fas fa-times"></i></button>

            </div>
            <div class="modal-body">
                <form (ngSubmit)="formdynamic.form.valid  && postDynamicFields(formdynamic)" id="formdynamic"
                    autocomplete="off" #formdynamic="ngForm" novalidate>
                    <ng-container *ngFor="let item of templateFields;let i=index">
                        <div class="col-md-6">

                            <div *ngIf="item.fieldInputTypeId == 1">

                                <mat-form-field class="w-100">
                                    <mat-label>{{item.fieldName}}</mat-label>
                                    <input matInput type="text" [(ngModel)]="item.description"
                                        [ngModelOptions]="{standalone: true}" #description="ngModel" name="description"
                                        required>
                                    <mat-error *ngIf="formdynamic.submitted && description.invalid">
                                        <mat-error *ngIf="description.errors.required">This Field is required
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="item.fieldInputTypeId == 2">

                                <mat-form-field class="w-100">
                                    <mat-label>{{item.fieldName}}</mat-label>
                                    <input matInput [matDatepicker]="picker" readonly (click)="picker.open()"
                                        #userDate="ngModel" name="userDate" [(ngModel)]="item.userDate" required>
                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error *ngIf="formdynamic.submitted && userDate.invalid">
                                        <mat-error *ngIf="userDate.errors.required">This Field is required
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                    </ng-container>
                </form>
            </div>
            <div class="modal-footer text-center">
                <button type="submit" form="formdynamic" class="btn primary-btn-filled primary-btn-s ">Add</button>
                <button type="button" class="btn primary-btn-filled primary-btn-s " (click)="onCloseDynamicField()"
                    data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div> -->


  <div class="discharge-list pl-5">
    <div  class="d-flex">
      <div  class="head-section mt-3 mb-2">DISCHARGE SUMMARY</div>
      <div  class="ml-auto d-flex">
        
        <div  class="ml-2 mt-1">
          <button  mat-stroked-button class=" btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2 " (click)="OnStartDischarge()" *ngIf="roleAccess.isCreate"><i class="fas fa-notes-medical mr-2"></i>Start Discharge</button>
        </div>
      </div>
    </div>
    <div class="col-md-12 bg-white p-2">
      <div  class="d-flex" *ngIf="roleAccess.isView">
        <div class="col-md-3 pl-0">
          <div  class="search"><input  type="text" placeholder="Search By Client Name" name="searchDischargettxt" class="search-input " [(ngModel)]="searchDischargettxt" (keyup)="applyFilter($event)">
            <a  class="search-icon"><i  class="fa fa-search"></i></a>
          </div>
        </div>
        <div class="ml-auto mt-2">
          <span class="filter-icon mr-2">
            <i  class="fa fa-filter"></i>
          </span>
          <div class="filter-group d-inline-block">
            <label  class="d-inline-block mr-2">Clinician name</label>
            <select  name="Meeting" class="form-control d-inline-block status-filter " [(ngModel)]="SelectedClinician" (change)="OnFilter()">
              <option [value]="0">--Select --</option>
              <option [value]="1">All</option>
              <option *ngFor="let item of ddlstafflist" [value]="item.userId">{{item.staffName}}</option></select>
            </div>
            <div class="filter-group d-inline-block">
              <label  class="d-inline-block mr-2">Type of Discharge</label>
              <select  name="Meeting" class="form-control d-inline-block status-filter " [(ngModel)]="SelectedDischargeType" (change)="OnFilter()">
                <option [value]="0">--Select --</option>
                <option [value]="1">Discharge</option>
                <option [value]="2">Dropout</option></select>
              </div>
            <div class="filter-group d-inline-block">
              <label  class="d-inline-block mr-2">Discharge Date</label>
              <mat-form-field class="custom-border-date align-middle" appearance="outline">
                <mat-label>mm/yyyy</mat-label>
                <input matInput [matDatepicker]="datePicker" [(ngModel)]="SelectedDate" (dateChange)="OnFilter()" [max]="today">
                <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                <mat-datepicker #datePicker (monthSelected)="setMonthAndYear($event, datePicker)" startView="year"></mat-datepicker>
              </mat-form-field>
              <!-- <input type="date" startView="year" class="form-control d-inline-block status-filter" [(ngModel)]="SelectedDate" (change)="OnFilter()"> -->
              </div>
            </div>
          </div>
          <div class="col-md-12 p-0">
          <div class="table-custom discharge-table">
            <div class="table-responsive" *ngIf="roleAccess.isView">
            <table mat-table [dataSource]="DischargeSumDatasource" class="mat-elevation-z8 w-100">

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Client Name </th>
                <td mat-cell *matCellDef="let element"> {{element.clientName}} </td>
              </ng-container>
            
              <ng-container matColumnDef="dischar">
                <th mat-header-cell *matHeaderCellDef> Type of Discharge</th>
                <td mat-cell *matCellDef="let element"> {{element.dischargeType}} </td>
              </ng-container>
            
              <ng-container matColumnDef="Clinician">
                <th mat-header-cell *matHeaderCellDef> Clinician Name</th>
                <td mat-cell *matCellDef="let element"> {{element.staffName}} </td>
              </ng-container>
              <ng-container matColumnDef="Discharge">
                <th mat-header-cell *matHeaderCellDef> Discharge Date </th>
                <td mat-cell *matCellDef="let element"> {{element.dsDate| date:'MM/dd/yyyy'}} </td>
              </ng-container>
              <ng-container matColumnDef="DOB">
                <th mat-header-cell *matHeaderCellDef>Treatment Summary</th>
                <td mat-cell *matCellDef="let element"> <span class="text-success ml-4" *ngIf="element.treatmentSummaryId !=0"><i class="fas fa-check-circle"></i></span></td>
              </ng-container>
          
            
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> {{ roleAccess.isView ? 'Actions' : '' }} </th>
                <td mat-cell *matCellDef="let element"> <a  *ngIf="roleAccess.isView" class="discharge-btn"  (click)="OnViewDischargeSummary(element)"><i  class="far fa-eye"></i></a> </td>
              </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
            </table>
            <mat-paginator [pageSize]="20"[pageSizeOptions]="[ 10, 20, 50, 75, 100]" (page)="onPageChange($event)" showFirstLastButtons [pageSize]="5"></mat-paginator>
            </div>
            <div  *ngIf="!roleAccess.isView">
              <div class="msg-nomeet text-center">You do not have privilege to access this. Please contact admin</div>
          </div>
          </div>
        </div>
    </div>
  </div>
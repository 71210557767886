export class Goals {
    goalId: number;
    goalName: string;
    goalCatagoryId: any;
    diagnosisCode:any;
    definition:string;
    specificity:string;
    measure:string;
    measureTypeId:string;
    isActive: boolean;    
    measures: Array<GoalMeasure>;
    applicableTreatment:any;
}

export class GoalMeasure {
    goalBenchmarkId :number;
    goalId: number;  
    benchmarktitle:string;
    benchmarkdiscription:string;
    benchmarkunit:any
   
}
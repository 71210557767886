<div class="tab-content" id="v-pills-tabContent" *ngIf="roleAccess.isView">
    <div class="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-home" role="tabpanel"
      aria-labelledby="v-pills-home-tab" >
      <div  class="box-lead-head w-100">Transaction Form  </div>
      
   <form (ngSubmit)="Transactionfrm.form.valid  && PostTransactionfrm(Transactionfrm)" id="Transactionfrm" autocomplete="off" #Transactionfrm="ngForm"
   novalidate>
      <div class="col-md-12 ">
        <div class="row">
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Transaction Code</mat-label>
               <input matInput  maxlength="10"
                [(ngModel)]="dtoTransactionfrm.TransCode" #TransCode="ngModel" name="TransCode"
                [ngClass]="{ 'is-invalid': Transactionfrm.submitted && TransCode.invalid }"
                required>
                <mat-error *ngIf="Transactionfrm.submitted && TransCode.invalid">
                  <mat-error *ngIf="TransCode.errors.required">Transaction Code  is required</mat-error>
                </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Transaction Type</mat-label>
              	 <mat-select   name="Tid" [(ngModel)]="dtoTransactionfrm.TranType" #Tid="ngModel"
                 [ngClass]="{ 'is-invalid': Transactionfrm.submitted && Tid.invalid }" required>
                  <mat-option *ngFor="let ttyp of transactionType" [value]="ttyp.Tid">{{ttyp.TtypeDescription}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="Transactionfrm.submitted && Tid.invalid">
                    <mat-error *ngIf="Tid.errors.required">Transaction Type is required</mat-error>
                  </mat-error>
                </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Type of Services</mat-label>
                <mat-select   name="typeofservice" [(ngModel)]="dtoTransactionfrm.TypesOfService" #TypesOfService="ngModel"
                [ngClass]="{ 'is-invalid': Transactionfrm.submitted && TypesOfService.invalid }" required>
                <mat-option *ngFor="let ts of typeofServices" [value]="ts.TsCode">{{ts.TypeofService}}</mat-option>
               </mat-select>
               <mat-error *ngIf="Transactionfrm.submitted && TypesOfService.invalid">
                <mat-error *ngIf="TypesOfService.errors.required">Type of Services is required</mat-error>
              </mat-error>
            </mat-form-field>
          </div>
          </div>
          <div class="row">
            <div class="col-md-4 mt-3">
              <mat-radio-button [checked]="dtoTransactionfrm.IsCpt"  (click)="onCptUb04click(1)" class="mr-4">CPT Code</mat-radio-button>
             <mat-radio-button [checked]="dtoTransactionfrm.IsUB04"  (click)="onCptUb04click(2)" >UB04</mat-radio-button>
            </div>
            <div class="col-md-4 CPT-val " *ngIf="dtoTransactionfrm.IsCpt">
    
              <mat-form-field class="w-100">
                <mat-label>CPT Code</mat-label>
                <mat-select   name="cpt" [(ngModel)]="dtoTransactionfrm.CptCode" #CptCode="ngModel">
                <mat-option *ngFor="let cp of cpt" [value]="cp.CptCode">{{cp.CptCode}} - {{cp.CptcodeDescription}}</mat-option>
                   </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-8 UB04-val " *ngIf="dtoTransactionfrm.IsUB04">
              <div class="row">
              <div class="col-md-6">
                <mat-form-field class="w-100">
                  <mat-label>Revenue Code</mat-label>
                    <mat-select   name="RevenuCode" [(ngModel)]="dtoTransactionfrm.RevenuCode" #RevenuCode="ngModel">
                    <mat-option *ngFor="let rc of revenueCode" [value]="rc.RevenueCode">{{rc.RevenueCode}}-{{rc.RevenueCodeDescription}}</mat-option>
                   </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
              
                <mat-form-field class="w-100">
                  <mat-label>HCPCS Code </mat-label>
                  <mat-select   name="hcpcsCode" [(ngModel)]="dtoTransactionfrm.HcpcsCode" #HcpcsCode="ngModel">
                    <mat-option *ngFor="let hc of hcpcsMaster" [value]="hc.HcpcsCode">{{hc.HcpcsCode}}-{{hc.HcpcsCodeDescription}}</mat-option>
                   </mat-select>
                </mat-form-field>
              </div>
            </div>
              
              
            </div>

          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field class="w-100">
                <mat-label>Units</mat-label>
                <input matInput maxlength="2" [(ngModel)]="dtoTransactionfrm.Units" customMin="1" #Units="ngModel"
                numeric  name="Units" [ngClass]="{ 'is-invalid': Transactionfrm.submitted && Units.invalid }" required/>
                <mat-error *ngIf="Transactionfrm.submitted && Units.invalid">
                <mat-error *ngIf="Units.errors.required">Units is required</mat-error>
                <mat-error *ngIf="Units.errors?.minlength">Please enter valid Units</mat-error>
              </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field class="w-100">
                <mat-label>Treatment Charge</mat-label>
                 <input matInput maxlength="4" [(ngModel)]="dtoTransactionfrm.Amount" customMin="2" #Amount="ngModel"
                        numeric  name="Amount" [ngClass]="{ 'is-invalid': Transactionfrm.submitted && Amount.invalid }" required/>
                      <mat-error *ngIf="Transactionfrm.submitted && Amount.invalid">
                      <mat-error *ngIf="Amount.errors.required">Amount is required</mat-error>
                </mat-error>

              </mat-form-field>
            </div>
            <div class="col-md-4"> 
              <mat-form-field class="w-100">
                <mat-label>Transaction description</mat-label>
                <input matInput maxlength="75" [(ngModel)]="dtoTransactionfrm.Tdescription"  #Tdescription="ngModel"
                 name="Tdescription" [ngClass]="{ 'is-invalid': Transactionfrm.submitted && Tdescription.invalid }" required/>
                <mat-error *ngIf="Transactionfrm.submitted && Tdescription.invalid">
                <mat-error *ngIf="Tdescription.errors.required">Transaction description is required</mat-error>
             
              </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-right mt-3"  *ngIf="roleAccess.isCreate || roleAccess.isEdit">
              <button type ="submit" mat-stroked-button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>
              <button type="button"  mat-stroked-button class="btn btn-cancel primary-btn-m h-40"  (click)="CancelClick()">Cancel</button>

            </div>
          </div>
          </div>
   </form>

           <div class="col-md-12  mt-2" *ngIf="roleAccess.isView">
          <!-- <div  class="box-lead-head ">Transaction - Details </div>  -->
            <!--search-->
            <div class="row">
              <div  class="col-md-4 mt-3">Transaction - Details </div>
              <div  class="col-md-3 ml-auto"> 
                <mat-form-field class="w-100">
                  <mat-label>Search transaction values</mat-label>
                  <input matInput type="text" (keyup)="searchTblVal($event.target.value)"/>
                </mat-form-field>
                
               </div>
              </div>
             <!--search end-->
            <div class="col-md-12 table-custom p-0">
              <div class="table-responsive">
                <mat-table #table [dataSource]="dataSource" matSort class="w-100">
                  <ng-container matColumnDef="sno">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="TransCode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Code </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.TransCode}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="TtypeDescription">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Type </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.TtypeDescription}} </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="TypeofService">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Type of Services </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.TypeofService}} </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="CptCode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>CPT Code </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.CptCode}} </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="RevenuCode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Revenue Code </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.RevenuCode}} </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="Hcpcscode">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> HCPCS Code </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.Hcpcscode}} </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="Units">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Units </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.Units}} </mat-cell>
                  </ng-container>
        
                  <ng-container matColumnDef="Amount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Amount </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.Amount}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="Tdescription">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Description </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.Tdescription}} </mat-cell>
                  </ng-container>
        

                  
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{ (roleAccess.isEdit) ? 'Actions' : '' }}  </mat-header-cell>
                    <mat-cell *matCellDef="let element;let i = index;">
                      <button type="button" mat-icon-button matTooltip="Edit" (click)="onListEdit(element)" color="primary"  *ngIf="roleAccess.isEdit">
                        <img src="../../../assets/images/editblue.png">
                      </button>
                      
                    </mat-cell>
                  </ng-container>
        
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        
                </mat-table>
               
              </div>
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

          </div>
  
      </div>
      </div>

      <div *ngIf="!roleAccess.isView">
        {{viewRightsMsg}}
        </div>
      

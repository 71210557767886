<div>
    
     
        <div class="d-flex w-100 mt-2">
       
          <h5 class="align-self-center">
            Activities
          </h5>
            <div class="ml-auto">
             
              <button type="button" class="btn primary-btn-outlined float-right" (click)="OnBackClick()"><i class="fas fa-angle-left pr-2"></i>Back</button>
            
                  </div>
        </div>
        <div class="d-flex met-info-top mt-3 mb-3 w-100">
          <i class="fas fa-user"></i>
          <div class="mr-4">Client Name 
            <span class="imp-info">{{clientName}}
  
          </span></div>
          <!-- <div  class="mr-4"> Appointment Date
            <span class="imp-info">{{_dtoPatientInfo?.startDate | date:'MM/dd/YYYY'}}</span>
          </div>
          <div  class="mr-4"> Appointment Time   <span class="imp-info">{{_dtoPatientInfo?.startTime | date:'hh:mm a'}}</span></div>
     -->
          </div>

        <!-- <div class="d-flex w-100 mt-2"> Client Name : {{clientName}}</div> -->
  
    <div class="col-md-12 auditClinicianTable table-custom p-0 mt-2">
      <div class="table-responsive" *ngIf="!isClinician">
        <mat-table #table [dataSource]="dataSource" matSort class="w-100">
          <ng-container matColumnDef="sno">
            <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="activity">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Activity </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ModuleName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="responsiblePerson">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Responsible Person </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ClinicianName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="startDate">

            <mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </mat-header-cell>
            <!-- <mat-cell *matCellDef="let element"> {{element.StartDate == null ? element.StartDate : global.globalUtctimeformat(element.StartDate) | date: 'MM/dd/yyyy' }}  </mat-cell> -->
            <mat-cell *matCellDef="let element"> {{element.StartDate | date: 'MM/dd/yyyy' }}  </mat-cell>

          </ng-container>
          <ng-container matColumnDef="completedDate">
            
            <mat-header-cell *matHeaderCellDef mat-sort-header> Completed Date </mat-header-cell>
            <!-- <mat-cell *matCellDef="let element"> {{ element.CreatedDate == null ? element.CreatedDate :  global.globalUtctimeformat(element.CreatedDate)  | date: 'MM/dd/yyyy' }} </mat-cell> -->
            <mat-cell *matCellDef="let element"> {{ element.CreatedDate | date: 'MM/dd/yyyy' }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.Status}} </mat-cell>
          </ng-container>

      
         
          <mat-header-row *matHeaderRowDef="displayedColumns1"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns1;"></mat-row>

        </mat-table>
      </div>
      <div class="table-responsive" *ngIf="isClinician">
        <mat-table #table [dataSource]="dataSource" matSort class="w-100">
          <ng-container matColumnDef="sno">
            <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="activity">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Activity </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ModuleName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="responsiblePerson">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Responsible Person </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ClinicianName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="startDate">

            <mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </mat-header-cell>
            <!-- <mat-cell *matCellDef="let element"> {{element.StartDate == null ? element.StartDate : global.globalUtctimeformat(element.StartDate) | date: 'MM/dd/yyyy' }}  </mat-cell> -->
            <mat-cell *matCellDef="let element"> {{element.StartDate | date: 'MM/dd/yyyy' }}  </mat-cell>

          </ng-container>
          <ng-container matColumnDef="completedDate">
            
            <mat-header-cell *matHeaderCellDef mat-sort-header> Completed Date </mat-header-cell>
            <!-- <mat-cell *matCellDef="let element"> {{ element.CreatedDate == null ? element.CreatedDate :  global.globalUtctimeformat(element.CreatedDate)  | date: 'MM/dd/yyyy' }} </mat-cell> -->
            <mat-cell *matCellDef="let element"> {{ element.CreatedDate | date: 'MM/dd/yyyy' }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.Status}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>{{ isClinician ? 'Actions' : '' }}  </mat-header-cell>
            <mat-cell *matCellDef="let element;let i = index;">
              <button type="button" mat-icon-button title="Edit" (click)="onListEdit(element)" color="primary" >
                <img src="../../../assets/images/editblue.png">
              </button>
             
              
            </mat-cell>
          </ng-container>
         
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

        </mat-table>
      </div>
      <div  *ngIf="auditDetailList?.length == 0">
        <div class="list-note w-100 mt-2 mb-2">
          <span>No records found</span>
        </div>
      </div>
    </div>
   
    <mat-paginator  [pageSize]="20" [pageSizeOptions]="[ 10, 20, 50, 75, 100]" showFirstLastButtons></mat-paginator>
  </div>

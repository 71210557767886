import { Component, OnInit,Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfigureForm } from 'src/app/_models/_Common/template';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { Global } from 'src/app/global/global.model';
import { TemplateService } from 'src/app/common/pages/template/template.service';
import { SiteLayoutComponent } from 'src/app/_Layout/site-layout/site-layout.component';

@Component({
  selector: 'app-form-rename-dialog',
  templateUrl: './form-rename-dialog.component.html',
  styleUrls: ['./form-rename-dialog.component.scss']
})
export class FormRenameDialogComponent implements OnInit {
  configureFormId : any;
  FormName : any;
  FormTypeId : any;
  IsQuestionExplaination:any
  NewFormName : ConfigureForm;
  IsGlobal:any;
  Location:any;
  type:number=0;
  skillTaughtMaster:any;
  constructor(public dialogRef: MatDialogRef<FormRenameDialogComponent>,private snackbar: SnackBarService, public templatesService: TemplateService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService,private dialog: MatDialog, public spinnerservice: NgxSpinnerService, public global: Global,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.NewFormName= new ConfigureForm();
    this.configureFormId = this.data.configureFormId;
    this.FormName = this.data.formName;
    this.FormTypeId = this.data.formTypeId;
    this.IsQuestionExplaination = this.data.isquestionExplanation;
    this.IsGlobal = this.data.isGlobal;
    this.Location = this.data.location;
    this.NewFormName.formName = this.data.formName;
    this.type = this.data.type;
    this.skillTaughtMaster = this.data.skillTaught;    
  }

  resetnotesform(){
    
  }

  saveFormName(form:NgForm){
  this.spinnerservice.show();
  this.NewFormName.configureFormId = this.configureFormId;
  this.NewFormName.formName = this.FormName;
  this.NewFormName.formTypeId = this.FormTypeId;
  this.NewFormName.isquestionExplanation = this.IsQuestionExplaination;
  this.NewFormName.location = this.Location;
  this.NewFormName.isGlobal = this.IsGlobal;
  const dataToEncrypt = this.commonService.DataToEncrypt(this.NewFormName, false);
  this.templatesService.postConfigureForm(dataToEncrypt).subscribe((res: any) => {
    if (res) {
      this.spinnerservice.hide();
      this.snackbar.success(JSON.parse(res).message);
      this.dialogRef.close();
    }
  },
    err => {
      this.spinnerservice.hide();
      this.snackbar.error('Library name already exists');
    }
    );
  }
  saveSkillTaughtForm(form:NgForm){
    this.spinnerservice.show();
    this.templatesService.postSkillTaughtCreateForm(this.commonService.DataToEncrypt(this.skillTaughtMaster,false)).subscribe((res)=>{
      this.snackbar.success(res);   
      this.spinnerservice.hide();
      this.dialogRef.close(); 
    },
    (err)=>{
      this.spinnerservice.hide();
    })
  }
}

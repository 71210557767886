export class chmaEditPatientDetails{
    patientAccountId:number;
    firstName:string;
    lastName:string;
    parentsFirstName:string;
    parentsLastName:string;
    dateOfBirth:Date;
    gradeLevel:number
    school:string;
    stateId:number;
    cityId:number;
    zipCode:number;
    streetAddress:string;
  }
<div class="top-btn-section clearfix srchtop" >
   
    <h2 class="head-section mb-2 mt-0 txtsize">Declined List</h2> 
   </div> 
   <div class="create-patients" >
    <div class="d-flex w-100 " >
    <div class="col-md-4 srchleft">
        <div  class="search ">
            <input  type="text" placeholder="Search By Client Name" name="" class="search-input " [(ngModel)]="searchpatientListtxt" (keyup)="OnSearchPatient()"><a  class="search-icon"><i  class="fa fa-search"></i></a></div>
    </div>
      
</div>
<div>
    <button class="btn back-btn-new mr-2 btnback" (click)="BacktoPatient()"><i
       class="fas fa-chevron-left mr-2"></i>Back</button>
 </div> 
<div class="list-group contact-group mt--14" > 
    <!-- | grdFilter:  searchpatientListtxt:'AdminPatientLists'; -->
    <a class="list-group-item" *ngFor="let item of lstPatient">
        <div class="col-md-12 p-0">
            <div class="row">
                <div class="col-md-4 ">
                    <div class="media">
                        <div class="pull-left">
                            <img class="rounded-circle"
                                src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                                alt="...">
                        </div>
                        <div class="media-body">
                            <h4 class="media-heading">{{item.FirstName}} {{item?.LastName}}  {{item.Age!=null?'-':''}} {{item.Age}}{{item.Age!=null?' yrs':''}} </h4>
                            <div class="media-content">
                                <ul class="list-unstyled">

                                    <li><i class="fas fa-mobile"></i> {{item?.ContactNo | mask: '(000)
                                        000-0000'}}</li>
                                    <li><i class="fas fa-envelope"></i> {{item?.Email}}</li>
                                    <li *ngIf="item.ClinicianName!=null"><span class="status-patient"> Primary Clinician:</span><span class="patient-info-value">{{item.ClinicianName}}</span></li>
                                    <!-- <li><span class="lead-stage-head stage-contact">{{item.LeadStage}}</span> </li> -->
                                    <!-- <li><span class="lead-stage-head stage-contact">Intake</span> </li> -->
                                    <li  class="mr-2 ml-auto d-flex"><span class="status-patient">Status</span>
                                        <span class="status-patient-value">{{item.PatientStatusName}}</span><a (click)="onShowStatusPopup(item)"  class="edit-status"><i class="far fa-edit"></i></a>                                      
                                    </li>
                                    <p class=" "><span class="reasonfull">Reason </span><span
                                        class="vreason">{{item?.Reason}} - <a class="newlink" (click)="commentsClick(item?.Comments)">View Comment</a></span></p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="">
                        <label class="program-heading">Program Status</label>
                        <!--Schedule Intake if Create intake is enabled-->
                        <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                            (click)="onIntakeSchedule(item)"
                            *ngIf="(item.ImmediateActionId == statusintake  || item.ImmediateActionId == statusintakeDeclined) &&
                            (item.ChmaTreatmentSummaryId == null) "
                            title="For Intake Schedule">
                            {{item.ImmediateActionId == statusintakeDeclined ? 'Re-Schedule Intake':'Schedule
                            Intake'}}
                        </button>
                            <!-- <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified" *ngIf="item.ChmaTreatmentSummaryId !== null" 
                            (click)="onScheduleModality(item)">Schedule Modality</button> -->
                            <!-- <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified" *ngIf="item.ChmaTreatmentSummaryId !== null" 
                            (click)="onRedirectTo(item,2)">{{ item.ImmediateActionId != statusPsyReschedule?'Schedule Modality':'Schedule Psychiatric Therapy'}}</button> -->
                            <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified" *ngIf="item.ChmaTreatmentSummaryId !== null" 
                            (click)="onRedirectTo(item,2)">Schedule Modality</button>
                        <button class="btn primary-btn-filled primary-btn-l mb-2 h-40 verified"
                            (click)="onIntakeSchedule(item)" *ngIf="item.ImmediateActionId == statusintakeAccepted"
                            title="Assign Staff For Intake">Assign Staff For Intake
                        </button>
                        <div
                            *ngIf="item.ImmediateActionId == statusintakePacket && item.MeetingStatus !=statusmeetingReSchedule && item.MeetingStatus !=statusrequestcancel && item.ChmaTreatmentSummaryId === null">
                            <div class="patient-forms">
                                <a (click)="onPrepareIntake(1,item)" title="Health Screening"><img
                                        src="../../../assets/images/health_screen.png" class="img-fluid"></a>
                                <a (click)="onPrepareIntake(4,item)" title="Consent Form"><img
                                        src="../../../assets/images/consent-new.png" class="img-fluid"></a>
                                <a (click)="onPrepareIntake(2,item)" title="Checklist"><img
                                        src="../../../assets/images/health_insurance.png" class="img-fluid"></a>
                                <a (click)="onPrepareIntake(3,item)" title="Survey"><img
                                        src="../../../assets/images/survey.png" class="img-fluid"></a>
                            </div>
                            <div class="w-100 float-left">
                                <div class="status-indicator">

                                    <ul class="progress-indicator pl-2">
                                        <li [ngClass]="{'completed': item.IsHealth}">
                                            <span class="bubble" title="Health Screening"></span>
                                        </li>
                                        <li
                                            [ngClass]="{'completed': item.Verified == item.Total }">
                                            <span class="bubble" title="Consent Form"></span>
                                        </li>
                                        <li [ngClass]="{'completed': item.IsMedication}">
                                            <span class="bubble" title="Checklist"></span>
                                        </li>
                                        <li [ngClass]="{'completed': item.IsSurvey}">
                                            <span class="bubble" title="Survey"></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <p *ngIf="item.ImmediateActionId != statusintake &&  item.PatientStatus!=Null"
                            [ngClass]="item.ImmediateActionId == statusintakeScheduled ? 'plain-message mb-1 w-100' : 'status-msg mb-1 w-100'">
                            {{item.PatientStatus}}
                            <!-- *ngIf="item.ImmediateActionId != statusintakePacket" -->
                            <span *ngIf="item.ImmediateActionId !=statusintakePacket">{{item.CreatedOn|
                                date: 'MM/dd/yyyy'}}
                                <div class=" text-right" >
                                    <button class="col text-center col-btn-dis"  role="button" title="Edit" (click)="onIntakeDateReSchedule(item)"
                                    *ngIf="!(item.ImmediateActionId == statusintake  || item.ImmediateActionId == statusintakeDeclined) &&
                                (item.ChmaTreatmentSummaryId == null) "><i class="far fa-edit"
                                       aria-hidden="true" ></i></button>
                                 </div>
                               
                            </span>
                           
                        </p>
                        <div class="text-danger" *ngIf="item.ImmediateActionId != statusintake ">
                            {{item.RescheduleDateTime }}   
                     </div>
                       
                        <div *ngIf="item.MeetingStatus ==statusmeetingReSchedule" class="w-100 float-left">
                            <p title="{{item.RescheduleReason}}">{{item.RescheduleReason}}</p>
                            <button class="btn btn-dashboard green-theme  mr-2" title="Accept"
                                (click)="onAcceptReschdule(item)">Accept</button>
                            <button class="btn btn-dashboard red-theme  mr-2" title="Deny"
                                (click)="onDeclineReschdule(item)">Deny</button>
                            <button class="btn btn-dashboard red-theme" title="Communicate Client"
                                (click)="onReturnLead(item)">Communicate Client</button>
                        </div>
                        <div *ngIf="item.MeetingStatus ==statusrequestcancel" class="w-100 float-left">
                           
                            <button class="btn btn-dashboard green-theme  mr-2" title="Accept"
                                (click)="onAcceptCancelIntake(item)">Cancel Intake</button>
                            <button class="btn btn-dashboard red-theme  mr-2" title="Deny"
                                (click)="onDeclineCancelIntake(item)">Deny</button>

                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="icons-section " >
                        <!-- <button class="col text-center col-btn-dis"><i class="far fa-edit"></i></button>
                        <button class="btn btn-patient"><i class="fas fa-briefcase mr-2"></i>Briefcase View</button> -->
                        <button class="btn btn-patient" (click)="onRedirectTo(item,1)"><i class="fas fa-briefcase mr-2" ></i>Briefcase View</button>
                        <!-- <button class="btn btn-patient" *ngIf="item.ChmaTreatmentSummaryId !== null" (click)="onScheduleModality(item,2)"><i class="far fa-calendar-check mr-2"></i>Schedule Modality</button> -->
                        <!-- <button class="btn btn-patient" *ngIf="item.ChmaTreatmentSummaryId !== null" (click)="onRedirectTo(item,2)"><i class="far fa-calendar-check mr-2"></i>Schedule Modality1</button> -->
                        <button class="btn btn-patient"  *ngIf="item.ChmaTreatmentSummaryId !== null" (click)="onRedirectTo(item,3)"><i class="fas fa-first-aid mr-2"></i> Treatment
                            Plan</button>
                            <button class="btn btn-patient" (click)="OnActivityClick(item)"><i class="fas fa-user-clock mr-1"></i> Activity</button>
                            <button class="btn btn-patient"  (click)="onPatientPayment(item)"><i class="far fa-credit-card mr-1"></i> Payment Details</button>
                            <button _ngcontent-gwg-c395="" class="btn btn-patient" (click)="OnPatientDashboard(item)"><i class="fas fa-tachometer-alt mr-1"></i> Client Dashboard</button>
                            <button _ngcontent-gwg-c395="" class="btn btn-patient" (click)="OnPatientInsurance(item)"><i class="fas fa-shield-alt"></i> Insurance Details</button>

                            <div class="input-group mb-1 custom-btn-go">
                                <span>Schedule</span>
                                <select id="drP{{item.PatientInfoId}}" class="form-control"   (change)="onScheduleModalityChange($event.target.value)"><option value="M" > Modality</option><option value="P"> Phone Call </option><option value="S"> School Meeting</option></select>
                                <div class="input-group-append">
                                  <button class="btn " type="button" id="Button3" (click)="onSelectedModality(item)"><i class="fas fa-arrow-right"></i></button>
                                </div>
                                
                             </div>
                             <div class="col-md-1">
                                <button _ngcontent-gwg-c395="" class="btn btn-patient clireturn" (click)="returnPatient(item.PatientInfoId)"><i class="fas fa-undo"></i> Resume </button>
                            </div>
                             
                    </div>
                    
                </div>
            </div>
        </div>

    </a>
    <mat-paginator class="pagination pagination-large" [pageSize]="20" (page)="onPageChange($event)" 
    [pageSizeOptions]="[ 10, 20, 50, 75, 100]">
</mat-paginator>
</div>
</div>
 <div class="comment-popup  cancel-tour">

    <div *ngIf="isCommentInformation">
    <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
      <div class="modal-dialog mat-dialog-container popupdcln" role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="cmdhdr">Comments</h6>
            
          </div>
          <div class="modal-body">
  <div >
    
    <div class="col-md-12">
      <div class="row mb-2">
      </div>
      <div *ngIf="isCommentInformationtext">
        <span class="modal-title pdgleft" >{{isCommentInformationtext}}</span>
     </div>
     <div *ngIf="!isCommentInformationtext">
        <span class="modal-title pdgleft">No comments</span>
     </div>
        <div class="col-md-2 btnok"  >
          <button mat-raised-button   
          class="btn btn-primary btn-lg w-100 btn primary-btn-filled primary-btn-l mb-2 h-40 smtbtn"  type="submit" 
          (click) = "closecomments()"  >ok</button>
        
        </div>
     
    </div>
  </div>
  </div>
  </div>
  </div>
  </div>
 </div>
   </div>
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TemplateService } from 'src/app/common/pages/template/template.service';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { GoalMeasure, Goals } from 'src/app/_models/_Common/_goals';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CommonService } from 'src/app/common/common.service';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss']
})
export class GoalsComponent implements OnInit {
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],                                       // remove formatting button


      ],

    },


  }
  displayedColumns = ['sno', 'goalName', 'period', 'relevance', 'status', 'actions'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  dataSourceCat: MatTableDataSource<any>;
  _dtoGoals: Goals;
  lstGoals: any = [];
  lstWeek: any = [];
  lsTimes: any = [];
  isEditGoals: boolean = false;
  isView = false;
  isEdit = false;
  isDelete = false;
  isCreate = false;
  roleaccess: any;
  ddlGoalCatagory: any;
  ddlMeasureType: any;
  ddldiagnosiscode : any;
  goalId:any;
  ddlTherapy: any;
  Aggregatedpercentagescore=constantVariables.MeasureTypeName.Aggregatedpercentagescore;
  KPIprogresslinked=constantVariables.MeasureTypeName.KPIprogresslinked;
  Qualitative=constantVariables.MeasureTypeName.Qualitative;
  PercentageBased = constantVariables.MeasureTypeName.Percentagebased;
  Timebased = constantVariables.MeasureTypeName.Timebased;
  allMeasures: any[];
  SelectAllDiagnosis: boolean=false;
  onpostgoal: boolean;
  roleAccess: any;
  constructor(public tempService: TemplateService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, public commonService: CommonService,
    private router: Router, private route: ActivatedRoute, public spinnerservice: NgxSpinnerService
    ,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Goals;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
        this.isCreate = this.roleAccess.isCreate;
      this.isView = this.roleAccess.isView;
      this.isEdit = this.roleAccess.isEdit;
      this.isDelete = this.roleAccess.isDelete;
      this.getDiagnosisCodeMster();
    this.getGoalsMaster();
    this._dtoGoals = new Goals();
    this.addGoal();
    this.setWeeks();
    this.setTimeTypes();
    this.getTherapylist();
    var access = JSON.parse(localStorage.getItem('roleaccess'));
    // const pageid = constantVariables.pagelists.Goal;
    // this.roleaccess = access.details.find(x => x.pageId == pageid);
    // if (this.roleaccess != null) {
    //   this.isCreate = this.roleaccess.isCreate;
    //   this.isView = this.roleaccess.isView;
    //   this.isEdit = this.roleaccess.isEdit;
    //   this.isDelete = this.roleaccess.isDelete;
    // }
    // else {
    //   this.isCreate = true;
    //   this.isView = true;
    //   this.isEdit = true;
    //   this.isDelete = true;
    // }
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.goalId = Number(this.commonService.FrontEndDecryption(params.id));
        this.getGoals();
      }
    });
  
    
  
  }
  getDiagnosisCodeMster() {
    this.commonService
      .getDiagonosisCodeMaster()
      .subscribe((res: any) => {
        if (res.objInputString != null) {
          let fres = this.commonService.decrypdata(res.objInputString);
          if (fres.length > 0) {
            this.ddldiagnosiscode = fres;
        }
      }
      },
        err => {
          this.spinnerservice.hide();
        });
  }
  getGoals() {
    console.log(this._dtoGoals)
    this.tempService.getGoalsbyId(this.goalId).subscribe( 
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this._dtoGoals = cloneDeep(res);
          this._dtoGoals.diagnosisCode = this._dtoGoals.diagnosisCode.split(',');
          this._dtoGoals.applicableTreatment = this._dtoGoals.applicableTreatment.split(',').map(Number);
          this._dtoGoals.goalCatagoryId =this._dtoGoals.goalCatagoryId.split(',').map(Number);
         this.allMeasures = cloneDeep(res.measures)
        // console.log(this.allMeasures.filter(x=>x.goalMeasureTypeId == this._dtoGoals.measureTypeId))
         this._dtoGoals.measures = this.allMeasures
          this._dtoGoals.measure = res.measure
          console.log(this._dtoGoals)
          this.isEditGoals = true;
          this._dtoGoals.measureTypeId = this._dtoGoals.measureTypeId;
          if(this._dtoGoals.diagnosisCode.length==this.ddldiagnosiscode.length){
            this.SelectAllDiagnosis=true;
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getGoalsMaster() {
    this.tempService.getGoalsMaster().subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this.ddlGoalCatagory = res.goalCatagory;
          this.ddlMeasureType = res.goalMeasure;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  addorUpdateGoals(frmGoals: NgForm) {

    if (!frmGoals.valid) {
      return
    }
    this.onpostgoal = true
    console.log(this._dtoGoals)
   
    if(this._dtoGoals.diagnosisCode.includes(0)){
    this._dtoGoals.diagnosisCode.splice(0, 1);}
   
    this._dtoGoals.goalCatagoryId = this._dtoGoals.goalCatagoryId.toString();
    this._dtoGoals.diagnosisCode = this._dtoGoals.diagnosisCode.toString();
    this._dtoGoals.applicableTreatment = this._dtoGoals.applicableTreatment.toString();
   // console.log(this._dtoGoals.applicableTreatment )
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoGoals, false);
    this.tempService.postGoals(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        // this.addGoal();
        // frmGoals.resetForm();
        this.isEditGoals = false;
        this.snackbar.success(res);
        //this.getGoals();
        this.resetfrmGoals(frmGoals);
        this.onpostgoal = false
      }
    },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      }
    );
  }
  resetfrmGoals(frmGoals: NgForm) {

    this.addGoal();
    frmGoals.resetForm();
    this.isEditGoals = false;
    this.onbacktogoallist();
  }
  getTherapylist(){
    this.spinnerservice.show();

    this.tempService.getTherapyForGoals().subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.ddlTherapy = this.commonService.decrypdata(res.objInputString);
       // console.log(this.ddlTherapy)
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  onEditGoal(item) {
    this._dtoGoals = cloneDeep(item);
    this.isEditGoals = true;
  }

  ondeleteGoals(obj) {


    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.confirmation.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();

        this.tempService.deleteGoals(obj.goalId)
          .subscribe(
            data => {
              this.spinnerservice.hide();
              this.snackbar.success(data.message);
              //this.getGoals();
            },
            error => {
              this.spinnerservice.hide();
              this.snackbar.error(error.error);
            });
      }
      else {
        return
      }
    });

  }
  toogleActiveGoal(obj) {
    obj.isActive = !obj.isActive;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.tempService.activeGoals(dataToEncrypt)
      .subscribe(
        data => {
          this.spinnerservice.hide();
          this.snackbar.success(data);
          //this.getGoals();
        },
        error => {
          this.spinnerservice.hide();
          this.snackbar.error(error.error);
        });
  }
  OnmeasuretypeChange(){
    if(this.allMeasures!=undefined){
    this._dtoGoals.measures = this.allMeasures.filter(x=>x.goalMeasureTypeId == this._dtoGoals.measureTypeId)
    }
    if(this._dtoGoals.measures == null || this._dtoGoals.measures.length==0){
      let _obj = new GoalMeasure();
    this._dtoGoals.measures.push(_obj);
    }

  }
  addMeasures() {
    let _obj = new GoalMeasure();
    this._dtoGoals.measures.push(_obj);
  }
  removeMeasures(index) {
    this._dtoGoals.measures.splice(index, 1);
  }
  addGoal() {
    this._dtoGoals = new Goals();
    let measures = new Array<GoalMeasure>();

    let _obj = new GoalMeasure();
    measures.push(_obj)
    this._dtoGoals.measures = measures;

  }
  setWeeks() {
    this.lstWeek = []
    for (let i = 1; i <= 12; i++) {
      const _obj = {
        weekId: i,
        weeek: i + ' Week'
      }
      this.lstWeek.push(_obj);
    }
    const _obj = {
      weekId: 13,
      weeek: 'Overarching Period'
    }
    this.lstWeek.push(_obj);
  }
  setTimeTypes() {
    this.lsTimes = []

    const _obj = {
      timeTypeId: 1,
      timeType: 'Seconds'
    }
    this.lsTimes.push(_obj);
    const _obj1 = {
      timeTypeId: 2,
      timeType: 'Minutes'
    }
    this.lsTimes.push(_obj1);
    const _obj2 = {
      timeTypeId: 3,
      timeType: 'Hours'
    }
    this.lsTimes.push(_obj2);

  }
  onbacktogoallist() {

    this.router.navigate(['/goal-list']);

  }

  onDignosisChange(item:any){
    console.log(item.includes(0))
     if(item.includes(0)){
       this.SelectAllDiagnosis = true;
    this._dtoGoals.diagnosisCode=[];
    this._dtoGoals.diagnosisCode.push(0)
    this.ddldiagnosiscode.forEach(x=>{
      this._dtoGoals.diagnosisCode.push(x.value)
    })
     }
     else if(item.includes(-1)){
      this.SelectAllDiagnosis = false;
      this._dtoGoals.diagnosisCode=[];
     }
     else{
      this.SelectAllDiagnosis = false;
     }
  }

  onDiagnosisCodeselect(selected){

  // console.log(item,selected)
  // this._dtoGoals.diagnosisCode=this._dtoGoals.diagnosisCode.forEach(x => { x.isActive = true      
  // });
  if(selected){
    this.SelectAllDiagnosis = true;
    this._dtoGoals.diagnosisCode=[];
    this.ddldiagnosiscode.forEach(x=>{
      this._dtoGoals.diagnosisCode.push(x.value)
    })
    }
    else {
      this.SelectAllDiagnosis = false
    }
  }
}

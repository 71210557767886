<div class="schedule-group" *ngIf="isGroupyTherapy">
    <div class="d-flex met-info-top w-100">
      <i class="fas fa-users"></i>
      <div class="mr-4">Create Day Schedule for
        <span class="imp-info">{{_dtoGrpTherapyMeta?.GroupTherapyName}}</span>
      </div>
      <div class="mr-4 ">
        Start Date
        <span class="imp-info"> {{_dtoGrpTherapyMeta?.StartDate |date:'MM/dd/yyyy'}}</span>
      </div>
      <div class="mr-4 ">
        Start Time
        <span class="imp-info">{{_dtoGrpTherapyMeta?.StartTime |date:'hh:mm a'}}</span>
      </div>
      <div class="mr-4 ">
        Duration
        <span class="imp-info">{{global.getMinutesSlot(_dtoGrpTherapyMeta?.Duration)}} </span>
      </div>
  
      
      <div class="ml-auto">
        <!-- <button type="button" mat-stroked-button [routerLink]="['/meeting-appointment']"
          class="bg-white top-back  mt-1 mb-2 h-40 mr-2">Back</button> -->

          <button type="button" mat-stroked-button (click)="onBackClick()"
          class="bg-white top-back  mt-1 mb-2 h-40 mr-2">Back</button>
      </div>
    </div> 
    <div class="sub-group bg-white pt-2 pb-2" *ngIf="!isNewSubgroup && isGroupyTherapy">
      <div class="col-md-12 text-center">
        <div class="mt-2">No Subgroup Created, Do You Want to <a (click)="onNewSubGroup()"
            class="Add-sub-btn cursor-pointer">Add</a> Subgroup?</div>
        <img class="w-25 img-fluid" src="../../../assets/images/sub-group.png" />
      </div>
    </div>
    <!-- <div class="todays-Date"><label>Date</label>,<i class="fas fa-calendar ml-2 mr-2"></i>{{selecteddate | date:'EEEE, MMMM
      d, y'}}</div> -->
    <div *ngIf="isNewSubgroup">
  
      <div class="Group-theraphy ">
  
        <div class="p-2 d-flex" *ngIf="!isCreateSubgroup && isSave">
  
          <div class="ml-auto"><button  (click)="onCreateSubGroup()" *ngIf="roleAccess.isCreate||roleAccess.isEdit" type="button" class="create-btn-group w-auto cursor-pointer">Create Sub
              Group</button></div>
        </div>
  
        <div class="bg-white mt-1 pb-2" *ngIf="isCreateSubgroup" [ngClass]="(roleAccess.isCreate||roleAccess.isEdit)?'':'disabled'">
          <div class="col-md-12">
            <div class="row">
              <div class="assign-head col-md-12"> <i class="fas fa-user-plus mr-2 mt-1" *ngIf="isSave"></i>Create Sub Group
                <div class="float-right" (click)="onCloseSubGroup()">
                  <!-- <a  class="link-top-subgroup">Assign Clinician</a>  -->
                  <!-- <i class="fas fa-trash-alt mr-3"></i> -->
                  <!-- <i class="fas fa-chevron-down"></i> -->
                  <i class="fas fa-times"></i>
                </div>
              </div>
              <div class="col-md-2">
                <div class="left-kids-section">
                  <h5 class="assign-head2">Patients in the Group</h5>
                  <ol class="simple_with_drop vertical">
                    <div cdkDropList id="activeList" [cdkDropListData]="_dtoGrpTherapyUsers"
                      [cdkDropListConnectedTo]="[inactiveList]" class="example-list"
                      [cdkDropListEnterPredicate]="allowDrop" (cdkDropListDropped)="drop($event)">
                      <ng-container *ngFor="let item of _dtoGrpTherapyUsers;let i =index">
                        <li [cdkDragData]="item" cdkDrag>
  
                          <i class="fas fa-arrows-alt mr-2"></i>
  
                          {{item.UserName}}
  
                        </li>
                      </ng-container>
                    </div>
  
                  </ol>
                </div>
  
              </div>
              <div class="col-md-10 border-left">
  
                <form (ngSubmit)="dayschfrm.form.valid  && postdayschdule(dayschfrm)" id="dayschfrm" autocomplete="off"
                  #dayschfrm="ngForm" novalidate>
                  <div class="group-create-content">
                    <div class="group-items">
  
                      <div class="col-md-12">
                        <div class="row">
  
                          <div class="col-md-8 border-right">
                            <div cdkDropList [cdkDropListData]="_dtoAssignedUsers" id="inactiveList"
                              class="example-list chip-container pt-0" (cdkDropListDropped)="drop($event)"
                              [cdkDropListConnectedTo]="[activeList]" [cdkDropListEnterPredicate]="allowDrop">
                              <div class="kids-assigned" *ngIf="_dtoAssignedUsers?.length == 0">
                                <h5 class="assign-head2">Assign Kids </h5>
                                <div class="chip-container">
                                  <div class="text-center drag-img">
  
                                    <p class="small-meta">(You can drag & drop from Kids list)</p>
  
                                    <img src="../../../assets/images/drop.png">
                                  </div>
  
                                </div>
                                <!-- Edit this -->
                                <mat-error class="mat-form-field-subscript-wrapper" *ngIf="assignKidsError" style="padding-left: 13px;">*Please select the patients before adding the theme</mat-error>
                              </div>
  
  
                              <div class="d-flex row w-100 clinic-assign" *ngIf="_dtoAssignedUsers?.length > 0">
                                <div class="col-md-5">
                                  <div class="assign-head2 text-left ">Assigned Kids</div>
                                </div>
                                <div class="col-md-1 mt-2">
  
                                </div>
                                <div class="col-md-5">
                                  <div class="assign-head2 text-left pl-0">Assign Clinician</div>
                                </div>
                                <div class="col-md-1">
  
                                </div>
                              </div>
                              <div>
                                <div class="d-flex row w-100 clinic-assign" *ngFor="let item of _dtoAssignedUsers;let i=index">
  
                                  <div class="align-self col-md-5">
                                    <div class="teachers-head">{{item.patientName}}</div>
                                  </div>
                                  <div class="col-md-1 mt-2">
                                    <i class="fas fa-arrow-right"></i>
                                  </div>
                                  <div class="clinic-lookup col-md-5">
  
                                    <select class="form-control" [(ngModel)]="item.clinicianId" #clinicianId="ngModel"
                                      name="clinicianId{{i}}"  (change)="OndayschClinicianchange($event.target.value)" required>
                                      <option value="null">--Select--</option>
                                      <option *ngFor="let item of ddlPrimarystaff" [value]="item.staffId">
                                        {{item.firstName}} {{item.lastName}}
                                      </option>
                                    </select>
                                    <mat-error *ngIf="dayschfrm.submitted && clinicianId.invalid">
                                      <mat-error class="font-10" *ngIf="clinicianId.errors.required">Clinician name is
                                        required</mat-error>
                                    </mat-error>
                                  </div>
                                  <div class="col-md-1">
                                    <a class="cursor-pointer" (click)="onremoveassigned(item)"> <i
                                        class="far fa-times-circle mt-2 text-danger"></i></a>
                                  </div>
  
                                </div>
  
                              </div>
                              <div class="font-14 text-danger">
                                <span [innerHTML]="patientExist"></span>
                                <span [innerHTML]="clinicianExist"></span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 ">
                            <div class="theme-assigned">
                              <h5 class="assign-head2">Add Theme</h5>
                              <div class="form-theme">
                                <div class="col-md-12">
                                  <div class="row">
                                    <div class="col-md-12 mt--14">
                                      <mat-form-field class="w-100">
                                        <mat-label class="control-label-new">Theme Name</mat-label>
                                        <input matInput [(ngModel)]="_dtoDayScheduleSubGroup.themeName"
                                          #themeName="ngModel" name="themeName" required>
                                        <mat-error *ngIf="dayschfrm.submitted && themeName.invalid">
                                          <mat-error *ngIf="themeName.errors.required">Theme name is
                                            required</mat-error>
                                        </mat-error>
                                      </mat-form-field>
  
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-12 mt--14">
                                      <mat-form-field class="w-100">
                                        <mat-label class="control-label-new">Sub group therapy Date</mat-label>
                                        <input matInput [matDatepicker]="gpdtpicker" name="schduleDate"
                                        [(ngModel)]="_dtoDaySchGrpTherapy.schduleDate"
                                        #schduleDate="ngModel" required
                                        [min]="currentdate">
                                        <mat-datepicker-toggle matSuffix [for]="gpdtpicker"></mat-datepicker-toggle>
                                        <mat-datepicker #gpdtpicker></mat-datepicker>
                                        <mat-error *ngIf="dayschfrm.submitted && schduleDate.invalid">
                                          <mat-error *ngIf="schduleDate.errors.required">Select Sub group therapy Date</mat-error>
                                        </mat-error>
                                      </mat-form-field>
  
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-md-6">
                                      <mat-form-field class="w-100">
                                        <mat-label class="control-label-new">Start Time</mat-label>
                                        <input matInput type="time" #startTime="ngModel"
                                          [(ngModel)]="_dtoDayScheduleSubGroup.startTime" name="startTime" min="10:00"
                                          max="11:00"  required>
                                        <mat-error *ngIf="dayschfrm.submitted && startTime.invalid">
                                          <mat-error *ngIf="startTime.errors.required">StartTime is
                                            required</mat-error>
                                          <mat-error *ngIf="startTime.errors?.min">min is
                                            required</mat-error>
                                        </mat-error>
  
  
                                      </mat-form-field>
  
                                    </div>
                                    <div class="col-md-6">
                                      <mat-form-field class="w-100">
                                        <mat-label class="control-label-new">End Time</mat-label>
                                        <input matInput type="time" #endTime="ngModel"
                                          [(ngModel)]="_dtoDayScheduleSubGroup.endTime" name="endTime"
                                          min="{{_dtoDayScheduleSubGroup.startTime}}" max="{{maxtime}}" (focusout)="check()" required>
                                        <mat-error *ngIf="dayschfrm.submitted && endTime.invalid">
                                          <mat-error *ngIf="endTime.errors.required">EndTime is
                                            required</mat-error>
                                        </mat-error>
  
                                      </mat-form-field>
                                      <mat-error *ngIf="dayschfrm.form.valid && endtime" class="mat-form-field-subscript-wrapper text-danger">
                                        End time should be greater than start time</mat-error>
                                    </div>
  
  
                                  </div>
  
                                  <div class="row">
                                    <div class="col-md-12">
                                      <mat-form-field class="w-100">
                                        <mat-label class="control-label-new">Location</mat-label>
                                        <mat-select #sfccMasterId="ngModel"
                                          [(ngModel)]="_dtoDayScheduleSubGroup.sfccMasterId" name="sfccMasterId" required>
                                          <mat-option *ngFor="let item of ddlSfccLocation" [value]="item.sfccMasterId">
                                            {{item.locationName}}
                                          </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="dayschfrm.submitted && sfccMasterId.invalid">
                                          <mat-error *ngIf="sfccMasterId.errors.required">Location is
                                            required</mat-error>
                                            <br>
                                        </mat-error>
                                      </mat-form-field>
  
                                    </div>
                                  </div>
  
                                  <!-- Added Radio button for IOP or PCP -->
                                  <div class="custom-radio" >
                                    <mat-radio-group aria-label="Select an option" #isIop="ngModel"
                                    [(ngModel)]="_dtoDayScheduleSubGroup.isIop" name="isIop" required>
                                      <div class="row">
                                        <div class="col-md">
                                          <mat-radio-button class="mr-1" (change)="chengeRadioValue('iop')" [value]="true">IOP
                                          </mat-radio-button>
                                        </div>
                                        <div class="col-md">
                                          <mat-radio-button (change)="chengeRadioValue('pcp')" [value]="false">PCP
                                          </mat-radio-button>
                                        </div>
                                      </div>
                                      <mat-error *ngIf="dayschfrm.submitted && isIop.invalid">
                                        <mat-error class="mat-form-field-subscript-wrapper text-danger" *ngIf="isIop.errors.required">Please select a field</mat-error>
                                      </mat-error>
                                    </mat-radio-group>
                                  </div>
  
                                </div>
  
                                <div style="padding-left:15px ;">
                                  <mat-error *ngIf="dayschfrm.form.valid && showErrorMessage" class="mat-form-field-subscript-wrapper text-danger" >
                                  {{errorMessage}}
                                  </mat-error>
                                </div>
                              </div>
  
                            </div>
                          </div>
  
                        </div>
                      </div>
  
                    </div>
                    <div class="col-md-12">
                      <div class="save-subgroup row" >
                        <div class="text-right col-md-12 p-0 mb-2">
                          <button type="submit"  [ngClass]="{'disabled' : buttonEnabled}"
                          form="dayschfrm"  class="create-btn-group ">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          </div>
        <div class="col-md-12 bg-white mt-2 pt-3">
          <div class="row">
            <div class="col-md-6 mb-2" *ngFor="let item of lstsubgroup;let i = index">
              <div class="card">
                <div class="card-body p-0">
                  <div class="gp-schedule-list d-flex">
                    <div class="groups-name">SubGroup {{i+1}}  </div> <div class="groups-name" *ngIf="item.scheduleDate !=null"> @ {{  item.scheduleDate | date:'MM/dd/YYYY'}}  </div>
                    <div class="time-start-end">({{item.startTime | date:'hh:mm a'}} - {{item.endTime | date:'hh:mm a'}})
                    </div>
                  </div>
                  <div class="cent-container p-2">
                    <div class="d-flex">
                      <div class="theme-view"><label>Theme Name</label>:<span>{{item.themeName}}
                        <span *ngIf="item.isIop"> (IOP)</span>
                        <span *ngIf="item.isPcp"> (PCP)</span>
  
                      </span></div>
                      <div class="theme-view"><label>Location</label>:<span>{{item.locationName}}</span></div>
                    </div>
                    <div class="col-md-12 p-0">
  
                      <div class="d-flex row w-100 clinic-assign">
                        <div class="col-md-5">
                          <div class="kids-name-assigned text-left ">Assigned Kids</div>
                        </div>
                        <div class="col-md-1 mt-2">
                        </div>
                        <div class="col-md-5">
                          <div class="kids-name-assigned text-left ">Assigned Clinician</div>
                        </div>
                      </div>
                      <div class="d-flex row w-100 clinic-assign"
                        *ngFor="let data of item.dtoSpDayScheduleSubgroupDetails">
                       <div class="align-self col-md-5">
                          <div class="teachers-head">{{data.userName}} </div>
                        </div>
                        <div class="col-md-1 mt-2">
                          <i class="fas fa-arrow-right"></i>
                        </div>
                        <div class="clinic-lookup col-md-5">
                          <div class="teachers-head">{{data.clinicianName}} </div>
                        </div>
                        <div class="col-md-1 p-0">
                           <!-- <a class="Assign-notes-btn mr-1" title="Assign Notes"><i class="far fa-sticky-note"></i></a>
                          <a class="Rating-star-btn mr-1" title="Five Points Rating"><i class="far fa-grin-stars"></i></a>
                         <a (click)="onDeleteDaySchedule(item,data)" class="Rating-star-btn" title="Delete schedule" ><i class="far fa-trash-alt"></i></a> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
             
          </div>
        </div>
  
  
      </div>
    </div>
  </div>
  <!-- isIndividualTherapy family-->
  <!--<div *ngIf="isIndividualFamilyTherapy">
    
    <div class="col-md-12">
      
      <div class="d-flex met-info-top w-100">
        <i class="fas fa-user"></i>
        <div class="mr-4">Client Name 
          <span class="imp-info">{{_dtoPatientInfo?.patientName }}
  
        </span></div>
        <div  class="mr-4"> Schedule Date 
          <span class="imp-info">{{_dtoPatientInfo?.therapyDate | date:'MM/dd/YYYY'}}</span>
        </div>
        <div  class="mr-4"> Start Time   <span class="imp-info">{{_dtoPatientInfo?.startTime | date:'hh:mm a'}}</span></div>
      <div  class="mr-4">  Duration <span  class="imp-info"> {{_dtoPatientInfo?.duration}}</span>
        </div><div class="ml-auto">
          <button type="button" mat-stroked-button [routerLink]="['/scheduler']"
          class="bg-white top-back  mt-1 mb-2 h-40 mr-2">Back</button>
         </div>
        </div>
    </div>
     <div class="admin-theraphy col-md-12 mt-3">
      <div class="col-md-12 lead-box  p-3">
      <div class="row">
        <div class="col-md-3">
          <label>Theraphy</label> 
          <p>{{_dtoPatientInfo?.therapyName}}</p>
        </div>
        <div class="col-md-3">
          <label>Theme Name</label>
          <p>{{_dtoPatientInfo?.scheduleName}}</p>
        </div>
        <div class="col-md-3">
          <label>Location</label>
          <p>{{_dtoPatientInfo?.locationName}}</p>
        </div>
        <div class="col-md-3 text-right mt-3"  *ngIf="_dtoPatientInfo?.isDeleted==false">
           <mat-dialog-actions class="btn-closed delete-btn">
              <button mat-button mat-dialog-close  matTooltip="Delete" role="button" (click)="ondeleteMS()" ><i  class="far fa-trash-alt" ></i></button>
          </mat-dialog-actions>
        </div> 
      </div>
    </div>
    </div> 
  </div>-->
  
 
  
  <!-- isIndividualTheapy family end-->
        
  
  
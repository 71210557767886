import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { cloneDeep } from "lodash";
import * as _ from "lodash";
import { UserserviceService } from '../userservice.service';
import { constantVariables } from 'src/app/constant/constant';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { users } from 'src/app/_models/_Users/users';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { LeadService } from 'src/app/patient-lead/lead.service'

declare function EncryptFieldData(data): any;
@Component({
  selector: 'app-user-onboard',
  templateUrl: './user-onboard.component.html',
  styleUrls: ['./user-onboard.component.scss']
})
export class UserOnboardComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['firstName', 'lastName', 'rolename', 'email', 'contactNumber', 'locationName', 'status', 'actions'];
  _dtoUser: users;
  isEditUsers: boolean = false;
  ddlSfccLocation: any;
  lstreportstaff: any;
  ddlrole: any;
  userId: number = 0;
  lststaff: any;
  noUsers: boolean = false;
  isView = false;
  isEdit = false;
  isDelete = false;
  isCreate = false;
  roleAccess: any;
  isActiveUser : boolean = false;
  emailConfirmed : boolean = false;
  constructor(public global: Global, private userService: UserserviceService, public spinnerservice: NgxSpinnerService,
    private router: Router, private dialog: MatDialog, private snackbar: SnackBarService, public commonService: CommonService
    ,public roleaccessservice:PageAccessService,public leadservice: LeadService
    ) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.StaffManagement;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this._dtoUser = new users();
   // var access = JSON.parse(localStorage.getItem('roleaccess'));
   // const pageid = constantVariables.pagelists.USERS;
    // this.roleaccess = access.details.find(x => x.pageId == pageid);
    // if (this.roleaccess != null) {
    //   this.isCreate = this.roleaccess.isCreate;
    //   this.isView = this.roleaccess.isView;
    //   this.isEdit = this.roleaccess.isEdit;
    //   this.isDelete = this.roleaccess.isDelete;
    // }
    // else {
    //   this.isCreate = true;
    //   this.isView = true;
    //   this.isEdit = true;
    //   this.isDelete = true;
    // }
    this.getRoles();
    this.getsfcclocation();
    this.getStaffList();
  }

  getRoles() {
    this.userService.getRolesById().subscribe(
      response => {
        this.ddlrole = response;
      },
      error => { }

    );
  }
  getsfcclocation() {
    this.userService.getLocation(this.userId).subscribe(
      res => {
        if (res != null) {
          this.ddlSfccLocation = res;
         this.getReportUsers();
          var loc = res.filter(x => x.sfccMasterId && x.isSelected == true);
          if (loc && loc.length > 0) {
            this._dtoUser.location = [];
            for (let i = 0; i < loc.length; i++) {
              this._dtoUser.location[i] = loc[i].sfccMasterId
            
            }

          }

        }
      },
      err => {

      },
    );
  }
  onlocationselect(item, event) {

    item.isSelected = event;
    this.getReportUsers();

  }

  getStaffList() {
    this.userService.getStaffList().subscribe(
      response => {
        this.lststaff = response;
        this.dataSource = new MatTableDataSource(this.lststaff);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error => { }

    );
  }
  oneditUserProfile(item) {
    this.isEditUsers = true;
    this.userId = item.userId;
    this.getsfcclocation();
    this._dtoUser = item;
    this._dtoUser.oldEmail = item.email;
    if(item.isActive == 0 && item.emailConfirmed == false){
      this.isActiveUser = true;
    }else{
      this.isActiveUser = false;
    }
    if(item.emailConfirmed == false){
      this.emailConfirmed = false;
    }else{
      this.emailConfirmed = true;
    }
    var name = this.lstreportstaff.find(e => e.reportedTo == item.reportedTo).firstName;
    this._dtoUser.reportedTo = name;
    
   
    // this.userService.getUsersByAuthId(authId).subscribe(userdata => {
    //   this.isEditUsers = true;
    //   this._dtoUser = userdata;

    // });

  }
  onroleselect() {
    this.getReportUsers();
  }
  getReportUsers() {

    if (this._dtoUser.roleId == null || this._dtoUser.roleId == 0 || this.ddlSfccLocation.filter(x => x.isSelected == true).length == 0)
      return;

    let _obj = {
      RoleId: this._dtoUser.roleId,
      Locations: this.ddlSfccLocation.filter(x => x.isSelected == true).map(({ sfccMasterId }) => sfccMasterId)
    }
    const dataToEncrypt = {
      ObjInputString: EncryptFieldData((JSON.stringify(_obj))),
    };
    // this.spinnerservice.show();
    this.userService.getReportUsers(dataToEncrypt)
      .subscribe(
        data => {
          this.lstreportstaff = JSON.parse(data);
          if (this.lstreportstaff.length == 0)
            this.noUsers = true;
          else
            this.noUsers = false;
        },
        error => {
        });
  }
  onResendActivationStaff() {
    this.spinnerservice.show();
    let _obj = {
      userId: this._dtoUser.userId,
    //  Email: item.ParentList[0].Email
    email: this._dtoUser.email,
    isPatient : false
    }
    this.leadservice.postResendActivation(_obj).subscribe(
      (res: any) => {
        this.snackbar.success(res)
        this.spinnerservice.hide();

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  addorUpdate(form: NgForm) {
    if (!form.valid) {
      return
    }
   this._dtoUser.licenseNumber = this._dtoUser.isLicensed ? this._dtoUser.licenseNumber : '';
    let _obj = {
      DtoRegister: this._dtoUser,
      Location: this.ddlSfccLocation
    }
    const dataToEncrypt = {
      objRegister: EncryptFieldData((JSON.stringify(_obj))),
    };
    this.spinnerservice.show();
    this.userService.postStaffRegister(_obj, this.isEditUsers)
      .subscribe(
        data => {
          this.spinnerservice.hide();
          if (this.isEditUsers) {
            this.snackbar.success(constantVariables.SuccessMessage.useredit);
          } else {
            this.snackbar.success(constantVariables.SuccessMessage.useradd);
          }
          form.resetForm();
          this.resetuserform(form);
        },
        error => {
          this.spinnerservice.hide();
          this.snackbar.error(error.error);
        });
  }
  resetuserform(form: NgForm) {
    form.resetForm();
    this.isEditUsers = false;
    this.isActiveUser = false;
    this.emailConfirmed = false;
    this.userId = 0;
    this._dtoUser = new users();
    this.getsfcclocation();
    this.getStaffList();
  }
  deleteUser(item) {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: 'Are you sure you want to delete this ?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = {
          AuthId: item.authId,
          userid: item.userId
        }
        this.userService.deleteStaffUser(item.authId).subscribe(
          () => {
            this.getStaffList();
            this.snackbar.success(constantVariables.SuccessMessage.DeletetedSuccesscully);
          },
          error => {
            this.snackbar.error(error.error);
          });

      }
    });
  }
  userFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}


import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { BillingService } from 'src/app/billing/billing.service';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import {createBill} from 'src/app/_models/_Billing/createBill';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { PatientService } from 'src/app/patient/patient.service';
import { LeadService } from 'src/app/patient-lead/lead.service';

declare function decrypt(data): any;
@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss']
})
export class BillComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
   @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['TransCode','Dosfrom','Dosto','Tdescription','TherapyName','Amount','PostedDate','BillStatus','alerts','actions'];
  
  @ViewChild('createBillfrm') createBillfrm: NgForm;
  dtocreateBill: createBill;
  drpBillForm = constantVariables.billingForm;
  drpDiagCode : any;
  roleAccess: any;
  constructor(
    public billingservice: BillingService, public global: Global,public leadservice: LeadService,
    private dialog: MatDialog, private snackbar: SnackBarService, private route: ActivatedRoute,public patientservice: PatientService,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService,public datepipe: DatePipe
    ,public roleaccessservice:PageAccessService
  ) { }
  deCrptedBillLists:any;
  searchCreateBillPtName:string;
  patientName:string;
  ParentName:string;
  dob:string;
  email:string;
  phoneNo:string;
  isPatientSelected:boolean=false;
  drpBillingProvider:any;
  drpBillingMethod:any;
  drpTransactionCode:any;
  isTransactioncode:boolean=false;
  transactionFormdatas:any;
  postMethod :boolean=false;
  submitted:boolean=false;
  isCpt:boolean=false;
  isUB04 :boolean=false;
  drpAllTransactionCode:any;
  selectedTranscode:any;
  patientInfoId:any;
  patientBillLists:any;
  //isBillPending:boolean=false;
  isParam:boolean=false;
  dsId:any="0";
  therapyId:any=0;
  patientDemographicLists:any[];
  patientAccountId:any;
  cptCode:any;
  revenueCode:any;transactionCode:any;
  msId:any;
  msTId:any;
  drpRenderingProvider:any;
  isHideSearchBox:boolean=false;
  fromClaimsLists:any="false";
  showCreateForm:boolean=true;
  dtoPatientOutofPacketInfoCompletedDetails:any;
  isOutOfPacketExpense:boolean=false;
  isPerWeekC:boolean=false;
  isOutOfPacketData:boolean=false;
  insuranceList : any = [];
  primary : any;
  secondary: any;
  teritary : any;
  ngOnInit(): void {
    this.isTransactioncode=false;
    this.dtocreateBill = new createBill();
    this.isHideSearchBox=false;
    const pageid = constantVariables.pagelists.CreateBill;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    
this.route.queryParams.subscribe(params => {
  if (Object.keys(params).length != 0) {
      this.dtocreateBill.PatientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
      if(params.dsId){
        this.dtocreateBill.DayScheduleAssignId = this.commonService.FrontEndDecryption(params.dsId).replace(/"/g, "");
        }
     
    this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patient).replace(/"/g, ""));
    this.dtocreateBill.TherapyID=this.commonService.FrontEndDecryption(params.therapyId).replace(/"/g, "");
    this.isParam=true;
    this.dsId=this.dtocreateBill.DayScheduleAssignId;
    
    this.therapyId=this.commonService.FrontEndDecryption(params.therapyId).replace(/"/g, "");
    this.patientAccountId=this.dtocreateBill.PatientAccountId;
    if(params.cptCode){
    this.cptCode= this.commonService.FrontEndDecryption(params.cptCode).replace(/"/g, "");
    }
    if(params.revenueCode){
    this.revenueCode= this.commonService.FrontEndDecryption(params.revenueCode).replace(/"/g, "");
    }
    this.transactionCode= this.commonService.FrontEndDecryption(params.transactionCode).replace(/"/g, "");
    if(params.msId){
    this.msId= this.commonService.FrontEndDecryption(params.msId).replace(/"/g, "");
       }
       if(params.msTId){
        this.msTId= this.commonService.FrontEndDecryption(params.msTId).replace(/"/g, "");
        this.dtocreateBill.MeetingScheduleTimeId= this.msTId;

           }

    if(params.fromClaims){
      this.fromClaimsLists= this.commonService.FrontEndDecryption(params.fromClaims).replace(/"/g, "");

      }
    this.isHideSearchBox=true;
    this.showCreateForm=( this.fromClaimsLists=='true')?false:true;
   }
});
this.getDiagnosisCodeMster();
  this.getDiagnosisCodeMster();
    this.getPatientLists();
    this.getMastersForCreateBilling();
    this.getPaymentCompleted();
    this.getInsurance();
   }
   getDiagnosisCodeMster() {
    this.commonService
      .getDiagonosisCodeMasterBill(this.patientAccountId)
      .subscribe((res: any) => {
        if (res.objInputString != null) {
          let fres = this.commonService.decrypdata(res.objInputString);
          if (fres.length > 0) {
            this.drpDiagCode = fres;
        }
      }
      },
        err => {
          this.spinnerservice.hide();
        });
  }
   getPaymentCompleted()
   {
    this.spinnerservice.show();
    this.patientservice.getPatientOutofPktCompletedDtls(this.patientAccountId).subscribe(
       res => {
         this.spinnerservice.hide();
         if(res.objInputString!=null) {
           let resultdata=this.commonService.decrypdata(res.objInputString);
            this.dtoPatientOutofPacketInfoCompletedDetails=resultdata;
            this.isOutOfPacketData =true;
            if(this.dtoPatientOutofPacketInfoCompletedDetails[0]?.PayFrequencyName=='Per Week')
            {
              this.isPerWeekC = true;
            }else{
              this.isPerWeekC = false;
            }
           }
           else{
            this.isOutOfPacketData =false;
           }
          },
       err => {this.spinnerservice.hide();}
     );
 }
 OutOfPacketExpense()
 {
  this.isOutOfPacketExpense =true;
 }
 OutofPacketExpenseClose(){
  this.isOutOfPacketExpense =false;
 }
  getPatientLists()
  {
   this.spinnerservice.show();
   this.patientDemographicLists=[];
   this.billingservice.getPatientListsForcreateBill().subscribe(
     res => {
        this.spinnerservice.hide();
         if (res.objInputString!=null) {
           let result=decrypt(res.objInputString)
            this.patientDemographicLists=result;
            this.deCrptedBillLists= result;
            if( this.isParam==true && (this.dsId !="0" || this.msTId!="0" ))
            {
              this.deCrptedBillLists= this.deCrptedBillLists.filter(x=>x.PatientAccountId==this.dtocreateBill.PatientAccountId)
               this.onSelectedPatient(this.deCrptedBillLists[0]);
            }
            
          }
       },
       err => {
         this.spinnerservice.hide();
       },
     );
  }
  onClickBack()
  {
 
if(this.fromClaimsLists=="true")
{
this.router.navigate(['/claims-lists']);
}
else
{
  this.router.navigate(['/bill-lists']);
}
  }
  
  onSelectedPatient(ptDtl)
  {

  this.patientName=ptDtl.PatientName;
  this.dob=ptDtl.DOB;
  this.email=ptDtl.Email;
  this.phoneNo=ptDtl.Phone;
  this.isPatientSelected=true;
  this.searchCreateBillPtName="";
  this.ParentName=ptDtl.ParentName;
  //this.isBillPending=(ptDtl.BillStatus==null)?true:false;
  this.patientInfoId=ptDtl.PatientInfoId;
  this.patientAccountId=ptDtl.PatientAccountId;
  this.dtocreateBill.PatientInfoId=ptDtl.PatientInfoId;
  this.dtocreateBill.PatientAccountId=ptDtl.PatientAccountId;
  this.dtocreateBill.DayScheduleAssignId=this.dsId;
  this.dtocreateBill.MeetingScheduleId=this.msId;
  this.dtocreateBill.MeetingScheduleTimeId=this.msTId;
  this.dtocreateBill.TherapyID=this.therapyId;
  this.bindGridData();
  }
  onCancelClick(){
    
    this.refresh();
  }
  refresh()
  {
    this.patientName="";
    this.dob="";
    this.email="";
    this.phoneNo="";
    this.isPatientSelected=false;
    this.isTransactioncode=false;
    this.dtocreateBill = new createBill();
    this.createBillfrm.resetForm();
    this.isHideSearchBox=false;
  }
  getMastersForCreateBilling(){
    this.spinnerservice.show();
    this.billingservice.getCreateBillMstr().subscribe(
      res => {
        if (res != null) {
         let result=decrypt(res.objInputString);
             this.drpBillingProvider=result.BillingProvider;
           this.drpBillingMethod=result.BillingMethod;
           this.drpTransactionCode=result.TransactionForm;
           this.drpAllTransactionCode=result.TransactionForm;
           this.drpRenderingProvider=result.BillRenderingProvider;
         
           this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onTransactioncCodePress()
  {
   this.dtocreateBill.TransCode= "";
   this.selectedTranscode="";
     if(this.isTransactioncode=true  && this.dtocreateBill.BFormType!=undefined)
   { this.drpTransactionCode=[];
    this.drpTransactionCode=this.drpAllTransactionCode;
   if(this.dtocreateBill.BFormType=="CPTCODE")
    {
      this.drpTransactionCode=this.drpTransactionCode.filter(x=>x.IsCpt==true);
      this.isCpt=true;
      this.isUB04=false;
    }
   else if(this.dtocreateBill.BFormType=="UB04")
    {
      this.drpTransactionCode=this.drpTransactionCode.filter(x=>x.IsUb04==true);
      this.isCpt=false;
      this.isUB04=true;
    }
  }
  }
  onBillformchange(val)
  {
    this.isTransactioncode=false;
    this.selectedTranscode="";
    this.dtocreateBill.TransCode= "";
    this.dtocreateBill.Units="";
    this.dtocreateBill.Amount="";
    this.dtocreateBill.Tdescription = "";
    this.dtocreateBill.RevenuCode = "";
    this.isCpt=false;
    this.isUB04=false;
    if(this.isParam==true){
    this.loadDefaultValuebyBillParameter();
    }
  }
  OnSaveclick(form: NgForm){
     this.spinnerservice.show();
     if (!form.valid) {
       this.spinnerservice.hide();
       return;
     }
    
  //  if(this.dtocreateBill.DayScheduleAssignId=="0" && (!this.dtocreateBill.IsUpdated))
  if((this.dtocreateBill.DayScheduleAssignId=="0" && (this.dtocreateBill.MeetingScheduleTimeId=="0" || this.dtocreateBill.MeetingScheduleTimeId == undefined)) && (!this.dtocreateBill.IsUpdated))
      { this.createBillfrm.resetForm();
      this.snackbar.error("No scheduled appointments, bill can't be created");
       this.spinnerservice.hide();
       return;
     }
    this.dtocreateBill.BillStatus="Posted";
    this.dtocreateBill.BillStatusId=1;
    
    const dataToEncrypt = this.commonService.DataToEncrypt(this.dtocreateBill, false);
    this.billingservice.PostCreateBill(dataToEncrypt).subscribe(
        (res) => {
          this.spinnerservice.hide();
          
          this.snackbar.success(res);
          this.createBillfrm.resetForm();
         // this.bindGridData();
          if(!this.dtocreateBill.IsUpdated){this.router.navigate(['/bill-lists'])}
          else
          {
            this.dtocreateBill=new createBill();
            this.getQueryparams();
          }
          
         // this.getbillListView2(true);
          },
        (err) => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        }
      );
    }
    onSelectedTranscode(e,item)
    {
      this.selectedTranscode=item.TransCode;
      this.dtocreateBill.TransCode="";
      this.dtocreateBill.TransCode=item.TransCode;
      this.dtocreateBill.Units=item.Units;
      this.dtocreateBill.Amount=item.Amount;
      this.dtocreateBill.Tdescription = item.Tdescription;
      this.dtocreateBill.RevenuCode = item.RevenuCode;
      this.dtocreateBill.CPTCode = item.CptCode;
      this.isTransactioncode=false;
    }
    getInsurance()
    {
      this.leadservice.getInsuranceBill(this.patientInfoId).subscribe(
        (res: any) => {
          this.insuranceList = this.commonService.decrypdata(res.objInputString);
          let primary = this.insuranceList.filter(x=>x.IsPrimaryInsurance && x.InsuranceType == constantVariables.Insurance.Primary);
          this.primary = primary.length > 0 ? primary[0].CarrierName : 'none'; 
          let secondary = this.insuranceList.filter(x=> x.InsuranceType == constantVariables.Insurance.Secondary);
          this.secondary = secondary.length > 0 ? secondary[0].CarrierName : 'none'; 
          let teritary= this.insuranceList.filter(x=> x.InsuranceType == constantVariables.Insurance.Teritary);
          this.teritary = teritary.length > 0 ? teritary[0].CarrierName : 'none'; 
        },
        err => {
          this.spinnerservice.hide();
        },);
    }
    
    bindGridData(){
      this.spinnerservice.show();
      this.dtocreateBill.TransCode="";
      this.isTransactioncode=false;
      this.patientBillLists=[];
     // debugger;
      this.billingservice.getcreatedBillLists(this.patientInfoId).subscribe(
         res => {
         // debugger;
          this.spinnerservice.hide();
           if (res.objInputString!=null) {
             let result=decrypt(res.objInputString)
         //  console.log(result);
            this.patientBillLists=result;
              this.dataSource = new MatTableDataSource(this.patientBillLists);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
             }
         },
         err => {
           this.spinnerservice.hide();
         },
       );
     }

     getEditBill(vals)
     {
      if(vals.BformType=="CPTCODE")
      {
       this.isCpt=true;
       this.isUB04=false;
      }
    else if(vals.BformType=="UB04")
     {
      this.isCpt=false;
      this.isUB04=true;
     }
      var diagnosis;
      if(vals.DiagnosisCode != null && vals.DiagnosisCode != "")
      {
        diagnosis = vals.DiagnosisCode.split(',');
      }
      this.showCreateForm=true;
 this.dtocreateBill.Amount=vals.Amount;
 this.dtocreateBill.BFormType=vals.BformType;
 this.dtocreateBill.BillId=vals.BillId;
 this.dtocreateBill.BillStatus=vals.BillStatus;
 this.dtocreateBill.BMethodId=vals.BmethodId;
 this.dtocreateBill.Rprovider=vals.BproviderId;
 this.dtocreateBill.DayScheduleAssignId=vals.DayScheduleAssignId;
 this.dtocreateBill.DiagnosisCode=diagnosis;
 this.dtocreateBill.DOSFrom=vals.Dosfrom;
 this.dtocreateBill.DOSTo=vals.Dosto;
 this.dtocreateBill.Units=vals.Units;
 this.dtocreateBill.Tdescription = vals.Tdescription;
 this.dtocreateBill.RevenuCode = vals.RevenuCode;
 this.dtocreateBill.CPTCode = vals.CptCode;
 this.dtocreateBill.Amount=vals.Amount;
 this.dtocreateBill.MeetingScheduleId=vals.MeetingScheduleId;
 this.dtocreateBill.MeetingScheduleTimeId=vals.MeetingScheduleTimeId;
 this.dtocreateBill.PatientAccountId=vals.PatientAccountId;
 this.dtocreateBill.PatientInfoId=vals.PatientInfoId;
 this.dtocreateBill.TherapyID=vals.TherapyId;
 this.dtocreateBill.TransCode=vals.TransCode;
 this.dtocreateBill.UserId=localStorage.getItem('id');
 this.dtocreateBill.BProviderId=vals.BproviderId;
 this.dtocreateBill.IsUpdated=true;
 this.dsId=vals.DayScheduleAssignId;
 this.therapyId=vals.TherapyId;
 this.msId=vals.MeetingScheduleId;
 this.msTId=vals.MeetingScheduleTimeId;

  }
     searchTblVal(val){
      this.dataSource.filter = val.trim().toLocaleLowerCase();
    }
    getQueryparams()
    {
      this.route.queryParams.subscribe(params => {
        if (Object.keys(params).length != 0) {
          this.dtocreateBill.PatientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
          if(params.dsId){
            this.dtocreateBill.DayScheduleAssignId = this.commonService.FrontEndDecryption(params.dsId).replace(/"/g, "");
            }
          this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patient).replace(/"/g, ""));
          this.dtocreateBill.TherapyID=this.commonService.FrontEndDecryption(params.therapyId).replace(/"/g, "");
          this.isParam=true;
          this.dsId=this.dtocreateBill.DayScheduleAssignId;
          this.therapyId=this.commonService.FrontEndDecryption(params.therapyId).replace(/"/g, "");
          this.patientAccountId=this.dtocreateBill.PatientAccountId;
        //  this.cptCode= this.commonService.FrontEndDecryption(params.cptCode).replace(/"/g, "");
        //  this.revenueCode= this.commonService.FrontEndDecryption(params.revenueCode).replace(/"/g, "");
        //  this.transactionCode= this.commonService.FrontEndDecryption(params.transactionCode).replace(/"/g, "");
         // this.msId= this.commonService.FrontEndDecryption(params.msId).replace(/"/g, "");
         // this.msTId= this.commonService.FrontEndDecryption(params.msTId).replace(/"/g, "");

          if(params.cptCode){
            this.cptCode= this.commonService.FrontEndDecryption(params.cptCode).replace(/"/g, "");
            }
            if(params.revenueCode){
            this.revenueCode= this.commonService.FrontEndDecryption(params.revenueCode).replace(/"/g, "");
            }
            this.transactionCode= this.commonService.FrontEndDecryption(params.transactionCode).replace(/"/g, "");
            if(params.msId){
            this.msId= this.commonService.FrontEndDecryption(params.msId).replace(/"/g, "");
            }
            this.msTId= this.commonService.FrontEndDecryption(params.msTId).replace(/"/g, "");
            if(params.fromClaims){
              this.fromClaimsLists= this.commonService.FrontEndDecryption(params.fromClaims).replace(/"/g, "");
              }
          
            this.showCreateForm=( this.fromClaimsLists=='true')?false:true;

         }
      
      });
      this.deCrptedBillLists= this.patientDemographicLists;
      // if( this.isParam==true && this.dsId !="0" )
      // {
      //   this.deCrptedBillLists= this.deCrptedBillLists.filter(x=>x.PatientAccountId==this.dtocreateBill.PatientAccountId)
      //   this.onSelectedPatient(this.deCrptedBillLists[0]);
      // }
       if( this.isParam==true && (this.dsId !="0" || this.msTId!="0" ))
      {
        this.deCrptedBillLists= this.deCrptedBillLists.filter(x=>x.PatientAccountId==this.dtocreateBill.PatientAccountId);
        this.onSelectedPatient(this.deCrptedBillLists[0]);
      }
    }
    loadDefaultValuebyBillParameter()
    {
      if(this.isParam==true)
      {
        this.drpTransactionCode=[];
      this.drpTransactionCode=this.drpAllTransactionCode;
       this.dtocreateBill.TransCode=this.transactionCode;
       this.selectedTranscode=this.transactionCode;
       if(this.dtocreateBill.BFormType=="CPTCODE")
         {
          this.drpTransactionCode=this.drpTransactionCode.filter(x=>x.IsCpt==true);
          this.drpTransactionCode=this.drpTransactionCode.find(x=>x.IsCpt==true && x.CptCode==this.cptCode);
          this.isCpt=true;
          this.isUB04=false;
         }
       else if(this.dtocreateBill.BFormType=="UB04")
        {
          this.drpTransactionCode=this.drpTransactionCode.filter(x=>x.IsUb04==true);
         this.drpTransactionCode=this.drpTransactionCode.find(x=>x.IsUb04==true && x.RevenuCode==this.revenueCode);
         this.isCpt=false;
         this.isUB04=true;
        }
        this.onSelectedTranscode(null,this.drpTransactionCode);
      }
    }

    // getbillListView(aftersave=false){
  //   this.spinnerservice.show();
  //   this.deCrptedBillLists=[];
  //   this.billingservice.getBillLists('CreateBill').subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       if(res.objInputString!=null)
  //       {
  //         this.deCrptedBillLists = this.commonService.decrypdata(res.objInputString);
  //         this.deCrptedBillLists= this.deCrptedBillLists.filter(x=>x.ApointmenteName=='Z-Total')
  //         if(Number(localStorage.getItem('sfcclocation'))>0){
  //            this.deCrptedBillLists=this.deCrptedBillLists.filter(x=>x.SfccMasterId==localStorage.getItem('sfcclocation'));
  //            }

  //           if(aftersave)
  //           {
  //           this.onSelectedPatient(this.deCrptedBillLists.find(x=>x.PatientInfoId== this.patientInfoId));
  //           }
  //        }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );
  // }
  // getbillListView2(aftersave=false){
  //   this.spinnerservice.show();
  //   this.deCrptedBillLists=[];
  //   this.billingservice.getBillLists('CreateBill').subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       if(res.objInputString!=null)
  //       {
  //         debugger;
  //         this.deCrptedBillLists = this.commonService.decrypdata(res.objInputString);
  //         if(this.isParam==true)
  //         {this.deCrptedBillLists= this.deCrptedBillLists.filter(x=>x.DayScheduleAssignId==this.dsId)
  //           this.onSelectedPatient(this.deCrptedBillLists[0]);
  //          }
  //         if(Number(localStorage.getItem('sfcclocation'))>0){
  //            this.deCrptedBillLists=this.deCrptedBillLists.filter(x=>x.SfccMasterId==localStorage.getItem('sfcclocation'));
  //            }
  //           if(aftersave)
  //           {
  //           this.onSelectedPatient(this.deCrptedBillLists.find(x=>x.PatientInfoId== this.patientInfoId));
  //           }
  //        }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );
  // }

  }




export class PresentingProblemsInfo{
    presentingProblemId:Number;
    patientAccountId:Number;
    chmatreatmentSummaryId:Number;
    targetSymptom:string;
    longTermGoal:string;
    staffSignature:any;
    objMetDate:any;
    staticGoals:Array<ShortTermGoal>;
    interventions:string;
    rsponsibleStaff:string;
    targetSymType:Number;
    isnext:any;
}

export class ShortTermGoal{
    shortTermGoalId:Number;
    presentingProblemId:Number;
    chmatreatmentSummaryId:Number;
    shortTermGoalsMasterId:Number;
    isActive:boolean;
    goalName:string;
    goalDescription:string;
    measureTypeId:any;
    measureTypeName:string;
    measures: Array<GoalMeasureBen>;
    therapyId:any;
    selectedTrackbenchId:any;
    patientAccountId:Number;
    targetDate:any;
    treatmentGoalsId:any;
}

export class GoalMeasureBen {
    goalBenchmarkId :number;
    goalId: number;  
    benchmarktitle:string;
    benchmarkdiscription:string;
    benchmarkunit:any;
    status:Number;
    trackBenchmarkId:Number;
    trackBenchIsSelected:boolean;
}

<div class="form-configuration">
    <div class="top-btn-section clearfix mb-1 w-100">
        <h2 class="head-section mb-2 mt-1">Create Group Therapy</h2>
        <div class="col-md-6 ml-auto mt-2 button-position pr-0">
            <button type="button" (click)="onbacktoformlist()" class="btn primary-btn-outlined float-right mr-2"><i
                    class="fas fa-angle-left pr-2"></i>Back</button>
        </div>

    </div>
    <form (ngSubmit)="frmGrpTherapy.form.valid  && postfrmGrpTherapy(frmGrpTherapy)" id="frmGrpTherapy"
        autocomplete="off" #frmGrpTherapy="ngForm" novalidate>
        <div class="col-md-12 bg-white pt-2  pb-2 ">
            <div class="row ">
                <div class="col-md-12  p-3">

                    <div class="col-md-12 mb-2">
                        <div class="row form-group">
                            <div class="col-md-10 mb-2 pl-0">



                                <label class="control-label-new font-weight-bold">Group Therapy Name : Name your Group
                                    Therapy</label>
                                <input class="form-control" maxlength="80" [(ngModel)]="_dtoGrpTherapy.groupTherapyName"
                                    appAlphabetOnly #groupTherapyName="ngModel" name="groupTherapyName" required>
                                <mat-error *ngIf="frmGrpTherapy.submitted && groupTherapyName.invalid">
                                    <mat-error class="mat-form-field-subscript-wrapper mt-4"
                                        *ngIf="groupTherapyName.errors.required">Group therapy name is
                                        required
                                    </mat-error>
                                </mat-error>
                            </div>
                            <div class="col-md-2 mb-2 ">

                                <label class="control-label-new font-weight-bold">No. of Slots</label>
                                <input class="form-control" placeholder="" step="0" maxlength="2" numeric customMin="1"
                                    customMax="100" [(ngModel)]="_dtoGrpTherapy.capacity" #capacity="ngModel"
                                    name="capacity" required>
                                <mat-error *ngIf="frmGrpTherapy.submitted && capacity.invalid">
                                    <mat-error class="mat-form-field-subscript-wrapper mt-4"
                                        *ngIf="capacity.errors.required">Capacity is
                                        required
                                    </mat-error>
                                    <mat-error class="mat-form-field-subscript-wrapper mt-4"
                                        *ngIf="capacity.errors?.customMin">Minimum number can be
                                        1.
                                    </mat-error>
                                    <mat-error class="mat-form-field-subscript-wrapper mt-4"
                                        *ngIf="capacity.errors?.customMax">Maximum number can be
                                        100.
                                    </mat-error>
                                </mat-error>

                            </div>


                        </div>
                        <div class="row">

                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-2 pl-0">

                                        <mat-form-field class="w-100" floatLabel="always">
                                            <mat-label class="control-label-new font-weight-bold">Start Date</mat-label>
                                            <input matInput [matDatepicker]="pstpicker" readonly [min]="currentdate"
                                                (click)="pstpicker.open()" [(ngModel)]="_dtoGrpTherapy.startDate"
                                                #startDate="ngModel" name="startDate" required>
                                            <mat-datepicker-toggle matSuffix [for]="pstpicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #pstpicker></mat-datepicker>

                                        </mat-form-field>
                                        <mat-error *ngIf="frmGrpTherapy.submitted && startDate.invalid">
                                            <mat-error class="mat-form-field-subscript-wrapper"
                                                *ngIf="startDate.errors.required">Start Date is
                                                required</mat-error>
                                        </mat-error>
                                    </div>
                                    <div class="col-md-2 mb-2 ">

                                        <div class="">
                                            <mat-form-field class="w-100" floatLabel="always">
                                                <mat-label class="control-label-new font-weight-bold">Start Time
                                                </mat-label>
                                                <input matInput type="time" #startTime="ngModel"
                                                    [(ngModel)]="_dtoGrpTherapy.startTime" name="startTime" min="00:00"
                                                    max="24:00" required>
                                            </mat-form-field>
                                            <mat-error *ngIf="frmGrpTherapy.submitted && startTime.invalid">
                                                <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                                    *ngIf="startTime.errors.required">Start Time is required
                                                </mat-error>
                                            </mat-error>


                                        </div>

                                    </div>
                                    <div class="col-md-2 mb-2 ">

                                        <div class="row">

                                            <div class="col-md-12 pl-0">
                                                <mat-form-field class="w-100" floatLabel="always">
                                                    <mat-label class="control-label-new font-weight-bold">Duration
                                                    </mat-label>
                                                    <mat-select name="duration" [(ngModel)]="_dtoGrpTherapy.duration"
                                                        #duration="ngModel" required>
                                                        <mat-option [value]="null">--Select Duration--</mat-option>
                                                        <mat-option [value]=15>15 mins</mat-option>
                                                        <mat-option [value]=30>30 mins</mat-option>
                                                        <mat-option [value]=60>1 hr</mat-option>
                                                        <mat-option [value]=120>2 hr</mat-option>
                                                        <mat-option [value]=180>3 hr</mat-option>
                                                        <mat-option [value]=240>4 hr</mat-option>
                                                        <mat-option [value]=300>5 hr</mat-option>
                                                        <mat-option [value]=360>6 hr</mat-option>
                                                        <mat-option [value]=420>7 hr</mat-option>
                                                        <mat-option [value]=480>8 hr</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-error *ngIf="frmGrpTherapy.submitted && duration.invalid">
                                                    <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                                        *ngIf="duration.errors.required">Duration is required
                                                    </mat-error>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 pl-0 " *ngIf="_dtoGrpTherapy.isEndDate">
                                        <mat-form-field class="w-100" floatLabel="always">
                                            <mat-label class="control-label-new font-weight-bold">End Date</mat-label>
                                            <input matInput [matDatepicker]="endpicker" readonly [min]="currentdate"
                                                (click)="endpicker.open()" [(ngModel)]="_dtoGrpTherapy.endDate"
                                                #endDate="ngModel" name="endDate" customdate required
                                                sDate="{{_dtoGrpTherapy.startDate}}">
                                            <mat-datepicker-toggle matSuffix [for]="endpicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #endpicker></mat-datepicker>

                                        </mat-form-field>
                                        <mat-error *ngIf="frmGrpTherapy.submitted && endDate.invalid">
                                            <mat-error class="mat-form-field-subscript-wrapper w-100"
                                                *ngIf="endDate.errors.required">End Date is
                                                required</mat-error>
                                        </mat-error>
                                        <mat-error class="mat-form-field-subscript-wrapper w-100"
                                            *ngIf="endDate.errors?.customdate">
                                            End date should be greater then start date.
                                        </mat-error>
                                    </div>
                                    <div class="col-md-2 mb-2 check-custom mt-4">
                                        <mat-checkbox class="example-margin" [(ngModel)]="_dtoGrpTherapy.isEndDate"
                                            #isEndDate="ngModel" name="isEndDate">Mark End Date?</mat-checkbox>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 pl-0">
                                <label class="control-label-new align-middle  font-weight-bold mt-2">Do You Want to Set
                                    Recurrance?</label>
                                <div class=" mt-2 d-inline-block align-middle pl-2 ">
                                    <mat-radio-group aria-label="Select an option"
                                        [(ngModel)]="_dtoGrpTherapy.isRecurrance" #isRecurrance="ngModel"
                                        name="isRecurrance" required>
                                        <mat-radio-button [value]="true" class="mr-2">Yes</mat-radio-button>
                                        <mat-radio-button [value]="false" class="mr-2">No</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-error *ngIf="frmGrpTherapy.submitted && isRecurrance.invalid">
                                        <mat-error class="mat-form-field-subscript-wrapper mt-4"
                                            *ngIf="isRecurrance.errors.required">Recurrance is
                                            required
                                        </mat-error>
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="_dtoGrpTherapy.isRecurrance">
                                <div class="">
                                    <div class="">
                                        <span class="control-label-new font-weight-bold">Frequency</span>
                                        <span class="ml-4 yes-no-insurance">
                                            <mat-radio-group aria-label="Select an option"
                                                [(ngModel)]="_dtoGrpTherapy.frequency" #frequency="ngModel"
                                                name="frequency" required>
                                                <mat-radio-button [value]="1" class="mr-2">Weekly</mat-radio-button>
                                                <mat-radio-button [value]="2" class="mr-2">Fortnightly
                                                </mat-radio-button>
                                                <mat-radio-button [value]="3" class="mr-2">Monthly </mat-radio-button>
                                            </mat-radio-group>
                                            <mat-error *ngIf="frmGrpTherapy.submitted && frequency.invalid">
                                                <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                                    *ngIf="frequency.errors.required">Frequency is
                                                    required</mat-error>
                                            </mat-error>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>

            </div>
            <div class="text-right col-md-12 pb-4">

                <button type="submit" form="frmGrpTherapy" title="Save" class="btn primary-btn-filled mr-2"
                    mat-button><i class="far fa-save pr-2"></i>{{isEditForm ?
                    'Update' : 'Save'}}</button>
                <button type="button" (click)="resetGrpTherapy()" class="btn btn-cancel mr-2" title="Cancel"
                    mat-button>Cancel</button>

            </div>
        </div>

    </form>
</div>
import { Component, OnInit } from '@angular/core';

import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { FinancialService } from 'src/app/patient-lead/financial-proposal/financial.service';
import { constantVariables } from 'src/app/constant/constant';
import { NgForm } from '@angular/forms';
import { cloneDeep } from "lodash";
import * as moment from 'moment';
import {
  CalendarEvent,
  CalendarDateFormatter,
  CalendarView,
} from 'angular-calendar';


import { CustomDateFormatter } from 'src/app/global/custom-date-formatter.provider';
import { LeadInfoComponent } from 'src/app/patient-lead/lead-info/lead-info.component'
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { PatientBreifcaseComponent } from 'src/app/patient/patient-breifcase/patient-breifcase.component';
@Component({
  selector: 'app-financial-review',
  templateUrl: './financial-review.component.html',
  styleUrls: ['./financial-review.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class FinancialReviewComponent implements OnInit {
  statusFinancial = constantVariables.status.financial;
  statussubmitted = constantVariables.status.submitted;
  statusreviewTour = constantVariables.status.reviewTour;
  statusCrbaUpdate = constantVariables.status.CrbaUpdate;
  statusforIntake = constantVariables.status.forIntake;
  statustourCompleted = constantVariables.status.tourCompleted;
  statusstaffAssigned = constantVariables.status.staffAssigned;
  statusAccepted = constantVariables.status.Accept;
  statusDeclined = constantVariables.status.Decline;
  tourDeleted = constantVariables.status.tourDeleted
  isFinancialDecline: boolean = false;
  reqDeclineReason: boolean = false;
  isRequestTour: boolean = false;
  isRequiredTour: boolean = false;
  isRequiredTour1: boolean = false;
  isTourCompleted: boolean = false;
  financialProposal: any;
  patientInfoId: any;
  userName: any;
  location: any;
  reasonforDecline: any;
  dateRequestTour: any;
  timeRequestTour: any;
  timeRequestTour1: any;
  timeslots: any=[];
  timeSlots1: any=[];
  LeadStatus: any;
  IsTourExist: boolean = true;
  appointmentInfo: any;
  meetingDateBlocks: any = [];
  roleAccessTour: RoelAccess;
  ;
  currentdate: any;
  isNoTimeSlots = false;
  isShowDeny: boolean = true;
  btnAcceptText: any = "Accept";
  isDateSelected: boolean = false;
  isDateSelected1: boolean = false;
  isDateSelectedError: boolean = false;
  isDateSelected1Error: boolean = false;
  isNoTimeSlots1: boolean = false;
  isTourCompleteEnabled: boolean = false;
  isShowDeclined: boolean = false;
  currentDate: Date = new Date();
  tourTime: any;
  isTour : boolean = false;
  isFinancereview: boolean;
  constructor(public financialService: FinancialService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, 
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public roleaccessservice: PageAccessService) { }
    // private leadcom: LeadInfoComponent,
  ngOnInit(): void {
    this.currentdate = new Date();
    this.LeadStatus = Number(localStorage.getItem("currentstatus"));
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.userName = localStorage.getItem("username");
    this.getFinancialProposalDetails();
    const pageid = constantVariables.pagelists.Tour;
    let access = JSON.parse(localStorage.getItem('roleaccess'));
    this.roleAccessTour = access.pagepermissions.find(x => x.pageId == pageid);
    this.getTourExist();
    this.isFinancereview = localStorage.getItem('isFinancereview') === 'true';
  }
  getTourExist()
  {
    this.financialService.getTourExist(this.patientInfoId).subscribe(
      res => {
        if (res != null) {
          this.isTour = res;
        }
        },
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  getFinancialProposalDetails() {
    this.spinnerservice.show();
    this.financialService.getFinancialProposalDetails(this.patientInfoId).subscribe(
      res => {
        if (res != null) {
          this.financialProposal = res;
          if (this.financialProposal?.requestTourDetails.length == 0) {
            this.IsTourExist = false;
          } else {
            this.IsTourExist = true;
          }
          if (new Date(this.financialProposal?.requestTourDetails[0]?.requestTourDate) <= this.currentDate) {
            var convertToLocalTime = new Date(this.financialProposal?.requestTourDetails[0]?.fromTime);
            var substartTime = new Date(
              this.currentDate.getFullYear(),
              this.currentDate.getMonth(),
              this.currentDate.getDate(),
              convertToLocalTime.getHours(),
              convertToLocalTime.getMinutes(),
              convertToLocalTime.getSeconds()
            );
            if (substartTime <= this.currentDate) {
              this.isTourCompleteEnabled = true;
            }
          }
          if (this.financialProposal?.status == this.statusAccepted) {
            this.btnAcceptText = "Accepted";
            this.isShowDeny = false;
          }
          if (this.financialProposal?.status == this.statusDeclined) {
            this.isShowDeny = false;
            this.isShowDeclined = true;
          }
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  postAcceptProposal() {
    this.financialProposal.status = constantVariables.status.Accept;
    var dataTobackEnd = this.financialProposal;
    // dataTobackEnd.requestTourDetails=[]
    // dataTobackEnd.requestTourDetails.push(this.financialProposal.requestTourDetails[0]);

    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(dataTobackEnd, false);
    this.financialService.postChangeStatus(dataTobackEnd).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.LeadStatus = this.statusforIntake;
        localStorage.setItem("currentstatus", this.statusforIntake);
        this.isShowDeny = false;
        this.btnAcceptText = "Accepted";

        this.router.navigate(['/leadinfo']);
        // this.leadcom.ngOnInit();
        this.getPatientAccountId();

        if (this.financialProposal.requestTourDetails != null) {

          this.postTourCompleted(2);
        }


        // if(this.financialProposal?.requestTourDetails?.length > 0){
        // this.postTourCompleted(2);} 
        // this.leadcom.ngOnInit();
        // this.router.navigate(['/patient-breifcase']);
      },
      err => {
        this.spinnerservice.hide();
        this.isShowDeny = true;
        this.btnAcceptText = "Accept";
        this.snackbar.error(err.error);
      },
    );


  }
  onDenyProposal() {
    this.reasonforDecline = "";
    this.isFinancialDecline = true;

  }
  closeDeclineProcess() {
    this.reasonforDecline = "";
    this.isFinancialDecline = false;
  }
  onDeclineProposal() {
    if (this.reasonforDecline == "") {
      this.reqDeclineReason = true;
      return
    }
    this.financialProposal.status = constantVariables.status.Decline;
    this.financialProposal.reason = this.reasonforDecline;
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.financialProposal, false);
    this.financialService.postChangeStatus(this.financialProposal).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.LeadStatus = this.statusCrbaUpdate;
        localStorage.setItem("currentstatus", this.statusCrbaUpdate);
        this.isShowDeny = false;
        this.isShowDeclined = true;
        this.closeDeclineProcess();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  dateRequestTour1: any;
  sameDateError: boolean = false;
  onCalendarSelectedChange(event, id) {
    if (id == 1) {
      this.dateRequestTour = moment(event.value).format("MM/DD/YYYY");
      var day = moment(event.value).day();
      this.isDateSelected = true;
      this.tourTime = new Date()
      this.getTimeSlots(this.appointmentInfo.appointmentTypesId, day, this.tourTime, 1);
      this.isDateSelectedError = false;
    } else if (id == 2) {
      this.dateRequestTour1 = moment(event.value).format("MM/DD/YYYY");
      if (this.dateRequestTour == this.dateRequestTour1) {
        this.sameDateError = true;
        this.isDateSelected1Error = false;
        return;
      }
      this.sameDateError = false;
      var day = moment(event.value).day();
      this.isDateSelected1 = true;
      this.getTimeSlots(this.appointmentInfo.appointmentTypesId, day, this.tourTime, 2);
      this.isDateSelected1Error = false;
    }
  }
  onYestoRequestTour() {
    this.isNoTimeSlots = false;
    this.getTourAppointmentType();
    //this.getTimeSlots();
  }
  meetingDateFilter = (d: any) => {
    //var ss =moment(d).isoWeekday();
    const day = moment(d).isoWeekday();
    if (this.meetingDateBlocks?.length > 0) {
      return this.meetingDateBlocks.includes(String(day));
    }
    return true

  };
  getTourAppointmentType() {
    this.financialService.getTourAppointmentType().subscribe(
      res => {
        if (res != null) {
          this.isRequestTour = true;
          this.appointmentInfo = res;
          if (res.frequency == 2 && res.availableDays != "") {
            this.meetingDateBlocks = res.availableDays.split(',');
          }
          //this.getTimeSlots(res.appointmentTypesId)
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onNotoRequestTour() {
    this.isDateSelected1Error = false;
    this.isDateSelectedError = false;
    this.isRequestTour = false;
    this.isNoTimeSlots = false;
    this.dateRequestTour = null;
    this.dateRequestTour1 = null;
    this.timeslots = null;
    this.timeSlots1 = null;
    this.isDateSelected = false;
    this.isDateSelected1 = false;
    this.isNoTimeSlots1 = false;
    this.isNoTimeSlots = false;
    this.timeRequestTour1 = null;
    this.timeRequestTour = null;
    this.sameDateError = false;
  }
  // onMettingDate(event, index) {
  //   var day = moment(event.value).day();
  //   this.getTimeSlots(this.appointmentInfo.appointmentTypesId, day,event.value)
  // }
  getTimeSlots(id, day, date, typeId) {

    var offset = new Date().getTimezoneOffset();
    let _obj = {
      day: day,
      id: id,
      currentDate: new Date(),
      selectedDate: new Date(date),
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    console.log(_obj);

    this.financialService.getTimeSlotsForTour(_obj).subscribe(
      res => {
        this.spinnerservice.hide();
        if (JSON.parse(res).length > 0) {
          console.log(res);
          if (typeId == 1) {
            var result = JSON.parse(res);
            const dateObject = new Date(_obj.selectedDate);
            const timeOnly = dateObject.toTimeString().split(" ")[0];
            console.log(timeOnly);
            result.forEach(e => {
              const dateObject1 = new Date(e.fromTime);
              const timeOnly1 = dateObject1.toTimeString().split(" ")[0];
              var compare = this.compareTimes(timeOnly1, timeOnly);
              if (compare >= 0) {
                this.timeslots.push(e)
              }
            })
          } else {
            var results = JSON.parse(res);
            const dateObject1 = new Date(_obj.selectedDate);
            const timeOnly2 = dateObject1.toTimeString().split(" ")[0];
            console.log(timeOnly2);
            results.forEach(e => {
              const dateObject2 = new Date(e.fromTime);
              const timeOnly3 = dateObject2.toTimeString().split(" ")[0];
              var compare = this.compareTimes(timeOnly3, timeOnly2);
              if (compare >= 0) {
                this.timeSlots1.push(e)
              }
            })
            // this.timeSlots1 = JSON.parse(res);
          }
        } else {
          this.isNoTimeSlots = true;
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  compareTimes(time1: string, time2: string): number {

    const time1Parts = time1.split(":").map(part => parseInt(part, 10));

    const time2Parts = time2.split(":").map(part => parseInt(part, 10));



    const hoursDiff = time1Parts[0] - time2Parts[0];

    const minutesDiff = time1Parts[1] - time2Parts[1];

    const secondsDiff = time1Parts[2] - time2Parts[2];



    if (hoursDiff !== 0) {

      return hoursDiff;

    } else if (minutesDiff !== 0) {

      return minutesDiff;

    } else {

      return secondsDiff;

    }

  }
  onSubmitTourRequest(frmRequestTour: NgForm) {
    if (this.dateRequestTour == undefined || this.timeRequestTour1 == undefined) {
      this.isRequiredTour = true;
      return
    } else {
      this.isRequiredTour = false;
    }
    let obj = {
      PatientInfoId: this.patientInfoId,
      AssignStaffId: null,
      RequestTourDate: this.dateRequestTour,
      TimeSlotId: 0,
      FromTime: this.timeRequestTour1.fromTime,
      ToTime: this.timeRequestTour1.toTime,
      Type: 1
    }

    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.financialService.postRequestTour(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.LeadStatus = this.statusreviewTour;
        localStorage.setItem("currentstatus", this.statusreviewTour);
        this.getFinancialProposalDetails();
        this.isRequestTour = false;
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onSubmitTourRequest1(frmRequestTour: NgForm) {
    if (this.dateRequestTour1 == undefined || this.timeRequestTour == undefined) {
      this.isRequiredTour1 = true;
      return
    } else {
      this.isRequiredTour1 = false;
    }
    let obj = {
      PatientInfoId: this.patientInfoId,
      AssignStaffId: null,
      RequestTourDate: this.dateRequestTour1,
      TimeSlotId: 0,
      FromTime: this.timeRequestTour.fromTime,
      ToTime: this.timeRequestTour.toTime,
      Type: 2
    }

    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
    this.financialService.postRequestTour(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.LeadStatus = this.statusreviewTour;
        localStorage.setItem("currentstatus", this.statusreviewTour);
        this.getFinancialProposalDetails();
        this.isRequestTour = false;
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  submitTourRequest() {
    if (!this.isDateSelected) {
      this.isDateSelectedError = true;
      this.isNoTimeSlots = true;
      if (!this.isDateSelected1) {
        this.isDateSelected1Error = true;
        this.isNoTimeSlots1 = true;
      }
      return;
    }
    if (this.timeRequestTour1 == undefined || this.timeRequestTour1 == null) {
      this.isNoTimeSlots = true;
    }
    else {
      this.isDateSelectedError = false;
      this.isNoTimeSlots = false;
    }
    if (!this.isDateSelected1) {
      this.isDateSelected1Error = true;
      this.isNoTimeSlots1 = true;
      return;
    }
    if (this.timeRequestTour == undefined || this.timeRequestTour == null) {
      this.isNoTimeSlots1 = true;
      return;
    }
    else {
      this.isDateSelected1Error = true;
      this.isNoTimeSlots1 = true;
    }
    if (this.dateRequestTour == this.dateRequestTour1) {
      this.sameDateError = true;
      return;
    }
    this.isDateSelected1Error = false;
    this.isNoTimeSlots1 = false;
    this.sameDateError = false;
    let obj1 = {
      PatientInfoId: this.patientInfoId,
      AssignStaffId: null,
      RequestTourDate1: this.dateRequestTour,
      RequestTourDate2: this.dateRequestTour1,
      TimeSlotId: 0,
      FromTime1: this.timeRequestTour1.fromTime,
      ToTime1: this.timeRequestTour1.toTime,
      FromTime2: this.timeRequestTour.fromTime,
      ToTime2: this.timeRequestTour.toTime,
      RequestTourId: null
    }

    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(obj1, false);
    this.financialService.postRequestTourNew(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.LeadStatus = this.statusreviewTour;
        localStorage.setItem("currentstatus", this.statusreviewTour);
        this.getFinancialProposalDetails();
        this.isRequestTour = false;
        this.sameDateError = false;

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  validateTimeSlot(id) {
    if (id == 1) {
      this.isNoTimeSlots = false
    }
    if (id == 2) {
      this.isNoTimeSlots1 = false;
    }
  }
  postTourCompleted(id) {
    let dataToBackend;
    if (id == 1) {
      this.financialProposal.requestTourDetails[0].status = constantVariables.status.tourCompleted;
      dataToBackend = this.financialProposal.requestTourDetails[0];
      // dataToBackend.isTour=true;
    } else if (id == 2) {
      dataToBackend = this.financialProposal.requestTourDetails[0];
      //dataToBackend.isTour=false;

      if (this.financialProposal?.requestTourDetails.length && this.financialProposal.requestTourDetails[0].status != 15) {
        dataToBackend.status = constantVariables.status.tourCompleted;
        dataToBackend.isTour = false;
      }
      else {
        return;
      }
    }

    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(dataToBackend, false);
    this.financialService.postTourStatus(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (id == 1) {
          this.snackbar.success(JSON.parse(res).message);
        }
        //this.LeadStatus = this.statustourCompleted;
        //localStorage.setItem("currentstatus", this.statustourCompleted);
        this.getFinancialProposalDetails();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getPatientAccountId() {
    // if(localStorage.getItem('roleId')=="4"){
    this.spinnerservice.hide();
    this.commonService.getPatientAccountId(this.patientInfoId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != 0) {
          localStorage.setItem("patientAccountId", res);
          this.router.navigate(['/patient-breifcase']);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
    // }
  }
  hideModal: boolean = false;
  CancelTour(data: any) {
    this.hideModal = false;
    this.spinnerservice.show();
    this.financialService.cancelTour(data.requestTourId).subscribe((res: any) => {
      this.spinnerservice.hide();
      this.snackbar.success((res).message);
      this.getFinancialProposalDetails();

    },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },

    );
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/admin/authentication.service';
import { CustomValidators } from 'src/app/global/custom-validators';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { constantVariables } from 'src/app/constant/constant';
import { UserserviceService } from '../userservice.service';
import { Global } from 'src/app/global/global.model';
declare function EncryptFieldData(data): any;
declare function decrypt(data): any;
@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {
  //#region  Declaration
  changepassswordForm: FormGroup;
  ResponseData: any;
  loading = false;
  submitted = false;
  showForgetform = false;
  showErrorPassword = false;
  newPassEye=true;
  confirmPassEye=true;
  ChangePasswordData = {
    Email: null,
    // OldPassword: null,
    Password: null,
    // ConfirmPassword: null,
    IsResetPassword: null,
    Token: null,
    PasswordID: null
  };
  //#endregion
  constructor(
    private formBuilder: FormBuilder,
    public global: Global,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private snackbar: SnackBarService,
    private uService: UserserviceService,
  ) { }
  // convenience getter for easy access to form fields
  get f() { return this.changepassswordForm.controls; }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
     var paramsValue = decrypt(params.Data);
      this.ChangePasswordData.Email = paramsValue.Email;
      this.ChangePasswordData.PasswordID = +paramsValue.PasswordID;
    });



    localStorage.setItem('username', this.ChangePasswordData.Email);
    localStorage.setItem('passwordid', this.ChangePasswordData.PasswordID);
    this.changepassswordForm = this.formBuilder.group({
      //  OldPassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern]],
      // Password: ['', [Validators.required, Validators.minLength(6), Validators.pattern]],
      Password: [
        null,
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          // CustomValidators.patternValidator(/[a-z]/, {
          //   hasSmallCase: true
          // }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8)
        ])
      ],

      // ConfirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      ConfirmPassword: [null, Validators.compose([Validators.required])],
      Screen: ['UpdatePassword']
    }, {
      //validator: MustMatch('Password', 'ConfirmPassword')
      validator: CustomValidators.passwordMatchValidator
    });


    this.changepassswordForm.get('ConfirmPassword').clearValidators();
    this.changepassswordForm.get('ConfirmPassword').updateValueAndValidity();

    this.ForgetLinkValidation();



  }
  showErrorPopup() {
    this.showErrorPassword = true;
  }

  hideErrorPopup() {
    this.showErrorPassword = false;
  }

  isinvalid: boolean = false;

  changeStyle($event) {
    this.isinvalid = $event.type == 'mouseover' ? this.showErrorPassword = true : this.showErrorPassword = false;
  }




  onSubmit() {
    this.submitted = true;

    this.changepassswordForm.get('ConfirmPassword').setValidators(Validators.compose([Validators.required]));
    this.changepassswordForm.get('ConfirmPassword').updateValueAndValidity();

    if (this.changepassswordForm.invalid) {
      return;
    }
    let emailid = localStorage.getItem('username');
    let passwordid = localStorage.getItem('passwordid');

    this.ChangePasswordData.Email = emailid;
    this.ChangePasswordData.Password = this.changepassswordForm.controls.Password.value;
    this.ChangePasswordData.IsResetPassword = true;
    // this.ChangePasswordData.ConfirmPassword= this.changepassswordForm.controls.ConfirmPassword.value;

    const dataToEncrypt = {

      objChangepassword: EncryptFieldData((JSON.stringify(this.ChangePasswordData))),

    };

    this.uService.resetPassword(dataToEncrypt)
      .pipe(first())
      .subscribe(
        _data => {
          this.snackbar.success(constantVariables.SuccessMessage.passwordChangeSuccess);
          this.authenticationService.onLogout();
          this.router.navigate(['./login']);

        },
        _error => {

        });
  }
  onclear() {
    this.changepassswordForm = this.formBuilder.group({
      OldPassword: [''],
      Password: [''],
      ConfirmPassword: ['']

    });
  }

  ForgetLinkValidation() {
    this.ChangePasswordData.Email = EncryptFieldData(this.ChangePasswordData.Email);
    this.uService.ForgetLinkValidation(this.ChangePasswordData)
      .pipe(first())
      .subscribe(
        _data => {
          this.showForgetform = true;
          this.loading = false;
        },
        _error => {
          this.snackbar.error(_error.error);
          this.authenticationService.onLogout();

          this.router.navigate(['./login']);
          this.loading = false;

        });
  }
  oncancel() {
    this.router.navigate(['./login']);
  }
}

import { Component, OnInit,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';

declare function decrypt(data): any;

@Component({
  selector: 'app-diagnosis-code',
  templateUrl: './diagnosis-code.component.html',
  styleUrls: ['./diagnosis-code.component.scss']
})
export class DiagnosisCodeComponent implements OnInit {
  @ViewChild('Diagnosisfrm') Diagnosisfrm: NgForm;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  dtoDiagnosisfrm: any;
  roleAccess: any;
  isEdit : boolean = false;
  dtoDiagnosisfrmList : any;
  displayedColumns = ['sno','DiagnosisCodeName', 'DiagnosisCodeDescription', 'actions'];
  constructor(public spinnerservice: NgxSpinnerService,public commonService: CommonService,
              public snackbar:SnackBarService, public roleaccessservice : PageAccessService,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.BillingMasters;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.dtoDiagnosisfrm = {}
    this.GetDiagnosisCode();
  }

  PostDiagnosisfrm(form: NgForm){
    this.spinnerservice.show();
     if (!form.valid) {
       this.spinnerservice.hide();
       return;
     }
    
   const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoDiagnosisfrm, false);
   this.commonService.postDiagnosiscode(dataToEncrypt).subscribe(
     (res: any) => {
      this.spinnerservice.hide();
        this.snackbar.success(res);
        this.CancelClick();
        this.GetDiagnosisCode();
      },
     err => {
       this.spinnerservice.hide();
       this.snackbar.error(err.error);
     },
   );
 }
 CancelClick(){ 
  this.isEdit=false;
  this.Diagnosisfrm.resetForm();
  this.dtoDiagnosisfrm = {};
  this.dtoDiagnosisfrmList = [];
  this.GetDiagnosisCode();


}
GetDiagnosisCode(){
  this.spinnerservice.show();
   this.commonService.getDiagonosisCode().subscribe(
     res => {
       if (res != null) {
         this.spinnerservice.hide();
        let result=decrypt(res.objInputString)
         this.dtoDiagnosisfrmList=result;
         this.dataSource = new MatTableDataSource(this.dtoDiagnosisfrmList);
         this.dataSource.paginator = this.paginator;
         this.dataSource.sort = this.sort;
         }
     },
     err => {
       this.spinnerservice.hide();
     },
   );
 }
 onListEdit(item)
 {
this.dtoDiagnosisfrm = item;
this.isEdit=true;
 }
 searchTblVal(val){
  this.dataSource.filter = val.trim().toLocaleLowerCase();
}
ondeleteDiagonasis(diagnosis){
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.SuccessMessage.DeleteConfirmMessage
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
      this.spinnerservice.show();
      this.ondltDiagnosis(diagnosis);

    }
  });
}
ondltDiagnosis(diagnosis) {
  this.commonService.deleteDiagnosis(diagnosis.DiagnosisCodeId).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      this.GetDiagnosisCode();
      this.snackbar.success(constantVariables.SuccessMessage.DeleteDiagnosisCode);
    },
    err => {
      this.spinnerservice.hide(); 
      this.snackbar.error(constantVariables.ErrorMessage.DiagnosisError);
  },
  );
}

}

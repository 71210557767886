 <div class="psycharist-progress">
  <div class="col-md-12">
    <div class="d-flex met-info-top w-100">
      <i  class="fas fa-user"></i>
      <div  class="mr-4">Client Name <span  class="imp-info">{{_dtoPatientInfo.PatientName }} </span></div>
      <div  class="mr-4"> Appointment Date <span  class="imp-info"> {{_dtoPatientInfo.StartDate | date:'MM/dd/YYYY'}}</span></div>
      <div  class="mr-4"> Appointment Time <span  class="imp-info">{{_dtoPatientInfo.StartTime | date:'hh:mm a'}}</span></div>

      <div  class="ml-auto">
        <button  type="button" (click)="OnbackClick()" mat-stroked-button class="mat-focus-indicator bg-white top-back mt-1 mb-2 h-40 mr-2 mat-stroked-button mat-button-base">Back</button>
      </div>
      </div>
  </div>

  <div class="col-md-12  mt-3">
    <div class="lead-box pt-2 pb-2">
    <div class="tab-history custom-tab-border mt-2">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item mr-1" role="presentation">
          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#progress" type="button" role="tab" aria-controls="s1" aria-selected="true" (click)="OnTabchange(1)"  [ngClass]="{'active show': SelectedTab == 1}" >Communication Form</button>
        </li>
        <!-- <li class="nav-item mr-1" role="presentation">
          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#goals" type="button" role="tab" aria-controls="s3" aria-selected="true" (click)="OnTabchange(2)"  [ngClass]="{'active show': SelectedTab == 2}" >Goals</button>
        </li> -->


      </ul>

      <div class="tab-content phoneschoolform--uifix">
        <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==1">
          <form (ngSubmit)="PhoneSchoolfrm.form.valid" id="PhoneSchoolfrm" autocomplete="off" #PhoneSchoolfrm="ngForm"
          novalidate>
          <div class="col-md-12 mt-3">
          <div  class="row">
            <div  class="d-flex w-100">
              <div  class="box-lead-head mb-2 ml-3">Conversation Communication Form </div>
              <div  class="ml-auto mr-2" *ngIf="roleAccess.isCreate||roleAccess.isEdit">
                <button  type="button" class="btn primary-btn-outlined-small mr-2 float-left" (click)="OnpreviewClick()">
                  <i class="far fa-eye mr-2"></i>Preview</button>
                  <button type="button" class="btn primary-btn-outlined-small mr-2 float-left" (click)="OnDownload(0)"><i class="fas fa-file-alt mr-2"></i>Generate Pdf</button>
                  <button  form="PhoneSchoolfrm" type="submit" class="btn primary-btn-outlined-small ng-star-inserted float-left mr-2"  (click)="PostPhoneschoolFrm()" >
                    <i  class="far fa-save mr-2"></i>{{IseditFrm?'Save':'Update'}}</button>
                <button  class="export-pdf-btn btn"  mat-type="button" (click)="OnDownload(1)"><i class="fa fa-print"></i></button>

                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row" >
                <label  class="col-md-4 font-weight-bold "><span class="control-label-new">Client Name</span></label>
                <div  class=" col-md-3 form-group" [ngClass]="{'disabled': true }">
                 <input type="text" class="form-control" [(ngModel)]="_dtoPatientInfo.PatientName"
                 #PatientName="ngModel" name="PatientName"/>
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-4 font-weight-bold "><span class="control-label-new">Staff Name</span></label>
                <div  class=" col-md-3 form-group" [ngClass]="{'disabled': true }">
                 <input type="text" class="form-control"  [(ngModel)]="_dtoPatientInfo.ClinicianName"
                 #ClinicianName="ngModel" name="ClinicianName"/>
                </div>
              </div>
              </div>
              <div class="col-md-12 checkininput">
                <div class="row">

                    <label class="col-md-4 font-weight-bold "><span class="control-label-new">Check in Time</span></label>
                    <div  class=" col-md-3 form-group mb-0 check-time">
                    <mat-form-field class="w-100 mt--14">
                    <mat-select  id="Checkin" [(ngModel)]="Checkin" name="Checkin" [min]="currenttime"
                    max="12:00" required
                    required>
                    <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
                        {{item.slotTime | date: 'hh:mm a'}}
                    </mat-option>
                </mat-select>

              </mat-form-field>
              </div>
              </div>
              </div>
              <div class="col-md-12 checkininput">
                <div class="row">
                <label  class="col-md-4 font-weight-bold "><span class="control-label-new">Check out Time</span></label>
                <div  class=" col-md-3 form-group mb-0 check-time">
                <mat-form-field class="w-100 mt--14">
                  <mat-select id="CheckOut" [(ngModel)]="CheckOut" name="CheckOut" [min]="currenttime"
                  max="12:00" class="mat-select-checkinput" required>
                    <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
                        {{item.slotTime | date: 'hh:mm a'}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
          </div>
              </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-4 font-weight-bold "><span class="control-label-new">Form of Communication</span></label>
                <div  class=" col-md-3 form-group">
                 <!-- <input type="text" class="form-control" [(ngModel)]="PhoneSchoolInfo.FormOfCommunication" #FormOfCommunication="ngModel" name="FormOfCommunication" row="3" maxlength="250"
                 [ngClass]="{ 'is-invalid': PhoneSchoolfrm.submitted && FormOfCommunication.invalid }" /> -->

                 <!-- <mat-error *ngIf="PhoneSchoolfrm.submitted && FormOfCommunication.invalid">
                  <mat-error class="text-xs" *ngIf="FormOfCommunication.errors.required">
                    Form of Communication is required</mat-error>
              </mat-error> -->
              <select class="form-control  mr-0" [(ngModel)]="PhoneSchoolInfo.FormOfCommunication"  name="FormOfCommunication"  (change)="OnCommunicationChange()">       
                <option *ngFor="let a of ddlFormCommunicationMaster" value="{{a.communicationCode}}">
                    {{a.formCommunicationName}}
                 </option>
              </select>
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-4 font-weight-bold "><span class="control-label-new">With whom did the communication take place?</span></label>
                <div  class=" col-md-3 form-group">
                 <input type="text" class="form-control" [(ngModel)]="PhoneSchoolInfo.WhomCommunicated" #WhomCommunicated="ngModel" name="WhomCommunicated" row="3" maxlength="250"
                 [ngClass]="{ 'is-invalid': PhoneSchoolfrm.submitted && WhomCommunicated.invalid }"/>
                 <!-- <mat-error *ngIf="PhoneSchoolfrm.submitted && WhomCommunicated.invalid">
                  <mat-error class="text-xs" *ngIf="WhomCommunicated.errors.required">
                    This field is required</mat-error>
              </mat-error> -->
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Content (include items discussed, reaction, and pertinent information) </label>
                <div  class="form-group">
                  <!-- <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical" [(ngModel)]="PhoneSchoolInfo.Content" #Content="ngModel" name="Content" row="3" maxlength="950"
                  [ngClass]="{ 'is-invalid': PhoneSchoolfrm.submitted && Content.invalid }" ></textarea> -->
                 
                  <app-word-counter-textarea [text]="PhoneSchoolInfo.Content" [maxlength]="3000"  [(ngModel)]="PhoneSchoolInfo.Content"
                  (textChange)="PhoneSchoolInfo.Content = $event" #Content="ngModel" name="Content" ngDefaultControl required></app-word-counter-textarea>


                  <!-- <mat-error *ngIf="PhoneSchoolfrm.submitted && Content.invalid">
                    <mat-error class="text-xs" *ngIf="Content.errors.required">
                      Content is required</mat-error>
                </mat-error> -->
                </div>
              </div>
              <div  class="col-md-12">
                <div  class="form-group clearfix m-0">
                  <div  class="float-left col-md-4 p-0 mt-3 pt-1">
                    <label  class="treat-label mr-2">Provider's Signature</label>
                    <img  id="esign" height="50px" width="300px" src="{{PhoneSchoolInfo.StaffInitials}}" style="border: 1px solid grey;">
                    <!-- <img id="esign" height="50px" width="300px" *ngIf ="PhoneSchoolInfo.StaffInitials" src="{{PhoneSchoolInfo.StaffInitials}}">
                    <img id="esign" height="50px" width="300px" *ngIf ="!PhoneSchoolInfo.StaffInitials" src="{{constantVariables.Appconfiguration.Esign1}}"> -->
                    <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialog()" > Add E-Sign </button>
                    <!-- <div class="float-left col-md-4 p-0 mt-4 pt-1" > -->
                      <!-- <mat-error *ngIf="PhoneSchoolfrm.submitted && PhoneSchoolInfo.StaffInitials == undefined && ((roleAccess.isSign && isLicensed) || (!_dtoPatientInfo.isLicensed && roleAccess.isUnlicensed) || (_dtoPatientInfo.IsIntern && roleAccess.isInternSign ))">
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="PhoneSchoolInfo.StaffInitials == undefined">
                        Staff signtaure is required</mat-error>
                    </mat-error> -->
                         <!-- </div> -->
                  </div>
                  <div  class="float-right col-md-2 p-0 ">
                    <mat-form-field class="w-100">
                      <mat-label>Date</mat-label>
                      <input matInput [matDatepicker]="picker" [(ngModel)]="PhoneSchoolInfo.SignDate" #SignDate="ngModel" name="SignDate" >


                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>

                    </mat-form-field>
                  </div>
                </div>
             </div>
              <!-- newly not mandatory added sign -->
              <div class="col-md-12">
                <div  class="form-group clearfix m-0">
                <div  class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label mr-2">Secondary Signature</label>
                  <img  id="esign1" height="50px" width="300px" src="{{PhoneSchoolInfo.StaffInitial1}}" style="border: 1px solid grey;">
                  <!-- <img  id="esign1" height="50px" width="300px" *ngIf="PhoneSchoolInfo.StaffInitials1" src="{{PhoneSchoolInfo.StaffInitials1}}">
                  <img  id="esign1" height="50px" width="300px" *ngIf="!PhoneSchoolInfo.StaffInitials1" src="{{constantVariables.Appconfiguration.Esign1}}"> -->
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialogs()" [disabled]="secondarySign()"> Add E-Sign </button>
                  <div class="float-left col-md-4 p-0 mt-4 pt-1" >
                 </div>
                </div>
              </div>
              </div>
             <!-- newly not mandatory sign end-->

              <!-- <div class="col-md-4" style="padding-left: 59px;">
                <label class="treat-label">Client Name</label>

              <mat-form-field appearance="fill" style="padding-left: 14px;">
                <input matInput maxlength="50" appAlphabetOnly
                [(ngModel)]="PhoneSchoolInfo.clientName" #clientName="ngModel" name="clientName">
              </mat-form-field>
              </div> -->

            </div>
          </form>
             </div>
             <!--<div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==2"></div>
            </div> -->
            </div>

   </div>


  </div>
  </div>

        <!--Preview popup for pgrogress notes-->
        <div class="Activity-popup">
          <div class="modal"  tabindex="-1" role="dialog" [style.display]="PopUpPreview? 'block':'none'" >
             <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                   <div class="modal-header">
                      <h6 class="modal-title">Preview</h6>
                      <button type="button" class="close"  data-dismiss="modal" (click)="OnclosePopup()"
                      aria-label="Close" >
                      <span aria-hidden="true">&times;</span>
                   </button>
                   </div>
                   <div class="modal-body">
                    <div class="individual-threaphy-container">
                    <div  class="history-list col-md-12 p-0">
                      <div class="d-flex col-md-12">
                        <button class="ml-auto btn-print-hover mb-3 mr-2" mat-type="button" (click)="print()"><i class="fa fa-print"></i></button>
                      </div>
                       <div id="print-section" >
                        <div class="page-header" style=" text-align: center; font-size: 15px; font-weight: 500; margin-bottom: 25px; color: #173a64;">{{therapyName}} </div>
                      <div class="row top-details-psych">
                        <div class="col-md-3"><label>Client Name</label>
                        <p>{{_dtoPatientInfo.PatientName }}</p></div>
                        <div class="col-md-3"><label>Staff Name</label>
                          <p>{{_dtoPatientInfo.ClinicianName}}</p></div>
                          <div class="col-md-3"><label>Appointment Date</label>
                            <p>{{_dtoPatientInfo.StartDate | date:'MM/dd/YYYY'}}</p></div>
                            <div class="col-md-3"><label> Appointment Time</label>
                              <p>{{_dtoPatientInfo.StartTime | date:'hh:mm a'}}</p></div>
                       </div>

                       <div class="row" >
                        <div class="col-md-12 mb-2  ">
                           <label class="control-label-new font-weight-bold" >Check in Time</label>
                       <div class="cli_value_family">
                        <span>{{Checkin | date: 'hh:mm a'}}</span>
                      </div>
                    </div>
                  </div> <div class="row" >
                    <div class="col-md-12 mb-2  ">
                       <label class="control-label-new font-weight-bold" >Check out Time</label>
                   <div class="cli_value_family">
                    <span>{{CheckOut | date: 'hh:mm a'}}</span>
                  </div>
                </div>
              </div>
                  <div class="row" >
                    <div class="col-md-12 mb-2  ">
                       <label class="control-label-new font-weight-bold" >Form of Communication</label>
                   <div class="cli_value_family">
                    <span>{{Communication}}</span>
                  </div>
                </div>
              </div>
              <div class="row" >
                <div class="col-md-12 mb-2  ">
                   <label class="control-label-new font-weight-bold" >With whom did the communication take place?</label>
               <div class="cli_value_family">
                <span>{{PhoneSchoolInfo.WhomCommunicated}}</span>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-12 mb-2  ">
               <label class="control-label-new font-weight-bold" > Content (include items discussed, reaction, and pertinent information)</label>
           <div class="cli_value_family">
            <span>{{PhoneSchoolInfo.Content}}</span>
          </div>
        </div>
      </div>
      <div class="row" >
        <div class="col-md-12 mb-2  ">
         <label class="control-label-new font-weight-bold" >Psychiatrist Signtaure</label>
        <div class="cli_value_family">
        <span><img  id="esign_prev" height="50px" width="300px" src="{{PhoneSchoolInfo.StaffInitials}}" /></span>
        </div>
        </div>
        </div>
</div>
                </div>

                </div>
                     </div>
                </div>
              </div>
            </div>
          </div>

           <!--End Preview popup-->


<a mimetype="application/octet-stream" #downloadLink></a>

import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import * as moment from 'moment';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { DtoSuicidalAssessmentInfo, suicidalIdeationSeverity, suicideAssessment } from 'src/app/_models/_Treatment/_SuicidalAssessment';
import { dtoGetSuicidalSafetyContactDtl, dtoSucidalSafetyContract } from 'src/app/_models/_Treatment/_sucidalSafety';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { threadId } from 'worker_threads';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SuicidalScreener } from 'src/app/_models/_Treatment/_suicidalScreener';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';

@Component({
  selector: 'app-sucidal-risk-assessment',
  templateUrl: './sucidal-risk-assessment.component.html',
  styleUrls: ['./sucidal-risk-assessment.component.scss']
})
export class SucidalRiskAssessmentComponent implements OnInit {
  @ViewChild('SuicidalAssessmentFrm') SuicidalAssessmentFrm: NgForm;
  @ViewChild('SucidalSafetyfrm') SucidalSafetyfrm: NgForm;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  patientAccountId: number;
  _dtoPatientInfo: any;
  suicidalAssessmentInfo:DtoSuicidalAssessmentInfo;
  dtosuicidalIdeationSeverity:suicidalIdeationSeverity;
  IdeationTotal: number;
  ActivatingEventGiven: boolean;
  TreatmentHistoryGiven: boolean;
  ClinicalStatusGiven: boolean;
  InternalGiven: boolean;
  ExternalGiven: boolean;
  BehaviorsGiven: boolean;
  HighRiskGiven: boolean;
  HighRiskTRIAGEGiven: boolean;
  ModerateRiskGiven: boolean;
  ModerateRiskTRIAGEGiven: boolean;
  LowRiskGiven: boolean;
  LowRiskTRIAGEGiven: boolean;
  RiskLevelGiven: boolean;
  AllParentGiven: boolean;
  BriefEvaGiven: boolean;
  dtosuicideAssessment: suicideAssessment;
 _dtoSafetyContract: dtoSucidalSafetyContract;
 _dtoQthreeContact:Array<dtoGetSuicidalSafetyContactDtl>;
 _dtoQFourContact:Array<dtoGetSuicidalSafetyContactDtl>;
 _dtoQFiveContact:Array<dtoGetSuicidalSafetyContactDtl>;
  isESignAttached: boolean;
  signatureImg: any;
  updatedsignatureImg: any;
  isformtype: string;
  defaultImage: any;
  IsEditContract: boolean;
  currentdate: Date;
  IsEditSuicidalAssessment: boolean;
  _dtoSuicidalScreener:SuicidalScreener;
  IsEditSuicideScreener: boolean;
  IsSucideScreenerValid: boolean;
  SubmitSuicideScreener: boolean= false;
  IsSuiceScreenerDocument: boolean;
  IsSuicedeAssesment: boolean;
  IsSafetyContract: boolean;
  IdeationLifeTimeTotal:Number;
  ShowActivitylog: boolean;
  ActivitylogList: any[];
  Decrptedlst: any;
  roleAccess: RoelAccess;
  pdffile: any;
  constructor(public global: Global, public treatmentservice: TreatmentService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public datepipe: DatePipe,public roleaccessservice:PageAccessService,
    public clinicianservice: ClinicianService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.SuicidalRiskAssessment;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.suicidalAssessmentInfo = new DtoSuicidalAssessmentInfo();
    this.suicidalAssessmentInfo.suicidalIdeationDtl = new suicidalIdeationSeverity();
    this.suicidalAssessmentInfo.suicideAssessment = new suicideAssessment();
    this.dtosuicidalIdeationSeverity = new suicidalIdeationSeverity();
    this.dtosuicideAssessment = new suicideAssessment();
    this._dtoSafetyContract = new dtoSucidalSafetyContract();
    this._dtoSuicidalScreener = new SuicidalScreener();
    this._dtoSuicidalScreener.FormTypeId = 1;
    this._dtoQthreeContact = [];
    this._dtoQFourContact=[];
    this._dtoQFiveContact = [];
    this.currentdate = new Date();
    let threevar = new dtoGetSuicidalSafetyContactDtl();
    threevar.typeId=1;
    this._dtoQthreeContact.push(threevar)
    let fourvar = new dtoGetSuicidalSafetyContactDtl();
    fourvar.typeId=2;
    this._dtoQFourContact.push(fourvar)  
     let fivevar = new dtoGetSuicidalSafetyContactDtl();
     fivevar.typeId=3;
    this._dtoQFiveContact.push(fivevar)
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        
      }
    });
    this.IsSuicedeAssesment=true;
    this.getPatientSuicidalAssessment();
   // this.getSuicidalSafetycontract();
  }


  getPatientSuicidalAssessment(){
    this.spinnerservice.show();
    this.treatmentservice.getSuicidalAssessment(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {

         this._dtoPatientInfo = res;
         this.dtosuicideAssessment = res.suicideAssessment;
         this.dtosuicidalIdeationSeverity = res.suicidalIdeationDtl
         this.dtosuicideAssessment.riskLevel = Number(this.dtosuicideAssessment.riskLevel)
         if(this.dtosuicidalIdeationSeverity.suicidalIdeationSeverityId == 0 && this.dtosuicideAssessment.suicideAssessmentId==0){
          this.IsEditSuicidalAssessment = true;
         }
         else{
          this.IsEditSuicidalAssessment = false;
         }
          this.TotalIdeation();
          this.TotalIdeationLifetime();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  SucidePdf(item){
    if(this.IsSuicedeAssesment)
    {
      this.SuicideAssesment(item);
    }else if(this.IsSafetyContract)
    {
      this.SafetyContractPdf(item);
    }
  }
  SuicideAssesment(item)
  {
    this.spinnerservice.show();
    this.clinicianservice.getSuicideAssesmentPdf(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdffile = res.filePath;
        if(item==1){
          window.open(this.pdffile, '_blank');
        }
        else if(item==0){
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  SafetyContractPdf(item)
  {
    this.spinnerservice.show();
    this.clinicianservice.getSafetyContractPdf(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdffile = res.filePath;
        if(item==1){
          window.open(this.pdffile, '_blank');
        }
        else if(item==0){
        const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  OnIdeationIntensity(data){
    if(data.suicidalRiskClgroupId==constantVariables.SuicidalAssessmentCIMaster.Frequency){
      this.dtosuicidalIdeationSeverity.howManyTimesMonth = data.orderSeq
      this.dtosuicidalIdeationSeverity.howManyTimesLifeTime = data.orderSeq

    }
    else  if(data.suicidalRiskClgroupId==constantVariables.SuicidalAssessmentCIMaster.Duration){
      this.dtosuicidalIdeationSeverity.howLongLastMonth = data.orderSeq
      this.dtosuicidalIdeationSeverity.howLongLastLifeTime = data.orderSeq

      }
    else  if(data.suicidalRiskClgroupId==constantVariables.SuicidalAssessmentCIMaster.Controllability){
        this.dtosuicidalIdeationSeverity.controllabilityMonth = data.orderSeq
        this.dtosuicidalIdeationSeverity.controllabilityLifeTime = data.orderSeq

      }
    else  if(data.suicidalRiskClgroupId==constantVariables.SuicidalAssessmentCIMaster.Deterrents){
        this.dtosuicidalIdeationSeverity.deterrentsMonth = data.orderSeq
        this.dtosuicidalIdeationSeverity.deterrentsLifeTime = data.orderSeq

      }
    else  if(data.suicidalRiskClgroupId==constantVariables.SuicidalAssessmentCIMaster.ReasonsforIdeation){
      this.dtosuicidalIdeationSeverity.reasonsForIdeationMonth = data.orderSeq
      this.dtosuicidalIdeationSeverity.reasonsForIdeationLifeTime = data.orderSeq

    }
    this.TotalIdeation()
    this.TotalIdeationLifetime()
  }

  TotalIdeation(){
    this.IdeationTotal = Number(this.dtosuicidalIdeationSeverity.howManyTimesMonth==undefined? 0:this.dtosuicidalIdeationSeverity.howManyTimesMonth)+Number(this.dtosuicidalIdeationSeverity.howLongLastMonth==undefined? 0:this.dtosuicidalIdeationSeverity.howLongLastMonth)+
                         Number(this.dtosuicidalIdeationSeverity.controllabilityMonth==undefined? 0:this.dtosuicidalIdeationSeverity.controllabilityMonth) + Number(this.dtosuicidalIdeationSeverity.deterrentsMonth==undefined? 0:this.dtosuicidalIdeationSeverity.deterrentsMonth)+
                         Number(this.dtosuicidalIdeationSeverity.reasonsForIdeationMonth==undefined? 0:this.dtosuicidalIdeationSeverity.reasonsForIdeationMonth)
  }

  OnCheckBoxClick(element,type){
   if(type==constantVariables.SuicidalAssessmentCIMaster.ActivatingEvents){
    this.ActivatingEventGiven = false
        for(let item of this._dtoPatientInfo.activiatingEvent){
          if(item.isChecked == true){
            this.ActivatingEventGiven = true
          }
        }

  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.TreatmentHistory){
    this.TreatmentHistoryGiven = false
        for(let item of this._dtoPatientInfo.treatmentHistory){
          if(item.isChecked == true){
            this.TreatmentHistoryGiven = true
          }
        }
  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.ClinicalStatus){
    this.ClinicalStatusGiven = false
        for(let item of this._dtoPatientInfo.clinicalStatus){
          if(item.isChecked == true){
            this.ClinicalStatusGiven = true
          }
        }
  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.Internal){
    this.InternalGiven = false
        for(let item of this._dtoPatientInfo.internal){
          if(item.isChecked == true){
            this.InternalGiven = true
          }
        }
  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.External){
    this.ExternalGiven = false
        for(let item of this._dtoPatientInfo.external){
          if(item.isChecked == true){
            this.ExternalGiven = true
          }
        }
  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.Behaviors){
    this.BehaviorsGiven = false
        for(let item of this._dtoPatientInfo.behaviors){
          if(item.isChecked == true){
            this.BehaviorsGiven = true
          }
        }
  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.HighRisk){
    this.HighRiskGiven = false
        for(let item of this._dtoPatientInfo.highRisk){
          if(item.isChecked == true){
            this.HighRiskGiven = true
          }
        }
  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.HighRiskTRIAGE){
    this.HighRiskTRIAGEGiven = false
        for(let item of this._dtoPatientInfo.highRiskTriage){
          if(item.isChecked == true){
            this.HighRiskTRIAGEGiven = true
          }
        }
  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.ModerateRisk){
    this.ModerateRiskGiven = false
        for(let item of this._dtoPatientInfo.moderateRisk){
          if(item.isChecked == true){
            this.ModerateRiskGiven = true
          }
        }
  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.ModerateRiskTRIAGE){
    this.ModerateRiskTRIAGEGiven = false
        for(let item of this._dtoPatientInfo.moderateRiskTriage){
          if(item.isChecked == true){
            this.ModerateRiskTRIAGEGiven = true
          }
        }
  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.LowRisk){
    this.LowRiskGiven = false
        for(let item of this._dtoPatientInfo.lowRisk){
          if(item.isChecked == true){
            this.LowRiskGiven = true
          }
        }
  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.LowRiskTRIAGE){
    this.LowRiskTRIAGEGiven = false
        for(let item of this._dtoPatientInfo.lowRiskTriage){
          if(item.isChecked == true){
            this.LowRiskTRIAGEGiven = true
          }
        }
  }
  else  if(type==constantVariables.SuicidalAssessmentCIMaster.RiskLevel){
    this.RiskLevelGiven = false
        for(let item of this._dtoPatientInfo.RiskLevelGiven){
          if(item.isChecked == true){
            this.TreatmentHistoryGiven = true
          }
        }
  }
 
  }
  OnclinicalNotes(element,type){
    if(type==constantVariables.SuicidalClinicalNotesMaster.AllParent){
      this.AllParentGiven = false
          for(let item of this._dtoPatientInfo.clinicalNotes){
            if(item.isChecked == true){
              this.AllParentGiven = true
            }
          }
    }
    else  if(type==constantVariables.SuicidalClinicalNotesMaster.BriefEvaluationSummary){
      this.BriefEvaGiven = false
          for(let item of this._dtoPatientInfo.briefEvaluationSummary){
            if(item.isChecked == true){
              this.BriefEvaGiven = true
            }
          }
    }
  }


  postSucidalAssessment(){
    if(!this.SuicidalAssessmentFrm.valid || this.dtosuicidalIdeationSeverity.howLongLastMonth == undefined || this.dtosuicidalIdeationSeverity.howManyTimesMonth == undefined ||
        this.dtosuicidalIdeationSeverity.deterrentsMonth ==undefined || this.dtosuicidalIdeationSeverity.controllabilityMonth ==undefined ||
         this.dtosuicidalIdeationSeverity.reasonsForIdeationMonth == undefined || this.dtosuicideAssessment.riskLevel == 0 || this.dtosuicidalIdeationSeverity.howLongLastLifeTime == undefined || this.dtosuicidalIdeationSeverity.howManyTimesLifeTime == undefined
         || this.dtosuicidalIdeationSeverity.deterrentsLifeTime == undefined || this.dtosuicidalIdeationSeverity.controllabilityLifeTime == undefined || this.dtosuicidalIdeationSeverity.reasonsForIdeationLifeTime == undefined){
          this.snackbar.error("Please fill mandatory fields")
          return;
         }
         else{
   this.spinnerservice.show();
    this.dtosuicideAssessment.activatingEvents = []
    this._dtoPatientInfo.activiatingEvent.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.activatingEvents.push(element.id)
      }
    });
    this.dtosuicideAssessment.activatingEvents = this.dtosuicideAssessment.activatingEvents.toString();
    this.dtosuicideAssessment.treatmentHistory = []
    this._dtoPatientInfo.treatmentHistory.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.treatmentHistory.push(element.id)
      }
    });
    this.dtosuicideAssessment.treatmentHistory = this.dtosuicideAssessment.treatmentHistory.toString();
    this.dtosuicideAssessment.clinicalStatus = []
    this._dtoPatientInfo.clinicalStatus.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.clinicalStatus.push(element.id)
      }
    });
    this.dtosuicideAssessment.clinicalStatus = this.dtosuicideAssessment.clinicalStatus.toString();
    this.dtosuicideAssessment.protectiveFactorsInternal = []
    this._dtoPatientInfo.internal.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.protectiveFactorsInternal.push(element.id)
      }
    });
    this.dtosuicideAssessment.protectiveFactorsInternal = this.dtosuicideAssessment.protectiveFactorsInternal.toString();
    this.dtosuicideAssessment.protectiveFactorsExternal = []
    this._dtoPatientInfo.external.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.protectiveFactorsExternal.push(element.id)
      }
    });
    this.dtosuicideAssessment.protectiveFactorsExternal = this.dtosuicideAssessment.protectiveFactorsExternal.toString();
    this.dtosuicideAssessment.behaviors = []
    this._dtoPatientInfo.behaviors.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.behaviors.push(element.id)
      }
    });
    this.dtosuicideAssessment.behaviors = this.dtosuicideAssessment.behaviors.toString();
    this.dtosuicideAssessment.riskStratificationHigh = []
    this._dtoPatientInfo.highRisk.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.riskStratificationHigh.push(element.id)
      }
    });
    this.dtosuicideAssessment.riskStratificationHigh = this.dtosuicideAssessment.riskStratificationHigh.toString();
    this.dtosuicideAssessment.riskStratificationHighTriage = []
    this._dtoPatientInfo.highRiskTriage.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.riskStratificationHighTriage.push(element.id)
      }
    });
    this.dtosuicideAssessment.riskStratificationHighTriage = this.dtosuicideAssessment.riskStratificationHighTriage.toString();
    this.dtosuicideAssessment.riskStratificationModerate = []
    this._dtoPatientInfo.moderateRisk.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.riskStratificationModerate.push(element.id)
      }
    });
    this.dtosuicideAssessment.riskStratificationModerate = this.dtosuicideAssessment.riskStratificationModerate.toString();
    this.dtosuicideAssessment.riskStratificationModerateTriage = []
    this._dtoPatientInfo.moderateRiskTriage.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.riskStratificationModerateTriage.push(element.id)
      }
    });
    this.dtosuicideAssessment.riskStratificationModerateTriage = this.dtosuicideAssessment.riskStratificationModerateTriage.toString();
    this.dtosuicideAssessment.riskStratificationLow = []
    this._dtoPatientInfo.lowRisk.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.riskStratificationLow.push(element.id)
      }
    });
    this.dtosuicideAssessment.riskStratificationLow = this.dtosuicideAssessment.riskStratificationLow.toString();
    this.dtosuicideAssessment.riskStratificationLowTriage = []
    this._dtoPatientInfo.lowRiskTriage.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.riskStratificationLowTriage.push(element.id)
      }
    });
    this.dtosuicideAssessment.riskStratificationLowTriage = this.dtosuicideAssessment.riskStratificationLowTriage.toString();
    this.dtosuicideAssessment.clinicalNote = []
    this._dtoPatientInfo.clinicalNotes.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.clinicalNote.push(element.id)
      }
    });
    this.dtosuicideAssessment.clinicalNote = this.dtosuicideAssessment.clinicalNote.toString();
    this.dtosuicideAssessment.briefEvaluationSummary = []
    this._dtoPatientInfo.briefEvaluationSummary.forEach(element => {
      if(element.isChecked==true){
        this.dtosuicideAssessment.briefEvaluationSummary.push(element.id)
      }
    });
    this.dtosuicideAssessment.briefEvaluationSummary = this.dtosuicideAssessment.briefEvaluationSummary.toString();
    this.suicidalAssessmentInfo.suicideAssessment = this.dtosuicideAssessment;
    this.suicidalAssessmentInfo.suicidalIdeationDtl = this.dtosuicidalIdeationSeverity;
    console.log(this.suicidalAssessmentInfo)
    const dataToEncrypt = this.commonService.DataToEncrypt(this.suicidalAssessmentInfo, false);
    this.treatmentservice.postSuicidalAssessmentForm(dataToEncrypt).subscribe(
      res=>{
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.IsSuicedeAssesment=true;
        this.getPatientSuicidalAssessment();
      },
      err => {

        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    )
  }
}

OnBack(){
  if(this.global.ismytask == undefined || this.global.ismytask){
    window.close(); 
  }
  else{this.router.navigate(['/clinician'], { queryParams: { menu: this.commonService.FrontEndEncryption(3)} });}
}

getSuicidalSafetycontract(){
  this.spinnerservice.show();
    this.treatmentservice.getSuicidalsafetycontract(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {

         this._dtoSafetyContract = res;
         if(this._dtoSafetyContract.suicidalSafetyContractId == 0){
          this.IsEditContract = true;
          this._dtoSafetyContract.clinentSignDate = new Date();
          this._dtoSafetyContract.therapistSignDate = new Date();
         }
         else{
          this.IsEditContract = false;
          this._dtoQthreeContact = this._dtoSafetyContract.qthreeContactDtl 
          this._dtoQFourContact = this._dtoSafetyContract.qfourContactDtl 
          this._dtoQFiveContact = this._dtoSafetyContract.qfiveContactDtl 
         }
         
      // console.log(this._dtoSafetyContract)
          
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
}

addContactDtl(item) {
  if(item==1){
    let threevar = new dtoGetSuicidalSafetyContactDtl();
    threevar.typeId=1;
    this._dtoQthreeContact.push(threevar)
  }
  else if(item==2){
 let fourvar = new dtoGetSuicidalSafetyContactDtl();
 fourvar.typeId=2;
    this._dtoQFourContact.push(fourvar)
  }
  else if(item==3){
    let fivevar = new dtoGetSuicidalSafetyContactDtl();
    fivevar.typeId=3;
    this._dtoQFiveContact.push(fivevar)
  }
}

removeclinicalinformation(data, index,type) {
  if (data.suicidalSafetyContactDtlId != undefined) {
    this.removeexistConinfo(data);
    this.getSuicidalSafetycontract();
  }
  else{
    if(type==1){
      this._dtoQthreeContact.splice(index, 1);
    }
    else if(type==2){
      this._dtoQFourContact.splice(index, 1);
    }
   else if(type==3){
      this._dtoQFiveContact.splice(index, 1);
    }
  }
}
removeexistConinfo(data) {
  this.spinnerservice.show();
  this.treatmentservice.removeSuicidalSafetyContact(data.psychiatricMedicationDtlId).subscribe((res: any) => {
    this.spinnerservice.hide();
    
  },
    err => {
      this.spinnerservice.hide();
    }
  );
}
openDialog(item) {
  this.global.isconsentesign = false;
  const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
    data: { isfutureSign: false, isParentsign: true },
    autoFocus: false 
  });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if(item==1){
      if (
        this.global.isconsentesign != undefined &&
        this.global.isconsentesign
      ) {
        this.signatureImg = this.updatedsignatureImg;
        this._dtoSafetyContract.clientSign = this.updatedsignatureImg;
       // this.IncReportfrmInfo.IsAttachEsign = true;
        (document.getElementById('esign1') as HTMLImageElement).src =
          this.signatureImg;
      } else if (
        !this.global.isconsentesign &&
        this.global.formtype != null &&
        this.global.formtype != undefined
      ) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this._dtoSafetyContract.clientSign = this.global.consentesign;
        //this.IncReviewInfo.IsAttachEsign = true;
        (document.getElementById('esign1') as HTMLImageElement).src =
          this.signatureImg;
      } else {
        this.signatureImg = this.defaultImage;
        //this.IncReportfrmInfo.IsAttachEsign = true;
        this._dtoSafetyContract.clientSign= this.defaultImage;
      }
    }
    else if(item==2){
      if (
        this.global.isconsentesign != undefined &&
        this.global.isconsentesign
      ) {
        this.signatureImg = this.updatedsignatureImg;
        this._dtoSafetyContract.therapistSign = this.updatedsignatureImg;
       // this.IncReportfrmInfo.IsAttachEsign = true;
        (document.getElementById('esign2') as HTMLImageElement).src =
          this.signatureImg;
      } else if (
        !this.global.isconsentesign &&
        this.global.formtype != null &&
        this.global.formtype != undefined
      ) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this._dtoSafetyContract.therapistSign = this.global.consentesign;
        //this.IncReviewInfo.IsAttachEsign = true;
        (document.getElementById('esign2') as HTMLImageElement).src =
          this.signatureImg;
      } else {
        this.signatureImg = this.defaultImage;
        //this.IncReportfrmInfo.IsAttachEsign = true;
        this._dtoSafetyContract.therapistSign= this.defaultImage;
      }
    }
    
  });
}

postSuicidalSafetyCOntract(){
  if(!this.SucidalSafetyfrm.valid || this._dtoSafetyContract.clientSign==undefined || this._dtoSafetyContract.therapistSign == undefined){
    this.snackbar.error("Please fill mandatory fields")
    return
  }
  else{
    this.spinnerservice.show()
    this._dtoSafetyContract.qthreeContactDtl = this._dtoQthreeContact;
    this._dtoSafetyContract.qfourContactDtl = this._dtoQFourContact;
    this._dtoSafetyContract.qfiveContactDtl = this._dtoQFiveContact;
    this._dtoSafetyContract.patientAccountId = this.patientAccountId;
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoSafetyContract, false);
    this.treatmentservice.PostSuicidalSafetyContract(dataToEncrypt).subscribe(
      res=>{
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.getSuicidalSafetycontract();
      },
      err => {

        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    )
  }
}
OnTabChange(item:MatTabChangeEvent){
  this.IsSuiceScreenerDocument = false
  if(item.index==0){
    this.IsSuicedeAssesment=true;
    this.IsSafetyContract=false;
    this.SuicidalAssessmentFrm.resetForm();
    this.SucidalSafetyfrm.resetForm();
    this.SubmitSuicideScreener = false;
    this.getPatientSuicidalAssessment();
  }
  if(item.index==1){
    this.IsSuicedeAssesment=false;
    this.IsSafetyContract=true;
    this.SuicidalAssessmentFrm.resetForm();
    this.SucidalSafetyfrm.resetForm();
    this.SubmitSuicideScreener = false;
    this.getSuicidalSafetycontract();
  }
  else if(item.index ==2){
    this.IsSuicedeAssesment=false;
    this.IsSafetyContract=false;
    this.IsSuiceScreenerDocument = true;
    this.SuicidalAssessmentFrm.resetForm();
    this.SucidalSafetyfrm.resetForm();
    this.SubmitSuicideScreener = false;
  //  this.getSuicidalScreener();
  }
}


getSuicidalScreener(){
  this.spinnerservice.show();
    this.treatmentservice.getSuicidalScreener(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {

         this._dtoSuicidalScreener = res;
         if(this._dtoSuicidalScreener.suicideScreeningId == 0){
          this.IsSucideScreenerValid = false;
          this.IsEditSuicideScreener = true;
         }
         else{
          this.IsEditSuicideScreener = false; 
         }        
          console.log(this._dtoSuicidalScreener)
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
}
OnRadioButtonChange(item,type){
if(type==1){
  if(item==1){
    this._dtoSuicidalScreener.wishToDead = true;
  }
  else if(item == 2){
    this._dtoSuicidalScreener.wishToDead = false;
  }
}
if(type==2){
  if(item==1){
    this._dtoSuicidalScreener.suicidalThoughts = true;
  }
  else if(item == 2){
    this._dtoSuicidalScreener.suicidalThoughts = false;
    this._dtoSuicidalScreener.thoughtsWithoutPlan = null;
    this._dtoSuicidalScreener.intentWithoutPlan = null;
    this._dtoSuicidalScreener.intentWithPlan = null;
  }
}
if(type==3){
  if(item==1){
    this._dtoSuicidalScreener.thoughtsWithoutPlan = true;
  }
  else if(item == 2){
    this._dtoSuicidalScreener.thoughtsWithoutPlan = false;
  }
}
if(type==4){
  if(item==1){
    this._dtoSuicidalScreener.intentWithoutPlan = true;
  }
  else if(item == 2){
    this._dtoSuicidalScreener.intentWithoutPlan = false;
  }
}
if(type==5){
  if(item==1){
    this._dtoSuicidalScreener.intentWithPlan = true;
  }
  else if(item == 2){
    this._dtoSuicidalScreener.intentWithPlan = false;
  }
}
if(type==6){
  if(item==1){
    this._dtoSuicidalScreener.suicideBehaviourLifeTime = true;
  }
  else if(item == 2){
    this._dtoSuicidalScreener.suicideBehaviourLifeTime = false;
  }
}
if(type==7){
  if(item==1){
    this._dtoSuicidalScreener.suicideBehaviourThreeMonths = true;
  }
  else if(item == 2){
    this._dtoSuicidalScreener.suicideBehaviourThreeMonths = false;
  }
}
//this.IsSucideScreenerValid = this.OnSuicideScreenerValid()
}

OnSuicideScreenerValid(){
  this.SubmitSuicideScreener = true;
  if(this._dtoSuicidalScreener.suicidalThoughts==true){
    if(this._dtoSuicidalScreener.wishToDead!=null&&this._dtoSuicidalScreener.suicidalThoughts!=null&&this._dtoSuicidalScreener.thoughtsWithoutPlan!=null&&this._dtoSuicidalScreener.intentWithPlan!=null&&this._dtoSuicidalScreener.intentWithoutPlan!=null&&this._dtoSuicidalScreener.suicideBehaviourLifeTime!=null&&this._dtoSuicidalScreener.suicideBehaviourThreeMonths!=null)
    {
      this.PostSuicideScreener();
    }
    else{
      this.snackbar.error("Please fill mandatory fields")
      return;
    }

  }
  else{
    if(this._dtoSuicidalScreener.wishToDead!=null&&this._dtoSuicidalScreener.suicidalThoughts!=null&&this._dtoSuicidalScreener.suicideBehaviourLifeTime!=null&&this._dtoSuicidalScreener.suicideBehaviourThreeMonths!=null){
      this.PostSuicideScreener();
    }
    else{
      this.snackbar.error("Please fill mandatory fields")
      return;
    }

  }

}

PostSuicideScreener(){
  
    this.spinnerservice.show()
    this._dtoSuicidalScreener.patientAccountId = this.patientAccountId;
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoSuicidalScreener, false);
    this.treatmentservice.PostSuicideScreener(dataToEncrypt).subscribe(
      res=>{
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
       // this.SubmitSuicideScreener = false;
       this.router.navigateByUrl('/treatmentplan-list');
      },
      err => {

        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    )
  
}
OnScreenerDocument(){
  this.router.navigate(['/suicide-documents'],{queryParams:{id:this.commonService.FrontEndEncryption(this.patientAccountId)}});
}
TotalIdeationLifetime(){
  this.IdeationLifeTimeTotal = Number(this.dtosuicidalIdeationSeverity.howManyTimesLifeTime==undefined? 0:this.dtosuicidalIdeationSeverity.howManyTimesLifeTime)+Number(this.dtosuicidalIdeationSeverity.howLongLastLifeTime==undefined? 0:this.dtosuicidalIdeationSeverity.howLongLastLifeTime)+
                       Number(this.dtosuicidalIdeationSeverity.controllabilityLifeTime==undefined? 0:this.dtosuicidalIdeationSeverity.controllabilityLifeTime) + Number(this.dtosuicidalIdeationSeverity.deterrentsLifeTime==undefined? 0:this.dtosuicidalIdeationSeverity.deterrentsLifeTime)+
                       Number(this.dtosuicidalIdeationSeverity.reasonsForIdeationLifeTime==undefined? 0:this.dtosuicidalIdeationSeverity.reasonsForIdeationLifeTime)
}
OnchangeIdeationLifeTime(val,item){
 
  var k;  
  k = Number(val.key);  
 // val.target.value = 0;  
  if(k > 0 && k <= 5){
    val.target.value = k;
    if(item==1){
      this.dtosuicidalIdeationSeverity.howManyTimesLifeTime = k;
    }
    if(item==2){
      this.dtosuicidalIdeationSeverity.howLongLastLifeTime = k;
    }
    if(item==3){
      this.dtosuicidalIdeationSeverity.controllabilityLifeTime = k;
    }
    if(item==4){
      this.dtosuicidalIdeationSeverity.deterrentsLifeTime = k;
    }
    if(item==5){
      this.dtosuicidalIdeationSeverity.reasonsForIdeationLifeTime = k;
    }
    this.TotalIdeationLifetime();
   // return true;
  }
  else{
    val.preventDefault();
    return false;
  }
 

}
SuicdeAssessmentlogs(){
  this.ShowActivitylog = true;
  this.ActivitylogList = []
  this.spinnerservice.show()
  this.treatmentservice.GetActivityLogsForSuicide(this.patientAccountId).subscribe(
    (res: any) => {
      if (res) {
        this.Decrptedlst = res;
        this.ActivitylogList = this.Decrptedlst;
       // console.log(this.leadhistory)
      }
      this.spinnerservice.hide()
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
onCloseActivitylog(){
  this.ShowActivitylog = false;
}
OnFormTypechange(){
  this._dtoSuicidalScreener.suicidalThoughts = null;
}
calculateAge() {
  this._dtoPatientInfo.age = moment().diff(this._dtoPatientInfo.dob, 'years');

}
}

import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { UserserviceService } from '../userservice.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/global/custom-validators';
import { AuthenticationService } from 'src/app/admin/authentication.service';
import { constantVariables } from 'src/app/constant/constant';
import {Location} from '@angular/common';
declare function EncryptFieldData(data): any;

@Component({
  selector: 'app-changepasswordprofile',
  templateUrl: './changepasswordprofile.component.html',
  styleUrls: ['./changepasswordprofile.component.scss']
})
export class ChangepasswordprofileComponent implements OnInit {

  userId :number;
  submitted:boolean=false;
  changepassswordForm: FormGroup;
  errorMessageForSamePassword:boolean = false;
  submitClicked:boolean = false;
  isActivation: boolean = false;
  showErrorPassword = false;
  isinvalid: boolean = false;
  myusername: any;
  inputType: string="password";
  oldPassEye = true;
  newPassEye = true;
  confirmPassEye = true;
  isOldPasswordCorrect: boolean=false;
  isclicked:boolean= false;
  constructor(
    public spinnerservice: NgxSpinnerService,
    private snackbar: SnackBarService,
    private userService:UserserviceService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private _location: Location,
  ) { }

  ngOnInit(): void {
    this.spinnerservice.show();
   this.userId = Number(localStorage.getItem("id"));
   this.userService.getProfileInfoById(this.userId).subscribe((res:any)=>{
     this.spinnerservice.hide();
    
   },  err => {
     this.spinnerservice.hide();
     this.snackbar.error(err.error);
   },)
   this.changepassswordForm = this.formBuilder.group({
     OldPassword: ['', [Validators.required]],
     // Password: ['', [Validators.required, Validators.minLength(6), Validators.pattern]],
     Password: [
       null,
       Validators.compose([
         Validators.required,
         // check whether the entered password has a number
         CustomValidators.patternValidator(/\d/, {
           hasNumber: true
         }),
         // check whether the entered password has upper case letter
         CustomValidators.patternValidator(/[A-Z]/, {
           hasCapitalCase: true
         }),
         // check whether the entered password has a lower case letter
         // CustomValidators.patternValidator(/[a-z]/, {
         //   hasSmallCase: true
         // }),
         // check whether the entered password has a special character
         CustomValidators.patternValidator(
           /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
           {
             hasSpecialCharacters: true
           }
         ),
         Validators.minLength(8)
       ])
     ],

     // ConfirmPassword: ['', [Validators.required, Validators.minLength(6)]],
     ConfirmPassword: [null, Validators.compose([Validators.required])],
     Screen: ['UpdatePassword']
   }, {
     //validator: MustMatch('Password', 'ConfirmPassword')
     validator: CustomValidators.passwordMatchValidator
   });
 }
 get f() { return this.changepassswordForm.controls; }
 
 
 showErrorPopup() {
  this.showErrorPassword = true;
}

hideErrorPopup() {
  this.showErrorPassword = false;
}
changeStyleMouseOut($event){
  this.isinvalid = $event.type == 'mouseover' ? this.showErrorPassword = true : this.showErrorPassword = false;
  this.errorMessageForSamePassword =false;
}
changeStyle($event) {
  this.isinvalid = $event.type == 'mouseover' ? this.showErrorPassword = true : this.showErrorPassword = false;
}

hideErrorMessage()
{
  this.errorMessageForSamePassword =false;
  this.isOldPasswordCorrect =false;
}
 onSubmit() {
  this.submitted = true;
  this.changepassswordForm.get('ConfirmPassword').setValidators(Validators.compose([Validators.required]));
  this.changepassswordForm.get('ConfirmPassword').updateValueAndValidity();
  if (this.changepassswordForm.invalid) {
    return;
  }
  if(this.changepassswordForm.controls.OldPassword.value == this.changepassswordForm.controls.Password.value)
  {
    this.errorMessageForSamePassword = true;
    return
  }
  else{
    this.errorMessageForSamePassword = false;
  }
  if(!this.submitClicked)
  {
  let emailid = localStorage.getItem('email');
  this.submitClicked = true;
  this.isActivation = true;
  let _ChangePasswordData = {
    Email : emailid,
    OldPassword: this.changepassswordForm.controls.OldPassword.value,
    Password: this.changepassswordForm.controls.Password.value,
    ConfirmPassword: this.changepassswordForm.controls.ConfirmPassword.value,
    IsResetPassword: this.isActivation == true ? 0 : 1,
    Token: null,
    PasswordID: '2',
    //Authid : this.Authid
  };
  const dataToEncrypt = {
    objChangepassword: EncryptFieldData((JSON.stringify(_ChangePasswordData))),
  };
    this.onchangePassword(dataToEncrypt);
}

}
OnBack(){
  this._location.back();
}
onchangePassword(dataToEncrypt) {
  this.spinnerservice.show();
  this.userService.ChangepasswordProfile(dataToEncrypt)
    .subscribe(
      _data => {
        this.spinnerservice.hide();
        this.submitClicked = false;
         this.snackbar.success(constantVariables.SuccessMessage.Passwordupdatedsuccessfully);
         this.authenticationService.onLogout();
      },
      _error => {
        this.spinnerservice.hide();
        if(_error.error=="Please enter correct old password")
        {
          this.isOldPasswordCorrect = true;
        }else{
          this.snackbar.error(_error.error);
        }
        this.submitClicked = false;
      });   
}
}

export class PriceCatalogue {
    priceCatalogueId: number;
    description: string;
    catalogueName: string;
    accessLevel: number;
    location:number;   
    programType: number;
    sfccmasterId:any;
    components:any;
}
export class PriceComponent {
    priceComponentId: number;
    priceCatalogueId: number;
    isInsuranceCoverage: boolean;
    anthem: number;
    sca: number;
    others: number;
    deductibleType:number;
    componentDetails: Array<PriceComponentDetails>;
}
export class PriceComponentDetails {
    priceComponentDetailsId: number;
    priceComponentId: number;
    componentName: string;
    priceValue: string;
    payFrequency: number;
    coInsurance: number;
    minPercentage:string;
    maxPercentage:string;
    fixedPercentage:string;
    anthem: number;
    sca: number;
    others: number;
    deductibleType:number;
    deductibleComments:string;
}
export class skillTaught{
    skillTaughtMasterId:number  
    isPcp:boolean  
    isIop:boolean  
    skillTaught:string  
    location:string  
    therapy:string  
    isGlobal:boolean  
    createdOn:Date  
    updatedOn:Date  
}
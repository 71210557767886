import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MasterInfo } from 'src/app/_models/_Common/masters';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { CommonService } from 'src/app/common/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { cloneDeep } from "lodash";
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PeriodicElement } from 'src/app/billing/billing-masters/billing-masters.component';
@Component({
  selector: 'app-masters',
  templateUrl: './masters.component.html',
  styleUrls: ['./masters.component.scss']
})
export class MastersComponent implements OnInit {
  @ViewChild('table', { static: true }) table: MatTable<PeriodicElement>;

  @Input() pageid: Number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  masterform: NgForm;
  displayedColumns = ['sno', 'MasterName', 'Description', 'IsActive', 'actions'];
  dataSource: any=[];
  pageId: any;
  lstMaster: any;
  masterData: MasterInfo;
  isEditMaster = false;
  headerdata: string;
  constantMaster = constantVariables.masterMenu;
  Savestarted: boolean;
  roleAccess: any;
  masterresponse :any;
  dragDisabled = true;
  constructor(private route: ActivatedRoute, public commonService: CommonService,
    private snackbar: SnackBarService, public spinnerservice: NgxSpinnerService,
    private dialog: MatDialog
    ,public roleaccessservice:PageAccessService
    ) { }

  ngOnInit(): void {
    this.masterData = new MasterInfo();
    this.pageId = Number(this.pageid);
    const pageid = constantVariables.pagelists.Settings;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
	
    this.getMasters(this.pageid);
    this.headerdata = this.constantMaster[this.pageId - 1].label
    // this.route.queryParams.subscribe(params => {
    //   if (params != null) {
    //     this.pageId = Number(params.pageid);
    //     this.getMasters(this.pageId)
    //     if (this.pageId != 5) {
    //       if (this.pageId == this.constantMaster[this.pageId - 1].value) {
    //         this.headerdata = this.constantMaster[this.pageId - 1].label
    //       }
    //     }
    //     else {
    //       this.headerdata = "Grade"
    //     }
    //   }
    // });

  }
  getMasters(id) {
    //this.spinnerservice.show();
    this.commonService.getMasters(id).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this.masterresponse=res;
          this.lstMaster = this.commonService.decrypdata(res.commonMaster);
          this.dataSource = new MatTableDataSource(this.lstMaster);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },
      err => {

      },
    );
  }
  omit_special_char(event)
  {   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }
  addorUpdate(form: NgForm) {
    if(!form.valid){
      return
    }
    this.spinnerservice.show();
    this.Savestarted = true;
    this.masterData.Screen = this.pageId;
    const dataToEncrypt = this.commonService.DataToEncrypt(this.masterData, false);
    this.commonService.postMasters(dataToEncrypt).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        this.getMasters(this.pageId);
        var response=res;
        if(response.split(':')[0]=="Source ")
        {
          this.snackbar.error(response.split(':')[1]);
        }  
        else if(response.split(':')[0] == "LeadType ")
        {
          this.snackbar.error(response.split(':')[1]);
        }
        else if(response.split(':')[0] == "Grade ")
        {
          this.snackbar.error(response.split(':')[1]);
        }
        else{
          this.snackbar.success(res.replace('{mastertype}', this.headerdata));
        }
        this.masterData = new MasterInfo();
        form.resetForm();

        this.isEditMaster = false;
        this.Savestarted = false;
      }
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  resetMasterform(form: NgForm) {
    form.resetForm();
    this.masterData = new MasterInfo();
    this.isEditMaster = false;

  }
  oneditMasters(element) {
    this.isEditMaster = true;
    this.masterData = cloneDeep(element);
  }
  ondeleteMasters(element) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();

        element.Screen = this.pageId;
        const dataToEncrypt = this.commonService.DataToEncrypt(element, false);
        this.commonService.deleteMasters(element).subscribe(
          (res: any) => {
            this.getMasters(this.pageId);
            this.spinnerservice.hide();
            this.snackbar.success(JSON.parse(res).message.replace('{mastertype}', this.headerdata));
          },
          err => {
            this.snackbar.error(err.error.replace('{mastertype}', this.headerdata));
            this.spinnerservice.hide();
          },
        );
      }
    });

  }
  onChangeStatusMasters(element) {

    this.spinnerservice.show();
    element.IsActive = !element.IsActive;
    element.Screen = this.pageId;
    const dataToEncrypt = this.commonService.DataToEncrypt(element, false);
    this.commonService.changeStatusMaster(dataToEncrypt).subscribe(
      (res: any) => {
        this.getMasters(this.pageId);
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message.replace('{mastertype}', this.headerdata));
      },
      err => {
        this.snackbar.error(err.error.replace('{mastertype}', this.headerdata));
        this.spinnerservice.hide();
      },
    );

  }
  onPageChangeMaster($event) {
    this.lstMaster = this.masterresponse.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }


  drop(event: CdkDragDrop<string[]>) {  
    this.dragDisabled = true;

    const previousIndex = this.lstMaster.findIndex((d) => d === event.item.data);

    moveItemInArray(this.lstMaster, previousIndex, event.currentIndex);
    this.table.renderRows();


    this.commonService.postListOrder(this.lstMaster).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message)
        //this.ddlFormName = JSON.parse(res);
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }  
}

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent,HttpErrorResponse  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { constantVariables } from 'src/app/constant/constant';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private loginService: LoginService,private snackbar: SnackBarService,) {

    }

   /* intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (localStorage.getItem('token') != null) {
            const clonedReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            });
            return next.handle(clonedReq).pipe(
                tap(
                    succ => { },
                    err => {
                        if (err.status == 401){
                            localStorage.removeItem('token');
                            this.router.navigateByUrl('/user/login');
                        }else if(err.status == 403){
                            this.router.navigateByUrl('/forbidden');
                        }
                    }
                )
            )
        }
        else
            return next.handle(req.clone());
    }
    getuseridfromsession
    roleid, accessId
    */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let authReq = request;
        const token = this.loginService.getAuthToken();

        if (token != null) {
          authReq = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token)


        });
        }
        // return next.handle(authReq);
        return next.handle(authReq).pipe(
            tap(
                succ => { },
               
                error => {
                     if (error.status == 401){       
                         localStorage.removeItem('token');
                         this.router.navigateByUrl('/login');
                     }
                     //else if (error.status == 403){
                    //     this.router.navigateByUrl('/forbidden');
                    // }
                    if(error.error instanceof ProgressEvent){
                        this.snackbar.error(constantVariables.ErrorMessage.ERROR_INTERNET);
                    }else{
                        //this.snackbar.error(error.error);
                    }
                }
            )
        );
      }
     

}

import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef, ChangeDetectorRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { FormControl, NgForm } from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import * as _ from "lodash";
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { constantVariables } from 'src/app/constant/constant';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { PatientService } from 'src/app/patient/patient.service';
import { colors } from 'src/app/_models/_Patient/_ScheduleAppoinment';
import RRule from 'rrule';
import { ViewPeriod } from 'calendar-utils';
import { DatePipe } from '@angular/common';
interface RecurringEvent {
  title: string;
  color: any;
  end?: any;
  id?: any;
  fromTime?: any;
  flag?:any;
  scheduledDate?: any;
  therapy?: any;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
    until: any;
  };
  
}
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { id } from 'date-fns/locale';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { timeStamp } from 'console';
import { TherapyPopObj } from 'src/app/_models/_Treatment/_TherapyPopUpObj';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MenuList, RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TreatmentService } from '../../treatment/treatment.service';
import { _dtoPatientDischarge } from 'src/app/_models/_Treatment/_diagnosis';

@Component({
  selector: 'app-clinician-mytask',
  templateUrl: './clinician-mytask.component.html',
  styleUrls: ['./clinician-mytask.component.scss']
})
export class ClinicianMytaskComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('clientModal') clientModal = {} as TemplateRef<any>
  @ViewChild('downloadLink') downloadLink: ElementRef;

  dataSource: MatTableDataSource<any>;
  DecrptedlstAppointments: any;
  lstAppointments: any;
  userId: any;
  filterdate: any;
  filterweekStartDate: any;
  filterweekEndDate: any;
  filterMonth: any;
  filterMonthdisplay: any;
  meetingScheduleId: any;
  filterType: any = 2;
  isReSchedule: boolean = false;
  isConflict: boolean = true;
  Day1viewDate: Date = new Date();
  meetingDateBlocks: any = [];
  fromTime: any;
  currentdate: any;
  toTime: any;
  Day1StartTime: any;
  Day1EndTime: any;
  staffAppointmentDetails: any = [];
  staffAppointmentDay1: CalendarEvent[] = [];
  timeslots = [];
  noslots: boolean = false;
  submitted: any = false;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  viewDate: Date = new Date();
  viewDateForDay= moment().toDate();
  isTaskShow: boolean = false;
  objAppointment: any;
  isShowMoreEvent = false;
  EVENT_LIMIT = 4;
  moreTaskEvents: any = [];
  rescheduleReason: string = '';
  appointmentType: any;
  recurringEvents: RecurringEvent[] = [];
  moreEventStyles:any;
  clickedDate: Date;
  appointments: any=[];
  rescheduleDate:any;
  weekViewEventTemplate:any;
  PickedDate: Date;
  Grouptherapyappoinments: any;
  isShowTherapydetails:boolean=false;
  isShowGrpTherapydetails:boolean=false;
  isShowBlockTime:boolean=false;
  objAppointmentForPopup: TherapyPopObj;
  GrouptherapyId=constantVariables.therapy.GroupTheraphy;
  MenuAccess: MenuList;
  roleAccess: RoelAccess;
  viewRightsMsg: any;
  IsNewRescheduleIntake: boolean;
  isShowGroupTherapy:boolean=false;
  groupTherapyList:any =[];
  filterMonthStartDate: any;
  filterMonthEndDate: any;


  ddlpatientList: any = [];
  filteredOptions: any = [];
  searchTerm: string = '';
  isSchedulerview: boolean = false
  patientNameDetails: any;
  filterControl = new FormControl();
  currentDate: any;
  clientdialogRef: any;
  clientTherapydetails: any = [];
  appointmentTypes: any = 'past';
  date_view:any;
  patientNameview : any;
  dateview : boolean = false;
  appoitmenttypefirst : any;
  isAutocompleteEnabled: boolean = true;
  roleTypeId: any;
  ddlclientNameList: any = [];
  selectedClinician: any;
  dtoAppointmentType: any;
  drpClinicians: any = [];
  dayView : boolean = false;
  ExistingReArrangeDatas = [];
  isReArrangeClinician: boolean = false;
  isShowDayView = false;
  clinicianvalues: any = [];
  isReview : boolean = false;
  UserId: number;
  staffName: any;
  isTaskShowRejoiners : boolean = false;
  isReJoiners : boolean;
  _dtoPatientDischarge :  _dtoPatientDischarge = new  _dtoPatientDischarge();
  secondaryId : any;
  therapyLocation : any;
  numbers: number[] = [];
  containsTwentyEight: boolean = false;
  individualFamLocation : any;
  appointmenttypeid: any = [];
  clientTherapydetailsDelete: any = [];

  constructor(public global: Global, public clinicianservice: ClinicianService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public patientservice: PatientService
    ,public datepipe: DatePipe,public roleaccessservice:PageAccessService, public treatmentservice: TreatmentService,private cdr: ChangeDetectorRef) {
      
     }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.ProgressNoteFivepointRating;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.viewRightsMsg=constantVariables.PagePermissionMsg.IsView;
    this.MenuAccess = this.roleaccessservice.GetSiteHeaderAccess();
    this.objAppointmentForPopup = new TherapyPopObj();
    this.currentdate = new Date();
    this.PickedDate = moment(this.filterdate).toDate();
    this.appointmentType = 0;
    this.selectedClinician = -1;
    this.dtoAppointmentType = 1;
    this.filterdate = moment(new Date()).format('LL');
    this.currentDate = moment(new Date()).format("MM/DD/YYYY");
    this.getPatientlist();
    this.getClientName();
    this.getExistingReArrangeClinician();
    this.setdefaultValueforrejoiner();
     this.roleTypeId = +localStorage.getItem('roletypeId')
    this.commonService.TherapyPopup.subscribe((res) => {
      this.OncloseTherapyDetailspopup();
    
    });
    if (localStorage.getItem('id')) {
      this.userId = localStorage.getItem('id');
      this.UserId = parseInt(localStorage.getItem('id'));
      console.log(this.userId)
    }
    if(isNaN(this.filterType)){
    this.filterType = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    if (isNaN(this.filterType) || this.filterType < 0) {
     // this.filterType = 1;
      if(this.filterType ==1){
        this.filterToday()
      }
      else if(this.filterType==2){
        this.filterbyWeek()
      }
      else if(this.filterType ==3){
        this.filterbyMonth()
      }
    }
    else{
      this.events = [];
      this.recurringEvents = [];
      this.Grouptherapyappoinments = [];
      
      this.loadCalenderAppointment(this.appointmentType);
    }
  }
else{
  this.events = [];
  this.recurringEvents = [];
  this.Grouptherapyappoinments = []
  this.filterbyWeek()
  // this.getExistingReArrangeClinician();
 // this.router.navigate(['/clinician'],{queryParams:{tab:2}});
}

// this.filterControl.valueChanges.subscribe(
//   (value: any) => {
//     this.filteredOptions = this.filterOptions(value)
//   }
  
// );


  }
  

  onTabChange(tab) {
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: tab,
      }
    });
    this.filterType = tab;
    this.events = [];
    this.recurringEvents = [];
  }
  filterToday() {
    this.view = CalendarView.Day;
    this.filterType = 1;
    this.userId = this.selectedClinician == -1 ? this.userId : this.selectedClinician;
    this.onTabChange(this.filterType);
    //this.filterdate = moment(new Date()).format('LL');
    this.filterdate = moment(moment(this.viewDate)).format('LL');
    //this.PickedDate = moment(new Date()).toDate();
    this.PickedDate =  new Date(moment(this.viewDate).format('MM/DD/YYYY'))
    //this.viewDateForDay = moment(this.PickedDate).toDate()
    this.viewDateForDay =new Date(moment(this.viewDate).format('MM/DD/YYYY'))
    this.loadCalenderAppointmentforScheduler(this.appointmentType);
    this.refresh.next();
  }
  filterDayNext(event) {
    if (this.filterType == 1) {
      this.filterdate = moment(this.filterdate).add(1, 'days').format('LL');
      //this.PickedDate =moment(this.viewDateForDay).toDate();
      this.PickedDate =  new Date(moment(this.viewDateForDay).format('MM/DD/YYYY'))
      this.loadCalenderAppointmentforScheduler(this.appointmentType);
    } else if (this.filterType == 2) {
      this.filterweekStartDate = moment(this.filterweekStartDate).add(7, 'days').format('LL');
      this.filterweekEndDate = moment(this.filterweekEndDate).add(7, 'days').format('LL');
      this.loadCalenderAppointmentforScheduler(this.appointmentType);
    } else if (this.filterType == 3) {
       this.filterMonth = Number(moment(this.viewDate).format('M'));
      this.filterMonthdisplay = moment(this.viewDate).add(1, 'months').format('MMM YYYY');
      this.filterMonthStartDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1);
      this.filterMonthEndDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth()+1, 0);
      this.loadCalenderAppointmentforScheduler(this.appointmentType);
    }
    
  }
  filterDayPrev(event) {
    if (this.filterType == 1) {
      this.filterdate = moment(this.filterdate).subtract(1, 'days').format('LL');
      //this.PickedDate = moment(this.viewDateForDay).toDate();
      this.PickedDate =  new Date(moment(this.viewDateForDay).format('MM/DD/YYYY'))
      this.getPatientAppointmentsforClinician()
    } else if (this.filterType == 2) {
      this.filterweekStartDate = moment(this.filterweekStartDate).subtract(7, 'days').format('LL');
      this.filterweekEndDate = moment(this.filterweekEndDate).subtract(7, 'days').format('LL');
      this.loadCalenderAppointmentforScheduler(this.appointmentType);
    } else if (this.filterType == 3) {
      // this.filterMonth = Number(moment(this.viewDate).subtract(1, 'M').format('M'));
      // this.filterMonthdisplay = moment(this.viewDate).subtract(1, 'months').format('MMM YYYY');
      this.filterMonth = Number(moment(this.viewDate).format('M'));
      this.filterMonthdisplay = moment(this.viewDate).format('MMM YYYY');
      this.filterMonthStartDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1);
    this.filterMonthEndDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth()+1, 0);
      this.loadCalenderAppointmentforScheduler(this.appointmentType);
    }
  }
  
  loadFormGroup(data){
    var disableDs;
    if(data.scheduleDate < new Date())
    {
      disableDs = true;
    }else{
      disableDs = false;
    }
    this.router.navigate(['/grouptherapy'], { queryParams: { groupTherapyId: this.commonService.FrontEndEncryption(data.GroupTherapyId),scheduleDate:data.scheduleDate,disable:this.commonService.FrontEndDecryption(disableDs),clinicianId:this.commonService.FrontEndDecryption(Number(this.userId))}});
  }
  filterbyWeek() {
    if(this.dateview == true){
      this.dateview = false;
      this.setdefaultValue();
      this.ngOnInit();
     
      return;
     }
     this.filterType = 2;
     this.recurringEvents = [];
     this.events = [];
    this.onTabChange(this.filterType);
    this.view = CalendarView.Week;
    this.userId = this.selectedClinician == -1 ? this.userId : this.selectedClinician;
    this.filterweekStartDate = moment(this.viewDate).startOf('week').toString();
    this.filterweekEndDate = moment(this.viewDate).endOf('week').toString();
    this.loadCalenderAppointment(this.appointmentType);
   

  }
  filterbyMonth() {
    if(this.dateview == true){
      this.dateview = false;
      this.setdefaultValue();
      this.ngOnInit();
      return;
     }
    this.filterType = 3;
    this.onTabChange(this.filterType);
    this.view = CalendarView.Month;
    this.filterMonthdisplay = moment().format('MMM YYYY');
    this.filterMonth = Number((moment(this.viewDate)).format('M'));
    this.userId = this.selectedClinician == -1 ? this.userId : this.selectedClinician;
    this.loadCalenderAppointmentforScheduler(this.appointmentType);
  }
  loadCalenderAppointment(event) {
    // this.drpClinicians = [];
       this.events = [];
    this.recurringEvents = [];
    this.Grouptherapyappoinments = []
    if (this.appointmentType == 0) {
      this.getPatientAppointments();
      this.getclinicianappointments();
      this.getGrpTherapy();

    }
    else if (this.appointmentType == 1) {
      this.getPatientAppointments();
    }
    else if (this.appointmentType == 2) {
      this.getGrpTherapy();
    }
    else if (this.appointmentType == 3) {
      this.loadIndividualTherapy();
    } 
    else if (this.appointmentType == 4) {
      this.loadFamilyTherapy();
    } 
    else if (this.appointmentType == 5) {
      this.loadTour();
    } 
    else if (this.appointmentType == 6) {
      this.loadPsycatricTherapy();
    }
    else if (this.appointmentType == 7) {
      this.loadPsycatricEvaluation();
    }
    else if (this.appointmentType == 8) {
      this.loadPsycatricMedication();
    }
    else if (this.appointmentType == 10) {
      this.loadschoolmeeting();
    }
    else if (this.appointmentType == 11) {
      this.loadphonecall();
    }
    else if (this.appointmentType == 12) {
      this.loadspeechtherapy();
    }
    else if (this.appointmentType == 13) {
      this.loadnutritiontherapy();
    }
    else if (this.appointmentType == 14) {
      this.loadblocktime();
    }
    if ( this.selectedClinician == -1){
      this.selectedClinician = (this.userId == "-1") ? this.drpClinicians[0].clinicianId : this.userId;   
    }
        
  }
  loadCalenderAppointmentforScheduler(event) {
    // this.drpClinicians = [];
    this.events = [];
 this.recurringEvents = [];
 this.Grouptherapyappoinments = []
 if (this.appointmentType == 0) {
   this.getPatientAppointmentsforClinician();
   this.getclinicianappointmentsforscheduler();
   this.getGrpTherapyforScheduler();
 }
 else if (this.appointmentType == 1) {
   this.getPatientAppointmentsforClinician();
 }
 else if (this.appointmentType == 2) {
   this.getGrpTherapy();
 }
 else if (this.appointmentType == 3) {
   this.loadIndividualTherapy();
 } 
 else if (this.appointmentType == 4) {
   this.loadFamilyTherapy();
 } 
 else if (this.appointmentType == 5) {
   this.loadTour();
 } 
 else if (this.appointmentType == 6) {
   this.loadPsycatricTherapy();
 }
 else if (this.appointmentType == 7) {
   this.loadPsycatricEvaluation();
 }
 else if (this.appointmentType == 8) {
   this.loadPsycatricMedication();
 }
 else if (this.appointmentType == 10) {
   this.loadschoolmeeting();
 }
 else if (this.appointmentType == 11) {
   this.loadphonecall();
 }
 else if (this.appointmentType == 12) {
   this.loadspeechtherapy();
 }
 else if (this.appointmentType == 13) {
   this.loadnutritiontherapy();
 }
 else if (this.appointmentType == 14) {
   this.loadblocktime();
 }
}
  loadFamilyTherapy() {
    if (this.appointments != null) {
      let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 3);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {
        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
        //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
        end:moment(res[i].toTime).format("HH:mm"),  
        title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          },
          flag:constantVariables.therapy.FamilyTheraphy
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.refresh.next();
      }
    }
  }
  loadIndividualTherapy() {
      if (this.appointments != null) {
      let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 2);
     // console.log(res);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
        //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
         end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

          },
          flag:constantVariables.therapy.IndividualTheraphy
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
         this.refresh.next();
      }
    }
  }
  loadTour() {
    
    if (this.appointments != null) {
      let res = this.appointments.filter(x => x.templateId == 3);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {
        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          // end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

          },
          flag:constantVariables.therapy.Tour
        }

        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        
        this.refresh.next();
      }
    }
  }
  getPatientAppointments() {
     this.events = this.events.filter(x=>x.tags!=0);
    this.recurringEvents = this.recurringEvents.filter(x=>x.flag!=0);
    let _obj = {     
      // userid: this.selectedClinician == -1 ? this.userId : this.selectedClinician,
      userid:  this.userId ,
      CurrentDate: this.filterType == 1 ? moment(this.filterdate).format("MM/DD/YYYY") : null,
      StartDate: this.filterType == 2 ? moment(this.filterweekStartDate).format("MM/DD/YYYY") : null,
      EndDate: this.filterType == 2 ? moment(this.filterweekEndDate).format("MM/DD/YYYY") : null,
      CurrentMonth: this.filterType == 3 ? this.filterMonth : null,
    }
    localStorage.setItem("mytasksearch", JSON.stringify(_obj));
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.clinicianservice.getPatientAppointments(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        // this.lstAppointments = JSON.parse(res);

        this.DecrptedlstAppointments = []
        this.DecrptedlstAppointments = JSON.parse(res);
        // this.lstAppointments = this.DecrptedlstAppointments.slice(0, 5);
        // this.dataSource = new MatTableDataSource(this.DecrptedlstAppointments);
        // this.dataSource.paginator = this.paginator;
        // if (this.filterType == 2 || this.filterType == 3) {
        //   this.loadCalderViewData(JSON.parse(res))
        // }
        let data = []
        data = JSON.parse(res)
        console.log(JSON.parse(res));
        this.loadCalderViewData(data)
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getPatientAppointmentsforClinician() {
    this.events = this.events.filter(x=>x.tags!=0);
   this.recurringEvents = this.recurringEvents.filter(x=>x.flag!=0);
   let _obj = {
    userid: this.selectedClinician == -1 ? this.userId : this.selectedClinician,
    // userid: this.selectedClinician,
     CurrentDate: this.filterType == 1 ? moment(this.filterdate).format("MM/DD/YYYY") : null,
     StartDate: this.filterType == 2 ? moment(this.filterweekStartDate).format("MM/DD/YYYY") : null,
     EndDate: this.filterType == 2 ? moment(this.filterweekEndDate).format("MM/DD/YYYY") : null,
     CurrentMonth: this.filterType == 3 ? this.filterMonth : null,
   }
   localStorage.setItem("mytasksearch", JSON.stringify(_obj));
   this.spinnerservice.show();
   const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
   this.clinicianservice.getPatientAppointments(dataToEncrypt).subscribe(
     (res: any) => {
       this.spinnerservice.hide();
       // this.lstAppointments = JSON.parse(res);

       this.DecrptedlstAppointments = []
       this.DecrptedlstAppointments = JSON.parse(res);
       // this.lstAppointments = this.DecrptedlstAppointments.slice(0, 5);
       // this.dataSource = new MatTableDataSource(this.DecrptedlstAppointments);
       // this.dataSource.paginator = this.paginator;
       // if (this.filterType == 2 || this.filterType == 3) {
       //   this.loadCalderViewData(JSON.parse(res))
       // }
       let data = []
       data = JSON.parse(res)
       console.log(JSON.parse(res));
       this.loadCalderViewData(data)
     },
     err => {
       this.spinnerservice.hide();
       this.snackbar.error(err.error);
     },
   );
 }

 
  loadCalderViewData(item) {
    //this.recurringEvents = [];
    //this.events = [];
    if (item != null) {
     
      for (var i = 0; i < item.length; i++) {
        // '<i class="fas fa-circle mr-2 intake-clr"></i>'+
        let _obj = {
          id: item[i].meetingScheduleId,
          start: new Date(moment(item[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(item[i].fromTime).format("HH:mm")),
          end:  moment(item[i].toTime).format("HH:mm"),
          title:'<div class="intake-clr cal-container"><div class="cal-therapyname">'+item[i].scheduleName+'</div></div>',
         // fromTime: item[i].fromTime,
           color: colors.white,
          cssClass: 'event-anchor',
         // flag:0,
         flag:constantVariables.therapy.Intake,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(item[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(item[i].fromTime).format("HH:mm")),
            until: new Date(moment(item[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(item[i].toTime).format("HH:mm")),
           // interval: 1
          },
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.refresh.next();
      } 
     
    
    }
  }
 
  getGrpTherapy() {
    this.events = [];
    this.recurringEvents = [];
    this.Grouptherapyappoinments = []
    let _obj = {
  //  userid: this.selectedClinician == -1 ? this.userId : this.selectedClinician,
   userid:  this.userId ,
   CurrentDate: this.filterType == 1 ? moment(this.filterdate).format("MM/DD/YYYY") : null,
   StartDate: this.filterType == 2 ? moment(this.filterweekStartDate).format("MM/DD/YYYY") : null,
   EndDate: this.filterType == 2 ? moment(this.filterweekEndDate).format("MM/DD/YYYY") : null,
   CurrentMonth: this.filterType == 3 ? this.filterMonth : '0'
   
 }
 localStorage.setItem("mytasksearch", JSON.stringify(_obj));

 this.spinnerservice.show();
 const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
 this.clinicianservice.getGrpTherapyAppointments(dataToEncrypt).subscribe(
   (response: any) => {
    this.spinnerservice.show();  
    var res = []
    this.Grouptherapyappoinments = JSON.parse(response);
    this.spinnerservice.hide();  
        if (res != null) {
       for (var i = 0; i < this.Grouptherapyappoinments.length; i++) {
       //  debugger;
        // let tmpTitle=(this.appointmentType == 2)?"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div><div class="cal-assignedname-clini text-truncate">'+  (res[i].subGroupName).split(',').join('<br/>')+'</div></div>' :
       //  "<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div></div>';
       if(this.Grouptherapyappoinments[i].isRecurrance == 1 && this.Grouptherapyappoinments[i].frequency == 6){ 
        let _obj = {
          start: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
          end:  moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm"),
         // title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div><div class="cal-assignedname-clini text-truncate">'+  (res[i].subGroupName).split(',').join('<br/>')+'</div></div>' ,
           title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ this.Grouptherapyappoinments[i].groupTherapyName +'</div>{{DS}}</div>',
          color: colors.white,
          cssClass: 'event-anchor',
          id: this.Grouptherapyappoinments[i].groupTherapyId,
          //flag:1,
          therapy: constantVariables.therapy.GroupTheraphy,
          flag:constantVariables.therapy.GroupTheraphy,
          rrule: {
            freq: RRule.MONTHLY,
            dtstart: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
            until: new Date(moment(this.Grouptherapyappoinments[i].releaseDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm")),
            //byweekday: (this.Grouptherapyappoinments[i].isRecurrance == 1 ? [(this.Grouptherapyappoinments[i].isMonday == 1 ? RRule.MO : ''),(this.Grouptherapyappoinments[i].isTuesday == 1 ? RRule.TU : ''),(this.Grouptherapyappoinments[i].isWednesday == 1 ? RRule.WE : ''),(this.Grouptherapyappoinments[i].isThursday == 1 ? RRule.TH : ''),(this.Grouptherapyappoinments[i].isFriday == 1 ? RRule.FR : ''),(this.Grouptherapyappoinments[i].isSunday == 1 ? RRule.SU : ''),(this.Grouptherapyappoinments[i].isSaturday == 1 ? RRule.SA : '')] :''),
            bymonthday: this.getSpecificWeekday(new Date(this.Grouptherapyappoinments[i].assignDate), new Date(this.Grouptherapyappoinments[i].releaseDate), this.Grouptherapyappoinments[i].weekDaysOnMonth,this.Grouptherapyappoinments[i].weekOfMonth),
          },  
          tags: constantVariables.therapy.GroupTheraphy
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
       }
       else if(this.Grouptherapyappoinments[i].isRecurrance == 1 && this.Grouptherapyappoinments[i].frequency == 5){
        let _obj = {
          start: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
          end:  moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm"),
         // title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div><div class="cal-assignedname-clini text-truncate">'+  (res[i].subGroupName).split(',').join('<br/>')+'</div></div>' ,
           title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ this.Grouptherapyappoinments[i].groupTherapyName +'</div>{{DS}}</div>',
          color: colors.white,
          cssClass: 'event-anchor',
          id: this.Grouptherapyappoinments[i].groupTherapyId,
          //flag:1,
          therapy: constantVariables.therapy.GroupTheraphy,
          flag:constantVariables.therapy.GroupTheraphy,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
            until: new Date(moment(this.Grouptherapyappoinments[i].releaseDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm")),
            byweekday: (this.Grouptherapyappoinments[i].isRecurrance == 1 ? [(this.Grouptherapyappoinments[i].isMonday == 1 ? RRule.MO : ''),(this.Grouptherapyappoinments[i].isTuesday == 1 ? RRule.TU : ''),(this.Grouptherapyappoinments[i].isWednesday == 1 ? RRule.WE : ''),(this.Grouptherapyappoinments[i].isThursday == 1 ? RRule.TH : ''),(this.Grouptherapyappoinments[i].isFriday == 1 ? RRule.FR : ''),(this.Grouptherapyappoinments[i].isSunday == 1 ? RRule.SU : ''),(this.Grouptherapyappoinments[i].isSaturday == 1 ? RRule.SA : '')] :''),
          },
          tags: constantVariables.therapy.GroupTheraphy
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
       }else if(this.Grouptherapyappoinments[i].isRecurrance == 1 && this.Grouptherapyappoinments[i].frequency == 4){
        let _obj = {
          start: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
          end:  moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm"),
         // title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div><div class="cal-assignedname-clini text-truncate">'+  (res[i].subGroupName).split(',').join('<br/>')+'</div></div>' ,
           title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ this.Grouptherapyappoinments[i].groupTherapyName +'</div>{{DS}}</div>',
          color: colors.white,
          cssClass: 'event-anchor',
          id: this.Grouptherapyappoinments[i].groupTherapyId,
          //flag:1,
          therapy: constantVariables.therapy.GroupTheraphy,
          flag:constantVariables.therapy.GroupTheraphy,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
            until: new Date(moment(this.Grouptherapyappoinments[i].releaseDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm")),
           // byweekday: (this.Grouptherapyappoinments[i].isRecurrance == 1 ? [(this.Grouptherapyappoinments[i].isMonday == 1 ? RRule.MO : ''),(this.Grouptherapyappoinments[i].isTuesday == 1 ? RRule.TU : ''),(this.Grouptherapyappoinments[i].isWednesday == 1 ? RRule.WE : ''),(this.Grouptherapyappoinments[i].isThursday == 1 ? RRule.TH : ''),(this.Grouptherapyappoinments[i].isFriday == 1 ? RRule.FR : ''),(this.Grouptherapyappoinments[i].isSunday == 1 ? RRule.SU : ''),(this.Grouptherapyappoinments[i].isSaturday == 1 ? RRule.SA : '')] :''),
          },
          tags: constantVariables.therapy.GroupTheraphy
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
       }else if(this.Grouptherapyappoinments[i].isRecurrance == 0){
        let _obj = {
          start: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
          end:  moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm"),
         // title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div><div class="cal-assignedname-clini text-truncate">'+  (res[i].subGroupName).split(',').join('<br/>')+'</div></div>' ,
           title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ this.Grouptherapyappoinments[i].groupTherapyName +'</div>{{DS}}</div>',
          color:colors.white,
          cssClass: 'event-anchor',
          id: this.Grouptherapyappoinments[i].groupTherapyId,
          //flag:1,
          therapy: constantVariables.therapy.GroupTheraphy,
          flag:constantVariables.therapy.GroupTheraphy,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
            until: new Date(moment(this.Grouptherapyappoinments[i].releaseDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm")),
           // byweekday: (this.Grouptherapyappoinments[i].isRecurrance == 1 ? [(this.Grouptherapyappoinments[i].isMonday == 1 ? RRule.MO : ''),(this.Grouptherapyappoinments[i].isTuesday == 1 ? RRule.TU : ''),(this.Grouptherapyappoinments[i].isWednesday == 1 ? RRule.WE : ''),(this.Grouptherapyappoinments[i].isThursday == 1 ? RRule.TH : ''),(this.Grouptherapyappoinments[i].isFriday == 1 ? RRule.FR : ''),(this.Grouptherapyappoinments[i].isSunday == 1 ? RRule.SU : ''),(this.Grouptherapyappoinments[i].isSaturday == 1 ? RRule.SA : '')] :''),
          },
          tags: constantVariables.therapy.GroupTheraphy
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
       }
      
       
         this.updateCalendarEventsonLoad();
         this.refresh.next();

       }
     
       return;
     }
     
   },
   err => {
     this.spinnerservice.hide();
     this.snackbar.error(err.error);
   },
 );
 this.spinnerservice.hide();

}
getGrpTherapyforScheduler() {
  this.events = [];
  this.recurringEvents = [];
  this.Grouptherapyappoinments = []
  let _obj = {
    userid: this.selectedClinician,
    CurrentDate: this.filterType == 1 ? moment(this.filterdate).format("MM/DD/YYYY") : null,
 StartDate: this.filterType == 2 ? moment(this.filterweekStartDate).format("MM/DD/YYYY") : null,
 EndDate: this.filterType == 2 ? moment(this.filterweekEndDate).format("MM/DD/YYYY") : null,
 CurrentMonth: this.filterType == 3 ? this.filterMonth : '0'
 
}
localStorage.setItem("mytasksearch", JSON.stringify(_obj));

this.spinnerservice.show();
const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
this.clinicianservice.getGrpTherapyAppointments(dataToEncrypt).subscribe(
 (response: any) => {
  this.spinnerservice.show();  
  var res = []
  this.Grouptherapyappoinments = JSON.parse(response);
  this.spinnerservice.hide();  
      if (res != null) {
     for (var i = 0; i < this.Grouptherapyappoinments.length; i++) {
     //  debugger;
      // let tmpTitle=(this.appointmentType == 2)?"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div><div class="cal-assignedname-clini text-truncate">'+  (res[i].subGroupName).split(',').join('<br/>')+'</div></div>' :
     //  "<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div></div>';
     if(this.Grouptherapyappoinments[i].isRecurrance == 1 && this.Grouptherapyappoinments[i].frequency == 6){ 
      let _obj = {
        start: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
        end:  moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm"),
       // title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div><div class="cal-assignedname-clini text-truncate">'+  (res[i].subGroupName).split(',').join('<br/>')+'</div></div>' ,
         title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ this.Grouptherapyappoinments[i].groupTherapyName +'</div>{{DS}}</div>',
        color: colors.white,
        cssClass: 'event-anchor',
        id: this.Grouptherapyappoinments[i].groupTherapyId,
        //flag:1,
        therapy: constantVariables.therapy.GroupTheraphy,
        flag:constantVariables.therapy.GroupTheraphy,
        rrule: {
          freq: RRule.MONTHLY,
          dtstart: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
          until: new Date(moment(this.Grouptherapyappoinments[i].releaseDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm")),
          //byweekday: (this.Grouptherapyappoinments[i].isRecurrance == 1 ? [(this.Grouptherapyappoinments[i].isMonday == 1 ? RRule.MO : ''),(this.Grouptherapyappoinments[i].isTuesday == 1 ? RRule.TU : ''),(this.Grouptherapyappoinments[i].isWednesday == 1 ? RRule.WE : ''),(this.Grouptherapyappoinments[i].isThursday == 1 ? RRule.TH : ''),(this.Grouptherapyappoinments[i].isFriday == 1 ? RRule.FR : ''),(this.Grouptherapyappoinments[i].isSunday == 1 ? RRule.SU : ''),(this.Grouptherapyappoinments[i].isSaturday == 1 ? RRule.SA : '')] :''),
          bymonthday: this.getSpecificWeekday(new Date(this.Grouptherapyappoinments[i].assignDate), new Date(this.Grouptherapyappoinments[i].releaseDate), this.Grouptherapyappoinments[i].weekDaysOnMonth,this.Grouptherapyappoinments[i].weekOfMonth),
        },  
        tags: constantVariables.therapy.GroupTheraphy
      }
      this.recurringEvents = [...this.recurringEvents, _obj];
     }
     else if(this.Grouptherapyappoinments[i].isRecurrance == 1 && this.Grouptherapyappoinments[i].frequency == 5){
      let _obj = {
        start: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
        end:  moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm"),
       // title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div><div class="cal-assignedname-clini text-truncate">'+  (res[i].subGroupName).split(',').join('<br/>')+'</div></div>' ,
         title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ this.Grouptherapyappoinments[i].groupTherapyName +'</div>{{DS}}</div>',
        color: colors.white,
        cssClass: 'event-anchor',
        id: this.Grouptherapyappoinments[i].groupTherapyId,
        //flag:1,
        therapy: constantVariables.therapy.GroupTheraphy,
        flag:constantVariables.therapy.GroupTheraphy,
        rrule: {
          freq: RRule.DAILY,
          dtstart: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
          until: new Date(moment(this.Grouptherapyappoinments[i].releaseDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm")),
          byweekday: (this.Grouptherapyappoinments[i].isRecurrance == 1 ? [(this.Grouptherapyappoinments[i].isMonday == 1 ? RRule.MO : ''),(this.Grouptherapyappoinments[i].isTuesday == 1 ? RRule.TU : ''),(this.Grouptherapyappoinments[i].isWednesday == 1 ? RRule.WE : ''),(this.Grouptherapyappoinments[i].isThursday == 1 ? RRule.TH : ''),(this.Grouptherapyappoinments[i].isFriday == 1 ? RRule.FR : ''),(this.Grouptherapyappoinments[i].isSunday == 1 ? RRule.SU : ''),(this.Grouptherapyappoinments[i].isSaturday == 1 ? RRule.SA : '')] :''),
        },
        tags: constantVariables.therapy.GroupTheraphy
      }
      this.recurringEvents = [...this.recurringEvents, _obj];
     }else if(this.Grouptherapyappoinments[i].isRecurrance == 1 && this.Grouptherapyappoinments[i].frequency == 4){
      let _obj = {
        start: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
        end:  moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm"),
       // title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div><div class="cal-assignedname-clini text-truncate">'+  (res[i].subGroupName).split(',').join('<br/>')+'</div></div>' ,
         title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ this.Grouptherapyappoinments[i].groupTherapyName +'</div>{{DS}}</div>',
        color: colors.white,
        cssClass: 'event-anchor',
        id: this.Grouptherapyappoinments[i].groupTherapyId,
        //flag:1,
        therapy: constantVariables.therapy.GroupTheraphy,
        flag:constantVariables.therapy.GroupTheraphy,
        rrule: {
          freq: RRule.DAILY,
          dtstart: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
          until: new Date(moment(this.Grouptherapyappoinments[i].releaseDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm")),
         // byweekday: (this.Grouptherapyappoinments[i].isRecurrance == 1 ? [(this.Grouptherapyappoinments[i].isMonday == 1 ? RRule.MO : ''),(this.Grouptherapyappoinments[i].isTuesday == 1 ? RRule.TU : ''),(this.Grouptherapyappoinments[i].isWednesday == 1 ? RRule.WE : ''),(this.Grouptherapyappoinments[i].isThursday == 1 ? RRule.TH : ''),(this.Grouptherapyappoinments[i].isFriday == 1 ? RRule.FR : ''),(this.Grouptherapyappoinments[i].isSunday == 1 ? RRule.SU : ''),(this.Grouptherapyappoinments[i].isSaturday == 1 ? RRule.SA : '')] :''),
        },
        tags: constantVariables.therapy.GroupTheraphy
      }
      this.recurringEvents = [...this.recurringEvents, _obj];
     }else if(this.Grouptherapyappoinments[i].isRecurrance == 0){
      let _obj = {
        start: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
        end:  moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm"),
       // title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ res[i].groupTherapyName +'</div><div class="cal-assignedname-clini text-truncate">'+  (res[i].subGroupName).split(',').join('<br/>')+'</div></div>' ,
         title:"<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>"+ this.Grouptherapyappoinments[i].groupTherapyName +'</div>{{DS}}</div>',
        color: colors.white,
        cssClass: 'event-anchor',
        id: this.Grouptherapyappoinments[i].groupTherapyId,
        //flag:1,
        therapy: constantVariables.therapy.GroupTheraphy,
        flag:constantVariables.therapy.GroupTheraphy,
        rrule: {
          freq: RRule.DAILY,
          dtstart: new Date(moment(this.Grouptherapyappoinments[i].assignDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].startTime).format("HH:mm")),
          until: new Date(moment(this.Grouptherapyappoinments[i].releaseDate).format("MM/DD/YYYY") + " " + moment(this.Grouptherapyappoinments[i].endTime).format("HH:mm")),
         // byweekday: (this.Grouptherapyappoinments[i].isRecurrance == 1 ? [(this.Grouptherapyappoinments[i].isMonday == 1 ? RRule.MO : ''),(this.Grouptherapyappoinments[i].isTuesday == 1 ? RRule.TU : ''),(this.Grouptherapyappoinments[i].isWednesday == 1 ? RRule.WE : ''),(this.Grouptherapyappoinments[i].isThursday == 1 ? RRule.TH : ''),(this.Grouptherapyappoinments[i].isFriday == 1 ? RRule.FR : ''),(this.Grouptherapyappoinments[i].isSunday == 1 ? RRule.SU : ''),(this.Grouptherapyappoinments[i].isSaturday == 1 ? RRule.SA : '')] :''),
        },
        tags: constantVariables.therapy.GroupTheraphy
      }
      this.recurringEvents = [...this.recurringEvents, _obj];
     }
    
     
       this.updateCalendarEventsonLoad();
       this.refresh.next();

     }
   
     return;
   }
   
 },
 err => {
   this.spinnerservice.hide();
   this.snackbar.error(err.error);
 },
);
this.spinnerservice.hide();

}
private getSpecificWeekday(startDate: Date, endDate: Date, weekday: number, occurrence: number): number {
  const start = new Date(this.filterType == 2 ? moment(this.filterweekStartDate).format("MM/DD/YYYY") : this.filterType == 3 ? moment(new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1)).format("MM/DD/YYYY") : moment(this.filterdate).format("MM/DD/YYYY"))
  const year = start.getFullYear();
const month = (this.filterType == 3 ? this.filterMonth :  start.getMonth() + 1);
weekday = weekday == 7 ? 0 : weekday;

  let resultDay;
   
  if(occurrence != 5){
    let currentDay = 1;
    let count = 0;
    
    while (count < occurrence) {
      const currentDate = new Date(year, month-1, currentDay);
  
      if (currentDate.getDay() === weekday) { 
        count++;
        if (count === occurrence) {
          resultDay = currentDate;
        }
      }
  
      currentDay++;
    }


  }else{
   // Calculate the first day of next month
   const firstDayOfNextMonth = new Date(start.getFullYear(), start.getMonth() , 1);

   // Calculate the last day of next month
   const lastDayOfNextMonth = new Date(start.getFullYear(), start.getMonth()+1 , 0);

   // Find the day of the week for the last day of next month
   let lastDayOfWeek = lastDayOfNextMonth.getDay();

   // Calculate the difference between the last day's index and Friday (assuming Sunday is 0)
   let difference = (lastDayOfWeek + 6 - (weekday-1)) % 7;

   // Subtract the difference from the last day of next month to get the last Friday
   resultDay = new Date(
     lastDayOfNextMonth.getFullYear(),
     lastDayOfNextMonth.getMonth(),
     lastDayOfNextMonth.getDate() - difference
   );

  }
  return resultDay.getDate();
}
  getclinicianappointments() {
    this.appointments =[]
    
    let _obj = {
      // userid: this.selectedClinician == -1 ? this.userId : this.selectedClinician,
      userid:  this.userId ,
      CurrentDate: this.filterType == 1 ? moment(this.filterdate).format("MM/DD/YYYY") : null,
      StartDate: this.filterType == 2 ? moment(this.filterweekStartDate).format("MM/DD/YYYY") :this.filterType == 3 ? moment(this.filterMonthStartDate).format("MM/DD/YYYY") : null,
      EndDate: this.filterType == 2 ? moment(this.filterweekEndDate).format("MM/DD/YYYY") : this.filterType == 3 ? moment(this.filterMonthEndDate).format("MM/DD/YYYY") : null,
      CurrentMonth: this.filterType == 3 ? this.filterMonth : '0',
      LocationId : localStorage.getItem('sfcclocation') ? localStorage.getItem('sfcclocation') : -1 
    }
    localStorage.setItem("mytasksearch", JSON.stringify(_obj));
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.clinicianservice.getClinicianAppointments(dataToEncrypt).subscribe(
      (response: any) => {
        var res = []
        res = JSON.parse(response);
        this.spinnerservice.hide();
        this.appointments= JSON.parse(response);
       // console.log(this.appointments);
        if (res != null) {
            for (var i = 0; i < res.length; i++) {
              let tmpvar=(res[i].typeId==2)?constantVariables.therapy.IndividualTheraphy:(res[i].typeId==3)?constantVariables.therapy.FamilyTheraphy:(res[i].typeId==5)?constantVariables.therapy.PsycatricTheraphy:(res[i].typeId==8)?constantVariables.therapy.PsycatricEvaluation:
              (res[i].typeId==9)?constantVariables.therapy.PsycatricMedication:(res[i].typeId==10)?constantVariables.therapy.School:(res[i].typeId==11)?constantVariables.therapy.Phonecall:(res[i].typeId==12)?constantVariables.therapy.Speech:
                      (res[i].typeId == 13) ? constantVariables.therapy.Nutrition : (res[i].typeId == 15) ? constantVariables.therapy.PhonecallSceduler : (res[i].typeId == 16) ? constantVariables.therapy.SchoolmeetingScheduler : (res[i].typeId == 17) ? constantVariables.therapy.BlockTimeScheduler:(res[i].typeId==0)?constantVariables.therapy.Tour:0

              //let colorVal = {};
              // if(res[i].typeId==17 && res[i].colorCode !=undefined)
              // {
              //   colorVal= {
              //     primary: res[i].colorCode,
              //     secondary: res[i].colorCode
              //   }
              // }else{
              //   colorVal=colors.white;
              // }
            // '<i class="fas fa-circle mr-2 grp-thrphy-clr"></i>'+'' ++'<br>'+  (res[i].subGroupName).replace(', ',"<br>")
            //let AppoinmentName = res[i].appointmentName.replace('/',"")
            // let _obj = {
            //   id: res[i].appointmentId,
            //   start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            //   end:  moment(res[i].toTime).format("HH:mm"),
            // //end: (res[i].scheduleEndDate == null) ? new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
            //   title:res[i].appointmentName,
            //  // fromTime: res[i].fromTime,
            //   cssClass: 'event-anchor',
            //   color: colors.blue,
              
            //   rrule: {
            //     freq: RRule.DAILY,
            //     dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            //     until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
            //     interval: 1
            //      }
            let _obj = {
              start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
              // end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
              end: moment(res[i].toTime).format("HH:mm"),
              title: res[i].appointmentName,
              color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
              cssClass: 'event-anchor',
              id: res[i].appointmentId,
              rrule: {
                freq: RRule.DAILY,
                dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
               // interval: 1
              }
            ,flag: tmpvar
            }

            this.recurringEvents = [...this.recurringEvents, _obj];

          }
          this.updateCalendarEventsonLoad();
          // this.drpClinicians = [];
          // this.drpClinicians = this.ExistingReArrangeDatas;
        }
      },
      
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getclinicianappointmentsforscheduler() {
    this.appointments =[]
    
    let _obj = {
      userid : this.selectedClinician == -1 ? this.userId : this.selectedClinician,
      // userid: this.selectedClinician,
      CurrentDate: this.filterType == 1 ? moment(this.filterdate).format("MM/DD/YYYY") : null,
      StartDate: this.filterType == 2 ? moment(this.filterweekStartDate).format("MM/DD/YYYY") :this.filterType == 3 ? moment(this.filterMonthStartDate).format("MM/DD/YYYY") : null,
      EndDate: this.filterType == 2 ? moment(this.filterweekEndDate).format("MM/DD/YYYY") : this.filterType == 3 ? moment(this.filterMonthEndDate).format("MM/DD/YYYY") : null,
      CurrentMonth: this.filterType == 3 ? this.filterMonth : '0',
      LocationId : localStorage.getItem('sfcclocation') ? localStorage.getItem('sfcclocation') : -1 ,
    }
    localStorage.setItem("mytasksearch", JSON.stringify(_obj));
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.clinicianservice.getClinicianAppointments(dataToEncrypt).subscribe(
      (response: any) => {
        var res = []
        res = JSON.parse(response);
        this.spinnerservice.hide();
        this.appointments= JSON.parse(response);

       // console.log(this.appointments);
        if (res != null) {
            for (var i = 0; i < res.length; i++) {
              let tmpvar=(res[i].typeId==2)?constantVariables.therapy.IndividualTheraphy:(res[i].typeId==3)?constantVariables.therapy.FamilyTheraphy:(res[i].typeId==5)?constantVariables.therapy.PsycatricTheraphy:(res[i].typeId==8)?constantVariables.therapy.PsycatricEvaluation:
              (res[i].typeId==9)?constantVariables.therapy.PsycatricMedication:(res[i].typeId==10)?constantVariables.therapy.School:(res[i].typeId==11)?constantVariables.therapy.Phonecall:(res[i].typeId==12)?constantVariables.therapy.Speech:
              (res[i].typeId==13)?constantVariables.therapy.Nutrition:(res[i].typeId==15)?constantVariables.therapy.PhonecallSceduler:(res[i].typeId==16)?constantVariables.therapy.SchoolmeetingScheduler:(res[i].typeId==17)?constantVariables.therapy.BlockTime:(res[i].typeId==0)?constantVariables.therapy.Tour:0

              let colorVal = {};
              if(res[i].typeId==17 && res[i].colorCode !=undefined)
              {
                colorVal= {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                }
              }else{
                colorVal=colors.white;
              }
            // '<i class="fas fa-circle mr-2 grp-thrphy-clr"></i>'+'' ++'<br>'+  (res[i].subGroupName).replace(', ',"<br>")
            //let AppoinmentName = res[i].appointmentName.replace('/',"")
            // let _obj = {
            //   id: res[i].appointmentId,
            //   start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            //   end:  moment(res[i].toTime).format("HH:mm"),
            // //end: (res[i].scheduleEndDate == null) ? new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
            //   title:res[i].appointmentName,
            //  // fromTime: res[i].fromTime,
            //   cssClass: 'event-anchor',
            //   color: colors.blue,
              
            //   rrule: {
            //     freq: RRule.DAILY,
            //     dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            //     until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
            //     interval: 1
            //      }
            let _obj = {
              start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
              // end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
              end: moment(res[i].toTime).format("HH:mm"),
              title: res[i].appointmentName,
              color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
                
              cssClass: 'event-anchor',
              id: res[i].appointmentId,
              rrule: {
                freq: RRule.DAILY,
                dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
               // interval: 1
              }
            ,flag: tmpvar
            }

            this.recurringEvents = [...this.recurringEvents, _obj];

          }
          this.updateCalendarEventsonLoad();
         
        }
      },
      
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  AcceptMeeting(obj) {
    this.spinnerservice.show();
    var offset = new Date().getTimezoneOffset();
    let _obj = {
      FromTime: this.fromTime,
      ToTime: this.toTime,
      ScheduleDate: moment.utc(this.Day1viewDate).utcOffset(-offset).format("MM/DD/YYYY"),
      MeetingScheduleId: obj.meetingScheduleId,
      CurrentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.clinicianservice.acceptMeeting(dataToEncrypt).subscribe(
      res => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getPatientAppointments()
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  openReSchedule() {
    this.IsNewRescheduleIntake = true;
    this.staffAppointmentDay1 = null;
    this.isReSchedule = !this.isReSchedule;
    this.fromTime = "";
    this.toTime = "";
    this.rescheduleReason = "";
    this.isConflict = true;

    this.isTaskShow = !this.isTaskShow;
    this.isTaskShowRejoiners= !this.isTaskShowRejoiners;

    this.rescheduleDate='';
    this.Day1viewDate =new Date();
    this.Day1StartTime=0;
    this.Day1EndTime=0;
    this.refresh.next();
    
  }
  reSchedule(obj) {
    this.meetingScheduleId = obj.meetingScheduleId;
    this.staffAppointmentDay1 = [];
    this.Day1viewDate = new Date();
    this.isConflict = true;
    this.timeslots = [];
    this.openReSchedule();
  }
  onMettingDate(event, index) {
    this.isConflict = true;
    this.staffAppointmentDay1 = [];
    this.Day1viewDate = event.value;
    var day = moment(event.value).day();
    this.getTimeSlots(1, day, index)
  }
  getTimeSlots(id, day, index) {
    var offset = new Date().getTimezoneOffset();
    let _obj = {
      Day: day,
      CurrentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      SelectedDate: moment.utc(this.Day1viewDate).utcOffset(-offset).format("MM/DD/YYYY"),
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.clinicianservice.getTimeSlots(dataToEncrypt).subscribe(
      res => {
        if (res != null) {

          this.timeslots = JSON.parse(res);
          this.noslots = this.timeslots.length == 0;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  meetingDateFilter = (d: any) => {
    const day = moment(d).isoWeekday();
    if (this.meetingDateBlocks?.length > 0) {
      return this.meetingDateBlocks.includes(String(day));
    }
    return true
  };

  onChangeDay1Time(event) {
    this.IsNewRescheduleIntake = false;
    this.fromTime = event.fromTime;
    this.toTime = event.toTime;
    this.Day1StartTime = Number(moment(event.fromTime).format("HH")) - 1;
    this.Day1EndTime = Number(moment(event.toTime).format("HH")) + 1;
    this.getAssignedSlots()
  }

  getAssignedSlots() {

    var offset = new Date().getTimezoneOffset();

    let _obj = {
      TimeSlot1FromTime: this.fromTime,
      TimeSlot1ToTime: this.toTime,
      TimeSlot1date: moment.utc(this.Day1viewDate).utcOffset(-offset).format("MM/DD/YYYY"),
      MeetingScheduleId: this.meetingScheduleId
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.clinicianservice.getAssignedSlots(dataToEncrypt).subscribe(
      (res: any) => {
        this.staffAppointmentDetails = JSON.parse(res).assignedSlots;
        if (this.staffAppointmentDetails != null) {
          this.staffAppointmentDay1 = [];
          this.Day1viewDate = new Date(this.Day1viewDate);
          for (var i = 0; i < this.staffAppointmentDetails.length; i++) {
            let _obj = {
              start: new Date(moment(this.Day1viewDate).format("MM/DD/YYYY") + " " + moment(this.staffAppointmentDetails[i].fromTime).format("HH:mm")),
              //title: "<span><i class='fas fa-exclamation-circle text-danger mr-2'></i></span>Meeting with " + Day1[i].firstName + " " + Day1[i].lastName
              title: this.staffAppointmentDetails[i].isConflict == true ? "<i class='fas fa-exclamation-circle text-danger mr-2'></i>Meeting with " + this.staffAppointmentDetails[i].firstName + " " + this.staffAppointmentDetails[i].lastName : "Meeting with " + this.staffAppointmentDetails[i].firstName + " " + this.staffAppointmentDetails[i].lastName,

            }

            this.staffAppointmentDay1 = [...this.staffAppointmentDay1, _obj];

          }
          this.isConflict = JSON.parse(res).isConflict;
          if (JSON.parse(res).isConflict && this.staffAppointmentDetails.find(x => x.requestTourId > 0 && x.isConflict == true) != null) {
            this.onRemoveConficts();
          }
        }
      },
      error =>
        this.spinnerservice.hide()
    );

  }

  onRemoveConficts() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.Warning.ConfictConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.postRemoveConflicts();
      }
    });
  }
  postRemoveConflicts() {
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.staffAppointmentDetails.find(x => x.requestTourId > 0 && x.isConflict == true), false);
    this.patientservice.postRemoveConflicts(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.lstAppointments = res;
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  // reScheduleMeeting(event) {
  //   if (event) {
  //     var offset = new Date().getTimezoneOffset();

  //     let _obj = {
  //       FromTime: this.fromTime,
  //       ToTime: this.toTime,
  //       ScheduleDate: moment.utc(this.Day1viewDate).utcOffset(-offset).format("MM/DD/YYYY"),
  //       MeetingScheduleId: this.meetingScheduleId,
  //       CurrentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
  //     }
  //     const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
  //     this.clinicianservice.reScheduleMeeting(dataToEncrypt).subscribe(
  //       (res: any) => {
  //         this.openReSchedule();
  //         this.getPatientAppointments()
  //         this.spinnerservice.hide();
  //         this.snackbar.success(JSON.parse(res).message);
  //       },
  //       error =>
  //         this.spinnerservice.hide()
  //     );
  //   }
  // }
  reScheduleMeeting(event) {
    if (event) {
      this.submitted = true;
      if (this.rescheduleReason == '') {
        return
      }
      var offset = new Date().getTimezoneOffset();

      let _obj = {
        FromTime: this.fromTime,
        ToTime: this.toTime,
        ScheduleDate: moment.utc(this.Day1viewDate).utcOffset(-offset).format("MM/DD/YYYY"),
        MeetingScheduleId: this.meetingScheduleId,
        CurrentDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        RescheduleReason: this.rescheduleReason
      }
      const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
      this.clinicianservice.reScheduleMeeting(dataToEncrypt).subscribe(
        (res: any) => {
          this.openReSchedule();
         // this.getPatientAppointments()
         this.loadCalenderAppointment(this.appointmentType);
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }
  }
  onPageChange($event) {
    this.lstAppointments = this.DecrptedlstAppointments.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }
  onchangeFilter(event) {
    if (event != "Any") {
      this.lstAppointments = _.filter(this.DecrptedlstAppointments, item => item.status === event);
      this.lstAppointments = this.lstAppointments.slice(0, 5);
      this.dataSource = new MatTableDataSource(this.lstAppointments);
      this.dataSource.paginator = this.paginator;
    } else {
      this.lstAppointments = this.DecrptedlstAppointments;
      this.lstAppointments = this.lstAppointments.slice(0, 5);
      this.dataSource = new MatTableDataSource(this.lstAppointments);
      this.dataSource.paginator = this.paginator;
    }
  }
  onNutritional(item) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-nutritional'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), page: this.filterType, isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } }));
    window.open(url, '_blank');
    //this.router.navigate(['/intake-nutritional'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), page: this.filterType, isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } });
  }
  onGenerateIntake(item) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-generate'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId),patientid: this.commonService.FrontEndEncryption(item.patientInfoId), page: this.filterType, isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } }));
    window.open(url, '_blank');
    //this.router.navigate(['/intake-generate'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), patientid: this.commonService.FrontEndEncryption(item.patientInfoId), page: this.filterType, isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } });
  }
  onintakechma(item) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-chma'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId),patientid: this.commonService.FrontEndEncryption(item.patientInfoId), page: this.filterType, isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } }));
    window.open(url, '_blank');
    //this.router.navigate(['/intake-chma'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), patientid: this.commonService.FrontEndEncryption(item.patientInfoId), page: this.filterType, isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } });
  }

  onPainAssessment(item) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/pain-assessment'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), page: this.filterType, isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } }));
    window.open(url, '_blank');
    //this.router.navigate(['/pain-assessment'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), page: this.filterType, isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } });
  }

  ShowReSchedule(scheduledate, scheduletime) {

    var CurrentDate = moment().format("YYYY-MM-DD HH:mm:ss")

    if ((moment(CurrentDate, "YYYY-MM-DD")).isSame(moment(scheduledate, "YYYY-MM-DD"))) {

      var y = moment(scheduledate, "YYYY-MM-DD").format("YYYY")
      var m = moment(scheduledate, "YYYY-MM-DD").format("MM")
      var d = moment(scheduledate, "YYYY-MM-DD").format("DD")
      var h = moment(scheduletime, "YYYY-MM-DD HH:mm:ss").hour
      var min = moment(scheduletime, "YYYY-MM-DD HH:mm:ss").minutes
      var time = y + '-' + m + '-' + d + ' ' + h + ':' + min + ':00'
      var schedule = moment(time)
      var hours = moment().diff(schedule, 'hours');
      if (hours < 2)
        return false;
      else
        return true;
    }
    else if ((moment(CurrentDate, "YYYY-MM-DD")) > (moment(scheduledate, "YYYY-MM-DD")))
      return false;
    else
      return true;
  }

  handleEvent(action: string, event: CalendarEvent): void {
    let curentDate = new Date();
    // if((this.datepipe.transform(curentDate.setDate( curentDate.getDate() + 2), "yyyy-MM-dd"))< (this.datepipe.transform(event.start, "yyyy-MM-dd")))
    // {
    //   this.snackbar.error("Upcoming dates selections are restricted");
    //   return;
    // }
     if (event.tags == constantVariables.therapy.Intake) {
      this.objAppointment = undefined;
      if (this.DecrptedlstAppointments.filter(m => m.meetingScheduleId == event.id).length > 0) {
        this.objAppointment = this.DecrptedlstAppointments.filter(m => m.meetingScheduleId == event.id)[0];
        this.getDischargePatient(this.objAppointment.patientAccountId);

        
        // this.isShowMoreEvent = false;
        // this.isTaskShow = true;
      }
    } 
     else if (event.tags == constantVariables.therapy.BlockTimeScheduler)
    {
      let currentEvent = this.appointments.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd")))[0];
        this.objAppointmentForPopup.id = currentEvent.appointmentId;
        this.objAppointmentForPopup.fromtime = currentEvent.fromTime;
        this.objAppointmentForPopup.scheduleDate = currentEvent.scheduleDate;
        this.objAppointmentForPopup.Duration = currentEvent.duration;
        this.objAppointmentForPopup.ScheduleName = currentEvent.meetingScheduleName;
        this.objAppointmentForPopup.ScheduleDuration = currentEvent.meetingScheduleDuration;
        this.objAppointmentForPopup.locationName = currentEvent.locationName;
        this.isShowBlockTime=true;

    }
    else if(event.tags== constantVariables.therapy.GroupTheraphy){
      this.objAppointmentForPopup = new TherapyPopObj();
      if (this.Grouptherapyappoinments.filter(m => m.groupTherapyId == event.id).length > 0 ) {
        let currentEvent = this.Grouptherapyappoinments.filter(m => m.groupTherapyId == event.id )[0];
        this.objAppointmentForPopup.id = currentEvent.groupTherapyId;
        // this.objAppointmentForPopup.patientName = currentEvent.userName;
         this.objAppointmentForPopup.fromtime = currentEvent.startTime;
         this.objAppointmentForPopup.scheduleDate = event.start;
        // this.objAppointmentForPopup.email = currentEvent.patientEmail;
        // this.objAppointmentForPopup.contactNo = currentEvent.contactNo;
        // this.objAppointmentForPopup.AdminNotes = currentEvent.adminNotes;
        // this.objAppointmentForPopup.MeetingType = currentEvent.meetingTypeId;
        // this.objAppointmentForPopup.MeetingLink = currentEvent.meetingLinks;
        this.objAppointmentForPopup.tags = event.tags;
        this.objAppointmentForPopup.start= event.start;
        this.objAppointmentForPopup.ScheduleName = currentEvent.groupTherapyName;
        this.objAppointmentForPopup.GroupTherapyId = currentEvent.groupTherapyId; 
        this.objAppointmentForPopup.id = currentEvent.groupTherapyId;
        this.objAppointmentForPopup.scheduleDate = event.start;
        this.objAppointmentForPopup.fromtime = currentEvent.startTime;
        this.objAppointmentForPopup.clinicianContactNo = currentEvent.clinicianContact;
        this.objAppointmentForPopup.clinicianemail = currentEvent.clinicianEmail;
        this.objAppointmentForPopup.ClinicianId = this.selectedClinician;
        this.objAppointmentForPopup.DayScheduleSubGroupId = currentEvent.dayScheduleSubGroupId;
        this.objAppointmentForPopup.DayScheduleAssignId = currentEvent.dayScheduleAssignId;
        this.objAppointmentForPopup.SfccMasterId = currentEvent.sfccMasterId;
        this.objAppointmentForPopup.Duration = currentEvent.duration;
        this.objAppointmentForPopup.endTime = currentEvent.endTime;
        this.objAppointmentForPopup.ImmediateActionId = currentEvent.immediateActionId;
        let duration = (currentEvent.duration >= 60 ? Math.floor(currentEvent.duration / 60).toString() + " hr" : "") + (currentEvent.duration % 60 > 0 ? " " + (currentEvent.duration % 60).toString() + " min" : "")
        // let duration = (currentEvent.duration >= 60?((currentEvent.duration/60).toString() + " hr"):"") + ( currentEvent.duration%60>0? ((currentEvent.duration%60).toString() + " min"):"")
        this.objAppointmentForPopup.ScheduleDuration = duration 
        
        this.objAppointmentForPopup.ScheduleDuration = duration
        this.objAppointmentForPopup.locationName = currentEvent.locationName;
        //this.isShowMoreEvent = false;
        this.spinnerservice.show();
        this.treatmentservice.GetGroupCount(currentEvent.groupTherapyId, moment(event.start).format("MM/DD/YYYY")).subscribe((res: any) => {
          this.spinnerservice.hide();
          if(res == 0 && this.roleTypeId != constantVariables.roleType.Intern)
          {
            this.objAppointmentForPopup.GroupTherapyLength = res;
            this.isShowGrpTherapydetails = true;
            
          }else{
             
              this.isShowTherapydetails = true;
              
              this.getgrouptherapy(this.objAppointmentForPopup)
           
           
          }
          
        });
        // this.isShowTherapydetails = true;
        // this.getgrouptherapy(this.objAppointmentForPopup)
      }
    }
    else{
      this.objAppointmentForPopup = new TherapyPopObj();
      if (this.appointments.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd"))).length > 0 ) {
        let currentEvent = this.appointments.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd")))[0];
        this.objAppointmentForPopup.id = currentEvent.appointmentId;
        this.objAppointmentForPopup.patientName = currentEvent.userName;
        this.objAppointmentForPopup.fromtime = currentEvent.fromTime;
        this.objAppointmentForPopup.scheduleDate = currentEvent.scheduleDate;
        this.objAppointmentForPopup.email = currentEvent.patientEmail;
        this.objAppointmentForPopup.contactNo = currentEvent.contactNo;
        this.objAppointmentForPopup.AdminNotes = currentEvent.adminNotes;
        this.objAppointmentForPopup.MeetingType = currentEvent.meetingTypeId;
        this.objAppointmentForPopup.ClinicianId = this.selectedClinician;
        this.objAppointmentForPopup.locationName = currentEvent.locationName;
        if((this.datepipe.transform(new Date(), "yyyy-MM-dd"))<= (this.datepipe.transform(currentEvent.scheduleDate, "yyyy-MM-dd"))){
          this.objAppointmentForPopup.MeetingLink = currentEvent.meetingLinks;
        }
       else{
        this.objAppointmentForPopup.MeetingLink =null
       }
        this.objAppointmentForPopup.tags = event.tags;
        this.objAppointmentForPopup.start= currentEvent.scheduleDate;
        this.objAppointmentForPopup.ScheduleName = currentEvent.meetingScheduleName;
        this.objAppointmentForPopup.OffSiteAddress =  currentEvent.offSiteAddress;
        this.objAppointmentForPopup.ScheduleDuration = currentEvent.meetingScheduleDuration;
        this.objAppointmentForPopup.locationName = currentEvent.locationName;
        this.isShowTherapydetails = true;
        this.getgrouptherapy(this.objAppointmentForPopup)
      }
    }
  //   else if (event.tags ==constantVariables.therapy.GroupTheraphy) {
  //     this.global.ismytask=true;
  //     this.router.navigate(['/progess-track'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")) } });
  //   }
  //   else if (event.tags ==constantVariables.therapy.IndividualTheraphy) {
  //     this.router.navigate(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")),
  //     theraphyid: this.commonService.FrontEndEncryption(event.tags)
      
  //   } });
  //   }
  //   else if (event.tags ==constantVariables.therapy.FamilyTheraphy) {
  //     this.router.navigate(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")),
  //     theraphyid: this.commonService.FrontEndEncryption(event.tags)
      
  //   } });
  //   }
  //   else if (event.tags ==constantVariables.therapy.PsycatricTheraphy) {
  //     this.router.navigate(['/psychiatrictherapy'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")),
  //     theraphyid: this.commonService.FrontEndEncryption(event.tags)
      
  //   } });
  // }
  //   else if (event.tags ==9) {
  //     this.router.navigate(['/psychiatricmedication'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")),
  //     theraphyid: this.commonService.FrontEndEncryption(event.tags)
      
  //   } });
  // }
  // else if (event.tags ==8) {
  //   this.router.navigate(['/psychiatricevaluation'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")),
  //   theraphyid: this.commonService.FrontEndEncryption(event.tags)
    
  // } });
  // }
  
  }
  onCloseEvent() {
    this.objAppointment = undefined;
    this.isTaskShow = false;
    
    this.isTaskShowRejoiners = false;
  }

  handleMoreEvent(e: any, events: CalendarEvent[]) {
    this.moreTaskEvents = events;
    this.isShowMoreEvent = !this.isShowMoreEvent;
    let styles = {
      'top': e.srcElement.offsetTop + 'px',
      'left': e.srcElement.offsetLeft + 'px',
    };
 
    this.moreEventStyles = {...styles};
  }
  eventClicked(event) {
   if((this.datepipe.transform(new Date(), "yyyy-MM-dd"))< (this.datepipe.transform(event.start, "yyyy-MM-dd")))
    {
      this.snackbar.error("Upcoming dates selections are restricted");
      return;
    }
    if (event.tags == constantVariables.therapy.Intake) {
      this.objAppointment = undefined;
      if (this.DecrptedlstAppointments.filter(m => m.meetingScheduleId == event.id).length > 0) {
        this.objAppointment = this.DecrptedlstAppointments.filter(m => m.meetingScheduleId == event.id)[0];
        this.getDischargePatient(this.objAppointment.patientAccountId);
        
        // if(this.isReJoiners == true)
        //   {
        //     this.isShowMoreEvent = false;
        //     this.isTaskShow = false;
        //     this.isTaskShowRejoiners = true;
        //   }
        //   else
        //   {
        //     this.isShowMoreEvent = false;
        //     this.isTaskShow = true;
        //     this.isTaskShowRejoiners = false;
        //   }
      }
    }
    else if (event.tags == constantVariables.therapy.BlockTimeScheduler)
    {
      let currentEvent = this.appointments.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd")))[0];
        this.objAppointmentForPopup.id = currentEvent.appointmentId;
        this.objAppointmentForPopup.fromtime = currentEvent.fromTime;
        this.objAppointmentForPopup.scheduleDate = currentEvent.scheduleDate;
        this.objAppointmentForPopup.Duration = currentEvent.duration;
        this.objAppointmentForPopup.ScheduleDuration = currentEvent.meetingScheduleDuration;
        this.objAppointmentForPopup.ScheduleName = currentEvent.meetingScheduleName;
        this.objAppointmentForPopup.locationName = currentEvent.locationName;
        this.isShowBlockTime=true;

    }


    else if(event.tags== constantVariables.therapy.GroupTheraphy){
      this.objAppointmentForPopup = new TherapyPopObj();
      if (this.Grouptherapyappoinments.filter(m => m.groupTherapyId == event.id).length > 0 ) {
        let currentEvent = this.Grouptherapyappoinments.filter(m => m.groupTherapyId == event.id )[0];
        this.objAppointmentForPopup.id = currentEvent.groupTherapyId;
         this.objAppointmentForPopup.fromtime = currentEvent.startTime;
         this.objAppointmentForPopup.scheduleDate = event.start;
        this.objAppointmentForPopup.tags = event.tags;
        this.objAppointmentForPopup.start= event.start;
        this.objAppointmentForPopup.ScheduleName = currentEvent.groupTherapyName;
        let duration = (currentEvent.duration >= 60?((currentEvent.duration/60).toString() + " hr"):"") + ( currentEvent.duration%60>0? ((currentEvent.duration%60).toString() + " min"):"")
        this.objAppointmentForPopup.ScheduleDuration = duration
        
        if (this.objAppointmentForPopup.ScheduleName == "BlockTime") {
          this.isShowBlockTime = true;

        }
        else {
          this.isShowTherapydetails = true;
          this.isShowBlockTime = false;
          this.isShowGrpTherapydetails = false;
        }
         

        // this.isShowTherapydetails = true;
        this.getgrouptherapy(this.objAppointmentForPopup)
      }
    }
    else{
      this.objAppointmentForPopup = new TherapyPopObj();
      if (this.appointments.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd"))).length > 0 ) {
        let currentEvent = this.appointments.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd")))[0];
        this.objAppointmentForPopup.id = currentEvent.appointmentId;
        this.objAppointmentForPopup.patientName = currentEvent.userName;
        this.objAppointmentForPopup.fromtime = currentEvent.fromTime;
        this.objAppointmentForPopup.scheduleDate = currentEvent.scheduleDate;
        this.objAppointmentForPopup.email = currentEvent.patientEmail;
        this.objAppointmentForPopup.contactNo = currentEvent.contactNo;
        this.objAppointmentForPopup.AdminNotes = currentEvent.adminNotes;
        this.objAppointmentForPopup.MeetingType = currentEvent.meetingTypeId;
        if((this.datepipe.transform(new Date(), "yyyy-MM-dd"))<= (this.datepipe.transform(currentEvent.scheduleDate, "yyyy-MM-dd"))){
          this.objAppointmentForPopup.MeetingLink = currentEvent.meetingLinks;
        }
       else{
        this.objAppointmentForPopup.MeetingLink =null
       }
        this.objAppointmentForPopup.tags = event.tags;
        this.objAppointmentForPopup.start= currentEvent.scheduleDate;
        this.objAppointmentForPopup.ScheduleName = currentEvent.meetingScheduleName;
        this.objAppointmentForPopup.OffSiteAddress =  currentEvent.offSiteAddress;
        this.objAppointmentForPopup.ScheduleDuration = currentEvent.meetingScheduleDuration;
       
          this.isShowTherapydetails = true;
       
        // this.isShowTherapydetails = true;
        this.getgrouptherapy(this.objAppointmentForPopup)
      }
    }
    // else if (event.tags == constantVariables.therapy.GroupTheraphy) {
    //   // this.global.ismytask = true
    //   // this.router.navigate(['/progess-track'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")) } });
    //   this.objAppointment = undefined;
    //   if (this.DecrptedlstAppointments.filter(m => m.meetingScheduleId == event.id).length > 0) {
    //     this.objAppointment = this.DecrptedlstAppointments.filter(m => m.meetingScheduleId == event.id)[0];
    //     this.isShowTherapydetails = true;
    //   }
    // }
  
    // else if (event.tags ==constantVariables.therapy.IndividualTheraphy) {
    //       this.router.navigate(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")),
    //   theraphyid: this.commonService.FrontEndEncryption(event.tags)
    //     } });
    //   }
    //   else if (event.tags ==constantVariables.therapy.FamilyTheraphy) {
    //       this.router.navigate(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")),
    //       theraphyid: this.commonService.FrontEndEncryption(event.tags)
    //       } });
    //     }
    //     else if (event.tags ==6) {
    //       this.router.navigate(['/psychiatrictherapy'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")),
    //       theraphyid: this.commonService.FrontEndEncryption(event.tags)
          
    //     } });
       
    //   }
    //   else if (event.tags ==9) {
    //     this.router.navigate(['/psychiatricmedication'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")),
    //     theraphyid: this.commonService.FrontEndEncryption(event.tags)
        
    //   } });
    //   }
    //   else if (event.tags ==8) {
    //     this.router.navigate(['/psychiatricevaluation'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id),date: this.commonService.FrontEndEncryption(moment(event.start).format("MM/DD/YYYY")),
    //     theraphyid: this.commonService.FrontEndEncryption(event.tags)
        
    //   } });
    //   }
   }
  requestCancelMeeting(obj) {
    this.spinnerservice.show();
    let _obj = {
      MeetingScheduleId: obj.meetingScheduleId,
      Type: 1
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.clinicianservice.cancelMeeting(dataToEncrypt).subscribe(
      res => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.isTaskShow = !this.isTaskShow;
         // this.getPatientAppointments();
         this.loadCalenderAppointment(this.appointmentType);
        }
      },
      err => {

        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  updateCalendarEventsonLoad() {
     var offset = new Date().getTimezoneOffset();
    
    if (this.recurringEvents && this.recurringEvents.length > 0) {
     
      this.events=[]
      this.recurringEvents.forEach((event) => {
        //let item = event;
        const rule: RRule = new RRule({
          ...event.rrule,
          // dtstart: moment(viewRender.period.start).startOf('day').toDate(),
          // until: moment(viewRender.period.end).endOf('day').toDate(),
        });
        let endTime = event?.end;
        let therapy = event?.therapy;
        let eventTitle = event?.title;
        let currentDate = new Date();
        var arraydate = event.scheduledDate != null ? event.scheduledDate.split(',') : null;
      
        const { title, color, id,flag } = event;
        const END = event.end
        rule.all().forEach((date) => {
          this.events.push({
            title:   this.roleTypeId != constantVariables.roleType.Intern ? title.includes("DS") ? (therapy == 3 && ((moment(date).format("MM/DD/YYYY") == moment(currentDate).format("MM/DD/YYYY") && event.end >= moment(new Date()).format("HH:mm")) || (date > currentDate))) ? title.replace("{{DS}}", "ADD DS") : title.replace("{{DS}}", "") : title :  title.replace("{{DS}}", ""),
            color,
            end: moment(moment(date).format("MM/DD/YYYY") + " " + END).toDate(),
            start: moment(date).toDate(),
            id,
            tags:flag
          });
        });
      });
      //this.cdr.detectChanges();
      this.refresh.next();
    }
  }

  
  loadPsycatricTherapy(){
   
    if (this.appointments != null) {
      let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 5);
     // console.log(res);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
        //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
         end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

          },
          flag:constantVariables.therapy.PsycatricTheraphy
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
         this.refresh.next();
      }
    }
  }
 
  loadPsycatricEvaluation(){
    if (this.appointments != null) {
      let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 8);
     // console.log(res);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
        //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
         end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

          },
          flag:constantVariables.therapy.PsycatricEvaluation
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
         this.refresh.next();
      }
    }
  }
   
  loadPsycatricMedication(){
    if (this.appointments != null) {
      let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 9);
     // console.log(res);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
        //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
         end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

          },
          flag:constantVariables.therapy.PsycatricMedication
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
         this.refresh.next();
      }
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
   this.filterdate = moment(event.target.value).format('LL');
    this.viewDateForDay = new Date(moment(event.target.value).format('MM/DD/YYYY'));
    this.viewDate = new Date(moment(event.target.value).format('MM/DD/YYYY'));
    this.activeDayIsOpen = false;
    this.filterMonth = Number((moment(this.viewDate)).format('M'));
   // this.filterweekStartDate = moment(this.viewDate).add(7, 'days').format('LL');
    //this.filterweekEndDate = moment(this.viewDate).add(7, 'days').format('LL');
    this.filterweekStartDate = moment(this.viewDate).startOf('week').toString();
    this.filterweekEndDate = moment(this.viewDate).endOf('week').toString();
    this.filterMonthStartDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1);
    this.filterMonthEndDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth()+1, 0);
 // this.getPatientAppointments()
   this.loadCalenderAppointment(this.appointmentType);
  }


  closeOpenMonthViewDay(){
    this.activeDayIsOpen = false;
    this.filterdate = moment(this.viewDateForDay).format('LL');
    //this.PickedDate = moment(this.viewDateForDay).toDate();
    this.PickedDate =  new Date(moment(this.viewDateForDay).format('MM/DD/YYYY'));
    // this.getPatientAppointments();
    this.loadCalenderAppointment(this.appointmentType);
  }
  OnProgressNotes(item){
    // var location = localStorage.getItem('sfcclocation') ? localStorage.getItem('sfcclocation') : -1 
    // if(location == -1){
    //  var locations = localStorage.getItem('location').replace(/"/g, ""); 
    // //  var locationforTherapy = this.commonService.FrontEndDecryption(locations).replace(/"/g, "");
    //   this.numbers = this.splitStringToNumbers(locations);
    //   this.containsTwentyEight = this.checkIfContainsTwentyEight(this.numbers);
    //   if(this.containsTwentyEight == true){
    //     this.individualFamLocation = 28
    //   }
    // }
    // this.therapyLocation = this.individualFamLocation ? this.individualFamLocation : location
    let appointmenttypeid = this.appointments.find(x => x.appointmentId == item.id);
    if ((appointmenttypeid.locationId == 26 && item.tags == 1) || (appointmenttypeid.locationId == 26 && item.tags == 2)){
          if (item.tags ==constantVariables.therapy.IndividualTheraphy) {
           this.router.navigate(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),individual:true,
           theraphyid: this.commonService.FrontEndEncryption(item.tags)
           
         } });
          }
         else if (item.tags ==constantVariables.therapy.FamilyTheraphy) {
           this.router.navigate(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),family:true,
           theraphyid: this.commonService.FrontEndEncryption(item.tags)
         } });
          }  
          return;
        }

       if (item.tags ==constantVariables.therapy.GroupTheraphy) {
         this.global.ismytask=true;
         this.isShowTherapydetails=false;
        this.isShowGroupTherapy = true;
  // this.getgrouptherapy(item);
  //this.router.navigate(['/progess-track'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")) } });
  //this.router.navigate(['/progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")) } });
    }
  
   else if (item.tags ==constantVariables.therapy.IndividualTheraphy) {
      this.router.navigate(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),individual:false,
      theraphyid: this.commonService.FrontEndEncryption(item.tags)
      
    } });
    }
    else if (item.tags ==constantVariables.therapy.FamilyTheraphy) {
      this.router.navigate(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),family:false,
      theraphyid: this.commonService.FrontEndEncryption(item.tags)
      
    } });
    }
    else if (item.tags ==constantVariables.therapy.PsycatricTheraphy) {
      this.router.navigate(['/psychiatrictherapy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(item.tags)
      
    } });
  }
    else if (item.tags ==9) {
      this.router.navigate(['/psychiatricmedication'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(item.tags)
      
    } });
  }
  else if (item.tags ==8) {
    this.router.navigate(['/psychiatricevaluation'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),
    theraphyid: this.commonService.FrontEndEncryption(item.tags)
    
  } });
  }
  else if (item.tags ==10 ) {
    this.router.navigate(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),
    theraphyid: this.commonService.FrontEndEncryption(item.tags)
    
  } });
  }
  else if (item.tags ==11 ) {
    this.router.navigate(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),
    theraphyid: this.commonService.FrontEndEncryption(item.tags)
    
  } });
  }
  else if (item.tags ==12 ) {
    this.router.navigate(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(item.tags)
      
    } });
  }
  else if (item.tags ==13 ) {
    this.router.navigate(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),
    theraphyid: this.commonService.FrontEndEncryption(item.tags)
    
  } });
  }
  else if (item.tags ==15 ) {
    this.router.navigate(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),
    theraphyid: this.commonService.FrontEndEncryption(item.tags)
    
  } });
  }
  else if (item.tags ==16 ) {
    this.router.navigate(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.id),date: this.commonService.FrontEndEncryption(moment(item.start).format("MM/DD/YYYY")),
    theraphyid: this.commonService.FrontEndEncryption(item.tags)
    
  } });
  }
  }
  splitStringToNumbers(input: string): number[] {
    return input.split(',').map(Number);
  }
  checkIfContainsTwentyEight(numbers: number[]): any {
    return numbers.includes(28);
  }
  
  progressNoteGroupTherapy(item)
  {
    this.router.navigate(['/progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.groupTherapyId),dayScheduleSubGroupId: this.commonService.FrontEndEncryption(item.dayScheduleSubGroupId) } });
  }
  getgrouptherapy(item) {
    this.spinnerservice.show();
    this.clinicianservice.getGroupTherapyDetails(item.id,item.start,this.userId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
         if (res != null) {
          this.groupTherapyList = res;
        //    this.datanotExists=(res.length==0)?true:false;
        //   this._dtoGrpTherapyMeta = res[0];
        //   this._dtoGrpTherapy = res;
        //   this._dtofilteredGrpTherapy =  this._dtoGrpTherapy          
         
        //     this.onThemeChange( this._dtoGrpTherapy[0].themeName)            
        //    // this._ddlGrpTherapy = _.uniqBy( this._dtoGrpTherapy, 'themeName');
           
        //  // this.ongetMedication();
        //   if(this.clinicalNoteParams=='true'){
        //     this.onAddGroupNotes(5);
        //   }
        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }
  OncloseTherapyDetailspopup(){
    //this.objAppointmentForPopup = undefined
    this.isShowTherapydetails = false;
    this.isShowBlockTime=false;
    this.isShowGrpTherapydetails = false
  }
  OncloseGroupDetailspopup(){
    this.isShowGroupTherapy = false
  }
  OnMeetingLinkClick(item){
    const url = item.MeetingLink
    window.open(url, '_blank');
  }

  loadschoolmeeting(){
    if (this.appointments != null) {
      let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 10);
     // console.log(res);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
        //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
         end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

          },
          flag:constantVariables.therapy.School
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
         this.refresh.next();
      }
    }
  }

  loadphonecall(){
    if (this.appointments != null) {
      let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 11);
     // console.log(res);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
        //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
         end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

          },
          flag:constantVariables.therapy.Phonecall
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
         this.refresh.next();
      }
    }
  }
  OnSuicidalAssesment(item)
{ 
  const url = this.router.serializeUrl(this.router.createUrlTree(['/sucidal-risk'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId)} }));
  window.open(url, '_blank');
 
}

loadspeechtherapy(){
  if (this.appointments != null) {
    let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 12);
   // console.log(res);
    this.events = [];
    this.recurringEvents = [];
    for (var i = 0; i < res.length; i++) {

      let _obj = {
        start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
      //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
       end: moment(res[i].toTime).format("HH:mm"),
        title: res[i].appointmentName,
         color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
        cssClass: 'event-anchor',
        id: res[i].appointmentId,
        rrule: {
          freq: RRule.DAILY,
          dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

        },
        flag:constantVariables.therapy.Speech
      }
      this.recurringEvents = [...this.recurringEvents, _obj];
      this.updateCalendarEventsonLoad();
       this.refresh.next();
    }
  }
}
loadnutritiontherapy(){
  if (this.appointments != null) {
    let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 13);
   // console.log(res);
    this.events = [];
    this.recurringEvents = [];
    for (var i = 0; i < res.length; i++) {

      let _obj = {
        start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
      //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
       end: moment(res[i].toTime).format("HH:mm"),
        title: res[i].appointmentName,
         color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
        cssClass: 'event-anchor',
        id: res[i].appointmentId,
        rrule: {
          freq: RRule.DAILY,
          dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

        },
        flag:constantVariables.therapy.Nutrition
      }
      this.recurringEvents = [...this.recurringEvents, _obj];
      this.updateCalendarEventsonLoad();
       this.refresh.next();
    }
  }
}
loadblocktime(){
  if (this.appointments != null) {
    let res = this.appointments.filter(x => x.templateId == 3 && x.typeId == 17);
   // console.log(res);
    this.events = [];
    this.recurringEvents = [];
    for (var i = 0; i < res.length; i++) {

      let _obj = {
        start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
      //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
       end: moment(res[i].toTime).format("HH:mm"),
        title: res[i].appointmentName,
         color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
        cssClass: 'event-anchor',
        id: res[i].appointmentId,
        rrule: {
          freq: RRule.DAILY,
          dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

        },
        flag:constantVariables.therapy.Nutrition
      }
      this.recurringEvents = [...this.recurringEvents, _obj];
      this.updateCalendarEventsonLoad();
       this.refresh.next();
    }
  }
}

CheckInDetails(item){
  
  this.router.navigate(['/attendance'], 
  {
     queryParams: 
    { 
      dayScheduleSubGroupId: this.commonService.FrontEndEncryption(item.dayScheduleSubGroupId),
      IsClinician: this.commonService.FrontEndEncryption(true)
    } 
  });
}

getPatientlist() {
  let location = +localStorage.getItem('sfcclocation')
  this.spinnerservice.show()
  if(location <= 0){
    location = -1;
  }
  this.secondaryId = localStorage.getItem('id');
  this.clinicianservice.getPatientListforDS(-1, location,this.secondaryId).subscribe(res => {
    this.ddlpatientList = res;
    
    if (this.UserId) {
      this.staffName = this.ddlpatientList.filter(x=>x.primaryClinicianId == this.UserId)[0].staffName;
      console.log(this.staffName);
    }
    this.spinnerservice.hide();
  },
    err => {
      this.spinnerservice.hide();

    });
}
getClientName() {
  let location = +localStorage.getItem('sfcclocation')
  this.spinnerservice.show()
  if(location <= 0){
    location = -1;
  }
  this.clinicianservice.getClientName(-1, location).subscribe(res => {
    this.ddlclientNameList = res;
    console.log(this.ddlclientNameList)
    this.spinnerservice.hide();
  },
    err => {
      this.spinnerservice.hide();

    });
}
clientnamedetails(event: any) {
 
//  this.filterControl.setValue('');
  this.clientTherapydetails = [];
  this.clientTherapydetailsDelete = [];
  this.appointmentTypes = 'all'
  this.clientdialogRef = this.dialog.open(this.clientModal,

    { width: '920px' });

  this.PatientNameDetails();
  this.isAutocompleteEnabled = !this.isAutocompleteEnabled;
}
SharePopupClose() {
  this.clientdialogRef = false;
}
filterOptions(value: any) {
  this.dateview = true;
  const filterValue = value.toLowerCase();
  return this.ddlclientNameList.filter((option: any) =>
    option.patientName.toLowerCase().includes(filterValue)
  );
}
PatientNameDetails() {
  this.clientTherapydetails = [];
  this.clientTherapydetailsDelete = [];
const patientName = this.filterControl.value;
this.patientNameview = this.filterControl.value;
  // this.patientNameDetails = this.filteredOptions ;
  const PatientAccountId = this.filteredOptions.find(e => e.patientName == patientName).patientAccountId;
  let _obj = {
    PatientAccountId: PatientAccountId
  }
  const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
  this.clinicianservice.GetSearchAppointmentsPatient(dataToEncrypt).subscribe(
    response => {
      var res = JSON.parse(response);
      res.forEach(record => {
        const isDeleted = record.isDeleted;
        record['scheduleDate'] = moment(record['scheduleDate']).format("MM/DD/YYYY")
        if(this.appointmentTypes == "past" && (this.datepipe.transform(this.currentDate, "yyyy-MM-dd") > (this.datepipe.transform(record['scheduleDate'], "yyyy-MM-dd")))  && !isDeleted)
        {
          this.clientTherapydetails.push(record)

        } if(this.appointmentTypes == "future" && (this.datepipe.transform(this.currentDate, "yyyy-MM-dd")  < (this.datepipe.transform(record['scheduleDate'], "yyyy-MM-dd")))  && !isDeleted )
         {
          this.clientTherapydetails.push(record)

        } if (this.appointmentTypes == "all"  && !isDeleted)
        {
          this.clientTherapydetails.push(record)

        }
        if (this.appointmentTypes == "Deleted" && isDeleted)
          {
            this.clientTherapydetailsDelete.push(record)

          }
      });

      this.spinnerservice.hide();
    },
    err => {
      this.spinnerservice.hide();
    },
  );

}
appointmentchangeValue(event) {
  this.appointmentTypes =  event.target.value
  this.PatientNameDetails();

}

chooseTheraphy(data:any) {
 this.clientdialogRef.close();
 this.date_view = data.scheduleDate;
 this.roleAccess.isView = true;
 this.view = CalendarView.Day;
 this.filterType = 1;
 this.viewDate=this.date_view;
 this.onTabChange(this.filterType);
 this.filterdate = moment(moment(this.date_view )).format('LL');
 this.PickedDate =  new Date(moment(this.date_view ).format('MM/DD/YYYY'))
 this.viewDateForDay =new Date(moment(this.date_view ).format('MM/DD/YYYY'))
 this.loadCalenderAppointment(this.appointmentType);
 this.refresh.next();
}

setdefaultValue(){
  // this.dataSource = new MatTableDataSource<any>;
  this.DecrptedlstAppointments ='';
  this.lstAppointments = '';
  this.userId = '';
  this.filterdate = '';
  this.filterweekStartDate= '';
  this.filterweekEndDate= '';
  this.filterMonth= '';
  this.filterMonthdisplay= '';
  this.meetingScheduleId= '';
  this.filterType= '';
  this.isReSchedule = false;
  this.isConflict = false;
  this.Day1viewDate = new Date();
  this.meetingDateBlocks = '';
  this.fromTime ='';
  this.currentdate ='';
  this.toTime ='';
  this.Day1StartTime='';
  this.Day1EndTime='';
  this.staffAppointmentDetails ='';
  this.staffAppointmentDay1 = [];
  this.timeslots = [];
  this.noslots = false;
  this.submitted = false;
  this.view = CalendarView.Month;
  this.CalendarView = CalendarView;
  this.refresh = new Subject();
  this.events = [];
  this.activeDayIsOpen = false;
  this.viewDate = new Date();
  this.viewDateForDay= moment().toDate();
  this.isTaskShow = false;
  this.objAppointment = '';
  this.isShowMoreEvent = false;
  this.EVENT_LIMIT = 4;
  this.moreTaskEvents = [];
  this.rescheduleReason = '';
  this.appointmentType  ='';
  this.recurringEvents = [];
  this.moreEventStyles='';
  this.clickedDate = new Date;
  this.appointments =[];
  this.rescheduleDate ='';
  this.weekViewEventTemplate ='';
  this.PickedDate = new Date;
  this.Grouptherapyappoinments ='';
  this.isShowTherapydetails =false;
  this.objAppointmentForPopup = new TherapyPopObj;
  this.GrouptherapyId=constantVariables.therapy.GroupTheraphy;
  this.MenuAccess = new MenuList;
  this.roleAccess = new RoelAccess;
  this.viewRightsMsg ='';
  this.IsNewRescheduleIntake = false;
  this.isShowGroupTherapy =false;
  this.groupTherapyList =[];
  this.filterMonthStartDate ='';
  this.filterMonthEndDate ='';


  this.ddlpatientList = [];
  this.filteredOptions = [];
  this.searchTerm = '';
  this.isSchedulerview = false
  this.patientNameDetails ='';
  this.currentDate ='';
  this.clientdialogRef = '';
  this.clientTherapydetails = [];
  this.clientTherapydetailsDelete = [];
  this.appointmentTypes = '';
  this.date_view ='';
  this.patientNameview ='';
  this.dateview  = false;
  this.ddlclientNameList = [];
  this.onClick();
}
onClick() {
  this.filterControl.reset();
  this.isAutocompleteEnabled = true;
}
onKeyUp(event: KeyboardEvent){
  const inputValue = (event.target as HTMLInputElement).value;
  this.filteredOptions = this.filterOptions(inputValue);
  console.log(this.filteredOptions)
}
onGeneratePDF(appointmentTypes){
  this.appointmentTypes =  appointmentTypes;
  this.appoitmenttypefirst = this.appointmentTypes.charAt(0);
  this.spinnerservice.show();
const patientName = this.filterControl.value;

  const PatientAccountId = this.filteredOptions.find(e => e.patientName == patientName).patientAccountId;
 
  this.clinicianservice.onGenerateSchedulerpopupPDF(PatientAccountId,this.appoitmenttypefirst).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
           this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
    },
    err => {
      this.spinnerservice.hide();
    },
  );

}
getExistingReArrangeClinician() {
  let location = +localStorage.getItem('sfcclocation')
  this.spinnerservice.show()
  if(location <= 0){
    location = -1;
  }
  // var location = localStorage.getItem('sfcclocation')
  // if (location == null) {
  //   let stringToSplit = localStorage.getItem('location');
  //   location = stringToSplit.replace(/['"]+/g, '');

  // }
  this.commonService.getReArrangeClinician(localStorage.getItem('id'), location).subscribe(
    res => {
      this.spinnerservice.hide();
      if (res != null) {
        this.ExistingReArrangeDatas = res;
        if (this.ExistingReArrangeDatas.length == this.drpClinicians.length) {
          this.drpClinicians = [];
          this.drpClinicians = this.ExistingReArrangeDatas;

          // this.selectedClinician=(this.isShowDayView)?0:this.drpClinicians[0].clinicianId;
          this.selectedClinician = (localStorage.getItem("adminDayViewClinician") == "-1") ? this.drpClinicians[0].clinicianId :
            localStorage.getItem("adminDayViewClinician");

          this.isReArrangeClinician = false;
        }
      }
       this.drpClinicians = [];
       this.drpClinicians = this.ExistingReArrangeDatas;
      // this.loadCalenderAppointment(this.appointmentType);
    },
    err => {
      this.isReArrangeClinician = false;
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}

onAppointmentClinicianchange() {
  if (this.isShowDayView == true) {
    localStorage.setItem("adminDayViewClinician", this.selectedClinician);
    localStorage.setItem("adminDayViewAppointment", this.dtoAppointmentType);
    this.commonService.adminDayViewDrpChange.next(false);

  }
  else {
    if (this.selectedClinician == -1) {
      this.snackbar.error("Must select one clinician");
      // this.selectedClinician = this.userId;
      this.loadCalenderAppointment(this.appointmentType);
      return;
      // this.loadCalenderAppointmentforScheduler(this.appointmentType);
      // this.selectedClinician = this.drpClinicians[0].clinicianId;
      // this.snackbar.error("Must select one clinician");
    }
    localStorage.setItem("adminDayViewClinician", this.selectedClinician);
    localStorage.setItem("adminDayViewAppointment", this.dtoAppointmentType);
    this.spinnerservice.hide();
    this.recurringEvents = [];
    this.events = [];
    if (this.view == "week") { this.filterbyWeeks(); }
    if (this.view == "month") { this.filterbyMonths(); }
    //if(this.dtoAppointmentType!=3 )
    // {
    if (this.dtoAppointmentType == 1) {
      this.loadCalenderAppointmentforScheduler(this.appointmentType);
        } else if (this.dtoAppointmentType == 1) {
      this.loadIntake();
    }
    else if (this.dtoAppointmentType == 3) {
      this.loadIndividualTherapy();
    }
    else if (this.dtoAppointmentType == 4) {
      this.loadFamilyTherapy();
    }
    else if (this.dtoAppointmentType == 5) {
      this.loadTour();
    }
    else if (this.dtoAppointmentType == 6) {
      this.loadPsycatricTherapy();
    }
    else if (this.dtoAppointmentType == 7) {
      this.loadPsycatricEvaluation()
    }
    else if (this.dtoAppointmentType == 8) {
      this.loadPsycatricMedication()
    }
    else if (this.dtoAppointmentType == 2) {
      this.getGrpTherapy();
    }
    else if (this.dtoAppointmentType == 11) {
      this.loadphonecall();
    }
    else if (this.dtoAppointmentType == 10) {
      this.loadschoolmeeting();
    }
    else if (this.dtoAppointmentType == 12) {
      this.loadspeechtherapy();
    }
    else if (this.dtoAppointmentType == 13) {
      this.loadnutritiontherapy();
    }
    else if (this.dtoAppointmentType == 14) {
      this.loadblocktime();
    }
    this.refresh.next();
    // }
    //  else if (this.dtoAppointmentType == 3) {
    //     //this.loadGroupTherapymeeting();
    //     this.getGrpTherapy();
    //   }
  }

}
loadIntake() {
  if (this.appointments != null) {
    //let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 1);
    let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 1 && x.typeId == 1 && x.clinicianId == this.selectedClinician) : this.appointments.filter(x => x.templateId == 1 && x.typeId == 1);
    this.events = [];
    this.recurringEvents = [];
    for (var i = 0; i < res.length; i++) {

      let _obj = {
        start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
        // end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
        end: moment(res[i].toTime).format("HH:mm"),
        title: res[i].appointmentName,
         color: colors.white,
        cssClass: 'event-anchor',
        id: res[i].appointmentId,
        rrule: {
          freq: RRule.DAILY,
          dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

        },
        tags: constantVariables.therapy.Intake
      }

      this.recurringEvents = [...this.recurringEvents, _obj];
      this.updateCalendarEventsonLoad();
      this.cdr.detectChanges();
      this.refresh.next();
    }
  }
}
filterbyWeeks() {
  this.filterType = 2;
  this.onTabChange(this.filterType);
  this.view = CalendarView.Week;
  if(this.dateview == true){
    this.setdefaultValue();
    this.ngOnInit();
   }
  this.filterweekStartDate = moment(this.viewDate).startOf('week').toString();
  this.filterweekEndDate = moment(this.viewDate).endOf('week').toString();
}
filterbyMonths() {
  this.filterType = 3;
  this.view = CalendarView.Month;
  if(this.dateview == true){
    this.setdefaultValue();
    this.ngOnInit();
   }
  this.filterMonthdisplay = moment().format('MMM YYYY');
  this.filterMonth = Number((moment(this.viewDate)).format('M'));

}

MedicationConsent(objAppointmentForPopup,objAppointment){
  const url = this.router.serializeUrl(this.router.createUrlTree(['/medication-pdfforchildren'],{ queryParams: {  patientName: this.commonService.FrontEndEncryption(objAppointmentForPopup.patientName),clinicianId: this.commonService.FrontEndEncryption(objAppointmentForPopup.ClinicianId),Meetingscheduleid: this.commonService.FrontEndEncryption(objAppointmentForPopup.id),isReview: this.isReview}} )) 
  window.open(url, '_blank');

}
MedicationReview(objAppointmentForPopup){
  this.isReview = true;
  console.log(this.staffName)
  const url = this.router.serializeUrl(this.router.createUrlTree(['/medication-reviewsheet'],{ queryParams: {  patientName: this.commonService.FrontEndEncryption(objAppointmentForPopup.patientName), staffName: this.commonService.FrontEndEncryption(this.staffName),clinicianId: this.commonService.FrontEndEncryption(objAppointmentForPopup.ClinicianId),Meetingscheduleid: this.commonService.FrontEndEncryption(objAppointmentForPopup.id),isReview: this.isReview}} )) 
  window.open(url, '_blank');
}
getDischargePatient(patientAccountId)
{
if(patientAccountId>0)
  this.clinicianservice.getDischargePatient(patientAccountId).subscribe(res => {
    if(res.patientAccountId>0)
      {
        this._dtoPatientDischarge = res;
        this.isReJoiners = true; 

        
            this.isShowMoreEvent = false;
            this.isTaskShow = false;
            this.isTaskShowRejoiners = true;
        
      }
      else
      {
      //  this._dtoPatientDischarge = "";
        this.isReJoiners = false; 
        this.isShowMoreEvent = false;
        this.isTaskShow = true;
        this.isTaskShowRejoiners = false;

      }
  },
    err => {
      this.spinnerservice.hide();

    });
}
setdefaultValueforrejoiner()
{
  this._dtoPatientDischarge.isIntakePacket = false;
  this._dtoPatientDischarge.isNutritionalQuestionnaire = false;
  this._dtoPatientDischarge.isPainAssessment = false;
  this._dtoPatientDischarge.isSuicidalRiskAssessment = false;
  this._dtoPatientDischarge.isIntakeUpdate = false;
  this._dtoPatientDischarge.isComprehensiveMHA = false;

}
OnIntakeUpdate(item)
{ 
  const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-update-rejoiners'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId)} }));
  window.open(url, '_blank');
 
}

Documentviewforclient(item){
  localStorage.setItem("PatientAccountIddoc",item.patientAccountId);
  this.router.navigate(['/patient-documents']);
}

}



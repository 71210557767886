<div class="d-flex">
<div class="ml-auto" *ngIf="datanotExists" >
  <button type="button" mat-stroked-button (click)="onBackClick()"
    class="bg-white top-back  mt-1 mb-2 h-40 mr-2">Back</button>
</div> 
</div>
<div *ngIf="datanotExists" class="no-patients"> 
  No patient is assigned to selected therapy
  <div class="image-nopatient"> <img class="img-fluid" src="../../../../assets/images/no-patients-assigned.png"></div>
</div>

<div  *ngIf="!datanotExists">
<div class="schedule-group">
 <div class="d-flex met-info-top w-100">
    <i class="fas fa-users"></i>
    <div class="mr-4">Group Therapy Name
      <span class="imp-info">{{_dtoGrpTherapyMeta?.groupTherapyName }}</span>
    </div>
    <div class="mr-4 ">
      Start Date
      <span class="imp-info">{{_dtoGrpTherapyMeta?.startDate | date:'MM/dd/YYYY'}}</span>
    </div>
    <div class="mr-4 ">
      Start Time
      <span class="imp-info">{{_dtoGrpTherapyMeta?.therapyStartTime | date:'hh:mm a'}}</span>
    </div>
    <div class="mr-4">
      Duration
      <span class="imp-info">{{global.getMinutesSlot(_dtoGrpTherapyMeta?.duration)}} </span>
    </div>


    <div class="ml-auto">
      <button type="button" mat-stroked-button (click)="onBackClick()"
        class="bg-white top-back  mt-1 mb-2 h-40 mr-2">Back</button>
    </div>
   
  </div>
</div>
<div class="five-points-container">
  <div class="d-flex heading-point-five">
    <h6>{{_dtofilteredGrpTherapy[0]?.themeName}}</h6>
    <div class="time-stamp-txt">

      <label >Start Time - <span>{{_dtofilteredGrpTherapy[0]?.startTime | date:'hh:mm a'}}</span></label>
      <label class="ml-2">End Time - <span>{{_dtofilteredGrpTherapy[0]?.endTime | date:'hh:mm a'}}</span></label>
    </div>
    <div class="ml-auto">
      <div class="d-flex">


      <span class="imp-inf mt-2 mr-2">Go to Other SubGroups </span>
      <select class="form-control d-inline-block status-filter" [(ngModel)]="daySchedule"  (change)="onThemeChange($event.target.value)">
        <option *ngFor="let a of _ddlGrpTherapy" value="{{a.themeName}}">{{a.themeName}}</option>
      </select>
    </div>
    </div>
  </div>

  <div class="listing-groups-clinican bg-white p-2 pl-3 pr-3">
    <!-- <div class="heading-assign-stud">Assigned Patients- <span>{{currentDate | date:'EEEE, MMMM d, y'}}</span> -->
      <div class="heading-assign-stud">Assigned Patients- <span>{{selecteddate | date:'EEEE, MMMM d, y'}}</span>

    </div>
    <div class="hr-diamond">

    </div>

    <div class="details-students-group">
      <div class="col-md-12  p-0">
        <div class="row">
          <div class="col-md-3 stud-name-sec pr-0 d-none ">
            <label class="name-label">Patient Name</label>
            <ng-container *ngFor="let item of _dtofilteredGrpTherapy;let i =index">
              <div class="border-card "
                [ngClass]="{'active':item.patientAccountId == grpTherapydetails?.patientAccountId}"
                (click)="getProgressActivityDetails(item)">
                <h5>{{item.userName}} <span>- {{item.themeName}}</span></h5>
                <div class="dob-detail">DOB: <span>{{item.dob | date:'MM/dd/YYYY'}}</span></div>
              </div>
            </ng-container>
          </div>
          <div class="list-of-pat">
            <div class="name-label mt-0 mb-2">Select the Patient Name</div>
            <button class="button-radio-custom" *ngFor="let item of _dtofilteredGrpTherapy;let i =index" (click)="getProgressActivityDetails(item)" [ngClass]="{'active show': item.patientInfoId == patientinfoid }">{{item.userName}}<div  class="font-10">DOB: <span >{{item.dob | date:'MM/dd/YYYY'}}</span></div></button>
          </div>
          <div class="col-md-12 pb-2">
            <div class="heading-assign-stud">
              Aggregate Goals
              <div  class="hr-diamond"></div>
            </div>
          </div>

          <div class="col-md-12 observ-bg" *ngIf="_dtoProgressActivity?.length !=0 ">
            <nav class="tabs-observ">
              <div class="nav nav-pills  justify-content-start" id="nav-tab" role="tablist">
                <!-- <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" (click)="onFivepoint()"
                  href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Five Point Ratings</a>
                <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" (click)="onAddGroupNotes(5)"
                  href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Clinical Notes</a> -->

                <button class="nav-item nav-link" [class.active]="showRatings" id="nav-home-tab" data-toggle="tab" (click)="onFivepoint()"
                role="tab" aria-controls="nav-home" aria-selected="true" style="border: none;">Five Point Ratings</button>
                <button class="nav-item nav-link" [class.active]="showClinicalNotes" id="toggle" data-toggle="tab" (click)="onAddGroupNotes(5)"
                role="tab" aria-controls="nav-profile" aria-selected="false" style="border: none;">Clinical Notes</button>

                <!-- <a class="nav-item nav-link" (click)="ongetMedication()" id="nav-contact-tab" data-toggle="tab"
                  href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Medication
                  History</a> -->
                <!-- <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</a> -->
                <div class="ml-auto"><a (click)="onPreviewTherapy()" class=" preview-btn-obser mr-2 cursor-pointer "
                    id="nav-contact-tab" aria-selected="false"><i class="far fa-eye mr-2"></i>Preview</a>
                  <a mimetype="application/octet-stream" #downloadLink></a>
                  <a (click)="getProgressPDF(0)" class=" preview-btn-obser cursor-pointer " id="nav-contact-tab"
                    aria-selected="false"><i class="fas fa-file-alt mr-2"></i>Generate Report</a>
                  <button  class="preview-btn-obser cursor-pointer"  mat-type="button" (click)="getProgressPDF(1)"><i class="fa fa-print mr-2"></i></button>

                </div>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" *ngIf="showRatings" >
                <div class="d-flex">
                  <label class="name-label">Your Observations For
                    <span>{{grpTherapydetails?.userName}}</span></label>
                  <div class="ml-auto mt-2" *ngIf="!isPatientLogin">
                    <button form="frmProgress" type="submit" class="btn primary-btn-outlined-small" *ngIf="roleAccess.isCreate||roleAccess.isEdit"><i
                        class="far fa-save mr-2"></i>Save</button>

                  </div>
                </div>

                <div class="observ-section">
                  <div class="col-md-12 p-0">
                    <!-- <div  class="table-responsive aggr-table">
                      <table  class="table">
                        <thead >
                          <tr>
                            <th  scope="col">Activity </th>
                            <th  scope="col" style="width: 120px;">Social Skills</th>
                            <th  scope="col" style="width: 120px;">Interpersonal Effectiveness </th>
                           <th  scope="col" style="width: 140px;">Distress Tolerance</th>
                           <th  scope="col" style="width: 120px;" >Life Skills</th>
                           <th  scope="col" style="width: 136px;" >Exposure Therapy</th>
                           <th  scope="col" style="width: 120px;" >Purposeful Movement</th>
                           <th  scope="col" style="width: 60px;"> Total </th>
                           <th  scope="col" style="width: 60px;">Status</th>
                          </tr>
                        </thead>
                        <tbody >
                          <tr>
                            <th  scope="row">Participate in task</th>
                            <td><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td><div  class="letter-total"> 4</div></td>
                            <td  ></td>
                          </tr>
                          <tr>
                            <th  scope="row">Be respectful to others</th>
                            <td><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td><div  class="letter-total"> 4</div></td>
                            <td  ></td>
                          </tr>
                          <tr>
                            <th  scope="row">Listen and follow directions</th>
                            <td><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td><div  class="letter-total"> 4</div></td>
                            <td  ></td>
                          </tr>
                          <tr>
                            <th  scope="row"><a (click)="toggleup = !toggleup" [ngClass]="{'collapsed' : toggleup}">
                              <i class="fa fa-caret-right mr-2"></i> Weight Loss
        
                            </a></th>
                            <td><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td><div  class="letter-total"> 4</div></td>
                            <td  > <div  class="goal-stat-chang ">
                              <span  class="status-tabl completed">
                                Completed</span>
                              </div></td>
                          </tr>
                          <tr>
                            <td colspan="9">
                              <div id="collapseOne" class="panel-collapse collapse  in"  [ngClass]="{'show' : toggleup}">
                                <div class="panel-body">
                                 <div class="col-md-12 ben-head">
                                   <div class="row">
                                     
                                     <div class="col-md-10 ben_des text-left">
                                      <div class=" ben_tit">
                                        Run for 2km
                                      </div>
                                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                    </div>
                    
                                    <div class="col-md-2 ben_unit">
                                      <p class="text-muted text-xs">Unit of Measure</p>
                                      10
                                    </div>
                                   </div>
                                 </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th  scope="row"><a (click)="toggle = !toggle" [ngClass]="{'collapsed' : toggle}">
                              <i class="fa fa-caret-right mr-2"></i> Weight Loss
        
                            </a></th>
                            <td><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                              <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                              <mat-radio-button value="2">N</mat-radio-button>
                            </mat-radio-group></div></td>
                            <td><div  class="letter-total"> 4</div></td>
                            <td  >
                              <div  class="goal-stat-chang ">
                                <span  class="status-tabl completed">
                                  Completed</span>
                                </div>

                            </td>
                          </tr>
                          <tr>
                            <td colspan="9">
                              <div id="collapseOne" class="panel-collapse collapse  in" [ngClass]="{'show' : toggle}" >
                                <div class="panel-body">
                                 <div class="col-md-12 ben-head">
                                   <div class="row">
                                     
                                     <div class="col-md-10 ben_des text-left">
                                      <div class=" ben_tit">
                                        Run for 2km
                                      </div>
                                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                    </div>
                                    
                                    <div class="col-md-2 ben_unit">
                                      <p class="text-muted text-xs">Unit of Measure</p>
                                      10
                                    </div>
                                   </div>
                                 </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr >
                            <th  scope="row">Total</th>
                            <td >
                              <div  class="letter-total">3</div>
                            </td>
                            <td >
                              <div  class="letter-total">2</div>
                            </td>
                            <td >
                              <div class="letter-total">1</div>
                            </td>
                            <td >
                              <div  class="letter-total">3</div>
                            </td>
                           <td>
                             <div class="letter-total">2</div>
                            </td>
                            <td><div  class="letter-total">1</div>
                            </td>
                            <td>

                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Add Notes For Group Intervention  </th>
                            <td >
                              <button class="btn add-notes-btn" title="Add Notes For Social Skill"><i class="fas fa-plus-circle clear-both float-left w-100"></i>Add Notes</button>
                            </td>
                            <td >
                              <button class="btn add-notes-btn" title="Add Notes For Interpersonal Effectiveness"><i class="fas fa-plus-circle clear-both float-left w-100"></i>Add Notes</button>
                            </td>
                            <td >
                             
                              <button class="btn add-notes-btn " title="Add Notes For Distress Tolerance"><i class="fas fa-plus-circle clear-both float-left w-100"></i>Add Notes</button>
                            </td>
                            <td >
                              <button class="btn add-notes-btn " title="Add Notes For Life Skills"><i class="fas fa-plus-circle clear-both float-left w-100"></i>Add Notes</button>
                             
                            </td>
                            
                            <td >
                              <button class="btn add-notes-btn " title="Add Notes For Exposure Therapy"><i class="fas fa-plus-circle clear-both float-left w-100"></i>Add Notes</button>
                             
                            </td>
                            
                            <td >
                              <button class="btn add-notes-btn " title="Add Notes For Purposeful Movement"><i class="fas fa-plus-circle clear-both float-left w-100"></i>Add Notes</button>
                             
                            </td>
                            
                          </tr>
                          </tbody>
                        </table>
                      </div> -->
                      <!--<div  class="table-responsive aggr-table">
                        <table  class="table">
                          <thead >
                            <tr>
                              <th  scope="col">Activity </th>
                              <th  scope="col" style="width: 120px;">Social Skills</th>
                              <th  scope="col" style="width: 155px;">Skill Generalization </th>
                             <th  scope="col" style="width: 155px;">Distress Intolerance</th>
                             <th  scope="col" style="width: 120px;" >Life Skills</th>
                             <th  scope="col" style="width: 60px;"> Total </th>
                             <th  scope="col" style="width: 60px;">Status</th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr>
                              <th  scope="row">Participate in task</th>
                              <td><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              
                              <td><div  class="letter-total"> 4</div></td>
                              <td  ></td>
                            </tr>
                            <tr>
                              <th  scope="row">Be respectful to others</th>
                              <td><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              
                              <td><div  class="letter-total"> 4</div></td>
                              <td  ></td>
                            </tr>
                            <tr>
                              <th  scope="row">Listen and follow directions</th>
                              <td><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              
                              <td><div  class="letter-total"> 4</div></td>
                              <td  ></td>
                            </tr>
                            <tr>
                              <th  scope="row"><a (click)="toggleup = !toggleup" [ngClass]="{'collapsed' : toggleup}">
                                <i class="fa fa-caret-right mr-2"></i> Weight Loss
          
                              </a></th>
                              <td><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              
                              <td><div  class="letter-total"> 4</div></td>
                              <td  > <div  class="goal-stat-chang ">
                                <span  class="status-tabl completed">
                                  Completed</span>
                                </div></td>
                            </tr>
                            <tr>
                              <td colspan="9">
                                <div id="collapseOne" class="panel-collapse collapse  in"  [ngClass]="{'show' : toggleup}">
                                  <div class="panel-body">
                                   <div class="col-md-12 ben-head">
                                     <div class="row">
                                       
                                       <div class="col-md-10 ben_des text-left">
                                        <div class=" ben_tit">
                                          Run for 2km
                                        </div>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                      </div>
                      
                                      <div class="col-md-2 ben_unit">
                                        <p class="text-muted text-xs">Unit of Measure</p>
                                        10
                                      </div>
                                     </div>
                                   </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th  scope="row"><a (click)="toggle = !toggle" [ngClass]="{'collapsed' : toggle}">
                                <i class="fa fa-caret-right mr-2"></i> Weight Loss
          
                              </a></th>
                              <td><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              <td  ><div  class="custom-radio"><mat-radio-group aria-label="Select an option">
                                <mat-radio-button value="1" class="mr-1">Y</mat-radio-button>
                                <mat-radio-button value="2">N</mat-radio-button>
                              </mat-radio-group></div></td>
                              
                              <td><div  class="letter-total"> 4</div></td>
                              <td  >
                                <div  class="goal-stat-chang ">
                                  <span  class="status-tabl completed">
                                    Completed</span>
                                  </div>
  
                              </td>
                            </tr>
                            <tr>
                              <td colspan="9">
                                <div id="collapseOne" class="panel-collapse collapse  in" [ngClass]="{'show' : toggle}" >
                                  <div class="panel-body">
                                   <div class="col-md-12 ben-head">
                                     <div class="row">
                                       
                                       <div class="col-md-10 ben_des text-left">
                                        <div class=" ben_tit">
                                          Run for 2km
                                        </div>
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                                      </div>
                                      
                                      <div class="col-md-2 ben_unit">
                                        <p class="text-muted text-xs">Unit of Measure</p>
                                        10
                                      </div>
                                     </div>
                                   </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr >
                              <th  scope="row">Total</th>
                              <td >
                                <div  class="letter-total">3</div>
                              </td>
                              <td >
                                <div  class="letter-total">2</div>
                              </td>
                              <td >
                                <div class="letter-total">1</div>
                              </td>
                              <td >
                                <div  class="letter-total">3</div>
                              </td>
                            
                              <td>
  
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">Add Notes For Group Intervention  </th>
                              <td >
                                <button class="btn add-notes-btn" title="Add Notes For Social Skill"><i class="fas fa-plus-circle clear-both float-left w-100"></i>Add Notes</button>
                              </td>
                              <td >
                                <button class="btn add-notes-btn" title="Add Notes For Skill Generalization"><i class="fas fa-plus-circle clear-both float-left w-100"></i>Add Notes</button>
                              </td>
                              <td >
                               
                                <button class="btn add-notes-btn " title="Add Notes For Distress Intolerance"><i class="fas fa-plus-circle clear-both float-left w-100"></i>Add Notes</button>
                              </td>
                              <td >
                                <button class="btn add-notes-btn " title="Add Notes For Life Skills"><i class="fas fa-plus-circle clear-both float-left w-100"></i>Add Notes</button>
                               
                              </td>
                              
                             
                              
                            </tr>
                            </tbody>
                          </table>
                        </div>-->
                    <form (ngSubmit)="frmProgress.form.valid  && postfrmProgress()" id="frmProgress" autocomplete="off"
                      #frmProgress="ngForm" novalidate>
                      <div class="table-responsive aggr-table">
                      <table class="table">

                        <thead>
                          <tr>
                            <th scope="col">Activity </th>
                            <th scope="col" style="width:120px;" >Social Skills</th>
                            <th scope="col" style="width:120px;">Interpersonal Effectiveness
                            </th>
                            <th scope="col" style="width:151px;" *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'PCP'">Skills Generalization</th>
                            <th scope="col" style="width:140px;" >Distress Tolerance</th>
                            <th scope="col" style="width:120px;"  *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">Life Skills</th>

                            <th scope="col" style="width:136px;"  *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">Exposure Therapy</th>
                            <th scope="col" style="width:120px;"  *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">Purposeful Movement</th>

                            <th scope="col" style="width:60px;"> Total </th>
                            <th scope="col" style="width:100px;"> Status </th>
                            <!--<th scope="col" style="width:60px;">Total Aggregate</th>-->
                          </tr>
                        </thead>

                        <tbody *ngFor="let item of _dtoProgressActivity;let i =index"   >
                          
                          <tr >
                            <!--<th scope="row"   >{{item.activityName}}</th>-->
                            <th  scope="row"><a (click)="item.expand = !item.expand" [ngClass]="{'collapsed' : item.expand}" *ngIf="item.trackBenchmarkId!=null" >
                              <i class="fa fa-caret-right mr-2" *ngIf="item.trackBenchmarkId!=null"></i> 
        
                            </a> {{item.activityName}} </th>
                            <td>
                              <div class="custom-radio">
                                <mat-radio-group aria-label="Select an option" [(ngModel)]="item.socialSkills"
                                  #socialSkills="ngModel" name="socialSkills{{i}}" required>
                                  <mat-radio-button class="mr-1" [value]="true">Y
                                  </mat-radio-button>
                                  <mat-radio-button [value]="false">N
                                  </mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="frmProgress.submitted && socialSkills.invalid">
                                  <mat-error class="mat-form-field-subscript-wrapper"
                                    *ngIf="socialSkills.errors.required">This field
                                    is required
                                  </mat-error>
                                </mat-error>
                              </div>
                            </td>
                            <td >
                              <div class="custom-radio">
                                <mat-radio-group aria-label="Select an option"
                                  [(ngModel)]="item.interpersonalEffectiveness" #interpersonalEffectiveness="ngModel"
                                  name="interpersonalEffectiveness{{i}}" required>
                                  <mat-radio-button class="mr-1" [value]="true">Y
                                  </mat-radio-button>
                                  <mat-radio-button [value]="false">N
                                  </mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="frmProgress.submitted && interpersonalEffectiveness.invalid">
                                  <mat-error class="mat-form-field-subscript-wrapper"
                                    *ngIf="interpersonalEffectiveness.errors.required">
                                    This field is required
                                  </mat-error>
                                </mat-error>
                              </div>
                            </td>
                            <td *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'PCP'">
                              <div class="custom-radio" *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'PCP'">
                                <mat-radio-group aria-label="Select an option" [(ngModel)]="item.skillsGeneralization"
                                  #skillsGeneralization="ngModel" name="skillsGeneralization{{i}}" required>
                                  <mat-radio-button class="mr-1" [value]="true">Y
                                  </mat-radio-button>
                                  <mat-radio-button [value]="false">N
                                  </mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="frmProgress.submitted && skillsGeneralization.invalid">
                                  <mat-error class="mat-form-field-subscript-wrapper"
                                    *ngIf="skillsGeneralization.errors.required">
                                    This field is required
                                  </mat-error>
                                </mat-error>
                              </div>
                            </td>
                            <td>
                              <div class="custom-radio">
                                <mat-radio-group aria-label="Select an option" [(ngModel)]="item.distressTolerance"
                                  #distressTolerance="ngModel" name="distressTolerance{{i}}" required>
                                  <mat-radio-button class="mr-1" [value]="true">Y
                                  </mat-radio-button>
                                  <mat-radio-button [value]="false">N
                                  </mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="frmProgress.submitted && distressTolerance.invalid">
                                  <mat-error class="mat-form-field-subscript-wrapper"
                                    *ngIf="distressTolerance.errors.required">
                                    This field is required
                                  </mat-error>
                                </mat-error>
                              </div>
                            </td>
                            <td  *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                              <div class="custom-radio">
                                <mat-radio-group aria-label="Select an option"
                                  [(ngModel)]="item.lifeSkills" #interpersonalEffectiveness="ngModel"
                                  name="lifeSkills{{i}}" required>
                                  <mat-radio-button class="mr-1" [value]="true">Y
                                  </mat-radio-button>
                                  <mat-radio-button [value]="false">N
                                  </mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="frmProgress.submitted && lifeSkills.invalid">
                                  <mat-error class="mat-form-field-subscript-wrapper"
                                    *ngIf="lifeSkills.errors.required">
                                    This field is required
                                  </mat-error>
                                </mat-error>
                              </div>
                            </td>

                            <td  *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                              <div class="custom-radio">
                                <mat-radio-group aria-label="Select an option"
                                  [(ngModel)]="item.exposureTherapy" #interpersonalEffectiveness="ngModel"
                                  name="exposureTherapy{{i}}" required>
                                  <mat-radio-button class="mr-1" [value]="true">Y
                                  </mat-radio-button>
                                  <mat-radio-button [value]="false">N
                                  </mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="frmProgress.submitted && exposureTherapy.invalid">
                                  <mat-error class="mat-form-field-subscript-wrapper"
                                    *ngIf="exposureTherapy.errors.required">
                                    This field is required
                                  </mat-error>
                                </mat-error>
                              </div>
                            </td>
                            <td *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                              <div class="custom-radio">
                                <mat-radio-group aria-label="Select an option"
                                  [(ngModel)]="item.purposefulMovement" #interpersonalEffectiveness="ngModel"
                                  name="purposefulMovement{{i}}" required>
                                  <mat-radio-button class="mr-1" [value]="true">Y
                                  </mat-radio-button>
                                  <mat-radio-button [value]="false">N
                                  </mat-radio-button>
                                </mat-radio-group>
                                <mat-error *ngIf="frmProgress.submitted && purposefulMovement.invalid">
                                  <mat-error class="mat-form-field-subscript-wrapper"
                                    *ngIf="purposefulMovement.errors.required">
                                    This field is required
                                  </mat-error>
                                </mat-error>
                              </div>
                            </td>
                            <td >
                              <div class="letter-total"> {{getrowActivityTotal(item)}}</div>
                            </td>
                            <td  *ngIf="item.trackBenchmarkId!=null" >
                              <div  class="goal-stat-chang " >
                                    <mat-select [(ngModel)]="item.status"
                                    #status="ngModel" name="{{i}}" >
                                      <mat-option [value]= Inprogress (click)="OnstatusChange(item,2)">Inprogress</mat-option>
                                      <mat-option [value]= Completed (click)="OnstatusChange(item,3)">Completed</mat-option>
                                    </mat-select>
                                </div>

                            </td>
                            <!--<td data-title="International Gross" data-type="currency">
                              <div class="letter-total"> {{item.totalAggregate}}</div>
                            </td>-->
                          </tr>
                          <tr  *ngIf="item.trackBenchmarkId!=null">
                            <td colspan="9" > 
                              <div id="collapseOne" class="panel-collapse collapse  in"  [ngClass]="{'show' : item.expand}">
                                <div class="panel-body">
                                 <div class="col-md-12 ben-head">
                                   <div class="row">
                                     
                                     <div class="col-md-10 ben_des text-left">
                                      <div class=" ben_tit">
                                       {{item.benchmarkTitle}}
                                      </div>
                                     {{item.benchmarkDescription}}
                                    </div>
                    
                                    <div class="col-md-2 ben_unit">
                                      <p class="text-muted text-xs">Unit of Measure</p>
                                      {{item.benchmarkunit}}
                                    </div>
                                   </div>
                                 </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                       </tbody>
                       <tbody>
                          <tr class="">
                            <th scope="row">Total</th>
                            <td>
                              <div class="letter-total">{{getColumnTotal('socialSkills')}}</div>
                            </td>
                            <td>
                              <div class="letter-total">{{getColumnTotal('interpersonalEffectiveness')}}</div>
                            </td>
                            <td *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'PCP'">
                              <div class="letter-total">{{getColumnTotal('skillsGeneralization')}}</div>
                            </td>
                            <td>
                              <div class="letter-total">{{getColumnTotal('distressTolerance')}}</div>
                            </td>
                            <td *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                              <div class="letter-total">{{getColumnTotal('lifeSkills')}}</div>
                            </td>

                            <td *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                              <div class="letter-total">{{getColumnTotal('exposureTherapy')}}</div>
                            </td>
                            <td *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                              <div class="letter-total">{{getColumnTotal('purposefulMovement')}}</div>
                            </td>
                            <td>


                            </td>
                          </tr>
                    
                          <tr>
                            <th scope="row">Add Notes for Group Intervention</th>
                            <td>
                              <button class="btn add-notes-btn" *ngIf="!isSocialNotes" (click)="onAddGroupNotes(1)"
                                type="button" title="Add Notes For Social Skill"><i
                                  class="fas fa-plus-circle clear-both float-left w-100"></i>Add
                                Notes</button>
                              <button class="btn notes-added-btn btn-success" type="button" *ngIf="isSocialNotes"
                                (click)="onAddGroupNotes(1)" title="Add Notes For Social Skill"><i
                                  class="fas fa-check-circle clear-both float-left w-100"></i>Notes
                                Added</button>

                            </td>
                            <td>
                              <button class="btn add-notes-btn" type="button" *ngIf="!isInterpersonalNotes"
                                (click)="onAddGroupNotes(4)" title="Add Notes For Interpersonal Effectiveness"><i
                                  class="fas fa-plus-circle clear-both float-left w-100"></i>Add
                                Notes</button>
                              <button class="btn notes-added-btn btn-success" (click)="onAddGroupNotes(4)" type="button"
                                *ngIf="isInterpersonalNotes" title="Add Notes For Interpersonal Effectiveness"><i
                                  class="fas fa-check-circle clear-both float-left w-100"></i>Notes
                                Added</button>
                            </td>
                            <td *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'PCP'">
                              <button class="btn add-notes-btn " *ngIf="!isSkillsNotes" type="button"
                                (click)="onAddGroupNotes(2)" title="Add Notes For Skills Generalization"><i
                                  class="fas fa-plus-circle clear-both float-left w-100"></i>Add
                                Notes</button>
                              <button class="btn notes-added-btn btn-success" type="button" *ngIf="isSkillsNotes"
                                (click)="onAddGroupNotes(2)" title="Add Notes For Skills Generalization"><i
                                  class="fas fa-check-circle clear-both float-left w-100"></i>Notes
                                Added</button>
                            </td>
                            <td>
                              <button class="btn add-notes-btn" *ngIf="!isDistressNotes" type="button"
                                (click)="onAddGroupNotes(3)" title="Add Notes For Distress Tolerance"><i
                                  class="fas fa-plus-circle clear-both float-left w-100"></i>Add
                                Notes</button>
                              <button class="btn notes-added-btn btn-success" type="button" *ngIf="isDistressNotes"
                                (click)="onAddGroupNotes(3)" type="button" title="Add Notes For Distress Tolerance"><i
                                  class="fas fa-check-circle clear-both float-left w-100"></i>Notes
                                Added</button>
                            </td>

                            <td *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                              <button class="btn add-notes-btn" type="button" *ngIf="!isLifeSkills"
                                (click)="onAddGroupNotes(6)"  title="Add Notes For Life Skills"><i
                                  class="fas fa-plus-circle clear-both float-left w-100"></i>Add
                                Notes</button>
                              <button class="btn notes-added-btn btn-success" (click)="onAddGroupNotes(6)"  type="button"
                                *ngIf="isLifeSkills" title="Add Notes For Life Skills"><i
                                  class="fas fa-check-circle clear-both float-left w-100"></i>Notes
                                Added</button>
                            </td>
                            <td *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                              <button class="btn add-notes-btn" type="button" *ngIf="!isExposureTherapy"
                                (click)="onAddGroupNotes(7)"  title="Add Notes For Exposure Therapy"><i
                                  class="fas fa-plus-circle clear-both float-left w-100"></i>Add
                                Notes</button>
                              <button class="btn notes-added-btn btn-success" (click)="onAddGroupNotes(7)" type="button"
                                *ngIf="isExposureTherapy"  title="Add Notes For Exposure Therapy"><i
                                  class="fas fa-check-circle clear-both float-left w-100"></i>Notes
                                Added</button>
                            </td>
                            <td *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                              <button class="btn add-notes-btn" type="button" *ngIf="!isPurposefulMovement"
                                 (click)="onAddGroupNotes(8)" title="Add Notes For Purposeful Movement"><i
                                  class="fas fa-plus-circle clear-both float-left w-100"></i>Add
                                Notes</button>
                              <button class="btn notes-added-btn btn-success" (click)="onAddGroupNotes(8)"  type="button"
                                *ngIf="isPurposefulMovement" title="Add Notes For Purposeful Movement"><i
                                  class="fas fa-check-circle clear-both float-left w-100"></i>Notes
                                Added</button>
                            </td>
                            <td>
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                    </form>
                  </div>

                  <div class="col-md-12  notes-added mb-2" *ngIf="isShowgroupnote">
                    <h5>Group Intervention for {{notesType == 1 ? 'Social Skills' : notesType == 2 ? 'Skills
                      Generalization' : notesType == 3 ? 'Distress Tolerance' :notesType == 4? 'Interpersonal Effectiveness': notesType == 6 ?'Life Skills':
                      notesType == 7? 'Exposure Therapy':'Purposeful Movement' }}</h5>
                    <div class="row">
                      <div class="col-md-4">
                        <!-- <textarea class="form-control" maxlength="200" [(ngModel)]="clinicalNotes.clinicalTopics"
                          #clinicalTopics="ngModel" name="clinicalTopics"
                          placeholder="Skills Taught/Clinical Topics Discussed "></textarea> -->
                        <quill-editor [style]="{height: '200px'}" name="clinicalTopics"
                          [(ngModel)]="clinicalNotes.clinicalTopics" [maxLength]="200" #clinicalTopics="ngModel"
                          [modules]="quillConfig" (onContentChanged)="textChanged($event)"></quill-editor>
                      </div>
                      <div class="col-md-4">
                        <!-- <textarea class="form-control" maxlength="200" [(ngModel)]="clinicalNotes.clientsResponse"
                          #clientsResponse="ngModel" name="clientsResponse"
                          placeholder="Client’s Response to Treatment Intervention "></textarea> -->
                        <quill-editor [style]="{height: '200px'}" name="clientsResponse"
                          [(ngModel)]="clinicalNotes.clientsResponse" [maxLength]="200" #clientsResponse="ngModel"
                          [modules]="quillConfig" (onContentChanged)="textChanged($event)"></quill-editor>
                      </div>
                      <div class="col-md-4">
                        <!-- <textarea class="form-control" [(ngModel)]="clinicalNotes.followUpPlan" #followUpPlan="ngModel"
                          name="followUpPlan" maxlength="200" placeholder="Follow-Up Plan "></textarea> -->
                        <quill-editor [style]="{height: '200px'}" name="followUpPlan"
                          [(ngModel)]="clinicalNotes.followUpPlan" [maxLength]="200" #followUpPlan="ngModel"
                          [modules]="quillConfig" (onContentChanged)="textChanged($event)"></quill-editor>
                      </div>
                      <div class="col-md-12 text-right" *ngIf="!isPatientLogin">
                        <button type="button" (click)="onSaveGroupNote()" *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                          class="btn primary-btn-outlined-small float-right mt-2">Save
                          Notes</button>
                      </div>
                    </div>

                  </div>

                </div>
              
              </div>

              <div class="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" *ngIf="showClinicalNotes" >
                <div class="col-md-12 notes-clinic mt-2 p-2">
                  <div class="col-md-12 p-0 mb-2">

                    <label>Chief Complaints/Presenting Problems</label>
                    <!-- <textarea class="form-control" [(ngModel)]="clinicalNotes.clinicalTopics" #clinicalTopics="ngModel"
                      name="clinicalTopics" maxlength="200"
                      placeholder="anxiety, depression, non-compliance, school refusal"></textarea> -->
                    <quill-editor [style]="{height: '200px'}" name="clinicalTopics"
                      [(ngModel)]="clinicalNotes.clinicalTopics" [maxLength]="200" #clinicalTopics="ngModel"
                      [modules]="quillConfig" (onContentChanged)="textChanged($event)"></quill-editor>
                  </div>
                  <div class="col-md-12 p-0 mb-2">

                    <label>Clinical Notes</label>
                    <!-- <textarea class="form-control" [(ngModel)]="clinicalNotes.clientsResponse"
                      #clientsResponse="ngModel" name="clientsResponse" maxlength="200"
                      placeholder="Comments, response to goals, observed behaviors staff response"></textarea> -->
                    <quill-editor [style]="{height: '200px'}" name="clientsResponse"
                      [(ngModel)]="clinicalNotes.clientsResponse" [maxLength]="200" #clientsResponse="ngModel"
                      [modules]="quillConfig" (onContentChanged)="textChanged($event)"></quill-editor>
                  </div>
                  <div class="col-md-12 p-0 mb-2">

                    <label>Significant/Critical Events/Updates/Meetings</label>
                    <!-- <textarea class="form-control" [(ngModel)]="clinicalNotes.followUpPlan" maxlength="200"
                      #followUpPlan="ngModel" name="followUpPlan"></textarea> -->
                    <quill-editor [style]="{height: '200px'}" name="followUpPlan"
                      [(ngModel)]="clinicalNotes.followUpPlan" [maxLength]="200" #followUpPlan="ngModel"
                      [modules]="quillConfig" (onContentChanged)="textChanged($event)"></quill-editor>
                  </div>


                  <div class="col-md-12">
                    <div class="form-group clearfix m-0">
                      <div class="float-left col-md-4 p-0 mt-3 pt-1">
                        <label class="control-label-new font-weight-bold">Severity Level:</label>
                        <mat-select #severity="ngModel" name="severity" [(ngModel)]="clinicalNotes.severity" [ngStyle]="{'color':bg}" (selectionChange)="onSelectionChanged($event.value)" placeholder="Select Severity">
                          <!-- <mat-option [value]="0"> 0 - Discharged </mat-option>
                          <mat-option [value]="1"> 1 - Mild </mat-option>
                          <mat-option [value]="2"> 2 - Medium  </mat-option>
                          <mat-option [value]="3"> 3 - Critical </mat-option> -->
                          <mat-option *ngFor="let item of ddlseverity" [ngStyle]="{'color': item.colocode}"  [value]="item.severityValue">{{item.severityLable}}</mat-option>
                        </mat-select>
                      </div>
                    </div>
                  </div>


                </div>
                <div class="col-md-12 text-right" >
                  <button type="button" (click)="onSaveGroupNote()" 
                    class="btn primary-btn-outlined-small float-right mt-2 mb-2" *ngIf="!isPatientLogin && (roleAccess.isCreate||roleAccess.isEdit)">Save
                    Notes</button>
                </div>
              </div>
              <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                <div class="col-md-12 p-0">
                  <div class="d-flex "> <label class="name-label">Medication History For
                      <span>{{grpTherapydetails?.userName}}</span></label>
                    <div class="ml-auto d-flex">

                      <button type="submit" *ngIf="_dtoMedication?.length > 0 && !isPatientLogin && (roleAccess.isCreate||roleAccess.isEdit)" form="frmMedication"
                        class="btn primary-btn-filled-small float-right mt-2 ml-2" ><i
                          class="far fa-save mr-2"></i>{{editmedication ? 'Update':'Save'}}</button>
                      <button *ngIf="_dtoMedication?.length > 0" (click)="_dtoMedication=[]"
                        class="btn primary-btn-outlined-small mt-2 ml-2">Cancel</button>
                      <button type="button" *ngIf="lstmedicine?.length > 0 && _dtoMedication?.length == 0 && !isPatientLogin && (roleAccess.isCreate||roleAccess.isEdit)"
                        (click)="addMedicationInfo()" class="btn primary-btn-outlined-small float-right mt-2 "><i
                          class="fas fa-plus-circle mr-2"></i>Add Medication</button>
                    </div>
                  </div>
                  <div class="medic-his-list" *ngIf=" _dtoMedication?.length == 0">

                    <ul>
                      <li class="mb-2 cursor-pointer" (click)="onOpenHistroy()">
                        <div class="card p-2 pb-1">
                          <div class="col-md-12">

                            <div class="col-md-12 medic-his-list ">
                              <div class="row">
                                <div class="col-md-3 p-0">
                                  <div class="mt-2">

                                    <div class="date-medic">Previous Medication History</div>
                                  </div>
                                </div>
                                <div class="col-md-5">

                                </div>

                                <div class="col-md-4 pr-0">
                                  <div class="d-flex w-100 mt-2 justify-content-end">
                                    <a class=""><i class="fas "
                                        [ngClass]="{'fa-chevron-up':isshowhistroy,'fa-chevron-down':!isshowhistroy}"></i></a>


                                  </div>

                                </div>
                              </div>
                            </div>

                          </div>
                          <ng-container *ngIf="isshowhistroy">
                            <div class="panel-list " *ngIf="medicationhistroy?.length > 0">
                              <div class="col-md-12 mt-2">
                                <div class="row">




                                  <div class="col-md-3">
                                    <div class="w-100">
                                      <label>Medication</label>

                                    </div>

                                  </div>
                                  <div class="col-md-2">
                                    <div class="">
                                      <label>Dosage</label>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="">
                                      <label>Frequency</label>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="">
                                      <label>Side Effect</label>
                                    </div>
                                  </div>


                                </div>

                              </div>
                              <div class="col-md-12 mt-2 mb-2" *ngFor="let item of medicationhistroy">
                                <div class="row">
                                  <div class="col-md-3">
                                    <div class="w-100">
                                      <span>{{item.medications}}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <div class="">
                                      <span>{{item.dosage}}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="">
                                      <span>{{item.frequency == 1 ? 'Daily' :item.frequency == 2 ? 'Weekly' : 'Monthly'
                                        }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="">
                                      {{item.sideEffects}}
                                    </div>
                                  </div>


                                </div>

                              </div>


                            </div>
                            <div class="panel-list " *ngIf="medicationhistroy?.length == 0">
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <span class="text-muted mx-auto">Previous medication history not available</span>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </li>
                      <li class="mb-2" *ngFor="let item of lstmedicine;">
                        <div class="card p-2 pb-1">
                          <div class="col-md-12">

                            <div class="col-md-12 medic-his-list ">
                              <div class="row">
                                <div class="col-md-3 p-0">
                                  <div class="mt-2">

                                    <div class="date-medic">Clinician Name: <span>{{item.clinicianName}}</span> </div>
                                  </div>
                                </div>
                                <div class="col-md-5">
                                </div>
                                <div class="col-md-4 pr-0">
                                  <div class="d-flex w-100 mt-2">

                                    <div class="date-medic ml-auto mt-1">Medication Date: <span>{{item.updatedDate |
                                        date:'MM/dd/yyy'}}</span> </div>
                                    <a class="icon-note cursor-pointer" (click)="onEditMedication(item)"
                                      title="Edit"><span class="mr-2"><i class="far fa-edit"></i></span></a>
                                  </div>

                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="panel-list ">
                            <div class="col-md-12 mt-2">
                              <div class="row">
                                <div class="col-md-3">
                                  <div class="w-100">
                                    <label>Medication</label>

                                  </div>

                                </div>
                                <div class="col-md-2">
                                  <div class="">
                                    <label>Dosage</label>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="">
                                    <label>Frequency</label>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="">
                                    <label>How Often?</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ng-container *ngIf="!item.toggle">
                              <div class="col-md-12 mt-2 mb-2"
                                *ngFor="let data of item.dtoPatientMedication | slice:0:2; ">
                                <div class="row">
                                  <div class="col-md-3">
                                    <div class="w-100">
                                      <span>{{data.medications}}</span>

                                    </div>

                                  </div>
                                  <div class="col-md-2">
                                    <div class="">
                                      <span>{{data.dosage}}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="">
                                      <span>{{data.frequency == 1 ? 'Daily' :data.frequency == 2 ? 'Weekly' : 'Monthly'
                                        }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="">
                                      <span class="morn-time" *ngIf="data.isMorning"><i
                                          class="fas fa-cloud-sun mr-1"></i>Morning</span>
                                      <span class="morn-time" *ngIf="data.isNoon"><i
                                          class="fas fa-sun mr-1"></i>Noon</span>
                                      <span class="morn-time" *ngIf="data.isEvening"><i
                                          class="far fa-moon mr-1"></i>Evening</span>
                                      <span class="morn-time" *ngIf="data.isBedTime"><i class="fas fa-bed mr-1"></i>Bed
                                        Time</span>
                                      <span class="pl-2"
                                        *ngIf="!data.isMorning && !data.isNoon && !data.isEvening && !data.isBedTime">-</span>
                                    </div>
                                  </div>


                                </div>

                              </div>
                            </ng-container>
                            <ng-container *ngIf="item.toggle">
                              <div class="col-md-12 mt-2 mb-2" *ngFor="let data of item.dtoPatientMedication">
                                <div class="row">
                                  <div class="col-md-3">
                                    <div class="w-100">
                                      <span>{{data.medications}}</span>

                                    </div>

                                  </div>
                                  <div class="col-md-2">
                                    <div class="">
                                      <span>{{data.dosage}}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="">
                                      <span>{{data.frequency == 1 ? 'Daily' :data.frequency == 2 ? 'Weekly' : 'Monthly'
                                        }}</span>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div class="">
                                      <span class="morn-time" *ngIf="data.isMorning"><i
                                          class="fas fa-cloud-sun mr-1"></i>Morning</span>
                                      <span class="morn-time" *ngIf="data.isNoon"><i
                                          class="fas fa-sun mr-1"></i>Noon</span>
                                      <span class="morn-time" *ngIf="data.isEvening"><i
                                          class="far fa-moon mr-1"></i>Evening</span>
                                      <span class="morn-time" *ngIf="data.isBedTime"><i class="fas fa-bed mr-1"></i>Bed
                                        Time</span>
                                      <span class="pl-2"
                                        *ngIf="!data.isMorning && !data.isNoon && !data.isEvening && !data.isBedTime">-</span>
                                    </div>
                                  </div>


                                </div>

                              </div>
                            </ng-container>
                            <div class="expndable text-right  ">
                              <a class="exp-btn cursor-pointer" (click)="item.toggle=!item.toggle"><span
                                  class="dots">...</span> </a>
                            </div>

                          </div>
                        </div>
                      </li>


                    </ul>
                  </div>
                  <div class="first-entry-medication text-center mt-5 "
                    *ngIf="lstmedicine?.length == 0 && _dtoMedication?.length == 0">
                    <label class="additional mt-3 pl-3">Medication history not available,
                      would you like to <a class="text-underscore cursor-pointer" (click)="addMedicationInfo()">add</a>
                      Medication?
                      <p><i class="fas fa-notes-medical"></i></p>

                    </label>
                  </div>
                  <form (ngSubmit)="frmMedication.form.valid  && postProgressMedication()" id="frmMedication"
                    autocomplete="off" #frmMedication="ngForm" novalidate>
                    <div class="add-medic" *ngFor="let med of _dtoMedication;let i= index;let last=last">

                      <div class="col-md-12 medication p-0">
                        <div class="row">
                          <div class="col-md-2">
                            <label>Medication</label>
                            <input class="form-control" maxlength="50" [(ngModel)]="med.medications"
                              #medications="ngModel" name="medications{{i}}" placeholder="Medicine" type="text"
                              required>
                            <mat-error *ngIf="frmMedication.submitted && medications.invalid">
                              <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                *ngIf="medications.errors.required">Medicine
                                is
                                required</mat-error>
                            </mat-error>
                          </div>
                          <div class="col-md-2">
                            <label>Dosage</label>
                            <input class="form-control" maxlength="30" [(ngModel)]="med.dosage" #dosage="ngModel"
                              name="dosage{{i}}" placeholder="Dosage" type="text" required>
                            <mat-error *ngIf="frmMedication.submitted && dosage.invalid">
                              <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="dosage.errors.required">
                                Dosage is
                                required</mat-error>
                            </mat-error>
                          </div>
                          <div class="col-md-2">
                            <label>Frequency</label>
                            <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                              [(ngModel)]="med.frequency" #frequency="ngModel" name="frequency{{i}}" required>
                              <option value="null">--Select Frequency--</option>
                              <option value="1">Daily</option>
                              <option value="2">Weekly</option>
                              <option value="3">Monthly</option>
                            </select>
                            <mat-error *ngIf="frmMedication.submitted && frequency.invalid">
                              <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                *ngIf="frequency.errors.required">Frequency
                                is
                                required</mat-error>
                            </mat-error>
                          </div>
                          <div class="col-md-5 text-center">

                            <div class="row">
                              <div class="d-flex w-100">
                                <label>How Often?</label>
                                <div class="ml-auto b4-meal">
                                  <div class="custom-radio">

                                    <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isBeforemeal"
                                      #isBeforemeal="ngModel" name="isBeforemeal{{i}}" required>
                                      <mat-radio-button class="mr-1" [value]="true">Before Meals
                                      </mat-radio-button>
                                      <mat-radio-button [value]="false">After Meals
                                      </mat-radio-button>
                                    </mat-radio-group>
                                    <mat-error *ngIf="frmMedication.submitted && isBeforemeal.invalid">
                                      <mat-error class="mat-form-field-subscript-wrapper"
                                        *ngIf="isBeforemeal.errors.required">
                                        This field is required
                                      </mat-error>
                                    </mat-error>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-3 p-0">
                                <label class="sub-small-label">Morning</label>
                                <div class="custom-radio">
                                  <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isMorning"
                                    #isMorning="ngModel" name="isMorning{{i}}">
                                    <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                                    <mat-radio-button [value]="false">N</mat-radio-button>
                                  </mat-radio-group>

                                </div>

                              </div>
                              <div class="col-md-3 p-0">
                                <label class="sub-small-label">Noon</label>
                                <div class="custom-radio">
                                  <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isNoon"
                                    #isNoon="ngModel" name="isNoon{{i}}">
                                    <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                                    <mat-radio-button [value]="false">N</mat-radio-button>
                                  </mat-radio-group>
                                </div>

                              </div>
                              <div class="col-md-3 p-0">
                                <label class="sub-small-label">Evening</label>
                                <div class="custom-radio">
                                  <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isEvening"
                                    #isEvening="ngModel" name="isEvening{{i}}">
                                    <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                                    <mat-radio-button [value]="false">N</mat-radio-button>
                                  </mat-radio-group>
                                </div>

                              </div>
                              <div class="col-md-3 p-0">
                                <label class="sub-small-label">Bed Time</label>
                                <div class="custom-radio">
                                  <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isBedTime"
                                    #isBedTime="ngModel" name="isBedTime{{i}}">
                                    <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                                    <mat-radio-button [value]="false">N</mat-radio-button>
                                  </mat-radio-group>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div class="col-md-1 mt-4">
                            <a class="add " (click)="addMedicationInfo()" style="cursor: pointer;"><i
                                class="fa fa-lg fa-plus-circle mr-2"></i><span class="sr-only">Add</span></a>
                            <a class="add " style="cursor: pointer;" (click)="removeclinicalinformation(med,i)"><i
                                class="fa fa-lg fa-minus-circle"></i><span class="sr-only">Remove</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="preview-fivepoint">
  <div class="modal" tabindex="-1" role="dialog" [style.display]="isPreviewTherapy ? 'block' : 'none'">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Preview</h6>
          <button type="button" class="close" (click)="isPreviewTherapy =!isPreviewTherapy" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="d-flex col-md-12">
              <button class="ml-auto btn-print-hover mb-3 mr-2" mat-type="button" (click)="print()"><i class="fa fa-print"></i></button>
            </div>
            
          </div> 
          <div id="print-section">
            <div class="page-header" style=" text-align: center; font-size: 15px; font-weight: 500; margin-bottom: 25px; color: #173a64;">Five Point Rating</div>

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4 d-flex">
                <i class="fas fa-hospital-user mr-2 mt-1 text-muted font-16"></i>
                <div>
                  <div class="stud-grp-name ">{{_dtoGrpTherapyMeta?.groupTherapyName }}<span>-
                      {{grpTherapydetails?.themeName}}</span></div>
                  <div class="dob-detail  ">DOB: <span>{{grpTherapydetails?.dob | date:'MM/dd/YYYY'}}</span></div>
                </div>

              </div>
              <div class="col-md-8">
                <div class="schedule-group">
                  <div class="d-flex w-100 font-14 justify-content-end">

                    <div class="mr-4">Group Therapy Name
                      <span class="imp-info">{{_dtoGrpTherapyMeta?.groupTherapyName }}</span>
                    </div>
                    <div class="mr-4 ">
                      Start Date
                      <span class="imp-info">{{_dtoGrpTherapyMeta?.startTime | date:'MM/dd/YYYY'}}</span>
                    </div>
                    <div class="mr-4 ">
                      Start Time
                      <span class="imp-info">{{_dtoGrpTherapyMeta?.startTime | date:'hh:mm a'}}</span>
                    </div>
                    <div class="mr-4 ">
                      Duration
                      <span class="imp-info">{{global.getMinutesSlot(_dtoGrpTherapyMeta?.duration)}} </span>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-4">
            <table class="responsive-table">

              <thead>
                <tr>
                  <th scope="col">Activity </th>
                  <th scope="col" style="width:111px;" >Social Skills</th>
                  <th scope="col" style="width:111px;">Interpersonal Effectiveness</th>
                  <th scope="col" style="width:160px;" *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'PCP'">Skills Generalization</th>
                  <th scope="col" style="width:142px;" >Distress Tolerance</th>
                  <th scope="col" style="width:111px;"  *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">Life Skills</th>
                  <th scope="col" style="width:136px;"  *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">Exposure Therapy</th>
                  <th scope="col" style="width:120px;"  *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">Purposeful Movement</th>
                  <th scope="col" style="width:60px;"> Total </th>
                  <th scope="col" style="width:100px;"> Status </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of _dtoProgressActivity;let i =index">
                  <th scope="row">{{item.activityName}}</th>
                  <td>
                    <div class="yes-no-5point">{{item.socialSkills == 1? 'Yes':'No'}}</div>
                  </td>
                  <td>
                    <div class="yes-no-5point">{{item.interpersonalEffectiveness == 1? 'Yes':'No'}}</div>
                  </td>
                  <td  *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'PCP'">
                    <div class="yes-no-5point" >{{item.skillsGeneralization == 1? 'Yes':'No'}}</div>
                  </td>
                  <td>
                    <div class="yes-no-5point">{{item.distressTolerance == 1? 'Yes':'No'}}</div>
                  </td>
                  <td data-type="currency" *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                    <div class="yes-no-5point"  >{{item.lifeSkills == 1? 'Yes':'No'}}</div>
                  </td>
                  <td data-type="currency" *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                    <div class="yes-no-5point"  >{{item.exposureTherapy == 1? 'Yes':'No'}}</div>
                  </td>
                  <td data-type="currency" *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">
                    <div class="yes-no-5point"  >{{item.purposefulMovement == 1? 'Yes':'No'}}</div>
                  </td>
                   <td data-title="International Gross" data-type="currency">
                    <div class="letter-total"> {{getrowActivityTotal(item)}}</div>
                  </td> 
                  <td  data-type="currency">
                    <div class="yes-no-5point"> {{item.status==2? 'Inprogress':item.status==3?'Completed':''}} </div>
                  </td> 
                </tr>

                <tr class="">
                  <th scope="row">Total</th>
                  <td>
                    <div class="letter-total">{{getColumnTotal('socialSkills')}}</div>
                  </td>
                  <td>
                    <div class="letter-total">{{getColumnTotal('interpersonalEffectiveness')}}</div>
                  </td>
                  <td>
                    <div class="letter-total" *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'PCP'"> {{getColumnTotal('skillsGeneralization')}}</div>
                  </td>
                  <td>
                    <div class="letter-total">{{getColumnTotal('distressTolerance')}}</div>
                  </td>
                  <td>
                    <div class="letter-total" *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">{{getColumnTotal('lifeSkills')}}</div>
                  </td>
                  <td>
                    <div class="letter-total" *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">{{getColumnTotal('exposureTherapy')}}</div>
                  </td>
                  <td>
                    <div class="letter-total" *ngIf="_dtofilteredGrpTherapy[0].groupTherapyTheme == 'IOP'">{{getColumnTotal('purposefulMovement')}}</div>
                  </td>

                  <td>
                    <!--
                    {{getColumnTotal('socialSkills') +
                    getColumnTotal('skillsGeneralization')+getColumnTotal('distressTolerance')+getColumnTotal('interpersonalEffectiveness')
                    +getofTotal() }} -->
                  </td>
                </tr>
                <!-- <tr>
                  <th scope="row">Staff Initials </th>
                  <td colspan="5">
                    <div class="w-50">

                       <img id='esign_12' height="50px" width="100px"  ></div>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <div class="col-md-12" *ngIf="_dtoProgressNotes?.length>0">
            <div class="six">
              <h1>Group Notes
              </h1>
            </div>
            <div *ngFor="let item of _dtoProgressNotes">
              <div class="mb-2 pb-3 border-bottom" *ngIf="item.notesType == 1">
                <div class="grp-sub-heading">
                  Group Intervention for Social Skills
                </div>
                <div class="row">
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Client’s Response to Treatment Intervention</div>
                      <p innerHTML="{{global.getContent(item.clinicalTopics)}}"></p>
                    </div>
                  </div>
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Skills Taught/Clinical Topics Discussed</div>
                      <p innerHTML="{{global.getContent(item.clientsResponse)}}"></p>
                    </div>

                  </div>

                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Follow-Up Plan</div>
                      <p innerHTML="{{global.getContent(item.followUpPlan)}}"></p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-2 pb-3 border-bottom" *ngIf="item.notesType == 2">
                <div class="grp-sub-heading">
                  Group Intervention for Skills Generalization
                </div>
                <div class="row">
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Client’s Response to Treatment Intervention</div>
                      <p innerHTML="{{global.getContent(item.clinicalTopics)}}"></p>
                    </div>
                  </div>
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Skills Taught/Clinical Topics Discussed</div>
                      <p innerHTML="{{global.getContent(item.clientsResponse)}}"></p>
                    </div>

                  </div>

                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Follow-Up Plan</div>
                      <p innerHTML="{{global.getContent(item.followUpPlan)}}"></p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-2 pb-3 border-bottom" *ngIf="item.notesType == 3">
                <div class="grp-sub-heading">
                  Group Intervention for Distress Tolerance
                </div>
                <div class="row">
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Client’s Response to Treatment Intervention</div>
                      <p innerHTML="{{global.getContent(item.clinicalTopics)}}"></p>
                    </div>
                  </div>
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Skills Taught/Clinical Topics Discussed</div>
                      <p innerHTML="{{global.getContent(item.clientsResponse)}}"></p>
                    </div>

                  </div>

                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Follow-Up Plan</div>
                      <p innerHTML="{{global.getContent(item.followUpPlan)}}"></p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-2 pb-3 border-bottom" *ngIf="item.notesType == 4">
                <div class="grp-sub-heading">
                  Group Intervention for Interpersonal Effectiveness
                </div>
                <div class="row">
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Client’s Response to Treatment Intervention</div>
                      <p innerHTML="{{global.getContent(item.clinicalTopics)}}"></p>
                    </div>
                  </div>
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Skills Taught/Clinical Topics Discussed</div>
                      <p innerHTML="{{global.getContent(item.clientsResponse)}}"></p>
                    </div>

                  </div>

                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Follow-Up Plan</div>
                      <p innerHTML="{{global.getContent(item.followUpPlan)}}"></p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-2 pb-3 border-bottom" *ngIf="item.notesType == 6">
                <div class="grp-sub-heading">
                  Group Intervention for Life Skills
                </div>
                <div class="row">
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Client’s Response to Treatment Intervention</div>
                      <p innerHTML="{{global.getContent(item.clinicalTopics)}}"></p>
                    </div>
                  </div>
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Skills Taught/Clinical Topics Discussed</div>
                      <p innerHTML="{{global.getContent(item.clientsResponse)}}"></p>
                    </div>

                  </div>

                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Follow-Up Plan</div>
                      <p innerHTML="{{global.getContent(item.followUpPlan)}}"></p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-2 pb-3 border-bottom" *ngIf="item.notesType == 7">
                <div class="grp-sub-heading">
                  Group Intervention for Exposure Therapy
                </div>
                <div class="row">
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Client’s Response to Treatment Intervention</div>
                      <p innerHTML="{{global.getContent(item.clinicalTopics)}}"></p>
                    </div>
                  </div>
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Skills Taught/Clinical Topics Discussed</div>
                      <p innerHTML="{{global.getContent(item.clientsResponse)}}"></p>
                    </div>

                  </div>

                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Follow-Up Plan</div>
                      <p innerHTML="{{global.getContent(item.followUpPlan)}}"></p>

                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-2 pb-3 border-bottom" *ngIf="item.notesType == 8">
                <div class="grp-sub-heading">
                  Group Intervention for Purposeful Movement
                </div>
                <div class="row">
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Client’s Response to Treatment Intervention</div>
                      <p innerHTML="{{global.getContent(item.clinicalTopics)}}"></p>
                    </div>
                  </div>
                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Skills Taught/Clinical Topics Discussed</div>
                      <p innerHTML="{{global.getContent(item.clientsResponse)}}"></p>
                    </div>

                  </div>

                  <div class="col-md-12 pr-0">
                    <div class="notes-container-grp">
                      <div class="grp-sub-heading-sub">Follow-Up Plan</div>
                      <p innerHTML="{{global.getContent(item.followUpPlan)}}"></p>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="col-md-12" *ngIf="isGroupNote ">
            <div class="six">
              <h1>Clinical Notes

              </h1>
            </div>
            <div *ngFor="let item of _dtoProgressNotes">
              <div *ngIf="item.notesType == 5">
                <div class="clinicl-view">
                  <label>Chief Complaints/Presenting Problems</label>
                  <p innerHTML="{{global.getContent(item.clinicalTopics)}}"></p>

                </div>
                <div class="clinicl-view">

                  <label>Clinical Notes</label>
                  <p innerHTML="{{global.getContent(item.clientsResponse)}}"></p>
                </div>

                <div class="clinicl-view">
                  <label>Significant/Critical Events/Updates/Meetings</label>
                  <p innerHTML="{{global.getContent(item.followUpPlan)}}"></p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-cancel" (click)="isPreviewTherapy =!isPreviewTherapy"
            data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Section -->
<!--<div class="comment-popup">
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display': (showModal==true) ? 'block' : 'none'}" style="display: block;">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Add Comments</h6>
          <button type="button" class="close"  data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
<div class="individual-threaphy-container">
  <div  class="comments-pop col-md-12 p-0">
 <textarea cols="3" placeholder="Enter your Comments" [(ngModel)]='textArea' class="form-control"></textarea>
  </div>

</div>
</div>
<div class="modal-footer">
  <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" (click)="postComments()"  *ngIf="!isPatientLogin">Save</button>
  <button type="button" class="btn btn-cancel"
    data-dismiss="modal" (click)="closeModal(showModal)" >Close</button>
</div>
</div>
</div>
</div>
</div>
</div>-->


 <div class="comment-popup benchmark-popup-rating">
  <div class="modal" tabindex="-1" role="dialog"  [style.display]="PopUp? 'block':'none'" >
    <div class="modal-dialog mat-dialog-container p-3" role="document">
      <div class="modal-content border-0">
        <div class="modal-body">
<div >
  <p  class="text-center font-weight-500">Do you want to update with next benchmark?</p>
 <!-- <p class="disabled"> <i class="fas fa-check-circle text-success mr-2"></i> {{PopUpdata.benchmarkTitle}}</p>-->
  <mat-radio-group aria-label="Select an option" [(ngModel)]="SelectedBenchmark" >
    <mat-radio-button class="w-100" [value]=1>{{PopUpdata.benchmarkTitle}}  </mat-radio-button>

    <mat-radio-button *ngIf="PopUpdata.nextBenchmark!=''" class="w-100" [value]=2>{{PopUpdata.nextBenchmark}}  </mat-radio-button>
  </mat-radio-group>
</div>

<div class="text-center">
  <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" (click)="OnBenchmarkselect()" *ngIf="roleAccess.isCreate||roleAccess.isEdit">Save</button>
  <button type="button" class="btn btn-cancel" (click)="Onclosepopup()"
    data-dismiss="modal"  >Close</button>
</div>
</div>
</div>
</div>
</div>
</div>


import { Component, OnInit,ViewChild } from '@angular/core';
import { LeadService } from 'src/app/patient-lead/lead.service';
import { CommonService } from 'src/app/common/common.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { MatDialog } from '@angular/material/dialog';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { SettingsService } from '../settings.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DtoAuditSettings } from 'src/app/_models/_Common/AuditSettings';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
@Component({
  selector: 'app-audit-settings-master',
  templateUrl: './audit-settings-master.component.html',
  styleUrls: ['./audit-settings-master.component.scss']
})
export class AuditSettingsMasterComponent implements OnInit {

  @ViewChild('AuditSettings') AuditSettings: NgForm;
  isEditAuthSettings: boolean;
  dtoAuditmaster : DtoAuditSettings;
  dateList : any;
  auditDateMasterId : number;
  constructor(public leadservice:LeadService,public commonService: CommonService,private snackbar: SnackBarService,private spinservice:NgxSpinnerService,
    private dialog: MatDialog, public roleaccessservice:PageAccessService
    ,private settingsService :SettingsService,public spinnerservice: NgxSpinnerService) { }

  ngOnInit(): void {
    this.dtoAuditmaster = new DtoAuditSettings();
    this.dateList = constantVariables.AuditDate;
    this.getAuditettingsMaster();
  }
  getAuditettingsMaster(){
    this.spinnerservice.show();
    this.settingsService.getAuditMaster().subscribe(
      res => {
        this.spinnerservice.hide();
        if (res) {
          this.dtoAuditmaster = res;
          if(this.dtoAuditmaster.auditDateMasterId==0){
            this.isEditAuthSettings = false;
          }
          else{
            this.isEditAuthSettings = true;
          }
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      })
  }
  postAuditsettings(){
    if (!this.AuditSettings.valid) {
      return
    }
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoAuditmaster, false);
    this.settingsService.postAuditsettings(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(res);     
        this.getAuditettingsMaster();          
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  resetForm()
  {
    this.auditDateMasterId = this.dtoAuditmaster?.auditDateMasterId;
    this.dtoAuditmaster = new DtoAuditSettings();
    this.AuditSettings.resetForm();
    this.dtoAuditmaster.auditDateMasterId =   this.auditDateMasterId;
  }

}

<div class="patient-dashboard new-align-dasboard">
    <div class="top-btn-section clearfix">
        <h2 class="head-section mb-2 mt-0">My Therapy Center - <span class="sub-head-section">Your one stop view of your
                entire treatment episode</span> </h2>
    </div>
    <div class="lead-box pt-2" *ngIf="!IsIntakeWelcomscreen && roleAccess.isView">
        <div class="d-flex">
        <div class=" p-2 mb-2 d-flex">
            <img  alt="image" width="" src="../../../assets/images/avatar2.jpg" class="rounded-circle" style="width: 40px;height: 40px;margin-right: 11px;">
            <div class="d-flex text-xs">
                <div  class="ml-1 mr-3">MTP Start Date <span  class="imp-info text-xs">{{schdStartDate | date:'MM/dd/yyyy'}}</span>
                </div>
            <div  class="ml-auto mr-2">MTP End Date <span  class="imp-info">{{schdSEndtDate |date:'MM/dd/yyyy'}} </span>
            </div>

        </div>

        </div>
        <div class="ml-auto d-flex">
            <div class="flex-1 mr-2 mt-1 treat-summ-btn" *ngIf="PatientTreatmentSummaryDetails?.length!=0">
                <div  class="input-group mb-3 custom-btn-go">
                    <span >Treatment Summary</span>
                    <select  class="form-control" [(ngModel)]="DischargesummaryID" name="DischargesummaryID">
                        <option [value]="0"> Select Diagnosis Code</option>
                        <option *ngFor="let item of PatientTreatmentSummaryDetails" [value]="item.dischargeSummaryId">{{item.diagnosisCode}}</option>
                    </select>
                    <div  class="input-group-append">
                        <button  type="button" id="Button3" class="btn" (click)="OnTreatmentPlan()">
                            <i  class="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!-- <button mat-stroked-button class="btn primary-btn-outlined-small h-40 mr-2 "><i class="fas fa-hand-holding-medical mr-1"></i>Treatment Summary</button> -->
            <button mat-stroked-button class="btn primary-btn-outlined-small h-40 "  *ngIf="roleId == 1" (click)="onPatientprofile()"><i class="fas fa-user-edit mr-1 align-top"></i>Edit Patient's Profile</button>
        </div>
        </div>
        <div class="col-md-12 appointment-list-upcome">
            <div class="row">
                   <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 p-0 appt-list">
                    <div class="row">
                    <div class="col-md-12">
                    <div class="card">
                        <div class="body p-2">
                            <div class="tab-card-header">
                                <ul id="myTab" role="tablist" class="nav nav-tabs card-header-tabs">
                                    <li class="nav-item">
                                        <a  id="one-tab" data-toggle="tab"  role="tab"  (click)="onTabChange(1)" class="nav-link cursor-pointer"  [ngClass]="{'active show': selectedTabIndex == 1}" >Upcoming Appointments</a>
                                    </li>
                                    <li  class="nav-item">
                                        <a  id="two-tab" data-toggle="tab"  role="tab"  (click)="onTabChange(2)" class="nav-link cursor-pointer active" [ngClass]="{'active show': selectedTabIndex == 2}" >Past Appointments</a>
                                    </li>
                                    <li  class="nav-item">
                                        <a  id="three-tab" data-toggle="tab"  role="tab"  (click)="onTabChange(3)" class="nav-link cursor-pointer active"[ngClass]="{'active show': selectedTabIndex == 3}" >Medication</a>
                                    </li>
                                    <!-- <li  class="mr-2 ml-auto d-flex"><span class="status-patient">Status</span>
                                        <select class="form-control select-small ml-3">
                                            <option>IOP</option>
                                            <option>PCB</option>
                                        </select>
                                    </li> -->
                                </ul>

                            </div>
                        </div>
                       <div  id="One" role="tabpanel"  class="tab-pane fade  active show "   role="tabpanel"
                        *ngIf="selectedTabIndex == 1">
                                           <div class="col-md-12 filter-patient-appoint mb-2">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="row">
                                <label class="col-md-4 col-form-label text-xs pr-0">Duration</label>
                                <div class="col-md-8 pr-0">
                                <select class="form-control text-xs"
                                     [(ngModel)]="duration"  name="duration"  (change)="getPatientBreifcaseAppointments('U')"><!-- (change)="onChangeUpDuration($event.target.value)"> -->
                                       <option value="CW">Current week</option>
                                      <!-- <option value="FN">Fortnight</option> -->
                                      <option value="CM">Current Month</option>

                                 </select>
                                </div>

                             </div>
                            </div>
                            <div class="col-md-5">
                             <div class="row">
                             <label class="col-md-5 col-form-label text-xs pr-0">Appointment Type</label>
                             <div class="col-md-7 pr-0">
                              <select class="form-control text-xs"  [(ngModel)]="apponitmentType"  name="apponitmentType"  (change)="getPatientBreifcaseAppointments('U')">
                                 <option value="0"> All </option>
                                 <option value="100">Group Therapy</option>
                                 <option value="3">Family Therapy</option>
                                 <option value="2">Individual Therapy</option>
                                 <option value="5">Psychiatric  Therapy</option>
                                 <option value="8">Psychiatric  Evaluation</option>
                                 <option value="9">Psychiatric  Medication</option>
                                 <option value="11">Phone Call </option>
                                 <option value="10">School Meeting</option>
                                 <option value="12">Speech Therapy </option>
                                 <option value="13">Nutrition Therapy</option>
                            </select>
                             </div>

                          </div>
                         </div>
                         <div class="col-md-3">
                             <div class="row">
                             <label class="col-md-4 col-form-label text-xs pr-0">Clinician</label>
                             <div class="col-md-8 pr-0">
                              <select class="form-control text-xs" [(ngModel)]="clinician"  name="clinician"  (change)="getPatientBreifcaseAppointments('U')">
                                <option value="0"> All </option>
                                <option *ngFor="let a of ddlStafflist" value="{{a.userId}}">
                                    {{a.staffName}}
                                 </option>
                              </select>
                             </div>
                            </div>
                         </div>



                        </div>

                    </div>
                       <div class="col-md-12 p-1">
                        <div class="bg-white">
                            <div class="appt-table thin-scroll">
                                <div class="table-responsive ">
                               <!--Dynamic Upcomming Appointment -->
                              <table class="table table-hover">
                               <thead>
                                <tr>
                                    <th style="width:100px;">Date</th>
                                    <th style="width:500px;">Details</th>
                                    <th style="width:150px;">Clinician</th>
                                    <th style="width:200px;" class="text-center">Therapy</th>
                                    <th style="width:150px;">Meeting Type</th>
                                    <th style="width:150px;">Meeting Link</th>
                                </tr>
                                </thead>
                                   <tbody>
                                   <tr *ngFor="let item of PatientUpcomingAppointments;let i = index"  [ngClass]="{'disabled':((item.scheduleStartDate | date:'MM/dd/yyyy') != (todayDate | date:'MM/dd/yyyy'))}" >
                                        <td>{{item.scheduleStartDate |date:'MM/dd/yyyy'}}</td>
                                         <td>{{item.appointmentName}}</td>
                                         <td>{{item.staffName}}</td>
                                          <td class="text-center">
                                           <span *ngIf="item.therapyId == 100" class="gt-badge">GT</span>
                                           <span *ngIf="item.therapyId == 3" class="f-badge">FT</span>
                                            <span *ngIf="item.therapyId == 2" class="it-badge">IT</span>
                                            <span *ngIf="item.therapyId == 5" class="pt-badge">PT</span>
                                            <span *ngIf="item.therapyId == 8" class="pe-badge">PE</span>
                                            <span *ngIf="item.therapyId == 9" class="pm-badge">PM</span>
                                            <span *ngIf="item.therapyId == 11" class="pc-badge">PC</span>
                                            <span *ngIf="item.therapyId == 10" class="sm-badge">SM</span>
                                            <span *ngIf="item.therapyId == 12" class="st-badge">ST</span>
                                            <span *ngIf="item.therapyId == 13" class="nt-badge">NT</span>
                                     </td>
                                     <td>{{item.meetingType}}</td>
                                      <td>
                                          <a  *ngIf="((item.scheduleStartDate | date:'MM/dd/yyyy') == (todayDate | date:'MM/dd/yyyy'))"
                                                    (click)="onmeetingLink(item)">{{ item.meetingLink }}</a>
                                           <a  *ngIf="((item.scheduleStartDate | date:'MM/dd/yyyy') != (todayDate | date:'MM/dd/yyyy'))" class="linkdisabled"
                                                       >{{ item.meetingLink }}</a>
                                      </td>
                               </tr>
                                </tbody>
                                </table>
                                <!--Dynamic Upcomming Appointment end-->
                                </div>
                            </div>
                        </div>
                    </div>
                   </div>
                    <div  id="Two" role="tabpanel"  class="tab-pane fade  active show "  role="tabpanel"
                                       *ngIf="selectedTabIndex == 2" >
                                       <div class="col-md-12 filter-patient-appoint mb-2">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="row">
                                                <label class="col-md-4 col-form-label text-xs pr-0">Duration</label>
                                                <div class="col-md-8 pr-0">
                                                <select class="form-control text-xs"
                                                     [(ngModel)]="duration"  name="durationP"  (change)="getPatientBreifcaseAppointments('P')">
                                                       <option value="PW" selected>Previous week</option>
                                                      <!-- <option value="PFN">Previous Fortnight</option> -->
                                                      <option value="PM">Previous Month</option>
                                                    </select>
                                                </div>

                                             </div>
                                            </div>
                                            <div class="col-md-5">
                                             <div class="row">
                                             <label class="col-md-5 col-form-label text-xs pr-0">Appointment Type</label>
                                             <div class="col-md-7 pr-0">
                                              <select class="form-control text-xs"  [(ngModel)]="apponitmentType"  name="apponitmentTypeP"  (change)="getPatientBreifcaseAppointments('P')">
                                                 <option value="0" selected> All </option>
                                                 <option value="100">Group Therapy</option>
                                                 <option value="3">Family Therapy</option>
                                                 <option value="2">Individual Therapy</option>
                                                 <option value="5">Psychiatric  Therapy</option>
                                                 <option value="8">Psychiatric  Evaluation</option>
                                                 <option value="9">Psychiatric  Medication</option>
                                                 <option value="11">Phone Call </option>
                                                 <option value="10">School Meeting</option>
                                                 <option value="12">Speech Therapy </option>
                                                 <option value="13">Nutrition Therapy</option>
                                       
                                            </select>
                                             </div>

                                          </div>
                                         </div>
                                         <div class="col-md-3">
                                             <div class="row">
                                             <label class="col-md-4 col-form-label text-xs pr-0">Clinician</label>
                                             <div class="col-md-8 pr-0">
                                              <select class="form-control text-xs" [(ngModel)]="clinician"  name="clinicianP"  (change)="getPatientBreifcaseAppointments('P')">
                                                <option value="0"> All </option>
                                                <option *ngFor="let a of ddlStafflistPast" value="{{a.userId}}">
                                                    {{a.staffName}}
                                                 </option>
                                              </select>
                                             </div>
                                            </div>
                                         </div>
                                      </div>

                                    </div>
                         <div class="col-md-12 p-1">
                                        <div class="bg-white">
                                            <div class="appt-table thin-scroll">
                                                <div class="table-responsive ">
                                                       <!--Dynamic Past Appointment -->
                              <table class="table table-hover">
                                <thead>
                                 <tr>
                                    <th style="width:100px;">Date</th>
                                    <th style="width:500px;">Details</th>
                                    <th style="width:150px;">Clinician</th>
                                    <th style="width:200px;" class="text-center">Therapy</th>

                                    <th style="width:150px;">Meeting Type</th>
                                    <th style="width:150px;">Meeting Link</th>
                                    <!-- <th class="text-center">Five Point Rating</th>
                                    <th class="text-center">Clinical Notes</th>
                                    <th class="text-center">Goal Score</th> -->
                                 </tr>
                                 </thead>
                                    <tbody>
                                    <tr *ngFor="let item of PatientAppointments;let i = index" [ngClass]="{'disabled':((item.scheduleStartDate | date:'MM/dd/yyyy') != (todayDate | date:'MM/dd/yyyy'))}">
                                         <td>{{item.scheduleStartDate |date:'MM/dd/yyyy'}}</td>
                                          <td>{{item.appointmentName}}</td>
                                          <td>{{item.staffName}}</td>
                                           <td class="stat-tp text-center">
                                            <span *ngIf="item.therapyId == 100" class="gt-badge">GT</span>
                                            <span *ngIf="item.therapyId == 3" class="f-badge">FT</span>
                                             <span *ngIf="item.therapyId == 2" class="it-badge">IT</span>
                                             <span *ngIf="item.therapyId == 5" class="pt-badge">PT</span>
                                             <span *ngIf="item.therapyId == 8" class="pe-badge">PE</span>
                                             <span *ngIf="item.therapyId == 9" class="pm-badge">PM</span>
                                             <span *ngIf="item.therapyId == 11" class="pc-badge">PC</span>
                                             <span *ngIf="item.therapyId == 10" class="sm-badge">SM</span>
                                             <span *ngIf="item.therapyId == 12" class="st-badge">ST</span>
                                             <span *ngIf="item.therapyId == 13" class="nt-badge">NT</span>
                                      </td>
                                      <td>{{item.meetingType}}</td>
                                      <td>
                                          <a  *ngIf="((item.scheduleStartDate | date:'MM/dd/yyyy') == (todayDate | date:'MM/dd/yyyy'))"
                                                   href="#" (click)="onmeetingLink(item)">{{ item.meetingLink }}</a>
                                           <a  *ngIf="((item.scheduleStartDate | date:'MM/dd/yyyy') != (todayDate | date:'MM/dd/yyyy'))"  class="linkdisabled"
                                                      href="#" >{{ item.meetingLink }}</a>
                                        </td>


                                      <!--class="linkdisabled"  <td  class="stat-tp text-center"><a  *ngIf="item.isFivepoint == 1" class="view-history"><i  class="far fa-grin-stars" (click)="onFivePRatings(item)"></i></a></td>
                                       <td class="stat-tp text-center"><a *ngIf="item.isClinicalNotes == 1" class="view-history "><i  class="fas fa-notes-medical" (click)="onClinicalNotes(item)"></i></a></td>
                                      <td class="stat-tp text-center"><a  *ngIf="item.isGoals == 1" class="view-history "><i  class="fas fa-bullseye"></i></a></td> -->
                                </tr>
                                 </tbody>
                                 </table>
                                 <!--Dynamic Past Appointment end-->

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                        </div>
                                        <div  id="Three" role="tabpanel" class="tab-pane fade  active show"  role="tabpanel"
                                        *ngIf="selectedTabIndex == 3" >
                                            <div  class="medic-his-list p-2">
                                                <ul class="list-unstyled list-unstyled-border list-unstyled-noborder thin-scroll" >
                                                    <li class="mb-2 ">
                                                        <div  class="card p-2 pb-1">
                                                            <div  class="col-md-12">
                                                                <div  class="col-md-12 medic-his-list">
                                                                    <div  class="row">
                                                                        <div  class="col-md-3 p-0">
                                                                            <div  class="mt-2">
                                                                                <div  class="date-medic">Previous Medication History</div>
                                                                            </div>
                                                                        </div>
                                                                        <div  class="col-md-5">

                                                                        </div>
                                                                        <div  class="col-md-4 pr-0">
                                                                            <div  class="d-flex w-100 mt-2 justify-content-end">
                                                                                <a >
                                                                                    <i  class="fas"
                                                                                     [ngClass]="{'fa-chevron-up':isshowhistroy,'fa-chevron-down':!isshowhistroy}" (click)="isshowhistroy=!isshowhistroy" ></i>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div  class="panel-list " *ngIf="isshowhistroy">
                                                                <div  class="col-md-12 mt-2">
                                                                    <div  class="row">
                                                                        <div  class="col-md-3">
                                                                            <div  class="w-100">
                                                                                <label >Medication</label>
                                                                            </div>
                                                                        </div>
                                                                        <div  class="col-md-2">
                                                                            <div >
                                                                                <label >Dosage</label>
                                                                            </div>
                                                                        </div>
                                                                        <div  class="col-md-3">
                                                                            <div >
                                                                                <label >Frequency</label>
                                                                            </div>
                                                                        </div>
                                                                        <div  class="col-md-4">
                                                                            <div >
                                                                                <label >Side Effect</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div  class="panel-list ">
                                                                <div  class="col-md-12 mt-2" >
                                                                <ul  >
                                                                    <li *ngFor="let item of medicationRecords ;let i=index" >
                                                                        <div  class="row">
                                                                            <div  class="col-md-3">
                                                                                <div  class="w-100">
                                                                                    <span>{{item.medications}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div  class="col-md-2">
                                                                                <div >
                                                                                    <span>{{item.dosage}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <div>
                                                                                    <!-- <span>{{item.frequency == 1 ?
                                                                                        'Daily' :item.frequency == 2 ?
                                                                                        'Weekly' : 'Monthly'
                                                                                        }}</span> -->
                                                                                        <span>{{item.frequency == 1 ? 'q.a.m. Every morning' :item.frequency == 2 ? 'q.p.m. Every evening' :item.frequency == 3 ? 'q.h.s Every night at bedtime'
                                                                                            :item.frequency == 4 ? 'b.i.d. Twice daily':item.frequency == 5 ? 't.i.d. 3 times a day':item.frequency == 6 ? 'q.i.d 4 times a day'
                                                                                            :item.frequency == 7 ? 'q.o.d Every other day':item.frequency == 8 ? 'q.w. Every week'  : 'p.r.n. Daily as needed'
                                                                                            
                                                                                          }}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div  class="col-md-4">
                                                                                <div > {{item.sideEffects}} </div>
                                                                            </div>
                                                                        </div>
                                                               </li>
                                                               </ul>
                                                            </div>
                                                            </div>
                                                               </div>
                                                         </div>
                                                    </li>
                                                        <li class="mb-2 cursor-pointer">
                                                            <div  class="card p-2 pb-1">
                                                                <div  class="col-md-12">
                                                                    <div  class="col-md-12 medic-his-list">
                                                                        <div  class="row">
                                                                            <div  class="col-md-3 p-0">
                                                                                <div  class="mt-2">
                                                                                    <div  class="date-medic">Current Medication</div>
                                                                                </div>
                                                                            </div>
                                                                            <div  class="col-md-5">

                                                                </div>
                                                                <div class="col-md-4 pr-0">
                                                                    <div class="d-flex w-100 mt-2 justify-content-end">
                                                                        <a>
                                                                            <i class="fas"
                                                                                [ngClass]="{'fa-chevron-up':isshowCurhistroy,'fa-chevron-down':!isshowCurhistroy}"
                                                                                (click)="isshowCurhistroy=!isshowCurhistroy"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="panel-list " *ngIf="isshowCurhistroy">
                                                        <div class="col-md-12 mt-2">
                                                            <div class="row">
                                                                <div class="col-md-3">
                                                                    <div class="w-100">
                                                                        <label>Medication</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <div>
                                                                        <label>Dosage</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3">
                                                                    <div>
                                                                        <label>Frequency</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div>
                                                                        <label>Side Effect</label>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <ng-container>
                                                            <div class="col-md-12 mt-2 mb-2"
                                                                *ngFor="let item of lstmedicine ;let i=index">
                                                                <ul>
                                                                    <li>
                                                                        <div class="row">
                                                                            <div class="col-md-3">
                                                                                <div class="w-100">
                                                                                    <span>{{item.medications}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-2">
                                                                                <div>
                                                                                    <span>{{item.dosage}}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <div>
                                                                                    <span>{{item.frequency == 1 ? 'q.a.m. Every morning' :item.frequency == 2 ? 'q.p.m. Every evening' :item.frequency == 3 ? 'q.h.s Every night at bedtime'
                                                                                        :item.frequency == 4 ? 'b.i.d. Twice daily':item.frequency == 5 ? 't.i.d. 3 times a day':item.frequency == 6 ? 'q.i.d 4 times a day'
                                                                                        :item.frequency == 7 ? 'q.o.d Every other day':item.frequency == 8 ? 'q.w. Every week'  : 'p.r.n. Daily as needed'
                                                                                        
                                                                                      }}</span>
                                                                                    <!-- <span>{{item.frequency == 1 ?
                                                                                        'Daily' :item.frequency == 2 ?
                                                                                        'Weekly' : 'Monthly'
                                                                                        }}</span> -->
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-4">
                                                                                <div class="">
                                                                                    <span class="morn-time"
                                                                                        *ngIf="item.isMorning"><i
                                                                                            class="fas fa-cloud-sun mr-1"></i>Morning</span>
                                                                                    <span class="morn-time"
                                                                                        *ngIf="item.isNoon"><i
                                                                                            class="fas fa-sun mr-1"></i>Noon</span>
                                                                                    <span class="morn-time"
                                                                                        *ngIf="item.isEvening"><i
                                                                                            class="far fa-moon mr-1"></i>Evening</span>
                                                                                    <span class="morn-time"
                                                                                        *ngIf="item.isBedTime"><i
                                                                                            class="fas fa-bed mr-1"></i>Bed
                                                                                        Time</span>
                                                                                      <span class="pl-2"
                                                                                        *ngIf="!item.isMorning && !item.isNoon && !item.isEvening && !item.isBedTime">-</span>
                                                                                    </div>
                                                                                  </div>
                                                                            </div>
                                                                   </li>
                                                                   </ul>
                                                                </div>
                                                            </ng-container>
                                                                   </div>
                                                             </div>
                                                        </li>
                                                        </ul>

                                            </div>

                                        </div>


                    </div>
                    </div>

                    <div class="col-md-12 ">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-5 col-sm-12 pr-0">
                                        <div class="card full-height">
                                            <div class="card-body p-3">
                                                <div class="card-title">Prior Intake</div>
                                                <div class="d-flex flex-wrap justify-content-around pb-2 pt-2 crba-blocks-container">
                                                    <div class="col-12 col-md-4 px-2 pb-2 pb-md-0 text-center crba-blocks">
                                                        <div id="circles-1">
                                                            <div class="crba" [ngClass]="lstPriorIntake?.isCrba ? 'tick' : 'crba minus'">
                                                                <img class="img-fluid" src="../../../assets/images/certificate.png">
                                                            </div>

                                                            </div>
                                                            <h6 class="fw-bold mt-3 mb-0">Benefits</h6>
                                                            <div class="stats" *ngIf="lstPriorIntake?.isCrba">
                                                                <i class="material-icons">update</i> CRBA Completed on
                                                                {{lstPriorIntake?.crbaUpdate | date:'MM/dd/yyyy'}}
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col-12 col-md-4 px-2 pb-2 pb-md-0 text-center crba-blocks" (click)="redirectToFinancialReview()">
                                                            <div id="circles-2">
                                                                <div class="crba"
                                                                    [ngClass]="lstPriorIntake?.isFinPro ? 'tick' : 'crba minus'">
                                                                    <img class="img-fluid"
                                                                        src="../../../assets/images/fin.png">
                                                                </div>

                                                        </div>
                                                        <h6 class="fw-bold mt-3 mb-0">Financial Proposal</h6>
                                                        <div class="stats" *ngIf="lstPriorIntake?.isFinPro">
                                                            <i class="material-icons">update</i> Financial Proposal Completed on
                                                            {{lstPriorIntake?.finProUpdate | date:'MM/dd/yyyy'}}
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 px-2 pb-2 pb-md-0 text-center crba-blocks">
                                                        <div id="circles-3">
                                                            <div class="crba" [ngClass]="lstPriorIntake?.isTour ? 'tick' : 'tick'">
                                                                <img class="img-fluid" src="../../../assets/images/appointment.png">
                                                            </div>

                                                        </div>
                                                        <h6 class="fw-bold mt-3 mb-0">Tour</h6>
                                                        <div class="stats">
                                                            <i class="material-icons">update</i> Tour Completed on
                                                            {{lstPriorIntake?.tourUpdate == null ? (lstPriorIntake?.finProUpdate | date:'MM/dd/yyyy'):  (lstPriorIntake?.tourUpdate | date:'MM/dd/yyyy')}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-md-7 col-sm-12 pr-0">
                                        <div class="card full-height">
                                            <div class="card-body p-3">
                                                <div class="card-title">Prepare for Intake</div>
                                                <h6 class="fw-bold intake-txt mt-3 mb-0 d-none">Schedule Intake Appointment</h6>
                                                <!-- <div class="intake-section-icon" *ngIf="immediateAction == statusintakePacket"> -->
                                                    <div class="intake-section-icon" [ngClass]="(roleAccess.isCreate||roleAccess.isEdit)?'':'disabled'">
                                                    <div class="d-flex flex-wrap justify-content-around pb-2 pt-2">
                                                        <div class="col-12 col-md-3 px-2 pb-2 pb-md-0 text-center">
                                                            <div id="circles-1">
                                                                <a class="intake" (click)="onPrepareIntake(1)"
                                                                    [ngClass]="intakeStatus?.isHealth ? 'tick' : 'cross'">
                                                                    <img class="img-fluid"
                                                                        src="../../../assets/images/health_screen.png">
                                                                     <i class="fas fa-check-circle"
                                                                        *ngIf="intakeStatus?.isHealth"></i>
                                                                    <i class="fas fa-minus-circle"
                                                                        *ngIf="!intakeStatus?.isHealth"></i> 
                                                                </a>

                                                            </div>
                                                            <h6 class="fw-bold intake-txt mt-3 mb-0">Health Screening</h6>

                                                        </div>
                                                        <div class="col-12 col-md-3 px-2 pb-2 pb-md-0 text-center">
                                                            <div id="circles-2">
                                                              <a class="intake" (click)="onPrepareIntake(4)"
                                                                 [ngClass]="intakeStatus?.verified == 0 ? 'tick' : 'cross'">
                                                                <img class="img-fluid"
                                                                     src="../../../assets/images/consent-new.png">
                                                                <i class="fas fa-minus-circle"
                                                                   *ngIf=" 7 > intakeStatus?.verified"></i>
                                                                <i class="fas fa-check-circle"
                                                                   *ngIf="intakeStatus?.verified >= 7"></i>

                                                              </a>

                                                            </div>
                                                            <h6 class="fw-bold intake-txt mt-3 mb-0">Consent Form</h6>
                                                            <div class="status-consent">
                                                                (<span>{{intakeStatus?.verified}}/{{intakeStatus?.total}}</span>
                                                                Signed)</div>
                                                        </div>
                                                        <div class="col-12 col-md-3 px-2 pb-2 pb-md-0 text-center">
                                                            <div id="circles-3">
                                                                <a class="intake" (click)="onPrepareIntake(2)"
                                                                    [ngClass]="intakeStatus?.isMedication ? 'tick' : 'cross'">
                                                                    <img class="img-fluid"
                                                                        src="../../../assets/images/health_insurance.png">
                                                                    <i class="fas fa-check-circle"
                                                                        *ngIf="intakeStatus?.isMedication"></i>
                                                                    <i class="fas fa-minus-circle"
                                                                        *ngIf="!intakeStatus?.isMedication"></i>
                                                                </a>
                                                            </div>
                                                            <h6 class="fw-bold intake-txt mt-3 mb-0">Medication Checklist</h6>
                                                        </div>
                                                        <div class="col-12 col-md-3 px-2 pb-2 pb-md-0 text-center">
                                                            <div id="circles-3">
                                                                <a class="intake" (click)="onPrepareIntake(3)"
                                                                    [ngClass]="intakeStatus?.isSurvey ? 'tick' : 'cross'">
                                                                    <img class="img-fluid" src="../../../assets/images/survey.png">
                                                                    <i class="fas fa-check-circle"
                                                                        *ngIf="intakeStatus?.isSurvey"></i>
                                                                    <i class="fas fa-minus-circle"
                                                                        *ngIf="!intakeStatus?.isSurvey"></i>
                                                                </a>
                                                            </div>
                                                            <h6 class="fw-bold intake-txt mt-3 mb-0">Survey</h6>
                                                        </div>
                                                    </div>
                                                   <div  *ngIf="immediateAction == statusintakePacket"><!--Newly added ngIf-->
                                                        <div class="intake-head">Your
                                                            intake is scheduled.</div>

                                                        <span class="time-schedule">
                                                            {{lstMeetingSchedule?.meetingTimes[0]?.scheduleDate | date:'fullDate'}}
                                                            {{lstMeetingSchedule?.meetingTimes[0]?.fromTime | date:'hh:mm a'}} to
                                                            {{lstMeetingSchedule?.meetingTimes[0]?.toTime | date:'hh:mm a'}}
                                                        </span>

                                                    </div>
                                                </div>
                                                <div class="intake-section" *ngIf="immediateAction != statusintakePacket">
                                                    <div
                                                        *ngIf="lstMeetingSchedule?.status == statusAccept || lstMeetingSchedule?.status == statusstaffAssigned">
                                                        <div class="intake-head">Your
                                                            intake is scheduled.</div>
                                                        <div class="intake-inprocess d-none">we are curently in process of assigning our
                                                            staff</div>
                                                        <span class="time-schedule">
                                                            {{lstMeetingSchedule?.meetingTimes[0]?.scheduleDate | date:'fullDate'}}
                                                            {{lstMeetingSchedule?.meetingTimes[0]?.fromTime | date:'hh:mm a'}} to
                                                            {{lstMeetingSchedule?.meetingTimes[0]?.toTime | date:'hh:mm a'}}
                                                        </span>

                                                    </div>
                                                    <div class="intake-declined"
                                                        *ngIf="lstMeetingSchedule?.status == statusDecline">
                                                        <div class="">Your
                                                            intake scheduled has been declined.</div>
                                                    </div>

                                                    <div class="not-yet" *ngIf="immediateAction == statusforIntake">
                                                        <img class="img-fluid" src="../../../assets/images/schedule-intake.png">
                                                        <span>Intake Appointment Pending Scheduling</span>
                                                    </div>
                                                    <div *ngIf="lstMeetingSchedule?.status == statusmeetingScheduled">

                                                        <div class="intake-head-sub-sub2  mb-2 border-bottom">Please select either available Intake appointment slot below</div>
                                                        <div class="custom-radio choose-intake mb-3">
                                                            <mat-radio-group aria-label="Select an option"
                                                                [disabled]="isAccceptedIntake" [(ngModel)]="rblMeetingDate"
                                                                #avaldate="ngModel" name="avaldate">
                                                                <mat-radio-button value="0">
                                                                    {{lstMeetingSchedule?.meetingTimes[0]?.scheduleDate |
                                                                    date:'fullDate'}}
                                                                    {{lstMeetingSchedule?.meetingTimes[0]?.fromTime | date:'hh:mm
                                                                    a'}}
                                                                    to
                                                                    {{lstMeetingSchedule?.meetingTimes[0]?.toTime | date:'hh:mm a'}}
                                                                </mat-radio-button>
                                                                <mat-radio-button value="1">
                                                                    {{lstMeetingSchedule?.meetingTimes[1]?.scheduleDate |
                                                                    date:'fullDate'}}
                                                                    {{lstMeetingSchedule?.meetingTimes[1]?.fromTime | date:'hh:mm
                                                                    a'}}
                                                                    to
                                                                    {{lstMeetingSchedule?.meetingTimes[1]?.toTime | date:'hh:mm a'}}
                                                                </mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix text-right mt-1"
                                                        *ngIf="lstMeetingSchedule?.status == statusmeetingScheduled">
                                                        <!-- <button class="btn btn-dashboard blue-theme float-right" title="Reschedule"
                                                            (click)="onDeclineintake()">Reschedule</button> -->
                                                        <button class="btn btn-dashboard green-theme float-right mr-2"
                                                            title="Accept" (click)="onAcceptintake()" *ngIf="roleAccess.isApprove"
                                                            [disabled]="rblMeetingDate == undefined">Accept</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-md-12 pr-0">
                                        <div class="card full-height">
                                            <div class="card-body p-3">
                                                <div class="card-title">My Treatment Progress</div>
                                                <div class="empty-treatment">
                                                    <p class="w-100 text-center mt-4 mb-4"><img
                                                            src="../../../assets/images/treatment.png"> </p>We will monitor your
                                                    progress based on your treatment plan
                                                </div>
                                            </div>

                                        </div>
                                    </div> -->
                                    <!-- <div class="col-md-6 pr-0">

                                       <div class="card full-height">
                                            <div class="card-body p-3">
                                                <div class="card-title">My Invoices</div>
                                                <div class="empty-invoice">
                                                    <p class="w-100 text-center mt-4 mb-4"><img
                                                            src="../../../assets/images/invoice-png.png"> </p> No active invoices
                                                </div>
                                            </div>
                                        </div>

                                    </div> -->
                                <!--</div>-->
                            </div>

                        </div>
                    </div>
                    </div>
                </div>

                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 medic-list">
                    <div class="calendar-small breif-calen">
                        <app-calender [patientInfoId]="patientInfoId" [Type]="2" ></app-calender>
                    </div>
                                    </div>

            </div>
        </div>


    </div>
</div>


<!--For Front desk wait pop up-->
<div class="intake-welcome-screen" *ngIf="IsIntakeWelcomscreen">
    <div class="landing-page">
        <div class="col-md-12">
            <div class="row">
                <div class="lead-box pl-5 pr-5 pt-4 pb-4 w-100">
                    <div class="col-md-12 text-center welcome-txt">
                        <h2>Thank you !</h2>
                        <div class="w-100 welcome-img">
                            <img src="../../../assets/images/WelcomepageImage.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-12 text-center welcome-txt">
                        <h2>{{userName}},</h2>
                    </div>
                    <div class="sub-txt-board mt-3 mb-3">Our front desk will provide you with Intake appointment options shortly.<br> Please complete the Intake documents under “Prepare for Intake” before your intake appointment.</div>
                    <div class="w-100 text-center">
                        <button class="btn start-btn" (click)="OnNextToBriefcase()">Next</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--For Front desk wait pop up end -->


<!-- <div class="col-md-12 history-section mt-4"
*ngIf="isfinancialproposal">
<app-financial-review></app-financial-review>
</div> -->
<div class="login-page">
    <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
        <div class="login-section">
            <div class="container-fluid">
                <div class="row no-gutter">
                    <div class="d-none d-md-flex col-md-6 col-lg-9 bg-image"></div>
                    <div class="col-md-6 col-lg-3">
                        <div class="login d-flex align-items-top py-4">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-9 col-lg-12 mx-auto">
                                        <div class="col-md-12 p-0 logo-img">
                                            <img class="img-fluid" src="../../assets/images/brand-logo.jpg" alt="">
                                        </div>
                                        <form fxLayout="column" (submit)="onSubmit()"
                                            class="col-md-12 col-mg-12 col-sm-12 col-xs-12 p-0"
                                            [formGroup]="changepassswordForm">
                                            <mat-card-content>
                                                <!--<div class="input-row" fxLayout="row" fxLayout.lt-md="column"
                                                    fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
                                                    <mat-form-field fxFlex class="example-full-width w-100">
                                                        <input autocomplete="off" placeholder="Old Password" matInput
                                                            (paste)="global.trimValue($event)" type="password"
                                                            id="float-input" formControlName="OldPassword"
                                                            autocomplete="off" class="form-control " />
                                                        <div *ngIf="submitted && f.OldPassword.errors"
                                                            class="invalid-feedback d-block">
                                                            <div *ngIf="f.OldPassword.errors.required">Password is
                                                                required</div>
                                                        </div>
                                                    </mat-form-field>
                                                </div>-->
                                                <div class="input-row">
                                                    <mat-form-field fxFlex class="example-full-width w-100">
                                                        <div class="custom-password">
                                                        <input autocomplete="off" matInput id="password"
                                                            placeholder="Password"
                                                            (paste)="global.trimValue($event)"
                                                            formControlName="Password" [type]="PassEye ? 'password' : 'text'" 
                                                            class="form-control" tooltipEvent="focus"
                                                            (focus)="showErrorPopup()" (focusout)="hideErrorPopup()"
                                                            [ngClass]="(changepassswordForm.controls['Password'].invalid && (changepassswordForm.controls.Password.value !=null && changepassswordForm.controls.Password.value !='')) ? 'is-invalid' : ''"
                                                            (mouseover)="changeStyle($event)"
                                                            (mouseout)="changeStyle($event)" />
                                                            <i  [class]="PassEye ? 'fa fa-fw fa-eye field-icon toggle-password' : 'fa fa-fw fa-eye-slash field-icon toggle-password'"  (click)="PassEye=!PassEye"></i></div>
                                                        <div *ngIf="submitted && f.Password.errors"
                                                            class="text-danger mat-form-field-subscript-wrapper mt-4">
                                                            <div *ngIf="f.Password.errors.required">Password is
                                                                required</div>
                                                        </div>
                                                    </mat-form-field>

                                                    <div class="popup-design--replicate"
                                                        [style.display]="showErrorPassword ? 'block' : 'none'">
                                                        <div class="icon-align--newpop">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="#ff0000"
                                                                class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                                            </svg>
                                                        </div>
                                                        <label class="col"
                                                            [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                                                            <i class="material-icons">{{
                                                                changepassswordForm.controls['Password'].hasError('required')
                                                                ||
                                                                changepassswordForm.controls['Password'].hasError('minlength')
                                                                ? 'cancel' :
                                                                'check_circle' }}</i>
                                                                Must contain at least 8 characters!
                                                        </label>

                                                        <label class="col"
                                                            [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                                            <i class="material-icons">{{
                                                                changepassswordForm.controls['Password'].hasError('required')
                                                                ||
                                                                changepassswordForm.controls['Password'].hasError('hasNumber')
                                                                ? 'cancel' :
                                                                'check_circle' }}</i>
                                                                Must contain at least 1 numeric character!
                                                        </label>

                                                        <label class="col"
                                                            [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                                            <i class="material-icons">{{
                                                                changepassswordForm.controls['Password'].hasError('required')
                                                                ||
                                                                changepassswordForm.controls['Password'].hasError('hasCapitalCase')
                                                                ? 'cancel' :
                                                                'check_circle' }}</i>
                                                                Must contain at least 1 uppercase letter!
                                                        </label>

                                                        <label class="col"
                                                            [ngClass]="changepassswordForm.controls['Password'].hasError('required') || changepassswordForm.controls['Password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                                            <i class="material-icons">{{
                                                                changepassswordForm.controls['Password'].hasError('required')
                                                                ||
                                                                changepassswordForm.controls['Password'].hasError('hasSpecialCharacters')
                                                                ? 'cancel' :
                                                                'check_circle' }}</i>
                                                                Must contain at least 1 special character!
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="input-row">
                                                    <mat-form-field fxFlex class="example-full-width w-100">
                                                        <div class="custom-password">
                                                        <input autocomplete="off" matInput placeholder="Confirm Password"
                                                            id="ConfirmPassword" (paste)="global.trimValue($event)"
                                                            [ngClass]="(changepassswordForm.controls['ConfirmPassword'].invalid && (changepassswordForm.controls.ConfirmPassword.value !=null && changepassswordForm.controls.ConfirmPassword.value !=''))? 'is-invalid' : ''"
                                                            formControlName="ConfirmPassword" [type]="ConfirmpassEye ? 'password' : 'text'" 
                                                            class="form-control" />
                                                            <i  [class]="ConfirmpassEye ? 'fa fa-fw fa-eye field-icon toggle-password' : 'fa fa-fw fa-eye-slash field-icon toggle-password'"  (click)="ConfirmpassEye=!ConfirmpassEye"></i></div>

                                                        <div *ngIf="submitted && f.ConfirmPassword.errors"
                                                            class="text-danger mat-form-field-subscript-wrapper mt-4">
                                                            <div *ngIf="f.ConfirmPassword.errors.required">Confirm
                                                                password
                                                                is Required</div>
                                                        </div>
                                                        <label
                                                            class="text-danger mt-4 invalid-feedback d-block mat-form-field-subscript-wrapper"
                                                            *ngIf="changepassswordForm.controls['ConfirmPassword'].hasError('NoPassswordMatch') && (changepassswordForm.controls.ConfirmPassword.value !=null && changepassswordForm.controls.ConfirmPassword.value !='')">
                                                            Passwords do not match
                                                        </label>
                                                    </mat-form-field>
                                                    <!-- <label class="text-danger invalid-feedback d-block"
                                                        *ngIf="(changepassswordForm.controls['ConfirmPassword'].hasError('required')">
                                                        Confirm password is Required!
                                                    </label> -->


                                                </div>
                                            </mat-card-content>
                                            <div class="col-md-12 text-center p-0 mt-2">
                                                <button mat-stroked-button color="accent" (click)="oncancelChangePwd()"
                                                    class="mr-2 primary-btn-outlined mb-3 " type="button">
                                                    Cancel
                                                </button>
                                                <!-- [disabled]="changepassswordForm.invalid" -->
                                                <button (click)="onSubmit()" mat-stroked-button color="accent"
                                                    class="primary-btn-filled " type="submit">
                                                    SET PASSWORD
                                                </button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
export class IncReportInfo{
    IncidentReportId:Number=0;
    PatientInfoId:Number;
    PatientName:string;
   // Address:string;
    TheraphyId:Number;
    ClinicianId:Number;
   // ReportedBy:Number;
    Title:string;
    IncidentDate:any;
    IncidentTime:any;
    IncidentLocation:string;
    StaffMember:string;
    BehaviorBefore:string;
    BehaviorAfter:string;
    BehaviorResponse:string;
    IsDiscontinuingRestraint:any;
    IsCalled:any;
    SupportPerson:string;
    IsInjured:any;
    InjuredPerson:string;
    Witness:string;
    AttemptedInterventions:string;
    RestraintDescribtion:string;
   // ReleaseCondition:string;
    Staff:string;
    RestraintTypeId:any;
    DescribeRestraint:string;
    CpiRestraintTypeId:any;
  //  Rtime:string;
    PhysicalDisabilities:string;
    PhysicalAbuse:string;
    MedExamDate:any;
    MedExamTime:any;
    Result:string;
    OtherTheraphyName:string;
    Status:Number;
    IncidentReportTypeId:Number;
    IncidentDescription:string;
    StaffInitial:string;
    ReportDate:any;
    IndividualInvolved:string;
    SignDate:any;
    Intervention:string;
    Program:Number;
    RTimeline:Number=0;
    RestraintConditions:Array<RestraintCondition>;
    RestraintInAday:any;
    DiscontinuingRestraint:any;
    IncidentDateForIncident:any;
    IncidentTimeForIncident:any;
    ConditionOnRelease:string;
    StaffInitials:string;
    LicenseNumber : string;
    SSignature : boolean;
}

export class RestraintCondition{
    RestraintConditionId:Number=0;
    RestraintAdminstrated:any;
    RestraintLength:string;
    EndTime :string;
    ProvisionOfWater:any;
    ReleaseCondition:string;
    AssessmentStatus:string;
}


import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { location } from 'src/app/_models/_Common/_Location';
import { NgForm } from '@angular/forms';
import { SettingsService } from '../settings/settings.service';
import { cloneDeep } from "lodash";
import { MeetingAppointmentComponent } from 'src/app/common/pages/settings/meeting-appointment/meeting-appointment.component';
import { MeetingLocationComponent } from 'src/app/common/pages/settings/meeting-location/meeting-location.component';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild(MeetingAppointmentComponent) private meetingAppointmentComponent: MeetingAppointmentComponent;
  @ViewChild(MeetingLocationComponent) private meetingLocationComponent: MeetingLocationComponent;
  selectedTabIndex: number;
  navMasterLinks: any[];
  constructor(public global: Global, public settingsService: SettingsService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,) { }

  ngOnInit(): void {
    this.selectedTabIndex = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    if (isNaN(this.selectedTabIndex) || this.selectedTabIndex < 0) {
      this.selectedTabIndex = 1;
    }

  }
  onTabChange(tab) {
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: tab
      }
    });
    this.selectedTabIndex = tab;
    // if (tab.index == 1) {
    //   this.meetingLocationComponent.getMeetingLocation();
    // }
    
  }



  //#endregion
}



export class benchmarkmodel {
Benchmarkdiscription: string;
Benchmarktitle: string;
Benchmarkunit: Number;
GoalBenchmarkId: Number;
GoalMeasureTypeId: Number;
Status: any;
TherapyId: Number;
TrackBenchmarkId: Number;
GoalId:any;
ChmatreatmentSummaryId:any;
TrackBenchIsSelected:any;
OtherBenchamarkId:any;
TreatmentGoalsId:any;
IsPresentingProb:any;
}
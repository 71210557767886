<div class="tab-content" id="v-pills-tabContent"  *ngIf="roleAccess.isView">
    <div class="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-home" role="tabpanel"
      aria-labelledby="v-pills-home-tab" >
      <div  class="box-lead-head w-100">Diagnosis Code  </div>
      
   <form (ngSubmit)="Diagnosisfrm.form.valid  && PostDiagnosisfrm(Diagnosisfrm)" id="Diagnosisfrm" autocomplete="off" #Diagnosisfrm="ngForm"
   novalidate>
      <div class="col-md-12 ">
        <div class="row">
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Diagnosis Code</mat-label>
               <input matInput  maxlength="10"
                [(ngModel)]="dtoDiagnosisfrm.DiagnosisCodeName" #DiagnosisCodeName="ngModel" name="DiagnosisCodeName" [disabled]="isEdit"
                [ngClass]="{ 'is-invalid': Diagnosisfrm.submitted && DiagnosisCodeName.invalid }"
                required>
                <mat-error *ngIf="Diagnosisfrm.submitted && DiagnosisCodeName.invalid">
                  <mat-error *ngIf="DiagnosisCodeName.errors.required">Diagnosis Code  is required</mat-error>
                </mat-error>
            </mat-form-field>
          </div>
          
          <div class="col-md-4">
            <mat-form-field class="w-100">
              <mat-label>Diagnosis Code Description</mat-label>
              
              <textarea matInput appTextareaAutoresize class="w-100" cols="2" rows="4" maxlength="1500"
              [(ngModel)]="dtoDiagnosisfrm.DiagnosisCodeDescription" #DiagnosisCodeDescription="ngModel" name="DiagnosisCodeDescription"
              [ngClass]="{ 'is-invalid': Diagnosisfrm.submitted && DiagnosisCodeDescription.invalid }"
              required></textarea>
                <mat-error *ngIf="Diagnosisfrm.submitted && DiagnosisCodeDescription.invalid">
                  <mat-error *ngIf="DiagnosisCodeDescription.errors.required">Diagnosis Code Description  is required</mat-error>
                </mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width custom-textarea mt-2">
              <mat-label>Diagnosis Code Description</mat-label>
              <textarea matInput appTextareaAutoresize class="w-100" cols="2" rows="4" maxlength="1500"
              [(ngModel)]="dtoDiagnosisfrm.DiagnosisCodeDescription" #DiagnosisCodeDescription="ngModel" name="DiagnosisCodeDescription"
              [ngClass]="{ 'is-invalid': Diagnosisfrm.submitted && DiagnosisCodeDescription.invalid }"
              required></textarea>
              <mat-error *ngIf="Diagnosisfrm.submitted && DiagnosisCodeDescription.invalid">
                <mat-error *ngIf="DiagnosisCodeDescription.errors.required">Diagnosis Code Description  is required</mat-error>
            </mat-form-field> -->
          </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-right mt-3" *ngIf="roleAccess.isCreate || roleAccess.isEdit">
              <button type ="submit" mat-stroked-button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{isEdit ? 'Update' : 'Save'}}</button>
              <button type="button"  mat-stroked-button class="btn btn-cancel primary-btn-m h-40"  (click)="CancelClick()">Cancel</button>

            </div>
          </div>
          </div>
   </form>

           <div class="col-md-12  mt-2" *ngIf="roleAccess.isView">
            <div class="row">
              <div  class="col-md-4 mt-3">Diagnosis Code - Details </div>
              <div  class="col-md-3 ml-auto"> 
                <mat-form-field class="w-100">
                  <mat-label>Search diagnosis codes</mat-label>
                  <input matInput type="text" (keyup)="searchTblVal($event.target.value)"/>
                </mat-form-field>
                
               </div>
              </div>
            <div class="col-md-12 table-custom p-0">
              <div class="table-responsive">
                <mat-table #table [dataSource]="dataSource" matSort class="w-100">
                  <ng-container matColumnDef="sno">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="DiagnosisCodeName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Diagnosis Code </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.DiagnosisCodeName}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="DiagnosisCodeDescription">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Diagnosis Code Description </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.DiagnosisCodeDescription}} </mat-cell>
                  </ng-container>
        
                 

                  
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>{{ (roleAccess.isEdit) ? 'Actions' : '' }}  </mat-header-cell>
                    <mat-cell *matCellDef="let element;let i = index;">
                      <button type="button" mat-icon-button matTooltip="Edit" (click)="onListEdit(element)" color="primary"  *ngIf="roleAccess.isEdit">
                        <img src="../../../assets/images/editblue.png">
                      </button>
                      <button type="button" mat-icon-button matTooltip="Delete" type="button" role="button"  class="cursor-pointer"
                      (click)="ondeleteDiagonasis(element)" color="warn" >
                      <img src="../../../assets/images/deletered.png">
                    </button>
                      
                    </mat-cell>
                  </ng-container>
        
                  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                  <mat-row [style.background-color]="(isEdit && row.DiagnosisCodeId == this.dtoDiagnosisfrm.DiagnosisCodeId) ? 'aliceblue' : 'white'" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        
                </mat-table>
               
              </div>
            </div>
            <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>

          </div> 
  
      </div>
      </div>

import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import { PatientService } from 'src/app/patient/patient.service';
import { chmadetails } from 'src/app/_models/_Patient/_CHMADetails';
import { IntakeCmhaComponent } from 'src/app/patient/intake/intake-cmha/intake-cmha.component';
import { chmaEditPatientDetails } from 'src/app/_models/_Patient/_ChmaEditPatientDetails';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { TemplateService } from 'src/app/common/pages/template/template.service';
@Component({
  selector: 'app-cmha-assessment',
  templateUrl: './cmha-assessment.component.html',
  styleUrls: ['./cmha-assessment.component.scss']
})
export class CmhaAssessmentComponent implements OnInit {
  isMovedChma: boolean;
  // @ViewChild(IntakeCmhaComponent, { static: false }) private chma;
  queYesNo = constantVariables.questionType.YesNo;
  queMCSA = constantVariables.questionType.MCSA;
  queMCMA = constantVariables.questionType.MCMA;
  queDescriptive = constantVariables.questionType.Descriptive;
  queRatings = constantVariables.questionType.Ratings;
  survey = constantVariables.inTakeForms.Survey;
  patientInfoId: any;
  patientAccountId: any;
  treatmentgoals: any = [];
  needsofclient: any = [];
  preliminarygoal: any = [];
  initialIntervention: any = [];
  lstCHMAAssessment: any = [];
  patientDetails=new chmaEditPatientDetails();
  roleAccess:RoelAccess;
  Severity:any;
  ddlseverity: any;
  therapySevierityList:any;
  lstGoals : any = [];
  bg = '#ff4000'
  constructor(public global: Global, public patientservice: PatientService, private chma: IntakeCmhaComponent,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public roleaccessservice:PageAccessService,
    public tempService: TemplateService) { }

  ngOnInit(): void {
    this.getPreliminaryGoals();
    const pageid = constantVariables.pagelists.PrepareCHMA;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.isMovedChma = Number(this.commonService.FrontEndDecryption(params.isChma).replace(/"/g, "")) == 0 ? false : true;
      }
      this.getSeverityMaster();
      this.getChmaAssessment();
      this.onAddtreatmentgoals(1);
      this.onAddneedsofclient(1);
      this.onAddpreliminarygoal(1);
      this.onAddinitialIntervention(1);
    });

  }
  istherapySevierityListAvailable=false;
  getChmaAssessment() {
    //this.spinnerservice.show();
    this.patientservice.getCmhaAssessment(this.patientAccountId).subscribe(
      (res: any) => {
        this.onLoadAssessment(res.dtoChmaAssessments);
        this.Severity = res.severity;
        this.therapySevierityList=res.therapySevierity; 
        console.log(this.therapySevierityList);
        if(this.therapySevierityList.length!=0){
          this.istherapySevierityListAvailable=true
        }else{
          this.istherapySevierityListAvailable=false
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onLoadAssessment(data) {
    if (data.filter(x => x.typeId == 1).length > 0) {
      this.treatmentgoals = data.filter(x => x.typeId == 1)
    } else {
      this.onAddtreatmentgoals(2);
    }
    if (data.filter(x => x.typeId == 2).length > 0) {
      this.needsofclient = data.filter(x => x.typeId == 2)
    } else {
      this.onAddneedsofclient(2);
    }
    if (data.filter(x => x.typeId == 3).length > 0) {
      this.preliminarygoal = data.filter(x => x.typeId == 3)
    } else {
      this.onAddpreliminarygoal(2);
    } if (data.filter(x => x.typeId == 4).length > 0) {
      this.initialIntervention = data.filter(x => x.typeId == 4)
    } else {
      this.onAddinitialIntervention(2);
    }
  }
  onAddtreatmentgoals(loop) {
    if (this.treatmentgoals && this.treatmentgoals == 0 || loop == 3) {
      loop = loop == 3 ? 1 : loop;
      for (let i = 0; i < loop; i++) {
        this.treatmentgoals.push({
          chmaassessmentId: 0,
          patientAccountId: this.patientAccountId,
          typeId: 1,
          information: null,
        })
      }
    }
  }
  onAddneedsofclient(loop) {
    if (this.needsofclient && this.needsofclient == 0 || loop == 3) {
      loop = loop == 3 ? 1 : loop;
      for (let i = 0; i < loop; i++) {
        this.needsofclient.push({
          chmaassessmentId: 0,
          patientAccountId: this.patientAccountId,
          typeId: 2,
          information: null,
        })
      }
    }
  }
  onAddpreliminarygoal(loop) {
    if (this.preliminarygoal && this.preliminarygoal == 0 || loop == 3) {
      loop = loop == 3 ? 1 : loop;
      for (let i = 0; i < loop; i++) {
        this.preliminarygoal.push({
          chmaassessmentId: 0,
          patientAccountId: this.patientAccountId,
          typeId: 3,
          information: null,
          goalId : null
        })
      }
    }
  }
  onAddinitialIntervention(loop) {
    if (this.initialIntervention && this.initialIntervention == 0 || loop == 3) {
      loop = loop == 3 ? 1 : loop;
      for (let i = 0; i < loop; i++) {
        this.initialIntervention.push({
          chmaassessmentId: 0,
          patientAccountId: this.patientAccountId,
          typeId: 4,
          information: null,

        })
      }
    }
  }
  removeinformation(data, index, type) {
    if (data.chmaassessmentId != 0) {
      this.removeexistMedinfo(data);
    }
    if (type == 1) {
      this.treatmentgoals.splice(index, 1);
    } else if (type == 2) {
      this.needsofclient.splice(index, 1);
    }
    else if (type == 3) {
      this.preliminarygoal.splice(index, 1);
    }
    else if (type == 4) {
      this.initialIntervention.splice(index, 1);
    }

  }
  removeexistMedinfo(data) {
    this.patientservice.removePsychotropicMed(data.chmaassessmentId).subscribe((res: any) => {
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  onSaveCmhaAssessment(type) {
   // if (!this.isMovedChma) {
    this.lstCHMAAssessment = [...this.treatmentgoals, ...this.needsofclient, ...this.preliminarygoal, ...this.initialIntervention];
    console.log(this.Severity)
    let _obj = {
      PatientAccountId: this.patientAccountId,
      Severity:this.Severity,
      DtoChmaAssessments:this.lstCHMAAssessment
    }
      //const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
      this.spinnerservice.show();
      this.patientservice.postCmhaAssessment(_obj).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.getChmaAssessment();
          this.onSaveChmaStatus(type);
          this.snackbar.success(JSON.parse(res).message);
          if (type == 1) {
            window.close()
          }
          else if(type != 2) {
            this.chma.onTabChange(6);
          }
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
   // }
  //  else {
  //    this.chma.onTabChange(6);
  //  }
  }
  onSaveChmaStatus(type: any) {

    let _obj = {
      PatientAccountId: this.patientAccountId,
      FormType: 5,
      patientdetails:this.patientDetails,
      Type : type
    }
    // const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.patientservice.postChmaStatus(_obj).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.chma.getChmaStatus();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  onPrevcmhatab() {
    this.chma.onTabChange(4);
  }
  getSeverityMaster(){
    this.spinnerservice.show()
    this.commonService.getSeverityMaster().subscribe(
      res => {
        if (res != null) {
      this.ddlseverity = res;
      console.log(this.ddlseverity)
   this.spinnerservice.hide()
  }},
  err => {
    this.spinnerservice.hide();
  
  })
  }
  onSelectionChanged(value){
    this.bg = this.ddlseverity.filter(x=>x.severityValue==value)[0].colocode;
  }  
    selectGoals(value, i) {
    var ind = this.preliminarygoal.filter(e=>e.information == value.value);
    // const objIndex = this.events.findIndex(obj => obj.meta.user.id == valueExist[i].meta.user.id && moment(obj.start).isSame(moment(valueExist[i].start)) && obj.isnew == true);
    if(ind.length > 1) {
      // this.preliminarygoal[i].information = '';
      this.preliminarygoal.splice(i, 1) ;
      this.snackbar.error('Goal already exist');
      this.preliminarygoal.push({
        chmaassessmentId: 0,
        patientAccountId: this.patientAccountId,
        typeId: 3,
        information: null,
        goalId : null
      })
    }
    else
    {
      var goals = this.lstGoals.filter(x=>x.goalName==value.value)
      this.preliminarygoal[i].goalId = goals[0].goalId;
    }
  }

  

  getPreliminaryGoals(){
    let isAdmin = false;
    this.tempService.getGoals(isAdmin).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
 
          this.lstGoals = res;
        
        } 
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
}

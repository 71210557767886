<div class="screener-doc">
    <div  class="top-btn-section col-md-12 clearfix mb-1 w-100">
      <h2  class="head-section mt-3">Screener Documents</h2>
      <div  class="col-md-5 ml-auto mt-2 pr-0 text-right ">
        <button mat-stroked-button class="bg-white top-back mt-1 mb-2 h-40 mr-2" (click)="OnBack()">Back</button></div>
       
       
       </div>
      </div>
      <div class="col-md-12 d-flex">
        <span  class="filter-icon mr-2 mt-2">
          <i  class="fa fa-filter"></i>
        </span>
        <div class="filter-group d-inline-block">
          <label  class="d-inline-block mr-2">Year</label>
          <select  name="clinician" class="form-control d-inline-block status-filter mr-0 "   [(ngModel)]="SelectedYear" name="SelectedYear" (change)="OnChangeYear()">
           <option *ngFor="let item of ddlyear; let i= index;" [value]="item" >{{item}}</option>
            <!-- <option  value="693" > 2021 </option>
            <option  value="696" > 2020 </option>
            <option value="347"  > 2019 </option>
            <option value="347"  > 2018 </option>
            <option value="347"  > 2017 </option>
            <option value="347"  > 2016 </option> -->
          </select>
        </div>
        <div class="filter-group d-inline-block" *ngIf="!(FormTypeId==2)">
          <label  class="d-inline-block mr-2">Month</label>
          <select  name="clinician" class="form-control d-inline-block status-filter mr-0 "   [(ngModel)]="SelectedMonth" name="SelectedMonth" (change)="OnChangeMonth()">
            <option *ngFor="let item of ddlMonthList; let i= index;"  [disabled]="!item.isActive" [value]="item.value" >{{item.label}}</option>
           </select>
        </div>
        <div class="ml-auto">
          <div class=" custom-toggle-btn ">
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style"  [(ngModel)]="FormTypeId" name="FormTypeId" (change)="OnChangeType()">
              <mat-button-toggle [value]="1">Daily</mat-button-toggle>
              <mat-button-toggle [value]="2">Monthly</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
           
      </div>
      </div>
      <div class="col-md-12 file_manager">
        <div class="row">
          <div class="col-lg-3 col-md-4 col-sm-12" *ngFor="let item of lstDocuments; let i=index;">
            <div class="card">
              <div class="file"> 
                <a (click)="OnDownload(item)">
                  <div class="hover"> 
                    <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                  </div>
                  <div class="file-name">
                    <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>{{item?.documentName}}</p> 
                    <small>Size: 42KB <span class="date text-muted">{{item?.createdDate | date:'mediumDate' }}</span></small>
                  </div> 
                </a>
              </div>
            </div>
          </div>
 
        </div>
      </div>
      <a mimetype="application/octet-stream" #downloadLink></a>
   


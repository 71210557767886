export class Task {    
    taskId: number;
    patientInfoId : number;
    content:string;
    status :string;
    dueDate :string;
    overDate :string;
    flagMeBy :string;
    taskName:string;
    assignedTo:number;
    assignedStaffName:string;
    documentId:number;
    taskPriority:any;
    priority:any;
    documents:any;
    isSelfAssigned:boolean;
    type: number;
    isCompleted:boolean;
}


export class Comment {    
    taskCommentId: number;
    patientInfoId : number;
    taskId : number;
    taskContent:string;
    statusId : number;
    isRead :boolean;
    createDate : Date;
    updateDate : Date;
    createdBy : number;
    updatedBy : number;
}

export class NoteComment {    
    noteCommentId: number;
    patientInfoId : number;
    notesId : number;
    noteContent:string;
    statusId : number;
    isRead :boolean;
    createDate : Date;
    updateDate : Date;
    createdBy : number;
    updatedBy : number;
}
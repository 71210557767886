import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { Blocks } from 'src/app/_models/_Common/_blocks';

import { NgForm } from '@angular/forms';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { cloneDeep } from "lodash";
import * as moment from 'moment';
@Component({
  selector: 'app-meeting-blocks',
  templateUrl: './meeting-blocks.component.html',
  styleUrls: ['./meeting-blocks.component.scss']
})
export class MeetingBlocksComponent implements OnInit {

  _dtoBlocks:Blocks;
  ddlmeetingblocks:any=[];
  ddlblockreason:any=[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  lstmeetingblocks:any=[];
  displayedColumns = ['sno','blockType', 'blockTypeName', 'description', 'IsActive', 'actions'];
  dataSource: MatTableDataSource<any>;
  programdays = [
    { key: '1', text: 'Sunday' },
    { key: '2', text: 'Monday' },
    { key: '3', text: 'Tuesday' },
    { key: '4', text: 'Wednesday' },
    { key: '5', text: 'Thursday' },
    { key: '6', text: 'Friday' },
    { key: '7', text: 'Saturday' },

  ];
  prgdays: any = [];
  editblocks:boolean=false;
  constructor(public global: Global, public settingsService: SettingsService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,) { }

  ngOnInit(): void {
    this._dtoBlocks = new Blocks();
    this.getBlocksMaster();
    this.getMeetingBlocks();
  }
  getBlocksMaster() {
    this.settingsService.getBlocksMaster().subscribe(
      res => {
        this.ddlmeetingblocks = res.blockType;
        this.ddlblockreason = res.blockReason;

      },
      error =>
        this.spinnerservice.hide()
    );
  }
  onWeekDaySelection(event, day) {
    if (event == true) {
      this._dtoBlocks.availableDays.push(day)
    } else {
      let index = this._dtoBlocks.availableDays.findIndex(d => d === day);
      this._dtoBlocks.availableDays.splice(index, 1);
    }

  }
  addappointmentTypeName(data: string) {
    this.postTypeName(data);

  }
  blockReasonTypeIdTag(tag: string) {

    return tag;
  }
  postTypeName(data: string) {
    if (data != null && this.ddlblockreason.filter(x => x.blockTypeName == data).length == 0) {
      let _obj = {
        BlockReasonTypeId: 0,
        ReasonTypeName: data,
        IsActive: true
      }

      const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
      this.settingsService.postBlockReasonTypeName(dataToEncrypt).subscribe(
        (res: any) => {
          this.ddlblockreason = JSON.parse(res);
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }
  }

  resetblocks(){
    this._dtoBlocks = new Blocks();
    this.getMeetingBlocks();
    this.editblocks=false;
  }
  postMeetingBlock(frmblock: NgForm){
    if (!frmblock.valid) {
      return
    }
    this._dtoBlocks.availableDays = this.prgdays.map((item) => { return item }).join(',');
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoBlocks, false);
    this.settingsService.postMeetingBlocks(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(res);
        this.getMeetingBlocks();
        frmblock.resetForm();
     
        this.resetblocks();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getMeetingBlocks(){
    this.settingsService.getMeetingBlocks().subscribe(
      res => {
       // this.ddlmeetingblocks = res;
       this.spinnerservice.hide();
       this.lstmeetingblocks = res;
       
       this.dataSource = new MatTableDataSource(this.lstmeetingblocks);
       this.dataSource.paginator = this.paginator;
       this.dataSource.sort = this.sort;

      },
      error =>
        this.spinnerservice.hide()
    );
  }
  oneditMeetingBlocks(element) {
    this.editblocks=true;
    this._dtoBlocks = element;
    this.prgdays = element.availableDays.split(',');
  }
  ondeleteMeetingBlocks(data) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.settingsService.deletemeetingblock(data.meetingBlockId).subscribe(
          (res: any) => {
            this.getMeetingBlocks();
            this.spinnerservice.hide();
            this.snackbar.success(res.message);
          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },
        );
      }
    });

  }
}

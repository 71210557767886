import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { Global } from 'src/app/global/global.model';
import { ClinicianService } from '../../clinician.service';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { currentDatas } from 'src/app/_models/_Treatment/_TherapyPopUpObj';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PatientService } from 'src/app/patient/patient.service';
import { PsychiatricMedication } from 'src/app/_models/_Treatment/_medication';

@Component({
  selector: 'app-medication-pdfforchildren',
  templateUrl: './medication-pdfforchildren.component.html',
  styleUrls: ['./medication-pdfforchildren.component.scss']
})
export class MedicationPdfforchildrenComponent implements OnInit {
  patientName: any;
  currentDate: any;
  text: string = '';
  Reason: string = '';
  Content: any;
  isformtype: string;

  isESignAttached: boolean;
  updatedsignatureImg: any;
  isAttachEsign: boolean;
  defaultImage: any;
  userid: any;

  pstartDate: any;
  pstartDatePhysician: any;
  pstartDateParent: any;
  pstartDatePatient: any;
  pstartDates: any;
  Meetingscheduleid: any;
  data: any;
  clientData: currentDatas = new currentDatas();
  isReview: boolean = false;
  isSubmitted: boolean = false;
  medicationForm: FormGroup;
  StartDate: any;
  StartTime: any;
  _dtoMedications: PsychiatricMedication[] = [];
  patientInfoId: any;
  @ViewChild('downloadLink') downloadLink!: ElementRef;

  constructor(private router: Router, private route: ActivatedRoute, public commonService: CommonService,
    public global: Global, private dialog: MatDialog, public spinnerservice: NgxSpinnerService, public clinicianservice: ClinicianService,
    public datepipe: DatePipe, public domSrv: DomSanitizer, private snackbar: SnackBarService, private fb: FormBuilder, public patientservice: PatientService) { }

  ngOnInit(): void {
    this.medicationForm = this.fb.group({
      patientName: ['', Validators.required],
      currentDate: [''],
      firstMedication: [''],
      firstReason: [''],
      secondMedication: [''],
      secondReason: [''],
      thirdMedication: [''],
      thirdReason: [''],
      signatureImgPatient: [''],
      currentDatePatient: [null],
      signatureImgParent: [''],
      currentDateParent: [null],
      parentNotAvailable: [false],
      signatureImgPhysician: [''],
      currentDatePhysician: [null],
      userid: [''],
      Meetingscheduleid: [''],
      medicationDetails: this.fb.array([])
    });
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientName = this.commonService.FrontEndDecryption(params.patientName).replace(/"/g, "");
        this.userid = Number(this.commonService.FrontEndDecryption(params.clinicianId).replace(/"/g, ""));
        this.Meetingscheduleid = Number(this.commonService.FrontEndDecryption(params.Meetingscheduleid).replace(/"/g, ""));
        this.isReview = this.commonService.FrontEndDecryption(params.isReview).replace(/"/g, "")
      }
      this.medicationForm.controls["patientName"].setValue(this.patientName)
      this.medicationForm.controls["userid"].setValue(this.userid)
      this.medicationForm.controls["Meetingscheduleid"].setValue(this.Meetingscheduleid)
      this.currentDate = new Date();
      this.medicationForm.controls["currentDatePatient"].setValue(this.currentDate);
      this.medicationForm.controls["currentDateParent"].setValue(this.currentDate);
      this.medicationForm.controls["currentDatePhysician"].setValue(this.currentDate);
      this.getpdfdata(this.Meetingscheduleid);
      this._dtoMedications.forEach(med => this.addMedicationInfo(med));

    });
  }

  autoResize(textarea: HTMLTextAreaElement): void {
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }
  openDialog() {
    this.global.isconsentesign = false;

    const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
      {
        data: { parentinfoId: this.userid, isfutureSign: false, isParentsign: true },
        autoFocus: false
      });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign) {
        this.medicationForm.controls["signatureImgPhysician"].setValue(this.updatedsignatureImg)
        this.isAttachEsign = true;

      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.medicationForm.controls["signatureImgPhysician"].setValue(this.global.consentesign)
        this.isformtype = this.global.formtype;
        this.isAttachEsign = true;

      }
      else {
        this.medicationForm.controls["signatureImgPhysician"].setValue(this.defaultImage)
      }
      (document.getElementById('esign') as HTMLImageElement).src = this.medicationForm.controls["signatureImgPhysician"].value;
    });
  }
  openDialogParent() {
    this.global.isconsentesign = false;

    const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
      {
        data: { parentinfoId: this.userid, isfutureSign: false, isParentsign: true },
        autoFocus: false
      });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign) {
        this.medicationForm.controls["signatureImgParent"].setValue(this.updatedsignatureImg)
        this.isAttachEsign = true;

      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.medicationForm.controls["signatureImgParent"].setValue(this.global.consentesign)
        this.isformtype = this.global.formtype;
        this.isAttachEsign = true;

      }
      else {
        this.medicationForm.controls["signatureImgParent"].setValue(this.defaultImage)
      }
      (document.getElementById('esignParent') as HTMLImageElement).src = this.medicationForm.controls["signatureImgParent"].value;
    });
  }
  openDialogPatient() {
    this.global.isconsentesign = false;

    const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
      {
        data: { parentinfoId: this.userid, isfutureSign: false, isParentsign: true },
        autoFocus: false
      });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign) {
        this.medicationForm.controls["signatureImgPatient"].setValue(this.updatedsignatureImg)
        this.isAttachEsign = true;

      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.medicationForm.controls["signatureImgPatient"].setValue(this.global.consentesign)
        this.isformtype = this.global.formtype;
        this.isAttachEsign = true;

      }
      else {
        this.medicationForm.controls["signatureImgPatient"].setValue(this.defaultImage)
      }
      (document.getElementById('esignPatient') as HTMLImageElement).src = this.medicationForm.controls["signatureImgPatient"].value;
    });
  }

  saveconsent() {
    this.isSubmitted = true;
    this.spinnerservice.show();
    console.log(this.medicationForm.value)
    this.clinicianservice.saveconsentMedication(this.medicationForm.value).subscribe(
      (res: any) => {
        if (res) {
          this.snackbar.success("Data saved successfully");
          this.getpdfdata(this.Meetingscheduleid);
        }
        this.spinnerservice.hide();
      }
    );
    this.spinnerservice.hide();
  }
  getpdfdata(Meetingscheduleid) {
    this.spinnerservice.show();
    this.clinicianservice.getpdfdataconsent(Meetingscheduleid).subscribe(
      (res: any) => {
        if (res.objInputString != null) {
          var fres = this.commonService.decrypdata(res.objInputString);

          var clientData = fres;
          this.StartDate = clientData.StartDate;
          this.StartTime = clientData.StartTime;
          var date1 = new Date(clientData.CurrentDate);
          date1.setHours(1, 0, 0, 0);
          const dateObject = new Date(date1 + 'Z');

          const formattedDate = this.formatDate(dateObject);
          var currentDate = formattedDate ? formattedDate : currentDate
          console.log(clientData.signatureImgPhysician);
          this.medicationForm.controls["currentDate"].setValue(clientData.currentDate);
          (document.getElementById('esignPatient') as HTMLImageElement).src = clientData.signatureImgPatient;
          this.medicationForm.controls["signatureImgPatient"].setValue((document.getElementById('esignPatient') as HTMLImageElement).src);

          (document.getElementById('esignParent') as HTMLImageElement).src = clientData.signatureImgParent;
          this.medicationForm.controls["signatureImgParent"].setValue((document.getElementById('esignParent') as HTMLImageElement).src);

          (document.getElementById('esign') as HTMLImageElement).src = clientData.signatureImgPhysician;
          this.medicationForm.controls["signatureImgPhysician"].setValue((document.getElementById('esign') as HTMLImageElement).src);
          if (clientData.currentDatePatient != null) {
            this.medicationForm.controls["currentDatePatient"].setValue(clientData.currentDatePatient);
          } else {
            this.medicationForm.controls["currentDatePatient"].setValue(this.currentDate);
          }
          if (clientData.currentDateParent != null) {
            this.medicationForm.controls["currentDateParent"].setValue(clientData.currentDateParent);
          }
          else {
            this.medicationForm.controls["currentDateParent"].setValue(this.currentDate);
          }
          if (clientData.currentDatePhysician != null) {
            this.medicationForm.controls["currentDatePhysician"].setValue(clientData.currentDatePhysician);
          }
          else {
            this.medicationForm.controls["currentDatePhysician"].setValue(this.currentDate);
          }
          this.medicationForm.controls["firstMedication"].setValue(clientData.firstMedication);
          this.medicationForm.controls["firstReason"].setValue(clientData.firstReason);
          this.medicationForm.controls["secondMedication"].setValue(clientData.secondMedication);
          this.medicationForm.controls["secondReason"].setValue(clientData.secondReason);
          this.medicationForm.controls["thirdMedication"].setValue(clientData.thirdMedication);
          this.medicationForm.controls["thirdReason"].setValue(clientData.thirdReason);
          this.medicationForm.controls["parentNotAvailable"].setValue(clientData.ParentNotAvailable);
          const medicationArray = this.fb.array(
            clientData.medicationDetails.map(med => this.fb.group({
              PsychiatricConsentMedicationId: [med.PsychiatricConsentMedicationId],
              medications: [med.Medications || ''],
              dosage: [med.Dosage || ''],
              frequency: [med.Frequency ? med.Frequency.toString() : null], // Convert to string
              sideEffects: [med.SideEffects || '']
            }))
          );
          this.medicationForm.setControl('medicationDetails', medicationArray);
        }
      }
    );
    this.spinnerservice.hide();
  }
  formatDate(date: Date) {
    return date;
  }

  onGeneratePDF() {

    this.spinnerservice.show();
    this.clinicianservice.onGenerateMedicationPDFconsent(this.Meetingscheduleid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();

        const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }


  addMedicationInfo(existingMed?: PsychiatricMedication) {
    const med = existingMed || new PsychiatricMedication();
    med.MeetingScheduleId = this.Meetingscheduleid;
    const medicationGroup = this.fb.group({
      MeetingScheduleId: [this.Meetingscheduleid],
      PsychiatricConsentMedicationId: [existingMed?.PsychiatricConsentMedicationId || 0],
      medications: [''],
      dosage: [''],
      frequency: [undefined],
      sideEffects: ['']

    });
    this.medicationDetails.push(medicationGroup)
    if (existingMed) {
      this._dtoMedications.push(existingMed);
    }
  }

  removeclinicalinformation(med: FormGroup, index: number) {
    const PsychiatricConsentMedicationId = med.get('PsychiatricConsentMedicationId')?.value;
    if (PsychiatricConsentMedicationId !== 0) {
      const psychiatricMedication: PsychiatricMedication = {
        PsychiatricConsentMedicationId: PsychiatricConsentMedicationId,
        MeetingScheduleId: med.get('MeetingScheduleId')?.value,
        medications: med.get('medications')?.value || [],
        dosage: med.get('dosage')?.value || '',
        frequency: med.get('frequency')?.value || '',
        sideEffects: med.get('sideEffects')?.value || [],
      };
      this.removeexistMedinfo(PsychiatricConsentMedicationId);
    } else {
      this._dtoMedications.splice(index, 1);
      this.medicationDetails.removeAt(index);
    }
  }
  removeexistMedinfo(PsychiatricConsentMedicationId) {

    this.spinnerservice.show();
    this.clinicianservice.removeConsentMedication(PsychiatricConsentMedicationId).subscribe(
      (res: any) => {
        console.log(res);
        this.spinnerservice.hide();
        this.getpdfdata(this.Meetingscheduleid);
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  get medicationDetails(): FormArray {
    return this.medicationForm.get('medicationDetails') as FormArray;
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { Appoinment } from 'src/app/_models/_Common/_Appointment';
import { AppointmentFrequency } from 'src/app/_models/_Common/_Appointment';
import { NgForm } from '@angular/forms';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { cloneDeep } from "lodash";
import * as moment from 'moment';
import { CatalogueService } from 'src/app/common/pages/price-catalogue/catalogue.service';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';

@Component({
  selector: 'app-meeting-appointment',
  templateUrl: './meeting-appointment.component.html',
  styleUrls: ['./meeting-appointment.component.scss']
})
export class MeetingAppointmentComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['sno', 'categoryName', 'typeName', 'startTime', 'endTime', 'timePerSlot','minPerSlot', 'IsActive', 'actions'];
  dataSource: MatTableDataSource<any>;
  programdays = [
    { key: '0', text: 'Sunday' },
    { key: '1', text: 'Monday' },
    { key: '2', text: 'Tuesday' },
    { key: '3', text: 'Wednesday' },
    { key: '4', text: 'Thursday' },
    { key: '5', text: 'Friday' },
    { key: '6', text: 'Saturday' },

  ];
  prgdays: any = [];
  isEditAppoinment: boolean;
  _DtoAppoinment: Appoinment;
  ddlmeetingblocks:any=[];
  ddlappointmenttype:any=[];
  ddlblockreason:any=[];
  appoinmentlst: any;
  ddlCategory: any;
  ddlappoinments: any;
  meetingTimeslots: any;
  isValidDate: any;
  isReqWeekly: boolean = false;
  appointmentfrequency = [];
  timeslots = [];
  isGlobalAccess: boolean = false;
  ddllocation: any;
  onpostmeetingappointment: boolean;
  drpClinician:any;
  roleAccess: any;
  minDate:any;
  lastDate : any;
  constructor(public global: Global, public settingsService: SettingsService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public CatlogueService: CatalogueService
    ,public roleaccessservice:PageAccessService
    ) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Settings;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this._DtoAppoinment = new Appoinment();
    this._DtoAppoinment.appointmentFrequencies = new Array<AppointmentFrequency>();
    this.minDate = new Date();
    this.getMeetingCategories();
    this.getTimeSlots();
    this.getMeetingAppointment();
    this.getAppointmentTypeNames();
    this.getddlLocation();
    this.getBlocksMaster();

   // const secondMondayInRange = this.findSecondOccurrencesInRange(startDate, endDate);
   const startDate = new Date('2023-01-01'); // Replace with your start date
    const endDate = new Date('2023-01-31');   // Replace with your end date

    

   // const firstMonday = this.getSpecificWeekday(startDate, endDate, 2, 1);

    // this.getClinicians();
  }

  findSecondOccurrencesInRange(startDate: Date, endDate: Date): Record<string, Date | null> {
    const daysOfWeek = ['Saturday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const secondOccurrences: Record<string, Date | null> = {};

    for (const dayOfWeek of daysOfWeek) {
      let currentDate = new Date(startDate);
      let occurrencesCount = 0;

      // Iterate through the weeks in the date range
      while (currentDate <= endDate) {
        const currentDayOfWeek = currentDate.getDay();
        if (currentDayOfWeek === this.getDayOfWeekIndex(dayOfWeek)) {
          occurrencesCount++;

          // Check if it's the second occurrence
          if (occurrencesCount === 2) {
            secondOccurrences[dayOfWeek] = new Date(currentDate);
            break;
          }
        }

        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Reset occurrences count for the next day of the week
      occurrencesCount = 0;
    }

    return secondOccurrences;
  }

  private getDayOfWeekIndex(dayOfWeek: string): number {
    const daysOfWeekMapping = {
      'Sunday': 0,
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6,
    };

    return daysOfWeekMapping[dayOfWeek];
  }

  getBlocksMaster() {
    this.settingsService.getBlocksMaster().subscribe(
      res => {
      //  console.log(res)
        this.ddlmeetingblocks = res.blockType;
        this.ddlblockreason = res.blockReason;
        this.ddlappointmenttype = res.dtoAppointmentType;

      },
      error =>
        this.spinnerservice.hide()
    );
  }
  pushAppoinmentFreq() {
    for (let i = 0; i < this.programdays.length; i++) {
      if (this.appointmentfrequency && this.appointmentfrequency.length > 0) {
        var _objFreq = this.appointmentfrequency.filter(x => x.dayId == Number(this.programdays[i].key))[0]
        if (_objFreq) {
          this._DtoAppoinment.appointmentFrequencies.push(new AppointmentFrequency);
          _objFreq.isChecked = true;
          this._DtoAppoinment.appointmentFrequencies[i] = _objFreq;
          this._DtoAppoinment.appointmentFrequencies[i].daylabel = this.programdays[i].text;
          continue
        } else {

        }
      }
      this._DtoAppoinment.appointmentFrequencies.push(new AppointmentFrequency);
      this._DtoAppoinment.appointmentFrequencies[i].dayId = Number(this.programdays[i].key);
      this._DtoAppoinment.appointmentFrequencies[i].daylabel = this.programdays[i].text;
      this._DtoAppoinment.appointmentFrequencies[i].isChecked = false;
    }
  }



  getMinutesSlot(timeSlot)
  {
    if(timeSlot == 0)
    {
      return 0;
    }
    else if(timeSlot == 30)
    {
      return 0;
    }
    else if(timeSlot == 60)
    {
      return 1;
    }
    else if(timeSlot == 120)
    {
      return 2;
    }
    else if(timeSlot == 180)
    {
      return 3;
    }
    else if(timeSlot == 240)
    {
      return 4;
    }
    else if(timeSlot == 300)
    {
      return 5;
    }
    else if(timeSlot == 360)
    {
      return 6;
    }
    else if(timeSlot == 420)
    {
      return 7;
    }
    else if(timeSlot == 480)
    {
      return 8;
    }
  }

  getddlLocation() {
    this.spinnerservice.show();
    this.CatlogueService.getLocations().subscribe(
      res => {
        if (res != null) {
          this.ddllocation = res;
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  onChangeFrequency(type) {
    if (type == 2 && this._DtoAppoinment.appointmentFrequencies.length==0) {
      this.pushAppoinmentFreq();
    }
  }

  //#region Appointment Settings
  getMeetingCategories() {
    this.settingsService.getMeetingCategories().subscribe(
      res => {
        this.ddlCategory = res.meetingCategory;

      },
      error =>
        this.spinnerservice.hide()
    );
  }
  getAppointmentTypeNames() {
    this.settingsService.getAppointmentTypeNames().subscribe(
      res => {
        this.ddlappoinments = res;
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  getTimeSlots() {
    this.settingsService.getTimeSlots().subscribe(
      res => {
        if (res != null) {
          this.meetingTimeslots = res;


        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getMeetingAppointment() {
    //this.spinnerservice.show()
    this.settingsService.getAppointmentTypes().subscribe(
      res => {
        this.spinnerservice.hide();
        this.appoinmentlst = res;        
        this.dataSource = new MatTableDataSource(this.appoinmentlst);
        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error =>
        this.spinnerservice.hide()
    );
  }
  postMeetingAppointment(frmAppointment: NgForm) {
    this.onpostmeetingappointment = true
    this.isReqWeekly = false;
    if (!frmAppointment.valid) {
      this.onpostmeetingappointment = false
      return
    }
    if (this._DtoAppoinment.typeName == null  || this._DtoAppoinment.typeName == '' ) {
      this.onpostmeetingappointment = false;
      return;
    }
    
    if (this._DtoAppoinment.frequency == 2) {
      this._DtoAppoinment.startTime = this._DtoAppoinment.endTime = null
      var reqFrequrency = this._DtoAppoinment.appointmentFrequencies.filter(x => x.isChecked == true)
      if (reqFrequrency && reqFrequrency.length == 0) {
        this.isReqWeekly = true;
        this.onpostmeetingappointment = false
        return

      }

    }

    if (this._DtoAppoinment.accessLevel == 1) {
      this._DtoAppoinment.locationId = '';
      this._DtoAppoinment.location='';
      this._DtoAppoinment.accessLevel = 1;
    } else {
      this._DtoAppoinment.accessLevel = 0;
      if (this._DtoAppoinment.locationId && this._DtoAppoinment.locationId.length > 0) {
        this._DtoAppoinment.location = this._DtoAppoinment.locationId.toString();
      }
    }
    if(this._DtoAppoinment.typeId == 2){
    if (this._DtoAppoinment.clinicianId && this._DtoAppoinment.clinicianId.length > 0) {
      this._DtoAppoinment.clinicianId = this._DtoAppoinment.clinicianId.toString();
    }
  }
   this._DtoAppoinment.duration = Number(this._DtoAppoinment.durationhr) + Number(this._DtoAppoinment.durationmins);
    const dataToEncrypt = this.commonService.DataToEncrypt(this._DtoAppoinment, false);
    this.spinnerservice.show();
    this.settingsService.postMeetingAppoinment(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(res);
        this.getMeetingAppointment();
        frmAppointment.resetForm();
        this.resetAppointment(frmAppointment);
        this.onpostmeetingappointment = false;
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
        this.onpostmeetingappointment = false;
      },
    );


  }

  onchangeAccesslvl(event) {
    if (event.checked) {
      //this.priceCatalogue.accessLevel = 0;
      //this.priceCatalogue.location = null;
      this.isGlobalAccess = true;
      this.getClinicians("-1");

    } else {
      //this.priceCatalogue.accessLevel = 1;
      this.isGlobalAccess = false;
      this.getClinicians(this._DtoAppoinment.locationId);
    }
  }

  resetAppointment(form:NgForm) {
    form.resetForm();
    this._DtoAppoinment = new Appoinment();
    this._DtoAppoinment.appointmentFrequencies = new Array<AppointmentFrequency>();
    this.isEditAppoinment = false;
    this.isReqWeekly = false;
    
  }
  checkGroupMeetingExist()
  {
    if(this._DtoAppoinment.appointmentTypesId)
    {
      this.spinnerservice.show();
      this.settingsService.checkGroupMeetingExist(this._DtoAppoinment.appointmentTypesId).subscribe(
        res=>{
          this.spinnerservice.hide();
          if(res.count > 0)
          {
           
            this.snackbar.error(constantVariables.ErrorMessage.GroupMeetingTimeChange);
            this._DtoAppoinment.startDate = this.lastDate;
          }
  
      })
    }
  }
  oneditAppointment(element) {
    if(element.typeName == 'Intake Schedule')
    {
      this.isEditAppoinment = true;
    }
    else if(element.typeName == 'Tour')
    {
      this.isEditAppoinment = true;
    }
    else
    {
      this.isEditAppoinment = false;
    }
    this.spinnerservice.show();
    this.settingsService.getAppointmentTypeDetails(element.appointmentTypesId).subscribe(
      res => {
        this.spinnerservice.hide();
        this._DtoAppoinment = cloneDeep(res);
        const num = this._DtoAppoinment.duration /60; 
        this._DtoAppoinment.durationhr = (Math.floor(num)*60).toString();
        this._DtoAppoinment.durationmins =  ((num - Math.floor(num))*60).toString();
        //console.log(this._DtoAppoinment)
        if(this._DtoAppoinment.typeId == 2 || this._DtoAppoinment.typeId == 5 || this._DtoAppoinment.typeId == 6)
        {
          this._DtoAppoinment.startTime = moment(res.startTime).format('HH:mm');
        }
        if(this._DtoAppoinment.typeId == 2)
        {
          this.lastDate = this._DtoAppoinment.startDate;
          this.minDate = this._DtoAppoinment.startDate;
        }
        this._DtoAppoinment.locationId = this._DtoAppoinment.location.split(',').map(Number);
        if(this._DtoAppoinment.accessLevel==1)
        {
          this.getClinicians(-1);
        }
        else
        {
        this.getClinicians(this._DtoAppoinment.locationId);
        }
        this.loadAccessLevel();
        this._DtoAppoinment.appointmentFrequencies = new Array<AppointmentFrequency>();
        this.appointmentfrequency = res.appointmentFrequencies;
        this.pushAppoinmentFreq();        
       // debugger;
        var isMaster = this.ddlappoinments.filter(x => x.appointmentTypeName == this._DtoAppoinment.typeName && x.isMaster == true)
        if(isMaster.length > 0)
        {
          this.isEditAppoinment = true;
        }
        else
        {
          this.isEditAppoinment = false;
        }
       if(this._DtoAppoinment.typeId == 2 && (this._DtoAppoinment.clinicianId != null && this._DtoAppoinment.clinicianId != "" &&this._DtoAppoinment.clinicianId != "undefined"))
        { 
          this._DtoAppoinment.clinicianId = this._DtoAppoinment.clinicianId.split(',').map(Number);
        }
      },
      error =>
        this.spinnerservice.hide()
    );
  }

  loadAccessLevel() {
    if (this._DtoAppoinment.accessLevel == 0) {
      //this.priceCatalogue.location = this.priceCatalogue.accessLevel;
      this._DtoAppoinment.accessLevel = 0;
      this.isGlobalAccess = false;
    } else {
      this._DtoAppoinment.location = null;
      this._DtoAppoinment.accessLevel = 1;
      this.isGlobalAccess = true;
    }
  }

  addappointmentTypeName(data: string) {
    this.postTypeName(data);

  }
  addAppointmentnameTag(tag: string) {

    return tag;
  }


  postblockReasonTypeName(data: string) {
    if (data != null && this.ddlblockreason.filter(x => x.blockTypeName == data).length == 0) {
      let _obj = {
        BlockReasonTypeId: 0,
        ReasonTypeName: data,
        IsActive: true
      }

      const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
      this.settingsService.postBlockReasonTypeName(dataToEncrypt).subscribe(
        (res: any) => {
          this.ddlblockreason = JSON.parse(res);
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }
  }

  blockReasonTypeIdTag(tag: string) {

    return tag;
  }

  postTypeName(data: string) {
    if (data != null && this.ddlmeetingblocks.filter(x => x.appointmentTypeName == data).length == 0) {
      // let _obj = {
      //   AppointmentTypeNameId: 0,
      //   AppointmentTypeName: data,
      //   IsActive: true
      // }

      let _obj = {
        BlockTypeId: 0,
        BlockTypeName: data,
        IsActive: true
      }

      const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
      this.settingsService.postBlockTypeName(dataToEncrypt).subscribe(
        (res: any) => {
          //this.ddlappoinments = JSON.parse(res);
          this.ddlmeetingblocks = JSON.parse(res);
          
        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }
  }

  locationchange(event){
    this.isGlobalAccess = false;
    this._DtoAppoinment.accessLevel = undefined;

    
  }
  loadClinician(event)
  {   
    this.getClinicians(this._DtoAppoinment.locationId);
  }
  ondeleteAppointment(data) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.settingsService.deleteAppointment(data.appointmentTypesId).subscribe(
          (res: any) => {
            this.getMeetingAppointment();
            this.spinnerservice.hide();
            this.snackbar.success(constantVariables.SuccessMessage.DeletetedSuccesscully);
          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },
        );
      }
    });

  }
  
  generateTimeSlots(sDate, eDate, time) {
    if (sDate != null && sDate != undefined && eDate != null && eDate != undefined) {
      var start = moment(sDate);
      var end = moment(eDate);
      // let diffMs = end.getTime() - start.getTime(); // milliseconds
      // let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      let diffMins = end.diff(start, 'minutes')
      if (time > diffMins) {
        return true
      } else {
        return false
      }
    }
  }

  generateTimeSlotsmins(sDate, eDate, timehr, time){
    if (sDate != null && sDate != undefined && eDate != null && eDate != undefined) {
      var start = moment(sDate);
      var end = moment(eDate);
      // let diffMs = end.getTime() - start.getTime(); // milliseconds
      // let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      let diffMins = end.diff(start, 'minutes')
      let diffMinsTimeHr = diffMins - timehr
      if (time > diffMinsTimeHr) {
        return true
      } else {
        return false
      }
    }

  }
  getWeekdays(item){
    var array2 = item.availableDays.split(',');
    const intersection = this.programdays.filter(x => array2.includes(x.key));
    var stringlst = intersection.map(({ text }) => text.slice(0, 3)).join(', ').toString();
    return stringlst
  }

  onSetClinician(item){
    //this.router.navigate(['/assign-clinician'], { queryParams: { id: this.commonService.FrontEndEncryption(69),theraphyid: this.commonService.FrontEndEncryption(3)
   //} }); 
   this.router.navigate(['/assign-clinician'], { queryParams: { id: this.commonService.FrontEndEncryption(item.appointmentTypesId),theraphyid: this.commonService.FrontEndEncryption(3),
    clinicians:this.commonService.FrontEndEncryption(item.clinicianId)
   } }); 
  }
  getClinicians(location) {
    var locationString = '';
    if(location != "-1")
    {
      location.map((x,index)=>
      {
        if (index == 0) {
          locationString = x.toString()
        } else {
          locationString = locationString + ',';
          locationString = locationString + x
        }
      })
    }
    else{
      locationString = location;
    }
    
    this.commonService.getStaffListMultipleClinician(locationString).subscribe((res: any) => {
        if (res) {
          this.drpClinician = res;
        }
      });
  }

}


import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { BillingService } from 'src/app/billing/billing.service';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { TransactionCodeComponent } from 'src/app/billing/billing-masters/transaction-code/transaction-code.component';


@Component({
  selector: 'app-billing-masters',
  templateUrl: './billing-masters.component.html',
  styleUrls: ['./billing-masters.component.scss']
})

export class BillingMastersComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

 
  displayedColumns1: string[] = ['sno', 'billgroup', 'action'];
  dataSource1 = new MatTableDataSource<PeriodicElement1>(ELEMENT_DATA1);
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(TransactionCodeComponent) private transactionCodeComponent: TransactionCodeComponent;
  // dataSource: MatTableDataSource<any>;
  constructor(
    public billingservice: BillingService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, private route: ActivatedRoute,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService,public datepipe: DatePipe
  ) { }
  
  selectedTabIndex:number=1;
  CPT: any=0;
  ngOnInit(): void {
  }
  OnCPTclick(item){
    if(item==1){
      this.CPT=1
    }
    else{
      this.CPT=2
    }
  }
  
  onTabChange(tab) {
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: tab
      }
    });
    this.selectedTabIndex = tab;
   
    
  }


}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
export interface PeriodicElement1 {
  sno: number;
  billgroup: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: '112344', weight: 10079, symbol: 'H'},
  {position: 2, name: '112344', weight: 40026, symbol: 'He'},
  {position: 3, name: '112344', weight: 6941, symbol: 'Li'},
  {position: 4, name: '112344', weight: 90122, symbol: 'Be'},
  {position: 5, name: '112344', weight: 10811, symbol: 'B'},
  {position: 6, name: '112344', weight: 120107, symbol: 'C'}
];
const ELEMENT_DATA1: PeriodicElement1[] = [
  {sno: 1, billgroup: 'new', },
  {sno: 2, billgroup: 'new1',  },
  {sno: 3, billgroup: 'new2', },
];
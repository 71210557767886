<div class="lead-section">
    <div class="top-btn-section clearfix">
      <h2 class="head-section mb-2 mt-0">Audit Settings</h2>
    </div>
    <div class="lead-box">
        <form (ngSubmit)="AuditSettings.form.valid" id="AuditSettings" autocomplete="off"  #AuditSettings="ngForm" novalidate>
        <div class="col-md-12 p-0 mt-4">
          <div>Overdue days for audit : </div>
            <div class="row">
                <!-- <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Profile Submission</mat-label>
                        <mat-select  [(ngModel)]="dtoAuditmaster.profileSubmission" #profileSubmission="ngModel" name="profileSubmission"
                        [ngClass]="{ 'is-invalid': AuditSettings.submitted && profileSubmission.invalid }" required >
                        <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuditSettings.submitted && profileSubmission.invalid">
                          <mat-error *ngIf="profileSubmission.errors.required">Profile Submission is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>
                
                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Update Benefits</mat-label>
                        <mat-select  [(ngModel)]="dtoAuditmaster.updateBenefits" #updateBenefits="ngModel" name="updateBenefits"
                        [ngClass]="{ 'is-invalid': AuditSettings.submitted && updateBenefits.invalid }" required >
                        <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuditSettings.submitted && updateBenefits.invalid">
                          <mat-error *ngIf="updateBenefits.errors.required">Update Benefits is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>

                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Financial Proposal</mat-label>
                        <mat-select  [(ngModel)]="dtoAuditmaster.financialProposal" #financialProposal="ngModel" name="financialProposal"
                        [ngClass]="{ 'is-invalid': AuditSettings.submitted && financialProposal.invalid }" required >
                        <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuditSettings.submitted && financialProposal.invalid">
                          <mat-error *ngIf="financialProposal.errors.required">Financial Proposal is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>

                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Schedule Intake</mat-label>
                        <mat-select  [(ngModel)]="dtoAuditmaster.scheduleIntake" #scheduleIntake="ngModel" name="scheduleIntake"
                        [ngClass]="{ 'is-invalid': AuditSettings.submitted && scheduleIntake.invalid }" required >
                        <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuditSettings.submitted && scheduleIntake.invalid">
                          <mat-error *ngIf="scheduleIntake.errors.required">Schedule Intake is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div> -->

                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Intake Packet</mat-label>
                        <mat-select  [(ngModel)]="dtoAuditmaster.intakePacket" #intakePacket="ngModel" name="intakePacket"
                        [ngClass]="{ 'is-invalid': AuditSettings.submitted && intakePacket.invalid }" required >
                        <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuditSettings.submitted && intakePacket.invalid">
                          <mat-error *ngIf="intakePacket.errors.required">Intake Packet is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>

             

                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>CMHA</mat-label>
                        <mat-select  [(ngModel)]="dtoAuditmaster.cmha" #cmha="ngModel" name="cmha"
                        [ngClass]="{ 'is-invalid': AuditSettings.submitted && cmha.invalid }" required >
                        <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuditSettings.submitted && cmha.invalid">
                          <mat-error *ngIf="cmha.errors.required">CMHA is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>

                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Pain Assesment</mat-label>
                        <mat-select  [(ngModel)]="dtoAuditmaster.painAssesment" #painAssesment="ngModel" name="painAssesment"
                        [ngClass]="{ 'is-invalid': AuditSettings.submitted && painAssesment.invalid }" required >
                        <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuditSettings.submitted && painAssesment.invalid">
                          <mat-error *ngIf="painAssesment.errors.required">Pain Assesment is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>

                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Nutritional Questionnaire</mat-label>
                        <mat-select  [(ngModel)]="dtoAuditmaster.nutritionalQuestion" #nutritionalQuestion="ngModel" name="nutritionalQuestion"
                        [ngClass]="{ 'is-invalid': AuditSettings.submitted && nutritionalQuestion.invalid }" required >
                        <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuditSettings.submitted && nutritionalQuestion.invalid">
                          <mat-error *ngIf="nutritionalQuestion.errors.required">Nutritional Questionnaire is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>

                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Suicidical Risk</mat-label>
                        <mat-select  [(ngModel)]="dtoAuditmaster.sucidicalRisk" #sucidicalRisk="ngModel" name="sucidicalRisk"
                        [ngClass]="{ 'is-invalid': AuditSettings.submitted && sucidicalRisk.invalid }" required >
                        <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuditSettings.submitted && sucidicalRisk.invalid">
                          <mat-error *ngIf="sucidicalRisk.errors.required">Suicidical Risk is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>

                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Treatment Plan</mat-label>
                        <mat-select  [(ngModel)]="dtoAuditmaster.treatmentPlan" #treatmentPlan="ngModel" name="treatmentPlan"
                        [ngClass]="{ 'is-invalid': AuditSettings.submitted && treatmentPlan.invalid }" required >
                        <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuditSettings.submitted && treatmentPlan.invalid">
                          <mat-error *ngIf="treatmentPlan.errors.required">Treatment Plan is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>

                <div class="col-md-4 mt-4">
                    <mat-form-field class="w-100 " >
                        <mat-label>Progress Note</mat-label>
                        <mat-select  [(ngModel)]="dtoAuditmaster.progressNote" #progressNote="ngModel" name="progressNote"
                        [ngClass]="{ 'is-invalid': AuditSettings.submitted && progressNote.invalid }" required >
                        <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="AuditSettings.submitted && progressNote.invalid">
                          <mat-error *ngIf="progressNote.errors.required">Progress Note is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                </div>

                <div class="col-md-4 mt-4">
                  <mat-form-field class="w-100 " >
                      <mat-label>Communication Form</mat-label>
                      <mat-select  [(ngModel)]="dtoAuditmaster.communicationForm" #communicationForm="ngModel" name="communicationForm"
                      [ngClass]="{ 'is-invalid': AuditSettings.submitted && communicationForm.invalid }" required >
                      <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="AuditSettings.submitted && communicationForm.invalid">
                        <mat-error *ngIf="communicationForm.errors.required">Communication Form is required</mat-error>
                      </mat-error>
                    </mat-form-field>
              </div>

                <div class="col-md-4 mt-4">
                  <mat-form-field class="w-100 " >
                      <mat-label>Incidient Report
                      </mat-label>
                      <mat-select  [(ngModel)]="dtoAuditmaster.incidentReport" #incidentReport="ngModel" name="incidentReport"
                      [ngClass]="{ 'is-invalid': AuditSettings.submitted && incidentReport.invalid }" required >
                      <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="AuditSettings.submitted && incidentReport.invalid">
                        <mat-error *ngIf="incidentReport.errors.required">Incidient Report is required</mat-error>
                      </mat-error>
                    </mat-form-field>
              </div>

              <div class="col-md-4 mt-4">
                <mat-form-field class="w-100 " >
                    <mat-label>Behavior Incident Report
                    </mat-label>
                    <mat-select  [(ngModel)]="dtoAuditmaster.behaviourIncidentReport" #behaviourIncidentReport="ngModel" name="behaviourIncidentReport"
                    [ngClass]="{ 'is-invalid': AuditSettings.submitted && behaviourIncidentReport.invalid }" required >
                    <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="AuditSettings.submitted && behaviourIncidentReport.invalid">
                      <mat-error *ngIf="behaviourIncidentReport.errors.required">Behavior Incident Report
                        is required</mat-error>
                    </mat-error>
                  </mat-form-field>
            </div>

            <div class="col-md-4 mt-4">
              <mat-form-field class="w-100 " >
                  <mat-label>Discharge Summary
                  </mat-label>
                  <mat-select  [(ngModel)]="dtoAuditmaster.dischargeSummary" #dischargeSummary="ngModel" name="dischargeSummary"
                  [ngClass]="{ 'is-invalid': AuditSettings.submitted && dischargeSummary.invalid }" required >
                  <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="AuditSettings.submitted && dischargeSummary.invalid">
                    <mat-error *ngIf="dischargeSummary.errors.required">Discharge Summary
                      is required</mat-error>
                  </mat-error>
                </mat-form-field>
          </div>

          <div class="col-md-4 mt-4">
            <mat-form-field class="w-100 " >
                <mat-label>Treatment Summary
                </mat-label>
                <mat-select  [(ngModel)]="dtoAuditmaster.treatmentSummary" #treatmentSummary="ngModel" name="treatmentSummary"
                [ngClass]="{ 'is-invalid': AuditSettings.submitted && treatmentSummary.invalid }" required >
                <mat-option *ngFor="let item of dateList" [value]="item.value" >{{item.label}}</mat-option>
                </mat-select>
                <mat-error *ngIf="AuditSettings.submitted && treatmentSummary.invalid">
                  <mat-error *ngIf="treatmentSummary.errors.required">Treatment Summary
                    is required</mat-error>
                </mat-error>
              </mat-form-field>
        </div>


               

            </div>
            <div class="col-md-12 text-right">  
                <button
                  type="submit" mat-stroked-button title="Save" form="AuditSettings" 
                  class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="postAuditsettings()">            
                {{!isEditAuthSettings ? 'Save' :
                'Update'}}
                </button>
                <button
                  type="button"
                  mat-stroked-button
                  title="Clear"
                  (click)="resetForm()"
                  class="btn btn-cancel primary-btn-m h-40">
                  Clear
                </button>
              </div>
        </div>
        </form>
    </div>
  </div>

import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { constantVariables } from 'src/app/constant/constant';
import { ClinicalNotes, Medication } from 'src/app/_models/_Treatment/_clinicalnotes';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { NgForm } from '@angular/forms';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import * as _ from "lodash";
import { IndFamTheraphyOutcomeNotes } from 'src/app/_models/_Treatment/_indvfamiTheraphy';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { X } from '@angular/cdk/keycodes';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MenuList, RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { DatePipe } from '@angular/common';
import { TreatmentService } from '../treatment.service';
//import { ThrowStmt } from '@angular/compiler';



@Component({
  selector: 'app-family-individual-theraphy',
  templateUrl: './family-individual-theraphy.component.html',
  styleUrls: ['./family-individual-theraphy.component.scss']
})
export class FamilyIndividualTheraphyComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  pdffile: any;
  selecteddate: any;
  userId: any; userName: any;
  selectedtheraphyid: any;
  selectedMsId: any;
  _dtoPatientInfo: any = [];
  intervention: any;
  frmindfamtheraphyGroup: FormGroup;
  indfamTheraphyHdr: any;
  selectedIndThrphy: any = [];
  isshowhistroy: boolean = false;
  _dtoIndFamTheraphyOutcomeNotes: IndFamTheraphyOutcomeNotes;
  SelectedTab = 1;
  medicationRecords: any;
  _dtoMedication: any = [];
  lstmedicine: any = []
  @ViewChild('frmProgress') frmProgress: NgForm;
  editmedication: boolean = false;
  onAddmedication: boolean = false;
  //E-sign
  signatureImg: any;
  updatedsignatureImg: any;
  isESignAttached: boolean;
  isformtype: string;
  isfutureSign: boolean;
  dynamicimgsrcid: any = [];
  isAttachEsign: boolean;
  defaultImage: any;
  isEdit: boolean = false;
  selectedIntervention: any;
  selectedChalenges: any;
  ShowPreview: boolean;
  goalsinfo: any;
  PopUp: boolean;
  PopUpdata: any = { 'benchmarkTitle': '', 'nextBenchmark': '' };
  SelectedBenchmark: number;
  MenuAccess: MenuList;
  roleAccess: RoelAccess;
  pdfFamIndNotes: any;
  isLicensed: boolean;
  RoleId: string;
  IsDownload: any;
  IsFamily: boolean = false;
  IsCloseWindow: boolean = false;
  ddlseverity: any;
  bg = '#ff4000';
  progressUpg: boolean = false;
  secondaryId : any;
  AutoFillSS:boolean=false;
  SSignature:any;
  GetClinicianSign: any[];
indtherapyprogressnote :any;
famtherapyprogressnote :any;
IsFamilypdf: boolean = false;
IsIndividualpdf: boolean = false;
  // eSignWhiteSpace : any = constantVariables.Appconfiguration.Esign1;
  constructor(public global: Global, public clinicianservice: ClinicianService, public treatmentservice: TreatmentService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, private formBuilder: FormBuilder, public roleaccessservice: PageAccessService, public datepipe: DatePipe,) {
      // console.log(this.eSignWhiteSpace);
      
     }

  ngOnInit(): void {
    this.isLicensed = localStorage.getItem('isLicensed') == 'true' ? true : false;
    this.RoleId = localStorage.getItem('roleId');
    this.userId = Number(localStorage.getItem('id'));
    const pageid = constantVariables.pagelists.ProgressNoteFivepointRating;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.MenuAccess = this.roleaccessservice.GetSiteHeaderAccess();
    this._dtoIndFamTheraphyOutcomeNotes = new IndFamTheraphyOutcomeNotes;
    this.getAutoFillSignature();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.selecteddate = JSON.parse(this.commonService.FrontEndDecryption(params.date));
        this.selectedtheraphyid = JSON.parse(this.commonService.FrontEndDecryption(params.theraphyid));
        this.selectedMsId = JSON.parse(this.commonService.FrontEndDecryption(params.id));
        if(params.individual){
          this.indtherapyprogressnote = JSON.parse(this.commonService.FrontEndDecryption(params.individual));
        }
        if(params.family){
          this.famtherapyprogressnote = JSON.parse(this.commonService.FrontEndDecryption(params.family));
        }
        if (params.IsFromHistory) {
          this.IsCloseWindow = true;
        }
        if(this.indtherapyprogressnote == "true"){
          if (this.selectedtheraphyid == 1) { this.indfamTheraphyHdr = "INDIVIDUAL THERAPY PROGRESS NOTE"; this.IsFamily = true; this.IsIndividualpdf = true; }
        }
        if(this.famtherapyprogressnote == "true"){
           if (this.selectedtheraphyid == 2) { this.indfamTheraphyHdr = "FAMILY THERAPY PROGRESS NOTE"; this.IsIndividualpdf = true; }
        }
        if (this.selectedtheraphyid == 1 && this.indtherapyprogressnote == "false") { this.indfamTheraphyHdr = "INDIVIDUAL THERAPY PROGRESS NOTE"; }
        else if (this.selectedtheraphyid == 2 && this.famtherapyprogressnote == "false") { this.indfamTheraphyHdr = "FAMILY THERAPY PROGRESS NOTE"; this.IsFamily = true; }
        else if (this.selectedtheraphyid == 12) { this.indfamTheraphyHdr = "SPEECH THERAPY"; }
        else if (this.selectedtheraphyid == 13) { this.indfamTheraphyHdr = "NUTRITION THERAPY"; }
        this.selectedIntervention = "";
        if ((this.datepipe.transform(new Date(), "yyyy-MM-dd")) < (this.datepipe.transform(this.selecteddate, "yyyy-MM-dd"))) {
          this.progressUpg = true;
        }
        this.getPatientInfo();
      }
    });
    this.getSeverityMaster();
    console.log(this._dtoPatientInfo);
  }

  getPatientInfo() {
    this.spinnerservice.show();
    this.isEdit = false;
    this.secondaryId = localStorage.getItem('id');
    this.clinicianservice.getIndvFamTherphyPtInfo(this.selectedMsId, this.selectedtheraphyid, this.selecteddate,this.secondaryId).subscribe(
      (res: any) => {
        if (res.length > 0) {
          this._dtoPatientInfo = res[0];          
          // this.isEdit=(this._dtoPatientInfo.dataExists==false)?false:true;
          this.intervention = this._dtoPatientInfo.iftCheklist.find(x => x.clinicalIssueGroupId == 2).clinicalIssueGroup;
          if (this._dtoPatientInfo.iftNotes == null) {
            this._dtoPatientInfo.iftNotes = this._dtoIndFamTheraphyOutcomeNotes;
            (document.getElementById('esign') as HTMLImageElement).src = "";
          }
          else if (this._dtoPatientInfo.iftNotes != null && this._dtoPatientInfo.iftNotes.isAttachEsign == true) {
            (document.getElementById('esign') as HTMLImageElement).src = this._dtoPatientInfo.iftNotes.staffInitial;
          }
          else if(this._dtoPatientInfo.iftNotes != null && this._dtoPatientInfo.iftNotes.isAttachEsign == true){
            (document.getElementById('esign1') as HTMLImageElement).src = this._dtoPatientInfo.iftNotes.staffInitial1;
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
    this.spinnerservice.hide();

  }



  postindfamTheraphyOutcomeNotes(form: NgForm, val) {
    if (!form.valid || (this._dtoPatientInfo.iftNotes.staffInitial == undefined && ((this.roleAccess.isSign && this.isLicensed) || (!this._dtoPatientInfo.isLicensed && this.roleAccess.isUnlicensed) || (this._dtoPatientInfo.IsIntern && this.roleAccess.isInternSign)))) {
      return
    }
    this._dtoPatientInfo.userId = +localStorage.getItem('id');
    if(val != 2)
    {
      this.spinnerservice.show();
      this._dtoPatientInfo.isSave = true;
    }else{
      this._dtoPatientInfo.isSave = false;
    }
    // const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoPatientInfo, false);
    const dataToSend = this._dtoPatientInfo;
    this.clinicianservice.postindfamTheraphyOutcomeNotes(dataToSend).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.getPatientInfo();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  OnTabchange(item) {
    this.SelectedTab = item;
    if (this.SelectedTab == 2) {
      this.getPatientMedication()
    }
    if (this.SelectedTab == 3) {
      this.getpatientgoal()
    }
  }

  getPatientMedication() {
    this.spinnerservice.show();
    //  console.log(this.userId )
    this.clinicianservice.getPatientMedication(this.selectedMsId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.medicationRecords = res;
        this.ongetMedication();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  addMedicationInfo() {
    // this._dtoMedication=new Medication();
    this.onAddmedication = true;
    this._dtoMedication.push({
      patientMedicationId: 0,
      patientAccountId: this.medicationRecords[0].patientAccountId,
      dayScheduleSubGroupId: 0,
      medications: null,
      dosage: null,
      frequency: null,
      isMorning: null,
      isNoon: null,
      isEvening: null,
      isBedTime: null,
      isBeforemeal: null,
      isDelete: null,
    })
  }

  postProgressMedication() {
    this.spinnerservice.show();

    //const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoMedication, false);
    this.clinicianservice.postProgressMedication(this._dtoMedication).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (this.editmedication) {
          var update = res.replace('saved', 'Updated')
          this.snackbar.success(update);
        } else {
          this.snackbar.success(res);
        }

        this._dtoMedication = [];
        this.ongetMedication();
        //this.getProgressActivityDetails(this.grpTherapydetails);
        this.editmedication = false;
        this.onAddmedication = false;
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );

  }

  ongetMedication() {
    this.isshowhistroy = false;
    this._dtoMedication = [];
    this.spinnerservice.show();

    this.clinicianservice.getprogressmedicine(this.medicationRecords[0].patientAccountId, 0).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          this.lstmedicine = res;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
    //this.addMedicationInfo();
  }
  removeclinicalinformation(data, index) {
    if (data.patientMedicationId != 0) {
      this.spinnerservice.show();
      this.removeexistMedinfo(data);
    }
    this._dtoMedication.splice(index, 1);
  }
  removeexistMedinfo(data) {
    data.dayScheduleSubGroupId = 0
    this.clinicianservice.deleteMediciation(data.patientMedicationId, data.dayScheduleSubGroupId).subscribe((res: any) => {
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }

  onEditMedication(item) {
    this.editmedication = true;
    this._dtoMedication = [];
    for (let i = 0; i < item.dtoPatientMedication.length; i++) {
      this._dtoMedication.push({
        patientMedicationId: item.dtoPatientMedication[i].patientMedicationId,
        patientAccountId: item.dtoPatientMedication[i].patientAccountId,
        medications: item.dtoPatientMedication[i].medications,
        dosage: item.dtoPatientMedication[i].dosage,
        frequency: item.dtoPatientMedication[i].frequency,
        isMorning: item.dtoPatientMedication[i].isMorning,
        isNoon: item.dtoPatientMedication[i].isNoon,
        isEvening: item.dtoPatientMedication[i].isEvening,
        isBedTime: item.dtoPatientMedication[i].isBedTime,
        isBeforemeal: item.dtoPatientMedication[i].isBeforemeal,
        isDelete: null,
      })
    }
  }
  openDialog(item) {
    this.global.isconsentesign = false;
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
    //   { data: { parentinfoId: this.userId, isfutureSign: false, isParentsign: true }, 
    //   autoFocus: false 
    // });
    let isParentsign =false;
    if(this.AutoFillSS==true)
     {
       isParentsign=true;
 
     }
     const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
       {
      data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
       autoFocus: false 
 
     });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign != undefined && this.global.isconsentesign) {
        if(this.SSignature!='')
          {
           this.updatedsignatureImg=this.SSignature;
          }
        this.signatureImg = this.updatedsignatureImg;
        if (item = 1) {
          this._dtoPatientInfo.iftNotes.staffInitial = this.updatedsignatureImg;
          this._dtoPatientInfo.iftNotes.isAttachEsign = true;
          (document.getElementById('esign') as HTMLImageElement).src = this.signatureImg;
        }
      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        if (item = 1) {
          this._dtoPatientInfo.iftNotes.staffInitial = this.global.consentesign;
          this._dtoPatientInfo.iftNotes.isAttachEsign = true;
          (document.getElementById('esign') as HTMLImageElement).src = this.signatureImg;
        }
      }
      else {
        this.signatureImg = this.defaultImage;
        this._dtoPatientInfo.iftNotes.isAttachEsign = true;
        // this._dtoPatientInfo.iftNotes.staffInitial = this.defaultImage;
      }


    });
  }
  openDialogs(item) {
    this.global.isconsentesign = false;
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
    //   { data: { parentinfoId: this.userId, isfutureSign: false, isParentsign: true },
    //   autoFocus: false  
    // });
    let isParentsign =false;
    if(this.AutoFillSS==true)
     {
       isParentsign=true;
 
     }
     const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
       {
      data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
       autoFocus: false 
 
     });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign != undefined && this.global.isconsentesign) {
        if(this.SSignature!='')
          {
           this.updatedsignatureImg=this.SSignature;
          }
        this.signatureImg = this.updatedsignatureImg;
        if (item = 1) {
          this._dtoPatientInfo.iftNotes.staffInitial1 = this.updatedsignatureImg;
          this._dtoPatientInfo.iftNotes.isAttachEsign = true;
          (document.getElementById('esign1') as HTMLImageElement).src = this.signatureImg;
        }
      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        if (item = 1) {
          this._dtoPatientInfo.iftNotes.staffInitial1 = this.global.consentesign;
          this._dtoPatientInfo.iftNotes.isAttachEsign = true;
          (document.getElementById('esign1') as HTMLImageElement).src = this.signatureImg;
        }
      }
      else {
        this.signatureImg = this.defaultImage;
        this._dtoPatientInfo.iftNotes.isAttachEsign = true;
        // this._dtoPatientInfo.iftNotes.staffInitial1 = this.defaultImage;
      }


    });
  }
  onIndFamEditClick(event) {
    // if(event=="E"){this.isEdit=false;}
    //  else if(event=="P"){this.isEdit=true;
    this.ShowPreview = true;

  }
  onClosePreview() {
    this.ShowPreview = false;
  }

  OnbackClick() {
    if (this.IsCloseWindow) {
      window.close();
    }
    else {
      this.router.navigate(['/clinician'], { queryParams: { menu: 1 } });

    }
  }
  getpatientgoal() {
    this.spinnerservice.show();
    //  console.log(this.userId )
    this.clinicianservice.getIndvFamgoals(this._dtoPatientInfo.patientAccountId, this.selectedtheraphyid, this.selectedMsId, this.selecteddate).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        console.log(res)
        this.goalsinfo = res
        if (this.goalsinfo[0].staffInitials != "") {
          //  this.signatureImg = this.goalsinfo[0].staffInitials
          (document.getElementById('esign2') as HTMLImageElement).src = this.goalsinfo[0].staffInitials
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onsavegoals() {
    this.goalsinfo.forEach(x => x.status = +x.status)

    console.log(this.goalsinfo)
    this.spinnerservice.show()
    const dataToEncrypt = this.commonService.DataToEncrypt(this.goalsinfo, false);
    this.clinicianservice.postIndFamgoals(this.goalsinfo).subscribe(res => {
      this.snackbar.success(res)
      this.getpatientgoal()
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
        // this.snackbar.error(err.error);
      },)
  }

  openDialogForgoals(item) {
    this.global.isconsentesign = false;
    
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
    //   { data: { parentinfoId: this.userId, isfutureSign: false, isParentsign: true } ,
    //   autoFocus: false 
    // });
    let isParentsign =false;
    if(this.AutoFillSS==true)
     {
       isParentsign=true;
 
     }
     const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
       {
      data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
       autoFocus: false 
 
     });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign != undefined && this.global.isconsentesign) {
        if(this.SSignature!='')
          {
           this.updatedsignatureImg=this.SSignature;
          }

        this.signatureImg = this.updatedsignatureImg;
        (document.getElementById('esign2') as HTMLImageElement).src = this.signatureImg;
        this.goalsinfo.forEach(x => x.staffInitials = this.signatureImg)
      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        (document.getElementById('esign2') as HTMLImageElement).src = this.signatureImg;
        this.goalsinfo.forEach(x => x.staffInitials = this.signatureImg)
      }
      else {
        this.signatureImg = this.defaultImage;

      }


    });
  }
  OnstatusChange(item) {
    this.SelectedBenchmark = null
    // item.Isstatuschnaged=true
    if (item.trackBenchIsSelected) {
      if (item.status == 3) {
        this.PopUp = true;
        this.PopUpdata = item;
      }
    }
    else {
      this.snackbar.error("Benchmark Already completed")
    }
    //  this.PopUp = true
    //  this.PopUpdata = item
  }

  Onclosepopup() {

    for (let item of this.goalsinfo) {
      if (item.trackbenchmarkId == this.PopUpdata.trackbenchmarkId) {
        item.status = 2
        this.PopUp = false
        return
      }
    }
  }
  OnBenchmarkselect() {
    this.spinnerservice.show();

    if (this.SelectedBenchmark == 1) {
      for (let item of this.goalsinfo) {
        if (item.trackbenchmarkId == this.PopUpdata.trackbenchmarkId) {
          item.Isstatuschnaged = true;
          item.status = 3
          item.IsNextBenchMark = false;
          console.log(item)
          this.PopUp = false
          this.spinnerservice.hide()
          return
        }
      }
    }
    else if (this.SelectedBenchmark == 2) {
      for (let item of this.goalsinfo) {
        if (item.trackbenchmarkId == this.PopUpdata.trackbenchmarkId) {
          item.Isstatuschnaged = true;
          item.status = 3
          item.IsNextBenchMark = true;
          console.log(item)
          this.PopUp = false
          this.spinnerservice.hide()
          return
        }
      }
    }
    this.spinnerservice.hide()
    //console.log(this.SelectedBenchmark)

  }


  OnDownload(item) {
    //this.downloadLink = new ElementRef();
    this.spinnerservice.show();
    this.clinicianservice.getIndFamProgressNotespdf(this.selectedMsId, this._dtoPatientInfo.meetingScheduleTimeId, this._dtoPatientInfo.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfFamIndNotes = res.filePath;
        if (item == 1) {
          window.open(this.pdfFamIndNotes, '_blank');
        }
        else if (item == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  print() {
    let printContents = document.getElementById('print-section').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    // this.ShowPreview=true;
    window.location.reload();

  }
  getSeverityMaster() {
    this.spinnerservice.show()
    this.commonService.getSeverityMaster().subscribe(
      res => {
        if (res != null) {
          this.ddlseverity = res;
          this.spinnerservice.hide()
        }
      },
      err => {
        this.spinnerservice.hide();

      })
  }
  onSelectionChanged(value) {
    this.bg = this.ddlseverity.filter(x => x.severityValue == value)[0].colocode;
    this._dtoPatientInfo.iftNotes.severity = value;

  }
  saveOnChange(frmindfamtheraphy)
  {
    this.postindfamTheraphyOutcomeNotes(frmindfamtheraphy,2);
  }
  secondarySign():any{
    if(this._dtoPatientInfo.licenseNumber != '' && this._dtoPatientInfo.licenseNumber != null ){
      return false;
    }
    else if(this._dtoPatientInfo.sSignature ){
     return false;
    }
    else{
      return true;
    }
  }
  getAutoFillSignature() {
    this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
        this.GetClinicianSign = res;

        if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
          this.AutoFillSS=false;
          this.SSignature=this.GetClinicianSign[0].clinicianSign;
        }
        else
        {
          this.AutoFillSS=true;
          this.SSignature='';
        }
    });
}
OnDownloadindfam(item) {
  //this.downloadLink = new ElementRef();
  this.spinnerservice.show();
  this.clinicianservice.getIndFamProgressNotespdfReverse(this.selectedMsId, this._dtoPatientInfo.meetingScheduleTimeId, this._dtoPatientInfo.patientAccountId).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      this.pdfFamIndNotes = res.filePath;
      if (item == 1) {
        window.open(this.pdfFamIndNotes, '_blank');
      }
      else if (item == 0) {
        const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
      }

    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
getSeverityDescription(severity: number): string {
  const severityMap = {
    0: '0 - Discharged',
    1: '1 - Mild',
    2: '2 - Medium',
    3: '3 - Critical'
  };
  return severityMap[severity] || 'Unknown Severity';
}
}



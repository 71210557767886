<form role="form" class="form-horizontal" (ngSubmit)="mailform.form.valid  && postmail()" name="global.mailform"
  id="mailform" autocomplete="off" #mailform="ngForm" novalidate>
  <div class="form-group row">
    <div class="col-lg-12 position-relative">

      <div class="example-chip-list position-relative w-100 custom-input gmail-txt">
        <mat-label class="to-label">To</mat-label>
        <mat-chip-list #EmailchipList>
          <mat-chip [color]="(item.invalid)?'warn':''" selected *ngFor="let item of global.toemailList" [selectable]="selectable"
            [removable]="removable" (removed)="removeEmail(item)" >
            {{item.value}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="" autocomplete="new-password" [(ngModel)]="global.mailinfo.ToUserEmail" #ToUserEmail="ngModel" name="ToUserEmail"
            [matChipInputFor]="EmailchipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addtoemail($event)" >
        </mat-chip-list>
        <mat-chip-list #EmailchipList>
          <!-- <mat-chip *ngFor="let item of global.toemailList" [selectable]="selectable" [removable]="removable"
            (removed)="removeEmail(item)">
            {{item.value}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip> -->

          <input placeholder="" #tomailinput autocomplete="new-password" [(ngModel)]="global.mailinfo.ToUserEmail"
            [matAutocomplete]="tomail" #ToUserEmail="ngModel" name="ToUserEmail" [matChipInputFor]="EmailchipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addtoemail($event)" (ngModelChange)="doFiltertomail(global.mailinfo.ToUserEmail)">
        </mat-chip-list>
        <mat-autocomplete #tomail="matAutocomplete" (optionSelected)="addtoemailselected($event)">
          <mat-option *ngFor="let par of filteredTomail" [value]="par">
            {{ par.email }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="mailform.submitted && ToUserEmail.invalid">
          <mat-error *ngIf="ToUserEmail.errors.required">To mail id is required</mat-error>
        </mat-error>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-lg-12 position-relative">

      <div class="example-chip-list position-relative w-100 custom-input gmail-txt">
        <mat-label class="to-label">CC</mat-label>
        <mat-chip-list #ccchipList>
          <mat-chip [color]="(item.invalid)?'warn':''" selected *ngFor="let item of global.ccemailList"
            [selectable]="selectable" [removable]="removable" (removed)="removeCCEmail(item)">
            {{item.value}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="" #ccmailinput autocomplete="new-password" [(ngModel)]="global.mailinfo.CCUsersEmail"
            [matAutocomplete]="ccmail" #CCUsersEmail="ngModel" name="CCUsersEmail" [matChipInputFor]="ccchipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
            (ngModelChange)="doFilterccmail(global.mailinfo.CCUsersEmail)" (matChipInputTokenEnd)="addccemail($event)">
        </mat-chip-list>
        <mat-autocomplete #ccmail="matAutocomplete" (optionSelected)="addCCemailselected($event)">
          <mat-option *ngFor="let par of filteredCCmail" [value]="par">
            {{ par.email }}
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <div class="col-lg-12 position-relative">
      <mat-label class="to-label">Subject : </mat-label>
      <span class="to-subject" *ngIf="subtag !== ''">"{{subtag}}":</span>
      <input id="inputPassword1" [(ngModel)]="global.mailinfo.Title"
        [ngClass]="subtag !== '' ? 'subject' : 'subject-without'" #Title="ngModel" name="Title"
        class="form-control gmail-txt" required>
      <mat-error *ngIf="mailform.submitted && Title.invalid">
        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="Title.errors.required">Subject is required
        </mat-error>
      </mat-error>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-lg-12 position-relative">
      <quill-editor placeholder="Enter Text" required [(ngModel)]="global.mailinfo.QueryDescription"
        #QueryDescription="ngModel" name="QueryDescription" [modules]="quillConfig"></quill-editor>
      <mat-error *ngIf="mailform.submitted && QueryDescription.invalid">
        <mat-error *ngIf="QueryDescription.errors.required">Mail Content is required</mat-error>
      </mat-error>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-lg-12 ">
      <mat-chip-list class="mt-2  attachment-sec">
        <mat-chip [color]="(item.invalid)?'warn':''" selected *ngFor="let item of global.mailattachment"
          [selectable]="selectable" [removable]="removable" (removed)="removeattachments(item)">
          <span class="attachname">{{item.name}}</span>
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
      <span class="btn green fileinput-button mr-2">
        <i class="fa fa-plus fa fa-white pr-1"></i>
        <span>Attachment</span>
        <input accept="image/*" type='file' #mailUploadfile multiple (change)="onmailFileChange($event)">
      </span>
      <button class="btn btn-send" type="submit">Send</button>
    </div>
  </div>
</form>
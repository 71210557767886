import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { RoleserviceService } from '../roleservice.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { roles } from 'src/app/_models/_Users/users';
import { UserserviceService } from '../../usermanagement/userservice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from 'src/app/services/snackBar.service'
@Component({
  selector: 'app-role-permission',
  templateUrl: './role-permission.component.html',
  styleUrls: ['./role-permission.component.scss']
})

export class RolePermissionComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  //#region Declaration
  dtorole: roles;
  dataSource = new MatTableDataSource<any>([]);
  columns: any[];
  displayedColumns: string[];
  groupByColumns: string[] = [];
  allData: any[];
  _allGroup: any[];
  ddlRole: any;
  expandedCar: any[] = [];
  expandedSubCar: any[] = [];
  ddlSfccLocation: any;
  allRoles: any;
  selectedRole: any;
  roleAccess: any;
  pageListDetails: any[] = [];
  allsupermodules: any[];

  //#endregion
  constructor(private formBuilder: FormBuilder, private roleService: RoleserviceService,
    private router: Router, private userService: UserserviceService, public spinnerservice: NgxSpinnerService
    , private snackbar: SnackBarService) { }

  ngOnInit() {
    this.dtorole = new roles();
    this.columns = [{
      field: 'pageName'
    }, {
      field: 'isView'
    }, {
      field: 'isCreate'
    }, {
      field: 'isEdit'
    }, {
      field: 'isDelete'
    }];
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['module'];
    this.loadAllRoles();
    this.getsfcclocation();
  }

  loadAllRoles() {
    this.userService.getRoles().subscribe(data => {
      this.allRoles = data;
      this.allRoles = this.allRoles.filter(x => x.id != 1);
      this.ddlRole = '1';
      this.allsupermodules = this.pageListDetails.map(item => item.superModule)
        .filter((value, index, self) => self.indexOf(value) === index);
    });

  }
  getsfcclocation() {
    this.userService.getLocation(0).subscribe(
      res => {
        if (res != null) {
          this.ddlSfccLocation = res;
        }
      },
      err => {

      },
    );
  }

  onSubmitRole() {

    var isvalid = true;

    if ((this.dtorole.details != null && this.dtorole.details.length == 0) || this.dtorole.details == null || this.dtorole.details == null) {

      isvalid = false;
    }

    const _obj = {
      Details: this.dtorole.details
    }

    this.spinnerservice.show();
    this.roleService.saveRoleAccess(this.dtorole)
      .subscribe(
        data => {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(data).message);
          this.getroleAccess();
        },
        error => {
          this.spinnerservice.hide();
          this.snackbar.error(error.error);
        });
  }
  onCheck(event) {

    this.dtorole.isAll = event.checked;
    this.getroleAccess();

  }
  getroleAccess() {
    this.dtorole.details = [];
    var isvalid = true;
    if (this.dtorole.roleId == 0 || this.dtorole.roleId == -1 || this.dtorole.roleId == undefined || this.dtorole.roleId == null) {
      isvalid = false;
    }
    
    if (!this.dtorole.isAll) {
      if (this.dtorole.location == 0 || this.dtorole.location == -1 || this.dtorole.location == undefined || this.dtorole.location == null) {
        isvalid = false;
      }
    }
    else {
      this.dtorole.location = -1;
    }
    if (!isvalid)
      return;
    this.roleService.getRoleAccess(this.dtorole).subscribe(res => {
      if (res) {
        this.dtorole.details = JSON.parse(res).details;
      }
    },
      err => {

      }
    );
  }

}


<div class="nutritional">
  <div class="top-btn-section clearfix mb-2">
    <h2 class="head-section mb-2 mt-2">Nutritional questionnaire</h2>
    <div class="col-md-5 ml-auto pr-0 text-right">
      <a
        title="PatientInformation"
        role="button"
        class="export-pdf-btn btn"
        (click)="getNutritionPdfFile(1)"
        ><i class="fas fa-print"></i>
      </a>
      <button
        class="btn generate-btn mr-2"
        (click)="getNutritionPdfFile(0)"
        *ngIf="lstIntakeNutritional?.answers?.groupList?.length > 0"
      >
        <i class="far fa-file-pdf mr-2"></i>Generate PDF
      </button>
      <!-- && !isMovedChma" -->
      <button
        class="btn back-btn-new mr-2"
        type="submit"
        form="frmNutrition"
        *ngIf="
          lstIntakeNutritional?.answers?.groupList?.length > 0 &&
          (roleAccess.isCreate || roleAccess.isEdit)
        "
      >
        Save
      </button>
      <button
        class="btn back-btn-new mr-2"
        (click)="NewQuestionnaire()"
        *ngIf="roleAccess.isCreate || roleAccess.isEdit"
      >
        Clear
      </button>
      <!-- <button class="btn back-btn-new mr-2" type="submit">Save</button> -->
      <button class="btn back-btn-new mr-2" (click)="onbacktoMytask()">
        <i class="fas fa-chevron-left mr-2"></i>Back
      </button>
    </div>
  </div>

  <div class="nut-information">
    <div class="d-flex met-info-top w-100">
      <i class="fas fa-user"></i>
      <div class="mr-4">
        Client Name
        <span class="imp-info">{{ _dtoPatientInfo?.patientName }} </span>
      </div>
      <div class="mr-4">
        DOB
        <span class="imp-info">{{
          _dtoPatientInfo?.dob | date : "MM/dd/YYYY"
        }}</span>
      </div>
      <div class="mr-4">
        Age <span class="imp-info">{{ _dtoPatientInfo?.age }} Yrs</span>
      </div>
      <div class="mr-4">
        Updated On
        <span
          class="imp-info"
          *ngIf="lstIntakeNutritional?.inTakeProgress?.inTakeProgressId != 0"
          >{{ _dtoPatientInfo?.updatedDate | date : "MM/dd/YYYY" }}</span
        >
      </div>
    </div>
    <form
      (ngSubmit)="
        frmNutrition.form.valid && onSaveNutritionQuestion(frmNutrition)
      "
      id="frmNutrition"
      autocomplete="off"
      #frmNutrition="ngForm"
      novalidate
    >
      <!-- [ngClass]="{'disabled':isMovedChma}" -->
      <div
        class="col-md-12 mb-4"
        *ngFor="
          let value of lstIntakeNutritional?.answers?.groupList;
          let j = index 
        "
      >
      <div *ngIf="value.instructionName != ''"> <b>INSTRUCTIONS : {{value.instructionName}}</b></div>
      <div
      class="col-md-12 mb-4 question-list-nutri"
      *ngFor="
        let item of value?.details;
        let i = index
      "
    >
        <div class="row">
          <div
            *ngIf="item.typeId != queText"
            class="question text-truncate w-100"
          >
            <span>Q{{ totalCount(j,i) }}.</span> {{ item.question }}
          </div>
          <div *ngIf="item.typeId == queText" class="treat-label d-flex mb-2">
            <span class="mt-2 mr-2">Q{{ totalCount(j,i) }}. </span>
            <span class="mt-2 mr-2">{{ item.question }}</span>

            <span
              class="mr-2"
              *ngFor="let opt of item?.options; let idx = index"
              ><input
                style="border: none; border-bottom: 1px solid #000000"
                matInput
                [(ngModel)]="opt.description"
                [ngModelOptions]="{ standalone: true }"
                #explanation="ngModel"
                name="explanation"
                maxlength="100"
            /></span>
            <span class="mt-2 ml-2 mr-2">{{ item.answerHint }}</span>
            <div></div>
          </div>
          <!-- <label class="nut-label col-md-12"></label> -->
        </div>

        <div
          class="row align-self-center text-left mt-3"
          *ngIf="
            item.typeId == queMCSA ||
            item.typeId == queMCMA ||
            item.typeId == queYesNo
          "
        >
          <div
            class="d-inline-block mr-2"
            *ngFor="let opt of item?.images; let idx = index"
          >
            <img
              class="img-thumbnail"
              style="width: 90px; height: 90px"
              *ngIf="opt.fileName != ''"
              [src]="opt.filePath"
            />
          </div>
        </div>
        <fieldset>
          <div class="row" *ngIf="item.typeId != queText">
            <div class="question-divider">
              <div class="divider-text">answer choices</div>
            </div>
          
            <ng-container *ngIf="item.typeId == queYesNo">
              <div
                class="d-inline-block ml-3 mr-2 card-shadow"
                *ngFor="let opt of item?.options; let idx = index"
              >
                <div
                  class="img-text-active"
                  [ngClass]="{ 'txt-area-active': opt.isAnswer }"
                >
                  <div class="custom-control custom-radio image-checkbox">
                  
                      <input
                        type="radio"
                        name="queYesNo{{ idx }}{{ i }}{{ j }}"
                        id="queYesNo{{ idx }}{{ i }}{{ j }}"
                        [ngModelOptions]="{ standalone: true }"
                        [checked]="opt.isAnswer"
                        (click)="rbAnswer(i, idx,event,j)"
                        class="custom-control-input"
                        required
                      />
                      <label class="px-0 custom-control-label" for="queYesNo{{ idx }}{{ i }}{{ j }}">
                      <!-- {{ opt.optionLabel }} -->
                      <div
                        *ngIf="
                          opt.documentName != null && opt.documentName != ''
                        "
                      >
                        <img
                          *ngIf="
                            opt.documentName != null || opt.documentName != ''
                          "
                          onerror="this.onerror=null; this.src='/assets/images/no-image.png'"
                          [src]="opt.documentLocation"
                          class="img-fluid"
                        />
                      </div>
                      <div
                        class="mt-5 no-image-text-nutrition p-0"
                        *ngIf="
                          opt.documentName == null || opt.documentName == ''
                        "
                      >
                      No image
                      </div>
                    </label>
                    <div class="text-slide-img">{{ opt.optionLabel }}</div>
                    <div
                      class="option-text"
                      *ngIf="item.iSquestionExplanation == 2"
                    >
                      <span
                        class="extra-info"
                        *ngIf="item.questionExplanation != ''"
                        >{{ item.questionExplanation }}</span
                      >
                      <textarea
                        name=""
                        id=""
                        rows="4"
                        maxlength="300"
                        [(ngModel)]="opt.explanation"
                        #explanation="ngModel"
                        placeholder="Enter Your Answer"
                        class="w-100"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="item.typeId == queMCSA">
              <div
                class="d-inline-block ml-3 mr-2 card-shadow"
                *ngFor="let opt of item?.options; let idx = index"
              >
                <div
                  class="img-text-active"
                  [ngClass]="{ 'txt-area-active': opt.isAnswer }"
                >
                  <div class="custom-control custom-radio image-checkbox">
                    <input
                      type="radio"
                      class="custom-control-input"
                      name="queMCSA{{ idx }}{{ i }}{{ j }}"
                      id="queMCSA{{ idx }}{{ i }}{{ j }}"
                      [ngModelOptions]="{ standalone: true }"
                      [checked]="opt.isAnswer"
                      (click)="rbAnswer(i, idx, event,j)"
                      required
                      
                    />

                    <label
                      class="custom-control-label"
                      for="queMCSA{{ idx }}{{ i }}{{ j }}"
                    >
                      <div
                        *ngIf="
                          opt.documentName != null && opt.documentName != ''
                        "
                      >
                        <img
                          *ngIf="
                            opt.documentName != null || opt.documentName != ''
                          "
                          [src]="opt.documentLocation"
                          onerror="this.onerror=null; this.src=''"
                          class="img-fluid"
                        />
                      </div>
                      <div
                        class="mt-3 no-image-text"
                        *ngIf="
                          opt.documentName == null || opt.documentName == ''
                        "
                      >
                        No image
                      </div>
                    </label>
                    <div class="text-slide-img">{{ opt.optionLabel }}</div>
                  </div>

                  <div
                    class="image-textarea"
                    *ngIf="item.iSquestionExplanation == 2"
                  >
                    <span
                      class="extra-info"
                      *ngIf="item.questionExplanation != ''"
                      >{{ item.questionExplanation }}</span
                    >
                    <textarea
                      name=""
                      id=""
                      rows="4"
                      maxlength="300"
                      [(ngModel)]="opt.explanation"
                      [ngModelOptions]="{ standalone: true }"
                      #explanation="ngModel"
                      placeholder="Enter Your Answer"
                      class="w-100"
                    ></textarea>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="item.typeId == queMCMA">
              <div
                class="d-inline-block ml-3 mr-2 card-shadow"
                *ngFor="let opt of item?.options; let idx = index"
              >
                <div
                  class="img-text-active"
                  [ngClass]="{ 'txt-area-active': opt.isAnswer }"
                >
                  <div class="custom-control custom-checkbox image-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      name="queMCMA{{ idx }}{{ i }}{{ j }}"
                      id="queMCMA{{ idx }}{{ i }}{{ j }}"
                      [ngModelOptions]="{ standalone: true }"
                      (click)="cbkAnswer(i, idx, $event,j)"
                      [checked]="opt.isAnswer"
                      required
                    />
                    <label
                      class="custom-control-label"
                      for="queMCMA{{ idx }}{{ i }}{{ j }}"
                    >
                      <div
                        *ngIf="
                          opt.documentName != null && opt.documentName != ''
                        "
                      >
                        <img
                          *ngIf="
                            opt.documentName != null && opt.documentName != ''
                          "
                          onerror="this.onerror=null; this.src=''"
                          [src]="opt.documentLocation"
                          class="img-fluid"
                        />
                      </div>
                      <div
                        class="mt-3 no-image-text"
                        *ngIf="
                          opt.documentName == null || opt.documentName == ''
                        "
                      >
                        No image
                      </div>
                    </label>
                    <div class="text-slide-img">{{ opt.optionLabel }}</div>
                  </div>

                  <div
                    class="option-text"
                    *ngIf="item.iSquestionExplanation == 2"
                  >
                    <span
                      class="extra-info"
                      *ngIf="item.questionExplanation != ''"
                      >{{ item.questionExplanation }}</span
                    >
                    <textarea
                      name=""
                      id=""
                      rows="4"
                      maxlength="300"
                      [(ngModel)]="opt.explanation"
                      [ngModelOptions]="{ standalone: true }"
                      #explanation="ngModel"
                      placeholder="Enter Your Answer"
                      class="w-100"
                    ></textarea>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="item.typeId == queDescriptive">
              <div
                class="col-md-12 mt-2"
                *ngFor="let opt of item?.options; let idx = index"
              >
                <div class="w-100 extra-value">
                  <span class="w-100 extra-info">Descriptive</span>

                  <textarea
                    name=""
                    id=""
                    rows="4"
                    class="w-100"
                    [(ngModel)]="opt.description"
                    [ngModelOptions]="{ standalone: true }"
                    #explanation="ngModel"
                    name="explanation"
                  ></textarea>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="item.typeId == queRatings">
              <div
                class="col-md-12"
                *ngFor="let opt of item?.options; let idx = index"
              >
                <div class="rating-get mt-3 mb-3">
                  <a
                    title="poor"
                    class="rate-numb"
                    [class.active]="opt.rating == 1"
                    (click)="onrating(i, idx, 1,j)"
                    ><span>1</span></a
                  >
                  <a
                    class="rate-numb"
                    [class.active]="opt.rating == 2"
                    (click)="onrating(i, idx, 2,j)"
                    ><span>2</span></a
                  >
                  <a
                    class="rate-numb"
                    [class.active]="opt.rating == 3"
                    (click)="onrating(i, idx, 3,j)"
                    ><span>3</span></a
                  >
                  <a
                    class="rate-numb"
                    [class.active]="opt.rating == 4"
                    (click)="onrating(i, idx, 4,j)"
                    ><span>4</span></a
                  >
                  <a
                    title="Excellent"
                    class="rate-numb"
                    [class.active]="opt.rating == 5"
                    (click)="onrating(i, idx, 5,j)"
                    ><span>5</span></a
                  >
                </div>

                <mat-error
                  class="mat-form-field-subscript-wrapper"
                  *ngIf="isReqRating"
                  >Rating is required
                </mat-error>
              </div>
            </ng-container>
            <!-- <ng-container *ngIf="item.typeId==queText">
                            <div class="col-md-12 mt-2" *ngFor="let opt of item?.options;let idx=index;">
                                <div class="w-100 extra-value">                                 
                                   
                                    <input class="mr-2" matInput [(ngModel)]="opt.description"
                                    [ngModelOptions]="{standalone: true}" #explanation="ngModel"
                                    name="explanation"/>
                                    <span class="w-100 extra-info">Descriptive</span>
                                </div>
                            </div>
                        </ng-container> -->

            <div class="col-md-12 mt-2">
              <ng-container
                *ngIf="
                  item.iSquestionExplanation == 1 &&
                  item.typeId != queDescriptive
                "
                ><span class="w-100 extra-info"></span>
                <div class="w-100 extra-value">
                  <span
                    class="extra-info"
                    *ngIf="item.questionExplanation != ''"
                    >{{ item.questionExplanation }}</span
                  >
                  <textarea
                    name=""
                    id=""
                    rows="4"
                    [ngModelOptions]="{ standalone: true }"
                    maxlength="300"
                    [(ngModel)]="item.explanation"
                    #questionExplanation="ngModel"
                    placeholder="Enter Your Answer"
                    class="w-100"
                  ></textarea>
                </div>
              </ng-container>
            </div>
          </div>
        </fieldset>
      </div>
      </div>
      <div
        class="col-md-12 text-right mb-3"
        *ngIf="
          lstIntakeNutritional?.answers?.groupList?.length > 0 &&
          (roleAccess.isCreate || roleAccess.isEdit)
        "
      >
        <!-- *ngIf="!isMovedChma" -->
        <button class="btn back-btn-new mr-2" type="submit" form="frmNutrition">
          Save
        </button>
        <button class="btn back-btn-new mr-2" (click)="onbacktoMytask()">
          <i class="fas fa-chevron-left mr-2"></i>Back
        </button>
      </div>
      <a mimetype="application/octet-stream" #downloadLink></a>
    </form>
  </div>
</div>

<div class="daily-scheduler-page">
    <div>
    <div class="top-btn-section col-md-12 clearfix mb-1 w-100">
        <div>
        <h2 class="head-section mt-3">
            <span class="arrow-span">
                <span class="arrow-image"><</span>
            </span>
            <span class="ml-2 head-span">{{_dtoGroupTherapyDetails?.typeName}}</span></h2>
         
            </div>
          
            </div>
            <div class="list-group-heading">
                <ul class="list-group list-group-horizontal">
                    <li class="list-group-item">
                        <p>
                            <label class="list-item-label">Date : </label> 
                            <span class="list-item-date">{{_dtoGroupTherapyDetails?.startDate | date:'mediumDate'}}</span>
                        </p>
                    </li>
                    <li class="list-group-item"> 
                        <p> 
                            <label class="list-item-label">Time : </label>
                             <span class="list-item-date">{{_dtoGroupTherapyDetails?.startTime | date:'shortTime'}} to {{_dtoGroupTherapyDetails?.endTime | date:'shortTime'}}</span>
                    </p>
                    </li>
                    <li class="list-group-item"><span class="list-item-label">{{typeSelected}}</span></li>
                  </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <h2 class="head-daily-section form-head-section mt-0 ml-5">FORM GROUPS</h2>
                </div>
                <div class="col-md-4 text-right pr-4">
                    <button class="btn primary-btn-filled-small mb-2 h-40 float-right" (click)="createGroup()"
                    >Create Group</button>
                </div>
                
            </div>
            <div class="content-section">
                <ul class="list-group list-group-horizontal">
                    <li class="list-group-item scheduler-list-item border-right pl-0 border-bottom" *ngFor="let group of _dtoDropListCount; let i =index">
                        
                        <div *ngIf="group==1">
                            <h3 class="scheduler-heading scheduler-head-padding">Available Participants</h3>
                            <span class="dots-content border-bottom pb-3">
                                <div 
                                id="1"
                                cdkDropList
                                [cdkDropListData]="_dtoGrpTherapyUsers"
                                [cdkDropListConnectedTo]="['2','3','4','5','6']"
                                class="example-list"
                                (cdkDropListDropped)="drop($event)" style="width: 100%;height: 100;max-height: calc(100vh);
                                overflow-x: auto;">
                                    <div class="example-box" *ngFor="let item of _dtoGrpTherapyUsers" cdkDrag >
                                        <span style="cursor: pointer;">                     
                                            <img src="../../assets/images/dots-six-vertical.svg" style="width: 15px; height: 15px;" alt="dotsimage"
                                            class="dots-six-vertical">
                                            <img src="../../assets/images/avator.jpg" style="width: 25px; height: 25px;" alt="profile" class="profile-icon"/>
                                            <span class="px-2">{{item.UserName}}</span>
                                        </span>               
                                    </div>
                                </div>
                            </span>  
                        </div>     
                        
                        <div [ngStyle]="false?{'display': 'none'} : {'display': 'block'}" *ngIf="group!=1">
                            <h3 class="scheduler-heading pl-0 border-bottom pb-2">
                            <span class="ml-2">Group {{group-1}}</span>
                             <img src="../../assets/images/delete.svg" alt="delete" class="delete-img"/>
                            </h3>
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label class="control-label-new">Start Time</mat-label>
                                        <input matInput type="time" #startTime="ngModel"
                                          [(ngModel)]="this._dtoOutputList[i-1].startTime" name="startTime" required (change)="onTimeChange(this._dtoOutputList[i-1],1)" > 
                                    </mat-form-field>
                                    <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].startTimeError">
                                        <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].startTimeError">{{this._dtoOutputList[i-1].startTimeErrorMessage}}</mat-error>
                                    </mat-error>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label class="control-label-new">End Time</mat-label>
                                        <input matInput type="time" #endTime="ngModel"
                                          [(ngModel)]="this._dtoOutputList[i-1].endTime" name="endTime" required (change)="onTimeChange(this._dtoOutputList[i-1],2)"> 
                                    </mat-form-field>
                                    <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].endTimeError">
                                        <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].endTimeError">{{this._dtoOutputList[i-1].endTimeErrorMessage}}</mat-error>
                                    </mat-error>
                                </div>
                            </div>
                            <div>
                                <mat-form-field class="w-100">
                                    <mat-label>Clinician / Staff</mat-label>
                                    <mat-select [(ngModel)]="this._dtoOutputList[i-1].clinician" #clinician="ngModel"
                                        name="clinician{{group}}" (selectionChange)="onMultiChange(this._dtoOutputList[i-1],1)">
                                        <mat-option #matOption *ngFor="let item of allClinicians" [value]="item" (click)="clinicianChange(i,item)">
                                            {{item.firstName}} {{item.lastName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>    
                                <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].clinicianError">
                                    <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].clinicianError">Clinicians are  required</mat-error>
                                </mat-error>
                            </div>
                            <div>
                                <mat-form-field class="w-100">
                                    <mat-label>Intern / Support</mat-label>
                                    <mat-select name="gender"
                                       required>
                                        <mat-option> leader</mat-option>
                                    
                                    </mat-select>
                                    <!-- <mat-error *ngIf="userinfo.submitted && gender.invalid">
                                        <mat-error *ngIf="gender.errors.required">Gender is required
                                        </mat-error>
                                    </mat-error> -->
                                </mat-form-field>
    
                            </div>
                            <div>
                                <mat-form-field class="w-100">
                                    <mat-label>Group Leader</mat-label>
                                    <mat-select name="groupLeader{{group}}" #groupLeader="ngModel" required [(ngModel)]="this._dtoOutputList[i-1].leader" (selectionChange)="onMultiChange(this._dtoOutputList[i-1],3)">
                                        <!-- <mat-option #matOption *ngFor="let item of this._dtoOutputList[i-1]?.clinician" [value]="item.staffId">
                                         {{item.firstName}} {{item.lastName}}
                                        </mat-option>   -->
                                        <mat-option #matOption [value]="this._dtoOutputList[i-1]?.clinician.staffId">
                                            {{this._dtoOutputList[i-1]?.clinician.firstName}} {{this._dtoOutputList[i-1]?.clinician.lastName}}
                                           </mat-option> 
                                    </mat-select>
                                </mat-form-field>
                                <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].leaderError">
                                    <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].leaderError">Group Leader is required
                                    </mat-error>
                                </mat-error>
    
                            </div>
                     
                            <div>
                                <mat-form-field class="w-100">
                                    <mat-label>Therapy</mat-label>
                                    <mat-select multiple name="therapy{{group}}" #therapy="ngModel" required [(ngModel)]="this._dtoOutputList[i-1].selectedTherapy" (selectionChange)="onMultiChange(this._dtoOutputList[i-1],2)">
                                        <mat-option #matOption *ngFor="let item of _dtoGroupMeetingTherapyMaster" [value]="item.groupMeetingTherapyId">
                                         {{item.therapyName}}
                                        </mat-option>  
                                    </mat-select>
                                </mat-form-field>
                                <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].therapyError">
                                    <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].therapyError">Therapy is required
                                    </mat-error>
                                </mat-error>
    
                            </div>
                            <div>

                                <span>
                                    <div
                                    id={{group}}
                                      cdkDropList
                                      [cdkDropListData]="this._dtoOutputList[i-1].dropList"
                                      [cdkDropListConnectedTo]="arrayFunction(group)"
                                      class="example-list"
                                      (cdkDropListDropped)="drop($event)" style="width: 100%;height: 200px;">
                                      <div class="example-box" *ngFor="let item of this._dtoOutputList[i-1].dropList" cdkDrag>
                                        <span style="cursor: pointer;">                     
                                            <img src="../../assets/images/dots-six-vertical.svg" style="width: 15px; height: 15px;" alt="dotsimage"
                                            class="dots-six-vertical">
                                            <img src="../../assets/images/avator.jpg" style="width: 25px; height: 25px;" alt="profile" class="profile-icon"/>
                                            <span class="px-2">{{item.UserName}}</span>
                                        </span>      
                                      </div>
                                    </div>
                                    <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].dropListError">
                                        <mat-error *ngIf="isFormSubmitted && this._dtoOutputList[i-1].dropListError">Patients are  required</mat-error>
                                    </mat-error>
                                </span>
    
                            </div>
                        </div>
                    </li>
                  </ul>
               

            </div>
            <div class="w-100 text-center py-3">
              
                <button type="submit"
                    class="btn primary-btn-filled-small float-right mr-2" (click)="postScheduleGroup()">Schedule Group</button>
                    <button type="submit"
                    class="btn primary-btn-outlined-small ng-star-inserted float-right mr-2">
                       CANCEL</button>
          </div>
        </div>

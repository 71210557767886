<div class="generate-intake">

  <div class="top-btn-section clearfix mb-2">

    <div class=" mt-2 mb-2"> <strong>Client Name : {{patientfullName}} </strong> </div>
    <div class="col-md-4 ml-auto pr-0">
      <!-- <span class=" float-right">
        <a title="PatientInformation" *ngIf="pdfPatientInformationIntake != null" role="button"
          href="{{pdfPatientInformationIntake}}" class="export-pdf-btn btn" target="_blank"><i class="fas fa-print"></i>
        </a>
      </span> -->

      <!-- <button class="btn generate-btn mr-2" *ngIf="isDownloadShow" (click)="onDownloadPDF(0)"><i class="far fa-file-pdf mr-2"></i>Generate</button> -->
      <button  class="export-pdf-btn btn"  mat-type="button" *ngIf="isDownloadShow" (click)="OnGenarateIntakePdfDownload(1)"><i class="fa fa-print"></i></button>
      <button class="btn generate-btn mr-2" *ngIf="isDownloadShow" (click)="onDownloadPDF()"><i class="far fa-file-pdf mr-2"></i>Download
        PDF</button>
      <button class="btn generate-btn mr-2" (click)="onGeneratePDF()"><i class="far fa-file-pdf mr-2"></i>Generate
        PDF</button>
       
      <button class="btn back-btn-new mr-2" (click)="onbacktoMytask()"><i
          class="fas fa-chevron-left mr-2"></i>Back</button>
    

    </div>

  </div>
  <div class="card mb-2">
    <div class="text-center">
      <div class="head-section mt-2 mb-2">Intake Packet </div>
    </div>
    <div class="card-body p-2 border-bottom">
      <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
        <div class="step " [ngClass]="{'completed': selectedtab  == 1}">
          <div class="step-icon-wrap">
            <div class="step-icon"><i class="far fa-user" (click)="onclickpacket(1)"></i></div>
          </div>
          <h4 class="step-title cursor-pointer" (click)="onclickpacket(1)">Personal Information</h4>
        </div>
        <div class="step " [ngClass]="{'completed': selectedtab  == 2}">
          <div class="step-icon-wrap">
            <div class="step-icon"><i class="fas fa-user-injured" (click)="onclickpacket(2)"></i></div>
          </div>
          <h4 class="step-title cursor-pointer" (click)="onclickpacket(2)">Patient Treatment History</h4>
        </div>
        <div class="step " [ngClass]="{'completed': selectedtab  == 3}">
          <div class="step-icon-wrap">
            <div class="step-icon"><i class="fas fa-tasks" (click)="onclickpacket(3)"></i></div>
          </div>
          <h4 class="step-title cursor-pointer" (click)="onclickpacket(3)">Health Screening Checklist </h4>
        </div>
        <div class="step " [ngClass]="{'completed': selectedtab  == 4}">
          <div class="step-icon-wrap">
            <div class="step-icon"><i class="far fa-list-alt" (click)="onclickpacket(4)"></i></div>
          </div>
          <h4 class="step-title cursor-pointer" (click)="onclickpacket(4)">Consent Forms</h4>
        </div>
        <div class="step" [ngClass]="{'completed': selectedtab  == 5}">
          <div class="step-icon-wrap">
            <div class="step-icon"><i class="fas fa-tasks" (click)="onclickpacket(5)"></i></div>
          </div>
          <h4 class="step-title cursor-pointer" (click)="onclickpacket(5)">Medication Management Checklist</h4>
        </div>
        <div class="step" [ngClass]="{'completed': selectedtab  == 6}">
          <div class="step-icon-wrap">
            <div class="step-icon"><i class="fas fa-poll" (click)="onclickpacket(6)"></i></div>
          </div>
          <h4 class="step-title cursor-pointer" (click)="onclickpacket(6)">Survey</h4>
        </div>
      </div>
    </div>
    <div >
      <div class="col-md-12 mt-2 p-0" *ngIf="selectedtab == 1 && patientData != undefined">
        <div class="">
          <div class="card-body pt-1 thin-scroll gen-height">
            <div class="col-md-12 p-0 mb-2">
              <h6 class="mb-2">PERSONAL INFORMATION</h6>
              <div class="row">
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">First Name</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{patientData.firstName}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Last Name</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{patientData.lastName}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Gender</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{patientData.genderType}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">DOB</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{patientData.dob|date:'MM/dd/yyyy'}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Age</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{patientData.age}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Patient Is A Minor</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{patientData.isMinor ?'Yes':'No'}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-1 p-0">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">SSN</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{patientData.ssid}}</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-12 p-0 mb-2">
              <h6 class="mb-2">ACADEMIC INFORMATION</h6>
              <div class="row">
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Grade Level</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{patientData.gradeLevel}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">School District</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{patientData.schoolDistrict}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">School Name</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{patientData.schoolName}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Teachers Name</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{patientData.teachersName}}</div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-12 p-0 mb-1">
              <h6 class="mb-2">CONTACT INFORMATION</h6>
              <div class="row">
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Parent Name (if minor)</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{parentData?.firstName}} {{parentData?.lastName}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Relationship</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{parentData?.parentalRelation}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Parent's marital Status</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{marital}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Email</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username ">
                        {{parentData?.email}}

                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row">
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">State</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{parentData?.stateName}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">City</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{parentData?.city}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Zip</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{parentData?.zip}} </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Home Number</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{parentData?.contactNo}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Work Number</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{parentData?.workNumber}}</div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Cell Number</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username"> {{parentData?.cellNumber}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <label class="table-label">Full Address</label>
                    </div>
                    <div class="col-md-12">
                      <div class="users-view-username">{{parentData?.addressLine1}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-12 p-0 mt-2 checklist-viewpage "  *ngIf="selectedtab == 3 || selectedtab == 5">
        <!-- <div class="status-msg mr-3 mt-2 text-right mb-2 cursor-pointer text-underline"> Date of last physical exam
          <span>{{lstIntakeSurvey?.inTakeProgress?.lastExamination|date:'MM/dd/yyyy'}}</span>
        </div> -->
        <div class="table-responsive lead-box p-0 gen-height thin-scroll">
          <table class="table table-hover table-center mb-0" [ngClass]="{'disabled':isMovedChma}">
            <thead>
              <tr>
                <th scope="col" style="width: 5%;">S.NO</th>
                <th scope="col" style="width: 40%;">Particulars</th>
                <th scope="col" style="width: 12%; text-align: center;">Yes/No</th>
                <th scope="col">Comments</th>
                <th scope="col" style="width: 10%;text-align: center;">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of lstIntakeSurvey?.answers;let i=index">
                <td>{{i+1}}</td>
                <!-- <td> <div class="no-date-indicate">
              <span><i class="fas fa-exclamation-circle"></i></span>
             </div></td> -->
                <!-- <td> <div class="no-date-indicate">
              <span><i class="fas fa-exclamation-circle"></i></span>
             </div></td> -->
                <!-- explanation -->
                <td>{{item.question}}</td>
                <td class="text-center"
                  *ngIf="(currentQuestion == null || currentQuestion == undefined )||( currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId != item.configureFormDetailId)"
                  [ngClass]="item.isAnswer == null || item.isAnswer ==  undefined?'no-date-indicate':''">{{item.isAnswer
                  == true?'Yes':item.isAnswer == false ?'No':''}}
                  <span *ngIf="item.isAnswer == null || item.isAnswer ==  undefined"><i
                      class="fas fa-exclamation-circle mr-2" style="color: orange;"></i> No data</span>
                </td>
                <td class="d-inline-block text-center"
                  *ngIf="currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId == item.configureFormDetailId">
                  <mat-radio-group name="isAnswer{{i}}" [(ngModel)]="item.isAnswer" required #isAnswer="ngModel">
                    <mat-radio-button [value]="true" class="mr-2" [checked]="item.isAnswer == true">Yes
                    </mat-radio-button>
                    <mat-radio-button [value]="false" [checked]="item.isAnswer == false">No
                    </mat-radio-button>
                  </mat-radio-group>
                  <span class="text-danger" *ngIf="isAnswerRequired">Answer Required</span>
                </td>
                <td
                  *ngIf="(currentQuestion == null || currentQuestion == undefined )||( currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId != item.configureFormDetailId)">
                  {{item.explanation}}</td>
                <td
                  *ngIf="currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId == item.configureFormDetailId">
                  <span class="hint-label" *ngIf="item.questionExplanation != ''">{{item.questionExplanation}}</span>
                  <textarea *ngIf="item.iSquestionExplanation == 1" row="3" placeholder="Enter Comments"
                    class="w-100 textarea-placehold" maxlength="300" [(ngModel)]="item.explanation"
                    #explanation="ngModel" name="explanation{{i}}" [required]="item.isAnswer"></textarea>

                  <span class="text-danger w-100 float-left" *ngIf="isCommentsRequired">Comments is required</span>
                </td>
                <td class="text-center">
                  <div>
                  <i *ngIf="(currentQuestion == null || currentQuestion == undefined )||( currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId != item.configureFormDetailId)"
                    class="fas fa-edit text-primary mr-2" (click)="onEdit(item)"></i>
                  <i *ngIf="currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId == item.configureFormDetailId"
                    class="fas fa-save text-success" (click)="postIntakeChecklist()"></i>
                  <i *ngIf="currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId == item.configureFormDetailId"
                    class="fas fa-times text-danger ml-2" (click)="onCancel()"></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
   
      </div>
      <div class="row px-3 intake-row" *ngIf="selectedtab == 5">
        <div class="col-md-12 mt-3 mb-3" *ngIf="lstIntakeSurvey?.answers[medicationIndex].isAnswer==true">
          <h2 class="head-section">Medication</h2>
          <span class="col-md-1 ml-auto d-flex">
            <i *ngIf="!IsEditMedication"
            class="fas fa-edit text-primary mr-2" (click)="onEditMedication()"></i>
          <i *ngIf="IsEditMedication"
            class="fas fa-save text-success" (click)="postIntakeChecklistall()"></i>
          <i *ngIf="IsEditMedication"
            class="fas fa-times text-danger ml-2" (click)="oncancelEditMedication()"></i> 
          </span>
          
          <div class="medication-dosage">  
            <div  class="row inputElement">  
              <div  class="col-md-3">  
                <label >Medications</label>  
              </div>
    
              <div  class="col-md-3">  
                <label >Dosage</label>  
              </div>
    
              <div  class="col-md-3">  
                <label>Frequency</label>  
              </div>
    
              <div  class="col-md-3">  
                <label >Side effects</label>  
              </div>
    
            </div>
    
            <div  class="loop-section position-relative mb-2" *ngFor="let item of _dtoMedicationsCheckList;let i= index;let last=last" [ngClass]="{'disabled': !IsEditMedication }">
    
              <div class="row inputElement" >  
                <div  class="col-md-3">  
                  <input  maxlength="50" [(ngModel)]="item.medications" #medications="ngModel" name="medications{{i}}" placeholder="Medicine" type="text" class="form-control " (change)="checkValidations()" >
                  <mat-error class="text-xs" *ngIf="item.noMedicationError">
                    This field is required
                  </mat-error>
                </div>
    
                <div  class="col-md-3">  
                  <input  maxlength="50" [(ngModel)]="item.dosage" #dosage="ngModel" name="dosage{{i}}" placeholder="Dosage" type="text" class="form-control " (change)="checkValidations()" >
                  <mat-error class="text-xs" *ngIf="item.noDosageError">
                    This field is required
                  </mat-error>
                </div>
    
                <div  class="col-md-3">  
                  <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                  [(ngModel)]="item.frequency" #frequency="ngModel" name="frequencyM{{i}}" (change)="checkValidations()">
                    <option value="null">Frequency</option>
                    <option value="1">q.a.m. Every morning</option>
                    <option value="2">q.p.m. Every evening</option>
                    <option value="3">q.h.s Every night at bedtime</option>
                    <option value="4">b.i.d. Twice daily</option>
                    <option value="5">t.i.d. 3 times a day</option>
                    <option value="6">q.i.d 4 times a day</option>
                    <option value="7">q.o.d Every other day</option>
                    <option value="8">q.w. Every week</option>
                    <option value="9">p.r.n. Daily as needed</option>
                  </select>
                  <mat-error class="text-xs" *ngIf="item.noFrequencyError">
                    This field is required
                  </mat-error>
                </div>
    
                <div  class="col-md-3">  
                  <input  maxlength="50" [(ngModel)]="item.sideEffects" #sideEffects="ngModel" name="sideEffects{{i}}" placeholder="Side effects" type="text" class="form-control " (change)="checkValidations()" >
                  <mat-error class="text-xs" *ngIf="item.noSideEffectsError">
                      This field is required
                  </mat-error>
                </div>
    
              </div>
    
              <a  class="add " style="cursor: pointer;" (click)="addMedicine_click()" *ngIf="last" >
                <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
              </a>
    
              <a  class="remove" style="cursor: pointer;" (click)="removeMedicine_click(item,i)" *ngIf="_dtoMedicationsCheckList.length!=1">  
                <i  class="fa fa-lg fa-minus-circle"></i>  
                <span  class="sr-only">Remove</span>  
              </a>
            </div>         
          </div>
    
      </div>
  
      <div class="col-md-12 mt-3 mb-3" *ngIf="lstIntakeSurvey?.answers[vitaminIndex].isAnswer==true" >
          <h2 class="head-section">Vitamins</h2>
          <span class="col-md-1 ml-auto d-flex">
            <i *ngIf="!IsEditVit"
            class="fas fa-edit text-primary mr-2" (click)="onEditIsEditVit()"></i>
          <i *ngIf="IsEditVit"
            class="fas fa-save text-success" (click)="postIntakeChecklistall()"></i>
          <i *ngIf="IsEditVit"
            class="fas fa-times text-danger ml-2" (click)="oncancelEditIsEditVit()"></i> 
          </span>
          <div class="medication-dosage">  
            <div  class="row inputElement">
    
              <div  class="col-md-3">
    
                <label >Vitamins</label>
    
              </div>
    
              <div  class="col-md-3">
    
                <label >Dosage</label>
    
              </div>
    
              <div  class="col-md-3">
    
                <label>Frequency</label>
    
              </div>
    
              <div  class="col-md-3">
    
                <label >Side effects</label>
    
              </div>
    
            </div>
    
            <div  class="loop-section position-relative mb-2"  [ngClass]="{'disabled': !IsEditVit }" *ngFor="let vitaminItem of _dtoVitamins;let i= index;let last=last" >
    
              <div class="row inputElement">  
                <div  class="col-md-3">  
                  <input  maxlength="50" [(ngModel)]="vitaminItem.medications" #vitaminMedication="ngModel" name="vitaminMedications{{i}}" placeholder="Vitamin" type="text" class="form-control " (change)="checkValidations()" >
                  <mat-error class="text-xs" *ngIf="vitaminItem.noMedicationError">
                    This field is required
                  </mat-error>
                </div>
    
                <div  class="col-md-3">  
                  <input  maxlength="50" [(ngModel)]="vitaminItem.dosage" #vitaminDosage="ngModel" name="vitaminDosage{{i}}" placeholder="Dosage" type="text"  class="form-control "  (change)="checkValidations()">
                  <mat-error class="text-xs" *ngIf="vitaminItem.noDosageError">
                    This field is required
                  </mat-error>
                </div>
    
                <div  class="col-md-3">  
                  <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                  [(ngModel)]="vitaminItem.frequency" #frequency="ngModel" name="frequencyV{{i}}" (change)="checkValidations()">
                    <option value="null">Frequency</option>
                    <option value="1">q.a.m. Every morning</option>
                    <option value="2">q.p.m. Every evening</option>
                    <option value="3">q.h.s Every night at bedtime</option>
                    <option value="4">b.i.d. Twice daily</option>
                    <option value="5">t.i.d. 3 times a day</option>
                    <option value="6">q.i.d 4 times a day</option>
                    <option value="7">q.o.d Every other day</option>
                    <option value="8">q.w. Every week</option>
                    <option value="9">p.r.n. Daily as needed</option>
                  </select>
                  <mat-error class="text-xs" *ngIf="vitaminItem.noFrequencyError">
                    This field is required
                  </mat-error>
                </div>
    
                <div  class="col-md-3">  
                  <input  maxlength="50" [(ngModel)]="vitaminItem.sideEffects" #vitaminSideEffects="ngModel" name="vitaminSideEffects{{i}}" placeholder="Side effects" type="text" class="form-control " (change)="checkValidations()" >
                  <mat-error class="text-xs" *ngIf="vitaminItem.noSideEffectsError">
                    This field is required
                  </mat-error>
                </div>
    
              </div>
    
              <a  class="add " style="cursor: pointer;" (click)="addVitamins_click()" *ngIf="last" >
                <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
              </a>
    
              <a  class="remove" style="cursor: pointer;" (click)="removeVitamin_click(vitaminItem,i)" *ngIf="_dtoVitamins.length!=1">  
                <i  class="fa fa-lg fa-minus-circle"></i>  
                <span  class="sr-only">Remove</span>  
              </a>
    
            </div> 
          </div>
    
      </div>
      </div>
      <div class="col-md-12 mt-2 p-0" *ngIf="selectedtab == 2">
        <div class="">
          <div class="card-body pt-1 thin-scroll gen-height">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 p-0">
                  <h6 style="margin-bottom: 10px;">PATIENT HISTORY</h6>
                  <div class="row"
                    *ngIf="patienthistoryinfo != undefined && patienthistoryinfo.objDtoPatientHistory != undefined">
                    <div class="col-md-12 pl-4">

                      <div class="w-100">
                        <label class="blue-label">
                          01. How did you hear about us?</label>
                      </div>
                      <div class="w-100">
                        <div class="users-view-username ">
                          {{patienthistoryinfo.objDtoPatientHistory.sourceType}}  <span class=""
                            *ngIf="patienthistoryinfo.objDtoPatientHistory.sourceTypeId == 1">
                            {{patienthistoryinfo.objDtoPatientHistory.SourceOthers}}
                          </span>
                        </div>
                      </div>

                    </div>


                  </div>
                  <div class="row  p-0"
                    *ngIf="patienthistoryinfo != undefined && patienthistoryinfo.objDtoPatientHistory != undefined &&  physicianDetails  != undefined">

                    <div class="col-md-12 pl-4 ">
                      <!-- <label class="blue-label">02. Primary Care Physician and Phone</label> -->
                      <label class="blue-label">02. Primary Care Physician </label>
                    </div>

                    <div class="col-md-12 pl-4" *ngIf="physicianDetails.isPhysician != Disagreed">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">First Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username"> {{physicianDetails.firstName}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Last Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{physicianDetails.lastName}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Email:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{physicianDetails.email}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Phone:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{physicianDetails.phoneNumber}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Hospital Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{physicianDetails.hospitalName}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Address1:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{physicianDetails.addressLine1}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Address2:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{physicianDetails.addressLine2}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">State:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{physicianDetails.stateName}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">City:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{physicianDetails.city}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Zip Code:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{physicianDetails.zipCode}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pl-4 mt-2">
                      <label class="blue-label" *ngIf="physicianDetails.isPhysician == true">
                        Patient has <span [ngStyle]="{'color': (physicianDetails.isAgree) ? 'green' : 'red'}">
                          {{physicianDetails.isAgree?'Agreed':'Disagreed'}}</span> to contact your Primary Care Physician and Phone to facilitate
                        our excellent care.</label>
                        <label class="blue-label" *ngIf="physicianDetails.isPhysician == false">
                        <span [ngStyle]="{'color': (physicianDetails.isPhysician) ? 'green' : 'red'}">
                            No</span> </label>

                      <div class="w-100">
                        <div class="users-view-username ">
                          <!-- [ngStyle]="{'color': (physicianDetails.isPhysician') ? 'color:green' : 'color:red'}"  -->

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row"
                    *ngIf="patienthistoryinfo != undefined && patienthistoryinfo.objDtoPatientHistory != undefined &&  psychiatristDetails  != undefined">

                    <div class="col-md-12 pl-4">
                      <!-- <label class="blue-label">03. Psychiatrist and Neurologist</label> -->
                      <label class="blue-label">03. Psychiatrist </label>
                    </div>

                    <div class="col-md-12 pl-4" *ngIf="psychiatristDetails.isPsychiatrist != Disagreed">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6" >
                              <label class="table-label">First Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username"> {{psychiatristDetails.firstName}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6" >
                              <label class="table-label">Last Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{psychiatristDetails.lastName}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Email:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{psychiatristDetails.email}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Phone:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{psychiatristDetails.phoneNumber}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Hospital Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{psychiatristDetails.hospitalName}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Address1:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{psychiatristDetails.addressLine1}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Address2:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{psychiatristDetails.addressLine2}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">State:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{psychiatristDetails.stateName}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">City:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{psychiatristDetails.city}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Zip Code:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{psychiatristDetails.zipCode}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pl-4 mt-2" *ngIf="psychiatristDetails.isPsychiatrist != Disagreed">
                      <label class="blue-label">
                        Patient has <span [ngStyle]="{'color': (psychiatristDetails.isAgree) ? 'green' : 'red'}">
                          {{psychiatristDetails.isAgree?'Agreed':'Disagreed'}}
                        </span> to contact their
                        Psychiatrist
                        to facilitate our excellent care.
                      </label>
                      <label class="blue-label" *ngIf="physicianDetails.isPsychiatrist == Disagreed">
                        <span [ngStyle]="{'color': (physicianDetails.isPsychiatrist) ? 'green' : 'red'}">
                          No
                        </span>
                      </label>

                      <div class="w-100">
                        <div class="users-view-username ">
                          <!-- [ngStyle]="{'color': (physicianDetails.isPhysician') ? 'color:green' : 'color:red'}"  -->

                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pl-4 mt-2" *ngIf="psychiatristDetails.isPsychiatrist == Disagreed">
                      <label >
                        <span [ngStyle]="{'color': (psychiatristDetails.isPsychiatrist) ? 'green' : 'red'}">No
                       </span></label>
                  
                      <div class="w-100">
                        <div class="users-view-username ">
                          <!-- [ngStyle]="{'color': (physicianDetails.isPhysician') ? 'color:green' : 'color:red'}"  -->

                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <!-- <div class="row"
                    *ngIf="patienthistoryinfo != undefined && patienthistoryinfo.objDtoPatientHistory != undefined &&  psychiatristDetails  != undefined"
                    > -->
                    <div class="row"  >
                      <div class="col-md-12 pl-4">
                        <label class="blue-label">04.Neurologist</label>
                      </div>
  
                      <div class="col-md-12 pl-4" *ngIf="neurologistDetails.isNeurologist != Disagreed">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-6">
                                <label class="table-label">First Name:</label>
                              </div>
                              <div class="col-md-6">
                                <div class="users-view-username"> {{neurologistDetails.firstName}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-6">
                                <label class="table-label">Last Name:</label>
                              </div>
                              <div class="col-md-6">
                                <div class="users-view-username">{{neurologistDetails.lastName}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-6">
                                <label class="table-label">Email:</label>
                              </div>
                              <div class="col-md-6">
                                <div class="users-view-username">{{neurologistDetails.email}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-6">
                                <label class="table-label">Phone:</label>
                              </div>
                              <div class="col-md-6">
                                <div class="users-view-username">{{neurologistDetails.phoneNumber}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-6">
                                <label class="table-label">Hospital Name:</label>
                              </div>
                              <div class="col-md-6">
                                <div class="users-view-username">{{neurologistDetails.hospitalName}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-6">
                                <label class="table-label">Address1:</label>
                              </div>
                              <div class="col-md-6">
                                <div class="users-view-username">{{neurologistDetails.addressLine1}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-6">
                                <label class="table-label">Address2:</label>
                              </div>
                              <div class="col-md-6">
                                <div class="users-view-username">{{neurologistDetails.addressLine2}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-6">
                                <label class="table-label">State:</label>
                              </div>
                              <div class="col-md-6">
                                <div class="users-view-username">{{neurologistDetails.stateName}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-6">
                                <label class="table-label">City:</label>
                              </div>
                              <div class="col-md-6">
                                <div class="users-view-username">{{neurologistDetails.city}}</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-6">
                                <label class="table-label">Zip Code:</label>
                              </div>
                              <div class="col-md-6">
                                <div class="users-view-username">{{neurologistDetails.zipCode}}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 pl-4 mt-2">
                        <label class="blue-label" *ngIf="neurologistDetails.isNeurologist == true || neurologistDetails.isNeurologist == false">
                          Patient has <span [ngStyle]="{'color': (neurologistDetails.isAgree) ? 'green' : 'red'}">
                            {{neurologistDetails.isAgree?'Agreed':'Disagreed'}}</span> to contact their
                          Neurologist to facilitate our excellent care.</label>
                          <label class="blue-label" *ngIf="neurologistDetails.isNeurologist == Disagreed">
                           <span [ngStyle]="{'color': (neurologistDetails.isNeurologist) ? 'green' : 'red'}">
                              No</span> </label>
                        <div class="w-100">
                          <div class="users-view-username ">
                            <!-- [ngStyle]="{'color': (physicianDetails.isPhysician') ? 'color:green' : 'color:red'}"  -->
  
                          </div>
                        </div>
                      </div>
                    </div>
                  <div class="row  p-0"
                    *ngIf="patienthistoryinfo != undefined && patienthistoryinfo.objDtoPatientHistory != undefined &&  therapistDetails  != undefined">

                    <div class="col-md-12 pl-4">
                      <label class="blue-label">05.Therapist</label>
                    </div>

                    <div class="col-md-12 pl-4" *ngIf="therapistDetails.isTherapist != Disagreed">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">First Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username"> {{therapistDetails.firstName}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Last Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{therapistDetails.lastName}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Email:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{therapistDetails.email}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Phone:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{therapistDetails.phoneNumber}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Hospital Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{therapistDetails.hospitalName}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Address1:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{therapistDetails.addressLine1}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Address2:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{therapistDetails.addressLine2}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">State:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{therapistDetails.stateName}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">City:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{therapistDetails.city}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Zip Code:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{therapistDetails.zipCode}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pl-4 mt-2">
                      <label class="blue-label" *ngIf="therapistDetails.isTherapist != Disagreed">
                        Patient has <span [ngStyle]="{'color': (therapistDetails.isAgree) ? 'green' : 'red'}">
                          {{therapistDetails.isAgree?'Agreed':'Disagreed'}}</span> to contact their Previous
                        Therapist
                        to facilitate our excellent care.</label>
                        <label class="blue-label" *ngIf="therapistDetails.isTherapist == Disagreed">
                          <span [ngStyle]="{'color': (therapistDetails.isTherapist) ? 'green' : 'red'}">
                           No</span> </label>

                      <div class="w-100">
                        <div class="users-view-username ">
                          <!-- [ngStyle]="{'color': (physicianDetails.isPhysician') ? 'color:green' : 'color:red'}"  -->

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row"  >
                    <div class="col-md-12 pl-4">
                      <label class="blue-label">06.CaseManager</label>
                    </div>

                    <div class="col-md-12 pl-4" *ngIf="caseManagerDetails.isCaseManager == true">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">First Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username"> {{caseManagerDetails.firstName}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Last Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{caseManagerDetails.lastName}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Email:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{caseManagerDetails.email}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Phone:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{caseManagerDetails.phoneNumber}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Hospital Name:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{caseManagerDetails.hospitalName}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Address1:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{caseManagerDetails.addressLine1}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Address2:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{caseManagerDetails.addressLine2}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">State:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{caseManagerDetails.stateName}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">City:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{caseManagerDetails.city}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">
                            <div class="col-md-6">
                              <label class="table-label">Zip Code:</label>
                            </div>
                            <div class="col-md-6">
                              <div class="users-view-username">{{caseManagerDetails.zipCode}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pl-4 mt-2" *ngIf="caseManagerDetails.isCaseManager == true">
                      <label class="blue-label" >
                        Patient has <span [ngStyle]="{'color': (caseManagerDetails.isAgree) ? 'green' : 'red'}">
                          {{caseManagerDetails.isAgree?'Agreed':'Disagreed'}}
                        </span> to contact their
                        Case Manager
                        to facilitate our excellent care.
                      </label>
                      
                      <div class="w-100">
                        <div class="users-view-username ">
                          <!-- [ngStyle]="{'color': (physicianDetails.isPhysician') ? 'color:green' : 'color:red'}"  -->

                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pl-4 mt-2" *ngIf="caseManagerDetails.isCaseManager == false || caseManagerDetails.isCaseManager == null">
                      <label class="blue-label" >
                        <span [ngStyle]="{'color': (caseManagerDetails.IsCaseManager) ? 'green' : 'red'}">
                          No
                        </span>
                      </label>
                  
                      
                      <div class="w-100">
                        <div class="users-view-username ">
                          <!-- [ngStyle]="{'color': (physicianDetails.isPhysician') ? 'color:green' : 'color:red'}"  -->

                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div class="col-md-6"
                  *ngIf="patienthistoryinfo != undefined && patienthistoryinfo.objDtoPatientHistory != undefined">
                  <h6 style="margin-bottom: 10px;">PAST OUTCOMES</h6>
                  <div class="col-md-12 pl-2">
                    <div class="w-100">
                      <label class="blue-label">
                        What worked? What didn’t?</label>
                    </div>
                    <div class="w-100">
                      <div class="custom-view-text">
                        {{patienthistoryinfo.objDtoPatientHistory.workDesc}}
                      </div>
                    </div>
                    <div class="w-100">
                      <label class="blue-label">
                        What goals would you like to accomplish for your child?</label>
                    </div>
                    <div class="w-100">
                      <div class="custom-view-text">
                        {{patienthistoryinfo.objDtoPatientHistory.goalsDesc}}
                      </div>
                    </div>
                    <div class="w-100">
                      <label class="blue-label">
                        What are your child's nutritional concerns?</label>
                    </div>
                    <div class="w-100">
                      <div class="custom-view-text">
                        {{patienthistoryinfo.objDtoPatientHistory.nutritionDesc}}
                      </div>
                    </div>
                    <div class="w-100">
                      <label class="blue-label">
                        CURRENT MEDICATIONS</label>
                    </div>
                    <div class="w-100 d-none" *ngFor="let med of this.patienthistoryinfo.objDtoMedication;let i = index">
                      <div class="custom-view-text">
                        {{i+1}}.{{med.medication}}
                      </div>
                    </div>
                    <div class="col-md-12 set6-temp disabled"  [ngClass]="disabled">
                      <div class="row mb-2">
                          <div class="col-md-12 custom-radio">
          
                              <div class="medication col-md-12 inputWrapper p-0">
                                  <div class="row inputElement">
                                      <div class="col-md-3"><label>Medications</label></div>
                                      <div class="col-md-3"><label>Dosage</label></div>
                                      <div class="col-md-3"><label>Frequency</label></div>
                                      <div class="col-md-3"><label>Side effects</label></div>
                                  </div>
                                  <div class="loop-section position-relative mb-2"
                                      *ngFor="let med of _dtoMedications;let i= index;let last=last">
                                      <div class="row  inputElement">
                                          <div class="col-md-3">
                                              <input  class="form-control" maxlength="50" [(ngModel)]="med.medications"
                                                  #medications="ngModel" name="medications{{i}}" placeholder="Medicine"
                                                  type="text">
                                          </div>
                                          <div  class="col-md-3"><input class="form-control" maxlength="30"
                                                  [(ngModel)]="med.dosage" #dosage="ngModel" name="dosage{{i}}"
                                                  placeholder="Dosage" type="text"></div>
                                          <div class="col-md-3">
                                              <select disabled class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                                                  [(ngModel)]="med.frequency" #frequency="ngModel" name="frequency{{i}}">
                                                  <option value="null">--Select Frequency--</option>
                                                  <option value="1">q.a.m. Every morning</option>
                                                  <option value="2">q.p.m. Every evening</option>
                                                  <option value="3">q.h.s Every night at bedtime</option>
                                                  <option value="4">b.i.d. Twice daily</option>
                                                  <option value="5">t.i.d. 3 times a day</option>
                                                  <option value="6">q.i.d 4 times a day</option>
                                                  <option value="7">q.o.d Every other day</option>
                                                  <option value="8">q.w. Every week</option>
                                                  <option value="9">p.r.n. Daily as needed</option>
                                              </select>
                                          </div>
                                          <div  class="col-md-3"><input class="form-control" maxlength="50"
                                                  [(ngModel)]="med.sideEffects" #sideEffects="ngModel" name="sideEffects{{i}}"
                                                  placeholder="Sideeffects" type="text"></div>
                                      </div>
                                      
                                  </div>
          
                              </div>
                          </div>
                          <div class="w-100">
                            <h6 class="row mb-2">PHARMACY DETAILS</h6>
                            <div class="w-100">
                              <label class="blue-label">
                              Pharmacy Name:</label>
                            </div>
                            <div class="w-100">
                              <div class="custom-view-text">
                                {{patienthistoryinfo.objDtoPatientHistory.pharmacyName}}
                              </div>
                            </div>
                            <div class="w-100">
                              <label class="blue-label">
                               Address:</label>
                            </div>
                            <div class="w-100">
                              <div class="custom-view-text">
                                {{patienthistoryinfo.objDtoPatientHistory.pharmacyAddress}}
                              </div>
                            </div>
                            <div class="w-100">
                              <label class="blue-label">
                               Phone Number:</label>
                            </div>
                            <div class="w-100">
                              <div class="custom-view-text">
                                {{patienthistoryinfo.objDtoPatientHistory.pharmacyPhone}}
                              </div>
                            </div>
                            <div class="w-100">
                              <label class="blue-label">
                               Fax:</label>
                            </div>
                            <div class="w-100">
                              <div class="custom-view-text">
                                {{patienthistoryinfo.objDtoPatientHistory.pharmacyFax}}
                              </div>
                            </div>
                         
                              
                            <label>Date of last physical exam</label>
                            <mat-form-field class="col-md-5 pl-5 disabled">
                              <mat-label>Date</mat-label>
                              <input matInput [matDatepicker]="exppicker" [(ngModel)]="patienthistoryinfo.objDtoPatientHistory.lastPhysicalExamDate" #lastPhysicalExamDate="ngModel" name="lastPhysicalExamDate" required>
                              <mat-datepicker-toggle matSuffix [for]="exppicker "></mat-datepicker-toggle>
                              <mat-datepicker #exppicker></mat-datepicker>
                            </mat-form-field>     
                          </div>
                      </div>
                  </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-md-12 mt-2 p-0" *ngIf="selectedtab == 4">
        <div class="card">
          <div class="card-body  pt-1 thin-scroll gen-height">
            <div class="col-md-12">
              <div class="row">

                <div class="col-md-3 p-0">

                  <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                    aria-orientation="vertical">
                    <ng-container *ngIf="lstAuthorizationConsent?.length>1">
                      <span *ngFor="let item of lstIntakeConsent;let i=index">
                        <a class="nav-link mb-2 p-3 shadow w-100" id="v-pills-home-tab" [ngClass]="{'active': selectedConsent == i}" data-toggle="pill"
                        (click)="onloadTemplate(item.consentTemplatesId,item.isVerified,i,lstAuthorizationConsent[0].clinicianTypeId,0)" href="#v-pills-home"
                        role="tab" aria-controls="v-pills-home" aria-selected="true" *ngIf="item.clinicianTypeId==0">

                        <span class="font-weight-bold small text-uppercase text-truncate  d-block">
                          <span class="complete-icon">
                            <i class="fas fa-check-circle mr-1" *ngIf="item.isVerified"></i>
                            <i class="fas fa-exclamation-circle  mr-1" style="color: orange;"
                              *ngIf="!item.isVerified"></i>
                          </span>
                          {{item.templateName}}
                        </span>
                      </a>
                      </span>
                      
                    </ng-container>

                    <ng-container *ngIf=" lstAuthorizationConsent?.length == 1" >
                      <span *ngFor="let item of lstIntakeConsent;let i=index">
                        <a class="nav-link mb-2 p-3 shadow w-100" id="v-pills-home-tab" [ngClass]="{'active': selectedConsent == i}" data-toggle="pill"
                        (click)="onloadTemplate(item.consentTemplatesId,item.isVerified,i,item.clinicianTypeId,0)" href="#v-pills-home"
                        role="tab" aria-controls="v-pills-home" aria-selected="true" *ngIf="item.clinicianTypeId==0">

                        <span class="font-weight-bold small text-uppercase text-truncate  d-block">
                          <span class="complete-icon">
                            <i class="fas fa-check-circle mr-1" *ngIf="item.isVerified"></i>
                            <i class="fas fa-exclamation-circle  mr-1" style="color: orange;"
                              *ngIf="!item.isVerified"></i>
                          </span>
                          {{item.templateName}}
                        </span>
                      </a>
                      </span>
                     
                    </ng-container>

                  </div>
                  
                </div>

                <div class="col-md-9" >
                  <!-- Tabs content -->

                  <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade shadow rounded bg-white show active p-5" id="v-pills-home" role="tabpanel"
                      aria-labelledby="v-pills-home-tab">
                      <span class="verifiedlable" *ngIf="isVerified">
                        <i class="fas fa-check-circle mr-1"></i> Verified</span>
                      <span class="unverified" *ngIf="!isVerified">
                        <i class="fas fa-exclamation-circle  mr-1" style="color: orange;"></i> Form not Verified</span>
                      <h4 class="font-italic mb-4">{{templateData?.templateName}}</h4>
                      <div class="ql-container ql-snow" style="border-width: 0;">
                        <div class="ql-editor" (click)="toggleHelp({},$event)"
                          [innerHTML]="templateData?.templateContent">
                        </div>
                      </div>
                      <!-- <p class="font-italic text-muted mb-2" (click)="toggleHelp({},$event)" [innerHTML]="templateData?.templateContent"></p> -->
                      <div class="verified-section">
                        <div class="update-btn-consent1 ml-4 clearfix">
                          <button type="button" class="btn primary-btn-filled float-right" title="Update Dynamic Field" *ngIf="roleAccess.isApprove" [ngClass]="{'disabled':isMovedChma}"
                            (click)="postDynamicFields()">Save</button>
                          <!-- <span class="check-custom d-flex pt-2 ">
                                                  <mat-checkbox class="example-margin" [checked]="isVerified" [disabled]="isVerified"
                                                      (change)="postUpdateVerified($event)" name="accessLevel">
                                                      Mark as signed 
                                                  </mat-checkbox>
                                              </span> -->
                            <nav aria-label="Page navigation example" *ngIf="selectedConsent==1 && lstAuthorizationConsent.length>1">
                               <ul class="pagination" >
                                    <li class="page-item" *ngFor="let item of lstAuthorizationConsent;let j=index">
                                      <a class="page-link" (click)="onloadTemplate_new(item.consentTemplatesId,item.isVerified,1,item.clinicianTypeId,j)"  [ngClass]="{'active-page' : item.isActive == true}">{{j+1}}</a></li> 
                                      <!-- <div >
                                        <a class="add" (click)="addNewTesmplate(103,false,1,0,lstAuthorizationConsent.length-1)" [ngClass]="{'active-page' : lstAuthorizationConsent.isActive == true}" style="cursor: pointer;padding-top: 9px"  ><i
                                          class="fa fa-lg fa-plus-circle" ></i><span class="sr-only">Add</span></a> 
                                      </div>                          -->
                                </ul>
                             </nav>
                             <nav aria-label="Page navigation example" *ngIf="selectedConsent==1 && lstAuthorizationConsent.length == 1">
                              <ul class="pagination" >
                                   <li class="page-item" *ngFor="let item of lstAuthorizationConsent;let j=index">
                                    <a class="page-link" (click)="onloadTemplate_new(item.consentTemplatesId,item.isVerified,1,item.clinicianTypeId,j)"  [ngClass]="{'active-page' : item.isActive == true}">{{j+1}}</a></li>                           
                               </ul>
                            </nav>
                            
                           
                        </div>
                       
                      </div>
                     
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-md-12 mt-2 p-0" *ngIf="selectedtab == 6">
        <div class="">
          <div class="card-body pt-1 gen-height thin-scroll">
            <div class="col-md-12">
              <div class="status-msg mr-3 mt-2 text-right mb-2 cursor-pointer text-underline"> Last Updated Date
                <span>{{lstIntakeSurvey?.inTakeProgress?.updateDate|date:'MM/dd/yyyy'}} </span>
              </div>
              <div class="col-md-12 p-0">

                <div class="question-container pl-2" *ngFor="let item of lstIntakeSurvey?.answers?.details;let i=index" [ngClass]="{'disabled':isMovedChma}">
                  <div class="question text-truncate w-100 mt-2">
                    <span>Q{{i+1}}.</span> {{item.question}}
                    <i *ngIf="(currentQuestion == null || currentQuestion == undefined )||( currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId != item.configureFormDetailId)"
                      class="fas fa-edit text-primary mr-2 ml-2" (click)="onEdit(item)"></i>
                    <i *ngIf="currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId == item.configureFormDetailId"
                      class="fas fa-save text-success ml-2" (click)="onNextQuestion()"></i>
                    <i *ngIf="currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId == item.configureFormDetailId"
                      class="fas fa-times text-danger ml-2" (click)="onCancel()"></i>
                  </div>
                  <div
                    *ngIf="(currentQuestion == null || currentQuestion == undefined )||( currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId != item.configureFormDetailId)">
                    <div class="answer-survey" *ngFor="let opt of item?.options;let idx=index;let last = last;">
                      <div class="col-md-12 mt-3"
                        *ngIf="item.typeId==queYesNo || item.typeId==queMCSA || item.typeId==queMCMA">
                        <div class="row "
                          *ngIf="opt.answerOptionId == 0 ||(opt.answerOptionId != 0 && opt.isAnswer == true)">
                          <div class="col pr-0">
                            <div class="ans-section">
                              <div class="option-tick">
                                <span *ngIf="opt.isAnswer == true"><img src="../../../assets/images/tick-survey.png">
                                </span> <span> {{opt.optionLabel}}</span>
                              </div>
                            </div>

                          </div>
                          <div class="col-md-6 pl-0"
                            *ngIf="item.iSquestionExplanation == 2 && opt.explanation != null && opt.explanation != undefined  &&  opt.explanation != ''">
                            <div class="ans-text-section">
                              <!-- <div class="ans-section-head">Answer</div> -->
                              <p class="answer-text mt-2">{{opt.explanation}}</p>

                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                    <div class="answer-survey" *ngIf="item.typeId==queDescriptive">
                      <div class="col-md-12 mt-2" *ngFor="let opt of item?.options;let idx=index">
                        <div class="row ">
                          <div class="col pl-3 mt-1">
                            <p class="answer-text">{{opt.description}}</p>
                            <!-- <div class="ans-text-section">
                      <div class="ans-section-head">Description</div>
                      

                    </div> -->
                          </div>
                        </div>
                      </div>


                    </div>
                    <div class="answer-survey" *ngIf="item.typeId==queRatings">
                      <div class="col-md-12 mt-3">
                        <div class="row ">
                          <div class="col pr-0">
                            <div class="ans-section">
                              <!-- <div class="ans-section-head">Options</div> -->
                              <div *ngFor="let opt of item?.options;let idx=index">
                                <div class="rating-get mt-3 mb-3">
                                  <a title="poor" class="rate-numb" [class.active]="opt.rating == 1"
                                    (click)="onrating(i,idx,1)"><span>1</span></a>
                                  <a class="rate-numb" [class.active]="opt.rating == 2"
                                    (click)="onrating(i,idx,2)"><span>2</span></a>
                                  <a class="rate-numb" [class.active]="opt.rating == 3"
                                    (click)="onrating(i,idx,3)"><span>3</span></a>
                                  <a class="rate-numb" [class.active]="opt.rating == 4"
                                    (click)="onrating(i,idx,4)"><span>4</span></a>
                                  <a title="Excellent" class="rate-numb" [class.active]="opt.rating == 5"
                                    (click)="onrating(i,idx,5)"><span>5</span></a>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div class="col-md-6 pl-0 "
                            *ngIf="item.iSquestionExplanation == 1 &&  item.explanation != null && item.explanation != undefined &&  item.explanation != ''">
                            <div class="ans-text-section">
                              <!-- <div class="ans-section-head">Answer</div> -->
                              <p class="answer-text mt-3">{{item.explanation}}</p>

                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                    <div class="row "
                      *ngIf="item.iSquestionExplanation == 1 && item.typeId != queDescriptive && item.typeId!=queRatings && item.explanation != null && item.explanation != undefined  &&  item.explanation != ''">
                      <div class="col pl-0 mt-3" style="padding-left: 30px !important;">
                        <div class="ans-text-section">
                          <div class="ans-section-head">{{item.questionExplanation}}</div>
                          <p class="answer-text">{{item.explanation}}</p>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="currentQuestion != null && currentQuestion != undefined && currentQuestion.configureFormDetailId == item.configureFormDetailId">

                    <ul class="answerList">
                      <li *ngFor="let opt of item?.options;let idx=index;let last = last;">
                        <div class="option-withtxt" *ngIf="item.typeId==queYesNo || item.typeId==queMCSA">
                          <label>
                            <input type="radio" name="queYesNo{{idx}}" [checked]="opt.isAnswer == true ? true:false"
                              (click)="rbAnswer(i,idx)" required>
                            {{opt.optionLabel}}
                          </label>
                          <div class="option-text" *ngIf="item.iSquestionExplanation == 2">

                            <textarea name="" id="" rows="4" maxlength="300" [(ngModel)]="opt.explanation"
                              #explanation="ngModel" placeholder="Enter Your Answer" class="w-100"></textarea>
                          </div>

                          <!-- <mat-error class="mat-form-field-subscript-wrapper " *ngIf="isReqRadio && last">Please select any option
                        </mat-error> -->

                        </div>
                        <div class="option-withtxt" *ngIf="item.typeId==queMCMA">
                          <label>
                            <input type="checkbox" name="queMCMA{{idx}}" (click)="cbkAnswer(i,idx,$event)"
                              [checked]="opt.isAnswer" required>
                            {{opt.optionLabel}}
                          </label>

                          <div class="option-text" *ngIf="item.iSquestionExplanation == 2">

                            <textarea name="expnation_{{idx}}" id="" rows="4" maxlength="300"
                              [(ngModel)]="opt.explanation" #explanation="ngModel" placeholder="Enter Your Answer"
                              class="w-100"></textarea>
                          </div>

                          <!-- <mat-error class="mat-form-field-subscript-wrapper " *ngIf="isReqRadio  && last">Please select any option
                        </mat-error> -->

                        </div>

                      </li>

                      <li
                        *ngIf="item.iSquestionExplanation == 1 && item.typeId != queDescriptive && item.typeId!=queRatings">
                        <span class="w-100 extra-info">{{item.questionExplanation}}</span>
                        <div class="w-100 extra-value">

                          <textarea name="" id="" rows="4" [ngModelOptions]="{standalone: true}" maxlength="300"
                            [(ngModel)]="item.explanation" #questionExplanation="ngModel"
                            placeholder="Enter Your Answer" class="w-100"></textarea>
                        </div>
                      </li>
                    </ul>



                    <ul class="answerList" *ngIf="item.typeId==queDescriptive" [ngClass]="{'disabled':pageId == 1}">
                      <li *ngFor="let opt of item?.options;let idx=index">
                        <div class="w-100 extra-value">
                          <span class="w-100 extra-info">Descriptive</span>

                          <textarea name="" id="" rows="4" class="w-100" [(ngModel)]="opt.description"
                            #explanation="ngModel" name="explanation"></textarea>
                          <!-- <mat-error class=" mt-4 mb-1 pt-1  mat-form-field-subscript-wrapper "
                          *ngIf="frmsurvey.submitted && explanation.invalid">
                          <mat-error *ngIf="explanation.errors.required">Descriptive is required
                          </mat-error>
                        </mat-error> -->
                        </div>
                      </li>
                    </ul>
                    <ul class="answerList" *ngIf="item.typeId==queRatings" [ngClass]="{'disabled':pageId == 1}">
                      <li *ngFor="let opt of item?.options;let idx=index">
                        <div class="rating-get mt-3 mb-3">
                          <a title="poor" class="rate-numb" [class.active]="opt.rating == 1"
                            (click)="onrating(i,idx,1)"><span>1</span></a>
                          <a class="rate-numb" [class.active]="opt.rating == 2"
                            (click)="onrating(i,idx,2)"><span>2</span></a>
                          <a class="rate-numb" [class.active]="opt.rating == 3"
                            (click)="onrating(i,idx,3)"><span>3</span></a>
                          <a class="rate-numb" [class.active]="opt.rating == 4"
                            (click)="onrating(i,idx,4)"><span>4</span></a>
                          <a title="Excellent" class="rate-numb" [class.active]="opt.rating == 5"
                            (click)="onrating(i,idx,5)"><span>5</span></a>
                        </div>
                      </li>
                      <li *ngIf="item.iSquestionExplanation == 1 && item.typeId != queDescriptive"><span
                          class="w-100 extra-info">{{item.questionExplanation}}</span>
                        <div class="w-100 extra-value">

                          <textarea name="" id="" rows="4" [ngModelOptions]="{standalone: true}" maxlength="300"
                            [(ngModel)]="item.explanation" #questionExplanation="ngModel"
                            placeholder="Enter Your Answer" class="w-100"></textarea>
                        </div>
                      </li>
                      <mat-error class="mat-form-field-subscript-wrapper " *ngIf="isReqRating">Rating is required
                      </mat-error>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>
<div class="modal generate-confirm" tabindex="-1" role="dialog"  [style]="isShowPopupForDownload ? 'display: block;' : 'display :none'">
  <div class="modal-dialog mat-dialog-container " role="document">
    <div class="modal-content border-0">
      <div class="modal-header">
        <h6 class="modal-title"> Generate Intake Packet Pdf Confirmation</h6>
        <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OncloseDownloadDetailspopup()" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"></div>
      <div >

    <div class="col-md-12">
      <div class="row mb-2">
        <div class="col-md-12">
          <div >
            <div class="mb-4">Thank you for selecting "Generate PDF" option. You will receive a notification and Email about the status. Once the PDF is generated, Kindly click on the "Download PDF" option to download the file. 
              </div>
            <div class=" text-right">
            <button type="button" class="btn primary-btn-filled float-right" data-dismiss="modal" (click)="OnGenarateIntakePdf()" >Proceed
            </button>
            <button type="button" class="btn btn-cancel mr-2 "  data-dismiss="modal" (click)="OncloseDownloadDetailspopup()" >May be Later
            </button>
            </div>
        
          <!-- </tbody>
        </table> -->
        </div>
        </div>





        </div>
    </div>
    </div>
  </div>
  </div>
</div>
<div class="modal generate-confirm" tabindex="-1" role="dialog"  [style]="isShowPopupForDownloadConfirmation ? 'display: block;' : 'display :none'">
  <div class="modal-dialog mat-dialog-container " role="document">
    <div class="modal-content border-0">
      <div class="modal-header">
        <h6 class="modal-title"> Download PDF Confirmation</h6>
        <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OnDownloadDetailspopupClose()" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"></div>
      <div >

    <div class="col-md-12">
      <div class="row mb-2">
        <div class="col-md-12">
          <div >
            <div class="mb-4">Note: The content in the PDF is saved based on the   {{global.globalUtctimeformat(pdfGeneratedDate) |  date: 'MM/dd/yyyy'}} and {{global.globalUtctimeformat(pdfGeneratedDate) |   date: 'hh:mm a'}} during the selection of the "Generate PDF" option.
              If you have updated the documents post-selection, the changes will not be saved.
              </div>
            <div class=" text-right">
            <button type="button" class="btn primary-btn-filled float-right" data-dismiss="modal" (click)="OnGenarateIntakePdfDownload(0)" >Download Anyways
            </button>
            <button type="button" class="btn btn-cancel mr-2 "  data-dismiss="modal" (click)="OnDownloadDetailspopupClose()" >Close
            </button>
            </div>
        
          <!-- </tbody>
        </table> -->
        </div>
        </div>





        </div>
    </div>
    </div>
  </div>
  </div>
</div>

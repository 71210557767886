<div class="top-btn-section clearfix mb-2">
  <h2 class="head-section mb-2 mt-2">Intake Update</h2>
  <div class="col-md-5 ml-auto pr-0 text-right">
    <button  class="btn back-btn-new float-none mr-2" (click)="onBack()">
      <i class="fas fa-chevron-left mr-2"> 

    </i>Back</button>
    <button class="btn generate-btn mr-2" (click)="generatePdf()">
      <i class="far fa-file-pdf mr-2"></i>Generate PDF
    </button>
    <button class="btn back-btn-new mr-2" type="submit" form="frmintakeupdate" (click)="onSaveUpdateIntake()">
      Save
    </button>
  </div>
</div>
<div class="d-flex met-info-top w-100">
  <i class="fas fa-user"></i>
  <div class="mr-4">
    Client Name

    <span class="imp-info">{{clientname}}</span>
  </div>
  <div class="mr-4">
    DOB
    <span class="imp-info">{{
      intakeupdateDtoPatient?.dob | date : "MM/dd/YYYY"
      }}</span>
  </div>
  <div class="mr-4">
  </div>
  <div class="mr-4">
    Updated On
    <span class="imp-info">{{ intakeupdateDto?.updatedOn | date : "MM/dd/YYYY" }}</span>
  </div>

</div>
<form class="intake-form" id="frmintakeupdate" autocomplete="off" #frmintakeupdate="ngForm">
  <div class="date-info d-flex align-items-center">
    <div class="label-container">
      <mat-label>Date</mat-label>
    </div>
    <div class="input-container">
      <mat-form-field class="button-container">
        <input matInput [matDatepicker]="pstpicker" readonly (click)="pstpicker.open()"
          [(ngModel)]="intakeupdateDto.date" name="date">
        <mat-datepicker-toggle matSuffix [for]="pstpicker">
        </mat-datepicker-toggle>
        <mat-datepicker #pstpicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="section">
    <h6 class="comments_heading mt-3 font-500">1. Undesirable behaviors (in school, home and community)</h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.behaviors" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.behaviors" (textChange)="intakeupdateDto.behaviors = $event" #behaviors="ngModel"
      name="behaviors" ngDefaultControl></app-word-counter-textarea>

  </div>


  <div class="section">
    <h6 class="comments_heading mt-3 font-500">2. Current Diagnosis/es</h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.currentDiagnosis" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.currentDiagnosis" (textChange)="intakeupdateDto.currentDiagnosis = $event"
      #currentDiagnosis="ngModel" name="currentDiagnosis" ngDefaultControl></app-word-counter-textarea>

  </div>
  <div class="section">
    <h6 class="comments_heading mt-3 font-500">3. Current Medications </h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.currentMedications" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.currentMedications" (textChange)="intakeupdateDto.currentMedications = $event"
      #currentMedications="ngModel" name="currentMedications" ngDefaultControl></app-word-counter-textarea>

  </div>
  <div class="section">
    <h6 class="comments_heading mt-3 font-500">4. Current outpatient providers/current support (if applicable)</h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.currentsupport" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.currentsupport" (textChange)="intakeupdateDto.currentsupport = $event"
      #currentsupport="ngModel" name="currentsupport" ngDefaultControl></app-word-counter-textarea>

  </div>
  <div class="section">
    <h6 class="comments_heading mt-3 font-500">5. School classification and placement (if applicable)</h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.schoolPlacement" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.schoolPlacement" (textChange)="intakeupdateDto.schoolPlacement = $event"
      #schoolPlacement="ngModel" name="schoolPlacement" ngDefaultControl></app-word-counter-textarea>

  </div>
  <div class="section">
    <h6 class="comments_heading mt-3 font-500">6. Homicidal/suicidal threats, ideation, attempt, gestures, self-harm
      behavior, and/or aggression toward others</h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.homodicalorsuicidal" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.homodicalorsuicidal" (textChange)="intakeupdateDto.homodicalorsuicidal = $event"
      #homodicalorsuicidal="ngModel" name="homodicalorsuicidal" ngDefaultControl></app-word-counter-textarea>

  </div>
  <div class="section">
    <h6 class="comments_heading mt-3 font-500">7. Stressors (environmental, relational, medical, etc.)</h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.stressors" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.stressors" (textChange)="intakeupdateDto.stressors = $event" #stressors="ngModel"
      name="stressors" ngDefaultControl></app-word-counter-textarea>

  </div>
  <div class="section">
    <h6 class="comments_heading mt-3 font-500">8. Family Situation (current living situation)</h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.familySituation" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.familySituation" (textChange)="intakeupdateDto.familySituation = $event"
      #familySituation="ngModel" name="familySituation" ngDefaultControl></app-word-counter-textarea>

  </div>
  <div class="section">
    <h6 class="comments_heading mt-3 font-500">9. Hospitalizations or ER psychiatric visits since last intake (if
      applicable) </h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.hospitalizations" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.hospitalizations" (textChange)="intakeupdateDto.hospitalizations = $event"
      #hospitalizations="ngModel" name="hospitalizations" ngDefaultControl></app-word-counter-textarea>

  </div>
  <div class="section">
    <h6 class="comments_heading mt-3 font-500">10. Recommended Goals/Objectives</h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.recommendedGoals" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.recommendedGoals" (textChange)="intakeupdateDto.recommendedGoals = $event"
      #recommendedGoals="ngModel" name="recommendedGoals" ngDefaultControl></app-word-counter-textarea>

  </div>
  <div class="section">
    <h6 class="comments_heading mt-3 font-500">11. Current hobbies/interests/motivators </h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.currenthobbies" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.currenthobbies" (textChange)="intakeupdateDto.currenthobbies = $event"
      #currenthobbies="ngModel" name="currenthobbies" ngDefaultControl></app-word-counter-textarea>

  </div>
  <div class="section">
    <h6 class="comments_heading mt-3 font-500">12. Strengths</h6>

    <app-word-counter-textarea class="counter-textarea" [text]="intakeupdateDto.strengths" [maxlength]="3000"
      [(ngModel)]="intakeupdateDto.strengths" (textChange)="intakeupdateDto.strengths = $event" #strengths="ngModel"
      name="strengths" ngDefaultControl></app-word-counter-textarea>

  </div>

  <div class="row form-row">
    <div class="label-containers">
      <mat-label>Clinician Name</mat-label>
    </div>
    <select class="form-control mb-2 cln_name" 
                [(ngModel)]="intakeupdateDto.clinicianName" #clinicianName="ngModel" name="clinicianName" >
                <option value="undefined">--Select ClinicianName--</option>
                <option *ngFor="let a of ddlStafflist" value="{{a.staffId}}">
                   {{a.firstName}} {{a.lastName}}
                </option>
             </select>
  </div>
  <div class="form-row no-gap row11">
    <div class="col-md-12 pl-0">
      <div class="form-group clearfix m-0">
        <div class="float-left col-md-4 p-0 mt-3 pt-1">
          <label class="treat-label mr-2 pl-0" style="padding-left :9px;">Client’s Signature:</label>
          <img id='esign' height="50px" width="300px" style="border: 1px solid grey;">
        </div>
        <div class="float-left col-md-4 p-0 mt-3 pt-1 ">

          <button type="button" (click)="openDialog()" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2">
            Add E-Sign
          </button>
        </div>

      </div>
    </div>
  </div>
</form>
<a mimetype="application/octet-stream" #downloadLink></a> 

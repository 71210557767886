<div class="progress-notes-page attendance-page">
  <div class="top-btn-sectionclearfix mb-1 w-100">
    <span class="ml-2 mt-2 head-span fw700">
      <img src="../../assets/images/avator.jpg" alt="avatar" height="35" width="35" />
      <span class="ml-2">{{CurrentCheckInData?.clinicianName}}</span>
    </span>
    <ul class="list-group list-group-horizontal-md bg-transparent">
      <li class="list-group-item bg-transparent"><label>Client Type :</label><span
          class="ml-2">{{CurrentCheckInData?.isIop?'IOP':'PCP'}}</span></li>
      <li class="list-group-item bg-transparent px-0"><span class="px-0">|</span></li>
      <li class="list-group-item bg-transparent"><label>Time :</label><span class="ml-2">{{CurrentCheckInData?.startTime |
          date: 'h:mm'}}-{{getEndTime(CurrentCheckInData?.startTime,CurrentCheckInData?.duration)}}</span></li>
    </ul>
  </div>
  <div class="row w-100">
    <div class="col-md-12 px-4">

      <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="onTabChangeProgress($event.index)">
        <mat-tab *ngFor="let item of CheckInList;let i = index;" label="{{item.themeName}}" label="Group1"
          class="triangle-up px-5">
          <div class="px-5 table-height">
            <table class="notes-table attendance-table">
              <tr class="tr-row">
                <th class="th-cols pl-2">SNO</th>
                <th class="th-cols-width text-center">
                  Name</th>
                <th class="text-center">Age</th>
                <th class="text-center">Phone #</th>
                <th class="text-center">ALLERGIES/NUTRITIONAL NOTES</th>
                <th class="text-center">{{item.schduleDate | date: 'dd/MM/yyy'}}</th>
              </tr>
              <tr *ngFor="let clientData of item.groupDetails.clientList;let y=index">
                <td class="bg-gray p-2">{{y+1}}</td>
                <td class="bg-gray p-2 text-center">{{clientData.patientName}}</td>
                <td class="bg-gray p-2 text-center"> {{clientData.age}}
                </td>
                <td class="bg-gray p-2 text-center w200"> {{clientData.phoneNumber | mask: '(000) 000-0000'}}
                </td>
                <td class="bg-gray p-2 text-center">
                  <input type="text" name="seasonal" [title]="clientData.allergicDetails" [value]="clientData.allergicDetails"
                    class="text-center fw700 fs14 border-0 text-color-blue text-uppercase" disabled />
                </td>
                <td class="bg-gray p-2 text-center">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="{{clientData.checkIn.fieldName+i+y}}"  
                    [ngModelOptions]="{standalone: true}" [checked]="clientData.checkIn.value"
                    (click)="selectUnselectAction(clientData.checkIn,item)">
                    <label class="custom-control-label" for="{{clientData.checkIn.fieldName+i+y}}">
                    </label>
                  </div>
                </td>
              </tr>
              <tr class="border-bottom-gray">
                <td colspan="5"></td>
                <td>
                  <input class="w-100 bg-white border-0 text-center fw700" type="text" name="seasonal" [value]="item.groupDetails.checkInCount+' of '+item.groupDetails.totalCount"
                    disabled />
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-12 mt-2">
            <div class="text-right">
              <button type="button" mat-stroked-button class="btn btn-cancel primary-btn-m h-40" (click)="onCancel()" >Cancel</button>
              <button type="button" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 ml-4" (click)="onSave()" >Save</button>

            </div>
          </div>
        </mat-tab>
        <!-- <mat-tab label="Client Name 2" class="triangle-up px-5"> </mat-tab>
          <mat-tab label="Client Name 3" class="triangle-up px-5"> </mat-tab> -->
      </mat-tab-group>
    </div>
  </div>

</div>
<div class="lead-section">
  <div class="top-btn-section clearfix">
    <h2 class="head-section mb-2 mt-0">Phone book</h2>
  </div>
  <div class="col-md-12 p-0 mb-3">
    <div class="lead-box">
      <form (ngSubmit)="phbookform.form.valid" id="phbookform" autocomplete="off"
        #phbookform="ngForm" novalidate>
        <div class="box-lead-head">Phone book information</div>


        <div class="col-md-12 p-0">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput placeholder="First Name" maxlength="50" appAlphabetOnly
                    [(ngModel)]="phonebookData.firstName" #firstName="ngModel" name="firstName"
                    [ngClass]="{ 'is-invalid': phbookform.submitted && firstName.invalid }" required>
                  <mat-error *ngIf="phbookform.submitted && firstName.invalid">
                    <mat-error *ngIf="firstName.errors.required">First Name is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput placeholder="Last Name" maxlength="50" appAlphabetOnly
                    [(ngModel)]="phonebookData.lastName" #lastName="ngModel" name="lastName"
                    [ngClass]="{ 'is-invalid': phbookform.submitted && lastName.invalid }" required>
                  <mat-error *ngIf="phbookform.submitted && lastName.invalid">
                    <mat-error *ngIf="lastName.errors.required">Last Name is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <mat-form-field class="w-100">
                   <input matInput placeholder="Occupation" maxlength="70" appAlphabetOnly
                  [(ngModel)]="phonebookData.occupation" #occupation="ngModel" name="occupation"
                  [ngClass]="{ 'is-invalid': phbookform.submitted && occupation.invalid }"  required>
                <mat-error *ngIf="phbookform.submitted && occupation.invalid">
                  <mat-error *ngIf="occupation.errors.required">Occupation is required</mat-error>
                </mat-error>
               </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput placeholder="Email" maxlength="50" [(ngModel)]="phonebookData.email" #email="ngModel"
                    name="email" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                    [ngClass]="{ 'is-invalid': phbookform.submitted && email.invalid }" required>
                  <mat-error *ngIf="phbookform.submitted && email.invalid">
                    <mat-error *ngIf="email.errors.required">Email is required</mat-error>
                    <mat-error *ngIf="email.errors?.pattern">Please enter valid email address</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput placeholder="Office Phone Number" mask='(000) 000-0000'
                    [(ngModel)]="phonebookData.phoneNumber" #phoneNumber="ngModel" name="phoneNumber"
                    [ngClass]="{ 'is-invalid': phbookform.submitted && phoneNumber.invalid }" required>
                  <mat-error *ngIf="phbookform.submitted && phoneNumber.invalid">
                    <mat-error *ngIf="phoneNumber.errors.required">Office Phone Number is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput placeholder="Cell Phone Number" mask='(000) 000-0000'
                    [(ngModel)]="phonebookData.cellPhone" #cellPhone="ngModel" name="cellPhone"
                    [ngClass]="{ 'is-invalid': phbookform.submitted && cellPhone.invalid }">
                  <mat-error *ngIf="phbookform.submitted && cellPhone.invalid">
                    <mat-error *ngIf="cellPhone.errors.required">Cell Phone is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput placeholder="Fax Number" mask='(000) 000-0000'
                    [(ngModel)]="phonebookData.faxNumber" #faxNumber="ngModel" name="faxNumber"
                    [ngClass]="{ 'is-invalid': phbookform.submitted && faxNumber.invalid }" >
                  <mat-error *ngIf="phbookform.submitted && faxNumber.invalid">
                    <mat-error *ngIf="faxNumber.errors.required">Fax Number is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <mat-form-field class="w-100">
                  <input matInput placeholder="Facility Name" appAlphabetOnly maxlength="50"
                    [(ngModel)]="phonebookData.hospitalName" #hospitalName="ngModel" name="hospitalName"
                    [ngClass]="{ 'is-invalid': phbookform.submitted && hospitalName.invalid }" required>
                  <mat-error *ngIf="phbookform.submitted && hospitalName.invalid">
                    <mat-error *ngIf="hospitalName.errors.required">Facility Name is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- <div class="col-md-4">
              <div class="form-group">

                <mat-form-field class="w-100">
                  <input matInput placeholder="Hospital Address" maxlength="96"
                    [(ngModel)]="phonebookData.hospitalAddress" #hospitalAddress="ngModel" name="hospitalAddress"
                    [ngClass]="{ 'is-invalid': phbookform.submitted && hospitalAddress.invalid }" required>
                  <mat-error *ngIf="phbookform.submitted && hospitalAddress.invalid">
                    <mat-error *ngIf="hospitalAddress.errors.required">Hospital Address is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div> -->
            <div class="col-md-3">
              <div class="form-group">

                <mat-form-field class="w-100">
                  <input matInput placeholder="Address Line1" maxlength="40" [(ngModel)]="phonebookData.addressLine1"
                    #addressLine1="ngModel" name="address Line1"
                    [ngClass]="{ 'is-invalid': phbookform.submitted && addressLine1.invalid }" required>
                  <mat-error *ngIf="phbookform.submitted && addressLine1.invalid">
                    <mat-error *ngIf="addressLine1.errors.required">Address Line1 is required</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">

                <mat-form-field class="w-100">
                  <input matInput placeholder="Address Line2" maxlength="30" [(ngModel)]="phonebookData.addressLine2"
                    #addressLine2="ngModel" name="address Line2"
                    [ngClass]="{ 'is-invalid': phbookform.submitted && addressLine2.invalid }">
                  <!-- <mat-error *ngIf="phbookform.submitted && AddressLine2.invalid">
                    <mat-error *ngIf="AddressLine2.errors.required">Hospital Address is required</mat-error>
                  </mat-error> -->
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-3">

              <mat-form-field class="w-100">
                <!-- <input type="text" placeholder="City" matInput [(ngModel)]="selectedCityId"  name="cityId1"    aria-label="Number" 
                [formControl]="cityCtrl" [matAutocomplete]="auto"  required>
               <mat-autocomplete #auto="matAutocomplete"  [displayWith]="getCityname.bind(this)">
                       <mat-option *ngFor="let opt of  filteredcities" [value]="opt.CitiesId">
                         {{opt.CityName}}
                       </mat-option>
                     </mat-autocomplete> -->
      
                  <input type="text" placeholder="City" [(ngModel)]="phonebookData.cityId" matInput name="cityId1"   aria-label="Number" 
                       [formControl]="cityCtrl " [matAutocomplete]="auto"  required>
                      <mat-autocomplete #auto="matAutocomplete"  [displayWith]="getCityname.bind(this)" >
                              <mat-option *ngFor="let opt of  filteredcities" [value]="opt.CitiesId">
                                {{opt.CityName}}
                              </mat-option>
                            </mat-autocomplete>
          
                <!-- <mat-select [(ngModel)]="phonebookData.cityId" #cityId="ngModel" name="cityId" filteredcities
                  [ngClass]="{ 'is-invalid': phbookform.submitted && cityId.invalid }" required>
                  <mat-option *ngFor="let item of ddlCities" [value]="item.CitiesId">
                    {{item.CityName}}
                  </mat-option>
                </mat-select> -->
                
                 <mat-error *ngIf="phbookform.submitted && selectedCityId.invalid">
                  <mat-error *ngIf="selectedCityId.errors.required">City is required</mat-error>
                
                </mat-error> 
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field class="w-100">
               <mat-label>State</mat-label>  
                <mat-select [(ngModel)]="phonebookData.statesId" #statesId="ngModel"
                 name="statesId"
                  [ngClass]="{ 'is-invalid': phbookform.submitted && statesId.invalid }" required>
                  <mat-option *ngFor="let item of ddlStates" [value]="item.StatesId">
                    {{item.StateName}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="phbookform.submitted && statesId.invalid">
                  <mat-error *ngIf="statesId.errors.required">State is required</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          
            <div class="col-md-3">
              <mat-form-field class="w-100">
                <input matInput placeholder="Zip Code" mask='00000' [(ngModel)]="phonebookData.zipCode"
                  #zipCode="ngModel" name="zipCode"
                  [ngClass]="{ 'is-invalid': phbookform.submitted && zipCode.invalid }" required>
                <mat-error *ngIf="phbookform.submitted && zipCode.invalid">
                  <mat-error *ngIf="zipCode.errors.required">Zip Code is required</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right">
          <!-- <button type="button" (click)="Import()" mat-stroked-button *ngIf="roleAccess.isView"
            class="btn import-btn primary-btn-m mb-2 h-40 mr-2">Import</button> -->

          <!-- <button type="submit" mat-stroked-button  *ngIf="roleAccess.isCreate || roleAccess.isEdit" -->
          <button type="submit" mat-stroked-button  form="phbookform"
            class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="addorUpdate(phbookform)">{{!isEditPhonebook ? 'Save' :
            'Update'}}</button>
            <!-- <button
            type="submit"
            mat-stroked-button
            title="Save" 
            class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="Onsave(blocktimeform)"
          >
          {{!isEditBlocktime ? 'Save' :
          'Update'}}
          </button> -->
          <button (click)="resetphonebookform(phbookform)" type="button" mat-stroked-button  *ngIf="roleAccess.isEdit || roleAccess.isDelete"
            class="btn btn-cancel primary-btn-m h-40">Cancel</button>
        </div>
        <div class="col-md-12 text-right" style="padding-right: 42px;">

          <button type="button" (click)="Import()" mat-stroked-button *ngIf="roleAccess.isView"
            class="btn import-btn primary-btn-m mb-2 h-40 mr-2">Import</button>
        </div>   
      </form>
    </div>
  </div>
  <div *ngIf="showImport" class="clearfix">
    <!-- <h6 class="card-title text-dark mt-1 mb-2">
      Upload
    </h6> -->
          
    <div class="w-100 bg-white p-2 float-left d-none">
      <div class="card rounded-0">
        <div class="card-body  pl-0 pr-0 pt-2 pb-0">
          <div class="w-100 float-left">
            <div class="pl-4 col-md-12 p-0">
              <div class="row">
                <div class="form-group col-md-4  mb-0">
                  <div class="float-left col-md-12 d-none">
                    <!-- <span class="text-muted mr-2 float-left">Step - 1</span> -->
                    <a class="float-left text-decoration-none" (click)="ondownloadtemplate()" [href]='global.ImportTemplateUrl' target="_self"
                      download>
                      <span class="text-muted" aria-hidden="true">
                        <svg width="1em" height="1em" viewBox="0 0 16 16"
                          class="bi bi-cloud-arrow-down text-decoration-none fa-2x" fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd"
                            d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                          <path fill-rule="evenodd"
                            d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z" />
                        </svg>

                      </span>
                    </a>
                    <div class="text-muted align-middle pl-2 mt-1 float-left docc-text " *ngIf="!istemplatedownloaded">Download Template</div>
                    <div class="text-muted align-middle pl-2 mt-1 float-left docc-text " *ngIf="istemplatedownloaded"> Download Template</div>
                    <div class="float-left uploaded w-100 -file mr-1 mb-2 text-danger"
                      *ngFor="let a of UploadFileList;let index = index;">
                      <span class="file-lenght float-left" title="{{a.name}}">{{a.name}}</span>
                      <span (click)="removeSelectedImport(index)"
                        class="close text-danger float-left ml-2 cursor-pointer">&times;</span>
                    </div>
                    <!-- <a class="float-left text-decoration-none" [href]='global.PRImportTemplateUrl'
                    (click)="updateDownloadedMTemplate()" target="_blank" download> 
                    <span class="text-muted" aria-hidden="true">
                      <svg width="1em" height="1em" viewBox="0 0 16 16"
                        class="bi bi-cloud-arrow-down text-decoration-none fa-2x" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                        <path fill-rule="evenodd"
                          d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708l2 2z" />
                      </svg>
                      <div class="text-muted text-decoration-none download-text">{{global.MasterTemplatePRImportlbl}}
                      </div>
                    </span>
                  </a>-->
                  </div>
                  <!-- *ngIf="istemplatedownloaded" -->
                  <!-- <div class="float-left col-md-6  d-none" >
                    <div class="text-muted w-100 float-left">Step - 2</div>
                    <div class="text-muted w-100 float-left docc-text">Attach the filled template</div>
                  </div> -->
                </div>
                <!-- *ngIf="istemplatedownloaded" -->
                <div class="form-group col-md-6  " >
                  <div class="float-left col-md-6 ">
                    <!-- <div class="text-muted w-100 float-left">Step - 3</div> -->
                    <div class="text-muted w-100 float-left docc-text">Upload file to import</div>
                  </div>
                  <!-- *ngIf="istemplatedownloaded" -->
                  <div class="float-left col-md-6 mt-3" >
                    <div class="row">
                      <div class="col-md-6 text-right">
                        <div class="upload-btn-wrapper">
                          <button class="btn file-add cursor-pointer float-right primary-btn-outlined">Add
                            Files</button>
                          <input accept=".doc,.xlsx,.xls" title="Add Files" type="file" (change)="onFileChange($event)"
                            class=" cursor-pointer" #importphbook />
                        </div>
                      </div>
                      <div class="col-md-6 text-right">

                        <button [ngStyle]="{'display': UploadFileList.length > 0  ? 'block' : 'none' }" type="button"
                          (click)="onImportPhoneBook()"
                          class="btn file-add cursor-pointer primary-btn-outlined">Upload</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new-design-import">
      <div class="col-md-12 text-center">
        <i class="fas fa-file-download"></i>
        <h6>Choose the File to be Imported</h6>
        <div class="sub-text-import">[Only xls,xlsx and csv formats are supported]</div>
        <div class="upload-btn-wrapper mt-2 mb-2 mr-2">
          <button class="btn">Add Files</button>
          <input accept=".doc,.xlsx,.xls" title="Add Files" type="file" (change)="onFileChange($event)"
                            class=" cursor-pointer" #importphbook />
        </div>
        <div class="upload-btn-wrapper mt-2 mb-2">
          <button class="btn" [ngStyle]="{'display': UploadFileList.length > 0  ? 'block' : 'none' }" type="button"
            (click)="onImportPhoneBook()">Upload File</button>
        </div>
        
        <a  class="sample-btn" (click)="ondownloadtemplate()" [href]='global.ImportTemplateUrl' target="_self"
        download>Downlaod Sample Template for Import</a>
        <div class="webix_scroll_cont">
          <div *ngFor="let a of UploadFileList;let index = index;" class="webix_list_item" style=" overflow:hidden;" >
            <div class="webix_remove_upload" (click)="removeSelectedImport(index)">
              <span class="cancel_icon">&times;</span>
            </div>
            <div style="text-overflow:ellipsis; white-space:nowrap; overflow:hidden; padding-right:8px;" title="{{a.name}}">{{a.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="top-btn-section clearfix" *ngIf="showimportfailure">
    <div class="table-responsive" *ngIf="this.importfails.length>0 ">
    <h2 class="head-section mb-2 mt-0">Import Summary</h2>
    <div class="ml-auto summary-count mt-3">
      <label >Total: <span>{{importCount}}</span></label>
      <label class="text-success">Success: <span>{{importSCount}}</span></label>
      <label class="text-danger" >Failure: <span>{{importFCount}}</span></label>
    </div>
  </div>
  </div>
  <div class="col-md-12 table-custom p-0 mb-2" *ngIf="showimportfailure ">
    <div class="table-responsive" *ngIf="this.importfails.length>0 ">
      <div class="table-responsive">
      <mat-table class="lessons-table mat-elevation-z8 w-100" [dataSource]="dataSourceimport">

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> S.No </mat-header-cell>
          <mat-cell *matCellDef="let customer;let i = index;">{{i+1}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
          <mat-cell class="description-cell" *matCellDef="let customer">{{customer.firstName}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef>Last Name</mat-header-cell>
          <mat-cell class="description-cell" *matCellDef="let customer">{{customer.lastName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
          <mat-header-cell *matHeaderCellDef>Phone Number</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let customer">{{customer.phoneNumber | mask: '(000) 000-0000'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="hospitalName">
          <mat-header-cell *matHeaderCellDef>Facility </mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let customer">{{customer.hospitalName}}
          </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="hospitalAddress">
          <mat-header-cell *matHeaderCellDef>Address</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let customer">{{customer.addressLine1}},
            {{customer.addressLine2}} {{customer.addressLine2 !== '' ? ',' : ''}}{{customer.state}},
            {{customer.city}}, {{customer.zipCode}}
          </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="hospitalAddress">
          
          <mat-header-cell *matHeaderCellDef>Address</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let customer">{{customer.addressLine1}},
            {{customer.addressLine2}} {{customer.addressLine2 !== '' ? ',' : ''}}{{customer.state}},
            {{customer.city}}, {{customer.zipCode}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Actions" *ngIf = " roleAccess.isDelete || roleAccess.isEdit " >

          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let customer ;let i = index;">
            <button type="button" mat-icon-button matTooltip="Edit" type="button" (click)="oneditsummary(customer,i)" *ngIf="roleAccess.isEdit"
              color="primary">
              <img src="../../../assets/images/editblue.png">
            </button>
             <!-- <button type="button" mat-icon-button matTooltip="Delete" type="button" 
              (click)="ondeletePhonebook(customer)" color="warn">
              <img src="../../../assets/images/deletered.png">
            </button> -->
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsimport"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsimport"></mat-row>
      </mat-table>
      <mat-paginator #errorpaginator [pageSize]="20"[pageSizeOptions]="[ 10, 20, 50, 75, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  </div>
  <div class="top-btn-section clearfix" >
    <h2 class="head-section mb-2 mt-0">Phonebook list</h2>

  </div>
  <div class="col-md-12 table-custom p-0 mb-2" >
    <div class="table-responsive">
      <mat-table class="lessons-table mat-elevation-z8 w-100" [dataSource]="dataSource">

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> S.No </mat-header-cell>
          <mat-cell *matCellDef="let customer;let i = index;">{{i+1}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
          <mat-cell class="description-cell" *matCellDef="let customer">{{customer.firstName}}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef>Last Name</mat-header-cell>
          <mat-cell class="description-cell" *matCellDef="let customer">{{customer.lastName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phoneNumber">
          <mat-header-cell *matHeaderCellDef>Phone Number</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let customer">{{customer.phoneNumber | mask: '(000) 000-0000'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="hospitalName">
          <mat-header-cell *matHeaderCellDef>Facility</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let customer">{{customer.hospitalName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="hospitalAddress">
          <mat-header-cell *matHeaderCellDef>Address</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let customer">{{customer.addressLine1}},
            {{customer.addressLine2}} {{customer.addressLine2 !== '' ? ',' : ''}}{{customer.state}},
            {{customer.city}}, {{customer.zipCode}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Actions">

          <mat-header-cell *matHeaderCellDef>{{  (roleAccess.isEdit || roleAccess.isDelete) ? Actions : ''  }}</mat-header-cell>
          <mat-cell class="duration-cell" *matCellDef="let customer">
            <button type="button" mat-icon-button matTooltip="Edit" role="button" class="cursor-pointer" type="button" (click)="oneditPhonebook(customer)"
              color="primary" *ngIf="roleAccess.isEdit">
              <img src="../../../assets/images/editblue.png">
            </button>
            <button type="button" mat-icon-button matTooltip="Delete" type="button" role="button"  class="cursor-pointer"
              (click)="ondeletePhonebook(customer)" color="warn"  *ngIf="roleAccess.isDelete">
              <img src="../../../assets/images/deletered.png">
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="20"[pageSizeOptions]="[ 10, 20, 50, 75, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>
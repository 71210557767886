import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import * as moment from 'moment';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-treatmenthistory',
  templateUrl: './treatmenthistory.component.html',
  styleUrls: ['./treatmenthistory.component.scss']
})
export class TreatmenthistoryComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  patientAccountId:any;
  treatmenthistory:any;
  patientInfoId : any;
  patientinfo:any;
  SelectedTab=1;
  leadhistory:any=[];
  DecrptedlstLead: any;
  defaultRecords=5;
  dataSource: MatTableDataSource<any>;
  GetActivityId: any;
  location: number;
  treatmentPlanMultiple : any;
  treatmentPlan : any;
  constructor(public global: Global, public treatmentservice: TreatmentService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.location = Number(this.commonService.FrontEndDecryption(params.location).replace(/"/g, ""));
      }
     
    });
    this.getTreatmentHistory();
    this.getMultipleTreatmentPlan();
    this.patientInfoId = Number(localStorage.getItem("patientId"));
  }
  getMultipleTreatmentPlan()
  {
    this.treatmentservice.getMultipleTreatmentPlan(this.patientAccountId).subscribe(
      (res: any) => {
        if (res != null) {
          this.treatmentPlanMultiple=res;
          this.treatmentPlanMultiple.mtpname1='';

this.treatmentPlanMultiple.forEach(item => {

 const updatedDate = new Date(item.treatmentPlanDate);
 const month = String(updatedDate.getMonth() + 1).padStart(2, '0');
 const day = String(updatedDate.getDate()).padStart(2, '0');
 const year = updatedDate.getFullYear();
 
 item.mtpname1 = `${item.mtpname} & ${month}/${day}/${year}`;
//   const updatedDateFormatted = new Date(item.treatmentPlanDate).toLocaleDateString('en-US');
//   item.mtpname = `${item.mtpname} - ${updatedDateFormatted}`;
});
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getTreatmentHistory(){
    this.spinnerservice.show();
    this.treatmentservice.getTreatmentHistory(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {

          this.treatmenthistory=res.treatmentHistory;
          this.patientinfo=res.treatmentHistoryInfo;

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onGenerateIntake(item) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-generate'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId),patientid: this.commonService.FrontEndEncryption(item.patientInfoId),isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } }));
    window.open(url, '_blank');
    this.global.ismytask =false;
   // this.router.navigate(['/intake-generate'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId),patientid: this.commonService.FrontEndEncryption(item.patientInfoId),isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } });
  }
  onintakechma(item) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-chma'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId),patientid: this.commonService.FrontEndEncryption(item.patientInfoId),isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } }));
    window.open(url, '_blank');
  }
  onTreatmentPlan(item) {
    let treatment = this.treatmentPlanMultiple.filter(x=>x.chmatreatmentSummaryId == this.treatmentPlan)[0];
    if(treatment)
    {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/treatmentplan'], { queryParams: { id: this.commonService.FrontEndEncryption(treatment.chmatreatmentSummaryId),account:this.commonService.FrontEndEncryption(treatment.patientAccountId),patient:this.commonService.FrontEndEncryption(this.patientInfoId),location:this.commonService.FrontEndEncryption(this.location)} }));
      window.open(url, '_blank');
    }
    
  }
  OntabChange(tab){
    this.SelectedTab = tab;
    if(this.SelectedTab ==2){
      this.getLeadHistory();
    }
  }

  getLeadHistory() {
    this.leadhistory = []
    this.spinnerservice.show()
    this.GetActivityId = this.patientinfo.patientInfoId
    this.treatmentservice.getLeadHistory(this.GetActivityId).subscribe(
      (res: any) => {
        if (res) {
          this.DecrptedlstLead = res
        //  this.leadhistory = res;
          this.leadhistory = this.DecrptedlstLead.slice(0, this.defaultRecords);
          this.dataSource = new MatTableDataSource(this.DecrptedlstLead);
          this.dataSource.paginator = this.paginator;
          console.log(this.leadhistory)
        }
        this.spinnerservice.hide()
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  onPageChange($event) {
    //ngfor array                 //array
    this.leadhistory = this.DecrptedlstLead.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }

  onGrourTherapy(item){
   // const url = this.router.serializeUrl(this.router.createUrlTree(['/progess-track'], { queryParams: { id: this.commonService.FrontEndEncryption(item.therapyId),date: this.commonService.FrontEndEncryption(new Date(item.schduleDate).toLocaleDateString()),dayScheduleSubGroupId:this.commonService.FrontEndEncryption(item.scheduleId)} }));
   const url = this.router.serializeUrl(this.router.createUrlTree(['/progess-track'], { queryParams: { id: this.commonService.FrontEndEncryption(item.therapyId),date: this.datepipe.transform(item.schduleDate, "MM-dd-yyyy"),dayScheduleSubGroupId:this.commonService.FrontEndEncryption(item.scheduleId),patientaccountid: this.commonService.FrontEndEncryption(this.patientAccountId)} }));
    window.open(url, '_blank');
  }
  // onClinicalNotes(item){
  //  // const url = this.router.serializeUrl(this.router.createUrlTree(['/progess-track'], { queryParams: { id: this.commonService.FrontEndEncryption(item.therapyId),date: this.commonService.FrontEndEncryption(new Date(item.schduleDate).toLocaleDateString()),clinicalNotes:true,dayScheduleSubGroupId:this.commonService.FrontEndEncryption(item.scheduleId) }}));
  //  const url = this.router.serializeUrl(this.router.createUrlTree(['/progess-track'], { queryParams: { id: this.commonService.FrontEndEncryption(item.therapyId),date: this.datepipe.transform(item.schduleDate, "MM-dd-yyyy"),clinicalNotes:true,dayScheduleSubGroupId:this.commonService.FrontEndEncryption(item.scheduleId),patientaccountid: this.commonService.FrontEndEncryption(this.patientAccountId) }}));
  //   window.open(url, '_blank');
  // }
  onClinicalNotes(item){
    if (item.therapyType ==2) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),theraphyid: this.commonService.FrontEndEncryption(1),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
    window.open(url, '_blank');  
    }
    else if (item.therapyType ==3) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(2),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
      window.open(url, '_blank');  
    }
    else if (item.therapyType ==5) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatrictherapy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(5),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
      window.open(url, '_blank'); 
  }
    else if (item.therapyType ==9) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatricmedication'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(item.therapyType),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
      window.open(url, '_blank'); 
  }
  else if (item.therapyType ==8) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/psychiatricevaluation'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),theraphyid: this.commonService.FrontEndEncryption(item.therapyType),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
    window.open(url, '_blank'); 
  }
  else if (item.therapyType ==10 ) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
    theraphyid: this.commonService.FrontEndEncryption(item.therapyType),IsFromHistory:this.commonService.FrontEndEncryption(1)
    
  } }));
  window.open(url, '_blank'); 
  }
  else if (item.therapyType ==11 ) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
    theraphyid: this.commonService.FrontEndEncryption(item.therapyType),IsFromHistory:this.commonService.FrontEndEncryption(1)
    
  } }));
  window.open(url, '_blank'); 
  }
  else if (item.therapyType ==12 ) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
      theraphyid: this.commonService.FrontEndEncryption(12),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
    window.open(url, '_blank'); 
  }
  else if (item.therapyType ==13 ) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/family-individual-theraphy'], { queryParams: { id: this.commonService.FrontEndEncryption(item.meetingScheduleId),date: this.commonService.FrontEndEncryption(moment(item.schduleDate).format("MM/DD/YYYY")),
    theraphyid: this.commonService.FrontEndEncryption(13),IsFromHistory:this.commonService.FrontEndEncryption(1)} }));
    window.open(url, '_blank'); 
  }
  }

}

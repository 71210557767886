import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LeadService } from '../lead.service';
import { Router, ActivatedRoute } from '@angular/router';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { NgForm } from '@angular/forms';
import { InsuranceAuthorization } from '../../_models/_Lead/InsAuthorizationDtl';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { Global } from 'src/app/global/global.model';
declare function decrypt(data): any;
@Component({
  selector: 'app-auth-threshold',
  templateUrl: './auth-threshold.component.html',
  styleUrls: ['./auth-threshold.component.scss'],
})
export class AuthThresholdComponent implements OnInit {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('insAuthorizationEdit') insAuthorizationEdit: NgForm;
  displayedColumns = [
    'id',
    'clientName',
    'location',
    'authorizationNumber',
    'authorizationType',
    'insurance',
    'visitsAllowded',
    'visitsCompleted',
    'utilized',
    'expiringIn',
    'Actions',
  ];
  selectedLocation: any;
  authDetails: any;
  authDetailsList: any;
  dataSource: MatTableDataSource<any>;
  roleAccess: RoelAccess;
  isShowEdit: boolean = false;
  constructor(
    private leadservice: LeadService,
    private router: Router,
    private spinservice: NgxSpinnerService,
    public roleaccessservice: PageAccessService,
    public commonService: CommonService,
    private snackbar: SnackBarService,
    public treatmentservice: TreatmentService,
    public datepipe: DatePipe,
    public global: Global
  ) {}
  authDetailsEdit: any;
  AuthorizationTypeddl: any = [];
  ddlInsuranceList: any = [];
  drpAuthorizationTrack = [];
  selectedValue: any;
  ShowActivity: boolean = false;
  authLog: any;
  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Authorization;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.authDetailsEdit = {};
    this.selectedValue = '1';
    this.getAuthorizationDetails();
    this.GetAuthorizationTypeMaster();
    this.getAuthorizationDetailTrack();
  }
  getAuthorizationDetails() {
    this.selectedLocation =
      Number(localStorage.getItem('sfcclocation')) <= 0
        ? -1
        : Number(localStorage.getItem('sfcclocation'));
        this.spinservice.show();
    this.leadservice
      .getAuthorizationThreshold(this.selectedLocation)
      .subscribe((res) => {
        this.spinservice.hide();
        if (res) {
          let data = decrypt(res.objInputString);
          this.authDetailsList = data;
          this.authDetails = data;
          this.dataSource = new MatTableDataSource(this.authDetails);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      },err=>{
        this.spinservice.hide();
      });
  }
  OnBackClick() {
    this.router.navigate(['/patientslists']);
  }
  userFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  editAuthorization(authDetails) {
    this.isShowEdit = true;
    this.getPatientInsuranceDetailList(authDetails.PatientInfoId,authDetails);
    
    //this.authDetailsEdit.NumberOfVisits =  +this.authDetailsEdit.NumberOfVisits;
  }
  onClose() {
    this.isShowEdit = false;
  }
  alphanumericwithhypen(e) {
    var code = 'charCode' in e ? e.charCode : e.keyCode;
    if (
      !(code == 45) && // hypen
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      e.preventDefault();
    }
  }
  alphanumeric(e) {
    var code = 'charCode' in e ? e.charCode : e.keyCode;
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      e.preventDefault();
    }
  }
  GetAuthorizationTypeMaster() {
    this.leadservice.getAuthorizationTypeMaster().subscribe((res: any) => {
      this.AuthorizationTypeddl = res;
      // console.log(res)
    });
  }
  getPatientInsuranceDetailList(PaInfoId,authDetails) {
    this.leadservice
      .getInsuranceDetailListByPatient(PaInfoId)
      .subscribe((res: any) => {
        this.ddlInsuranceList = res;
        this.authDetailsEdit = authDetails;
      });
  }
  getAuthorizationDetailTrack() {
    this.spinservice.show();
    this.leadservice.getAuthorizationDetailTrack().subscribe(
      (data) => {
        this.spinservice.hide();
        if (data.objInputString != null) {
          this.drpAuthorizationTrack = this.commonService.decrypdata(
            data.objInputString
          );
        }
      },
      (err) => {
        this.spinservice.hide();
      }
    );
  }

  PostInsAuthorization() {
    if (
      this.authDetailsEdit.InsuranceDetailsId != undefined ||
      this.authDetailsEdit.AuthorizationNumber ||
      this.authDetailsEdit.AuthorizationTypeId ||
      this.authDetailsEdit.CptHcpcs ||
      this.authDetailsEdit.StartDate ||
      this.authDetailsEdit.EndDate ||
      this.authDetailsEdit.NumberOfVisits ||
      this.authDetailsEdit.AuthorizationTrackId
    ) {
      this.spinservice.show();
      //this.authDetailsEdit.PatientInfoId= this.patientInfoId;
      this.authDetailsEdit.AuthorizationTypeId =+this.authDetailsEdit.AuthorizationTypeId; //.toString();
      // this.insAuthorizationDtl.IsIop = this.IsIop;
      // this.insAuthorizationDtl.IsPcp = this.IsPcp;
      this.authDetailsEdit.DaysPerWeek = +this.authDetailsEdit.DaysPerWeek;
      this.authDetailsEdit.InsuranceDetailsId = +this.authDetailsEdit.InsuranceDetailsId;
      this.authDetailsEdit.AuthorizationTrackId = +this.authDetailsEdit.AuthorizationTrackId;
      this.authDetailsEdit.NumberOfVisits =
        +this.authDetailsEdit.NumberOfVisits;
      //console.log(this.insAuthorizationDtl)
      //const dataToEncrypt = this.commonService.DataToEncrypt(this.insAuthorizationDtl, true);
      this.leadservice.postInsAuthorizationDtl(this.authDetailsEdit).subscribe(
        (res) => {
          if (res != '') {
            this.spinservice.hide();
            this.isShowEdit = false;
            this.getAuthorizationDetails();
            this.insAuthorizationEdit.resetForm();
            this.snackbar.success(res);
          }
        },
        (err) => {
          this.spinservice.hide();
        }
      );
    }
  }
  onFilterChange() {
    if (this.selectedValue == '1') {
      this.authDetails = this.authDetailsList;
    } else {
      this.authDetails = this.authDetailsList.filter(
        (x) => x.IsDateExpiredAlready == true || x.IsVisitExpiredAlready == true 

        || x.IsDateExpired || x.IsVisitExpired || x.DateCount !=0
      );
    }
    this.dataSource = new MatTableDataSource(this.authDetails);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  onViewActivity(activity) {
    this.ShowActivity = true;
    this.getAuthActivityLog(activity);
  }
  onCloseActivitylog() {
    this.ShowActivity = false;
    this.authLog = null;
  }
  getAuthActivityLog(item) {
    this.spinservice.show();
    this.ShowActivity = true;
    // console.log(item);
    this.treatmentservice
      .getAuthActivityLog(item.PatientInfoId, item.InsuranceAuthorizationId)
      .subscribe(
        (res: any) => {
          this.spinservice.hide();
          this.authLog = res;
        },
        (err) => {
          this.spinservice.hide();
        }
      );
  }
  downloadExcel() {
    this.authDetails.map((item) => {
      item.ExpiringIn =
        item.IsDateExpiredAlready != 1
          ? item.DateCount != 0
            ? item.IsDateExpired == 1
              ? item.PriorValueExpiry + ' days'
              : item.DateCount + (item.DateCount == 1 ? ' day' : ' days')
            : this.datepipe.transform(item.EndDate, 'MM/dd/yyyy')
          : 'Expired';
    });
    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Define the table headers
    const headers = [
      { header: 'Client Name', key: 'PatientName', width: 20 },
      { header: 'Location', key: 'LocationName', width: 20 },
      { header: 'Authorization Number', key: 'AuthorizationNumber', width: 20 },
      { header: 'Authorization Type', key: 'AuthorizationType', width: 20 },
      { header: 'Insurance', key: 'Insurance', width: 20 },
      { header: 'Visits Allowded', key: 'NumberOfVisits', width: 20 },
      { header: 'Visits Completed', key: 'NoOfVisits', width: 20 },
      { header: '% Utilized', key: 'VisitUtilized', width: 20 },
      { header: 'Expiring In', key: 'ExpiringIn', width: 20 },
    ];

    // Add the headers to the worksheet
    worksheet.columns = headers;

    // Format the header cells as bold
    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true };

    // Add the data rows to the worksheet

    const data = this.dataSource.filteredData;

    worksheet.addRows(data);

    // Generate the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'SFCCAuthorizationList.xlsx');
    });
  }
}

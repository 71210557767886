<div class="psycharist-progress">
  <div class="col-md-12">
    <div class="d-flex met-info-top w-100">
      <i  class="fas fa-user"></i>
      <div  class="mr-4">Client Name <span  class="imp-info">    {{_dtoPatientInfo.patientName }} </span></div>
      <div  class="mr-4">Psychiatrist Name <span  class="imp-info">    {{_dtoPatientInfo.clinicianName}} </span></div>
      <div  class="mr-4"> Appointment  Date <span  class="imp-info">     {{_dtoPatientInfo.startDate | date:'MM/dd/YYYY'}} </span></div>
      <div  class="mr-4"> Appointment  Time <span  class="imp-info">    {{_dtoPatientInfo.startTime | date:'hh:mm a'}}</span></div>
      
      <div  class="ml-auto">
        <button  type="button" mat-stroked-button class="mat-focus-indicator bg-white top-back mt-1 mb-2 h-40 mr-2 mat-stroked-button mat-button-base" (click)="OnbackClick()">Back</button>
      </div>
      </div>
  </div>
  
  <div class="col-md-12  mt-3">
    <div class="lead-box pt-2 pb-2">
    <div class="tab-history custom-tab-border mt-2">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item mr-1" role="presentation">
          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#progress" type="button" role="tab" aria-controls="s1" aria-selected="true" (click)="OnTabchange(1)"  [ngClass]="{'active show': SelectedTab == 1}" >Progress Note</button>
        </li>
        <li class="nav-item " role="presentation">
          <button class="nav-link" id="medication-tab" data-bs-toggle="tab" data-bs-target="#medications" type="button" role="tab" aria-controls="s2" aria-selected="true" (click)="OnTabchange(3)"  [ngClass]="{'active show': SelectedTab == 3}" >Medication History</button>
        </li>
        <li class="nav-item mr-1" role="presentation">
          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#goals" type="button" role="tab" aria-controls="s3" aria-selected="true" (click)="OnTabchange(2)"  [ngClass]="{'active show': SelectedTab == 2}" >Goals</button>
        </li>
        
        
      </ul>
      
      <div class="tab-content">
        <!--For Progress Note-->
        <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==1" [ngClass]="{'disabled':progressUpg == true }">
          <form (ngSubmit)="PsyTheraapyFrm.form.valid" id="PsyTheraapyFrm" autocomplete="off" #PsyTheraapyFrm="ngForm"
          novalidate>
         <div class="col-md-12 mt-3">
          <div  class="row">
            <div  class="d-flex w-100">
              <div  class="box-lead-head mb-2 ml-3">Psychiatrist Notes </div>
              <div  class="ml-auto mr-2">
                <button  type="button" class="btn primary-btn-outlined-small mr-2 float-left" (click)="OnpreviewClick()">
                  <i class="far fa-eye mr-2"></i>Preview</button>
                  <button type="button" class="btn primary-btn-outlined-small mr-2 float-left" (click)="OnDownload(0)"><i class="fas fa-file-alt mr-2"></i>Generate Pdf</button>
                  <button  form="PsyTheraapyFrm" type="submit" class="btn primary-btn-outlined-small ng-star-inserted mr-2 float-left" (click)="PostPsyTheraapyFrm()" *ngIf="roleAccess.isCreate||roleAccess.isEdit">
                    <i  class="far fa-save mr-2"></i>{{IseditPsy?'Save':'Update'}}</button>
                    <button  class="export-pdf-btn btn"  mat-type="button" (click)="OnDownload(1)"><i class="fa fa-print"></i></button>

                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-2 font-weight-bold "><span class="control-label-new">Client Name</span></label> 
                <div  class=" col-md-3 form-group" [ngClass]="{'disabled': true }">
                 <input type="text" class="form-control" [(ngModel)]="_dtoPatientInfo.patientName"
                 #patientName="ngModel" name="patientName" />

                </div>
              </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-2 font-weight-bold "><span class="control-label-new">Psychiatrist</span></label> 
                <div  class=" col-md-3 form-group" [ngClass]="{'disabled': true }">
                 <input type="text" class="form-control"  [(ngModel)]="_dtoPatientInfo.clinicianName"
                 #clinicianName="ngModel" name="clinicianName"/>
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Chief Complaint/Changes since last visit </label> 
                <div  class="form-group">
                  <textarea class="form-control "  ng-reflect-name="txt_clinical"  [(ngModel)]="psyTherapyInfo.chiefComplaint" #chiefComplaint="ngModel" name="chiefComplaint" row="3" maxlength="1500" 
                  [ngClass]="{ 'is-invalid': PsyTheraapyFrm.submitted && chiefComplaint.invalid }" ></textarea>
                </div>
                <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && chiefComplaint.invalid">
                  <mat-error class="text-xs" *ngIf="chiefComplaint.errors.required">
                    Chief Complaint is required</mat-error>
              </mat-error> -->
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Describe Mental Status</label> 
                <div  class="form-group">
                  <textarea  rows="3" class="form-control " 
                  [(ngModel)]="psyTherapyInfo.mentalStatus" #mentalStatus="ngModel" name="mentalStatus" row="3" maxlength="500"
                  [ngClass]="{ 'is-invalid': PsyTheraapyFrm.submitted && mentalStatus.invalid }"   ></textarea>
                </div>
                <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && mentalStatus.invalid">
                  <mat-error class="text-xs" *ngIf="mentalStatus.errors.required">
                    Mental Status is required</mat-error>
              </mat-error> -->
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class=" col-md-2 font-weight-bold "><span class="control-label-new">Assessment</span></label> 
                <div  class="col-md-10 form-group">
                  <div class="d-flex check-fields-large-label ">
                  <div class="check-custom check-custom text-transform-unset" *ngFor="let item of _dtoPatientInfo.assessment; let i=index;">
                    <mat-checkbox class="example-margin mr-2" [(ngModel)]="item.isChecked" name ="A{{i}}id" (change)="Onassessment($event,2)">{{item.clinicalIssues}}</mat-checkbox>
                  </div>
                  <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && !Assessmentgiven">
                    <mat-error class="text-xs" *ngIf="!Assessmentgiven">
                      Assessment is required
                    </mat-error>
                  </mat-error> -->
                
                </div>
                 
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="treat-label col-md-2 font-weight-bold "><span class="control-label-new">Medication Compliance</span></label> 
                <div  class="col-md-10 form-group">
                  <mat-radio-group aria-label="Select an option" id="medicationCompliance" [(ngModel)]="psyTherapyInfo.medicationCompliance" #medicationCompliance="ngModel" name="medicationCompliance{{1}}" 
                  [ngClass]="{ 'is-invalid': PsyTheraapyFrm.submitted && medicationCompliance.invalid }"  >
                    <mat-radio-button value="1" class="mr-2" (click)="OnMedicationCompliance(1,2,1)">Yes </mat-radio-button>
                    <mat-radio-button value="2" class="mr-2" (click)="OnMedicationCompliance(2,2,1)">No</mat-radio-button>
                    <mat-radio-button value="3" class="mr-2" (click)="OnMedicationCompliance(3,2,1)">N/A</mat-radio-button>
                  </mat-radio-group>
                  <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && medicationCompliance.invalid">
                    <mat-error class="text-xs" *ngIf="medicationCompliance.errors.required">
                      Medication Compliance is required</mat-error>
                </mat-error> -->
                </div>
              </div>
              </div>
              <div class="col-md-12 check-fields-large-label check-custom text-transform-unset mb-2">
                <div class="check-custom">
                  <mat-checkbox class="example-margin" [(ngModel)]="psyTherapyInfo.allergies" #allergies="ngModel" name="allergies"   
                 >Assessed for occurrence of medication reactions/allergies/sensitivities</mat-checkbox>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class=" col-md-3 font-weight-bold pr-0 "><span class="control-label-new">Was any medication reaction reported?</span></label>  
                <div  class="col-md-9 form-group">
                  <mat-radio-group aria-label="Select an option" id="isMedicationReaction" [(ngModel)]="psyTherapyInfo.isMedicationReaction" #isMedicationReaction="ngModel" name="isMedicationReaction" 
                  [ngClass]="{ 'is-invalid': PsyTheraapyFrm.submitted && isMedicationReaction.invalid }"  >
                    <mat-radio-button value="1" class="mr-2" (click)="Onmedicationreaction(1,2)">Yes </mat-radio-button>
                    <mat-radio-button value="2" class="mr-2" (click)="Onmedicationreaction(2,2)">No</mat-radio-button>
                    <mat-radio-button value="3" class="mr-2" (click)="OnMedicationCompliance(3,2,2)">N/A</mat-radio-button>
                  </mat-radio-group>
                  <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && isMedicationReaction.invalid">
                    <mat-error class="text-xs" *ngIf="isMedicationReaction.errors.required">
                      This field is required</mat-error>
                </mat-error> -->
                </div>
              </div>
              </div>
              <div class="col-md-12" *ngIf="psyTherapyInfo.isMedicationReaction=='1'">
                <label  class="control-label-new  font-weight-bold " >If Yes, please document medication reaction(s) reported by Client and/or family member</label> 
                <div  class="form-group">
                  <textarea  rows="3" class="form-control "  [(ngModel)]="psyTherapyInfo.medicationReaction" #medicationReaction="ngModel" name="medicationReaction"  ></textarea>
                </div>
              </div>
              <div class="col-md-12" >
                <div class="custom-head mt-3 mb-2"><h2>Diagnosis</h2></div>
                <div  class="basic-info diag-sec mb-3 row">
                  <div  class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Chooose the Diagnosis Code for the Patient</mat-label>
                      <mat-select multiple #DCode [(ngModel)]="psyTherapyInfo.diagnosis" #diagnosis="ngModel"
                      name="diagnosis"  [ngClass]="{ 'is-invalid': PsyTheraapyFrm.submitted && diagnosis.invalid }">
    
                      <mat-option #matOption *ngFor="let item of ddldiagnosiscode" [value]="item.value"
                        (click)="onDiagnosisCodeselect(item,matOption.selected)" [matOption.selected]="item.isActive">
                        {{item.value}}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && diagnosis.invalid">
                      <mat-error  *ngIf="diagnosis.errors.required">
                        This field is required</mat-error>
                  </mat-error> -->
                    </mat-form-field>
                  </div>
                  <!--<div  class="col-md-8">
                    <div class="chip-container-content ">
                      <mat-chip-list aria-label="Fish selection">
                        <mat-chip>F90.2 - Attention-deficit hyperactivity disorder, combined type </mat-chip>
                     
                        <mat-chip  selected>F41.9-Anxiety disorder, unspecified </mat-chip>
                        <mat-chip  selected>F84.0-Autistic disorder </mat-chip>
                      </mat-chip-list>
                    </div>
                  </div>-->
                  <div class="col-md-8" *ngIf="psyTherapyInfo.diagnosis?.length > 0">
                    <div class="chip-container-content">
                      <mat-chip-list aria-label="Fish selection">
                        <ng-container *ngFor="let item of ddldiagnosiscode">
                          <mat-chip *ngIf="item.isActive">{{item.label}}
                            <!--<mat-icon matChipRemove (click)="onRemoveDiagnosisCode(item)">cancel</mat-icon>-->
                          </mat-chip>
                        </ng-container>
                      </mat-chip-list>
                    </div>
                  </div>
                  </div>
              </div>
              <!--<div class="col-md-12">
                <div class="row">
                <label  class="col-md-1 font-weight-bold "><span class="control-label-new">Diagnosis</span></label> 
                <div  class=" col-md-3 form-group">
                 <input type="text" class="form-control"  [(ngModel)]="psyTherapyInfo.diagnosis" #diagnosis="ngModel" name="diagnosis" 
                 [ngClass]="{ 'is-invalid': PsyTheraapyFrm.submitted && diagnosis.invalid }"    />
                </div>
                <mat-error *ngIf="PsyTheraapyFrm.submitted && diagnosis.invalid">
                  <mat-error class="text-xs" *ngIf="diagnosis.errors.required">
                    Diagnosis is required</mat-error>
              </mat-error>
              </div>
              </div>-->
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Treatment (Medication(s) prescribed including dosage, frequency, amount)</label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control " [(ngModel)]="psyTherapyInfo.treatment" #treatment="ngModel" name="treatment" 
                  [ngClass]="{ 'is-invalid': PsyTheraapyFrm.submitted && treatment.invalid }"     ></textarea>
                </div>
                <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && treatment.invalid">
                  <mat-error class="text-xs" *ngIf="treatment.errors.required">
                    This field is required</mat-error>
              </mat-error> -->
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Treatment Goals and Objectives</label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control "   [(ngModel)]="psyTherapyInfo.treatmentGoals" #treatmentGoals="ngModel" name="treatmentGoals" 
                  [ngClass]="{ 'is-invalid': PsyTheraapyFrm.submitted && treatmentGoals.invalid }"     ></textarea>
                </div>
                <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && treatmentGoals.invalid">
                  <mat-error class="text-xs" *ngIf="treatmentGoals.errors.required">
                    Treatment goals and objectives is required</mat-error>
              </mat-error> -->
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Goals and Objectives for Service</label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control " [(ngModel)]="psyTherapyInfo.serviceGoals" #serviceGoals="ngModel" name="serviceGoals" 
                  [ngClass]="{ 'is-invalid': PsyTheraapyFrm.submitted && serviceGoals.invalid }"     ></textarea>
                </div>
                <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && serviceGoals.invalid">
                  <mat-error class="text-xs" *ngIf="serviceGoals.errors.required">
                    Goals and objectives for service is required</mat-error>
              </mat-error> -->
              </div>
              <div class="col-md-12 check-fields-large-label mb-2">
                <div class="check-custom check-custom text-transform-unset">
                  <mat-checkbox class="example-margin" [(ngModel)]="psyTherapyInfo.isMedicationChanges" #isMedicationChanges="ngModel" name="isMedicationChanges"  >Please check, if any medication changes since last visit</mat-checkbox>
                </div>
              </div>
              <div class="col-md-12" >
                <label  class="control-label-new font-weight-bold ">Medication Education/Counseling (please check if completed; if not, explain below) </label> 
                <div class="form-group row check-fields-large-label check-custom text-transform-unset" >
                  <div class="col-md-12" *ngFor="let item of _dtoPatientInfo.counseling;let i=index;">
                    <div class="check-custom">
                      <mat-checkbox class="example-margin" [(ngModel)]="item.isChecked" name="id{{i}}" (change)="OnCouncellingchange($event,2)">{{item.clinicalIssues}}</mat-checkbox>
                    </div>
                     </div>
                     <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && !Councellinggiven">
                      <mat-error class="text-xs" *ngIf="!Councellinggiven">
                       This field is required</mat-error>
                  </mat-error> -->
                  
                </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Comments</label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical" [(ngModel)]="psyTherapyInfo.comments" #comments="ngModel" name="comments" 
                  [ngClass]="{ 'is-invalid': PsyTheraapyFrm.submitted && comments.invalid }"  ></textarea>
                </div>
                <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && comments.invalid">
                  <mat-error class="text-xs" *ngIf="comments.errors.required">
                    Comments is required</mat-error>
              </mat-error> -->
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold "> Next Follow-Up</label> 
               <div class="form-group" >
                <mat-radio-group aria-label="Select an option" [(ngModel)]="psyTherapyInfo.nextFollowUp" id="nextFollowUp" #nextFollowUp="ngModel" name="nextFollowUp" 
                [ngClass]="{ 'is-invalid': PsyTheraapyFrm.submitted && nextFollowUp.invalid }"  >
                  <!-- <mat-radio-button value=1 class="mr-2" (change)="PostPsyTheraapyFrm(2)" >1 Week  </mat-radio-button> -->
                  <mat-radio-button value="1" class="mr-2"  >1 Week  </mat-radio-button>
                  <mat-radio-button value="2" class="mr-2"  >2 weeks</mat-radio-button>
                  <mat-radio-button value="3" class="mr-2"  >1 Month</mat-radio-button>
                  <mat-radio-button value="4" class="mr-2"  >2 Months</mat-radio-button>
                  <mat-radio-button value="5" class="mr-2" >3 Months</mat-radio-button>
                </mat-radio-group>
               </div>
              </div>
              
              <div class="col-md-12">
                <div class="form-group clearfix m-0">
                  <div class="float-left col-md-4 p-0 mt-3 pt-1">
                    <label class="control-label-new font-weight-bold">Severity Level:</label>
                    <mat-select #severity="ngModel" name="severity" [(ngModel)]="_dtoPatientInfo.psyTherapyInfo.severity" [ngStyle]="{'color':bg}" (selectionChange)="onSelectionChanged($event.value)" placeholder="Select Severity">
                      <!-- <mat-option [value]="0"> 0 - Discharged </mat-option>
                      <mat-option [value]="1"> 1 - Mild </mat-option>
                      <mat-option [value]="2"> 2 - Medium  </mat-option>
                      <mat-option [value]="3"> 3 - Critical </mat-option> -->
                      <mat-option *ngFor="let item of ddlseverity" [ngStyle]="{'color': item.colocode}"  [value]="item.severityValue">{{item.severityLable}}</mat-option>
                    </mat-select>
                  </div>
                </div>
              </div>


              <div  class="col-md-12">
                <div  class="form-group clearfix m-0">
                  <div  class="float-left col-md-5 p-0 mt-3 pt-1">
                    <label  class="treat-label mr-2">Provider's Signature</label>
                    <img  id="esign" height="50px" width="300px" src="{{psyTherapyInfo.staffInitials}}"style="border: 1px solid #d3c7c7 !important;">
                    <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialog()" > Add E-Sign </button>
                    <div class="float-left col-md-4 p-0 mt-4 pt-1" >
                      <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && psyTherapyInfo.staffInitials == undefined && ((roleAccess.isSign && isLicensed) || (!_dtoPatientInfo.isLicensed && roleAccess.isUnlicensed) || (_dtoPatientInfo.IsIntern && roleAccess.isInternSign ))">
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="psyTherapyInfo.staffInitials == undefined">
                         Signtaure is required</mat-error>
                    </mat-error> -->
                         </div>
                  </div>
                  <div  class="float-left col-md-5 p-0 mt-3 pt-1">
                    <label  class="treat-label mr-2">Secondary Signature</label>
                    <img  id="esign_1" height="50px" width="300px" src="{{psyTherapyInfo.staffInitial1}}"style="border: 1px solid #d3c7c7 !important;">
                    <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"  [disabled]="secondarySign()" (click)="openDialog1()" > Add E-Sign </button>
                    <div class="float-left col-md-4 p-0 mt-4 pt-1" >
                      <!-- <mat-error *ngIf="PsyTheraapyFrm.submitted && psyTherapyInfo.staffInitials == undefined && ((roleAccess.isSign && isLicensed) || (!_dtoPatientInfo.isLicensed && roleAccess.isUnlicensed) || (_dtoPatientInfo.IsIntern && roleAccess.isInternSign ))">
                        <mat-error class="mat-form-field-subscript-wrapper" *ngIf="psyTherapyInfo.staffInitials == undefined">
                         Signtaure is required</mat-error>
                    </mat-error> -->
                         </div>
                  </div>
                 
                  <div  class="float-right col-md-2 p-0 ">
                    <mat-form-field class="w-100">
                      <mat-label>Date</mat-label>
                      <input matInput [matDatepicker]="picker" [(ngModel)]="psyTherapyInfo.signDate" #signDate="ngModel" name="signDate">
                      
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            
          </form>
        </div>
        <!--For Goals--> 
        <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==2" [ngClass]="{'disabled':progressUpg == true }"> 
             <div class="goal-page-indiv col-md-12 mt-3">
              <div class="col-md-12 p-0">
                <div class="d-flex w-100 mb-2">
                  <div class="box-lead-head mb-2 ">Goals </div>
                  <div class="ml-auto">
                  </div>
                </div>
              </div>
              <div class="panel-group" id="accordion">
                <div *ngFor="let item of goalsinfo; let i=index">
                <div class="panel panel-default active" >
                 
                  <div class="panel-heading" >
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse{{i}}" class="collapsed">
                        <i class="fa fa-caret-right mr-2"></i>{{ item.goal }}
  
                      </a>
                      <!--<div class="goal-stat-chang float-right" >
                       <select class="form-control" [(ngModel)]="item.status"
                       #status="ngModel" name="status" (change)="OnstatusChange(item)">
                         <option [value]=2  >Inprogress</option>
                         <option [value]=3 > Completed</option>
                       </select>
                      </div>-->
                      
                    </h4>
                  </div>
                  <div id="collapse{{i}}" class="panel-collapse collapse in" >
                    <div class="panel-body">
                     <div class="col-md-12 ben-head">
                       <div class="row">
                         
                         <div class="col-md-8 ben_des">
                          <div class=" ben_tit">
                           {{ item.benchmarkTitle }}
                          </div>
                         {{item.benchmarkDescription}}
                        </div>
                        <div class="col-md-2">
                          <div class="goal-stat-chang mt-3">
                              <span class="status-tabl inprogress" *ngIf="item.status==2"><i class="fas fa-circle mr-2"></i>Inprogress</span>
                              <span class="status-tabl completed" *ngIf="item.status==3"><i class="fas fa-circle mr-2"></i>Completed</span>
                          </div>
                      </div>
                        <div class="col-md-2 ben_unit">
                          <p class="text-muted text-xs">Unit of Measure</p>
                          {{item.benchmarkunit}}
                        </div>
                       </div>
                     </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            
            </div>
         
         
         
         
        </div>  
        <div  id="medication" role="tabpanel" aria-labelledby="medication-tab" class="tab-pane fade medic-hist  active show"  *ngIf="SelectedTab ==3" [ngClass]="{'disabled':progressUpg == true }">

           <div  class="medic-his-list p-2">
              <ul *ngIf="!onAddmedication">
                  <li  class="mb-2 cursor-pointer">
                      <div  class="card p-2 pb-1">
                          <div  class="col-md-12">
                              <div  class="col-md-12 medic-his-list">
                                  <div  class="row">
                                      <div  class="col-md-3 p-0">
                                          <div  class="mt-2">
                                              <div  class="date-medic">Previous Medication History</div>
                                          </div>
                                      </div>
                                      <div  class="col-md-5">
                                      </div>
                                      <div  class="col-md-4 pr-0">
                                          <div  class="d-flex w-100 mt-2 justify-content-end">
                                              <a>
                                                 <i  class="fas" 
                                                 [ngClass]="{'fa-chevron-up':isshowhistroy,'fa-chevron-down':!isshowhistroy}" (click)="isshowhistroy=!isshowhistroy"></i>
                                              </a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <ng-container *ngIf="isshowhistroy">
                          <div  class="panel-list ">
                              <div  class="col-md-12 mt-2">
                                  <div  class="row">
                                      <div  class="col-md-3">
                                          <div  class="w-100">
                                              <label >Medication</label>
                                          </div>
                                      </div>
                                      <div  class="col-md-2">
                                          <div >
                                              <label >Dosage</label>
                                          </div>
                                      </div>
                                      <div  class="col-md-3">
                                          <div >
                                              <label >Frequency</label>
                                          </div>
                                      </div>
                                      <div  class="col-md-4">
                                          <div >
                                              <label >Side Effect</label>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div  class="col-md-12 mt-2 mb-2 ">
                                <ul  >
                                  <li *ngFor="let item of medicationRecords ;let i=index" >
                                      <div  class="row">
                                          <div  class="col-md-3">
                                              <div  class="w-100">
                                                  <span>{{item.medications}}</span>
                                              </div>
                                          </div>
                                          <div  class="col-md-2">
                                              <div >
                                                  <span>{{item.dosage}}</span>
                                              </div>
                                          </div>
                                          <div  class="col-md-3">
                                              <div >
                                                <span>{{item.frequency == 1 ? 'q.a.m. Every morning' :item.frequency == 2 ? 'q.p.m. Every evening' :item.frequency == 3 ? 'q.h.s Every night at bedtime'
                                                  :item.frequency == 4 ? 'b.i.d. Twice daily':item.frequency == 5 ? 't.i.d. 3 times a day':item.frequency == 6 ? 'q.i.d 4 times a day'
                                                  :item.frequency == 7 ? 'q.o.d Every other day':item.frequency == 8 ? 'q.w. Every week'  : 'p.r.n. Daily as needed'
                                                  
                                                }}</span>
                                                  <!-- <span>{{item.frequency == 1 ? 'Daily' :item.frequency == 2 ? 'Weekly' : 'Monthly'
                                                  }}</span> -->
                                              </div>
                                          </div>
                                          <div  class="col-md-4">
                                              <div > {{item.sideEffects}} </div>
                                          </div>
                                      </div>                                                                  
                             </li>
                             </ul>                                    
                             </div>
                             <div class="panel-list " *ngIf="medicationhistroy?.length == 0">
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <span class="text-muted mx-auto">Previous medication history not available</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          </ng-container>   
                       </div>
                  </li>
                  <!-- <div class="date-medic" >Current Medication </div> -->
                  <li class="mb-2" *ngFor="let item of lstmedicine;" >
                    <div class="card p-2 pb-1">
                      <div class="col-md-12">

                        <div class="col-md-12 medic-his-list " *ngIf="!onAddmedication">
                          <div class="row">
                            <div class="col-md-3 p-0">
                              <div class="mt-2">

                                <div class="date-medic" >Prescribed By - {{item.clinicianName}} </div>
                              </div>
                            </div>
                            <div class="col-md-5">
                            </div>
                            <div class="col-md-4 pr-0">
                              <div class="d-flex w-100 mt-2">

                                <div class="date-medic ml-auto mt-1">Medication Date: <span>{{item.updatedDate |
                                    date:'MM/dd/yyy'}}</span> </div>
                                <a class="icon-note cursor-pointer" (click)="onEditMedication(item)"
                                  title="Edit"><span class="mr-2"><i class="far fa-edit"></i></span></a>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="panel-list "  *ngIf="!onAddmedication">
                        <div class="col-md-12 mt-2">
                          <div class="row">
                            <div class="col-md-3">
                              <div class="w-100">
                                <label>Medication</label>

                              </div>

                            </div>
                            <div class="col-md-2">
                              <div class="">
                                <label>Dosage</label>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="">
                                <label>Frequency</label>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div class="">
                                <label>How Often?</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="!item.toggle">
                          <div class="col-md-12 mt-2 mb-2"
                            *ngFor="let data of item.dtoPatientMedication ">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="w-100">
                                  <span>{{data.medications}}</span>

                                </div>

                              </div>
                              <div class="col-md-2">
                                <div class="">
                                  <span>{{data.dosage}}</span>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="">
                                  <span>{{data.frequency == 1 ? 'q.a.m. Every morning' :data.frequency == 2 ? 'q.p.m. Every evening' :data.frequency == 3 ? 'q.h.s Every night at bedtime'
                                    :data.frequency == 4 ? 'b.i.d. Twice daily':data.frequency == 5 ? 't.i.d. 3 times a day':data.frequency == 6 ? 'q.i.d 4 times a day'
                                    :data.frequency == 7 ? 'q.o.d Every other day':data.frequency == 8 ? 'q.w. Every week'  : 'p.r.n. Daily as needed'
                                    
                                  }}</span>
                                  <!-- <span>{{data.frequency == 1 ? 'Daily' :data.frequency == 2 ? 'Weekly' : 'Monthly'
                                    }}</span> -->
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="">
                                  <span class="morn-time" *ngIf="data.isMorning"><i
                                      class="fas fa-cloud-sun mr-1"></i>Morning</span>
                                  <span class="morn-time" *ngIf="data.isNoon"><i
                                      class="fas fa-sun mr-1"></i>Noon</span>
                                  <span class="morn-time" *ngIf="data.isEvening"><i
                                      class="far fa-moon mr-1"></i>Evening</span>
                                  <span class="morn-time" *ngIf="data.isBedTime"><i class="fas fa-bed mr-1"></i>Bed
                                    Time</span>
                                  <span class="pl-2"
                                    *ngIf="!data.isMorning && !data.isNoon && !data.isEvening && !data.isBedTime">-</span>
                                </div>
                              </div>


                            </div>

                          </div>
                        </ng-container>
                        <ng-container *ngIf="item.toggle">
                          <div class="col-md-12 mt-2 mb-2" *ngFor="let data of item.dtoPatientMedication">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="w-100">
                                  <span>{{data.medications}}</span>

                                </div>

                              </div>
                              <div class="col-md-2">
                                <div class="">
                                  <span>{{data.dosage}}</span>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="">
                                  <span>{{data.frequency == 1 ? 'q.a.m. Every morning' :data.frequency == 2 ? 'q.p.m. Every evening' :data.frequency == 3 ? 'q.h.s Every night at bedtime'
                                    :data.frequency == 4 ? 'b.i.d. Twice daily':data.frequency == 5 ? 't.i.d. 3 times a day':data.frequency == 6 ? 'q.i.d 4 times a day'
                                    :data.frequency == 7 ? 'q.o.d Every other day':data.frequency == 8 ? 'q.w. Every week'  : 'p.r.n. Daily as needed'
                                    
                                  }}</span>
                                  <!-- <span>{{data.frequency == 1 ? 'Daily' :data.frequency == 2 ? 'Weekly' : 'Monthly'
                                    }}</span> -->
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="">
                                  <span class="morn-time" *ngIf="data.isMorning"><i
                                      class="fas fa-cloud-sun mr-1"></i>Morning</span>
                                  <span class="morn-time" *ngIf="data.isNoon"><i
                                      class="fas fa-sun mr-1"></i>Noon</span>
                                  <span class="morn-time" *ngIf="data.isEvening"><i
                                      class="far fa-moon mr-1"></i>Evening</span>
                                  <span class="morn-time" *ngIf="data.isBedTime"><i class="fas fa-bed mr-1"></i>Bed
                                    Time</span>
                                  <span class="pl-2"
                                    *ngIf="!data.isMorning && !data.isNoon && !data.isEvening && !data.isBedTime">-</span>
                                </div>
                              </div>


                            </div>

                          </div>
                        </ng-container>
                        <div class="expndable text-right  ">
                          <a class="exp-btn cursor-pointer" (click)="item.toggle=!item.toggle"><span
                              class="dots">...</span> </a>
                        </div>

                      </div>
                    </div>
                  </li>
              </ul>
          </div>
          <div class="first-entry-medication text-center mt-5 "
          *ngIf="lstmedicine?.length == 0 && (roleAccess.isCreate||roleAccess.isEdit)">
          <label class="additional mt-3 pl-3">Medication history not available,
            would you like to <a class="text-underscore cursor-pointer"  (click)="addMedicationInfo()">add</a>
            Medication?
            <p><i class="fas fa-notes-medical"></i></p>

          </label>
        </div>
        
        <form (ngSubmit)="frmMedication.form.valid  && postProgressMedication()" id="frmMedication"
          autocomplete="off" #frmMedication="ngForm" novalidate class="clearfix pb-3">
          <div class="col-md-12 float-left text-right" *ngIf=" (roleAccess.isCreate||roleAccess.isEdit)">
            <button *ngIf="_dtoMedication?.length > 0" (click)="_dtoMedication=[]; onAddmedication=fasle; editmedication=false"
              class="btn primary-btn-outlined-small float-right mt-2 ml-2">Cancel</button>
            <button type="submit" *ngIf="_dtoMedication?.length > 0 " form="frmMedication"
              class="btn primary-btn-filled-small float-right mt-2 mr-2"><i
                class="far fa-save mr-2"></i>{{editmedication ? 'Update':'Save'}}</button>
          
  
            <button type="button" *ngIf="lstmedicine?.length > 0 && _dtoMedication?.length == 0"
              (click)="addMedicationInfo()" class="btn primary-btn-outlined-small float-right mt-2 "><i
                class="fas fa-plus-circle mr-2"></i>Add Medication</button>
          </div>
          <div class="add-medic float-left w-100" *ngFor="let med of _dtoMedication;let i= index;let last=last">

            <div class="col-md-12 medication ">
              <div class="row">
                <div class="col-md-2">
                  <label>Medication</label>
                  <input class="form-control" maxlength="50" [(ngModel)]="med.medications"
                    #medications="ngModel" name="medications{{i}}" placeholder="Medicine" type="text"
                    required>
                  <mat-error *ngIf="frmMedication.submitted && medications.invalid">
                    <mat-error class="mat-form-field-subscript-wrapper mt-3"
                      *ngIf="medications.errors.required">Medicine
                      is
                      required</mat-error>
                  </mat-error>
                </div>
                <div class="col-md-2">
                  <label>Dosage</label>
                  <input class="form-control" maxlength="30" [(ngModel)]="med.dosage" #dosage="ngModel"
                    name="dosage{{i}}" placeholder="Dosage" type="text" required>
                  <mat-error *ngIf="frmMedication.submitted && dosage.invalid">
                    <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="dosage.errors.required">
                      Dosage is
                      required</mat-error>
                  </mat-error>
                </div>
                <div class="col-md-2">
                  <label>Frequency</label>
                  <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                    [(ngModel)]="med.frequency" #frequency="ngModel" name="frequency{{i}}" required>
                    <option value="null">--Select Frequency--</option>
                    <option value="1">q.a.m. Every morning</option>
                    <option value="2">q.p.m. Every evening</option>
                    <option value="3">q.h.s Every night at bedtime</option>
                    <option value="4">b.i.d. Twice daily</option>
                    <option value="5">t.i.d. 3 times a day</option>
                    <option value="6">q.i.d 4 times a day</option>
                    <option value="7">q.o.d Every other day</option>
                    <option value="8">q.w. Every week</option>
                    <option value="9">p.r.n. Daily as needed</option>
                  </select>
                  <mat-error *ngIf="frmMedication.submitted && frequency.invalid">
                    <mat-error class="mat-form-field-subscript-wrapper mt-3"
                      *ngIf="frequency.errors.required">Frequency
                      is
                      required</mat-error>
                  </mat-error>
                </div>
                <div class="col-md-5 text-center">

                  <div class="row">
                    <div class="d-flex w-100">
                      <label>How Often?</label>
                      <div class="ml-auto b4-meal">
                        <div class="custom-radio">

                          <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isBeforemeal"
                            #isBeforemeal="ngModel" name="isBeforemeal{{i}}" required>
                            <mat-radio-button class="mr-1" [value]="true">Before Meals
                            </mat-radio-button>
                            <mat-radio-button [value]="false">After Meals
                            </mat-radio-button>
                          </mat-radio-group>
                          <mat-error *ngIf="frmMedication.submitted && isBeforemeal.invalid">
                            <mat-error class="mat-form-field-subscript-wrapper"
                              *ngIf="isBeforemeal.errors.required">
                              This field is required
                            </mat-error>
                          </mat-error>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 p-0">
                      <label class="sub-small-label">Morning</label>
                      <div class="custom-radio">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isMorning"
                          #isMorning="ngModel" name="isMorning{{i}}">
                          <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                          <mat-radio-button [value]="false">N</mat-radio-button>
                        </mat-radio-group>

                      </div>

                    </div>
                    <div class="col-md-3 p-0">
                      <label class="sub-small-label">Noon</label>
                      <div class="custom-radio">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isNoon"
                          #isNoon="ngModel" name="isNoon{{i}}">
                          <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                          <mat-radio-button [value]="false">N</mat-radio-button>
                        </mat-radio-group>
                      </div>

                    </div>
                    <div class="col-md-3 p-0">
                      <label class="sub-small-label">Evening</label>
                      <div class="custom-radio">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isEvening"
                          #isEvening="ngModel" name="isEvening{{i}}">
                          <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                          <mat-radio-button [value]="false">N</mat-radio-button>
                        </mat-radio-group>
                      </div>

                    </div>
                    <div class="col-md-3 p-0">
                      <label class="sub-small-label">Bed Time</label>
                      <div class="custom-radio">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="med.isBedTime"
                          #isBedTime="ngModel" name="isBedTime{{i}}">
                          <mat-radio-button [value]="true" class="mr-2">y</mat-radio-button>
                          <mat-radio-button [value]="false">N</mat-radio-button>
                        </mat-radio-group>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-md-1 mt-4">
                  <a class="add " (click)="addMedicationInfo()" style="cursor: pointer;"><i
                      class="fa fa-lg fa-plus-circle mr-2"></i><span class="sr-only">Add</span></a>
                  <a class="add " style="cursor: pointer;" (click)="removeclinicalinformation(med,i)"><i
                      class="fa fa-lg fa-minus-circle"></i><span class="sr-only">Remove</span></a>
                </div>

              </div>
            </div>
          </div>
          
         
        </form>         
      
      
      </div>
        
        
        </div>
      
            </div>

   </div>
   <!--Preview popup-->
    <div class="Activity-popup">
  <div class="modal"  tabindex="-1" role="dialog" [style.display]="PopUpPreview? 'block':'none'" >
     <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h6 class="modal-title">Preview</h6>
              <button type="button" class="close"  data-dismiss="modal" (click)="OnclosePopup()"
              aria-label="Close" >
              <span aria-hidden="true">&times;</span>
           </button>
           </div>
           <div class="modal-body">
            <div class="individual-threaphy-container">
            <div  class="history-list col-md-12 p-0">
              <div class="row">
                <div class="d-flex col-md-12">
                  <button class="ml-auto btn-print-hover mb-3 mr-2" mat-type="button" (click)="print()"><i class="fa fa-print"></i></button>
                </div>
                
              </div> 
              <div id="print-section">
                <div class="page-header" style=" text-align: center; font-size: 15px; font-weight: 500; margin-bottom: 25px; color: #173a64;">PSYCHIATRIST NOTES </div>

              <div class="row top-details-psych" >
                <div class="col-md-3"><label>Client Name</label>
                <p>{{_dtoPatientInfo.patientName }}</p></div>
                <div class="col-md-3"><label>Psychiatrist Name</label>
                  <p>{{_dtoPatientInfo.clinicianName}}</p></div>
                  <div class="col-md-3"><label> Appointment Date</label>
                    <p>{{_dtoPatientInfo.startDate | date:'MM/dd/YYYY'}}</p></div>
                    <div class="col-md-3"><label> Appointment Time</label>
                      <p>{{_dtoPatientInfo.startTime | date:'hh:mm a'}}</p></div>
                    
               </div>
               
               <div class="row" >
                <div class="col-md-12 mb-2  ">
                   <label class="control-label-new font-weight-bold" >Chief Complaint/Changes since last visit</label>
               <div class="cli_value_family">
                <span>{{psyTherapyInfo.chiefComplaint}}</span>
              </div>
            </div>
          </div>
            <div class="row" >
              <div class="col-md-12 mb-2  ">
                 <label class="control-label-new font-weight-bold" >Describe Mental Status</label>
             <div class="cli_value_family">
              <span>{{psyTherapyInfo.mentalStatus}}</span>
            </div>
          </div>
          </div>
        
              <div class="row" >
              <div class="col-md-12 mb-2  ">
                 <label class="control-label-new font-weight-bold" >Assessment</label>
             <div class="cli_value_family ">
              <ng-container *ngFor="let item of _dtoPatientInfo.assessment;let i=index">
                <span class="cli_value_family"  *ngIf="item.isChecked">
                  <span >{{item.clinicalIssues}} <small>,</small></span>
                </span>
                
                </ng-container>
            </div>
          </div>
        </div>
        <div class="row" >
          <div class="col-md-12 mb-2  ">
             <label class="control-label-new font-weight-bold" >Medication Compliance</label>
         <div class="cli_value_family">
          <span>{{psyTherapyInfo.medicationCompliance=="1"?'Yes':psyTherapyInfo.medicationCompliance=="2"?'No':psyTherapyInfo.medicationCompliance=="3"?'N/A':''}}</span>
        </div>
      </div>
    </div>
    <div class="row" >
      <div class="col-md-12 mb-2  ">
         <label class="control-label-new font-weight-bold" >Assessed for occurrence of medication reactions/allergies/sensitivities</label>
     <div class="cli_value_family">
      <span>{{psyTherapyInfo.allergies==true?'Yes':'No'}}</span>
    </div>
  </div>
      </div>
      <div class="row" >
        <div class="col-md-12 mb-2  ">
          <label class="control-label-new font-weight-bold" >Was any medication reaction reported?</label>
      <div class="cli_value_family">
        <span>{{psyTherapyInfo.isMedicationReaction=="1"?'Yes':psyTherapyInfo.isMedicationReaction=="2"?'No':psyTherapyInfo.isMedicationReaction=="3"?'N/A':''}}</span>
      </div>
      </div>
      </div>
        <div class="row" *ngIf="psyTherapyInfo.isMedicationReaction=='1'">
        <div class="col-md-12 mb-2  ">
        <label class="control-label-new font-weight-bold" >If Yes, please document medication reaction(s) reported by Client and/or family member</label>
        <div class="cli_value_family">
        <span>{{psyTherapyInfo.medicationReaction}}</span>
        </div>
        </div>
        </div>
        <div class="row" >
          <div class="col-md-12 mb-2  ">
          <label class="control-label-new font-weight-bold" >Diagnosis</label>
          <div class="cli_value_family">
          <span>{{psyTherapyInfo.diagnosis}}</span>
          </div>
          </div>
          </div>
  <div class="row" >
    <div class="col-md-12 mb-2  ">
     <label class="control-label-new font-weight-bold" >Treatment (Medication(s) prescribed including dosage, frequency, amount)</label>
    <div class="cli_value_family">
    <span>{{psyTherapyInfo.treatment}}</span>
    </div>
    </div>
    </div>
    <div class="row" >
      <div class="col-md-12 mb-2  ">
       <label class="control-label-new font-weight-bold" >  Treatment Goals and Objectives</label>
      <div class="cli_value_family">
      <span>{{psyTherapyInfo.treatmentGoals}}</span>
      </div>
      </div>
      </div>
      <div class="row" >
        <div class="col-md-12 mb-2  ">
         <label class="control-label-new font-weight-bold" >Treatment Goals and Objectives</label>
        <div class="cli_value_family">
        <span>{{psyTherapyInfo.treatmentGoals}}</span>
        </div>
        </div>
        </div>
        <div class="row" >
          <div class="col-md-12 mb-2  ">
           <label class="control-label-new font-weight-bold" > Goals and Objective for Service</label>
          <div class="cli_value_family">
          <span>{{psyTherapyInfo.serviceGoals}}</span>
          </div>
          </div>
          </div>
          <div class="row" >
            <div class="col-md-12 mb-2  ">
             <label class="control-label-new font-weight-bold" >Please check, if any medication changes since last visit</label>
            <div class="cli_value_family">
            <span>{{psyTherapyInfo.isMedicationChanges==true?'Yes':'No'}}</span>
            </div>
            </div>
            </div>
            <div class="row" >
              <div class="col-md-12 mb-2  ">
               <label class="control-label-new font-weight-bold" >  Medication Education/Counseling (please check if completed; if not, explain below)</label>
              <div class="cli_value_family">
                <ng-container *ngFor="let item of _dtoPatientInfo.counseling;let i=index">
                  <span class="cli_value_family"  *ngIf="item.isChecked">
                    <span >{{item.clinicalIssues}} <small>,</small></span>
                  </span>
                  
                  </ng-container>
              </div>
              </div>
              </div>
              <div class="row" >
                <div class="col-md-12 mb-2  ">
                 <label class="control-label-new font-weight-bold" >Comments</label>
                <div class="cli_value_family">
                <span>{{psyTherapyInfo.comments}}</span>
                </div>
                </div>
                </div>
                <div class="row" >
                  <div class="col-md-12 mb-2  ">
                   <label class="control-label-new font-weight-bold" > Next Follow-Up</label>
                  <div class="cli_value_family">
                  <span>{{psyTherapyInfo.nextFollowUp==1?'1 Week':psyTherapyInfo.nextFollowUp==2?'2 Weeks':psyTherapyInfo.nextFollowUp==3?'1 Month':
                    psyTherapyInfo.nextFollowUp==4?'2 Months': psyTherapyInfo.nextFollowUp==5?'3 Months':''}}</span>
                  </div>
                  </div>
                  </div>
                  
                  <div class="row" >
                    <div class="col-md-12 mb-2  ">
                     <label class="control-label-new font-weight-bold" >Clinician Signtaure</label>
                    <div class="cli_value_family">
                    <span><img  id="esign_prev" height="50px" width="300px" src="{{psyTherapyInfo.staffInitials}}" /></span>
                    </div>
                    </div>
                    </div>
                 </div>
        </div> 

        </div> 
             </div>
        </div>
      </div>
    </div>
  </div>  
 
   <!--End Preview popup-->
  </div>
  </div> 
  <a mimetype="application/octet-stream" #downloadLink></a>

import { Component, OnInit, ViewChild, AfterViewInit, ElementRef,ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { NONE_TYPE, ParseTreeResult, viewClassName } from '@angular/compiler';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { PatientService } from 'src/app/patient/patient.service';
import { BillingService } from 'src/app/billing/billing.service';
import { DatePipe } from '@angular/common'
import { cloneDeep } from "lodash";
import * as moment from 'moment';


import {DtoBridgePayTransactionSaleRequest} from 'src/app/_models/_Billing/payment';
import {DtoPatientOutofPacketInfo,DtoPatientOutofPacketInvoicePdf,  DtoPatientOutofPacketInfoDetailsActiveLists
  ,DtoPatientOutofPacketInfoDetailsCompletedLists,DtoPatientOutofPacketInfoDetails, DtoPatientOutofPacketNotes,DtoOutOfPacketPdfHistory} from 'src/app/_models/_Billing/dtoPatientOutofPacketDetails';
import { Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { IntakeGenerateComponent } from '../intake/intake-generate/intake-generate.component';
declare function decrypt(data): any;
declare function EncryptFieldData(data): any;
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';

@Component({
  selector: 'app-patient-payment-details',
  templateUrl: './patient-payment-details.component.html',
  styleUrls: ['./patient-payment-details.component.scss']
})
export class PatientPaymentDetailsComponent implements OnInit {
  @ViewChild('paymentTransSale') paymentTransSale: NgForm;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  patientInfoId: any;
  patientAccountId: any;
  roleId:any;
  reDirectFrom:any;
  patientOutofPktDtls:any;
  dtoPatientOutofPacketInfoDetails:DtoPatientOutofPacketInfoDetails;
  OutofPktPaymentCompletedLists: Array<DtoPatientOutofPacketInfoDetailsCompletedLists>;
  OutofPktPaymentActiveLists: Array<DtoPatientOutofPacketInfoDetailsActiveLists>;
  dtoPatientOutofPacketInfo:DtoPatientOutofPacketInfo;
  dtoBridgePayTransactionSaleRequest : DtoBridgePayTransactionSaleRequest;
  DtoPatientOutofPacketInvoicePdf:DtoPatientOutofPacketInvoicePdf;
  dtoPatientOutofPacketNotes:DtoPatientOutofPacketNotes;
  dtoOutOfPacketPdfHistory:DtoOutOfPacketPdfHistory;
  isTranssale:boolean=false;
  isTranssale1:boolean=false;
  paymentresponse:any;
  totalpending:any;
  allPaymenttypeMasters:any;
  drpPaymentMethod:any;
  selectedPaymentMethod:any;
  isCash:boolean=false;
  isCard:boolean=false;
  isPaymentSelected:boolean=false;
  currentdate;
  expirationDate:any;
  isPrintDatePopup:boolean=false;
  startDate:any;
  endDate:any;
   OutofPktPaymentPrintdata: Array<DtoPatientOutofPacketInfoDetailsCompletedLists>;
  subtotal:any=0;
  todayDate:any;
  dtoPatientOutofPacketInvoicePdf;
  pdfInvoice:any;
  isStartDateErr:boolean;
  isEndDateErr:boolean;
  today:any;
  TmpLists: Array<DtoPatientOutofPacketInfoDetails>;
  isAddNotes:boolean=false;
  isNotesErr:boolean=false;
  notesContent:any;
  isShowNotesAttachment:boolean=false;
  roleAccess: any;
  isPerWeek=true;
  isPerWeekC=true;
  constructor(public global: Global, public settingsService: SettingsService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public patientservice: PatientService,
    public datepipe: DatePipe,public billingservice: BillingService
    ,public roleaccessservice:PageAccessService
      ) { }

  ngOnInit(): void {
    this.roleId = localStorage.getItem("roleId");
    
    this.route.queryParams.subscribe(params => {
       if (Object.keys(params).length != 0) {
        if(params.info){
          this.patientInfoId=JSON.parse(this.commonService.FrontEndDecryption(params.info));
          this.reDirectFrom="admin";
          }
          else {this.patientInfoId = localStorage.getItem("patientId");}
          if(params.id){
            this.patientAccountId=JSON.parse(this.commonService.FrontEndDecryption(params.id));
            }
        } 
    });
    
    this.isPaymentSelected=false;
    this.currentdate = moment().format("YYYY-MM-DD HH:mm:ss")
    this.OutofPktPaymentCompletedLists=[];
    this.OutofPktPaymentActiveLists=[];
    this.totalpending=0;
    this.today=new Date();
    this.dtoBridgePayTransactionSaleRequest=new DtoBridgePayTransactionSaleRequest();
    this.notesContent="";
    this.getOutpofPacketinfo();
    this.getOutpofPacketDtls();
    this.getPaymentMaster();
    this.selectedPaymentMethod=0;
    this.isPrintDatePopup=false;
    this.OutofPktPaymentPrintdata=[];
    this.todayDate=moment().format('MMMM DD, YYYY');
    this.dtoPatientOutofPacketInvoicePdf=new DtoPatientOutofPacketInvoicePdf();
    this.isStartDateErr=false;
    this.isEndDateErr=false;
    this.TmpLists=[];
    this.dtoPatientOutofPacketInfoDetails=new DtoPatientOutofPacketInfoDetails();
    this.dtoPatientOutofPacketNotes=new DtoPatientOutofPacketNotes();
    this.dtoPatientOutofPacketNotes.NotesId=0;
    this.dtoPatientOutofPacketNotes.NotesContent="";
    this.dtoPatientOutofPacketNotes.PatientAccountId=this.patientAccountId;
    const pageid = constantVariables.pagelists.OutofpocketInvoice;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.dtoOutOfPacketPdfHistory=new DtoOutOfPacketPdfHistory();
    }
onBackClick()
{
  this.reDirectFrom=="admin"? this.router.navigate(['/patientslists']):this.router.navigate(['/patient-breifcase']);
}
getOutpofPacketinfo()
{
  this.spinnerservice.show();
    this.patientservice.getPatientOutofPktInfo(this.patientAccountId).subscribe(
      res => {
         if(res.objInputString!=null) {
          this.spinnerservice.hide();
         let result=this.commonService.decrypdata(res.objInputString);
           this.dtoPatientOutofPacketInfo=result[0];
           this.dtoPatientOutofPacketNotes.NotesId=this.dtoPatientOutofPacketInfo.NotesId;
           this.dtoPatientOutofPacketNotes.NotesContent=this.dtoPatientOutofPacketInfo.NotesContent;
           this.dtoPatientOutofPacketNotes.PatientAccountId=this.dtoPatientOutofPacketInfo.PatientAccountId;
           this.isShowNotesAttachment=(this.dtoPatientOutofPacketInfo.NotesId==0)?false:true;
             }
         },
      err => {this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
}

getOutpofPacketDtls()
{
  this.spinnerservice.show();
   this.patientservice.getPatientOutofPktDtls(this.patientAccountId).subscribe(
      res => {
        this.spinnerservice.hide();
        if(res.objInputString!=null) {
          let resultdata=this.commonService.decrypdata(res.objInputString);
           this.dtoPatientOutofPacketInfoDetails=resultdata;
           this.OutofPktPaymentActiveLists=this.dtoPatientOutofPacketInfoDetails.DtoPatientOutofPacketInfoDetailsActiveLists;
           this.OutofPktPaymentCompletedLists=this.dtoPatientOutofPacketInfoDetails.DtoPatientOutofPacketInfoDetailsCompletedLists;
             if(this.OutofPktPaymentActiveLists.length>0){
              let g=this.OutofPktPaymentActiveLists.find(x=>x.PayFrequency==3)
              this.isPerWeek=(g==null|| g==undefined)?false:true;
                    
           const resultsumSession = this.OutofPktPaymentActiveLists.reduce((accumulator, obj) => {
            return accumulator + obj.DueAmount;
          }, 0);
          this.totalpending=resultsumSession;
    
         
             }
             if(this.OutofPktPaymentCompletedLists.length>0){
             let g=this.OutofPktPaymentCompletedLists.find(x=>x.PayFrequency==3)
             this.isPerWeekC=(g==null || g==undefined)?false:true; 
             console.log(this.OutofPktPaymentCompletedLists.find(x=>x.PayFrequency==3))
             console.log(g);
             console.log(this.isPerWeekC);
            }
          }
         },
      err => {this.spinnerservice.hide();}
    );
}

getOutpofPacketDtls_old()
{
  
  this.spinnerservice.show();
   this.patientservice.getPatientOutofPktDtls(this.patientAccountId).subscribe(
      res => {
        this.spinnerservice.hide();
        if(res.objInputString!=null) {
          let resultdata=this.commonService.decrypdata(res.objInputString);
           this.dtoPatientOutofPacketInfoDetails=resultdata;
            this.OutofPktPaymentActiveLists=this.dtoPatientOutofPacketInfoDetails.DtoPatientOutofPacketInfoDetailsActiveLists;
           this.OutofPktPaymentCompletedLists=this.dtoPatientOutofPacketInfoDetails.DtoPatientOutofPacketInfoDetailsCompletedLists;
           // //  let newItems = this.groupByweeksession(this.OutofPktPaymentCompletedLists);
        // // this.TmpLists=newItems;
        //    this.OutofPktPaymentCompletedLists=this.OutofPktPaymentCompletedLists.filter(x=>x.BillStatus=="C");
        //    this.OutofPktPaymentActiveLists=resultdata;
            this.OutofPktPaymentActiveLists=this.OutofPktPaymentActiveLists.filter(x=>x.BillStatus!="C");
           this.OutofPktPaymentActiveLists = this.groupByweeksession(this.OutofPktPaymentActiveLists);
          if(this.OutofPktPaymentActiveLists.length>0){
         //  const resultsumSession = this.OutofPktPaymentActiveLists.reduce((accumulator, obj) => {
          //   return accumulator + obj.SessionFee;
          // }, 0);
          // const resultsumIntake = this.OutofPktPaymentActiveLists.reduce((accumulator, obj) => {
          //   return accumulator + obj.InTakeFee;
          // }, 0);
          // this.totalpending=resultsumSession+resultsumIntake;
             }
             this.OutofPktPaymentCompletedLists=this.OutofPktPaymentCompletedLists.filter(x=>x.BillStatus=="C");
             this.OutofPktPaymentCompletedLists = this.groupByweeksession(this.OutofPktPaymentCompletedLists);
          }
         },
      err => {this.spinnerservice.hide();}
    );
}

onShowPaymentPopup(item)
{debugger;
  this.isTranssale=true;
  this.paymentresponse="";
  this.selectedPaymentMethod=0;
  this.dtoBridgePayTransactionSaleRequest=new DtoBridgePayTransactionSaleRequest();
  this.dtoBridgePayTransactionSaleRequest.PatientAccountId=item.PatientAccountId;
  this.dtoBridgePayTransactionSaleRequest.AppointmentId=(item.DayScheduleAssignId==0)?item.MeetingScheduleTimeId:item.DayScheduleAssignId;
   this.dtoBridgePayTransactionSaleRequest.TherapyId=item.TherapyId;
  this.dtoBridgePayTransactionSaleRequest.PatientInfoId=item.PatientInfoId;
  this.dtoBridgePayTransactionSaleRequest.MeetingScheduleTimeId=item.MeetingScheduleTimeId;
  this.dtoBridgePayTransactionSaleRequest.DayScheduleAssignId=item.DayScheduleAssignId;
  this.dtoBridgePayTransactionSaleRequest.TransactionCode=item.TransactionCode;
  this.dtoBridgePayTransactionSaleRequest.BillValue=item.DueAmount;
  //this.dtoBridgePayTransactionSaleRequest.Amount=(item.TherapyId==100)?item.InTakeFee:item.SessionFee;
  this.dtoBridgePayTransactionSaleRequest.Amount=item.DueAmount;

  this.dtoBridgePayTransactionSaleRequest.GroupedDayScheduleAssignId=item.GroupedDayScheduleAssignId;
  this.dtoBridgePayTransactionSaleRequest.GroupedMeetingScheduleTimeId=item.GroupedMeetingScheduleTimeId;
  this.dtoBridgePayTransactionSaleRequest.DueAmount=item.DueAmount;

}
OnCancelsale()
{
  this.paymentTransSale.resetForm();
  this.isTranssale=false;
  this.isPaymentSelected=false;
  this.dtoBridgePayTransactionSaleRequest=new DtoBridgePayTransactionSaleRequest();
 
}

PostTransactionSale(form)
{
  this.paymentresponse="";
  this.spinnerservice.show();
      if (!form.valid) {
        this.spinnerservice.hide();
        return;
      }
      debugger;
      this.dtoBridgePayTransactionSaleRequest.ReaminingAmt=0.00;
      this.dtoBridgePayTransactionSaleRequest.ExcessAmount=0.00;
  this.dtoBridgePayTransactionSaleRequest.PaymentMethodId=this.selectedPaymentMethod
  this.dtoBridgePayTransactionSaleRequest.PaymentMethod=this.drpPaymentMethod.find(x=>x.PaymentTypeId==this.selectedPaymentMethod).PaymentTypeName;
  
  this.dtoBridgePayTransactionSaleRequest.ExpirationDate=moment(this.expirationDate).format("MMYY");
  
  this.dtoBridgePayTransactionSaleRequest.HolderType="P";
  this.dtoBridgePayTransactionSaleRequest.AccountZip =33774;
  this.dtoBridgePayTransactionSaleRequest.InvoiceNum=null;
   this.paymentresponse="";
   if(this.dtoBridgePayTransactionSaleRequest.BillValue>this.dtoBridgePayTransactionSaleRequest.Amount)
   {
    this.dtoBridgePayTransactionSaleRequest.ReaminingAmt=(this.dtoBridgePayTransactionSaleRequest.BillValue-this.dtoBridgePayTransactionSaleRequest.Amount);
   }
   if(this.dtoBridgePayTransactionSaleRequest.BillValue<this.dtoBridgePayTransactionSaleRequest.Amount)
   {
    this.dtoBridgePayTransactionSaleRequest.ExcessAmount=(this.dtoBridgePayTransactionSaleRequest.Amount-this.dtoBridgePayTransactionSaleRequest.BillValue);
   }
  const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoBridgePayTransactionSaleRequest, false);
    this.billingservice.postpayment(dataToEncrypt).subscribe(
    (res: any)  => {
      this.spinnerservice.hide();
       // console.log(res);
       this.isTranssale=false;
      if(this.isCash){
        this.snackbar.success(res);
        this.OnCancelsale();
        this.getOutpofPacketinfo();
        this.getOutpofPacketDtls();
      
      }
    else 
    {
      let js=JSON.parse(res);
      this.paymentresponse=js.responseDescription;
    if(js.responseCode == "00000")
      {
        this.snackbar.success(this.paymentresponse);
        this.OnCancelsale();
        this.getOutpofPacketinfo();
        this.getOutpofPacketDtls();
        //return;
      }
      else{
      this.spinnerservice.hide();
      this.expirationDate="";
      this.snackbar.error(this.paymentresponse);
      this.OnCancelsale();
      }
    }
      },
    err => {
      this.expirationDate="";
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}         
      
padLeadingZeros(num, size) {
  var s = num+"";
  while (s.length < size) s = "0" + s;
  return s;
}

creditnumfocusout(val)
{
  if(val!="")
  {
  var cardnumber = val;
  this.dtoBridgePayTransactionSaleRequest.PaymentAccountNumber= val;
  var last = cardnumber.substring(cardnumber.length - 1);
  this.dtoBridgePayTransactionSaleRequest.PaymentAccountNumberMask=cardnumber.substring(0, cardnumber.length-1).replace(/\d/g,"*")+last;
  }
}
creditnumkeypress(val)
{
  if(val!="")
  {
  var cardnumber = val;
  let originalValue= val;
  var last = cardnumber.substring(cardnumber.length - 1);
  this.dtoBridgePayTransactionSaleRequest.PaymentAccountNumber=cardnumber.substring(0, cardnumber.length-1).replace(/\d/g,"*")+last;
  }
}
securitycodefocusout(val){
  if(val!="")
  {
   this.dtoBridgePayTransactionSaleRequest.SecurityCode= val;
   this.dtoBridgePayTransactionSaleRequest.SecurityCodeMask=val.substring(0, val.length).replace(/\d/g,"*")
  }
}

getPaymentMaster(){
  this.spinnerservice.show();
  this.billingservice.getPaymentMstr().subscribe(
    res => {
      this.spinnerservice.hide();
       if(res.objInputString!=null)
        {
          this.allPaymenttypeMasters = this.commonService.decrypdata(res.objInputString);
          this.drpPaymentMethod=this.allPaymenttypeMasters.filter(x=>x.IsShownPatientOutofPkt==true);
         }
       },
    err => {
      this.spinnerservice.hide();
    },
  );
}
OnchangePaymentMethod(val)
{ 
  this.isPaymentSelected=false;
    if(val!="0"){
     this.isPaymentSelected=true;
     this.isCash=(val==9)?true:false;
     if(this.isCash){this.dtoBridgePayTransactionSaleRequest.PaymentDate=this.currentdate};
    }
}

onCancelClick()
  {
 this.paymentTransSale.resetForm();
 this.dtoBridgePayTransactionSaleRequest=new DtoBridgePayTransactionSaleRequest();
 }
 OnPrintClick()
 { this.isPrintDatePopup=false;
  if(this.OutofPktPaymentPrintdata.length==0)
  {
    this.startDate="";
    this.endDate="";
    this.isPrintDatePopup=true;
  }
  else if(this.OutofPktPaymentPrintdata.length>0)
  {
    this.PrintInvoice();
  }
 }
 OnPrintPopupcancel()
 {
  this.startDate="";
  this.endDate="";
  this.isPrintDatePopup=false;
 }
 OnPrintPopupSubmit()
 { debugger;
    this.isStartDateErr=false;
    this.isEndDateErr=false;
    if((this.startDate=="" || this.startDate== undefined) && (this.endDate=="" || this.endDate==undefined))
      {
        this.isStartDateErr=true;
        this.isEndDateErr=true;
        return;
      }
    else if((this.startDate!="" || this.startDate!= undefined)  && (this.endDate==""  || this.endDate==undefined))
       {
        this.isStartDateErr=false;
        this.isEndDateErr=true;
        return;
       }
    else if((this.startDate=="" || this.startDate== undefined)  && (this.endDate!=""  || this.endDate!=undefined))
        {
        this.isStartDateErr=true;
        this.isEndDateErr=false;
        return;
        }
    if(this.OutofPktPaymentPrintdata.length>0)
      {
        this.isPrintDatePopup=false;
        // this.dtoOutOfPacketPdfHistory.PatientAccountId=this.patientAccountId;
        // this.dtoOutOfPacketPdfHistory.PrintStartDate=this.startDate;
        // this.dtoOutOfPacketPdfHistory.PrintEndDate=this.endDate;
        // this.dtoOutOfPacketPdfHistory.IsDateSelected = true;
        this.PrintInvoice();
      }
    

 }
isprintInvoice = false;

radiobuttonClick(item,evnt)
{ 
  debugger
  this.OutofPktPaymentPrintdata=[];
  this.OutofPktPaymentPrintdata.push(item);
  this.isPrintDatePopup=false;
  // this.dtoOutOfPacketPdfHistory.PatientAccountId=item.PatientAccountId;
  // this.dtoOutOfPacketPdfHistory.PrintStartDate=item.PaidOn;
  // //this.dtoOutOfPacketPdfHistory.PrintEndDate=null;
  // this.dtoOutOfPacketPdfHistory.GroupedDayScheduleAssignId = item.GroupedDayScheduleAssignId;
  // this.dtoOutOfPacketPdfHistory.GroupedMeetingScheduleTimeId = item.GroupedMeetingScheduleTimeId;
  // this.dtoOutOfPacketPdfHistory.GroupTherapyId = item.GroupTherapyId;
  // this.dtoOutOfPacketPdfHistory.DayScheduleSubGroupId = item.DayScheduleSubGroupId;
  // this.dtoOutOfPacketPdfHistory.DayScheduleGroupTherapyId = item.DayScheduleGroupTherapyId;
  // this.dtoOutOfPacketPdfHistory.DayScheduleAssignId = item.DayScheduleAssignId;
  // this.dtoOutOfPacketPdfHistory.IsDateSelected =false;
 }

OnPrintdatechange()
 { 
  let tmpOutofPktPaymentCompletedLists=this.OutofPktPaymentCompletedLists;
  
  this.OutofPktPaymentPrintdata = tmpOutofPktPaymentCompletedLists.filter(
  m => this.datepipe.transform(m.PaidOn, 'yyyy-MM-dd')>=this.datepipe.transform(this.startDate, 'yyyy-MM-dd')
        &&
       this.datepipe.transform(m.PaidOn, 'yyyy-MM-dd')<=this.datepipe.transform(this.endDate, 'yyyy-MM-dd')
  );
 }

 PrintInvoice()
 {
  
  this.dtoPatientOutofPacketInvoicePdf.dtoPatientOutofPacketInfo=this.dtoPatientOutofPacketInfo;
  this.dtoPatientOutofPacketInvoicePdf.dtoPatientOutofPacketInfoDetailsPdf=this.OutofPktPaymentPrintdata;
  this.dtoPatientOutofPacketInvoicePdf.Totalpending=this.totalpending.toString();
  //const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoPatientOutofPacketInvoicePdf, false);
  this.spinnerservice.show();
  this.patientservice.postOutofPktInvoicePdf(this.dtoPatientOutofPacketInvoicePdf).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.OutofPktPaymentPrintdata=[];
        let res1=JSON.parse(res)
        this.pdfInvoice = res1.filePath;
        const linkSource = 'data:application/pdf;base64,'+res1.base64File+'';
        this.downloadLink.nativeElement.href = res1.base64File;
        this.downloadLink.nativeElement.download = res1.fileName;
        this.downloadLink.nativeElement.click();
        //this.SaveOutOfPacketPdfHistory();
        window.location.reload();
        
      },
      err => {
        this.spinnerservice.hide();
      },
    );
 }
//  SaveOutOfPacketPdfHistory(){
//   debugger
//   const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoOutOfPacketPdfHistory, false);
//   this.patientservice.saveOutOfPacketPdfHistory(dataToEncrypt).subscribe(
//     (res: any) => {
//       this.spinnerservice.hide();
//       this.OutofPktPaymentPrintdata=[];
//       let res1=JSON.parse(res)
//       this.pdfInvoice = res1.filePath;
//       const linkSource = 'data:application/pdf;base64,'+res1.base64File+'';
//       this.downloadLink.nativeElement.href = res1.base64File;
//       this.downloadLink.nativeElement.download = res1.fileName;
//       this.downloadLink.nativeElement.click();
//       window.location.reload();
      
//     },
//     err => {
//       this.spinnerservice.hide();
//     },
//   );
//  }
 onaddNotes()
 {
 this.isAddNotes=true;
 }
 onCanceladdNotes(){  
  this.isAddNotes=false; 
  this.isNotesErr=false;
  this.isShowNotesAttachment=(this.dtoPatientOutofPacketInfo.NotesId==0)?false:true;}

 onSaveaddNotes(){
   
  this.isNotesErr=false;
  if(this.dtoPatientOutofPacketNotes.NotesContent=="")
  {
   this.isNotesErr=true;
   return;
  }
  this.dtoPatientOutofPacketNotes.NotesId=(this.dtoPatientOutofPacketNotes==null)?0:this.dtoPatientOutofPacketNotes.NotesId;
  this.dtoPatientOutofPacketNotes.PatientAccountId=this.patientAccountId
  this.spinnerservice.show();
  const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoPatientOutofPacketNotes, false);
  this.patientservice.postPatientOutofPktNotes(dataToEncrypt).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      this.isShowNotesAttachment=true;
      this.isAddNotes=false;
      this.getOutpofPacketinfo();
      this.snackbar.success(JSON.parse(res).message);

      },
    err => {
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
 }
 groupByweeksession(array){
     return array.reduce((r, a) => {
       r[a.DayScheduleAssignId] = r[a.DayScheduleAssignId] || [];
         r[a.DayScheduleAssignId].push(a);
        return r;
     //}, Object.create(null));
   }, {});
 }


}




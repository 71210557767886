import { Component, OnInit, ViewChild } from '@angular/core';
import { constantVariables } from 'src/app/constant/constant';
import { PatientService } from '../patient.service';
import { Global } from 'src/app/global/global.model';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-patient-declainlist',
  templateUrl: './patient-declainlist.component.html',
  styleUrls: ['./patient-declainlist.component.scss']
})
export class PatientDeclainlistComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  statusintake = constantVariables.status.forIntake;
  statusmeetingScheduled = constantVariables.status.meetingScheduled;
  statusintakeScheduled = constantVariables.status.intakeScheduled;
  statusintakeDeclined = constantVariables.status.intakeDeclined;
  statusintakeAccepted = constantVariables.status.intakeAccepted;
  statusintakePacket = constantVariables.status.intakePacket;
  statusmeetingReSchedule = constantVariables.status.meetingReSchedule;
  statusrequestcancel = constantVariables.status.requestcancel;
  statusPsyReschedule = constantVariables.status.psytherapyReschedule;
  DecrptedlstPatient: any = [];
  lstPatient: any = [];
  public searchpatientListtxt : string;
  PatientInfoId : any;
  PatientDeclainIsActive : boolean ;
  isCommentInformation : boolean;
  isCommentInformationtext : any;
  constructor(public patientservice: PatientService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, private route: ActivatedRoute,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    this.getpatientlistView();

}
getpatientlistView() {
  this.spinnerservice.show();
  
   let locationid= Number(localStorage.getItem('sfcclocation'))==0?-1:Number(localStorage.getItem('sfcclocation'));
    this.patientservice.getadminDeclinePatientList(locationid,0,0).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      this.DecrptedlstPatient = this.commonService.decrypdata(res.objInputString);
       this.lstPatient = this.DecrptedlstPatient.slice(0, 20);
      this.dataSource = new MatTableDataSource(this.DecrptedlstPatient);
      this.dataSource.paginator = this.paginator;
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}

onPageChange($event) {
this.lstPatient = this.DecrptedlstPatient.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
}

OnSearchPatient(){
  let tempPatientlist = this.DecrptedlstPatient
  if(this.searchpatientListtxt !="" || this.searchpatientListtxt === undefined || this.searchpatientListtxt === null){
    this.lstPatient = tempPatientlist.filter(
      (x: any) =>
    x.FirstName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
    x.LastName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
  }
  else{
   this.lstPatient = tempPatientlist.slice(0, 5);
  }
}
BacktoPatient(){
  this.router.navigate(['/patientslists'])
}
returnPatient(PatientInfoId){
  this.PatientInfoId = PatientInfoId;

  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.SuccessMessage.LeadReturn
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
      this.moveLeadInformation();      
    }
  });
}
moveLeadInformation(){
  this.spinnerservice.show();
  this.PatientDeclainIsActive = true;
  this.patientservice.RemovePatientAccount(this.PatientInfoId , this.PatientDeclainIsActive,null,null).subscribe(  
      (res: any) => {
      this.snackbar.success("Patient resumed successfully");  
      this.spinnerservice.hide();
      this.getpatientlistView();
    },
    err => {
      this.spinnerservice.hide();
    },
  );
  
}
commentsClick(item){
  this.isCommentInformation = true;
  this.isCommentInformationtext = item

}
closecomments(){
  this.isCommentInformation = false;

}

}

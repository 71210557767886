import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ClinicianService } from '../clinician/clinician.service';
import { IntakeUpdateDto } from 'src/app/_models/_Treatment/_TherapyPopUpObj';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { constantVariables } from 'src/app/constant/constant';

@Component({
  selector: 'app-intake-update-rejoiners',
  templateUrl: './intake-update-rejoiners.component.html',
  styleUrls: ['./intake-update-rejoiners.component.scss']
})
export class IntakeUpdateRejoinersComponent implements OnInit {
  frmintakeupdate: FormGroup;
  patientAccountId: any;
  intakeupdateDto: IntakeUpdateDto = new IntakeUpdateDto;
  isESignAttached: boolean;
  updatedsignatureImg: any;
  isAttachEsign: boolean;
  defaultImage: any;
  clientname: any;
  intakeupdate: any;
  intakeupdateDtoPatient: any;
  date: any;
  currentdate: any;
  ddlStafflist : any;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  constructor(private router: Router, private route: ActivatedRoute, public commonService: CommonService,
    public global: Global, private dialog: MatDialog, public spinnerservice: NgxSpinnerService, public clinicianservice: ClinicianService,
    public datepipe: DatePipe, public domSrv: DomSanitizer, private snackbar: SnackBarService, private fb: FormBuilder, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
      }
    });
    this.currentdate = new Date();
    this.getStaffList();
    this.getSaveUpdateIntake(this.patientAccountId);
  }
  createForm() {
    this.frmintakeupdate = this.fb.group({
      date: ['',],
      behaviors: ['',],
      currentDiagnosis: ['',],
      currentMedications: ['',],
      currentsupport: ['',],
      schoolPlacement: ['',],
      homodicalorsuicidal: ['',],
      stressors: ['',],
      familySituation: ['',],
      hospitalizations: ['',],
      recommendedGoals: ['',],
      Currenthobbies: ['',],
      strengths: ['',],
      clinicianName: ['',],
      signatureImg: [''],
      dob: [''],
      updatedon: [''],
      clientname: [''],
      patientAccountId: ['']
    });
  }
  onSaveUpdateIntake() {
    this.spinnerservice.show();
    this.intakeupdateDto.patientAccountId = this.patientAccountId;
    this.clinicianservice.saveUpdateIntake(this.intakeupdateDto).subscribe(
      (res: any) => {
        if (res) {
          this.snackbar.success(res);
          this.getSaveUpdateIntake(this.patientAccountId)
        }
      }
    );
    this.spinnerservice.hide();
  }
  getSaveUpdateIntake(patientAccountId) {
    this.spinnerservice.show();
    this.clinicianservice.getSaveUpdateIntake(patientAccountId).subscribe(
      (res: any) => {
        if(res != null){
          var fres = res;
          this.intakeupdateDto = fres.sPtakeUpdateRejoiners;
          this.intakeupdateDtoPatient = fres.patientdetailsDto;
          this.clientname = this.intakeupdateDtoPatient.clientname;
          if(this.intakeupdateDto == null){
           this.intakeupdateDto = new IntakeUpdateDto
            this.intakeupdateDto.date = new Date(this.getcurrentdate());
            return;
          }
          else{
            var date1 = new Date(this.intakeupdateDto.date);
            date1.setHours(1, 0, 0, 0);
            const dateObject = new Date(date1 + 'Z');
    
            const formattedDate = this.formatDate(dateObject);
            this.intakeupdateDto.date = formattedDate ? formattedDate : this.currentdate;
          }
          
          (document.getElementById('esign') as HTMLImageElement).src = this.intakeupdateDto.signatureImg
  
          // this.frmintakeupdate.controls["date"].setValue(date);
          // this.frmintakeupdate.controls["updatedon"].setValue(date);
          // this.frmintakeupdate.controls["behaviors"].setValue(intakeupdateDto.behaviors);
          // this.frmintakeupdate.controls["currentDiagnosis"].setValue(intakeupdateDto.currentDiagnosis);
          // this.frmintakeupdate.controls["currentMedications"].setValue(intakeupdateDto.currentMedications);
          // this.frmintakeupdate.controls["currentsupport"].setValue(intakeupdateDto.currentsupport);
          // this.frmintakeupdate.controls["schoolPlacement"].setValue(intakeupdateDto.schoolPlacement);
          // this.frmintakeupdate.controls["homodicalorsuicidal"].setValue(intakeupdateDto.homodicalorsuicidal);
          // this.frmintakeupdate.controls["stressors"].setValue(intakeupdateDto.stressors);
          // this.frmintakeupdate.controls["familySituation"].setValue(intakeupdateDto.familySituation);
          // this.frmintakeupdate.controls["hospitalizations"].setValue(intakeupdateDto.hospitalizations);
          // this.frmintakeupdate.controls["recommendedGoals"].setValue(intakeupdateDto.recommendedGoals);
          // this.frmintakeupdate.controls["Currenthobbies"].setValue(intakeupdateDto.currenthobbies);
          // this.frmintakeupdate.controls["strengths"].setValue(intakeupdateDto.strengths);
          // (document.getElementById('esign') as HTMLImageElement).src = intakeupdateDto.signatureImg
          // this.frmintakeupdate.controls["signatureImg"].setValue((document.getElementById('esign') as HTMLImageElement).src);
          // this.frmintakeupdate.controls["clinicianName"].setValue(intakeupdateDto.ClinicianName);
          // this.frmintakeupdate.controls["dob"].setValue(intakeupdateDtoPatient.dob);
          // // this.frmintakeupdate.controls["updatedon"].setValue(intakeupdateDto.Updatedon);
          // this.frmintakeupdate.controls["clientname"].setValue(intakeupdateDtoPatient.clientname);
          // this.clientname = intakeupdateDto.clientname;
          // this.cdr.detectChanges();
          
          
        }

      }
      
    );
    this.spinnerservice.hide();
  }
  formatDate(date: Date) {
    return date;
  }
getcurrentdate(){
  var currentDate = new Date();

  // Extract individual components
  var year = currentDate.getFullYear();
  var month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Month is zero-based
  var day = ('0' + currentDate.getDate()).slice(-2);
  var hours = ('0' + currentDate.getHours()).slice(-2);
  var minutes = ('0' + currentDate.getMinutes()).slice(-2);
  var seconds = ('0' + currentDate.getSeconds()).slice(-2);
  var milliseconds = ('00' + currentDate.getMilliseconds()).slice(-3);
  
  // Construct the desired format
  var formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
  
 return formattedDate
}



  openDialog() {
    this.global.isconsentesign = false;
    const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
      data: { isfutureSign: false, isParentsign: true },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.isESignAttached = this.global.isconsentesign;
      if (
        this.global.isconsentesign != undefined &&
        this.global.isconsentesign
      ) {
        this.intakeupdateDto.signatureImg = this.updatedsignatureImg;
        (document.getElementById('esign') as HTMLImageElement).src = this.intakeupdateDto.signatureImg;
      } else if (
        !this.global.isconsentesign &&
        this.global.formtype != null &&
        this.global.formtype != undefined
      ) {
        this.intakeupdateDto.signatureImg = this.global.consentesign;

        (document.getElementById('esign') as HTMLImageElement).src =
          this.intakeupdateDto.signatureImg;
      } else {
        this.intakeupdateDto.signatureImg = this.defaultImage;
      }
    });
  }
  generatePdf() {
    this.spinnerservice.show();
    this.clinicianservice.getGenerateIntakePdf(this.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
          console.log(res);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onBack() {
    if(this.global.ismytask == undefined || this.global.ismytask){
      window.close(); 
    }
    else{
      this.router.navigateByUrl('/clinician');
    }
  }
  getStaffList()
{
   var SfccMasterId = '-1'
this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician,SfccMasterId).subscribe(
    (res: any) => {
      if (res) {
        this.ddlStafflist = res;  
        this.cdr.detectChanges();  
        // var client =  this.ddlStafflist.filter(x=>x.staffId==this.dtoScheduleModality.provider)[0];
        // //console.log(this.ddlStafflist);
        
        // //console.log(client);        
        // this.ObjTherapyPopUp.clinicianName = client?.firstName + " " +client?.lastName;
        }   
      },
    err => {
      this.spinnerservice.hide();
    },
  );
}

}

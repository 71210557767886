export class users{
    userId: number;      
    firstName :string;
    lastName:string;
   // userName: string;    
    email:string;   
    contactNumber:string;
    roleId:number;
    isActive: boolean;
    location:any;
    reportedTo:any;
    gender:number;
    isManager:boolean;
    oldEmail:string;
    isLicensed : boolean;
    licenseNumber : any;
    isLicenser : boolean;
    licenserNumber : any;
    sSignature : boolean;
}
export class roles{
    roleId: number;      
    location :number;
    isAll: boolean;
    details:any;
    isSave:boolean;
}
<div class="content-main-section " *ngIf="roleAccess.isView">
 
  <div class="d-flex justify-content-between align-items-center">
    <div class="">
      <h4 class="head-section m-0" *ngIf="!isShowDayView">Calendar</h4>
    </div>
    <div class="">
      <div class="btn-group custom-radio-grp mb-2" role="group" aria-label="Basic example">
        <button type="button" (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month && !isShowDayView" class="btn btn-secondary">Month</button>

        <button type="button" (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week && !isShowDayView" class="btn btn-secondary">Week</button>
        <!-- <button type="button" class="btn btn-secondary active" (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day">Day</button> -->
        <button type="button" class="btn btn-secondary active" (click)="dayViewClick()" id="btn_dayview"
          [class.active]="isShowDayView">Day</button>
      </div>
    </div>
  </div>

  <div class="col-md-12 d-flex justify-content-between day-changer-container p-0 ">    
    <div class="d-flex">
      <div class="mt-0 pt-1 day-changer" *ngIf="!isShowDayView">
        <div class="align-items-center d-flex slider-arr mr-3">
          <div class="d-inline-block mr-2">
            <span class="current-date text-nowrap">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</span>
          </div>
          <a class="mr-2 cursor-pointer">
            <span mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="filterDayPrev($event)"><i class="fas fa-chevron-left"></i></span>
          </a>
          <a class="mr-2 cursor-pointer">
            <span mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="filterDayNext($event)">
              <i class="fas fa-chevron-right"></i></span>
          </a>
          <!-- Newly add -->
          <div class="d-inline-block choose-date date-hide ml-0">
            <mat-form-field class="w-100">
              <mat-label>Choose a date</mat-label>
              <input matInput [matDatepicker]="picker" (dateChange)="addEvent('change', $event)" [(ngModel)]="PickedDate"
                name="PickedDate">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- Newly add -->
        </div>
      </div>
      <div class="ml-auto cliname_margin client-search">
        <div class="d-flex">
        
          <!-- <i class="material-icons search-icon" id="srcicon">search</i> -->
          <!-- <mat-form-field class="example-full-width" id="filtercontrol">
            <input type="text" matInput [formControl]="filterControl" [matAutocomplete]="auto" placeholder="Client Name"
            [matAutocompleteDisabled]="!isAutocompleteEnabled" (keyup)="onKeyUp($event)">
            <button mat-button matSuffix *ngIf="filterControl" mat-icon-button (click)="onClick()">
              <mat-icon>close</mat-icon>
          </button>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="clientnamedetails($event)">
              <mat-option *ngFor="let a of filteredOptions " [value]="a.patientName" (click)="matInput.value=''"> {{a.patientName}} </mat-option>
            </mat-autocomplete>
           
          </mat-form-field> -->
  
          <mat-form-field class="example-full-width"> 
            <input type="text" matInput [formControl]="filterControl" [matAutocomplete]="auto" placeholder="Client Name"
            [matAutocompleteDisabled]="!isAutocompleteEnabled" (keyup)="onKeyUp($event)">
            <button mat-button matSuffix *ngIf="filterControl" mat-icon-button (click)="onClick()">
               <mat-icon matSuffix >clear</mat-icon> 
               </button>
               <mat-autocomplete #auto="matAutocomplete" (optionSelected)="clientnamedetails($event)">
                <mat-option *ngFor="let a of filteredOptions " [value]="a.patientName" (click)="matInput.value=''"> {{a.patientName}} </mat-option>
              </mat-autocomplete>
              </mat-form-field>
              <mat-icon matSuffix class ="src" id="search">search</mat-icon>   
        </div>
      </div>
    </div>
    <div class="">
      <div class="d-flex">
        <div class="filter-group d-flex">
          <label class="d-inline-block mb-0 mt-2 mr-3">Clinician</label>
          <select class="form-control d-inline-block status-filter" [value]='0' [(ngModel)]="selectedClinician"
            name="clinician" (change)="onAppointmentClinicianchange()">
            <option value="-1">All</option>
            <option *ngFor="let i of drpClinicians" [value]="i.clinicianId">{{i.clinicianName}}</option>
          </select>
          
        </div>
  
        <div class="filter-group d-flex mr-0">
          <label class="d-inline-block mb-0 mt-2 mr-3">Appointment</label>
          <!--  <select class="form-control d-inline-block status-filter" [(ngModel)]="dtoAppointmentType"
          #AppointmentType="ngModel" name="AppointmentType" (change)="onchangeAppointment($event.target.value)"> -->
          <select class="form-control d-inline-block status-filter" [(ngModel)]="dtoAppointmentType"
            #AppointmentType="ngModel" name="AppointmentType" (change)="onAppointmentClinicianchange()">
            <option value="undefined">--Select --</option>
            <option value="1" selected>All</option>
            <option value="2">Intake</option>
            <option value="3">Group Therapy</option>
            <option value="4">Individual Therapy</option>
            <option value="5">Family Therapy</option>
            <option value="7">Psychiatric Therapy</option>
            <option value="8">Psychiatric Evaluation</option>
            <option value="9">Psychiatric Medication</option>
            <option value="6">Tour</option>
            <option value="11">Phone Call </option>
            <option value="10">School Meeting</option>
            <option value="12">Speech Therapy</option>
            <option value="13">Nutrition Therapy</option>
            <option value="14">Block Time</option>
          </select>
        </div>
        <!-- <button mat-stroked-button class="btn primary-btn-outlined-small mr-2 "><i class="fas fa-plus-circle mr-1"></i> Appointment</button> -->
        <button class="rearange" title="Re-arrange Clinician" (click)="OnreArrangeClinician()"><i
          class="fas fa-sort-amount-up"></i></button>
  
      </div>
    </div>
    
  </div>
</div>

<div [ngSwitch]="view" class="schedular" *ngIf="!isShowDayView && roleAccess.isView" >


  
  <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
    [cellTemplate]="customCellTemplate" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="daySegmentClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
    class="month-scheduler" >
  </mwl-calendar-month-view>
  
  <mwl-calendar-week-view class="schdule-calendar" *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate"
  [events]="events" (eventClicked)="handleEvent('Clicked', $event.event)"
  (eventTimesChanged)="eventTimesChanged($event)" [dayStartHour]="07"
   (hourSegmentClicked)="hourSegmentClicked($event.date)" ></mwl-calendar-week-view>



  <ng-template #customCellTemplate let-day="day" let-locale="locale">
    <div class="cal-cell-top">
      <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
      <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    </div>
    <div *ngIf="day.events.length > 0" class="">
      <div *ngFor="let event of day.events; trackBy: trackByEventId; index as i"   >

        <ng-template *ngIf="i < EVENT_LIMIT; then showEventsBlock; else showMoreBlock">
        </ng-template>
        <ng-template #showEventsBlock>
          <div class="col-event-mytask cal-event-title " *ngIf="i < EVENT_LIMIT" (mwlClick)="eventClicked(event)" [ngStyle]="{'background-color': event.color.primary}" >
          <!-- <div  [ngStyle]="{'background-color': event.color}"> -->
            <span innerHtml=" {{event.title}}"></span>
          </div>
          <!-- </div> -->
        </ng-template>
        <ng-template #showMoreBlock>
          <ng-container *ngIf="i === EVENT_LIMIT">
            <div class="cal-events" (mwlClick)="handleMoreEvent($event,day.events)">
              <a class="showmore font-14"> {{day.events.length - EVENT_LIMIT}} more</a>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-template>
  <!-- (beforeViewRender)="updateCalendarEvents($event)"  -->
  
 
  <!-- <mwl-day-view-scheduler *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [users]="users"
[refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)"
[dayStartHour]="07"
(eventClicked)="handleEvent('Clicked', $event.event)">
</mwl-day-view-scheduler> -->






</div>

<div *ngIf="isShowDayView && roleAccess.isView" #dayviewDiv>
  <app-admin-day-view-schedule [date_view]="date_view"></app-admin-day-view-schedule>
</div>

<!--If View permission is not there for this role-->
<div class="task" *ngIf="!roleAccess.isView">
  <div class="msg-nomeet text-center"> {{viewRightsMsg}}</div>
</div>
<!--More details pop up-->
<div class="modal bulk-add emoji-popup1 " id="myModal" [style.display]="isShowMoreEvent ? 'block' : 'none'">
  <div class="modal-dialog mb-2">
    <div class="modal-content">
      <div class="modal-body p-0">
        <div class="clearfix popup-mytask position-relative">
          <div class="modal-closing "><a class="cursor-pointer" (click)="isShowMoreEvent = !isShowMoreEvent"><i
                class="fa fa-times"></i></a></div>
          <div class="col-md-12 mytask p-0">
            <div class="header-day" class="badge badge-info mt-2 mb-2 ml-2" *ngIf="moreTaskEvents?.length > 0">
              {{moreTaskEvents[0].start | date:'EEEE, MMMM d, y'}}</div>
            <div class="popup-list col-md-12">
              <div class="row ">
                <div *ngFor="let e of moreTaskEvents" class="col-md-6 mb-1">
                  <div class="col-event-mytask cal-event-title " (click)="handleEvent('Clicked', e)">
                    <div  [ngStyle]="{'background-color': e.color}">
                    <span innerHtml="{{e.title}} {{e.fromTime| date:'hh:mm a'}}"></span>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
<!--More details pop up end-->
<!--Select Location pop up-->
<div class="comment-popup loc-sched-popup benchmark-popup-rating">
  <div class="modal" tabindex="-1" role="dialog" [style.display]="isAllLocation ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container p-3" role="document">
      <div class="modal-content border-0">
        <div class="modal-body">
          <div>
            <div class="text-center location-schedule">
              <i class="fas fa-map-marked-alt"></i>
            </div>
            <p class="text-center font-weight-500">Please choose a location</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Select Location pop up end-->

<!--Re Arrange clinician pop up-->
<div class="comment-popup  benchmark-popup-rating">
  <div class="modal" tabindex="-1" role="dialog" [style.display]="isReArrangeClinician ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container p-3" role="document">
      <div class="modal-content border-0">
        <div class="modal-body">
          <div>

            <p class="text-center font-weight-500">Re-arrange Clinician</p>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5">
                  <table id="tbl_reArrange" class="form-control" style="overflow:auto;height:185px;">
                    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
                      <tr *ngFor="let value of drpClinicians; let index = index;" (click)="getTdindex(index);" cdkDrag
                        [ngClass]="{'td-rearrangeClinician':(index ==rearrangeSelectedIndex)}">
                        <td>{{value.clinicianName}}</td>
                        <td style="display:none;">{{value.clinicianId}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-1">
                  <div class="mt-5 mb-2"><button class="up-down-btn" (click)="moveCUp();"><i
                        class="fas fa-arrow-up"></i></button></div>
                  <div><button class="up-down-btn" (click)="moveCDown();"><i class="fas fa-arrow-down"></i></button>
                  </div>

                </div>
                <div class="col-md-6">
                  <div class="hint-msg">
                    Select the Clinician <div> Drag & Drop</div>
                    <div>Or</div> Arrow Key to re-arrange the order of Clinician
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right col-md-12 mt-2">
              <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2"
                (click)="reArrangeClinicianSave()">Save</button>
              <button type="button" class="btn btn-cancel" (click)="reArrangePopUpClose();">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Re Arrange clinician pop up end-->





<div *ngIf="isShowTherapydetails" #TherapyDetailsPopup>
  <app-therapy-details-pop-up [ObjTherapyPopUp]="ObjTherapyPopUp"></app-therapy-details-pop-up>
</div>


<div class="comment-popup loc-sched-popup benchmark-popup-rating">
  <div class="modal" tabindex="-1" role="dialog" [style.display]="isnodata ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container p-3" role="document">
      <div class="modal-content border-0">
        <div class="modal-body">
          <div>
            <div class="text-center location-schedule">
              <i class="fas fa-map-marked-alt"></i>
            </div>
            <!-- <p  class="text-center font-weight-500"> Chosen location doesn't include any scheduled session</p> -->
            <p class="text-center font-weight-500"> Chosen location does not have any scheduled sessions</p>
            <a (click)="hideModal()" class="addSession-style" *ngIf="!isNoClinician">Add Session</a>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isShowScheduleModality" #scheuleModality>
  <app-schedule-modality-pop-up [ObjTherapyPopUp]="ObjTherapyPopUp"></app-schedule-modality-pop-up>
</div>



<ng-template let-data #clientModal>
  <div class="row">
    <h2 mat-dialog-title class="col-8">Appointment Details</h2>
    <div class="col-3">
      <button class="btn generate-btn mr-2 pdf_color " (click)="onGeneratePDF(appointmentType)"><i class="far fa-file-pdf mr-2"></i>Generate
        PDF</button>
    </div>
    <div class="col-1 text_right">
      <mat-icon  mat-dialog-close>close</mat-icon>
    </div>
  </div>

  <mat-dialog-content>
    <div class="row mt_20">
      <div class="col-5 ">
        <div class="d-flex row ml-10">
          <h6 class="p-2 mar_left"> Patient Name : </h6>
          <span class="p-2 client_mrgtop">{{patientNameview}}</span>
        </div>
      </div>
      <aside id="info-block" class="col-6 mar_border">
        <section class="file-marker">
          <div>
            <div class="box-title">
              Appointments
            </div>
            <div class="box-contents">
              <div id="audit-trail">
                <label class="Radio_padding">
                  <input type="radio" [(ngModel)]="appointmentType" (change)="appointmentchangeValue($event)"
                    name="Appointment" value="past" /> Past
                </label>

                <label class="Radio_padding">
                  <input type="radio" [(ngModel)]="appointmentType" (change)="appointmentchangeValue($event)"
                    name="Appointment" value="future" /> Future
                </label>
                <label class="Radio_padding">
                  <input type="radio" [(ngModel)]="appointmentType" (change)="appointmentchangeValue($event)"
                    name="Appointment" value="all" /> All
                </label>
                <label class="Radio_padding">
                  <input type="radio" [(ngModel)]="appointmentType" (change)="appointmentchangeValue($event)"
                    name="Appointment" value="Deleted" /> Deleted
                </label>
              </div>
            </div>
          </div>
        </section>
      </aside>
    </div>
    <div class="d-flex mt-2 justify-content-center"
      *ngIf="clientTherapydetails.length ==  0 && appointmentType == 'future'">
      <span class="text-danger p-2">No Appointments for future </span>
    </div>
    <div class="d-flex mt-2 justify-content-center"
      *ngIf="clientTherapydetails.length ==  0 && appointmentType == 'past'">
      <span class="text-danger p-2">No Appointments for past </span>
    </div>
    <div class="d-flex mt-2 justify-content-center"
      *ngIf="clientTherapydetails.length ==  0 && appointmentType == 'all'">
      <span class="text-danger p-2">No Appointments for all </span>
    </div>
    <div class="d-flex mt-2 justify-content-center"
      *ngIf="clientTherapydetailsDelete.length ==  0 && appointmentType == 'Deleted'">
      <span class="text-danger p-2">No Appointments for Deleted </span>
    </div>
    <div class="row" *ngIf="clientTherapydetails.length > 0">
      <div style="margin-top: 10px;">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Location</th>
              <th>Provider</th>
              <th>Date</th>
              <th>Time</th>
              <th>Duration</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let li of clientTherapydetails;let i=index" (click)="chooseTheraphy(li)">
              <td>{{li.location}}</td>
              <td>{{li.clinicianName}}</td>
              <td>{{li.scheduleDate}}</td>
              <td>{{li.fromTime |date:'shortTime'}}</td>
              <td>{{li.duration}}</td>
              <td>{{li.reason}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <table #table [dataSource]="dataSourceClient"  class="w-100">
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef> Location</th>
            <td mat-cell *matCellDef="let element"> {{element.location}} </td>
          </ng-container>
          <ng-container matColumnDef="clinicianName">
            <th mat-header-cell *matHeaderCellDef> Provider </th>
            <td mat-cell *matCellDef="let element"> {{element.clinicianName}} </td>
          </ng-container>
          <ng-container matColumnDef="scheduleDate">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.scheduleDate}} </td>
          </ng-container>
          <ng-container matColumnDef="fromTime">
            <th mat-header-cell *matHeaderCellDef> Time </th>
            <td mat-cell *matCellDef="let element"> {{element.fromTime}} </td>
          </ng-container>
          <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef> Duration </th>
            <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
          </ng-container>
          <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef> Reason </th>
            <td mat-cell *matCellDef="let element"> {{element.reason}} </td>
          </ng-container> 
        </table> -->
    </div>
    <div class="row scrollwdth" *ngIf="clientTherapydetailsDelete.length > 0">
      <div style="margin-top: 10px;">
        <table class="table table-bordered tblwidth">
          <thead>
            <tr>
              <th style="width: 3%;">Location</th>
              <th style="width: 4%;">Provider</th>
              <th style="width: 3%;">Date</th>
              <th style="width: 3%;">Time</th>
              <th style="width: 3%;">Duration</th>
              <th style="width: 5%;">Reason</th>
              <th style="width: 3%;">Reason for deletion</th>
              <th style="width: 25%;">Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let li of clientTherapydetailsDelete;let i=index" (click)="chooseTheraphy(li)">
              <td>{{li.location}}</td>
              <td>{{li.clinicianName}}</td>
              <td>{{li.scheduleDate}}</td>
              <td>{{li.fromTime |date:'shortTime'}}</td>
              <td>{{li.duration}}</td>
              <td>{{li.reason}}</td>
              <td>{{li.deleteReason}}</td>
              <td>{{li.deleteComments}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
  </mat-dialog-content>
</ng-template>
<a mimetype="application/octet-stream" #downloadLink></a>
<a mimetype="application/octet-stream" #mainpage></a>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private http: HttpClient, public commonService: CommonService) { }
  getTemplateCategory() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Template/GetTemplateCategory');
  }
  getTemplateNames(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Template/GetTemplateNames?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getConsentTemplateDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Template/GetConsentTemplateDetails?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getTemplateFields(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Template/GetTemplateFields?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  
  postConsentTemplate(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTemplate/PostConsentTemplate',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  postimportImage(frmData) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'multipart/form-data'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostIntake/ImageUpload', frmData)
      .pipe(map(res => {

        return res;
      }));
  }
  poststatus(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTemplate/ChangeStatus',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getConsentTemplates() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Template/GetConsentTemplates');
  }
  deleteTemplate(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostTemplate/DeleteTemplate?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  deleteTemplates(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTemplate/DeleteTemplates',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   // return this.http.get<any>(environment.BaseURI + 'gateway/PostTemplate/DeleteTemplates' + this.commonService.DataToEncrypt(id, true));
  }
  postInstructionType(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/SaveInstructionType',
    //return this.http.post('https://localhost:44368/api/Master/SaveBlockType',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postPurposeName(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/SavePurposeMaster',
    //return this.http.post('https://localhost:44368/api/Master/SaveBlockType',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getPriceCatalogues(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetPriceCatalogues?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  deletePriceCatalogue(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostPriceCatalogue/DeletePriceCatalogue?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  deletePriceCatalogues(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostPriceCatalogue/DetelePriceCatalogues  ',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  getLocations() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetLocations');
  }
  getLocationList() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetLocationList');
  }

  //#region forms
   getConfigureForms() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetConfigureForm/GetConfigureForms');
  }
  getFormName() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetConfigureForm/GetConfigureFormNames');
  }
  getFormTypes() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetConfigureForm/GetFormTypes');
  }
  getQuestionTypes() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetConfigureForm/GetQuestionTypes');
  }
  getInstructionType() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetInstructionType');
  }
  getDocumentModuleType() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetDocumentModuleType');
  }
  getConfigureFormDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetConfigureForm/GetConfigureFormDetails?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getQuestionDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetConfigureForm/GetQuestionDetails?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getLibraryImages(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetLibraryImages?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getLibraryNames() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetIntake/GetLibraryNames');
  }
  
  postConfigureForm(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostConfigureForm/SaveConfigureForm',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  postConfigureFormDetails(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostConfigureForm/SaveConfigureFormDetail',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  deleteQuestion(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostConfigureForm/DeleteConfigureFormDetail?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  deleteQuesOption(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostConfigureForm/DeleteOption?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  deleteConfigureForms(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/PostConfigureForm/DeleteConfigureForm?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  deleteMultipleConfigureForms(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostConfigureForm/DeleteConfigureForms',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  postBulkQuestions(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostConfigureForm/SaveBulkQuestions',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  postFormName(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostConfigureForm/SaveAppointmentTypeName',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postQuestionOrder(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostConfigureForm/SaveQuestionOrder',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postGoals(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Goal/SaveGoal',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getGoals(isAdmin) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Goal/GetGoals?isAdmin=' + this.commonService.DataToEncrypt(isAdmin, true));
  }
  // getGoals(isAdmin) {
  //   const headers = new HttpHeaders().set(
  //     'Content-Type',
  //     'application/json; charset=utf-8'
  //   );
  //   return this.http.post(environment.BaseURI + 'gateway/Goal/SaveGoal',
  //     isAdmin, { headers: headers, responseType: 'text' })
  //     .pipe(map(function (response) { return response; }));  }
  getGoalsMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetTemplateGoalMaster');
  }
 
  deleteGoals(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/Goal/DeleteGoal?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getGoalsbyId(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/Goal/GetGoalsbyId?Id=' + this.commonService.DataToEncrypt(id, true));
  }

  activeGoals(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Goal/ChangeActive',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  deleteMultipleGoals(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Goal/DeleteGoals',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  //#endregion

//#region therapyname
getTreatmentPlanMaster() {
  return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetTreatmentPlanMaster');
}
//#endregion

  //#region  Group Therapy
  postGroupTherapy(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTemplate/PostGroupTherapy',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getGroupTherapy(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Template/GetGroupTherapy?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  deleteGroupTherapy(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/PostTemplate/DeleteTherapy?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  deleteGroupTherapys(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTemplate/DeleteTherapys  ',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
   
  }
  //#endregion
  
  postConfigureFormDirectionName(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostConfigureForm/SaveDirectionName',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  getTherapyForGoals() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetTherapyForGoals');
  }
  postSkillTaughtCreateForm(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/PostSkillTaughtDetails',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  GetSkillTaughtMasterData() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetSkillTaughtMasterData');
  }
  GetSkillTaughtMasterDataById(data:any) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetSkillTaughtMasterDataById?Id='+data);
  }
  GetSkillTaughtQuestionsById(data:any) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetSkillTaughtQuestionsById?Id='+data);
  }
  postSkillTaughtQuestion(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/PostSkillTaughtQuestions',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  async GetSkillTaughtNamesByTherapyList(data:any,typeId:any,locationId:any) {
    let skiiTaught =await this.http.get<any>(environment.BaseURI + 'gateway/Master/GetSkillTaughtNamesByTherapy?TherapyId='+data+'&TypeId='+typeId+'&locationId='+locationId).toPromise();
    return skiiTaught;
  }
   GetSkillTaughtNamesByTherapy(data:any,typeId:any,locationId:any) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetSkillTaughtNamesByTherapy?TherapyId='+data+'&TypeId='+typeId+'&locationId='+locationId);
  }
  DeleteSkillTaughtForm(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/DeleteSkillTaughtForm',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  GetSkillTaughtQuestionByTherapyIds(data:any) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetSkillTaughtQuestionsByTherapyIds?Ids='+data);
  }
  DeleteSkillTaughtQuestion(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/DeleteSkillTaughtQuestion',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  GetPreliminaryGoals(){
    return this.http.get<any>(environment.BaseURI + 'gateway/Goal/GetPreliminaryGoals');
  }
  postSkillTaughtTherapyForm(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/Master/postSkillTaughtTherapyForm',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
}

   <div class="discharge-form">
    <!-- <div  class="d-flex">
      <div  class="head-section mt-2 mb-2">
        Treatment Summary
      </div>
      <div class="row">
        <div><button class="btn generate-btn mr-2 pdf_color pdf" (click)="treatmentSummarypdf()" ><i class="far fa-file-pdf mr-2"></i>Generate
          PDF</button></div>
        
        <div  class="ml-auto">
          <button  tabindex="0" class="btn back-btn-new mr-2" (click)="OnBack()">
            <i  class="fas fa-chevron-left mr-2"></i>Back</button>
            
        
          </div>
      </div>

      </div> -->
      <div class="d-flex bd-highlight"> 
        <div class="p-2 flex-grow-1 bd-highlight head-section">Treatment Summary</div> 
         <div class=" bd-highlight">
          <button class="btn generate-btn " (click)="treatmentSummarypdf()" ><i class="far fa-file-pdf mr-2"></i>Generate PDF</button>
        </div> 
          <div class="p-2 bd-highlight"><button  tabindex="0" class="btn back-btn-new " (click)="OnBack()">
            <i  class="fas fa-chevron-left "></i>Back</button></div>
         </div>
      <div class="col-md-12 bg-white p-2 pdgtop">
        <form (ngSubmit)="TreatmentSumFrm.form.valid " id="TreatmentSumFrm" autocomplete="off" #TreatmentSumFrm="ngForm"
          novalidate >
          <!--[ngClass]="{'disabled':  !(PatAccountId==0) }"-->
          <div class="col-md-12" [ngClass]="{'disabled': true}">
            <div class="row">
              <div class="col-md-4">
                <mat-form-field class="w-100">
                  <mat-label>Client’s Name</mat-label>
                  <input matInput [(ngModel)]="TreatmentSummaryInfo.patientName" #patientName="ngModel" name="patientName"  [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && patientName.invalid }" required>
                  <mat-error *ngIf="TreatmentSumFrm.submitted && patientName.invalid">
                    <mat-error  *ngIf="patientName.errors.required">
                      Client’s Name is required</mat-error>
                </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2 dateicon-hide" [ngClass]="{'disabled': true }">
                <mat-form-field class="w-100">
                  <mat-label>DOB</mat-label>
                  <input matInput [matDatepicker]="picker" [(ngModel)]="TreatmentSummaryInfo.dob" #dob="ngModel" name="dob">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-4" >
                <ng-select appAlphabetOnly placeholder="Clinician *" [items]="ddlstafflist" bindLabel="staffName" 
                bindValue="userId"   [(ngModel)]="TreatmentSummaryInfo.clinicianId" addTagText="Add item"
                name="clinicianId" #clinicianId="ngModel" >
              </ng-select>

                 <!-- <ng-select appAlphabetOnly placeholder="Clinician *"  bindLabel="staffName" 
                 bindValue="userId"    addTagText="Add item"
                 name="clinicianId"  >
               </ng-select> -->
               </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-2 mt-3">
                <div class="treat-label"> Dates Attended Program</div>
              </div>
              <div class="col-md-2">
                <mat-form-field class="w-100">
                  <mat-label>From</mat-label>
                  <input matInput [matDatepicker]="Frmpicker" [(ngModel)]="TreatmentSummaryInfo.progFromDate" #progFromDate="ngModel" name="progFromDate"  [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && progFromDate.invalid }" required [max]="FromDateRestriction">
                  <mat-datepicker-toggle matSuffix [for]="Frmpicker"></mat-datepicker-toggle>
                  <mat-datepicker #Frmpicker></mat-datepicker>
                  <mat-error *ngIf="TreatmentSumFrm.submitted && progFromDate.invalid">
                    <mat-error  *ngIf="progFromDate.errors.required">
                      This field is required</mat-error>
                </mat-error>
                </mat-form-field>
              </div>
              
              <div class="col-md-2">
                <mat-form-field class="w-100">
                  <mat-label>To</mat-label>
                  <input matInput [matDatepicker]="Topicker" [(ngModel)]="TreatmentSummaryInfo.progToDate" #progToDate="ngModel" name="progToDate"  [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && progToDate.invalid }" required [min]="TreatmentSummaryInfo.progFromDate">
                  <mat-datepicker-toggle matSuffix [for]="Topicker"></mat-datepicker-toggle>
                  <mat-datepicker #Topicker></mat-datepicker>
                  <mat-error *ngIf="TreatmentSumFrm.submitted && progToDate.invalid">
                    <mat-error  *ngIf="progToDate.errors.required">
                      This field is required</mat-error>
                </mat-error>
            
                </mat-form-field>
              </div>
            </div>
          </div>
        
          <div class="col-md-12" [ngClass]="{'disabled': true}">
            <div class="custom-head mt-3 mb-2"><h2>Diagnosis</h2></div>
            <div  class="basic-info diag-sec mb-3 row">
              <div  class="col-md-4">
                <mat-form-field class="w-100">
                  <mat-label>Chooose the Diagnosis Code for the Patient</mat-label>
                  <mat-select multiple  [(ngModel)]="TreatmentSummaryInfo.diagnosisCode" #diagnosisCode="ngModel"
                  name="diagnosisCode" required [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && diagnosisCode.invalid }">

                  <mat-option #matOption *ngFor="let item of ddldiagnosiscode" [value]="item.value"
                    (click)="onDiagnosisCodeselect(item,matOption.selected)" [matOption.selected]="item.isActive">
                    {{item.value}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="TreatmentSumFrm.submitted && diagnosisCode.invalid">
                  <mat-error  *ngIf="diagnosisCode.errors.required">
                    This field is required</mat-error>
              </mat-error>
                </mat-form-field>
              </div>
              <div  class="col-md-8">
                <div class="chip-container-content ">
                  <mat-chip-list aria-label="Fish selection">
                    <ng-container *ngFor="let item of ddldiagnosiscode">
                      <mat-chip *ngIf="item.isActive">{{item.label}}
                        <!--<mat-icon matChipRemove (click)="onRemoveDiagnosisCode(item)">cancel</mat-icon>-->
                      </mat-chip>
                    </ng-container>
                  </mat-chip-list>
                </div>
              </div>
              </div>
          </div>
          
          
          <div class="col-md-12">
            <div class="custom-head mt-3 mb-2"><h2>Course of Treatment</h2></div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Presenting Problem
              </div>
              <textarea row="3" maxlength="1500" class="w-100 form-control mb-3"  [(ngModel)]="TreatmentSummaryInfo.presentingProblem" #presentingProblem="ngModel"
              name="presentingProblem" required [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && presentingProblem.invalid }">
____ presents with the following symptoms and behaviors: ____.
                                </textarea>
                                <mat-error *ngIf="TreatmentSumFrm.submitted && presentingProblem.invalid">
                                  <mat-error class="text-xs" *ngIf="presentingProblem.errors.required">
                                    Presenting Problem is required</mat-error>
                              </mat-error>
              
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Interventions/Treatment Provided
              </div>
              <textarea style="height: 140px;" row="3" maxlength="1500" class="w-100 form-control mb-3"  [(ngModel)]="TreatmentSummaryInfo.treatmentGoals" #treatmentGoals="ngModel"
              name="treatmentGoals" required [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && treatmentGoals.invalid }">
             </textarea>
             <mat-error *ngIf="TreatmentSumFrm.submitted && treatmentGoals.invalid">
              <mat-error class="text-xs" *ngIf="treatmentGoals.errors.required">
                Intervention in Treatment is required</mat-error>
          </mat-error>  
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Treatment Goals
              </div>
              <textarea style="height: 100px;" row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="TreatmentSummaryInfo.treatmentProvided" #treatmentProvided="ngModel"
              name="treatmentProvided" required [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && treatmentProvided.invalid }">
              The treatment goals were as follows:
              Goal #1.
              Goal #2.
              </textarea>
               <mat-error *ngIf="TreatmentSumFrm.submitted && treatmentProvided.invalid">
                <mat-error class="text-xs" *ngIf="treatmentProvided.errors.required">
                  Treatment Provided/Response is required</mat-error>
            </mat-error>                  
            </div>
           
            <div class="col-md-12 p-0">
              <div  class="custom-head mt-3 mb-3">
                <h2 > Strengths, Observations, and Response to Treatment</h2>
              </div>
              <div class="treat-label">
                Strengths
              </div>
              <textarea row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="TreatmentSummaryInfo.strengths" #strengths="ngModel"
              name="strengths" required [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && strengths.invalid }">
To begin, throughout Stepping Forward Counseling Center’s Program ____ demonstrated several strengths. 
               </textarea>
               <mat-error *ngIf="TreatmentSumFrm.submitted && strengths.invalid">
                <mat-error class="text-xs" *ngIf="strengths.errors.required">
                  Strengths is required</mat-error>
            </mat-error>  
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Observations
              </div>
              <textarea row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="TreatmentSummaryInfo.observations" #observations="ngModel"
              name="observations" required [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && observations.invalid }">
 ___ was observed displaying a variety of __ target behaviors. Over the course of treatment counselors observed
               </textarea>
               <mat-error *ngIf="TreatmentSumFrm.submitted && observations.invalid">
                <mat-error class="text-xs" *ngIf="observations.errors.required">
                  Observations is required</mat-error>
            </mat-error>  
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Response to Treatment
              </div>
              <textarea row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="TreatmentSummaryInfo.treatmentResponse" #treatmentResponse="ngModel"
              name="treatmentResponse" required [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && treatmentResponse.invalid }">
With the treatment provided ___ made mild progress toward both treatment goals. Further, ___ mildly improved peer engagement and taking responsibility for ___ actions and utilization of coping skills. ___ continued to present with fragile self-esteem and poor social skills.
              </textarea>
              <mat-error *ngIf="TreatmentSumFrm.submitted && treatmentResponse.invalid">
                <mat-error class="text-xs" *ngIf="treatmentResponse.errors.required">
                  Response to Treatment is required</mat-error>
            </mat-error>  
            </div>
            <div class="col-md-12 p-0">
              <div class="custom-head mt-3 mb-2"><h2>Recommendations</h2></div>
              
              <textarea row="5" maxlength="1500" class="w-100 form-control mb-5" style="height: 112px;" [(ngModel)]="TreatmentSummaryInfo.recommendations" #recommendations="ngModel"
              name="recommendations" required [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && recommendations.invalid }">
              </textarea>
              <mat-error *ngIf="TreatmentSumFrm.submitted && recommendations.invalid">
                <mat-error class="text-xs" *ngIf="recommendations.errors.required">
                  Recommendations is required</mat-error>
            </mat-error>  
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                To help achieve these goals it is recommended
              </div>
              <textarea row="5"  maxlength="850" class="w-100 form-control mb-5" style="height: auto;" [(ngModel)]="TreatmentSummaryInfo.recommendationsToGoals" #recommendationsToGoals="ngModel"
              name="recommendationsToGoals" required [ngClass]="{ 'is-invalid': TreatmentSumFrm.submitted && recommendationsToGoals.invalid }">

              </textarea>
              <mat-error *ngIf="TreatmentSumFrm.submitted && recommendationsToGoals.invalid">
                <mat-error class="text-xs" *ngIf="recommendationsToGoals.errors.required">
                  To help achieve these goals it is recommended is required</mat-error>
            </mat-error> 
            </div>
            
            <div class="col-md-12">
              <div  class="form-group clearfix m-0">
                <div class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label">Clinician’s Signature</label>
                  <img  id="esign_3" height="50px" width="300px">
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialog()" > Add E-Sign </button>
                  <div class="float-left col-md-6 p-0 mt-3 pt-1" >
                    <mat-error *ngIf="TreatmentSumFrm.submitted &&  this.TreatmentSummaryInfo.staffInitials == undefined ">
                      <mat-error class="mat-form-field-subscript-wrapper" *ngIf=" this.TreatmentSummaryInfo.staffInitials == undefined">
                        Clinician’s Signature is required</mat-error>
                  </mat-error>
                   </div>
                </div>
                <div class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label">Secondary Signature</label>
                  <img  id="esign_4" height="50px" width="300px">
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" [disabled]="secondarySign()" (click)="openDialog1()" > Add E-Sign </button>
                  <div class="float-left col-md-6 p-0 mt-3 pt-1" >
                    <!-- <mat-error *ngIf="TreatmentSumFrm.submitted &&  this.TreatmentSummaryInfo.staffInitials == undefined ">
                      <mat-error class="mat-form-field-subscript-wrapper" *ngIf=" this.TreatmentSummaryInfo.staffInitials == undefined">
                        Clinician’s Signature is required</mat-error>
                  </mat-error> -->
                   </div>
                </div>
                <div  class="float-right col-md-2 p-0">
                  <mat-form-field class="w-100 ">
                    <mat-label>Date </mat-label>
                    <input matInput [matDatepicker]="picker3"  [(ngModel)]="TreatmentSummaryInfo.signDate" #signDate="ngModel"
                    name="signDate" >
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                  
                  </mat-form-field>
                  </div>
                </div>
            </div>
            
            
            <div class="col-md-12" *ngIf="PatAccountId==0 && (roleAccess.isCreate || roleAccess.isEdit)">
              <div class="text-right">
              <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" type="submit" form="TreatmentSumFrm" mat-stroked-button (click)="PostTreatmentSummary()"> {{Isnew?'Save':'Update'}} </button>
              <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button (click)="OnBack()">Cancel</button>
            </div>
            </div>
          </div>
        </form>
      </div>
  </div> 
  <a mimetype="application/octet-stream" #downloadLink></a> 

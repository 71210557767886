import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { NgForm } from '@angular/forms';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { PatientService } from 'src/app/patient/patient.service';
import { cloneDeep } from "lodash";
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';

@Component({
  selector: 'app-patient-breifcase',
  templateUrl: './patient-breifcase.component.html',
  styleUrls: ['./patient-breifcase.component.scss']
})
export class PatientBreifcaseComponent implements OnInit {

  patientInfoId: any;
  lstPriorIntake: any;
  lstMeetingSchedule: any;
  lstTourRequest: any;
  rblMeetingDate:any
  immediateAction:any;
  intakeStatus:any;
  isAccceptedIntake:boolean = false;
  statusintake=constantVariables.status.forIntake;
  statusmeetingScheduled=constantVariables.status.meetingScheduled;
  statusintakeScheduled=constantVariables.status.intakeScheduled;
  statusintakeDeclined=constantVariables.status.intakeDeclined;
  statusAccept=constantVariables.status.Accept;
  statusDecline=constantVariables.status.Decline;
  statusforIntake=constantVariables.status.forIntake;
  statusstaffAssigned=constantVariables.status.staffAssigned;
  statusintakePacket=constantVariables.status.intakePacket;
  Tour=constantVariables.PatientAppointmentTypes.Tour;
  Intake=constantVariables.PatientAppointmentTypes.Intake;
  IndividualTherapy=constantVariables.PatientAppointmentTypes.IndividualTherapy;
  FamilyTherapy=constantVariables.PatientAppointmentTypes.FamilyTherapy;
  GroupTherapy=constantVariables.PatientAppointmentTypes.FamilyTherapy;
  PatientAppointments: any;
  PatientUpcomingAppointments: any;
  selectedTabIndex=1;
  medicationRecords: any;
  _dtoMedication: any[];
  lstmedicine: any;
  isshowhistroy=false;
  isshowCurhistroy=false;
  duration: any;
  apponitmentType: any;
  clinician: any;
  isUpPast:any;
  ddlStafflist: any[];
  ddlStafflistPast: any[];
  schdStartDate:any;
  schdSEndtDate:any;
  patientAccountId: any;
  PatientTreatmentSummaryDetails: any=[];
  DischargesummaryID:any=0;
  roleId:any;
  clinicianId: any;
  todayDate:any;
  IsIntakeWelcomscreen:boolean;
  userName: string;
  roleAccess: RoelAccess;
  isrejectIntakePopup : boolean = false;
  showMsg : boolean = false;
  updatedDatetime : any;
  schDate:any;
  declaintime:any;
  declainDate:any;
  schDatereschedule:any;
  schTimereschedule:any;
  timeAMPM:any='AM';
  timeFormats = ['AM', 'PM']
  patienname :any;
  isfinancialproposal : boolean
  constructor(public global: Global, public settingsService: SettingsService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,public cdr: ChangeDetectorRef,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public patientservice: PatientService,
    public datepipe: DatePipe,public roleaccessservice:PageAccessService
    ) { }
    
  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Briefcase;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.roleId = localStorage.getItem("roleId");
    this.userName = localStorage.getItem('username');
    this.route.queryParams.subscribe(params => {
       if (Object.keys(params).length != 0) {
        this.patientInfoId = this.commonService.FrontEndDecryption(params.id);
      } else {
        this.patientInfoId = localStorage.getItem("patientId");
           }
      this.immediateAction = localStorage.getItem("currentstatus");
      if(this.immediateAction == this.statusforIntake){
        this.IsIntakeWelcomscreen = true;
      }
      if(params.patientname){
        this.patienname = this.commonService.FrontEndDecryption(params.patientname).replace(/"/g, "")
      }
      //this.getPatientBreifcase();
      this.duration='CW';
      this.apponitmentType="0";
      this.clinician="0";
      this.isUpPast="U";
      this.ddlStafflist=[];
      this.ddlStafflistPast=[];
      this.todayDate = new Date();
      this.getCurrentStatus();
      this.getPatientBreifcaseAppointments(this.isUpPast);
    });
  }
  getPatientBreifcaseAppointments(isUpPast) {
    this.spinnerservice.show();
     this.patientservice.getPatientBreifcaseAppointments(this.patientInfoId, this.duration, this.apponitmentType, this.clinician, this.isUpPast).subscribe(
      res => {
        if (res != null) {
          this.spinnerservice.hide();
           console.log(res.patientBriefcaseAppointments);
          this.PatientUpcomingAppointments = [];
          this.PatientAppointments=[];
         if(res.patientBriefcaseAppointments!=null && res.patientBriefcaseAppointments.length>0){
          this.patientAccountId=res.patientBriefcaseAppointments[0].patientAccountId;
         this.schdStartDate=res.patientBriefcaseAppointments[0].scheduleStartDate;
          this.schdSEndtDate=res.patientBriefcaseAppointments[res.patientBriefcaseAppointments.length-1].finalScheduleEndDate;
           if(this.isUpPast=="U")
           {
           this.PatientUpcomingAppointments = res.patientBriefcaseAppointments.filter(x=>x.isUpcoming == 1);
           if(this.ddlStafflist.length==0 || this.ddlStafflist==undefined) {
             this.ddlStafflist= res.patientBriefcaseAppointments.filter(
            (t2, i, arr) => arr.findIndex(t => t.userId === t2.userId) === i );
           }
           //this.schdStartDate=this.PatientUpcomingAppointments[0].scheduleStartDate;
           //this.schdSEndtDate=this.PatientUpcomingAppointments[0].scheduleEndDate;
           }
          else if(this.isUpPast=="P")
           {
            this.PatientAppointments = res.patientBriefcaseAppointments.filter(x=>x.isPast == 1);
            if(this.ddlStafflistPast.length==0 || this.ddlStafflistPast==undefined) {
            this.ddlStafflistPast=res.patientBriefcaseAppointments.filter(
              (t2, i, arr) => arr.findIndex(t => t.userId === t2.userId) === i );
           }
          }
           }
         this.lstPriorIntake = res.priorIntake;
          this.intakeStatus = res.intakeStatus;
          this.medicationRecords = res.psychotropicMedication;
          this.lstmedicine = res.currentMedication;        
          this.lstMeetingSchedule = res.meetingSchedule;
          if(res.meetingSchedule!=null && res.meetingSchedule.patientAccountId!=null && this.patientAccountId == undefined)
          {
            this.patientAccountId=res.meetingSchedule.patientAccountId;
          }
          if(res.patTreatmentSummaries!=null && res.patTreatmentSummaries?.length!=0){
            this.PatientTreatmentSummaryDetails = res.patTreatmentSummaries;
          }
         }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getPatientBreifcase() {
    this.spinnerservice.show();
    this.patientservice.getPatientBreifcase(this.patientInfoId).subscribe(
      res => {
        if (res != null) {
         // debugger;
          this.spinnerservice.hide();
          this.lstPriorIntake = res.priorIntake;
          this.lstMeetingSchedule = res.meetingSchedule;
          this.lstTourRequest = res.tourRequest;
          this.intakeStatus = res.intakeStatus;
          this.PatientUpcomingAppointments = res.patientAppointments.filter(x=>x.isUpcoming == 1);
          this.PatientAppointments = res.patientAppointments.filter(x=>x.isUpcoming == 0);
          this.medicationRecords = res.psychotropicMedication;
          this.lstmedicine = res.currentMedication;
      //   console.log(this.medicationRecords)
      //   console.log(this.lstmedicine)
          
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onAcceptintake(){
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.lstMeetingSchedule?.meetingTimes[this.rblMeetingDate], false);
    
    this.patientservice.postAcceptMeeting(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.rblMeetingDate = undefined;
        this.isAccceptedIntake=true;
       // this.getPatientBreifcase();
       this.getPatientBreifcaseAppointments(this.isUpPast);
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onDeclineintake(){
    this.spinnerservice.show();
    this.patientservice.onReScheduleMeeting(this.lstMeetingSchedule?.meetingScheduleId).subscribe(
      res => {
        if (res != null) {
          this.spinnerservice.hide();
          this.snackbar.success(res.message);
          //this.getPatientBreifcase();
          this.getPatientBreifcaseAppointments(this.isUpPast);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  
  onPrepareIntake(type){
    if(localStorage.getItem('roleId')!="4" && (this.patientAccountId!=null && this.patientAccountId!="" && this.patientAccountId != undefined) )
      {
       if(type == 1){
           // this.router.navigate(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(this.patientAccountId), pageid: 1 } });
           const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(this.patientAccountId) , cftid: 2,pageid:1,patientinfoid:this.commonService.FrontEndEncryption(this.patientInfoId),patientName :this.commonService.FrontEndEncryption(this.patienname)} }));
           
           window.open(url, '_blank');
         }else if(type==2){
          //this.router.navigate(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(this.patientAccountId), pageid: 1 } });
          const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-checklist'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(this.patientAccountId), cftid: 4,pageid:1,patientinfoid:this.commonService.FrontEndEncryption(this.patientInfoId),patientName :this.commonService.FrontEndEncryption(this.patienname)} }));
          window.open(url, '_blank');
          }else if(type==3){
           // this.router.navigate(['/intake-survey'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(this.patientAccountId), pageid: 1 } });
           const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-survey'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(this.patientAccountId),pageid:1,patientName :this.commonService.FrontEndEncryption(this.patienname)} }));
           window.open(url, '_blank');
          }else if(type==4){
           // this.router.navigate(['/intake-consent'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(this.patientAccountId), pageid: 1 } });
           const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-consent'], { queryParams: { tab: type, patientAccountId: this.commonService.FrontEndEncryption(this.patientAccountId),pageid:1,patientinfoid :this.commonService.FrontEndEncryption(this.patientInfoId),patientName :this.commonService.FrontEndEncryption(this.patienname)} }));
           window.open(url, '_blank');
          }
        }   
   else if(localStorage.getItem('roleId')=="4")
    {
   
      if(type == 1){
          // this.router.navigate(['/intake-checklist'], { queryParams: { tab: type } });
          const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-checklist'], { queryParams: { tab: type, cftid: 2}  }));
          window.open(url, '_blank');
       }else if(type==2){
           //this.router.navigate(['/intake-checklist'], { queryParams: { tab: type } });
           const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-checklist'], { queryParams: { tab: type, cftid: 4} }));
          window.open(url, '_blank');
       }else if(type==3){
         //  this.router.navigate(['/intake-survey'], { queryParams: { tab: type } });
         const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-survey'], { queryParams: { tab: type} }));
          window.open(url, '_blank');
       }else if(type==4){
       // this.router.navigate(['/intake-consent'], { queryParams: { tab: type } });
       const url = this.router.serializeUrl(this.router.createUrlTree(['/intake-consent'], { queryParams: { tab: type} }));
       window.open(url, '_blank');
      }  
   }
   else{
     return false;
   } 
 
  }
  onTabChange(tab:any){
    this.selectedTabIndex = tab;
    if(this.selectedTabIndex==1)
    {
      this.isUpPast="U";
      this.duration='CW';
      this.apponitmentType="0";
      this.clinician="0";
      this.isUpPast="U";
      this.getPatientBreifcaseAppointments(this.isUpPast);
    }
    else if(this.selectedTabIndex==2)
    {
      this.isUpPast="P";
      this.duration='PW';
      this.apponitmentType="0";
      this.clinician="0";
      this.isUpPast="P";
      this.getPatientBreifcaseAppointments(this.isUpPast);
    }
  }
  onClinicalNotes(item)
  {
   const url = this.router.serializeUrl(this.router.createUrlTree(['/progess-track'], { queryParams: { id: this.commonService.FrontEndEncryption(item.therapyId),date: this.datepipe.transform(item.scheduleStartDate, "MM-dd-yyyy"),clinicalNotes:true,dayScheduleSubGroupId:this.commonService.FrontEndEncryption(item.meetingScheduleTimeId),paramClinicianId: this.commonService.FrontEndEncryption(item.userId),paramIsPatientLogin: this.commonService.FrontEndEncryption("true") }}));
    window.open(url, '_blank');
  }
  onFivePRatings(item){
    // const url = this.router.serializeUrl(this.router.createUrlTree(['/progess-track'], { queryParams: { id: this.commonService.FrontEndEncryption(item.therapyId),date: this.commonService.FrontEndEncryption(new Date(item.schduleDate).toLocaleDateString()),dayScheduleSubGroupId:this.commonService.FrontEndEncryption(item.scheduleId)} }));
    const url = this.router.serializeUrl(this.router.createUrlTree(['/progess-track'], { queryParams: { id: this.commonService.FrontEndEncryption(item.therapyId),date: this.datepipe.transform(item.scheduleStartDate, "MM-dd-yyyy"),dayScheduleSubGroupId:this.commonService.FrontEndEncryption(item.meetingScheduleTimeId),paramClinicianId: this.commonService.FrontEndEncryption(item.userId),paramIsPatientLogin: this.commonService.FrontEndEncryption("true")} }));
     window.open(url, '_blank');
   }
   getCurrentStatus()
  {
   // if(localStorage.getItem('roleId')=="4"){
    this.spinnerservice.hide();
    this.commonService.getLeadCurrentStatus(this.patientInfoId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res!=0) {
           localStorage.setItem("currentstatus", res);
           this.immediateAction =res;
          } 
      },
      err => {
        this.spinnerservice.hide();
      },
    );
 // }
}

onPatientprofile() {
  this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(this.patientInfoId),  isAdminClicked :this.commonService.FrontEndDecryption(true) } });
}
OnTreatmentPlan(){
  if(this.DischargesummaryID!=0){
  for (let item of this.PatientTreatmentSummaryDetails){
    if(item.dischargeSummaryId==this.DischargesummaryID){
      this.clinicianId = item.clinicianId
    }
  }
  const url = this.router.serializeUrl(this.router.createUrlTree(['/treatment-summary'], {queryParams:{id:this.commonService.FrontEndEncryption(this.DischargesummaryID),isedit:this.commonService.FrontEndEncryption(2),clinician:this.commonService.FrontEndEncryption(this.clinicianId)}}));
  window.open(url, '_blank');
}
else{
  this.snackbar.error("Please select any Diagnosis code.")
}
}
onmeetingLink(item)
{
const url = item.meetingLink;
window.open(url, '_blank');

}

OnNextToBriefcase(){
  this.IsIntakeWelcomscreen = false;
}
onReturnintake(){
  this.isrejectIntakePopup = true;
  // this.spinnerservice.show();
  //   const dataToEncrypt = this.commonService.DataToEncrypt(this.lstMeetingSchedule?.meetingTimes[this.rblMeetingDate], false);
    
  //   this.patientservice.postAcceptMeeting(dataToEncrypt).subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       this.snackbar.success(JSON.parse(res).message);
  //       this.rblMeetingDate = undefined;
  //       this.isAccceptedIntake=true;
  //      this.getPatientBreifcaseAppointments(this.isUpPast);
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //       this.snackbar.error(err.error);
  //     },
  //   );
}

oncloIntakepopup(){
  this.isrejectIntakePopup = false;
}
submitDatetime(){
  this.declainDate = new Date(this.schDatereschedule);
  this.declaintime = this.schTimereschedule
this.isrejectIntakePopup = false;
this.snackbar.success("Successfully submitted the intake reschedule request");

}
redirectToFinancialReview(): void {
  localStorage.setItem('isFinancereview', 'true');
  // this.isfinancialproposal = true;
  this.router.navigate(['/financial-review']);
  this.cdr.detectChanges(); // Manually trigger change detection
}




}

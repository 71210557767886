import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignaturePad } from 'angular2-signaturepad';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Global } from 'src/app/global/global.model';
import { CommonService } from 'src/app/common/common.service';
import { SnackBarService } from 'src/app/services/snackBar.service'

@Component({
  selector: 'app-esign-dialog',
  templateUrl: './esign-dialog.component.html',
  styleUrls: ['./esign-dialog.component.scss'],

})
export class EsignDialogComponent implements OnInit {
  form: FormGroup;
  TypeName: string = '';
  title: string;
  message: string;
  signatureImg!: string;
  selectedFont: any;
  @ViewChild(SignaturePad) signaturePad: SignaturePad | undefined;
  @ViewChild('canvas', { static: false }) canvasRef: ElementRef;
  @ViewChild('esignupload', { static: true }) esignuploadd;
  @ViewChild('esignupload') eleesignupload: ElementRef;
  signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 500,
    'canvasHeight': 200
  };
  tabname: any;
  esignupload: any;
  fontfamily: any;
  uploadFile: any;
  parentinfoId: any;
  submitForm: any;
  baseData: any;
  constructor(public dialogRef: MatDialogRef<EsignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public global: Global,
    public commonService: CommonService, private snackbar: SnackBarService) { }

  ngOnInit() {
    this.tabname = 'Type';
  }

  submit(form) {
    this.dialogRef.close(`${form.value.filename}`);
  }

  ngAfterViewInit() {
    if (this.signaturePad) {
      this.signaturePad.set('minWidth', 2);
      this.signaturePad.clear();
    }
  }

  drawComplete() {
    if (this.signaturePad) {
      console.log(this.signaturePad.toDataURL());
    }
  }

  drawStart() {
    console.log('begin drawing');
    this.submitForm = true;
  }

  onfontview(data) {
    this.fontfamily = data.target.value;
    const canvas = this.canvasRef.nativeElement as HTMLCanvasElement;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.width);
    context.fillStyle = "black";
    if (this.fontfamily == 'Dancing Script') {
      context.font = '50px "Dancing Script"';
    }
    else if (this.fontfamily == 'Liu Jian Mao Cao') {
      context.font = '50px "Liu Jian Mao Cao"';
    }
    else {
      context.font = '50px "Ma Shan Zheng"';
    }
    context.fillText(this.TypeName, 50, 90, 140);
    const base64Data = canvas.toDataURL();
    this.baseData = base64Data;
    this.submitForm = true;
  }

  clearCanvas(cnv, ctx) { // gets reference to canvas context
    //const cnv = this.canvasRef.nativeElement as HTMLCanvasElement;
    //const ctx = cnv.getContext('2d');
    ctx.beginPath();    // clear existing drawing paths
    ctx.save();         // store the current transformation matrix
    // Use the identity matrix while clearing the canvas
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, cnv.width, cnv.height);
    ctx.restore();        // restore the transform

  }

  tabChanged(event) {
    this.submitForm = false;
    this.tabname = event.tab.textLabel;
    this.clearSignature();
  }

  clearSignature() {
    this.submitForm = false;
    if (this.signaturePad && this.tabname == 'Draw') {
      this.signaturePad.clear();
    }
    else if (this.tabname == 'Type') {
      this.TypeName = '';
      const canvas = this.canvasRef.nativeElement as HTMLCanvasElement;
      const context = canvas.getContext('2d');
      this.clearCanvas(canvas, context);
    }
    else {
      this.eleesignupload.nativeElement.value = "";
    }

  }

  savePad() {
    if (this.signaturePad && this.tabname == 'Draw') {
      const base64Data = this.signaturePad.toDataURL();
      this.signatureImg = base64Data;
      this.global.esign = base64Data;
      this.global.formtype = 'Draw';
    }
    else if (this.tabname == 'Type') {
      this.signatureImg = this.baseData;
      this.global.esign = this.baseData;
      this.global.formtype = 'Type';
    }
    else if (this.tabname == 'Upload') {
      this.signatureImg = this.baseData;
      this.global.esign = this.baseData;
      this.global.formtype = 'Upload';
    }
    let formData = new FormData();
    const encryptEsign = this.commonService.FrontEndEncryption(this.signatureImg);
    formData.append('Esignature', encryptEsign);
    formData.append('EsignType', this.global.formtype);
    formData.append('ParentInfoId', this.data.parentinfoId)
    this.commonService.postEsigndata(formData)
      .subscribe((event: any) => {
      }), (err: any) => {
      };
  }


  omitspecialchar(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    this.baseData = reader.result;
    this.submitForm = true;
  }

}

import { Component, OnInit } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { FinancialService } from 'src/app/patient-lead/financial-proposal/financial.service';
import { constantVariables } from 'src/app/constant/constant';
import { NgForm } from '@angular/forms';
import { cloneDeep } from "lodash";
import * as moment from 'moment';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MenuList, RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
@Component({
  selector: 'app-request-tour',
  templateUrl: './request-tour.component.html',
  styleUrls: ['./request-tour.component.scss']
})

export class RequestTourComponent implements OnInit {
  statusFinancial = constantVariables.status.financial;
  statussubmitted = constantVariables.status.submitted;
  statusreviewTour = constantVariables.status.reviewTour;
  statusCrbaUpdate = constantVariables.status.CrbaUpdate;
  statusforIntake = constantVariables.status.forIntake;
  statustourCompleted=constantVariables.status.tourCompleted;
  statusstaffAssigned=constantVariables.status.staffAssigned;
  isShowAssignStaff: boolean = false;
  isRequiredStaff: boolean = false;
  istimeSlot: boolean = false;
  isTourCompleted: boolean = false;
  financialProposal: any = [];
  patientInfoId: any;
  userName: any;
  location: any;
  timeslots: any;
  LeadStatus: any;
  stafflist: any = [];
  staffSlotlst: any = [];
  assignstaffId: any;
  assignstaffId1: any;
  slotAvailableId: any;
  slotsAvailable: any = [];
  slottiming:any;
  isRescheduleTourEnabled:boolean=false;
  currentdate:any;
  newAssignedDate:any;
  newAssignedStartTime:any;
  newAssignedEndTime:any;
  newAssignItem:any;
  separator = "/";
  showFirstDate;
  roleAccess: RoelAccess;
 pageFrom:number;
  constructor(public financialService: FinancialService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    this.LeadStatus = Number(localStorage.getItem("currentstatus"));
    this.currentdate = moment().subtract(2, 'days').format("YYYY-MM-DD HH:mm:ss");
    this.route.queryParams.subscribe(params => {
      if (params != null) {

        this.patientInfoId = this.commonService.FrontEndDecryption(params.id);
        if(params.page != undefined)
        {
          this.pageFrom = JSON.parse(this.commonService.FrontEndDecryption(params.page));
        }
        this.userName = this.commonService.FrontEndDecryption(params.name).replace(/"/g, "");
        this.getFinancialProposalDetails();
        this.getStaffList();
        const pageid = constantVariables.pagelists.Tour;
        this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
      }
    });

  }
  isScheduled:boolean=false;
  getFinancialProposalDetails() {
    this.spinnerservice.show();
    this.financialService.getFinancialProposalDetails(this.patientInfoId).subscribe(
      res => {
        if (res != null) {
          this.financialProposal = res;
          if(this.financialProposal?.requestTourDetails?.length==1){
            this.showFirstDate=true;
            this.isScheduled=true;
          }

          if (!moment(this.financialProposal?.requestTourDetails?.requestTourDate).isSame(moment(), 'day')) {
            this.isTourCompleted = true;
          }
          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  stafflist1:any;
  getStaffList() {
    this.commonService.getStaffList(0).subscribe(
      (res: any) => {
        if (res) {
          this.stafflist = res;
          this.stafflist1 = res;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  isShowAssignStaff1:boolean=false;
  isShowChangeStaff:boolean=false;
  onselectAssign(id) {
    if(id==1){
    this.isShowAssignStaff = true;
    this.isShowChangeStaff=false;
    }
    if(id==2){
      this.isShowAssignStaff1 = true;
    }
    if(id==3){
      this.isShowChangeStaff=true;
      this.isShowAssignStaff = true;
    }
  }
  onCancelAssign() {
    this.isShowAssignStaff = false;
    this.isRequiredStaff = false;
    this.showAssignStaff1Error=false;
    this.showAssignStaffError=false;
    this.istimeSlot = false;
    this.staffSlotlst=[];
    this.slotsAvailable=[];
  }
  onChangeAssignStaff(event,id) {
    this.isRequiredStaff = false;
    this.slotAvailableId = undefined;
    this.spinnerservice.show();

    var requestTourId;
    if(id==1){
      requestTourId=this.financialProposal?.requestTourDetails[0]?.requestTourId;
      this.showAssignStaffError=false;
    }else if(id==2){
      requestTourId=this.financialProposal?.requestTourDetails[1]?.requestTourId;
      this.showAssignStaff1Error=false;
    }
    this.financialService.getAssignedSlotsUpdated(event.value, requestTourId).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {

          this.staffSlotlst = res.assignedSlots;
          this.slotsAvailable = res.availableSlots;
          this.slottiming = this.slotsAvailable[0].fromTime;
          var availableSlots = this.slotsAvailable.filter(x => x.timeSlotsId == this.financialProposal.requestTourDetails.timeSlotId)
          if (availableSlots && availableSlots.length > 0) {
            this.slotAvailableId = this.financialProposal.requestTourDetails.timeSlotId;
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onChangeAssignStaff1(event,id) {
    this.isRequiredStaff = false;
    this.slotAvailableId = undefined;
    this.spinnerservice.show();
    var requestTourId;
    if(id==1){
      requestTourId=this.financialProposal?.requestTourDetails[0]?.requestTourId;
    }else if(id==2){
      requestTourId=this.financialProposal?.requestTourDetails[1]?.requestTourId;
    }
    this.financialService.getAssignedSlotsUpdated(event.value, requestTourId).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this.staffSlotlst = res.assignedSlots;
          this.slotsAvailable = res.availableSlots;
          this.slottiming = this.slotsAvailable[0].fromTime;
          var availableSlots = this.slotsAvailable.filter(x => x.timeSlotsId == this.financialProposal.requestTourDetails.timeSlotId)
          if (availableSlots && availableSlots.length > 0) {
            this.slotAvailableId = this.financialProposal.requestTourDetails.timeSlotId;
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onChangeAvaSlot(event){
    this.istimeSlot = false;
  }
  showAssignStaffError:boolean=false;
  showAssignStaff1Error:boolean=false;
  postAssignStaff(id) {
    this.isRequiredStaff = false;
    this.istimeSlot = false;
    var dataTobackend;
    if(id==0){
      if(this.assignstaffId==null||this.assignstaffId==undefined){
        this.showAssignStaffError=true;
        return;
      }
    this.financialProposal.requestTourDetails[id].assignStaffId = this.assignstaffId;
    this.financialProposal.requestTourDetails[id].cancelTourId=this.financialProposal.requestTourDetails[1].requestTourId;
    dataTobackend=this.financialProposal.requestTourDetails[id];
  }
    else if(id==1){
      if(this.assignstaffId1==null||this.assignstaffId1==undefined){
        this.showAssignStaff1Error=true;
        return;
      }
      this.financialProposal.requestTourDetails[id].assignStaffId = this.assignstaffId1;
      this.financialProposal.requestTourDetails[id].cancelTourId=this.financialProposal.requestTourDetails[0].requestTourId;
      dataTobackend=this.financialProposal.requestTourDetails[id];
    }
    else if(id==3){
      if(this.assignstaffId==null||this.assignstaffId==undefined){
        this.showAssignStaffError=true;
        return;
      }
    this.financialProposal.requestTourDetails[0].assignStaffId = this.assignstaffId;
    dataTobackend=this.financialProposal.requestTourDetails[0];
    }
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(dataTobackend, false);
    this.financialService.AssignStaffNew(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(JSON.parse(res).message);
        this.LeadStatus = this.statusstaffAssigned;
        localStorage.setItem("currentstatus", this.statusstaffAssigned);
        this.getFinancialProposalDetails();
        this.isShowChangeStaff=false;
        this.onCancelAssign();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  changeView(id){
    if(id==1){
      this.showFirstDate=true;
      this.showAssignStaffError=false;
      this.isShowAssignStaff = false;
    }
    if(id==2){
      this.showFirstDate=false;
      this.showAssignStaff1Error=false;
      this.isShowAssignStaff1 = false;
    }
  }
  timeSlots:any;
  GetAvailableTimeSlots(){
    this.timeSlots=[];
    this.newAssignedDate=null;
    this.newAssignItem=null;
    this.newAssignedEndTime=null;
    this.newAssignedStartTime=null;
    this.isRescheduleTourEnabled=true;
    this.financialService.getAssignedSlots(this.financialProposal?.requestTourDetails[0]?.assignStaffId, this.financialProposal?.requestTourDetails[0]?.requestTourId).subscribe((res:any)=>{
      this.timeSlots=res;
    });
  }
  isReschedule:boolean=false;
  isTimeSelected:boolean=false;
  validateReschedule(){
    if(this.newAssignedDate==null){
      this.isReschedule=true;
      this.isTimeSelected=true;
      return;
    }else{
      this.isReschedule=false;
      if(this.newAssignedStartTime==null||this.newAssignedEndTime==null){
        this.isTimeSelected=true;
        return;
      }else{
        this.isTimeSelected=false;
      }
    }
  }
  calanderChange(){
    if(this.newAssignedDate==null){
      this.isReschedule=true;
      return;
    }else{
      this.isReschedule=false;
    }
  }
  onBack(){
    if(this.pageFrom == 1)
    {
      this.router.navigateByUrl('/dashboard');
    }else{
      this.router.navigateByUrl('/leadlist');
    }
  }
  PostRescheduleTour(data){
    data.fromTime=this.newAssignedStartTime;
    data.toTime=this.newAssignedEndTime;
    data.requestTourDate=this.newAssignedDate;
    if(this.newAssignedDate==null){
      this.isReschedule=true;
      this.isTimeSelected=true;
      return;
    }else{
      if(this.newAssignedStartTime==null||this.newAssignedEndTime==null){
        this.isReschedule=false;
        this.isTimeSelected=true;
        return;
      }else{
        this.isTimeSelected=false;
        this.isReschedule=false;
      }
    }
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(data, false);
    this.financialService.postRescheduleTour(dataToEncrypt).subscribe((res:any)=>{
      this.spinnerservice.hide();
      this.snackbar.success(JSON.parse(res).message);
      this.getFinancialProposalDetails();
      this.isRescheduleTourEnabled=false;
      this.isReschedule=false;
      this.newAssignedDate=null;
    },err=>{
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    });
  }

  selectChange($event:any){
    this.newAssignedStartTime=this.newAssignItem.split("/")[0];
    this.newAssignedEndTime=this.newAssignItem.split("/")[1];
    this.validateReschedule();
  }
  cancelClick(){
    this.isTimeSelected=false;
    this.isReschedule=false;
    this.isRescheduleTourEnabled=false;
    this.newAssignItem=null;
    this.timeSlots=null;
  }
}

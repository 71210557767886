import { TreatmentGoalConfig } from './../../../../_models/_Treatment/_treatmentgoals';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from './../../../../services/snackBar.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { constantVariables } from 'src/app/constant/constant';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SpinnerService } from 'src/app/services/spinnerservice.service';
import { CommonService } from 'src/app/common/common.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { I, X, Y } from '@angular/cdk/keycodes';
import { th } from 'date-fns/locale';
import { NgForm } from '@angular/forms';
import {benchmarkmodel} from '../../../../_models/_Treatment/_benchmarkMeasures';
import { NgxSpinnerService } from 'ngx-spinner';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';

@Component({
  selector: 'app-treatment-goal-config',
  templateUrl: './treatment-goal-config.component.html',
  styleUrls: ['./treatment-goal-config.component.scss']
})
export class TreatmentGoalConfigComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('Benchmarkfrm') Benchmarkfrm: NgForm;
  treatmentGoalList:any;

  patientAccountId:number;
  chmaTreatmentSummaryId:number;
  patientInfoId:number;
 
  currentdate:any;

  events: string[] = [];

  kpiGoalIsEmpty:boolean=false;
  qualitativeGoalIsEmpty:boolean=false;

  kpiGoalNotFilled:boolean=false;

  newArray:any[]=[];
  modality: any=[];
  ShowIndividualTherapy:boolean=false;
  ShowGroupTherapy:boolean=false;
  ShowFamilyTherapy:boolean=false;
  ShowPsyividualTherapy:boolean=false;
  ShowPsyEvaTherapy:boolean=false;
  ShowPsyMedTherapy:boolean=false;
  ShowSpeechTherapy:boolean=false;
  ShowNutritionTherapy:boolean=false;

  //For new goal changes
  IndPercentageGoals:any;
  IndTimeGoals:any;
  IndExchangeGoals:any;
  IndActivityGoals:any;
  IndResponseGoals:any;
  IndOthers:any;
  FamPercentageGoals:any;
  FamTimeGoals:any;
  FamExchangeGoals:any;
  FamActivityGoals:any;
  FamResponseGoals:any;
  FamOthers:any;
  GrpPercentageGoals:any;
  GrpTimeGoals:any;
  GrpExchangeGoals:any;
  GrpActivityGoals:any;
  GrpResponseGoals:any;
  GrpOthers:any;
  EditBenchmark: boolean=false;
  Benchmarkfrminfo: benchmarkmodel;
  status: number;
  selected:any
  Otherbenchmarks: any;
  AllResponse: any;
  PercentageBased = constantVariables.MeasureTypeName.Percentagebased;
  Timebased = constantVariables.MeasureTypeName.Timebased;
  measuretype: any;
  PsyPercentageGoals: any;
  PsyTimeGoals: any;
  PsyExchangeGoals: any;
  PsyActivityGoals: any;
  PsyResponseGoals: any;
  PsyOthers: any;
  PsyEvaPercentageGoals: any;
  PsyEvaTimeGoals: any;
  PsyEvaExchangeGoals: any;
  PsyEvaActivityGoals: any;
  PsyEvaResponseGoals: any;
  PsyEvaOthers: any;
  PsyMedPercentageGoals: any;
  PsyMedTimeGoals: any;
  PsyMedExchangeGoals: any;
  PsyMedActivityGoals: any;
  PsyMedResponseGoals: any;
  PsyMedOthers: any;
  SpeechPercentageGoals: any;
  SpeechTimeGoals: any;
  SpeechExchangeGoals: any;
  SpeechActivityGoals: any;
  SpeechResponseGoals: any;
  SpeechOthers: any;
  NutritionPercentageGoals: any;
  NutritionTimeGoals: any;
  NutritionExchangeGoals: any;
  NutritionActivityGoals: any;
  NutritionResponseGoals: any;
  NutritionOthers: any;
  roleId:number;
  isGoalConfigDisabled:boolean=false;
  ProgressbenchId: any;
  IsShowStatus: boolean=false;
  roleAccess: RoelAccess;
  constructor(private dialog:MatDialog,public spinnerservice:NgxSpinnerService
    ,private treatmentservice:TreatmentService,private snackbar:SnackBarService,
    private route:ActivatedRoute,public commonService: CommonService
    ,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    this.roleId = Number(localStorage.getItem("roleId"));
    this.currentdate=new Date();
    this.Benchmarkfrminfo = new benchmarkmodel();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.account).replace(/"/g, ""));
        this.chmaTreatmentSummaryId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.patientInfoId = Number(this.commonService.FrontEndDecryption(params.patient).replace(/"/g, ""));
      }
      this.treatmentservice.changeInGoalInformation.subscribe((res)=>{
        this.getTreatmentGoalsList()
      });
      this.treatmentservice.getSuggestedGoals.subscribe((res)=>  
     { this.getTreatmentGoalsList();
     // this.TabConfigure()}
     }
      );
  
    });

    if(this.roleId==1){
      this.isGoalConfigDisabled=true;
    }
    const pageid = constantVariables.pagelists.TreatmentPlan;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
  }


  getTreatmentGoalsList(){
    
    this.spinnerservice.show()
    this.treatmentservice.getGoalsWithbenchmark(this.chmaTreatmentSummaryId).subscribe(
          (res: any) => {
            this.spinnerservice.hide();
    
            if (res) {
              this.modality = res.dtoTreatmentModality;
              this.TabConfigure();
              this.AllResponse = res
              for (let key in this.AllResponse) {
                 this.AllResponse[key];
                 if(this.AllResponse[key].length!=0 && key !='dtoTreatmentModality'){
                  this.AllResponse[key].forEach(x=>{
                    for(let measure of x.measures){
                      if(measure.trackBenchIsSelected){
                         x.selectedTrackbenchId=measure.trackBenchmarkId
                         return
                      }
                    }
                  })
                }
            }
              this.IndPercentageGoals = this.AllResponse.indPercentageBased;          
              this.IndTimeGoals = this.AllResponse.indTimeBased;            
              this.IndExchangeGoals = this.AllResponse.indExchangesbased;           
                this.IndActivityGoals = this.AllResponse.indActivitybased               
                this.IndResponseGoals= this.AllResponse.indResponsebased              
                this.IndOthers = this.AllResponse.indOthers               
                this.FamPercentageGoals = this.AllResponse.famPercentageBased               
                this.FamTimeGoals = this.AllResponse.famTimeBased             
                this.FamExchangeGoals =this.AllResponse.famExchangesbased               
                this.FamActivityGoals = this.AllResponse.famActivitybased               
                this.FamResponseGoals = this.AllResponse.famResponsebased              
                this.FamOthers = this.AllResponse.famOthers              
                this.GrpPercentageGoals = this.AllResponse.grpPercentageBased             
                this.GrpTimeGoals = this.AllResponse.grpTimeBased              
                this.GrpExchangeGoals = this.AllResponse.grpExchangesbased              
                this.GrpActivityGoals = this.AllResponse.grpActivitybased               
                this.GrpResponseGoals  = this.AllResponse.grpResponsebased              
                this.GrpOthers = this.AllResponse.grpOthers
                this.PsyPercentageGoals = this.AllResponse.psyPercentageBased             
                this.PsyTimeGoals = this.AllResponse.psyTimeBased              
                this.PsyExchangeGoals = this.AllResponse.psyExchangesbased              
                this.PsyActivityGoals = this.AllResponse.psyActivitybased               
                this.PsyResponseGoals  = this.AllResponse.psyResponsebased              
                this.PsyOthers = this.AllResponse.psyOthers
                this.PsyEvaPercentageGoals = this.AllResponse.psyEvaPercentageBased             
                this.PsyEvaTimeGoals = this.AllResponse.psyEvaTimeBased              
                this.PsyEvaExchangeGoals = this.AllResponse.psyEvaExchangesbased              
                this.PsyEvaActivityGoals = this.AllResponse.psyEvaActivitybased               
                this.PsyEvaResponseGoals  = this.AllResponse.psyEvaResponsebased              
                this.PsyEvaOthers = this.AllResponse.psyEvaOthers
                this.PsyMedPercentageGoals = this.AllResponse.psyMedPercentageBased             
                this.PsyMedTimeGoals = this.AllResponse.psyMedTimeBased              
                this.PsyMedExchangeGoals = this.AllResponse.psyMedExchangesbased              
                this.PsyMedActivityGoals = this.AllResponse.psyMedActivitybased               
                this.PsyMedResponseGoals  = this.AllResponse.psyMedResponsebased              
                this.PsyMedOthers = this.AllResponse.psyMedOthers
                
                //////////
                this.SpeechPercentageGoals = this.AllResponse.speechPercentageBased             
                this.SpeechTimeGoals = this.AllResponse.speechTimeBased              
                this.SpeechExchangeGoals = this.AllResponse.speechExchangesbased              
                this.SpeechActivityGoals = this.AllResponse.speechActivitybased               
                this.SpeechResponseGoals  = this.AllResponse.speechResponsebased              
                this.SpeechOthers = this.AllResponse.speechOthers
                /////////////
                this.NutritionPercentageGoals = this.AllResponse.nutriPercentageBased             
                this.NutritionTimeGoals = this.AllResponse.nutriTimeBased              
                this.NutritionExchangeGoals = this.AllResponse.nutriExchangesbased              
                this.NutritionActivityGoals = this.AllResponse.nutriActivitybased               
                this.NutritionResponseGoals  = this.AllResponse.nutriResponsebased              
                this.NutritionOthers = this.AllResponse.nutriOthers
                
              // this.PercentageGoals = this.PercentageGoals.forEach(x=>  x.measures.filter(y=>y.therapyId==constantVariables.therapy.IndividualTheraphy))
              // console.log( this.PercentageGoals)
            }
          },
          err => {
            this.spinnerservice.hide();
          },
        );
  }

  ondeletegoals(item) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.Warning.removeConfirmMessage
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {

        this.spinnerservice.show();
        this.treatmentservice.removeTreatmentGaols(item.goalId).subscribe(
          (res: any) => {
            this.spinnerservice.hide();
            this.treatmentservice.getGoals.next(false);
            this.getTreatmentGoalsList();
            this.snackbar.success(constantVariables.SuccessMessage.goaldelete);
          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);
          },
        );
      }
    });
  }

TabConfigure(){
  let IndModality= this.modality.filter(x=>(x.therapyId==constantVariables.therapy.IndividualTheraphy && x.treatmentModalityId!=0))
 IndModality.length!=0?  this.ShowIndividualTherapy = true:this.ShowIndividualTherapy = false; 
 let FamModality= this.modality.filter(x=>(x.therapyId==constantVariables.therapy.FamilyTheraphy && x.treatmentModalityId!=0))
 FamModality.length!=0?  this.ShowFamilyTherapy = true:this.ShowFamilyTherapy=false;
 let GrpModality= this.modality.filter(x=>(x.therapyId==constantVariables.therapy.GroupTheraphy && x.treatmentModalityId!=0))
 GrpModality.length!=0?  this.ShowGroupTherapy = true:this.ShowGroupTherapy=false;
 let PsyModality= this.modality.filter(x=>(x.therapyId==4 && x.treatmentModalityId!=0))
 PsyModality.length!=0?  this.ShowPsyividualTherapy = true:this.ShowPsyividualTherapy=false;
 let PsyEvaModality= this.modality.filter(x=>(x.therapyId==7 && x.treatmentModalityId!=0))
 PsyEvaModality.length!=0?  this.ShowPsyEvaTherapy = true:this.ShowPsyEvaTherapy=false;
 let PsyMedModality= this.modality.filter(x=>(x.therapyId==8 && x.treatmentModalityId!=0))
 PsyMedModality.length!=0?  this.ShowPsyMedTherapy = true:this.ShowPsyMedTherapy=false;
 let SpeechModality= this.modality.filter(x=>(x.therapyId==11 && x.treatmentModalityId!=0))
 SpeechModality.length!=0?  this.ShowSpeechTherapy = true:this.ShowSpeechTherapy=false;
 let NutritionModality= this.modality.filter(x=>(x.therapyId==12 && x.treatmentModalityId!=0))
 NutritionModality.length!=0?  this.ShowNutritionTherapy = true:this.ShowNutritionTherapy=false;
}
 OntabChange(item:any){}

OnEditBenchmark(bench,Goal){
  this.EditBenchmark = true
  this.measuretype= Goal.measureTypeId
  this.Benchmarkfrminfo.Benchmarktitle = bench.benchmarktitle
  this.Benchmarkfrminfo.Benchmarkdiscription = bench.benchmarkdiscription
  this.Benchmarkfrminfo.Benchmarkunit = bench.benchmarkunit
  this.Benchmarkfrminfo.GoalBenchmarkId = bench.goalBenchmarkId
  this.Benchmarkfrminfo.GoalMeasureTypeId=bench.goalMeasureTypeId
  this.Benchmarkfrminfo.TherapyId=bench.therapyId
  this.Benchmarkfrminfo.TrackBenchmarkId=bench.trackBenchmarkId
  this.Benchmarkfrminfo.ChmatreatmentSummaryId=bench.chmatreatmentSummaryId
  this.Benchmarkfrminfo.TrackBenchIsSelected=bench.trackBenchIsSelected
  this.Benchmarkfrminfo.GoalId = Goal.goalId;
  this.Benchmarkfrminfo.Status = bench.status;
  this.Benchmarkfrminfo.ChmatreatmentSummaryId = Goal.chmatreatmentSummaryId;
  if(this.Benchmarkfrminfo.Status ==2){
    this.IsShowStatus = true;
    this.status = 2
    this.Benchmarkfrminfo.Status = 'true'
  }
  else if(this.Benchmarkfrminfo.Status ==3){
    this.status = 3
    this.Benchmarkfrminfo.Status = 'false'
  }
  else{
    //this.Benchmarkfrminfo.Status = null
    this.status = 1
  }

    this.Otherbenchmarks=[]
    if(this.Benchmarkfrminfo.Status !=1){
  Goal.measures.map(Y=>{
    if(Y.trackBenchmarkId!=bench.trackBenchmarkId){
      this.Otherbenchmarks.push(Y.trackBenchmarkId)
    }  });
  }
}
onClosemodal(){
  this.EditBenchmark = false
  this.Benchmarkfrminfo.Status = this.status
  this.IsShowStatus = false;
  //this.getTreatmentGoalsList()
}
Onstatuschange(item:any){
  if(item ==1){
  this.status = 2
  }
  else{
    this.status = 3
  }
}
OnpostBenchmark(){
  if(!this.Benchmarkfrm.valid){
    return
  }
  else{
  this.Benchmarkfrminfo.Status = this.status
  this.Benchmarkfrminfo.IsPresentingProb = false;
  if(this.Otherbenchmarks!=null){
  this.Benchmarkfrminfo.OtherBenchamarkId = this.Otherbenchmarks;
  }
  if(this.status==2){
    this.Benchmarkfrminfo.TrackBenchIsSelected = true;
  }
  else if(this.status == 1){
    this.Benchmarkfrminfo.TrackBenchIsSelected = false;

  }
 // this.Benchmarkfrminfo.Benchmarkunit = Number(this.Benchmarkfrminfo.Benchmarkunit)
const dataToEncrypt = this.commonService.DataToEncrypt(this.Benchmarkfrminfo, false);
this.spinnerservice.show();
//console.log(this._dtoPatientInfo);
this.treatmentservice.PostTreatmentBenchmark(this.Benchmarkfrminfo).subscribe(
  (res: any) => {
    this.spinnerservice.hide();
    this.snackbar.success(JSON.parse(res).message);
    this.treatmentservice.TreatmentPlanStatus.next(false)
    this.EditBenchmark = false;
    this.IsShowStatus = false;
    this.getTreatmentGoalsList()
  },
  err => {
    this.spinnerservice.hide();
    this.snackbar.error(err.error);
  },
);
  }
}

OnselectTrackbenchmark(bench,goalDtl){
  this.Otherbenchmarks=[];
  goalDtl.measures.map(Y=>{
    if(Y.trackBenchmarkId!=bench.trackBenchmarkId){
      this.Otherbenchmarks.push(Y.trackBenchmarkId)
    }
                Y.trackBenchIsSelected=false; 
              });
  bench.trackBenchIsSelected = true
}

OnAddBenchMark(item){
  this.Benchmarkfrm.resetForm();
  this.EditBenchmark = true
  this.measuretype= item.measureTypeId
  this.Benchmarkfrminfo= new benchmarkmodel();
  this.Benchmarkfrminfo.GoalMeasureTypeId=item.measureTypeId;
  this.Benchmarkfrminfo.TherapyId=item.therapyId;
  this.Benchmarkfrminfo.TrackBenchmarkId=0;
  this.Benchmarkfrminfo.ChmatreatmentSummaryId=item.chmatreatmentSummaryId
  this.Benchmarkfrminfo.TrackBenchIsSelected=0;
  this.Benchmarkfrminfo.GoalId = item.goalId;
  this.Benchmarkfrminfo.Status = 1;
  this.Benchmarkfrminfo.TreatmentGoalsId = item.treatmentGoalsId;
  //this.Benchmarkfrminfo.
  this.status = 1;
  this.Otherbenchmarks=[]
}
OnDeleteBenchmark(Id){
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.Warning.removeConfirmMessage
    }
  });

  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
  this.spinnerservice.show();
  this.treatmentservice.removetreatmentbenchmark(Id).subscribe((res:any) => {
    this.spinnerservice.hide();
    this.getTreatmentGoalsList();
    this.snackbar.success(constantVariables.SuccessMessage.RemoveTreatmentGoal);
  },
    err => {
      this.spinnerservice.hide();
    }
  );
}})
}

generatePdf()
{
  this.spinnerservice.show();
  this.commonService.getConfigureProgressTrackerPdf(this.chmaTreatmentSummaryId).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if (res != null) {
        const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
        this.downloadLink.nativeElement.href = res.base64File;
        this.downloadLink.nativeElement.download = res.fileName;
        this.downloadLink.nativeElement.click();
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}


}




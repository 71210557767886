import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserserviceService } from '../userservice.service';
import { CommonService } from 'src/app/common/common.service';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { constantVariables } from 'src/app/constant/constant';
declare function EncryptFieldData(data): any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  rolelist: any;
  authId: any;
  submitted = false;
  constructor(public global: Global, private formBuilder: FormBuilder, public snackbar: SnackBarService,
    private router: Router, public userService: UserserviceService, public commonService: CommonService) {
  }

  ngOnInit(): void {

    // this.router.routerState.root.queryParams.subscribe(params => {
    //   if (params.id) {
    //     this.authId = this.commonService.FrontEndDecryption(params.id);
    //     this.onEditUserProfile();
    //   }
    // });
    this.getRoles();
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required, this.global.cannotContainSpace],
      lastName: ['', Validators.required, this.global.cannotContainSpace],
      username: ['', Validators.required, this.global.cannotContainSpace],
      //password: ['', [Validators.required, Validators.minLength(6)]],
      email: ['', Validators.required, this.global.cannotContainSpace, Validators.pattern(
        '[a-zA-Z0-9._-]{1,}@[a-zA-Z._-]{2,}[.]{1}[a-zA-Z]{2,}'), Validators.email],
      contactNumber: ['', Validators.required, this.global.cannotContainSpace],
      // empId: ['', Validators.required],
      roleId: ['', Validators.required]

    });

  }
  get f() { return this.registerForm.controls; }
  getRoles() {
    this.userService.getRoles().subscribe(
      response => {
        this.rolelist = response;
      },
      error => { }

    );
  }
  onSubmitRegister() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    const dataToEncrypt = {
      objRegister: EncryptFieldData((JSON.stringify(this.registerForm.value))),
    };

    this.userService.register(dataToEncrypt)
      .subscribe(
        data => {
          this.snackbar.success(constantVariables.SuccessMessage.DataSaveSuccessfully);
          this.router.navigate(['/login']);
        },
        error => {
        });
  }

}

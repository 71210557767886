export class Insurance{
    InsuranceDetailsId:number;
    MemberName: string;
    Ssid: string;
    MemberEmployer: string;
    EmployerAddress: string;
    EmployerPhoneNo: string;
    CarrierName: string;
    PolicyNo: string;
    GroupNo: string;
    IsPrimaryInsurance :boolean
    PatientInfoId:number;
    IsScaRequired:boolean;
    InsuranceTypeId:number;
    InsuranceRelationShipId:number;
    MemberId:number;
    FirstName: string;
    LastName: string;
    IsResubmit:boolean;
    InsuranceIsActive:boolean;
    StartDate:Date;
    EndDate:Date;
    GroupName:string;
    CoPayRequired:boolean;
    PatientPercent:boolean;
    AuthorizationRequired:boolean;
}
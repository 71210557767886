import { Component } from '@angular/core';
import { AuthenticationService } from './admin/authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private authenticationService: AuthenticationService) { }
  title = 'SFCCEMR';
  
  onLogout() {
    this.authenticationService.onLogout();
  }
}

import { Component, OnInit,ViewChild } from '@angular/core';
import { constantVariables } from 'src/app/constant/constant';
import { LeadService } from 'src/app/patient-lead/lead.service'
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { NotesDialogComponent } from 'src/app/common/notes-dialog/notes-dialog.component';
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  patientInfoId: any;
  patientnotes: any;
  Decrptedlst:any;
  constructor(public leadservice: LeadService, public global: Global,
    private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.patientInfoId = localStorage.getItem('patientId');
    this.getNotes()
  }
  getNotes() {
    this.leadservice.getNotes(this.patientInfoId).subscribe(
      (res: any) => {
        if (res) {
          this.Decrptedlst = res;
          this.patientnotes = this.Decrptedlst.slice(0, 5);
          this.dataSource = new MatTableDataSource(this.Decrptedlst);
          this.dataSource.paginator = this.paginator;
          console.log(this.patientnotes)
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onPageChange($event) {

    this.patientnotes = this.Decrptedlst.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }

  openDialog(data) {
    const dialogRef = this.dialog.open(NotesDialogComponent,
      { data: { parentinfoId: this.patientInfoId, notesId : data.notesId} });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}


import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { BillingService } from 'src/app/billing/billing.service';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { DatePipe } from '@angular/common'; 
import * as moment from 'moment';
import {DtoBridgePayTransactionSaleRequest} from 'src/app/_models/_Billing/payment';
//import {DtoBridgePayTransactionSaleRequestMessage} from 'src/app/_models/_Billing/payment';

declare function decrypt(data): any;
@Component({
  selector: 'app-bill-lists',
  templateUrl: './bill-lists.component.html',
  styleUrls: ['./bill-lists.component.scss']
})
export class BillListsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  public searchbillListtxt : string;
  constructor(public billingservice: BillingService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, private route: ActivatedRoute,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService,public datepipe: DatePipe,
    ) { }
    
    deCrptedBillLists:any;
    theraphy:any;
    selectedTheraphy:any;
    selectedClinician:any;
    ddlStafflist: any[];
    tmpBillLists:any[];

    dtoBridgePayTransactionSaleRequest : DtoBridgePayTransactionSaleRequest;
    isTranssale:boolean=false;
    paymentresponse:any;
    TherapyId:any;
  ngOnInit(): void {
     this.getbillListView();
    //this.getbillListViewOld();
     this.getTherapy();
    this.selectedTheraphy=0;
    this.selectedClinician=0;
    this.dtoBridgePayTransactionSaleRequest=new DtoBridgePayTransactionSaleRequest();
    this.paymentresponse="";
  }

  getTherapy(){
    
     this.billingservice.getTherapyMstr().subscribe(
      (res: any) => {
            
       if(res.length>0){this.theraphy=res;}
      },
      err => {
   console.log("error");
      },
    );
  }
  getBListFilterby()
  {
if( this.selectedTheraphy!=0 && this.selectedClinician==0)
{
  this.deCrptedBillLists=this.tmpBillLists.filter(x=>x.TherapyId==this.selectedTheraphy);
}
else if( this.selectedTheraphy==0 && this.selectedClinician!=0)
{
  this.deCrptedBillLists=this.tmpBillLists.filter(x=>x.ClinicianId==this.selectedClinician);
}
else if(this.selectedTheraphy!=0 && this.selectedClinician!=0)
{
  this.deCrptedBillLists=this.tmpBillLists.filter(x=>x.ClinicianId==this.selectedClinician && x.TherapyId==this.selectedTheraphy);
}
else
{
  this.deCrptedBillLists=this.tmpBillLists;
}
 }
 getbillListView(){
  this.spinnerservice.show();
    this.ddlStafflist=[];
  this.billingservice.getBillLists('BillLists').subscribe(
    (res: any)  => {
      this.spinnerservice.hide();
     if(res.objInputString!=null)
      {
       // debugger;
        this.deCrptedBillLists = this.commonService.decrypdata(res.objInputString);
        this.deCrptedBillLists = this.deCrptedBillLists.filter(x=>x.BillStatus==null)
        this.tmpBillLists = this.deCrptedBillLists;
        let tmplist=this.deCrptedBillLists;
      //  console.log(this.deCrptedBillLists);
        if(Number(localStorage.getItem('sfcclocation'))>0){
       this.tmpBillLists=this.tmpBillLists.filter(x=>x.SfccMasterId==localStorage.getItem('sfcclocation'));
       this.deCrptedBillLists=this.tmpBillLists;
        }
       // this.dataSource = new MatTableDataSource(this.deCrptedBillLists);
       // this.dataSource.paginator = this.paginator;
        if(this.ddlStafflist.length==0 || this.ddlStafflist==undefined) {
          this.ddlStafflist= tmplist.filter(
         (t2, i, arr) => arr.findIndex(t => t.ClinicianId === t2.ClinicianId && t.ClinicianId !=0) === i );
        }
      }
     
       },
    err => {
      this.spinnerservice.hide();
    },
  );
}
onScheduleLink(item)
{
  //console.log(item);
  this.router.navigate(['/bill'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientAccountId),dsId:this.commonService.FrontEndEncryption(item.DayScheduleAssignId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId)
    ,therapyDate:this.commonService.FrontEndEncryption(item.TherapyDate)
    ,therapyId:this.commonService.FrontEndEncryption(item.TherapyId)
    ,schedName:this.commonService.FrontEndEncryption(item.ApointmenteName)
    ,isIop:this.commonService.FrontEndEncryption(item.IsIop)
    ,isPcp:this.commonService.FrontEndEncryption(item.IsPcp)
    ,cptCode:this.commonService.FrontEndEncryption(item.CptCode)
    ,revenueCode:this.commonService.FrontEndEncryption(item.RevenueCode)
    ,transactionCode:this.commonService.FrontEndEncryption(item.TransactionCode)
    ,msId:this.commonService.FrontEndEncryption(item.MeetingScheduleId)
    ,msTId:this.commonService.FrontEndEncryption(item.MeetingScheduleTimeId)
   
  } });
}

postpaymentTest()
{
  this.spinnerservice.show();
  this.dtoBridgePayTransactionSaleRequest.PaymentAccountNumber=5454545454545454;
  this.dtoBridgePayTransactionSaleRequest.ExpirationDate=this.padLeadingZeros(1025, 4);
  this.dtoBridgePayTransactionSaleRequest.Amount=600;
  this.dtoBridgePayTransactionSaleRequest.HolderType="P";
  this.dtoBridgePayTransactionSaleRequest.AccountZip =33774;
  this.dtoBridgePayTransactionSaleRequest.SecurityCode=123;

  this.dtoBridgePayTransactionSaleRequest.PatientAccountId=240;
  this.dtoBridgePayTransactionSaleRequest.InvoiceNum=null;
  this.dtoBridgePayTransactionSaleRequest.AppointmentId=null;
  this.dtoBridgePayTransactionSaleRequest.TherapyId=0;
  
  const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoBridgePayTransactionSaleRequest, false);
    this.billingservice.postpayment(dataToEncrypt).subscribe(
    (res: any)  => {
      this.spinnerservice.hide();
      debugger;
     console.log(res);
     this.spinnerservice.hide();
       },
    err => {
      this.spinnerservice.hide();
    },
  );

}
getpaymentTest()
{
  this.spinnerservice.show();
  this.billingservice.getpayment().subscribe(
       (res: any)  => {
      this.spinnerservice.hide();
      debugger;
     console.log(res);
     this.spinnerservice.hide();
       },
    err => {
      this.spinnerservice.hide();
    },
  );
}
 padLeadingZeros(num, size) {
  var s = num+"";
  while (s.length < size) s = "0" + s;
  return s;
}
onSelectTransale(item)
{debugger;
  console.log(item);
  this.dtoBridgePayTransactionSaleRequest.PatientAccountId=item.PatientAccountId;
  this.dtoBridgePayTransactionSaleRequest.AppointmentId=(item.DayScheduleAssignId==0)?item.MeetingScheduleTimeId:item.DayScheduleAssignId;
   this.dtoBridgePayTransactionSaleRequest.TherapyId=item.TherapyId;
  this.dtoBridgePayTransactionSaleRequest.PatientInfoId=item.PatientInfoId;
  this.dtoBridgePayTransactionSaleRequest.MeetingScheduleTimeId=item.MeetingScheduleTimeId;
  this.dtoBridgePayTransactionSaleRequest.DayScheduleAssignId=item.DayScheduleAssignId;
  this.dtoBridgePayTransactionSaleRequest.TransactionCode=item.TransactionCode;
  this.dtoBridgePayTransactionSaleRequest.BillValue=null;
  this.dtoBridgePayTransactionSaleRequest.Amount=(item.TherapyId==100)?item.InTakeFee:item.SessionFee;
this.isTranssale=true;
}
OnCancelsale()
{
  this.dtoBridgePayTransactionSaleRequest=new DtoBridgePayTransactionSaleRequest();
  this.isTranssale=false;
}

PostTransactionSale(form)
{
  this.paymentresponse="";
  this.spinnerservice.show();
      if (!form.valid) {
        this.spinnerservice.hide();
        return;
      }
     // this.dtoBridgePayTransactionSaleRequest.PaymentAccountNumber=5454545454545454;
  this.dtoBridgePayTransactionSaleRequest.ExpirationDate=this.padLeadingZeros(this.dtoBridgePayTransactionSaleRequest.ExpirationDate, 4);
  //this.dtoBridgePayTransactionSaleRequest.Amount=600;
  this.dtoBridgePayTransactionSaleRequest.HolderType="P";
  this.dtoBridgePayTransactionSaleRequest.AccountZip =33774;
  //this.dtoBridgePayTransactionSaleRequest.SecurityCode=123;
  this.dtoBridgePayTransactionSaleRequest.InvoiceNum=null;
  this.paymentresponse="";
  const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoBridgePayTransactionSaleRequest, false);
    this.billingservice.postpayment(dataToEncrypt).subscribe(
    (res: any)  => {
      this.spinnerservice.hide();
    
      let js=JSON.parse(res);
      console.log(res);
      this.paymentresponse=js.responseDescription;
      this.dtoBridgePayTransactionSaleRequest=new DtoBridgePayTransactionSaleRequest();
  this.isTranssale=false;
      
     this.spinnerservice.hide();
       },
    err => {
      this.spinnerservice.hide();
    },
  );
}
}

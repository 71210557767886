<div class="content-main-section ">
 <div class="col-md-12 d-flex day-changer-container ">
  <button type="button" #btndayview  class="btn btn-secondary active" (click)="setView(CalendarView.Day)"
  [class.active]="view === CalendarView.Day" style="display:none;">Day</button>
  <div class="mt-0 pt-3 day-changer">
      <div class="align-middle d-flex slider-arr ">
      <div class="d-flex mr-2">
       <span class="current-date">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</span>
      </div>
      <a class="mr-2 cursor-pointer">
        <span mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"><i class="fas fa-chevron-left"></i></span>
      </a>
      <a class="mr-2 cursor-pointer">
        <span mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
          <i class="fas fa-chevron-right"></i></span>
      </a>
    </div>
 </div>
 <div class="d-flex choose-date date-hide ml-0">
  <mat-form-field class="w-100 mt-3">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" (dateChange)="addEvent('change', $event)" [(ngModel)]="PickedDate"  name="PickedDate" >
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
</div>
  <div  class="schedular">
<mwl-day-view-scheduler [viewDate]="viewDate" [events]="events" [users]="users" [refresh]="refresh" (eventTimesChanged)="eventTimesChanged($event)"
  [dayStartHour]="07" (eventClicked)="handleEvent('Clicked', $event.event)" [dayEndHour]="23" (hourSegmentClicked)="hourSegmentClicked($event)"  >
</mwl-day-view-scheduler>
</div>

<div *ngIf="isShowTherapydetails" #TherapyDetailsPopup>
  <app-therapy-details-pop-up [ObjTherapyPopUp]="ObjTherapyPopUp"></app-therapy-details-pop-up> 
 </div>
 <div *ngIf="isShowScheduleModality" #scheuleModality>
  <app-schedule-modality-pop-up [ObjTherapyPopUp]="ObjTherapyPopUp"></app-schedule-modality-pop-up> 
 </div>

<div class="lead-detail-view " [ngClass]="{'detail-view-full':istogglemenu,'detail-view-half':!istogglemenu}">
  <div class="details-head d-flex">
    <h4 class="top-label">Client Dashboard</h4>
    <a (click)="onBacklead()" class="top-label ml-auto cursor-pointer"><i class="fas fa-angle-left pr-2"></i>back</a>
  </div>
  <div class="details-center col-md-12 pl-0 mt-1">
    <div class="row">
      <div class="col-md-3 left-detail">
        <div class="card details-card ">
          <div class="card-body  hot">
            <div class="circle">
              <i class="fas fa-circle" aria-hidden="true"> </i>
            </div>
            <div class="d-flex flex-column align-items-center text-center">
              <div class="user-info w-100">
                <div class="user-avatar1"><img class="rounded-circle"
                    src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="User"></div>
                <div class="user-data">
                  <!-- <h4 class="username">{{patientdetail?.FirstName}} {{patientdetail?.LastName}}</h4> -->
                  <h4 class="username">{{patientdetail?.FirstName}} {{patientdetail?.LastName}}</h4>
                  <div class="row">
                    <mat-form-field appearance="legacy" class="clidashboard"> 
                      <mat-label>Preferred Name</mat-label> 
                      <input matInput placeholder="Preferred Name" [(ngModel)]="patientdetail.PreferredName"> 
                  </mat-form-field>
                  <button  type="submit" (click)="saveName()" title="Save" class="btn form-control-pf-save btnfrname" aria-label="Save"><i class="fas fa-check"></i></button>
                  <button  type="button" (click)="cancelName()" title="Cancel" class="btn btn-default form-control-pf-cancel btnfrname" aria-label="Cancel"><i class="fas fa-times"></i></button>
                  </div>
                 <div class="drpdown">
                  <!-- <mat-form-field >
                    <mat-label>Preferred Pronouns</mat-label>
                    <mat-select [(ngModel)]="patientdetail.PreferredPronouns">
                      <mat-option value="She">She</mat-option>
                      <mat-option value="Her">Her</mat-option>
                      <mat-option value="Hers">Hers</mat-option>
                      <mat-option value="He">He</mat-option>
                      <mat-option value="Him">Him</mat-option>
                      <mat-option value="His">His</mat-option>
                      <mat-option value="They">They</mat-option>
                      <mat-option value="Them">Them</mat-option>
                      <mat-option value="Their">Their</mat-option>
                    </mat-select>
                  </mat-form-field> -->
                  <mat-form-field>
                    <mat-label>Preferred Pronouns</mat-label>
                    <mat-select [(ngModel)]="patientdetail.PreferredPronouns" multiple>
                      <mat-option *ngFor="let pronoun of pronouns" [value]="pronoun">{{pronoun}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <button  type="submit" (click)="savePronouns()" title="Save" class="btn form-control-pf-save" aria-label="Save"><i class="fas fa-check"></i></button>
                  <button  type="button" (click)="cancelPronouns()" title="Cancel" class="btn btn-default form-control-pf-cancel" aria-label="Cancel"><i class="fas fa-times"></i></button>
                 </div>
                
              
                  <p class="sub-text-grey"><span class="gender-img"><i class="fas fa-mars pr-1"></i></span>
                    
                    {{patientdetail?.GenderType}}
                    |
                    <!-- DOB:{{patientdetail?.Dob| date: 'MM/dd/yyyy'}}</p> -->
                    <!-- Age:{{patientdetail?.Age}} </p> -->
                    Age:{{patientdetail?.Age}} </p>
                  <p class="sub-text-grey">{{patientdetail?.LocationName}} </p>
                </div>
              </div>
              <div class="sub-details-profile col-md-12 text-left mb-3 mt-3 ">
                <div class="row">
                  <div class="left-side col-md-6">
                    <!-- <div class="username-thin">{{patientdetail?.SourceTypeName}}</div> -->
                    <div class="username-thin">{{patientdetail?.SourceTypeName}}</div>
                    <!-- <div class="sub-text-grey-small">Referred</div> -->
                    <!-- <div class="username-thin">Paid Facebook Ad</div>  -->
                    <!-- <div class="sub-text-grey-small">Source</div>  -->
                    <div class="sub-text-grey-small">Source</div> 
                  </div>
                  <div class="right-side col-md-6 pr-0">
                    <div class="username-thin">{{patientdetail?.LeadStageId == 2 ? 'Benefits' :'Point of Contact'}}
                    </div>
                    <div class="sub-text-grey-small">stage</div>
                  </div>
                </div>
              </div>
              <div class="sub-details-profile col-md-12 text-left mb-3 mt-3 ">
                <div class="row">
                  <div class="left-side col-md-6">
                    <div class="username-thin">{{patientdetail?.LeadTypeName}}</div>
                    <div class="sub-text-grey-small">lead type</div>
                  </div>
                  <div class="right-side col-md-6 pr-0">
                    <div class="username-thin">{{patientdetail?.CreatedOn| date: 'MM/dd/yyyy'}}</div>
                    <div class="sub-text-grey-small">created date</div>
                  </div>
                </div>
              </div>
              <div class="sub-details-profile col-md-12 text-left mb-3 mt-3 ">
                <div class="row">
                  <div class="left-side col-md-2">
                    <span class="gender-img"><i class="fas fa-phone-alt"></i></span>
                  </div>
                  <div class="right-side col-md-10">
                    <div class="username-thin">{{patientPrimary&&patientPrimary[0]?.ContactNo | mask: '(000) 000-0000'}}
                    </div>
                    <div class="sub-text-grey-small">phone</div>
                  </div>
                </div>
              </div>
              <div class="sub-details-profile col-md-12 text-left mb-3 mt-3 ">
                <div class="row">
                  <div class="left-side col-md-2">
                    <span class="gender-img"><i class="fas fa-at"></i></span>
                  </div>
                  <div class="right-side col-md-10">
                    <div class="username-thin-lower text-truncate">{{patientPrimary&&patientPrimary[0]?.Email}}</div>
                    <div class="sub-text-grey-small">Email</div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-12 text-center">
                <button class="btn edit-btn"><img class="pr-3" src="../../../assets/images/editblue.png"   (click)="onEditPatientinfo('0')" />Edit</button>
              </div> -->

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9 full-width-section">
        <div class="data-hide " (click)="toggleinfo()"><i class="fas fa-bars cursor-pointer" ></i></div>
        <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event)">
          <mat-tab label="contact info">
            <div class="d-flex" ><div class="ml-auto">
                <button  mat-stroked-button class="btn primary-btn-outlined-small float-right mb-2"  *ngIf="(roleAccess.isCreate)" (click)="onAddContact()">
                  <i  class="fas fa-plus-circle mr-2"></i>Add Contact</button>
                </div></div> 
            <div class="col-md-12 table-custom p-0 ">
              <div class="example-container mat-elevation-z8 ">
                <div class="table-responsive">
                  <mat-table #table [dataSource]="dataSource" matSort>

                    <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->

                    <!-- Position Column -->
                    <ng-container matColumnDef="position">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                      <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="firstName">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> FirstName </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.FirstName}} </mat-cell>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="lastName">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> LastName </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.LastName}} </mat-cell>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="email">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.Email}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="contactNo">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.ContactNo | mask: '(000) 000-0000'}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="parentalRelation">
                      <mat-header-cell *matHeaderCellDef mat-sort-header>Relation </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.ParentalRelation}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="isPrimaryContact">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Primary Contact </mat-header-cell>
                      <mat-cell *matCellDef="let element"> {{element.IsPrimaryContact == 1? 'Yes': 'No'}} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                      <mat-header-cell *matHeaderCellDef>{{roleAccess.isEdit||roleAccess.isDelete?'Actions':''}} </mat-header-cell>
                      <mat-cell *matCellDef="let element;let i = index;">
                        <!-- <button mat-icon-button matTooltip="Edit" (click)="editParentForm(element,i)" color="primary"> -->
                        <button mat-icon-button matTooltip="Edit" *ngIf="roleAccess.isEdit" (click)="onEditPatientinfo(element.ParentInfoId)" color="primary">
                          <img src="../../../assets/images/editblue.png">
                        </button>
                        <button style="display:none;" mat-icon-button matTooltip="Delete"  *ngIf="roleAccess.isDelete" (click)="deleteParentForm(element,i)" color="warn">
                          <img src="../../../assets/images/deletered.png">
                        </button>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
                </div>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
              </div>
            </div>
            <div class="contact-info-popup">
              <div class="modal"  tabindex="-1" role="dialog" [style.display]=" ShowContactSavePopup ? 'block' : 'none'">
                <div class="modal-dialog modal-lg" role="document">
                   <div class="modal-content">
                      <div class="modal-header">
                         <h6 class="modal-title text-uppercase">Contact information</h6>
                         <button type="button" class="close"  data-dismiss="modal"
                         aria-label="Close" (click)="onCloseContactSavePopup()">
                         <span aria-hidden="true">&times;</span>
                      </button>
                      </div>
                      <div class="modal-body p-0">
              <div class="col-md-12 ">
                <form (ngSubmit)="parentform.form.valid &&  addparent(parentform)" id="parentform" autocomplete="off"
            #parentform="ngForm" novalidate>
            <div class="lead-box mb-0 pb-2">
              <div class="clearfix w-100">
                <div class="float-right">
                  <span class="check-custom mr-3">
                    <mat-checkbox class="example-margin" [(ngModel)]="parentModel.IsPrimaryContact"
                    #parentIsPrimaryContact="ngModel" name="parentIsPrimaryContact" (change)="makePrimaryContact()">Do you prefer to use this contact as your primary contact?
                  </mat-checkbox>
                  </span>
                  <!-- <span class="check-custom ">
                    <mat-checkbox class="example-margin" [(ngModel)]="parentModel.IsEmergency" name="IsEmergency">
                      Emergency contact
                    </mat-checkbox>
                  </span> -->
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                      <mat-form-field class="w-100">
                      <input matInput placeholder="First Name" appAlphabetOnly [(ngModel)]="parentModel.FirstName"
                        #parentFirstName="ngModel" name="parentFirstName" maxlength="50"
                        [ngClass]="{ 'is-invalid': parentform.submitted && parentFirstName.invalid }" required>
                      <mat-error *ngIf="parentform.submitted && parentFirstName.invalid">
                        <mat-error *ngIf="parentFirstName.errors.required">First Name is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <!-- <mat-form-field class="w-100">
                      <input matInput placeholder="LastName Name (if minor)" appAlphabetOnly maxlength="50"
                        name="PLastName"
                       required>
                      </mat-form-field> -->
                      <mat-form-field class="w-100">
                        <input matInput placeholder="Last Name" appAlphabetOnly [(ngModel)]="parentModel.LastName"
                          #parentLastName="ngModel" name="parentLastName" maxlength="50"
                          [ngClass]="{ 'is-invalid': parentform.submitted && parentLastName.invalid }" required>
                        <mat-error *ngIf="parentform.submitted && parentLastName.invalid">
                          <mat-error *ngIf="parentLastName.errors.required">Last Name is required</mat-error>
                        </mat-error>
                      </mat-form-field>
                  </div>
                  <div class="col-md-3">
                    <!-- <mat-form-field class="w-100">
                      <mat-label>Relationship</mat-label>
                      <mat-select 
                        name="ParentalRelationTypeId"
                         required>
                        <mat-option>
                         parent
                        </mat-option>
                      </mat-select>
                    </mat-form-field> -->
                    <mat-form-field class="w-100">
                      <mat-label>Relation</mat-label>
                      <mat-select [(ngModel)]="parentModel.ParentalRelationTypeId"
                        #parentParentalRelationTypeId="ngModel" name="parentParentalRelationTypeId"
                        [ngClass]="{ 'is-invalid': parentform.submitted && parentParentalRelationTypeId.invalid }"
                        required>
                        <mat-option *ngFor="let item of ddlparentalRelationType" [value]="item.ParentalRelationTypeId">
                          {{item.ParentalRelationName}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="parentform.submitted && parentParentalRelationTypeId.invalid">
                        <mat-error *ngIf="parentParentalRelationTypeId.errors.required">Relation is required
                        </mat-error>
                      </mat-error>
                    </mat-form-field>

                  </div>
                  <div class="col-md-3">
                      <mat-form-field class="w-100">
                      <mat-label>Parent's Marital Status</mat-label>
                      <mat-select [(ngModel)]="parentModel.MaritalStatus" #MaritalStatus="ngModel"
                        name="MaritalStatus" [ngClass]="{ 'is-invalid': parentform.submitted && MaritalStatus.invalid }"
                        required>
                        <mat-option *ngFor="let item of ddlmaritalStatus"   [value]="item.value">{{item.label}}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="(parentform.submitted && MaritalStatus.invalid)">
                        <mat-error *ngIf="MaritalStatus.errors?.required">Marital Status is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-4">
                 <mat-form-field class="w-100">
                    <input type="text" name="address" placeholder="Full Address" matInput [matAutocomplete]="auto"
                    [(ngModel)]="parentModel.AddressLine1" (ngModelChange)="onChangeAddress($event)"
                    #AddressLine1="ngModel" name="AddressLine1"  [ngClass]="{ 'is-invalid': parentform.submitted && AddressLine1.invalid }"
                    required />
                    <mat-error *ngIf="(parentform.submitted && AddressLine1.invalid)t">
                      <mat-error *ngIf="AddressLine1.errors?.required">Address is required</mat-error>
                    </mat-error>

                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let address of addressoption" [value]="address.streetLine"
                      (click)="onselectaddress(address)">
                      {{ address.streetLine }} {{address.secondary}}
                    </mat-option>
                  </mat-autocomplete>
                  </mat-form-field>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                      <mat-form-field class="w-100">
                      <mat-label>State</mat-label>
                      <mat-select [(ngModel)]="parentModel.StatesId" #StatesId="ngModel" 
                        (selectionChange)="onChangeState($event)" name="StatesId"
                        [ngClass]="{ 'is-invalid': parentform.submitted && StatesId.invalid }" required>
                        <mat-option *ngFor="let item of ddlStates" [value]="item.StatesId">
                          {{item.StateName}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="parentform.submitted && StatesId.invalid">
                        <mat-error *ngIf="StatesId.errors?.required">State is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>City</mat-label>
                      <mat-select [(ngModel)]="parentModel.City" #City="ngModel" name="City" 
                        [ngClass]="{ 'is-invalid': parentform.submitted && City.invalid }" required>
                        <mat-option *ngFor="let item of ddlCities" [value]="item.CitiesId">
                          {{item.CityName}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="parentform.submitted && City.invalid">
                        <mat-error *ngIf="City.errors?.required">City is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                   <mat-form-field class="w-100">
                      <input matInput placeholder="Zip" mask='00000'  [(ngModel)]="parentModel.Zip"
                        #Zip="ngModel" name="Zip" [ngClass]="{ 'is-invalid': parentform.submitted && Zip.invalid }"
                        required>
                      <mat-error *ngIf="parentform.submitted && Zip.invalid">
                        <mat-error *ngIf="Zip.errors?.required">Zip is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Cell Number" mask='(000) 000-0000'
                        [(ngModel)]="parentModel.CellNumber" #CellNumber="ngModel" name="CellNumber"
                        [ngClass]="{ 'is-invalid': parentform.submitted && CellNumber.invalid }" required>
                        <mat-error *ngIf="(parentform.submitted && CellNumber.invalid)">
                          <mat-error *ngIf="CellNumber.errors?.required">Cell Number is required</mat-error>
                          <mat-error *ngIf="CellNumber.errors?.minlength">Please enter valid cell number</mat-error>
                        </mat-error>
                      </mat-form-field>
                  </div>

                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Work Number" mask='(000) 000-0000'
                        [(ngModel)]="parentModel.WorkNumber" #WorkNumber="ngModel" name="WorkNumber"
                        [ngClass]="{ 'is-invalid': parentform.submitted && WorkNumber.invalid }">
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Home Number" mask='(000) 000-0000' maxlength="14"
                        [(ngModel)]="parentModel.ContactNo" #ContactNo="ngModel" name="ContactNo"
                        [ngClass]="{ 'is-invalid': parentform.submitted && ContactNo.invalid }">
                      <mat-error *ngIf="parentform.submitted && ContactNo.invalid">
                      <mat-error *ngIf="ContactNo.errors?.minlength">Please enter valid phone number</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Email" [(ngModel)]="parentModel.Email" #Email="ngModel"
                        autocomplete="new-password" name="Email"
                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                        [ngClass]="{ 'is-invalid': parentform.submitted && Email.invalid }" required>
                      <mat-error *ngIf="(parentform.submitted && Email.invalid)">
                        <mat-error *ngIf="Email.errors?.required">Email is required</mat-error>
                        <mat-error *ngIf="Email.errors?.pattern">Please enter valid email address</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-1">
                <button type="submit"  mat-stroked-button form="parentform"
                  class="btn primary-btn-filled primary-btn-m h-40 " >Save Contact</button>
              </div>
            </div>
         </form>
          </div> 
          </div>
          </div>
          </div>
          </div>
            </div>
          </mat-tab>
          <mat-tab label="Provider Contact Info">
            <div class="d-flex" ><div class="ml-auto">
              <button  mat-stroked-button class="btn primary-btn-outlined-small float-right mb-2" *ngIf="roleAccess.isCreate"  (click)="AddProvider()">
                <i  class="fas fa-plus-circle mr-2"></i>Add Contact</button>
              </div></div> 
              <div class="col-md-12 table-custom p-0 ">
                <div class="example-container mat-elevation-z8 ">
                  <div class="table-responsive">
                    <mat-table #table [dataSource]="dataSourceForProvider" matSort>
  
                      <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->
  
                      <!-- Position Column -->
                      <ng-container matColumnDef="position">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index;"> {{i+1}}</mat-cell>
                      </ng-container>
  
                      <!-- Name Column -->
                      <ng-container matColumnDef="firstName">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> FirstName </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.FirstName}} </mat-cell>
                      </ng-container>
  
                      <!-- Weight Column -->
                      <ng-container matColumnDef="lastName" >
                        <mat-header-cell *matHeaderCellDef mat-sort-header> LastName </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.LastName}} </mat-cell>
                      </ng-container>
  
                      <!-- Symbol Column -->
                      <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.Email}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="contactNo">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.PhoneNumber | mask: '(000) 000-0000'}} </mat-cell>
                      </ng-container>
                      <ng-container matColumnDef="role">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>Roles </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.Role}} </mat-cell>
                      </ng-container>
                      <!-- <ng-container matColumnDef="isPrimaryContact">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>  </mat-header-cell>
                        <mat-cell *matCellDef="let element">  </mat-cell>
                      </ng-container> -->
                      <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef>{{roleAccess.isEdit||roleAccess.isDelete?'Actions':''}} </mat-header-cell>
                        <mat-cell *matCellDef="let element;let i = index;">
                          <!-- <button mat-icon-button matTooltip="Edit" (click)="editParentForm(element,i)" color="primary"> -->
                          <button mat-icon-button matTooltip="Edit" (click)="OnEditProviderInfo(element)" *ngIf="roleAccess.isEdit" color="primary">
                            <img src="../../../assets/images/editblue.png">
                          </button>
                          <button style="display:none;" mat-icon-button matTooltip="Delete"   *ngIf="roleAccess.isDelete"  (click)="deleteParentForm(element,i)" color="warn">
                            <img src="../../../assets/images/deletered.png">
                          </button>
                        </mat-cell>
                      </ng-container>
                      <mat-header-row *matHeaderRowDef="displayedColumnsForProvider"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumnsForProvider;"></mat-row>
                    </mat-table>
                  </div>
                  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div>
              </div>
              <div class="contact-info-popup">
              <div class="modal"  tabindex="-1" role="dialog"   [style.display]="IsEditProvider ? 'block' : 'none'">
                <div class="modal-dialog modal-lg" role="document">
                   <div class="modal-content">
                      <div class="modal-header">
                         <h6 class="modal-title text-uppercase">Contact information</h6>
                         <button type="button" class="close"  data-dismiss="modal" (click)="oncloseProvider()"
                         aria-label="Close" >
                         <span aria-hidden="true">&times;</span>
                      </button>
                      </div>
                      <div class="modal-body p-0">
              <div class="col-md-12 ">
                <form (ngSubmit)="providerform.form.valid &&  postProviderform()" id="providerform" autocomplete="off"
          #providerform="ngForm" novalidate>
            <div class="lead-box mb-0 pb-2">
              <div class="clearfix w-100">
               
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                      <mat-form-field class="w-100">
                      <input matInput placeholder="First Name" appAlphabetOnly [(ngModel)]="ObjProvider.FirstName"
                        #ObjProviderFirstName="ngModel" name="ObjProviderFirstName" maxlength="50"
                        [ngClass]="{ 'is-invalid': providerform.submitted && ObjProviderFirstName.invalid }" required>
                      <mat-error *ngIf="providerform.submitted && ObjProviderFirstName.invalid">
                        <mat-error *ngIf="ObjProviderFirstName.errors.required">First Name is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-md-4"  >
                    <mat-form-field class="w-100">
                    <input matInput placeholder="Last Name" appAlphabetOnly [(ngModel)]="ObjProvider.LastName"
                      #ObjProviderLastName="ngModel" name="ObjProviderLastName" maxlength="50"
                      [ngClass]="{ 'is-invalid': providerform.submitted && ObjProviderLastName.invalid }" required>
                    <mat-error *ngIf="providerform.submitted && ObjProviderLastName.invalid">
                      <mat-error *ngIf="ObjProviderLastName.errors.required">Last Name is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Office Phone Number" mask='(000) 000-0000'
                      [(ngModel)]="ObjProvider.PhoneNumber" #PhoneNumber="ngModel" name="PhoneNumber"
                      [ngClass]="{ 'is-invalid': providerform.submitted && PhoneNumber.invalid }" required>
                      <mat-error *ngIf="(providerform.submitted && PhoneNumber.invalid)">
                        <mat-error *ngIf="PhoneNumber.errors?.required">PhoneNumber is required</mat-error>
                        <mat-error *ngIf="PhoneNumber.errors?.minlength">Please enter valid phone number</mat-error>
                      </mat-error>
                    </mat-form-field>
                </div>
               </div>
              </div>
              <div class="col-md-12" >
                <div class="row" >

                  
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Email" [(ngModel)]="ObjProvider.Email" #Email="ngModel"
                        autocomplete="new-password" name="Email"
                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                        [ngClass]="{ 'is-invalid': providerform.submitted && Email.invalid }" required>
                      <mat-error *ngIf="(providerform.submitted && Email.invalid)">
                        <mat-error *ngIf="Email.errors?.required">Email is required</mat-error>
                        <mat-error *ngIf="Email.errors?.pattern">Please enter valid email address</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

            
                <div class="col-md-4">
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Fax Number" mask='(000) 000-0000'
                      [(ngModel)]="ObjProvider.FaxNumber" #FaxNumber="ngModel" name="FaxNumber"
                      [ngClass]="{ 'is-invalid': providerform.submitted && FaxNumber.invalid }" >
                      <!-- <mat-error *ngIf="(providerform.submitted && FaxNumber.invalid)">
                        <mat-error *ngIf="FaxNumber.errors?.required">Fax Number is required</mat-error>
                        <mat-error *ngIf="FaxNumber.errors?.minlength">Please enter valid fax number</mat-error>
                      </mat-error> -->
                    </mat-form-field>
                </div>
                <div class="col-md-4" *ngIf="isAddProvider">
                  <mat-form-field class="w-100">
                    <mat-label>Physician Type</mat-label>
                    <mat-select [(ngModel)]="ObjProvider.PhysicianTypeId" #PhysicianTypeId="ngModel" name="PhysicianTypeId"   
                      [ngClass]="{ 'is-invalid': providerform.submitted && PhysicianTypeId.invalid }" required>
                      <mat-option *ngFor="let drp of getProviderDetails()" [value]="drp.value">
                        {{drp.label}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="providerform.submitted && PhysicianTypeId.invalid">
                      <mat-error *ngIf="PhysicianTypeId.errors?.required">Physician Type is required</mat-error>
                    </mat-error>

                 </mat-form-field>
            
            </div> 

                </div>
              </div>
              <div class="col-md-12" >
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Facility Name" 
                        [(ngModel)]="ObjProvider.HospitalName" #HospitalName="ngModel" name="HospitalName"
                        [ngClass]="{ 'is-invalid': providerform.submitted && HospitalName.invalid }" required>
                        <mat-error *ngIf="(providerform.submitted && HospitalName.invalid)">
                          <mat-error *ngIf="HospitalName.errors?.required">Facility Name is required</mat-error>                       
                        </mat-error>
                      </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Address1" 
                        [(ngModel)]="ObjProvider.AddressLine1" #AddressLine1="ngModel" name="AddressLine1"
                        [ngClass]="{ 'is-invalid': providerform.submitted && AddressLine1.invalid }" required>
                        <mat-error *ngIf="(providerform.submitted && AddressLine1.invalid)">
                          <mat-error *ngIf="AddressLine1.errors?.required">Address1 is required</mat-error>                         
                        </mat-error>
                      </mat-form-field>
                  </div>
                   <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Address2"
                        [(ngModel)]="ObjProvider.AddressLine2" #AddressLine2="ngModel" name="AddressLine2"
                        [ngClass]="{ 'is-invalid': providerform.submitted && AddressLine2.invalid }" >
                        <!-- <mat-error *ngIf="(providerform.submitted && AddressLine2.invalid)">
                          <mat-error *ngIf="AddressLine2.errors?.required">Cell Number is required</mat-error>
                        </mat-error> -->
                      </mat-form-field>
                  </div> 
                </div>
              </div>
              <div class="col-md-12" >
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                    <mat-label>State</mat-label>
                    <mat-select [(ngModel)]="ObjProvider.StatesId" #StatesId="ngModel" 
                      (selectionChange)="onChangeStateforclient($event)" name="StatesId"
                      [ngClass]="{ 'is-invalid': providerform.submitted && StatesId.invalid }" required>
                      <mat-option *ngFor="let item of ddlStates" [value]="item.StatesId">
                        {{item.StateName}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="(providerform.submitted && StatesId.invalid)">
                      <mat-error *ngIf="StatesId.errors?.required">State is required</mat-error>                       
                    </mat-error>
                   </mat-form-field>
                   <!-- <span *ngIf="isStateValid" class="text-xs text-danger">State is required</span> -->
                </div>
                  <div class="col-md-4">
                 <!-- <mat-form-field class="w-100">
                      <mat-label>City</mat-label>
                      <mat-select [(ngModel)]="ObjProvider.CityId" #CityId="ngModel" name="CityId"   (selectionChange)="onChangeCity($event)"
                        [ngClass]="{ 'is-invalid': providerform.submitted && City.invalid }" required>
                        <mat-option *ngFor="let item of ddlCities" [value]="item.CitiesId">
                          {{item.CityName}}
                        </mat-option>
                      </mat-select>
                      </mat-form-field>  -->
                      <mat-form-field *ngIf="showDdlProviderInfoCity">
                        <mat-select placeholder="City"  [(ngModel)]="ObjProvider.CityId" #CityId="ngModel" name="CityId" (selectionChange)="errorhide()" required>
                            <cdk-virtual-scroll-viewport [itemSize]="35" [style.height.px]=5*35 >
                             <mat-option *cdkVirtualFor="let item of ddlCitiesforclient" [value]="item.CitiesId"
                              >{{item.CityName}}</mat-option>
                            </cdk-virtual-scroll-viewport>
                        </mat-select>
                       
                    </mat-form-field>
                    <div *ngIf="isCityValid">
                      <span  class="text-xs err_danger">City is required</span> 
                    </div>

                    <mat-form-field *ngIf="!showDdlProviderInfoCity" >
                      <input matInput placeholder="City" 
                      [(ngModel)]="CityName"  name="CityName" >
                      </mat-form-field>
                           <span class="edit-contact-city" (click)="OneditProviderinfoCity()" *ngIf="!showDdlProviderInfoCity"> <i class="fa fa-edit" ></i></span>
                          
                  </div>

                
                 
                  <div class="col-md-4">
                   <mat-form-field class="w-100">
                      <input matInput placeholder="ZipCode" mask='00000'  [(ngModel)]="ObjProvider.ZipCode"
                        #ZipCode="ngModel" name="ZipCode" [ngClass]="{ 'is-invalid': providerform.submitted && ZipCode.invalid }"
                        required>
                      <mat-error *ngIf="providerform.submitted && ZipCode.invalid">
                        <mat-error *ngIf="ZipCode.errors?.required">Zip is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>          
           
              <div class="col-md-12 text-right mt-1">
                <button type="submit"  mat-stroked-button form="providerform"
                  class="btn primary-btn-filled primary-btn-m h-40 " (click)="errorcitys()" >Save Contact</button>
              </div>
              
              
            </div>
         </form>
          </div>
          </div>
          </div>
          </div>
          </div>
            </div>
          </mat-tab>
          <mat-tab label="Lead details ">
            <div class="d-flex" ><div class="ml-auto">
              <!-- <button  mat-stroked-button class="btn primary-btn-outlined-small float-right mb-2" *ngIf="roleAccess.isCreate"  (click)="AddLeadDetails()">
                <i  class="fas fa-plus-circle mr-2"></i>Add Contact</button> -->
              </div></div> 
              <div class="col-md-12 table-custom p-0 ">
                <div class="example-container mat-elevation-z8 ">
                  <div class="table-responsive">
                     <mat-table #table [dataSource]="dataSourceForLead" matSort>
                      <!-- <mat-table>  -->
                    <ng-container matColumnDef="position">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> S.No </mat-header-cell>
                      <mat-cell *matCellDef="let Leaddetailview; let i = index;"> {{i+1}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="source">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Source </mat-header-cell>
                      <mat-cell *matCellDef="let Leaddetailview"> {{Leaddetailview?.SourceTypeName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="type">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> LeadType </mat-header-cell>
                      <mat-cell *matCellDef="let Leaddetailview"> {{Leaddetailview?.LeadTypeName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="location">
                      <mat-header-cell *matHeaderCellDef mat-sort-header> Location </mat-header-cell>
                      <mat-cell *matCellDef="let Leaddetailview"> {{Leaddetailview?.LocationName}} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                      <mat-header-cell *matHeaderCellDef>{{roleAccess.isEdit||roleAccess.isDelete?'Actions':''}} </mat-header-cell>
                      <mat-cell *matCellDef="let element;let i = index;">
                        <!-- <button mat-icon-button matTooltip="Edit" (click)="editParentForm(element,i)" color="primary"> -->
                        <button mat-icon-button matTooltip="Edit" *ngIf="roleAccess.isEdit" (click)="onEditLeadDetailInfo(element.ParentInfoId)" color="primary">
                          <img src="../../../assets/images/editblue.png">
                        </button>
                        <button style="display:none;" mat-icon-button matTooltip="Delete"  *ngIf="roleAccess.isDelete" (click)="deleteParentForm(element,i)" color="warn">
                          <img src="../../../assets/images/deletered.png">
                        </button>
                      </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsForLeaddetails"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsForLeaddetails;"></mat-row>
                    <!-- <ng-container matColumnDef="actions">
                      <mat-header-cell *matHeaderCellDef>{{roleAccess.isEdit||roleAccess.isDelete?'Actions':''}} </mat-header-cell>
                    </ng-container> -->
                  </mat-table> 
            
                      <!-- Weight Column 
                       </mat-table> -->
                  </div>
                  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                </div> 
              </div>
          </mat-tab>
          <mat-tab label="Journal">
            <div class="notes-section">
              <div class="col-md-12 p-0">
                <div class="lead-box">
                  <div class="top-notes d-flex">
                    
                    <div class="ml-auto togle-task">
                      <div class="btn-group btn-group-toggle" data-toggle="buttons">
                        <label class="btn btn-light themed active">
                          <input type="radio" (click)="ontaskview()"  name="options" id="option1" autocomplete="off" checked> <i class="fas fa-tasks mr-2"></i>Tasks
                        </label>
                        <label class="btn btn-light themed">
                          <input type="radio" (click)="oncalenderView()" name="options" id="option2" autocomplete="off"> <i class="far fa-sticky-note mr-2"></i>Notes
                        </label>
                      </div>
                      <!-- <button type="button" title="Task"  mat-raised-button  (click)="ontaskview()" 
                        class="primary-btn-outlined-small mr-2"><i class="fas fa-tasks mr-2"></i>Task
                        </button>
                      <button type="button" title="Notes" (click)="oncalenderView()"mat-raised-button
                        class="primary-btn-outlined-small "><i class="far fa-sticky-note mr-2"></i>Notes</button> -->


                    </div>
                  </div>

                  <div *ngIf=!isShowCalenderView>
                    <app-task></app-task>
                  </div>
                  <div *ngIf=isShowCalenderView>
                    <app-notes-add></app-notes-add>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <!-- <mat-tab label="Notes">
            <app-notes-add></app-notes-add>

          </mat-tab> -->
          <mat-tab label="ACTIVITY">
            <app-lead-history></app-lead-history>
          </mat-tab>
          <mat-tab label="Messages">
            <div class="inside-tabs-msg">
              <!-- <app-messages [id]="patientInfoId"></app-messages> -->
              <app-messages ></app-messages>

            </div>
          </mat-tab>

        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
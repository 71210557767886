<div class="finance-review-page">
    <div class="top-btn-section clearfix mb-2">
      <div class="col-md-12">
          <div class="row">
            <div class="col-md-8">
              <div class="head-section mb-2 mt-2 pl-3">Review Tour Request</div>
            </div>
            <div class="col-md-4 ml-auto pr-0">
              <span class=" float-right">

               </span>


                <button class="btn back-btn-new mr-2" (click)="onBack()"><i class="fas fa-chevron-left mr-2"></i>Back</button>

            </div>
          </div>
        </div>


    </div>
    <div class="lead-box mb-3 pt-3 h-vh-50">

        <div class="col-md-12 finance-review p-0 pt-2">
            <div class="col-md-12 proposal">
                <div class="row">
                    <div class="col-md-4 d-none">
                        <div class="proposal-form">
                            <div class="form">
                                <p>Hello <b>{{userName}}</b></p>
                                <p> I was able to take a look at the benefts and the following would be your financial
                                    responsibility
                                    for the program: </p>
                                <!-- <div class="col-md-12 pricelistview-review">
                                    <div class="row" *ngFor="let com of financialProposal?.componentDetails">
                                        <div class="col-md-12 p-0">
                                            <div class="list-head price-label mb-2">{{com.componentName}}</div>
                                        </div>
                                        <div class="col-md-12" *ngFor="let pr of com.pricing">
                                            <div class="row">
                                                <p class="col-md-6">if co-Insurance {{pr.coInsuranceVal}}</p>
                                                <div class="col-md-6 pr-0">
                                                    <p>{{pr.priceValue}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row ">
                                        <div class="col-md-6 pl-0">
                                            <p> Deductible - <b>{{financialProposal?.deductibleType}}</b></p>
                                        </div>
                                        <div class="col-md-6 pr-0">
                                            <p>{{financialProposal?.deductibleTypeVal}}</p>

                                        </div>
                                    </div>

                                </div> -->
                                <div class="col-md-12 pricelistview-review">
                                    <div  class="col-md-12">
                                        <div  class="row">
                                            <div  class="col-md-4 "  *ngFor="let com of financialProposal?.componentDetails">
                                                <div class="">
                                                    <label  class="list-head price-label col-md-12">{{com.componentName}}</label>
                                                    <div  class="col-md-12 p-0" *ngFor="let pr of com.pricing">
                                                        <div  class="list-value-new float-left w-100 ">
                                                            <span  class="highlight-text">$ {{pr.priceValue}}</span>
                                                            <span  class="small-text-highlight">if co-insurance {{pr.coInsuranceVal}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div  class="col-md-4 ">
                                                <div class="">
                                                    <label  class="list-head price-label col-md-12">Deductible</label>
                                                    <div  class="col-md-12 p-0 ">
                                                        <div  class="list-value-new float-left w-100 ">
                                                            <span  class="small-text-highlight"><b>{{financialProposal?.deductibleType}} -</b> {{financialProposal?.deductibleTypeVal}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>

                                </p>
                                <p>Please let me know if you have any questions and if you would like to move forward.
                                </p>
                                <p>Best, </p>
                                <div class="col-md-12 p-0">
                                    <div class="row">
                                        <div class="col-md-6 text-left">
                                            <p class="m-0">{{financialProposal?.location?.locationName}}</p>
                                            <p class="m-0">{{financialProposal?.location?.address1}},
                                                {{financialProposal?.location?.address2}}</p>
                                            <p class="m-0">{{financialProposal?.location?.stateName}},
                                                {{financialProposal?.location?.cityName}}</p>
                                        </div>


                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <div>Thank you</div>
                                        </div>

                                    </div>
                                </div>
                                <!-- <div class="col-md-12 p-0">
                                  <div class="row">
                                      <div class="col-md-12 text-center">
                                          <div>Thank you</div>
                                      </div>

                                  </div>
                              </div> -->
                            </div>
                        </div>
                    </div>







                    <div class="col-md-12 req-tour ">
                        <div class="card">

                            <mat-radio-group aria-label="Select an option" *ngIf="roleAccess.isView">
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="col-md-12 mt-3 ml-3 ">
                                      <mat-radio-button value="1" (change)="changeView(1)" *ngIf="financialProposal?.requestTourDetails?.length>1"> Tour Request - 1</mat-radio-button>
                                    </div>
                                    <div class="card-body pt-1 pb-1" [ngClass]="showFirstDate ? 'card-body' : 'card-body disabled'" (change)="showFirstDate=true">

                                      <div class="req-tour-section">
                                          <label class="label-tour col-md-12 p-0">{{financialProposal.parentname}} has requested a Tour. Please review the details below and assign staff.</label>

                                          <div class="info-user">

                                              <p>Tour Requested Date: <b
                                                      class="ml-2">{{financialProposal?.requestTourDetails[0]?.requestTourDate |
                                                      date: 'MM/dd/yyyy'}}</b> </p>
                                              <p>Tour Requested Time: <b
                                                      class="ml-2">{{financialProposal?.requestTourDetails[0]?.fromTime | date:
                                                      'hh:mm a'}} - {{financialProposal?.requestTourDetails[0]?.toTime | date:
                                                      'hh:mm a'}}</b></p>
                                              <p class="confirm-schedule help-texted"
                                                  *ngIf=" financialProposal?.requestTourDetails[0]?.status ==  statustourCompleted  || financialProposal?.requestTourDetails[0]?.status == statusstaffAssigned">
                                                  Your tour is confirmed with <b>{{financialProposal?.requestTourDetails[0]?.userName}}</b>. Please check in with the front desk once you arrive.
                                                  <!-- Your schedule is confirmed with
                                                  <b>{{financialProposal?.requestTourDetails?.userName}}</b>. You can check
                                                  with our front office once you visit us here. -->
                                              </p>
                                          </div>
                                          <div class="col-md-12 text-center p-0 mb-3 mt-2">
                                              <button class="btn primary-btn-filled mx-auto cursor-pointer" type="button"  *ngIf="financialProposal?.requestTourDetails[0]?.status != statusstaffAssigned && roleAccess.isApprove"
                                                  title="Assign Staff" (click)="onselectAssign(1)">Assign Staff</button>
                                                  <div class="d-flex">
                                                      <button class="btn primary-btn-filled mr-2 ml-5 cursor-pointer" type="button" *ngIf="financialProposal?.requestTourDetails[0]?.status == statusstaffAssigned  && roleAccess.isEdit"
                                                  title="Re-schedule" (click)="GetAvailableTimeSlots()">Re-schedule</button>
                                              <button class="btn primary-btn-filled  cursor-pointer" type="button" *ngIf="financialProposal?.requestTourDetails[0]?.status == statusstaffAssigned && roleAccess.isEdit"
                                                  title="Change Staff" (click)="onselectAssign(3)">Change Staff</button>
                                                  </div>

                                          </div>
                                          <div class="staff-details border-top " *ngIf="isShowAssignStaff && showFirstDate">
                                              <div class="col-md-12 p-0 " >
                                                  <mat-form-field class="w-100 mt-2">
                                                      <mat-label>Assign Staff</mat-label>
                                                      <mat-select [(ngModel)]="assignstaffId"
                                                          (selectionChange)="onChangeAssignStaff($event,1)">
                                                          <mat-option *ngFor="let a of stafflist" [value]="a.staffId">
                                                              {{a.firstName}} {{a.lastName}}
                                                          </mat-option>
                                                      </mat-select>
                                                  </mat-form-field>
                                                  <mat-error *ngIf="showAssignStaffError" class="mat-form-field-subscript-wrapper">
                                                      Please select staff
                                                  </mat-error>
                                              </div>
                                              <div class="col-md-12 p-0">
                                                  <div *ngIf="staffSlotlst?.length > 0">
                                                      <label class="label-tour col-md-12 p-0 font-15 mb-3">Staff's existing
                                                          schedule before you assign:</label>
                                                      <div class="schedule-info" *ngFor="let data of staffSlotlst">
                                                          <div class="schedule-list "
                                                              [ngClass]="{'conflict':data.isConflict}">
                                                              <span><i class="fas fa-clock mr-2"></i>{{data?.fromTime |
                                                                  date:'hh:mm a'}} - {{data?.toTime | date:'hh:mm a'}}:
                                                                  Meeting
                                                                  with {{data.firstname}} {{data.lastName}}</span>
                                                          </div>
                                                          <div class="conflict-msg" *ngIf="data.isConflict"><span
                                                                  class="text-conflict">Conflict!</span>
                                                              <span class="remain-text">You may consider assigning to someone
                                                                  else.</span>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="help-texted d-none" *ngIf="staffSlotlst?.length > 0">
                                                      Rest of the days are available. You can assign if you don't find any
                                                      conflict
                                                  </div>
                                                  <div class="row form-group d-none" *ngIf="isShowAssignStaff">
                                                      <label class="label-tour col-md-5 mt-3">Available Time</label>
                                                      <div class="col-md-7">
                                                          <mat-form-field class="w-100">
                                                              <mat-select [(ngModel)]="slotAvailableId"
                                                                  (selectionChange)="onChangeAvaSlot($event)">
                                                                  <mat-option *ngFor="let a of slotsAvailable"
                                                                      [value]="a.timeSlotsId">
                                                                      {{a.fromTime | date: 'hh:mm a'}} - {{a.toTime | date:
                                                                      'hh:mm a'}}
                                                                  </mat-option>
                                                              </mat-select>

                                                          </mat-form-field>
                                                          <mat-error *ngIf="istimeSlot"
                                                              class="mat-form-field-subscript-wrapper">
                                                              Please select available slot
                                                          </mat-error>
                                                      </div>
                                                  </div>

                                                  <div class="row form-group" *ngIf="isShowAssignStaff">
                                                      <div class="col-md-12 text-right">
                                                          <button class="btn btn-cancel float-right cursor-pointer"
                                                              type="button" title="Cancel"
                                                              (click)='onCancelAssign()'>Cancel</button>
                                                          <button
                                                              class="btn primary-btn-filled float-right mr-2 cursor-pointer"
                                                              type="button" title="Assign Staff"
                                                              (click)="postAssignStaff(0)" *ngIf="isShowAssignStaff&&!isShowChangeStaff">Confirm :
                                                              {{financialProposal?.requestTourDetails[0]?.fromTime | date:
                                                              'hh:mm a'}} - {{financialProposal?.requestTourDetails[0]?.toTime |
                                                              date:
                                                              'hh:mm a'}}</button>
                                                            <button
                                                            class="btn primary-btn-filled float-right mr-2 cursor-pointer"
                                                            type="button" title="Assign Staff"
                                                            (click)="postAssignStaff(3)" *ngIf="isShowChangeStaff && isShowAssignStaff">Confirm :
                                                            {{financialProposal?.requestTourDetails[0]?.fromTime | date:
                                                            'hh:mm a'}} - {{financialProposal?.requestTourDetails[0]?.toTime |
                                                            date:
                                                            'hh:mm a'}}</button>

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  </div>









                                  <div class="col-md-6">
                                    <div class="col-md-12 mt-3 ml-3 ">
                                      <mat-radio-button value="2" (change)="changeView(2)" *ngIf="financialProposal?.requestTourDetails?.length>1"> Tour Request - 2</mat-radio-button>
                                      </div>
                                      <div class="card-body pt-1" [ngClass]="!showFirstDate||isReschedule ? 'card-body' : 'card-body disabled'" *ngIf="financialProposal?.requestTourDetails?.length>1">
                                        <div class="req-tour-section">
                                            <label class="label-tour col-md-12 p-0">{{financialProposal.parentname}} has requested a Tour. Please review the details below and assign staff.</label>

                                            <div class="info-user">

                                                <p>Tour Requested Date: <b
                                                        class="ml-2">{{financialProposal?.requestTourDetails[1]?.requestTourDate |
                                                        date: 'MM/dd/yyyy'}}</b> </p>
                                                <p>Tour Requested Time: <b
                                                        class="ml-2">{{financialProposal?.requestTourDetails[1]?.fromTime | date:
                                                        'hh:mm a'}} - {{financialProposal?.requestTourDetails[1]?.toTime | date:
                                                        'hh:mm a'}}</b></p>
                                                <p class="confirm-schedule help-texted"
                                                    *ngIf=" financialProposal?.requestTourDetails[1]?.status ==  statustourCompleted  || financialProposal?.requestTourDetails[1]?.status == statusstaffAssigned">
                                                    Your tour is confirmed with <b>{{financialProposal?.requestTourDetails[1]?.userName}}</b>.. Please check in with the front desk once you arrive.
                                                    <!-- Your schedule is confirmed with
                                                    <b>{{financialProposal?.requestTourDetails?.userName}}</b>. You can check
                                                    with our front office once you visit us here. -->
                                                </p>
                                            </div>
                                            <div class="col-md-12 text-center p-0 mb-3 mt-2">
                                                <button class="btn primary-btn-filled mx-auto cursor-pointer" type="button"  *ngIf="financialProposal?.requestTourDetails[1]?.status != statusstaffAssigned && roleAccess.isApprove"
                                                    title="Assign Staff" (click)="onselectAssign(2)">Assign Staff</button>
                                                    <div class="d-flex">
                                                        <button class="btn primary-btn-filled mr-2 ml-5 cursor-pointer" type="button" *ngIf="financialProposal?.requestTourDetails[1]?.status == statusstaffAssigned && roleAccess.isEdit"
                                                    title="Re-schedule" (click)="GetAvailableTimeSlots()">Re-schedule</button>
                                                <button class="btn primary-btn-filled  cursor-pointer" type="button" *ngIf="financialProposal?.requestTourDetails[1]?.status == statusstaffAssigned && roleAccess.isEdi"
                                                    title="Change Staff" (click)="onselectAssign(2)">Change Staff</button>
                                                    </div>

                                            </div>
                                            <div class="staff-details border-top " *ngIf="isShowAssignStaff1 && !showFirstDate">
                                                <div class="col-md-12 p-0 " *ngIf="isShowAssignStaff1">
                                                    <mat-form-field class="w-100 mt-2">
                                                        <mat-label>Assign Staff</mat-label>
                                                        <mat-select [(ngModel)]="assignstaffId1"
                                                            (selectionChange)="onChangeAssignStaff1($event,2)">
                                                            <mat-option *ngFor="let a of stafflist1" [value]="a.staffId">
                                                                {{a.firstName}} {{a.lastName}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-error *ngIf="showAssignStaff1Error" class="mat-form-field-subscript-wrapper">
                                                        Please select staff
                                                    </mat-error>
                                                </div>



                                                <div class="col-md-12 p-0">
                                                    <div *ngIf="staffSlotlst?.length > 0">
                                                        <label class="label-tour col-md-12 p-0 font-15 mb-3">Staff's existing
                                                            schedule before you assign:</label>
                                                        <div class="schedule-info" *ngFor="let data of staffSlotlst">
                                                            <div class="schedule-list "
                                                                [ngClass]="{'conflict':data.isConflict}">
                                                                <span><i class="fas fa-clock mr-2"></i>{{data?.fromTime |
                                                                    date:'hh:mm a'}} - {{data?.toTime | date:'hh:mm a'}}:
                                                                    Meeting
                                                                    with {{data.firstname}} {{data.lastName}}</span>
                                                            </div>
                                                            <div class="conflict-msg" *ngIf="data.isConflict"><span
                                                                    class="text-conflict">Conflict!</span>
                                                                <span class="remain-text">You may consider assigning to someone
                                                                    else.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="help-texted d-none" *ngIf="staffSlotlst?.length > 0">
                                                        Rest of the days are available. You can assign if you don't find any
                                                        conflict
                                                    </div>
                                                    <div class="row form-group d-none" *ngIf="isShowAssignStaff1">
                                                        <label class="label-tour col-md-5 mt-3">Available Time</label>
                                                        <div class="col-md-7">
                                                            <mat-form-field class="w-100">
                                                                <mat-select [(ngModel)]="slotAvailableId"
                                                                    (selectionChange)="onChangeAvaSlot($event)">
                                                                    <mat-option *ngFor="let a of slotsAvailable"
                                                                        [value]="a.timeSlotsId">
                                                                        {{a.fromTime | date: 'hh:mm a'}} - {{a.toTime | date:
                                                                        'hh:mm a'}}
                                                                    </mat-option>
                                                                </mat-select>

                                                            </mat-form-field>
                                                            <mat-error *ngIf="istimeSlot"
                                                                class="mat-form-field-subscript-wrapper">
                                                                Please select available slot
                                                            </mat-error>
                                                        </div>
                                                    </div>

                                                    <div class="row form-group" *ngIf="isShowAssignStaff1">
                                                        <div class="col-md-12 text-right">
                                                            <button class="btn btn-cancel float-right cursor-pointer"
                                                                type="button" title="Cancel"
                                                                (click)='onCancelAssign()'>Cancel</button>
                                                            <button
                                                                class="btn primary-btn-filled float-right mr-2 cursor-pointer"
                                                                type="button" title="Assign Staff"
                                                                (click)="postAssignStaff(1)">Confirm :
                                                                {{financialProposal?.requestTourDetails[1]?.fromTime | date:
                                                                'hh:mm a'}} - {{financialProposal?.requestTourDetails[1]?.toTime |
                                                                date:
                                                                'hh:mm a'}}</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                              </div>




                        </mat-radio-group>
                        <!-- <div class="req-tour-section" *ngIf=" !roleAccess.isEdit">
                            <p>You do not have the access.</p>
                        </div> -->
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
<div class="comment-popup  re-schedule-popup" *ngIf="isRescheduleTourEnabled">
    <div class="modal" tabindex="-1" role="dialog"  style="display: block;">
      <div class="modal-dialog mat-dialog-container " role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title"> Re-schedule</h6>
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close"  (click)="isRescheduleTourEnabled=false">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
  <div >


    <div class="col-md-12">

        <div class="row mb-2">
          <div class="col-md-4">
            <label class="control-label-new font-weight-bold">Tour Date</label>

          </div>

           <div class="col-md-8">
            <mat-form-field class="w-100 mt--14">
              <mat-label>Choose a date</mat-label>
              <input matInput [matDatepicker]="picker" [min]="currentdate" name="schduleDate" [(ngModel)]="newAssignedDate" (dateChange)="calanderChange()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div *ngIf="isReschedule">
              <br>
              <span *ngIf="isReschedule" class="mat-form-field-subscript-wrapper text-danger">No date is selected</span>
            </div>
          </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-4">
              <label class="control-label-new font-weight-bold">Confirm Time</label>

            </div>

             <div class="col-md-8">
                <select class="form-control" #selectTime [(ngModel)]="newAssignItem" (change)="selectChange($event)">
                    <option *ngFor="let item of timeSlots.availableSlots" value="{{item.fromTime+separator+item.toTime}}">
                      {{item.fromTime | date:'hh:mm a'}} - {{item.toTime | date:'hh:mm a'}}
                    </option>
                </select>
                <div *ngIf="isTimeSelected">
                  <br>
                  <span *ngIf="isTimeSelected" class="mat-form-field-subscript-wrapper text-danger">No time selected for selected date </span>
                </div>
            </div>
          </div>
    </div>
    <div class="text-right col-md-12 mt-3">
      <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" (click)="PostRescheduleTour(financialProposal?.requestTourDetails[0])" >Re-schedule</button>
      <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="cancelClick()"  >Cancel</button>
    </div>



  </div>


  </div>
  </div>
  </div>
  </div>
  </div>

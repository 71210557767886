export class createBill
    {
         BillId :Number=0;
         BProviderId :Number;
         BMethodId :Number;
         BFormType :string;
         DiagnosisCode:string;
         DOSFrom :any;
         DOSTo:any ;
         TransCode:string;
         Units :any;
        Amount :any;
        Tdescription : any;
        RevenuCode : any;
        CPTCode : any;
         Rprovider :any;
        PatientInfoId:Number;
        PatientAccountId :Number;
         DayScheduleAssignId:string;
        MeetingScheduleId :string;
        MeetingScheduleTimeId :string;
        TherapyID  :Number;
        IsInTake :any;
        BformSaveMethhod :string;
IsDeleted :any;
UserId=localStorage.getItem('id');
BillStatus:string;
IsUpdated:boolean=false;
BillStatusId:any;
        }
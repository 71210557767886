import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { LeadService } from 'src/app/patient-lead/lead.service';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { SnackBarService } from 'src/app/services/snackBar.service';
import {DtoAuthNotifySettings} from 'src/app/_models/_Common/AuthSetting'
import { SettingsService } from '../settings.service';
@Component({
  selector: 'app-authorization-settings-master',
  templateUrl: './authorization-settings-master.component.html',
  styleUrls: ['./authorization-settings-master.component.scss']
})
export class AuthorizationSettingsMasterComponent implements OnInit {
  @ViewChild('AuthSettings') AuthSettings: NgForm;
  AuthorizationNotificationType=[];
  AuthorizationNotificationPercentageType: any;
  dtoAuthNotifySettings:DtoAuthNotifySettings;
  isEditAuthSettings: boolean;
  constructor(public leadservice:LeadService,public commonService: CommonService,private snackbar: SnackBarService,private spinservice:NgxSpinnerService,
    private dialog: MatDialog, public roleaccessservice:PageAccessService
    ,private settingsService :SettingsService,public spinnerservice: NgxSpinnerService) { }

  ngOnInit(): void {
    this.dtoAuthNotifySettings = new DtoAuthNotifySettings();
    this.GetAuthorizationNotificationType();
    this.getAuthSettingsMaster();          
  }
  GetAuthorizationNotificationType()
  {
    this.leadservice.getAuthorizationNotificationTypeMaster().subscribe((res:any)=>{
      this.AuthorizationNotificationType = res.filter(x=>x.notifyType==1);
      this.AuthorizationNotificationPercentageType = res.filter(x=>x.notifyType==2);
       // console.log(res)
      })
  }

  postNotifyAuthsettings(){
    if (!this.AuthSettings.valid) {
      return
    }
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoAuthNotifySettings, false);
    this.settingsService.postNotifyAuthsettings(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.snackbar.success(res);     
        this.getAuthSettingsMaster();             
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getAuthSettingsMaster(){
    this.settingsService.getAuthSettingsMaster().subscribe(
      res => {
        if (res) {
          this.dtoAuthNotifySettings = res;
          if(this.dtoAuthNotifySettings.authNotificationMasterId==0){
            this.isEditAuthSettings = false;
          }
          else{
            this.isEditAuthSettings = true;
          }
        }
      })
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantVariables } from 'src/app/constant/constant';
import { IntakegenerateService } from 'src/app/patient/intake/intake-generate/intakegenerate.service';
import { PatientService } from 'src/app/patient/patient.service';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { CommonService } from '../../common.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ShareDocuments } from 'src/app/_models/_Common/_document';
import { TemplateService } from '../template/template.service';

@Component({
  selector: 'app-client-documents',
  templateUrl: './client-documents.component.html',
  styleUrls: ['./client-documents.component.scss']
})
export class ClientDocumentsComponent implements OnInit {
  @ViewChild('attachments') attachment: any;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('ShareDocument') ShareDocument: NgForm;

  roleAccess: any;
  patientAccountId: any;
  patientName: any;
  uploadedDocumentList: any = [];
  ShowDocuments: boolean = false;
  fileList: File[] = [];
  listOfFiles: any[] = [];
  SelectedPatientInfoForFile: any;
  filesize:any;
  filecount:any;
  patientId: any;
  UserName: any;
  uploadedDocumentfile: any;
  isShare: boolean = false;
  clickedItem: any;
  isShareDocument: boolean = false;
  isShareAll: boolean = true;
  shareDocuments: any;
  toEmail: any;
  documentTypeForShare: any;
  clickedTab: any;
  ddlDocumentType : any;
  documentModuleTypeId : number = 0;
  isRequired : boolean = false;



  constructor(public spinnerservice: NgxSpinnerService,public patientservice: PatientService,public commonService: CommonService,
    private snackbar: SnackBarService,public roleaccessservice:PageAccessService,private router: Router,private route: ActivatedRoute,
    public treatmentservice: TreatmentService,public tempService: TemplateService) { }

  ngOnInit(): void {
     const pageid = constantVariables.pagelists.ClientDocuments;
     this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
     this.patientId = localStorage.getItem("patientId");
     this.UserName = localStorage.getItem("username");
     this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
     this.getUploadedDocumentList();
     this.shareDocuments = new ShareDocuments();
     this.getDocumentModuleType();
     //  this.route.queryParams.subscribe(params => {
      // if (params != null) {
      //   this.patientAccountId = this.commonService.FrontEndDecryption(params.id).replace(/"/g, "");
      //   this.patientName = params.patientName;
      // }
      // this.dtoPatientOutofPacketInvoicePdf = new DtoPatientOutofPacketInvoicePdf();
      // this.shareDocuments = new ShareDocuments();
      // this.checkDocumentExists();

    //  })
    // this.getpatientViewList();
  }
  getDocumentModuleType()
  {
    this.tempService.getDocumentModuleType().subscribe(
      res => {
        if (res != null) {
          this.ddlDocumentType = res;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }
  getUploadedDocumentList() {
    this.uploadedDocumentList = [];
    this.spinnerservice.show();
    this.patientservice.getUploadedDocumentList(this.patientAccountId).subscribe(
      (res: any) => {
        //this.spinnerservice.hide();
        if (res.objInputString != null) {
          var DataResult = this.commonService.decrypdata(res.objInputString);
          if (DataResult) {
            //  this.isUploadedDocumentExists = true;
             this.uploadedDocumentList = DataResult;
             this.spinnerservice.hide();
            //  this.ActiveTab();
            //  this.NoDocumentExist();
          }
          this.spinnerservice.hide();
        }
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  getDocuments(patientId) {
    this.fileList=[];
    this.listOfFiles = [];
    this.documentModuleTypeId = null;
    this.isRequired = false;
    this.ShowDocuments = true;
    this.SelectedPatientInfoForFile = patientId;
  }
  closepopup(){
    this.filesize=false;
    this.filecount=false;
    this.ShowDocuments = false;
    return;
  }
  onFileChanged(event: any) {
    if (this.fileList && this.fileList.length >= 5) {
        this.filecount=true;
        console.log("You are only allowed to upload 5 files");
        return;
    }
    else {
      this.filecount=false;
      for (var i = 0; i <= event.target.files.length - 1; i++) {
        var filesize = ((event.target.files[i].size / 1024) / 1024).toFixed(4);
        if (event.target.files.length < 5 && parseInt(filesize) <= 10) {
          this.filesize = false;
          var selectedFile = event.target.files[i];
          this.fileList.push(selectedFile);
          this.listOfFiles.push(selectedFile.name)
        }
        else {
          this.filesize = true;
          console.log("File size should not exceed 3 Mb")
        }
      }
    }
    this.attachment.nativeElement.value = '';
  }
  removeSelectedFile(index) {
    // Delete the item from fileNames list
    this.listOfFiles.splice(index, 1);
    // delete file from FileList
    this.fileList.splice(index, 1);
  }
  savedocs(){
    if(this.documentModuleTypeId == null)
    {
      this.isRequired = true;
      return;
    }
    if(this.fileList.length==0){
      this.snackbar.error("Please add files")
      return;
    }
    else{
    let formData = new FormData();
    for(var i in this.fileList){
      formData.append('File'+i, this.fileList[i]);
    }
    
    // formData.append('PolicyDocument', this.srcInsurancePolicy);
    formData.append('PatientInfoId', this.SelectedPatientInfoForFile);
    formData.append('DocumentModuleTypeId', this.documentModuleTypeId?.toString());

    this.spinnerservice.show();
    this.treatmentservice.postUploadDocuments(formData)
      .subscribe((event: any) => {
        this.snackbar.success(event.message);
        this.getUploadedDocumentList();
        this.spinnerservice.hide();
        this.ShowDocuments = false;

      }, (err: any) => {

        this.spinnerservice.hide();
        this.snackbar.error(err.error)

      });
    }
  }
  UploadedDocumentsDownload(item, params) {
    this.spinnerservice.show();
    if (params == 3) {
      this.ShareSucess();
    }
    item.patientAccountId=this.patientAccountId;
    const dataToEncrypt = this.commonService.DataToEncrypt(item, false);
    this.patientservice.UploadedDocumentsDownload(dataToEncrypt).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //this.OutofPktPaymentPrintdata=[];
        this.uploadedDocumentfile = res.filePath;
        if (params == 1) {
          var fileType = this.getFileExtension(this.uploadedDocumentfile);
          if ( fileType === '.doc' || fileType === '.docx' || fileType === '.ppt' || fileType === '.pptx' || fileType === '.xls' || fileType === '.xlsx') {
            // Replace certain characters in the URL with their URL-encoded versions
            var url = this.uploadedDocumentfile.replace(/\//g, '%2F')
                                               .replace(/:/g, '%3A')
                                               .replace(/\?/g, '%3F')
                                               .replace(/-/g, '%2D')
                                               .replace(/&/g, '%26')
                                               .replace(/=/g, '%3D');
            // Open the URL in a new tab using Microsoft Office viewer
            window.open(`https://view.officeapps.live.com/op/view.aspx?src=${url}`, "_blank");
          }
          // If the file extension is PDF, just open the URL in a new tab
          else {
            window.open(this.uploadedDocumentfile, "_blank");
          }
          //window.open(this.uploadedDocumentfile, '_blank');
        }
        else if (params == 0) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        } else if (params == 3) {
          this.shareFunCall(res);
        }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  ShareSucess() {
    this.spinnerservice.hide();
    this.SharePopupClose();
    this.snackbar.success("Document shared successfully");
  }
  SharePopupClose() {
    this.isShare = false;
    this.clickedItem = "";
    this.isShareDocument = true;
    this.ShareDocument.resetForm();
    if (this.isShareAll) {
      this.isShareAll = false;
    }
  }
  getFileExtension(url) {
    var fileType = (url = url.substr(1 + url.lastIndexOf("/")).split('?')[0]).substr(url.lastIndexOf("."));
    return fileType
}
shareFunCall(res) {
  this.shareDocuments = new ShareDocuments();
  this.shareDocuments.PatientAccountId = this.patientAccountId;
  this.shareDocuments.ToEmail = this.toEmail;
  this.shareDocuments.FilePath = res.filePath;
  this.shareDocuments.FileName = res.fileName;
  this.shareDocuments.DocumentType = this.documentTypeForShare;
  this.shareDocuments.PatientInfoId = this.patientId;
  this.toEmail = "";
  //const dataToEncrypt = this.commonService.DataToEncrypt(this.shareDocuments, false);
  this.patientservice.shareAppointmentDocuments(this.shareDocuments).subscribe(
    (res: any) => {
      this.ShareSucess();
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
onClickShare(item, clickedTab) {
  this.isShare = true;
  this.clickedItem = item;
  this.isShareDocument = true;
  this.clickedTab = clickedTab;
  this.isShareAll = false;
  //this.router.navigateByUrl('/messages');
}
  
}

<div class="d-flex">
  <div class="ml-auto" *ngIf="datanotExists" >
    <button type="button" mat-stroked-button [routerLink]="['/scheduler']"
      class="bg-white top-back  mt-1 mb-2 h-40 mr-2">Back</button>
  </div>
  </div>
  <div *ngIf="datanotExists" class="no-patients">
    No patient is assigned to selected therapy
    <div class="image-nopatient"> <img class="img-fluid" src="../../../../assets/images/no-patients-assigned.png"></div>
  </div>

<div class="schedule-group" *ngIf="isGroupyTherapyOld">
  <div class="d-flex met-info-top w-100">
    <i class="fas fa-users"></i>
    <div class="mr-4">Create Day Schedule for
      <span class="imp-info">{{_dtoGrpTherapyMeta?.groupTherapyName}}</span>
    </div>
    <div class="mr-4 ">
      Start Date
      <span class="imp-info"> {{_dtoGrpTherapyMeta?.startDate |date:'MM/dd/yyyy'}}</span>
    </div>
    <div class="mr-4 ">
      Start Time
      <span class="imp-info">{{_dtoGrpTherapyMeta?.startTime |date:'hh:mm a'}}</span>
    </div>
    <div class="mr-4 ">
      Duration
      <span class="imp-info">{{global.getMinutesSlot(_dtoGrpTherapyMeta?.duration)}} </span>
    </div>



    <div class="ml-auto">
      <button type="button" mat-stroked-button [routerLink]="['/scheduler']"
        class="bg-white top-back  mt-1 mb-2 h-40 mr-2">Back</button>
    </div>
  </div>
  <div class="sub-group bg-white pt-2 pb-2" *ngIf="!isNewSubgroup && isGroupyTherapyOld">
    <div class="col-md-12 text-center">
      <div class="mt-2">No Subgroup Created, Do You Want to <a (click)="onNewSubGroup()"
          class="Add-sub-btn cursor-pointer">Add</a> Subgroup?</div>
      <img class="w-25 img-fluid" src="../../../assets/images/sub-group.png" />
    </div>
  </div>
  <div class="todays-Date"><label>Date</label>,<i class="fas fa-calendar ml-2 mr-2"></i>{{selecteddate | date:'EEEE, MMMM
    d, y'}}</div>
  <div *ngIf="isNewSubgroup">

    <div class="Group-theraphy ">

      <div class="p-2 d-flex" *ngIf="!isCreateSubgroup && isSave">

        <div class="ml-auto"><button  (click)="onCreateSubGroup()" type="button" class="create-btn-group w-auto cursor-pointer">Create Sub
            Group</button></div>
      </div>

      <div class="bg-white mt-1 pb-2" *ngIf="isCreateSubgroup">
        <div class="col-md-12">
          <div class="row">
            <div class="assign-head col-md-12"> <i class="fas fa-user-plus mr-2 mt-1" *ngIf="isSave"></i>Create Sub Group
              <div class="float-right" (click)="onCloseSubGroup()">
                <!-- <a  class="link-top-subgroup">Assign Clinician</a>  -->
                <!-- <i class="fas fa-trash-alt mr-3"></i> -->
                <!-- <i class="fas fa-chevron-down"></i> -->
                <i class="fas fa-times"></i>
              </div>
            </div>
            <div class="col-md-2">
              <div class="left-kids-section">
                <h5 class="assign-head2">Patients in the Group</h5>
                <ol class="simple_with_drop vertical">
                  <div cdkDropList id="activeList" [cdkDropListData]="_dtoGrpTherapyUsers"
                    [cdkDropListConnectedTo]="[inactiveList]" class="example-list"
                    [cdkDropListEnterPredicate]="allowDrop" (cdkDropListDropped)="drop($event)">
                    <ng-container *ngFor="let item of _dtoGrpTherapyUsers;let i =index">
                      <li [cdkDragData]="item" cdkDrag>

                        <i class="fas fa-arrows-alt mr-2"></i>

                        {{item.userName}}

                      </li>
                    </ng-container>
                  </div>

                </ol>
              </div>

            </div>
            <div class="col-md-10 border-left">

              <form (ngSubmit)="dayschfrm.form.valid  && postdayschdule(dayschfrm)" id="dayschfrm" autocomplete="off"
                #dayschfrm="ngForm" novalidate>
                <div class="group-create-content">
                  <div class="group-items">

                    <div class="col-md-12">
                      <div class="row">

                        <div class="col-md-8 border-right">
                          <div cdkDropList [cdkDropListData]="_dtoAssignedUsers" id="inactiveList"
                            class="example-list chip-container pt-0" (cdkDropListDropped)="drop($event)"
                            [cdkDropListConnectedTo]="[activeList]" [cdkDropListEnterPredicate]="allowDrop">
                            <div class="kids-assigned" *ngIf="_dtoAssignedUsers?.length == 0">
                              <h5 class="assign-head2">Assign Kids </h5>
                              <div class="chip-container">
                                <div class="text-center drag-img">

                                  <p class="small-meta">(You can drag & drop from Kids list)</p>

                                  <img src="../../../assets/images/drop.png">
                                </div>

                              </div>
                              <!-- Edit this -->
                              <mat-error class="mat-form-field-subscript-wrapper" *ngIf="assignKidsError" style="padding-left: 13px;">*Please select the patients before adding the theme</mat-error>
                            </div>


                            <div class="d-flex row w-100 clinic-assign" *ngIf="_dtoAssignedUsers?.length > 0">
                              <div class="col-md-5">
                                <div class="assign-head2 text-left ">Assigned Kids</div>
                              </div>
                              <div class="col-md-1 mt-2">

                              </div>
                              <div class="col-md-5">
                                <div class="assign-head2 text-left pl-0">Assign Clinician</div>
                              </div>
                              <div class="col-md-1">

                              </div>
                            </div>
                            <div>
                              <div class="d-flex row w-100 clinic-assign" *ngFor="let item of _dtoAssignedUsers;let i=index">

                                <div class="align-self col-md-5">
                                  <div class="teachers-head">{{item.patientName}}</div>
                                </div>
                                <div class="col-md-1 mt-2">
                                  <i class="fas fa-arrow-right"></i>
                                </div>
                                <div class="clinic-lookup col-md-5">

                                  <select class="form-control" [(ngModel)]="item.clinicianId" #clinicianId="ngModel"
                                    name="clinicianId{{i}}" required>
                                    <option value="null">--Select--</option>
                                    <option *ngFor="let item of ddlPrimarystaff" [value]="item.staffId">
                                      {{item.firstName}} {{item.lastName}}
                                    </option>
                                  </select>
                                  <mat-error *ngIf="dayschfrm.submitted && clinicianId.invalid">
                                    <mat-error class="font-10" *ngIf="clinicianId.errors.required">Clinician name is
                                      required</mat-error>
                                  </mat-error>
                                </div>
                                <div class="col-md-1">
                                  <a class="cursor-pointer" (click)="onremoveassigned(item)"> <i
                                      class="far fa-times-circle mt-2 text-danger"></i></a>
                                </div>

                              </div>

                            </div>
                            <div class="font-14 text-danger">
                              <span [innerHTML]="patientExist"></span>
                              <span [innerHTML]="clinicianExist"></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 ">
                          <div class="theme-assigned">
                            <h5 class="assign-head2">Add Theme</h5>
                            <div class="form-theme">
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="col-md-12 mt--14">
                                    <mat-form-field class="w-100">
                                      <mat-label class="control-label-new">Theme Name</mat-label>
                                      <input matInput [(ngModel)]="_dtoDayScheduleSubGroup.themeName"
                                        #themeName="ngModel" name="themeName" required>
                                      <mat-error *ngIf="dayschfrm.submitted && themeName.invalid">
                                        <mat-error *ngIf="themeName.errors.required">Theme name is
                                          required</mat-error>
                                      </mat-error>
                                    </mat-form-field>

                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                      <mat-label class="control-label-new">Start Time </mat-label>
                                      <input matInput type="time" #startTime="ngModel" (change)="OnTimeChange()"
                                        [(ngModel)]="_dtoDayScheduleSubGroup.startTime" name="startTime" min="10:00"
                                        max="11:00" (focusout)="checkTime()" required>
                                      <mat-error *ngIf="dayschfrm.submitted && startTime.invalid">
                                        <mat-error *ngIf="startTime.errors.required">StartTime is
                                          required</mat-error>
                                        <mat-error *ngIf="startTime.errors?.min">min is
                                          required</mat-error>
                                      </mat-error>


                                    </mat-form-field>

                                  </div>
                                  <div class="col-md-6">
                                    <mat-form-field class="w-100">
                                      <mat-label class="control-label-new">End Time</mat-label>
                                      <input matInput type="time" #endTime="ngModel"
                                        [(ngModel)]="_dtoDayScheduleSubGroup.endTime" name="endTime"
                                        min="{{_dtoDayScheduleSubGroup.startTime}}" max="{{maxtime}}" (focusout)="check()" required>
                                      <mat-error *ngIf="dayschfrm.submitted && endTime.invalid">
                                        <mat-error *ngIf="endTime.errors.required">EndTime is
                                          required</mat-error>
                                      </mat-error>

                                    </mat-form-field>
                                    <mat-error *ngIf="dayschfrm.form.valid && endtime" class="mat-form-field-subscript-wrapper text-danger">
                                      End time should be greater than start time</mat-error>
                                  </div>


                                </div>

                                <div class="row">
                                  <div class="col-md-12">
                                    <mat-form-field class="w-100">
                                      <mat-label class="control-label-new">Location</mat-label>
                                      <mat-select #sfccMasterId="ngModel"
                                        [(ngModel)]="_dtoDayScheduleSubGroup.sfccMasterId" name="sfccMasterId" required>
                                        <mat-option *ngFor="let item of ddlSfccLocation" [value]="item.sfccMasterId">
                                          {{item.locationName}}
                                        </mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="dayschfrm.submitted && sfccMasterId.invalid">
                                        <mat-error *ngIf="sfccMasterId.errors.required">Location is
                                          required</mat-error>
                                          <br>
                                      </mat-error>
                                    </mat-form-field>

                                  </div>
                                </div>

                                <!-- Added Radio button for IOP or PCP -->
                                <div class="custom-radio" >
                                  <mat-radio-group aria-label="Select an option" #isIop="ngModel"
                                  [(ngModel)]="_dtoDayScheduleSubGroup.isIop" name="isIop" required>
                                    <div class="row">
                                      <div class="col-md">
                                        <mat-radio-button class="mr-1" (change)="chengeRadioValue('iop')" [value]="true">IOP
                                        </mat-radio-button>
                                      </div>
                                      <div class="col-md">
                                        <mat-radio-button (change)="chengeRadioValue('pcp')" [value]="false">PCP
                                        </mat-radio-button>
                                      </div>
                                    </div>
                                    <mat-error *ngIf="dayschfrm.submitted && isIop.invalid">
                                      <mat-error class="mat-form-field-subscript-wrapper text-danger" *ngIf="isIop.errors.required">Please select a field</mat-error>
                                    </mat-error>
                                  </mat-radio-group>
                                </div>

                              </div>

                              <div style="padding-left:15px ;">
                                <mat-error *ngIf="dayschfrm.form.valid && showErrorMessage" class="mat-form-field-subscript-wrapper text-danger" >
                                {{errorMessage}}
                                </mat-error>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                  <div class="col-md-12">
                    <div class="save-subgroup row" >
                      <div class="text-right col-md-12 p-0 mb-2">
                        <button type="submit" form="dayschfrm" *ngIf="roleAccess.isCreate|| roleAccess.isEdit" class="create-btn-group">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
      <div class="col-md-12 bg-white mt-2 pt-3">
        <div class="row">
          <div class="col-md-6 mb-2" *ngFor="let item of lstsubgroup;let i = index">
            <div class="card">
              <div class="card-body p-0">
                <div class="gp-schedule-list d-flex">
                  <div class="groups-name">SubGroup {{i+1}}</div>
                  <div class="time-start-end">({{item.startTime | date:'hh:mm a'}} - {{item.endTime | date:'hh:mm a'}})
                  </div>
                </div>
                <div class="cent-container p-2">
                  <div class="d-flex">
                    <div class="theme-view"><label>Theme Name</label>:<span>{{item.themeName}}
                      <span *ngIf="item.isIop"> (IOP)</span>
                      <span *ngIf="item.isPcp"> (PCP)</span>

                    </span></div>
                    <div class="theme-view"><label>Location</label>:<span>{{item.locationName}}</span></div>
                  </div>
                  <div class="col-md-12 p-0">

                    <div class="d-flex row w-100 clinic-assign">
                      <div class="col-md-5">
                        <div class="kids-name-assigned text-left ">Assigned Kids</div>
                      </div>
                      <div class="col-md-1 mt-2">
                      </div>
                      <div class="col-md-5">
                        <div class="kids-name-assigned text-left ">Assigned Clinician</div>
                      </div>
                    </div>
                    <div class="d-flex row w-100 clinic-assign"
                      *ngFor="let data of item.dtoSpDayScheduleSubgroupDetails">
                     <div class="align-self col-md-5">
                        <div class="teachers-head">{{data.userName}} </div>
                      </div>
                      <div class="col-md-1 mt-2">
                        <i class="fas fa-arrow-right"></i>
                      </div>
                      <div class="clinic-lookup col-md-5">
                        <div class="teachers-head">{{data.clinicianName}} </div>
                      </div>
                      <div class="col-md-1 p-0">
                        <a class="Assign-notes-btn mr-1" title="Assign Notes"><i class="far fa-sticky-note"></i></a>
                        <a class="Rating-star-btn mr-1" title="Five Points Rating"><i class="far fa-grin-stars"></i></a>
                        <a (click)="onDeleteDaySchedule(item,data)" class="Rating-star-btn" title="Delete schedule" ><i class="far fa-trash-alt"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>


    </div>
  </div>
</div>


<div class="theraphy-details"  *ngIf="!datanotExists">
  <div  class="col-md-12">
    <div class="d-flex  met-info-top w-100">
      <i class="fas fa-calendar-check"></i>
      <div class="top-details-info">
        <label>Schedule Date</label>
         <div *ngIf="!IsgroupTherapy">{{_dtoPatientInfo?.therapyDate | date:'MM/dd/YYYY'}}</div>
         <div *ngIf="IsgroupTherapy">{{_dtoGrpTherapydata?.ScheduleDate | date:'MM/dd/YYYY'}}</div>
      </div>
      <div class="top-details-info" >
        <label>Start Time</label>
         <div *ngIf="!IsgroupTherapy"> {{_dtoPatientInfo?.startTime | date:'hh:mm a'}}</div>
         <div *ngIf="IsgroupTherapy">{{_dtoGrpTherapydata?.TherapyStartTime | date:'hh:mm a'}}</div>
      </div>
      <div class="top-details-info">
        <label>Duration</label>
          <div class="col-md-12"><span class="details-value"  *ngIf="!IsgroupTherapy">{{_dtoPatientInfo?.duration}}</span></div>
          <div class="col-md-12"><span class="details-value"  *ngIf="IsgroupTherapy">{{global.getMinutesSlot(_dtoGrpTherapydata?.Duration)}}</span></div>
      </div>
      <div class="top-details-info" *ngIf="IsgroupTherapy">
        <label class="">Patients</label>
        <div class="small-select">
        <select   class="form-control  status-filter"
          [(ngModel)]="selectedPatient"  name="patient"  (change)="onPatientListchange(1)">
          <!-- <option value="0">All</option>  -->
          <option *ngFor="let i of drpPatients" [value]="i.PatientAccountId">{{i.PatientName}}</option>
        </select>
      </div>
    </div>
    <div class="top-details-info" *ngIf="IsgroupTherapy">
      <label class="">Theme</label>
      <div class="small-select">
      <select   class="form-control  status-filter"
        [(ngModel)]="selectedTheme"  name="patient"  (change)="onPatientListchange(2)">
        <!-- <option value="0">All</option>  -->
        <option *ngFor="let i of themeDropDown" [value]="i.DayScheduleSubGroupId">{{i.ThemeName}}</option>
      </select>
    </div>
     </div>

      <div class="ml-auto">

        <button class="bg-white top-back mt-1 mb-2 h-40 mr-2" mat-stroked-button [routerLink]="['/scheduler']" >Back</button>
      </div>
    </div>

    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-8">
        <div class=" bg-white p-2 mb-3">
          <div class="custom-head mt-1 mb-3"><h2>Patient Info</h2></div>
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                  Patient Name
                </label>
                <!-- <div class="col-md-12"><span class="details-value">{{_dtoPatientInfo?.patientName}}</span></div> -->
                <div class="col-md-12"><span class="details-value">{{(IsgroupTherapy)?_dtoGrpTherapydata?.PatientName:_dtoPatientInfo?.patientName}}</span></div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                 Age
                </label>
                <!-- <div class="col-md-12"><span class="details-value">{{_dtoPatientInfo?.age}} Yrs</span></div> -->
                <div class="col-md-12"><span class="details-value">{{(IsgroupTherapy)?_dtoGrpTherapydata?.Age:_dtoPatientInfo?.age}} Yrs</span></div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                 Email
                </label>
                <!-- <div class="col-md-12"><span class="details-value">{{_dtoPatientInfo?.email}}</span></div>  -->
                <div class="col-md-12"><span class="details-value">{{(IsgroupTherapy)?_dtoGrpTherapydata?.PatientEmail:_dtoPatientInfo?.email}}</span></div>
              </div>
            </div>
            <div class="col-md-4 mt-2">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                 Phone Number
                </label>
                <div class="col-md-12"><span class="details-value" *ngIf="!IsgroupTherapy">{{_dtoPatientInfo?.contactNo | mask: '(000) 000-0000'}}</span></div>
                <div class="col-md-12"><span class="details-value" *ngIf="IsgroupTherapy">{{_dtoGrpTherapydata?.PatientContact | mask: '(000) 000-0000'}}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class=" bg-white p-2 mb-2">
          <div class="custom-head mt-1 mb-3"><h2>Clinician Info</h2></div>
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                  Clinician Name
                </label>
                <!-- <div class="col-md-12"><span class="details-value">{{_dtoPatientInfo?.staffName}}</span></div> -->
                <div class="col-md-12"><span class="details-value">{{(IsgroupTherapy)?_dtoGrpTherapydata?.ClinicianName:_dtoPatientInfo?.staffName}}</span></div>

              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                 Email
                </label>
                <div class="col-md-12"><span class="details-value" >{{(IsgroupTherapy)?_dtoGrpTherapydata?.ClinicianEmail:_dtoPatientInfo?.staffEmail}}</span></div>

              </div>
            </div>
            <div class="col-md-4 mt-2">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                 Phone Number
                </label>
                <div class="col-md-12"><span class="details-value"  *ngIf="!IsgroupTherapy">{{_dtoPatientInfo?.staffContactNo| mask: '(000) 000-0000'}}</span></div>
                <div class="col-md-12"><span class="details-value" *ngIf="IsgroupTherapy">{{_dtoGrpTherapydata?.ClinicianContact| mask: '(000) 000-0000'}}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class=" bg-white p-2 mb-2">
          <div class="d-flex">
          <div class="custom-head mt-1 mb-3"><h2>Additional Info</h2></div>
          <div class="ml-auto">
            <a (click)="showVirtualmeet()" class="btn btn-links mr-2"  *ngIf="!IsPast && isVirtualMeet && (roleAccess.isCreate || roleAccess.isEdit)"><i class="fas fa-plus-circle mr-2"></i>Add virtual meeting link</a>
            <a (click)="showNotesClick()" class="btn btn-links mr-2" *ngIf="!IsPast && (roleAccess.isCreate||roleAccess.isEdit)"><i class="fas fa-plus-circle mr-2"></i>Add Notes</a>
          </div>
        </div>
        <div class="col-md-12 p-0"  *ngIf="isVirtualMeet">
          <div class="row">
          <div class="col-md-6 form-group ">
            <input type="text"  [(ngModel)]="virtualMeettxt" maxlength="400" placeholder="Enter virtual meeting link" class="form-control "/>
            <span class="text-xs sub-descib" *ngIf="isVmeetingErr">* Virtual meeting link is required</span>
          </div>
          <div class="col-md-6 ">
            <button class="btn primary-btn-filled float-left" *ngIf="(roleAccess.isCreate||roleAccess.isEdit) && !IsPast" (click)="saveVirtualMeet()">Save</button>
          </div>
        </div>
        </div>


        <div class="col-md-12 p-0 " *ngIf="isShowNotes ">
          <textarea cols="2" class="form-control font-italic" maxlength="400" [(ngModel)]="adminNotes"   placeholder="Enter admin  notes"></textarea>
          <span class="text-xs sub-descib">(maximum allowed character is 400 only)</span>
          <br>
          <span class="text-xs sub-descib" *ngIf="isNotesErr">* Admin notes is required</span>
          <div class="col-md-12 mt-2 mb-2 clearfix"><button class="btn primary-btn-filled float-right" *ngIf="roleAccess.isCreate||roleAccess.isEdit" (click)="saveNotes()">Save Notes</button></div>
        </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class=" bg-white p-2 mb-2">
          <div class="custom-head mt-1 mb-3"><h2>Schedule Details</h2></div>
          <div class="row">
            <div class="col-md-6 mt-1">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                  Theme Name
                </label>
                <!-- <div class="col-md-12"><span class="details-value">{{_dtoPatientInfo?.scheduleName}}</span></div> -->
                <div class="col-md-12"><span class="details-value">{{(IsgroupTherapy)?_dtoGrpTherapydata?.ThemeName:_dtoPatientInfo?.scheduleName}}</span></div>
              </div>
            </div>
            <div class="col-md-6 mt-1">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                 Location
                </label>
                <!-- <div class="col-md-12"><span class="details-value">{{_dtoPatientInfo?.locationName}}</span></div> -->
                <div class="col-md-12"><span class="details-value">{{(IsgroupTherapy)?_dtoGrpTherapydata?.LocationName:_dtoPatientInfo?.locationName}}</span></div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                 Therapy
                </label>
                <!-- <div class="col-md-12"><span class="details-value">{{_dtoPatientInfo?.therapyName}}</span></div> -->
                <div class="col-md-12"><span class="details-value">{{(IsgroupTherapy)?_dtoGrpTherapydata?.GroupTherapyName:_dtoPatientInfo?.therapyName}}</span></div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                  Schedule Date
                </label>
                 <div class="col-md-12"><span class="details-value" *ngIf="!IsgroupTherapy">{{_dtoPatientInfo?.therapyDate | date:'MM/dd/YYYY'}}</span></div>
                <div class="col-md-12"><span class="details-value" *ngIf="IsgroupTherapy">{{_dtoGrpTherapydata?.ScheduleDate | date:'MM/dd/YYYY'}}</span></div>

              </div>
            </div>
            <div class="col-md-6 mt-3">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                  Start Time
                </label>
                <div class="col-md-12"><span class="details-value" *ngIf="!IsgroupTherapy">{{_dtoPatientInfo?.startTime | date:'hh:mm a'}}</span></div>
                <div class="col-md-12"><span class="details-value" *ngIf="IsgroupTherapy">{{_dtoGrpTherapydata?.StartTime | date:'hh:mm a'}}</span></div>

              </div>
            </div>
            <div class="col-md-6 mt-3">
              <div class="row">
                <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                  Duration
                </label>
                 <div class="col-md-12"><span class="details-value"  *ngIf="!IsgroupTherapy">{{_dtoPatientInfo?.duration}}</span></div>
                 <div class="col-md-12"><span class="details-value"  *ngIf="IsgroupTherapy">{{_dtoGrpTherapydata?.DSADuration}}</span></div>
                  </div>
            </div>

            <!-- <div class="col-md-12 text-center mt-4 mb-3" *ngIf="_dtoPatientInfo?.isDeleted==false"> -->
              <div class="col-md-12 text-center mt-4 mb-3" *ngIf="((IsgroupTherapy==false && _dtoPatientInfo?.isDeleted==false )|| (IsgroupTherapy==true &&_dtoGrpTherapydata.IsDeleted==false))">
              <button  mat-stroked-button class=" btn-success mr-2" (click)="onRescheduleMS()"  *ngIf="isSave && roleAccess.isEdit && !IsPast  "><i class="fas fa-history mr-2"></i>Reschedule</button>
              <button  mat-stroked-button class="btn-delete btn-danger" (click)="ondeleteMS()" *ngIf="roleAccess.isDelete"><i  class="far fa-trash-alt mr-2"></i>Delete</button>
            </div>
          </div>
        </div>
      </div>

      </div>
    </div>


</div>


<!-- isIndividualTheapy family end-->

 <!--Delete Pop up-->
<div class="comment-popup  re-schedule-popup">
  <div class="modal" tabindex="-1" role="dialog" [style.display]="PopUpDelete? 'block':'none'">
    <div class="modal-dialog mat-dialog-container " role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h6 class="modal-title"> Delete</h6>
          <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OncloseDeletePopup()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
<div >


  <div class="col-md-12">
    <div class="row mb-2">
      <div class="col-md-4">

          <label class="mt-2 control-label-new font-weight-bold">Reason for Delete</label>
      </div>

       <div class="col-md-8">
        <select  class="form-control"  [(ngModel)]="DeleteReason" name="DeleteReason">
          <option [value]=0>Select Reason</option>
          <option *ngFor="let item of ddlDeleteReason " [value]="item.deleteMSReasonMasterId">{{item.reason}}</option>
          <!-- <option value="2">reason1</option>
          <option value="3">reason2</option>
          <option value="4">reason3</option>
          <option value="5">reason4</option>
          <option value="6">reason5</option> -->
          </select>
          <mat-error class="text-xs" *ngIf="DeleteReason==0 && DeleteMeetingscheduleclick">
            Reason for Delete is required</mat-error>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-12">
        <textarea cols="3" class="form-control font-italic" placeholder="Comments" [(ngModel)]="DeleteComments" name="DeleteComments" maxlength="300"></textarea>
      </div>
    </div>
  </div>
  <div class="text-right col-md-12 mt-2">
    <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" (click)="deleteMeetingSchedule()" >Delete</button>
    <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="OncloseDeletePopup()" >Cancel</button>
  </div>



</div>


</div>
</div>
</div>
</div>
</div>
<!--Delete pop up end-->

<!--Re-schedule pop up end-->
<div class="comment-popup  re-schedule-popup">
  <div class="modal" tabindex="-1" role="dialog" [style.display]="PopUpReschedule? 'block':'none'">
    <div class="modal-dialog mat-dialog-container " role="document">
      <div class="modal-content border-0">
        <div class="modal-header">
          <h6 class="modal-title"> Re-schedule</h6>
          <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OnCancelReschedule()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
<div >
  <form (ngSubmit)="scheduleModality.form.valid  && getConflictsModality()" id="scheduleModality"
  autocomplete="off" #scheduleModality="ngForm" novalidate>
  <div class="col-md-12">
    <div class="row mb-2">
     <div class="col-md-4">
      <label class="control-label-new font-weight-bold">Provider</label>
     </div>
       <div class="col-md-8" *ngIf="dtoScheduleModality.therapyId != Intake">
        <select class="form-control" name="Provider"
        [(ngModel)]="dtoScheduleModality.provider" #provider="ngModel" name="provider"
         >
        <option value="undefined">--Select Provider--</option>
        <option *ngFor="let a of ddlStafflist" value="{{a.staffId}}">
           {{a.firstName}} {{a.lastName}}
        </option>
     </select>
    

      </div>
      <div class="col-md-8" *ngIf="dtoScheduleModality.therapyId ==Intake">
         
        <mat-form-field class="w-100">
         <mat-label>Provider</mat-label>
          <mat-select multiple  [(ngModel)]="dtoScheduleModality.provider"
          #provider="ngModel" name="Provider" required>
               <mat-option #matOption *ngFor="let item of ddlStafflist" [value]="item.staffId">
                  {{item.firstName}} {{item.lastName}}
                </mat-option>
     </mat-select>
     </mat-form-field>
 
         </div>
    </div>
<!-- Add additional clinician and support staff Feb 01 2024 User Story 11346-->
<div class="row mb-2"  *ngIf="dtoScheduleModality.therapyId == Intake">
  <div class="col-md-4">
   <label class="control-label-new font-weight-bold">Additional Clinician</label>
  </div>
  <div class="col-md-8">
    <mat-form-field class="w-100">
<mat-label>Additional Clinician</mat-label>
 <mat-select multiple  [(ngModel)]="dtoScheduleModality.additionalprovider"
 #additionalprovider="ngModel" name="additionalprovider" required>
      <mat-option #matOption *ngFor="let item of ddlStafflistadditional" [value]="item.staffId">
         {{item.firstName}} {{item.lastName}}
       </mat-option>
</mat-select>
</mat-form-field>

</div>
 </div>
 <div class="row mb-2"  *ngIf="dtoScheduleModality.therapyId == Intake">
  <div class="col-md-4">
   <label class="control-label-new font-weight-bold">Support Staff</label>
  </div>
    <div class="col-md-8">
      <mat-form-field class="w-100">
        <mat-label>Support Staff</mat-label>
         <mat-select multiple  [(ngModel)]="dtoScheduleModality.supportstaffprovider"
         #supportstaffprovider="ngModel" name="supportstaffprovider" required>
              <mat-option #matOption *ngFor="let item of ddlSupportStafflist" [value]="item.staffId">
                 {{item.firstName}} {{item.lastName}}
               </mat-option>
    </mat-select>
    </mat-form-field>
   </div>
 </div>

    <div class="row mb-2">
      <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Start Date</label>

      </div>

       <div class="col-md-8">
        <mat-form-field class="example-full-width" >
          <mat-label>Choose a date</mat-label>
          <input required matInput [matDatepicker]="picker2" readonly
             [min]="currentdate"  (dateChange)="handleStartDatechange($event)"
             [(ngModel)]="dtoScheduleModality.startDate"
             #startDate="ngModel" name="startDate" />
          <mat-datepicker-toggle matSuffix [for]="picker2">
          </mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
          <mat-error *ngIf="scheduleModality.submitted && startDate.invalid">
             <mat-error *ngIf="startDate.errors.required">Start Date is required
             </mat-error>
          </mat-error>
       </mat-form-field>
      </div>
    </div>
    <!-- <div class="row mb-2">
      <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Start Time</label>

      </div>

       <div class="col-md-8">
        <input class="form-control" type="time" #startTime="ngModel" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
          max="24:00" required>
            <mat-error *ngIf="scheduleModality.submitted && startTime.invalid">
                <mat-error *ngIf="startTime.errors.required">Start Time is required
                </mat-error>
             </mat-error>
              <mat-error *ngIf="scheduleModality.submitted && startTime.value<currenttime">
                  Start Time is invalid
             </mat-error>
      </div>
    </div> -->
    <!-- <div class="row mb-2">
      <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Start Time </label>
      </div>
       <div class="col-md-8">
        <mat-form-field class="w-100">
        <mat-select   #startTime="ngModel" (ngModelChange)="OnTimeChange()" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
        max="12:00" required>
        <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
            {{item.slotTime | date: 'hh:mm a'}}
        </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-error class="text-xs" *ngIf="IspastTime">
    Start Time is invalid
 </mat-error>
      </div>
    </div>  -->

    <div class="row mb-2">
      <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Start Time </label>
      </div>
       <div class="col-md-8">
        <mat-form-field class="w-100">
        <mat-select   #startTime="ngModel" (ngModelChange)="OnTimeChange()" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
        max="12:00" required >
        <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
            {{item.slotTime | date: 'hh:mm a'}}
        </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-error class="text-xs" *ngIf="IspastTime">
    Start Time is invalid
 </mat-error>
      </div>
    </div> 

    <div class="row mb-2">
      <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Duration</label>

      </div>

       <!-- <div class="col-md-8">
        <select class="form-control" name="duration"
        [(ngModel)]="dtoScheduleModality.duration" #duration="ngModel" required>
        <option value="undefined">--Select Duration--</option>
        <option value=15>15 mins</option>
        <option value=30>30 mins</option>
        <option value=60>1 hr</option>
        <option value=120>2 hr</option>
        <option value=180>3 hr</option>
        <option value=240>4 hr</option>
        <option value=300>5 hr</option>
        <option value=360>6 hr</option>
        <option value=420>7 hr</option>
        <option value=480>8 hr</option>
     </select>
     <mat-error *ngIf="scheduleModality.submitted && duration.invalid">
        <mat-error class="mat-form-field-subscript-wrapper mt-3"
           *ngIf="duration.errors.required">Duration is required</mat-error>
     </mat-error>
      </div> -->

      <div class="col-md-4">
        <select class="form-control" name="duration" [(ngModel)]="dtoScheduleModality.duration" #duration="ngModel" required>
        <option value="undefined">--Select Duration--</option>
        <option value=0>0 hr</option>
        <option value=60>1 hr</option>
        <option value=120>2 hr</option>
        <option value=180>3 hr</option>
        <option value=240>4 hr</option>
        <option value=300>5 hr</option>
        <option value=360>6 hr</option>
        <option value=420>7 hr</option>
        <option value=480>8 hr</option>
     </select>
     <mat-error *ngIf="scheduleModality.submitted && (minutes==undefined || duration==undefined)">
      <mat-error class="mat-form-field-subscript-wrapper mt-3"
         *ngIf="( duration==undefined)">Hours required</mat-error>
   </mat-error>
   <!-- <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="IsDurationInvalid">
    Duration is invalid
     </mat-error> -->
      </div>
      <div class="col-md-4">
        <select class="form-control " name="minutes" [(ngModel)]="dtoScheduleModality.minutes" #minutes="ngModel" required>
          <option value="undefined">--Select mins--</option>
          <option value=0>00 mins</option>
          <option value=15>15 mins</option>
          <option value=30>30 mins</option>
          <option value=45>45 mins</option>             
        </select>
        <mat-error *ngIf="scheduleModality.submitted && (minutes==undefined || duration==undefined)">
          <mat-error class="mat-form-field-subscript-wrapper mt-3"
             *ngIf="(minutes==undefined )">Mins required</mat-error>
       </mat-error>
      </div>
    </div>
    <div class="row mb-2" *ngIf="dtoScheduleModality.therapyId != Intake">
      <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Location</label>

      </div>

       <div class="col-md-8">
        <select class="form-control" [(ngModel)]="dtoScheduleModality.location"
        #location="ngModel" name="location" required [ngClass]="{'disabled':true}">
        <option value="undefined">--Select Location--</option>
        <option *ngFor="let a of ddlSfccLocation" value="{{a.sfccMasterId}}">
           {{a.locationName}}
        </option>
     </select>
     <mat-error *ngIf="scheduleModality.submitted && location.invalid">
        <mat-error class="mat-form-field-subscript-wrapper mt-3"
           *ngIf="location.errors.required">Location
           is
           required</mat-error>
     </mat-error>
      </div>
    </div>
    <div class="row mb-2" *ngIf="dtoScheduleModality.therapyId != Intake">
      <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Meeting Room</label>

      </div>

       <div class="col-md-8">
        <select class="form-control" [(ngModel)]="dtoScheduleModality.meetingRoom"
         #meetingRoom="ngModel" name="meetingRoom" required [ngClass]="{'disabled':true}">
         <option value="undefined">--Select Location--</option>
          <option *ngFor="let a of ddlMeetinglocation" value="{{a.meetingLocationsId}}">
             {{a.meetingName}}
          </option>
         </select>
         <mat-error *ngIf="scheduleModality.submitted && meetingRoom.invalid">
           <mat-error class="mat-form-field-subscript-wrapper mt-3"
                *ngIf="meetingRoom.errors.required">Meeting Room is required</mat-error>
          </mat-error>
      </div>
    </div>
    <div class="row mb-2" *ngIf="dtoScheduleModality.therapyId != Intake">
      <div class="col-md-4">
      <label class="control-label-new font-weight-bold">Meeting Type</label>
      </div>
      <div class="col-md-8">
         <select class="form-control"  [(ngModel)]="dtoScheduleModality.meetingType" #meetingType="ngModel" name="meetingType" required>
             <option [value]=0>Select Meeting Type</option>
             <option *ngFor="let item of ddlMeetingType " [value]="item.msMeetingTypeId">{{item.meetingType}}</option>
         </select>
         <mat-error *ngIf="scheduleModality.submitted && meetingType.invalid">
          <mat-error class="mat-form-field-subscript-wrapper mt-3"
               *ngIf="meetingType.errors.required">Meeting Type is required</mat-error>
         </mat-error>
   </div>
  </div>
    <div class="row mb-2">
      <div class="col-md-12">
        <textarea cols="3" class="form-control font-italic" placeholder="Comments" [(ngModel)]="dtoScheduleModality.rescheduleComments"
        #rescheduleComments="ngModel" name="rescheduleComments" maxlength="300"></textarea>
        <!-- <mat-error *ngIf="scheduleModality.submitted && rescheduleComments.invalid">
          <mat-error class="mat-form-field-subscript-wrapper mt-3"
               *ngIf="rescheduleComments.errors.required"> Comments is required</mat-error>
         </mat-error> -->
      </div>
    </div>

  </div>
  <div class="text-right col-md-12 mt-2">
    <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2"  >Reschedule</button>
    <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="OnCancelReschedule()" >Cancel</button>
  </div>


</form>
</div>


</div>
</div>
</div>
</div>
</div>
<!--Re-schedule pop up end-->


import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { Task } from 'src/app/_models/_Common/task';
import { CalenderComponent } from 'src/app/common/pages/calender/calender.component';
import { TaskService } from 'src/app/common/pages/task/task.service';
import { MatDialog } from '@angular/material/dialog';
import { cloneDeep } from "lodash";
import * as _ from "lodash";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TaskDialogComponent } from '../../task-dialog/task-dialog.component';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
@Component({
  selector: 'app-task-info',
  templateUrl: './task-info.component.html',
  styleUrls: ['./task-info.component.scss']
})
export class TaskInfoComponent implements OnInit {
  @ViewChild('taskfrm') taskForm: NgForm;
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean'],                                       // remove formatting button


      ],

    },

    keyboard: {
      bindings: {
        shiftEnter: {
          key: 13,
          shiftKey: true,
          handler: (range, context) => {
            // Handle shift+enter
            //console.log("shift+enter")
          }
        },
        enter: {
          key: 13,
          handler: (range, context) => {
           // console.log("enter");
            return true;
          }
        }
      }
    }
  }
  @ViewChild('taskfile') eletaskfile: ElementRef;
  statusToday = constantVariables.taskStatus.Today;
  statusDue = constantVariables.taskStatus.Due;
  statusOverDue = constantVariables.taskStatus.OverDue;
  statusCompleted = constantVariables.status.completed;
  statusInprogess = constantVariables.status.inProgress;

  isValidDueDate: boolean = true;
  isValidFlagDate: boolean = true;
  isEditTask: boolean = false;
  isViewTask: boolean = false;
  isShowAddtask: boolean = false;
  taskinfo: Task;
  currentdate: any;
  UserId: any;
  lstTask: any;
  lstuser: any;
  lstTaskInfo: any;
  documentId: any;
  userName: any;
  userEmail: any;
  UploadFileList = [];
  UploadedFileList = [];
  editTaskInfo: any;
  filterToday: any = [];
  filterInprogress: any = [];
  filterComplted: any = [];
  filterAssignedtoMe: any = [];
  filterImportant: any = [];
  filterAssignedbyMe: any = [];
  activeGroupBy: number = 1;
  isView = false;
  isEdit = false;
  isDelete = false;
  isCreate = false;
  roleaccess: any;
  filterdate: any;
  viewDate: Date = new Date();
  filterweekStartDate: any;
  filterweekEndDate: any;
  filterMonth: any;
  filterMonthdisplay: any;
  meetingScheduleId: any;
  filterType: any = 1;
  events: CalendarEvent[] = [];
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  ddlPriority = constantVariables.priority;
  LocationId: any;
  isAllLocation: boolean=false;
  taskActivityLog:any;
  isActivityLogShown:boolean=false;
  isFormSubmitted:boolean=false;
  roleAccess: any;
  constructor(public global: Global, private dialog: MatDialog,private _sanitizer: DomSanitizer,
    private snackbar: SnackBarService, public taskService: TaskService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService
    ,public roleaccessservice:PageAccessService
    ) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.Task;
 this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
 this.isCreate = this.roleAccess.isCreate;
   this.isView = this.roleAccess.isView;
   this.isEdit = this.roleAccess.isEdit;
   this.isDelete = this.roleAccess.isDelete;
    //this.router.navigate(['/clinician'],{queryParams:{menu:2}});
    this.currentdate = new Date();
    this.taskinfo = new Task();
    var access = JSON.parse(localStorage.getItem('roleaccess'));
    //const pageid = constantVariables.pagelists.Task;
   // this.roleaccess = access.details.find(x => x.pageId == pageid);
    // if (this.roleaccess != null) {
    //   this.isCreate = this.roleaccess.isCreate;
    //   this.isView = this.roleaccess.isView;
    //   this.isEdit = this.roleaccess.isEdit;
    //   this.isDelete = this.roleaccess.isDelete;
    // }
    // else {
    //   this.isCreate = true;
    //   this.isView = true;
    //   this.isEdit = true;
    //   this.isDelete = true;
    // }
    this.userName = localStorage.getItem("username");
    this.userEmail = localStorage.getItem("email");
    this.UserId = Number(localStorage.getItem("id"));
    this.filterType = parseInt(this.route.snapshot.queryParamMap.get('tab'), 10);
    if (isNaN(this.filterType) || this.filterType < 0) {
      this.filterType = 1;
      this.filterdate = moment(new Date()).format('LL');
    }
    if (this.filterType == 1) {
      this.filterdate = moment(new Date()).format('LL');
    }
    if (this.filterType == 2) {
      this.filterweekStartDate = moment().startOf('week').toString();
      this.filterweekEndDate = moment().endOf('week').toString();
    }
    if (this.filterType == 3) {
      this.filterMonth = Number(moment(new Date()).format('M'));
      this.filterMonthdisplay = moment().format('MMM YYYY');
    }
    // if((localStorage.getItem('sfcclocation')) != undefined){
    // //)  
    // this.getTask();
    // this.getStaffList();
    // }
    // else{
      // if(localStorage.getItem('roleId')==constantVariables.role.PrimaryClinician 
      // || localStorage.getItem('roleId')==constantVariables.role.SupportClinician 
      // || localStorage.getItem('roleId')==constantVariables.role.Admin
      //  || localStorage.getItem('roletypeId')==constantVariables.role.AdminandClinician
      //  || localStorage.getItem('roletypeId')==constantVariables.role.Admin
      //  ){
      //   this.getStaffList();
      //   this.getTask();
      //   this.getAllTask();
      //   //this.isAllLocation=false;
      // }
      // else{
      //   //this.isAllLocation=true;
      // }
   
    //}
    if(localStorage.getItem('roleId')==constantVariables.role.PrimaryClinician 
      || localStorage.getItem('roleId')==constantVariables.role.SupportClinician 
      || localStorage.getItem('roleId')==constantVariables.role.Admin
       || localStorage.getItem('roletypeId')==constantVariables.role.AdminandClinician
       || localStorage.getItem('roletypeId')==constantVariables.role.Admin
       || localStorage.getItem('roletypeId')==constantVariables.role.PrimaryClinician
       || localStorage.getItem('roletypeId')=='null'
       ){
        this.getStaffList();
        this.getTask();
        this.getAllTask();
        //this.isAllLocation=false;
      }
      else{
        //this.isAllLocation=true;
      }
  }
  saveTaskInfo(form: NgForm) {
    var dueDate = moment(this.taskinfo.dueDate).format("MM/DD/YYYY");
    var overDate = moment(this.taskinfo.overDate).format("MM/DD/YYYY");
    var flagDate = moment(this.taskinfo.flagMeBy).format("MM/DD/YYYY");
    this.isFormSubmitted=true;
    //var ValidFlagDate = this.validateDates(dueDate,flagDate);
    var ValidFlagDate = moment(flagDate).isSameOrBefore(dueDate);

    if (!ValidFlagDate) {
      this.isValidFlagDate = false;
      return
    } else {
      this.isValidFlagDate = true;
    }
    this.taskinfo.taskPriority = Number(this.taskinfo.taskPriority);
    this.taskinfo.documents = this.UploadedFileList;
    this.spinnerservice.show();
    //const dataToEncrypt = this.commonService.DataToEncrypt(this.taskinfo, false);
    this.taskService.postTaskInfo(this.taskinfo).subscribe((res: any) => {
      if (res) {
        this.spinnerservice.hide();
        form.resetForm();
        this.resetnotesform();
        this.snackbar.success(JSON.parse(res).message);
        this.getTask();
        this.isFormSubmitted=false;
      }
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  changeRemainder(){   
    if(this.isFormSubmitted){
      var dueDate = moment(this.taskinfo.dueDate).format("MM/DD/YYYY");
      var flagDate = moment(this.taskinfo.flagMeBy).format("MM/DD/YYYY");
      var ValidFlagDate = moment(flagDate).isSameOrBefore(dueDate);
  
      if (!ValidFlagDate) {
        this.isValidFlagDate = false;
        return
      } else {
        this.isValidFlagDate = true;
      }
    }
  }
  validateDates(sDate: string, eDate: string) {
    var isValidDate = true;
    if ((sDate == null || eDate == null)) {
      isValidDate = false;
    }

    if ((sDate != null && eDate != null) && (eDate) < (sDate)) {

      isValidDate = false;
    }
    return isValidDate;
  }
  resetnotesform() {
    this.taskinfo = new Task();

    this.isEditTask = false;
    this.isShowAddtask = false;
    this.UploadFileList = [];
    this.UploadedFileList = [];
    this.isViewTask = false;

  }
  onTabChange(tab) {
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: tab,
      }
    });
    this.filterType = tab;

  }
  filterbyToday() {
    this.filterType = 1;
    this.onTabChange(this.filterType);
    this.filterdate = moment(new Date()).format('LL');
    this.getTask();
  }
  filterDayNext(event) {
    if (this.filterType == 1) {
      this.filterdate = moment(this.filterdate).add(1, 'days').format('LL');
    } else if (this.filterType == 2) {
      this.filterweekStartDate = moment(this.filterweekStartDate).add(7, 'days').format('LL');
      this.filterweekEndDate = moment(this.filterweekEndDate).add(7, 'days').format('LL');
    } else if (this.filterType == 3) {
      this.filterMonth = Number(moment(this.filterMonthdisplay).add(1, 'M').format('M'));
      this.filterMonthdisplay = moment(this.filterMonthdisplay).add(1, 'months').format('MMM YYYY');;
    }
    this.getTask();
  }
  filterDayPrev(event) {
    if (this.filterType == 1) {
      this.filterdate = moment(this.filterdate).subtract(1, 'days').format('LL');
    } else if (this.filterType == 2) {
      this.filterweekStartDate = moment(this.filterweekStartDate).subtract(7, 'days').format('LL');
      this.filterweekEndDate = moment(this.filterweekEndDate).subtract(7, 'days').format('LL');
    } else if (this.filterType == 3) {
      this.filterMonth = Number(moment(this.filterMonthdisplay).subtract(1, 'M').format('M'));
      this.filterMonthdisplay = moment(this.filterMonthdisplay).subtract(1, 'months').format('MMM YYYY');
    }
    this.getTask();
  }

  filterbyWeek() {
    this.filterType = 2;
    this.onTabChange(this.filterType);
    this.filterweekStartDate = moment().startOf('week').toString();
    this.filterweekEndDate = moment().endOf('week').toString();
    // this.filterweekStartDate = moment(new Date()).startOf('isoWeek').toString();
    // this.filterweekEndDate = moment(new Date()).endOf('isoWeek').toString();
    this.getTask();
  }
  filterbyMonth() {
    this.filterType = 3;
    this.onTabChange(this.filterType);
    this.filterMonth = Number(moment(new Date()).format('M'));
    this.filterMonthdisplay = moment().format('MMM YYYY');
    this.getTask();
  }
  getTask() {

    let _obj = {
      CurrentDate: this.filterType == 1 ? moment(this.filterdate).format("MM/DD/YYYY") : null,
      StartDate: this.filterType == 2 ? moment(this.filterweekStartDate).format("MM/DD/YYYY") : null,
      EndDate: this.filterType == 2 ? moment(this.filterweekEndDate).format("MM/DD/YYYY") : null,
      CurrentMonth: this.filterType == 3 ? this.filterMonth : null,
    }
    
    localStorage.setItem("mytasksearch", JSON.stringify(_obj));
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.taskService.getTaskInfo(dataToEncrypt).subscribe(
      (res: any) => {
        if (res) {
         this.lstTaskInfo = JSON.parse(res);
         
         this.getAllTask();
         //if(localStorage.getItem('roletypeId') != constantVariables.role.AdminandClinician){
          this.getAssignedToMe();
        // } 
         this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getAllTask() {
    this.activeGroupBy = 1;
    this.filterToday = _.reverse(_.sortBy(this.lstTaskInfo.filter(m => m.taskStatus == 1), 'updateDate'));
    this.filterInprogress = _.reverse(_.sortBy(this.lstTaskInfo.filter(m => m.taskStatus == 3), 'updateDate'));
    this.filterComplted = _.reverse(_.sortBy(this.lstTaskInfo.filter(m => m.taskStatus == 2), 'updateDate'));
    this.filterAssignedtoMe = _.reverse(_.sortBy(this.lstTaskInfo.filter(m => m.assignedTo == this.UserId ), 'updateDate'));
    //&& m.createdBy != this.UserId
    this.filterImportant = _.reverse(_.sortBy(this.lstTaskInfo.filter(m => m.taskPriority == 1), 'updateDate'));
    this.filterAssignedbyMe = _.reverse(_.sortBy(this.lstTaskInfo.filter(m => m.isSelfAssigned == true && m.createdBy == this.UserId), 'updateDate'));
  }
  getimportantTask() {
    this.activeGroupBy = 2;
    this.filterToday = _.reverse(_.sortBy(this.filterImportant.filter(m => m.taskStatus == 1), 'updateDate'));
    this.filterInprogress = _.reverse(_.sortBy(this.filterImportant.filter(m => m.taskStatus == 3), 'updateDate'));
    this.filterComplted = _.reverse(_.sortBy(this.filterImportant.filter(m => m.taskStatus == 2), 'updateDate'));
  }
  getAssignedToMe() {
    this.activeGroupBy = 3;
    this.filterToday = _.reverse(_.sortBy(this.filterAssignedtoMe.filter(m => m.taskStatus == 1), 'updateDate'));
    this.filterInprogress = _.reverse(_.sortBy(this.filterAssignedtoMe.filter(m => m.taskStatus == 3), 'updateDate'));
    this.filterComplted = _.reverse(_.sortBy(this.filterAssignedtoMe.filter(m => m.taskStatus == 2), 'updateDate'));
  }
  getAssignedbyMe() {
    this.activeGroupBy = 4;
    this.filterToday = _.reverse(_.sortBy(this.filterAssignedbyMe.filter(m => m.taskStatus == 1), 'updateDate'));
    this.filterInprogress = _.reverse(_.sortBy(this.filterAssignedbyMe.filter(m => m.taskStatus == 3), 'updateDate'));
    this.filterComplted = _.reverse(_.sortBy(this.filterAssignedbyMe.filter(m => m.taskStatus == 2), 'updateDate'));
  }
 
  getStaffList() {
    if(localStorage.getItem('roleId')==constantVariables.role.PrimaryClinician 
    || localStorage.getItem('roleId')==constantVariables.role.SupportClinician
    || localStorage.getItem('roletypeId')==constantVariables.role.AdminandClinician
    || localStorage.getItem('roletypeId')==constantVariables.role.Admin

    
    ){
        if(!!localStorage.getItem('location')){
          this.LocationId = +localStorage.getItem('location').split(',')[0].replace("\"",'').replace('"','');
        }
        else{
          this.LocationId = -1;
        }
    }
    else{
      this.LocationId = localStorage.getItem('sfcclocation');
    }
    if(!this.LocationId){
       this.LocationId=-1;
    }
    this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician,this.LocationId).subscribe(
      (res: any) => {
        if (res) {
          this.lstuser = res;

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onChangeSort(event) {
    if (event == 1) {
      this.getAllTask();
    } else if (event == 2) {
      this.getimportantTask();
    } else if (event == 3) {
      this.getAssignedToMe();
    }
  }
  onFileChange(event) {

    const reader = new FileReader();
    var _validFileExtensions = [".jpg", ".jpeg", ".png", ".pdf", ".doc", ".docx"];
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (file.size > 5020905) {
        this.snackbar.error(constantVariables.ErrorMessage.filesize)
        return
      }
      if ((Array.from(event.target.files).length + this.UploadedFileList?.length) <= 3) {
        if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "application/pdf" || file.type == "application/msword" || file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
          reader.readAsDataURL(file);

          reader.onload = () => {

            this.UploadFileList = [];
            var files = Array.from(event.target.files);
            for (var i = 0; i < files.length; i++) {

              this.UploadFileList.push(files[i]);

            }
            const frmData = new FormData();
            for (var i = 0; i < this.UploadFileList.length; i++) {
              frmData.append("doc", this.UploadFileList[i]);
            }
            frmData.append('DocumentTypeId', constantVariables.DocumentTypeId.Task);
            this.spinnerservice.show();
            this.taskService.postTaskImage(frmData).subscribe((res: any) => {

              if (res) {
                this.eletaskfile.nativeElement.value = "";
                this.spinnerservice.hide();
                if (this.UploadedFileList?.length == 0) {
                  this.UploadedFileList = res;
                } else {
                  this.UploadedFileList.push(...res);
                }

              }
            }, err => {
              this.spinnerservice.hide();
              this.snackbar.error(err.error);
            });
            this.eletaskfile.nativeElement.value = "";
          };
        } else {
          this.eletaskfile.nativeElement.value = "";
          this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
        }
      } else {
        this.eletaskfile.nativeElement.value = "";
        this.snackbar.error("Maximum 3 files allowed")
      }
    }
  }
  deleteUploadImage(index) {
    this.UploadedFileList.splice(index, 1);
    this.eletaskfile.nativeElement.value = "";
  }
  CreateTask(frm:NgForm) {
    this.isShowAddtask = !this.isShowAddtask;
    this.isValidFlagDate=true;
    this.isFormSubmitted=false;
    frm.resetForm();
  }
  onedittask(element) {
    this.isShowAddtask = true;
    this.isEditTask = true;
    this.taskinfo = cloneDeep(element);

    this.editTaskInfo = element;
    this.UploadedFileList = element.documents;
  }
  onViewtask(element) {
    this.isShowAddtask = true;
    this.isViewTask = true;
    this.taskinfo = cloneDeep(element);

    this.editTaskInfo = element;
    this.UploadedFileList = element.documents;
  }
  ondeletetask(item) {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.taskService.deleteTask(item.taskId).subscribe(
          res => {
            this.spinnerservice.hide();
            this.snackbar.success(res.message);
            this.getTask();


          },
          err => {
            this.spinnerservice.hide();
            this.snackbar.error(err.error);

          },
        );
      }
    });

  }
  downloadUploadedFile(item) {

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', item.filePath);
    link.setAttribute('download', item.filename);
    document.body.appendChild(link);
    link.click();
    link.remove();



  }
  changeTaskStatus(event, item) {
    // this.spinnerservice.show();
    if (item.statusId != null || item.statusId != "null") {
      let _obj = {
        TaskId: item.taskId,
        StatusId: Number(item.statusId)
      }

      this.taskService.postTaskStatus(_obj).subscribe((res: any) => {
        if (res) {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getTask();

        }
      },
        err => {
          this.spinnerservice.hide();
        }
      );
    }
  }

  // ondropTask(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.lstFromDetails?.details, event.previousIndex, event.currentIndex);
  //   if (event.previousIndex != event.currentIndex) {
  //     this.spinnerservice.show();
  //     const dataToEncrypt = this.commonService.DataToEncrypt(this.lstFromDetails?.details, false);
  //     this.tempService.postQuestionOrder(dataToEncrypt).subscribe(
  //       (res: any) => {
  //         this.spinnerservice.hide();
  //         //this.ddlFormName = JSON.parse(res);
  //       },
  //       err => {
  //         this.spinnerservice.hide();
  //         this.snackbar.error(err.error);
  //       },
  //     );
  //   }
  // }
  drop(event: CdkDragDrop<string[]>) {
    // if (event.previousContainer === event.container) {
    //   // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    // } else {

    this.spinnerservice.show();
    let _obj = {
      taskId: event.previousContainer.data[event.previousIndex]["taskId"],
      statusId: event.container.id == "cdkdropnew" ? 3 : event.container.id == "cdkdropinprogress" ? 27 : 30
    }
    this.changeTaskStatus(null, _obj);
    transferArrayItem(event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex);
  }
  onSelfAssign(event){
    if(event.checked){
      this.taskinfo.assignedTo = this.UserId;
    }else{
      this.taskinfo.assignedTo = null;
    }
  }
  onAssginedto(event){
    if(event.value == this.UserId){
      this.taskinfo.isSelfAssigned = true;
    }
  }
  gettaskcontent(data): SafeHtml{
    return this._sanitizer.bypassSecurityTrustHtml(data);
  }
  openDialog(data) {
    const dialogRef = this.dialog.open(TaskDialogComponent,
      { data: { parentinfoId: 0, taskId : data.taskId} });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  oncompletetask(element){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.CompleteConfirmMessage
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        element.statusId=30;
      this.changeTaskStatus(null,element)
      }
    });

  }
  showActivityLog(item){
    this.taskService.getTaskActivityLog(item).subscribe(res=>{
      this.taskActivityLog=res;
      this.isActivityLogShown=true;          
    })
  }
}

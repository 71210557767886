import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { LeadService } from 'src/app/patient-lead/lead.service'
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MenuList, RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
declare function decrypt(data): any;
declare function EncryptFieldData(data): any;

@Component({
  selector: 'app-lead-kanban',
  templateUrl: './lead-kanban.component.html',
  styleUrls: ['./lead-kanban.component.scss']
})
export class LeadKanbanComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  public searchpatientListtxt : string;
  lstLeadkanban: any = [];
  DecrptedlstLead: any = [];
  DecrptedlstLeadspc : any =[];
  DecrptedlstLeadsbenifit : any = [];  
  DecrptedlstLeadsfee : any = [];  
  DecrptedlstLeadstour : any = []; 
  DecrptedlstLeadsintake : any = []; 
  defaultRecords: any = 5;
  statusResendActivationMail = constantVariables.status.ResendActivationMail;
  statusUploadInsurance = constantVariables.status.UploadInsurance;
  statusReviewPacket = constantVariables.status.ReviewPacket;
  statusReturn = constantVariables.status.Return;
  statusVerified = constantVariables.status.Verified;
  statusCrbaUpdate = constantVariables.status.CrbaUpdate;
  statusFinancial=constantVariables.status.financial;
  statusintake=constantVariables.status.forIntake;
  statusreviewTour=constantVariables.status.reviewTour;
  statusdecline=constantVariables.status.Decline;
  statusstaffAssigned=constantVariables.status.staffAssigned;
  statusRequestTour=constantVariables.status.requestTour;
  MenuAccess: MenuList;
  roleAccess: RoelAccess;
  isPopup: boolean = false;
  isEditUsers : boolean =true;
  oldEmail : any;
  resendactivationemail:any;
  isPrimaryEmailChanged:boolean=false;
  parentFirstName: any;
  financialDenyReason: any;
  isFinancialDecline: boolean = false;
  subjectTag = "";
  constructor(public leadservice: LeadService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, public commonService: CommonService, 
    public spinnerservice: NgxSpinnerService, public roleaccessservice:PageAccessService
    ) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.PatientAccount;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.MenuAccess = this.roleaccessservice.GetSiteHeaderAccess();
    this.getLeadlistView();
    localStorage.setItem("profiletab", "1");
  }
  // onPageChange($event) {    
  //   this.lstLead = this.DecrptedlstLead.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  // }
  getLeadlistView() {
    this.spinnerservice.show();
    this.leadservice.getLeadKanbanView().subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.lstLeadkanban = res;
         this.DecrptedlstLeadspc = res.pointOfContact; 
         this.DecrptedlstLeadsbenifit = res.benefits;  
         this.DecrptedlstLeadsfee = res.fee;  
         this.DecrptedlstLeadstour = res.tour;  
         this.DecrptedlstLeadsintake = res.intake;  
 
       console.log(this.lstLeadkanban)
      },
      err => {

      },
    );

  }
  OnSearchPatient() {

    if (this.searchpatientListtxt!="" || this.searchpatientListtxt === undefined || this.searchpatientListtxt === null) {
      // let tempPatientlist = [...this.DecrptedlstLead?.pointOfContact]
      // let tempbenefitlist = [...this.DecrptedlstLead?.benefits]
      // let tempfee = [...this.DecrptedlstLead?.fee]
      // let temptour = [...this.DecrptedlstLead?.tour]
      // let tempintake = [...this.DecrptedlstLead.intake]

      this.lstLeadkanban.pointOfContact = this.DecrptedlstLeadspc.filter(
        (x: any) =>
          x.firstName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
          x.lastName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
          x.email?.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
      this.lstLeadkanban.benefits = this.DecrptedlstLeadsbenifit.filter(
        (x: any) =>
          x.firstName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
          x.lastName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
          x.email?.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
      this.lstLeadkanban.fee = this.DecrptedlstLeadsfee.filter(
        (x: any) =>
          x.firstName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
          x.lastName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
          x.email?.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
      this.lstLeadkanban.tour = this.DecrptedlstLeadstour.filter(
        (x: any) =>
          x.firstName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
          x.lastName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
          x.email?.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
      this.lstLeadkanban.intake = this.DecrptedlstLeadsintake.filter(
        (x: any) =>
          x.firstName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
          x.lastName.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()) ||
          x.email?.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
      //this.lstLead = tempPatientlist.filter((x: any) =>x.Email?.toLowerCase().includes(this.searchpatientListtxt.toLowerCase()));
    }
    else {
      this.lstLeadkanban.pointOfContact = this.DecrptedlstLeadspc;
      this.lstLeadkanban.benefits = this.DecrptedlstLeadsbenifit;
      this.lstLeadkanban.fee = this.DecrptedlstLeadsfee;
      this.lstLeadkanban.tour = this.DecrptedlstLeadstour;
      this.lstLeadkanban.intake = this.DecrptedlstLeadsintake;

      //this.getLeadlistView()
    }
  }
  onIntakeSchedule(item) {
    if(this.MenuAccess.ScheduleIntake){
      localStorage.setItem("immediateActionId", item.immediateActionId);
      let obj = {
        name: item.firstName,
        patientAccountId: item.patientAccountId,
        pageid: 1,
        age:moment(item.dob, "DD-MM-YYYY").diff(moment(), 'years'),
        gender: item.genderType,
        location:item.sfccMasterId
  
      }
      this.router.navigate(['/intake-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(obj) } });
    }
    else{
      this.snackbar.error(constantVariables.SuccessMessage.AccessPermissionError)
    }
    
  }
  
  onResendActivation(item) {
    this.spinnerservice.show();
    this.isPopup = true;
    this.isEditUsers = false;
    this.resendactivationemail = item;
    this.oldEmail = this.resendactivationemail.email;
    this.spinnerservice.hide();
  }
  onResendActivationEmail(resendactivationemail){
    if(resendactivationemail.email != this.oldEmail){
      this.isPrimaryEmailChanged = true;
    }
   let _obj = {
       PatientInfoId: resendactivationemail.patientInfoId,
     //  Email: resendactivationemail.ParentList[0].Email
      Email: resendactivationemail.email,
      OldEmail :this.oldEmail,
      IsPatient : true,
      }
     this.leadservice.postResendActivation(_obj).subscribe(
       (res: any) => {
         this.resendactivationemail = res.Email;
        this.snackbar.success(res)
         this.spinnerservice.hide();
         this.isPopup = false;
 
      },
       err => {
        this.spinnerservice.hide();
      },
     );
}
SharePopupClose(){
  this.isPopup = false;
}
  onPatientdetailview(id) {
    this.router.navigate(['/leaddetailview'], { queryParams: { id: this.commonService.FrontEndEncryption(id),PageId:2 } });
  }
  onUpdateCRBA(id) {
    this.router.navigate(['/crba'], { queryParams: { id: this.commonService.FrontEndEncryption(id) } });
  }
  onSubmitFinancial(item) {
    var parentList = item.parentList.filter(x => x.isPrimaryContact == true)[0];
    if (parentList) {
     this.parentFirstName =parentList.firstName;
    }
    else {
     // this.parentFirstName = item.FirstName;
     this.parentFirstName =item.ParentName;
    }
    //let parentListItem = item.parentList.filter(x=>x.isPrimaryContact == true)[0];
    //this.parentFirstName =parentListItem?.firstName;
    this.router.navigate(['/financial-proposal'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientInfoId), name: this.commonService.FrontEndEncryption(item.firstName), parentname: this.commonService.FrontEndEncryption(this.parentFirstName) } });
  }
  onRequestTour(item) {
    this.router.navigate(['/request-tour'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientInfoId), name: this.commonService.FrontEndEncryption(item.firstName) } });
  }
  onPatientinfo(id) {
    this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(id), isAdminClicked :this.commonService.FrontEndDecryption(true) } });
  }
  closeDeclineProcess() {
    this.isFinancialDecline = false;
  }
  
  getfinancialReason(item) {
    this.leadservice.getfinancialReason(item.patientInfoId).subscribe(
      (res: any) => {

        this.spinnerservice.hide();
        this.isFinancialDecline = true;
        this.financialDenyReason = "";
        this.financialDenyReason = res.reason;

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  openComposeMail(email) {
    // this.closeEmailPopup = true;
    this.global.clearEmaillist();
    this.global.onOpenEmailPopup(email);
  }

}

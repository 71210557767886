import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { constantVariables } from 'src/app/constant/constant';
import * as _ from "lodash";
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { MatTableDataSource } from '@angular/material/table'; // for table
import { MatPaginator } from '@angular/material/paginator';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { MenuList, RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { FLAGS } from 'html2canvas/dist/types/dom/element-container';
import { TemplateService } from 'src/app/common/pages/template/template.service';
import { TreatmentNotesComponent } from '../treatment-notes/treatment-notes.component';


@Component({
  selector: 'app-treatmentplan-list',
  templateUrl: './treatmentplan-list.component.html',
  styleUrls: ['./treatmentplan-list.component.scss']
})
export class TreatmentplanListComponent implements OnInit {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('attachments') attachment: any;
  fileList: File[] = [];
  listOfFiles: any[] = [];
  dataSource: MatTableDataSource<any>;
  statusinProgress = constantVariables.status.inProgress;
  DecrptedlstTreatment: any;
  lstTreatment: any;
  roleId: any;
  roleFront = constantVariables.role.FrontDesk;
  roleAdmin = constantVariables.role.Admin;
  ShowActivity: boolean = false;
  ShowDocuments: boolean = false;
  clinicianHistory: any;
  paginatorVariable: number = 20;
  srchTreatmnPlanpatientListtxt: any;
  roleAccess: RoelAccess;
  MenuAccess: MenuList;
  filesize:any;
  filecount:any;
  SelectedPatientInfoForFile: any;
  ddlDocumentType : any;
  documentModuleTypeId : number = 0;
  isRequired : boolean = false; 
  isShowCalenderView:boolean =false;

  constructor(public global: Global, public treatmentservice: TreatmentService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public roleaccessservice: PageAccessService,
    public tempService: TemplateService) { }

  ngOnInit(): void {
    this.router.navigate(['/clinician'], { queryParams: { menu: 3 } });
    this.global.ismytask = false;
    this.roleId = Number(localStorage.getItem("roleId"));
    //  console.log(!!(localStorage.getItem("paginator")));
    if (!!(localStorage.getItem("paginator"))) {
      this.paginatorVariable = +(localStorage.getItem("paginator"));
    } else {
      this.paginatorVariable = 20;
    }
    const pageid = constantVariables.pagelists.TreatmentPlan;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.MenuAccess = this.roleaccessservice.GetSiteHeaderAccess();


    this.getTreatmentPlanList();
    this.getDocumentModuleType();
  }
  getDocumentModuleType()
  {
    this.tempService.getDocumentModuleType().subscribe(
      res => {
        if (res != null) {
          this.ddlDocumentType = res;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }
  getTreatmentPlanList() {

    this.spinnerservice.show();

    this.treatmentservice.getTreatmentPlanList(0).subscribe(
      (res: any) => {
        this.spinnerservice.hide();

        

        this.DecrptedlstTreatment = res;
        this.lstTreatment = this.DecrptedlstTreatment.slice(0, this.paginatorVariable);
        this.dataSource = new MatTableDataSource(this.DecrptedlstTreatment);
        this.dataSource.paginator = this.paginator;
        this.paginator.pageSize = this.paginatorVariable;
      },
      err => {
        this.spinnerservice.hide();

      },
    );

  }
  onPageChange($event) {
    this.lstTreatment = this.DecrptedlstTreatment.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
    this.paginator.pageSize = $event.pageSize;
    this.paginatorVariable = $event.pageSize;
    localStorage.setItem('paginator', $event.pageSize)
  }
  onchangeFilter(event) {

    if (event != "Any") {
      this.lstTreatment = _.filter(this.DecrptedlstTreatment, item => item.status === event);
      this.lstTreatment = this.lstTreatment.slice(0, 5);
      // console.log(this.lstTreatment);
      this.dataSource.paginator = this.paginator;
      this.dataSource = new MatTableDataSource(this.lstTreatment);
    } else {
      this.lstTreatment = this.DecrptedlstTreatment;
      this.lstTreatment = this.lstTreatment.slice(0, 5);
      this.dataSource = new MatTableDataSource(this.lstTreatment);
      this.dataSource.paginator = this.paginator;
    }
  }
  onNutritional(item) {
    this.global.ismytask = false;
    this.router.navigate(['/intake-nutritional'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId) } });
  }
  onGenerateIntake(item) {
    this.global.ismytask = false;
    this.router.navigate(['/intake-generate'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), patientid: this.commonService.FrontEndEncryption(item.patientInfoId), isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } });
  }
  onintakechma(item) {
    this.global.ismytask = false;
    this.router.navigate(['/intake-chma'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), patientid: this.commonService.FrontEndEncryption(item.patientInfoId), isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } });
  }

  onPainAssessment(item) {
    this.router.navigate(['/pain-assessment'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), isChma: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId) } });
  }

  onScheduleModality(item) {
    this.router.navigate(['/schedule-modality'], { queryParams: { id: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId), account: this.commonService.FrontEndEncryption(item.patientAccountId), patient: this.commonService.FrontEndEncryption(item.patientInfoId), treatmentPlanDate: item.treatmentPlanDate } });
  }
  ontreatmenthistory(item) {
    this.router.navigate(['/treatment-history'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), location: this.commonService.FrontEndEncryption(item.sfccMasterId) } });
  }
  onTreatmentPlan(item) {
    this.router.navigate(['/leaddetailview'],{queryParams:{id:this.commonService.FrontEndEncryption(item.patientInfoId),PageId:3}});
    // this.router.navigate(['/treatmentplan'], { queryParams: { id: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId), account: this.commonService.FrontEndEncryption(item.patientAccountId), patient: this.commonService.FrontEndEncryption(item.patientInfoId), location: this.commonService.FrontEndEncryption(item.sfccMasterId) } });
  }
  onEditTreatmentPlan(item){
    localStorage.removeItem('TreatmentplanProvider');
    this.router.navigate(['/treatmentplan'], { queryParams: { id: this.commonService.FrontEndEncryption(item.chmaTreatmentSummaryId), account: this.commonService.FrontEndEncryption(item.patientAccountId), patient: this.commonService.FrontEndEncryption(item.patientInfoId), location: this.commonService.FrontEndEncryption(item.sfccMasterId) } });
  }
  getDocuments(item) {
    this.fileList=[];
    this.listOfFiles = [];
    this.documentModuleTypeId = null;
    this.isRequired = false;
    this.ShowDocuments = true;
    this.SelectedPatientInfoForFile = item.patientInfoId;
  }
  closepopup(){
    this.filesize=false;
    this.filecount=false;
    this.ShowDocuments = false;
    return;
  }
  getActivityLog(item) {
    this.ShowActivity = true;
    // console.log(item);
    this.treatmentservice.getClinicianActivityLog(item.patientInfoId).subscribe(
      (res: any) => {
        // console.log(res);
        this.clinicianHistory = res;
      }
    )
  }
  onCloseActivitylog() {
   
    this.ShowActivity = false;
    this.clinicianHistory = null;
  }


  OnSuicidalRiskAssessment(item) {
    this.global.ismytask = false;
    this.router.navigate(['/sucidal-risk'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId) } });
  }
  OnSearchPatient() {
    let tempPatientlist = this.DecrptedlstTreatment
    if (this.srchTreatmnPlanpatientListtxt != "" || this.srchTreatmnPlanpatientListtxt === undefined || this.srchTreatmnPlanpatientListtxt === null) {
      this.lstTreatment = tempPatientlist.filter((x: any) => x.patientName.toLowerCase().indexOf(this.srchTreatmnPlanpatientListtxt.toLowerCase()) !== -1);
    }
    else {
      // this.lstTreatment = tempPatientlist.slice(0, 5);
      this.lstTreatment = this.DecrptedlstTreatment.slice(0, this.paginatorVariable);
      this.dataSource = new MatTableDataSource(this.DecrptedlstTreatment);
      this.dataSource.paginator = this.paginator;
      this.paginator.pageSize = this.paginatorVariable;
    }

  }

  onFileChanged(event: any) {
    if (this.fileList && this.fileList.length >= 5) {
        this.filecount=true;
        console.log("You are only allowed to upload 5 files");
        return;
    }
    else {
      this.filecount=false;
      for (var i = 0; i <= event.target.files.length - 1; i++) {
        var filesize = ((event.target.files[i].size / 1024) / 1024).toFixed(4);
        if (event.target.files.length < 5 && parseInt(filesize) <= 10) {
          this.filesize = false;
          var selectedFile = event.target.files[i];
          this.fileList.push(selectedFile);
          this.listOfFiles.push(selectedFile.name)
        }
        else {
          this.filesize = true;
          console.log("File size should not exceed 3 Mb")
        }
      }
    }
    this.attachment.nativeElement.value = '';
  }
  removeSelectedFile(index) {
    // Delete the item from fileNames list
    this.listOfFiles.splice(index, 1);
    // delete file from FileList
    this.fileList.splice(index, 1);
  }
  savedocs(){
    if(this.documentModuleTypeId == null)
    {
      this.isRequired = true;
      return;
    }
    if(this.fileList.length==0){
      this.snackbar.error("Please add files")
      return;
    }
    else{
    let formData = new FormData();
    for(var i in this.fileList){
      formData.append('File'+i, this.fileList[i]);
    }
    
    // formData.append('PolicyDocument', this.srcInsurancePolicy);
    formData.append('PatientInfoId', this.SelectedPatientInfoForFile);
    formData.append('DocumentModuleTypeId', this.documentModuleTypeId?.toString());

    this.spinnerservice.show();
    this.treatmentservice.postUploadDocuments(formData)
      .subscribe((event: any) => {
        this.snackbar.success(event.message);
        this.spinnerservice.hide();
        this.ShowDocuments = false;

      }), (err: any) => {

        this.spinnerservice.hide();
        this.snackbar.error(err.error)

      };
    }
  }

  selectItem:any;
 
// getNotes(item) {
//   this.selectItem =item
//  this.isShowCalenderView=true;
//   }
getNotes(item) {
  this.dialog.open(TreatmentNotesComponent,{
    data: { allrecord: item.patientInfoId} ,
    width:"80%",
    height:"70%",
  })

  }

  
//  onmeetingnotes(item) {
//   this.router.navigate(['/phone-schoolmeet-progressnotes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), location: this.commonService.FrontEndEncryption(item.sfccMasterId) } });
// }

onmeetingnotes(item) {
  localStorage.setItem('patientAccountIdT',item.patientAccountId);
    this.router.navigate(['/treatment-meeting-notes'], { queryParams: { id: this.commonService.FrontEndEncryption(item.patientAccountId), location: this.commonService.FrontEndEncryption(item.sfccMasterId) } });
  }

}

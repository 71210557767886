
import {
  Component,
  ChangeDetectorRef, ChangeDetectionStrategy,
  ViewChild,
  OnInit, ElementRef, Input
} from '@angular/core';

import { Subject } from 'rxjs';

// import {
//   CalendarEvent, CalendarView, CalendarDayViewBeforeRenderEvent, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent,
// } from 'angular-calendar';
import { Router, ActivatedRoute } from '@angular/router';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import * as moment from 'moment';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { colors } from 'src/app/_models/_Patient/_ScheduleAppoinment';
import RRule from 'rrule';
import { ViewPeriod } from 'calendar-utils';
import { DatePipe } from '@angular/common';
import { cloneDeep } from "lodash";
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CalendarEvent, CalendarView, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { AdminTherapyPopUpObj } from 'src/app/_models/_Treatment/_TherapyPopUpObj';

interface RecurringEvent {
  title: string;
  color: any;
  end?: any;
  id?: any;
  tags?: any;
  scheduledDate?: any;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
    dtstart: any;
    until: any;
    interval?: any;
    

  };
}


@Component({
  selector: 'app-admin-day-view-schedule',
  templateUrl: './admin-day-view-schedule.component.html',
  styleUrls: ['./admin-day-view-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush

})

export class AdminDayViewScheduleComponent implements OnInit {
  //@Input() selectedClinician: number
  //@Input() dtoAppointmentType: number;
 @Input() date_view:any;
  dtoAppointmentType: any;
  selectedClinician: any;

  @ViewChild('picker', { static: true }) _picker: MatDatepicker<Date>;
  @ViewChild('btndayview') btndayview: ElementRef<HTMLElement>;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate = moment().toDate();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  showBlockTimePopup:boolean=false;

  dtoMeeting; any;
  isShowMoreEvent = false;
  EVENT_LIMIT = 4;
  moreTaskEvents: any = [];
  filterType: any;
  recurringEvents: RecurringEvent[] = [];
  viewPeriod: ViewPeriod;
  appointments: any = [];
  drpClinicians: any = [];

  allAppointments: any = [];
  isAllLocation: boolean = false;

  ScheduleFirstviewDate: Date;
  users = [];
  tmpusers = [];
  userlstcopy = [];
  public scheduleOptionFirstDate: any;
  currentdate;
  locationId = 0;
  tmpappointmentFilterdata = [];
  PickedDate: any;
  //Therapy pop 
  ObjTherapyPopUp: AdminTherapyPopUpObj;
  isShowTherapydetails: boolean;
  isShowScheduleModality :boolean =false;
  constructor(public global: Global, public settingsService: SettingsService, private cdr: ChangeDetectorRef,
    private snackbar: SnackBarService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog, public commonService: CommonService, public clinicianservice: ClinicianService, public spinnerservice: NgxSpinnerService, public treatmentservice: TreatmentService,
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    //this.dtoAppointmentType = 1;
    // this.selectedClinician=0;
    this.currentdate = new Date();
    if(this.date_view) {
      this.viewDate = moment(this.date_view).toDate()
    }
    this.PickedDate = moment(this.viewDate).toDate();
    //this.PickedDate = new Date(moment(this.viewDate).format('MM/DD/YYYY'))
    //  this.route.queryParams.subscribe((params) => {
    //   if (Object.keys(params).length != 0) {
    //     this.dtoAppointmentType = Number(this.commonService.FrontEndDecryption(params.appointmentId));
    //     this.selectedClinician= Number(this.commonService.FrontEndDecryption(params.clinicanId));
    //       }
    // });


    this.commonService.adminDayViewDrpChange.subscribe((res) => {
      this.loadAllAppointments();

    });

    this.commonService.TherapyPopup.subscribe((res) => {
      this.OncloseTherapyDetailspopup();

    });
    this.commonService.ScheduleModality.subscribe((res)=>{
      this.OnCloseScheduleModality();
      
   });
    setTimeout(() => { this.btndayview.nativeElement.click(); }, 200);
    // this.loadAllAppointments();
    // this.dtoAppointmentType=1;
    // this.selectedClinician=347;

  }

  setView(view: CalendarView) {
    this.view = view;
  }
  OnCloseScheduleModality(){
    this.ObjTherapyPopUp = undefined
    this.isShowScheduleModality=false;
   // window.location.reload();
  }
  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.refresh.next();

  }
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
    //this.PickedDate = moment(this.viewDate).toDate();
    this.PickedDate = new Date(moment(this.viewDate).format('MM/DD/YYYY'));
    this.loadAllAppointments();
  }
  loadAllAppointments() {
    this.spinnerservice.show();
    this.drpClinicians = [];
    this.allAppointments = [];
    this.appointments = [];
    this.users = [];
    this.tmpappointmentFilterdata = [];
    //this.selectedClinician=localStorage.getItem("adminDayViewClinician");
    this.selectedClinician = (localStorage.getItem("adminDayViewClinician") == "0" || localStorage.getItem("adminDayViewClinician") == null) ? -1 : localStorage.getItem("adminDayViewClinician");
    this.dtoAppointmentType = localStorage.getItem("adminDayViewAppointment");

    let tmpappointmntId;
    if (this.dtoAppointmentType == "1" || this.dtoAppointmentType == "undefined") {
      tmpappointmntId = 0;
    }
    else { tmpappointmntId = this.dtoAppointmentType; }
    this.commonService.getIntakeAppointmentsAdminDayView(tmpappointmntId, this.datepipe.transform(this.viewDate, "yyyy-MM-dd"), this.selectedClinician, localStorage.getItem('id'),localStorage.getItem('sfcclocation')).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this.allAppointments = [];
          this.allAppointments = res;
          //  this.allAppointments=(Number(localStorage.getItem('sfcclocation'))>0)?this.allAppointments.filter(x=>x.locationId==localStorage.getItem('sfcclocation')):
          //                       this.allAppointments ;
          this.allAppointments = (Number(localStorage.getItem('sfcclocation')) > 0) ? this.allAppointments.filter(x => x.locationId == localStorage.getItem('sfcclocation') || x.locationId == "A") :
            this.allAppointments;
          this.appointments = this.allAppointments;
          this.appointments = (this.selectedClinician != -1) ? this.appointments.filter(x => x.clinicianId == this.selectedClinician) :
            this.allAppointments;

          if (this.dtoAppointmentType == 1) {
            this.tmpappointmentFilterdata = this.appointments;
          } else if (this.dtoAppointmentType == 6) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.typeId == 2 && x.templateId == 3);
          }
          else if (this.dtoAppointmentType == 2) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.templateId == 1 && x.typeId == 1);
          }
          else if (this.dtoAppointmentType == 4) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.templateId == 1 && x.typeId == 2);
          }
          else if (this.dtoAppointmentType == 5) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.templateId == 1 && x.typeId == 3);
          }
          else if (this.dtoAppointmentType == 6) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.templateId == 3);
          }
          else if (this.dtoAppointmentType == 7) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.templateId == 1 && x.typeId == 5);
          }
          else if (this.dtoAppointmentType == 8) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.templateId == 1 && x.typeId == 8);
          }
          else if (this.dtoAppointmentType == 9) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.templateId == 1 && x.typeId == 9);
          }
          else if (this.dtoAppointmentType == 11) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.templateId == 1 && x.typeId == 11);
          }
          else if (this.dtoAppointmentType == 12) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.templateId == 1 && x.typeId == 12);
          }
          else if (this.dtoAppointmentType == 13) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.templateId == 1 && x.typeId == 13);
          }
          else if (this.dtoAppointmentType == 14) {
            this.tmpappointmentFilterdata = this.appointments.filter(x => x.templateId == 3 && x.typeId == 200);
          }
          let tmplist = this.tmpappointmentFilterdata;
          // if (this.tmpappointmentFilterdata.length > 0) {

            if (this.selectedClinician == -1 ) {
              this.loadAllClinician(false);
            }
            else {
              if(this.appointments.length ==0){
                this.loadAllClinician(true);
              }else{
                let tmpdrp = (this.selectedClinician != -1) ? this.appointments : this.allAppointments;
                this.drpClinicians = tmpdrp.filter(
                  (t2, i, arr) => arr.findIndex(t => t.clinicianId === t2.clinicianId && t.clinicianId != 0) === i);
                this.loadAppointmentsClinician();
              }
             
            }



            console.log(this.drpClinicians);

          //}
          // else {
          //   this.spinnerservice.hide();
          //   this.router.navigate(['/scheduler']);
          //   //this.snackbar.error("Data does not exisits");

          // }
        }//res null
        else {
          this.spinnerservice.hide();
          this.router.navigate(['/scheduler']);
          this.snackbar.error("Data does not exisits");

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  loadAllClinician(isClinicianExist)
  {
    this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician, localStorage.getItem('sfcclocation')).subscribe(
      (resp: any) => {
        if (resp) {
          var temp = resp
          var temp1 = [];
          temp.map(x => {
            var _obj = {
              clinicianId: x.staffId,
              clinicianName: x.firstName
            }
            temp1.push(_obj);
          })
          if(isClinicianExist){
            this.drpClinicians =temp1.filter(x=>x.clinicianId == +this.selectedClinician);
          }else{
            this.drpClinicians = temp1;
          }
          console.log(this.drpClinicians);
          this.loadAppointmentsClinician();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  hourSegmentClicked(hour): void {
    let currentDate = new Date();
   if((this.datepipe.transform(currentDate.setDate(currentDate.getDate() - 2), "yyyy-MM-dd"))> (this.datepipe.transform(hour.date, "yyyy-MM-dd")))
   {
     this.snackbar.error("Cannot schedule a meeting in past");
     return;
   }
   //console.log('hourClicked Hour', hour);
  
  
   this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
   this.ObjTherapyPopUp.id = 0;
   this.ObjTherapyPopUp.start = hour.date;
   this.ObjTherapyPopUp.scheduleDate = hour.date;
   this.ObjTherapyPopUp.fromtime = hour.date;  
   this.ObjTherapyPopUp.SfccMasterId = Number(localStorage.getItem('sfcclocation'));
   if(this.selectedClinician == "-1")
   {
    const col = hour.sourceEvent.target.closest(".cal-day-column");
    const colIndex = [...col.parentElement.children].indexOf(col);
    this.ObjTherapyPopUp.ClinicianId =this.users[colIndex].id;
   }else{
    this.ObjTherapyPopUp.ClinicianId = this.selectedClinician;
   }
   this.isShowScheduleModality = true;
}
  loadAppointmentsClinician() {
    if (this.appointments != null ) {
      let res = this.appointments;
      this.events = [];
      for (let i = 0; i < this.drpClinicians.length; i++) {
        this.users.push({
          id: this.drpClinicians[i].clinicianId,
          name: this.drpClinicians[i].clinicianName,
          type: 1
        })
      }
    }
    this.getDayViewDataAppointmentLists();
  }
  getDayViewDataAppointmentLists() {
    this.spinnerservice.show();
    let res = this.tmpappointmentFilterdata;
      this.spinnerservice.hide();
      this.events = [];
      this.tmpusers = this.users;
      if (res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          var Userslst = this.tmpusers.filter(x => x.id == res[i].clinicianId)
          if (Userslst.length > 0) {
            let thrpyid = 0;
            if (res[i].typeId == 2) {
              thrpyid = constantVariables.therapy.IndividualTheraphy;
            }
            else if (res[i].typeId == 3) {
              thrpyid = constantVariables.therapy.FamilyTheraphy;
            }
            else if (res[i].typeId == 5) {
              thrpyid = constantVariables.therapy.PsycatricTheraphy;
            }
            else if (res[i].typeId == 8) {
              thrpyid = constantVariables.therapy.PsycatricEvaluation;
            }
            else if (res[i].typeId == 9) {
              thrpyid = constantVariables.therapy.PsycatricMedication;
            }
            else if (res[i].typeId == 0) {
              thrpyid = constantVariables.therapy.Tour;
            }
            else if (res[i].typeId == 1) {
              thrpyid = constantVariables.therapy.Intake;
            }
            else if (res[i].typeId == 11) {
              thrpyid = constantVariables.therapy.Phonecall;
            }
            else if (res[i].typeId == 10) {
              thrpyid = constantVariables.therapy.School;
            }
            else if (res[i].typeId == 12) {
              thrpyid = constantVariables.therapy.Speech;
            }
            else if (res[i].typeId == 13) {
              thrpyid = constantVariables.therapy.Nutrition;
            }
            //  else  if(res[i].typeId!=1 && res[i].typeId!=2 && res[i].typeId!=3 )
            else if (res[i].typeId == 100) {
              thrpyid = constantVariables.therapy.GroupTheraphy;
            }
            else if (res[i].typeId == 17) {
              thrpyid = constantVariables.therapy.BlockTimeScheduler;
            }
            let currentDate = new Date();
 
            if(res[i].isRecurrance == 1 && res[i].frequency == 6){
              let monthRecurrence = this.getSpecificWeekday(new Date(res[i].assignDate), new Date(res[i].releaseDate), res[i].weekDaysOnMonth,res[i].weekOfMonth);
              if(moment(monthRecurrence).format("MM/DD/YYYY") == moment(this.viewDate).format("MM/DD/YYYY"))
              {
                this.events.push({
                  start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                  end: (res[i].scheduleEndDate == null) ? new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                  //title: res[i].appointmentName,
                  title : (thrpyid == 3) ? ((moment(res[i].scheduleDate).format("MM/DD/YYYY") == moment(currentDate).format("MM/DD/YYYY") )||(new Date(res[i].scheduleDate) > currentDate))  ?  res[i].appointmentName.replace("{{DS}}","ADD DS") : res[i].appointmentName.replace("{{DS}}","") : res[i].appointmentName,
                  color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
                  cssClass: 'event-anchor',
                  id: res[i].appointmentId,
              
                  meta: {
                    user: Userslst[0],
                  },
                  status: constantVariables.lblMessage.Exist,
                  isnew: false,
                  tags: thrpyid,
                })
              }
             
           
            }else if(res[i].isRecurrance == 1 && res[i].frequency == 5){
              const currentDate = new Date(this.viewDate)
              if( ((res[i].isMonday == 1 && currentDate.getDay() === 1 ) || (res[i].isTuesday == 1 && currentDate.getDay() === 2) || (res[i].isWednesday == 1 && currentDate.getDay() === 3) || (res[i].isThursday == 1 && currentDate.getDay() === 4) || (res[i].isFriday == 1 && currentDate.getDay() === 5) || (res[i].isSaturday == 1 && currentDate.getDay() === 6) || (res[i].isSunday == 1 && currentDate.getDay() === 0)))
              {
                this.events.push({
                  start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                  end: (res[i].scheduleEndDate == null) ? new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                  //title: res[i].appointmentName,
                  title : (thrpyid == 3) ? ((moment(res[i].scheduleDate).format("MM/DD/YYYY") == moment(currentDate).format("MM/DD/YYYY") )||(new Date(res[i].scheduleDate) > currentDate))  ?  res[i].appointmentName.replace("{{DS}}","ADD DS") : res[i].appointmentName.replace("{{DS}}","") : res[i].appointmentName,
                  color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
                  cssClass: 'event-anchor',
                  id: res[i].appointmentId,
                  rrule: {
                    freq: RRule.DAILY,
                    dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                    until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                   // interval: 1
                   //byweekday: ((res[i].isRecurrance == 1 && res[i].frequency == 5) ? [(res[i].isMonday == 1 ? RRule.MO : ''),(res[i].isTuesday == 1 ? RRule.TU : ''),(res[i].isWednesday == 1 ? RRule.WE : ''),(res[i].isThursday == 1 ? RRule.TH : ''),(res[i].isFriday == 1 ? RRule.FR : ''),(res[i].isSunday == 1 ? RRule.SU : ''),(res[i].isSaturday == 1 ? RRule.SA : '')] :''),
                  },
                  meta: {
                    user: Userslst[0],
                  },
                  status: constantVariables.lblMessage.Exist,
                  isnew: false,
                  tags: thrpyid,
                })
              }
             
            }else if(res[i].isRecurrance == 1 && res[i].frequency == 4)
            {
              this.events.push({
                start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                end: (res[i].scheduleEndDate == null) ? new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                //title: res[i].appointmentName,
                title : (thrpyid == 3) ? ((moment(res[i].scheduleDate).format("MM/DD/YYYY") == moment(currentDate).format("MM/DD/YYYY") )||(new Date(res[i].scheduleDate) > currentDate))  ?  res[i].appointmentName.replace("{{DS}}","ADD DS") : res[i].appointmentName.replace("{{DS}}","") : res[i].appointmentName,
                color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
                cssClass: 'event-anchor',
                id: res[i].appointmentId,
                rrule: {
                  freq: RRule.DAILY,
                  dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                  until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                 // interval: 1
                },
                meta: {
                  user: Userslst[0],
                },
                status: constantVariables.lblMessage.Exist,
                isnew: false,
                tags: thrpyid,
              })

            
            }else if(res[i].isRecurrance == 0 || res[i].isRecurrance == null){
              {
                this.events.push({
                  start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                  end: (res[i].scheduleEndDate == null) ? new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                  //title: res[i].appointmentName,
                  title : (thrpyid == 3) ? ((moment(res[i].scheduleDate).format("MM/DD/YYYY") == moment(currentDate).format("MM/DD/YYYY") )||(new Date(res[i].scheduleDate) > currentDate))  ?  res[i].appointmentName.replace("{{DS}}","ADD DS") : res[i].appointmentName.replace("{{DS}}","") : res[i].appointmentName,
                  color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
                  cssClass: 'event-anchor',
                  id: res[i].appointmentId,
                  rrule: {
                    freq: RRule.DAILY,
                    dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                    until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                   // interval: 1
                  },
                  meta: {
                    user: Userslst[0],
                  },
                  status: constantVariables.lblMessage.Exist,
                  isnew: false,
                  tags: thrpyid,
                })
              }
            }
        



          }

        }
        this.refresh.next();
        ////Clinican without Appointment
        let tmpevents = this.events;
        //// let arr1 = res;
        let arr1 = this.appointments;
        let arr2 = tmpevents;
        const filterByReference = (arr1, tmpevents) => {
          let res = [];
          res = arr1.filter(el => {
            return !tmpevents.find(element => {
              return element.id === el.appointmentId;
            });
          });
          return res;
        }
        let filteredValues = filterByReference(arr1, tmpevents);
        this.tmpusers = this.users;
        for (let j = 0; j < filteredValues.length; j++) {
          var tmpusr = this.tmpusers.find(x => x.id == filteredValues[j].clinicianId);
          if (tmpusr.length > 0)
          
          {
            this.events.push({
              start: new Date(moment(filteredValues[j].scheduleDate).format("MM/DD/YYYY") + " " + moment(filteredValues[j].fromTime).format("HH:mm")),
              end: (filteredValues[j].scheduleEndDate == null) ? new Date(moment(filteredValues[j].scheduleDate).format("MM/DD/YYYY") + " " + moment(filteredValues[j].toTime).format("HH:mm a")) : new Date(moment(filteredValues[j].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(filteredValues[j].toTime).format("HH:mm")),
              title: filteredValues[j].appointmentName,
              color: {
                primary: res[j].colorCode,
                secondary: res[j].colorCode
              },
              cssClass: 'event-anchor',
              id: filteredValues[j].appointmentId,
              meta: {
                user: tmpusr[0],
              },
              isnew: true
            })
          }
        }

      }
    this.spinnerservice.hide();
    this.pushDayViewDataToCalendar();
  }
  private getSpecificWeekday(startDate: Date, endDate: Date, weekday: number, occurrence: number): Date {
    const start = new Date(moment(this.viewDate).format("MM/DD/YYYY")) 
    const year = start.getFullYear();
  const month =  start.getMonth() + 1;
  weekday = weekday == 7 ? 0 : weekday;

  let resultDay;
   
  if(occurrence != 5){
    let currentDay = 1;
    let count = 0;
    
    while (count < occurrence) {
      const currentDate = new Date(year, month-1, currentDay);
  
      if (currentDate.getDay() === weekday) { 
        count++;
        if (count === occurrence) {
          resultDay = currentDate;
        }
      }
  
      currentDay++;
    }


  }else{
   // Calculate the first day of next month
   const firstDayOfNextMonth = new Date(start.getFullYear(), start.getMonth() , 1);

   // Calculate the last day of next month
   const lastDayOfNextMonth = new Date(start.getFullYear(), start.getMonth()+1 , 0);

   // Find the day of the week for the last day of next month
   let lastDayOfWeek = lastDayOfNextMonth.getDay();

   // Calculate the difference between the last day's index and Friday (assuming Sunday is 0)
   let difference = (lastDayOfWeek + 6 - (weekday-1)) % 7;

   // Subtract the difference from the last day of next month to get the last Friday
   resultDay = new Date(
     lastDayOfNextMonth.getFullYear(),
     lastDayOfNextMonth.getMonth(),
     lastDayOfNextMonth.getDate() - difference
   );

  }
  return resultDay;
}
  pushDayViewDataToCalendar() {
    let day1event = this.events.filter(x => x.status == constantVariables.lblMessage.Exist)
    for (let i = 0; i < day1event.length; i++) {
      const objIndex = this.events.findIndex(obj => obj.meta.user.id == day1event[i].meta.user.id && moment(obj.start).isSame(moment(day1event[i].start)) && obj.isnew == true);
      if (objIndex > -1) {
        this.events.splice(objIndex, 1);
      }
    }
    this.refresh.next();
    this.spinnerservice.hide();
  }


  handleMoreEvent(e: any, events: CalendarEvent[]) {
    this.moreTaskEvents = events;
    this.isShowMoreEvent = !this.isShowMoreEvent;
  }
  // handleEvent(action: string, event: CalendarEvent): void {
  //   //   if (event.tags == constantVariables.therapy.GroupTheraphy || event.tags ==constantVariables.therapy.IndividualTheraphy || event.tags ==constantVariables.therapy.FamilyTheraphy 
  //   //     ||event.tags == constantVariables.therapy.PsycatricTheraphy || event.tags ==constantVariables.therapy.PsycatricEvaluation || event.tags ==constantVariables.therapy.PsycatricMedication 
  //   //     )
  //   //   {
  //   //   this.router.navigate(['/day-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id), date: this.commonService.FrontEndEncryption(moment(event.start).format('MM/DD/YYYY'))
  //   //   ,theraphyid: this.commonService.FrontEndEncryption(event.tags)
  //   //  } });
  //   // }
  //   if (event.tags == constantVariables.therapy.GroupTheraphy) {

  //     this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
  //     let GrpTherapylstForFilter = this.appointments
  //     if (GrpTherapylstForFilter.filter(m => m.groupTherapyId == event.id).length > 0) {
  //       let currentEvent = GrpTherapylstForFilter.filter(m => m.groupTherapyId == event.id)[0];
  //       this.ObjTherapyPopUp.id = currentEvent.groupTherapyId;
  //       this.ObjTherapyPopUp.start = event.start;
  //       this.ObjTherapyPopUp.scheduleDate = event.start;
  //       this.ObjTherapyPopUp.fromtime = currentEvent.startTime;
  //       this.ObjTherapyPopUp.tags = event.tags;
  //       this.ObjTherapyPopUp.ScheduleName = currentEvent.groupTherapyName;
  //       this.ObjTherapyPopUp.clinicianContactNo = currentEvent.clinicianContact;
  //       this.ObjTherapyPopUp.clinicianName = currentEvent.clinicianName;
  //       this.ObjTherapyPopUp.clinicianemail = currentEvent.clinicianEmail;
  //       this.ObjTherapyPopUp.ClinicianId = this.selectedClinician;
  //       this.ObjTherapyPopUp.DayScheduleSubGroupId = currentEvent.dayScheduleSubGroupId;
  //       this.ObjTherapyPopUp.GroupTherapyId = currentEvent.groupTherapyId;
  //       this.ObjTherapyPopUp.DayScheduleAssignId = currentEvent.dayScheduleAssignId;
  //       this.ObjTherapyPopUp.SfccMasterId = currentEvent.sfccMasterId;
  //       this.ObjTherapyPopUp.Duration = currentEvent.duration;
  //       this.ObjTherapyPopUp.endTime = currentEvent.endTime;
  //       this.ObjTherapyPopUp.ImmediateActionId = currentEvent.immediateActionId;
  //       //this.ObjTherapyPopUp.Location = +localStorage.getItem('location');
  //       let duration = (currentEvent.duration >= 60 ? Math.floor(currentEvent.duration / 60).toString() + " hr" : "") + (currentEvent.duration % 60 > 0 ? " " + (currentEvent.duration % 60).toString() + " min" : "")
  //       // let duration = (currentEvent.duration > 60 ? ((currentEvent.duration / 60).toString() + " hr") : "") + (currentEvent.duration % 60 > 0 ? ((currentEvent.duration % 60).toString() + " min") : "")
  //       this.ObjTherapyPopUp.ScheduleDuration = duration
  //       //this.isShowMoreEvent = false;
  //       this.spinnerservice.show();
  //       this.treatmentservice.GetGroupCount(currentEvent.groupTherapyId, moment(event.start).format("MM/DD/YYYY")).subscribe((res: any) => {
  //         this.spinnerservice.hide();
  //         this.ObjTherapyPopUp.GroupTherapyLength = res;
  //         this.isShowTherapydetails = true;
  //       });
  //     }
  //   }
  //  else if ( event.tags == constantVariables.therapy.IndividualTheraphy || event.tags == constantVariables.therapy.FamilyTheraphy
  //     || event.tags == constantVariables.therapy.Tour || event.tags == constantVariables.therapy.PsycatricTheraphy || event.tags == constantVariables.therapy.PsycatricEvaluation || event.tags == constantVariables.therapy.PsycatricMedication
  //     || event.tags == constantVariables.therapy.Intake || event.tags == constantVariables.therapy.Phonecall || event.tags == constantVariables.therapy.School || event.tags == constantVariables.therapy.Speech || event.tags == constantVariables.therapy.Nutrition) {
  //     this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
  //     let MeetingDatafilter = this.appointments
  //     if (MeetingDatafilter.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd"))).length > 0) {
  //       let currentEvent = MeetingDatafilter.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd")));

  //       this.ObjTherapyPopUp.id = currentEvent[0].appointmentId;
  //       this.ObjTherapyPopUp.start = event.start;
  //       this.ObjTherapyPopUp.scheduleDate = event.start;
  //       this.ObjTherapyPopUp.fromtime = currentEvent[0].fromTime;
  //       this.ObjTherapyPopUp.tags = event.tags;
  //       this.ObjTherapyPopUp.ScheduleName = currentEvent[0].scheduleName;
  //       this.ObjTherapyPopUp.clinicianContactNo = currentEvent[0].clinicianContact;
  //       this.ObjTherapyPopUp.clinicianName = currentEvent[0].clinicianName;
  //       this.ObjTherapyPopUp.clinicianemail = currentEvent[0].clinicianEmail;
  //       this.ObjTherapyPopUp.patientName = currentEvent[0].patientName;
  //       this.ObjTherapyPopUp.AdminNotes = currentEvent[0].adminNotes;
  //       this.ObjTherapyPopUp.email = currentEvent[0].patientEmail;
  //       this.ObjTherapyPopUp.contactNo = currentEvent[0].patientContact;
  //       this.ObjTherapyPopUp.ScheduleTimeId = currentEvent[0].meetingScheduleTimeId;
  //       this.ObjTherapyPopUp.MeetingRoom = currentEvent[0].meetingRoom;
  //       this.ObjTherapyPopUp.Location = currentEvent[0].locationId;
  //       this.ObjTherapyPopUp.Duration = currentEvent[0].duration;
  //       this.ObjTherapyPopUp.patientAccountId = currentEvent[0].patientAccountId;
  //       this.ObjTherapyPopUp.isPatientVisit = currentEvent[0].isPatientVisist
  //       this.ObjTherapyPopUp.ClinicianId = currentEvent[0].clinicianId;
  //       this.ObjTherapyPopUp.DayScheduleSubGroupId = currentEvent[0].dayScheduleSubGroupId;
  //       this.ObjTherapyPopUp.GroupTherapyId = currentEvent[0].groupTherapyId;
  //       this.ObjTherapyPopUp.OffSiteAddress = currentEvent[0].offSiteAddress;
  //       this.ObjTherapyPopUp.ImmediateActionId = currentEvent[0].immediateActionId;
  //       this.ObjTherapyPopUp.ScheduleDuration = currentEvent[0].meetingScheduleDuration;
  //       // let duration = (currentEvent[0].duration >= 60 ? ((currentEvent[0].duration / 60).toString() + " hr") : "") + (currentEvent[0].duration % 60 > 0 ? ((currentEvent[0].duration % 60).toString() + " min") : "")
  //       // this.ObjTherapyPopUp.ScheduleDuration = duration
  //       if ((this.datepipe.transform(new Date(), "yyyy-MM-dd")) <= (this.datepipe.transform(event.start, "yyyy-MM-dd"))) {
  //         this.ObjTherapyPopUp.MeetingLink = currentEvent[0].meetingLink;
  //       }
  //       else {
  //         this.ObjTherapyPopUp.MeetingLink = null;
  //       }
  //       this.ObjTherapyPopUp.MeetingType = currentEvent[0].meetingTypeId;
  //       if(currentEvent[0].groupTherapyId != 0 && currentEvent[0].groupTherapyId != undefined)
  //       {
  //         this.spinnerservice.show();
  //         this.treatmentservice.GetGroupCount(currentEvent[0].groupTherapyId,moment(event.start).format("MM/DD/YYYY")).subscribe((res: any)  => {
  //           this.spinnerservice.hide();
  //           this.ObjTherapyPopUp.GroupTherapyLength=res;
  //           this.isShowTherapydetails = true;
  //         });
  //       }else{
  //         this.isShowTherapydetails = true;
  //       }
       
  //     }
  //   }
  // }
  handleEvent(action: string, event: CalendarEvent): void {

    if (event.tags == constantVariables.therapy.GroupTheraphy) {

      this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
      let GrpTherapylstForFilter = this.appointments
      if (GrpTherapylstForFilter.filter(m => m.groupTherapyId == event.id).length > 0) {
        let currentEvent = GrpTherapylstForFilter.filter(m => m.groupTherapyId == event.id)[0];
        this.ObjTherapyPopUp.id = currentEvent.groupTherapyId;
        this.ObjTherapyPopUp.start = event.start;
        this.ObjTherapyPopUp.scheduleDate = event.start;
        this.ObjTherapyPopUp.fromtime = currentEvent.startTime;
        this.ObjTherapyPopUp.tags = event.tags;
        this.ObjTherapyPopUp.ScheduleName = currentEvent.groupTherapyName;
        this.ObjTherapyPopUp.clinicianContactNo = currentEvent.clinicianContact;
        this.ObjTherapyPopUp.clinicianName = currentEvent.clinicianName;
        this.ObjTherapyPopUp.clinicianemail = currentEvent.clinicianEmail;
        this.ObjTherapyPopUp.ClinicianId = this.selectedClinician;
        this.ObjTherapyPopUp.DayScheduleSubGroupId = currentEvent.dayScheduleSubGroupId;
        this.ObjTherapyPopUp.GroupTherapyId = currentEvent.groupTherapyId;
        this.ObjTherapyPopUp.DayScheduleAssignId = currentEvent.dayScheduleAssignId;
        this.ObjTherapyPopUp.SfccMasterId = currentEvent.sfccMasterId;
        this.ObjTherapyPopUp.Duration = currentEvent.duration;
        this.ObjTherapyPopUp.endTime = currentEvent.endTime;
        this.ObjTherapyPopUp.ImmediateActionId = currentEvent.immediateActionId;
        //this.ObjTherapyPopUp.Location = +localStorage.getItem('location');
        let duration = (currentEvent.duration >= 60 ? Math.floor(currentEvent.duration / 60).toString() + " hr" : "") + (currentEvent.duration % 60 > 0 ? " " + (currentEvent.duration % 60).toString() + " min" : "")
        // let duration = (currentEvent.duration > 60 ? ((currentEvent.duration / 60).toString() + " hr") : "") + (currentEvent.duration % 60 > 0 ? ((currentEvent.duration % 60).toString() + " min") : "")
        this.ObjTherapyPopUp.ScheduleDuration = duration
        //this.isShowMoreEvent = false;
        this.spinnerservice.show();
        this.treatmentservice.GetGroupCount(currentEvent.groupTherapyId, moment(event.start).format("MM/DD/YYYY")).subscribe((res: any) => {
          this.spinnerservice.hide();
          this.ObjTherapyPopUp.GroupTherapyLength = res;
          this.isShowTherapydetails = true;
        });
      }
    }
    else if (event.tags == constantVariables.therapy.IndividualTheraphy || event.tags == constantVariables.therapy.FamilyTheraphy
      || event.tags == constantVariables.therapy.PsycatricTheraphy || event.tags == constantVariables.therapy.PsycatricEvaluation || event.tags == constantVariables.therapy.PsycatricMedication
      || event.tags == constantVariables.therapy.Tour || event.tags == constantVariables.therapy.Intake || event.tags == constantVariables.therapy.Tour || event.tags == constantVariables.therapy.Phonecall
      || event.tags == constantVariables.therapy.School || event.tags == constantVariables.therapy.Speech || event.tags == constantVariables.therapy.Nutrition|| event.tags == constantVariables.therapy.BlockTimeScheduler)
       {
      this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
      let MeetingDatafilter = this.appointments;
      var start = moment(event.start).format('HH:mm') + ":00" ;
      var end = moment(event.end).format('HH:mm') + ":00" ;
      if (MeetingDatafilter.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd"))).length > 0) {
        // let currentEvent = MeetingDatafilter.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd")));

        let currentEvent = MeetingDatafilter.filter(m => m.appointmentId == event.id && (moment(m.fromTime).format('HH:mm') + ":00" ) == start && (moment(m.toTime).format('HH:mm') + ":00" )== end
        && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd")));

        this.ObjTherapyPopUp.id = currentEvent[0].appointmentId;
        this.ObjTherapyPopUp.start = event.start;
        this.ObjTherapyPopUp.scheduleDate = event.start;
        this.ObjTherapyPopUp.fromtime = currentEvent[0].fromTime;
        this.ObjTherapyPopUp.tags = event.tags;
        this.ObjTherapyPopUp.ScheduleName = currentEvent[0].scheduleName;
        this.ObjTherapyPopUp.clinicianContactNo = currentEvent[0].clinicianContact;
        this.ObjTherapyPopUp.clinicianName = currentEvent[0].clinicianName;
        this.ObjTherapyPopUp.clinicianemail = currentEvent[0].clinicianEmail;
        this.ObjTherapyPopUp.patientName = currentEvent[0].patientName;
        this.ObjTherapyPopUp.AdminNotes = currentEvent[0].adminNotes;
        this.ObjTherapyPopUp.email = currentEvent[0].patientEmail;
        this.ObjTherapyPopUp.contactNo = currentEvent[0].patientContact;
        this.ObjTherapyPopUp.ScheduleTimeId = currentEvent[0].meetingScheduleTimeId;
        this.ObjTherapyPopUp.MeetingRoom = currentEvent[0].meetingRoom;
        this.ObjTherapyPopUp.Location = currentEvent[0].locationId;
        this.ObjTherapyPopUp.Duration = currentEvent[0].duration;
        this.ObjTherapyPopUp.patientAccountId = currentEvent[0].patientAccountId;
        this.ObjTherapyPopUp.isPatientVisit = currentEvent[0].isPatientVisist
        this.ObjTherapyPopUp.ClinicianId = this.selectedClinician;
        this.ObjTherapyPopUp.OffSiteAddress = currentEvent[0].offSiteAddress;
        this.ObjTherapyPopUp.AppointmentId = currentEvent[0].appointmentId;
        this.ObjTherapyPopUp.ImmediateActionId = currentEvent[0].immediateActionId;
        this.ObjTherapyPopUp.Status = currentEvent[0].status;
        this.ObjTherapyPopUp.ScheduleDuration = currentEvent[0].meetingScheduleDuration;
        this.ObjTherapyPopUp.SfccMasterId = Number(currentEvent[0].locationId);
        
        if ((this.datepipe.transform(new Date(), "yyyy-MM-dd")) <= (this.datepipe.transform(event.start, "yyyy-MM-dd"))) {
          this.ObjTherapyPopUp.MeetingLink = currentEvent[0].meetingLink;
        }
        else {
          this.ObjTherapyPopUp.MeetingLink = null;
        }
        this.ObjTherapyPopUp.MeetingType = currentEvent[0].meetingTypeId;
        this.isShowTherapydetails = true;
      }
    }



  }
  eventClicked(event: CalendarEvent): void {
    if ((this.datepipe.transform(new Date(), "yyyy-MM-dd")) < (this.datepipe.transform(event.start, "yyyy-MM-dd"))) {
      this.snackbar.error("Upcoming dates selections are restricted");
      return;
    }
    //   if (event.tags == constantVariables.therapy.GroupTheraphy || event.tags ==constantVariables.therapy.IndividualTheraphy || event.tags ==constantVariables.therapy.FamilyTheraphy 
    //     ||event.tags == constantVariables.therapy.PsycatricTheraphy || event.tags ==constantVariables.therapy.PsycatricEvaluation || event.tags ==constantVariables.therapy.PsycatricMedication 
    //     )
    //   {
    //   this.router.navigate(['/day-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(event.id), date: this.commonService.FrontEndEncryption(moment(event.start).format('MM/DD/YYYY'))
    //   ,theraphyid: this.commonService.FrontEndEncryption(event.tags)
    //  } });
    // }
    if (event.tags == constantVariables.therapy.GroupTheraphy || event.tags == constantVariables.therapy.IndividualTheraphy || event.tags == constantVariables.therapy.FamilyTheraphy
      || event.tags == constantVariables.therapy.PsycatricTheraphy || event.tags == constantVariables.therapy.PsycatricEvaluation || event.tags == constantVariables.therapy.PsycatricMedication
      || event.tags == constantVariables.therapy.Tour || event.tags == constantVariables.therapy.Intake || event.tags == constantVariables.therapy.Phonecall || event.tags == constantVariables.therapy.School || event.tags == constantVariables.therapy.Speech || event.tags == constantVariables.therapy.Nutrition) {
      this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
      let MeetingDatafilter = this.appointments
      if (MeetingDatafilter.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd"))).length > 0) {
        let currentEvent = MeetingDatafilter.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd")));

        this.ObjTherapyPopUp.id = currentEvent[0].appointmentId;
        this.ObjTherapyPopUp.start = event.start;
        this.ObjTherapyPopUp.scheduleDate = event.start;
        this.ObjTherapyPopUp.fromtime = currentEvent[0].fromTime;
        this.ObjTherapyPopUp.tags = event.tags;
        this.ObjTherapyPopUp.ScheduleName = currentEvent[0].scheduleName;
        this.ObjTherapyPopUp.clinicianContactNo = currentEvent[0].clinicianContact;
        this.ObjTherapyPopUp.clinicianName = currentEvent[0].clinicianName;
        this.ObjTherapyPopUp.clinicianemail = currentEvent[0].clinicianEmail;
        this.ObjTherapyPopUp.patientName = currentEvent[0].patientName;
        this.ObjTherapyPopUp.AdminNotes = currentEvent[0].adminNotes;
        this.ObjTherapyPopUp.email = currentEvent[0].patientEmail;
        this.ObjTherapyPopUp.contactNo = currentEvent[0].patientContact;
        this.ObjTherapyPopUp.ScheduleTimeId = currentEvent[0].meetingScheduleTimeId;
        this.ObjTherapyPopUp.MeetingRoom = currentEvent[0].meetingRoom;
        this.ObjTherapyPopUp.Location = currentEvent[0].locationId;
        this.ObjTherapyPopUp.Duration = currentEvent[0].duration;
        this.ObjTherapyPopUp.patientAccountId = currentEvent[0].patientAccountId;
        this.ObjTherapyPopUp.isPatientVisit = currentEvent[0].isPatientVisist
        this.ObjTherapyPopUp.ClinicianId = currentEvent[0].clinicianId;
        this.ObjTherapyPopUp.DayScheduleSubGroupId = currentEvent[0].dayScheduleSubGroupId;
        this.ObjTherapyPopUp.GroupTherapyId = currentEvent[0].groupTherapyId
        this.ObjTherapyPopUp.OffSiteAddress = currentEvent[0].offSiteAddress;
        this.ObjTherapyPopUp.ImmediateActionId = currentEvent[0].immediateActionId;
        this.ObjTherapyPopUp.ScheduleDuration = currentEvent[0].meetingScheduleDuration;
        // let duration = (currentEvent[0].duration >= 60 ? ((currentEvent[0].duration / 60).toString() + " hr") : "") + (currentEvent[0].duration % 60 > 0 ? ((currentEvent[0].duration % 60).toString() + " min") : "")
        // this.ObjTherapyPopUp.ScheduleDuration = duration
        if ((this.datepipe.transform(new Date(), "yyyy-MM-dd")) <= (this.datepipe.transform(event.start, "yyyy-MM-dd"))) {
          this.ObjTherapyPopUp.MeetingLink = currentEvent[0].meetingLink;
        }
        else {
          this.ObjTherapyPopUp.MeetingLink = null;
        }
        this.ObjTherapyPopUp.MeetingType = currentEvent[0].meetingTypeId;
        if(currentEvent[0].groupTherapyId != 0 && currentEvent[0].groupTherapyId != undefined)
        {
          this.spinnerservice.show();
          this.treatmentservice.GetGroupCount(currentEvent[0].groupTherapyId,moment(event.start).format("MM/DD/YYYY")).subscribe((res: any)  => {
            this.spinnerservice.hide();
            this.ObjTherapyPopUp.GroupTherapyLength=res;
            this.isShowTherapydetails = true;
          });
        }else{
          this.isShowTherapydetails = true;
        }
      }
    }
  }
  onCreateDaySch(id, event) {
    this.router.navigate(['/day-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(id), date: this.commonService.FrontEndEncryption(moment(event.start).format('MM/DD/YYYY')) } });
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    //  console.log( moment(event.target.value).toDate());
    this.viewDate = moment(event.target.value).toDate()
    let lsDate = new Date(event.target.value);
    var dsDate = new Date(event.target.value);
    dsDate.setMinutes( dsDate.getMinutes() + dsDate.getTimezoneOffset() );
    this.viewDate =dsDate;
    //this.viewDate = new Date(lsDate.getFullYear(), lsDate.getMonth(), lsDate.getDate(), lsDate.getHours(), lsDate.getMinutes() - lsDate.getTimezoneOffset());
    //this.PickedDate = new= Date(moment(event.target.value).format('MM/DD/YYYY'))
    this.activeDayIsOpen = false;
    this.loadAllAppointments();
  }

  OncloseTherapyDetailspopup() {
    this.ObjTherapyPopUp = undefined
    this.isShowTherapydetails = false;
    // window.location.reload();
  }
}





<div class="consent-page">
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedTab" (selectedTabChange)="onTabChange($event.index)">
        <mat-tab label="Consent Templates" *ngIf="isView">
            <div class="top-btn-section clearfix mb-3 col-md-12 d-none">
                <h2 class="head-section mb-2 mt-0">Consent Templates</h2>

            </div>
            <div class="col-md-12 mt-3">
                <div class="row">
                    <div class="col-md-7 ">
                        <div class="row ">
                            <div class="col-md-6">
                                <div class="search">
                                    <input type="text" [(ngModel)]="templateNameSch" class="search-input"
                                        placeholder="Search By Template Name" name="">
                                    <a (click)='searchTempName(0)' class="search-icon"> <i class="fa fa-search"></i>
                                    </a>
                                    <a (click)='searchTempName(1)' *ngIf="templateNameSch !=''"
                                        class="search-icon mr-1"> <i class="fa fa-times"></i> </a>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="search">
                                    <input type="text" [(ngModel)]="templateCatagorySch" class="search-input"
                                        placeholder="Search By Template Category" name="">
                                    <a (click)='searchTempCat(0)' class="search-icon"> <i class="fa fa-search"></i> </a>
                                    <a (click)='searchTempCat(1)' *ngIf="templateCatagorySch !=''"
                                        class="search-icon mr-1"> <i class="fa fa-times"></i> </a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-5">
                        <button *ngIf="isDelete" class="btn primary-btn-outlined float-right"
                            [disabled]="templateids?.length == 0" (click)="ondeletemultipletemp()"><i
                                class="fas fa-trash mr-2"></i>Delete
                            Template</button>
                        <button *ngIf="isCreate" type="button" [routerLink]="['/consent']"
                            class="btn primary-btn-outlined float-right mr-2"><i class="fas fa-plus-circle mr-2"></i>Add
                            Template</button>

                    </div>
                </div>
            </div>
            <div class="section-consent">

                <div class="list-template">
                    <ul class="col-md-12 nomargin">
                        <li *ngFor="let item of lsttemplates;let i=index" class="col-md-12">
                            <div class="card">
                                <div class="card-body p-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <div class="py-4 position-relative">
                                                <div class="check-custom invi-check">
                                                    <mat-checkbox class="example-margin"
                                                        (change)="cbklisttemplate($event,item, i)" name="tag{{i}}">
                                                    </mat-checkbox>
                                                </div>
                                                <h6 class="title-consent pl-5 ">{{item.templateName}}</h6>
                                                <div class="row pl-5 esign-labled">
                                                    <div class="col-md-4">
                                                        <span class="list-head">{{item.category}}</span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span class="list-head ">E-Signature is applicable :
                                                            <!-- <span class="font-weight-normal pl-2">{{item.isEsign ? 'Yes, ' : 'No'}}</span> -->
                                                            <span *ngIf="item.isEsign"
                                                                class="font-weight-normal pl-2"><i
                                                                    class="far fa-check-square" style="
                                                                color: #216721;"></i>
                                                            </span>
                                                            <span *ngIf="!item.isEsign"
                                                                class="font-weight-normal pl-2"><i>NA</i>
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <span *ngIf="item.isEsign" class="party-doc">
                                                            <span *ngIf="item.isMultiPartyDocument"
                                                                class="font-weight-normal pl-2"><i
                                                                    class="fas fa-users mr-2 "></i> Multi Party
                                                                Document</span>
                                                            <span *ngIf="!item.isMultiPartyDocument"
                                                                class="font-weight-normal pl-2"><i
                                                                    class="fas fa-user mr-2 "></i> Single Party Document
                                                            </span>
                                                        </span>

                                                    </div>

                                                </div>
                                                <div class="row pl-5 create-date">
                                                    <div class="col-md-4">
                                                        <span class="val-list">Created on : {{item.createDate | date:
                                                            'MM/dd/yyyy hh:mm a'}}</span>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <span class="val-list">Updated on : {{item.updateDate | date:
                                                            'MM/dd/yyyy hh:mm a'}}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 border-left">
                                            <div class="py-4 clearfix">
                                                <div class="result_action float-left w-100">
                                                    <!-- <span class="status text-left">
                                                        Status :</span>
                                                    <span class="for_approval checko" *ngIf="item.isActive">
                                                        <i class="fas fa-check mr-2 ml-2"></i>{{item.status}}</span>
                                                    <span class="for_reject checko" *ngIf="!item.isActive">
                                                        <i class="fas fa-minus-circle mr-2 ml-2"></i>{{item.status}}</span> -->
                                                </div>
                                                <div class="result_action_btn float-left w-100 mt-3">
                                                    <a (click)="onpreivewtemplate(item)" title="Preview" *ngIf="isView"
                                                        class="btn line-btn cursor-pointer mb-2"><i class="fa fa-eye"
                                                            aria-hidden="true"></i></a>
                                                    <!-- <a (click)="onStatusChange(item,1)" title="Deactivate"
                                                        class="btn line-btn cursor-pointer" *ngIf="item.isActive">Deactivate</a>
                                                    <a (click)="onStatusChange(item,0)" class="btn line-btn cursor-pointer"
                                                        *ngIf="!item.isActive">Active</a> -->
                                                    <a *ngIf="isEdit" title="edit"
                                                        (click)="onedittemplate(item.consentTemplatesId)"
                                                        class="btn line-btn cursor-pointer mb-2">Edit</a>
                                                    <a *ngIf="isDelete" (click)="ondeleteTemplate(item)" title="delete"
                                                        class="btn line-btn mb-2 cursor-pointer">Delete</a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>
                    <div class="col-md-12">
                        <mat-paginator #consent class="pagination pagination-large" (page)="onPageChange($event)"
                            [pageSize]="20" [pageSizeOptions]="[ 10, 20, 50, 75, 100]">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Price Catalogue" *ngIf="isView">
            <!-- <app-price-catalogue></app-price-catalogue> -->
            <div class="catalogue-lsit">
                <div class="col-md-12 mt-3 p-0">
                    <div class="row">
                        <div class="col-md-7 ">
                            <div class="row ">
                                <div class="col-md-6">
                                    <div class="search">
                                        <input type="text" [(ngModel)]="searchbyCatalouge" class="search-input"
                                            placeholder="Search By Catalogue" name="">
                                        <a (click)='searchCatalouge(0)' class="search-icon"> <i
                                                class="fa fa-search"></i> </a>
                                        <a (click)='searchCatalouge(1)' *ngIf="searchbyCatalouge !=''"
                                            class="search-icon mr-1"> <i class="fa fa-times"></i> </a>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field>
                                        <mat-label>Location</mat-label>
                                        <mat-select name="location" [(ngModel)]="Sfcclocation" #location="ngModel"
                                            (selectionChange)="onChangelocation($event)">
                                            <mat-option *ngFor="let item of ddllocation" [value]="item.sfccMasterId">
                                                {{item.locationName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <button *ngIf="isDelete" class="btn primary-btn-outlined float-right"
                                (click)="ondeletemultipleCat()" [disabled]="catalougeids?.length == 0"><i
                                    class="fas fa-trash mr-2"></i>Delete
                                Catalogue</button>
                            <button *ngIf="isCreate" type="button" [routerLink]="['/price-catalogue']"
                                class="btn primary-btn-outlined float-right mr-2"><i
                                    class="fas fa-plus-circle mr-2"></i>Create
                                Catalogue</button>

                        </div>
                    </div>
                </div>
                <div class="list-template">
                    <ul class="col-md-12 nomargin">
                        <li *ngFor="let item of catalougelst;let i=index">
                            <div class="card">
                                <div class="card-body p-0">
                                    <div class="row">
                                        <div class="col-md-10">
                                            <div class="py-4 position-relative">
                                                <div class="check-custom invi-check">
                                                    <mat-checkbox class="example-margin"
                                                        (change)="cbklistCatalogue($event,item, i)" name="cat{{i}}">
                                                    </mat-checkbox>
                                                </div>
                                                <h6 class="title-consent pl-5 mb-0">{{item.catalogueName}}</h6>
                                                <div class="row pl-5">
                                                    <div class="col-md-3">

                                                        <label class="list-head">Program Type:</label>
                                                        <span class="list-value-new pl-2">{{item.programType}}</span>

                                                    </div>
                                                    <div class="col-md-3">

                                                        <label class="list-head">Location:</label>
                                                        <span class="list-value-new pl-2">{{item.city}}</span>

                                                    </div>
                                                    <div class="col-md-3">
                                                        <label class="list-head">Global:</label>
                                                        <span class="list-value-new pl-2">{{item.isGolbal ? 'Yes' :
                                                            'No'}}</span>

                                                    </div>
                                                </div>
                                                <div class="row pl-5">
                                                    <div class="col-md-12">
                                                        <label
                                                            class="list-head sub-head-list col-md-12 p-0">Description</label>
                                                        <p class="float-left w-100 list-value-new">
                                                            {{item.description}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2 border-left">
                                            <div class="py-4 clearfix">
                                                <div class="result_action_btn float-left w-100 mt-3">
                                                    <a *ngIf="isEdit" title="Edit" role="button"
                                                        (click)="oneditPriceCatalogue(item.priceCatalogueId)"
                                                        class="btn line-btn cursor-pointer mb-2">Edit</a>
                                                    <a *ngIf="isDelete" title="Delete" role="button"
                                                        (click)="ondeletePriceCatalogue(item.priceCatalogueId)"
                                                        class="btn line-btn cursor-pointer mb-2">Delete</a>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="empty-data" *ngIf="catalougelst?.length == 0">
                        <img class="img-fluid" src="../../../../../assets/images/no_result.png">
                    </div>
                    <div class="col-md-12">
                        <mat-paginator #pricecat class="pagination pagination-large"
                            (page)="onPageChangeCataloge($event)" [pageSize]="20" [pageSizeOptions]="[ 10, 20, 50, 75, 100]"
                            >
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Configure Forms" *ngIf="isView">
            <div class="catalogue-lsit">
                <div class="form-configuration">
                    <div class="top-btn-section clearfix mb-1 w-100">
                        <h2 class="head-section mb-2 mt-1">Configure Form</h2>
                        <div class="col-md-6 ml-auto mt-2 button-position pr-0">
                            <button *ngIf="isDelete" class="btn primary-btn-outlined float-right"
                                [disabled]="configids?.length == 0" (click)="ondeletemultipleForm()"><i
                                    class="fas fa-trash mr-2"></i>Delete
                                Forms</button>
                            <button *ngIf="isCreate" type="button" [routerLink]="['/configure-forms']"
                                class="btn primary-btn-outlined float-right mr-2">
                                <i class="fas fa-plus-circle mr-2"></i>Create Form
                            </button>
                        </div>
                    </div>

                    <div class="listing-questions">
                        <ul class="col-md-12 nomargin">
                            <li class="col-md-12 p-0" *ngFor="let item of lstConfigureForms;let i = index">
                                <div class="card">
                                    <div class="card-body p-0">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="py-3 position-relative">
                                                    <div class="check-custom invi-check">
                                                        <mat-checkbox class="example-margin"
                                                            (change)="cbklistForms($event,item, i)" name="{{i}}">
                                                        </mat-checkbox>
                                                    </div>
                                                    <div class="formed-type  pl-5">
                                                        <span class="text-truncate d-block"
                                                            title="{{item.formName}}">{{item.formName}}</span>
                                                        <label>Form Name</label>

                                                    </div>
                                                    <span class="val-list pl-5">Created on : {{global.globalUtctimeformat(item.createDate | date:
                                                        'MM/dd/yyyy hh:mm a')
                                                        }}</span>

                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="py-3 position-relative">

                                                    <div class="formed-type  w-100">
                                                        <span
                                                            [ngClass]="{'survey-clr':item.formTypeId == 1, 'checklist-clr':item.formTypeId == 2,'nutrition-clr':item.formTypeId == 3}">{{item.formTypeName}}</span>

                                                        <label>Form Type</label>

                                                    </div>
                                                    <span class="val-list">Updated on : {{global.globalUtctimeformat(item.updateDate | date:
                                                'MM/dd/yyyy hh:mm a')
                                                }}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="py-3 position-relative">
                                                    <div class=" col-md-12">
                                                        <div class=" row">
                                                            <div class="questionsnumber-list col-md-12">
                                                                <div class="formed-type2 total-ques row">

                                                                    <label>Total Number of Questions</label>
                                                                    <span>{{item.yesNoCount+item.mcsaCount+item.mcmaCount+item.descriptiveCount+item.ratingsCount+item.textCount}}</span>

                                                                </div>
                                                            </div>
                                                            <div class="questionsnumber-list col-md-4">
                                                                <div class="formed-type2 row ">
                                                                    <label class="">Yes/No -</label>
                                                                    <span class=" pl-1">{{item.yesNoCount}}</span>

                                                                </div>
                                                                <div class="formed-type2 row ">
                                                                    <label class="">MCSA -</label>
                                                                    <span class="pl-1">{{item.mcsaCount}}</span>

                                                                </div>

                                                            </div>
                                                            <div class="questionsnumber-list col-md-4">
                                                                <div class="formed-type2 row ">
                                                                    <label class="">MCMA -</label>
                                                                    <span class="pl-1">{{item.mcmaCount}}</span>

                                                                </div>
                                                                <div class="formed-type2 row ">
                                                                    <label class="">Descriptive -</label>
                                                                    <span class="pl-1">{{item.descriptiveCount}}</span>

                                                                </div>


                                                            </div>
                                                            <div class="questionsnumber-list col-md-4">


                                                                <div class="formed-type2 row ">
                                                                    <label class="">Rating -</label>
                                                                    <span class="pl-1">{{item.ratingsCount}}</span>

                                                                </div>
                                                                <div class="formed-type2 row ">
                                                                    <label class="">Text -</label>
                                                                    <span class="pl-1">{{item.textCount}}</span>

                                                                </div>
                                                
                                                                

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-md-2 border-left">
                                                <div class="py-3 clearfix">
                                                    <div class="result_action_btn float-left w-100 mt-3">
                                                        <a title="Preview" class="btn line-btn cursor-pointer mb-2"
                                                            (click)="onViewForms(item)">
                                                            <i aria-hidden="true" class="fa fa-eye"></i>
                                                        </a>
                                                        <a *ngIf="isEdit" title="edit" (click)="onEditForms(item)"
                                                            class="btn line-btn cursor-pointer mb-2">Edit</a>
                                                        <a *ngIf="isDelete" title="delete" (click)="onDeleteForm(item)"
                                                            class="btn line-btn mb-2 cursor-pointer">Delete</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Skills Taught" *ngIf="isView">
            <div class="catalogue-lsit">
                <div class="form-configuration">
                    <div class="top-btn-section clearfix mb-1 w-100">
                        <h2 class="head-section mb-2 mt-1">Skills Taught</h2>
                        <div class="col-md-6 ml-auto mt-2 button-position pr-0">
                            <button *ngIf="isDelete" class="btn primary-btn-outlined float-right"
                                [disabled]="skillTaughtCheckBoxItems?.length == 0" (click)="ondeletemultipleSkillTaughtForm()"><i
                                    class="fas fa-trash mr-2"></i>Delete
                                Forms</button>
                            <button *ngIf="isCreate" type="button" [routerLink]="['/skill-taught']"
                                class="btn primary-btn-outlined float-right mr-2">
                                <i class="fas fa-plus-circle mr-2"></i>Skills Taught
                            </button>
                        </div>
                    </div>
                    <div class="card mt-2" *ngFor="let item of skillTaughtMasterData">
                        <div class="col-md-12 p-0 created-form-section view-form-meta">
                            <div class="lead-box pt-2 pb-2">
                              <div class="clearfix">
                                <div class="col-md-12">
                                  <div class="form-details col-md-12 mt-3 mb-3 p-0">
                                    <div class="row" >
                                          <div class="col-md-4" >
                                            <div class="check-custom invi-check">
                                                <mat-checkbox class="example-margin" (change)="skillTaughtFormCheckBox($event,item, i)" name="{{i}}">
                                                </mat-checkbox>
                                            </div>
                                            <div class="ml-0">
                                              <label class="form-detail-label ml-5">Form Name :</label>
                                              <span
                                                class="text-truncate align-middle font-weight-bold d-inline-block w-200px"
                                              >
                                                {{item.skillTaught}}
                                              </span>
                                            </div>
                                            <div>
                                              <p class="form-detail-label ml-5">
                                                Created On :
                                                <span class="text-truncate align-middle d-inline-block w-200px"
                                                  >{{global.globalUtctimeformat(item.createdOn | date:'MM/dd/yyyy hh:mm a')}}</span
                                                >
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-md-3 p-0">
                                            <div>
                                              <label class="form-detail-label">Client Type :</label>
                                              <span class="text-truncate align-middle d-inline-block font-weight-bold" *ngIf="item.isIop && item.isPcp">IOP,</span>
                                              <span class="text-truncate align-middle d-inline-block font-weight-bold" *ngIf="item.isIop && !item.isPcp">IOP</span>
                                              <span class="text-truncate align-middle d-inline-block font-weight-bold" *ngIf="item.isPcp">PCP</span>
                                            </div>
                                            <div>
                                              <p class="form-detail-label">
                                                Last Updated On :
                                                <span class="form-detail-label">{{global.globalUtctimeformat(item.updatedOn | date:'MM/dd/yyyy hh:mm a')}}</span>
                                                </p>
                                            </div>
                                          </div>
                                          <div class="col-md-3">
                                            <div>
                                                <label class="form-detail-label">Therapy :</label>
                                                <span
                                                class="align-middle d-inline-block font-weight-bold"
                                                >
                                                {{item.therapy}}
                                                </span>
                                            </div>
                                            <div>
                                                <p class="form-detail-label">
                                                  Location :
                                                  <span class="form-detail-label" *ngIf="!item.isGlobal">{{item.location}}</span>
                                                  <span class="form-detail-label" *ngIf="item.isGlobal">Global</span>
                                                </p>
                                              </div>
                                          </div>
                                          <div class="col-md-2 text-left clearfix mt-2 pr-1 pl-1">
                                            <button
                                            type="button"
                                            class="btn primary-btn-outlined float-right mr-2 fs12" (click)="deleteSkillTaughForm(item.skillTaughtMasterId)">
                                            <i class="fas fa-trash mr-2"></i>Delete
                                          </button>

                                            <button
                                              type="button"
                                              class="btn primary-btn-outlined float-right mr-2 fs12" (click)="editSkillTaughtForm(item.skillTaughtMasterId)">
                                              <i class="far fa-edit mr-2"></i>Edit
                                            </button>
                                          
                                          <button title="Preview" 
                                          class="btn line-btn fs14 cursor-pointer mb-0 mt-0 py-2 float-right" (click)="viewSkillTaughtForm(item.skillTaughtMasterId)" ><i class="fa fa-eye"
                                              aria-hidden="true"></i></button>
                                          </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
         <!-- <mat-tab label="Group Therapy" *ngIf="isView">

            <div class="schdule-modality">
                <div class="col-md-12">
                    <div class="top-btn-section col-md-12 p-0 clearfix mb-1 w-100">
                        <div class="head-section mt-3">Group Therapy </div>
                        <div class="col-md-6 ml-auto mt-2 pr-0"><button
                                class="btn primary-btn-outlined float-right ng-star-inserted"
                                (click)="ondeletemultipleTherapy()" [disabled]="grpTherapyids?.length == 0"><i
                                    class="fas fa-trash mr-2"></i>Delete Group Theraphy</button>

                            <button type="button" class="btn primary-btn-outlined float-right mr-2 ng-star-inserted"
                                tabindex="0" [routerLink]="['/group-therapy']"><i
                                    class="fas fa-plus-circle mr-2"></i>Add Group Theraphy</button>
                        </div>
                    </div>

                </div>

                <div class="gt-list">
                    <div class="list-template">
                        <ul class="col-md-12 nomargin">
                            <li class="col-md-12 p-0" *ngFor="let item of grpTherapylst;let i=index">
                                <div class="card mt-2">
                                    <div class="card-body p-0">
                                        <div class="">
                                            <div class="py-4 position-relative">
                                                <div class="col-md-12 ">
                                                    <div class="row">
                                                        <div class="col-md-10">
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    <div class="check-custom invi-check">
                                                                        <mat-checkbox class="example-margin"  (change)="cbklistTherapy($event,item, i)" name="tag{{i}}">
                                                                        </mat-checkbox>
                                                                    </div>
                                                                    <div class="formed-type pl-5">
                                                                        <span
                                                                            class="text-truncate d-block">{{item.groupTherapyName}}</span>
                                                                        <label class="text-primary">Group Therapy
                                                                            Name</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-8">
                                                                    <div class="col-md-12 p-0 mb-2">
                                                                        <span class="slot-label mr-2">No of Slots
                                                                            :</span>
                                                                        
                                                                        <span
                                                                            class="slot-count">{{item.capacity}}</span>
                                                                    </div>
                                                                    <span class="val-list mr-4">Start Date :
                                                                        {{item.startDate | date:'MM/dd/YYYY'}}</span>
                                                                    <span class="val-list mr-4">Start Time :
                                                                        {{item.startTime | date:'hh:mm a'}}</span>
                                                                    <span class="val-list mr-4">Duration
                                                                        :{{ getMinutesSlot(item.duration)}}</span>
                                                                    <span class="val-list ">End Date :{{item.endDate == null ? ' - ' : item.endDate |
                                                                        date:'MM/dd/YYYY'}}</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-md-2 border-left">
                                                            <div class="clearfix">
                                                                <div class="result_action_btn float-left w-100 mt-3">
                                                                    <a title="edit"
                                                                        (click)="oneditGrpTherapy(item.groupTherapyId)"
                                                                        class="btn line-btn cursor-pointer ng-star-inserted">Edit</a>
                                                                    <a title="delete"
                                                                        (click)="ondeleteGrpTherapy(item.groupTherapyId)"
                                                                        class="btn line-btn cursor-pointer ng-star-inserted">Delete</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="col-md-12">
                            <mat-paginator #grptherpy class="pagination pagination-large"
                                (page)="onPageChangeTherapy($event)" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
                            </mat-paginator>
                        </div>
                    </div>
                </div>

            </div>
        </mat-tab>  -->
        <!-- <mat-tab label="Goals" *ngIf="isGoalView">
          
            <div class="top-btn-section clearfix mb-3 col-md-12">
          

            </div>
            <div class="top-btn-section clearfix mb-1 w-100">
                <h2 class="head-section mb-2 mt-1">Goals</h2>
                <div class="col-md-6 ml-auto mt-2 button-position pr-0">
                    <button *ngIf="isGoalDelete" class="btn primary-btn-outlined float-right" [disabled]="goalids?.length == 0"
                        (click)="ondeletemultipleGoal()"><i class="fas fa-trash mr-2"></i>Delete
                        Goals</button>
                    <button *ngIf="isGoalCreate" type="button" [routerLink]="['/config-goal']"
                        class="btn primary-btn-outlined float-right mr-2"><i class="fas fa-plus-circle mr-2"></i>Add
                        Goal</button>

                </div>
            </div>

            <div class="section-consent">

                <div class="list-template">
                    <ul class="col-md-12 nomargin">
                        <li *ngFor="let item of lstGoals;let i=index" class="col-md-12">
                            <div class="card mt-2">
                                <div class="card-body p-0">
                                    <div class="row">
                                        <div class="col-md-12 goals-viewd">
                                          <div class="py-4 position-relative">
                                          <div class="row">
                                              <div class="col-md-10">
                                                <div class="row">
                                                  <div class="col-md-7">
                                                      
                                                          <div class="check-custom invi-check">
                                                              <mat-checkbox class="example-margin"
                                                                  (change)="cbklistGoal($event,item, i)" name="tag{{i}}">
                                                              </mat-checkbox>
                                                          </div>
                                                          <div  class="formed-type pl-5">
                                                              <span  class="text-truncate d-block" >{{item.goalName}}</span>
                                                              <label class="text-primary" >Goal Name</label>
                                                          </div>
                                                          
                                                         
                                                       
                                                      
                                                  </div>
                                                  <div class="col-md-3">
                                                      
                                                      <div  class="formed-type ">
                                                          <span  class="text-truncate d-block" >{{item.catagoryName}}</span>
                                                          <label class="text-primary" >Category </label>
                                                      </div>
                                                     
                                                          
                                                  </div>
                                                  <div class="col-md-2 text-right">
                                                    <div  class="questionsnumber-list col-md-12 p-0 text-right mb-2">
                                                        <span  class="formed-type2 mr-1 "><label >LMTP -</label><span  class="pl-1 count-round">0</span></span>
                                                        
                                                        </div>
                                                    
                                                        
                                                </div>
                                                  </div>
                                                 
                                                <div class="row pl-5">
                                                  <div class="col-md-6">
                                                      <label  class="list-head sub-head-list col-md-12 p-0">Description</label>
                                                      <p  class="float-left w-100 list-value-new" innerHTML="{{global.getContent(item.definition)}} "> </p>
                                                      
                                                      <div class="col-md-12 p-0">
                                                        <span class="val-list  mr-4">Created on : {{global.globalUtctimeformat(item.createDate)}}</span>
                                                        <span class="val-list">Updated on : {{global.globalUtctimeformat(item.updateDate)}}</span> 
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6 border-left">
                                                    <div class="mb-2 "> 
                                                        <label class="list-head m-0 p-0">Measure Type:</label>
                                                    <span  class="list-value-new pl-2">{{item.measureTypeName}}</span>
                                                    </div>
                                                      <div class="mb-2 "> 
                                                          <label class="list-head m-0 p-0">specificity:</label>
                                                      <span  class="list-value-new pl-2">{{item.specificity}}</span>
                                                      </div>
                                                      <div class="mb-2 "> 
                                                          <div class="w-75 d-inline-block">
                                                            <label class="list-head m-0 p-0">Measure :</label>
                                                            <span  class="list-value-new pl-2">{{item.measure}}</span>
                                                          </div>
                                                          <div class="w-25 d-inline-block align-top text-right">
                                                            <span  class="formed-type2 "><label >No of BM -</label><span  class="pl-1 count-round">{{item?.measures?.length}}</span></span>
                                                          </div>
                                                        

                                                      </div>
                                                      
                                                      
                                                  </div>
                                                </div>
                                                <div class="row pl-5">
                                                   
                                                </div>
                                                  
                                              </div>
                                              <div class="col-md-2 border-left">
                                                  <div class=" clearfix">
                                                      <div class="result_action float-left w-100">
      
                                                      </div>
                                                      <div class="result_action_btn float-left w-100 mt-3">
                                                       
                                                          <a  *ngIf="isGoalEdit" title="edit" (click)="oneditGoal(item.goalId)"
                                                              class="btn line-btn cursor-pointer">Edit</a>
                                                          <a  *ngIf="isGoalDelete" (click)="ondeleteGoal(item)" title="delete"
                                                              class="btn line-btn cursor-pointer">Delete</a>
                                                      </div>
      
                                                  </div>
                                              </div>
                                          </div>
                                          </div>
                                        </div>
                                         
                                         
                                      </div>
                                    </div>
                            </div>
                        </li>

                    </ul>
                    <div class="col-md-12">
                        <mat-paginator #pagGoal class="pagination pagination-large" (page)="onPageChangeGoal($event)"
                            [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </mat-tab> -->
    </mat-tab-group>

</div>
<div class="modal previewmodel" id="myModal" [style.display]="isPreviewtemplate ? 'block' : 'none'">
    <div class="modal-dialog mb-2">
        <div class="modal-content email-compose">
            <div class="modal-header">
                <h4 class="modal-title">Preview Template</h4>
                <button aria-hidden="true" data-dismiss="modal" class="close" (click)="closepreviewtemp()"
                    type="button"><i class="fas fa-times"></i></button>

            </div>
            <div class="modal-body">
                <div class="col-md-12 preview-body">
                    <!-- <p innerHTML="{{previewtemplatecontent}}"></p> -->
                    <!-- <pre>{{previewtemplatecontent}}</pre> -->
                    <!-- <quill-editor theme="bubble"
                        style="height: 100%;" 
                        value="{{previewtemplatecontent}}" >
                    </quill-editor> -->
                    <div class="ql-container ql-snow" style="border-width: 0;">
                        <div class="ql-editor" [innerHTML]="previewtemplatecontent">
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
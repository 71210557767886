<div class="CMHA">
  <a class="d-none" #downloadLink></a>
  <div class="top-btn-section clearfix mb-2">
    <h2 class="head-section mb-2 mt-2 pl-3">
      Comprehensive Mental Health Assessment
    </h2>
    <div class="col-md-4 text-right ml-auto pr-0">
      <button
        class="btn back-btn-new float-none mr-2"
        (click)="onbacktoMytask()"
      >
        <i class="fas fa-chevron-left mr-2"></i>Back
      </button>
      <button
        class="btn back-btn-new mr-2 float-none"
        (click)="onGeneratePDF(0)"
      >
        <i class="far fa-file-pdf mr-2"></i>Generate PDF
      </button>
      <span class="float-none">
        <a
          title="PatientInformation"
          role="button"
          class="export-pdf-btn btn float-none"
          (click)="onGeneratePDF(1)"
          ><i class="fas fa-print"></i>
        </a>
      </span>
    </div>
  </div>

  <div class="col-md-12">
    <div class="card tab-card">
      <div class="card-body pt-0 pb-0 pl-0 pr-0">
        <div class="top-btn-section clearfix">
          <div class="d-flex met-info-top w-100">
            <i class="fas fa-user"></i>
            <div class="mr-4">
              Client Name
              <span class="imp-info">
                {{ _dtoPatientInfo?.patientName }}
              </span>
            </div>
            <div class="mr-4">
              DOB
              <span class="imp-info">
                {{ _dtoPatientInfo?.dob | date: "MM/dd/YYYY" }}
              </span>
            </div>
            <div class="mr-4">
              Age <span class="imp-info"> {{ _dtoPatientInfo?.age }} Yrs </span>
            </div>
            <div class="mr-4">
              Updated On
              <span class="imp-info">
                {{ _dtoPatientInfo?.updatedDate | date: "MM/dd/YYYY" }}
              </span>
            </div>
          </div>
        </div>
        <br />
        <div class="tab-card-header">
          <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link cursor-pointer"
                [ngClass]="{ 'active show': selectedTabIndex == 1 }"
                id="one-tab"
                data-toggle="tab"
                (click)="onTabChange(1)"
                role="tab"
                aria-controls="One"
                aria-selected="true"
              >
                <!-- <i class="fas fa-user-injured mr-2"></i> -->
                <span class="complete-icon">
                  <i
                    class="fas fa-check-circle mr-1"
                    *ngIf="global.isPatient == 1"
                  ></i>
                  <i
                    class="fas fa-exclamation-circle mr-1"
                    style="color: orange"
                    *ngIf="global.isPatient == 0"
                  ></i>
                </span>
                Client Details
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link cursor-pointer"
                [ngClass]="{ 'active show': selectedTabIndex == 2 }"
                id="two-tab"
                data-toggle="tab"
                (click)="onTabChange(2)"
                role="tab"
                aria-controls="Two"
                aria-selected="false"
              >
                <!-- <i class="fas fa-hospital-user mr-2"></i> -->
                <span class="complete-icon">
                  <i
                    class="fas fa-check-circle mr-1"
                    *ngIf="global.isClinicalInfo == 1"
                  ></i>
                  <i
                    class="fas fa-exclamation-circle mr-1"
                    style="color: orange"
                    *ngIf="global.isClinicalInfo == 0"
                  ></i> </span
                >Clinical Information
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link cursor-pointer"
                [ngClass]="{ 'active show': selectedTabIndex == 3 }"
                id="three-tab"
                data-toggle="tab"
                (click)="onTabChange(3)"
                role="tab"
                aria-controls="Two"
                aria-selected="false"
              >
                <!-- <i class="fas fa-heartbeat mr-2"></i> -->
                <span class="complete-icon">
                  <i
                    class="fas fa-check-circle mr-1"
                    *ngIf="global.isEvaluvation == 1"
                  ></i>
                  <i
                    class="fas fa-exclamation-circle mr-1"
                    style="color: orange"
                    *ngIf="global.isEvaluvation == 0"
                  ></i>
                </span>
                Evaluation of Daily Living
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link cursor-pointer"
                [ngClass]="{ 'active show': selectedTabIndex == 4 }"
                id="four-tab"
                data-toggle="tab"
                role="tab"
                (click)="onTabChange(4)"
                aria-controls="Three"
                aria-selected="false"
              >
                <!-- <i class="fas fa-fist-raised mr-2"></i> -->
                <span class="complete-icon">
                  <i
                    class="fas fa-check-circle mr-1"
                    *ngIf="global.isClientSW == 1"
                  ></i>
                  <i
                    class="fas fa-exclamation-circle mr-1"
                    style="color: orange"
                    *ngIf="global.isClientSW == 0"
                  ></i>
                </span>
                Client’s Strength and Weakness
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link cursor-pointer"
                [ngClass]="{ 'active show': selectedTabIndex == 5 }"
                id="five-tab"
                data-toggle="tab"
                role="tab"
                (click)="onTabChange(5)"
                aria-controls="Three"
                aria-selected="false"
              >
                <!-- <i class="fas fa-bullseye mr-2"></i> -->
                <span class="complete-icon">
                  <i
                    class="fas fa-check-circle mr-1"
                    *ngIf="global.isAssessment == 1"
                  ></i>
                  <i
                    class="fas fa-exclamation-circle mr-1"
                    style="color: orange"
                    *ngIf="global.isAssessment == 0"
                  ></i>
                </span>
                Assessment Recommendations
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link cursor-pointer"
                [ngClass]="{ 'active show': selectedTabIndex == 6 }"
                id="five-tab"
                data-toggle="tab"
                role="tab"
                (click)="onTabChange(6)"
                aria-controls="Three"
                aria-selected="false"
              >
                <!-- <i class="fas fa-list-alt mr-2"></i> -->
                <span class="complete-icon">
                  <i
                    class="fas fa-check-circle mr-1"
                    *ngIf="global.isClinicalSummary == 1"
                  ></i>
                  <i
                    class="fas fa-exclamation-circle mr-1"
                    style="color: orange"
                    *ngIf="global.isClinicalSummary == 0"
                  ></i>
                </span>
                Clinical Summary
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link cursor-pointer"
                [ngClass]="{ 'active show': selectedTabIndex == 7 }"
                id="five-tab"
                data-toggle="tab"
                role="tab"
                (click)="onTabChange(7)"
                aria-controls="Three"
                aria-selected="false"
              >
                <!-- <i class="fas fa-list-alt mr-2"></i> -->
                <span class="complete-icon">
                  <i
                    class="fas fa-check-circle mr-1"
                    *ngIf="global.isTreatmentSummary == 1"
                  ></i>
                  <i
                    class="fas fa-exclamation-circle mr-1"
                    style="color: orange"
                    *ngIf="global.isTreatmentSummary == 0"
                  ></i>
                </span>
                Assign Clinician
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade p-3 chma-height thin-scroll"
          id="one"
          role="tabpanel"
          aria-labelledby="one-tab"
          [ngClass]="{ 'active show': selectedTabIndex == 1 }"
          *ngIf="selectedTabIndex == 1"
        >
          <app-cmha-patientdetails></app-cmha-patientdetails>
        </div>
        <div
          class="tab-pane fade p-3 chma-height thin-scroll"
          id="two"
          role="tabpanel"
          aria-labelledby="two-tab"
          [ngClass]="{ 'active show': selectedTabIndex == 2 }"
          *ngIf="selectedTabIndex == 2"
        >
          <app-cmha-clinicalinfo></app-cmha-clinicalinfo>
        </div>
        <div
          class="tab-pane fade p-3 chma-height thin-scroll"
          id="three"
          role="tabpanel"
          aria-labelledby="three-tab"
          [ngClass]="{ 'active show': selectedTabIndex == 3 }"
          *ngIf="selectedTabIndex == 3"
        >
          <app-cmha-evaluation></app-cmha-evaluation>
        </div>
        <div
          class="tab-pane fade p-3 chma-height thin-scroll"
          id="three"
          role="tabpanel"
          aria-labelledby="three-tab"
          [ngClass]="{ 'active show': selectedTabIndex == 4 }"
          *ngIf="selectedTabIndex == 4"
        >
          <app-cmha-strengthweekness></app-cmha-strengthweekness>
        </div>
        <div
          class="tab-pane fade p-3"
          id="three"
          role="tabpanel"
          aria-labelledby="three-tab"
          [ngClass]="{ 'active show': selectedTabIndex == 5 }"
          *ngIf="selectedTabIndex == 5"
        >
          <app-cmha-assessment></app-cmha-assessment>
        </div>
        <div
          class="tab-pane fade p-3 chma-height thin-scroll"
          id="three"
          role="tabpanel"
          aria-labelledby="three-tab"
          [ngClass]="{ 'active show': selectedTabIndex == 6 }"
          *ngIf="selectedTabIndex == 6"
        >
          <app-cmha-clinical></app-cmha-clinical>
        </div>
        <div
          class="tab-pane fade p-3 chma-height thin-scroll"
          id="three"
          role="tabpanel"
          aria-labelledby="three-tab"
          [ngClass]="{ 'active show': selectedTabIndex == 7 }"
          *ngIf="selectedTabIndex == 7"
        >
          <app-cmha-treatmentplan></app-cmha-treatmentplan>
        </div>
      </div>
    </div>
  </div>
</div>



import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { PhoneSchoolmeetForm } from 'src/app/_models/_Treatment/_phoneschoolmeet';
import { ClinicianService } from '../../clinician/clinician.service';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { constantVariables } from 'src/app/constant/constant';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';

import moment from 'moment';
import { cloneDeep } from "lodash";


  @Component({
    selector: 'app-treatment-meeting-notes',
    templateUrl: './treatment-meeting-notes.component.html',
    styleUrls: ['./treatment-meeting-notes.component.scss']
  })
  export class TreatmentMeetingNotesComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('PhoneSchoolfrm')PhoneSchoolfrm : NgForm;
  selecteddate: any;
  selectedtheraphyid: any;
  selectedMsId: any;
  selectedIntervention: string;
  _dtoPatientInfo: any;
  SelectedTab =1;
  PhoneSchoolInfo:PhoneSchoolmeetForm;
  isESignAttached: boolean;
  signatureImg: any;
  updatedsignatureImg: any;
  isformtype: string;
  defaultImage: any;
  IseditFrm: boolean ;
  PopUpPreview: boolean;
  roleAccess: RoelAccess;
  pdfpsytherapynotes: any;
  therapyName: string;
  isLicensed: boolean;
  RoleId: string;
  ddlFormCommunicationMaster: any;
  Communication: any;
  meetingTimeslots: any;
  IspastTime: boolean;
  currenttime: string;
  cdr: any;
  CheckOut:any;
  Checkin:any;
  IsCloseWindow: boolean=false;
  PatientAccountId:Number;
  ShowPopup : boolean=false;
  _dtoNotes: any = [];
  NoRecord :any;
    secondaryId: any;
    progressnotesid: number;
    AutoFillSS: boolean = false;
    SSignature: any;
    GetClinicianSign: any[];
    userId: any;
  constructor(public global: Global,public settingsService: SettingsService, public clinicianservice: ClinicianService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,public treatmentservice: TreatmentService,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,private formBuilder: FormBuilder
    , public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    //this.isLicensed = localStorage.getItem('isLicensed') == 'true'?true:false;
    this.RoleId = localStorage.getItem('roleId');
    this.userId = Number(localStorage.getItem('id'));
    this.getAutoFillSignature();
    this.PatientAccountId=Number(localStorage.getItem('patientAccountIdT'));
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.progressnotesid = Number(this.commonService.FrontEndDecryption(params.notesId).replace(/"/g, ""));
       
      }
    });
    const pageid = constantVariables.pagelists.ProgressNoteFivepointRating;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.PhoneSchoolInfo = new PhoneSchoolmeetForm();


    if(this.progressnotesid>0)
      {
      this.editNotePopup(this.progressnotesid);
        this.PhoneSchoolInfo.PhoneSchoolMeetProgressNotesId=this.progressnotesid;

      }
      else
      {
        this.PhoneSchoolInfo.PhoneSchoolMeetProgressNotesId=0;
       this.IseditFrm  = true;


      }

    
    this.getFormCommunication();
    this.getSchedulerTimeSlots();
    this.getPatientInfo(this.PatientAccountId);
   // this.IseditFrm  = true;

        //  this. _dtoPatientInfo.startDate="";
        //  this._dtoPatientInfo.startTime="";

    // this.route.queryParams.subscribe(params => {
    //   if (Object.keys(params).length != 0) { 
    //      this.selecteddate = JSON.parse(this.commonService.FrontEndDecryption(params.date));
    //     this.selectedtheraphyid = JSON.parse(this.commonService.FrontEndDecryption(params.theraphyid));
    //     this.selectedMsId= JSON.parse(this.commonService.FrontEndDecryption(params.id));
    //     if(params.IsFromHistory){
    //       this.IsCloseWindow = true;
    //     }
    //     if(this.selectedtheraphyid==11){this.therapyName="PHONE CALL CONVERSATION COMMUNICATION FORM";}
    //     else if (this.selectedtheraphyid==10) {this.therapyName="SCHOOL MEET CONVERSATION COMMUNICATION FORM";}
    //     this.selectedIntervention="";
    //     this.getPatientInfo();
        
    //     }
    // });
    
  }

  OnTabchange(item){
    this.SelectedTab = item;
    if(this.SelectedTab ==2){
     
    }
    if(this.SelectedTab ==3){
     
    }
    
  }
  getSchedulerTimeSlots() {
    this.settingsService.getSchedulerTimeSlots().subscribe(
      res => {
        if (res != null) {
          this.meetingTimeslots = res;
          this.cdr.detectChanges();  
  
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  

  getPatientInfo(PatientAccountId) {
    let date = '2021-05-28'
    this.spinnerservice.show();
    this.secondaryId = localStorage.getItem('id');
    this.clinicianservice.getPatientAndClinicainName(PatientAccountId,this.secondaryId).subscribe(
      (data: any) => {
       // debugger;
        this.spinnerservice.hide();
        if (data){
     
         this._dtoPatientInfo=data;
         this._dtoPatientInfo.patientName=this._dtoPatientInfo[0].patientName;
         this._dtoPatientInfo.clinicianName=this._dtoPatientInfo[0].clinicianName; 
         this.PhoneSchoolInfo.StaffInitial1 = this.PhoneSchoolInfo.StaffInitial1 == null ? this._dtoPatientInfo[0].staffInitial1 : this.PhoneSchoolInfo.StaffInitial1;
         this.PhoneSchoolInfo.StaffInitials = this.PhoneSchoolInfo.StaffInitials == null ? this._dtoPatientInfo[0].staffInitials : this.PhoneSchoolInfo.StaffInitials;
         localStorage.setItem('ClinicianName',this._dtoPatientInfo.clinicianName);
        // this.OnCommunicationChange(1);

        // this.PhoneSchoolInfo = res.DtoPhoneSchoolNotes;
        // if(this.PhoneSchoolInfo.PhoneSchoolMeetProgressNotesId!=0 ){
        
        //   this.OnCommunicationChange(1)
        // this.Checkin =moment(date).format("YYYY-MM-DD") + " " + moment( this.PhoneSchoolInfo.Checkin).format('HH:mm')+":00.000";
        // this.CheckOut =moment(date).format("YYYY-MM-DD") + " " + moment( this.PhoneSchoolInfo.CheckOut).format('HH:mm')+":00.000";
        // this.PhoneSchoolInfo.isSave = false;
        // }
        // else{
        //  this.IseditFrm  = true
        // }
        }
      },
        
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  PostPhoneschoolFrm(val){
    // if(!this.PhoneSchoolfrm.valid ){
    //   return;
    // }
    // else{
   
      this.PhoneSchoolInfo.isSave = true;  
    this.PhoneSchoolInfo.Typeid = 11;
    this.PhoneSchoolInfo.Checkin  = moment(this.Checkin).format("YYYY-MM-DDTHH:mm:ss")
    this.PhoneSchoolInfo.CheckOut  = moment(this.CheckOut).format("YYYY-MM-DDTHH:mm:ss")
    this.PhoneSchoolInfo.PatientAccountId=this.PatientAccountId;         
    this.PhoneSchoolInfo.ClientName=localStorage.getItem('ClinicianName');    
   
  const dataToEncrypt = this.commonService.DataToEncrypt(this.PhoneSchoolInfo, false);
  this.clinicianservice.PostPhoneSchoolNotesTreatment(this.PhoneSchoolInfo).subscribe(
    res=>{
      // if(val != 2)
      // {
      this.spinnerservice.hide();
      if(this.IseditFrm==true)
      {
        this.snackbar.success('Phone notes saved successfully');
     this.router.navigate(['/clinician'],{queryParams:{menu:3}});


      }
      else
      {
        this.snackbar.success('Phone notes updated successfully');

      }
      this.PhoneSchoolInfo.PhoneSchoolMeetProgressNotesId=0;
      // this.getPatientInfo(120)
      // }
  },
      err => {
        this.spinnerservice.hide();
      this.snackbar.success('Phone notes not saved');
      },) 
    //}

 // }
}

  openDialog() {
    this.global.isconsentesign = false;
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
    //   data: { isfutureSign: false, isParentsign: true },
    //   autoFocus: false 
    // });
    let isParentsign =false;
    if(this.AutoFillSS==true)
     {
       isParentsign=true;
 
     }
     const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
       {
      data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
       autoFocus: false 
 
     });
    dialogRef.afterClosed().subscribe((result) => {
      this.isESignAttached = this.global.isconsentesign;
      if (
        this.global.isconsentesign != undefined &&
        this.global.isconsentesign
      ) {
        if(this.SSignature!='')
          {
           this.updatedsignatureImg=this.SSignature;
          }
          
        this.signatureImg = this.updatedsignatureImg;
        this.PhoneSchoolInfo.StaffInitials = this.updatedsignatureImg;
       // this.IncReportfrmInfo.IsAttachEsign = true;
        (document.getElementById('esign') as HTMLImageElement).src = this.signatureImg;
      } else if (
        !this.global.isconsentesign &&
        this.global.formtype != null &&
        this.global.formtype != undefined
      ) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.PhoneSchoolInfo.StaffInitials = this.global.consentesign;
        //this.IncReviewInfo.IsAttachEsign = true;
        (document.getElementById('esign') as HTMLImageElement).src =
          this.signatureImg;
      } else {
        this.signatureImg = this.defaultImage;
        //this.IncReportfrmInfo.IsAttachEsign = true;
        // this.PhoneSchoolInfo.StaffInitials = this.defaultImage;
      }
    });
}
openDialogs() {
  // this.global.isconsentesign = false;
  // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
  //   data: { isfutureSign: false, isParentsign: true },
  //   autoFocus: false 
  // });
  this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if (
      this.global.isconsentesign != undefined &&
      this.global.isconsentesign
    ) {
      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
      this.signatureImg = this.updatedsignatureImg;
      this.PhoneSchoolInfo.StaffInitial1 = this.updatedsignatureImg;
     // this.IncReportfrmInfo.IsAttachEsign = true;
      (document.getElementById('esign1') as HTMLImageElement).src =
        this.signatureImg;
    } else if (
      !this.global.isconsentesign &&
      this.global.formtype != null &&
      this.global.formtype != undefined
    ) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      this.PhoneSchoolInfo.StaffInitial1= this.global.consentesign;
      //this.IncReviewInfo.IsAttachEsign = true;
      (document.getElementById('esign1') as HTMLImageElement).src =
        this.signatureImg;
    } else {
      this.signatureImg = this.defaultImage;
      //this.IncReportfrmInfo.IsAttachEsign = true;
      // this.PhoneSchoolInfo.StaffInitials1 = this.defaultImage;
    }
  });
}
OnpreviewClick(){
  this.PopUpPreview = true;
}
OnclosePopup(){
  this.PopUpPreview = false;
}
OnbackClick(){
  
     this.router.navigate(['/clinician'],{queryParams:{menu:3}});
   // this.router.navigateByUrl('/dashboard');

  
 }

 OnDownload(item){
  //this.downloadLink = new ElementRef();
  this.spinnerservice.show();
  
  this.clinicianservice.getCommunicationformTreatmentpdf(this.PhoneSchoolInfo.PhoneSchoolMeetProgressNotesId,this.PatientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfpsytherapynotes = res.filePath;
        if(item==1)
        {
          window.open(this.pdfpsytherapynotes, '_blank');
        }
        else if(item==0)
        {
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        }
       
      },
      err => {
        this.spinnerservice.hide();
      },
    );
}
print(){
  let printContents = document.getElementById('print-section').innerHTML;
     let originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
    // this.ShowPreview=true;
     window.location.reload();
    
}
getFormCommunication(){
  this.spinnerservice.show()
  this.commonService.getFormCommunicationMaster().subscribe(
    res => {
      if (res != null) {
    this.ddlFormCommunicationMaster = res;
    console.log(this.ddlFormCommunicationMaster)
 this.spinnerservice.hide()
}},
err => {
  this.spinnerservice.hide();

})
}
OnCommunicationChange(val){
  if(this.PhoneSchoolInfo.FormOfCommunication!=null){
  this.Communication = this.ddlFormCommunicationMaster.filter(x=>x.communicationCode == this.PhoneSchoolInfo.FormOfCommunication)[0].formCommunicationName;
  // if(val == 2)
  // {
  //   this.PostPhoneschoolFrm(2);
  // }
 
}
}
// onSelectionChange()
// {
//   this.PostPhoneschoolFrm(2);
// }
Onclosepopup()
{
  this.ShowPopup=false;
}

OnViewClick()
{
  this.getTreatmentNotes();
  this.ShowPopup=true;

}
getTreatmentNotes()
{
  this.spinnerservice.show();
  this.clinicianservice.getPhoneSchoolMeetProgressNotesTreatment(this.PatientAccountId).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if (res && res.length > 0) {
        this._dtoNotes = cloneDeep(res);
      } else {
        // this.onAddMedication();
        this.NoRecord = "No record found";
      }

    },
    err => {
      this.spinnerservice.hide();
    },
  );

}

editNotePopup(phoneSchoolMeetProgressNotesTreatmentId)
{
  this.ShowPopup=false;
  this.IseditFrm=false;
  this.getTreatmentNotesById(phoneSchoolMeetProgressNotesTreatmentId);
  this.PhoneSchoolInfo.PhoneSchoolMeetProgressNotesId=phoneSchoolMeetProgressNotesTreatmentId;
  //this.PostPhoneschoolFrm(1);
}
getTreatmentNotesById(phoneSchoolMeetProgressNotesTreatmentId)
{
  let date = '2021-05-28';

  this.spinnerservice.show();
  this.clinicianservice.getTreatmentNotesById(phoneSchoolMeetProgressNotesTreatmentId).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if (res) {
        this.PhoneSchoolInfo.Content = res.content;
        this.PhoneSchoolInfo.FormOfCommunication = res.formOfCommunication;
        this.PhoneSchoolInfo.WhomCommunicated = res.whomCommunicated;
        this.PhoneSchoolInfo.StaffInitial1 = res.staffInitial1;
        this.PhoneSchoolInfo.StaffInitials = res.staffInitials;
        this.PhoneSchoolInfo.SignDate= res.signDate;
        
      this.Checkin =moment(date).format("YYYY-MM-DD") + " " + moment( res.checkin).format('HH:mm')+":00.000";
      this._dtoPatientInfo.StartDate=res.createdDate1;
      this._dtoPatientInfo.StartTime=this.Checkin;
      this.CheckOut =moment(date).format("YYYY-MM-DD") + " " + moment( res.checkOut).format('HH:mm')+":00.000";
      this.OnCommunicationChange(1)

          
      } else {
        // this.onAddMedication();
        this.NoRecord = "No record found";
      }

    },
    err => {
      this.spinnerservice.hide();
    },
  );

}

ondeleteNotePopup(phoneSchoolMeetProgressNotesTreatmentId) {
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: '',
      message: constantVariables.SuccessMessage.DeleteConfirmMessage
    }
  });
  confirmDialog.afterClosed().subscribe(result => {
    if (result === true) {
      this.spinnerservice.show();
      this.clinicianservice.onDeletetreatmentnotes(phoneSchoolMeetProgressNotesTreatmentId).subscribe(
        (res: any) => {
          console.log(res)
          this.spinnerservice.hide();
          this. getTreatmentNotes();
          this.snackbar.success(constantVariables.SuccessMessage.DeletetedNotesSuccesscully); 

        },

        
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
    }
  });
}

secondarySign():any{
  if(this._dtoPatientInfo[0].licenseNumber != '' && this._dtoPatientInfo[0].licenseNumber != null ){
    return false;
  }
  else if(this._dtoPatientInfo[0].sSignature ){
   return false;
  }
  else{
    return true;
  }
}
getAutoFillSignature() {
  this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
      this.GetClinicianSign = res;

      if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
        this.AutoFillSS=false;
        this.SSignature=this.GetClinicianSign[0].clinicianSign;
      }
      else
      {
        this.AutoFillSS=true;
        this.SSignature='';
      }
  });
}
}

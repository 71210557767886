
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router,ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { BillingService } from 'src/app/billing/billing.service';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import {transactionForm} from 'src/app/_models/_Billing/transactionForm';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';



declare function decrypt(data): any;

@Component({
  selector: 'app-claims-lists',
  templateUrl: './claims-lists.component.html',
  styleUrls: ['./claims-lists.component.scss']
})
export class ClaimsListsComponent implements OnInit {
  public searchclaimListtxt : string;
  drpBillstatus = constantVariables.billStatus;
  drpProvider:any;
  drpLocation:any;
  toggleStyle: boolean = false;
  claimLists:any=[];
  tmpClaimLists:any=[];
  
  selectedProvider:any;
  selectedLocation:any;
  drpInsPriority:any;
  drpBillStatus:any;
  // selectedInsPriority:any;
  // arrSelectedInsPriority=[];
  // arrselectedBstatus=[];
   selectedBstatus:any;
  DOSTo:any;
  DOSFrom:any;
  maxDate:any;
  temparryins:any=[];
  temparrbillsts:any=[];
  isCpt:boolean=false;
  isUB04:boolean=false;
  isDos:boolean=false;
  isDat:boolean=false;
  selectedBForm:any;
  selectedFromDate:any;
  selectedToDate:any;
  drpInsuranceGrp:any;
  selectedInsGrp:any;
  dtoTransactionfrm: transactionForm;
  roleAccess: any;
   constructor(
    public billingservice: BillingService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, private route: ActivatedRoute,
    private router: Router, public commonService: CommonService, public spinnerservice: NgxSpinnerService,public datepipe: DatePipe
    ,public roleaccessservice:PageAccessService
    ) { }
 
  ngOnInit(): void {
  
    this.maxDate = new Date();
    this.refreshFilters();
  
    const pageid = constantVariables.pagelists.InsuranClaims;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);

    this.getMastersforClaimsList();
    this.getclaimslists();
    }
  refreshFilters()
  {
    this.isCpt=false;
    this.isUB04=false;
    this.isDos=false;
    this.isDat=false;
    this.selectedBForm="0";
    this.selectedProvider=0;
    this.selectedLocation=0;
     this.selectedBstatus=0;
     this.selectedInsGrp=0;
   }
  getclaimslists(){
    this.drpProvider=[];
    this.drpLocation=[];
    this.drpInsuranceGrp=[];
  this.spinnerservice.show();
  this.billingservice.getClaimLists().subscribe(
    (res: any)  => {
     if(res.objInputString!=null)
      {
         this.claimLists = this.commonService.decrypdata(res.objInputString);
         this.tmpClaimLists = this.claimLists;
        let tmplist=this.claimLists;
        let tmpLoclist=this.claimLists;
        let tmpInsGrp=this.claimLists;
        if(Number(localStorage.getItem('sfcclocation'))>0){
        this.tmpClaimLists=this.tmpClaimLists.filter(x=>x.SfccMasterId==localStorage.getItem('sfcclocation'));
        this.claimLists=this.tmpClaimLists;
       }
       if(this.drpProvider.length==0 || this.drpProvider==undefined) {
         this.drpProvider= tmplist.filter(
                   (t2, i, arr) => arr.findIndex(t => t.Rprovider === t2.Rprovider && t.Rprovider !=0) === i );
       }
       if(this.drpLocation.length==0 || this.drpLocation==undefined) {
        this.drpLocation= tmpLoclist.filter(
                  (t2, i, arr) => arr.findIndex(t => t.SfccMasterId === t2.SfccMasterId && t.SfccMasterId !=0) === i );
      }
      
      if(this.drpInsuranceGrp.length==0 || this.drpInsuranceGrp==undefined) {
        this.drpInsuranceGrp= tmpInsGrp.filter(
                  (t2, i, arr) => arr.findIndex(t => t.InsuranceId === t2.InsuranceId && t.InsuranceId !=0) === i );
      }
     }
    
     this.onCptUb04click(1);
     this.spinnerservice.hide();
       },
    err => {
      this.spinnerservice.hide();
    },
  );
}
getCListFilterby()
{
  this.claimLists=this.tmpClaimLists;
 if(this.selectedLocation!=0  && this.selectedBForm=="0" && this.selectedInsGrp=="0" )
{
this.claimLists=this.claimLists.filter(x=>x.SfccMasterId==this.selectedLocation);
}  
else if(this.selectedLocation==0  && this.selectedBForm!="0" && this.selectedInsGrp=="0" )
{
this.claimLists=this.claimLists.filter(x=>x.BFormType==this.selectedBForm);
}
else if(this.selectedLocation==0 && this.selectedBForm=="0" && this.selectedInsGrp!="0")
{
this.claimLists=this.claimLists.filter(x=> x.InsuranceId==this.selectedInsGrp);
}
else if(this.selectedLocation!=0 && this.selectedBForm!="0" && this.selectedInsGrp=="0")
{
this.claimLists=this.claimLists.filter(x=> x.SfccMasterId==this.selectedLocation && x.BFormType==this.selectedBForm);
}
else if(this.selectedLocation==0 && this.selectedBForm!="0" && this.selectedInsGrp!="0")
{
this.claimLists=this.claimLists.filter(x=> x.InsuranceId==this.selectedInsGrp && x.BFormType==this.selectedBForm);
}
else if(this.selectedLocation!=0 && this.selectedBForm=="0" && this.selectedInsGrp!="0")
{
this.claimLists=this.claimLists.filter(x=> x.InsuranceId==this.selectedInsGrp && x.SfccMasterId==this.selectedLocation);
}

else if(this.selectedLocation!=0 && this.selectedBForm!="0" && this.selectedInsGrp!="0")
{
this.claimLists=this.claimLists.filter(x=> x.SfccMasterId==this.selectedLocation && x.BFormType==this.selectedBForm&& x.InsuranceId==this.selectedInsGrp);
}
else{this.claimLists=this.tmpClaimLists;}
if(this.isDat)
{
  this.claimLists=this.claimLists.filter(x=>(this.datepipe.transform(x.DOSFrom,"yyyy-MM-dd"))==this.selectedFromDate) ;
}
else if(this.isDos)
{
  this.claimLists = this.claimLists.filter(m =>Date.parse(this.datepipe.transform(m.DOSFrom, "yyyy-MM-dd")) >= Date.parse(this.selectedFromDate) && 
                                 Date.parse(this.datepipe.transform(m.DOSFrom, "yyyy-MM-dd")) <= Date.parse(this.selectedToDate));
}

}
// getCListFilterbyold()
// {
//   this.claimLists=this.tmpClaimLists;
// if( this.selectedBstatus!=0 && this.selectedProvider==0 && this.selectedLocation==0 && this.selectedBForm=="0"  && this.selectedInsGrp=="0" )
// {
// this.claimLists=this.claimLists.filter(x=>x.BillStatus==this.selectedBstatus);
// }
// else if( this.selectedBstatus==0 && this.selectedProvider!=0 && this.selectedLocation==0 && this.selectedBForm=="0" && this.selectedInsGrp=="0" )
// {
// this.claimLists=this.claimLists.filter(x=>x.Rprovider==this.selectedProvider);
// }
// else if( this.selectedBstatus==0 && this.selectedProvider==0 && this.selectedLocation!=0  && this.selectedBForm=="0" && this.selectedInsGrp=="0" )
// {
// this.claimLists=this.claimLists.filter(x=>x.SfccMasterId==this.selectedLocation);
// }  
// else if( this.selectedBstatus==0 && this.selectedProvider==0 && this.selectedLocation==0  && this.selectedBForm!="0" && this.selectedInsGrp=="0" )
// {
// this.claimLists=this.claimLists.filter(x=>x.BFormType==this.selectedBForm);
// }
// else if(this.selectedBstatus!=0 && this.selectedProvider!=0 && this.selectedLocation!=0 && this.selectedBForm!="0" && this.selectedInsGrp=="0")
// {
// this.claimLists=this.claimLists.filter(x=>x.BillStatus==this.selectedBstatus && x.Rprovider==this.selectedProvider && x.SfccMasterId==this.selectedLocation && x.BFormType==this.selectedBForm);
// }
// else{this.claimLists=this.tmpClaimLists;}
// if(this.isDat)
// {
//   this.claimLists=this.claimLists.filter(x=>(this.datepipe.transform(x.DOSFrom,"yyyy-MM-dd"))==this.selectedFromDate) ;
// }
// else if(this.isDos)
// {
//   this.claimLists = this.claimLists.filter(m =>Date.parse(this.datepipe.transform(m.DOSFrom, "yyyy-MM-dd")) >= Date.parse(this.selectedFromDate) && 
//                                  Date.parse(this.datepipe.transform(m.DOSFrom, "yyyy-MM-dd")) <= Date.parse(this.selectedToDate));
// }

// }
getMastersforClaimsList(){
  this.spinnerservice.show();
  this.billingservice.getClaimsListsMstr().subscribe(
    res => {
      if (res != null) {
      let result=decrypt(res.objInputString);
      // console.log(result);
      // debugger;
       this.drpInsPriority=result.InsurancePriority;
         this.drpBillStatus=result.BillStatus;
        this.spinnerservice.hide();
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}

onInspriority(event)
{
  this.refreshFilters();
 let tmp=this.tmpClaimLists.find(x=>x.InsurancePriority==event.target.value);
 if((event.target.checked)&&(tmp!=null && tmp!="undefined"))
 {
   this.claimLists=this.tmpClaimLists;
  this.temparryins = this.claimLists.filter((e:any)=>e.InsurancePriority==event.target.value);
 }
 else if((event.target.checked==false)&&(this.temparryins.length>0))
  {
     if(tmp!=null && tmp!="undefined"){
       this.temparryins =  this.temparryins.filter((e:any)=>e.InsurancePriority!=event.target.value);
      }
  }
  if(this.temparryins.length>0)
  {
    this.claimLists=[];
    this.claimLists=this.temparryins;
  }
  else{this.claimLists=this.tmpClaimLists;}
 
}
onBillStatus(event)
{
  this.refreshFilters();
 let tmp2=this.tmpClaimLists.find(x=>x.BillStatusId==event.target.value);
 if((event.target.checked)&&(tmp2!=null && tmp2!="undefined"))
 {
   this.claimLists=this.tmpClaimLists;
   this.temparrbillsts = this.claimLists.filter((e:any)=>e.BillStatusId==event.target.value);
 }
 else if((event.target.checked==false)&&(this.temparrbillsts.length>0))
  {
     if(tmp2!=null && tmp2!="undefined"){
       this.temparrbillsts =  this.temparrbillsts.filter((e:any)=>e.BillStatusId!=event.target.value);
      }
  }
  if(this.temparrbillsts.length>0)
  {
    this.claimLists=[];
    this.claimLists=this.temparrbillsts;
  }
  else{this.claimLists=this.tmpClaimLists;}
 
}
onCptUb04click(val){
  if(val==1){ 
    this.isCpt=true;
    this.isUB04=false;
    this.selectedBForm="CPTCODE";
    }
  else if(val==2){ 
    this.isCpt=false;
    this.isUB04=true;
    this.selectedBForm="UB04";
   }
  this.getCListFilterby();
}
onDosDatFilter(filterval)
{
  if(filterval==1){ 
    this.isDos=false;
    this.isDat=true;
   }
  else if(filterval==2){ 
    this.isDos=true;
    this.isDat=false;
    }
}
onChangedosfrom(event,isDos=false)
{
  if(isDos==true){
    this.selectedFromDate=this.datepipe.transform(event.value, "yyyy-MM-dd");
    return;
  }
this.selectedFromDate=this.datepipe.transform(event.value, "yyyy-MM-dd");
this.getCListFilterby();
}
onChangedosTo(event)
{
    this.selectedToDate=this.datepipe.transform(event.value, "yyyy-MM-dd");
    this.getCListFilterby();
}

onCreateclaim(item)
{
  this.router.navigate(['/bill'], { queryParams: { id: this.commonService.FrontEndEncryption(item.PatientAccountId),dsId:this.commonService.FrontEndEncryption(item.DayScheduleAssignId),patient:this.commonService.FrontEndEncryption(item.PatientInfoId)
     ,therapyId:this.commonService.FrontEndEncryption(item.TherapyId)
     ,transactionCode:this.commonService.FrontEndEncryption(item.TransCode)
    ,msTId:this.commonService.FrontEndEncryption(item.MeetingScheduleTimeId),
    fromClaims:this.commonService.FrontEndEncryption("true")
   
  } });
}


//testing to Availity

avTest()
{
  this.dtoTransactionfrm = new transactionForm();
  this.dtoTransactionfrm.IsCpt=false;
  this.dtoTransactionfrm.IsUB04=false;
  const dataToEncrypt = this.commonService.DataToEncrypt(this.dtoTransactionfrm, false);
  this.billingservice.postAVtest(dataToEncrypt).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
         console.log(res);
     },
    err => {
      this.spinnerservice.hide();
      console.log(err.error);
     // this.snackbar.error(err.error);
    },
  );
}

getMethodAvtest(){
  this.spinnerservice.show();
this.billingservice.getMethodAvtest().subscribe(
  (res: any)  => {
    this.spinnerservice.hide();
    debugger;
   console.log(res);
   this.spinnerservice.hide();
     },
  err => {
    this.spinnerservice.hide();
  },
);
}


}


<div class="row p-heading">
    <div class="col justify-content-start">
   
        <h2 class="head-section mt-2">Manage permission </h2>
    </div>
</div>
<div class="col-md-12 p-0 mb-3">
    <div class="lead-box p-0">
        <form (ngSubmit)="userinfo.form.valid  && onSubmitRole()" id="userinfo" autocomplete="off"
            #userinfo="ngForm" novalidate>
            <div class="box-lead-head"></div>
            <div class="col-md-12 p-0">
                <div class="row">
                    <div class="col-md-3">
                        <mat-form-field class="w-100">
                            <mat-label>Role</mat-label>
                            <mat-select [(ngModel)]="dtorole.roleId" #roleId="ngModel" name="roleId"
                                [ngClass]="{ 'is-invalid': userinfo.submitted && roleId.invalid }" required>
                                <mat-option *ngFor="let item of allRoles" [value]="item.id" (click)="getroleAccess()">
                                    {{item.roleName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="userinfo.submitted && roleId.invalid">
                                <mat-error *ngIf="roleId.errors.required">Role is required
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">
                        <mat-form-field class="w-100">
                            <mat-label>Location</mat-label>
                            <mat-select [disabled]="dtorole.isAll == true" [(ngModel)]="dtorole.location" #location="ngModel" name="location"
                            [ngClass]="{ 'is-invalid': userinfo.submitted && location.invalid }" required>
                            <mat-option *ngFor="let item of ddlSfccLocation" [value]="item.sfccMasterId" (click)="getroleAccess()">
                                {{item.locationName}}
                            </mat-option>
                        </mat-select>
                            <mat-error *ngIf="userinfo.submitted && location.invalid && !dtorole.isAll">
                                <mat-error *ngIf="location.errors.required">Location  is required
                                </mat-error>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-3" >
                        <div class="check-custom mt-4">
                            <mat-checkbox class="example-margin" [(ngModel)]="dtorole.isAll"
                                #isActive="ngModel" name="parentIsPrimaryContact" (change)="onCheck($event)">All Locations
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-3 text-right mt-2">

                        <button type="submit" mat-stroked-button form="userinfo" title="Save"
                            class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" *ngIf="dtorole.details != null && dtorole.details.length != 0" (click)="onSubmitRole()">Save</button>
                        <!-- <button (click)="resetuserform(userinfo)" type="button" mat-stroked-button title="Cancel"
                            class="btn btn-cancel primary-btn-m h-40">Cancel</button> -->
                    </div>
                </div>
            </div>
            
        </form>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12 d-none" *ngIf="dtorole.details != null && dtorole.details.length != 0">
                <label _ngcontent-xld-c306="" class="list-head">Select Module</label>
                <div class="module-list">
                    <ng-container *ngFor="let a of dtorole.details">
                        <div class="module-list-sec">
                            <div class="check-custom ">
                                <mat-checkbox class="example-margin">{{a.pageName}} Management
                                </mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                    

                </div>
            </div>
            <ng-container *ngFor="let a of dtorole.details">
             <div class="module-content-permisiion col-md-12">
                <div class="module-content-name">
                    <!-- {{a.pageName}} Management -->
                    Intake
                </div>
               
                <div class="module-perm-list">
                    <div class="head-setting">
                        Create a Lead Account
                    </div>
                    
                    <div class="module-list-sec-perm">
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" [(ngModel)]="a.isCreate">Create  
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" [(ngModel)]="a.isEdit"> Edit/Reschedule
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" [(ngModel)]="a.isDelete"> Delete
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                        
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" [(ngModel)]="a.isView">View 
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                       
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" >Approve/Reject 
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                        
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" >Signature 
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="module-perm-list">
                    <div class="head-setting">
                        Patient Account
                    </div>
                    
                    <div class="module-list-sec-perm">
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" >Create  
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin"> Edit/Reschedule
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" > Delete
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                        
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" >View 
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                       
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" >Approve/Reject 
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                        
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" >Signature 
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <!-- <div class="module-perm-list">
                    <div class="module-list-sec-perm">
                        <label>Reportee</label>
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" [(ngModel)]="a.isViewReport">View All
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" [(ngModel)]="a.isCreateReport">Create New 
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" [(ngModel)]="a.isEditReport"> Edit
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="module-list-sec-perm">
                        <div class="check-custom ">
                            <mat-checkbox class="example-margin" [(ngModel)]="a.isDeleteReport"> Delete
                            </mat-checkbox>
                        </div>
                    </div>
                </div> -->
            </div>
        </ng-container>
       


        </div>

    </div>
    <div class="col-md-12 text-right">
        <button mat-stroked-button
            class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" *ngIf="dtorole.details != null && dtorole.details.length != 0" (click)="onSubmitRole()">Save</button>
        <button mat-stroked-button class="btn btn-cancel primary-btn-m h-40" (click)="getroleAccess()" *ngIf="dtorole.details != null && dtorole.details.length != 0">Cancel</button>
    </div>

</div>
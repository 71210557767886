<div *ngIf="isGoalView">
    <!-- <div class="top-btn-section clearfix mb-3 col-md-12">

       <h4 class="head-section mt-0 ">Goals</h4>
    </div> -->
    <div class="top-btn-section col-md-12 clearfix mb-1 w-100">
        <h2 class="head-section mt-3">Goals</h2>
        <div class="col-md-6 ml-auto mt-2 pr-0">
            <button *ngIf="isGoalDelete" class="btn primary-btn-outlined float-right" [disabled]="goalids?.length == 0"
                (click)="ondeletemultipleGoal()"><i class="fas fa-trash mr-2"></i>Delete
                Goals</button>
            <button *ngIf="isGoalCreate" type="button" [routerLink]="['/config-goal']"
                class="btn primary-btn-outlined float-right mr-2"><i class="fas fa-plus-circle mr-2"></i>Add
                Goal</button>

        </div>
    </div>

    <div class="section-consent">

        <div class="list-template">
            <ul class="col-md-12 nomargin">
                <li *ngFor="let item of lstGoals;let i=index" class="col-md-12">
                    <div class="card mt-2">
                        <div class="card-body p-0">
                            <div class="row">
                                <div class="col-md-12 goals-viewd">
                                    <div class="py-4 position-relative">
                                        <div class="row">
                                            <div class="col-md-10">
                                                <div class="row">
                                                    <div class="col-md-7">

                                                        <div class="check-custom invi-check">
                                                            <mat-checkbox class="example-margin"
                                                                (change)="cbklistGoal($event,item, i)" name="tag{{i}}">
                                                            </mat-checkbox>
                                                        </div>
                                                        <div class="formed-type pl-5">
                                                            <span class="text-truncate d-block">{{item.goalName}}</span>
                                                            <label class="text-primary">Goal Name</label>
                                                        </div>




                                                    </div>
                                                   <div class="col-md-3">

                                                       <!-- <div class="formed-type ">
                                                            <span
                                                                class="text-truncate d-block"><span *ngFor="let cat of item.goalCatagoryId let last=last let i=index" >
                                                                    <span *ngFor="let name of ddlGoalCatagory">
                                                                     <span *ngIf="name.goalCatagoryId==cat" >
                                                                         {{name.catagoryName}}
                                                                     </span>
                                                                    </span>
                                                                </span> </span>
                                                            <label class="text-primary">Category </label>
                                                        </div>-->


                                                    </div> 
                                                    <div class="col-md-2 text-right">
                                                        <div class="questionsnumber-list col-md-12 p-0 text-right mb-2">
                                                            <span class="formed-type2 mr-1 "><label>LMTP -</label><span
                                                                    class="pl-1">0</span></span>

                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="row pl-5">
                                                    <div class="col-md-6">
                                                        <label
                                                            class="list-head sub-head-list col-md-12 p-0">Description</label>
                                                        <p class="float-left w-100 list-value-new"
                                                            innerHTML="{{global.getContent(item.definition)}} "> </p>
                                                        <!-- <a   class="btn-mored">More..</a> -->
                                                        <div class="col-md-12 p-0">
                                                            <span class="val-list  mr-4">Created on :
                                                                {{global.globalUtctimeformat(item.createDate)}}</span>
                                                            <span class="val-list">Updated on :
                                                                {{global.globalUtctimeformat(item.updateDate)}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 border-left">
                                                        <div class="mb-2 ">
                                                            <label class="list-head m-0 p-0">Measure Type:</label>
                                                            <span
                                                                class="list-value-new pl-2">{{item.measureTypeName}}</span>
                                                        </div>
                                                        <div class="mb-2 ">
                                                            <label class="list-head m-0 p-0">Category:</label>
                                                            <span *ngFor="let catName of item.catagoryName let last=last"
                                                                class="list-value-new pl-2">{{catName}}{{last?'':','}}</span>
                                                        </div>
                                                        <div class="mb-2 ">
                                                            <div class="w-75 d-inline-block">
                                                                <label class="list-head m-0 p-0">Applicable Treatment :</label>
                                                                <span *ngFor="let therapy of item.applicableTreatment let last=last"
                                                                    class="list-value-new pl-2">
                                                                    {{therapy==1?'Individual Therapy':therapy==2?'Family Therapy':therapy==3?'Group Therapy':therapy==4?'Psychiatric Therapy':therapy==5?'Nutrition Therapy':therapy==6?'Speech Theraphy':therapy==7?'Psychiatric Evaluation':therapy==8?'Psychiatric Medication':therapy==11?'Speech Therapy':'Nutrition Therapy'}}{{last?'':','}} 

                                                                </span>
                                                            </div>
                                                            <div class="w-25 d-inline-block align-top text-right">
                                                                <span class="formed-type2 "><label>No. of BM
                                                                        -</label><span
                                                                        class="pl-1">{{item?.measures?.length}}</span></span>
                                                            </div>


                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="row pl-5">

                                                </div>

                                            </div>
                                            <div class="col-md-2 border-left">
                                                <div class=" clearfix">
                                                    <div class="result_action float-left w-100">

                                                    </div>
                                                    <div class="result_action_btn float-left w-100 mt-3">

                                                        <a *ngIf="isGoalEdit" title="edit"
                                                            (click)="oneditGoal(item.goalId)"
                                                            class="btn line-btn cursor-pointer">Edit</a>
                                                        <a *ngIf="isGoalDelete" (click)="ondeleteGoal(item)"
                                                            title="delete"
                                                            class="btn line-btn cursor-pointer">Delete</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </li>

            </ul>
            <div class="col-md-12">
                <mat-paginator #pagGoal class="pagination pagination-large" (page)="onPageChangeGoal($event)"
                    [pageSize]="20" [pageSizeOptions]="[ 10, 20, 50, 75, 100]">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isGoalView">
    {{viewRightsMsg}}
    </div>
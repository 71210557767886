<div class="clinical-review-page">
    <form (ngSubmit)="crbafrm.form.valid  && savecrbainfo(crbafrm)" id="crbafrm" autocomplete="off" #crbafrm="ngForm"
        novalidate>
        <div class="row top-btn-section clearfix">
            <h2 class="head-section mb-2 mt-0 col-md-5">CLINICAL REVIEW BENEFITS AND AUTHORIZATIONS</h2>
            <div class="col-md-7 text-right">
                <button *ngIf ="generatePdf" type="button"(click)="GetBenefitsAndAuthoizationPdf()"
                class="btn mr-2 back-btn-new float-none" >
                <i class="far fa-file-pdf mr-2" ></i>Generate PDF
              </button> 
                    <button class="btn mr-2 back-btn-new float-none" (click)="onBack()"><i
                            class="fas fa-chevron-left mr-2"></i>Back</button>
        </div>
        
        </div>
        <div class="top-btn-section mb-2" *ngIf="basicDetails">
            <a class="link-btn-client cursor-pointer mt-1" (click)="redirectLeadInfo()">{{basicDetails.userName}}</a>
            <div class="stright-label">
                <label>DOB:</label>
                <span> {{basicDetails.dob | date:
                    'MM/dd/yyyy'}}</span>
            </div>
            <!-- <div class="stright-label">
                <label>Insurance ID:</label>
                <span>{{basicDetails.insuranceId}}</span>
            </div> -->
        </div>


        <div class="lead-box mb-3">
            <div class="col-md-12 p-0">
                <div class="row">
                    <div class="col-md-4">
                        <div class="col-md-12 p-0">
                            <div class="form-group">
                                <label for="exampleInputEmail1" class="crb-label">Program</label>
                                <div class="col-md-12 p-0">
                                    <mat-radio-group aria-label="Select an option"
                                        [(ngModel)]="crbainfo.programDetails.programType" #programType="ngModel"
                                        name="programType" required>
                                        <mat-radio-button class="mr-1" [value]="1">PCP</mat-radio-button>
                                        <mat-radio-button class="mr-1" [value]="2">IOP</mat-radio-button>
                                        <mat-radio-button class="mr-1" [value]="3">Outpatient</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-error *ngIf="crbafrm.submitted && programType.invalid">
                                        <mat-error class="" *ngIf="programType.errors.required">program type is required
                                        </mat-error>
                                    </mat-error>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-6">
                        <div class="col-md-12 p-0">
                            <div class="form-group">
                                <label for="exampleInputEmail1" class="crb-label">Program Days</label>
                                <div class="col-md-12 p-0">
                                    <div class="check-list-custom">
                                        <mat-selection-list class="check-custom d-inline-block mr-2" required
                                            #program="ngModel" name="program" [(ngModel)]="prgdays"
                                            [ngModelOptions]="{standalone: true}">
                                            <mat-list-option [value]="a.key" *ngFor="let a of programdays">
                                                {{a.text}}
                                            </mat-list-option>
                                        </mat-selection-list>
                                        <mat-error *ngIf="crbafrm.submitted && program.invalid">
                                            <mat-error *ngIf="program.errors.required">Program Days is required
                                            </mat-error>
                                        </mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-md-2">
                        <div class="example-form mt-2">
                            <mat-form-field class="w-100">
                                <mat-label>Start Date</mat-label>
                                <input matInput [matDatepicker]="pstpicker" readonly 
                                    (click)="pstpicker.open()" [(ngModel)]="crbainfo.programDetails.startDate"
                                    #pstartDate="ngModel" name="pstartDate"
                                    [ngClass]="{ 'is-invalid': crbafrm.submitted && pstartDate.invalid }">
                                <mat-datepicker-toggle matSuffix [for]="pstpicker">
                                </mat-datepicker-toggle>
                                <mat-datepicker #pstpicker></mat-datepicker>                              
                                <!-- <mat-error *ngIf="crbafrm.submitted && pstartDate.invalid">
                                    <mat-error *ngIf="pstartDate.errors.required">Start Date is
                                        required</mat-error>
                                </mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>






        </div>
        <div class="col-md-12 p-0">
            <div class="lead-box">
                <div class="row">
                    <div class="col-md-12">
                        <div class="box-lead-head">Insurance</div>

                        <div class="col-md-12 p-0">
                            <div class="row">
                                <div class="col-md-3">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="Carrier Name" appAlphabetOnly maxlength="50"
                                            [(ngModel)]="crbainfo.insuranceDetails.carrierName" #carrierName="ngModel"
                                            name="carrierName"
                                            [ngClass]="{ 'is-invalid': crbafrm.submitted && carrierName.invalid }"
                                            >
                                        <mat-error *ngIf="crbafrm.submitted && carrierName.invalid">
                                            <mat-error *ngIf="carrierName.errors.required">Carrier name is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">

                                    <mat-form-field class="w-100">

                                        <mat-label>Insurance Type</mat-label>
                                        <mat-select [(ngModel)]="crbainfo.insuranceDetails.insuranceTypeId"
                                            #insuranceTypeId="ngModel" name="insuranceTypeId"
                                            [ngClass]="{ 'is-invalid': crbafrm.submitted && insuranceTypeId.invalid }"
                                            >
                                            <mat-option *ngFor="let item of ddlInsuranceType"
                                                [value]="item.InsuranceTypeId">
                                                {{item.InsuranceTypeName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="crbafrm.submitted && insuranceTypeId.invalid">
                                            <mat-error *ngIf="insuranceTypeId.errors.required">Insurance Type is
                                                required</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="Member ID" specialIsAlphaNumeric maxlength="30"
                                            [(ngModel)]="crbainfo.insuranceDetails.memberId" #memberId="ngModel"
                                            name="memberId"
                                            [ngClass]="{ 'is-invalid': crbafrm.submitted && memberId.invalid }"
                                            >
                                        <mat-error *ngIf="crbafrm.submitted && memberId.invalid">
                                            <mat-error *ngIf="memberId.errors.required">Member ID is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="Group Number" specialIsAlphaNumeric maxlength="25"
                                            [(ngModel)]="crbainfo.insuranceDetails.groupNo" #groupNo="ngModel"
                                            name="groupNo"
                                            [ngClass]="{ 'is-invalid': crbafrm.submitted && groupNo.invalid }" >
                                        <mat-error *ngIf="crbafrm.submitted && groupNo.invalid">
                                            <mat-error *ngIf="groupNo.errors.required">Group Number is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <div class="insurance-holder">
                                        Policy Holder
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="First Name" appAlphabetOnly maxlength="50"
                                            [(ngModel)]="crbainfo.insuranceDetails.firstName" #firstName="ngModel"
                                            name="firstName"
                                            [ngClass]="{ 'is-invalid': crbafrm.submitted && firstName.invalid }"
                                            >
                                        <mat-error *ngIf="crbafrm.submitted && firstName.invalid">
                                            <mat-error *ngIf="firstName.errors.required">First name is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="Last Name" appAlphabetOnly maxlength="50"
                                            [(ngModel)]="crbainfo.insuranceDetails.lastName" #lastName="ngModel"
                                            name="lastName"
                                            [ngClass]="{ 'is-invalid': crbafrm.submitted && lastName.invalid }"
                                            >
                                        <mat-error *ngIf="crbafrm.submitted && lastName.invalid">
                                            <mat-error *ngIf="lastName.errors.required">Last name is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field class="w-100">
                                        <input matInput placeholder="SSN" mask='000-00-0000'
                                            [(ngModel)]="crbainfo.insuranceDetails.ssid" #ssid="ngModel" name="ssid"
                                            [ngClass]="{ 'is-invalid': crbafrm.submitted && ssid.invalid }" required>
                                        <mat-error *ngIf="crbafrm.submitted && ssid.invalid">
                                            <mat-error *ngIf="ssid.errors.required">SSN is required</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">

                                    <mat-form-field class="w-100">

                                        <mat-label>Relationship to Insured</mat-label>
                                        <mat-select [(ngModel)]="crbainfo.insuranceDetails.insuranceRelationShipId"
                                            #insuranceRelationShipId="ngModel" name="insuranceRelationShipId"
                                            [ngClass]="{ 'is-invalid': crbafrm.submitted && insuranceRelationShipId.invalid }"
                                            >
                                            <mat-option *ngFor="let item of ddlInsuranceRelationShip"
                                                [value]="item.InsuranceRelationShipId">
                                                {{item.RelationShip}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="crbafrm.submitted && insuranceRelationShipId.invalid">
                                            <mat-error *ngIf="insuranceRelationShipId.errors.required">Insurance
                                                RelationShip is
                                                required</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-12">
                                    <div class="insurance-holder">
                                       Client Address
                                    </div>

                                 
                                </div>
                                <div class="col-md-12">
                                    <label class="stright-label">Address: <span>{{crbainfo.clientAddress.addressLine1}}</span></label> 
                                    <label class="stright-label ml-2">State: <span>{{crbainfo.clientAddress.stateName}}</span></label> 
                                    <label class="stright-label ml-2">City: <span>{{crbainfo.clientAddress.city}}</span></label> 
                                    <label class="stright-label ml-2">Zip: <span>{{crbainfo.clientAddress.zip}}</span></label> 
                                </div>

                                 <div class="col-md-12">
                                    <div class="insurance-holder">
                                        Insurance Claim Address
                                    </div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-3 statealign">
                                                <mat-form-field class="w-100">
                                                    <!-- <input matInput placeholder="Address" appAlphabetOnly maxlength="50"
                                                        
                                                        > -->
                                                    <input matInput placeholder="Address"  maxlength="150"
                                                        [(ngModel)]="crbainfo.benefitDetails.address" #address="ngModel"
                                                        name="address"
                                                        [ngClass]="{ 'is-invalid': crbafrm.submitted && address.invalid }"
                                                        >
                                                    <!-- <mat-error *ngIf="crbafrm.submitted && carrierName.invalid">
                                                        <mat-error *ngIf="carrierName.errors.required">Carrier name is required
                                                        </mat-error>
                                                    </mat-error> -->
                                                </mat-form-field>
                                            </div>
                                          <div class="col-md-3 statealign">
                                            <mat-form-field class="w-100">
                                              <mat-label>State</mat-label>
                                              <mat-select [(ngModel)]="crbainfo.benefitDetails.stateId" #stateId="ngModel"
                                            
                                                 name="stateId" (selectionChange)="onStateChnage($event.value)">
                                                 <!-- [ngClass]="{ 'is-invalid': crbafrm.submitted && stateId.invalid }" required> -->
                                                <mat-option *ngFor="let item of ddlStates" [value]="item.StatesId">
                                                  {{item.StateName}}
                                                </mat-option>
                                              </mat-select>
                                              <!-- <mat-error *ngIf="(leadinfo.submitted && StatesId.invalid)||isSubmit">
                                                <mat-error *ngIf="StatesId.errors?.required">State is required</mat-error>
                                              </mat-error> -->
                                            </mat-form-field>
                                          </div>
                        
                                          <div class="col-md-3">
                        
                                            <mat-form-field class="w-100">
                        
                                              <mat-label>City</mat-label>
                        
                                              <mat-select  placeholder="City"  [(ngModel)]="crbainfo.benefitDetails.cityId" #cityId="ngModel" name="cityId"  (selectionChange)="onChangeProviderinfoCity($event)">
                                                <!-- <cdk-virtual-scroll-viewport [itemSize]="35" [style.height.px]=5*35 > -->
                                                 <mat-option *ngFor="let item of ddlCitiesProviderinfo" [value]="item.CitiesId"
                                                  >{{item.CityName}}</mat-option>
                                                <!-- </cdk-virtual-scroll-viewport> -->
                                            </mat-select>
                                              <!-- <mat-error *ngIf="(leadinfo.submitted && City.invalid)||isSubmit">
                                                <mat-error *ngIf="City.errors?.required">City is required</mat-error>
                                              </mat-error> -->
                                            </mat-form-field>
                                          </div>
                        
                                          <div class="col-md-3">
                                            <mat-form-field class="w-100">
                                              <input matInput placeholder="Zip" numeric minlength="5" maxlength="10"  [(ngModel)]="crbainfo.benefitDetails.zipcode"
                                                #zipcode="ngModel" name="zipcode" [ngClass]="{ 'is-invalid': crbafrm.submitted && carrierName.invalid }">
                                              <!-- <mat-error *ngIf="(leadinfo.submitted && Zip.invalid)||isSubmit">
                                                <mat-error *ngIf="Zip.errors?.required">Zip is required</mat-error>
                                              </mat-error> -->
                                            </mat-form-field>
                                          </div>
                                          <div class="col-md-3 statealign">
                                                <mat-form-field class="w-100">
                                                    <mat-label>Payer ID #</mat-label>
                                                    <input matInput placeholder="" maxlength="10" specialIsAlphaNumeric
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.payerId"
                                                        #payerId="ngModel"
                                                        name="payerId">
                                                    <!-- <mat-error
                                                        *ngIf="crbafrm.submitted && payerId.invalid">
                                                        <mat-error *ngIf="payerId.errors.required">
                                                            Reference is
                                                            required
                                                        </mat-error>
                                                    </mat-error> -->
                                                </mat-form-field>
                                           

                                        </div>
                                        </div>
                                      </div>
                                 
                                </div>



                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-12 p-0">
            <div class="lead-box">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                        <div class="col-md-12">
                            <div class="box-lead-head">BENEFITS</div>
                        </div>

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3 d-none">
                                    <div class="example-form">
                                        <mat-form-field class="example-full-width w-100">
                                            <mat-label>Name of Insurance Company</mat-label>
                                            <input matInput placeholder="" maxlength="30" specialIsAlphaNumeric
                                                [(ngModel)]="crbainfo.benefitDetails.insuranceCompany"
                                                #insuranceCompany="ngModel" name="insuranceCompany"
                                                [ngClass]="{ 'is-invalid': crbafrm.submitted && insuranceCompany.invalid }">
                                            <mat-error *ngIf="crbafrm.submitted && insuranceCompany.invalid">
                                                <mat-error *ngIf="insuranceCompany.errors.required">Company Name is
                                                    required</mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-3 d-none">
                                    <div class="example-form">
                                        <mat-form-field class="example-full-width w-100">
                                            <mat-label>Employee Name</mat-label>
                                            <input matInput placeholder="" maxlength="30"
                                                [(ngModel)]="crbainfo.authorizationsDetails.employeeName"
                                                appAlphabetOnly #employeeName="ngModel" name="employeeName"
                                                [ngClass]="{ 'is-invalid': crbafrm.submitted && employeeName.invalid }">
                                            <mat-error *ngIf="crbafrm.submitted && employeeName.invalid">
                                                <mat-error *ngIf="employeeName.errors.required">Employee name is
                                                    required
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="col-md-3 d-none">
                                    <div class="example-form">
                                        <mat-form-field class="w-100">
                                            <mat-label> Date</mat-label>
                                            <input matInput [matDatepicker]="infopicker" readonly
                                                (click)="infopicker.open()"
                                                [(ngModel)]="crbainfo.authorizationsDetails.infoDate"
                                                #infoDate="ngModel" name="infoDate"
                                                [ngClass]="{ 'is-invalid': crbafrm.submitted && infoDate.invalid }">
                                            <mat-datepicker-toggle matSuffix [for]="infopicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #infopicker></mat-datepicker>
                                            <mat-error *ngIf="crbafrm.submitted && infoDate.invalid">
                                                <mat-error *ngIf="infoDate.errors.required">Info Date is
                                                    required</mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <label for="exampleInputEmail1" class="col-md-12">Is there Out of Network
                                            Benefits?</label>
                                        <div class="col-md-12 ">
                                            <mat-radio-group aria-label="Select an option"
                                                [(ngModel)]="crbainfo.benefitDetails.outOfNetwork"
                                                #outOfNetwork="ngModel" name="outOfNetwork" required>
                                                <mat-radio-button class="mr-4" [value]="true">Yes</mat-radio-button>
                                                <mat-radio-button class="mr-4" [value]="false">No</mat-radio-button>
                                            </mat-radio-group>
                                            <mat-error *ngIf="crbafrm.submitted && outOfNetwork.invalid">
                                                <mat-error class="mat-form-field-subscript-wrapper"
                                                    *ngIf="outOfNetwork.errors.required">Is Out Of Network is
                                                    required</mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <label for="exampleInputEmail1" class="col-md-12">Is a Single Case
                                            Agreement needed?</label>
                                        <div class="col-md-12 ">
                                            <mat-radio-group aria-label="Select an option"
                                                [(ngModel)]="crbainfo.benefitDetails.isSingleCaseAgreement"
                                                #isSingleCaseAgreement="ngModel" name="isSingleCaseAgreement" required>
                                                <mat-radio-button class="mr-4" [value]="true">Yes</mat-radio-button>
                                                <mat-radio-button class="mr-4" [value]="false">No</mat-radio-button>
                                            </mat-radio-group>
                                            <mat-error *ngIf="crbafrm.submitted && isSingleCaseAgreement.invalid">
                                                <mat-error class="mat-form-field-subscript-wrapper"
                                                    *ngIf="isSingleCaseAgreement.errors.required">Is Single Case
                                                    Agreement is
                                                    required</mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <label for="exampleInputEmail1" class="col-md-12">Is self-funded?</label>
                                        <div class="col-md-12 ">
                                            <mat-radio-group aria-label="Select an option"
                                                [(ngModel)]="crbainfo.benefitDetails.isSelfFunded"
                                                #isSelfFunded="ngModel" name="isSelfFunded" required>
                                                <mat-radio-button class="mr-4" [value]="true">Yes</mat-radio-button>
                                                <mat-radio-button class="mr-4" [value]="false">No</mat-radio-button>
                                            </mat-radio-group>
                                            <mat-error *ngIf="crbafrm.submitted && isSelfFunded.invalid">
                                                <mat-error class="mat-form-field-subscript-wrapper"
                                                    *ngIf="isSelfFunded.errors.required">Is Single Case
                                                    Agreement is
                                                    required</mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="example-form">
                                        <mat-form-field class="example-full-width w-100">
                                            <mat-label>Co-insurance %</mat-label>
                                            <input matInput step="0" placeholder="" maxlength="8" numeric decimals="2"
                                                [(ngModel)]="crbainfo.benefitDetails.coInsurance" #coInsurance="ngModel"
                                                name="coInsurance"
                                                [ngClass]="{ 'is-invalid': crbafrm.submitted && coInsurance.invalid }"
                                                required>
                                            <mat-error *ngIf="crbafrm.submitted && coInsurance.invalid">
                                                <mat-error *ngIf="coInsurance.errors.required">Co-Insurance is required
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="example-form">
                                        <mat-form-field class="example-full-width w-100">
                                            <mat-label>Copay $</mat-label>
                                            <input matInput step="0" placeholder="" maxlength="8" numeric decimals="2"
                                                [(ngModel)]="crbainfo.benefitDetails.coPay" #coPay="ngModel"
                                                name="coPay"
                                                [ngClass]="{ 'is-invalid': crbafrm.submitted && coPay.invalid }"
                                                required>
                                            <mat-error *ngIf="crbafrm.submitted && coPay.invalid">
                                                <mat-error *ngIf="coPay.errors.required">Co-Pay is required
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    
                                    <mat-form-field  class="w-100">
                                        <mat-label>Deductible (Individual)</mat-label>
                                        <input matInput step="0" placeholder="" maxlength="8" numeric decimals="2"
                                        [(ngModel)]="crbainfo.benefitDetails.deductibleforindividual"
                                        #deductibleforindividual="ngModel" name="deductibleforindividual"
                                        [ngClass]="{ 'is-invalid': crbafrm.submitted && deductibleforindividual.invalid }"
                                        required>       
                                          <mat-error *ngIf="crbafrm.submitted && deductibleforindividual.invalid">
                                            <mat-error *ngIf="deductibleforindividual.errors.required">Individual
                                                Deductible  is required
                                            </mat-error>
                                        </mat-error>      
                                      </mat-form-field>
                                     
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field class="example-full-width w-100">
                                        <mat-label>Deductible Used (Individual)</mat-label>
                                        <input matInput step="0" placeholder="" maxlength="8" numeric decimals="2"
                                            [(ngModel)]="crbainfo.benefitDetails.individualDeductible"
                                            #individualDeductible="ngModel" name="individualDeductible"
                                            [ngClass]="{ 'is-invalid': crbafrm.submitted && individualDeductible.invalid }"
                                            required>
                                        <mat-error *ngIf="crbafrm.submitted && individualDeductible.invalid">
                                            <mat-error *ngIf="individualDeductible.errors.required">Individual
                                                Deductible Used is required
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                           
                                     
                                </div>
                                <div class="col-md-3">
                                    <div class="example-form">
                                        <mat-form-field class="w-100">
                                            <mat-label>Deductible (Family)</mat-label>
                                            <input matInput step="0" placeholder="" maxlength="8" numeric decimals="2"
                                            [(ngModel)]="crbainfo.benefitDetails.deductibleforfamily"
                                            #deductibleforfamily="ngModel" name="deductibleforfamily"
                                            [ngClass]="{ 'is-invalid': crbafrm.submitted && deductibleforfamily.invalid }"
                                            required>    
                                             <mat-error *ngIf="crbafrm.submitted && deductibleforfamily.invalid">
                                                <mat-error *ngIf="deductibleforfamily.errors.required">Family
                                                    Deductible is required
                                                </mat-error>
                                            </mat-error>
                                    </mat-form-field>
                                    </div>

                                </div>
                                <div class="col-md-3">
                                    <div class="example-form">
                                        <mat-form-field class="example-full-width w-100">
                                            <mat-label>Deductible Used (Family)</mat-label>
                                            <input matInput step="0" placeholder="" maxlength="8" numeric decimals="2"
                                                [(ngModel)]="crbainfo.benefitDetails.familyDeductible"
                                                #familyDeductible="ngModel" name="familyDeductible"
                                                [ngClass]="{ 'is-invalid': crbafrm.submitted && familyDeductible.invalid }"
                                                required>
                                            <mat-error *ngIf="crbafrm.submitted && familyDeductible.invalid">
                                                <mat-error *ngIf="familyDeductible.errors.required">Family Deductible
                                                    Used is
                                                    required
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="example-form">
                                        <mat-form-field class="example-full-width w-100">
                                            <mat-label>Max Out of Pocket (Individual) $</mat-label>
                                            <input matInput step="0" placeholder="" maxlength="8" numeric decimals="2"
                                                [(ngModel)]="crbainfo.benefitDetails.maxOutOfPocket"
                                                #maxOutOfPocket="ngModel" name="maxOutOfPocket"
                                                [ngClass]="{ 'is-invalid': crbafrm.submitted && maxOutOfPocket.invalid }"
                                                required>
                                            <mat-error *ngIf="crbafrm.submitted && maxOutOfPocket.invalid">
                                                <mat-error *ngIf="maxOutOfPocket.errors.required">MaxOut Of Pocket is
                                                    required
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="col-md-3">
                                    <div class="example-form">
                                        <mat-form-field class="example-full-width w-100">
                                            <mat-label>Max Out of Pocket (Family) $</mat-label>
                                            <input matInput step="0" placeholder="" maxlength="8" numeric decimals="2"
                                                [(ngModel)]="crbainfo.benefitDetails.maxOutOfPocketFamily"
                                                #maxOutOfPocketFamily="ngModel" name="maxOutOfPocketFamily"
                                                [ngClass]="{ 'is-invalid': crbafrm.submitted && maxOutOfPocketFamily.invalid }"
                                                required>
                                            <mat-error *ngIf="crbafrm.submitted && maxOutOfPocketFamily.invalid">
                                                <mat-error *ngIf="maxOutOfPocketFamily.errors.required">MaxOut Of Pocket
                                                    Family is
                                                    required
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <!-- <div class="col-md-3">
                                    <div class="example-form">
                                        <mat-form-field class="example-full-width w-100">
                                            <mat-label>Deductible used $</mat-label>
                                            <input matInput step="0" placeholder="" maxlength="8" numeric decimals="2"
                                                [(ngModel)]="crbainfo.benefitDetails.deductibleUsed"
                                                #deductibleUsed="ngModel" name="deductibleUsed"
                                                [ngClass]="{ 'is-invalid': crbafrm.submitted && deductibleUsed.invalid }"
                                                required>
                                            <mat-error *ngIf="crbafrm.submitted && deductibleUsed.invalid">
                                                <mat-error *ngIf="deductibleUsed.errors.required">Deductible Used is
                                                    required
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                </div> -->
                                <div class="col-md-3">
                                    <div class="example-form">
                                        <mat-form-field class="w-100">
                                            <mat-label>Effective Date</mat-label>
                                            <input matInput [matDatepicker]="effdobpicker" readonly
                                                (click)="effdobpicker.open()"
                                                [(ngModel)]="crbainfo.benefitDetails.effectiveDate"
                                                #effectiveDate="ngModel" name="effectiveDate"
                                                [ngClass]="{ 'is-invalid': crbafrm.submitted && effectiveDate.invalid }"
                                                required>
                                            <mat-datepicker-toggle matSuffix [for]="effdobpicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #effdobpicker></mat-datepicker>
                                            <mat-error *ngIf="crbafrm.submitted && effectiveDate.invalid">
                                                <mat-error *ngIf="effectiveDate.errors.required">Effective Date is
                                                    required</mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group row">
                                        <label for="exampleInputEmail1" class="col-md-12"> Maximum Number of Visits
                                            Per Year</label>
                                        <div class="col-md-12 ">
                                            <mat-radio-group aria-label="Select an option"
                                                [(ngModel)]="crbainfo.benefitDetails.isMaxVisitPerYear"
                                                #isMaxVisitPerYear="ngModel" name="isMaxVisitPerYear" required>
                                                <mat-radio-button class="mr-4" [value]="true">Yes</mat-radio-button>
                                                <mat-radio-button class="mr-4" [value]="false">No</mat-radio-button>
                                            </mat-radio-group>

                                            <mat-error *ngIf="crbafrm.submitted && isMaxVisitPerYear.invalid">
                                                <mat-error class="mat-form-field-subscript-wrapper"
                                                    *ngIf="isMaxVisitPerYear.errors.required">Is Maximum Number of
                                                    Visits is
                                                    required</mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3" *ngIf="crbainfo.benefitDetails.isMaxVisitPerYear == true">
                                    <div class="example-form">
                                        <mat-form-field class="example-full-width w-100">
                                            <mat-label>Maximum #</mat-label>
                                            <input matInput step="0" placeholder="" maxlength="8" numeric decimals="2"
                                                [(ngModel)]="crbainfo.benefitDetails.maxNumberofVisitsPa"
                                                #maxNumberofVisitsPa="ngModel" name="maxNumberofVisitsPa"
                                                [ngClass]="{ 'is-invalid': crbafrm.submitted && maxNumberofVisitsPa.invalid }"
                                                required>
                                            <mat-error *ngIf="crbafrm.submitted && maxNumberofVisitsPa.invalid">
                                                <mat-error *ngIf="maxNumberofVisitsPa.errors.required">Max Number of
                                                    Visits Per Year is required
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="exampleInputEmail1" class="crb-label">Is Authorization Required for?</label>
                                <div class=" col-md-12">
                                    <div class=" row">
                                        <div class="col-md-3 p-0 mb-3">
                                            <div class=" row">
                                                <label for="exampleInputEmail1" class="col-md-12">Individual
                                                    (90837)</label>
                                                <div class="col-md-12 ">
                                                    <mat-radio-group aria-label="Select an option"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.isIndividual"
                                                        #isIndividual="ngModel" name="isIndividual" required>
                                                        <mat-radio-button class="mr-4" [value]="true">Yes
                                                        </mat-radio-button>
                                                        <mat-radio-button class="mr-4" [value]="false">No
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <mat-error *ngIf="crbafrm.submitted && isIndividual.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper"
                                                            *ngIf="isIndividual.errors.required">Is Individual is
                                                            required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 p-0 mb-3">
                                            <div class=" row">
                                                <label for="exampleInputEmail1" class="col-md-12">Family (90847)</label>
                                                <div class="col-md-12 ">
                                                    <mat-radio-group aria-label="Select an option"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.isFamily"
                                                        #isFamily="ngModel" name="isFamily" required>
                                                        <mat-radio-button class="mr-4" [value]="true">Yes
                                                        </mat-radio-button>
                                                        <mat-radio-button class="mr-4" [value]="false">No
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <mat-error *ngIf="crbafrm.submitted && isFamily.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper"
                                                            *ngIf="isFamily.errors.required">Is Family is
                                                            required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 p-0 mb-3">
                                            <div class=" row">
                                                <label for="exampleInputEmail1" class="col-md-12">Group (90853)</label>
                                                <div class="col-md-12 ">
                                                    <mat-radio-group aria-label="Select an option"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.isGroup"
                                                        #isGroup="ngModel" name="isGroup" required>
                                                        <mat-radio-button class="mr-4" [value]="true">Yes
                                                        </mat-radio-button>
                                                        <mat-radio-button class="mr-4" [value]="false">No
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <mat-error *ngIf="crbafrm.submitted && isGroup.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper"
                                                            *ngIf="isGroup.errors.required">Is Group is
                                                            required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 p-0 mb-3">
                                            <div class=" row">
                                                <label for="exampleInputEmail1" class="col-md-12">PCP (H0035/0913)</label>
                                                <div class="col-md-12 ">
                                                    <mat-radio-group aria-label="Select an option"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.isPcp"
                                                        #isPcp="ngModel" name="isPcp" required>
                                                        <mat-radio-button class="mr-4" [value]="true">Yes
                                                        </mat-radio-button>
                                                        <mat-radio-button class="mr-4" [value]="false">No
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <mat-error *ngIf="crbafrm.submitted && isPcp.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper"
                                                            *ngIf="isPcp.errors.required">Is Group is
                                                            required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 p-0 mb-3">
                                            <div class=" row">
                                                <label for="exampleInputEmail1" class="col-md-12">IOP
                                                    (S9480/0905)</label>
                                                <div class="col-md-12">
                                                    <mat-radio-group aria-label="Select an option"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.isIop"
                                                        #isIop="ngModel" name="isIop" required>
                                                        <mat-radio-button class="mr-4" [value]="true">Yes
                                                        </mat-radio-button>
                                                        <mat-radio-button class="mr-4" [value]="false">No
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <mat-error *ngIf="crbafrm.submitted && isIop.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper"
                                                            *ngIf="isIop.errors.required">Is Iop is
                                                            required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 p-0 mb-3">
                                            <div class=" row">
                                                <label for="exampleInputEmail1" class="col-md-12">Nutrition (97803)</label>
                                                <div class="col-md-12">
                                                    <mat-radio-group  [(ngModel)]="crbainfo.authorizationsRequiredInfo.isNutrition"
                                                     #nutrition="ngModel" name="nutrition" required>
                                                        <mat-radio-button class="mr-4" [value]="true">Yes
                                                        </mat-radio-button>
                                                        <mat-radio-button class="mr-4" [value]="false">No
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <mat-error *ngIf="crbafrm.submitted && nutrition.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper"
                                                            *ngIf="nutrition.errors.required">Is Nutrition is
                                                            required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 p-0 mb-3">
                                            <div class=" row">
                                                <label for="exampleInputEmail1" class="col-md-12">Med. Management (90832)</label>
                                                <div class="col-md-12">
                                                    <mat-radio-group aria-label="Select an option"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.medManagement"
                                                        #medManagement="ngModel" name="medManagement" required>
                                                        <mat-radio-button class="mr-4" [value]="true">Yes
                                                        </mat-radio-button>
                                                        <mat-radio-button class="mr-4" [value]="false">No
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <mat-error *ngIf="crbafrm.submitted && medManagement.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper"
                                                            *ngIf="medManagement.errors.required">Med. Management (90832) is
                                                            required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 p-0 mb-3">
                                            <div class=" row">
                                                <label for="exampleInputEmail1" class="col-md-12">Diagnostic Eval. (90791)</label>
                                                <div class="col-md-12">
                                                    <mat-radio-group aria-label="Select an option"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.diagnosticEval"
                                                        #diagnosticEval="ngModel" name="diagnosticEval" required>
                                                        <mat-radio-button class="mr-4" [value]="true">Yes
                                                        </mat-radio-button>
                                                        <mat-radio-button class="mr-4" [value]="false">No
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <mat-error *ngIf="crbafrm.submitted && diagnosticEval.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper"
                                                            *ngIf="diagnosticEval.errors.required">Diagnostic Eval. (90791) is
                                                            required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                   
                                        <div class="col-md-3 p-0 mb-3">
                                            <div class=" row">
                                                <label for="exampleInputEmail1" class="col-md-12">Is Telehealth a covered benefit?</label>
                                                <div class="col-md-12">
                                                    <mat-radio-group aria-label="Select an option"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.isTelehealth"
                                                        #isTelehealth="ngModel" name="isTelehealth" required>
                                                        <mat-radio-button class="mr-4" [value]="true">Yes
                                                        </mat-radio-button>
                                                        <mat-radio-button class="mr-4" [value]="false">No
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <mat-error *ngIf="crbafrm.submitted && isTelehealth.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper"
                                                            *ngIf="isTelehealth.errors.required">Is Telehealth is
                                                            required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 p-0 mb-3" *ngIf="crbainfo.authorizationsRequiredInfo.isTelehealth == true">
                                            <div class=" row">
                                                <label for="exampleInputEmail1" class="col-md-12">Is Telehealth covered by out-of-network providers?</label>
                                                <div class="col-md-12">
                                                    <mat-radio-group aria-label="Select an option"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.isoutOfNetwork"
                                                        #isoutOfNetwork="ngModel" name="isoutOfNetwork" required>
                                                        <mat-radio-button class="mr-4" [value]="true">Yes
                                                        </mat-radio-button>
                                                        <mat-radio-button class="mr-4" [value]="false">No
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <mat-error *ngIf="crbafrm.submitted && isoutOfNetwork.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper"
                                                            *ngIf="isoutOfNetwork.errors.required">Is Telehealth out-of-network is
                                                            required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 p-0 mb-3">
                                            <div class=" row">
                                                <label for="exampleInputEmail1" class="col-md-12">Are both Individual
                                                    and Group
                                                    able to be billed on the same day?</label>
                                                <div class="col-md-12 ">
                                                    <mat-radio-group aria-label="Select an option"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.individualGroup"
                                                        #individualGroup="ngModel" name="individualGroup" required>
                                                        <mat-radio-button class="mr-4" [value]="true">Yes
                                                        </mat-radio-button>
                                                        <mat-radio-button class="mr-4" [value]="false">No
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                    <mat-error *ngIf="crbafrm.submitted && individualGroup.invalid">
                                                        <mat-error class="mat-form-field-subscript-wrapper"
                                                            *ngIf="individualGroup.errors.required">Is individual
                                                            Group is required</mat-error>
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 p-0">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="example-form">
                                                <mat-form-field class="example-full-width w-100">
                                                    <mat-label>Insurance Representative Name</mat-label>
                                                    <input matInput placeholder="" maxlength="30" appAlphabetOnly
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.insuranceRepresentative"
                                                        #insuranceRepresentative="ngModel"
                                                        name="insuranceRepresentative"
                                                        [ngClass]="{ 'is-invalid': crbafrm.submitted && insuranceRepresentative.invalid }"
                                                        required>
                                                    <mat-error
                                                        *ngIf="crbafrm.submitted && insuranceRepresentative.invalid">
                                                        <mat-error *ngIf="insuranceRepresentative.errors.required">
                                                            Insurance Representative is
                                                            required
                                                        </mat-error>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                        </div>
                                        <div class="col-md-3">
                                            <div class="example-form">
                                                <mat-form-field class="example-full-width w-100">
                                                    <mat-label>Reference #</mat-label>
                                                    <!-- <input matInput placeholder="" maxlength="30" specialIsAlphaNumeric
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.reference"
                                                        #reference="ngModel"
                                                        name="reference"
                                                        [ngClass]="{ 'is-invalid': crbafrm.submitted && reference.invalid }"
                                                        required> -->
                                                        <input matInput placeholder="" maxlength="30" pattern="^[a-zA-Z0-9!@#$%^&*()_-+=|\/{}.,~;:''']*$"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.reference"
                                                        #reference="ngModel"
                                                        name="reference"
                                                        [ngClass]="{ 'is-invalid': crbafrm.submitted && reference.invalid }"
                                                        required>
                                                    <mat-error
                                                        *ngIf="crbafrm.submitted && reference.invalid">
                                                        <mat-error *ngIf="reference.errors.required">
                                                            Reference is
                                                            required
                                                        </mat-error>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                        </div>
                                        <div class="col-md-3">
                                            <div class="example-form">
                                                <mat-form-field class="w-100">
                                                    <mat-label> Date</mat-label>
                                                    <input matInput [matDatepicker]="repdobpicker" readonly
                                                        (click)="repdobpicker.open()"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.reportDate"
                                                        #reportDate="ngModel" name="reportDate"
                                                        [ngClass]="{ 'is-invalid': crbafrm.submitted && reportDate.invalid }"
                                                        required>
                                                    <mat-datepicker-toggle matSuffix [for]="repdobpicker">
                                                    </mat-datepicker-toggle>
                                                    <mat-datepicker #repdobpicker></mat-datepicker>
                                                    <mat-error *ngIf="crbafrm.submitted && reportDate.invalid">
                                                        <mat-error *ngIf="reportDate.errors.required">Report Date is
                                                            required</mat-error>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                        </div>
                                        <div class="col-md-3">
                                            <div class="example-form">
                                                <mat-form-field class="example-full-width w-100">
                                                    <mat-label>Phone</mat-label>
                                                    <input matInput placeholder="" mask='(000) 000-0000' maxlength="14"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.phone"
                                                        #phone="ngModel" name="phone"
                                                        [ngClass]="{ 'is-invalid': crbafrm.submitted && phone.invalid }"
                                                        required>
                                                    <mat-error *ngIf="crbafrm.submitted && phone.invalid">
                                                        <mat-error *ngIf="phone.errors.required">Phone is required
                                                        </mat-error>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                        </div>
                                        <div class="col-md-3">
                                            <div class="example-form">
                                                <mat-form-field class="example-full-width w-100">
                                                    <mat-label>Ext</mat-label>
                                                    <input matInput placeholder="" step="0" numeric maxlength="5"
                                                        [(ngModel)]="crbainfo.authorizationsRequiredInfo.ext"
                                                        #ext="ngModel" name="ext"
                                                        [ngClass]="{ 'is-invalid': crbafrm.submitted && ext.invalid }">
                                                    <mat-error *ngIf="crbafrm.submitted && ext.invalid">
                                                        <mat-error *ngIf="ext.errors.required">Ext is required
                                                        </mat-error>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>

                                        </div>
                                        
                                       <!-- <div class="col-md-9 basic-info mb-3">
                                       <label class="treat-label">Comments</label>
            
                                        <app-word-counter-textarea [text]="crbainfo.authorizationsRequiredInfo.comments" [maxlength]="10000" [(ngModel)]="crbainfo.authorizationsRequiredInfo.comments" 
                                       (textChange)="crbainfo.authorizationsRequiredInfo.comments = $event" #comments="ngModel" name="comments" ngDefaultControl ></app-word-counter-textarea>
   
                                       </div> -->
                                   
                                    </div>
                                </div>
                                <div class="col-md-12 p-0">
                                    <div class="basic-info mb-3">
                                        <label class="treat-label">Comments</label>
             
                                         <app-word-counter-textarea [text]="crbainfo.authorizationsRequiredInfo.comments" [maxlength]="10000" [(ngModel)]="crbainfo.authorizationsRequiredInfo.comments" 
                                        (textChange)="crbainfo.authorizationsRequiredInfo.comments = $event" #comments="ngModel" name="comments" ngDefaultControl ></app-word-counter-textarea>
    
                                        </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    </div>


                </div>


            </div>
        </div>
        
        <div class="col-md-12 ">
            <div class="">
                <div class="col-md-12 p-0">
                <div class="row">
                    <div class="col-md-12 p-0">
                        <!-- <div class="box-lead-head">AUTHORIZATIONS</div> -->

   <!--Authorization -->
   <div class="author">
    <app-insurance-authorization ></app-insurance-authorization>
  </div>
  <!--Authorization end-->
  <!-- <div class="lead-box h-auto">
                    <div class="col-md-12 p-0">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="box-lead-head mb-3 col-md-12 p-0">Clinical Information Provided</div>
                                    <div class="row">
                                        <div class="col-md-10" *ngFor="let info of clinicalInfo;let idx = index">
                                            <div class="row">
                           
                                                <div class="col-md-11">

                                                    <mat-form-field class="example-full-width w-100">
                                                        <textarea matInput [(ngModel)]="info.information" maxlength="150"
                                                        [ngModelOptions]="{ standalone : true }"
                                                            #information="ngModel"> </textarea>
                                                       
                                                        <mat-error *ngIf="crbafrm.submitted && information.invalid">
                                                            <mat-error *ngIf="information.errors.required">Information
                                                                is
                                                                required
                                                            </mat-error>
                                                        </mat-error>
                                                    </mat-form-field>
                                                    <span class="text-xs sub-descib">(maximum allowed character is 150 only)</span>
                                                </div>
                                                <div class="col-md-1 p-0 mt-3 text-right">
                                                    <a (click)="removeclinicalinformation(info,idx)"
                                                        class="cursor-pointer icon-close" title="Remove" *ngIf="i !=0">
                                                        <span class="material-icons">
                                                            close
                                                        </span>
                                                    </a>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <p class="actions mt-3">
                                                <a id="addinfo" (click)="addclinicalinformation()" class="cursor-pointer icon-color"
                                                    title="Add Medications">
                                                    <span class="material-icons">
                                                        add
                                                    </span>
                                                </a>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">

                                </div>
                            </div>
                        </div>


                    
                    <div class="col-md-12 p-0">
                        <div class="text-right">
                            <button type="submit" form="crbafrm" mat-stroked-button
                                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save</button>
                            <button type="button" [routerLink]="['/leadlist']" mat-stroked-button
                                class="btn btn-cancel primary-btn-m h-40">Cancel</button>
                        </div>
                    </div>
                </div> -->
                </div>
                </div>
                </div>
            </div>
        </div>

    </form>
</div>
<a mimetype="application/octet-stream" #downloadLink></a>

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AlphabetOnlyDirective } from '../app/common/_directive/alphabet-only.directive';
import { TextareaAutoresizeDirective } from '../app/common/_directive/TextareaAutoresizeDirective.directive';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DateValidator } from '../app/common/_directive/date.directive';
import { SpecialCharacterDirective } from 'src/app/common/_directive/specialChracter.directive';
import { NumericDirective } from 'src/app/common/_directive/numeric.directive';
import { CustomMaxDirective } from 'src/app/common/_directive/custom-max-validator.directive';
import { CustomMinDirective } from 'src/app/common/_directive/custom-min-validator.directive';
import { SiteLayoutComponent } from './_Layout/site-layout/site-layout.component';
import { SiteHeaderComponent } from './_Layout/site-header/site-header.component';
import { SiteFooterComponent } from './_Layout/site-footer/site-footer.component';

import { Global } from './global/global.model';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { ConfirmDialogComponent } from './common/confirm-dialog/confirm-dialog.component';
import { EsignDialogComponent } from './common/esign-dialog/esign-dialog.component';
import { TaskDialogComponent } from './common/task-dialog/task-dialog.component';
import { NotesDialogComponent } from './common/notes-dialog/notes-dialog.component';
import { ConsentEsignDialogComponent } from './common/consentesign-dialog/consentesign-dialog.component';
import { LibraryDialogComponent } from './common/library-dialog/library-dialog.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './usermanagement/register/register.component';
import { ChangepasswordComponent } from './usermanagement/changepassword/changepassword.component';
import { ForgetpasswordComponent } from './usermanagement/forgetpassword/forgetpassword.component';
import { UserlistComponent } from './usermanagement/userlist/userlist.component';
// import { SitelayoutComponent } from './_sitelayout/sitelayout.component';
import { RolesComponent } from './rolemanagement/roles/roles.component';
import { RolePermissionComponent } from './rolemanagement/role-permission/role-permission.component';
import { SnackBarService } from '../app/services/snackBar.service';
import { SpinnerOverlayComponent } from '../app/common/spinner-overlay/spinner-overlay.component';
// import { MAT_DATE_LOCALE, NativeDateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
// import { MatAutocompleteModule } from '@angular/material/autocomplete';



import { QuillModule } from 'ngx-quill'
import _Quill from 'quill'
import { NgSelectModule } from '@ng-select/ng-select';
import {ReadMoreComponent} from 'src/app/common/_directive/readmore.directive';
//#region Common Pages
import { NotificationComponent } from './notification/notification.component';
import { ViewNotificationComponent } from './notification/view-notification/view-notification.component';
import { MastersComponent } from '../app/common/pages/masters/masters.component';
import { SendEmailComponent } from '../app/common/pages/send-email/send-email.component';
import { MessagesComponent } from 'src/app/common/pages/messages/messages.component';
import { PhoneBookComponent } from 'src/app/common/pages/masters/phone-book/phone-book.component';
import { TaskInfoComponent } from 'src/app/common/pages/task-info/task-info.component';
import { GoalsComponent } from 'src/app/common/pages/template/goals/goals.component';
//#endregion
//#region Lead
import { LeadAccountComponent } from '../app/patient-lead/lead-account/lead-account.component';
import { LeadListviewComponent } from '../app/patient-lead/lead-listview/lead-listview.component';
import { LeadDetailviewComponent } from '../app/patient-lead/lead-detailview/lead-detailview.component';
import { LeadComponent } from '../app/patient-lead/lead/lead.component';
import { LeadKanbanComponent } from '../app/patient-lead/lead-kanban/lead-kanban.component';
import { LeadInfoComponent } from '../app/patient-lead/lead-info/lead-info.component';
import { LeadHistoryComponent } from 'src/app/patient-lead/lead-history/lead-history.component';
import { SpinnerService } from './services/spinnerservice.service';
import { ConsentComponent } from 'src/app/common/pages/template/consent/consent.component';
import { CrbaComponent } from 'src/app/patient-lead/crba/crba.component';
import { ConsentTemplatesComponent } from 'src/app/common/pages/template/consent-templates/consent-templates.component';
import { RequestTourComponent } from 'src/app/patient-lead/request-tour/request-tour.component';
import { FinancialProposalComponent } from 'src/app/patient-lead/financial-proposal/financial-proposal.component';
import { PriceCatalogueComponent } from 'src/app/common/pages/price-catalogue/price-catalogue.component';
import { NotesComponent } from 'src/app/common/pages/notes/notes.component';
import { TaskComponent } from 'src/app/common/pages/task/task.component';
import { NotesAddComponent } from 'src/app/common/pages/notes/notes-add/notes-add.component';
import { CalenderComponent } from 'src/app/common/pages/calender/calender.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FinancialReviewComponent } from 'src/app/patient-lead/financial-proposal/financial-review/financial-review.component';
import { SettingsComponent } from 'src/app/common/pages/settings/settings.component';
import { ConfigureFormsComponent } from 'src/app/common/pages/template/configure-forms/configure-forms.component';
//#endregion
//#region  Patient
import { PatientListviewComponent } from 'src/app/patient/patient-listview/patient-listview.component';
import { IntakeScheduleComponent } from 'src/app/patient/intake-schedule/intake-schedule.component';
import { MeetingAppointmentComponent } from 'src/app/common/pages/settings/meeting-appointment/meeting-appointment.component';
import { MeetingLocationComponent } from 'src/app/common/pages/settings/meeting-location/meeting-location.component';
import { PatientBreifcaseComponent } from 'src/app/patient/patient-breifcase/patient-breifcase.component';
import { SfccLocationComponent } from 'src/app/common/pages/settings/sfcc-location/sfcc-location.component';
import { IntakeConsentComponent } from 'src/app/patient/intake/intake-consent/intake-consent.component';
import { IntakeChecklistComponent } from 'src/app/patient/intake/intake-checklist/intake-checklist.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { IntakeSurveyComponent } from 'src/app/patient/intake/intake-survey/intake-survey.component';
import { ImageLibraryComponent } from 'src/app/common/pages/settings/image-library/image-library.component';
import { IntakeNutritionalComponent } from 'src/app/patient/intake/intake-nutritional/intake-nutritional.component';

import { ClinicianLayoutComponent } from 'src/app/staff/clinician/clinician-layout/clinician-layout.component';
import { ClinicianMytaskComponent } from 'src/app/staff/clinician/clinician-mytask/clinician-mytask.component';
import { IntakeGenerateComponent } from 'src/app/patient/intake/intake-generate/intake-generate.component'
import { IntakeCmhaComponent } from 'src/app/patient/intake/intake-cmha/intake-cmha.component';
import { CmhaPatientdetailsComponent } from 'src/app/patient/intake/intake-cmha/cmha-patientdetails/cmha-patientdetails.component';
import { CmhaClinicalinfoComponent } from 'src/app/patient/intake/intake-cmha/cmha-clinicalinfo/cmha-clinicalinfo.component';
import { CmhaEvaluationComponent } from 'src/app/patient/intake/intake-cmha/cmha-evaluation/cmha-evaluation.component';
import { PainAssessmentComponent } from 'src/app/patient/pain-assessment/pain-assessment.component'

import { CmhaAssessmentComponent } from 'src/app/patient/intake/intake-cmha/cmha-assessment/cmha-assessment.component';
import { CmhaStrengthweeknessComponent } from 'src/app/patient/intake/intake-cmha/cmha-strengthweekness/cmha-strengthweekness.component';
import { CmhaClinicalComponent } from 'src/app/patient/intake/intake-cmha/cmha-clinical/cmha-clinical.component';
import { TreatmentplanListComponent } from 'src/app/staff/treatment/treatmentplan-list/treatmentplan-list.component';

//#endregion

//#region  User Mange
import { UserManageComponent } from 'src/app/usermanagement/user-manage/user-manage.component';
import { UserOnboardComponent } from 'src/app/usermanagement/user-onboard/user-onboard.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CmhaTreatmentplanComponent } from './patient/intake/intake-cmha/cmha-treatmentplan/cmha-treatmentplan.component';

//#endregion

//#region TreatMent
import { TreatmentplanComponent } from 'src/app/staff/treatment/treatmentplan/treatmentplan.component';
import { TreatmentdiagnosisComponent } from 'src/app/staff/treatment/treatmentplan/treatmentdiagnosis/treatmentdiagnosis.component';
import { TreatmentgoalsComponent } from 'src/app/staff/treatment/treatmentplan/treatmentgoals/treatmentgoals.component';
import { TreatmentconsentComponent } from 'src/app/staff/treatment/treatmentplan/treatmentconsent/treatmentconsent.component';
import {TreatmentmodalityComponent} from 'src/app/staff/treatment/treatmentplan/treatmentmodality/treatmentmodality.component';
import {ScheduleModalityComponent} from 'src/app/staff/treatment/schedule-modality/schedule-modality.component';
import { GoalListComponent } from './common/pages/template/goals/goal-list/goal-list.component';
import { DayViewSchedulerComponent } from './common/pages/intake-scheduler/day-view-scheduler';
import { MeetingBlocksComponent } from './common/pages/settings/meeting-blocks/meeting-blocks.component';
import { GroupTherapyComponent } from './common/pages/template/group-therapy/group-therapy.component';
import { ScheduleGrptherapyComponent } from './staff/treatment/schedule-grptherapy/schedule-grptherapy.component';
import { SchedulerCalenderComponent } from './common/pages/scheduler-calender/scheduler-calender.component';
import { DayScheduleComponent } from './staff/treatment/day-schedule/day-schedule.component';
import { FormRenameDialogComponent } from './common/form-rename-dialog/form-rename-dialog.component';
import { ProgressTrackComponent } from './staff/treatment/progress-track/progress-track.component';
import { DatePipe } from '@angular/common';
import { TreatmenthistoryComponent } from './staff/treatment/treatmenthistory/treatmenthistory.component';
import {  FamilyIndividualTheraphyComponent } from './staff/treatment/family-individual-theraphy/family-individual-theraphy.component';
import { TreatmentGoalConfigComponent } from './staff/treatment/treatmentplan/treatment-goal-config/treatment-goal-config.component';
import { BillListsComponent } from './billing/bill-lists/bill-lists.component';
import { BillComponent } from './billing/bill/bill.component';
import { InsuranceAuthorizationComponent } from './patient-lead/lead-info/insurance-authorization/insurance-authorization.component'; 

import { gridFilterPipe } from 'src/app/common/_directive/gridFilterPipe.directive';
import { IncidentReportingComponent } from './staff/incident-reporting/incident-reporting/incident-reporting.component';
import { IncidentReportingListComponent } from './staff/incident-reporting/incident-reporting-list/incident-reporting-list.component';
import { IncidentReviewComponent } from './staff/incident-reporting/incident-review/incident-review.component';
import { BillingMastersComponent } from './billing/billing-masters/billing-masters.component';
import { TransactionCodeComponent } from './billing/billing-masters/transaction-code/transaction-code.component';
import { PaymentDetailsComponent } from './patient-lead/lead-info/payment-details/payment-details.component';
import { BillingProviderComponent } from './billing/billing-masters/billing-provider/billing-provider.component';
import { ClaimsListsComponent } from './billing/claims-lists/claims-lists.component';
import { PsychiatricEvaluationProgressNotesComponent } from './staff/treatment/psychiatric-evaluation-progress-notes/psychiatric-evaluation-progress-notes.component';
import { PsychiatricMedicationProgressNotesComponent } from './staff/treatment/psychiatric-medication-progress-notes/psychiatric-medication-progress-notes.component';
import { PsychiatricTherapyProgressNotesComponent } from './staff/treatment/psychiatric-therapy-progress-notes/psychiatric-therapy-progress-notes.component';
import { DischargeSummaryComponent } from './staff/discharge-summary/discharge-summary/discharge-summary.component';
import { DischargeSummaryListComponent } from './staff/discharge-summary/discharge-summary-list/discharge-summary-list.component';
import { TreatmentSummaryComponent } from './staff/discharge-summary/treatment-summary/treatment-summary.component';
import { DischargedPatientListViewComponent } from './patient/discharge_patient_listview/discharged-patient-list-view/discharged-patient-list-view.component';

import { AdminDayViewScheduleComponent } from './common/pages/admin-day-view-schedule/admin-day-view-schedule.component';
import { AssignClinicianComponent } from './common/pages/settings/assign-clinician/assign-clinician.component';
import { TherapyDetailsPopUpComponent } from './common/therapy-details-pop-up/therapy-details-pop-up.component';
import { PhonecallSchoolmeetScheduleComponent } from './patient/phonecall-schoolmeet-schedule/phonecall-schoolmeet-schedule.component';
import { PhonecallSchoolProgressNotesComponent } from './staff/treatment/phonecall-school-progress-notes/phonecall-school-progress-notes.component';
import { PatientPaymentDetailsComponent } from 'src/app/patient/patient-payment-details/patient-payment-details.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SucidalRiskAssessmentComponent } from './staff/treatment/sucidal-risk-assessment/sucidal-risk-assessment.component';
import { PatientslistsComponent } from './patient/patientslists/patientslists.component';
import { SuicidalScreenerDocumentComponent } from './staff/treatment/sucidal-risk-assessment/suicidal-screener-document/suicidal-screener-document.component';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { percentageDirective } from './common/_directive/_percentage-directive';
import { TreatmentPresentingProblemsComponent } from './staff/treatment/treatmentplan/treatment-presenting-problems/treatment-presenting-problems.component';
import { AddEditBenchPopUpComponent } from './common/add-edit-bench-pop-up/add-edit-bench-pop-up.component';
import { PatientInvoiceListsComponent } from './patient/patient-invoice-lists/patient-invoice-lists.component';
import { ManagePermissionsComponent } from './rolemanagement/manage-permissions/manage-permissions.component';
import { ProfileComponent } from './usermanagement/profile/profile.component';
import { ChangepasswordprofileComponent } from './usermanagement/changepasswordprofile/changepasswordprofile.component';
import { PatientDocumentsComponent } from './common/pages/patient-documents/patient-documents.component';
import { PatientdocumentsDetailsComponent } from './common/pages/patientdocuments-details/patientdocuments-details.component';
import { LandingPageComponent } from './common/pages/landing-page/landing-page.component';
import { ScheduleModalityPopUpComponent } from './common/schedule-modality-pop-up/schedule-modality-pop-up.component';
import { BlockTimeComponent } from './common/pages/settings/block-time/block-time.component';
import { AuthorizationSettingsMasterComponent } from './common/pages/settings/authorization-settings-master/authorization-settings-master.component';
import { AuthThresholdComponent } from './patient-lead/auth-threshold/auth-threshold.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DailyschedulerComponent } from './dailyscheduler/dailyscheduler.component';
import { ProgressnotesComponent } from './progressnotes/progressnotes.component';
import { AccordiandesignDailyschedulerComponent } from './accordiandesign-dailyscheduler/accordiandesign-dailyscheduler.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { SkillTaughtComponent } from './common/pages/template/skill-taught/skill-taught.component';
//import { CumulativeInsuranceComponent } from './patient-lead/cumulative-insurance/cumulative-insurance.component';
import { DiagnosisCodeComponent } from './billing/billing-masters/diagnosis-code/diagnosis-code.component';
import { FilterPipe } from './common/_directive/filter.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CumulativeInsuranceComponent } from './patient-lead/cumulative-insurance/cumulative-insurance.component';
import { InsuranceInfoComponent } from './billing/insurance-info/insurance-info.component';
import { WordCounterComponent } from './services/word-counter/word-counter.component';
import { WordCounterTextareaComponent } from './services/word-counter-textarea/word-counter-textarea.component';
import { AuditSettingsMasterComponent } from './common/pages/settings/audit-settings-master/audit-settings-master.component';
import { AuditListComponent } from './Audit/audit-list/audit-list.component';
import { AuditClinicianComponent } from './Audit/audit-clinician/audit-clinician.component';
//import { AuditClinicianComponent } from './Audit/audit-clinician/audit-clinician.component';
import { ClientDocumentsComponent } from './common/pages/client-documents/client-documents.component';
import { AdmindeclainlistLeadComponent } from './patient-lead/admindeclainlist-lead/admindeclainlist-lead.component';
import { PatientDeclainlistComponent } from './patient/patient-declainlist/patient-declainlist.component';
import { TreatmentNotesComponent } from './staff/treatment/treatment-notes/treatment-notes.component';


import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {AutoCompleteModule} from 'primeng/autocomplete';


import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
//import { MedicationPdfforchildrenComponent } from './staff/clinician/clinician-mytask/medication-pdfforchildren/medication-pdfforchildren.component';
//import { MedicationReviewsheetComponent } from './staff/clinician/clinician-mytask/medication-pdfforchildren/medication-reviewsheet/medication-reviewsheet.component';
import { TreatmentMeetingNotesComponent } from './staff/treatment/treatment-meeting-notes/treatment-meeting-notes.component';
import { IntakeUpdateRejoinersComponent } from './staff/intake-update-rejoiners/intake-update-rejoiners.component';

import {PendingDocumentsComponent} from './staff/pending-documents/pending-documents.component';
import {FinanciallistComponent} from './patient/financiallist/financiallist.component';

//import { WordCounterReactiveformComponent } from './services/word-counter-reactiveform/word-counter-reactiveform.component';
import { MedicationPdfforchildrenComponent } from './staff/clinician/clinician-mytask/medication-pdfforchildren/medication-pdfforchildren.component';
import { MedicationReviewsheetComponent } from './staff/clinician/clinician-mytask/medication-pdfforchildren/medication-reviewsheet/medication-reviewsheet.component';

//#endregion
const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [
    AppComponent,
    AlphabetOnlyDirective,
    TextareaAutoresizeDirective,
    SpecialCharacterDirective,
    NumericDirective,
    CustomMaxDirective,
    ReadMoreComponent,
    CustomMinDirective,
    DateValidator,
    NotificationComponent,
    SendEmailComponent,
    ViewNotificationComponent,
    MessagesComponent,
    TaskComponent,
    NotesAddComponent,
    CalenderComponent,
    SiteLayoutComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    percentageDirective,
    LoginComponent,
    ConfirmDialogComponent,
    EsignDialogComponent,
    ConsentEsignDialogComponent,
    LibraryDialogComponent,
    TaskDialogComponent,
    NotesDialogComponent,
    SpinnerOverlayComponent,
    HomeComponent,
    // SitelayoutComponent,
    RegisterComponent,
    ChangepasswordComponent,
    ForgetpasswordComponent,
    UserlistComponent,
    RolesComponent,
    RolePermissionComponent,
    LeadAccountComponent,
    LeadListviewComponent,
    LeadDetailviewComponent,
    LeadComponent,
    LeadKanbanComponent,
    LeadInfoComponent,
    LeadHistoryComponent,
    MastersComponent,
    PhoneBookComponent,
    ConsentComponent,
    CrbaComponent,
    ConsentTemplatesComponent,
    RequestTourComponent,
    FinancialProposalComponent,
    PriceCatalogueComponent,
    NotesComponent,
    FinancialReviewComponent,
    PatientListviewComponent,
    SettingsComponent,
    IntakeScheduleComponent,
    MeetingAppointmentComponent,
    MeetingLocationComponent,
    PatientBreifcaseComponent,
    SfccLocationComponent,
    ConfigureFormsComponent,
    IntakeConsentComponent,
    IntakeChecklistComponent,
    IntakeSurveyComponent,
    ImageLibraryComponent,
    IntakeGenerateComponent,
    PainAssessmentComponent,
    ClinicianLayoutComponent,
    ClinicianMytaskComponent,
    UserManageComponent,
    UserOnboardComponent,
    IntakeNutritionalComponent,
    IntakeCmhaComponent,
    CmhaPatientdetailsComponent,
    CmhaClinicalinfoComponent,
    CmhaEvaluationComponent,
    CmhaStrengthweeknessComponent,
    CmhaAssessmentComponent,
    CmhaClinicalComponent,
    TaskInfoComponent,
    CmhaTreatmentplanComponent,
    TreatmentplanListComponent,
    GoalsComponent,
    TreatmentplanComponent,
    TreatmentdiagnosisComponent,
    TreatmentgoalsComponent,
    TreatmentconsentComponent,
    TreatmentmodalityComponent,
    ScheduleModalityComponent,
    GoalListComponent,
    DayViewSchedulerComponent,
    MeetingBlocksComponent,
    GroupTherapyComponent,
    ScheduleGrptherapyComponent,
    SchedulerCalenderComponent,
    DayScheduleComponent,
    FormRenameDialogComponent,
    ProgressTrackComponent,
    TreatmenthistoryComponent,
    FamilyIndividualTheraphyComponent,
    TreatmentGoalConfigComponent,
    BillListsComponent,
    BillComponent,
    gridFilterPipe,
    InsuranceAuthorizationComponent,
    IncidentReportingComponent,
    IncidentReportingListComponent,
    IncidentReviewComponent,
    BillingMastersComponent,
    TransactionCodeComponent,
    PaymentDetailsComponent,
    BillingProviderComponent,
    ClaimsListsComponent,
    PsychiatricEvaluationProgressNotesComponent,
    PsychiatricMedicationProgressNotesComponent,
    PsychiatricTherapyProgressNotesComponent,
    DischargeSummaryComponent,
    DischargeSummaryListComponent,
    TreatmentSummaryComponent,
    DischargedPatientListViewComponent,
    AdminDayViewScheduleComponent,
    AssignClinicianComponent,
    TherapyDetailsPopUpComponent,
    PhonecallSchoolmeetScheduleComponent,
        PhonecallSchoolProgressNotesComponent,
        PatientPaymentDetailsComponent,
        SucidalRiskAssessmentComponent,
        PatientslistsComponent,
     SuicidalScreenerDocumentComponent,
     TreatmentPresentingProblemsComponent,
     AddEditBenchPopUpComponent,
     PatientInvoiceListsComponent,
     ManagePermissionsComponent,
     ProfileComponent,
     ChangepasswordprofileComponent,
     PatientDocumentsComponent,
     PatientdocumentsDetailsComponent,
     LandingPageComponent,
     ScheduleModalityPopUpComponent,
     BlockTimeComponent,
     AuthorizationSettingsMasterComponent,
     AuthThresholdComponent,
     DashboardComponent,
     DailyschedulerComponent,
     ProgressnotesComponent,
     AccordiandesignDailyschedulerComponent,
     AttendanceComponent,
     SkillTaughtComponent,
     CumulativeInsuranceComponent,
     DiagnosisCodeComponent,
     FilterPipe,
     InsuranceInfoComponent,
     WordCounterComponent,
     WordCounterTextareaComponent,
     AuditSettingsMasterComponent,
     AuditListComponent,
     AuditClinicianComponent,
    // AuditClinicianComponent,
     ClientDocumentsComponent,
     AdmindeclainlistLeadComponent,
     PatientDeclainlistComponent,
     TreatmentNotesComponent,
     MedicationPdfforchildrenComponent,
    MedicationReviewsheetComponent,
    TreatmentMeetingNotesComponent,
    IntakeUpdateRejoinersComponent,
    PendingDocumentsComponent,
    FinanciallistComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    QuillModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgSelectModule,
    BrowserAnimationsModule,
    SignaturePadModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    MatMomentDateModule,
    MatAutocompleteModule,
    MatTabsModule,
    ScrollingModule,
    DragDropModule ,
    MatDialogModule,
    AutoCompleteModule,
    NgxMatColorPickerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    ConfirmDialogComponent,
    EsignDialogComponent,
    ConsentEsignDialogComponent,
    LibraryDialogComponent,
    TaskDialogComponent,
    NotesDialogComponent,
    FinanciallistComponent
  ],

  providers: [LoginService, Global, AuthGuard, SnackBarService, SpinnerService,FilterPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    //{ provide: MAT_DATE_LOCALE, useValue: 'en-US' },
 { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },

    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
    

  ],
  bootstrap: [AppComponent],

})


export class AppModule { }


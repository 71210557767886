export class ScheduleAppoinment {
    meetingScheduleId: number;
    appointmentTypesId: number;
    meetingCategoryId: number;
    meetingLocationId: number;
    patientAccountId: number;
    scheduleName: string;
    locationName:string;
    status: number;
    isActive: boolean
    typeId?: number;
    participants: Array<DtoMeetingParticipants>;
    // primaryHosts: Array<DtoMeetingHost>;
    // supportHosts: Array<DtoMeetingHost>;
    meetingTimes: Array<DtoMeetingTime>;
    scheduleOptionFirstDate: Date;
    scheduleOptionSecondDate: Date;
    optionOneMeetingTimes :Array<DtoMeetingTime>;
    optionTwoMeetingTimes :Array<DtoMeetingTime>;
    age:any;
    gender:string;
    staffId:number;
}
export class DtoMeetingTime {
    meetingScheduleTimeId: number;
    meetingScheduleId: number;
    status: number;
    scheduleDate: any;
    timeSlotId: number;
    fromTime:any;
    toTime:any;
    meetingTypeId:any;
    meetinghost: Array<DtoMeetingHost>;
}
export class DtoMeetingParticipants {
    meetingParticipantsId: number;
    meetingScheduleId: number;
    patientAccountId: number;
    status: number;
    isActive: boolean
}

export class DtoMeetingHost {
    meetingHostId: number;
    meetingScheduleId: number;
    staffId: number;
    status: number;
    isActive: boolean;
    hostType:number;
}


export const colors: any = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3'
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF'
    },
    yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA'
    },
    green: {
      primary: '#97ff8c',
      secondary: '#26bd5c'
    },
    orange:{
      primary: '#7d5313',
      secondary: '#fea520'
      
    },
    white:{
      primary:'fff',
      secondary:'fff'
    }
  };
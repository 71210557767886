<div class="strength-weekness">
    <!-- <span *ngIf="global.assessmentUpdatedOn !=null"
        class="status-msg float-left text-right w-100 mr-3 mt-2 cursor-pointer text-underline">Last Updated on:
        <span>{{global.assessmentUpdatedOn | date:
            'MM/dd/yyyy'}}</span></span>
    <div class="col-md-12">
        <div class="text-right">
            <button type="button" mat-stroked-button (click)="onSaveCmhaAssessment()"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save & Next</button>
            <button type="button" [routerLink]="['/clinician']" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40">Cancel</button>
        </div>
    </div> -->
    <div class="col-md-12 d-flex">
        <span *ngIf="global.assessmentUpdatedOn !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
            Updated on:
            <span>{{global.globalUtctimeformat(global.assessmentUpdatedOn) | date:
                'MM/dd/yyyy hh:mm a'}}</span></span>
                <span *ngIf="global.assessmentUpdatedByName !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
                    Updated By:
                    <span>{{global.assessmentUpdatedByName }}</span></span>
        <div class="text-right ml-auto">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <!-- <button type="button" mat-stroked-button (click)="onSaveCmhaAssessment(0)"
                    class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{isMovedChma ? 'Next' : 'Save
                    & Next'}}</button> -->
            <button type="button" mat-stroked-button (click)="onSaveCmhaAssessment(0)"
                *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{ 'Save
                & Next'}}</button>
            <!-- *ngIf="!isMovedChma" -->
            <button type="button" (click)="onSaveCmhaAssessment(1)" mat-stroked-button
                *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save & Exit</button>
            <button type="button" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button
                *ngIf="!global.isAssessment" (click)="onSaveCmhaAssessment(2)">Save as Draft</button>
        </div>
    </div>
    <!-- [ngClass]="{'disabled':isMovedChma}" -->
    <div class="row" >
        <div class="col-md-6">
            <div class="box-lead-head mb-3 col-md-12 p-0">Treatment Recommendation Goals:</div>
            <div class="row">
                <div class="col-md-10" *ngFor="let info of treatmentgoals;let idx = index">
                    <div class="row">
                        <div class="col-md-11">

                            <mat-form-field class="example-full-width w-100">
                                <input matInput [(ngModel)]="info.information" maxlength="3000" #information="ngModel"
                                    required>

                            </mat-form-field>
                        </div>
                        <div class="col-md-1 p-0 mt-3 text-right">
                            <a (click)="removeinformation(info,idx,1)" class="cursor-pointer icon-close" title="Remove"
                                *ngIf="idx !=0">
                                <span class="material-icons">
                                    close
                                </span>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-1">
                    <p class="actions mt-3">
                        <a (click)="onAddtreatmentgoals(3)" class="cursor-pointer icon-color" title="Add Goals">
                            <span class="material-icons">
                                add
                            </span>
                        </a>

                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="box-lead-head mb-3 col-md-12 p-0">Immediate Needs of Client:</div>
            <div class="row">
                <div class="col-md-10" *ngFor="let info of needsofclient;let idx = index">
                    <div class="row">
                        <div class="col-md-11">

                            <mat-form-field class="example-full-width w-100">
                                <input matInput [(ngModel)]="info.information" maxlength="3000" #information="ngModel"
                                    required>

                            </mat-form-field>
                        </div>
                        <div class="col-md-1 p-0 mt-3 text-right">
                            <a (click)="removeinformation(info,idx,2)" class="cursor-pointer icon-close" title="Remove"
                                *ngIf="idx !=0">
                                <span class="material-icons">
                                    close
                                </span>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-1">
                    <p class="actions mt-3">
                        <a (click)="onAddneedsofclient(3)" class="cursor-pointer icon-color" title="Add Need of CLient">
                            <span class="material-icons">
                                add
                            </span>
                        </a>

                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="box-lead-head mb-3 col-md-12 p-0">Preliminary Goals and Objectives:</div>
            <div class="row">
                <div class="col-md-10" *ngFor="let info of preliminarygoal;let idx = index">
                    <div class="row">
                        <div class="col-md-11">

                            <mat-form-field class="example-full-width w-100">
                                <!-- <input matInput [(ngModel)]="info.information" maxlength="500" #information="ngModel"
                                    required> -->
                                    <mat-label>Goals</mat-label>
                                                <mat-select [(ngModel)]="info.information"  (selectionChange)="selectGoals($event,idx)"
                                                    name="goalName">
                                                    <ng-container *ngFor="let a of lstGoals">
                                                        <mat-option 
                                                             [value]="a.goalName">
                                                            {{a.goalName}}
                                                        </mat-option>
                                                    </ng-container>
                                                </mat-select>

                            </mat-form-field>
                        </div>
                        <div class="col-md-1 p-0 mt-3 text-right">
                            <a (click)="removeinformation(info,idx,3)" class="cursor-pointer icon-close" title="Remove"
                                *ngIf="idx !=0">
                                <span class="material-icons">
                                    close
                                </span>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-1">
                    <p class="actions mt-3">
                        <a (click)="onAddpreliminarygoal(3)" class="cursor-pointer icon-color"
                            title="Add Need of CLient">
                            <span class="material-icons">
                                add
                            </span>
                        </a>

                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="box-lead-head mb-3 col-md-12 p-0">Initial Interventions:</div>
            <div class="row">
                <div class="col-md-10" *ngFor="let info of initialIntervention;let idx = index">
                    <div class="row">
                        <div class="col-md-11">

                            <mat-form-field class="example-full-width w-100">
                                <input matInput [(ngModel)]="info.information" maxlength="3000" #information="ngModel"
                                    required>

                            </mat-form-field>
                        </div>
                        <div class="col-md-1 p-0 mt-3 text-right">
                            <a (click)="removeinformation(info,idx,4)" class="cursor-pointer icon-close" title="Remove"
                                *ngIf="idx !=0">
                                <span class="material-icons">
                                    close
                                </span>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-1">
                    <p class="actions mt-3">
                        <a (click)="onAddinitialIntervention(3)" class="cursor-pointer icon-color"
                            title="Add Need of CLient">
                            <span class="material-icons">
                                add
                            </span>
                        </a>

                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-6" *ngIf="!istherapySevierityListAvailable">
            <div class="box-lead-head my-2 col-md-12 p-0">Severity level:
                <!-- <span class="text-red">*</span> -->
            </div>

            <!-- <div class="row">
                <div class="col-md-12">
                        <span>12/08/2022</span>
                        <span><span>-</span>Family Therapy</span>
                        <span><span>-</span>Critical</span>
                </div>
            </div> -->
            <div class="row">
                <div class="col-md-10" >
                    <div class="row">
                        <div class="col-md-11">

                            <!-- <mat-form-field class="example-full-width w-100">
                                <input matInput [(ngModel)]="info.information" maxlength="50" #information="ngModel"
                                    required>

                            </mat-form-field> -->
                            <mat-form-field class="w-60" >
                                <mat-label></mat-label>
                                <mat-select [(ngModel)]="Severity" [ngStyle]="{'color':bg}" (selectionChange)="onSelectionChanged($event.value)">
                                  <!-- <mat-option [value]="0"> 0 - Discharged </mat-option>
                                  <mat-option [value]="1"> 1 - Mild </mat-option>
                                  <mat-option [value]="2"> 2 - Medium  </mat-option>
                                  <mat-option [value]="3"> 3 - Critical </mat-option> -->
                                  <mat-option *ngFor="let item of ddlseverity" [ngStyle]="{'color': item.colocode}"  [value]="item.severityValue">{{item.severityLable}}</mat-option>
                                </mat-select>
                                <!--  <mat-error>Purpose of blocking is required</mat-error> -->
                              </mat-form-field>
                        </div>                      
                    </div>
                </div>
               
            </div>
        </div>

        <div class="col-md-12" *ngIf="istherapySevierityListAvailable">
            <div class="box-lead-head my-2 col-md-12 p-0">Theraphy Severity level:</div>
                    <div class="row" *ngFor="let therapy of therapySevierityList">
                        <div class="col-md-12 therapy-severity">
                            <div class="row">
                                <div class="col-md-12">
                                        <span>{{therapy.therapyName}}</span>
                                        <span><span>-</span>{{therapy.severityName}}</span>
                                </div>
                            </div>
                        </div>               
                     </div>
                </div>
        </div>
    <div class="col-md-12">
        <div class="text-right">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <!-- <button type="button" mat-stroked-button (click)="onSaveCmhaAssessment(0)"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{isMovedChma ? 'Next' : 'Save
                & Next'}}</button> -->
                <button type="button" mat-stroked-button (click)="onSaveCmhaAssessment(0)" *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{'Save
                & Next'}}</button>
                <!-- *ngIf="!isMovedChma" -->
            <button type="button" (click)="onSaveCmhaAssessment(1)"  mat-stroked-button *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save & Exit</button>
        </div>
    </div>
</div>
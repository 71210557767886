<div class="lead-section">
    <div class="top-btn-section clearfix">
        <h2 class="head-section mb-2 mt-0">Onboard Staff</h2>
    </div>
    <div class="col-md-12 p-0 mb-3">
        <!-- <div class="lead-box" *ngIf="isCreate || isEditUsers " -->
            <div class="lead-box" >
            <form (ngSubmit)="userinfo.form.valid  && addorUpdate(userinfo)" id="userinfo" autocomplete="off"
                #userinfo="ngForm" novalidate>
                <div class="box-lead-head"></div>
                <div class="col-md-12 p-0">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <mat-form-field class="w-100">
                                    <input matInput placeholder="First Name" maxlength="50" appAlphabetOnly
                                        [(ngModel)]="_dtoUser.firstName" #firstName="ngModel" name="firstName" 
                                        [ngClass]="{ 'is-invalid': userinfo.submitted && firstName.invalid }" required>
                                    <mat-error *ngIf="userinfo.submitted && firstName.invalid">
                                        <mat-error *ngIf="firstName.errors.required">First Name is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <mat-form-field class="w-100">
                                    <input matInput placeholder="Last Name" maxlength="50" appAlphabetOnly
                                        [(ngModel)]="_dtoUser.lastName" #lastName="ngModel" name="lastName"
                                        [ngClass]="{ 'is-invalid': userinfo.submitted && lastName.invalid }" required>
                                    <mat-error *ngIf="userinfo.submitted && lastName.invalid">
                                        <mat-error *ngIf="lastName.errors.required">Last Name is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- <div class="col-md-3">
                            <div class="form-group">
                                <mat-form-field class="w-100">
                                    <input matInput placeholder="UserName" maxlength="50" appAlphabetOnly [disabled]="isEditUsers"
                                        [(ngModel)]="_dtoUser.userName" #userName="ngModel" name="userName"
                                        [ngClass]="{ 'is-invalid': userinfo.submitted && userName.invalid }" required>
                                    <mat-error *ngIf="userinfo.submitted && userName.invalid">
                                        <mat-error *ngIf="userName.errors.required">User Name is required</mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div> -->
                        <div class="col-md-3">
                            <div class="form-group">
                                <mat-form-field class="w-100">
                                    <input matInput placeholder="Email" maxlength="50" [(ngModel)]="_dtoUser.email"
                                        #email="ngModel" name="email" 
                                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                                        [ngClass]="{ 'is-invalid': userinfo.submitted && email.invalid }" required>
                                    <mat-error *ngIf="userinfo.submitted && email.invalid">
                                        <mat-error *ngIf="email.errors.required">Email is required</mat-error>
                                        <mat-error *ngIf="email.errors?.pattern">Please enter valid email address
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Contact Number" mask='(000) 000-0000'
                                    [(ngModel)]="_dtoUser.contactNumber" #contactNumber="ngModel" name="contactNumber"
                                    [ngClass]="{ 'is-invalid': userinfo.submitted && contactNumber.invalid }" required>
                                <mat-error *ngIf="userinfo.submitted && contactNumber.invalid">
                                    <mat-error *ngIf="contactNumber.errors.required">Contact Number is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <mat-label>Role</mat-label>
                                <mat-select [(ngModel)]="_dtoUser.roleId" #roleId="ngModel" name="roleId"
                                    [ngClass]="{ 'is-invalid': userinfo.submitted && roleId.invalid }" required>
                                    <ng-container *ngFor="let item of ddlrole">
                                    <mat-option *ngIf="item.isActive && item.id != 1" [value]="item.id" (click)="onroleselect()">
                                        {{item.roleName}}
                                    </mat-option>
                                </ng-container>
                                </mat-select>
                                <mat-error *ngIf="userinfo.submitted && roleId.invalid">
                                    <mat-error *ngIf="roleId.errors.required">Role is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>

                        </div>

                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <mat-label>Location</mat-label>
                                <mat-select multiple  [(ngModel)]="_dtoUser.location" #location="ngModel"
                                    name="location" 
                                    [ngClass]="{ 'is-invalid': userinfo.submitted && location.invalid }"
                                    required>
                                    <mat-option #matOption *ngFor="let item of ddlSfccLocation" (click)="onlocationselect(item,matOption.selected)" [value]="item.sfccMasterId">
                                        {{item.locationName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="userinfo.submitted && location.invalid">
                                    <mat-error *ngIf="location.errors.required">Location  is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>

                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <mat-label>Report To</mat-label>
                                <mat-select [(ngModel)]="_dtoUser.reportedTo" #reportedTo="ngModel" name="reportedTo"
                                    [ngClass]="{ 'is-invalid': userinfo.submitted && reportedTo.invalid }" required>
                                    <mat-option *ngFor="let item of lstreportstaff" [value]="item.userId">
                                        {{item.firstName +" " +item.lastName }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="userinfo.submitted && reportedTo.invalid">
                                    <mat-error *ngIf="!noUsers && reportedTo.errors?.required">Report To  is required
                                    </mat-error>
                                    <mat-error *ngIf="noUsers">No Users Available
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="w-100">
                                <mat-label>Gender</mat-label>
                                <mat-select [(ngModel)]="_dtoUser.gender" #gender="ngModel" name="gender"
                                    [ngClass]="{ 'is-invalid': userinfo.submitted && gender.invalid }" required>
                                    <mat-option [value]="0"> Male</mat-option>
                                    <mat-option [value]="1"> Female</mat-option>
                                </mat-select>
                                <mat-error *ngIf="userinfo.submitted && gender.invalid">
                                    <mat-error *ngIf="gender.errors.required">Gender is required
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>

                        </div>
                        <div class="col-md-3">
                            <div class="check-custom mt-4">
                                <mat-checkbox class="example-margin" [(ngModel)]="_dtoUser.isManager"
                                    #isManager="ngModel" name="isManager">Manager
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="col-md-1" *ngIf="emailConfirmed">
                            <div class="check-custom mt-4">
                                <mat-checkbox class="example-margin" [(ngModel)]="_dtoUser.isActive"
                                    #isActive="ngModel" name="parentIsPrimaryContact">Active
                                </mat-checkbox>
                            </div>
                        </div>
                       
                        <div class="col-md-1">
                            <div class="check-custom mt-4">
                                <mat-checkbox class="example-margin" [(ngModel)]="_dtoUser.isLicenser"
                                    #isLicenser="ngModel" name="isLicenser">licensure
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="col-md-2" *ngIf="_dtoUser.isLicenser">
                            <div class="form-group">
                                <mat-form-field class="w-100">
                                    <input matInput placeholder="Licensure Number" specialIsAlphaNumeric maxlength="20"
                                        [(ngModel)]="_dtoUser.licenserNumber" #licenseNumber="ngModel" name="licenserNumber" 
                                         >
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="check-custom mt-4">
                              <mat-checkbox class="example-margin" [(ngModel)]="_dtoUser.isLicensed"
                                            #isLicensed="ngModel" name="isLicensed">Licensed
                              </mat-checkbox>
                            </div>
                          </div>
                    
                          <div class="col-md-2" *ngIf="_dtoUser.isLicensed">
                            <div class="form-group">
                              <mat-form-field class="w-100">
                                <input matInput placeholder="License Number" specialIsAlphaNumeric maxlength="20"
                                       [(ngModel)]="_dtoUser.licenseNumber" #licenseNumber="ngModel" name="licenseNumber" required>
                                <mat-error *ngIf="userinfo.submitted && licenseNumber.invalid">
                                  <mat-error *ngIf="licenseNumber.errors.required">License Number is required</mat-error>
                                </mat-error>
                              </mat-form-field>
                            </div>
                          </div>
                        <div class="col-md-2">
                            <div class="check-custom mt-4">
                                <mat-checkbox class="example-margin" [(ngModel)]="_dtoUser.sSignature"
                                    #sSignature="ngModel" name="sSignature">Secondary Signature
                                </mat-checkbox>
                            </div>
                        </div>
                        <div class="col-md-3" *ngIf="isActiveUser">
                            <div class="check-custom mt-4">
                                <button mat-stroked-button title="Resend Activation Mail" type="button"
                                    class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" (click)="onResendActivationStaff()">Resend Activation Mail</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 text-right" *ngIf="roleAccess.isEdit || roleAccess.isCreate">
                   <button type="submit" mat-stroked-button form="userinfo" title="{{!isEditUsers ? 'Save' :
                    'Update'}}"
                        class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{!isEditUsers ? 'Save' :
                        'Update'}}</button>
                    <button (click)="resetuserform(userinfo)" type="button" mat-stroked-button title="Cancel"
                        class="btn btn-cancel primary-btn-m h-40">Cancel</button>
                </div>
            </form>
        </div>
    </div>

    <div class="top-btn-section clearfix">
        <div class="box-lead-head float-left mt-3 pt-1">Staff list</div>
        <div class="ml-auto">
        <mat-form-field >
            <input matInput (keyup)="userFilter($event.target.value)" placeholder="Filter">
          </mat-form-field>
        </div>
    </div>
    <div class="col-md-12 table-custom p-0 mb-2">
        <div class="table-responsive">
           
            <mat-table [dataSource]="dataSource" matSort class="usertable"> 
      
                <ng-container matColumnDef="firstName" style="width: 50px;">
                  <mat-header-cell *matHeaderCellDef mat-sort-header style="white-space: nowrap;"> First Name </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.firstName}} </mat-cell>
                </ng-container> 
               
                <ng-container matColumnDef="lastName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header  style="white-space: nowrap;"> Last Name </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.lastName}} </mat-cell>
                </ng-container>  
               
                <!-- <ng-container matColumnDef="userName">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> UserName </mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{row.userName}} </mat-cell>
                </ng-container>  -->
               
                <ng-container matColumnDef="rolename">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Role </mat-header-cell>
                  <mat-cell *matCellDef="let row" > {{row.rolename}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="email" >
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
                  <mat-cell *matCellDef="let row" style="width: 100px;" > {{row.email}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="contactNumber">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Contact Number </mat-header-cell>
                  <mat-cell *matCellDef="let row" > {{row.contactNumber | mask: '(000) 000-0000'}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="locationName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Location </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.locationName}} </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell *matCellDef="let row" > {{row.isActive == 1 ? 'Active' : 'Inactive'}} </mat-cell>
                  </ng-container>
                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef> {{(roleAccess.isEdit || roleAccess.isDelete) ? 'Actions' : '' }} </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <button  mat-icon-button matTooltip="Edit" *ngIf="roleAccess.isEdit"  class="iconbutton" (click)="oneditUserProfile(row)" color="primary">
                        <mat-icon aria-label="Edit">edit</mat-icon>
                      </button>
                    <button mat-icon-button matTooltip="Delete"  *ngIf="roleAccess.isDelete" class="iconbutton" (click)="deleteUser(row)" color="warn">
                        <mat-icon aria-label="Delete">delete</mat-icon>
                      </button>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
              </mat-table>  
              <mat-paginator [pageSize]="20"[pageSizeOptions]="[ 10, 20, 50, 75, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>

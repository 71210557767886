<div  class="col-md-12 mb-4 p-0">
    <div  class="lead-box">
       
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6 pl-0">
                    <h2 class="head-section mb-2 mt-0">Notifications</h2>
                </div>
                <div class="col-md-6">
                    <div class="row">
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>From Date</mat-label>
                        <input matInput [matDatepicker]="picker" readonly (click)="picker.open()" [(ngModel)]="FromDate"
                            #startdate="ngModel" name="startdate" [max]="currentdate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>To Date</mat-label>
                        <input matInput [matDatepicker]="picker1" readonly (click)="picker1.open()" [(ngModel)]="ToDate" #enddate="ngModel"
                            name="enddate" [max]="currentdate">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    
                    
                    </mat-form-field>
                    <div class="invalid-feedback d-block" *ngIf="dateRangeerror">
                        <div>End Date must be greater than Start Date</div>
                    </div>
                </div>
                <div class="col-md-4 pr-0">
                    <button class="btn primary-btn-filled primary-btn-m h-40 float-right" (click)="applydatefilter()" type="submit">Apply</button>
                </div>
            </div>
            </div>
                
            </div>
        </div>
        <div  class="col-md-12 table-custom p-0 notify">
            
               
                <div  class="example-container mat-elevation-z8">
                    <div  class="table-responsive">
                        <mat-table [dataSource]="dataSource" matSort>
        
                            <ng-container matColumnDef="notificationTitle" >
                                <mat-header-cell *matHeaderCellDef mat-sort-header class="notify-title"> Title </mat-header-cell>
                                <mat-cell class="notify-pointer" *matCellDef="let row" (click)="markUnreadNofitication(row)"><i class="material-icons text-secondary bi bi-exclamation-circle-fill mr-2 fs-5"></i> {{row.notificationTitle}} </mat-cell>
                            </ng-container>
                        
                            <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.status == 1 ? 'Read' : 'Unread'}} </mat-cell>
                            </ng-container>
                        
                            <ng-container matColumnDef="createdOn">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Dated On </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.createdOn | date: 'MM/dd/yyyy'}} </mat-cell>
                            </ng-container>
                        
                           
                           
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;">
                            </mat-row>
                        </mat-table>
                        <div class="mt-4 text-center mb-2" *ngIf = "notificationList?.length == 0"> No records found </div>
                        <mat-paginator [pageSize]="20"  [pageSizeOptions]="[10, 20,50,100]" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
            
        </div>    
     </div>
</div>        




    


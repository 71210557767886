import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { Global } from 'src/app/global/global.model';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { PhoneSchoolmeetForm } from 'src/app/_models/_Treatment/_phoneschoolmeet';
import { ClinicianService } from '../../clinician/clinician.service';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { constantVariables } from 'src/app/constant/constant';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import moment from 'moment';
@Component({
  selector: 'app-phonecall-school-progress-notes',
  templateUrl: './phonecall-school-progress-notes.component.html',
  styleUrls: ['./phonecall-school-progress-notes.component.scss']
})
export class PhonecallSchoolProgressNotesComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('PhoneSchoolfrm')PhoneSchoolfrm : NgForm;
  selecteddate: any;
  selectedtheraphyid: any;
  selectedMsId: any;
  selectedIntervention: string;
  _dtoPatientInfo: any;
  SelectedTab =1;
  PhoneSchoolInfo:PhoneSchoolmeetForm;
  isESignAttached: boolean;
  signatureImg: any;
  updatedsignatureImg: any;
  isformtype: string;
  defaultImage: any;
  IseditFrm: boolean;
  PopUpPreview: boolean;
  roleAccess: RoelAccess;
  pdfpsytherapynotes: any;
  therapyName: string;
  isLicensed: boolean;
  RoleId: string;
  ddlFormCommunicationMaster: any;
  Communication: any;
  meetingTimeslots: any;
  IspastTime: boolean;
  currenttime: string;
  cdr: any;
  CheckOut:any;
  Checkin:any
  IsCloseWindow: boolean=false;
  secondaryId : any;
  AutoFillSS:boolean=false;
  SSignature:any;
  GetClinicianSign: any[];  
  userId: any;

  constructor(public global: Global,public settingsService: SettingsService, public clinicianservice: ClinicianService,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,public treatmentservice: TreatmentService,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,private formBuilder: FormBuilder
    , public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    this.isLicensed = localStorage.getItem('isLicensed') == 'true'?true:false;
    this.RoleId = localStorage.getItem('roleId');
    this.userId = Number(localStorage.getItem('id'));
    const pageid = constantVariables.pagelists.ProgressNoteFivepointRating;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.PhoneSchoolInfo = new PhoneSchoolmeetForm();

    this.getFormCommunication();
    this.getSchedulerTimeSlots();
    this.getAutoFillSignature();
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) { 
         this.selecteddate = JSON.parse(this.commonService.FrontEndDecryption(params.date));
        this.selectedtheraphyid = JSON.parse(this.commonService.FrontEndDecryption(params.theraphyid));
        this.selectedMsId= JSON.parse(this.commonService.FrontEndDecryption(params.id));
        if(params.IsFromHistory){
          this.IsCloseWindow = true;
        }
        if(this.selectedtheraphyid==11){this.therapyName="PHONE CALL CONVERSATION COMMUNICATION FORM";}
        else if (this.selectedtheraphyid==10) {this.therapyName="SCHOOL MEET CONVERSATION COMMUNICATION FORM";}
        this.selectedIntervention="";
        this.getPatientInfo();
        
        }
    });
    
  }

  OnTabchange(item){
    this.SelectedTab = item;
    if(this.SelectedTab ==2){
     
    }
    if(this.SelectedTab ==3){
     
    }
    
  }
  getSchedulerTimeSlots() {
    this.settingsService.getSchedulerTimeSlots().subscribe(
      res => {
        if (res != null) {
          this.meetingTimeslots = res;
          this.cdr.detectChanges();  
  
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  

  getPatientInfo() {
    let date = '2021-05-28'
    this.spinnerservice.show();
    this.secondaryId = localStorage.getItem('id');
    this.clinicianservice.getphoneschoolmeetpatientinfo(this.selectedMsId,this.selectedtheraphyid,this.selecteddate,this.secondaryId).subscribe(
      (data: any) => {
       // debugger;
        this.spinnerservice.hide();
        if (data){
        let res =this.commonService.decrypdata(data.objInputString);
         this._dtoPatientInfo=res;     
        this.PhoneSchoolInfo = res.DtoPhoneSchoolNotes;
        if(this.PhoneSchoolInfo.PhoneSchoolMeetProgressNotesId!=0 ){
          this.IseditFrm  = false
          this.OnCommunicationChange()
        this.Checkin =moment(date).format("YYYY-MM-DD") + " " + moment( this.PhoneSchoolInfo.Checkin).format('HH:mm')+":00.000";
        this.CheckOut =moment(date).format("YYYY-MM-DD") + " " + moment( this.PhoneSchoolInfo.CheckOut).format('HH:mm')+":00.000";

        }
        else{
         this.IseditFrm  = true
        }
        }
      },
        
        err => {
          this.spinnerservice.hide();
        },
      );
  }
  PostPhoneschoolFrm(){
    // if(!this.PhoneSchoolfrm.valid || (this.PhoneSchoolInfo.StaffInitials==undefined && ((this.roleAccess.isSign && this.isLicensed) || (!this._dtoPatientInfo.isLicensed && this.roleAccess.isUnlicensed) || (this._dtoPatientInfo.IsIntern && this.roleAccess.isInternSign ))) ){
    //   return
    // }
    // else{
    this.spinnerservice.show()
    this.PhoneSchoolInfo.Typeid = this.selectedtheraphyid;
    this.PhoneSchoolInfo.Checkin  = moment(this.Checkin).format("YYYY-MM-DDTHH:mm:ss")
    this.PhoneSchoolInfo.CheckOut  = moment(this.CheckOut).format("YYYY-MM-DDTHH:mm:ss")
  const dataToEncrypt = this.commonService.DataToEncrypt(this.PhoneSchoolInfo, false);
  this.clinicianservice.PostPhoneSchoolNotes(this.PhoneSchoolInfo).subscribe(
    res=>{
      this.spinnerservice.hide();
      this.snackbar.success(res);
      this.getPatientInfo()
  },
      err => {
    
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },) 
    //}
  }

  openDialog() {
    // this.global.isconsentesign = false;
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
    //   data: { isfutureSign: false, isParentsign: true },
    //   autoFocus: false 
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   this.isESignAttached = this.global.isconsentesign;
    //   if (
    //     this.global.isconsentesign != undefined &&
    //     this.global.isconsentesign
    //   ) {
      this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if (
      this.global.isconsentesign != undefined &&
      this.global.isconsentesign
    ) {
      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
        this.signatureImg = this.updatedsignatureImg;
        this.PhoneSchoolInfo.StaffInitials = this.updatedsignatureImg;
       // this.IncReportfrmInfo.IsAttachEsign = true;
        (document.getElementById('esign') as HTMLImageElement).src =
          this.signatureImg;
      } else if (
        !this.global.isconsentesign &&
        this.global.formtype != null &&
        this.global.formtype != undefined
      ) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.PhoneSchoolInfo.StaffInitials = this.global.consentesign;
        //this.IncReviewInfo.IsAttachEsign = true;
        (document.getElementById('esign') as HTMLImageElement).src =
          this.signatureImg;
      } else {
        this.signatureImg = this.defaultImage;
        //this.IncReportfrmInfo.IsAttachEsign = true;
        // this.PhoneSchoolInfo.StaffInitials = this.defaultImage;
      }
    });
}
openDialogs() {
  // this.global.isconsentesign = false;
  // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
  //   data: { isfutureSign: false, isParentsign: true },
  //   autoFocus: false 
  // });
  this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if (
      this.global.isconsentesign != undefined &&
      this.global.isconsentesign
    ) {
      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
      this.signatureImg = this.updatedsignatureImg;
      this.PhoneSchoolInfo.StaffInitial1 = this.updatedsignatureImg;
     // this.IncReportfrmInfo.IsAttachEsign = true;
      (document.getElementById('esign1') as HTMLImageElement).src =
        this.signatureImg;
    } else if (
      !this.global.isconsentesign &&
      this.global.formtype != null &&
      this.global.formtype != undefined
    ) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      this.PhoneSchoolInfo.StaffInitial1= this.global.consentesign;
      //this.IncReviewInfo.IsAttachEsign = true;
      (document.getElementById('esign1') as HTMLImageElement).src =
        this.signatureImg;
    } else {
      this.signatureImg = this.defaultImage;
      //this.IncReportfrmInfo.IsAttachEsign = true;
      // this.PhoneSchoolInfo.StaffInitials1 = this.defaultImage;
    }
  });
}
OnpreviewClick(){
  this.PopUpPreview = true;
}
OnclosePopup(){
  this.PopUpPreview = false;
}
OnbackClick(){
  if(this.IsCloseWindow){
    window.close();
  }
  else{
    this.router.navigate(['/clinician'],{queryParams:{menu:1}});

  }
 }

 OnDownload(item){
  //this.downloadLink = new ElementRef();
  this.spinnerservice.show();
    this.clinicianservice.getCommunicationformpdf(this.selectedMsId,this._dtoPatientInfo.MeetingScheduleTimeId,this._dtoPatientInfo.PatientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.pdfpsytherapynotes = res.filePath;
        if(item==1){
          window.open(this.pdfpsytherapynotes, '_blank');
        }
        else if(item==0){
          const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
        }
       
      },
      err => {
        this.spinnerservice.hide();
      },
    );
}
print(){
  let printContents = document.getElementById('print-section').innerHTML;
     let originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
    // this.ShowPreview=true;
     window.location.reload();
    
}
getFormCommunication(){
  this.spinnerservice.show()
  this.commonService.getFormCommunicationMaster().subscribe(
    res => {
      if (res != null) {
    this.ddlFormCommunicationMaster = res;
    console.log(this.ddlFormCommunicationMaster)
 this.spinnerservice.hide()
}},
err => {
  this.spinnerservice.hide();

})
}
OnCommunicationChange(){
  if(this.PhoneSchoolInfo.FormOfCommunication!=null){
  this.Communication = this.ddlFormCommunicationMaster.filter(x=>x.communicationCode == this.PhoneSchoolInfo.FormOfCommunication)[0].formCommunicationName;
  // if(val == 2)
  // {
  //   this.PostPhoneschoolFrm(2);
  // }
 
}
}
// onSelectionChange()
// {
//   this.PostPhoneschoolFrm(2);
// }
secondarySign():any{
  if(this.PhoneSchoolInfo.LicenseNumber != '' && this.PhoneSchoolInfo.LicenseNumber != null ){
    return false;
  }
  else if(this.PhoneSchoolInfo.SSignature ){
   return false;
  }
  else{
    return true;
  }
}
getAutoFillSignature() {
  this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
      this.GetClinicianSign = res;

      if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
        this.AutoFillSS=false;
        this.SSignature=this.GetClinicianSign[0].clinicianSign;
      }
      else
      {
        this.AutoFillSS=true;
        this.SSignature='';
      }
  });
}

}


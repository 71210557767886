<div class="col-md-12 p-0  view-note d-block">
  <h2 class="head-section mb-2 mt-0">Notes</h2>
  <div class="row">
    <div class="col-md-12 mb-2" *ngFor="let item of patientnotes">
      <div class="list-note">
        <div class="row">
          <div class="col-md-12">
            <div class="tag-label">
              {{item.noteType}}
            </div>
            <div class="float-left w-100 position-relative">
              <!-- <span class="check-custom invi-check2 align-middle">
                  <mat-checkbox class="example-margin" (change)="cbklistnotes($event,item, i)" name="tag{{i}}">
                  </mat-checkbox>
                </span> -->

              <p class="note-content" innerHTML="{{global.getContent(item.notesContent)}}">
                <span class="tag-label w-100 mb-1">Description</span>

              </p>
            </div>



          </div>
          <!-- <div class="col-md-1 text-right pl-0">
              <a (click)="oneditNotes(item)" class="icon-note cursor-pointer" title="edit"><span class="mr-2"><i class="far fa-edit"></i></span></a>
              <a (click)="ondeletedNotes(item)" class="icon-note cursor-pointer" title="delete"> <span class="mr-2"><i
                    class="far fa-trash-alt"></i></span></a> 
            </div>-->
          <div class="col-md-12 text-right">
            <div class="row">
              <div class="col-md-8">
                <div *ngIf="item.tags?.length > 0">
                  <span class="tag-label">Tags</span>
                  <mat-chip-list aria-label="Fish selection">
                    <mat-chip *ngFor="let a of item.tags">{{a.email}}</mat-chip>
                  </mat-chip-list>
                </div>
              </div>
              <div class="col-md-4 ">
                <a title="comment"><span
                  class="mr-2" (click)="openDialog(item)"><i class="far fa-comments"></i></span></a>
                <div class="timeanddate"><span class="time-label">Last
                    updated</span><span>{{global.globalUtctimeformat(item.updateDate)}}</span></div>

              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <div class="col-md-12" *ngIf="patientnotes?.length == 0">
      <div class="list-note w-100 mt-2 mb-2">
        <span>No records found</span>
      </div>
    </div>
    <div class="col-md-12 w-100">
      <mat-paginator class="pagination pagination-large" (page)="onPageChange($event)" [pageSize]="5"
        [pageSizeOptions]="[5, 10, 25, 100]">
      </mat-paginator>
    </div>
  </div>
</div>
export class ProfileDetails {
    firstName:string;
    lastName:string;
    email:any;
    role:string;
    roleName:string;
    primaryFacility:string;
    licenseNumber:string;
    address:string;
    userName:string;
    oldEmail:string;
    password:string;
    screenName:string;
    signature:string;
}


export class ProfileImageDetails {
    UserId:number;
    ProfileImage : string;
    ProfileImageName : string;
}
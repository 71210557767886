<!-- <div class="row p-heading">
  <div class="col justify-content-start">
      <h3 class="head-section mt-3">Roles</h3>
  </div>
  <div class="float-right mr-3" *ngIf="isPageCreate==true"> 
      <button mat-stroked-button color="accent" type="button" (click)="onAddRole()">Add</button>
  </div>
</div>
<div class="example-header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>

<div class="example-container mat-elevation-z8">

  <mat-table [dataSource]="dataSource" matSort>

    
    <ng-container matColumnDef="RoleName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> RoleName </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.RoleName}} </mat-cell>
    </ng-container> 
   
    <ng-container matColumnDef="Description">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{row.Description}} </mat-cell>
    </ng-container>  
   
    
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button mat-icon-button matTooltip="Edit"  *ngIf="isPageEdit==true" class="iconbutton" (click)="onEdituser(row.Id);"  color="primary">
            <mat-icon aria-label="Edit">edit</mat-icon>
          </button>
      
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
<div class="modal "   [style.display]="isAddRole ? 'block' : 'none'"  data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title f-clr-primary" *ngIf="!isEdit" id="staticBackdropLabel">Create New Role</h4>
        <h4 class="modal-title f-clr-primary" *ngIf="isEdit" id="staticBackdropLabel">Update Role</h4>
        
        <button type="button" class="close" (click)="closeAddRole();" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
          <form [formGroup]="roleForm" (submit)="onRoleSubmit()" autocomplete="off" class="create-user"> 
            
            <div class="form-row">
              <div class="col-6 popup-cont-sec left">
                <div class="col-12">
                  <label for="inp" class="inp">
                      <span class="label">Role Name </span>
                      <input type="text" formControlName="RoleName" placeholder="&nbsp;">
                      <div *ngIf="submitted && f.RoleName.errors" class="invalid-feedback d-block">
                          <div *ngIf="f.RoleName.errors.required || f.RoleName.errors.cannotContainSpace">RoleName is required</div>
                      </div>                       
                  </label>
                </div>
                <div class="col-12">
                  <label for="inp" class="inp">
                      <span class="label">Description </span>
                    <input type="text" formControlName="Description" placeholder="&nbsp;">                      
                    <div *ngIf="submitted && f.Description.errors" class="invalid-feedback d-block">
                      <div *ngIf="f.Description.errors.required || f.Description.errors.cannotContainSpace">Description is required</div>
                  </div>
                  </label>
                </div>
              </div>
            </div>
          </form>
      </div>
      <div class="modal-footer p-4">
          <div class="row align-items-end">
              <button type="button" class="btn primary-btn-gradient btn-min-width ml-4" data-dismiss="modal" (click)="closeAddRole();">Cancel</button>
              <button type="button" class="btn primary-btn-gradient btn-min-width ml-4" *ngIf="!isEdit" (click)="onRoleSubmit()">Submit</button>
              <button type="button" class="btn primary-btn-gradient btn-min-width ml-4" *ngIf="isEdit" (click)="onUpdateRole()">Update</button>
              
          </div>
      </div>
    </div>
  </div>
</div> -->
<!-- <div class="eclaim-section">
  <div class="col-md-12">
    <div  class="d-flex w-100">
      <div  class="col-md-3 p-0">
        <div  class="search">
          <input  type="text" placeholder="Search" name="txt" class="search-input " >
          <a  class="search-icon"><i  class="fa fa-search"></i>
          </a>
        </div>
      </div>
      <div  class="ml-auto">
        <div class="input-group-btn search-panel open position-relative">
        
          
          <button type="button" class="btn btn-default dropdown-toggle filter-btn-border" (click)="toggleStyle = !toggleStyle;" >
            <i class="fas fa-filter "></i> <span class="text-muted mr-1">Filters</span><i class="fas fa-caret-down p-0"></i>
          </button>
          
         
      </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="row">
      <div class="w-100" [ngClass]="{'col-md-9' : toggleStyle}">
        <div class="col-md-12 table-responsive table-scroll-height thin-scroll">
  
          <table class="pl-table col-md-12 w-100">
            <thead class="pl-thead tall">
              <tr  class="text-left">
                <td  class="text-muted text-xs p-2" style="width: 120px;">Claim ID</td>
                <td  class="text-muted text-xs p-2" style="width: 120px;">Patient ID</td>
                <td  class="text-muted text-xs p-2" style="width: 180px;">Patient Name</td>
                <td  class="text-muted text-xs p-2" style="width: 160px;">Insurance Company</td>
                <td  class="text-muted text-xs p-2" style="width: 143px;">Provider</td>
                <td  class="text-muted text-xs p-2" style="width: 220px;">Insurance Coverage/Charges</td>
                <td  class="text-muted text-xs p-2" style="width: 120px;">Receivables</td>
                <td class="text-muted text-xs p-2" style="width: 150px;">Status</td>
                <td  class="text-muted text-xs p-2" style="width: 150px;">Location</td>
                <td  class="text-muted text-xs p-2" style="width: 150px;">DOS</td>
                <td  class="text-muted text-xs p-2" style="width: 200px;">Claim Created Date</td>
                <td  class="text-muted text-xs p-2" style="width: 120px;">Days Over</td>
              </tr>
            </thead>
            <tbody class="pl-tbody scroll">
              <tr class=" mb-1  text-left">
                <td class="p-2"><a href="#">1234</a></td>
                <td class="p-2">22234</td>
                <td class="p-2 ">Ezra</td>
                <td class=" p-2">blue shield</td>
                <td class="p-2 ">Michel</td>
                <td class=" p-2">5000</td>
                <td class=" p-2">3000</td>
                <td class="p-2 ">Inprogress</td>
                <td class=" p-2">UK</td>
                <td class=" p-2">service</td>
                <td class=" p-2">02/10/20</td>
                <td class="p-2 ">10</td>
              </tr>
              <tr class=" mb-1  text-left">
                <td class="p-2"><a href="#">1234</a></td>
                <td class="p-2">22234</td>
                <td class="p-2 ">Ezra</td>
                <td class=" p-2">blue shield</td>
                <td class="p-2 ">Michel</td>
                <td class=" p-2">5000</td>
                <td class=" p-2">3000</td>
                <td class="p-2 ">Inprogress</td>
                <td class=" p-2">UK</td>
                <td class=" p-2">service</td>
                <td class=" p-2">02/10/20</td>
                <td class="p-2 ">10</td>
              </tr>
              <tr class=" mb-1  text-left">
                <td class="p-2"><a href="#">1234</a></td>
                <td class="p-2">22234</td>
                <td class="p-2 ">Ezra</td>
                <td class=" p-2">blue shield</td>
                <td class="p-2 ">Michel</td>
                <td class=" p-2">5000</td>
                <td class=" p-2">3000</td>
                <td class="p-2 ">Inprogress</td>
                <td class=" p-2">UK</td>
                <td class=" p-2">service</td>
                <td class=" p-2">02/10/20</td>
                <td class="p-2 ">10</td>
              </tr>
              <tr class=" mb-1  text-left">
                <td class="p-2"><a href="#">1234</a></td>
                <td class="p-2">22234</td>
                <td class="p-2 ">Ezra</td>
                <td class=" p-2">blue shield</td>
                <td class="p-2 ">Michel</td>
                <td class=" p-2">5000</td>
                <td class=" p-2">3000</td>
                <td class="p-2 ">Inprogress</td>
                <td class=" p-2">UK</td>
                <td class=" p-2">service</td>
                <td class=" p-2">02/10/20</td>
                <td class="p-2 ">10</td>
              </tr>
              <tr class=" mb-1  text-left">
                <td class="p-2"><a href="#">1234</a></td>
                <td class="p-2">22234</td>
                <td class="p-2 ">Ezra</td>
                <td class=" p-2">blue shield</td>
                <td class="p-2 ">Michel</td>
                <td class=" p-2">5000</td>
                <td class=" p-2">3000</td>
                <td class="p-2 ">Inprogress</td>
                <td class=" p-2">UK</td>
                <td class=" p-2">service</td>
                <td class=" p-2">02/10/20</td>
                <td class="p-2 ">10</td>
              </tr>
              
            </tbody>
          </table>
      </div>
      </div>
      <div class="col-md-3 d-none"  [ngClass]="{'filter-block' : toggleStyle}" >
        <div class="filter-right">
          <div class="card">
            <article class="filter-group m-0">
                <header class="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_aside1" data-abc="true" aria-expanded="false" class="collapsed">
                        <i class="icon-control fa fa-chevron-down"></i>
                        <h6 class="title">Process Filters </h6>
                    </a>
                </header>
                <div class="filter-content collapse show" id="collapse_aside1" style="">
                  <div class="card-body">
                    <div class="col-md-12 p-0 ">
                      <div class="row">
                        <div class="col-md-12 ">
                          <div class="head-sub-filter">Insurance Priority</div>
                        </div>
                        <div class="col-md-12">
                          <form>
                            <div class="check-custom">
                              <mat-checkbox class="example-margin">Primary</mat-checkbox>
                              </div>
                            <div class="check-custom">
                            <mat-checkbox class="example-margin">Secondary</mat-checkbox>
                            </div>
                            <div class="check-custom">
                              <mat-checkbox class="example-margin">Tertiary</mat-checkbox>
                              </div>
                              <div class="check-custom">
                                <mat-checkbox class="example-margin">Overdue Primary</mat-checkbox>
                                </div>
                                <div class="check-custom">
                                  <mat-checkbox class="example-margin">Overdue Secondary</mat-checkbox>
                                  </div>
                                  <div class="check-custom">
                                    <mat-checkbox class="example-margin">Overdue Tertiary</mat-checkbox>
                                    </div>
                         
                            
                          </form>
                        </div>
                        <div class="col-md-12 ">
                          <div class="head-sub-filter">Status</div>
                        </div>
                        <div class="col-md-12">
                          <form>
                            
                            <div class="check-custom">
                            <mat-checkbox class="example-margin">Posted</mat-checkbox>
                            </div>
                            <div class="check-custom">
                              <mat-checkbox class="example-margin">Filed Prim</mat-checkbox>
                              </div>
                              <div class="check-custom">
                                <mat-checkbox class="example-margin">Paid Prim Refile</mat-checkbox>
                                </div>
                                <div class="check-custom">
                                  <mat-checkbox class="example-margin">Filed Prim Reject</mat-checkbox>
                                  </div>
                                  <div class="check-custom">
                                    <mat-checkbox class="example-margin">Hold</mat-checkbox>
                                    </div>
                                    <div class="check-custom">
                                      <mat-checkbox class="example-margin">Closed/Completed</mat-checkbox>
                                      </div>
                         
                            
                          </form>
                        </div>
                        <div class="col-md-12 ">
                          <div class="head-sub-filter">Form Type</div>

                        </div>
                        <div class="col-md-12 custom-radio">
                          <mat-radio-group aria-label="Select an option">
                            <mat-radio-button value="1" class="mr-2">CMS 1500</mat-radio-button>
                            <mat-radio-button value="2">UB04</mat-radio-button>
                          </mat-radio-group>
                        </div>
                        
                      </div>

                      
                    </div>
                    
              
                    </div>
                </div>
            </article> 
            <article class=" filter-group m-0">
                <header class="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_aside2" data-abc="true" aria-expanded="false" class="collapsed">
                        <i class="icon-control fa fa-chevron-down"></i>
                        <h6 class="title">Date Filters </h6>
                    </a>
                </header>
                <div class="filter-content collapse" id="collapse_aside2" style="">
                    <div class="card-body">
                      
                      <div class="col-md-12 custom-radio pl-0">
                        <mat-radio-group aria-label="Select an option">
                          <mat-radio-button value="1" class="mr-2">Date</mat-radio-button>
                          <mat-radio-button value="2">DOS</mat-radio-button>
                        </mat-radio-group>
                      </div>
                      <div class="form-row">

                        <div class="form-group col-md-6">
                          <label>From</label>
                          <input type="date" class="form-control" id="inputEmail4" >
                        </div>
                        <div class="form-group col-md-6 text-left">
                          <label>To</label>
                          <input type="Date" class="form-control" >
                        </div>
                        </div>
                       </div>  
                </div>
            </article>  
            <article class="filter-group m-0">
                <header class="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_aside3" data-abc="true" aria-expanded="false" class="collapsed">
                        <i class="icon-control fa fa-chevron-down"></i>
                        <h6 class="title">Other Filters </h6>
                    </a>
                </header>
                <div class="filter-content collapse" id="collapse_aside3" style="">
                    <div class="card-body">
                      <div class="col-md-12 mb-3">
                        
                          <select  class="form-control" >
                            <option value="one">Insurance Company</option>
                            <option value="two">Second option</option>
                          </select>
                         
                      </div>
                      <div class="col-md-12">
                          <select  class="form-control" >
                            <option value="one">Location</option>
                            <option value="two">Second option</option>
                          </select>
                     
                      </div>
                      
                      
                     
                    </div>  
                </div>
            </article>  
            <article class="filter-group m-0">
                <header class="card-header">
                    <a href="#" data-toggle="collapse" data-target="#collapse_aside4" data-abc="true" class="collapsed" aria-expanded="false">
                        <i class="icon-control fa fa-chevron-down"></i>
                        <h6 class="title">Totals </h6>
                    </a>
                </header>
                <div class="filter-content collapse" id="collapse_aside4" style="">
                    <div class="card-body p-2 pt-3">
                      <ul class=" "  >
                        <li>
                          <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                            <div class="check-custom">
                            <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit"><i class="fas fa-wifi"></i></span>Transmit</mat-checkbox>
                          </div>
                          <div class="ml-auto">32</div>
                          </div>
                        </li>
                        <li>
                          <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                            <div class="check-custom">
                            <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit-w"><i class="fas fa-wifi"></i></span>Transmit w/warnings</mat-checkbox>
                          </div>
                          <div class="ml-auto">0</div>
                          </div>
                        </li>
                        <li>
                          
                          <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                            <div class="check-custom">
                            <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit"><i class="fas fa-print"></i></span>Print</mat-checkbox>
                          </div>
                          <div class="ml-auto">0</div>
                          </div>
                        </li>
                        <li>
                          <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                            <div class="check-custom">
                            <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit-w"><i class="fas fa-print"></i></span>Print w/warnings</mat-checkbox>
                          </div>
                          <div class="ml-auto">0</div>
                          </div>
                        </li>
                        <li>
                          <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                            <div class="check-custom">
                            <mat-checkbox class="example-margin"><span class="mr-2 bg-transmit-error"><i class="fas fa-times"></i></span>Error or Info Missing</mat-checkbox>
                          </div>
                          <div class="ml-auto">0</div>
                          </div>
                        </li>
                        <li><hr class="dropdown-divider"></li>
                        <li>
                          <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                            
                            <span class="total-text">Total Tickets</span>
                         
                          <div class="ml-auto">0</div>
                          </div>
                        </li>
                        <li>
                          <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                            
                          <span class="total-text"> Total Patients</span>
                         
                          <div class="ml-auto">0</div>
                          </div>
                        </li>
                        <li>
                          <div class="dropdown-item p-0 d-flex gap-2 align-items-center" >
                            
                            <span class="total-text"> Total Balance </span>
                         
                          <div class="ml-auto">0</div>
                          </div>
                        </li>
                      </ul>
                    </div>   
                </div>
            </article>  
        </div>
        </div>
       
      </div>
    </div>
  </div>
  
</div> -->

<!-- <div class="psycharist-progress">
  <div class="col-md-12">
    <div class="d-flex met-info-top w-100">
      <i  class="fas fa-user"></i>
      <div  class="mr-4">Client Name <span  class="imp-info">John Carter </span></div>
      <div  class="mr-4">Psychiatrist Name <span  class="imp-info">Dr. Gary Pedneault, MD </span></div>
      <div  class="mr-4"> Start Date <span  class="imp-info">05/03/2022</span></div>
      <div  class="mr-4"> Start Time <span  class="imp-info">06:00 PM</span></div>
      
      <div  class="ml-auto">
        <button  type="button" mat-stroked-button class="mat-focus-indicator bg-white top-back mt-1 mb-2 h-40 mr-2 mat-stroked-button mat-button-base">Back</button>
      </div>
      </div>
  </div>
  
  <div class="col-md-12  mt-3">
    <div class="lead-box pt-2 pb-2">
    <div class="tab-history custom-tab-border mt-2">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item mr-1" role="presentation">
          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#progress" type="button" role="tab" aria-controls="s1" aria-selected="true" (click)="OnTabchange(1)"  [ngClass]="{'active show': SelectedTab == 1}" >Progress Notes</button>
        </li>
        <li class="nav-item mr-1" role="presentation">
          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#goals" type="button" role="tab" aria-controls="s3" aria-selected="true" (click)="OnTabchange(2)"  [ngClass]="{'active show': SelectedTab == 2}" >Goals</button>
        </li>
        
        
      </ul>
      
      <div class="tab-content">
        <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==1">
         <div class="col-md-12 mt-3">
          <div  class="row">
            <div  class="d-flex w-100">
              <div  class="box-lead-head mb-2 ml-3">Psychiatrist Notes </div>
              <div  class="ml-auto mr-2">
                <button  type="submit" class="btn primary-btn-outlined-small mr-2 float-left">
                  <i class="far fa-eye mr-2"></i>Preview</button>
                  <button  form="frmindfamtheraphy" type="submit" class="btn primary-btn-outlined-small ng-star-inserted">
                    <i  class="far fa-save mr-2"></i>Save</button>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-2 font-weight-bold "><span class="control-label-new">Client Name</span></label> 
                <div  class=" col-md-3 form-group">
                 <input type="text" class="form-control" />
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-2 font-weight-bold "><span class="control-label-new">Psychiatrist</span></label> 
                <div  class=" col-md-3 form-group">
                 <input type="text" class="form-control" />
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Chief Complaint/Changes since last visit: </label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Describe Mental Status</label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control " ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class=" col-md-2 font-weight-bold "><span class="control-label-new">Assessment</span></label> 
                <div  class="col-md-10 form-group">
                  <div class="d-flex check-fields-large-label">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Stable</mat-checkbox>
                  </div>
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Improvement</mat-checkbox>
                  </div>
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Decline</mat-checkbox>
                  </div>
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">No Change</mat-checkbox>
                  </div>
                </div>
                 
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="treat-label col-md-2 font-weight-bold "><span class="control-label-new">Medication Compliance</span></label> 
                <div  class="col-md-10 form-group">
                  <mat-radio-group aria-label="Select an option" >
                    <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                    <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              </div>
              <div class="col-md-12 check-fields-large-label mb-2">
                <div class="check-custom">
                  <mat-checkbox class="example-margin">Assessed for occurrence of medication reactions/allergies/sensitivities</mat-checkbox>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class=" col-md-3 font-weight-bold pr-0 "><span class="control-label-new">Were any medication reaction reported?</span></label>  
                <div  class="col-md-9 form-group">
                  <mat-radio-group aria-label="Select an option" >
                    <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                    <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new  font-weight-bold ">If Yes, please document medication reaction(s) reported by Client and/or family member</label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control " ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-1 font-weight-bold "><span class="control-label-new">Diagnosis</span></label> 
                <div  class=" col-md-3 form-group">
                 <input type="text" class="form-control" />
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Treatment (Medication(s) prescribed including dosage, frequency, amount)</label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control " ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Treatment Goals and Objectives</label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control " ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Goals and Objective for Service</label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control " ></textarea>
                </div>
              </div>
              <div class="col-md-12 check-fields-large-label mb-2">
                <div class="check-custom">
                  <mat-checkbox class="example-margin">Please check, if any medication changes since last visit</mat-checkbox>
                </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Medication Education/Counseling (please check if completed; if not, explain below) </label> 
                <div class="form-group row check-fields-large-label">
                  <div class="col-md-12">
                    <div class="check-custom">
                      <mat-checkbox class="example-margin">Medication education/counseling provided</mat-checkbox>
                 
                    </div>
                     </div>
                     <div class="col-md-12">
                      <div class="check-custom">
                        <mat-checkbox class="example-margin">Side effects and drug interactions and risks and benefits of medication(s) explained</mat-checkbox>
                   
                      </div>
                       </div>
                       <div class="col-md-12">
                        <div class="check-custom">
                          <mat-checkbox class="example-margin">Medication Information Purpose & Side Effect Checklist form completed and provided for each medication</mat-checkbox>
                     
                        </div>
                         </div>
                         <div class="col-md-12">
                          <div class="check-custom">
                            <mat-checkbox class="example-margin">Counseled regarding concomitant drug and alcohol use</mat-checkbox>
                       
                          </div>
                           </div>
                           <div class="col-md-12">
                            <div class="check-custom">
                              <mat-checkbox class="example-margin">Medication discussed with parent/legal guardian; Accompanied by (Name and Relationship)</mat-checkbox>
                         
                            </div>
                             </div>
                             <div class="col-md-12">
                              <div class="check-custom">
                                <mat-checkbox class="example-margin">Informed of heat/sun related risks</mat-checkbox>
                           
                              </div>
                               </div>
                               <div class="col-md-12">
                                <div class="check-custom">
                                  <mat-checkbox class="example-margin">Client understands the name of medication(s) and prescribed regimen</mat-checkbox>
                             
                                </div>
                                 </div>
                </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Comments</label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold "> Next Follow-Up</label> 
               <div class="form-group">
                <mat-radio-group aria-label="Select an option" >
                  <mat-radio-button value="1" class="mr-2">1 week  </mat-radio-button>
                  <mat-radio-button value="2" class="mr-2">2 week</mat-radio-button>
                  <mat-radio-button value="3" class="mr-2">1 month</mat-radio-button>
                  <mat-radio-button value="4" class="mr-2">2 month</mat-radio-button>
                  <mat-radio-button value="5" class="mr-2">3 month</mat-radio-button>
                </mat-radio-group>
               </div>
              </div>
              <div  class="col-md-12">
                <div  class="form-group clearfix m-0">
                  <div  class="float-left col-md-4 p-0 mt-3 pt-1">
                    <label  class="treat-label mr-2">Clinician Signtaure</label>
                    <img  id="esign" height="50px" width="300px" src="">
                    <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"> Add E-Sign </button>
                  </div>
                  <div  class="float-right col-md-2 p-0 ">
                    <mat-form-field class="w-100">
                      <mat-label>Date</mat-label>
                      <input matInput [matDatepicker]="picker">
                      
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
             </div>
             <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==2"></div>
            </div>  
            </div>

   </div>
    

  </div>
  </div> -->
<!-- <div class="psycharist-progress">
  <div class="col-md-12">
    <div class="d-flex met-info-top w-100">
      <i  class="fas fa-user"></i>
      <div  class="mr-4">Client Name <span  class="imp-info">John Carter </span></div>
      <div  class="mr-4">Psychiatrist Name <span  class="imp-info">Ezra </span></div>
      <div  class="mr-4"> Start Date <span  class="imp-info">05/03/2022</span></div>
      <div  class="mr-4"> Start Time <span  class="imp-info">06:00 PM</span></div>
      
      <div  class="ml-auto">
        <button  type="button" mat-stroked-button class="mat-focus-indicator bg-white top-back mt-1 mb-2 h-40 mr-2 mat-stroked-button mat-button-base">Back</button>
      </div>
      </div>
  </div>
  <div class="col-md-12  mt-3">
    <div class="lead-box pt-2 pb-2">
      <div class="tab-history custom-tab-border mt-2">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item mr-1" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#progress" type="button" role="tab" aria-controls="s1" aria-selected="true" (click)="OnTabchange(1)"  [ngClass]="{'active show': SelectedTab == 1}" >Progress Notes</button>
          </li>
          <li class="nav-item mr-1" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#goals" type="button" role="tab" aria-controls="s3" aria-selected="true" (click)="OnTabchange(2)"  [ngClass]="{'active show': SelectedTab == 2}" >Goals</button>
          </li>
          
          
        </ul>
        
        <div class="tab-content">
          <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==1">
           <div class="col-md-12 mt-3">
            <div  class="row">
              <div  class="d-flex w-100">
                <div  class="box-lead-head mb-2 ml-3">Psychiatric/Medication Management Form </div>
                <div  class="ml-auto mr-2">
                  <button  type="submit" class="btn primary-btn-outlined-small mr-2 float-left">
                    <i class="far fa-eye mr-2"></i>Preview</button>
                    <button  form="frmindfamtheraphy" type="submit" class="btn primary-btn-outlined-small ng-star-inserted">
                      <i  class="far fa-save mr-2"></i>Save</button>
                    </div>
                  </div>
                </div>
              </div>
 
    
    <div class="col-md-12">
      <div class="row">
      <label  class="col-md-2 font-weight-bold "><span class="control-label-new">Client-partner Name</span></label> 
      <div  class=" col-md-3 form-group">
       <input type="text" class="form-control" />
      </div>
    </div>
    </div>
    <div class="col-md-12">
      <div class="row">
      <label  class="col-md-2 font-weight-bold "><span class="control-label-new">Psychiatrist Name</span></label> 
      <div  class=" col-md-3 form-group">
       <input type="text" class="form-control" />
      </div>
    </div>
    </div>
    <div class="col-md-12 mt-3 mb-3">
      <div class="medication-dosage">
        <div  class="row inputElement">
          <div  class="col-md-3">
            <label >Medications</label>
          </div>
          <div  class="col-md-3">
            <label >Dosage</label>
          </div>
          <div  class="col-md-3">
            <label>Frequency</label>
          </div>
          <div  class="col-md-3">
            <label >Side effects</label>
          </div>
        </div>
        <div  class="loop-section position-relative mb-2 ">
          <div class="row inputElement">
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Medicine" type="text" required="" class="form-control "  >
            </div>
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Dosage" type="text" required="" class="form-control "  >
            </div>
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Frequency" type="text" required="" class="form-control "  >
            </div>
            <div  class="col-md-3">
              <input  maxlength="50" placeholder="Side effects" type="text" required="" class="form-control "  >
            </div>
          </div>
          <a  class="add " style="cursor: pointer;">
            <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
          </a>
          <a  class="remove" style="cursor: pointer;">
            <i  class="fa fa-lg fa-minus-circle"></i>
            <span  class="sr-only">Remove</span>
          </a>
        </div>
      </div>
    </div>
    
    <div class="col-md-12">
      <label  class="control-label-new font-weight-bold ">Conversation Details </label> 
      <div  class="form-group">
        <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
      </div>
    </div>
    
    <div  class="col-md-12">
      <div  class="form-group clearfix m-0">
        <div  class="float-left col-md-4 p-0 mt-3 pt-1">
          <label  class="treat-label mr-2">Staff Signature </label>
          <img  id="esign" height="50px" width="300px" src="">
          <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"> Add E-Sign </button>
        </div>
        <div  class="float-right col-md-2 p-0 ">
          <mat-form-field class="w-100">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="picker">
            
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div  class="col-md-12">
      <div  class="form-group clearfix m-0">
        <div  class="float-left col-md-4 p-0 mt-3 pt-1">
          <label  class="treat-label mr-2">Psychiatrist Signature</label>
          <img  id="esign" height="50px" width="300px" src="">
          <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"> Add E-Sign </button>
        </div>
        <div  class="float-right col-md-2 p-0 ">
          <mat-form-field class="w-100">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="picker">
            
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
          </div>
        </div>
      </div>
      <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==2">
         </div>
    </div>
    </div>
    </div>
  </div>
</div> -->
<!-- <div class="psycharist-progress">
  <div class="col-md-12">
    <div class="d-flex met-info-top w-100">
      <i  class="fas fa-user"></i>
      <div  class="mr-4">Client Name <span  class="imp-info">John Carter </span></div>
      <div  class="mr-4">Psychiatrist Name <span  class="imp-info">Ezra </span></div>
      <div  class="mr-4"> Start Date <span  class="imp-info">05/03/2022</span></div>
      <div  class="mr-4"> Start Time <span  class="imp-info">06:00 PM</span></div>
      
      
      <div  class="ml-auto">
        <button  type="button" mat-stroked-button class="mat-focus-indicator bg-white top-back mt-1 mb-2 h-40 mr-2 mat-stroked-button mat-button-base">Back</button>
      </div>
      </div>
  </div>
  <div class="col-md-12  mt-3">
    <div class="lead-box pt-2 pb-2">
        
      <div class="tab-history custom-tab-border mt-2">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item mr-1" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#progress" type="button" role="tab" aria-controls="s1" aria-selected="true" (click)="OnTabchange(1)"  [ngClass]="{'active show': SelectedTab == 1}" >Psychiatric Evaluation</button>
          </li>
          <li class="nav-item mr-1" role="presentation">
            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#goals" type="button" role="tab" aria-controls="s3" aria-selected="true" (click)="OnTabchange(2)"  [ngClass]="{'active show': SelectedTab == 2}" >Mental Status</button>
          </li>
          
          
        </ul>
        
        <div class="tab-content">
          <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==1">
           <div class="col-md-12 mt-3">
             <div class="row">
              <div  class="d-flex w-100">
                <div  class="box-lead-head mb-2 ml-3">Psychiatric Evaluation </div>
                <div  class="ml-auto mr-2">
                  <button  type="submit" class="btn primary-btn-outlined-small mr-2 float-left">
                    <i class="far fa-eye mr-2"></i>Preview</button>
                    <button  form="frmindfamtheraphy" type="submit" class="btn primary-btn-outlined-small ng-star-inserted">
                      <i  class="far fa-save mr-2"></i>Save</button>
                    </div>
                  </div>
             </div>
             
           </div>
           <div class="">
              <form>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                     <mat-form-field class="w-100">
                       <mat-label>Client Name </mat-label>
                       <input matInput>
                     </mat-form-field>
                    </div>
                    <div class="col-md-4">
                      <mat-form-field class="w-100">
                        <mat-label>Evaluating Psychiatrist </mat-label>
                        <input matInput>
                      </mat-form-field>
                     </div>
                     <div class="col-md-2">
                      <mat-form-field class="w-100">
                        <mat-label>Date of Birth</mat-label>
                        <input matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                     </div>
                    <div class="col-md-2">
                     <mat-form-field class="w-100">
                       <mat-label>Date of Evaluation</mat-label>
                       <input matInput [matDatepicker]="picker">
                       <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                       <mat-datepicker #picker></mat-datepicker>
                     </mat-form-field>
                    </div>
                   
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Identification</label>
                  <div  class="form-group">
                    <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Chief Complaint</label>
                  <div  class="form-group">
                    <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">History of Presenting Illness</label>
                  <div  class="form-group">
                    <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Past Psychiatric History	</label>
                  <div  class="form-group">
                    <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">ETOH & Substance Abuse History</label>
                  <div  class="form-group">
                    <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Medical History</label>
                  <div  class="form-group">
                    <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Current Medications</label>
                  <div  class="form-group">
                    <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Comprehensive Medication History</label>
                  <div  class="form-group">
                    <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Developmental History</label>
                  <div  class="form-group">
                    <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Psychosocial History</label>
                  <div  class="form-group">
                    <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <label  class="control-label-new font-weight-bold">Family History</label>
                  <div  class="form-group">
                    <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                  </div>
                </div>
           </form>
           </div>
          </div>
          <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==2">
            <div class="col-md-12 mt-3">
              <div class="row">
               <div  class="d-flex w-100">
                 <div  class="box-lead-head mb-2 ml-3">Mental Status </div>
                 <div  class="ml-auto mr-2">
                   <button  type="submit" class="btn primary-btn-outlined-small mr-2 float-left">
                     <i class="far fa-eye mr-2"></i>Preview</button>
                     <button  form="frmindfamtheraphy" type="submit" class="btn primary-btn-outlined-small ng-star-inserted">
                       <i  class="far fa-save mr-2"></i>Save</button>
                     </div>
                   </div>
              </div>
              
            </div>
            <div class="col-md-12">
              <label  class="control-label-new font-weight-bold">Appearance: (Posture, Facial Expressions, Tics, Eye Contact, Weight, Height, Hygiene)</label>
              <div  class="form-group row check-fields-large-label">
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Neat</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Well Groomed</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Well Nourished</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2"> Adequate Hygiene</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Older Than Stated Age</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Younger Than Stated Age</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Appeared Stated Age</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Disheveled</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Malodorous</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Average Weight</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Overweight</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Obese</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Thin</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Malnourished</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Short in Stature</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="check-custom">
                    <mat-checkbox class="example-margin mr-2">Tall</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="check-custom mt-2">
                        <mat-checkbox class="example-margin mr-2">Tatoos</mat-checkbox>
                      </div>
                    </div>
                    <div class="col-md-9 d-none">
                      <div  class="form-group">
                        <textarea  name="complaint" rows="1" class="form-control text-xs font-italic" placeholder="Enter tatoo details"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                <div class="col-md-3">
                  <div class="check-custom mt-2">
                    <mat-checkbox class="example-margin mr-2">Birthmarks</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-9 d-none">
                  <div  class="form-group">
                    <textarea  name="complaint" rows="1" class="form-control text-xs font-italic" placeholder="Enter birthmark details"></textarea>
                  </div>
                </div>
                </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                <div class="col-md-3">
                  <div class="check-custom mt-2">
                    <mat-checkbox class="example-margin mr-2">Dental Condition</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-9 d-none">
                  <div  class="form-group">
                    <textarea  name="complaint" rows="1" class="form-control text-xs font-italic" placeholder="Enter dental condition details"></textarea>
                  </div>
                </div>
                </div>
                </div>
              </div>
            </div>
           <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Motor Activity: (Shaking Foot, Tapping, Tics, Fidgety)</label>
            <div  class="form-group">
              <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
            </div>
           </div>
           <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Facial Expressions</label>
            <div  class="form-group">
              <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
            </div>
           </div>
           <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">What is predominant feeling/emotional response? </label>
            <div  class="form-group row check-fields-large-label">
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Happy</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Elated</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Fearful</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2"> Anxious</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Angry</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Hostile</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Indifferent </mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Flat</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Blunted</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom mt-2">
                  <mat-checkbox class="example-margin mr-2">Other</mat-checkbox>
                </div>
              </div>
              <div class="col-md-12 d-none">
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control text-xs font-italic" placeholder="If others, please enter details"></textarea>
                </div>
              </div>
              
              
              
            </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Attitude/Behavior</label>
            <div  class="form-group row check-fields-large-label">
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Cooperative</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Friendly</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Engaged</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2"> Polite</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Humble</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Shy</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Uncooperative</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Sullen</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Tearful</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Guarded</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Resistant </mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Other</mat-checkbox>
                </div>
              </div>
              <div class="col-md-12 d-none">
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control text-xs font-italic" placeholder="If others, please enter details"></textarea>
                </div>
              </div>
              
              
              
            </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Mood/Affect: (examples) On a scale of 1-10 with 10 being very happy, answer the following</label>
            <div class="form-group d-flex">
              <label class="treat-label ">How do you feel most days?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control ">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">How do you feel now?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control ">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">In general, would you say that you feel good about yourself?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control ">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">Do you find yourself crying sometimes?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control ">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">Are you a worrier? </label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control ">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">Do you experience mood swings?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-2">
                    <select class="form-control ">
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                </div>
              
              </div>
            </div>
            <div class="form-group d-flex">
              <label class="treat-label ">For children: If you had 3 wishes, what would they be?</label>
              <div class="ml-auto col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <div  class="form-group">
                      <textarea  name="complaint" rows="3" class="form-control text-xs font-italic" placeholder="Please, enter details"></textarea>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Mood</label>
            <div  class="form-group row check-fields-large-label">
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Happy </mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Anxious</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Elevated</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2"> Liable</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Euthymic</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Sad</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Hostile</mat-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="row">
            <label  class="treat-label col-md-3 font-weight-bold "><span class="control-label-new">Is affect congruent to discussion? </span></label> 
            <div  class="col-md-9 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Speech</label>
            <div  class="form-group row check-fields-large-label">
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Normal rate </mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Normal Volume</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Soft Spoken	</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2"> Loud</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Logical</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Slurred</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Clear</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Garbled/Mumbling</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2"> Coherent</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Incoherent</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Stuttering</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Halting</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Guarded</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Obscene</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Rambling</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Loose Association</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Flight of Ideas</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Poverty of Thought</mat-checkbox>
                </div>
              </div>
              <div class="col-md-3">
                <div class="check-custom">
                  <mat-checkbox class="example-margin mr-2">Other</mat-checkbox>
                </div>
              </div>
              <div class="col-md-12 d-none">
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control text-xs font-italic" placeholder="If others, please enter details"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Additional Comments</label>
            <div  class="form-group">
              <textarea  name="complaint" rows="3" class="form-control "  ng-reflect-name="txt_clinical"></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Perceptual Disturbances</label>
            <div  class="form-group">
              <textarea  name="complaint" rows="3" class="form-control "  ng-reflect-name="txt_clinical"></textarea>
            </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Thought Process</label>
            <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Does the client’s thinking make sense? </span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Are thought patterns connected?	</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Flight of ideas? (Pressured skipping from one idea to another) </span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Loose association? (Connections between thoughts that seem mixed up and although the client seems to be a connection, it is far from obvious to you)</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <label  class=" col-md-8 font-weight-bold "><span class="treat-label">Any evidence of magical thinking?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Thought Content (examples)</label>
            <div class="sub-head-psy">Delusions: </div>
            <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you believe that you have special power? </span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you feel that another person or force is controlling your behavior? </span></label> 
            
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="sub-head-psy">Paranoia: </div>
          <div class="row">
            
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you ever have thoughts that people are talking or laughing about either you or the things you say or do?</span></label> 
            
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          </div>
          <div class="col-md-12">
            <label  class="control-label-new font-weight-bold">Suicidal/Homicidal Ideations</label>
            <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Have you ever been so sad that you thought about hurting/killing yourself? </span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
              <textarea  name="complaint" rows="3" class="form-control " placeholder="When?" ng-reflect-name="txt_clinical"></textarea>
           
            </div>
          </div>
         
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Have you ever tried to commit suicide?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
              <textarea  name="complaint" rows="3" class="form-control " placeholder="When?" ng-reflect-name="txt_clinical"></textarea>
           
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Plan/Intent?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
              <textarea  name="complaint" rows="3" class="form-control " placeholder="When?" ng-reflect-name="txt_clinical"></textarea>
           
            </div>
          </div>
          <div class="row">
            <label  class=" col-md-8 font-weight-bold "><span class="treat-label">Do you have a history of violent behavior/getting into fights frequently?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Have you ever felt so angry that you thought about hurting or killing someone?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
              <textarea  name="complaint" rows="3" class="form-control " placeholder="If yes, when, about whom, and when is the last time you felt this way?" ng-reflect-name="txt_clinical"></textarea>
           
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Plan/Intent?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
              </mat-radio-group>
              <textarea  name="complaint" rows="3" class="form-control " placeholder="When?" ng-reflect-name="txt_clinical"></textarea>
           
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Orientation</label>
          <div class="sub-head-psy">Time: </div>
          <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">When you came in today, was it in the</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Morning </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">Afternoon</mat-radio-button>
              <mat-radio-button value="3" class="mr-2">Evening</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="sub-head-psy">Place : </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you know the name of this building? </span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="sub-head-psy">Person : </div>
        <div class="row">
          
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Can you tell me why you are here today?</span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        </div>
        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Memory (examples)</label>
          <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Has your memory been good?</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you find you are having difficulty remembering names?	</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you find you are having difficulty remembering telephone numbers?</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you find you are having difficulty remembering appointments?</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Yes </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row">
          <label  class=" col-md-8 font-weight-bold "><span class="treat-label">What did you have to eat yesterday? </span></label> 
          <div  class="col-md-4 form-group">
            <textarea  name="complaint" rows="3" class="form-control " placeholder="Please, enter details" ng-reflect-name="txt_clinical"></textarea>
           
          </div>
        </div>
        <div class="row">
          <label  class=" col-md-8 font-weight-bold "><span class="treat-label">What did you do last weekend? </span></label> 
          <div  class="col-md-4 form-group">
            <textarea  name="complaint" rows="3" class="form-control " placeholder="Please, enter details" ng-reflect-name="txt_clinical"></textarea>
           
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">I am going to give you 3 objects to remember and tell back to me when I ask</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Good   </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">Fair</mat-radio-button>
              <mat-radio-button value="3" class="mr-2">Poor</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">I am going to give you 4 numbers.  I would like you to repeat them and say in reverse.</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Good   </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">Fair</mat-radio-button>
              <mat-radio-button value="3" class="mr-2">Poor</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        </div>
        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Concentration (examples) </label>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Starting at 20, I would like you to count backwards by 3</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Good   </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">Fair</mat-radio-button>
                <mat-radio-button value="3" class="mr-2">Poor</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Name the days of the week in reverse.</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Good   </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">Fair</mat-radio-button>
                <mat-radio-button value="3" class="mr-2">Poor</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Intelligence</label>
          <div class="sub-head-psy"> Fund of knowledge: </div>
          <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Name 5 animals, foods, countries.</span></label> 
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Above Average </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">Average</mat-radio-button>
              <mat-radio-button value="3" class="mr-2">Below Average</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="sub-head-psy">Abstract Thinking : </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">How is a lie different from a mistake? </span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Above Average </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">Average</mat-radio-button>
              <mat-radio-button value="3" class="mr-2">Below Average</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="row">
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">How are a window and a door the same?</span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Above Average </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">Average</mat-radio-button>
              <mat-radio-button value="3" class="mr-2">Below Average</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="sub-head-psy">vocabulary : </div>
        <div class="row">
          
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Spell “elephant”.</span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Above Average </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">Average</mat-radio-button>
              <mat-radio-button value="3" class="mr-2">Below Average</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <div class="sub-head-psy"> Calculation : </div>
        <div class="row">
          
          <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">How many quarters are in $1.75?</span></label> 
          
          <div  class="col-md-4 form-group">
            <mat-radio-group aria-label="Select an option" >
              <mat-radio-button value="1" class="mr-2">Above Average </mat-radio-button>
              <mat-radio-button value="2" class="mr-2">Average</mat-radio-button>
              <mat-radio-button value="3" class="mr-2">Below Average</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        </div>

        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Judgment: (examples) </label>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Can you think of a situation where you have acted foolish or made a bad choice?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Good   </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">Fair</mat-radio-button>
                <mat-radio-button value="3" class="mr-2">Poor</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">What would you do if someone criticizes a job that you have done or a decision out have made?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Good   </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">Fair</mat-radio-button>
                <mat-radio-button value="3" class="mr-2">Poor</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Insight: (examples) </label>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you think that you usually make good decisions?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Good   </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">Fair</mat-radio-button>
                <mat-radio-button value="3" class="mr-2">Poor</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">Do you think that you are in anyway responsible for what has happened?</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Good   </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">Fair</mat-radio-button>
                <mat-radio-button value="3" class="mr-2">Poor</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">What part do you think that you played in this?</span></label> 
            <div  class="col-md-4 form-group">
                <textarea  name="complaint" rows="3" placeholder="Please, enter details" class="form-control " ng-reflect-name="txt_clinical"></textarea>
              
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="treat-label">What changes would help you most?</span></label> 
            <div  class="col-md-4 form-group">
              <textarea  name="complaint" rows="3" placeholder="Please, enter details" class="form-control " ng-reflect-name="txt_clinical"></textarea>
              
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="control-label-new font-weight-bold">Frustration Tolerance</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Good   </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">Fair</mat-radio-button>
                <mat-radio-button value="3" class="mr-2">Poor</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row">
            <label  class="treat-label col-md-8 font-weight-bold "><span class="control-label-new font-weight-bold">Impulse Control</span></label> 
            <div  class="col-md-4 form-group">
              <mat-radio-group aria-label="Select an option" >
                <mat-radio-button value="1" class="mr-2">Good   </mat-radio-button>
                <mat-radio-button value="2" class="mr-2">Fair</mat-radio-button>
                <mat-radio-button value="3" class="mr-2">Poor</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          
        </div>

        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Comments</label>
          <div  class="form-group">
            <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
          </div>
        </div>
        
        <div class="col-md-12">
          <div>
          <label  class="control-label-new font-weight-bold mb-2">Assessment</label>
        </div>
          <label  class="treat-label font-weight-bold">Axis I</label>
          <div  class="form-group">
            <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
          </div>
        </div>
        <div class="col-md-12">
          <label  class="treat-label font-weight-bold">Axis II</label>
          <div  class="form-group">
            <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
          </div>
        </div>
        <div class="col-md-12">
          <label  class="treat-label font-weight-bold">Axis III</label>
          <div  class="form-group">
            <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
          </div>
        </div>
        <div class="col-md-12">
          <label  class="treat-label font-weight-bold">Axis IV</label>
          <div  class="form-group">
            <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
          </div>
        </div>
        <div class="col-md-12">
          <label  class="treat-label font-weight-bold">Axis V</label>
          <div  class="form-group">
            <div class="row">
              <div class="col-md-6">
                <textarea  name="complaint" rows="3" class="form-control " placeholder="Current" ng-reflect-name="txt_clinical"></textarea>
         
              </div>
              <div class="col-md-6">
                <textarea  name="complaint" rows="3" class="form-control " placeholder="Last Year" ng-reflect-name="txt_clinical"></textarea>
         
              </div>
            </div>
             </div>
        </div>

        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Perceptual Disturbances</label>
          <div  class="form-group">
            <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
          </div>
        </div>
        <div class="col-md-12">
          <label  class="control-label-new font-weight-bold">Plan/Recommendation	</label>
          <div  class="form-group">
            <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
          </div>
        </div>

          </div>
        </div>
        </div>
        </div>  
    

  </div>
</div>
<div class="Activity-popup">
  <div class="modal"  tabindex="-1" role="dialog" style="display: none;" >
     <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h6 class="modal-title">Preview</h6>
              <button type="button" class="close"  data-dismiss="modal"
              aria-label="Close" >
              <span aria-hidden="true">&times;</span>
           </button>
           </div>
           <div class="modal-body">
            <div class="individual-threaphy-container">
            <div  class="history-list col-md-12 p-0">
              <div class="row top-details-psych">
                <div class="col-md-3"><label>Client Name</label>
                <p>John</p></div>
                <div class="col-md-3"><label>Psychiatrist Name</label>
                  <p>Ezra</p></div>
                  <div class="col-md-3"><label> Date of Evaluation</label>
                    <p>10/02/20</p></div>
                    <div class="col-md-3"><label>Date of Birth</label>
                      <p>11/03/90</p></div>
               </div>
              <div class="row" >
              <div class="col-md-12 mb-2  ">
                 <label class="control-label-new font-weight-bold" >Assessment</label>
             <div class="cli_value_family">
              <span>Stable,</span><span>Improvement,</span><span>Decline</span>
            </div>
          </div>
        </div>
          </div>
        </div> 
             </div>
        </div>
      </div>
    </div>
  </div>   -->
  <!-- <div class="col-md-10 mx-auto bg-white mt-2 invoice-details">
    <div class="panel panel-default plain" id="dash_0">
      <div class="panel-body p-3">
        <div class="row">
       
          <div class="col-md-12">
            <div class="invoice-details mt-2">
              <div class="well">
                <ul class="list-unstyled mb-0">
                  <li><strong>Invoice</strong> #936988</li>
                  <li><strong>Invoice Date:</strong> Monday, May 9th, 2022</li>
                  <li><strong>Status:</strong> <span class="label label-success">PAID</span></li>
                </ul>
              </div>
            </div>
            <div class="invoice-to mt-2">
              <ul class="list-unstyled">
                <li><strong>Invoiced To</strong></li>
                <li>Name: <span>Albert D</span>  </li>
                <li>DOB: <span>10/19/2016</span></li>
                <li>Contact : <span>Dennis F</span></li>
                <li>Email : <span>dennis@yopmail.com</span></li>
                <li>Phone : <span>(859) 675-8975</span></li>
              </ul>
            </div>
            <div class="invoice-items">
              <div class="table-responsive" style="overflow: hidden; outline: none;" tabindex="0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="per70 text-center">Description</th>
                      <th class="per5 text-center">Qty</th>
                      <th class="per25 text-center">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1024MB Cloud 2.0 Server - elisium.dynamic.com (12/04/2014 - 01/03/2015)</td>
                      <td class="text-center">1</td>
                      <td class="text-center">$25.00 USD</td>
                    </tr><tr><td>Logo design</td>
                      <td class="text-center">1</td>
                      <td class="text-center">$200.00 USD</td>
                    </tr>
                    <tr>
                      <td>Backup - 1024MB Cloud 2.0 Server - elisium.dynamic.com</td>
                      <td class="text-center">12</td>
                      <td class="text-center">$12.00 USD</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colspan="2" class="text-right">Sub Total:</th>
                      <th class="text-center">$237.00 USD</th>
                    </tr>
                    <tr>
                      <th colspan="2" class="text-right">Tax</th>
                      <th class="text-center">$47.40 USD</th>
                    </tr>
                    <tr>
                      <th colspan="2" class="text-right">Total:</th>
                      <th class="text-center">$284.4.40 USD</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div class="invoice-footer mt-2">
              <p class="text-center">Generated on Sunday, May 08th, 2022 <a mat-stroked-button href="#" target="_blank"><i class="fa fa-print mr5"></i> Print</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
 <!-- <div class="psycharist-progress">
  <div class="col-md-12">
    <div class="d-flex met-info-top w-100">
      <i  class="fas fa-user"></i>
      <div  class="mr-4">Client Name <span  class="imp-info">John Carter </span></div>
      <div  class="mr-4"> Start Date <span  class="imp-info">05/03/2022</span></div>
      <div  class="mr-4"> Start Time <span  class="imp-info">06:00 PM</span></div>
      
      <div  class="ml-auto">
        <button  type="button" mat-stroked-button class="mat-focus-indicator bg-white top-back mt-1 mb-2 h-40 mr-2 mat-stroked-button mat-button-base">Back</button>
      </div>
      </div>
  </div>
  
  <div class="col-md-12  mt-3">
    <div class="lead-box pt-2 pb-2">
    <div class="tab-history custom-tab-border mt-2">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item mr-1" role="presentation">
          <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#progress" type="button" role="tab" aria-controls="s1" aria-selected="true" (click)="OnTabchange(1)"  [ngClass]="{'active show': SelectedTab == 1}" >Progress Notes</button>
        </li>
        <li class="nav-item mr-1" role="presentation">
          <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#goals" type="button" role="tab" aria-controls="s3" aria-selected="true" (click)="OnTabchange(2)"  [ngClass]="{'active show': SelectedTab == 2}" >Goals</button>
        </li>
        
        
      </ul>
      
      <div class="tab-content">
        <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==1">
         <div class="col-md-12 mt-3">
          <div  class="row">
            <div  class="d-flex w-100">
              <div  class="box-lead-head mb-2 ml-3">Conversation Communication Form </div>
              <div  class="ml-auto mr-2">
                <button  type="submit" class="btn primary-btn-outlined-small mr-2 float-left">
                  <i class="far fa-eye mr-2"></i>Preview</button>
                  <button  form="frmindfamtheraphy" type="submit" class="btn primary-btn-outlined-small ng-star-inserted">
                    <i  class="far fa-save mr-2"></i>Save</button>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-4 font-weight-bold "><span class="control-label-new">Client-partner Name</span></label> 
                <div  class=" col-md-3 form-group">
                 <input type="text" class="form-control" />
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-4 font-weight-bold "><span class="control-label-new">Staff Name</span></label> 
                <div  class=" col-md-3 form-group">
                 <input type="text" class="form-control" />
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-4 font-weight-bold "><span class="control-label-new">Form of Communication</span></label> 
                <div  class=" col-md-3 form-group">
                 <input type="text" class="form-control" />
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                <label  class="col-md-4 font-weight-bold "><span class="control-label-new">With whom did the communication take place?</span></label> 
                <div  class=" col-md-3 form-group">
                 <input type="text" class="form-control" />
                </div>
              </div>
              </div>
              <div class="col-md-12">
                <label  class="control-label-new font-weight-bold ">Content (include items discussed, reaction, and pertinent information) </label> 
                <div  class="form-group">
                  <textarea  name="complaint" rows="3" class="form-control " ng-reflect-name="txt_clinical"></textarea>
                </div>
              </div>
              <div  class="col-md-12">
                <div  class="form-group clearfix m-0">
                  <div  class="float-left col-md-4 p-0 mt-3 pt-1">
                    <label  class="treat-label mr-2">Staff Signature</label>
                    <img  id="esign" height="50px" width="300px" src="">
                    <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"> Add E-Sign </button>
                  </div>
                  <div  class="float-right col-md-2 p-0 ">
                    <mat-form-field class="w-100">
                      <mat-label>Date</mat-label>
                      <input matInput [matDatepicker]="picker">
                      
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
             </div>
             <div class="tab-pane active show" id="home" role="tabpanel" aria-labelledby="home-tab" *ngIf=" SelectedTab ==2"></div>
            </div>  
            </div>

   </div>
    

  </div>
  </div>  -->
<!-- <div class="progress-log">
  <div  class="col-md-12 d-flex">
    <h4  class="head-section mt-0">Progress Log</h4>
  </div>
  <div class="col-md-12 bg-white p-2">
    <div class="col-md-12 list-container-log">
      <div class="row">
          <div class="col-md-12">
            <div class="row mb-1">
              <div class="col-md-8 ">
                <h6 class="mb-1">Timothy Husai </h6>
                <div class="text-xs text-muted">12 Years</div>
                </div>
                <div class="col-md-4 text-right">
                  <p class="text-muted mb-0 text-xs">16 May 2022 17:19</p>
                  
                 </div>
                </div>
                </div>
                <div class="col-md-12 tab-custom-logs">
                <mat-tab-group>
                  <mat-tab label="Individual Therapy"> 
                    <div class="looping-section w-100 mb-2">
                      <div class="col-md-12">
                        <div class="row mb-1">
                        
                        <div class="col-md-3">
                          <p class="text-muted mb-0 text-xs"><span class="list-label ">Therapy : </span> <span class="list-val">Individual Therapy  </span> </p>
                          
                        </div>
                        <div class="col-md-3">
                         <p class="text-muted mb-0 text-xs"> <span class="list-label ">Frequency : </span> <span class="list-val ">3 weeks</span></p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 custom-panel">
                      <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div class="col-md-12 ">
                            <div class="row">
                              <div class="col-md-5">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal Name : </span> <span class="list-val ">Stress Management</span></p>
                            
                              </div>
                              <div class="col-md-4">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal assigned date & time : </span> <span class="list-val ">16 May 2022 17:19</span></p>
                             
                              </div>
                              <div class="col-md-3">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">No of Benchmark : </span> <span class="list-val ">4</span></p>
                            
                              </div>
                            </div>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">1. Be willing to compromise</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>16 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">2. Create a balanced schedule</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>18 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">3. Learn to forgive</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>22 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">4. Share your feelings</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>25 May 2022</div>  </div>
                          </div>
                          </div>
                         
                        </div>
                        
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div class="col-md-12 ">
                            <div class="row">
                              <div class="col-md-5">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal Name : </span> <span class="list-val ">Regular Diet</span></p>
                            
                              </div>
                              <div class="col-md-4">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal assigned date & time : </span> <span class="list-val ">18 May 2022 17:19</span></p>
                             
                              </div>
                              <div class="col-md-3">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">No of Benchmark : </span> <span class="list-val ">3</span></p>
                            
                              </div>
                            </div>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">1. Breakfast</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>16 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">2. Lunch</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>18 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">3. Dinner</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-warning">[Inprogress]</span> <div>22 May 2022</div>  </div>
                          </div>
                          </div>
                         
                         
                        </div>
                        
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                    </div>
                    </div>
                  </mat-tab>
                  <mat-tab label="Family Therapy">
                    <div class="looping-section w-100 mb-2">
                      <div class="col-md-12">
                        <div class="row mb-1">
                        
                        <div class="col-md-3">
                          <p class="text-muted mb-0 text-xs"><span class="list-label "> : </span> <span class="list-val">Family Therapy  </span> </p>
                          
                        </div>
                        <div class="col-md-3">
                         <p class="text-muted mb-0 text-xs"> <span class="list-label ">Frequency : </span> <span class="list-val ">2 weeks</span></p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 custom-panel">
                      <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div class="col-md-12 ">
                            <div class="row">
                              <div class="col-md-5">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal Name : </span> <span class="list-val ">Stress Management</span></p>
                            
                              </div>
                              <div class="col-md-4">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal assigned date & time : </span> <span class="list-val ">16 May 2022 17:19</span></p>
                             
                              </div>
                              <div class="col-md-3">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">No of Benchmark : </span> <span class="list-val ">4</span></p>
                            
                              </div>
                            </div>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">1. Be willing to compromise</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>16 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">2. Create a balanced schedule</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>18 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">3. Learn to forgive</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>22 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">4. Share your feelings</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>25 May 2022</div>  </div>
                          </div>
                          </div>
                         
                        </div>
                        
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div class="col-md-12 ">
                            <div class="row">
                              <div class="col-md-5">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal Name : </span> <span class="list-val ">Regular Diet</span></p>
                            
                              </div>
                              <div class="col-md-4">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal assigned date & time : </span> <span class="list-val ">18 May 2022 17:19</span></p>
                             
                              </div>
                              <div class="col-md-3">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">No of Benchmark : </span> <span class="list-val ">3</span></p>
                            
                              </div>
                            </div>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">1. Breakfast</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>16 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">2. Lunch</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>18 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">3. Dinner</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-warning">[Inprogress]</span> <div>22 May 2022</div>  </div>
                          </div>
                          </div>
                         
                         
                        </div>
                        
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                    </div>
                    </div>
                  </mat-tab>
                  <mat-tab label="Group Theraphy"> 
                    <div class="looping-section w-100 mb-2">
                      <div class="col-md-12">
                        <div class="row mb-1">
                        
                        <div class="col-md-3">
                          <p class="text-muted mb-0 text-xs"><span class="list-label ">Therapy : </span> <span class="list-val">Group Therapy  </span> </p>
                          
                        </div>
                        <div class="col-md-3">
                         <p class="text-muted mb-0 text-xs"> <span class="list-label ">Frequency : </span> <span class="list-val ">3 weeks</span></p>
                          
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 custom-panel">
                      <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div class="col-md-12 ">
                            <div class="row">
                              <div class="col-md-5">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal Name : </span> <span class="list-val ">Stress Management</span></p>
                            
                              </div>
                              <div class="col-md-4">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal assigned date & time : </span> <span class="list-val ">16 May 2022 17:19</span></p>
                             
                              </div>
                              <div class="col-md-3">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">No of Benchmark : </span> <span class="list-val ">4</span></p>
                            
                              </div>
                            </div>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">1. Be willing to compromise</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>16 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">2. Create a balanced schedule</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>18 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">3. Learn to forgive</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>22 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">4. Share your feelings</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>25 May 2022</div>  </div>
                          </div>
                          </div>
                         
                        </div>
                        
                      </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          <div class="col-md-12 ">
                            <div class="row">
                              <div class="col-md-5">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal Name : </span> <span class="list-val ">Regular Diet</span></p>
                            
                              </div>
                              <div class="col-md-4">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">Goal assigned date & time : </span> <span class="list-val ">18 May 2022 17:19</span></p>
                             
                              </div>
                              <div class="col-md-3">
                                <p class="text-muted mb-0 text-xs"> <span class="list-label ">No of Benchmark : </span> <span class="list-val ">3</span></p>
                            
                              </div>
                            </div>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">1. Breakfast</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>16 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">2. Lunch</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-success">[Completed]</span> <div>18 May 2022</div>  </div>
                          </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="text-muted mb-0 text-xs text-truncate col-md-9 "> <span class="bench-head">3. Dinner</span>  </div>
          
                            <div class="text-muted mb-0 text-xs text-truncate col-md-3 text-right "> <span class="text-xs text-warning">[Inprogress]</span> <div>22 May 2022</div>  </div>
                          </div>
                          </div>
                         
                         
                        </div>
                        
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                    </div>
                    </div>
                     </mat-tab>
                  
                </mat-tab-group>
              </div>
                
          
          
          
          
      </div>
  </div>
  </div>
</div>
  -->
  <!--Discharge summary-->
  <!-- <div class="discharge-form">
    <div  class="d-flex">
      <div  class="head-section mt-2 mb-2">
        DISCHARGE SUMMARY
      </div>
      <div  class="ml-auto">
        <button  tabindex="0" class="btn back-btn-new mr-2">
          <i  class="fas fa-chevron-left mr-2"></i>Back</button>
        </div>
      </div>
      <div class="col-md-12 bg-white p-2">
        <form>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <mat-form-field class="w-100">
                  <mat-label>Client’s Name</mat-label>
                  <input matInput>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field class="w-100">
                  <mat-label>DOB</mat-label>
                  <input matInput [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field class="w-100" >
                  <mat-label>Clinician’s Name</mat-label>
                  <input matInput>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field class="w-100">
                  <mat-label>Date</mat-label>
                  <input matInput [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <mat-radio-group aria-label="Select an option">
              <mat-radio-button value="1" class="mr-2">Discharge</mat-radio-button>
              <mat-radio-button value="2">Dropout</mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="col-md-12 d-none">
            <mat-form-field class="w-100 custom-textarea">
              <mat-label>Enter the details for follow-up</mat-label>
              <textarea matInput></textarea>
            </mat-form-field>
          </div>
          <div class="col-md-12 d-none">
            <mat-form-field class="w-100 custom-textarea">
              <mat-label>Provide a reason for dropout</mat-label>
              <textarea matInput></textarea>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <div class="custom-head mt-3 mb-2"><h2>Diagnosis</h2></div>
            <div  class="basic-info diag-sec mb-3 row">
              <div  class="col-md-4">
                <mat-form-field class="w-100">
                  <mat-label>Chooose the Diagnosis Code for the Patient</mat-label>
                  <mat-select  multiple>
                    <mat-option >F90.2</mat-option>
                    <mat-option >F41.9</mat-option>
                    <mat-option >F84.0</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div  class="col-md-8">
                <div class="chip-container-content ">
                  <mat-chip-list aria-label="Fish selection">
                    <mat-chip>F90.2 - Attention-deficit hyperactivity disorder, combined type </mat-chip>
                 
                    <mat-chip  selected>F41.9-Anxiety disorder, unspecified </mat-chip>
                    <mat-chip  selected>F84.0-Autistic disorder </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
              </div>
          </div>
          <div class="col-md-12">
            <div class="custom-head mt-3 mb-2"><h2>Medication</h2></div>
            <div class="col-md-12 mt-3 mb-3 p-0">
              <div class="medication-dosage">
                <div  class="row inputElement">
                  <div  class="col-md-3">
                    <label >Medications</label>
                  </div>
                  <div  class="col-md-3">
                    <label >Dosage</label>
                  </div>
                  <div  class="col-md-3">
                    <label>Frequency</label>
                  </div>
                  <div  class="col-md-3">
                    <label >Side effects</label>
                  </div>
                </div>
                <div  class="loop-section position-relative mb-2 ">
                  <div class="row inputElement">
                    <div  class="col-md-3">
                      <input  maxlength="50" placeholder="Medicine" type="text" required="" class="form-control "  >
                    </div>
                    <div  class="col-md-3">
                      <input  maxlength="50" placeholder="Dosage" type="text" required="" class="form-control "  >
                    </div>
                    <div  class="col-md-3">
                      <input  maxlength="50" placeholder="Frequency" type="text" required="" class="form-control "  >
                    </div>
                    <div  class="col-md-3">
                      <input  maxlength="50" placeholder="Side effects" type="text" required="" class="form-control "  >
                    </div>
                  </div>
                  <a  class="add " style="cursor: pointer;">
                    <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
                  </a>
                  <a  class="remove" style="cursor: pointer;">
                    <i  class="fa fa-lg fa-minus-circle"></i>
                    <span  class="sr-only">Remove</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <mat-form-field class="w-100 ">
                  <mat-label>Start Date of Services</mat-label>
                  <input matInput [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field class="w-100 ">
                  <mat-label>Termination Date of Services</mat-label>
                  <input matInput [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
           
          </div>
          <div class="col-md-12">
            <div class="custom-head mt-3 mb-3"><h2>Course of Treatment</h2></div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Presenting Problem
              </div>
            
                <textarea class="w-100 form-control mb-3"> ____ presents with the following symptoms and behaviors:</textarea>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Objectives of treatment
              </div>
                <textarea class="w-100 form-control mb-3">
Objectives of treatment are as follows:
  Goal #1.
  Goal #2.
                </textarea>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Significant Findings
              </div>
              <textarea class="w-100 form-control mb-3">
Over the course of treatment counselors observed
              </textarea>
              
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Clinical Condition at Termination
              </div>
              <textarea class="w-100 form-control mb-3">
At termination ____ presented with the following symptoms:
              </textarea>
             
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Interventions in treatment
              </div>
              <textarea class="w-100 form-control mb-3">
_____ attended the IOP program at Stepping Forward Counseling Center # of days per week from _____ to ____ . With this, ____ was placed in a small group of peers (6-18) around her age. ____ was placed on a token economy-based behavior modification program which provided reinforcements when ____ met enough of ____ treatment goals throughout the group. Additional interventions included: implementation of coping skills, movement therapy, relaxation training (deep breathing), social skills training, mindfulness, cognitive behavior therapy, to increase ___ flexibility and increase self-esteem and help support relationship building. Finally, ___ was provided with praise, validation, as well as visual, physical and verbal prompts in order to encourage ____ to work on ___ specific treatment goals.
              </textarea>
          
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Treatment Provided/Response
              </div>
              <textarea class="w-100 form-control mb-3">
The treatment provided for ___ was group therapy through behavior modification. ____ was involved in interpersonal effectiveness, social skills training, distress tolerance and life skills training. With the treatment provided ____ made mild progress toward both treatment goals. Further, ___ mildly improved peer engagement and taking responsibility for ___ actions and utilization of coping skills. ___ continued to present with fragile self-esteem and poor social skills.
              </textarea>
              
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Reason for termination
              </div>
              <textarea class="form-control w-100 mb-3">
Parents enrolled ___ for ___ weeks of IOP. ___ was terminated upon parent request. </textarea>
              
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Discharge Plan
              </div>
            
                <textarea class="form-control w-100 mb-3">
Recommendation for further need for treatment:
  - It is recommended that ____ continue to work on </textarea>
             
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                After-Care Treatment Recommendations:
              </div>
              <textarea class="w-100 form-control mb-3">
- Receive communication skills training focused on engaging with peers effectively (social skills groups)
              </textarea>
            
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Follow-up activities and interventions planned
              </div>
              <textarea class="w-100 form-control mb-3">
                </textarea>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Comments
              </div>
              <textarea class="w-100 form-control mb-3">
                </textarea>
            </div>
            <div class="col-md-12 p-0">
              <div  class="form-group clearfix m-0">
                <div class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label w-100">Clinician’s Signature</label>
                  <img  id="esign_3" height="50px" width="300px">
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"> Add E-Sign </button>
                </div>
                <div  class="float-right col-md-2 p-0">
                  <mat-form-field class="w-100 ">
                    <mat-label>Date </mat-label>
                    <input matInput [matDatepicker]="picker3">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                  </mat-form-field>
                  </div>
                </div>
            </div>
            <div class="col-md-12 p-0">
              <div  class="form-group clearfix m-0">
                <div class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label w-100">Client Signtaure</label>
                  <img  id="esign_3" height="50px" width="300px">
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"> Add E-Sign </button>
                </div>
                <div  class="float-right col-md-2 p-0">
                  <mat-form-field  class="w-100 ">
                    <mat-label> Date</mat-label>
                    <input matInput [matDatepicker]="picker1">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  </div>
                </div>
            </div>
            <div class="col-md-12 p-0">
              <div  class="form-group clearfix m-0">
                <div class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label w-100">Family Member's Signature</label>
                  <img  id="esign_3" height="50px" width="300px">
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"> Add E-Sign </button>
                </div>
                <div  class="float-right col-md-2 p-0">
                  <mat-form-field class="w-100 ">
                    <mat-label>Date </mat-label>
                    <input matInput [matDatepicker]="picker2">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                  </div>
                </div>
            </div>
            <div class="col-md-12">
              <div class="text-right">
              <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button>Save</button>
              <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button>Cancel</button>
            </div>
            </div>
          </div>
        </form>
      </div>
  </div> -->
<!--Treatment summary-->
  <!-- <div class="discharge-form">
    <div  class="d-flex">
      <div  class="head-section mt-2 mb-2">
        Treatment Summary
      </div>
      <div  class="ml-auto">
        <button  tabindex="0" class="btn back-btn-new mr-2">
          <i  class="fas fa-chevron-left mr-2"></i>Back</button>
        </div>
      </div>
      <div class="col-md-12 bg-white p-2">
        <form>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <mat-form-field class="w-100">
                  <mat-label>Client’s Name</mat-label>
                  <input matInput>
                </mat-form-field>
              </div>
              
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-2 mt-3">
                <div class="treat-label"> Dates Attended Program</div>
              </div>
              <div class="col-md-2">
                <mat-form-field class="w-100">
                  <mat-label>From</mat-label>
                  <input matInput [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              
              <div class="col-md-2">
                <mat-form-field class="w-100">
                  <mat-label>To</mat-label>
                  <input matInput [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        
          <div class="col-md-12">
            <div class="custom-head mt-3 mb-2"><h2>Diagnosis</h2></div>
            <div  class="basic-info diag-sec mb-3 row">
              <div  class="col-md-4">
                <mat-form-field class="w-100">
                  <mat-label>Chooose the Diagnosis Code for the Patient</mat-label>
                  <mat-select  multiple>
                    <mat-option >F90.2</mat-option>
                    <mat-option >F41.9</mat-option>
                    <mat-option >F84.0</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div  class="col-md-8">
                <div class="chip-container-content ">
                  <mat-chip-list aria-label="Fish selection">
                    <mat-chip>F90.2 - Attention-deficit hyperactivity disorder, combined type </mat-chip>
                 
                    <mat-chip  selected>F41.9-Anxiety disorder, unspecified </mat-chip>
                    <mat-chip  selected>F84.0-Autistic disorder </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
              </div>
          </div>
          
          
          <div class="col-md-12">
            <div class="custom-head mt-3 mb-2"><h2>Course of Treatment</h2></div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Presenting Problem
              </div>
              <textarea class="w-100 form-control mb-3">
____ presents with the following symptoms and behaviors: ____.
                                </textarea>
              
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Interventions/Treatment Provided
              </div>
              <textarea class="w-100 form-control mb-3">
 ____ attended the IOP program at Stepping Forward Counseling Center # of days per week from ____  to ___. With this, ____ was placed in a small group of peers (6-18) around ___ age. ___ was placed on a token economy-based behavior modification program which provided reinforcements when ___ met enough of ___ treatment goals throughout the group. Additional interventions included: implementation of coping skills, movement therapy, relaxation training (deep breathing), social skills training, mindfulness, cognitive behavior therapy, to increase __ flexibility and increase self-esteem and help support relationship building. Finally, ___ was provided with praise, validation, as well as visual, physical and verbal prompts in order to encourage ___ to work on ___ specific treatment goals.
                                                </textarea>
              
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Treatment Goals
              </div>
              <textarea class="w-100 form-control mb-3">
The treatment goals were as follows: 
  Goal #1 .
  Goal #2.
             </textarea>
             
            </div>
            <div class="col-md-12 p-0">
              <div  class="custom-head mt-3 mb-3">
                <h2 > Strengths, Observations, and Response to Treatment</h2>
              </div>
              <div class="treat-label">
                Strengths
              </div>
              <textarea class="w-100 form-control mb-3">
To begin, throughout Stepping Forward Counseling Center’s Summer Program ____ demonstrated several strengths. 
               </textarea>
              
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Observations
              </div>
              <textarea class="w-100 form-control mb-3">
 ___ was observed displaying a variety of __ target behaviors. Over the course of treatment counselors observed
               </textarea>
           
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Response to Treatment
              </div>
              <textarea class="w-100 form-control mb-3">
With the treatment provided ___ made mild progress toward both treatment goals. Further, ___ mildly improved peer engagement and taking responsibility for ___ actions and utilization of coping skills. ___ continued to present with fragile self-esteem and poor social skills.
              </textarea>
             
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Recommendations
              </div>
              <textarea class="w-100 form-control mb-3">
Though ___ demonstrated improvement, the following are continued treatment recommendations: 
  -Articulating wants and needs verbally
  -Interact in cooperative play with peers
  -Identifying thoughts and feelings and ineffective behaviors
  -Peer engagement and social skills
To help ___ achieve these goals it is recommended:
  -Receive social and communication skills training.
                              </textarea>
             
            </div>
            
            <div class="col-md-12">
              <div  class="form-group clearfix m-0">
                <div class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label">Clinician’s Signature</label>
                  <img  id="esign_3" height="50px" width="300px">
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"> Add E-Sign </button>
                </div>
                <div  class="float-right col-md-2 p-0">
                  <mat-form-field class="w-100 ">
                    <mat-label>Date </mat-label>
                    <input matInput [matDatepicker]="picker3">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                  </mat-form-field>
                  </div>
                </div>
            </div>
            
            
            <div class="col-md-12">
              <div class="text-right">
              <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button>Save</button>
              <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button>Cancel</button>
            </div>
            </div>
          </div>
        </form>
      </div>
  </div> -->


  <!-- <div class="discharge-list">
    <div  class="d-flex">
      <div  class="head-section mt-3 mb-2">DISCHARGE SUMMARY</div>
      <div  class="ml-auto d-flex">
        
        <div  class="ml-2 mt-1">
          <button  mat-stroked-button class=" btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2 "><i class="fas fa-notes-medical mr-2"></i>Start Discharge</button>
        </div>
      </div>
    </div>
    <div class="col-md-12 bg-white p-2">
      <div  class="d-flex">
        <div class="col-md-3 pl-0">
          <div  class="search"><input  type="text" placeholder="Search By Client Name" name="" class="search-input " >
            <a  class="search-icon"><i  class="fa fa-search"></i></a>
          </div>
        </div>
        <div class="ml-auto mt-2">
          <span class="filter-icon mr-2">
            <i  class="fa fa-filter"></i>
          </span>
          <div class="filter-group d-inline-block">
            <label  class="d-inline-block mr-2">Clinician name</label>
            <select  name="Meeting" class="form-control d-inline-block status-filter " >
              <option >--Select --</option>
              <option>mani prabhu</option></select>
            </div>
            <div class="filter-group d-inline-block">
              <label  class="d-inline-block mr-2">Type of Discharge</label>
              <select  name="Meeting" class="form-control d-inline-block status-filter " >
                <option >--Select --</option>
                <option>mani prabhu</option></select>
              </div>
            <div class="filter-group d-inline-block">
              <label  class="d-inline-block mr-2">Discharge Date</label>
              <input type="date" class="form-control d-inline-block status-filter">
              </div>
            </div>
          </div>
          <div class="col-md-12 p-0">
          <div class="table-custom discharge-table">
            <div class="table-responsive">
            <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8 w-100">

            
              <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef> No. </th>
                <td mat-cell *matCellDef="let element"> {{element.position}} </td>
              </ng-container>
            
       
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Client Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
            
              
            
              <ng-container matColumnDef="Clinician">
                <th mat-header-cell *matHeaderCellDef> Clinician Name</th>
                <td mat-cell *matCellDef="let element"> {{element.Clinician}} </td>
              </ng-container>
              <ng-container matColumnDef="dischar">
                <th mat-header-cell *matHeaderCellDef> Type of Discharge</th>
                <td mat-cell *matCellDef="let element">  </td>
              </ng-container>
              <ng-container matColumnDef="DOB">
                <th mat-header-cell *matHeaderCellDef>Treatment Summary</th>
                <td mat-cell *matCellDef="let element"> <span class="text-success ml-4"><i class="fas fa-check-circle"></i></span></td>
              </ng-container>
              <ng-container matColumnDef="Discharge">
                <th mat-header-cell *matHeaderCellDef> Discharge Date </th>
                <td mat-cell *matCellDef="let element"> {{element.Discharge}} </td>
              </ng-container>
            
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions </th>
                <td mat-cell *matCellDef="let element"> <a class="discharge-btn" href=""><i  class="far fa-eye"></i></a> </td>
              </ng-container>
            
              <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
            </table>
            </div>
          </div>
        </div>
    </div>
  </div> -->
  <!-- <div class="discharge-lst create-patients">
    <div class="d-flex w-100 ">
      <div class="col-md-4 ">
          <div  class="search">
              <input  type="text" placeholder="Search By Patient Name" name="" class="search-input " ><a  class="search-icon"><i  class="fa fa-search"></i></a></div>
      </div>
      <div class="col-md-8 text-right">
            </div>
  </div>
  <div  class="list-group contact-group mt--14">
    <a  class="list-group-item ">
      <div  class="col-md-12 p-0">
        <div  class="row">
          <div  class="col-md-4">
            <div  class="media">
              <div  class="pull-left">
                <img  src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" alt="..." class="rounded-circle">
              </div>
              <div  class="media-body">
                <h4  class="media-heading">Juvin </h4>
                <div  class="media-content">
                  <ul class="list-unstyled">
                    <li ><i  class="fas fa-mobile"></i> (657) 845-9834</li>
                    <li ><i  class="fas fa-envelope"></i> grace@yopmail.com</li>
                    <li  class="mr-2 ml-auto d-flex"><span  class="status-patient">Status</span>
                      <span  class="status-patient-value">Discharged</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div  class="col-md-4">
            <div ><label  class="program-heading">Program Status</label>
              <div  class="">
                <div  class="patient-forms">
                  <a  title="Health Screening">
                    <img  src="../../../assets/images/health_screen.png" class="img-fluid">
                  </a>
                  <a  title="Consent Form">
                    <img  src="../../../assets/images/consent-new.png" class="img-fluid">
                  </a>
                    <a  title="Checklist">
                      <img  src="../../../assets/images/health_insurance.png" class="img-fluid">
                    </a>
                      <a  title="Survey">
                        <img  src="../../../assets/images/survey.png" class="img-fluid">
                      </a>
                    </div>
                    <div class="w-100 float-left">
                      <div  class="status-indicator">
                        <ul  class="progress-indicator pl-2">
                          <li  >
                            <span title="Health Screening" class="bubble"></span>
                          </li>
                          <li >
                            <span  title="Consent Form" class="bubble"></span>
                          </li>
                          <li >
                            <span title="Checklist" class="bubble"></span>
                          </li>
                          <li >
                            <span  title="Survey" class="bubble"></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p   class="status-msg mb-1 w-100 ">
                     </p>
                    </div>
                  </div>
                  <div  class="col-md-4">
                    <div  class="icons-section">
                      <button  class="btn btn-patient">
                        <i  class="fas fa-briefcase mr-2"></i>Briefcase View</button>
                        <button  class="btn btn-patient">
                          <i  class="fas fa-user-clock mr-2"></i> Activity</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a  class="list-group-item ">
                    <div  class="col-md-12 p-0">
                      <div  class="row">
                        <div  class="col-md-4">
                          <div  class="media">
                            <div  class="pull-left">
                              <img  src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" alt="..." class="rounded-circle">
                            </div>
                            <div  class="media-body">
                              <h4  class="media-heading"> Annisten </h4>
                              <div  class="media-content">
                                <ul class="list-unstyled">
                                  <li ><i  class="fas fa-mobile"></i> (542) 787-3824</li>
                                  <li ><i  class="fas fa-envelope"></i> jeffereymax@yopmail.com</li>
                                  <li  class="mr-2 ml-auto d-flex"><span  class="status-patient">Status</span>
                                    <span  class="status-patient-value">Discharged</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div  class="col-md-4">
                          <div ><label  class="program-heading">Program Status</label>
                            <div  class="">
                              <div  class="patient-forms">
                                <a  title="Health Screening">
                                  <img  src="../../../assets/images/health_screen.png" class="img-fluid">
                                </a>
                                <a  title="Consent Form">
                                  <img  src="../../../assets/images/consent-new.png" class="img-fluid">
                                </a>
                                  <a  title="Checklist">
                                    <img  src="../../../assets/images/health_insurance.png" class="img-fluid">
                                  </a>
                                    <a  title="Survey">
                                      <img  src="../../../assets/images/survey.png" class="img-fluid">
                                    </a>
                                  </div>
                                  <div class="w-100 float-left">
                                    <div  class="status-indicator">
                                      <ul  class="progress-indicator pl-2">
                                        <li  >
                                          <span title="Health Screening" class="bubble"></span>
                                        </li>
                                        <li >
                                          <span  title="Consent Form" class="bubble"></span>
                                        </li>
                                        <li >
                                          <span title="Checklist" class="bubble"></span>
                                        </li>
                                        <li >
                                          <span  title="Survey" class="bubble"></span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <p   class="status-msg mb-1 w-100 ">
                                   </p>
                                  </div>
                                </div>
                                <div  class="col-md-4">
                                  <div  class="icons-section">
                                    <button  class="btn btn-patient">
                                      <i  class="fas fa-briefcase mr-2"></i>Briefcase View</button>
                                      <button  class="btn btn-patient">
                                        <i  class="fas fa-user-clock mr-2"></i> Activity</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
  </div>
  </div> -->
  <!-- <div class="theraphy-details">
    <div  class="col-md-12">
      <div class="d-flex  w-100">
        <div></div>
        <div class="ml-auto">
          <button class="bg-white top-back mt-1 mb-2 h-40 mr-2" mat-stroked-button>Back</button>
        </div>
      </div>
     
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-8">
          <div class=" bg-white p-2 mb-3">
            <div class="custom-head mt-1 mb-3"><h2>Patient Info</h2></div>
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                    Patient Name
                  </label>
                  <div class="col-md-12"><span class="details-value">Peter C</span></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                   Age
                  </label>
                  <div class="col-md-12"><span class="details-value">12 Yrs</span></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                   Email
                  </label>
                  <div class="col-md-12"><span class="details-value">Cruzz@yopmail.com</span></div>
                </div>
              </div>
              <div class="col-md-4 mt-2">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                   Phone Number
                  </label>
                  <div class="col-md-12"><span class="details-value">+1(547)832-5436</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class=" bg-white p-2 mb-2">
            <div class="custom-head mt-1 mb-3"><h2>Clinician Info</h2></div>
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                    Clinician Name
                  </label>
                  <div class="col-md-12"><span class="details-value">Peter C</span></div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                   Email
                  </label>
                  <div class="col-md-12"><span class="details-value">Cruzz@yopmail.com</span></div>
                </div>
              </div>
              <div class="col-md-4 mt-2">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                   Phone Number
                  </label>
                  <div class="col-md-12"><span class="details-value">+1(547)832-5436</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class=" bg-white p-2 mb-2">
            <div class="custom-head mt-1 mb-3"><h2>schedule Details</h2></div>
            <div class="row">
              <div class="col-md-6 mt-1">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                    Theme Name
                  </label>
                  <div class="col-md-12"><span class="details-value">Grazia K</span></div>
                </div>
              </div>
              <div class="col-md-6 mt-1">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                   Location
                  </label>
                  <div class="col-md-12"><span class="details-value">San Antonio</span></div>
                </div>
              </div>
              <div class="col-md-6 mt-3">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                   Therapy
                  </label>
                  <div class="col-md-12"><span class="details-value">Individual Therapy</span></div>
                </div>
              </div>
              <div class="col-md-6 mt-3">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                    Schedule Date
                  </label>
                  <div class="col-md-12"><span class="details-value">05/04/2022</span></div>
                </div>
              </div>
              <div class="col-md-6 mt-3">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                    Start Time
                  </label>
                  <div class="col-md-12"><span class="details-value">12:00 PM</span></div>
                </div>
              </div>
              <div class="col-md-6 mt-3">
                <div class="row">
                  <label class="control-label-new col-md-12 font-weight-bold pl-3 ">
                    Duration
                  </label>
                  <div class="col-md-12"><span class="details-value">1hr 60 mins</span></div>
                </div>
              </div>
              <div class="col-md-12 text-center mt-4 mb-3">
                <button  mat-stroked-button class="btn-delete btn-danger"><i  class="far fa-trash-alt mr-2"></i>Delete</button>
              </div>
            </div>
          </div>
        </div>
         
        </div>
      </div>
      
      
  </div> -->
<!-- <div class="suicide-risk">
  <div  class="top-btn-section clearfix mb-2">
    <h2  class="head-section mb-2 mt-2 ">Suicidal Risk Assessment </h2>
    <div  class="col-md-4 ml-auto pr-0">
      <button  class="btn back-btn-new"><i  class="fas fa-chevron-left mr-2"></i>Back</button>
      <button  class="btn back-btn-new mr-2"><i  class="far fa-file-pdf mr-2"></i>Generate PDF</button>
      <span  class="float-right"></span>
    </div>
  </div>
  <div class="risks-tabs">
    <mat-tab-group>
      <mat-tab label="Suicide Assessment">
        <div class="bg-white p-3 col-md-12">
          <div class="s1 mb-4">
          <div class="custom-head mt-3 mb-3"><h2>Step 1: Identify Risk Factors </h2></div>
          
          <div class="safe-head col-md-12 ">
            <div class="row">
                <div class="col-md-8 pt-2">
                 <div class="safe-t-label">C-SSCS Suicidal Ideation Severity</div> 
                </div>
                <div class="col-md-2 text-center">
                  <div class="safe-t-label pl-0">Month</div> 
                </div>
                <div class="col-md-2 text-center">
                  <div class="safe-t-label pl-0">Lifetime(Worst)</div> 
                </div>
            </div>
        </div>
          <div class="safe-t col-md-12 safe-border ">
              <div class="row">
                  <div class="col-md-8 ">
                    <div class="safe-t-label-bold">1) Wish to be dead</div>
                   <div class="safe-t-label text-muted">Have you wished you were dead or wished you could go to sleep and not wake up?</div> 
                  </div>
                  <div class="col-md-2">
                    <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
                  </div>
                  <div class="col-md-2">
                    <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
                  </div>
              </div>
          </div>
          <div class="safe-t col-md-12 safe-border">
            <div class="row">
                <div class="col-md-8 ">
                  <div class="safe-t-label-bold">2) Current suicidal thoughts</div>
                 <div class="safe-t-label text-muted">Have you actually had any thoughts of killing yourself?</div> 
                </div>
                <div class="col-md-2">
                  <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
                </div>
                <div class="col-md-2">
                  <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
                </div>
            </div>
        </div>
        <div class="safe-t col-md-12 safe-border">
          <div class="row">
              <div class="col-md-8 ">
                <div class="safe-t-label-bold">3) Suicidal thoughts w/ Method (w/no specific Plan or Intent or act)</div>
               <div class="safe-t-label text-muted">Have you been thinking about how you might kill yourself?</div> 
              </div>
              <div class="col-md-2">
                <div class="moderate-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
              </div>
              <div class="col-md-2">
                <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
              </div>
          </div>
      </div>
      <div class="safe-t col-md-12 safe-border">
        <div class="row">
            <div class="col-md-8 ">
              <div class="safe-t-label-bold">4) Suicidal Intent without Specific Plan</div>
             <div class="safe-t-label text-muted">Have you had these thoughts and had some intention of acting on them?</div> 
            </div>
            <div class="col-md-2">
              <div class="higher-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
            </div>
            <div class="col-md-2">
              <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
            </div>
        </div>
    </div>
    <div class="safe-t col-md-12 safe-border ">
      <div class="row">
          <div class="col-md-8 ">
            <div class="safe-t-label-bold">5) Intent with Plan</div>
           <div class="safe-t-label text-muted">Have you started to work out or worked out the details of how to kill yourself? Did you intend to carry out this plan?</div> 
          </div>
          <div class="col-md-2">
            <div class="higher-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
          </div>
          <div class="col-md-2">
            <div class="lower-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
          </div>
      </div>
  </div>
  <div class="safe-head col-md-12 ">
    <div class="row">
        <div class="col-md-8 pt-2">
         <div class="safe-t-label">C-SSRS Suicidal Behavior</div> 
        </div>
        <div class="col-md-2 text-center">
          <div class="safe-t-label pl-0">3 Months</div> 
        </div>
        <div class="col-md-2 text-center">
          <div class="safe-t-label pl-0">Lifetime(Worst)</div> 
        </div>
    </div>
</div>
          <div class="safe-t col-md-12 p-2">
              <div class="row">
                  <div class="col-md-8 ">
                   <div class="safe-t-label p-0">Have you ever done anything, started to do anything, or prepared to do anything to end your life?</div> 
                   <div class="col-md-12 p-0 mt-2">
                    <div class="Theme-textarea">
                     
                      <textarea cols="4" class="form-control" placeholder="Examples: Collected pills, obtained a gun, gave away valuables, wrote a will or suicide note, took out pills but didn’t swallow any, held a gun but changed your mind or it was grabbed from your hand, went to the roof but didn’t jump; or actually took pills, tried to shoot yourself, cut yourself, tried to hang yourself, etc."></textarea>
                    </div>
                  </div>
                  </div>
                  <div class="col-md-2">
                    <div class="higher-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
                  </div>
                  <div class="col-md-2">
                    <div class="moderate-risk-box larger-checkbox-center"><label class=" font-15 mt-2"><input type="checkbox" > </label></div>
                  </div>
              </div>
          </div>
          <div class="col-md-12 mt-4">

            <div class="safe-t-label p-0 mb-3 font-weight-bold text-dark col-md-12">Activating Events</div>
            <div class="col-md-12 p-0 ">
              <div class="row">
             <div class="col-md-6"> <label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Recent losses or other significant negative event(s) (legal, financial, relationship, etc.)</label></div>
             <div class="col-md-6"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Pending incarceration or homelessness </label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Current or pending isolation or feeling alone </label></div> 
              <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Others</label>
                <div class="Theme-textarea  d-none">
                     
                  <textarea cols="4" class="form-control" ></textarea>
                </div>
              </div>
              
            </div>
              </div>
          </div>
          <div class="col-md-12 mt-3 ">

            <div class="safe-t-label p-0 mb-3 font-weight-bold text-dark col-md-12">Treatment History</div>
            <div class="col-md-12 p-0 ">
              <div class="row">
             <div class="col-md-6"> <label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" > Previous psychiatric diagnosis and treatments</label></div>
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" >Hopeless or dissatisfied with treatment </label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Non-compliant with treatment </label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Not receiving treatment </label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Insomnia </label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Others</label>
              <div class="Theme-textarea  d-none">
                   
                <textarea cols="4" class="form-control" ></textarea>
              </div>
            </div>
          </div>
              </div>
          </div>
          <div class="col-md-12 mt-3">

            <div class="safe-t-label p-0 mb-3 font-weight-bold text-dark col-md-12">Clinical Status</div>
            <div class="col-md-12 p-0 ">
              <div class="row">
             <div class="col-md-6"> <label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox">Hopelessness</label></div>
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" >Major depressive episode</label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Mixed affect episode (e.g. Bipolar)</label></div>
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Command Hallucinations to hurt self</label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Chronic physical pain or other acute medical problem (e.g. CNS disorders)</label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Highly impulsive behavior </label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Substance abuse or dependence </label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Agitation or severe anxiety </label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Perceived burden on family or others</label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Homicidal Ideation</label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Aggressive behavior towards others</label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox"  >Refuses or feels unable to agree to safety plan</label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Sexual abuse (lifetime)</label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox"  >Family history of suicide</label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Others</label>
              <div class="Theme-textarea  d-none">
                   
                <textarea cols="4" class="form-control" ></textarea>
              </div>
            </div>
            </div>
              </div>
          </div>
          <div class="col-md-12 check-custom check-fields-large-label text-dark mt-4">
            <mat-checkbox class="mr-2">Access to lethal methods: Ask specifically about presence or absence of a firearm in the home or workplace or ease of accessing</mat-checkbox>
            
          </div>
        </div>
        <div class="s2 mb-4">
          <div class="custom-head mt-3 mb-3"><h2>Step 2: Identify Protective Factors (Protective factors may not counteract significant acute suicide risk factors)</h2></div>
          <div class="col-md-12 mt-3 ">

            <div class="safe-t-label p-0 mb-3 font-weight-bold text-dark col-md-12">Internal</div>
            <div class="col-md-12 p-0 ">
              <div class="row">
             <div class="col-md-6"> <label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox">Fear of death or dying due to pain and suffering</label></div>
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" >Identifies reasons for living</label></div> 
             
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Others</label>
              <div class="Theme-textarea  d-none">
                   
                <textarea cols="4" class="form-control" ></textarea>
              </div>
            </div>
          </div>
              </div>
          </div>
          <div class="col-md-12 mt-3">

            <div class="safe-t-label p-0 mb-3 font-weight-bold text-dark col-md-12">External</div>
            <div class="col-md-12 p-0 ">
              <div class="row">
             <div class="col-md-6"> <label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox">Belief that suicide is immoral; high spirituality</label></div>
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" >Responsibility to family or others; living with family</label></div> 
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Supportive social network of family or friends</label></div>
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox"  >Engaged in work or school</label></div> 
            
             <div class="col-md-6"><label  class="mr-2 font-15 "><input class="mr-2 "  type="checkbox" required="" >Others</label>
              <div class="Theme-textarea  d-none">
                   
                <textarea cols="4" class="form-control" ></textarea>
              </div>
            </div>
            </div>
              </div>
          </div>
        </div>
        <div class="s3 mb-4">
          <div class="custom-head mt-3 mb-3"><h2>Step 3: Specific questioning about Thoughts, Plans, and Suicidal Intent – (see Step 1 for Ideation Severity and Behavior)</h2></div>
          
          <div class="safe-head col-md-12 ">
            <div class="row">
                <div class="col-md-8 pt-2">
                 <div class="safe-t-label">C-SSRS Suicidal Ideation Intensity (with respect to the most severe ideation identified above)</div> 
                </div>
                <div class="col-md-2 text-center">
                  <div class="safe-t-label pl-0">Month</div> 
                </div>
                <div class="col-md-2 text-center">
                  <div class="safe-t-label pl-0">Lifetime(Worst)</div> 
                </div>
            </div>
        </div>
          <div class="safe-t col-md-12 ">
              <div class="row">
                  <div class="col-md-8 pt-2">
                    <div class="safe-t-label-bold">Frequency</div>
                   <div class="safe-t-label text-muted p-0">How many times have you had these thoughts? </div>
                   <div class="d-flex flex-wrap mt-3">
                    
                      <div class="mr-2 font-15"><a href="#" class="number-selct mr-1">1</a>
                        Less than once a week
                      </div>
                      <div class="mr-2 font-15"><a href="#" class="number-selct mr-1">2</a>
                        Once a week
                        </div>
                        
                        <div class="mr-2 font-15"><a href="#" class="number-selct mr-1">3</a>
                          2-5 times in week
                          </div>
                          <div class="mr-2 font-15"><a href="#" class="number-selct mr-1">4</a>
                       Daily or almost daily
                       </div>
                       <div class="mr-2 font-15"><a href="#" class="number-selct mr-1">5</a>
                      Many times each day
                      </div>
                   
                  </div>
                  </div>
                  <div class="col-md-2 text-center d-flex align-items-center border">
                    <div class="value-number-selct w-100">
                     <span>1</span>
                    </div>
                   </div>
                  <div class="col-md-2 text-center d-flex align-items-center border">
                  
                  </div>
              </div>
          </div>
          <div class="safe-t col-md-12 odd-bg pt-0 pb-0">
            <div class="row">
                <div class="col-md-8 pt-2">
                  <div class="safe-t-label-bold">Duration</div>
                 <div class="safe-t-label text-muted p-0">When you have the thoughts how long do they last?</div>
                 <div class="col-md-12 mt-3 p-0">
                  <div class="row">
                  <div class="col-md-6 font-15"><a href="#" class="number-selct mr-1">1</a>
                    Fleeting - few seconds or minutes
                  </div>
                  <div class="col-md-6 font-15"><a href="#" class="number-selct mr-1">2</a>
                    Less than 1 hour/some of the time
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-md-6 font-15"><a href="#" class="number-selct mr-1">3</a>
                      1-4 hours/a lot of time
                      </div>
                      <div class="col-md-6 font-15"><a href="#" class="number-selct mr-1">4</a>
                        4-8 hours/most of day
                   </div>
                  </div>
                  <div class="row">
                   <div class="col-md-6 font-15"><a href="#" class="number-selct mr-1">5</a>
                    More than 8 hours/persistent or continuous
                  </div>
                </div>
               
              </div>
                 
                </div>
                <div class="col-md-2 text-center d-flex align-items-center border">
                  <div class="value-number-selct w-100">
                   <span>2</span>
                  </div>
                 </div>
                <div class="col-md-2 text-center d-flex align-items-center border">
                
                </div>
            </div>
        </div>
        <div class="safe-t col-md-12 ">
          <div class="row">
              <div class="col-md-8 pt-2">
                <div class="safe-t-label-bold">Controllability</div>
               <div class="safe-t-label text-muted p-0">Could/can you stop thinking about killing yourself or wanting to die if you want to? </div>
               <div class="col-md-12 mt-3 p-0">
                <div class="row">
                  <div class=" col-md-6 font-15"><a href="#" class="number-selct mr-1">1</a>
                    Easily able to control thoughts
                  </div>
                  <div class=" col-md-6 font-15"><a href="#" class="number-selct mr-1">2</a>
                    Can control thoughts with little difficulty
                    </div>
                </div>
                <div class="row">
                  <div class="  col-md-6 font-15"><a href="#" class="number-selct mr-1">3</a>
                    Can control thoughts with some difficulty
                    </div>
                    <div class=" col-md-6 font-15"><a href="#" class="number-selct mr-1">4</a>
                      Can control thoughts  with a lot of difficulty
                 </div>
                </div>
                  <div class="row">
                  
                 <div class=" col-md-6 font-15"><a href="#" class="number-selct mr-1">5</a>
                  Unable to control thoughts
                </div>
                <div class=" col-md-6 font-15"><a href="#" class="number-selct mr-1">0</a>
                  Does not attempt to control thoughts
                </div>
                </div>
             
            </div>
                
              </div>
              <div class="col-md-2 text-center d-flex align-items-center border">
                <div class="value-number-selct w-100">
                 <span>1</span>
                </div>
               </div>
              <div class="col-md-2 text-center d-flex align-items-center border">
               
              </div>
          </div>
      </div>
      <div class="safe-t col-md-12 odd-bg pt-0 pb-0">
        <div class="row">
            <div class="col-md-8 pt-2">
              <div class="safe-t-label-bold">Deterrents</div>
             <div class="safe-t-label text-muted p-0">Are there things - anyone or anything (e.g., family, religion, pain of death) - that stopped you from wanting to die or acting on thoughts of committing suicide? </div>
            <div class="col-md-12 mt-3 p-0">
              <div class="row">
                <div class=" col-md-6 font-15"><a href="#" class="number-selct mr-1">1</a>
                  Deterrents definitely stopped you from attempting suicide 
                </div>
                <div class=" col-md-6 font-15"><a href="#" class="number-selct mr-1">2</a>
                  Deterrents probably stopped you
                  </div>
              </div>
              <div class="row">
                <div class="  col-md-6 font-15"><a href="#" class="number-selct mr-1">3</a>
                  Uncertain that deterrents stopped you
                  </div>
                  <div class=" col-md-6 font-15"><a href="#" class="number-selct mr-1">4</a>
                    Deterrents most likely did not stop you
               </div>
              </div>
                <div class="row">
                
               <div class=" col-md-6 font-15"><a href="#" class="number-selct mr-1">5</a>
                Deterrents definitely did not stop you
              </div>
              <div class=" col-md-6 font-15"><a href="#" class="number-selct mr-1">0</a>
                Does not apply
              </div>
              </div>
           
          </div>
             
            </div>
            <div class="col-md-2 text-center d-flex align-items-center border">
              <div class="value-number-selct w-100">
               <span>1</span>
              </div>
             </div>
            <div class="col-md-2 text-center d-flex align-items-center border">
             
            </div>
        </div>
    </div>
    <div class="safe-t col-md-12 ">
      <div class="row">
          <div class="col-md-8 pt-2">
            <div class="safe-t-label-bold">Reasons for Ideation</div>
           <div class="safe-t-label text-muted p-0">What sort of reasons did you have for thinking about wanting to die or killing yourself?  Was it to end the pain or stop the way you were feeling (in other words you couldn’t go on living with this pain or how you were feeling) or was it to get attention, revenge or a reaction from others? Or both?</div>
           <div class="col-md-12 mt-3 p-0">
            <div class="row">
              <div class=" col-md-12 font-15"><a href="#" class="number-selct mr-1">1</a>
                Completely to get attention, revenge or a reaction from others 
              </div>
              <div class=" col-md-12 font-15"><a href="#" class="number-selct mr-1">2</a>
                Mostly to get attention, revenge or a reaction from others
                </div>
            </div>
            <div class="row">
              <div class="  col-md-12 font-15"><a href="#" class="number-selct mr-1">3</a>
                Equally to get attention, revenge or a reaction from others and to end/stop the pain
                </div>
                <div class=" col-md-12 font-15"><a href="#" class="number-selct mr-1">4</a>
                  Mostly to end or stop the pain (you couldn’t go on living with the pain or how you were feeling)
             </div>
            </div>
              <div class="row">
              
             <div class=" col-md-12 font-15"><a href="#" class="number-selct mr-1">5</a>
              Completely to end or stop the pain (you couldn’t go on living with the pain or  how you were feeling)
            </div>
            <div class=" col-md-12 font-15"><a href="#" class="number-selct mr-1">0</a>
              Does not apply
            </div>
            </div>
         
        </div>
            
          </div>
          <div class="col-md-2 text-center d-flex align-items-center border">
           <div class="value-number-selct w-100">
            <span>5</span>
           </div>
          </div>
          <div class="col-md-2 text-center d-flex align-items-center border">
            
          </div>
      </div>
  </div>
  <div class="total-bg col-md-12 ">
    <div class="row">
        <div class="col-md-8  text-right">
          <div class="safe-t-label-bold">Total Score :</div>
         
         </div> 
        
        <div class="col-md-2 text-center">
          <span class="font-weight-bold">10</span>
        </div>
        <div class="col-md-2">
          
        </div>
      </div>
    </div>
    <div class="safe-t-label-bold mt-3">Notes</div>
    <div class="col-md-12 mt-3 mb-2  p-0">

      <div class="safe-t-label p-0 mb-3 font-weight-500 text-dark col-md-12">Behaviors</div>
      <div class="col-md-12 pl-3 ">
        <div class="row">
       <div class="col-md-12"> <label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox">Preparatory Acts (e.g., buying pills, purchasing a gun, giving things away, writing a suicide note) </label></div>
       <div class="col-md-12"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" >Aborted/self-interrupted attempts,</label></div> 
       <div class="col-md-12"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox"  >Interrupted attempts and </label></div>
       <div class="col-md-12"><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox"  >Actual attempts</label></div> 
      
      </div>

        </div>
    </div>
    <div class="mb-2">
      <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Assess for the presence of non-suicidal self-injurious behavior <span class="text-muted text-xs">(e.g. cutting, hair pulling, cuticle biting, skin picking)</span> </div>
      <div class="text-muted text-xs">particularly among adolescents and  young adults, and especially among those with a history of mood or externalizing disorders</div>
    </div>
    <div class="mb-2">
      <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >For Youths: <span class="text-muted text-xs">ask parents/guardian about evidence of suicidal thoughts, plans or behaviors and changes in mood, behaviors or disposition</span> </div>
    
    </div>
    <div class="mb-2">
      <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Assess for homicidal ideation, plan behavior and intent <span class="text-muted text-xs">particularly in:</span></div>
    <div class="pl-3">
           
     <div class="mt-2"><input class="mr-2 "  type="checkbox"  > <span class="text-muted text-xs">character disordered males dealing with separation, especially if paranoid, or impulsivity disorders</span></div>
    </div>
    </div>
        </div>
        <div class="s4 mb-4">
          <div class="custom-head mt-3 mb-3"><h2>Step 4: Guidelines to Determine Level of Risk and Develop Interventions to LOWER Risk Level</h2></div>
          <blockquote><div class="safe-head-quote">“The estimation of suicide risk, at the culmination of the suicide assessment, is the quintessential clinical judgment, since no study has identified one specific risk factor or set of risk factors as specifically predictive of suicide or other suicidal behavior.” 
            <div class="quote-from"> - From The American Psychiatric Association Practice Guidelines for the Assessment and Treatment of Patients with Suicidal Behaviors, page 24.</div></div>
          </blockquote>
          <div class="safe-head col-md-12 ">
            <div class="row">
                <div class="col-md-8 ">
                  
                 <div class="safe-t-label p-0">RISK STRATIFICATION</div>
                 
                </div>
                <div class="col-md-4">
                  <div class="safe-t-label p-0">TRIAGE</div>
                </div>
                
            </div>
        </div>
          <div class="safe-t col-md-12 border-bottom ">
            <div class="row">
                <div class="col-md-8 pt-2 pl-0">
                <div class="hgr mb-2">
                 <div class="safe-t-label p-0 ">High Risk</div>
                 <div class=" mt-3">
                    <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Suicidal  ideation  with intent or intent with plan in past month (C-SSRS Suicidal Ideation #4 or #5) </label></div>
                    <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Suicidal behavior within past 3 months (C-SSRS Suicidal Behavior)</label></div>
                  
                 </div> 
                </div> 
                </div>
                <div class="col-md-4">
                  <div class="safe-t-label p-2"><div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Initiate local psychiatric admission process</label></div>
                    <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Stay with patient until transfer to higher level of care is complete
                  Follow-up and document outcome of emergency psychiatric evaluation</label></div>
                </div>
                </div>
            </div>
        </div>
        <div class="safe-t col-md-12 border-bottom ">
          <div class="row">
              <div class="col-md-8 pt-2 pl-0">
                <div class="modr mb-2">
               <div class="safe-t-label p-0 ">Moderate Risk </div>
               <div class=" mt-3">
                <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Suicidal ideation with method WITHOUT plan, intent or behavior 
                  in past month (C-SSRS screen #3)</label></div>
                <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Suicidal behavior more than 3 months ago (C-SSRS Suicidal Behavior)</label></div>
                <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Multiple risk factors and few protective factors</label></div>
                  
              
               
               </div> 
              </div>
              </div>
              <div class="col-md-4">
                <div class="safe-t-label p-2">
                  <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Directly address suicide risk, implementing suicide prevention strategies</label></div>
                  <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Develop Safety Plan</label></div>
                
              </div>
              </div>
              
          </div>
      </div>
      <div class="safe-t col-md-12 border-bottom ">
        <div class="row">
            <div class="col-md-8 pt-2 pl-0">
              <div class="lr mb-2">
             <div class="safe-t-label p-0 ">Low Risk</div>
             <div class=" mt-3">
              <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Wish to die or suicidal thoughts (C-SSRS Suicidal Ideation #1 and/or #2) no method, plan, intent or behavior</label></div>
              <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Suicidal ideation more than 1 month ago (C-SSRS screen #1-5)</label></div>
              <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Modifiable risk factors and strong protective factors</label></div>
              <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >No reported history of Suicidal Ideation or Behavior</label></div>
                
             
     
             </div> 
            </div>
            </div>
            <div class="col-md-4">

              <div class="safe-t-label p-2">
                <div><label  class="mr-2 font-15"><input class="mr-2 "  type="checkbox" required="" >Discretionary Outpatient Referral</label></div>
              </div>
            </div>
            
        </div>
    </div>
        </div>
        <div class="s5 mb-4">
          <div class="custom-head mt-3 mb-3"><h2>Step 5: Document Level of Risk, Rationale for Risk Assignment, Intervention and Structured Follow Up Plan (to be developed)</h2></div>
          <div class="safe-t-label-bold mt-3">Risk Level</div>
          <div class="col-md-12 p-0  mt-2">
            <label class="mr-3 font-15"><input type="checkbox" required="" class="mr-1 align-middle">High Risk</label>
            <label class="mr-3 font-15"><input type="checkbox" required="" class="mr-1 align-middle">Moderate Risk</label>
            <label class="mr-3 font-15"><input type="checkbox" required="" class="mr-1 align-middle">Low Risk Suicidal</label>
   
          </div>
          <div class="safe-t-label-bold mt-3">Clinical Note</div>
          <div class="mb-2 mt-3">
            <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Your Clinical Observation </div>
            <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Relevant Mental Status Information</div>
            <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Methods of Suicide Risk Evaluation </div>
            <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Brief Evaluation Summary </div>
             <div class="pl-3">
              <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Warning Signs</div>
              <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Risk Indicators</div>
              <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Protective Factors</div>
              <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Access to Lethal Means</div>
              <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Collateral Sources Used and Relevant Information Obtained</div>
              <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Specific Assessment Data to Support Risk Determination</div>
              <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Rationale for Actions Taken and Not Taken</div>
             </div>
             <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Provision of Crisis Line 1-800-273-TALK(8255) </div>
             <div class="safe-t-label p-0"><input class="mr-2 "  type="checkbox"  >Implementation of Safety Plan (If Applicable) </div>
            
          </div>
          <div class="Theme-textarea">
               
            <textarea cols="4" class="form-control" placeholder="comments"></textarea>
          </div>
          </div>
          <div class="col-md-12">
            <div class="text-right">
            <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button>Save</button>
            <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button>Cancel</button>
          </div>
          </div>
        </div>
      </mat-tab>






      <mat-tab label="Safety Contract"> 
        <div class="bg-white p-3 col-md-12">
          <div class="col-md-12 mt-3 mb-3">
            I, <span class="name-highlight">George (client)</span>, hereby contract with <span class="name-highlight">Ezra (therapist)</span>, that I will take the following actions if I feel suicidal.
          </div>
          
          <div class="col-md-12 mb-4 mt-3">
            <div class="treat-label mb-2">1. I will not attempt suicide.</div>
            <div class="treat-label d-flex mb-2">
              <span class="mt-2 mr-2">2. I will phone </span>
              <span><input type="text" class="form-control" /></span>
              <span class="mt-2 ml-2 mr-2">at </span>
              <span><input type="text" class="form-control" /></span>
            </div>
            <div class="treat-label d-flex mb-2">
              <span class="mt-2 mr-2">3. If I do not reach,</span>
              <span><input type="text" class="form-control" /></span>
              <span class="mt-2 ml-2 mr-2">I will phone any of the following services : </span>
               </div>
               <div class="services-person">
                <div class="col-md-6 mt-3 mb-3">
                  <div class="medication-dosage">
                    <div  class="row inputElement">
                      <div  class="col-md-6">
                        <label >Name/Agency</label>
                      </div>
                      <div  class="col-md-6">
                        <label >Phone</label>
                      </div>
                      
                    </div>
                    <div  class="loop-section position-relative mb-2 ">
                      <div class="row inputElement">
                        <div  class="col-md-6">
                          <input  maxlength="50" placeholder="Name/Agency" type="text" required="" class="form-control "  >
                        </div>
                        <div  class="col-md-6">
                          <input  maxlength="50" placeholder="Phone" type="text" required="" class="form-control "  >
                        </div>
                        
                      </div>
                      <a  class="add " style="cursor: pointer;">
                        <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
                      </a>
                      <a  class="remove" style="cursor: pointer;">
                        <i  class="fa fa-lg fa-minus-circle"></i>
                        <span  class="sr-only">Remove</span>
                      </a>
                    </div>
                  </div>
                </div>
               </div>
            <div class="treat-label">4. I will further seek social supports from any of the following people</div>
            <div class="services-person">
              <div class="col-md-6 mt-3 mb-3">
                <div class="medication-dosage">
                  <div  class="row inputElement">
                    <div  class="col-md-6">
                      <label >Name</label>
                    </div>
                    <div  class="col-md-6">
                      <label >Phone</label>
                    </div>
                    
                  </div>
                  <div  class="loop-section position-relative mb-2 ">
                    <div class="row inputElement">
                      <div  class="col-md-6">
                        <input  maxlength="50" placeholder="Name" type="text" required="" class="form-control "  >
                      </div>
                      <div  class="col-md-6">
                        <input  maxlength="50" placeholder="Phone" type="text" required="" class="form-control "  >
                      </div>
                      
                    </div>
                    <a  class="add " style="cursor: pointer;">
                      <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
                    </a>
                    <a  class="remove" style="cursor: pointer;">
                      <i  class="fa fa-lg fa-minus-circle"></i>
                      <span  class="sr-only">Remove</span>
                    </a>
                  </div>
                </div>
              </div>
             </div>
            <div class="treat-label">5. If none of these actions are helpful or not available, I will check-in the ER at one of the following</div>
            <div class="services-person">
              <div class="col-md-9 mt-3 mb-3">
                <div class="medication-dosage">
                  <div  class="row inputElement">
                    <div  class="col-md-4">
                      <label >Name</label>
                    </div>
                    <div  class="col-md-4">
                      <label >Address</label>
                    </div>
                    <div  class="col-md-4">
                      <label >Phone</label>
                    </div>
                    
                  </div>
                  <div  class="loop-section position-relative mb-2 ">
                    <div class="row inputElement">
                      <div  class="col-md-4">
                        <input  maxlength="50" placeholder="Name" type="text" required="" class="form-control "  >
                      </div>
                      <div  class="col-md-4">
                        <input  maxlength="50" placeholder="Address" type="text" required="" class="form-control "  >
                      </div>
                      <div  class="col-md-4">
                        <input  maxlength="50" placeholder="Phone" type="text" required="" class="form-control "  >
                      </div>
                      
                    </div>
                    <a  class="add " style="cursor: pointer;">
                      <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
                    </a>
                    <a  class="remove" style="cursor: pointer;">
                      <i  class="fa fa-lg fa-minus-circle"></i>
                      <span  class="sr-only">Remove</span>
                    </a>
                  </div>
                </div>
              </div>
             </div>
            <div class="treat-label">6. If I am not able to receive help I will phone 911 or 0</div>
          </div>
          
          <div  class="col-md-12">
            <div  class="form-group clearfix m-0">
              <div  class="float-left col-md-4 p-0 mt-3 pt-1">
                <label  class="treat-label mr-2 w-100">Client’s Signature </label>
                <img  id="esign" height="50px" width="300px" src="">
                <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"> Add E-Sign </button>
              </div>
              <div  class="float-right col-md-2 p-0 ">
                <mat-form-field class="w-100">
                  <mat-label>Date</mat-label>
                  <input matInput [matDatepicker]="picker">
                  
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div  class="col-md-12">
            <div  class="form-group clearfix m-0">
              <div  class="float-left col-md-4 p-0 mt-3 pt-1">
                <label  class="treat-label mr-2 w-100">Therapist’s Signature</label>
                <img  id="esign" height="50px" width="300px" src="">
                <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2"> Add E-Sign </button>
              </div>
              <div  class="float-right col-md-2 p-0 ">
                <mat-form-field class="w-100">
                  <mat-label>Date</mat-label>
                  <input matInput [matDatepicker]="picker">
                  
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="text-right">
                <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button>Save</button>
                <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button>Cancel</button>
              </div>
              </div>
        </div>
      </mat-tab>



      <mat-tab label="Suicide Screener"> 
        <div class="bg-white p-3 col-md-12">
          <div class="custom-head mt-3 mb-3"><h2>Suicide Screening</h2></div>
          <div class="col-md-12 p-0">
            <div class="col-md-12 text-right">
              <div class="row">
               <div class="font-12 hint-ques-label"><i class="far fa-lightbulb mr-2"></i>Ask Questions that are Bolded and Underlined</div>
             <div class="ml-auto mt-3">
              <span class="low"><i class="fas fa-square mr-2"> </i>Low Risk</span>
              <span class="moderate"><i class="fas fa-square mr-2"> </i>Moderate Risk</span>
              <span class="high"><i class="fas fa-square mr-2"> </i>High Risk</span>
             </div>
              </div>
              </div>
            <div class="safe-head col-md-12 ">
              <div class="row">
                <div class="col-md-10 ">
                  <div class="font-15 mt-3 pl-2">Suicidal Ideation Definitions and Prompts</div>
                </div>
                <div class="col-md-2 text-center pl-0 border-left">
                  <div class="safe-t-label pl-0 text-muted">Past Month</div>
                  <div class="col-md-12 border-top">
                  <div class="row">
                    <div class="col-md-6 p-2 text-success bg-white">
                      <div class="safe-t-label pl-0"><i class="fas fa-check-circle mr-2"></i>Yes</div>
                    </div>
                    <div class="col-md-6 p-2 border-left text-danger bg-white">
                      <div class="safe-t-label pl-0"><i class="fas fa-times-circle mr-2"></i>No</div>
                    </div>
                  </div>
                </div>
                </div>
               </div>
               </div>
               <div class="safe-t col-md-12 safe-border">
                <div class="row">
                  <div class="col-md-10">
                    <div class="safe-t-label-bold">1) Wish to be dead</div>
                    <div class="safe-t-label text-muted">Person endorses thoughts about a wish to be dead or not alive anymore, or wish to fall asleep and not wake up</div>
                    <div class="ques-safe">Have you wished you were dead or wished you could go to sleep and not wake up?</div>
                  </div>
                  <div class="col-md-2">
                    <div class="col-md-12 ">
                    <div class="row">
                      <div class="col-md-6 h-40 border-right lr">
                        <div class="form-check text-center large-radio-btn">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                          <label class="form-check-label" for="flexRadioDefault1">
                            
                          </label>
                        </div>
                         </div>
                      <div class="col-md-6  h-40 p-2">
                        <div class="form-check text-center large-radio-btn">
                          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                          <label class="form-check-label" for="flexRadioDefault2">
                            
                          </label>
                        </div>
                         </div>
                    </div>
                  </div>
                    </div>
                  </div>
                </div>
                <div class="safe-t col-md-12 safe-border">
                  <div class="row">
                    <div class="col-md-10">
                      <div class="safe-t-label-bold">2) Suicidal Thoughts</div>
                      <div class="safe-t-label text-muted">general non-specific thoughts of wanting to end one's life/die by suicide, "I've thought about Killing myself" without general thoughts of ways to kill oneself/associated methods,intent, or plan.</div>
                      <div class="ques-safe">Have you actually had any thoughts of killing yourself?</div>
                    </div>
                    <div class="col-md-2">
                      <div class="col-md-12 ">
                      <div class="row">
                        <div class="col-md-6 h-40 border-right lr">
                          <div class="form-check text-center large-radio-btn">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                              
                            </label>
                          </div>
                           </div>
                        <div class="col-md-6  h-40 p-2 ">
                          <div class="form-check text-center large-radio-btn">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                            <label class="form-check-label" for="flexRadioDefault2">
                              
                            </label>
                          </div>
                           </div>
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-question-answer">If Yes to 2, ask questions 3,4,5 and 6. If No to 2, go directly to question 6</div>
                  <div class="safe-t col-md-12 safe-border">
                    <div class="row">
                      <div class="col-md-10">
                        <div class="safe-t-label-bold">6) Suicide Behavior Question</div>
                        <div class="ques-safe">Have you ever done anything, started to do anything, or prepared to do anything to end your life?</div>
                        <div class="safe-t-label text-muted">Examples: Collected pills, obtained a gun, gave away valuables, wrote a will or suicide note, took out pills but didn’t swallow any, held a gun but changed your mind or it was grabbed from your hand, went to the roof but didn’t jump; or actually took pills, tried to shoot yourself, cut yourself, tried to hang yourself, etc.</div>
                        <div class="ques-safe">If yes, Was this within the past three months?</div>
                      </div>
                      <div class="col-md-2">
                        <div class="col-md-12  text-center border">
                          <div class="row">
                          <div class="safe-head safe-t-label pl-0 text-muted w-100">Lifetime</div>
                        </div>
                        </div>
                        <div class="col-md-12 ">
                        <div class="row">
                          <div class="col-md-6 h-40 border-right p-2">
                            <div class="form-check text-center large-radio-btn">
                              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                              <label class="form-check-label" for="flexRadioDefault1">
                                
                              </label>
                            </div>
                             </div>
                          <div class="col-md-6  h-40 p-2 ">
                            <div class="form-check text-center large-radio-btn">
                              <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                              <label class="form-check-label" for="flexRadioDefault2">
                                
                              </label>
                            </div>
                             </div>
                        </div>
                      </div>
                      <div class="col-md-12  text-center border">
                        <div class="row">
                        <div class="safe-head safe-t-label pl-0 text-muted w-100">Past 3 months</div>
                      </div>
                      </div>
                      <div class="col-md-12 ">
                      <div class="row">
                        <div class="col-md-6 h-40 border-right hgr">
                          <div class="form-check text-center large-radio-btn">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                              
                            </label>
                          </div>
                           </div>
                        <div class="col-md-6  h-40 modr">
                          <div class="form-check text-center large-radio-btn">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                            <label class="form-check-label" for="flexRadioDefault2">
                              
                            </label>
                          </div>
                           </div>
                      </div>
                    </div>
                        </div>
                      </div>
                    </div>
                </div>
         
          
          
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div> -->
<!-- <div class="modal bulk-add emoji-popup1 " id="myModal" style="display: block;">
  <div class="modal-dialog mb-2">
      <div class="modal-content">
          <div class="modal-body p-0">
              <div class="clearfix popup-mytask position-relative">
                  <div class="modal-closing "><a class="cursor-pointer" ><i
                              class="fa fa-times"></i></a></div>
                  <div class="col-md-12 mytask p-0">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <span class="head-top-schedule">Group Theraphy</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="header-day" class="schedule-pop-head mt-2 " >Patient Info</div>
                            <div  class=" align-middle mb-3" >
                              <a  class="item-author text-color mb-1 d-block">Mery F</a>
                              <div  class="patient-details">
                               
                                <h5 >
                                  <i  class="fas fa-envelope mr-1"></i>mery@yopmail.com </h5>
                                  <h5  class="mb-0">
                                    <i  class="fas fa-phone"></i>+1 (906) 589-9652</h5>
                                  </div>
                                </div>
                          </div>
                          <div class="col-md-6">
                            <div class="header-day" class="schedule-pop-head mt-2 " >Clinician Info</div>
                            <div  class=" align-middle mb-3" >
                              <a  class="item-author text-color mb-1 d-block  ">Zoya D</a>
                              <div  class="patient-details">
                                <h5 >
                                  <i  class="fas fa-envelope mr-1"></i>zoya@yopmail.com </h5>
                                  <h5  class="mb-0">
                                    <i  class="fas fa-phone"></i>(894) 859-0354</h5>
                                  </div>
                                </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div  class="w-100 ">
                              <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Scheduled Date </span>
                              <span  class="text-color text-success">08/31/2021 </span>
                            </div>
                            <div  class="w-100 mb-2">
                              <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Start Time </span>
                              <span  class="text-color text-success"> 08:00 PM</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <a class="mr-2 link-trans" href="#" title="Virtual Meeting Link"><span><i class="fas fa-link mr-2"></i>Virtual Meeting Link</span></a>
                           <a class="link-trans" href="#" title="Session details"><span><i class="fas fa-info-circle mr-2"></i>Session details</span></a>
                          </div>
                        </div>
                        <div class="row d-none">
                          <div class="col-md-12 border-top border-bottom pt-2 pb-2">
                            <textarea cols="2" class="form-control font-italic" placeholder="Enter Admin Notes" ></textarea>
                          <div class="w-100 text-right mt-2">
                            <button class="btn btn-cancel float-right">Cancel</button>
                            <button class="btn primary-btn-filled float-right mr-2">Save</button>
                            
                          </div>
                          </div>
                          </div>
                        <div class="row">
                          <div class="col-md-12 text-right mt-4 mb-3">
                            <button type="button" class="btn btn-primary mr-2 btn-sm">Add admin Notes</button>
                            <button type="button" class="btn btn-success mr-2 btn-sm">Re-schedule</button>
                            <button type="button" class="btn btn-danger mr-2 btn-sm">Delete</button>
                          </div>
                        </div>

                      </div>
                      
                  </div>

              </div>
          </div>

      </div>
  </div>
</div> -->
<!-- <div class="modal bulk-add emoji-popup1 " id="myModal" style="display: block;">
  <div class="modal-dialog mb-2">
      <div class="modal-content">
          <div class="modal-body p-0">
              <div class="clearfix popup-mytask position-relative">
                  <div class="modal-closing "><a class="cursor-pointer" ><i
                              class="fa fa-times"></i></a></div>
                  <div class="col-md-12 mytask p-0">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <span class="head-top-schedule">Group Theraphy</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="header-day" class="schedule-pop-head mt-2 " >Patient Info</div>
                            <div  class=" align-middle mb-3" >
                              <a  class="item-author text-color mb-1 d-block">Mery F</a>
                              <div  class="patient-details">
                               
                                <h5 >
                                  <i  class="fas fa-envelope mr-1"></i>mery@yopmail.com </h5>
                                  <h5  class="mb-0">
                                    <i  class="fas fa-phone"></i>+1 (906) 589-9652</h5>
                                  </div>
                                </div>
                          </div>
                          
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div  class="w-100 ">
                              <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Scheduled Date </span>
                              <span  class="text-color text-success">08/31/2021 </span>
                            </div>
                            <div  class="w-100 mb-2">
                              <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Start Time </span>
                              <span  class="text-color text-success"> 08:00 PM</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <a class="mr-2 link-trans" href="#" title="Virtual Meeting Link"><span><i class="fas fa-link mr-2"></i>Virtual Meeting Link</span></a>
                           <a class="link-trans" href="#" title="Session details"><span><i class="fas fa-info-circle mr-2"></i>Progress note</span></a>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12  pt-2 pb-2">
                            <p class="schedule-time-head mb-1 text-sm">Admin Notes</p>
                            <p class="text-xs">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                               It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                          </div>
                          </div>
                        

                      </div>
                      
                  </div>

              </div>
          </div>

      </div>
  </div>
</div> -->
<!-- <div class="modal bulk-add emoji-popup1 modal-bg " id="myModal" style="display: block;">
  <div class="modal-dialog mb-2">
      <div class="modal-content">
          <div class="modal-body p-0">
              <div class="clearfix popup-mytask position-relative">
                  <div class="modal-closing "><a class="cursor-pointer" ><i
                              class="fa fa-times"></i></a></div>
                  <div class="col-md-12 mytask p-0">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <span class="head-top-schedule">Group Therapy</span>
                          </div>
                        </div>
                        <div class="row">
                         
                          <div class="col-md-12">
                            <div class="header-day" class="schedule-pop-head mt-2 " >Clinician Info</div>
                            <div  class=" align-middle mb-3" >
                              <a  class="item-author text-color mb-1 d-block  ">Zoya D</a>
                              <div  class="patient-details">
                                <h5 >
                                  <i  class="fas fa-envelope mr-1"></i>zoya@yopmail.com </h5>
                                  <h5  class="mb-0">
                                    <i  class="fas fa-phone"></i>(894) 859-0354</h5>
                                  </div>
                                </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div  class="w-100 ">
                              <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Scheduled Date </span>
                              <span  class="text-color text-success">08/31/2021 </span>
                            </div>
                            <div  class="w-100 ">
                              <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Start Time </span>
                              <span  class="text-color text-success"> 08:00 PM</span>
                            </div>
                            <div  class="w-100 mb-2">
                              <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Duration </span>
                              <span  class="text-color text-success">3hrs</span>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <a class="mr-2 link-trans" href="#" title="Virtual Meeting Link"><span><i class="fas fa-link mr-2"></i>Virtual Meeting Link</span></a>
                           <a class="link-trans" href="#" title="Session details"><span><i class="fas fa-info-circle mr-2"></i>Session details</span></a>
                          </div>
                        </div>
                        <div class="row d-none">
                          <div class="col-md-12 border-top border-bottom pt-2 pb-2">
                            <textarea cols="2" class="form-control font-italic" placeholder="Enter Admin Notes" ></textarea>
                          <div class="w-100 text-right mt-2">
                            <button class="btn btn-cancel float-right">Cancel</button>
                            <button class="btn primary-btn-filled float-right mr-2">Save</button>
                            
                          </div>
                          </div>
                          </div>
                        <div class="row">
                          <div class="col-md-12 text-right mt-4 mb-3">
                            <button type="button" class="btn btn-primary mr-2 btn-sm">Add admin Notes</button>
                            <button type="button" class="btn btn-success mr-2 btn-sm">Re-schedule</button>
                            <button type="button" class="btn btn-danger mr-2 btn-sm">Delete</button>
                          </div>
                        </div>

                      </div>
                      
                  </div>

              </div>
          </div>

      </div>
  </div>
</div> -->
<!-- <div class="intake-welcome-screen">
  <div class="landing-page">
    <div  class="col-md-12">
      <div  class="row">
        <div  class="lead-box pl-5 pr-5 pt-4 pb-4 w-100">
          <div  class="col-md-12 text-center welcome-txt">
            <h2 >Thank You !</h2>
            <div  class="w-100 welcome-img">
              <img  src="../../../assets/images/WelcomepageImage.png" class="img-fluid">
            </div>
          </div>
          <div  class="col-md-12 text-center welcome-txt">
            <h2 >churchil,</h2>
          </div>
          <div  class="sub-txt-board mt-3 mb-3">The front desk will provide you Intake appointment options shortly.</div>
          <div  class="w-100 text-center">
            <button  class="btn start-btn">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

  <!-- <div class="billing-page">
  <div class="col-md-12">
    <div  class="d-flex">
      <div  class="head-section mt-2 mb-2">
        Payment Details
      </div>
      <div  class="ml-auto">
        <button  tabindex="0" class="btn back-btn-new mr-2">
          <i  class="fas fa-chevron-left mr-2"></i>Back</button>
        </div>
      </div>
      </div>
      <div class="col-md-12 bg-white p-2">
        <div class="row">
          <div class="col-md-8">
            <div class="custom-head mt-3 mb-2 pl-2">
              <h2>Account Information</h2>
            </div>
            <div class="col-md-12 info-bill">
              <div class="row">
                <div class="col-md-6">
                  <div class="patient-detail pl-2 pt-2">
                    <h6 class="fw-bold">Jonathan</h6>
                    <ul  class="list-unstyled text-sm text-muted">
                      <li><div>3897 Hickroy St, salt Lake city</div>
      
                        Utah, United States 84104</li>
                      <li >
                        <i  class="fas fa-mobile"></i> (937) 373-7322</li>
                        <li ><i  class="fas fa-envelope"></i> Jackdxr@yopmail.com</li>
                      </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-6"><label class="text-muted font-weight-500 font-15">Your Balance Due</label></div>
                    <div class="col-md-6"><div class="fw-bold">: <i class="fas fa-dollar-sign mr-1"></i>1254.50</div></div>
                  </div>
                  <div class="row">
                    <div class="col-md-6"><label class="text-muted font-weight-500 font-15">Total Number of Payments</label></div>
                    <div class="col-md-6"><div class="fw-bold">: 20</div></div>
                  </div>
                  <div class="row">
                    <div class="col-md-6"><label class="text-muted font-weight-500 font-15">Guardian Name</label></div>
                    <div class="col-md-6"><div class="fw-bold">: David</div></div>
                  </div>
                  <div class="row">
                    <div class="col-md-6"><label class="text-muted font-weight-500 font-15">Treatment Start Date</label></div>
                    <div class="col-md-6"><div class="fw-bold">: 09/04/22</div></div>
                  </div>
                 
                </div>
              </div>
            </div>
            

          </div>
          <div class="col-md-4">
            <div class="col-md-12 mb-4"> 
              <div class="row box-right "> 
                <div class="col-md-6 pr-0 border-right "> 
                  <p class="pl-3 p-blue  h6 mb-0">TOTAL RECIEVED</p>
                   <p class="h3 fw-bold-700 d-flex"> 
                    <i class="fas fa-dollar-sign mr-1 align-text-top text-muted"></i>84,254 <span class="text-muted">.58</span>
                   </p> 
                  
                  </div>
                   <div class="col-md-6">
                     <div class="">
                      <p class="pl-3   h6 mb-0 p-blue">PENDING</p>
                       
                       <p class="h3 fw-bold-700 d-flex"> 
                        <i class="fas fa-dollar-sign mr-1 align-text-top text-muted"></i>1254 <span class="text-muted">.50</span>
                       </p> 
                     </div>
                    </div> 
                  </div> 
                </div>
                <div class="col-md-12">
                  <button class="btn btn-success btn-sm float-right mb-2"><i class="fas fa-paperclip mr-2"></i>Notes attached</button>
                 
                  <button class="btn primary-btn-outlined-small float-right mb-2 d-none"><i class="fas fa-plus mr-2"></i>Add Notes</button>
                  <div class="notes-container d-none">
                    <textarea cols="3" class="form-control" ></textarea>
                    
                    <button class="btn btn-cancel float-right mb-1 mt-2">cancel</button>
                    <button class="btn primary-btn-filled float-right mb-1 mt-2 mr-1">Save</button>
                 
                  </div>
                
                </div>
          </div> 
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="custom-head mt-3 mb-1 pl-2 d-flex">
              <h2>Account Summary</h2>
              <div class="ml-auto">
                
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="risks-tabs">
              <mat-tab-group>
                <mat-tab label="Payments Active">
                  <div class="summary-list col-md-12">
                    <div class="p-2 ">
                    
                      <div class="card-body1">
                        <div class="col-md-12 per-section-loop">
                          <div class="row pt-2 pb-2">
                            <div class="col-md-1 pl-0">
                              <div class="bill-labeld">
                                Scheduled Date
                              </div>
                             
                              
                            </div>
                            <div class="col-md-2">
                              <div class="bill-labeld">
                                Therapy
                              </div>
                             
                              
                            </div>
                            <div class="col-md-2">
                              <div class="bill-labeld">
                               Frequency
                              </div>
                              
                            </div>
                            <div class="col-md-1">
                              <div class="bill-labeld">
                                Duration
                              </div>
                              
                            </div>
                            
                            <div class="col-md-2">
                              <div class="bill-labeld">
                               Payment Due
                              </div>
                             
                            </div>
                           
                            <div class="col-md-2">
                              <div class="bill-labeld">
                                Action
                              </div>
                              
                            </div>
                          </div>
                          <div class="row pt-2 pb-2">
                            <div class="col-md-1 pl-0">
                             
                              <div class="bill-valued-sub">
                                4/07/22
                              </div>
                              
                              
                            </div>
                            <div class="col-md-2">
                             
                              <div class="bill-valued-sub">
                                Group Therapy
                              </div>
                              
                              
                            </div>
                            <div class="col-md-2">
                              <div class="bill-valued-sub">
                                Per Session
                               </div>
                            </div>
                            <div class="col-md-1">
                            
                              <div class="bill-valued-sub">
                                3 hrs
                              </div>
                            </div>
                            
                            <div class="col-md-2">
                            
                              <div class="bill-valued">
                                $10.00
                              </div>
                            </div>
                            
                            <div class="col-md-2">
                             
                              <div class="bill-valued-sub">
                                <button  mat-stroked-btn class="btn primary-btn-filled-small">Pay now</button>
                              </div>
                              
                              
                            </div>
                          </div>
                          <div class="row pt-2 pb-2">
                            <div class="col-md-1 pl-0">
                             
                              <div class="bill-valued-sub">
                                4/07/22
                              </div>
                              
                              
                            </div>
                            <div class="col-md-2">
                             
                              <div class="bill-valued-sub">
                                Family Therapy
                              </div>
                              
                              
                            </div>
                            <div class="col-md-2">
                              <div class="bill-valued-sub">
                                Per Session
                               </div>
                            </div>
                            <div class="col-md-1">
                            
                              <div class="bill-valued-sub">
                                3 hrs
                              </div>
                            </div>
                            
                            <div class="col-md-2">
                            
                              <div class="bill-valued">
                                $10.00
                              </div>
                            </div>
                           
                            <div class="col-md-2">
                             
                              <div class="bill-valued-sub">
                                <button  mat-stroked-btn class="btn primary-btn-filled-small">Pay now</button>
                              </div>
                              
                              
                            </div>
                          </div>
                          
                        </div>
                        <div class="col-md-12 weekly-loop">
                          <div class="row ">
                            <div class="col-md-1 pl-0">
                              
                              <div class="bill-valued-sub pt-2 pb-2">
                                4/07/22
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                5/07/22
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                6/07/22
                              </div>
                              
                            </div>
                            <div class="col-md-2">
                              
                              <div class="bill-valued-sub pt-2 pb-2">
                                Group Therapy
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                Group Therapy
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                Group Therapy
                              </div>
                              
                            </div>
                            <div class="col-md-2">
                             
                              <div class="bill-valued-sub pt-2 pb-2">
                                Weekly
                               </div>
                            </div>
                            <div class="col-md-1">
                              
                              <div class="bill-valued-sub pt-2 pb-2">
                                3 hrs
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                3 hrs
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                3 hrs
                              </div>
                              
                            </div>
                            
                            <div class="col-md-2">
                             
                              <div class="bill-valued pt-2 pb-2">
                                $10.00
                              </div>
                              <div class="bill-valued pt-2 pb-2">
                                $11.00
                              </div>
                              <div class="bill-valued pt-2 pb-2">
                                $12.00
                              </div>
                            </div>
                            
                           
                            <div class="col-md-2">
                              
                              <div class="bill-valued-sub pt-2 pb-2">
                                <button  mat-stroked-btn class="btn primary-btn-filled-small">Pay now</button>
                              </div>
                              
                            </div>
                          </div>
                          


                         
                          
                        </div>
                      </div>
                    </div>
                  </div>

                </mat-tab>
                <mat-tab label="Payments Completed">
                  <div class="d-flex w-100 pr-3">
                    <div class="ml-auto">
                      
                      
                      <button class="btn primary-btn-filled-small float-right mr-2"><i class="fas fa-print mr-2"></i>Print</button>
                    </div>
                  </div>
                  <div class="summary-list col-md-12">
                    <div class="p-2 ">
                     
                      <div class="card-body1">
                        <mat-radio-group aria-label="Select an option">
                        <div class="col-md-12 per-section-loop">
                         
                          <div class="row pt-2 pb-2">
                            <div class="col-md-1">
                             
                             
                            </div>
                            <div class="col-md-1 pl-0">
                              <div class="bill-labeld">
                                Scheduled Date
                              </div>
                             
                              
                            </div>
                            <div class="col-md-2">
                              <div class="bill-labeld">
                                Therapy
                              </div>
                             
                              
                            </div>
                            <div class="col-md-2">
                              <div class="bill-labeld">
                               Frequency
                              </div>
                              
                            </div>
                            <div class="col-md-1">
                              <div class="bill-labeld">
                                Duration
                              </div>
                              
                            </div>
                            
                            <div class="col-md-2">
                              <div class="bill-labeld">
                               Payment Done
                              </div>
                             
                            </div>
                            <div class="col-md-1">
                              <div class="bill-labeld">
                                Payment Type
                              </div>
                              
                            </div>
                            <div class="col-md-1">
                              <div class="bill-labeld">
                                Paid On
                              </div>
                              
                            </div>
                            
                          </div>
                          <div class="row pt-2 pb-2">
                            <div class="col-md-1">
                              <div class="">
                              <mat-radio-button value="1"></mat-radio-button>
                            </div>
                               
                              
                             
                            </div>
                            <div class="col-md-1 pl-0">
                             
                              <div class="bill-valued-sub">
                                4/07/22
                              </div>
                              
                              
                            </div>
                            <div class="col-md-2">
                             
                              <div class="bill-valued-sub">
                                Group Therapy
                              </div>
                              
                              
                            </div>
                            <div class="col-md-2">
                              <div class="bill-valued-sub">
                                Per Session
                               </div>
                            </div>
                            <div class="col-md-1">
                            
                              <div class="bill-valued-sub">
                                3 hrs
                              </div>
                            </div>
                            
                            <div class="col-md-2">
                            
                              <div class="bill-valued">
                                $10.00
                              </div>
                            </div>
                            <div class="col-md-1">
                              
                              <div class="bill-valued-sub">
                                Cash
                              </div>
                            </div>
                            <div class="col-md-1">
                             
                              <div class="bill-valued-sub">
                                4/07/22
                              </div>
                              
                              
                            </div>
                            
                          </div>
                          <div class="row pt-2 pb-2">
                            <div class="col-md-1">
                              <div class="">
                                <mat-radio-button value="2"></mat-radio-button>
                              </div>
                             
                            </div>
                            <div class="col-md-1 pl-0">
                             
                              <div class="bill-valued-sub">
                                4/07/22
                              </div>
                              
                              
                            </div>
                            <div class="col-md-2">
                             
                              <div class="bill-valued-sub">
                                Family Therapy
                              </div>
                              
                              
                            </div>
                            <div class="col-md-2">
                              <div class="bill-valued-sub">
                                Per Session
                               </div>
                            </div>
                            <div class="col-md-1">
                            
                              <div class="bill-valued-sub">
                                3 hrs
                              </div>
                            </div>
                            
                            <div class="col-md-2">
                            
                              <div class="bill-valued">
                                $10.00
                              </div>
                            </div>
                            <div class="col-md-1">
                              
                              <div class="bill-valued-sub">
                                Cash
                              </div>
                            </div>
                            <div class="col-md-1">
                             
                              <div class="bill-valued-sub">
                                4/07/22
                              </div>
                              
                              
                            </div>
                            
                          </div>
                        
                        </div>
                        <div class="col-md-12 weekly-loop">
                          <div class="row ">
                            <div class="col-md-1">
                              <div class="pt-2">
                                <mat-radio-button value="3"></mat-radio-button>
                              </div>
                             
                            </div>
                            <div class="col-md-1 pl-0">
                              
                              <div class="bill-valued-sub pt-2 pb-2">
                                4/07/22
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                5/07/22
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                6/07/22
                              </div>
                              
                            </div>
                            <div class="col-md-2">
                              
                              <div class="bill-valued-sub pt-2 pb-2">
                                Group Therapy
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                Group Therapy
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                Group Therapy
                              </div>
                              
                            </div>
                            <div class="col-md-2">
                             
                              <div class="bill-valued-sub pt-2 pb-2">
                                Weekly
                               </div>
                            </div>
                            <div class="col-md-1">
                              
                              <div class="bill-valued-sub pt-2 pb-2">
                                3 hrs
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                3 hrs
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                3 hrs
                              </div>
                              
                            </div>
                            
                            <div class="col-md-2">
                             
                              <div class="bill-valued pt-2 pb-2">
                                $10.00
                              </div>
                              <div class="bill-valued pt-2 pb-2">
                                $11.00
                              </div>
                              <div class="bill-valued pt-2 pb-2">
                                $12.00
                              </div>
                            </div>
                            <div class="col-md-1">
                             
                              <div class="bill-valued-sub pt-2 pb-2">
                                Cash
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                Cash
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                Cash
                              </div>
                            </div>
                            <div class="col-md-1">
                             
                              <div class="bill-valued-sub pt-2 pb-2">
                                7/07/22
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                8/07/22
                              </div>
                              <div class="bill-valued-sub pt-2 pb-2">
                                9/07/22
                              </div>
                            </div>
                            
                          </div>
                          


                         
                          
                        </div>
                      </mat-radio-group>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                </mat-tab-group>
          </div>
          
          
        </div>
      </div>
      </div>
      <div class="comment-popup  cancel-tour">
        <div class="modal" tabindex="-1" role="dialog"  style="display: none;">
          <div class="modal-dialog mat-dialog-container " role="document">
            <div class="modal-content border-0">
    
              <div class="modal-body">
      <div >
    
    
        <div class="col-md-12">
          <div class="row ">
            <div class="col-md-12">
    
                <p class="text-center">if they want to invoice a single payment or list of payments?</p>
            </div>
    
    
          </div>
    
        </div>
        <div class="text-center col-md-12 mt-2">
          <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" >Yes</button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal" >No</button>
        </div>
    
    
    
      </div>
    
    
      </div>
      </div>
      </div>
      </div>
      </div>
      <div class="Activity-popup11 print-popup" style="display: none;">
        <div class="modal"  tabindex="-1" role="dialog"  style="display: block;">
           <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                 <div class="modal-header">
                    <h6 class="modal-title"><i class="fas fa-print mr-2"></i> Print</h6>
                    <button type="button" class="close"  data-dismiss="modal"
                    aria-label="Close" >
                    <span aria-hidden="true">&times;</span>
                 </button>
                 </div>
                 <div class="modal-body">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-2 text-center">
                        <label class="mt-2 mb-0">From</label>
                      </div>
                      <div class="col-md-4">
                        <mat-form-field class="w-100 mt--14">
                          <mat-label>Choose a date</mat-label>
                          <input matInput [matDatepicker]="picker">
                         
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="col-md-2 text-center">
                        <label class="mt-2 mb-0">To</label>
                      </div>
                      <div class="col-md-4">
                        <mat-form-field class="w-100  mt--14">
                          <mat-label>Choose a date</mat-label>
                          <input matInput [matDatepicker]="picker">
                         
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>

                  </div>
                   </div>
                   <div class="modal-footer">
                    <button class="btn primary-btn-filled primary-btn-m">print</button>
                    <button class="btn btn-cancel">cancel</button>
                   </div>
              </div>
            </div>
          </div>
        </div> 
        -->
       
          <!-- <div class="screener-doc">
          <div  class="top-btn-section col-md-12 clearfix mb-1 w-100">
            <h2  class="head-section mt-3">Screener Documents</h2>
            <div  class="col-md-5 ml-auto mt-2 pr-0 text-right ">
              <div class=" custom-toggle-btn ">
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                  <mat-button-toggle value="bold">Daily</mat-button-toggle>
                  <mat-button-toggle value="italic">Monthly</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
             
             
             </div>
            </div>
            <div class="col-md-12 d-flex">
              <span  class="filter-icon mr-2">
                <i  class="fa fa-filter"></i>
              </span>
              <div class="filter-group d-inline-block">
                <label  class="d-inline-block mr-2">Year</label>
                <select  name="clinician" class="form-control d-inline-block status-filter mr-0 "  >
                  <option  value="0" >2022</option>
                  <option  value="693" > 2021 </option>
                  <option  value="696" > 2020 </option>
                  <option value="347"  > 2019 </option>
                  <option value="347"  > 2018 </option>
                  <option value="347"  > 2017 </option>
                  <option value="347"  > 2016 </option>
                </select>
              </div>
              <div class="filter-group d-inline-block">
                <label  class="d-inline-block mr-2">Month</label>
                <select  name="clinician" class="form-control d-inline-block status-filter mr-0 "  >
                  <option  >January</option>
                  <option   > February </option>
                  <option  > march </option>
                  <option> April </option>
                  <option> May </option>
                  <option> June </option>
                  <option> July </option>
                  <option> Augest</option>
                  <option> September </option>
                  <option> October </option>
                  <option> November </option>
                  <option> December </option>
                </select>
              </div>
              <div class="ml-auto"> <button mat-stroked-button class="bg-white top-back mt-1 mb-2 h-40 mr-2">Back</button></div>
            </div>
            <div class="col-md-12 file_manager">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-12">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12 ">
                  <div class="card">
                    <div class="file"> 
                      <a href="javascript:void(0);">
                        <div class="hover"> 
                          <button type="button" class="btn btn-icon btn-danger"> <i class="fa fa-trash"></i> </button>
                        </div>
                        <div class="file-name">
                          <p class=" text-muted"><i class="fa fa-file text-info mr-2"></i>Document_2017.doc</p> 
                          <small>Size: 42KB <span class="date text-muted">Nov 02, 2017</span></small>
                        </div> 
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
         
         </div> -->
         <!-- <div class="invoice-page">
          <div class="col-md-12">
            <div class="row">
              <div class="d-flex">
                <div class="head-section mt-3">Invoices</div>
                <div class="ml-auto">
                  
                </div>
              </div>
            </div>
          </div>
          <div class="invoice-section col-md-12 mt-2">
            <div class="row">
              <div class="col-md-3 pl-0">
                <div class="search">
                  <input  type="text" placeholder="Search" name="" class="search-input " >
                  <a  class="search-icon">
                    <i  class="fa fa-search"></i>
                  </a>
                </div>
              </div>
              <div class="col-md-3 ml-auto text-right">
                <span  class="filter-icon mr-2"><i  class="fa fa-filter"></i></span>
                <mat-form-field class="custom-border-date align-middle" appearance="outline">
                  <mat-label>Choose a date</mat-label>
                  <input matInput [matDatepicker]="picker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class=" card card-table mb-0 w-100">
                <div class="card-body p-0">
                <div class="table-responsive">
                <table class="table table-hover table-center mb-0">
                <thead>
                <tr>
                <th>Invoice No</th>
                <th>Clinician</th>
                <th>Amount</th>
                
                <th>Paid On</th>
                
                </tr>
                </thead>
                <tbody>
                <tr>
                <td>
                <a href="invoice-view.html">#INV-0010</a>
                </td>
                <td>
                <h6 class="table-avatar">
                
                Dr.Ruby Perrin 
                </h6>
                </td>
                <td>$450</td>
                
                <td>14 Nov 2019</td>
                
                </tr>
                <tr>
                <td>
                <a href="invoice-view.html">#INV-0009</a>
                </td>
                <td>
                <h6 class="table-avatar">
                
                Dr. Darren Elder 
                </h6>
                </td>
                <td>$300</td>
                
                <td>13 Nov 2019</td>
                
                </tr>
                
                
                
                
                
                
                
                
                </tbody>
                </table>
                </div>
                </div>
                </div>
            </div>
          </div>
         </div> -->

         <!-- invoice design
         <div style="position: relative; background-color: #fff; background-clip: border-box; border: 1px solid rgba(0,0,0,.125); border-radius: 0.25rem;padding:15px;">
          <table style="width: 100%; table-layout: fixed;">
             <tr>
                <td style="width: 50%; padding: 10px;"><img src="../../assets/images/brand-logo.jpg" alt="" class="p-0 m-0" style="width: 350px;"></td>
                <td style="text-align: right; width: 50%; padding: 10px;">
                   <h3><strong style="font-size: 15px; text-transform: uppercase; color: #666;">INVOICE #INV-0001</strong></h3>
                   <ul style="padding-left: 0; list-style: none;">
                      <li style="font-size: 13px; list-style: none;">Date: <span>October 12, 2017</span></li>
                   </ul>
                </td>
             </tr>
             <tr>
                <td style="width: 50%; padding: 10px;">
                   <ul style="padding-left: 0; list-style: none;">
                      <li>
                         <h5 style="font-size: 13px;"><strong>Jonathan</strong></h5>
                      </li>
                      <li><span style="font-size: 13px;">3897 Hickroy St, salt Lake city</span></li>
                      <li style="font-size: 13px;"> Utah, United States 84104</li>
                      <li style="font-size: 13px;"><i class="fas fa-mobile"></i> (937) 373-7322</li>
                      <li style="font-size: 13px;"><i class="fas fa-envelope"></i> Jackdxr@yopmail.com</li>
                      <li style="font-size: 13px;"><a href="#"> Jackdxr@yopmail.com</a></li>
                   </ul>
                </td>
                <td style="width: 50%; padding: 10px;">
                   <div>
                      <span style="font-size: 13px; color: #6c757d;">Payment Details:</span>
                      <ul style="padding-left: 0; list-style: none; font-size: 13px;">
                         <li>
                            <h5 class="fw-13"><strong>Total Due:</strong><span style="text-align: right; float: right; color: #666;"><strong>$288.2</strong></span></h5>
                         </li>
                         <li>Total number of payments: <span style="text-align: right; float: right; color: #666;">20</span></li>
                         <li>Treatment Start Date: <span style="text-align: right; float: right; color: #666;">09/04/22</span></li>
                      </ul>
                   </div>
                </td>
             </tr>
          </table>
          <table style="width: 100%; table-layout: fixed; font-size: 14px;">
             <thead>
                <tr>
                   <th style="vertical-align: bottom; border-bottom: 2px solid #dee2e6; padding: 0.75rem; border-top: 1px solid #dee2e6;">#</th>
                   <th style="vertical-align: bottom; border-bottom: 2px solid #dee2e6; padding: 0.75rem; border-top: 1px solid #dee2e6;">Therapy</th>
                   <th style="vertical-align: bottom; border-bottom: 2px solid #dee2e6; padding: 0.75rem; border-top: 1px solid #dee2e6;">Frequency</th>
                   <th style="vertical-align: bottom; border-bottom: 2px solid #dee2e6; padding: 0.75rem; border-top: 1px solid #dee2e6;">Payment Type</th>
                   <th style="vertical-align: bottom; border-bottom: 2px solid #dee2e6; padding: 0.75rem; border-top: 1px solid #dee2e6;">Paid on</th>
                   <th style="vertical-align: bottom; border-bottom: 2px solid #dee2e6; padding: 0.75rem; border-top: 1px solid #dee2e6;">Payment Amount</th>
                </tr>
             </thead>
             <tbody>
                <tr>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">1</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">Family Therapy</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">Per Session</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">Debit card</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">04/09/22</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">$150</td>
                </tr>
                <tr>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">2</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">Group Therapy</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">Per Session</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">Debit card</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">06/11/22</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">$12</td>
                </tr>
                <tr>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">3</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">Family Therapy</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">Per Session</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">Cash</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">06/12/22</td>
                   <td style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">$100</td>
                </tr>
             </tbody>
          </table>
          <table style="width: 100%; table-layout: fixed; font-size: 14px;">
             <tr>
                <td style="width: 60%; padding: 10px;"></td>
                <td style="width: 40%; padding: 10px;">
                   <table style="width: 100%; table-layout: fixed; font-size: 14px;">
                      <tbody>
                         <tr>
                            <th style="padding: 0.75rem;vertical-align: top;border-top: 1px solid #dee2e6;width: 50%;">
                               <h6>Total due:</h6>
                            </th>
                            <td style="text-align: left;font-size: 14px;padding: 0.75rem;vertical-align: top;border-top: 1px solid #dee2e6;width: 50%;"></td>
                         </tr>
                         <tr>
                            <th style="padding: 0.75rem;vertical-align: top;border-top: 1px solid #dee2e6;width: 50%;">Subtotal:</th>
                            <td style="text-align: left;font-size: 14px;padding: 0.75rem;vertical-align: top;border-top: 1px solid #dee2e6;width: 50%;">$262</td>
                         </tr>
                         <tr>
                            <th style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">Tax: <span class="text-regular">(10%)</span></th>
                            <td style="text-align: left;font-size: 14px;padding: 0.75rem;vertical-align: top;border-top: 1px solid #dee2e6;">$26.2</td>
                         </tr>
                         <tr>
                            <th style="padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6;">Total:</th>
                            <td style="text-align: left;font-size: 14px;padding: 0.75rem;vertical-align: top;border-top: 1px solid #dee2e6;">
                               <h5 style="font-size: 14px; color: #007bff;"><strong>$288.2</strong></h5>
                            </td>
                         </tr>
                      </tbody>
                   </table>
                </td>
             </tr>
          </table>
          <div style="width:100%;font-size:14px;">
            <h5 style="width:100%;font-size:14px;">Other information</h5>
            <p style="color:#6c757d">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed dictum ligula, cursus blandit risus. Maecenas eget metus non tellus dignissim aliquam ut a ex. Maecenas sed vehicula dui, ac suscipit lacus. Sed finibus leo vitae lorem interdum, eu scelerisque tellus fermentum. Curabitur sit amet lacinia lorem. Nullam finibus pellentesque libero, eu finibus sapien interdum vel</p>
            </div>
       </div> 
      invoice design end-->
      <!-- <div class="role-permission">
        <div class="permission-container " > 
          <div  class="permis-heading" >Intake <i class="fas fa-chevron-down ml-2"></i></div>
          <div class="expand-table" >
            <div class="table-reponsive pt-2">
              <table class="table table-custom text-center table-history"> 
                <thead>
                  <tr>
                    <th>Accounts</th> 
                    <th>Create</th>
                    <th>Edit/Reschedule  </th>
                    <th>Delete</th>
                    <th>View</th>
                    <th>Approve/Reject  </th>
                    <th>Signature </th>
                  </tr>
                 
    
    
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Lead Account
                    </td>
                    <td><div class="check-custom ">
                      <mat-checkbox class="example-margin" >
                      </mat-checkbox>
                  </div>
                      
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Patient Account
                    </td>
                    <td><div class="check-custom ">
                      <mat-checkbox class="example-margin" >
                      </mat-checkbox>
                  </div>
                      
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                  </tr>
                  
                </tbody>
    
              </table>
            </div>
          </div>
        </div>
        <div class="permission-container " >
          <div  class="permis-heading">Intake <i class="fas fa-chevron-down ml-2"></i></div>
          <div class="expand-table" >
            <div class="table-reponsive pt-2">
              <table class="table table-custom text-center table-history">
                <thead>
                  <tr>
                    <th>Accounts</th>
                    <th>Create</th>
                    <th>Edit/Reschedule  </th>
                    <th>Delete</th>
                    <th>View</th>
                    <th>Approve/Reject  </th>
                    <th>Signature </th>
                  </tr>
                 
    
    
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Lead Account
                    </td>
                    <td><div class="check-custom ">
                      <mat-checkbox class="example-margin" >
                      </mat-checkbox>
                  </div>
                      
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Patient Account
                    </td>
                    <td><div class="check-custom ">
                      <mat-checkbox class="example-margin" >
                      </mat-checkbox>
                  </div>
                      
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                    <td>
                      <div class="check-custom ">
                        <mat-checkbox class="example-margin" >
                        </mat-checkbox>
                    </div>
                    </td>
                  </tr>
                  
                </tbody>
    
              </table>
            </div>
          </div>
        </div>
      
      </div> -->

      <!-- admin-profile -->


      <!-- <div class="profile-page">
        <div  class="top-btn-section col-md-12 clearfix mb-1 w-100">
          <h2  class="head-section mt-3">Profile Information</h2>
          <div  class="col-md-6 ml-auto mt-2 pr-0">
            </div>
          </div>
        <div class="col-md-12">
        <div class="row">
            
          <div class="col-lg-3  col-md-5">
            <div class="card">
              <div class="card-body profile-card">
                <div class="mt-4 text-center">
                  <img src="../../../assets/images/avator.jpg" class="rounded-circle" width="150">
                  <h4 class="card-title mt-2">Hanna Gover</h4>
                  <h6 class="card-subtitle">Admin</h6>
                  <label class="custom-files">Upload profile Picture<input type="file" class="custom-file-input"></label>
                  
                </div>
              </div>
            </div>
          </div>
          
          
          <div class="col-lg-9  col-md-7">
            <div class="card">
              <div class="card-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6 border-right">
                      <form class="form-horizontal form-material mx-2">
                        <div class="form-group">
                          <label class="col-md-12 ">First Name</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="First Name" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-md-12 ">Last Name</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="Last Name" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="example-email" class="col-md-12 ">Email</label>
                          <div class="col-md-12">
                            <input type="email" placeholder="Email" class="form-control ps-0 form-control-line" name="example-email" id="example-email">
                          </div>
                        </div>
                        
                        <div class="form-group">
                          <label class="col-md-12 ">Role</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="Role" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-md-12 ">Primary Facility</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="Primary Facility" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                       
                       
                        <div class="form-group">
                          <div class="col-sm-12 d-flex">
                            <button class="btn btn-success btn-theme ml-auto  text-white">
                              Save Profile
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="col-md-6">
                      <form class="form-horizontal form-material mx-2">
                        <div class="form-group">
                          <label class="col-md-12">User Name</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="User Name" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-md-12 ">Password</label>
                          <div class="col-md-12">
                            <input type="password" value="password" class="form-control ps-0 form-control-line">
                          </div>
                        </div> 
                        <div class="form-group">
                          <div class="col-sm-12 d-flex">
                            <button class="btn btn-success btn-theme ml-auto  text-white">
                             Change Password
                            </button>
                          </div>
                        </div>
                        </form>
                    </div>
                  
                </div>
                </div>
                
              </div>
            </div>
          </div>
          
        </div>
      </div>
      </div> -->


      <!-- Clinician-profile -->
<!--       
      <div class="profile-page">
        <div  class="top-btn-section col-md-12 clearfix mb-1 w-100">
          <h2  class="head-section mt-3">Profile Information</h2>
          <div  class="col-md-6 ml-auto mt-2 pr-0">
            </div>
          </div>
        <div class="col-md-12">
        <div class="row">
            
          <div class="col-lg-3  col-md-5">
            <div class="card">
              <div class="card-body profile-card">
                <div class="mt-4 text-center">
                  <img src="../../../assets/images/avator.jpg" class="rounded-circle" width="150">
                  <h4 class="card-title mt-2">Nancy cruz</h4>
                  <h6 class="card-subtitle">Clinician</h6>
                  <label class="custom-files">Upload profile Picture<input type="file" class="custom-file-input"></label>
                  
                </div>
              </div>
            </div>
          </div>
          
          
          <div class="col-lg-9  col-md-7">
            <div class="card">
              <div class="card-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6 border-right">
                      <form class="form-horizontal form-material mx-2">
                        <div class="form-group">
                          <label class="col-md-12 ">First Name</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="First Name" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-md-12 ">Last Name</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="Last Name" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="example-email" class="col-md-12 ">Email</label>
                          <div class="col-md-12">
                            <input type="email" placeholder="Email" class="form-control ps-0 form-control-line" name="example-email" id="example-email">
                          </div>
                        </div>
                        
                        <div class="form-group">
                          <label class="col-md-12 ">Role</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="Role" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-md-12 ">Primary Facility</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="Primary Facility" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-md-12 ">License Type</label>
                          <div class="col-md-12">
                            <select class="form-control ps-0 form-control-line">
                              <option>Type1</option>
                              <option>Type2</option>
                              <option>Type3</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-md-12 ">License Number</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="License Number" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                       
                        <div class="form-group">
                          <div class="col-sm-12 d-flex">
                            <button class="btn btn-success btn-theme ml-auto  text-white">
                              Save Profile 
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="col-md-6">
                      <form class="form-horizontal form-material mx-2">
                        <div class="form-group">
                          <label class="col-md-12">User Name</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="User Name" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-md-12 ">Password</label>
                          <div class="col-md-12">
                            <input type="password" value="password" class="form-control ps-0 form-control-line">
                          </div>
                        </div> 
                        <div class="form-group">
                          <div class="col-sm-12 d-flex">
                            <button class="btn btn-success btn-theme ml-auto  text-white">
                             Change Password
                            </button>
                          </div>
                        </div>
                        </form>
                    </div>
                 
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          
        </div>
      </div>
      </div> -->
      
       <!-- Client-profile -->
      
       <!-- <div class="profile-page">
        <div  class="top-btn-section col-md-12 clearfix mb-1 w-100">
          <h2  class="head-section mt-3">Profile Information</h2>
          <div  class="col-md-6 ml-auto mt-2 pr-0">
            </div>
          </div>
        <div class="col-md-12">
        <div class="row">
            
          <div class="col-lg-3  col-md-5">
            <div class="card">
              <div class="card-body profile-card">
                <div class="mt-4 text-center">
                  <img src="../../../assets/images/avator.jpg" class="rounded-circle" width="150">
                  <h4 class="card-title mt-2">Churchil</h4>
                  
                  <label class="custom-files">Upload profile Picture<input type="file" class="custom-file-input"></label>
                  
                </div>
              </div>
            </div>
          </div>
          
          
          <div class="col-lg-9  col-md-7">
            <div class="card">
              <div class="card-body">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6 border-right">
                      <form class="form-horizontal form-material mx-2">
                        <div class="form-group">
                          <label class="col-md-12 ">First Name</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="First Name" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-md-12 ">Last Name</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="Last Name" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        
                        <div class="form-group">
                          <label for="example-email" class="col-md-12 ">Email</label>
                          <div class="col-md-12">
                            <input type="email" placeholder="Email" class="form-control ps-0 form-control-line" name="example-email" id="example-email">
                          </div>
                        </div>
                        
                        
                        <div class="form-group">
                          <label class="col-md-12 mb-0">Address</label>
                          <div class="col-md-12">
                            <textarea rows="5" class="form-control ps-0 form-control-line"></textarea>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="col-sm-12 d-flex">
                            <button class="btn btn-success btn-theme ml-auto  text-white">
                              Save Profile
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div class="col-md-6">
                      <form class="form-horizontal form-material mx-2">
                        <div class="form-group">
                          <label class="col-md-12">User Name</label>
                          <div class="col-md-12">
                            <input type="text" placeholder="User Name" class="form-control ps-0 form-control-line">
                          </div>
                        </div>
                        <div class="form-group">
                          <label class="col-md-12 ">Password</label>
                          <div class="col-md-12">
                            <input type="password" value="password" class="form-control ps-0 form-control-line">
                          </div>
                        </div> 
                        <div class="form-group">
                          <div class="col-sm-12 d-flex">
                            <button class="btn btn-success btn-theme ml-auto  text-white">
                             Change Password
                            </button>
                          </div>
                        </div>
                        </form>
                    </div>
                 
                  </div>
                </div>
                
              </div>
            </div>
          </div>
          
        </div>
      </div>
      </div> -->



       <!-- <div class="change_pas_after_login ">
        <div  class="top-btn-section col-md-12 clearfix mb-1 w-100">
          <h2  class="head-section mt-3">Change Password</h2>
          <div  class="col-md-6 ml-auto mt-2 pr-0">
            </div>
          </div>
        <div class="col-md-12">
          <div class="card pt-4 ">
            <form class="form-horizontal form-material mx-2 col-md-4">
              
              <div class="form-group">
                <label class="col-md-12 ">Old Password</label>
                <div class="col-md-12">
                  <input type="password" value="password" class="form-control ps-0 form-control-line">
                </div>
              </div> 
              <div class="form-group">
                <label class="col-md-12 ">New Password</label>
                <div class="col-md-12">
                  <input type="password" value="password" class="form-control ps-0 form-control-line">
                </div>
              </div> 
              <div class="form-group">
                <label class="col-md-12">Confirm Password</label>
                <div class="col-md-12">
                  <input type="password" value="password" class="form-control ps-0 form-control-line">
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-12 d-flex">
                  <button class="btn btn-success btn-theme ml-auto  text-white">
                   Update Password
                  </button>
                </div>
              </div> 
              </form>
          </div>
        </div>
          
       </div> -->
       <!-- <div class="documents">
        <div  class="col-md-12 p-0">
          <div  class="d-flex w-100">
            <div  class="col-md-3 p-0">
              <div  class="search">
                <input  type="text" placeholder="Search By Patient " name="txt" class="search-input " >
                <a  class="search-icon">
                  <i  class="fa fa-search"></i>
                </a>
              </div>
            </div>
            <div  class="col-md-5 mt-2">
              <span  class="filter-icon mr-2"><i  class="fa fa-filter"></i></span>
              <div  class="filter-group d-inline-block">
                <label  class="d-inline-block mr-2">Status</label>
                <select  name="clinician" class="form-control d-inline-block status-filter mr-0 "  >
                  <option  >Discharge</option>
                  <option    class=""> Inprogrss </option>
                  </select>
                </div>
              
                </div>
                <div  class="ml-auto">
                  
                  <button  mat-stroked-button="" class=" bg-white top-back mt-1 mb-2 h-40 mr-2 " tabindex="0" >
                 Back</button>
                  </div>
                </div>
              </div>
              <div class="col-md-12 card ">
                <div class="doc_table table-responsive">
                  <table class="table ">
                    <thead>
                      <tr>
                        
                        <th style="width:250px" class="  text-xs">
                          Patient Name
                        </th>
                        <th class="  text-xs">
                            Status
                        </th>
                      </tr>
                      
                    </thead>
                    <tbody>
                      <tr>
                        <td class="  text-xs"><a href="#">Aaron Aaiden</a></td>
                        <td class="  text-xs">Inprogress</td>
                      </tr>
                      <tr>
                        <td class="  text-xs"><a href="#">Aaron Aaiden</a></td>
                        <td class="  text-xs">Inprogress</td>
                      </tr>
                      <tr>
                        <td class="  text-xs"><a href="#">Aaron Aaiden</a></td>
                        <td class="  text-xs">Inprogress</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
       </div> -->
       <!-- <div class="Document_page">
        <div class="d-flex">
          <div class="head-section mt-3 mb-2">Documents</div>
        </div>
        <div class="drive-wrapper drive-list-view">
          <div class="table-responsive drive-items-table-wrapper">
            <table class="table">
              <thead>
                <tr>
                  
                  <th class="name truncate">Name</th>
                  
                  <th class="date">Submitted Date</th>
                  <th class="size">Size</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  
                  <td class="name truncate"><a href="#">Meeting Notes.txt</a></td>
              
                  <td class="date">Sep 23, 2015</td><td class="size">18 KB</td>
                  <td><i  class="fa fa-download"></i></td>
                </tr>
                <tr>
                  
                  <td class="name truncate"><a href="#">Stock Image DC3214.JPG</a></td>
                 
                  <td class="date">Sep 21, 2015</td><td class="size">235 MB</td>
                  <td><i  class="fa fa-download"></i></td>
                </tr>
                <tr>
                 
                  <td class="name truncate"><a href="#">Deck Lorem Ipsum.ppt</a></td>
                
                  <td class="date">Sep 20, 2015</td><td class="size">136 MB</td>
                  <td><i  class="fa fa-download"></i></td>
                </tr>

                  <tr>
                  
                    <td class="name truncate"><a href="#">Project Tasks.csv</a></td>
                 
                    <td class="date">Aug 16, 2015</td><td class="size">32 KB</td>
                    <td><i  class="fa fa-download"></i></td>
                  </tr>
                  <tr>
                    
                    <td class="name truncate"><a href="#">Project Brief.pdf</a></td>
                  
                    <td class="date">Aug 15, 2015</td>
                    <td class="size">73 MB</td>
                    <td><i  class="fa fa-download"></i></td>
                  </tr>
                  <tr>
                    
                    <td class="name truncate"><a href="#">Image DS1341.JPG</a></td>
                   
                    <td class="date">Aug 15, 2015</td><td class="size">171 MB</td>
                    <td><i  class="fa fa-download"></i></td>
                  </tr>
                  <tr>
                   
                    <td class="name truncate"><a href="#">Image DS3214.JPG</a></td>
                   
                    <td class="date">Aug 15, 2015</td><td class="size">171 MB</td>
                    <td><i  class="fa fa-download"></i></td>
                  </tr>
                  <tr>
                  
                    <td class="name truncate"><a href="#">UX Resource</a></td>
                    
                    <td class="date">Feb 07, 2015</td><td class="size">--</td>
                    <td><i  class="fa fa-download"></i></td>
                  </tr>
                  <tr>
                    
                    <td class="name truncate"><a href="#">Prototypes</a></td>
                  
                    <td class="date">Jan 03, 2015</td>
                    <td class="size">--</td>
                    <td><i  class="fa fa-download"></i></td>
                  </tr>
                  <tr>
                    
                    <td class="name truncate"><a href="#">Quisque.doc</a></td>
                  
                    <td class="date">Oct 21, 2014</td>
                    <td class="size">27 KB</td>
                    <td><i  class="fa fa-download"></i></td>
                  </tr>
                  <tr>
                   
                    <td class="name truncate"><a href="#">Aenean imperdiet.doc</a></td>
                   
                    <td class="date">Oct 16, 2014</td><td class="size">23 KB</td>
                    <td><i  class="fa fa-download"></i></td>
                  </tr>
                  <tr>
                    
                    <td class="name truncate"><a href="#">demo.html</a></td>
                    
                    <td class="date">Aug 23, 2014</td>
                    <td class="size">10 KB</td>
                    <td><i  class="fa fa-download"></i></td>
                  </tr>
                 
                </tbody></table></div></div>
       </div> -->

      
       <!-- <div class="container-fluid">
        <div class="row">
          <div class="col-sm-3">
            <div class="card p-4 fixed-left-header">
              <div class="tabs-panel">
                <h6 class="doc-head"><i class="fa fa-file mr-2"></i> Documents</h6>
                
                <div class="clear"></div>
              </div>
              <div class="panel-body tab-content">
                <div class="tab-pane documents-panel active">
                
                     <div class="clear"></div>
                     <div class="v-spacing-xs"></div><h4 class="no-margin-top"> Categories</h4>
                     <ul class="folders list-unstyled">
                      <li class="active"> <a href="#"> <i class="fa fa-folder mr-2"></i> Intake Documents </a></li>
                      <li> <a href="#"> <i class="fa fa-folder mr-2"></i> Treatment Plan Documents </a></li>
                      <li> <a href="#"> <i class="fa fa-folder mr-2"></i> Appointments Documents</a></li>
                     
                    </ul>
                    <div class="v-spacing-xs"></div> 
                    </div>
                    </div></div></div>
                    <div class="col-sm-9 tab-content no-bg no-border">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                          <div class="card">
                            <div class="file h-auto p-2">
                               <div class="document-view-list">
                                <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Intake Packet.doc<a href="#" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                  <p class="w-100 mb-0">
                                    <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                  </p> 
                                  </div> 
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                            <div class="card">
                              <div class="file h-auto p-2">
                                 <div class="document-view-list">
                                  <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Pain Assessment.doc<a href="#" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                    <p class="w-100 mb-0">
                                      <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                    </p> 
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                              <div class="card">
                                <div class="file h-auto p-2">
                                   <div class="document-view-list">
                                    <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Nutritional Question.doc<a href="#" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                      <p class="w-100 mb-0">
                                        <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                      </p> 
                                      </div> 
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                                <div class="card">
                                  <div class="file h-auto p-2">
                                     <div class="document-view-list">
                                      <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Prepare CHMA.doc<a href="#" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                        <p class="w-100 mb-0">
                                          <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                        </p> 
                                        </div> 
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 mb-2">
                                  <div class="card">
                                    <div class="file h-auto p-2">
                                       <div class="document-view-list">
                                        <div class="file-name"><p class="mb-2 text-muted position-relative file-truncate-name">Suicidal Risk Assessment.doc<a href="#" class="fil-name"><i  class="fa fa-download mr-2 "></i>Download</a></p>
                                          <p class="w-100 mb-0">
                                            <small class="w-100">Size: 89KB <span class="date text-muted float-right">Dec 15, 2017</span></small>
                                          </p> 
                                          </div> 
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                 
                      </div>
                      </div></div></div> -->


<!-- schedule appointments -->

<!-- <div class="scheduleAppointemnts">
  <div  class="top-btn-section col-md-12 p-0 clearfix mb-1 w-100">
    <div  class="head-section mt-2"> Schedule Appointments</div>
    <div  class="ml-auto">
      <button mat-stroked-button class="mat-focus-indicator top-back mb-2 h-40 mr-2 " >Back</button>
    </div>
  </div>
  <div class="large-search-custom" >
    <div class="col-md-12">
      <div  class="search">
    
        <input  type="text" placeholder="Search By Patient"  name="" class="search-input " ><a  class="search-icon"><i  class="fa fa-search"></i></a></div>
        <ul id="myUL" style="display:none">
         <li >
           <a  style="cursor: pointer;"></a></li>
       </ul> 
        </div>
  </div>
  <div class="col-md-12 mt-3 border p-2">
    <div class="row">
      <div class="col-md-2">
        <div class="d-flex align-items-center">
          <div class="image"><img src="https://bootdey.com/img/Content/avatar/avatar7.png" width="60" class="rounded">
          </div>
          <div class="ml-3 w-100 d-flex">
            <div class="mr-3 mt-3">
              <h5 class="mb-0 mt-0 text-md">Hendry Will</h5>
              <span class="text-xs text-dark font-weight-500">12 Years</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 bg-white pt-4  pb-2 "> 
    <h2 class="mb-1 sub-inc">Create Schedule Appointment</h2>
    <div class="row ">
       <div class="col-md-12  p-3">
          <form 
             autocomplete="off"  novalidate>

             <div class="mb-2 d-flex justify-content-center">
                <div class="card p-3 w-100">
                   <div class="">

                      <div class="col-md-12 mb-3">
                         <div class="row">
                            <div class="col-md-4 mb-2">
                               <div class="row">
                                  <label class="control-label-new col-md-3 font-weight-bold mt-2">Provider</label>
                                  <div class="col-md-9">
                                     <select class="form-control" name="Provider"
                                         name="provider"
                                        required>
                                        <option value="undefined">--Select Provider--</option>
                                        
                                     </select>
                                  </div>
                               </div>
                            </div>
                            <div class="col-md-4 mb-2 ">
                              <div class="row">
                                 <label class="control-label-new col-md-3 font-weight-bold mt-2">Therapy</label>
                                 <div class="col-md-9">

                                     <select class="form-control"   name="meetingTypeId" required>
                                       <option value="undefined">--Select Therapy--</option>
                                       
                                   </select>
                                  
                                </div>
                              </div>
                           </div>
                          
                            <div class="col-md-4 mb-2">
                               <div class="row">
                                  <label class="control-label-new col-md-3 font-weight-bold mt-2">Start Date</label>
                                  <div class="col-md-9">

                                    
                                     <mat-form-field class="example-full-width" class="w-100  mt--14">
                                        <mat-label>Choose a date</mat-label>
                                        <input required matInput [matDatepicker]="picker2" readonly name="startDate" />
                                        <mat-datepicker-toggle matSuffix [for]="picker2">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                       
                                     </mat-form-field>

                                  </div>
                               </div>
                            </div>

                            <div class="col-md-4 mb-2">
                               <div class="row">
                                  <label class="control-label-new col-md-3 font-weight-bold mt-2">Start Time</label>
                                  <div class="col-md-9 pb-3">

                                     <input class="form-control" type="time" 
                                        
                                        required>
                                    
                                  </div>
                                 
                               </div>
                            </div>
                            <div class="col-md-4 mb-2">
                               <div class="row">
                                  <label class="control-label-new col-md-3 font-weight-bold mt-2">Duration</label>
                                  <div class="col-md-9">

                                     <select class="form-control" name="duration" required>
                                        <option value="undefined">--Select Duration--</option>
                                        <option value=15>15 mins</option>
                                        <option value=30>30 mins</option>
                                        <option value=60>1 hr</option>
                                        <option value=120>2 hr</option>
                                        <option value=180>3 hr</option>
                                        <option value=240>4 hr</option>
                                        <option value=300>5 hr</option>
                                        <option value=360>6 hr</option>
                                        <option value=420>7 hr</option>
                                        <option value=480>8 hr</option>
                                     </select>
                                  
                                  </div>
                               </div>
                            </div>
                            <div class="col-md-4 mb-2">
                               <div class="row">
                                  <label class="control-label-new col-md-3 font-weight-bold mt-2">Location</label>
                                  <div class="col-md-9">

                                     <select class="form-control"  name="location" required>
                                        <option value="undefined">--Select Location--</option>
                                       
                                     </select>
                                   
                                  </div>
                               </div>
                            </div>
                            <div class="col-md-4 mb-2">
                               <div class="row">
                                  <label class="control-label-new col-md-3 font-weight-bold mt-2">Meeting Room</label>
                                  <div class="col-md-9">

                                     <select class="form-control"  name="meetingRoom" required>
                                        <option value="undefined">--Select Location--</option>
                                        
                                     </select>
                                     
                                  </div>
                               </div>
                            </div>
                            <div class="col-md-4 mb-2 ">
                               <div class="row">
                                  <label class="control-label-new col-md-3 font-weight-bold mt-2">Meeting Type</label>
                                  <div class="col-md-9">

                                      <select class="form-control"   name="meetingTypeId" required>
                                        <option value="undefined">--Select Meeting Type--</option>
                                        
                                    </select>
                                   
                                 </div>
                               </div>
                            </div>
                            <div class="col-md-4 mb-2 ">
                              <div class="row">
                                 <label class="control-label-new col-md-3 font-weight-bold mt-2">Clinician</label>
                                 <div class="col-md-9">
  
                                     <select class="form-control"   name="meetingTypeId" required>
                                       <option value="undefined">--Select Clinician--</option>
                                       
                                   </select>
                                  
                                </div>
                              </div>
                           </div>
                           
                            
                         </div>
                      </div>


                   </div>
                   
                </div>
             </div>

             <div class="col-md-12">
                <div class="row">

                   <div class="col-md-12 text-right mt-2 pr-0 mt-3" >
                      <button class="btn primary-btn-filled float-right " type="submit"
                         form="scheduleModality">Generate Schedule</button>
                      <button class="btn btn-cancel primary-btn-m h-40 mr-2" type="button"
                         >Cancel</button>
                   </div> -->
                   <!-- <div class="col-md-12 p-0"  >
                      <div class="col-md-12 p-0">
                         <h2>Schedule List</h2>
                         <div class="schedule-summary">
                            Schedule for having  Meetings from
                            till 
                         </div>
                         <div class="table-responsive">
                            <form 
                               id="scheduleConflictModality" autocomplete="off" 
                               novalidate>
                               <table class="table modality-table">
                                  <thead>
                                     <tr>
                                      
                                        <th scope="col">#</th>
                                       
                                        <th scope="col">Schedule Date</th>
                                        <th scope="col">Start Time</th>
                                        <th scope="col">End Time</th>
                                        <th scope="col"></th>
                                     </tr>
                                  </thead>
                                  <tbody>
                                     <ng-container >
                                        <tr>
                                           <td>
                                              <input type="checkbox" />
                                           </td>
                                           
                                          
                                           <td></td>
                                           <td></td>
                                           <td></td>
                                           <td><button mat-icon-button matTooltip="Delete" role="button" 
                                                 color="warn">
                                                 <img src="../../../assets/images/deletered.png">
                                              </button></td>
                                        </tr>
                                     </ng-container>
                                  </tbody>
                               </table>
                            </form>
                         </div>
                      </div>
                      <div class="col-md-12 text-right mt-2" >
                         <button class="btn primary-btn-filled-small float-right ml-2" type="button"
                            >Create </button>
                         <button class="btn primary-btn-filled-small float-right" type="button"
                            ><i class="fas fa-forward mr-2"></i> Skip All
                            Conflicts</button>
                         <button class="btn primary-btn-filled-small float-right mr-2" type="button"
                            ><i class="fas fa-forward mr-2"></i> Skip</button>

                      </div>
                   </div> -->
                   <!-- <div class="col-md-12 p-0"  >
                      <mat-error class="mat-form-field-subscript-wrapper">
                         Please skip the Conflicts
                      </mat-error>
                   </div> -->
                   
                  
                <!-- </div>
             </div>


          </form>
       </div>

    </div>
 </div>

</div> -->
<div class="doc_patient">
  <div  class="col-md-12 ">
    <div  class="row">
      <div class="col-md-9">
        <h2  class="head-section mt-3">Document</h2>
      </div>
      <div  class="col-md-3 ">
        <button  tabindex="0" class="btn back-btn-new mr-3">
          <i class="fas fa-chevron-left mr-2"></i>Back</button>
       
      </div>
      <!-- <div  class="col-md-3 ">
        <div  class="search">
          <input  type="text" placeholder="Search By Email ID " name="txt" class="search-input " >
          <a  class="search-icon">
            <i  class="fa fa-search"></i>
          </a>
        </div>
      </div> -->
        <!-- <button mat-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        
        <mat-menu #menu="matMenu">
          <button mat-menu-item><mat-icon>home</mat-icon><span>Home</span></button>
        </mat-menu> -->
      
</div>
</div>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3">
      <div class="card p-4 ">
        <div  class="search border mb-4">
          <input  type="text" placeholder="Search By Client " name="txt" class="search-input " >
          <a  class="search-icon">
            <i  class="fa fa-search"></i>
          </a>
        </div>
      <div  class="tabs-panel"><h6 class="doc-head mb-2"> Client Name</h6><div  class="clear"></div></div>
      <ul class="folders list-unstyled">
        <li class="active"> 
          <a  href="#" >
            <div class="col-md-12">
            <div  class="row">
              <div  class="left-side col-md-2">
                <span  class="gender-img">
                  <i  class="fas fa-user mt-2"></i>
                </span>
              </div>
              <div  class="right-side col-md-10 pl-0">
                <div  class="text-md text-truncate font-weight-500">John</div>
            <div  class="text-xs text-truncate">adamjames@yopmail.com</div>
          </div>
        </div>
        </div>
       </a>
        </li>
        <li> <a  href="#" >
          <div class="col-md-12">
          <div  class="row">
            <div  class="left-side col-md-2">
              <span  class="gender-img">
                <i  class="fas fa-user mt-2"></i>
              </span>
            </div>
            <div  class="right-side col-md-10 pl-0">
              <div  class="text-md text-truncate font-weight-500">John</div>
          <div  class="text-xs text-truncate">jonbdd3@yopmail.com</div>
        </div>
      </div>
      </div>
     </a></li>
        <li> <a  href="#" >
          <div class="col-md-12">
          <div  class="row">
            <div  class="left-side col-md-2">
              <span  class="gender-img">
                <i  class="fas fa-user mt-2"></i>
              </span>
            </div>
            <div  class="right-side col-md-10 pl-0">
              <div  class="text-md text-truncate font-weight-500">John</div>
          <div  class="text-xs text-truncate">rasel_john77@yopmail.com</div>
        </div>
      </div>
    </div>
     </a></li>
       
      </ul>
    </div>
    </div>
    <div class="col-md-9 pl-0">
      <div class="card p-4 ">
        <div class="d-flex mb-4">
          <div class="">
           
            <!-- <div class="filter-group d-inline-block">
              <label  class="d-inline-block mr-2">Select Categories</label>
              <select  name="Meeting" class="form-control d-inline-block w-auto " >
                <option >--Select --</option>
                <option>Intake documents</option></select>
              </div> -->
              <div class="d-inline-block">
                <div class="ml-2"><span  class="status-patient text-md"> Program:</span>
                  <span  class="patient-info-value text-md text-dark ml-2">IOP</span></div>
              </div>
          </div>
          <div class="ml-auto">
            <button  mat-type="button" class="export-pdf-btn btn mr-0"><i  class="fa fa-share mr-2"></i>Share All</button>
            <button  mat-type="button" class="export-pdf-btn btn mr-2"><i  class="fa fa-print mr-2"></i>Print All</button>
            
          </div>
        </div>
        <div class="col-md-12 p-0">
          <div class="row">
          <div class="col-md-12 ">
            <mat-tab-group>
              <mat-tab label="Intake documents "> 
                <div class="doc_table mt-3">
                  <div class="table-responsive">
                    <table class="table align-items-center ">
                      <thead class="">
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Document Name</th>
                          <th scope="col">Created by</th>
                          
                         
                          <th scope="col">Action</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1
                          </td>
                          <td>
                            Individual_Therapy__2009-12-31__23-59-59
                          </td>
                          <td>
                            Zoya D
                          </td>
                       
                          
                          <td>
                            <button mat-button [matMenuTriggerFor]="menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            
                            <mat-menu #menu="matMenu">
                              <button mat-menu-item><span>Preview</span></button>
                              <button mat-menu-item><span>Print</span></button>
                              <button mat-menu-item><span>Download</span></button>
                              <button mat-menu-item><span>Share</span></button>
                            </mat-menu> 
                          </td>
                          </tr>
                          <tr>
                            <td>
                              2
                            </td>
                            <td>
                              Family_Therapy__2010-08-23__23-59-59
                            </td>
                            <td>
                              Issac N
                            </td>
                           
                            <td>
                              <button mat-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                              </button>
                              
                              <mat-menu #menu="matMenu">
                                <button mat-menu-item><span>Preview</span></button>
                                <button mat-menu-item><span>Print</span></button>
                                <button mat-menu-item><span>Download</span></button>
                                <button mat-menu-item><span>Share</span></button>
                              </mat-menu> 
                            </td>
                            </tr>
                            <tr>
                              <td>
                                3
                              </td>
                              <td>
                                Individual_Therapy__2022-09-26__23-59-59
                              </td>
                              <td>
                                Zoya D
                              </td>
                              
                            
                              <td>
                                <button mat-button [matMenuTriggerFor]="menu">
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                
                                <mat-menu #menu="matMenu">
                                  <button mat-menu-item><span>Preview</span></button>
                                  <button mat-menu-item><span>Print</span></button>
                                  <button mat-menu-item><span>Download</span></button>
                                  <button mat-menu-item><span>Share</span></button>
                                </mat-menu> 
                              </td>
                              </tr>
                              <tr>
                                <td>
                                  4
                                </td>
                                <td>
                                  Individual_Therapy__2009-12-31__23-59-59
                                </td>
                                <td>
                                  Zoya D
                                </td>
                             
                               
                                <td>
                                  <button mat-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                  </button>
                                  
                                  <mat-menu #menu="matMenu">
                                    <button mat-menu-item><span>Preview</span></button>
                                    <button mat-menu-item><span>Print</span></button>
                                    <button mat-menu-item><span>Download</span></button>
                                    <button mat-menu-item><span>Share</span></button>
                                  </mat-menu> 
                                </td>
                                </tr>
                                <tr>
                                  <td>
                                    5
                                  </td>
                                  <td>
                                    Family_Therapy__2010-08-23__23-59-59
                                  </td>
                                  <td>
                                    Issac N
                                  </td>
                                  
                                  <td>
                                    <button mat-button [matMenuTriggerFor]="menu">
                                      <mat-icon>more_vert</mat-icon>
                                    </button>
                                    
                                    <mat-menu #menu="matMenu">
                                      <button mat-menu-item><span>Preview</span></button>
                                      <button mat-menu-item><span>Print</span></button>
                                      <button mat-menu-item><span>Download</span></button>
                                      <button mat-menu-item><span>Share</span></button>
                                    </mat-menu> 
                                  </td>
                                  </tr>
                                  <tr>
                                    <td>
                                     6
                                    </td>
                                    <td>
                                      Individual_Therapy__2022-09-26__23-59-59
                                    </td>
                                    <td>
                                      Zoya D
                                    </td>
                                  
                                    <td>
                                      <button mat-button [matMenuTriggerFor]="menu">
                                        <mat-icon>more_vert</mat-icon>
                                      </button>
                                      
                                      <mat-menu #menu="matMenu">
                                        <button mat-menu-item><span>Preview</span></button>
                                        <button mat-menu-item><span>Print</span></button>
                                        <button mat-menu-item><span>Download</span></button>
                                        <button mat-menu-item><span>Share</span></button>
                                      </mat-menu> 
                                    </td>
                                    </tr>
                          </tbody>
                          </table>
                </div>
              </div>
                
              </mat-tab>
              <mat-tab label="Treatment Plan Documents"> 
                <div class="doc_table mt-3">
                  <div class="table-responsive">
                    <table class="table align-items-center ">
                      <thead class="">
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Document Name</th>
                          <th scope="col">Created by</th>
                          <th scope="col">Action</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1
                          </td>
                          <td>
                            Individual_Therapy__2009-12-31__23-59-59
                          </td>
                          <td>
                            Zoya D
                          </td>
                       
                         
                          <td>
                            <button mat-button [matMenuTriggerFor]="menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            
                            <mat-menu #menu="matMenu">
                              <button mat-menu-item><span>Preview</span></button>
                              <button mat-menu-item><span>Print</span></button>
                              <button mat-menu-item><span>Download</span></button>
                              <button mat-menu-item><span>Share</span></button>
                            </mat-menu> 
                          </td>
                          </tr>
                          <tr>
                            <td>
                              2
                            </td>
                            <td>
                              Family_Therapy__2010-08-23__23-59-59
                            </td>
                            <td>
                              Issac N
                            </td>
                           
                          
                            <td>
                              <button mat-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                              </button>
                              
                              <mat-menu #menu="matMenu">
                                <button mat-menu-item><span>Preview</span></button>
                                <button mat-menu-item><span>Print</span></button>
                                <button mat-menu-item><span>Download</span></button>
                                <button mat-menu-item><span>Share</span></button>
                              </mat-menu> 
                            </td>
                            </tr>
                            <tr>
                              <td>
                                3
                              </td>
                              <td>
                                Individual_Therapy__2022-09-26__23-59-59
                              </td>
                              <td>
                                Zoya D
                              </td>
                              
                             
                              <td>
                                <button mat-button [matMenuTriggerFor]="menu">
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                
                                <mat-menu #menu="matMenu">
                                  <button mat-menu-item><span>Preview</span></button>
                                  <button mat-menu-item><span>Print</span></button>
                                  <button mat-menu-item><span>Download</span></button>
                                  <button mat-menu-item><span>Share</span></button>
                                </mat-menu> 
                              </td>
                              </tr>
                          </tbody>
                          </table>
                </div>
              </div>
                
              </mat-tab>
              
              <mat-tab label="Appointments Documents">

                <div class="doc_table mt-3">
                  <div class="table-responsive">
                    <table class="table align-items-center ">
                      <thead class="">
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Document Name</th>
                          <th scope="col">Created by</th>
                          <th scope="col">Action</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1
                          </td>
                          <td>
                            School Meeting__2009-12-31__23-59-59
                          </td>
                          <td>
                            Zoya D
                          </td>
                       
                          
                          <td>
                            <button mat-button [matMenuTriggerFor]="menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            
                            <mat-menu #menu="matMenu">
                              <button mat-menu-item><span>Preview</span></button>
                              <button mat-menu-item><span>Print</span></button>
                              <button mat-menu-item><span>Download</span></button>
                              <button mat-menu-item><span>Share</span></button>
                            </mat-menu> 
                          </td>
                          </tr>
                          <tr>
                            <td>
                              2
                            </td>
                            <td>
                              Psychiatric Evaluation__2010-08-23__23-59-59
                            </td>
                            <td>
                              Issac N
                            </td>
                          
                            <td>
                              <button mat-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                              </button>
                              
                              <mat-menu #menu="matMenu">
                                <button mat-menu-item><span>Preview</span></button>
                                <button mat-menu-item><span>Print</span></button>
                                <button mat-menu-item><span>Download</span></button>
                                <button mat-menu-item><span>Share</span></button>
                              </mat-menu> 
                            </td>
                            </tr>
                            <tr>
                              <td>
                                3
                              </td>
                              <td>
                                Psychiatric Therapy__2022-09-26__23-59-59
                              </td>
                              <td>
                                Zoya D
                              </td>
                              
                              <td>
                                <button mat-button [matMenuTriggerFor]="menu">
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                
                                <mat-menu #menu="matMenu">
                                  <button mat-menu-item><span>Preview</span></button>
                                  <button mat-menu-item><span>Print</span></button>
                                  <button mat-menu-item><span>Download</span></button>
                                  <button mat-menu-item><span>Share</span></button>
                                </mat-menu> 
                              </td>
                              </tr>
                          </tbody>
                          </table>
                </div>
              </div>
              </mat-tab>
              <mat-tab label="Onboarding Documents"> 
                <div class="doc_table mt-3">
                  <div class="table-responsive">
                    <table class="table align-items-center ">
                      <thead class="">
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Document Name</th>
                          <th scope="col">Created by</th>
                          <th scope="col">Action</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1
                          </td>
                          <td>
                            Individual_Therapy__2009-12-31__23-59-59
                          </td>
                          <td>
                            Zoya D
                          </td>
                       
                          
                          <td>
                            <button mat-button [matMenuTriggerFor]="menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            
                            <mat-menu #menu="matMenu">
                              <button mat-menu-item><span>Preview</span></button>
                              <button mat-menu-item><span>Print</span></button>
                              <button mat-menu-item><span>Download</span></button>
                              <button mat-menu-item><span>Share</span></button>
                            </mat-menu> 
                          </td>
                          </tr>
                          <tr>
                            <td>
                              2
                            </td>
                            <td>
                              Family_Therapy__2010-08-23__23-59-59
                            </td>
                            <td>
                              Issac N
                            </td>
                           
                            
                            <td>
                              <button mat-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                              </button>
                              
                              <mat-menu #menu="matMenu">
                                <button mat-menu-item><span>Preview</span></button>
                                <button mat-menu-item><span>Print</span></button>
                                <button mat-menu-item><span>Download</span></button>
                                <button mat-menu-item><span>Share</span></button>
                              </mat-menu> 
                            </td>
                            </tr>
                            <tr>
                              <td>
                                3
                              </td>
                              <td>
                                Individual_Therapy__2022-09-26__23-59-59
                              </td>
                              <td>
                                Zoya D
                              </td>
                              
                            
                              <td>
                                <button mat-button [matMenuTriggerFor]="menu">
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                
                                <mat-menu #menu="matMenu">
                                  <button mat-menu-item><span>Preview</span></button>
                                  <button mat-menu-item><span>Print</span></button>
                                  <button mat-menu-item><span>Download</span></button>
                                  <button mat-menu-item><span>Share</span></button>
                                </mat-menu> 
                              </td>
                              </tr>
                          </tbody>
                          </table>
                </div>
              </div>
                
              </mat-tab>
              <mat-tab label="financial Documents">

                <div class="doc_table mt-3">
                  <div class="table-responsive">
                    <table class="table align-items-center ">
                      <thead class="">
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Document Name</th>
                          <th scope="col">Created by</th>
                          
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1
                          </td>
                          <td>
                            Individual_Therapy__2009-12-31__23-59-59
                          </td>
                          <td>
                            Zoya D
                          </td>
                       
                         
                          <td>
                            <button mat-button [matMenuTriggerFor]="menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            
                            <mat-menu #menu="matMenu">
                              <button mat-menu-item><span>Preview</span></button>
                              <button mat-menu-item><span>Print</span></button>
                              <button mat-menu-item><span>Download</span></button>
                              <button mat-menu-item><span>Share</span></button>
                            </mat-menu> 
                          </td>
                          </tr>
                          <tr>
                            <td>
                              2
                            </td>
                            <td>
                              Family_Therapy__2010-08-23__23-59-59
                            </td>
                            <td>
                              Issac N
                            </td>
                           
                           
                            <td>
                              <button mat-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                              </button>
                              
                              <mat-menu #menu="matMenu">
                                <button mat-menu-item><span>Preview</span></button>
                                <button mat-menu-item><span>Print</span></button>
                                <button mat-menu-item><span>Download</span></button>
                                <button mat-menu-item><span>Share</span></button>
                              </mat-menu> 
                            </td>
                            </tr>
                            <tr>
                              <td>
                                3
                              </td>
                              <td>
                                Individual_Therapy__2022-09-26__23-59-59
                              </td>
                              <td>
                                Zoya D
                              </td>
                              
                            
                              <td>
                                <button mat-button [matMenuTriggerFor]="menu">
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                
                                <mat-menu #menu="matMenu">
                                  <button mat-menu-item><span>Preview</span></button>
                                  <button mat-menu-item><span>Print</span></button>
                                  <button mat-menu-item><span>Download</span></button>
                                  <button mat-menu-item><span>Share</span></button>
                                </mat-menu> 
                              </td>
                              </tr>
                          </tbody>
                          </table>
                </div>
              </div>
              </mat-tab>
              <mat-tab label="Communication Documents">
                <div class="doc_table mt-3">
                  <div class="table-responsive">
                    <table class="table align-items-center ">
                      <thead class="">
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">Document Name</th>
                          <th scope="col">Created by</th>
                          <th scope="col">Action</th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            1
                          </td>
                          <td>
                            Individual_Therapy__2009-12-31__23-59-59
                          </td>
                          <td>
                            Zoya D
                          </td>
                       
                         
                          <td>
                            <button mat-button [matMenuTriggerFor]="menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>
                            
                            <mat-menu #menu="matMenu">
                              <button mat-menu-item><span>Preview</span></button>
                              <button mat-menu-item><span>Print</span></button>
                              <button mat-menu-item><span>Download</span></button>
                              <button mat-menu-item><span>Share</span></button>
                            </mat-menu> 
                          </td>
                          </tr>
                          <tr>
                            <td>
                              2
                            </td>
                            <td>
                              Family_Therapy__2010-08-23__23-59-59
                            </td>
                            <td>
                              Issac N
                            </td>
                           
                          
                            <td>
                              <button mat-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                              </button>
                              
                              <mat-menu #menu="matMenu">
                                <button mat-menu-item><span>Preview</span></button>
                                <button mat-menu-item><span>Print</span></button>
                                <button mat-menu-item><span>Download</span></button>
                                <button mat-menu-item><span>Share</span></button>
                              </mat-menu> 
                            </td>
                            </tr>
                            <tr>
                              <td>
                                3
                              </td>
                              <td>
                                Individual_Therapy__2022-09-26__23-59-59
                              </td>
                              <td>
                                Zoya D
                              </td>
                              
                             
                              <td>
                                <button mat-button [matMenuTriggerFor]="menu">
                                  <mat-icon>more_vert</mat-icon>
                                </button>
                                
                                <mat-menu #menu="matMenu">
                                  <button mat-menu-item><span>Preview</span></button>
                                  <button mat-menu-item><span>Print</span></button>
                                  <button mat-menu-item><span>Download</span></button>
                                  <button mat-menu-item><span>Share</span></button>
                                </mat-menu> 
                              </td>
                              </tr>
                          </tbody>
                          </table>
                </div>
              </div>
                </mat-tab>
            </mat-tab-group>
          </div>
          </div>
        </div>
      
    </div>
  </div>
</div>
</div>
<div class="comment-popup  cancel-tour">
  <div class="modal" tabindex="-1" role="dialog"  style="display: none;">
    <div class="modal-dialog mat-dialog-container " role="document">

      <div class="modal-content border-0">
<div class="modal-header">
  <div class="modal-title">Share All Documents</div>
  <button type="button" class="close" (click)="closeAddRole();" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
        <div class="modal-body">
<div >


  <div class="col-md-12">
    <div class="row ">
      <div class="col-md-12">
<div class="row">
  <div class="col-md-10">
    <mat-form-field class="w-100">
      <mat-label>Email Address</mat-label>
      <input matInput>
    </mat-form-field>
  </div>
  <div class="col-md-2">
    <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" >Send</button>
  </div>
</div>
        
      </div>


    </div>

  </div>
  <div class="text-center col-md-12 mt-2">
    
    <!-- <button type="button" class="btn btn-cancel" data-dismiss="modal" >No</button> -->
  </div>



</div>


</div>
</div>
</div>
</div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private http: HttpClient, public commonService: CommonService) { }
  getBillLists(bListsfor){
       return this.http.get<any>(environment.BaseURI + 'gateway/GetBilling/GetBillLists?bListsfor='+this.commonService.DataToEncrypt(bListsfor, true));
       }
  // getBillListsV2(bListsfor){
  //       return this.http.get<any>(environment.BaseURI + 'gateway/GetBilling/GetBillListsV2?bListsfor='+this.commonService.DataToEncrypt(bListsfor, true));
  //       }
  getTherapyMstr(){
        return this.http.get<any>(environment.BaseURI + 'gateway/GetBilling/getTherapyMstr');
        }
   getBillMstr(){
          return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetBillMasters');
          }
          getProviderMaster(){
   return this.http.get<any>(environment.BaseURI + 'gateway/BillingMaster/GetProviderType');
}
getInsuranceMaster(){
  return this.http.get<any>(environment.BaseURI + 'gateway/BillingMaster/GetInsuranceMaster');
}
getBillingGroup(){
  return this.http.get<any>(environment.BaseURI + 'gateway/BillingMaster/GetBillingGroup');
}
PostInsurancefrm(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostBilling/PostInsuranceInfoForm',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}
    postTransactionfrm(data) {
            const headers = new HttpHeaders().set(
              'Content-Type',
              'application/json; charset=utf-8'
            );
            return this.http.post(environment.BaseURI + 'gateway/PostBilling/PostTransactionForm',
              data, { headers: headers, responseType: 'text' })
              .pipe(map(function (response) { return response; }));
          }
          
    getTransactionLists(){
            return this.http.get<any>(environment.BaseURI + 'gateway/GetBilling/GetTransactionCodes');
            }
    getInsuranceInfo(){
          return this.http.get<any>(environment.BaseURI + 'gateway/GetBilling/GetInsuranceInfo');
    }
    getCreateBillMstr(){
              return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetCreateBillMasters');
              }
    PostCreateBill(data) {
        const headers = new HttpHeaders().set(
            'Content-Type',
             'application/json; charset=utf-8'
           );
        return this.http.post(environment.BaseURI + 'gateway/PostBilling/PostCreateBillForm',
                  data, { headers: headers, responseType: 'text' })
                  .pipe(map(function (response) { return response; }));
          }

getcreatedBillLists(id) {return this.http.get<any>(environment.BaseURI + 'gateway/GetBilling/GetCreatedBills?Id=' + this.commonService.DataToEncrypt(id, true));}
   getPaymentMstr(){return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetPaymentMasters');}  
            PostPatientPaymentMaster(data) {
              const headers = new HttpHeaders().set(
                  'Content-Type',
                   'application/json; charset=utf-8'
                 );
              return this.http.post(environment.BaseURI + 'gateway/PostBilling/PostPatientPaymentMaster',
                        data, { headers: headers, responseType: 'text' })
                        .pipe(map(function (response) { return response; }));
                }
getPatientCreditcardDtls(id) {return this.http.get<any>(environment.BaseURI + 'gateway/GetBilling/getPatientCreditcardDtls?Id=' + this.commonService.DataToEncrypt(id, true));}
 getPatientListsForcreateBill(){return this.http.get<any>(environment.BaseURI + 'gateway/GetBilling/GetPatientDetailCreateBill');}
 getClaimLists(){return this.http.get<any>(environment.BaseURI + 'gateway/GetClaims/GetClaimsLists');}
 getClaimsListsMstr(){return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetClaimsListMasters');}

 
 postAVtest(data) {
  const headers = new HttpHeaders().set(
      'Content-Type',
       'application/json; charset=utf-8'
     );
  return this.http.post(environment.BaseURI + 'gateway/PostClaims/PostAVTest',
            data, { headers: headers, responseType: 'text' })
            .pipe(map(function (response) { return response; }));
    }


    getMethodAvtest(){
      return this.http.get<any>(environment.BaseURI + 'gateway/PostClaims/GetAVTest');
      }
      getpayment(){
        return this.http.get<any>(environment.BaseURI + 'gateway/Payment/GetBridgePayToken');
        }
        postpayment(data){
          const headers = new HttpHeaders().set(
            'Content-Type',
             'application/json; charset=utf-8'
           );
        return this.http.post(environment.BaseURI + 'gateway/Payment/PostTransactionSale',
                  data, { headers: headers, responseType: 'text' })
                  .pipe(map(function (response) { return response; }));
          
          }
          deleteInsuranceInfo(data) {
            const headers = new HttpHeaders().set(
              'Content-Type',
              'application/json; charset=utf-8'
            );
            return this.http.post(environment.BaseURI + 'gateway/PostBilling/DeleteInsuranceInfo',
              data, { headers: headers, responseType: 'text' })
              .pipe(map(function (response) { return response; }));
          }
          changeStatusInsuranceInfo(data) {
            const headers = new HttpHeaders().set(
              'Content-Type',
              'application/json; charset=utf-8'
            );
            return this.http.post(environment.BaseURI + 'gateway/PostBilling/ChangeStatusInsuranceInfo',
              data, { headers: headers, responseType: 'text' })
              .pipe(map(function (response) { return response; }));
          }

    }


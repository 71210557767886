export class DtoAuditSettings{
    auditDateMasterId : number;
    profileSubmission : number;
    updateBenefits : number;
    financialProposal : number;
    scheduleIntake : number;
    intakePacket : number;
    cmha : number;
    painAssesment : number;
    nutritionalQuestion : number;
    sucidicalRisk : number;
    treatmentPlan : number;
    progressNote : number;
    isActive : boolean;
    incidentReport : number;
    behaviourIncidentReport : number;
    dischargeSummary : number;
    treatmentSummary : number;
    communicationForm : number;
}
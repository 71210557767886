import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ParentInfo } from 'src/app/_models/_Lead/parentInfo';
import { PatientInfo } from 'src/app/_models/_Lead/patientInfo';
import { constantVariables } from 'src/app/constant/constant';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { LeadService } from 'src/app/patient-lead/lead.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Insurance } from 'src/app/_models/_Lead/insurance';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
// import * as fileSaver from 'file-saver';
import { PatientHistory } from 'src/app/_models/_Lead/patientHistory';
import { HospitalInfo } from 'src/app/_models/_Lead/patientHistory';
import { notification } from 'src/app/_models/_Common/notification';
import { PatientService } from 'src/app/patient/patient.service';
import { PatientMedicineInfo } from 'src/app/_models/_Lead/patientMedications';

import { NotificationService } from 'src/app/notification/notification.service';
import { addressslookup } from 'src/app/services/add-lookup.service';
import { EsignDialogComponent } from 'src/app/common/esign-dialog/esign-dialog.component';
import { cloneDeep } from "lodash";
import { AnyMxRecord } from 'dns';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { Location } from '@angular/common';
import { UserserviceService } from 'src/app/usermanagement/userservice.service';
import { AuthenticationService } from 'src/app/admin/authentication.service';
declare function EncryptFieldData(data): any;
import { TemplateService } from 'src/app/common/pages/template/template.service';
import { error } from 'console';

@Component({
  selector: 'app-lead-info',
  templateUrl: './lead-info.component.html',
  styleUrls: ['./lead-info.component.scss']
})
export class LeadInfoComponent implements OnInit {
  displayedColumns = ['FirstName', 'ParentalRelation', 'Email', 'ContactNo', 'actions'];
  //insuraceColumns = ['MemberName', 'CarrierName', 'PolicyNo', 'GroupNo', 'actions'];
  insuraceColumns = ['InsuranceType', 'CarrierName', 'MemberId', 'GroupNo', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  @ViewChild('leadinfo') leadinfo: NgForm;
  @ViewChild('insurancefrm') insurancefrm: NgForm;
  @ViewChild('patienthistoryfrm') patienthistoryfrm: NgForm;
  @ViewChild('Insurancefile', { static: true }) Insurancefile;
  @ViewChild('Insurancefile') eleInsurancefile: ElementRef;
  // @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  @ViewChild(MatPaginator) insPaginator: MatPaginator;
  @ViewChild(MatSort) insSort: MatSort;
  insDataSource: any=[];
  insDataSourceHistory: any=[];

  primaryTab: boolean = true;
  insuranceTab: boolean = false;
  historyTab: boolean = false;
  esignTab: boolean = false;
  feeTab: boolean = false;
  authorizationTab: boolean = false;
  isSubmit: boolean = false;
  cellRequired: boolean = false;
  currentTab: any;
  primaryTabCompleted: boolean;
  insuranceTabCompleted: boolean;
  historyTabCompleted: boolean;
  esignTabCompleted: boolean;
  roleId;
  roleLead = constantVariables.role.Lead;
  roleAdmin = constantVariables.role.Admin;
  statusResendActivationMail = constantVariables.status.ResendActivationMail;
  statusUploadInsurance = constantVariables.status.UploadInsurance;
  statusReviewPacket = constantVariables.status.ReviewPacket;
  statusReturn = constantVariables.status.Return;
  statusVerified = constantVariables.status.Verified;
  statusFinancial = constantVariables.status.financial;
  statusreviewTour = constantVariables.status.reviewTour;
  statusforIntake = constantVariables.status.forIntake;
  statusforStaffAssigned = constantVariables.status.staffAssigned;
  statustourCompleted = constantVariables.status.tourCompleted;
  intakeScheduled = constantVariables.status.intakeScheduled;
  isAssigned = false;
  immidiateactionid: any;
  noticationinfo: notification
  parentemail: any;
  parentfirstname: any;
  oldEmail: any;
  //#region primary Info
  patientinfo: PatientInfo;
  parentinfo: any;
  patientPrimary: any;
  parentPrimary: any;
  ddlgender: any;
  ddlgrade: any;
  ddlmaritalStatus: any
  ddlSoucre: any;
  ddlLeadType: any;
  ddlfacilityType: any;
  ddlparentalRelationType: any;
  ddlStates: any;
  ddlCities: any;
  ddlcityMaster: any;
  ddlPsychiatristCity: any;
  ddlPhysicianCity: any;
  ddlTherapistCity: any;
  isAgeGroup = false;
  patientAge: number;
  patientDob: string;
  patientid: any;
  parentStateId: any;
  ddlstatemaster: any;
  bulkUploadFileList: any = [];
  ddlCitiesLoad: any = [];
  patientModel: PatientInfo;
  Email: string;
  isEmailChanged: boolean = false;
  userId: number;
  insuranceSubitted: boolean = false;
  primaryMail : string;
  isInsuranceRedirect: boolean = false;
  IsNewFee : boolean = false;

  //#endregion

  //#region Insurance
  insuranceinfo: Insurance;
  isAddInsurance = false;
  imgInsuranceFront = '';
  imgInsuranceBack = '';
  imgInsurancePolicy = '';
  imgNameInsuranceFront = '';
  imgNameInsuranceBack = '';
  // imgNameInsurancePolicy = '';
  srcInsuranceFront: any;
  srcInsuranceBack: any;
  // srcInsurancePolicy: any;
  idInsuranceFront: any;
  idInsuranceBack: any;
  // idInsurancePolicy: any;
  primaryInsurance: any;
  lstInsurance: any;
  lstInsuranceHistory:any;
  lstUploadInsurance: any;
  isdeleteInscopy: number = 3;
  isEditPrimary: boolean = false;
  UploadFileList: any = [];
  isUploadinsuranceFront: boolean = false;
  isUploadinsuranceBack: boolean = false;
  isUploadinsurancePolicy: boolean = false;
  isAddPolicyDocumnet: boolean = false;
  uploadflag: string;
  paientdetails: any;
  isEditInsurance: boolean = false;
  isInsUploadModel: boolean = false;
  isNexttoHistory: boolean = false;
  ddlInsuranceType: any = [];
  ddlInsuranceRelationShip: any = [];
  InsuranceFrontNotFound: boolean = false;
  InsuranceBackNotFound: boolean = false;
  IsNoInsurance: boolean = false;
  //#endregion

  //#region Patient history
  medicineinfo: PatientMedicineInfo[] = [];
  patienthistoryinfo: PatientHistory;
  subjectTag = "";
  idTag: any;
  phbook: any;
  phPsychiatristbook: any;
  phNeurologistbook: any;
  phTherapistbook: any;
  phCasemanagerbook :any;
  phonebook: any = [];
  filteredphbook: any = [];
  filteredPsychiatristph: any = [];
  filteredTherapistph: any = [];
  _dtoMedications: any = [];
  //#endregion

  //#region esign
  IsEsignOptionSelected: boolean = false;
  //#endregion
  UserName: any;
  currentUserId: any;
  addressoption;
  LeadStatus: any;
  signatureImg: any;
  IsNoInsurancechecked: boolean = false;
  Islead: boolean;
  InsuranceDataSourceDummy: MatTableDataSource<any>;
  isProfileonly: boolean = false;
  tmpParentInfo: any;
  paramsParentinfoId: any = 0;
  authorizeTabCompleted: boolean = false;
  paymentDetailsTabCompleted: boolean = false;
  paymentDetailsTab: boolean = false;
  IsleadResubmit: boolean = false;
  IsInsuranceResubmit: boolean = false;
  IsHistoryResubmit: boolean = false;
  filterNeurologist: any[];
  filterCaseManager : any[];
  ddlyesno = constantVariables.YesNo;
  ddlAgreeDisagree = constantVariables.AgreeDisAgree;

  isShowAuthorization: boolean = false;
  allCities: any[];
  roleAccess: any;
  isAdminClicked: boolean = false;
  isPrimaryContactChanged: boolean = false;
  isAddPrimary :boolean=false;
  IsChanged :boolean = false;
  IsInsuranceChanged : boolean = false;
  IsUpdated : boolean = false;
  isFormSubmitted:boolean=false;
  messageShow:any;
  isProfileDisabled : boolean = false;
  disablePrimaryContact : boolean = false;
  isValidEmail : boolean = false;
  maskedSSN : any;
  SecureId : any;
  //isInsuranceRedirect:  boolean = false;
  // customPattern = {'0': {pattern: new RegExp('\\d'), symbol: 'X'}};
  constructor(public leadservice: LeadService, public patientservice: PatientService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,
    private addlookup: addressslookup, public notify: NotificationService, private _location: Location,
    public domSrv: DomSanitizer, public roleaccessservice: PageAccessService, private userService: UserserviceService,
    private authenticationService: AuthenticationService, public tempService: TemplateService) { }
    currentdate:any;
    isDelete :boolean = false;
  ngOnInit(): void {
    this.isDelete = false;
    this.currentdate=new Date();
    //#region init
    this.patientinfo = new PatientInfo();
    this.patientPrimary = new ParentInfo();
    // this.patientPrimary.MaritalStatus = 0;
    this.patienthistoryinfo = new PatientHistory();
    this.patienthistoryinfo.PhysicianDetails = new HospitalInfo();
    this.patienthistoryinfo.PsychiatristDetails = new HospitalInfo();
    this.patienthistoryinfo.TherapistDetails = new HospitalInfo();
    this.patienthistoryinfo.NeurologistDetails = new HospitalInfo();
    this.patienthistoryinfo.CaseMangerDetails = new HospitalInfo();
    this.ddlgender = constantVariables.gender;
    // this.ddlgrade = constantVariables.gradelevel;
    this.ddlmaritalStatus = constantVariables.maritalStatus;
    this.insuranceinfo = new Insurance();
    this.insuranceinfo.Ssid = "";
    this.maskedSSN  = ""; 
    this.insuranceinfo.IsPrimaryInsurance = true;
    this.noticationinfo = new notification();
    debugger;
    console.log('Lead Inof TEst');
    this.LeadStatus = localStorage.getItem("currentstatus");
    // this.patientModel = new PatientInfo();
    this.userId = Number(localStorage.getItem("id"));

    localStorage.setItem('isFinancereview', 'false');


    //#endregion

    this.roleId = Number(localStorage.getItem("roleId"));
    const pageid = constantVariables.pagelists.Profile;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);

    this.route.queryParams.subscribe(params => {
      if (params != null) {
        this.currentUserId = localStorage.getItem('id');
        this.UserName = localStorage.getItem("username");
        this.patientid = this.commonService.FrontEndDecryption(params.id).replace(/"/g, "");
        localStorage.setItem('patientId', this.patientid);
        if (params.email) {
          this.parentemail = this.commonService.FrontEndDecryption(params.email).replace(/"/g, "");
        }
        if (params.name) {
          this.parentfirstname = this.commonService.FrontEndDecryption(params.name).replace(/"/g, "");
        }
        if (params.isProfileonly) {
          this.isProfileonly = this.commonService.FrontEndDecryption(params.isProfileonly).replace(/"/g, "");
        }
        if (params.parentInfoId) {
          this.paramsParentinfoId = this.commonService.FrontEndDecryption(params.parentInfoId).replace(/"/g, "");
        }
        if (params.isAdminClicked) {
          this.isAdminClicked = this.commonService.FrontEndDecryption(params.isAdminClicked).replace(/"/g, "")
        }

        if (params.isInsuranceRedirect) {
          this.isInsuranceRedirect = params.isInsuranceRedirect;
        }
        this.getddlStateMaster();
        this.loadtabsection(); 
        this.getCurrentStatus();
        this.getInsAuthStatusbyPatient();
        this.getPaymentDtlExists();
      }
    });
  }
  //#region  Navigation
  loadtabsection() {

    this.currentTab = localStorage.getItem("profiletab");
    if (this.currentTab == "1") {
      this.primaryTab = true;
      this.insuranceTab = false;
      this.historyTab = false;
      this.feeTab = false;
      this.esignTab = false;
      this.authorizationTab = false;
      this.paymentDetailsTab = false;
      this.getPatientAdditionalinfo(this.patientid);
      this.getddlLocation();
      //this.getddlStateMaster();

      this.getPatientAdditionalinfo(this.patientid);
    } else if (this.currentTab == "2") {
      this.primaryTab = false;
      this.insuranceTab = true;
      this.historyTab = false;
      this.feeTab = false;
      this.esignTab = false;
      this.authorizationTab = false;
      this.paymentDetailsTab = false;
      this.getInsuranceMaster();
      this.getInsurance();

    } else if (this.currentTab == "3") {

      this.primaryTab = false;
      this.insuranceTab = false;
      this.historyTab = true;
      this.feeTab = false;
      this.authorizationTab = false;
      this.paymentDetailsTab = false;
      //this.getddlStateMaster();
      this.getphonebook();
      this.getddlMaster();
      this.esignTab = false;
      this.getPatientHistory();
      this.getPsychotropicMedication();
    } else if (this.currentTab == "4") {


      this.primaryTab = false;
      this.insuranceTab = false;
      this.historyTab = false;
      this.feeTab = true;
      this.authorizationTab = false;
      this.paymentDetailsTab = false;
      this.esignTab = true;
      this.getPatientAdditionalinfo(this.patientid);
    }
    else if (this.currentTab == "5") {

      this.primaryTab = false;
      this.insuranceTab = false;
      this.historyTab = false;
      this.feeTab = true;
      this.authorizationTab = false;
      this.paymentDetailsTab = false;
      this.esignTab = false;

    }
    else if (this.currentTab == "6") {

      this.primaryTab = false;
      this.insuranceTab = false;
      this.historyTab = false;
      this.feeTab = false;
      // this.authorizationTab = true;
      this.authorizationTab = false;
      this.paymentDetailsTab = false;
      this.esignTab = false;

    }
    else if (this.currentTab == "7") {

      this.primaryTab = false;
      this.insuranceTab = false;
      this.historyTab = false;
      this.feeTab = false;
      this.authorizationTab = false;
      this.esignTab = false;
      this.paymentDetailsTab = true;

    }


    else if (this.currentTab == null || this.currentTab == undefined) {
      localStorage.setItem("profiletab", "1");
      //this.getddlStateMaster();

      this.getPatientAdditionalinfo(this.patientid);
      this.getddlLocation();
    }
    this.getInsAuthStatusbyPatient();
    if ((this.medicineinfo && this.medicineinfo.length == 0)) {
      this.medicineinfo.push({
        PatientMedicationsId: 0,
        PatientHistoryId: 0,
        PatientInfoId: this.patientid,
        Medication: null,
        IsActive: true,
      })
    }
  }

  tabPrimarytoInsurance() {

    if (this.roleId == 1) {
      // this.isSubmit = true;

      
      var valid = true;
      if (this.patientinfo.LastName == null || this.patientinfo.LastName == '') {
        valid = false;
      }
      if (this.patientinfo.FirstName == "" || this.patientinfo.FirstName == null) {
        valid = false;
      }

      // if (this.patientinfo.Age == 0 || this.patientinfo.Age == null || this.patientinfo.Age == NaN) {
      //   valid = false;
      // }
      if (this.patientinfo.Ssid == "" || this.patientinfo.Ssid == null) {
        valid = false;
      }
      // if (this.patientPrimary.MaritalStatus == 0 || this.patientPrimary.Maripatientinfo.Ssidpatientinfo.SsidtalStatus == null || this.patientPrimary.MaritalStatus == NaN) {
      // if (this.patientPrimary.MaritalStatus == 0 || this.patientPrimary.MaritalStatus == NaN) {
      if (this.patientPrimary.MaritalStatus == 0 || Number.isNaN(this.patientPrimary.MaritalStatus)) {

        valid = false;
      }
      // if (this.patientinfo.GradeLevel == 0 || this.patientinfo.GradeLevel == null || this.patientinfo.GradeLevel == NaN) {
      if (this.patientinfo.GradeLevel == 0 || this.patientinfo.GradeLevel == null || Number.isNaN(this.patientinfo.GradeLevel)) {
        valid = false;
      }
      if (this.patientinfo.SchoolDistrict == "" || this.patientinfo.SchoolDistrict == null) {
        valid = false;
      }

      // if (this.patientinfo.SchoolName == null || this.patientinfo.SchoolName == "" || this.patientPrimary.SchoolName == NaN) {
      if (this.patientinfo.SchoolName == null || this.patientinfo.SchoolName == "" || Number.isNaN(this.patientPrimary.SchoolName)) {
        valid = false;
      }
      // if (this.patientinfo.TeachersName == "" || this.patientinfo.TeachersName == null ||  this.patientPrimary.TeachersName == NaN) {
      if (this.patientinfo.TeachersName == "" || this.patientinfo.TeachersName == null || Number.isNaN(this.patientPrimary.TeachersName)) {
        valid = false;
      }
      // if (this.patientinfo.TeachersLastName == "" || this.patientinfo.TeachersLastName == null ||  this.patientPrimary.TeachersLastName == NaN) {
      if (this.patientinfo.TeachersLastName == "" || this.patientinfo.TeachersLastName == null || Number.isNaN(this.parentPrimary.TeachersLastName)) {
        valid = false;
      }
      if (this.patientinfo.Academiccategory == "" || this.patientinfo.Academiccategory == null || Number.isNaN(this.parentPrimary.Academiccategory)) {
        valid = false;
      }

      //  if (this.patientPrimary.CellNumber == null || this.patientPrimary.CellNumber == '' || this.patientPrimary.CellNumber == NaN) {
      if (this.patientPrimary.CellNumber == null || this.patientPrimary.CellNumber == '' || Number.isNaN(this.patientPrimary.CellNumber)) {

        valid = false;
      }
      // if (this.patientPrimary.Email == null || this.patientPrimary.Email == '' || this.patientPrimary.Email == NaN) {
      if (this.patientPrimary.Email == null || this.patientPrimary.Email == '' || Number.isNaN(this.patientPrimary.Email)) {

        valid = false;
      }
      // if (this.patientPrimary.MaritalStatus == null || this.patientPrimary.MaritalStatus == '' || this.patientPrimary.MaritalStatus == NaN) {
      //   if (this.patientPrimary.MaritalStatus == null || this.patientPrimary.MaritalStatus == '' || Number.isNaN(this.patientPrimary.MaritalStatus)) {
      //     valid = false;
      // }

      // if (this.patientPrimary.ParentalRelationTypeId == null || this.patientPrimary.ParentalRelationTypeId == 0 || this.patientPrimary.ParentalRelationTypeId == NaN) {
      if (this.patientPrimary.ParentalRelationTypeId == null || this.patientPrimary.ParentalRelationTypeId == 0 || Number.isNaN(this.patientPrimary.ParentalRelationTypeId)) {
        valid = false;
      }

      // if (this.patientPrimary.LastName == null || this.patientPrimary.LastName == '' || this.patientPrimary.LastName == NaN) {
      if (this.patientPrimary.LastName == null || this.patientPrimary.LastName == '' || Number.isNaN(this.patientPrimary.LastName)) {

        valid = false;
      }

      // if (this.patientPrimary.FirstName == "" || this.patientPrimary.FirstName == null || this.patientPrimary.FirstName == 0 || this.patientPrimary.FirstName == NaN) {
      if (this.patientPrimary.FirstName == "" || this.patientPrimary.FirstName == null || this.patientPrimary.FirstName == 0 || Number.isNaN(this.patientPrimary.FirstName)) {
        valid = false;
      }
      // if (this.patientPrimary.AddressLine1 == null || this.patientPrimary.AddressLine1 == 0 || this.patientPrimary.AddressLine1 == NaN) {
      if (this.patientPrimary.AddressLine1 == null || this.patientPrimary.AddressLine1 == 0 || Number.isNaN(this.patientPrimary.AddressLine1)) {
        valid = false;
      }

      if (!valid) {
        this.isSubmit = true;
        return;
      }

      // this.isSubmit = false;
      valid = true;

    }
    if (this.leadinfo.valid) {
      if(this.leadinfo.dirty && !this.IsChanged)
      {
        this.IsChanged = true;
      }else{
        this.IsChanged = false;
      }
      if (!this.patientinfo.IsProfileCompleted || (this.IsChanged && !this.IsUpdated) ) {
        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
          disableClose: true,
          data: {
            title: '',
            message: constantVariables.SuccessMessage.SaveProfileConfirmation
          }
        });
        confirmDialog.afterClosed().subscribe(result => {
          if (result === true) {

            this.updateleadinfo(1);

          } else {
            this.IsChanged = false;
            this.IsUpdated = false;
            this.PrimaryToInsurance();
          }
        }
        );
      } else {
        this.IsChanged = false;
            this.IsUpdated = false;
        this.PrimaryToInsurance();
      }

    }
    else {
      this.isSubmit = true;
      this.leadinfo.form.markAllAsTouched();
      this.leadinfo.form.markAsDirty();
    }
    this.getInsAuthStatusbyPatient();
  }
  PrimaryToInsurance() {
    this.primaryTab = false;
    this.insuranceTab = true;
    this.historyTab = false;
    this.feeTab = false;
    this.esignTab = false;
    this.authorizationTab = false;
    this.getInsuranceMaster();
    this.getInsurance();
    localStorage.setItem("profiletab", "2");
  }
  tabProfileToHistory() {
    if (this.leadinfo.valid) {
      if(this.leadinfo.dirty && !this.IsChanged)
      {
        this.IsChanged = true;
      }else{
        this.IsChanged = false;
      }
      if (!this.patientinfo.IsProfileCompleted || (this.IsChanged && !this.IsUpdated)) {
        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
          disableClose: true,
          data: {
            title: '',
            message: constantVariables.SuccessMessage.SaveProfileConfirmation
          }
        });
        confirmDialog.afterClosed().subscribe(result => {
          if (result === true) {
            this.updateleadinfo(2);

          } else {
            this.IsChanged = false;
            this.IsUpdated = false;
            this.InsuranceToHistory();
          }
        }
        );
      } else {
        this.IsChanged = false;
        this.IsUpdated = false;
        this.InsuranceToHistory();
      }

    }
    else {
      this.leadinfo.form.markAllAsTouched();
      this.leadinfo.form.markAsDirty();
    }
    this.getInsAuthStatusbyPatient();
  }
  tabInsurancetoHistorySave() {
    // if(!this.insuranceTabCompleted)
    // {
      if(this.insurancefrm.dirty && !this.IsInsuranceChanged)
      {
        this.IsInsuranceChanged = true;
      }else{
        this.IsInsuranceChanged = false;
      }
    if (this.insurancefrm.dirty && this.IsInsuranceChanged) {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          title: '',
          message: constantVariables.SuccessMessage.SaveInsuranceConfirmation
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
          if (!this.insurancefrm.valid && this.imgInsuranceFront == "" && this.imgInsuranceBack == "") {
           // this.IsInsuranceChanged = false;
            this.saveInsuranceOnTab();
            

          } 
          else if (this.insurancefrm.valid && this.imgInsuranceFront == "" && this.imgInsuranceBack == "") {
            //this.IsInsuranceChanged = false;
            this.saveInsuranceOnTab();
           
          }
          else {
            this.isNexttoHistory = true;
            this.insuranceTabCompleted = true;
            this.IsInsuranceChanged = false;
            this.InsuranceFrontNotFound = false;
            this.InsuranceBackNotFound = false;
            this.saveInsurance();
          }
        } else {
          this.resetInsurance();
          this.IsInsuranceChanged = false;
          this.tabInsurancetoHistory();
        }
      });

    } 
    else {
      this.IsInsuranceChanged = false;
      this.isFormSubmitted=false;
      this.InsuranceFrontNotFound=false;
      this.InsuranceBackNotFound=false;
      this.tabInsurancetoHistory();
    }
  //}
  // else {
  //   this.tabInsurancetoHistory();
  // }

  }
  saveInsuranceOnTab() {
    this.insurancefrm.form.markAllAsTouched();
    this.insurancefrm.form.markAsDirty();
    // this.insuranceSubitted = true;
    this.insurancefrm.onSubmit
    if(this.imgInsuranceBack == '')
    {
      this.InsuranceBackNotFound = true;
    }
    if(this.imgInsuranceFront == '')
    {
      this.InsuranceFrontNotFound = true;
    }
    this.saveInsurance();
  }
  tabInsurancetoHistory() {



    this.InsuranceToHistory();


    // if (this.lstInsurance && this.lstInsurance.length > 0) {
    //   var primaryInsurance = this.lstInsurance.filter(x => x.IsPrimaryInsurance === true);
    //   if (primaryInsurance && primaryInsurance.length > 0) {

  }
  InsuranceToHistory() {
    this.primaryTab = false;
    this.insuranceTab = false;
    this.historyTab = true;
    this.feeTab = false;
    this.authorizationTab = false;
    this.esignTab = false;
    this.paymentDetailsTab = false;
    this.getddlMaster();
    //this.getddlStateMaster();
    this.getphonebook();
    this.getPatientHistory();
    this.getPsychotropicMedication();

    localStorage.setItem("profiletab", "3");
    //   } else {
    //     this.snackbar.error(constantVariables.ErrorMessage.primaryInsurance);
    //   }
    // }
    this.getInsAuthStatusbyPatient();
  }
  tabtohistory() {
    this.primaryTab = false;
    this.insuranceTab = false;
    this.historyTab = true;
    this.feeTab = false;
    this.esignTab = false;
    this.authorizationTab = false
    this.getddlMaster();
    //this.getddlStateMaster();
    this.getphonebook();
    this.getPatientHistory();
    this.getPsychotropicMedication();

    localStorage.setItem("profiletab", "3");
    this.getInsAuthStatusbyPatient();
  }
  tabtoesign() {
    this.primaryTab = false;
    this.insuranceTab = false;
    this.historyTab = false;
    this.feeTab = false;
    this.esignTab = true;
    this.authorizationTab = false;
    this.paymentDetailsTab = false;
    this.getPatientAdditionalinfo(this.patientid);
    if (!this.patientPrimary.IsSignFuture) {
      this.signatureImg = null;
    }
    localStorage.setItem("profiletab", "4");
    this.getInsAuthStatusbyPatient();
  }

  tabtonextvalidateEsign() {
    if (Number(localStorage.getItem("roleId")) == 4) {
      this.tabtofee();
    }
    else {
      this.tabEsignToPaymentdetails();
    }
  }
  onEsignOption($event) {
    this.IsEsignOptionSelected = true
  }
  submittonextvalidateEsign() {
    if (!this.IsEsignOptionSelected) {
      this.snackbar.error('Please Select any option to submit.')
      return;
    }
    else {
      if ((this.signatureImg === null || this.signatureImg === '') && this.patientPrimary.IsSignFuture == true) {
        this.snackbar.error('Please add esign.')
        return;
      }
      if (!this.patientPrimary.IsSignFuture) {
        this.patientPrimary.Esignature = null;
        this.signatureImg = null;
      }

      this.spinnerservice.show();
      const dtoEsignInfo = {
        ParentInfoId: this.patientPrimary.ParentInfoId,
        IsSignFuture: this.patientPrimary.IsSignFuture,
      };
      const dataToEncrypt = this.commonService.DataToEncrypt(dtoEsignInfo, false);
      this.leadservice.postEsignature(dataToEncrypt).subscribe(res => {
        if (res !== "") {
          this.spinnerservice.hide();
          this.snackbar.success(constantVariables.SuccessMessage.esignUpate);
          this.esignTabCompleted = true;
          this.getPatientAdditionalinfo(this.patientid);
        } else {
          this.spinnerservice.hide();
          this.snackbar.success(constantVariables.SuccessMessage.esignUpate);
          this.esignTabCompleted = true;
          this.getPatientAdditionalinfo(this.patientid);
        }
      },
        err => {
          this.spinnerservice.hide();
        }
      );
    }
  }

  tabtoprevvalidateEsign() {
    this.tabtohistory();
  }

  tabtofee() {

    this.primaryTab = false;
    this.insuranceTab = false;
    this.historyTab = false;
    this.feeTab = true;
    // this.esignTab = false;
    this.authorizationTab = false;
    // this.paymentDetailsTab=false;
    localStorage.setItem("profiletab", "4");
    this.getInsAuthStatusbyPatient();
  }
  prevToPrimary() {
    this.primaryTab = true;
    this.insuranceTab = false;
    this.historyTab = false;
    this.feeTab = false;
    this.esignTab = false;
    this.authorizationTab = false;
    this.paymentDetailsTab = false;
    this.isInsuranceRedirect = false;
    localStorage.setItem("profiletab", "1");
    this.spinnerservice.show();
    this.getddlLocation();
    this.getPatientAdditionalinfo(this.patientid);
    this.getInsAuthStatusbyPatient();
  }
  prevToInsurance() {
    this.primaryTab = false;
    this.insuranceTab = true;
    this.historyTab = false;
    this.feeTab = false;
    this.esignTab = false;
    this.authorizationTab = false;
    this.paymentDetailsTab = false;
    localStorage.setItem("profiletab", "2");
    this.getInsuranceMaster();
    this.getInsurance();
    this.getInsAuthStatusbyPatient();
  }

  //#endregion

  //#region Lead
  //#region primary Info
  getddlMaster() {
    //this.spinnerservice.show();
    this.leadservice.getAllMaster().subscribe(
      res => {
        if (res != null) {
          this.ddlSoucre = this.commonService.decrypdata(res.source);
          this.ddlLeadType = this.commonService.decrypdata(res.leadType);
          this.ddlfacilityType = this.commonService.decrypdata(res.facilityType);
          this.ddlparentalRelationType = this.commonService.decrypdata(res.parentalRelationType);
          // this.ddlStates = this.commonService.decrypdata(res.states);
          // this.ddlgrade = this.commonService.decrypdata(res.grades);
          // this.ddlcityMaster = this.commonService.decrypdata(res.cities);


          this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  async getddlStateMaster() {
    this.spinnerservice.show();
    var res = await this.leadservice.getstateMasterForAllState();
    if (res != null) {
      this.spinnerservice.hide();
      this.ddlparentalRelationType = this.commonService.decrypdata(res.parentalRelationType);
      this.ddlstatemaster = this.commonService.decrypdata(res.states);
      this.ddlStates = this.commonService.decrypdata(res.states);
      this.ddlgrade = this.commonService.decrypdata(res.grades);
      this.ddlCitiesLoad = this.commonService.decrypdata(res.cities);
      // console.log(this.ddlstatemaster);
      /**/
      //   this.allCities= this.ddlCities;
      //  if (this.patienthistoryinfo.PhysicianDetails.StatesId>0)
      //   {
      //     this.ddlPhysicianCity=this.allCities.filter(x=>x.StatesId==this.patienthistoryinfo.PhysicianDetails.StatesId);
      //   }
      //    if (this.patienthistoryinfo.PsychiatristDetails.StatesId>0) {
      //     this.allCities= this.ddlCities;
      //     this.ddlPsychiatristCity =this.allCities.filter(x=>x.StatesId==this.patienthistoryinfo.PsychiatristDetails.StatesId);
      //   }
      //   if (this.patienthistoryinfo.TherapistDetails.StatesId>0) {
      //     this.allCities= this.ddlCities;
      //     this.ddlTherapistCity =this.allCities.filter(x=>x.StatesId==this.patienthistoryinfo.TherapistDetails.StatesId);
      //   }

      //   this.spinnerservice.hide();
      /**/
      if (this.patienthistoryinfo != null) {

        if (this.patienthistoryinfo.PhysicianDetails.StatesId > 0) {
          if (this.ddlCitiesLoad.length >0) {
            this.spinnerservice.hide();
            this.ddlPhysicianCity = this.ddlCitiesLoad.filter(x => x.StatesId == this.patienthistoryinfo.PhysicianDetails.StatesId)
          } else {
            this.leadservice.getcities(this.patienthistoryinfo.PhysicianDetails.StatesId).then(
              res => {
                this.spinnerservice.hide();
                this.ddlPhysicianCity = this.commonService.decrypdata(res.cities);
              },
              error =>
                this.spinnerservice.hide()
            );
          }
        }
      }
      if (this.patienthistoryinfo.PsychiatristDetails.StatesId) {
        //this.ddlPsychiatristCity = this.ddlcityMaster.filter((item) => item.StatesId == this.patienthistoryinfo.PsychiatristDetails.StatesId);
        if (this.ddlCitiesLoad.length >0) {
          this.spinnerservice.hide();
          this.ddlPsychiatristCity = this.ddlCitiesLoad.filter(x => x.StatesId == this.patienthistoryinfo.PsychiatristDetails.StatesId)
        } else {
          this.leadservice.getcities(this.patienthistoryinfo.PsychiatristDetails.StatesId).then(
            res => {
              this.spinnerservice.hide();
              this.ddlPsychiatristCity = this.commonService.decrypdata(res.cities);
            },
            error =>
              this.spinnerservice.hide()
          );
        }
      }
      if (this.patienthistoryinfo.TherapistDetails.StatesId) {
        //this.ddlTherapistCity = this.ddlcityMaster.filter((item) => item.StatesId == this.patienthistoryinfo.TherapistDetails.StatesId);
        if (this.ddlCitiesLoad.length >0) {
          this.spinnerservice.hide();
          this.ddlTherapistCity = this.ddlCitiesLoad.filter(x => x.StatesId == this.patienthistoryinfo.TherapistDetails.StatesId)
        } else {
          this.leadservice.getcities(this.patienthistoryinfo.TherapistDetails.StatesId).then(
            res => {
              this.spinnerservice.hide();
              this.ddlTherapistCity = this.commonService.decrypdata(res.cities);
            },
            error =>
              this.spinnerservice.hide()
          );
        }
      }
      if (this.patientPrimary.StatesId) {
        if (this.ddlCitiesLoad.length > 0) {
          this.spinnerservice.hide();
          this.ddlCities = this.ddlCitiesLoad.filter(x => x.StatesId == this.patientPrimary.StatesId)
        } else {
          this.leadservice.getcities(this.patientPrimary.StatesId).then(
            res => {
              this.spinnerservice.hide();
              this.ddlCities = this.commonService.decrypdata(res.cities);
            },
            error =>
              this.spinnerservice.hide()
          );
        }
      }
    }
    this.spinnerservice.hide();
    // },
    // err => {
    //   this.spinnerservice.hide();
    // },
    //);
  }
  calculateAge(birthdate: any, event) {
    // this.isSubmit = false;
    this.isAgeGroup = false;
    // this.isSubmit = false;
    this.patientAge = moment().diff(birthdate, 'years');

    // if (this.patientAge >= 3 && this.patientAge <= 18) {
    //   this.patientinfo.IsMinor = true;
    //   this.patientDob = event.value.toString();
    // }
    // else {
    //   this.isAgeGroup = true;
    //   this.patientinfo.IsMinor = false;
    //   this.snackbar.error(constantVariables.ErrorMessage.dobvalidation);
    //   return
    // }
    if (this.patientAge >= 1) {
      this.patientinfo.IsMinor = true;
      this.patientDob = event.value.toString();
    }
    else {
      this.isAgeGroup = true;
      this.patientinfo.IsMinor = false;
      this.snackbar.error(constantVariables.ErrorMessage.dobvalidationremove);
      return
    }
    this.patientinfo.Age = this.patientAge;

  }
  getPatientAdditionalinfo(id) {
    // this.spinnerservice.show();
    this.leadservice.getPatientAdditionalinfo(id).subscribe(
      (res: any) => {

        var patientprofile = this.commonService.decrypdata(res.objInputString);
       
        console.log(patientprofile)
        if (patientprofile) {

          this.patientinfo = patientprofile.ObjDtoPatientInfo[0];
          if(this.patientinfo.IsSkipInsurance==true)
          {
            this.IsNoInsurancechecked = true;
          }
          else
          {
            this.IsNoInsurancechecked = false;
          }
          if(this.patientinfo.NewFee == true)
            {
              this.IsNewFee = true;
            }
            else
            {
              this.IsNewFee = false;
            }
          if(this.isInsuranceRedirect)
          {
            const isInsuranceCompleted = this.patientinfo.IsInsuranceCompleted;
            if (isInsuranceCompleted == true) {
              this.prevToInsurance() ;
          } else {
             this.prevToPrimary();
          }
          }


          // this.maskSSN1();
          // this.SecureId = this.patientinfo.Ssid;
          // this.patientinfo.Ssid = this.SecureId 

          // this.maskSSN1();
          // this.maskedSSN = this.patientinfo.Ssid;
          // this.patientinfo.Ssid = this.maskedSSN;
          // this.maskSSN1();
          //age
          this.patientAge = this.patientinfo.Dob ? moment().diff(this.patientinfo.Dob, 'years') : 0;
          this.patientinfo.Age = this.patientAge;
          //age calculation is end
           this.patientinfo.GradeLevel = this.patientinfo.GradeLevel == 0 ? null : this.patientinfo.GradeLevel;
          // this.patientinfo.Academiccategory = this.patientinfo.
          this.global.patientInfoStatus = this.patientinfo.PatientStatus
          this.global.patientInfoStatusCreatedon = this.patientinfo.CreatedOn;
          this.immidiateactionid = patientprofile.ObjDtoPatientInfo[0].ImmediateActionId;
          // console.log("ima" + this.immidiateactionid)
          if (localStorage.getItem("currentstatus") === null) {
            localStorage.setItem("currentstatus", this.immidiateactionid);
          }
          //if(this.immidiateactionid > this.intakeScheduled)
          //{
          //  this.isProfileDisabled = true;
          //}else{
          //  this.isProfileDisabled = false;
          //}
          this.patientPrimary = patientprofile.ObjDtoParentInfo.filter(
            x => x.IsPrimaryContact === true)[0];
          // console.log(this.patientPrimary);
          // if(this.patientPrimary.MaritalStatus){
          //   this.patientPrimary.MaritalStatus = +this.patientPrimary.MaritalStatus;
          // }
          //  if (this.patientPrimary === undefined){
          //   console.log(this.patientPrimary);
          //  }
          this.oldEmail = this.patientPrimary.Email;
          this.primaryMail = this.patientPrimary.Email;
          this.disablePrimaryContact = true;
          this.parentPrimary = patientprofile.ObjDtoParentInfo.filter(
            x => x.IsPrimaryContact === true)[0];
          this.isPrimaryContactChanged = false;
          if (this.patientPrimary.Esignature != null && this.patientPrimary.Esignature != '') {
            const image = this.commonService.FrontEndDecryption(this.patientPrimary.Esignature);
            this.signatureImg = this.domSrv.bypassSecurityTrustResourceUrl(image);
            this.signatureImg = this.signatureImg.changingThisBreaksApplicationSecurity?.replace(/"/g, "");
          }
          if (!this.patientPrimary.IsSignFuture) {
            this.signatureImg = null;
          }
          this.parentStateId = this.patientPrimary.StatesId;
          if (this.parentStateId) {
            //this.spinnerservice.show();
            //this.ddlCities = this.getcities(this.parentStateId);
            console.log(this.ddlCities, this.ddlCitiesLoad, this.parentStateId);

            if (this.ddlCitiesLoad.length >0) {
              this.ddlCities = this.ddlCitiesLoad.filter(x => x.StatesId == this.parentStateId)
              this.patientPrimary.City = Number(this.patientPrimary.City)
            }
            else {
              this.leadservice.getcities(this.parentStateId).then(
                res => {
                  this.ddlCities = this.commonService.decrypdata(res.cities);
                  this.patientPrimary.City = Number(this.patientPrimary.City)
                  this.spinnerservice.hide();
                },
                error =>
                  this.spinnerservice.hide()
              );
            }

          }
           
             // this.patientPrimary.City = Number(localStorage.getItem("sfcclocation")) == 0? '':Number(localStorage.getItem("sfcclocation"));
          this.primaryTabCompleted = this.patientinfo.IsProfileCompleted == null ? false : this.patientinfo.IsProfileCompleted;
          this.insuranceTabCompleted = this.patientinfo.IsInsuranceCompleted == null ? false : this.patientinfo.IsInsuranceCompleted;
          this.historyTabCompleted = this.patientinfo.IsHistoryCompleted == null ? false : this.patientinfo.IsHistoryCompleted;

          this.dataSource = new MatTableDataSource(patientprofile.ObjDtoParentInfo);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.tmpParentInfo = patientprofile.ObjDtoParentInfo;
          if (this.patientPrimary.IsSignFuture == null) {
            this.esignTabCompleted = false;
          } else if (this.patientPrimary.IsSignFuture && (this.patientPrimary.Esignature !== null && this.patientPrimary.Esignature !== '')) {
            this.esignTabCompleted = true;
          }
          else if (!this.patientPrimary.IsSignFuture) {
            this.esignTabCompleted = true;
          }
          else {
            this.esignTabCompleted = false;
          }
        }
        if (this.isProfileonly && this.paramsParentinfoId != "0") {
          this.editparent(this.tmpParentInfo.find(x => x.ParentInfoId == this.paramsParentinfoId))
        }
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  updateleadinfo(val) {
    if (!this.leadinfo.valid) {
      return
    }
    if (this.isAgeGroup == true) {
      this.snackbar.error(constantVariables.ErrorMessage.dobvalidation);
      return
    }

    this.patientinfo.Age = this.patientAge;
    this.patientPrimary.Esignature = '';
    this.patientPrimary.EsignType = '';
    this.patientinfo.IsResubmit = this.IsleadResubmit;
    this.patientPrimary.City = this.patientPrimary.City.toString();
    this.patientPrimary.PatientInfoId = +this.patientPrimary.PatientInfoId;
    this.spinnerservice.show();
    let _obj: any = {
      UserId: 0,
      ObjDtoPatientInfo: this.patientinfo,
      ObjDtoParentInfo: [this.patientPrimary]
    }
    // const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);

    this.leadservice.postLeadAccount(_obj).subscribe(res => {
      this.spinnerservice.hide();
      if (res) {

    this.snackbar.success(res);
    this.patientinfo.IsProfileCompleted = true;
    //this.IsChanged = false;
    this.IsUpdated = true;
    if (val == 1) {
      this.tabPrimarytoInsurance();
      this.primaryTabCompleted = true;
    } else {
      this.InsuranceToHistory();
    }
    } 
    else {
      this.tabPrimarytoInsurance();
      this.snackbar.success(res);
    }
    },
      err => {
        this.IsChanged = false;
        this.snackbar.error(err.error);
        this.spinnerservice.hide();
      }
    );
  }
  //#endregion

  //#region Insurance

  getInsuranceMaster() {
    this.leadservice.getInsuranceMaster().subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this.ddlInsuranceType = this.commonService.decrypdata(res.insuranceType);
          this.ddlInsuranceRelationShip = this.commonService.decrypdata(res.insuranceRelationship);

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  InsuranceDatas = [];
  getInsurance() {
    
    this.spinnerservice.show();
    this.insuranceinfo.Ssid = "";
    this.leadservice.getInsurance(this.patientid).subscribe(
      (res: any) => {

        if (localStorage.getItem('currentstatus')) {
          this.immidiateactionid = Number(localStorage.getItem('currentstatus'));
        }
        // this.imgInsuranceFront = null;
        // this.lstUploadInsurance = null;
        //this.imgInsurancePolicy = "";
        // this.imgAttachment = "";
        // this.imgNameAttachment = "";
        var dtoInsurance = this.commonService.decrypdata(res.objInputString);
        this.lstInsurance = dtoInsurance.ObjDtoInsurance;
        this.lstInsuranceHistory = dtoInsurance.ObjDtoInsuranceHistory;

        this.InsuranceDatas = dtoInsurance.ObjDtoInsurance;;
        this.paientdetails = dtoInsurance.PatientDetails;
        if (this.lstInsurance && this.lstInsurance.length == 0) {
          this.insuranceTabCompleted = false;
          
          //  this.skipandSaveInsurance();
          this.isAddInsurance = true;
          this.isAddPrimary = true;
          // this.insuranceinfo.EmployerPhoneNo = this.paientdetails.EmployerPhoneNo;
          this.insuranceinfo.MemberName = this.paientdetails.MemberName;
          // this.insuranceinfo.Ssid = this.paientdetails.Ssid;

        } else {
          this.isAddPrimary = false;
          this.IsNoInsurancechecked = this.lstInsurance[0].IsSkipInsurance
          this.IsNoInsurance = this.IsNoInsurancechecked

          this.primaryTabCompleted = dtoInsurance.ObjDtoInsurance[0].IsProfileCompleted == null ? false : dtoInsurance.ObjDtoInsurance[0].IsProfileCompleted;
          this.insuranceTabCompleted = dtoInsurance.ObjDtoInsurance[0].IsInsuranceCompleted == null ? false : dtoInsurance.ObjDtoInsurance[0].IsInsuranceCompleted;
          this.historyTabCompleted = dtoInsurance.ObjDtoInsurance[0].IsHistoryCompleted == null ? false : dtoInsurance.ObjDtoInsurance[0].IsHistoryCompleted;
          this.esignTabCompleted = this.patientPrimary.Esignature == null || this.patientPrimary.Esignature == '' ? false : true;

        }
        this.lstUploadInsurance = dtoInsurance.ObjDtoDocuments;
        var primaryInsurance = this.lstInsurance.filter(x => x.IsPrimaryInsurance === true);
        if ((primaryInsurance && primaryInsurance.length > 0) || !primaryInsurance.isSkipInsurance) {
          this.loadPrimaryInsurance();
          // this.isEditInsurance = true;
        }
        else {
          this.isAddInsurance = false;
          this.isEditInsurance = false;
          this.getPatientAdditionalinfo(this.patientid)

        }
        this.insDataSource = new MatTableDataSource(this.lstInsurance.filter(obj => obj.IsSkipInsurance != true && obj.Ssid != null));
        this.insDataSource.paginator = this.paginator;
        this.insDataSource.sort = this.sort;

        this.insDataSourceHistory = new MatTableDataSource(this.lstInsuranceHistory.filter(obj => obj.IsSkipInsurance != true && obj.Ssid != null));
        this.insDataSourceHistory.paginator = this.paginator;
        this.insDataSourceHistory.sort = this.sort;

        this.spinnerservice.hide();
        if(this.isDelete ){
          if(!this.lstInsurance || (this.lstInsurance && this.lstInsurance.length == 0)){
            this.insurancefrm.resetForm();
            this.insuranceinfo.IsPrimaryInsurance = true;
            this.insuranceTabCompleted = false;
          }
          this.isDelete = false;
        }
        
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  skipandSaveInsurance() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.Warning.SkipInsurance
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        //this.insuranceTabCompleted = true;
        this.tabInsurancetoHistory();
        this.saveSkipInsurance();


      } else {

      }
    });

  }
  saveSkipInsurance() {

    if (this.IsNoInsurance) {
      localStorage.setItem("ISInsurance", "yes")
    }
    else {
      localStorage.setItem("ISInsurance", "no")
    }
    this.spinnerservice.show();
    let obj = {
      PatientInfoId: this.patientid
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);

    this.leadservice.postSkipInsurance(this.patientid, this.IsInsuranceResubmit).subscribe(
      res => {
        this.snackbar.success(res.message)
        this.spinnerservice.hide();

        // this.tabInsurancetoHistory();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onChangeState(state) {

    //this.ddlCities = this.getcities(state.value);  //this.ddlcityMaster.filter((item) => item.StatesId == state.value);
    if (this.ddlCitiesLoad.length >0) {
      this.ddlCities = this.ddlCitiesLoad.filter(x => x.StatesId == state.value)
    } else {
      this.leadservice.getcities(state.value).then(
        res => {
          this.ddlCities = this.commonService.decrypdata(res.cities);;
        },
        error =>
          this.spinnerservice.hide()
      );
    }

  }
  onChangeCity(city) {
    let stId = this.ddlCities.find(x => x.CitiesId == city.value).StatesId;
    this.ddlStates.filter((item) => item.StatesId == stId);
    this.patientPrimary.StatesId = this.ddlStates.find(x => x.StatesId == stId).StatesId;
  }
  onStateChnage(state) {
    // this.isSubmit = false;
    let stateItem = this.ddlstatemaster.find(x => x.StatesId == state.value);
    this.ddlCities = this.ddlCitiesLoad.filter((item) => item.StatesId == stateItem.StatesId);
    this.patientPrimary.City = '';
    // this.patientPrimary.City = Number(localStorage.getItem("sfcclocation")) == 0? '':Number(localStorage.getItem("sfcclocation"));
    console.log(this.ddlCities);
  }
  saveInsurance() {
    this.isFormSubmitted=true;
    if (!this.insurancefrm.valid) {
      this.IsInsuranceChanged = false;
      if (!this.IsNoInsurance) {
        if (this.imgInsuranceFront == "" || this.imgInsuranceFront == null || this.imgInsuranceBack == "") {
          // this.snackbar.error(constantVariables.ErrorMessage.insuranceUpload)
          if (this.imgInsuranceFront == "" || this.imgInsuranceFront == null) {
            this.InsuranceFrontNotFound = true
          }
          if (this.imgInsuranceBack == "" ||this.imgInsuranceBack == null  ) {
            this.InsuranceBackNotFound = true
          }
        }
      }
      return;
    }

     if (!this.IsNoInsurance) {
      if (this.imgInsuranceFront == "" || this.imgInsuranceBack == "" || this.imgInsuranceFront == null  || this.imgInsuranceBack == null) {
        // this.snackbar.error(constantVariables.ErrorMessage.insuranceUpload)
        if (this.imgInsuranceFront == "" || this.imgInsuranceFront == null) {
          this.InsuranceFrontNotFound = true
        }
        if (this.imgInsuranceBack == "" ||this.imgInsuranceBack == null) {
          this.InsuranceBackNotFound = true
        }
        this.IsInsuranceChanged = false;
        return
      }

      // if (this.imgInsurancePolicy == "" && this.isAddPolicyDocumnet == false) {

      //   const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      //     data: {
      //       title: '',
      //       message: constantVariables.ErrorMessage.insurancePolicyUpload
      //     }
      //   });
      //   confirmDialog.afterClosed().subscribe(result => {
      //     if (result === true) {
      //       this.isAddPolicyDocumnet = true;
      //       this.saveInsurance();
      //     } else {

      //       return
      //     }
      //   });
      //   return
      // }

      var primaryInsurance = this.lstInsurance.filter(
        x => x.IsPrimaryInsurance === true);
      if (primaryInsurance.length > 1 && this.insuranceinfo.IsPrimaryInsurance == true) {
        if (this.isEditPrimary == false) {
          this.isPrimaryInsuranceAdd();
          return
        }

      }
      this.insuranceinfo.PatientInfoId = this.patientid;
      this.insuranceinfo.IsPrimaryInsurance == null ? this.insuranceinfo.IsPrimaryInsurance = false : this.insuranceinfo.IsPrimaryInsurance;
      this.insuranceinfo.IsResubmit = this.IsInsuranceResubmit;
      let formData = new FormData();
      // formData.append('InsuranceFront', this.srcInsuranceFront);
      // formData.append('InsuranceBack', this.srcInsuranceBack);
      // formData.append('UploadAttachment', this.uploadAttachmanetSrc);

      // formData.append('PolicyDocument', this.srcInsurancePolicy);
      for (const key of Object.keys(this.insuranceinfo)) {
        const value = this.insuranceinfo[key];
        formData.append(key, value);
      }

      this.spinnerservice.show();
      this.leadservice.postInsurancedata(formData)
        .subscribe((event: any) => {
          // this.leadservice.postInsurancedata(formData).subscribe((res: any) => {


          this.postInsuranceImageFront(event.documentId);
          this.messageShow =event.message;
             
          // this.resetInsurance();

          // this.getInsurance();
          // this.isAddInsurance = false;
          // this.snackbar.success(event.message);
          // this.cancelAddMoreInsurance();
          // this.resetInsUploadflag();
          // this.uploadAttachmanetSrc = null;
          // this.isAttachmentEnabled = false;
          // if (this.isNexttoHistory) {
          //   //this.tabtohistory();
          //   this.isNexttoHistory = false;
          //   this.tabInsurancetoHistory();
          //}

        },
        err=>{
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        }); 
    }
    else {
      this.spinnerservice.show();
      //this.insuranceTabCompleted = true;
      this.saveSkipInsurance();
      this.tabInsurancetoHistory();

    }
  }

  postInsuranceImageFront(documentId)
  {
    let formData = new FormData();
     formData.append('InsuranceFront', this.srcInsuranceFront);
     formData.append("PatientInfoId", this.patientid);
     formData.append("DocumentId", documentId);
    this.leadservice.postInsuranceImage(formData)
        .subscribe((event: any) => {
          // this.leadservice.postInsurancedata(formData).subscribe((res: any) => {

          this.postInsuranceImageBack(documentId);

        },err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        });
  }
  postInsuranceImageBack(documentId)
  {
    let formData = new FormData();
    formData.append('InsuranceBack', this.srcInsuranceBack);
     formData.append("PatientInfoId", this.patientid);
     formData.append("DocumentId", documentId);
    this.leadservice.postInsuranceImage(formData)
        .subscribe((event: any) => {
          // this.leadservice.postInsurancedata(formData).subscribe((res: any) => {
            if (this.imgInsuranceFront == "") {
            this.isAddInsurance = true
          }
          if (this.imgInsuranceBack == "") {
            this.isAddInsurance = true
          }
          this.spinnerservice.hide();
          this.resetInsurance();
          this.getInsurance();
          this.isAddInsurance = false;
          this.snackbar.success(this.messageShow);
          this.cancelAddMoreInsurance();
          this.resetInsUploadflag();
          this.uploadAttachmanetSrc = null;
          this.isAttachmentEnabled = false;
          if (this.isNexttoHistory) {
            //this.tabtohistory();
            this.isNexttoHistory = false;
            this.tabInsurancetoHistory();
          }

        },  err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        });
  }

  nexttohistory() {

    this.isNexttoHistory = true;
    if (this.IsNoInsurance) {
      //this.insuranceTabCompleted = true;
      this.saveSkipInsurance();

      this.tabInsurancetoHistory();
      this.insuranceTabCompleted = true;
    }
    this.tabInsurancetoHistory();
    this.getInsAuthStatusbyPatient();
  }
  nexttoAuthorization() {
    // this.isNexttoHistory = true;
    if (this.IsNoInsurance) {
      localStorage.setItem("ISInsurance", "yes")
    }
    else {
      localStorage.setItem("ISInsurance", "no")
    }
    if (this.IsNoInsurance) {
      //this.insuranceTabCompleted = true;
      this.saveSkipInsurance();
      // this.tabInsurancetoHistory();
    }
    this.primaryTab = false;
    this.insuranceTab = false;
    //this.historyTab = false;
    this.historyTab = true;
    this.feeTab = false;
    //this.authorizationTab = true;
    this.authorizationTab = false;
    this.esignTab = false;
    localStorage.setItem("profiletab", "6");
    this.tabtohistory();
  }
  isPrimaryInsuranceAdd() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: "Would you like to add this as primary insurance"
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.isEditPrimary = true;
        this.saveInsurance();
      } else {
        this.insuranceinfo.IsPrimaryInsurance = false;
      }
    });
  }
  addMoreInsurance() {
    this.isAttachmentEnabled = true;
    localStorage.setItem("profiletab", "2");
    /**/
    if ((this.InsuranceDatas.filter(obj => obj.IsSkipInsurance != true).length) >= 3) {
      this.snackbar.error("Not allowed to add more than three");
      return;
    }
    /**/
    this.isAddInsurance = true;
    this.insuranceinfo = new Insurance();
    this.insuranceinfo.Ssid = "";
    this.insurancefrm.resetForm();
    this.imgInsuranceFront = "";
    this.imgInsuranceBack = "";
    this.imgNameAttachment = "";
    this.imgAttachment = "";
    // this.imgInsurancePolicy = "";
    //this.insuranceinfo.EmployerPhoneNo = this.paientdetails.EmployerPhoneNo;
    this.insuranceinfo.MemberName = this.paientdetails.MemberName;
    // this.insuranceinfo.Ssid = this.paientdetails.Ssid;

  }
  idAttachment: any;
  imgAttachment: any;
  imgNameAttachment: any;
  isAttached: boolean = true;
  loadPrimaryInsurance() {
    this.primaryInsurance = this.lstInsurance.filter(
      x => x.IsPrimaryInsurance === true)[0];
    if (this.primaryInsurance != null && this.primaryInsurance != undefined) {
      this.insuranceinfo = this.primaryInsurance;
      var documents = this.lstUploadInsurance.filter(
        x => x.DocumentId === this.primaryInsurance.InsuranceDetailsId);
        // this.imgInsuranceFront = null;
        // this.imgInsuranceBack = null;
      if (documents && documents.length > 0) {
        // if (this.isdeleteInscopy == 0 || this.isdeleteInscopy == 1) {
        //   this.imgInsuranceFront = documents[this.isdeleteInscopy].DocumentLocation;
        //   this.imgInsuranceBack = documents[this.isdeleteInscopy].DocumentLocation;
        //   this.imgNameInsuranceFront = documents[0].DocumentName;
        // } 
        // else 
        if (documents.length == 1) {
          if (documents[0].Description == "0") {

            this.imgInsuranceFront = documents[0].DocumentLocation;
            this.idInsuranceFront = documents[0].UploadDocumentsId;
            this.imgNameInsuranceFront = documents[0].DocumentName;
          } else if (documents[0].Description == "1") {
            this.idInsuranceBack = documents[0].UploadDocumentsId;
            this.imgInsuranceBack = documents[0].DocumentLocation;
            this.imgNameInsuranceBack = documents[0].DocumentName;
          } else if (documents[0].Description == "3") {
            this.idAttachment = documents[0].UploadDocumentsId;
            this.imgAttachment = documents[0].DocumentLocation;
            this.imgNameAttachment = documents[0].DocumentName;
          }
          // else if (documents[0].Description == "2") {
          //   this.idInsurancePolicy = documents[0].UploadDocumentsId;
          //   this.imgInsurancePolicy = documents[0].DocumentLocation;
          //   this.imgNameInsurancePolicy = documents[0].DocumentName;
          // }
        }
        else {
          for (let i = 0; i < documents.length; i++) {
            if (documents[i].Description == "0") {
              this.imgInsuranceFront = documents[i].DocumentLocation;
              this.idInsuranceFront = documents[i].UploadDocumentsId;
              this.imgNameInsuranceFront = documents[i].DocumentName;
            }
            else if (documents[i].Description == "1") {
              this.idInsuranceBack = documents[i].UploadDocumentsId;
              this.imgInsuranceBack = documents[i].DocumentLocation;
              this.imgNameInsuranceBack = documents[i].DocumentName;
            } else if (documents[i].Description == "3") {
              this.idAttachment = documents[i].UploadDocumentsId;
              this.imgAttachment = documents[i].DocumentLocation;
              this.imgNameAttachment = documents[i].DocumentName;
            }
          
          }
        }
      }

    }
  }
  resetInsurance() {
    this.isFormSubmitted=false;
    this.InsuranceFrontNotFound=false;
    this.InsuranceBackNotFound=false;
    this.insuranceinfo = new Insurance();
    this.insuranceinfo.Ssid = "";
    this.imgInsuranceFront = "";
    this.idInsuranceFront = "";
    this.imgNameInsuranceFront = "";

    this.imgInsuranceBack = "";
    this.idInsuranceBack = "";
    this.imgNameInsuranceBack = "";
    this.imgNameAttachment = "";
    // this.imgInsurancePolicy = "";
    // this.idInsurancePolicy = "";
    // this.imgNameInsurancePolicy = "";

    this.isAddPolicyDocumnet = false;
    this.isEditInsurance = false;
    this.UploadFileList = [];
    this.InsuranceFrontNotFound = false;
    this.InsuranceBackNotFound = false;
  }
  cancelAddMoreInsurance() {
    //this.insurancefrm.resetForm();
    this.uploadAttachmanetSrc = null;
    this.isAttachmentEnabled = false;
    this.resetInsurance();
    // this.loadPrimaryInsurance();
    this.isAddInsurance = false;
    this.isEditInsurance = false;
    this.InsuranceFrontNotFound = false;
    this.InsuranceBackNotFound = false;
  }
  onInsFileChange(event, uploadfrom) {
    const reader = new FileReader();
    var _validFileExtensions = [".jpg", ".jpeg", ".png"];
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if(file.size > 1048576)
      {
        this.snackbar.error(constantVariables.ErrorMessage.ImageSizeExceeded);
        return;
      }
      if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "image/webp") {
        reader.readAsDataURL(file);

        reader.onload = () => {

          //this.UploadFileList = Array.from(event.target.files);
          if (uploadfrom == 0) {
            this.isUploadinsuranceFront = true;
            this.imgInsuranceFront = reader.result as string;
            this.srcInsuranceFront = event.target.files[0];
            this.UploadFileList.push(Array.from(event.target.files));
            this.InsuranceFrontNotFound = false;

          } else if (uploadfrom == 1) {
            this.isUploadinsuranceBack = true;
            this.imgInsuranceBack = reader.result as string;
            this.srcInsuranceBack = event.target.files[0];
            this.UploadFileList.push(Array.from(event.target.files));
            this.InsuranceBackNotFound = false

          }
          // else if (this.uploadflag == "2") {
          //   this.isUploadinsurancePolicy = true;
          //   this.imgInsurancePolicy = reader.result as string;
          //   this.srcInsurancePolicy = event.target.files[0];
          //   this.UploadFileList.push(Array.from(event.target.files));

          // }
          this.eleInsurancefile.nativeElement.value = "";
        };
      } else {
        this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
      }

    }
  }
  // onInsFileUpload(event) {
  //   const reader = new FileReader();
  //   var _validFileExtensions = [".jpg", ".jpeg", ".png", ".pdf", ".doc", ".docx"];
  //   if (event.target.files && event.target.files.length) {
  //     const [file] = event.target.files;

  //     if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "application/pdf" || file.type == "application/msword" || file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
  //       reader.readAsDataURL(file);

  //       reader.onload = () => {

  //         var files = Array.from(event.target.files);

  //         for (var i = 0; i < files.length; i++) {
  //           if (this.UploadFileList.length < 3) {
  //             let _fileobj = {
  //               File: files[i],
  //               uploadflag: "",
  //               imgsrc: reader.result as string
  //             }
  //             this.UploadFileList.push(_fileobj);
  //           }
  //         }
  //         this.onchoosenInsurance();
  //       };
  //     } else {
  //       this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
  //     }
  //   }
  // }
  // onchoosenInsurance() {

  //   for (var i = 0; i < this.UploadFileList.length; i++) {

  //     if (this.UploadFileList[i].uploadflag == "0") {
  //       if (this.UploadFileList[i].File.type == "image/jpeg" || this.UploadFileList[i].File.type == "image/png") {
  //         this.imgInsuranceFront = this.UploadFileList[i].imgsrc;
  //         this.imgNameInsuranceFront = this.UploadFileList[i].File.name;
  //         this.srcInsuranceFront = this.UploadFileList[i].File;
  //         this.isUploadinsuranceFront = true;
  //       }
  //     }
  //     else if (this.UploadFileList[i].uploadflag == "1") {
  //       if (this.UploadFileList[i].File.type == "image/jpeg" || this.UploadFileList[i].File.type == "image/png") {
  //         this.imgInsuranceBack = this.UploadFileList[i].imgsrc;
  //         this.imgNameInsuranceBack = this.UploadFileList[i].File.name;
  //         this.srcInsuranceBack = this.UploadFileList[i].File;
  //         this.isUploadinsuranceBack = true;
  //       }
  //     }
  //     // else if (this.UploadFileList[i].uploadflag == "2") {
  //     //   this.imgInsurancePolicy = "../../assets/images/doc.png";
  //     //   this.imgNameInsurancePolicy = this.UploadFileList[i].File.name;
  //     //   this.srcInsurancePolicy = this.UploadFileList[i].File;
  //     //   this.isUploadinsurancePolicy = true;
  //     // }


  //   }


  //   this.eleInsurancefile.nativeElement.value = "";
  // }
  // uploadinsurancecopy() {
  //   let formData = new FormData();
  //   if (this.srcInsuranceFront !== "" && this.srcInsuranceFront != null) {
  //     formData.append('InsuranceFront', this.srcInsuranceFront);
  //   }
  //   if (this.srcInsuranceBack !== "" && this.srcInsuranceBack != null) {
  //     formData.append('InsuranceBack', this.srcInsuranceBack);
  //   }
  //   if (this.srcInsurancePolicy !== "" && this.srcInsurancePolicy != null) {
  //     formData.append('PolicyDocument', this.srcInsurancePolicy);
  //   }
  //   this.leadservice.postInsurancedata(formData)
  //     .subscribe((event: any) => {
  //     }), (err: any) => {

  //       this.spinnerservice.hide();
  //       this.snackbar.error(err.error)

  //     };
  // }
  deleteInsurancePopup(index) {
    this.UploadFileList.splice(index, 1);
  }
  oncloseInsUpload() {
    this.isInsUploadModel = false;
    // this.UploadFileList = [];
    this.eleInsurancefile.nativeElement.value = "";
  }
  onEditInsurance(data) {
    this.isAttachmentEnabled = true;
    this.resetInsUploadflag();
    this.imgInsuranceFront = "";
    this.imgInsuranceBack = "";
    this.imgInsurancePolicy = "";
    this.imgAttachment = "";
    this.imgNameAttachment = "";
    this.isAddInsurance = true;
    this.isEditInsurance = true;
    this.insuranceinfo = data;
    this.loadInsuranceDocument(data);

  }
  resetInsUploadflag() {
    this.isUploadinsuranceFront = false;
    this.isUploadinsuranceBack = false;
    this.isUploadinsurancePolicy = false;
  }
  loadInsuranceDocument(data) {
    var documents = this.lstUploadInsurance.filter(
      
      x => x.DocumentId === data.InsuranceDetailsId);

    if (documents && documents.length > 0) {
      if (documents.length == 1) {
        if (documents[0].Description == "0") {
          this.imgInsuranceFront = documents[0].DocumentLocation;
          this.idInsuranceFront = documents[0].UploadDocumentsId;
          this.imgNameInsuranceFront = documents[0].DocumentName;
        } else if (documents[0].Description == "1") {
          this.idInsuranceBack = documents[0].UploadDocumentsId;
          this.imgInsuranceBack = documents[0].DocumentLocation;
          this.imgNameInsuranceBack = documents[0].DocumentName;
        }
        else if (documents[0].Description == "3") {
          this.idAttachment = documents[0].UploadDocumentsId;
          this.imgAttachment = documents[0].DocumentLocation;
          this.imgNameAttachment = documents[0].DocumentName;
        }
      }
      else {
        for (let i = 0; i < documents.length; i++) {
          if (documents[i].Description == "0") {
            this.imgInsuranceFront = documents[i].DocumentLocation;
            this.idInsuranceFront = documents[i].UploadDocumentsId;
            this.imgNameInsuranceFront = documents[i].DocumentName;
          }
          else if (documents[i].Description == "1") {
            this.idInsuranceBack = documents[i].UploadDocumentsId;
            this.imgInsuranceBack = documents[i].DocumentLocation;
            this.imgNameInsuranceBack = documents[i].DocumentName;
          }
          else if (documents[i].Description == "3") {
            this.idAttachment = documents[i].UploadDocumentsId;
            this.imgAttachment = documents[i].DocumentLocation;
            this.imgNameAttachment = documents[i].DocumentName;
          }
        }
      }
    }
  }
  ondeleteInsurance(data) {
    if(data.IsPrimaryInsurance == true)
    {
      this.snackbar.error(constantVariables.ErrorMessage.PrimaryInsuranceDelete);
    }else{
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: '',
          message: constantVariables.SuccessMessage.DeleteConfirmMessage
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
          this.spinnerservice.show();
          this.leadservice.deleteInsurance(data.InsuranceDetailsId).subscribe(
            (res: any) => {
              
              if (data.IsPrimaryInsurance) {
                this.resetInsurance();
              }
             
              this.resetInsurance();
              this.isDelete = true;
              this.getInsurance();
              this.isAddInsurance = false;
              this.spinnerservice.hide();
              this.snackbar.success(constantVariables.SuccessMessage.DeletetedSuccesscully);
             
            },
            err => {
              this.spinnerservice.hide();
            },
          );
        }
      });
    }
   

  }
  deleteInsuranceCard(iscopyfrom) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        var id = iscopyfrom == 0 ? this.idInsuranceFront : this.idInsuranceBack;

        this.leadservice.deleteInsuranceCopy(id).subscribe(
          (res: any) => {
            this.isdeleteInscopy = iscopyfrom;
            iscopyfrom == 0 ? this.imgInsuranceFront = "" : iscopyfrom == 1 ? this.imgInsuranceBack = "" : this.imgInsurancePolicy = "";
            //this.getInsurance();
            this.spinnerservice.hide();
            if(id == this.idInsuranceFront){
              this.srcInsuranceFront = null;
              // this.imgInsuranceFront = null;
            }
            else{
              this.srcInsuranceBack = null;
              // this.imgInsuranceBack = null;
            }
            this.snackbar.success(constantVariables.SuccessMessage.DeletetedSuccesscully);
           // if(!this.lstInsurance || (this.lstInsurance && this.lstInsurance.length == 0)){
        //   this.insurancefrm.resetForm();
        //   this.insuranceinfo.IsPrimaryInsurance = true;
        // }
            
          },
          err => {
            this.spinnerservice.hide();
          },
        );
      }
    });

  }
  deleteUploadInsurance(isfrom) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        isfrom == 0 ? this.imgInsuranceFront = "" : isfrom == 1 ? this.imgInsuranceBack = "" : this.imgInsurancePolicy = "";

        if (this.UploadFileList && this.UploadFileList.length > 0) {

          this.UploadFileList.forEach((item, index) => {
            if (Number(item.uploadflag) === isfrom) {
              this.UploadFileList.splice(index, 1);
            }
          });

        }
        this.eleInsurancefile.nativeElement.value = "";
      }
    });

  }
  onviewInsurance(data) {
    window.scroll(0, 0);
    this.imgInsuranceFront = "";
    this.imgInsuranceBack = "";
    this.imgInsurancePolicy = "";
    //this.isAddInsurance = true;
    this.insuranceinfo = data;
    this.loadInsuranceDocument(data);
    this.resetInsUploadflag();
  }
  downloadinsurancecard(fileName) {
    this.spinnerservice.show();
    this.leadservice.getImage(fileName).subscribe(response => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);

      // fileSaver.saveAs(blob, fileName);
    }), error => this.spinnerservice.hide(),
      () => this.spinnerservice.hide();
  }
  // ddluploadType(event: any) {

  //   this.uploadflag = event.value;

  // }
  //#endregion

  //#region Patient history
  getPatientHistory() {

    // //this.spinnerservice.show();
    this.leadservice.getPatientHistory(this.patientid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (localStorage.getItem('currentstatus')) {
          this.immidiateactionid = Number(localStorage.getItem('currentstatus'));
        }
        this.spinnerservice.hide();
        var patienthistory = this.commonService.decrypdata(res.objInputString);
        this.patienthistoryinfo.SourceTypeId = patienthistory.SourceTypeId;
        if (patienthistory) {
          if (patienthistory.ObjDtoPatientHistory.CreatedBy !== 0) {
            this.patienthistoryinfo = patienthistory.ObjDtoPatientHistory;
            this.patienthistoryinfo.lastPhysicalExamDate=patienthistory.ObjDtoPatientHistory.lastPhysicalExamDate;
            this.patienthistoryinfo.PharmacyName = patienthistory.ObjDtoPatientHistory.PharmacyName;
            this.patienthistoryinfo.PharmacyFax = patienthistory.ObjDtoPatientHistory.PharmacyFax;
            this.patienthistoryinfo.PharmacyPhone = patienthistory.ObjDtoPatientHistory.PharmacyPhone;
            this.patienthistoryinfo.PharmacyAddress = patienthistory.ObjDtoPatientHistory.PharmacyAddress;
            this.primaryTabCompleted = patienthistory.ObjDtoPatientHistory.IsProfileCompleted == null ? true : patienthistory.ObjDtoPatientHistory.IsProfileCompleted;
            this.insuranceTabCompleted = patienthistory.ObjDtoPatientHistory.IsInsuranceCompleted == null ? true : patienthistory.ObjDtoPatientHistory.IsInsuranceCompleted;
            this.historyTabCompleted = patienthistory.ObjDtoPatientHistory.IsHistoryCompleted == null ? true : patienthistory.ObjDtoPatientHistory.IsHistoryCompleted;
            this.esignTabCompleted = this.patientPrimary.Esignature == null || this.patientPrimary.Esignature == '' ? false : true;

          } else {
            this.patienthistoryinfo.IsPhysician = null;
            this.patienthistoryinfo.IsPsychiatrist = null;
            this.patienthistoryinfo.IsTherapist = null;
            this.patienthistoryinfo.IsNeurologist = null;
            this.patienthistoryinfo.IsCaseManager = null;
            this.primaryTabCompleted = this.primaryTabCompleted == undefined ? true : this.primaryTabCompleted;
            this.insuranceTabCompleted = this.insuranceTabCompleted == undefined ? true : this.insuranceTabCompleted;
          }
          if (this.patienthistoryinfo.PhysicianDetails == null) {
            this.patienthistoryinfo.PhysicianDetails = new HospitalInfo();

          }
          else {
            this.onChangePhysicianState(this.patienthistoryinfo.PhysicianDetails.StatesId);
          }
          if (this.patienthistoryinfo.PsychiatristDetails == null) {
            this.patienthistoryinfo.PsychiatristDetails = new HospitalInfo();
          }
          else {
            this.onChangePsychiatristState(this.patienthistoryinfo.PsychiatristDetails.StatesId);
          }
          if (this.patienthistoryinfo.TherapistDetails == null) {
            this.patienthistoryinfo.TherapistDetails = new HospitalInfo();
          }
          if (this.patienthistoryinfo.NeurologistDetails == null) {
            this.patienthistoryinfo.NeurologistDetails = new HospitalInfo();
          }
          if (this.patienthistoryinfo.CaseMangerDetails == null) {
            this.patienthistoryinfo.CaseMangerDetails = new HospitalInfo();
          }
          else {
            this.onChangeTherapistState(this.patienthistoryinfo.TherapistDetails.StatesId);
          }
          if (patienthistory.ObjDtoMedication && patienthistory.ObjDtoMedication.length > 0) {
            this.medicineinfo = [];
            this.medicineinfo = patienthistory.ObjDtoMedication
          }

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  postPatientHistory() {
    if (!this.patienthistoryfrm.valid) {
      return
    }
    if(!this.isResubmit) {
      this.isResubmit = true; 
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.confirmation.onCompleteLeadProfile
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.spinnerservice.show();
        this.patienthistoryinfo.PatientInfoId = this.patientid;
        let _obj: any = {
          ObjDtoPatientHistory: this.patienthistoryinfo,
          ObjDtoMedication: this.medicineinfo
        }
        const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
        console.log(this.patienthistoryinfo);
        //return;
        this.leadservice.postPaitentHistory(dataToEncrypt).subscribe(res => {
          this.isResubmit = false; 
          this.spinnerservice.hide();
          if (res !== "") {
            this.snackbar.success(res);
            this.historyTabCompleted = true;
            this.onSavePsychotropic();
            this.getCurrentStatus();
            // this.tabtoesign();
          } else {
            this.snackbar.success(constantVariables.SuccessMessage.DataSaveSuccessfully);
            // this.tabtoesign();
            //this.snackbar.success(res);
          }
          // if (this.immidiateactionid == this.statusReturn) {
          //   var notTitle = constantVariables.NotificationTitle.onResubmitTreatment.replace('{{username}}', this.UserName)
          // } else {
          //   var notTitle = constantVariables.NotificationTitle.onsubmitTreatment.replace('{{username}}', this.UserName)
          // }
          // this.triggerInappNotification(notTitle, "", null);
        },
          err => {
            this.isResubmit = false; 
            this.spinnerservice.hide();
          }
        );
      } else {
        this.isResubmit = false; 
      }
    });

  }

  }
  addMedicine() {
    let index = this.medicineinfo.length;
    if ((this.medicineinfo[index - 1].Medication !== "")) {
      let obj: PatientMedicineInfo = {
        PatientMedicationsId: 0,
        PatientHistoryId: 0,
        PatientInfoId: this.patientid,
        Medication: null,
        IsActive: true,
      }
      this.medicineinfo.push(obj)
    }
  }
  removeMedicine(data, index) {
    data.IsActive = false
    this.medicineinfo.splice(index, 1);
  }
  //#endregion
  //#endregion
  //#region FrontDesk

  onVerifyLead() {
    this.spinnerservice.show();
    this.leadservice.postVerifyPacket(this.patientid).subscribe(res => {
      this.spinnerservice.hide();
      if (res == "") {
        this.snackbar.success(constantVariables.SuccessMessage.onverified);
        //this.triggerInappNotification(constantVariables.NotificationTitle.onVerified.replace('{{username}}', this.parentfirstname), "", this.patientid);
        this.router.navigate(['/leadlist']);
      } else {

        this.router.navigate(['/leadlist']);
        //this.snackbar.success(res);
      }
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  onReturnLead() {
    this.global.clearEmaillist();
    this.subjectTag = "Please Revise";
    this.idTag = this.patientid;
    localStorage.setItem('patientId', this.patientid);
    localStorage.setItem('parentname', this.parentfirstname);
    this.global.onOpenEmailPopup(this.parentemail);

  }
  //#endregion
  triggerInappNotification(title, comments, userId) {
    comments = comments.replace('{{username}}', this.UserName)

    this.noticationinfo.NotificationTitle = title;
    this.noticationinfo.Comments = comments;
    this.noticationinfo.RoleId = this.roleId;
    this.noticationinfo.UserId = userId;
    const dataToEncrypt = this.commonService.DataToEncrypt(this.noticationinfo, false);

    this.notify.posttriggernotification(dataToEncrypt).subscribe(res => {
      //this.spinnerservice.hide();


    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }

  getphonebook() {

    let locationid = (localStorage.getItem('sfcclocation') == null || localStorage.getItem('sfcclocation') == "undefined") ? "-1" :
      localStorage.getItem('sfcclocation');
    this.leadservice.getPhonebook(locationid).subscribe(
      res => {
        if (res && res.length > 0) {
          this.phonebook = res;
        }
      })
  }

  doPhoneFilter() {
    this.filteredphbook = [];
    if (!isNaN(this.phbook)) {
      this.filteredphbook = this.phonebook.filter(m => m.phoneNumber.startsWith(this.phbook))
    } else {
      this.filteredphbook = this.phonebook.filter(x => x.physicianName.toLowerCase().indexOf(this.phbook.toLowerCase()) !== -1);
    }

  }
  doPsychiatristPhoneFilter() {
    this.filteredPsychiatristph = [];
    if (!isNaN(this.phbook)) {
      this.filteredPsychiatristph = this.phonebook.filter(m => m.phoneNumber.startsWith(this.phPsychiatristbook))
    } else {
      this.filteredPsychiatristph = this.phonebook.filter(x => x.physicianName.toLowerCase().indexOf(this.phPsychiatristbook.toLowerCase()) !== -1);
    }
  }
  doTherapistPhoneFilter() {
    this.filteredTherapistph = [];
    if (!isNaN(this.phbook)) {
      this.filteredTherapistph = this.phonebook.filter(m => m.phoneNumber.startsWith(this.phTherapistbook))
    } else {
      this.filteredTherapistph = this.phonebook.filter(x => x.physicianName.toLowerCase().indexOf(this.phTherapistbook.toLowerCase()) !== -1);
    }
  }
  doNeurologistPhoneFilter() {
    this.filterNeurologist = [];
    if (!isNaN(this.phbook)) {
      this.filterNeurologist = this.phonebook.filter(m => m.phoneNumber.startsWith(this.phNeurologistbook))
    } else {
      this.filterNeurologist = this.phonebook.filter(x => x.physicianName.toLowerCase().indexOf(this.phNeurologistbook.toLowerCase()) !== -1);
    }
  }
  doCaseManagerPhoneFilter() {
    this.filterCaseManager = [];
    if (!isNaN(this.phbook)) {
      this.filterCaseManager = this.phonebook.filter(m => m.phoneNumber.startsWith(this.phCasemanagerbook))
    } else {
      this.filterCaseManager = this.phonebook.filter(x => x.physicianName.toLowerCase().indexOf(this.phCasemanagerbook.toLowerCase()) !== -1);
    }
  }
  bindhospitalinfo(item, type) {
    if (type == 1) {
      if (item.statesId) {
        var id = item.statesId;
        //this.ddlPhysicianCity = this.getcities(id)
        if (this.ddlCitiesLoad.length >0) {
          this.ddlPhysicianCity = this.ddlCitiesLoad.filter(x => x.StatesId == id)
        } else {
          this.leadservice.getcities(id).then(
            res => {
              this.ddlPhysicianCity = this.commonService.decrypdata(res.cities);;
            },
            error =>
              this.spinnerservice.hide()
          ); //this.ddlcityMaster.filter((item) => item.StatesId == id);
        }

      }
      this.patienthistoryinfo.PhysicianDetails.FirstName = item.firstName;
      this.patienthistoryinfo.PhysicianDetails.LastName = item.lastName;
      this.patienthistoryinfo.PhysicianDetails.Email = item.email;
      this.patienthistoryinfo.PhysicianDetails.PhoneNumber = item.phoneNumber;
      this.patienthistoryinfo.PhysicianDetails.CellPhone = item.cellPhone;
      this.patienthistoryinfo.PhysicianDetails.FaxNumber = item.faxNumber;
      this.patienthistoryinfo.PhysicianDetails.AddressLine1 = item.addressLine1;
      this.patienthistoryinfo.PhysicianDetails.AddressLine2 = item.addressLine2;
      this.patienthistoryinfo.PhysicianDetails.StatesId = item.statesId;
      this.patienthistoryinfo.PhysicianDetails.CityId = item.cityId;
      this.patienthistoryinfo.PhysicianDetails.HospitalName = item.hospitalName;
      this.patienthistoryinfo.PhysicianDetails.ZipCode = item.zipCode;



    }
    else if (type == 2) {
      if (item.statesId) {
        var id = item.statesId;
        //this.ddlPsychiatristCity = this.getcities(id); //this.ddlcityMaster.filter((item) => item.StatesId == id);
        if (this.ddlCitiesLoad.length >0) {
          this.ddlPsychiatristCity = this.ddlCitiesLoad.filter(x => x.StatesId == id)
        } else {
          this.leadservice.getcities(id).then(
            res => {
              this.ddlPsychiatristCity = this.commonService.decrypdata(res.cities);;
            },
            error =>
              this.spinnerservice.hide()
          );
        }
      }
      this.patienthistoryinfo.PsychiatristDetails.FirstName = item.firstName;
      this.patienthistoryinfo.PsychiatristDetails.LastName = item.lastName;
      this.patienthistoryinfo.PsychiatristDetails.Email = item.email;
      this.patienthistoryinfo.PsychiatristDetails.PhoneNumber = item.phoneNumber;
      this.patienthistoryinfo.PsychiatristDetails.CellPhone = item.cellPhone;
      this.patienthistoryinfo.PsychiatristDetails.FaxNumber = item.faxNumber;
      this.patienthistoryinfo.PsychiatristDetails.AddressLine1 = item.addressLine1;
      this.patienthistoryinfo.PsychiatristDetails.AddressLine2 = item.addressLine2;
      this.patienthistoryinfo.PsychiatristDetails.StatesId = item.statesId;
      this.patienthistoryinfo.PsychiatristDetails.CityId = item.cityId;
      this.patienthistoryinfo.PsychiatristDetails.ZipCode = item.zipCode;
      this.patienthistoryinfo.PsychiatristDetails.HospitalName = item.hospitalName;

    }
    else if (type == 3) {
      if (item.statesId) {
        var id = item.statesId;
        //this.ddlTherapistCity = this.getcities(id); //this.ddlcityMaster.filter((item) => item.StatesId == id);
        if (this.ddlCitiesLoad.length >0) {
          this.ddlTherapistCity = this.ddlCitiesLoad.filter(x => x.StatesId == id)
        } else {
          this.leadservice.getcities(id).then(
            res => {
              this.ddlTherapistCity = this.commonService.decrypdata(res.cities);;
            },
            error =>
              this.spinnerservice.hide()
          );
        }
      }
      this.patienthistoryinfo.TherapistDetails.FirstName = item.firstName;
      this.patienthistoryinfo.TherapistDetails.LastName = item.lastName;
      this.patienthistoryinfo.TherapistDetails.Email = item.email;
      this.patienthistoryinfo.TherapistDetails.PhoneNumber = item.phoneNumber;
      this.patienthistoryinfo.TherapistDetails.CellPhone = item.cellPhone;
      this.patienthistoryinfo.TherapistDetails.FaxNumber = item.faxNumber;
      this.patienthistoryinfo.TherapistDetails.AddressLine1 = item.addressLine1;
      this.patienthistoryinfo.TherapistDetails.AddressLine2 = item.addressLine2;
      this.patienthistoryinfo.TherapistDetails.StatesId = item.statesId;
      this.patienthistoryinfo.TherapistDetails.CityId = item.cityId;
      this.patienthistoryinfo.TherapistDetails.ZipCode = item.zipCode;
      this.patienthistoryinfo.TherapistDetails.HospitalName = item.hospitalName;
    }
    else if (type == 4) {
      if (item.statesId) {
        var id = item.statesId;
        //this.ddlTherapistCity = this.getcities(id); //this.ddlcityMaster.filter((item) => item.StatesId == id);
        if (this.ddlCitiesLoad.length >0) {
          this.ddlTherapistCity = this.ddlCitiesLoad.filter(x => x.StatesId == id)
        } else {
          this.leadservice.getcities(id).then(
            res => {
              this.ddlTherapistCity = this.commonService.decrypdata(res.cities);;
            },
            error =>
              this.spinnerservice.hide()
          );
        }
      }
      this.patienthistoryinfo.NeurologistDetails.FirstName = item.firstName;
      this.patienthistoryinfo.NeurologistDetails.LastName = item.lastName;
      this.patienthistoryinfo.NeurologistDetails.Email = item.email;
      this.patienthistoryinfo.NeurologistDetails.PhoneNumber = item.phoneNumber;
      this.patienthistoryinfo.NeurologistDetails.CellPhone = item.cellPhone;
      this.patienthistoryinfo.NeurologistDetails.FaxNumber = item.faxNumber;
      this.patienthistoryinfo.NeurologistDetails.AddressLine1 = item.addressLine1;
      this.patienthistoryinfo.NeurologistDetails.AddressLine2 = item.addressLine2;
      this.patienthistoryinfo.NeurologistDetails.StatesId = item.statesId;
      this.patienthistoryinfo.NeurologistDetails.CityId = item.cityId;
      this.patienthistoryinfo.NeurologistDetails.ZipCode = item.zipCode;
      this.patienthistoryinfo.NeurologistDetails.HospitalName = item.hospitalName;
    }
    else if (type == 5) {
      if (item.statesId) {
        var id = item.statesId;
        //this.ddlTherapistCity = this.getcities(id); //this.ddlcityMaster.filter((item) => item.StatesId == id);
        if (this.ddlCitiesLoad.length >0) {
          this.ddlTherapistCity = this.ddlCitiesLoad.filter(x => x.StatesId == id)
        } else {
          this.leadservice.getcities(id).then(
            res => {
              this.ddlTherapistCity = this.commonService.decrypdata(res.cities);;
            },
            error =>
              this.spinnerservice.hide()
          );
        }
      }
      this.patienthistoryinfo.CaseMangerDetails.FirstName = item.firstName;
      this.patienthistoryinfo.CaseMangerDetails.LastName = item.lastName;
      this.patienthistoryinfo.CaseMangerDetails.Email = item.email;
      this.patienthistoryinfo.CaseMangerDetails.PhoneNumber = item.phoneNumber;
      this.patienthistoryinfo.CaseMangerDetails.CellPhone = item.cellPhone;
      this.patienthistoryinfo.CaseMangerDetails.FaxNumber = item.faxNumber;
      this.patienthistoryinfo.CaseMangerDetails.AddressLine1 = item.addressLine1;
      this.patienthistoryinfo.CaseMangerDetails.AddressLine2 = item.addressLine2;
      this.patienthistoryinfo.CaseMangerDetails.StatesId = item.statesId;
      this.patienthistoryinfo.CaseMangerDetails.CityId = item.cityId;
      this.patienthistoryinfo.CaseMangerDetails.ZipCode = item.zipCode;
      this.patienthistoryinfo.CaseMangerDetails.HospitalName = item.hospitalName;
    }

  }
  onChangePhysicianState(state) {
    if (this.ddlCitiesLoad.length>0) {
      this.ddlPhysicianCity = this.ddlCitiesLoad.filter(x => x.StatesId == state)
    } else {
      this.leadservice.getcities(state).then(
        res => {
          this.ddlPhysicianCity = this.commonService.decrypdata(res.cities);;
        },
        error =>
          this.spinnerservice.hide()
      );
    }
  }
  onChangePsychiatristState(state) {
    if (this.ddlCitiesLoad.length>0) {
      this.ddlPsychiatristCity = this.ddlCitiesLoad.filter(x => x.StatesId == state)
    } else {
      this.leadservice.getcities(state).then(
        res => {
          this.ddlPsychiatristCity = this.commonService.decrypdata(res.cities);;
        },
        error =>
          this.spinnerservice.hide()
      );
    }
  }
  onChangeTherapistState(state) {
    if (this.ddlCitiesLoad.length >0) {
      this.ddlTherapistCity = this.ddlCitiesLoad.filter(x => x.StatesId == state)
    } else {
      this.leadservice.getcities(state).then(
        res => {
          this.ddlTherapistCity = this.commonService.decrypdata(res.cities);;
        },
        error =>
          this.spinnerservice.hide()
      );
    }
  }
  // async getcities(id){
  //  this.leadservice.getcities(id).then(
  //   res => {
  //    return  this.commonService.decrypdata(res.cities);;
  //   },
  //   error =>
  //   this.spinnerservice.hide()
  // );
  onChangeAddress(term: any) {
    // this.isSubmit = false;
    this.addlookup.AutocompleteAddress(term).then(data => {
      this.addressoption = data.result as any[];
    }).catch();
  }
  onselectaddress(item) {
    this.ddlStates = this.ddlstatemaster.filter((x) => x.StateCode == item.state);
    this.patientPrimary.StatesId = this.ddlStates[0].StatesId
    this.patientPrimary.Zip = item.zipcode;
    this.onloadcity(this.ddlStates[0].StatesId, item);

  }
  onloadcity(id, item) {
    if (this.ddlCitiesLoad.length >0) {
      this.ddlCities = this.ddlCitiesLoad.filter(x => x.StatesId == id);
      this.patientPrimary.City = this.ddlCities.filter((x) => x.CityName == item.city)[0].CitiesId;
    } else {
      this.leadservice.getcities(id).then(
        res => {
          this.ddlCities = this.commonService.decrypdata(res.cities);
          this.patientPrimary.City = this.ddlCities.filter((x) => x.CityName == item.city)[0].CitiesId;
        },
        error =>
          this.spinnerservice.hide()
      );
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(EsignDialogComponent,
      { data: { parentinfoId: this.patientPrimary.ParentInfoId } });
    dialogRef.afterClosed().subscribe(result => {
      if (this.global.esign != null && this.global.esign != '') {
        this.esignTabCompleted = true;
        this.signatureImg = this.global.esign;
      }
    });
  }
  addParent() {
    // this.isSubmit = false;
    this.patientPrimary = new ParentInfo();
    this.patientPrimary.ParentInfoId = 0;
    this.oldEmail= null;
    this.disablePrimaryContact = false;
  }
  editparent(data: any) {
    // console.log(data)
    this.ddlCities = this.ddlCitiesLoad.filter(x => x.StatesId == data.StatesId);
    this.patientPrimary = data
    this.patientPrimary.City = +this.patientPrimary.City;
    this.oldEmail = data.Email;
    this.disablePrimaryContact = true;
  }
  deleteparent(data: any)
  {
    if(data.IsPrimaryContact)
    {
      this.snackbar.error(constantVariables.ErrorMessage.PrimaryContactDelete);
    }else{

      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: '',
          message: constantVariables.SuccessMessage.DeleteConfirmMessage
        }
      });
      confirmDialog.afterClosed().subscribe(result => {
        if (result === true) {
          this.spinnerservice.show();
          this.imgInsuranceFront = "";
          this.imgInsuranceBack = "";
          this.imgNameAttachment = "";
          this.imgAttachment = "";
          this.leadservice.deleteParentinfo(data.ParentInfoId).subscribe(
            (res: any) => {
              this.spinnerservice.hide();
              this.getParentAdditionalinfo(this.patientid);
            },
           
            err => {
              this.spinnerservice.hide();
            },
          );
        }
      });
    }
  }
  makePrimaryContact() {
    if(!this.leadinfo.form.controls.Email.valid)
        {
          this.isValidEmail = false;
        }else{
          this.isValidEmail = true;
        }
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.confirmation.ChangePrimaryEmailAddress.replace('{{email}}',this.patientPrimary.Email).replace('{{email1}}',this.patientPrimary.Email)
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.patientPrimary.IsPrimaryContact = true;
        this.patientPrimary.IsEmergency = !this.patientPrimary.IsPrimaryContact;
        this.isPrimaryContactChanged = true;
      
        this.addorUpdateParent();
      }
      else {
        this.patientPrimary.IsPrimaryContact = false;
        this.patientPrimary.IsEmergency = !this.patientPrimary.IsPrimaryContact;
        this.isPrimaryContactChanged = false;
      }
    });
  }
  checkRequiredFileds()
  {
    var valid = true;
    if (this.patientPrimary.CellNumber == null || this.patientPrimary.CellNumber == '' || Number.isNaN(this.patientPrimary.CellNumber)) {
      valid = false;
    }
      if (this.patientPrimary.Email == null || this.patientPrimary.Email == '' || Number.isNaN(this.patientPrimary.Email)) {
        valid = false;
      
    }
    if (this.patientPrimary.ParentalRelationTypeId == null || this.patientPrimary.ParentalRelationTypeId == 0 || Number.isNaN(this.patientPrimary.ParentalRelationTypeId)) {
      valid = false;
    }

    if (this.patientPrimary.LastName == null || this.patientPrimary.LastName == '' || Number.isNaN(this.patientPrimary.LastName)) {
      valid = false;
    }

    if (this.patientPrimary.FirstName == "" || this.patientPrimary.FirstName == null || this.patientPrimary.FirstName == 0 || Number.isNaN(this.patientPrimary.FirstName)) {
      valid = false;
    }
    if (this.patientPrimary.AddressLine1 == null || this.patientPrimary.AddressLine1 == 0 || Number.isNaN(this.patientPrimary.AddressLine1)) {
      valid = false;
    }
    if (this.patientPrimary.City == null || this.patientPrimary.City == 0 || Number.isNaN(this.patientPrimary.City) || this.patientPrimary.City == '') {
      this.patientPrimary.City =  this.patientPrimary.City == 0 ?'':this.patientPrimary.City ;
      valid = false;
    }
    if (this.patientPrimary.Zip == null || this.patientPrimary.Zip == 0 || Number.isNaN(this.patientPrimary.Zip)) {
      valid = false;
    }
    if(!this.leadinfo.form.controls.Email.valid)
    {
      valid = false;
    }
    if (!valid) { 
      this.disablePrimaryContact = false;
      this.patientPrimary.IsEmergency = !this.patientPrimary.IsPrimaryContact;
        this.isPrimaryContactChanged = false;
    }else{
      this.disablePrimaryContact = true;
    }
  }

  addorUpdateParent() {
    // debugger
    if (!this.patientPrimary.IsPrimaryContact) {
      this.patientPrimary.IsEmergency = true;
      this.patientPrimary.IsPrimaryContact = false;
    }
    // this.isSubmit = true;
    var valid = true;
    // if (this.patientinfo.LastName == null || this.patientinfo.LastName == '') {
    //   valid = false;
    // }

    // // if (this.patientinfo.FirstName == "" || this.patientinfo.FirstName == null || this.patientPrimary.FirstName == NaN) {
    // if (this.patientinfo.FirstName == "" || this.patientinfo.FirstName == null || Number.isNaN(this.patientPrimary.FirstName)) {

    //   valid = false;
    // }
    // if (this.patientinfo.SchoolName == null || this.patientinfo.SchoolName == "" || Number.isNaN(this.patientPrimary.SchoolName)) {
    //   valid = false;
    // }
    // if (this.patientinfo.TeachersName == "" || this.patientinfo.TeachersName == null || Number.isNaN(this.patientPrimary.TeachersName)) {
    //   valid = false;
    // }

    if (this.patientPrimary.CellNumber == null || this.patientPrimary.CellNumber == '' || Number.isNaN(this.patientPrimary.CellNumber)) {
      this.leadinfo.form.controls.CellNumber.markAsTouched();
      valid = false;
    }
    // if (this.patientPrimary.Email == null || this.patientPrimary.Email == '' || this.patientPrimary.Email == NaN) {
    //if (this.patientPrimary.IsPrimaryContact) {
      if (this.patientPrimary.Email == null || this.patientPrimary.Email == '' || Number.isNaN(this.patientPrimary.Email)) {
        this.leadinfo.form.controls.Email.markAsTouched();
        valid = false;
      }
    //}
    //  if (this.patientPrimary.ZipCode == null || this.patientPrimary.OldEmail == '' || this.patientPrimary.ZipCode == NaN) {
    //    valid = false;
    //  }

    // if (this.patientPrimary.MaritalStatus == null || this.patientPrimary.MaritalStatus == '' || this.patientPrimary.MaritalStatus == NaN) {
    //   if (this.patientPrimary.MaritalStatus == null || this.patientPrimary.MaritalStatus == '' || Number.isNaN(this.patientPrimary.MaritalStatus)) {
    //     valid = false;
    // }

    // if (this.patientPrimary.ParentalRelationTypeId == null || this.patientPrimary.ParentalRelationTypeId == 0 || this.patientPrimary.ParentalRelationTypeId == NaN) {
    if (this.patientPrimary.ParentalRelationTypeId == null || this.patientPrimary.ParentalRelationTypeId == 0 || Number.isNaN(this.patientPrimary.ParentalRelationTypeId)) {
      this.leadinfo.form.controls.ParentalRelationTypeId.markAsTouched();
      valid = false;
    }

    if (this.patientPrimary.LastName == null || this.patientPrimary.LastName == '' || Number.isNaN(this.patientPrimary.LastName)) {
      this.leadinfo.form.controls.PLastName.markAsTouched();
      valid = false;
    }

    if (this.patientPrimary.FirstName == "" || this.patientPrimary.FirstName == null || this.patientPrimary.FirstName == 0 || Number.isNaN(this.patientPrimary.FirstName)) {
      this.leadinfo.form.controls.PFirstName.markAsTouched();
      valid = false;
    }
    if (this.patientPrimary.AddressLine1 == null || this.patientPrimary.AddressLine1 == 0 || Number.isNaN(this.patientPrimary.AddressLine1)) {
      this.leadinfo.form.controls.AddressLine1.markAsTouched();
      valid = false;
    }
    if (this.patientPrimary.City == null || this.patientPrimary.City == 0 || Number.isNaN(this.patientPrimary.City) || this.patientPrimary.City == '') {
      this.patientPrimary.City =  this.patientPrimary.City == 0 ?'':this.patientPrimary.City ;
      this.leadinfo.form.controls.City.markAsTouched();
      valid = false;
    }
    if (this.patientPrimary.StatesId == null || this.patientPrimary.StatesId == 0 || Number.isNaN(this.patientPrimary.StatesId) || this.patientPrimary.StatesId == '') {
    
      this.leadinfo.form.controls.StatesId.markAsTouched();
      valid = false;
    }
    if (this.patientPrimary.Zip == null || this.patientPrimary.Zip == 0 || Number.isNaN(this.patientPrimary.Zip)) {
      this.leadinfo.form.controls.Zip.markAsTouched();
      valid = false;
    }
    if(!this.leadinfo.form.controls.Email.valid && this.patientPrimary.ParentInfoId == 0)
    {
      if(!this.isValidEmail)
      {
        valid = false;
      }
    }
    if (!valid) { 
      if(this.patientPrimary.ParentInfoId == 0)
      {
        this.disablePrimaryContact = false;
        this.patientPrimary.IsPrimaryContact = false;
        this.patientPrimary.IsEmergency = !this.patientPrimary.IsPrimaryContact;
          this.isPrimaryContactChanged = false;
      }
      // this.isSubmit = true;
      // this.leadinfo.form.markAllAsTouched();
      // this.leadinfo.form.markAsDirty();
      return; }
    this.patientPrimary.PatientInfoId = this.patientid;
    this.patientPrimary.IsPrimaryContactChanged = this.isPrimaryContactChanged;
    this.patientPrimary.PrimaryMail = this.primaryMail;
    if (this.isPrimaryContactChanged) {
      // this.patientPrimary.IsPrimaryContact = true;
      this.patientPrimary.oldEmail = this.oldEmail;
      const dataToEncrypt = this.commonService.DataToEncrypt(this.patientPrimary, false);
      this.spinnerservice.show();
      this.leadservice.postParent(dataToEncrypt).subscribe((res: any) => {
        if (res) {
          this.spinnerservice.hide();
          this.snackbar.success(res);
          // this.isSubmit = false;
          if(this.patientPrimary.IsPrimaryContactChanged == true && this.isAdminClicked == false)
          {
            
            this.snackbar.errorLongDuration(constantVariables.ErrorMessage.ActivationMailSend);
            this.authenticationService.onLogout();
          }
          if (this.isAdminClicked) {
            this._location.back();
          } else {
            this.getParentAdditionalinfo(this.patientid);
          }
        }
      },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
          if(this.patientPrimary.ParentInfoId == 0)
          {
            this.patientPrimary.IsPrimaryContact = false;
            this.patientPrimary.IsEmergency = !this.patientPrimary.IsPrimaryContact;
              this.isPrimaryContactChanged = false;
          }
        }
      );
    }
    else {
      // this.patientPrimary.IsPrimaryContact = false;
      // const dataToEncrypt = this.commonService.DataToEncrypt(this.patientPrimary, false);
      this.spinnerservice.show();
      if (this.patientPrimary.email != this.Email) {
        this.isEmailChanged = true;
      }
      this.patientPrimary.oldEmail = this.oldEmail;
      this.patientPrimary.screenName = "UpdateEmailProfile";
      const dataToEncrypt = this.commonService.DataToEncrypt(this.patientPrimary, false);
      this.leadservice.postParent(dataToEncrypt).subscribe((res: any) => {
        if (res) {
          this.spinnerservice.hide();
          this.snackbar.success(res);
          if (this.isEmailChanged) {
            this.updateEmail();
          }
          // this.isSubmit = false;
          if (this.isAdminClicked) {
            this._location.back();
          } else {
            this.getParentAdditionalinfo(this.patientid);
          }
        }
      },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
          if(this.patientPrimary.ParentInfoId == 0)
          {
            this.patientPrimary.IsPrimaryContact = false;
            this.patientPrimary.IsEmergency = !this.patientPrimary.IsPrimaryContact;
              this.isPrimaryContactChanged = false;
          }
        }
      );

    }
    // return;
    // if (this.parentPrimary.ParentInfoId != this.patientPrimary.ParentInfoId || (this.patientPrimary.ParentInfoId == 0 && this.patientPrimary.IsPrimaryContact)) {

    //   // const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    //   //   data: {
    //   //     title: '',
    //   //     message: constantVariables.confirmation.ChangePrimaryMessage
    //   //   }
    //   // });
    //   // confirmDialog.afterClosed().subscribe(result => {
    //     if (this.isPrimaryContactChanged) {
    //       this.patientPrimary.IsPrimaryContact = true;
    //       this.patientPrimary.oldEmail = this.oldEmail;
    //       const dataToEncrypt = this.commonService.DataToEncrypt(this.patientPrimary, false);
    //       this.spinnerservice.show();
    //       this.leadservice.postParent(dataToEncrypt).subscribe((res: any) => {
    //         if (res) {
    //           this.spinnerservice.hide();
    //           this.snackbar.success(res);
    //           this.isSubmit = false;
    //           if(this.isAdminClicked)
    //           {
    //             this._location.back();
    //           }else{
    //             this.getParentAdditionalinfo(this.patientid);
    //           }
    //         }
    //       },
    //         err => {
    //           this.spinnerservice.hide();
    //           this.snackbar.error(err.error);
    //         }
    //       );
    //     }
    //     else {
    //       this.patientPrimary.IsPrimaryContact = false;
    //       const dataToEncrypt = this.commonService.DataToEncrypt(this.patientPrimary, false);
    //       this.spinnerservice.show();
    //       if(this.patientPrimary.email != this.Email)
    //       {
    //         this.isEmailChanged =true;
    //       }
    //       this.patientPrimary.oldEmail = this.oldEmail;
    //       this.patientPrimary.screenName ="UpdateEmailProfile";
    //       this.leadservice.postParent(dataToEncrypt).subscribe((res: any) => {
    //         if (res) {
    //           this.spinnerservice.hide();
    //           this.snackbar.success(res);
    //           if(this.isEmailChanged)
    //           {
    //             this.updateEmail();
    //           }
    //           this.isSubmit = false;
    //           if(this.isAdminClicked)
    //           {
    //             this._location.back();
    //           }else{
    //             this.getParentAdditionalinfo(this.patientid);
    //           }
    //         }
    //       },
    //         err => {
    //           this.spinnerservice.hide();
    //           this.snackbar.error(err.error);
    //         }
    //       );

    //     }
    //   // });
    // }
    // else {
    //   this.patientPrimary.oldEmail = this.oldEmail;
    //   const dataToEncrypt = this.commonService.DataToEncrypt(this.patientPrimary, false);
    //   this.spinnerservice.show();
    //   this.leadservice.postParent(dataToEncrypt).subscribe((res: any) => {
    //     if (res) {
    //       this.spinnerservice.hide();
    //      this.snackbar.success(res);
    //      if(this.isEmailChanged)
    //      {
    //        this.updateEmail();
    //      }
    //      this.isSubmit = false;
    //      if(this.isAdminClicked)
    //      {
    //        this._location.back();
    //      }else{
    //       this.getParentAdditionalinfo(this.patientid);
    //      }
    //     }
    //   },
    //     err => {
    //       this.spinnerservice.hide();
    //       this.snackbar.error(err.error);
    //     }
    //   );
    // }
  }
  updateEmail() {

    this.spinnerservice.show();
    let data = {
      UserId: this.userId,
      oldEmail: this.Email,
      NewEmail: this.patientPrimary.email
      // th.oldEmail = this.patientPrimary.Email;

    };
    const dataToEncrypt = {
      ObjInputString: EncryptFieldData((JSON.stringify(data))),
    };
    this.userService.UpdateEmail(dataToEncrypt).subscribe(
      res => {

        this.spinnerservice.hide();
        this.snackbar.success(constantVariables.SuccessMessage.EmailUpdatedSucess);
        this.authenticationService.onLogout();
      },
      err => {

        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },)
  }
  getParentAdditionalinfo(id) {
    //this.spinnerservice.show();
    this.leadservice.getPatientAdditionalinfo(id).subscribe(
      (res: any) => {

        var patientprofile = this.commonService.decrypdata(res.objInputString);
        if (patientprofile) {

          // this.patientinfo = patientprofile.ObjDtoPatientInfo[0];
          // this.global.patientInfoStatus = this.patientinfo.PatientStatus
          // this.global.patientInfoStatusCreatedon = this.patientinfo.CreatedOn;
          // this.immidiateactionid = patientprofile.ObjDtoPatientInfo[0].ImmediateActionId;
          // if (localStorage.getItem("currentstatus") === null) {
          //   localStorage.setItem("currentstatus", this.immidiateactionid);
          // }

          this.patientPrimary = patientprofile.ObjDtoParentInfo.filter(
            x => x.IsPrimaryContact === true)[0];
            this.oldEmail = this.patientPrimary.Email;
            this.isPrimaryContactChanged = false;
            this.primaryMail = this.patientPrimary.Email;
          this.parentPrimary = patientprofile.ObjDtoParentInfo.filter(
            x => x.IsPrimaryContact === true)[0];
          if (this.patientPrimary.Esignature != null && this.patientPrimary.Esignature != '') {
            const image = this.commonService.FrontEndDecryption(this.patientPrimary.Esignature);
            this.signatureImg = this.domSrv.bypassSecurityTrustResourceUrl(image);
            this.signatureImg = this.signatureImg.changingThisBreaksApplicationSecurity?.replace(/"/g, "");
          }
          if (!this.patientPrimary.IsSignFuture) {
            this.signatureImg = null;
          }
          this.parentStateId = this.patientPrimary.StatesId;
          if (this.parentStateId) {
            //this.ddlCities = this.getcities(this.parentStateId);
            if (this.ddlCitiesLoad.length >0) {
              this.ddlCities = this.ddlCitiesLoad.filter(x => x.StatesId == this.parentStateId)
            } else {
              this.leadservice.getcities(this.parentStateId).then(
                res => {
                  this.ddlCities = this.commonService.decrypdata(res.cities);;
                },
                error =>
                  this.spinnerservice.hide()
              );
            }
          }
          this.patientPrimary.City = Number(this.patientPrimary.City)
          // this.primaryTabCompleted = this.patientinfo.IsProfileCompleted == null ? false : this.patientinfo.IsProfileCompleted;
          // this.insuranceTabCompleted = this.patientinfo.IsInsuranceCompleted == null ? false : this.patientinfo.IsInsuranceCompleted;
          // this.historyTabCompleted = this.patientinfo.IsHistoryCompleted == null ? false : this.patientinfo.IsHistoryCompleted;

          this.dataSource = new MatTableDataSource(patientprofile.ObjDtoParentInfo);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          if (this.patientPrimary.IsSignFuture == null) {
            this.esignTabCompleted = false;
          } else if (this.patientPrimary.IsSignFuture && (this.patientPrimary.Esignature !== null && this.patientPrimary.Esignature !== '')) {
            this.esignTabCompleted = true;
          }
          else if (!this.patientPrimary.IsSignFuture) {
            this.esignTabCompleted = true;
          }
          else {
            this.esignTabCompleted = false;
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  getPsychotropicMedication() {
    this.patientservice.getPsychotropicMedication(this.patientid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res && res.length > 0) {
          this._dtoMedications = cloneDeep(res);
        }
        // else {
        //   this.onAddMedication();
        // }

      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onAddMedication() {
    if (this._dtoMedications == null || this._dtoMedications.length == 0) {
      this._dtoMedications = [];
      for (let i = 0; i < 2; i++) {
        this._dtoMedications.push({
          psychotropicMedicationId: 0,
          patientInfoId: Number(this.patientid),
          medications: null,
          dosage: null,
          frequency: null,
          sideEffects: null
        })
      }
    }
  }
  removeclinicalinformation(data, index) {
    if (data.psychotropicMedicationId != 0) {
      this.removeexistMedinfo(data);
    }
    this._dtoMedications.splice(index, 1);
  }
  removeexistMedinfo(data) {
    this.patientservice.removePsychotropicMed(data.psychotropicMedicationId).subscribe((res: any) => {
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  addMedicationInfo() {
    this._dtoMedications.push({
      psychotropicMedicationId: 0,
      patientInfoId: Number(this.patientid),
      medications: null,
      dosage: null,
      frequency: null,
      sideEffects: null
    })
  }

  onSavePsychotropic() {

    //this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoMedications, false);
    this.patientservice.postPsychotropic(this._dtoMedications).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //this.snackbar.success(JSON.parse(res).message);
        this.getPsychotropicMedication();

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }

  OnNoInsurance() {
    this.IsNoInsurance = !this.IsNoInsurance
    if (this.IsNoInsurance == true) {
      //  this.resetInsurance();
      this.insuranceinfo = new Insurance();
      // this.insurancefrm.resetForm();
      // this.isEditInsurance = true;
      // this.isAddInsurance = true
      this.imgInsuranceFront = "";
      this.imgInsuranceBack = "";
      this.isAddInsurance = false;
      this.isEditInsurance = false;
      // this.insDataSource = new MatTableDataSource();
    }
    else {
      //this.addMoreInsurance();
      // this.getInsurance()
      // this.insuranceinfo = new Insurance();
      // this.insurancefrm.resetForm();
      this.imgInsuranceFront = "";
      this.imgInsuranceBack = "";
      this.isAddInsurance = true;
      this.isEditInsurance = false;
      //  this.insuranceinfo.EmployerPhoneNo = this.paientdetails.EmployerPhoneNo;
      this.insuranceinfo.MemberName = this.paientdetails.MemberName;
      // this.insuranceinfo.Ssid = this.paientdetails.Ssid;
      this.insuranceinfo.EmployerPhoneNo = "";
      // this.insuranceinfo.MemberName = "";
      this.insuranceinfo.Ssid = "";
    }
    // this.imgInsurancePolicy = "";


    //  console.log(this.IsNoInsurance)
  }

  getCurrentStatus() {
    this.spinnerservice.show();
    this.commonService.getLeadCurrentStatus(this.patientid).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != 0) {
          localStorage.setItem("currentstatus", res);
          this.LeadStatus = res;
          this.immidiateactionid = res;
          //      console.log(this.LeadStatus);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }

  tabPrimarytoAuthorization() {
    this.primaryTab = false;
    this.insuranceTab = false;
    this.historyTab = false;
    this.feeTab = false;
    this.esignTab = false;
    //this.authorizationTab = true
    this.authorizationTab = false;
    this.getddlMaster();
    // this.getddlStateMaster();
    this.getphonebook();
    this.getPatientHistory();
    this.getPsychotropicMedication();

    localStorage.setItem("profiletab", "6");
    this.getInsAuthStatusbyPatient();
  }

  getInsAuthStatusbyPatient() {
    //this.spinnerservice.show()
    this.authorizeTabCompleted = true;
    // this.leadservice.GetInsAuthorizationStatus(this.patientid).subscribe((res:any)=>{
    //   this.spinnerservice.hide()
    //   if(res){
    //     debugger;
    //     this.authorizeTabCompleted = res
    //   }
    // },
    // err=>{
    //   this.spinnerservice.hide()
    // })
  }
  //Payment Details tab

  tabEsignToPaymentdetails() {
    this.primaryTab = false;
    this.insuranceTab = false;
    this.historyTab = false;
    this.feeTab = false;
    this.esignTab = false;
    this.authorizationTab = false;
    this.paymentDetailsTab = true;
    this.getddlMaster();
    //this.getddlStateMaster();
    this.getphonebook();
    this.getPatientHistory();
    this.getPsychotropicMedication();

    localStorage.setItem("profiletab", "7");
    this.getPaymentDtlExists();

  }
  getPaymentDtlExists() {
    this.spinnerservice.show()
    this.leadservice.ChkPaymentDtlExists(this.patientid).subscribe((res: any) => {
      this.spinnerservice.hide()
      if (res) {
        this.paymentDetailsTabCompleted = res
      }
    },
      err => {
        this.spinnerservice.hide()
      })
  }
  //Payment Details tab --end
  //Resubmit Form option
  OnresubmitLeadinfo() {
    this.IsleadResubmit = true;
    this.updateleadinfo(1);
  }

  OnResubmitInsuranceTab() {
    this.IsInsuranceResubmit = true;
    if (this.IsNoInsurance) {
      this.insuranceTabCompleted = true;
      this.saveSkipInsurance();
      this.tabInsurancetoHistory();
    }
    else {
      this.saveInsurance();
      this.tabInsurancetoHistory();
    }
  }
  isResubmit = false;
  OnResubmitHistoryTab(){
    this.isResubmit = false;
      this.postPatientHistory();


  }
  //Resubmit end
  addAuthorization() { this.isShowAuthorization = true; }
  hideAuthorization() { this.isShowAuthorization = false; }

  @ViewChild('insuranceUpload') fileUpload: ElementRef;
  uploadAttachment = '';
  uploadAttachmanetSrc: any;
  isAttachment: boolean = false;
  isAttachmentEnabled: boolean = false;
  onInsuranceUpload(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      reader.readAsDataURL(file);

      reader.onload = () => {
        var formData = new FormData();
        this.isAttachmentEnabled = true;
        this.imgNameAttachment = event.target.files[0].name;
        this.uploadAttachmanetSrc = event.target.files[0];
        this.idAttachment = null;
        this.fileUpload.nativeElement.value = null;
      }
    }
  }
  openAttachment() {
    if (this.idAttachment != null) {
      window.open(this.imgAttachment, "_blank");
    }

  }
  deleteAttachment(id) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.DeleteConfirmMessage
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        if (this.imgAttachment != null && this.idAttachment != null) {
          this.spinnerservice.show();

          this.leadservice.deleteInsuranceCopy(this.idAttachment).subscribe(
            (res: any) => {
              this.spinnerservice.hide();
              this.snackbar.success(constantVariables.SuccessMessage.DeletetedSuccesscully);
              this.resetInsurance();
              this.getInsurance();
            },
            err => {
              this.spinnerservice.hide();
            },
          );
        } else {
          this.idAttachment = "";
          this.imgAttachment = "";
          this.imgNameAttachment = "";
          this.uploadAttachmanetSrc = "";
          this.isAttachmentEnabled = false;
          this.fileUpload.nativeElement.value = null;
        }
      }
    });
  }
  onAddPsyChange(item) {
    if (!this.patienthistoryinfo.IsPsychiatrist && item == 1) {
      this.patienthistoryinfo.PsychiatristDetails = new HospitalInfo()
    }
    if (this.patienthistoryinfo.IsPsychiatrist && item == 1) {
      this.patienthistoryinfo.PsychiatristDetails = new HospitalInfo()
    }
    if (!this.patienthistoryinfo.IsNeurologist && item == 2) {
      this.patienthistoryinfo.NeurologistDetails = new HospitalInfo()
    }
    if (this.patienthistoryinfo.IsNeurologist && item == 2) {
      this.patienthistoryinfo.NeurologistDetails = new HospitalInfo()
    }
    if (!this.patienthistoryinfo.IsTherapist && item == 3) {
      this.patienthistoryinfo.TherapistDetails = new HospitalInfo()
    }
    if (this.patienthistoryinfo.IsTherapist == true && item == 3) {
      this.patienthistoryinfo.TherapistDetails = new HospitalInfo()
    }
    if (!this.patienthistoryinfo.IsCaseManager && item == 6) {
      this.patienthistoryinfo.CaseMangerDetails = new HospitalInfo()
    }
    if (this.patienthistoryinfo.IsCaseManager == true && item == 6) {
      this.patienthistoryinfo.CaseMangerDetails = new HospitalInfo()
    }

  }
  getddlLocation() {
    this.spinnerservice.show();
    this.tempService.getLocationList().subscribe(
      res => {
        if (res != null) {
          //  debugger;
          this.ddlfacilityType = res.filter(m => m.sfccMasterId !== -1 && m.sfccMasterId !== 0);
          //this.spinnerservice.hide();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

    this.leadservice.getAllMaster().subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this.ddlSoucre = this.commonService.decrypdata(res.source);
          this.ddlLeadType = this.commonService.decrypdata(res.leadType);
          //this.ddlfacilityType = decrypt(res.facilityType);
          this.ddlparentalRelationType = this.commonService.decrypdata(res.parentalRelationType);
        }
      },
      err => {

      },
    );
  }
  Historycompleted() {
    if (this.historyTab = true) {
      // console.log("Submitted additional information")
      this.snackbar.success('Submitted additional information')
      return;
    }
    else {
      this.snackbar.error('Error while submitting the data.')

    }

  }
  errormsgChange(){
    // this.isSubmit = false;
    this.checkRequiredFileds();
  }
}

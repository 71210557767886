<div class="demography">
  <div class="top-btn-section clearfix row" *ngIf="primaryTab">
    <div class="head-number-section col-lg-8 col-md-12">
      <div class="breadcrumb-arrow">
        <span class="rounded-number">1</span>
        <span class="first-head">Profile Information</span>
        <span class="breadcrumb-custom" *ngIf="!isProfileonly">
          <a [ngClass]="primaryTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-active cursor-pointer'"
            role="button" (click)="prevToPrimary()">Profile</a>
          <a [ngClass]="insuranceTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="tabPrimarytoInsurance()">Insurance</a>
            <!-- <a [ngClass]="authorizeTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="tabPrimarytoAuthorization()">Authorization</a> -->
          <a [ngClass]="historyTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="tabProfileToHistory()">History</a>
          <!-- <a [ngClass]="esignTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="historyTabCompleted ? tabtoesign() : null">E-Signature</a> -->
           <a [ngClass]="(LeadStatus == statusforIntake) ? 'cursor-pointer breadcrumb-arrow-completed' : 'breadcrumb-arrow-active cursor-pointer'"
            (click)="tabtofee()"
            *ngIf="(roleAccess.isCreate || roleAccess.isEdit) && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake)  && !IsNewFee">Fee</a>
            <!-- <a [ngClass]="paymentDetailsTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="tabEsignToPaymentdetails()">Payment Details</a> -->
        </span>
      </div>
      <div><span style="color: red;">*</span>All mandatory fields need be filled to submit the form. </div>
    </div>
    <div class="col-lg-4 col-md-12 text-right" *ngIf="(roleAccess.isCreate || roleAccess.isEdit)">
      <!-- <button (click)="OnresubmitLeadinfo()"  class="btn primary-btn-filled d-inline-block mr-2" *ngIf=" immidiateactionid == statusReturn">resubmit</button> -->
      <button type="button" *ngIf="!(immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn)"
        [disabled]="immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn " (click)="tabPrimarytoInsurance()"
        mat-stroked-button class="btn edit-btn primary-btn-m h-40">NEXT</button>
      <button type="button" (click)="tabPrimarytoInsurance()"
        *ngIf="immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn" mat-stroked-button
        class="btn edit-btn primary-btn-m h-40">NEXT</button>
    </div>
    <div class="col-lg-12 col-md-12 text-right" *ngIf=" roleAccess.isApprove && !isProfileonly">
      <span
      *ngIf="immidiateactionid == statusVerified || immidiateactionid == statusReviewPacket || immidiateactionid == statusReturn"
      class="status-msg float-left text-right w-100 mr-3 mt-2 mb-2 cursor-pointer text-underline">{{global.patientInfoStatus}}<span>{{global.patientInfoStatusCreatedon|
        date: 'MM/dd/yyyy'}}</span></span>
      <button type="button" (click)="onVerifyLead()" *ngIf="immidiateactionid == statusReviewPacket"
        [ngClass]="{'btn-disable': immidiateactionid == statusVerified || immidiateactionid == statusReturn}"
        [disabled]="immidiateactionid == statusVerified|| immidiateactionid == statusReturn"
        class="btn btn-verified mb-1 mr-2"><img src="../../../assets/images/verify.png">
        APPROVE</button>
      <button type="button" *ngIf="immidiateactionid == statusReviewPacket" (click)="onReturnLead()"
        [ngClass]="{'btn-disable': immidiateactionid == statusReturn}" [disabled]="immidiateactionid == statusReturn"
        class="btn btn-return mb-1 mr-2"><img src="../../../assets/images/return.png">
        Return</button>
        <div class="float-left w-100 mt-2">
          <button type="button" class="btn btn-arrow" (click)="tabPrimarytoInsurance()"><i
            class="fas fa-arrow-right"></i></button>
        </div>

    </div>


  </div>
  <div class="top-btn-section clearfix row" *ngIf="insuranceTab">
    <div class="head-number-section col-lg-8 col-md-12">
      <div class="breadcrumb-arrow">
        <span class="rounded-number">2 </span>
        <span class="first-head">Insurance Info</span>
        <span class="breadcrumb-custom">
          <a (click)="prevToPrimary()"
            [ngClass]="primaryTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-active cursor-pointer'">Profile</a>
          <a class="breadcrumb-arrow-active cursor-pointer"
            [ngClass]="!insuranceTabCompleted ? 'breadcrumb-arrow-active cursor-pointer':'cursor-pointer breadcrumb-arrow-completed'">Insurance</a>
            <!-- <a [ngClass]="authorizeTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="tabPrimarytoAuthorization()">Authorization</a> -->
          <a [ngClass]="historyTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-third cursor-pointer'"
            form= "insurancefrm" (click)="tabInsurancetoHistorySave()">History</a>
          <!-- <a [ngClass]="esignTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-third cursor-pointer'"
            role="button" (click)="historyTabCompleted ? tabtoesign() : null ">E-Signature</a> -->
            <a [ngClass]="(LeadStatus == statusforIntake) ? 'cursor-pointer breadcrumb-arrow-completed' : 'breadcrumb-arrow-active cursor-pointer'"
            (click)="tabtofee()"
            *ngIf="(roleAccess.isCreate || roleAccess.isEdit) && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake)  && !IsNewFee">Fee</a>
            <!--<a [ngClass]="paymentDetailsTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="tabEsignToPaymentdetails()">Payment Details</a> -->
          </span>
      </div>
      <div><span style="color: red;">*</span>All mandatory fields need be filled to submit the form. </div>
    </div>
    <div class="col-lg-4 col-md-12 text-right" *ngIf="(roleAccess.isCreate || roleAccess.isEdit) ">
      <!-- <button (click)="OnResubmitInsuranceTab()" class="btn primary-btn-filled d-inline-block mr-2" *ngIf=" immidiateactionid == statusReturn">resubmit</button> -->
      <button mat-stroked-button role="button" type="button" class="btn prev-btn primary-btn-m mb-2 h-40 mr-2"
        (click)="prevToPrimary()">PREV</button>
      <button type="button" *ngIf="isAddInsurance && !IsNoInsurance" (click)="tabInsurancetoHistorySave()" mat-stroked-button
        class="btn edit-btn primary-btn-m h-40 1">NEXT</button>
        <button type="submit" *ngIf="IsNoInsurance"  (click)="nexttohistory()" mat-stroked-button
        class="btn edit-btn primary-btn-m h-40 1">NEXT</button>
      <button type="button" *ngIf="!isAddInsurance && !IsNoInsurance" (click)="nexttoAuthorization()" mat-stroked-button
        class="btn edit-btn primary-btn-m h-40 2">NEXT</button>
      <!-- <button type="button"
        *ngIf="(immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn && isAddInsurance && !insuranceTabCompleted) "
        (click)="tabInsurancetoHistory()"  mat-stroked-button
        class="btn edit-btn primary-btn-m h-40 2">NEXTs</button> -->
    </div>
    <div class="col-lg-12 col-md-12 text-right" *ngIf="roleId !== 4">
      <span
      *ngIf="immidiateactionid == statusVerified || immidiateactionid == statusReviewPacket || immidiateactionid == statusReturn"
      class="status-msg float-left text-right w-100 mr-3 mt-2 mb-2 cursor-pointer text-underline">{{global.patientInfoStatus}}<span>{{global.patientInfoStatusCreatedon|
        date: 'MM/dd/yyyy'}}</span></span>
      <button type="button" *ngIf="immidiateactionid == statusReviewPacket"
        [ngClass]="{'btn-disable': immidiateactionid == statusVerified || immidiateactionid == statusReturn}"
        [disabled]="immidiateactionid == statusVerified || immidiateactionid == statusReturn" (click)="onVerifyLead()"
        [disabled]="immidiateactionid == statusVerified" class="btn btn-verified mb-1 mr-2"><img
          src="../../../assets/images/verify.png">
          APPROVE</button>
      <button type="button" *ngIf="immidiateactionid == statusReviewPacket"
        [ngClass]="{'btn-disable': immidiateactionid == statusReturn}" (click)="onReturnLead()"
        [disabled]="immidiateactionid == statusReturn" class="btn btn-return mb-1 mr-2"><img
          src="../../../assets/images/return.png">
        Return</button>
        <div class="float-left w-100 mt-2">
          <button type="button" class="btn btn-arrow mr-2 " (click)="prevToPrimary()"><i
            class="fas fa-arrow-left"></i></button>
        <button type="button" class="btn btn-arrow" (click)="tabInsurancetoHistory()"><i
            class="fas fa-arrow-right"></i></button>
        </div>

    </div>


  </div>
  <div class="top-btn-section clearfix row" *ngIf="historyTab">
    <div class="head-number-section col-lg-8 col-md-12">
      <div class="breadcrumb-arrow">
        <span class="rounded-number">3 </span>
        <span class="first-head">History</span>
        <span class="breadcrumb-custom">
          <a (click)="prevToPrimary()"
            [ngClass]="primaryTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-active cursor-pointer'">Profile</a>
          <a [ngClass]="insuranceTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            (click)="prevToInsurance()">Insurance</a>
            <!-- <a [ngClass]="authorizeTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="tabPrimarytoAuthorization()">Authorization</a> -->
          <a [ngClass]="!historyTabCompleted ? 'breadcrumb-arrow-active cursor-pointer':'cursor-pointer breadcrumb-arrow-completed'"
            (click)="tabInsurancetoHistory()">History</a>
          <!-- <a [ngClass]="esignTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-third cursor-pointer'"
            role="button" (click)="historyTabCompleted ? tabtoesign() : null ">E-Signature</a> -->
            <a [ngClass]="(LeadStatus == statusforIntake) ? 'cursor-pointer breadcrumb-arrow-completed' : 'breadcrumb-arrow-active cursor-pointer'"
            (click)="tabtofee()"
            *ngIf="(roleAccess.isCreate || roleAccess.isEdit) && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake) && !IsNewFee">Fee</a>
            <!--<a [ngClass]="paymentDetailsTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="tabEsignToPaymentdetails()">Payment Details</a> -->
        </span>
      </div>
      <div><span style="color: red;">*</span>All mandatory fields need be filled to submit the form. </div>
    </div>
    <div class="col-lg-4 col-md-12 text-right" *ngIf="(roleAccess.isCreate || roleAccess.isEdit)">
      <button  type="submit" (click)="OnResubmitHistoryTab()"  form="patienthistoryfrm" class="btn primary-btn-filled d-inline-block mr-2" *ngIf=" immidiateactionid == statusReturn">resubmit</button>
      <button type="button" (click)="nexttoAuthorization()" mat-stroked-button
        class="btn prev-btn primary-btn-m mb-2 h-40 mr-2"(click)="prevToInsurance()">PREV</button>
       <!-- [disabled]="immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn " -->
      <button type="submit" *ngIf="!(immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn) && !historyTabCompleted && primaryTabCompleted && insuranceTabCompleted"
      [disabled]="immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn "
        form="patienthistoryfrm" mat-stroked-button class="btn edit-btn primary-btn-m w-auto h-40">Submit</button>
      <!-- <button type="button" *ngIf="(immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn)"
        mat-stroked-button class="btn edit-btn primary-btn-m w-auto h-40" (click)="Historycompleted()">Submit</button> -->
        <button type="button" *ngIf="(immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn) && !historyTabCompleted && primaryTabCompleted && insuranceTabCompleted"
        mat-stroked-button class="btn edit-btn primary-btn-m w-auto h-40" (click)="Historycompleted()">Submit</button>
        <button type="button" *ngIf="(roleAccess.isCreate || roleAccess.isEdit) && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake)"
        mat-stroked-button class="btn edit-btn primary-btn-m w-auto h-40" (click)="tabtofee()">Next</button>
        <!-- <button type="submit"
        *ngIf="!((roleAccess.isCreate || roleAccess.isEdit) && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake))"
        (click)="Historycompleted()" mat-stroked-button
        class="btn edit-btn primary-btn-m w-auto h-40">Submitb</button> -->
        
    </div>
    <div class="col-lg-12 col-md-12 text-right" *ngIf="roleId !== 4">
      <span
      *ngIf="immidiateactionid == statusVerified || immidiateactionid == statusReviewPacket || immidiateactionid == statusReturn"
      class="status-msg float-left text-right w-100 mr-3 mt-2 mb-2 cursor-pointer text-underline">{{global.patientInfoStatus}}<span>{{global.patientInfoStatusCreatedon|
        date: 'MM/dd/yyyy'}}</span></span>
      <button type="button" (click)="onVerifyLead()" *ngIf="immidiateactionid == statusReviewPacket"
        [ngClass]="{'btn-disable': immidiateactionid == statusVerified || immidiateactionid == statusReturn}"
        [disabled]="immidiateactionid == statusVerified || immidiateactionid == statusReturn"
        class="btn btn-verified mb-1 mr-2"><img src="../../../assets/images/verify.png">
        APPROVE</button>
      <button type="button" *ngIf="immidiateactionid == statusReviewPacket" (click)="onReturnLead()"
        [ngClass]="{'btn-disable':  immidiateactionid == statusReturn}" [disabled]="immidiateactionid == statusReturn"
        class="btn btn-return mb-1 mr-2"><img src="../../../assets/images/return.png">
        Return</button>
        <div class="float-left w-100 mt-2">
          <button type="button" class="btn btn-arrow mr-2 " (click)="prevToInsurance()"><i
            class="fas fa-arrow-left"></i></button>
        <!-- <button type="button" class="btn btn-arrow" (click)="tabtoesign()"><i class="fas fa-arrow-right"></i></button> -->

        </div>
       </div>


  </div>
  <!-- <div class="top-btn-section clearfix row" *ngIf="esignTab">
    <div class="head-number-section col-lg-8 col-md-12">
      <div class="breadcrumb-arrow">
        <span class="rounded-number">4 </span>
        <span class="first-head">E-Signature </span>
        <span class="breadcrumb-custom">
          <a (click)="prevToPrimary()" class="breadcrumb-arrow-completed cursor-pointer">Profile</a>
          <a class="breadcrumb-arrow-completed cursor-pointer" (click)="prevToInsurance()">Insurance</a>
          <a [ngClass]="authorizeTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
          role="button" (click)="tabPrimarytoAuthorization()">Authorization</a>
          <a [ngClass]="historyTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-third cursor-pointer'"
            (click)="tabInsurancetoHistory()">History</a>
          <a [ngClass]="esignTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-third cursor-pointer'"
            role="button" (click)="tabtoesign()">E-Signature</a> 
          <a [ngClass]="(LeadStatus == statusforIntake ) ? 'cursor-pointer breadcrumb-arrow-completed' : 'breadcrumb-arrow-active cursor-pointer'"
            (click)="tabtofee()"
            *ngIf="(roleAccess.isCreate || roleAccess.isEdit) && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake || LeadStatus==statusforStaffAssigned )">Fee</a>
            <a [ngClass]="paymentDetailsTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="tabEsignToPaymentdetails()">Payment Details</a>
          </span>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 text-right" *ngIf="(roleAccess.isCreate || roleAccess.isEdit)">
      <button class="btn primary-btn-filled d-inline-block mr-2" *ngIf=" immidiateactionid == statusReturn">resubmit</button>
      <button type="button" (click)="tabtoprevvalidateEsign()" mat-stroked-button
        class="btn prev-btn primary-btn-m mb-2 h-40 mr-2">PREV</button>
      <button type="submit"
        *ngIf="!((roleAccess.isCreate || roleAccess.isEdit) && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake))"
        (click)="submittonextvalidateEsign()" mat-stroked-button
        class="btn edit-btn primary-btn-m w-auto h-40">Submit</button>
      <button type="button"
        *ngIf="((roleAccess.isCreate || roleAccess.isEdit) && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake))"
        mat-stroked-button class="btn edit-btn primary-btn-m w-auto h-40"
        (click)="tabtonextvalidateEsign()">Next</button>
    </div>
    <div class="col-lg-12 col-md-12 text-right" *ngIf="roleId !==4">
      <span
      *ngIf="immidiateactionid == statusVerified || immidiateactionid == statusReviewPacket || immidiateactionid == statusReturn"
      class="status-msg float-left text-right w-100 mr-3 mt-2 mb-2 cursor-pointer text-underline">
      {{global.patientInfoStatus}}<span>
        {{
        global.patientInfoStatusCreatedon|
        date: 'MM/dd/yyyy'
        }}
      </span>
    </span>
      <button type="button" (click)="onVerifyLead()" *ngIf="immidiateactionid == statusReviewPacket"
        [ngClass]="{'btn-disable': immidiateactionid == statusVerified || immidiateactionid == statusReturn}"
        [disabled]="immidiateactionid == statusVerified || immidiateactionid == statusReturn"
        class="btn btn-verified mb-1 mr-2">
        <img src="../../../assets/images/verify.png">
        APPROVE
      </button>
      <button type="button" *ngIf="immidiateactionid == statusReviewPacket" (click)="onReturnLead()"
        [ngClass]="{'btn-disable':  immidiateactionid == statusReturn}" [disabled]="immidiateactionid == statusReturn"
        class="btn btn-return mb-1 mr-2">
        <img src="../../../assets/images/return.png">
        Return
      </button>
      <div class="float-left w-100 mt-2">
        <button type="button" (click)="tabtoprevvalidateEsign()" class="btn btn-arrow mr-4 ">
          <i class="fas fa-arrow-left"></i>
        </button>
      </div>


    </div>

  </div> -->

  <div class="top-btn-section clearfix row" *ngIf="feeTab">
    <div class="head-number-section col-lg-8 col-md-12">
      <div class="breadcrumb-arrow">
        <span class="rounded-number">4</span>
        <span class="first-head">Fee</span>
        <span class="breadcrumb-custom">
          <a (click)="prevToPrimary()" class="breadcrumb-arrow-completed cursor-pointer">Profile</a>
          <a class="breadcrumb-arrow-completed cursor-pointer" (click)="prevToInsurance()">Insurance</a>
          <!-- <a [ngClass]="authorizeTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
          role="button" (click)="tabPrimarytoAuthorization()">Authorization</a> -->
          <a class="breadcrumb-arrow-completed cursor-pointer" (click)="tabtohistory()">History</a>
          <!-- <a class="breadcrumb-arrow-completed cursor-pointer" role="button" (click)="tabtoesign()">E-Signature</a> -->
          <a [ngClass]="(LeadStatus == statusforIntake) ? 'cursor-pointer breadcrumb-arrow-completed' : 'breadcrumb-arrow-active cursor-pointer'"
          (click)="tabtofee()"
          *ngIf="(roleAccess.isCreate || roleAccess.isEdit) && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake)  && !IsNewFee">Fee</a>
            <!--<a [ngClass]="paymentDetailsTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="tabEsignToPaymentdetails()">Payment Details</a> -->
          </span>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 text-right" *ngIf="(roleAccess.isCreate || roleAccess.isEdit) ">
      <!--<button class="btn primary-btn-filled d-inline-block mr-2" *ngIf=" immidiateactionid == statusReturn">resubmit</button>-->
      <!-- <button type="button" (click)="tabtoesign()" mat-stroked-button
        class="btn prev-btn primary-btn-m mb-2 h-40 mr-2">PREV</button> -->

    </div>


  </div>
  <!-- <div class="top-btn-section clearfix row" *ngIf="authorizationTab">
    <div class="head-number-section col-lg-8 col-md-12">
      <div class="breadcrumb-arrow">
        <span class="rounded-number">6 </span>
        <span class="first-head">Authorization</span>
        <span class="breadcrumb-custom">
          <a (click)="prevToPrimary()" class="breadcrumb-arrow-completed cursor-pointer">Profile</a>
          <a class="breadcrumb-arrow-completed cursor-pointer" (click)="prevToInsurance()">Insurance</a>
          <a [ngClass]="authorizeTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-active cursor-pointer'"
          role="button" (click)="tabPrimarytoAuthorization()">Authorization</a>
          <a  [ngClass]="historyTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-third cursor-pointer'"(click)="tabtohistory()">History</a>
          <a  [ngClass]="esignTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-third cursor-pointer'" role="button" (click)="tabtoesign()">E-Signature</a>
          <a [ngClass]="(LeadStatus == statusforIntake ) ? 'cursor-pointer breadcrumb-arrow-completed' : 'breadcrumb-arrow-active cursor-pointer'"
            (click)="tabtofee()"
            *ngIf="roleId == 4 && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake)">Fee</a>
            <a [ngClass]="paymentDetailsTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-second cursor-pointer'"
            role="button" (click)="tabEsignToPaymentdetails()">Payment Details</a>

          </span>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 text-right" *ngIf="roleId == 4 ">

      <button type="button" (click)="prevToInsurance()" mat-stroked-button
        class="btn prev-btn primary-btn-m mb-2 h-40 mr-2">PREV</button>
      <button type="button" (click)="nexttohistory()"
        mat-stroked-button class="btn edit-btn primary-btn-m w-auto h-40">Next</button>

    </div>
  </div> -->


  <!-- Payment details-->
  <!-- <div class="top-btn-section clearfix row" *ngIf="paymentDetailsTab">
    <div class="head-number-section col-lg-8 col-md-12">
      <div class="breadcrumb-arrow">
        <span class="rounded-number">7 </span>
        <span class="first-head">Payment Details</span>
        <span class="breadcrumb-custom">
          <a (click)="prevToPrimary()" class="breadcrumb-arrow-completed cursor-pointer">Profile</a>
          <a class="breadcrumb-arrow-completed cursor-pointer" (click)="prevToInsurance()">Insurance</a> -->
          <!-- <a [ngClass]="authorizeTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-active cursor-pointer'"
          role="button" (click)="tabPrimarytoAuthorization()">Authorization</a> -->
          <!-- <a  [ngClass]="historyTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-third cursor-pointer'"(click)="tabtohistory()">History</a> -->
          <!-- <a  [ngClass]="esignTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-third cursor-pointer'" role="button" (click)="tabtoesign()">E-Signature</a> -->
          <!-- <a [ngClass]="(LeadStatus == statusforIntake ) ? 'cursor-pointer breadcrumb-arrow-completed' : 'breadcrumb-arrow-active cursor-pointer'"
         (click)="tabtofee()"
            *ngIf="(roleAccess.isCreate || roleAccess.isEdit) && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake)">Fee</a>

            <a [ngClass]="paymentDetailsTabCompleted ? 'breadcrumb-arrow-completed cursor-pointer':'breadcrumb-arrow-active cursor-pointer'"
            role="button" (click)="tabEsignToPaymentdetails()">Payment Details</a> -->
        <!-- </span> -->
      <!-- </div> -->
    <!-- </div> -->
    <!-- <div class="col-lg-4 col-md-12 text-right" *ngIf="(roleAccess.isCreate || roleAccess.isEdit) "> -->
      <!--<button class="btn primary-btn-filled d-inline-block mr-2" *ngIf=" immidiateactionid == statusReturn">resubmit</button>-->
      <!-- <button type="button" (click)="tabtoesign()" mat-stroked-button
        class="btn prev-btn primary-btn-m mb-2 h-40 mr-2">PREV</button> -->
    <!-- </div>
  </div> -->
  <!--Payment Details end-->


  <!-- [ngClass]="{'disabled':immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn }" -->
  <div class="col-md-12 p-0 mt-4 mb-4" *ngIf="primaryTab">
    <form (ngSubmit)="leadinfo.form.valid  && updateleadinfo(1)" id="leadinfo" autocomplete="off" #leadinfo="ngForm"
      novalidate>
      <div class="row">

        <div class="col-md-6">
          <div class="row">
            <div class="col-lg-6 col-md-12"
                 >
                 <!-- [ngClass]="{'disabled':immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn }"> -->
              <div class="lead-box ">
                <div class="box-lead-head">client information</div>
                <div class="line-input mt-2">
                  <mat-form-field class="w-100">

                    <input matInput placeholder="First Name" appAlphabetOnly maxlength="50"
                           [(ngModel)]="patientinfo.FirstName" #FirstName="ngModel" name="FirstName"
                           autocomplete="new-password" [ngClass]="{ 'is-invalid': leadinfo.submitted && FirstName.invalid }"
                           required>
                    <mat-error *ngIf="leadinfo.submitted && FirstName.invalid">
                      <mat-error *ngIf="FirstName.errors.required">First Name is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Last Name" appAlphabetOnly maxlength="50" autocomplete="new-password"
                           [(ngModel)]="patientinfo.LastName" #LastName="ngModel" name="LastName"
                           [ngClass]="{ 'is-invalid': leadinfo.submitted && LastName.invalid }" required>
                    <mat-error *ngIf="leadinfo.submitted && LastName.invalid">
                      <mat-error *ngIf="LastName.errors.required">Last Name is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label>Gender</mat-label>
                    <mat-select [disabled]="immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn" [(ngModel)]="patientinfo.Gender" #Gender="ngModel" name="Gender"
                                [ngClass]="{ 'is-invalid': leadinfo.submitted && Gender.invalid }" required>
                      <mat-option *ngFor="let item of ddlgender" [value]="item.value">
                        {{item.label}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="leadinfo.submitted && Gender.invalid">
                      <mat-error *ngIf="Gender.errors.required">Gender is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label>DOB</mat-label>
                    <input matInput (dateChange)="calculateAge(patientinfo.Dob,$event)" [matDatepicker]="picker"
                           readonly (click)="picker.open()" [disabled]="immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn"
                            [(ngModel)]="patientinfo.Dob" #Dob="ngModel" name="Dob"
                           [ngClass]="{ 'is-invalid': leadinfo.submitted && Dob.invalid }" required >
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="(leadinfo.submitted && Dob.invalid)||isSubmit">
                      <mat-error *ngIf="Dob.errors?.required">DOB is required</mat-error>
                    </mat-error>

                  </mat-form-field>
                  <mat-form-field class="w-100">

                    <input matInput placeholder="Age" disabled [(ngModel)]="patientinfo.Age" #Age="ngModel" name="Age"
                           [ngClass]="{ 'is-invalid': leadinfo.submitted && Age.invalid }">

                  </mat-form-field>
                  <div class="check-custom">
                    <mat-checkbox class="example-margin" disabled [(ngModel)]="patientinfo.IsMinor" name="IsMinor">
                      patient is a
                      minor
                    </mat-checkbox>
                  </div>
                  <mat-form-field class="w-100">
                    <input matInput placeholder="SSN" mask='000-00-0000' [disabled]="immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn"
                     [(ngModel)]="patientinfo.Ssid" #Ssid="ngModel"
                           name="Ssid" [ngClass]="{ 'is-invalid': leadinfo.submitted && Ssid.invalid }" required (change)="errormsgChange()">
                    <mat-error *ngIf="(leadinfo.submitted && Ssid.invalid)||isSubmit">
                      <mat-error *ngIf="Ssid.errors?.required">SSN is required</mat-error>
                      <mat-error *ngIf="Ssid.errors?.minlength">Please enter valid SSN</mat-error>
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="w-100">
                    <mat-label>Parent's Marital Status</mat-label>
                    <mat-select [disabled]="immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn"
                     [(ngModel)]="patientinfo.MaritalStatus" #MaritalStatus="ngModel"
                                name="MaritalStatus" [ngClass]="{ 'is-invalid': leadinfo.submitted && MaritalStatus.invalid }"
                                required (change)="errormsgChange()">
                      <!-- <mat-option *ngFor="let item of ddlmaritalStatus" [disabled]="!item.IsActive" -->
                      <mat-option *ngFor="let item of ddlmaritalStatus"
                                  [value]="item.value">
                        {{item.label}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="(leadinfo.submitted && MaritalStatus.invalid)||isSubmit">
                      <mat-error *ngIf="MaritalStatus.errors?.required">Marital Status is required</mat-error>
                    </mat-error>
                  </mat-form-field>

                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
                 <!-- [ngClass]="{'disabled':immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn }"> -->
              <div class="lead-box"  [ngClass]="{'disabled':immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn }">
                <div class="box-lead-head">Academic information</div>
                <div class="line-input mt-2">
                  <mat-form-field class="w-100">
                    <mat-label>Grade Level</mat-label>
                    <mat-select [(ngModel)]="patientinfo.GradeLevel" #GradeLevel="ngModel" name="GradeLevel"
                                [ngClass]="{ 'is-invalid': leadinfo.submitted && GradeLevel.invalid }" required (change)="errormsgChange()">
                      <mat-option *ngFor="let item of ddlgrade" [disabled]="!item.IsActive" [value]="item.MasterId">
                        {{item.MasterName}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="(leadinfo.submitted && GradeLevel.invalid) ||isSubmit ">
                      <mat-error *ngIf="GradeLevel.errors?.required">Grade Level is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label>Academic Category</mat-label>
                    <mat-select [(ngModel)]="patientinfo.Academiccategory" #Academiccategory="ngModel" name="Academiccategory"
                    [ngClass]="{ 'is-invalid': leadinfo.submitted && Academiccategory.invalid }" required>
                                <mat-option value="option1">Public</mat-option>
                                <mat-option value="option2">Private</mat-option>
                    </mat-select>
                    <mat-error *ngIf="(leadinfo.submitted && Academiccategory.invalid) ||isSubmit ">
                      <mat-error *ngIf="Academiccategory.errors?.required">Academic Category is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">

                    <input matInput placeholder="School District" appAlphabetOnly maxlength="50"
                           [(ngModel)]="patientinfo.SchoolDistrict" #SchoolDistrict="ngModel" name="SchoolDistrict"
                           [ngClass]="{ 'is-invalid': leadinfo.submitted && SchoolDistrict.invalid }" required (change)="errormsgChange()">
                    <mat-error *ngIf="(leadinfo.submitted && SchoolDistrict.invalid)||isSubmit">
                      <mat-error *ngIf="SchoolDistrict.errors?.required">School District is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <input matInput placeholder="School Name" maxlength="50" appAlphabetOnly
                           [(ngModel)]="patientinfo.SchoolName" #SchoolName="ngModel" name="SchoolName"
                           [ngClass]="{ 'is-invalid': leadinfo.submitted && SchoolName.invalid }" required (change)="errormsgChange()">
                    <mat-error *ngIf="(leadinfo.submitted && SchoolName.invalid)||isSubmit">
                      <mat-error *ngIf="SchoolName.errors?.required">School Name is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Teacher's  First Name" maxlength="50" appAlphabetOnly
                           [(ngModel)]="patientinfo.TeachersName" #TeachersName="ngModel" name="TeachersName"
                           [ngClass]="{ 'is-invalid': leadinfo.submitted && TeachersName.invalid }" required (change)="errormsgChange()">
                    <mat-error *ngIf="(leadinfo.submitted && TeachersName.invalid)||isSubmit ">
                      <mat-error *ngIf="TeachersName.errors?.required">Teacher's First Name is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <input matInput placeholder="Teacher's Last Name" maxlength="50" appAlphabetOnly
                           [(ngModel)]="patientinfo.TeachersLastName" #TeachersLastName="ngModel" name="TeachersLastName"
                           [ngClass]="{ 'is-invalid': leadinfo.submitted && TeachersLastName.invalid }" required (change)="errormsgChange()">
                    <mat-error *ngIf="(leadinfo.submitted && TeachersLastName.invalid)||isSubmit">
                      <mat-error *ngIf="TeachersLastName.errors?.required">Teacher's Last Name is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                
                  <!--123>-->
                  <!-- <button type="button"  mat-stroked-button
         class="btn primary-btn-filled primary-btn-m h-40 ">Save Contact</button> -->

                </div>
              </div>
              <div class="lead-box">
                <div class="box-lead-head">Lead profile information</div>
                <div class="line-input mt-2">
                  <mat-form-field class="w-100">
                    <mat-label>Source</mat-label>
                    <mat-select [disabled]="immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn"  [(ngModel)]="patientinfo.SourceTypeId" #SourceTypeId="ngModel" name="SourceTypeId"
                                [ngClass]="{ 'is-invalid': leadinfo.submitted && SourceTypeId.invalid }" required>
                      <ng-container *ngFor="let item of ddlSoucre">
                        <mat-option *ngIf="item.IsActive" [value]="item.SourceTypeId">
                          {{item.SourceName}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error *ngIf="leadinfo.submitted && SourceTypeId.invalid">
                      <mat-error *ngIf="SourceTypeId.errors?.required">Source is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label>Type</mat-label>
                    <mat-select [disabled]="immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn"  
                    [(ngModel)]="patientinfo.LeadTypeId" #LeadTypeId="ngModel" name="LeadTypeId"
                                [ngClass]="{ 'is-invalid': leadinfo.submitted && LeadTypeId.invalid }" required>
                      <ng-container *ngFor="let item of ddlLeadType">
                        <mat-option  [value]="item.LeadTypeId">
                          {{item.LeadTypeName}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error *ngIf="leadinfo.submitted && LeadTypeId.invalid">
                      <mat-error *ngIf="LeadTypeId.errors?.required">Type is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <mat-label>Location</mat-label>
                    <mat-select [(ngModel)]="patientinfo.FacilityTypeId" #FacilityTypeId="ngModel"
                                name="FacilityTypeId"
                                [ngClass]="{ 'is-invalid': leadinfo.submitted && FacilityTypeId.invalid }" required>
                      <ng-container *ngFor="let item of ddlfacilityType">
                        <mat-option [value]="item.sfccMasterId">
                          {{item.locationName}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                    <mat-error *ngIf="leadinfo.submitted && FacilityTypeId.invalid">
                      <mat-error *ngIf="FacilityTypeId.errors?.required">Location is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-12 p-0">
            <div class="lead-box">
              <div class="clearfix w-100">
                <div class="box-lead-head float-left">Contact information</div>
                <div class="float-right">
                  <span class="check-custom mr-3">
                    <mat-checkbox class="example-margin" #IsPrimaryContact="ngModel"
                      [(ngModel)]="patientPrimary.IsPrimaryContact" name="IsPrimaryContact" *ngIf="!patientPrimary.IsPrimaryContact" [disabled]="!disablePrimaryContact" (change)="makePrimaryContact()">Do you prefer to use this contact as your primary contact?
                    </mat-checkbox>
                    <!-- <button *ngIf="!patientPrimary.IsPrimaryContact" (click)="makePrimaryContact()" type="button">Make as Primary Contact</button> -->
                  </span>
                  <!-- <span class="check-custom ">
                    <mat-checkbox class="example-margin" [(ngModel)]="patientPrimary.IsEmergency" name="IsEmergency">
                      Emergency contact
                    </mat-checkbox>
                  </span> -->
                </div>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="First Name (if minor)" appAlphabetOnly maxlength="50"
                        [(ngModel)]="patientPrimary.FirstName" #PFirstName="ngModel" name="PFirstName"
                        [ngClass]="{ 'is-invalid': leadinfo.submitted && PFirstName.invalid }" required (focusout)="checkRequiredFileds()">
                      <mat-error *ngIf="(leadinfo.submitted && PFirstName.invalid) || isSubmit">
                        <mat-error *ngIf="PFirstName.errors?.required">FirstName is required</mat-error>
                      </mat-error>
                      <!-- <mat-error *ngIf="isSubmit">
                        <mat-error *ngIf="PFirstName.errors?.required">FirstName is required</mat-error>
                      </mat-error> -->
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Last Name (if minor)" appAlphabetOnly maxlength="50"
                        [(ngModel)]="patientPrimary.LastName" #PLastName="ngModel" name="PLastName"
                        [ngClass]="{ 'is-invalid': leadinfo.submitted && PFirstName.invalid }" required (focusout)="checkRequiredFileds()">
                      <mat-error *ngIf="(leadinfo.submitted && PLastName.invalid)||isSubmit">
                        <mat-error *ngIf="PLastName.errors?.required">LastName is required</mat-error>
                      </mat-error>

                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <mat-label>Relationship</mat-label>
                      <mat-select [(ngModel)]="patientPrimary.ParentalRelationTypeId" #ParentalRelationTypeId="ngModel"
                        name="ParentalRelationTypeId" (change)="checkRequiredFileds()"
                        [ngClass]="{ 'is-invalid': leadinfo.submitted && ParentalRelationTypeId.invalid }" required>
                        <mat-option *ngFor="let item of ddlparentalRelationType" [disabled]="!item.IsActive"
                          [value]="item.ParentalRelationTypeId">
                          {{item.ParentalRelationName}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="(leadinfo.submitted && ParentalRelationTypeId.invalid) || isSubmit">
                        <mat-error *ngIf="ParentalRelationTypeId.errors?.required">Relationship is required
                        </mat-error>
                      </mat-error>

                    </mat-form-field>

                  </div>

                </div>
              </div>
              <div class="col-md-12 mt-4">

                <mat-form-field class="w-100">
                  <!-- <input matInput placeholder="Full Address" maxlength="96" autocomplete="new-password"  [(ngModel)]="patientPrimary.AddressLine1"
                    #AddressLine1="ngModel" name="AddressLine1"
                    [ngClass]="{ 'is-invalid': leadinfo.submitted && AddressLine1.invalid }" required> -->

                  <input type="text" name="address" placeholder="Address" matInput [matAutocomplete]="auto"
                    [(ngModel)]="patientPrimary.AddressLine1" (ngModelChange)="onChangeAddress($event)"
                    #AddressLine1="ngModel" name="AddressLine1"  [ngClass]="{ 'is-invalid': leadinfo.submitted && AddressLine1.invalid }"
                    required  (focusout)="checkRequiredFileds()"/>
                    <mat-error *ngIf="(leadinfo.submitted && AddressLine1.invalid)||isSubmit">
                      <mat-error *ngIf="AddressLine1.errors?.required">Address is required</mat-error>
                    </mat-error>

                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let address of addressoption" [value]="address.streetLine"
                      (click)="onselectaddress(address)">
                      {{ address.streetLine }} {{address.secondary}}
                    </mat-option>
                  </mat-autocomplete>
                  </mat-form-field>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4"><!--(selectionChange)="onChangeState($event)" -->
                    <mat-form-field class="w-100">
                      <mat-label>State</mat-label>
                      <mat-select [(ngModel)]="patientPrimary.StatesId" #StatesId="ngModel"
                      (selectionChange)="onStateChnage($event)"
                         name="StatesId"
                        [ngClass]="{ 'is-invalid': leadinfo.submitted && StatesId.invalid }" required>
                        <mat-option *ngFor="let item of ddlStates" [value]="item.StatesId">
                          {{item.StateName}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="(leadinfo.submitted && StatesId.invalid)||isSubmit">
                        <mat-error *ngIf="StatesId.errors?.required">State is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-md-4">

                    <mat-form-field class="w-100">

                      <mat-label>City</mat-label>
                      <!-- (selectionChange)="onChangeCity($event)" -->

                      <mat-select [(ngModel)]="patientPrimary.City" #City="ngModel" name="City"
                        [ngClass]="{ 'is-invalid': leadinfo.submitted && City.invalid }" required (change)="errormsgChange()">
                        <mat-option *ngFor="let item of ddlCities" [value]="item.CitiesId">
                          {{item.CityName}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="(leadinfo.submitted && City.invalid)||isSubmit">
                        <mat-error *ngIf="City.errors?.required">City is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>


                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Zip" mask='00000'  [(ngModel)]="patientPrimary.Zip"
                        #Zip="ngModel" name="Zip" [ngClass]="{ 'is-invalid': leadinfo.submitted && Zip.invalid }"
                        required (change)="errormsgChange()" (focusout)="checkRequiredFileds()">
                      <mat-error *ngIf="(leadinfo.submitted && Zip.invalid)||isSubmit">
                        <mat-error *ngIf="Zip.errors?.required">Zip is required</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Cell Number" mask='(000) 000-0000'
                        [(ngModel)]="patientPrimary.CellNumber" #CellNumber="ngModel" name="CellNumber"
                        [ngClass]="{ 'is-invalid': leadinfo.submitted && CellNumber.invalid }" required (change)="errormsgChange()" (focusout)="checkRequiredFileds()">
                        <mat-error *ngIf="(leadinfo.submitted && CellNumber.invalid)||isSubmit">
                          <mat-error *ngIf="CellNumber.errors?.required">Cell Number is required</mat-error>
                          <mat-error *ngIf="CellNumber.errors?.minlength">Please enter valid cell number</mat-error>
                        </mat-error>
                        <!-- <mat-error *ngIf="isSubmit">
                          <mat-error *ngIf="CellNumber.errors?.required">Cell Number is required</mat-error>
                        </mat-error> -->
                    </mat-form-field>
                  </div>

                  <div class="col-md-4">
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Work Number" mask='(000) 000-0000'
                        [(ngModel)]="patientPrimary.WorkNumber" #WorkNumber="ngModel" name="WorkNumber"
                        [ngClass]="{ 'is-invalid': leadinfo.submitted && WorkNumber.invalid }">
                      <!-- <div *ngIf="leadinfo.submitted && WorkNumber.invalid" class="invalid-feedback">
                        <div *ngIf="WorkNumber.errors.required">Work Number is required</div>
                        <div *ngIf="WorkNumber.errors?.minlength">Please enter valid work number</div>
                      </div> -->
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field class="w-100">

                      <input matInput placeholder="Home Number" mask='(000) 000-0000' maxlength="14"
                        [(ngModel)]="patientPrimary.ContactNo" #ContactNo="ngModel" name="ContactNo"
                        [ngClass]="{ 'is-invalid': leadinfo.submitted && ContactNo.invalid }">
                      <mat-error *ngIf="leadinfo.submitted && ContactNo.invalid">
                        <!-- <mat-error *ngIf="ContactNo.errors.required">Phone is required</mat-error> -->
                        <mat-error *ngIf="ContactNo.errors?.minlength">Please enter valid phone number</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6">
                    <mat-form-field class="w-100">
                      <!-- <input matInput placeholder="Email" [(ngModel)]="patientPrimary.Email" #Email="ngModel"
                        autocomplete="new-password" name="Email"
                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                        [ngClass]="{ 'is-invalid': leadinfo.submitted && Email.invalid }" [disabled]="patientPrimary.ParentInfoId != 0" required> -->
                        <input matInput placeholder="Email"  [(ngModel)]="patientPrimary.Email" #Email="ngModel"
                        autocomplete="new-password" name="Email"
                        pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$" required
                        [ngClass]="{ 'is-invalid': leadinfo.submitted && Email.invalid }" [disabled]="patientPrimary.IsPrimaryContact && patientPrimary.ParentInfoId != 0" (focusout)="checkRequiredFileds()">
                      <mat-error *ngIf="(leadinfo.submitted && Email.invalid) || isSubmit">
                        <mat-error *ngIf="Email.errors?.required">Email is required</mat-error>
                        <mat-error *ngIf="Email.errors?.pattern">Please enter valid email address</mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <!-- <button type="button" (click)="addorUpdateParent()" mat-stroked-button form="parentform"
                      class="btn primary-btn-filled primary-btn-m h-40 ">{{patientPrimary.ParentInfoId != 0?'Update
                  Contact':'Save Contact'}}</button> -->
                  <button type="button" (click)="addorUpdateParent()" mat-stroked-button
                  class="btn primary-btn-filled primary-btn-m h-40 ">{{patientPrimary.ParentInfoId != 0?'Update
                  Contact':'Save Contact'}}</button>
                  <div class="mt-2 text-danger fs-6"><span class="font-lead
                    ">Note : This "{{patientPrimary.ParentInfoId != 0?'Update
                    Contact':'Save Contact'}}" button only save the contact information details</span></div>
              </div>
            </div>
          </div>
          <div class="col-md-12 p-0">
            <div class="lead-box">
              <div class="clearfix w-100">
                <div class="box-lead-head float-left">Contact information</div>
                <div class="float-right">
                  <button type="button" class="btn add-btn"  (click)="addParent()"><i
                      class="fas fa-user-plus mr-2"></i>ADD CONTACT</button>
                </div>
              </div>
              <div class="col-md-12  table-custom p-0">
                <div class="table-responsive ">
                  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100" matSort>

                    <ng-container matColumnDef="FirstName">
                      <th mat-header-cell *matHeaderCellDef> Name </th>
                      <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
                    </ng-container>


                    <ng-container matColumnDef="ParentalRelation">
                      <th mat-header-cell *matHeaderCellDef> Relation </th>
                      <td mat-cell *matCellDef="let element"> {{element.ParentalRelation}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Email">
                      <th mat-header-cell *matHeaderCellDef> Email </th>
                      <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ContactNo">
                      <th mat-header-cell *matHeaderCellDef> Phone </th>
                      <td mat-cell *matCellDef="let element"> {{element.ContactNo | mask: '(000) 000-0000'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef>Actions </th>
                      <td mat-cell *matCellDef="let element;let i = index;" class="">
                        <button type="button" mat-icon-button matTooltip="Edit" color="primary" (click)="editparent(element)">
                          <img src="../../../assets/images/editblue.png">
                        </button>
                        <button type="button" mat-icon-button matTooltip="Delete" color="warn" (click)="deleteparent(element)">
                          <img src="../../../assets/images/deletered.png">
                        </button>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>
    </form>
  </div>
  <div class="d-flex">
  <div class="ml-auto mt-2" *ngIf="insuranceTab">
    <span class="check-custom large-font  " >
      <mat-checkbox class="first-head" (click)="OnNoInsurance()" [checked]="IsNoInsurancechecked"
      [ngClass]="{'disabled':(immidiateactionid == statusVerified && immidiateactionid == statusReviewPacket) }"
       >No Insurance
      </mat-checkbox>
    </span>
  </div>
</div>
  <div class="col-md-12 mt-2 mb-4 p-0" *ngIf="insuranceTab"
    [ngClass]="{'disabled':(immidiateactionid == statusVerified && immidiateactionid == statusReviewPacket) || IsNoInsurance }"
    >
    <form (ngSubmit)="saveInsurance()" id="insurancefrm" autocomplete="off"
      #insurancefrm="ngForm" novalidate>
      <div class="row">
        <div class="col-md-3">
          <div class="lead-box">
            <div class="box-lead-head">Insurance Card</div>
            <div class="card-upload mt-3">
              <div class="insurance-sub ">
                Insurance Card(Front)

              </div>
              <div class="preview-bg">
                <div class="custom-upload" title="Select to add files" *ngIf="!imgInsuranceFront">
                  <input accept=".jpg,.jpeg,.png" #Insurancefile type='file' [disabled]="!isAddInsurance"
                    (change)="onInsFileChange($event,0)" >
                  <label class="file-upload"><b>CLICK TO UPLOAD</b> Insurance Card(Front)</label>


                </div>
                <div class="img-preview position-relative" *ngIf="imgInsuranceFront">

                  <div class="insurance-img"> <img [src]="imgInsuranceFront" style="height: 250px; width:100%"></div>
                  <div class="action-icon">
                    <span class="pr-3">
                      <a [ngClass]="{ 'disabled': !isAddInsurance }" *ngIf="!isUploadinsuranceFront" title="Download"
                        role="button" href="{{imgInsuranceFront}}" target="_blank"><i
                          class="fas fa-download cursor-pointer"></i></a></span>
                    <span>
                      <button type="button" type="button" class="cursor-pointer" [disabled]="!isAddInsurance"
                        (click)="!isUploadinsuranceFront ? deleteInsuranceCard(0) : deleteUploadInsurance(0)"
                        role="button" title="Delete"><img src="../../../assets/images/deletered.png"
                          class="cursor-pointer"></button></span>

                  </div>
                </div>
              </div>
 <!-- <div class="insurance-imgsize ">Note : Please upload image below 350KB </div> -->
 <div class="insurance-imgsize ">Note : Please upload image below 1MB </div>
            </div>
            <div class="mt-1 mb-1  text-xs  error" *ngIf="isFormSubmitted&&InsuranceFrontNotFound">
              Insurance Card(Front) is required
            </div>
            <div class="card-upload mt-3">
              <div class="insurance-sub ">
                Insurance Card(Back)
              </div>
              <div class="preview-bg">
                <div class="custom-upload" title="Select to add files" *ngIf="!imgInsuranceBack">
                  <input accept=".jpg,.jpeg,.png" type='file' #Insurancefile [disabled]="!isAddInsurance"
                    (change)="onInsFileChange($event,1)" >
                  <label class="file-upload"><b>CLICK TO UPLOAD</b> Insurance Card(Back)</label>

                </div>
                <div class="img-preview position-relative" *ngIf="imgInsuranceBack">
                  <div class="insurance-img"> <img [src]="imgInsuranceBack" style="height: 250px; width:100%"></div>
                  <div class="action-icon">
                    <span class="pr-3"><a [ngClass]="{ 'disabled': !isAddInsurance }" *ngIf="!isUploadinsuranceBack"
                        title="Download" role="button" href="{{imgInsuranceBack}}" target="_blank"><i
                          class="fas fa-download"></i></a></span>
                    <span><button type="button"
                        (click)="!isUploadinsuranceBack ? deleteInsuranceCard(1) : deleteUploadInsurance(1)"
                        [disabled]="!isAddInsurance" role="button" title="Delete"><img
                          src="../../../assets/images/deletered.png"></button></span>
                  </div>
                </div>
              </div>
              <!-- <div class="insurance-imgsize ">Note : Please upload image below 350KB </div> -->
              <div class="insurance-imgsize ">Note : Please upload image below 1MB </div>
            </div>
            <div class="mt-1 mb-1  text-xs  error" *ngIf="isFormSubmitted&&InsuranceBackNotFound">
              Insurance Card(Back) is required
            </div>
            <!-- <div class="card-upload mt-3">
              <div class="insurance-sub ">
                Policy Document
              </div>
              <div class="preview-bg">
                <div class="img-preview position-relative" *ngIf="imgInsurancePolicy">
                  <div class="insurance-img"> <img src="../../assets/images/doc.png" style="height: 250px; width:100%">
                  </div>
                  <div class="action-icon">
                    <span class="pr-3"><a [ngClass]="{ 'disabled': !isAddInsurance }" *ngIf="!isUploadinsurancePolicy"
                        title="Download" role="button" href="{{imgInsurancePolicy}}" target="_blank"><i
                          class="fas fa-download"></i></a></span>
                    <span><button type="button"
                        (click)="!isUploadinsurancePolicy ? deleteInsuranceCard(2) : deleteUploadInsurance(2)"
                        [disabled]="!isAddInsurance" role="button" title="Delete"><img
                          src="../../../assets/images/deletered.png"></button></span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="col-md-9">
          <div class="col-md-12 p-0">
            <div class="lead-box">
              <div class="box-lead-head d-flex">Insurance Info

              </div>
              <div class="row">
                <div class="col-md-12 ">

                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-3">
                        <mat-form-field class="w-100">
                          <input matInput placeholder="Carrier Name" appAlphabetOnly maxlength="50"
                            [disabled]="!isAddInsurance" [(ngModel)]="insuranceinfo.CarrierName" #CarrierName="ngModel"
                            name="CarrierName"
                            [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && CarrierName.invalid }" required>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && CarrierName.invalid">
                            <mat-error *ngIf="CarrierName.errors.required">Carrier Name is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3">

                        <mat-form-field class="w-100">

                          <mat-label>Insurance Type</mat-label>
                          <mat-select [(ngModel)]="insuranceinfo.InsuranceTypeId" #InsuranceTypeId="ngModel"
                            name="InsuranceTypeId"
                            [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && InsuranceTypeId.invalid }"
                            [disabled]="!isAddInsurance" required>
                            <mat-option *ngFor="let item of ddlInsuranceType" [value]="item.InsuranceTypeId">
                              {{item.InsuranceTypeName}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && InsuranceTypeId.invalid">
                            <mat-error *ngIf="InsuranceTypeId.errors.required">Insurance Type is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3">
                        <mat-form-field class="w-100">
                          <input matInput placeholder="Member ID" specialIsAlphaNumeric maxlength="30"
                            [disabled]="!isAddInsurance" [(ngModel)]="insuranceinfo.MemberId" #MemberId="ngModel"
                            name="MemberId" [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && MemberId.invalid }"
                            required>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && MemberId.invalid">
                            <mat-error *ngIf="MemberId.errors.required">Member ID is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3">
                        <mat-form-field class="w-100">
                          <input matInput placeholder="Group Number" specialIsAlphaNumeric maxlength="25"
                            [disabled]="!isAddInsurance" [(ngModel)]="insuranceinfo.GroupNo" #GroupNo="ngModel"
                            name="GroupNo" [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && GroupNo.invalid }"
                            required>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && GroupNo.invalid">
                            <mat-error *ngIf="GroupNo.errors.required">Group Number is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-12">
                        <div class="insurance-holder">
                          Policy Holder
                        </div>
                      </div>
                      <div class="col-md-3">
                        <mat-form-field class="w-100">
                          <input matInput placeholder="First Name" appAlphabetOnly maxlength="50"
                            [disabled]="!isAddInsurance" [(ngModel)]="insuranceinfo.FirstName" #FirstName="ngModel"
                            name="FirstName" [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && FirstName.invalid }"
                            required>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && FirstName.invalid">
                            <mat-error *ngIf="FirstName.errors.required">First Name is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3">
                        <mat-form-field class="w-100">
                          <input matInput placeholder="Last Name" appAlphabetOnly maxlength="50"
                            [disabled]="!isAddInsurance" [(ngModel)]="insuranceinfo.LastName" #LastName="ngModel"
                            name="LastName" [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && LastName.invalid }"
                            required>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && LastName.invalid">
                            <mat-error *ngIf="LastName.errors.required">Last Name is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3">
                        <mat-form-field class="w-100">
                          <input matInput placeholder="SSN" mask='000-00-0000' [disabled]="!isAddInsurance"
                            [(ngModel)]="insuranceinfo.Ssid" #Ssid="ngModel" name="Ssid"
                            [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && Ssid.invalid }" required>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && Ssid.invalid">
                            <mat-error *ngIf="Ssid.errors.required">SSN is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3">

                        <mat-form-field class="w-100">

                          <mat-label>Patient Relationship to Insured</mat-label>
                          <mat-select [(ngModel)]="insuranceinfo.InsuranceRelationShipId"
                            #InsuranceRelationShipId="ngModel" name="InsuranceRelationShipId"
                            [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && InsuranceRelationShipId.invalid }"
                            [disabled]="!isAddInsurance" required>
                            <mat-option *ngFor="let item of ddlInsuranceRelationShip"
                              [value]="item.InsuranceRelationShipId">
                              {{item.RelationShip}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && InsuranceRelationShipId.invalid">
                            <mat-error *ngIf="InsuranceRelationShipId.errors.required">Patient Relationship to Insured is
                              required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <!-- <div class="col-md-3">
                        <mat-form-field class="w-100">
                          <input matInput placeholder="Member Name" [disabled]="!isAddInsurance" appAlphabetOnly
                            maxlength="50" [(ngModel)]="insuranceinfo.MemberName" #MemberName="ngModel"
                            name="MemberName" [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && MemberName.invalid }"
                            required>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && MemberName.invalid">
                            <mat-error *ngIf="MemberName.errors.required">Member name is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div> -->

                      <div class="col-md-3">
                        <mat-form-field class="w-100">
                          <input matInput placeholder="Employer" [disabled]="!isAddInsurance" appAlphabetOnly
                            maxlength="50" [(ngModel)]="insuranceinfo.MemberEmployer" #MemberEmployer="ngModel"
                            name="MemberEmployer"
                            [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && MemberEmployer.invalid }" required>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && MemberEmployer.invalid">
                            <mat-error *ngIf="MemberEmployer.errors.required">Employer is required</mat-error>
                          </mat-error>
                        </mat-form-field>

                      </div>

                      <!-- <div class="col-md-3">
                        <mat-form-field class="w-100">
                          <input matInput placeholder="Policy No." specialIsAlphaNumeric maxlength="25"
                            [disabled]="!isAddInsurance" [(ngModel)]="insuranceinfo.PolicyNo" #PolicyNo="ngModel"
                            name="PolicyNo" [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && PolicyNo.invalid }"
                            required>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && PolicyNo.invalid">
                            <mat-error *ngIf="PolicyNo.errors.required">Policy Number is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div> -->

                      <div class="col-md-3">
                        <mat-form-field class="w-100">
                          <input matInput placeholder="Employer Phone #" [disabled]="!isAddInsurance"
                            mask='(000) 000-0000' [(ngModel)]="insuranceinfo.EmployerPhoneNo" #EmployerPhoneNo="ngModel"
                            name="EmployerPhoneNo"
                            [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && EmployerPhoneNo.invalid }" required>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && EmployerPhoneNo.invalid">
                            <mat-error *ngIf="EmployerPhoneNo.errors.required">Employer Phone # is required</mat-error>
                          </mat-error>
                        </mat-form-field>

                      </div>
                      <div class="col-md-6">
                        <mat-form-field class="w-100">

                          <input matInput placeholder="Employer Address" [disabled]="!isAddInsurance" maxlength="50"
                            [(ngModel)]="insuranceinfo.EmployerAddress" #EmployerAddress="ngModel"
                            name="EmployerAddress"
                            [ngClass]="{ 'is-invalid': (insurancefrm.submitted || insuranceSubitted) && EmployerAddress.invalid }" required>
                          <mat-error *ngIf="(insurancefrm.submitted || insuranceSubitted) && EmployerAddress.invalid">
                            <mat-error *ngIf="EmployerAddress.errors.required">Employer Address is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-3">
                        <span class="check-custom mr-3 float-left mt-2" style="padding-top: 28px; ">
                          <mat-checkbox class="example-margin" #IsPrimaryInsurance="ngModel"
                            [disabled]="(!isAddInsurance || isAddPrimary)" [(ngModel)]="insuranceinfo.IsPrimaryInsurance"
                            name="IsPrimaryInsurance">Primary Insurance
                          </mat-checkbox>
                        </span>

                      </div>
                        <div class="col-md-3" style="padding-top: 33px">
                          <span class="btn green attached-btn fileinput-button mr-2" *ngIf=!imgNameAttachment&&isAttachmentEnabled>
                              <i class="fa fa-plus fa fa-white pr-1"></i>
                              <span>Attachment</span>
                              <input accept=".jpg,.jpeg,.png" type='file' #insuranceUpload type='file' (change)="onInsuranceUpload($event)">
                          </span>
                          <span class="btn green attached-btn fileinput-button mr-2" *ngIf=!imgNameAttachment&&!isAttachmentEnabled>
                            <i class="fa fa-plus fa fa-white pr-1"></i>
                            <span>Attachment</span>
                            <input accept=".jpg,.jpeg,.png" type='file' #insuranceUpload type='file' (change)="onInsuranceUpload($event)">
                        </span>
                        <mat-chip-list class="mt-1 mb-1 mat-chip-attach w-auto" *ngIf="imgNameAttachment">
                          <mat-chip
                            [selectable]="selectable" [removable]="true" >
                            <span class="attachname" (click)="openAttachment()">{{imgNameAttachment}}</span>
                            <mat-icon matChipRemove (click)="deleteAttachment(3)" *ngIf="isAttachmentEnabled">cancel</mat-icon>
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                      <div class="insurance-imgsize">Note : When you are unchecking the checkbox, please check in the other insurance. If not, the details will get vanished </div>

                    </div>
                  </div>


                  <div class="col-md-12 mt-3">

                    <div class="row">
                      <!-- <div class="col-md-5">
                        <span class="check-custom mr-3">
                          <mat-checkbox class="example-margin" #IsScaRequired="ngModel" [disabled]="!isAddInsurance"
                            [(ngModel)]="insuranceinfo.IsScaRequired" name="IsScaRequired">Single Case
                            Agreement (SCA) required
                          </mat-checkbox>

                        </span>
                      </div> -->
                       <div class="col-md-7 pl-0">
                        <div *ngIf="roleId===1">
                        <button type="button" class="btn add-btn" (click)="addAuthorization()" title="Add Insurance"  *ngIf="!isShowAuthorization" >
                          <i  class="fas fa-plus mr-2"></i>
                           ADD AUTHORIZATION DETAILS</button>

                           <button type="button" class="btn add-btn" (click)="hideAuthorization()" title="Hide Insurance"   *ngIf="isShowAuthorization"  >
                            <i   class="fas fa-minus mr-2"></i>
                             HIDE AUTHORIZATION DETAILS</button>
                             </div>


                      </div>

                        <!-- <div  class="col-md-5 text-right" *ngIf="roleId === 4 || roleId == 1" > -->
                        <div  class="col-md-5 text-right">

                          <button type="button" [disabled]="immidiateactionid == statusVerified" class="btn add-btn"
                            title="Add Insurance" *ngIf="!isAddInsurance" (click)="addMoreInsurance()"><i
                              class="fas fa-plus mr-2"></i>ADD ANOTHER INSURANCE</button>
                          <button type="submit" title="Save" *ngIf="isAddInsurance" form="insurancefrm" mat-stroked-button
                            class="btn edit-btn mb-2 mr-2 primary-btn-m h-40">{{!isEditInsurance ? 'Add' : 'Update'}}</button>
                          <button mat-stroked-button role="button" title="Cancel" type="button" *ngIf="isAddInsurance"
                            class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2"
                            (click)="cancelAddMoreInsurance()">Cancel</button>
                        </div>

                      <!-- </div> -->
                    </div>
                  </div>

                </div>
                <!-- <div class="col-md-12 col-lg-3 text-center p-0">
                  <div class="custom-upload" title="Select upload type to add files">
                    <input multiple accept="application/msword, application/vnd.ms-excel, application/pdf, image/*"
                      type='file' #Insurancefile [disabled]="!isAddInsurance" (change)="onInsFileUpload($event)">
                    <label class="file-upload">Click to upload insurance policy document</label>
                  </div>

                </div> -->
              </div>
            </div>
            <!--Authorization -->
            <div class="author" *ngIf="isShowAuthorization">
              <app-insurance-authorization></app-insurance-authorization>
            </div>
            <!--Authorization end-->
            <div class="col-md-12 p-0">
              <div class="lead-box">
                <div class="clearfix w-100">
                  <div class="box-lead-head float-left">Insurance Details</div>
                  <!-- <div class="float-right" *ngIf="roleId === 4">
                    <button type="button" [disabled]="immidiateactionid == statusVerified" class="btn add-btn"
                      title="Add Insurance" *ngIf="!isAddInsurance" (click)="addMoreInsurance()"><i
                        class="fas fa-plus mr-2"></i>ADD ANOTHER INSURANCE</button>
                    <button type="submit" title="Save" *ngIf="isAddInsurance" form="insurancefrm" mat-stroked-button
                      class="btn edit-btn mb-2 mr-2 primary-btn-m h-40">{{!isEditInsurance ? 'Add' : 'Update'}}</button>
                    <button mat-stroked-button role="button" title="Cancel" type="button" *ngIf="isAddInsurance"
                      class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2"
                      (click)="cancelAddMoreInsurance()">Cancel</button>
                  </div> -->
                </div>
                <div class="col-md-12  table-custom p-0">
                  <div class="table-responsive">
                    <table mat-table [dataSource]="insDataSource" class="mat-elevation-z8 w-100" matSort>

                      <!-- DtoInsurance       <ng-container matColumnDef="MemberName">
                        <th mat-header-cell *matHeaderCellDef> COB </th>
                        <td mat-cell *matCellDef="let element">{{element.MemberName}}</td>
                      </ng-container> -->
                      <ng-container matColumnDef="InsuranceType">
                        <th mat-header-cell *matHeaderCellDef> COB </th>
                        <td mat-cell *matCellDef="let element">{{element.InsuranceType}}</td>
                      </ng-container>

                      <ng-container matColumnDef="CarrierName">
                        <th mat-header-cell *matHeaderCellDef> Carrier </th>
                        <td mat-cell *matCellDef="let element">{{element.CarrierName}} </td>
                      </ng-container>
                      <!-- <ng-container matColumnDef="PolicyNo">
                        <th mat-header-cell *matHeaderCellDef> Policy Number </th>
                        <td mat-cell *matCellDef="let element">{{element.PolicyNo}}</td>
                      </ng-container> -->
                      <ng-container matColumnDef="MemberId">
                        <th mat-header-cell *matHeaderCellDef> Member ID</th>
                        <td mat-cell *matCellDef="let element">{{element.MemberId}}</td>
                      </ng-container>

                       <ng-container matColumnDef="GroupNo">
                        <th mat-header-cell *matHeaderCellDef> Group Number </th>
                        <td mat-cell *matCellDef="let element"> {{element.GroupNo}} </td>
                      </ng-container>
                      <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions </th>
                        <td mat-cell *matCellDef="let element;let i = index;" class="">
                          <button type="button" mat-icon-button matTooltip="View" type="button"
                            (click)="onviewInsurance(element)" color="primary">
                            <mat-icon>remove_red_eye</mat-icon>
                          </button>
                          <button type="button" mat-icon-button matTooltip="Edit" type="button"
                            (click)="onEditInsurance(element)" color="primary">
                            <img src="../../../assets/images/editblue.png">
                          </button>
                          <button type="button" mat-icon-button matTooltip="Delete" type="button"
                            (click)="ondeleteInsurance(element)" color="warn">
                            <img src="../../../assets/images/deletered.png">
                          </button>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="insuraceColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: insuraceColumns;"></tr>
                    </table>
                  </div>

                </div>

                <div class="box-lead-head float-left">Insurance Details History</div>
               
              
              <div class="col-md-12  table-custom p-0">
                <div class="table-responsive">
                  <table mat-table [dataSource]="insDataSourceHistory" class="mat-elevation-z8 w-100" matSort>

                    <ng-container matColumnDef="InsuranceType">
                      <th mat-header-cell *matHeaderCellDef> COB </th>
                      <td mat-cell *matCellDef="let element">{{element.InsuranceType}}</td>
                    </ng-container>

                    <ng-container matColumnDef="CarrierName">
                      <th mat-header-cell *matHeaderCellDef> Carrier </th>
                      <td mat-cell *matCellDef="let element">{{element.CarrierName}} </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="MemberId">
                      <th mat-header-cell *matHeaderCellDef> Member ID</th>
                      <td mat-cell *matCellDef="let element">{{element.MemberId}}</td>
                    </ng-container>

                     <ng-container matColumnDef="GroupNo">
                      <th mat-header-cell *matHeaderCellDef> Group Number </th>
                      <td mat-cell *matCellDef="let element"> {{element.GroupNo}} </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef>Actions </th>
                      <td mat-cell *matCellDef="let element;let i = index;" class="">
                        <button type="button" mat-icon-button matTooltip="View" type="button"
                          (click)="onviewInsurance(element)" color="primary">
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <!-- <button type="button" mat-icon-button matTooltip="Edit" type="button"
                          (click)="onEditInsurance(element)" color="primary">
                          <img src="../../../assets/images/editblue.png">
                        </button>
                        <button type="button" mat-icon-button matTooltip="Delete" type="button"
                          (click)="ondeleteInsurance(element)" color="warn">
                          <img src="../../../assets/images/deletered.png">
                        </button> -->
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="insuraceColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: insuraceColumns;"></tr>
                  </table>
                </div>

              </div>


              </div>
            </div>

          </div>
        </div>
      </div>
    </form>

  </div>
  <div class="col-md-12 history-section mt-4" *ngIf="historyTab"
    [ngClass]="{'disabled':immidiateactionid != statusUploadInsurance && immidiateactionid != statusReturn }">
    <form (ngSubmit)="patienthistoryfrm.form.valid   && postPatientHistory()" id="patienthistoryfrm" autocomplete="off"
      #patienthistoryfrm="ngForm" (keydown.enter)="$event.preventDefault()" novalidate>
      <div class="row">
        <div class="col-md-6">
          <div class="lead-box">
            <div class="box-lead-head mb-3 col-md-12">History</div>
            <div class="col-md-12">
              <div class="col-md-12 ">
                <div class="row">
                  <div class="col-md-8 pl-0">
                    <label class="form-label pt-3">01. How did you hear about us?</label>
                  </div>
                  <div class="col-md-4">
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="custom-options">
                    <mat-select [(ngModel)]="patienthistoryinfo.SourceTypeId" #SourceTypeId="ngModel"
                      name="SourceTypeId"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && SourceTypeId.invalid }" required>
                      <mat-option *ngFor="let item of ddlSoucre" [disabled]="!item.IsActive"
                        [value]="item.SourceTypeId">
                        {{item.SourceName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6" *ngIf="this.patienthistoryinfo.SourceTypeId === 1">

                  <mat-form-field class="example-full-width custom-textarea ">
                    <mat-label>If other please specify</mat-label>
                    <textarea matInput [(ngModel)]="patienthistoryinfo.SourceOthers" #SourceOthers="ngModel"
                      name="SourceOthers"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && SourceOthers.invalid }"
                      required></textarea>
                    <mat-error *ngIf="patienthistoryfrm.submitted && SourceOthers.invalid">
                      <mat-error *ngIf="SourceOthers.errors.required">Others is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-md-12 odd-bg">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-7 pl-0">
                    <label class="form-label pt-3">02. Primary Care Physician</label>
                  </div>
                  <div class="col-md-5">
                    <fieldset class="fieldset-custom mb-3">
                      <legend>Search Phonebook</legend>
                      <mat-form-field class="example-full-width w-100 ">
                        <input matInput placeholder="" 
                          onKeyPress="if(this.value.length==10) return false;" step="0" class="ng-trim-ignore"
                          [matAutocomplete]="auto1" name="phbook1" #phbook1="ngModel" [(ngModel)]="phbook"
                          (ngModelChange)="doPhoneFilter()" />
                        <button type="button" mat-button matSuffix mat-icon-button aria-label="search">
                          <mat-icon>search</mat-icon>
                        </button>
                      </mat-form-field>
                      <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option *ngFor="let a of filteredphbook" (click)="bindhospitalinfo(a,1)"
                          [value]="a.phoneNumber">
                          {{a.phoneNumber | mask: '(000) 000-0000'}} - {{a.physicianName}}
                        </mat-option>
                      </mat-autocomplete>
                    </fieldset>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.FirstName" #PhysicianFirstName="ngModel"
                      name="PhysicianFirstName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PhysicianFirstName.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="LastName" appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.LastName" #PhysicianLastName="ngModel"
                      name="PhysicianLastName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PhysicianLastName.invalid }">

                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <input matInput placeholder="Email" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.Email" #PhysicianEmail="ngModel"
                      name="PhysicianEmail" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PhysicianEmail.invalid }" >
                    <mat-error *ngIf="patienthistoryfrm.submitted && PhysicianEmail.invalid">
                      <mat-error *ngIf="PhysicianEmail.errors.required">Email is required</mat-error>
                      <mat-error *ngIf="PhysicianEmail.errors?.pattern">Please enter valid email address</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Office Phone Number</mat-label>
                    <input matInput placeholder="Office Phone Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.PhoneNumber" #PhysicianPhone="ngModel"
                      name="PhysicianPhone"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PhysicianPhone.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Cell Phone Number</mat-label>
                    <input matInput placeholder="Cell Phone Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.CellPhone" #CellPhone="ngModel" name="CellPhone"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CellPhone.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Fax Number</mat-label>
                    <input matInput placeholder="Fax Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.FaxNumber" #FaxNumber="ngModel" name="FaxNumber"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && FaxNumber.invalid }">

                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Facility Name </mat-label>
                    <input matInput placeholder="Facility Name " appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.HospitalName" #PhysicianHospitalName="ngModel"
                      name="PhysicianHospitalName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PhysicianHospitalName.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Address1</mat-label>
                    <input matInput placeholder="Address1" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.AddressLine1"
                      #PhysicianHospitalAddress1="ngModel" name="PhysicianHospitalAddress1"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PhysicianHospitalAddress1.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Address2</mat-label>
                    <input matInput placeholder="Address2" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.AddressLine2" #PhysicianAddress2="ngModel"
                      name="PhysicianAddress2"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PhysicianAddress2.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>State</mat-label>
                    <mat-select [(ngModel)]="patienthistoryinfo.PhysicianDetails.StatesId" #PhysicianstatesId="ngModel"
                      (selectionChange)="onChangePhysicianState($event.value)" name="PhysicianstatesId"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PhysicianstatesId.invalid }">
                      <mat-option *ngFor="let item of ddlstatemaster" [value]="item.StatesId">
                        {{item.StateName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>City</mat-label>
                    <mat-select [(ngModel)]="patienthistoryinfo.PhysicianDetails.CityId" #PhysiciancityId="ngModel"
                      name="PhysiciancityId"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PhysiciancityId.invalid }">
                      <mat-option *ngFor="let item of ddlPhysicianCity" [value]="item.CitiesId">
                        {{item.CityName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Zip Code</mat-label>
                    <input matInput placeholder="Zip Code" mask='00000' maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.ZipCode" #PhysicianZipCode="ngModel"
                      name="PhysicianZipCode"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PhysicianZipCode.invalid }">

                  </mat-form-field>
                </div>

                <div class="col-md-12">
                  To provide excellent care we request to contact your PCP
                  <div>
                    <mat-radio-group [(ngModel)]="patienthistoryinfo.PhysicianDetails.IsAgree" #PhysicianDetailsIsAgree="ngModel"
                      name="PhysicianDetailsIsAgree">
                      <mat-radio-button *ngFor="let item of ddlAgreeDisagree; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 ">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-7 pl-0">
                    <label class="form-label pt-3">03. Do you see a Psychiatrist?  </label>
                    <div class="custom-radio  ml-4">
                    <!-- <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="1" class="mr-2">Yes</mat-radio-button>
                      <mat-radio-button value="2">No</mat-radio-button>
                    </mat-radio-group> -->
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="patienthistoryinfo.IsPsychiatrist" #IsPsychiatrist="ngModel" (change)="onAddPsyChange(1)"
                    name="IsPsychiatrist" >
                      <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  </div>
                  <div class="col-md-5" *ngIf="patienthistoryinfo.IsPsychiatrist==true">
                    <fieldset class="fieldset-custom mb-3">
                      <legend>Search Phonebook</legend>
                      <mat-form-field class="example-full-width w-100 ">
                        <input matInput placeholder="" 
                          onKeyPress="if(this.value.length==10) return false;" step="0" class="ng-trim-ignore"
                          [matAutocomplete]="auto2" name="phbook2" #phbook2="ngModel" [(ngModel)]="phPsychiatristbook"
                          (ngModelChange)="doPsychiatristPhoneFilter()" />
                        <button type="button" mat-button matSuffix mat-icon-button aria-label="search">
                          <mat-icon>search</mat-icon>
                        </button>
                      </mat-form-field>
                      <mat-autocomplete #auto2="matAutocomplete">
                        <mat-option *ngFor="let a of filteredPsychiatristph" (click)="bindhospitalinfo(a,2)"
                          [value]="a.phoneNumber">
                          {{a.phoneNumber | mask: '(000) 000-0000'}} - {{a.physicianName}}
                        </mat-option>
                      </mat-autocomplete>
                    </fieldset>
                  </div>
                </div>

              </div>

              <div class="row" *ngIf="patienthistoryinfo.IsPsychiatrist==true">
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PsychiatristDetails.FirstName" #PsychiatristFirstName="ngModel"
                      name="PsychiatristFirstName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PsychiatristFirstName.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="LastName" appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PsychiatristDetails.LastName" #PsychiatristLastName="ngModel"
                      name="PsychiatristLastName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PsychiatristLastName.invalid }">

                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <input matInput placeholder="Email" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PsychiatristDetails.Email" #PsychiatristEmail="ngModel"
                      name="PsychiatristEmail" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PsychiatristEmail.invalid }">
                    <mat-error *ngIf="patienthistoryfrm.submitted && PsychiatristEmail.invalid">
                      <mat-error *ngIf="PsychiatristEmail.errors.required">Email is required</mat-error>
                      <mat-error *ngIf="PsychiatristEmail.errors?.pattern">Please enter valid email address</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Office Phone Number</mat-label>
                    <input matInput placeholder="Office Phone Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.PsychiatristDetails.PhoneNumber" #PsychiatristPhone="ngModel"
                      name="PsychiatristPhone"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PsychiatristPhone.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Cell Phone Number</mat-label>
                    <input matInput placeholder="Cell Phone Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.CellPhone" #CellPhone="ngModel" name="CellPhone"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CellPhone.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Fax Number</mat-label>
                    <input matInput placeholder="Fax Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.PhysicianDetails.FaxNumber" #FaxNumber="ngModel" name="FaxNumber"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && FaxNumber.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Facility Name </mat-label>
                    <input matInput placeholder="Facility Name " appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PsychiatristDetails.HospitalName"
                      #PsychiatristHospitalName="ngModel" name="PsychiatristHospitalName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PsychiatristHospitalName.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Address1</mat-label>
                    <input matInput placeholder="Address1" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PsychiatristDetails.AddressLine1" #PsychiatristAddress1="ngModel"
                      name="PsychiatristAddress1"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PsychiatristAddress1.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Address2</mat-label>
                    <input matInput placeholder="Address2" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PsychiatristDetails.AddressLine2" #PsychiatristAddress2="ngModel"
                      name="PsychiatristAddress2"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PsychiatristAddress2.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>State</mat-label>
                    <mat-select [(ngModel)]="patienthistoryinfo.PsychiatristDetails.StatesId"
                      #PsychiatristStatesId="ngModel" (selectionChange)="onChangePsychiatristState($event.value)"
                      name="PsychiatristStatesId"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PsychiatristStatesId.invalid }">
                      <mat-option *ngFor="let item of ddlstatemaster" [value]="item.StatesId">
                        {{item.StateName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>City</mat-label>
                    <mat-select [(ngModel)]="patienthistoryinfo.PsychiatristDetails.CityId"
                      #PsychiatristCityId="ngModel" name="PsychiatristCityId"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PsychiatristCityId.invalid }">
                      <mat-option *ngFor="let item of ddlPsychiatristCity" [value]="item.CitiesId">
                        {{item.CityName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Zip Code</mat-label>
                    <input matInput placeholder="Zip Code " mask='00000' maxlength="50"
                      [(ngModel)]="patienthistoryinfo.PsychiatristDetails.ZipCode" #PsychiatristZipCode="ngModel"
                      name="PsychiatristZipCode"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && PsychiatristZipCode.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  To provide excellent care we request to contact your Psychiatrist
                  <div>

                    <mat-radio-group [(ngModel)]="patienthistoryinfo.PsychiatristDetails.IsAgree" #PsychiatristDetailsIsAgree="ngModel"
                    name="PsychiatristDetailsIsAgree">
                    <mat-radio-button *ngFor="let item of ddlAgreeDisagree; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>
                    </mat-radio-group>

                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 odd-bg">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-7 pl-0">
                    <label class="form-label pt-3">04. Do you see a Neurologist? </label>
                    <div class="custom-radio  ml-4">
                      <!-- <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="1" class="mr-2">Yes</mat-radio-button>
                        <mat-radio-button value="2">No</mat-radio-button>
                      </mat-radio-group> -->
                      <mat-radio-group aria-label="Select an option" [(ngModel)]="patienthistoryinfo.IsNeurologist" #IsNeurologist="ngModel" (change)="onAddPsyChange(2)"
                      name="IsNeurologist" >
                        <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                  <div class="col-md-5" *ngIf="patienthistoryinfo.IsNeurologist==true">
                    <fieldset class="fieldset-custom mb-3">
                      <legend>Search Phonebook</legend>
                      <mat-form-field class="example-full-width w-100 ">
                        <input matInput placeholder="" 
                          onKeyPress="if(this.value.length==10) return false;" step="0" class="ng-trim-ignore"
                          [matAutocomplete]="auto4" name="phbook2" #phbook2="ngModel" [(ngModel)]="phNeurologistbook"
                          (ngModelChange)="doNeurologistPhoneFilter()" />
                        <button type="button" mat-button matSuffix mat-icon-button aria-label="search">
                          <mat-icon>search</mat-icon>
                        </button>
                      </mat-form-field>
                      <mat-autocomplete #auto4="matAutocomplete">
                        <mat-option *ngFor="let a of filterNeurologist" (click)="bindhospitalinfo(a,4)"
                          [value]="a.phoneNumber">
                          {{a.phoneNumber | mask: '(000) 000-0000'}} - {{a.physicianName}}
                        </mat-option>
                      </mat-autocomplete>
                    </fieldset>
                  </div>
                </div>

              </div>

              <div class="row"  *ngIf="patienthistoryinfo.IsNeurologist==true">
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.NeurologistDetails.FirstName" #NeurologistFirstName="ngModel"
                      name="NeurologistFirstName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistFirstName.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="LastName" appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.NeurologistDetails.LastName" #NeurologistLastName="ngModel"
                      name="NeurologistLastName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistLastName.invalid }">

                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <input matInput placeholder="Email" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.NeurologistDetails.Email" #NeurologistEmail="ngModel"
                      name="NeurologistEmail" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistEmail.invalid }">
                    <mat-error *ngIf="patienthistoryfrm.submitted && NeurologistEmail.invalid">
                      <mat-error *ngIf="NeurologistEmail.errors.required">Email is required</mat-error>
                      <mat-error *ngIf="NeurologistEmail.errors?.pattern">Please enter valid email address</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Office Phone Number</mat-label>
                    <input matInput placeholder="Office Phone Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.NeurologistDetails.PhoneNumber" #NeurologistPhone="ngModel"
                      name="NeurologistPhone"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistPhone.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Cell Phone Number</mat-label>
                    <input matInput placeholder="Cell Phone Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.NeurologistDetails.CellPhone" #NeurologistCellPhone="ngModel" name="NeurologistCellPhone"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistCellPhone.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Fax Number</mat-label>
                    <input matInput placeholder="Fax Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.NeurologistDetails.FaxNumber" #NeurologistFaxNumber="ngModel" name="NeurologistFaxNumber"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistFaxNumber.invalid }">

                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Facility Name </mat-label>
                    <input matInput placeholder="Facility Name " appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.NeurologistDetails.HospitalName"
                      #NeurologistHospitalName="ngModel" name="NeurologistHospitalName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistHospitalName.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Address1</mat-label>
                    <input matInput placeholder="Address1" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.NeurologistDetails.AddressLine1" #NeurologistAddress1="ngModel"
                      name="NeurologistAddress1"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistAddress1.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Address2</mat-label>
                    <input matInput placeholder="Address2" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.NeurologistDetails.AddressLine2" #NeurologistAddress2="ngModel"
                      name="NeurologistAddress2"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistAddress2.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>State</mat-label>
                    <mat-select [(ngModel)]="patienthistoryinfo.NeurologistDetails.StatesId"
                      #NeurologistStatesId="ngModel" (selectionChange)="onChangePsychiatristState($event.value)"
                      name="NeurologistStatesId"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistStatesId.invalid }">
                      <mat-option *ngFor="let item of ddlstatemaster" [value]="item.StatesId">
                        {{item.StateName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>City</mat-label>
                    <mat-select [(ngModel)]="patienthistoryinfo.NeurologistDetails.CityId"
                      #NeurologistCityId="ngModel" name="NeurologistCityId"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistCityId.invalid }">
                      <mat-option *ngFor="let item of ddlPsychiatristCity" [value]="item.CitiesId">
                        {{item.CityName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Zip Code</mat-label>
                    <input matInput placeholder="Zip Code " mask='00000' maxlength="50"
                      [(ngModel)]="patienthistoryinfo.NeurologistDetails.ZipCode" #NeurologistZipCode="ngModel"
                      name="NeurologistZipCode"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NeurologistZipCode.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  To provide excellent care we request to contact your Neurologist
                  <div>

                    <mat-radio-group [(ngModel)]="patienthistoryinfo.NeurologistDetails.IsAgree" #NeurologistDetailsIsAgree="ngModel"
                    name="NeurologistDetailsIsAgree">
                    <mat-radio-button *ngFor="let item of ddlAgreeDisagree; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>
                    </mat-radio-group>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 ">

              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-7 pl-0">
                    <label class="form-label pt-3">05. Do you see a Therapist?</label>
                    <div class="custom-radio  ml-4">
                      <!-- <mat-radio-group aria-label="Select an option" >
                        <mat-radio-button value="1" class="mr-2">Yes</mat-radio-button>
                        <mat-radio-button value="2">No</mat-radio-button>
                      </mat-radio-group> -->
                      <mat-radio-group aria-label="Select an option" [(ngModel)]="patienthistoryinfo.IsTherapist" #IsTherapist="ngModel" (change)="onAddPsyChange(3)"
                      name="IsTherapist" >
                        <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                  <div class="col-md-5" *ngIf="patienthistoryinfo.IsTherapist==true">
                    <fieldset class="fieldset-custom mb-3">
                      <legend>Search Phonebook</legend>
                      <mat-form-field class="example-full-width w-100 ">
                        <input matInput placeholder="" 
                          onKeyPress="if(this.value.length==10) return false;" step="0" class="ng-trim-ignore"
                          [matAutocomplete]="auto3" name="phbook2" #phbook2="ngModel" [(ngModel)]="phTherapistbook"
                          (ngModelChange)="doTherapistPhoneFilter()" />
                        <button type="button" mat-button matSuffix mat-icon-button aria-label="search">
                          <mat-icon>search</mat-icon>
                        </button>
                      </mat-form-field>
                      <mat-autocomplete #auto3="matAutocomplete">
                        <mat-option *ngFor="let a of filteredTherapistph" (click)="bindhospitalinfo(a,3)"
                          [value]="a.phoneNumber">
                          {{a.phoneNumber | mask: '(000) 000-0000'}} - {{a.physicianName}}
                        </mat-option>
                      </mat-autocomplete>
                    </fieldset>
                  </div>
                </div>

              </div>

              <div class="row"  *ngIf="patienthistoryinfo.IsTherapist==true">
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.TherapistDetails.FirstName" #TherapistFirstName="ngModel"
                      name="TherapistFirstName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && TherapistFirstName.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="LastName" appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.TherapistDetails.LastName" #TherapistLastName="ngModel"
                      name="TherapistLastName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && TherapistLastName.invalid }">

                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <input matInput placeholder="Email" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.TherapistDetails.Email" #TherapistEmail="ngModel"
                      name="TherapistEmail" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && TherapistEmail.invalid }">
                    <mat-error *ngIf="patienthistoryfrm.submitted && TherapistEmail.invalid">
                      <mat-error *ngIf="TherapistEmail.errors.required">Email is required</mat-error>
                      <mat-error *ngIf="TherapistEmail.errors?.pattern">Please enter valid email address</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Office Phone Number</mat-label>
                    <input matInput placeholder="Office Phone Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.TherapistDetails.PhoneNumber" #TherapistPhoneNumber="ngModel"
                      name="TherapistPhoneNumber"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && TherapistPhoneNumber.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Cell Phone Number</mat-label>
                    <input matInput placeholder="Cell Phone Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.TherapistDetails.CellPhone" #CellPhone="ngModel" name="CellPhone"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CellPhone.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Fax Number</mat-label>
                    <input matInput placeholder="Fax Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.TherapistDetails.FaxNumber" #FaxNumber="ngModel" name="FaxNumber"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && FaxNumber.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Facility Name </mat-label>
                    <input matInput placeholder="Facility Name " appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.TherapistDetails.HospitalName" #TherapistHospitalName="ngModel"
                      name="TherapistHospitalName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && TherapistHospitalName.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Address1</mat-label>
                    <input matInput placeholder="Address1" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.TherapistDetails.AddressLine1" #TherapistAddressLine1="ngModel"
                      name="TherapistAddressLine1"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && TherapistAddressLine1.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Address2</mat-label>
                    <input matInput placeholder="Address2" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.TherapistDetails.AddressLine2" #TherapistAddressLine2="ngModel"
                      name="TherapistAddressLine2"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && TherapistAddressLine2.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>State</mat-label>
                    <mat-select [(ngModel)]="patienthistoryinfo.TherapistDetails.StatesId" #TherapistStatesId="ngModel"
                      (selectionChange)="onChangeTherapistState($event.value)" name="TherapistStatesId"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && TherapistStatesId.invalid }">
                      <mat-option *ngFor="let item of ddlstatemaster" [value]="item.StatesId">
                        {{item.StateName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>City</mat-label>
                    <mat-select [(ngModel)]="patienthistoryinfo.TherapistDetails.CityId" #TherapistCityId="ngModel"
                      name="TherapistCityId"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && TherapistCityId.invalid }">
                      <mat-option *ngFor="let item of ddlTherapistCity" [value]="item.CitiesId">
                        {{item.CityName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>ZipCode</mat-label>
                    <input matInput placeholder="ZipCode " mask='00000' maxlength="50"
                      [(ngModel)]="patienthistoryinfo.TherapistDetails.ZipCode" #TherapistZipCode="ngModel"
                      name="TherapistZipCode"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && TherapistZipCode.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  To provide excellent care we request to contact your Therapist
                  <div>
                    <!-- <input type="radio" name="patienthistoryinfo.IsTherapist" [value]='true'
                      [(ngModel)]='patienthistoryinfo.IsTherapist' required> Agree
                    <input type="radio" name='patienthistoryinfo.IsTherapist' [value]='false'
                      [(ngModel)]='patienthistoryinfo.IsTherapist' required> Disagree -->

                    <mat-radio-group [(ngModel)]="patienthistoryinfo.TherapistDetails.IsAgree" #TherapistDetailsIsAgree="ngModel"
                    name="TherapistDetailsIsAgree">
                    <mat-radio-button *ngFor="let item of ddlAgreeDisagree; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>
                    </mat-radio-group>
                    <!-- <mat-error *ngIf="patienthistoryfrm.submitted && IsTherapist.invalid">
                      <mat-error *ngIf="IsTherapist.errors.required">This field is required
                      </mat-error>
                    </mat-error> -->
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-12 odd-bg">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-7 pl-0">
                    <label class="form-label pt-3">06. Do you have a case manager? </label>
                    <div class="custom-radio  ml-4">
                      <!-- <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="1" class="mr-2">Yes</mat-radio-button>
                        <mat-radio-button value="2">No</mat-radio-button>
                      </mat-radio-group> -->
                      <mat-radio-group aria-label="Select an option" [(ngModel)]="patienthistoryinfo.IsCaseManager" #IsCaseManager="ngModel" (change)="onAddPsyChange(6)"
                      name="IsCaseManager" >
                        <mat-radio-button *ngFor="let item of ddlyesno; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                  <div class="col-md-5" *ngIf="patienthistoryinfo.IsCaseManager==true">
                    <fieldset class="fieldset-custom mb-3">
                      <legend>Search Phonebook</legend>
                      <mat-form-field class="example-full-width w-100 ">
                        <input matInput placeholder="" 
                          onKeyPress="if(this.value.length==10) return false;" step="0" class="ng-trim-ignore"
                          [matAutocomplete]="auto4" name="phbook2" #phbook2="ngModel" [(ngModel)]="phCasemanagerbook"
                          (ngModelChange)="doCaseManagerPhoneFilter()" />
                        <button type="button" mat-button matSuffix mat-icon-button aria-label="search">
                          <mat-icon>search</mat-icon>
                        </button>
                      </mat-form-field>
                      <mat-autocomplete #auto4="matAutocomplete">
                        <mat-option *ngFor="let a of filterCaseManager" (click)="bindhospitalinfo(a,5)"
                          [value]="a.phoneNumber">
                          {{a.phoneNumber | mask: '(000) 000-0000'}} - {{a.physicianName}}
                        </mat-option>
                      </mat-autocomplete>
                    </fieldset>
                  </div>
                </div>

              </div>

              <div class="row"  *ngIf="patienthistoryinfo.IsCaseManager==true">
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>First Name</mat-label>
                    <input matInput placeholder="First Name" appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.CaseMangerDetails.FirstName" #CaseManagerFirstName="ngModel"
                      name="CaseManagerFirstName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseManagerFirstName.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Last Name</mat-label>
                    <input matInput placeholder="LastName" appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.CaseMangerDetails.LastName" #CaseManagerLastName="ngModel"
                      name="CaseManagerLastName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseManagerLastName.invalid }">

                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <input matInput placeholder="Email" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.CaseMangerDetails.Email" #CaseManagerEmail="ngModel"
                      name="CaseManagerEmail" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseManagerEmail.invalid }">
                    <mat-error *ngIf="patienthistoryfrm.submitted && CaseManagerEmail.invalid">
                      <mat-error *ngIf="CaseManagerEmail.errors.required">Email is required</mat-error>
                      <mat-error *ngIf="CaseManagerEmail.errors?.pattern">Please enter valid email address</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Office Phone Number</mat-label>
                    <input matInput placeholder="Office Phone Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.CaseMangerDetails.PhoneNumber" #CaseManagerPhone="ngModel"
                      name="CaseManagerPhone"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseManagerPhone.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Cell Phone Number</mat-label>
                    <input matInput placeholder="Cell Phone Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.CaseMangerDetails.CellPhone" #CaseManagerCellPhone="ngModel" name="CaseManagerCellPhone"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseManagerCellPhone.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Fax Number</mat-label>
                    <input matInput placeholder="Fax Number" mask='(000) 000-0000'
                      [(ngModel)]="patienthistoryinfo.CaseMangerDetails.FaxNumber" #CaseManagerFaxNumber="ngModel" name="CaseManagerFaxNumber"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseManagerFaxNumber.invalid }">

                  </mat-form-field>
                </div>

                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Facility Name </mat-label>
                    <input matInput placeholder="Facility Name " appAlphabetOnly maxlength="50"
                      [(ngModel)]="patienthistoryinfo.CaseMangerDetails.HospitalName"
                      #CaseManagerHospitalName="ngModel" name="CaseManagerHospitalName"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseManagerHospitalName.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Address1</mat-label>
                    <input matInput placeholder="Address1" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.CaseMangerDetails.AddressLine1" #CaseManagerAddress1="ngModel"
                      name="CaseManagerAddress1"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseManagerAddress1.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Address2</mat-label>
                    <input matInput placeholder="Address2" maxlength="50"
                      [(ngModel)]="patienthistoryinfo.CaseMangerDetails.AddressLine2" #CaseManagerAddress2="ngModel"
                      name="CaseManagerAddress2"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseManagerAddress2.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>State</mat-label>
                    <mat-select [(ngModel)]="patienthistoryinfo.CaseMangerDetails.StatesId"
                      #CaseMangerStatesId="ngModel" (selectionChange)="onChangePsychiatristState($event.value)"
                      name="CaseMangerStatesId"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseMangerStatesId.invalid }">
                      <mat-option *ngFor="let item of ddlstatemaster" [value]="item.StatesId">
                        {{item.StateName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>City</mat-label>
                    <mat-select [(ngModel)]="patienthistoryinfo.CaseMangerDetails.CityId"
                      #CaseMangerCityId="ngModel" name="CaseMangerCityId"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseMangerCityId.invalid }">
                      <mat-option *ngFor="let item of ddlPsychiatristCity" [value]="item.CitiesId">
                        {{item.CityName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field class="example-full-width w-100">
                    <mat-label>Zip Code</mat-label>
                    <input matInput placeholder="Zip Code " mask='00000' maxlength="50"
                      [(ngModel)]="patienthistoryinfo.CaseMangerDetails.ZipCode" #CaseMangerZipCode="ngModel"
                      name="CaseMangerZipCode"
                      [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && CaseMangerZipCode.invalid }">

                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  To provide excellent care we request to contact your Case Manager
                  <div>

                    <mat-radio-group [(ngModel)]="patienthistoryinfo.CaseMangerDetails.IsAgree" #CaseMangerDetailsIsAgree="ngModel"
                    name="CaseMangerDetailsIsAgree">
                    <mat-radio-button *ngFor="let item of ddlAgreeDisagree; let i=index" class="mr-2"  [value]="item.value"  > {{item.label}} </mat-radio-button>
                    </mat-radio-group>

                  </div>
                </div>
              </div>
            </div>
            
          </div>

        </div>
        <div class="col-md-6">
          <div class="col-md-12 mb-4 p-0">
            <div class="lead-box">
              <div class="box-lead-head mb-3 col-md-12">PAST OUTCOMES</div>
              <div class="col-md-12">
                <label class="form-label pt-3"> </label>
                <!-- <mat-form-field class="example-full-width custom-textarea mt-2"> -->
                  <mat-label>What worked? What didn't?</mat-label>

                  <!-- <textarea matInput placeholder="" appTextareaAutoresize [(ngModel)]="patienthistoryinfo.WorkDesc"
                    #WorkDesc="ngModel" name="WorkDesc"
                    [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && WorkDesc.invalid }" required></textarea> 
                    -->
                   
                  
                    <app-word-counter-textarea [text]="patienthistoryinfo.WorkDesc" [maxlength]="3000"  [(ngModel)]="patienthistoryinfo.WorkDesc" 
                    (textChange)="patienthistoryinfo.WorkDesc = $event" #WorkDesc="ngModel" name="WorkDesc" ngDefaultControl  required></app-word-counter-textarea>
                   
                    
                  <mat-error class="mb-2" *ngIf="patienthistoryfrm.submitted && WorkDesc.invalid">
                    <mat-error *ngIf="WorkDesc.errors.required">This field is
                      required</mat-error>
                  </mat-error>
                <!-- </mat-form-field> -->
              </div>

              <div class="col-md-12">
                <label class="form-label pt-3"></label>
                <!-- <mat-form-field class="example-full-width custom-textarea mt-2"> -->
                  <mat-label>What goals would you like to accomplish for your child? </mat-label>
                  <!-- <textarea matInput placeholder="" appTextareaAutoresize [(ngModel)]="patienthistoryinfo.GoalsDesc"
                    #GoalsDesc="ngModel" name="GoalsDesc"
                    [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && GoalsDesc.invalid }" required></textarea> 
                     -->
                    <app-word-counter-textarea [text]="patienthistoryinfo.GoalsDesc" [maxlength]="3000"  [(ngModel)]="patienthistoryinfo.GoalsDesc"
                     #GoalsDesc="ngModel" name="GoalsDesc" ngDefaultControl [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && GoalsDesc.invalid }" required></app-word-counter-textarea>
  

                  <mat-error class="mb-2" *ngIf="patienthistoryfrm.submitted && GoalsDesc.invalid">
                    <mat-error *ngIf="GoalsDesc.errors.required">This field is
                      required</mat-error>
                  </mat-error>
                <!-- </mat-form-field> -->
              </div>

              <div class="col-md-12">
                <label class="form-label pt-3"></label>
                <!-- <mat-form-field class="example-full-width custom-textarea mt-2"> -->
                  <mat-label>What are your child's nutritional concerns?</mat-label>
                  <!-- <textarea matInput placeholder="" appTextareaAutoresize [(ngModel)]="patienthistoryinfo.NutritionDesc"
                    #NutritionDesc="ngModel" name="NutritionDesc"
                    [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NutritionDesc.invalid }"
                    required></textarea> -->

                    <app-word-counter-textarea [text]="patienthistoryinfo.NutritionDesc" [maxlength]="3000"  [(ngModel)]="patienthistoryinfo.NutritionDesc"
                      #NutritionDesc="ngModel" name="NutritionDesc" ngDefaultControl
                    [ngClass]="{ 'is-invalid': patienthistoryfrm.submitted && NutritionDesc.invalid }" required></app-word-counter-textarea>
  

                  <mat-error *ngIf="patienthistoryfrm.submitted && NutritionDesc.invalid">
                    <mat-error *ngIf="NutritionDesc.errors.required">This field is
                      required</mat-error>
                  </mat-error>
                <!-- </mat-form-field> -->
              </div>

            </div>
          </div>
          <div class="col-md-12 set6-temp">
            <div class="row mb-2">
                <div class="col-md-12 custom-radio">

                    <div class="medication col-md-12 inputWrapper p-0" *ngIf="_dtoMedications.length > 0 || isAssigned">
                        <div class="row inputElement">
                            <div class="col-md-3"><label>Medications</label></div>
                            <div class="col-md-3"><label>Dosage</label></div>
                            <div class="col-md-3"><label>Frequency</label></div>
                            <div class="col-md-3"><label>Side Effects</label></div>
                        </div>
                        <div class="loop-section position-relative mb-2"
                            *ngFor="let med of _dtoMedications;let i= index;let last=last">
                            <div class="row  inputElement">
                                <div class="col-md-3">
                                    <input class="form-control" maxlength="50" [(ngModel)]="med.medications"
                                        #medications="ngModel" name="medications{{i}}" placeholder="Medication"
                                        type="text">
                                </div>
                                <div class="col-md-3"><input class="form-control" maxlength="30"
                                        [(ngModel)]="med.dosage" #dosage="ngModel" name="dosage{{i}}"
                                        placeholder="Dosage" type="text"></div>
                                <div class="col-md-3 pr-0">
                                    <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                                        [(ngModel)]="med.frequency" #frequency="ngModel" name="frequency{{i}}">
                                        <option value="null">Frequency</option>
                                        <option value="1">q.a.m. Every morning</option>
                                        <option value="2">q.p.m. Every evening</option>
                                        <option value="3">q.h.s Every night at bedtime</option>
                                        <option value="4">b.i.d. Twice daily</option>
                                        <option value="5">t.i.d. 3 times a day</option>
                                        <option value="6">q.i.d 4 times a day</option>
                                        <option value="7">q.o.d Every other day</option>
                                        <option value="8">q.w. Every week</option>
                                        <option value="9">p.r.n. Daily as needed</option>
                                    </select>
                                </div>
                                <div class="col-md-3"><input class="form-control" maxlength="50"
                                        [(ngModel)]="med.sideEffects" #sideEffects="ngModel" name="sideEffects{{i}}"
                                        placeholder="Side Effects" type="text"></div>
                            </div>
                            <a class="add" style="cursor: pointer;" *ngIf="last" (click)="addMedicationInfo()"><i
                                    class="fa fa-lg fa-plus-circle"></i><span class="sr-only">Add</span></a>
                            <a class="remove" style="cursor: pointer;"
                                (click)="removeclinicalinformation(med,i)"><i class="fa fa-lg fa-minus-circle"></i><span
                                    class="sr-only">Remove</span></a>
                        </div>

                    </div>

                    <div class="col-md-12 set1-temp" *ngIf="_dtoMedications.length == 0">
                      <div class=" col-md-12 row">
                          <div class="col-md-10 p-0">
                              <label class="additional">Medication history not available, would you like to add?</label>

                          </div>
                          <div class="col-md-2 p-0">
                              <button type="button" (click)="addMedicationInfo()" mat-stroked-button
              class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Add</button>
                          </div>

                      </div>
                  </div>
                  <div class="col-md-12 set1-temp  pl-3">
                    <div class="box-lead-head mb-3">PHARMACY DETAILS</div>
                    <mat-form-field class="w-100">
                    <input matInput placeholder="Pharmacy Name"  appAlphabetOnly maxlength="50" [(ngModel)]="patienthistoryinfo.PharmacyName" #PharmacyName="ngModel" name="PharmacyName">
                    </mat-form-field>
                    <mat-form-field class="w-100">
                      <input matInput placeholder="Address" maxlength="50" [(ngModel)]="patienthistoryinfo.PharmacyAddress" #PharmacyAddress="ngModel" name="PharmacyAddress">
                      </mat-form-field>
                      <mat-form-field class="w-100">
                        <input matInput placeholder="Phone Number"  mask='(000) 000-0000' maxlength="14"  [(ngModel)]="patienthistoryinfo.PharmacyPhone" #PharmacyPhone="ngModel"
                        name="PharmacyPhone">
                        </mat-form-field>
                        <mat-form-field class="w-100">
                          <input matInput placeholder="Fax" mask='(000) 000-0000'  [(ngModel)]="patienthistoryinfo.PharmacyFax" #PharmacyFax="ngModel"
                          name="PharmacyFax">
                          </mat-form-field>
                    <!-- [(ngModel)]="patientinfo.FirstName" #FirstName="ngModel" name="FirstName"
                    autocomplete="new-password"> -->
                          <label class="additional">Last physical exam</label>
                          <mat-form-field class="col-md-5 pl-5">
                            <mat-label>Date</mat-label>
                            <input matInput [matDatepicker]="exppicker" [max]="currentdate" [(ngModel)]="patienthistoryinfo.LastPhysicalExamDate" #lastPhysicalExamDate="ngModel" name="lastPhysicalExamDate" required>
                            <mat-datepicker-toggle matSuffix [for]="exppicker "></mat-datepicker-toggle>
                            <mat-datepicker #exppicker></mat-datepicker>
                            <mat-error *ngIf="patienthistoryfrm.submitted && lastPhysicalExamDate.invalid">
                              <mat-error *ngIf="lastPhysicalExamDate.errors.required">This field is required</mat-error>
                            </mat-error>
                          </mat-form-field>                        
                  </div>

                </div>
            </div>
        </div>
          <div class="col-md-12 p-0 d-none">
            <div class="lead-box">
              <div class="box-lead-head mb-3 col-md-12">CURRENT MEDICATIONS</div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-10" *ngFor="let med of medicineinfo;let i = index">
                    <div class="row">
                      <div class="col-md-11">
                        <mat-form-field class="example-full-width w-100">
                          <input matInput [(ngModel)]="med.Medication" maxlength="50"
                            [ngModelOptions]="{ standalone : true }" #Medication="ngModel" required>
                          <mat-error *ngIf="patienthistoryfrm.submitted && Medication.invalid">
                            <mat-error *ngIf="Medication.errors.required">Medication is required</mat-error>
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="col-md-1 p-0 mt-3 text-right" *ngIf="i !== 0">
                        <a (click)="removeMedicine(med,i)" class="cursor-pointer icon-close" title="Remove">
                          <span class="material-icons">
                            close
                          </span>
                        </a>
                      </div>

                    </div>
                  </div>
                  <div class="col-md-1">
                    <p class="actions mt-3">
                      <a (click)="addMedicine()" class="cursor-pointer icon-color" title="Add Medications">
                        <span class="material-icons">
                          add
                        </span>
                      </a>

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="col-md-12 history-section mt-4"
    *ngIf="feeTab && ((roleAccess.isCreate || roleAccess.isEdit) && (LeadStatus==statusFinancial || LeadStatus==statusreviewTour||LeadStatus==statusforIntake ))">
    <app-financial-review></app-financial-review>
  </div>

  <!--Here's the section for Authorization tab-->
  <!-- <div class="col-md-12 history-section mt-4"
  *ngIf="authorizationTab">
 <app-insurance-authorization></app-insurance-authorization>
</div> -->

</div>
<div class="modal mail-modal" id="myModal" [style.display]="global.showEmailPopup ? 'block' : 'none'">
  <div class="modal-dialog mb-2">
    <div class="modal-content email-compose">
      <div class="modal-header">
        <h4 class="modal-title">Email</h4>
        <button aria-hidden="true" data-dismiss="modal" class="close" (click)="global.oncloseEmailpopup()"
          type="button"><i class="fas fa-times"></i></button>

      </div>
      <div class="modal-body">
        <app-send-email [subtag]="subjectTag" [idtag]="idTag" [tomailtag]="parentemail"></app-send-email>

      </div>
    </div>
  </div>
</div>

<!-- <div class="modal " id="myModal" [style.display]="isInsUploadModel ? 'block' : 'none'">
  <div class="modal-dialog mb-2">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">Tag Insurance</h6>
        <button aria-hidden="true" data-dismiss="modal" class="close" (click)="oncloseInsUpload()" type="button"><i
            class="fas fa-times"></i></button>

      </div>
      <div class="modal-body">
        <table class="table table-striped upload-table">
          <thead>
            <tr>
              <th>File Name</th>
              <th>Upload Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of UploadFileList;let i = index">
              <td>
                <div class="text-control"> {{data.File.name}}</div>
              </td>
              <td>
                <mat-form-field>
                  <mat-label>Upload Type</mat-label>
                  <mat-select (selectionChange)="ddluploadType($event)" name="utype_{{i}}" [(ngModel)]="data.uploadflag"
                    [ngModelOptions]="{standalone: true}">
                    <mat-option value="0">Insurance front</mat-option>
                    <mat-option value="1">Insurance back</mat-option>
                    <mat-option value="2">Policy Document</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td> <button type="button" (click)="deleteInsurancePopup(i)" mat-icon-button matTooltip="Delete"
                  color="warn">
                  <img src="../../../assets/images/deletered.png">
                </button></td>
            </tr>

          </tbody>
        </table>
      </div>
      <div class="modal-footer text-center">
        <button type="button" (click)="onchoosenInsurance()" class="btn primary-btn-filled primary-btn-s "
          data-dismiss="modal">Ok</button>
      </div>
</div>
</div>
</div> -->
<!-- <div class="col-md-12 mt-4 mb-4 p-0  lead-box pt-3 pb-3" *ngIf="esignTab">
  <div class="row">
    <div class="col-md-6">
      <div class="col-md-12">
        Would you like to sign future?
        <div>
          <mat-radio-group [(ngModel)]="patientPrimary.IsSignFuture" #IsSignFuture="ngModel" name="IsSignFuture"
            [disabled]="roleId !== 4" (change)="onEsignOption($event.value)" required>
            <mat-radio-button [value]='true' class="mr-3 my-3"> Yes</mat-radio-button>
            <mat-radio-button [value]='false'> No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="insurance-img" *ngIf="patientPrimary.IsSignFuture">
        <div class="w-100 pl-3" *ngIf="signatureImg !== null && signatureImg !==''"><img [src]="signatureImg"
            style="height: 100px; width:auto;border: 1px solid #ccc;"></div>
      </div>
      <div class="col-md-12 text-right mt-3" *ngIf="patientPrimary.IsSignFuture">
        <button type="button" (click)="openDialog()" [disabled]="roleId !== 4"
          class="btn primary-btn-filled primary-btn-m h-40 mr-2" type="button">
          Add E-Sign
        </button>
      </div>
    </div>
  </div>
</div> -->

  <!--PAyment Details tab-->
  <!-- <div class="col-md-12 history-section mt-4"
  *ngIf="paymentDetailsTab">
<app-payment-details></app-payment-details>
</div> -->
<!--PAyment Details tab end-->

import { Component, ElementRef, OnInit,ViewChild } from '@angular/core';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { ActivatedRoute,Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/app/common/common.service';
import {IncReportInfo, RestraintCondition} from 'src/app/_models/IncReportDtl'
import { MatDialog } from '@angular/material/dialog';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { DischargeMedication, DischargeSummaryFormInfo } from 'src/app/_models/_Treatment/_dischargeSummary/_dichargeSummaryInfo';
import { ClinicianService } from '../../clinician/clinician.service';
import { constantVariables } from 'src/app/constant/constant';
import { SELECT_ITEM_HEIGHT_EM } from '@angular/material/select';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
@Component({
  selector: 'app-discharge-summary',
  templateUrl: './discharge-summary.component.html',
  styleUrls: ['./discharge-summary.component.scss']
})
export class DischargeSummaryComponent implements OnInit {
  @ViewChild('DisSumFrm') DisSumFrm: NgForm;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  ddlpatientList:any
  Id: any;
  ddlstafflist: any;
  DischargeSUmmaryInfo:DischargeSummaryFormInfo;
  DOB: any;
  _dtoMedications: any=[];
  ddldiagnosiscodeModel : any;
  ddldiagnosiscode:any; 
  isESignAttached: boolean;
  signatureImg: any;
  updatedsignatureImg: any;
  isformtype: string;
  defaultImage: any;
  DischargeSummaryId: number;
  clinician: number;
  status: number;
  Isedit: boolean;
  Isdischarge:any;
  IseditNum: number;
  StartDateRestriction:any;
  PopUpPreview: boolean;
  PatientName:any;
  ClinicianName: any;
  DiagnosisCodeAllowed: any;
  roleAccess: any;
  secondaryId : any;
  AutoFillSS:boolean=false;
  SSignature:any;
  GetClinicianSign: any[];  
  userId: any;
  constructor(public treatmentservice: TreatmentService, public router:Router,public global: Global,public clinicianservice: ClinicianService,
    public spinnerservice: NgxSpinnerService, private dialog: MatDialog, private snackbar: SnackBarService,public commonService: CommonService,private route: ActivatedRoute
    ,public roleaccessservice:PageAccessService
    ) { }

  ngOnInit(): void {
    this.userId = Number(localStorage.getItem('id'));

    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.DischargeSummaryId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.clinician = Number(this.commonService.FrontEndDecryption(params.clinician).replace(/"/g, ""));
        this.IseditNum = Number(this.commonService.FrontEndDecryption(params.isedit).replace(/"/g, ""));
      }
    })
    const pageid = constantVariables.pagelists.DischargeSummary;
   this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);

   this.getDiagnosisCodeMster();
   this.getPatientlist()
   this.GetStaffList()
   this.getAutoFillSignature();
    this.DischargeSUmmaryInfo = new DischargeSummaryFormInfo();
    this.DischargeSUmmaryInfo.clientSignDate = new Date();
    this.DischargeSUmmaryInfo.clinicianSignDate = new Date();
    this.DischargeSUmmaryInfo.famMemSignDate = new Date();
    this.DischargeSUmmaryInfo.dsDate = new Date();
    this.StartDateRestriction = new Date();
    this.StartDateRestriction.setDate(this.StartDateRestriction.getDate() - 1);
    let med = new DischargeMedication();
    med.dischargeMedicationId=0;
    med.dischargeSummaryId = this.DischargeSUmmaryInfo.dischargeSummaryId;
    med.patientAccountId = this.DischargeSUmmaryInfo.patientAccountId;
    this._dtoMedications.push(med);
    this.Id = localStorage.getItem('id') 
   
    if(this.IseditNum==1){
      this.Isedit=true;
    }
    else{
      this.Isedit = false;
    }
  
  }
  getDiagnosisCodeMster() {
    this.commonService
      .getDiagonosisCodeMaster()
      .subscribe((res: any) => {
        if (res.objInputString != null) {
          let fres = this.commonService.decrypdata(res.objInputString);
          if (fres.length > 0) {
            this.ddldiagnosiscodeModel = fres;
            this.ddldiagnosiscodeModel?.forEach(x=>x.isActive=false);
        }
      }
      },
        err => {
          this.spinnerservice.hide();
        });
  }
  getPatientlist(){
    this.spinnerservice.show()
    this.secondaryId = localStorage.getItem('id');
    let locationId=+localStorage.getItem('sfcclocation')

    if (locationId == 0){
      locationId = -1;
    }
  this.treatmentservice.getPatientListforDS(-1,locationId,this.secondaryId).subscribe(res=>{
    if(this.DischargeSummaryId!=undefined){
      this.ddlpatientList = res;
    }
    else{
      this.ddlpatientList = res.filter(x=>x.diagnosisCode!='');
    }
       if(this.DischargeSummaryId!=0){
        this.getDischargeSummayByid();
    }
   this.spinnerservice.hide()
  },
  err => {
    this.spinnerservice.hide();
  
  })
  }
  GetStaffList(){
    this.spinnerservice.show()
    this.treatmentservice.getCLinicalStaffList().subscribe(res=>{
      this.spinnerservice.hide()
      this.ddlstafflist = res
     // this.ddlReportedBy = res
      this.DischargeSUmmaryInfo.clinicianId = +this.Id
      this.ClinicianName = this.ddlstafflist.filter(x=>x.userId ==this.DischargeSUmmaryInfo.clinicianId )
    //   if(this.DischargeSummaryId!=0){
    //     this.getDischargeSummayByid();
    // }
    },
    err => {
      this.spinnerservice.hide();
  
    })
  }
  onPatientchange(item){
    //this.IncReportfrmInfo.Address = item.address
     this.PatientName=item.patientName
    this.DischargeSUmmaryInfo.dob = item.dateDOB
    this.DischargeSUmmaryInfo.diagnosisCode = item.diagnosisCode.split(',').map(String);
    this.ddldiagnosiscode = [];
    this.ddldiagnosiscodeModel.forEach(x=>x.isActive=false);
    for (let item of this.ddldiagnosiscodeModel){
      if( this.DischargeSUmmaryInfo.diagnosisCode.includes(item.value)){
        item.isActive = true;
        this.ddldiagnosiscode.push(item)
      }
    }
    this.ongetMedication();
  }
  PostDisSummaryFrm(){
    if(!this.DisSumFrm.valid ){
      this.snackbar.error("Please fill mandatory fields")
      return
    }
    else{
      this.DischargeSUmmaryInfo.medication = this._dtoMedications
    this.DischargeSUmmaryInfo.diagnosisCode= this.DischargeSUmmaryInfo.diagnosisCode.toString();
    console.log(this.DischargeSUmmaryInfo)
    this.spinnerservice.show()
    const dataToEncrypt = this.commonService.DataToEncrypt(this.DischargeSUmmaryInfo, false);
    this.clinicianservice.PostDischargeSummary(this.DischargeSUmmaryInfo).subscribe(
      res=>{
        this.spinnerservice.hide();
        this.snackbar.success(res);
        this.OnBack();
       // this.getDischargeSummayByid();
    },
        err => {
      
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },)
      }
  }
  addMedicationInfo() {
    let med = new DischargeMedication();
    med.dischargeMedicationId=0;
    med.dischargeSummaryId = this.DischargeSUmmaryInfo.dischargeSummaryId;
    med.patientAccountId = this.DischargeSUmmaryInfo.patientAccountId;
    this._dtoMedications.push(med);
   // this._dtoMedications.push(new DischargeMedication())
}
removeclinicalinformation(data, index) {
  if (data.dischargeMedicationId != 0 ) {
    this.removeexistMedinfo(data);
    this.getDischargeSummayByid();
  }
  else{
    this._dtoMedications.splice(index, 1);
  }
}
removeexistMedinfo(data) {
  this.spinnerservice.show();
  this.clinicianservice.removeDischargeMedication(data.dischargeMedicationId).subscribe((res: any) => {
    this.spinnerservice.hide();
    
  },
    err => {
      this.spinnerservice.hide();
    }
  );
}

onDiagnosisCodeselect(item, event) {
  item.isActive = false;
  if(event==true){
    if(this.DiagnosisCodeAllowed.includes(item.value)){
      item.isActive = event;
    }
    else{
      this.DischargeSUmmaryInfo.diagnosisCode.pop();
      item.isActive = false;
      this.snackbar.error("Please select valid diagnosis code for the patient")
    }
  }
  else{
  item.isActive = event;
  }
}
onDischargeType(item){
  console.log(this.Isdischarge)
  if(item==1){
this.DischargeSUmmaryInfo.isDischarge = true
this.DischargeSUmmaryInfo.isDropOut= false
  }
  else if(item==2){
    this.DischargeSUmmaryInfo.isDischarge = false
this.DischargeSUmmaryInfo.isDropOut= true

  }
}
openDialog() {
  // this.global.isconsentesign = false;
  // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
  //   data: { isfutureSign: false, isParentsign: true },
  //   autoFocus: false 

  // });
  this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if (
      this.global.isconsentesign != undefined &&
      this.global.isconsentesign
    ) {
      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
      this.signatureImg = this.updatedsignatureImg;
      this.DischargeSUmmaryInfo.clinicianSignature = this.updatedsignatureImg;
     // this.IncReportfrmInfo.IsAttachEsign = true;
      (document.getElementById('esign_3') as HTMLImageElement).src =
        this.signatureImg;
    } else if (
      !this.global.isconsentesign &&
      this.global.formtype != null &&
      this.global.formtype != undefined
    ) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      this.DischargeSUmmaryInfo.clinicianSignature= this.global.consentesign;
      //this.IncReviewInfo.IsAttachEsign = true;
      (document.getElementById('esign_3') as HTMLImageElement).src =
        this.signatureImg;
    } else {
      this.signatureImg = this.defaultImage;
      //this.IncReportfrmInfo.IsAttachEsign = true;
      this.DischargeSUmmaryInfo.clinicianSignature = this.defaultImage;
    }
  });
}
openDialog1() {
  this.global.isconsentesign = false;
  const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
    data: { isfutureSign: false, isParentsign: true },
    autoFocus: false 

  });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if (
      this.global.isconsentesign != undefined &&
      this.global.isconsentesign
    ) {
      this.signatureImg = this.updatedsignatureImg;
      this.DischargeSUmmaryInfo.clientSigntaure = this.updatedsignatureImg;
     // this.IncReportfrmInfo.IsAttachEsign = true;
      (document.getElementById('esign_2') as HTMLImageElement).src =
        this.signatureImg;
    } else if (
      !this.global.isconsentesign &&
      this.global.formtype != null &&
      this.global.formtype != undefined
    ) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      this.DischargeSUmmaryInfo.clientSigntaure= this.global.consentesign;
      //this.IncReviewInfo.IsAttachEsign = true;
      (document.getElementById('esign_2') as HTMLImageElement).src =
        this.signatureImg;
    } else {
      this.signatureImg = this.defaultImage;
      //this.IncReportfrmInfo.IsAttachEsign = true;
      this.DischargeSUmmaryInfo.clientSigntaure = this.defaultImage;
    }
  });
}
openDialog2() {
  // this.global.isconsentesign = false;
  // const dialogRef = this.dialog.open(ConsentEsignDialogComponent, {
  //   data: { isfutureSign: false, isParentsign: true },
  //   autoFocus: false 

  // });
  this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
  dialogRef.afterClosed().subscribe((result) => {
    this.isESignAttached = this.global.isconsentesign;
    if (
      this.global.isconsentesign != undefined &&
      this.global.isconsentesign
    ) {
      if(this.SSignature!='')
        {
         this.updatedsignatureImg=this.SSignature;
        }
      this.signatureImg = this.updatedsignatureImg;

      this.DischargeSUmmaryInfo.famMemSignature = this.updatedsignatureImg;
     // this.IncReportfrmInfo.IsAttachEsign = true;
      (document.getElementById('esign_1') as HTMLImageElement).src =
        this.signatureImg;
    } else if (
      !this.global.isconsentesign &&
      this.global.formtype != null &&
      this.global.formtype != undefined
    ) {
      this.signatureImg = this.global.consentesign;
      this.isformtype = this.global.formtype;
      this.DischargeSUmmaryInfo.famMemSignature= this.global.consentesign;
      //this.IncReviewInfo.IsAttachEsign = true;
      (document.getElementById('esign_1') as HTMLImageElement).src = this.signatureImg;
    } else {
      this.signatureImg = this.defaultImage;
      //this.IncReportfrmInfo.IsAttachEsign = true;
      this.DischargeSUmmaryInfo.famMemSignature = this.defaultImage;
    }
  });
}
ongetMedication() {
  this._dtoMedications = [];
  this.spinnerservice.show();

  this.clinicianservice.getprogressmedicineForDS(this.DischargeSUmmaryInfo.patientAccountId,0).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if (res?.length!=0) {
        this._dtoMedications = res;
      }
      else{
        let med = new DischargeMedication();
        med.dischargeMedicationId=0;
        med.dischargeSummaryId = this.DischargeSUmmaryInfo.dischargeSummaryId;
        med.patientAccountId = this.DischargeSUmmaryInfo.patientAccountId;
        this._dtoMedications.push(med);
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
  //this.addMedicationInfo();
}
getDischargeSummayByid(){
  this.spinnerservice.show();
  this.secondaryId = localStorage.getItem('id');
  this.treatmentservice.getDischargeSummayByid(this.DischargeSummaryId,this.secondaryId).subscribe((res:any)=>{

    this.DischargeSUmmaryInfo = res
    this.DischargeSUmmaryInfo.diagnosisCode = this.DischargeSUmmaryInfo.diagnosisCode.split(',').map(String);
    this.ddldiagnosiscode = [];
    this.ddldiagnosiscodeModel.forEach(x=>x.isActive=false);
    for (let item of this.ddldiagnosiscodeModel){
      if( this.DischargeSUmmaryInfo.diagnosisCode.includes(item.value)){
        item.isActive = true;
        this.ddldiagnosiscode.push(item)
      }
    }
    this._dtoMedications = this.DischargeSUmmaryInfo.medication;
    if(this.DischargeSUmmaryInfo.isDischarge == true){
    this.Isdischarge = 1
    }
    else if(this.DischargeSUmmaryInfo.isDropOut== true ){
      this.Isdischarge = 2
    }
    if(this.DischargeSummaryId!=undefined){
  //  this.PatientName = (this.ddlpatientList.filter(x=>x.patientAccountId == this.DischargeSUmmaryInfo.patientAccountId))[0].patientName
  this.PatientName = (this.ddlpatientList.filter(x=>x.patientAccountId == this.DischargeSUmmaryInfo.patientAccountId)).patientName

    }
    (document.getElementById('esign_3') as HTMLImageElement).src = this.DischargeSUmmaryInfo.clinicianSignature;
    if(this.ClinicianName==null || this.ClinicianName == undefined || this.ClinicianName.length==0){
      this.ClinicianName = this.ddlstafflist.filter(x=>x.userId ==this.DischargeSUmmaryInfo.clinicianId )
    }
    // (document.getElementById('esign_2') as HTMLImageElement).src = this.DischargeSUmmaryInfo.clientSigntaure;
    (document.getElementById('esign_1') as HTMLImageElement).src = this.DischargeSUmmaryInfo.famMemSignature;
    this.spinnerservice.hide()
  },
  err => {
    this.spinnerservice.hide();
  },)
}
OnBack(){
  this.router.navigate(['/clinician'],{queryParams:{menu:5}});
}
OnTreatmentsummary(){
  this.router.navigate(['/treatment-summary'],{queryParams:{id:this.commonService.FrontEndEncryption(this.DischargeSUmmaryInfo.dischargeSummaryId),isedit:this.commonService.FrontEndEncryption(this.IseditNum),clinician:this.commonService.FrontEndEncryption(this.Id)}});
}
OnpreviewClick(){
  this.PopUpPreview = true;
}
OnclosePopup(){
  this.PopUpPreview = false;
}
dischargepdf(){
  this.spinnerservice.show();
    this.treatmentservice.getdischargesummarypdf(this.DischargeSummaryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
          this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
          console.log(res);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
}
secondarySign():any{
  if(this.ddlpatientList[0].licenseNumber != '' && this.ddlpatientList[0].licenseNumber != null ){
    return false;
  }
  else if(this.ddlpatientList[0].sSignature ){
   return false;
  }
  else{
    return true;
  }
}
getAutoFillSignature() {
  this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
      this.GetClinicianSign = res;

      if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
        this.AutoFillSS=false;
        this.SSignature=this.GetClinicianSign[0].clinicianSign;
      }
      else
      {
        this.AutoFillSS=true;
        this.SSignature='';
      }
  });
}
}
